import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import {
  ChecklistCategoryType,
  CreateChecklistCategoriesResponse,
  CreateUpdateChecklistResponse,
  EnumChecklistEntityType,
  ListChecklistCategoriesResponse,
} from "../../../models/checklists";
import { LIST_CHECKLIST_CATEGORIES } from "./queries";

const listChecklistCategoriesData = (
  cache: ApolloCache<any>,
  entityId: string,
  entityType: EnumChecklistEntityType
) => {
  const cacheData = cache.readQuery<ListChecklistCategoriesResponse>({
    query: LIST_CHECKLIST_CATEGORIES,
    variables: {
      entityId,
      entityType,
    },
  });
  return cacheData?.listChecklistCategories;
};

const updateListChecklistCategoriesData = (
  cache: ApolloCache<any>,
  entityId: string,
  entityType: EnumChecklistEntityType,
  newList: ChecklistCategoryType[]
) => {
  cache.writeQuery({
    query: LIST_CHECKLIST_CATEGORIES,
    variables: {
      entityId,
      entityType,
    },
    data: {
      listChecklistCategories: newList,
    },
  });
};

export const handleCreateCategory = (
  entityId: string,
  entityType: EnumChecklistEntityType
): MutationUpdaterFn<CreateChecklistCategoriesResponse> => (
  cache,
  { data }
) => {
  const category = data?.createUpdateChecklistCategory;

  const categoryCache = listChecklistCategoriesData(
    cache,
    entityId,
    entityType
  );
  if (!categoryCache || !category) {
    return;
  }

  const categories = categoryCache.concat(category);
  updateListChecklistCategoriesData(cache, entityId, entityType, categories);
};

export const handleCreateItemChecklist = (
  entityId: string,
  entityType: EnumChecklistEntityType
): MutationUpdaterFn<CreateUpdateChecklistResponse> => (cache, { data }) => {
  const newItem = data?.createUpdateChecklist;

  const categoryCache = listChecklistCategoriesData(
    cache,
    entityId,
    entityType
  );
  if (!categoryCache || !newItem) {
    return;
  }

  const newData = categoryCache?.map((item) => {
    if (item._id === newItem?.category._id) {
      return { ...item, checklist: [...item.checklist, newItem] };
    }
    return item;
  });

  updateListChecklistCategoriesData(cache, entityId, entityType, newData);
};
