import { isNaN, find } from "lodash";
import { chain, evaluate } from "mathjs";
import React, { ChangeEvent, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StateManager } from "react-select/src/stateManager";
import { TakeoffListItem } from "../../models/take-off";
import { convertQuantity } from "../plans/take-off-plan-viewer/utils";

import CalculatorInput from "./CalculatorInput";
import CalculatorOptions from "./CalculatorOptions";
import "./styles.scss";
import {
  CALC_INPUT_CONFIG,
  CalcActions,
  CalcSubmitFunction,
  normalizeExpression,
  Rounding,
} from "./utils";

type CalculatorProps = {
  onClose: () => void;
  onSubmit: CalcSubmitFunction;
  initialExpresion: string;
  initialRounding?: Rounding;
  initialWastage?: string;
  initialTakeoff?: TakeoffListItem;
  disableKeypress?: boolean;
  takeoffs: TakeoffListItem[];
  onCreateTakeoffPress?: () => void;
};

const Calculator: React.FC<CalculatorProps> = (props) => {
  const {
    onClose,
    onSubmit,
    initialExpresion,
    initialRounding,
    initialWastage,
    initialTakeoff,
    disableKeypress,
    takeoffs,
    onCreateTakeoffPress,
  } = props;

  const { t } = useTranslation();

  const [expression, setExpression] = useState<string>("");
  const [calcResult, setCalcResult] = useState<number | null>(null);
  const roundingRef = useRef<StateManager | null>(null);
  const wastageRef = useRef<StateManager | null>(null);

  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      //setExpression(normalizeExpression(event.target.value));
      setTakeOff(null);
    },
    []
  );

  const [rounding, setRounding] = useState<Rounding>(Rounding.NONE);
  const [wastage, setWastage] = useState("");
  const [takeOff, setTakeOff] = useState<TakeoffListItem | null>(null);

  React.useEffect(() => {
    if (initialWastage) {
      setWastage(initialWastage);
    }
    if (initialRounding) {
      setRounding(initialRounding);
    }
  }, [initialRounding, initialWastage]);

  React.useEffect(() => {
    setExpression(normalizeExpression(initialExpresion));
  }, [initialExpresion]);

  const handleSubmit = React.useCallback(() => {
    if (!calcResult) {
      return;
    }

    onSubmit(calcResult, {
      rounding,
      wastage: parseFloat(wastage || "0"),
      takeOff,
      quantity: expression.toString(),
    });
  }, [onSubmit, calcResult, rounding, wastage, expression]);

  const onCalcButtonClick = React.useCallback(
    (value: string) => {
      switch (value) {
        case "=": {
          handleSubmit();
          break;
        }
        case CalcActions.CLEAR: {
          setExpression("");
          break;
        }
        case CalcActions.REMOVE: {
          setExpression(expression.slice(0, -1));
          break;
        }
        default:
          const normalizedExpression = normalizeExpression(expression + value);
          setExpression(normalizedExpression);
      }
    },
    [expression, handleSubmit]
  );

  React.useEffect(() => {
    try {
      const result = evaluate(expression);
      let resultChain = chain(result);

      const preparedWastage = parseInt(wastage);

      if (!isNaN(preparedWastage)) {
        resultChain = resultChain.multiply(preparedWastage / 100 + 1);
      }

      switch (rounding) {
        case Rounding.UP:
          resultChain = resultChain.ceil();
          break;
        case Rounding.DOWN:
          resultChain = resultChain.floor();
          break;
        case Rounding.NEAREST:
          resultChain = resultChain.round(0);
          break;
        default:
          resultChain = resultChain.round(2);
      }

      setCalcResult(resultChain.done());
    } catch (e) {
      setCalcResult(null);
    }
  }, [expression, rounding, wastage]);

  const handleRoundingChange = React.useCallback((value) => {
    setRounding(value);
  }, []);

  const handleWastageChange = React.useCallback((value) => {
    setWastage(value);
  }, []);

  const handleTakeOffChange = React.useCallback(
    (value) => {
      const takeoff = find(takeoffs, { _id: value });

      if (!takeoff) return;
      setTakeOff(takeoff || null);
      const { quantity } = convertQuantity(takeoff.quantity, takeoff.UOM);
      setExpression(quantity.toString());
    },
    [takeoffs]
  );

  React.useEffect(() => {
    if (initialTakeoff) {
      setTakeOff(initialTakeoff || null);
      const { quantity } = convertQuantity(
        initialTakeoff.quantity,
        initialTakeoff.UOM
      );
      setExpression(quantity.toString());
    }
  }, [initialTakeoff]);

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (disableKeypress) return;

      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
        return;
      }
      if (event.key === "w") {
        event.preventDefault();

        if (wastageRef.current) {
          roundingRef.current && roundingRef.current.onMenuClose();
          return wastageRef.current.state.menuIsOpen
            ? wastageRef.current.onMenuClose()
            : wastageRef.current.onMenuOpen();
        }
      }
      if (event.key === "r") {
        event.preventDefault();

        if (roundingRef.current) {
          wastageRef.current && wastageRef.current.onMenuClose();
          return roundingRef.current.state.menuIsOpen
            ? roundingRef.current.onMenuClose()
            : roundingRef.current.onMenuOpen();
        }
      }
      if (event.key === "Backspace") {
        event.preventDefault();
        if (expression.length) {
          setExpression(
            normalizeExpression(expression.slice(0, expression.length - 1))
          );
        }
        return;
      }
      if (event.key === "c") {
        return setExpression("");
      }
      setExpression(normalizeExpression(expression + event.key));
    },
    [handleSubmit, expression, disableKeypress]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Row className="calculator">
        <Col sm={12} lg={7} className="calculator-col">
          <CalculatorInput
            calcResult={calcResult}
            expression={expression}
            config={CALC_INPUT_CONFIG}
            onTextareaChange={handleChange}
            onCalcButtonClick={onCalcButtonClick}
          />
        </Col>
        <Col sm={12} lg={5} className="calculator-inputs-col">
          <CalculatorOptions
            initialRounding={initialRounding}
            initialWastage={initialWastage}
            initialTakeoff={initialTakeoff}
            onRoundingChange={handleRoundingChange}
            onWastageChange={handleWastageChange}
            onTakeOffChange={handleTakeOffChange}
            roundingRef={roundingRef}
            wastageRef={wastageRef}
            takeoffs={takeoffs}
            onCreateTakeoffPress={onCreateTakeoffPress}
          />
          <div className="d-flex calculator-form-controls">
            <Button
              variant="secondary"
              className="button large info mr-2"
              onClick={onClose}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="button large success ml-2"
            >
              {t("common.accept")}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Calculator;
