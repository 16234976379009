import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";
import { AddMemberPayload, UserRoles } from "../../../models/team-member";

export default function createMemberSchema(t: TFunction) {
  return yup.object().shape<AddMemberPayload>({
    first_name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "team.first_name")
      ),
    last_name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "team.last_name")
      ),
    email: yup.string().email().required(),
    role: yup
      .string()
      .oneOf([UserRoles.builderadmin, UserRoles.basic, UserRoles.manager])
      .required(ValidationMessagesUtils.createRequiredMessage(t, "team.email")),
  });
}
