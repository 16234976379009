import { gql } from "@apollo/client";
import { TODOLIST_FRAGMENT, TODO_FRAGMENT } from "./fragments";

export const UPDATE_TODO_LIST_COMPLETION = gql`
  mutation updateTodoListCompletion($todoListId: ID!, $status: Boolean!) {
    updateTodoListCompletion(todoListId: $todoListId, status: $status) {
      ...TodoListFragment
    }
  }
  ${TODOLIST_FRAGMENT}
`;

export const DELETE_TODO_LIST = gql`
  mutation deleteTodoList($todoListId: ID!) {
    deleteTodoList(todoListId: $todoListId) {
      ...TodoListFragment
    }
  }
  ${TODOLIST_FRAGMENT}
`;

export const CREATE_UPDATE_TODO_LIST = gql`
  mutation createUpdateTodoList($todoList: NewTodoListInput!) {
    createUpdateTodoList(todoList: $todoList) {
      ...TodoListFragment
    }
  }
  ${TODOLIST_FRAGMENT}
`;

export const BULK_UPDATE_TODO_LIST = gql`
  mutation BulkUpdateTodoList(
    $action: TodoListBulkAction!
    $todoLists: [TodoListInput]!
  ) {
    bulkUpdateTodoList(action: $action, todoLists: $todoLists) {
      _id
      completed
      is_deleted
      task
    }
  }
`;

export const CREATE_TODO_CHECKLIST = gql`
  mutation($checkList: TodoListChecklistInput!) {
    todo: createUpdateTodoChecklist(checkList: $checkList) {
      ...TodoFragment
    }
  }
  ${TODO_FRAGMENT}
`;

export const TODO_DELETE_CHECKLIST_CATEGORY = gql`
  mutation($todoListId: ID!, $checklistCategoryId: ID!) {
    todo: deleteTodoChecklistCategory(
      todoListId: $todoListId
      checklistCategoryId: $checklistCategoryId
    ) {
      ...TodoFragment
    }
  }
  ${TODO_FRAGMENT}
`;

export const ARCHIVE_TODO = gql`
  mutation UpdateTodoListArchiveState($todoListId: ID!, $isArchived: Boolean!) {
    todo: updateTodoListArchiveState(
      todoListId: $todoListId
      is_archived: $isArchived
    ) {
      ...TodoFragment
    }
  }
  ${TODO_FRAGMENT}
`;

export const TODO_DELETE_CHECKLIST = gql`
  mutation DeleteTodoListChecklist($todoListId: ID!, $checklistId: ID!) {
    deleteTodoListChecklist(
      todoListId: $todoListId
      checklistId: $checklistId
    ) {
      ...TodoFragment
    }
  }
  ${TODO_FRAGMENT}
`;

export const CREATE_TODO_CHECKLIST_CATEGORY = gql`
  mutation($checkListCategory: TodoListChecklistCategoryInput!) {
    todo: createUpdateTodoChecklistCategory(
      checkListCategory: $checkListCategory
    ) {
      ...TodoFragment
    }
  }
  ${TODO_FRAGMENT}
`;

export const TOGGLE_CHECKLIST_STATUS = gql`
  mutation ToggleTodoListChecklistStatus(
    $todoListId: ID!
    $checklistId: ID!
    $isComplete: Boolean
  ) {
    toggleTodoListChecklistStatus(
      todoListId: $todoListId
      checklistId: $checklistId
      isComplete: $isComplete
    ) {
      ...TodoFragment
    }
  }
  ${TODO_FRAGMENT}
`;

export const UPDATE_TODO_LIST_TAGS = gql`
  mutation($todoListId: ID!, $tags: [String!]) {
    todo: updateTodoListTags(todoListId: $todoListId, tags: $tags) {
      ...TodoFragment
    }
  }
  ${TODO_FRAGMENT}
`;

export const CREATE_UPDATE_CHECKLIST_TEMPLATE = gql`
  mutation CreateUpdateChecklistTemplate(
    $template: TodoChecklistTemplateInput!
  ) {
    createUpdateChecklistTemplate(template: $template) {
      _id
      name
      is_deleted
      categories {
        _id
        name
        is_deleted
        order
        templateId
        items {
          _id
          task
          order
          categoryId
          is_deleted
        }
      }
    }
  }
`;

export const SAVE_CHECKLIST_CATEGORY_TEMPLATE = gql`
  mutation CreateUpdateChecklistTemplateCategory(
    $templateId: ID!
    $category: todoChecklistTemplateCategoryInput!
  ) {
    createUpdateChecklistTemplateCategory(
      templateId: $templateId
      category: $category
    ) {
      _id
      name
      categories {
        _id
      }
    }
  }
`;

export const DELETE_CHECKLIST_TEMPLATE = gql`
  mutation DeleteChecklistTemplate($templateId: ID!) {
    deleteChecklistTemplate(templateId: $templateId) {
      _id
      name
      is_deleted
      categories {
        _id
        name
        is_deleted
        order
        templateId
        items {
          _id
          task
          order
          categoryId
          is_deleted
        }
      }
    }
  }
`;

export const DELETE_CHECKLIST_TEMPLATE_CATEGORY = gql`
  mutation DeleteChecklistTemplateCategory($templateId: ID!, $categoryId: ID!) {
    deleteChecklistTemplateCategory(
      templateId: $templateId
      categoryId: $categoryId
    ) {
      _id
      name
    }
  }
`;

export const CREATE_CHECKLIST_TEMPLATE_CATEGORY = gql`
  mutation CreateUpdateChecklistTemplateCategory(
    $templateId: ID!
    $category: todoChecklistTemplateCategoryInput!
  ) {
    createUpdateChecklistTemplateCategory(
      templateId: $templateId
      category: $category
    ) {
      _id
      name
      categories {
        _id
        name
        order
        items {
          _id
          task
          order
          categoryId
        }
      }
    }
  }
`;

export const IMPOR_TODO_FROM_TEMPLATE = gql`
  mutation ImportTodoChecklistFromTemplate($todoListId: ID!, $templateId: ID!) {
    importTodoChecklistFromTemplate(
      todoListId: $todoListId
      templateId: $templateId
    ) {
      _id
      checklistCategories {
        _id
        name
        checklist {
          _id
          task
          assignedTo {
            ... on User {
              _id
              fullName
            }
          }
        }
      }
    }
  }
`;
