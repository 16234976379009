import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { map } from "lodash";
import moment from "moment";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/subcontractor-navigation-items";
import {
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import {
  QuoteRequest,
  QuoteRequestListResponse,
  QuoteRequestCommentPayload,
} from "../../../graphql/types/models/quote-request";
import TableCard from "../../../components/dashboard/table-card";
import { LIST_QUOTE_REQUESTS } from "../../../graphql/queries/subcontractor/quotes/queries";
import { formatQuoteNumber } from "../../../utils/text";

type QuoteRequestTableItem = QuoteRequest & {
  status: string;
  builder: string;
};
const QuotesContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data, loading } = useQuery<QuoteRequestListResponse>(
    LIST_QUOTE_REQUESTS
  );

  const tableData = React.useMemo<TableCardData<QuoteRequestTableItem>>(() => {
    return {
      columns: [
        {
          valueKey: "quoteNumber",
          title: t("common.number"),
          formatValue: (row: any, column: any, value: number) =>
            formatQuoteNumber(value),
        },
        {
          valueKey: "builder",
          title: t("quoteRequest.builder"),
          formatValue: (row: any, column: any, value: number) => value,
        },
        {
          valueKey: "name",
          title: t("quoteRequest.title"),
          formatValue: (row: any, column: any, value: number) => value,
        },
        {
          valueKey: "due_date",
          title: t("quoteRequest.due_date"),
          formatValue: (row: any, column: any, value: number) =>
            moment().format("Do MMMM YYYY"),
        },
        {
          valueKey: "status",
          title: t("common.status"),
          formatValue: (row: any, column: any, value: number) => value,
        },
      ],
      rows: map(data?.subcontractorListQuoteRequests, (item: QuoteRequest) => ({
        cells: {
          ...item,
          status: item.subcontractors[0].status,
          builder: item.business.name,
        },
      })),
    };
  }, [data]);

  const quotesTableRowActions: TableRowActionData<
    QuoteRequestTableItem
  >[] = React.useMemo(
    () => [
      {
        // text: "Accept",
        icon: "check",
        onClick: console.log,
      },
      {
        icon: "close",
        onClick: console.log,
      },
      {
        svgIcon: "Bill",
        onClick: console.log,
        // shouldRender: (row) => row.status !== "Draft",
      },
    ],
    []
  );

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<QuoteRequestTableItem>) => {
      history.push(`/quotes/${row.cells._id}`);
    },
    []
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quotesSection.quotes")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES} />
      <Row>
        <Col xs={12}>
          <TableCard
            isDataLoading={loading}
            data={tableData}
            alignEnd
            onRowClick={handleRowClick}
            // rowActions={quotesTableRowActions}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default QuotesContainer;
