import { MutationUpdaterFn, ApolloCache } from "@apollo/client";
import {
  CreateUpdateOrderResponse,
  DeleteOrderResponse,
  ListOrdersResponse,
  ReceivePurchaseOrder,
  GetOrderResponse,
} from "../../types/models/order";
import {
  PurchaseOrderListItem,
  PurchaseOrderReal,
  PurchaseOrderReceipt,
} from "../../../models/purchaseOrder";
import { GET_ORDER, LIST_ORDERS } from "./queries";
import { concat, filter, omit } from "lodash";
import {
  findCostingItemFromCache,
  updateCostingItemCache,
} from "../job-costing/utils";
import { JobCostingPurchaseOrderItem } from "../../../models/job";

const getOrdersList = (cache: ApolloCache<any>, jobId?: string) => {
  const orderList = cache.readQuery<ListOrdersResponse>({
    query: LIST_ORDERS,
    variables: {
      jobId,
    },
  });
  return orderList?.getJobPurchaseOrders;
};

const updateOrdersList = (
  cache: ApolloCache<any>,
  orders: PurchaseOrderListItem[],
  jobId?: string
) => {
  cache.writeQuery<ListOrdersResponse>({
    query: LIST_ORDERS,
    variables: {
      jobId,
    },
    data: {
      getJobPurchaseOrders: orders,
    },
  });
};

const updateOrder = (
  cache: ApolloCache<any>,
  orderId: string,
  order: PurchaseOrderReal | null,
  jobId?: string
) => {
  cache.writeQuery({
    query: GET_ORDER,
    variables: {
      jobId,
      purchaseOrderId: orderId,
    },
    data: {
      getJobPurchaseOrderById: order,
    },
  });
};

const getOrder = (
  cache: ApolloCache<any>,
  orderId?: string,
  jobId?: string
) => {
  const orderResponse = cache.readQuery<GetOrderResponse>({
    query: GET_ORDER,
    variables: {
      jobId,
      purchaseOrderId: orderId,
    },
  });
  return orderResponse?.getJobPurchaseOrderById;
};

export const handleOrderAdd = (
  jobId?: string
): MutationUpdaterFn<CreateUpdateOrderResponse> => (cache, response) => {
  const createdOrder = response.data?.jobCreateUpdatePurchaseOrder;
  if (!createdOrder) return;

  const orders = getOrdersList(cache, jobId);
  if (orders) {
    const orderListItem = {
      ...createdOrder,
    };
    const updatedOrders = concat(orders, orderListItem);
    updateOrdersList(cache, updatedOrders, jobId);
  }
  updateOrder(cache, createdOrder._id, createdOrder, jobId);

  const purchaseOrder = omit(createdOrder, ["items"]);
  createdOrder.items?.forEach((item) => {
    if (!item) return;
    const purchaseOrderItem = {
      ...item,
      orderId: createdOrder._id,
      purchaseOrder,
      receipts: [] as PurchaseOrderReceipt[],
    } as JobCostingPurchaseOrderItem;
    const costingFragment = findCostingItemFromCache(cache, item.costingItemId);
    if (costingFragment) {
      updateCostingItemCache(cache, {
        ...costingFragment,
        purchaseOrderItems: (costingFragment.purchaseOrderItems || []).concat(
          purchaseOrderItem
        ),
      });
    }
  });
};

export const handleOrderDelete = (
  jobId?: string
): MutationUpdaterFn<DeleteOrderResponse> => (cache, response) => {
  const deletedOrder = response.data?.jobDeletePurchaseOrder;

  const orders = getOrdersList(cache, jobId);

  if (!deletedOrder || !orders) {
    return;
  }
  const updatedOrders = filter(
    orders,
    (order) => order._id !== deletedOrder._id
  );
  updateOrdersList(cache, updatedOrders, jobId);
  updateOrder(cache, deletedOrder._id, null, jobId);
};

export const handleUpdateOrderReceive = (
  jobId: string,
  purchaseOrderId: string
): MutationUpdaterFn<ReceivePurchaseOrder> => (cache, { data }) => {
  const newOrder = data?.jobReceivePurchaseOrder;
  const oldOrder = getOrder(cache, purchaseOrderId, jobId);

  if (!newOrder || !oldOrder) return;
  updateOrder(cache, newOrder._id, newOrder, jobId);
};
