import { LatLngLiteral } from "leaflet";

export type TakeOffItemProperties = {
  color?: string;
  text?: string;
  isEdited?: boolean;
};

export type TakeOffComment = {
  id: number | string;
  type: string;
  properties: TakeOffItemProperties;
  geometry: LatLngLiteral;
};

export enum TakeOffMeasurement {
  LINEAR_METER = "lm",
  MILLIMETRE = "mm",
  METER_SQUARED = "m2",
  CUBIC_METER = "m3",
  QUANTITY = "qty",
  TONNE = "tonne",
}

export enum TakeOffShapes {
  RECTANGLE = "rectangle",
  POLYLINE = "polyline",
  DEDUCTION = "deduction",
  COMMENT = "comment",
}

export type CreateTakeOffPayload = {
  name: string;
  UOM: TakeOffMeasurement;
  color: string;
};

export type CopyTakeOffPayload = {
  name: string;
  UOM?: TakeOffMeasurement;
  color: string;
  depth?: string;
};

export type UpdateTakeOffPayload = {
  _id: string;
  name: string;
  color: string;
};

export type CreateTakeOffLabelPayload = {
  name: string;
};

export type TakeoffProps = {
  color: string;
};

export type TakeoffListItem = {
  _id?: string;
  name: string;
  UOM: string;
  quantity: number;
  properties: TakeoffProps;
  shapes?: TakeOffShape[];
};

export type TakeOffShape = {
  name?: string;
  geometry: LatLngLiteral[];
  angle: number | null;
  wallHeight: number | null;
  depth: number | null;
  materialWidth: number | null;
  weight: number | null;
  type: string;
  page: number;
  leaflet_id: number;
  properties?: object;
};

export type SerializedLayer = {
  name?: string;
  type: string;
  page: number | null;
  geometry: LatLngLiteral[];
  leaflet_id: number;
  angle: number | null;
  wallHeight: number | null;
  depth: number | null;
  materialWidth: number | null;
  properties?: object;
  weight: number | null;
};

export type ConvertedQuantityUnit = {
  quantity: number;
  UOM: string;
};
