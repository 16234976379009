import { useLazyQuery, useMutation } from "@apollo/client";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import CreateUpdateDocumentModal, {
  ModalAction,
} from "../components/documents-main/create-update-modal";
import UploadDocumentModal, {
  UploadDocumentModalRef,
} from "../components/documents-main/upload-files-modal";
import { notify } from "../components/notification";
import DocumentsTableComponent, {
  DocumentType,
  DocumentsTable,
} from "../containers/documents/documents-table";
import {
  CREATE_UPDATE_DOCUMENT,
  CREATE_UPDATE_FOLDER,
  DELETE_DOCUMENT,
  DELETE_FOLDER,
} from "../graphql/queries/documents/mutations";
import {
  GET_FOLDER,
  GET_SYSTEM_FOLDER,
} from "../graphql/queries/documents/queries";
import {
  FolderType,
  handleDocumentAdd,
  handleDocumentDelete,
  handleDocumentUpdate,
  handleFolderAdd,
  handleFolderDelete,
  handleFolderUpdate,
} from "../graphql/queries/documents/utils";
import {
  CreateUpdateDocumentResponse,
  CreateUpdateFolderResponse,
  DeleteDocumentResponse,
  DeleteFolderResponse,
  GetFolderResponse,
  GetSystemFolderResponse,
} from "../graphql/types/models/documents";
import {
  CreateFolderPayload,
  CurrentDirectoryWithId,
  EnumDocumentAccessRole,
  SystemFolderType,
  UpdateDocumentPayload,
} from "../models/documents";
import { uploadFiles } from "../utils/files";
import ConfirmDialog, { ConfirmDialogRef } from "../components/confirm-dialog";
import { useDownloadFileFromURL } from "./useDownloadFile";
import { UserPayload } from "../graphql/types/models/auth";
import { UserRoles } from "../models/team-member";
import DocumentsHeader from "../containers/documents/documents-header";
import { CopyAction, useDocumentsCopy } from "./useDocumentsCopy";
import { imageTypes, useImageViewer } from "./useImageViewer";
import PdfViewerModal, {
  PdfViewerModalRef,
} from "../components/documents/pdf-viewer-modal";

type UseDocumentsType = {
  id?: string;
  systemFolderType: SystemFolderType;
  user: UserPayload | null;
  route: string;
  setAccessPermissions?: boolean;
};

type Params = {
  folderId: string;
};

export function useDocuments(props: UseDocumentsType) {
  const {
    id,
    systemFolderType,
    user,
    route,
    setAccessPermissions = false,
  } = props;
  const uploadRef = React.useRef<UploadDocumentModalRef>(null);
  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const pdfViewerModalRef = React.useRef<PdfViewerModalRef>(null);
  const [files, setFiles] = React.useState<File[]>([]);
  const [currentDirectory, setCurrentDirectory] = React.useState<
    CurrentDirectoryWithId
  >({
    _id: "",
    subFolders: [],
    documents: [],
  });
  const [selectedItemsToDelete, setSelectedItemsToDelete] = React.useState<
    DocumentsTable[]
  >([]);
  const [deleteTarget, setDeleteTarget] = React.useState<DocumentsTable | null>(
    null
  );
  const [
    editModalAction,
    setEditModalAction,
  ] = React.useState<ModalAction | null>(null);
  const [editDocument, setEditDocument] = React.useState<DocumentsTable | null>(
    null
  );
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<Params>();
  const { downloadFileFromURL } = useDownloadFileFromURL(imageTypes);
  const sectionRoute = route.toLowerCase();
  const isBasicUser = React.useMemo(() => user?.role === UserRoles.basic, [
    user,
  ]);
  const isAdminUser = React.useMemo(
    () => user?.role === UserRoles.builderadmin,
    [user]
  );

  const { openImageViewer, renderGallery } = useImageViewer();

  const [addFile] = useMutation<CreateUpdateDocumentResponse>(
    CREATE_UPDATE_DOCUMENT,
    {
      onCompleted: async (data) => {
        if (
          files &&
          files.length > 0 &&
          data.createUpdateDocuments.length > 0
        ) {
          await uploadFiles(
            data.createUpdateDocuments.map((document) => ({
              name: document.name,
              upload_url: document.upload_url,
            })),
            files,
            uploadRef.current?.uploadProgress
          );
        }
        uploadRef.current?.show(false);
        uploadRef.current?.showUploading(false);
        notify({
          title: t("documents.addDocuments"),
          content: t("documents.success.addDocuments"),
        });
      },
      onError: () => {
        uploadRef.current?.show(false);
        uploadRef.current?.showUploading(false);
        notify({
          error: true,
          title: t("documents.addDocuments"),
          content: t("documents.error.addDocuments"),
        });
      },
    }
  );
  const handleAddFile = React.useCallback(
    async (
      files: File[],
      accessOptions?: {
        accessRole: EnumDocumentAccessRole;
        accessSelectedUsers: string[];
      }
    ) => {
      try {
        if (!files || !currentDirectory?._id || !id) return;
        uploadRef.current?.showUploading(true);
        setFiles(files);

        await addFile({
          variables: {
            documents: files.map((file) => ({
              folderId: currentDirectory?._id,
              name: file.name,
              size: file.size,
              type: file.type,
              accessRole: accessOptions?.accessRole,
              accessSelectedUsers: accessOptions?.accessSelectedUsers,
            })),
          },
          // update: !match.params.folderId
          //   ? handleDocumentAdd(FolderType.SYSTEM, {
          //       refId: id,
          //       systemType: systemFolderType,
          //     })
          //   : handleDocumentAdd(FolderType.FOLDER, {
          //       folderId: currentDirectory?._id,
          //     }),
        });
      } catch (e) {
        setFiles([]);
      }
    },
    [currentDirectory, match, id, uploadRef, systemFolderType]
  );

  const [
    getSystemFolder,
    {
      loading: systemFolderLoading,
      refetch: refetchRoot,
      data: systemFolderData,
    },
  ] = useLazyQuery<GetSystemFolderResponse>(GET_SYSTEM_FOLDER, {
    onCompleted: (data) => {
      setCurrentDirectory(data.getSystemFolder);
    },
    fetchPolicy: "cache-and-network",
    variables: {
      refId: id,
      systemType: systemFolderType,
    },
  });

  const [
    getFolder,
    { loading: folderLoading, data: folderData },
  ] = useLazyQuery<GetFolderResponse>(GET_FOLDER, {
    onCompleted: (data) => {
      setCurrentDirectory(data.getFolder);
    },
    onError: (error) => {
      const code = get(error, "graphQLErrors.0.extensions.code");
      if (code === "FOLDER_DOES_NOT_EXIST") {
        history.push(`/${sectionRoute}/${id}/documents`);
        refetchRoot && refetchRoot();
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [addUpdateFolder] = useMutation<CreateUpdateFolderResponse>(
    CREATE_UPDATE_FOLDER
  );

  const [updateFile] = useMutation<CreateUpdateDocumentResponse>(
    CREATE_UPDATE_DOCUMENT
  );

  React.useEffect(() => {
    setSelectedItemsToDelete([]);
  }, [currentDirectory]);

  React.useEffect(() => {
    if (match.params.folderId) {
      getFolder({
        variables: {
          folderId: match.params.folderId,
        },
      });
    } else {
      getSystemFolder();
    }
  }, [match]);

  const handleAddFolder = React.useCallback(
    async (data: CreateFolderPayload) => {
      try {
        if (!data.name || !id) {
          closeEditModal();
          return;
        }

        await addUpdateFolder({
          variables: {
            folder: {
              name: data.name,
              ...(currentDirectory?._id && {
                parentId: currentDirectory?._id,
              }),
              accessRole: data?.accessRole || EnumDocumentAccessRole.ALL,
              accessSelectedUsers: data?.accessSelectedUsers || [],
            },
          },
          update: !match.params.folderId
            ? handleFolderAdd(FolderType.SYSTEM, {
                refId: id,
                systemType: systemFolderType,
              })
            : handleFolderAdd(FolderType.FOLDER, {
                folderId: currentDirectory._id,
              }),
        });

        notify({
          title: t("documents.addFolder"),
          content: t("documents.success.addFolder"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("documents.addFolder"),
          content: t("documents.error.addFolder"),
        });
      }

      closeEditModal();
    },
    [currentDirectory, addUpdateFolder, match, id, systemFolderType]
  );

  const handleUpdateFile = React.useCallback(
    async (data: UpdateDocumentPayload) => {
      try {
        if (!data.name || !id) {
          closeEditModal();
          return;
        }

        await updateFile({
          variables: {
            documents: [
              {
                _id: editDocument?._id,
                folderId: currentDirectory?._id,
                name: data.name,
                accessRole: data?.accessRole,
                accessSelectedUsers: data?.accessSelectedUsers,
              },
            ],
          },
          update: !match.params.folderId
            ? handleDocumentUpdate(FolderType.SYSTEM, {
                refId: id,
                systemType: systemFolderType,
              })
            : handleDocumentUpdate(FolderType.FOLDER, {
                folderId: currentDirectory?._id,
              }),
        });

        notify({
          title: t("documents.updateDocuments"),
          content: t("documents.success.updateDocuments"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("documents.updateDocuments"),
          content: t("documents.error.updateDocuments"),
        });
      }

      closeEditModal();
    },
    [currentDirectory, editDocument, updateFile, match, id, systemFolderType]
  );

  const [deleteFolder, { loading: deletingFolder }] = useMutation<
    DeleteFolderResponse
  >(DELETE_FOLDER);

  const [deleteDocument, { loading: deletingDocument }] = useMutation<
    DeleteDocumentResponse
  >(DELETE_DOCUMENT);

  const selectFolder = React.useCallback(
    (folderId: string) => {
      history.push(`/${sectionRoute}/${id}/documents/${folderId}`);
    },
    [history, sectionRoute]
  );

  const selectRootFolders = React.useCallback(() => {
    history.push(`/${sectionRoute}/${id}/documents/`);
  }, [history, id, sectionRoute]);

  const openCreateFolderModal = React.useCallback(() => {
    setEditModalAction(ModalAction.CREATE_FOLDER);
  }, [editModalAction]);

  const openUpdateFolderModal = React.useCallback(
    (tableItem: DocumentsTable) => {
      setEditDocument(tableItem);
      setEditModalAction(ModalAction.EDIT_FOLDER);
    },
    [editModalAction]
  );

  const openUpdateFileModal = React.useCallback(
    (tableItem: DocumentsTable) => {
      setEditDocument(tableItem);
      setEditModalAction(ModalAction.EDIT_FILE);
    },
    [editModalAction]
  );

  const showUploadModal = React.useCallback(
    () => uploadRef.current?.show(true),
    [uploadRef]
  );

  const handleDeleteSelected = React.useCallback(async () => {
    try {
      if (!selectedItemsToDelete) {
        return;
      }

      await Promise.all(
        selectedItemsToDelete.map(async (item) => {
          if (!id) return;

          if (item.type === DocumentType.FOLDER) {
            await deleteFolder({
              variables: {
                folderId: item._id,
              },
              update: !match.params.folderId
                ? handleFolderDelete(FolderType.SYSTEM, {
                    refId: id,
                    systemType: systemFolderType,
                  })
                : handleFolderDelete(FolderType.FOLDER, {
                    folderId: currentDirectory?._id,
                  }),
            });
          }

          if (item.type === DocumentType.FILE) {
            if (!currentDirectory?._id) return;

            await deleteDocument({
              variables: {
                documentId: item._id,
              },
              update: handleDocumentDelete(FolderType.FOLDER, {
                folderId: currentDirectory?._id,
              }),
            });
          }
        })
      );

      notify({
        title: t("documents.removeDocuments"),
        content: t("documents.success.removeDocuments"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("documents.removeDocuments"),
        content: t("documents.error.removeDocuments"),
      });
    }
  }, [selectedItemsToDelete, currentDirectory, match, id, systemFolderType]);

  const closeEditModal = React.useCallback(() => {
    setEditModalAction(null);
    setEditDocument(null);
  }, [editModalAction]);

  const openRemoveDialog = React.useCallback(
    (row?: DocumentsTable) => {
      if (row) {
        setDeleteTarget(row);
        confirmRef?.current?.show(true);
      }
    },
    [confirmRef]
  );

  const openRemoveDialogForSelectedItems = React.useCallback(() => {
    confirmRef?.current?.show(true);
  }, [confirmRef]);

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget(null);
    confirmRef?.current?.show(false);
  }, [confirmRef]);

  const handleDocumentRemoveSubmit = React.useCallback(async () => {
    try {
      if (!deleteTarget || !id) {
        return;
      }

      if (deleteTarget.type === DocumentType.FOLDER) {
        await deleteFolder({
          variables: {
            folderId: deleteTarget?._id,
          },
          update: !match.params.folderId
            ? handleFolderDelete(FolderType.SYSTEM, {
                refId: id,
                systemType: systemFolderType,
              })
            : handleFolderDelete(FolderType.FOLDER, {
                folderId: currentDirectory?._id,
              }),
        });
      }

      if (deleteTarget.type === DocumentType.FILE) {
        if (!currentDirectory?._id) return;

        await deleteDocument({
          variables: {
            documentId: deleteTarget?._id,
          },
          update: handleDocumentDelete(FolderType.FOLDER, {
            folderId: currentDirectory?._id,
          }),
        });
      }

      notify({
        title: t("documents.removeDocuments"),
        content: t("documents.success.removeDocuments"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("documents.removeDocuments"),
        content: t("documents.error.removeDocuments"),
      });
    }

    closeRemoveDialog();
  }, [deleteTarget, currentDirectory, match, id, systemFolderType]);

  const addSelectedItemsToDelete = (items: DocumentsTable[]) => {
    setSelectedItemsToDelete(items);
  };

  const getSubmitFunction = React.useCallback(() => {
    switch (editModalAction) {
      case ModalAction.CREATE_FOLDER:
        return handleAddFolder;
      case ModalAction.EDIT_FILE:
        return handleUpdateFile;
      case ModalAction.EDIT_FOLDER:
        return handleUpdateFolder;
      default:
        return handleAddFolder;
    }
  }, [editModalAction, editDocument]);

  const handleUpdateFolder = React.useCallback(
    async (data: CreateFolderPayload) => {
      try {
        if (!data.name || !editDocument?._id || !id) {
          closeEditModal();
          return;
        }

        await addUpdateFolder({
          variables: {
            folder: {
              _id: editDocument?._id,
              name: data.name,
              accessRole: data?.accessRole || EnumDocumentAccessRole.ALL,
              accessSelectedUsers: data?.accessSelectedUsers || [],
            },
          },
          update: !match.params.folderId
            ? handleFolderUpdate(FolderType.SYSTEM, {
                refId: id,
                systemType: systemFolderType,
              })
            : handleFolderUpdate(FolderType.FOLDER, {
                folderId: editDocument?._id,
              }),
        });

        notify({
          title: t("documents.updateFolder"),
          content: t("documents.success.updateFolder"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("documents.updateFolder"),
          content: t("documents.error.updateFolder"),
        });
      }

      closeEditModal();
    },
    [editDocument, addUpdateFolder, match, id, systemFolderType]
  );

  const { handleCopyAction, renderSelectFolderModal } = useDocumentsCopy({
    startFolderId: currentDirectory._id,
  });

  const handleOnHeaderCopy = React.useCallback(() => {
    handleCopyAction(currentDirectory._id, CopyAction.COPY_FOLDER);
  }, [currentDirectory, handleCopyAction]);

  const isDisabledHeaderCopyContent = React.useMemo(
    () =>
      !(
        Boolean(currentDirectory.subFolders.length) ||
        Boolean(currentDirectory.documents.length)
      ),
    [currentDirectory.documents.length, currentDirectory.subFolders.length]
  );

  const parentFolderPermissions = React.useMemo(() => {
    if (currentDirectory?.parentTree) {
      return currentDirectory?.parentTree[
        currentDirectory?.parentTree?.length - 1
      ];
    }
  }, [currentDirectory]);

  const handleOnEdit = React.useCallback(() => {
    if (currentDirectory?.parentTree) {
      const parentFolder =
        currentDirectory?.parentTree[currentDirectory?.parentTree?.length - 1];
      openUpdateFolderModal({
        ...parentFolder,
        size: null,
        type: DocumentType.FOLDER,
      });
    }
  }, [currentDirectory, openUpdateFolderModal]);

  const handleDownloadFile = React.useCallback(
    async (fileId: string) => {
      const file = currentDirectory.documents.find((doc) => doc._id === fileId);
      if (file) {
        const type = file.type;
        switch (type) {
          case "image/jpeg":
          case "image/jpg":
          case "image/png":
          case "image/webp":
            const imageData = await downloadFileFromURL(fileId);
            openImageViewer({
              url: imageData.data.getDocumentDownloadURL.url,
              name: imageData.data.getDocumentDownloadURL.name,
            });
            break;
          case "application/pdf":
            const res = await downloadFileFromURL(fileId);
            pdfViewerModalRef.current?.show(true, {
              url: res.data.getDocumentDownloadURL.url,
              name: res.data.getDocumentDownloadURL.name,
            });

            break;

          default:
            downloadFileFromURL(fileId);
            break;
        }
      }
    },
    [currentDirectory, downloadFileFromURL, openImageViewer]
  );

  const renderCreateUpdateDocumentModal = () => {
    return (
      <CreateUpdateDocumentModal
        modalAction={editModalAction}
        show={!!editModalAction}
        onSubmit={getSubmitFunction()}
        onClose={closeEditModal}
        editDocument={editDocument}
        setAccessPermissions={isAdminUser ? setAccessPermissions : undefined}
        parentFolderPermissions={parentFolderPermissions}
      />
    );
  };

  const renderDocumentsTableComponent = () => {
    return (
      <>
        {isAdminUser && renderSelectFolderModal()}
        {renderGallery()}
        <PdfViewerModal ref={pdfViewerModalRef} />
        <DocumentsTableComponent
          folders={currentDirectory.subFolders}
          documents={currentDirectory.documents}
          tableLoading={systemFolderLoading || folderLoading}
          selectFolder={selectFolder}
          isRootFolder={!currentDirectory.parentTree}
          handleEditFolderName={openUpdateFolderModal}
          handleEditFileName={openUpdateFileModal}
          onUploadClick={showUploadModal}
          onAddNewFolderClick={openCreateFolderModal}
          selectedItemsToDelete={selectedItemsToDelete}
          addSelectedItemsToDelete={addSelectedItemsToDelete}
          openRemoveDialog={openRemoveDialog}
          handleDownloadFile={handleDownloadFile}
          isReadonly={isBasicUser}
          onCopyAction={isAdminUser ? handleCopyAction : undefined}
          setAccessPermissions={isAdminUser ? setAccessPermissions : undefined}
        />
      </>
    );
  };

  const renderUploadDocumentModal = () => {
    return (
      <UploadDocumentModal
        ref={uploadRef}
        onUpload={handleAddFile}
        setAccessPermissions={setAccessPermissions}
        parentFolderPermissions={parentFolderPermissions}
      />
    );
  };

  const renderConfirmDialog = () => {
    return (
      <ConfirmDialog
        disabled={deletingFolder || deletingDocument}
        ref={confirmRef}
        onClose={closeRemoveDialog}
        onSubmit={
          deleteTarget ? handleDocumentRemoveSubmit : handleDeleteSelected
        }
        title={t("documents.removeDocuments")}
        confirm={t("common.delete")}
      >
        <div className="field-text">{t("documents.removeMessage")}</div>
      </ConfirmDialog>
    );
  };

  const renderDocumentModals = () => {
    return (
      <>
        {renderUploadDocumentModal()}
        {renderCreateUpdateDocumentModal()}
        {renderConfirmDialog()}
      </>
    );
  };

  const renderDocumentsHeader = () => {
    return (
      <DocumentsHeader
        onUploadClick={showUploadModal}
        selectFolder={selectFolder}
        selectRootFolders={selectRootFolders}
        onAddNewFolderClick={openCreateFolderModal}
        parentTree={
          currentDirectory?.parentTree
            ? currentDirectory?.parentTree.filter((_item, index) => index !== 0)
            : []
        }
        rootName={
          currentDirectory &&
          currentDirectory?.parentTree &&
          currentDirectory?.parentTree.length > 0
            ? currentDirectory?.parentTree[0].name
            : ""
        }
        openRemoveDialogForSelectedItems={openRemoveDialogForSelectedItems}
        quantityOfSelectedItems={
          selectedItemsToDelete ? selectedItemsToDelete.length : 0
        }
        isReadonly={isBasicUser}
        onCopyContent={
          currentDirectory?._id && isAdminUser ? handleOnHeaderCopy : undefined
        }
        onEditFolder={isAdminUser ? handleOnEdit : undefined}
        isDisabledHeaderCopyContent={isDisabledHeaderCopyContent}
      />
    );
  };

  return {
    renderDocumentModals,
    renderDocumentsTableComponent,
    renderDocumentsHeader,
  };
}
