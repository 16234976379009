import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { useQuery } from "@apollo/client";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import ClientJobDiscussion from "../job-discussion";
import ClientJobProgressClaims from "../job-progress-claims";
import ClientJobQuoteRequest from "../job-quote-request";
import ClientJobQuoteRequestDetails from "../job-quote-request-details";
import ClientJobVariations from "../job-variations";
import ClientJobSiteDiaries from "../job-site-diaries";
import { ClientJobResponse } from "../../../../graphql/types/models/job";
import { GET_CLIENT_JOB } from "../../../../graphql/queries/client/job/queries";

type Params = {
  id: string;
};

type JobsContainerProps = RouteComponentProps<Params> & DashboardContextValue;

const ClientJobsContainer: React.FC<JobsContainerProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { id: jobId } = match.params;

  const {
    data: clientJobResponse,
    loading: clientJobResponseLoading,
  } = useQuery<ClientJobResponse>(GET_CLIENT_JOB, { variables: { jobId } });

  React.useEffect(() => {
    setNavigationContext({
      ...navigationContext,
      clientJob: {
        _id: jobId,
        name: clientJobResponse?.getClientJob.name || "",
        jobNumber: clientJobResponse?.getClientJob.jobNumber || 0,
      },
    });
  }, [setNavigationContext, jobId, clientJobResponse]);

  React.useEffect(() => {
    return () => {
      setNavigationContext({
        ...navigationContext,
        clientJob: undefined,
      });
    };
  }, []);

  if (clientJobResponseLoading) {
    return null;
  }

  if (!clientJobResponse?.getClientJob && !clientJobResponseLoading) {
    return <Redirect to="/projects" />;
  }

  if (!navigationContext?.clientJob) return null;

  return (
    <Switch>
      <Route path="/projects/:id/discussion" component={ClientJobDiscussion} />
      <Route
        path="/projects/:id/progress-claims"
        component={ClientJobProgressClaims}
      />
      <Route
        exact
        path="/projects/:id/quote-requests"
        component={ClientJobQuoteRequest}
      />
      <Route
        path="/projects/:id/quote-requests/:quote_id/sub/:sub_id"
        component={ClientJobQuoteRequestDetails}
      />
      <Route
        exact
        path="/projects/:id/variations"
        component={ClientJobVariations}
      />
      <Route
        exact
        path="/projects/:id/site-diary"
        component={ClientJobSiteDiaries}
      />
    </Switch>
  );
};

export default withDashboardContext(ClientJobsContainer);
