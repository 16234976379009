import { gql } from "@apollo/client";

export const ROSTER_ITEM_FRAGMENT = gql`
  fragment RosterItemFragment on RosterItem {
    _id
    rounding
    wastage
    UOM
    cost
    quantity
    name
    costingCategoryName
    costingItemId
    note
    roster {
      _id
      rosterNumber
      status
      date
      job {
        _id
      }
    }
  }
`;

export const ROSTER_FRAGMENT = gql`
  fragment RosterData on Roster {
    _id
    rosterNumber
    date
    reference
    status
    updatedAt
    createdAt
    internalNote
    items {
      ...RosterItemFragment
    }
    job {
      _id
    }
  }
  ${ROSTER_ITEM_FRAGMENT}
`;
