import { GenericFormFields } from "../generic-form/GenericFormBody";
import { SelectOption } from "../generic-form/inputs/select";

export const createTagsField = <TItem>(
  tags: SelectOption[],
  onTagsCreate: (value: string) => void
): GenericFormFields<TItem> => {
  return {
    tags: {
      type: "creatable-select",
      valueKey: "tags",
      creatableProps: {
        autoSelectCreatedOption: true,
        onCreateOption: onTagsCreate,
        isMulti: true,
        options: tags,
      },
      inputProps: {
        className: "input-style",
      },
    },
  };
};
