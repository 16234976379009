import React, { useState } from "react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { createCategoryItemField } from "./utils";
import {
  FormikPropGetSetValues,
  GenericFormFields,
} from "../../generic-form/GenericFormBody";
import {
  SelectionCategoryItemOption,
  SelectionCategoryItemOptionInput,
} from "../../../graphql/types/models/selections";
import { UOMOption } from "../../../utils/types/options";
import { useUOM } from "../../../hooks/useUOM";
import createItemOptionSchema from "./CreateItemOptionModal.schema";
import { Col, Row } from "react-bootstrap";
import { omit } from "lodash";
import { UploadUrl } from "../../../utils/files";
import { getMediaInput } from "../../../utils/transform";
import { useAttachment } from "../../modals/send-modal/hooks/useAttachment";
import FormField from "../../generic-form/form-field";
import UpdateEntityModal from "../../modals/update-entity";
import CreateEntityModal from "../../modals/create-entity";
import {
  findPriceItemFromCache,
  searchPriceItem,
} from "../../../graphql/queries/price-list/utils";
import { useApolloClient } from "@apollo/client";

type CreateItemOptionModalProps = {
  data?: SelectionCategoryItemOption | null;
  show: boolean;
  onSubmit: (
    data: SelectionCategoryItemOptionInput,
    cb: (urls: UploadUrl[]) => Promise<void>
  ) => void;
  onClose: () => void;
  uoms: UOMOption[];
};

const CreateItemOptionModal: React.FC<CreateItemOptionModalProps> = (props) => {
  const { data, show, onSubmit, onClose, uoms: currentUOMs } = props;
  const { t } = useTranslation();
  const client = useApolloClient();

  const [fields, setFields] = useState<
    GenericFormFields<SelectionCategoryItemOptionInput>
  >({});

  const UOMvalues = React.useMemo(() => {
    return currentUOMs
      .map((item) => ({ UOM: item.value }))
      .filter((item) => Boolean(item.UOM));
  }, [currentUOMs]);

  const { uoms, handleUOMCreate } = useUOM(UOMvalues);

  const handlePriceSearch = React.useCallback(
    async (value: string) => await searchPriceItem(client, value),
    [client]
  );

  const handleNameChange = React.useCallback(
    async (value: string, formikProps: FormikPropGetSetValues) => {
      const priceItem = findPriceItemFromCache(client, value);

      if (priceItem) {
        const hasUom = uoms.some((uom) => uom.value === priceItem.UOM);
        if (!hasUom) {
          handleUOMCreate(priceItem.UOM);
        }
        const { setFieldValue } = formikProps;
        setFieldValue("name", priceItem.name);
        setFieldValue("UOM", priceItem.UOM);
        setFieldValue("cost", priceItem.cost);
      }
    },
    [client, handleUOMCreate, uoms]
  );

  React.useEffect(() => {
    setFields(
      createCategoryItemField(
        t,
        uoms,
        handleUOMCreate,
        handleNameChange,
        handlePriceSearch
      )
    );
  }, [uoms]);

  React.useEffect(() => {
    if (data?.attachments.length) {
      const files = data?.attachments.map((file) => ({
        ...(omit(file, ["__typename"]) as File),
      }));
      setAttachments(files);
    }
  }, [data]);

  const {
    attachments,
    openFilePicker,
    renderAttachments,
    renderDropzone,
    setAttachments,
    uploadAttachments,
    clearAttachments,
  } = useAttachment();

  const renderAttach = React.useCallback(() => {
    return (
      <Row>
        <Col className="form-column pl-4 pr-4" sm={12}>
          <FormField name="attachments" label={t("common.attachments")}>
            {renderDropzone()}
          </FormField>
          {renderAttachments(true)}
        </Col>
      </Row>
    );
  }, [openFilePicker, renderAttachments]);

  const handleSubmit = React.useCallback(
    (data: SelectionCategoryItemOptionInput, helpers?: FormikHelpers<any>) => {
      if (attachments.length) {
        const files = getMediaInput(attachments);
        onSubmit({ ...data, attachments: files }, uploadAttachments);
      } else {
        onSubmit(data, uploadAttachments);
      }
      helpers?.resetForm();
      clearAttachments();
    },
    [attachments, clearAttachments, onSubmit, uploadAttachments]
  );

  const handleClose = React.useCallback(() => {
    onClose();
    clearAttachments();
  }, [onClose]);

  return (
    <>
      {data ? (
        <UpdateEntityModal<SelectionCategoryItemOptionInput>
          validationSchema={createItemOptionSchema(t)}
          title={t("selections.editItemOption")}
          data={{
            name: data.name || "",
            description: data.description || "",
            note: data.note || "",
            cost: data.cost || "",
            quantity: data.quantity || "",
            UOM: data.UOM || "",
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={handleClose}
          endRenderer={renderAttach()}
        />
      ) : (
        <CreateEntityModal<SelectionCategoryItemOptionInput>
          validationSchema={createItemOptionSchema(t)}
          title={t("selections.createItemOption")}
          data={{
            name: "",
            description: "",
            note: "",
            cost: "",
            quantity: "",
            UOM: "",
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={handleClose}
          endRenderer={renderAttach()}
        />
      )}
    </>
  );
};

export default CreateItemOptionModal;
