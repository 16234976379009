import { gql } from "@apollo/client";

export const TIMESHEET_REQUEST_FRAGMENT = gql`
  fragment TimesheetRequest on Timesheet {
    _id
    task
    note
    job {
      _id
      name
      address
    }
    startTime
    endTime
    is_processed
    rate
    breakDuration
    costingCategoryName
    costingItemId
    user {
      _id
      hourly_rate
      business {
        _id
        name
      }
      first_name
      last_name
      fullName
      email
      country
      updatedAt
      createdAt
    }
  }
`;
