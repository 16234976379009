import { TFunction } from "i18next";
import { map } from "lodash";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import { STATES_OPTIONS } from "../../../../utils/options";
import { Business } from "../../../../graphql/types/models/business";

const createInvoiceFields = (t: TFunction): GenericFormFields<Business> => {
  return {
    bankName: [
      {
        type: "text",
        label: t("company.bankName"),
        placeholder: t("company.bankName"),
        valueKey: "bankName",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("company.accountName"),
        placeholder: t("company.accountName"),
        valueKey: "accountName",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    account: [
      {
        type: "text",
        label: t("company.BSBNumber"),
        placeholder: t("company.BSBNumber"),
        valueKey: "BSB",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("company.accountNumber"),
        placeholder: t("company.accountNumber"),
        valueKey: "accountNumber",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    defaultDueDays: {
      type: "text",
      label: t("company.dueDate"),
      placeholder: t("company.dueDate"),
      valueKey: "defaultDueDays",
      textInputProps: {
        // onValueChange: onPlaceSelect,
      },
      inputProps: {
        type: "number",
        required: true,
      },
    },

    paymentTerms: [
      {
        type: "text",
        controlType: "textarea",
        label: t("company.paymentTerms"),
        placeholder: t("company.additionalPaymentTerms"),
        valueKey: "paymentTerms",
        inputProps: {
          rows: 3,
          type: "text",
          required: false,
        },
      },
    ],
  };
};

export default createInvoiceFields;
