import React from "react";
import Icon from "../../../../../icons/Icon";

type FullscreenProps = {
  isFullscreen?: boolean;
  onToggle: () => void;
};

const Fullscreen: React.FC<FullscreenProps> = (props) => {
  const { onToggle, isFullscreen } = props;

  return (
    <div className="rdw-fullscreen-wrapper fullscreen-tool" onClick={onToggle}>
      <span className="rdw-option-wrapper">
        <Icon name={isFullscreen ? "close_fullscreen" : "fullscreen"} />
      </span>
    </div>
  );
};

export default Fullscreen;
