import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { ChangePasswordPayload } from "../../../models/profile";

const createChangePwdFields = (
  t: TFunction
): GenericFormFields<ChangePasswordPayload> => {
  return {
    old_password: {
      type: "text",
      label: t("profile.currentPassword"),
      placeholder: t("placeholders.currentPwd"),
      valueKey: "old_password",
      inputProps: {
        type: "password",
        required: true,
      },
    },
    new_password: {
      type: "text",
      label: t("profile.newPassword"),
      placeholder: t("placeholders.newPwd"),
      valueKey: "new_password",
      inputProps: {
        type: "password",
        required: true,
      },
    },
    confirm_new_password: {
      type: "text",
      label: t("profile.confirmNewPassword"),
      placeholder: t("placeholders.confirmPwd"),
      valueKey: "confirm_new_password",
      inputProps: {
        type: "password",
        required: true,
      },
    },
  };
};

export default createChangePwdFields;
