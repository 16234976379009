import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useLazyQuery, useMutation } from "@apollo/client";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { get } from "lodash";
import { connect } from "react-redux";

import { uploadFiles } from "../../../utils/files";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import UploadDocumentModal, {
  UploadDocumentModalRef,
} from "../../../components/documents-main/upload-files-modal";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardCard from "../../../components/dashboard/card";
import DocumentsHeader from "../documents-header";
import DocumentsTableComponent, { DocumentType } from "../documents-table";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import type { DocumentsTable } from "../documents-table";
import {
  LIST_FOLDERS,
  GET_FOLDER,
} from "../../../graphql/queries/documents/queries";
import {
  CREATE_UPDATE_FOLDER,
  CREATE_UPDATE_DOCUMENT,
  DELETE_FOLDER,
  DELETE_DOCUMENT,
} from "../../../graphql/queries/documents/mutations";
import {
  handleFolderAdd,
  handleFolderUpdate,
  handleDocumentUpdate,
  handleDocumentAdd,
  handleFolderDelete,
  handleDocumentDelete,
  FolderType,
} from "../../../graphql/queries/documents/utils";
import {
  ListFoldersResponse,
  GetFolderResponse,
  CreateUpdateFolderResponse,
  CreateUpdateDocumentResponse,
  DeleteFolderResponse,
  DeleteDocumentResponse,
} from "../../../graphql/types/models/documents";
import {
  CurrentDirectory,
  CreateFolderPayload,
  UpdateDocumentPayload,
  EnumDocumentAccessRole,
} from "../../../models/documents";
import CreateUpdateDocumentModal, {
  ModalAction,
} from "../../../components/documents-main/create-update-modal";
import { notify } from "../../../components/notification";
import { useDownloadFileFromURL } from "../../../hooks/useDownloadFile";
import { CopyAction, useDocumentsCopy } from "../../../hooks/useDocumentsCopy";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import "./styles.scss";
import PdfViewerModal, {
  PdfViewerModalRef,
} from "../../../components/documents/pdf-viewer-modal";
import { imageTypes, useImageViewer } from "../../../hooks/useImageViewer";

type Params = {
  id: string;
};
type DocumentsContainerProps = RouteComponentProps<Params> &
  DashboardContextValue & {
    user: UserPayload | null;
  };

const DocumentsContainer: React.FC<DocumentsContainerProps> = ({
  match,
  user,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { openImageViewer, renderGallery } = useImageViewer();

  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const uploadRef = React.useRef<UploadDocumentModalRef>(null);
  const pdfViewerModalRef = React.useRef<PdfViewerModalRef>(null);

  const { downloadFileFromURL } = useDownloadFileFromURL(imageTypes);

  const [currentDirectory, setCurrentDirectory] = React.useState<
    CurrentDirectory
  >({
    subFolders: [],
    documents: [],
  });
  const [
    editModalAction,
    setEditModalAction,
  ] = React.useState<ModalAction | null>(null);
  const [editDocument, setEditDocument] = React.useState<DocumentsTable | null>(
    null
  );
  const [files, setFiles] = React.useState<File[]>([]);
  const [selectedItemsToDelete, setSelectedItemsToDelete] = React.useState<
    DocumentsTable[]
  >([]);
  const [deleteTarget, setDeleteTarget] = React.useState<DocumentsTable | null>(
    null
  );
  const isAdminUser = React.useMemo(
    () => user?.role === UserRoles.builderadmin,
    [user]
  );

  const showUploadModal = React.useCallback(
    () => uploadRef.current?.show(true),
    [uploadRef]
  );

  const [
    getRootFolders,
    { loading: foldersLoading, refetch: refetchRoot },
  ] = useLazyQuery<ListFoldersResponse>(LIST_FOLDERS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setCurrentDirectory({
        subFolders: data.listFolders || [],
        documents: [],
      });
    },
  });

  const [getFolder, { loading: folderLoading }] = useLazyQuery<
    GetFolderResponse
  >(GET_FOLDER, {
    onCompleted: (data) => {
      setCurrentDirectory(data.getFolder);
    },
    onError: (error) => {
      const code = get(error, "graphQLErrors.0.extensions.code");
      if (code === "FOLDER_DOES_NOT_EXIST") {
        history.push("/documents");
        refetchRoot && refetchRoot();
      }
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    setSelectedItemsToDelete([]);
  }, [currentDirectory]);

  React.useEffect(() => {
    if (match.params.id) {
      getFolder({
        variables: {
          folderId: match.params.id,
        },
      });
    } else {
      getRootFolders();
    }
  }, [match]);

  const selectFolder = React.useCallback(
    (id: string) => {
      history.push(`/documents/${id}`);
    },
    [history]
  );

  const selectRootFolders = React.useCallback(() => {
    history.push(`/documents/`);
  }, [history]);

  const [addUpdateFolder] = useMutation<CreateUpdateFolderResponse>(
    CREATE_UPDATE_FOLDER
  );

  const [updateFile] = useMutation<CreateUpdateDocumentResponse>(
    CREATE_UPDATE_DOCUMENT
  );

  const [addFile] = useMutation<CreateUpdateDocumentResponse>(
    CREATE_UPDATE_DOCUMENT,
    {
      onCompleted: async (data) => {
        if (
          files &&
          files.length > 0 &&
          data.createUpdateDocuments.length > 0
        ) {
          await uploadFiles(
            data.createUpdateDocuments.map((document) => ({
              name: document.name,
              upload_url: document.upload_url,
            })),
            files,
            uploadRef.current?.uploadProgress
          );
        }
        uploadRef.current?.show(false);
        uploadRef.current?.showUploading(false);
        notify({
          title: t("documents.addDocuments"),
          content: t("documents.success.addDocuments"),
        });
      },
      onError: () => {
        uploadRef.current?.showUploading(false);
        uploadRef.current?.show(false);
        notify({
          error: true,
          title: t("documents.addDocuments"),
          content: t("documents.error.addDocuments"),
        });
      },
    }
  );

  const openCreateFolderModal = React.useCallback(() => {
    setEditModalAction(ModalAction.CREATE_FOLDER);
  }, [editModalAction]);

  const openUpdateFolderModal = React.useCallback(
    (tableItem: DocumentsTable) => {
      setEditDocument(tableItem);
      setEditModalAction(ModalAction.EDIT_FOLDER);
    },
    [editModalAction]
  );

  const openUpdateFileModal = React.useCallback(
    (tableItem: DocumentsTable) => {
      setEditDocument(tableItem);
      setEditModalAction(ModalAction.EDIT_FILE);
    },
    [editModalAction]
  );

  const closeEditModal = React.useCallback(() => {
    setEditModalAction(null);
    setEditDocument(null);
  }, [editModalAction]);

  const handleAddFolder = React.useCallback(
    async (data: CreateFolderPayload) => {
      try {
        if (!data.name) {
          closeEditModal();
          return;
        }

        await addUpdateFolder({
          variables: {
            folder: {
              name: data.name,
              ...(currentDirectory?._id && {
                parentId: currentDirectory?._id,
              }),
              accessRole: data?.accessRole || EnumDocumentAccessRole.ALL,
              accessSelectedUsers: data?.accessSelectedUsers || [],
            },
          },
          update: currentDirectory?._id
            ? handleFolderAdd(FolderType.FOLDER, {
                folderId: currentDirectory?._id,
              })
            : handleFolderAdd(FolderType.ROOT),
        });

        notify({
          title: t("documents.addFolder"),
          content: t("documents.success.addFolder"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("documents.addFolder"),
          content: t("documents.error.addFolder"),
        });
      }

      closeEditModal();
    },
    [currentDirectory, addUpdateFolder]
  );

  const handleUpdateFolder = React.useCallback(
    async (data: CreateFolderPayload) => {
      try {
        if (!data.name) {
          closeEditModal();
          return;
        }

        await addUpdateFolder({
          variables: {
            folder: {
              _id: editDocument?._id,
              name: data.name,
              accessRole: data?.accessRole || EnumDocumentAccessRole.ALL,
              accessSelectedUsers: data?.accessSelectedUsers || [],
            },
          },
          update: currentDirectory?._id
            ? handleFolderUpdate(FolderType.FOLDER, {
                folderId: currentDirectory?._id,
              })
            : handleFolderUpdate(FolderType.ROOT),
        });

        notify({
          title: t("documents.updateFolder"),
          content: t("documents.success.updateFolder"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("documents.updateFolder"),
          content: t("documents.error.updateFolder"),
        });
      }

      closeEditModal();
    },
    [currentDirectory, editDocument, addUpdateFolder]
  );

  const handleUpdateFile = React.useCallback(
    async (data: UpdateDocumentPayload) => {
      try {
        if (!data.name || !currentDirectory?._id) {
          closeEditModal();
          return;
        }

        await updateFile({
          variables: {
            documents: [
              {
                _id: editDocument?._id,
                folderId: currentDirectory?._id,
                name: data.name,
                accessRole: data?.accessRole,
                accessSelectedUsers: data?.accessSelectedUsers,
              },
            ],
          },
          update: handleDocumentUpdate(FolderType.FOLDER, {
            folderId: currentDirectory?._id,
          }),
        });

        notify({
          title: t("documents.updateDocuments"),
          content: t("documents.success.updateDocuments"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("documents.updateDocuments"),
          content: t("documents.error.updateDocuments"),
        });
      }

      closeEditModal();
    },
    [currentDirectory, editDocument, updateFile]
  );

  const handleAddFile = React.useCallback(
    async (
      files: File[],
      accessOptions?: {
        accessRole: EnumDocumentAccessRole;
        accessSelectedUsers: string[];
      }
    ) => {
      try {
        if (!files || !currentDirectory?._id) {
          uploadRef.current?.show(false);
          return;
        }
        uploadRef.current?.showUploading(true);
        setFiles(files);

        await addFile({
          variables: {
            documents: files.map((file) => ({
              folderId: currentDirectory?._id,
              name: file.name,
              size: file.size,
              type: file.type,
              accessRole: accessOptions?.accessRole,
              accessSelectedUsers: accessOptions?.accessSelectedUsers,
            })),
          },
          // update: handleDocumentAdd(FolderType.FOLDER, {
          //   folderId: currentDirectory?._id,
          // }),
        });
      } catch (e) {
        setFiles([]);
      }
    },
    [currentDirectory, addFile, uploadRef]
  );

  const openRemoveDialog = React.useCallback(
    (row?: DocumentsTable) => {
      if (row) {
        setDeleteTarget(row);
        confirmRef?.current?.show(true);
      }
    },
    [confirmRef]
  );

  const openRemoveDialogForSelectedItems = React.useCallback(() => {
    confirmRef?.current?.show(true);
  }, [confirmRef]);

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget(null);
    confirmRef?.current?.show(false);
  }, [confirmRef]);

  const [deleteFolder, { loading: deletingFolder }] = useMutation<
    DeleteFolderResponse
  >(DELETE_FOLDER);

  const [deleteDocument, { loading: deletingDocument }] = useMutation<
    DeleteDocumentResponse
  >(DELETE_DOCUMENT);

  const handleDeleteSelected = React.useCallback(async () => {
    try {
      if (!selectedItemsToDelete) {
        return;
      }

      await Promise.all(
        selectedItemsToDelete.map(async (item) => {
          if (item.type === DocumentType.FOLDER) {
            await deleteFolder({
              variables: {
                folderId: item._id,
              },
              update: currentDirectory?._id
                ? handleFolderDelete(FolderType.FOLDER, {
                    folderId: currentDirectory?._id,
                  })
                : handleFolderDelete(FolderType.ROOT),
            });
          }

          if (item.type === DocumentType.FILE) {
            if (!currentDirectory?._id) return;

            await deleteDocument({
              variables: {
                documentId: item._id,
              },
              update: handleDocumentDelete(FolderType.FOLDER, {
                folderId: currentDirectory?._id,
              }),
            });
          }
        })
      );

      notify({
        title: t("documents.removeDocuments"),
        content: t("documents.success.removeDocuments"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("documents.removeDocuments"),
        content: get(e, "message", t("documents.error.removeDocuments")),
      });
    }
  }, [selectedItemsToDelete, currentDirectory]);

  const handleDocumentRemoveSubmit = React.useCallback(async () => {
    try {
      if (!deleteTarget) {
        return;
      }

      if (deleteTarget.type === DocumentType.FOLDER) {
        await deleteFolder({
          variables: {
            folderId: deleteTarget?._id,
          },
          update: currentDirectory?._id
            ? handleFolderDelete(FolderType.FOLDER, {
                folderId: currentDirectory?._id,
              })
            : handleFolderDelete(FolderType.ROOT),
        });
      }

      if (deleteTarget.type === DocumentType.FILE) {
        if (!currentDirectory?._id) return;

        await deleteDocument({
          variables: {
            documentId: deleteTarget?._id,
          },
          update: handleDocumentDelete(FolderType.FOLDER, {
            folderId: currentDirectory?._id,
          }),
        });
      }

      notify({
        title: t("documents.removeDocuments"),
        content: t("documents.success.removeDocuments"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("documents.removeDocuments"),
        content: t("documents.error.removeDocuments"),
      });
    }

    closeRemoveDialog();
  }, [deleteTarget, currentDirectory]);

  const getSubmitFunction = React.useCallback(() => {
    switch (editModalAction) {
      case ModalAction.CREATE_FOLDER:
        return handleAddFolder;
      case ModalAction.EDIT_FILE:
        return handleUpdateFile;
      case ModalAction.EDIT_FOLDER:
        return handleUpdateFolder;
      default:
        return handleAddFolder;
    }
  }, [editModalAction, editDocument]);

  const addSelectedItemsToDelete = (items: DocumentsTable[]) => {
    setSelectedItemsToDelete(items);
  };

  const { handleCopyAction, renderSelectFolderModal } = useDocumentsCopy({
    startFolderId: currentDirectory._id,
  });

  const handleOnHeaderCopy = React.useCallback(() => {
    handleCopyAction(match?.params?.id, CopyAction.COPY_FOLDER);
  }, [handleCopyAction, match.params.id]);

  const handleOnEdit = React.useCallback(() => {
    if (currentDirectory?.parentTree) {
      const parentFolder =
        currentDirectory?.parentTree[currentDirectory?.parentTree?.length - 1];
      openUpdateFolderModal({
        ...parentFolder,
        size: null,
        type: DocumentType.FOLDER,
      });
    }
  }, [currentDirectory, openUpdateFolderModal]);

  const isDisabledHeaderCopyContent = React.useMemo(
    () =>
      !(
        Boolean(currentDirectory.subFolders.length) ||
        Boolean(currentDirectory.documents.length)
      ),
    [currentDirectory.documents.length, currentDirectory.subFolders.length]
  );

  const parentFolderPermissions = React.useMemo(() => {
    if (currentDirectory?.parentTree) {
      return currentDirectory?.parentTree[
        currentDirectory?.parentTree?.length - 1
      ];
    }
  }, [currentDirectory]);

  const handleDownloadFile = React.useCallback(
    async (fileId: string) => {
      const file = currentDirectory.documents.find((doc) => doc._id === fileId);
      if (file) {
        const type = file.type;
        switch (type) {
          case "image/jpeg":
          case "image/jpg":
          case "image/png":
          case "image/webp":
            const imageData = await downloadFileFromURL(fileId);
            openImageViewer({
              url: imageData.data.getDocumentDownloadURL.url,
              name: imageData.data.getDocumentDownloadURL.name,
            });
            break;
          case "application/pdf":
            const res = await downloadFileFromURL(fileId);
            pdfViewerModalRef.current?.show(true, {
              url: res.data.getDocumentDownloadURL.url,
              name: res.data.getDocumentDownloadURL.name,
            });

            break;

          default:
            downloadFileFromURL(fileId);
            break;
        }
      }
    },
    [currentDirectory, downloadFileFromURL, openImageViewer]
  );

  return (
    <Container fluid>
      <Helmet title={t("navigation.documents")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.DOCUMENTS_MAIN} />
      <UploadDocumentModal
        ref={uploadRef}
        onUpload={handleAddFile}
        setAccessPermissions={true}
        parentFolderPermissions={parentFolderPermissions}
      />
      {renderGallery()}
      <PdfViewerModal ref={pdfViewerModalRef} />
      <CreateUpdateDocumentModal
        modalAction={editModalAction}
        show={!!editModalAction}
        onSubmit={getSubmitFunction()}
        onClose={closeEditModal}
        editDocument={editDocument}
        setAccessPermissions={isAdminUser}
        parentFolderPermissions={parentFolderPermissions}
      />
      {isAdminUser && renderSelectFolderModal()}
      <ConfirmDialog
        disabled={deletingFolder || deletingDocument}
        ref={confirmRef}
        onClose={closeRemoveDialog}
        onSubmit={
          deleteTarget ? handleDocumentRemoveSubmit : handleDeleteSelected
        }
        title={t("documents.removeDocuments")}
        confirm={t("common.delete")}
      >
        <div className="field-text">{t("documents.removeMessage")}</div>
      </ConfirmDialog>
      <Row>
        <Col xs={12} className="pb-sm-5 pb-lg-0">
          <DashboardCard className="documents-wrapper">
            <DocumentsHeader
              onUploadClick={showUploadModal}
              selectFolder={selectFolder}
              selectRootFolders={selectRootFolders}
              onAddNewFolderClick={openCreateFolderModal}
              parentTree={currentDirectory.parentTree || null}
              openRemoveDialogForSelectedItems={
                openRemoveDialogForSelectedItems
              }
              quantityOfSelectedItems={
                selectedItemsToDelete ? selectedItemsToDelete.length : 0
              }
              onCopyContent={
                match?.params?.id && isAdminUser
                  ? handleOnHeaderCopy
                  : undefined
              }
              onEditFolder={isAdminUser ? handleOnEdit : undefined}
              isDisabledHeaderCopyContent={isDisabledHeaderCopyContent}
            />
            <DocumentsTableComponent
              folderId={match.params.id}
              folders={currentDirectory.subFolders}
              documents={currentDirectory.documents}
              tableLoading={foldersLoading || folderLoading}
              selectFolder={selectFolder}
              isRootFolder={!currentDirectory.parentTree}
              handleEditFolderName={openUpdateFolderModal}
              handleEditFileName={openUpdateFileModal}
              onUploadClick={showUploadModal}
              onAddNewFolderClick={openCreateFolderModal}
              selectedItemsToDelete={selectedItemsToDelete}
              addSelectedItemsToDelete={addSelectedItemsToDelete}
              openRemoveDialog={openRemoveDialog}
              handleDownloadFile={handleDownloadFile}
              onCopyAction={isAdminUser ? handleCopyAction : undefined}
              setAccessPermissions={isAdminUser}
            />
          </DashboardCard>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withDashboardContext(DocumentsContainer));
