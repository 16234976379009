import React from "react";
import { useTranslation } from "react-i18next";
import { compact, map } from "lodash";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import TableCard from "../../dashboard/table-card";
import {
  ClientQuoteRequest,
  ClientSubcontractor,
} from "../../../graphql/types/models/quote-request";

type ClientSubcontractorListProps = {
  quoteRequest: ClientQuoteRequest;
  onSelect?: (item: ClientQuoteRequest, subcontractorId: string) => void;
};

type ClientSubcontractorsTableItem = {
  _id: string;
  business_name?: string;
  clientStatus?: string;
  cost?: string;
};

const ClientSubcontractorList: React.FC<ClientSubcontractorListProps> = (
  props
) => {
  const { quoteRequest, onSelect } = props;

  const { t } = useTranslation();

  const getSubcontractorCost = React.useCallback(
    (subcontractor: ClientSubcontractor) => {
      let sum = 0;
      subcontractor?.items?.forEach((item) => (sum += item.cost));
      return sum;
    },
    []
  );

  const tableData = React.useMemo<
    TableCardData<ClientSubcontractorsTableItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "business_name",
          title: t("common.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "cost",
          title: t("costing.cost"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "clientStatus",
          title: t("quoteRequest.clientStatus"),
          formatValue: (row: any, column: any, value: string) => value,
        },
      ],
      rows: map(quoteRequest.subcontractors, (item: ClientSubcontractor) => ({
        cells: {
          _id: item._id,
          business_name: compact([
            item.business_name,
            item.contact_person,
          ]).join(" - "),
          clientStatus: item.client_feedback,
          cost: t("common.currency", { amount: getSubcontractorCost(item) }),
        },
      })),
    };
  }, [quoteRequest, t, getSubcontractorCost]);

  const handleRowClick = React.useCallback(
    (row?: TableCardDataRow<ClientSubcontractorsTableItem>) => {
      if (!row) return;
      onSelect && onSelect(quoteRequest, row.cells._id);
    },
    [quoteRequest, onSelect]
  );

  return (
    <TableCard
      data={tableData}
      isDataLoading={false}
      onRowClick={handleRowClick}
      title={t("common.quotes")}
    />
  );
};

export default ClientSubcontractorList;
