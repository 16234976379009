import { gql } from "@apollo/client";
import { SIGNATURE_FRAGMENT } from "./fragments";

export const LIST_VARIATIONS = gql`
  query JobGetVariations($jobId: ID) {
    getJobGetVariations(jobId: $jobId) {
      _id
      variationNumber
      status
      name
      date
      subTotal
      GST
      total
      progressClaimed
    }
  }
`;

export const GET_VARIATION_PREVIEW = gql`
  query GetJobVariationPreview($jobId: ID, $variationId: ID) {
    getJobVariationPreview(jobId: $jobId, variationId: $variationId) {
      _id
      pdf
    }
  }
`;

export const GET_UNCLAIMED_VARIATIONS = gql`
  query($jobId: ID) {
    getJobGetUnClaminedVariations(jobId: $jobId) {
      _id
      date
      name
      variationNumber
      subTotal
      GST
      total
    }
  }
`;

export const GET_VARIATION = gql`
  query($jobId: ID, $variationId: ID) {
    getJobGetVariationById(jobId: $jobId, variationId: $variationId) {
      _id
      name
      status
      total
      GST
      note
      variationNumber
      progressClaimed
      date
      subTotal
      clientSignature {
        ...SignatureFragment
      }
      builderSignature {
        ...SignatureFragment
      }
      contact {
        _id
        last_name
        first_name
      }
      job {
        _id
      }
      items {
        name
        UOM
        cost
        quantity
        raw_quantity
        margin_amount
        hasGST
        wastage
        rounding
        costingCategoryName
        costingItemId
      }
    }
  }
  ${SIGNATURE_FRAGMENT}
`;
