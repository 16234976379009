import React from "react";
import { Helmet } from "react-helmet";
import { useQuery, useLazyQuery } from "@apollo/client";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { concat, omit, reverse } from "lodash";

import { NAVIGATION_ROUTES } from "../../components/dashboard/sidebar/utils/navigation-items";
import SetNavigationRoute from "../../components/navigation/SetNavigationRoute";
import {
  SiteDiary,
  SiteDiariesListPayload,
  SiteDiaryPagination,
  SiteDiaryResponse,
  SiteDiariesListResponse,
  SiteDiaryTemplateListResponse,
} from "../../graphql/types/models/site-diary";
import {
  GET_SITE_DIARY,
  LIST_SITE_DIARIES_BY_FILTERS,
  LIST_SITE_DIARY_TEMPLATES,
} from "../../graphql/queries/site-diary/queries";
import SiteDiaryItem from "../../components/job-site-diary/entry-list-item";
import Dropdown, { DropdownItem } from "../../components/dashboard/dropdown";
import { PaginationButton } from "../../components/category-select-card";
import SiteDiaryList from "../../components/job-site-diary/entry-list";
import { Pagination } from "../../models/pagination";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_PAGINATION_PAGE,
} from "../../constants/pagination";
import { useDropdownJobs } from "../../hooks/useDropdownJobs";
import { useDropdownDateRange } from "../../hooks/useDropdownDateRange";
import DashboardActionHeader from "../../components/dashboard/table-card/DashboardActionHeader";
import "./styles.scss";

type Filter = {
  id: string;
  name: string;
};

const SiteDiaryContainer: React.FC = () => {
  const { currentJob, setJob, renderDropdownJobs } = useDropdownJobs();
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    renderDropdownDateRange,
  } = useDropdownDateRange({ showResetIcon: true });

  const [allDiaries, setAllDiaries] = React.useState<SiteDiary[]>([]);
  const [currentType, setType] = React.useState<Filter>({
    id: "",
    name: "",
  });
  const [listPagination, setListPagination] = React.useState<Pagination>();
  const [isFilterByTypeShow, setIsFilterByTypeShow] = React.useState(false);

  const [siteDiaryListPagitation, setSiteDiaryListPagitation] = React.useState<
    SiteDiaryPagination
  >({
    page: DEFAULT_PAGINATION_PAGE,
    limit: DEFAULT_PAGINATION_LIMIT,
  });

  const globalSiteDiariesFilters = "globalSiteDiariesTableFilters";
  const {
    storedValue: storedFilters,
    setStoredValue: setStoredFilters,
  } = useLocalStorage(globalSiteDiariesFilters, {
    startDate,
    endDate,
    currentJob,
    currentType,
  });

  const { t } = useTranslation();

  const [siteDiaryList] = useLazyQuery<
    SiteDiariesListResponse,
    SiteDiariesListPayload
  >(LIST_SITE_DIARIES_BY_FILTERS, {
    fetchPolicy: "network-only",
    variables: {
      filters: {
        dateRange: {
          from: startDate || undefined,
          to: endDate || undefined,
        },
        jobId: currentJob.id,
        templateId: currentType.id,
      },
      pagination: siteDiaryListPagitation,
    },
    onCompleted: ({ listSiteDiaries }) => {
      if (listSiteDiaries?.siteDiaries) {
        setAllDiaries(listSiteDiaries?.siteDiaries);
        setStoredFilters({ startDate, endDate, currentJob, currentType });
        setListPagination(omit(listSiteDiaries, ["siteDiaries"]));
      }
    },
  });

  const [
    getSiteDiary,
    { data: siteDiaryData, loading: getSiteDiaryLoading },
  ] = useLazyQuery<SiteDiaryResponse>(GET_SITE_DIARY);

  const selectedDiary = React.useMemo(
    () => siteDiaryData?.getSiteDiaryById || null,
    [siteDiaryData]
  );

  const { data: templatesData, loading } = useQuery<
    SiteDiaryTemplateListResponse
  >(LIST_SITE_DIARY_TEMPLATES);

  const templates = React.useMemo(
    () => templatesData?.listSiteDiaryTemplates ?? [],
    [templatesData]
  );

  const resetPagination = React.useCallback(() => {
    setSiteDiaryListPagitation({
      ...siteDiaryListPagitation,
      page: 1,
    });
  }, [siteDiaryListPagitation]);

  const siteDiaries = React.useMemo<SiteDiary[]>(
    () => reverse([...allDiaries]),
    [allDiaries]
  );

  const handleSelect = React.useCallback(
    (siteDiary: SiteDiary) => {
      getSiteDiary({
        variables: {
          jobId: currentJob.id,
          diaryId: siteDiary._id,
        },
      });
    },
    [currentJob.id, getSiteDiary]
  );

  React.useEffect(() => {
    if (siteDiaries?.length > 0) {
      handleSelect(siteDiaries[0]);
    }
  }, [siteDiaries, handleSelect]);

  React.useEffect(() => {
    resetPagination();
    siteDiaryList();
  }, [currentJob, startDate, endDate, currentType, siteDiaryList]);

  React.useEffect(() => {
    if (storedFilters) {
      const { startDate, endDate, currentJob, currentType } = storedFilters;
      setJob(currentJob);
      setType(currentType);
      setStartDate(startDate ? new Date(startDate) : null);
      setEndDate(endDate ? new Date(endDate) : null);
    } else {
      setStartDate(null);
      setEndDate(null);
      setStoredFilters({ startDate, endDate, currentJob, currentType });
    }
  }, []);

  const toggleFilterByType = React.useCallback(
    () => setIsFilterByTypeShow(!isFilterByTypeShow),
    [isFilterByTypeShow]
  );

  const filterByTypeDropdownItems = React.useMemo<DropdownItem[]>(
    () =>
      concat(
        {
          id: "-",
          label: t("common.all"),
          onClick: () => {
            setType({
              id: "",
              name: "",
            });
          },
        },
        templates.map((template) => ({
          id: template._id,
          label: template.name,
          onClick: () => {
            setType({
              id: template._id,
              name: template.name,
            });
          },
        }))
      ),
    [templates]
  );

  const handleListPagination = React.useCallback(
    (type: PaginationButton) => {
      if (type === PaginationButton.Next) {
        setSiteDiaryListPagitation({
          ...siteDiaryListPagitation,
          page: siteDiaryListPagitation.page + 1,
        });
      }
      if (type === PaginationButton.Previous) {
        setSiteDiaryListPagitation({
          ...siteDiaryListPagitation,
          page: siteDiaryListPagitation.page - 1,
        });
      }
    },
    [siteDiaryList, siteDiaryListPagitation]
  );

  const filters = React.useMemo(
    () => [
      renderDropdownDateRange(),
      renderDropdownJobs(),
      <Dropdown
        isVisible={isFilterByTypeShow}
        handleToggle={toggleFilterByType}
        label={currentType.name || t("siteDiary.filterByType")}
        icon="expand_more"
        id="filter-by-type"
        items={filterByTypeDropdownItems}
        alignRight
      />,
    ],
    [
      currentType.name,
      filterByTypeDropdownItems,
      isFilterByTypeShow,
      renderDropdownDateRange,
      renderDropdownJobs,
      t,
      toggleFilterByType,
    ]
  );

  return (
    <Container fluid className="m-0 p-0 h-100 common-sitediary-container">
      <Helmet title={t("navigation.siteDiaries")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SITE_DIARIES} />
      <DashboardActionHeader>
        {filters.map((filter, index) => (
          <div className="pr-2 pb-4" key={index}>
            {filter}
          </div>
        ))}
      </DashboardActionHeader>

      <Row className="common-sitediary-list">
        <Col lg={4} sm={5} xs={12} className="list">
          <SiteDiaryList
            title={t("siteDiary.entries")}
            placeholder={t("siteDiary.emptyFilterPlaceholder")}
            onSelect={handleSelect}
            entries={siteDiaries}
            selectedEntry={selectedDiary}
            hideAddBtn
            pagination={listPagination}
            onPagination={handleListPagination}
            hideFilter={true}
            disableGroups={true}
          />
        </Col>
        {selectedDiary && siteDiaries?.length !== 0 && (
          <Col lg={8} sm={7} xs={12} className="mh-100">
            <SiteDiaryItem
              title={selectedDiary.job?.name || t("siteDiary.diaryEntry")}
              siteDiary={selectedDiary}
              isGlobalView
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default SiteDiaryContainer;
