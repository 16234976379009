import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import appHistory from "../../router/history";
import AffiliateLinkContainer from "../affiliate-link";
import AffiliateRegisteredContainer from "../affiliate-registered";
import SignInContainer from "../sign-in";
import SignUpContainer from "../sign-up";
import SignUpActivateContainer from "../sign-up-activate";
import SignUpConfirmContainer from "../sign-up-confirm";
import ForgetPasswordContainer from "../forget-password";
import DashboardContainer from "../dashboard";
import SubcontractorDashboardContainer from "../subcontractor/dashboard";
import WbAdminDashboardContainer from "../wb-admin/dashboard";
import ClientDashboardContainer from "../client/dashboard";
import AffiliateDashboardContainer from "../affiliate/dashboard";
import ResetPasswordContainer from "../reset-password";
import Context, { SiteContext } from "../../siteContext";
import GraphQLClient from "../../graphql";
import "./styles.css";

type AppContainerProps = {};

const AppContainer: React.FC<AppContainerProps> = () => {
  const context = React.useContext(Context) as SiteContext;
  const { isSubcontractor, isAdmin, isClient, isAffiliate } = context;
  GraphQLClient.checkToken(context);

  const publicRoutes = React.useMemo(
    () => [
      <Route key="signin" exact path="/sign-in" component={SignInContainer} />,
      <Route
        key="reset"
        exact
        path="/reset-password/:resetHash"
        component={ResetPasswordContainer}
      />,
      <Route
        key="forget"
        exact
        path="/forget-password"
        component={ForgetPasswordContainer}
      />,
    ],
    []
  );

  const routes = React.useMemo(() => {
    if (isAffiliate) {
      return (
        <Switch>
          {publicRoutes}
          <Route exact path="/sign-up" component={SignUpContainer} />
          <Route
            exact
            path="/sign-up/thank-you"
            component={AffiliateRegisteredContainer}
          />
          <Route path="*" component={AffiliateDashboardContainer} />
        </Switch>
      );
    } else if (isSubcontractor) {
      return (
        <Switch>
          {publicRoutes}
          <Route path="*" component={SubcontractorDashboardContainer} />
        </Switch>
      );
    } else if (isAdmin) {
      return (
        <Switch>
          {publicRoutes}
          <Route path="*" component={WbAdminDashboardContainer} />
        </Switch>
      );
    } else if (isClient) {
      return (
        <Switch>
          {publicRoutes}
          <Route path="*" component={ClientDashboardContainer} />
        </Switch>
      );
    }

    return (
      <Switch>
        {publicRoutes}
        <Route
          exact
          path="/a/:affiliateCode"
          component={AffiliateLinkContainer}
        />
        <Route exact path="/sign-up" component={SignUpContainer} />
        <Route
          exact
          path="/sign-up/activate/:id"
          component={SignUpActivateContainer}
        />
        <Route
          exact
          path="/sign-up/confirm/:id/:confirmHash"
          component={SignUpConfirmContainer}
        />
        <Route path="*" component={DashboardContainer} />
      </Switch>
    );
  }, [isSubcontractor, isAdmin, isClient]);

  return (
    <React.Suspense fallback="">
      <div className="application-wrapper">
        <ConnectedRouter history={appHistory}>{routes}</ConnectedRouter>
      </div>
    </React.Suspense>
  );
};
export default AppContainer;
