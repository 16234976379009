import { gql } from "@apollo/client";
import {
  COSTING_FRAGMENT,
  COSTING_CATEGORY_FRAGMENT,
  COSTING_ITEM_FRAGMENT,
} from "./fragments";
import { SELECTION_CATEGORY_ITEM_FRAGMENT } from "../selection/fragments";

export const CREATE_SALES_QUOTE_CATEGORY = gql`
  mutation CreateSalesQuoteCategory(
    $margin_amount: Float
    $name: String
    $salesQuoteId: ID!
  ) {
    createSalesQuoteCategory(
      salesQuoteId: $salesQuoteId
      category: { name: $name, type: costing, margin_amount: $margin_amount }
    ) {
      _id
      name
      margin_amount
      status
      order
    }
  }
`;

export const UPDATE_SALES_QUOTE_CATEGORY_STATUS = gql`
  mutation UpdateSalesQuoteCostingCategoryStatus(
    $salesQuoteId: ID!
    $categoryId: ID!
    $status: EnumSalesQuoteCategoryStatus!
  ) {
    updateSalesQuoteCostingCategoryStatus(
      salesQuoteId: $salesQuoteId
      categoryId: $categoryId
      status: $status
    ) {
      _id
      status
    }
  }
`;

export const SAVE_UPDATE_SALES_QUOTE_CATEGORY_ITEMS = gql`
  mutation(
    $salesQuoteId: ID!
    $category: SalesQuoteCategoryInput!
    $costItems: [CostingItemInput]
  ) {
    saveSalesQuoteCostingByCategory(
      salesQuoteId: $salesQuoteId
      category: $category
      costItems: $costItems
    ) {
      ...SalesQuoteCostingCategoryFragment
    }
  }
  ${COSTING_CATEGORY_FRAGMENT}
`;

export const UPDATE_SALES_QUOTE_COSTING_CATEGORY_ORDER = gql`
  mutation($salesQuoteId: ID!, $categories: [SalesQuoteCategoryInput]!) {
    updateSalesQuoteCostingCategoryOrder(
      salesQuoteId: $salesQuoteId
      categories: $categories
    ) {
      ...SalesQuoteCostingCategoryFragment
    }
  }
  ${COSTING_CATEGORY_FRAGMENT}
`;

export const SAVE_SALES_QUOTE_COSTING_BULK = gql`
  mutation($salesQuoteId: ID!, $costItems: [CostingItemInput]) {
    saveSalesQuoteCostingBulk(
      salesQuoteId: $salesQuoteId
      costItems: $costItems
    ) {
      ...SalesQuoteCostingFragment
    }
  }
  ${COSTING_FRAGMENT}
`;

export const UPDATE_COSTING_ITEMS_ALLOWANCE = gql`
  mutation($salesQuoteId: ID!, $costItemIds: [ID]!, $status: Boolean!) {
    updateCostingItemAllowanceStatus(
      salesQuoteId: $salesQuoteId
      costItemIds: $costItemIds
      status: $status
    ) {
      ...SalesQuoteCostingItemFragment
    }
  }
  ${COSTING_ITEM_FRAGMENT}
`;

export const ADD_SPEC_FROM_COSTING_ITEMS = gql`
  mutation addSpecItemsFromCostingItems(
    $salesQuoteId: ID!
    $costingItemList: [ID!]!
  ) {
    addSpecItemsFromCostingItems(
      salesQuoteId: $salesQuoteId
      costingItemList: $costingItemList
    ) {
      _id
      name
      address
      city
      state
      postcode
      number
      include_costing
      terms
      status
      total
      subTotal
      markup
      is_locked
      costingCategories {
        _id
        name
        costings {
          _id
          name
          specCategoryItem {
            _id
          }
        }
      }
      job {
        _id
        name
      }
      contact {
        _id
        email
        first_name
        last_name
        phone
        phone_secondary
      }
      business {
        _id
        name
      }
      files {
        _id
        type
        size
        name
        url
        upload_url
        calibration {
          lat
          lng
        }
      }
      printTemplate {
        _id
        name
      }
    }
  }
`;

export const ADD_SELECTION_ITEMS_FROM_COSTING_ITEMS = gql`
  mutation($salesQuoteId: ID!, $costingItemList: [ID!]!) {
    addSelectionItemsFromCostingItems(
      salesQuoteId: $salesQuoteId
      costingItemList: $costingItemList
    ) {
      _id
      cost
      quantity
      rounding
      wastage
      name
      raw_quantity
      UOM
      selectionItem {
        ...SelectionCategoryItemFragment
      }
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
`;

export const DELETE_SALES_QUOTE_CATEGORY = gql`
  mutation DeleteSalesQuoteCategory($salesQuoteId: ID!, $categoryId: ID!) {
    deleteSalesQuoteCategory(
      salesQuoteId: $salesQuoteId
      categoryId: $categoryId
    ) {
      _id
      costingCategories {
        _id
      }
    }
  }
`;
