import authenticationReducer, {
  AuthenticationReducerState,
} from "./authentication/reducer";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

export type RootReducerState = {
  authentication: AuthenticationReducerState;
  router: any;
};

const createRootReducer = (history: any) =>
  combineReducers({
    authentication: authenticationReducer,
    router: connectRouter(history),
  });

export default createRootReducer;
