import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { assign } from "lodash";
import { useMutation } from "@apollo/client";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import { configureClaimFields } from "./utils";
import {
  ConfigureInvoicePayload,
  CreateJobPayload,
  JobDetails,
  JobInvoiceType,
} from "../../../models/job";
import createJobSchema from "./schema";
import { CreateUpdateJobResponse } from "../../../graphql/types/models/job";
import { CREATE_UPDATE_JOB } from "../../../graphql/queries/job/mutations";
import { notify } from "../../notification";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { handleJobCreate } from "../../../graphql/queries/job/utils";

type ConfigureClaimModalProps = {
  job?: JobDetails;
};

const ConfigureClaimModal: React.FC<ConfigureClaimModalProps> = (
  props,
  ref
) => {
  const { job } = props;

  const { t } = useTranslation();

  const { shouldShow, hide } = useModalDisplay(ref);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateJobPayload>
  >({});
  const [invoiceType, setInvoiceType] = React.useState<JobInvoiceType>(
    JobInvoiceType.Standard
  );

  const [updateJob] = useMutation<CreateUpdateJobResponse>(CREATE_UPDATE_JOB, {
    update: handleJobCreate,
    onCompleted: (response) => {
      hide();

      notify({
        title: t("jobs.configureClaim"),
        content: t("jobs.success.updateJob"),
      });
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("jobs.configureClaim"),
        content: error.message,
      });
    },
  });

  const handleInvoiceTypeChange = React.useCallback((value: string) => {
    setInvoiceType(
      value === JobInvoiceType.Retention
        ? JobInvoiceType.Retention
        : JobInvoiceType.Standard
    );
  }, []);

  React.useEffect(() => {
    setInvoiceType(job?.invoiceType || JobInvoiceType.Standard);
  }, [job]);

  React.useEffect(() => {
    setFormFields(
      configureClaimFields(t, invoiceType, handleInvoiceTypeChange)
    );
  }, [t, invoiceType, handleInvoiceTypeChange]);

  const initialValues = React.useMemo(
    () => ({
      invoiceType: job?.invoiceType || JobInvoiceType.Standard,
      excludeVariationFromClaimAmount:
        job?.excludeVariationFromClaimAmount || false,
      retention: {
        invoicePercentage: job?.retention?.invoicePercentage || 0,
        totalPercentage: job?.retention?.totalPercentage || 0,
      },
    }),
    [job]
  );

  const handleSubmit = React.useCallback(
    (values: ConfigureInvoicePayload) => {
      if (job) {
        assign(values, {
          _id: job._id,
          customer: job.customer?._id,
        });
      }
      const isRetention = values.invoiceType === JobInvoiceType.Retention;
      return updateJob({
        variables: {
          jobId: job?._id,
          job: {
            ...values,
            retention: {
              invoicePercentage: isRetention
                ? Number(values.retention.invoicePercentage)
                : 0,
              totalPercentage: isRetention
                ? Number(values.retention.totalPercentage)
                : 0,
            },
          },
        },
      });
    },
    [job]
  );

  return (
    <CreateEntityModal
      validationSchema={createJobSchema(t)}
      className="configure-claim-modal"
      title={t("jobs.configureClaim")}
      show={shouldShow}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
      submitText={t("common.update")}
    />
  );
};

export default forwardRef(ConfigureClaimModal);
