import React from "react";
import { t } from "i18next";
import { get } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ACCOUNTING_INTEGRATION } from "../../graphql/queries/integrations/queries";
import {
  AccountingIntegrationResponse,
  AccountingIntegrationSyncItemType,
  SyncAccountingItemResponse,
} from "../../graphql/types/models/integrations";
import { SYNC_ACCOUNTING_ITEM } from "../../graphql/queries/integrations/mutations";
import { notify } from "../../components/notification";

export function useAccountingIntegrationQuery() {
  const { data, refetch: refetchIntegration } = useQuery<
    AccountingIntegrationResponse
  >(GET_ACCOUNTING_INTEGRATION);

  const [syncAccountingItem] = useMutation<SyncAccountingItemResponse>(
    SYNC_ACCOUNTING_ITEM,
    {
      onCompleted: () => {
        notify({
          title: t("integrations.syncWithProvider", {
            provider: t(`integrations.${integrationProvider?.toLowerCase()}`),
          }),
          content: t("integrations.success.syncWithProvider"),
        });
      },
      onError: (e) => {
        notify({
          error: true,
          title: t("integrations.syncWithProvider", {
            provider: t(`integrations.${integrationProvider?.toLowerCase()}`),
          }),
          content: get(e, "message", "integrations.errors.syncWithProvider"),
        });
      },
    }
  );

  const syncItem = React.useCallback(
    async (
      itemType: AccountingIntegrationSyncItemType,
      itemId: string,
      jobId?: string
    ) => {
      try {
        syncAccountingItem({
          variables: {
            jobId: jobId || "",
            itemType,
            itemId,
          },
        });
      } catch (e) {}
    },
    []
  );

  const integrationProvider = React.useMemo(
    () => data?.getCurrentAccountingIntegration?.integration_type,
    [data]
  );

  const accountingIntegration = React.useMemo(
    () => data?.getCurrentAccountingIntegration,
    [data]
  );

  return {
    accountingIntegration,
    integrationProvider,
    refetchIntegration,
    syncAccountingItem,
    syncItem,
  };
}
