import React, { useState } from "react";
import { round } from "mathjs";
import {
  AddPriceListCategoryPayload,
  BulkPriceListAdjustPayload,
  PriceListItem,
} from "../../../../models/price-list";
import CreateEntityModal from "../../../modals/create-entity";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import createAdjustPriceListFields from "./utils";
import createAdjustPriceListSchema from "./AdjustPrice.schema";
import TableCard from "../../../dashboard/table-card";
import { chain, map } from "lodash";
import { TableCardData } from "../../../dashboard/table-card/utils";

type AdjustPriceListProps = {
  show: boolean;
  previewValues?: AddPriceListCategoryPayload;
  onSubmit: (data: BulkPriceListAdjustPayload) => void;
  onClose: () => void;
};

type PreviewPriceListItem = PriceListItem & {
  adjustedCost: number;
};

const AdjustPriceListModal: React.FC<AdjustPriceListProps> = (props) => {
  const { onSubmit, show, onClose, previewValues } = props;

  const { t } = useTranslation();

  const [percentage, setPercentage] = useState(0);

  const [formFields, setFormFields] = useState<
    GenericFormFields<BulkPriceListAdjustPayload>
  >({});

  const onPercentageChange = React.useCallback((value) => {
    console.log("onPercentageChange", value);

    setPercentage(value);
  }, []);

  React.useEffect(() => {
    setFormFields(createAdjustPriceListFields(t, onPercentageChange));
  }, [t]);

  const previewData = React.useMemo<TableCardData<PreviewPriceListItem>>(() => {
    return {
      columns: [
        {
          valueKey: "sku",
          title: t("priceList.sku"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "name",
          title: t("priceList.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "UOM",
          title: t("priceList.uom"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "cost",
          title: t("priceList.cost"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "adjustedCost",
          title: t("priceList.adjustedCost"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: chain(previewValues?.items)
        .take(5)
        .map((item) => ({
          cells: {
            ...item,
            adjustedCost: round(item.cost * (1 + percentage / 100), 2),
          },
        }))
        .value(),
    };
  }, [t, previewValues, percentage]); //selectedCategory+

  const renderPreview = () => {
    return <TableCard isDataLoading={false} title={""} data={previewData} />;
  };

  return (
    <CreateEntityModal
      validationSchema={createAdjustPriceListSchema(t)}
      show={show}
      className="adjust-price-list-modal"
      title={t("priceList.adjustPrice")}
      submitText={t("common.update")}
      fields={formFields}
      data={{
        amount: 0,
      }}
      onClose={onClose}
      onSubmit={onSubmit}
      endRenderer={renderPreview()}
    />
  );
};

export default AdjustPriceListModal;
