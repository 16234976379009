import { QuoteRequest, QuoteRequestComment } from "./quote-request";
import { User } from "./user";
import { SupplierDetails } from "../../../models/supplier";
import { JobMessage } from "../../../models/job-messaging";
import { VariationReal } from "../../../models/variations";
import { Selection } from "./selections";

export enum EnumSystemUserType {
  Subcontractor = "Subcontractor",
  Builder = "Builder",
  Client = "Client",
  None = "None",
}

export enum EnumNotificationType {
  QuoteRequestComment = "QuoteRequestComment",
  NewMessage = "NewMessage",
  QuoteRequest = "QuoteRequest",
  VariationClientAccept = "VariationClientAccept",
  QuoteRequestClientAccept = "QuoteRequestClientAccept",
  SelectionClientSubmit = "SelectionClientSubmit",
  Other = "Other",
}

export type Notification = {
  _id: string;
  message: string;
  is_read: boolean;
  createdAt: string;
  senderType: EnumSystemUserType;
  notificationType: EnumNotificationType;
  sender: User & SupplierDetails;
  entity:
    | QuoteRequestComment
    | QuoteRequest
    | JobMessage
    | VariationReal
    | Selection;
};

export type ListNotificationResponse = {
  listNotifications: Notification[];
};

export type ReadNotificationResponse = {
  readNotification: Notification;
};
