import { TFunction } from "i18next";
import { map } from "lodash";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { SalesQuoteTemplate } from "../../../models/salesQuote";

export const createImportFromTemplateField = (
  t: TFunction,
  handleTemplateListChange: UpdateTextInputFn,
  templates: SalesQuoteTemplate[]
): GenericFormFields<{}> => {
  const templateOptions = map(templates, (category) => ({
    value: category._id,
    label: category.name,
  }));

  return {
    template: {
      type: "select-autocomplete",
      label: t("quotes.template"),
      valueKey: "template",
      autocompleteProps: {
        options: templateOptions,
      },
      textInputProps: {
        onValueChange: handleTemplateListChange,
      },
      inputProps: {
        className: "form-input-compact",
      },
    },
  };
};
