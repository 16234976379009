import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/subcontractor-navigation-items";

const HomeContainer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet title={t("navigation.dashboard")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.DASHBOARD} />
      home
    </div>
  );
};

export default HomeContainer;
