import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import ContactsImport from "../../../components/contacts/contacts-import";
import { Col, Row } from "react-bootstrap";
import { ClientColumnMap } from "../../../models/client";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import ConfirmDialog from "../../../components/confirm-dialog";
import { ContactImportModes } from "../../../utils/options";
import ImportMapper from "../../../components/import-mapper";
import DashboardCard from "../../../components/dashboard/card";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import { FIELD_MAP_CONTACT, FIELD_MAP_SUPPLIER } from "./costants";
import { MapFieldsResult } from "../../../utils/sheets/types";
import { useMutation } from "@apollo/client";
import {
  IMPORT_CONTACTS,
  IMPORT_SUPPLIERS,
} from "../../../graphql/queries/client/mutations";
import { notify } from "../../../components/notification";
import { useHistory } from "react-router-dom";
import { LIST_CONTACTS } from "../../../graphql/queries/client/queries";
import { LIST_SUPPLIERS } from "../../../graphql/queries/supplier/queries";
import { useImportMapper } from "../../../hooks/useImportMapper";

const ContactsImportContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [importMode, setImportMode] = useState(ContactImportModes.CONTACT);
  const [importFieldMapTemplate, setImportFieldMapTemplate] = useState<any>(
    FIELD_MAP_CONTACT
  );

  const [contacts, setContact] = useState<
    MapFieldsResult<ClientColumnMap>[] | null
  >(null);
  const [filteredContacts, setFilteredContacts] = useState<
    MapFieldsResult<ClientColumnMap>[] | null
  >(null);
  const [showExportDialog, setExportDialogVisibility] = useState(false);

  const {
    file,
    headerRow,
    sampleRow,
    rows,
    handleFileAccept,
    handleReset,
  } = useImportMapper();

  const [importSuppliers, { loading: isLoadingimportSuppliers }] = useMutation(
    IMPORT_SUPPLIERS,
    {
      onCompleted: ({ importSuppliers }) => {
        const noImported = importSuppliers ? importSuppliers.length : 0;
        const noSkipped = (rows?.length || 0) - noImported;
        notify({
          content: t("mapping.suppliersImported", { noImported, noSkipped }),
          title: t("mapping.importSuppliers"),
        });
        history.push("/contacts/suppliers");
      },
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [
        {
          query: LIST_SUPPLIERS,
        },
      ],
    }
  );

  const [importContact, { loading: isLoadingImportContact }] = useMutation(
    IMPORT_CONTACTS,
    {
      onCompleted: ({ importContacts }) => {
        const noImported = importContacts ? importContacts.length : 0;
        const noSkipped = (rows?.length || 0) - noImported;

        notify({
          content: t("mapping.contactsImported", { noImported, noSkipped }),
          title: t("mapping.importContacts"),
        });
        history.push("/contacts/clients");
      },
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: [
        {
          query: LIST_CONTACTS,
        },
      ],
    }
  );

  const closeExportDialog = React.useCallback(() => {
    setExportDialogVisibility(false);
  }, []);

  const handleSupplierImport = React.useCallback(async (importData: any[]) => {
    await importSuppliers({
      variables: {
        suppliers: importData.map((supplier) => {
          let data = { ...supplier };
          if (supplier.abn) {
            const abn = supplier.abn.replace(/[^0-9]/gi, "");
            data.abn = abn.length === 11 ? abn : null;
          }
          return data;
        }),
      },
    });
  }, []);

  const handleContactImport = React.useCallback(async (importData: any[]) => {
    await importContact({
      variables: { contacts: importData },
    });
  }, []);

  //(importMode == "CONTACT"? FIELD_MAP_CONTACT : FIELD_MAP_SUPPLIER
  const handleImportModeChange = React.useCallback(
    (mode) => {
      if (mode == ContactImportModes.CONTACT)
        setImportFieldMapTemplate(FIELD_MAP_CONTACT);
      else setImportFieldMapTemplate(FIELD_MAP_SUPPLIER);

      setImportMode(mode);
    },
    [setImportFieldMapTemplate, importFieldMapTemplate]
  );

  const onImport = React.useCallback(
    async (data: any[]) => {
      if (data.length > 0) {
        if (importMode == ContactImportModes.CONTACT) handleContactImport(data);
        else handleSupplierImport(data);
      }
    },
    [importMode]
  );

  const reset = React.useCallback(() => {
    setFilteredContacts(null);
    setExportDialogVisibility(false);
    handleReset();
  }, [handleReset]);

  const handleCancel = React.useCallback(() => {
    reset();
  }, [reset]);

  const skippedRows = (contacts?.length || 0) - (filteredContacts?.length || 0);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.contactsSection.importContacts")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.CONTACTS_SECTION.IMPORT_CONTACTS}
      />

      <ConfirmDialog
        title={t("mapping.importContacts")}
        show={showExportDialog}
        onClose={closeExportDialog}
        onSubmit={closeExportDialog}
      >
        <div>
          <div className="field-text">
            {t("mapping.rowImported", { rowCount: filteredContacts?.length })}
          </div>

          <div className="field-text">
            {t("mapping.contactsSkipped", { rowCount: skippedRows })}
          </div>
        </div>
      </ConfirmDialog>

      <Row className="h-100">
        <Col lg={4} xs={12}>
          <ContactsImport
            fileName={file?.name}
            onCancel={handleCancel}
            onFileSelect={handleFileAccept}
            importMode={importMode}
            onModeChange={handleImportModeChange}
          />
        </Col>
        <Col lg={8} xs={12}>
          {headerRow && (
            <DashboardCard className="import-mapper">
              <DashboardCardHeader>{t("contacts.mapData")}</DashboardCardHeader>
              <ImportMapper
                options={importFieldMapTemplate}
                rows={rows}
                onSubmit={onImport}
                header={headerRow}
                sample={sampleRow}
                isLoading={isLoadingImportContact || isLoadingimportSuppliers}
              />
            </DashboardCard>
          )}
          {!headerRow && <CardPlaceholder />}
        </Col>
      </Row>
    </Container>
  );
};

export default ContactsImportContainer;
