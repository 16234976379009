import { TFunction } from "i18next";
import { CreateChecklistPayload } from "../../../models/task";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const createChecklistField = (
  t: TFunction
): GenericFormFields<CreateChecklistPayload> => {
  const fields: GenericFormFields<CreateChecklistPayload> = {
    name: {
      type: "text",
      label: t("tasks.checklistgroupName"),
      placeholder: t("common.name"),
      valueKey: "name",
      inputProps: {
        required: true,
      },
    },
  };

  return fields;
};
