import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import AuthenticationLayout from "../layouts/authentication";
import AuthenticationCard from "../../components/authentication/authentication-card";

type AffiliateRegisteredContainerProps = {};

const AffiliateRegisteredContainer: React.FC<AffiliateRegisteredContainerProps> = (
  props
) => {
  const { t } = useTranslation();
  return (
    <AuthenticationLayout>
      <Helmet title={t("authentication.affiliateSignUp")} />
      <AuthenticationCard
        title={t("authentication.affiliateSignUp")}
        container={Form}
        containerProps={{
          className: "authentication-card-container sign-up-card",
        }}
        body={() => (
          <>
            <div>
              <p className="lead">{t("authentication.affiliateThankYou")}</p>
              <br />
              <p className="lead">
                {t("authentication.affiliateThankYouReview")}
              </p>
            </div>
          </>
        )}
        footer={() => null}
      />
    </AuthenticationLayout>
  );
};

export default AffiliateRegisteredContainer;
