import { gql } from "@apollo/client";
import { TEAM_MEMBER } from "../../members/fragments";

export const SCHEDULE_CATEGORY_FRAGMENT = gql`
  fragment ScheduleCategoryFragment on ScheduleCategory {
    _id
    name
    startDate
    endDate
    colour
    order
    is_deleted
    items {
      _id
      name
      description
      startDate
      endDate
      progress
      order
      supplier {
        _id
      }
      user {
        ...TeamMember
      }
      predecessor {
        _id
      }
      dependant {
        _id
      }
      reminder {
        daysBefore
        timezone
      }
    }
  }
  ${TEAM_MEMBER}
`;
