import { TFunction } from "i18next";
import { map } from "lodash";
import { SpecTemplate } from "../../../models/specification";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const importSpecFromTemplateFields = (
  t: TFunction,
  templates: SpecTemplate[]
): GenericFormFields<{ templateId: string }> => {
  const specTemplate = map(templates, (template) => ({
    value: template._id,
    label: template.name,
  }));
  return {
    templateId: {
      type: "default-select",
      label: t("common.template"),
      placeholder: t("common.template"),
      valueKey: "templateId",
      selectProps: {
        options: specTemplate,
      },
    },
  };
};
