import React from "react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import {
  Client,
  ClientPhase,
  ClientType,
  CreateClientFormFields,
  CreateClientPayload,
} from "../../../models/client";

import UpdateEntityModal from "../../modals/update-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createClientSchema from "./CreateClient.schema";
import { createClientFormFields } from "./utils";
import { handlePlaceSelect } from "../../../utils/place";
import "./styles.scss";

type CreateClientModalProps = {
  show: boolean;
  onSubmit: (data: CreateClientPayload) => void;
  onClose: () => void;
  disabled?: boolean;
  client?: Client;
  onDelete?: () => void | Promise<any>;
  showDeleteButton?: boolean;
};

const CreateClientModal: React.FC<CreateClientModalProps> = ({
  show,
  client,
  onSubmit,
  onClose,
  onDelete,
  showDeleteButton,
  disabled,
}) => {
  const { t } = useTranslation();
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateClientFormFields>
  >({});
  const [clientType, setClientType] = React.useState<ClientType>(
    ClientType.INDIVIDUAL
  );

  const handleClientType = React.useCallback((value) => {
    setClientType(value);
  }, []);

  React.useEffect(() => {
    setFormFields(
      createClientFormFields(t, handlePlaceSelect, handleClientType, clientType)
    );
  }, [clientType]);

  const handleSubmit = React.useCallback(
    (values: CreateClientFormFields) => {
      const newValues = {
        ...values,
        ...(clientType === ClientType.INDIVIDUAL
          ? { business_name: "", abn: "" }
          : {}),
        mobile: values.phone,
      };

      return onSubmit(newValues);
    },
    [onSubmit, clientType]
  );

  React.useEffect(() => {
    setClientType(client ? client.type : ClientType.INDIVIDUAL);
  }, [client]);

  const intialValues = React.useMemo(() => {
    if (client) {
      return client;
    }

    return {
      business_name: "",
      abn: "",
      first_name: "",
      last_name: "",
      email: "",
      phase: ClientPhase.CUSTOMER,
      phone: "",
      phone_secondary: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      postcode: "",
      type: ClientType.INDIVIDUAL,
    };
  }, [client]);

  return (
    <UpdateEntityModal
      validationSchema={createClientSchema(t)}
      title={client ? t("contacts.editClient") : t("contacts.newClient")}
      className="create-client-modal"
      show={show}
      data={intialValues}
      onSubmit={handleSubmit}
      onClose={onClose}
      fields={formFields}
      showDeleteButton={showDeleteButton}
      onDelete={onDelete}
      submitButtonTitle={client ? t("common.update") : t("common.create")}
    />
  );
};

export default CreateClientModal;
