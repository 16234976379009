import React, { useEffect } from "react";
import { uniqBy } from "lodash";
import { UOMOption } from "../utils/types/options";
import { MEASUREMENT_OPTIONS } from "../utils/options";

type UOMItem = {
  UOM: string;
};
// TODO: Implement this hook across all sections that use a custom UOM
export function useUOM(items?: UOMItem[]) {
  const [uoms, setUOMs] = React.useState<UOMOption[]>(MEASUREMENT_OPTIONS);

  useEffect(() => {
    if (!items) return;
    setUOMs(
      uniqBy(
        uoms.concat(
          items.map((item) => ({
            label: item.UOM,
            value: item.UOM,
          }))
        ),
        "value"
      )
    );
  }, [items]);

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        uoms.concat([{ label: uom, value: uom }]),
        "value"
      );
      setUOMs(newUOMs);
    },
    [uoms, setUOMs]
  );

  return { uoms, handleUOMCreate };
}
