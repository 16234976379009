import { TFunction } from "i18next";
import { filter, isArray } from "lodash";
import {
  AppendDynamicPropsFn,
  DisableFieldFn,
  EnableRowCopyingFn,
  FormikPropGetSetValues,
  GenericFormFields,
  UpdateRowFn,
  UpdateTextInputFn,
  UpdateToggleFn,
} from "../../generic-form/GenericFormBody";
import {
  CreateCostingCategoryPayload,
  CreateCostingItemPayload,
  UpdateCostingCategoryPayload,
} from "../../../models/salesQuote";
import { calcItemTotal } from "../../../utils/calculations";
import {
  AssemblyCostingItem,
  AssemblyListItem,
} from "../../../models/assembly";
import { UOMOption } from "../../../utils/types/options";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import CostingAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";

export const createUpdateCostingsField = (
  t: TFunction,
  onOpenAssemblyModal: (fieldName: string, value?: string) => void,
  onMarkupPress: (fieldName: string, value?: string) => void,
  onCalculatorOpen: (fieldName: string) => void,
  assemblyOptions: SelectOption[],
  getAssemblyProps: AppendDynamicPropsFn,
  getMarkupProps: AppendDynamicPropsFn,
  uoms: UOMOption[],
  handleUOMCreate: (uom: string) => void,
  handleAssemblyChange: UpdateRowFn,
  handleQuantityChange: UpdateTextInputFn,
  handleCostChange: (key: string) => UpdateTextInputFn,
  // handleCostChange: (key: string) => UpdateTextInputFn,
  handleFieldDisable: DisableFieldFn,
  handlePriceSearch: (value: string) => Promise<SelectOption[]>,
  costsHaveGST: boolean,
  handleCostsHaveGST: UpdateToggleFn,
  handleToggleGSTFree: UpdateToggleFn,
  priceLookupButton: () => JSX.Element,
  handleEnableRowCopying: EnableRowCopyingFn,
  onCreateAssemblyFromSelection: (formikProps?: FormikPropGetSetValues) => void
): GenericFormFields<UpdateCostingCategoryPayload> => {
  // const assemblyOptions = assemblies.map((assembly) => ({
  //   value: assembly._id,
  //   label: assembly.name,
  //   //hidden: true,//assembly._id.indexOf("assembly:") === -1,
  //  // data: assembly,
  // }))
  //const assemblyOptions = [] as SelectOption[];

  const assemblyAsyncOptions = async (value: string) => {
    const searchRegex = new RegExp(value, "i");
    const options = !!value
      ? filter(
          assemblyOptions,
          (option) => option.label.search(searchRegex) >= 0
        )
      : assemblyOptions;

    if (!value) return [];

    const result = await handlePriceSearch(value);
    return [...options, ...result];
  };

  return {
    category: [
      {
        type: "text",
        label: t("costing.categoryName"),
        placeholder: t("placeholders.categoryName"),
        valueKey: "name",
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("costing.markupPercentLabel"),
        placeholder: t("placeholders.marginValue"),
        valueKey: "margin_amount",
        inputProps: {
          type: "number",
          required: true,
        },
      },
    ],
    costItems: {
      tableTitle: t("costing.costingItems"),
      rightActionButton: priceLookupButton,
      toggles: [
        {
          name: "costsHaveGST",
          label: t("costing.costsHaveGST"),
          onChange: handleCostsHaveGST,
        },
        {
          name: "toggleGSTFree",
          label: t("costing.toggleGSTFree"),
          onChange: handleToggleGSTFree,
        },
      ],
      rowGenerator: () => ({
        name: "",
        quantity: "",
        assemblyId: "",
        margin_amount: "0",
        cost: "0",
        UOM: "qty",
        note: "",
        gstFree: false,
        hasGST: true,
      }),
      isDraggable: true,
      isCompact: true,
      isTwoRow: true,
      isSelectable: true,
      actionWithSelected: {
        action: onCreateAssemblyFromSelection,
        buttonTitle: t("costing.createAssemblyFromSelection"),
        fieldName: "costItems",
      },
      enableRowCopying: handleEnableRowCopying,
      row: [
        {
          // width: "200px",
          type: "creatable-select",
          label: t("costing.name"),
          placeholder: t("placeholders.name"),
          valueKey: "name",
          updateTableRow: handleAssemblyChange,
          append: {
            icon: "file_copy",
            className: "input-group-append-compact",
            onClick: onOpenAssemblyModal,
            dynamicProps: getAssemblyProps,
          },
          creatableProps: {
            isEditable: true,
            isClearable: true,
            autoSelectCreatedOption: true,
            options: assemblyOptions,
            asyncOptions: assemblyAsyncOptions,
            optionComponent: CostingAutocompleteMenuOption,
            // onCreateOption: handleItemCreate,
            isValidNewOption: (v: string) => v.length > 0,
            hideSelectedOptions: true,
          },
          inputProps: {
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        // {
        //   width: "240px",
        //   type: "select",
        //   label: t("costing.item"),
        //   placeholder: t("placeholders.item"),
        //   valueKey: "assemblyId",
        //   updateTableRow: handleAssemblyChange,
        //   append: {
        //     icon: "file_copy",
        //     className: "text field-text",
        //     onClick: onOpenAssemblyModal,
        //   },
        //   autocompleteProps: {
        //     menuPortalTarget: document.body,
        //     menuPosition: "fixed",
        //     options: assemblies.map((assembly) => ({
        //       value: assembly._id,
        //       label: assembly.name,
        //     })),
        //   },
        //   inputProps: {
        //     type: "text",
        //     required: true,
        //   },
        // },
        {
          width: "125px",
          maxWidth: "125px",
          type: "text",
          label: t("costing.quantity"),
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          disallowNegativeNumber: true,
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
          textInputProps: {
            onValueChange: handleQuantityChange,
          },
          append: {
            icon: "calculate",
            onClick: onCalculatorOpen,
            className: "input-group-append-compact",
          },
        },
        {
          width: "125px",
          maxWidth: "125px",
          type: "creatable-select",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        costsHaveGST
          ? {
              width: "140px",
              maxWidth: "140px",
              type: "text",
              label: t("costing.costInc"),
              placeholder: t("placeholders.costValue"),
              valueKey: "cost_inc",
              disableRowField: handleFieldDisable,
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
                debounceChangeEvent: true,
              },
              textInputProps: {
                onValueChange: handleCostChange("cost_inc"),
              },
            }
          : {
              width: "140px",
              maxWidth: "140px",
              type: "text",
              label: t("costing.cost"),
              placeholder: t("placeholders.costValue"),
              valueKey: "cost",
              disableRowField: handleFieldDisable,
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
                debounceChangeEvent: true,
              },
              textInputProps: {
                onValueChange: handleCostChange("cost"),
              },
            },
        {
          width: "140px",
          maxWidth: "140px",
          type: "text",
          label: t("costing.markupPercentLabel"),
          placeholder: t("placeholders.marginValue"),
          valueKey: "margin_amount",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
          append: {
            onClick: onMarkupPress,
            dynamicProps: getMarkupProps,
            className: "input-group-append-compact",
          },
        },
        {
          width: "100px",
          maxWidth: "100px",
          type: "toggle",
          valueKey: "gstFree",
          label: t("costing.gstFree"),
          toggleProps: {
            label: "",
          },
        },
        {
          type: "text",
          controlType: "textarea",
          label: t("costing.note"),
          placeholder: "",
          valueKey: "note",
          secondRow: true,
          inputProps: {
            className: "form-input-compact",
            type: "text",
            rows: 2,
            debounceChangeEvent: true,
          },
        },
      ],
      dynamicFields: [
        {
          title: costsHaveGST ? t("costing.totalInc") : t("costing.totalEx"),
          maxWidth: "160px",
          formatValue: (
            values: CreateCostingItemPayload,
            formValues: CreateCostingCategoryPayload
          ) => {
            const amount =
              values?.quantity && (costsHaveGST ? values.cost_inc : values.cost)
                ? calcItemTotal(
                    {
                      margin_amount: Number(values?.margin_amount),
                      cost:
                        Number(
                          costsHaveGST && !values.gstFree
                            ? values.cost_inc
                            : values.cost
                        ) || 0,
                      quantity: Number(values?.quantity),
                    },
                    Number(formValues?.margin_amount)
                  )
                : 0;

            return t("common.currency", { amount });
          },
        },
      ],
    },
  };
};

export const addAssemblyItem = (
  assembly: AssemblyCostingItem | AssemblyCostingItem[]
) => {
  return !isArray(assembly)
    ? {
        items: [
          {
            UOM: assembly.UOM,
            cost: assembly.cost,
            name: assembly.name,
            quantity: assembly.quantity,
            raw_quantity: assembly.raw_quantity,
            total: assembly.total,
          },
        ],
      }
    : {
        items: assembly.map((item) => ({
          UOM: item.UOM,
          cost: item.cost,
          name: item.name,
          quantity: item.quantity,
          raw_quantity: item.raw_quantity,
          total: item.total,
        })),
      };
};

export const createNewRow = () => ({
  UOM: "",
  assemblyId: "",
  cost: "0",
  real_cost: "0",
  gstFree: false,
  hasGST: true,
  items: [],
  margin_amount: "0",
  name: "",
  note: "",
  quantity: 0,
  type: "",
  _id: "",
});
