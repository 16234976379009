import React, { ReactElement, useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { GSTCResult } from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import { LIST_JOBS_SCHEDULE } from "../../graphql/queries/job-schedule/queries";
import {
  JobsSchedule,
  ListJobsSchedule,
} from "../../graphql/types/models/job-schedule";
import EmptyPlaceholder from "../../components/empty-placeholder";
import CalendarWrapper from "../../components/calendar";
import SetNavigationRoute from "../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../components/dashboard/sidebar/utils/navigation-items";
import { createState } from "../../components/calendar/utils";
import {
  createScheduleConfig,
  createScheduleItems,
  createScheduleRows,
  getCategories,
  getTasks,
} from "./utils";
import DashboardCardBody from "../../components/dashboard/card/DashboardCardBody";
import DashboardCard from "../../components/dashboard/card";
import DashboardCardHeader from "../../components/dashboard/card/DashboardCardHeader";
import SVGIcon from "../../components/icons/SVGIcon";
import {
  AssigneeFilterFn,
  AssigneeType,
  getAssigneesFilter,
} from "../jobs/job-schedule/hooks/useScheduleFilter";
import { JobDetails, ScheduleCategoryItem } from "../../models/job";
import { SupplierDetails } from "../../models/supplier";
import { User } from "../../graphql/types/models/user";
import Dropdown from "../../components/dashboard/dropdown";
import { getFullName } from "../../utils/text";
import Avatar from "../../components/avatar";
import UserDetailsModal, {
  UserDetailsModalRef,
} from "../../components/job-schedule/user-details-modal";
import "./styles.scss";

type CalendarContainerProps = {};

type ScheduleFilter = {
  assignee: User | SupplierDetails | null;
  assigneeType: AssigneeType;
  job: JobDetails | null;
};

const CalendarContainer: React.FC<CalendarContainerProps> = () => {
  const { t } = useTranslation();

  const userDetailsRef = useRef<UserDetailsModalRef>(null);

  const { data: scheduleData } = useQuery<ListJobsSchedule>(
    LIST_JOBS_SCHEDULE,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const renderAvatar = (
    user?: User,
    supplier?: SupplierDetails
  ): ReactElement => {
    if (!user && !supplier) return <div />;

    const name = user ? getFullName(user) : supplier?.business_name;

    return (
      <Avatar
        rounded
        height="24px"
        width="24px"
        iconSize="24px"
        userName={name}
      />
    );
  };

  const [calendarApp, setApp] = React.useState<GSTCResult | null>(null);

  const schedule = React.useMemo(() => scheduleData?.listJobsSchedule ?? [], [
    scheduleData,
  ]);

  const taskCategories = React.useMemo(() => {
    return getCategories(schedule);
  }, [getCategories, schedule]);

  const tasks = React.useMemo(() => {
    return getTasks(taskCategories);
  }, [taskCategories]);

  const createCalendarState = React.useCallback(() => {
    return createState(createScheduleConfig(t, renderAvatar, userDetailsRef));
  }, []);

  const calendarStateRef = useRef<any>(createCalendarState());
  const [scheduleState, setScheduleState] = React.useState<JobsSchedule[]>([]);
  const [scheduleFilter, setScheduleFilter] = React.useState<ScheduleFilter>({
    assignee: null,
    assigneeType: AssigneeType.User,
    job: null,
  });

  const getFilteredStateByJobs = React.useCallback(() => {
    if (scheduleFilter.job) {
      return schedule.filter(
        (item) => item.job._id === scheduleFilter.job?._id
      );
    } else {
      return schedule;
    }
  }, [scheduleFilter.job, schedule]);

  const getFilteredStateByAssignee = React.useCallback(
    (state: JobsSchedule[]) => {
      if (!scheduleFilter.assignee) return state;

      const jobsCategories = getCategories(state);
      const categoriesWithFilteredTasks: Record<
        string,
        ScheduleCategoryItem[]
      > = {};
      jobsCategories.forEach((category) => {
        if (category?.items) {
          const filteredTasks = category.items.filter((task) =>
            scheduleFilter.assigneeType === AssigneeType.User
              ? task.user?._id === scheduleFilter?.assignee?._id
              : task.supplier?._id === scheduleFilter?.assignee?._id
          );
          categoriesWithFilteredTasks[category._id] = filteredTasks;
        }
      });

      const filteredState = state.map((job) => {
        const allCategories = job.categories.map((category) => {
          return {
            ...category,
            items: categoriesWithFilteredTasks[category._id],
          };
        });
        const categories = allCategories.filter(
          (category) => category.items.length !== 0
        );
        return { ...job, categories };
      });

      return filteredState.filter((job) => job.categories.length !== 0);
    },
    [getCategories, scheduleFilter]
  );

  const handleFilters = React.useCallback(() => {
    const statefilteredByJobs = getFilteredStateByJobs();
    const statefilteredByAssignee = getFilteredStateByAssignee(
      statefilteredByJobs
    );
    setScheduleState(statefilteredByAssignee);
  }, [getFilteredStateByAssignee, getFilteredStateByJobs]);

  React.useEffect(() => {
    if (scheduleFilter.job || scheduleFilter.assignee) {
      handleFilters();
    } else {
      setScheduleState(schedule);
    }
  }, [schedule]);

  React.useEffect(() => {
    handleFilters();
  }, [scheduleFilter]);

  React.useEffect(() => {
    if (isEmpty(scheduleState)) {
      return;
    }

    const items = createScheduleItems(scheduleState, schedule);
    const rows = createScheduleRows(scheduleState, t);

    calendarStateRef.current?.update("config", (config: any) => {
      config.list.rows = rows;
      config.chart.items = items;
      return config;
    });
  }, [scheduleState]);

  React.useEffect(() => {
    if (!calendarApp) {
      return;
    }
    calendarApp.api.scrollToTime(
      parseInt(moment().startOf("day").format("x")),
      false
    );
  }, [calendarApp]);

  const handleTimelineToggle = React.useCallback(() => {
    const value = calendarStateRef.current?.get("config.list.columns.percent");
    calendarStateRef.current?.update(
      "config.list.columns.percent",
      !value ? 100 : 0
    );
  }, []);

  const handleZoomIn = React.useCallback(() => {
    if (calendarStateRef.current?.get("config.chart.time.zoom") === 18) return; // it breaks going below 18
    calendarStateRef.current?.update(
      "config.chart.time.zoom",
      calendarStateRef.current?.get("config.chart.time.zoom") - 1
    );
  }, []);

  const handleZoomOut = React.useCallback(() => {
    calendarStateRef.current?.update(
      "config.chart.time.zoom",
      calendarStateRef.current?.get("config.chart.time.zoom") + 1
    );
  }, []);

  const handleCalendarCreate = React.useCallback((app: any) => {
    setApp(app);
  }, []);

  const onFilterAssignee = React.useCallback<AssigneeFilterFn>(
    (assignee, assigneeType) => {
      const newFilter = { ...scheduleFilter, assignee, assigneeType };
      setScheduleFilter(newFilter);
    },
    [scheduleFilter]
  );

  const assigneeItems = React.useMemo(() => {
    if (!tasks) return [];
    return getAssigneesFilter(t, tasks, onFilterAssignee);
  }, [tasks, onFilterAssignee]);

  const renderAssigneeFilter = React.useCallback(() => {
    let label = t("schedule.filterAssignee");
    if (scheduleFilter?.assignee) {
      if (scheduleFilter?.assigneeType === AssigneeType.Supplier) {
        label = (scheduleFilter.assignee as SupplierDetails).business_name;
      } else {
        label = getFullName(scheduleFilter.assignee as User);
      }
    }
    return (
      <Dropdown
        label={label}
        icon="expand_more"
        id="assignee-dropdown"
        items={assigneeItems}
      />
    );
  }, [assigneeItems, scheduleFilter.assignee, scheduleFilter?.assigneeType, t]);

  const handleJobFilterChange = React.useCallback(
    (filterJob: JobDetails | null) => {
      if (!scheduleFilter.job && !filterJob) return;
      if (scheduleFilter.job?._id === filterJob?._id) return;
      const newFilter = { ...scheduleFilter, job: filterJob };
      setScheduleFilter(newFilter);
    },
    [scheduleFilter]
  );

  const jobItems = React.useMemo(() => {
    return [
      {
        id: "all-jobs",
        label: t("calendar.allJobs"),
        onClick: () => handleJobFilterChange(null),
      },
    ].concat(
      schedule.map((item) => {
        return {
          id: item.job._id,
          label: item.job.name,
          onClick: () => handleJobFilterChange(item.job),
        };
      })
    );
  }, [t, handleJobFilterChange, schedule]);

  const renderJobsFilter = React.useCallback(() => {
    let label = t("calendar.allJobs");
    if (scheduleFilter?.job) {
      label = scheduleFilter?.job.name;
    }
    return (
      <Dropdown
        label={label}
        icon="expand_more"
        id="jobs-dropdown"
        items={jobItems}
      />
    );
  }, [jobItems, scheduleFilter?.job, t]);

  return (
    <div className="calendar-overview global-schedule">
      <Helmet title={t("navigation.schedule")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.GLOBAL_SCHEDULE} />
      <UserDetailsModal ref={userDetailsRef} />
      <DashboardCard className="schedule-body">
        <DashboardCardHeader className="d-flex">
          <div>{t("schedule.timeline")}</div>
          <div className="actions-container">
            {renderJobsFilter()}
            {renderAssigneeFilter()}
            <div className="action" onClick={handleTimelineToggle}>
              <SVGIcon name="Timeline" />
            </div>
            <div className="action" onClick={handleZoomIn}>
              <SVGIcon name="ZoomIn" />
            </div>
            <div className="action" onClick={handleZoomOut}>
              <SVGIcon name="ZoomOut" />
            </div>
          </div>
        </DashboardCardHeader>
        <DashboardCardBody>
          {schedule?.length ? (
            <CalendarWrapper
              state={calendarStateRef.current}
              onCreate={handleCalendarCreate}
            />
          ) : (
            <EmptyPlaceholder message={t("schedule.noJobSchedules")} />
          )}
        </DashboardCardBody>
      </DashboardCard>
    </div>
  );
};

export default CalendarContainer;
