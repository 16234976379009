import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function updateCostingSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "costing.categoryName")
      ),
    costItems: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "common.name")
          ),
        quantity: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "common.quantity")
          ),
        UOM: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.uom")
          ),
        cost: yup
          .number()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.cost")
          ),
      })
    ),
  });
}
