import { BadgeProps } from "react-bootstrap/Badge";
import { PurchaseOrderStatus } from "../../../models/purchaseOrder";
import { RosterStatus } from "../../../models/roster";
import { VariationStatuses } from "../../../models/variations";

export const getOrderBadgeVariant = (
  status: PurchaseOrderStatus | VariationStatuses | RosterStatus
): BadgeProps["variant"] => {
  if (
    status === PurchaseOrderStatus.SENT ||
    status === VariationStatuses.AWAITING_CLIENT_APPROVAL
  ) {
    return "warning";
  } else if (
    status === PurchaseOrderStatus.RECEIVED ||
    status === VariationStatuses.APPROVED_BY_BUILDER ||
    status === RosterStatus.APPROVED
  ) {
    return "success";
  } else if (status === PurchaseOrderStatus.PARTIALLY_RECEIVED) {
    return "info";
  } else if (
    status === PurchaseOrderStatus.CANCELLED ||
    status === VariationStatuses.CANCELLED
  ) {
    return "danger";
  }
  return "secondary";
};
