import { gql } from "@apollo/client";

export const WB_ADMIN_PROFILE = gql`
  query {
    wbAdminProfile {
      _id
      first_name
      last_name
      email
    }
  }
`;
