import React from "react";
import { map } from "lodash";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import { useTranslation } from "react-i18next";
import { ScheduleCategoryItem } from "../../../models/job";
import CardPlaceholder from "../../dashboard/card-placeholder";
import DashboardTaskItem from "../../dashboard-overview/task/TaskItem";
import EmptyPlaceholder from "../../empty-placeholder";
import "./styles.scss";

type ScheduleProps = {
  loading: boolean;
  scheduleItems?: ScheduleCategoryItem[];
};

const Schedule: React.FC<ScheduleProps> = (props) => {
  const { loading, scheduleItems } = props;

  const { t } = useTranslation();

  if (loading) {
    return <CardPlaceholder hideFooter={true} />;
  }

  const renderScheduleItems = () => {
    if (!scheduleItems?.length) {
      return (
        <EmptyPlaceholder message={t("jobOverview.emptyTaskPlaceholder")} />
      );
    }
    return map(scheduleItems, (item) => (
      <DashboardTaskItem t={t} task={item} showJobName={false} />
    ));
  };

  return (
    <DashboardCard>
      <DashboardCardHeader>
        {t("jobOverview.thisWeeksTasks")}
      </DashboardCardHeader>
      <DashboardCardBody>{renderScheduleItems()}</DashboardCardBody>
    </DashboardCard>
  );
};

export default Schedule;
