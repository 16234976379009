import * as yup from "yup";
import { TFunction } from "i18next";
import moment from "moment";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createTimesheetSchema(t: TFunction) {
  return yup.object().shape({
    job: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "timesheets.job")
      ),
    task: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "timesheets.task")
      ),
    startDate: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "timesheets.startDate")
      ),
    endDate: yup
      .string()
      .test("endDateTest", "End Date Test Message", function (value: string) {
        const { startDate } = this.parent;
        return moment(value).isAfter(startDate);
      })
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "timesheets.endDate")
      ),
    breakDuration: yup
      .string()
      .test("breakDurationTest", "End Date Test Message", function (
        value: string
      ) {
        const { startDate, endDate } = this.parent;
        if (startDate && endDate) {
          return moment(endDate).subtract(value, "minutes").diff(startDate) > 0;
        }
        return true;
      })
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "timesheets.breakDuration"
        )
      ),
  });
}
