import * as yup from "yup";
import { TFunction } from "i18next";

import { ValidationMessagesUtils } from "../../../utils/yup";
import { ScheduleTemplate } from "../../../models/job";
import { find, trim } from "lodash";

export default function saveScheduleAsTemplateSchema(
  t: TFunction,
  scheduleTemplates: ScheduleTemplate[]
) {
  return yup.object().shape({
    scheduleTemplate: yup.object().shape({
      name: yup
        .string()
        .test(
          "duplicateNameTest",
          ({ value }) => t("schedule.templateNameExists", { name: value }),
          function (value: string) {
            if (!value) return true;
            const name = trim(value);
            return !find(scheduleTemplates, { name });
          }
        )
        .required(
          ValidationMessagesUtils.createRequiredMessage(
            t,
            "schedule.templateName"
          )
        ),
    }),
  });
}
