import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "@apollo/client";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { compact } from "lodash";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import { RootReducerState } from "../../../../redux/reducer";
import DetailsCard from "../../../../components/dashboard/details-card";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import HeaderPageAction from "../../../../components/dashboard/header/HeaderPageAction";
import HeaderActionButton from "../../../../components/dashboard/header/HeaderActionButton";
import ChangePasswordModal from "../../../../components/profile/change-pwd-modal";
import { ChangePasswordPayload } from "../../../../models/profile";
import { DetailsCardDataItem } from "../../../../components/dashboard/details-card/DetailsCardList";
import { updateProfile } from "../../../../redux/authentication/actions";
import { getFullAddress, getFullName } from "../../../../utils/text";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/affiliate-navigation-items";
import { AffiliateProfileResponse } from "../../../../graphql/types/models/affiliate";
import { AFFILIATE_PROFILE } from "../../../../graphql/queries/affiliate/queries";
import { AFFILIATE_CHANGE_PASSWORD } from "../../../../graphql/queries/affiliate/mutations";
import { ChangePasswordResponse } from "../../../../graphql/types/models/auth";
import { notify } from "../../../../components/notification";
import GraphQLClient from "../../../../graphql";
import DashboardCard from "../../../../components/dashboard/card";
import DashboardCardHeader from "../../../../components/dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../../../components/dashboard/card/DashboardCardBody";

type ProfileOverviewProps = DashboardContextValue & {};

const AffiliateProfileOverview: React.FC<ProfileOverviewProps> = (props) => {
  const { t } = useTranslation();

  const [showPasswordModal, setPwdModalVisibility] = useState(false);

  const { data: affiliateData } = useQuery<AffiliateProfileResponse>(
    AFFILIATE_PROFILE
  );

  const affiliate = React.useMemo(() => affiliateData?.affiliateProfile, [
    affiliateData,
  ]);

  const [changePassword] = useMutation<ChangePasswordResponse>(
    AFFILIATE_CHANGE_PASSWORD,
    {
      onCompleted: ({ changePassword }) => {
        GraphQLClient.setToken(changePassword.token);
        setPwdModalVisibility(false);
        notify({
          title: t("profile.changePassword"),
          content: t("profile.success.changePassword"),
        });
      },
      onError: () => {
        notify({
          title: t("profile.changePassword"),
          content: t("profile.errors.changePassword"),
          error: true,
        });
      },
    }
  );

  const openPwdModal = React.useCallback(() => {
    setPwdModalVisibility(true);
  }, []);

  const closePwdModal = React.useCallback(() => {
    setPwdModalVisibility(false);
  }, []);

  const profileCardData: DetailsCardDataItem[] = React.useMemo(() => {
    return compact([
      {
        icon: "business",
        title: t("affiliate.businessName"),
        text: affiliate?.business_name || t("common.na"),
      },
      {
        icon: "face",
        title: t("affiliate.name"),
        text: getFullName(affiliate),
      },
      {
        icon: "shield",
        title: t("company.abnNumber"),
        text: affiliate?.ABN || t("common.na"),
      },
      {
        icon: "room",
        title: t("company.address"),
        text: getFullAddress(affiliate),
      },
      {
        icon: "local_phone",
        title: t("authentication.phoneNumber"),
        text: affiliate?.phone || t("common.na"),
      },
      {
        icon: "mail",
        title: t("authentication.emailAddress"),
        text: affiliate?.email || t("common.na"),
      },
    ]);
  }, [affiliate]);

  const handlePwdChangeSubmit = React.useCallback(
    (values: ChangePasswordPayload) => {
      return changePassword({
        variables: {
          old_password: values.old_password,
          new_password: values.new_password,
        },
      });
    },
    []
  );

  return (
    <Container fluid className="m-0 p-0 h-100 profile-overview">
      <Helmet title={t("navigation.account.profile")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE} />
      <HeaderPageAction>
        <HeaderActionButton icon="lock" outlined onClick={openPwdModal}>
          {t("profile.changePassword")}
        </HeaderActionButton>
      </HeaderPageAction>

      <ChangePasswordModal
        onSubmit={handlePwdChangeSubmit}
        onClose={closePwdModal}
        show={showPasswordModal}
      />

      <Row className="h-100">
        <Col lg={6} xs={12} xl={4}>
          <DetailsCard
            title={t("profile.details")}
            fullHeight
            data={profileCardData}
          />
        </Col>
        <Col lg={6} xs={12} xl={8}>
          <DashboardCard className="affiliate-link">
            <DashboardCardHeader>
              <div>{t("affiliate.affiliateLink")}</div>
            </DashboardCardHeader>
            <DashboardCardBody className="conversation-messages">
              <p>{t("affiliate.affiliateLinkIntro")}</p>
              <p>{t("affiliate.affiliateLinkDetails")}</p>
              <br />
              <h4 className="pl-5">{affiliate?.affiliateUrl}</h4>
            </DashboardCardBody>
          </DashboardCard>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default withDashboardContext(
  connect(mapStateToProps, { updateProfile })(AffiliateProfileOverview)
);
