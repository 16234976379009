import { Modifier, DraftInlineStyle } from "draft-js";
import { EditorState, ContentBlock, ContentState } from "react-draft-wysiwyg";

export type DecoratorCb = (start: number, end: number) => void;

export const getEntityStrategy = (entityName: string) => {
  return (
    contentBlock: ContentBlock,
    callback: DecoratorCb,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === entityName
      );
    }, callback);
  };
};

export const insertText = (
  editorState: EditorState,
  text: string,
  entityKey?: string,
  inlineStyle?: DraftInlineStyle
) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  let newContentState: ContentState;

  if (selection.isCollapsed()) {
    newContentState = Modifier.insertText(
      contentState,
      selection,
      text,
      inlineStyle,
      entityKey
    );
  } else {
    newContentState = Modifier.replaceText(
      contentState,
      selection,
      text,
      inlineStyle,
      entityKey
    );
  }

  return EditorState.push(editorState, newContentState, "insert-characters");
};
