import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import {
  getMoveDirectiondOptions,
  getMovePeriodOptions,
} from "../../../utils/options";
import { BulkUpdateSchedulePayload } from "../../../models/job";

export const createRescheduleFields = (
  t: TFunction
): GenericFormFields<BulkUpdateSchedulePayload> => {
  return {
    moveNumber: {
      type: "text",
      label: t("schedule.moveNumber"),
      placeholder: t("schedule.moveNumber"),
      valueKey: "move.moveNumber",
      inputProps: {
        type: "number",
        required: true,
      },
    },
    period: {
      type: "button-select",
      label: t("schedule.period"),
      valueKey: "move.period",
      buttonSelectProps: {
        options: getMovePeriodOptions(t),
      },
    },
    direction: {
      type: "select",
      label: t("schedule.direction"),
      placeholder: t("schedule.direction"),
      valueKey: "move.direction",
      autocompleteProps: {
        options: getMoveDirectiondOptions(t),
      },
    },
  };
};
