import { gql } from "@apollo/client";

export const CREATE_TAKEOFF_ITEM = gql`
  mutation CreateTakeoffItem($salesQuoteId: ID!, $takeOffItem: TakeOffInput) {
    createTakeOffItem(salesQuoteId: $salesQuoteId, takeOffItem: $takeOffItem) {
      _id
      name
      quantity
      UOM
      shapes {
        name
        angle
        depth
        wallHeight
        materialWidth
        weight
        geometry {
          lat
          lng
        }
        type
        page
        leaflet_id
      }
      properties {
        color
      }
    }
  }
`;

export const DELETE_TAKEOFF_ITEM = gql`
  mutation DeleteTakeoffItem($takeOffItemId: ID!) {
    deleteTakeOffItem(takeOffItemId: $takeOffItemId) {
      _id
    }
  }
`;

export const UPDATE_TAKEOFF_ITEMS = gql`
  mutation UpdateTakeoffItem(
    $salesQuoteId: ID!
    $takeOffItems: [TakeOffInput]
  ) {
    updateTakeOffItems(
      salesQuoteId: $salesQuoteId
      takeOffItems: $takeOffItems
    ) {
      _id
      takeOffItems {
        _id
        name
        quantity
        UOM
        shapes {
          name
          angle
          depth
          wallHeight
          weight
          materialWidth
          geometry {
            lat
            lng
          }
          type
          page
          leaflet_id
          properties {
            text
          }
        }
        properties {
          color
        }
      }
    }
  }
`;
