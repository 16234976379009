import classNames from "classnames";
import { CRS, LatLngBoundsLiteral } from "leaflet";
import React from "react";
import { Map } from "react-leaflet";
import "leaflet-fullscreen";
import { SalesQuoteFile } from "../../../models/salesQuote";
import {
  PlanViewerContext,
  PlanViewerContextOptions,
} from "./PlanViewerContext";

import PlanViewerImageOverlay from "./PlanViewerImageOverlay";
import "./styles.scss";

type PlanViewerProps = {
  planFile: SalesQuoteFile;
  horizontal?: boolean;
  onBoundsChange?: (bounds: number[]) => void;
  className?: string;
};

const PlanViewer: React.FC<PlanViewerProps> = ({
  planFile,
  horizontal,
  onBoundsChange,
  className,
  children,
}) => {
  const [bounds, setBounds] = React.useState<number[]>([1000, 1000]);
  const [isHorizontal, setIsHorizontal] = React.useState<boolean>(!!horizontal);

  const handleOnRotateMap = React.useCallback((horizontal?: boolean) => {
    // isHorizontalRef.current = !isUndefined(horizontal) ? horizontal : !isHorizontalRef.current;
    // setIsHorizontal(isHorizontalRef.current)
  }, []);

  const handleBoundsChange = React.useCallback(
    (newBounds: number[]) => {
      setBounds(newBounds);
      onBoundsChange?.(newBounds);
    },
    [setBounds, onBoundsChange]
  );

  const planViewerContext: PlanViewerContextOptions = {
    isHorizontal: isHorizontal,
    onMapRotate: handleOnRotateMap,
  };

  const fileUrl = React.useMemo(() => {
    return planFile.url || "";
  }, [planFile]);

  return (
    <PlanViewerContext.Provider value={planViewerContext}>
      <div className={classNames(className, "plan-viewer")}>
        <Map
          crs={CRS.Simple}
          className={classNames("plan-viewer-map", {
            horizontal: isHorizontal,
          })}
          center={[51.5, -0.06]}
          bounds={[[0, 0], bounds] as LatLngBoundsLiteral}
          zoom={1000}
          minZoom={-4}
          onRotateMap={null}
          maxNativeZoom={18}
          fullscreenControl
        >
          {children}
          <PlanViewerImageOverlay
            url={fileUrl}
            bounds={bounds}
            onBoundsChange={handleBoundsChange}
          />
        </Map>
      </div>
    </PlanViewerContext.Provider>
  );
};

export default PlanViewer;
