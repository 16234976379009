import React from "react";
import L, { FeatureGroup, Map } from "leaflet";
import "leaflet-draw";
import { get, set } from "lodash";
import { Calibration, SalesQuoteFile } from "../../../models/salesQuote";
import {
  TakeOffMeasurement,
  TakeoffListItem,
  TakeOffShape,
  SerializedLayer,
} from "../../../models/take-off";
import PlanViewer from "../plan-viewer";
import usePlanViewerComment from "../plan-viewer/comment/usePlanViewerComment";
import PlanViewerToolbar, {
  PlanViewerDrawOptions,
} from "../plan-viewer/PlanViewerToolbar";

import "./styles.scss";
import {
  COMMENT_PATH,
  createShapeLabel,
  deserializeShapes,
  getMarkerIcon,
  serializeLayer,
} from "./utils";

type TakeOffPlanViewerProps = {
  planFile: SalesQuoteFile;
  takeOff: TakeoffListItem | null;
  shapes: TakeOffShape[];
  calibrations: Calibration[];
  page: number;
  isLocked: Boolean;
  isSelectedNewTakeOff?: Boolean;
  onShapesUpdate: (shapes: TakeOffShape[]) => void;
  onBoundsChange?: (bounds: number[]) => void;
};

export type TakeOffPlanViewerRef = {
  getMap: () => Map | null;
  focusOnShape: (shape: TakeOffShape) => void;
};

const TakeOffPlanViewer: React.FC<TakeOffPlanViewerProps> = (
  {
    planFile,
    takeOff,
    shapes,
    calibrations,
    page,
    isLocked,
    onShapesUpdate,
    onBoundsChange,
    isSelectedNewTakeOff,
  },
  ref
) => {
  const takeOffItemsRef = React.useRef<{
    shapes: TakeOffShape[];
  }>({
    shapes: shapes,
  });

  const [wallHeight, setWallHeight] = React.useState<number | undefined>(
    undefined
  ); //(DEFAULT_WALL_HEIGHT);
  const [materialWidth, setMaterialWidth] = React.useState<number | undefined>(
    undefined
  );
  const [measureWeight, setMeasureWeight] = React.useState<number | undefined>(
    undefined
  );
  const [pitchAngle, setPitchAngle] = React.useState<number | undefined>(0);
  const [depth, setDepth] = React.useState<number | undefined>(undefined);
  const mapRef = React.useRef<Map | null>(null);
  const featureGroupRef = React.useRef<FeatureGroup | null>(null);

  React.useImperativeHandle(
    ref,
    () => ({
      getMap: () => mapRef.current,
      focusOnShape: (shape: TakeOffShape) => {
        let shapeLayer: any;
        mapRef.current?.eachLayer((layer: any) => {
          if (shape.leaflet_id === layer._leaflet_id) {
            shapeLayer = layer;
          }
        });

        if (shapeLayer) {
          const center = shapeLayer.getCenter?.() || shapeLayer.getLatLng?.();
          if (center) {
            mapRef.current?.panTo(center);
            if (shapeLayer.layerType === "comment") {
              const marker = shapeLayer as L.Marker;
              marker.setIcon?.(getMarkerIcon("#000000", "draw-icon"));
              setTimeout(() => {
                marker.setIcon?.(
                  getMarkerIcon(takeOff?.properties.color, "draw-icon")
                );
              }, 500);
            } else {
              const path = shapeLayer as L.Path;
              path.setStyle?.({ color: "#000000" });
              setTimeout(() => {
                path.setStyle?.({
                  color:
                    shapeLayer.layerType === "deduction" ||
                    shapeLayer.layerType === "rectangleDeduction" ||
                    shapeLayer.layerType === "polylineDeduction"
                      ? "#FF0000"
                      : takeOff?.properties.color,
                });
              }, 500);
            }
          }
        }
      },
    }),
    [takeOff]
  );

  const polylineOptions = React.useMemo(
    () => ({
      allowIntersection: false,
      showLength: true,
      icon: getMarkerIcon(takeOff?.properties.color, "draw-icon"),
      shapeOptions: {
        stroke: true,
        opacity: 1,
        color: takeOff?.properties.color,
      },
      metric: true,
      calibrations,
      UOM: takeOff?.UOM,
    }),
    [takeOff, calibrations, planFile]
  );

  const handleChangePitchAngle = React.useCallback((value: number) => {
    setPitchAngle(Math.max(Math.min(value, 85), 0)); // between 0 and 85
    // Hack to share pitch with Rectangle/Polygon tooltip
    set(L.Draw.Rectangle.prototype, "options.angle", value);
    set(L.Draw.Polygon.prototype, "options.angle", value);
    set(L.Draw.Polyline.prototype, "options.angle", value);
  }, []);

  const handleChangeWallHeight = React.useCallback((value: number) => {
    setWallHeight(value);
    // Hack to share wallHeight with Polyline tooltip
    set(L.Draw.Polyline.prototype, "options.wallHeight", value);
  }, []);

  const handleChangeMeasureWeight = React.useCallback((value: number) => {
    setMeasureWeight(value);
    set(L.Draw.Polyline.prototype, "options.weight", value);
  }, []);

  const handleChangeMaterialWidth = React.useCallback((value: number) => {
    setMaterialWidth(value);
    // Hack to share materialWidth with Rectangle/Polygon tooltip
    set(L.Draw.Rectangle.prototype, "options.materialWidth", value);
    set(L.Draw.Polygon.prototype, "options.materialWidth", value);
  }, []);

  const handleChangeDepth = React.useCallback((value: number) => {
    setDepth(value);
    // Hack to share depth with Rectangle/Polygon tooltip
    set(L.Draw.Rectangle.prototype, "options.depth", value);
    set(L.Draw.Polygon.prototype, "options.depth", value);
  }, []);

  const takeOffToolbarOptions = React.useMemo<PlanViewerDrawOptions>(() => {
    const options: PlanViewerDrawOptions = {
      circle: false,
      circlemarker: false,
      polygon: false,
      polyline: false,
      rectangle: false,
      marker: false,
      comment: false,
      deduction: false,
      polylineDeduction: false,
    };

    if (isLocked) return options;

    const polyLine = polylineOptions;

    const polyGon = {
      showLength: true,
      showArea: true,
      shapeOptions: {
        stroke: true,
        lineCap: "round",
        opacity: 1,
        color: takeOff?.properties.color,
        fillColor: takeOff?.properties.color,
      },
      metric: true,
      icon: getMarkerIcon(takeOff?.properties.color, "draw-icon"),
      calibrations,
      UOM: takeOff?.UOM,
    };

    const deductionPolygon = {
      showLength: true,
      showArea: true,
      shapeOptions: {
        stroke: true,
        lineCap: "round",
        opacity: 1,
        color: "#FF0000",
        fillColor: "#FF0000",
      },
      metric: true,
      icon: getMarkerIcon("#FF0000", "draw-icon"),
      calibrations,
      UOM: takeOff?.UOM,
    };

    const deductionPolyline = {
      allowIntersection: false,
      showLength: true,
      icon: getMarkerIcon("#FF0000", "draw-icon"),
      shapeOptions: {
        stroke: true,
        opacity: 1,
        color: "#FF0000",
      },
      metric: true,
      calibrations,
      UOM: takeOff?.UOM,
    };

    const rectangle = {
      showArea: true,
      shapeOptions: {
        stroke: true,
        lineCap: "round",
        opacity: 1,
        color: takeOff?.properties.color,
        fillColor: takeOff?.properties.color,
      },
      metric: true,
      calibrations,
      UOM: takeOff?.UOM,
    };

    const deductionRectangle = {
      showArea: true,
      shapeOptions: {
        stroke: true,
        lineCap: "round",
        opacity: 1,
        color: "#FF0000",
        fillColor: "#FF0000",
      },
      metric: true,
      calibrations,
      UOM: takeOff?.UOM,
    };

    const comment = {
      repeatMode: false,
      icon: getMarkerIcon(takeOff?.properties.color, "draw-icon"),
      toolbar: { undo: false, finish: false },
    };

    if (takeOff?.UOM === TakeOffMeasurement.QUANTITY) {
      options.marker = {
        repeatMode: true,
        icon: getMarkerIcon(takeOff?.properties.color, "draw-icon"),
      };

      return options;
    }

    if (
      takeOff?.UOM === TakeOffMeasurement.MILLIMETRE ||
      takeOff?.UOM === TakeOffMeasurement.LINEAR_METER
    ) {
      options.polyline = polyLine;
      options.polylineDeduction = deductionPolyline;
      options.deduction = deductionPolygon as any;
      options.rectangleDeduction = deductionRectangle as any;
      options.comment = comment;
      options.polygon = polyGon as any;
      options.rectangle = rectangle as any;
    }
    if (takeOff?.UOM === TakeOffMeasurement.TONNE) {
      options.polyline = polyLine;
      options.polylineDeduction = deductionPolyline;
      options.comment = comment;
    }

    if (takeOff?.UOM === TakeOffMeasurement.METER_SQUARED) {
      options.polyline = polyLine;
      options.comment = comment;
      options.polygon = polyGon as any;
      options.deduction = deductionPolygon as any;
      options.polylineDeduction = deductionPolyline;
      options.rectangleDeduction = deductionRectangle as any;
      options.rectangle = rectangle as any;
    }

    if (takeOff?.UOM === TakeOffMeasurement.CUBIC_METER) {
      options.comment = comment;
      options.polygon = polyGon as any;
      options.deduction = deductionPolygon as any;
      options.rectangleDeduction = deductionRectangle as any;
      options.rectangle = rectangle as any;
    }

    return options;
  }, [takeOff, calibrations, isLocked, polylineOptions]);

  const handleCommentCreate = React.useCallback(
    (layer: L.Marker, data: GeoJSON.Feature) => {
      const items: SerializedLayer[] = [];
      const layerSource = featureGroupRef;

      layerSource.current?.eachLayer((l) => {
        const serialized = serializeLayer(l);
        if (get(l, "layerType") === "comment") {
          // don't add blank comments
          if (get(serialized, "properties.text")) {
            items.push(serialized);
          }
        } else {
          items.push(serialized);
        }
      });

      onShapesUpdate(items as any);
    },
    [onShapesUpdate]
  );

  const handleCommentDelete = (layer: L.Marker, data: any) => {
    // onCommentsUpdate(
    //   filter(
    //     takeOffItemsRef.current.comments,
    //     (comment) => comment.id !== data.id
    //   )
    // );
  };

  const { openCommentForm, openCommentView } = usePlanViewerComment(
    handleCommentCreate,
    handleCommentDelete
  );

  const handleUpdateItem = React.useCallback(
    (e) => {
      const items: SerializedLayer[] = [];
      const layerSource = e.layerType === "marker" ? mapRef : featureGroupRef;

      layerSource.current?.eachLayer((l) => {
        const serialized = serializeLayer(l);
        const layerType = get(l, "layerType");
        if (layerType === "comment") {
          const comment = get(l, COMMENT_PATH);
          // don't add blank comments
          if (get(comment, "properties.text")) {
            items.push(serialized);
          }
        } else if (layerType && layerType !== "label") {
          items.push(serialized);
        }
      });

      onShapesUpdate(items as any);
    },
    [onShapesUpdate]
  );

  const handleToolbarOnMounted = React.useCallback((e) => {
    mapRef.current = e._map;
    featureGroupRef.current = e.options.edit.featureGroup;
  }, []);

  const setLayerAttributes = React.useCallback(
    (layer: any) => {
      set(layer, "page", page); // used for _getMeasurementString
      if (
        layer.layerType === "polyline" ||
        layer.layerType === "polylineDeduction"
      ) {
        if (takeOff?.UOM === TakeOffMeasurement.METER_SQUARED && wallHeight) {
          set(layer, "wallHeight", wallHeight);
        }
        if (takeOff?.UOM === TakeOffMeasurement.LINEAR_METER && pitchAngle) {
          set(layer, "angle", pitchAngle);
        }
        if (takeOff?.UOM === TakeOffMeasurement.TONNE && measureWeight) {
          set(layer, "weight", measureWeight);
        }
      } else {
        if (takeOff?.UOM === TakeOffMeasurement.METER_SQUARED && pitchAngle) {
          set(layer, "angle", pitchAngle);
        }
        if (takeOff?.UOM === TakeOffMeasurement.CUBIC_METER && depth) {
          set(layer, "depth", depth);
        }
        if (takeOff?.UOM === TakeOffMeasurement.LINEAR_METER && materialWidth) {
          set(layer, "materialWidth", materialWidth);
        }
      }
    },
    [takeOff, pitchAngle, wallHeight, depth, materialWidth, page, measureWeight]
  );

  const handleToolbarOnLayerAdd = React.useCallback(
    (e) => {
      set(e.layer, "page", page); // used for _getMeasurementString
    },
    [page]
  );

  const handleToolbarOnCreated = React.useCallback(
    (e) => {
      let type = e.layerType;
      let layer = e.layer;

      set(layer, "layerType", type);
      setLayerAttributes(layer);

      if (type === "comment") {
        openCommentForm(layer);
        return;
      }

      handleUpdateItem(e);
    },
    [
      handleUpdateItem,
      openCommentForm,
      takeOff,
      wallHeight,
      materialWidth,
      pitchAngle,
      depth,
      setLayerAttributes,
      measureWeight,
    ]
  );

  const handleToolbarOnEdited = React.useCallback((e) => handleUpdateItem(e), [
    handleUpdateItem,
  ]);

  const handleToolbarOnDeleted = React.useCallback(
    (e) => {
      handleUpdateItem(e);
    },
    [handleUpdateItem]
  );

  const clearLayers = React.useCallback(() => {
    mapRef.current?.eachLayer((l) => {
      if (get(l, "layerType") === "label") {
        l.remove();
      }
    });
    featureGroupRef.current?.eachLayer((l) => {
      if (get(l, "layerType") === "comment") {
        (l as L.Marker).closePopup();
        (l as L.Marker).unbindPopup();
      }
      l.remove();
    });

    featureGroupRef.current?.clearLayers();
  }, []);

  const redrawLayers = React.useCallback(
    (
      featureGroup: FeatureGroup,
      map: L.Map,
      takeOff: TakeoffListItem,
      shapes: TakeOffShape[]
    ) => {
      clearLayers();
      const shapesLayers: L.Layer[] = deserializeShapes(takeOff, shapes);
      // const commentMarkers: L.Marker[] = deserializeComments(takeOff, comments);
      shapesLayers.forEach((shape, i) => {
        if (!shape) return;
        featureGroup.addLayer(shape);
        const layerType = get(shape, "layerType");

        if (
          [
            "polygon",
            "polyline",
            "deduction",
            "polylineDeduction",
            "rectangleDeduction",
          ].includes(layerType)
        ) {
          const label = createShapeLabel(shape, takeOff, planFile.calibration);
          mapRef.current?.addLayer(label);
        }

        if (layerType === "comment") {
          openCommentView(shape as L.Marker, true);
        }
      });

      // commentMarkers.forEach((comment) => {
      //   featureGroup.addLayer(comment);
      //   openCommentView(comment, true);
      // });
    },
    [planFile]
  );

  React.useEffect(() => {
    if (mapRef.current && featureGroupRef.current && takeOff) {
      takeOffItemsRef.current.shapes = shapes;

      redrawLayers(featureGroupRef.current, mapRef.current, takeOff, shapes);
    } else if (!takeOff) {
      clearLayers();
    }
  }, [shapes, takeOff, clearLayers]);

  React.useEffect(() => {
    if (isSelectedNewTakeOff) {
      setPitchAngle(0);
    }
  }, [isSelectedNewTakeOff]);

  const hasCalibration = React.useMemo(
    () => !!planFile?.calibration?.lat && !!planFile?.calibration?.lng,
    [planFile]
  );

  return (
    <PlanViewer planFile={planFile} onBoundsChange={onBoundsChange}>
      {hasCalibration && takeOff && (
        <PlanViewerToolbar
          key={takeOff._id}
          onMounted={handleToolbarOnMounted}
          onLayerAdd={handleToolbarOnLayerAdd}
          onCreated={handleToolbarOnCreated}
          onEdited={handleToolbarOnEdited}
          onDeleted={handleToolbarOnDeleted}
          draw={takeOffToolbarOptions}
          onWallHeightChange={handleChangeWallHeight}
          onPitchAngleChange={handleChangePitchAngle}
          onMeasureWeightChange={handleChangeMeasureWeight}
          onDepthChange={handleChangeDepth}
          onMaterialWidthChange={handleChangeMaterialWidth}
          showWallHeight={takeOff?.UOM === TakeOffMeasurement.METER_SQUARED}
          showPitchAngle={
            takeOff?.UOM === TakeOffMeasurement.LINEAR_METER ||
            takeOff?.UOM === TakeOffMeasurement.METER_SQUARED
          }
          showDepth={takeOff?.UOM === TakeOffMeasurement.CUBIC_METER}
          showMeasureWeight={takeOff?.UOM === TakeOffMeasurement.TONNE}
          showMaterialWidth={takeOff?.UOM === TakeOffMeasurement.LINEAR_METER}
          wallHeight={wallHeight}
          pitchAngle={pitchAngle}
          measureWeight={measureWeight}
          depth={depth}
          materialWidth={materialWidth}
          editable={!isLocked}
          continuous={true}
        />
      )}
    </PlanViewer>
  );
};

export default React.forwardRef(TakeOffPlanViewer);
