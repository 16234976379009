import React from "react";
import { findIndex, isArray, reduce } from "lodash";
import { TFunction } from "react-i18next";
import { JobCostingTableItem } from "..";
import ClaimModal from "../../../../components/claims/claim-modal";
import {
  TableCardAction,
  TableRowActionData,
} from "../../../../components/dashboard/table-card/utils";
import ListProgressClaimsModal, {
  ListProgressClaimsModalRef,
} from "../../../../components/job-claim/list-claims-modal";
import { useJobContacts } from "../../../../hooks/useJobContacts";
import { ModalDisplayRef } from "../../../../hooks/useModalDisplay";
import { ClaimReal } from "../../../../models/claim";
import { JobCostingItem } from "../../../../models/job";
import { getProgressClaimItems, toggleCostingItem } from "../utils";

export function useClaimCreate(
  t: TFunction,
  isCostPlus: boolean,
  resetAllSelections: () => void,
  jobId?: string
) {
  const modalRef = React.useRef<ModalDisplayRef>(null);
  const listClaimsRef = React.useRef<ListProgressClaimsModalRef>(null);
  const { contacts } = useJobContacts(jobId);
  const [claimItems, setClaimItems] = React.useState<JobCostingTableItem[]>([]);

  const [selectedCostingItems, setSelectedCostingItems] = React.useState<
    JobCostingItem[]
  >([]);

  const resetClaimItems = React.useCallback(() => {
    setClaimItems([]);
  }, []);

  const toggleClaimItem = React.useCallback(
    (row: JobCostingTableItem | JobCostingTableItem[]) => {
      if (isArray(row)) {
        const items = reduce(
          row,
          (result, item) => {
            if (item.claimedPercent !== 100) {
              return toggleCostingItem(result, item, true);
            }
            return result;
          },
          claimItems
        );
        setClaimItems(items);
      } else setClaimItems(toggleCostingItem(claimItems, row));
    },
    [claimItems]
  );

  const isItemSelectedForClaim = React.useCallback(
    (id: string) => {
      return findIndex(claimItems, { _id: id }) >= 0;
    },
    [claimItems]
  );

  const claimTableAction = React.useMemo<TableCardAction | null>(
    () =>
      isCostPlus
        ? null
        : {
            title: t(
              claimItems.length
                ? "costing.claimItemsNumber"
                : "costing.claimItems",
              { number: claimItems.length }
            ),
            onClick: () => {
              if (claimItems) {
                setSelectedCostingItems(claimItems);
                modalRef.current?.show(true);
              }
            },
            svgIcon: "Progress Claim",
            className: "button info-reverse",
            disabled: !claimItems.length,
          },
    [isCostPlus, modalRef, claimItems, selectedCostingItems]
  );

  const claimRowAction = React.useMemo<TableRowActionData<JobCostingTableItem>>(
    () => ({
      svgIcon: "Progress Claim",
      counter: (row: JobCostingTableItem) => {
        return getProgressClaimItems(row).length;
      },
      title: (row: JobCostingTableItem) =>
        t("costing.claimCreatedWithCount", {
          count: getProgressClaimItems(row).length,
        }),
      dropdownId: "claim-item",
      onClick: (row: JobCostingTableItem) => {
        const data = getProgressClaimItems(row);
        if (data?.length) {
          listClaimsRef.current?.setData(data);
          listClaimsRef.current?.show(true);
        }
      },
      shouldRender: (row: JobCostingTableItem) =>
        getProgressClaimItems(row).length > 0,
    }),
    [isCostPlus, jobId, selectedCostingItems]
  );

  const handleClaimUpdate = React.useCallback(
    (progressClaim: ClaimReal) => {
      const updatedProgressClaim = {
        ...progressClaim,
        items: [],
      };
      const updatedClaimItems = claimItems.map((claimItem) => {
        const updatedItem = progressClaim.items?.find(
          (pcItem) => claimItem._id === pcItem.costingItemId
        );
        if (updatedItem) {
          return {
            ...claimItem,
            progressClaimItems: [
              ...(claimItem.progressClaimItems || []),
              {
                ...updatedItem,
                progressClaim: updatedProgressClaim,
              },
            ],
          };
        }
        return claimItem;
      });
      setClaimItems(updatedClaimItems);
      resetAllSelections();
    },
    [isCostPlus, claimItems]
  );

  const renderClaimModal = React.useCallback(() => {
    return (
      <>
        <ClaimModal
          ref={modalRef}
          jobId={jobId}
          isCostPlus={isCostPlus}
          claimedAmount={0}
          claimedGST={0}
          contractTotal={0}
          contractTotalGST={0}
          // client={jobContact}
          clients={contacts}
          costingItems={selectedCostingItems}
          onCreateUpdate={handleClaimUpdate}
        />
        <ListProgressClaimsModal ref={listClaimsRef} isCostPlus={isCostPlus} />
      </>
    );
  }, [isCostPlus, selectedCostingItems, handleClaimUpdate]);

  return {
    renderClaimModal,
    claimTableAction,
    claimRowAction,
    toggleClaimItem,
    resetClaimItems,
    isItemSelectedForClaim,
  };
}
