import { PhotoProps } from "react-photo-gallery";
import { Media } from "../../../models/media";

export const imageType = [
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg",
  "image/webp",
];

export const attachmentsToPhotos = (
  attachments: Media[]
): PhotoProps<any>[] => {
  return attachments?.map((photo) => ({
    src: photo.url,
    name: photo.name || "",
    width: photo.width ? photo.width / 100 : 0,
    height: photo.height ? photo.height / 100 : 0,
  }));
};
