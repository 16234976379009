import React, { useState } from "react";
import { head, trim } from "lodash";
import XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { readSheet } from "../utils/sheets";
import { notify } from "../components/notification";

export function useImportMapper<Data extends {}>() {
  const { t } = useTranslation();
  const [rows, setRows] = useState<object[] | null>(null);
  const [headerRow, setHeaderRow] = useState<string[] | null>(null);
  const [sampleRow, setSampleRow] = useState<object | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const handleFileAccept = React.useCallback(async (files: File[]) => {
    const file = head(files) || null;
    if (!file) {
      return notify({
        error: true,
        title: t("common.import"),
        content: t("common.errors.fileExtension"),
      });
    }
    setFile(file);
    try {
      const sheet = await readSheet(file, 0);
      let readRange = null;
      if (sheet["!ref"]) {
        const sheetRange = XLSX.utils.decode_range(sheet["!ref"]);
        sheetRange.s.c = 0;
        sheetRange.e.c = Math.min(sheetRange.e.c, 19); // only take first 20 rows for performance reasons.
        readRange = XLSX.utils.encode_range(sheetRange);
      }
      const json = XLSX.utils.sheet_to_json(sheet, {
        defval: null,
        range: readRange,
        raw: false,
      }) as object[];

      const header = [];
      let numEmpty = 0;
      for (let i = 0; i < Object.keys(json[0] as any).length; i++) {
        let value = sheet[XLSX.utils.encode_cell({ c: i, r: 0 })]?.w;
        if (!value) {
          const emptyCount = numEmpty > 0 ? `_${numEmpty}` : "";
          value = `__EMPTY${emptyCount}`;
          numEmpty++;
        }
        header.push(value);
      }
      const sampleData = {} as any;

      header.forEach((key) => {
        for (let i = 0; i < Math.min(100, json.length); i++) {
          const cell = trim((json[i] as any)[key]);
          if (cell) {
            sampleData[key] = cell;
            break;
          }
        }
      });
      setSampleRow(sampleData as object);
      setHeaderRow(header);
      setRows(json);
    } catch (e) {
      setRows(null);
      setHeaderRow(null);
    }
  }, []);

  const handleReset = React.useCallback(() => {
    setRows(null);
    setHeaderRow(null);
    setSampleRow(null);
    setFile(null);
  }, []);

  return {
    file,
    headerRow,
    sampleRow,
    rows,
    handleFileAccept,
    handleReset,
  };
}
