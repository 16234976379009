import * as yup from "yup";
import { TFunction } from "i18next";

import { ValidationMessagesUtils } from "../../../utils/yup";

export default function editQuoteMarkupSchema(t: TFunction) {
  return yup.object().shape({
    markup: yup.number(),
  });
}
