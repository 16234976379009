import React from "react";
import classNames from "classnames";
import Gallery, { PhotoClickHandler, PhotoProps } from "react-photo-gallery";
import moment from "moment";
import { map, merge } from "lodash";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useTranslation } from "react-i18next";
import Icon from "../../icons/Icon";
import {
  SiteDiary,
  SiteDiaryField,
  SiteDiaryFieldType,
  SiteDiaryFieldValue,
  SiteDiaryFieldValueRow,
} from "../../../graphql/types/models/site-diary";
import "./styles.scss";
import { Media } from "../../../models/media";
import {
  TableCardData,
  TableCardDataColumn,
  TableCardDataRow,
} from "../../dashboard/table-card/utils";
import CardTable from "../../dashboard/table-card/CardTable";

type EntryListItemProps = {
  siteDiary: SiteDiary;
  print?: boolean;
};
type RenderPhotoFn = (attachments?: Media[]) => React.ReactElement | null;

const EntryListItem: React.FC<EntryListItemProps> = ({ siteDiary, print }) => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
  const [currentGallery, setCurrentGallery] = React.useState<PhotoProps<any>>(
    []
  );

  const attachmentsToPhotos = (attachments: Media[]): PhotoProps<any>[] => {
    return attachments?.map((photo) => ({
      src: photo.url,
      name: photo.name || "",
      width: photo.width ? photo.width / 100 : 0,
      height: photo.height ? photo.height / 100 : 0,
    }));
  };

  const openLightbox = React.useCallback(
    (gallery: PhotoProps<any>[]) => (event: React.MouseEvent, props: any) => {
      setCurrentGallery(gallery);
      setCurrentImage(props.index);
      setViewerIsOpen(true);
    },
    []
  );

  const closeLightbox = () => {
    setCurrentGallery([]);
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const renderOverview = React.useCallback(
    () => (
      <>
        <h2 className="site-diary-title">{siteDiary.name}</h2>
        <div className="site-diary-entry-overview">
          <div className="site-diary-entry-overview-item">
            <Icon name="person" outlined />
            <span>
              {siteDiary.created_by?.first_name}{" "}
              {siteDiary.created_by?.last_name}
            </span>
          </div>
          <div className="site-diary-entry-overview-item">
            <Icon name="card_travel" />
            <span>{siteDiary.template?.name}</span>
          </div>
          <div className="site-diary-entry-overview-item">
            <Icon name="calendar_today" />
            <span>
              {moment(siteDiary.entry_date_time).format("Do MMM YYYY")}
            </span>
          </div>
          <div className="site-diary-entry-overview-item">
            <Icon name="access_time" />
            <span>{moment(siteDiary.entry_date_time).format("LT")}</span>
          </div>
          {/**
        <div className="site-diary-entry-overview-item">
          <Icon name="wb_sunny"/>
          <span>{siteDiary.weather}</span>
        </div>
        */}
        </div>
      </>
    ),
    [siteDiary]
  );

  const renderWeatherConditions = React.useCallback(() => {
    if (!siteDiary.weather) return null;
    return (
      <>
        <h3 className="site-diary-entry-question">{`${t(
          "siteDiary.weather.location",
          siteDiary.weather
        )}`}</h3>
        <p className="site-diary-entry-note">
          {`${t("siteDiary.weather.conditions")}: ${
            siteDiary.weather.weather_description
          }, `}
          {`${t("siteDiary.weather.temperature")}: ${
            siteDiary.weather.temperature
          }°C, `}
          {`${t("siteDiary.weather.humidity")}: ${
            siteDiary.weather.humidity
          }%, `}
          {`${t("siteDiary.weather.wind")}: ${siteDiary.weather.wind_speed}${t(
            "siteDiary.weather.windSpeedMeasure"
          )}`}
        </p>
      </>
    );
  }, [siteDiary]);

  const renderNote = React.useCallback(() => {
    if (!siteDiary.note) return null;
    return (
      <p
        className="site-diary-entry-note"
        dangerouslySetInnerHTML={{
          __html: siteDiary.note?.replace(/\n/g, "<br />"),
        }}
      />
    );
  }, [siteDiary]);

  const renderGallery = React.useCallback(
    (attachments?: Media[]) => {
      if (!attachments?.length) return null;
      const gallery = attachmentsToPhotos(attachments);
      return (
        <>
          <Gallery
            photos={gallery}
            targetRowHeight={200}
            margin={10}
            onClick={openLightbox(gallery)}
          />
        </>
      );
    },
    [viewerIsOpen]
  );

  const renderPhotos = React.useCallback((attachments?: Media[]) => {
    if (!attachments?.length) return null;
    const gallery = attachmentsToPhotos(attachments);
    return (
      <>
        {map(gallery, (photo) => (
          <div className="photo">
            <img src={photo.src} />
          </div>
        ))}
      </>
    );
  }, []);

  const renderPhotosCol = React.useCallback(
    (renderPhoto: RenderPhotoFn) => (
      row: TableCardDataRow<any>,
      col: TableCardDataColumn<any>,
      attachments?: Media[]
    ) => {
      if (!attachments?.length) return null;
      return renderPhoto(attachments);
    },
    []
  );
  const renderPhotosRowForPrint = React.useCallback(
    (renderPhoto: RenderPhotoFn) => (
      row: TableCardDataRow<any>,
      index: number
    ) => {
      const attachments = row.cells.attachments as Media[];
      if (!attachments?.length) return null;
      return (
        <tr className="site-diary-table-row-photo">
          <td></td>
          <td colSpan={Object.keys(row.cells).length}>
            {renderPhoto(attachments)}
          </td>
        </tr>
      );
    },
    []
  );

  const getCollectionTableData = React.useCallback(
    (
      subFields: SiteDiaryField[],
      subValues: SiteDiaryFieldValueRow[],
      renderPhoto: RenderPhotoFn
    ): TableCardData<any> => {
      return {
        columns: [
          ...map(subFields, (subField: SiteDiaryField) => ({
            valueKey: subField._id,
            title: subField.title,
            formatValue: (row: any, column: any, value: string) => {
              return subField.type === "Date" &&
                moment(value, moment.ISO_8601, true).isValid()
                ? moment(value).format("Do MMM YYYY")
                : value;
            },
          })),
          {
            valueKey: "attachments",
            title: "",
            className: "site-diary-table-cell-photo",
            renderValue: renderPhotosCol(renderPhoto),
          },
        ],
        rows: map(subValues, (subValue: SiteDiaryFieldValueRow) => ({
          cells: merge(
            { attachments: subValue.attachments },
            ...map(subValue.columns, (column: any) => ({
              [column.field?._id]: column.value,
            }))
          ),
          renderSubRow: renderPhotosRowForPrint(renderPhoto),
        })),
      };
    },
    []
  );

  const renderCollection = React.useCallback(
    (responseItem: SiteDiaryFieldValue, renderPhoto: RenderPhotoFn) => {
      const tableData = getCollectionTableData(
        responseItem.field.subFields,
        responseItem.subValues || [],
        renderPhoto
      );
      return (
        <CardTable
          table={tableData}
          tableClass="table-outline w-100"
          showCountTitle
          rowCount
        />
      );
    },
    []
  );

  const renderQuestions = React.useCallback(
    (values: SiteDiaryFieldValue[], renderPhoto: RenderPhotoFn) => {
      let index = 0;
      return map(values, (responseItem: SiteDiaryFieldValue) => {
        let response = null;
        switch (responseItem.field?.type) {
          case SiteDiaryFieldType.String:
          case SiteDiaryFieldType.Number:
          case SiteDiaryFieldType.Radio:
            response = <p>{responseItem.value}</p>;
            break;
          case SiteDiaryFieldType.Date:
            response = (
              <p>{moment(responseItem.value).format("Do MMM YYYY")}</p>
            );
            break;
          case SiteDiaryFieldType.File:
            // NFI
            break;
          case SiteDiaryFieldType.Collection:
            response = renderCollection(responseItem, renderPhoto);
            break;
          case SiteDiaryFieldType.Heading:
            return (
              <div className="site-diary-entry-question">
                <h2>{responseItem.field?.title}</h2>
              </div>
            );
        }
        return (
          <div className="site-diary-entry-question">
            <div className="site-diary-entry-id">{index++ + 1}.</div>
            <div className="w-100">
              <h3 className="site-diary-entry-title">
                {responseItem.field?.title}
              </h3>
              {response}
              {renderPhoto(responseItem.attachments)}
            </div>
          </div>
        );
      });
    },
    [siteDiary]
  );

  return (
    <div
      className={classNames("site-diary-entry", {
        "site-diary-print": print,
      })}
    >
      {renderOverview()}
      {renderWeatherConditions()}
      {renderNote()}
      {renderQuestions(siteDiary.values, print ? renderPhotos : renderGallery)}
      {(print ? renderPhotos : renderGallery)(siteDiary.attachments)}

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={currentGallery.map((photo: PhotoProps<any>) => ({
                source: {
                  regular: photo.src,
                  fullscreen: photo.src,
                },
                caption: photo.name,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default EntryListItem;
