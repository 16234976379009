import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/client";

import HomeContainer from "../../home";
import DashboardLayout from "../../layouts/dashboard";
import QuotesOverviewContainer from "../quotes";
import QuotesDetailsContainer from "../quote-details";
import ProfileContainer from "../profile/profile-container";
import { RootReducerState } from "../../../redux/reducer";
import {
  getUser,
  getUserFailed,
  getUserSuccess,
} from "../../../redux/authentication/actions";
import { SUBCONTRACTOR_PROFILE } from "../../../graphql/queries/subcontractor/auth/queries";
import { DashboardContainerProps } from "../../dashboard";

const SubcontractorDashboardContainer: React.FC<DashboardContainerProps> = ({
  getUser,
  getUserFailed,
  getUserSuccess,
  isAuthenticated,
  user,
}) => {
  const [getSubcontractorProfile] = useLazyQuery(SUBCONTRACTOR_PROFILE, {
    onCompleted: (data) => {
      getUserSuccess(data.subcontractorProfile, true, false, false);
    },
    onError: (error) => {
      getUserFailed(error.message);
    },
  });

  React.useEffect(() => {
    if (user && isAuthenticated) {
      return;
    }

    getUser();
    getSubcontractorProfile();
  }, [user, isAuthenticated, getSubcontractorProfile, getUser]);

  return (
    <DashboardLayout>
      <Switch>
        {/* <Route exact path="/" component={HomeContainer} /> */}
        <Route exact path="/">
          <Redirect to="/quotes" />
        </Route>
        <Route exact path="/quotes" component={QuotesOverviewContainer} />
        <Route path="/quotes/:id" component={QuotesDetailsContainer} />
        <Route path="/account" component={ProfileContainer} />,
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
    isAuthenticated: state.authentication.isAuthenticated,
    isAuthenticating: state.authentication.isAuthenticating,
  };
};

export default connect(mapStateToProps, {
  getUser,
  getUserSuccess,
  getUserFailed,
})(SubcontractorDashboardContainer);
