import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationRules } from "../../../utils/yup";

export default function createBusinessSchema(t: TFunction) {
  return yup.object().shape({
    business_name: yup.string().required(),
    email: yup.string().email().required(),
    phone_number: ValidationRules.getPhoneValidationRules(yup, t).required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    subscriptionEndDate: yup.date().required(),
  });
}
