import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { chain, compact, find, map, pick, sumBy } from "lodash";
import { formatQuoteNumber } from "../../../utils/text";
import {
  PreviewQuoteRequestResponse,
  PreviewQuoteRequestShareResponse,
  QuoteRequest,
  QuoteRequestStatus,
  QuoteRequestSubcontractor,
} from "../../../graphql/types/models/quote-request";
import { DATE_FORMAT_DATE_STRING } from "../../../constants/dates";
import {
  CostingTable,
  CostingTableItem,
  createCostingTable,
} from "../../../components/costing/utils";
import { GST_PERCENT, calcGST } from "../../../utils/calculations";
import {
  TableCardData,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import CustomReportCard from "../../../components/dashboard/report-card/custom-report-card";
import DashboardCardField from "../../../components/dashboard/card/DashboardCardField";
import CardTable from "../../../components/dashboard/table-card/CardTable";
import Totals from "../../../components/costing/total";
import { Media } from "../../../models/media";
import { useLazyQuery } from "@apollo/client";
import {
  PREVIEW_QUOTE_REQUEST,
  PREVIEW_QUOTE_REQUEST_SHARE,
} from "../../../graphql/queries/quote-request/queries";
import { printBase64Pdf } from "../../../utils/pdf";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import SendModal from "../../../components/modals/send-modal";
import { SendEmailForm } from "../../../models/email";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import {
  getClientFeedbackBadge,
  getStatusBadge,
} from "../../../components/quote-requests/utils";
import Conversation, {
  ConversationRef,
} from "../../../components/communication/conversation";
import { getMediaInput } from "../../../utils/transform";
import { uploadFiles } from "../../../utils/files";
import { Message } from "../../../models/conversation";
import { useNote } from "../../../hooks/useNote";
import { NoteEntityType } from "../../../graphql/types/models/note";
import "./styles.scss";
import { useQuoteRequestMessageQuery } from "../../../hooks/queries/useQuoteRequestMessageQuery";
import QuoteRequestEditModal from "../../../components/quote-requests/quote-request-edit-modal";
import { useQuoteRequestOperationsMutation } from "../../../hooks/mutations/useQuoteRequestOperationsMutation";
import { useCreateOrderMutation } from "../../../hooks/mutations/useCreateOrderMutation";
import { SystemFolderType } from "../../../models/documents";

type QuoteRequestCardProps = {
  quoteRequest: QuoteRequest;
  subcontractor: QuoteRequestSubcontractor;
  onDelete?: (id: string) => void;
  onEdit?: () => void;
  jobId?: string;
  quoteId?: string;
  hideShareOptions?: boolean;
};

const QuoteRequestCard: React.FC<QuoteRequestCardProps> = (props) => {
  const { t } = useTranslation();

  const {
    quoteRequest,
    subcontractor,
    onDelete,
    onEdit,
    hideShareOptions,
    jobId,
    quoteId,
  } = props;

  const acceptRef = React.useRef<ConfirmDialogRef>();
  const declineRef = React.useRef<ConfirmDialogRef>();
  const sendRef = React.useRef<ModalDisplayRef>();
  const editRef = React.useRef<ModalDisplayRef>();
  const shareRef = React.useRef<ModalDisplayRef>();
  const conversationRef = React.useRef<ConversationRef>(null);
  const [filename, setFilename] = React.useState("");
  const [shareFilename, setShareFilename] = React.useState("");
  const [shouldPreviewPDF, setShouldPreviewPDF] = React.useState(false);

  const { renderNotesCard } = useNote(
    NoteEntityType.QUOTE_REQUEST,
    subcontractor._id
  );

  const { quoteRequestMessages } = useQuoteRequestMessageQuery(
    quoteRequest._id,
    subcontractor._id
  );

  const {
    renderPurchaseOrderForm,
    handleShowCreateOrderModal,
  } = useCreateOrderMutation({
    jobId,
    subcontractor,
    data: quoteRequest.items,
  });

  const {
    shareQuoteRequest,
    sendQuoteRequest,
    acceptQuoteRequest,
    declineQuoteRequest,
    sendComment,
  } = useQuoteRequestOperationsMutation({
    onCompletedShareQuoteRequest: () => shareRef.current?.show(false),
    onCompletedSendQuoteRequest: () => sendRef.current?.show(false),
  });

  const [getPDF, { data: pdfData }] = useLazyQuery<PreviewQuoteRequestResponse>(
    PREVIEW_QUOTE_REQUEST,
    {
      onCompleted: (data) => {
        setFilename(data?.previewQuoteRequest.filename);
        if (shouldPreviewPDF) {
          printBase64Pdf(data?.previewQuoteRequest.pdf);
        }
        setShouldPreviewPDF(false);
      },
      fetchPolicy: "network-only",
    }
  );

  const [getSharePDF, { data: pdfShareData }] = useLazyQuery<
    PreviewQuoteRequestShareResponse
  >(PREVIEW_QUOTE_REQUEST_SHARE, {
    onCompleted: (data) => {
      setShareFilename(data?.previewQuoteRequestShare.filename);
      if (shouldPreviewPDF) {
        printBase64Pdf(data?.previewQuoteRequestShare.pdf);
      }
      setShouldPreviewPDF(false);
    },
    onError: () => {
      setShouldPreviewPDF(false);
    },
  });

  const handleSubmitComment = React.useCallback(
    async (comment: string, files: File[] | null) => {
      if (quoteRequest && comment && subcontractor) {
        const attachments = getMediaInput(files);

        const result = await sendComment({
          variables: {
            quoteRequestId: quoteRequest._id,
            comment: {
              comment,
              subcontractorResponseId: subcontractor._id,
              attachments,
            },
          },
        });

        conversationRef?.current?.reset();

        if (files?.length && attachments.length) {
          const attachmentResults =
            result.data?.commentQuoteRequest?.attachments;
          if (attachmentResults?.length) {
            await uploadFiles(attachmentResults, files);
          }
        }
      }
    },
    [sendComment, quoteRequest]
  );

  const _id = quoteRequest._id;

  const handlePrint = React.useCallback(() => {
    setShouldPreviewPDF(true);
    getPDF({
      variables: {
        quoteRequestId: quoteRequest._id,
        subcontractorId: subcontractor._id,
      },
    });
  }, [quoteRequest, subcontractor, getPDF]);

  const handlePrintShare = React.useCallback(() => {
    setShouldPreviewPDF(true);
    getSharePDF({
      variables: {
        quoteRequestId: quoteRequest._id,
        subcontractorId: subcontractor._id,
      },
    });
  }, [quoteRequest, subcontractor, getSharePDF]);

  const handleDelete = React.useCallback(() => {
    onDelete && onDelete(_id);
  }, [onDelete, _id]);

  const handleAccept = React.useCallback(() => {
    acceptRef.current?.show(true, () => {
      acceptQuoteRequest({
        variables: {
          quoteRequestId: quoteRequest._id,
          subcontractorResponseId: subcontractor._id,
        },
      });
    });
  }, [quoteRequest, subcontractor]);

  const handleDecline = React.useCallback(() => {
    declineRef.current?.show(true, () => {
      declineQuoteRequest({
        variables: {
          quoteRequestId: quoteRequest._id,
          subcontractorResponseId: subcontractor._id,
        },
      });
    });
  }, [quoteRequest, subcontractor]);

  const handleShareSubmit = React.useCallback(
    (data: SendEmailForm) => {
      return shareQuoteRequest({
        variables: {
          jobId: quoteRequest.job._id,
          quoteRequestId: quoteRequest._id,
          subcontractorResponseIdList: [subcontractor._id],
          message: {
            message: data.message,
            to: data.to,
            subject: data.title,
            remoteAttachmentId: data.remoteAttachmentId,
          },
        },
      });
    },
    [quoteRequest, subcontractor]
  );

  const handleSendSubmit = React.useCallback(
    (data: SendEmailForm) => {
      return sendQuoteRequest({
        variables: {
          quoteRequestId: quoteRequest._id,
          subcontractorId: subcontractor._id,
          message: {
            message: data.message,
            to: data.to,
            subject: data.title,
            remoteAttachmentId: data.remoteAttachmentId,
          },
        },
      });
    },
    [quoteRequest, subcontractor]
  );

  const handleSend = React.useCallback(() => {
    getPDF({
      variables: {
        quoteRequestId: quoteRequest._id,
        subcontractorId: subcontractor._id,
      },
    });
    sendRef.current?.show(true);
  }, [quoteRequest, subcontractor]);

  const handleShare = React.useCallback(() => {
    getSharePDF({
      variables: {
        quoteRequestId: quoteRequest._id,
        subcontractorId: subcontractor._id,
      },
    });
    shareRef.current?.show(true);
  }, [quoteRequest, subcontractor]);

  const handleEdit = React.useCallback(() => {
    editRef.current?.show(true);
  }, []);

  const contact = React.useMemo(() => {
    if (quoteRequest?.job) {
      return quoteRequest.job.customer;
    }

    if (quoteRequest?.salesQuote) {
      return quoteRequest.salesQuote.contact;
    }

    return null;
  }, [quoteRequest]);

  const items = React.useMemo<CostingTableItem[]>(() => {
    return quoteRequest.items?.map((item) => {
      const subcontractorItem = find(subcontractor.items, {
        quoteItemId: item._id,
      });
      return {
        name: item?.name || "",
        UOM: item?.UOM || "",
        quantity: item?.quantity || 0,
        cost: subcontractorItem?.cost || 0,
        total: subcontractorItem?.cost
          ? subcontractorItem?.cost * (item?.quantity || 0)
          : 0,
      };
    });
  }, [quoteRequest, subcontractor]);

  const itemData = React.useMemo<TableCardData<CostingTable>>(
    () =>
      createCostingTable(items, t, 0, [
        "margin_amount",
        "allocatedCategory",
        "clientTotal",
      ]),
    [quoteRequest, items]
  );

  const totals = React.useMemo(() => {
    const subTotal = sumBy(items, "total");
    const GST = calcGST(subTotal, GST_PERCENT);
    return {
      subTotal,
      GST,
      total: subTotal + GST,
    };
  }, [items]);

  const dropdownItems = React.useMemo(() => {
    const controls = compact([
      {
        id: "printRequest",
        label: t("quoteRequest.printRequest"),
        icon: "print",
        outlined: true,
        onClick: handlePrint,
      },
      subcontractor.status !== QuoteRequestStatus.REQUESTED && !hideShareOptions
        ? {
            id: "printQuote",
            label: t("quoteRequest.printQuote"),
            icon: "print",
            outlined: true,
            onClick: handlePrintShare,
          }
        : null,
      {
        id: "mail",
        label: t("common.send"),
        icon: "mail",
        outlined: true,
        onClick: handleSend,
      },
      subcontractor.status === QuoteRequestStatus.REQUESTED
        ? {
            id: "edit",
            label: t("quoteRequest.manulEntry"),
            icon: "edit",
            outlined: true,
            onClick: handleEdit,
          }
        : null,
      subcontractor.status === QuoteRequestStatus.SUBMITTED && !hideShareOptions
        ? {
            id: "share",
            label: t("quoteRequest.shareWithClient"),
            icon: "share",
            outlined: true,
            onClick: handleShare,
          }
        : null,
      subcontractor.status === QuoteRequestStatus.SUBMITTED
        ? {
            id: "accept",
            label: t("quoteRequest.accept"),
            icon: "check",
            outlined: true,
            onClick: handleAccept,
          }
        : null,
      subcontractor.status === QuoteRequestStatus.SUBMITTED
        ? {
            id: "decline",
            label: t("quoteRequest.decline"),
            icon: "close",
            outlined: true,
            onClick: handleDecline,
          }
        : null,
      jobId && subcontractor.status === QuoteRequestStatus.ACCEPTED
        ? {
            id: "create-order",
            label: t("quoteRequest.createOrder"),
            icon: "receipt_long",
            outlined: true,
            onClick: handleShowCreateOrderModal,
          }
        : null,
    ]);

    return controls;
  }, [
    handleDelete,
    handleAccept,
    handleDecline,
    handleSend,
    handleShare,
    handlePrint,
    handlePrintShare,
    onEdit,
    hideShareOptions,
    jobId,
  ]);

  const attachmentsData = React.useMemo<TableCardData<Media>>(
    () => ({
      columns: [
        {
          valueKey: "name",
          title: t("quoteRequest.fileName"),
          formatValue: (row: any, column: any, value: number) => value,
        },
        {
          valueKey: "size",
          title: t("quoteRequest.fileSize"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.fileSize", { amount: value }),
        },
      ],
      rows: map(quoteRequest.attachments, (attachment) => ({
        cells: attachment,
      })),
    }),
    [quoteRequest]
  );

  const attachmentsRowActions: TableRowActionData<Media>[] = React.useMemo(
    () => [
      {
        icon: "download",
        dropdownId: "download",
        onClick: (row) => {
          const url = row.url;
          url && window.open(url, "_blank");
        },
      },
    ],
    []
  );

  const messages = React.useMemo<Message[]>(() => {
    return chain(quoteRequestMessages)
      .map((comment) => ({
        _id: comment._id,
        text: comment.comment,
        media: comment.attachments,
        createdAt: comment.createdAt,
        sender: pick(comment.sender, ["_id", "name", "email"]),
      }))
      .reverse()
      .value();
  }, [quoteRequestMessages]);

  const title = formatQuoteNumber(quoteRequest.quoteNumber, "QR");

  const badges = React.useMemo(
    () =>
      compact([
        getClientFeedbackBadge(subcontractor.client_feedback),
        getStatusBadge(subcontractor.status),
      ]),
    [subcontractor]
  );

  return (
    <>
      <CustomReportCard
        title={title}
        badges={badges}
        controls={dropdownItems}
        className="quote-request-card"
      >
        <Row className="main-row">
          <Col lg={4} xs={12} className="report-col">
            <DashboardCardField
              title={t("common.name")}
              content={quoteRequest.name}
              wrapWords
            />
          </Col>
          <Col lg={8} xs={12} className="report-col">
            <DashboardCardField
              title={t("quoteRequest.scopeOfWork")}
              content={quoteRequest.scope}
              placeholder={t("quoteRequest.scopeOfWork")}
              wrapWords
            />
          </Col>
        </Row>
        <Row className="main-row">
          <Col lg={3} xs={12} className="report-col">
            <DashboardCardField
              title={t("quoteRequest.subcontractor")}
              content={compact([
                subcontractor.supplier?.business_name,
                subcontractor.supplier?.contact_person,
              ]).join(" / ")}
            />
          </Col>
          <Col lg={3} xs={12} className="report-col">
            <DashboardCardField
              title={t("quoteRequest.due_date")}
              content={moment(quoteRequest.due_date).format(
                DATE_FORMAT_DATE_STRING
              )}
            />
          </Col>
          <Col lg={3} xs={12} className="report-col">
            <DashboardCardField
              title={t("common.status")}
              content={t(`quoteRequest.statuses.${subcontractor.status}`)}
            />
          </Col>
          <Col lg={3} xs={12} className="report-col">
            <DashboardCardField
              title={t("quoteRequest.dateReceived")}
              content={
                subcontractor.date_submitted
                  ? moment(subcontractor.date_submitted).format(
                      DATE_FORMAT_DATE_STRING
                    )
                  : t("common.na")
              }
            />
          </Col>
        </Row>

        <Row className="table-row">
          <CardTable rowCount table={itemData} />
        </Row>

        <Row className="row--big">
          <Col lg={7} xs={12} className="report-col--big">
            {!!quoteRequest.attachments?.length && (
              <>
                <div className="field-text field-text--underlined table-title">
                  {t("common.attachments")}
                </div>
                <CardTable
                  rowCount
                  table={attachmentsData}
                  showCountTitle={true}
                  rowActions={attachmentsRowActions}
                />
              </>
            )}
          </Col>
          <Col lg={5} xs={12} className="report-col--big">
            <Totals
              title={t("quoteRequest.amount")}
              subtotal={totals.subTotal}
              gst={totals.GST}
              total={totals.total}
            />
          </Col>
        </Row>
      </CustomReportCard>

      <Row className="row--big mt-4">
        <Col lg={6} md={12}>
          {renderNotesCard()}
        </Col>
        <Col lg={6} md={12} className="quote-request-conversation">
          <Conversation
            ref={conversationRef}
            onSend={(comment, files) => handleSubmitComment(comment, files)}
            messages={messages}
            name={t("quoteRequest.comments")}
            noAvatar
          />
        </Col>
      </Row>

      {jobId && renderPurchaseOrderForm()}

      <ConfirmDialog
        ref={declineRef}
        title={t("quoteRequest.declineQuote")}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("quoteRequest.declineQuoteConfirm")}
        </span>
      </ConfirmDialog>

      <ConfirmDialog
        ref={acceptRef}
        title={t("quoteRequest.acceptQuote")}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("quoteRequest.acceptQuoteConfirm")}
        </span>
      </ConfirmDialog>
      <QuoteRequestEditModal
        ref={editRef}
        quoteRequest={quoteRequest}
        subcontractor={subcontractor}
      />
      <SendModal
        ref={sendRef}
        title={t("quoteRequest.sendToSupplier")}
        submitText={t("common.send")}
        onSubmit={handleSendSubmit}
        contacts={subcontractor.supplier ? [subcontractor.supplier] : []}
        contact={subcontractor.supplier}
        disableContactSelect
        fileName={filename}
        onFileClick={() =>
          pdfData?.previewQuoteRequest?.pdf &&
          printBase64Pdf(pdfData?.previewQuoteRequest?.pdf)
        }
        addDocumentsSelect={{
          entityId: quoteId ?? jobId,
          systemFolderType: jobId ? SystemFolderType.JOB : SystemFolderType.SQ,
        }}
      />
      {quoteRequest?.job && (
        <SendModal
          ref={shareRef}
          title={t("quoteRequest.shareQuoteRequest")}
          submitText={t("common.share")}
          onSubmit={handleShareSubmit}
          contacts={contact ? [contact] : []}
          contact={contact}
          disableContactSelect
          fileName={shareFilename}
          onFileClick={() =>
            pdfShareData?.previewQuoteRequestShare?.pdf &&
            printBase64Pdf(pdfShareData?.previewQuoteRequestShare?.pdf)
          }
          addDocumentsSelect={{
            entityId: jobId,
            systemFolderType: SystemFolderType.JOB,
          }}
        />
      )}
    </>
  );
};

export default QuoteRequestCard;
