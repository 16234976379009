import React from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import EmptyPlaceholder from "../../../components/empty-placeholder";

import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import {
  RouteComponentProps,
  generatePath,
  useHistory,
} from "react-router-dom";
import { useQuoteRequestsQuery } from "../../../hooks/queries/useQuoteRequestsQuery";
import QuoteRequestList from "../../../components/quote-requests/quote-request-list";
import {
  QuoteRequest,
  QuoteRequestSubcontractor,
} from "../../../graphql/types/models/quote-request";
import QuoteRequestCard from "../../quote-request/quote-request-card";
import { find, head } from "lodash";
import EstimationLocationHeader from "../../header/estimation-location-header";

type Params = {
  id: string;
  qr_id?: string;
  sub_id?: string;
};
type EstimationQuoteRequestsProps = RouteComponentProps<Params> &
  DashboardContextValue & { user: UserPayload | null };

const EstimationQuoteRequests: React.FC<EstimationQuoteRequestsProps> = ({
  navigationContext,
  match,
  user,
}) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const salesQuoteId = navigationContext?.quote?._id || "";
  const { qr_id: quoteRequestId } = match.params;
  const { sub_id: subcontractorId } = match.params;
  const [
    selectedRequest,
    setSelectedRequest,
  ] = React.useState<QuoteRequest | null>(null);
  const [selectedSubcontractorId, setSelectedSubcontractorId] = React.useState(
    ""
  );

  const { t } = useTranslation();
  const history = useHistory();

  const {
    quoteRequests,
    getQuoteRequest,
    quoteRequest,
  } = useQuoteRequestsQuery({
    salesQuoteId,
  });

  React.useEffect(() => {
    const quoteRequest =
      find(quoteRequests, { _id: quoteRequestId || selectedRequest?._id }) ||
      head(quoteRequests);
    head(quoteRequests);
    const subcontractor =
      find(quoteRequest?.subcontractors, {
        _id: subcontractorId || selectedSubcontractorId,
      }) || head(quoteRequest?.subcontractors);
    if (quoteRequest && subcontractor) {
      handleSelect(quoteRequest, subcontractor);
    }
  }, [quoteRequests, quoteRequestId, subcontractorId]);

  const subcontractor = React.useMemo(() => {
    return quoteRequest?.subcontractors?.find(
      (s) => s._id === selectedSubcontractorId
    );
  }, [quoteRequest, selectedSubcontractorId]);

  const handleSelect = React.useCallback(
    (quoteRequest: QuoteRequest, subcontractor: QuoteRequestSubcontractor) => {
      setSelectedSubcontractorId(subcontractor._id);
      setSelectedRequest(quoteRequest);
      history.push(
        generatePath(
          "/quotes/:id/quote-requests/:qr_id/subcontractor/:sub_id",
          {
            id: salesQuoteId,
            qr_id: quoteRequest._id,
            sub_id: subcontractor._id,
          }
        )
      );
      getQuoteRequest({
        variables: {
          quoteRequestId: quoteRequest._id,
        },
      });
    },
    [getQuoteRequest, history, salesQuoteId]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quotesSection.quoteRequests")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.QUOTES_SECTION.QUOTE_REQUESTS}
      />
      <EstimationLocationHeader />
      {quoteRequests.length ? (
        <Row className="h-100">
          <Col lg={4} sm={5} xs={12}>
            <QuoteRequestList
              title={t("quoteRequest.quoteRequests")}
              onSelect={handleSelect}
              quoteRequests={quoteRequests}
              selectedSubcontractorId={selectedSubcontractorId}
            />
          </Col>
          <Col lg={8} sm={7} xs={12} className="mh-100">
            {quoteRequest && subcontractor ? (
              <QuoteRequestCard
                quoteRequest={quoteRequest}
                subcontractor={subcontractor}
                hideShareOptions={true}
                quoteId={salesQuoteId}
              />
            ) : (
              <CardPlaceholder />
            )}
          </Col>
        </Row>
      ) : (
        <EmptyPlaceholder message={t("quoteRequest.emptyPlaceholder")} />
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withDashboardContext(EstimationQuoteRequests));
