import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import { compact, map, trim } from "lodash";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../containers/layouts/dashboard/DashboardContext";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
  TableDropdownItem,
} from "../../../components/dashboard/table-card/utils";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import { JobListItem, JobStatus } from "../../../models/job";
import CreateJobModal from "../../../components/job/create-job-modal";
import { LIST_JOBS } from "../../../graphql/queries/job/queries";
import {
  ListJobsResponse,
  ArchiveJobResponse,
  UnarchiveJobResponse,
} from "../../../graphql/types/models/job";
import { DeleteJobResponse } from "../../../models/job";
import { DeleteJobInput, ArchiveJobInput } from "../../../graphql/input/job";
import {
  DELETE_JOB,
  ARCHIVE_JOB,
  UNARCHIVE_JOB,
} from "../../../graphql/queries/job/mutations";
import { handleJobRemove } from "../../../graphql/queries/job/utils";
import {
  formatQuoteNumber,
  getFullAddress,
  getFullName,
} from "../../../utils/text";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { notify } from "../../../components/notification";
import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import DashboardActionHeader from "../../../components/dashboard/table-card/DashboardActionHeader";
import "./styles.scss";

type JobsContainerProps = DashboardContextValue & {
  jobStatus?: JobStatus;
  user: UserPayload | null;
};

type JobTable = {
  _id: string;
  jobNumber: string;
  clientName: string;
  updatedAt: string;
  name: string;
  address: string;
  contractTotal: number;
  contractTotalInc: number;
  totalClaimed: number;
  totalRealCost: number;
  totalEstimated: number;
  totalProfit: number;
  type: string;
};

type ConfirmDialog = {
  title: string;
  description: string;
  confirm: string;
  onSubmit: () => void;
};

const Jobs: React.FC<JobsContainerProps> = (props) => {
  const { jobStatus = JobStatus.ACTIVE, user, setSearchOptions } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const createRef = React.useRef<ModalDisplayRef>(null);
  const confirmRef = React.useRef<ConfirmDialogRef>(null);

  const [filter, setFilter] = React.useState("");

  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmDialog>();

  const {
    data: jobsList,
    loading: jobsLoading,
    updateQuery: updateJobsList,
  } = useQuery<ListJobsResponse>(LIST_JOBS, {
    variables: {
      status: jobStatus,
    },
    fetchPolicy: "cache-and-network",
  });

  const [removeJobs, { loading: jobDeleting }] = useMutation<
    DeleteJobResponse,
    DeleteJobInput
  >(DELETE_JOB);

  const [completeJob, { loading: jobCompleting }] = useMutation<
    ArchiveJobResponse,
    ArchiveJobInput
  >(ARCHIVE_JOB);

  const [unarchiveJob, { loading: jobUncompleting }] = useMutation<
    UnarchiveJobResponse,
    ArchiveJobInput
  >(UNARCHIVE_JOB, {
    onCompleted: () => {
      notify({
        title: t("jobs.reopenJob"),
        content: t("jobs.success.reopenJob"),
      });
    },
    onError: (e) => {
      notify({
        error: true,
        title: t("jobs.reopenJob"),
        content: e?.message,
      });
    },
  });

  const handleSearch = React.useCallback(
    (text: string) => setFilter(trim(text)),
    []
  );

  const isAdmin = React.useMemo(() => user?.role === UserRoles.builderadmin, [
    user,
  ]);

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("common.search"),
      options: [],
      onAutocomplete: handleSearch,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [t, setSearchOptions, handleSearch]);

  const openJobModal = React.useCallback(() => {
    createRef?.current?.show(true);
  }, [createRef]);

  const openRemoveDialog = React.useCallback(
    (row?: JobTable) => {
      if (row) {
        setConfirmDialog({
          title: t("jobs.removeJob"),
          description: t("jobs.removeMessage"),
          confirm: t("common.delete"),
          onSubmit: handleJobRemoveSubmit(row._id),
        });
        confirmRef?.current?.show(true);
      }
    },
    [confirmDialog, confirmRef]
  );

  const openCompleteDialog = React.useCallback(
    (row?: JobTable) => {
      if (row) {
        setConfirmDialog({
          title: t("jobs.completeJob"),
          description: t("jobs.completeMessage"),
          confirm: t("common.complete"),
          onSubmit: handleJobCompleteSubmit(row._id),
        });
        confirmRef?.current?.show(true);
      }
    },
    [confirmDialog, confirmRef]
  );

  const openReopenDialog = React.useCallback(
    (row?: JobTable) => {
      if (row) {
        setConfirmDialog({
          title: t("jobs.reopenJob"),
          description: t("jobs.reopenMessage"),
          confirm: t("jobs.reopenJob"),
          onSubmit: handleReopenJobSubmit(row._id),
        });
        confirmRef?.current?.show(true);
      }
    },
    [confirmDialog, confirmRef]
  );

  const closeConfirmDialog = React.useCallback(() => {
    setConfirmDialog(undefined);
    confirmRef?.current?.show(false);
  }, [confirmRef]);

  const filteredJobs = React.useMemo(
    () =>
      jobsList?.listJobs?.filter((job) => {
        return (
          job.name.toUpperCase().includes(filter.toUpperCase()) ||
          getFullAddress(job, " ").toUpperCase().includes(filter.toUpperCase())
        );
      }),
    [jobsList, filter]
  );

  const jobsTableData = React.useMemo<TableCardData<JobTable>>(
    () => ({
      columns: compact([
        {
          valueKey: "jobNumber",
          title: t("common.number"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            formatQuoteNumber(value, "J"),
        },
        {
          valueKey: "name",
          title: t("jobs.job"),
          className: "job-name",
          sortable: true,
          tooltip: (row) => row.name,
        },
        {
          valueKey: "type",
          title: t("common.type"),
          className: "job-type",
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            t(`jobs.types.${value}`),
        },
        {
          valueKey: "address",
          title: t("jobs.address"),
          className: "cell-truncate",
          sortable: true,
          tooltip: (row) => getFullAddress(row),
        },
        {
          valueKey: "clientName",
          title: t("jobs.client"),
          sortable: true,
        },
        user?.role !== UserRoles.basic && {
          valueKey: "contractTotalInc",
          title: t("jobs.contractTotalInc"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "updatedAt",
          title: t("jobs.lastUpdated"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            moment(value).format("Do MMM YYYY"),
        },
      ]),
      rows: map(filteredJobs, (job: JobListItem) => ({
        cells: {
          _id: job._id,
          jobNumber: `${job.jobNumber}`,
          address: getFullAddress(job),
          clientName: getFullName(job.customer),
          updatedAt: job.updatedAt,
          name: job.name,
          contractTotal: job.contractTotal,
          contractTotalInc:
            Number(job.contractTotal || 0) + Number(job.contractTotalGST || 0),
          totalClaimed: job.totalClaimed,
          totalRealCost: job.totalRealCost,
          totalEstimated: job.totalEstimatedWithMarkup,
          totalProfit: job.totalClaimed - job.totalRealCost,
          type: job.type,
        },
      })),
    }),
    [t, filteredJobs, user]
  );

  const handleJobRemoveSubmit = React.useCallback(
    (targetId: string) => async () => {
      try {
        if (!targetId) {
          return;
        }

        await removeJobs({
          variables: {
            jobId: targetId,
          },
        });

        updateJobsList(handleJobRemove(targetId));

        notify({
          title: t("jobs.removeJob"),
          content: t("jobs.success.removeJob"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("jobs.removeJob"),
          content: t("jobs.error.removeJob"),
        });
      }

      closeConfirmDialog();
    },
    [confirmDialog, closeConfirmDialog]
  );

  const handleJobCompleteSubmit = React.useCallback(
    (targetId: string) => async () => {
      try {
        if (!targetId) return;
        await completeJob({
          variables: {
            jobId: targetId,
          },
        });

        updateJobsList(handleJobRemove(targetId));

        notify({
          title: t("jobs.completeJob"),
          content: t("jobs.success.completeJob"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("jobs.completeJob"),
          content: t("jobs.error.completeJob"),
        });
      }

      closeConfirmDialog();
    },
    [t, confirmDialog, closeConfirmDialog, completeJob, updateJobsList]
  );

  const handleReopenJobSubmit = React.useCallback(
    (targetId: string) => async () => {
      if (!targetId) return;
      unarchiveJob({
        variables: {
          jobId: targetId,
        },
      });
      closeConfirmDialog();
    },
    [t, closeConfirmDialog, unarchiveJob]
  );

  const jobTableRowDropdownActions: TableDropdownItem<
    JobTable
  >[] = React.useMemo(
    () => [
      jobStatus === JobStatus.ACTIVE
        ? {
            icon: "task_alt",
            outlined: true,
            id: "complete",
            label: t("common.markComplete"),
            onClick: openCompleteDialog,
          }
        : {
            icon: "restore",
            outlined: true,
            id: "uncomplete",
            label: t("jobs.reopenJob"),
            onClick: openReopenDialog,
          },
      {
        icon: "delete",
        outlined: true,
        id: "remove",
        label: t("common.remove"),
        onClick: openRemoveDialog,
      },
    ],
    [t, openRemoveDialog, openCompleteDialog, openReopenDialog, jobStatus]
  );

  const jobTableRowActions: TableRowActionData<JobTable>[] = React.useMemo(
    () =>
      user?.role === UserRoles.builderadmin
        ? [
            {
              icon: "more_horiz",
              dropdownId: "team-member",
              options: jobTableRowDropdownActions,
            },
          ]
        : [],
    [jobTableRowDropdownActions, user]
  );

  const handleJobClick = React.useCallback(
    (row: TableCardDataRow<JobTable>) => {
      history.push(`/jobs/${row.cells._id}`);
    },
    [history]
  );

  const routeId = React.useMemo(
    () =>
      jobStatus === JobStatus.ACTIVE
        ? NAVIGATION_ROUTES.JOBS_SECTION.JOBS
        : NAVIGATION_ROUTES.JOBS_SECTION.COMPLETED_JOBS,
    [jobStatus]
  );

  const helmetTitle = React.useMemo(
    () =>
      jobStatus === JobStatus.ACTIVE
        ? t("navigation.jobsSection.jobs")
        : t("navigation.jobsSection.jobs"),
    [t, jobStatus]
  );

  return (
    <div>
      <SetNavigationRoute routeId={routeId} />
      <Helmet title={helmetTitle} />

      <CreateJobModal ref={createRef} />

      <ConfirmDialog
        disabled={jobDeleting || jobCompleting || jobUncompleting}
        ref={confirmRef}
        onClose={closeConfirmDialog}
        onSubmit={confirmDialog?.onSubmit}
        title={confirmDialog?.title}
        confirm={confirmDialog?.confirm}
      >
        <div className="field-text">{confirmDialog?.description}</div>
      </ConfirmDialog>
      <Row className="justify-content-end pb-4">
        <Col>
          <DashboardActionHeader
            onActionButton={openJobModal}
            shouldRenderActionButton={isAdmin}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {jobsLoading || jobsTableData.rows.length ? (
            <TableCard
              tableId="jobs"
              isDataLoading={jobsLoading}
              data={jobsTableData}
              alignEnd
              onRowClick={handleJobClick}
              rowActions={jobTableRowActions}
              className="overflow-visible"
            />
          ) : jobStatus === JobStatus.ACTIVE ? (
            user?.role === UserRoles.builderadmin ? (
              <EmptyPlaceholder
                message={t("jobs.emptyPlaceholder")}
                buttonText={t("jobs.addNewJob")}
                onButtonPress={openJobModal}
              />
            ) : (
              <EmptyPlaceholder message={t("jobs.emptyPlaceholder")} />
            )
          ) : (
            <EmptyPlaceholder message={t("jobs.emptyCompletePlaceholder")} />
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(Jobs));
