import React, { useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { keys, chain, pick, find, mapValues } from "lodash";
import { useHistory } from "react-router-dom";
import {
  SupplierDetails,
  CreateSupplierPayload,
  SupplierListCategoryItem,
  SupplierModalFields,
  UpdateSupplierPayload,
} from "../../../models/supplier";

import CreateEntityModal, { ModalButton } from "../../modals/create-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { createSuppliersFormFields } from "./utils";
import createSupplierSchema from "./CreateSupplier.schema";
import "./styles.scss";
import { handlePlaceSelect } from "../../../utils/place";
import { LIST_SUPPLIERS_CATEGORIES } from "../../../graphql/queries/supplier/queries";
import {
  CreateSupplierResponse,
  DeleteSupplierResponse,
  SupplierListCategoriesResponse,
} from "../../../graphql/types/models/supplier";
import {
  CREATE_UPDATE_SUPPLIER,
  DELETE_SUPPLIER,
} from "../../../graphql/queries/supplier/mutations";
import {
  handleSupplierAdd,
  handleSupplierDelete,
} from "../../../graphql/queries/supplier/utils";
import { notify } from "../../../components/notification";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";

type CreateSupplierModalProps = {
  onCreate?: (supplier: SupplierDetails) => void;
  supplier?: SupplierDetails;
};

export type CreateSupplierModalRef = {
  show: (show: boolean) => void;
};

const CreateSupplierModal: React.FC<CreateSupplierModalProps> = (
  { onCreate, supplier },
  ref
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<SupplierModalFields>
  >({});
  const [categories, setCategories] = React.useState<
    SupplierListCategoryItem[]
  >([]);
  const [tempCategories, setTempCategories] = React.useState<
    SupplierListCategoryItem[]
  >([]);
  const [showModal, setShowModal] = React.useState(false);
  const confirmRef = React.useRef<ConfirmDialogRef>(null);

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => setShowModal(show),
  }));

  const { data: supplierCategories } = useQuery<SupplierListCategoriesResponse>(
    LIST_SUPPLIERS_CATEGORIES
  );

  const [createSupplier] = useMutation<CreateSupplierResponse>(
    CREATE_UPDATE_SUPPLIER,
    {
      onCompleted: ({ createUpdateSupplier }) => {
        onCreate && onCreate(createUpdateSupplier);
        setShowModal(false);
        const message = supplier ? "updateSupplier" : "createSupplier";
        notify({
          content: t(`contacts.success.${message}`),
          title: t(`contacts.${message}`),
        });
      },
      update: (cache, props) =>
        !supplier ? handleSupplierAdd(cache, props) : null,
      onError: () => {
        const message = supplier ? "updateSupplier" : "createSupplier";
        notify({
          error: true,
          content: t(`contacts.errors.${message}`),
          title: t(`contacts.${message}`),
        });
      },
    }
  );

  const [deleteSupplier, { loading: deletingSupplier }] = useMutation<
    DeleteSupplierResponse
  >(DELETE_SUPPLIER, {
    update: handleSupplierDelete,
    onCompleted: () => {
      setShowModal(false);
      notify({
        title: t("contacts.deleteSupplier"),
        content: t("contacts.success.deleteSupplier"),
      });
      history.push("/contacts/suppliers");
    },
    onError: () => {
      notify({
        error: true,
        title: t("contacts.deleteSupplier"),
        content: t("contacts.errors.deleteSupplier"),
      });
    },
  });

  const handleCategoryChange = React.useCallback(
    (value: string) => {
      const option = {
        _id: value,
        name: value,
      };
      setTempCategories([option, ...tempCategories]);
    },
    [tempCategories, setTempCategories]
  );

  React.useEffect(() => {
    const categoryOptions = chain(categories)
      .concat(tempCategories)
      .map((category) => ({
        value: category._id,
        label: category.name,
      }))
      .uniqBy("value")
      .value();

    setFormFields(
      createSuppliersFormFields(
        t,
        categoryOptions,
        handleCategoryChange,
        handlePlaceSelect
      )
    );
  }, [t, categories, handleCategoryChange, tempCategories]);

  React.useEffect(() => {
    if (supplierCategories) {
      setCategories(supplierCategories?.listSupplierCategories);
    }
  }, [supplierCategories, setCategories]);

  const handleSubmit = React.useCallback(
    (values: SupplierModalFields) => {
      if (!values._id) {
        delete values._id;
      }
      const supplier: CreateSupplierPayload = {
        ...values,
        mobile: values.phone_number,
        abn: values.abn ? values.abn.replace(/\s/g, "") : "",
      };

      const category = find(tempCategories, { _id: values.categoryId });

      if (category && category._id === category.name) {
        supplier.new_category_name = category.name;
        delete supplier.categoryId;
      }

      return createSupplier({
        variables: {
          supplier,
        },
      });
    },
    [tempCategories, createSupplier]
  );

  const deleteButton = React.useMemo<ModalButton>(
    () => ({
      title: t("common.delete"),
      className: "button large info",
      onClick: async () => {
        confirmRef.current?.show(true);
      },
    }),
    [confirmRef]
  );
  const confirmSupplierDelete = React.useCallback(() => {
    deleteSupplier({
      variables: {
        id: supplier?._id,
      },
    });
  }, [supplier, deleteSupplier]);

  const initialValues = React.useMemo(() => {
    let data = {
      _id: "",
      abn: "",
      phone_number: "",
      phone_secondary: "",
      contact_person: "",
      email: "",
      categoryId: "",
      business_name: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      postcode: "",
      fax_number: "",
    } as UpdateSupplierPayload;
    if (supplier) {
      data = {
        ...mapValues(pick(supplier, keys(data)), (s) => s || ""), // convert nulls to ""
        categoryId: supplier.category?._id || "",
      } as UpdateSupplierPayload;
    }
    return data;
  }, [supplier]);

  return (
    <>
      <CreateEntityModal
        className="create-supplier-modal"
        validationSchema={createSupplierSchema(t)}
        title={supplier?.business_name || t("contacts.newSupplier")}
        show={showModal}
        data={initialValues}
        onSubmit={handleSubmit}
        onClose={() => setShowModal(false)}
        fields={formFields}
        submitText={supplier ? t("common.update") : undefined}
        leftButton={supplier ? deleteButton : undefined}
      />
      <ConfirmDialog
        ref={confirmRef}
        disabled={deletingSupplier}
        title={t("contacts.deleteSupplier")}
        onSubmit={confirmSupplierDelete}
      >
        <span className="field-text">
          {t("contacts.deleteSupplierMessage")}
        </span>
      </ConfirmDialog>
    </>
  );
};

export default forwardRef(CreateSupplierModal);
