import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { map } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import { useSelectionTemplates } from "../../../hooks/useSelectionTemplates";
import { SelectionTemplateType } from "../../../graphql/types/models/selections";

const SelectionTemplatesOverview: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    selectionTemplates,
    selectionTemplatesDataLoading,
  } = useSelectionTemplates();

  const handleTemplateClick = React.useCallback(
    (row: TableCardDataRow<SelectionTemplateType>) => {
      history.push(`/settings/selection-templates/${row.cells._id}`);
    },
    [history]
  );

  const selectionTemplatesData: TableCardData<SelectionTemplateType> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
        },
        {
          valueKey: "updatedAt",
          title: t("common.lastUpdated"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
      ],
      rows: map(selectionTemplates, (template: SelectionTemplateType) => ({
        cells: template,
      })),
    };
  }, [t, selectionTemplates]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.selectionTemplates")} />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SELECTION_TEMPLATES
        }
      />
      <TableCard
        isDataLoading={selectionTemplatesDataLoading}
        fullHeight
        overflowXHidden
        data={selectionTemplatesData}
        onRowClick={handleTemplateClick}
        alignEnd
      />
    </Container>
  );
};

export default SelectionTemplatesOverview;
