import {
  ChecklistCategoryType,
  ChecklistEntityResult,
  ChecklistType,
  EnumChecklistEntityType,
} from "../../../models/checklists";
import { formatQuoteNumber } from "../../../utils/text";

export const calcChecklistProgress = (item: ChecklistCategoryType) => {
  return item.checklist.length
    ? Math.floor(
        (item.checklist.filter((task: ChecklistType) => task.completed).length /
          item.checklist.length) *
          100
      )
    : 0;
};

export const calcChecklistCategoryProgress = (
  category: ChecklistEntityResult
) => {
  if (!category?.categories) return 0;
  const sum = category?.categories?.reduce((total, item) => {
    return calcChecklistProgress(item) + total;
  }, 0);
  return Math.floor(sum / category.categories.length);
};

export const getCategoryNumber = (
  entityType: EnumChecklistEntityType,
  category: ChecklistEntityResult
) => {
  if (entityType === EnumChecklistEntityType.Job) {
    return formatQuoteNumber(category.entity.jobNumber, "J");
  }

  if (entityType === EnumChecklistEntityType.Quote) {
    return formatQuoteNumber(category.entity.number, "Q");
  }
  return category?.entity?.name;
};
