import { TFunction } from "i18next";
import * as yup from "yup";

export default function createClaimReceiveSchema(
  t: TFunction,
  maxAmount: number
) {
  return yup.object().shape({
    amount: yup.number().max(maxAmount).required(),
    dateReceived: yup.string().required(),
    note: yup.string().optional(),
    reference: yup.string().optional(),
  });
}
