import React from "react";
import { useQuery } from "@apollo/client";
import { JobTotalResponse } from "../../graphql/types/models/job-costing";
import { JOB_TOTALS } from "../../graphql/queries/job-costing/queries";

export function useJobTotalsQuery(jobId?: string) {
  const {
    data: jobTotalsData,
    loading: jobTotalsLoading,
    refetch: refetchJobTotals,
  } = useQuery<JobTotalResponse>(JOB_TOTALS, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const jobTotals = React.useMemo(() => jobTotalsData?.totals, [jobTotalsData]);

  return { jobTotals, jobTotalsLoading, refetchJobTotals };
}
