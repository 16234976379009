import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";
import { ReminderModalFields } from "./index";
import { ReminderRepeatTypeEnum } from "../../../models/reminder";

export default function createReminderSchema(t: TFunction) {
  return yup.object().shape<ReminderModalFields>({
    title: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "reminders.title")
      ),
    description: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "reminders.description"
        )
      ),
    endDate: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "reminders.date")
      ),
    endTime: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "reminders.time")
      ),
    repeat: yup
      .string()
      .oneOf([
        ReminderRepeatTypeEnum.Annually,
        ReminderRepeatTypeEnum.Monthly,
        ReminderRepeatTypeEnum.Weekly,
        ReminderRepeatTypeEnum.None,
      ])
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "reminders.repeat")
      ),
  });
}

export const validateEmail = (email: string) =>
  yup.string().email().isValidSync(email);
