import React from "react";

type UseSelectedItemsProps<T> = {
  handleToggle: (item: T) => void;
  items: T[];
  setItems: (items: T[]) => void;
};

export function useSelectedItems<T>(items: T[]): UseSelectedItemsProps<T> {
  const [selectedItems, setSelectedItems] = React.useState<T[]>(items);

  const handleAdd = (id: T): void => {
    setSelectedItems((prevState: T[]) => prevState.concat(id));
  };

  const handleRemove = (id: T): void => {
    setSelectedItems((prevState: T[]) => prevState.filter((it) => it !== id));
  };

  const handleToggle = (id: T): void => {
    selectedItems.includes(id) ? handleRemove(id) : handleAdd(id);
  };

  return { handleToggle, items: selectedItems, setItems: setSelectedItems };
}
