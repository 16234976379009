import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import JobLocationHeader from "../../header/job-location-header";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import Conversation from "../../../components/communication/conversation";
import { find, head, isEmpty } from "lodash";
import ChannelList, {
  ChannelInfo,
} from "../../../components/communication/channels-list";
import { useMutation, useQuery } from "@apollo/client";
import { GET_JOB_CHANNELS } from "../../../graphql/queries/job-messaging/queries";
import { generatePath, useHistory, useParams } from "react-router-dom";
import ClientHeaderList from "../../header/client-header-list";
import {
  GetJobChannelsResponse,
  JobChannelComposeResponse,
  OnMessageAddResponse,
} from "../../../graphql/types/models/job-messaging";
import { UserPayload } from "../../../graphql/types/models/auth";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import {
  GetJobChannelsInput,
  JobChannelComposeInput,
  OnJobMessageAddedInput,
} from "../../../graphql/types/inputs/messaging";
import { JOB_CHANNEL_COMPOSE } from "../../../graphql/queries/job-messaging/mutations";
import { ComposeRef } from "../../../components/communication/compose";
import { getMediaInput } from "../../../utils/transform";
import {
  JOB_MESSAGING_SUBSCRIBE_EXTERNAL,
  JOB_MESSAGING_SUBSCRIBE_INTERNAL,
} from "../../../graphql/queries/job-messaging/subscription";
import { uploadFiles } from "../../../utils/files";
import {
  updateInternalChannel,
  updateExternalChannel,
  handlePostMessage,
  createMessageOptimisticResponse,
} from "../../../graphql/queries/job-messaging/utils";
import { RootReducerState } from "../../../redux/reducer";
import { connect } from "react-redux";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { UserRoles } from "../../../models/team-member";
import TeammateHeaderList from "../../header/teammate-header-list";
import { useDeleteJobChannelMessageMutation } from "../../../hooks/mutations/useDeleteJobChannelMessageMutation";
import { MembersDetails } from "../../../components/communication/members-details";

type Params = {
  id: string;
  channelId: string;
};

type JobMessagesProps = {
  user: UserPayload | null;
} & DashboardContextValue;

const JobMessages: React.FC<JobMessagesProps> = (props) => {
  const { user, navigationContext } = props;
  const isLocked = navigationContext?.job?.isLocked || false;

  const { t } = useTranslation();
  const history = useHistory();

  const { id: jobId, channelId } = useParams<Params>();

  const {
    data: channelsData,
    loading: channelsLoading,
    updateQuery: updateChannel,
    subscribeToMore,
  } = useQuery<GetJobChannelsResponse, GetJobChannelsInput>(GET_JOB_CHANNELS, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const composeRef = React.useRef<ComposeRef>(null);

  const [sendMessage] = useMutation<
    JobChannelComposeResponse,
    JobChannelComposeInput
  >(JOB_CHANNEL_COMPOSE);

  const isReadonly = React.useMemo(() => user?.role === UserRoles.basic, [
    user,
  ]);

  const [selectedChannel, setChannel] = useState<ChannelInfo | null>();

  const channels = React.useMemo<ChannelInfo[]>(() => {
    const internal = channelsData?.internalChannel;
    const external = channelsData?.externalChannel;

    if (!channelsData) {
      return [];
    }

    return [
      {
        _id: internal?._id || "",
        name: t("common.internal"),
        icon: "lock",
        messages: internal?.messages,
      },
      {
        _id: external?._id || "",
        name: t("common.external"),
        icon: "public",
        messages: external?.messages,
      },
    ];
  }, [t, channelsData]);

  const {
    renderDeleteMessageConfirmDialog,
    handleShowDeleteMessageConfirmDialog,
  } = useDeleteJobChannelMessageMutation({
    jobId,
    channelId: selectedChannel?._id,
  });

  const handleConversationSelect = React.useCallback(
    (_id?: string) => {
      const channel = find(channels, { _id }) || head(channels);

      if (channel) {
        setChannel(channel);
        history.push(
          generatePath("/jobs/:id/messages/:channelId", {
            id: jobId,
            channelId: channel?._id,
          })
        );
      }
    },
    [channels, history, jobId]
  );

  const internalId = channelsData?.internalChannel?._id;
  const externalId = channelsData?.externalChannel?._id;
  const internalMembers = channelsData?.internalChannel?.job?.members;
  const externalMembers = React.useMemo(
    () =>
      channelsData?.externalChannel?.job?.members?.filter(
        (m) => m.role !== UserRoles.basic
      ),
    [channelsData]
  );

  React.useEffect(() => {
    if (!externalId) {
      return;
    }

    const unsubExternal = subscribeToMore<
      OnMessageAddResponse,
      OnJobMessageAddedInput
    >({
      document: JOB_MESSAGING_SUBSCRIBE_EXTERNAL,
      variables: {
        jobId,
        channelId: externalId,
      },
      updateQuery: updateExternalChannel,
    });

    return () => {
      unsubExternal();
    };
  }, [subscribeToMore, externalId]);

  React.useEffect(() => {
    if (!internalId) {
      return;
    }

    const unsubInternal = subscribeToMore<
      OnMessageAddResponse,
      OnJobMessageAddedInput
    >({
      document: JOB_MESSAGING_SUBSCRIBE_INTERNAL,
      variables: {
        jobId,
        channelId: internalId,
      },
      updateQuery: updateInternalChannel,
    });

    return () => {
      unsubInternal();
    };
  }, [internalId]);

  const handleMessageSend = React.useCallback(
    async (message: string, files: File[] | null) => {
      if (!selectedChannel) {
        return;
      }

      composeRef.current?.reset();

      if (!message && isEmpty(files)) {
        return;
      }

      const media = getMediaInput(files);

      const channelId = selectedChannel?._id;

      try {
        const response = await sendMessage({
          optimisticResponse: createMessageOptimisticResponse(channelId, user),
          variables: {
            channelId,
            jobId,
            message: {
              text: message,
              media,
            },
          },
        });

        const newMessage = response.data?.jobChannelCompose;

        if (newMessage?.media && files) {
          await uploadFiles(newMessage.media, files);
        }
        updateChannel((channelDetails) => {
          return handlePostMessage(channelDetails, response.data);
        });
      } catch (e) {}
    },
    [user, selectedChannel, jobId]
  );

  React.useEffect(() => {
    handleConversationSelect(channelId || selectedChannel?._id);
  }, [channels, channelId]);

  const channelMessages = React.useMemo(
    () =>
      selectedChannel?.messages?.filter((message) => !message.is_deleted) || [],
    [selectedChannel]
  );

  const isExternalChat = selectedChannel?.name === t("common.external");

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.jobMessages")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.JOBS_SECTION.JOB_MESSAGES}
      />

      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      <Row className="h-100">
        {!isReadonly && (
          <Col lg={4} xs={12} className="mh-100">
            {channelsLoading && !selectedChannel ? (
              <CardPlaceholder />
            ) : (
              <ChannelList
                selectedId={selectedChannel?._id}
                channels={channels}
                onSelect={handleConversationSelect}
              />
            )}
          </Col>
        )}
        <Col lg={8} xs={12} className="mh-100">
          {!selectedChannel && <CardPlaceholder />}
          {selectedChannel && (
            <>
              <Conversation
                ref={composeRef}
                onSend={handleMessageSend}
                messages={channelMessages}
                name={`${selectedChannel.name} ${t(
                  "communication.conversation"
                )}`}
                disabled={isLocked}
                user={user}
                onDeleteMessage={handleShowDeleteMessageConfirmDialog}
                subHeader={() => (
                  <MembersDetails
                    externalMembers={externalMembers}
                    internalMembers={internalMembers}
                    isExternalChat={isExternalChat}
                    jobId={jobId}
                  />
                )}
              />
              {renderDeleteMessageConfirmDialog()}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps)(withDashboardContext(JobMessages));
