import React from "react";
import { TFunction } from "i18next";
import { DashboardNavigationContext } from "../../../../containers/layouts/dashboard/DashboardContext";
import { SidebarNavItem } from "../SidebarNavListItem";

import Icon from "../../../icons/Icon";
import SVGIcon from "../../../icons/SVGIcon";

export const NAVIGATION_ROUTES = {
  DASHBOARD: "DASHBOARD",
  ADMIN_SECTION: {
    BUSINESSES: "QUOTES",
    DETAILS: "DETAILS",
  },
  USERS: "USERS",
  AFFILIATES: "AFFILIATES",
  AFFILIATES_SECTION: {
    AFFILIATES: "AFFILIATES",
    PENDING_AFFILIATES: "PENDING_AFFILIATES",
    AFFILIATE_DETAILS: "AFFILIATE_DETAILS",
  },
  LOGOUT: {
    LOGOUT: "LOGOUT",
  },
};

export const createAdminNavItems = (
  t: TFunction,
  context: DashboardNavigationContext | null
): SidebarNavItem[] => {
  return [
    {
      id: NAVIGATION_ROUTES.DASHBOARD,
      icon: () => <Icon name="home" outlined />,
      title: t("navigation.dashboard"),
      url: "/",
    },
    {
      id: NAVIGATION_ROUTES.ADMIN_SECTION.BUSINESSES,
      expandable: true,
      icon: () => <SVGIcon name="Bill" />,
      url: "/businesses",
      title: t("navigation.wbAdmin.businesses"),
      defaultItemId: NAVIGATION_ROUTES.ADMIN_SECTION.BUSINESSES,
      items: [
        {
          id: NAVIGATION_ROUTES.ADMIN_SECTION.BUSINESSES,
          icon: () => <SVGIcon name="Bill" />,
          title: t("navigation.wbAdmin.businesses"),
          url: "/businesses",
          hideInSidebarMenu: !!context?.business,
        },
        {
          id: NAVIGATION_ROUTES.ADMIN_SECTION.DETAILS,
          icon: () => <SVGIcon name="Bill" />,
          title: t("navigation.wbAdmin.businessDetails"),
          url: `/businesses/${context?.business?._id}`,
          hideInSidebarMenu: !context?.business,
        },
      ],
    },
    {
      id: NAVIGATION_ROUTES.USERS,
      icon: () => <Icon name="person" outlined />,
      title: t("navigation.wbAdmin.users"),
      url: `/users`,
    },
    {
      id: NAVIGATION_ROUTES.AFFILIATES,
      expandable: true,
      icon: () => <Icon name="link" outlined />,
      title: t("navigation.wbAdmin.affiliates"),
      url: `/affiliates`,
      defaultItemId: NAVIGATION_ROUTES.AFFILIATES_SECTION.AFFILIATES,
      items: [
        {
          id: NAVIGATION_ROUTES.AFFILIATES_SECTION.AFFILIATES,
          icon: () => <Icon name="hourglass_empty" outlined />,
          title: t("navigation.wbAdmin.affiliates"),
          url: "/affiliates",
          hideInSidebarMenu: true,
        },
        {
          id: NAVIGATION_ROUTES.AFFILIATES_SECTION.PENDING_AFFILIATES,
          icon: () => <Icon name="hourglass_empty" outlined />,
          title: t("navigation.wbAdmin.pendingAffiliates"),
          url: "/affiliates?status=pending",
        },
        {
          id: NAVIGATION_ROUTES.AFFILIATES_SECTION.AFFILIATE_DETAILS,
          icon: () => <Icon name="business" outlined />,
          title: t("navigation.wbAdmin.affiliateDetails"),
          url: `/affiliates/${context?.affiliate?._id}`,
          hideInSidebarMenu: !context?.affiliate,
        },
      ],
    },
    {
      id: NAVIGATION_ROUTES.LOGOUT.LOGOUT,
      icon: () => <Icon name="logout" outlined />,
      title: t("navigation.account.logout"),
      isLogout: true,
    },
  ];
};
