import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { compact } from "lodash";
import CustomReportCard from "../../dashboard/report-card/custom-report-card";
import { VariationReal, VariationStatuses } from "../../../models/variations";
import DashboardCardField from "../../dashboard/card/DashboardCardField";
import Totals from "../../costing/total";
import { TableCardData } from "../../dashboard/table-card/utils";
import { CostingTable, createCostingTable } from "../../costing/utils";
import CardTable from "../../dashboard/table-card/CardTable";
import { formatQuoteNumber } from "../../../utils/text";
import { useViewInJob } from "../../../hooks/useViewInJobs";
import DashboardCardSignField from "../../dashboard/card/DashboardCardSignField";
import { EnumDigitalSignatureType } from "../../accept-signature-dialog/utils";
import { getVariationBadgeVariant } from "../../../containers/jobs/job-variations/utils";
import "./styles.scss";

type VariationCardProps = {
  variation: VariationReal;
  titleIndex?: number | null;
  onApprove?: (id: string) => void;
  onUnapprove?: (id: string) => void;
  onDelete?: (id: string) => void;
  onSend?: (id: string) => void;
  onPrint?: (id: string) => void;
  onEdit?: () => void;
  disableCancel?: boolean;
  withoutControls?: boolean;
  isGlobalView?: boolean;
  isLoading?: boolean;
};

const VariationCard: React.FC<VariationCardProps> = (props) => {
  const { t } = useTranslation();

  const {
    variation,
    titleIndex,
    onApprove,
    onUnapprove,
    onDelete,
    onSend,
    onPrint,
    onEdit,
    disableCancel,
    withoutControls,
    isGlobalView = false,
    isLoading,
  } = props;

  const {
    _id,
    name,
    status,
    total,
    GST,
    note,
    subTotal,
    date,
    contact,
    items,
    job,
  } = variation;

  const handlePrint = React.useCallback(() => {
    onPrint && onPrint(_id);
  }, [onPrint, _id]);

  const handleDelete = React.useCallback(() => {
    onDelete && onDelete(_id);
  }, [onDelete, _id]);

  const handleSend = React.useCallback(() => {
    onSend && onSend(_id);
  }, [onSend, _id]);

  const handleApprove = React.useCallback(() => {
    onApprove && onApprove(_id);
  }, [onApprove, _id]);

  const handleUnapprove = React.useCallback(() => {
    onUnapprove && onUnapprove(_id);
  }, [onUnapprove, _id]);

  const handleEdit = React.useCallback(() => {
    onEdit && onEdit();
  }, [onEdit]);

  const categoryData = React.useMemo<TableCardData<CostingTable>>(
    () => createCostingTable(items, t, 0, ["allocatedCategory"]),
    [items, t]
  );

  const { buttons } = useViewInJob({
    isGlobalView: isGlobalView,
    jobId: job?._id,
    orderId: _id,
    path: t("navigation.jobsSection.variations"),
  });

  const dropdownItems = React.useMemo(() => {
    const controls = compact([
      {
        id: "print",
        label: t("common.print"),
        icon: "print",
        outlined: true,
        onClick: handlePrint,
      },
      {
        id: "mail",
        label: t("common.send"),
        icon: "mail",
        outlined: true,
        onClick: handleSend,
      },
    ]);

    if (status !== VariationStatuses.APPROVED_BY_BUILDER) {
      controls.push({
        id: "approve",
        label: t("variations.approve"),
        icon: "check",
        outlined: true,
        onClick: handleApprove,
      });
      controls.push({
        id: "edit",
        label: t("common.edit"),
        icon: "edit",
        outlined: true,
        onClick: handleEdit,
      });
      controls.push({
        id: "delete",
        label: t("common.delete"),
        icon: "delete",
        outlined: true,
        onClick: handleDelete,
      });
    }
    if (status === VariationStatuses.APPROVED_BY_BUILDER) {
      controls.push({
        id: "unapprove",
        label: t("variations.unapprove"),
        icon: "cancel",
        outlined: true,
        onClick: handleUnapprove,
      });
    }

    return controls;
  }, [
    status,
    handleDelete,
    handleApprove,
    handleUnapprove,
    handleSend,
    handlePrint,
    onEdit,
  ]);

  const title = formatQuoteNumber(titleIndex?.toString() || "", "V");

  const badges = React.useMemo(() => {
    return compact([
      variation.progressClaimed
        ? {
            variant: getVariationBadgeVariant(variation),
            label: t("variations.claimed"),
          }
        : null,
      {
        variant: getVariationBadgeVariant(variation),
        label: t(`variations.statuses.${variation.status}`),
      },
    ]);
  }, [variation]);

  return (
    <CustomReportCard
      title={title}
      badges={badges}
      controls={dropdownItems}
      withoutControls={withoutControls}
      buttons={buttons}
      className="variation-card"
    >
      <Row className="main-row">
        <Col lg={6} xs={12} className="report-col">
          <DashboardCardField title={t("variations.name")} content={name} />
        </Col>
        <Col lg={3} xs={12} className="report-col">
          <DashboardCardField
            title={t("variations.contactName")}
            content={compact([contact?.first_name, contact?.last_name]).join(
              " "
            )}
          />
        </Col>
        <Col lg={3} xs={12} className="report-col">
          <DashboardCardField
            title={t("variations.date")}
            content={moment(date).format("D MMMM YYYY")}
          />
        </Col>
      </Row>

      <Row className="main-row table-row">
        <CardTable rowCount table={categoryData} />
      </Row>

      <Row className="row--big">
        <Col lg={7} xs={12} className="report-col--big">
          <DashboardCardField
            title={t("variations.notes")}
            content={note}
            placeholder={t("variations.noNotes")}
            height="180px"
            wrapWords
          />
        </Col>
        <Col lg={5} xs={12} className="report-col--big">
          <Totals
            title={t("variations.amount")}
            gst={GST}
            total={total}
            subtotal={subTotal}
          />
        </Col>
      </Row>

      <Row className="row--big">
        <Col lg={6} xs={12} className="report-col--big">
          <DashboardCardSignField
            signature={variation?.clientSignature}
            signatureType={EnumDigitalSignatureType.CLIENT}
            isLoading={isLoading}
          />
        </Col>
        <Col lg={6} xs={12} className="report-col--big">
          <DashboardCardSignField
            signature={variation?.builderSignature}
            signatureType={EnumDigitalSignatureType.BUILDER}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </CustomReportCard>
  );
};

export default VariationCard;
