import { TFunction } from "i18next";
import { map } from "lodash";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { CreateSelectionCategoryItemPayload } from "../../../graphql/types/models/selections";
import {
  SalesQuoteCategory,
  SalesQuoteCostItem,
} from "../../../models/salesQuote";
import CostingAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";
import { SelectOption } from "../../generic-form/inputs/creatable-select";

export const createCategoryItemField = (
  t: TFunction,
  costings: SalesQuoteCostItem[],
  costingCategories: SalesQuoteCategory[],
  onCostingCategoryChange: UpdateTextInputFn,
  costingCategoryId: string,
  handlePriceSearch: (value: string) => Promise<SelectOption[]>,
  onCostingItemNameChange: UpdateTextInputFn,
  isTemplate?: boolean
): GenericFormFields<CreateSelectionCategoryItemPayload> => {
  const costingItems = map(costings, (item) => ({
    value: item._id,
    label: item.name,
  }));

  const costingOptions = map(costingCategories, (item) => ({
    value: item._id,
    label: item.name,
  }));

  const fields: GenericFormFields<CreateSelectionCategoryItemPayload> = {
    name: {
      type: "text",
      label: t("common.name"),
      placeholder: t("common.name"),
      valueKey: "name",
      inputProps: {
        required: true,
      },
    },
    description: {
      type: "text",
      controlType: "textarea",
      label: t("common.description"),
      placeholder: t("common.description"),
      valueKey: "description",
    },
    ...(!isTemplate && {
      dueDate: {
        type: "date-picker",
        label: t("common.dueDate"),
        placeholder: t("common.dueDate"),
        valueKey: "dueDate",
      },
    }),
    ...(!isTemplate && {
      costingCategoryEntityId: {
        type: "select-autocomplete",
        label: t("selections.costingCategory"),
        placeholder: t("selections.costingCategory"),
        valueKey: "costingCategoryEntityId",
        autocompleteProps: {
          options: costingOptions,
        },
        textInputProps: {
          onValueChange: onCostingCategoryChange,
        },
      },
    }),
    ...(isTemplate && {
      costingItemEntityName: {
        type: "creatable-select",
        label: t("selections.costingName"),
        placeholder: t("selections.costingName"),
        valueKey: "costingItemEntityName",
        touched: true,
        inputProps: {
          required: true,
          debounceChangeEvent: true,
        },
        creatableProps: {
          isEditable: true,
          isClearable: true,
          autoSelectCreatedOption: true,
          asyncOptions: handlePriceSearch,
          optionComponent: CostingAutocompleteMenuOption,
          isValidNewOption: (v: string) => v.length > 0,
          noOptionsMessage: () => null,
        },
        textInputProps: {
          onValueChange: onCostingItemNameChange,
        },
      },
    }),
    ...(Boolean(costingCategoryId) &&
      !isTemplate && {
        costingItemEntityId: {
          type: "default-select",
          label: t("selections.costingItem"),
          placeholder: t("selections.costingItem"),
          valueKey: "costingItemEntityId",
          selectProps: {
            options: costingItems,
          },
        },
      }),
  };

  return fields;
};
