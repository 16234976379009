import { gql } from "@apollo/client";

export const LIST_CLIENT_REMINDERS = gql`
  query ListClientReminders($filter: ReminderFilterInput!) {
    listReminders(filter: $filter) {
      _id
      title
      description
      completed
      dueDate
      supplier {
        _id
        business_name
        contact_person
      }
      contact {
        _id
        first_name
        last_name
        email
      }
    }
  }
`;
