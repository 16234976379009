import { gql } from "@apollo/client";

export const CLIENT_GET_JOB_CHANNEL = gql`
  query clientGetJobChannel($jobId: ID!) {
    clientGetJobChannel(jobId: $jobId) {
      _id
      channelType
      messages {
        _id
        text
        is_deleted
        linkPreview {
          title
          description
          url
          imageUrl
        }
        channel {
          _id
        }
        media {
          _id
          upload_url
          url
          type
          size
        }
        sender {
          _id
          first_name
          last_name
          email
          profileImage {
            url
          }
        }
        createdAt
      }
    }
  }
`;
