import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { reverse } from "lodash";

import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";
import { CLIENT_JOB_LIST_SHARED_SITE_DIARIES } from "../../../../graphql/queries/site-diary/queries";
import { ClientSiteDiariesResponse } from "../../../../graphql/types/models/site-diary";
import { SiteDiary } from "../../../../graphql/types/models/site-diary";
import SiteDiaryList from "../../../../components/job-site-diary/entry-list";
import SiteDiaryItem from "../../../../components/job-site-diary/entry-list-item";
import CardPlaceholder from "../../../../components/dashboard/card-placeholder";
import EmptyPlaceholder from "../../../../components/empty-placeholder";

type Params = {
  id: string;
};

type ClientJobSiteDiariesProps = RouteComponentProps<Params>;

const ClientJobSiteDiaries: React.FC<ClientJobSiteDiariesProps> = ({
  match,
}) => {
  const { id: jobId } = match.params;

  const { t } = useTranslation();

  const [selectedDiary, setSelectedDiary] = useState<SiteDiary | null>(null);

  const { data: clientSiteDiariesData } = useQuery<ClientSiteDiariesResponse>(
    CLIENT_JOB_LIST_SHARED_SITE_DIARIES,
    {
      variables: {
        jobId,
      },
    }
  );

  const clientSiteDiaries = React.useMemo<SiteDiary[]>(
    () =>
      clientSiteDiariesData?.clientJobListSharedSiteDiaries?.length
        ? reverse([...clientSiteDiariesData.clientJobListSharedSiteDiaries])
        : [],
    [clientSiteDiariesData]
  );

  const handleSelect = React.useCallback(
    (siteDiary) => {
      setSelectedDiary(siteDiary);
    },
    [setSelectedDiary]
  );

  console.log(clientSiteDiaries);

  return (
    <Container fluid className="h-100">
      <Helmet title={t("navigation.jobsSection.siteDiary")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.JOBS_SECTION.SITE_DIARIES}
      />

      {clientSiteDiaries?.length ? (
        <Row className="h-100">
          <Col lg={5} xs={12}>
            <SiteDiaryList
              title={t("siteDiary.entries")}
              onSelect={handleSelect}
              entries={clientSiteDiaries}
              selectedEntry={selectedDiary}
              hideAddBtn
            />
          </Col>
          <Col lg={7} xs={12} className="mh-100">
            {selectedDiary ? (
              <SiteDiaryItem
                title={t("siteDiary.diaryEntry")}
                siteDiary={selectedDiary}
                isClientItem
              />
            ) : (
              <CardPlaceholder />
            )}
          </Col>
        </Row>
      ) : (
        <EmptyPlaceholder message={t("siteDiary.clientEmptyPlaceholder")} />
      )}
    </Container>
  );
};

export default ClientJobSiteDiaries;
