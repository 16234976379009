import { MutationUpdaterFn } from "@apollo/client";
import { concat, filter } from "lodash";
import {
  CreateUpdateVariationResponse,
  DeleteVariationResponse,
  ListVariationResponse,
} from "../../types/models/job-variation";
import { GET_VARIATION, LIST_VARIATIONS } from "./queries";

export const handleVariationAdd = (
  jobId?: string
): MutationUpdaterFn<CreateUpdateVariationResponse> => (cache, response) => {
  const createdVariation = response.data?.jobCreateUpdateVariation;

  const variationsResponse = cache.readQuery<ListVariationResponse>({
    query: LIST_VARIATIONS,
    variables: {
      jobId,
    },
  });

  const variationsList = variationsResponse?.getJobGetVariations;

  if (!createdVariation || !variationsList) {
    return;
  }

  const variationListItem = {
    ...createdVariation,
  };

  cache.writeQuery({
    query: LIST_VARIATIONS,
    variables: {
      jobId,
    },
    data: {
      getJobGetVariations: concat(variationsList, variationListItem),
    },
  });

  cache.writeQuery({
    query: GET_VARIATION,
    data: {
      getJobGetVariationById: createdVariation,
    },
    variables: {
      variationId: createdVariation._id,
      jobId,
    },
  });
};

export const handleVariationDelete = (
  jobId?: string
): MutationUpdaterFn<DeleteVariationResponse> => (cache, response) => {
  const deletedVariation = response.data?.jobDeleteVariation;

  const variationsResponse = cache.readQuery<ListVariationResponse>({
    query: LIST_VARIATIONS,
    variables: {
      jobId,
    },
  });

  const variationsList = variationsResponse?.getJobGetVariations;

  if (!deletedVariation || !variationsList) {
    return;
  }

  cache.writeQuery({
    query: LIST_VARIATIONS,
    variables: {
      jobId,
    },
    data: {
      getJobGetVariations: filter(
        variationsList,
        (item) => item._id !== deletedVariation._id
      ),
    },
  });

  cache.writeQuery({
    query: GET_VARIATION,
    data: {
      getJobGetVariationById: null,
    },
    variables: {
      variationId: deletedVariation._id,
      jobId,
    },
  });
};
