import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import SetNavigationRoute from "../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../components/dashboard/sidebar/utils/navigation-items";
import DashboardNotificationCard from "../../components/dashboard-overview/notification";
import DashboardTaskCard from "../../components/dashboard-overview/task";
import DashboardQuoteCard from "../../components/dashboard-overview/quotes";
import DashboardJobCard from "../../components/dashboard-overview/jobs";
import { connect } from "react-redux";
import { RootReducerState } from "../../redux/reducer";
import { UserPayload } from "../../graphql/types/models/auth";
import { UserRoles } from "../../models/team-member";
import "./styles.scss";

type HomeContainerProps = {
  user: UserPayload | null;
};

const HomeContainer: React.FC<HomeContainerProps> = (props) => {
  const { user } = props;

  const { t } = useTranslation();

  const isBuilderAdmin = React.useMemo(
    () => user?.role === UserRoles.builderadmin,
    [user]
  );

  return (
    <div className="home-overview">
      <Helmet title={t("navigation.dashboard")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.DASHBOARD} />
      <Row>
        <Col sm={6}>
          <DashboardQuoteCard isLocked={!isBuilderAdmin} />
          <DashboardJobCard isLocked={!isBuilderAdmin} user={user} />
        </Col>
        <Col sm={6}>
          <DashboardNotificationCard />
          <DashboardTaskCard />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps)(HomeContainer);
