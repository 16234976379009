import { gql } from "@apollo/client";
import { JOB_MESSAGE_FRAGMENT } from "./fragments";

export const JOB_MESSAGING_SUBSCRIBE_INTERNAL = gql`
  subscription OnJobMessageAdded($jobId: ID!, $channelId: ID!) {
    message: jobChannelSubscribeInternalNewMessage(
      jobId: $jobId
      channelId: $channelId
    ) {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_FRAGMENT}
`;

export const JOB_MESSAGING_SUBSCRIBE_EXTERNAL = gql`
  subscription OnJobMessageAdded($jobId: ID!, $channelId: ID!) {
    message: jobChannelSubscribeExternalNewMessage(
      jobId: $jobId
      channelId: $channelId
    ) {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_FRAGMENT}
`;

export const JOB_ALL_CHANNEL_SUBSCRIBE_NEW_MESSAGE = gql`
  subscription JobChannelSubscribeAll {
    jobChannelSubscribeAll {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_FRAGMENT}
`;
