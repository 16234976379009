import React from "react";
import { compact, map, omit } from "lodash";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment-timezone";

import {
  TodoList,
  TaskStatus,
  CreateTodoListPayload,
  TaskArchiveStatus,
  SystemUser,
} from "../../../models/task";
import {
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import TableCard, {
  EmptyTablePlaceholder,
} from "../../../components/dashboard/table-card";
import { LIST_TODO_LIST } from "../../../graphql/queries/task/queries";
import {
  UPDATE_TODO_LIST_COMPLETION,
  DELETE_TODO_LIST,
  CREATE_UPDATE_TODO_LIST,
  BULK_UPDATE_TODO_LIST,
  ARCHIVE_TODO,
} from "../../../graphql/queries/task/mutation";
import {
  handleDeleteTodoList,
  handleAddTodoList,
  handleUpdateTodoList,
  handleArchiveTodoList,
} from "../../../graphql/queries/task/utils";
import {
  ListTodoListResponse,
  UpdateTodoListCompletionResponce,
  DeleteTodoListResponse,
  CreateUpdateTodoListResponse,
  ArchiveTodoListResponse,
} from "../../../graphql/types/models/task";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import CreateUpdateTaskModal from "../../../components/task/create-update-task-modal";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { notify } from "../../../components/notification";
import { Helmet } from "react-helmet";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { UserPayload } from "../../../graphql/types/models/auth";
import { getFullName } from "../../../utils/text";
import Dropdown from "../../../components/dashboard/dropdown";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { UserRoles } from "../../../models/team-member";
import DashboardActionHeader from "../../../components/dashboard/table-card/DashboardActionHeader";
import { useDropdownJobs } from "../../../hooks/useDropdownJobs";
import { useDropdownMembers } from "../../../hooks/useDropdownMembers";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { EnumSystemUserType } from "../../../graphql/types/models/notification";
import Avatar from "../../../components/avatar";
import AvatarGroup from "../../../components/avatar-group";
import "./styles.scss";

type TasksTableProps = DashboardContextValue &
  RouteComponentProps & {
    user: UserPayload | null;
    archiveStatus: TaskArchiveStatus;
  };

type SelectedItemType = {
  _id: string;
};

enum OperationsType {
  incomplete = "Incomplete",
  complete = "Complete",
  delete = "Delete",
}

const TasksTable: React.FC<TasksTableProps> = (props) => {
  const { user, setSearchOptions, archiveStatus } = props;
  const { t } = useTranslation();
  const { currentJob, setJob, renderDropdownJobs } = useDropdownJobs();
  const { memberId, setMemberId, renderDropdownMembers } = useDropdownMembers({
    user,
  });
  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const archiveRef = React.useRef<ConfirmDialogRef>(null);
  const unarchiveRef = React.useRef<ConfirmDialogRef>(null);
  const confirmBulkTasksRef = React.useRef<ConfirmDialogRef>(null);
  const [deleteTarget, setDeleteTarget] = React.useState("");
  const [archiveTarget, setArchiveTarget] = React.useState<TodoList | null>(
    null
  );
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [completed, setCompleted] = React.useState<string | boolean>(false);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [activeOperation, setActiveOperation] = React.useState<
    OperationsType | boolean
  >(false);
  const history = useHistory();
  const [
    todoListToUpdate,
    setTodoListToUpdate,
  ] = React.useState<TodoList | null>(null);
  const [selectedItems, setSelectedItems] = React.useState<SelectedItemType[]>(
    []
  );
  const isArchived = archiveStatus === TaskArchiveStatus.ARCHIVED;

  const tasksFiltersStorageKey = "tasksTableFilters";
  const {
    storedValue: storedFilters,
    setStoredValue: setStoredFilters,
  } = useLocalStorage(tasksFiltersStorageKey, {
    currentJob,
    memberId,
    completed,
    activeOperation,
  });

  const [getTasks, { data: tasksList, loading: tasksLoading }] = useLazyQuery<
    ListTodoListResponse
  >(LIST_TODO_LIST, {
    fetchPolicy: "network-only",
  });

  const [modifySelectedTasks, { loading: operationsLoading }] = useMutation<
    ListTodoListResponse
  >(BULK_UPDATE_TODO_LIST, {
    refetchQueries: [
      {
        query: LIST_TODO_LIST,
        variables: {
          filter: {
            jobId: currentJob.id,
            userId: memberId,
            completed: completed === "" ? undefined : completed,
          },
        },
      },
    ],
  });

  const [updateTodoListCompletion] = useMutation<
    UpdateTodoListCompletionResponce
  >(UPDATE_TODO_LIST_COMPLETION);

  const [deleteTodoList, { loading: todoListDeleting }] = useMutation<
    DeleteTodoListResponse
  >(DELETE_TODO_LIST);

  const [archiveTodoList, { loading: todoListArchiving }] = useMutation<
    ArchiveTodoListResponse
  >(ARCHIVE_TODO);

  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const [
    createUpdateTodoList,
    { loading: todoListCreatingUpdating },
  ] = useMutation<CreateUpdateTodoListResponse>(CREATE_UPDATE_TODO_LIST);

  React.useEffect(() => {
    setSelectedItems([]);
  }, [memberId, currentJob, completed]);

  const loadTasks = React.useCallback(() => {
    getTasks({
      variables: {
        filter: {
          jobId: currentJob.id,
          userId: memberId,
          completed: completed === "" ? undefined : completed,
          is_archived: isArchived,
        },
      },
    });
    setStoredFilters({ currentJob, memberId, completed, activeOperation });
  }, [currentJob, memberId, completed, activeOperation, isArchived]);

  const handleSearch = React.useCallback(
    (text: string) => setSearchFilter(text.trim()),
    []
  );

  const filteredJobs = React.useMemo(
    () =>
      tasksList?.listTodoList?.filter((job) => {
        return (
          job.task?.toUpperCase().includes(searchFilter.toUpperCase()) ||
          job.description?.toUpperCase().includes(searchFilter.toUpperCase())
        );
      }),
    [tasksList?.listTodoList, searchFilter]
  );

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("tasks.searchTasks"),
      options: [],
      onAutocomplete: handleSearch,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [setSearchOptions]);

  React.useEffect(() => {
    if (storedFilters) {
      const {
        currentJob,
        activeOperation,
        memberId,
        completed,
      } = storedFilters;
      setJob(currentJob);
      setActiveOperation(activeOperation);
      setMemberId(memberId);
      setCompleted(completed);
    } else {
      setStoredFilters({ currentJob, activeOperation, memberId, completed });
    }
  }, []);

  const handleOperationsWithTasks = React.useCallback(
    async (operation: OperationsType) => {
      const showNotify = (
        operation: OperationsType,
        error: boolean = false
      ) => {
        if (!error) {
          notify({
            title: t(`tasks.${operation.toLowerCase()}Task`),
            content: t(`tasks.success.${operation.toLowerCase()}SelectedTask`),
          });
        } else {
          notify({
            error,
            title: t("tasks.operations.error"),
            content: t(`tasks.error.${operation.toLowerCase()}Task`),
          });
        }
      };
      setActiveOperation(false);

      try {
        if (selectedItems.length === 0) {
          notify({
            error: true,
            title: t("tasks.operations.error"),
            content: t("tasks.operations.choose"),
          });
          return;
        }

        await modifySelectedTasks({
          variables: {
            todoLists: selectedItems,
            action: operation,
          },
        });

        showNotify(operation);

        setSelectedItems([]);
      } catch (e) {
        showNotify(operation, true);
      }
    },
    [modifySelectedTasks, selectedItems, t]
  );

  React.useEffect(() => {
    loadTasks();
  }, [loadTasks, isArchived]);

  const toggleSelectedItem = React.useCallback(
    (row?: SelectedItemType) => {
      if (!row?._id) return;

      const newItems = [...selectedItems];
      const item = newItems.find((item) => item._id === row._id);

      if (item) {
        newItems.splice(
          newItems.findIndex((item) => item._id === row._id),
          1
        );
      } else {
        newItems.push({ _id: row._id });
      }
      setSelectedItems(newItems);
    },
    [selectedItems, setSelectedItems]
  );

  const openCreateModal = React.useCallback(() => {
    setShowCreateModal(true);
  }, [setShowCreateModal]);

  const closeCreateModal = React.useCallback(() => {
    setTodoListToUpdate(null);
    setShowCreateModal(false);
  }, [setShowCreateModal]);

  const handleEdit = React.useCallback(
    (row?: TodoList) => {
      if (row) {
        const currenTask = tasksList?.listTodoList.find(
          (todo) => todo._id === row._id
        );
        if (currenTask) {
          setTodoListToUpdate(currenTask);
        } else {
          setTodoListToUpdate(row);
        }
      }
    },
    [tasksList?.listTodoList]
  );

  const handleUpdateTodoListCompletion = React.useCallback(
    async (todoListId: string, status: boolean = true) => {
      try {
        if (!todoListId) {
          return;
        }

        await updateTodoListCompletion({
          variables: {
            todoListId,
            status,
          },
        });

        status
          ? notify({
              title: t("tasks.completeTask"),
              content: t("tasks.success.completeTask"),
            })
          : notify({
              title: t("tasks.incompleteTask"),
              content: t("tasks.success.incompleteTask"),
            });
      } catch (e) {
        status
          ? notify({
              error: true,
              title: t("tasks.completeTask"),
              content: t("tasks.error.completeTask"),
            })
          : notify({
              error: true,
              title: t("tasks.incompleteTask"),
              content: t("tasks.error.incompleteTask"),
            });
      }
    },
    [t, updateTodoListCompletion]
  );

  const handleDeleteTask = React.useCallback(async () => {
    try {
      if (!deleteTarget) {
        return;
      }

      await deleteTodoList({
        variables: {
          todoListId: deleteTarget,
        },
        update: handleDeleteTodoList(),
      });
      loadTasks();

      notify({
        title: t("tasks.deleteTask"),
        content: t("tasks.success.deleteTask"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("tasks.deleteTask"),
        content: t("tasks.error.deleteTask"),
      });
    }
  }, [t, deleteTodoList, deleteTarget]);

  const handleArchiveTask = React.useCallback(async () => {
    const currentArchiveStatus = archiveTarget?.is_archived;
    try {
      if (!archiveTarget) {
        return;
      }

      await archiveTodoList({
        variables: {
          todoListId: archiveTarget._id,
          isArchived: !archiveTarget.is_archived,
        },
        update: handleArchiveTodoList(archiveTarget._id),
      });
      loadTasks();

      notify({
        title: currentArchiveStatus
          ? t("tasks.unarchiveTask")
          : t("tasks.archiveTask"),
        content: currentArchiveStatus
          ? t("tasks.success.unarchiveTask")
          : t("tasks.success.archiveTask"),
      });
    } catch (e) {
      notify({
        error: true,
        title: currentArchiveStatus
          ? t("tasks.unarchiveTask")
          : t("tasks.archiveTask"),
        content: currentArchiveStatus
          ? t("tasks.error.unarchiveTask")
          : t("tasks.error.archiveTask"),
      });
    }
  }, [t, deleteTodoList, archiveTarget]);

  const handleAddTask = React.useCallback(
    async (data: CreateTodoListPayload, createAnother: boolean) => {
      try {
        if (!data) {
          return;
        }

        const result = await createUpdateTodoList({
          variables: {
            todoList: {
              ...omit(data, ["members"]),
              ...(Boolean(data?.members) && {
                members: data?.members?.map((member) => ({
                  memberId: member,
                  memberType: EnumSystemUserType.Builder,
                })),
              }),
              timezone: moment.tz.guess(),
            },
          },
          update: handleAddTodoList(),
        });
        loadTasks();

        notify({
          title: t("tasks.addTask"),
          content: t("tasks.success.addTask"),
        });

        if (!createAnother) {
          history.push(`/tasks/${result.data?.createUpdateTodoList._id}`);
        }
      } catch (e) {
        notify({
          error: true,
          title: t("tasks.addTask"),
          content: t("tasks.error.addTask"),
        });
      }
      if (!createAnother) closeCreateModal();
    },
    [t, closeCreateModal, createUpdateTodoList, loadTasks]
  );

  const handleUpdateTask = React.useCallback(
    async (data: CreateTodoListPayload) => {
      try {
        if (!data || !todoListToUpdate) {
          return;
        }

        await createUpdateTodoList({
          variables: {
            todoList: {
              _id: todoListToUpdate._id,
              ...omit(data, ["members"]),
              ...(data?.members && {
                members: data.members.map((member) => ({
                  memberId: member,
                  memberType: EnumSystemUserType.Builder,
                })),
              }),
              timezone: moment.tz.guess(),
            },
          },
          update: handleUpdateTodoList(todoListToUpdate._id),
        });

        loadTasks();

        notify({
          title: t("tasks.updateTask"),
          content: t("tasks.success.updateTask"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("tasks.updateTask"),
          content: t("tasks.error.updateTask"),
        });
      }
      closeCreateModal();
    },
    [t, closeCreateModal, createUpdateTodoList, todoListToUpdate, loadTasks]
  );

  const openRemoveDialog = React.useCallback(
    (row?: TodoList) => {
      if (row) {
        setDeleteTarget(row._id);
        confirmRef?.current?.show(true);
      }
    },
    [confirmRef]
  );

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget("");
    confirmRef?.current?.show(false);
  }, [confirmRef]);

  const openArchiveDialog = React.useCallback(
    (row?: TodoList) => {
      if (row) {
        setArchiveTarget(row);
        archiveRef?.current?.show(true);
      }
    },
    [archiveRef]
  );

  const openUnarchiveDialog = React.useCallback(
    (row?: TodoList) => {
      if (row) {
        setArchiveTarget(row);
        unarchiveRef?.current?.show(true);
      }
    },
    [unarchiveRef]
  );

  const closeArchiveDialog = React.useCallback(() => {
    setArchiveTarget(null);
    archiveRef?.current?.show(false);
  }, [archiveRef]);

  const closeUnarchiveDialog = React.useCallback(() => {
    setArchiveTarget(null);
    unarchiveRef?.current?.show(false);
  }, [unarchiveRef]);

  const openRemoveSelectedTasksDialog = React.useCallback(() => {
    if (selectedItems.length > 0) {
      confirmBulkTasksRef?.current?.show(true);
    }
  }, [confirmBulkTasksRef, selectedItems]);

  const handleSubmitRemoveSelectedTasks = React.useCallback(() => {
    handleOperationsWithTasks(OperationsType.delete);
  }, [handleOperationsWithTasks]);

  const closeRemoveSelectedTasksDialog = React.useCallback(() => {
    confirmBulkTasksRef?.current?.show(false);
  }, [confirmBulkTasksRef]);

  const handleUpdateStatus = React.useCallback(
    (row?: TodoList) => {
      if (row) {
        handleUpdateTodoListCompletion(row._id, !row.completed);
      }
    },
    [handleUpdateTodoListCompletion]
  );

  const completedOptions = React.useMemo(
    () => [
      {
        id: "all",
        label: t("tasks.allStatuses"),
        onClick: () => setCompleted(""),
      },
      {
        id: "incomplete",
        label: t("tasks.incomplete"),
        onClick: () => setCompleted(false),
      },
      {
        id: "complete",
        label: t("tasks.complete"),
        onClick: () => setCompleted(true),
      },
    ],
    []
  );

  const operationOptions = React.useMemo(
    () => [
      {
        id: "incomplete",
        label: t("tasks.operations.incomplete"),
        onClick: () => handleOperationsWithTasks(OperationsType.incomplete),
      },
      {
        id: "complete",
        label: t("tasks.operations.complete"),
        onClick: () => handleOperationsWithTasks(OperationsType.complete),
      },
      {
        id: "delete",
        label: t("tasks.operations.delete"),
        onClick: () => openRemoveSelectedTasksDialog(),
      },
    ],
    [selectedItems]
  );

  const handleRelationClick = React.useCallback(
    (
      row: any,
      column: any,
      value: string,
      e?: React.MouseEvent<HTMLTableCellElement>
    ) => {
      if (e) {
        e.stopPropagation();
      }
    },
    []
  );

  const renderAvatars = (members: SystemUser[]) => {
    return members.map((user) => {
      return (
        <Avatar
          key={user._id}
          outlined
          fallbackColor="light"
          userName={getFullName(user)}
          rounded
          width="35px"
          height="35px"
          url={user.profileImage?.url}
        />
      );
    });
  };

  const renderRelationCell = React.useCallback((row: any) => {
    const {
      cells: { job, lead, scheduleItem },
    } = row;

    return (
      <div className="relationCell">
        {job && (
          <span
            onClick={() => history.push(`/jobs/${job?._id}`)}
            className="badge badge-info"
          >{`Job: ${job?.name}`}</span>
        )}
        {lead && (
          <span
            onClick={() => history.push(`/leads/${lead?._id}`)}
            className="badge badge-warning"
          >{`Lead: ${lead?.name}`}</span>
        )}
        {scheduleItem && (
          <span
            onClick={() =>
              history.push(`/jobs/${scheduleItem?.job?._id}/schedule`)
            }
            className="badge badge-success"
          >{`Schedule: ${scheduleItem?.name}`}</span>
        )}
      </div>
    );
  }, []);

  const documentsTableData = React.useMemo(() => {
    return {
      columns: compact([
        {
          valueKey: "task",
          title: t("tasks.task"),
          sortable: true,
        },
        // {
        //   valueKey: "description",
        //   title: t("tasks.description"),
        //   sortable: true,
        // },
        {
          valueKey: "dueDate",
          title: t("tasks.dueDate"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
        {
          valueKey: "completed",
          title: t("tasks.status"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => {
            const {
              cells: { dueDate },
            } = row;
            const overDue = moment().isAfter(moment(dueDate).endOf("day"));
            return (
              <span
                className={
                  value
                    ? "badge badge-success"
                    : overDue
                    ? "badge badge-danger"
                    : "badge badge-secondary"
                }
              >
                {value
                  ? TaskStatus.COMPLETE
                  : overDue
                  ? TaskStatus.OVERDUE
                  : TaskStatus.INCOMPLETE}
              </span>
            );
          },
        },
        {
          valueKey: "progress",
          title: t("placeholders.progress"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => `${value}%`,
        },
        user?.role === UserRoles.builderadmin ||
        user?.role === UserRoles.manager
          ? {
              valueKey: "members",
              title: t("tasks.assignedTo"),
              sortable: true,
              formatValue: (row: any, column: any, value: SystemUser[]) => (
                <AvatarGroup>{renderAvatars(value)}</AvatarGroup>
              ),
            }
          : null,
        {
          valueKey: "Relation",
          title: t("tasks.relation"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            renderRelationCell(row),
          onClick: handleRelationClick,
        },
      ]),
      rows: map(filteredJobs, (item) => ({
        cells: {
          ...item,
        },
      })),
    };
  }, [t, user, filteredJobs]);

  const tableRowActions: TableRowActionData<TodoList>[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "tasks-list",
        options: [
          {
            icon: "delete",
            outlined: true,
            id: "edit",
            label: t("tasks.editTask"),
            onClick: handleEdit,
            shouldRender: (row: TodoList) => !row.jobScheduleItem,
          },
          {
            icon: "check_circle",
            outlined: true,
            id: "complete",
            label: t("tasks.completeTask"),
            onClick: handleUpdateStatus,
            shouldRender: (row: TodoList) => !row.completed && !isArchived,
          },
          {
            icon: "unpublished",
            outlined: true,
            id: "incomplete",
            label: t("tasks.incompleteTask"),
            onClick: handleUpdateStatus,
            shouldRender: (row: TodoList) => row.completed && !isArchived,
          },
          {
            icon: "archive",
            outlined: true,
            id: "archive",
            label: t("tasks.operations.archive"),
            onClick: openArchiveDialog,
            shouldRender: (row: TodoList) => !Boolean(row.is_archived),
          },
          {
            icon: "unarchive",
            outlined: true,
            id: "unarchive",
            label: t("tasks.operations.unarchive"),
            onClick: openUnarchiveDialog,
            shouldRender: (row: TodoList) => Boolean(row.is_archived),
          },
          {
            icon: "delete",
            outlined: true,
            id: "delete",
            label: t("tasks.deleteTask"),
            onClick: openRemoveDialog,
          },
        ],
      },
    ],

    [t, openRemoveDialog, handleUpdateStatus, handleEdit, isArchived]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: isArchived
        ? t("tasks.emptyArchivedPlaceholder")
        : completed
        ? t("tasks.emptyTasksFilterPlaceholder")
        : t("tasks.emptyTasksPlaceholder"),
      buttonText: t("tasks.addTask"),
      onPress: !isArchived ? openCreateModal : undefined,
    }),
    [t, openCreateModal, completed, isArchived]
  );

  const completedLabel = React.useMemo(() => {
    if (completed === "") return t("tasks.allStatuses");
    return completed ? t("tasks.complete") : t("tasks.incomplete");
  }, [completed]);

  const operationsLabel = React.useMemo(() => {
    switch (activeOperation) {
      case OperationsType.delete:
        return t("tasks.operations.delete");
      case OperationsType.incomplete:
        return t("tasks.operations.incomplete");
      case OperationsType.complete:
        return t("tasks.operations.complete");
      default:
        return t("tasks.operations.placeholder");
    }
  }, [activeOperation]);

  const renderStatusFilter = React.useCallback(() => {
    return (
      <div className="dashboard-dropdown-filter">
        <Dropdown
          label={completedLabel}
          icon="expand_more"
          id="filter-complete"
          items={completedOptions}
        />
      </div>
    );
  }, [completed, completedLabel, completedOptions]);

  const renderOperationsDropdown = React.useCallback(() => {
    if (!selectedItems.length) return null;
    return (
      <div className="dashboard-dropdown-filter">
        <Dropdown
          label={operationsLabel}
          icon="expand_more"
          id="filter-operations"
          items={operationOptions}
        />
      </div>
    );
  }, [operationsLabel, operationOptions, selectedItems]);

  const filters = React.useMemo(
    () => [
      renderDropdownMembers(),
      renderDropdownJobs(),
      renderStatusFilter(),
      renderOperationsDropdown(),
    ],
    [
      renderDropdownJobs,
      renderDropdownMembers,
      renderOperationsDropdown,
      renderStatusFilter,
    ]
  );

  const tableLeftRowActions: TableRowActionData<
    SelectedItemType
  >[] = React.useMemo(
    () => [
      {
        icon: "check_box",
        dropdownId: "document",
        onClick: toggleSelectedItem,
        shouldRender: (row) =>
          selectedItems.find((item) => item._id === row._id),
      },
      {
        icon: "check_box_outline_blank",
        dropdownId: "document",
        onClick: toggleSelectedItem,
        shouldRender: (row) =>
          !selectedItems.find((item) => item._id === row._id),
      },
    ],
    [selectedItems, toggleSelectedItem]
  );

  const handleClientTableRowClick = React.useCallback(
    (row: TableCardDataRow<TodoList>) => {
      history.push(`/tasks/${row.cells._id}`);
    },
    [history]
  );

  return (
    <Container fluid className="tasks-table-container">
      <Helmet title={t("navigation.tasks")} />
      <SetNavigationRoute
        routeId={
          isArchived
            ? NAVIGATION_ROUTES.TASKS_SECTION.ARCHIVED_TASKS
            : NAVIGATION_ROUTES.TASKS
        }
      />

      <CreateUpdateTaskModal
        canAssign={user?.role !== UserRoles.basic}
        data={todoListToUpdate}
        user={user}
        show={!!todoListToUpdate || showCreateModal}
        onClose={closeCreateModal}
        onSubmit={todoListToUpdate ? handleUpdateTask : handleAddTask}
      />

      <ConfirmDialog
        disabled={todoListDeleting}
        ref={confirmRef}
        onClose={closeRemoveDialog}
        onSubmit={handleDeleteTask}
        title={t("tasks.deleteTask")}
        confirm={t("common.delete")}
      >
        <div className="field-text">{t("tasks.removeMessage")}</div>
      </ConfirmDialog>

      <ConfirmDialog
        disabled={todoListArchiving}
        ref={archiveRef}
        onClose={closeArchiveDialog}
        onSubmit={handleArchiveTask}
        title={t("tasks.archiveTask")}
        confirm={t("tasks.operations.archive")}
      >
        <div className="field-text">{t("tasks.archiveMessage")}</div>
      </ConfirmDialog>

      <ConfirmDialog
        disabled={todoListArchiving}
        ref={unarchiveRef}
        onClose={closeUnarchiveDialog}
        onSubmit={handleArchiveTask}
        title={t("tasks.unarchiveTask")}
        confirm={t("tasks.operations.unarchive")}
      >
        <div className="field-text">{t("tasks.unarchiveTaskMessage")}</div>
      </ConfirmDialog>

      <ConfirmDialog
        disabled={operationsLoading}
        ref={confirmBulkTasksRef}
        onClose={closeRemoveSelectedTasksDialog}
        onSubmit={handleSubmitRemoveSelectedTasks}
        title={t("tasks.deleteSelectedTask")}
        confirm={t("common.delete")}
      >
        <div className="field-text">{t("tasks.removeSelectedMessage")}</div>
      </ConfirmDialog>
      <DashboardActionHeader
        onActionButton={!isArchived ? openCreateModal : undefined}
        actionButtonTitle={t("tasks.addTask")}
        disabledActionButton={tasksLoading}
      >
        {filters.map((filter, index) => (
          <div className="pr-2 pb-4" key={index}>
            {filter}
          </div>
        ))}
      </DashboardActionHeader>
      <TableCard
        tableId="tasks"
        className="overflow-visible"
        isDataLoading={tasksLoading || todoListCreatingUpdating}
        data={documentsTableData}
        alignEnd={true}
        rowActions={tableRowActions}
        emptyPlaceholder={emptyPlaceholder}
        leftRowActions={!isArchived ? tableLeftRowActions : undefined}
        onRowClick={handleClientTableRowClick}
      />
    </Container>
  );
};

export default withRouter(withDashboardContext(TasksTable));
