import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../../components/icons/Icon";
import { QuoteStatus } from "../../../../models/salesQuote";
import { getQuoteStatusOptions } from "../../../../utils/options";
import "./style.scss";

type EstimationStatusProps = {
  status: string;
};

const EstimationStatus: FC<EstimationStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const statusOptions = getQuoteStatusOptions(t);
  const currentStatus = statusOptions.find((item) => item.value === status);

  const renderStatus = (status: string = "") => {
    const getIconName = (status: string) => {
      if (status === QuoteStatus.DRAFT) return "pending_actions";
      if (status === QuoteStatus.APPROVED) return "check_circle";
      if (status === QuoteStatus.SENT) return "hourglass_bottom";
    };

    return (
      <div className={`inner-container ${status.toLowerCase()}-color`}>
        <Icon
          className={`estimation-status-container ${status.toLowerCase()}-color`}
          name={getIconName(status.toUpperCase()) as string}
          outlined
        />
        <div>{status}</div>
      </div>
    );
  };

  return (
    <div className="estimation-status-container">
      {renderStatus(currentStatus?.label)}
    </div>
  );
};

export default EstimationStatus;
