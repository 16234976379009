import React from "react";
import moment from "moment";
import "./styles.scss";
import classNames from "classnames";
import Avatar from "../../avatar";
import Markdown from "../../markdown";
import { toNumber } from "lodash";

type ConversationItemProps = {
  name: string;
  isSelected?: boolean;
  id: string;
  logo?: string;
  icon?: string;
  messageText?: string;
  messageDate?: string;
  messageOwner?: string;
  messageSubText?: string;
  onSelect?: (id: string) => void;
};

export const getMessageTime = (date: string): string => {
  let rawDate: string | number = date;
  const timestamp = toNumber(rawDate);

  if (!isNaN(timestamp)) {
    rawDate = timestamp;
  }

  const time = moment(rawDate);

  if (moment().isSame(rawDate, "day")) {
    return time.format("h:mma");
  }

  return time.format("Do MMM");
};

const ConversationItem: React.FC<ConversationItemProps> = ({
  id,
  onSelect,
  isSelected,
  name,
  messageText,
  messageDate,
  messageOwner,
  messageSubText,
  logo,
  icon,
}) => {
  const containerClasses = classNames(
    {
      "conversation-item--selected": isSelected,
    },
    "conversation-item"
  );

  const handleSelect = React.useCallback(() => {
    onSelect && onSelect(id);
  }, [onSelect, id]);

  const messageSender = messageOwner ? `${messageOwner}: ` : "";

  return (
    <div className={containerClasses} onClick={handleSelect}>
      <div className="conversation-content">
        <div className="conversation-avatar">
          <Avatar
            height="60px"
            width="60px"
            url={logo}
            iconSize="35px"
            icon={icon}
          />
        </div>
        <div className="conversation-info">
          <div className="conversation-title">
            <div className="conversation-name field-text">{name}</div>
            {messageDate && (
              <div className="field-text">{getMessageTime(messageDate)}</div>
            )}
          </div>
          {messageText && (
            <div className="field-text conversation-message">
              <Markdown source={`${messageSender}${messageText}`} />
            </div>
          )}
          {messageSubText && (
            <div className="field-text text-truncate">{messageSubText}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConversationItem;
