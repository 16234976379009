import React from "react";
import { Col, Row } from "react-bootstrap";
import { WithT } from "i18next";

type SignUpConfirmFooterProps = WithT & {
  onResendClick: (e: any) => void;
};

const SignUpConfirmFooter: React.FC<SignUpConfirmFooterProps> = ({
  t,
  onResendClick,
}) => {
  return (
    <Row className="w-100 m-0">
      <Col lg={12} xs={12} className="d-flex p-0">
        <div className="d-flex p-0 align-items-center">
          <a href="#" onClick={onResendClick} className="sign-up-button">
            {t("authentication.resendActivation")}
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default SignUpConfirmFooter;
