import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { connect } from "react-redux";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import JobDetails from "../../../components/job-overview/job-details";
import ContactDetails from "../../../components/job-overview/contact-details";
import ProfitDetails from "../../../components/job-overview/profit-details";
import Schedule from "../../../components/job-overview/schedule";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { GET_JOB_DETAILS } from "../../../graphql/queries/job/queries";
import { GetJobOverviewResponse } from "../../../graphql/types/models/job";
import JobLocationHeader from "../../header/job-location-header";
import ClientHeaderList from "../../header/client-header-list";
import CreateJobModal from "../../../components/job/create-job-modal";
import { ListScheduleItemsResponse } from "../../../graphql/types/models/job-schedule";
import { LIST_SCHEDULE_ITEMS } from "../../../graphql/queries/job-schedule/queries";
import TeammateHeaderList from "../../header/teammate-header-list";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useJobTotalsQuery } from "../../../hooks/queries/useJobTotalsQuery";
import { useNote } from "../../../hooks/useNote";
import { NoteEntityType } from "../../../graphql/types/models/note";
import { useChecklists } from "../../../hooks/useChecklists";
import { EnumChecklistEntityType } from "../../../models/checklists";
import { EnumChecklistTemplateType } from "../../../models/task";
import { useGrantJobContact } from "../../../hooks/useGrantJobContact";
import "./styles.scss";

type JobOverviewProps = DashboardContextValue & {
  user: UserPayload | null;
};

const JobOverview: React.FC<JobOverviewProps> = ({
  navigationContext,
  user,
}) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const jobId = navigationContext?.job?._id || "";
  const jobName = navigationContext?.job?.name;
  const { t } = useTranslation();
  const createRef = React.useRef<ModalDisplayRef>(null);

  const range = React.useMemo(
    () => ({
      from: moment().utc().startOf("week").toISOString(),
      to: moment().utc().add(1, "day").endOf("week").toISOString(),
    }),
    []
  );

  const isReadonly = React.useMemo(() => user?.role === UserRoles.basic, [
    user,
  ]);

  const { jobTotals, jobTotalsLoading } = useJobTotalsQuery(jobId);
  const { renderNotesCard } = useNote(NoteEntityType.Job, jobId);
  const { renderChecklists } = useChecklists({
    entityId: jobId,
    entityType: EnumChecklistEntityType.Job,
    checklistTemplateType: EnumChecklistTemplateType.Job,
  });

  const {
    data: jobDetails,
    loading: jobDetailsLoading,
    error: jobError,
  } = useQuery<GetJobOverviewResponse>(GET_JOB_DETAILS, {
    variables: {
      jobId,
    },
  });

  const { data: jobSchedule, loading: jobScheduleLoading } = useQuery<
    ListScheduleItemsResponse
  >(LIST_SCHEDULE_ITEMS, {
    variables: {
      jobId,
      startRange: range,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    renderConfirmGrantDialogs,
    contacts: jobContacts,
    jobContactsLoading,
    revokeAccessConfirm,
    grantAccessConfirm,
  } = useGrantJobContact(jobId);

  const job = jobDetails?.getJob;
  const customer = job?.customer;

  const handleEdit = React.useCallback(() => {
    createRef.current?.show(true);
  }, [createRef]);

  const handleActionContactButton = React.useCallback(
    (contactId: string, accessId?: string) => {
      if (accessId) {
        return revokeAccessConfirm(accessId, contactId);
      }
      return grantAccessConfirm(contactId);
    },
    [grantAccessConfirm, revokeAccessConfirm]
  );

  const renderJobDetails = React.useCallback(() => {
    return (
      <Row>
        <Col lg={4} xs={12}>
          <div className="card-wrapper">
            {job && (
              <JobDetails
                job={job}
                disabled={!job || jobDetailsLoading}
                onEdit={handleEdit}
                buttonDisabled={isLocked || isReadonly}
                isReadonly={isReadonly}
              />
            )}
          </div>
          <div className="card-wrapper">
            {renderConfirmGrantDialogs()}
            <ContactDetails
              disabled={jobDetailsLoading || jobContactsLoading}
              primary={customer}
              jobContacts={jobContacts?.jobContacts}
              onContactActionButton={handleActionContactButton}
            />
          </div>
          {/* <div className="card-wrapper">
            <Claims
              loading={dashboardLoading}
              claims={dashboard?.progress_claims}
            />
          </div> */}
        </Col>
        <Col lg={8} xs={12}>
          {!isReadonly && (
            <div className="card-wrapper">
              <ProfitDetails
                job={job}
                loading={jobTotalsLoading}
                amounts={jobTotals}
              />
            </div>
          )}
          <div className="card-wrapper">{renderNotesCard()}</div>
          <div className="card-wrapper">{renderChecklists()}</div>
          <div className="card-wrapper">
            <Schedule
              loading={jobScheduleLoading}
              scheduleItems={jobSchedule?.listScheduleItems}
            />
          </div>
        </Col>
      </Row>
    );
  }, [
    job,
    jobDetailsLoading,
    handleEdit,
    isLocked,
    isReadonly,
    jobContactsLoading,
    customer,
    handleActionContactButton,
    jobTotalsLoading,
    jobTotals,
    jobScheduleLoading,
    jobSchedule,
    renderChecklists,
    renderNotesCard,
  ]);

  return (
    <Container fluid className="m-0 p-0 h-100 job-overview">
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.OVERVIEW} />
      <Helmet title={t("navigation.jobsSection.overview")} />

      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <CreateJobModal ref={createRef} job={jobDetails?.getJob} />
      {renderJobDetails()}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(JobOverview));
