import { gql } from "@apollo/client";
import { JOB_MESSAGE_FRAGMENT } from "../job-messaging/fragments";

export const CLIENT_SUBSCRIBE_NEW_MESSAGE = gql`
  subscription OnJobMessageAdded($channelId: ID!) {
    message: clientSubscribeNewMessage(channelId: $channelId) {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_FRAGMENT}
`;
