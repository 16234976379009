import {
  AppendDynamicPropsFn,
  AppendOnClickFn,
  FormikPropGetSetValues,
  GenericFormFields,
  UpdateRowFn,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { TFunction } from "i18next";
import {
  PurchaseOrderPayload,
  PurchaseOrderStatus,
} from "../../../models/purchaseOrder";
import { Rounding } from "../../calculator/utils";
import { SupplierListItem } from "../../../models/supplier";
import { chain, compact, map } from "lodash";
import { VariationCostItemPayload } from "../../../models/variations";
import { calcItemTotal } from "../../../utils/calculations";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import { PriceListItem } from "../../../models/price-list";
import CostingAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";

import { UOMOption, GenericRecord } from "../../../utils/types/options";
import { SearchJobCostingItem } from "../../../models/job";

const createOrderFields = (
  t: TFunction,
  openCalculator: (filed: string) => void,
  openAllocateCosting: AppendOnClickFn,
  suppliers: SupplierListItem[] | undefined,
  openSupplierModal: () => void,
  uoms: UOMOption[],
  costingCategoryList: GenericRecord[] | undefined,
  hideCost: boolean,
  costsHaveGST: boolean,
  handleHideCostChange: (value: boolean) => void,
  handleCostsHaveGST: (
    value: boolean,
    formikProps: FormikPropGetSetValues
  ) => void,
  handleCostChange: (key: string) => UpdateTextInputFn,
  handleUOMCreate: (uom: string) => void,
  handleCostingCreate: (costing: string) => void,
  handleCostingSearch: (value: string) => Promise<SearchJobCostingItem[]>,
  handlePriceSearch: (value: string) => Promise<PriceListItem[]>,
  priceItems: SelectOption[],
  handlePriceSelect: UpdateRowFn,
  handleCategorySelect: UpdateRowFn,
  getAllocateProps: AppendDynamicPropsFn,
  handleStatusSelect: (status: string) => void,
  activeStatus: PurchaseOrderStatus | string,
  editMode?: boolean
): GenericFormFields<PurchaseOrderPayload> => {
  const costingOptions = async (value: string) => {
    if (!value) return [];

    const costings = await handleCostingSearch(value.toLowerCase());
    const costingItems = map(
      costings,
      (costingItem) =>
        ({
          label: costingItem.name,
          value: `costing:${costingItem._id}`,
          hidden: false,
          data: costingItem,
        } as SelectOption)
    );
    const prices = await handlePriceSearch(value);

    const priceItems = map(
      prices,
      (priceItem) =>
        ({
          label: priceItem.name,
          value: `price:${priceItem._id}`,
          hidden: false,
          data: priceItem,
        } as SelectOption)
    );
    return costingItems.concat(priceItems);
  };

  const statuses = chain(PurchaseOrderStatus)
    .filter((status) => {
      return (
        !editMode ||
        (status !== PurchaseOrderStatus.RECEIVED &&
          status !== PurchaseOrderStatus.PARTIALLY_RECEIVED)
      );
    })
    .map((status) => ({
      value: status,
      label: t(`orders.statuses.${status}`),
    }))
    .value();

  return {
    status: {
      type: "default-select",
      label: t("variations.status"),
      placeholder: t("placeholders.status"),
      valueKey: "status",
      selectProps: {
        options: statuses,
      },
      inputProps: {
        type: "text",
        required: true,
      },
      textInputProps: {
        onValueChange: handleStatusSelect,
      },
    },
    supplier: {
      type: "select",
      label: t("orders.contactName"),
      placeholder: t("placeholders.contactName"),
      valueKey: "supplier",
      autocompleteProps: {
        options: map(suppliers, (supplier) => ({
          value: supplier._id,
          label: supplier.business_name,
        })),
      },
      inputProps: {
        type: "text",
        required: true,
      },
      append: {
        onClick: openSupplierModal,
        icon: "add",
      },
    },
    attention: {
      type: "text",
      label: t("orders.attention"),
      placeholder: t("placeholders.attention"),
      valueKey: "attention",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    reference: {
      type: "text",
      label: t("orders.reference"),
      placeholder: t("placeholders.reference"),
      valueKey: "reference",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    date: {
      type: "date-picker",
      label: t("orders.orderDate"),
      placeholder: t("placeholders.orderDate"),
      valueKey: "date",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    ...(activeStatus !== PurchaseOrderStatus.RECEIVED &&
    activeStatus !== PurchaseOrderStatus.PARTIALLY_RECEIVED
      ? {
          deliveryDate: {
            type: "date-picker",
            label: t("orders.deliveryDate"),
            placeholder: t("placeholders.deliveryDate"),
            valueKey: "deliveryDate",
            inputProps: {
              type: "text",
              required: true,
            },
          },
        }
      : {
          dateReceived: {
            type: "date-picker",
            label: t("orders.receiveDate"),
            placeholder: t("placeholders.receiveDate"),
            valueKey: "dateReceived",
            inputProps: {
              type: "text",
              required: true,
            },
          },
        }),
    ...(activeStatus === PurchaseOrderStatus.RECEIVED ||
    activeStatus === PurchaseOrderStatus.PARTIALLY_RECEIVED
      ? {
          dueDate: {
            type: "date-picker",
            label: t("common.dueDate"),
            placeholder: t("common.dueDate"),
            valueKey: "dueDate",
            inputProps: {
              type: "text",
            },
          },
        }
      : {}),
    contactNumber: {
      type: "text",
      label: t("orders.contactNumber"),
      placeholder: t("placeholders.contactNumber"),
      valueKey: "contactNumber",
      showError: true,
      inputProps: {
        type: "text",
        required: true,
      },
    },
    deliveryAddress: {
      type: "place-autocomplete",
      label: t("orders.address"),
      placeholder: t("placeholders.address"),
      valueKey: "deliveryAddress",
      inputProps: {
        type: "text",
        required: true,
      },
    },

    deliveryInstructions: {
      type: "text",
      label: t("orders.instruction"),
      placeholder: t("placeholders.instruction"),
      valueKey: "deliveryInstructions",
      controlType: "textarea",
      inputProps: {
        rows: 12,
        type: "text",
        required: true,
      },
    },
    items: {
      tableTitle: t("costing.costingItems"),
      isCompact: true,
      toggles: [
        {
          name: "hideCost",
          label: t("orders.hideCost"),
          onChange: handleHideCostChange,
          value: hideCost,
        },
        {
          name: "costsHaveGST",
          label: t("costing.costsHaveGST"),
          onChange: handleCostsHaveGST,
        },
      ],
      rowGenerator: () => ({
        name: "",
        quantity: null,
        UOM: "qty",
        cost: 0,
        cost_inc: 0,
        rounding: Rounding.NONE,
        wastage: 0,
      }),
      row: compact([
        {
          width: "250px",
          type: "creatable-select",
          label: t("common.description"),
          placeholder: t("placeholders.description"),
          valueKey: "name",
          updateTableRow: handlePriceSelect,

          inputProps: {
            type: "text",
            className: "form-input-compact",
            required: true,
          },

          creatableProps: {
            autoSelectCreatedOption: true,
            isEditable: true,
            isClearable: true,
            options: priceItems,
            asyncOptions: costingOptions,
            optionComponent: CostingAutocompleteMenuOption,
            isValidNewOption: (v: string) => v.length > 0,
            noOptionsMessage: () => null,
          },
        },
        {
          width: "125px",
          type: "text",
          label: t("costing.quantity"),
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          disallowNegativeNumber: true,
          inputProps: {
            type: "number",
            className: "form-input-compact",
            required: true,
          },
          append: {
            onClick: openCalculator,
            icon: "calculate",
            className: "input-group-append-compact",
          },
        },
        {
          width: "100px",
          type: "creatable-select",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            className: "form-input-compact",
            required: true,
          },
        },
        !hideCost
          ? !costsHaveGST
            ? {
                width: "100px",
                type: "text",
                label: t("costing.cost"),
                placeholder: t("placeholders.costValue"),
                valueKey: "cost",
                inputProps: {
                  type: "number",
                  className: "form-input-compact",
                  required: true,
                },
                textInputProps: {
                  onValueChange: handleCostChange("cost"),
                },
              }
            : {
                width: "100px",
                type: "text",
                label: t("costing.costInc"),
                placeholder: t("placeholders.costValue"),
                valueKey: "cost_inc",
                inputProps: {
                  type: "number",
                  className: "form-input-compact",
                  required: true,
                },
                textInputProps: {
                  onValueChange: handleCostChange("cost_inc"),
                },
              }
          : null,
        !hideCost
          ? {
              width: "100px",
              maxWidth: "100px",
              type: "toggle",
              valueKey: "gstFree",
              label: t("costing.gstFree"),
              toggleProps: {
                label: "",
              },
            }
          : null,
        {
          width: "150px",
          type: "creatable-select",
          label: t("costing.costingCategory"),
          placeholder: t("placeholders.category"),
          valueKey: "costingCategoryName",
          updateTableRow: handleCategorySelect,
          creatableProps: {
            autoSelectCreatedOption: true,
            options: costingCategoryList,
            onCreateOption: handleCostingCreate,
            isValidNewOption: (v: string) => v.length > 0,
            isClearable: true,
          },
          inputProps: {
            type: "text",
            className: "form-input-compact",
            required: true,
          },
          append: {
            onClick: openAllocateCosting,
            className: "input-group-append-compact",
            dynamicProps: getAllocateProps,
          },
        },
      ]),
      dynamicFields: [
        {
          title: costsHaveGST ? t("costing.totalInc") : t("costing.totalEx"),
          formatValue: (values: VariationCostItemPayload) => {
            const amount = calcItemTotal(
              {
                margin_amount: Number(values?.margin_amount) || 0,
                cost:
                  Number(
                    costsHaveGST && !values.gstFree
                      ? values.cost_inc
                      : values.cost
                  ) || 0,
                quantity: values?.quantity || 0,
              },
              0
            );

            return t("common.currency", { amount });
          },
        },
      ],
    },
    internalNote: {
      type: "text",
      controlType: "textarea",
      label: t("common.internalNote"),
      placeholder: t("placeholders.notes"),
      valueKey: "internalNote",
      inputProps: {
        required: true,
      },
    },
  };
};

export default createOrderFields;
