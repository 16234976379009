import React from "react";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import { useTranslation } from "react-i18next";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import { Button } from "react-bootstrap";
import "./styles.scss";
import CheckList from "../../check-list";
import { IMPORT_CHECKLIST } from "./constants";
import FileInput, {
  FILE_RESTRICTION_SPREADSHEET,
} from "../../uploaders/file-input";
import ButtonSelectInput from "../../generic-form/inputs/button-select";
import {
  getImportModeOptions,
  ContactImportModes,
} from "../../../utils/options";

type ContactsImport = {
  onFileSelect: (file: File[]) => void;
  importMode: ContactImportModes;
  onModeChange: (mode: ContactImportModes) => void;
  onCancel: () => void;
  fileName?: string;
};

const ContactsImport: React.FC<ContactsImport> = (props) => {
  const { t } = useTranslation();
  const { onCancel, onFileSelect, importMode, onModeChange, fileName } = props;

  const importModesOptions = React.useMemo(() => {
    return getImportModeOptions(t);
  }, []);

  const handleModeChange = React.useCallback(
    (value: string) => {
      onModeChange && onModeChange(value as ContactImportModes);
    },
    [onModeChange]
  );

  const renderLabel = (label: string) => {
    return (
      <div className="field-text form-input-label font-weight-bold">
        {label}
      </div>
    );
  };

  return (
    <DashboardCard className="contact-import">
      <DashboardCardHeader>{t("contacts.importContacts")}</DashboardCardHeader>
      <DashboardCardBody>
        <div className="field-text form-input-label font-weight-bold">
          {t("contacts.checkList")}
        </div>
        <CheckList items={IMPORT_CHECKLIST} />

        {renderLabel(t("contacts.uploadFile"))}
        <FileInput
          fileName={fileName}
          accept={FILE_RESTRICTION_SPREADSHEET}
          label={t("contacts.dropContactsFile")}
          iconSize={60}
          onUpload={onFileSelect}
        />
        {renderLabel(t("contacts.importAs"))}
        <ButtonSelectInput
          value={importMode}
          onChange={handleModeChange}
          options={importModesOptions}
          name="importModes"
        />
      </DashboardCardBody>

      <DashboardCardFooter className="import-footer">
        {/*
        <Button variant="secondary" className="button large info mr-2" onClick={onCancel}>
          {t("common.cancel")}
        </Button>

        <Button variant="primary" className="button large success">
          {t("common.upload")}
        </Button>
        */}
      </DashboardCardFooter>
    </DashboardCard>
  );
};

export default ContactsImport;
