import React from "react";
import { isEmpty } from "lodash";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../containers/layouts/dashboard/DashboardContext";

type SetNavigationRouteProps = DashboardContextValue & {
  routeId: string;
};

const SetNavigationRoute: React.FC<SetNavigationRouteProps> = ({
  routeId,
  setNavigationItem,
  navigationItems,
}) => {
  React.useEffect(() => {
    if (!isEmpty(navigationItems)) {
      setNavigationItem(routeId, true);
    }
  }, [navigationItems]);

  return null;
};

export default withDashboardContext(SetNavigationRoute);
