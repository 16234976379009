import { User } from "./user";
import { Media } from "../../../models/media";
import { MediaInput } from "../inputs/media";

export enum NoteEntityType {
  Client = "CLIENT",
  Supplier = "SUPPLIER",
  Job = "JOB",
  Estimation = "ESTIMATION",
  Lead = "LEAD",
  TodoList = "TODOLIST",
  QUOTE_REQUEST = "QUOTE_REQUEST",
}

export type Note = {
  _id: string;
  entityId: string;
  entityType: NoteEntityType;
  description: string;
  attachments: Media[];
  createdBy: User;
  updatedAt: string;
  createdAt: string;
};

export type NotePayload = {
  _id?: string;
  description: string;
  attachments?: MediaInput[];
};

export type ListNotesResponse = {
  listNotes: Note[];
};

export type CreateUpdateNotePayload = {
  entityId: string;
  entityType: NoteEntityType;
  note: NotePayload;
};

export type CreateUpdateNoteResponse = {
  createUpdateNote: Note;
};

export type DeleteNotePayload = {
  entityId: string;
  entityType: NoteEntityType;
  noteId: string;
};
export type DeleteNoteResponse = {
  deleteNote: Note;
};
