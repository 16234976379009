import {
  FormikPropGetSetValues,
  GenericFormFields,
  UpdateRowFn,
} from "../../generic-form/GenericFormBody";
import { TFunction } from "i18next";
import {
  CreateCostingItemPayload,
  QuoteMeasurement,
} from "../../../models/salesQuote";
import { calcItemTotal } from "../../../utils/calculations";
import {
  AssemblyCreatePayload,
  AssemblyItemPayload,
} from "../../../models/assembly";
import { UOMOption } from "../../../utils/types/options";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import CostingAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";

export const createAssemblyFields = (
  t: TFunction,
  uoms: UOMOption[],
  handleUOMCreate: (uom: string) => void,
  openCalculatorModal: (
    fieldName: string,
    fieldValue?: string,
    rowIndex?: number,
    formikProps?: FormikPropGetSetValues
  ) => void,
  handlePriceSearch: (value: string) => Promise<SelectOption[]>,
  handlePriceListChange: UpdateRowFn,
  handleOnUOMButton: (value: string) => void,
  activeUOMButton: QuoteMeasurement
): GenericFormFields<AssemblyCreatePayload> => {
  const costingAsyncOptions = async (value: string) => {
    if (!value) return [];

    const result = await handlePriceSearch(value);
    return result;
  };

  return {
    name: {
      type: "text",
      label: t("common.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    UOM: {
      type: "button-select",
      label: t("takeOffSection.measurement"),
      valueKey: "UOM",
      buttonSelectProps: {
        onChange: handleOnUOMButton,
        options: [
          {
            value: QuoteMeasurement.LINEAR_METER,
            label: t("takeOffSection.linearMeters"),
          },
          {
            value: QuoteMeasurement.METER_SQUARED,
            label: t("takeOffSection.metersSquared"),
          },
          {
            value: QuoteMeasurement.QUANTITY,
            label: t("takeOffSection.quantity"),
          },
        ],
      },
    },

    items: {
      tableTitle: t("assembly.assemblyItems"),
      isDraggable: true,
      isCompact: true,
      rowGenerator: (): AssemblyItemPayload => ({
        cost: 0,
        quantity: 0,
        raw_quantity: "",
        UOM: activeUOMButton,
        name: "",
        total: 0,
        margin_amount: 0,
      }),
      row: [
        {
          width: "270px",
          maxWidth: "270px",
          type: "creatable-select",
          label: t("common.name"),
          placeholder: t("placeholders.name"),
          valueKey: "name",
          updateTableRow: handlePriceListChange,
          creatableProps: {
            isEditable: true,
            autoSelectCreatedOption: true,
            options: [],
            asyncOptions: costingAsyncOptions,
            // onCreateOption: handleCreatePriceOption,
            optionComponent: CostingAutocompleteMenuOption,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
        },
        {
          width: "153px",
          maxWidth: "153px",
          type: "text",
          disallowNegativeNumber: true,
          label: t("costing.quantity"),
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
          },
          append: {
            icon: "calculate",
            className: "input-group-append-compact",
            onClick: openCalculatorModal,
          },
        },
        {
          width: "140px",
          maxWidth: "140px",
          type: "creatable-select",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
        },
        // {
        //   width: "140px",
        //   type: "text",
        //   label: t("costing.markup"),
        //   placeholder: t("placeholders.marginValue"),
        //   valueKey: "margin_amount",
        //   inputProps: {
        //     type: "number",
        //     required: true,
        //   },
        // },
        {
          width: "140px",
          maxWidth: "140px",
          type: "text",
          label: t("costing.cost"),
          placeholder: t("placeholders.costValue"),
          valueKey: "cost",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
          },
        },
      ],
      dynamicFields: [
        {
          title: t("costing.totalEx"),
          maxWidth: "160px",
          formatValue: (values: CreateCostingItemPayload) => {
            const amount =
              calcItemTotal(
                {
                  margin_amount: Number(values?.margin_amount) || 0,
                  cost: Number(values?.cost) || 0,
                  quantity: Number(values?.quantity) || 0,
                },
                0
              ) || 0;

            return t("common.currency", { amount });
          },
        },
      ],
    },
  };
};
