import React from "react";
import { useApolloClient } from "@apollo/client";
import { useDownloadFile } from "../useDownloadFile";
import {
  ExportSuppliersRequest,
  ExportSuppliersResponse,
  SupplierFilterInput,
} from "../../graphql/types/models/supplier";
import { EXPORT_SUPPLIERS } from "../../graphql/queries/supplier/queries";

export function useSuppliersExportQuery() {
  const { downloadFile } = useDownloadFile();
  const client = useApolloClient();

  const exportSuppliers = React.useCallback(
    async (filter: SupplierFilterInput) => {
      try {
        const result = await client.query<
          ExportSuppliersResponse,
          ExportSuppliersRequest
        >({
          query: EXPORT_SUPPLIERS,
          variables: {
            filter,
          },
          fetchPolicy: "network-only",
        });
        console.log({ result });

        const exportData = result?.data?.exportSuppliers;
        downloadFile(exportData.data, exportData.filename);
      } catch (e) {}
    },
    []
  );

  return { exportSuppliers };
}
