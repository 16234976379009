import {
  CreateUpdateTimesheetInput,
  CreateUpdateTimesheetPayload,
} from "../../../models/timesheet";

export const getTimesheetInput = (data: CreateUpdateTimesheetPayload) => {
  const result: CreateUpdateTimesheetInput = {
    task: data.task,
    note: data.note,
    startTime: data.startDate,
    userId: data.userId,
    endTime: data.endDate,
    jobId: data.job,
    breakDuration: Number(data.breakDuration),
    costingCategoryName: data.costingCategoryName,
    costingItemId: data.costingItemId,
  };

  if (data._id) {
    result._id = data._id;
  }

  if (data.scheduleItemId) {
    result.scheduleItemId = data.scheduleItemId;
  }

  return result;
};
