import { TFunction } from "i18next";
import { CreateSalesQuotePayload } from "../../../models/salesQuote";
import { getQuoteStatusOptions, STATES_OPTIONS } from "../../../utils/options";

import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { AutocompleteInputOption } from "../../generic-form/inputs/autocomplete";
import ClientInputContainer from "./ClientInputContainer";

export function updateSalesQuoteFormFields(
  t: TFunction,
  clientOptions: AutocompleteInputOption[],
  onAddClientClick: () => void,
  onContactSelect: (contactId: string) => void,
  onPlaceSelect: UpdateTextInputFn,
  isCopy?: boolean
): GenericFormFields<CreateSalesQuotePayload> {
  return {
    name: {
      type: "text",
      label: t("quotes.name"),
      placeholder: t("placeholders.quoteName"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    contactId: {
      type: "select",
      label: t("quotes.client"),
      placeholder: t("placeholders.client"),
      valueKey: "contactId",
      autocompleteProps: {
        options: clientOptions,
        onValueChange: onContactSelect,
        sortByAsc: true,
      },
      inputProps: {
        required: true,
      },
      formField: ClientInputContainer,
      formFieldProps: {
        onAddClientClick: onAddClientClick as any,
      },
    },
    ...(isCopy && {
      markup: {
        type: "text",
        label: t("costing.markupPercentLabel"),
        placeholder: t("costing.markupPercentLabel"),
        valueKey: "markup",
        inputProps: {
          type: "number",
          required: true,
        },
      },
    }),
    ...(!isCopy && {
      status: {
        type: "select",
        label: t("common.status"),
        placeholder: t("placeholders.status"),
        valueKey: "status",
        autocompleteProps: {
          options: getQuoteStatusOptions(t),
        },
        inputProps: {
          required: true,
        },
      },
    }),
    address: {
      type: "place-autocomplete",
      label: t("quotes.siteAddress"),
      placeholder: t("placeholders.address"),
      valueKey: "address",
      textInputProps: {
        onValueChange: onPlaceSelect,
      },
      inputProps: {
        required: true,
      },
    },
    location: [
      {
        type: "text",
        label: t("contacts.city"),
        placeholder: t("placeholders.city"),
        valueKey: "city",
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "select",
        label: t("contacts.state"),
        placeholder: t("placeholders.state"),
        valueKey: "state",
        autocompleteProps: {
          options: STATES_OPTIONS,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    postcode: {
      type: "text",
      label: t("contacts.postcode"),
      placeholder: t("placeholders.postcode"),
      valueKey: "postcode",
      showError: true,
      inputProps: {
        type: "text",
        required: true,
      },
    },
    ...(isCopy && {
      title: {
        type: "group-title",
        placeholder: t("quotes.itemsToCopy"),
      },
      takeoffs: {
        type: "toggle",
        valueKey: "takeoffs",
        toggleProps: {
          label: t("quotes.plansTakeOffs"),
        },
      },
      costings: {
        type: "toggle",
        valueKey: "costings",
        toggleProps: {
          label: t("quotes.costings"),
        },
      },
      specifications: {
        type: "toggle",
        valueKey: "specifications",
        toggleProps: {
          label: t("quotes.specifications"),
        },
      },
      documents: {
        type: "toggle",
        valueKey: "documents",
        toggleProps: {
          label: t("quotes.documents"),
        },
      },
    }),
  };
}
