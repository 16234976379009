import { TFunction } from "i18next";
import { ConfigureInvoicePayload, JobInvoiceType } from "../../../models/job";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const configureClaimFields = (
  t: TFunction,
  invoiceType: JobInvoiceType,
  onInvoiceTypeChange: (value: string) => void
): GenericFormFields<ConfigureInvoicePayload> => {
  const fields: GenericFormFields<ConfigureInvoicePayload> = {
    invoiceType: {
      type: "button-select",
      label: t("jobs.invoiceType"),
      valueKey: "invoiceType",
      buttonSelectProps: {
        onChange: onInvoiceTypeChange,
        options: [
          {
            value: JobInvoiceType.Standard,
            label: t("jobs.invoiceTypes.STANDARD"),
          },
          {
            value: JobInvoiceType.Retention,
            label: t("jobs.invoiceTypes.RETENTION"),
          },
        ],
      },
    },
    invoicePercentage: {
      type: "text",
      label: t("jobs.retentionInvoicePercentage"),
      valueKey: "retention.invoicePercentage",
      inputProps: {
        type: "number",
        required: true,
      },
      append: {
        text: "%",
      },
    },
    totalPercentage: {
      type: "text",
      label: t("jobs.retentionTotalPercentage"),
      valueKey: "retention.totalPercentage",
      inputProps: {
        type: "number",
        required: true,
      },
      append: {
        text: "%",
      },
    },
    excludeVariationFromClaimAmount: {
      type: "toggle",
      valueKey: "excludeVariationFromClaimAmount",
      label: t("jobs.variationSettings"),
      toggleProps: {
        label: t("jobs.excludeVariationFromClaimAmount"),
      },
    },
  };
  if (invoiceType === JobInvoiceType.Standard) {
    delete fields.invoicePercentage;
    delete fields.totalPercentage;
  }

  return fields;
};
