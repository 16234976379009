import { CreateJobPayload, JobDetails } from "../../../models/job";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { TFunction } from "i18next";

export const createSaveCostingAsTemplateField = (
  t: TFunction
): GenericFormFields<CreateJobPayload> => {
  const fields: GenericFormFields<CreateJobPayload> = {
    name: {
      type: "text",
      label: t("buildingTemplate.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        required: true,
      },
    },
  };

  return fields;
};
