import { TFunction } from "i18next";
import { map } from "lodash";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../../generic-form/GenericFormBody";
import { STATES_OPTIONS } from "../../../../utils/options";
import { Business } from "../../../../graphql/types/models/business";

const createAddPriceListFields = (
  t: TFunction,
  onPlaceSelect: UpdateTextInputFn
): GenericFormFields<Business> => {
  return {
    company: [
      {
        type: "text",
        label: t("company.companyName"),
        placeholder: t("company.companyName"),
        valueKey: "name",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("company.abnNumber"),
        placeholder: t("company.abnNumber"),
        valueKey: "abn",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    builderNumber: [
      {
        type: "text",
        label: t("company.builderNumber"),
        placeholder: t("placeholders.builderNumber"),
        valueKey: "builderNumber",
        showError: true,
        inputProps: {
          type: "text",
        },
      },
    ],
    phone: [
      {
        type: "text",
        label: t("contacts.email"),
        placeholder: t("placeholders.email"),
        valueKey: "email",
        showError: true,
        inputProps: {
          type: "email",
          required: true,
        },
      },
      {
        type: "text",
        label: t("company.phone"),
        placeholder: t("company.phone"),
        valueKey: "phone_number",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    address: [
      {
        type: "place-autocomplete",
        label: t("company.address"),
        placeholder: t("company.address"),
        valueKey: "address",
        textInputProps: {
          onValueChange: onPlaceSelect,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.addressSecondary"),
        placeholder: t("placeholders.addressSecondary"),
        valueKey: "address2",
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    city: [
      {
        type: "text",
        label: t("contacts.city"),
        placeholder: t("placeholders.city"),
        valueKey: "city",
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "select",
        label: t("contacts.state"),
        placeholder: t("placeholders.state"),
        valueKey: "state",
        autocompleteProps: {
          options: STATES_OPTIONS,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.postcode"),
        placeholder: t("placeholders.postcode"),
        valueKey: "postcode",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
  };
};

export default createAddPriceListFields;
