import React from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../../hooks/useModalDisplay";
import { TableCardData } from "../../../../components/dashboard/table-card/utils";
import { formatQuoteNumber } from "../../../../utils/text";
import LeftModal from "../../../../components/left-modal";
import LeftModalHeader from "../../../../components/left-modal/LeftModalHeader";
import LeftModalBody from "../../../../components/left-modal/LeftModalBody";
import CardTable from "../../../../components/dashboard/table-card/CardTable";
import { RosterModalListItem } from "../../../../models/roster";
import "./styles.scss";

type RosterItemsTable = {
  _id: string;
  date: string;
  name: string;
  quantity: number;
  UOM: string;
  cost: number;
  total: number;
  status: string;
  rosterOrderName: string;
  jobId: string;
};

export type ListRosterItemsModalRef = ModalDisplayRef & {
  setData: (data: RosterModalListItem[]) => void;
};

const ListRosterItemsModal: React.FC = (_props, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = React.useState<RosterModalListItem[]>([]);

  const { shouldShow, hide } = useModalDisplay<ListRosterItemsModalRef>(
    ref,
    {
      setData: (data: RosterModalListItem[]) => {
        setData(data);
      },
    } as ListRosterItemsModalRef,
    [setData]
  );

  const tableData = React.useMemo<TableCardData<RosterItemsTable>>(() => {
    return {
      columns: [
        {
          valueKey: "date",
          title: t("roster.rosterDate"),
          formatValue: (row: any, column: any, value: number) =>
            moment(value).format("Do MMMM YYYY"),
        },
        {
          valueKey: "rosterOrderName",
          title: t("roster.rosterOrder"),
          className: "table-cell-link",
          onClick: (row) => {
            hide();
            history.push(`/jobs/${row.cells.jobId}/roster/${row.cells._id}`);
          },
        },
        {
          valueKey: "status",
          title: t("common.status"),
          formatValue: (row: any, column: any, value: number) =>
            t(`roster.statuses.${value}`),
        },
        {
          valueKey: "name",
          title: t("orders.nameLabel"),
        },
        {
          valueKey: "quantity",
          title: t("costing.quantity"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.quantity", { amount: value }),
        },
        {
          valueKey: "UOM",
          title: t("costing.uom"),
        },
        {
          valueKey: "cost",
          title: t("costing.cost"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "total",
          title: t("costing.totalEx"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(data, (item) => ({
        cells: {
          _id: item.roster._id,
          jobId: item.roster.job._id,
          name: item.name,
          quantity: item.quantity,
          UOM: item.UOM,
          cost: item.cost,
          total: item.cost * item.quantity,
          date: item.roster.date,
          status: item.roster.status,
          rosterOrderName: formatQuoteNumber(item.roster.rosterNumber, "RO"),
        },
      })),
    };
  }, [data, hide, history, t]);

  return (
    <LeftModal show={shouldShow} onHide={hide} className="list-rosters">
      <LeftModalHeader title={t("roster.rosters")} onClose={hide} />
      <LeftModalBody>
        <CardTable table={tableData} tableClass="table-outline" />
      </LeftModalBody>
    </LeftModal>
  );
};

export default React.forwardRef(ListRosterItemsModal);
