import { EnumSystemUserType } from "../graphql/types/models/notification";
import { JobDetails } from "./job";
import { SalesQuote } from "./salesQuote";
import { SystemUser } from "./task";

export enum EnumChecklistEntityType {
  Job = "JOB",
  Quote = "ESTIMATION",
}

export type ChecklistType = {
  _id: string;
  entityType: EnumChecklistEntityType;
  entityId: string;
  updatedAt: string;
  createdAt: string;
  task: string;
  dueDate: string;
  completed: boolean;
  assignedTo: SystemUser;
  completedByType: EnumSystemUserType;
  assignedToType: EnumSystemUserType;
  completedBy: SystemUser;
  order: number;
  is_deleted: Boolean;
  category: ChecklistCategoryType;
};

export type ChecklistCategoryType = {
  _id: string;
  name: string;
  checklist: ChecklistType[];
  entityType: EnumChecklistEntityType;
  entityId: string;
  order: number;
  is_deleted: boolean;
};

export type ListChecklistCategoriesResponse = {
  listChecklistCategories: ChecklistCategoryType[];
};

export type ChecklistEntityResult = {
  entity: JobDetails & SalesQuote;
  _id: string;
  categories: ChecklistCategoryType[];
  name?: string;
};

export type ListChecklistCategoiresByEntityResponse = {
  listChecklistCategoiresByEntityType: ChecklistEntityResult[];
};

export type CreateChecklistCategoriesResponse = {
  createUpdateChecklistCategory: ChecklistCategoryType[];
};

export type ImportChecklistFromTemplateResponse = {
  createUpdateChecklistCategory: ChecklistCategoryType[];
};

export type DeleteChecklistCategoryResponse = {
  deleteChecklistCategory: ChecklistCategoryType;
};

export type DeleteChecklistResponse = {
  deleteChecklist: ChecklistType;
};

export type ToggleChecklistStatusResponse = {
  toggleChecklistStatus: ChecklistType;
};

export type CreateUpdateChecklistResponse = {
  createUpdateChecklist: ChecklistType;
};
