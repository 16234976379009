import React from "react";
import { get, isString } from "lodash";

export function useFilteredArray<TData>(
  array: TData[],
  filterField: keyof TData | string
) {
  const [filter, setFilter] = React.useState("");
  const [filteredArray, setFilteredArray] = React.useState<TData[]>(array);

  React.useEffect(() => {
    const filteredData = filter
      ? array.filter((item: any) => get(item, filterField) === filter)
      : array;

    setFilteredArray(filteredData);
  }, [filter, array, filterField]);

  return { filteredArray, filter, setFilter };
}

export function useFilteredArrayMultipleField<TData>(
  array: TData[],
  filterFields: (keyof TData)[],
  partialMatch?: boolean,
  filterbySelectFn?: (item: TData, search: string) => boolean
) {
  const [filter, setFilter] = React.useState("");
  const [filterSelect, setFilterbySelect] = React.useState("");
  const [filteredArray, setFilteredArray] = React.useState<TData[]>(array);

  React.useEffect(() => {
    if (!array?.length) return;

    const filteredData = filter
      ? partialMatch
        ? array.filter((item: any) =>
            filterFields.some((field) => {
              if (!item[field] || !isString(item[field])) {
                return false;
              }

              return item[field].toLowerCase().includes(filter.toLowerCase());
            })
          )
        : array.filter((item: any) =>
            filterFields.some((field) => item[field] === filter)
          )
      : array;

    if (filterSelect) {
      const filteredBySelect = filteredData.filter((item: any) =>
        filterbySelectFn?.(item, filterSelect)
      );

      setFilteredArray(filteredBySelect);
    } else {
      setFilteredArray(filteredData);
    }
  }, [filter, array, filterSelect]);

  return { filteredArray, filter, setFilter, setFilterbySelect, filterSelect };
}
