import React, { useState } from "react";
import ReminderList from "../../../components/reminder/reminder-list";
import CreateReminderModal from "../../../components/reminder/create-reminder-modal";
import { CreateReminderPayload } from "../../../models/reminder";
import { notify } from "../../../components/notification";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateUpdateReminderResponse,
  ListRemindersResponse,
} from "../../../graphql/types/models/reminder";
import {
  CREATE_UPDATE_REMINDER,
  DELETE_REMINDER,
  UPDATE_REMINDER_STATUS,
} from "../../../graphql/queries/reminder/mutations";
import { LIST_CLIENT_REMINDERS } from "../../../graphql/queries/reminder/queries";
import { useTranslation } from "react-i18next";
import {
  handleReminderAdd,
  handleReminderDelete,
} from "../../../graphql/queries/reminder/utils";
import { ClientTypeEnum } from "../../../models/client";
import ConfirmDialog from "../../../components/confirm-dialog";

type ContactReminderProps = {
  contactId: string;
  clientType: ClientTypeEnum;
  clientName: string;
  contactLoading?: boolean;
};

const ContactReminder: React.FC<ContactReminderProps> = (props) => {
  const { contactLoading, contactId, clientType, clientName } = props;

  const { t } = useTranslation();

  const [showReminderModal, setReminderModalVisibility] = useState(false);
  const [deleteTargetId, setDeleteTarget] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const toggleDeleteConfirm = React.useCallback(() => {
    setShowDeleteConfirm((visible) => !visible);
  }, []);

  const { data: remindersData, loading: remindersLoading } = useQuery<
    ListRemindersResponse
  >(LIST_CLIENT_REMINDERS, {
    variables: {
      filter: {
        clientId: contactId,
        clientType,
      },
    },
  });

  const [createReminder] = useMutation<CreateUpdateReminderResponse>(
    CREATE_UPDATE_REMINDER,
    {
      onCompleted: () => {
        setReminderModalVisibility(false);
      },
      update: handleReminderAdd(clientType, contactId),
    }
  );

  const [updateReminderStatus] = useMutation(UPDATE_REMINDER_STATUS);
  const [deleteReminder, { loading: reminderDeleting }] = useMutation(
    DELETE_REMINDER,
    {
      update: handleReminderDelete(clientType, contactId),
    }
  );

  const closeReminderModal = React.useCallback(() => {
    setReminderModalVisibility(false);
  }, []);

  const openReminderModal = React.useCallback(() => {
    setReminderModalVisibility(true);
  }, []);

  const handleReminderSubmit = React.useCallback(
    async (values: CreateReminderPayload) => {
      try {
        await createReminder({
          variables: {
            reminder: {
              contactId,
              ...values,
            },
          },
        });

        notify({
          title: t("reminders.createReminder"),
          content: t("reminders.success.createReminder"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("reminders.createReminder"),
          content: t("reminders.error.createReminder"),
        });
      }
    },
    [contactId]
  );

  const openDeleteConfirm = React.useCallback((id: string) => {
    setDeleteTarget(id);
    setShowDeleteConfirm(true);
  }, []);

  const handleDeleteReminderSubmit = React.useCallback(async () => {
    try {
      await deleteReminder({
        variables: {
          reminderId: deleteTargetId,
        },
      });

      notify({
        title: t("reminders.deleteReminder"),
        content: t("reminders.success.deleteReminder"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("reminders.deleteReminder"),
        content: t("reminders.error.deleteReminder"),
      });
    }

    setShowDeleteConfirm(false);
    setDeleteTarget("");
  }, [deleteTargetId]);

  const handleReminderStatusChange = React.useCallback(
    async (reminderId: string, status: boolean) => {
      try {
        await updateReminderStatus({
          variables: {
            reminderId,
            status: !status,
          },
        });
      } catch (e) {}
    },
    [contactId]
  );

  return (
    <>
      <CreateReminderModal
        show={showReminderModal}
        onSubmit={handleReminderSubmit}
        onClose={closeReminderModal}
      />
      <ConfirmDialog
        disabled={reminderDeleting}
        show={showDeleteConfirm}
        title={t("reminders.deleteReminder")}
        onSubmit={handleDeleteReminderSubmit}
        onClose={toggleDeleteConfirm}
      >
        <div className="field-text">{t("reminders.deleteMessage")}</div>
      </ConfirmDialog>
      <ReminderList
        clientName={clientName}
        loading={remindersLoading || contactLoading}
        reminders={remindersData?.listReminders}
        onNewClick={openReminderModal}
        onDelete={openDeleteConfirm}
        onStatusChange={handleReminderStatusChange}
        className="mt-5"
      />
    </>
  );
};

export default ContactReminder;
