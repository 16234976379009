import React from "react";
import moment from "moment";
import DatePickerRange from "../components/date-picker-range";

type UseDropdownDateRangeProps = {
  showResetIcon?: boolean;
};

export function useDropdownDateRange(props: UseDropdownDateRangeProps) {
  const { showResetIcon } = props;
  const [startDate, setStartDate] = React.useState<Date | null>(
    moment().startOf("week").subtract(1, "week").toDate()
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    moment().endOf("week").toDate()
  );

  const handleSetStartDate = React.useCallback((value: Date) => {
    setStartDate(value);
  }, []);

  const handleSetEndDate = React.useCallback((value: Date) => {
    setEndDate(value);
  }, []);

  const handleOnResetRange = React.useCallback(() => {
    setStartDate(null);
    setEndDate(null);
  }, []);

  const renderDropdownDateRange = () => {
    return (
      <DatePickerRange
        startDate={startDate}
        endDate={endDate}
        setStartDate={handleSetStartDate}
        setEndDate={handleSetEndDate}
        className="datePickerRange"
        showResetButton={showResetIcon}
        onResetRange={handleOnResetRange}
      />
    );
  };

  return {
    startDate,
    endDate,
    renderDropdownDateRange,
    setStartDate,
    setEndDate,
  };
}
