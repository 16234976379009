import { gql } from "@apollo/client";

export const DELETE_CLIENT = gql`
  mutation DeleteClient($id: ID) {
    deleteContact(contactId: $id) {
      _id
    }
  }
`;

export const CREATE_UPDATE_CLIENT = gql`
  mutation CreateClient($contact: ContactInput) {
    createUpdateClient(contact: $contact) {
      _id
      business_name
      abn
      first_name
      last_name
      email
      phase
      phone
      phone_secondary
      address
      address2
      city
      state
      postcode
      type
      jobs {
        _id
        name
        contractTotal
        state
        createdAt
      }
      salesQuotes {
        _id
        name
        number
        total
        createdAt
        status
      }
    }
  }
`;

export const IMPORT_CONTACTS = gql`
  mutation ImportContacts($contacts: [ContactInput]) {
    importContacts(contacts: $contacts) {
      _id
    }
  }
`;

export const IMPORT_SUPPLIERS = gql`
  mutation ImportSuppliers($suppliers: [SupplierInput]) {
    importSuppliers(suppliers: $suppliers) {
      _id
    }
  }
`;
