import React from "react";
import classNames from "classnames";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import printStyles from "../entry-list-item/printStyles";
import { SiteDiary } from "../../../graphql/types/models/site-diary";
import EntryListItem from "../entry-list-item/EntryListItem";
import Icon from "../../icons/Icon";
import "./styles.scss";
import UploadSpinner from "../../upload-spinner";

type EntryGroupProps = {
  name: string;
  isOpen?: boolean;
  exportItems?: SiteDiary[];
  onExportAll?: (siteDiaries: SiteDiary[]) => Promise<SiteDiary[]>;
};

const EntryGroup: React.FC<EntryGroupProps> = ({
  name,
  isOpen: isOpenDefault,
  onExportAll,
  exportItems,
  children,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(!!isOpenDefault);
  const printRef = React.useRef<HTMLDivElement | null>(null);
  const [itemsToPrint, setItemsToPrint] = React.useState<SiteDiary[]>();
  const [isExporting, setIsExporting] = React.useState(false);

  const containerClasses = classNames(
    {
      "entry-group-list--open": isOpen,
    },
    "entry-group-list"
  );

  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return (
    <div className={containerClasses}>
      <div className="field-text entry-group-item">
        <b className="entry-group-item--title" title={name} onClick={toggle}>
          {t("siteDiary.groupNameCount", { name, count: exportItems?.length })}
        </b>
        <div className="d-flex h-100" style={{ flex: 1 }} onClick={toggle} />
        {isOpen && (
          <ReactToPrint
            pageStyle={printStyles}
            trigger={() => (
              <div className="d-flex justify-content-end">
                <Button variant="secondary" className="button small info">
                  <Icon name="file_download" />
                  {t("common.exportAll")}
                </Button>
              </div>
            )}
            content={() => printRef.current}
            onBeforeGetContent={() => {
              return new Promise((resolve) => {
                if (exportItems) {
                  setIsExporting(true);
                  if (onExportAll) {
                    onExportAll(exportItems)
                      .then((items) => {
                        setItemsToPrint(items);
                        resolve(true);
                      })
                      .catch((e) => {
                        resolve(false);
                        setIsExporting(false);
                      });
                  } else {
                    setItemsToPrint(exportItems);
                    resolve(true);
                  }
                }
              });
            }}
            onAfterPrint={() => {
              setItemsToPrint([]);
              setIsExporting(false);
            }}
          />
        )}
        <div className="d-flex" onClick={toggle}>
          <Icon name={isOpen ? "expand_less" : "expand_more"} />
        </div>
      </div>
      <div ref={printRef}>
        {map(itemsToPrint, (item) => (
          <EntryListItem siteDiary={item} print />
        ))}
      </div>

      {isOpen && children}
      <UploadSpinner
        show={isExporting}
        text={t("siteDiary.pleaseWaitExport")}
      />
    </div>
  );
};

export default EntryGroup;
