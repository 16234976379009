import React from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { find, head, isEmpty } from "lodash";
import { connect } from "react-redux";

import CardPlaceholder from "../../../../components/dashboard/card-placeholder";
import Conversation from "../../../../components/communication/conversation";
import { ChannelInfo } from "../../../../components/communication/channels-list";
import { DashboardContextValue } from "../../../layouts/dashboard/DashboardContext";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";
import { ComposeRef } from "../../../../components/communication/compose";
import { notify } from "../../../../components/notification";

import {
  GetClientJobChannel,
  ClientPostMessage,
} from "../../../../graphql/types/models/client-job-messaging";
import { GetClientJobChannelInput } from "../../../../graphql/types/inputs/client-messaging";
import { CLIENT_GET_JOB_CHANNEL } from "../../../../graphql/queries/client-job-messaging/queries";
import { CLIENT_POST_MESSAGE } from "../../../../graphql/queries/client-job-messaging/mutations";

import { OnMessageAddResponse } from "../../../../graphql/types/models/job-messaging";
import { OnClientMessageAddedInput } from "../../../../graphql/types/inputs/messaging";
import {
  updateClientChannel,
  handlePostMessage,
  createMessageOptimisticResponse,
} from "../../../../graphql/queries/client-job-messaging/utils";

import { getMediaInput } from "../../../../utils/transform";
import { uploadFiles } from "../../../../utils/files";
import { RootReducerState } from "../../../../redux/reducer";
import { UserPayload } from "../../../../graphql/types/models/auth";
import { CLIENT_SUBSCRIBE_NEW_MESSAGE } from "../../../../graphql/queries/client-job-messaging/subscription";
import { useDeleteJobChannelMessageMutation } from "../../../../hooks/mutations/useDeleteJobChannelMessageMutation";

type Params = {
  id: string;
};

type JobDiscussionProps = RouteComponentProps<Params> &
  DashboardContextValue & {
    user: UserPayload | null;
  };

const JobDiscussion: React.FC<JobDiscussionProps> = ({ user, match }) => {
  const { id: jobId } = match.params;

  const { t } = useTranslation();

  const composeRef = React.useRef<ComposeRef>(null);

  const [selectedChannel, setChannel] = React.useState<ChannelInfo | null>();

  const {
    data: jobChannelResponse,
    loading: jobChannelLoading,
    updateQuery: updateChannel,
    subscribeToMore,
  } = useQuery<GetClientJobChannel, GetClientJobChannelInput>(
    CLIENT_GET_JOB_CHANNEL,
    { variables: { jobId } }
  );

  const [sendMessage] = useMutation<ClientPostMessage>(CLIENT_POST_MESSAGE);

  const {
    renderDeleteMessageConfirmDialog,
    handleShowDeleteMessageConfirmDialog,
  } = useDeleteJobChannelMessageMutation({
    jobId,
    channelId: selectedChannel?._id,
    isClient: true,
  });

  const channels = React.useMemo<ChannelInfo[]>(() => {
    const channel = jobChannelResponse?.clientGetJobChannel;

    if (jobChannelLoading) {
      return [];
    }

    return [
      {
        _id: channel?._id || "",
        name: t("client.jobs.discussion"),
        icon: "arrow_circle_down",
        messages: channel?.messages,
      },
    ];
  }, [t, jobChannelResponse, jobChannelLoading]);

  React.useEffect(() => {
    if (!jobChannelResponse?.clientGetJobChannel?._id) {
      return;
    }

    const unsubExternal = subscribeToMore<
      OnMessageAddResponse,
      OnClientMessageAddedInput
    >({
      document: CLIENT_SUBSCRIBE_NEW_MESSAGE,
      variables: {
        channelId: jobChannelResponse?.clientGetJobChannel?._id,
      },
      updateQuery: updateClientChannel,
    });

    return () => {
      unsubExternal?.();
    };
  }, [subscribeToMore, jobChannelResponse]);

  React.useEffect(() => {
    if (selectedChannel) {
      const channel = find(channels, { _id: selectedChannel._id });
      setChannel(channel || null);
      return;
    }

    setChannel(head(channels) || null);
  }, [selectedChannel, channels]);

  const handleSendMessage = React.useCallback(
    async (message: string, files: File[] | null) => {
      if (!selectedChannel) return;

      composeRef.current?.reset();

      if (!message && isEmpty(files)) return;

      const media = getMediaInput(files);

      const channelId = selectedChannel?._id;

      try {
        const response = await sendMessage({
          optimisticResponse: createMessageOptimisticResponse(channelId, user),
          variables: {
            channelId,
            jobId,
            message: {
              text: message,
              media,
            },
          },
        });

        const newMessage = response.data?.clientPostMessage;

        if (newMessage?.media && files) {
          await uploadFiles(newMessage.media, files);
        }
        updateChannel((channelDetails) => {
          return handlePostMessage(channelDetails, response.data);
        });
      } catch (e) {
        notify({
          error: true,
          title: t("client.jobs.sendMessage"),
          content: t("client.jobs.error.sendMessage"),
        });
      }
    },
    [jobId, selectedChannel, sendMessage, user, updateChannel, t]
  );

  const channelMessages = React.useMemo(
    () =>
      selectedChannel?.messages?.filter((message) => !message.is_deleted) || [],
    [selectedChannel]
  );

  return (
    <Container fluid className="h-100">
      <Helmet title={t("navigation.jobsSection.discussions")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.DISCUSSION} />

      <Row className="h-100">
        <Col lg={12} xs={12} className="mh-100">
          {!selectedChannel && <CardPlaceholder />}
          {selectedChannel && (
            <>
              <Conversation
                ref={composeRef}
                onSend={handleSendMessage}
                messages={channelMessages}
                name={selectedChannel.name}
                user={user}
                onDeleteMessage={handleShowDeleteMessageConfirmDialog}
              />
              {renderDeleteMessageConfirmDialog()}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps)(JobDiscussion);
