import React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { useLazyQuery, useQuery } from "@apollo/client";
import CreateEntityModal from "../../modals/create-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { createPriceLookupField } from "./utils";
import {
  GET_PRICE_LIST,
  LIST_PRICE_LISTS,
} from "../../../graphql/queries/price-list/queries";
import {
  GetPriceListResponse,
  ListPriceListResponse,
} from "../../../graphql/types/models/price-list";
import CardTable from "../../dashboard/table-card/CardTable";
import { TableCardData } from "../../dashboard/table-card/utils";
import { PriceListItem } from "../../../models/price-list";
import { EmptyTablePlaceholder } from "../../dashboard/table-card";
import SearchInput from "../../search-input";
import "./styles.scss";

export type CostingsfieldName = "costItems" | "items";

type PriceLookupModalProps = {
  show: boolean;
  onClose: () => void;
  formikProps: FormikProps<any>;
  fieldName: CostingsfieldName;
  onUOMCreate: (uom: string) => void;
  isRestrictedEntry: boolean;
};

const PriceLookupModal: React.FC<PriceLookupModalProps> = (props) => {
  const {
    show,
    onClose,
    formikProps,
    fieldName,
    onUOMCreate,
    isRestrictedEntry,
  } = props;

  const { t } = useTranslation();

  const [fields, setFields] = React.useState<
    GenericFormFields<{ priceList: string; category: string }>
  >({});
  const [selectedPriceList, setSelectedPriceList] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");

  const formikRef = React.useRef<any>();

  const { data: priceLists } = useQuery<ListPriceListResponse>(
    LIST_PRICE_LISTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [getPriceList, { data: priceListData }] = useLazyQuery<
    GetPriceListResponse
  >(GET_PRICE_LIST, {
    fetchPolicy: "cache-and-network",
  });

  const categories = React.useMemo(() => {
    return priceListData?.getPrice?.categorisedItems || [];
  }, [priceListData]);

  const categoryItems = React.useMemo(() => {
    if (selectedPriceList && selectedCategory) {
      const category = categories?.find(
        (category) => category._id === selectedCategory
      );

      return category ? category.items : [];
    }
    return [];
  }, [categories, selectedCategory, selectedPriceList]);

  const handlePriceListChange = React.useCallback((value: string) => {
    if (!value) return;
    setSelectedPriceList(value);
    getPriceList({
      variables: {
        priceId: value,
      },
    });
  }, []);

  const handleCategoryChange = React.useCallback((value: string) => {
    setSelectedCategory(value);
  }, []);

  const resetForm = React.useCallback(() => {
    setFields(
      createPriceLookupField(
        t,
        handlePriceListChange,
        handleCategoryChange,
        selectedPriceList,
        categories || [],
        priceLists?.listPrice || []
      )
    );
  }, [
    t,
    handlePriceListChange,
    handleCategoryChange,
    selectedPriceList,
    categories,
    priceLists?.listPrice,
  ]);

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleSelect = React.useCallback(
    (item: any) => {
      const { cells } = item;
      const { values, setFieldValue } = formikProps;

      const newRow = {
        UOM: cells?.UOM || "",
        assemblyId: "",
        cost: !isRestrictedEntry ? cells?.cost?.toString() || "0" : "0",
        real_cost: isRestrictedEntry ? cells?.cost?.toString() || "0" : "0",
        gstFree: false,
        hasGST: true,
        items: [],
        margin_amount: "0",
        name: cells?.name || "",
        note: "",
        quantity: 1,
        type: "",
        _id: "",
      };

      setFieldValue(fieldName, values[fieldName].concat(newRow));
      if (cells?.UOM) {
        onUOMCreate(cells.UOM);
      }
    },
    [fieldName, formikProps, onUOMCreate]
  );

  const filteredItems = React.useMemo(
    () =>
      categoryItems?.filter((item) => {
        return item.name?.toUpperCase().includes(searchInput.toUpperCase());
      }),
    [categoryItems, searchInput]
  );

  const costingData = React.useMemo<TableCardData<PriceListItem>>(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "UOM",
          title: t("costing.uom"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "cost",
          title: t("costing.cost"),
          formatValue: (row: any, column: any, value: number) =>
            !isNaN(value) ? t("common.currency", { amount: value }) : "",
        },
        {
          valueKey: "_id",
          title: "",
          formatValue: (row: any, column: any, value: number) => {
            return (
              <Button
                size="sm"
                variant="info"
                onClick={() => handleSelect(row)}
                className="button add-button"
              >
                {t("common.add")}
              </Button>
            );
          },
        },
      ],
      rows:
        filteredItems?.map((item) => ({
          cells: item,
        })) || [],
    };
  }, [t, filteredItems, formikProps]);

  const closeButton = React.useCallback(() => {
    return (
      <Button
        size="lg"
        variant="success"
        onClick={onClose}
        className="button large success"
      >
        {t("common.close")}
      </Button>
    );
  }, []);

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("costing.emptyPriceLookupSearchPlaceholder", {
        filter: searchInput,
      }),
    }),
    [searchInput, t]
  );

  const table = React.useMemo(() => {
    return (
      <>
        <div className="search-input-container">
          <SearchInput
            value={searchInput}
            onChange={setSearchInput}
            showCancelButton
            className="search-input"
            appendSearchIcon
          />
        </div>
        <CardTable
          rowCount
          table={costingData}
          emptyTableText={emptyPlaceholder.text}
        />
      </>
    );
  }, [costingData, t]);

  return (
    <CreateEntityModal
      formikRef={formikRef}
      className="price-lookup-modal"
      title={t("costing.priceLookup")}
      data={{
        priceList: "",
        category: "",
      }}
      show={show}
      fields={fields}
      onSubmit={() => {}}
      onClose={onClose}
      endRenderer={selectedCategory ? table : undefined}
      leftFooterRenderer={closeButton}
      hideSubmitButtons={true}
    />
  );
};

export default PriceLookupModal;
