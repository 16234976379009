import * as yup from "yup";
import { TFunction } from "i18next";

import { find, trim } from "lodash";
import { ValidationMessagesUtils } from "../../../utils/yup";
import { SelectionTemplateType } from "../../../graphql/types/models/selections";

export default function saveSelectionAsTemplateSchema(
  t: TFunction,
  selectionTemplates: SelectionTemplateType[]
) {
  return yup.object().shape({
    name: yup
      .string()
      .test(
        "duplicateNameTest",
        ({ value }) => t("selections.templateNameExists", { name: value }),
        function (value: string) {
          if (!value) return true;
          const name = trim(value);
          return !find(selectionTemplates, { name });
        }
      )
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "selections.templateName"
        )
      ),
    description: yup.string(),
  });
}
