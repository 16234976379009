import React from "react";
import { chain, map } from "lodash";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import { ListRemindersResponse } from "../../../graphql/types/models/reminder";
import { LIST_TODO_LIST } from "../../../graphql/queries/task/queries";
import { ListTodoListResponse } from "../../../graphql/types/models/task";
import { LIST_CLIENT_REMINDERS } from "../../../graphql/queries/reminder/queries";
import { TodoList } from "../../../models/task";
import { Reminder } from "../../../models/reminder";
import TaskDasboardItem from "./TaskDashboardItem";
import DashboardReminderItem from "./ReminderItem";
import EmptyPlaceholder from "../../empty-placeholder";
import "./styles.scss";

type DashboardTaskCardProps = {};
type CombinedData = {
  task?: TodoList;
  reminder?: Reminder;
  date: number;
};

const DashboardTaskCard: React.FC<DashboardTaskCardProps> = () => {
  const { t } = useTranslation();

  const range = React.useMemo(
    () => ({
      from: moment().utc().startOf("week"),
      to: moment().utc().add(1, "day").endOf("week"),
    }),
    []
  );

  const { data: tasksList, loading: tasksLoading } = useQuery<
    ListTodoListResponse
  >(LIST_TODO_LIST);

  const { data: remindersData, loading: remindersLoading } = useQuery<
    ListRemindersResponse
  >(LIST_CLIENT_REMINDERS, {
    variables: {
      filter: {
        dueRange: range,
        completed: false,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const filteredTasks = React.useMemo(
    () =>
      tasksList?.listTodoList.filter((task) =>
        moment(task.dueDate).isBetween(range.from, range.to)
      ),
    [tasksList, range]
  );

  const combinedData = React.useMemo(() => {
    if (tasksLoading || remindersLoading) return null;

    const tasks = map(
      filteredTasks,
      (task) => ({ task, date: +new Date(task.dueDate) } as CombinedData)
    );
    const reminders = map(
      remindersData?.listReminders,
      (reminder) =>
        ({ reminder, date: +new Date(reminder.dueDate) } as CombinedData)
    );

    return chain(tasks).concat(reminders).sortBy("date").value();
  }, [filteredTasks, remindersData, tasksLoading, remindersLoading]);

  const renderTasks = React.useCallback(() => {
    if (!combinedData) return null;
    if (combinedData.length) {
      return map(combinedData, ({ task, reminder }) => {
        if (task) {
          return <TaskDasboardItem key={task._id} t={t} task={task} />;
        } else if (reminder) {
          return (
            <DashboardReminderItem
              key={reminder._id}
              t={t}
              reminder={reminder}
            />
          );
        }
      });
    }
    return <EmptyPlaceholder message={t("dashboard.emptyTasksPlaceholder")} />;
  }, [combinedData]);

  return (
    <DashboardCard className="dashboard-tasks">
      <DashboardCardHeader className="justify-content-between">
        {t("dashboard.tasksReminders")}
      </DashboardCardHeader>
      <DashboardCardBody>{renderTasks()}</DashboardCardBody>
    </DashboardCard>
  );
};

export default DashboardTaskCard;
