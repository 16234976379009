import DashboardCard from "../card";
import React from "react";

import "./styles.scss";
import { map } from "lodash";
import DashboardCardBody from "../card/DashboardCardBody";
import DashboardCardHeader from "../card/DashboardCardHeader";
import DashboardCardFooter from "../card/DashboardCardFooter";
import Button from "react-bootstrap/Button";
import Icon from "../../icons/Icon";
import SVGIcon from "../../icons/SVGIcon";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
  ExpandedFieldOptions,
} from "./utils";
import CardTable from "./CardTable";
import classNames from "classnames";
import EmptyPlaceholder from "../../empty-placeholder";
import CardPagination, { PaginationButton } from "../card-pagination";
import { Pagination } from "../../../models/pagination";

export type EmptyTablePlaceholder = {
  text: string;
  buttonText?: string;
  onPress?: () => void;
};

type TableCardProps = {
  tableId?: string;
  title?: string;
  titleBadge?: string;
  data: TableCardData<any>;
  onRowClick?: (row: TableCardDataRow<any>, index?: number) => void;
  actions?: TableCardAction[];
  headerAction?: TableCardAction;
  additionalHeaderAction?: () => JSX.Element;
  leftRowActions?: TableRowActionData<any>[];
  leftRowActionsClassName?: string;
  alignLeftActionsEnd?: boolean;
  rowActions?: TableRowActionData<any>[];
  rowCount?: boolean;
  isDataLoading?: boolean;
  alignEnd?: boolean;
  headerVariant?: "default";
  overflowXHidden?: boolean;
  overflowInherit?: boolean;
  fullHeight?: boolean;
  emptyPlaceholder?: EmptyTablePlaceholder;
  emptyTableText?: string;
  expandedFieldTitle?: string;
  expandedFieldOptions?: ExpandedFieldOptions<any>;
  className?: string;
  compact?: boolean;
  headerTableLeftRowActions?: () => JSX.Element;
  light?: boolean;
  pagination?: Pagination;
  onPagination?: (type: PaginationButton) => void;
};

const TableCard: React.FC<TableCardProps> = ({
  tableId,
  title,
  onRowClick,
  data,
  leftRowActions,
  leftRowActionsClassName,
  alignLeftActionsEnd,
  rowActions,
  actions,
  rowCount,
  titleBadge,
  headerAction,
  isDataLoading,
  alignEnd,
  headerVariant,
  overflowXHidden,
  overflowInherit,
  fullHeight,
  emptyPlaceholder,
  emptyTableText,
  expandedFieldTitle,
  expandedFieldOptions,
  className,
  compact,
  headerTableLeftRowActions,
  light,
  pagination,
  onPagination,
  additionalHeaderAction,
}) => {
  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<any>, index?: number) => {
      onRowClick && onRowClick(row, index);
    },
    [onRowClick]
  );

  const renderActionButton = (action: TableCardAction) => {
    if (action.isComponent) {
      return action.component;
    }
    return (
      <Button
        disabled={action.disabled}
        key={action.title}
        className={action.className}
        onClick={action.onClick}
      >
        {action.icon && <Icon name={action.icon} />}
        {action.svgIcon && <SVGIcon name={action.svgIcon} />}
        {action.title}
      </Button>
    );
  };

  const isDefaultHeader = headerVariant === "default";

  const tableClasses = classNames("table-card", {
    "table--full-height": fullHeight,
  });

  const cardClasses = classNames(className, {
    "table--full-height": fullHeight,
    "table--withoutBoxShadow": light,
  });

  const headerClasses = classNames("d-flex ", {
    "card-header--disabled": isDataLoading,
    "card-header--default justify-content-center p-0": isDefaultHeader,
    "justify-content-between": !isDefaultHeader,
  });

  const badgeClasses = classNames("title-badge text-capitalize", {
    "title-badge--disabled": isDataLoading,
  });

  const headerTextClasses = classNames("d-flex align-items-center", {
    "text-capitalize": !!titleBadge,
  });

  const bodyStyles = classNames({
    "table-body--hidden": overflowXHidden,
    "table--full-height": fullHeight,
    "table--overflow": overflowInherit,
    "p-0": light,
  });

  const shouldShowPlaceholder = React.useMemo(
    () => emptyPlaceholder && !data.rows.length && !isDataLoading,
    [emptyPlaceholder, data, isDataLoading]
  );

  return (
    <div className={tableClasses}>
      <DashboardCard className={cardClasses}>
        {(title || titleBadge || headerAction) && (
          <DashboardCardHeader className={headerClasses}>
            <div className={headerTextClasses}>
              {title}
              {titleBadge && <div className={badgeClasses}>{titleBadge}</div>}
            </div>
            <div className="d-flex">
              {!isDefaultHeader &&
                !isDataLoading &&
                additionalHeaderAction &&
                additionalHeaderAction()}
              {!isDefaultHeader &&
                !isDataLoading &&
                headerAction &&
                renderActionButton(headerAction)}
            </div>
          </DashboardCardHeader>
        )}
        <DashboardCardBody className={bodyStyles}>
          {shouldShowPlaceholder && emptyPlaceholder ? (
            <EmptyPlaceholder
              message={emptyPlaceholder.text}
              buttonText={emptyPlaceholder?.buttonText}
              onButtonPress={emptyPlaceholder?.onPress}
            />
          ) : (
            <CardTable
              tableId={tableId}
              isDataLoading={isDataLoading}
              rowCount={rowCount}
              onRowClick={handleRowClick}
              table={data}
              alignLeftActionsEnd={alignLeftActionsEnd}
              alignEnd={alignEnd}
              leftRowActions={leftRowActions}
              leftRowActionsClassName={leftRowActionsClassName}
              rowActions={rowActions}
              expandedFieldTitle={expandedFieldTitle}
              expandedFieldOptions={expandedFieldOptions}
              tableClass={compact ? "table-compact" : ""}
              headerTableLeftRowActions={headerTableLeftRowActions}
              emptyTableText={emptyTableText}
            />
          )}
        </DashboardCardBody>
        {!isDataLoading &&
          (actions || (pagination && onPagination)) &&
          !shouldShowPlaceholder && (
            <DashboardCardFooter
              className={classNames({
                "justify-content-between":
                  pagination && onPagination && actions && actions?.length > 0,
              })}
            >
              {actions && map(actions, (action) => renderActionButton(action))}
              {pagination && onPagination && (
                <CardPagination
                  pagination={pagination}
                  onPagination={onPagination}
                  className={classNames({})}
                />
              )}
            </DashboardCardFooter>
          )}
      </DashboardCard>
    </div>
  );
};

export default TableCard;
