import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PDFPageProxy } from "pdfjs-dist";
import { Spinner } from "react-bootstrap";

import Icon from "../../../icons/Icon";
import { resizeCanvas } from "./utils";

type SelectPagesListItemProps = {
  pageNumber: number;
  page: PDFPageProxy | null;
  isSelected: boolean;
  shouldRender: boolean;
  onClick: () => void;
  onRenderComplete: (page: number) => void;
};

export type SelectPagesListItemRef = {
  getPage: () => PDFPageProxy | null;
  getCanvas: () => HTMLCanvasElement | null;
};

const SelectPagesListItem: React.FC<SelectPagesListItemProps> = (
  { pageNumber, page, isSelected, onClick, shouldRender, onRenderComplete },
  ref
) => {
  const { t } = useTranslation();
  const [pageRendered, setPageRendered] = React.useState(false);
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const offscreenCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const [pageRotation, setPageRotation] = React.useState(0);
  const DEFAULT_PAGE_ROTATION_VALUE = 90;

  React.useImperativeHandle(
    ref,
    () => ({
      getCanvas: () => offscreenCanvasRef.current,
      getPage: () => page,
    }),
    [canvasRef, page]
  );

  const renderPages = React.useCallback(
    (isRerenderPage: boolean = false, rotationValue: number = 0) => {
      if (!isRerenderPage) {
        setPageRendered(false);
      }
      if (!page) return;
      const desiredWidth = 310;
      const scale = 4;
      const viewport = page.getViewport({
        scale: scale,
        rotation: rotationValue,
      });

      const canvasScale = desiredWidth / viewport.width;
      const desiredHeight = viewport.height * canvasScale;

      if (!canvasRef.current) return;
      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvasRef.current = offscreenCanvas;
      offscreenCanvas.width = viewport.width;
      offscreenCanvas.height = viewport.height;
      const offscreenContext = offscreenCanvas.getContext("2d");
      if (!offscreenContext) return;

      const context = canvasRef.current.getContext("2d");
      if (!context) return;
      if (!isRerenderPage) {
        setPageRendered(true);
      }
      canvasRef.current.width = viewport.width;
      canvasRef.current.height = viewport.height;

      page
        .render({
          canvasContext: offscreenContext,
          viewport: viewport,
        })
        .promise.then(() => {
          if (offscreenCanvasRef.current && canvasRef.current) {
            context.drawImage(offscreenCanvas, 0, 0);
            resizeCanvas(canvasRef.current, desiredWidth, desiredHeight);
          }
          if (!isRerenderPage) {
            onRenderComplete(pageNumber);
          }
        });
    },
    [onRenderComplete, page, pageNumber]
  );

  const reRenderCurrentPage = React.useCallback(
    (currentPageNumber: number = 0, rotationValue: number = 0) => {
      if (currentPageNumber === page?.pageNumber) {
        renderPages(true, rotationValue);
      }
    },
    [page, renderPages]
  );

  React.useEffect(() => {
    if (!shouldRender) return;
    renderPages();
  }, [page, shouldRender]);

  React.useEffect(() => {
    return () => {
      canvasRef.current = null;
      offscreenCanvasRef.current = null;
    };
  }, []);

  const onRotate = React.useCallback(
    (event: React.SyntheticEvent, isRight: boolean = false) => {
      event.stopPropagation();
      const rotationValue = isRight
        ? pageRotation + DEFAULT_PAGE_ROTATION_VALUE
        : pageRotation - DEFAULT_PAGE_ROTATION_VALUE;
      setPageRotation(rotationValue);
      reRenderCurrentPage(page?.pageNumber, rotationValue);
    },
    [page?.pageNumber, pageRotation, reRenderCurrentPage]
  );

  return (
    <li
      className={classNames("page-item", { "is-selected": isSelected })}
      onClick={onClick}
    >
      <canvas ref={canvasRef} id={`page-${pageNumber}`} />
      <div className="page-placeholder">
        {!pageRendered ? (
          <Spinner animation="border" style={{ width: 50, height: 50 }} />
        ) : (
          <div className="rotate-icons">
            <button onClick={onRotate} className="rotate-icon-left">
              <Icon name="rotate_left" />
            </button>
            <button
              onClick={(e) => onRotate(e, true)}
              className="rotate-icon-right"
            >
              <Icon name="rotate_right" />
            </button>
          </div>
        )}
      </div>
      <div className="page-number">
        <span>{t("plansSection.page", { pageNumber: pageNumber + 1 })}</span>
      </div>
      {isSelected && (
        <div className="selection-mark">
          <Icon name="done" />
        </div>
      )}
    </li>
  );
};

export default React.forwardRef(SelectPagesListItem);
