import React from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import SidebarNavList from "./SidebarNavList";

export type SidebarNavItem = {
  id: string;
  title: string;
  displayTitle?: string;
  icon: React.ElementType;
  url?: string;
  items?: SidebarNavItem[];
  defaultItemId?: string;
  initial?: boolean;
  expandable?: boolean;
  className?: string;
  hideInSidebarMenu?: boolean;
  isLogout?: boolean;
};

type SidebarNavListItemProps = {
  item: SidebarNavItem;
  isSelected: boolean;
  isMinified: boolean;
  selectedItem: SidebarNavItem | null;
  onItemClick: () => void;
  onNestedItemClick: (item: SidebarNavItem) => void;
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = ({
  item,
  isSelected,
  isMinified,
  onItemClick,
  selectedItem,
  onNestedItemClick,
}) => {
  const ItemIcon = item.icon;
  const isExpanded = isSelected && !isEmpty(item.items) && item.expandable;

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      onItemClick();
    },
    [onItemClick]
  );

  return (
    <React.Fragment>
      {item.hideInSidebarMenu ? null : (
        <a
          key={item.id}
          className={classNames(
            "navigation-item",
            { selected: isSelected, [item.id.toLowerCase()]: true },
            item.className
          )}
          onClick={handleClick}
          href={item.url}
        >
          <div className="icon">
            <ItemIcon />
          </div>
          {isMinified ? null : (
            <React.Fragment>
              <div className="title">{item.title}</div>
              <div className="rightAccessory">
                {isExpanded ? (
                  <i className="material-icons">expand_more</i>
                ) : (
                  <i className="material-icons">chevron_right</i>
                )}
              </div>
            </React.Fragment>
          )}
        </a>
      )}
      {item.items && item.expandable ? (
        <SidebarNavList
          key={`${item.id}-list`}
          className={classNames("nested", { expanded: isSelected })}
          items={item.items}
          selectedItem={selectedItem}
          onItemClick={onNestedItemClick}
          isMinified={isMinified}
        />
      ) : null}
    </React.Fragment>
  );
};

export default SidebarNavListItem;
