import classNames from "classnames";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { ColorResult, SketchPicker } from "react-color";
import { PresetColor } from "react-color/lib/components/sketch/Sketch";

import Icon from "../../../icons/Icon";
import { TextInputProps } from "../text";

import "./styles.scss";

export type ColourPickerProps = TextInputProps & {
  onChange: (value: string) => void;
  disableAlpha?: boolean;
  presetColors?: PresetColor[];
};

function RGBAToHexA(hex: string, a: number) {
  if (hex === "transparent") {
    return "#00000000";
  }

  let alpha = Math.round(a * 255).toString(16);
  if (alpha.length === 1) alpha = "0" + alpha;

  return hex + alpha;
}

const ColourPicker: React.FC<ColourPickerProps> = ({
  value,
  placeholder,
  onChange,
  className,
  disableAlpha,
  readOnly,
  presetColors,
}) => {
  const handleColorChange = React.useCallback(
    (color: ColorResult) => {
      const value = disableAlpha
        ? color.hex
        : RGBAToHexA(color.hex, color.rgb.a || 100);
      onChange(value);
    },
    [onChange, disableAlpha]
  );

  return (
    <Dropdown className={classNames("colour-picker", className)}>
      <Dropdown.Toggle
        className="picker-toggle"
        id="dropdown-basic"
        disabled={readOnly}
      >
        <div className="picker-info">
          <div className="selected-color" style={{ backgroundColor: value }} />
          <div
            className={classNames("color-name", {
              "field-text": value,
              placeholder: placeholder && !value,
            })}
          >
            {value || placeholder}
          </div>
        </div>
        {!readOnly && <Icon name={"expand_more"} />}
      </Dropdown.Toggle>

      <Dropdown.Menu className="picker-menu">
        <SketchPicker
          color={value}
          onChange={handleColorChange}
          disableAlpha={disableAlpha}
          presetColors={presetColors}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColourPicker;
