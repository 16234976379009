import React from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CreateUpdateTodoListResponse,
  DeleteTodoListResponse,
  ListTodoListResponse,
  TodoListFilterInput,
  UpdateTodoListCompletionResponce,
} from "../graphql/types/models/task";
import { LIST_TODO_LIST } from "../graphql/queries/task/queries";
import CreateUpdateTaskModal from "../components/task/create-update-task-modal";
import { CreateTodoListPayload, TodoList } from "../models/task";
import {
  handleAddTodoList,
  handleDeleteTodoList,
  handleUpdateTodoList,
} from "../graphql/queries/task/utils";
import { notify } from "../components/notification";
import { t } from "i18next";
import {
  CREATE_UPDATE_TODO_LIST,
  DELETE_TODO_LIST,
  UPDATE_TODO_LIST_COMPLETION,
} from "../graphql/queries/task/mutation";
import moment from "moment-timezone";
import { EnumSystemUserType } from "../graphql/types/models/notification";

type TasksProps = {
  filter: TodoListFilterInput;
};

export function useTasks(props: TasksProps) {
  const { filter } = props;

  const [taskToUpdate, setTaskToUpdate] = React.useState<TodoList | null>(null);

  const [deleteTarget, setDeleteTarget] = React.useState("");

  const [getTasks, { data: tasksList, loading }] = useLazyQuery<
    ListTodoListResponse
  >(LIST_TODO_LIST, {
    fetchPolicy: "network-only",
  });

  const [deleteTodoList, { loading: todoListDeleting }] = useMutation<
    DeleteTodoListResponse
  >(DELETE_TODO_LIST);

  const [updateTodoListCompletion] = useMutation<
    UpdateTodoListCompletionResponce
  >(UPDATE_TODO_LIST_COMPLETION);

  const [
    createUpdateTodoList,
    { loading: todoListCreatingUpdating },
  ] = useMutation<CreateUpdateTodoListResponse>(CREATE_UPDATE_TODO_LIST);

  const loadTasks = React.useCallback(() => {
    getTasks({
      variables: {
        filter,
      },
    });
  }, [getTasks]);

  const [showCreateTaskModal, setShowCreateTaskModal] = React.useState(false);
  const openCreateTaskModal = React.useCallback(() => {
    setShowCreateTaskModal(true);
  }, []);

  const closeCreateTaskModal = React.useCallback(() => {
    setTaskToUpdate(null);
    setShowCreateTaskModal(false);
  }, []);

  const handleDeleteTask = React.useCallback(async () => {
    try {
      if (!deleteTarget) {
        return;
      }

      await deleteTodoList({
        variables: {
          todoListId: deleteTarget,
        },
        update: handleDeleteTodoList(),
      });

      notify({
        title: t("tasks.deleteTask"),
        content: t("tasks.success.deleteTask"),
      });

      loadTasks();
    } catch (e) {
      notify({
        error: true,
        title: t("tasks.deleteTask"),
        content: t("tasks.error.deleteTask"),
      });
    }
  }, [deleteTarget, deleteTodoList, loadTasks]);

  const handleUpdateTodoListCompletion = React.useCallback(
    async (todoListId: string, status: boolean = true) => {
      try {
        if (!todoListId) {
          return;
        }

        await updateTodoListCompletion({
          variables: {
            todoListId,
            status,
          },
        });

        status
          ? notify({
              title: t("tasks.completeTask"),
              content: t("tasks.success.completeTask"),
            })
          : notify({
              title: t("tasks.incompleteTask"),
              content: t("tasks.success.incompleteTask"),
            });
      } catch (e) {
        status
          ? notify({
              error: true,
              title: t("tasks.completeTask"),
              content: t("tasks.error.completeTask"),
            })
          : notify({
              error: true,
              title: t("tasks.incompleteTask"),
              content: t("tasks.error.incompleteTask"),
            });
      }
    },
    [updateTodoListCompletion]
  );

  const handleUpdateStatus = React.useCallback(
    (row?: TodoList) => {
      if (row) {
        handleUpdateTodoListCompletion(row._id, !row.completed);
      }
    },
    [handleUpdateTodoListCompletion]
  );

  const handleUpdateTask = React.useCallback(
    async (data: CreateTodoListPayload) => {
      try {
        if (!data || !taskToUpdate) {
          return;
        }

        await createUpdateTodoList({
          variables: {
            todoList: {
              _id: taskToUpdate._id,
              ...data,
              ...(data?.members && {
                members: data.members.map((member) => ({
                  memberId: member,
                  memberType: EnumSystemUserType.Builder,
                })),
              }),
              timezone: moment.tz.guess(),
            },
          },
          update: handleUpdateTodoList(taskToUpdate._id),
        });

        loadTasks();

        notify({
          title: t("tasks.updateTask"),
          content: t("tasks.success.updateTask"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("tasks.updateTask"),
          content: t("tasks.error.updateTask"),
        });
      }
      closeCreateTaskModal();
    },
    [closeCreateTaskModal, taskToUpdate, createUpdateTodoList, loadTasks]
  );

  const handleAddTask = React.useCallback(
    async (data: CreateTodoListPayload, createAnother: boolean) => {
      try {
        if (!data) {
          return;
        }

        await createUpdateTodoList({
          variables: {
            todoList: {
              ...data,
              ...(data?.members && {
                members: data.members.map((member) => ({
                  memberId: member,
                  memberType: EnumSystemUserType.Builder,
                })),
              }),
              timezone: moment.tz.guess(),
            },
          },
          update: handleAddTodoList(),
        });
        loadTasks();

        notify({
          title: t("tasks.addTask"),
          content: t("tasks.success.addTask"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("tasks.addTask"),
          content: t("tasks.error.addTask"),
        });
      }
      if (!createAnother) closeCreateTaskModal();
    },
    [closeCreateTaskModal, createUpdateTodoList, loadTasks]
  );

  const renderCreateUpdateTaskModal = React.useCallback(
    () => (
      <CreateUpdateTaskModal
        canAssign={true}
        data={taskToUpdate}
        user={null}
        show={!!taskToUpdate || showCreateTaskModal}
        onClose={closeCreateTaskModal}
        onSubmit={taskToUpdate ? handleUpdateTask : handleAddTask}
        leadId={filter.leadId}
      />
    ),
    [
      closeCreateTaskModal,
      filter.leadId,
      handleAddTask,
      handleUpdateTask,
      showCreateTaskModal,
      taskToUpdate,
    ]
  );

  return {
    loadTasks,
    tasksList,
    loading,
    renderCreateUpdateTaskModal,
    openCreateTaskModal,
    closeCreateTaskModal,
    handleUpdateStatus,
    setDeleteTarget,
    handleDeleteTask,
    setTaskToUpdate,
    handleUpdateTodoListCompletion,
  };
}
