import { DigitalSignature } from "../../../components/accept-signature-dialog/utils";
import { Client } from "../../../models/client";
import { JobDetails } from "../../../models/job";
import { Media } from "../../../models/media";
import { SalesQuote } from "../../../models/salesQuote";
import { MediaInput } from "../inputs/media";
import { User } from "./user";

export type SelectionResult = Selection & SelectionTemplateType;

export type Selection = {
  _id: string;
  name: string;
  description: string;
  dueDate: string;
  linkedEntityId: string;
  entity: JobDetails & SalesQuote;
  linkedEntityType: EnumSelectionLinkedEntityType;
  status: EnumSelectionStatus;
  categories: SelectionCategory[];
  is_deleted: boolean;
  updatedAt: string;
  createdAt: string;
};

export type CreateUpdateQuoteSelectionCategoryResponse = {
  createUpdateSelectionCategory: SelectionCategory;
};

export type CreateUpdateSelectionCategoryItemResponse = {
  createUpdateSelectionCategoryItem: SelectionCategoryItemType;
};

export type CreateUpdateItemOptionResponse = {
  createUpdateSelectionCategoryItemOption: SelectionCategoryItemOption;
};

export type SelectSelectionCategoryItemResponse = {
  selectSelectionCategoryItem: SelectionCategoryItemType;
};

export type ListClientAccessResponse = {
  listClientAccess: {
    contact: {
      _id: string;
    };
  }[];
};

export type ClientSelectSelectionItems = {
  items: SelectionCategoryItemType[];
  signature: DigitalSignature;
};

export type ClientSelectSelectionItemResponse = {
  clientSelectSelectionItem: SelectionCategoryItemType;
};

export type ClientSelectSelectionItemsResponse = {
  clientSelectSelectionItems: ClientSelectSelectionItems;
};

export type DeleteSelectionCategoryResponse = {
  deleteSelectionCategory: SelectionCategory;
};

export type DeleteSelectionCategoryItemResponse = {
  deleteSelectionCategoryItem: SelectionCategoryItemType;
};

export type DeleteSelectionOptionResponse = {
  deleteSelectionCategoryItemOption: SelectionCategoryItemOption;
};

export type ShareSelectionResponse = {
  shareSelection: Selection;
};

export type ListSelectionTemplatesResponse = {
  listSelectionTemplates: SelectionTemplateType[];
};

export type SaveSelectionAsTemplateResponse = {
  saveSelectionAsTemplate: SelectionTemplateType;
};

export type ImportSelectionAsTemplateResponse = {
  importSelectionTemplate: SelectionResult;
};

export type ClientGetSelectionResponse = {
  clientGetSelection: Selection;
};

export type SalesQuoteGetSelectionResponse = {
  getSelection: SelectionResult;
};

export type GetSelectionTemplateResponse = {
  getSelectionTemplate: SelectionTemplateType;
};

export enum EnumSelectionLinkedEntityType {
  JOB = "JOB",
  ESTIMATION = "ESTIMATION",
}

export enum EnumSelectionStatus {
  DRAFT = "DRAFT",
  SHARED = "SHARED",
  SELECTED = "SELECTED",
  COMPLETED = "COMPLETED",
  INCOMPLETE = "INCOMPLETE",
  CANCELLED = "CANCELLED",
  OVERDUE = "OVERDUE",
}

export type SelectionCategory = {
  _id: string;
  name: string;
  description: string;
  dueDate: string;
  costingCategoryEntityName: string;
  costingCategoryEntityId: string;
  costingCategoryEntityType: EnumSelectionLinkedEntityType;
  status: EnumSelectionStatus;
  order: number;
  is_deleted: boolean;
  items: SelectionCategoryItemType[];
  selectionEntityType: EnumSelectionEntityType;
  selectionEntityId: string;
  updatedAt: string;
  createdAt: string;
};

export type SelectionCategoryItemType = {
  _id: string;
  name: string;
  description: string;
  dueDate: string;
  note: string;
  cost: number;
  quantity: number;
  order: number;
  UOM: string;
  costingCategoryEntityId: string;
  costingItemEntityName: string;
  costingItemEntityId: string;
  costingItemEntityType: EnumSelectionLinkedEntityType;
  category: SelectionCategory;
  status: EnumSelectionStatus;
  options: SelectionCategoryItemOption[];
  defaultOption: SelectionCategoryItemOption;
  selectedOption: SelectionCategoryItemOption;
  selectedByDate: string;
  selectedBy: SelectionUser;
  approvedBy?: SelectionUser;
  approvedByDate?: string;
  clientComment?: string;
  clientSignature?: DigitalSignature;
  comments: SelectionCategoryItemComment[];
  is_deleted: boolean;
  updatedAt: string;
  createdAt: string;
  costingItem: {
    _id: string;
    name: string;
    category: {
      _id: string;
      name: string;
    };
  };
};

export enum EnumSelectionEntityType {
  SELECTION = "SELECTION",
  TEMPLATE = "TEMPLATE",
}

export type SelectionCategoryItemOption = {
  _id: string;
  name: string;
  description: string;
  note: string;
  cost: number;
  markup_cost: number;
  quantity: number;
  order: number;
  UOM: string;
  attachments: Media[];
  is_deleted: boolean;
  updatedAt: string;
  createdAt: string;
};

type SelectionCategoryItemComment = {
  _id: string;
  comment: string;
  commentor: SelectionUser;
  is_deleted: boolean;
  updatedAt: string;
  createdAt: string;
};

type SelectionUser = User & Client;

export type SelectionTemplateType = {
  _id: string;
  name: string;
  description: string;
  categories: SelectionCategory[];
  is_deleted: boolean;
  updatedAt: string;
  createdAt: string;
};

export type CreateSelectionCategoryPayload = {
  _id?: string;
  name: string;
  description?: string;
  dueDate?: string;
  order?: string;
  costingCategory?: string;
  status?: EnumSelectionStatus;
  costingCategoryEntityName?: string;
  costingCategoryEntityId?: string;
  costingCategoryEntityType?: EnumSelectionLinkedEntityType;
  items?: SelectionCategoryItemInput[];
};

export type CreateSelectionCategoryItemPayload = {
  _id?: string;
  name: string;
  description?: string;
  dueDate?: string;
  costingCategoryEntityId?: string;
  costingItemEntityId?: string;
  costingItemEntityName?: string;
  costingItemEntityType?: EnumSelectionLinkedEntityType;
};

export type SelectionCategoryItemOptionInput = {
  _id?: string;
  name: string;
  description?: string;
  note?: string;
  cost?: number | string;
  quantity?: number | string;
  order?: number;
  UOM?: string;
  attachments?: MediaInput[];
};

type SelectionCategoryItemInput = {
  _id?: string;
  name: string;
  description: string;
  dueDate: string;
  note: string;
  cost: number;
  quantity: number;
  order: number;
  UOM: string;
  defaultOption: string;
  costingItemEntityName: string;
  costingItemEntityId: string;
  costingItemEntityType: EnumSelectionLinkedEntityType;
  status: EnumSelectionStatus;
};
