import { TFunction } from "i18next";
import { CreateTakeOffLabelPayload } from "../../../models/take-off";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export function createTakeOffLabelFields(
  t: TFunction
): GenericFormFields<CreateTakeOffLabelPayload> {
  return {
    name: {
      type: "text",
      label: t("takeOffSection.label"),
      placeholder: t("takeOffSection.label"),
      valueKey: "name",
      inputProps: {
        required: true,
      },
    },
  };
}
