import { GSTCResult } from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min";
import React from "react";
import { useTranslation } from "react-i18next";
import { CalendarTaskChartItem } from "../utils";

type ContextMenuOption = {
  label: string;
  handler: () => void;
};

export function useTimelineContextMenu(
  appRef: React.MutableRefObject<GSTCResult | null>,
  openTaskGroupModal: (id: string, time: { start: Date; end: Date }) => void,
  openCreateTaskModal: (
    id: string,
    parentId: string,
    time: { start: Date; end: Date }
  ) => void
) {
  const { t } = useTranslation();
  const [anchorPoint, setAnchorPoint] = React.useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = React.useState(false);
  const isSelectingRef = React.useRef(false);
  const selectingRef = React.useRef<CalendarTaskChartItem[] | null>(null);
  const tooltipRef = React.useRef<any>();
  const selectedRef = React.useRef<{
    parentId: string | null;
    id: string | null;
    time: {
      start: Date;
      end: Date;
    } | null;
  }>();
  React.useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      if (isSelectingRef.current && tooltipRef.current) {
        tooltipRef.current.style.left = `${ev.clientX + 1}px`;
        tooltipRef.current.style.top = `${ev.clientY + 1}px`;
      }
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      document.getElementsByTagName("body")[0].style.cursor = "default";
    };
  }, []);

  const handleTimelineContextAction = React.useCallback(
    (event: React.MouseEvent) => {
      if (!selectedRef.current?.id) {
        setShowMenu(false);
        return;
      }
      event.preventDefault();
      setAnchorPoint({
        x: event.pageX,
        y: event.pageY,
      });
      setShowMenu(true);
    },
    [appRef]
  );

  const handleTimelineSelectContext = React.useCallback(
    (event: React.MouseEvent) => {
      setShowMenu(false);
      const selection =
        appRef.current?.api.plugins.Selection.getSelection().selected[
          "chart-timeline-grid-row-cell"
        ] || [];

      const itemSelection =
        appRef.current?.api.plugins.Selection.getSelection().selected[
          "chart-timeline-items-row-item"
        ] || [];
      if (selection.length > 0 && itemSelection.length === 0) {
        event.preventDefault();
        const firstCell = selection[0]?.time?.leftGlobal;
        const lastCell = selection[selection.length - 1]?.time?.rightGlobal;
        const time = {
          start: new Date(firstCell),
          end: new Date(lastCell),
        };
        const parentId = selection[0].row?.parentId
          ? selection[0].row?.parentId
          : selection[0].row?.id;
        const id = selection[0].row?.id;
        selectedRef.current = { parentId, time, id };
      } else {
        selectedRef.current = { parentId: null, time: null, id: null };
      }
    },
    []
  );

  const handleClearContext = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setShowMenu(false);
    },
    [isSelectingRef]
  );

  const handleCreateCategory = React.useCallback(() => {
    if (selectedRef.current?.id && selectedRef.current?.time) {
      openTaskGroupModal(selectedRef.current?.id, selectedRef.current?.time);
    }
    setShowMenu(false);
    selectedRef.current = { id: null, time: null, parentId: null };
  }, [selectedRef]);

  const handleCreateTask = React.useCallback(() => {
    if (
      selectedRef.current?.id &&
      selectedRef.current?.time &&
      selectedRef.current?.parentId
    ) {
      openCreateTaskModal(
        selectedRef.current?.id,
        selectedRef.current?.parentId,
        selectedRef.current?.time
      );
    }
    setShowMenu(false);
    selectedRef.current = { id: null, time: null, parentId: null };
  }, [selectedRef]);

  const renderTooltip = React.useCallback(() => {
    return (
      <div
        ref={tooltipRef}
        style={{
          position: "absolute",
          background: "#314961",
          color: "white",
          padding: 10,
          zIndex: 119,
          borderRadius: 5,
        }}
      ></div>
    );
  }, []);

  const renderTimelineContextMenu = React.useCallback(() => {
    const Tooltip = renderTooltip();
    if (!showMenu) return Tooltip;
    const options: ContextMenuOption[] = [
      {
        label: t("schedule.createCategory"),
        handler: handleCreateCategory,
      },
      {
        label: t("schedule.createItem"),
        handler: handleCreateTask,
      },
    ];

    return (
      <>
        {Tooltip}
        <div
          style={{
            position: "absolute",
            paddingLeft: 10,
            paddingRight: 10,
            background: "#314961",
            borderRadius: 5,
            color: "white",
            zIndex: 1,
            left: `${anchorPoint.x}px`,
            top: `${anchorPoint.y}px`,
          }}
        >
          {options.map((option) => (
            <div
              key={option.label}
              onClick={option.handler}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                cursor: "pointer",
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      </>
    );
  }, [
    selectingRef,
    isSelectingRef,
    anchorPoint,
    showMenu,
    handleCreateTask,
    renderTooltip,
  ]);

  return {
    handleClearContext,
    handleTimelineSelectContext,
    handleTimelineContextAction,
    renderTimelineContextMenu,
  };
}
