import React from "react";
import classNames from "classnames";
import { chain, map } from "lodash";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import omitDeep from "omit-deep-lodash";

import {
  CreateTakeOffLabelPayload,
  TakeoffListItem,
  TakeOffMeasurement,
  TakeOffShape,
} from "../../../models/take-off";
import {
  convertQuantity,
  getCalibrationFromShape,
  qtyFn,
  updateAreaQuantity,
  updateLineQuantity,
} from "../take-off-plan-viewer/utils";
import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import Icon from "../../icons/Icon";
import { Calibration } from "../../../models/salesQuote";
import TakeOffPage from "./TakeOffListPage";
import TakeOffListShapes from "./TakeOffListShapes";
import { UPDATE_TAKEOFF_ITEMS } from "../../../graphql/queries/take-off/mutations";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";
import { useSalesQuoteQuery } from "../../../hooks/queries/useSalesQuoteQuery";
import TakeOffLabelModal from "../take-off-label-modal";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import Dropdown from "../../dashboard/dropdown";
import SaveTakeOffAsTemplateModal from "../../../components/plans/take-off-modals/save-as-template";
import ImportTakeoffFromTemplateModal from "../../../components/plans/take-off-modals/import-takeoff-from-template";
import "./styles.scss";

type TakeOffCardProps = {
  salesQuoteId: string;
  takeOffs?: TakeoffListItem[] | null;
  selectedTakeOffItem: TakeoffListItem | null;
  isLocked: boolean;
  onTakeOffItemSelect: (item: TakeoffListItem | null) => void;
  onAddTakeOffClick: () => void;
  onEditTakeOffClick: (item: TakeoffListItem) => void;
  onCopyTakeOffClick: (item: TakeoffListItem) => void;
  onDeleteTakeOffClick: (id: string) => void;
  onAddCostingClick: () => void;
  onPageSelect?: (page: number) => void;
  onShapeClick?: (shape: TakeOffShape) => void;
  onDownloadTakeOffs: () => void;
  refetchTakeOffItems: () => void;
};

type ShapeOrder = {
  [index: string]: number;
};

const ShapeOrderIndexes: ShapeOrder = {
  polyline: 0,
  polygon: 1,
  rectangle: 2,
  deduction: 3,
  polylineDeduction: 4,
  rectangleDeduction: 5,
  comment: 6,
};

const TakeOffCard: React.FC<TakeOffCardProps> = ({
  salesQuoteId,
  takeOffs,
  isLocked,
  onTakeOffItemSelect,
  selectedTakeOffItem,
  onAddTakeOffClick,
  onEditTakeOffClick,
  onCopyTakeOffClick,
  onDeleteTakeOffClick,
  onAddCostingClick,
  onPageSelect,
  onShapeClick,
  onDownloadTakeOffs,
  refetchTakeOffItems,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [shapeToEdit, setShapeToEdit] = React.useState<TakeOffShape>();
  const confirmDeleteRef = React.useRef<ConfirmDialogRef>(null);
  const labelRef = React.useRef<ModalDisplayRef>(null);
  const saveTakeOffAsTemplateRef = React.useRef<ModalDisplayRef>();
  const importTakeOffFromTemplateRef = React.useRef<ModalDisplayRef>();

  const { salesQuotePlans } = useSalesQuoteQuery(salesQuoteId);

  const [updateTakeoff] = useMutation(UPDATE_TAKEOFF_ITEMS);

  const calibrations = React.useMemo<Calibration[]>(
    () => salesQuotePlans.map((file) => file.calibration),
    [salesQuotePlans]
  );

  const getTotalUnit = (qty: number, unit: string) => {
    const { quantity, UOM } = convertQuantity(qty, unit);
    return `${quantity?.toLocaleString("en-us", {
      maximumFractionDigits: 2,
    })} ${UOM}`;
  };

  const getItemTitle = (item: TakeoffListItem) => {
    const total = getTotalUnit(item.quantity, item.UOM);
    return `${item.name} (${total})`;
  };

  const handleItemClick = React.useCallback(
    (item: TakeoffListItem) => () => {
      onTakeOffItemSelect(item._id === selectedTakeOffItem?._id ? null : item);
    },
    [selectedTakeOffItem, onTakeOffItemSelect]
  );

  const handleDeleteClick = React.useCallback(
    (id: string) => (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onDeleteTakeOffClick && onDeleteTakeOffClick(id);
    },
    [onDeleteTakeOffClick]
  );

  const handleEditClick = React.useCallback(
    (takeOff: TakeoffListItem) => (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onEditTakeOffClick && onEditTakeOffClick(takeOff);
    },
    [onEditTakeOffClick]
  );

  const handleCopyClick = React.useCallback(
    (takeOff: TakeoffListItem) => (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onCopyTakeOffClick && onCopyTakeOffClick(takeOff);
    },
    [onCopyTakeOffClick]
  );

  const handleShapeDelete = React.useCallback(
    (shape: TakeOffShape) => {
      setShapeToEdit(shape);
      confirmDeleteRef.current?.show(true);
    },
    [setShapeToEdit]
  );

  const handleShapeLabel = React.useCallback(
    (shape: TakeOffShape) => {
      setShapeToEdit(shape);
      labelRef.current?.show(true);
    },
    [labelRef]
  );

  const handleShapeLabelSubmit = React.useCallback(
    async (values: CreateTakeOffLabelPayload) => {
      if (!shapeToEdit || !selectedTakeOffItem) return;
      const updatedTakeoff = {
        _id: selectedTakeOffItem?._id,
        shapes: selectedTakeOffItem?.shapes?.map((shape) => ({
          ...omitDeep(shape, ["__typename", "isEdited"]),
          name: shape === shapeToEdit ? values.name : shape?.name,
        })),
      };
      await updateTakeoff({
        variables: {
          salesQuoteId,
          takeOffItems: [updatedTakeoff],
        },
      });

      labelRef.current?.show(false);
    },
    [labelRef, selectedTakeOffItem, shapeToEdit]
  );

  const handleUpdateScaledItems = React.useCallback(
    async (items: TakeoffListItem[]) => {
      await updateTakeoff({
        variables: {
          salesQuoteId,
          takeOffItems: items.map((item) => ({
            ...omitDeep(item, ["__typename"]),
          })),
        },
      });
    },
    [salesQuoteId, updateTakeoff]
  );

  React.useEffect(() => {
    if (takeOffs) {
      let scaledItems: TakeoffListItem[] = [];
      takeOffs.forEach((item) => {
        if (!item.shapes) return;
        const pages = chain(item.shapes)
          .uniqBy("page")
          .map((s) => s.page)
          .sort()
          .value();
        if (item.UOM === TakeOffMeasurement.QUANTITY) return;
        let allPagesQuantity = 0;
        pages.forEach((page) => {
          const pageShapes = chain(item.shapes)
            .filter((s) => s.page === page)
            .sortBy((s) => ShapeOrderIndexes[s.type] || 0)
            .value();

          if (pageShapes.length) {
            allPagesQuantity += pageShapes.reduce((total, shapeItem) => {
              const calibration = getCalibrationFromShape(
                calibrations,
                shapeItem
              );
              if (!calibration) return 0;
              const quantity = qtyFn(shapeItem, calibration, item.UOM);
              return total + quantity;
            }, 0);
          }
        });

        if (allPagesQuantity !== item.quantity) {
          const itemToUpload = { ...item, quantity: allPagesQuantity };
          scaledItems.push(itemToUpload);
        }
      });

      if (scaledItems.length) {
        handleUpdateScaledItems(scaledItems);
      }
    }
  }, [takeOffs]);

  const handleShapeDeleteConfirm = React.useCallback(() => {
    if (!shapeToEdit || !selectedTakeOffItem) return;
    const shapes = selectedTakeOffItem.shapes?.filter((s) => s !== shapeToEdit);
    let quantity = 0;
    switch (selectedTakeOffItem.UOM) {
      case TakeOffMeasurement.MILLIMETRE:
      case TakeOffMeasurement.LINEAR_METER:
        quantity = updateLineQuantity(
          selectedTakeOffItem,
          shapes,
          calibrations
        );
        break;
      case TakeOffMeasurement.METER_SQUARED:
      case TakeOffMeasurement.CUBIC_METER:
        quantity = updateAreaQuantity(
          selectedTakeOffItem,
          shapes,
          calibrations
        );
        break;
    }

    const updatedTakeoff = {
      _id: selectedTakeOffItem?._id,
      shapes: shapes?.map((shape) =>
        omitDeep(shape, ["__typename", "isEdited"])
      ),
      quantity,
    };
    updateTakeoff({
      variables: {
        salesQuoteId,
        takeOffItems: [updatedTakeoff],
      },
    });
  }, [salesQuoteId, selectedTakeOffItem, shapeToEdit]);

  const handleNextClick = React.useCallback(
    () => history.push(`/quotes/${salesQuoteId}/costing`),
    [history, salesQuoteId]
  );

  const renderShapes = React.useCallback(
    (takeOff: TakeoffListItem) => {
      const pages = chain(takeOff.shapes)
        .uniqBy("page")
        .map((s) => s.page)
        .sort()
        .value();

      return pages.map((page) => {
        const pageShapes = chain(takeOff.shapes)
          .filter((s) => s.page === page)
          .sortBy((s) => ShapeOrderIndexes[s.type] || 0)
          .value();

        if (pageShapes.length) {
          const shapeItems = (
            <TakeOffListShapes
              key={`pageShapes${page}`}
              shapes={pageShapes}
              UOM={takeOff.UOM}
              calibrations={calibrations}
              onShapeDelete={handleShapeDelete}
              onShapeClick={onShapeClick}
              onShapeLabel={handleShapeLabel}
            />
          );

          if (salesQuotePlans.length === 1) {
            return shapeItems;
          }

          return (
            <TakeOffPage
              key={`page${page}`}
              page={page}
              onPageSelect={onPageSelect}
            >
              {shapeItems}
            </TakeOffPage>
          );
        }
      });
    },
    [calibrations, salesQuotePlans, onPageSelect]
  );

  const renderTakeoff = React.useCallback(
    (takeOff: TakeoffListItem) => {
      const isSelected = takeOff._id === selectedTakeOffItem?._id;
      return (
        <li
          className={classNames("take-off-list-item", {
            selected: isSelected,
          })}
          key={takeOff._id}
        >
          <div className="item-total" onClick={handleItemClick(takeOff)}>
            <div className="item-info">
              <div
                className="item-color"
                style={{ backgroundColor: takeOff.properties.color }}
              />
              <div className="item-title">{getItemTitle(takeOff)}</div>
            </div>

            {isSelected && takeOff._id && (
              <>
                <div className="action" onClick={handleCopyClick(takeOff)}>
                  <Icon name="file_copy" />
                </div>
                {!isLocked && (
                  <div className="action" onClick={handleEditClick(takeOff)}>
                    <Icon name="edit" />
                  </div>
                )}
                {!isLocked && (
                  <div
                    className="action"
                    onClick={handleDeleteClick(takeOff._id)}
                  >
                    <Icon name="clear" />
                  </div>
                )}
              </>
            )}
          </div>
          {isSelected && renderShapes(takeOff)}
        </li>
      );
    },
    [
      selectedTakeOffItem,
      handleItemClick,
      handleDeleteClick,
      onEditTakeOffClick,
      isLocked,
    ]
  );

  const handleSaveAsTemplate = React.useCallback(() => {
    saveTakeOffAsTemplateRef.current?.show(true);
  }, [saveTakeOffAsTemplateRef]);

  const handleImportTakeOffFromTemplate = React.useCallback(() => {
    importTakeOffFromTemplateRef.current?.show(true);
  }, [importTakeOffFromTemplateRef]);

  const dropdownOptions = React.useMemo(
    () => [
      {
        id: "export",
        label: t("navigation.quotesSection.exportTakeOffs"),
        onClick: onDownloadTakeOffs,
        icon: "download",
      },
      {
        id: "saveAsTemplate",
        label: t("takeOffSection.saveAsTemplate"),
        onClick: handleSaveAsTemplate,
        icon: "save",
      },
      {
        id: "importFromTemplate",
        label: t("takeOffSection.importFromTemplate"),
        onClick: handleImportTakeOffFromTemplate,
        icon: "import_export",
      },
    ],
    [onDownloadTakeOffs]
  );

  const renderDropdown = () => {
    return (
      <Dropdown
        label={t("common.options")}
        icon="expand_more"
        id="export-takeOff-items"
        items={dropdownOptions}
        alignRight
      />
    );
  };

  return (
    <>
      <ConfirmDialog
        ref={confirmDeleteRef}
        title={t("takeOffSection.deleteItem")}
        onSubmit={handleShapeDeleteConfirm}
      >
        <span className="field-text">
          {t("takeOffSection.deleteItemMessage")}
        </span>
      </ConfirmDialog>
      <TakeOffLabelModal
        ref={labelRef}
        shape={shapeToEdit}
        onSubmit={handleShapeLabelSubmit}
      />
      <SaveTakeOffAsTemplateModal
        ref={saveTakeOffAsTemplateRef}
        salesQuoteId={salesQuoteId}
      />
      <ImportTakeoffFromTemplateModal
        ref={importTakeOffFromTemplateRef}
        salesQuoteId={salesQuoteId}
        refetchTakeOffItems={refetchTakeOffItems}
      />
      <DashboardCard className="take-off-card mh-100">
        <DashboardCardHeader className="justify-content-between">
          {t("takeOffSection.takeOffItems")}
          {renderDropdown()}
        </DashboardCardHeader>
        <DashboardCardBody>
          <Button
            className="button large info add-item"
            onClick={onAddTakeOffClick}
          >
            {t("takeOffSection.addTakeOff")}
          </Button>
          <ul className="take-off-list">{map(takeOffs, renderTakeoff)}</ul>
        </DashboardCardBody>
        <DashboardCardFooter className="d-flex justify-content-between">
          {!isLocked && (
            <Button className="button info" onClick={onAddCostingClick}>
              {t("takeOffSection.addTakeOffsToCosting")}
            </Button>
          )}
          <Button className="button success" onClick={handleNextClick}>
            {t("common.next")}
          </Button>
        </DashboardCardFooter>
      </DashboardCard>
    </>
  );
};

export default TakeOffCard;
