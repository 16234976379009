import { gql } from "@apollo/client";
import { SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT } from "./fragments";

export const LIST_QUOTE_PRINT_TEMPLATES = gql`
  query($salesQuoteId: ID) {
    listSalesQuotePrintTemplates(salesQuoteId: $salesQuoteId) {
      ...SalesQuotePrintTemplateFragment
    }
  }
  ${SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT}
`;

export const PRINT_SALES_QUOTE = gql`
  query($salesQuoteId: ID!) {
    printSalesQuoteTemplate(salesQuoteId: $salesQuoteId) {
      _id
      pdf
    }
  }
`;
