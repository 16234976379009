import { TFunction } from "i18next";

export class ValidationMessagesUtils {
  static createRequiredMessage(t: TFunction, fieldName: string) {
    return t("errors.required", { fieldName: t(fieldName) });
  }

  static createNumberMessage(t: TFunction, fieldName: string) {
    return t("errors.number", { fieldName: t(fieldName) });
  }

  static createMaxEqualMessageCurrency(
    t: TFunction,
    fieldName: string,
    max: number
  ) {
    return t("errors.maxEqualMessage", {
      fieldName: t(fieldName),
      max: t("common.currency", { amount: max }),
    });
  }

  static createMaxNumberMessage(t: TFunction, fieldName: string, max: number) {
    return t("errors.maxLengthNumber", { fieldName: t(fieldName), max });
  }

  static createMinEqualMessageCurrency(
    t: TFunction,
    fieldName: string,
    min: number
  ) {
    return t("errors.minEqualMessage", {
      fieldName: t(fieldName),
      min: t("common.currency", { amount: min }),
    });
  }

  static createInvalidPhoneNumberMessage(t: TFunction) {
    return t("errors.invalidPhoneFormat");
  }
}

export class ValidationRules {
  static getPostcodeRules = (yup: any, t: TFunction) => {
    return yup
      .string()
      .nullable()
      .matches(
        /^[0-9]{4}$/,
        ValidationMessagesUtils.createMaxNumberMessage(
          t,
          "contacts.postcode",
          4
        )
      );
  };

  static getPhoneValidationRules = (yup: any, t: TFunction) => {
    return yup
      .string()
      .matches(
        /^[-+()0-9\s]+$/,
        ValidationMessagesUtils.createInvalidPhoneNumberMessage(t)
      );
  };
}
