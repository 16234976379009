import React from "react";
import Icon from "../../icons/Icon";
import classNames from "classnames";
import { isEmpty, chain } from "lodash";
import { CategorySelectorCosting } from "./index";
import {
  calcCategoryClientTotal,
  calcCategoryRealTotal,
  calcCategoryTotal,
  calcWithGST,
} from "../../../utils/calculations";
import { useTranslation } from "react-i18next";
import { JobCostingItem } from "../../../models/job";
import { CategoryCostingItem } from "../../../models/costing";
import { ConnectDragSource } from "react-dnd";
import { EnumSalesQuoteCategoryStatus } from "../../../models/salesQuote";

type CostingCategoryItemProps = {
  isSelected: boolean;
  category: CategorySelectorCosting;
  trueCostTotal?: boolean;
  itemsForQuote?: JobCostingItem[] | CategoryCostingItem[];
  onDelete?: (id: string) => void;
  onCopy?: (id: string) => void;
  onClick?: (category: CategorySelectorCosting) => void;
  dragRef?: ConnectDragSource;
  showPricesIncGst?: boolean;
  markupPercent?: number;
};

const CostingCategoryItem: React.FC<CostingCategoryItemProps> = (props) => {
  const { t } = useTranslation();

  const {
    isSelected,
    trueCostTotal,
    category,
    itemsForQuote,
    onDelete,
    onCopy,
    onClick,
    dragRef,
    showPricesIncGst,
    markupPercent = 0,
  } = props;

  const getItemClass = (): string => {
    return classNames("quote-costing-list-item", {
      "costing-category-selected": isSelected,
    });
  };

  const itemTotals = React.useMemo(() => {
    const items = category.costings || category.items;

    if (isEmpty(items)) {
      return "";
    }
    let total = trueCostTotal
      ? calcCategoryRealTotal(category.items)
      : calcCategoryClientTotal(
          items,
          category.margin_amount,
          markupPercent,
          showPricesIncGst
        );
    if (category.status === EnumSalesQuoteCategoryStatus.NOT_USED) {
      total = 0;
    }
    return t("common.currency", {
      amount: total,
    });
  }, [category, trueCostTotal, showPricesIncGst, markupPercent]);

  const selectedForQuoteTotal = React.useMemo(() => {
    if (!itemsForQuote?.length) return null;

    const items = category.costings || category.items;

    const selectedCount = chain(items)
      .intersectionBy(itemsForQuote, (item) => item?._id)
      .value().length;

    if (!selectedCount) return null;

    return `(${selectedCount})`;
  }, [category, itemsForQuote]);

  const handleDelete = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      onDelete && onDelete(category._id);
    },
    [onDelete, category]
  );

  const handleCopy = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onCopy && onCopy(category._id);
    },
    [onCopy, category._id]
  );

  const handleClick = React.useCallback(() => {
    return onClick && onClick(category);
  }, [onClick, category]);

  const statusIcon = React.useMemo(() => {
    switch (category.status) {
      case EnumSalesQuoteCategoryStatus.COMPLETE:
        return <Icon className="complete-icon" name="check_circle" outlined />;
      case EnumSalesQuoteCategoryStatus.INCOMPLETE:
        return (
          <Icon className="incomplete-icon" name="error_outline" outlined />
        );
      case EnumSalesQuoteCategoryStatus.NOT_USED:
        return <Icon className="hidden-icon" name="visibility_off" outlined />;
    }
    return null;
  }, [category]);

  return (
    <div className={getItemClass()} onClick={handleClick} ref={dragRef}>
      <div className="item-name">
        <>
          <div className="icon-container">{statusIcon}</div>
          {category.name} {selectedForQuoteTotal}
        </>
      </div>
      <div className="right-side">
        <div className="d-flex flex-column">
          <div className="item-total">{itemTotals}</div>
        </div>
        {!!onCopy && (
          <div className="copy-action mr-1" onClick={handleCopy}>
            <Icon name="content_copy" outlined />
          </div>
        )}
        {!category.is_locked && !!onDelete && (
          <div className="remove-action" onClick={handleDelete}>
            <Icon name="clear" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CostingCategoryItem;
