import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ExpandedFieldOptions } from "./utils";
import Dropdown, { DropdownItem } from "../dropdown";
import { chain, isArray } from "lodash";

type CartTableExpandedFieldProps = {
  data: any[];
  fieldOptions: ExpandedFieldOptions<any>;
};

const TableExpandedField: React.FC<CartTableExpandedFieldProps> = ({
  data,
  fieldOptions,
}) => {
  const { t } = useTranslation();

  const [showDropdown, setDropdownVisibility] = useState(false);

  const toggleDropdown = React.useCallback(() => {
    setDropdownVisibility(!showDropdown);
  }, [showDropdown]);

  const options: DropdownItem[] = React.useMemo(() => {
    return chain(data)
      .map((item) => ({
        ...item,
        label: item[fieldOptions.expandedSubFiled],
        onClick: () => {
          fieldOptions.onFieldClick && fieldOptions.onFieldClick(item);
        },
      }))
      .value();
  }, [data, fieldOptions]);

  const label: string = React.useMemo(() => {
    if (fieldOptions.getLabel && isArray(data)) {
      return fieldOptions.getLabel(data.length);
    }
    return t("common.itemsNumber", { number: 0 });
  }, [data, fieldOptions, t]);

  return (
    <Dropdown
      menuWidth="auto"
      light
      iconFirst
      isVisible={showDropdown}
      handleToggle={toggleDropdown}
      label={label}
      outlined
      id={fieldOptions.dropdownId}
      items={options}
      isDisabled={!isArray(data) || (isArray(data) && data.length <= 0)}
    />
  );
};

export default TableExpandedField;
