import React from "react";
import Icon from "../../icons/Icon";
import classNames from "classnames";
import { SpecificationCategory } from "../../../models/specification";
import { ConnectDragSource } from "react-dnd";

type SpecificationCategoryItemProps = {
  isSelected: boolean;
  category: SpecificationCategory;
  onDelete?: (id: string) => void;
  onClick?: (category: SpecificationCategory) => void;
  dragRef: ConnectDragSource;
};

const SpecificationCategoryItem: React.FC<SpecificationCategoryItemProps> = (
  props
) => {
  const { isSelected, category, onDelete, onClick, dragRef } = props;

  const getItemClass = (): string => {
    return classNames("quote-specification-list-item", {
      "specification-category-selected": isSelected,
    });
  };

  const handleDelete = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      onDelete && onDelete(category._id);
    },
    [onDelete, category]
  );

  const handleClick = React.useCallback(() => {
    return onClick && onClick(category);
  }, [onClick, category]);

  return (
    <div className={getItemClass()} onClick={handleClick} ref={dragRef}>
      <div className="item-name">{category.name}</div>
      <div className="right-side">
        {!!onDelete && (
          <div className="remove-action" onClick={handleDelete}>
            <Icon name="clear" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecificationCategoryItem;
