import classNames from "classnames";
import React from "react";
import { Container } from "react-bootstrap";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { isEmpty, isString } from "lodash";

import GraphQLClient from "../../../graphql";
import { logout } from "../../../redux/authentication/actions";
import Header from "../../../components/dashboard/header";
import Sidebar from "../../../components/dashboard/sidebar";
import { SidebarNavItem } from "../../../components/dashboard/sidebar/SidebarNavListItem";
import {
  createNavItems,
  findItemById,
} from "../../../components/dashboard/sidebar/utils/navigation-items";
import { createAffiliateNavItems } from "../../../components/dashboard/sidebar/utils/affiliate-navigation-items";
import { createSubcontractorNavItems } from "../../../components/dashboard/sidebar/utils/subcontractor-navigation-items";
import { createAdminNavItems } from "../../../components/dashboard/sidebar/utils/admin-navigation-items";
import { createClientNavItems } from "../../../components/dashboard/sidebar/utils/client-navigation-items";
import { RootReducerState } from "../../../redux/reducer";
import {
  DashboardBackNavigationOptions,
  DashboardContext,
  DashboardContextValue,
  DashboardNavigationContext,
  DashboardSearchOptions,
} from "./DashboardContext";

import Context, { SiteContext } from "../../../siteContext";

import { UserPayload } from "../../../graphql/types/models/auth";

import "./styles.scss";
import ConfirmDialog from "../../../components/confirm-dialog";
import Tour from "../../../components/tour";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import SidebarExpandButton from "../../../components/dashboard/sidebar/SidebarExpandButton";

type DashboardLayoutProps = RouteComponentProps &
  React.PropsWithChildren<{
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    user: UserPayload | null;
    logout: () => void;
  }>;

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  isAuthenticated,
  isAuthenticating,
  user,
  children,
  history,
  logout,
}) => {
  const {
    isUser,
    isSubcontractor,
    isAdmin,
    isClient,
    isAffiliate,
  } = React.useContext(Context) as SiteContext;
  const [isSideMenuExpanded, setSideMenuExpanded] = React.useState(false);
  const [isActiveExpandButton, setIsExpandButton] = React.useState(false);
  const [isSidebarMouseEnter, setIsSidebarMouseEnter] = React.useState(false);
  const [showLogoutDialog, setLogoutDialogVisibility] = React.useState(false);
  const [
    navigationContext,
    setNavigationContext,
  ] = React.useState<DashboardNavigationContext | null>(null);
  const [navigationItems, setNavigationItems] = React.useState<
    SidebarNavItem[]
  >([]);
  const [
    navigationItem,
    setNavigationItem,
  ] = React.useState<SidebarNavItem | null>(null);
  const [
    searchOptions,
    setSearchOptions,
  ] = React.useState<DashboardSearchOptions | null>(null);
  const [
    backNavigationOptions,
    setBackNavigationOptions,
  ] = React.useState<DashboardBackNavigationOptions | null>(null);

  const { t } = useTranslation();

  const expandButtonKey = "isExpandButtonActive";

  const {
    storedValue: storedExpandButton,
    setStoredValue: setStoreExpandButton,
  } = useLocalStorage(expandButtonKey, {
    isActiveExpandButton,
  });

  React.useEffect(() => {
    if (storedExpandButton) {
      const { isActiveExpandButton } = storedExpandButton;
      if (isActiveExpandButton) setSideMenuExpanded(isActiveExpandButton);
      setIsExpandButton(isActiveExpandButton);
    } else {
      setStoreExpandButton({ isActiveExpandButton });
    }
  }, []);

  React.useEffect(() => {
    if (isAffiliate) {
      setNavigationItems(createAffiliateNavItems(t, navigationContext));
    } else if (isSubcontractor) {
      setNavigationItems(createSubcontractorNavItems(t, navigationContext));
    } else if (isAdmin) {
      setNavigationItems(createAdminNavItems(t, navigationContext));
    } else if (isClient) {
      setNavigationItems(createClientNavItems(t, navigationContext));
    } else {
      setNavigationItems(createNavItems(t, navigationContext, user));
    }
  }, [
    t,
    navigationContext,
    isSubcontractor,
    isAdmin,
    isUser,
    isClient,
    isAffiliate,
    user,
  ]);

  React.useEffect(() => {
    if (!navigationItem || !navigationItem.url) return;
    const hasItems = !!navigationItem.items && !isEmpty(navigationItem.items);

    !navigationItem.initial && !hasItems && history.push(navigationItem.url);
  }, [navigationItem, history]);

  const handleSetNavigationItem = React.useCallback(
    (item: SidebarNavItem | string, initial?: boolean) => {
      let navItem: SidebarNavItem | null = null;

      if (isString(item)) {
        navItem = findItemById(navigationItems, item) || null;
      } else {
        navItem = item;
      }

      if (navItem?.isLogout) {
        return setLogoutDialogVisibility(true);
      }
      if (navItem?.defaultItemId && navItem?.items) {
        navItem = findItemById(navItem?.items, navItem?.defaultItemId) || null;
      }

      navItem && (navItem.initial = initial);

      setNavigationItem(navItem);
    },
    [navigationItems]
  );

  const closeConfirmDialog = React.useCallback(() => {
    setLogoutDialogVisibility(false);
  }, []);

  const submitLogout = React.useCallback(() => {
    GraphQLClient.setToken("");
    GraphQLClient.clearStore();
    logout();
  }, [logout]);

  const dashboardContext: DashboardContextValue = {
    navigationContext,
    setNavigationContext,
    isSideMenuExpanded,
    setSideMenuExpanded,
    navigationItems,
    navigationItem,
    setNavigationItem: handleSetNavigationItem,
    searchOptions,
    setSearchOptions,
    backNavigationOptions,
    setBackNavigationOptions,
  };

  const handleOnExpandButton = React.useCallback(() => {
    if (!isSideMenuExpanded) {
      setSideMenuExpanded(true);
    }
    if (isSideMenuExpanded && isActiveExpandButton) {
      setSideMenuExpanded(false);
    }

    setIsExpandButton(!isActiveExpandButton);
    setStoreExpandButton({ isActiveExpandButton: !isActiveExpandButton });
  }, [isActiveExpandButton, isSideMenuExpanded, setStoreExpandButton]);

  const handleExpandButtonMouseEnter = React.useCallback(
    () => setSideMenuExpanded(isSideMenuExpanded),
    [isSideMenuExpanded]
  );

  const handleDashboardMouseEnter = React.useCallback(() => {
    if (!isSideMenuExpanded || isActiveExpandButton) return;
    setSideMenuExpanded(false);
  }, [isSideMenuExpanded, isActiveExpandButton]);

  if (isAuthenticating) {
    return <div>Loading</div>;
  }

  // if (!isAuthenticated) {
  //   return <Redirect to="/sign-in" />;
  // }

  return (
    <DashboardContext.Provider value={dashboardContext}>
      <Container fluid className="dashboard">
        <Sidebar
          isActiveExpandButton={isActiveExpandButton}
          setIsSidebarMouseEnter={setIsSidebarMouseEnter}
        />
        <main
          className={classNames("dashboard-main", {
            "side-menu-expanded": isSideMenuExpanded,
          })}
        >
          <SidebarExpandButton
            expandButtonMouseEnter={handleExpandButtonMouseEnter}
            isActiveExpandButton={isActiveExpandButton}
            isSideMenuExpanded={isSideMenuExpanded}
            isSidebarMouseEnter={isSidebarMouseEnter}
            onExpandButton={handleOnExpandButton}
          />
          <Header />
          <div
            onMouseEnter={handleDashboardMouseEnter}
            className="dashboard-content"
          >
            {children}
          </div>
        </main>
        <ConfirmDialog
          title={t("authentication.logout")}
          show={showLogoutDialog}
          onClose={closeConfirmDialog}
          onSubmit={submitLogout}
          confirm={t("common.yes")}
          cancel={t("common.no")}
        >
          <span className="field-text">
            {t("authentication.logoutMessage")}
          </span>
        </ConfirmDialog>
        {isUser && <Tour setSideMenuExpanded={setSideMenuExpanded} />}
      </Container>
    </DashboardContext.Provider>
  );
};

const mapStateToProps = (state: RootReducerState) => ({
  user: state.authentication.user,
  isAuthenticated: state.authentication.isAuthenticated,
  isAuthenticating: state.authentication.isAuthenticating,
});

export default connect(mapStateToProps, { logout })(
  withRouter(DashboardLayout)
);
