import { gql } from "@apollo/client";
import { CHECKLIST_CATEGORY_FRAGMENT, CHECKLIST_FRAGMENT } from "./fragments";

export const UPDATE_CHECKLIST_CATEGORY_ORDER = gql`
  mutation(
    $entityId: ID!
    $entityType: EnumChecklistEntityType!
    $checklistCategoryOrder: [ChecklistCategoryOrderInput]!
  ) {
    updateChecklistCategoryOrder(
      entityId: $entityId
      entityType: $entityType
      checklistCategoryOrder: $checklistCategoryOrder
    ) {
      ...ChecklistCategoryFragment
    }
  }
  ${CHECKLIST_CATEGORY_FRAGMENT}
`;

export const CREATE_UPDATE_CHECKLIST_CATEGORY = gql`
  mutation CreateUpdateChecklistCategory(
    $checklistCategory: ChecklistCategoryInput!
  ) {
    createUpdateChecklistCategory(checklistCategory: $checklistCategory) {
      ...ChecklistCategoryFragment
    }
  }
  ${CHECKLIST_CATEGORY_FRAGMENT}
`;

export const DELETE_CHECKLIST_CATEGORY = gql`
  mutation DeleteChecklistCategory(
    $entityId: ID!
    $entityType: EnumChecklistEntityType!
    $categoryId: ID!
  ) {
    deleteChecklistCategory(
      entityId: $entityId
      entityType: $entityType
      categoryId: $categoryId
    ) {
      ...ChecklistCategoryFragment
    }
  }
  ${CHECKLIST_CATEGORY_FRAGMENT}
`;

export const UPDATE_CHECKLIST_ORDER = gql`
  mutation(
    $entityId: ID!
    $entityType: EnumChecklistEntityType!
    $categoryId: ID!
    $checklistOrder: [ChecklistOrderInput]!
  ) {
    updateChecklistOrder(
      entityId: $entityId
      entityType: $entityType
      categoryId: $categoryId
      checklistOrder: $checklistOrder
    ) {
      ...ChecklistFragment
    }
  }
  ${CHECKLIST_FRAGMENT}
`;

export const CREATE_UPDATE_CHECKLIST = gql`
  mutation($checklist: ChecklistInput!) {
    createUpdateChecklist(checklist: $checklist) {
      ...ChecklistFragment
    }
  }
  ${CHECKLIST_FRAGMENT}
`;

export const DELETE_CHECKLIST = gql`
  mutation(
    $entityId: ID!
    $entityType: EnumChecklistEntityType!
    $categoryId: ID!
    $checklistId: ID!
  ) {
    deleteChecklist(
      entityId: $entityId
      entityType: $entityType
      categoryId: $categoryId
      checklistId: $checklistId
    ) {
      ...ChecklistFragment
    }
  }
  ${CHECKLIST_FRAGMENT}
`;

export const TOGGLE_CHECKLIST_STATUS = gql`
  mutation(
    $entityId: ID!
    $entityType: EnumChecklistEntityType!
    $categoryId: ID!
    $checklistId: ID!
    $isComplete: Boolean!
  ) {
    toggleChecklistStatus(
      entityId: $entityId
      entityType: $entityType
      categoryId: $categoryId
      checklistId: $checklistId
      isComplete: $isComplete
    ) {
      ...ChecklistFragment
    }
  }
  ${CHECKLIST_FRAGMENT}
`;

export const IMPORT_CHECKLIST_FROM_TEMPLATE = gql`
  mutation(
    $entityId: ID!
    $entityType: EnumChecklistEntityType!
    $templateId: ID!
  ) {
    importChecklistFromTemplate(
      entityId: $entityId
      entityType: $entityType
      templateId: $templateId
    ) {
      ...ChecklistCategoryFragment
    }
  }
  ${CHECKLIST_CATEGORY_FRAGMENT}
`;

export const CREATE_CHECKLIST_TEMPLATE = gql`
  mutation($template: TodoChecklistTemplateInput!) {
    createUpdateChecklistTemplate(template: $template) {
      _id
      name
      is_deleted
      categories {
        _id
        name
        is_deleted
        order
        items {
          _id
          task
          order
          is_deleted
        }
      }
    }
  }
`;
