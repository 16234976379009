import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_JOB = gql`
  mutation($jobId: ID, $job: JobInput) {
    createUpdateJob(jobId: $jobId, job: $job) {
      ...JobFragment
    }
  }
  ${JOB_FRAGMENT}
`;

export const DELETE_JOB = gql`
  mutation($jobId: ID!) {
    deleteJob(jobId: $jobId) {
      ...JobFragment
    }
  }
  ${JOB_FRAGMENT}
`;

export const ARCHIVE_JOB = gql`
  mutation($jobId: ID!) {
    archiveJob(jobId: $jobId) {
      ...JobFragment
    }
  }
  ${JOB_FRAGMENT}
`;
export const UNARCHIVE_JOB = gql`
  mutation($jobId: ID!) {
    unarchiveJob(jobId: $jobId) {
      ...JobFragment
    }
  }
  ${JOB_FRAGMENT}
`;
