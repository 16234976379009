import { WithT } from "i18next";
import { FormikProps } from "formik";
import React from "react";
import { Col, Form, Row, Container } from "react-bootstrap";

export type ForgetPasswordFormValues = {
  email: string;
};

type ForgetPasswordFormBodyProps = WithT &
  Pick<
    FormikProps<ForgetPasswordFormValues>,
    "values" | "errors" | "touched" | "handleChange" | "handleBlur"
  >;

const ForgetPasswordFormBody: React.FC<ForgetPasswordFormBodyProps> = ({
  t,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <Container fluid className="m-0 p-0 mw-100">
      <Row>
        <Col xs={12}>
          <span className="field-text text-wrap">
            {t("authentication.resetPasswordInfo")}
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="form-input-label">
              {t("authentication.emailAddress")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="email"
              isInvalid={touched.email && !!errors.email}
              value={values.email}
              className="form-input"
              type="email"
              placeholder={t("placeholders.emailAddress")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgetPasswordFormBody;
