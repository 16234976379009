import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createQuoteRequestSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "quoteRequest.title")
      ),
    due_date: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "quoteRequest.due_date"
        )
      ),
    scope: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "quoteRequest.proposal"
        )
      ),
    suppliers: yup
      .array()
      .min(1)
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "quoteRequest.subcontractors"
        )
      )
      .of(
        yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "quoteRequest.subcontractors"
            )
          )
      ),
  });
}
