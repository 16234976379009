import React from "react";
import classNames from "classnames";

export type DashboardCardFooterProps = {
  className?: string;
};

const DashboardCardFooter: React.FC<DashboardCardFooterProps> = ({
  children,
  className,
}) => {
  return <div className={classNames("footer", className)}>{children}</div>;
};

export default DashboardCardFooter;
