import React from "react";
import { map } from "lodash";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import Icon from "../icons/Icon";

type CheckListProps = {
  items: string[];
};

const CheckList: React.FC<CheckListProps> = (props) => {
  const { items } = props;

  const { t } = useTranslation();

  const renderItem = () => {
    return map(items, (item, index) => (
      <div key={index} className="check-list-item">
        <Icon name="stop_circle" className="check-list-icon" />
        <span className="field-text">{t(item)}</span>
      </div>
    ));
  };

  return <div className="check-list">{renderItem()}</div>;
};

export default CheckList;
