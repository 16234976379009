import { gql } from "@apollo/client";

export const DELETE_SUPPLIER = gql`
  mutation DeleteSupplier($id: ID) {
    deleteSupplier(supplierId: $id) {
      _id
    }
  }
`;

export const CREATE_UPDATE_SUPPLIER = gql`
  mutation CreateUpdateSupplier($supplier: SupplierInput) {
    createUpdateSupplier(supplier: $supplier) {
      _id
      abn
      contact_person
      email
      address
      address2
      business_name
      state
      city
      fax_number
      phone_number
      mobile
      phone_secondary
      postcode
      category {
        _id
        name
      }
    }
  }
`;
