import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { Form } from "react-bootstrap";
import { FormikHelpers } from "formik";

import CreateEntityModal from "../../modals/create-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { createUpdateSpecificationField } from "../specification-create-add-modal/utils";
import createSpecificationShema from "../specification-create-add-modal/CreateSpecificationSchema";
import { CreateUpdateSpecCategoryModalPayload } from "../../../models/specification";
import SelectInput, { SelectOption } from "../../generic-form/inputs/select";

import { CostingCategoryListItem } from "../../../models/salesQuote";
import { SalesQuoteCategoryListResponse } from "../../../graphql/types/models/category";
import { LIST_SALES_QUOTE_CATEGORIES } from "../../../graphql/queries/category/queries";

import "./styles.scss";

type SpecImportFromCostingModalProps = {
  salesQuoteId: string;
  show: boolean;
  onSubmit: (data: CreateUpdateSpecCategoryModalPayload) => void;
  onClose: () => void;
};

const SpecImportFromCostingModal: React.FC<SpecImportFromCostingModalProps> = (
  props
) => {
  const { salesQuoteId, show, onSubmit, onClose } = props;

  const { t } = useTranslation();

  const [
    selectedCategory,
    setSelectedCategory,
  ] = React.useState<CostingCategoryListItem | null>(null);
  const [importAnother, setImportAnother] = React.useState(false);

  const { data: quoteCategories } = useQuery<SalesQuoteCategoryListResponse>(
    LIST_SALES_QUOTE_CATEGORIES,
    {
      variables: { salesQuoteId },
      fetchPolicy: "cache-and-network",
    }
  );

  const categories = quoteCategories?.listSalesQuoteCostingCategories;

  const categoryOptions = React.useMemo<SelectOption[]>(() => {
    return (
      categories?.map((category) => ({
        label: category.name,
        value: category._id,
      })) ?? []
    );
  }, [categories]);

  const handleChangeCategory = React.useCallback(
    (categoryId: string | string[]) => {
      const selectedCategory = categories?.find(
        (category) => category._id === categoryId
      );

      if (selectedCategory) {
        setSelectedCategory(selectedCategory);
      }
    },
    [categories]
  );

  const costingCategorySelect = React.useMemo(
    () => (
      <Form.Group>
        <Form.Label className="form-input-label">
          {t("specifications.selectCostingCategory")}
        </Form.Label>
        <SelectInput
          onChange={handleChangeCategory}
          options={categoryOptions}
          value={selectedCategory?._id}
          name={"costing-category"}
          placeholder={t("costing.costingCategory")}
        />
      </Form.Group>
    ),
    [selectedCategory, categoryOptions, handleChangeCategory, t]
  );

  const data = React.useMemo(
    () => ({
      name: selectedCategory?.name || "",
      items: selectedCategory?.costings.map((item) => ({
        name: item.name,
        description: "",
      })),
    }),
    [selectedCategory]
  );

  const formFields = React.useMemo<
    GenericFormFields<CreateUpdateSpecCategoryModalPayload>
  >(() => {
    return selectedCategory ? createUpdateSpecificationField(t) : {};
  }, [t, selectedCategory]);

  const renderLeftFooter = React.useCallback(() => {
    return (
      <Form.Group controlId="importAnotherItem">
        <Form.Check
          checked={importAnother}
          onChange={(event: React.ChangeEvent<any>) =>
            setImportAnother(!importAnother)
          }
          type="checkbox"
          label={t("specifications.importAnotherItem")}
        />
      </Form.Group>
    );
  }, [importAnother, t]);

  const handleClose = React.useCallback(() => {
    setSelectedCategory(null);
    setImportAnother(false);
    onClose();
  }, [onClose]);

  const handleSubmit = React.useCallback(
    (
      data: CreateUpdateSpecCategoryModalPayload,
      helpers?: FormikHelpers<CreateUpdateSpecCategoryModalPayload>
    ) => {
      onSubmit(data);
      helpers?.resetForm();
      setSelectedCategory(null);

      if (!importAnother) handleClose();
      return;
    },
    [importAnother, handleClose, onSubmit]
  );

  return (
    <CreateEntityModal
      className="import-spesification-modal"
      validationSchema={createSpecificationShema(t)}
      title={t("specifications.importSpecFromCosting")}
      show={show}
      data={data}
      onSubmit={handleSubmit}
      onClose={handleClose}
      fields={formFields}
      topRenderer={costingCategorySelect}
      leftFooterRenderer={renderLeftFooter}
    />
  );
};

export default SpecImportFromCostingModal;
