import React from "react";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import { TextInputProps } from "../text";
import "./styles.scss";

const SliderWithTooltip = createSliderWithTooltip(Slider);

export type SliderInputProps = TextInputProps & {
  max?: number;
  min?: number;
  step?: number | null;
  onChange: (value: number) => void;
  value: number;
  showRangeValues?: boolean;
};

const SliderInput: React.FC<SliderInputProps> = (props) => {
  const { step, max = 100, min = 0, onChange, value, showRangeValues } = props;

  const renderRangeContainer = () => {
    if (!showRangeValues) {
      return null;
    }

    return (
      <div className="range-container">
        <div className="field-text">{min}%</div>
        <div className="field-text">{max}%</div>
      </div>
    );
  };

  return (
    <div className="slider-wrapper">
      {renderRangeContainer()}
      <SliderWithTooltip
        max={max}
        className="slider-input"
        step={step}
        min={min}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SliderInput;
