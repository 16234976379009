import React from "react";
import { useLazyQuery } from "@apollo/client";
import { ListTakeoffItemsResponse } from "../graphql/types/models/take-off";
import { LIST_TAKEOFF } from "../graphql/queries/take-off/queries";

export function useGetTakeOffs() {
  const [getTakeOffItems, { data: takeOffItems }] = useLazyQuery<
    ListTakeoffItemsResponse
  >(LIST_TAKEOFF);

  const takeoffs = React.useMemo(
    () => takeOffItems?.listSalesQuoteTakeOff || undefined,
    [takeOffItems]
  );

  return { getTakeOffItems, takeoffs };
}
