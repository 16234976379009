import React, { useState } from "react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import DashboardCardHeader from "../../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../../dashboard/card";
import Dropdown from "../../../dashboard/dropdown";
import "./styles.scss";
import { PriceListItem } from "../../../../models/price-list";
import PriceListCategoryItem from "./PriceListCategoryItem";

export type CategorySelectorPriceList = {
  _id: string;
  name: string;
  items?: PriceListItem[];
};

type CostingCategoriesProps = {
  categories?: CategorySelectorPriceList[] | null;
  onCategorySelect?: (category: CategorySelectorPriceList) => void;
  onAddClick?: () => void;
  onPriceAdjustPress?: () => void;
  onDownloadPress?: () => void;
  onUploadPress?: () => void;
  selectedCategory: CategorySelectorPriceList | null;
  onDelete?: (categoryId: string) => void;
  onPrint?: () => void;
};

const PriceListCategories: React.FC<CostingCategoriesProps> = (props) => {
  const { t } = useTranslation();
  const {
    categories,
    onCategorySelect,
    selectedCategory,
    onAddClick,
    onDelete,
    onPriceAdjustPress,
    onDownloadPress,
    onUploadPress,
    onPrint,
  } = props;

  const [dropdownShown, setDropdownShown] = useState(false);

  const renderCategories = () => {
    return (
      <div className="price-list-category-list">
        {map(categories, (category) => (
          <PriceListCategoryItem
            key={category._id}
            category={category}
            onDelete={onDelete}
            onClick={onCategorySelect}
            isSelected={category._id === selectedCategory?._id}
          />
        ))}
      </div>
    );
  };

  const dropdownItems = React.useMemo(() => {
    return [
      {
        id: "upload",
        label: t("priceList.uploadPriceList"),
        icon: "upload",
        name: "upload",
        outlined: true,
        onClick: onUploadPress,
      },
      {
        id: "download",
        label: t("priceList.downloadPriceList"),
        icon: "download",
        name: "download",
        outlined: true,
        onClick: onDownloadPress,
      },
      {
        id: "print",
        label: t("priceList.printPriceList"),
        icon: "print",
        name: "print",
        outlined: true,
        onClick: onPrint,
      },
      {
        id: "price",
        label: t("priceList.adjustPrice"),
        icon: "attach_money",
        name: "price",
        outlined: true,
        onClick: onPriceAdjustPress,
      },
    ];
  }, [onPriceAdjustPress]);

  const toggleDropdown = React.useCallback(() => {
    setDropdownShown(!dropdownShown);
  }, [dropdownShown]);

  return (
    <DashboardCard className="price-list-categories">
      <DashboardCardHeader className="text-capitalize justify-content-between">
        <div className="d-flex align-items-center">
          {t("common.categories")}
        </div>

        <Dropdown
          isVisible={dropdownShown}
          handleToggle={toggleDropdown}
          label={t("common.options")}
          icon="expand_more"
          size="250px"
          id="price-list-dropdown"
          items={dropdownItems}
        />
      </DashboardCardHeader>
      <DashboardCardBody>
        <Button className="button info add-category" block onClick={onAddClick}>
          {t("quotes.addCategory")}
        </Button>
        {renderCategories()}
      </DashboardCardBody>
    </DashboardCard>
  );
};

export default PriceListCategories;
