import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import { concat, filter, findIndex } from "lodash";
import {
  CreateUpdateMemberResponse,
  ListMembersResponse,
} from "../models/members";
import { LIST_MEMBERS } from "./queries";
import { TEAM_MEMBER } from "./fragments";

const getMembersList = (cache: ApolloCache<any>) => {
  const members = cache.readQuery<ListMembersResponse>({
    query: LIST_MEMBERS,
  });
  return members?.listMembers;
};

export const handleMemberAdd: MutationUpdaterFn<CreateUpdateMemberResponse> = (
  cache,
  response
) => {
  const addedMember = response.data?.addedMember;
  const membersList = getMembersList(cache);
  if (!addedMember || !membersList) {
    return;
  }

  const index = findIndex(membersList, { _id: addedMember._id });
  if (index >= 0) {
    const id = `TeamMember:${addedMember?._id}`;
    cache.writeFragment({
      id,
      fragment: TEAM_MEMBER,
      data: addedMember,
    });
  } else {
    cache.writeQuery<ListMembersResponse>({
      query: LIST_MEMBERS,
      data: {
        listMembers: concat(membersList, addedMember),
      },
    });
  }
};

export const handleMemberRemove = (memberId: string) => (
  memberList?: ListMembersResponse
) => {
  const listMembers = memberList?.listMembers;

  return {
    listMembers: filter(listMembers, (member) => member._id !== memberId),
  };
};
