import React from "react";
import { Form } from "react-bootstrap";

export function useCreateAnother(label: string) {
  const [shouldCreateAnother, setCreateAnother] = React.useState(false);

  const renderCreateAnother = React.useCallback(() => {
    return (
      <Form.Group controlId="createAnotherTask">
        <Form.Check
          checked={shouldCreateAnother}
          onChange={(event: React.ChangeEvent<any>) =>
            setCreateAnother(!shouldCreateAnother)
          }
          type="checkbox"
          label={label}
        />
      </Form.Group>
    );
  }, [shouldCreateAnother, label]);

  return { shouldCreateAnother, renderCreateAnother, setCreateAnother };
}
