import { gql } from "@apollo/client";
import { TODOLIST_FRAGMENT, TODO_FRAGMENT } from "./fragments";

export const LIST_TODO_LIST = gql`
  query listTodoList($filter: TodoListFilterInput) {
    listTodoList(filter: $filter) {
      ...TodoListFragment
    }
  }
  ${TODOLIST_FRAGMENT}
`;

export const GET_TODO_LIST = gql`
  query getTodoList($todoListId: ID!) {
    getTodoList(todoListId: $todoListId) {
      ...TodoFragment
    }
  }
  ${TODO_FRAGMENT}
`;

export const GET_TODO_LIST_TAGS = gql`
  query gistTodoListTags {
    listTodoListTags {
      _id
      name
    }
  }
`;

export const GET_CHECKLIST_TEMPLATES = gql`
  query ListChecklistTemplates($type: EnumChecklistTemplateType) {
    listChecklistTemplates(type: $type) {
      _id
      name
      is_deleted
      type
      categories {
        _id
        name
        is_deleted
        order
        templateId
        items {
          _id
          task
          order
          categoryId
          is_deleted
        }
      }
    }
  }
`;

export const GET_CHECKLIST_TEMPLATE = gql`
  query GetChecklistTemplate($templateId: ID!) {
    getChecklistTemplate(templateId: $templateId) {
      _id
      name
      is_deleted
      type
      categories {
        _id
        name
        is_deleted
        order
        templateId
        items {
          _id
          task
          order
          categoryId
          is_deleted
        }
      }
    }
  }
`;
