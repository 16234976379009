import { gql } from "@apollo/client";
export const ASSEMBLY_FRAGMENT = gql`
  fragment AssemblyFragment on Assembly {
    _id
    name
    UOM
    total
    items {
      UOM
      cost
      name
      total
      quantity
      raw_quantity
      wastage
      rounding
    }
  }
`;
