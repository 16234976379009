import { TFunction } from "i18next";
import { chain, map } from "lodash";
import { CreateTodoListPayload } from "../../../models/task";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { TeamMember } from "../../../models/team-member";
import { CreateUpdateLead, LeadStage } from "../../../models/leads";
import { ContactListItem } from "../../../graphql/types/models/client";
import { getFullName } from "../../../utils/text";
import { STATES_OPTIONS } from "../../../utils/options";

export const createLeadField = (
  t: TFunction,
  team: TeamMember[],
  listStages: LeadStage[],
  contacts: ContactListItem[],
  onClientModalOpen: () => void,
  onPlaceSelect: UpdateTextInputFn
): GenericFormFields<CreateTodoListPayload> => {
  const teamOptions = map(team, (member) => ({
    value: member._id,
    label: [member.first_name, member.last_name].join(" "),
  }));

  const contactOptions = chain(contacts)
    .map((contact) => ({
      value: contact._id,
      label: getFullName(contact),
    }))
    .value();

  const stageOptions = map(listStages, (job) => ({
    value: job._id,
    label: job.name,
  }));

  const fields: GenericFormFields<CreateUpdateLead> = {
    stage: {
      type: "select",
      label: t("leads.stage"),
      placeholder: t("schedule.select"),
      valueKey: "stage",
      autocompleteProps: {
        options: stageOptions,
      },
      inputProps: {
        required: true,
      },
    },
    name: {
      type: "text",
      label: t("placeholders.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        required: true,
      },
    },
    description: {
      type: "text",
      controlType: "textarea",
      label: t("placeholders.description"),
      placeholder: t("placeholders.description"),
      valueKey: "description",
    },
    budget: {
      type: "text",
      label: t("placeholders.budget"),
      placeholder: t("placeholders.budget"),
      valueKey: "budget",
    },
    expectedCloseDate: {
      type: "date-picker",
      label: t("leads.expectedCloseDate"),
      placeholder: t("leads.expectedCloseDate"),
      valueKey: "expectedCloseDate",
    },
    assignedTo: {
      type: "creatable-select",
      label: t("common.assignTo"),
      placeholder: t("common.assignTo"),
      valueKey: "assignedTo",
      creatableProps: {
        autoSelectCreatedOption: true,
        isMulti: true,
        options: teamOptions,
      },
      inputProps: {
        required: true,
      },
    },
    contacts: {
      type: "creatable-select",
      label: t("placeholders.contacts"),
      placeholder: t("placeholders.contacts"),
      valueKey: "contacts",
      append: {
        icon: "add",
        onClick: onClientModalOpen,
      },
      creatableProps: {
        autoSelectCreatedOption: true,
        isMulti: true,
        options: contactOptions,
        sortByAsc: true,
      },
      inputProps: {
        required: true,
      },
    },
    address: {
      type: "place-autocomplete",
      label: t("jobs.address"),
      placeholder: t("placeholders.address"),
      valueKey: "address",
      textInputProps: {
        onValueChange: onPlaceSelect,
      },
    },
    location: [
      {
        type: "text",
        label: t("contacts.city"),
        placeholder: t("placeholders.city"),
        valueKey: "city",
        inputProps: {
          type: "text",
        },
      },
      {
        type: "select",
        label: t("contacts.state"),
        placeholder: t("placeholders.state"),
        valueKey: "state",
        autocompleteProps: {
          options: STATES_OPTIONS,
        },
        inputProps: {
          type: "text",
        },
      },
    ],
    postcode: {
      type: "text",
      label: t("contacts.postcode"),
      placeholder: t("placeholders.postcode"),
      valueKey: "postcode",
      inputProps: {
        type: "text",
      },
    },
  };

  return fields;
};
