import classnames from "classnames";
import map from "lodash/map";
import React from "react";
import { Button } from "react-bootstrap";

import { TextInputProps } from "../text";

import "./styles.scss";

export type ButtonSelectInputProps = Omit<TextInputProps, "onChange"> & {
  options: Array<{ value: string; label: string }>;
  onChange: (value: string) => void;
};

const ButtonSelectInput: React.FC<ButtonSelectInputProps> = ({
  value,
  onChange,
  options,
}) => {
  const handleOnClick = React.useCallback(
    (value: string) => () => onChange(value),
    [onChange]
  );

  return (
    <div className="button-select-input">
      {map(options, (option) => {
        const isActive = option.value === value;
        return (
          <Button
            key={option.value}
            className={classnames("button large", {
              primary: isActive,
              info: !isActive,
            })}
            onClick={handleOnClick(option.value)}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
};

export default ButtonSelectInput;
