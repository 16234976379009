import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { UpdateSubProfilePayload } from "../../../models/profile";
import { TFunction } from "i18next";

const createUpdateSubProfileFields = (
  t: TFunction
): GenericFormFields<UpdateSubProfilePayload> => {
  return {
    business_name: {
      type: "text",
      label: t("profile.businessName"),
      placeholder: t("profile.businessName"),
      valueKey: "business_name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    contact_person: {
      type: "text",
      label: t("profile.contactPerson"),
      placeholder: t("profile.contactPerson"),
      valueKey: "contact_person",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    email: {
      type: "text",
      label: t("profile.email"),
      placeholder: t("placeholders.email"),
      valueKey: "email",
      inputProps: {
        type: "text",
        required: true,
      },
    },
  };
};

export default createUpdateSubProfileFields;
