import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import AuthenticationLayout from "../layouts/authentication";
import ResetPasswordForm from "../../components/authentication/reset-password-form";
import { RESET_PASSWORD } from "../../graphql/queries/auth/mutations";
import GraphQLClient from "../../graphql";
import {
  ResetPasswordPayload,
  ResetPasswordResponse,
} from "../../graphql/types/models/auth";
import Context, { SiteContext } from "../../siteContext";
import { SUBCONTRACTOR_RESET_PASSWORD } from "../../graphql/queries/subcontractor/auth/mutations";
import { CLIENT_RESET_PASSWORD } from "../../graphql/queries/client/auth/mutations";
import { notify } from "../../components/notification";
import { AFFILIATE_RESET_PASSWORD } from "../../graphql/queries/affiliate/mutations";

type Params = {
  resetHash: string;
};

const ResetPasswordContainer = () => {
  const match = useRouteMatch<Params>();
  const history = useHistory();
  const { t } = useTranslation();
  const { isSubcontractor, isClient, isAffiliate } = React.useContext(
    Context
  ) as SiteContext;

  React.useEffect(() => {
    GraphQLClient.setToken("");
  }, []);

  const getQueryForRole = React.useMemo(() => {
    if (isClient) return CLIENT_RESET_PASSWORD;
    else if (isSubcontractor) return SUBCONTRACTOR_RESET_PASSWORD;
    else if (isAffiliate) return AFFILIATE_RESET_PASSWORD;
    else return RESET_PASSWORD;
  }, [isSubcontractor, isClient, isAffiliate]);

  const [resetPassword] = useMutation<
    ResetPasswordResponse,
    ResetPasswordPayload
  >(getQueryForRole, {
    onCompleted: () => {
      notify({
        title: t("authentication.resetPassword"),
        content: t("authentication.success.resetPassword"),
      });
      history.push("/sign-in");
    },
    onError: (error) => {
      notify({
        title: t("common.errors.requestFailed"),
        content: error.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (password) => {
      return resetPassword({
        variables: {
          resetHash: match.params.resetHash,
          password,
        },
      });
    },
    [resetPassword, match]
  );

  return (
    <AuthenticationLayout>
      <Helmet title={t("authentication.resetPassword")} />
      <ResetPasswordForm onSubmit={handleSubmit}></ResetPasswordForm>
    </AuthenticationLayout>
  );
};

export default ResetPasswordContainer;
