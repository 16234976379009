import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { compact, omit, filter } from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import {
  ClientType,
  ClientTypeEnum,
  CreateClientPayload,
} from "../../../models/client";
import DetailsCard, {
  DetailsCardAction,
} from "../../../components/dashboard/details-card";
import { DetailsCardDataItem } from "../../../components/dashboard/details-card/DetailsCardList";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardBackNavigation from "../../../components/navigation/DashboardBackNavigation";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CONTACT } from "../../../graphql/queries/client/queries";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  ContactDetailResponse,
  DeleteClientResponse,
} from "../../../graphql/types/models/client";
import {
  CREATE_UPDATE_CLIENT,
  DELETE_CLIENT,
} from "../../../graphql/queries/client/mutations";
import { handleClientDelete } from "../../../graphql/queries/client/utils";
import ClientActivity from "../../../components/contacts/client-activity";
import { Email } from "../../../models/email";
import EMAILS from "../../../__mocks__/emails";
import ConfirmDialog from "../../../components/confirm-dialog";
import { notify } from "../../../components/notification";
import ContactReminder from "../contacts-reminders";
import { useNote } from "../../../hooks/useNote";
import { NoteEntityType } from "../../../graphql/types/models/note";
import CreateClientModal from "../../../components/contacts/create-client-modal";

type Params = {
  id: string;
};

type ContactsClientContainerProps = {
  emails: Email[];
} & RouteComponentProps<Params>;

const ContactsClientContainer: React.FC<ContactsClientContainerProps> = (
  props
) => {
  const { match, emails = EMAILS } = props;

  const contactId = match.params.id;
  const history = useHistory();

  const { t } = useTranslation();
  const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);

  const { renderNotesCard } = useNote(NoteEntityType.Client, contactId);

  const { data: contactData, loading: contactLoading, error } = useQuery<
    ContactDetailResponse
  >(GET_CONTACT, {
    variables: {
      id: contactId,
    },
  });

  const [deleteContact, { loading: contactDeleting }] = useMutation<
    DeleteClientResponse
  >(DELETE_CLIENT, {
    update: handleClientDelete,
  });

  const [updateClient] = useMutation(CREATE_UPDATE_CLIENT, {
    onCompleted: () => {
      setShowClientModal(false);
    },
  });

  const openDeleteDialog = React.useCallback(() => {
    setDeleteDialogVisibility(true);
  }, []);

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteDialogVisibility(false);
  }, []);

  const [showClientModal, setShowClientModal] = React.useState(false);

  const contactDetails = contactData?.getContactDetails;

  const fullName = contactDetails
    ? `${contactDetails?.first_name} ${contactDetails?.last_name}`
    : "";

  const detailsCardData: DetailsCardDataItem[] = React.useMemo(() => {
    const contact = contactData?.getContactDetails;

    if (contactLoading || !contact) {
      return [];
    }

    return filter(
      [
        {
          icon: "business",
          title: t("contacts.businessName"),
          text: contact.business_name,
        },
        {
          icon: "shield",
          text: contact.abn ? t("company.abnNumberValue", contact) : "",
        },
        {
          icon: "person",
          text: `${contact.first_name} ${contact.last_name}`,
        },
        {
          icon: "label",
          title: t("contacts.phase"),
          text: t(`contacts.${contact.phase}`),
        },
        {
          icon: "room",
          text: compact([
            contact.address,
            contact.address2,
            contact.city,
            contact.state,
            contact.postcode,
          ]).join(", "),
        },
        {
          icon: "mail",
          text: contact.email,
        },
        {
          icon: "local_phone",
          text: compact([contact.phone, contact.phone_secondary]).join(", "),
        },
        {
          icon: "person_pin",
          title: t("contacts.clientType"),
          text:
            contact.type === ClientType.INDIVIDUAL
              ? t("contacts.individual")
              : t("contacts.business"),
        },
      ],
      (r) => !!r.text
    );
  }, [contactLoading, contactData]);

  const handleEditButtonClick = React.useCallback(() => {
    setShowClientModal(true);
  }, []);

  const handleClientModalCancel = React.useCallback(() => {
    setShowClientModal(false);
  }, []);

  const handleClientModalSubmit = React.useCallback(
    async (data: CreateClientPayload) => {
      const preparedData = {
        ...data,
        address: data.address ? data.address : "",
      };
      try {
        await updateClient({
          variables: {
            contact: omit(preparedData, ["jobs", "salesQuotes", "__typename"]),
          },
        });
        notify({
          content: t("contacts.success.updateContact"),
          title: t("contacts.updateContact"),
        });
      } catch (e) {
        notify({
          error: true,
          content: t("contacts.errors.updateContact"),
          title: t("contacts.updateContact"),
        });
      }
    },
    [updateClient]
  );

  const handleContactDelete = React.useCallback(async () => {
    if (!contactId) {
      return;
    }

    try {
      await deleteContact({ variables: { id: contactId } });
      notify({
        title: t("contacts.deleteContact"),
        content: t("contacts.success.deleteContact"),
      });

      history.push("/contacts");
    } catch (e) {
      notify({
        error: true,
        title: t("contacts.deleteContact"),
        content: t("contacts.errors.deleteContact"),
      });
    }
    setDeleteDialogVisibility(false);
  }, [contactId, deleteContact]);

  const detailsCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("common.edit"),
        onClick: handleEditButtonClick,
        className: "button info",
        disabled: contactLoading,
      },
    ],
    [t, contactLoading, handleEditButtonClick]
  );

  if (!!error) {
    return null;
  }

  return (
    <Container className="contacts-client" fluid>
      <DashboardBackNavigation
        routeId={NAVIGATION_ROUTES.CONTACTS_SECTION.CONTACTS}
      />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.CONTACTS_SECTION.CLIENT} />
      <Helmet title={t("navigation.contactsSection.client")} />
      <ConfirmDialog
        disabled={contactDeleting}
        title={t("contacts.deleteContact")}
        show={showDeleteDialog}
        onSubmit={handleContactDelete}
        onClose={closeDeleteDialog}
      >
        <span className="field-text">{t("contacts.deleteContactMessage")}</span>
      </ConfirmDialog>
      {contactData?.getContactDetails && (
        <CreateClientModal
          showDeleteButton={true}
          disabled={contactDeleting}
          onDelete={openDeleteDialog}
          client={contactData?.getContactDetails}
          show={showClientModal}
          onClose={handleClientModalCancel}
          onSubmit={handleClientModalSubmit}
        />
      )}
      {/* <Row className="w-100">
        <Col lg={5} xs={12} className="pb-sm-5 pr-sm-4 reminders">
        </Col>
        <Col lg={7} xs={12} className="pb-sm-5 recent-messages pl-sm-4">
          <Emails emails={emails} title={t("communication.recentMessages")} />
        </Col>
      </Row> */}

      <Row className="w-100">
        <Col lg={4} xs={12} className="pb-sm-5 pb-lg-0 pr-sm-4">
          <DetailsCard
            fullHeight
            title={t("common.details")}
            data={detailsCardData}
            actions={detailsCardActions}
          />
          <ContactReminder
            contactLoading={contactLoading}
            clientName={fullName}
            contactId={contactId}
            clientType={ClientTypeEnum.CONTACT}
          />
        </Col>
        <Col lg={8} xs={12} className="pb-sm-5 pb-lg-0 pl-sm-4">
          <ClientActivity
            contacts={contactData?.getContactDetails}
            contactLoading={contactLoading}
          />
          <div className="pt-sm-5">{renderNotesCard()}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactsClientContainer;
