import React from "react";
import { useQuery } from "@apollo/client";
import { GET_SALES_QUOTE } from "../../graphql/queries/quote/queries";
import { GetSalesQuoteResponse } from "../../graphql/types/models/quote";

export function useSalesQuoteQuery(salesQuoteId?: string) {
  const { data: salesQuoteData, loading } = useQuery<GetSalesQuoteResponse>(
    GET_SALES_QUOTE,
    {
      variables: {
        salesQuoteId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const salesQuote = React.useMemo(() => salesQuoteData?.getSalesQuote, [
    salesQuoteData,
  ]);
  const salesQuotePlans = React.useMemo(
    () => salesQuoteData?.getSalesQuote?.files || [],
    [salesQuoteData]
  );

  return { salesQuote, salesQuotePlans, loading };
}
