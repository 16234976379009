import { ApolloCache, MutationUpdaterFn } from "@apollo/client";

import { GET_JOB_TIMESHEETS } from "./queries";
import {
  JobTimesheetsListResponse,
  JobTimesheetsProcessResponse,
} from "../../types/models/job-timesheet";
import { filter, concat } from "lodash";
import { Timesheet } from "../../../models/timesheet";
import {
  DeleteTimesheetResponse,
  UnprocessTimesheetResponse,
} from "../../types/models/timesheet";

const getJobTimesheetsList = (
  cache: ApolloCache<any>,
  jobId: string,
  isProcessed: boolean
) => {
  const jobTimesheetsResponse = cache.readQuery<JobTimesheetsListResponse>({
    query: GET_JOB_TIMESHEETS,
    variables: {
      jobId,
      isProcessed,
    },
  });

  return jobTimesheetsResponse?.getJobTimesheets;
};

const updateJobTimesheetsListCache = (
  cache: ApolloCache<any>,
  timesheets: Timesheet[],
  jobId: string,
  isProcessed: boolean
) => {
  cache.writeQuery<JobTimesheetsListResponse>({
    query: GET_JOB_TIMESHEETS,
    variables: {
      jobId,
      isProcessed,
    },
    data: {
      getJobTimesheets: timesheets,
    },
  });
};

export const handleTimesheetsProcessing = (
  jobId: string
): MutationUpdaterFn<JobTimesheetsProcessResponse> => (cache, { data }) => {
  const newProcessedTimesheets = data?.processTimesheets;

  const processedTimesheetsList = getJobTimesheetsList(cache, jobId, true);
  const unprocessedTimesheetsList = getJobTimesheetsList(cache, jobId, false);

  if (!newProcessedTimesheets || !unprocessedTimesheetsList) return;

  const timesheets = filter(
    unprocessedTimesheetsList,
    (timesheet) =>
      !newProcessedTimesheets.some(
        (newTimesheet) => newTimesheet._id === timesheet._id
      )
  );

  updateJobTimesheetsListCache(cache, timesheets, jobId, false);

  if (!newProcessedTimesheets || !processedTimesheetsList) return;

  const timesheetsList = concat(
    processedTimesheetsList,
    ...newProcessedTimesheets
  );

  updateJobTimesheetsListCache(cache, timesheetsList, jobId, true);
};

export const handleTimesheetsUnprocessing = (
  jobId: string
): MutationUpdaterFn<UnprocessTimesheetResponse> => (cache, { data }) => {
  const newUnprocessedTimesheets = data?.unprocessTimesheet;

  const processedTimesheetsList = getJobTimesheetsList(cache, jobId, true);
  const unprocessedTimesheetsList = getJobTimesheetsList(cache, jobId, false);

  if (!newUnprocessedTimesheets || !processedTimesheetsList) return;

  const timesheets = filter(
    processedTimesheetsList,
    (timesheet) => newUnprocessedTimesheets._id !== timesheet._id
  );

  updateJobTimesheetsListCache(cache, timesheets, jobId, true);

  if (!newUnprocessedTimesheets || !unprocessedTimesheetsList) return;

  const timesheetsList = concat(
    unprocessedTimesheetsList,
    newUnprocessedTimesheets
  );

  updateJobTimesheetsListCache(cache, timesheetsList, jobId, false);
};

export const handleDeleteJobTimesheet = (
  jobId: string
): MutationUpdaterFn<DeleteTimesheetResponse> => (cache, response) => {
  const deletedTimesheet = response.data?.deleteTimesheet;
  const unprocessedTimesheetsList = getJobTimesheetsList(cache, jobId, false);

  if (!deletedTimesheet || !unprocessedTimesheetsList) return;

  const filteredTimesheets = filter(
    unprocessedTimesheetsList,
    (timesheet) => timesheet._id !== deletedTimesheet._id
  );

  updateJobTimesheetsListCache(cache, filteredTimesheets, jobId, false);
};
