import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { head, map, omit, pick, find, concat, compact, get } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import CategorySelectorCard, {
  CategorySelectorFilter,
  CategorySelectorPayload,
} from "../../../components/category-select-card";
import {
  VariationPayload,
  VariationStatuses,
} from "../../../models/variations";
import VariationCard from "../../../components/variation/variation-card";
import VariationModal from "../../../components/variation/variation-modal";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";
import {
  BUILDER_APPROVE_VARIATION,
  DELETE_VARIATION,
  SEND_VARIATION,
  UNAPPROVE_VARIATION,
} from "../../../graphql/queries/job-variation/mutations";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import {
  GET_VARIATION,
  GET_VARIATION_PREVIEW,
  LIST_VARIATIONS,
} from "../../../graphql/queries/job-variation/queries";
import {
  ApproveVariationResponse,
  DeleteVariationResponse,
  GetVariationPreviewResponse,
  GetVariationResponse,
  ListVariationResponse,
  UnapproveVariationResponse,
} from "../../../graphql/types/models/job-variation";
import { handleVariationDelete } from "../../../graphql/queries/job-variation/utils";
import SendModal from "../../../components/modals/send-modal";
import { ContactListResponse } from "../../../graphql/types/models/client";
import { LIST_CONTACTS } from "../../../graphql/queries/client/queries";
import { SendEmailForm } from "../../../models/email";
import { printBase64Pdf } from "../../../utils/pdf";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import { notify } from "../../../components/notification";
import JobLocationHeader from "../../header/job-location-header";
import ClientHeaderList from "../../header/client-header-list";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import { RouteComponentProps } from "react-router-dom";
import { formatQuoteNumber } from "../../../utils/text";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import { DropdownItem } from "../../../components/dashboard/dropdown";
import TeammateHeaderList from "../../header/teammate-header-list";
import { getVariationBadgeVariant } from "./utils";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useGetTakeOffs } from "../../../hooks/useGetTakeOffs";
import AcceptSignatureDialog, {
  AcceptSignatureDialogRef,
  DigitalSignatureInput,
} from "../../../components/accept-signature-dialog";
import { uploadFiles } from "../../../utils/files";
import { SystemFolderType } from "../../../models/documents";

type Params = {
  id: string;
  var_id?: string;
};

type JobVariations = RouteComponentProps<Params> &
  DashboardContextValue & {
    user: UserPayload | null;
  };

const JobVariations: React.FC<JobVariations> = ({
  navigationContext,
  match,
  user,
}) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const jobId = navigationContext?.job?._id;
  const jobContact = navigationContext?.job?.customer;
  const businessName = navigationContext?.job?.businessName;
  const salesQuoteId = navigationContext?.job?.salesQuote?._id;
  const { var_id: varId } = match.params;
  const [uploadingSignature, setUploadingSignature] = React.useState(false);

  const { t } = useTranslation();

  const client = useApolloClient();

  const { loading: contactsLoading, data: contactsData } = useQuery<
    ContactListResponse
  >(LIST_CONTACTS);

  const [
    getVariation,
    {
      loading: variationLoading,
      data: variationDetails,
      error: variationError,
    },
  ] = useLazyQuery<GetVariationResponse>(GET_VARIATION);

  const [deleteVariation, { loading: variationDeleting }] = useMutation<
    DeleteVariationResponse
  >(DELETE_VARIATION, {
    update: handleVariationDelete(jobId),
  });

  const [approveVariation, { loading: variationApproving }] = useMutation<
    ApproveVariationResponse
  >(BUILDER_APPROVE_VARIATION);

  const [unapproveVariation, { loading: variationUnapproving }] = useMutation<
    UnapproveVariationResponse
  >(UNAPPROVE_VARIATION, {
    onCompleted: () => {
      notify({
        title: t("variations.unapproveVariation"),
        content: t("variations.success.unapproveVariation"),
      });
    },
    onError: (e) => {
      notify({
        error: true,
        title: t("variations.unapproveVariation"),
        content: get(e, "message", t("variations.errors.unapproveVariation")),
      });
    },
  });

  const [variationFilter, setVariationFilter] = useState<
    CategorySelectorFilter
  >({
    id: "",
  });

  const [showVariationsModal, setVariationModalVisibility] = useState(false);
  const [emailTarget, setEmailTarget] = useState<null | string>(null);
  const [isEditMode, setEditMode] = useState(false);
  const [showCc, setShowCc] = React.useState(false);
  const [showBcc, setShowBcc] = React.useState(false);
  const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
  const [deleteTargetId, setDeleteTarget] = useState("");
  const [approveTargetId, setApproveTarget] = useState("");
  const sendRef = React.useRef<ModalDisplayRef>();
  const unapproveRef = React.useRef<ConfirmDialogRef>();
  const acceptSignatureDialogRef = React.useRef<AcceptSignatureDialogRef>(null);

  const isSalesQuoteHasFiles = React.useMemo(
    () => navigationContext?.job?.salesQuote?.files?.length !== 0,
    [navigationContext]
  );

  const closeApproveDialog = React.useCallback(() => {
    acceptSignatureDialogRef.current?.show(false);
    setApproveTarget("");
  }, []);

  const openApproveDialog = React.useCallback((id: string) => {
    acceptSignatureDialogRef.current?.show(true);
    setApproveTarget(id);
  }, []);

  const handleUnapprove = React.useCallback(
    (id: string) => {
      unapproveRef.current?.show(true, () => {
        unapproveVariation({
          variables: {
            jobId,
            variationId: id,
          },
        });
      });
    },
    [unapproveRef, jobId]
  );

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteDialogVisibility(false);
    setDeleteTarget("");
  }, []);

  const openDeleteDialog = React.useCallback((id: string) => {
    setDeleteDialogVisibility(true);
    setDeleteTarget(id);
  }, []);

  const [sendVariation] = useMutation(SEND_VARIATION, {
    onCompleted: () => {
      sendRef.current?.show(false);
      notify({
        title: t("variations.sendEmail"),
        content: t("variations.success.sendVariation"),
      });
      setShowBcc(false);
      setShowCc(false);
    },
    onError: () => {
      notify({
        error: true,
        title: t("variations.sendEmail"),
        content: t("variations.errors.sendVariation"),
      });
      setShowBcc(false);
      setShowCc(false);
    },
  });

  const { data: variations } = useQuery<ListVariationResponse>(
    LIST_VARIATIONS,
    {
      variables: {
        jobId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const variation = React.useMemo(
    () => variationDetails?.getJobGetVariationById,
    [variationDetails]
  );
  const { getTakeOffItems, takeoffs } = useGetTakeOffs();

  React.useEffect(() => {
    if (salesQuoteId) {
      return getTakeOffItems({
        variables: {
          salesQuoteId,
        },
      });
    }
  }, [getTakeOffItems, salesQuoteId]);

  const closeVariationModal = React.useCallback(() => {
    setVariationModalVisibility(false);
    setEditMode(false);
  }, []);

  const openVariationModal = React.useCallback(() => {
    setVariationModalVisibility(true);
  }, []);

  const handleSelectCategory = React.useCallback(
    (filter) => {
      getVariation({
        variables: {
          jobId,
          variationId: filter.id,
        },
      });
      setVariationFilter(filter);
    },
    [jobId]
  );

  const copyMailOptions = React.useMemo(() => {
    return {
      showCc,
      setShowCc,
      showBcc,
      setShowBcc,
      showReplyTo: true,
    };
  }, [showBcc, showCc]);

  const handleSubmit = React.useCallback(
    (variationId) => {
      setVariationModalVisibility(false);
      getVariation({
        variables: {
          jobId,
          variationId: variationId,
        },
      });
      setVariationFilter({
        id: variationId,
      });

      setEditMode(false);
      setVariationModalVisibility(false);
      return null;
    },
    [jobId, setEditMode, setVariationModalVisibility]
  );

  const handleApprove = React.useCallback(
    async (signature: DigitalSignatureInput, file: File) => {
      if (!approveTargetId) {
        return;
      }

      try {
        const result = await approveVariation({
          variables: {
            jobId,
            variationId: approveTargetId,
            signature,
          },
        });
        const resultSignature = result.data?.approve.builderSignature?.file;

        if (resultSignature) {
          setUploadingSignature(true);
          await uploadFiles([resultSignature], [file]);
          setUploadingSignature(false);
        }
      } catch (e) {}

      acceptSignatureDialogRef.current?.show(false);
      setApproveTarget("");
    },
    [approveVariation, jobId, approveTargetId]
  );

  // TODO: move to component/hook for Client version to use
  const variationsCategories: CategorySelectorPayload[] = React.useMemo(() => {
    const data = variations?.getJobGetVariations;
    return map(data, (variation) => ({
      id: variation._id,
      label: formatQuoteNumber(variation.variationNumber, "V"),
      description: compact([
        moment(variation.date).format("D MMMM YYYY"),
        variation.name,
      ]).join(": "),
      rightLabel: t("common.currency", { amount: variation.subTotal }),
      total: variation.subTotal ? variation.subTotal : 0,
      rightBadge: compact([
        variation.progressClaimed
          ? {
              variant: getVariationBadgeVariant(variation),
              label: t("variations.claimed"),
            }
          : null,
        {
          variant: getVariationBadgeVariant(variation),
          label: t(`variations.statuses.${variation.status}`),
        },
      ]),
      orderNumber: formatQuoteNumber(variation.variationNumber, "V"),
    }));
  }, [variations]);

  const handleSendSubmit = React.useCallback(
    (message: SendEmailForm) => {
      return sendVariation({
        variables: {
          jobId,
          variationId: emailTarget,
          message: {
            subject: message.title,
            to: message.to,
            ...(showCc && { cc: message.cc }),
            ...(showBcc && { bcc: message.bcc }),
            ...(message.replyTo && { replyTo: message.replyTo }),
            message: message.message,
            remoteAttachmentId: message.remoteAttachmentId,
          },
        },
      });
    },
    [sendVariation, jobId, emailTarget, showCc, showBcc]
  );

  const handleSendClick = React.useCallback(
    (id: string) => {
      if (!contactsData?.listContacts) {
        return;
      }

      setEmailTarget(id);
      sendRef.current?.show(true);
    },
    [contactsData, sendRef]
  );

  const handleEdit = React.useCallback(() => {
    setEditMode(true);
    setVariationModalVisibility(true);
  }, []);

  const handlePrint = React.useCallback(
    async (variationId: string) => {
      try {
        const preview = await client.query<GetVariationPreviewResponse>({
          query: GET_VARIATION_PREVIEW,
          fetchPolicy: "network-only",
          variables: {
            variationId,
            jobId,
          },
        });

        printBase64Pdf(preview?.data.getJobVariationPreview.pdf);
      } catch (e) {}
    },
    [jobId]
  );

  const handleVariationDeleteConfirm = React.useCallback(async () => {
    if (!deleteTargetId) {
      return;
    }

    try {
      await deleteVariation({
        variables: {
          jobId,
          variationId: deleteTargetId,
        },
      });

      notify({
        title: t("variations.deleteVariation"),
        content: t("variations.success.deleteVariation"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("variations.deleteVariation"),
        content: t("variations.errors.deleteVariation"),
      });
    }

    setDeleteDialogVisibility(false);
    setDeleteTarget("");
  }, [deleteTargetId, jobId, deleteVariation]);

  const variationToUpdate: VariationPayload | null = React.useMemo(() => {
    if (!variation) {
      return null;
    }

    const preparedItem = map(variation.items, (item) =>
      omit(item, ["__typename"])
    );
    const preparedVariation = pick(variation, [
      "_id",
      "name",
      "date",
      "status",
      "note",
    ]);

    return {
      ...preparedVariation,
      contact: variation.contact?._id || "",
      items: preparedItem,
    };
  }, [variation]);

  React.useEffect(() => {
    if (variation || variationLoading || variationError) {
      return;
    }

    let firstVariation = head(variations?.getJobGetVariations);

    if (varId) {
      const selectedVar = find(variations?.getJobGetVariations, {
        _id: varId,
      });
      if (selectedVar) firstVariation = selectedVar;
    }

    if (firstVariation) {
      getVariation({
        variables: {
          jobId,
          variationId: firstVariation._id,
        },
      });

      setVariationFilter({
        id: firstVariation._id,
      });
    }
  }, [
    variationError,
    variations,
    variation,
    variationLoading,
    getVariation,
    jobId,
  ]);

  const renderVariationCard = () => {
    if (!variation || variationLoading) {
      return <CardPlaceholder />;
    }

    return (
      <VariationCard
        variation={variation}
        titleIndex={variation?.variationNumber}
        onApprove={openApproveDialog}
        onUnapprove={handleUnapprove}
        onDelete={openDeleteDialog}
        onPrint={handlePrint}
        onEdit={handleEdit}
        onSend={handleSendClick}
        disableCancel={variation.status === VariationStatuses.CANCELLED}
        isLoading={uploadingSignature}
      />
    );
  };

  const previewFileName = `${businessName} - Variation #${variation?.variationNumber}.pdf`;

  const {
    filteredArray,
    filter: inputFilter,
    setFilter: setSearchByInput,
    setFilterbySelect,
    filterSelect: arrayFilter,
  } = useFilteredArrayMultipleField(
    variationsCategories,
    ["orderNumber", "description"],
    true,
    (item: CategorySelectorPayload, search: string) =>
      !!item.rightBadge?.filter((b) => b.label === search).length
  );

  const filterDropdownItems = React.useMemo<DropdownItem[]>(
    () =>
      concat(
        {
          id: "-",
          label: t("common.all"),
          onClick: () => setFilterbySelect(""),
        },
        Object.keys(VariationStatuses).map((key) => ({
          id: key,
          label: t(`variations.statuses.${key}`),
          onClick: () => setFilterbySelect(t(`variations.statuses.${key}`)),
        })),
        {
          id: "claimed",
          label: t("variations.statuses.CLAIMED"),
          onClick: () => setFilterbySelect(t("variations.statuses.CLAIMED")),
        }
      ),
    [t, setFilterbySelect]
  );

  const total = React.useMemo(() => {
    return filteredArray.reduce((sum, current) => {
      return sum + (current?.total || 0);
    }, 0);
  }, [filteredArray]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.variations")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.VARIATIONS} />

      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      <VariationModal
        editMode={isEditMode}
        variation={variationToUpdate}
        contact={jobContact}
        show={showVariationsModal}
        onClose={closeVariationModal}
        onSubmit={handleSubmit}
        jobId={jobId}
        takeoffs={isSalesQuoteHasFiles ? takeoffs : null}
        salesQuoteId={isSalesQuoteHasFiles ? salesQuoteId : null}
      />
      <ConfirmDialog
        disabled={variationDeleting}
        title={t("variations.deleteVariation")}
        show={showDeleteDialog}
        onSubmit={handleVariationDeleteConfirm}
        onClose={closeDeleteDialog}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("variations.deleteVariationMessage")}
        </span>
      </ConfirmDialog>

      <AcceptSignatureDialog
        ref={acceptSignatureDialogRef}
        title={t("variations.approveVariation")}
        onSubmit={handleApprove}
        onClose={closeApproveDialog}
        submitButtontText={t("common.approve")}
        isLoading={uploadingSignature}
      />

      <ConfirmDialog
        ref={unapproveRef}
        disabled={variationUnapproving}
        title={t("variations.unapproveVariation")}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("variations.builderUnapproveMessage")}
        </span>
      </ConfirmDialog>

      {contactsData?.listContacts && (
        <SendModal
          ref={sendRef}
          fileName={previewFileName}
          onFileClick={() => variation && handlePrint(variation._id)}
          title={t("variations.sendEmail")}
          subject={t("variations.sendEmailSubject", {
            variationNumber: variation
              ? formatQuoteNumber(variation.variationNumber, "V")
              : "",
          })}
          contacts={contactsData?.listContacts}
          contact={navigationContext?.job?.customer}
          onSubmit={handleSendSubmit}
          submitText={t("common.send")}
          copyOptions={copyMailOptions}
          addDocumentsSelect={{
            entityId: jobId,
            systemFolderType: SystemFolderType.JOB,
          }}
        />
      )}
      {variationsCategories.length ? (
        <Row className="h-100">
          <Col lg={4} xs={12}>
            <CategorySelectorCard
              title={t("variations.variations")}
              filter={variationFilter}
              onSelectCategory={handleSelectCategory}
              categories={filteredArray}
              hideSelectAllButton={true}
              onAddNewItem={openVariationModal}
              disabled={isLocked}
              dropdownFilter={arrayFilter}
              filterDropdownItems={filterDropdownItems}
              searchOptions={{ value: inputFilter, onChange: setSearchByInput }}
              placeholder={t("variations.emptyFilterPlaceholder", {
                status: arrayFilter,
              })}
              total={total}
            />
          </Col>
          <Col lg={8} xs={12}>
            {renderVariationCard()}
          </Col>
        </Row>
      ) : !isLocked ? (
        <EmptyPlaceholder
          message={t("variations.emptyPlaceholder")}
          buttonText={t("variations.createVariation")}
          onButtonPress={openVariationModal}
        />
      ) : (
        <EmptyPlaceholder message={t("variations.emptyPlaceholder")} />
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withDashboardContext(JobVariations));
