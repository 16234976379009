import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { differenceWith } from "lodash";

import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { TeamMember } from "../../../models/team-member";

import {
  ListJobMembersResponse,
  AddJobMemberResponse,
  RemoveJobMemberResponse,
} from "../../../graphql/types/models/job-teammate";
import {
  ListJobMembersInput,
  AddJobMemberInput,
  RemoveJobMemberInput,
} from "../../../graphql/types/inputs/job-teammate";
import { LIST_JOB_MEMBERS } from "../../../graphql/queries/job-teammate/queries";
import {
  ADD_JOB_MEMBER,
  REMOVE_JOB_MEMBER,
} from "../../../graphql/queries/job-teammate/mutation";
import {
  handleAddTeammate,
  handleRemoveTeammate,
} from "../../../graphql/queries/job-teammate/utils";

import TeammateHeader from "../../../components/header/teammate-header-list";
import { notify } from "../../../components/notification";

import { useVisibilityWithOutsideClick } from "../../../hooks/useVisibilityWithOutsideClick";

type Params = {
  id: string;
};

type TeammateHeaderListProps = {
  isReadonly?: boolean;
};

const TeammateHeaderList: React.FC<TeammateHeaderListProps> = (props) => {
  const { isReadonly = false } = props;

  const { t } = useTranslation();

  const { id: jobId } = useParams<Params>();

  const { data: allMembersResponse, loading: allMembersLoading } = useQuery<
    ListMembersResponse
  >(LIST_MEMBERS);

  const { data: teammatesResponse, loading: teammatesLoading } = useQuery<
    ListJobMembersResponse,
    ListJobMembersInput
  >(LIST_JOB_MEMBERS, {
    variables: {
      jobId,
    },
  });

  const [addJobMember, { loading: addJobMemberLoading }] = useMutation<
    AddJobMemberResponse,
    AddJobMemberInput
  >(ADD_JOB_MEMBER);

  const [removeJobMember, { loading: removeJobMemberLoading }] = useMutation<
    RemoveJobMemberResponse,
    RemoveJobMemberInput
  >(REMOVE_JOB_MEMBER);

  const {
    ref: showTeammatesRef,
    isVisible: showTeammatesList,
    setIsVisible: setTeammatessListVisibility,
  } = useVisibilityWithOutsideClick(false);

  const toggleTeammateList = React.useCallback(() => {
    setTeammatessListVisibility((visible) => !visible);
  }, []);

  const addTeammate = React.useCallback(
    async (memberId: string) => {
      try {
        if (!memberId || !jobId) return;

        await addJobMember({
          variables: {
            jobId,
            memberId,
          },
          update: handleAddTeammate(jobId),
        });

        notify({
          title: t("headerTeammate.addTeammate"),
          content: t("headerTeammate.success.addTeammate"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("headerTeammate.addTeammate"),
          content: t("headerTeammate.error.addTeammate"),
        });
      }
    },
    [jobId, addJobMember, t]
  );

  const removeTeammate = React.useCallback(
    async (memberId: string) => {
      try {
        if (!jobId || !memberId) return;

        await removeJobMember({
          variables: { jobId, memberId },
          update: handleRemoveTeammate(jobId),
        });

        notify({
          title: t("headerTeammate.removeFromJob"),
          content: t("headerTeammate.success.removeFromJob"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("headerTeammate.removeFromJob"),
          content: t("headerTeammate.error.removeFromJob"),
        });
      }
    },
    [jobId, removeJobMember, t]
  );

  const filteredContacts = React.useMemo(() => {
    if (allMembersLoading || teammatesLoading) {
      return [];
    }

    return differenceWith(
      allMembersResponse?.listMembers || [],
      teammatesResponse?.listJobMembers || [],
      (client: TeamMember, jobContact: TeamMember) => {
        return client._id === jobContact._id;
      }
    );
  }, [
    allMembersResponse,
    allMembersLoading,
    teammatesLoading,
    teammatesResponse,
  ]);

  return (
    <TeammateHeader
      ref={showTeammatesRef}
      loading={
        teammatesLoading || addJobMemberLoading || removeJobMemberLoading
      }
      jobMembers={teammatesResponse?.listJobMembers}
      contacts={filteredContacts}
      showTeammatesList={showTeammatesList}
      toggleTeammateList={toggleTeammateList}
      addTeammate={addTeammate}
      removeTeammate={removeTeammate}
      isReadonly={isReadonly}
    />
  );
};

export default TeammateHeaderList;
