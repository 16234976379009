import React, { useState } from "react";
import { uniqBy, get, omit } from "lodash";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { useApolloClient } from "@apollo/client";
import CreateEntityModal from "../../modals/create-entity";
import {
  CreateJobCostingPayload,
  JobCostingItemPayload,
} from "../../../models/job";
import {
  AppendDynamicProps,
  DynamicProps,
  FormikPropGetSetValues,
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import CalculatorModal from "../../modals/calculator";
import createJobCostingSchema from "./CreateJobCosting.schema";
import { UOMOption } from "../../../utils/types/options";
import { createJobCostingFields } from "./utils";
import {
  AssemblyListItem,
  AssemblyCreatePayload,
} from "../../../models/assembly";
import CreateAssemblyModal, {
  CreateAssemblyModalRef,
} from "../../costing/create-assembly-modal";
import {
  calcGSTEx,
  calcWithGST,
  GST_PERCENT,
} from "../../../utils/calculations";
import { useCostingLookup } from "../../../hooks/useCostingLookup";
import "./styles.scss";
import { addAssemblyItem } from "../../costing/update-costing-modal/utils";
import { Rounding } from "../../calculator/utils";
import { useCostChangeGST } from "../../../hooks/useCostChangeGST";
import { usePriceLookup } from "../../../hooks/usePriceLookup";
import { useCreateAssemblyFromSelection } from "../../../hooks/useCreateAssemblyFromSelection";

type CreateJobCostingModalProps = {
  show: boolean;
  onSubmit: (
    data: CreateJobCostingPayload,
    assemblies?: AssemblyListItem[]
  ) => void;
  onClose: () => void;
  uoms: UOMOption[];
  isRestrictedEntry: boolean;
};

const CreateJobCostingModal: React.FC<CreateJobCostingModalProps> = (props) => {
  const {
    show,
    onSubmit,
    onClose,
    uoms: currentUOMs,
    isRestrictedEntry,
  } = props;

  const { t } = useTranslation();
  const client = useApolloClient();
  const {
    assemblies,
    assemblyOptions,
    setDefaultItems,
    handleAssemblyCreation,
    handleCostingNameChange,
    handleIsAssemblyCheck,
    searchCostingItem,
  } = useCostingLookup(client);

  const {
    costsHaveGST,
    handleCostChange,
    handleCostsHaveGST,
    resetCostsHaveGST,
  } = useCostChangeGST();

  const assemblyRef = React.useRef<CreateAssemblyModalRef>(null);

  const [showCalculator, setCalculatorVisibility] = useState(false);
  const [calcFieldName, setFieldName] = useState("");
  const [uoms, setUOMs] = React.useState<UOMOption[]>([]);
  const [assemblyRowIndex, setAssemblyRowIndex] = React.useState<
    number | undefined
  >();

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        uoms.concat([{ label: uom, value: uom }]),
        "value"
      );
      setUOMs(newUOMs);
    },
    [uoms, setUOMs]
  );

  const { priceLookupButton, renderPriceLookupModal } = usePriceLookup(
    t,
    "items",
    handleUOMCreate,
    isRestrictedEntry
  );

  const {
    handleOnCreateAssemblyFromSelection,
    hasSelectedItems,
    handleResetSelectedItems,
  } = useCreateAssemblyFromSelection("items", setAssemblyRowIndex, assemblyRef);

  React.useEffect(() => {
    if (!show) {
      resetCostsHaveGST();
    }
  }, [show]);

  React.useEffect(() => {
    setUOMs(currentUOMs);
  }, [currentUOMs]);

  const toggleCalculatorModal = React.useCallback(() => {
    setCalculatorVisibility(!showCalculator);
  }, [showCalculator]);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateJobCostingPayload>
  >({});

  const openCalculatorModal = React.useCallback((fieldName: string) => {
    setFieldName(fieldName);
    setCalculatorVisibility(true);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, []);

  const handlePriceSearch = React.useCallback(
    (value: string) =>
      isRestrictedEntry ? Promise.resolve([]) : searchCostingItem(value),
    [client, isRestrictedEntry]
  );

  const handleAssemblyChange = React.useCallback(
    (fieldValue, rowIndex, formikProps, fieldName) => {
      if (!show) return;
      const fieldsArray = `items[${rowIndex}]`;
      const item = handleCostingNameChange(
        fieldValue,
        fieldsArray,
        formikProps,
        true
      );
      if (item?.UOM) {
        handleUOMCreate(item.UOM);
      }
    },
    [show, handleUOMCreate]
  );

  const getAssemblyProps = React.useCallback(
    (formikProps, __, rowIndex): AppendDynamicProps => {
      if (isRestrictedEntry) {
        return { isHidden: true };
      }
      const hasAssembly = !!formikProps.values.items[rowIndex]?.assemblyId;
      return {
        icon: "file_copy",
        className: hasAssembly ? "success" : "",
      };
    },
    [isRestrictedEntry]
  );

  const handleAssemblyPress = React.useCallback(
    (
      fieldName: string,
      _fieldValue?: string,
      rowIndex?: number,
      formikProps?: FormikProps<any>
    ) => {
      const formAssembly = get(formikProps?.values, fieldName.split(".")[0]);
      // const assembly = formAssembly?.assemblyId ? formAssembly : null;
      setAssemblyRowIndex(rowIndex);
      const assembly = formAssembly?.assemblyId
        ? formAssembly
        : { ...formAssembly, ...addAssemblyItem(formAssembly) };
      assemblyRef.current?.show(true, assembly);
    },
    [assemblies, assemblyRef]
  );

  const handleAssemblySubmit = React.useCallback(
    async (assembly: AssemblyCreatePayload, formikProps: FormikProps<any>) => {
      const fieldsArray = `items[${assemblyRowIndex}]`;
      await handleAssemblyCreation(fieldsArray, assembly, formikProps);
      if (hasSelectedItems) {
        handleResetSelectedItems(formikProps);
      }
      assemblyRef.current?.show(false);
    },
    [assemblyRef, assemblyRowIndex, hasSelectedItems]
  );

  // clear wastage when manually setting quantity
  const handleQuantityChange = React.useCallback(
    (fieldValue, formikProps, rowIndex) => {
      formikProps.setFieldValue(
        `items[${rowIndex}].raw_quantity`,
        fieldValue.toString()
      );
      formikProps.setFieldValue(`items[${rowIndex}].wastage`, 0);
      formikProps.setFieldValue(`items[${rowIndex}].rounding`, "");
    },
    []
  );

  const getFieldProps = React.useCallback(
    (_formikProps, fieldName, _rowIndex): DynamicProps => {
      const name = fieldName.split(".")?.[1];
      if (
        isRestrictedEntry &&
        ["quantity", "UOM", "cost", "cost_inc", "gstFree"].indexOf(name) >= 0
      ) {
        return { disabled: true };
      }
      return {};
    },
    [isRestrictedEntry]
  );

  const getCalcProps = React.useCallback(
    (formikProps, __, rowIndex): AppendDynamicProps => {
      if (
        isRestrictedEntry ||
        formikProps.values.items[rowIndex]?.is_restricted_entry
      ) {
        return {
          isHidden: true,
        };
      }
      return {};
    },
    [isRestrictedEntry]
  );
  const handleDisableRowDeleting = React.useCallback(() => false, []);

  React.useEffect(() => {
    setFormFields(
      createJobCostingFields(
        t,
        uoms,
        openCalculatorModal,
        handleUOMCreate,
        handlePriceSearch,
        assemblyOptions,
        getFieldProps,
        getAssemblyProps,
        getCalcProps,
        handleAssemblyPress,
        handleAssemblyChange,
        handleQuantityChange,
        handleCostChange,
        false, // is Locked
        costsHaveGST,
        handleCostsHaveGST,
        handleDisableRowDeleting,
        priceLookupButton,
        undefined,
        handleOnCreateAssemblyFromSelection
      )
    );
  }, [
    t,
    uoms,
    assemblyOptions,
    getAssemblyProps,
    getCalcProps,
    handleAssemblyChange,
    handleAssemblyPress,
    handleQuantityChange,
    handleCostChange,
    handleUOMCreate,
    getFieldProps,
    openCalculatorModal,
    handlePriceSearch,
    costsHaveGST,
    handleCostsHaveGST,
    handleDisableRowDeleting,
    priceLookupButton,
    handleOnCreateAssemblyFromSelection,
  ]);

  const handleCreateSubmit = React.useCallback(
    (data: CreateJobCostingPayload) => {
      onSubmit &&
        onSubmit(
          {
            ...data,
            items: data.items.map((item, index) => ({
              ...omit(item, ["cost_inc", "real_cost_inc"]),
              cost: costsHaveGST
                ? calcGSTEx(item.cost_inc || 0, GST_PERCENT)
                : item.cost,
              real_cost: costsHaveGST
                ? calcGSTEx(item.real_cost_inc || 0, GST_PERCENT)
                : item.real_cost,
              order: index + 1,
              hasGST: !item.gstFree,
            })),
          },
          assemblies
        );
    },
    [onSubmit, assemblies, costsHaveGST]
  );

  return (
    <CreateEntityModal
      validationSchema={createJobCostingSchema(t)}
      title={t("costing.newJobCosting")}
      className="create-job-costing-modal"
      data={{
        name: "",
        can_timesheet: true,
        items: [
          {
            UOM: "qty",
            cost: "0",
            name: "",
            wastage: 0,
            rounding: Rounding.NONE,
            quantity: "0",
            raw_quantity: "",
            real_cost: "0",
            assemblyId: "",
            order: 0,
            hasGST: true,
            gstFree: false,
          },
        ],
      }}
      show={show}
      fields={formFields}
      onSubmit={handleCreateSubmit}
      onClose={onClose}
      disableEventValidation={true}
    >
      {(formikProps) => {
        const wastage = get(formikProps.values, calcFieldName.split(".")[0])
          ?.wastage;
        const rounding = get(formikProps.values, calcFieldName.split(".")[0])
          ?.rounding;
        const quantity = get(formikProps.values, calcFieldName);
        const raw_quantity = get(
          formikProps.values,
          calcFieldName.split(".")[0]
        )?.raw_quantity;
        const rawQuantity = raw_quantity || quantity;
        const itemName = get(formikProps.values, calcFieldName.split(".")[0])
          ?.name;

        return (
          <>
            {renderPriceLookupModal(formikProps)}
            <CalculatorModal
              formikProps={formikProps}
              show={showCalculator}
              onSubmit={handleSubmit}
              fieldName={calcFieldName}
              onClose={toggleCalculatorModal}
              wastage={wastage?.toString()}
              initialValue={rawQuantity}
              rounding={rounding}
              title={itemName}
            />

            <CreateAssemblyModal
              ref={assemblyRef}
              uoms={uoms}
              onSubmit={handleAssemblySubmit}
              onClose={() => assemblyRef.current?.show(false)}
              formikProps={formikProps}
            />
          </>
        );
      }}
    </CreateEntityModal>
  );
};

export default CreateJobCostingModal;
