import React from "react";
import { useTranslation } from "react-i18next";
import {
  CopyTakeOffPayload,
  TakeOffMeasurement,
  TakeoffListItem,
} from "../../../../models/take-off";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import CreateEntityModal from "../../../modals/create-entity";
import { copyTakeOffFields } from "./utils";
import { useModalDisplay } from "../../../../hooks/useModalDisplay";
import COLOURS from "../../../../constants/colours";
import copyTakeOffSchema from "./CopyTakeOffSchema";
import "./styles.scss";

type CopyTakeOffModalProps = {
  onSubmit: (data: CopyTakeOffPayload, measurement: string) => void;
  colourIndex: number;
  takeOff: TakeoffListItem | null;
};

const CopyTakeOffModal: React.FC<CopyTakeOffModalProps> = (
  { onSubmit, colourIndex = 0, takeOff },
  ref
) => {
  const { t } = useTranslation();
  const { shouldShow, hide } = useModalDisplay(ref, []);

  const [activeMeasurementTab, setActiveMeasurementTab] = React.useState<
    string
  >(TakeOffMeasurement.LINEAR_METER);
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CopyTakeOffPayload>
  >({});

  const isHideMeasurement =
    takeOff?.UOM === TakeOffMeasurement.LINEAR_METER ||
    takeOff?.UOM === TakeOffMeasurement.QUANTITY ||
    takeOff?.UOM === TakeOffMeasurement.TONNE;

  const handleMeasurementChange = React.useCallback((value: string) => {
    setActiveMeasurementTab(value);
  }, []);

  React.useEffect(() => {
    if (takeOff?.UOM) {
      setFormFields(
        copyTakeOffFields(
          t,
          takeOff.UOM,
          handleMeasurementChange,
          activeMeasurementTab,
          isHideMeasurement
        )
      );
    }
  }, [takeOff, activeMeasurementTab, isHideMeasurement]);

  const handleSumbit = React.useCallback(
    (data: CopyTakeOffPayload) => {
      onSubmit(data, isHideMeasurement ? takeOff?.UOM : activeMeasurementTab);
      setActiveMeasurementTab(TakeOffMeasurement.LINEAR_METER);
    },
    [activeMeasurementTab, isHideMeasurement, onSubmit, takeOff?.UOM]
  );

  const handleClose = React.useCallback(() => {
    hide();
    setActiveMeasurementTab(TakeOffMeasurement.LINEAR_METER);
  }, []);

  if (!takeOff) return null;

  return (
    <CreateEntityModal
      validationSchema={copyTakeOffSchema(t, activeMeasurementTab)}
      title={t("takeOffSection.copyTakeOff")}
      show={shouldShow}
      data={{
        name: t("common.copyOf", { name: takeOff?.name }) || "",
        depth: "",
        UOM:
          (takeOff?.UOM as TakeOffMeasurement) ||
          TakeOffMeasurement.LINEAR_METER,
        color: COLOURS[colourIndex % COLOURS.length],
      }}
      className="copy-take-off-form-modal"
      onSubmit={handleSumbit}
      onClose={handleClose}
      fields={formFields}
    />
  );
};

export default React.forwardRef(CopyTakeOffModal);
