import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../utils/yup";

export default function acceptSignatureSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "common.name")
      ),
  });
}
