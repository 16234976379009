import { gql } from "@apollo/client";

export const QUOTE_REQUEST_FRAGMENT = gql`
  fragment QuoteRequest on QuoteRequest {
    _id
    quoteNumber
    name
    note
    scope
    due_date
    business {
      _id
      name
    }
    items {
      _id
      quantity
    }
    salesQuote {
      _id
      number
      name
      contact {
        _id
        first_name
        last_name
        email
      }
    }
    job {
      _id
      name
      jobNumber
      customer {
        _id
        first_name
        last_name
        email
      }
    }
    attachments {
      _id
      url
      upload_url
      type
      name
    }
    subcontractors {
      _id
      status
      date_submitted
      client_feedback
      supplier {
        _id
        business_name
        email
        contact_person
        business {
          _id
          name
        }
      }
      items {
        _id
        cost
        gst_inc
        quoteItemId
      }
    }
  }
`;

export const CLIENT_QUOTE_REQUEST_FRAGMENT = gql`
  fragment ClientQuoteRequestData on ClientQuoteRequest {
    _id
    name
    subcontractors {
      _id
      business_name
      contact_person
      client_feedback
      items {
        _id
        name
        UOM
        cost
        quantity
      }
    }
  }
`;
