import { gql } from "@apollo/client";

export const LIST_BUILDING_TEMPLATES = gql`
  query {
    templates: listBuildingTemplates {
      _id
      name
      type
      categories {
        _id
        name
        items {
          _id
          name
          cost
          UOM
          quantity
          items {
            _id
            name
            cost
            UOM
            quantity
            raw_quantity
          }
        }
      }
    }
  }
`;
