import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import HeaderActionButton from "../../../components/dashboard/header/HeaderActionButton";
import HeaderPageAction from "../../../components/dashboard/header/HeaderPageAction";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DetailsCard, {
  DetailsCardAction,
} from "../../../components/dashboard/details-card";
import { DetailsCardDataItem } from "../../../components/dashboard/details-card/DetailsCardList";
import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UpdateProfilePayload } from "../../../models/profile";
import Avatar from "../../../components/avatar";
import "./styles.scss";
import ChangePasswordModal from "../../../components/profile/change-pwd-modal";
import UpdateProfileModal from "../../../components/profile/update-profile-modal";
import { useMutation } from "@apollo/client";
import { omit } from "lodash";
import {
  CHANGE_PASSWORD,
  EDIT_PROFILE,
} from "../../../graphql/queries/auth/mutations";
import {
  ChangePasswordResponse,
  EditProfileResponse,
  UserPayload,
} from "../../../graphql/types/models/auth";
import GraphQLClient from "../../../graphql";
import { notify } from "../../../components/notification";
import { updateProfile } from "../../../redux/authentication/actions";
import { uploadFiles } from "../../../utils/files";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";

type ProfileOverviewProps = DashboardContextValue & {
  user: UserPayload | null;
  updateProfile: (user: Partial<UserPayload>) => void;
};

const ProfileOverview: React.FC<ProfileOverviewProps> = (props) => {
  const { user, updateProfile } = props;

  const { t } = useTranslation();

  const [showPasswordModal, setPwdModalVisibility] = useState(false);
  const [showEditModal, setEditModalVisibility] = useState(false);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  const [updateProfileAction] = useMutation<EditProfileResponse>(EDIT_PROFILE, {
    onCompleted: async (data) => {
      if (
        avatarFile &&
        data.updateProfile.profileImage &&
        data.updateProfile.profileImage.name &&
        data.updateProfile.profileImage.upload_url
      ) {
        await uploadFiles(
          [
            {
              name: data.updateProfile.profileImage?.name,
              upload_url: data.updateProfile.profileImage?.upload_url,
            },
          ],
          [avatarFile]
        );
      }

      updateProfile(data.updateProfile);
      setEditModalVisibility(false);
    },
  });

  const [changePassword] = useMutation<ChangePasswordResponse>(
    CHANGE_PASSWORD,
    {
      onCompleted: (data) => {
        GraphQLClient.setToken(data.changePassword.token);
        setPwdModalVisibility(false);
        notify({
          title: t("profile.changePassword"),
          content: t("profile.success.changePassword"),
        });
      },
      onError: () => {
        notify({
          title: t("profile.changePassword"),
          content: t("profile.errors.changePassword"),
          error: true,
        });
      },
    }
  );

  const openEditModal = React.useCallback(() => {
    setEditModalVisibility(true);
  }, []);

  const closeEditModal = React.useCallback(() => {
    setAvatarFile(null);
    setEditModalVisibility(false);
  }, []);

  const openPwdModal = React.useCallback(() => {
    setPwdModalVisibility(true);
  }, []);

  const closePwdModal = React.useCallback(() => {
    setPwdModalVisibility(false);
  }, []);

  const RenderNotifyPlaceholder = React.useCallback(() => {
    return (
      <>
        <div className="mb-2">
          {user?.disabledNotifications && (
            <span>
              {`${t("profile.email")} ${t("profile.notify")}: `}
              {user?.disabledNotifications?.email ? (
                <b>{t("common.disabled")}</b>
              ) : (
                <b>{t("common.enabled")}</b>
              )}
            </span>
          )}
        </div>
        <div>
          {user?.disabledNotifications && (
            <span>
              {`${t("profile.mobilePush")} ${t("profile.notify")}: `}
              {user?.disabledNotifications?.push ? (
                <b>{t("common.disabled")}</b>
              ) : (
                <b>{t("common.enabled")}</b>
              )}
            </span>
          )}
        </div>
      </>
    );
  }, [t, user?.disabledNotifications]);

  const profileCardData: DetailsCardDataItem[] = React.useMemo(() => {
    return [
      {
        icon: "work_outline",
        text: user?.business.name || "",
      },
      {
        icon: "person",
        text: user ? `${user.first_name} ${user.last_name}` : "",
      },
      {
        icon: "mail",
        text: user ? user.email : "",
      },
      {
        icon: "notifications",
        text: <RenderNotifyPlaceholder />,
      },
      {
        icon: "assignment_ind",
        text: user?.signature ? (
          <div dangerouslySetInnerHTML={{ __html: user.signature?.html }} />
        ) : (
          ""
        ),
      },
    ];
  }, [RenderNotifyPlaceholder, user]);

  const profileActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("profile.editDetails"),
        onClick: openEditModal,
        className: "button info large",
        disabled: !user,
      },
    ],
    [t, openEditModal, user]
  );

  const handlePwdChangeSubmit = React.useCallback(
    (values) => {
      return changePassword({ variables: values });
    },
    [changePassword]
  );

  const handleEditSubmit = React.useCallback(
    (values) => {
      const payload: UpdateProfilePayload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        signature: values.signature,
        disabledNotifications: {
          email: values.disabledEmailNotifications,
          push: values.disabledPushNotifications,
        },
      };

      if (avatarFile) {
        payload.profileImage = {
          name: avatarFile.name,
          type: avatarFile.type,
          size: avatarFile.size,
        };
      }

      return updateProfileAction({ variables: payload });
    },
    [updateProfileAction, avatarFile]
  );

  const handleAvatarUpload = React.useCallback((files) => {
    setAvatarFile(files[0]);
  }, []);

  return (
    <Container fluid className="m-0 p-0 h-100 profile-overview">
      <Helmet title={t("navigation.account.profile")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE} />
      <HeaderPageAction>
        <HeaderActionButton icon="lock" outlined onClick={openPwdModal}>
          {t("profile.changePassword")}
        </HeaderActionButton>
      </HeaderPageAction>

      <ChangePasswordModal
        onSubmit={handlePwdChangeSubmit}
        onClose={closePwdModal}
        show={showPasswordModal}
      />

      {user && (
        <UpdateProfileModal
          avatarFile={avatarFile}
          onUpload={handleAvatarUpload}
          user={user}
          show={showEditModal}
          onClose={closeEditModal}
          onSubmit={handleEditSubmit}
        />
      )}

      <Row className="h-100">
        <Col lg={6} xs={12} xl={4}>
          <DetailsCard
            title={t("profile.details")}
            actions={profileActions}
            fullHeight
            data={profileCardData}
          >
            <div className="profile-avatar">
              {user?.profileImage && (
                <Avatar
                  url={user.profileImage.url}
                  rounded
                  alt=""
                  width="200px"
                  height="200px"
                />
              )}
            </div>
          </DetailsCard>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default withDashboardContext(
  connect(mapStateToProps, { updateProfile })(ProfileOverview)
);
