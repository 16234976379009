import { TFunction } from "i18next";
import { GenericFormFields } from "../generic-form/GenericFormBody";
import { User } from "../../graphql/types/models/user";
import { Client } from "../../models/client";
import { Media } from "../../models/media";

export type DigitalSignature = {
  _id: string;
  name: string;
  IPv4: string;
  IPv6: string;
  date: string;
  signedByType: EnumDigitalSignatureType;
  signedBy: DigitalSignatureUser;
  file: Media;
};

export enum EnumDigitalSignatureType {
  CLIENT = "CLIENT",
  BUILDER = "BUILDER",
}

export type DigitalSignatureUser = User & Client;

export const createFields = (
  t: TFunction
): GenericFormFields<{ name: string }> => {
  return {
    name: {
      type: "text",
      label: t("placeholders.signedBy"),
      placeholder: t("common.name"),
      valueKey: "name",
      showError: true,
      inputProps: {
        required: true,
      },
    },
  };
};
