export enum AccountingIntegrationType {
  XERO = "XERO",
  MYOB = "MYOB",
  // QUICKBOOKS = "QUICKBOOKS", // TODO: Add Support
}

export enum AccountingIntegrationSyncItemType {
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PROGRESS_CLAIM = "PROGRESS_CLAIM",
  SALES_QUOTE = "SALES_QUOTE",
}

export type XeroAccount = {
  id: string;
  code: number;
  name: string;
  accountID: string;
  type: string;
};

export type XeroTenant = {
  id: string;
  tenantId: string;
  tenantType: string;
  tenantName: string;
  accounts: XeroAccount[];
};

export type Xero = {
  tenant: XeroTenant;
  expenseAccount: XeroAccount;
  salesAccount: XeroAccount;
};
export type ConnectXeroResponse = {
  connectXero: {
    url: string;
  };
};

export type ConnectXeroCallbackPayload = {
  path: string;
};

export type ConnectXeroCallbackResponse = {
  connectXeroCallback: any;
};

export type AccountingIntegrationResponse = {
  getCurrentAccountingIntegration: AccountingIntegration;
};

export type AccountingIntegrationErrorsResponse = {
  getAccountingIntegrationErrors: AccountingIntegrationError[];
};

export type ClearAccountingIntegrationErrorsResponse = {
  clearAccountingIntegrationErrors: boolean;
};

export type SyncAccountingItemResponse = {
  syncAccountingItem: boolean;
};

export type XeroTenantResponse = {
  getTenant: XeroTenant;
};

export type XeroAccountPayload = {
  salesAccount: XeroAccount;
  expenseAccount: XeroAccount;
};
export type UpdateXeroPayload = {
  xero: XeroAccountPayload;
};

export type UpdateXeroResponse = {
  updateXeroIntegration: AccountingIntegration;
};

export type DisconnectXeroResponse = {
  disconnectXero: AccountingIntegration;
};

export type ConnectAccountingRequest = {
  provider: AccountingIntegrationType;
};

/**
 * Provider structure
 */
export type MYOB = {
  tenant: AccountingTenant;
  expenseAccount: AccountingAccount;
  salesAccount: AccountingAccount;
};

export type AccountingIntegration = {
  _id: string;
  integration_type: AccountingIntegrationType;
  xero?: Xero;
  myob?: MYOB;
  sync?: AccountingIntegrationSync;
  updatedAt: Date;
  createdAt: Date;
};

export type AccountingIntegrationSync = {
  purchaseOrders: boolean;
  purchaseOrderReceipts: boolean;
  progressClaims: boolean;
};

export type AccountingTenant = {
  id: string;
  tenantName: string;
};

export type AccountingAccount = {
  id: string;
  code: string;
  name: string;
  type: string;
  isSales?: boolean;
  isExpenses?: boolean;
};
export type AccountingConfiguration = {
  tenantId: string;
  salesAccountId: string;
  expenseAccountId: string;
  sync: AccountingIntegrationSync;
};

export enum AccountingCustomConfigurationType {
  TEXT = "TEXT",
  SELECT = "SELECT",
  GROUP = "GROUP",
}
export type AccountingCustomConfigurationOption = {
  value: string;
  label: string;
};
export type AccountingCustomConfiguration = {
  key: string;
  label: string;
  placeholder?: string;
  hint?: string;
  type: AccountingCustomConfigurationType;
  options?: AccountingCustomConfigurationOption[];
  value: string;
};

export type AccountingIntegrationError = {
  _id: string;
  type: string;
  message: string;
  code: number;
  details: string;
  createdAt: string;
};

export type ConnectAccountingResponse = {
  connectAccounting: {
    url: string;
  };
};
export type ConnectAccountingCallbackPayload = {
  provider: AccountingIntegrationType;
  data: String;
};
export type DisconnectAccountingResponse = {
  disconnectAccounting: AccountingIntegration;
};
export type ConnectAccountingCallbackResponse = {
  connectCallback: AccountingIntegration;
};

export type AccountingTenantsResponse = {
  getAccountingTenants: AccountingTenant[];
};

export type AccountingAccountsResponse = {
  getAccountingAccounts: AccountingAccount[];
};

export type AccountingCustomConfigurationResponse = {
  getAccountingCustomConfiguration: AccountingCustomConfiguration[];
};

export type AccountingConfigurationPayload = {
  config: AccountingConfiguration & {
    customConfiguration?: {
      key: string;
      value: string;
    }[];
  };
};
export type AccountingConfigurationResponse = {
  setAccountingConfiguration: AccountingIntegration;
};
