import React from "react";
import { Toast, ToastBody, ToastHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import useSound from "use-sound";
import newMessageSound from "./message.mp3";
import Avatar from "../avatar";
import { JobChannelMessageSender } from "../../models/job-messaging";
import { getFullName } from "../../utils/text";
import { useTranslation } from "react-i18next";
import { User } from "../../graphql/types/models/user";
import "./styles.scss";

type NotificationProps = {
  title?: string;
  closeToast?: () => void;
  content?: string;
  withSound?: boolean;
  sender?: User | JobChannelMessageSender;
  onLinkPress?: () => void;
};

const MessageNotification = (props: NotificationProps) => {
  const { closeToast, content, title, sender, withSound, onLinkPress } = props;
  const { t } = useTranslation();
  const [play] = useSound(newMessageSound, {
    soundEnabled: withSound,
  });

  return (
    <Toast className="border-0 message-notification" onClose={closeToast}>
      <div className="conatiner">
        {withSound && play()}
        <div className="avatar-container">
          <Avatar
            userName={getFullName(sender)}
            width="50px"
            height="50px"
            rounded
            url={sender?.profileImage?.url}
          />
        </div>

        <div className="notification-body">
          <ToastHeader closeButton={true} className="title field-text ">
            <span className="text-truncate">{title}</span>
          </ToastHeader>
          <ToastBody className="body">
            <div className="content">{content}</div>
            <div className="link-container">
              {onLinkPress && (
                <button onClick={onLinkPress}>
                  {t("communication.viewDiscussion")}
                </button>
              )}
            </div>
          </ToastBody>
        </div>
      </div>
    </Toast>
  );
};

export const messageNotify = (props?: NotificationProps) => {
  toast.dismiss();
  toast(<MessageNotification {...props} />, {
    toastId: "success",
    closeButton: false,
    hideProgressBar: true,
    position: "bottom-right",
    autoClose: 5000,
    pauseOnHover: true,
  });
};

export default MessageNotification;
