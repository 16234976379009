import { gql } from "@apollo/client";

export const LIST_NOTIFICATIONS = gql`
  query {
    listNotifications {
      _id
      message
      is_read
      createdAt
      sender {
        ... on User {
          _id
          first_name
          last_name
          fullName
        }
        ... on Subcontractor {
          _id
          business_name
          contact_person
        }
        ... on Client {
          _id
          first_name
          last_name
        }
      }
      senderType
      notificationType
      entity {
        ... on QuoteRequestComment {
          _id
          subcontractorResponseId
          comment
          quoteRequest {
            _id
            name
            quoteNumber
            salesQuote {
              _id
              number
              name
            }
          }
        }
        ... on QuoteRequest {
          _id
          name
          quoteNumber
          salesQuote {
            _id
            number
            name
          }
        }
        ... on Variation {
          _id
          name
          variationNumber
          job {
            _id
            jobNumber
            name
          }
        }
        ... on Selection {
          _id
          linkedEntityType
          entity {
            ... on Job {
              __typename
              _id
              name
              jobNumber
            }
            ... on SalesQuote {
              __typename
              _id
              name
              number
            }
          }
        }
      }
    }
  }
`;
