import { gql } from "@apollo/client";
import { FOLDER_FRAGMENT } from "./fragments";

export const LIST_FOLDERS = gql`
  query listFolders {
    listFolders {
      _id
      name
      createdAt
      __typename
      accessRole
      accessSelectedUsers {
        _id
      }
    }
  }
`;

export const GET_FOLDER = gql`
  query getFolder($folderId: ID!) {
    getFolder(folderId: $folderId) {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const GET_SYSTEM_FOLDER = gql`
  query getSystemFolder($refId: ID!, $systemType: EnumSystemFolderType!) {
    getSystemFolder(refId: $refId, systemType: $systemType) {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;

export const GET_SYSTEM_SUB_FOLDER = gql`
  query getSystemSubFolder(
    $refId: ID!
    $systemType: EnumSystemFolderType!
    $subType: EnumSystemSubFolderType!
  ) {
    getSystemSubFolder(
      refId: $refId
      systemType: $systemType
      subType: $subType
    ) {
      ...FolderFragment
    }
  }
  ${FOLDER_FRAGMENT}
`;
