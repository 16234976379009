import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import XLSX from "xlsx";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { chain, find, head, isEmpty, map, reduce } from "lodash";
import { printBase64Pdf } from "../../../../utils/pdf";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
} from "../../../../components/dashboard/table-card/utils";
import PriceListCategories from "../../../../components/settings/price-list/categories";
import AddPriceListCategoryModal, {
  AddPriceListCategoryModalRef,
} from "../../../../components/settings/price-list/add-category";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import {
  CreatePriceListCategoryResponse,
  DeletePriceListCategory,
  GetPriceListResponse,
  ListPriceListCategoriesResponse,
  PreviewPriceListResponse,
} from "../../../../graphql/types/models/price-list";
import {
  LIST_PRICE_LIST_CATEGORIES,
  GET_PRICE_LIST,
  PRICE_PREVIEW,
} from "../../../../graphql/queries/price-list/queries";
import {
  CREATE_PRICE_LIST_CATEGORY,
  DELETE_PRICE_LIST_CATEGORY,
  UPDATE_PRICE_LIST_CATEGORY_ITEMS,
  ADJUST_PRICE_LIST_PRICE,
  PRICE_LIST_UPLOAD,
} from "../../../../graphql/queries/price-list/mutations";
import {
  AddPriceListCategoryPayload,
  BulkPriceListAdjustPayload,
  PriceListCategory,
  PriceListItem,
} from "../../../../models/price-list";
import ConfirmDialog from "../../../../components/confirm-dialog";
import { notify } from "../../../../components/notification";
import { handlePriceListCategoryDelete } from "../../../../graphql/queries/price-list/utils";
import AdjustPriceListModal from "../../../../components/settings/price-list/bulk-adjust";
import { MEASUREMENT_OPTIONS } from "../../../../utils/options";
import { UOMOption } from "../../../../utils/types/options";

type Params = {
  id: string;
};
type PriceListProps = RouteComponentProps<Params> & DashboardContextValue;

const PriceList: React.FC<PriceListProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { t } = useTranslation();

  const { id: priceListId } = match.params;

  const [createPriceListCategory] = useMutation<
    CreatePriceListCategoryResponse
  >(CREATE_PRICE_LIST_CATEGORY);

  const [deletePriceListCategory, { loading: costingDeleting }] = useMutation<
    DeletePriceListCategory
  >(DELETE_PRICE_LIST_CATEGORY, {
    update: handlePriceListCategoryDelete(priceListId),
  });

  const { data: priceListCategoryData, loading: costingLoading } = useQuery<
    ListPriceListCategoriesResponse
  >(LIST_PRICE_LIST_CATEGORIES, {
    variables: {
      priceId: priceListId,
    },
  });

  const {
    data: priceListData,
    loading: priceListLoading,
    refetch: refetchPriceList,
  } = useQuery<GetPriceListResponse>(GET_PRICE_LIST, {
    variables: {
      priceId: priceListId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [updatePriceListCategoryItems] = useMutation(
    UPDATE_PRICE_LIST_CATEGORY_ITEMS,
    {
      onCompleted: () => {
        refetchPriceList();
      },
    }
  );

  const [uploadSupplierPrice] = useMutation(PRICE_LIST_UPLOAD, {
    onCompleted: () => {
      refetchPriceList();
    },
  });

  const [adjustPriceList] = useMutation<CreatePriceListCategoryResponse>(
    ADJUST_PRICE_LIST_PRICE,
    {
      onCompleted: () => {
        refetchPriceList();
      },
    }
  );

  const [selectedCategory, setCategory] = useState<PriceListCategory | null>(
    null
  );
  const [
    editingCategory,
    setEditingCategory,
  ] = useState<PriceListCategory | null>(null);
  const [isAdjustModalVisible, setAdjustModalVisibility] = useState(false);
  const [showDeleteConfirmation, setDeleteConfirmationVisibility] = useState(
    false
  );
  const [deleteTargetId, setDeleteTarget] = useState("");
  const addCategoryRef = React.useRef<AddPriceListCategoryModalRef>();

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteConfirmationVisibility(false);
    setDeleteTarget("");
  }, []);

  const openDeleteDialog = React.useCallback((id: string) => {
    setDeleteConfirmationVisibility(true);
    setDeleteTarget(id);
  }, []);

  const handleAddCategorySubmit = React.useCallback(
    async (values: AddPriceListCategoryPayload) => {
      const prepareItems = map(values.items, (item) => {
        return {
          _id: item._id,
          name: item.name || "",
          cost: parseFloat(item.cost?.toString().replace(/[^0-9\.]/g, "")) || 0,
          UOM: item.UOM?.toString(),
          gst_inc: false,
          category: item.category?.toString(),
          sku: item.sku?.toString(),
        };
      });

      if (values.method == "upload" && prepareItems.length > 0) {
        await uploadSupplierPrice({
          variables: {
            priceId: priceListId,
            items: prepareItems,
          },
        });
      } else {
        const category = await createPriceListCategory({
          variables: {
            priceId: priceListId,
            name: values.name,
            categoryId: values._id,
          },
        });

        const items = map(prepareItems, (item) => ({
          ...item,
          category: category.data?.createUpdatePriceCategory?._id,
        }));

        await updatePriceListCategoryItems({
          variables: {
            priceId: priceListId,
            items: items,
          },
        });
      }
      addCategoryRef.current?.show(false);
    },
    [
      addCategoryRef,
      priceListId,
      updatePriceListCategoryItems,
      createPriceListCategory,
      uploadSupplierPrice,
    ]
  );

  React.useEffect(() => {
    const data = priceListData?.getPrice?.categorisedItems;
    const category =
      find(data, { _id: selectedCategory?._id }) ||
      head(priceListData?.getPrice?.categorisedItems);
    setCategory(category || null);
  }, [priceListData, selectedCategory]);

  React.useEffect(() => {
    setNavigationContext({
      ...navigationContext,
      priceList: priceListData?.getPrice,
    });
  }, [priceListData, setNavigationContext]);

  const handleCategorySelect = React.useCallback((category) => {
    setCategory(category);
  }, []);

  const client = useApolloClient();

  const handlePrint = React.useCallback(async () => {
    const pdfPreview = await client.query<PreviewPriceListResponse>({
      query: PRICE_PREVIEW,
      fetchPolicy: "network-only",
      variables: {
        priceId: priceListId,
      },
    });

    printBase64Pdf(pdfPreview?.data?.getPricePreview.pdf);
  }, [priceListId]);

  const toggleUpdateModal = React.useCallback(() => {
    const shouldShow = addCategoryRef.current?.shouldShow();
    setEditingCategory(!shouldShow ? selectedCategory : null);
    addCategoryRef.current?.show(!shouldShow);
  }, [selectedCategory, addCategoryRef]);

  const toggleAdjustPriceModal = React.useCallback(() => {
    setAdjustModalVisibility(!isAdjustModalVisible);
  }, [isAdjustModalVisible]);

  const handleUpload = React.useCallback(async () => {
    addCategoryRef.current?.showUpload();
  }, [addCategoryRef]);

  const handleDownload = React.useCallback(async () => {
    if (!priceListData?.getPrice) return null;

    const filename = `${priceListData?.getPrice?.name?.replace(
      /[^a-z0-9\-]/gi,
      "_"
    )}.xlsx`;
    const header = [
      t("priceList.category"),
      t("priceList.sku"),
      t("priceList.name"),
      t("priceList.uom"),
      t("priceList.cost"),
    ];

    const rows = reduce(
      priceListData?.getPrice?.categorisedItems,
      (values: any, category: any) => {
        const items = map(category?.items, (item) => [
          category.name,
          item.sku,
          item.name,
          item.UOM,
          item.cost,
        ]);

        return values.concat(items);
      },
      [header as any] as any
    );

    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(rows);

    /* add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, "page1");

    /* write workbook */
    XLSX.writeFile(wb, filename);
  }, [priceListData]);

  const handleAdjustPriceSubmit = React.useCallback(
    async (values: BulkPriceListAdjustPayload) => {
      await adjustPriceList({
        variables: {
          priceId: priceListId,
          amount: values.amount,
        },
      });

      toggleAdjustPriceModal();
    },
    [priceListId, toggleAdjustPriceModal]
  );

  const handleCategoryDelete = React.useCallback(async () => {
    if (!deleteTargetId) {
      return;
    }

    try {
      await deletePriceListCategory({
        variables: {
          priceId: priceListId,
          categoryId: deleteTargetId,
        },
      });

      notify({
        title: t("costing.deleteCosting"),
        content: t("costing.success.deleteCosting"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("costing.deleteCosting"),
        content: t("costing.errors.deleteCosting"),
      });
    }

    setDeleteConfirmationVisibility(false);
    setDeleteTarget("");
  }, [deletePriceListCategory, priceListId, deleteTargetId]);

  const categoryData = React.useMemo<TableCardData<PriceListItem>>(() => {
    return {
      columns: [
        {
          valueKey: "sku",
          title: t("priceList.sku"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "name",
          title: t("priceList.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "UOM",
          title: t("priceList.uom"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "cost",
          title: t("priceList.cost"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(selectedCategory?.items, (item) => ({
        cells: { ...item },
      })),
    };
  }, [t, selectedCategory]);

  const headerAction = React.useMemo<TableCardAction>(
    () => ({
      className: "button large info bg-transparent text-light",
      icon: "edit",
      disabled: !selectedCategory,
      onClick: toggleUpdateModal,
      title: t("common.edit"),
    }),
    [t, selectedCategory, toggleUpdateModal]
  );

  const deletingCategoryTitle = React.useMemo(() => {
    const category = find(priceListCategoryData?.listPriceCategory, {
      _id: deleteTargetId,
    });

    return category?.name;
  }, [priceListCategoryData, deleteTargetId]);

  const uoms = React.useMemo(
    () =>
      chain(priceListCategoryData?.listPriceCategory)
        .reduce((items: UOMOption[], next: PriceListCategory) => {
          return items.concat(
            map(next.items, (item) => ({ label: item.UOM, value: item.UOM }))
          );
        }, MEASUREMENT_OPTIONS)
        .uniqBy("value")
        .value(),
    [priceListCategoryData?.listPriceCategory]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet
        title={t("navigation.settings.priceListName", {
          name: priceListData?.getPrice?.name,
        })}
      />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SETTINGS.PRICE_LIST} />
      {priceListCategoryData?.listPriceCategory && (
        <AddPriceListCategoryModal
          ref={addCategoryRef}
          initialValues={
            (editingCategory as AddPriceListCategoryPayload) || { name: "" }
          }
          categories={priceListCategoryData?.listPriceCategory}
          onSubmit={handleAddCategorySubmit}
          onClose={toggleUpdateModal}
          uoms={uoms}
        />
      )}

      <AdjustPriceListModal
        previewValues={selectedCategory as PriceListCategory}
        show={isAdjustModalVisible}
        onSubmit={handleAdjustPriceSubmit}
        onClose={toggleAdjustPriceModal}
      />

      <ConfirmDialog
        disabled={costingDeleting}
        title={deletingCategoryTitle}
        onSubmit={handleCategoryDelete}
        show={showDeleteConfirmation}
        onClose={closeDeleteDialog}
      >
        <span className="field-text">
          {t("costing.deleteCostingCategoryMessage")}
        </span>
      </ConfirmDialog>
      <Row className="h-100">
        <Col lg={4} xs={12}>
          {priceListData?.getPrice && (
            <PriceListCategories
              categories={priceListData?.getPrice?.categorisedItems}
              onAddClick={() => addCategoryRef.current?.show(true)}
              selectedCategory={selectedCategory}
              onDelete={openDeleteDialog}
              onCategorySelect={handleCategorySelect}
              onPriceAdjustPress={toggleAdjustPriceModal}
              onDownloadPress={handleDownload}
              onUploadPress={handleUpload}
              onPrint={handlePrint}
            />
          )}
        </Col>
        <Col lg={8} xs={12}>
          <TableCard
            isDataLoading={
              costingLoading ||
              priceListLoading ||
              isEmpty(priceListCategoryData?.listPriceCategory)
            }
            headerAction={headerAction}
            titleBadge={selectedCategory?.name}
            rowCount
            title={""}
            data={categoryData}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(withDashboardContext(PriceList));
