import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { get } from "lodash";
import {
  DeleteTakeOffTemplatePayload,
  DeleteTakeOffTemplatePayloadResponse,
  ImportTakeOffTemplatePayload,
  ImportTakeOffTemplatePayloadResponse,
  ListSalesQuoteTakeOffTemplates,
  SaveTakeOffAsTemplatePayload,
  SaveTakeOffAsTemplateResponse,
} from "../models/salesQuote";
import {
  DELETE_TAKE_OFF_TEMPLATE,
  IMPORT_TAKE_OFF_TEMPLATE,
  SAVE_TAKE_OFF_AS_TEMPLATE,
} from "../graphql/queries/quote/mutations";
import { notify } from "../components/notification";
import { SALES_QUOTE_TAKE_OFF_TEMPLATES } from "../graphql/queries/quote/queries";
import {
  handleImportTakeoffFromTemplate,
  handleTakeoffTemplateDelete,
  handleTakeoffTemplateSave,
} from "../graphql/queries/quote/utils";

export type TakeOffTemplateProps = {
  jobId?: string;
  onImport?: () => void;
  onSave?: () => void;
};

export function useTakeOffTemplate(props?: TakeOffTemplateProps) {
  const { t } = useTranslation();
  const {
    data: templatesData,
    loading: templatesDataLoading,
    refetch: refetchTakeoffTemplates,
  } = useQuery<ListSalesQuoteTakeOffTemplates>(SALES_QUOTE_TAKE_OFF_TEMPLATES, {
    fetchPolicy: "cache-and-network",
  });

  const [importTakeOffFromTemplate] = useMutation<
    ImportTakeOffTemplatePayloadResponse,
    ImportTakeOffTemplatePayload
  >(IMPORT_TAKE_OFF_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("takeOffSection.success.importFromTemplate"),
        title: t("takeOffSection.importFromTemplate"),
      });
      props?.onImport?.();
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(
          e,
          "message",
          t("takeOffSection.errors.importFromTemplate")
        ),
        title: t("takeOffSection.importFromTemplate"),
      });
    },
    update: handleImportTakeoffFromTemplate,
  });

  const [deleteTakeoffTemplate] = useMutation<
    DeleteTakeOffTemplatePayloadResponse,
    DeleteTakeOffTemplatePayload
  >(DELETE_TAKE_OFF_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("takeOffSection.success.deleteTemplate"),
        title: t("takeOffSection.deleteTemplate"),
      });
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("takeOffSection.errors.deleteTemplate")),
        title: t("takeOffSection.deleteTemplate"),
      });
    },
    update: handleTakeoffTemplateDelete,
  });

  const [saveTakeOffAsTemplate] = useMutation<
    SaveTakeOffAsTemplateResponse,
    SaveTakeOffAsTemplatePayload
  >(SAVE_TAKE_OFF_AS_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("takeOffSection.success.saveAsTemplate"),
        title: t("takeOffSection.saveAsTemplate"),
      });
      props?.onSave?.();
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("takeOffSection.errors.saveAsTemplate")),
        title: t("takeOffSection.saveAsTemplate"),
      });
    },
    update: handleTakeoffTemplateSave,
  });

  const takeOffTemplates = React.useMemo(
    () => templatesData?.listSalesQuoteTakeOffTemplates || [],
    [templatesData]
  );

  return {
    takeOffTemplates,
    templatesDataLoading,
    saveTakeOffAsTemplate,
    importTakeOffFromTemplate,
    refetchTakeoffTemplates,
    deleteTakeoffTemplate,
  };
}
