import React from "react";
import {
  DigitalSignature,
  EnumDigitalSignatureType,
} from "../../accept-signature-dialog/utils";
import { useTranslation } from "react-i18next";
import moment from "moment";

type DashboardCardSignFieldType = {
  signature?: DigitalSignature;
  signatureType?: EnumDigitalSignatureType;
  isLoading?: boolean;
};

const DashboardCardSignField: React.FC<DashboardCardSignFieldType> = (
  props
) => {
  const { signature, signatureType, isLoading } = props;
  const { t } = useTranslation();

  const isBulderApproval = signatureType === EnumDigitalSignatureType.BUILDER;

  return (
    <div className="card-field">
      <div className="field-text card-field-header text-truncate">
        {isBulderApproval
          ? t("variations.builderApproval")
          : t("variations.customerApproval")}
      </div>
      <div className="signature-container">
        {signature?.file?.url && !isLoading && (
          <img
            alt={t("variations.signedBy")}
            className="signature"
            src={`${signature?.file?.url}`}
          />
        )}
      </div>
      {signature?.file?.url && !isLoading && (
        <div className="field-text mt-4">
          {t("variations.signedBy")}
          <span className="font-weight-bold">{` ${signature?.name} `}</span>
          {t("common.on")}
          <span className="font-weight-bold">
            {` ${moment(signature?.date).format("DD MMMM YYYY")}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default DashboardCardSignField;
