import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { CreateTaskPayload } from "../../../models/job";

export const saveScheduleAsTemplateFields = (
  t: TFunction
): GenericFormFields<CreateTaskPayload> => {
  return {
    name: {
      type: "text",
      label: t("schedule.templateName"),
      placeholder: t("schedule.templateName"),
      valueKey: "scheduleTemplate.name",
      showError: true,
      inputProps: {},
    },
    saveAssignedUsers: {
      type: "toggle",
      valueKey: "scheduleTemplate.saveAssignedUsers",
      toggleProps: {
        label: t("schedule.saveAssignedUsers"),
      },
    },
    saveAssignedSuppliers: {
      type: "toggle",
      valueKey: "scheduleTemplate.saveAssignedSuppliers",
      toggleProps: {
        label: t("schedule.saveAssignedSuppliers"),
      },
    },
    saveReminders: {
      type: "toggle",
      valueKey: "scheduleTemplate.saveReminders",
      toggleProps: {
        label: t("schedule.saveReminders"),
      },
    },
  };
};
