import React, { forwardRef, useImperativeHandle } from "react";
import CreateClientModal from "../../../components/contacts/create-client-modal";
import { CreateClientPayload } from "../../../models/client";
import { notify } from "../../../components/notification";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_UPDATE_CLIENT } from "../../../graphql/queries/client/mutations";
import { handleClientAdd } from "../../../graphql/queries/client/utils";

type CreateClient = {
  closeOnSubmit?: boolean;
  onCreatedRecord?: (contactId: string) => void;
};

export type CreateClientRef = {
  show: (show: boolean) => void;
};

const CreateClient: React.FC<CreateClient> = (props, ref) => {
  const { closeOnSubmit = true, onCreatedRecord } = props;

  const [show, setShow] = React.useState(false);

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => {
      setShow(show);
    },
  }));

  const [addClient] = useMutation(CREATE_UPDATE_CLIENT, {
    onCompleted: ({ createUpdateClient }) => {
      if (onCreatedRecord) onCreatedRecord(createUpdateClient._id);
      closeOnSubmit && setShow(false);
    },
    update: handleClientAdd,
  });

  const handleClientSubmit = React.useCallback(
    async (contact: CreateClientPayload) => {
      try {
        await addClient({ variables: { contact } });
        notify({
          content: t("contacts.success.createContact"),
          title: t("contacts.createContact"),
        });
      } catch (e) {
        notify({
          error: true,
          content: t("contacts.errors.createContact"),
          title: t("contacts.createContact"),
        });
      }
    },
    [t, addClient]
  );

  return (
    <CreateClientModal
      show={!!show}
      onSubmit={handleClientSubmit}
      onClose={() => setShow(false)}
    />
  );
};

export default forwardRef(CreateClient);
