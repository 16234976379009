import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import {
  ChecklistEntityResult,
  EnumChecklistEntityType,
} from "../../../models/checklists";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardCard from "../../../components/dashboard/card";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../../components/dashboard/card/DashboardCardBody";
import ChecklistBadge from "../../../components/checklists/checklist-badge/ChecklistBadge";
import { calcChecklistCategoryProgress } from "../../../components/checklists/checklists-categories/utils";
import { useChecklistsByEntity } from "../../../hooks/useChecklistsByEntity";

const JobChecklists: React.FC = () => {
  const { t } = useTranslation();
  const {
    selectedCategory,
    renderViewButton,
    renderChecklistsCategoryItems,
    renderChecklistsCategories,
  } = useChecklistsByEntity({ entityType: EnumChecklistEntityType.Job });

  return (
    <Container fluid className="m-0 p-0 h-100 job-checklists">
      <Helmet title={t("navigation.jobsSection.checklistsReport")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.CHECKLISTS} />
      <Row className="h-100">
        <Col lg={4} xs={12}>
          {renderChecklistsCategories()}
        </Col>
        <Col lg={8} xs={12}>
          <DashboardCard>
            <DashboardCardHeader className="justify-content-between">
              <div className="d-flex align-items-center text-capitalize">
                <span className="mr-3">{selectedCategory?.entity.name}</span>
                {selectedCategory && (
                  <ChecklistBadge
                    progress={calcChecklistCategoryProgress(
                      selectedCategory as ChecklistEntityResult
                    )}
                  />
                )}
              </div>
              {renderViewButton()}
            </DashboardCardHeader>
            <DashboardCardBody className="category-container">
              {renderChecklistsCategoryItems()}
            </DashboardCardBody>
          </DashboardCard>
        </Col>
      </Row>
    </Container>
  );
};
export default JobChecklists;
