import { gql } from "@apollo/client";
import { SIGNATURE_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_VARIATION = gql`
  mutation JobCreateUpdateVariation($jobId: ID, $variation: VariationInput) {
    jobCreateUpdateVariation(jobId: $jobId, variation: $variation) {
      _id
      name
      status
      total
      GST
      note
      variationNumber
      progressClaimed
      date
      subTotal
      contact {
        last_name
        first_name
      }
      items {
        name
        UOM
        cost
        quantity
        raw_quantity
        margin_amount
        hasGST
        wastage
        rounding
      }
    }
  }
`;

export const DELETE_VARIATION = gql`
  mutation JobDeleteVariation($jobId: ID, $variationId: ID) {
    jobDeleteVariation(jobId: $jobId, variationId: $variationId) {
      _id
    }
  }
`;

export const CANCEL_VARIATION = gql`
  mutation JobCancelVariation($jobId: ID, $variationId: ID) {
    jobCancelVariation(jobId: $jobId, variationId: $variationId) {
      _id
      status
    }
  }
`;

export const BUILDER_APPROVE_VARIATION = gql`
  mutation jobBuilderApproveVariation(
    $jobId: ID
    $variationId: ID
    $signature: DigitalSignatureInput!
  ) {
    approve: jobBuilderApproveVariation(
      jobId: $jobId
      variationId: $variationId
      signature: $signature
    ) {
      _id
      status
      clientSignature {
        ...SignatureFragment
      }
      builderSignature {
        ...SignatureFragment
      }
    }
  }
  ${SIGNATURE_FRAGMENT}
`;

export const UNAPPROVE_VARIATION = gql`
  mutation($jobId: ID!, $variationId: ID!) {
    approve: jobUnapproveVariation(jobId: $jobId, variationId: $variationId) {
      _id
      status
      clientSignature {
        ...SignatureFragment
      }
      builderSignature {
        ...SignatureFragment
      }
    }
  }
  ${SIGNATURE_FRAGMENT}
`;

export const SEND_VARIATION = gql`
  mutation JobSendVariation(
    $jobId: ID
    $variationId: ID
    $message: JobSendEmailInput
  ) {
    jobSendVariation(
      jobId: $jobId
      variationId: $variationId
      message: $message
    ) {
      _id
      status
    }
  }
`;
