import React, { useState, useEffect, Ref } from "react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import {
  AddPriceListCategoryPayload,
  PriceListItem,
  PriceListUpdateMethod,
} from "../../../../models/price-list";
import CreateEntityModal from "../../../modals/create-entity";
import { PriceListCategory } from "../../../../models/price-list";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import createAddPriceListCategoryFields from "./utils";
import { FILE_RESTRICTION_SPREADSHEET } from "../../../uploaders/file-input";
import ImportMapper, {
  ImportMapperRef,
} from "../../../../components/import-mapper";
import { MapResult } from "../../../../components/import-mapper/types";
import createPriceListCategorySchema from "./AddCategory.schema";

import { FIELD_MAP_PRICE_LIST } from "./constants";
import "./styles.scss";
import { UOMOption } from "../../../../utils/types/options";
import { FormikHelpers } from "formik";
import {
  useModalDisplay,
  ModalDisplayRef,
} from "../../../../hooks/useModalDisplay";
import { useImportMapper } from "../../../../hooks/useImportMapper";
import { useUOM } from "../../../../hooks/useUOM";
import { calcGSTEx, GST_PERCENT } from "../../../../utils/calculations";
import { useCostChangeGST } from "../../../../hooks/useCostChangeGST";

type AddPriceListValues = AddPriceListCategoryPayload & {
  method?: PriceListUpdateMethod;
};

type AddPriceListCategoryProps = {
  categories: PriceListCategory[];
  initialValues: AddPriceListCategoryPayload;
  uoms: UOMOption[];
  onSubmit: (data: AddPriceListValues) => void;
  onClose: () => void;
};

export type AddPriceListCategoryModalRef = ModalDisplayRef & {
  showUpload: () => void;
};

const AddPriceListCategoryModal: React.FC<AddPriceListCategoryProps> = <
  Data extends {}
>(
  props: AddPriceListCategoryProps,
  ref: Ref<any>
) => {
  const [mapValues, setMapValues] = useState<MapResult<Data>>();
  const [uploadMethod, setUploadMethod] = useState<string>(
    PriceListUpdateMethod.INPUT
  );

  const {
    onSubmit,
    onClose,
    categories,
    uoms: currentUOMs,
    initialValues,
  } = props;
  const {
    costsHaveGST,
    handleCostsHaveGST,
    resetCostsHaveGST,
  } = useCostChangeGST();
  const { uoms, handleUOMCreate } = useUOM(initialValues.items);
  const [selectedCategory, setSelectedCategory] = useState<
    AddPriceListCategoryPayload
  >();
  const importRef = React.useRef<ImportMapperRef>(null);

  const onImport = React.useCallback(async (data: any[]) => {
    // console.log({ data });
  }, []);

  const {
    file,
    headerRow,
    rows,
    handleFileAccept,
    handleReset,
  } = useImportMapper();

  const { shouldShow, hide, show } = useModalDisplay(ref, {
    showUpload: () => {
      setUploadMethod(PriceListUpdateMethod.UPLOAD);
      show();
    },
  });

  useEffect(() => {
    setSelectedCategory({
      ...initialValues,
      name: initialValues.name || "",
    });
  }, [initialValues, setSelectedCategory]);

  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<
    GenericFormFields<AddPriceListValues>
  >({});

  const onMethodChange = React.useCallback(
    (method: string) => {
      setUploadMethod(method);
    },
    [setUploadMethod, uploadMethod]
  );

  const fileName = React.useMemo(() => file?.name, [file]);

  React.useEffect(() => {
    const fields = createAddPriceListCategoryFields(
      t,
      uoms,
      handleUOMCreate,
      onMethodChange,
      handleFileAccept,
      costsHaveGST,
      handleCostsHaveGST,
      FILE_RESTRICTION_SPREADSHEET,
      fileName,
      uploadMethod as PriceListUpdateMethod
    );

    setFormFields(fields);
  }, [fileName, uploadMethod, uoms, costsHaveGST]);

  const handleMapChange = React.useCallback((values: MapResult<Data>) => {
    setMapValues(values);
  }, []);

  const handleClose = React.useCallback(() => {
    handleReset();
    setUploadMethod(PriceListUpdateMethod.INPUT);
    onClose();
    resetCostsHaveGST();
  }, [onClose, hide, handleReset]);

  const handleFormSubmit = React.useCallback(
    (props: any, helpers?: FormikHelpers<any>) => {
      let items = props?.items?.map((item: PriceListItem) => {
        return {
          ...omit(item, ["cost_inc"]),
          cost: costsHaveGST
            ? calcGSTEx(item.cost_inc || 0, GST_PERCENT)
            : item.cost
            ? item.cost
            : 0,
        };
      });

      if (mapValues && uploadMethod === PriceListUpdateMethod.UPLOAD) {
        items = importRef.current?.prepareMap(mapValues);
        if (!items) return false;
        items = items?.map((item: PriceListItem) => {
          return {
            ...omit(item, ["cost_inc"]),
            cost: !item.cost
              ? calcGSTEx(item.cost_inc || 0, GST_PERCENT)
              : item.cost
              ? item.cost
              : 0,
          };
        });
      }
      onSubmit({ ...props, items });
      helpers?.resetForm();
      handleClose();
    },
    [mapValues, uploadMethod, importRef, costsHaveGST]
  );

  const mapDisplayOptions = {
    hideSample: true,
    renderTableContainer: (children: any) => children,
  };

  const fileUploader = () => {
    if (uploadMethod !== PriceListUpdateMethod.UPLOAD) return null;
    return (
      <div className="uploader">
        {headerRow && (
          <ImportMapper
            ref={importRef}
            onChange={handleMapChange}
            options={FIELD_MAP_PRICE_LIST}
            rows={rows}
            header={headerRow}
            displayOptions={mapDisplayOptions}
            onSubmit={onImport}
          />
        )}
      </div>
    );
  };

  return (
    <CreateEntityModal
      validationSchema={createPriceListCategorySchema(t)}
      show={shouldShow}
      data={
        {
          ...initialValues,
          method: uploadMethod,
        } as AddPriceListValues
      }
      className="create-category-modal update-category-modal"
      onSubmit={handleFormSubmit}
      onClose={handleClose}
      title={t("priceList.addPriceListCategory")}
      fields={formFields}
      endRenderer={fileUploader()}
    />
  );
};

export default React.forwardRef(AddPriceListCategoryModal);
