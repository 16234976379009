import React from "react";
import { useTranslation } from "react-i18next";
import { map, reduce, uniqBy } from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  TableCardData,
  TableCardDataColumn,
  TableCardDataRow,
} from "../../dashboard/table-card/utils";
import { JobCostingPurchaseOrderItem } from "../../../models/job";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import LeftModal from "../../left-modal";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import CardTable from "../../dashboard/table-card/CardTable";
import { formatQuoteNumber } from "../../../utils/text";
import "./styles.scss";
import {
  PurchaseOrderReceipt,
  PurchaseOrderReceiptTableItem,
} from "../../../models/purchaseOrder";

type PurchaseOrdersTable = {
  _id: string;
  date: string;
  name: string;
  quantity: number;
  UOM: string;
  cost: number;
  total: number;
  status: string;
  purchaseOrderName: string;
  supplier: string;
  jobId: string;
};

export type ListPurchaseOrdersModalRef = ModalDisplayRef & {
  setData: (
    data: JobCostingPurchaseOrderItem[],
    jobCostingItemId?: string
  ) => void;
};

const ListPurchaseOrdersModal: React.FC = (_props, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = React.useState<{
    jobCostingItems: JobCostingPurchaseOrderItem[];
    jobCostingItemId: string | null;
  }>({ jobCostingItems: [], jobCostingItemId: null });

  const { shouldShow, hide } = useModalDisplay<ListPurchaseOrdersModalRef>(
    ref,
    {
      setData: (
        data: JobCostingPurchaseOrderItem[],
        jobCostingItemId?: string
      ) => {
        if (jobCostingItemId) {
          setData({ jobCostingItems: data, jobCostingItemId });
        } else {
          setData({ jobCostingItems: data, jobCostingItemId: null });
        }
      },
    } as ListPurchaseOrdersModalRef,
    [setData]
  );

  const receiptsData = React.useMemo(() => {
    const jobCostingItems = uniqBy(data.jobCostingItems, "orderId");
    const receipts = jobCostingItems.reduce((accum, order) => {
      const { purchaseOrder } = order;
      if (purchaseOrder?.receipts.length) {
        return accum.concat(purchaseOrder?.receipts);
      }
      return accum;
    }, [] as PurchaseOrderReceipt[]);
    return receipts
      .reduce((items, receipt) => {
        const receiptItems = receipt.items.map((item) => ({
          ...item,
          reference: receipt.reference,
          dateReceived: receipt.dateReceived,
          purchaseOrder: receipt.purchaseOrder,
          total: 0,
          supplier: receipt.supplier,
        }));

        return items.concat(receiptItems as PurchaseOrderReceiptTableItem[]);
      }, [] as PurchaseOrderReceiptTableItem[])
      .filter(
        (receipt) =>
          receipt.purchaseOrderItem?.costingItemId === data?.jobCostingItemId
      );
  }, [data]);

  const tableData = React.useMemo<TableCardData<PurchaseOrdersTable>>(() => {
    const columns = [
      {
        valueKey: "date",
        title: t("orders.orderDate"),
        formatValue: (row: any, column: any, value: number) =>
          value && moment(value).format("Do MMMM YYYY"),
      },
      {
        valueKey: "purchaseOrderName",
        title: t("orders.purchaseOrder"),
        className: "table-cell-link",
        onClick: (row: any) => {
          hide();
          history.push(
            `/jobs/${row.cells.jobId}/purchase-orders/${row.cells._id}`
          );
        },
      },
      {
        valueKey: "supplier",
        title: t("common.supplier"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "status",
        title: t("common.status"),
        formatValue: (row: any, column: any, value: number) =>
          value && t(`orders.statuses.${value}`),
      },
      {
        valueKey: "name",
        title: t("orders.nameLabel"),
      },
      {
        valueKey: "quantity",
        title: t("costing.quantity"),
        formatValue: (row: any, column: any, value: number) =>
          value && t("common.quantity", { amount: value }),
      },
      {
        valueKey: "UOM",
        title: t("costing.uom"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "cost",
        title: t("costing.cost"),
        formatValue: (row: any, column: any, value: number) =>
          value !== undefined && t("common.currency", { amount: value }),
      },
      {
        valueKey: "total",
        title: t("costing.totalEx"),
        formatValue: (row: any, column: any, value: number) =>
          t("common.currency", { amount: value }),
      },
    ] as TableCardDataColumn<PurchaseOrdersTable>[];

    const rows = map(data.jobCostingItems, (item) => ({
      cells: {
        _id: item.purchaseOrder._id,
        jobId: item.purchaseOrder?.job?._id || "",
        name: item.name,
        quantity: item.quantity,
        UOM: item.UOM,
        cost: item.cost,
        total: item.cost * item.quantity,
        date: item.purchaseOrder.date,
        status: item.purchaseOrder.status,
        supplier: item.purchaseOrder.supplier?.business_name,
        purchaseOrderName: formatQuoteNumber(
          item.purchaseOrder.orderNumber,
          "PO"
        ),
      },
    })) as TableCardDataRow<PurchaseOrdersTable>[];

    if (rows.length) {
      rows.push({
        isTotal: true,
        cells: reduce(
          rows,
          (result: PurchaseOrdersTable, row) => {
            result["total"] += row?.cells?.total || 0;

            return result;
          },
          {
            total: 0,
          } as PurchaseOrdersTable
        ),
      } as TableCardDataRow<PurchaseOrdersTable>);
    }

    return { columns, rows };
  }, [data, hide]);

  const receiptsTableData = React.useMemo<
    TableCardData<PurchaseOrderReceiptTableItem>
  >(() => {
    const columns = [
      {
        valueKey: "dateReceived",
        title: t("orders.dateReceived"),
        formatValue: (row: any, column: any, value: number) =>
          value && moment(value).format("Do MMMM YYYY"),
      },
      {
        valueKey: "purchaseOrderName",
        title: t("orders.purchaseOrder"),
        className: "table-cell-link",
        onClick: (row: any) => {
          hide();
          history.push(
            `/jobs/${row.cells.jobId}/purchase-orders/${row.cells._id}`
          );
        },
      },
      {
        valueKey: "supplier",
        title: t("common.supplier"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "name",
        title: t("orders.nameLabel"),
      },
      {
        valueKey: "reference",
        title: t("common.reference"),
      },
      {
        valueKey: "quantity",
        title: t("orders.receivedQuantity"),
        formatValue: (row: any, column: any, value: number) =>
          value && t("common.quantity", { amount: value }),
      },
      {
        valueKey: "UOM",
        title: t("costing.uom"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "cost",
        title: t("costing.cost"),
        formatValue: (row: any, column: any, value: number) =>
          value !== undefined && t("common.currency", { amount: value }),
      },
      {
        valueKey: "total",
        title: t("costing.totalEx"),
        formatValue: (row: any, column: any, value: number) =>
          t("common.currency", { amount: value }),
      },
    ] as TableCardDataColumn<PurchaseOrderReceiptTableItem>[];

    const rows = map(receiptsData, (item) => ({
      cells: {
        _id: item.purchaseOrder?._id,
        jobId: item.purchaseOrder?.job?._id || "",
        name: item.name,
        reference: item.reference,
        dateReceived: item.dateReceived,
        purchaseOrderName: formatQuoteNumber(
          item?.purchaseOrder?.orderNumber || 0,
          "PO"
        ),
        quantity: item.quantity,
        UOM: item.UOM,
        cost: item.cost,
        total: item.cost * item.quantity,
        supplier: item.purchaseOrder?.supplier?.business_name,
      },
    })) as TableCardDataRow<PurchaseOrderReceiptTableItem>[];

    if (rows.length) {
      rows.push({
        isTotal: true,
        cells: reduce(
          rows,
          (result: PurchaseOrderReceiptTableItem, row) => {
            result["total"] += row?.cells?.total || 0;

            return result;
          },
          {
            total: 0,
          } as PurchaseOrderReceiptTableItem
        ),
      } as TableCardDataRow<PurchaseOrderReceiptTableItem>);
    }

    return { columns, rows };
  }, [data, hide]);

  return (
    <LeftModal show={shouldShow} onHide={hide} className="list-purchase-orders">
      <LeftModalHeader title={t("orders.purchaseOrders")} onClose={hide} />
      <LeftModalBody>
        <CardTable table={tableData} tableClass="table-outline" />
        {Boolean(receiptsData.length) && (
          <>
            <div className="receipts-table-title">{t("orders.receipts")}</div>
            <CardTable table={receiptsTableData} tableClass="table-outline" />
          </>
        )}
      </LeftModalBody>
    </LeftModal>
  );
};

export default React.forwardRef(ListPurchaseOrdersModal);
