import React from "react";
import { useTranslation } from "react-i18next";
import { find, head } from "lodash";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import {
  ChecklistEntityResult,
  EnumChecklistEntityType,
  ListChecklistCategoiresByEntityResponse,
  ToggleChecklistStatusResponse,
} from "../models/checklists";
import { LIST_CHECKLIST_CATEGORIES_BY_ENTITY_TYPE } from "../graphql/queries/checklists/queries";
import { useFilteredArrayMultipleField } from "./useFilteredArray";
import ChecklistsCategories, {
  ChecklistFilterEnum,
} from "../components/checklists/checklists-categories";
import { calcChecklistCategoryProgress } from "../components/checklists/checklists-categories/utils";
import { TOGGLE_CHECKLIST_STATUS } from "../graphql/queries/checklists/mutation";
import { DropdownItem } from "../components/dashboard/dropdown";
import { generatePath, useHistory } from "react-router-dom";
import ChecklistsItem from "../components/checklists/checklists-item";
import EmptyPlaceholder from "../components/empty-placeholder";

export type ChecklistsByEntityProps = {
  entityType: EnumChecklistEntityType;
};

const pathByEntityType = {
  [EnumChecklistEntityType.Job]: "jobs",
  [EnumChecklistEntityType.Quote]: "quotes",
};

const viewButtonNameByEntityType = {
  [EnumChecklistEntityType.Job]: "jobs.viewInJob",
  [EnumChecklistEntityType.Quote]: "quotes.viewInEstimation",
};

export function useChecklistsByEntity(props: ChecklistsByEntityProps) {
  const { entityType } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const [
    selectedCategory,
    setCategory,
  ] = React.useState<ChecklistEntityResult | null>(null);
  const {
    data: listChecklistCategoriesData,
    loading: loadinglistChecklistCategories,
  } = useQuery<ListChecklistCategoiresByEntityResponse>(
    LIST_CHECKLIST_CATEGORIES_BY_ENTITY_TYPE,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        entityType,
      },
    }
  );

  const checklistsCategories = React.useMemo(() => {
    return (
      listChecklistCategoriesData?.listChecklistCategoiresByEntityType.map(
        (item) => ({ ...item, name: item.entity.name })
      ) || []
    );
  }, [listChecklistCategoriesData]);

  const {
    filteredArray,
    filter: inputFilter,
    setFilter: setSearchByInput,
    setFilterbySelect: setArrayFilter,
    filterSelect: arrayFilter,
  } = useFilteredArrayMultipleField(
    checklistsCategories,
    ["name"],
    true,
    (item: ChecklistEntityResult, search: string) => {
      if (search === ChecklistFilterEnum.Incomplete) {
        const progress = calcChecklistCategoryProgress(item);
        return progress > 0 && progress < 100;
      }
      if (search === ChecklistFilterEnum.Complete) {
        const progress = calcChecklistCategoryProgress(item);
        return progress === 100;
      }
      if (search === ChecklistFilterEnum.NotStarted) {
        const progress = calcChecklistCategoryProgress(item);
        return progress === 0;
      }
      return true;
    }
  );

  React.useEffect(() => {
    const data =
      filteredArray ||
      listChecklistCategoriesData?.listChecklistCategoiresByEntityType;
    const selectionCategory =
      find(data, { _id: selectedCategory?._id }) || head(data);

    setCategory(selectionCategory || null);
  }, [listChecklistCategoriesData, filteredArray]);

  const filterDropdownItems = React.useMemo<DropdownItem[]>(
    () => [
      {
        id: "-",
        label: t("common.all"),
        onClick: () => setArrayFilter(""),
      },
      {
        id: t("tasks.checklistFilterType.notStarted"),
        label: t("tasks.checklistFilterType.notStarted"),
        onClick: () => setArrayFilter(ChecklistFilterEnum.NotStarted),
      },
      {
        id: t("tasks.checklistFilterType.incomplete"),
        label: t("tasks.checklistFilterType.incomplete"),
        onClick: () => setArrayFilter(ChecklistFilterEnum.Incomplete),
      },
      {
        id: t("tasks.checklistFilterType.complete"),
        label: t("tasks.checklistFilterType.complete"),
        onClick: () => setArrayFilter(ChecklistFilterEnum.Complete),
      },
    ],
    [setArrayFilter, t]
  );

  const [
    toggleChecklistStatus,
    { loading: loadingToggleChecklistStatus },
  ] = useMutation<ToggleChecklistStatusResponse>(TOGGLE_CHECKLIST_STATUS);

  const handleToggleChecklistStatus = React.useCallback(
    (row: { _id: string; completed: boolean }, categoryId: string) => {
      toggleChecklistStatus({
        variables: {
          entityId: selectedCategory?.entity._id,
          entityType,
          checklistId: row._id,
          isComplete: !row.completed,
          categoryId,
        },
      });
    },
    [entityType, selectedCategory, toggleChecklistStatus]
  );

  const handleCategorySelect = React.useCallback(
    (category: ChecklistEntityResult) => {
      setCategory(category);
    },
    []
  );

  const isLoading =
    loadinglistChecklistCategories || loadingToggleChecklistStatus;

  const renderChecklistsCategories = () => (
    <ChecklistsCategories
      selectedCategory={selectedCategory}
      selectCategory={handleCategorySelect}
      categories={filteredArray}
      searchOptions={{
        value: inputFilter,
        onChange: setSearchByInput,
      }}
      filterDropdownItems={filterDropdownItems}
      dropdownFilter={arrayFilter}
      entityType={entityType}
    />
  );

  const renderChecklistsCategoryItems = () => {
    return selectedCategory?.categories.length ? (
      selectedCategory?.categories.map((item) => {
        return (
          <ChecklistsItem
            key={item._id}
            item={item}
            onToggleChecklistStatus={handleToggleChecklistStatus}
            isLoading={isLoading}
          />
        );
      })
    ) : (
      <EmptyPlaceholder
        message={t("selections.emptyCategoryItemsPlaceholder")}
      />
    );
  };

  const handleViewButton = React.useCallback(() => {
    if (!selectedCategory?.entity._id) return;
    const url = generatePath(`/${pathByEntityType[entityType]}/:id`, {
      id: selectedCategory?.entity._id,
    });
    history.push(url);
  }, [entityType, history, selectedCategory]);

  const buttonView = React.useMemo(() => {
    return {
      id: "view",
      label: t(`${viewButtonNameByEntityType[entityType]}`),
      name: "buttonView",
      onClick: handleViewButton,
    };
  }, [t, entityType, handleViewButton]);

  const renderViewButton = () => (
    <Button
      className="button btn btn-primary bg-transparent text-light mr-3"
      onClick={buttonView.onClick}
      disabled={!Boolean(filteredArray?.length)}
    >
      {buttonView.label}
    </Button>
  );

  return {
    renderChecklistsCategories,
    selectedCategory,
    renderChecklistsCategoryItems,
    renderViewButton,
  };
}
