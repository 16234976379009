import * as yup from "yup";
import { TFunction } from "i18next";

import { find, trim } from "lodash";
import { TodoChecklistTemplates } from "../../../models/task";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function saveChecklistAsTemplateSchema(
  t: TFunction,
  listChecklistTemplates: TodoChecklistTemplates[],
  isEdit?: boolean,
  isChecklistsSection?: boolean
) {
  return yup.object().shape({
    name: yup
      .string()
      .test(
        "duplicateNameTest",
        ({ value }) => t("tasks.checklistTemplateNameExists", { name: value }),
        function (value: string) {
          if (!value) return true;
          const name = trim(value);
          return isEdit ? true : !find(listChecklistTemplates, { name });
        }
      )
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "tasks.templateName")
      ),
    ...(!isChecklistsSection && { type: yup.string().required() }),
  });
}
