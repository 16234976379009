import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { filter, includes, isEmpty, map, reduce } from "lodash";
// import claimSchema from "./Claim.schema";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
// import { createClaimFields } from "./utils";
import ModalForm from "../../modals/modal-form";
import {
  SubscribeAddPaymentMethodReponse,
  SubscribeToPlanPayload,
  SubscribeToPlanReponse,
  SubscriptionPlan,
} from "../../../graphql/types/models/subscription";
import {
  ADD_PAYMENT_METHOD,
  SUBSCRIPE_TO_PLAN,
} from "../../../graphql/queries/subscription/mutations";
import "./styles.scss";
import { notify } from "../../notification";

type UpdatePaymentModalProps = {
  onSuccess: () => void;
};

export type UpdatePaymentModalRef = {
  show: (show: boolean) => void;
};
const UpdatePaymentModal: React.FC<UpdatePaymentModalProps> = (props, ref) => {
  const { t } = useTranslation();

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  const { onSuccess } = props;

  const [showModal, setShowModal] = useState(false);

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => {
      setShowModal(show);
    },
  }));

  const [addPaymenMethod] = useMutation<SubscribeAddPaymentMethodReponse>(
    ADD_PAYMENT_METHOD
  );

  const handleClose = React.useCallback(() => setShowModal(false), []);

  const handleSubmit = React.useCallback(
    async (values: any) => {
      if (!elements || !stripe) return;
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) return;

      const paymentMethod = await addPaymenMethod();
      const clientSecret = paymentMethod.data?.addPaymentMethod.clientSecret;
      if (!clientSecret) return;
      let { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });
      if (setupIntent && setupIntent.status === "succeeded") {
        notify({
          content: t("billing.success.updatePaymentMethod"),
          title: t("billing.changePayment"),
        });
        setShowModal(false);
        return onSuccess();
      } else {
        notify({
          error: true,
          content: error?.message || t("billing.error.payment"),
          title: t("billing.changePayment"),
        });
      }
    },
    [setShowModal]
  );

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return null;
  }

  const createOptions = () => {
    return {
      style: {
        base: {
          fontSize: "16px",
          color: "#495057",
          "::placeholder": {
            color: "#868e96",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };

  return (
    <ModalForm
      className="update-payment-modal"
      show={showModal}
      onClose={handleClose}
      data={{}}
      onSubmit={handleSubmit}
      title={t("billing.changePayment")}
      submitText={t("common.update")}
    >
      {(formikProps) => (
        <Container className="generic-form-body" fluid>
          <label className="form-input-label form-label">
            {t("billing.enterCardDetails")}:
          </label>
          <CardElement
            className="form-control form-input"
            options={createOptions()}
          />
        </Container>
      )}
    </ModalForm>
  );
};

export default forwardRef(UpdatePaymentModal);
