import { TFunction } from "i18next";
import * as yup from "yup";
import {
  SiteDiaryFieldType,
  SiteDiaryTemplate,
} from "../../../graphql/types/models/site-diary";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createSiteDiarySchema(
  t: TFunction,
  isEditing: boolean,
  template: SiteDiaryTemplate | null
) {
  const schema: any = {
    templateId: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "siteDiary.template")
      ),
    name: yup.string().optional(),
    // .required(
    //   ValidationMessagesUtils.createRequiredMessage(t, "siteDiary.title")
    // ),
    entry_date_time: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "siteDiary.entryDate")
      ),
  };
  if (isEditing) {
    schema.templateId = yup.string();
  }

  template?.fields.forEach((field: any) => {
    switch (field.type) {
      case SiteDiaryFieldType.String:
      case SiteDiaryFieldType.Radio:
        schema[field._id] = yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, field.title)
          );
        break;
      case SiteDiaryFieldType.Number:
        schema[field._id] = yup
          .number()
          .typeError(
            ValidationMessagesUtils.createNumberMessage(t, field.title)
          )
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, field.title)
          );
        break;
      case SiteDiaryFieldType.Date:
        schema[field._id] = yup
          .date()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, field.title)
          );
        break;
      case SiteDiaryFieldType.Collection:
        schema[field._id] = yup
          .array()
          .min(1)
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, field.title)
          )
          .of(
            yup
              .string()
              .required(
                ValidationMessagesUtils.createRequiredMessage(t, field.title)
              )
          );
        break;
    }
  });

  return yup.object().shape(schema);
}
