import React from "react";
import { FormikProps } from "formik";
import PriceLookupModal, {
  CostingsfieldName,
} from "../components/costing/price-lookup-modal";
import { Button } from "react-bootstrap";
import { TFunction } from "react-i18next";

export function usePriceLookup(
  t: TFunction,
  fieldName: CostingsfieldName,
  handleUOMCreate: (uom: string) => void,
  isRestrictedEntry: boolean
) {
  const [showPriceLookupModal, setShowPriceLookupModal] = React.useState(false);

  const closePriceLookupModal = React.useCallback(() => {
    setShowPriceLookupModal(false);
  }, []);

  const priceLookupButton = React.useCallback(() => {
    return (
      <div>
        <Button
          className="button large info btn btn-secondary"
          onClick={() => setShowPriceLookupModal(true)}
        >
          {t("costing.priceLookup")}
        </Button>
      </div>
    );
  }, [t]);

  const renderPriceLookupModal = React.useCallback(
    (formikProps: FormikProps<any>) => {
      return (
        <PriceLookupModal
          show={showPriceLookupModal}
          onClose={closePriceLookupModal}
          formikProps={formikProps}
          isRestrictedEntry={isRestrictedEntry}
          onUOMCreate={handleUOMCreate}
          fieldName={fieldName}
        />
      );
    },
    [
      closePriceLookupModal,
      fieldName,
      showPriceLookupModal,
      isRestrictedEntry,
      handleUOMCreate,
    ]
  );

  return { priceLookupButton, renderPriceLookupModal };
}
