import React from "react";
import { useTranslation } from "react-i18next";

import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import ChecklistsCategoryItem from "./ChecklistCategoryItem";
import "./styles.scss";
import {
  ChecklistEntityResult,
  EnumChecklistEntityType,
} from "../../../models/checklists";
import SearchInput from "../../search-input";
import { SearchOptions } from "../../category-select-card";
import EmptyPlaceholder from "../../empty-placeholder";
import Dropdown, { DropdownItem } from "../../dashboard/dropdown";

export enum ChecklistFilterEnum {
  NotStarted = "Not started",
  Incomplete = "Incomplete",
  Complete = "Complete",
}

type ChecklistsCategoriesProps = {
  categories?: ChecklistEntityResult[];
  selectedCategory: ChecklistEntityResult | null;
  searchOptions: SearchOptions;
  selectCategory?: (category: ChecklistEntityResult) => void;
  filterDropdownItems?: DropdownItem[];
  dropdownFilter?: string;
  entityType: EnumChecklistEntityType;
};

const ChecklistsCategories: React.FC<ChecklistsCategoriesProps> = ({
  categories,
  selectedCategory,
  selectCategory,
  searchOptions,
  filterDropdownItems,
  dropdownFilter,
  entityType,
}) => {
  const { t } = useTranslation();

  const renderItem = React.useCallback(
    (item: ChecklistEntityResult) => {
      return (
        <ChecklistsCategoryItem
          key={item._id}
          category={item}
          onClick={selectCategory}
          isSelected={item._id === selectedCategory?._id}
          entityType={entityType}
        />
      );
    },
    [selectCategory, selectedCategory, entityType]
  );

  const renderCategories = React.useCallback(() => {
    return !categories?.length ? (
      <EmptyPlaceholder message={t("placeholders.noCategories")} />
    ) : (
      <div className="checklist-list">
        {categories?.map((item) => {
          return renderItem(item);
        })}
      </div>
    );
  }, [categories, renderItem, t]);

  return (
    <DashboardCard className="checklist-categories">
      <DashboardCardHeader className="text-capitalize justify-content-between">
        <SearchInput
          value={searchOptions.value}
          onChange={searchOptions.onChange}
          className="searchInput"
          showCancelButton
        />
        {filterDropdownItems && (
          <Dropdown
            label={dropdownFilter || t("common.filters")}
            icon="expand_more"
            menuWidth="270px"
            id="filter-dropdown"
            items={filterDropdownItems}
          />
        )}
      </DashboardCardHeader>
      <DashboardCardBody>
        <div className="checklist-body">{renderCategories()}</div>
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end"></DashboardCardFooter>
    </DashboardCard>
  );
};

export default ChecklistsCategories;
