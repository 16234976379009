import React, { useState, useImperativeHandle, forwardRef } from "react";
import * as uuid from "uuid";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import filesize from "filesize";

import CreateEntityModal from "../../modals/create-entity";
import {
  SupplierCertificatioPayload,
  SupplierCertificationType,
} from "../../../models/supplier";
import createAddCertificationFields from "./utils";
import createAddCertificationSchema from "./AddCertification.schema";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import TableCard from "../../dashboard/table-card";
import FileInput from "../../uploaders/file-input";
import {
  TableRowActionData,
  TableCardData,
} from "../../dashboard/table-card/utils";
import "./styles.scss";

type FileTable = {
  _id: string;
  name: string;
  size: number;
};

type FileWithID = {
  _id: string;
  file: File;
};

export type AddCertificationModalRef = {
  show: (show: boolean) => void;
};

type AddCertificationModalProps = {
  onSubmit: (certification: SupplierCertificatioPayload) => void;
  onClose: () => void;
};

const AddCertificationModal: React.FC<AddCertificationModalProps> = (
  props,
  ref
) => {
  const { onSubmit, onClose } = props;

  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState(false);
  const [formFields, setFormFields] = useState<
    GenericFormFields<SupplierCertificatioPayload>
  >({});
  const [files, setFiles] = React.useState<FileWithID[]>([]);

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => setShowModal(show),
  }));

  React.useEffect(() => {
    const fields = createAddCertificationFields(t);

    setFormFields(fields);
  }, [t]);

  const handleRemoveFile = React.useCallback(
    (row?: FileTable) => {
      if (row) {
        setFiles(files.filter((file) => file._id !== row._id));
      }
    },
    [files, setFiles]
  );

  const handleFileUpload = React.useCallback(
    (uploadedFiles: File[]) =>
      setFiles([
        ...files,
        ...uploadedFiles.map((file) => ({ _id: uuid.v4(), file })),
      ]),
    [files]
  );

  const filesTableData = React.useMemo<TableCardData<FileTable>>(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("documents.documentName"),
        },
        {
          valueKey: "size",
          title: t("documents.size"),
          formatValue: (row: any, column: any, value: number) =>
            value ? filesize(value) : "-",
        },
      ],
      rows: map(files, (item) => ({
        cells: {
          _id: item._id,
          name: item.file.name,
          size: item.file.size,
        },
      })),
    };
  }, [t, files]);

  const tableRowActions: TableRowActionData<FileTable>[] = React.useMemo(
    () => [
      {
        icon: "delete",
        id: "remove",
        onClick: handleRemoveFile,
      },
    ],
    [handleRemoveFile]
  );

  const fileUploader = React.useMemo(() => {
    return (
      <div className="uploader-wrapper">
        {files && files.length > 0 && (
          <TableCard
            data={filesTableData}
            rowCount={true}
            rowActions={tableRowActions}
          />
        )}
        <div className="uploader">
          <FileInput onUpload={handleFileUpload} />
        </div>
      </div>
    );
  }, [files, filesTableData, handleFileUpload, tableRowActions]);

  const handleSubmit = React.useCallback(
    (data: SupplierCertificatioPayload) => {
      onSubmit && onSubmit({ ...data, files: files.map((item) => item.file) });
      setFiles([]);
    },
    [files, onSubmit]
  );

  return (
    <CreateEntityModal<SupplierCertificatioPayload>
      validationSchema={createAddCertificationSchema(t)}
      show={showModal}
      className="add-certification-modal"
      onSubmit={handleSubmit}
      data={{
        name: "",
        document_type: SupplierCertificationType.Certificate,
        expiry_date: "",
        note: "",
      }}
      onClose={onClose}
      title={t("certification.addCertification")}
      fields={formFields}
      endRenderer={fileUploader}
    />
  );
};

export default forwardRef(AddCertificationModal);
