import React from "react";
import { ConnectDragSource, useDrop } from "react-dnd";
import { SortableItem } from "./types";
import Item from "./Item";
import { findItem } from "./utils";

type TreeProps = {
  parentId?: string;
  items: SortableItem[];
  renderItem: (item: any, dragRef: ConnectDragSource) => React.ReactElement;
  moveItem: (id: string, afterId?: string, parentId?: string) => void;
  type?: string;
  onDrop?: () => void;
};

const Tree: React.FC<TreeProps> = (props) => {
  const { items, parentId, moveItem, type, renderItem, onDrop } = props;
  const [{}, dropRef] = useDrop({
    accept: type || "item",
    // drop: () => {},
    // collect: monitor => ({
    // isOver: !isPrimary && !!monitor.isOver({ shallow: true }),
    // canDrop: !!monitor.canDrop(),
    // item: monitor.getItem(),
    // dropOffset: monitor.getSourceClientOffset(),
    // }),
    drop: () => {
      onDrop && onDrop();
    },
    hover: (draggedItem: any, monitor: any) => {
      if (!monitor.isOver({ shallow: true })) return;
      if (parentId) {
        const descendantNode = findItem(parentId, draggedItem.items);
        if (descendantNode) return;
        if (draggedItem.parentId == parentId || draggedItem.id == parentId)
          return;
      }

      moveItem(draggedItem.id, undefined, parentId);
    },
  });

  if (!items) return null;

  return (
    <div
      ref={dropRef}
      style={{
        position: "relative",
        minHeight: 10,
        paddingTop: 10,
        marginTop: -11,
      }}
    >
      {items.map((item: any) => (
        <Item
          id={item.id}
          item={item}
          type={type || "item"}
          key={item.id}
          moveItem={moveItem}
          parentId={parentId}
          renderItem={renderItem}
        />
      ))}
    </div>
  );
};

export default Tree;
