import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { pick, map } from "lodash";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
  StoreHelpers,
} from "react-joyride";
import { useHistory } from "react-router-dom";
import { EDIT_PROFILE } from "../../graphql/queries/auth/mutations";
import {
  EditProfileResponse,
  UserProfileResponse,
} from "../../graphql/types/models/auth";
import ConfirmDialog from "../confirm-dialog";
import "./styles.scss";
import { PROFILE } from "../../graphql/queries/auth/queries";

type TourProps = {
  setSideMenuExpanded: (value: boolean) => void;
};
type TooltipContentProps = {
  title?: string;
  text: string;
  next: () => void;
};
const TooltipContent: React.FC<TooltipContentProps> = ({
  title,
  text,
  next,
}) => (
  <div>
    {title && <h4>{title}</h4>}
    <p className="field-text">{text}</p>
    <div className="tooltip-footer">
      <Button variant="success" className="button" onClick={next}>
        Continue
      </Button>
    </div>
  </div>
);

const Tour: React.FC<TourProps> = ({ setSideMenuExpanded }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showStart, setShowStart] = React.useState(false);
  const [showFinish, setShowFinish] = React.useState(false);
  const [run, setRun] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [helpers, setHelpers] = React.useState<StoreHelpers>();

  const { data: userData } = useQuery<UserProfileResponse>(PROFILE);
  const [updateProfile] = useMutation<EditProfileResponse>(EDIT_PROFILE);

  const startTour = React.useCallback(() => {
    history.push("/");
    setStep(0);
    setTimeout(() => {
      setRun(true);
    }, 500);
  }, [setShowStart, setRun, helpers]);

  const completeOnboarding = React.useCallback(() => {
    updateProfile({
      variables: {
        ...pick(userData?.profile, [
          "first_name",
          "last_name",
          "email",
          "signature",
        ]),
        hasOnboarded: true,
      },
    });
  }, [userData, updateProfile]);

  const canceTour = React.useCallback(() => {
    setShowStart(false);
    setShowFinish(false);
    completeOnboarding();
  }, [setShowStart, setShowFinish, completeOnboarding]);

  React.useEffect(() => {
    if (userData?.profile?.hasOnboarded === false) {
      setShowStart(true);
    }
  }, [userData?.profile?.hasOnboarded]);

  const startEstimating = React.useCallback(() => {
    history.push("/quotes");
  }, [history]);

  const handleNext = React.useCallback(() => {
    switch (step) {
      case 0:
        setSideMenuExpanded(true);
        setRun(false);
        setStep(step + 1);
        setTimeout(() => {
          setRun(true);
        }, 500);
        break;
      default:
        setStep(step + 1);
        break;
    }
  }, [step, setSideMenuExpanded, setStep, setRun]);

  const steps = React.useMemo<Step[]>(
    () =>
      map(
        [
          {
            target: ".home-overview",
            content: (
              <TooltipContent text={t("tour.dashboard")} next={handleNext} />
            ),
            placement: "auto",
          },
          {
            target: ".dashboard-sidebar",
            content: (
              <TooltipContent
                title={t("tour.sidebarNavigation")}
                text={t("tour.navigation")}
                next={handleNext}
              />
            ),
          },
          {
            target: ".navigation-item.communication",
            content: (
              <TooltipContent
                title={t("navigation.communicationSection.communication")}
                text={t("tour.communication")}
                next={handleNext}
              />
            ),
          },
          {
            target: ".navigation-item.contacts",
            content: (
              <TooltipContent
                title={t("navigation.contactsSection.contacts")}
                text={t("tour.contacts")}
                next={handleNext}
              />
            ),
          },
          {
            target: ".navigation-item.quotes",
            content: (
              <TooltipContent
                title={t("navigation.quotesSection.estimations")}
                text={t("tour.estimations")}
                next={handleNext}
              />
            ),
          },
          {
            target: ".navigation-item.quote_requests",
            content: (
              <TooltipContent
                title={t("navigation.quoteRequestSection.quoteRequests")}
                text={t("tour.quoteRequests")}
                next={handleNext}
              />
            ),
          },
          {
            target: ".navigation-item.jobs",
            content: (
              <TooltipContent
                title={t("navigation.jobsSection.jobs")}
                text={t("tour.jobs")}
                next={handleNext}
              />
            ),
          },
          {
            target: ".navigation-item.settings",
            content: (
              <TooltipContent
                title={t("navigation.settings.settingsOverview")}
                text={t("tour.settings")}
                next={handleNext}
              />
            ),
          },
          {
            target: ".navigation-item.account",
            content: (
              <TooltipContent
                title={t("navigation.account.profile")}
                text={t("tour.profile")}
                next={handleNext}
              />
            ),
          },
        ],
        (item: any) => ({
          ...item,
          disableBeacon: true,
          hideCloseButton: true,
          disableOverlayClose: true,
          hideFooter: true,
          placement: item.placement || "right-start",
        })
      ),
    [handleNext]
  );

  const handleJoyrideCallback = React.useCallback(
    (data: CallBackProps) => {
      const { action, index, type, status } = data;

      if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
        // Need to set our running state to false, so we can restart if we click start again.
        setRun(false);
        setStep(0);
        setShowFinish(true);
        completeOnboarding();
      }
    },
    [setRun, setStep, setShowFinish, completeOnboarding]
  );

  return (
    <>
      <Joyride
        steps={steps}
        debug={true}
        stepIndex={step}
        run={run}
        getHelpers={(helpers) => setHelpers(helpers)}
        callback={handleJoyrideCallback}
        showProgress={true}
        showSkipButton={true}
      />
      <ConfirmDialog
        title={t("tour.hello")}
        show={showStart}
        onClose={canceTour}
        onSubmit={startTour}
        confirm={t("tour.yesPlease")}
        cancel={t("common.no")}
        disableHide={true}
      >
        <span className="field-text">{t("tour.welcome")}</span>
      </ConfirmDialog>
      <ConfirmDialog
        title={t("tour.thatsAWrap")}
        show={showFinish}
        onClose={canceTour}
        onSubmit={startEstimating}
        confirm={t("tour.startEstimating")}
        cancel={t("common.close")}
        disableHide={true}
      >
        <span className="field-text">{t("tour.thanksForTouring")}</span>
      </ConfirmDialog>
    </>
  );
};
export default Tour;
