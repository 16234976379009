import { gql } from "@apollo/client";
import { PURCHASE_ORDER_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_ORDER = gql`
  mutation JobCreateUpdatePurchaseOrder(
    $jobId: ID
    $purchaseOrder: PurchaseOrderInput
  ) {
    jobCreateUpdatePurchaseOrder(jobId: $jobId, purchaseOrder: $purchaseOrder) {
      ...PurchaseOrderFragment
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;

export const SEND_ORDER = gql`
  mutation JobSendPurchaseOrder(
    $jobId: ID
    $purchaseOrderId: ID
    $message: JobSendEmailInput
  ) {
    jobSendPurchaseOrder(
      jobId: $jobId
      purchaseOrderId: $purchaseOrderId
      message: $message
    ) {
      _id
      status
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation JobDeletePurchaseOrder($jobId: ID, $purchaseOrderId: ID) {
    jobDeletePurchaseOrder(jobId: $jobId, purchaseOrderId: $purchaseOrderId) {
      _id
    }
  }
`;

export const CANCEL_RECEIVE_ORDER = gql`
  mutation jobCancelReceivedPurchaseOrder($jobId: ID!, $purchaseOrderId: ID!) {
    jobCancelReceivedPurchaseOrder(
      jobId: $jobId
      purchaseOrderId: $purchaseOrderId
    ) {
      ...PurchaseOrderFragment
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;

export const MARK_ORDER_RECEIVED = gql`
  mutation jobMarkPurchaseOrderReceived($jobId: ID!, $purchaseOrderId: ID!) {
    jobMarkPurchaseOrderReceived(
      jobId: $jobId
      purchaseOrderId: $purchaseOrderId
    ) {
      ...PurchaseOrderFragment
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;

export const MARK_ORDER_RECEIPT_BILLABLE = gql`
  mutation jobMarkPurchaseOrderReceiptBillable(
    $jobId: ID!
    $purchaseOrderId: ID!
    $purchaseOrderReceiptId: ID!
    $isBillable: Boolean!
  ) {
    jobMarkPurchaseOrderReceiptBillable(
      jobId: $jobId
      purchaseOrderId: $purchaseOrderId
      purchaseOrderReceiptId: $purchaseOrderReceiptId
      isBillable: $isBillable
    ) {
      _id
      isNonBillable
    }
  }
`;

export const CANCEL_ORDER_RECEIPT = gql`
  mutation jobCancelPurchaseOrderReceipt(
    $jobId: ID!
    $purchaseOrderId: ID!
    $purchaseOrderReceiptId: ID!
  ) {
    jobCancelPurchaseOrderReceipt(
      jobId: $jobId
      purchaseOrderId: $purchaseOrderId
      purchaseOrderReceiptId: $purchaseOrderReceiptId
    ) {
      ...PurchaseOrderFragment
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;

export const CANCEL_ORDER = gql`
  mutation JobCancelPurchaseOrder($jobId: ID, $purchaseOrderId: ID) {
    jobCancelPurchaseOrder(jobId: $jobId, purchaseOrderId: $purchaseOrderId) {
      _id
      status
    }
  }
`;

export const RECEIVE_ORDER = gql`
  mutation jobReceivePurchaseOrder(
    $jobId: ID!
    $purchaseOrderId: ID!
    $receipt: PurchaseOrderReceiptInput
  ) {
    jobReceivePurchaseOrder(
      jobId: $jobId
      purchaseOrderId: $purchaseOrderId
      receipt: $receipt
    ) {
      ...PurchaseOrderFragment
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;
