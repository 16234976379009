import { TFunction } from "i18next";
import {
  AppendDynamicPropsFn,
  AppendOnClickFn,
  GenericFormFields,
  UpdateRowFn,
} from "../../generic-form/GenericFormBody";
import {
  VariationPayload,
  VariationCostItemPayload,
  VariationStatuses,
} from "../../../models/variations";
import { chain, map } from "lodash";
import { calcItemTotal } from "../../../utils/calculations";
import { Rounding } from "../../calculator/utils";
import { UOMOption, GenericRecord } from "../../../utils/types/options";
import { ContactListItem } from "../../../graphql/types/models/client";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import { SearchJobCostingItem } from "../../../models/job";
import { PriceListItem } from "../../../models/price-list";
import CostingAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";

export const createVariationsFields = (
  t: TFunction,
  openCalculator: (
    fieldName: string,
    fieldValue?: string,
    rowIndex?: number
  ) => void,
  uoms: UOMOption[],
  clients: ContactListItem[],
  handleUOMCreate: (uom: string) => void,
  costingCategoryList: GenericRecord[] | undefined,
  handleCostingCreate: (costing: string) => void,
  handleCategorySelect: UpdateRowFn,
  openAllocateCosting: AppendOnClickFn,
  getAllocateProps: AppendDynamicPropsFn,
  handleCostingSearch: (value: string) => Promise<SearchJobCostingItem[]>,
  handlePriceSearch: (value: string) => Promise<PriceListItem[]>,
  handlePriceSelect: UpdateRowFn,
  priceItems: SelectOption[],
  markupValue?: number
): GenericFormFields<VariationPayload> => {
  const costingOptions = async (value: string) => {
    if (!value) return [];

    const costings = await handleCostingSearch(value.toLowerCase());
    const costingItems = map(
      costings,
      (costingItem) =>
        ({
          label: costingItem.name,
          value: `costing:${costingItem._id}`,
          hidden: false,
          data: costingItem,
        } as SelectOption)
    );
    const prices = await handlePriceSearch(value);

    const priceItems = map(
      prices,
      (priceItem) =>
        ({
          label: priceItem.name,
          value: `price:${priceItem._id}`,
          hidden: false,
          data: priceItem,
        } as SelectOption)
    );
    return costingItems.concat(priceItems);
  };

  return {
    name: {
      type: "text",
      label: t("variations.nameLabel"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    contact: {
      type: "select",
      label: t("claims.contact"),
      placeholder: t("placeholders.contact"),
      valueKey: "contact",
      autocompleteProps: {
        options: map(clients, (client) => ({
          value: client._id,
          label: `${client.first_name} ${client.last_name}`,
        })),
      },
      inputProps: {
        required: true,
      },
    },
    date: {
      type: "date-picker",
      label: t("variations.date"),
      placeholder: t("placeholders.date"),
      valueKey: "date",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    note: {
      type: "text",
      controlType: "textarea",
      label: t("variations.notes"),
      placeholder: t("placeholders.notes"),
      valueKey: "note",
      inputProps: {
        rows: 6,
        type: "text",
        required: true,
      },
    },
    status: {
      type: "select",
      label: t("variations.status"),
      placeholder: t("placeholders.status"),
      valueKey: "status",
      autocompleteProps: {
        options: chain(VariationStatuses)
          .filter((s) => s !== VariationStatuses.APPROVED_BY_BUILDER)
          .map((status) => ({
            value: status,
            label: t(`variations.statuses.${status}`),
          }))
          .value(),
      },
      inputProps: {
        type: "text",
        required: true,
      },
    },

    items: {
      tableTitle: t("costing.costingItems"),
      rowGenerator: () => ({
        name: "",
        quantity: null,
        margin_amount: markupValue || 0,
        cost: 0,
        UOM: "qty",
        wastage: 0,
        rounding: Rounding.NONE,
      }),
      row: [
        {
          width: "250px",
          type: "creatable-select",
          label: t("variations.name"),
          placeholder: t("placeholders.name"),
          valueKey: "name",
          updateTableRow: handlePriceSelect,
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
          creatableProps: {
            autoSelectCreatedOption: true,
            isEditable: true,
            isClearable: true,
            options: priceItems,
            asyncOptions: costingOptions,
            optionComponent: CostingAutocompleteMenuOption,
            isValidNewOption: (v: string) => v.length > 0,
            noOptionsMessage: () => null,
          },
        },
        {
          width: "150px",
          type: "text",
          label: t("costing.quantity"),
          disallowNegativeNumber: false,
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
          },
          append: {
            onClick: openCalculator,
            className: "input-group-append-compact",
            icon: "calculate",
          },
        },
        {
          width: "150px",
          type: "creatable-select",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            className: "form-input-compact",
            required: true,
          },
        },
        {
          width: "100px",
          type: "text",
          label: t("costing.cost"),
          placeholder: t("placeholders.costValue"),
          valueKey: "cost",
          inputProps: {
            type: "number",
            className: "form-input-compact",
            required: true,
          },
        },
        {
          width: "60px",
          type: "text",
          label: t("costing.markup"),
          placeholder: t("placeholders.marginValue"),
          valueKey: "margin_amount",
          inputProps: {
            type: "number",
            className: "form-input-compact",
          },
        },
        {
          width: "250px",
          type: "creatable-select",
          label: t("costing.costingCategory"),
          placeholder: t("placeholders.category"),
          valueKey: "costingCategoryName",
          updateTableRow: handleCategorySelect,
          creatableProps: {
            autoSelectCreatedOption: true,
            options: costingCategoryList,
            onCreateOption: handleCostingCreate,
            isValidNewOption: (v: string) => v.length > 0,
            isClearable: true,
          },
          inputProps: {
            type: "text",
            className: "form-input-compact",
            required: true,
          },
          append: {
            onClick: openAllocateCosting,
            className: "input-group-append-compact",
            dynamicProps: getAllocateProps,
          },
        },
        {
          width: "100px",
          type: "toggle",
          valueKey: "gstFree",
          label: t("costing.gstFree"),
          toggleProps: {
            label: "",
          },
        },
      ],
      dynamicFields: [
        {
          title: t("costing.totalEx"),
          formatValue: (values: VariationCostItemPayload) => {
            const amount = calcItemTotal(
              {
                margin_amount: values.margin_amount || 0,
                cost: values.cost || 0,
                quantity: values.quantity || 0,
              },
              0
            );

            return t("common.currency", { amount });
          },
        },
      ],
    },
  };
};
