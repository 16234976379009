import { gql } from "@apollo/client";
import { PURCHASE_ORDER_FRAGMENT } from "./fragments";

export const LIST_ORDERS = gql`
  query($jobId: ID) {
    getJobPurchaseOrders(jobId: $jobId) {
      _id
      orderNumber
      status
      reference
      hasGST
      hideCost
      subTotal
      date
      supplier {
        _id
        email
        business_name
        contact_person
        abn
      }
      receipts {
        _id
        reference
        dateReceived
        dueDate
        hasGST
        subTotal
        GST
        total
      }
    }
  }
`;

export const JOB_CHECK_ORDER_RECEIPT = gql`
  query JobCheckDuplicatePurchaseOrderReceipt(
    $jobId: ID!
    $reference: String!
  ) {
    jobCheckDuplicatePurchaseOrderReceipt(
      jobId: $jobId
      reference: $reference
    ) {
      reference
    }
  }
`;

export const GET_ORDER = gql`
  query($jobId: ID, $purchaseOrderId: ID) {
    getJobPurchaseOrderById(jobId: $jobId, purchaseOrderId: $purchaseOrderId) {
      ...PurchaseOrderFragment
    }
  }
  ${PURCHASE_ORDER_FRAGMENT}
`;

export const GET_ORDER_PREVIEW = gql`
  query GetJobPurchaseOrderPreview($jobId: ID, $purchaseOrderId: ID) {
    getJobPurchaseOrderPreview(
      jobId: $jobId
      purchaseOrderId: $purchaseOrderId
    ) {
      _id
      pdf
    }
  }
`;
