import React from "react";
import { map } from "lodash";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import CreateJobModal from "../../job/create-job-modal";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import { ListJobsResponse } from "../../../graphql/types/models/job";
import { LIST_JOBS } from "../../../graphql/queries/job/queries";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";

import DashboardJobItem from "./JobItem";
import EmptyPlaceholder from "../../empty-placeholder";
import { UserPayload } from "../../../graphql/types/models/auth";
import "./styles.scss";

type DashboardJobCardProps = {
  isLocked?: boolean;
  user: UserPayload | null;
};

const DashboardJobCard: React.FC<DashboardJobCardProps> = (props) => {
  const { isLocked = false, user } = props;

  const { t } = useTranslation();
  const createJobRef = React.useRef<ModalDisplayRef>(null);

  const { data, loading } = useQuery<ListJobsResponse>(LIST_JOBS, {
    fetchPolicy: "cache-and-network",
  });

  const renderJobs = React.useCallback(() => {
    if (loading) return null;
    if (data?.listJobs?.length) {
      return map(data?.listJobs, (job) => (
        <DashboardJobItem user={user} key={job._id} job={job} t={t} />
      ));
    }
    return !isLocked ? (
      <EmptyPlaceholder
        message={t("dashboard.emptyJobsPlaceholder")}
        buttonText={t("jobs.addNewJob")}
        onButtonPress={() => createJobRef.current?.show(true)}
      />
    ) : (
      <EmptyPlaceholder message={t("dashboard.emptyJobsPlaceholder")} />
    );
  }, [t, data, loading, createJobRef, isLocked]);

  return (
    <>
      <DashboardCard className="dashboard-jobs">
        <DashboardCardHeader className="justify-content-between">
          {t("dashboard.jobsInProgress")}
        </DashboardCardHeader>
        <DashboardCardBody>{renderJobs()}</DashboardCardBody>
      </DashboardCard>

      <CreateJobModal ref={createJobRef} />
    </>
  );
};

export default DashboardJobCard;
