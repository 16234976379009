import { gql } from "@apollo/client";

export const DELETE_BUILDING_TEMPLATE = gql`
  mutation DeleteBuildingTemplate($templateId: ID!) {
    deleteBuildingTemplate(templateId: $templateId) {
      _id
      name
    }
  }
`;
