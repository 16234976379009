import React from "react";
import { components, IndicatorProps } from "react-select";

import Icon from "../../../icons/Icon";

type AutocompleteDropdownIndicatorProps = IndicatorProps<any> & {};

const AutocompleteDropdownIndicator: React.FC<AutocompleteDropdownIndicatorProps> = (
  props
) => {
  return (
    <components.DropdownIndicator {...props} className="dropdown-indicator">
      <Icon name="expand_more" />
    </components.DropdownIndicator>
  );
};

export default AutocompleteDropdownIndicator;
