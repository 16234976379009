import React from "react";
import { findIndex, isArray, reduce } from "lodash";
import { TFunction } from "react-i18next";
import { JobCostingTableItem } from "..";
import {
  TableCardAction,
  TableRowActionData,
} from "../../../../components/dashboard/table-card/utils";
import { getQuoteRequestsItems, toggleCostingItem } from "../utils";
import QuoteRequestModal, {
  QuoteRequestRef,
  QuoteRequestTableItem,
  QuoteRequestType,
} from "../../../../components/quotes/quote-request-modal";
import {
  QuoteRequestCreatePayload,
  QuoteRequestItemStatus,
  QuoteRequestPayload,
  QuoteRequestResponse,
} from "../../../../graphql/types/models/quote-request";
import { handleSendQuoteRequest } from "../../../../graphql/queries/job-costing/utils";
import { uploadFiles } from "../../../../utils/files";
import { useMutation } from "@apollo/client";
import { CREATE_QUOTE_REQUEST } from "../../../../graphql/queries/quote-request/mutations";
import { notify } from "../../../../components/notification";
import { getMediaInput } from "../../../../utils/transform";
import { useQuoteRequestSend } from "../../../../hooks/useQuoteRequestSend";
import ListQuoteRequestsModal, {
  ListQuoteRequestsModalRef,
} from "../../../../components/quote-requests/list-quote-requests-modal";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";

export function useQuoteRequest(
  t: TFunction,
  resetAllSelections: () => void,
  jobId?: string,
  onCompletedCreate?: () => void
) {
  const quoteRequestRef = React.useRef<QuoteRequestRef>(null);
  const listQuoteRequestsRef = React.useRef<ListQuoteRequestsModalRef>(null);
  const [quoteItems, setQuoteItems] = React.useState<JobCostingTableItem[]>([]);

  const resetQuoteItems = React.useCallback(() => {
    setQuoteItems([]);
  }, []);

  const { showSendQuoteRequest, renderSendQuoteRequest } = useQuoteRequestSend({
    onSendComplete: resetQuoteItems,
    jobId,
  });

  const [createQuoteRequest] = useMutation<
    QuoteRequestResponse,
    QuoteRequestCreatePayload
  >(CREATE_QUOTE_REQUEST, {
    onCompleted: ({ createUpdateQuoteRequest }) => {
      if (createUpdateQuoteRequest) {
        notify({
          title: t("quoteRequest.createQuoteRequest"),
          content: t("quoteRequest.success.createQuoteRequest"),
        });
        onCompletedCreate?.();
      }
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("quoteRequest.createQuoteRequest"),
        content: error.message,
      });
    },
  });

  const toggleQuoteItem = React.useCallback(
    (row: JobCostingTableItem | JobCostingTableItem[]) => {
      let items;
      if (isArray(row)) {
        items = reduce(
          row,
          (result, item) => {
            if (!item?.quoteRequestStatus || item?.quantity > 0) {
              return toggleCostingItem(result, item, true);
            }
            return result;
          },
          quoteItems
        );
      } else {
        if (!row?.quoteRequestStatus || row?.quantity > 0) {
          items = toggleCostingItem(quoteItems, row);
        }
      }
      items && setQuoteItems(items);
    },
    [quoteItems]
  );

  const quoteRequestAllItems = React.useMemo(() => {
    const items: QuoteRequestTableItem[] = [];
    quoteItems.forEach((item) => {
      if (item.items?.length) {
        item.items.forEach((assemblyItem) => {
          items.push({
            ...assemblyItem,
            assemblyId: item._id,
            quantity: assemblyItem.computed_quantity,
          } as QuoteRequestTableItem);
        });
      } else {
        items.push(item as QuoteRequestTableItem);
      }
    });
    return items;
  }, [quoteItems]);

  const isItemSelectedForQuote = React.useCallback(
    (id: string) => {
      return findIndex(quoteItems, { _id: id }) >= 0;
    },
    [quoteItems]
  );

  const quoteTableAction = React.useMemo<TableCardAction>(
    () => ({
      title: t(
        quoteItems.length ? "costing.quoteItemsNumber" : "costing.quoteItems",
        { number: quoteItems.length }
      ),
      onClick: () => quoteRequestRef?.current?.show(true),
      icon: "price_change",
      className: "button info-reverse",
      disabled: !quoteItems.length,
    }),
    [quoteItems]
  );

  const quoteRowActions = React.useMemo<
    TableRowActionData<JobCostingTableItem>[]
  >(
    () => [
      {
        icon: "price_change",
        dropdownId: "quote-request",
        counter: (row: JobCostingTableItem) =>
          getQuoteRequestsItems(row).length,
        title: (row: JobCostingTableItem) =>
          t("costing.quoteRequestedWithCount", {
            count: getQuoteRequestsItems(row).length,
          }),
        onClick: (row: JobCostingTableItem) => {
          const data = getQuoteRequestsItems(row);
          if (jobId) {
            listQuoteRequestsRef.current?.setData(data, jobId);
            listQuoteRequestsRef.current?.show(true);
          }
        },
        shouldRender: (row) =>
          row.quoteRequestStatus === QuoteRequestItemStatus.REQUESTED,
      },
      {
        icon: "task_alt",
        dropdownId: "quote-request-accepted",
        counter: (row: JobCostingTableItem) =>
          getQuoteRequestsItems(row).length,
        title: (row: JobCostingTableItem) =>
          t("costing.quoteAcceptedWithCount", {
            count: getQuoteRequestsItems(row).length,
          }),
        onClick: (row: JobCostingTableItem) => {
          const data = getQuoteRequestsItems(row);
          if (jobId) {
            listQuoteRequestsRef.current?.setData(data, jobId);
            listQuoteRequestsRef.current?.show(true);
          }
        },
        shouldRender: (row) =>
          row.quoteRequestStatus === QuoteRequestItemStatus.ACCEPTED,
      },
    ],
    [jobId, t]
  );

  const handleSubmitQuote = React.useCallback(
    async (values: QuoteRequestPayload) => {
      if (!jobId) return;

      const attachments = getMediaInput(values.attachments as File[]);

      const quoteRequest: QuoteRequestPayload = {
        ...values,
        attachments,
      };

      const result = await createQuoteRequest({
        variables: {
          quoteRequest,
        },
        update: handleSendQuoteRequest(jobId, values.items),
      });

      if (result.data?.createUpdateQuoteRequest) {
        if (attachments.length && values.attachments.length) {
          const attachmentResults =
            result.data?.createUpdateQuoteRequest?.attachments;
          if (attachmentResults?.length) {
            await uploadFiles(attachmentResults, values.attachments as File[]);
          }
        }
        quoteRequestRef.current?.show(false);
        showSendQuoteRequest(result.data.createUpdateQuoteRequest);
      }
      resetAllSelections();
    },
    [
      createQuoteRequest,
      uploadFiles,
      jobId,
      quoteRequestRef,
      handleSendQuoteRequest,
      showSendQuoteRequest,
    ]
  );

  const renderQuoteModal = React.useCallback(() => {
    if (!jobId) return null;
    return (
      <>
        <QuoteRequestModal
          ref={quoteRequestRef}
          onSubmit={handleSubmitQuote}
          itemsForQuote={quoteRequestAllItems}
          itemId={jobId}
          type={QuoteRequestType.JOB}
        />
        {renderSendQuoteRequest()}
      </>
    );
  }, [jobId, quoteRequestAllItems, handleSubmitQuote]);

  const renderListQuoteRequestModal = React.useCallback(() => {
    return (
      <ListQuoteRequestsModal
        ref={listQuoteRequestsRef}
        redirectTo={NAVIGATION_ROUTES.JOBS_SECTION.JOBS}
      />
    );
  }, []);

  return {
    renderQuoteModal,
    quoteTableAction,
    quoteRowActions,
    toggleQuoteItem,
    resetQuoteItems,
    isItemSelectedForQuote,
    renderListQuoteRequestModal,
  };
}
