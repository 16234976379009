import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/client";

// import HomeContainer from "../home";
import ClientJobsList from "../jobs/jobs";
import ClientJobsContainer from "../jobs/jobs-container";
import DashboardLayout from "../../layouts/dashboard";
import { RootReducerState } from "../../../redux/reducer";
import {
  getUser,
  getUserFailed,
  getUserSuccess,
} from "../../../redux/authentication/actions";
import { DashboardContainerProps } from "../../dashboard";

import { CLIENT_PROFILE } from "../../../graphql/queries/client/auth/queries";
import ClientsQuoteList from "../quotes/quotes-list";
import ClientQuoteContainer from "../quotes/quotes-container";
import ClientProfileContainer from "../profile/profile-container";

const ClientDashboardContainer: React.FC<DashboardContainerProps> = ({
  getUser,
  getUserFailed,
  getUserSuccess,
  isAuthenticated,
  user,
}) => {
  const [getClientProfile] = useLazyQuery(CLIENT_PROFILE, {
    onCompleted: (data) => {
      getUserSuccess(data.clientProfile, false, false, true);
    },
    onError: (error) => {
      getUserFailed(error.message);
    },
  });

  React.useEffect(() => {
    if (user && isAuthenticated) {
      return;
    }

    getUser();
    getClientProfile();
  }, [user, isAuthenticated, getClientProfile, getUser]);

  return (
    <DashboardLayout>
      <Switch>
        {/* <Route exact path="/" component={HomeContainer} /> */}
        <Route exact path="/projects" component={ClientJobsList} />
        <Route path="/projects/:id" component={ClientJobsContainer} />
        <Route exact path="/quotes" component={ClientsQuoteList} />
        <Route path="/quotes/:id" component={ClientQuoteContainer} />
        <Route path="/account" component={ClientProfileContainer} />
        <Route>
          <Redirect to="/projects" />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
    isAuthenticated: state.authentication.isAuthenticated,
    isAuthenticating: state.authentication.isAuthenticating,
  };
};

export default connect(mapStateToProps, {
  getUser,
  getUserSuccess,
  getUserFailed,
})(ClientDashboardContainer);
