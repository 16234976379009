import React from "react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import Icon from "../../icons/Icon";
import ChecklistCategoryItem from "./ChecklistCategoryItem";
import { SortableItem } from "../../sortable-list/types";
import SortableList, { SortableListRef } from "../../sortable-list";
import { ConnectDragSource } from "react-dnd";
import { TodoChecklistTemplateCategory } from "../../../models/task";
import "./styles.scss";

type ChecklistGroupsProps = {
  addButtonPlacement?: "top" | "bottom" | "none";
  categories?: TodoChecklistTemplateCategory[];
  onBackClick?: () => void;
  onNextClick?: () => void;
  onDelete?: (categoryId: string) => void;
  onAddClick?: () => void;
  onSort?: (ordered: TodoChecklistTemplateCategory[]) => void;
  disabled?: boolean;
  selectedCategory: TodoChecklistTemplateCategory | null;
  selectCategory?: (category: TodoChecklistTemplateCategory) => void;
  onImportFromCosting?: () => void;
};

const ChecklistGroups: React.FC<ChecklistGroupsProps> = ({
  addButtonPlacement = "top",
  categories,
  onDelete,
  onAddClick,
  onSort,
  disabled = false,
  selectedCategory,
  selectCategory,
}) => {
  const { t } = useTranslation();

  const listRef = React.useRef<SortableListRef>(null);

  const sortableItems = React.useMemo((): SortableItem[] => {
    return map(categories, (category) => ({
      id: category._id,
      item: category,
    }));
  }, [categories]);

  const onDrop = React.useCallback(() => {
    if (!onSort) return;
    const newOrder = listRef.current?.getOrder()?.map(
      ({ item }, order) =>
        ({
          ...item,
          order,
        } as TodoChecklistTemplateCategory)
    );
    if (newOrder) {
      onSort(newOrder);
    }
  }, [onSort, listRef]);

  const renderItem = React.useCallback(
    (data: SortableItem, dragRef: ConnectDragSource) => {
      const { item } = data;
      const category = item as TodoChecklistTemplateCategory;
      return (
        <ChecklistCategoryItem
          key={category._id}
          category={category}
          onDelete={!disabled ? onDelete : undefined}
          onClick={selectCategory}
          isSelected={category._id === selectedCategory?._id}
          dragRef={dragRef}
        />
      );
    },
    [selectedCategory, selectCategory, disabled, onDelete]
  );

  const renderCategories = React.useCallback(() => {
    return (
      <div className="checklist-list">
        <SortableList
          ref={listRef}
          onDrop={onDrop}
          items={sortableItems}
          renderItem={renderItem}
        />
      </div>
    );
  }, [listRef, sortableItems, onDrop, renderItem]);

  return (
    <DashboardCard className="checklist-categories">
      <DashboardCardHeader className="text-capitalize justify-content-between">
        <div className="d-flex align-items-left">{t("tasks.groups")}</div>
      </DashboardCardHeader>
      <DashboardCardBody>
        <div className="checklist-body">
          {addButtonPlacement === "top" && (
            <Button
              disabled={disabled}
              className="button info add-category"
              block
              onClick={onAddClick}
            >
              {t("tasks.addGroup")}
            </Button>
          )}
          {renderCategories()}
        </div>
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end">
        {addButtonPlacement === "bottom" && (
          <Button
            className="button success large"
            onClick={onAddClick}
            disabled={disabled}
          >
            <Icon name="add" />
            {t("common.createNew")}
          </Button>
        )}
      </DashboardCardFooter>
    </DashboardCard>
  );
};

export default ChecklistGroups;
