import * as yup from "yup";
import { TFunction } from "i18next";

import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createSendQuoteSchema(t: TFunction) {
  return yup.object().shape({
    title: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "common.subject")
      ),
    message: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "common.body")
      ),
    to: yup
      .array()
      .of(yup.string())
      .required(ValidationMessagesUtils.createRequiredMessage(t, "common.to")),
  });
}

export const validateEmail = (email: string) =>
  yup.string().email().isValidSync(email);
