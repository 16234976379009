import {
  CreateAssemblyResponse,
  ListAssembliesResponse,
  CreateUpdateAssemblyResponse,
  DeleteAssemblyResponse,
} from "../../types/models/assembly";
import { LIST_ASSEMBLIES } from "./queries";
import { MutationUpdaterFn, ApolloCache, ApolloClient } from "@apollo/client";
import { cloneDeep, concat, find, map, reduce, uniqueId, filter } from "lodash";
import {
  AssemblyCreatePayload,
  AssemblyItemPayload,
  AssemblyListItem,
  PreparedAssemblyBody,
} from "../../../models/assembly";
import GraphQlClient from "../../index";
import { calcItemTotal } from "../../../utils/calculations";
import { ASSEMBLY_FRAGMENT } from "./fragments";
import { Rounding } from "../../../components/calculator/utils";

export const handleCreateAssembly: MutationUpdaterFn<CreateAssemblyResponse> = (
  cache,
  { data }
) => {
  const createAssembly = data?.createAssembly;
  if (!createAssembly) return;
  const id = `Assembly:${createAssembly?._id}`;
  cache.writeFragment({
    id,
    fragment: ASSEMBLY_FRAGMENT,
    data: {
      ...createAssembly,
      items: map(createAssembly.items, (item) => ({
        ...item,
        __typename: "AssemblyItem",
      })),
      name: createAssembly.name,
      __typename: "Assembly",
    },
  });

  const cacheData = cache.readQuery<ListAssembliesResponse>({
    query: LIST_ASSEMBLIES,
  });

  if (!cacheData || !createAssembly) {
    return;
  }

  const { listAssemblies } = cacheData;

  cache.writeQuery({
    query: LIST_ASSEMBLIES,
    data: { listAssemblies: listAssemblies.concat(createAssembly) },
  });
};

export const prepareAssemblyItem = (
  assembly: AssemblyCreatePayload
): PreparedAssemblyBody => {
  const preparedItems = map(assembly.items, (item: AssemblyItemPayload) => ({
    name: item.name,
    UOM: item.UOM,
    cost: Number(item.cost),
    quantity: item.quantity,
    // margin_amount: parseFloat(item.margin_amount),
    rounding: item?.rounding || Rounding.NONE,
    wastage: Number(item.wastage),
    raw_quantity:
      item.raw_quantity?.toString() || item.quantity?.toString() || "",
    total: calcItemTotal(
      {
        // margin_amount: parseFloat(item.margin_amount),
        cost: item.cost,
        quantity: item.quantity,
      },
      0
    ),
  }));

  const { _id, name, UOM } = assembly;

  return {
    _id: _id && /^assembly:/.test(_id) ? _id : "",
    name,
    UOM,
    total: reduce(
      preparedItems,
      (prev, costing) => {
        return prev + costing.total;
      },
      0
    ),
    items: preparedItems,
  };
};

export const createLocalAssembly = (preparedAssembly: any) => {
  const { client } = GraphQlClient;

  const assemblyId = uniqueId();

  const id = `Assembly:${assemblyId}`;
  const localAssembly: AssemblyListItem = {
    ...preparedAssembly,
    _id: assemblyId,
    items: map(preparedAssembly.items, (assembly) => ({
      ...assembly,
      __typename: "AssemblyItem",
    })),
    name: preparedAssembly.name,
    __typename: "Assembly",
  };

  client.writeFragment({
    id,
    fragment: ASSEMBLY_FRAGMENT,
    data: localAssembly,
  });
  return localAssembly;
};

export const assemblyToFormPayload = (assembly: AssemblyListItem) => {
  return {
    name: assembly.name,
    UOM: assembly.UOM,
    items: map(assembly.items, (costItem) => {
      return {
        cost: costItem.cost.toString(),
        quantity: costItem.quantity.toString(),
        UOM: costItem.UOM,
        name: costItem.name,
        margin_amount: "",
      };
    }),
  };
};

const getAssemblies = (cache: ApolloCache<any>) => {
  const assembliesResponse = cache.readQuery<ListAssembliesResponse>({
    query: LIST_ASSEMBLIES,
  });

  return assembliesResponse?.listAssemblies;
};

const updateAssemblies = (
  cache: ApolloCache<any>,
  assemblies: AssemblyListItem[]
) => {
  cache.writeQuery<ListAssembliesResponse>({
    query: LIST_ASSEMBLIES,
    data: {
      listAssemblies: assemblies,
    },
  });
};

export const handleAddAssembly: MutationUpdaterFn<CreateUpdateAssemblyResponse> = (
  cache,
  response
) => {
  const addedAssembly = response.data?.createUpdateAssembly;
  const assembliesList = getAssemblies(cache);

  if (!addedAssembly || !assembliesList) return;

  const assemblies = concat(assembliesList, addedAssembly);
  updateAssemblies(cache, assemblies);
};

export const handleDeleteAssembly: MutationUpdaterFn<DeleteAssemblyResponse> = (
  cache,
  response
) => {
  const deletedAssembly = response.data?.deleteAssembly;
  const assembliesList = getAssemblies(cache);

  if (!deletedAssembly || !assembliesList) return;

  const assemblies = filter(
    assembliesList,
    (assembly) => assembly._id !== deletedAssembly._id
  );
  updateAssemblies(cache, assemblies);
};

export const handleUpdateAssembly: MutationUpdaterFn<CreateUpdateAssemblyResponse> = (
  cache,
  response
) => {
  const updatedAssembly = response.data?.createUpdateAssembly;
  const assembliesList = getAssemblies(cache);

  if (!updatedAssembly || !assembliesList) return;

  const assemblies = map(assembliesList, (assembly) =>
    assembly._id === updatedAssembly._id ? updatedAssembly : assembly
  );
  updateAssemblies(cache, assemblies);
};
