import React from "react";
import { TFunction } from "i18next";
import { DashboardNavigationContext } from "../../../../../containers/layouts/dashboard/DashboardContext";

import Icon from "../../../../icons/Icon";
import SVGIcon from "../../../../icons/SVGIcon";

import { NAVIGATION_ROUTES } from "../navigation-items";

export const basicNavItems = (
  t: TFunction,
  context: DashboardNavigationContext | null
) => [
  {
    id: NAVIGATION_ROUTES.DASHBOARD,
    icon: () => <Icon name="home" outlined />,
    title: t("navigation.dashboard"),
    url: "/",
  },
  {
    id: NAVIGATION_ROUTES.CALENDAR,
    icon: () => <Icon name="date_range" outlined />,
    title: t("navigation.calendar"),
    url: "/calendar",
  },
  {
    id: NAVIGATION_ROUTES.COMMUNICATION_SECTION.COMMUNICATION,
    expandable: true,
    icon: () => <Icon name="forum" outlined />,
    url: "/communication",
    title: t("navigation.communicationSection.communication"),
    defaultItemId: NAVIGATION_ROUTES.COMMUNICATION_SECTION.MESSAGES,
    items: [
      {
        id: NAVIGATION_ROUTES.COMMUNICATION_SECTION.MESSAGES,
        icon: () => <Icon name="chat_bubble_outline" outlined />,
        title: t("navigation.communicationSection.messages"),
        url: `/communication/messages`,
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.TASKS,
    icon: () => <Icon name="task" outlined />,
    title: t("navigation.tasks"),
    url: "/tasks",
    expandable: false,
  },
  {
    id: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
    icon: () => <SVGIcon name="Hammer" />,
    title: t("navigation.jobsSection.jobs"),
    url: "/jobs",
    expandable: true,
    defaultItemId: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
    items: [
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
        icon: () => <SVGIcon name="Hammer" />,
        title: t("navigation.jobsSection.jobs"),
        url: "/jobs",
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.COMPLETED_JOBS,
        icon: () => <Icon name="assignment_turned_in" outlined />,
        title: t("navigation.jobsSection.completedJobs"),
        url: "/jobs/completed",
        hideInSidebarMenu: !!context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.OVERVIEW,
        icon: () => <SVGIcon name="Grid2" />,
        title: t("navigation.jobsSection.overview"),
        url: `/jobs/${context?.job?._id}`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.JOB_MESSAGES,
        icon: () => <Icon name="chat_bubble_outline" outlined />,
        title: t("navigation.jobsSection.jobMessages"),
        url: `/jobs/${context?.job?._id}/messages`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.SCHEDULE,
        icon: () => <SVGIcon name="Tasks" />,
        title: t("navigation.jobsSection.schedule"),
        url: `/jobs/${context?.job?._id}/schedule`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.SITE_DIARY,
        icon: () => <Icon name="book" outlined />,
        title: t("navigation.jobsSection.siteDiary"),
        url: `/jobs/${context?.job?._id}/site-diary`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.DOCUMENTS,
        icon: () => <SVGIcon name="Document" />,
        title: t("navigation.jobsSection.documents"),
        url: `/jobs/${context?.job?._id}/documents`,
        hideInSidebarMenu: !context?.job,
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.SITE_DIARIES,
    icon: () => <Icon name="book" outlined />,
    title: t("navigation.siteDiaries"),
    url: "/site-diaries",
    expandable: false,
  },
  {
    id: NAVIGATION_ROUTES.TIMESHEETS_SECTION.TIMESHEETS,
    icon: () => <Icon name="list_alt" outlined />,
    title: t("navigation.timesheetsSection.timesheets"),
    url: "/timesheets",
    expandable: true,
    defaultItemId: NAVIGATION_ROUTES.TIMESHEETS_SECTION.TIMESHEETS,
    items: [
      {
        id: NAVIGATION_ROUTES.TIMESHEETS_SECTION.TIMESHEETS,
        icon: () => <Icon name="list_alt" outlined />,
        title: t("navigation.timesheetsSection.timesheets"),
        url: "/timesheets",
        hideInSidebarMenu: true,
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.ACCOUNT.ACCOUNT,
    icon: () => <SVGIcon name="User" />,
    title: t("navigation.account.profile"),
    url: "/account",
    defaultItemId: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
    expandable: true,
    items: [
      {
        id: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
        icon: () => <SVGIcon name="User" />,
        title: t("navigation.account.profile"),
        url: "/account/profile",
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.ACCOUNT.LOGOUT,
        icon: () => <Icon name="logout" outlined />,
        title: t("navigation.account.logout"),
        isLogout: true,
      },
    ],
  },
];
