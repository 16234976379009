import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import { WithT } from "i18next";
import { FormikProps } from "formik";
import { SignInFormValues } from "./SignInFormBody";
import Context, { SiteContext } from "../../../siteContext";

type SignInFormFooterProps = WithT &
  Pick<
    FormikProps<SignInFormValues>,
    "values" | "handleChange" | "handleBlur" | "isSubmitting"
  >;

const SignInFormFooter: React.FC<SignInFormFooterProps> = ({
  t,
  values,
  handleBlur,
  handleChange,
  isSubmitting,
}) => {
  const { isUser, isAffiliate } = React.useContext(Context) as SiteContext;
  return (
    <Row className="w-100 m-0 sign-in-submit">
      <Col
        lg={6}
        xs={12}
        className="d-flex p-0 align-items-center justify-content-end"
      >
        <Form.Check
          onChange={handleChange}
          onBlur={handleBlur}
          name="rememberMe"
          checked={values.rememberMe}
          className="remember-me"
          label={t("authentication.rememberMe")}
        />
        <Button
          size="lg"
          variant="success"
          type="submit"
          disabled={isSubmitting}
          className="button large success sign-in-button"
        >
          {t("authentication.signIn")}
        </Button>
      </Col>
      <Col lg={6} xs={12} className="d-flex p-0">
        {(isUser || isAffiliate) && (
          <div className="d-flex p-0 align-items-center sign-in-other">
            <span className="field-text">
              {t("authentication.dontHaveAccountYet")}
            </span>
            <Link to="/sign-up" className="sign-up-button">
              <Button
                size="lg"
                variant="outline-success"
                disabled={isSubmitting}
                className="button large outline-success"
              >
                {t("authentication.signUp")}
              </Button>
            </Link>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default SignInFormFooter;
