import { compact } from "lodash";
import React from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { JobDetails, JobInvoiceType, JobTotal } from "../../../models/job";
import TableCard from "../../dashboard/table-card";
import { TableCardData } from "../../dashboard/table-card/utils";

type ProfitTable = Omit<JobTotal, "completionPercent">;

type ProfitDetailsProps = {
  loading: boolean;
  job?: JobDetails;
  amounts?: JobTotal;
};

const ProfitDetails: React.FC<ProfitDetailsProps> = (props) => {
  const { loading, amounts, job } = props;

  const { t } = useTranslation();
  const isRetention = React.useMemo(
    () => job?.invoiceType === JobInvoiceType.Retention,
    [job]
  );

  const tableData = React.useMemo<TableCardData<ProfitTable>>(() => {
    return {
      columns: compact([
        {
          valueKey: "totalRevenue",
          title: t("jobOverview.adjustedContract"),
          tooltip: () => t("jobOverview.tooltips.adjustedContract"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        // {
        //   valueKey: "totalVariation",
        //   title: t("jobOverview.variations"),
        //   formatValue: (row: any, column: any, value: number) =>
        //     t("common.currency", { amount: value }),
        // },
        // {
        //   valueKey: "totalEstimatedWithMarkup",
        //   title: t("jobOverview.total"),
        //   formatValue: (row: any, column: any, value: number) =>
        //     t("common.currency", { amount: value }),
        // },
        {
          valueKey: "totalCost",
          title: t("jobOverview.totalBudget"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "totalRealCost",
          title: t("jobOverview.realCost"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "totalExpectedRealCost",
          title: t("jobOverview.totalExpectedRealCost"),
          tooltip: () => t("jobOverview.tooltips.totalExpectedRealCost"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "totalClaimed",
          title: t("jobOverview.claimed"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currencyPercentage", {
              amount: value,
              percent: row.cells.totalClaimedPercent || 0,
            }),
        },
        isRetention && {
          valueKey: "totalRetained",
          title: t("jobOverview.retained"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
        isRetention && {
          valueKey: "totalRetainable",
          title: t("jobOverview.retainable"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
        isRetention && {
          valueKey: "totalRetentionClaimed",
          title: t("jobOverview.retentionClaimed"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "totalWIP",
          title: t("jobOverview.wip"),
          tooltip: () => t("jobOverview.tooltips.wip"),
          formatValue: (row: any, column: any, value: number) => (
            <Badge variant={value <= 0 ? "success" : "danger"}>
              {t("common.currency", { amount: value })}
            </Badge>
          ),
        },
        {
          valueKey: "totalGrossProfit",
          title: t("jobOverview.grossProfit"),
          tooltip: () => t("jobOverview.tooltips.grossProfit"),
          formatValue: (row: any, column: any, value: string) => (
            <span
              className={
                parseFloat(value) > 0
                  ? "badge badge-success"
                  : parseFloat(value) < 0
                  ? "badge badge-danger"
                  : ""
              }
            >
              {t("common.currency", { amount: value })}
            </span>
          ),
        },
        {
          valueKey: "totalGrossMargin",
          title: t("jobOverview.grossMargin"),
          tooltip: () => t("jobOverview.tooltips.grossMargin"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.percent", { amount: value }),
        },
      ]),
      rows: [
        {
          cells: {
            contractTotal: job?.contractTotal || 0,
            totalEstimated:
              (amounts?.totalEstimated || 0) -
              Number(amounts?.totalVariation || 0),
            totalEstimatedWithMarkup: amounts?.totalEstimatedWithMarkup || 0,
            totalEstimatedWithMarkupGST:
              amounts?.totalEstimatedWithMarkupGST || 0,
            totalRealCost: amounts?.totalRealCost || 0,
            totalVariation: amounts?.totalVariation || 0,
            totalVariationGST: amounts?.totalVariationGST || 0,
            totalVariationClaimed: amounts?.totalVariationClaimed || 0,
            totalVariationClaimedGST: amounts?.totalVariationClaimedGST || 0,
            totalVariationItemClaimed: amounts?.totalVariationItemClaimed || 0,
            totalVariationItemClaimedGST:
              amounts?.totalVariationItemClaimedGST || 0,
            totalCost: amounts?.totalEstimated || 0,
            totalClaimed: amounts?.totalClaimed || 0,
            totalClaimedGST: amounts?.totalClaimedGST || 0,
            totalClaimedPercent: amounts?.totalClaimedPercent || 0,
            totalClaims: amounts?.totalClaims || 0,
            totalClaimsGST: amounts?.totalClaimsGST || 0,
            totalProfit: amounts?.totalProfit || 0,
            totalRetained: amounts?.totalRetained || 0,
            totalRetainedGST: amounts?.totalRetainedGST || 0,
            totalRetainable: amounts?.totalRetainable || 0,
            totalRetainableGST: amounts?.totalRetainableGST || 0,
            totalRetentionClaimed: amounts?.totalRetentionClaimed || 0,
            totalRetentionClaimedGST: amounts?.totalRetentionClaimedGST || 0,
            totalWIP: amounts?.totalWIP || 0,
            totalRevenue: amounts?.totalRevenue || 0,
            totalExpectedRealCost: amounts?.totalExpectedRealCost || 0,
            totalGrossProfit: amounts?.totalGrossProfit || 0,
            totalGrossMargin: amounts?.totalGrossMargin || 0,
          },
        },
      ],
    };
  }, [amounts, isRetention]);

  return (
    <TableCard
      isDataLoading={loading}
      title={t("jobOverview.budget")}
      data={tableData}
    />
  );
};

export default ProfitDetails;
