import React, { useState } from "react";
import CreateEntityModal from "../../modals/create-entity";
import {
  CreateReminderPayload,
  ReminderRepeatTypeEnum,
} from "../../../models/reminder";
import { useTranslation } from "react-i18next";
import createReminderFields from "./utils";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createReminderSchema from "./CreateReminder.schema";
import moment from "moment";

type CreateReminderModalProps = {
  show: boolean;
  onSubmit: (data: CreateReminderPayload) => void;
  onClose: () => void;
};

export type ReminderModalFields = {
  title: string;
  description: string;
  endDate: string;
  endTime: string;
  repeat: null | ReminderRepeatTypeEnum;
};

const CreateReminderModal: React.FC<CreateReminderModalProps> = (props) => {
  const { t } = useTranslation();

  const [fields, setFields] = useState<
    GenericFormFields<CreateReminderPayload>
  >({});

  React.useEffect(() => {
    setFields(createReminderFields(t));
  }, []);

  const { show, onSubmit, onClose } = props;

  const handleSubmit = React.useCallback(
    (values: ReminderModalFields) => {
      const endTime = moment(values.endTime);
      const dueDate = moment(values.endDate);

      dueDate.set("hour", endTime.get("hour"));
      dueDate.set("minute", endTime.get("minute"));

      const reminder = {
        title: values.title,
        description: values.description,
        repeat: values.repeat,
        dueDate: dueDate.toISOString(),
      };

      return onSubmit(reminder);
    },
    [onSubmit]
  );

  return (
    <CreateEntityModal<ReminderModalFields>
      validationSchema={createReminderSchema(t)}
      title={t("reminders.createNew")}
      data={{
        title: "",
        description: "",
        endDate: "",
        endTime: "",
        repeat: null,
      }}
      show={show}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};

export default CreateReminderModal;
