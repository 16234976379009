import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationRules } from "../../../utils/yup";

export default function createAffiliateSignUpFormSchema(t: TFunction) {
  return yup.object().shape({
    ABN: yup
      .string()
      .matches(/^[0-9]+$/, t("errors.invalidABN"))
      .length(11, t("errors.invalidABN")),
    first_name: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.firstName"),
      })
    ),
    last_name: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.lastName"),
      })
    ),
    address: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.address"),
      })
    ),
    city: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.city"),
      })
    ),
    state: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.state"),
      })
    ),
    postcode: ValidationRules.getPostcodeRules(yup, t).required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.postcode"),
      })
    ),
    email: yup
      .string()
      .email()
      .required(
        t("authentication.errors.isRequired", {
          fieldName: t("authentication.emailAddress"),
        })
      ),
    phone: yup
      .string()
      .required(
        t("authentication.errors.isRequired", {
          fieldName: t("authentication.phoneNumber"),
        })
      )
      .matches(
        /^[0-9]+$/,
        t("authentication.errors.isInvalid", {
          fieldName: t("authentication.phoneNumber"),
        })
      )
      .min(
        10,
        t("authentication.errors.isInvalid", {
          fieldName: t("authentication.phoneNumber"),
        })
      )
      .max(
        10,
        t("authentication.errors.isInvalid", {
          fieldName: t("authentication.phoneNumber"),
        })
      ),
    about: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.affiliateAbout"),
      })
    ),
    termsAgreement: yup.bool().oneOf(
      [true],
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.affiliateAgreement"),
      })
    ),
  });
}
