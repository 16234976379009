import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createVariationSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "variations.nameLabel")
      ),
    notes: yup.string(),
    date: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "variations.date")
      ),
    status: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "variations.status")
      ),
    items: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "variations.name")
          ),
        quantity: yup
          .number()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.quantity")
          ),
        // margin_amount: yup
        //   .number()
        //   .required(
        //     ValidationMessagesUtils.createRequiredMessage(t, "costing.markup")
        //   ),
        cost: yup
          .number()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.cost")
          ),
        UOM: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.uom")
          ),
      })
    ),
  });
}
