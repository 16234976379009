import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import filesize from "filesize";
import { findIndex, map } from "lodash";

import { TableRowActionData } from "../../dashboard/table-card/utils";
import TableCard from "../../dashboard/table-card";
import LeftModal from "../../left-modal";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalFooter from "../../left-modal/LeftModalFooter";
import FileInput from "../../uploaders/file-input";
import UploadSpinner, { UploadFileProgress } from "../../upload-spinner";
import CreatableSelectInput from "../../generic-form/inputs/creatable-select";
import AutocompleteInput from "../../generic-form/inputs/autocomplete";
import {
  EnumDocumentAccessRole,
  PrimaryFolder,
} from "../../../models/documents";
import { useLazyQuery } from "@apollo/client";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { getFullName } from "../../../utils/text";
import "./styles.scss";

type FileTable = {
  name: string;
  size: number;
};

type UploadDocumentModalProps = {
  onUpload: (
    files: File[],
    accessOptions?: {
      accessRole: EnumDocumentAccessRole;
      accessSelectedUsers: string[];
    }
  ) => void;
  setAccessPermissions?: boolean;
  parentFolderPermissions?: PrimaryFolder;
};

export type UploadDocumentModalRef = {
  show: (show: boolean) => void;
  showUploading: (uploading: boolean) => void;
  uploadProgress: (file: File, progress: number) => void;
};

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = (
  props,
  ref
) => {
  const { onUpload, setAccessPermissions, parentFolderPermissions } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);

  const [files, setFiles] = React.useState<File[]>([]);
  const [filesProgress, setFilesProgress] = React.useState<
    UploadFileProgress[]
  >([]);
  const [
    restrictAccessValue,
    setRestrictAccessValue,
  ] = React.useState<EnumDocumentAccessRole | null>(null);
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => {
      setShowModal(show);
      setFiles([]);
    },
    showUploading: (uploading: boolean) => {
      setIsUploading(uploading);
    },
    uploadProgress: (file: File, progress: number) => {
      const fProgress = [...filesProgress];
      const currentFileIndex = findIndex(filesProgress, (f) => f.file === file);
      if (currentFileIndex >= 0) {
        fProgress[currentFileIndex].progress = progress;
        setFilesProgress(fProgress);
      }
    },
  }));

  const { t } = useTranslation();

  React.useEffect(() => {
    if (parentFolderPermissions && setAccessPermissions) {
      setRestrictAccessValue(parentFolderPermissions?.accessRole || null);
      setSelectedUsers(
        parentFolderPermissions?.accessSelectedUsers?.map((user) => user._id) ||
          []
      );
    }
  }, [parentFolderPermissions, setAccessPermissions]);

  const handleUpload = React.useCallback(() => {
    setFilesProgress(
      files.map((file) => ({
        file,
        progress: 0,
      }))
    );
    onUpload(files, {
      accessRole: restrictAccessValue || EnumDocumentAccessRole.ALL,
      accessSelectedUsers: selectedUsers,
    });
    setRestrictAccessValue(EnumDocumentAccessRole.ALL);
    setSelectedUsers([]);
  }, [files, onUpload, restrictAccessValue, selectedUsers]);

  const handleFileUpload = React.useCallback(
    (uploadedFiles: File[]) => {
      if (
        files.some((file) =>
          uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name)
        )
      ) {
        return;
      }

      setFiles([...files, ...uploadedFiles]);
    },
    [files]
  );

  const handleClose = React.useCallback(() => {
    setFiles([]);
    setShowModal(false);
    setRestrictAccessValue(EnumDocumentAccessRole.ALL);
    setSelectedUsers([]);
  }, [setShowModal, setFiles]);

  const openRemoveDialog = React.useCallback(
    (row?: FileTable) => {
      if (row) {
        setFiles(files.filter((file) => file.name !== row.name));
      }
    },
    [files, setFiles]
  );

  const filesTableData = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("documents.documentName"),
        },
        {
          valueKey: "size",
          title: t("documents.size"),
          formatValue: (row: any, column: any, value: number) =>
            value ? filesize(value) : "-",
        },
      ],
      rows: map(files, (file) => ({
        cells: file,
      })),
    };
  }, [files]);

  const tableRowActions: TableRowActionData<FileTable>[] = React.useMemo(
    () => [
      {
        icon: "delete",
        id: "remove",
        onClick: openRemoveDialog,
      },
    ],
    [openRemoveDialog]
  );

  const restrictAccessOptions = Object.keys(EnumDocumentAccessRole).map(
    (option) => ({
      label: t(`documents.documentAccessRoles.${option}`),
      value: option,
    })
  );

  const [getTeamData, { data: teamData }] = useLazyQuery<ListMembersResponse>(
    LIST_MEMBERS
  );
  const teamMembers = React.useMemo(() => teamData?.listMembers || [], [
    teamData,
  ]);

  const teamOptions = map(teamMembers, (member) => ({
    value: member._id,
    label: getFullName(member),
  }));

  const handleRestrictAccessChange = React.useCallback(
    (value: string) => {
      if (value === EnumDocumentAccessRole.SELECTED) {
        getTeamData();
      }
      setRestrictAccessValue(value as EnumDocumentAccessRole);
    },
    [getTeamData]
  );

  return (
    <>
      <LeftModal
        className={"upload-files-modal"}
        show={showModal}
        onHide={handleClose}
      >
        <LeftModalHeader
          title={t("documents.uploadFiles")}
          onClose={handleClose}
        />
        <LeftModalBody className="flex-column">
          {files && files.length > 0 && (
            <TableCard
              data={filesTableData}
              rowCount={true}
              rowActions={tableRowActions}
            />
          )}
          <div className="uploader">
            <FileInput onUpload={handleFileUpload} />
            {setAccessPermissions && (
              <div className="form-group d-flex flex-column">
                <label className="form-input-label form-label">
                  {t("documents.restrictAccess")}
                </label>
                <AutocompleteInput
                  name="setAccessPermissions"
                  options={restrictAccessOptions}
                  placeholder={t("documents.restrictAccess")}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  onChange={handleRestrictAccessChange}
                  value={restrictAccessValue}
                />
              </div>
            )}
            {restrictAccessValue === EnumDocumentAccessRole.SELECTED && (
              <div className="form-group d-flex flex-column">
                <label className="form-input-label form-label">
                  {t("documents.accessUsers")}
                </label>
                <CreatableSelectInput
                  name="accessSelectedUsers"
                  onChange={(values) => setSelectedUsers(values as string[])}
                  value={selectedUsers}
                  options={teamOptions}
                  isMulti={true}
                />
              </div>
            )}
          </div>
        </LeftModalBody>
        <LeftModalFooter>
          <Button
            variant="secondary"
            className="button large info"
            disabled={false}
            onClick={handleClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="primary"
            disabled={false}
            className="button large success"
            onClick={handleUpload}
          >
            {t("common.upload")}
          </Button>
        </LeftModalFooter>
      </LeftModal>
      <UploadSpinner
        show={isUploading}
        text={t("documents.pleaseWaitUpload")}
        filesProgress={filesProgress}
      />
    </>
  );
};

export default forwardRef(UploadDocumentModal);
