import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import ImportMapper from "../../../components/import-mapper";
import DashboardCard from "../../../components/dashboard/card";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import { MapFieldsResult } from "../../../utils/sheets/types";
import { useMutation, useQuery } from "@apollo/client";
import { notify } from "../../../components/notification";
import { useHistory } from "react-router-dom";
import { LIST_CONTACTS } from "../../../graphql/queries/client/queries";
import { useImportMapper } from "../../../hooks/useImportMapper";
import { FIELD_MAP_LEAD } from "./leads";
import LeadsImport from "../../../components/lead/leads-import";
import { IMPORT_LEADS } from "../../../graphql/queries/leads/mutations";
import {
  ImportLeadsPayload,
  ImportLeadsResponse,
  LeadsColumnMap,
} from "../../../models/leads";
import { ContactListResponse } from "../../../graphql/types/models/client";
import { getFullName } from "../../../utils/text";
import { get } from "lodash";
import { MapField } from "../../../components/import-mapper/types";
import { parseDate } from "../../../utils/date";

const LeadsImportContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [leads, setLeads] = useState<MapFieldsResult<LeadsColumnMap>[] | null>(
    null
  );
  const [skippedLeads, setSkippedLeads] = useState<
    MapFieldsResult<LeadsColumnMap>[] | null
  >(null);
  const [mapFields, setMapFields] = useState<MapField<LeadsColumnMap>[]>(
    FIELD_MAP_LEAD
  );

  const {
    file,
    headerRow,
    sampleRow,
    rows,
    handleFileAccept,
    handleReset,
  } = useImportMapper();

  const [mapRows, setMapRows] = useState<string[] | null>(headerRow);

  const { data: contactsList } = useQuery<ContactListResponse>(LIST_CONTACTS);
  const contactsData = React.useMemo(
    () =>
      contactsList?.listContacts.map((contact) => getFullName(contact)) || [],
    [contactsList]
  );

  const [importLeads, { loading: isLoadingImportLeads }] = useMutation<
    ImportLeadsResponse,
    ImportLeadsPayload
  >(IMPORT_LEADS, {
    onCompleted: ({ importLeads }) => {
      notify({
        content: skippedLeads?.length
          ? t("mapping.leadsImportedSkipped", {
              imported: leads?.length ? leads?.length - skippedLeads.length : 0,
              leadsCount: leads?.length,
              skippedCount: skippedLeads.length,
            })
          : t("mapping.leadsImported", {
              imported: leads?.length,
            }),
        title: t("mapping.importLeads"),
      });
      reset();
      if (importLeads.length > 0) {
        history.push("/leads");
      }
    },
    onError: (e) => {
      notify({
        error: true,
        title: t("mapping.importError"),
        content: get(e, "message", t("mapping.importError")),
      });
    },
  });

  React.useEffect(() => {
    setMapRows(headerRow);
  }, [headerRow]);

  const handleLeadsImport = React.useCallback(
    async (importData: LeadsColumnMap[]) => {
      const skippedImportData = importData.filter(
        (item) =>
          !contactsData.includes(
            `${item.contactFirstName} ${item.contactLastName}`
          )
      );
      const filteredImportData = importData.filter((item) =>
        contactsData.includes(
          `${item.contactFirstName} ${item.contactLastName}`
        )
      );
      setLeads(importData);
      setSkippedLeads(skippedImportData);

      await importLeads({
        variables: {
          leads: filteredImportData.map((item) => ({
            ...item,
            ...(item?.expectedCloseDate && {
              expectedCloseDate: parseDate(
                item?.expectedCloseDate
              ).toISOString(),
            }),
            budget: item?.budget
              ? Number(item.budget.toString().replace(/[^0-9\.]/g, "")) || 0
              : 0,
          })),
        },
      });
    },
    [contactsData, importLeads]
  );

  const onImport = React.useCallback(
    async (data: any[]) => {
      if (data.length > 0) {
        handleLeadsImport(data);
      }
    },
    [handleLeadsImport, contactsData]
  );

  const onChange = React.useCallback(
    async (data: any) => {
      const fieldsTofilter = ["assignedToFirstName", "assignedToLastName"];
      const rowsTofilter = [
        t("leads.assignedToFirstName"),
        t("leads.assignedToLastName"),
      ];
      if (Boolean(data?.[t("leads.assignedToEmail")])) {
        const filteredMapFileds = mapFields.filter(
          (field) => !fieldsTofilter.includes(field.fieldKey)
        );
        const filteredMapRows = mapRows?.filter(
          (row) => !rowsTofilter.includes(row)
        );
        setMapFields(filteredMapFileds);
        setMapRows(filteredMapRows || null);
        return;
      }
      if (
        Boolean(data?.[t("leads.assignedToFirstName")]) ||
        Boolean(data?.[t("leads.assignedToLastName")])
      ) {
        const mapFileds = mapFields.map((field) => {
          if (fieldsTofilter.includes(field.fieldKey)) {
            return { ...field, required: true };
          }
          return field;
        });
        const filteredMapRows = mapRows?.filter(
          (row) => row !== t("leads.assignedToEmail")
        );
        setMapFields(
          mapFileds.filter((field) => field.fieldKey !== "assignedToEmail")
        );
        setMapRows(filteredMapRows || null);
        return;
      }

      setMapFields(FIELD_MAP_LEAD);
      setMapRows(headerRow);
    },
    [headerRow, mapFields, mapRows]
  );

  const reset = React.useCallback(() => {
    setSkippedLeads(null);
    setLeads(null);
    handleReset();
  }, [handleReset]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.leadsSection.importLeads")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.LEADS_SECTION.IMPORT_LEADS}
      />

      <Row className="h-100">
        <Col lg={4} xs={12}>
          <LeadsImport fileName={file?.name} onFileSelect={handleFileAccept} />
        </Col>
        <Col lg={8} xs={12}>
          {headerRow && (
            <DashboardCard className="import-mapper">
              <DashboardCardHeader>{t("leads.mapData")}</DashboardCardHeader>
              <ImportMapper
                options={mapFields}
                rows={rows}
                onSubmit={onImport}
                onChange={onChange}
                header={mapRows || []}
                sample={sampleRow}
                isLoading={isLoadingImportLeads}
              />
            </DashboardCard>
          )}
          {!headerRow && <CardPlaceholder />}
        </Col>
      </Row>
    </Container>
  );
};

export default LeadsImportContainer;
