import React, { FC } from "react";
import ReactDatePicker from "react-datepicker";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../icons/Icon";
import "./styles.scss";

type DatePickerRangeProps = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (value: Date) => void;
  setEndDate: (value: Date) => void;
  onResetRange?: () => void;
  showResetButton?: boolean;
  className?: string;
};

const DatePickerRange: FC<DatePickerRangeProps> = (props) => {
  const { t } = useTranslation();
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    className,
    onResetRange,
    showResetButton,
  } = props;

  return (
    <div
      className={classNames("datePickerContainer", {
        widthWithIcon: showResetButton,
      })}
    >
      <div className="datePickerLabel">{t("common.dateRange")}</div>
      <ReactDatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date as Date)}
        selectsStart
        maxDate={endDate}
        className={className}
        dateFormat={"dd/MM/yy"}
        placeholderText={t("common.min")}
      />
      <span>{"-"}</span>
      <ReactDatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date as Date)}
        selectsEnd
        minDate={startDate}
        className={className}
        dateFormat={"dd/MM/yy"}
        placeholderText={t("common.max")}
      />
      {showResetButton && (
        <div className="reset-icon-container">
          {(startDate || endDate) && (
            <button onClick={onResetRange}>
              <Icon className="reset-icon" name="close" outlined />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DatePickerRange;
