import React from "react";
import { Badge, BadgeProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EnumSelectionStatus } from "../../../graphql/types/models/selections";

type SelectionBudgeType = {
  status?: EnumSelectionStatus;
  className?: string;
  isOption?: boolean;
  isClient?: boolean;
};

const SelectionBudge: React.FC<SelectionBudgeType> = ({
  status,
  className,
  isOption,
  isClient,
}) => {
  const { t } = useTranslation();

  const selectionBadgeVariant = {
    [EnumSelectionStatus.DRAFT]: "secondary",
    [EnumSelectionStatus.SHARED]: "info",
    [EnumSelectionStatus.INCOMPLETE]: "info",
    [EnumSelectionStatus.SELECTED]: "warning",
    [EnumSelectionStatus.CANCELLED]: "danger",
    [EnumSelectionStatus.COMPLETED]: "success",
    [EnumSelectionStatus.OVERDUE]: "danger",
  };

  if (!status) return <></>;

  return (
    <Badge
      className={className}
      variant={selectionBadgeVariant[status] as BadgeProps["variant"]}
    >
      {status === EnumSelectionStatus.SELECTED && !isOption && !isClient
        ? t("selections.statuses.pendingApproval")
        : t(`selections.statuses.${status}`)}
    </Badge>
  );
};

export default SelectionBudge;
