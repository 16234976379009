import XLSX, { WorkSheet } from "xlsx";
import { map, transform, get } from "lodash";
import { MapResult } from "../../components/import-mapper/types";
import { MapFieldsResult } from "./types";

export const readSheet = (
  file: File,
  sheetIndex: number
): Promise<WorkSheet> => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const tableData = XLSX.read(reader.result, { type: "binary" });
      const sheet = tableData.Sheets[tableData.SheetNames[sheetIndex]];

      resolve(sheet);
    };

    reader.onerror = () => {
      reject(null);
    };
  });
};

export const mapFields = <Data extends {}>(
  mappedFields: MapResult<Data>,
  rows: object[]
): MapFieldsResult<Data>[] => {
  return map(rows, (sheetRow) => {
    return transform<string | null, Partial<Data>>(
      mappedFields,
      (newRow, fieldName, key) => {
        if (!fieldName) {
          return null;
        }

        const rowKey: keyof Data = key as keyof Data;
        newRow[rowKey] = get(sheetRow, fieldName);
      },
      {}
    );
  });
};
