import React from "react";
import { useQuery } from "@apollo/client";
import { LIST_JOBS } from "../../graphql/queries/job/queries";
import { ListJobsResponse } from "../../graphql/types/models/job";

export function useJobsQuery() {
  const { data: jobsData } = useQuery<ListJobsResponse>(LIST_JOBS);

  const jobs = React.useMemo(() => jobsData?.listJobs || [], [jobsData]);

  return { jobs };
}
