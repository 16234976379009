import React from "react";
import PurchaseOrderForm from "../../components/order/order-modal/purchaser-form";
import { ModalDisplayRef } from "../useModalDisplay";
import { JobCostingItem } from "../../models/job";
import {
  QuoteRequestItem,
  QuoteRequestSubcontractor,
} from "../../graphql/types/models/quote-request";
import { CreateUpdateOrderResponse } from "../../graphql/types/models/order";
import { useHistory } from "react-router-dom";

type CreateOrderMutationProps = {
  data: QuoteRequestItem[];
  subcontractor?: QuoteRequestSubcontractor;
  jobId?: string;
};

export function useCreateOrderMutation(props: CreateOrderMutationProps) {
  const { data, subcontractor, jobId } = props;
  const history = useHistory();

  const createOrderModalRef = React.useRef<ModalDisplayRef>(null);
  const [selectedCostingItems, setSelectedCostingItems] = React.useState<
    JobCostingItem[]
  >([]);

  const handleShowCreateOrderModal = React.useCallback(() => {
    const orderItems = data.map((item, index) => {
      return {
        _id: item.job_costing?._id,
        name: item.name,
        quantity: item.quantity,
        UOM: item.UOM,
        cost: subcontractor?.items[index]?.cost,
        category: item.job_costing?.category,
        hasGST: !subcontractor?.items[index]?.gst_inc,
      };
    });

    setSelectedCostingItems(orderItems as JobCostingItem[]);
    createOrderModalRef.current?.show(true);
  }, [data, subcontractor]);

  const handleOrderCreated = React.useCallback(
    (values: CreateUpdateOrderResponse) => {
      createOrderModalRef.current?.show(false);
      setSelectedCostingItems([]);
      history.push(
        `/jobs/${jobId}/purchase-orders/${values.jobCreateUpdatePurchaseOrder._id}`
      );
    },
    [history, jobId]
  );

  const renderPurchaseOrderForm = React.useCallback(() => {
    return (
      <PurchaseOrderForm
        ref={createOrderModalRef}
        jobId={jobId}
        onSubmit={handleOrderCreated}
        costingItems={selectedCostingItems}
        supplierId={subcontractor?.supplier?._id}
      />
    );
  }, [
    handleOrderCreated,
    jobId,
    selectedCostingItems,
    subcontractor?.supplier?._id,
  ]);

  return { renderPurchaseOrderForm, handleShowCreateOrderModal };
}
