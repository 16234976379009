import { gql } from "@apollo/client";

export const PURCHASE_ORDER_FRAGMENT = gql`
  fragment PurchaseOrderFragment on PurchaseOrder {
    _id
    supplier {
      _id
      business_name
      contact_person
      email
      abn
    }
    job {
      _id
      type
    }
    reference
    status
    attention
    orderNumber
    deliveryInstructions
    deliveryAddress
    deliveryDate
    date
    contactNumber
    hasGST
    costIncGST
    hideCost
    externalLastSyncDate
    internalNote
    subTotal
    GST
    total
    items {
      _id
      rounding
      wastage
      UOM
      cost
      quantity
      raw_quantity
      name
      costingCategoryName
      costingItemId
      hasGST
      items {
        _id
        UOM
        cost
        name
        quantity
        raw_quantity
        rounding
        wastage
      }
    }
    receipts {
      _id
      reference
      dateReceived
      dueDate
      hasGST
      costIncGST
      isNonBillable
      subTotal
      GST
      total
      internalNote
      receiptDocuments {
        _id
        name
        url
      }
      items {
        _id
        itemNumber
        name
        UOM
        cost
        margin_amount
        quantity
        hasGST
      }
    }
  }
`;
