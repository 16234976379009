import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { FormikProps } from "formik";
import { map, uniqBy } from "lodash";
import CreateEntityModal, { ModalButton } from "../../modals/create-entity";
import { createAssemblyFields } from "./utils";
import { QuoteMeasurement } from "../../../models/salesQuote";
import CalculatorModal from "../../modals/calculator";
import createAssemblySchema from "./CreateAssembly.schema";
import {
  AssemblyCreatePayload,
  AssemblyListItem,
} from "../../../models/assembly";
import { findEnum } from "../../../utils/options";
import { UOMOption } from "../../../utils/types/options";
import { useCostingLookup } from "../../../hooks/useCostingLookup";
import { TakeoffListItem } from "../../../models/take-off";
import { FormikPropGetSetValues } from "../../generic-form/GenericFormBody";
import { CostSubItem } from "../../../models/costing";
import { Rounding } from "../../calculator/utils";
import { SearchCostingItemType } from "../../../graphql/types/models/price-list";
import "./styles.scss";

type CreateAssemblyModalProps = {
  uoms: UOMOption[];
  onSubmit: (
    data: AssemblyCreatePayload,
    formikProps: FormikProps<any>
  ) => void;
  onClose: () => void;
  formikProps: FormikProps<any>;
  takeoffs?: TakeoffListItem[];
  takeoffProps?: {
    name?: string;
    canCreateTakeoff?: boolean;
    salesQuoteId?: string;
  };
};

export type CreateAssemblyModalRef = {
  show: (show: boolean, assembly?: AssemblyListItem) => void;
};

const CreateAssemblyModal: React.FC<CreateAssemblyModalProps> = (
  props,
  ref
) => {
  const {
    onSubmit,
    onClose,
    formikProps,
    uoms: currentUOMs,
    takeoffs,
    takeoffProps,
  } = props;

  const client = useApolloClient();
  const { searchCostingItem, handleCostingNameChange } = useCostingLookup(
    client,
    SearchCostingItemType.PRICE_LIST
  );

  const [show, setShowModal] = useState(false);
  const [showCalculator, setCalculatorVisibility] = useState(false);
  const [calcFieldName, setFieldName] = useState("");
  const [assemblyItem, setAssemblyItem] = useState<CostSubItem | null>(null);
  const [assembly, setAssembly] = useState<AssemblyListItem | null>(null);
  const [uoms, setUOMs] = React.useState<UOMOption[]>(currentUOMs);
  const [activeUOMButton, setActiveUOMButton] = React.useState<
    QuoteMeasurement
  >(QuoteMeasurement.LINEAR_METER);

  useImperativeHandle(ref, () => ({
    show: (show: boolean, assembly?: AssemblyListItem) => {
      setShowModal(show);
      setAssembly(assembly || null);
    },
  }));

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        uoms.concat([{ label: uom, value: uom }]),
        "value"
      );
      setUOMs(newUOMs);
    },
    [uoms, setUOMs]
  );

  const handleOnUOMButton = React.useCallback((value: string) => {
    setActiveUOMButton(findEnum(QuoteMeasurement, value));
  }, []);

  const initialValues = React.useMemo(() => {
    return {
      _id: assembly?._id,
      name: assembly?.name || "",
      UOM:
        (assembly?.UOM && findEnum(QuoteMeasurement, assembly?.UOM)) ||
        QuoteMeasurement.LINEAR_METER,
      saveForReuse: false,
      items: assembly
        ? map(assembly.items, (item) => ({
            cost: item.cost,
            quantity: parseFloat(item.quantity),
            raw_quantity: item.raw_quantity,
            UOM:
              findEnum(QuoteMeasurement, item.UOM) ||
              QuoteMeasurement.LINEAR_METER,
            name: item.name,
            total: item.total,
            wastage: item.wastage,
            rounding: item.rounding,
          }))
        : [
            {
              cost: 0,
              quantity: 0,
              raw_quantity: "",
              rounding: Rounding.NONE,
              wastage: 0,
              UOM: QuoteMeasurement.LINEAR_METER,
              name: "",
              total: 0,
            },
          ],
    };
  }, [assembly]);

  const toggleCalculatorModal = React.useCallback(() => {
    setCalculatorVisibility(!showCalculator);
  }, [showCalculator]);

  const { t } = useTranslation();

  const openCalculatorModal = React.useCallback(
    (
      fieldName: string,
      fieldValue?: string,
      rowIndex?: number,
      formikProps?: FormikPropGetSetValues
    ) => {
      if (rowIndex || rowIndex === 0) {
        setAssemblyItem(formikProps?.values?.items[rowIndex]);
      }
      setFieldName(fieldName);
      setCalculatorVisibility(true);
    },
    []
  );

  const saveAndReuseButton = React.useMemo<ModalButton>(
    () => ({
      title: t("assembly.saveForReuse"),
      onClick: async (formik: FormikProps<AssemblyCreatePayload>) => {
        formik.setFieldValue("saveForReuse", true);
        await formik.submitForm();
        if (!formik.isValid) {
          formik.setFieldValue("saveForReuse", false);
        }
      },
    }),
    [t]
  );

  const handleSubmit = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, []);

  const handlePriceListItemChange = React.useCallback(
    (fieldValue, rowIndex, formikProps) => {
      const fieldsArray = `items[${rowIndex}]`;
      const item = handleCostingNameChange(
        fieldValue,
        fieldsArray,
        formikProps
      );
      if (item?.UOM) {
        handleUOMCreate(item.UOM);
      }
    },
    [handleUOMCreate]
  );

  const formFields = React.useMemo(() => {
    return createAssemblyFields(
      t,
      uoms,
      handleUOMCreate,
      openCalculatorModal,
      searchCostingItem,
      handlePriceListItemChange,
      handleOnUOMButton,
      activeUOMButton
    );
  }, [
    t,
    uoms,
    handleUOMCreate,
    openCalculatorModal,
    handlePriceListItemChange,
    activeUOMButton,
  ]);

  return (
    <CreateEntityModal
      className="assembly-modal"
      validationSchema={createAssemblySchema(t)}
      title={t("assembly.addAssembly")}
      show={show}
      leftButton={saveAndReuseButton}
      data={initialValues}
      onSubmit={(data) => onSubmit(data, formikProps)}
      onClose={onClose}
      fields={formFields}
    >
      {(formikProps: FormikProps<any>) => {
        const rawQuantity =
          assemblyItem?.raw_quantity || assemblyItem?.quantity || "";
        const itemName = assemblyItem?.name;

        return (
          <CalculatorModal
            formikProps={formikProps}
            show={showCalculator}
            onSubmit={handleSubmit}
            fieldName={calcFieldName}
            onClose={toggleCalculatorModal}
            initialValue={rawQuantity}
            wastage={assemblyItem?.wastage?.toString()}
            rounding={assemblyItem?.rounding}
            takeoffs={takeoffs}
            takeoffProps={{
              ...takeoffProps,
              name: assemblyItem?.name || assembly?.name || "",
            }}
            title={itemName}
          />
        );
      }}
    </CreateEntityModal>
  );
};

export default forwardRef(CreateAssemblyModal);
