import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  EnumSelectionStatus,
  SelectionCategoryItemOption,
  SelectionCategoryItemType,
} from "../../../graphql/types/models/selections";
import { getFullName } from "../../../utils/text";
import SelectionItemOption from "../item-option";
import "./styles.scss";
import EmptyPlaceholder from "../../empty-placeholder";

type SelectionItemOptionsProps = {
  item: SelectionCategoryItemType;
  onDelete?: (option: SelectionCategoryItemOption) => void;
  onEdit?: (option: SelectionCategoryItemOption) => void;
  onSelect?: (option: SelectionCategoryItemOption) => void;
  onUnselect?: () => void;
  onApprove?: () => void;
  onClientSelect?: (
    option: SelectionCategoryItemOption,
    comment?: string
  ) => void;
  isLoading?: boolean;
  isLocked?: boolean;
  isClient?: boolean;
  isTemplate?: boolean;
  selectedOptions?: { optionId: string; comment: string }[];
};

const SelectionItemOptions: React.FC<SelectionItemOptionsProps> = (props) => {
  const {
    item,
    isLoading,
    onEdit,
    onDelete,
    onSelect,
    onApprove,
    onUnselect,
    isClient,
    isLocked,
    onClientSelect,
    isTemplate,
    selectedOptions,
  } = props;
  const { t } = useTranslation();

  const isCompleted = item.status === EnumSelectionStatus.COMPLETED;

  const approvedBy = item?.approvedBy
    ? t("selections.approvedByBuilder", {
        name: getFullName(item?.approvedBy),
        date: moment(item?.approvedByDate).format("Do MMM YYYY"),
      })
    : undefined;

  return (
    <div className="options-container">
      {item.options.length ? (
        item.options.map((option, index) => {
          const selected = selectedOptions?.find(
            (item) => item.optionId === option._id
          );
          const selectedBy =
            item?.selectedOption?._id === option._id
              ? t("selections.completedByClient", {
                  name: getFullName(item?.selectedBy),
                  date: moment(item?.selectedByDate).format("Do MMM YYYY"),
                })
              : undefined;

          return (
            <SelectionItemOption
              key={option._id}
              index={index}
              option={option}
              onEdit={onEdit}
              onDelete={onDelete}
              onSelect={onSelect}
              onUnselect={onUnselect}
              onApprove={onApprove}
              selectedBy={selectedBy}
              approvedBy={selectedBy ? approvedBy : undefined}
              isCompleted={isCompleted}
              isLoading={isLoading}
              isClient={isClient}
              onClientSelect={onClientSelect}
              isLocked={isLocked}
              isTemplate={isTemplate}
              selected={Boolean(selected)}
              selectedComment={selected?.comment || ""}
              clientComment={item.clientComment}
              clientSignatureUrl={item.clientSignature?.file.url}
            />
          );
        })
      ) : (
        <EmptyPlaceholder message={t("selections.emptyOprionsPlaceholder")} />
      )}
    </div>
  );
};

export default SelectionItemOptions;
