import React, { useState } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import filesize from "filesize";
import * as uuid from "uuid";
import { map, get, uniqBy, chain } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import ModalForm from "../../modals/modal-form";
import createOrderReceiveSchema from "./ReceiveOrder.schema";
import { TableRowActionData } from "../../dashboard/table-card/utils";
import {
  PurchaseOrderReceivePreparatoryPayload,
  PurchaseOrderPreparatoryItemPayload,
  ReceivePurchaseOrderPayload,
  PurchaseOrderReceiptItemPayload,
  PurchaseOrderStatus,
} from "../../../models/purchaseOrder";
import CalculatorModal, { CalcFieldData } from "../../modals/calculator";
import {
  GenericFormFields,
  GenericFormTable,
  renderField,
  renderTableForm,
} from "../../generic-form/GenericFormBody";
import { PurchaseOrderReal } from "../../../models/purchaseOrder";
import ModalTotals from "../../modals/modal-totals";
import { VariationPayload } from "../../../models/variations";
import { CalcBag } from "../../calculator/utils";
import createPurchaseOrderReceiveFields from "./utils";
import TableCard from "../../dashboard/table-card";
import FileInput from "../../uploaders/file-input";
import { UOMOption } from "../../../utils/types/options";
import { MEASUREMENT_OPTIONS } from "../../../utils/options";
import { CREATE_UPDATE_DOCUMENT } from "../../../graphql/queries/documents/mutations";
import { GET_SYSTEM_SUB_FOLDER } from "../../../graphql/queries/documents/queries";
import {
  CreateUpdateDocumentResponse,
  GetSystemSubFolderResponse,
} from "../../../graphql/types/models/documents";
import {
  EnumDocumentAccessRole,
  SystemFolderType,
  SystemSubFolderType,
} from "../../../models/documents";
import { uploadFiles } from "../../../utils/files";
import { useCostChangeGST } from "../../../hooks/useCostChangeGST";
import { calcGSTEx, GST_PERCENT } from "../../../utils/calculations";
import { TakeoffListItem } from "../../../models/take-off";
import { JobType } from "../../../models/job";
import "./styles.scss";

type FileTable = {
  id: string;
  name: string;
  size: number;
};

type PurchaseOrderReceiveModalProps = {
  data: PurchaseOrderReal | null;
  jobId?: string;
  show: boolean;
  onClose: () => void;
  onSubmit: (data: ReceivePurchaseOrderPayload | null) => void;
  onReferenceChange: (value: string) => Promise<void>;
  referenceFieldWarning: string;
  takeoffs?: TakeoffListItem[] | null;
  salesQuoteId?: string | null;
};

const PurchaseOrderReceiveModal: React.FC<PurchaseOrderReceiveModalProps> = (
  props
) => {
  const {
    data,
    jobId,
    show,
    onClose,
    onSubmit,
    onReferenceChange,
    referenceFieldWarning,
    takeoffs,
    salesQuoteId,
  } = props;

  const { t } = useTranslation();

  const {
    costsHaveGST,
    handleCostChange,
    handleCostsHaveGST,
    resetCostsHaveGST,
  } = useCostChangeGST();

  const [formFields, setFormFields] = useState<
    GenericFormFields<PurchaseOrderReceivePreparatoryPayload>
  >({});
  const [calcData, setFieldData] = useState<CalcFieldData>({
    fieldName: "",
  });
  const [showCalculator, setCalculatorVisibility] = useState(false);
  const [files, setFiles] = React.useState<{ id: string; file: File }[]>([]);
  const [currentUOMOptions, setCurrentUOMOptions] = useState<UOMOption[]>(
    MEASUREMENT_OPTIONS.map((item) => {
      return {
        value: item.value.toString(),
        label: item.label.toString(),
      };
    })
  );

  const isCostPlus = data?.job?.type === JobType.COST_PLUS;

  const { data: systemFolderResponse } = useQuery<GetSystemSubFolderResponse>(
    GET_SYSTEM_SUB_FOLDER,
    {
      variables: {
        refId: jobId,
        systemType: SystemFolderType.JOB,
        subType: SystemSubFolderType.JOB_PURCHASE_ORDER,
      },
    }
  );

  const [addFile] = useMutation<CreateUpdateDocumentResponse>(
    CREATE_UPDATE_DOCUMENT,
    {
      onCompleted: async (data) => {
        if (
          files &&
          files.length > 0 &&
          data.createUpdateDocuments.length > 0
        ) {
          await uploadFiles(
            data.createUpdateDocuments.map((document) => ({
              name: document.name,
              upload_url: document.upload_url,
            })),
            files.map((fileItem) => fileItem.file)
          );
        }
      },
    }
  );

  const initialValues = React.useMemo(() => {
    if (!data)
      return {
        reference: "",
        dateReceived: "",
        dueDate: "",
        internalNote: "",
        isNonBillable: false,
        purchaseOrderStatus: PurchaseOrderStatus.RECEIVED,
        items: [
          {
            _id: "",
            name: "",
            UOM: "lm",
            cost: 0,
            quantity: 1,
            gstFree: false,
            hasGST: true,
          },
        ],
      };

    return {
      reference: "",
      dateReceived: "",
      dueDate: "",
      internalNote: data?.internalNote,
      purchaseOrderStatus: PurchaseOrderStatus.RECEIVED,
      isNonBillable: false,
      items: map(data.items, (item) => ({
        ...item,
        hasGST: item.hasGST,
        gstFree: item.hasGST === false,
      })),
    };
  }, [data]);

  const closeModal = React.useCallback(() => {
    setFiles([]);
    resetCostsHaveGST();
    return onClose && onClose();
  }, [onClose, setFiles]);

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        currentUOMOptions.concat([{ label: uom, value: uom }]),
        "value"
      );
      setCurrentUOMOptions(newUOMs);
    },
    [currentUOMOptions, setCurrentUOMOptions]
  );

  const prepareItemDataToUpload = React.useCallback(
    (item: PurchaseOrderPreparatoryItemPayload, index: number) => {
      const { name, cost, cost_inc, quantity, UOM, gstFree } = item;

      const resultItem: PurchaseOrderReceiptItemPayload = {
        name,
        cost: costsHaveGST
          ? calcGSTEx(Number(cost_inc) || 0, GST_PERCENT)
          : Number(cost || 0),
        quantity: Number(quantity) || 0,
        hasGST: !gstFree,
        UOM,
        itemNumber: index + 1,
      };

      if (item._id) {
        resultItem.purchaseOrderItem = item._id;
      }

      return resultItem;
    },
    [costsHaveGST]
  );

  const prepareDataToUpload = React.useCallback(
    (formData: PurchaseOrderReceivePreparatoryPayload) => {
      if (!jobId || !data) return null;

      return {
        jobId,
        purchaseOrderId: data._id,
        receipt: {
          reference: formData.reference,
          dateReceived: formData.dateReceived,
          dueDate: formData.dueDate,
          isNonBillable: !!formData.isNonBillable,
          purchaseOrderStatus: formData.purchaseOrderStatus,
          costIncGST: costsHaveGST,
          items: formData.items.map((item, index) =>
            prepareItemDataToUpload(item, index)
          ),
          receiptDocumentId: [] as string[],
        },
      };
    },
    [jobId, data, prepareItemDataToUpload, costsHaveGST]
  );

  const onPurchaseOrderReceiveSubmit = React.useCallback(
    async (formData: PurchaseOrderReceivePreparatoryPayload) => {
      if (!jobId) {
        resetCostsHaveGST();
        closeModal();
        return;
      }

      const resultData = prepareDataToUpload(formData);
      const systemFolder = systemFolderResponse?.getSystemSubFolder;

      if (systemFolder && resultData && files.length) {
        const data = await addFile({
          variables: {
            documents: files.map((fileItem) => ({
              folderId: systemFolder._id,
              name: fileItem.file.name,
              size: fileItem.file.size,
              type: fileItem.file.type,
              accessRole: EnumDocumentAccessRole.MANAGER,
            })),
          },
        });

        if (data.data?.createUpdateDocuments) {
          const receiptDocumentId = data.data?.createUpdateDocuments.map(
            (document) => document._id
          );
          resultData.receipt.receiptDocumentId = receiptDocumentId;
        }
      }

      onSubmit && onSubmit(resultData);
      closeModal();
      resetCostsHaveGST();
    },
    [
      jobId,
      closeModal,
      systemFolderResponse,
      addFile,
      files,
      prepareDataToUpload,
      onSubmit,
    ]
  );

  const openCalculatorModal = React.useCallback(
    (fieldName: string, _fieldValue?: string, rowIndex?: number) => {
      setFieldData({
        fieldName,
        rowIndex,
      });
      setCalculatorVisibility(true);
    },
    []
  );

  const closeCalcModal = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, []);

  const handleCalculatorSubmit = React.useCallback(
    (formikProps: FormikProps<VariationPayload>, calcBag: CalcBag) => {
      formikProps.setFieldValue(
        `items[${calcData.rowIndex}].rounding`,
        calcBag.rounding
      );
      formikProps.setFieldValue(
        `items[${calcData.rowIndex}].wastage`,
        calcBag.wastage
      );
      setCalculatorVisibility(false);
    },
    [calcData]
  );

  const handleDisableRowField = React.useCallback(
    (rowValues: PurchaseOrderPreparatoryItemPayload, rowIndex: number) => {
      if (rowValues._id && data) {
        return !!data.items.find((item) => item._id === rowValues._id);
      }
      return false;
    },
    [data]
  );

  React.useEffect(() => {
    setFormFields(
      createPurchaseOrderReceiveFields(
        t,
        openCalculatorModal,
        handleDisableRowField,
        currentUOMOptions,
        handleUOMCreate,
        costsHaveGST,
        handleCostsHaveGST,
        handleCostChange,
        onReferenceChange,
        referenceFieldWarning
      )
    );
  }, [
    t,
    openCalculatorModal,
    handleDisableRowField,
    currentUOMOptions,
    handleUOMCreate,
    costsHaveGST,
    handleCostChange,
    onReferenceChange,
    referenceFieldWarning,
  ]);

  React.useEffect(() => {
    const defUOAM = MEASUREMENT_OPTIONS.map((item) => {
      return {
        value: item.value.toString(),
        label: item.label.toString(),
      };
    });

    if (initialValues && initialValues.items) {
      initialValues.items.forEach((itm) => {
        if (itm.UOM)
          defUOAM.push({
            value: itm.UOM,
            label: itm.UOM,
          });
      });
    }

    setCurrentUOMOptions(chain(defUOAM).uniqBy("value").value());
  }, [initialValues, data, setCurrentUOMOptions]);

  const filesTableData = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("documents.documentName"),
        },
        {
          valueKey: "size",
          title: t("documents.size"),
          formatValue: (row: any, column: any, value: number) =>
            value ? filesize(value) : "-",
        },
      ],
      rows: map(files, (item) => ({
        cells: {
          id: item.id,
          name: item.file.name,
          size: item.file.size,
        },
      })),
    };
  }, [files]);

  const handleFileUpload = React.useCallback(
    (uploadedFiles: File[]) => {
      setFiles([
        ...files,
        ...uploadedFiles.map((item) => ({ id: uuid.v4(), file: item })),
      ]);
    },
    [files]
  );

  const handleDeleteFile = React.useCallback(
    (row?: FileTable) => {
      if (row) {
        const newFiles = files.filter((item) => item.id !== row.id);
        setFiles(newFiles);
      }
    },
    [files]
  );

  const tableRowActions: TableRowActionData<FileTable>[] = React.useMemo(
    () => [
      {
        icon: "delete",
        id: "remove",
        onClick: handleDeleteFile,
      },
    ],
    [handleDeleteFile]
  );

  return (
    <ModalForm<PurchaseOrderReceivePreparatoryPayload>
      show={show}
      validationSchema={createOrderReceiveSchema(t)}
      onClose={closeModal}
      data={initialValues}
      className="order-receive-modal"
      title={t("orders.receivePurchaseOrder")}
      onSubmit={onPurchaseOrderReceiveSubmit}
      submitText={t("orders.receiveOrder")}
    >
      {(formikProps: FormikProps<PurchaseOrderReceivePreparatoryPayload>) => (
        <Container className="generic-form-body" fluid>
          <CalculatorModal
            formikProps={formikProps}
            show={showCalculator}
            onSubmit={handleCalculatorSubmit}
            fieldName={calcData.fieldName}
            takeoffs={takeoffs}
            takeoffProps={{
              canCreateTakeoff: !!salesQuoteId,
              salesQuoteId: salesQuoteId ? salesQuoteId : undefined,
              name: get(formikProps.values, calcData.fieldName.split(".")[0])
                ?.name,
            }}
            initialValue={get(formikProps.values, calcData.fieldName)}
            wastage={(
              get(
                formikProps.values,
                `items[${calcData.rowIndex}].wastage`,
                ""
              ) || ""
            ).toString()}
            rounding={(
              get(
                formikProps.values,
                `items[${calcData.rowIndex}].rounding`,
                ""
              ) || ""
            ).toString()}
            onClose={closeCalcModal}
            title={
              get(formikProps.values, calcData.fieldName.split(".")[0])?.name
            }
          />
          <Row>
            <Col lg={3} xs={12} md={12} className="col-no-gutters">
              {renderField(formikProps, formFields.reference, 12)}
            </Col>
            <Col lg={3} xs={12} md={12} className="col-no-gutters">
              {renderField(formikProps, formFields.dateReceived, 12)}
            </Col>
            <Col lg={3} xs={12} md={12} className="col-no-gutters">
              {renderField(formikProps, formFields.dueDate, 12)}
            </Col>
            <Col lg={3} xs={12} md={12} className="col-no-gutters">
              {renderField(formikProps, formFields.purchaseOrderStatus, 12)}
            </Col>
          </Row>
          {isCostPlus ? (
            <Row>
              <Col lg={4} xs={12} md={12} className="col-no-gutters">
                {renderField(formikProps, formFields.isNonBillable, 12)}
              </Col>
            </Row>
          ) : null}

          <Row>
            {renderTableForm(
              formikProps,
              formFields.items as GenericFormTable<
                PurchaseOrderReceivePreparatoryPayload,
                any
              >,
              "items"
            )}
          </Row>
          <Row>{renderField(formikProps, formFields.internalNote, 12)}</Row>

          <Row>
            <Col lg={7} xs={12}>
              <div className="field-text field-text--underlined receipts-title">
                {t("orders.receipts")}
              </div>
              <Row className="row-m uploader-table">
                {files && files.length > 0 && (
                  <TableCard
                    data={filesTableData}
                    rowCount={true}
                    rowActions={tableRowActions}
                  />
                )}
                <div className="uploader">
                  <FileInput onUpload={handleFileUpload} />
                </div>
              </Row>
            </Col>

            <Col lg={5} xs={12}>
              <ModalTotals
                items={formikProps.values.items}
                margin={0}
                title={t("orders.amount")}
                hasGST={data?.hasGST}
              />
            </Col>
          </Row>
        </Container>
      )}
    </ModalForm>
  );
};

export default PurchaseOrderReceiveModal;
