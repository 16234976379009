import { AssemblyListItem } from "../../../models/assembly";
import {
  PriceList,
  PriceListCategory,
  PriceListItem,
} from "../../../models/price-list";

export enum SearchCostingItemType {
  ALL = "ALL",
  ASSEMBLY = "ASSEMBLY",
  PRICE_LIST = "PRICE_LIST",
}

export type GetPriceListResponse = {
  getPrice: PriceList;
};

export type ListPriceListResponse = {
  listPrice: PriceList[];
};

export type CreatePriceListResponse = {
  createUpdatePrice: PriceList;
};

export type DeletePriceListResponse = {
  deletePrice: PriceList;
};

export type CreatePriceListCategoryResponse = {
  createUpdatePriceCategory: PriceListCategory;
};

export type CreatePriceListCategoryItemsResponse = {
  updatePriceListCategoryItems: PriceListCategory;
};

export type ListPriceListCategoriesResponse = {
  listPriceCategory: PriceListCategory[];
};

export type DeletePriceListCategory = {
  deletePriceItemsByCategory: PriceListCategory;
};

export type SearchPriceListItemResponse = {
  searchPriceItem: PriceListItem[];
};

export type PreviewPriceListResponse = {
  getPricePreview: {
    _id: string;
    pdf: string;
  };
};

export type SearchCostingItem = {
  assemblyItems: AssemblyListItem[];
  priceItems: PriceListItem[];
};

export type SearchCostingItemResponse = {
  searchCostingItem: SearchCostingItem;
};
