import { PDFPageProxy } from "pdfjs-dist";
import { map } from "lodash";
export type PageCanvas = {
  page: PDFPageProxy;
  canvas: HTMLCanvasElement;
};
export const canvasesToImages = async (
  pageCanvasList: PageCanvas[],
  quality?: number,
  type?: string
): Promise<Blob[]> => {
  const images = [];
  try {
    for (const pageCanvas of pageCanvasList) {
      const image = await new Promise<Blob>((resolve, reject) => {
        pageCanvas.canvas.toBlob(
          (blob: Blob | null) => {
            if (blob) {
              return resolve(blob as Blob);
            }
            return reject(null);
          },
          type || "image/webp",
          quality
        );
      });
      images.push(image);
    }
    return images;
  } catch (e) {
    console.error({ e });
    return [];
  }
};

export const pagesToImages = async (
  pages: PDFPageProxy[],
  quality?: number,
  type?: string
): Promise<Blob[]> => {
  const scale = 4;

  try {
    const promises = map(pages, async (page) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (!context) {
        return "";
      }

      const pageViewport = page.getViewport({
        scale: scale,
      });

      canvas.height = pageViewport.height;
      canvas.width = pageViewport.width;

      const renderTask = page.render({
        canvasContext: context,
        viewport: pageViewport,
      });

      await renderTask.promise;
      return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob: Blob | null) => {
            if (blob) {
              return resolve(blob as Blob);
            }
            return reject(null);
          },
          "image/webp" || type,
          quality
        );
      });
    });
    return Promise.all(promises) as Promise<Blob[]>;
  } catch (e) {
    return [];
  }
};

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const printBase64Pdf = (pdf: string) => {
  const blob = b64toBlob(pdf, "application/pdf");
  const file = window.URL.createObjectURL(blob);
  const windowFeatures =
    "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
    "resizable,screenX=40,screenY=40,width=900,height=1120";
  window.open(file, "_blank", windowFeatures);
  setTimeout(() => {
    URL.revokeObjectURL(file);
  }, 1000 * 60 * 10); // clean up after 10min
};

export const printUrlPdf = async (url: string) => {
  const res = await window.fetch(url);
  const blob = await res.blob();
  const file = window.URL.createObjectURL(blob);
  const windowFeatures =
    "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
    "resizable,screenX=40,screenY=40,width=900,height=1120";
  window.open(file, "_blank", windowFeatures);
  setTimeout(() => {
    URL.revokeObjectURL(file);
  }, 1000 * 60 * 10);
};
