import React from "react";
import { useTranslation } from "react-i18next";
import { UpdateProfilePayload } from "../../../models/profile";
import UpdateEntityModal from "../../modals/update-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createUpdateProfileFields from "./utils";
import AvatarUploader from "../../uploaders/avatar-uploader";
import createEditProfileSchema from "./EditProfile.schema";
import "./styles.scss";

type UpdateProfileModalProps = {
  show: boolean;
  onSubmit: (data: UpdateProfilePayload) => void;
  onClose: () => void;
  user: UpdateProfilePayload;
  onUpload?: (files: File[]) => void;
  avatarFile: File | null;
  isClient?: boolean;
};
type UpdateProfileForm = Omit<
  UpdateProfilePayload,
  "signature" | "disabledNotifications"
> & {
  signature?: string;
  signatureRaw?: string;
  signatureText?: string;
  disabledPushNotifications?: boolean;
  disabledEmailNotifications?: boolean;
};
const UpdateProfileModal: React.FC<UpdateProfileModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    show,
    onSubmit,
    onClose,
    user,
    onUpload,
    avatarFile,
    isClient,
  } = props;

  const formFields = React.useMemo<GenericFormFields<UpdateProfileForm>>(() => {
    return createUpdateProfileFields(t, isClient);
  }, [isClient, t]);

  const initialValues = React.useMemo(() => {
    return {
      ...user,
      signature: user?.signature?.html,
      signatureRaw: user?.signature?.raw,
      disabledEmailNotifications: user?.disabledNotifications?.email,
      disabledPushNotifications: user?.disabledNotifications?.push,
    };
  }, [user]);

  const fileUploader = () => {
    return (
      <div className="avatar-detail">
        <AvatarUploader
          icon="insert_photo"
          outlined
          onUpload={onUpload}
          file={avatarFile}
          imageUrl={initialValues?.profileImage?.url}
        />
      </div>
    );
  };

  const handleSubmit = React.useCallback(
    (values: UpdateProfileForm) => {
      onSubmit({
        ...values,
        signature: {
          html: values.signature || "",
          text: values.signatureText || "",
          raw: JSON.stringify(values.signatureRaw) || "",
        },
      });
    },
    [onSubmit]
  );

  return (
    <UpdateEntityModal
      className="edit-details-modal"
      validationSchema={createEditProfileSchema(t)}
      title={t("profile.editDetailsTitle")}
      show={show}
      topRenderer={!isClient ? fileUploader() : undefined}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={onClose}
      fields={formFields}
    />
  );
};

export default UpdateProfileModal;
