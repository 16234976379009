import React from "react";
import { compact } from "lodash";
import { useMutation } from "@apollo/client";
import { t } from "i18next";
import { notify } from "../components/notification";
import {
  QuoteRequest,
  QuoteRequestResponse,
} from "../graphql/types/models/quote-request";
import { SendEmailForm } from "../models/email";
import { SEND_QUOTE_REQUEST } from "../graphql/queries/quote-request/mutations";
import { ModalDisplayRef } from "./useModalDisplay";
import SendModal from "../components/modals/send-modal";
import { SystemFolderType } from "../models/documents";

type QuoteRequestSendProps = {
  onSendComplete?: () => void;
  quoteId?: string;
  jobId?: string;
};

export function useQuoteRequestSend(props: QuoteRequestSendProps) {
  const { onSendComplete, quoteId, jobId } = props;
  const sendRef = React.useRef<ModalDisplayRef>();
  const [quoteRequest, setQuoteRequest] = React.useState<QuoteRequest>();

  const showSendQuoteRequest = React.useCallback(
    (quoteRequest: QuoteRequest) => {
      setQuoteRequest(quoteRequest);
      sendRef.current?.show(true);
    },
    [sendRef]
  );

  const [sendQuoteRequest] = useMutation<QuoteRequestResponse>(
    SEND_QUOTE_REQUEST,
    {
      onCompleted: () => {
        // quoteRequestRef?.current?.show(false);
        // resetQuoteItems();
        onSendComplete?.();
      },
      onError: (error) => {
        notify({
          error: true,
          title: t("quoteRequest.sendToSupplier"),
          content: error.message,
        });
      },
    }
  );

  const sendContacts = React.useMemo(() => {
    if (quoteRequest?.subcontractors?.length) {
      return compact(quoteRequest?.subcontractors?.map((sub) => sub.supplier));
    }
    return [];
  }, [quoteRequest]);

  const handleSendSubmit = React.useCallback(
    async (data: SendEmailForm) => {
      if (!quoteRequest) return;
      await Promise.all(
        quoteRequest?.subcontractors?.map((subcontractor) => {
          if (subcontractor.supplier?.email) {
            return sendQuoteRequest({
              variables: {
                quoteRequestId: quoteRequest._id,
                subcontractorId: subcontractor._id,
                message: {
                  message: data.message,
                  to: subcontractor.supplier.email,
                  subject: data.title,
                  remoteAttachmentId: data.remoteAttachmentId,
                },
              },
            });
          }
        })
      );
      sendRef.current?.show(false);
      notify({
        title: t("quoteRequest.sendToSupplier"),
        content: t("quoteRequest.success.sendQuoteRequest"),
      });
      return true;
    },
    [quoteRequest]
  );

  const renderSendQuoteRequest = React.useCallback(
    () => (
      <SendModal
        ref={sendRef}
        title={t("quoteRequest.sendToSupplier")}
        submitText={t("common.send")}
        onSubmit={handleSendSubmit}
        contacts={sendContacts}
        contact={sendContacts}
        disableContactSelect
        addDocumentsSelect={{
          entityId: jobId ?? quoteId,
          systemFolderType: jobId ? SystemFolderType.JOB : SystemFolderType.SQ,
        }}
      />
    ),
    [handleSendSubmit, sendContacts, jobId, quoteId]
  );

  return {
    showSendQuoteRequest,
    renderSendQuoteRequest,
  };
}
