import React, { useEffect, useRef, useState } from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { get, uniqBy } from "lodash";
import { JobCostingCategoryBasic, JobCostingItem } from "../models/job";
import SelectCostingModal, {
  SelectCostingModalRef,
} from "../components/job-costing/select-costing-modal";
import { LIST_JOB_COSTINGS_BASIC } from "../graphql/queries/job-costing/queries";
import { ListJobCostingsBasicResponse } from "../graphql/types/models/job-costing";
import { AppendDynamicProps } from "../components/generic-form/GenericFormBody";
import { GenericRecord } from "../utils/types/options";
import { SelectOption } from "../components/generic-form/inputs/creatable-select";

export enum CostingAllocationType {
  Timesheet = "TIMESHEET",
  PurchaseOrder = "PURCHASE_ORDER",
}
type AllocateProps = {
  type: CostingAllocationType;
  jobId?: string | null;
  isTable?: boolean;
};

export function useJobCostingAllocate(props: AllocateProps) {
  const { jobId, isTable, type } = props;
  const selectCostingRef = useRef<SelectCostingModalRef>(null);
  const [categoryList, setCategoryList] = useState<GenericRecord[]>([]);
  const [jobCostings, setjobCostings] = useState<JobCostingCategoryBasic[]>([]);

  const client = useApolloClient();
  const [
    getCostings,
    { data: jobCostingData, loading: jobCostingLoading },
  ] = useLazyQuery<ListJobCostingsBasicResponse>(LIST_JOB_COSTINGS_BASIC);

  useEffect(() => {
    if (jobId) {
      getCostings({
        variables: {
          jobId,
          filter: { type },
        },
      });
    }
  }, [jobId, type]);

  const getFieldName = React.useCallback(
    (name: string, rowIndex?: number) => {
      if (isTable) {
        return `items[${rowIndex}].${name}`;
      }
      return name;
    },
    [isTable]
  );

  useEffect(() => {
    if (!jobCostingLoading && jobCostingData?.listJobCostingsBasic) {
      const categories: SelectOption[] = [];

      jobCostingData?.listJobCostingsBasic.forEach((cat) => {
        categories.push({
          label: cat.name,
          value: cat.name,
          data: jobId,
        });
      });

      setCategoryList(categories);
    }
  }, [jobCostingData, setCategoryList, jobCostingLoading]);

  const handeSelectCosting = React.useCallback(
    (rowIndex: number, formikProps) => (item?: JobCostingItem) => {
      const { setFieldValue } = formikProps;
      setFieldValue(getFieldName("costingItemId", rowIndex), item?._id || "");
    },
    []
  );

  const handleSetCostingOutside = React.useCallback(
    (items: JobCostingCategoryBasic[]) => {
      setjobCostings(items);
      setCategoryList(
        items.map((item) => ({
          label: item.name,
          value: item.name,
          data: item.jobId,
        }))
      );
    },
    []
  );

  const handleSelectCategory = React.useCallback(
    async (fieldValue, rowIndex, formikProps) => {
      if (!jobCostingData) return;

      const category = !jobId
        ? jobCostings.find((c) => c.name === fieldValue)
        : jobCostingData?.listJobCostingsBasic?.find(
            (c) => c.name === fieldValue
          );
      const { setFieldValue } = formikProps;
      if (!category) {
        setFieldValue(getFieldName("costingItemId", rowIndex), "");
      } else {
        // ensure costing item exists
        const costingId = get(
          formikProps.values,
          `items.${rowIndex}.costingItemId`,
          ""
        );
        const item =
          costingId && category.items.find((item) => item._id === costingId);
        if (!item) {
          setFieldValue(getFieldName("costingItemId", rowIndex), "");
        }
      }
    },
    [jobId, getFieldName, jobCostingData, jobCostings]
  );

  const handleCreateCategory = React.useCallback(
    (name: string) => {
      if (categoryList) {
        const newCosting = uniqBy(
          categoryList.concat([{ label: name, value: name }]),
          "value"
        );
        setCategoryList(newCosting);
      } else {
        setCategoryList([{ label: name, value: name }]);
      }
    },
    [categoryList, setCategoryList]
  );

  const openAllocateCosting = React.useCallback(
    (fieldName, fieldValue, rowIndex, formikProps) => {
      if (fieldValue) {
        const category = !jobId
          ? jobCostings.find((c) => c.name === fieldValue)
          : jobCostingData?.listJobCostingsBasic?.find(
              (c) => c.name === fieldValue
            );
        const costingId = get(
          formikProps.values,
          getFieldName("costingItemId", rowIndex),
          ""
        );
        if (category) {
          selectCostingRef.current?.setParams(
            category,
            costingId,
            handeSelectCosting(rowIndex, formikProps)
          );
          selectCostingRef.current?.show(true);
        }
      }
    },
    [jobId, jobCostings, jobCostingData, getFieldName, handeSelectCosting]
  );

  const getAllocateProps = React.useCallback(
    (formikProps, __, rowIndex): AppendDynamicProps => {
      const categoryName = get(
        formikProps.values,
        getFieldName("costingCategoryName", rowIndex)
      );
      const hasCategory =
        categoryName &&
        !!(Boolean(!jobId)
          ? jobCostings.find((c) => c.name === categoryName)
          : jobCostingData?.listJobCostingsBasic?.find(
              (c) => c.name === categoryName
            ));
      if (!hasCategory) {
        return {
          isHidden: true,
        };
      }
      const hasCosting = !!get(
        formikProps.values,
        getFieldName("costingItemId", rowIndex)
      );
      return {
        icon: hasCosting ? "insert_link" : "link_off",
        className: hasCosting ? "success" : "error",
      };
    },
    [jobCostingData, getFieldName, jobId, jobCostings]
  );

  const renderSelectCostingModal = React.useCallback(
    () => <SelectCostingModal ref={selectCostingRef} />,
    [selectCostingRef]
  );

  return {
    categoryList,
    jobCostingLoading,
    openAllocateCosting,
    getAllocateProps,
    handleCreateCategory,
    handeSelectCosting,
    handleSelectCategory,
    renderSelectCostingModal,
    client,
    handleSetCostingOutside,
  };
}
