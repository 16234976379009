import React from "react";
import { useTranslation } from "react-i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import UpdateEntityModal from "../../modals/update-entity";
import { createDocumentField } from "./utils";
import {
  CreateFolderPayload,
  EnumDocumentAccessRole,
  PrimaryFolder,
} from "../../../models/documents";
import "./styles.scss";
import createFolderSchema from "./Document.schema";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { useLazyQuery } from "@apollo/client";
import { User } from "../../../graphql/types/models/user";

export enum ModalAction {
  EDIT_FOLDER = "edit-folder",
  CREATE_FOLDER = "create-folder",
  EDIT_FILE = "edit-file",
}

type CreateDocumentModalProps = {
  show: boolean;
  modalAction?: ModalAction | null;
  onSubmit: (data: CreateFolderPayload) => void;
  onClose: () => void;
  editDocument: {
    _id: string;
    name: string;
    accessRole?: EnumDocumentAccessRole;
    accessSelectedUsers?: User[];
  } | null;
  setAccessPermissions?: boolean;
  parentFolderPermissions?: PrimaryFolder;
};

const CreateUpdateDocumentModal: React.FC<CreateDocumentModalProps> = (
  props
) => {
  const {
    show,
    onSubmit,
    onClose,
    modalAction,
    editDocument,
    setAccessPermissions,
    parentFolderPermissions,
  } = props;

  const { t } = useTranslation();
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateFolderPayload>
  >({});
  const [isActiveRestrictAccess, setIsActiveRestrictAccess] = React.useState(
    false
  );
  const parentFolderAccessRole = parentFolderPermissions?.accessRole;
  const parentFoldeAccessUsers = parentFolderPermissions?.accessSelectedUsers?.map(
    (user) => user._id
  );

  const [getTeamData, { data: teamData }] = useLazyQuery<ListMembersResponse>(
    LIST_MEMBERS
  );
  const teamMembers = React.useMemo(() => teamData?.listMembers || [], [
    teamData,
  ]);

  const handleRestrictAccessChange = React.useCallback(
    (value: string) => {
      if (value === EnumDocumentAccessRole.SELECTED) {
        getTeamData();
        setIsActiveRestrictAccess(true);
      } else {
        if (isActiveRestrictAccess) {
          setIsActiveRestrictAccess(false);
        }
      }
    },
    [getTeamData, isActiveRestrictAccess]
  );

  const handleSubmit = React.useCallback(
    (data: CreateFolderPayload) => {
      if (!isActiveRestrictAccess) {
        return onSubmit({ ...data, accessSelectedUsers: [] });
      }
      onSubmit({
        ...data,
        accessSelectedUsers: data?.accessSelectedUsers || [],
      });
    },
    [isActiveRestrictAccess, onSubmit]
  );

  React.useEffect(() => {
    setFormFields(
      createDocumentField(
        t,
        handleRestrictAccessChange,
        setAccessPermissions,
        isActiveRestrictAccess,
        teamMembers
      )
    );
  }, [
    t,
    setAccessPermissions,
    handleRestrictAccessChange,
    isActiveRestrictAccess,
    teamMembers,
  ]);

  const getTitle = () => {
    switch (modalAction) {
      case ModalAction.CREATE_FOLDER:
        return t("documents.newFolder");
      case ModalAction.EDIT_FILE:
        return t("documents.editFile");
      case ModalAction.EDIT_FOLDER:
        return t("documents.editFolder");
      default:
        return t("documents.newFolder");
    }
  };
  return (
    <>
      {!editDocument ? (
        <CreateEntityModal
          validationSchema={createFolderSchema(t)}
          className="create-folder-modal"
          title={getTitle()}
          show={show}
          data={{
            name: "",
            accessRole: parentFolderAccessRole,
            accessSelectedUsers: parentFoldeAccessUsers || [],
          }}
          onSubmit={handleSubmit}
          onClose={onClose}
          fields={formFields}
        />
      ) : (
        <UpdateEntityModal
          validationSchema={createFolderSchema(t)}
          className="create-folder-modal"
          title={getTitle()}
          show={show}
          data={{
            name: editDocument.name || "",
            accessRole: editDocument.accessRole,
            accessSelectedUsers:
              editDocument?.accessSelectedUsers?.map((user) => user._id) || [],
          }}
          onSubmit={handleSubmit}
          onClose={onClose}
          fields={formFields}
        />
      )}
    </>
  );
};

export default CreateUpdateDocumentModal;
