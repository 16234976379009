import React from "react";
import AuthenticationCard from "../authentication-card";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import createResetPasswordSchema from "./ResetPassword.schema";
import ResetPasswordFormBody from "./ResetPasswordFormBody";
import ForgetPasswordFormFooter from "../forget-password-form/ForgetPasswordFormFooter";
import "./styles.scss";

type ResetPasswordFormProps = {
  onSubmit: (password: string) => void;
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: createResetPasswordSchema(t),
    onSubmit: (values) => {
      return onSubmit(values.password);
    },
  });

  return (
    <AuthenticationCard
      title={t("authentication.resetPassword")}
      container={Form}
      containerProps={{
        onSubmit: handleSubmit,
        noValidate: true,
        className: "authentication-card-container reset-password-card",
      }}
      body={ResetPasswordFormBody}
      bodyProps={{
        t,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }}
      footer={ForgetPasswordFormFooter}
      footerProps={{ t, isSubmitting }}
    />
  );
};

export default ResetPasswordForm;
