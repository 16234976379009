import { gql } from "@apollo/client";

export const TODOLIST_FRAGMENT = gql`
  fragment TodoListFragment on TodoList {
    _id
    task
    description
    completed
    dueDate
    progress
    jobScheduleItem
    is_archived
    members {
      ... on User {
        _id
        first_name
        last_name
        profileImage {
          _id
          url
        }
      }
    }
    scheduleItem {
      _id
      name
      job {
        _id
      }
    }
    lead {
      _id
      name
    }
    user {
      _id
      first_name
      last_name
    }
    job {
      _id
      name
    }
  }
`;

export const TODO_FRAGMENT = gql`
  fragment TodoFragment on TodoList {
    _id
    task
    description
    completed
    dueDate
    progress
    job {
      _id
      name
    }
    tags {
      _id
      name
    }
    members {
      ... on User {
        _id
        first_name
        last_name
      }
    }
    checklistCategories {
      _id
      name
      checklist {
        _id
        task
        dueDate
        assignedTo {
          ... on User {
            _id
            first_name
            last_name
          }
        }
        completed
        completedBy {
          ... on User {
            _id
            first_name
            last_name
            fullName
          }
        }
      }
    }
  }
`;

export const CHECKLIST_TEMPLATE_FRAGMENT = gql`
  fragment ChecklistTemplateFragment on TodoChecklistTemplate {
    _id
    name
    is_deleted
    categories {
      _id
      name
      is_deleted
      order
      templateId
      items {
        _id
        task
        order
        categoryId
        is_deleted
      }
    }
  }
`;
