import React, { useCallback, useMemo } from "react";
import { map } from "lodash";
import classNames from "classnames";
import { FormikProps } from "formik";
import Icon from "../../icons/Icon";
import { SalesQuoteFile } from "../../../models/salesQuote";

import "./styles.scss";

type PlanSelectionProps = {
  plans?: SalesQuoteFile[];
  formikProps: FormikProps<any>;
};

type PlanSelectionImageProps = {
  plan: SalesQuoteFile;
  isSelected?: boolean;
  onClick: () => void;
};

const PlanSelectionImage: React.FC<PlanSelectionImageProps> = (props) => {
  const { plan, isSelected, onClick } = props;

  const fileUrl = React.useMemo(() => {
    return plan.url || "";
  }, [plan]);

  return (
    <div
      className={classNames("plan", { "is-selected": isSelected })}
      onClick={onClick}
    >
      <img src={fileUrl} className="plan-preview-img" />
      {isSelected && (
        <div className="selection-mark">
          <Icon name="done" />
        </div>
      )}
    </div>
  );
};

const PlanSelection: React.FC<PlanSelectionProps> = (props) => {
  const { plans, formikProps } = props;

  const onClick = useCallback(
    (index, plan) => {
      formikProps.setFieldValue(
        `plans[${index}]`,
        !formikProps.values.plans[index] ? plan._id : undefined
      );
    },
    [formikProps]
  );

  return (
    <div className="plan-container">
      {map(plans, (plan, index) => (
        <PlanSelectionImage
          plan={plan}
          onClick={() => onClick(index, plan)}
          isSelected={formikProps.values.plans.indexOf(plan._id) >= 0}
        />
      ))}
    </div>
  );
};

export default PlanSelection;
