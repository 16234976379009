import React from "react";
import { useTranslation } from "react-i18next";
import { compact } from "lodash";
import moment from "moment";
import { SalesQuote } from "../../../../models/salesQuote";
import { DetailsCardDataItem } from "../../../../components/dashboard/details-card/DetailsCardList";
import DetailsCard, {
  DetailsCardAction,
} from "../../../../components/dashboard/details-card";
import CardPlaceholder from "../../../../components/dashboard/card-placeholder";
import { getFullAddress } from "../../../../utils/text";

type QuoteDetailsProps = {
  quote: SalesQuote;
  disabled: boolean;
  onEdit: () => void;
  buttonDisabled: boolean;
  isReadonly: boolean;
};

const QuoteDetailsCard: React.FC<QuoteDetailsProps> = (props) => {
  const { quote, disabled, onEdit, buttonDisabled, isReadonly } = props;

  const { t } = useTranslation();

  const cardData: DetailsCardDataItem[] = React.useMemo(() => {
    const address = getFullAddress(quote);
    const items = [
      {
        icon: "badge",
        text: quote.name,
      },
      {
        icon: "calendar_today",
        text: `${moment(quote.createdAt).format("D MMM YYYY")}`,
        title: t("common.createdAt"),
      },
      {
        icon: "attach_money",
        text: t("common.currency", { amount: quote.subTotal }),
        title: t("quotes.estimationTotal"),
      },
      address
        ? {
            icon: "place",
            text: address,
            title: t("quotes.siteAddress"),
          }
        : null,
      !isReadonly
        ? {
            icon: "receipt",
            text: quote.status,
            title: t("common.status"),
          }
        : null,
    ];

    return compact(items);
  }, [quote, isReadonly]);

  const cardActions: DetailsCardAction[] = React.useMemo(
    () =>
      isReadonly
        ? []
        : [
            {
              disabled: buttonDisabled,
              title: t("common.edit"),
              onClick: onEdit,
              className: "button info",
            },
          ],
    [t, onEdit, isReadonly]
  );

  if (disabled) {
    return <CardPlaceholder />;
  }
  return (
    <DetailsCard
      fullHeight
      title={t("common.details")}
      data={cardData}
      actions={cardActions}
    />
  );
};

export default QuoteDetailsCard;
