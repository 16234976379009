import { useLazyQuery, useMutation } from "@apollo/client";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { notify } from "../components/notification";
import {
  COPY_DOCUMENT,
  COPY_FOLDER_CONTENT,
  CREATE_UPDATE_FOLDER,
} from "../graphql/queries/documents/mutations";
import { GET_FOLDER, LIST_FOLDERS } from "../graphql/queries/documents/queries";
import {
  CopyDocumentResponse,
  CopyFolderResponse,
  CreateUpdateFolderResponse,
  GetFolderResponse,
  ListFoldersResponse,
} from "../graphql/types/models/documents";
import { CreateFolderPayload, CurrentDirectory } from "../models/documents";
import SelectFolderModal from "../components/documents-main/select-folder-modal";
import {
  FolderType,
  handleFolderAdd,
} from "../graphql/queries/documents/utils";
import CreateUpdateDocumentModal, {
  ModalAction,
} from "../components/documents-main/create-update-modal";

type UseDocumentsCopyType = {
  startFolderId?: string;
};

export enum CopyAction {
  COPY_FOLDER = "copy-folder",
  COPY_FILE = "copy-file",
}

export function useDocumentsCopy(props: UseDocumentsCopyType) {
  const { startFolderId } = props;
  const [currentDirectory, setCurrentDirectory] = React.useState<
    CurrentDirectory
  >({
    subFolders: [],
    documents: [],
  });
  const [currentDirectoryId, setCurrentDirectoryId] = React.useState(
    startFolderId || ""
  );
  const [showSelectFolderModal, setShowSelectFolderModal] = React.useState(
    false
  );
  const [showCreateFolderModal, setShowCreateFolderModal] = React.useState(
    false
  );
  const [currentCopyData, setCurrentCopyData] = React.useState<{
    action: CopyAction | null;
    dataId: string;
    currentFolderId: string | null;
  }>({
    action: null,
    dataId: "",
    currentFolderId: null,
  });

  const { t } = useTranslation();

  const [getRootFolders, { refetch: refetchRoot }] = useLazyQuery<
    ListFoldersResponse
  >(LIST_FOLDERS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setCurrentDirectory({
        subFolders: data.listFolders || [],
        documents: [],
      });
    },
  });

  const [getFolder] = useLazyQuery<GetFolderResponse>(GET_FOLDER, {
    onCompleted: (data) => {
      setCurrentDirectory(data.getFolder);
    },
    onError: (error) => {
      const code = get(error, "graphQLErrors.0.extensions.code");
      if (code === "FOLDER_DOES_NOT_EXIST") {
        refetchRoot && refetchRoot();
      }
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (currentDirectoryId) {
      getFolder({
        variables: {
          folderId: currentDirectoryId,
        },
      });
    } else {
      getRootFolders();
    }
  }, [currentDirectoryId]);

  React.useEffect(() => {
    if (startFolderId) {
      setCurrentDirectoryId(startFolderId);
    } else setCurrentDirectoryId("");
  }, [startFolderId, showSelectFolderModal]);

  const selectModalFolder = React.useCallback((id: string) => {
    setCurrentDirectoryId(id);
  }, []);

  const selectModalRootFolders = React.useCallback(() => {
    setCurrentDirectoryId("");
  }, []);

  const [copyFolderMutation] = useMutation<CopyFolderResponse>(
    COPY_FOLDER_CONTENT,
    {
      onCompleted: (data) => {
        notify({
          title: t("documents.copyContents"),
          content: t("documents.success.copyFolder"),
        });
        handleOnClose();
      },
      onError: () => {
        notify({
          error: true,
          title: t("documents.copyContents"),
          content: t("documents.error.copyFolder"),
        });
      },
    }
  );

  const [copyDocumentMutation] = useMutation<CopyDocumentResponse>(
    COPY_DOCUMENT,
    {
      onCompleted: (data) => {
        notify({
          title: t("documents.copyDocument"),
          content: t("documents.success.copyDocument"),
        });
        handleOnClose();
      },
      onError: () => {
        notify({
          error: true,
          title: t("documents.copyDocument"),
          content: t("documents.error.copyDocument"),
        });
      },
    }
  );

  const handleOnClose = React.useCallback(() => {
    setCurrentCopyData({
      action: null,
      dataId: "",
      currentFolderId: null,
    });
    setCurrentDirectoryId("");
    setShowSelectFolderModal(false);
  }, []);

  const handleCopyAction = React.useCallback(
    (id: string, copyAction: CopyAction) => {
      setCurrentCopyData({
        action: copyAction,
        dataId: id,
        currentFolderId: currentDirectory._id || null,
      });
      setShowSelectFolderModal(true);
    },
    [currentDirectory]
  );

  const handleCopy = React.useCallback(
    (id: string) => {
      if (currentCopyData.action === CopyAction.COPY_FOLDER) {
        copyFolderMutation({
          variables: {
            sourceFolderId: currentCopyData.dataId,
            destFolderId: id,
          },
        });
      }

      if (currentCopyData.action === CopyAction.COPY_FILE) {
        copyDocumentMutation({
          variables: {
            documentId: currentCopyData.dataId,
            destFolderId: id,
          },
        });
      }
    },
    [currentCopyData]
  );

  const [addUpdateFolder] = useMutation<CreateUpdateFolderResponse>(
    CREATE_UPDATE_FOLDER
  );

  const closeCreateModal = React.useCallback(() => {
    setShowCreateFolderModal(false);
  }, []);

  const handleAddFolder = React.useCallback(
    async (data: CreateFolderPayload) => {
      try {
        await addUpdateFolder({
          variables: {
            folder: currentDirectoryId
              ? {
                  name: data.name,
                  parentId: currentDirectoryId,
                }
              : { name: data.name },
          },
          update: currentDirectoryId
            ? handleFolderAdd(FolderType.FOLDER, {
                folderId: currentDirectoryId,
              })
            : handleFolderAdd(FolderType.ROOT),
        });

        notify({
          title: t("documents.addFolder"),
          content: t("documents.success.addFolder"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("documents.addFolder"),
          content: t("documents.error.addFolder"),
        });
      }

      closeCreateModal();
    },
    [closeCreateModal, addUpdateFolder, currentDirectoryId, t]
  );

  const renderSelectFolderModal = () => {
    return (
      <>
        <CreateUpdateDocumentModal
          modalAction={ModalAction.CREATE_FOLDER}
          show={showCreateFolderModal}
          onSubmit={handleAddFolder}
          onClose={closeCreateModal}
          editDocument={null}
        />
        <SelectFolderModal
          folderId={currentDirectoryId}
          show={showSelectFolderModal}
          onSubmit={handleCopy}
          onClose={handleOnClose}
          folders={currentDirectory.subFolders}
          isRootFolder={!currentDirectory.parentTree}
          selectFolder={selectModalFolder}
          parentTree={currentDirectory.parentTree || null}
          selectRootFolders={selectModalRootFolders}
          onAddNewFolderClick={() => setShowCreateFolderModal(true)}
        />
      </>
    );
  };

  return {
    renderSelectFolderModal,
    handleCopyAction,
  };
}
