import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { map } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import { useSpecificationsTemplate } from "../../../hooks/useSpecificationsTemplate";
import { SpecTemplate } from "../../../models/specification";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import SaveSpecificationsAsTemplateModal from "../../../components/specification/save-as-template";

const SpecTemplatesOverview: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    specTemplates,
    templatesDataLoading,
    deleteSpecTemplate,
  } = useSpecificationsTemplate();

  const handleTemplateClick = React.useCallback(
    (row: TableCardDataRow<SpecTemplate>) => {
      history.push(`/settings/specification-templates/${row.cells._id}`);
    },
    [history]
  );

  const deleteConfirmRef = React.useRef<ConfirmDialogRef>();

  const handleDelete = React.useCallback(
    (templateId: string) => () => {
      if (!templateId) {
        return;
      }
      deleteSpecTemplate({
        variables: {
          templateId,
        },
      });

      deleteConfirmRef.current?.show(false);
    },
    [deleteSpecTemplate]
  );

  const openRemoveDialog = React.useCallback(
    (row?: SpecTemplate) => {
      if (row) {
        deleteConfirmRef.current?.show(true, handleDelete(row._id));
      }
    },
    [handleDelete]
  );

  const saveSpecAsTemplateRef = React.useRef<ModalDisplayRef>();
  const tableActions = React.useMemo<TableCardAction[]>(
    () => [
      {
        title: t("specifications.addTemplate"),
        onClick: () => saveSpecAsTemplateRef.current?.show(true),
        icon: "add",
        className: "button large large-wide success",
      },
    ],
    [t]
  );

  const tableRowActions: TableRowActionData<SpecTemplate>[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "document-list",
        options: [
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openRemoveDialog,
          },
        ],
      },
    ],
    [openRemoveDialog, t]
  );

  const specTemplatesData: TableCardData<SpecTemplate> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
        },
        {
          valueKey: "updatedAt",
          title: t("common.lastUpdated"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
      ],
      rows: map(specTemplates, (template: SpecTemplate) => ({
        cells: template,
      })),
    };
  }, [t, specTemplates]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet
        title={t("navigation.settings.templatesSection.specificationTemplates")}
      />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SPECIFICATION_TEMPLATES
        }
      />
      <ConfirmDialog
        ref={deleteConfirmRef}
        title={t("specifications.deleteTemplate")}
        confirm={t("common.delete")}
      >
        <div className="field-text">
          {t("specifications.deleteTemplateMessage")}
        </div>
      </ConfirmDialog>
      <SaveSpecificationsAsTemplateModal ref={saveSpecAsTemplateRef} />
      <TableCard
        isDataLoading={templatesDataLoading}
        overflowXHidden
        fullHeight
        data={specTemplatesData}
        onRowClick={handleTemplateClick}
        rowActions={tableRowActions}
        actions={tableActions}
        alignEnd
      />
    </Container>
  );
};

export default SpecTemplatesOverview;
