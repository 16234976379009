import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { compact } from "lodash";
import LeftModal from "../../left-modal";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import {
  JobCostingCategory,
  JobCostingCategoryBasic,
  JobCostingItem,
} from "../../../models/job";
import CardTable from "../../dashboard/table-card/CardTable";
import {
  TableCardData,
  TableRowActionData,
} from "../../dashboard/table-card/utils";
import LeftModalFooter from "../../left-modal/LeftModalFooter";

type SelectCallback = (item?: JobCostingItem) => void;

export type SelectCostingModalRef = ModalDisplayRef & {
  setParams: (
    category: JobCostingCategory | JobCostingCategoryBasic,
    costingId: string,
    callback: SelectCallback
  ) => void;
};

const SelectCostingModal: React.FC = ({}, ref) => {
  const [category, setCategory] = React.useState<JobCostingCategory>();
  const [costingId, setCostingId] = React.useState<string>("");
  const [selectCallback, setSelectCallback] = React.useState<
    SelectCallback[]
  >();
  const { t } = useTranslation();
  const { shouldShow, hide } = useModalDisplay(ref, {
    setParams: (
      category: JobCostingCategory,
      costingId: string,
      callback: SelectCallback
    ) => {
      setCategory(category);
      setCostingId(costingId);
      setSelectCallback([callback]);
    },
  });

  const handleSelect = React.useCallback(
    (item: JobCostingItem) => {
      selectCallback?.[0]?.(item);
      hide();
    },
    [selectCallback, hide]
  );

  const handleRemove = React.useCallback(() => {
    selectCallback?.[0]?.();
    hide();
  }, [selectCallback, hide]);

  const costingData = React.useMemo<TableCardData<JobCostingItem>>(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("costing.description"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "UOM",
          title: t("costing.uom"),
          formatValue: (row: any, column: any, value: string) => value,
        },
      ],
      rows:
        category?.items?.map((costing) => ({
          cells: costing,
        })) || [],
    };
  }, [t, category]);

  const tableRowActions: TableRowActionData<JobCostingItem>[] = React.useMemo(
    () => [
      {
        icon: "radio_button_unchecked",
        id: "unchecked",
        onClick: handleSelect,
        shouldRender: (row) => row._id !== costingId,
      },
      {
        icon: "radio_button_checked",
        id: "checked",
        onClick: handleSelect,
        shouldRender: (row) => row._id === costingId,
      },
    ],
    [handleSelect, costingId]
  );

  return (
    <LeftModal
      show={shouldShow}
      onHide={hide}
      className="allocate-costing-modal"
    >
      <LeftModalHeader title={t("costing.allocateCosting")} onClose={hide} />
      <LeftModalBody>
        <CardTable
          rowCount
          table={costingData}
          rowActions={tableRowActions}
          emptyTableText={t("costing.noCostingItems")}
        />
      </LeftModalBody>
      <LeftModalFooter className="justify-content-between">
        <Button
          variant="secondary"
          className="button large large-wide info"
          onClick={handleRemove}
        >
          {t("costing.removeAllocation")}
        </Button>
        <Button
          size="lg"
          variant="success"
          onClick={hide}
          className="button large success"
        >
          {t("common.close")}
        </Button>
      </LeftModalFooter>
    </LeftModal>
  );
};

export default React.forwardRef(SelectCostingModal);
