import React from "react";
import { TFunction } from "i18next";
import { DashboardNavigationContext } from "../../../../containers/layouts/dashboard/DashboardContext";
import { SidebarNavItem } from "../SidebarNavListItem";

import Icon from "../../../icons/Icon";
import SVGIcon from "../../../icons/SVGIcon";

export const NAVIGATION_ROUTES = {
  DASHBOARD: "DASHBOARD",
  JOBS_SECTION: {
    JOBS: "JOBS",
    DISCUSSION: "DISCUSSION",
    PROGRESS_CLAIMS: "PROGRESS_CLAIMS",
    QUOTES: "QUOTE_REQUESTS",
    VARIATIONS: "VARIATIONS",
    SITE_DIARIES: "SITE_DIARIES",
  },
  QUOTES_SECTION: {
    QUOTES: "QUOTES",
    ESTIMATION: "ESTIMATIONS",
    SELECTIONS: "SELECTIONS",
  },
  ACCOUNT: {
    ACCOUNT: "ACCOUNT",
    MY_PROFILE: "MY_PROFILE",
  },
  LOGOUT: {
    LOGOUT: "LOGOUT",
  },
};

export const createClientNavItems = (
  t: TFunction,
  context: DashboardNavigationContext | null
): SidebarNavItem[] => {
  return [
    {
      id: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
      icon: () => <SVGIcon name="Hammer" />,
      title: t("client.jobs.projects"),
      url: "/projects",
      expandable: true,
      defaultItemId: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
      items: [
        {
          id: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
          icon: () => <SVGIcon name="Hammer" />,
          title: t("client.jobs.projects"),
          url: "/projects",
          hideInSidebarMenu: true,
        },
        {
          id: NAVIGATION_ROUTES.JOBS_SECTION.DISCUSSION,
          icon: () => <Icon name="chat_bubble_outline" outlined />,
          title: t("client.jobs.discussion"),
          url: `/projects/${context?.clientJob?._id}/discussion`,
          hideInSidebarMenu: !context?.clientJob,
        },
        {
          id: NAVIGATION_ROUTES.JOBS_SECTION.PROGRESS_CLAIMS,
          icon: () => <SVGIcon name="Progress Claim" />,
          title: t("client.jobs.progressClaims"),
          url: `/projects/${context?.clientJob?._id}/progress-claims`,
          hideInSidebarMenu: !context?.clientJob,
        },
        {
          id: NAVIGATION_ROUTES.JOBS_SECTION.VARIATIONS,
          icon: () => <SVGIcon name="Change" />,
          title: t("navigation.jobsSection.variations"),
          url: `/projects/${context?.clientJob?._id}/variations`,
          hideInSidebarMenu: !context?.clientJob,
        },
        {
          id: NAVIGATION_ROUTES.JOBS_SECTION.QUOTES,
          icon: () => <SVGIcon name="Pricing" />,
          title: t("client.jobs.quoteRequests"),
          url: `/projects/${context?.clientJob?._id}/quote-requests`,
          hideInSidebarMenu: !context?.clientJob,
        },
        {
          id: NAVIGATION_ROUTES.JOBS_SECTION.SITE_DIARIES,
          icon: () => <Icon name="book" outlined />,
          title: t("client.jobs.siteDiaries"),
          url: `/projects/${context?.clientJob?._id}/site-diary`,
          hideInSidebarMenu: !context?.clientJob,
        },
      ],
    },
    {
      id: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
      icon: () => <SVGIcon name="Bill" />,
      title: t("client.quotes.estimations"),
      url: "/quotes",
      expandable: true,
      defaultItemId: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
      items: [
        {
          id: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
          icon: () => <SVGIcon name="Grid2" />,
          title: t("client.quotes.estimations"),
          url: "/quotes",
          hideInSidebarMenu: true,
        },
        {
          id: NAVIGATION_ROUTES.QUOTES_SECTION.SELECTIONS,
          icon: () => <Icon name="grading" outlined />,
          title: t("client.quotes.selections"),
          url: `/quotes/${context?.clientQuote?._id}/selections`,
          hideInSidebarMenu: !context?.clientQuote,
        },
      ],
    },
    {
      id: NAVIGATION_ROUTES.ACCOUNT.ACCOUNT,
      icon: () => <SVGIcon name="User" />,
      title: t("navigation.account.profile"),
      url: "/account",
      defaultItemId: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
      expandable: true,
      items: [
        {
          id: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
          icon: () => <SVGIcon name="User" />,
          title: t("navigation.account.profile"),
          url: "/account/profile",
          hideInSidebarMenu: true,
        },
      ],
    },
    {
      id: NAVIGATION_ROUTES.LOGOUT.LOGOUT,
      icon: () => <Icon name="logout" outlined />,
      title: t("navigation.account.logout"),
      isLogout: true,
    },
  ];
};
