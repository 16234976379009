import React from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { CardReorder, reorderCards, reorderColumn } from "./utils";
import Lists from "./lists";
import "./style.scss";

export type Column<TItem> = {
  _id: string;
  name: string;
  order: number;
  cards: TItem[];
};

export type BaseCard = {
  _id: string;
  budget?: number;
};

enum DroppableType {
  COLUMN = "Column",
}

type KanbanBoardProps<TItem> = {
  columns: Column<TItem>[];
  renderCard: (item: TItem, index: number) => JSX.Element;
  renderFilters?: () => JSX.Element;
  onColumnsChange: (columns: Column<TItem>[]) => void;
  addColumn: (column: { name: string; order: number; _id?: string }) => void;
  deleteColumn: (columnId: string) => void;
  reOrderColumn: (orderedList: string[]) => void;
  reOrderCardsInsideColumn: (columnId: string, orderedList: string[]) => void;
  reOrderCardsBetweenColumn: (
    columnId: string,
    currentItem: TItem
  ) => Promise<void>;
  addCard: () => void;
  addButtonPlaceholder?: string;
  renderLeadsTable?: () => JSX.Element;
  gridView?: boolean;
  toggleGridView?: () => void;
  children?: React.ReactNode;
};

const KanbanBoard = <TItem extends BaseCard>(
  props: KanbanBoardProps<TItem>
) => {
  const {
    columns,
    onColumnsChange,
    addColumn,
    deleteColumn,
    reOrderColumn,
    reOrderCardsInsideColumn,
    reOrderCardsBetweenColumn,
    renderCard,
    addCard,
    renderFilters,
    addButtonPlaceholder,
    renderLeadsTable,
    gridView,
    toggleGridView,
  } = props;

  const onDragEnd = async (result: DropResult): Promise<void> => {
    const { destination, type } = result;
    if (!destination) {
      return;
    }
    if (type === DroppableType.COLUMN) {
      const reorderedColumns = reorderColumn(columns, result);
      if (reorderedColumns) {
        onColumnsChange(reorderedColumns);
        const orderedList = reorderedColumns.map((column) => column._id);

        reOrderColumn(orderedList);
      }
    }

    const reorderedItems = reorderCards(columns, result);
    if (reorderedItems) {
      const { newColumnsList, currentItem, reorderType } = reorderedItems;
      onColumnsChange(newColumnsList);

      if (reorderType === CardReorder.INSIDE) {
        const currentColumn = newColumnsList.find(
          (column) => column._id === result.destination?.droppableId
        );
        const orderedList = currentColumn?.cards.map((card) => card._id);
        if (currentColumn && orderedList) {
          reOrderCardsInsideColumn(currentColumn._id, orderedList);
        }

        return;
      }

      if (reorderType === CardReorder.BETWEEN) {
        reOrderCardsBetweenColumn(destination.droppableId, currentItem);

        return;
      }
      if (reorderType === CardReorder.COMBINE) {
        await reOrderCardsBetweenColumn(destination.droppableId, currentItem);
        const currentColumn = newColumnsList.find(
          (column) => column._id === result.destination?.droppableId
        );
        const orderedList = currentColumn?.cards.map((card) => card._id);
        if (currentColumn && orderedList) {
          reOrderCardsInsideColumn(currentColumn._id, orderedList);
        }
        return;
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="all-columns"
        direction="horizontal"
        type={DroppableType.COLUMN}
      >
        {(provided, snapshot) => (
          <Lists<TItem>
            provided={provided}
            snapshot={snapshot}
            lists={columns}
            setColumnsList={onColumnsChange}
            addColumn={addColumn}
            deleteColumn={deleteColumn}
            renderCard={renderCard}
            renderFilters={renderFilters}
            addCard={addCard}
            addButtonPlaceholder={addButtonPlaceholder}
            renderLeadsTable={renderLeadsTable}
            gridView={gridView}
            toggleGridView={toggleGridView}
          />
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default KanbanBoard;
