import {
  DeleteTodoListResponse,
  ListTodoListResponse,
  CreateUpdateTodoListResponse,
  ArchiveTodoListResponse,
} from "../../types/models/task";
import {
  GET_CHECKLIST_TEMPLATE,
  GET_CHECKLIST_TEMPLATES,
  LIST_TODO_LIST,
} from "./queries";
import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import { filter, concat, map } from "lodash";
import {
  CreateUpdateChecklistTemplateResponse,
  DeleteChecklistTemplateCategoryResponse,
  DeleteChecklistTemplateListResponse,
  EnumChecklistTemplateType,
  GetChecklistTemplateResponse,
  GetTodoChecklistTemplateListResponse,
  TodoChecklistTemplates,
} from "../../../models/task";

export const handleDeleteTodoList = (): MutationUpdaterFn<
  DeleteTodoListResponse
> => (cache, response) => {
  const deletedTodoList = response.data?.deleteTodoList;
  const cacheData = cache.readQuery<ListTodoListResponse>({
    query: LIST_TODO_LIST,
  });

  const listTodoList = cacheData?.listTodoList;

  if (!listTodoList || !deletedTodoList) {
    return;
  }

  cache.writeQuery({
    query: LIST_TODO_LIST,
    data: {
      listTodoList: filter(
        listTodoList,
        (todoList) => todoList._id !== deletedTodoList._id
      ),
    },
  });
};

export const handleAddTodoList = (): MutationUpdaterFn<
  CreateUpdateTodoListResponse
> => (cache, response) => {
  const newTodoList = response.data?.createUpdateTodoList;
  const cacheData = cache.readQuery<ListTodoListResponse>({
    query: LIST_TODO_LIST,
  });

  const listTodoList = cacheData?.listTodoList;

  if (!listTodoList || !newTodoList) {
    return;
  }

  cache.writeQuery({
    query: LIST_TODO_LIST,
    data: {
      listTodoList: concat(listTodoList, newTodoList),
    },
  });
};

export const handleUpdateTodoList = (
  updatedTodoListId: string
): MutationUpdaterFn<CreateUpdateTodoListResponse> => (cache, response) => {
  const updatedTodoList = response.data?.createUpdateTodoList;
  const cacheData = cache.readQuery<ListTodoListResponse>({
    query: LIST_TODO_LIST,
  });

  const listTodoList = cacheData?.listTodoList;

  if (!listTodoList || !updatedTodoList) {
    return;
  }

  cache.writeQuery({
    query: LIST_TODO_LIST,
    data: {
      listTodoList: map(listTodoList, (todoList) =>
        todoList._id === updatedTodoListId ? updatedTodoList : todoList
      ),
    },
  });
};

export const handleArchiveTodoList = (
  updatedTodoListId: string
): MutationUpdaterFn<ArchiveTodoListResponse> => (cache, response) => {
  const updatedTodoList = response.data?.todo;
  const cacheData = cache.readQuery<ListTodoListResponse>({
    query: LIST_TODO_LIST,
  });

  const listTodoList = cacheData?.listTodoList;

  if (!listTodoList || !updatedTodoList) {
    return;
  }

  cache.writeQuery({
    query: LIST_TODO_LIST,
    data: {
      listTodoList: map(listTodoList, (todoList) =>
        todoList._id === updatedTodoListId ? updatedTodoList : todoList
      ),
    },
  });
};

const getChecklistTemplateList = (
  cache: ApolloCache<any>,
  checklistTemplateType?: EnumChecklistTemplateType
) => {
  const cacheData = cache.readQuery<GetTodoChecklistTemplateListResponse>({
    query: GET_CHECKLIST_TEMPLATES,
    ...(checklistTemplateType && {
      variables: {
        type: checklistTemplateType,
      },
    }),
  });
  return cacheData?.listChecklistTemplates;
};

const updateChecklistTemplateList = (
  cache: ApolloCache<any>,
  newList: GetTodoChecklistTemplateListResponse,
  checklistTemplateType?: EnumChecklistTemplateType
) => {
  cache.writeQuery({
    query: GET_CHECKLIST_TEMPLATES,
    ...(checklistTemplateType && {
      variables: {
        type: checklistTemplateType,
      },
    }),
    data: newList,
  });
};

export const handleDeleteChecklistTemplate = (): MutationUpdaterFn<
  DeleteChecklistTemplateListResponse
> => (cache, response) => {
  const deletedTemplateID = response.data?.deleteChecklistTemplate._id;
  const cacheData = getChecklistTemplateList(cache);
  if (!deletedTemplateID || !cacheData) {
    return;
  }
  const newList = cacheData.filter(
    (template) => template._id !== deletedTemplateID
  );

  updateChecklistTemplateList(cache, { listChecklistTemplates: newList });
};

export const handleAddChecklistTemplate = (
  checklistTemplateType?: EnumChecklistTemplateType
): MutationUpdaterFn<CreateUpdateChecklistTemplateResponse> => (
  cache,
  response
) => {
  const newTemplate = response.data?.createUpdateChecklistTemplate;
  const cacheData = getChecklistTemplateList(cache, checklistTemplateType);
  if (!newTemplate || !cacheData) {
    return;
  }

  updateChecklistTemplateList(
    cache,
    {
      listChecklistTemplates: cacheData.concat(newTemplate),
    },
    checklistTemplateType
  );
};

const getChecklistTemplate = (cache: ApolloCache<any>, templateId: string) => {
  const cacheData = cache.readQuery<GetChecklistTemplateResponse>({
    query: GET_CHECKLIST_TEMPLATE,
    variables: {
      templateId,
    },
  });
  return cacheData?.getChecklistTemplate;
};

const updateChecklistTemplate = (
  cache: ApolloCache<any>,
  newTemplate: TodoChecklistTemplates,
  templateId: string
) => {
  cache.writeQuery<GetChecklistTemplateResponse>({
    query: GET_CHECKLIST_TEMPLATE,
    variables: {
      templateId,
    },
    data: {
      getChecklistTemplate: newTemplate,
    },
  });
};

export const handleChecklistTemplateGroupDelete = (
  templateId?: string
): MutationUpdaterFn<DeleteChecklistTemplateCategoryResponse> => (
  cache,
  response
) => {
  const deleteTemplateId = response.data?.deleteChecklistTemplateCategory._id;
  if (!templateId) {
    return;
  }
  const template = getChecklistTemplate(cache, templateId);

  if (!deleteTemplateId || !template) {
    return;
  }

  updateChecklistTemplate(
    cache,
    {
      ...template,
      categories: template.categories.filter(
        (item) => item._id === deleteTemplateId
      ),
    },
    templateId
  );
};
