import React from "react";
import Icon from "../../icons/Icon";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../loader";

type ConversationEmptyBodyProps = {
  emptyMessage?: string;
  onAddClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  emptyButtonText?: string;
  emptyBodyActions?: () => JSX.Element;
};

const ConversationEmptyBody: React.FC<ConversationEmptyBodyProps> = (props) => {
  const {
    disabled,
    emptyMessage,
    onAddClick,
    loading,
    emptyButtonText,
    emptyBodyActions,
  } = props;

  const { t } = useTranslation();

  const renderBody = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        {emptyMessage && (
          <div className="field-text empty-message">{emptyMessage}</div>
        )}
        {emptyBodyActions ? (
          emptyBodyActions()
        ) : (
          <Button
            disabled={disabled}
            variant="primary"
            className="button large success fit"
            onClick={onAddClick}
          >
            <Icon name="add" />
            {emptyButtonText || t("common.createNew")}
          </Button>
        )}
      </>
    );
  };

  return <div className="communication-empty">{renderBody()}</div>;
};

export default ConversationEmptyBody;
