import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { SalesQuotePrintTemplateNamePayload } from "../../../graphql/types/models/quote-designer";
import CreateEntityModal from "../../modals/create-entity";
import newQuoteTemplateSchema from "./NewQuoteTemplate.schema";
import { newQuoteQuoteTemplateFields } from "./utils";

export type NewQuoteTemplateModal = {
  onSubmit: (values: SalesQuotePrintTemplateNamePayload) => void;
};
export type NewQuoteTemplateModalRef = {
  show: (show: boolean, name?: string) => void;
};

const NewQuoteTemplateModal: React.FC<NewQuoteTemplateModal> = (
  { onSubmit },
  ref
) => {
  const { t } = useTranslation();

  const [show, setShow] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({ name: "" });

  useImperativeHandle(ref, () => ({
    show: (show: boolean, name?: string) => {
      setShow(show);
      if (name) {
        setInitialValues({
          name: t("quotes.templateNameCopy", { name }),
        });
      }
    },
  }));

  return (
    <React.Fragment>
      <CreateEntityModal
        validationSchema={newQuoteTemplateSchema(t)}
        title={t("quotes.copyTemplate")}
        show={show}
        data={initialValues}
        onSubmit={onSubmit}
        submitText={t("common.save")}
        onClose={() => setShow(false)}
        fields={newQuoteQuoteTemplateFields(t)}
      />
    </React.Fragment>
  );
};

export default forwardRef(NewQuoteTemplateModal);
