import { CardOperation } from "../../components/kanban-board/utils";
import { CreateUpdateLead, LeadSale, LeadStage } from "../../models/leads";
import { getFullName } from "../../utils/text";

export const prepareLeadObject = (
  leadItem: LeadSale,
  stage: string,
  type: CardOperation
): CreateUpdateLead => {
  const assignedTo = leadItem.assignedTo
    ? leadItem.assignedTo.map((item) => item._id)
    : [];
  const contacts = leadItem.contacts
    ? leadItem.contacts.map((item) => item._id)
    : [];

  const lead = {
    stage,
    name: leadItem.name,
    description: leadItem.description,
    expectedCloseDate: leadItem.expectedCloseDate,
    budget: leadItem.budget,
    address: leadItem?.address,
    city: leadItem?.city,
    state: leadItem?.state,
    postcode: leadItem?.postcode,
    assignedTo,
    contacts,
    ...(type === CardOperation.UPDATE && { _id: leadItem._id }),
  };

  return lead;
};

export const findAllLabels = (stages: LeadStage[]) => {
  const allLabels: string[] = [];
  stages.forEach((stage) => {
    stage.leads.forEach((lead) => {
      if (lead.tags) {
        lead.tags.forEach((tag) => {
          if (!allLabels.includes(tag.name)) allLabels.push(tag.name);
        });
      }
    });
  });

  return allLabels;
};

export const getAllLeads = (stages: LeadStage[]) => {
  const allLeads: LeadSale[] = [];
  stages.forEach((stage) => {
    stage.leads.forEach((lead) => {
      const obj = {
        ...lead,
        ...(lead.tags && { labels: lead.tags.map((tag) => tag.name) }),
      };
      allLeads.push(obj);
    });
  });

  return allLeads;
};

export const prepareLeadsToTable = (leads: LeadSale[]) => {
  return leads.map((lead) => ({
    _id: lead._id,
    stage: lead.stage.name,
    name: lead.name,
    contact: lead.contacts.map((contact) => getFullName(contact)).join(", "),
    assignee: lead.assignedTo
      .map((assignee) => getFullName(assignee))
      .join(", "),
    budget: lead?.budget?.toString() || "0",
    closeDay: lead?.expectedCloseDate,
    labels: Boolean(lead.tags)
      ? lead.tags.map((tag) => tag.name).join(", ")
      : "",
  }));
};

export const assigneesFilter = (id: string, listLeadStages: LeadStage[]) => {
  const filteredLeadsByAssignee = listLeadStages.map((stage) => {
    const { leads } = stage;
    const filteredLeads = leads.filter((lead) => {
      if (lead.assignedTo.filter((assignee) => assignee._id === id).length > 0)
        return true;
      else return false;
    });
    const filteredStage = { ...stage, leads: filteredLeads };
    return filteredStage;
  });
  return filteredLeadsByAssignee;
};

export const labelFilter = (label: string, listLeadStages: LeadStage[]) => {
  const filteredLeads = listLeadStages.map((stage) => {
    const { leads } = stage;
    const filteredLeads = leads.filter((lead) => {
      if (lead.tags) {
        if (lead.tags.filter((tag) => tag.name === label).length > 0)
          return true;
        else return false;
      }
    });
    const filteredStage = { ...stage, leads: filteredLeads };
    return filteredStage;
  });
  return filteredLeads;
};

export const headInputFilter = (
  candidate: { label: string; value: string; data: any },
  input: string
): boolean => {
  if (input) {
    return candidate.data.values.toLowerCase().includes(input.toLowerCase());
  }
  return true;
};
