import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { compact, each, find, map, sortBy } from "lodash";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Calendar,
  EventWrapperProps,
  momentLocalizer,
  View,
} from "react-big-calendar";
import moment from "moment";
import SetNavigationRoute from "../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../components/dashboard/sidebar/utils/navigation-items";
import Dropdown from "../../components/dashboard/dropdown";
import { CalendarResponse } from "../../graphql/types/models/calendar";
import { GET_CALENDAR } from "../../graphql/queries/calendar/query";
import { ListJobsResponse } from "../../graphql/types/models/job";
import { LIST_JOBS } from "../../graphql/queries/job/queries";
import { formatQuoteNumber } from "../../utils/text";
import { JobStatus } from "../../models/job";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop";
import "./styles.scss";

type CalendarContainerProps = {};
type DateRange = {
  startDate: Date;
  endDate: Date;
};
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const EventWrapper: React.ComponentType<EventWrapperProps<any>> = ({
  event,
  children,
}) => {
  return (
    <div className={`event-${event.type}`}>
      {event.jobName && <div className="event-jobname">{event.jobName}</div>}
      {children}
    </div>
  );
};

const CalendarContainer: React.FC<CalendarContainerProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(history.location.search);

  const [dates, setDates] = React.useState<DateRange>({
    startDate: moment(searchParams.get("startDate") || undefined)
      .startOf("month")
      .toDate(),
    endDate: moment(searchParams.get("endDate") || undefined)
      .endOf("month")
      .toDate(),
  });
  const [jobId, setJobId] = React.useState("");
  const [date, setDate] = React.useState<Date>(new Date());
  const [view, setView] = React.useState<View>("month");

  React.useEffect(() => {
    const queryDate = searchParams.get("date")?.toString();
    if (queryDate) {
      setDate(new Date(queryDate));
    }
    const queryView = searchParams.get("view")?.toString() || "month";
    if (queryView) {
      setView(queryView as View);
    }
    const queryJobId = searchParams.get("jobId")?.toString() || "";
    if (queryJobId) {
      setJobId(queryJobId);
    }
  }, []);

  const { data: calendarData, refetch: getCalendar } = useQuery<
    CalendarResponse
  >(GET_CALENDAR, {
    variables: {
      startDate: dates.startDate.toISOString(),
      endDate: dates.endDate.toISOString(),
      filter: {
        jobId,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: jobsData } = useQuery<ListJobsResponse>(LIST_JOBS, {
    variables: {
      status: JobStatus.ACTIVE,
    },
  });

  const jobs = React.useMemo(() => {
    const jobItems = map(jobsData?.listJobs, (job) => ({
      id: job._id,
      label: job.name,
      onClick: () => setJobId(job._id),
    }));
    const items = [
      {
        id: "all",
        label: t("calendar.allJobs"),
        onClick: () => setJobId(""),
      },
    ].concat(sortBy(jobItems, "label"));

    return items;
  }, [jobsData]);

  const jobFilterLabel = React.useMemo(() => {
    if (!jobId) return t("calendar.allJobs");
    return find(jobsData?.listJobs, { _id: jobId })?.name;
  }, [jobId, jobsData]);

  React.useEffect(() => {
    getCalendar({
      startDate: dates.startDate.toISOString(),
      endDate: dates.endDate.toISOString(),
      filter: {
        jobId,
      },
    });
  }, [dates, jobId]);

  const events = React.useMemo(() => {
    const data: any = [];
    const calendar = calendarData?.getCalendar;
    each(calendar?.purchaseOrders, (po) => {
      po.deliveryDate &&
        data.push({
          id: po._id,
          title: t("calendar.purchaseOrderItem", {
            order: compact([
              formatQuoteNumber(po.orderNumber, "PO"),
              po.reference,
              po.supplier?.business_name || po.supplier?.contact_person,
            ]).join(", "),
          }),
          start: moment(po.deliveryDate).startOf("day").toDate(),
          end: moment(po.deliveryDate).startOf("day").toDate(),
          allDay: true,
          type: "purchaseorder",
          url: `/jobs/${po.job?._id}/purchase-orders/${po._id}`,
          jobName: po.job?.name,
        });
    });
    each(calendar?.todos, (todo) => {
      data.push({
        id: todo._id,
        title: t("calendar.taskItem", todo),
        start: moment(todo.dueDate).startOf("day").toDate(),
        end: moment(todo.dueDate).startOf("day").toDate(),
        allDay: true,
        type: "todo",
        url: `/tasks`,
      });
    });
    each(calendar?.scheduleItems, (si) => {
      data.push({
        id: si._id,
        title: t("calendar.scheduleItem", si),
        start: moment(si.startDate).toDate(),
        end: moment(si.endDate).toDate(),
        allDay: true,
        type: "scheduleitem",
        url: `/jobs/${si.job?._id}/schedule`,
        jobName: si.job?.name,
      });
    });
    return data;
  }, [calendarData]);

  const onRangeChange = React.useCallback(
    (range) => {
      let newDates = dates;
      if (range?.start && range?.end) {
        newDates = {
          startDate: range.start,
          endDate: range.end,
        };
      } else if (range?.length) {
        newDates = {
          startDate: range[0],
          endDate: moment(range[range.length - 1])
            .endOf("day")
            .toDate(),
        };
      }
      setDates(newDates);
    },
    [setDates]
  );

  React.useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.append("date", date.toISOString());
    searchParams.append("view", view);
    searchParams.append("jobId", jobId);
    searchParams.append("startDate", dates.startDate.toISOString());
    searchParams.append("endDate", dates.endDate.toISOString());
    history.replace(`/calendar?${searchParams}`);
  }, [date, jobId, view]);

  const onSelectEvent = React.useCallback((event) => {
    history.push(event.url);
  }, []);

  return (
    <div className="calendar-overview">
      <Helmet title={t("navigation.calendar")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.CALENDAR} />
      <Row>
        <Col sm={12} className="dashboard-dropdown-filter">
          <Dropdown
            label={jobFilterLabel}
            icon="expand_more"
            size="300px"
            id="filter-jobs"
            items={jobs}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Calendar
            localizer={localizer}
            events={events}
            onRangeChange={onRangeChange}
            onSelectEvent={onSelectEvent}
            startAccessor="start"
            endAccessor="end"
            components={{
              eventWrapper: EventWrapper,
            }}
            popup={true}
            date={date}
            onNavigate={(date) => setDate(date)}
            view={view}
            onView={(view) => setView(view)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CalendarContainer;
