import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { find, head, isEmpty } from "lodash";

import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import {
  CreateUpdateSpecCategoryModalPayload,
  GetSpecTemplateResponse,
  SpecificationCategory,
} from "../../../../models/specification";
import SpecificationCategories from "../../../../components/specification/specification-categories";
import SpecificationTable from "../../../../components/specification/specification-table";
import CreateUpdateSpecCategoryModal from "../../../../components/specification/specification-create-add-modal";
import { resizeFiles, uploadFiles } from "../../../../utils/files";
import { notify } from "../../../../components/notification";
import { CREATE_UPDATE_SPEC_TEMPLATE_CATEGORY } from "../../../../graphql/queries/specification/mutation";
import { CreateUpdateSpecTemplateCategory } from "../../../../graphql/types/models/specification";
import { useSpecifications } from "../../../../hooks/useSpecifications";
import { GET_SPEC_TEMPLATE } from "../../../../graphql/queries/specification/queries";
import { handleAddSpecTemplateCategory } from "../../../../graphql/queries/specification/utils";

type Params = {
  id: string;
};

type SpecTemplateProps = RouteComponentProps<Params> & DashboardContextValue;

const SpecificationTemplate: React.FC<SpecTemplateProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { t } = useTranslation();

  const { id: templateId } = match.params;

  const [
    selectedCategory,
    setCategory,
  ] = React.useState<SpecificationCategory | null>(null);

  const [showCreateEditModal, setShowCreateEditModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [
    editSpecCategory,
    setEditSpecCategory,
  ] = React.useState<SpecificationCategory | null>(null);
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);

  const { data: specTemplate, loading: specTemplateLoading } = useQuery<
    GetSpecTemplateResponse
  >(GET_SPEC_TEMPLATE, {
    variables: {
      templateId,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    openDeleteDialog,
    renderDeleteCategoryConfirm,
    getCategoryInput,
  } = useSpecifications({
    categories: specTemplate?.getSpecTemplate
      ?.categories as SpecificationCategory[],
    templateId,
    isTemplate: true,
  });

  React.useEffect(() => {
    if (specTemplate) {
      setNavigationContext({
        ...navigationContext,
        specificationTemplate: specTemplate.getSpecTemplate,
      });
    }
  }, [setNavigationContext, specTemplate]);

  React.useEffect(() => {
    const data = specTemplate?.getSpecTemplate?.categories;
    const selectionCategory =
      find(data, { _id: selectedCategory?._id }) || head(data);
    setCategory(selectionCategory || null);
  }, [specTemplate, selectedCategory]);

  const handleCategorySelect = React.useCallback(
    (category: SpecificationCategory) => {
      setCategory(category);
    },
    []
  );

  const openCreateModal = React.useCallback(async () => {
    setShowCreateEditModal(true);
  }, [setShowCreateEditModal]);

  const closeCreateModal = React.useCallback(async () => {
    setShowCreateEditModal(false);
    setEditSpecCategory(null);
  }, [setShowCreateEditModal, setEditSpecCategory]);

  const [createUpdateSpecTemplateCategory] = useMutation<
    CreateUpdateSpecTemplateCategory
  >(CREATE_UPDATE_SPEC_TEMPLATE_CATEGORY, {
    onCompleted: async (data) => {
      if (
        filesToUpload?.length > 0 &&
        data?.createUpdateSpecTemplateCategory?.items
      ) {
        setLoading(true);
        await uploadFiles(
          data.createUpdateSpecTemplateCategory.items
            .filter((item) => item.upload_url)
            .map((item) => ({
              name: item.name,
              upload_url: item.upload_url,
            })),
          filesToUpload
        );
      }
      setCategory(data.createUpdateSpecTemplateCategory);
      setLoading(false);
    },
  });

  const handleCreateSpecCategory = React.useCallback(
    async (specCategory: CreateUpdateSpecCategoryModalPayload) => {
      try {
        if (!specCategory) return;

        if (specCategory.items) {
          const files: File[] = [];

          specCategory.items.forEach((item) => {
            if (item.photo && item.photo.file) {
              files.push(item.photo.file);
            }
          });
          setLoading(true);
          const compressedFiles = await resizeFiles(files);
          setFilesToUpload(compressedFiles as any[]);
        }

        await createUpdateSpecTemplateCategory({
          variables: {
            templateId,
            category: getCategoryInput(specCategory),
          },
          update: handleAddSpecTemplateCategory(templateId),
        });
        setLoading(false);
        notify({
          title: t("specifications.addSpecCategory"),
          content: t("specifications.success.addSpecCategory"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("specifications.addSpecCategory"),
          content: t("specifications.error.addSpecCategory"),
        });
      }
      setFilesToUpload([]);
      closeCreateModal();
    },
    [closeCreateModal, createUpdateSpecTemplateCategory, t, templateId]
  );

  const handleEditSpecCategory = React.useCallback(
    async (specCategory: CreateUpdateSpecCategoryModalPayload) => {
      try {
        if (!specCategory) return;

        if (specCategory.items) {
          const files: File[] = [];

          specCategory.items.forEach((item) => {
            if (item.photo && item.photo.file) {
              files.push(item.photo.file);
            }
          });

          const compressedFiles = await resizeFiles(files);
          setFilesToUpload(compressedFiles as any[]);
        }

        await createUpdateSpecTemplateCategory({
          variables: {
            templateId,
            category: getCategoryInput(specCategory),
          },
        });

        notify({
          title: t("specifications.editSpecification"),
          content: t("specifications.success.editSpecification"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("specifications.editSpecification"),
          content: t("specifications.error.editSpecification"),
        });
      }
      setFilesToUpload([]);
      closeCreateModal();
    },
    [
      closeCreateModal,
      createUpdateSpecTemplateCategory,
      getCategoryInput,
      t,
      templateId,
    ]
  );

  const openUpdateModal = React.useCallback(async () => {
    setEditSpecCategory(selectedCategory);
    setShowCreateEditModal(true);
  }, [setShowCreateEditModal, selectedCategory]);

  return (
    <Container fluid className="m-0 p-0 h-100 quote-selections">
      <Helmet title={t("navigation.quotesSection.selections")} />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SPECIFICATION_TEMPLATE
        }
      />
      {renderDeleteCategoryConfirm()}
      <CreateUpdateSpecCategoryModal
        data={editSpecCategory}
        show={showCreateEditModal}
        onClose={closeCreateModal}
        onSubmit={
          editSpecCategory ? handleEditSpecCategory : handleCreateSpecCategory
        }
      />

      <Row className="h-100">
        <Col lg={4} xs={12}>
          <SpecificationCategories
            displayNavButtons={false}
            selectedCategory={selectedCategory}
            selectCategory={handleCategorySelect}
            categories={specTemplate?.getSpecTemplate.categories.filter(
              Boolean
            )}
            onAddClick={openCreateModal}
            onDelete={openDeleteDialog}
          />
        </Col>
        <Col lg={8} xs={12}>
          <SpecificationTable
            isDataLoading={
              loading || specTemplateLoading || isEmpty(selectedCategory?.items)
            }
            tableTitle={selectedCategory?.name || ""}
            items={selectedCategory?.items.filter(Boolean)}
            isLocked={false}
            isSelectedCategory={!!selectedCategory}
            updateSpecCategoryPress={openUpdateModal}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default withRouter(withDashboardContext(SpecificationTemplate));
