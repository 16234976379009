import React from "react";
import { Button, Modal } from "react-bootstrap";
import Icon from "../icons/Icon";

type LeftModalHeaderProps = {
  title: string | JSX.Element;
  onClose: () => void;
};

const LeftModalHeader: React.FC<LeftModalHeaderProps> = ({
  title,
  onClose,
}) => {
  return (
    <Modal.Header className="header">
      <Modal.Title className="title">{title}</Modal.Title>
      <Button className="close" onClick={onClose}>
        <Icon name="close" />
      </Button>
    </Modal.Header>
  );
};

export default LeftModalHeader;
