import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createProviderConfigurationSchema(t: TFunction) {
  return yup.object().shape({
    tenantId: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "integrations.account")
      ),
    salesAccountId: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "integrations.incomeAccount"
        )
      ),
    expenseAccountId: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "integrations.expensesAccount"
        )
      ),
  });
}
