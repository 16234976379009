import React from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import {
  TableCardData,
  TableCardDataColumn,
} from "../../dashboard/table-card/utils";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import LeftModal from "../../left-modal";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import CardTable from "../../dashboard/table-card/CardTable";
import { formatQuoteNumber, getFullName } from "../../../utils/text";
import { Timesheet } from "../../../models/timesheet";
import { formatDuration } from "../../../utils/date";
import { calcTotalTimesheetValue } from "../../../utils/calculations";
import { JobTimesheetTableItem } from "../../../models/job-timesheet";
// import "./styles.scss";

export type ListTimesheetsModalRef = ModalDisplayRef & {
  setData: (name: string, data: Timesheet[]) => void;
};

const ListTimesheetsModal: React.FC = ({}, ref) => {
  const { t } = useTranslation();

  const [costingName, setCostingName] = React.useState("");
  const [data, setData] = React.useState<Timesheet[]>([]);

  const { shouldShow, hide } = useModalDisplay<ListTimesheetsModalRef>(
    ref,
    {
      setData: (name: string, data: Timesheet[]) => {
        setCostingName(name);
        setData(data);
      },
    } as ListTimesheetsModalRef,
    [setData, setCostingName]
  );

  const tableData = React.useMemo<TableCardData<JobTimesheetTableItem>>(() => {
    return {
      columns: [
        {
          valueKey: "task",
          title: t("timesheets.task"),
        },
        {
          valueKey: "creatorName",
          title: t("timesheets.creator"),
        },
        {
          valueKey: "duration",
          title: t("timesheets.duration"),
        },
        {
          valueKey: "rate",
          title: t("jobTimesheets.rate"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "total",
          title: t("jobTimesheets.total"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(data, (item) => {
        const startDate = moment(item.startTime);
        const endDate = moment(item.endTime);

        return {
          cells: {
            ...item,
            creatorName: getFullName(item.user),
            duration: formatDuration(
              endDate.subtract(item.breakDuration, "minutes").diff(startDate),
              t
            ),
            dateTime: "",
            rate: item?.user?.hourly_rate ?? 0,
            breakDuration: item.breakDuration?.toString(),
            total: calcTotalTimesheetValue(
              item.rate,
              item.startTime,
              item.endTime,
              Number(item.breakDuration)
            ),
          },
        };
      }),
    };
  }, [data]);

  return (
    <LeftModal
      show={shouldShow}
      onHide={hide}
      className="wide list-timesheet-claims-modal"
    >
      <LeftModalHeader
        title={t("jobTimesheets.timesheetItems")}
        onClose={hide}
      />
      <LeftModalBody>
        <div>
          <h3>{costingName}</h3>
          <CardTable table={tableData} tableClass="table-outline" />
        </div>
      </LeftModalBody>
    </LeftModal>
  );
};

export default React.forwardRef(ListTimesheetsModal);
