import { MutationUpdaterFn, ApolloCache } from "@apollo/client";
import { filter, find } from "lodash";
import {
  CreateUpdateNoteResponse,
  DeleteNoteResponse,
  ListNotesResponse,
  Note,
  NoteEntityType,
} from "../../types/models/note";
import { LIST_NOTES } from "./queries";

const listNotes = (
  cache: ApolloCache<any>,
  entityType: NoteEntityType,
  entityId: string
) => {
  const response = cache.readQuery<ListNotesResponse>({
    query: LIST_NOTES,
    variables: {
      entityType,
      entityId,
    },
  });

  return response?.listNotes;
};

const updateNotes = (
  cache: ApolloCache<any>,
  entityType: NoteEntityType,
  entityId: string,
  notes: Note[]
) => {
  cache.writeQuery<ListNotesResponse>({
    query: LIST_NOTES,
    variables: {
      entityType,
      entityId,
    },
    data: {
      listNotes: notes,
    },
  });
};

export const handleCreateUpdateNote = (
  entityType: NoteEntityType,
  entityId: string
): MutationUpdaterFn<CreateUpdateNoteResponse> => (cache, response) => {
  const createdNote = response.data?.createUpdateNote;
  const notesCache = listNotes(cache, entityType, entityId);
  if (!createdNote || !notesCache) return;

  const noteExists = find(notesCache, { _id: createdNote._id });
  if (!noteExists) {
    updateNotes(cache, entityType, entityId, [...notesCache, createdNote]);
  }
};

export const handleDeleteNote = (
  entityType: NoteEntityType,
  entityId: string
): MutationUpdaterFn<DeleteNoteResponse> => (cache, response) => {
  const deletedNote = response.data?.deleteNote;
  const notesCache = listNotes(cache, entityType, entityId);

  if (!deletedNote || !notesCache) return;

  const notes = filter(notesCache, (note) => note._id !== deletedNote._id);

  updateNotes(cache, entityType, entityId, notes);
};
