import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { map, chain } from "lodash";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";

import ListTable, {
  ListTableRow,
} from "../../../../components/dashboard/list-table";
import DashboardCard from "../../../../components/dashboard/card";
import DashboardCardBody from "../../../../components/dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../../../components/dashboard/card/DashboardCardFooter";
import DashboardCardHeader from "../../../../components/dashboard/card/DashboardCardHeader";
import ConfirmDialog from "../../../../components/confirm-dialog";
import {
  ClientJobQuoteSubByIdResponse,
  ClientAcceptQuoteRequestResponse,
  QuoteRequestClientFeedback,
} from "../../../../graphql/types/models/quote-request";
import { CLIENT_ACCEPT_JOB_QUOTE_REQUEST } from "../../../../graphql/queries/quote-request/mutations";
import { handleClientQuoteRequestAccept } from "../../../../graphql/queries/quote-request/utils";
import { CLIENT_GET_JOB_QUOTES_SUB_BY_ID } from "../../../../graphql/queries/quote-request/queries";
import {
  calcGST,
  calcTotal,
  GST_PERCENT,
} from "../../../../utils/calculations";
import { notify } from "../../../../components/notification";
import CardPlaceholder from "../../../../components/dashboard/card-placeholder";
import "./styles.scss";

type Params = {
  id: string;
  quote_id: string;
  sub_id: string;
};

type ClientJobQuoteRequestDetailsProps = RouteComponentProps<Params>;

const ClientJobQuoteRequestDetails: React.FC<ClientJobQuoteRequestDetailsProps> = ({
  match,
}) => {
  const { id: jobId, quote_id, sub_id } = match.params;

  const [acceptDialogVisible, setAcceptDialogVisible] = useState(false);

  const { t } = useTranslation();

  const {
    data: clientListJobQuoteResponse,
    loading: clientListJobQuoteLoading,
  } = useQuery<ClientJobQuoteSubByIdResponse>(CLIENT_GET_JOB_QUOTES_SUB_BY_ID, {
    variables: {
      jobId,
      quoteRequestId: quote_id,
      subResId: sub_id,
    },
  });

  const [acceptQuoteRequest, { loading: acceptLoading }] = useMutation<
    ClientAcceptQuoteRequestResponse
  >(CLIENT_ACCEPT_JOB_QUOTE_REQUEST);

  const quote = React.useMemo(
    () => clientListJobQuoteResponse?.clientGetJobQuoteSubResponseById,
    [clientListJobQuoteResponse]
  );

  const subQuoteRequest = React.useMemo(() => {
    return quote?.subcontractors?.find(
      (subcontractor) => subcontractor._id === sub_id
    );
  }, [quote, sub_id]);

  const closeAcceptDialog = React.useCallback(
    () => setAcceptDialogVisible(false),
    []
  );

  const openAcceptDialog = React.useCallback(
    () => setAcceptDialogVisible(true),
    []
  );

  const totalsData = React.useMemo<ListTableRow[]>(() => {
    if (!subQuoteRequest) return [];
    const subTotal: number = chain(subQuoteRequest?.items)
      .reduce((total, item) => total + item.quantity * +(item?.cost ?? 0), 0)
      .value();

    const GST = calcGST(subTotal, GST_PERCENT);
    const total = calcTotal(subTotal, GST);

    return [
      { label: "Subtotal", value: t("common.currency", { amount: subTotal }) },
      { label: "GST", value: t("common.currency", { amount: GST }) },
      { label: "Total", value: t("common.currency", { amount: total }) },
    ];
  }, [t, subQuoteRequest]);

  const handleAcceptQuoteRequest = React.useCallback(async () => {
    try {
      await acceptQuoteRequest({
        variables: {
          jobId,
          quoteRequestId: quote_id,
          subResId: sub_id,
        },
        update: handleClientQuoteRequestAccept(jobId, quote_id, sub_id),
      });

      notify({
        title: t("quoteRequest.clientAcceptQuote"),
        content: t("quoteRequest.success.clientAcceptQuote"),
      });
    } catch (err) {
      notify({
        error: true,
        title: t("quoteRequest.clientAcceptQuote"),
        content: t("quoteRequest.error.clientAcceptQuote"),
      });
    }
  }, [jobId, quote_id, sub_id, t, acceptQuoteRequest]);

  const canAccept = React.useMemo(() => {
    return (
      subQuoteRequest?.client_feedback !== QuoteRequestClientFeedback.ACCEPTED
    );
  }, [subQuoteRequest]);

  if (clientListJobQuoteLoading || acceptLoading) return <CardPlaceholder />;

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("client.jobs.quoteRequest")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.QUOTES} />

      <ConfirmDialog
        title={t("quoteRequest.acceptQuote")}
        show={acceptDialogVisible}
        onSubmit={handleAcceptQuoteRequest}
        onClose={closeAcceptDialog}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("quoteRequest.acceptQuoteConfirm")}
        </span>
      </ConfirmDialog>

      <DashboardCard className="quote-request-details-card">
        <DashboardCardHeader className="text-capitalize justify-content-between">
          <div className="d-flex align-items-center">
            {quote?.name}
            {subQuoteRequest?.client_feedback && (
              <div className="title-badge text-capitalize w-auto pl-3 pr-3">
                {subQuoteRequest?.client_feedback}
              </div>
            )}
          </div>
        </DashboardCardHeader>
        <DashboardCardBody>
          <Row>
            <Col md={12}>
              <Table
                className="table table-form table-outline"
                hover
                responsive
              >
                <thead className="table-header table-header-nowrap">
                  <tr>
                    <th className="field-text">{t("costing.name")}</th>
                    <th className="field-text">{t("costing.quantity")}</th>
                    <th className="field-text">{t("costing.uom")}</th>
                    <th className="field-text">{t("costing.cost")}</th>
                    <th className="field-text text-right">
                      {t("costing.totalEx")}
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {subQuoteRequest &&
                    map(subQuoteRequest?.items, (item, index) => (
                      <tr key={index}>
                        <td
                          className="field-text"
                          style={{ minWidth: "100px" }}
                        >
                          {item.name}
                        </td>
                        <td
                          className="field-text"
                          style={{ minWidth: "100px" }}
                        >
                          {t("common.quantity", { amount: item.quantity })}
                        </td>
                        <td
                          className="field-text"
                          style={{ minWidth: "100px" }}
                        >
                          {item.UOM}
                        </td>
                        <td
                          className="field-text"
                          style={{ minWidth: "100px" }}
                        >
                          {t("common.currency", {
                            amount: item.cost,
                          })}
                        </td>
                        <td
                          className="field-text text-right"
                          style={{ minWidth: "100px" }}
                        >
                          {t("common.currency", {
                            amount: item.quantity * item.cost,
                          })}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col lg={6}>
              <ListTable title={t("quoteRequest.cost")} rows={totalsData} />
            </Col>
          </Row>
        </DashboardCardBody>
        {canAccept && (
          <DashboardCardFooter className="d-flex justify-content-end">
            <Button
              variant="primary"
              className="button large success"
              onClick={openAcceptDialog}
            >
              {t("quoteRequest.accept")}
            </Button>
          </DashboardCardFooter>
        )}
      </DashboardCard>
    </Container>
  );
};

export default ClientJobQuoteRequestDetails;
