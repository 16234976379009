import L, { LatLngLiteral, Layer, Marker, Path } from "leaflet";
import Leaflet from "../../../utils/leaflet-updated";
import { includes, reduce } from "lodash";
import map from "lodash/map";
import set from "lodash/set";
import noop from "lodash/noop";
import get from "lodash/get";
import { Calibration } from "../../../models/salesQuote";
import {
  SerializedLayer,
  TakeOffComment,
  TakeoffListItem,
  TakeOffShape,
  TakeOffMeasurement,
  ConvertedQuantityUnit,
} from "../../../models/take-off";

L.drawLocal.draw.toolbar.actions.title = "end";
L.drawLocal.draw.toolbar.actions.text = "end";

export const COMMENT_PATH = "options.staticOptions.commentOptions";
export const DEFAULT_WALL_HEIGHT = 0;

export const getMarkerIcon = (fill = "#0074d9", className: string) =>
  new L.Icon({
    iconUrl:
      "data:image/svg+xml;base64," +
      new Buffer(
        `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80"><g><ellipse ry="40" rx="40" cy="40" cx="40" stroke-width="0" fill="${fill}"/></g></svg>`
      ).toString("base64"),
    iconSize: [10, 10],
    iconAnchor: [5, 5],
    className,
  });

export const serializeLayer = (layer: Layer): SerializedLayer => {
  const layerType = get(layer, "layerType");
  const latlngs =
    layerType === "marker" || layerType === "comment"
      ? [get(layer, "_latlng")]
      : get(layer, "_latlngs");

  return {
    name: get(layer, "name", null),
    type: layerType === "polygon" ? "rectangle" : layerType,
    geometry: includes(
      ["polygon", "deduction", "rectangle", "rectangleDeduction"],
      layerType
    )
      ? latlngs[0]
      : latlngs,
    leaflet_id: get(layer, "_leaflet_id"),
    wallHeight: get(layer, "wallHeight", null),
    weight: get(layer, "weight", null),
    angle: get(layer, "angle", null),
    depth: get(layer, "depth", null),
    materialWidth: get(layer, "materialWidth", null),
    page: get(layer, "page", null),
    properties: get(layer, `${COMMENT_PATH}.properties`, undefined),
  };
};

export const serializeCommentLayer = (layer: L.Marker) => {
  return {
    type: get(layer, "layerType"),
    geometry: [
      {
        lat: layer.getLatLng().lat,
        lng: layer.getLatLng().lng,
      },
    ] as LatLngLiteral[],
    properties: get(layer, `${COMMENT_PATH}.properties`, {}),
    leaflet_id: get(layer, "_leaflet_id"),
    wallHeight: null,
    angle: null,
    depth: null,
    materialWidth: null,
    weight: null,
    page: get(layer, "page", null),
  };
};

export const deserializeShapes = (
  takeOff: TakeoffListItem,
  items: TakeOffShape[]
): L.Layer[] => {
  return map(items, (item) => {
    if (item.type === "comment") {
      let layer: L.Marker = L.marker(item.geometry[0], {
        icon: getMarkerIcon(takeOff.properties.color, "draw-icon"),
      });
      if (item.page) {
        set(layer, "page", item.page);
      }
      set(layer, "layerType", item.type);
      set(layer, "_leaflet_id", item.leaflet_id);

      set(layer, COMMENT_PATH, item);

      return layer;
    }

    const preparedType = item.type === "rectangle" ? "polygon" : item.type;
    const preparedGeometry =
      preparedType === "marker" ? item.geometry[0] : item.geometry;

    let layerFactory = get(L, preparedType, noop);

    if (preparedType === "polylineDeduction") {
      layerFactory = L.polyline;
    }
    if (preparedType === "deduction") {
      layerFactory = L.polygon;
    }
    if (preparedType === "rectangleDeduction") {
      layerFactory = L.rectangle;
    }

    let layer = layerFactory(preparedGeometry);
    set(layer, "layerType", preparedType);

    if (item.page) {
      set(layer, "page", item.page);
    }

    if (preparedType === "marker") {
      (layer as Marker).setIcon(
        getMarkerIcon(takeOff.properties.color, "draw-icon")
      );
    } else if (
      preparedType === "deduction" ||
      preparedType === "rectangleDeduction" ||
      preparedType === "polylineDeduction"
    ) {
      (layer as Path).setStyle({
        color: "#FF0000",
      });
    } else {
      (layer as Path).setStyle({
        color: takeOff.properties.color,
      });
    }

    set(layer, "name", item.name);
    set(layer, "angle", item.angle);
    set(layer, "depth", item.depth);
    set(layer, "weight", item.weight);
    set(layer, "wallHeight", item.wallHeight);
    set(layer, "materialWidth", item.materialWidth);
    set(layer, "_leaflet_id", item.leaflet_id);

    return layer;
  });
};

export const createShapeLabel = (
  shape: L.Layer,
  takeOff: TakeoffListItem,
  calibration: Calibration
) => {
  const polyShape = shape as L.Polyline;
  const layerType = get(shape, "layerType");
  const isLine = layerType === "polyline" || layerType === "polylineDeduction";

  const labelLatLng = isLine
    ? (polyShape.getLatLngs()[0] as L.LatLng)
    : polyShape.getBounds().getCenter();

  const shapeLatLng = isLine
    ? polyShape.getLatLngs()
    : polyShape.getLatLngs()[0];

  const shapeLayerAsShape = {
    geometry: shapeLatLng as L.LatLngLiteral[],
    angle: get(polyShape, "angle"),
    wallHeight: get(polyShape, "wallHeight"),
    weight: get(polyShape, "weight"),
    depth: get(polyShape, "depth"),
    materialWidth: get(polyShape, "materialWidth"),
    page: get(polyShape, "page"),
    type: get(polyShape, "layerType"),
    leaflet_id: get(polyShape, "_leaflet_id"),
  };
  let quantity = 0;
  switch (takeOff.UOM) {
    case TakeOffMeasurement.MILLIMETRE:
    case TakeOffMeasurement.LINEAR_METER:
    case TakeOffMeasurement.TONNE:
      quantity = getShapeDistance(shapeLayerAsShape, calibration);
      break;
    case TakeOffMeasurement.METER_SQUARED:
    case TakeOffMeasurement.CUBIC_METER:
      quantity = getShapeArea(shapeLayerAsShape, calibration);
      break;
  }
  const units = convertQuantity(quantity, takeOff.UOM);

  const label = L.marker(labelLatLng, {
    icon: L.divIcon({
      className: `leaflet-label leaflet-label-${layerType}`, // Set class for CSS styling
      html: [units.quantity, units.UOM].join(" "),
    }),
  });
  set(label, "layerType", "label");
  return label;
};

export const deserializeComments = (
  takeOff: TakeoffListItem,
  items: TakeOffComment[]
): L.Marker[] => {
  return map(items, (item) => {
    let layer: L.Marker = L.marker(item.geometry, {
      icon: getMarkerIcon(takeOff.properties.color, "draw-icon"),
    });
    set(layer, "layerType", item.type);
    set(layer, COMMENT_PATH, item);
    return layer;
  });
};

export const geodesicArea = (
  latlngs: LatLngLiteral[],
  scale: Calibration,
  angle?: number,
  depth?: number,
  materialWidth?: number,
  weight?: number
) => {
  const pointsCount = latlngs.length;
  let area = 0.0,
    p1,
    p2;
  const scaleKoef = scale.lng * scale.lat;
  if (pointsCount > 2) {
    for (let i = 0; i < pointsCount; i++) {
      p1 = latlngs[i];
      p2 = latlngs[(i + 1) % pointsCount];
      area += p1.lng * p2.lat * scaleKoef - p2.lng * p1.lat * scaleKoef;
    }
    area /= 2;
  }
  // pitch
  if (angle) {
    // https://www.omnicalculator.com/construction/roofing
    area = area / Math.cos((angle * Math.PI) / 180);
  }
  if (depth) {
    area *= depth / 1000;
  }
  if (materialWidth) {
    area *= 1000 / materialWidth;
  }
  if (weight) {
    area *= 1000 / weight;
  }

  return Math.abs(area);
};

const calcShapeDistance = (shape: TakeOffShape, calibration: Calibration) => {
  let distance = 0;
  if (!shape?.geometry) return distance;
  // calculate distance between each point
  for (let i = 0; i < shape.geometry.length - 1; i++) {
    distance += Leaflet.LineUtil._sqDistScale(
      shape.geometry[i],
      shape.geometry[i + 1],
      calibration
    );
  }
  if (shape.angle) {
    distance = calculateDistanceWithAngle(distance, shape.angle);
  }

  if (shape.weight) {
    distance = calculateWeight(distance, shape.weight);
  }

  if (shape.type === "polylineDeduction") {
    distance *= -1; // negative amount
  }
  return distance;
};

export const getShapeDistance = (
  shape: TakeOffShape,
  calibration: Calibration
) => {
  let amount = 0;

  if (
    shape.type === "polygon" ||
    shape.type === "rectangle" ||
    shape.type === "deduction" ||
    shape.type === "rectangleDeduction"
  ) {
    amount =
      geodesicArea(
        shape.geometry,
        calibration,
        0,
        0,
        shape.materialWidth || 0,
        shape.weight || 0
      ) / 1000;
    if (shape.type === "deduction" || shape.type === "rectangleDeduction") {
      amount *= -1;
    }
  } else {
    amount = calcShapeDistance(shape, calibration);
  }
  return amount;
};

export const getCalibrationFromShape = (
  calibrations: Calibration[],
  shape: TakeOffShape
) => {
  let pageIndex = shape.page - 1;
  if (pageIndex < 0 || pageIndex >= calibrations.length) {
    return null;
  }
  return calibrations[pageIndex];
};

export const updateLineQuantity = (
  takeOff: TakeoffListItem,
  shapes: TakeOffShape[] | undefined,
  calibrations: Calibration[]
): number => {
  return reduce(
    shapes,
    (ans, shape: TakeOffShape) => {
      const calibration = getCalibrationFromShape(calibrations, shape); // avoid crash when page is deleted
      if (!calibration) return ans;
      return ans + getShapeDistance(shape, calibration);
    },
    0
  );
};

export const getShapeArea = (shape: TakeOffShape, calibration: Calibration) => {
  let amount = 0;
  // wall height
  if (shape.type === "polyline" || shape.type === "polylineDeduction") {
    amount =
      (shape.wallHeight || shape.weight || 0) *
      calcShapeDistance(shape, calibration);
  } else {
    amount = geodesicArea(
      shape.geometry,
      calibration,
      shape.angle || 0,
      shape.depth || 0,
      shape.weight || 0
    );

    if (shape.type === "deduction" || shape.type === "rectangleDeduction") {
      amount *= -1; // negative amount
    }
  }
  return amount;
};

export const updateAreaQuantity = (
  takeOffItem: TakeoffListItem,
  shapes: TakeOffShape[] | undefined,
  calibrations: Calibration[]
): number => {
  return reduce(
    shapes,
    (ans: number, shape: TakeOffShape) => {
      const calibration = getCalibrationFromShape(calibrations, shape);
      if (!calibration) return ans;
      return ans + getShapeArea(shape, calibration);
    },
    0
  );

  // if (newTakeOffItem.pitchAngle) {
  //   newTakeOffItem.measurementCount = Math.abs(
  //     newTakeOffItem.measurementCount /
  //       Math.cos((newTakeOffItem.pitchAngle * Math.PI) / 180)
  //   );
  // }
};

export const convertQuantity = (
  value: number,
  unit: string
): ConvertedQuantityUnit => {
  switch (unit) {
    case TakeOffMeasurement.METER_SQUARED:
    case TakeOffMeasurement.CUBIC_METER: {
      const convert = value / 1000000;
      return {
        quantity: Math.round(convert * 100) / 100,
        UOM: unit,
      };
    }
    case TakeOffMeasurement.MILLIMETRE:
    case TakeOffMeasurement.LINEAR_METER: {
      const convert = value / 1000;
      return {
        quantity: Math.round(convert * 100) / 100,
        UOM: TakeOffMeasurement.LINEAR_METER,
      };
    }
    case TakeOffMeasurement.TONNE: {
      const convert = value / 1000;
      return {
        quantity: Math.round(convert * 100) / 100,
        UOM: TakeOffMeasurement.TONNE,
      };
    }
  }

  return {
    quantity: value,
    UOM: unit,
  };
};

export const calculateDistanceWithAngle = (distance: number, angle: number) => {
  const radians = angle * (Math.PI / 180);
  return distance * Math.sqrt(Math.pow(radians, 2) + 1);
};

export const calculateWeight = (distance: number, kg: number) => {
  if (!kg) return 0;
  const koef = kg / 1000;
  return +(distance * koef).toFixed(2);
};

export const qtyFn = (
  shape: TakeOffShape,
  calibration: Calibration,
  UOM: string
) => {
  switch (UOM) {
    case TakeOffMeasurement.MILLIMETRE:
    case TakeOffMeasurement.LINEAR_METER:
    case TakeOffMeasurement.TONNE:
      return getShapeDistance(shape, calibration);
    case TakeOffMeasurement.METER_SQUARED:
    case TakeOffMeasurement.CUBIC_METER:
      return getShapeArea(shape, calibration);
  }
  return 0;
};
