import React from "react";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import ConversationItem from "../conversation-item";
import { JobMessage } from "../../../models/job-messaging";
import { head } from "lodash";
import { getFullName } from "../../../utils/text";

export type ChannelInfo = {
  messages?: JobMessage[];
  _id: string;
  name: string;
  icon: string;
};

type ChannelListProps = {
  selectedId?: string;
  onSelect: (id: string) => void;
  channels: ChannelInfo[];
};

const ChannelList: React.FC<ChannelListProps> = (props) => {
  const { selectedId, onSelect, channels } = props;

  const { t } = useTranslation();

  const renderItems = () => {
    return map(channels, ({ _id, name, messages, icon }) => {
      const lastMessage = head(
        messages?.filter((message) => !message.is_deleted)
      );

      const sender = lastMessage?.sender;
      const fullName = getFullName(sender);

      return (
        <ConversationItem
          icon={icon}
          isSelected={_id === selectedId}
          key={_id}
          id={_id}
          messageDate={lastMessage?.createdAt}
          messageText={lastMessage?.text}
          messageOwner={fullName}
          name={name}
          onSelect={onSelect}
        />
      );
    });
  };

  return (
    <DashboardCard className="channels-list">
      <DashboardCardHeader className="channels-header">
        {t("communication.channels")}
      </DashboardCardHeader>
      <DashboardCardBody className="channels-body">
        {renderItems()}
      </DashboardCardBody>
    </DashboardCard>
  );
};

export default ChannelList;
