import React from "react";
import { useTranslation } from "react-i18next";

import ExtendTrialSchema from "./ExtendTrial.schema";
import { Business } from "../../../graphql/types/models/business";
import { useModalDisplay } from "../../../hooks/useModalDisplay";

import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import {
  WbAdminExtendSubscriptionDatePayload,
  WbAdminExtendSubscriptionDateResponse,
} from "../../../graphql/types/models/wb-business";
import { extendTrialFields } from "./utils";
import { useMutation } from "@apollo/client";
import { WB_ADMIN_EXTEND_SUBSCRIPTION } from "../../../graphql/queries/wb-admin/business/mutations";
import { notify } from "../../notification";

type ExtendTrialModalProps = {
  business: Business;
};

const ExtendTrialModal: React.FC<ExtendTrialModalProps> = (
  { business },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<WbAdminExtendSubscriptionDatePayload>
  >({});

  const [extendTrial] = useMutation<
    WbAdminExtendSubscriptionDateResponse,
    WbAdminExtendSubscriptionDatePayload
  >(WB_ADMIN_EXTEND_SUBSCRIPTION, {
    onCompleted: () => {
      hide();
      notify({
        title: t("wbAdmin.extendTrial"),
        content: t("wbAdmin.success.extendTrial"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("wbAdmin.extendTrial"),
        content: t("wbAdmin.error.extendTrial"),
      });
    },
  });

  React.useEffect(() => {
    setFormFields(extendTrialFields(t));
  }, [t]);

  const handleSubmit = React.useCallback(
    (values: WbAdminExtendSubscriptionDatePayload) => {
      extendTrial({
        variables: values,
      });
    },
    [business]
  );

  return (
    <CreateEntityModal
      validationSchema={ExtendTrialSchema(t)}
      title={t("wbAdmin.extendTrial")}
      show={shouldShow}
      data={{
        businessId: business._id || "",
        date: business.subscriptionEndDate || "",
      }}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
      submitText={t("common.save")}
    />
  );
};

export default React.forwardRef(ExtendTrialModal);
