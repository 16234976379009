import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createTaskGroupSchema from "./CreateScheduleGroup.schema";
import CreateEntityModal from "../../modals/create-entity";
import { createTaskGroupFields } from "./utils";
import { CreateTaskGroupPayload } from "../../../models/job";
import { EXTRA_COLOURS } from "../../../constants/colours";
import { useCreateAnother } from "../../../hooks/useCreateAnother";
import "./styles.scss";

type CreateTaskModalProps = {
  onSubmit: (data: CreateTaskGroupPayload, createAnother: boolean) => void;
  colourIndex?: number;
};

export type CreateTaskGroupModalRef = {
  show: (
    show: boolean,
    categoryData?: {
      start: Date;
      end: Date;
    }
  ) => void;
};

const CreateTaskGroupModal: React.FC<CreateTaskModalProps> = (props, ref) => {
  const { onSubmit, colourIndex = 0 } = props;
  const [show, setShow] = React.useState(false);
  const [categoryData, setCategoryData] = React.useState<{
    start: string | null;
    end: string | null;
  }>({
    start: null,
    end: null,
  });

  const { t } = useTranslation();

  const { shouldCreateAnother, renderCreateAnother } = useCreateAnother(
    t("schedule.createAnotherCategory")
  );

  useImperativeHandle(ref, () => ({
    show: (show: boolean, categoryData?: { start: Date; end: Date }) => {
      if (categoryData) {
        setCategoryData({
          start: categoryData.start.toISOString(),
          end: categoryData.end.toISOString(),
        });
      }
      setShow(show);
    },
  }));

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateTaskGroupPayload>
  >({});

  const resetForm = React.useCallback(() => {
    setFormFields(createTaskGroupFields(t));
  }, [t]);

  React.useEffect(() => {
    resetForm();
  }, [t]);

  const handleSubmit = React.useCallback(
    (values: CreateTaskGroupPayload, helpers?: FormikHelpers<any>) => {
      onSubmit(values, shouldCreateAnother);
      helpers?.resetForm();
      setFormFields({}); // required to reset suppliers selection
      resetForm();
      setCategoryData({
        start: null,
        end: null,
      });
      return true;
    },
    [resetForm, onSubmit, shouldCreateAnother]
  );

  const handleOnClose = React.useCallback(() => {
    setCategoryData({
      start: null,
      end: null,
    });
    setShow(false);
  }, []);

  return (
    <CreateEntityModal
      validationSchema={createTaskGroupSchema(t)}
      className="create-task-group-modal"
      title={t("schedule.addScheduleCategory")}
      show={show}
      data={{
        name: "",
        startDate: categoryData.start || "",
        endDate: categoryData.end || "",
        colour: EXTRA_COLOURS[colourIndex % EXTRA_COLOURS.length],
      }}
      onSubmit={handleSubmit}
      onClose={handleOnClose}
      fields={formFields}
      leftFooterRenderer={renderCreateAnother}
    />
  );
};

export default forwardRef(CreateTaskGroupModal);
