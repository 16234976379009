import { ApolloClient } from "@apollo/client";
import {
  SearchCostingItemResponse,
  SearchCostingItemType,
} from "../../graphql/types/models/price-list";
import { SEARCH_COSTING_ITEM } from "../../graphql/queries/price-list/queries";
import { PRICE_ITEM_FRAGMENT } from "../../graphql/queries/price-list/fragments";
import { last } from "lodash";
import { ASSEMBLY_FRAGMENT } from "../../graphql/queries/assemblies/fragments";
import { AssemblyListItem } from "../../models/assembly";
import { PriceListItem } from "../../models/price-list";
import { SelectOption } from "../../components/generic-form/inputs/creatable-select";

export function useCostingSearchQuery(
  client: ApolloClient<any>,
  searchItemType: SearchCostingItemType = SearchCostingItemType.ALL
) {
  const searchCostingItem = async (search: string): Promise<SelectOption[]> => {
    const { data } = await client.query<SearchCostingItemResponse>({
      query: SEARCH_COSTING_ITEM,
      variables: { search, searchItemType },
      fetchPolicy: "cache-first",
    });
    const { assemblyItems, priceItems } = data.searchCostingItem;
    const items: SelectOption[] = [];
    assemblyItems.forEach((item) => {
      items.push({
        label: item.name,
        value: `assembly:${item._id}`,
        hidden: false,
        data: item,
      });
    });

    priceItems.forEach((item) => {
      items.push({
        label: item.name,
        value: `price:${item._id}`,
        hidden: false,
        data: item,
      });
    });

    return items;
  };

  const findCostingItemFromCache = (
    itemId: string
  ): PriceListItem | AssemblyListItem | null => {
    if (!itemId) return null;

    if (itemId.indexOf("price:") < 0 && itemId.indexOf("assembly:") < 0)
      return null;
    const id = last(itemId.split(":"));

    const priceId = `PriceItem:${id}`;
    const priceItem = client?.readFragment({
      id: priceId,
      fragment: PRICE_ITEM_FRAGMENT,
    });
    if (priceItem) return priceItem;

    const assemblyId = `Assembly:${id}`;
    const assemblyItem = client?.readFragment({
      id: assemblyId,
      fragment: ASSEMBLY_FRAGMENT,
    });
    return assemblyItem;
  };

  return { searchCostingItem, findCostingItemFromCache };
}
