import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { useApolloClient } from "@apollo/client";
import { get, map, trim, omit } from "lodash";
import CreateAssemblyModal, {
  CreateAssemblyModalRef,
} from "../create-assembly-modal";
import {
  AppendDynamicProps,
  FormikPropGetSetValues,
  GenericFormFields,
} from "../../generic-form/GenericFormBody";
import {
  CreateCostingCategoryPayload,
  CreateCostingItemPayload,
  DistributeMarkup,
  UpdateCostingCategoryPayload,
} from "../../../models/salesQuote";
import UpdateEntityModal from "../../modals/update-entity";
import { addAssemblyItem, createUpdateCostingsField } from "./utils";
import CalculatorModal from "../../modals/calculator";
import {
  AssemblyCreatePayload,
  AssemblyListItem,
} from "../../../models/assembly";
import { TakeoffListItem } from "../../../models/take-off";
import { UOMOption } from "../../../utils/types/options";
import updateCostingSchema from "./UpdateCostingSchema";
import { calcGSTEx, GST_PERCENT } from "../../../utils/calculations";
import { useCostingLookup } from "../../../hooks/useCostingLookup";
import { useUOM } from "../../../hooks/useUOM";
import { Rounding } from "../../calculator/utils";
import { useCostChangeGST } from "../../../hooks/useCostChangeGST";
import { usePriceLookup } from "../../../hooks/usePriceLookup";
import { useCreateAssemblyFromSelection } from "../../../hooks/useCreateAssemblyFromSelection";
import "./styles.scss";

type UpdateCostingModalProps = {
  onSubmit: (data: UpdateCostingCategoryPayload, isCopy?: boolean) => void;
  onClose: () => void;
  // values: UpdateCostingCategoryPayload|null;
  takeoffs: TakeoffListItem[];
  uoms: UOMOption[];
  canCreateTakeoff?: boolean;
  salesQuoteId?: string;
};

export type UpdateCostingModalRef = {
  show: (
    show: boolean,
    category?: UpdateCostingCategoryPayload,
    isCopy?: boolean
  ) => void;
};

const UpdateCostingModal: React.FC<UpdateCostingModalProps> = (props, ref) => {
  const {
    onSubmit,
    onClose,
    takeoffs,
    uoms: currentUOMs,
    canCreateTakeoff,
    salesQuoteId,
  } = props;

  const client = useApolloClient();
  const {
    assemblies,
    assemblyOptions,
    setDefaultItems,
    handleAssemblyCreation,
    handleCostingNameChange,
    handleIsAssemblyCheck,
    searchCostingItem,
  } = useCostingLookup(client);

  const {
    costsHaveGST,
    handleCostChange,
    handleToggleGSTFree,
    handleCostsHaveGST,
    resetCostsHaveGST,
  } = useCostChangeGST();

  const [show, setShowModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [showCalculator, setCalculatorVisibility] = useState(false);
  const [calcFieldName, setFieldName] = useState("");
  const [category, setCategory] = useState<UpdateCostingCategoryPayload | null>(
    null
  );
  const [initialised, setInitialised] = React.useState(false);
  const [assemblyRowIndex, setAssemblyRowIndex] = React.useState<
    number | undefined
  >();

  const assemblyRef = React.useRef<CreateAssemblyModalRef>(null);

  const UOMvalues = React.useMemo(() => {
    return currentUOMs.map((item) => ({ UOM: item.value }));
  }, [currentUOMs]);

  const { uoms, handleUOMCreate } = useUOM(UOMvalues);

  useImperativeHandle(ref, () => ({
    show: (
      show: boolean,
      category?: UpdateCostingCategoryPayload,
      isCopy?: boolean
    ) => {
      setShowModal(show);
      setInitialised(!category);
      setCategory(category || null);
      if (isCopy) {
        setIsCopy(true);
      }
    },
  }));

  React.useEffect(() => {
    const items = map(category?.costItems, (item: CreateCostingItemPayload) => {
      return {
        ...item,
        _id: item.assemblyId || item.name,
        name: item.name,
        UOM: item.UOM,
        wastage: Number(item?.wastage || 0),
        rounding: item.rounding,
        total: Number(item.cost) || 0,
        margin_amount: Number(item.margin_amount || 0),
        __typename: "",
      } as AssemblyListItem;
    });

    setDefaultItems(items);
  }, [category]);

  const initialValues = React.useMemo(() => {
    setInitialised(true);
    if (!category) {
      return {
        name: "",
        margin_amount: "",
        costItems: [
          {
            name: "",
            quantity: "",
            assemblyId: "",
            margin_amount: "",
            wastage: 0,
            rounding: Rounding.NONE,
            cost: "0",
            UOM: "lm",
            note: "",
            gstFree: false,
            hasGST: true,
          },
        ],
      };
    }

    const values = {
      ...category,
      costItems: map(category?.costItems, (item) => ({
        ...item,
        gstFree: !item.hasGST,
        note: trim(item.note || ""),
        // name: item.assemblyId || item.name, // fixed for pre-selected dropdown
        // assemblyId: item.assemblyId,
      })),
    };
    return values;
  }, [category, setInitialised]);

  const toggleCalculatorModal = React.useCallback(() => {
    setCalculatorVisibility(!showCalculator);
  }, [showCalculator]);

  const { t } = useTranslation();
  const { priceLookupButton, renderPriceLookupModal } = usePriceLookup(
    t,
    "costItems",
    handleUOMCreate,
    false
  );
  const {
    handleOnCreateAssemblyFromSelection,
    hasSelectedItems,
    handleResetSelectedItems,
  } = useCreateAssemblyFromSelection(
    "costItems",
    setAssemblyRowIndex,
    assemblyRef
  );

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<UpdateCostingCategoryPayload>
  >({});

  const openCalculatorModal = React.useCallback((fieldName: string) => {
    setFieldName(fieldName);
    setCalculatorVisibility(true);
  }, []);

  const getAssemblyProps = React.useCallback(
    (formikProps, __, rowIndex): AppendDynamicProps => {
      const hasAssembly = !!formikProps.values.costItems[rowIndex]?.assemblyId;
      return {
        icon: "file_copy",
        className: hasAssembly ? "success" : "",
      };
    },
    []
  );

  const getMarkupProps = React.useCallback(
    (formikProps, __, rowIndex): AppendDynamicProps => {
      let icon,
        tooltip,
        className = "";
      switch (formikProps.values.costItems[rowIndex]?.distribute_markup) {
        case DistributeMarkup.CATEGORY:
          icon = "group_work";
          className = "warning";
          tooltip = t("costing.distributeOverCategory");
          break;
        case DistributeMarkup.ESTIMATION:
          icon = "workspaces";
          className = "primary";
          tooltip = t("costing.distributeOverEstimation");
          break;
        default:
          icon = "do_disturb";
          className = "";
          tooltip = t("costing.doNotDistribute");
      }
      return {
        icon,
        className,
        tooltip,
      };
    },
    []
  );

  // clear wastage when manually setting quantity
  const handleQuantityChange = React.useCallback(
    (fieldValue, formikProps, rowIndex) => {
      if (!initialised || !show) return;
      formikProps.setFieldValue(
        `costItems[${rowIndex}].raw_quantity`,
        fieldValue.toString()
      );
      formikProps.setFieldValue(`costItems[${rowIndex}].wastage`, 0);
      formikProps.setFieldValue(`costItems[${rowIndex}].rounding`, "");
    },
    [initialised, show]
  );

  const handleAssemblyChange = React.useCallback(
    (fieldValue, rowIndex, formikProps, fieldName) => {
      if (!initialised || !show) return;

      const fieldsArray = `costItems[${rowIndex}]`;
      const item = handleCostingNameChange(
        fieldValue,
        fieldsArray,
        formikProps
      );
      if (item?.UOM) {
        handleUOMCreate(item.UOM);
      }
    },
    [initialised, show, handleUOMCreate]
  );

  const handleAssemblyPress = React.useCallback(
    (
      fieldName: string,
      fieldValue?: string,
      rowIndex?: number,
      formikProps?: FormikProps<any>
    ) => {
      const formAssembly = get(formikProps?.values, fieldName.split(".")[0]);
      // const assembly = find(assemblies, { _id: formAssembly?.assemblyId });
      setAssemblyRowIndex(rowIndex);
      const assembly = formAssembly?.assemblyId
        ? formAssembly
        : { ...formAssembly, ...addAssemblyItem(formAssembly) };

      assemblyRef.current?.show(true, assembly);
    },
    [initialValues, assemblies, assemblyRef, handleAssemblyChange]
  );

  const handlePriceSearch = React.useCallback(
    (value: string) => searchCostingItem(value),
    []
  );

  const handleMarkupPress = React.useCallback(
    (
      fieldName: string,
      fieldValue?: string,
      rowIndex?: number,
      formikProps?: FormikProps<any>
    ) => {
      const distributeKey = [fieldName.split(".")[0], "distribute_markup"].join(
        "."
      );
      const currentValue = get(
        formikProps?.values,
        distributeKey,
        DistributeMarkup.NONE
      );
      let nextValue;
      switch (currentValue) {
        case DistributeMarkup.CATEGORY:
          nextValue = DistributeMarkup.ESTIMATION;
          break;
        case DistributeMarkup.ESTIMATION:
          nextValue = DistributeMarkup.NONE;
          break;
        default:
          nextValue = DistributeMarkup.CATEGORY;
      }
      formikProps?.setFieldValue(distributeKey, nextValue);
    },
    []
  );
  const handleEnableRowCopying = React.useCallback(
    (formikProps: FormikPropGetSetValues, rowIndex: number) => {
      const { values, setFieldValue } = formikProps;
      const currentRow = values["costItems"][rowIndex];
      setFieldValue(
        "costItems",
        values["costItems"].concat({ ...currentRow, _id: "" })
      );
    },
    []
  );

  React.useEffect(() => {
    setFormFields(
      createUpdateCostingsField(
        t,
        handleAssemblyPress,
        handleMarkupPress,
        openCalculatorModal,
        assemblyOptions,
        getAssemblyProps,
        getMarkupProps,
        uoms,
        handleUOMCreate,
        handleAssemblyChange,
        handleQuantityChange,
        handleCostChange,
        handleIsAssemblyCheck,
        handlePriceSearch,
        costsHaveGST,
        handleCostsHaveGST,
        handleToggleGSTFree,
        priceLookupButton,
        handleEnableRowCopying,
        handleOnCreateAssemblyFromSelection
      )
    );
  }, [
    t,
    assemblyOptions,
    uoms,
    handleUOMCreate,
    handleAssemblyPress,
    handleMarkupPress,
    openCalculatorModal,
    getAssemblyProps,
    handleAssemblyChange,
    handleQuantityChange,
    handleCostChange,
    handlePriceSearch,
    handleIsAssemblyCheck,
    costsHaveGST,
    handleCostsHaveGST,
    handleToggleGSTFree,
    handleEnableRowCopying,
  ]);

  const handleClose = React.useCallback(() => {
    setShowModal(false);
    setInitialised(false);
    resetCostsHaveGST();
    setIsCopy(false);
  }, [setInitialised, setShowModal]);

  const handleSubmit = React.useCallback(
    (values: CreateCostingCategoryPayload) => {
      console.log({ values });
      onSubmit &&
        onSubmit(
          {
            ...values,
            costItems: values.costItems.map((item, index) => ({
              ...omit(item, ["cost_inc", "gstFree"]),
              hasGST: !item.gstFree,
              cost: costsHaveGST
                ? calcGSTEx(Number(item.cost_inc) || 0, GST_PERCENT).toString()
                : item.cost,
              order: index + 1,
            })),
          },
          isCopy
        );
      handleClose();
    },
    [onSubmit, assemblies, handleClose, costsHaveGST, isCopy]
  );

  const handleAssemblySubmit = React.useCallback(
    async (assembly: AssemblyCreatePayload, formikProps: FormikProps<any>) => {
      const fieldsArray = `costItems[${assemblyRowIndex}]`;
      await handleAssemblyCreation(fieldsArray, assembly, formikProps);
      if (hasSelectedItems) {
        handleResetSelectedItems(formikProps);
      }
      assemblyRef.current?.show(false);
    },
    [assemblyRef, assemblyRowIndex, hasSelectedItems]
  );

  const handleCalcSubmit = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, [calcFieldName]);

  return (
    <UpdateEntityModal
      className="update-costing-modal"
      title={t("costing.updateCostings")}
      validationSchema={updateCostingSchema(t)}
      show={show}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={handleClose}
      fields={formFields}
      disableEventValidation={true}
      submitButtonTitle={isCopy ? t("common.copy") : t("common.update")}
    >
      {(formikProps) => {
        const wastage = get(formikProps.values, calcFieldName.split(".")[0])
          ?.wastage;
        const rounding = get(formikProps.values, calcFieldName.split(".")[0])
          ?.rounding;
        const quantity = get(formikProps.values, calcFieldName);
        const raw_quantity = get(
          formikProps.values,
          calcFieldName.split(".")[0]
        )?.raw_quantity;
        const rawQuantity = raw_quantity || quantity;
        const itemName = get(formikProps.values, calcFieldName.split(".")[0])
          ?.name;

        return (
          <>
            {renderPriceLookupModal(formikProps)}
            {showCalculator && (
              <CalculatorModal
                formikProps={formikProps}
                show={showCalculator}
                onSubmit={handleCalcSubmit}
                fieldName={calcFieldName}
                onClose={toggleCalculatorModal}
                takeoffs={takeoffs}
                initialValue={rawQuantity}
                wastage={wastage?.toString()}
                rounding={rounding?.toString()}
                takeoffProps={{
                  canCreateTakeoff,
                  salesQuoteId,
                  name: get(formikProps.values, calcFieldName.split(".")[0])
                    ?.name,
                }}
                title={itemName}
              />
            )}

            <CreateAssemblyModal
              ref={assemblyRef}
              uoms={uoms}
              onSubmit={handleAssemblySubmit}
              onClose={() => assemblyRef.current?.show(false)}
              formikProps={formikProps}
              takeoffs={takeoffs}
              takeoffProps={{
                canCreateTakeoff,
                salesQuoteId,
                name: "",
              }}
            />
          </>
        );
      }}
    </UpdateEntityModal>
  );
};

export default forwardRef(UpdateCostingModal);
