import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../../components/confirm-dialog";
import CommunicationList from "../../../../components/communication/communication-list";
import { SalesQuote } from "../../../../models/salesQuote";
import EstimationItem from "../lead-estimation/estimation-item/";
import "./styles.scss";
import {
  LINK_LEAD_TO_ESTIMATE,
  UNLINK_LEAD_FROM_ESTIMATE,
} from "../../../../graphql/queries/leads/mutations";
import {
  UnLinkLeadPayload,
  UnLinkLeadResponse,
} from "../../../../models/leads";
import { notify } from "../../../../components/notification";
import CreateEntityModal from "../../../../components/modals/create-entity";
import linkEstimationSchema from "./estimation-item/LinkEstimation.schema";
import { GenericFormFields } from "../../../../components/generic-form/GenericFormBody";
import linkToEstimationFields from "./estimation-item/utils";
import { Button } from "react-bootstrap";
import Icon from "../../../../components/icons/Icon";
import CreateSalesQuoteModal, {
  CreateSalesQuoteModalRef,
} from "../../../../components/quotes/create-sales-quote-modal";
import { ContactDetail } from "../../../../graphql/types/models/client";

type LeadEstimationProps = {
  leadEstimate: SalesQuote | null;
  leadId: string | null;
  estimationsList: SalesQuote[];
  refetchLeadData: (leadId: string) => void;
  contacts?: ContactDetail[];
};
const LeadEstimation: React.FC<LeadEstimationProps> = ({
  leadEstimate,
  leadId,
  estimationsList,
  refetchLeadData,
  contacts,
}) => {
  const { t } = useTranslation();
  const confirmRef = useRef<ConfirmDialogRef>(null);
  const createQuoteRef = React.useRef<CreateSalesQuoteModalRef>(null);
  const [unlinkTarget, setUnlinkTarget] = useState<SalesQuote | null>(null);

  const handleUnlink = React.useCallback((salesQuote: SalesQuote) => {
    setUnlinkTarget(salesQuote);
    confirmRef.current?.show(true);
  }, []);

  const [unlinkLead, { loading: loadingCreateUpdateLead }] = useMutation<
    UnLinkLeadResponse,
    UnLinkLeadPayload
  >(UNLINK_LEAD_FROM_ESTIMATE, {
    onCompleted: (response) => {
      notify({
        title: t("leads.unlinkEstimation"),
        content: t("leads.success.unlinkLead"),
      });
      if (leadId) refetchLeadData(leadId);
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("leads.unlinkEstimation"),
        content: error.message,
      });
    },
  });

  const [linkToEstimation, { loading: loadingLinkToEstimation }] = useMutation<
    UnLinkLeadResponse,
    UnLinkLeadPayload
  >(LINK_LEAD_TO_ESTIMATE, {
    onCompleted: (response) => {
      notify({
        title: t("leads.linkToEstimation"),
        content: t("leads.success.linkLead"),
      });
      if (leadId) refetchLeadData(leadId);
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("leads.linkToEstimation"),
        content: error.message,
      });
    },
  });

  React.useEffect(() => {
    if (leadId) refetchLeadData(leadId);
  }, []);

  const handleConfirmUnlink = React.useCallback(() => {
    if (!unlinkTarget || !leadId) return;
    unlinkLead({
      variables: {
        leadId: leadId,
        estimateId: unlinkTarget._id,
      },
    });
  }, [leadId, unlinkLead, unlinkTarget]);

  const handleCreate = React.useCallback(() => {
    setShowModal(true);
  }, []);

  const [showModal, setShowModal] = React.useState(false);

  const formFields = React.useMemo<
    GenericFormFields<{ estimateId: string }>
  >(() => {
    return linkToEstimationFields(t, estimationsList);
  }, [estimationsList, t]);

  const handleSubmit = React.useCallback(
    async (values: { estimateId: string }) => {
      const { estimateId } = values;

      if (leadId && estimateId) {
        linkToEstimation({
          variables: {
            leadId,
            estimateId,
          },
        });
      }
      setShowModal(false);
    },
    [leadId, linkToEstimation]
  );

  const handleCreateQuote = React.useCallback(() => {
    if (!leadId) return;
    if (!!contacts?.length) {
      const contactId = contacts[0]._id;
      createQuoteRef.current?.show(true, leadId, contactId);
    } else {
      createQuoteRef.current?.show(true, leadId);
    }
  }, [contacts, leadId]);

  const emptyEstimationActions = React.useCallback(() => {
    return (
      <div className="d-flex">
        <Button
          variant="primary"
          className="button large success fit mr-3"
          onClick={handleCreate}
        >
          <Icon name="insert_link" />
          {t("leads.linkToEstimation")}
        </Button>
        <Button
          variant="primary"
          className="button large success fit"
          onClick={handleCreateQuote}
        >
          <Icon name="add" />
          {t("leads.createEstimate")}
        </Button>
      </div>
    );
  }, [handleCreate, handleCreateQuote, t]);

  return (
    <>
      <CommunicationList
        title={t("leads.estimation")}
        emptyBodyActions={emptyEstimationActions}
        loading={false}
        empty={!leadEstimate}
        emptyMessage={t("leads.emptyPlaceholder")}
        className={"lead-estimation-container"}
        hideAddBtn
      >
        {leadEstimate && (
          <EstimationItem leadEstimate={leadEstimate} onUnlink={handleUnlink} />
        )}
      </CommunicationList>
      <CreateSalesQuoteModal ref={createQuoteRef} />
      <CreateEntityModal
        validationSchema={linkEstimationSchema(t)}
        title={t("leads.linkToEstimation")}
        data={{
          estimateId: "",
        }}
        show={showModal}
        fields={formFields}
        onSubmit={handleSubmit}
        onClose={() => setShowModal(false)}
        submitText={t("leads.link")}
      />
      <ConfirmDialog
        ref={confirmRef}
        title={t("leads.unlinkEstimation")}
        confirm={t("common.yes")}
        onSubmit={handleConfirmUnlink}
      >
        <span className="field-text">{t("leads.unlinkEstimationMessage")}</span>
      </ConfirmDialog>
    </>
  );
};

export default LeadEstimation;
