import React from "react";
import moment from "moment";
import classnames from "classnames";
import { compact } from "lodash";
import { Badge } from "react-bootstrap";
import { TFunction } from "i18next";
import { useHistory } from "react-router-dom";
import Icon from "../../icons/Icon";
import { Reminder } from "../../../models/reminder";
import "./styles.scss";

type DashboarReminderItemProps = {
  reminder: Reminder;
  t: TFunction;
};
const DashboarReminderItem: React.FC<DashboarReminderItemProps> = (props) => {
  const { reminder, t } = props;
  const history = useHistory();

  const contact = React.useMemo(() => {
    if (reminder.contact) {
      return compact([
        reminder.contact.first_name,
        reminder.contact.last_name,
      ]).join(" ");
    }
    if (reminder.supplier) {
      return reminder.supplier.business_name;
    }
  }, [reminder]);

  const handleClick = React.useCallback(() => {
    if (reminder.contact) {
      history.push(`/contacts/clients/${reminder.contact._id}`);
    }
    if (reminder.supplier) {
      history.push(`/contacts/suppliers/${reminder.supplier._id}`);
    }
  }, [reminder]);

  return (
    <div className={classnames("dashboard-task")} onClick={handleClick}>
      <div className="task-message">
        <Badge variant="danger">0%</Badge>
        <b>{reminder.title}</b>
        <span>
          {" "}
          {t(
            reminder.contact ? "dashboard.onClient" : "dashboard.onSupplier"
          )}{" "}
        </span>
        <b>{contact}</b>
        <span
          className="task-date"
          title={moment(reminder.dueDate).format("lll")}
        >
          {moment(reminder.dueDate).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default DashboarReminderItem;
