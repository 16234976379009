import classNames from "classnames";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../icons/Icon";
import "./styles.scss";

type SearchInputProps = {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  className?: string;
  showCancelButton?: boolean;
  appendSearchIcon?: boolean;
};

const SearchInput: FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
  className,
  showCancelButton,
  appendSearchIcon,
}) => {
  const { t } = useTranslation();
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const isValue = React.useMemo(() => {
    return Boolean(value);
  }, [value]);

  const handleOnCancel = React.useCallback(() => {
    onChange("");
  }, [onChange]);

  return (
    <div className="inputSearch-container">
      <input
        value={value}
        onChange={handleSearchInput}
        className={classNames(className, "inputSearch")}
        type="text"
        placeholder={placeholder ? placeholder : "Search..."}
      />
      {isValue && showCancelButton && (
        <button
          onClick={handleOnCancel}
          className="cancel-icon-container"
          title={t("common.clearSearch")}
        >
          <Icon className="cancel-icon" name="cancel" outlined />
        </button>
      )}
      {!isValue && appendSearchIcon && (
        <Icon className="search-icon" name="search" outlined />
      )}
    </div>
  );
};

export default SearchInput;
