import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createJobCostingSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "costing.categoryName")
      ),
    items: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "costing.description"
            )
          ),
        quantity: yup
          .string()
          .nullable()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.quantity")
          ),
        cost: yup
          .string()
          .nullable()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.cost")
          ),
        UOM: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.uom")
          ),
      })
    ),
  });
}
