import { gql } from "@apollo/client";
import { SELECTION_CATEGORY_ITEM_FRAGMENT } from "../selection/fragments";

export const LIST_SALES_QUOTE_CATEGORIES = gql`
  query ListSalesQuoteCategories($salesQuoteId: ID!) {
    listSalesQuoteCostingCategories(salesQuoteId: $salesQuoteId) {
      _id
      name
      margin_amount
      order
      status
      salesQuote {
        markup
      }
      costings {
        _id
        name
        quantity
        raw_quantity
        UOM
        cost
        distribute_markup
        distribution_amount
        quoteRequestStatus
        quoteRequestItems {
          _id
          name
          cost
          quantity
          UOM
          quoteRequest {
            _id
            name
            status
            quoteNumber
            due_date
            subcontractors {
              _id
              status
              supplier {
                _id
                business_name
              }
              items {
                _id
                cost
                quoteItemId
              }
            }
          }
        }
        margin_amount
        type
        note
        is_allowance
        order
        hasGST
        wastage
        rounding
        selectionItem {
          ...SelectionCategoryItemFragment
        }
        specCategoryItem {
          _id
        }
        items {
          _id
          name
          quantity
          raw_quantity
          computed_quantity
          UOM
          cost
          wastage
          rounding
          computed_quantity
        }
      }
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
`;
