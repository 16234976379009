import { TFunction } from "i18next";
import * as yup from "yup";

import {
  CreateTakeOffPayload,
  TakeOffMeasurement,
} from "../../../../models/take-off";
import { ValidationMessagesUtils } from "../../../../utils/yup";

export default function createTakeOffSchema(t: TFunction) {
  return yup.object().shape<CreateTakeOffPayload>({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "takeOffSection.name")
      ),
    UOM: yup
      .string()
      .oneOf([
        TakeOffMeasurement.LINEAR_METER,
        TakeOffMeasurement.METER_SQUARED,
        TakeOffMeasurement.CUBIC_METER,
        TakeOffMeasurement.QUANTITY,
        TakeOffMeasurement.TONNE,
      ])
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "takeOffSection.measurement"
        )
      ),
    color: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "takeOffSection.colour"
        )
      ),
  });
}
