import React, { useCallback, useState } from "react";
import { chain, findIndex } from "lodash";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { uploadFiles, UploadUrl } from "../../../../../utils/files";
import UploadSpinner, { UploadFileProgress } from "../../../../upload-spinner";
import Icon from "../../../../icons/Icon";
import "./styles.scss";
import AttachmentItem from "../../../../attachments/AttachmentItem";

export type FileWithUrl = File & {
  url?: string;
};

export function useAttachment() {
  const { t } = useTranslation();

  const [attachments, setAttachments] = useState<FileWithUrl[]>([]);
  const [isUploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<UploadFileProgress[]>(
    []
  );

  const onDrop = useCallback(
    (acceptedFiles: FileWithUrl[]) => {
      const newAttachments = chain(attachments)
        .concat(acceptedFiles)
        .compact()
        .uniqBy((f) => [f.name, f.lastModified, f.size].join("."))
        .value();
      setAttachments(newAttachments);
    },
    [attachments]
  );

  const {
    getRootProps,
    getInputProps,
    open: openFilePicker,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const onProgress = useCallback(
    (file: File, progress: number) => {
      const fProgress = [...uploadProgress];
      const currentFileIndex = findIndex(
        uploadProgress,
        (f) => f.file === file
      );
      if (currentFileIndex >= 0) {
        fProgress[currentFileIndex].progress = progress;
        setUploadProgress(fProgress);
      }
    },
    [uploadProgress]
  );

  const uploadAttachments = useCallback(
    async (urls: UploadUrl[]) => {
      const filteredAttachments = attachments.filter((file) => !file.url);

      if (isUploading) return;
      setUploadProgress(
        filteredAttachments.map((file) => ({
          file,
          progress: 0,
        }))
      );
      setUploading(true);
      await uploadFiles(urls, filteredAttachments, onProgress);
      setUploading(false);
    },
    [attachments, isUploading, onProgress]
  );

  const onRemove = useCallback(
    (attachment: File) => {
      const newAttachments = attachments.filter((a) => a !== attachment);
      setAttachments(newAttachments);
    },
    [attachments]
  );

  const clearAttachments = useCallback(() => setAttachments([]), []);

  const renderAttachments = useCallback(
    (showPreview?: boolean) => {
      return (
        <div className="ra-list" {...getRootProps()}>
          <UploadSpinner
            show={isUploading}
            text={t("communication.pleaseWaitAttachments")}
            filesProgress={uploadProgress}
          />
          <input {...getInputProps()} />
          {attachments.map(
            (attachment: FileWithUrl & { _id?: string }, index) => {
              const isFile = Boolean(attachment.url);
              return (
                <div
                  className="d-flex align-items-center flex-row ra"
                  key={attachment?._id || `${index} ${attachment.name}`}
                >
                  <div
                    className="attachment-wrapper"
                    key={attachment.name}
                  ></div>
                  {isFile && showPreview ? (
                    <AttachmentItem
                      showFileIcons={true}
                      remoteFile={{
                        ...attachment,
                        _id: `${index} ${attachment.name}`,
                        upload_url: attachment?.url as string,
                        url: attachment?.url as string,
                      }}
                    />
                  ) : (
                    showPreview && (
                      <AttachmentItem file={attachment} showFileIcons={true} />
                    )
                  )}
                  <div className="pr-2 ra-name">
                    <b>{attachment.name}</b>
                  </div>
                  <div className="pr-2">
                    <span>
                      ({t("common.fileSize", { amount: attachment.size })})
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center ra-remove"
                    onClick={() => onRemove(attachment)}
                  >
                    <Icon name="close" />
                  </div>
                </div>
              );
            }
          )}
        </div>
      );
    },
    [attachments, onRemove, isUploading, getRootProps, getInputProps]
  );

  const renderDropzone = useCallback(() => {
    return (
      <div
        {...getRootProps()}
        className="file-dropzone"
        onClick={openFilePicker}
      >
        <input {...getInputProps()} />
        <div className="field-text dropzone-container">
          <Icon className="mr-3" name="upload_file" outlined />
          <div>{t("common.dropZone")}</div>
        </div>
      </div>
    );
  }, [getRootProps, getInputProps]);

  return {
    attachments,
    clearAttachments,
    openFilePicker,
    uploadAttachments,
    uploadProgress,
    renderAttachments,
    renderDropzone,
    setAttachments,
  };
}
