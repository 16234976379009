import React from "react";
import { Route, Switch } from "react-router-dom";
// import Conversation from "../conversation";
import EmailsContainer from "../emails";
// import SmsContainer from "../sms";
import MessagesContainer from "../messages";

const CommunicationContainer = () => {
  return (
    <Switch>
      {/* <Route path="/communication/conversation" component={Conversation} /> */}
      {/* <Route path="/communication/email" component={EmailsContainer} /> */}
      {/* <Route path="/communication/sms" component={SmsContainer} /> */}
      <Route
        exact
        path="/communication/messages"
        component={MessagesContainer}
      />
      <Route path="/communication/messages/:id" component={MessagesContainer} />
    </Switch>
  );
};

export default CommunicationContainer;
