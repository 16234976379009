import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { NAVIGATION_ROUTES } from "../../components/dashboard/sidebar/utils/navigation-items";
import KanbanBoard, { Column } from "../../components/kanban-board";
import { CardOperation } from "../../components/kanban-board/utils";
import CreateUpdateLeadModal from "../../components/lead/create-update-lead-modal";
import Loader from "../../components/loader";
import SetNavigationRoute from "../../components/navigation/SetNavigationRoute";
import { notify } from "../../components/notification";
import {
  CREATE_UPDATE_LEAD,
  CREATE_UPDATE_LEAD_STAGE,
  DELETE_LEAD_STAGES,
  REORDER_LEAD_STAGES,
  RE_ORDER_LEADS,
} from "../../graphql/queries/leads/mutations";
import { LIST_LEAD_STAGES } from "../../graphql/queries/leads/queries";
import {
  CreateUpdateLead,
  CreateUpdateLeadPayload,
  CreateUpdateLeadResponse,
  CreateUpdateLeadStagePayload,
  CreateUpdateLeadStageResponse,
  DeleteLeadStagesdPayload,
  DeleteLeadStagesResponse,
  LeadSale,
  LeadStage,
  ListLeadStagesResponse,
  ReorderLeadsPayload,
  ReorderLeadsResponse,
  ReOrderLeadStagesdPayload,
  ReOrderLeadStagesResponse,
} from "../../models/leads";
import LeadCard from "./lead-card";
import {
  assigneesFilter,
  headInputFilter,
  findAllLabels,
  getAllLeads,
  labelFilter,
  prepareLeadObject,
} from "./utils";
import Dropdown from "../../components/dashboard/dropdown";
import { LIST_MEMBERS } from "../../graphql/members/queries";
import { ListMembersResponse } from "../../graphql/models/members";
import { each, find } from "lodash";
import { getFullName } from "../../utils/text";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../layouts/dashboard/DashboardContext";
import { HeaderSearchOption } from "../../components/dashboard/header-search";
import LeadsTable from "./leads-table";
import { useTranslation } from "react-i18next";
import "./style.scss";

type LeadsContainerProps = DashboardContextValue & RouteComponentProps & {};

type LeadsBoardFilters = {
  assignees: string;
  labels: string;
  gridView: boolean;
};

const Leads: React.FC<LeadsContainerProps> = ({ setSearchOptions }) => {
  const { t } = useTranslation();
  const [listStages, setListStages] = React.useState<LeadStage[]>([]);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [allLabels, setallLabels] = React.useState<string[] | []>([]);
  const [assigneeId, setAssigneeId] = React.useState("");
  const [labelId, setLabelId] = React.useState("");
  const history = useHistory();
  const [gridView, setGridView] = React.useState(false);
  const [boardFilters, setBoardFilters] = React.useState<LeadsBoardFilters>({
    assignees: "",
    labels: "",
    gridView: false,
  });

  const kanbanBoardFiltersId = "leadsKanbanBoardFilters";

  const [addColumn, { loading: loadingCreateColumn }] = useMutation<
    CreateUpdateLeadStageResponse,
    CreateUpdateLeadStagePayload
  >(CREATE_UPDATE_LEAD_STAGE, {
    refetchQueries: [{ query: LIST_LEAD_STAGES }],
    onCompleted: (data) => {
      if (listStages) {
        if (
          listStages
            .map((stage) => stage._id)
            .includes(data.createUpdateLeadStage._id)
        ) {
          return setListStages(
            listStages.map((stage) => {
              if (stage._id === data.createUpdateLeadStage._id) {
                return data.createUpdateLeadStage;
              }
              return stage;
            })
          );
        }
        setListStages([...listStages, data.createUpdateLeadStage]);
      }
    },
  });

  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const [deleteLeadStage] = useMutation<
    DeleteLeadStagesResponse,
    DeleteLeadStagesdPayload
  >(DELETE_LEAD_STAGES);

  const { data: listLeadStages, loading } = useQuery<ListLeadStagesResponse>(
    LIST_LEAD_STAGES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setListStages(data.listLeadStages);
        setallLabels(findAllLabels(data.listLeadStages));
      },
    }
  );
  const [getLeads, { data: tasksList, loading: tasksLoading }] = useLazyQuery<
    ListLeadStagesResponse
  >(LIST_LEAD_STAGES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setListStages(data.listLeadStages);
      setallLabels(findAllLabels(data.listLeadStages));
    },
  });

  const [reOrderStages] = useMutation<
    ReOrderLeadStagesResponse,
    ReOrderLeadStagesdPayload
  >(REORDER_LEAD_STAGES);

  const [reOrderLeads] = useMutation<ReorderLeadsResponse, ReorderLeadsPayload>(
    RE_ORDER_LEADS
  );

  const [createUpdateLead, { loading: loadingCreateUpdateLead }] = useMutation<
    CreateUpdateLeadResponse,
    CreateUpdateLeadPayload
  >(CREATE_UPDATE_LEAD);

  const handleAddColumn = React.useCallback(
    (column: { name: string; order: number; _id?: string }) => {
      addColumn({
        variables: {
          stage: column,
        },
      });
    },
    [addColumn]
  );

  const handleDeleteColumn = React.useCallback(
    (columnId: string) => {
      deleteLeadStage({
        variables: {
          leadStageId: columnId,
        },
      });
    },
    [deleteLeadStage]
  );

  const handleReorderStages = React.useCallback(
    (orderedList: string[]) => {
      reOrderStages({
        variables: {
          orderedList,
        },
      });
    },
    [reOrderStages]
  );

  const reorderLeadsInsideColumn = React.useCallback(
    (columnId, orderedList) => {
      reOrderLeads({
        variables: {
          leadStageId: columnId,
          orderedList,
        },
      });
    },
    [reOrderLeads]
  );

  const reorderLeadsBetweenColumn = React.useCallback(
    async (columnId, currentItem) => {
      const lead = prepareLeadObject(
        currentItem,
        columnId,
        CardOperation.UPDATE
      );

      await createUpdateLead({
        variables: {
          lead,
        },
      });
    },
    [createUpdateLead]
  );

  const hanldeColumnsChange = React.useCallback(
    (columns: Column<LeadSale>[]) => {
      const stages = columns.map((column) => ({
        ...column,
        leads: column.cards,
      }));
      setListStages(stages);
    },
    []
  );

  const handlePressLead = React.useCallback(
    (lead: LeadSale) => {
      history.push(`/leads/${lead._id}`);
    },
    [history]
  );

  const renderCard = (item: LeadSale, index: number) => {
    return (
      <LeadCard
        key={item._id}
        lead={item}
        index={index}
        onPress={handlePressLead}
      />
    );
  };

  const renderLeadsTable = () => {
    return <LeadsTable listStages={listStages} />;
  };

  const handleAssigneesFilter = React.useCallback(
    (id: string, listLeadStages: LeadStage[]) => {
      setAssigneeId(id);
      const filteredLeadsByAssignee = assigneesFilter(id, listLeadStages);
      setListStages(filteredLeadsByAssignee);
    },
    []
  );

  const handleLabelFilter = React.useCallback(
    (label: string, listLeadStages: LeadStage[]) => {
      setLabelId(label);
      const filteredLeads = labelFilter(label, listLeadStages);

      setListStages(filteredLeads);
    },
    []
  );

  React.useEffect(() => {
    const storedBoardFilters = localStorage.getItem(kanbanBoardFiltersId);

    if (storedBoardFilters) {
      try {
        const parsedFilters: LeadsBoardFilters = JSON.parse(storedBoardFilters);
        setBoardFilters(parsedFilters);
        if (listLeadStages?.listLeadStages) {
          if (parsedFilters.assignees && parsedFilters.labels) {
            const filteredByAssignees = assigneesFilter(
              parsedFilters.assignees,
              listLeadStages?.listLeadStages
            );
            handleLabelFilter(parsedFilters.labels, filteredByAssignees);
          } else if (parsedFilters.assignees) {
            handleAssigneesFilter(
              parsedFilters.assignees,
              listLeadStages?.listLeadStages
            );
          } else if (parsedFilters.labels) {
            handleLabelFilter(
              parsedFilters.labels,
              listLeadStages?.listLeadStages
            );
          }
        }
        setGridView(parsedFilters.gridView);
        setAssigneeId(parsedFilters.assignees);
        setLabelId(parsedFilters.labels);
      } catch {}
    } else {
      localStorage.setItem(kanbanBoardFiltersId, JSON.stringify(boardFilters));
    }
  }, [listLeadStages?.listLeadStages]);

  const storeBoardFilters = React.useCallback((values: LeadsBoardFilters) => {
    setBoardFilters(values);
    localStorage.setItem(kanbanBoardFiltersId, JSON.stringify(values));
  }, []);

  const toggleGridView = React.useCallback(() => {
    setGridView(!gridView);
    storeBoardFilters({ ...boardFilters, gridView: !gridView });
  }, [boardFilters, gridView, storeBoardFilters]);

  const assigneesOptions = React.useMemo(() => {
    const items = [
      {
        id: "all",
        label: t("leads.allAssignees"),
        onClick: () => {
          setAssigneeId("");
          storeBoardFilters({ ...boardFilters, assignees: "" });
          if (listLeadStages) {
            if (labelId) {
              const filteredByLabel = labelFilter(
                labelId,
                listLeadStages?.listLeadStages
              );
              setListStages(filteredByLabel);
            } else {
              setListStages(listLeadStages?.listLeadStages);
            }
          }
        },
      },
    ];
    each(teamData?.listMembers, (member) => {
      items.push({
        id: member._id,
        label: getFullName(member),
        onClick: () => {
          storeBoardFilters({ ...boardFilters, assignees: member._id });
          if (listLeadStages?.listLeadStages) {
            if (labelId) {
              const filteredByLabel = labelFilter(
                labelId,
                listLeadStages?.listLeadStages
              );
              handleAssigneesFilter(member._id, filteredByLabel);
            } else {
              handleAssigneesFilter(member._id, listLeadStages?.listLeadStages);
            }
          }
        },
      });
    });
    return items;
  }, [
    boardFilters,
    handleAssigneesFilter,
    labelId,
    listLeadStages,
    storeBoardFilters,
    t,
    teamData?.listMembers,
  ]);

  const selectedMember = React.useMemo(() => {
    if (!assigneeId) return t("leads.allAssignees");
    const member = find(teamData?.listMembers, { _id: assigneeId });
    return getFullName(member);
  }, [assigneeId, t, teamData?.listMembers]);

  const renderAssigneesFilter = React.useCallback(() => {
    return (
      <div className="pr-2">
        <Dropdown
          label={selectedMember}
          icon="expand_more"
          id="filter-assignees"
          items={assigneesOptions}
        />
      </div>
    );
  }, [selectedMember, assigneesOptions]);

  const selectedLabel = React.useMemo(() => {
    if (!labelId) return t("leads.allLabels");

    return labelId;
  }, [labelId]);

  const labelsOptions = React.useMemo(() => {
    const items = [
      {
        id: "all",
        label: t("leads.allLabels"),
        onClick: () => {
          setLabelId("");
          if (listLeadStages) {
            if (assigneeId) {
              const filteredByassignees = assigneesFilter(
                assigneeId,
                listLeadStages?.listLeadStages
              );
              setListStages(filteredByassignees);
            } else {
              setListStages(listLeadStages?.listLeadStages);
            }
          }
          storeBoardFilters({ ...boardFilters, labels: "" });
        },
      },
    ];
    each(allLabels, (label) => {
      items.push({
        id: label,
        label: label,
        onClick: () => {
          if (listLeadStages?.listLeadStages) {
            if (assigneeId) {
              const filteredByAssignees = assigneesFilter(
                assigneeId,
                listLeadStages?.listLeadStages
              );
              handleLabelFilter(label, filteredByAssignees);
            } else {
              handleLabelFilter(label, listLeadStages?.listLeadStages);
            }
          }
          storeBoardFilters({ ...boardFilters, labels: label });
        },
      });
    });
    return items;
  }, [
    allLabels,
    assigneeId,
    boardFilters,
    handleLabelFilter,
    listLeadStages,
    storeBoardFilters,
    t,
  ]);

  const renderLabelsFilter = React.useCallback(() => {
    return (
      <div className="pr-2">
        <Dropdown
          label={selectedLabel}
          icon="expand_more"
          id="filter-assignees"
          items={labelsOptions}
        />
      </div>
    );
  }, [labelsOptions, selectedLabel]);

  const renderFilters = () => {
    return (
      <>
        {renderAssigneesFilter()}
        {renderLabelsFilter()}
      </>
    );
  };

  const openCreateModal = React.useCallback(() => {
    setShowCreateModal(true);
  }, [setShowCreateModal]);

  const closeCreateModal = React.useCallback(() => {
    setShowCreateModal(false);
  }, [setShowCreateModal]);

  const handlePressNewLead = React.useCallback(() => {
    openCreateModal();
  }, [openCreateModal]);

  const handleAddLead = React.useCallback(
    async (data: CreateUpdateLead) => {
      try {
        if (!data) {
          return;
        }

        await createUpdateLead({
          variables: {
            lead: data,
          },
        });
        getLeads();
        closeCreateModal();
        notify({
          title: t("leads.createNewLead"),
          content: t("leads.success.createLead"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("leads.createNewLead"),
          content: t("leads.errors.addLead"),
        });
      }
    },
    [createUpdateLead, getLeads, closeCreateModal]
  );

  const searchOptions = React.useMemo(() => {
    if (listLeadStages?.listLeadStages) {
      const allLeads = getAllLeads(listLeadStages?.listLeadStages);
      return allLeads.map((lead) => {
        const contacts = lead.contacts
          .map((contact) => getFullName(contact))
          .join(" ");
        const labels = Boolean(lead.tags)
          ? lead.tags.map((tag) => tag.name).join(" ")
          : " ";
        const values = `${lead.name} ${contacts} ${labels}`;

        return {
          value: lead._id,
          label: lead.name,
          values,
        };
      });
    }
  }, [listLeadStages?.listLeadStages]);

  const handleSearchSelect = React.useCallback(
    (option: HeaderSearchOption | null) => {
      if (!option) return;
      history.push(`/leads/${option.value}`);
    },
    [history]
  );

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("leads.searchLead"),
      options: searchOptions ?? [],
      onSelect: handleSearchSelect,
      filterOptions: headInputFilter,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [setSearchOptions, searchOptions, handleSearchSelect]);

  if (loading) {
    return (
      <div className="lead loader-center">
        <Loader />
      </div>
    );
  }
  return (
    <Container className="lead" fluid>
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.LEADS} />
      <Helmet title={t("navigation.leads")} />
      <div className="inner-container">
        <KanbanBoard<LeadSale>
          renderCard={renderCard}
          renderFilters={renderFilters}
          columns={listStages.map((list) => ({ ...list, cards: list.leads }))}
          onColumnsChange={hanldeColumnsChange}
          addColumn={handleAddColumn}
          deleteColumn={handleDeleteColumn}
          reOrderColumn={handleReorderStages}
          reOrderCardsInsideColumn={reorderLeadsInsideColumn}
          reOrderCardsBetweenColumn={reorderLeadsBetweenColumn}
          addCard={handlePressNewLead}
          addButtonPlaceholder={t("leads.newLead")}
          renderLeadsTable={renderLeadsTable}
          gridView={gridView}
          toggleGridView={toggleGridView}
        />
        <CreateUpdateLeadModal
          data={null}
          listStages={listStages}
          show={showCreateModal}
          onClose={closeCreateModal}
          onSubmit={handleAddLead}
        />
      </div>
    </Container>
  );
};
export default withRouter(withDashboardContext(Leads));
