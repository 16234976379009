import { gql } from "@apollo/client";

export const FOLDER_FRAGMENT = gql`
  fragment FolderFragment on Folder {
    _id
    name
    createdAt
    parentTree {
      _id
      name
      accessRole
      accessSelectedUsers {
        _id
      }
    }
    subFolders {
      _id
      name
      createdAt
      accessRole
      accessSelectedUsers {
        _id
      }
    }
    documents {
      _id
      name
      folder {
        _id
        name
      }
      type
      size
      width
      height
      url
      upload_url
      createdAt
      accessRole
      accessSelectedUsers {
        _id
      }
    }
  }
`;
