import React from "react";
import { InputProps } from "react-select";
import Form from "react-bootstrap/Form";

type AutocompleteSelectInputProps = InputProps & {};

const AutocompleteSelectInput: React.FC<AutocompleteSelectInputProps> = (
  props
) => {
  const { error, touched, name, placeholder } = (props as any).selectProps;
  const { value, type, onChange, onBlur } = props as any;

  return (
    <Form.Control
      autoComplete={`${+new Date()}`}
      value={value}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      name={`${name}${+new Date()}`}
      isInvalid={touched && !!error}
      className="input"
    />
  );
};

export default AutocompleteSelectInput;
