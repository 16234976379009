import React, { useState } from "react";
import { map, compact, pick } from "lodash";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import Icon from "../../icons/Icon";
import { SpecificationCategory } from "../../../models/specification";
import SpecificationCategoryItem from "./SpecificationCategoryItem";
import Dropdown from "../../dashboard/dropdown";
import { SortableItem } from "../../sortable-list/types";
import SortableList, { SortableListRef } from "../../sortable-list";
import "./styles.scss";
import { ConnectDragSource } from "react-dnd";

type SpecificationCategoriesProps = {
  addButtonPlacement?: "top" | "bottom" | "none";
  displayNavButtons?: boolean;
  categories?: SpecificationCategory[];
  onBackClick?: () => void;
  onNextClick?: () => void;
  onDelete?: (specCategoryId: string) => void;
  onAddClick?: () => void;
  onSort?: (ordered: SpecificationCategory[]) => void;
  disabled?: boolean;
  selectedCategory: SpecificationCategory | null;
  selectCategory?: (category: SpecificationCategory) => void;
  onImportFromCosting?: () => void;
  onSaveAsTemplate?: () => void;
  onImportFromTemplate?: () => void;
};

const SpecificationCategories: React.FC<SpecificationCategoriesProps> = ({
  addButtonPlacement = "top",
  displayNavButtons = true,
  categories,
  onBackClick,
  onNextClick,
  onDelete,
  onAddClick,
  onSort,
  disabled = false,
  selectedCategory,
  selectCategory,
  onImportFromCosting,
  onSaveAsTemplate,
  onImportFromTemplate,
}) => {
  const { t } = useTranslation();

  const [dropdownShown, setDropdownShown] = useState(false);
  const listRef = React.useRef<SortableListRef>(null);

  const toggleDropdown = React.useCallback(() => {
    setDropdownShown(!dropdownShown);
  }, [dropdownShown]);

  const sortableItems = React.useMemo((): SortableItem[] => {
    return map(categories, (category) => ({
      id: category._id,
      item: category,
    }));
  }, [categories]);

  const onDrop = React.useCallback(() => {
    if (!onSort) return;
    const newOrder = listRef.current?.getOrder()?.map(
      ({ item }, order) =>
        ({
          ...item,
          order,
        } as SpecificationCategory)
    );
    if (newOrder) {
      onSort(newOrder);
    }
  }, [onSort, listRef]);

  const renderItem = React.useCallback(
    (data: SortableItem, dragRef: ConnectDragSource) => {
      const { item } = data;
      const category = item as SpecificationCategory;
      return (
        <SpecificationCategoryItem
          key={category._id}
          category={category}
          onDelete={!disabled ? onDelete : undefined}
          onClick={selectCategory}
          isSelected={category._id === selectedCategory?._id}
          dragRef={dragRef}
        />
      );
    },
    [selectedCategory, selectCategory, disabled, onDelete]
  );

  const renderCategories = React.useCallback(() => {
    return (
      <div className="quote-specification-list">
        <SortableList
          ref={listRef}
          onDrop={onDrop}
          items={sortableItems}
          renderItem={renderItem}
        />
      </div>
    );
  }, [listRef, sortableItems, onDrop, renderItem]);

  const dropdownItems = React.useMemo(() => {
    return compact([
      onImportFromCosting &&
        !disabled && {
          id: "import",
          label: t("specifications.importFromCosting"),
          icon: "attach_money",
          name: "import",
          outlined: true,
          onClick: onImportFromCosting,
        },
      onSaveAsTemplate &&
        !disabled && {
          id: "saveAsTemplate",
          label: t("specifications.saveAsTemplate"),
          icon: "save",
          name: "saveAsTemplate",
          outlined: true,
          onClick: onSaveAsTemplate,
        },
      onImportFromTemplate &&
        !disabled && {
          id: "importFromTemplate",
          label: t("specifications.importFromTemplate"),
          icon: "import_export",
          name: "importFromTemplate",
          outlined: true,
          onClick: onImportFromTemplate,
        },
    ]);
  }, [
    onImportFromCosting,
    disabled,
    t,
    onSaveAsTemplate,
    onImportFromTemplate,
  ]);

  return (
    <DashboardCard className="quote-specification-categories">
      <DashboardCardHeader className="text-capitalize justify-content-between">
        <div className="d-flex align-items-left">{t("common.categories")}</div>

        {dropdownItems.length > 0 && (
          <Dropdown
            isVisible={dropdownShown}
            handleToggle={toggleDropdown}
            label={t("common.options")}
            icon="expand_more"
            size="250px"
            id="specification-dropdown"
            items={dropdownItems}
          />
        )}
      </DashboardCardHeader>
      <DashboardCardBody>
        <div className="quote-specification-body">
          {addButtonPlacement === "top" && (
            <Button
              disabled={disabled}
              className="button info add-category"
              block
              onClick={onAddClick}
            >
              {t("quotes.addCategory")}
            </Button>
          )}
          {renderCategories()}
        </div>
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end">
        {displayNavButtons && (
          <>
            <Button
              className="button info mr-2"
              variant="secondary"
              onClick={onBackClick}
            >
              {t("common.back")}
            </Button>
            <Button className="button success" onClick={onNextClick}>
              {t("common.next")}
            </Button>
          </>
        )}
        {addButtonPlacement === "bottom" && (
          <Button
            className="button success large"
            onClick={onAddClick}
            disabled={disabled}
          >
            <Icon name="add" />
            {t("common.createNew")}
          </Button>
        )}
      </DashboardCardFooter>
    </DashboardCard>
  );
};

export default SpecificationCategories;
