import React from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  QuoteRequestListResponse,
  QuoteRequestResponse,
} from "../../graphql/types/models/quote-request";
import {
  GET_QUOTE_REQUEST,
  LIST_QUOTE_REQUESTS,
} from "../../graphql/queries/quote-request/queries";

type QuoteRequestFilter = {
  jobId?: string;
  salesQuoteId?: string;
};
export function useQuoteRequestsQuery(filter: QuoteRequestFilter) {
  const { data, loading } = useQuery<QuoteRequestListResponse>(
    LIST_QUOTE_REQUESTS,
    {
      variables: {
        filter,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    getQuoteRequest,
    { loading: quoteRequestLoading, data: quoteRequestData },
  ] = useLazyQuery<QuoteRequestResponse>(GET_QUOTE_REQUEST);

  const quoteRequests = React.useMemo(() => data?.listQuoteRequests || [], [
    data,
  ]);

  const quoteRequest = React.useMemo(
    () => quoteRequestData?.getQuoteRequestById,
    [quoteRequestData]
  );

  return { quoteRequests, getQuoteRequest, quoteRequest };
}
