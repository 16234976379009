import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardCard from "../../../components/dashboard/card";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { SystemFolderType } from "../../../models/documents";
import JobLocationHeader from "../../header/job-location-header";
import ClientHeaderList from "../../header/client-header-list";

import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import TeammateHeaderList from "../../header/teammate-header-list";
import { useDocuments } from "../../../hooks/useDocuments";

import "./styles.scss";

type Params = {
  folderId: string;
};
type JobDocumentsProps = RouteComponentProps<Params> &
  DashboardContextValue & {
    user: UserPayload | null;
  };

const JobDocuments: React.FC<JobDocumentsProps> = ({
  navigationContext,
  match,
  user,
}) => {
  const jobId = navigationContext?.job?._id;
  const { t } = useTranslation();
  const {
    renderDocumentModals,
    renderDocumentsTableComponent,
    renderDocumentsHeader,
  } = useDocuments({
    id: jobId,
    systemFolderType: SystemFolderType.JOB,
    user,
    route: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
    setAccessPermissions: true,
  });

  return (
    <Container fluid>
      <Helmet title={t("navigation.jobsSection.documents")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.DOCUMENTS} />
      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      {renderDocumentModals()}
      <Row>
        <Col xs={12} className="pb-sm-5 pb-lg-0">
          <DashboardCard className="job-documents-wrapper">
            {renderDocumentsHeader()}
            {renderDocumentsTableComponent()}
          </DashboardCard>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(JobDocuments));
