import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import {
  CreateFolderPayload,
  EnumDocumentAccessRole,
} from "../../../models/documents";
import { TFunction } from "i18next";
import { map } from "lodash";
import { TeamMember } from "../../../models/team-member";
import { getFullName } from "../../../utils/text";

export const createDocumentField = (
  t: TFunction,
  handleRestrictAccessChange?: UpdateTextInputFn,
  setAccessPermissions?: boolean,
  isActiveRestrictAccess?: boolean,
  team?: TeamMember[]
): GenericFormFields<CreateFolderPayload> => {
  const restrictAccessOptions = Object.keys(EnumDocumentAccessRole).map(
    (option) => ({
      label: t(`documents.documentAccessRoles.${option}`),
      value: option,
    })
  );

  const teamOptions = map(team, (member) => ({
    value: member._id,
    label: getFullName(member),
  }));

  return {
    name: {
      type: "text",
      label: t("common.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    ...(setAccessPermissions && {
      accessRole: {
        type: "select-autocomplete",
        label: t("documents.restrictAccess"),
        placeholder: t("documents.restrictAccess"),
        valueKey: "accessRole",
        autocompleteProps: {
          options: restrictAccessOptions,
        },
        inputProps: {
          required: true,
        },
        textInputProps: {
          onValueChange: handleRestrictAccessChange,
        },
      },
    }),
    ...(isActiveRestrictAccess && {
      accessSelectedUsers: {
        type: "creatable-select",
        label: t("documents.accessUsers"),
        placeholder: t("documents.accessUsers"),
        valueKey: "accessSelectedUsers",
        creatableProps: {
          isMulti: true,
          options: teamOptions,
        },
        inputProps: {
          required: true,
        },
      },
    }),
  };
};
