import { gql } from "@apollo/client";
import { SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT } from "./fragments";

export const UPDATE_SALES_QUOTE_PRINT_TEMPLATE = gql`
  mutation($template: SalesQuoteTemplateInput!) {
    createUpdateSalesQuotePrintTemplate(template: $template) {
      ...SalesQuotePrintTemplateFragment
    }
  }
  ${SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT}
`;

export const COPY_SALES_QUOTE_PRINT_TEMPLATE = gql`
  mutation($printTemplateId: ID!, $name: String!) {
    copySalesQuotePrintTemplate(
      printTemplateId: $printTemplateId
      name: $name
    ) {
      ...SalesQuotePrintTemplateFragment
    }
  }
  ${SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT}
`;

export const PREVIEW_TEMPALTE_FOR_SALES_QUOTE = gql`
  mutation(
    $salesQuoteId: ID!
    $template: SalesQuoteTemplateInput!
    $section: EnumSalesQuoteDesignerSection!
  ) {
    previewTemplateForSalesQuote(
      salesQuoteId: $salesQuoteId
      template: $template
      section: $section
    ) {
      pdf
    }
  }
`;

export const DELETE_SALES_QUOTE_PRINT_TEMPLATE = gql`
  mutation($printTemplateId: ID!) {
    deleteSalesQuotePrintTemplate(printTemplateId: $printTemplateId) {
      ...SalesQuotePrintTemplateFragment
    }
  }
  ${SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT}
`;
