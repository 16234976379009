import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import ClientQuoteSelections from "../selections";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";

type Params = {
  id: string;
};

type QuoteContainerProps = RouteComponentProps<Params> & DashboardContextValue;

const ClientQuoteContainer: React.FC<QuoteContainerProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { id: quoteId } = match.params;

  React.useEffect(() => {
    setNavigationContext({
      ...navigationContext,
      clientQuote: {
        _id: quoteId,
      },
    });
  }, [setNavigationContext, quoteId]);

  React.useEffect(() => {
    return () => {
      setNavigationContext({
        ...navigationContext,
        clientQuote: undefined,
      });
    };
  }, []);

  if (!navigationContext?.clientQuote) return null;

  return (
    <Switch>
      <Route path="/quotes/:id/selections" component={ClientQuoteSelections} />
    </Switch>
  );
};

export default withDashboardContext(ClientQuoteContainer);
