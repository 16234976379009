import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

const createChangePwdSchema = (t: TFunction) => {
  return yup.object().shape({
    old_password: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "profile.currentPassword"
        )
      ),
    new_password: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "profile.newPassword")
      ),
    confirm_new_password: yup
      .string()
      .oneOf(
        [yup.ref("new_password"), ""],
        t("authentication.errors.confirmPassword")
      )
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "profile.confirmNewPassword"
        )
      ),
  });
};

export default createChangePwdSchema;
