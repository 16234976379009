import React from "react";
import classNames from "classnames";
import "./styles.scss";
import SidebarNavigation from "./SidebarNavigation";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../containers/layouts/dashboard/DashboardContext";

type SidebarProps = RouteComponentProps &
  DashboardContextValue & {
    isActiveExpandButton: boolean;
    setIsSidebarMouseEnter: (value: boolean) => void;
  };

const Sidebar: React.FC<SidebarProps> = ({
  isSideMenuExpanded,
  setSideMenuExpanded,
  navigationItem,
  navigationItems,
  setNavigationItem,
  isActiveExpandButton,
  setIsSidebarMouseEnter,
}) => {
  const handleSidebarMouseEnter = React.useCallback(() => {
    if (isActiveExpandButton) {
      setIsSidebarMouseEnter(true);
      return;
    }
    setSideMenuExpanded(true);
  }, [isActiveExpandButton, setSideMenuExpanded]);

  const handleSidebarMouseLeave = React.useCallback(() => {
    if (isActiveExpandButton) {
      setIsSidebarMouseEnter(false);
      return;
    }
    setSideMenuExpanded(false);
  }, [isActiveExpandButton, setSideMenuExpanded]);

  return (
    <div
      className={classNames("dashboard-sidebar", {
        minified: !isSideMenuExpanded,
      })}
      onMouseEnter={handleSidebarMouseEnter}
      onMouseLeave={handleSidebarMouseLeave}
    >
      <div className="header">
        <div className="logo">
          {!isSideMenuExpanded ? (
            <img src="/assets/wunderbuild-mini-logo.svg" alt="" />
          ) : (
            <img src="/assets/header-logo.svg" alt="" />
          )}
        </div>
      </div>
      <SidebarNavigation
        navigationItem={navigationItem}
        isMinified={!isSideMenuExpanded}
        navigationItems={navigationItems}
        onNavigationItemClick={setNavigationItem}
      />
    </div>
  );
};

export default withRouter(withDashboardContext(Sidebar));
