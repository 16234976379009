export const removeNode = (id: string, items: any) => {
  for (const node of items) {
    if (node.id == id) {
      items.splice(items.indexOf(node), 1);
      return;
    }

    if (node.items && node.items.length) {
      removeNode(id, node.items);
    }
  }
};

export const findItem = (id: string, items: any) => {
  for (const node of items) {
    if (node.id == id) return node;
    if (node.items?.length) {
      const result = findItem(id, node.items) as any;
      if (result) {
        return result;
      }
    }
  }

  return false;
};
