import { gql } from "@apollo/client";

export const CLAIM_FRAGMENT = gql`
  fragment ProgressClaimFragment on ProgressClaim {
    _id
    invNumber
    claimDate
    dueDate
    status
    isOverdue
    description
    richComment {
      text
      html
      raw
    }
    job {
      _id
    }
    amount
    percentage
    retainedAmount
    retainedGST
    variationAmount
    subTotal
    GST
    total
    markupSubTotal
    markupGST
    markupTotal
    invoiceSubTotal
    invoiceGST
    invoiceTotal
    outstandingTotal
    receivedTotal
    createdAt
    hideItems
    defaultView
    receivedDate
    receivedNote
    externalLastSyncDate
    receipts {
      _id
      reference
      dateReceived
      note
      amount
    }
    variations {
      _id
      subTotal
      GST
      total
      date
      name
      variationNumber
    }
    items {
      _id
      name
      amount
      percentage
      amountToDate
      percentageToDate
      hasGST
      markup
      markupSubTotal
      markupGST
      markupTotal
      total
      remaining
      note
      costingItemId
      purchaseOrderId
      createdAt
      updatedAt
      purchaseOrder {
        _id
        orderNumber
        reference
        GST
        supplier {
          _id
          business_name
          contact_person
        }
        items {
          _id
          name
          cost
          quantity
          hasGST
        }
      }
      purchaseOrderReceipt {
        _id
        reference
        dateReceived
        hasGST
        subTotal
        GST
        total
        items {
          _id
          itemNumber
          name
          UOM
          cost
          margin_amount
          quantity
          hasGST
        }
        purchaseOrder {
          _id
          orderNumber
          reference
          GST
          supplier {
            _id
            business_name
            contact_person
          }
          items {
            _id
            name
            cost
            quantity
          }
        }
      }
      costingItem {
        _id
        name
        cost
        quantity
      }
    }
    contact {
      _id
      first_name
      last_name
      email
    }
  }
`;
