import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { map, take } from "lodash";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import HeaderTitle from "../../../components/dashboard/header/HeaderTitle";
import HeaderPageAction from "../../../components/dashboard/header/HeaderPageAction";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import Dropdown from "../../../components/dashboard/dropdown";
import { ListJobsResponse } from "../../../graphql/types/models/job";
import { LIST_JOBS } from "../../../graphql/queries/job/queries";
import "./styles.scss";
import { formatQuoteNumber } from "../../../utils/text";

type JobLocationHeaderProps = DashboardContextValue;

const JobLocationHeader: React.FC<JobLocationHeaderProps> = (props) => {
  const { navigationContext } = props;

  const history = useHistory();
  const match = useRouteMatch();
  const jobName = `${formatQuoteNumber(
    Number(navigationContext?.job?.jobNumber),
    "J"
  )} ${navigationContext?.job?.name}`;
  const { data: jobsList } = useQuery<ListJobsResponse>(LIST_JOBS);

  const [showDropdown, setDropdownVisibility] = useState(false);

  const handleToggle = React.useCallback(() => {
    setDropdownVisibility(!showDropdown);
  }, [showDropdown]);

  const jobOptions = React.useMemo(() => {
    const jobs = jobsList?.listJobs;

    return map(jobs, (job) => ({
      id: job._id,
      label: `${formatQuoteNumber(Number(job?.jobNumber), "J")} ${job?.name}`,
      onClick: () => {
        const path = take(match.path.split("/"), 4).join("/");
        const url = generatePath(path, { id: job._id });
        history.push(url);
      },
    }));
  }, [jobsList]);

  return (
    <HeaderPageAction order="3">
      <HeaderTitle>
        <Dropdown
          iconFirst
          toggleClass="job-navigation-toggle"
          isVisible={showDropdown}
          handleToggle={handleToggle}
          label={jobName}
          outlined
          size="340px"
          id="job-navigation-toolbar"
          items={jobOptions}
          menuWidth="auto"
        />
      </HeaderTitle>
    </HeaderPageAction>
  );
};

export default withDashboardContext(JobLocationHeader);
