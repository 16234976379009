import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { get } from "lodash";
import { notify } from "../components/notification";
import { LIST_SPEC_TEMPLATES } from "../graphql/queries/specification/queries";
import {
  CreateUpdateSpecTemplateResponse,
  DeleteSpecTemplateResponse,
  ImportSpecFromTemplateResponse,
  ListSpecTemplatesResponse,
  SaveSpecAsTemplatePayload,
  SaveSpecAsTemplateResponse,
} from "../models/specification";
import {
  CREATE_UPDATE_SPEC_TEMPLATE,
  DELETE_SPEC_TEMPLATE,
  IMPORT_SPEC_TEMPLATE,
  SAVE_SPEC_AS_TEMPLATE,
} from "../graphql/queries/specification/mutation";
import {
  handleSpecTemplateCreateUpdate,
  handleSpecTemplateDelete,
  handleSpecTemplateSave,
} from "../graphql/queries/specification/utils";

export type SpecificationsTemplateProps = {
  jobId?: string;
  onImport?: () => void;
  onSave?: () => void;
};

export function useSpecificationsTemplate(props?: SpecificationsTemplateProps) {
  const { t } = useTranslation();
  const {
    data: templatesData,
    loading: templatesDataLoading,
    refetch: refetchSpecTemplates,
  } = useQuery<ListSpecTemplatesResponse>(LIST_SPEC_TEMPLATES, {
    fetchPolicy: "cache-and-network",
  });

  const [importSpecFromTemplate] = useMutation<ImportSpecFromTemplateResponse>(
    IMPORT_SPEC_TEMPLATE,
    {
      onCompleted: (data) => {
        notify({
          content: t("specifications.success.importFromTemplate"),
          title: t("specifications.importFromTemplate"),
        });
        props?.onImport?.();
      },
      onError: (e) => {
        notify({
          error: true,
          content: get(
            e,
            "message",
            t("specifications.errors.importFromTemplate")
          ),
          title: t("specifications.importFromTemplate"),
        });
      },
    }
  );

  const [deleteSpecTemplate] = useMutation<DeleteSpecTemplateResponse>(
    DELETE_SPEC_TEMPLATE,
    {
      onCompleted: (data) => {
        notify({
          content: t("specifications.success.deleteTemplate"),
          title: t("specifications.deleteTemplate"),
        });
      },
      onError: (e) => {
        notify({
          error: true,
          content: get(e, "message", t("specifications.errors.deleteTemplate")),
          title: t("specifications.deleteTemplate"),
        });
      },
      update: handleSpecTemplateDelete,
    }
  );

  const [saveSpecAsTemplate] = useMutation<
    SaveSpecAsTemplateResponse,
    SaveSpecAsTemplatePayload
  >(SAVE_SPEC_AS_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("specifications.success.saveAsTemplate"),
        title: t("specifications.saveAsTemplate"),
      });
      props?.onSave?.();
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("specifications.errors.saveAsTemplate")),
        title: t("specifications.saveAsTemplate"),
      });
    },
    update: handleSpecTemplateSave,
  });

  const [createUpdateSpecTemplate] = useMutation<
    CreateUpdateSpecTemplateResponse
  >(CREATE_UPDATE_SPEC_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("specifications.success.saveAsTemplate"),
        title: t("specifications.saveAsTemplate"),
      });
      props?.onSave?.();
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("specifications.errors.saveAsTemplate")),
        title: t("specifications.saveAsTemplate"),
      });
    },
    update: handleSpecTemplateCreateUpdate,
  });

  const specTemplates = React.useMemo(
    () => templatesData?.listSpecTemplates || [],
    [templatesData]
  );

  return {
    specTemplates,
    templatesDataLoading,
    saveSpecAsTemplate,
    refetchSpecTemplates,
    importSpecFromTemplate,
    deleteSpecTemplate,
    createUpdateSpecTemplate,
  };
}
