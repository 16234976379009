import { gql } from "@apollo/client";
import { JOB_COSTING_ITEM_FRAGMENT } from "./fragments";

export const LIST_JOB_COSTINGS = gql`
  query GetJobCostings($jobId: ID!) {
    getJobCostings(jobId: $jobId) {
      _id
      name
      totalRealCost
      totalEstimatedCost
      totalMarkup
      can_timesheet
      is_restricted_entry
      is_locked
      items {
        ...JobCostingItemFragment
      }
    }
  }
  ${JOB_COSTING_ITEM_FRAGMENT}
`;

export const LIST_JOB_COSTINGS_BASIC = gql`
  query($jobId: ID!, $filter: JobCostingFilterInput) {
    listJobCostingsBasic(jobId: $jobId, filter: $filter) {
      _id
      name
      items {
        _id
        name
        UOM
      }
    }
  }
`;

export const LIST_COSTINGS_CATEGORIES = gql`
  query GetJobCostings($jobId: ID!) {
    categoryList: getJobCostings(jobId: $jobId) {
      _id
      name
      can_timesheet
    }
  }
`;

export const JOB_TOTALS = gql`
  query getJobTotals($jobId: ID!) {
    totals: getJobTotals(jobId: $jobId) {
      totalEstimated
      totalEstimatedWithMarkup
      totalEstimatedWithMarkupGST
      totalRealCost
      totalVariation
      totalVariationGST
      totalVariationClaimed
      totalVariationClaimedGST
      totalVariationItemClaimed
      totalVariationItemClaimedGST
      totalClaimed
      totalClaimedGST
      totalClaimedPercent
      totalClaims
      totalClaimsGST
      completionPercent
      totalProfit
      totalRetained
      totalRetainedGST
      totalRetainable
      totalRetainableGST
      totalRetentionClaimed
      totalRetentionClaimedGST
      totalClaimedPercent
      totalVariancePercent
      totalExpectedRealCost
      totalGrossProfit
      totalGrossMargin
      totalRevenue
      totalWIP
    }
  }
`;

export const GET_JOB_BILL_OF_QUANTITY_PREVIEW = gql`
  query($jobId: ID) {
    getJobBillOfQuantityPreview(jobId: $jobId) {
      _id
      pdf
    }
  }
`;

export const EXPORT_JOB_COSTINGS = gql`
  query($jobId: ID!) {
    exportJobCostings(jobId: $jobId) {
      _id
      data
      filename
    }
  }
`;

export const PRINT_JOB_COSTINGS = gql`
  query(
    $jobId: ID!
    $type: EnumJobCostingPrintType!
    $options: JobCostingPrintOptionsInput!
  ) {
    printJobCostings(jobId: $jobId, type: $type, options: $options) {
      _id
      pdf
    }
  }
`;
