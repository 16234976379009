import { gql } from "@apollo/client";
import { SIGNATURE_FRAGMENT } from "../../job-variation/fragments";

export const CLIENT_APPROVE_VARIATION = gql`
  mutation ClientApproveVariation(
    $jobId: ID!
    $variationId: ID!
    $signature: DigitalSignatureInput!
  ) {
    clientApproveVariation(
      jobId: $jobId
      variationId: $variationId
      signature: $signature
    ) {
      _id
      status
      clientSignature {
        ...SignatureFragment
      }
      builderSignature {
        ...SignatureFragment
      }
    }
  }
  ${SIGNATURE_FRAGMENT}
`;
