import { gql } from "@apollo/client";
import { SPEC_CATEGORY_FRAGMENT, SPEC_TEMPATE_FRAGMENT } from "./fragments";

export const LIST_SPEC_CATEGORIES = gql`
  query getSpecBySalesQuoteId($salesQuoteId: ID!) {
    getSpecBySalesQuoteId(salesQuoteId: $salesQuoteId) {
      ...SpecCategoryFragment
    }
  }
  ${SPEC_CATEGORY_FRAGMENT}
`;

export const LIST_SPEC_TEMPLATES = gql`
  query {
    listSpecTemplates {
      ...SpecTemplateFragment
    }
  }
  ${SPEC_TEMPATE_FRAGMENT}
`;

export const GET_SPEC_TEMPLATE = gql`
  query getSpecTemplate($templateId: ID!) {
    getSpecTemplate(templateId: $templateId) {
      ...SpecTemplateFragment
    }
  }
  ${SPEC_TEMPATE_FRAGMENT}
`;
