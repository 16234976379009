import React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { chain, compact } from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import ModalForm from "../../modals/modal-form";
import moment from "moment";
import { calcTotalTimesheetValue } from "../../../utils/calculations";
import {
  FormikPropGetSetValues,
  GenericFormFields,
  GenericFormTable,
  renderTableForm,
} from "../../generic-form/GenericFormBody";
import createProcessSchema from "./Process.schema";
import createProcessFields from "./utils";
import ModalTotals from "../../modals/modal-totals";
import {
  JobTimesheetTableItem,
  ProcessTimesheetModalFormItem,
  ProcessTimesheetModalForm,
} from "../../../models/job-timesheet";
import { formatDuration } from "../../../utils/date";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";
import {
  CostingAllocationType,
  useJobCostingAllocate,
} from "../../../hooks/useJobCostingAllocate";
import { getMinutesfromBreakDuration } from "../../../utils/options";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import "./styles.scss";
import { LIST_JOB_COSTINGS_BASIC } from "../../../graphql/queries/job-costing/queries";
import { ListJobCostingsBasicResponse } from "../../../graphql/types/models/job-costing";
import { JobCostingCategoryBasic } from "../../../models/job";

type ProcessTimesheetModalProps = {
  jobTimesheetItems: JobTimesheetTableItem[] | null;
  jobId: string | null | undefined;
  onSubmit: (values: ProcessTimesheetModalFormItem[]) => void;
};

const ProcessTimesheetModal: React.FC<ProcessTimesheetModalProps> = (
  props,
  ref
) => {
  const { onSubmit, jobTimesheetItems, jobId } = props;

  const { t } = useTranslation();

  const { shouldShow, hide } = useModalDisplay(ref);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<ProcessTimesheetModalForm>
  >({});
  const [timesheetJobId, setTimesheetJobId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const confirmRef = React.useRef<ConfirmDialogRef>(null);

  const {
    categoryList,
    handleSetCostingOutside,
    openAllocateCosting,
    getAllocateProps,
    handleCreateCategory,
    handleSelectCategory,
    renderSelectCostingModal,
    client,
  } = useJobCostingAllocate({
    type: CostingAllocationType.Timesheet,
    jobId,
    isTable: true,
  });

  const filteredCategoryList = React.useMemo(() => {
    return timesheetJobId
      ? (categoryList as SelectOption[]).map((option) => ({
          ...option,
          hidden: option?.data !== timesheetJobId,
        }))
      : categoryList;
  }, [categoryList, timesheetJobId]);

  const handleDateChange = React.useCallback(
    (fieldValue, rowIndex, formikProps) => {
      if (!fieldValue) return;
      const { setFieldValue, values } = formikProps;
      const row = values.items[rowIndex];
      const startTime = moment(row.startTime);
      const endTime = moment(row.endTime);
      const duration = formatDuration(
        endTime.subtract(row.breakDuration, "minutes").diff(startTime),
        t
      );
      setFieldValue(`items[${rowIndex}].duration`, duration);
    },
    []
  );

  const hendleMenuOpen = React.useCallback(
    (formikProps?: FormikPropGetSetValues, rowIndex?: number) => {
      if (!rowIndex && rowIndex !== 0) return;
      const currentItem = formikProps?.values["items"][rowIndex];
      const timesheetjobId = currentItem?.job?._id;
      if (timesheetjobId) {
        setTimesheetJobId(timesheetjobId);
      }
    },
    []
  );

  const getAllCostings = React.useCallback(
    async (jobIds: string[]) => {
      try {
        setIsLoading(true);
        const allLists = jobIds.map(async (jobId) => {
          return {
            ...(await client.query<ListJobCostingsBasicResponse>({
              query: LIST_JOB_COSTINGS_BASIC,
              variables: {
                jobId,
                filter: { type: CostingAllocationType.Timesheet },
              },
            })),
            jobId,
          };
        });
        Promise.all(allLists).then((response) => {
          const costingItems: JobCostingCategoryBasic[] = [];
          response.forEach((item) => {
            item.data.listJobCostingsBasic?.forEach((category) => {
              costingItems.push({
                ...category,
                jobId: item.jobId,
              });
            });
          });
          handleSetCostingOutside(costingItems);
          setIsLoading(false);
        });
      } catch (error) {
        return;
      }
    },
    [client, handleSetCostingOutside]
  );

  React.useEffect(() => {
    if (!jobId && shouldShow) {
      const jobIds = chain(jobTimesheetItems)
        .map((item) => item.job?._id)
        .uniq()
        .value();

      getAllCostings(jobIds);
    }
  }, [shouldShow]);

  React.useEffect(() => {
    setFormFields(
      createProcessFields(
        t,
        filteredCategoryList,
        openAllocateCosting,
        handleCreateCategory,
        handleDateChange,
        handleSelectCategory,
        getAllocateProps,
        !jobId ? isLoading : undefined,
        !jobId ? hendleMenuOpen : undefined
      )
    );
  }, [
    t,
    filteredCategoryList,
    isLoading,
    jobId,
    openAllocateCosting,
    handleCreateCategory,
    handleDateChange,
    hendleMenuOpen,
    getAllocateProps,
  ]);

  const handleSubmitConfirm = React.useCallback(
    (values: ProcessTimesheetModalForm) => {
      onSubmit && onSubmit(values.items);
      hide();
    },
    [onSubmit, hide]
  );

  const handleSubmit = React.useCallback(
    (values: ProcessTimesheetModalForm) => {
      confirmRef.current?.show(true, () => handleSubmitConfirm(values));
      return true;
    },
    [confirmRef]
  );

  return (
    <>
      <ModalForm<ProcessTimesheetModalForm>
        show={shouldShow}
        validationSchema={createProcessSchema(t)}
        data={{
          items: jobTimesheetItems
            ? jobTimesheetItems.map((el) => ({
                ...el,
                breakDuration:
                  getMinutesfromBreakDuration(el.breakDuration) ?? "",
                task: compact([el.creatorName, el.task]).join(" - "),
                rate: el?.user?.hourly_rate ?? 0,
              }))
            : [],
        }}
        className="process-timesheet-modal"
        title={t("jobTimesheets.processTimesheets")}
        submitText={t("jobTimesheets.process")}
        onSubmit={handleSubmit}
        onClose={hide}
      >
        {(formikProps: FormikProps<ProcessTimesheetModalForm>) => (
          <>
            <Container className="generic-form-body" fluid>
              <Row>
                {renderTableForm(
                  formikProps,
                  formFields.items as GenericFormTable<
                    ProcessTimesheetModalForm,
                    any
                  >,
                  "items"
                )}
              </Row>

              <Row>
                <Col lg={7} />
                <Col lg={5} xs={12}>
                  <ModalTotals
                    items={formikProps.values.items.map((el) => ({
                      cost: calcTotalTimesheetValue(
                        el.rate,
                        el.startTime,
                        el.endTime,
                        Number(el.breakDuration)
                      ),
                      quantity: 1,
                    }))}
                    margin={0}
                    title={t("orders.amount")}
                  />
                </Col>
              </Row>
            </Container>

            <ConfirmDialog
              ref={confirmRef}
              title={t("jobTimesheets.processTimesheets")}
              confirm={t("jobTimesheets.process")}
            >
              <span className="field-text">
                {t("jobTimesheets.processMessage", {
                  count: formikProps.values?.items?.length || 0,
                })}
                {t("jobTimesheets.processMessageLocked")}
              </span>
            </ConfirmDialog>
            {renderSelectCostingModal()}
          </>
        )}
      </ModalForm>
    </>
  );
};

export default React.forwardRef(ProcessTimesheetModal);
