import { TFunction } from "i18next";
import { CreateSalesQuotePayload } from "../../../models/salesQuote";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export function editQuoteMarkupFields(
  t: TFunction
): GenericFormFields<CreateSalesQuotePayload> {
  return {
    markup: {
      type: "text",
      label: t("costing.markupPercentLabel"),
      placeholder: t("costing.markupPercentLabel"),
      valueKey: "markup",
      inputProps: {
        type: "number",
        required: true,
      },
    },
  };
}
