import React from "react";
import { TFunction } from "react-i18next";
import Dropdown, {
  DropdownItem,
} from "../../../../components/dashboard/dropdown";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { printBase64Pdf } from "../../../../utils/pdf";
import { useApolloClient } from "@apollo/client";
import { PRINT_JOB_COSTINGS } from "../../../../graphql/queries/job-costing/queries";
import { PrintJobCostingResponse } from "../../../../graphql/types/models/job-costing";

type PrintOptions = {
  showItems: boolean;
  showExpenses: boolean;
  showUOM: boolean;
  showQuantity: boolean;
  showBudget: boolean;
  showClient: boolean;
  showRealCost: boolean;
  showClaimed: boolean;
  showContractTotal: boolean;
};

export enum PrintType {
  Allowances = "ALLOWANCES",
  Costings = "COSTINGS",
}

export function usePrintCostings(
  t: TFunction,
  printType: PrintType,
  jobId?: string
) {
  const client = useApolloClient();
  const [printOptions, setPrintOptions] = React.useState<PrintOptions>({
    showItems: true,
    showExpenses: true,
    showUOM: true,
    showQuantity: true,
    showBudget: true,
    showClient: true,
    showRealCost: true,
    showClaimed: true,
    showContractTotal: true,
  });

  const {
    storedValue: storedPrintOptions,
    setStoredValue: setStorePrintOptions,
  } = useLocalStorage("jobCostingsPrintOptions", printOptions);

  React.useEffect(() => {
    if (storedPrintOptions) {
      setPrintOptions(storedPrintOptions);
    }
  }, [storedPrintOptions]);

  const handlePrint = React.useCallback(async () => {
    try {
      const pdfPreview = await client.query<PrintJobCostingResponse>({
        query: PRINT_JOB_COSTINGS,
        variables: {
          jobId,
          type: printType,
          options: printOptions,
        },
        fetchPolicy: "network-only",
      });
      printBase64Pdf(pdfPreview?.data?.printJobCostings.pdf);
    } catch (e) {}
  }, [printOptions, printType, jobId]);

  const handlePrintOption = React.useCallback(
    (key: keyof PrintOptions) => () => {
      const newPrintOptions = {
        ...printOptions,
        [key]: !printOptions[key],
      };
      setPrintOptions(newPrintOptions);
      setStorePrintOptions(newPrintOptions);
    },
    [printOptions]
  );

  const getPrintOptionIcon = React.useCallback(
    (key: keyof PrintOptions) => {
      return printOptions[key] ? "check_box" : "check_box_outline_blank";
    },
    [printOptions]
  );

  const printButtonAction = React.useMemo<DropdownItem[]>(() => {
    const keys = Object.keys(printOptions);
    return keys.map((key: any) => ({
      id: key,
      icon: getPrintOptionIcon(key),
      onSelect: handlePrintOption(key),
      label: t(`costing.${key}`),
    }));
  }, [printOptions, handlePrintOption, getPrintOptionIcon]);

  const renderDropdown = React.useCallback(() => {
    return (
      <Dropdown
        icon="print"
        id="dashboardAction-dropdown"
        dropdownClass="mr-2"
        items={printButtonAction}
        alignRight
        split
        disableAutoClose
        onClick={handlePrint}
      />
    );
  }, [printButtonAction, printOptions, handlePrint]);

  return {
    renderDropdown,
  };
}
