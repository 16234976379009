import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../components/confirm-dialog";
import DashboardActionHeader from "../../../components/dashboard/table-card/DashboardActionHeader";
import CreateUpdateChecklistItemModal from "../../../components/task/create-checklist-item-modal";
import { CreateChecklistItemPayload, SystemUser } from "../../../models/task";
import Checklist from "../checklist-task";

export type BaseChecklist = {
  _id: string;
  task: string;
  completed: boolean;
  dueDate: string;
  assignedTo: SystemUser;
};
export type BaseCategory<SChecklistItem> = {
  _id: string;
  name: string;
  checklist: SChecklistItem[];
};

export type BaseTask = {
  _id: string;
};

type ChecklistCategoryProps<TItem, SChecklistItem> = {
  checklist: TItem;
  onDeleteChecklist: (checklist: TItem) => void;
  onEditChecklist: (checklist: TItem) => void;
  onDeleteChecklistItem: (checklistId: string, categoryId: string) => void;
  onCreateChecklistItem: (
    data: CreateChecklistItemPayload,
    checklistId: string
  ) => void;
  onToggleChecklistStatus: (row: SChecklistItem, categoryId: string) => void;
  isLoading: boolean;
};

const ChecklistCategory = <
  TItem extends BaseCategory<SChecklistItem>,
  SChecklistItem extends BaseChecklist
>(
  props: ChecklistCategoryProps<TItem, SChecklistItem>
) => {
  const {
    checklist,
    onDeleteChecklist,
    onToggleChecklistStatus,
    onCreateChecklistItem,
    isLoading,
    onDeleteChecklistItem,
    onEditChecklist,
  } = props;
  const { t } = useTranslation();

  const [currentTask, setCurrentTask] = React.useState<SChecklistItem | null>(
    null
  );
  const [showDeleteDialog, setDeleteDialogVisibility] = React.useState(false);
  const [showDeleteTaskDialog, setDeleteDialogTaskVisibility] = React.useState(
    false
  );
  const [
    showCreateChecklistItemModal,
    setShowCreateChecklistItemModal,
  ] = React.useState(false);

  const openCreateChecklistItemModal = React.useCallback(() => {
    setShowCreateChecklistItemModal(true);
  }, []);

  const handleCreateChecklistItem = React.useCallback(
    (data: CreateChecklistItemPayload, createAnother: boolean) => {
      onCreateChecklistItem(data, checklist._id);
      if (!createAnother) closeCreateChecklistItemModal();
    },
    [checklist._id, onCreateChecklistItem]
  );

  const handleUpdateChecklistItem = React.useCallback(
    (data: CreateChecklistItemPayload, createAnother: boolean) => {
      onCreateChecklistItem(data, checklist._id);
      if (!createAnother) closeCreateChecklistItemModal();
    },
    [checklist, currentTask]
  );

  const closeCreateChecklistItemModal = React.useCallback(() => {
    setCurrentTask(null);
    setShowCreateChecklistItemModal(false);
  }, []);

  const openDeleteDialog = React.useCallback(() => {
    setDeleteDialogVisibility(true);
  }, []);

  const closeDeleteDialog = React.useCallback(() => {
    setCurrentTask(null);
    setDeleteDialogVisibility(false);
  }, []);

  const deleteChecklist = React.useCallback(() => {
    onDeleteChecklist(checklist);
  }, [checklist, onDeleteChecklist]);

  const editChecklist = React.useCallback(() => {
    onEditChecklist(checklist);
  }, [checklist, onEditChecklist]);

  const deleteChecklistTask = React.useCallback(() => {
    if (currentTask) {
      onDeleteChecklistItem(currentTask._id, checklist._id);
    }
  }, [checklist, currentTask, onDeleteChecklistItem]);

  const checklistCategoryOptions = () => {
    return [
      {
        id: "editChecklistCategory",
        label: t("tasks.editGroup"),
        name: "editChecklistCategory",
        outlined: true,
        onClick: editChecklist,
      },
      {
        id: "deleteChecklistCategory",
        label: t("tasks.deleteChecklistCategory"),
        name: "deleteChecklistCategory",
        outlined: true,
        onClick: openDeleteDialog,
      },
    ];
  };
  const hasItems = checklist.checklist.length > 0;
  const checklistProgress =
    (checklist.checklist.filter((task: SChecklistItem) => task.completed)
      .length /
      checklist.checklist.length) *
    100;

  const openRemoveDialog = React.useCallback((row?: SChecklistItem) => {
    if (row) {
      setCurrentTask(row);
      setDeleteDialogTaskVisibility(true);
    }
  }, []);

  const closeRemoveTaskDialog = React.useCallback(() => {
    setDeleteDialogTaskVisibility(false);
    setCurrentTask(null);
  }, []);

  const handleEditTask = React.useCallback(
    (row?: SChecklistItem) => {
      if (row) {
        setCurrentTask(row);
        openCreateChecklistItemModal();
      }
    },
    [openCreateChecklistItemModal]
  );

  const handleToggleChecklistStatus = React.useCallback(
    (row: SChecklistItem) => {
      if (row) {
        onToggleChecklistStatus(row, checklist._id);
      }
    },
    [checklist, onToggleChecklistStatus]
  );

  return (
    <>
      <ConfirmDialog
        title={t("tasks.deleteChecklistCategory")}
        show={showDeleteDialog}
        onSubmit={deleteChecklist}
        onClose={closeDeleteDialog}
      >
        <span className="field-text">
          {t("tasks.deleteChecklistCategoryMessage")}
        </span>
      </ConfirmDialog>

      <ConfirmDialog
        title={t("tasks.deleteTask")}
        show={showDeleteTaskDialog}
        onSubmit={deleteChecklistTask}
        onClose={closeRemoveTaskDialog}
      >
        <span className="field-text">{t("tasks.removeMessage")}</span>
      </ConfirmDialog>
      <CreateUpdateChecklistItemModal
        data={currentTask}
        show={showCreateChecklistItemModal}
        onClose={closeCreateChecklistItemModal}
        onSubmit={handleCreateChecklistItem}
        onUpdate={handleUpdateChecklistItem}
      />
      <div className="p-4 wrapper">
        <DashboardActionHeader
          onActionButton={openCreateChecklistItemModal}
          actionButtonTitle={t("tasks.createNewChecklist")}
          dropdownItems={checklistCategoryOptions()}
          dropdownWidth="160"
        >
          <div className="header-text name">{checklist.name}</div>
        </DashboardActionHeader>
        {hasItems && (
          <div className="pt-4">
            <ProgressBar
              animated={isLoading}
              now={Math.floor(checklistProgress)}
              label={`${Math.floor(checklistProgress)}%`}
              variant={
                Math.floor(checklistProgress) === 100 ? "success" : "warning"
              }
            />
          </div>
        )}
        <Checklist<SChecklistItem>
          checklist={checklist.checklist}
          onToggleChecklistStatus={handleToggleChecklistStatus}
          onEditChecklist={handleEditTask}
          onDeleteChecklist={openRemoveDialog}
        />
      </div>
    </>
  );
};

export default ChecklistCategory;
