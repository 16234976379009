import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { find, head, isEmpty, omit } from "lodash";

import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import ChecklistTable from "../../../../components/task/checklist-template-table";
import ChecklistGroups from "../../../../components/task/checklist-categories";
import {
  CreateUpdateChecklistTemplateGroupResponse,
  GetChecklistTemplateResponse,
  TodoChecklistTemplateCategory,
} from "../../../../models/task";
import { GET_CHECKLIST_TEMPLATE } from "../../../../graphql/queries/task/queries";
import { useChecklistTemplate } from "../../../../hooks/useChecklistTemplate";

type Params = {
  id: string;
};

type ChecklistTemplateProps = RouteComponentProps<Params> &
  DashboardContextValue;

const ChecklistTemplate: React.FC<ChecklistTemplateProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { t } = useTranslation();

  const { id: templateId } = match.params;

  const [
    selectedCategory,
    setCategory,
  ] = React.useState<TodoChecklistTemplateCategory | null>(null);

  const {
    data: checklistTemplate,
    loading: checklistTemplateLoading,
  } = useQuery<GetChecklistTemplateResponse>(GET_CHECKLIST_TEMPLATE, {
    variables: {
      templateId,
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (checklistTemplate) {
      setNavigationContext({
        ...navigationContext,
        checklistTemplate: checklistTemplate.getChecklistTemplate,
      });
    }
  }, [setNavigationContext, checklistTemplate]);

  React.useEffect(() => {
    const data = checklistTemplate?.getChecklistTemplate?.categories;
    const selectionCategory =
      find(data, { _id: selectedCategory?._id }) || head(data);

    setCategory(selectionCategory || null);
  }, [checklistTemplate]);

  const handleCategorySelect = React.useCallback(
    (category: TodoChecklistTemplateCategory) => {
      setCategory(category);
    },
    []
  );

  const handleOnAddGroup = React.useCallback(
    (data: CreateUpdateChecklistTemplateGroupResponse) => {
      const existingCategories = checklistTemplate?.getChecklistTemplate?.categories.map(
        (category) => category._id
      );
      const newCategory = data?.createUpdateChecklistTemplateCategory?.categories.filter(
        (category) => !existingCategories?.includes(category._id)
      );
      setCategory(newCategory[0]);
    },
    [checklistTemplate]
  );
  const {
    openDeleteGroupDialog,
    renderDeleteChecklistGroupConfirm,
    renderCreateUpdateChecklistGroupModal,
    openCreateGroupModal,
    openUpdateGroupModal,
    createUpdateChecklistTemplate,
  } = useChecklistTemplate({
    templateId,
    onAddGroup: handleOnAddGroup,
    isSort: true,
  });

  const openUpdateChecklistGroupModal = React.useCallback(async () => {
    if (!selectedCategory) return;
    const hasOrder = selectedCategory.order;
    hasOrder
      ? openUpdateGroupModal(
          selectedCategory,
          checklistTemplate?.getChecklistTemplate
        )
      : openUpdateGroupModal(
          { ...selectedCategory, order: 0 },
          checklistTemplate?.getChecklistTemplate
        );
  }, [selectedCategory, openUpdateGroupModal]);

  const handleSort = React.useCallback(
    (categories: TodoChecklistTemplateCategory[]) => {
      if (!checklistTemplate) return;
      const categoriesData = categories.map((category) => ({
        ...omit(category, ["is_deleted", "templateId", "__typename"]),
        items: category.items.map((item) => ({
          ...omit(item, ["categoryId", "__typename", "is_deleted"]),
          task: item.task,
          order: item.order,
        })),
        name: category.name,
      }));

      createUpdateChecklistTemplate({
        variables: {
          template: {
            ...omit(checklistTemplate?.getChecklistTemplate, [
              "__typename",
              "is_deleted",
            ]),
            name: checklistTemplate?.getChecklistTemplate.name,
            categories: categoriesData,
          },
        },
      });
    },
    [checklistTemplate, createUpdateChecklistTemplate]
  );

  return (
    <Container fluid className="m-0 p-0 h-100 quote-selections">
      <Helmet
        title={t("navigation.settings.templatesSection.checklistTemplates")}
      />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.CHECKLIST_TEMPLATE
        }
      />
      {renderDeleteChecklistGroupConfirm()}
      {renderCreateUpdateChecklistGroupModal()}
      <Row className="h-100">
        <Col lg={4} xs={12}>
          <ChecklistGroups
            selectedCategory={selectedCategory}
            selectCategory={handleCategorySelect}
            categories={checklistTemplate?.getChecklistTemplate.categories}
            onAddClick={openCreateGroupModal}
            onDelete={openDeleteGroupDialog}
            onSort={handleSort}
          />
        </Col>
        <Col lg={8} xs={12}>
          <ChecklistTable
            isDataLoading={
              checklistTemplateLoading || isEmpty(selectedCategory?.items)
            }
            tableTitle={selectedCategory?.name || ""}
            items={selectedCategory?.items.filter(Boolean)}
            isLocked={false}
            isSelectedCategory={!!selectedCategory}
            updateGroupPress={openUpdateChecklistGroupModal}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default withRouter(withDashboardContext(ChecklistTemplate));
