export const ProgressItem = (
  html: any,
  name: string,
  description: string,
  progress: number
) => {
  return html`<div class="calendar-item">
    <div class="calendar-item-name" title="${description}">${name}</div>
    <div class="calendar-progress" style="width: ${progress}%;">
      <div class="calendar-progress-percent">
        ${progress ? `${progress}%` : ""}
      </div>
    </div>
  </div> `;
};
