import React from "react";
import classNames from "classnames";

export type DashboardCardBodyProps = {
  children?: any;
  className?: string;
};

const DashboardCardBody: React.FC<DashboardCardBodyProps> = ({
  children,
  className,
}) => {
  return <div className={classNames("body", className)}>{children}</div>;
};

export default DashboardCardBody;
