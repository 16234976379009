import { TFunction } from "i18next";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import { BulkPriceListAdjustPayload } from "../../../../models/price-list";

const createAdjustPriceListFields = (
  t: TFunction,
  onPercentageChange: (value: any) => void
): GenericFormFields<BulkPriceListAdjustPayload> => ({
  amount: {
    valueKey: "amount",
    type: "text",
    label: t("priceList.percentAmount"),
    placeholder: t("placeholders.marginValue"),
    inputProps: {
      required: true,
      type: "number",
    },
    textInputProps: {
      onValueChange: onPercentageChange,
    },
  },
});

export default createAdjustPriceListFields;
