import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { find, map, omit } from "lodash";
import {
  CopySalesQuoteInput,
  CopySalesQuotePayload,
  CreateSalesQuotePayload,
  SalesQuote,
} from "../../../models/salesQuote";

import UpdateEntityModal from "../../modals/update-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import UpdateSalesQuoteSchema from "./CreateQuote.schema";
import { updateSalesQuoteFormFields } from "./utils";
import { AutocompleteInputOption } from "../../generic-form/inputs/autocomplete";
import { ContactListItem } from "../../../graphql/types/models/client";
import CreateClient, {
  CreateClientRef,
} from "../../../containers/modals/create-client";
import { handlePlaceSelect } from "../../../utils/place";

type UpdateSalesQuoteModalProps = {
  quote?: SalesQuote;
  onSubmit?: (data: CreateSalesQuotePayload) => void;
  onCopySubmit?: (data: CopySalesQuotePayload) => void;
  clientsList: ContactListItem[];
  isCopy?: boolean;
};

export type UpdateSalesQuoteModalRef = {
  show: (show: boolean) => void;
};

const UpdateSalesQuoteModal: React.FC<UpdateSalesQuoteModalProps> = (
  { quote, onSubmit, clientsList, isCopy, onCopySubmit },
  ref
) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const createClientRef = React.useRef<CreateClientRef>(null);
  const formikRef = React.useRef<any>();

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => setShowModal(show),
  }));

  const onCreateClient = React.useCallback(
    () => createClientRef.current?.show(true),
    [createClientRef]
  );

  const handleContactSelect = React.useCallback(
    (contactId: string) => {
      if (formikRef.current?.values && !formikRef.current?.values?.address) {
        const contact = find(clientsList, (c) => c._id === contactId);
        if (contact?.address) {
          formikRef.current.setFieldValue("address", contact.address);
          formikRef.current.setFieldValue("city", contact.city);
          formikRef.current.setFieldValue("state", contact.state);
          formikRef.current.setFieldValue("postcode", contact.postcode);
        }
      }
    },
    [formikRef]
  );

  const formFields = React.useMemo<
    GenericFormFields<CreateSalesQuotePayload>
  >(() => {
    const clientsData: AutocompleteInputOption[] = map(
      clientsList,
      (client) => ({
        value: client._id,
        label: `${client.first_name} ${client.last_name}`,
      })
    );

    return updateSalesQuoteFormFields(
      t,
      clientsData,
      onCreateClient,
      handleContactSelect,
      handlePlaceSelect,
      isCopy
    );
  }, [
    t,
    clientsList,
    onCreateClient,
    handleContactSelect,
    handlePlaceSelect,
    isCopy,
  ]);

  const handleCopySubmit = React.useCallback(
    (data: CopySalesQuoteInput) => {
      onCopySubmit?.({
        quote: omit(data, [
          "_id",
          "takeoffs",
          "documents",
          "costings",
          "specifications",
        ]),
        salesQuoteId: data._id as string,
        options: {
          takeoffs: data?.takeoffs || false,
          documents: data?.documents || false,
          costings: data?.costings || false,
          specifications: data?.specifications || false,
        },
      });
    },
    [onCopySubmit]
  );

  if (!quote) return null;

  return (
    <React.Fragment>
      <UpdateEntityModal
        formikRef={formikRef}
        validationSchema={UpdateSalesQuoteSchema(t)}
        title={isCopy ? t("quotes.copyQuote") : t("quotes.updateSalesQuote")}
        show={showModal}
        data={{
          _id: quote._id,
          name: isCopy
            ? t("quotes.copyQuoteName", { estimationName: quote.name })
            : quote.name,
          contactId: quote.contact?._id || "",
          status: quote.status,
          markup: quote.markup,
          address: quote.address || "",
          city: quote.city || "",
          state: quote.state || "",
          postcode: quote.postcode || "",
        }}
        onSubmit={(data: CreateSalesQuotePayload) =>
          isCopy ? handleCopySubmit(data) : onSubmit?.(data)
        }
        onClose={() => setShowModal(false)}
        fields={formFields}
        submitButtonTitle={isCopy ? t("common.copy") : undefined}
      />
      <CreateClient ref={createClientRef} />
    </React.Fragment>
  );
};

export default forwardRef(UpdateSalesQuoteModal);
