import React from "react";

export const useVisibilityWithOutsideClick = (initIsVisible: boolean) => {
  const [isVisible, setIsVisible] = React.useState(initIsVisible);
  const ref = React.useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref?.current && !ref?.current?.contains(event?.target)) {
      setIsVisible(false);
    }
  };

  React.useEffect(() => {
    document
      .getElementById("root")
      ?.addEventListener("click", handleClickOutside, true);
    return () => {
      document
        .getElementById("root")
        ?.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isVisible, setIsVisible };
};
