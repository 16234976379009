import { SubscriptionPlan } from "./subscription";
import { User } from "./user";

export type VerifyAffiliateResponse = {
  affiliateVerification: boolean;
};
export enum AffiliateStatus {
  PENDING = "PENDING",
  REVIEWED = "REVIEWED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum AffiliateBusinessStatus {
  TRIALING = "TRIALING",
  EXPIRED = "EXPIRED",
  ACTIVE = "ACTIVE",
}

export type AffiliateBusinessTableItem = AffiliateBusiness & {
  subscriptionPlanName: string;
  subscriptionPlanValue: number;
};

export type AffiliateBusiness = {
  _id: string;
  name: string;
  stripeSubscriptionId: string;
  subscriptionPlan: SubscriptionPlan;
  subscriptionEndDate: string;
  createdAt: string;
  status: AffiliateBusinessStatus;
};

export type Affiliate = {
  _id: string;
  first_name: string;
  last_name: string;
  business_name: string;
  email: string;
  phone: string;
  ABN: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  hasBuilders: boolean;
  about: string;
  additionalComment: string;
  reviewedBy: User;
  status: AffiliateStatus;
  businesses: AffiliateBusiness[];
  createdAt: string;
  affiliateUrl?: string;
};

export type AffiliateSignUpPayload = {
  first_name: string;
  last_name: string;
  business_name: string;
  email: string;
  phone: string;
  ABN: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  hasBuilders: boolean | string;
  about: string;
  additionalComment: string;
  termsAgreement: boolean;
};

export type AffiliateSignInResponse = {
  affiliateLogin: {
    _id: string;
    token: string;
  };
};

export type AffiliateSignUpResponse = {
  affiliateSignup: {
    business_name: string;
    last_name: string;
    first_name: string;
    email: string;
    phone: string;
  };
};

export type AffiliateListResponse = {
  affiliates: Affiliate[];
};

export type AffiliateDetailsResponse = {
  affiliate: Affiliate;
};

export type AffiliateApproveResponse = {
  affiliate: Affiliate;
};

export type AffiliateProfileResponse = {
  affiliateProfile: Affiliate;
};

export type AffiliateBusinessesResponse = {
  affiliateListBusinesses: AffiliateBusiness[];
};
