import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client";
import { map, head, compact } from "lodash";
import moment from "moment";

import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";
import CategorySelectorCard, {
  CategorySelectorFilter,
} from "../../../../components/category-select-card";
import {
  CLIENT_LIST_VARIATIONS,
  GET_CLIENT_VARIATION,
  GET_CLIENT_VARIATION_PREVIEW,
} from "../../../../graphql/queries/client/job-variation/queries";
import { CLIENT_APPROVE_VARIATION } from "../../../../graphql/queries/client/job-variation/mutations";
import { handleVariationAccept } from "../../../../graphql/queries/client/job-variation/utils";
import {
  ClientListVariationsResponse,
  ClientApproveVariationResponse,
  GetClientJobVariationByIdResponse,
} from "../../../../graphql/types/models/client-job-variation";
import { ClientApproveVariationInputs } from "../../../../graphql/types/inputs/client-job-variation";
import { formatQuoteNumber } from "../../../../utils/text";
import EmptyPlaceholder from "../../../../components/empty-placeholder";
import CardPlaceholder from "../../../../components/dashboard/card-placeholder";
import ClientVariationCard from "../../../../components/variation/client-variation-card";
import { notify } from "../../../../components/notification";
import { getVariationBadgeVariant } from "../../../jobs/job-variations/utils";
import AcceptSignatureDialog, {
  AcceptSignatureDialogRef,
  DigitalSignatureInput,
} from "../../../../components/accept-signature-dialog";
import { uploadFiles } from "../../../../utils/files";
import { GetClientVariationPreviewResponse } from "../../../../graphql/types/models/job-variation";
import { printBase64Pdf } from "../../../../utils/pdf";

type Params = {
  id: string;
};

type ClientJobVariationsProps = RouteComponentProps<Params>;

const ClientJobVariations: React.FC<ClientJobVariationsProps> = ({ match }) => {
  const { id: jobId } = match.params;

  const { t } = useTranslation();
  const client = useApolloClient();

  const [variationFilter, setVariationFilter] = useState<
    CategorySelectorFilter
  >({
    id: "",
  });
  const acceptSignatureDialogRef = React.useRef<AcceptSignatureDialogRef>(null);
  const [acceptTarget, setAcceptTarget] = useState<string>("");
  const [uploadingSignature, setUploadingSignature] = React.useState(false);

  const {
    data: clientVariationsResponse,
    loading: clientVariationsLoading,
  } = useQuery<ClientListVariationsResponse>(CLIENT_LIST_VARIATIONS, {
    variables: { jobId },
  });

  const [
    getVariation,
    {
      data: clientVariationDetails,
      loading: clientVariationLoading,
      error: clientVariationError,
    },
  ] = useLazyQuery<GetClientJobVariationByIdResponse>(GET_CLIENT_VARIATION);

  const [clientAcceptVariation, { loading: clientAcceptLoading }] = useMutation<
    ClientApproveVariationResponse,
    ClientApproveVariationInputs
  >(CLIENT_APPROVE_VARIATION);

  const variationsCategories = React.useMemo(() => {
    const data = clientVariationsResponse?.clientGetJobVariations;

    return map(data, (variation) => ({
      id: variation._id,
      label: formatQuoteNumber(variation.variationNumber, "V"),
      description: compact([
        moment(variation.date).format("D MMMM YYYY"),
        variation.name,
      ]).join(": "),
      rightLabel: t("common.currency", { amount: variation.subTotal }),
      rightBadge: [
        {
          variant: getVariationBadgeVariant(variation),
          label: t(`variations.statuses.${variation.status}`),
        },
      ],
    }));
  }, [t, clientVariationsResponse]);

  React.useEffect(() => {
    if (
      clientVariationDetails?.clientGetJobVariationById ||
      clientVariationLoading ||
      clientVariationError
    ) {
      return;
    }

    let firstVariation = head(clientVariationsResponse?.clientGetJobVariations);

    if (firstVariation) {
      getVariation({
        variables: {
          jobId,
          variationId: firstVariation._id,
        },
      });

      setVariationFilter({
        id: firstVariation._id,
      });
    }
  }, [
    clientVariationError,
    clientVariationsResponse,
    clientVariationDetails,
    clientVariationLoading,
    getVariation,
    jobId,
  ]);

  const handleSelectCategory = React.useCallback(
    (filter) => {
      if (filter.id) {
        getVariation({
          variables: {
            jobId,
            variationId: filter.id,
          },
        });
        setVariationFilter(filter);
      }
    },
    [jobId, getVariation]
  );

  const closeApproveDialog = React.useCallback(() => {
    acceptSignatureDialogRef.current?.show(false);
    setAcceptTarget("");
  }, []);

  const openApproveDialog = React.useCallback((id: string) => {
    acceptSignatureDialogRef.current?.show(true);
    setAcceptTarget(id);
  }, []);

  const handleClientAcceptVariation = React.useCallback(
    async (signature: DigitalSignatureInput, file: File) => {
      try {
        if (!jobId || !acceptTarget) return;

        const result = await clientAcceptVariation({
          variables: {
            jobId,
            variationId: acceptTarget,
            signature,
          },
          update: handleVariationAccept(jobId),
        });

        const resultSignature =
          result.data?.clientApproveVariation.clientSignature?.file;

        if (resultSignature) {
          setUploadingSignature(true);
          await uploadFiles([resultSignature], [file]);
          setUploadingSignature(false);
        }

        notify({
          title: t("variations.acceptVariation"),
          content: t("variations.success.acceptVariation"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("variations.acceptVariation"),
          content: t("variations.error.acceptVariation"),
        });
      } finally {
        acceptSignatureDialogRef.current?.show(false);
        setAcceptTarget("");
      }
    },
    [t, jobId, acceptTarget, clientAcceptVariation]
  );

  const handlePrint = React.useCallback(
    async (variationId: string) => {
      try {
        const preview = await client.query<GetClientVariationPreviewResponse>({
          query: GET_CLIENT_VARIATION_PREVIEW,
          fetchPolicy: "network-only",
          variables: {
            variationId,
            jobId,
          },
        });

        printBase64Pdf(preview?.data.clientGetJobVariationPreview.pdf);
      } catch (e) {}
    },
    [jobId]
  );

  const renderVariationCard = () => {
    if (
      !clientVariationDetails?.clientGetJobVariationById ||
      clientVariationLoading ||
      clientVariationsLoading ||
      clientAcceptLoading
    ) {
      return <CardPlaceholder />;
    }

    return (
      <ClientVariationCard
        {...clientVariationDetails?.clientGetJobVariationById}
        titleIndex={
          clientVariationDetails?.clientGetJobVariationById?.variationNumber
        }
        onAccept={openApproveDialog}
        isLoading={uploadingSignature}
        onPrint={handlePrint}
      />
    );
  };

  return (
    <Container fluid className="h-100">
      <Helmet title={t("navigation.jobsSection.variations")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.VARIATIONS} />

      <AcceptSignatureDialog
        ref={acceptSignatureDialogRef}
        title={t("variations.approveVariation")}
        onSubmit={handleClientAcceptVariation}
        onClose={closeApproveDialog}
        submitButtontText={t("common.approve")}
        isLoading={uploadingSignature}
      />

      {variationsCategories.length ? (
        <Row className="h-100">
          <Col lg={4} xs={12}>
            <CategorySelectorCard
              title={t("variations.variations")}
              filter={variationFilter}
              onSelectCategory={handleSelectCategory}
              categories={variationsCategories}
              hideSelectAllButton
              hideAddButton
              disabled={
                clientAcceptLoading ||
                clientVariationLoading ||
                clientVariationsLoading
              }
            />
          </Col>
          <Col lg={8} xs={12}>
            {renderVariationCard()}
          </Col>
        </Row>
      ) : (
        <EmptyPlaceholder message={t("client.variations.emptyPlaceholder")} />
      )}
    </Container>
  );
};

export default ClientJobVariations;
