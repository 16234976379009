import React from "react";
import GSTC from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import { reduce, sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import Dropdown from "../../../../components/dashboard/dropdown";
import { User } from "../../../../graphql/types/models/user";
import { ScheduleCategory, ScheduleCategoryItem } from "../../../../models/job";
import { SupplierDetails } from "../../../../models/supplier";
import { createScheduleChart, createScheduleRows } from "../utils";
import { getFullName } from "../../../../utils/text";

export enum AssigneeType {
  User = "User",
  Supplier = "Supplier",
}

type ScheduleFilter = {
  assignee: User | SupplierDetails | null;
  assigneeType: AssigneeType;
  category: ScheduleCategory | null;
};

type AssigneeUserFilter = {
  [key: string]: User;
};

type AssigneeSupplierFilter = {
  [key: string]: SupplierDetails;
};

type AssigneeOptions = {
  users: AssigneeUserFilter;
  suppliers: AssigneeSupplierFilter;
};

export type AssigneeFilterFn = (
  assignee: User | SupplierDetails | null,
  type: AssigneeType
) => void;
export type CategoryFilterFn = (category: ScheduleCategory | null) => void;

export const getAssigneesFilter = (
  t: TFunction,
  tasks: ScheduleCategoryItem[],
  onFilterAssignee: AssigneeFilterFn
) => {
  const assignees = reduce(
    tasks,
    (items, item) => {
      if (item.user && !items.users[item.user._id]) {
        items.users[item.user._id] = item.user;
      }
      if (item.supplier && !items.suppliers[item.supplier._id]) {
        items.suppliers[item.supplier._id] = item.supplier;
      }
      return items;
    },
    {
      users: {},
      suppliers: {},
    } as AssigneeOptions
  );

  const items = [];

  for (let user of Object.values(assignees.users)) {
    items.push({
      id: user?._id,
      label: getFullName(user),
      onClick: () => onFilterAssignee(user, AssigneeType.User),
    });
  }
  for (let supplier of Object.values(assignees.suppliers)) {
    items.push({
      id: supplier?._id,
      label: supplier.business_name,
      onClick: () => onFilterAssignee(supplier, AssigneeType.Supplier),
    });
  }
  return [
    {
      id: "",
      label: t("schedule.allAssignees"),
      onClick: () => onFilterAssignee(null, AssigneeType.User),
    },
  ].concat(sortBy(items, "label"));
};
export const getCategoryFilter = (
  t: TFunction,
  categories: ScheduleCategory[],
  onFilterCategory: CategoryFilterFn
) => {
  const items = [
    {
      id: "",
      label: t("schedule.allCategories"),
      onClick: () => onFilterCategory(null),
    },
  ].concat(
    sortBy(
      categories.map((category) => ({
        id: category._id,
        label: category.name,
        onClick: () => onFilterCategory(category),
      })),
      "label"
    )
  );

  return items;
};

export function useScheduleFilter(
  calendarState: any,
  tasks?: ScheduleCategoryItem[],
  tasksData?: ScheduleCategory[]
) {
  const { t } = useTranslation();
  const [scheduleFilter, setScheduleFilter] = React.useState<ScheduleFilter>({
    assignee: null,
    assigneeType: AssigneeType.User,
    category: null,
  });

  const applyScheduleFilter = React.useCallback(
    (calendarState: any, filter?: ScheduleFilter) => {
      if (!tasksData) return;
      const defaultFilter = filter || scheduleFilter;
      calendarState.update("config", (config: any) => {
        // only update the rows/items if size has changed (better UX)
        const rows = createScheduleRows(tasksData, t);
        const items = createScheduleChart(tasksData);
        const safeRows = [];
        if (defaultFilter.assignee) {
          if (defaultFilter.assigneeType === AssigneeType.User) {
            for (let rowId in rows) {
              if (
                rows[rowId].parentId &&
                (!rows[rowId].user ||
                  rows[rowId].user?._id !== defaultFilter.assignee?._id)
              ) {
                delete rows[rowId];
                delete items[rowId];
              } else {
                safeRows.push(rows[rowId].parentId);
              }
            }
          }
          if (defaultFilter.assigneeType === AssigneeType.Supplier) {
            for (let rowId in rows) {
              if (
                rows[rowId].parentId &&
                (!rows[rowId].supplier ||
                  rows[rowId].supplier?._id !== defaultFilter.assignee?._id)
              ) {
                delete rows[rowId];
                delete items[rowId];
              } else {
                safeRows.push(rows[rowId].parentId);
              }
            }
          }
          for (let rowId in rows) {
            if (!rows[rowId].parentId && safeRows.indexOf(rowId) < 0) {
              delete rows[rowId];
              delete items[rowId];
            }
          }
        }
        // persist collapsed row state
        for (let rowId in rows) {
          if (config.list.rows[rowId]?.expanded === false) {
            rows[rowId].expanded = false;
          }
          if (defaultFilter.category) {
            const categoryId = GSTC.api.GSTCID(defaultFilter.category._id);
            for (let rowId in rows) {
              if (
                (rows[rowId].parentId && rows[rowId].parentId !== categoryId) ||
                (!rows[rowId].parentId && rows[rowId].id !== categoryId)
              ) {
                delete rows[rowId];
                delete items[rowId];
              }
            }
          }
        }
        // remove dependencies that are hidden
        for (let rowId in items) {
          if (items[rowId].dependant && items[rowId].dependant?.length) {
            items[rowId].dependant = items[rowId].dependant?.filter(
              (dependantId) => !!items[dependantId]
            );
          }
        }
        config.list.rows = rows;
        config.chart.items = items;
        return config;
      });
    },
    [scheduleFilter, tasksData]
  );

  const onFilterAssignee = React.useCallback<AssigneeFilterFn>(
    (assignee, assigneeType) => {
      const newFilter = { ...scheduleFilter, assignee, assigneeType };
      setScheduleFilter(newFilter);
      applyScheduleFilter(calendarState, newFilter);
    },
    [scheduleFilter, tasksData, calendarState]
  );

  const onFilterCategory = React.useCallback<CategoryFilterFn>(
    (category) => {
      const newFilter = { ...scheduleFilter, category };
      setScheduleFilter(newFilter);
      applyScheduleFilter(calendarState, newFilter);
    },
    [scheduleFilter, tasksData, calendarState]
  );

  const assigneeItems = React.useMemo(() => {
    if (!tasks) return [];
    return getAssigneesFilter(t, tasks, onFilterAssignee);
  }, [tasks, onFilterAssignee]);

  const renderAssigneeFilter = React.useCallback(() => {
    let label = t("schedule.filterAssignee");
    if (scheduleFilter?.assignee) {
      if (scheduleFilter?.assigneeType === AssigneeType.Supplier) {
        label = (scheduleFilter.assignee as SupplierDetails).business_name;
      } else {
        label = getFullName(scheduleFilter.assignee as User);
      }
    }
    return (
      <Dropdown
        label={label}
        icon="expand_more"
        id="assignee-dropdown"
        items={assigneeItems}
      />
    );
  }, [assigneeItems, scheduleFilter]);

  const categoryItems = React.useMemo(() => {
    if (!tasksData) return [];
    return getCategoryFilter(t, tasksData, onFilterCategory);
  }, [tasksData, onFilterAssignee]);

  const renderCategoryFilter = React.useCallback(() => {
    const label = scheduleFilter.category
      ? scheduleFilter.category.name
      : t("schedule.filterCategory");
    return (
      <Dropdown
        label={label}
        icon="expand_more"
        id="category-dropdown"
        items={categoryItems}
      />
    );
  }, [categoryItems, scheduleFilter]);

  return {
    applyScheduleFilter,
    renderAssigneeFilter,
    renderCategoryFilter,
    scheduleFilter,
  };
}
