import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { first, mapValues, pick } from "lodash";

import { Business } from "../../../../graphql/types/models/business";
import { GET_BUSINESS } from "../../../../graphql/queries/business/queries";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import editCompanyFields from "./utils";
import editCompanySchema from "./EditCompany.schema";
import UpdateEntityModal from "../../../modals/update-entity";
import AvatarUploader from "../../../uploaders/avatar-uploader";
import { resizeFiles, uploadFiles } from "../../../../utils/files";
import { getMediaInput } from "../../../../utils/transform";
import { handlePlaceSelect } from "../../../../utils/place";
import { useModalDisplay } from "../../../../hooks/useModalDisplay";
import { useBusinessMutation } from "../../../../hooks/mutations/useBusinessMutation";

import "./styles.scss";

type EditCompanyProps = {
  company: Business;
};

const EditCompanyModal: React.FC<EditCompanyProps> = (props, ref) => {
  const { company } = props;

  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<GenericFormFields<Business>>({});

  const [logoFile, setLogoFile] = useState<File | null>(null);

  const { shouldShow, hide } = useModalDisplay(ref);
  const { updateBusiness } = useBusinessMutation(
    hide,
    async (cache, { data }) => {
      // upload logo and append timestamp to prevent browser loading an invalid image
      if (logoFile && data?.updateBusiness.logo) {
        await uploadFiles([data?.updateBusiness.logo], [logoFile]);
        const business = {
          ...data.updateBusiness,
        };
        if (business.logo) {
          business.logo = {
            ...business.logo,
            url: business.logo.url + `?${+new Date()}`, // append timestamp to reset image cache
          };
          cache.writeQuery({
            query: GET_BUSINESS,
            data: { getBusiness: business },
          });
        }
      }
    }
  );

  React.useEffect(() => {
    setFormFields(editCompanyFields(t, handlePlaceSelect));
  }, [t]);

  const initialValues = React.useMemo(
    () => mapValues(company, (s) => s || ""),
    [company]
  );

  const handleLogoUpload = React.useCallback(
    async (files: File[]) => {
      const compressedFiles = await resizeFiles([files[0]]);
      setLogoFile(compressedFiles[0] as File);
    },
    [setLogoFile]
  );

  const handleSubmit = React.useCallback(
    async (values) => {
      const data = pick(values, [
        "name",
        "email",
        "phone_number",
        "address",
        "address2",
        "city",
        "state",
        "postcode",
        "country",
        "abn",
        "builderNumber",
      ]) as any;
      if (logoFile) {
        data.logo = first(getMediaInput([logoFile]));
      }
      updateBusiness({
        variables: {
          business: data,
        },
      });
    },
    [updateBusiness, logoFile]
  );

  return (
    <UpdateEntityModal
      validationSchema={editCompanySchema(t)}
      show={shouldShow}
      className="edit-company-modal"
      title={t("company.editCompany")}
      fields={formFields}
      data={initialValues}
      onClose={hide}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<any>) => (
        <>
          <label className="form-input-label form-label">Company Logo</label>
          <div className="avatar-detail">
            <AvatarUploader
              icon="insert_photo"
              outlined
              onUpload={handleLogoUpload}
              file={logoFile}
              imageUrl={company.logo?.url}
              contained
            />
          </div>
        </>
      )}
    </UpdateEntityModal>
  );
};

export default forwardRef(EditCompanyModal);
