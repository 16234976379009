import React, { MouseEventHandler } from "react";

import "./styles.scss";
import { ExpandedFieldOptions } from "./utils";
import TableExpandedField from "./TableExpandedField";
import classNames from "classnames";

type CardTableExpandedFieldsProps = {
  data: any[];
  fieldOptions: ExpandedFieldOptions<any>;
};

const TableExpandedFields: React.FC<CardTableExpandedFieldsProps> = (props) => {
  const { data, fieldOptions } = props;

  const containerClasses = classNames("d-flex row-actions");

  const handleActionClick = React.useCallback<
    MouseEventHandler<HTMLDivElement>
  >((event) => {
    event.stopPropagation();
  }, []);

  return (
    <div className={containerClasses} onClick={handleActionClick}>
      <TableExpandedField data={data} fieldOptions={fieldOptions} />
    </div>
  );
};

export default TableExpandedFields;
