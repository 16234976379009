import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import {
  CreateCostingCategoryPayload,
  CreateCostingItemPayload,
  UpdateCostingCategoryPayload,
} from "../../../models/salesQuote";
import { calcItemTotal } from "../../../utils/calculations";

export const createUpdateQuoteRequestFields = (
  t: TFunction
): GenericFormFields<UpdateCostingCategoryPayload> => {
  // const assemblyOptions = assemblies.map((assembly) => ({
  //   value: assembly._id,
  //   label: assembly.name,
  //   //hidden: true,//assembly._id.indexOf("assembly:") === -1,
  //  // data: assembly,
  // }))
  //const assemblyOptions = [] as SelectOption[];

  return {
    items: {
      tableTitle: t("quoteRequest.quoteItems"),
      isCompact: true,
      row: [
        {
          // width: "200px",
          type: "text",
          label: t("costing.name"),
          placeholder: t("placeholders.name"),
          valueKey: "name",
          inputProps: {
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
            readOnly: true,
          },
        },
        {
          width: "125px",
          maxWidth: "125px",
          type: "text",
          label: t("costing.quantity"),
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          disallowNegativeNumber: true,
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
            readOnly: true,
          },
        },
        {
          width: "125px",
          maxWidth: "125px",
          type: "text",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
            readOnly: true,
          },
        },
        {
          width: "140px",
          maxWidth: "140px",
          type: "text",
          label: t("costing.cost"),
          placeholder: t("placeholders.costValue"),
          valueKey: "cost",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
      ],
      dynamicFields: [
        {
          title: t("costing.totalEx"),
          maxWidth: "160px",
          formatValue: (
            values: CreateCostingItemPayload,
            formValues: CreateCostingCategoryPayload
          ) => {
            const amount =
              values?.quantity && values.cost
                ? calcItemTotal({
                    cost: Number(values.cost) || 0,
                    quantity: Number(values?.quantity),
                  })
                : 0;

            return t("common.currency", { amount });
          },
        },
      ],
    },
  };
};
