import React from "react";
import { Form, Button } from "react-bootstrap";
import classNames from "classnames";

import "./styles.scss";
import Icon from "../../icons/Icon";
import { FormFieldProps } from "../../generic-form/form-field";

type ClientInputContainerProps = FormFieldProps & {
  onAddClientClick: () => void;
};

const ClientInputContainer: React.FC<ClientInputContainerProps> = ({
  name,
  label,
  error,
  touched,
  hint,
  className,
  children,
  onAddClientClick,
}) => {
  return (
    <Form.Group className={className} controlId={name}>
      <Form.Label className="form-input-label">{label}</Form.Label>
      <div
        className={classNames("client-input-container", {
          "is-invalid": touched && !!error,
        })}
      >
        {children}
        <Button className="button large add-icon" onClick={onAddClientClick}>
          <Icon name="add" />
        </Button>
      </div>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      {hint && !error && <Form.Text>{hint}</Form.Text>}
    </Form.Group>
  );
};

export default ClientInputContainer;
