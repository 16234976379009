import React from "react";
import { SidebarNavItem } from "../../../components/dashboard/sidebar/SidebarNavListItem";
import {
  HeaderSearchOption,
  Option,
} from "../../../components/dashboard/header-search";
import { PriceList } from "../../../models/price-list";
import { ContactDetail } from "../../../graphql/types/models/client";
import { JobType } from "../../../models/job";
import { SalesQuote } from "../../../models/salesQuote";
import { SelectionTemplateType } from "../../../graphql/types/models/selections";
import { TodoChecklistTemplates } from "../../../models/task";
import { SpecTemplate } from "../../../models/specification";

export type QuoteNavContext = {
  _id: string;
  name: string;
  businessName: string;
  quoteNumber: number;
  hasFiles: boolean;
  isLocked: boolean;
  markupPercent: number;
  jobId?: string;
};

export type BusinessNavContext = {
  _id: string;
  name: string;
};

export type AffiliateNavContext = {
  _id: string;
  business_name: string;
  first_name: string;
  last_name: string;
};

export type QuoteRequestNavContext = {
  _id: string;
  name: string;
  quoteNumber: string;
};

export type JobNavContext = {
  _id: string;
  name: string;
  contractTotal: number;
  jobNumber?: number;
  contractTotalGST: number;
  businessName: string;
  type?: JobType;
  customer?: ContactDetail;
  salesQuote?: SalesQuote;
  isLocked: boolean;
  isContractLocked: boolean;
  isRetention: boolean;
};

export type LeadContext = {
  _id: string;
  name: string;
};

export type TaskContext = {
  _id: string;
  task: string;
};

export type ClientJobNavContext = {
  _id: string;
  name: string;
  jobNumber: number;
};

export type ClientQouteNavContext = {
  _id: string;
};

export type PriceListContext = PriceList & {};

export type DashboardNavigationContext = {
  affiliate?: AffiliateNavContext;
  quote?: QuoteNavContext;
  quoteRequest?: QuoteRequestNavContext;
  job?: JobNavContext;
  priceList?: PriceListContext;
  selectionTemplate?: SelectionTemplateType;
  business?: BusinessNavContext;
  clientJob?: ClientJobNavContext;
  clientQuote?: ClientQouteNavContext;
  lead?: LeadContext;
  task?: TaskContext;
  checklistTemplate?: TodoChecklistTemplates;
  specificationTemplate?: SpecTemplate;
};

export type DashboardSearchOptions = {
  placeholder?: string;
  onSelect?: (option: HeaderSearchOption | null) => void;
  onAutocomplete?: (input: string) => void;
  options: HeaderSearchOption[];
  filterOptions?: (options: Option, input: string) => boolean;
};

export type DashboardBackNavigationOptions = {
  item: SidebarNavItem;
};

export type DashboardContextValue = {
  navigationContext: DashboardNavigationContext | null;
  setNavigationContext: (context: DashboardNavigationContext | null) => void;
  isSideMenuExpanded: boolean;
  setSideMenuExpanded: (newValue: boolean) => void;
  setNavigationItem: (item: SidebarNavItem | string, initial?: boolean) => void;
  navigationItem: SidebarNavItem | null;
  navigationItems: SidebarNavItem[];
  searchOptions: DashboardSearchOptions | null;
  setSearchOptions: (options: DashboardSearchOptions | null) => void;
  backNavigationOptions: DashboardBackNavigationOptions | null;
  setBackNavigationOptions: (
    options: DashboardBackNavigationOptions | null
  ) => void;
};

export const DashboardContext = React.createContext<DashboardContextValue>(
  null as any
);

export function withDashboardContext<P extends {}>(
  Component: React.FC<P & DashboardContextValue>
) {
  return (props: P) => (
    <DashboardContext.Consumer>
      {(value) => <Component {...value} {...props} />}
    </DashboardContext.Consumer>
  );
}
