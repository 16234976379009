import React from "react";
import { useTranslation } from "react-i18next";
import { map, findIndex } from "lodash";
import moment from "moment-timezone";

import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import { Timesheet, TimesheetCategory } from "../../../models/timesheet";
import { JobTimesheetTableItem } from "../../../models/job-timesheet";
import {
  calcTotalTimesheetValue,
  getTimeInterval,
} from "../../../utils/calculations";
import { formatDuration } from "../../../utils/date";
import { getFullName } from "../../../utils/text";
import { useTimesheetExportQuery } from "../../../hooks/queries/useTimesheetExportQuery";
import "./styles.scss";

type TimesheetsTableProps = {
  jobId?: string;
  isProcessed?: boolean;
  items?: Timesheet[];
  selectedCategory: TimesheetCategory;
  selectedItems: JobTimesheetTableItem[];
  tableTitle: string;
  isDataLoading: boolean;
  processPress: () => void;
  onUnprocess: (tableItem: { jobId: string; timesheetId: string }) => void;
  toggleSelectedItem: (row?: JobTimesheetTableItem) => void;
  openEditModal: (row?: JobTimesheetTableItem) => void;
  openRemoveDialog: (row?: JobTimesheetTableItem) => void;
};

const TimesheetsTable: React.FC<TimesheetsTableProps> = ({
  jobId,
  isProcessed,
  items,
  selectedCategory,
  selectedItems,
  tableTitle,
  isDataLoading,
  processPress,
  toggleSelectedItem,
  onUnprocess,
  openEditModal,
  openRemoveDialog,
}) => {
  const { t } = useTranslation();

  const { exportTimesheets } = useTimesheetExportQuery();

  const timesheetsItemsTableData = React.useMemo<
    TableCardData<JobTimesheetTableItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "task",
          title: t("timesheets.task"),
        },
        {
          valueKey: "creatorName",
          title: t("timesheets.creator"),
        },
        {
          valueKey: "dateTime",
          className: "date-time",
          title: t("jobTimesheets.dateTime"),
        },
        {
          valueKey: "duration",
          title: t("timesheets.duration"),
        },
        // {
        //   valueKey: "rate",
        //   title: t("jobTimesheets.rate"),
        //   formatValue: (row: any, column: any, value: number) => {
        //     const {
        //       cells: { user },
        //     } = row;
        //     return t("common.currency", { amount: user?.hourly_rate ?? 0 });
        //   },
        // },
        // {
        //   valueKey: "total",
        //   title: t("jobTimesheets.total"),
        //   formatValue: (row: any, column: any, value: number) =>
        //     t("common.currency", { amount: value }),
        // },
      ],
      rows: map(items, (item) => {
        const startDate = moment(item.startTime);
        const endDate = moment(item.endTime);

        return {
          cells: {
            ...item,
            creatorName: getFullName(item.user),
            dateTime: getTimeInterval(item.startTime, item.endTime),
            duration: formatDuration(
              endDate.subtract(item.breakDuration, "minutes").diff(startDate),
              t
            ),
            breakDuration: item.breakDuration?.toString(),
            // total: calcTotalTimesheetValue(
            //   item.rate,
            //   item.startTime,
            //   item.endTime,
            //   Number(item.breakDuration)
            // ),
          },
        };
      }),
    };
  }, [t, items]);

  const tableRowActions: TableRowActionData<
    JobTimesheetTableItem
  >[] = React.useMemo(
    () => [
      {
        icon: "edit",
        shouldRender: (row) => !row.is_processed,
        onClick: openEditModal,
      },
      {
        icon: "delete",
        onClick: openRemoveDialog,
        shouldRender: (row) => !row.is_processed,
      },
      {
        icon: "restore",
        title: t("timesheets.unprocess"),
        onClick: (row) =>
          onUnprocess({
            jobId: row.job._id,
            timesheetId: row._id,
          }),
        shouldRender: (row) => row.is_processed,
      },
    ],
    [onUnprocess]
  );

  const tableActions = React.useMemo<TableCardAction[]>(
    () =>
      selectedCategory === TimesheetCategory.UNPROCESSED
        ? [
            {
              title: t(
                selectedItems.length
                  ? "jobTimesheets.processNumber"
                  : "jobTimesheets.process",
                { number: selectedItems.length }
              ),
              onClick: processPress,
              svgIcon: "Invoice",
              className: "button large large-wide info-reverse",
              disabled: !selectedItems.length,
            },
          ]
        : [],
    [t, processPress, selectedItems, selectedCategory]
  );

  const rowActions: TableRowActionData<JobTimesheetTableItem>[] = React.useMemo(
    () => [
      {
        icon: "check_box",
        dropdownId: "select-item",
        onClick: toggleSelectedItem,
        shouldRender: (row?: JobTimesheetTableItem) => {
          if (!row) return false;
          if (row.is_processed) return false;
          return findIndex(selectedItems, { _id: row?._id }) >= 0;
        },
      },
      {
        icon: "check_box_outline_blank",
        dropdownId: "select-item",
        onClick: toggleSelectedItem,
        shouldRender: (row?: JobTimesheetTableItem) => {
          if (!row) return false;
          if (row.is_processed) return false;
          return !(findIndex(selectedItems, { _id: row?._id }) >= 0);
        },
      },
      {
        icon: "comment",
        dropdownId: "note",
        onClick: () => {},
        shouldRender: (row) => !!row.note,
        tooltip: (row) => row.note,
      },
    ],
    [selectedItems, toggleSelectedItem]
  );

  const headerAction = React.useMemo<TableCardAction>(() => {
    return {
      className: "button large info bg-transparent text-light",
      icon: "download",
      onClick: () =>
        exportTimesheets({
          jobId,
          isProcessed,
          userId: "",
          timezone: moment.tz.guess(),
        }),
      title: t("common.export"),
    };
  }, [t, jobId, isProcessed]);

  return (
    <TableCard
      isDataLoading={isDataLoading}
      title={tableTitle}
      data={timesheetsItemsTableData}
      headerAction={headerAction}
      actions={tableActions}
      emptyPlaceholder={{ text: t("jobTimesheets.emptyPlaceholder") }}
      leftRowActions={rowActions}
      rowActions={tableRowActions}
    />
  );
};

export default TimesheetsTable;
