import classNames from "classnames";
import map from "lodash/map";
import React from "react";
import Table from "react-bootstrap/Table";
import { orderBy } from "lodash";

import TableRowActions from "./TableRowActions";
import TableExpandedFields from "./TableExpandedFields";
import {
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
  ExpandedFieldOptions,
  TableCardDataColumn,
} from "./utils";
import { useTranslation } from "react-i18next";
import Cell from "./Cell";
import Icon from "../../icons/Icon";

type CardTableProps<TData> = {
  table: TableCardData<TData>;
  onRowClick?: (row: TableCardDataRow<any>, index?: number) => void;
  RowActionsComponent?: React.ComponentClass<any>;
  leftRowActions?: TableRowActionData<TData>[];
  headerTableLeftRowActions?: any;
  leftRowActionsClassName?: string;
  rowActions?: TableRowActionData<TData>[];
  rowCount?: boolean;
  isDataLoading?: boolean;
  alignEnd?: boolean;
  alignLeftActionsEnd?: boolean;
  showCountTitle?: boolean;
  tableClass?: string;
  tableId?: string;
  hideColumns?: boolean;
  emptyTableText?: string;
  expandedFieldTitle?: string;
  expandedFieldOptions?: ExpandedFieldOptions<any>;
};

type TableSort = {
  column: string;
  direction: "asc" | "desc";
};

const CardTable: React.FC<CardTableProps<any>> = ({
  table,
  tableId,
  onRowClick,
  rowActions,
  leftRowActions,
  headerTableLeftRowActions,
  leftRowActionsClassName = "",
  RowActionsComponent = TableRowActions,
  rowCount,
  isDataLoading,
  alignLeftActionsEnd,
  alignEnd,
  showCountTitle,
  tableClass,
  hideColumns = false,
  emptyTableText,
  expandedFieldTitle,
  expandedFieldOptions,
}) => {
  const { t } = useTranslation();
  const [tableSort, setTableSort] = React.useState<TableSort | null>(null);
  const isClickableRow = !!onRowClick;
  const tableSortId = ["tableSort", tableId].join("_");

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<any>, index: number) => () => {
      onRowClick && onRowClick(row, index);
    },
    [onRowClick]
  );

  const rowData = React.useMemo(() => {
    if (tableSort) {
      return orderBy(table.rows, (r) => r.cells[tableSort.column], [
        tableSort.direction,
      ]);
    }
    return table.rows;
  }, [table, tableSort]);

  React.useEffect(() => {
    if (!tableId) return;
    const storedSort = localStorage.getItem(tableSortId);
    if (storedSort) {
      try {
        const parsedSort = JSON.parse(storedSort);
        setTableSort(parsedSort as TableSort);
      } catch {}
    }
  }, [tableSortId]);

  React.useEffect(() => {
    if (tableSort) {
      localStorage.setItem(tableSortId, JSON.stringify(tableSort));
    }
  }, [tableSort]);

  const toggleSort = React.useCallback(
    (column: string) => () => {
      if (tableSort && tableSort.column === column) {
        return setTableSort({
          column,
          direction: tableSort?.direction === "asc" ? "desc" : "asc",
        });
      }
      setTableSort({ column, direction: tableSort?.direction || "asc" });
    },
    [tableSort]
  );

  const renderRow: any = (
    row: TableCardDataRow<any>,
    index: number,
    indexStr: string,
    isSubRow?: boolean
  ) => (
    <>
      <tr
        key={`row-${index}`}
        className={classNames("table-row", {
          "table_row--clickable": isClickableRow,
          "table-row--subrow": isSubRow,
          "table-row--total": row.isTotal,
        })}
        onClick={handleRowClick(row, index)}
      >
        {leftRowActions && leftRowActions?.length > 0 && (
          <td className={leftRowActionsClassName}>
            <RowActionsComponent
              alignEnd={alignLeftActionsEnd}
              row={row}
              rowIndex={index}
              actions={leftRowActions}
            />
          </td>
        )}
        {rowCount && (
          <td className="field-text count-cell">
            {!row.isTotal && `${indexStr}.`}
          </td>
        )}
        {map(table.columns, (col, index) => (
          <Cell col={col} row={row} key={`cell-${index}`} />
        ))}
        {expandedFieldOptions?.expandedField && row.cells && (
          <td>
            <TableExpandedFields
              data={row.cells[expandedFieldOptions?.expandedField]}
              fieldOptions={expandedFieldOptions}
            />
          </td>
        )}
        {rowActions && (
          <td>
            <RowActionsComponent
              alignEnd={alignEnd}
              row={row}
              rowIndex={index}
              actions={rowActions}
            />
          </td>
        )}
      </tr>
      {row.subRows &&
        row.subRows.map((subRow, subIndex) =>
          renderRow(subRow, subIndex, `${indexStr}.${subIndex + 1}`, true)
        )}
      {row.renderSubRow?.(row, index)}
    </>
  );

  // Do not convert to .useCallback() - Job costing selection stops working
  const renderTableBody = () => {
    if (!isDataLoading) {
      if (table.rows.length === 0) {
        return (
          <tr className="disabled-row">
            <td
              colSpan={
                table.columns.length +
                (rowActions?.length || 0) +
                (leftRowActions?.length || 0) +
                (rowCount ? 1 : 0)
              }
              className="text-center field-text"
            >
              <i>{emptyTableText || t("common.noData")}</i>
            </td>
          </tr>
        );
      }
      return map(rowData, (row, index) =>
        renderRow(row, index, `${index + 1}`)
      );
    } else {
      return (
        <tr className="disabled-row">
          {map(table.columns, (column, index) => (
            <td key={`row-${index}`} className="disabled-column" />
          ))}
        </tr>
      );
    }
  };

  const renderHeaderCell = React.useCallback(
    (col: TableCardDataColumn<any>) => {
      let icon = null;
      if (tableSort?.column === col.valueKey) {
        icon = (
          <Icon
            name={
              tableSort?.direction === "asc" ? "arrow_upward" : "arrow_downward"
            }
          />
        );
      }
      return (
        <th
          key={col.valueKey as string}
          className={classNames("field-text", col.className, {
            "table-header--sortable": col.sortable,
          })}
          onClick={
            col.sortable ? toggleSort(col.valueKey.toString()) : undefined
          }
        >
          <div>
            {col.title}
            {icon}
          </div>
        </th>
      );
    },
    [tableSort]
  );

  const headerClasses = classNames("table-header", {
    "table-header--disabled": isDataLoading,
  });

  return (
    <Table className={classNames("table", tableClass)} hover responsive>
      {!hideColumns && (
        <thead className={headerClasses}>
          <tr>
            {leftRowActions &&
              leftRowActions?.length > 0 &&
              RowActionsComponent &&
              table.rows.length > 0 &&
              (Boolean(headerTableLeftRowActions) ? (
                headerTableLeftRowActions()
              ) : (
                <th />
              ))}
            {!isDataLoading && rowCount && (
              <th className="count-cell field-text">
                {showCountTitle && t("common.number")}
              </th>
            )}
            {map(table.columns, renderHeaderCell)}
            {expandedFieldOptions?.expandedField && expandedFieldTitle && (
              <th className="field-text">{expandedFieldTitle}</th>
            )}
            {rowActions && RowActionsComponent && <th />}
          </tr>
        </thead>
      )}
      <tbody className="table-body">{renderTableBody()}</tbody>
    </Table>
  );
};

export default CardTable;
