import React, { MouseEventHandler } from "react";

import "./styles.scss";
import { TableCardDataRow, TableRowActionData } from "./utils";
import { map } from "lodash";
import TableRowAction from "./TableRowAction";
import classNames from "classnames";

type CardTableRowActionsProps = {
  row: TableCardDataRow<any>;
  rowIndex: number;
  actions: TableRowActionData<any>[];
  alignEnd?: boolean;
};

const TableRowActions: React.FC<CardTableRowActionsProps> = ({
  row,
  actions,
  alignEnd,
}) => {
  const containerClasses = classNames("d-flex row-actions", {
    "justify-content-end": alignEnd,
  });

  const handleActionClick = React.useCallback<
    MouseEventHandler<HTMLDivElement>
  >((event) => {
    event.stopPropagation();
  }, []);

  return (
    <div className={containerClasses} onClick={handleActionClick}>
      {map(actions, (action, key) => (
        <TableRowAction key={key} action={action} row={row} />
      ))}
    </div>
  );
};

export default TableRowActions;
