import React, { useState } from "react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import CreateEntityModal from "../../modals/create-entity";
import UpdateEntityModal from "../../modals/update-entity";
import { createCategoryField } from "./utils";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createCategorySchema from "./CreateCategory.schema";
import {
  CreateSelectionCategoryPayload,
  SelectionCategory,
} from "../../../graphql/types/models/selections";

type CreateCategoryModalProps = {
  data?: SelectionCategory | null;
  show: boolean;
  onSubmit: (data: CreateSelectionCategoryPayload) => void;
  onClose: () => void;
  isTemplate?: boolean;
};

const CreateUpdateCategoryModal: React.FC<CreateCategoryModalProps> = (
  props
) => {
  const { data, show, onSubmit, onClose, isTemplate } = props;
  const { t } = useTranslation();

  const [fields, setFields] = useState<
    GenericFormFields<CreateSelectionCategoryPayload>
  >({});

  React.useEffect(() => {
    setFields(createCategoryField(t, isTemplate));
  }, [t, isTemplate]);

  const handleSubmit = React.useCallback(
    (data: CreateSelectionCategoryPayload, helpers?: FormikHelpers<any>) => {
      onSubmit && onSubmit(data);
      helpers?.resetForm();
    },
    [onSubmit]
  );

  return (
    <>
      {data ? (
        <UpdateEntityModal<CreateSelectionCategoryPayload>
          validationSchema={createCategorySchema(t)}
          title={t("selections.editCategory")}
          data={{
            name: data.name || "",
            description: data.description || "",
            dueDate: data.dueDate || "",
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      ) : (
        <CreateEntityModal<CreateSelectionCategoryPayload>
          validationSchema={createCategorySchema(t)}
          title={t("selections.createNewCategory")}
          data={{
            name: "",
            description: "",
            dueDate: "",
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default CreateUpdateCategoryModal;
