import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router-dom";

type UseViewInJobProps = {
  jobId?: string;
  orderId: string;
  path: string;
  isGlobalView: boolean;
};

export function useViewInJob(props: UseViewInJobProps) {
  const { jobId, orderId, path, isGlobalView } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const handleViewJob = React.useCallback(() => {
    if (!jobId) return;
    const url = generatePath("/jobs/:id/:path/:orderId", {
      id: jobId,
      orderId,
      path: path.toLowerCase(),
    });
    history.push(url);
  }, [jobId, orderId, path]);

  const buttons = React.useMemo(() => {
    if (!isGlobalView) return [];
    return [
      {
        id: "edit",
        label: t("jobs.viewInJob"),
        name: "viewJob",
        onClick: handleViewJob,
      },
    ];
  }, [isGlobalView, handleViewJob]);

  return { buttons };
}
