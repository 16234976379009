import React from "react";
import CommunicationList from "../../communication/communication-list";
import { useTranslation } from "react-i18next";
import { Reminder as ReminderType } from "../../../models/reminder";
import { map, isEmpty } from "lodash";
import Reminder from "../reminder-item";

type ReminderListProps = {
  reminders?: ReminderType[];
  onNewClick?: () => void;
  onStatusChange: (id: string, currentStatus: boolean) => void;
  onDelete: (id: string) => void;
  loading?: boolean;
  clientName?: string;
  className?: string;
};

const ReminderList: React.FC<ReminderListProps> = (props) => {
  const {
    reminders,
    onNewClick,
    onStatusChange,
    onDelete,
    loading,
    clientName,
    className,
  } = props;

  const { t } = useTranslation();

  const isRemindersListEmpty = isEmpty(reminders);

  const renderItems = () => {
    return map(reminders, (reminder) => (
      <Reminder
        onDelete={onDelete}
        key={reminder._id}
        {...reminder}
        onStatusChange={onStatusChange}
      />
    ));
  };

  return (
    <CommunicationList
      title={t("reminders.reminders")}
      onNewClick={onNewClick}
      loading={loading}
      emptyButtonText={t("reminders.createReminder")}
      empty={isRemindersListEmpty}
      emptyMessage={t("reminders.remindersEmpty", { clientName })}
      className={`reminder-list ${className}`}
    >
      {renderItems()}
    </CommunicationList>
  );
};

export default ReminderList;
