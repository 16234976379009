import { gql } from "@apollo/client";
import { SUBSCRIPTION_PLAN } from "./fragments";

export const SUBSCRIPE_TO_PLAN = gql`
  mutation($planId: ID!) {
    subscribeToPlan(planId: $planId) {
      subscriptionId
      clientSecret
      requiresPaymentMethod
    }
  }
`;
export const CANCEL_SUBSCRIPTION = gql`
  mutation {
    cancelSubscription {
      ...Plan
    }
  }
  ${SUBSCRIPTION_PLAN}
`;

export const ADD_PAYMENT_METHOD = gql`
  mutation {
    addPaymentMethod {
      subscriptionId
      clientSecret
      requiresPaymentMethod
    }
  }
`;
