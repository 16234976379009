import React from "react";
import { Row, Col, Container, BadgeProps, Badge } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import moment from "moment";

import {
  AffiliateBusiness,
  AffiliateBusinessStatus,
  AffiliateBusinessTableItem,
  AffiliateBusinessesResponse,
} from "../../../graphql/types/models/affiliate";
import { LIST_AFFILIATE_BUSINESSES } from "../../../graphql/queries/affiliate/queries";
import { TableCardData } from "../../../components/dashboard/table-card/utils";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/affiliate-navigation-items";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import TableCard from "../../../components/dashboard/table-card";
import { DATE_FORMAT_DATE_STRING } from "../../../constants/dates";

export const getAffiliateBusinessStatusBadge = (
  status: AffiliateBusinessStatus
) => {
  let label = status;
  let variant: BadgeProps["variant"] = "info";
  switch (status) {
    case AffiliateBusinessStatus.ACTIVE:
      variant = "success";
      break;
    case AffiliateBusinessStatus.TRIALING:
      variant = "warning";
      break;
    case AffiliateBusinessStatus.EXPIRED:
      variant = "danger";
      break;
    default:
      variant = "primary";
  }
  return {
    label,
    variant,
  };
};

const AffiliateSignups: React.FC = () => {
  const { t } = useTranslation();

  const { data: businessesData, loading } = useQuery<
    AffiliateBusinessesResponse
  >(LIST_AFFILIATE_BUSINESSES);

  const businesses = React.useMemo(
    () => businessesData?.affiliateListBusinesses || [],
    [businessesData]
  );

  const tableData = React.useMemo<
    TableCardData<AffiliateBusinessTableItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("affiliate.businessName"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "subscriptionPlanName",
          title: t("affiliate.subscriptionPlanName"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "subscriptionPlanValue",
          title: t("affiliate.subscriptionPlanValue"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "status",
          title: t("affiliate.status"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => {
            const { label, variant } = getAffiliateBusinessStatusBadge(
              value as AffiliateBusinessStatus
            );
            return <Badge variant={variant}>{label}</Badge>;
          },
        },
        {
          valueKey: "createdAt",
          title: t("affiliate.signUpDate"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format(DATE_FORMAT_DATE_STRING),
        },
      ],
      rows: map(
        businessesData?.affiliateListBusinesses,
        (item: AffiliateBusiness) => ({
          cells: {
            ...item,
            subscriptionPlanName: item.subscriptionPlan?.name || "",
            subscriptionPlanValue: Number(item.subscriptionPlan?.price) || 0,
          },
        })
      ),
    };
  }, [businessesData, t]);

  return (
    <Container fluid className="h-100">
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SIGNUPS_SECTION.SIGNUPS} />

      <Row className="h-100">
        <Col xs={12} className="client-jobs-table-container pb-sm-5 pb-lg-0">
          {businesses.length > 0 ? (
            <TableCard isDataLoading={loading} alignEnd data={tableData} />
          ) : (
            <EmptyPlaceholder
              message={t("affiliate.emptySignupsPlaceholder")}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AffiliateSignups;
