import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { get, round } from "lodash";
import { useMutation } from "@apollo/client";

import CreateEntityModal from "../../modals/create-entity";
import { createClaimReceiveFields } from "./utils";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import {
  ReceiveClaimPayload,
  ReceiveClaimResponse,
} from "../../../graphql/types/models/job-claim";
import { RECEIVE_CLAIM } from "../../../graphql/queries/job-claim/mutation";
import { notify } from "../../notification";
import createClaimReceiveSchema from "./claimReceiveSchema";
import { Row } from "react-bootstrap";
import { useProgressClaimQuery } from "../../../hooks/queries/useProgressClaimQuery";
import { UpdateTextInputFn } from "../../generic-form/GenericFormBody";

type ClaimReceiveModalProps = {
  jobId?: string;
  claimId: string;
};
const ClaimReceiveModal: React.FC<ClaimReceiveModalProps> = (props, ref) => {
  const { jobId, claimId } = props;
  const { t } = useTranslation();

  const { shouldShow, hide } = useModalDisplay(ref);

  const { progressClaim } = useProgressClaimQuery(jobId, claimId);

  const outstandingTotal = React.useMemo(() => {
    return round(progressClaim?.outstandingTotal || 0, 5);
  }, [progressClaim]);

  const [receiveClaim] = useMutation<ReceiveClaimResponse>(RECEIVE_CLAIM, {
    onCompleted: () => {
      notify({
        title: t("claims.receiveClaim"),
        content: t("claims.success.receiveClaim"),
      });
      hide();
    },
    onError: (e) => {
      notify({
        error: true,
        title: t("claims.receiveClaim"),
        content: get(e, "message", t("claims.errors.receiveClaim")),
      });
    },
  });

  const renderOutstanding = React.useCallback(() => {
    const amount = t("common.currency", { amount: outstandingTotal });
    return (
      <Row className="unclaimed-message">
        {t("claims.outstandingMessage", { amount })}
      </Row>
    );
  }, [outstandingTotal]);

  const handleSubmit = React.useCallback(
    (values: ReceiveClaimPayload) => {
      receiveClaim({
        variables: {
          jobId,
          progressClaimId: claimId,
          received: {
            ...values,
            amount: Number(values.amount) || 0,
          },
        },
      });
    },
    [jobId, claimId]
  );

  const initialValues = React.useMemo(() => {
    return {
      amount: outstandingTotal,
      reference: "",
      dateReceived: moment().startOf("day").toISOString(),
      note: "",
    };
  }, [outstandingTotal]);

  return (
    <CreateEntityModal
      validationSchema={createClaimReceiveSchema(t, outstandingTotal)}
      className="claim-receive-modal"
      title={t("claims.receiveClaim")}
      show={shouldShow}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={hide}
      submitText={t("common.receive")}
      fields={createClaimReceiveFields(t)}
      topRenderer={renderOutstanding()}
    />
  );
};

export default forwardRef(ClaimReceiveModal);
