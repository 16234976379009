import { WithT } from "i18next";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthenticationCard from "../authentication-card";
import SignUpActivateBody from "./SignUpActivateBody";
import SignUpActivateFooter from "./SignUpActivateFooter";
import "./styles.scss";

type SignUpActivateProps = {
  onResendClick: (e: any) => void;
};

const SignUpActivate: React.FC<SignUpActivateProps> = ({ onResendClick }) => {
  const { t } = useTranslation();

  return (
    <AuthenticationCard
      title={t("authentication.activateYourAccount")}
      container={Form}
      containerProps={{
        className: "authentication-card-container sign-up-activate-card",
      }}
      body={SignUpActivateBody}
      bodyProps={{ t }}
      footer={SignUpActivateFooter}
      footerProps={{
        t,
        onResendClick,
      }}
    />
  );
};

export default SignUpActivate;
