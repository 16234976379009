import React from "react";
import { useTranslation } from "react-i18next";

import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import ScaleSummaryItem from "./ScaleSummaryItem";
import { SalesQuoteFile } from "../../../models/salesQuote";
import "./styles.scss";

type ScaleSummaryPagesProps = {
  files?: SalesQuoteFile[];
  selectedPage?: SalesQuoteFile | null;
  selectPage?: (page: number) => void;
  checkScaleValue?: (page: SalesQuoteFile) => string;
};

const ScaleSummaryPages: React.FC<ScaleSummaryPagesProps> = ({
  files,
  selectedPage,
  selectPage,
  checkScaleValue,
}) => {
  const { t } = useTranslation();

  const renderCategories = React.useCallback(() => {
    return (
      <div className="summary-items">
        {files?.length &&
          files.map((file, index) => {
            return (
              <ScaleSummaryItem
                key={file._id}
                page={file}
                onClick={selectPage}
                pageNumber={index + 1}
                t={t}
                isSelected={file._id === selectedPage?._id}
                checkScaleValue={checkScaleValue}
              />
            );
          })}
      </div>
    );
  }, [files, selectPage, selectedPage]);

  return (
    <DashboardCard className="scale-summary-pages">
      <DashboardCardHeader className="text-capitalize centered">
        <div className="d-flex align-items-center">
          {t("plansSection.pageSummary")}
        </div>
      </DashboardCardHeader>
      <DashboardCardBody>
        <div className="quote-specification-body">{renderCategories()}</div>
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end"></DashboardCardFooter>
    </DashboardCard>
  );
};

export default ScaleSummaryPages;
