import { TFunction } from "i18next";
import { map } from "lodash";
import { GenericFormFields } from "../../../../../components/generic-form/GenericFormBody";
import { AutocompleteInputOption } from "../../../../../components/generic-form/inputs/autocomplete";
import { SalesQuote } from "../../../../../models/salesQuote";
import { formatQuoteNumber } from "../../../../../utils/text";

const linkToEstimationFields = (
  t: TFunction,
  estimationsList: SalesQuote[]
): GenericFormFields<{ estimateId: string }> => {
  const estimationsOptions: AutocompleteInputOption[] = map(
    estimationsList,
    (estimation: SalesQuote) => ({
      value: estimation._id,
      label: `${formatQuoteNumber(estimation.number, "Q")} ${estimation.name}`,
    })
  );
  return {
    estimateId: {
      type: "select",
      label: t("leads.estimation"),
      placeholder: t("leads.estimation"),
      valueKey: "estimateId",
      autocompleteProps: {
        options: estimationsOptions,
      },
      inputProps: {
        required: true,
      },
    },
  };
};

export default linkToEstimationFields;
