import { gql } from "@apollo/client";

export const GET_CALENDAR = gql`
  query($startDate: String!, $endDate: String!, $filter: CalendarFilter) {
    getCalendar(startDate: $startDate, endDate: $endDate, filter: $filter) {
      purchaseOrders {
        _id
        orderNumber
        reference
        date
        deliveryDate
        job {
          _id
          name
        }
        supplier {
          _id
          business_name
          contact_person
        }
      }
      scheduleItems {
        _id
        name
        startDate
        endDate
        user {
          _id
          first_name
          last_name
        }
        job {
          _id
          name
        }
      }
      todos {
        _id
        task
        description
        completed
        dueDate
      }
    }
  }
`;
