import React, { FC } from "react";
import { compact, map } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

import CardTable from "../../../components/dashboard/table-card/CardTable";
import { TableRowActionData } from "../../../components/dashboard/table-card/utils";
import { User } from "../../../graphql/types/models/user";
import { TaskStatus } from "../../../models/task";
import { getFullName } from "../../../utils/text";
import EmptyPlaceholder from "../../../components/empty-placeholder";

type ChecklistProps<SChecklistItem> = {
  checklist: SChecklistItem[];
  onToggleChecklistStatus: (row: SChecklistItem) => void;
  onEditChecklist?: (row?: SChecklistItem) => void;
  onDeleteChecklist?: (row?: SChecklistItem) => void;
  hideTaskOperations?: boolean;
};

const Checklist = <SChecklistItem extends {}>(
  props: ChecklistProps<SChecklistItem>
) => {
  const {
    checklist,
    onToggleChecklistStatus,
    onEditChecklist,
    onDeleteChecklist,
    hideTaskOperations,
  } = props;
  const { t } = useTranslation();

  const checklistTableData = () => {
    return {
      columns: compact([
        {
          valueKey: "task",
          title: t("tasks.task"),
          sortable: true,
        },
        {
          valueKey: "dueDate",
          title: t("tasks.dueDate"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            value ? moment(value).format("Do MMM YYYY") : "",
        },
        {
          valueKey: "completed",
          title: t("tasks.status"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => {
            const overDue = moment().isAfter(
              moment(row?.cells?.dueDate).endOf("day")
            );
            return (
              <span
                className={
                  value
                    ? "badge badge-success"
                    : overDue
                    ? "badge badge-danger"
                    : "badge badge-warning"
                }
              >
                {value
                  ? TaskStatus.COMPLETE
                  : overDue
                  ? TaskStatus.OVERDUE
                  : TaskStatus.INCOMPLETE}
              </span>
            );
          },
        },
        {
          valueKey: "assignedTo",
          title: t("tasks.assignedTo"),
          sortable: true,
          formatValue: (row: any, column: any, value: User) =>
            getFullName(value),
        },
        {
          valueKey: "completedBy",
          title: t("tasks.completedBy"),
          sortable: true,
          formatValue: (row: any, column: any, value: User) =>
            getFullName(value),
        },
      ]),
      rows: map(checklist, (item) => ({
        cells: {
          ...item,
        },
      })),
    };
  };

  const tableRowActions: TableRowActionData<SChecklistItem>[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "tasks-list",
        options: [
          {
            icon: "edit",
            outlined: true,
            id: "edit",
            label: t("tasks.editTask"),
            onClick: (row?: SChecklistItem) => onEditChecklist?.(row),
          },
          {
            icon: "delete",
            outlined: true,
            id: "delete",
            label: t("tasks.deleteTask"),
            onClick: (row?: SChecklistItem) => onDeleteChecklist?.(row),
          },
        ],
      },
    ],

    [t, onEditChecklist, onDeleteChecklist]
  );

  const tableLeftRowActions = [
    {
      icon: "check_box",
      dropdownId: "document",
      onClick: (row: any) => onToggleChecklistStatus(row),
      shouldRender: (row: any) => row.completed,
    },
    {
      icon: "check_box_outline_blank",
      dropdownId: "document",
      onClick: (row: any) => onToggleChecklistStatus(row),
      shouldRender: (row: any) => !row.completed,
    },
  ];

  if (checklist.length === 0) {
    return <EmptyPlaceholder message={t("tasks.emptyChecklistsPlaceholder")} />;
  }
  return (
    <CardTable
      tableId="checklistTasks"
      table={checklistTableData()}
      alignEnd={true}
      rowActions={!hideTaskOperations ? tableRowActions : undefined}
      leftRowActions={tableLeftRowActions}
      // isDataLoading={isLoading}
      emptyTableText={t("tasks.emptyChecklistsItemsPlaceholder")}
    />
  );
};

export default Checklist;
