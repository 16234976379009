import { gql } from "@apollo/client";
import { ROSTER_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_ROSTER = gql`
  mutation($jobId: ID!, $roster: RosterInput!) {
    jobCreateUpdateRoster(jobId: $jobId, roster: $roster) {
      ...RosterData
    }
  }
  ${ROSTER_FRAGMENT}
`;

export const APPROVE_ROSTER = gql`
  mutation($jobId: ID!, $rosterId: ID!) {
    jobApproveRoster(jobId: $jobId, rosterId: $rosterId) {
      ...RosterData
    }
  }
  ${ROSTER_FRAGMENT}
`;

export const UNAPPROVE_ROSTER = gql`
  mutation($jobId: ID!, $rosterId: ID!) {
    jobUnapproveRoster(jobId: $jobId, rosterId: $rosterId) {
      ...RosterData
    }
  }
  ${ROSTER_FRAGMENT}
`;

export const DELETE_ROSTER = gql`
  mutation($jobId: ID!, $rosterId: ID!) {
    jobDeleteRoster(jobId: $jobId, rosterId: $rosterId) {
      ...RosterData
    }
  }
  ${ROSTER_FRAGMENT}
`;

export const SEND_ROSTER = gql`
  mutation($jobId: ID!, $rosterId: ID!, $message: JobSendEmailInput!) {
    jobSendRoster(jobId: $jobId, rosterId: $rosterId, message: $message) {
      _id
      status
    }
  }
`;
