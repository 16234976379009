import React from "react";
import { FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { TFunction } from "react-i18next";
import JobCostingsModal, {
  CostingsfieldName,
} from "../components/claims/job-costings-modal";

export function useJobCostings(
  t: TFunction,
  fieldName: CostingsfieldName,
  jobId?: string
) {
  const [showJobCostingsModal, setShowJobCostingsModal] = React.useState(false);

  const closeJobCostingsModal = React.useCallback(() => {
    setShowJobCostingsModal(false);
  }, []);

  const addCostingItemButton = React.useCallback(() => {
    return (
      <Button
        className="button info mb-1"
        onClick={() => setShowJobCostingsModal(true)}
      >
        {t("common.addItem")}
      </Button>
    );
  }, [t]);

  const renderJobCostingsModal = React.useCallback(
    (formikProps: FormikProps<any>) => {
      return (
        <JobCostingsModal
          show={showJobCostingsModal}
          onClose={closeJobCostingsModal}
          formikProps={formikProps}
          fieldName={fieldName}
          jobId={jobId}
        />
      );
    },
    [closeJobCostingsModal, fieldName, showJobCostingsModal, jobId]
  );

  return { addCostingItemButton, renderJobCostingsModal };
}
