import React from "react";
import moment from "moment";
import Icon from "../../icons/Icon";
import classNames from "classnames";
import {
  EnumSelectionStatus,
  SelectionCategory,
} from "../../../graphql/types/models/selections";
import SelectionBudge from "../badge";

type SelectionCategoryItemProps = {
  isSelected: boolean;
  isClient?: boolean;
  isTemplate?: boolean;
  selectedItemsCount?: string;
  category: SelectionCategory;
  onDelete?: (id: string) => void;
  onClick?: (category: SelectionCategory) => void;
};

const SelectionCategoryItem: React.FC<SelectionCategoryItemProps> = (props) => {
  const {
    isSelected,
    category,
    onDelete,
    onClick,
    isClient,
    isTemplate,
    selectedItemsCount,
  } = props;

  const isCompleteOrSelectedCategory =
    category.status === EnumSelectionStatus.COMPLETED ||
    category.status === EnumSelectionStatus.SELECTED;
  const overDue = React.useMemo(() => {
    return moment().isAfter(moment(category.dueDate).endOf("day"));
  }, [category.dueDate]);

  const isSelectedCategories = React.useMemo(() => {
    const selectedCategoriesCount = category?.items.filter(
      (item) =>
        item.status !== EnumSelectionStatus.SELECTED &&
        item.status !== EnumSelectionStatus.COMPLETED
    );
    return Boolean(selectedCategoriesCount?.length);
  }, [category]);

  const getItemClass = (): string => {
    return classNames("quote-selection-list-item", {
      "selection-category-selected": isSelected,
    });
  };

  const handleDelete = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onDelete && onDelete(category._id);
    },
    [onDelete, category]
  );

  const handleClick = React.useCallback(() => {
    return onClick && onClick(category);
  }, [onClick, category]);

  const hasUnallocatedItems = category.items.find((item) => !item?.costingItem);

  return (
    <div className={getItemClass()} onClick={handleClick}>
      <div className="item-name">
        {hasUnallocatedItems && !isClient && !isTemplate && (
          <Icon
            className="unallocatedItems-icon"
            name="error_outline"
            outlined
          />
        )}
        {category.name}
      </div>
      <div className="right-side">
        {!isTemplate && (
          <>
            {isClient && <span>{selectedItemsCount}</span>}
            <SelectionBudge
              status={
                isClient
                  ? isCompleteOrSelectedCategory
                    ? category.status
                    : overDue
                    ? !isSelectedCategories
                      ? EnumSelectionStatus.INCOMPLETE
                      : EnumSelectionStatus.OVERDUE
                    : EnumSelectionStatus.INCOMPLETE
                  : category.status
              }
              className="mr-2"
            />
          </>
        )}
        {!!onDelete && (
          <div className="remove-action" onClick={handleDelete}>
            <Icon name="clear" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectionCategoryItem;
