import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { map } from "lodash";
import moment from "moment";

import {
  withDashboardContext,
  DashboardContextValue,
} from "../../layouts/dashboard/DashboardContext";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/admin-navigation-items";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import {
  BusinessesResponse,
  Business,
} from "../../../graphql/types/models/business";
import TableCard from "../../../components/dashboard/table-card";
import { EmptyTablePlaceholder } from "../../../components/dashboard/table-card";
import { WB_LIST_BUSINESS } from "../../../graphql/queries/wb-admin/business/queries";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";
import CreateBusinessModal from "../../../components/admin/create-business-modal";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import DashboardActionHeader from "../../../components/dashboard/table-card/DashboardActionHeader";

type BusinessRequestTableItem = Business & {
  planName?: string;
};

type BusinessContainerProps = DashboardContextValue;

const BusinessContainer: React.FC<BusinessContainerProps> = ({
  navigationContext,
  setNavigationContext,
  setSearchOptions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const createBusinessRef = React.useRef<ModalDisplayRef>();

  const { data, loading } = useQuery<BusinessesResponse>(WB_LIST_BUSINESS);

  const businesses = React.useMemo(() => data?.businesses ?? [], [data]);

  const { filteredArray, setFilter } = useFilteredArrayMultipleField(
    businesses,
    ["name", "email", "phone_number", "address", "city", "state", "postcode"],
    true
  );

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("common.search"),
      options: [],
      onAutocomplete: setFilter,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [t, setSearchOptions, setFilter]);

  const tableData = React.useMemo<
    TableCardData<BusinessRequestTableItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("company.companyName"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "abn",
          title: t("company.abnNumber"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "total_users",
          title: t("company.numberOfUsers"),
          formatValue: (row: any, column: any, value: number) => value,
        },
        {
          valueKey: "phone_number",
          title: t("company.phone"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "email",
          title: t("team.email"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "planName",
          title: t("businesses.planName"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "subscriptionEndDate",
          title: t("businesses.subscriptionEndDate"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
      ],
      rows: map(filteredArray, (item: Business) => ({
        cells: {
          ...item,
          total_users: item.users?.length,
          planName: item.subscriptionPlan?.name,
        },
      })),
    };
  }, [filteredArray, t]);

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<BusinessRequestTableItem>) => {
      setNavigationContext({
        ...navigationContext,
        business: {
          _id: row.cells._id || "",
          name: row.cells.name,
        },
      });
      history.push(`/businesses/${row.cells._id}`);
    },
    [setNavigationContext]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("businesses.emptyPlaceholder"),
    }),
    [t]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.wbAdmin.WunderbuidConsole")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.ADMIN_SECTION.BUSINESSES}
      />
      <Row className="justify-content-end pb-4">
        <Col>
          <DashboardActionHeader
            className="large-wide"
            actionButtonTitle={t("wbAdmin.createBusiness")}
            onActionButton={() => createBusinessRef.current?.show(true)}
            shouldRenderActionButton
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TableCard
            isDataLoading={loading}
            data={tableData}
            alignEnd
            onRowClick={handleRowClick}
            // rowActions={quotesTableRowActions}
            emptyPlaceholder={emptyPlaceholder}
          />
        </Col>
      </Row>
      <CreateBusinessModal ref={createBusinessRef} />
    </Container>
  );
};

export default withDashboardContext(BusinessContainer);
