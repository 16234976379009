import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { SendEmailForm } from "../../../models/email";
import { TFunction } from "i18next";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import { validateEmail } from "./SendModal.schema";
import { compact } from "lodash";

export const validateOption = (email: string) => {
  return !!email && validateEmail(email);
};

export type CopyMailOptions = {
  showCc?: boolean;
  setShowCc?: (value: boolean) => void;
  showBcc?: boolean;
  setShowBcc?: (value: boolean) => void;
  setReplyTo?: (value: boolean) => void;
  teamMembers: SelectOption[];
  showReplyTo?: boolean;
  replyTo?: boolean;
};

export const createQuoteEmailField = (
  t: TFunction,
  contacts: SelectOption[],
  onRecipientCreate: (value: string) => void,
  disableContactSelect?: boolean,
  copyOptions?: CopyMailOptions,
  replyToOptions?: SelectOption[]
): GenericFormFields<SendEmailForm> => {
  return {
    to: {
      type: "creatable-select",
      label: t("common.to"),
      placeholder: t("placeholders.recipients"),
      valueKey: "to",
      creatableProps: {
        autoSelectCreatedOption: true,
        onCreateOption: onRecipientCreate,
        isMulti: true,
        options: contacts,
        isValidNewOption: validateOption,
        isDisabled: !!disableContactSelect,
      },
    },
    ...(copyOptions && {
      options: {
        withoutTitle: true,
        toggles: compact([
          copyOptions?.setShowCc && {
            name: "ccToggle",
            label: t("common.cc"),
            onChange: copyOptions?.setShowCc,
          },
          copyOptions?.setShowBcc && {
            name: "bccToggle",
            label: t("common.bcc"),
            onChange: copyOptions?.setShowBcc,
          },
          copyOptions?.showReplyTo && {
            name: "reaplyToToggle",
            label: t("common.replyTo"),
            onChange: copyOptions?.setReplyTo,
          },
        ]),
        row: [],
      },
    }),
    ...(copyOptions?.showCc && {
      cc: {
        type: "creatable-select",
        label: t("common.cc"),
        placeholder: t("placeholders.recipients"),
        valueKey: "cc",
        creatableProps: {
          autoSelectCreatedOption: true,
          onCreateOption: onRecipientCreate,
          isMulti: true,
          options: copyOptions.teamMembers,
          isValidNewOption: validateOption,
        },
      },
    }),
    ...(copyOptions?.showBcc && {
      bcc: {
        type: "creatable-select",
        label: t("common.bcc"),
        placeholder: t("placeholders.recipients"),
        valueKey: "bcc",
        creatableProps: {
          autoSelectCreatedOption: true,
          onCreateOption: onRecipientCreate,
          isMulti: true,
          options: copyOptions.teamMembers,
          isValidNewOption: validateOption,
        },
      },
    }),
    ...(copyOptions?.replyTo && {
      replyTo: {
        type: "creatable-select",
        label: t("common.replyTo"),
        placeholder: t("common.email"),
        valueKey: "replyTo",
        creatableProps: {
          options: replyToOptions,
        },
      },
    }),

    title: {
      type: "text",
      label: t("common.subject"),
      placeholder: t("placeholders.subject"),
      valueKey: "title",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    message: {
      type: "rich-editor",
      label: t("common.body"),
      placeholder: t("placeholders.body"),
      valueKey: "message",
      richEditorProps: {
        textKey: "messageText",
        rawKey: "messageRaw",
      },
    },
  };
};
