import classNames from "classnames";
import { find, map, filter, includes, isEmpty } from "lodash";
import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import { StateManager } from "react-select/src/stateManager";

import { FieldAppend } from "../../GenericFormBody";
import AutocompleteControl from "../autocomplete/AutocompleteControl";
import AutocompleteDropdownIndicator from "../autocomplete/AutocompleteDropdownIndicator";
import AutocompleteSelectInput from "../autocomplete/AutocompleteInput";
import AutocompleteMenu from "../autocomplete/AutocompleteMenu";
import AutocompleteMenuList from "../autocomplete/AutocompleteMenuList";
import AutocompleteMenuOption from "../autocomplete/AutocompleteMenuOption";
import AutocompleteValueContainer from "../autocomplete/AutocompleteValueContainer";
import InputAppend from "../input-apend";
import SelectMultiValueContainer from "../autocomplete/SelectMultiValueContainer";
import SelectMultiValueRemove from "../autocomplete/SelectMultiValueRemove";
import SelectMultiValueLabel from "../autocomplete/SelectFieldValueLabel";
import { TextInputProps } from "../text";

export type SelectOption = {
  value: string;
  label: string;
  hidden?: boolean;
};

export type SelectInputProps = React.PropsWithRef<{}> &
  Omit<TextInputProps, "onChange"> & {
    onChange: (value: string | string[]) => void;
    options: SelectOption[];
    onValueChange?: (input: string | string[]) => void;
    append?: FieldAppend;
    selectRef?: React.Ref<StateManager>;
    isMulti?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
  };

const SelectInput: React.FC<SelectInputProps> = ({
  value,
  options,
  placeholder,
  error,
  touched,
  name,
  onBlur,
  onChange,
  append,
  onValueChange,
  selectRef,
  isMulti,
  isDisabled,
  readOnly,
  rowIndex,
  className,
  isLoading,
}) => {
  const handleAppendClick = React.useCallback(() => {
    append?.onClick &&
      append.onClick(name, value, rowIndex, append.formikProps);
  }, [append, name, rowIndex, value]);

  const [selectedOption, setSelectedOption] = useState<
    SelectOption | SelectOption[] | null
  >(null);

  React.useEffect(() => {
    if (!isMulti) {
      const option = find(options, { value });
      if (option) {
        setSelectedOption(option);
      } else {
        setSelectedOption(null);
      }
    } else {
      const preparedOptions: SelectOption[] = filter(options, (option) =>
        includes(value, option.value)
      );
      setSelectedOption(preparedOptions);
    }
  }, [value, isMulti, options]);

  const renderAppend = () => {
    if (!append) {
      return null;
    }

    return (
      <InputAppend
        {...append}
        fieldName={name}
        rowIndex={rowIndex}
        onClick={handleAppendClick}
      />
    );
  };

  const handleSelectChange = React.useCallback(
    (option) => {
      if (!isMulti) {
        onValueChange && onValueChange(option.value);
        onChange(option.value);
        setSelectedOption(option);
      } else {
        const values: string[] = map(option, "value");
        onValueChange && onValueChange(values);
        onChange(values);
        setSelectedOption(option);
      }
    },
    [onChange, onValueChange, isMulti]
  );

  return (
    <InputGroup className="form-input-group">
      <Select
        ref={selectRef}
        name={name}
        onChange={handleSelectChange}
        onBlur={onBlur}
        value={selectedOption}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        className={classNames("autocomplete", className, {
          "is-invalid": touched && !!error,
          "autocomplete-multi": isMulti && !isEmpty(selectedOption),
        })}
        error={error}
        isSearchable={!!isMulti}
        touched={touched}
        isDisabled={isDisabled || readOnly}
        isLoading={isLoading}
        components={{
          MultiValueLabel: SelectMultiValueLabel,
          MultiValueRemove: SelectMultiValueRemove,
          MultiValueContainer: SelectMultiValueContainer,
          Control: AutocompleteControl,
          Input: AutocompleteSelectInput,
          Menu: AutocompleteMenu,
          MenuList: AutocompleteMenuList,
          Option: AutocompleteMenuOption,
          ValueContainer: AutocompleteValueContainer,
          DropdownIndicator: AutocompleteDropdownIndicator,
          Placeholder: () => null,
          IndicatorSeparator: null,
        }}
      />
      {renderAppend()}
    </InputGroup>
  );
};

export default SelectInput;
