import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { getCertificationOptions } from "../../../utils/options";
import { SupplierCertificatioPayload } from "../../../models/supplier";

const createAddCertificationFields = (
  t: TFunction
): GenericFormFields<SupplierCertificatioPayload> => {
  return {
    main: [
      {
        valueKey: "name",
        type: "text",
        label: t("common.name"),
        placeholder: t("placeholders.name"),
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        valueKey: "document_type",
        type: "default-select",
        label: t("certification.type"),
        placeholder: t("certification.type"),
        selectProps: {
          options: getCertificationOptions(t),
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    additional: [
      {
        valueKey: "expiry_date",
        type: "date-picker",
        label: t("certification.expires"),
        placeholder: t("certification.expires"),
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        valueKey: "note",
        type: "text",
        label: t("certification.note"),
        placeholder: t("certification.note"),
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
  };
};

export default createAddCertificationFields;
