import React from "react";
import moment from "moment";
import classnames from "classnames";
import { Badge } from "react-bootstrap";
import { TFunction } from "i18next";
import { useHistory } from "react-router-dom";
import Icon from "../../icons/Icon";
import { ScheduleCategoryItem } from "../../../models/job";
import "./styles.scss";

type DashboardTaskItemProps = {
  task: ScheduleCategoryItem;
  t: TFunction;
  showJobName?: boolean;
};
const DashboardTaskItem: React.FC<DashboardTaskItemProps> = (props) => {
  const { task, t, showJobName = true } = props;
  const history = useHistory();

  const badgeVariant = React.useMemo(() => {
    if (task.progress < 25) {
      return "danger";
    }
    if (task.progress < 50) {
      return "warning";
    }
    if (task.progress < 100) {
      return "info";
    }
    return "success";
  }, [task.progress]);

  const handleClick = React.useCallback(
    () => history.push(`/jobs/${task.job?._id}/schedule`),
    [task]
  );

  return (
    <div className={classnames("dashboard-task")} onClick={handleClick}>
      <div className="task-message">
        <Badge variant={badgeVariant}>{task.progress}%</Badge>
        <b>{task.name}</b>
        {showJobName && (
          <>
            <span> {t("dashboard.onJob")} </span>
            <b>{task.job?.name}</b>
          </>
        )}
        <span className="task-date" title={moment(task.startDate).format("ll")}>
          {moment(task.startDate).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default DashboardTaskItem;
