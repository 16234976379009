import { gql } from "@apollo/client";

export const CREATE_PRICE_LIST = gql`
  mutation createUpdatePrice($price: PriceInput!) {
    createUpdatePrice(price: $price) {
      _id
      name
      createdAt
      supplier {
        _id
        business_name
      }
    }
  }
`;

export const DELETE_PRICE_LIST = gql`
  mutation deletePrice($priceId: ID!) {
    deletePrice(priceId: $priceId) {
      _id
      name
      createdAt
      supplier {
        _id
        business_name
      }
    }
  }
`;

export const CREATE_PRICE_LIST_CATEGORY = gql`
  mutation createUpdatePriceCategory(
    $priceId: ID!
    $name: String!
    $categoryId: ID
  ) {
    createUpdatePriceCategory(
      priceId: $priceId
      categoryId: $categoryId
      name: $name
    ) {
      _id
      name
    }
  }
`;

export const PRICE_LIST_UPLOAD = gql`
  mutation uploadSupplierPrice($priceId: ID!, $items: [PriceItemInput]!) {
    uploadSupplierPrice(priceId: $priceId, items: $items) {
      _id
    }
  }
`;

export const DELETE_PRICE_LIST_CATEGORY = gql`
  mutation deletePriceItemsByCategory($priceId: ID!, $categoryId: ID!) {
    deletePriceItemsByCategory(priceId: $priceId, categoryId: $categoryId) {
      _id
      name
      createdAt
      categorisedItems {
        _id
        name
        items {
          _id
          name
          category {
            _id
            name
          }
          UOM
          cost
        }
      }
      supplier {
        _id
        business_name
      }
    }
  }
`;

export const UPDATE_PRICE_LIST_CATEGORY_ITEMS = gql`
  mutation createUpdatePriceItems($priceId: ID!, $items: [PriceItemInput]) {
    createUpdatePriceItems(priceId: $priceId, items: $items) {
      _id
      name
      UOM
      cost
      gst_inc
      updatedAt
      createdAt
    }
  }
`;

export const ADJUST_PRICE_LIST_PRICE = gql`
  mutation adjustPriceCosts($priceId: ID!, $amount: Float!) {
    adjustPriceCosts(priceId: $priceId, amount: $amount) {
      _id
      name
      createdAt
      categorisedItems {
        _id
        name
        items {
          _id
          name
          category {
            _id
            name
          }
          UOM
          cost
        }
      }
      supplier {
        _id
        business_name
      }
    }
  }
`;
