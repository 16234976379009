import { gql } from "@apollo/client";
import { TEAM_MEMBER } from "../../members/fragments";
import { SCHEDULE_CATEGORY_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_JOB_SCHEDULE_CATEGORY = gql`
  mutation CreateJobScheduleCategory(
    $jobId: ID!
    $scheduleCategory: ScheduleCategoryInput
  ) {
    jobCreateUpdateScheduleCategory(
      jobId: $jobId
      scheduleCategory: $scheduleCategory
    ) {
      ...ScheduleCategoryFragment
    }
  }
  ${SCHEDULE_CATEGORY_FRAGMENT}
`;

export const DELETE_JOB_SCHEDULE_CATEGORY = gql`
  mutation JobDeleteScheduleCategory($jobId: ID!, $scheduleCategoryId: ID!) {
    jobDeleteScheduleCategory(
      jobId: $jobId
      scheduleCategoryId: $scheduleCategoryId
    ) {
      _id
    }
  }
`;

export const CREATE_UPDATE_JOB_SCHEDULE_ITEM = gql`
  mutation CreateScheduleItem(
    $jobId: ID!
    $categoryId: ID!
    $scheduleItem: ScheduleItemInput
  ) {
    jobCreateUpdateScheduleItem(
      jobId: $jobId
      categoryId: $categoryId
      scheduleItem: $scheduleItem
    ) {
      _id
      name
      description
      startDate
      endDate
      progress
      order
      supplier {
        _id
        business_name
        contact_person
        email
      }
      user {
        ...TeamMember
      }
      predecessor {
        _id
        dependant {
          _id
        }
      }
      dependant {
        _id
      }
      reminder {
        daysBefore
        timezone
      }
    }
  }
  ${TEAM_MEMBER}
`;

export const DELETE_JOB_SCHEDULE_ITEM = gql`
  mutation JobDeleteScheduleItem($jobId: ID, $scheduleItemId: ID) {
    jobDeleteScheduleItem(jobId: $jobId, scheduleItemId: $scheduleItemId) {
      _id
      predecessor {
        _id
        dependant {
          _id
        }
      }
    }
  }
`;

export const UPDATE_JOB_SCHEDULE_ORDER = gql`
  mutation($jobId: ID!, $categories: [JobScheduleSortedCategoryInput]!) {
    jobUpdateScheduleOrder(jobId: $jobId, categories: $categories) {
      ...ScheduleCategoryFragment
    }
  }
  ${SCHEDULE_CATEGORY_FRAGMENT}
`;

export const BULK_CREATE_UPDATE_JOB_SCHEDULE_CATEGORY = gql`
  mutation bulkCreateUpdateJobScheduleCategory(
    $jobId: ID!
    $scheduleCategories: [ScheduleCategoryInput]!
  ) {
    bulkCreateUpdateJobScheduleCategory(
      jobId: $jobId
      scheduleCategories: $scheduleCategories
    ) {
      ...ScheduleCategoryFragment
    }
  }
  ${SCHEDULE_CATEGORY_FRAGMENT}
`;

export const BULK_UPDATE_JOB_SCHEDULE = gql`
  mutation bulkUpdateJobSchedule(
    $jobId: ID!
    $action: ScheduleBulkAction!
    $categories: [ScheduleCategoryInput]
    $items: [ScheduleItemInput]
  ) {
    bulkUpdateJobSchedule(
      jobId: $jobId
      action: $action
      categories: $categories
      items: $items
    ) {
      ...ScheduleCategoryFragment
    }
  }
  ${SCHEDULE_CATEGORY_FRAGMENT}
`;

export const SEND_JOB_SCHEDULE = gql`
  mutation JobSendSchedule(
    $jobId: ID!
    $message: JobSendEmailInput!
    $filter: ScheduleFilterInput
  ) {
    jobSendSchedule(jobId: $jobId, message: $message, filter: $filter) {
      _id
      status
    }
  }
`;

export const SEND_JOB_SCHEDULE_ITEM_REMINDER = gql`
  mutation($jobId: ID!, $scheduleItemId: ID!, $message: JobSendEmailInput!) {
    jobSendScheduleItemReminder(
      jobId: $jobId
      scheduleItemId: $scheduleItemId
      message: $message
    ) {
      _id
    }
  }
`;

export const IMPORT_JOB_SCHEDULE_FROM_TEMPLATE = gql`
  mutation jobImportScheduleFromTemplate(
    $jobId: ID!
    $scheduleTemplateId: ID!
    $date: String!
  ) {
    jobImportScheduleFromTemplate(
      jobId: $jobId
      scheduleTemplateId: $scheduleTemplateId
      date: $date
    ) {
      ...ScheduleCategoryFragment
    }
  }
  ${SCHEDULE_CATEGORY_FRAGMENT}
`;

export const SAVE_JOB_SCHEDULE_AS_TEMPLATE = gql`
  mutation($jobId: ID!, $scheduleTemplate: ScheduleTemplateInput!) {
    jobSaveScheduleAsTemplate(
      jobId: $jobId
      scheduleTemplate: $scheduleTemplate
    ) {
      _id
      name
    }
  }
`;

export const DELETE_SCHEDULE_TEMPLATE = gql`
  mutation($scheduleTemplateId: ID!) {
    deleteScheduleTemplate(scheduleTemplateId: $scheduleTemplateId) {
      _id
      name
    }
  }
`;
