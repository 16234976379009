import React from "react";
import Tree from "./Tree";
import { ConnectDragSource, useDrag, useDrop } from "react-dnd";
import { SortableItem } from "./types";

type ItemProps = {
  id: string;
  parentId?: string;
  item: SortableItem;
  renderItem: (item: any, dragRef: ConnectDragSource) => React.ReactElement;
  moveItem: (id: string, afterId?: string, parentId?: string) => void;
  type: string;
};

const Item: React.FC<ItemProps> = (props: any) => {
  const { item, parentId, moveItem, type, renderItem } = props;
  const [{}, dropRef] = useDrop({
    accept: type,
    canDrop: false,
    hover: (draggedItem: SortableItem, monitor: any) => {
      if (draggedItem.id === item.id || draggedItem.id === parentId) return;
      if (!monitor.isOver({ shallow: true })) return;
      moveItem(draggedItem.id, item.id, parentId);
    },
  } as any);

  const [, dragRef, previewRef] = useDrag({
    type: type,
    item: {
      id: item.id,
      parentId,
      items: item.items || [],
      // type: type,
    } as SortableItem,
    isDragging: (monitor: any) => item.id == monitor.getItem().id,
  });

  return (
    <div ref={dropRef}>
      <div ref={previewRef}>
        <div>{renderItem(item, dragRef)}</div>
        <Tree
          items={item.items}
          moveItem={moveItem}
          parentId={item.id}
          type={type === "subitem" ? "skip" : "subitem"}
          renderItem={renderItem}
        />
      </div>
    </div>
  );
};

export default Item;
