import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
  DeleteNotePayload,
  DeleteNoteResponse,
  Note,
  NoteEntityType,
} from "../../../graphql/types/models/note";
import NoteItem from "../note-item";
import CommunicationList from "../../communication/communication-list";
import NoteModal, { NoteModalRef } from "../note-modal";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";
import { DELETE_NOTE } from "../../../graphql/queries/note/mutations";
import { handleDeleteNote } from "../../../graphql/queries/note/utils";
import "./styles.scss";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import EmptyPlaceholder from "../../empty-placeholder";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import { Button } from "react-bootstrap";
import Icon from "../../icons/Icon";

type NoteCardProps = {
  entityType: NoteEntityType;
  entityId: string;
  notes: Note[];
  canEdit?: boolean;
};
const NoteCard: React.FC<NoteCardProps> = ({ entityType, entityId, notes }) => {
  const { t } = useTranslation();
  const noteRef = useRef<NoteModalRef>(null);
  const confirmRef = useRef<ConfirmDialogRef>(null);
  const [deleteTarget, setDeleteTarget] = useState<Note | null>(null);

  const [deleteNote] = useMutation<DeleteNoteResponse, DeleteNotePayload>(
    DELETE_NOTE,
    {
      update: handleDeleteNote(entityType, entityId),
    }
  );

  const handleDelete = React.useCallback(
    (note: Note) => {
      setDeleteTarget(note);
      confirmRef.current?.show(true);
    },
    [confirmRef]
  );

  const handleConfirmDelete = React.useCallback(() => {
    if (!deleteTarget) return;
    deleteNote({
      variables: {
        entityType,
        entityId,
        noteId: deleteTarget._id,
      },
    });
  }, [deleteTarget, entityType, entityId]);

  const handleCreate = React.useCallback(() => {
    noteRef.current?.show();
  }, [noteRef]);

  const handleEdit = React.useCallback(
    (note: Note) => {
      noteRef.current?.show(note);
    },
    [noteRef]
  );

  return (
    <>
      <DashboardCard>
        <DashboardCardHeader className="justify-content-between">
          {t("note.notes")}
          <div className="d-flex mr-3">
            <Button
              className="button large bg-transparent"
              block
              onClick={handleCreate}
            >
              <Icon name="add" />
              {t("note.createNote")}
            </Button>
          </div>
        </DashboardCardHeader>
        <DashboardCardBody>
          {notes?.length === 0 && (
            <EmptyPlaceholder message={t("note.emptyPlaceholder")} />
          )}
          {notes.map((note) => (
            <NoteItem note={note} onDelete={handleDelete} onEdit={handleEdit} />
          ))}
        </DashboardCardBody>
      </DashboardCard>
      <NoteModal ref={noteRef} entityType={entityType} entityId={entityId} />

      <ConfirmDialog
        ref={confirmRef}
        title={t("note.deleteNote")}
        confirm={t("common.yes")}
        onSubmit={handleConfirmDelete}
      >
        <span className="field-text">{t("note.deleteNoteMessage")}</span>
      </ConfirmDialog>
    </>
  );
};

export default NoteCard;
