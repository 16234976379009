import React, { useRef, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputAppend from "../input-apend";
import { TextInputProps } from "../text";
import classNames from "classnames";
import "./styles.scss";

export type DatePickerProps = Omit<TextInputProps, "onChange"> & {
  calendarClassName?: string;
  buttonClassName?: string;
  dateFormat?: string;
  showTimeSelect: boolean;
  showTimeSelectOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onChange: (value: string) => void;
};

export const DEFAULT_DATE_PICKER_FORMAT = "dd/MM/yyyy";
export const TIME_FORMAT = "hh:mma";
export const DEFAULT_DATE_TIME_FORMAT = "dd/MM/yyyy hh:mma";

const DatePickerInput: React.FC<DatePickerProps> = (props) => {
  const {
    calendarClassName,
    buttonClassName,
    showTimeSelect,
    showTimeSelectOnly,
    minDate,
    maxDate,
    onChange,
    dateFormat,
    placeholder,
    touched,
    error,
    onBlur,
    name,
    value,
    rowIndex,
    disabled = false,
    className,
  } = props;

  const pickerRef = useRef<DatePicker>(null);
  const groupRef = useRef<InputGroup<"div"> & HTMLDivElement>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleChange = React.useCallback(
    (date) => {
      if (!date) {
        onChange && onChange("");
      } else {
        date?.setSeconds(0);
        date?.setMilliseconds(0);
        onChange && onChange(date.toISOString());
      }

      setSelectedDate(date);
      if (!showTimeSelect) {
        pickerRef.current?.setOpen(false);
      }
    },
    [onChange, pickerRef]
  );

  React.useEffect(() => {
    if (!value) {
      return;
    }

    setSelectedDate(new Date(value));
  }, [value]);

  const pickerClasses = classNames(
    {
      "is-invalid": touched && !!error,
    },
    "text-input form-input form-control",
    className
  );

  // replace with DatePicker.onClickOutside
  const handleMouseDown = React.useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      const isClickOutside = !groupRef.current?.contains(event.target as Node);

      if (isClickOutside) {
        pickerRef.current?.setOpen(false);
      } else {
        pickerRef.current?.setOpen(true);
      }
    },
    [pickerRef, groupRef]
  );

  React.useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  // const handleGroupClick = React.useCallback((event: any) => {

  //   setShowCalendar(!showCalendar);

  //   if (!showCalendar) {
  //     pickerRef.current?.setFocus();
  //   }
  // }, [showCalendar]);

  const getTimeFormat = () => {
    if (showTimeSelectOnly) {
      return TIME_FORMAT;
    }

    if (showTimeSelect) {
      return dateFormat || DEFAULT_DATE_TIME_FORMAT;
    }

    return dateFormat || DEFAULT_DATE_PICKER_FORMAT;
  };

  return (
    <InputGroup
      className="form-input-group"
      // onClick={handleGroupClick}
      ref={groupRef}
    >
      <DatePicker
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        maxDate={maxDate}
        minDate={minDate}
        onBlur={onBlur}
        name={name}
        autoComplete="off"
        placeholderText={placeholder}
        className={pickerClasses}
        onChange={handleChange}
        ref={pickerRef}
        wrapperClassName="w-100"
        selected={selectedDate}
        dateFormat={getTimeFormat()}
        calendarClassName={calendarClassName}
        disabled={disabled}
      />
      <InputAppend
        fieldName={name}
        rowIndex={rowIndex}
        className={buttonClassName}
        icon="calendar_today"
        outlined
      />
    </InputGroup>
  );
};

export default DatePickerInput;
