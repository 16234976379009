import React from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import {
  TableCardData,
  TableCardDataColumn,
} from "../../dashboard/table-card/utils";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import LeftModal from "../../left-modal";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import CardTable from "../../dashboard/table-card/CardTable";
import { formatQuoteNumber } from "../../../utils/text";
import { ClaimItem } from "../../../models/claim";
import "./styles.scss";

type ProgressClaimsTable = ClaimItem & {
  status: string;
  progressClaimName: string;
};

export type ListProgressClaimsModalRef = ModalDisplayRef & {
  setData: (data: ClaimItem[]) => void;
};

const ListProgressClaimsModal: React.FC<{
  isCostPlus: boolean;
}> = ({ isCostPlus }, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const [data, setData] = React.useState<ClaimItem[]>([]);

  const { shouldShow, hide } = useModalDisplay<ListProgressClaimsModalRef>(
    ref,
    {
      setData: (data: ClaimItem[]) => {
        setData(data);
      },
    } as ListProgressClaimsModalRef,
    [setData]
  );

  const tableData = React.useMemo<TableCardData<ProgressClaimsTable>>(() => {
    const items: TableCardDataColumn<ProgressClaimsTable>[] = isCostPlus
      ? [
          {
            valueKey: "subTotal",
            title: t("costing.cost"),
            formatValue: (row: any, column: any, value: number) =>
              !isNaN(value) ? t("common.currency", { amount: value }) : "",
          },
          {
            valueKey: "markupSubTotal",
            title: t("claims.builderMargin"),
            formatValue: (row: any, column: any, value: number) =>
              !isNaN(value) ? t("common.currency", { amount: value }) : "",
          },
          {
            valueKey: "invoiceGST",
            title: t("costing.gst"),
            formatValue: (row: any, column: any, value: number) =>
              !isNaN(value) ? t("common.currency", { amount: value }) : "",
          },
          {
            valueKey: "invoiceTotal",
            title: t("claims.totalInc"),
            formatValue: (row: any, column: any, value: number) =>
              !isNaN(value) ? t("common.currency", { amount: value }) : "",
          },
        ]
      : [
          {
            valueKey: "total",
            title: t("costing.totalEx"),
            formatValue: (row: any, column: any, value: number) =>
              t("common.currency", { amount: value }),
          },
          {
            valueKey: "amount",
            title: t("claims.claimAmount"),
            formatValue: (row: any, column: any, value: number) =>
              t("common.currency", { amount: value }),
          },
          {
            valueKey: "percentage",
            title: t("claims.claimPercent"),
            formatValue: (row: any, column: any, value: number) =>
              t("claims.claimedPercent", { percentage: value }),
          },
          {
            valueKey: "amountToDate",
            title: t("claims.claimedToDate"),
            formatValue: (row: any, column: any, value: number) =>
              t("common.currency", { amount: value }),
          },
          {
            valueKey: "remaining",
            title: t("claims.remaining"),
            formatValue: (row: any, column: any, value: number) =>
              t("common.currency", { amount: value }),
          },
        ];
    return {
      columns: [
        {
          valueKey: "createdAt",
          title: t("claims.date"),
          formatValue: (row: any, column: any, value: number) =>
            moment(value).format("Do MMMM YYYY"),
        },
        {
          valueKey: "progressClaimName",
          title: t("claims.progressClaim"),
          className: "table-cell-link",
          onClick: (row: any) => {
            hide();
            if (!row.cells._id) return;
            const url = generatePath("/jobs/:id/progress-claims/:claimId", {
              id: (match.params as any)?.id,
              claimId: row.cells._id,
            });
            history.push(url);
          },
        },
        {
          valueKey: "status",
          title: t("common.status"),
          formatValue: (row: any, column: any, value: number) =>
            t(`orders.statuses.${value}`),
        },
        ...items,
      ],
      rows: map(data, (item) => ({
        cells: {
          ...item,
          ...(isCostPlus ? item.progressClaim : null),
          _id: item.progressClaim._id,
          // jobId: item.progressClaim?.job?._id || "",
          description: item.progressClaim.description,
          createdAt: item.progressClaim.createdAt,
          status: item.progressClaim.status,
          progressClaimName: formatQuoteNumber(
            item.progressClaim.invNumber,
            "PC"
          ),
        },
      })),
    };
  }, [isCostPlus, data, hide, match]);

  return (
    <LeftModal
      show={shouldShow}
      onHide={hide}
      className="list-progress-claims-modal"
    >
      <LeftModalHeader title={t("jobOverview.progressClaims")} onClose={hide} />
      <LeftModalBody>
        <div>
          <h3>{data[0]?.name}</h3>
          <CardTable table={tableData} tableClass="table-outline" />
        </div>
      </LeftModalBody>
    </LeftModal>
  );
};

export default React.forwardRef(ListProgressClaimsModal);
