import React from "react";
import { useTranslation } from "react-i18next";
import { ChangePasswordPayload } from "../../../models/profile";
import createFields from "./utils";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import UpdateEntityModal from "../../modals/update-entity";
import createChangePwdSchema from "./ChangePassword.schema";

type ChangePasswordModalProps = {
  show: boolean;
  onSubmit: (data: ChangePasswordPayload) => void;
  onClose: () => void;
};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = (props) => {
  const { t } = useTranslation();
  const { show, onSubmit, onClose } = props;

  const formFields = React.useMemo<
    GenericFormFields<ChangePasswordPayload>
  >(() => {
    return createFields(t);
  }, [t]);

  return (
    <UpdateEntityModal
      validationSchema={createChangePwdSchema(t)}
      title={t("profile.changePassword")}
      show={show}
      data={{
        old_password: "",
        new_password: "",
        confirm_new_password: "",
      }}
      onSubmit={onSubmit}
      onClose={onClose}
      fields={formFields}
    />
  );
};

export default ChangePasswordModal;
