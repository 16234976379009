import React from "react";
import { useTranslation } from "react-i18next";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../../hooks/useModalDisplay";
import LeftModal from "../../../left-modal";
import LeftModalBody from "../../../left-modal/LeftModalBody";
import LeftModalHeader from "../../../left-modal/LeftModalHeader";
import CreateTakeOffModal from "../create-take-off";
import PlanTakeOffEditor, {
  TakeOffPlanEditorRef,
} from "../../take-off-plan-editor";
import {
  CreateTakeOffPayload,
  TakeoffListItem,
} from "../../../../models/take-off";
import LeftModalFooter from "../../../left-modal/LeftModalFooter";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { CREATE_TAKEOFF_ITEM } from "../../../../graphql/queries/take-off/mutations";
import { CreateTakeoffResponse } from "../../../../graphql/types/models/take-off";
import { handleTakeOffAdd } from "../../../../graphql/queries/take-off/utils";
import { convertQuantity } from "../../take-off-plan-viewer/utils";
import "./styles.scss";

type FloatingTakeoffModalProps = {
  salesQuoteId?: string;
  name?: string;
  onCreate: (data: TakeoffListItem) => void;
};

const FloatingTakeoffModal: React.FC<FloatingTakeoffModalProps> = (
  { salesQuoteId, name, onCreate },
  ref
) => {
  const { t } = useTranslation();
  const [takeOff, setTakeOff] = React.useState<TakeoffListItem | null>(null);
  const createTakeoffRef = React.useRef<ModalDisplayRef>(null);
  const editorRef = React.useRef<TakeOffPlanEditorRef>(null);
  const [stepOne, setStepOne] = React.useState(true);
  const [quantity, setQuantity] = React.useState(0);
  const { shouldShow, hide } = useModalDisplay(ref);

  const [createTakeoff] = useMutation<CreateTakeoffResponse>(
    CREATE_TAKEOFF_ITEM,
    {
      onCompleted: ({ createTakeOffItem }) => {
        onCreate(createTakeOffItem);
        hide();
      },
      update: handleTakeOffAdd(salesQuoteId),
    }
  );

  React.useEffect(() => {
    if (shouldShow && stepOne) {
      createTakeoffRef.current?.show(true);
    }
    if (!shouldShow) {
      setStepOne(true);
      setQuantity(0);
    }
  }, [stepOne, shouldShow]);

  const handleCreateTakeoffDisplayChange = React.useCallback(
    (display: boolean) => {
      if (!display) {
        hide();
      } else {
        setTakeOff(null);
      }
    },
    [hide]
  );

  React.useEffect(() => {
    createTakeoffRef.current?.addDisplayListener?.(
      handleCreateTakeoffDisplayChange
    );
    return () => {
      createTakeoffRef.current?.removeDisplayListener?.(
        handleCreateTakeoffDisplayChange
      );
    };
  }, [createTakeoffRef, handleCreateTakeoffDisplayChange]);

  const handleCreateTakeoff = React.useCallback(
    (data: CreateTakeOffPayload) => {
      setTakeOff({
        name: data.name,
        UOM: data.UOM,
        properties: {
          color: data.color,
        },
        quantity: 0,
      });
      setStepOne(false);
      createTakeoffRef.current?.show(false);
      return true;
    },
    [setTakeOff]
  );

  const handleSave = React.useCallback(() => {
    const takeOffItem = editorRef.current?.getUnsavedTakeoff();
    if (takeOffItem) {
      createTakeoff({
        variables: {
          salesQuoteId,
          takeOffItem,
        },
      });
    }
  }, []);

  const totalQuantity = React.useMemo(() => {
    if (!takeOff) return null;
    const { quantity: qty, UOM } = convertQuantity(quantity, takeOff.UOM);

    return `${qty.toLocaleString("en-us", {
      maximumFractionDigits: 2,
    })} ${UOM}`;
  }, [quantity, takeOff]);

  if (stepOne) {
    return (
      <CreateTakeOffModal
        ref={createTakeoffRef}
        onSubmit={handleCreateTakeoff}
        name={name}
        colourIndex={0}
      />
    );
  }

  return (
    <React.Fragment>
      <LeftModal
        show={shouldShow}
        onHide={hide}
        className="floating-takeoff-modal"
      >
        <LeftModalHeader
          title={t("takeOffSection.newTakeOff")}
          onClose={hide}
        />
        <LeftModalBody>
          <PlanTakeOffEditor
            ref={editorRef}
            salesQuoteId={salesQuoteId}
            takeOff={takeOff}
            onQuantityChange={setQuantity}
            disableAutoSave
          />
          <LeftModalFooter>
            <div className="quantity-total">Total: {totalQuantity}</div>
            <Button
              variant="secondary"
              className="button large info"
              onClick={hide}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="primary"
              className="button large success"
              onClick={handleSave}
            >
              {t("common.save")}
            </Button>
          </LeftModalFooter>
        </LeftModalBody>
      </LeftModal>
    </React.Fragment>
  );
};

export default React.forwardRef(FloatingTakeoffModal);
