import React from "react";
import { ControlProps, components } from "react-select";
import classNames from "classnames";

type AutocompleteControlProps = ControlProps<any> & {};

const AutocompleteControl: React.FC<AutocompleteControlProps> = ({
  children,
  ...props
}) => {
  //const {error, touched} = (props as any).selectProps;

  return (
    <components.Control
      {...props}
      className={classNames("control", { focused: props.isFocused })}
    >
      {children}
    </components.Control>
  );
};

export default AutocompleteControl;
