import React from "react";
import { useTranslation } from "react-i18next";
import {
  CreateTakeOffPayload,
  TakeOffMeasurement,
} from "../../../../models/take-off";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import CreateEntityModal from "../../../modals/create-entity";
import createTakeOffSchema from "./CreateTakeOffSchema";
import { createTakeOffFields } from "./utils";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../../hooks/useModalDisplay";
import COLOURS from "../../../../constants/colours";
import { useCreateAnother } from "../../../../hooks/useCreateAnother";
import "./styles.scss";

type CreateTakeOffModalProps = {
  onSubmit: (data: CreateTakeOffPayload) => void;
  colourIndex: number;
  name?: string;
};

export type CreateTakeOffModalRef = ModalDisplayRef & {
  shouldCreateAnother: () => boolean;
};

const CreateTakeOffModal: React.FC<CreateTakeOffModalProps> = (
  { onSubmit, name, colourIndex = 0 },
  ref
) => {
  const { t } = useTranslation();
  const { shouldCreateAnother, renderCreateAnother } = useCreateAnother(
    t("takeOffSection.createAnotherTakeOff")
  );
  const { shouldShow, hide } = useModalDisplay(
    ref,
    {
      shouldCreateAnother: () => shouldCreateAnother,
    },
    [shouldCreateAnother]
  );

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateTakeOffPayload>
  >({});

  React.useEffect(() => {
    setFormFields(createTakeOffFields(t));
  }, [t]);

  return (
    <CreateEntityModal
      validationSchema={createTakeOffSchema(t)}
      title={t("takeOffSection.newTakeOff")}
      show={shouldShow}
      data={{
        name: name || "",
        UOM: TakeOffMeasurement.LINEAR_METER,
        color: COLOURS[colourIndex % COLOURS.length],
      }}
      className="take-off-form-modal"
      onSubmit={onSubmit}
      onClose={hide}
      fields={formFields}
      leftFooterRenderer={renderCreateAnother}
    />
  );
};

export default React.forwardRef(CreateTakeOffModal);
