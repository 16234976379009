import { gql } from "@apollo/client";
import { ACCOUNTING_INTEGRATION_FRAGMENT } from "./fragments";

export const GET_ACCOUNTING_INTEGRATION = gql`
  query {
    getCurrentAccountingIntegration {
      ...AccountingIntegrationFragment
    }
  }
  ${ACCOUNTING_INTEGRATION_FRAGMENT}
`;

export const GET_ACCOUNTING_TENANTS = gql`
  query {
    getAccountingTenants {
      id
      tenantName
    }
  }
`;
export const GET_ACCOUNTING_ACCOUNTS = gql`
  query($tenantId: String!) {
    getAccountingAccounts(tenantId: $tenantId) {
      id
      code
      name
      type
      isSales
      isExpenses
    }
  }
`;

export const GET_ACCOUNTING_TENANT = gql`
  query {
    getTenant {
      id
      tenantId
      tenantType
      tenantName
      accounts {
        code
        name
        accountID
        type
      }
    }
  }
`;

export const GET_ACCOUNTING_CUSTOM_CONFIGURATION = gql`
  query {
    getAccountingCustomConfiguration {
      key
      label
      type
      placeholder
      hint
      options {
        value
        label
      }
      value
    }
  }
`;

export const GET_ACCOUNTING_INTEGRATION_ERRORS = gql`
  query {
    getAccountingIntegrationErrors {
      _id
      type
      message
      code
      details
      createdAt
    }
  }
`;
