import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createItemOptionSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(ValidationMessagesUtils.createRequiredMessage(t, "tasks.task")),
    description: yup.string(),
    UOM: yup.string().required(),
    quantity: yup.number().required(),
    cost: yup.number().required(),
    dueDate: yup.string(),
  });
}
