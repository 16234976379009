import { gql } from "@apollo/client";
import { JOB_FRAGMENT } from "./fragments";

export const LIST_JOBS = gql`
  query ListJobs($status: EnumJobStatus) {
    listJobs(status: $status) {
      _id
      jobNumber
      name
      address
      city
      state
      postcode
      totalClaimed
      totalRealCost
      contractTotal
      contractTotalGST
      totalEstimated
      totalEstimatedWithMarkup
      totalVariation
      totalClaims
      totalClaimed
      totalWIP
      type
      createdAt
      updatedAt
      customer {
        _id
        last_name
        first_name
      }
    }
  }
`;
export const GET_JOB = gql`
  query GetJob($jobId: ID!) {
    getJob(jobId: $jobId) {
      ...JobFragment
    }
  }
  ${JOB_FRAGMENT}
`;

export const GET_JOB_STATUS = gql`
  query GetJob($jobId: ID!) {
    getJob(jobId: $jobId) {
      _id
      name
      is_locked
    }
  }
`;

export const GET_JOB_DETAILS = gql`
  query GetJob($jobId: ID!) {
    getJob(jobId: $jobId) {
      ...JobFragment
    }
  }
  ${JOB_FRAGMENT}
`;

export const GET_JOB_CONTACTS = gql`
  query($jobId: ID!) {
    contacts: getJobContacts(jobId: $jobId) {
      contact {
        _id
        last_name
        first_name
        email
        state
        city
      }
      clientAccess {
        _id
      }
    }
  }
`;

export const GLOBAL_JOB_SEARCH = gql`
  query($filters: JobSearchFilters!, $pagination: PaginationInput!) {
    searchJob(filters: $filters, pagination: $pagination) {
      purchaseOrderReceipts {
        _id
        reference
        dateReceived
        purchaseOrder {
          _id
          reference
          orderNumber
          status
          subTotal
          createdAt
          receipts {
            subTotal
          }
        }
        job {
          _id
          name
          jobNumber
        }
      }
      purchaseOrders {
        _id
        orderNumber
        status
        date
        subTotal
        receipts {
          total
          subTotal
        }
        job {
          _id
          name
          jobNumber
        }
        supplier {
          _id
          business_name
          contact_person
        }
        reference
      }
      rosters {
        _id
        orderNumber: rosterNumber
        date
        reference
        status
        createdAt
        job {
          _id
          jobNumber
        }
      }
      variations {
        _id
        reference: name
        status
        total
        orderNumber: variationNumber
        progressClaimed
        date
        subTotal
        job {
          _id
          name
          jobNumber
        }
        contact {
          _id
          last_name
          first_name
        }
      }
      progressClaims {
        _id
        orderNumber: invNumber
        claimDate
        status
        subTotal: amount
        job {
          _id
          name
          jobNumber
        }
        reference: description
        date: createdAt
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
    }
  }
`;
