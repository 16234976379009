import React from "react";
import { ValueContainerProps, components } from "react-select";

type AutocompleteValueContainerProps = ValueContainerProps<any> & {};

const AutocompleteValueContainer: React.FC<AutocompleteValueContainerProps> = (
  props
) => {
  return (
    <components.ValueContainer {...props} className="value-container">
      {props.children}
    </components.ValueContainer>
  );
};

export default AutocompleteValueContainer;
