import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import classNames from "classnames";
import { compact } from "lodash";
import { Button } from "react-bootstrap";
import Dropdown from "../../dashboard/dropdown";
import {
  EnumSelectionStatus,
  SelectionCategoryItemOption,
  SelectionCategoryItemType,
} from "../../../graphql/types/models/selections";
import SelectionBudge from "../badge";
import Icon from "../../icons/Icon";
import SelectionItemOptions from "../item-options";
import "./styles.scss";

type CategorySelectionItemProps = {
  item: SelectionCategoryItemType;
  onDelete?: (id: string) => void;
  onApproveOption?: (item: SelectionCategoryItemType) => void;
  onClientSelectOption?: (
    option: SelectionCategoryItemOption,
    item: SelectionCategoryItemType,
    comment?: string
  ) => void;
  onEdit?: (item: SelectionCategoryItemType) => void;
  onDeleteOption?: (
    option: SelectionCategoryItemOption,
    item: SelectionCategoryItemType
  ) => void;
  onUnselectOption?: (
    item: SelectionCategoryItemType,
    isUnapprove?: boolean
  ) => void;
  onSelectOption?: (
    option: SelectionCategoryItemOption,
    item: SelectionCategoryItemType
  ) => void;
  onEditOption?: (
    option: SelectionCategoryItemOption,
    item: SelectionCategoryItemType
  ) => void;
  onCreateOption?: (item: SelectionCategoryItemType) => void;
  isLoading?: boolean;
  isLocked?: boolean;
  isClient?: boolean;
  isOpenItem?: boolean;
  isTemplate?: boolean;
  selectedOptions?: { optionId: string; comment: string }[];
};

const CategorySelectionItem: React.FC<CategorySelectionItemProps> = (props) => {
  const {
    item,
    onDelete,
    onEdit,
    onSelectOption,
    isLoading,
    isLocked,
    onDeleteOption,
    onEditOption,
    onCreateOption,
    onApproveOption,
    onUnselectOption,
    isClient,
    onClientSelectOption,
    isOpenItem = false,
    isTemplate,
    selectedOptions,
  } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(isOpenItem);

  const isCompleted = item.status === EnumSelectionStatus.COMPLETED;

  const quoteSelectionItemClasses = classNames(
    {
      borderBottom: !isOpen,
    },
    "quote-selection-item"
  );

  const toggleExpand = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const costingItemPath = item?.costingItem ? (
    <div className="d-flex aling-items-center">
      <Icon outlined className="mr-1" name="insert_link" />
      <span>
        {`${item?.costingItem?.category?.name} / ${item?.costingItem?.name}`}
      </span>
    </div>
  ) : (
    <div className="d-flex aling-items-center color-error">
      <Icon outlined className="mr-1" name="link_off" />
      <span>{t("common.unallocated")}</span>
    </div>
  );

  const handleOnEdit = React.useCallback(() => {
    onEdit && onEdit(item);
  }, [item, onEdit]);

  const handleOnUnaprrove = React.useCallback(() => {
    onUnselectOption && onUnselectOption(item, true);
  }, [item, onUnselectOption]);

  const handleOnApprove = React.useCallback(() => {
    onApproveOption && onApproveOption(item);
  }, [item, onApproveOption]);

  const handleOnClientSelect = React.useCallback(
    (option: SelectionCategoryItemOption, comment?: string) => {
      onClientSelectOption && onClientSelectOption(option, item, comment);
    },
    [item, onClientSelectOption]
  );

  const handleOnEditOption = React.useCallback(
    (option: SelectionCategoryItemOption) => {
      onEditOption && onEditOption(option, item);
    },
    [item, onEdit]
  );

  const handleOnDeleteOption = React.useCallback(
    (option: SelectionCategoryItemOption) => {
      onDeleteOption && onDeleteOption(option, item);
    },
    [item, onDeleteOption]
  );

  const handleOnSelectOption = React.useCallback(
    (option: SelectionCategoryItemOption) => {
      onSelectOption && onSelectOption(option, item);
    },
    [item, onSelectOption]
  );

  const handleOnUnselectOption = React.useCallback(() => {
    onUnselectOption && onUnselectOption(item);
  }, [item, onUnselectOption]);

  const handleOnDelete = React.useCallback(() => {
    onDelete && onDelete(item._id);
  }, [item._id, onDelete]);

  const dropdownItems = React.useMemo(() => {
    return compact([
      !isCompleted && {
        id: "edit",
        label: t("common.edit"),
        name: "share",
        onClick: handleOnEdit,
      },
      isCompleted && {
        id: "edit",
        label: t("selections.unapprove"),
        name: "unapprove",
        onClick: handleOnUnaprrove,
      },
      {
        id: "delete",
        label: t("common.delete"),
        name: "delete",
        onClick: handleOnDelete,
      },
    ]);
  }, [handleOnDelete, handleOnEdit, t, isCompleted]);

  const overDue = React.useMemo(() => {
    return moment().isAfter(moment(item.dueDate).endOf("day"));
  }, [item.dueDate]);

  const handleOnCreateOption = React.useCallback(() => {
    onCreateOption && onCreateOption(item);
  }, [item, onCreateOption]);

  return (
    <div className={"quote-selection-container"}>
      <div className={quoteSelectionItemClasses}>
        <div className="d-flex flex-row justify-content-between">
          <div
            className="d-flex flex-column justify-content-center"
            onClick={toggleExpand}
          >
            <div className="item-title">
              <Icon
                className="expand-button"
                name={isOpen ? "expand_more" : "expand_less"}
              />
              <span>{item.name}</span>

              {overDue &&
                item.status !== EnumSelectionStatus.COMPLETED &&
                item.status !== EnumSelectionStatus.SELECTED && (
                  <SelectionBudge status={EnumSelectionStatus.OVERDUE} />
                )}
            </div>
            {item?.dueDate && (
              <div className="item-dueDate">
                {t("common.due", {
                  date: moment(item?.dueDate).format("DD/MM/YYYY"),
                })}
              </div>
            )}
            {!isClient && !isTemplate && (
              <div className="item-costing-path">{costingItemPath}</div>
            )}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-end">
            <div className="d-flex justify-content-end mb-2">
              {item.selectedOption && (
                <>
                  <span className="total-selected-option pr-2">
                    {isClient
                      ? t("placeholders.totalExfixedAmount", {
                          amount:
                            item.selectedOption.markup_cost *
                            item.selectedOption.quantity,
                        })
                      : t("placeholders.totalExfixedAmount", {
                          amount:
                            item.selectedOption.cost *
                            item.selectedOption.quantity,
                        })}
                  </span>
                  {!isClient && (
                    <span className="total-selected-option">
                      {t("placeholders.clientTotalExAmount", {
                        amount:
                          item.selectedOption.markup_cost *
                          item.selectedOption.quantity,
                      })}
                    </span>
                  )}
                </>
              )}
              {!isTemplate && (
                <SelectionBudge
                  status={
                    isClient
                      ? isCompleted
                        ? item.status
                        : item.status === EnumSelectionStatus.SELECTED
                        ? item.status
                        : EnumSelectionStatus.INCOMPLETE
                      : item.status
                  }
                  isClient={isClient}
                />
              )}
            </div>
            {isOpen && (
              <div className="ml-3 d-flex">
                {!isCompleted && !isClient && !isLocked && (
                  <div className="add-option-button">
                    <Button
                      disabled={isLocked}
                      className="button success create-button"
                      onClick={handleOnCreateOption}
                    >
                      <Icon name="add" />
                      {t("selections.createOption")}
                    </Button>
                  </div>
                )}
                {!isClient && !isLocked && (
                  <Dropdown
                    label={t("common.options")}
                    icon="expand_more"
                    id="dashboardAction-dropdown"
                    items={dropdownItems}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {isOpen && !isClient && (
          <div className="item-description">{item.description}</div>
        )}
      </div>

      {isOpen && (
        <SelectionItemOptions
          onDelete={handleOnDeleteOption}
          onEdit={handleOnEditOption}
          item={item}
          isLoading={isLoading}
          isLocked={isLocked}
          onSelect={handleOnSelectOption}
          onUnselect={handleOnUnselectOption}
          onApprove={handleOnApprove}
          isClient={isClient}
          onClientSelect={handleOnClientSelect}
          isTemplate={isTemplate}
          selectedOptions={selectedOptions}
        />
      )}
    </div>
  );
};

export default CategorySelectionItem;
