import { CategoryCostingItem } from "./costing";
import { ContactDetail } from "../graphql/types/models/client";
import { SalesQuotePrintTemplate } from "../graphql/types/models/quote-designer";
import { TakeoffListItem } from "./take-off";
import { JobType } from "./job";

export enum CalibrationType {
  None,
  Vertical,
  Horizontal,
}

export enum QuoteMeasurement {
  LINEAR_METER = "lm",
  METER_SQUARED = "m2",
  QUANTITY = "qty",
}
export enum QuoteCostingDisplay {
  INCLUDE = "INCLUDE",
  DO_NOT_INCLUDE = "DO_NOT_INCLUDE",
}

export enum QuoteStatus {
  NON_CONTRACTED = "NON_CONTRACTED",
  APPROVED = "APPROVED",
  DRAFT = "DRAFT",
  SENT = "SENT",
}

export enum QuoteArchiveStatus {
  ARCHIVED = "ARCHIVED",
  UNARCHIVED = "UNARCHIVED",
}

export enum DistributeMarkup {
  NONE = "NONE",
  CATEGORY = "CATEGORY",
  ESTIMATION = "ESTIMATION",
}

export enum SalesQuoteTemplateType {
  CUSTOM = "Custom",
  SYSTEM = "System",
}

export enum PaperType {
  A0 = "A0",
  B1 = "B1",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
}

export interface Calibration {
  lng: number;
  lat: number;
  horizontal?: number;
  scale?: string;
  sheet?: string;
}

export type CalibrationPayload = Calibration & {
  fileId: string;
};

export interface CreateSalesQuotePayload {
  _id?: string;
  name: string;
  address?: string;
  city?: string;
  state?: string;
  postcode?: string;
  templateId?: string;
  contactId: string;
  status?: string;
  markup?: number;
}

export type CopySalesQuoteInput = CreateSalesQuotePayload & {
  takeoffs?: boolean;
  documents?: boolean;
  costings?: boolean;
  specifications?: boolean;
};
export type CopySalesQuotePayload = {
  quote: CreateSalesQuotePayload;
  salesQuoteId: string;
  options: {
    takeoffs: boolean;
    documents: boolean;
    costings: boolean;
    specifications: boolean;
  };
};

export type SalesQuoteTemplate = {
  _id: string;
  name: string;
  type: SalesQuoteTemplateType;
  categories?: BuildingTemplateCategory[];
};

export type BuildingTemplateCategory = {
  _id: string;
  name: string;
  items: BuildingTemplateCategoryItem[];
};

export type BuildingTemplateCategoryItem = {
  _id: string;
  name: string;
  cost: number;
  UOM: string;
  quantity: number;
  raw_quantity: string;
  category: string;
  items?: Omit<BuildingTemplateCategoryItem, "category">[];
};

export type CreateCostingItemPayload = {
  _id?: string;
  originalId?: string;
  name: string;
  quantity: string;
  margin_amount: string;
  assemblyId: string;
  cost: string;
  cost_inc?: string;
  gstFree?: boolean;
  hasGST: boolean;
  UOM: string;
  wastage?: number;
  rounding?: string;
  raw_quantity?: string;
  note: string;
  distribute_markup?: string;
  order?: number;
  items?: {
    name: string;
    quantity: string;
    raw_quantity: string;
    cost: string;
    UOM: string;
    wastage: number;
    rounding: string;
  }[];
};

export type CreateCostingCategoryPayload = {
  name: string;
  margin_amount: string;
  costItems: CreateCostingItemPayload[];
};

export type UpdateCostingCategoryPayload = {
  _id?: string;
  name: string;
  margin_amount: string;
  costItems: CreateCostingItemPayload[];
};

export type SalesQuote = {
  _id: string;
  business?: QuoteBusiness;
  name: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  number: number;
  subTotal: number;
  GST: number;
  total: number;
  markup: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  is_locked: boolean;
  is_archived: boolean;
  contact?: ContactDetail;
  takeOffItems?: TakeoffListItem[];
  costingCategories?: SalesQuoteCategory[];
  files?: SalesQuoteFile[];
  include_costing?: boolean;
  terms?: string;
  printTemplate?: SalesQuotePrintTemplate;
  job: {
    _id: string;
    jobNumber: number;
    name: string;
    address: string;
    city: string;
    city_normalized: string;
    state: string;
    postcode: string;
    startDate: string;
    endDate: string;
    markup: number;
    type: JobType;
  } | null;
};

export type ClientSalesQuote = {
  _id: string;
  business: QuoteBusiness;
  businessName?: string;
  name: string;
  status: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  number: number;
  updatedAt: string;
  createdAt: string;
  is_deleted: boolean;
};

export type SalesQuoteCategory = {
  _id: string;
  name: string;
  costings: SalesQuoteCostItem[];
};

export type SalesQuoteCostItem = {
  _id: string;
  name: string;
  UOM: string;
  specCategoryItem: {
    _id: string;
  };
  selectionItem: {
    _id: string;
  };
};

export type SalesQuoteFile = {
  _id: string;
  type: string;
  size: number;
  name: string;
  url: string;
  upload_url: string;
  calibration: Calibration;
};

export enum EnumSalesQuoteCategoryStatus {
  OPTIONAL = "OPTIONAL",
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  NOT_USED = "NOT_USED",
}

export type CostingCategoryListItem = {
  _id: string;
  name: string;
  margin_amount: number;
  costings: CategoryCostingItem[];
  order: number;
  status?: EnumSalesQuoteCategoryStatus;
  __typename: string;
  salesQuote: {
    markup: number;
  };
};

export type QuoteBusiness = {
  _id: string;
  name: string;
};

export type TakeOffCostingItemPayload = {
  UOM: string;
  cost: number;
  name: string;
  quantity: number;
  raw_quantity: string;
  margin_amount: number | null;
  category: string;
};
export type AddTakeOffToCostingPayload = {
  items: TakeOffCostingItemPayload[];
};

export enum QuotePrintTemplateSection {
  THEME = "THEME",
  FOOTER = "FOOTER",
  COVER = "COVER",
  LETTER = "LETTER",
  COSTINGS = "COSTINGS",
  ALLOWANCES = "ALLOWANCES",
  SPECIFICATIONS = "SPECIFICATIONS",
  NOTES = "NOTES",
  SUMMARY = "SUMMARY",
  TERMS = "TERMS",
  ACCEPTANCE = "ACCEPTANCE",
}

export type SalesQuoteTakeOffTemplate = {
  _id: string;
  name: string;
  updatedAt: string;
  takeOffs: {
    _id: string;
    name: string;
    properties: {
      color: string;
    };
    UOM: string;
  };
};

export type ListSalesQuoteTakeOffTemplates = {
  listSalesQuoteTakeOffTemplates: SalesQuoteTakeOffTemplate[];
};

export type SaveTakeOffAsTemplatePayload = {
  salesQuoteId: string;
  name: String;
};
export type SaveTakeOffAsTemplateResponse = {
  saveTakeOffsAsTemplate: SalesQuoteTakeOffTemplate;
};

export type ImportTakeOffTemplatePayload = {
  templateId: string;
  salesQuoteId: String;
};
export type ImportTakeOffTemplatePayloadResponse = {
  importTakeOffTemplateToSalesQuote: SalesQuote;
};

export type DeleteTakeOffTemplatePayload = {
  templateId: string;
};

export type DeleteTakeOffTemplatePayloadResponse = {
  deleteSalesQuoteTakeOffTemplate: {
    _id: string;
    is_deleted: boolean;
  };
};
