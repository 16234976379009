import React from "react";
import { map } from "lodash";

import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import { TimesheetCategory } from "../../../models/timesheet";
import TimesheetsCategoryItem from "./TimesheetsCategoryItem";
import { Timesheet } from "../../../models/timesheet";
import { calcTotalTimesheetValue } from "../../../utils/calculations";
import "./styles.scss";

type CategoryItems = {
  processed?: Timesheet[];
  unprocessed?: Timesheet[];
};

type TimesheetsCategoriesProps = {
  items?: CategoryItems;
  selectedCategory: TimesheetCategory | null;
  selectCategory?: (status: TimesheetCategory) => void;
};

const TimesheetsCategories: React.FC<TimesheetsCategoriesProps> = ({
  items,
  selectedCategory,
  selectCategory,
}) => {
  const calcTotalCategory = (timesheets?: Timesheet[]) => {
    let total = 0.0;
    timesheets?.forEach(
      (timesheet) =>
        (total += calcTotalTimesheetValue(
          timesheet.rate,
          timesheet.startTime,
          timesheet.endTime,
          timesheet.breakDuration
        ))
    );
    return total;
  };

  const renderCategories = () => {
    return (
      <div className="jobs-timesheets-list">
        {map(Object.values(TimesheetCategory), (status) => (
          <TimesheetsCategoryItem
            key={status}
            status={status}
            onClick={selectCategory}
            isSelected={selectedCategory === status}
            total={
              status === TimesheetCategory.PROCESSED
                ? calcTotalCategory(items?.processed)
                : calcTotalCategory(items?.unprocessed)
            }
          />
        ))}
      </div>
    );
  };

  return (
    <DashboardCard className="jobs-timesheets-categories">
      <DashboardCardHeader className="text-capitalize justify-content-between"></DashboardCardHeader>
      <DashboardCardBody>{renderCategories()}</DashboardCardBody>
    </DashboardCard>
  );
};

export default TimesheetsCategories;
