import React from "react";
import { TFunction } from "i18next";
import { DashboardNavigationContext } from "../../../../containers/layouts/dashboard/DashboardContext";
import { SidebarNavItem } from "../SidebarNavListItem";

import Icon from "../../../icons/Icon";
import SVGIcon from "../../../icons/SVGIcon";

export const NAVIGATION_ROUTES = {
  DASHBOARD: "DASHBOARD",
  QUOTES_SECTION: {
    QUOTES: "QUOTES",
    DETAILS: "DETAILS",
  },
  ACCOUNT: {
    ACCOUNT: "ACCOUNT",
    MY_PROFILE: "MY_PROFILE",
    LOGOUT: "LOGOUT",
  },
};

export const createSubcontractorNavItems = (
  t: TFunction,
  context: DashboardNavigationContext | null
): SidebarNavItem[] => {
  return [
    // {
    //   id: NAVIGATION_ROUTES.DASHBOARD,
    //   icon: () => <Icon name="home" outlined />,
    //   title: t("navigation.dashboard"),
    //   url: "/",
    // },
    {
      id: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
      icon: () => <SVGIcon name="Bill" />,
      title: t("navigation.quotesSection.quotes"),
      url: "/quotes",
    },
    {
      id: NAVIGATION_ROUTES.QUOTES_SECTION.DETAILS,
      icon: () => <SVGIcon name="Bill" />,
      title: t("navigation.quotesSection.quotes"),
      url: `/quotes/${context?.quote?._id}`,
      hideInSidebarMenu: !context?.quote,
    },
    {
      id: NAVIGATION_ROUTES.ACCOUNT.ACCOUNT,
      icon: () => <SVGIcon name="User" />,
      title: t("navigation.account.profile"),
      url: "/account",
      defaultItemId: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
      expandable: true,
      items: [
        {
          id: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
          icon: () => <SVGIcon name="User" />,
          title: t("navigation.account.profile"),
          url: "/account/profile",
          hideInSidebarMenu: true,
        },
        {
          id: NAVIGATION_ROUTES.ACCOUNT.LOGOUT,
          icon: () => <Icon name="logout" outlined />,
          title: t("navigation.account.logout"),
          isLogout: true,
        },
      ],
    },
  ];
};
