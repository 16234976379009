import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { get } from "lodash";
import { notify } from "../components/notification";
import {
  IMPORT_SELECTION_TEMPLATE,
  SAVE_SELECTION_AS_TEMPLATE,
} from "../graphql/queries/selection/mutation";
import {
  ImportSelectionAsTemplateResponse,
  ListSelectionTemplatesResponse,
  SaveSelectionAsTemplateResponse,
} from "../graphql/types/models/selections";
import { LIST_SELECTION_TEMPLATES } from "../graphql/queries/selection/queries";
import { handleSelectionTemplateSave } from "../graphql/queries/selection/utils";

export type SelectionTemplateProps = {
  onImport?: () => void;
  onSave?: () => void;
};

export function useSelectionTemplates(props?: SelectionTemplateProps) {
  const { t } = useTranslation();

  const {
    data: selectionTemplatesData,
    loading: selectionTemplatesDataLoading,
  } = useQuery<ListSelectionTemplatesResponse>(LIST_SELECTION_TEMPLATES, {
    fetchPolicy: "cache-and-network",
  });

  const [importSelectionFromTemplate] = useMutation<
    ImportSelectionAsTemplateResponse
  >(IMPORT_SELECTION_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("selections.success.importFromTemplate"),
        title: t("selections.importFromTemplate"),
      });
      props?.onImport?.();
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("selections.errors.importFromTemplate")),
        title: t("selections.importFromTemplate"),
      });
    },
  });

  const [saveSelectionAsTemplate] = useMutation<
    SaveSelectionAsTemplateResponse
  >(SAVE_SELECTION_AS_TEMPLATE, {
    onCompleted: () => {
      notify({
        content: t("selections.success.saveAsTemplate"),
        title: t("selections.saveAsTemplate"),
      });
      props?.onSave?.();
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("selections.errors.saveAsTemplate")),
        title: t("selections.saveAsTemplate"),
      });
    },
    update: handleSelectionTemplateSave,
  });

  const selectionTemplates = React.useMemo(
    () => selectionTemplatesData?.listSelectionTemplates || [],
    [selectionTemplatesData?.listSelectionTemplates]
  );

  return {
    saveSelectionAsTemplate,
    importSelectionFromTemplate,
    selectionTemplates,
    selectionTemplatesDataLoading,
  };
}
