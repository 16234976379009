import React, { useState, useCallback, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import {
  chain,
  map,
  merge,
  find,
  pick,
  filter,
  isArray,
  isEmpty,
} from "lodash";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import classNames from "classnames";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  GenericFormFields,
  GenericFormTable,
  renderField,
  renderFields,
  renderTableForm,
} from "../../generic-form/GenericFormBody";
import ModalForm from "../../modals/modal-form";
import {
  createSiteDiaryRequestFields,
  filterAttachments,
  preloadDiaryAttachments,
  prepareSiteDiaryPayload,
  uploadSiteDiaryAttachments,
} from "./utils";
import createSiteDiarySchema from "./SiteDiarySchema";
import {
  LIST_SITE_DIARY_CATEGORIES,
  LIST_SITE_DIARY_TEMPLATES,
} from "../../../graphql/queries/site-diary/queries";
import {
  SiteDiary,
  SiteDiaryCategoriesResponse,
  SiteDiaryCategory,
  SiteDiaryCreatePayload,
  SiteDiaryCreateResponse,
  SiteDiaryFieldType,
  SiteDiaryFieldValue,
  SiteDiaryPayload,
  SiteDiarySubFieldValue,
  SiteDiaryTemplate,
  SiteDiaryTemplateField,
  SiteDiaryTemplateListResponse,
} from "../../../graphql/types/models/site-diary";
import { FormikProps } from "formik";
import FileInput, { FileInputRef } from "../../uploaders/file-input";
import { resizeFiles } from "../../../utils/files";
import { CREATE_SITE_DIARY } from "../../../graphql/queries/site-diary/mutations";
import { notify } from "../../notification";
import Icon from "../../icons/Icon";
import "./styles.scss";
import { FileWithPath } from "react-dropzone";
import Attachments from "../../attachments";
import { Media } from "../../../models/media";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import { handleDiaryAdd } from "../../../graphql/queries/site-diary/utils";
import UploadSpinner from "../../upload-spinner";
import { LIST_JOBS } from "../../../graphql/queries/job/queries";
import { ListJobsResponse } from "../../../graphql/types/models/job";
type SiteDiaryModalProps = {
  jobId: string;
  // onSubmit: (data: any) => void;
};

type UploadTarget = {
  fieldId?: string | null;
  rowId?: number | null;
};
export type Attachment = {
  file?: FileWithPath;
  media?: Media;
} & UploadTarget;

export type AttachmentFilter = {
  media: Media[];
  uploads: File[];
};

export type SiteDiaryModalRef = ModalDisplayRef & {
  showClone: (siteDiary: SiteDiary) => void;
  showEdit: (siteDiary: SiteDiary) => void;
};
const SiteDiaryModal: React.FC<SiteDiaryModalProps> = (props, ref) => {
  const { t } = useTranslation();
  const { jobId } = props;

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<SiteDiaryPayload>
  >({});
  const [editingDiary, setEditingDiary] = useState<SiteDiary | null>(null);
  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = useState<SiteDiaryTemplate | null>(null);
  const [initialValues, setInitialValues] = useState<any>();
  const uploadRef = React.useRef<FileInputRef>();
  const [uploadTarget, setUploadTarget] = React.useState<UploadTarget | null>(
    null
  );
  const [siteDiaryCategories, setSiteDiaryCategories] = React.useState<
    SiteDiaryCategory[]
  >([]);

  const [
    listJobs,
    { loading: jobLoading, error: jobError, data: jobsResponse },
  ] = useLazyQuery<ListJobsResponse>(LIST_JOBS);

  const [isCloned, setIsCloned] = useState(false);

  const isEditing = React.useMemo(() => !!editingDiary, [editingDiary]);

  const { shouldShow, show, hide } = useModalDisplay(ref, {
    show: () => {
      show();
      checkInitialValues();
    },
    showClone: (siteDiary: SiteDiary) => {
      show();
      preloadDiary(siteDiary);
      setIsCloned(true);
    },
    showEdit: (siteDiary: SiteDiary) => {
      setIsCloned(false);
      show();
      preloadDiary(siteDiary, true);
    },
  });

  const { data: templatesData, loading } = useQuery<
    SiteDiaryTemplateListResponse
  >(LIST_SITE_DIARY_TEMPLATES);

  const { data: categoriesData, refetch: refetchCategories } = useQuery<
    SiteDiaryCategoriesResponse
  >(LIST_SITE_DIARY_CATEGORIES, {
    variables: {
      jobId,
    },
  });

  const [createSiteDiary, { client }] = useMutation<
    SiteDiaryCreateResponse,
    SiteDiaryCreatePayload
  >(CREATE_SITE_DIARY, {
    onCompleted: refetchCategories,
  });

  const templates = React.useMemo(() => templatesData?.listSiteDiaryTemplates, [
    templatesData,
  ]);

  React.useEffect(() => {
    if (categoriesData) {
      setSiteDiaryCategories(categoriesData.listSiteDiaryCategories);
    }
  }, [categoriesData]);

  React.useEffect(() => {
    if (selectedTemplate?._id && !isEditing && !isCloned) {
      checkInitialValues();
    }
  }, [selectedTemplate, isEditing, isCloned]);

  React.useEffect(() => {
    if (isCloned) {
      listJobs();
    }
  }, [isCloned, listJobs]);

  const checkInitialValues = React.useCallback(() => {
    if (isEditing || isCloned) {
      //  === selectedTemplate?._id
      return;
    }

    const values = {
      jobId: jobId,
      templateId: selectedTemplate?._id,
      name: "",
      entry_date_time: new Date(),
      note: "",
      weather: "",
    } as any;
    selectedTemplate?.fields.forEach((field: SiteDiaryTemplateField) => {
      switch (field.type) {
        case SiteDiaryFieldType.String:
        case SiteDiaryFieldType.Number:
        case SiteDiaryFieldType.Radio:
          values[field._id] = "";
          break;
        case SiteDiaryFieldType.Date:
          values[field._id] = new Date();
          break;
        case SiteDiaryFieldType.File:
          // NFI
          break;
        case SiteDiaryFieldType.Collection:
          values[field._id] = [
            merge(
              {},
              ...map(field.subFields, (subField) => ({
                [subField._id]: "",
              }))
            ),
          ];
          break;
      }
    });
    setInitialValues(values);
  }, [selectedTemplate, isEditing, isCloned]);

  const preloadDiary = React.useCallback(
    (siteDiary: SiteDiary, isEditing: boolean = false) => {
      const values = {
        ...pick(siteDiary, ["name", "entry_date_time", "note"]),
        templateId: siteDiary.template._id,
        category: siteDiary.category?._id,
        jobId: jobId,
      } as any;
      if (isEditing) {
        values._id = siteDiary._id;
      }

      const attachments: Attachment[] = [];

      if (siteDiary.attachments?.length) {
        attachments.push(...preloadDiaryAttachments(siteDiary.attachments));
      }
      siteDiary.values.forEach((response: SiteDiaryFieldValue) => {
        switch (response.field.type) {
          case SiteDiaryFieldType.String:
          case SiteDiaryFieldType.Number:
          case SiteDiaryFieldType.Radio:
            values[response.field._id] = response.value;
            break;
          case SiteDiaryFieldType.Date:
            values[response.field._id] = moment(response.value);
            break;
          case SiteDiaryFieldType.File:
            // NFI
            break;
          case SiteDiaryFieldType.Collection:
            values[response.field._id] = [];
            response.subValues?.forEach((subValue, rowId) => {
              values[response.field._id][rowId] = merge(
                {},
                ...map(subValue.columns, (column: SiteDiarySubFieldValue) => ({
                  [column.field._id]: column.value,
                }))
              );
              if (subValue.attachments?.length) {
                attachments.push(
                  ...preloadDiaryAttachments(
                    subValue.attachments,
                    response.field._id,
                    rowId
                  )
                );
              }
            });
            break;
        }
        if (response.attachments?.length) {
          attachments.push(
            ...preloadDiaryAttachments(response.attachments, response.field._id)
          );
        }
      });
      if (isEditing) {
        setEditingDiary(siteDiary);
        values.attachments = attachments;
      }
      const template = find(templates, { _id: siteDiary.template._id });
      if (template) {
        setSelectedTemplate(template);
      }
      setInitialValues(values);
    },
    [setSelectedTemplate, setInitialValues, setEditingDiary, templates]
  );

  const validationSchema = React.useMemo(() => {
    return createSiteDiarySchema(t, isEditing || isCloned, selectedTemplate);
  }, [isEditing, isCloned, selectedTemplate]);

  const handleTemplateSelect = React.useCallback(
    (templateId: string) => {
      const template = find(templates, { _id: templateId });
      if (template) {
        setSelectedTemplate(template);
      }
    },
    [templates]
  );

  const handleRemoveAttachment = React.useCallback(
    (formikProps: FormikProps<any>, item: File | Media) => {
      formikProps.setFieldValue(
        "attachments",
        filter(
          formikProps.values.attachments,
          (f: Attachment) => !(item === f.file || item === f.media)
        )
      );
    },
    []
  );

  const uploadFieldPhoto = React.useCallback(
    (fieldId: string, rowId?: number) => () => {
      setUploadTarget({ fieldId, rowId });
      uploadRef.current?.open();
    },
    [uploadRef]
  );

  const handleDeleteRow = React.useCallback(
    (formikProps, name, rowIndex) => {
      formikProps.setFieldValue(
        "attachments",
        filter(
          formikProps.values.attachments,
          (f: Attachment) => f.fieldId !== name && f.rowId !== rowIndex
        )
      );
    },
    [selectedTemplate]
  );

  const handleCreateCategory = React.useCallback(
    (value: string) => {
      const match = find(
        siteDiaryCategories,
        (c) => c.name.toLowerCase() === value.toLowerCase()
      );
      if (match) return;

      setSiteDiaryCategories([
        ...siteDiaryCategories.filter((c) => c._id !== "_new"),
        {
          _id: "_new",
          name: value,
        },
      ]);
    },
    [siteDiaryCategories]
  );

  const renderFieldAttachments = React.useCallback(
    (formikProps, fieldId?: string, rowId?: number) => {
      const { media, uploads } = filterAttachments(
        formikProps.values?.attachments as Attachment[],
        fieldId,
        rowId
      );

      if (!media.length && !uploads.length) {
        return null;
      }

      return (
        <div
          className={classNames("sd-upload-row", {
            "sd-upload-row-indent": !!fieldId,
          })}
        >
          <Attachments
            remoteFiles={media}
            files={uploads}
            onRemove={(item: File | Media) =>
              handleRemoveAttachment(formikProps, item)
            }
          />
        </div>
      );
    },
    [handleRemoveAttachment]
  );

  const renderSecondRow = React.useCallback(
    (formikProps, fieldId, rowIndex) => {
      const RowAttachments = renderFieldAttachments(
        formikProps,
        fieldId,
        rowIndex
      );
      if (!RowAttachments) return null;
      return (
        <tr className="sd-table-row-attachments">
          <td colSpan={10}>{RowAttachments}</td>
        </tr>
      );
    },
    [renderFieldAttachments]
  );

  const renderTableRowUploadButton = React.useCallback(
    (fieldId: string, rowIndex: number) => {
      return (
        <div className="sd-row-upload-icon">
          <div
            className="sd-row-upload-icon"
            onClick={uploadFieldPhoto(fieldId, rowIndex)}
          >
            <Icon name="add_a_photo" />
          </div>
        </div>
      );
    },
    [uploadFieldPhoto]
  );

  React.useEffect(() => {
    const jobs =
      isCloned && jobsResponse?.listJobs ? jobsResponse.listJobs : null;

    setFormFields(
      createSiteDiaryRequestFields(
        t,
        isEditing || isCloned,
        handleTemplateSelect,
        templates,
        selectedTemplate,
        siteDiaryCategories,
        handleCreateCategory,
        jobs,
        renderSecondRow,
        renderTableRowUploadButton,
        handleDeleteRow
      )
    );
  }, [
    t,
    templates,
    selectedTemplate,
    siteDiaryCategories,
    renderSecondRow,
    renderTableRowUploadButton,
    handleCreateCategory,
    handleDeleteRow,
    isEditing,
    jobsResponse,
    isCloned,
  ]);

  const handleClose = useCallback(() => {
    setIsCloned(false);
    hide();
    setInitialValues({});
    setSelectedTemplate(null);
    setEditingDiary(null);
  }, [hide, setInitialValues]);

  const onAttach = React.useCallback(
    async (acceptedFiles: FileWithPath[], formikProps: FormikProps<any>) => {
      const compressedFiles = (await resizeFiles(
        acceptedFiles
      )) as FileWithPath[];
      const mappedFiles: Attachment[] = compressedFiles.map(
        (file: FileWithPath) => ({
          file,
          fieldId: uploadTarget?.fieldId,
          rowId: uploadTarget?.rowId,
        })
      );

      const newFiles = chain(formikProps.values.attachments)
        .concat(mappedFiles)
        .compact()
        .uniqBy((a: Attachment) =>
          [a.fieldId, a.file?.name, a.file?.size].join(".")
        )
        .value();

      formikProps.setFieldValue("attachments", newFiles);
      setUploadTarget(null);
    },
    [uploadTarget]
  );

  const fileUploader = React.useCallback(
    (formikProps: FormikProps<any>) => {
      if (!selectedTemplate) return null;
      return (
        <Row>
          <Col xs={12} className="form-column">
            <div className="uploader">
              <label className="form-input-label form-label">
                {t("siteDiary.additionalPhotos")}
              </label>
              <FileInput
                ref={uploadRef}
                hideIcon={false}
                onUpload={(files) => onAttach(files, formikProps)}
                onCancel={() => setUploadTarget(null)}
                fileName={null}
                accept="image/*"
              />
            </div>
          </Col>
        </Row>
      );
    },
    [selectedTemplate, uploadTarget]
  );

  const renderFieldUploadButton = React.useCallback((field, id) => {
    return (
      <div
        className={classNames("sd-row-upload", {
          "sd-row-upload-table": isArray(field?.row),
        })}
        onClick={uploadFieldPhoto(id)}
      >
        <div className="sd-row-upload-icon">
          <Icon name="add_a_photo" />
        </div>
      </div>
    );
  }, []);

  const renderRow = React.useCallback(
    (formikProps: FormikProps<any>, field: any, id: string, index?: number) => {
      if (!selectedTemplate || id == "templateId") {
        return renderFields(formikProps, field);
      }

      if (index && index >= 0) {
        return (
          <>
            <div className="sd-row">
              <div className="sd-row-count field-text">{index}.</div>
              <div className="sd-row-field">
                {isArray(field?.row)
                  ? renderTableForm(formikProps, field, id)
                  : renderField(formikProps, field, 12)}
              </div>
              {renderFieldUploadButton(field, id)}
            </div>
            {renderFieldAttachments(formikProps, id)}
          </>
        );
      }
      if (isArray(field)) {
        return renderFields(formikProps, field);
      }
      return (
        <div className="sd-row">
          <div className="sd-row-field">
            {renderField(formikProps, field, 12)}
          </div>
        </div>
      );
    },
    [selectedTemplate, renderFieldAttachments, renderFieldUploadButton]
  );

  const renderRows = React.useCallback(
    (formikProps, formFields) => {
      let index = 1;
      return map(
        formFields,
        (field: GenericFormTable<SiteDiaryPayload, any>, id) => {
          if (id === "jobSelect") return null;
          if (selectedTemplate) {
            const templateField = find(selectedTemplate.fields, { _id: id });
            if (templateField) {
              if (templateField.type === SiteDiaryFieldType.Heading) {
                return (
                  <div className="pt-4" key={templateField._id}>
                    <h4>{templateField.title}</h4>
                  </div>
                );
              }
              return (
                <Row key={id}>{renderRow(formikProps, field, id, index++)}</Row>
              );
            }
          }
          return <Row key={id}>{renderRow(formikProps, field, id)}</Row>;
        }
      );
    },
    [selectedTemplate]
  );

  const handleSubmit = React.useCallback(
    async (values: any) => {
      const siteDiaryJobId = isCloned && values.job ? values.job : jobId;

      if (!selectedTemplate) return;
      const siteDiary = prepareSiteDiaryPayload(
        selectedTemplate,
        siteDiaryCategories,
        values
      );
      siteDiary.jobId = siteDiaryJobId;

      const result = await createSiteDiary({
        variables: {
          jobId: siteDiaryJobId,
          siteDiary,
        },
      });
      const siteDiaryResult = result.data?.createUpdateSiteDiary;
      if (siteDiaryResult) {
        await uploadSiteDiaryAttachments(values.attachments, siteDiaryResult);
      }

      notify({
        title: t("siteDiary.createEntry"),
        content: t("siteDiary.success.createSiteDiary"),
      });
      setIsCloned(false);

      // fix media cache
      if (isEmpty(values.job))
        handleDiaryAdd(jobId)(client.cache as any, result);

      hide();
      setInitialValues({});
      setSelectedTemplate(null);
      setEditingDiary(null);
      return true;
    },
    [
      selectedTemplate,
      siteDiaryCategories,
      isEditing,
      editingDiary,
      hide,
      isCloned,
      setIsCloned,
    ]
  );

  const leftFooterRenderer = React.useCallback(
    (formikProps) => {
      if (formFields.jobSelect) {
        return (
          <div className="d-flex flex-fill">
            {renderField(formikProps, formFields.jobSelect, 12)}
            <div className="d-flex" />
          </div>
        );
      }
    },
    [formFields]
  );

  if (!shouldShow) return null;

  return (
    <ModalForm
      validationSchema={validationSchema}
      className="site-diary-modal"
      title={
        isEditing ? t("siteDiary.updateEntry") : t("siteDiary.createEntry")
      }
      show={shouldShow}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={handleClose}
      submitText={t("common.save")}
      enableReinitialize={true}
      leftFooterRenderer={leftFooterRenderer}
    >
      {(formikProps) => (
        <>
          <Container className="generic-form-body" fluid>
            <Row>
              <Col lg={12} md={12}>
                {renderRows(formikProps, formFields)}
                {fileUploader(formikProps)}
                {renderFieldAttachments(formikProps)}
              </Col>
            </Row>
          </Container>
          <UploadSpinner
            show={formikProps.isSubmitting}
            text={t("siteDiary.submittingText")}
          />
        </>
      )}
    </ModalForm>
  );
};

export default forwardRef(SiteDiaryModal);
