import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { chain, compact, concat, map, transform } from "lodash";
import { BadgeProps } from "react-bootstrap";
import { SiteDiary } from "../../../graphql/types/models/site-diary";
import CategorySelectorCard, {
  CategorySelectorFilter,
  PaginationButton,
} from "../../category-select-card";
import { useFilteredArray } from "../../../hooks/useFilteredArray";
import { DropdownItem } from "../../dashboard/dropdown";
import EntryGroup from "./EntryGroup";
import CategorySelectItem from "../../category-select-card/CategorySelectItem";
import { Pagination } from "../../../models/pagination";
import "./styles.scss";

type SiteDiaryListProps = {
  entries?: SiteDiary[];
  selectedEntry: SiteDiary | null;
  onSelect?: (siteDiary: SiteDiary) => void;
  onAddNewItem?: () => void;
  onExportAll?: (siteDiaries: SiteDiary[]) => Promise<SiteDiary[]>;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  hideAddBtn?: boolean;
  hideFilter?: boolean;
  additionalFilters?: () => JSX.Element;
  pagination?: Pagination;
  onPagination?: (type: PaginationButton) => void;
  disableGroups?: boolean;
};

const SiteDiaryList: React.FC<SiteDiaryListProps> = (props) => {
  const {
    entries,
    selectedEntry,
    onSelect,
    onExportAll,
    onAddNewItem,
    title,
    placeholder,
    disabled = false,
    hideAddBtn = false,
    hideFilter,
    additionalFilters,
    pagination,
    onPagination,
    disableGroups,
  } = props;
  const { t } = useTranslation();

  const [diaryFilter, setFilter] = React.useState<CategorySelectorFilter>({
    id: "",
  });
  const [itemsToPrint, setItemsToPrint] = React.useState<SiteDiary[]>();
  const printRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (selectedEntry) {
      setFilter({ id: selectedEntry._id });
    }
  }, [selectedEntry]);

  const handleSelectEntry = React.useCallback(
    (filter: CategorySelectorFilter) => {
      if (filter.id) {
        const entry = entries?.find((e) => e._id === filter.id);
        if (entry) {
          onSelect?.(entry);
        }
      }
    },
    [onSelect, entries]
  );

  const entryList = React.useMemo(() => {
    return map(entries, (entry) => ({
      id: entry._id,
      label: entry.name,
      description: compact([
        moment(entry.entry_date_time).format("D MMMM YYYY"),
        entry.job?.name,
      ]).join(", "),
      category: entry.category?.name,
      rightLabel: entry.template?.name,
      data: entry,
    }));
  }, [t, entries]);

  const templates = React.useMemo(
    () =>
      chain(entries)
        .map((e) => e.template?.name)
        .uniq()
        .sort()
        .value(),
    [entries]
  );

  const {
    filteredArray,
    filter: arrayFilter,
    setFilter: setArrayFilter,
  } = useFilteredArray(entryList, "rightLabel");

  const filterDropdownItems = React.useMemo<DropdownItem[]>(
    () =>
      concat(
        {
          id: "-",
          label: t("common.all"),
          onClick: () => setArrayFilter(""),
        },
        templates.map((template) => ({
          id: template,
          label: template,
          onClick: () => setArrayFilter(template),
        }))
      ),
    [t, templates, setArrayFilter]
  );

  const categories = React.useMemo(() => {
    const list = transform(
      filteredArray,
      (result: any, item) => {
        const category = !!item.category
          ? item.category
          : t("siteDiary.ungrouped");
        result[category] = (result[category] || []).concat(item);
      },
      {}
    );
    return Object.keys(list)
      .sort()
      .reduce((result: any, key: string) => {
        result[key] = list[key];
        return result;
      }, {});
  }, [filteredArray]);

  const renderGroupedItems = React.useCallback(() => {
    return map(categories, (items, name) => (
      <EntryGroup
        key={name}
        name={name}
        exportItems={map(items, (i) => i.data)}
        onExportAll={onExportAll}
      >
        {map(items, (item) => (
          <CategorySelectItem
            key={item._id}
            item={item}
            onSelect={() => handleSelectEntry(item)}
          />
        ))}
      </EntryGroup>
    ));
  }, [categories, itemsToPrint, printRef]);

  const categoryKeys = Object.keys(categories);
  if (
    !disableGroups &&
    (categoryKeys.length > 1 ||
      (categoryKeys.length === 1 && !!categories[categoryKeys[0]][0].category))
  ) {
    return (
      <>
        <CategorySelectorCard
          title={title || ""}
          placeholder={placeholder || t("siteDiary.emptyPlaceholder")}
          onSelectCategory={handleSelectEntry}
          filter={diaryFilter}
          dropdownFilter={arrayFilter}
          dropdownFilterLabel={t("siteDiary.filterByType")}
          filterDropdownItems={filterDropdownItems}
          disabled={disabled}
          hideSelectAllButton
          hideAddButton={hideAddBtn}
          hideFilter={hideFilter}
          onAddNewItem={onAddNewItem}
          additionalFilters={additionalFilters}
          pagination={pagination}
          onPagination={onPagination}
        >
          {renderGroupedItems()}
        </CategorySelectorCard>
      </>
    );
  }

  return (
    <CategorySelectorCard
      title={title || ""}
      placeholder={placeholder || t("siteDiary.emptyPlaceholder")}
      onSelectCategory={handleSelectEntry}
      filter={diaryFilter}
      dropdownFilter={arrayFilter}
      dropdownFilterLabel={t("siteDiary.filterByType")}
      filterDropdownItems={filterDropdownItems}
      categories={filteredArray}
      disabled={disabled}
      hideSelectAllButton
      hideAddButton={hideAddBtn}
      hideFilter={hideFilter}
      onAddNewItem={onAddNewItem}
      additionalFilters={additionalFilters}
      pagination={pagination}
      onPagination={onPagination}
    />
  );
};

export default SiteDiaryList;
