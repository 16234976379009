import React, { WheelEvent } from "react";
import classNames from "classnames";
import { Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import "./styles.scss";
import { FieldAppend } from "../../GenericFormBody";
import InputAppend from "../input-apend";

export type TextInputProps = {
  rowIndex?: number;
  append?: FieldAppend;
  name: string;
  value: any;
  className?: string;
  touched?: boolean;
  error?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  controlType?: React.ElementType;
  onChange: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: React.SyntheticEvent<any>) => void;
  onKeyDown?: React.KeyboardEventHandler;
  onClick?: (event: any) => void;
  width?: string;
  maxWidth?: string;
  rows?: number;
  disabled?: boolean;
  showError?: boolean;
  readOnly?: boolean;
  autoComplete?: "off" | "on" | string;
  debounceChangeEvent?: boolean;
};

const TextInput: React.FC<TextInputProps> = ({
  name,
  value,
  autoComplete = "off",
  disabled,
  className,
  touched,
  error,
  type,
  required,
  placeholder,
  onChange,
  onBlur,
  onKeyDown,
  onClick,
  append,
  rows,
  width,
  maxWidth,
  controlType,
  showError,
  rowIndex,
  readOnly,
  debounceChangeEvent,
  children,
}) => {
  const [fieldValue, setFieldValue] = React.useState(""); // convert to string to fix issue when deleting rows

  React.useEffect(() => setFieldValue(value ? `${value}` : ""), [value]);

  const handleAppendClick = React.useCallback(() => {
    append?.onClick &&
      append.onClick(name, value, rowIndex, append.formikProps);
  }, [name, append, rowIndex, value]);

  const handleOnChange = React.useCallback(
    (e: React.ChangeEvent<any>) => {
      const newValue = e.target.value;
      if (value !== newValue || newValue !== fieldValue) {
        setFieldValue(e.target.value);
        const isInputEvent = e.nativeEvent instanceof InputEvent;
        if (!isInputEvent || !debounceChangeEvent) {
          onChange && onChange(e);
        }
      }
    },
    [type, fieldValue, value, setFieldValue, debounceChangeEvent]
  );

  const handleOnBlur = React.useCallback(
    (e: React.FocusEvent<any>) => {
      if (value?.toString() !== fieldValue?.toString()) {
        debounceChangeEvent &&
          onChange &&
          onChange({
            target: {
              value: fieldValue,
            },
          } as React.ChangeEvent<any>);
        onBlur && onBlur(e);
      }
    },
    [fieldValue, value, onChange, onBlur, debounceChangeEvent]
  );

  const isNumberType = React.useMemo(() => {
    return type === "number";
  }, [type]);

  const numberInputOnWheelPreventChange = (e: WheelEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    target.blur();
  };

  const renderAppend = React.useCallback(() => {
    if (!append) {
      return null;
    }

    return (
      <InputAppend
        {...append}
        fieldName={name}
        rowIndex={rowIndex}
        onClick={handleAppendClick}
      />
    );
  }, [append, name, rowIndex, handleAppendClick]);

  const style = React.useMemo(
    () => ({
      minWidth: width,
      width: maxWidth,
    }),
    [width, maxWidth]
  );

  const groupClasses = React.useMemo(
    () =>
      classNames(
        {
          "is-invalid": touched && !!error && showError,
        },
        "form-input-group"
      ),
    [touched, error, showError]
  );

  return (
    <InputGroup className={groupClasses} style={style} onClick={onClick}>
      <Form.Control
        autoComplete={autoComplete}
        disabled={disabled}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        name={name}
        onKeyDown={onKeyDown}
        onWheel={isNumberType ? numberInputOnWheelPreventChange : () => {}}
        as={controlType}
        isInvalid={touched && !!error}
        value={fieldValue} // convert to string to fix issue when deleting rows
        className={classNames(
          {
            "text-input": !controlType || controlType === "input",
            "textarea-input": controlType === "textarea",
          },
          className
        )}
        type={type}
        rows={rows}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
      />
      {renderAppend()}
      {children}
    </InputGroup>
  );
};

export default TextInput;
