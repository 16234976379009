import { gql } from "@apollo/client";

export const QUOTE_REQUEST_COMMENT_SUB = gql`
  subscription onCommentQuoteAdded($quoteRequestId: ID!) {
    comment: subscribeCommentQuoteRequest(quoteRequestId: $quoteRequestId) {
      _id
      comment
      createdAt
      sender {
        _id
        name
        email
        senderType
      }
      attachments {
        _id
        upload_url
        url
        type
        size
      }
    }
  }
`;
