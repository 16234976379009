import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  defaultDataIdFromObject,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { SubscriptionClient } from "subscriptions-transport-ws/dist/client";
import history from "../router/history";
import { SiteContext } from "../siteContext";

const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:4000/graphql";

export const TOKEN_TYPE_USER: string = "user";
export const TOKEN_TYPE_SUBCONTRACTOR: string = "subcontractor";
export const TOKEN_TYPE_ADMIN: string = "admin";
export const TOKEN_TYPE_CLIENT: string = "client";
export const TOKEN_TYPE_AFFILIATE: string = "affiliate";

class GraphQLClient {
  static TOKEN_KEY = "accessToken";
  static TOKEN_TYPE = "accessType";

  client: ApolloClient<any>;
  token: string = "";
  tokenType: string = "";

  subscriptionsClient: SubscriptionClient;

  constructor(uri: string, subLink: string) {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          if (extensions?.code === "SUBSCRIPTION_ERROR") {
            return history.replace("/settings/billing");
          }
          if (extensions?.code === "AUTHORIZATION_ERROR") {
            return history.replace("/sign-in");
          }
        });
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const http = new HttpLink({
      uri,
      credentials: "same-origin",
    });

    this.subscriptionsClient = new SubscriptionClient(subLink, {
      reconnect: true,
      connectionParams: () => {
        return {
          "X-Access-Token": this.token ? this.token : "",
        };
      },
    });

    const wsLink = new WebSocketLink(this.subscriptionsClient);

    const httpLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      http
    );

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          "X-Access-Token": this.token ? this.token : "",
        },
      };
    });

    const link = errorLink.concat(authLink).concat(httpLink);

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        // set the object's cache ID
        dataIdFromObject(responseObject) {
          if (
            responseObject.__typename &&
            responseObject.__typename === "Media"
          ) {
            return `${responseObject.__typename}:${responseObject._id}${responseObject.url}`;
          }
          if (responseObject.__typename && responseObject._id) {
            return `${responseObject.__typename}:${responseObject._id}`;
          }
          return defaultDataIdFromObject(responseObject);
        },
      }),
    });

    this.getToken();
  }

  setToken = (token: string, type: string = TOKEN_TYPE_USER) => {
    this.token = token;
    localStorage.setItem(GraphQLClient.TOKEN_KEY, token);
    localStorage.setItem(GraphQLClient.TOKEN_TYPE, type);
    // force websocket to reconnect with new header
    this.subscriptionsClient.close(false, true);
  };

  getToken = () => {
    if (!this.token) {
      this.token = localStorage.getItem(GraphQLClient.TOKEN_KEY) || "";
    }
    if (!this.tokenType) {
      this.tokenType = localStorage.getItem(GraphQLClient.TOKEN_TYPE) || "";
    }

    return {
      token: this.token,
      tokenType: this.tokenType,
    };
  };

  checkToken = (context: SiteContext) => {
    const token = this.getToken();
    let tokenType = TOKEN_TYPE_USER;
    if (context.isSubcontractor) tokenType = TOKEN_TYPE_SUBCONTRACTOR;
    else if (context.isAdmin) tokenType = TOKEN_TYPE_ADMIN;
    else if (context.isClient) tokenType = TOKEN_TYPE_CLIENT;
    else if (context.isAffiliate) tokenType = TOKEN_TYPE_AFFILIATE;

    if (
      !/^\/(sign|reset|forgot)-/.test(history.location.pathname) &&
      (!token?.token || token?.tokenType !== tokenType)
    ) {
      return history.push("/sign-in");
    }
  };

  clearStore = () => {
    this.client && this.client.clearStore();
  };
}

export default new GraphQLClient(API_URL, API_URL.replace(/^http/, "ws"));
