import React from "react";
import { ModalDisplayRef } from "../../../../hooks/useModalDisplay";
import ImportScheduleFromTemplateModal from "../../../../components/job-schedule/import-schedule-from-template";
import SaveScheduleAsTemplateModal from "../../../../components/job-schedule/save-schedule-as-template";

export function useImportFromTemplate(jobId?: string) {
  const importRef = React.useRef<ModalDisplayRef>();
  const saveRef = React.useRef<ModalDisplayRef>();

  const renderImportFromTemplate = React.useCallback(() => {
    return <ImportScheduleFromTemplateModal ref={importRef} jobId={jobId} />;
  }, [importRef, jobId]);

  const handleImportFromTemplate = React.useCallback(() => {
    importRef.current?.show(true);
  }, [importRef]);

  const renderSaveAsTemplate = React.useCallback(() => {
    return <SaveScheduleAsTemplateModal ref={saveRef} jobId={jobId} />;
  }, [saveRef, jobId]);

  const handleSaveAsTemplate = React.useCallback(() => {
    saveRef.current?.show(true);
  }, [saveRef]);

  return {
    renderImportFromTemplate,
    handleImportFromTemplate,
    renderSaveAsTemplate,
    handleSaveAsTemplate,
  };
}
