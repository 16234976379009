import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import SignUpConfirm from "../../components/authentication/sign-up-confirm";
import AuthenticationLayout from "../layouts/authentication";
import {
  ACTIVATE_PROFILE,
  RESEND_ACTIVATION,
} from "../../graphql/queries/auth/mutations";
import { notify } from "../../components/notification";
import {
  ActivateProfileResponse,
  ResendAcivationEmailResponse,
} from "../../graphql/types/models/auth";

type Params = {
  id: string;
  confirmHash: string;
};

const SignUpActivateContainer: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { t } = useTranslation();
  const history = useHistory();

  const [resendActivationEmail] = useMutation<ResendAcivationEmailResponse>(
    RESEND_ACTIVATION,
    {
      onCompleted: (data) => {
        notify({
          title: t("authentication.success.resendActivationTitle"),
          content: t("authentication.success.resendActivation"),
        });
      },
      onError: (error) => {
        notify({
          title: t("authentication.errors.resendActivationTitle"),
          content: t("authentication.errors.resendActivation"),
          error: true,
        });
      },
    }
  );

  const [activateProfile] = useMutation<ActivateProfileResponse>(
    ACTIVATE_PROFILE,
    {
      onCompleted: (data) => {
        notify({
          title: t("authentication.success.activateProfileTitle"),
          content: t("authentication.success.activateProfile"),
        });
        history.push("/sign-in");
      },
      onError: (error) => {
        notify({
          title: t("authentication.errors.activateProfileTitle"),
          content: error.message,
          error: true,
        });
      },
    }
  );

  React.useEffect(() => {
    activateProfile({
      variables: {
        userId: match.params.id,
        confirmHash: match.params.confirmHash,
      },
    });
  }, []);

  const handleResend = React.useCallback(
    (e: any) => {
      e.preventDefault();
      resendActivationEmail({
        variables: {
          userId: match.params.id,
        },
      });
    },
    [resendActivationEmail, match]
  );

  return (
    <AuthenticationLayout>
      <Helmet title={t("authentication.activateYourAccount")} />
      <SignUpConfirm
        failed={true}
        loading={false}
        onResendClick={handleResend}
      />
    </AuthenticationLayout>
  );
};

export default SignUpActivateContainer;
