import React from "react";
import { useTranslation } from "react-i18next";
import CreateEntityModal from "../../modals/create-entity";
import UpdateEntityModal from "../../modals/update-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { createUpdateSpecificationField } from "./utils";
import createSpecificationShema from "./CreateSpecificationSchema";
import {
  SpecificationCategory,
  CreateUpdateSpecCategoryModalPayload,
  CreateUpdateSpecCategoryItemModalPayload,
} from "../../../models/specification";
import "./styles.scss";

type CreateUpdateSpecCategoryModalProps = {
  data: SpecificationCategory | null;
  show: boolean;
  onSubmit: (data: CreateUpdateSpecCategoryModalPayload) => void;
  onClose: () => void;
};

const CreateUpdateSpecCategoryModal: React.FC<CreateUpdateSpecCategoryModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const { show, onSubmit, onClose, data } = props;

  const initialCreateData = React.useMemo<CreateUpdateSpecCategoryModalPayload>(
    () => ({
      name: "",
      items: [
        {
          name: "",
          description: "",
          order: 0,
        },
      ],
    }),
    []
  );

  const formFields = React.useMemo<
    GenericFormFields<CreateUpdateSpecCategoryModalPayload>
  >(() => {
    return createUpdateSpecificationField(t);
  }, [t]);

  const getInitialUpdateData = React.useCallback(() => {
    if (data) {
      const result: CreateUpdateSpecCategoryModalPayload = {
        _id: data._id,
        name: data.name,
      };

      if (data.items) {
        result.items = data.items.map((item, order) => {
          const resultItem: CreateUpdateSpecCategoryItemModalPayload = {
            _id: item._id,
            name: item.name,
            order: item.order || order,
          };

          resultItem.description = item.description || "";

          if (item.url) {
            resultItem.photo = {
              url: item.url,
            };
          }

          return resultItem;
        });
      }
      return result;
    }
    return initialCreateData;
  }, [data, initialCreateData]);

  return data ? (
    <UpdateEntityModal
      className="create-spesification-modal"
      validationSchema={createSpecificationShema(t)}
      title={t("specifications.editSpecification")}
      show={show}
      data={getInitialUpdateData()}
      onSubmit={onSubmit}
      onClose={onClose}
      fields={formFields}
    />
  ) : (
    <CreateEntityModal
      className="create-spesification-modal"
      validationSchema={createSpecificationShema(t)}
      title={t("specifications.addSpecCategory")}
      show={show}
      data={initialCreateData}
      onSubmit={onSubmit}
      onClose={onClose}
      fields={formFields}
    />
  );
};

export default CreateUpdateSpecCategoryModal;
