import React from "react";
import { map } from "lodash";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import CreateSalesQuoteModal, {
  CreateSalesQuoteModalRef,
} from "../../quotes/create-sales-quote-modal";
import {
  QuoteListRequest,
  QuoteListResponse,
} from "../../../graphql/types/models/quote";
import { LIST_QUOTES } from "../../../graphql/queries/quote/queries";
import { QuoteStatus } from "../../../models/salesQuote";
import DashboardQuoteItem from "./QuoteItem";
import EmptyPlaceholder from "../../empty-placeholder";
import "./styles.scss";

type DashboardQuoteCardProps = {
  isLocked?: boolean;
};

const DashboardQuoteCard: React.FC<DashboardQuoteCardProps> = (props) => {
  const { isLocked = false } = props;

  const { t } = useTranslation();

  const createQuoteRef = React.useRef<CreateSalesQuoteModalRef>(null);

  const { data, loading } = useQuery<QuoteListResponse, QuoteListRequest>(
    LIST_QUOTES,
    {
      variables: {
        filter: {
          status: QuoteStatus.DRAFT,
        },
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const renderQuotes = React.useCallback(() => {
    if (loading) return null;
    if (data?.listSalesQuotes?.length) {
      return map(data?.listSalesQuotes, (quote) => (
        <DashboardQuoteItem key={quote._id} quote={quote} t={t} />
      ));
    }
    return !isLocked ? (
      <EmptyPlaceholder
        message={t("dashboard.emptyQuotesPlaceholder")}
        buttonText={t("quotes.createEstimation")}
        onButtonPress={() => createQuoteRef.current?.show(true)}
      />
    ) : (
      <EmptyPlaceholder message={t("dashboard.emptyQuotesPlaceholder")} />
    );
  }, [t, data, loading, isLocked]);

  if (isLocked) return null;

  return (
    <>
      <DashboardCard className="dashboard-quotes">
        <DashboardCardHeader className="justify-content-between">
          {t("dashboard.quotesInProgress")}
        </DashboardCardHeader>
        <DashboardCardBody>{renderQuotes()}</DashboardCardBody>
      </DashboardCard>
      <CreateSalesQuoteModal ref={createQuoteRef} />
    </>
  );
};

export default DashboardQuoteCard;
