import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery, { PhotoProps } from "react-photo-gallery";
import { compact } from "lodash";
import Dropdown from "../../dashboard/dropdown";
import {
  EnumSelectionStatus,
  SelectionCategoryItemOption,
} from "../../../graphql/types/models/selections";
import SelectionBudge from "../badge";
import { Media } from "../../../models/media";
import Icon from "../../icons/Icon";
import { attachmentsToPhotos, imageType } from "./utils";
import TextInput from "../../generic-form/inputs/text";

type SelectionItemOptionProps = {
  option: SelectionCategoryItemOption;
  index: number;
  onDelete?: (option: SelectionCategoryItemOption) => void;
  onSelect?: (option: SelectionCategoryItemOption) => void;
  onUnselect?: () => void;
  onEdit?: (option: SelectionCategoryItemOption) => void;
  onApprove?: () => void;
  onClientSelect?: (
    option: SelectionCategoryItemOption,
    comment?: string
  ) => void;
  selectedBy?: string;
  approvedBy?: string;
  clientComment?: string;
  selectedComment?: string;
  clientSignatureUrl?: string;
  isCompleted: boolean;
  isLoading?: boolean;
  isClient?: boolean;
  isLocked?: boolean;
  isTemplate?: boolean;
  selected?: boolean;
};

const SelectionItemOption: React.FC<SelectionItemOptionProps> = (props) => {
  const {
    onEdit,
    onDelete,
    option,
    index,
    onSelect,
    selectedBy,
    approvedBy,
    onApprove,
    isCompleted,
    onUnselect,
    isLoading,
    isClient,
    isLocked,
    isTemplate,
    onClientSelect,
    selected,
    selectedComment,
    clientComment,
    clientSignatureUrl,
  } = props;
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = React.useState(0);
  const [commentValue, setSetCommentValue] = React.useState(selectedComment);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
  const [currentGallery, setCurrentGallery] = React.useState<PhotoProps<any>>(
    []
  );

  const openLightbox = React.useCallback(
    (gallery: PhotoProps<any>[]) => (event: React.MouseEvent, props: any) => {
      setCurrentGallery(gallery);
      setCurrentImage(props.index);
      setViewerIsOpen(true);
    },
    []
  );

  const closeLightbox = () => {
    setCurrentGallery([]);
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const renderGallery = React.useCallback(
    (attachments?: Media[]) => {
      if (!attachments?.length) return null;
      const gallery = attachmentsToPhotos(attachments);
      return (
        <Gallery
          photos={gallery}
          targetRowHeight={200}
          margin={5}
          onClick={openLightbox(gallery)}
        />
      );
    },
    [viewerIsOpen]
  );

  const handleOnCommentChange = React.useCallback(
    (comment: string) => {
      setSetCommentValue(comment);
      onClientSelect && onClientSelect(option, comment);
    },
    [onClientSelect, option]
  );

  const handleOnEdit = React.useCallback(() => {
    onEdit && onEdit(option);
  }, [onEdit, option]);

  const handleOnDelete = React.useCallback(() => {
    onDelete && onDelete(option);
  }, [onDelete, option]);

  const handleOnSelect = React.useCallback(() => {
    onSelect && onSelect(option);
  }, [onSelect, option]);

  const handleOnClientSelect = React.useCallback(() => {
    onClientSelect && onClientSelect(option);
  }, [onClientSelect, option]);

  const dropdownItems = React.useMemo(() => {
    return compact([
      !isTemplate && {
        id: "select",
        label: selectedBy ? t("selections.unselect") : t("selections.select"),
        name: "select",
        onClick: selectedBy ? onUnselect : handleOnSelect,
      },
      {
        id: "edit",
        label: t("common.edit"),
        name: "edit",
        onClick: handleOnEdit,
      },
      {
        id: "delete",
        label: t("common.delete"),
        name: "delete",
        onClick: handleOnDelete,
      },
    ]);
  }, [
    handleOnDelete,
    handleOnEdit,
    handleOnSelect,
    onUnselect,
    selectedBy,
    isTemplate,
  ]);

  const optionTitle = `${index + 1}. ${option.name}`;

  return (
    <div className="option-container">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex flex-column justify-content-center ">
          <div className="option-title">
            <span>{optionTitle}</span>
            {selectedBy && isCompleted && (
              <SelectionBudge
                status={EnumSelectionStatus.SELECTED}
                isOption={true}
                isClient={isClient}
              />
            )}
            {((selectedBy && !isCompleted) || selected) && (
              <SelectionBudge
                status={EnumSelectionStatus.SELECTED}
                isClient={isClient}
              />
            )}
            <div className="option-subtitle">
              <span>
                {t("placeholders.quantityAmount", {
                  amount: option.quantity,
                })}
              </span>
              <span>
                {t("placeholders.uomAmount", {
                  amount: option.UOM,
                })}
              </span>
              {!isClient && (
                <span>
                  {t("placeholders.unitCostAmount", {
                    amount: option.cost,
                  })}
                </span>
              )}
              <span>
                {isClient
                  ? t("placeholders.unitCostAmount", {
                      amount: option.markup_cost,
                    })
                  : t("placeholders.clientUnitCostAmount", {
                      amount: option?.markup_cost,
                    })}
              </span>
              <span>
                {isClient
                  ? t("placeholders.totalExfixedAmount", {
                      amount: option?.markup_cost * option.quantity,
                    })
                  : t("placeholders.clientTotalExAmount", {
                      amount: option?.markup_cost * option.quantity,
                    })}
              </span>
            </div>
          </div>
        </div>
        <div className="ml-3">
          {!isCompleted && !isClient && !isLocked && (
            <Dropdown
              label={t("common.options")}
              icon="expand_more"
              id="dashboardAction-dropdown"
              items={dropdownItems}
            />
          )}
        </div>
      </div>

      <div className="option-description">{option.description}</div>
      <div className="files-conatiner">
        {!isLoading &&
          renderGallery(
            option.attachments.filter((file) => imageType.includes(file.type))
          )}
        {option.attachments
          .filter((file) => !imageType.includes(file.type))
          .map((attachment) => {
            return (
              <div className="file-container" key={attachment._id}>
                <a
                  href={attachment.url}
                  target="_blank"
                  className="note-item--attachments--file pr-2 d-flex cursor-pointer"
                >
                  <Icon
                    name={
                      attachment.type === "application/pdf"
                        ? "picture_as_pdf"
                        : "description"
                    }
                    outlined
                  />
                </a>
                <div className="text-truncate file-name">{attachment.name}</div>
              </div>
            );
          })}
      </div>
      {selectedBy && (
        <>
          <div className="selected-by">
            <div>{selectedBy}</div>
            {clientSignatureUrl && !isLoading && (
              <div className="clientSignature-container">
                <img src={clientSignatureUrl} />
              </div>
            )}
            {clientComment && (
              <div>
                <div>{t("common.comment")}</div>
                <div className="clientComment">{clientComment}</div>
              </div>
            )}
          </div>
          {!isCompleted && !isClient && !isLocked && (
            <Button
              className="button success create-button mb-3"
              onClick={onApprove}
            >
              {t("common.approve")}
            </Button>
          )}
        </>
      )}
      {approvedBy && (
        <div className="selected-by">
          <div>{approvedBy}</div>
        </div>
      )}

      {!isCompleted && isClient && !selectedBy && (
        <Button
          className="button success create-button mb-2 color-primary"
          onClick={handleOnClientSelect}
          disabled={selected}
        >
          {selected ? t("client.quotes.selected") : t("client.quotes.select")}
        </Button>
      )}
      {selected && (
        <div className="form-group">
          <label className="form-input-label form-label font-weight-bold">
            {t("client.quotes.itemComments")}
          </label>
          <TextInput
            placeholder={t("client.quotes.itemComments")}
            name={t("client.quotes.itemComments")}
            type={"text"}
            value={commentValue}
            controlType="textarea"
            rows={3}
            onChange={(e) => handleOnCommentChange(e.currentTarget.value)}
            className="text-input form-input form-control"
          ></TextInput>
        </div>
      )}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={currentGallery.map((photo: PhotoProps<any>) => ({
                source: {
                  regular: photo.src,
                  fullscreen: photo.src,
                },
                caption: photo.name,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default SelectionItemOption;
