import { TFunction } from "i18next";
import * as yup from "yup";
import moment from "moment";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createImportScheduleSchema(t: TFunction) {
  return yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "common.name")
          ),
      })
    ),
  });
}
