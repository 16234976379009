import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import { concat } from "lodash";

import {
  CreateUpdateItemOptionResponse,
  CreateUpdateQuoteSelectionCategoryResponse,
  CreateUpdateSelectionCategoryItemResponse,
  EnumSelectionLinkedEntityType,
  GetSelectionTemplateResponse,
  ListSelectionTemplatesResponse,
  SalesQuoteGetSelectionResponse,
  SaveSelectionAsTemplateResponse,
  SelectionResult,
  SelectionTemplateType,
} from "../../types/models/selections";
import {
  GET_SELECTION,
  GET_SELECTION_TEMPLATE,
  LIST_SELECTION_TEMPLATES,
} from "./queries";

const getSelection = (cache: ApolloCache<any>, salesQuoteId?: string) => {
  const specCategoriesListResponse = cache.readQuery<
    SalesQuoteGetSelectionResponse
  >({
    query: GET_SELECTION,
    variables: {
      linkedEntityId: salesQuoteId,
      linkedEntityType: EnumSelectionLinkedEntityType.ESTIMATION,
    },
  });

  return specCategoriesListResponse?.getSelection;
};

const updateSelectionCache = (
  cache: ApolloCache<any>,
  data: SelectionResult,
  salesQuoteId?: string
) => {
  cache.writeQuery<SalesQuoteGetSelectionResponse>({
    query: GET_SELECTION,
    variables: {
      linkedEntityId: salesQuoteId,
      linkedEntityType: EnumSelectionLinkedEntityType.ESTIMATION,
    },
    data: {
      getSelection: data,
    },
  });
};

export const handleAddCategory = (
  salesQuoteId?: string
): MutationUpdaterFn<CreateUpdateQuoteSelectionCategoryResponse> => (
  cache,
  { data }
) => {
  const addedCategory = data?.createUpdateSelectionCategory;
  const cachedData = getSelection(cache, salesQuoteId);

  if (!addedCategory || !cachedData) {
    return;
  }

  const updatedData = {
    ...cachedData,
    categories: concat(cachedData.categories, addedCategory),
  };
  updateSelectionCache(cache, updatedData, salesQuoteId);
};

export const handleUpdateCategory = (
  salesQuoteId?: string
): MutationUpdaterFn<CreateUpdateQuoteSelectionCategoryResponse> => (
  cache,
  { data }
) => {
  const updatedCategory = data?.createUpdateSelectionCategory;
  const cachedData = getSelection(cache, salesQuoteId);

  if (!updatedCategory || !cachedData) {
    return;
  }

  const updatedData = {
    ...cachedData,
    categories: cachedData.categories.map((category) => {
      if (category._id !== updatedCategory._id) return category;
      return updatedCategory;
    }),
  };
  updateSelectionCache(cache, updatedData, salesQuoteId);
};

export const handleAddCategoryItem = (
  salesQuoteId?: string,
  categoryId?: string
): MutationUpdaterFn<CreateUpdateSelectionCategoryItemResponse> => (
  cache,
  { data }
) => {
  const addedCategoryItem = data?.createUpdateSelectionCategoryItem;
  const cachedData = getSelection(cache, salesQuoteId);

  if (!addedCategoryItem || !cachedData) {
    return;
  }
  const currentCategory = cachedData.categories.find(
    (item) => item._id === categoryId
  );
  if (currentCategory) {
    const updatedCategoryItems = concat(
      currentCategory.items,
      addedCategoryItem
    );

    const updatedData = {
      ...cachedData,
      categories: cachedData.categories.map((category) => {
        if (category._id !== categoryId) return category;
        return { ...category, items: updatedCategoryItems };
      }),
    };
    updateSelectionCache(cache, updatedData, salesQuoteId);
  }
};

export const handleAddCategoryItemOption = (
  salesQuoteId?: string,
  categoryId?: string,
  itemId?: string
): MutationUpdaterFn<CreateUpdateItemOptionResponse> => (cache, { data }) => {
  const addedCategoryItemOption = data?.createUpdateSelectionCategoryItemOption;
  const cachedData = getSelection(cache, salesQuoteId);

  if (!addedCategoryItemOption || !cachedData) {
    return;
  }
  const currentCategory = cachedData.categories.find(
    (item) => item._id === categoryId
  );
  if (currentCategory) {
    const updatedCategoryItems = currentCategory.items.map((item) => {
      if (item._id !== itemId) return item;
      return { ...item, options: item.options.concat(addedCategoryItemOption) };
    });
    const updatedData = {
      ...cachedData,
      categories: cachedData.categories.map((category) => {
        if (category._id !== categoryId) return category;
        return { ...category, items: updatedCategoryItems };
      }),
    };
    updateSelectionCache(cache, updatedData, salesQuoteId);
  }
};

const getSelectionTemplateList = (cache: ApolloCache<any>) => {
  const cacheData = cache.readQuery<ListSelectionTemplatesResponse>({
    query: LIST_SELECTION_TEMPLATES,
  });
  return cacheData?.listSelectionTemplates;
};

const updateSelectionTemplateList = (
  cache: ApolloCache<any>,
  newList: SelectionTemplateType[]
) => {
  cache.writeQuery<ListSelectionTemplatesResponse>({
    query: LIST_SELECTION_TEMPLATES,
    data: {
      listSelectionTemplates: newList,
    },
  });
};

export const handleSelectionTemplateSave: MutationUpdaterFn<SaveSelectionAsTemplateResponse> = (
  cache,
  response
) => {
  const item = response.data?.saveSelectionAsTemplate;
  const templatesList = getSelectionTemplateList(cache) || [];

  if (!item) {
    return;
  }

  updateSelectionTemplateList(cache, templatesList.concat(item));
};

const getSelectionTemplates = (
  cache: ApolloCache<any>,
  templateId?: string
) => {
  const templatesResponse = cache.readQuery<GetSelectionTemplateResponse>({
    query: GET_SELECTION_TEMPLATE,
    variables: {
      templateId,
    },
  });

  return templatesResponse?.getSelectionTemplate;
};

const updateSelectionTemplatesCache = (
  cache: ApolloCache<any>,
  data: SelectionTemplateType,
  templateId?: string
) => {
  cache.writeQuery<GetSelectionTemplateResponse>({
    query: GET_SELECTION_TEMPLATE,
    variables: {
      templateId,
    },
    data: {
      getSelectionTemplate: data,
    },
  });
};

export const handleAddTemplateCategory = (
  templateId?: string
): MutationUpdaterFn<CreateUpdateQuoteSelectionCategoryResponse> => (
  cache,
  { data }
) => {
  const addedCategory = data?.createUpdateSelectionCategory;
  const cachedData = getSelectionTemplates(cache, templateId);

  if (!addedCategory || !cachedData) {
    return;
  }

  const updatedData = {
    ...cachedData,
    categories: concat(cachedData.categories, addedCategory),
  };
  updateSelectionTemplatesCache(cache, updatedData, templateId);
};

export const handleAddTemplateCategoryItem = (
  templateId?: string,
  categoryId?: string
): MutationUpdaterFn<CreateUpdateSelectionCategoryItemResponse> => (
  cache,
  { data }
) => {
  const addedCategoryItem = data?.createUpdateSelectionCategoryItem;
  const cachedData = getSelectionTemplates(cache, templateId);

  if (!addedCategoryItem || !cachedData) {
    return;
  }
  const currentCategory = cachedData.categories.find(
    (item) => item._id === categoryId
  );
  if (currentCategory) {
    const updatedCategoryItems = concat(
      currentCategory.items,
      addedCategoryItem
    );

    const updatedData = {
      ...cachedData,
      categories: cachedData.categories.map((category) => {
        if (category._id !== categoryId) return category;
        return { ...category, items: updatedCategoryItems };
      }),
    };
    updateSelectionTemplatesCache(cache, updatedData, templateId);
  }
};

export const handleAddTemplateCategoryItemOption = (
  templateId?: string,
  categoryId?: string,
  itemId?: string
): MutationUpdaterFn<CreateUpdateItemOptionResponse> => (cache, { data }) => {
  const addedCategoryItemOption = data?.createUpdateSelectionCategoryItemOption;
  const cachedData = getSelectionTemplates(cache, templateId);

  if (!addedCategoryItemOption || !cachedData) {
    return;
  }
  const currentCategory = cachedData.categories.find(
    (item) => item._id === categoryId
  );
  if (currentCategory) {
    const updatedCategoryItems = currentCategory.items.map((item) => {
      if (item._id !== itemId) return item;
      return { ...item, options: item.options.concat(addedCategoryItemOption) };
    });
    const updatedData = {
      ...cachedData,
      categories: cachedData.categories.map((category) => {
        if (category._id !== categoryId) return category;
        return { ...category, items: updatedCategoryItems };
      }),
    };
    updateSelectionTemplatesCache(cache, updatedData, templateId);
  }
};
