import { invoke, set } from "lodash";
import get from "lodash/get";
import React from "react";
import { COMMENT_PATH } from "../../take-off-plan-viewer/utils";

const usePlanViewerComment = (
  onCommentCreate: (layer: L.Marker, commentData: any) => void,
  onCommentDelete: (layer: L.Marker, commentData: any) => void
) => {
  const bindClickEvents = ({ layer, onOpen, onClose, doNotOpen }: any) => {
    let isOpen = invoke(layer, "_popup.isOpen");
    layer.off("popupopen");
    layer.off("popupclose");
    layer.on("popupopen", () => onOpen(layer));
    layer.on("popupclose", () => onClose(layer));

    if (isOpen) {
      onOpen(layer);
    } else {
      !doNotOpen && layer.openPopup();
    }
  };

  const checkCommentLayer = (layer: L.Marker) => {
    if (!layer) {
      return false;
    }

    const layerData = get(layer, COMMENT_PATH);
    if (!layerData || !layerData.leaflet_id) {
      layer.remove();
      return true;
    }
  };

  const handleCommentViewClose = (layer: L.Marker) => {
    checkCommentLayer(layer);
  };

  const handleCommentFormClose = (layer: L.Marker) => {
    if (!checkCommentLayer(layer)) {
      handleOpenCommentView(layer);
    }
  };

  const submitCommentForm = (e: any, layer: L.Marker) => {
    e && e.preventDefault();

    let layerOpt = layer.toGeoJSON();
    layerOpt.id = layerOpt.id || get(layer, "_leaflet_id");
    layerOpt.properties.text = e.target.text.value;
    layerOpt.properties.isEdited = !layerOpt.properties.isEdited;
    set(layer, COMMENT_PATH, layerOpt);
    onCommentCreate(layer, layerOpt);
    handleOpenCommentView(layer);
  };

  const onViewClick = (layer: any, layerOpt: any) => {
    let container = layer._popup._container.querySelector("#comment-view");
    const editButton = container.querySelector("#edit-button");
    const deleteButton = container.querySelector("#delete-button");
    if (editButton) {
      editButton.onclick = () => {
        handleOpenCommentForm(layer);
      };
    }

    if (deleteButton) {
      deleteButton.onclick = () => {
        onCommentDelete(layer, layerOpt);
        checkCommentLayer(layer);
      };
    }
  };

  // new comment
  const handleOpenCommentForm = (layer: L.Marker) => {
    const map: L.Map = (layer as any)._map;
    !!map && map.fire("wunderbuild:disable_comment");
    const commentData = get(layer, COMMENT_PATH);
    let text = get(commentData, "properties.text", "");
    layer.bindPopup(
      `<form id="comment-form" class="popup-form comment-form js-popup-content">
      <textarea id="name-input" name="text" class="name-input">${text}</textarea>
      <div class="popup-actions d-flex justify-content-end w-100">
        <button id="close-button" type="button" class="button close-button js-close-pop-up-btn">
        	<i class="material-icons">close</i>
        </button>
        <button id="submit-button" type="submit" class="button submit-button btn-reset">
          <i class="material-icons">check</i>
        </button>
      </div>
    </form>`,
      {
        minWidth: 280,
        maxHeight: 200,
        closeOnClick: true,
      }
    );

    bindClickEvents({
      layer,
      onOpen: () => {
        let container = (layer as any)._popup._container.querySelector(
          "#comment-form"
        );
        container.onsubmit = (e: any) => submitCommentForm(e, layer);
        container.querySelector("#close-button").onclick = () => {
          handleCommentFormClose(layer);
        };
        container.querySelector("#name-input").focus();
      },
      onClose: () => {
        // handleCommentFormClose(layer);
      },
    });
  };

  // view comment
  const handleOpenCommentView = (layer: L.Marker, doNotOpen?: boolean) => {
    const layerOpt: GeoJSON.Feature = get(layer, COMMENT_PATH) || {};
    const text = layerOpt.properties?.text || "";
    // <div class="popup-actions d-flex justify-content-end w-100">
    // <button id="edit-button" type="button" class="button close-button">
    //   <i class="material-icons">edit</i>
    // </button>
    // <button id="delete-button" class="button submit-button">
    //   <i class="material-icons">delete</i>
    // </button>
    layer.bindPopup(
      `<div id="comment-view" class="popup-form comment-form js-popup-content">
      <p>${text}</p>
      </div>
    </div>`,
      {
        minWidth: 280,
        maxHeight: 200,
      }
    );
    bindClickEvents({
      layer,
      onOpen: () => onViewClick(layer, layerOpt),
      onClose: () => handleCommentViewClose(layer),
      doNotOpen,
    });
  };

  return {
    openCommentForm: handleOpenCommentForm,
    openCommentView: handleOpenCommentView,
  };
};

export default usePlanViewerComment;
