import React from "react";
import { Toast, ToastBody, ToastHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import "./styles.scss";
import classNames from "classnames";

type NotificationProps = {
  closeToast?: () => void;
  error?: boolean;
  content?: string;
  title?: string;
};

const Notification = (props: NotificationProps) => {
  const { closeToast, content, title, error } = props;

  const bodyClasses = classNames("body", { error });
  const titleClasses = classNames("title field-text", { error });

  return (
    <Toast className="border-0 notification" onClose={closeToast}>
      <ToastHeader closeButton={true} className={titleClasses}>
        {title}
      </ToastHeader>
      <ToastBody className={bodyClasses}>{content}</ToastBody>
    </Toast>
  );
};

export const notify = (props?: NotificationProps) => {
  toast.dismiss();
  toast(<Notification {...props} />, {
    toastId: props?.error ? "error" : "success",
    closeButton: false,
    hideProgressBar: true,
    position: "top-center",
  });
};

export default Notification;
