import React from "react";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import { Button } from "react-bootstrap";
import Icon from "../../icons/Icon";
import "./styles.scss";
import { useTranslation } from "react-i18next";

type ConversationsFooterProps = {
  onNewClick?: () => void;
  hideButton?: boolean;
  disabled?: boolean;
  footerButtonText?: string;
};

const ConversationsFooter: React.FC<ConversationsFooterProps> = (props) => {
  const { t } = useTranslation();

  const { onNewClick, disabled, hideButton, footerButtonText } = props;

  const renderSubmitButton = () => {
    if (hideButton) {
      return;
    }

    return (
      <Button
        disabled={disabled}
        variant="primary"
        className="button large success"
        onClick={onNewClick}
      >
        <Icon name="add" />
        {footerButtonText ? footerButtonText : t("common.createNew")}
      </Button>
    );
  };

  return (
    <DashboardCardFooter className="communication-footer">
      {renderSubmitButton()}
    </DashboardCardFooter>
  );
};

export default ConversationsFooter;
