import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { isEmpty, filter, some, map } from "lodash";
import pdfjsLib, { PDFPageProxy } from "pdfjs-dist";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import ConfirmDialog, { ConfirmDialogRef } from "../../../confirm-dialog";
import DashboardCard from "../../../dashboard/card";
import DashboardCardBody from "../../../dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../../dashboard/card/DashboardCardFooter";
import DashboardCardHeader from "../../../dashboard/card/DashboardCardHeader";
import SelectPagesList from "./SelectPagesList";
import SelectPagesListItem, {
  SelectPagesListItemRef,
} from "./SelectPagesListItem";

import "./styles.scss";

// The workerSrc property shall be specified.
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

type SelectPagesCardProps = {
  pages: Array<PDFPageProxy | null>;
  onCancel: () => void;
  onSubmit: (
    pages: number[],
    canvasRefs: SelectPagesListItemRef[],
    replacePlans: boolean
  ) => void;
  isSubmitting: boolean;
  hasPlans: boolean;
  sectionMessage?: string;
};

const SelectPagesCard: React.FC<SelectPagesCardProps> = ({
  pages,
  onCancel,
  onSubmit,
  isSubmitting,
  hasPlans,
  sectionMessage,
}) => {
  const { t } = useTranslation();
  const [selectedPages, setSelectedPages] = React.useState<number[]>([]);
  const [nextPageRender, setNextPageRender] = React.useState<number>(0);
  const hasSelectedPage = React.useMemo(() => !isEmpty(selectedPages), [
    selectedPages,
  ]);
  const [replacePlans, setReplacePlans] = React.useState(false);
  const confirmRef = React.useRef<ConfirmDialogRef>(null);

  React.useEffect(() => {
    pageRefs.current = pageRefs.current.slice(0, pages.length);
  }, [pages]);
  const pageRefs = React.useRef<SelectPagesListItemRef[]>([]);

  React.useEffect(() => {
    setNextPageRender(0);
  }, [pages]);

  const handleItemClick = React.useCallback(
    (pageNumber: number) => () => {
      const hasPage = some(selectedPages, (p) => p === pageNumber);
      setSelectedPages(
        hasPage
          ? filter(selectedPages, (p) => p !== pageNumber)
          : [...selectedPages, pageNumber]
      );
    },
    [selectedPages]
  );

  const handleCancelClick = React.useCallback(() => {
    setSelectedPages([]);
    onCancel && onCancel();
  }, [setSelectedPages, onCancel]);

  const handleNextClick = React.useCallback(() => {
    const canvases = selectedPages.map((page) => pageRefs.current[page]);
    onSubmit(selectedPages, canvases, replacePlans);
  }, [selectedPages, replacePlans, onSubmit]);

  const handleNextConfirm = React.useCallback(() => {
    if (!hasPlans || !replacePlans) {
      handleNextClick();
    } else {
      confirmRef.current?.show(true);
    }
  }, [confirmRef, replacePlans, hasPlans, selectedPages, onSubmit]);

  const handleRenderComplete = React.useCallback(
    (page: number) => {
      setNextPageRender(page + 1);
    },
    [setNextPageRender]
  );

  const hasFinishedRender = React.useMemo(
    () => pages.length === nextPageRender,
    [pages, nextPageRender]
  );

  return (
    <DashboardCard
      className={classNames("select-pages-card", { inactive: !document })}
    >
      <DashboardCardHeader>{t("plansSection.selectPages")}</DashboardCardHeader>
      {sectionMessage && (
        <div className="card-title field-text">{sectionMessage}</div>
      )}
      <DashboardCardBody>
        {!isEmpty(pages) && (
          <SelectPagesList>
            {map(pages, (page, pageNumber) => (
              <SelectPagesListItem
                ref={(ref: SelectPagesListItemRef) =>
                  (pageRefs.current[pageNumber] = ref)
                }
                key={pageNumber}
                pageNumber={pageNumber}
                page={page}
                isSelected={some(selectedPages, (p) => p === pageNumber)}
                onClick={handleItemClick(pageNumber)}
                shouldRender={pageNumber === nextPageRender}
                onRenderComplete={handleRenderComplete}
              />
            ))}
          </SelectPagesList>
        )}
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end">
        {hasPlans && (
          <div className="justify-content-center align-content-center pr-2">
            <Form.Check
              className="d-flex h-100 align-items-center"
              type="checkbox"
              id="overwrite"
              label={t("plansSection.replaceExistingPlans")}
              checked={replacePlans}
              onChange={() => setReplacePlans(!replacePlans)}
            />
          </div>
        )}

        {!!pages.length && (
          <Button
            className="button info mr-2"
            onClick={handleCancelClick}
            disabled={isSubmitting}
          >
            {t("common.cancel")}
          </Button>
        )}

        <Button
          className="button success"
          onClick={handleNextConfirm}
          disabled={isSubmitting || !hasSelectedPage || !hasFinishedRender}
        >
          {t("common.next")}
        </Button>
      </DashboardCardFooter>

      <ConfirmDialog
        ref={confirmRef}
        title={t("plansSection.replaceExistingPlans")}
        confirm={t("common.yes")}
        onSubmit={handleNextClick}
      >
        <span className="field-text">
          {t("plansSection.confirmReplacePlans")}
        </span>
      </ConfirmDialog>
    </DashboardCard>
  );
};

export default SelectPagesCard;
