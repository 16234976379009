import {
  PurchaseOrderReceiptItem,
  ReceiptDocuments,
} from "../../models/purchaseOrder";
import { TableCardData } from "../../components/dashboard/table-card/utils";
import { TFunction } from "i18next";
import { calcItemTotal } from "../../utils/calculations";
import { map, filter, includes } from "lodash";

export type ReceiptTableItem = PurchaseOrderReceiptItem & {
  total: number;
};

export const createReceiptItemsTable = (
  receiptItems: PurchaseOrderReceiptItem[] | null,
  t: TFunction,
  hiddenFields?: [keyof PurchaseOrderReceiptItem]
): TableCardData<ReceiptTableItem> => {
  const table: TableCardData<ReceiptTableItem> = {
    columns: [
      {
        valueKey: "name",
        title: t("costing.name"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "quantity",
        title: t("orders.receivedQuantity"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.quantity", { amount: value }),
      },
      {
        valueKey: "UOM",
        title: t("costing.uom"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "cost",
        title: t("costing.cost"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.currency", { amount: value }),
      },
      {
        valueKey: "margin_amount",
        title: t("costing.markup"),
        formatValue: (row: any, column: any, value: string) => `${value}%`,
      },
      {
        valueKey: "total",
        title: t("costing.totalEx"),
        formatValue: (row: any, column: any, value: number) =>
          t("common.currency", { amount: value }),
      },
    ],
    rows: map(receiptItems, (receipt) => ({
      cells: {
        ...receipt,
        total: calcItemTotal(receipt, receipt.margin_amount || 0),
      },
    })),
  };

  const filteredColumns = filter(
    table.columns,
    (column) => !includes(hiddenFields, column.valueKey)
  );

  return {
    ...table,
    columns: filteredColumns,
  };
};

export const createReceiptTable = (
  receipts: ReceiptDocuments[],
  t: TFunction
) => {
  return {
    columns: [
      {
        valueKey: "name",
        title: t("common.name"),
      },
    ],
    rows: map(receipts, (item) => ({
      cells: {
        _id: item._id,
        name: item.name,
      },
    })),
  };
};
