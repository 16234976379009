import React from "react";
import { useTranslation } from "react-i18next";
import { ModalDisplayRef } from "../../../../hooks/useModalDisplay";
import { GetOrderPreviewResponse } from "../../../../graphql/types/models/order";
import { useSuppliersQuery } from "../../../../hooks/queries/useSuppliersQuery";
import { PurchaseOrderReal } from "../../../../models/purchaseOrder";
import { GET_ORDER_PREVIEW } from "../../../../graphql/queries/order/queries";
import { useApolloClient, useMutation } from "@apollo/client";
import { printBase64Pdf } from "../../../../utils/pdf";
import { notify } from "../../../../components/notification";
import { SEND_ORDER } from "../../../../graphql/queries/order/mutations";
import { SendEmailForm } from "../../../../models/email";
import { formatQuoteNumber } from "../../../../utils/text";
import SendModal from "../../../../components/modals/send-modal";
import { SystemFolderType } from "../../../../models/documents";

export function useSendOrder() {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { suppliers } = useSuppliersQuery();

  const sendRef = React.useRef<ModalDisplayRef>(null);
  const [order, setOrder] = React.useState<PurchaseOrderReal>();
  const [showCc, setShowCc] = React.useState(false);
  const [showBcc, setShowBcc] = React.useState(false);
  const previewFileName = React.useMemo(
    () => `Order #${order?.orderNumber}.pdf`,
    [order]
  );

  const [sendOrder] = useMutation(SEND_ORDER, {
    onCompleted: () => {
      notify({
        title: t("orders.sendOrder"),
        content: t("orders.success.sendOrder"),
      });
      sendRef.current?.show(false);
      setShowCc(false);
      setShowBcc(false);
    },
    onError: () => {
      notify({
        error: true,
        title: t("orders.sendOrder"),
        content: t("orders.errors.sendOrder"),
      });
      setShowCc(false);
      setShowBcc(false);
    },
  });

  const showSendOrder = React.useCallback(
    (purchaseOrder: PurchaseOrderReal) => {
      setOrder(purchaseOrder);
      sendRef.current?.show(true);
    },
    [sendRef]
  );

  const copyMailOptions = React.useMemo(() => {
    return {
      showCc,
      setShowCc,
      showBcc,
      setShowBcc,
      showReplyTo: true,
    };
  }, [showBcc, showCc]);

  const handlePrint = React.useCallback(async () => {
    try {
      const preview = await client.query<GetOrderPreviewResponse>({
        query: GET_ORDER_PREVIEW,
        fetchPolicy: "network-only",
        variables: {
          jobId: order?.job?._id,
          purchaseOrderId: order?._id,
        },
      });

      printBase64Pdf(preview?.data?.getJobPurchaseOrderPreview.pdf);
    } catch (e) {}
  }, [order]);

  const handleSendOrderSubmit = React.useCallback(
    (message: SendEmailForm) => {
      return sendOrder({
        variables: {
          jobId: order?.job?._id,
          purchaseOrderId: order?._id,
          message: {
            subject: message.title,
            to: message.to,
            ...(showCc && { cc: message.cc }),
            ...(showBcc && { bcc: message.bcc }),
            ...(message.replyTo && { replyTo: message.replyTo }),
            message: message.message,
            remoteAttachmentId: message.remoteAttachmentId,
          },
        },
      });
    },
    [order, sendRef, showCc, showBcc]
  );

  const renderSendOrderModal = React.useCallback(() => {
    return (
      <SendModal
        ref={sendRef}
        fileName={previewFileName}
        onFileClick={handlePrint}
        onSubmit={handleSendOrderSubmit}
        contacts={suppliers}
        contact={order?.supplier}
        title={t("orders.sendOrder")}
        subject={t("orders.sendOrderSubject", {
          orderNumber: order ? formatQuoteNumber(order.orderNumber, "PO") : "",
        })}
        submitText={t("common.send")}
        copyOptions={copyMailOptions}
        addDocumentsSelect={{
          entityId: order?.job?._id,
          systemFolderType: SystemFolderType.JOB,
        }}
      />
    );
  }, [
    previewFileName,
    handlePrint,
    handleSendOrderSubmit,
    suppliers,
    order,
    t,
    copyMailOptions,
  ]);

  return {
    renderSendOrderModal,
    showSendOrder,
  };
}
