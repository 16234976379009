import React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import CreateEntityModal from "../../modals/create-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { createJobCostingsField } from "./utils";
import CardTable from "../../dashboard/table-card/CardTable";
import { TableCardData } from "../../dashboard/table-card/utils";
import { EmptyTablePlaceholder } from "../../dashboard/table-card";
import SearchInput from "../../search-input";
import { ListJobCostingsResponse } from "../../../graphql/types/models/job-costing";
import { LIST_JOB_COSTINGS } from "../../../graphql/queries/job-costing/queries";
import { JobCostingItem } from "../../../models/job";
import { ClaimItem } from "../../../models/claim";
import { calculateAmountToDate } from "../claim-modal/utils";
import "./styles.scss";

export type CostingsfieldName = "items";

type JobCostingsModalProps = {
  show: boolean;
  onClose: () => void;
  formikProps: FormikProps<any>;
  fieldName: CostingsfieldName;
  jobId?: string;
};

const JobCostingsModal: React.FC<JobCostingsModalProps> = (props) => {
  const { show, onClose, formikProps, fieldName, jobId } = props;

  const { t } = useTranslation();

  const [fields, setFields] = React.useState<
    GenericFormFields<{ category: string }>
  >({});
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");

  const formikRef = React.useRef<any>();

  const { data: jobCostingData, loading: costingLoading } = useQuery<
    ListJobCostingsResponse
  >(LIST_JOB_COSTINGS, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const costingItemIds = React.useMemo(
    () =>
      formikProps.values[fieldName].map(
        (costing: ClaimItem) => costing.costingItemId
      ),
    [fieldName, formikProps.values]
  );

  const categories = React.useMemo(() => {
    return jobCostingData?.getJobCostings || [];
  }, [jobCostingData]);

  const categoryItems = React.useMemo(() => {
    if (selectedCategory) {
      const category = categories?.find(
        (category) => category._id === selectedCategory
      );

      return category ? category.items : [];
    }
    return [];
  }, [categories, selectedCategory]);

  const handleCategoryChange = React.useCallback((value: string) => {
    setSelectedCategory(value);
  }, []);

  const resetForm = React.useCallback(() => {
    setFields(
      createJobCostingsField(t, handleCategoryChange, categories || [])
    );
  }, [t, handleCategoryChange, categories]);

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleSelect = React.useCallback(
    (item: any) => {
      const { cells } = item;
      const { values, setFieldValue } = formikProps;

      const {
        amountToDate,
        percentageToDate,
        total,
        remaining,
      } = calculateAmountToDate(cells);

      const newRow = {
        amount: "",
        amountToDate,
        costingItemId: cells?._id || "",
        hasGST: true,
        name: cells?.name || "",
        note: cells?.note || "",
        percentage: "",
        percentageToDate,
        remaining,
        total,
      };

      setFieldValue(fieldName, values[fieldName].concat(newRow));
    },
    [fieldName, formikProps]
  );

  const filteredItems = React.useMemo(
    () =>
      categoryItems?.filter((item) => {
        return item.name?.toUpperCase().includes(searchInput.toUpperCase());
      }),
    [categoryItems, searchInput]
  );

  const costingData = React.useMemo<
    TableCardData<JobCostingItem & { total?: number }>
  >(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "UOM",
          title: t("costing.uom"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "total",
          title: t("costing.totalEx"),
          formatValue: (row: any, column: any, value: number) => {
            const total =
              (row.cells.markup_cost || row.cells.cost) * row.cells.quantity;
            return !isNaN(total) ? t("common.currency", { amount: total }) : "";
          },
        },
        {
          valueKey: "_id",
          title: "",
          formatValue: (row: any, column: any, value: number) => {
            const isAddedItem = costingItemIds.includes(row?.cells?._id);
            return (
              <Button
                size="sm"
                variant="info"
                onClick={() => handleSelect(row)}
                className="button add-button"
                disabled={isAddedItem}
              >
                {t("common.add")}
              </Button>
            );
          },
        },
      ],
      rows:
        filteredItems?.map((item) => ({
          cells: item,
        })) || [],
    };
  }, [t, filteredItems, formikProps, costingItemIds]);

  const closeButton = React.useCallback(() => {
    return (
      <Button
        size="lg"
        variant="success"
        onClick={onClose}
        className="button large success"
      >
        {t("common.close")}
      </Button>
    );
  }, []);

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("costing.emptyPriceLookupSearchPlaceholder", {
        filter: searchInput,
      }),
    }),
    [searchInput, t]
  );

  const table = React.useMemo(() => {
    return (
      <>
        <div className="search-input-container">
          <SearchInput
            value={searchInput}
            onChange={setSearchInput}
            showCancelButton
            className="search-input"
            appendSearchIcon
          />
        </div>
        <CardTable
          rowCount
          table={costingData}
          emptyTableText={emptyPlaceholder.text}
        />
      </>
    );
  }, [costingData, t]);

  return (
    <CreateEntityModal
      formikRef={formikRef}
      className="job-costings-modal"
      title={t("claims.addCostingItem")}
      data={{
        category: "",
      }}
      show={show}
      fields={fields}
      onSubmit={() => {}}
      onClose={onClose}
      endRenderer={selectedCategory ? table : undefined}
      leftFooterRenderer={closeButton}
      hideSubmitButtons={true}
    />
  );
};

export default JobCostingsModal;
