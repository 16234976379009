import React from "react";
import AutocompleteInput from "../../generic-form/inputs/autocomplete";
import { useTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import ClientItem from "./ClientItem";
import { JobContact } from "../../../models/job-contact";
import { ContactNameListItem } from "../../../models/client";
import Loader from "../../loader";

type ClientHeaderDropdownProps = {
  jobContacts?: JobContact[];
  contacts?: ContactNameListItem[];
  selectedClient: string | null;
  onClientChange: (id: string) => void;
  onRemove: (id: string) => void;
  onAccessGrant: (id: string) => void;
  onAccessRevoke: (id: string, contactId: string) => void;
  openClientModal: () => void;
  updating: boolean;
  isReadonly?: boolean;
};

const ClientHeaderDropdown: React.FC<ClientHeaderDropdownProps> = (props) => {
  const {
    openClientModal,
    contacts,
    updating,
    jobContacts,
    onClientChange,
    selectedClient,
    onRemove,
    onAccessGrant,
    onAccessRevoke,
    isReadonly = false,
  } = props;

  const { t } = useTranslation();

  const handleClientChange = React.useCallback(
    (value: string) => {
      onClientChange(value);
    },
    [onClientChange]
  );

  const contactOptions = React.useMemo(() => {
    return map(contacts, (contact) => {
      const fullName = contact
        ? `${contact?.first_name} ${contact?.last_name}`
        : "";

      return {
        value: contact._id,
        label: fullName,
      };
    });
  }, [contacts]);

  const renderLoader = () => {
    if (!updating) {
      return null;
    }

    return (
      <div className="client-loader">
        <Loader />
      </div>
    );
  };

  const renderClients = () => {
    if (!updating && isEmpty(jobContacts)) {
      return (
        <div className="field-text p-2 text-truncate client-empty-message">
          {t("clientAccess.noClientAccess")}
        </div>
      );
    }

    return map(jobContacts, (jobContact) => {
      const { contact, _id, clientAccess } = jobContact;

      if (!contact) {
        return null;
      }

      const fullName = `${contact.first_name} ${contact.last_name}`;

      return (
        <ClientItem
          onRemove={onRemove}
          onRevoke={onAccessRevoke}
          onGrant={onAccessGrant}
          fullName={fullName}
          email={contact.email}
          jobContactId={_id}
          contactId={contact._id}
          accessId={clientAccess?._id}
          key={contact._id}
          isReadonly={isReadonly}
        />
      );
    });
  };

  const append = React.useMemo(() => {
    return { onClick: openClientModal, icon: "add" };
  }, [openClientModal]);

  return (
    <div className="client-header-dropdown">
      {renderLoader()}
      {!isReadonly && (
        <div className="client-selector">
          <AutocompleteInput
            name="client"
            options={contactOptions}
            placeholder={t("placeholders.chooseClient")}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            append={append}
            onChange={handleClientChange}
            value={selectedClient}
          />
        </div>
      )}
      <div className="client-list">{renderClients()}</div>
    </div>
  );
};

export default ClientHeaderDropdown;
