import { gql } from "@apollo/client";

export const LIST_QUOTE_REQUESTS = gql`
  query {
    subcontractorListQuoteRequests {
      _id
      quoteNumber
      name
      note
      scope
      due_date
      business {
        _id
        name
      }
      attachments {
        _id
        url
        type
      }
      subcontractors {
        _id
        status
        supplier {
          _id
          business_name
          email
        }
        items {
          _id
          cost
          gst_inc
        }
      }
    }
  }
`;

export const GET_QUOTE_REQUEST = gql`
  query($quoteRequestId: ID!) {
    subcontractorGetQuoteRequestById(quoteRequestId: $quoteRequestId) {
      _id
      quoteNumber
      name
      note
      scope
      due_date

      business {
        _id
        name
      }

      attachments {
        _id
        name
        url
        upload_url
        type
        size
      }

      items {
        _id
        name
        UOM
        quantity
      }

      subcontractors {
        _id
        supplier {
          _id
          business_name
          email
        }
        status

        items {
          _id
          cost
          gst_inc
        }

        comments {
          _id
          comment
          sender {
            _id
            name
            email
            senderType
          }
          attachments {
            _id
            upload_url
            url
            type
            size
          }
        }
      }
    }
  }
`;
