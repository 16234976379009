import React from "react";
import Autocomplete, { Suggestion } from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import { map, isEmpty } from "lodash";
import TextInput, { TextInputProps } from "../text";
import { PLACE_SEARCH_OPTIONS } from "../../../../utils/place";
import "./styles.scss";

type PlacesAutocompleteProps = Omit<TextInputProps, "onChange"> & {
  onChange: (value: string) => void;
  onPlaceSelect: (value: string, placeId: string) => void;
};

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = (props) => {
  const {
    error,
    touched,
    placeholder,
    onChange,
    name,
    className,
    value,
    onPlaceSelect,
  } = props;

  const { t } = useTranslation();

  const autocompleteRef = React.useRef<any>(null);

  const handleChange = React.useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );

  const handleManual = React.useCallback(() => {
    autocompleteRef.current?.clearSuggestions();
  }, [autocompleteRef]);

  const handleSelect = React.useCallback(
    (value: string, placeId: string) => {
      onChange(value);
      onPlaceSelect && onPlaceSelect(value, placeId);
    },
    [onChange, onPlaceSelect]
  );

  const renderSuggestions = (
    suggestions: ReadonlyArray<Suggestion>,
    getSuggestionItemProps: any
  ) => {
    if (isEmpty(suggestions)) {
      return null;
    }

    return (
      <div className="menu-container autocomplete-menu place-dropdown-container">
        <div className="menu-list">
          {map(suggestions, (suggestion) => {
            return (
              <div
                {...getSuggestionItemProps(suggestion)}
                className="option option-content-height"
                key={suggestion.index}
              >
                {suggestion.description}
              </div>
            );
          })}
          <div
            className="option option-content-height"
            key="manual"
            onClick={handleManual}
          >
            {t("common.enterManually")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Autocomplete
      ref={autocompleteRef}
      searchOptions={PLACE_SEARCH_OPTIONS}
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <>
          <TextInput
            {...getInputProps()}
            name={name}
            autoComplete={`${+new Date()}`}
            error={error}
            touched={touched}
            className={className}
            placeholder={placeholder}
            value={value}
          >
            {renderSuggestions(suggestions, getSuggestionItemProps)}
          </TextInput>
        </>
      )}
    </Autocomplete>
  );
};

export default PlacesAutocomplete;
