import React from "react";
import AutocompleteInput, {
  AutocompleteInputOption,
} from "../generic-form/inputs/autocomplete";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { v1 as uuidv1 } from "uuid";

type MapperRowProps = {
  name: string;
  value: string;
  fieldLabel: string;
  options: AutocompleteInputOption[];
  placeholder?: string;
  sampleData: string;
  hideSample?: boolean;
} & Pick<FormikProps<unknown>, "setFieldValue" | "handleBlur">;

const MapperRow: React.FC<MapperRowProps> = (props) => {
  const { t } = useTranslation();

  const {
    name,
    value,
    setFieldValue,
    handleBlur,
    options,
    placeholder,
    fieldLabel,
    sampleData,
    hideSample = false,
  } = props;

  const handleChange = React.useCallback(
    (value) => {
      setFieldValue(name as string, value || null);
    },
    [name, setFieldValue]
  );

  const uniqueName = React.useMemo(() => uuidv1(), []); // WUN-140

  return (
    <tr className="table-row">
      <td className="field-text">{fieldLabel}</td>
      <td>
        <AutocompleteInput
          name={uniqueName}
          autoComplete="off"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          options={options}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          isClearable
        />
      </td>
      {!hideSample && <td className="field-text">{sampleData}</td>}
    </tr>
  );
};

export default MapperRow;
