import { gql } from "@apollo/client";
import { EMAIL_DATA } from "./fragments";

export const SEND_EMAIL = gql`
  mutation SendEmail($email: EmailInput, $channelId: ID) {
    email: sendEmail(email: $email, channelId: $channelId) {
      ...EmailData
    }
  }
  ${EMAIL_DATA}
`;

export const GET_REMOTE_ATTACHMENTS_URL = gql`
  mutation getRemoteAttachmentsUrl(
    $attachments: [MediaInput]!
    $documentIds: [ID]
  ) {
    getRemoteAttachmentsUrl(
      attachments: $attachments
      documentIds: $documentIds
    ) {
      _id
      attachments {
        _id
        name
        url
        upload_url
        type
        size
      }
    }
  }
`;
