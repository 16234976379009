import { LatLngLiteral } from "leaflet";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isEmpty, map, uniqBy } from "lodash";

import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import CalibrateScaleViewer from "../../../components/plans/calibration-scale-viewer/CalibrateScaleViewer";
import HorizontalLineCard from "../../../components/plans/horizontal-line-card";
import PlansCard from "../../../components/plans/plans-card";
import { useMutation } from "@apollo/client";
import { FileCalibrationResponse } from "../../../graphql/types/models/quote";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { CALIBRATE_FILE } from "../../../graphql/queries/quote/mutations";
import {
  CalibrationPayload,
  PaperType,
  SalesQuoteFile,
} from "../../../models/salesQuote";
import { useSalesQuoteQuery } from "../../../hooks/queries/useSalesQuoteQuery";
import EstimationLocationHeader from "../../header/estimation-location-header";
import ScaleSummaryPages from "../../../components/plans/scale-summary";
import { PAPER_WIDTH_VALUES, SCALE_OPTIONS } from "../../../utils/options";
import { useGetPageScale } from "../../../hooks/useGetPageScale";
import { SelectOption } from "../../../components/generic-form/inputs/creatable-select";

type CalibrateScaleContainerProps = DashboardContextValue;

const CalibrateScaleContainer: React.FC<CalibrateScaleContainerProps> = ({
  navigationContext,
}) => {
  const { salesQuote, salesQuotePlans } = useSalesQuoteQuery(
    navigationContext?.quote?._id
  );

  const [createFileCalibration] = useMutation<FileCalibrationResponse>(
    CALIBRATE_FILE
  );

  const history = useHistory();
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [pageBounds, setPageBounds] = React.useState<number[]>();
  const [lineValue, setLineValue] = React.useState("");
  const [scaleValue, setScaleValue] = React.useState("100");
  const [paperValue, setPaperValue] = React.useState(PaperType.A3.toString());
  const [isVarticalData, setIsVarticalData] = React.useState<boolean>(false);
  const [calibrationData, setCalibrationData] = React.useState<LatLngLiteral[]>(
    []
  );

  const { checkPageScaleValue } = useGetPageScale(pageBounds);

  const selectedFile = React.useMemo(() => {
    if (!salesQuotePlans) {
      return null;
    }
    const currentPage = salesQuotePlans[selectedPage - 1];
    const { scale, sheet } = currentPage?.calibration || {};

    if (scale) {
      const scaleValue = scale.split(":")[1];
      setScaleValue(scaleValue);
    } else {
      setScaleValue("100");
    }
    if (sheet) {
      setPaperValue(sheet);
    } else {
      setPaperValue(PaperType.A3.toString());
    }
    return currentPage;
  }, [salesQuotePlans, selectedPage]);

  const isCustomScale = React.useMemo(() => paperValue === "CUSTOM", [
    paperValue,
  ]);

  const customScaleOptions = React.useMemo(() => {
    if (!salesQuotePlans) {
      return [];
    }
    const existingOptions = SCALE_OPTIONS.map((options) => options.label);
    const newOptions: SelectOption[] = [];
    salesQuotePlans.forEach((page) => {
      const scaleValue = page.calibration?.scale;
      if (scaleValue) {
        if (!existingOptions.includes(scaleValue)) {
          newOptions.push({
            label: scaleValue,
            value: scaleValue.split(":")[1],
          });
        }
      }
    });

    return uniqBy(newOptions, "label");
  }, [salesQuotePlans]);

  const calibrateNumberDiff = (
    latlngs: LatLngLiteral[],
    number: number,
    param: "lat" | "lng"
  ) => {
    return number / Math.abs(latlngs[0][param] - latlngs[1][param]);
  };

  React.useEffect(() => {
    if (!salesQuote) {
      return;
    }

    if (selectedPage > salesQuotePlans.length) {
      history.push(`/quotes/${salesQuote._id}/take-off`);
    }
  }, [selectedPage, salesQuote, salesQuotePlans, history]);

  const handleLineSizeSubmit = React.useCallback(
    async (all: boolean) => {
      if (!salesQuote) {
        return;
      }

      if (
        (isCustomScale && isEmpty(calibrationData)) ||
        (!isCustomScale && isEmpty(scaleValue)) ||
        !pageBounds
      )
        return;

      let calibration_data: CalibrationPayload | null = null;
      let allFileCalibration: CalibrationPayload[] = [];

      let lng = 0;
      if (isCustomScale && !isVarticalData) {
        lng = calibrateNumberDiff(
          calibrationData,
          Number.parseFloat(lineValue),
          "lng"
        );
      } else if (isCustomScale && isVarticalData) {
        lng = calibrateNumberDiff(
          calibrationData,
          Number.parseFloat(lineValue),
          "lat"
        );
      } else {
        const pageWidth = Math.max(...pageBounds);
        let paperWidth = 0;
        switch (paperValue) {
          case PaperType.A0:
            paperWidth = PAPER_WIDTH_VALUES[PaperType.A0];
            break;
          case PaperType.B1:
            paperWidth = PAPER_WIDTH_VALUES[PaperType.B1];
            break;
          case PaperType.A1:
            paperWidth = PAPER_WIDTH_VALUES[PaperType.A1];
            break;
          case PaperType.A2:
            paperWidth = PAPER_WIDTH_VALUES[PaperType.A2];
            break;
          case PaperType.A3:
            paperWidth = PAPER_WIDTH_VALUES[PaperType.A3];
            break;
          case PaperType.A4:
            paperWidth = PAPER_WIDTH_VALUES[PaperType.A4];
            break;
        }
        const pxPerMm = paperWidth / pageWidth;
        lng = pxPerMm * parseInt(scaleValue);
      }

      const latLng: LatLngLiteral = {
        lat: lng,
        lng: lng,
      };

      const calibrationScale = isCustomScale ? "CUSTOM" : `1:${scaleValue}`;

      if (all) {
        allFileCalibration = map(salesQuotePlans, (file) => ({
          fileId: file._id,
          ...latLng,
          scale: calibrationScale,
          sheet: paperValue,
        }));
      } else {
        calibration_data = {
          fileId: salesQuotePlans[selectedPage - 1]?._id,
          ...latLng,
          scale: calibrationScale,
          sheet: paperValue,
        };
      }

      try {
        await createFileCalibration({
          variables: {
            salesQuoteId: salesQuote._id,
            calibrations: all ? allFileCalibration : [calibration_data],
          },
        });
      } catch (e) {
        console.log(e);
      }

      if (!all) {
        setLineValue("");
      }

      setSelectedPage(all ? salesQuotePlans.length + 1 : selectedPage + 1);
      setIsVarticalData(false);
    },
    [
      selectedPage,
      calibrationData,
      salesQuote,
      salesQuotePlans,
      createFileCalibration,
      lineValue,
      scaleValue,
      paperValue,
      isCustomScale,
      pageBounds,
      isVarticalData,
    ]
  );

  const handleCalibrationUpdate = React.useCallback(
    (data: LatLngLiteral[], isVerticalPolyline?: boolean) => {
      setIsVarticalData(isVerticalPolyline || false);
      setCalibrationData(data);
    },
    []
  );

  const handleOnPageSelect = React.useCallback((page: number) => {
    setSelectedPage(page);
  }, []);

  const handleOnSummaryPagesSelect = React.useCallback((pageNumber: number) => {
    setSelectedPage(pageNumber);
  }, []);

  const handleBoundsChange = React.useCallback((bounds: number[]) => {
    setPageBounds(bounds);
  }, []);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quotesSection.scale")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.SCALE} />
      <EstimationLocationHeader />
      <Row className="h-100">
        <Col lg={4} xs={12}>
          <HorizontalLineCard
            scale={scaleValue}
            line={lineValue}
            paper={paperValue}
            calibration={calibrationData}
            onHorizontalLineChange={setLineValue}
            onScaleChange={setScaleValue}
            onPaperChange={setPaperValue}
            onSubmit={handleLineSizeSubmit}
            customScaleOptions={customScaleOptions}
          />
          <ScaleSummaryPages
            files={salesQuotePlans}
            checkScaleValue={checkPageScaleValue}
            selectPage={handleOnSummaryPagesSelect}
            selectedPage={selectedFile}
          />
        </Col>
        <Col lg={8} xs={12} className="h-100">
          <PlansCard
            files={salesQuotePlans}
            selectedPage={selectedPage}
            onPageChange={handleOnPageSelect}
          >
            {!!selectedFile && (
              <CalibrateScaleViewer
                planFile={selectedFile}
                onCalibrationUpdate={handleCalibrationUpdate}
                onBoundsChange={handleBoundsChange}
                canDraw={isCustomScale}
              />
            )}
          </PlansCard>
        </Col>
      </Row>
    </Container>
  );
};

export default withDashboardContext(CalibrateScaleContainer);
