import React from "react";
import {
  UpdateTextInputFn,
  UpdateToggleFn,
} from "../components/generic-form/GenericFormBody";
import { calcGSTEx, calcWithGST, GST_PERCENT } from "../utils/calculations";

export function useCostChangeGST(defaultValue?: boolean) {
  const [costsHaveGST, setCostsHaveGST] = React.useState(false);
  const costHaveGSTRef = React.useRef(false); // workaround for state refresh issue

  const handleCostChange = React.useCallback(
    (fieldKey: string): UpdateTextInputFn => (
      fieldValue,
      formikProps,
      rowIndex
    ) => {
      const arrayKey = !formikProps.values?.costItems ? "items" : "costItems";
      if (fieldKey === "cost") {
        if (costHaveGSTRef.current) return; // workaround for state refresh issue

        formikProps.setFieldValue(
          `${arrayKey}[${rowIndex}].cost_inc`,
          calcWithGST(Number(fieldValue) || 0, GST_PERCENT)
        );
      } else {
        formikProps.setFieldValue(
          `${arrayKey}[${rowIndex}].cost`,
          calcGSTEx(Number(fieldValue) || 0, GST_PERCENT)
        );
      }
    },
    [costsHaveGST, costHaveGSTRef]
  );

  const handleToggleGSTFree: UpdateToggleFn = React.useCallback(
    (value, formikProps) => {
      const arrayKey = !formikProps.values?.costItems ? "items" : "costItems";
      formikProps.values?.[arrayKey]?.forEach((_item: any, index: number) => {
        formikProps.setFieldValue(`${arrayKey}[${index}].gstFree`, value);
      });
    },
    []
  );

  const handleCostsHaveGST: UpdateToggleFn = React.useCallback(
    (value, formikProps) => {
      costHaveGSTRef.current = value;
      setCostsHaveGST(value);
      const arrayKey = !formikProps.values?.costItems ? "items" : "costItems";
      formikProps?.values?.[arrayKey]?.forEach((item: any, index: number) => {
        if (value) {
          formikProps.setFieldValue(
            `${arrayKey}[${index}].cost_inc`,
            calcWithGST(Number(item.cost) || 0, GST_PERCENT)
          );
          if (item.real_cost) {
            formikProps.setFieldValue(
              `${arrayKey}[${index}].real_cost_inc`,
              calcWithGST(Number(item.real_cost) || 0, GST_PERCENT)
            );
          }
        } else {
          formikProps.setFieldValue(
            `${arrayKey}[${index}].cost`,
            calcGSTEx(Number(item.cost_inc) || 0, GST_PERCENT)
          );
          if (item.real_cost_inc) {
            formikProps.setFieldValue(
              `${arrayKey}[${index}].real_cost`,
              calcGSTEx(Number(item.real_cost_inc) || 0, GST_PERCENT)
            );
          }
        }
      });
    },
    [setCostsHaveGST, costsHaveGST]
  );

  React.useEffect(() => {
    if (defaultValue) {
      setCostsHaveGST(defaultValue);
      costHaveGSTRef.current = defaultValue;
    }
  }, [defaultValue]);

  const resetCostsHaveGST = React.useCallback(() => {
    setCostsHaveGST(defaultValue || false);
    costHaveGSTRef.current = defaultValue || false;
  }, [defaultValue]);

  return {
    costsHaveGST,
    handleCostChange,
    handleToggleGSTFree,
    handleCostsHaveGST,
    resetCostsHaveGST,
  };
}
