import React from "react";
import { map } from "lodash";
import classNames from "classnames";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import ClientQuoteRequestItem from "./ClientQuoteRequestItem";
import "./styles.scss";
import { ClientQuoteRequest } from "../../../graphql/types/models/quote-request";

export type ClientSalesQuoteRequest = {
  _id: string;
  name: string;
  number: number;
  quoteRequests: ClientQuoteRequest[];
};

type ClientQuoteRequestListProps = {
  title: string | React.ReactElement;
  className?: string;
  salesQuoteRequests?: ClientSalesQuoteRequest[];
  selectedQuoteRequest: ClientQuoteRequest | null;
  onSelect: (item: ClientQuoteRequest) => void;
};

const ClientQuoteRequestList: React.FC<ClientQuoteRequestListProps> = (
  props
) => {
  const {
    title,
    children,
    salesQuoteRequests,
    selectedQuoteRequest,
    className,
    onSelect,
  } = props;

  const renderItems = () => {
    return map(salesQuoteRequests, (salesQuoteRequest) => {
      return (
        <ClientQuoteRequestItem
          salesQuoteRequest={salesQuoteRequest}
          onSelect={onSelect}
          selectedQuoteRequest={selectedQuoteRequest}
          isOpen={true}
        />
      );
    });
  };

  const cardClasses = classNames(className, "quote-request-list");

  return (
    <DashboardCard className={cardClasses}>
      <DashboardCardHeader className="quote-request-header">
        {title}
      </DashboardCardHeader>

      <DashboardCardBody className="quote-request-body">
        {renderItems()}

        {children}
      </DashboardCardBody>
    </DashboardCard>
  );
};

export default ClientQuoteRequestList;
