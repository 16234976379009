import React from "react";
import Select from "react-select";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";

import "./styles.scss";
import HeaderSearchInput from "./HeaderSearchInput";
import HeaderSearchControl from "./HeaderSearchControl";
import HeaderSearchSingleValue from "./HeaderSearchSingleValue";
import HeaderSearchContainer from "./HeaderSearchContainer";
import HeaderSearchMenuOption from "./HeaderSearchMenuOption";
import HeaderSearchMenu from "./HeaderSearchMenu";
import HeaderSearchValueContainer from "./HeaderSearchValueContainer";
import HeaderSearchPlaceholder from "./HeaderSearchPlaceholder";
import { useTranslation } from "react-i18next";

export type HeaderSearchOption = {
  value: string;
  label: string;
  values?: string;
};

export type Option = {
  label: string;
  value: string;
  data: any;
};

type HeaderSearchProps = {
  placeholder?: string;
  onSelect?: (option: HeaderSearchOption | null) => void;
  onAutocomplete?: (input: string) => void;
  options: HeaderSearchOption[];
  hideNoOptions?: boolean;
  filterOptions?: (options: Option, input: string) => boolean;
};

const HeaderSearch: React.FC<HeaderSearchProps> = ({
  placeholder,
  onAutocomplete,
  onSelect,
  options,
  hideNoOptions,
  filterOptions,
}) => {
  const [
    selectedOption,
    setSelectedOption,
  ] = React.useState<HeaderSearchOption | null>(null);
  const [inputValue, setInputChange] = React.useState("");
  const { t } = useTranslation();

  const handleChange = React.useCallback((option: any, { action }) => {
    setSelectedOption(option);
    setInputChange(option.label);
  }, []);

  const handleInputValueChange = React.useCallback(
    (input: string, { action }) => {
      if (action === "input-change") setInputChange(input);
    },
    []
  );

  React.useEffect(() => {
    onAutocomplete && onAutocomplete(inputValue);
    if (isEmpty(inputValue)) setSelectedOption(null);
  }, [inputValue]);
  React.useEffect(() => onSelect && onSelect(selectedOption), [selectedOption]);

  return (
    <div
      className={classNames("header-search", {
        expanded: !!selectedOption || !isEmpty(inputValue),
      })}
    >
      <Select
        value={selectedOption}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputValueChange}
        options={options}
        placeholder={placeholder || t("common.searchPlaceholder")}
        noOptionsMessage={hideNoOptions ? () => null : undefined}
        components={{
          Input: HeaderSearchInput,
          Control: HeaderSearchControl,
          Menu: HeaderSearchMenu,
          Option: HeaderSearchMenuOption,
          SelectContainer: HeaderSearchContainer,
          SingleValue: HeaderSearchSingleValue,
          ValueContainer: HeaderSearchValueContainer,
          Placeholder: HeaderSearchPlaceholder,
          IndicatorSeparator: null,
          DropdownIndicator: null,
        }}
        filterOption={filterOptions}
      />
    </div>
  );
};

export default HeaderSearch;
