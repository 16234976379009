import React from "react";
import { indexOf, concat, slice } from "lodash";
import { DropdownItem } from "../dropdown";
import { SVGIconNames } from "../../icons/SVGIcon";

export type TableCardDataColumn<TData> = {
  title: string;
  isLink?: boolean;
  valueKey: keyof TData;
  linkTarget?: string;
  className?: string;
  linkKey?: keyof TData;
  sortable?: boolean;
  tooltip?: (row: TData) => string;
  onClick?: (
    row: TableCardDataRow<TData>,
    column: TableCardDataColumn<TData>,
    value: any,
    event?: React.MouseEvent<HTMLTableCellElement>
  ) => void;
  formatValue?: (
    row: TableCardDataRow<TData>,
    column: TableCardDataColumn<TData>,
    value: any
  ) => any;
  renderValue?: (
    row: TableCardDataRow<TData>,
    column: TableCardDataColumn<TData>,
    value: any
  ) => any;
};
export type TableCardDataRow<TData> = {
  cells: TData;
  subRows?: TableCardDataRow<TData>[];
  isTotal?: boolean;
  renderSubRow?: (
    row: TableCardDataRow<any>,
    index: number
  ) => React.ReactElement | null;
};
export type TableCardData<TData> = {
  columns: TableCardDataColumn<TData>[];
  rows: TableCardDataRow<TData>[];
};
export type TableCardAction = {
  title: string;
  icon?: string;
  svgIcon?: SVGIconNames;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
  isComponent?: boolean;
  component?: JSX.Element;
};

export type TableDropdownItem<TData> = {
  onClick: (row?: TData) => void;
  shouldRender?: (row: TData) => boolean;
} & DropdownItem;

export type TableRowActionData<TData> = {
  onClick?: (row: TData) => void;
  title?: string | ((row: TData) => string);
  counter?: number | ((row: TData) => number);
  text?: string;
  icon?: string;
  svgIcon?: SVGIconNames;
  dropdownId?: string;
  options?: TableDropdownItem<TData>[];
  className?: string;
  renderer?: React.ComponentClass<{
    row: TableCardDataRow<any>;
    action: TableRowActionData<TData>;
  }>;
  tooltip?: (row: TData) => string;
  shouldRender?: (row: TData) => void;
};

export type ExpandedFieldOptions<TField> = {
  expandedField: string;
  expandedSubFiled: string;
  dropdownId: string;
  getLabel?: (quantity: number) => string;
  onFieldClick?: (field: TField) => void;
  icon?: string;
};

export const updateSelectedRows = (rowId: string, selected: string[]) => {
  const selectedIndex = indexOf(selected, rowId);
  let newSelected: string[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, rowId);
  } else if (selectedIndex === 0) {
    newSelected = concat(newSelected, slice(selected, 1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = concat(newSelected, slice(selected, 0, -1));
  } else if (selectedIndex > 0) {
    newSelected = concat(
      newSelected,
      slice(selected, 0, selectedIndex),
      slice(selected, selectedIndex + 1)
    );
  }

  return newSelected;
};
