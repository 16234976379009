import { TFunction } from "i18next";
import { map } from "lodash";
import {
  AppendDynamicPropsFn,
  AppendOnClickFn,
  GenericFormFields,
} from "../../generic-form/GenericFormBody";
import { CreateTaskPayload, ScheduleTemplate } from "../../../models/job";

export const importScheduleFromTemplateFields = (
  t: TFunction,
  scheduleTemplates: ScheduleTemplate[],
  getTemplateAppendProps: AppendDynamicPropsFn,
  onSelectTemplate: (templateId: string | string[]) => void,
  onDeleteTemplate: AppendOnClickFn
): GenericFormFields<CreateTaskPayload> => {
  const scheduleTemplate = map(scheduleTemplates, (template) => ({
    value: template._id,
    label: template.name,
  }));
  return {
    scheduleTemplateId: {
      type: "default-select",
      label: t("schedule.scheduleTemplate"),
      placeholder: t("schedule.select"),
      valueKey: "scheduleTemplateId",
      selectProps: {
        options: scheduleTemplate,
        onValueChange: onSelectTemplate,
      },
      append: {
        onClick: onDeleteTemplate,
        dynamicProps: getTemplateAppendProps,
      },
    },
    date: {
      type: "date-picker",
      label: t("schedule.startDate"),
      placeholder: t("placeholders.startDate"),
      valueKey: "date",
      inputProps: {
        required: true,
      },
    },
  };
};
