import { gql } from "@apollo/client";

export const SIGNATURE_FRAGMENT = gql`
  fragment SignatureFragment on DigitalSignature {
    _id
    name
    date
    signedByType
    file {
      _id
      name
      url
      upload_url
      type
      size
    }
  }
`;
