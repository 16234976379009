import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Icon from "../../../components/icons/Icon";
import { getTemplatesSections } from "./utils";
import "./styles.scss";

const TemplatesList: React.FC = () => {
  const { t } = useTranslation();

  const sections = getTemplatesSections(t);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.templatesSection.templates")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.TEMPLATES}
      />
      <Row className="templates-sections">
        <Col className="flex-column">
          {sections.map((section) => {
            return (
              <div className="templates-section">
                <h2 className="templates-section-title">
                  {section.sectionName}
                </h2>
                <div className="templates-section-items">
                  {section.sectionItems.map((item) => {
                    return (
                      <Link to={item.url} className="templates-section-item">
                        <Icon
                          outlined
                          className="templates-section-icon"
                          name="auto_stories"
                        />
                        <h2 className="templates-section-name">{item.title}</h2>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};
export default TemplatesList;
