import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { useSpecificationsTemplate } from "../../../hooks/useSpecificationsTemplate";
import CreateEntityModal from "../../modals/create-entity";
import saveSpecAsTemplateSchema from "./SaveAsTemplate.schema";
import { saveSpecificationsAsTemplateFields } from "./utils";

type SaveSpecificationsAsTemplateProps = {
  salesQuoteId?: string;
};

const SaveSpecificationsAsTemplateModal: React.FC<SaveSpecificationsAsTemplateProps> = (
  { salesQuoteId },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<{ name: string }>
  >({});

  const {
    specTemplates,
    saveSpecAsTemplate,
    createUpdateSpecTemplate,
  } = useSpecificationsTemplate({
    onSave: hide,
  });

  React.useEffect(() => {
    if (!specTemplates) return;
    setFormFields(saveSpecificationsAsTemplateFields(t));
  }, [specTemplates]);

  const handleSubmit = React.useCallback(
    (values: { name: string }) => {
      if (!salesQuoteId)
        return createUpdateSpecTemplate({
          variables: {
            template: {
              name: values.name,
            },
          },
        });
      return saveSpecAsTemplate({
        variables: {
          name: values.name,
          salesQuoteId,
        },
      });
    },
    [createUpdateSpecTemplate, salesQuoteId, saveSpecAsTemplate]
  );

  const validationSchema = React.useMemo(
    () => saveSpecAsTemplateSchema(t, specTemplates),
    [t, specTemplates]
  );

  if (!specTemplates) return null;

  return (
    <CreateEntityModal
      validationSchema={validationSchema}
      className="import-template"
      title={t("specifications.saveAsTemplate")}
      show={shouldShow}
      data={{
        name: "",
      }}
      submitText={t("common.save")}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
    />
  );
};

export default forwardRef(SaveSpecificationsAsTemplateModal);
