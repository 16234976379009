import classNames from "classnames";
import { FormikProps } from "formik";
import { create, isArray, map, pick, get } from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RawDraftContentState } from "draft-js";

import FormField from "./form-field";
import AutocompleteInput, {
  AutocompleteInputProps,
} from "./inputs/autocomplete";
import ButtonSelectInput, {
  ButtonSelectInputProps,
} from "./inputs/button-select";
import ColourPicker, { ColourPickerProps } from "./inputs/colour-picker";
import TextInput from "./inputs/text";

import TableForm from "./table-form";
import SelectInput, { SelectInputProps } from "./inputs/select";
import DatePickerInput, { DatePickerProps } from "./inputs/date-picker";
import CreatableSelectInput, {
  CreatableSelectProps,
} from "./inputs/creatable-select";
import PlacesAutocomplete from "./inputs/place-autocomplete";
import RichEditor, { RichEditorProps } from "./inputs/rich-editor";
import SliderInput, { SliderInputProps } from "./inputs/slider";
import ToggleInput, { ToggleInputProps } from "./inputs/toggle";
import FileInput, { FileInputProps } from "../uploaders/file-input";
import ImageUploader, { ImageUploaderProps } from "../uploaders/image-uploader";
import GroupTitle from "./inputs/group-title";
import DescriptionBlock from "./inputs/description-block";
import "./styles.scss";

export type GenericFormFieldType =
  | "text"
  | "select"
  | "checkbox"
  | "toggle"
  | "colour-picker"
  | "button-select"
  | "default-select"
  | "date-picker"
  | "creatable-select"
  | "place-autocomplete"
  | "rich-editor"
  | "slider"
  | "file-input"
  | "select-autocomplete"
  | "text-autocomplete"
  | "image-uploader"
  | "group-title"
  | "description-block";

export type FieldRenderVariants = "table";

export type DynamicProps = {
  disabled?: boolean;
};

export type AppendDynamicProps = {
  icon?: string;
  className?: string;
  isHidden?: boolean;
  tooltip?: string;
};

export type FormikPropGetSetValues = Pick<
  FormikProps<any>,
  "values" | "setFieldValue"
>;
export type FormikPropSetValues = Pick<FormikProps<any>, "setFieldValue">;
export type DynamicPropsFn = (
  formikProps?: FormikPropGetSetValues,
  fieldName?: string,
  rowIndex?: number
) => DynamicProps;

export type AppendDynamicPropsFn = (
  formikProps?: FormikPropGetSetValues,
  fieldName?: string,
  rowIndex?: number
) => AppendDynamicProps;

export type AppendOnClickFn = (
  fieldName: string,
  fieldValue?: string,
  rowIndex?: number,
  formikProps?: FormikPropGetSetValues
) => void;

export type ActionWithSelectedProps = {
  action?: (formikProps?: FormikPropGetSetValues) => void;
  buttonTitle: string;
  fieldName: "costItems" | "items";
};

export type FieldAppend = {
  icon?: string;
  text?: string;
  onClick?: AppendOnClickFn;
  className?: string;
  formikProps?: FormikPropGetSetValues;
  fieldName?: string;
  rowIndex?: number;
  dynamicProps?: AppendDynamicPropsFn;
};

export type UpdateRowFn = (
  fieldValue: string,
  rowIndex: number,
  formikProps: FormikPropGetSetValues,
  sourceKey?: string
) => void;
export type DeleteRowFn = (
  formikProps: FormikPropGetSetValues,
  name: string,
  rowIndex: number
) => void;
export type DisableFieldFn = (rowValues: any, rowIndex: number) => boolean;
// export type DisableRowDeletingFn = (rowValues: any) => boolean;
export type DisableRowEditingFn = (rowValues: any) => boolean;
export type DisableRowDeletingFn = (
  formikProps: FormikPropGetSetValues,
  rowIndex: number
) => boolean;
export type EnableRowCopyingFn = (
  formikProps: FormikPropGetSetValues,
  rowIndex: number
) => void;
export type UpdateTextInputFn = (
  value: string,
  formikProps: FormikPropGetSetValues,
  rowIndex?: number
) => void;
export type UpdateInputFn = (
  value: string,
  formikProps: FormikPropGetSetValues
) => void;
export type UpdateToggleFn = (
  value: boolean,
  formikProps: FormikPropGetSetValues
) => void;
export type RenderSecondRowFn = (
  formikProps: Omit<FormikProps<any>, "handleSubmit" | "handleReset">,
  name: string,
  rowIndex: number
) => React.ReactElement | null;

export type RenderDynamicRowCellFn = (
  name: string,
  rowIndex: number,
  formValues: any
) => React.ReactElement | null;

export type GenericFormField<TData, TValue> = {
  type: GenericFormFieldType;
  updateTableRow?: UpdateRowFn;
  disableRowField?: DisableFieldFn;
  showError?: boolean;
  warningMessage?: string;
  //TODO fix nested field keys
  valueKey?: string;
  label?: string;
  className?: string;
  hint?: string;
  placeholder?: string;
  multiple?: boolean;
  append?: FieldAppend;
  touched?: boolean;
  checked?: boolean;
  width?: string;
  maxWidth?: string;
  formColumnClass?: string;
  disabled?: boolean;
  controlType?: React.ElementType;
  disallowNegativeNumber?: boolean;
  autocompleteProps?: Partial<AutocompleteInputProps>;
  buttonSelectProps?: Partial<ButtonSelectInputProps>;
  descriptionBlockProps?: {
    element: JSX.Element;
  };
  selectProps?: Partial<SelectInputProps>;
  sliderProps?: Partial<SliderInputProps>;
  toggleProps?: Partial<ToggleInputProps> & {
    onChange?: UpdateToggleFn;
  };
  creatableProps?: Partial<CreatableSelectProps>;
  richEditorProps?: Partial<RichEditorProps>;
  textInputProps?: {
    onValueChange?: UpdateTextInputFn;
  };
  datePickerProps?: Partial<DatePickerProps> & {
    onValueChange?: UpdateInputFn;
  };
  fileInputProps?: Partial<FileInputProps>;
  uploadImageProps?: Partial<ImageUploaderProps>;
  colourPickerProps?: Partial<ColourPickerProps>;
  inputProps?: Partial<
    React.InputHTMLAttributes<any> & React.TextareaHTMLAttributes<any>
  > & {
    debounceChangeEvent?: boolean;
  };
  formField?: React.ElementType<any>;
  formFieldProps?: any;
  afterDynamicFields?: boolean;
  secondRow?: boolean;
  index?: number;
  dynamicProps?: DynamicPropsFn;
};

export type GenericFormTableDynamicField = {
  title: string;
  formatValue: (rowValues: any, formValues: any) => string;
  onClick?: (rowValues: any, formValues: any) => void;
  maxWidth?: string;
  index?: number;
  render?: RenderDynamicRowCellFn;
};

export type GenericFormTableToggleControl = {
  name: string;
  label: string;
  value?: boolean;
  onChange?: UpdateToggleFn;
};

export type GenericFormTableInputControl = {
  name: string;
  label: string;
  type?: string;
  placeholder?: string;
  value?: boolean;
  onChange?: UpdateTextInputFn;
};

export type GenericFormFieldsUnion =
  | GenericFormTable<any, any>
  | GenericFormField<any, any>
  | GenericFormField<any, any>[];

export type GenericFormTable<TData, TValue> = {
  row: GenericFormField<TData, any>[];
  rowGenerator?: () => void;
  disableRowDeleting?: DisableRowDeletingFn;
  enableRowCopying?: EnableRowCopyingFn;
  disableRowEditing?: DisableRowEditingFn;
  tableTitle?: string;
  isCompact?: boolean;
  isIndexed?: boolean;
  isDisabled?: boolean;
  isDraggable?: boolean;
  isSelectable?: boolean;
  actionWithSelected?: ActionWithSelectedProps;
  withoutTitle?: boolean;
  isTwoRow?: boolean;
  rightActionButton?: () => JSX.Element;
  toggles?: GenericFormTableToggleControl[];
  inputs?: GenericFormTableInputControl[];
  dynamicFields?: GenericFormTableDynamicField[];
  renderSecondRow?: RenderSecondRowFn;
  deleteTableRow?: DeleteRowFn;
  formColumnClass?: string;
};

export type GenericFormFields<TData> = {
  [name: string]:
    | GenericFormTable<TData, any>
    | GenericFormField<TData, any>
    | GenericFormField<TData, any>[];
};

type GenericFormBodyProps<TData> = Omit<
  FormikProps<TData>,
  "handleSubmit" | "handleReset"
> & {
  fields: GenericFormFields<TData>;
};

const DEFAULT_COLUMN_SIZE = 12; // Column size for single input in column

type RenderFieldProps = Omit<
  FormikProps<any>,
  "handleSubmit" | "handleReset"
> & {
  field: GenericFormField<any, any>;
  columnSize?: number;
  variant?: FieldRenderVariants;
  rowIndex?: number;
};

export const RenderField: React.FC<RenderFieldProps> = ({
  field,
  columnSize,
  errors,
  touched,
  rowIndex,
  handleBlur,
  handleChange,
  values,
  setFieldValue,
  setFieldTouched,
  variant,
}) => {
  const fieldKey = field.valueKey as string;
  const FormFieldComponent = field.formField || FormField;
  let InputComponent: React.ElementType = TextInput;
  let formFieldProps = {
    name: field.valueKey,
    label: field.label,
    error: get(errors, fieldKey),
    hint: field.hint,
    touched: get(touched, fieldKey),
    warningMessage: field.warningMessage,
  };
  let inputComponentProps = create(pick(formFieldProps, ["name", "error"]), {
    name: field.valueKey,
    value: get(values, fieldKey),
    label: field.label,
    disabled: field.disabled,
    showError: field.showError,
    className: classNames(
      "form-input",
      field.className,
      field.inputProps?.className
    ),
    error: get(errors, fieldKey),
    touched: field?.touched || get(touched, fieldKey),
    type: field.inputProps?.type,
    required: field.inputProps?.required,
    readOnly: field.inputProps?.readOnly,
    placeholder: field.placeholder,
    onChange: handleChange,
    onBlur: handleBlur,
    append: field.append
      ? {
          ...field.append,
          formikProps: {
            values,
            setFieldValue,
          },
        }
      : field.append,
    width: field.width,
    maxWidth: field.maxWidth,
    controlType: field.controlType,
    rows: field.inputProps?.rows,
    rowIndex: rowIndex,
    disallowNegativeNumber: field.disallowNegativeNumber,
    debounceChangeEvent: field.inputProps?.debounceChangeEvent,
  });

  const prepareNumericValue = (value: number) => {
    if (field.inputProps?.type === "number") {
      if (field.disallowNegativeNumber) {
        return Math.abs(value) || value;
      }
      return Number(value) || value;
    }

    return value;
  };

  const handleTextInputChange = React.useCallback(
    (e: React.ChangeEvent<any>) => {
      const value = prepareNumericValue(e.target.value);

      setFieldValue(field.valueKey as string, value);
      setFieldTouched(field.valueKey as string, true);
      const onValueChange = field.textInputProps?.onValueChange;
      onValueChange &&
        onValueChange(e.target.value, { setFieldValue, values }, rowIndex);
    },
    [handleChange, field, rowIndex]
  );

  const handleRichEditorChange = React.useCallback(
    (value: string, text: string, raw: RawDraftContentState) => {
      setFieldValue(field.valueKey as string, value);
      setFieldTouched(field.valueKey as string, true);
      if (field.richEditorProps?.textKey) {
        setFieldValue(field.richEditorProps.textKey, text);
      }
      if (field.richEditorProps?.rawKey) {
        setFieldValue(field.richEditorProps.rawKey, raw);
      }
    },
    [field]
  );

  const handleSliderChange = React.useCallback(
    (value: number) => setFieldValue(field.valueKey as string, value),
    [field]
  );

  const handleToggleChange = React.useCallback(
    (value: boolean) => {
      setFieldValue(field.valueKey as string, value);
      field.toggleProps?.onChange?.(value, { values, setFieldValue });
    },
    [field]
  );

  const handleAutocompleteChange = React.useCallback(
    (value: string) => setFieldValue(field.valueKey as string, value),
    [field]
  );

  const handlePlaceAutocompleteChange = React.useCallback(
    (value: string) => setFieldValue(field.valueKey as string, value),
    [field]
  );

  const handlePlaceSelect = React.useCallback(
    (value: string, placeId: string) => {
      const onValueChange = field.textInputProps?.onValueChange;
      onValueChange && onValueChange(placeId, { setFieldValue, values });
    },
    [field]
  );

  const handleButtonSelectChange = React.useCallback(
    (value: string) => {
      setFieldValue(field.valueKey as string, value);
      const onChange = field.buttonSelectProps?.onChange;
      onChange && onChange(value);
    },
    [field]
  );

  const handleColourPickerChange = React.useCallback(
    (value: string) => setFieldValue(field.valueKey as string, value),
    [field]
  );

  const handleSelectChange = React.useCallback(
    (value: string) => {
      setFieldValue(field.valueKey as string, value);
      setFieldTouched(field.valueKey as string, true);
      field.textInputProps?.onValueChange?.(value, { setFieldValue, values });
    },
    [field]
  );

  const handleDateChange = React.useCallback(
    (date: string) => {
      setFieldValue(field.valueKey as string, date);
      setFieldTouched(field.valueKey as string, true);
      field.datePickerProps?.onValueChange?.(date, { values, setFieldValue });
    },
    [field, values]
  );

  const handleSelectAutocomplete = React.useCallback(
    (value: string) => {
      setFieldValue(field.valueKey as string, value);
      field.textInputProps?.onValueChange?.(value, { setFieldValue, values });
    },
    [field]
  );

  const handleTouch = React.useCallback(() => {
    setFieldTouched(field.valueKey as string, true);
  }, [field]);

  switch (field.type) {
    case "date-picker": {
      InputComponent = DatePickerInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.datePickerProps,
        {
          onChange: handleDateChange,
        }
      );
      break;
    }
    case "select":
      InputComponent = AutocompleteInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.autocompleteProps,
        {
          onChange: handleAutocompleteChange,
        }
      );
      break;
    case "button-select":
      InputComponent = ButtonSelectInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.buttonSelectProps,
        {
          onChange: handleButtonSelectChange,
        }
      );
      break;
    case "colour-picker":
      InputComponent = ColourPicker;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.colourPickerProps,
        {
          onChange: handleColourPickerChange,
        }
      );
      break;
    case "default-select":
      InputComponent = SelectInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.selectProps,
        {
          onChange: handleSelectChange,
        }
      );
      break;
    case "creatable-select":
      InputComponent = CreatableSelectInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        {
          ...field.creatableProps,
          ...(field.creatableProps?.onMenuOpen && {
            onMenuOpen: () =>
              field.creatableProps?.onMenuOpen?.(
                {
                  values,
                  setFieldValue,
                },
                rowIndex
              ),
          }),
        },
        {
          defaultValue: inputComponentProps.value,
          onChange: handleSelectChange,
        }
      );
      break;
    case "slider":
      InputComponent = SliderInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.sliderProps,
        {
          onChange: handleSliderChange,
        }
      );
      break;
    case "description-block":
      InputComponent = DescriptionBlock;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.descriptionBlockProps
      );
      break;
    case "group-title":
      InputComponent = GroupTitle;
      inputComponentProps = Object.assign({}, inputComponentProps);
      break;
    case "place-autocomplete":
      InputComponent = PlacesAutocomplete;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.creatableProps,
        {
          onChange: handlePlaceAutocompleteChange,
          onPlaceSelect: handlePlaceSelect,
        }
      );
      break;
    case "rich-editor":
      InputComponent = RichEditor;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.richEditorProps,
        {
          onChange: handleRichEditorChange,
          rawValue: field.richEditorProps?.rawKey
            ? get(values, field.richEditorProps?.rawKey, "")
            : undefined,
        }
      );
      break;
    case "file-input":
      InputComponent = FileInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.fileInputProps
      );
      break;
    case "select-autocomplete":
      InputComponent = AutocompleteInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.autocompleteProps,
        field.textInputProps,
        {
          onValueChange: handleSelectAutocomplete,
          onTouch: handleTouch,
        }
      );
      break;
    // @deprecated
    case "text-autocomplete":
      InputComponent = TextInput;
      inputComponentProps = Object.assign({}, inputComponentProps, {
        onValueChange: handleSelectAutocomplete,
      });
      break;
    case "image-uploader":
      InputComponent = ImageUploader;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.uploadImageProps,
        {
          onUpload: (file: File) => {
            if (field.valueKey) {
              setFieldValue(field.valueKey, { file });
            }
          },
          valueKey: inputComponentProps.value,
        }
      );
      break;

    case "toggle":
      InputComponent = ToggleInput;
      inputComponentProps = Object.assign(
        {},
        inputComponentProps,
        field.toggleProps,
        {
          onChange: handleToggleChange,
        }
      );
      break;

    default:
      inputComponentProps = Object.assign({}, inputComponentProps, {
        value: prepareNumericValue(inputComponentProps.value),
        onChange: handleTextInputChange,
      });
      InputComponent = TextInput;
      break;
  }

  const columnClass = classNames("form-column", field?.formColumnClass);

  if (variant === "table") {
    return <InputComponent {...inputComponentProps} />;
  }

  return (
    <Col
      lg={columnSize}
      xs={12}
      className={columnClass}
      key={field.valueKey as string}
    >
      <FormFieldComponent
        name={formFieldProps.name as string}
        label={formFieldProps.label}
        error={formFieldProps.error as string}
        warningMessage={formFieldProps.warningMessage as string}
        hint={formFieldProps.hint}
        touched={formFieldProps.touched}
        {...field.formFieldProps}
      >
        <InputComponent {...inputComponentProps} />
      </FormFieldComponent>
    </Col>
  );
};

export const renderField = (
  props: FormikProps<any>,
  field: GenericFormFieldsUnion,
  columnSize: number
) => {
  const genericField = field as GenericFormField<any, any>;
  if (!genericField) return null;
  return (
    <RenderField
      key={genericField.valueKey}
      columnSize={columnSize}
      field={genericField}
      {...props}
    />
  );
};

export const renderFields = (
  formikProps: Omit<FormikProps<any>, "handleSubmit" | "handleReset">,
  fields: GenericFormField<any, any>[]
) => {
  const columns = Math.floor(12 / Object.keys(fields).length);
  return (
    <React.Fragment>
      {map(fields, (field: GenericFormField<any, any>, index: number) => (
        <RenderField
          key={index}
          field={field}
          columnSize={columns}
          {...formikProps}
        />
      ))}
    </React.Fragment>
  );
};

export const renderTableForm = (
  formikProps: FormikProps<any>,
  table: GenericFormTable<any, any>,
  name: string
) => {
  return (
    <TableForm
      showCounterTitle={true}
      dynamicFields={table.dynamicFields}
      tableTitle={table.tableTitle}
      isCompact={table.isCompact}
      isIndexed={table.isIndexed}
      isDisabled={table.isDisabled}
      isDraggable={table.isDraggable}
      isSelectable={table.isSelectable}
      actionWithSelected={table.actionWithSelected}
      isTwoRow={table.isTwoRow}
      toggles={table.toggles}
      rightActionButton={table.rightActionButton}
      inputs={table.inputs}
      renderSecondRow={table.renderSecondRow}
      deleteTableRow={table.deleteTableRow}
      row={table.row}
      {...formikProps}
      name={name}
      rowGenerator={table.rowGenerator}
      disableRowDeleting={table.disableRowDeleting}
      enableRowCopying={table.enableRowCopying}
      disableRowEditing={table.disableRowEditing}
    />
  );
};

const GenericFormBody: React.FC<GenericFormBodyProps<any>> = ({
  fields,
  ...formikProps
}) => {
  const renderRow = (
    field:
      | GenericFormTable<any, any>
      | GenericFormField<any, any>
      | GenericFormField<any, any>[],
    key: string
  ) => {
    if (isArray(field)) {
      return renderFields(formikProps, field as GenericFormField<any, any>[]);
    }

    const tableFormFields = field as GenericFormTable<any, any>;

    if (isArray(tableFormFields.row)) {
      return (
        <TableForm
          dynamicFields={tableFormFields.dynamicFields}
          tableTitle={tableFormFields.tableTitle}
          isCompact={tableFormFields.isCompact}
          isDraggable={tableFormFields.isDraggable}
          isTwoRow={tableFormFields.isTwoRow}
          isSelectable={tableFormFields.isSelectable}
          actionWithSelected={tableFormFields.actionWithSelected}
          rightActionButton={tableFormFields.rightActionButton}
          toggles={tableFormFields.toggles}
          withoutTitle={tableFormFields.withoutTitle}
          disableRowEditing={tableFormFields.disableRowEditing}
          enableRowCopying={tableFormFields.enableRowCopying}
          disableRowDeleting={tableFormFields.disableRowDeleting}
          row={tableFormFields.row}
          {...formikProps}
          name={key}
          rowGenerator={tableFormFields.rowGenerator}
        />
      );
    } else {
      return (
        <RenderField
          field={field as GenericFormField<any, any>}
          {...formikProps}
        />
      );
    }
  };

  return (
    <Container className="generic-form-body" fluid>
      {map(
        fields,
        (
          field: GenericFormField<any, any> | GenericFormField<any, any>[],
          key: string
        ) => (
          <Row key={key}>{renderRow(field, key)}</Row>
        )
      )}
    </Container>
  );
};

export default GenericFormBody;
