import {
  ApolloCache,
  MutationUpdaterFunction,
  useMutation,
} from "@apollo/client";
import { UpdateBusinessResponse } from "../../graphql/types/models/business";
import { UPDATE_BUSINESS } from "../../graphql/queries/business/mutations";
import { notify } from "../../components/notification";
import { useTranslation } from "react-i18next";

export function useBusinessMutation(
  onCompleted?: () => void,
  update?: MutationUpdaterFunction<
    UpdateBusinessResponse,
    any,
    any,
    ApolloCache<any>
  >
) {
  const { t } = useTranslation();
  const [updateBusiness] = useMutation<UpdateBusinessResponse>(
    UPDATE_BUSINESS,
    {
      onCompleted: () => {
        notify({
          content: t("company.success.updateDetails"),
          title: t("company.updateDetails"),
        });
        onCompleted?.();
      },
      onError: () => {
        notify({
          error: true,
          content: t("company.error.updateDetails"),
          title: t("company.updateDetails"),
        });
      },
      update,
    }
  );

  return { updateBusiness };
}
