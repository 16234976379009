import { TFunction } from "i18next";
import { compact, map } from "lodash";
import {
  AppendDynamicPropsFn,
  AppendOnClickFn,
  FormikPropGetSetValues,
  GenericFormFields,
  UpdateInputFn,
  UpdateRowFn,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { CreateUpdateTimesheetPayload } from "../../../models/timesheet";
import { JobListItem } from "../../../models/job";
import { getBreakDurationOptions } from "../../../utils/options";
import { GenericRecord } from "../../../utils/types/options";
import { TeamMember } from "../../../models/team-member";

export const createTimesheetField = (
  t: TFunction,
  isEdit: boolean,
  onStartDateChange: UpdateInputFn,
  costingCategoryList: GenericRecord[] | undefined,
  openAllocateCosting: AppendOnClickFn,
  handleCategorySelect: UpdateRowFn,
  getAllocateProps: AppendDynamicPropsFn,
  handleJobChange: UpdateTextInputFn,
  team: TeamMember[],
  jobs?: JobListItem[],
  jobId?: string,
  jobCostingLoading?: boolean
): GenericFormFields<CreateUpdateTimesheetPayload> => {
  const teamOptions = map(team, (member) => ({
    value: member._id,
    label: [member.first_name, member.last_name].join(" "),
  }));
  return {
    userId: {
      type: "select-autocomplete",
      label: t("timesheets.teamMember"),
      valueKey: "userId",
      autocompleteProps: {
        options: teamOptions,
      },
    },
    job: compact([
      !isEdit
        ? {
            type: "select-autocomplete",
            label: t("timesheets.job"),
            valueKey: "job",
            autocompleteProps: {
              options: map(jobs, (job) => ({
                value: job._id,
                label: `${job.name}`,
              })),
            },
            textInputProps: {
              onValueChange: handleJobChange,
            },
          }
        : null,
      !isEdit && jobId
        ? {
            type: "default-select",
            label: t("timesheets.allocateToCosting"),
            placeholder: t("timesheets.allocateToCosting"),
            valueKey: "costingCategoryName",
            // autocompleteProps: {
            //   options: costingCategoryList,
            //   isClearable: true,
            // },
            selectProps: {
              options: costingCategoryList,
              isLoading: jobCostingLoading,
            },
            textInputProps: {
              onValueChange: (value, formikProps) =>
                handleCategorySelect(
                  value,
                  0,
                  formikProps as FormikPropGetSetValues
                ),
            },
            inputProps: {
              type: "text",
              required: true,
            },
            append: {
              onClick: openAllocateCosting,
              dynamicProps: getAllocateProps,
            },
          }
        : null,
    ]),
    task: {
      type: "text",
      label: t("timesheets.task"),
      placeholder: t("timesheets.task"),
      valueKey: "task",
      inputProps: {
        required: true,
      },
    },
    dates: [
      {
        type: "date-picker",
        label: t("timesheets.startDate"),
        placeholder: t("timesheets.startDate"),
        valueKey: "startDate",
        inputProps: {
          required: true,
        },
        datePickerProps: {
          showTimeSelect: true,
          calendarClassName: "datetime-picker",
          onValueChange: onStartDateChange,
        },
      },
      {
        type: "date-picker",
        label: t("timesheets.endDate"),
        placeholder: t("timesheets.endDate"),
        valueKey: "endDate",
        inputProps: {
          required: true,
        },
        datePickerProps: {
          showTimeSelect: true,
          calendarClassName: "datetime-picker",
        },
      },
    ],
    breakDuration: {
      type: "select-autocomplete",
      label: t("timesheets.breakDuration"),
      valueKey: "breakDuration",
      autocompleteProps: {
        options: getBreakDurationOptions(t),
      },
    },
    note: {
      type: "text",
      controlType: "textarea",
      label: t("timesheets.notes"),
      placeholder: t("timesheets.notes"),
      valueKey: "note",
      inputProps: {
        rows: 2,
      },
    },
  };
};
