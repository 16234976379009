import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GrantClientAccessResponse,
  RevokeClientAccessResponse,
} from "../graphql/types/models/client-access";
import {
  GRANT_CLIENT_ACCESS,
  REVOKE_CLIENT_ACCESS,
} from "../graphql/queries/client-access/mutations";
import {
  ClientAccessType,
  GrantAccessInput,
  RevokeAccessInput,
} from "../graphql/input/client-access";
import { ListJobContactsResponse } from "../graphql/models/job-contact";
import { ListJobContactsInput } from "../graphql/input/job-contacts";
import { LIST_JOB_CONTACTS } from "../graphql/job-contact/queries";
import { updateJobContactsList } from "../graphql/client-access/utils";
import { notify } from "../components/notification";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import ConfirmDialog from "../components/confirm-dialog";

export function useGrantJobContact(jobId: string) {
  const [selectedClient, setSelectedClient] = React.useState<string | null>(
    null
  );
  const [targetClientId, setTargetClientId] = React.useState<string | null>(
    null
  );
  const [targetAccessId, setAccessClientId] = React.useState<string | null>(
    null
  );
  const [showGrantDialog, setGrantDialogVisibility] = React.useState(false);
  const [showRevokeDialog, setRevokeDialogVisibility] = React.useState(false);

  const { t } = useTranslation();

  const {
    data: contacts,
    loading: jobContactsLoading,
    updateQuery: updateJobContacts,
  } = useQuery<ListJobContactsResponse, ListJobContactsInput>(
    LIST_JOB_CONTACTS,
    {
      variables: {
        jobId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [revokeAccess, { loading: accessRevoking }] = useMutation<
    RevokeClientAccessResponse,
    RevokeAccessInput
  >(REVOKE_CLIENT_ACCESS);
  const [grantAccess, { loading: accessGranting }] = useMutation<
    GrantClientAccessResponse,
    GrantAccessInput
  >(GRANT_CLIENT_ACCESS);

  const closeGrantDialog = React.useCallback(() => {
    setGrantDialogVisibility(false);
  }, []);

  const closeRevokeDialog = React.useCallback(() => {
    setRevokeDialogVisibility(false);
  }, []);

  const handleAccessGrant = React.useCallback(async (contactId: string) => {
    setSelectedClient(contactId);
  }, []);

  const grantAccessConfirm = React.useCallback((contactId: string) => {
    setTargetClientId(contactId);
    setGrantDialogVisibility(true);
  }, []);

  const revokeAccessConfirm = React.useCallback(
    (accessId: string, contactId: string) => {
      setAccessClientId(accessId);
      setTargetClientId(contactId);
      setRevokeDialogVisibility(true);
    },
    []
  );

  const handleGrantSubmit = React.useCallback(async () => {
    if (!targetClientId) {
      return;
    }

    try {
      const response = await grantAccess({
        variables: {
          accessType: ClientAccessType.JOB,
          accessId: jobId,
          contactId: targetClientId,
        },
      });

      const access = response.data?.grantClientAccess;

      if (access) {
        updateJobContacts(updateJobContactsList(targetClientId, access));
      }

      notify({
        title: t("clientAccess.grantAccess"),
        content: t("clientAccess.success.grantAccess"),
      });
    } catch (error) {
      const message = get(
        error,
        "message",
        t("clientAccess.error.grantAccess")
      );
      notify({
        error: true,
        title: t("clientAccess.error.failedToGrantAccess"),
        content: message,
      });
    }

    setGrantDialogVisibility(false);
    setTargetClientId(null);
  }, [targetClientId, grantAccess, jobId, t, updateJobContacts]);

  const handleAccessRevoke = React.useCallback(async () => {
    if (!targetAccessId || !targetClientId) {
      return;
    }

    try {
      const response = await revokeAccess({
        variables: {
          accessId: jobId,
          clientAccessId: targetAccessId,
          accessType: ClientAccessType.JOB,
        },
      });

      const access = response.data?.revokeClientAccess;

      if (access) {
        updateJobContacts(updateJobContactsList(targetClientId, null));
      }

      notify({
        title: t("clientAccess.revokeAccess"),
        content: t("clientAccess.success.revokeAccess"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("clientAccess.revokeAccess"),
        content: t("clientAccess.error.revokeAccess"),
      });
    }

    setRevokeDialogVisibility(false);
    setAccessClientId(null);
    setTargetClientId(null);
  }, [
    targetAccessId,
    targetClientId,
    revokeAccess,
    jobId,
    t,
    updateJobContacts,
  ]);

  const renderConfirmGrantDialogs = React.useCallback(() => {
    return (
      <>
        <ConfirmDialog
          disabled={accessGranting}
          title={t("clientAccess.grantAccess")}
          show={showGrantDialog}
          onClose={closeGrantDialog}
          onSubmit={handleGrantSubmit}
        >
          <span className="field-text">
            {t("clientAccess.grantAccessMessage")}
          </span>
        </ConfirmDialog>
        <ConfirmDialog
          disabled={accessRevoking}
          title={t("clientAccess.revokeAccess")}
          show={showRevokeDialog}
          onSubmit={handleAccessRevoke}
          onClose={closeRevokeDialog}
        >
          <span className="field-text">
            {t("clientAccess.revokeAccessMessage")}
          </span>
        </ConfirmDialog>
      </>
    );
  }, [
    accessGranting,
    accessRevoking,
    closeGrantDialog,
    closeRevokeDialog,
    handleAccessRevoke,
    handleGrantSubmit,
    showGrantDialog,
    showRevokeDialog,
    t,
  ]);

  return {
    renderConfirmGrantDialogs,
    selectedClient,
    setSelectedClient,
    contacts,
    jobContactsLoading,
    accessRevoking,
    accessGranting,
    revokeAccessConfirm,
    grantAccessConfirm,
    handleAccessGrant,
  };
}
