import { gql } from "@apollo/client";

export const JOB_FRAGMENT = gql`
  fragment JobFragment on Job {
    _id
    jobNumber
    name
    contractTotal
    contractTotalGST
    address
    city
    city_normalized
    state
    postcode
    startDate
    endDate
    markup
    type
    is_locked
    is_contract_locked
    is_non_contracted
    invoiceType
    invoiceToJobAddress
    excludeVariationFromClaimAmount
    retention {
      invoicePercentage
      totalPercentage
    }
    createdAt
    updatedAt
    customer {
      _id
      first_name
      last_name
      email
      phone
      phone_secondary
      is_invited
    }
    business {
      _id
      name
    }
    salesQuote {
      _id
      files {
        _id
        name
      }
      takeOffItems {
        _id
        name
        UOM
        quantity
        properties {
          color
        }
        shapes {
          name
          angle
          wallHeight
          materialWidth
          depth
          geometry {
            lat
            lng
          }
          type
          page
          leaflet_id
          properties {
            text
          }
        }
      }
    }
  }
`;
