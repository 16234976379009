import React, { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import createNoteSchema from "./NoteModal.schema";
import FormField from "../../generic-form/form-field";
import {
  CreateUpdateNotePayload,
  CreateUpdateNoteResponse,
  Note,
  NoteEntityType,
  NotePayload,
} from "../../../graphql/types/models/note";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { useAttachment } from "../../modals/send-modal/hooks/useAttachment";
import CreateEntityModal from "../../modals/create-entity";
import createNoteFields from "./utils";
import { CREATE_UPDATE_NOTE } from "../../../graphql/queries/note/mutations";
import { notify } from "../../notification";
import { getMediaInput } from "../../../utils/transform";
import { handleCreateUpdateNote } from "../../../graphql/queries/note/utils";
import { omit } from "lodash";

type NoteModalProps = {
  entityType: NoteEntityType;
  entityId: string;
};

export type NoteModalRef = {
  show: (note?: Note) => void;
};

const NoteModal: React.FC<NoteModalProps> = (props, ref) => {
  const { entityType, entityId } = props;
  const { t } = useTranslation();

  const { shouldShow, hide, show } = useModalDisplay(ref);

  const [editTarget, setEditTarget] = React.useState<Note | null>(null);

  useImperativeHandle(ref, () => ({
    show: (note?: Note) => {
      show();
      if (note) {
        setEditTarget(note);
      }
    },
  }));

  const [createUpdateNote] = useMutation<
    CreateUpdateNoteResponse,
    CreateUpdateNotePayload
  >(CREATE_UPDATE_NOTE, {
    update: handleCreateUpdateNote(entityType, entityId),
    onCompleted: () => {
      // hide();
      // notify({
      //   title: t("wbAdmin.extendTrial"),
      //   content: t("wbAdmin.success.extendTrial"),
      // });
    },
    onError: () => {
      notify({
        error: true,
        title: editTarget ? t("note.editNote") : t("note.createNote"),
        content: editTarget
          ? t("note.error.editNote")
          : t("note.error.createNote"),
      });
    },
  });

  const {
    attachments,
    openFilePicker,
    renderAttachments,
    renderDropzone,
    uploadAttachments,
    clearAttachments,
    setAttachments,
  } = useAttachment();

  React.useEffect(() => {
    if (editTarget?.attachments.length) {
      const files = editTarget?.attachments.map((file) => ({
        ...(omit(file, ["__typename"]) as File),
      }));
      setAttachments(files);
    }
    if (!shouldShow) {
      clearAttachments();
    }
  }, [shouldShow]);

  const handleSubmit = React.useCallback(
    async (values: NotePayload) => {
      const files = getMediaInput(attachments);
      const result = await createUpdateNote({
        variables: {
          entityId,
          entityType,
          note: {
            ...values,
            ...(editTarget && { _id: editTarget._id }),
            attachments: files,
          },
        },
      });
      const resultAttachments = result.data?.createUpdateNote?.attachments;
      const existingFiles = editTarget?.attachments.map((file) => file._id);
      const newFiles = resultAttachments?.filter(
        (file) => !existingFiles?.includes(file._id)
      );
      if (newFiles?.length) {
        await uploadAttachments(newFiles);
      }
      notify({
        title: editTarget ? t("note.editNote") : t("note.createNote"),
        content: editTarget
          ? t("note.success.editNote")
          : t("note.success.createNote"),
      });
      handleOnClose();
    },
    [
      attachments,
      createUpdateNote,
      entityId,
      entityType,
      editTarget,
      uploadAttachments,
    ]
  );

  const handleOnClose = React.useCallback(() => {
    hide();
    setEditTarget(null);
  }, []);

  const renderAttach = React.useCallback(() => {
    return (
      <Container fluid>
        <Row>
          <Col className="form-column" sm={12}>
            <FormField name="attachments" label={t("common.attachments")}>
              {renderAttachments()}
            </FormField>
            {renderDropzone()}
          </Col>
        </Row>
      </Container>
    );
  }, [openFilePicker, renderAttachments]);

  return (
    <CreateEntityModal
      validationSchema={createNoteSchema(t)}
      className="create-note-modal"
      title={editTarget ? t("note.editNote") : t("note.createNote")}
      show={shouldShow}
      data={{
        description: editTarget ? editTarget.description : "",
      }}
      onSubmit={handleSubmit}
      onClose={handleOnClose}
      fields={createNoteFields(t)}
      submitText={editTarget ? t("common.update") : t("common.save")}
      endRenderer={renderAttach()}
    />
  );
};

export default React.forwardRef(NoteModal);
