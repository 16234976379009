import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { pick } from "lodash";
import {
  CreateSalesQuotePayload,
  SalesQuote,
} from "../../../models/salesQuote";

import CreateEntityModal from "../../modals/create-entity";
import editQuoteMarkupSchema from "./EditQuoteMarkup.schema";
import { editQuoteMarkupFields } from "./utils";
import { QuoteCreateUpdateResponse } from "../../../graphql/types/models/quote";
import { CREATE_UPDATE_SALES_QUOTE } from "../../../graphql/queries/quote/mutations";
import { useModalDisplay } from "../../../hooks/useModalDisplay";

type QuoteMarkupModalProps = {
  salesQuote: SalesQuote;
  onSubmit?: () => void;
};

const QuoteMarkupModal: React.FC<QuoteMarkupModalProps> = (
  { salesQuote, onSubmit },
  ref
) => {
  const { t } = useTranslation();

  const { shouldShow, hide } = useModalDisplay(ref);

  const [createUpdateSalesQuote] = useMutation<QuoteCreateUpdateResponse>(
    CREATE_UPDATE_SALES_QUOTE,
    {
      onCompleted: () => {
        hide();
        onSubmit?.();
      },
    }
  );

  const handleSubmit = React.useCallback(
    (quote: Omit<CreateSalesQuotePayload, "templateId">) => {
      createUpdateSalesQuote({
        variables: {
          quote: {
            ...quote,
            markup:
              typeof quote.markup === "string"
                ? parseFloat(quote.markup)
                : quote.markup,
          },
        },
      });
    },
    [createUpdateSalesQuote]
  );

  return (
    <React.Fragment>
      <CreateEntityModal
        validationSchema={editQuoteMarkupSchema(t)}
        title={t("quotes.editMarkup")}
        show={shouldShow}
        data={{
          _id: salesQuote?._id,
          markup: salesQuote?.markup || 0,
          name: salesQuote?.name || "",
          contactId: salesQuote?.contact?._id || "",
        }}
        onSubmit={handleSubmit}
        onClose={hide}
        submitText={t("common.save")}
        fields={editQuoteMarkupFields(t)}
      />
    </React.Fragment>
  );
};

export default React.forwardRef(QuoteMarkupModal);
