import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import {
  CreateQuoteCategoryResponse,
  DeleteQuoteCategoryResponse,
  SalesQuoteCategoryListResponse,
  SaveSalesQuoteCategoryItems,
  UpdateSalesQuoteCostingCategoryStatusResponse,
} from "../../types/models/category";
import { filter, map, some, uniqueId } from "lodash";
import { LIST_SALES_QUOTE_CATEGORIES } from "./queries";
import { CostingCategoryListItem } from "../../../models/salesQuote";

const getQuoteCategories = (cache: ApolloCache<any>, salesQuoteId?: string) => {
  const response = cache.readQuery<SalesQuoteCategoryListResponse>({
    query: LIST_SALES_QUOTE_CATEGORIES,
    variables: { salesQuoteId },
  });

  return response?.listSalesQuoteCostingCategories;
};

const updateQuoteCategories = (
  cache: ApolloCache<any>,
  salesQuoteId: string,
  categories: CostingCategoryListItem[]
) => {
  cache.writeQuery<SalesQuoteCategoryListResponse>({
    query: LIST_SALES_QUOTE_CATEGORIES,
    variables: {
      salesQuoteId,
    },
    data: {
      listSalesQuoteCostingCategories: categories,
    },
  });
};

export const handleCreateCategory = (
  salesQuoteId?: string
): MutationUpdaterFn<CreateQuoteCategoryResponse> => (cache, { data }) => {
  const category = data?.createSalesQuoteCategory;

  const categoryCache = getQuoteCategories(cache, salesQuoteId);
  if (!categoryCache || !category || !salesQuoteId) {
    return;
  }
  const categories = categoryCache.concat({
    ...category,
    costings: [],
  });
  updateQuoteCategories(cache, salesQuoteId, categories);
};

export const handleDeleteCategory = (
  salesQuoteId?: string
): MutationUpdaterFn<DeleteQuoteCategoryResponse> => (cache, { data }) => {
  const salesQuote = data?.deleteSalesQuoteCategory;

  const categoryCache = getQuoteCategories(cache, salesQuoteId);

  if (!categoryCache || !salesQuote || !salesQuoteId) {
    return;
  }

  const categories = filter(categoryCache, (category) => {
    return some(salesQuote.costingCategories, { _id: category._id });
  });
  updateQuoteCategories(cache, salesQuoteId, categories);
};

export const handleUpdateStatusCategory = (
  salesQuoteId?: string
): MutationUpdaterFn<UpdateSalesQuoteCostingCategoryStatusResponse> => (
  cache,
  { data }
) => {
  const salesQuote = data?.updateSalesQuoteCostingCategoryStatus;

  const categoryCache = getQuoteCategories(cache, salesQuoteId);
  if (!categoryCache || !salesQuote || !salesQuoteId) {
    return;
  }
  const newData = categoryCache?.map((item) => {
    if (item._id === salesQuote?._id) {
      return { ...item, status: salesQuote.status };
    }
    return item;
  });

  updateQuoteCategories(cache, salesQuoteId, newData);
};

export const handleCreateCostingItems = (
  salesQuoteId?: string
): MutationUpdaterFn<SaveSalesQuoteCategoryItems> => (cache, { data }) => {
  const updatedCategory = data?.saveSalesQuoteCostingByCategory;

  const categoryCache = getQuoteCategories(cache, salesQuoteId);

  if (!categoryCache || !salesQuoteId || !updatedCategory) return;

  const categories = map(categoryCache, (category) => {
    if (category._id === updatedCategory._id) {
      return updatedCategory;
    }
    return category;
  });
  updateQuoteCategories(cache, salesQuoteId, categories);
};

export const prepareCategoryUpdateItem = (
  selectedCategory: CostingCategoryListItem | null
) => {
  if (!selectedCategory) {
    return;
  }

  const costItems = map(selectedCategory.costings, (costing, index) => {
    const assemblyId = costing.type === "assembly" ? uniqueId("assembly:") : "";
    return {
      name: costing.name,
      quantity: costing.quantity.toString(),
      raw_quantity: costing.raw_quantity || costing.quantity.toString(),
      margin_amount: costing.margin_amount.toString(),
      cost: costing.cost.toString(),
      wastage: Number(costing.wastage) || 0,
      rounding: costing.rounding,
      UOM: costing.UOM,
      hasGST: costing.hasGST,
      distribute_markup: costing.distribute_markup,
      items: map(costing.items, (item) => ({
        _id: item._id,
        name: item.name,
        wastage: item.wastage,
        rounding: item.rounding,
        quantity: item.quantity.toString(),
        raw_quantity:
          item?.raw_quantity?.toString() ?? item.quantity.toString(),
        cost: item.cost.toString(),
        UOM: item.UOM,
      })),
      type: costing.type,
      note: costing.note,
      _id: assemblyId || costing._id,
      originalId: costing._id,
      assemblyId,
    };
  });

  return {
    _id: selectedCategory._id,
    name: selectedCategory.name,
    margin_amount: selectedCategory.margin_amount.toString(),
    costItems,
  };
};
