import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { getReminderRepeatOptions } from "../../../utils/options";
import { ReminderModalFields } from "./index";

const createReminderFields = (
  t: TFunction
): GenericFormFields<ReminderModalFields> => {
  return {
    title: {
      type: "text",
      label: t("reminders.title"),
      placeholder: t("placeholders.title"),
      valueKey: "title",
    },
    description: {
      type: "text",
      controlType: "textarea",
      inputProps: {
        rows: 5,
      },
      label: t("reminders.description"),
      placeholder: t("placeholders.description"),
      valueKey: "description",
    },
    dates: [
      {
        type: "date-picker",
        label: t("reminders.date"),
        placeholder: t("placeholders.date"),
        valueKey: "endDate",
      },
      {
        type: "date-picker",
        label: t("reminders.time"),
        placeholder: t("placeholders.time"),
        valueKey: "endTime",
        datePickerProps: {
          showTimeSelect: true,
          showTimeSelectOnly: true,
        },
      },
    ],
    repeat: {
      type: "select",
      label: t("reminders.repeat"),
      placeholder: t("placeholders.repeat"),
      valueKey: "repeat",
      autocompleteProps: {
        options: getReminderRepeatOptions(t),
      },
    },
  };
};

export default createReminderFields;
