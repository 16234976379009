import { gql } from "@apollo/client";

export const CREATE_UPDATE_REMINDER = gql`
  mutation CreateUpdateReminder($reminder: ReminderInput) {
    createUpdateReminder(reminder: $reminder) {
      _id
      title
      description
      completed
      dueDate
    }
  }
`;

export const UPDATE_REMINDER_STATUS = gql`
  mutation UpdateReminderStatus($reminderId: ID!, $status: Boolean!) {
    updateReminderStatus(reminderId: $reminderId, status: $status) {
      _id
      completed
    }
  }
`;

export const DELETE_REMINDER = gql`
  mutation DeleteReminder($reminderId: ID!) {
    deleteReminderById(reminderId: $reminderId) {
      _id
    }
  }
`;
