import { gql } from "@apollo/client";

export const AFFILIATE_FRAGMENT = gql`
  fragment AffiliateFragment on Affiliate {
    _id
    first_name
    last_name
    business_name
    phone
    ABN
    address
    city
    state
    postcode
    email
    affiliateCode
    affiliateUrl
    status
    is_deleted
  }
`;

export const AFFILIATE_BUSINESS_FRAGMENT = gql`
  fragment AffiliateBusinessFragment on AffiliateBusiness {
    _id
    name
    stripeSubscriptionId
    subscriptionPlan {
      _id
      name
      price
      limit
    }
    subscriptionEndDate
    status
    createdAt
  }
`;
