import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { uniqBy } from "lodash";
import { LatLngLiteral } from "leaflet";

import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import TextInput from "../../generic-form/inputs/text";
import SelectInput, { SelectOption } from "../../generic-form/inputs/select";
import { PAPER_OPTIONS, SCALE_OPTIONS } from "../../../utils/options";
import CreatableSelectInput from "../../generic-form/inputs/creatable-select";
import "./styles.scss";

type HorizontalLineCardProps = {
  line: string;
  scale: string;
  paper: string;
  calibration: LatLngLiteral[];
  onSubmit: (all: boolean) => void;
  onHorizontalLineChange: (line: string) => void;
  onScaleChange: (scale: string) => void;
  onPaperChange: (paper: string) => void;
  customScaleOptions?: SelectOption[];
};

export const UNITS_MAP = {
  mm: "millimeter",
  cm: "centimeter",
};

const HorizontalLineCard: React.FC<HorizontalLineCardProps> = ({
  line,
  scale,
  paper,
  calibration,
  onSubmit,
  onHorizontalLineChange,
  onScaleChange,
  onPaperChange,
  customScaleOptions,
}) => {
  const { t } = useTranslation();
  const [scaleOptions, setScaleOptions] = React.useState<SelectOption[]>(
    SCALE_OPTIONS
  );

  React.useEffect(() => {
    if (customScaleOptions?.length) {
      setScaleOptions([...scaleOptions, ...customScaleOptions]);
    }
  }, []);

  const handleCreateScale = React.useCallback(
    (customScale: string) => {
      const customScaleValue = customScale.split(":")[1];
      const newOptions = uniqBy(
        [
          ...scaleOptions,
          {
            label: customScale,
            value: customScaleValue,
          },
        ],
        "value"
      );
      setScaleOptions(newOptions);
      onScaleChange(customScaleValue);
    },
    [scaleOptions, setScaleOptions, onScaleChange]
  );

  const handleLineChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onHorizontalLineChange(event.target.value);
    },
    [onHorizontalLineChange]
  );

  const handleScaleChange = React.useCallback(
    (value: string | string[]) => onScaleChange(value as string),
    [onScaleChange]
  );

  const handlePaperChange = React.useCallback(
    (value: string | string[]) => onPaperChange(value as string),
    [onScaleChange]
  );

  const handleSubmit = React.useCallback(() => {
    onSubmit(false);
  }, [onSubmit]);

  const handleApplyAll = React.useCallback(() => {
    onSubmit(true);
  }, [onSubmit]);

  const isCustom = React.useMemo(() => paper === "CUSTOM", [paper]);

  const canContinue = React.useMemo(() => {
    if (isCustom) return !isEmpty(line) && !isEmpty(calibration);
    return !isEmpty(paper) || !isEmpty(scale);
  }, [line, scale, paper, calibration, isCustom]);

  return (
    <DashboardCard className="overflow-visible">
      <DashboardCardHeader className="centered">
        {t("plansSection.scalePlans")}
      </DashboardCardHeader>
      <DashboardCardBody>
        <Row>
          <Col sm={6}>
            <div className="form-group d-flex flex-column">
              <label className="form-input-label form-label">
                {t("plansSection.sheetSize")}
              </label>
              <SelectInput
                name="paper"
                onChange={handlePaperChange}
                value={paper}
                options={PAPER_OPTIONS}
              />
            </div>
          </Col>
          {!isCustom && (
            <Col sm={6}>
              <div className="form-group d-flex flex-column">
                <label className="form-input-label form-label">
                  {t("plansSection.scale")}
                </label>
                <CreatableSelectInput
                  name="scale"
                  onChange={handleScaleChange}
                  value={scale}
                  options={scaleOptions}
                  isMulti={false}
                  isValidNewOption={(value) => /^1:[0-9]+$/g.test(value)}
                  onCreateOption={handleCreateScale}
                />
              </div>
            </Col>
          )}
        </Row>
        {isCustom && (
          <div className="mb-4">
            <p className="field-text">
              {t("plansSection.horizontalLineHelper")}
            </p>
            <TextInput
              name="horizontal-line"
              value={line}
              append={{
                className: "text field-text unit-append",
                text: t(`plansSection.${get(UNITS_MAP, "mm")}`),
              }}
              onChange={handleLineChange}
              type="number"
              placeholder={t("placeholders.length")}
              className="form-input horizontal-line"
            />
          </div>
        )}
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end">
        {canContinue && (
          <Button className="button info mr-4" onClick={handleApplyAll}>
            {t("plansSection.applyAll")}
          </Button>
        )}
        <Button
          className="button success"
          disabled={!canContinue}
          onClick={handleSubmit}
        >
          {t("common.next")}
        </Button>
      </DashboardCardFooter>
    </DashboardCard>
  );
};

export default HorizontalLineCard;
