import { gql } from "@apollo/client";

export const SELECTION_CATEGORY_ITEM_OPTION_FRAGMENT = gql`
  fragment SelectionCategoryItemOptionFragment on SelectionCategoryItemOption {
    _id
    name
    description
    note
    cost
    markup_cost
    quantity
    order
    UOM
    attachments {
      _id
      name
      type
      size
      url
      upload_url
    }
    is_deleted
    updatedAt
    createdAt
  }
`;

export const DIGITAL_SIGNATURE_FRAGMENT = gql`
  fragment DigitalSignatureFragment on DigitalSignature {
    _id
    name
    date
    signedByType
    signedBy {
      ... on User {
        _id
        first_name
        last_name
      }
      ... on Client {
        _id
        first_name
        last_name
      }
    }
    file {
      _id
      name
      type
      url
      upload_url
    }
  }
`;

export const SELECTION_CATEGORY_ITEM_FRAGMENT = gql`
  fragment SelectionCategoryItemFragment on SelectionCategoryItem {
    _id
    name
    description
    dueDate
    note
    order
    costingCategoryEntityName
    costingCategoryEntityId
    costingItemEntityName
    costingItemEntityId
    costingItemEntityType
    clientComment
    comments {
      _id
      comment
    }
    costingItem {
      _id
      name
      category {
        _id
        name
      }
    }
    status
    is_deleted
    updatedAt
    createdAt
    selectedBy {
      ... on User {
        _id
        first_name
        last_name
      }
      ... on Client {
        _id
        first_name
        last_name
      }
    }
    selectedByDate
    clientSignature {
      ...DigitalSignatureFragment
    }
    approvedBy {
      _id
      first_name
      last_name
    }
    approvedByDate
    category {
      _id
    }
    options {
      ...SelectionCategoryItemOptionFragment
    }
    defaultOption {
      ...SelectionCategoryItemOptionFragment
    }
    selectedOption {
      ...SelectionCategoryItemOptionFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_OPTION_FRAGMENT}
  ${DIGITAL_SIGNATURE_FRAGMENT}
`;

export const SELECTION_CATEGORY_FRAGMENT = gql`
  fragment SelectionCategoryFragment on SelectionCategory {
    _id
    name
    description
    dueDate
    costingCategoryEntityName
    costingCategoryEntityId
    costingCategoryEntityType
    order
    status
    is_deleted
    updatedAt
    createdAt
    items {
      ...SelectionCategoryItemFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
`;

export const SELECTION_FRAGMENT = gql`
  fragment SelectionFragment on Selection {
    _id
    name
    description
    dueDate
    linkedEntityId
    linkedEntityType
    status
    is_deleted
    updatedAt
    createdAt
    entity {
      ... on Job {
        __typename
        _id
        name
        jobNumber
      }
      ... on SalesQuote {
        __typename
        _id
        name
        number
      }
    }
    categories {
      ...SelectionCategoryFragment
    }
  }
  ${SELECTION_CATEGORY_FRAGMENT}
`;

export const SELECTION_TEMPLATE_FRAGMENT = gql`
  fragment SelectionTemplateFragment on SelectionTemplate {
    _id
    name
    description
    is_deleted
    updatedAt
    createdAt
    categories {
      ...SelectionCategoryFragment
    }
  }
  ${SELECTION_CATEGORY_FRAGMENT}
`;
