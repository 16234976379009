import { gql } from "@apollo/client";

export const LIST_SUPPLIERS = gql`
  query ListSuppliers {
    listSuppliers {
      _id
      business_name
      contact_person
      email
      address
      address2
      state
      city
      postcode
      phone_number
      phone_secondary
    }
  }
`;

export const LIST_SUPPLIERS_CATEGORIES = gql`
  query ListSupplierCategories {
    listSupplierCategories {
      _id
      name
    }
  }
`;

export const GET_SUPPLIER = gql`
  query GetSupplier($id: ID!) {
    getSupplierDetails(supplierId: $id) {
      _id
      abn
      business_name
      email
      contact_person
      address
      address2
      phone_number
      phone_secondary
      city
      state
      postcode
      fax_number
      mobile
      purchase_orders {
        _id
        orderNumber
        date
        deliveryDate
        status
        reference
        job {
          _id
          name
        }
      }
      category {
        _id
        name
      }
    }
  }
`;
export const EXPORT_SUPPLIERS = gql`
  query exportSuppliers($filter: SupplierFilterInput!) {
    exportSuppliers(filter: $filter) {
      _id
      data
      filename
    }
  }
`;
