import { BadgeProps } from "react-bootstrap/Badge";
import { RosterStatus } from "../../../models/roster";
import { calcWithGST, GST_PERCENT } from "../../../utils/calculations";

export const getRosterBadgeVariant = (
  status: RosterStatus
): BadgeProps["variant"] => {
  if (status === RosterStatus.SENT) {
    return "warning";
  } else if (status === RosterStatus.APPROVED) {
    return "success";
  } else if (status === RosterStatus.CANCELLED) {
    return "danger";
  }
  return "secondary";
};

export const getTotal = (items: { cost: number; quantity: number }[]) => {
  return items.reduce(
    (sum, current) =>
      sum + calcWithGST(current.cost * current.quantity || 0, GST_PERCENT),
    0
  );
};
