import { MutationUpdaterFn, ApolloCache } from "@apollo/client";
import {
  DeleteFolderResponse,
  ListFoldersResponse,
  GetFolderResponse,
  DeleteDocumentResponse,
  CreateUpdateFolderResponse,
  CreateUpdateDocumentResponse,
  GetSystemFolderResponse,
} from "../../types/models/documents";
import { LIST_FOLDERS, GET_FOLDER, GET_SYSTEM_FOLDER } from "./queries";
import {
  Folder,
  SystemFolderType,
  RootFolder,
  PrimaryFolder,
  Document,
} from "../../../models/documents";
import { concat, filter, map } from "lodash";

export enum FolderType {
  SYSTEM = "SYSTEM_FOLDER",
  ROOT = "ROOT_FOLDER",
  FOLDER = "FOLDER",
}

export type FolderConfig = { folderId: string };
export type SystemFolderConfig = {
  refId: string;
  systemType: SystemFolderType;
};

export type DocumentsConfig = FolderConfig | SystemFolderConfig;

const isFolderConfig = (config: DocumentsConfig): config is FolderConfig =>
  (config as FolderConfig).folderId !== undefined;

const isSystemFolderConfig = (
  config: DocumentsConfig
): config is SystemFolderConfig =>
  (config as SystemFolderConfig).refId !== undefined &&
  (config as SystemFolderConfig).systemType !== undefined;

const isFolder = (folder: PrimaryFolder[] | Folder): folder is Folder =>
  (folder as Folder).subFolders !== undefined;

const isPrimaryFolderArray = (
  folders: PrimaryFolder[] | Folder
): folders is PrimaryFolder[] =>
  (folders as PrimaryFolder[]).length !== undefined;

const getFolder = (cache: ApolloCache<any>, folderId: string) => {
  const folderResponse = cache.readQuery<GetFolderResponse>({
    query: GET_FOLDER,
    variables: {
      folderId,
    },
  });

  return folderResponse?.getFolder;
};

const getSystemFolder = (
  cache: ApolloCache<any>,
  config: SystemFolderConfig
) => {
  const { refId, systemType } = config;

  const systemFolderResponse = cache.readQuery<GetSystemFolderResponse>({
    query: GET_SYSTEM_FOLDER,
    variables: {
      refId,
      systemType,
    },
  });

  return systemFolderResponse?.getSystemFolder;
};

const getListFolder = (cache: ApolloCache<any>) => {
  const foldersResponse = cache.readQuery<ListFoldersResponse>({
    query: LIST_FOLDERS,
  });

  return foldersResponse?.listFolders;
};

const updateFolder = (
  cache: ApolloCache<any>,
  folders: PrimaryFolder[],
  prevStateFolder: Folder,
  config: FolderConfig
) => {
  const { folderId } = config;
  cache.writeQuery({
    query: GET_FOLDER,
    variables: { folderId },
    data: {
      getFolder: {
        ...prevStateFolder,
        subFolders: folders,
      },
    },
  });
};

const updateFolderDocuments = (
  cache: ApolloCache<any>,
  documents: Document[],
  prevStateFolder: Folder,
  config: FolderConfig
) => {
  const { folderId } = config;
  cache.writeQuery({
    query: GET_FOLDER,
    variables: { folderId },
    data: {
      getFolder: {
        ...prevStateFolder,
        documents: documents,
      },
    },
  });
};

const updateSystemFolder = (
  cache: ApolloCache<any>,
  folders: PrimaryFolder[],
  prevStateFolder: Folder,
  config: SystemFolderConfig
) => {
  const { refId, systemType } = config;
  cache.writeQuery({
    query: GET_SYSTEM_FOLDER,
    variables: {
      refId,
      systemType,
    },
    data: {
      getSystemFolder: {
        ...prevStateFolder,
        subFolders: folders,
      },
    },
  });
};

const updateSystemFolderDocuments = (
  cache: ApolloCache<any>,
  documents: Document[],
  prevStateFolder: Folder,
  config: SystemFolderConfig
) => {
  const { refId, systemType } = config;
  cache.writeQuery({
    query: GET_SYSTEM_FOLDER,
    variables: {
      refId,
      systemType,
    },
    data: {
      getSystemFolder: {
        ...prevStateFolder,
        documents: documents,
      },
    },
  });
};

const updateFolderList = (
  cache: ApolloCache<any>,
  folders: PrimaryFolder[]
) => {
  cache.writeQuery({
    query: LIST_FOLDERS,
    data: {
      listFolders: folders,
    },
  });
};

const getCacheFolderData = (
  cache: ApolloCache<any>,
  type: FolderType,
  config?: DocumentsConfig
) => {
  switch (type) {
    case FolderType.FOLDER: {
      if (config && isFolderConfig(config))
        return getFolder(cache, config.folderId);
      break;
    }
    case FolderType.ROOT: {
      return getListFolder(cache);
    }
    case FolderType.SYSTEM: {
      if (config && isSystemFolderConfig(config))
        return getSystemFolder(cache, config);
      break;
    }
  }
};

const getCacheFolderDataPartialFolderType = (
  cache: ApolloCache<any>,
  type: FolderType,
  config?: DocumentsConfig
) => {
  switch (type) {
    case FolderType.FOLDER: {
      if (config && isFolderConfig(config))
        return getFolder(cache, config.folderId);
      break;
    }
    case FolderType.SYSTEM: {
      if (config && isSystemFolderConfig(config))
        return getSystemFolder(cache, config);
      break;
    }
  }
};

const getArrayOfFolders = (
  type: FolderType,
  cacheData: PrimaryFolder[] | Folder
) => {
  if (type === FolderType.ROOT) {
    if (isPrimaryFolderArray(cacheData)) {
      return cacheData;
    }
  } else {
    if (isFolder(cacheData)) {
      return cacheData.subFolders;
    }
  }
};

const updateCacheFolderData = (
  cache: ApolloCache<any>,
  type: FolderType,
  folders: PrimaryFolder[],
  prevCache: any,
  config?: DocumentsConfig
) => {
  switch (type) {
    case FolderType.FOLDER: {
      if (config && isFolderConfig(config))
        return updateFolder(cache, folders, prevCache, config);
      break;
    }
    case FolderType.ROOT: {
      return updateFolderList(cache, folders);
    }
    case FolderType.SYSTEM: {
      if (config && isSystemFolderConfig(config)) {
        return updateSystemFolder(cache, folders, prevCache, config);
      }
      break;
    }
  }
};

const updateCacheDocumentsData = (
  cache: ApolloCache<any>,
  type: FolderType,
  documents: Document[],
  prevCache: any,
  config?: DocumentsConfig
) => {
  switch (type) {
    case FolderType.FOLDER: {
      if (config && isFolderConfig(config))
        return updateFolderDocuments(cache, documents, prevCache, config);
      break;
    }
    case FolderType.SYSTEM: {
      if (config && isSystemFolderConfig(config)) {
        return updateSystemFolderDocuments(cache, documents, prevCache, config);
      }
      break;
    }
  }
};

export const handleFolderAdd = (
  type: FolderType,
  config?: DocumentsConfig
): MutationUpdaterFn<CreateUpdateFolderResponse> => (cache, { data }) => {
  const newFolder = data?.createUpdateFolder;
  const cacheData = getCacheFolderData(cache, type, config);
  if (!cacheData || !newFolder) return;

  const folders = getArrayOfFolders(type, cacheData);
  if (!folders) return;

  const newData = concat(folders, newFolder);
  updateCacheFolderData(cache, type, newData, cacheData, config);
};

export const handleFolderDelete = (
  type: FolderType,
  config?: DocumentsConfig
): MutationUpdaterFn<DeleteFolderResponse> => (cache, { data }) => {
  const deletedFolder = data?.deleteFolder;
  const cacheData = getCacheFolderData(cache, type, config);
  if (!cacheData || !deletedFolder) return;

  const folders = getArrayOfFolders(type, cacheData);
  if (!folders) return;

  const newData = filter(folders, (folder) => folder._id !== deletedFolder._id);
  updateCacheFolderData(cache, type, newData, cacheData, config);
};

export const handleFolderUpdate = (
  type: FolderType,
  config?: DocumentsConfig
): MutationUpdaterFn<CreateUpdateFolderResponse> => (cache, { data }) => {
  const updatedFolder = data?.createUpdateFolder;
  const cacheData = getCacheFolderData(cache, type, config);
  if (!cacheData || !updatedFolder) return;

  const folders = getArrayOfFolders(type, cacheData);
  if (!folders) return;

  const newData = map(folders, (folder) =>
    folder._id === updatedFolder._id ? updatedFolder : folder
  );
  updateCacheFolderData(cache, type, newData, cacheData, config);
};

export const handleDocumentAdd = (
  type: FolderType,
  config?: DocumentsConfig
): MutationUpdaterFn<CreateUpdateDocumentResponse> => (cache, { data }) => {
  const updatedDocuments = data?.createUpdateDocuments;
  const cacheData = getCacheFolderDataPartialFolderType(cache, type, config);
  if (!cacheData || !updatedDocuments) return;

  const documents = cacheData.documents;
  if (!documents) return;

  const newData = concat(documents, updatedDocuments);
  updateCacheDocumentsData(cache, type, newData, cacheData, config);
};

export const handleDocumentUpdate = (
  type: FolderType,
  config?: DocumentsConfig
): MutationUpdaterFn<CreateUpdateDocumentResponse> => (cache, { data }) => {
  const updatedDocuments = data?.createUpdateDocuments;
  const cacheData = getCacheFolderDataPartialFolderType(cache, type, config);
  if (!cacheData || !updatedDocuments) return;

  const documents = cacheData.documents;
  if (!documents) return;

  const newData = map(documents, (document) => {
    const updatedDocument = updatedDocuments.find(
      (item) => item._id === document._id
    );

    if (updatedDocument) {
      return updatedDocument;
    }

    return document;
  });
  updateCacheDocumentsData(cache, type, newData, cacheData, config);
};

export const handleDocumentDelete = (
  type: FolderType,
  config?: DocumentsConfig
): MutationUpdaterFn<DeleteDocumentResponse> => (cache, { data }) => {
  const deletedDocument = data?.deleteDocument;
  const cacheData = getCacheFolderDataPartialFolderType(cache, type, config);
  if (!cacheData || !deletedDocument) return;

  const documents = cacheData.documents;
  if (!documents) return;

  const newData = filter(
    documents,
    (document) => document._id !== deletedDocument._id
  );
  updateCacheDocumentsData(cache, type, newData, cacheData, config);
};
