import { useMutation, useQuery } from "@apollo/client";
import { find } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { notify } from "../../components/notification";
import UpdateSalesQuoteModal, {
  UpdateSalesQuoteModalRef,
} from "../../components/quotes/update-sales-quote-modal";
import { LIST_CONTACTS } from "../../graphql/queries/client/queries";
import {
  COPY_SALES_QUOTE,
  CREATE_UPDATE_SALES_QUOTE,
} from "../../graphql/queries/quote/mutations";
import { LIST_QUOTES } from "../../graphql/queries/quote/queries";
import { handleCopyQuote } from "../../graphql/queries/quote/utils";
import {
  ContactListItem,
  ContactListResponse,
} from "../../graphql/types/models/client";
import {
  QuoteCopyResponse,
  QuoteCreateUpdateResponse,
  QuoteListResponse,
} from "../../graphql/types/models/quote";
import {
  CopySalesQuotePayload,
  CreateSalesQuotePayload,
  SalesQuote,
} from "../../models/salesQuote";

export function useEditEstimation() {
  const [selectedSalesQuote, setSelectedSalesQuote] = React.useState<
    SalesQuote
  >();
  const updateRef = React.useRef<UpdateSalesQuoteModalRef>(null);
  const copyRef = React.useRef<UpdateSalesQuoteModalRef>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const [clients, setClients] = React.useState<ContactListItem[] | null>(null);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [createUpdateSalesQuote] = useMutation<QuoteCreateUpdateResponse>(
    CREATE_UPDATE_SALES_QUOTE,
    {
      onCompleted: () => {
        updateRef.current?.show(false);
        if (editMode) {
          notify({
            title: t("quotes.editQuote"),
            content: t("quotes.success.updateQuote"),
          });
          setEditMode(false);
        }
      },
      onError: (error) => {
        if (editMode) {
          notify({
            error: true,
            title: t("quotes.editQuote"),
            content: t("quotes.errors.updateQuote"),
          });
          setEditMode(false);
        }
      },
      update(cache, { data }) {
        const createUpdateSalesQuote = data?.createUpdateSalesQuote;

        const cacheData: QuoteListResponse | null = cache.readQuery({
          query: LIST_QUOTES,
          variables: {
            filter: {
              is_archived: false,
            },
          },
        });
        console.log({ cacheData });

        if (!cacheData || !createUpdateSalesQuote) {
          return;
        }

        const { listSalesQuotes } = cacheData;

        if (!find(listSalesQuotes, { _id: createUpdateSalesQuote._id })) {
          cache.writeQuery({
            query: LIST_QUOTES,
            variables: {
              filter: {
                is_archived: false,
              },
            },
            data: {
              listSalesQuotes: listSalesQuotes.concat(createUpdateSalesQuote),
            },
          });
          history.push(`/quotes/${createUpdateSalesQuote._id}/plans`);
        }
      },
    }
  );

  const [copySalesQuote] = useMutation<
    QuoteCopyResponse,
    CopySalesQuotePayload
  >(COPY_SALES_QUOTE, {
    onCompleted: (data) => {
      copyRef.current?.show(false);
      notify({
        title: t("quotes.copyQuote"),
        content: t("quotes.success.copyQuote"),
      });
      history.push(`/quotes/${data.copySalesQuote._id}`);
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("quotes.copyQuote"),
        content: t("quotes.errors.copyQuote"),
      });
    },
    update: handleCopyQuote,
  });

  const { loading: contactsLoading, data: clientsData } = useQuery<
    ContactListResponse
  >(LIST_CONTACTS);

  const handleQuoteEdit = React.useCallback(
    (quote?: SalesQuote) => {
      if (quote) {
        setSelectedSalesQuote(quote);
        updateRef.current?.show(true);
      }
    },
    [updateRef]
  );

  const handleQuoteCopy = React.useCallback(
    (quote?: SalesQuote) => {
      if (quote) {
        setSelectedSalesQuote(quote);
        copyRef.current?.show(true);
      }
    },
    [copyRef]
  );

  React.useEffect(() => {
    const data = clientsData?.listContacts;

    if (contactsLoading || !data) {
      return;
    }

    setClients(data);
  }, [clientsData, contactsLoading]);

  const handleCreateQuoteSubmit = React.useCallback(
    (quote: CreateSalesQuotePayload, isEdit?: boolean) => {
      if (isEdit) {
        setEditMode(true);
      }
      return createUpdateSalesQuote({
        variables: {
          quote,
        },
      });
    },
    [createUpdateSalesQuote]
  );

  const handleCopyQuoteSubmit = React.useCallback(
    (quote: CopySalesQuotePayload) => {
      return copySalesQuote({
        variables: {
          ...quote,
        },
      });
    },
    [copySalesQuote]
  );

  const renderUpdateSalesQuoteModal = React.useCallback(
    (quote?: SalesQuote) => {
      return (
        clients && (
          <UpdateSalesQuoteModal
            ref={updateRef}
            quote={quote ?? selectedSalesQuote}
            onSubmit={(data: CreateSalesQuotePayload) =>
              handleCreateQuoteSubmit(data, true)
            }
            clientsList={clients}
          />
        )
      );
    },
    [clients, handleCreateQuoteSubmit, selectedSalesQuote]
  );

  const renderCopySalesQuoteModal = React.useCallback(
    (quote?: SalesQuote) => {
      return (
        clients && (
          <UpdateSalesQuoteModal
            ref={copyRef}
            quote={quote ?? selectedSalesQuote}
            onCopySubmit={(
              data: CreateSalesQuotePayload | CopySalesQuotePayload
            ) => handleCopyQuoteSubmit(data as CopySalesQuotePayload)}
            clientsList={clients}
            isCopy={true}
          />
        )
      );
    },
    [clients, handleCopyQuoteSubmit, selectedSalesQuote]
  );

  return {
    handleQuoteEdit,
    selectedSalesQuote,
    setSelectedSalesQuote,
    createUpdateSalesQuote,
    renderUpdateSalesQuoteModal,
    contactsLoading,
    clients,
    renderCopySalesQuoteModal,
    handleQuoteCopy,
  };
}
