import { ApolloCache, ApolloClient, MutationUpdaterFn } from "@apollo/client";
import { filter, findIndex, map, pick } from "lodash";
import {
  CreateUpdateJobCostingCategoryResponse,
  DeleteJobCostingCategory,
  ListJobCostingsResponse,
} from "../../types/models/job-costing";
import { QuoteRequestResponse } from "../../../graphql/types/models/quote-request";
import { LIST_JOB_COSTINGS } from "./queries";
import { QuoteRequestItemStatus } from "../../../graphql/types/models/quote-request";
import { JobCostingItem, SearchJobCostingItem } from "../../../models/job";
import { JOB_COSTING_ITEM_FRAGMENT } from "./fragments";

export const handleJobCostingCategoryAdd = (
  jobId?: string
): MutationUpdaterFn<CreateUpdateJobCostingCategoryResponse> => (
  cache,
  response
) => {
  const createdCategory = response?.data?.jobCreateUpdateCostingCategory;

  const categoriesResponse = cache.readQuery<ListJobCostingsResponse>({
    query: LIST_JOB_COSTINGS,
    variables: {
      jobId,
    },
  });

  if (!createdCategory || !categoriesResponse) {
    return;
  }

  const categoryList = categoriesResponse?.getJobCostings;
  let jobCostings = [...categoryList];

  const existingCategoryIndex = findIndex(categoryList, {
    _id: createdCategory._id,
  });
  if (existingCategoryIndex >= 0) {
    jobCostings[existingCategoryIndex] = createdCategory;
  } else {
    jobCostings.push(createdCategory);
  }

  cache.writeQuery({
    query: LIST_JOB_COSTINGS,
    data: {
      getJobCostings: jobCostings,
    },
    variables: {
      jobId,
    },
  });
};

export const handleJobCostingCategoryDelete = (
  jobId?: string
): MutationUpdaterFn<DeleteJobCostingCategory> => (cache, response) => {
  const deletedCostingItem = response?.data?.jobDeleteCostingCategory;

  const categoriesResponse = cache.readQuery<ListJobCostingsResponse>({
    query: LIST_JOB_COSTINGS,
    variables: {
      jobId,
    },
  });

  if (!deletedCostingItem || !categoriesResponse) {
    return;
  }

  const categoryList = categoriesResponse?.getJobCostings;

  cache.writeQuery({
    query: LIST_JOB_COSTINGS,
    data: {
      getJobCostings: filter(
        categoryList,
        (category) => category._id !== deletedCostingItem._id
      ),
    },
    variables: {
      jobId,
    },
  });
};

export const handleSendQuoteRequest = (
  jobId: string,
  itemsId: string[]
): MutationUpdaterFn<QuoteRequestResponse> => (cache, { data }) => {
  const categoriesResponse = cache.readQuery<ListJobCostingsResponse>({
    query: LIST_JOB_COSTINGS,
    variables: {
      jobId,
    },
  });

  if (!itemsId || !categoriesResponse) return;

  const categoriesList = map(categoriesResponse.getJobCostings, (category) => ({
    ...category,
    items: map(category.items, (item) =>
      itemsId.find((id) => id === item._id)
        ? {
            ...item,
            quoteRequestStatus: QuoteRequestItemStatus.REQUESTED,
          }
        : item
    ),
  }));

  cache.writeQuery({
    query: LIST_JOB_COSTINGS,
    data: {
      getJobCostings: categoriesList,
    },
    variables: {
      jobId,
    },
  });
};

export const findCostingItemFromCostingsCache = async (
  client: ApolloClient<any>,
  jobId: string,
  costingId: string
): Promise<SearchJobCostingItem | null> => {
  if (costingId.indexOf("costing:") < 0) return null;
  const id = costingId.split(":")[1];

  const { data } = await client.query<ListJobCostingsResponse>({
    query: LIST_JOB_COSTINGS,
    variables: { jobId },
    fetchPolicy: "cache-only",
  });
  let costingItem = null;
  for (let category of data.getJobCostings) {
    const match = category.items.find((item) => item._id === id);
    if (match) {
      costingItem = match as SearchJobCostingItem;
      break;
    }
  }

  return costingItem;
};

export const findCostingItemFromCache = (
  client: ApolloClient<any> | ApolloCache<any>,
  costingId: string
): JobCostingItem | null => {
  const id = `JobCostingItem:${costingId}`;
  const costingItem = client?.readFragment({
    id,
    fragment: JOB_COSTING_ITEM_FRAGMENT,
    fragmentName: "JobCostingItemFragment",
  });
  return costingItem as JobCostingItem;
};

export const updateCostingItemCache = (
  client: ApolloClient<any> | ApolloCache<any>,
  item: JobCostingItem
) => {
  const id = `JobCostingItem:${item._id}`;
  client?.writeFragment({
    id,
    fragment: JOB_COSTING_ITEM_FRAGMENT,
    fragmentName: "JobCostingItemFragment",
    data: item,
  });
};

export const searchCostingItem = async (
  client: ApolloClient<any>,
  jobId: string,
  search: string
): Promise<SearchJobCostingItem[]> => {
  if (!jobId) return [];
  const { data } = await client.query<ListJobCostingsResponse>({
    query: LIST_JOB_COSTINGS,
    variables: { jobId },
    fetchPolicy: "cache-first",
  });
  const items = data.getJobCostings.reduce((list, category) => {
    const items = category.items
      .filter((item) => item.name.toLowerCase().indexOf(search) >= 0)
      .map((item) => ({
        ...item,
        category: pick(category, ["_id", "name"]),
      }));
    list.push(...items);
    return list;
  }, [] as SearchJobCostingItem[]);
  return items;
};
