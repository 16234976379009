import { UpdateQueryFn } from "@apollo/client/core/watchQueryOptions";
import { filter, find, map, uniqueId } from "lodash";
import moment from "moment";
import {
  GetJobChannelsResponse,
  JobChannelComposeResponse,
  OnMessageAddResponse,
  GetJobChannelResponse,
  NewMessageSubscriptionResponse,
  ListAllMessagesResponse,
} from "../../types/models/job-messaging";
import {
  JobChannelComposeInput,
  OnJobMessageAddedInput,
} from "../../types/inputs/messaging";
import { JobChannelEnum } from "../../../models/job-messaging";
import { UserPayload } from "../../types/models/auth";

type UpdateChannelsFn = UpdateQueryFn<
  GetJobChannelsResponse,
  OnJobMessageAddedInput,
  OnMessageAddResponse
>;

type UpdateChannelFn = UpdateQueryFn<
  GetJobChannelResponse,
  OnJobMessageAddedInput,
  OnMessageAddResponse
>;

type UpdateAllChannelsFn = UpdateQueryFn<
  ListAllMessagesResponse,
  {},
  NewMessageSubscriptionResponse
>;

export const updateInternalChannel: UpdateChannelsFn = (
  previousQueryResult,
  response
) => {
  const { subscriptionData } = response;

  const { internalChannel } = previousQueryResult;

  const newMessage = subscriptionData.data.message;
  const messages = internalChannel?.messages;

  const existMessage = find(messages, { _id: newMessage._id });

  if (existMessage || !internalChannel) {
    return previousQueryResult;
  }

  return {
    ...previousQueryResult,
    internalChannel: {
      ...internalChannel,
      messages: [newMessage, ...(messages || [])],
    },
  };
};

export const updateExternalChannel: UpdateChannelsFn = (
  previousQueryResult,
  response
) => {
  const { subscriptionData } = response;

  const { externalChannel } = previousQueryResult;

  const messages = externalChannel?.messages;
  const newMessage = subscriptionData.data.message;

  const existMessage = find(messages, { _id: newMessage._id });

  if (existMessage || !externalChannel) {
    return previousQueryResult;
  }

  return {
    ...previousQueryResult,
    externalChannel: {
      ...externalChannel,
      messages: [newMessage, ...(messages || [])],
    },
  };
};

export const handlePostMessage = (
  channelsData: GetJobChannelsResponse,
  newMessage?: JobChannelComposeResponse | null
): GetJobChannelsResponse => {
  const messageChannelId = newMessage?.jobChannelCompose?.channel._id;

  const externalChannel = channelsData?.externalChannel;
  const internalChannel = channelsData?.internalChannel;

  let channel = null;

  if (internalChannel?._id === messageChannelId) {
    channel = internalChannel;
  } else if (externalChannel?._id === messageChannelId) {
    channel = externalChannel;
  }

  const existMessage = find(channel?.messages, {
    _id: newMessage?.jobChannelCompose?._id,
  });

  if (!channel || !newMessage) {
    return channelsData;
  }

  const newMessages = [
    {
      ...newMessage?.jobChannelCompose,
      media: map(newMessage?.jobChannelCompose?.media, (media) => ({
        ...media,
        url: media.url + `?${+new Date()}`,
      })),
    },
    ...(filter(
      channel?.messages,
      (message) => message._id !== newMessage?.jobChannelCompose?._id
    ) || []),
  ];

  const newChannel = {
    ...channel,
    messages: newMessages,
  };

  const getResponse = () => {
    if (newChannel.channelType === JobChannelEnum.External) {
      return {
        internalChannel,
        externalChannel: newChannel,
      };
    }

    return {
      externalChannel,
      internalChannel: newChannel,
    };
  };

  const newChannels = getResponse() as GetJobChannelsResponse;

  return newChannels;
};

export const createMessageOptimisticResponse = (
  channelId: string,
  user: UserPayload | null
) => (
  variables: JobChannelComposeInput
): JobChannelComposeResponse & { __typename: "Mutation" } => {
  const message = variables.message;

  return {
    __typename: "Mutation",
    jobChannelCompose: {
      __typename: "JobChannelMessage",
      _id: uniqueId("message-"),
      createdAt: moment().toISOString(),
      text: message.text,
      is_deleted: false,
      linkPreview: {
        __typename: "MediaPreview",
        description: null,
        imageUrl: null,
        title: null,
        url: null,
      },
      channel: {
        _id: channelId,
        __typename: "JobChannel",
      },
      media: [],
      sender: {
        __typename: "JobChannelMessageSender",
        _id: user?._id || uniqueId("profile-"),
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        profileImage: {
          url: user?.profileImage?.url,
        },
      },
    },
  };
};

export const handleAddMessage = (
  channelsData: GetJobChannelResponse,
  newMessage?: JobChannelComposeResponse | null
): GetJobChannelResponse => {
  const channel = channelsData.getJobChannelByType;

  if (!channel || !newMessage) {
    return channelsData;
  }

  const newMessages = [
    {
      ...newMessage?.jobChannelCompose,
      media: map(newMessage?.jobChannelCompose?.media, (media) => ({
        ...media,
        url: media.url + `?${+new Date()}`,
      })),
    },
    ...(filter(
      channel?.messages,
      (message) => message._id !== newMessage?.jobChannelCompose?._id
    ) || []),
  ];

  return {
    getJobChannelByType: {
      ...channel,
      messages: newMessages,
    },
  };
};

export const updateAllChannels: UpdateAllChannelsFn = (
  previousQueryResult,
  response
) => {
  const { subscriptionData } = response;
  const { listallJobsMessages } = previousQueryResult;

  const newMessage = subscriptionData.data.jobChannelSubscribeAll;

  const newDataExternal = map(listallJobsMessages.external, (channel) => ({
    ...channel,
    messages: channel.messages?.map((message) =>
      message._id === newMessage._id ? newMessage : message
    ),
  }));

  const newDataInternal = map(listallJobsMessages.internal, (channel) => ({
    ...channel,
    messages: channel.messages?.map((message) =>
      message._id === newMessage._id ? newMessage : message
    ),
  }));

  return {
    listallJobsMessages: {
      internal: newDataInternal,
      external: newDataExternal,
    },
  };
};

export const updateOnlyExternalChannel: UpdateChannelFn = (
  previousQueryResult,
  response
) => {
  const { subscriptionData } = response;

  const { getJobChannelByType } = previousQueryResult;

  const messages = getJobChannelByType?.messages;
  const newMessage = subscriptionData.data.message;

  const existMessage = find(messages, { _id: newMessage._id });

  if (existMessage || !getJobChannelByType) {
    return previousQueryResult;
  }

  const newMessages = [
    {
      ...newMessage,
      media: map(newMessage?.media, (media) => ({
        ...media,
        url: media.url
          ? `${media.url.split("?")[0]}?${+new Date()}`
          : media.url,
      })),
    },
    ...(filter(messages, (message) => message._id !== newMessage?._id) || []),
  ];

  return {
    ...previousQueryResult,
    getJobChannelByType: {
      ...getJobChannelByType,
      messages: newMessages,
    },
  };
};

export const updateOnlyInternalChannel: UpdateChannelFn = (
  previousQueryResult,
  response
) => {
  const { subscriptionData } = response;

  const { getJobChannelByType } = previousQueryResult;

  const newMessage = subscriptionData.data.message;
  const messages = getJobChannelByType?.messages;

  const existMessage = find(messages, { _id: newMessage._id });

  if (existMessage || !getJobChannelByType) {
    return previousQueryResult;
  }

  const newMessages = [
    {
      ...newMessage,
      media: map(newMessage?.media, (media) => ({
        ...media,
        url: media.url
          ? `${media.url.split("?")[0]}?${+new Date()}`
          : media.url,
      })),
    },
    ...(filter(messages, (message) => message._id !== newMessage?._id) || []),
  ];

  return {
    ...previousQueryResult,
    getJobChannelByType: {
      ...getJobChannelByType,
      messages: newMessages,
    },
  };
};
