import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Col, Row } from "react-bootstrap";
import { useHistory, RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import ClientQuoteRequestList, {
  ClientSalesQuoteRequest,
} from "../../../../components/client-quote-requests/quote-request-list";
import { ClientQuoteRequest } from "../../../../graphql/types/models/quote-request";
import { CLIENT_LIST_JOB_QUOTE_REQUEST } from "../../../../graphql/queries/quote-request/queries";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { ClientListJobQuoteResponse } from "../../../../graphql/types/models/quote-request";
import ClientSubcontractorList from "../../../../components/client-quote-requests/subcontractor-list";
import EmptyPlaceholder from "../../../../components/empty-placeholder";

type Params = {
  id: string;
};

type ClientJobQuoteRequestsProps = RouteComponentProps<Params> &
  DashboardContextValue;

const ClientJobQuoteRequests: React.FC<ClientJobQuoteRequestsProps> = ({
  match,
  navigationContext,
}) => {
  const { id: jobId } = match.params;
  const clientJob = navigationContext?.clientJob;

  const { t } = useTranslation();
  const history = useHistory();

  const [
    selectedQuoteRequest,
    setSelectedQuoteRequest,
  ] = useState<ClientQuoteRequest | null>(null);

  const { data: clientJobQuoteRequestsResponse } = useQuery<
    ClientListJobQuoteResponse
  >(CLIENT_LIST_JOB_QUOTE_REQUEST, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const jobQuotes = React.useMemo(
    () => clientJobQuoteRequestsResponse?.clientListJobQuoteResponses,
    [clientJobQuoteRequestsResponse]
  );

  const categorisedQuotes = React.useMemo<ClientSalesQuoteRequest[]>(() => {
    if (!jobQuotes || !clientJob) return [] as ClientSalesQuoteRequest[];

    return [
      {
        ...clientJob,
        number: clientJob.jobNumber,
        quoteRequests: jobQuotes,
      },
    ];
  }, [clientJob, jobQuotes]);

  React.useEffect(() => {
    if (categorisedQuotes.length && !selectedQuoteRequest) {
      setSelectedQuoteRequest(categorisedQuotes[0].quoteRequests[0]);
    }
  }, [selectedQuoteRequest, categorisedQuotes]);

  const handleSelectSubcontractor = React.useCallback(
    (quote: ClientQuoteRequest, subcontractorId: string) => {
      if (clientJob) {
        history.push(
          `/projects/${clientJob._id}/quote-requests/${quote._id}/sub/${subcontractorId}`
        );
      }
    },
    [clientJob, history]
  );

  const handleSelectQuoteRequest = React.useCallback(
    (quote: ClientQuoteRequest) => {
      setSelectedQuoteRequest(quote);
    },
    []
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.QUOTES} />
      <Helmet title={t("client.jobs.quoteRequests")} />

      {jobQuotes?.length ? (
        <Row className="h-100">
          <Col lg={4} xs={12} className="mh-100">
            {selectedQuoteRequest && (
              <ClientQuoteRequestList
                title={t("client.jobs.quoteRequests")}
                salesQuoteRequests={categorisedQuotes}
                selectedQuoteRequest={selectedQuoteRequest}
                onSelect={handleSelectQuoteRequest}
              />
            )}
          </Col>
          <Col lg={8} xs={12} className="mh-100">
            {selectedQuoteRequest && (
              <ClientSubcontractorList
                quoteRequest={selectedQuoteRequest}
                onSelect={handleSelectSubcontractor}
              />
            )}
          </Col>
        </Row>
      ) : (
        <EmptyPlaceholder
          message={t("client.quoteRequests.emptyPlaceholder")}
        />
      )}
    </Container>
  );
};

export default withRouter(withDashboardContext(ClientJobQuoteRequests));
