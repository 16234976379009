import { TFunction } from "i18next";
import { compact } from "lodash";
import {
  ClientPhase,
  ClientType,
  CreateClientFormFields,
} from "../../../models/client";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { STATES_OPTIONS } from "../../../utils/options";

export function createClientFormFields(
  t: TFunction,
  onPlaceSelect: UpdateTextInputFn,
  onClientTypeSelect: (value: string | string[]) => void,
  clientType: ClientType
): GenericFormFields<CreateClientFormFields> {
  return {
    business: compact([
      {
        type: "default-select",
        label: t("contacts.clientType"),
        placeholder: t("contacts.clientType"),
        valueKey: "type",
        showError: true,
        selectProps: {
          options: [
            {
              value: ClientType.INDIVIDUAL,
              label: t("contacts.individual"),
            },
            {
              value: ClientType.BUSINESS,
              label: t("contacts.business"),
            },
          ],
          onValueChange: onClientTypeSelect,
        },
        inputProps: {
          required: true,
        },
      },
      clientType === ClientType.BUSINESS
        ? {
            type: "text",
            label: t("contacts.businessName"),
            placeholder: t("contacts.businessName"),
            valueKey: "business_name",
            showError: true,
            inputProps: {
              type: "text",
              required: true,
            },
          }
        : null,
      clientType === ClientType.BUSINESS
        ? {
            type: "text",
            label: t("company.abnNumber"),
            placeholder: t("company.abnNumber"),
            valueKey: "abn",
            showError: true,
            inputProps: {
              type: "text",
            },
          }
        : null,
    ]),
    names: [
      {
        type: "text",
        label: t("contacts.firstName"),
        placeholder: t("placeholders.firstName"),
        valueKey: "first_name",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.lastName"),
        placeholder: t("placeholders.lastName"),
        valueKey: "last_name",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "select",
        label: t("contacts.phase"),
        placeholder: t("placeholders.phase"),
        valueKey: "phase",
        showError: true,
        autocompleteProps: {
          options: [
            {
              value: ClientPhase.LEAD,
              label: t("contacts.lead"),
            },
            {
              value: ClientPhase.PROSPECT,
              label: t("contacts.prospect"),
            },
            {
              value: ClientPhase.CUSTOMER,
              label: t("contacts.customer"),
            },
          ],
        },
        inputProps: {
          required: true,
        },
      },
    ],
    phone: [
      {
        type: "text",
        label: t("contacts.primaryPhone"),
        placeholder: t("placeholders.primaryPhone"),
        valueKey: "phone",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.secondaryPhone"),
        placeholder: t("placeholders.secondaryPhone"),
        valueKey: "phone_secondary",
        inputProps: {
          type: "text",
        },
      },
      {
        type: "text",
        label: t("contacts.email"),
        placeholder: t("placeholders.email"),
        valueKey: "email",
        showError: true,
        inputProps: {
          type: "email",
          required: true,
        },
      },
    ],
    address: [
      {
        type: "place-autocomplete",
        label: t("contacts.address"),
        placeholder: t("placeholders.addressPrimary"),
        valueKey: "address",
        textInputProps: {
          onValueChange: onPlaceSelect,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.addressSecondary"),
        placeholder: t("placeholders.addressSecondary"),
        valueKey: "address2",
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    location: [
      {
        type: "select",
        label: t("contacts.state"),
        placeholder: t("placeholders.state"),
        valueKey: "state",
        autocompleteProps: {
          options: STATES_OPTIONS,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.city"),
        placeholder: t("placeholders.city"),
        valueKey: "city",
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.postcode"),
        placeholder: t("placeholders.postcode"),
        valueKey: "postcode",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
  };
}
