import React, { useState } from "react";
import "./styles.scss";
import Icon from "../../icons/Icon";
import { useDropzone } from "react-dropzone";
import Avatar from "../../avatar";

type AvatarUploaderProps = {
  icon?: string;
  outlined?: boolean;
  onUpload?: (file: File[]) => void;
  width?: string;
  height?: string;
  imageUrl?: string;
  file?: File | null;
  contained?: boolean;
};

const AvatarUploader: React.FC<AvatarUploaderProps> = (props) => {
  const {
    outlined,
    icon,
    onUpload,
    file,
    width,
    height,
    imageUrl,
    contained,
  } = props;

  const [imgUrl, setUrl] = useState("");

  React.useEffect(() => {
    if (!imageUrl) {
      return;
    }
    setUrl(imageUrl);
  }, [imageUrl]);

  React.useEffect(() => {
    if (!file) {
      return;
    }

    const url = URL.createObjectURL(file);
    setUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file]);

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      onUpload && onUpload(acceptedFiles);
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const styles = React.useMemo(
    () => ({
      width,
      height,
    }),
    [width, height]
  );

  return (
    <div {...getRootProps()} className="avatar-uploader" style={styles}>
      <div className="avatar-preview">
        {imgUrl && (
          <Avatar
            url={imgUrl}
            rounded
            width="100%"
            height="100%"
            contained={contained}
          />
        )}
      </div>
      <input {...getInputProps()} />
      {icon && !imageUrl && <Icon name={icon} outlined={outlined} />}
    </div>
  );
};

export default AvatarUploader;
