import { gql } from "@apollo/client";
import { CLAIM_FRAGMENT } from "./fragments";

export const LIST_CLAIMS = gql`
  query($jobId: ID) {
    getJobProgressClaims(jobId: $jobId) {
      _id
      status
      invNumber
      amount
      retainedAmount
      variationAmount
      invoiceSubTotal
      description
      createdAt
      claimDate
      dueDate
      isOverdue
    }
  }
`;

export const GET_CLAIM = gql`
  query($jobId: ID, $progressClaimId: ID) {
    getJobProgressClaim(jobId: $jobId, progressClaimId: $progressClaimId) {
      ...ProgressClaimFragment
    }
  }
  ${CLAIM_FRAGMENT}
`;

export const GET_CLAIM_PREVIEW = gql`
  query($jobId: ID, $progressClaimId: ID, $printView: EnumProgressClaimView) {
    getJobProgressClaimPreview(
      jobId: $jobId
      progressClaimId: $progressClaimId
      printView: $printView
    ) {
      _id
      pdf
    }
  }
`;

export const GET_CLAIM_STATEMENT = gql`
  query($jobId: ID!, $progressClaimIds: [ID]!) {
    getJobProgressClaimStatement(
      jobId: $jobId
      progressClaimIds: $progressClaimIds
    ) {
      _id
      pdf
    }
  }
`;

export const GET_UNCLAIMED_ITEMS = gql`
  query($jobId: ID!) {
    getJobUnclaimedItems(jobId: $jobId) {
      costingItems {
        _id
        UOM
        cost
        real_cost
        name
        quantity
      }
      purchaseOrderReceipts {
        _id
        reference
        dateReceived
        hasGST
        subTotal
        GST
        total
        receiptDocuments {
          _id
          name
          url
        }
        purchaseOrder {
          _id
          orderNumber
          reference
          status
          date
          contactNumber
          hasGST
          subTotal
          GST
          total
          supplier {
            _id
            business_name
            contact_person
          }
        }
        items {
          _id
          itemNumber
          name
          UOM
          cost
          margin_amount
          quantity
        }
      }
    }
  }
`;
