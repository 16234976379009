import React from "react";
import Attachment from "../../../generic-form/inputs/rich-editor/custom-buttons/attachment";
import EmojiPickerTool from "../emoji-picker";
import { Button } from "react-bootstrap";
import Icon from "../../../icons/Icon";
import { EmojiData } from "emoji-mart";

type ToolbarProps = {
  onSend: () => void;
  showIcon?: boolean;
  onEmojiSelect: (emoji: EmojiData) => void;
  sendBtnClasses: string;
  sendBtnText?: string;
  onFilesSelect?: (files: File[]) => void;
  sendBtnDisabled?: boolean;
};

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const {
    onSend,
    showIcon,
    onEmojiSelect,
    sendBtnClasses,
    sendBtnText,
    onFilesSelect,
    sendBtnDisabled,
  } = props;

  return (
    <div className="compose-tools">
      <Attachment onSelect={onFilesSelect} />
      {/* <EmojiPickerTool onSelect={onEmojiSelect} /> */}
      <Button
        variant="primary"
        className={sendBtnClasses}
        onClick={onSend}
        disabled={sendBtnDisabled}
      >
        {showIcon && <Icon name="send" />}
        {sendBtnText}
      </Button>
    </div>
  );
};

export default Toolbar;
