import React, { ReactElement } from "react";

import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import { compact } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import Icon from "../../../components/icons/Icon";
import ReportCard, {
  ReportCardControlsEnum,
} from "../../dashboard/report-card";
import DashboardCardFooter from "../../../components/dashboard/card/DashboardCardFooter";
import { SiteDiary } from "../../../graphql/types/models/site-diary";
import { Media } from "../../../models/media";
import EntryListItem from "./EntryListItem";
import printStyles from "./printStyles";
import CustomReportCard from "../../dashboard/report-card/custom-report-card";
import "./styles.scss";

export type SiteDiaryItemProps = {
  title: string;
  siteDiary: SiteDiary;
  onEdit?: () => void;
  onDelete?: () => void;
  onClone?: () => void;
  onShare?: () => void;
  isDisabled?: boolean;
  isClientItem?: boolean;
  isGlobalView?: boolean;
};

export type SiteDiaryAttachment = {
  name: string;
  size: number;
};

export enum SiteDiaryItemControlsEnum {
  edit = "edit",
  clone = "clone",
  delete = "delete",
  share = "share",
}

const SiteDiaryItem: React.FC<SiteDiaryItemProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    title,
    siteDiary,
    onEdit,
    onDelete,
    onClone,
    onShare,
    isDisabled = false,
    isClientItem = false,
    isGlobalView = false,
  } = props;

  const printRef = React.useRef<HTMLDivElement | null>(null);

  const footer = React.useMemo(
    () =>
      !isGlobalView && !isClientItem ? (
        <DashboardCardFooter className="site-diary-footer">
          <ReactToPrint
            pageStyle={printStyles}
            trigger={() => (
              <Button variant="secondary" className="button large info">
                <Icon name="file_download" />
                {t("common.export")}
              </Button>
            )}
            content={() => printRef.current}
          />
        </DashboardCardFooter>
      ) : null,
    [t, isClientItem]
  );

  const badge = React.useMemo(
    () =>
      siteDiary.is_client_shared && !isClientItem
        ? t("siteDiary.sharedWithClient")
        : "",
    [t, siteDiary, isClientItem]
  );

  const handleViewJob = React.useCallback(() => {
    if (!siteDiary.job) return;
    const url = generatePath("/jobs/:id/site-diary/:diaryId", {
      id: siteDiary.job._id,
      diaryId: siteDiary._id,
    });
    history.push(url);
  }, [siteDiary]);

  const controls = React.useMemo(() => {
    return compact([
      onEdit && !isDisabled && !siteDiary.template?.is_deleted
        ? {
            id: "edit",
            label: t("common.edit"),
            icon: "edit",
            name: ReportCardControlsEnum.edit,
            outlined: true,
            onClick: onEdit,
          }
        : null,
      onShare
        ? {
            id: "share",
            label: t("common.share"),
            icon: "share",
            name: ReportCardControlsEnum.share,
            outlined: true,
            onClick: onShare,
          }
        : null,
      onClone && !isDisabled && !siteDiary.template?.is_deleted
        ? {
            id: "clone",
            label: t("common.clone"),
            icon: "content_copy",
            name: ReportCardControlsEnum.clone,
            outlined: true,
            onClick: onClone,
          }
        : null,
      onDelete && !isDisabled
        ? {
            id: "delete",
            label: t("common.delete"),
            icon: "delete",
            name: ReportCardControlsEnum.delete,
            outlined: true,
            onClick: onDelete,
          }
        : null,
    ]);
  }, [siteDiary, onDelete, onShare, onEdit, onClone, isDisabled]);

  const buttons = React.useMemo(() => {
    if (!isGlobalView) return [];
    return [
      {
        id: "edit",
        label: t("siteDiary.viewInJob"),
        name: "viewJob",
        onClick: handleViewJob,
      },
    ];
  }, [isGlobalView, handleViewJob]);

  return (
    <CustomReportCard
      title={title}
      badge={badge}
      footer={footer}
      controls={controls}
      buttons={buttons}
      withoutControls={!controls.length}
    >
      <EntryListItem siteDiary={siteDiary} />

      <div ref={printRef}>
        <EntryListItem siteDiary={siteDiary} print />
      </div>
    </CustomReportCard>
  );
};

export default SiteDiaryItem;
