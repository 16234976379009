import { gql } from "@apollo/client";

export const SAVE_QUOTE_COSTING_AS_TEMPLATE = gql`
  mutation($salesQuoteId: ID!, $templateName: String!) {
    result: saveSalesQuoteCostingsAsTemplate(
      salesQuoteId: $salesQuoteId
      templateName: $templateName
    ) {
      _id
    }
  }
`;
