import React from "react";
import { map } from "lodash";
import DetailsCardListItem from "./DetailsCardListItem";

export type DetailsCardDataItem = {
  icon?: string;
  title?: string;
  text: string | JSX.Element | JSX.Element[];
};

type DetailsCardListProps = {
  items: DetailsCardDataItem[];
};

const DetailsCardList: React.FC<DetailsCardListProps> = ({ items }) => {
  return (
    <ul className="details-card-list">
      {map(items, (item, index) => (
        <DetailsCardListItem
          key={index}
          text={item.text}
          icon={item.icon}
          title={item.title}
        />
      ))}
    </ul>
  );
};

export default DetailsCardList;
