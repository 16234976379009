import { useMutation } from "@apollo/client";
import React from "react";
import { GET_DOCUMENT_DOWNLOAD_URL } from "../graphql/queries/documents/mutations";
import { GetDocumentDownloadUrlResponse } from "../graphql/types/models/documents";
import { b64toBlob } from "../utils/pdf";

export function useDownloadFile() {
  const downloadFile = React.useCallback(
    (base64Data: string, fileName: string) => {
      const blob = b64toBlob(base64Data);
      const file = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = file;
      downloadLink.download = fileName;

      setTimeout(() => {
        URL.revokeObjectURL(file);
      }, 300);

      return downloadLink.click();
    },
    []
  );

  return { downloadFile };
}

export function useDownloadFileFromURL(sendUrlForFileTypes?: string[]) {
  const downloadRef = React.useRef<Window | null>();
  const [getDownloadUrl] = useMutation<GetDocumentDownloadUrlResponse>(
    GET_DOCUMENT_DOWNLOAD_URL,
    {
      onCompleted: ({ getDocumentDownloadURL }) => {
        if (downloadRef.current && getDocumentDownloadURL.url) {
          if (sendUrlForFileTypes?.includes(getDocumentDownloadURL.type)) {
            return;
          }
          downloadRef.current.location = getDocumentDownloadURL.url;
        }
      },
    }
  );

  const downloadFileFromURL = React.useCallback(async (documentId: string) => {
    downloadRef.current = window.open(undefined, "_self");

    const res = await getDownloadUrl({
      variables: {
        documentId,
      },
    });

    return res as { data: GetDocumentDownloadUrlResponse };
  }, []);

  return { downloadFileFromURL };
}
