import classNames from "classnames";
import map from "lodash/map";
import React from "react";
import Table from "react-bootstrap/Table";

export type ListTableRow = {
  label: any;
  value: any;
};

type ListTableProps = {
  title: string;
  rows: ListTableRow[];
  isDataLoading?: boolean;
  showCountTitle?: boolean;
  tableClass?: string;
  onRowClick?: (row: ListTableRow, index: number) => void;
};

const ListTable: React.FC<ListTableProps> = ({
  title,
  rows,
  isDataLoading,
  tableClass,
  onRowClick,
}) => {
  const handleRowClick = React.useCallback(
    (row: ListTableRow, index: number) => () => {
      onRowClick && onRowClick(row, index);
    },
    [onRowClick]
  );

  const renderTableBody = () => {
    return map(rows, (row: ListTableRow, index: number) => (
      <tr
        key={`row-${index}`}
        className={classNames("table-row", { clickable: !!onRowClick })}
        onClick={handleRowClick(row, index)}
      >
        <td className={classNames("field-text medium")}>{row.label}</td>
        <td className={classNames("field-text text-right")}>{row.value}</td>
      </tr>
    ));
  };

  const headerClasses = classNames("table-header", {
    "table-header--disabled": isDataLoading,
  });

  return (
    <Table
      className={classNames("table table-outline", tableClass)}
      hover
      responsive
    >
      <thead className={headerClasses}>
        <tr>
          <th className="field-text text-center" colSpan={2}>
            {title}
          </th>
        </tr>
      </thead>
      <tbody className="table-body">{renderTableBody()}</tbody>
    </Table>
  );
};

export default ListTable;
