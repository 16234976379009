import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Icon from "../../icons/Icon";
import "./styles.scss";

type EntryGroupProps = {
  name: string;
  isOpen?: boolean;
};

const EntryGroup: React.FC<EntryGroupProps> = ({
  name,
  isOpen: isOpenDefault,
  children,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(!!isOpenDefault);
  const containerClasses = classNames(
    {
      "entry-group-list--open": isOpen,
    },
    "entry-group-list"
  );

  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return (
    <div className={containerClasses}>
      <div className="field-text entry-group-item">
        <b className="entry-group-item--title" title={name} onClick={toggle}>
          {name}
        </b>
        <div className="d-flex h-100" style={{ flex: 1 }} onClick={toggle} />
        <div className="d-flex" onClick={toggle}>
          <Icon name={isOpen ? "expand_less" : "expand_more"} />
        </div>
      </div>

      {isOpen && children}
    </div>
  );
};

export default EntryGroup;
