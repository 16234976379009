import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { compact, take } from "lodash";
import CardPlaceholder from "../../dashboard/card-placeholder";
import DetailsCard, { DetailsCardAction } from "../../dashboard/details-card";
import { DetailsCardDataItem } from "../../dashboard/details-card/DetailsCardList";
import { JobDetails, JobInvoiceType, JobType } from "../../../models/job";
import { getFullAddress } from "../../../utils/text";

type JobDetailsProps = {
  job: JobDetails;
  disabled: boolean;
  onEdit: () => void;
  buttonDisabled: boolean;
  isReadonly: boolean;
};

const JobDetailsCard: React.FC<JobDetailsProps> = (props) => {
  const { job, disabled, onEdit, buttonDisabled, isReadonly } = props;

  const { t } = useTranslation();

  const cardData: DetailsCardDataItem[] = React.useMemo(() => {
    const items = compact([
      {
        icon: "badge",
        text: job.name,
      },
      {
        icon: "place",
        text: getFullAddress(job),
      },
      {
        icon: "calendar_today",
        text: `${moment(job.startDate).format("D MMM YYYY")} - ${moment(
          job.endDate
        ).format("D MMM YYYY")}`,
      },
      {
        icon: "handshake",
        text: t(`jobs.types.${job.type}`),
        title: t("jobs.jobType"),
      },
      job.contractTotal
        ? {
            icon: "attach_money",
            text: t("jobs.contractAmountInc", {
              amount:
                Number(job.contractTotal || 0) + Number(job.contractTotalGST),
            }),
            title: t("jobs.contractTotalInc"),
          }
        : null,
      job.type === JobType.COST_PLUS
        ? {
            icon: "sell",
            text: t("costing.markupPercentReverse", { percent: job.markup }),
            title: t("costing.markup"),
          }
        : null,
      job.invoiceType === JobInvoiceType.Retention
        ? {
            icon: "receipt_long",
            text: t(`jobs.invoiceTypes.${job.invoiceType}`),
            title: t("jobs.invoiceType"),
          }
        : null,
    ]);
    if (isReadonly) return take(items, 3);
    return items;
  }, [job, isReadonly]);

  const cardActions: DetailsCardAction[] = React.useMemo(
    () =>
      isReadonly
        ? []
        : [
            {
              disabled: buttonDisabled,
              title: t("common.edit"),
              onClick: onEdit,
              className: "button info",
            },
          ],
    [t, onEdit, isReadonly]
  );

  if (disabled) {
    return <CardPlaceholder />;
  }
  return (
    <DetailsCard
      fullHeight
      title={t("common.details")}
      data={cardData}
      actions={cardActions}
    />
  );
};

export default JobDetailsCard;
