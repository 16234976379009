import { LatLngLiteral } from "leaflet";
import React from "react";
import "react-leaflet-fullscreen/dist/styles.css";

import { SalesQuoteFile } from "../../../models/salesQuote";
import PlanViewer from "../plan-viewer";
import PlanViewerToolbar from "../plan-viewer/PlanViewerToolbar";
import "./style.scss";
import classNames from "classnames";

type CalibrateScaleViewerProps = {
  planFile: SalesQuoteFile;
  canDraw: boolean;
  onCalibrationUpdate: (
    data: LatLngLiteral[],
    isVerticalPolyline?: boolean
  ) => void;
  onBoundsChange?: (bounds: number[]) => void;
};

const CalibrateScaleViewer: React.FC<CalibrateScaleViewerProps> = ({
  planFile,
  canDraw,
  onCalibrationUpdate,
  onBoundsChange,
}) => {
  const polyline = React.useRef<any | null>(null);
  const layerRef = React.useRef<any | null>(null);

  const [activeVerticalPolyline, setVerticalPolyline] = React.useState(false);

  const handleCreate = React.useCallback(
    (e) => {
      // lock to horizontal line
      const latLngs = e.layer.getLatLngs();
      activeVerticalPolyline
        ? (latLngs[1].lng = latLngs[0].lng)
        : (latLngs[1].lat = latLngs[0].lat);

      e.layer.setLatLngs(latLngs);

      layerRef.current = e.layer;

      // e.layer.editing.enable();
      onCalibrationUpdate(e.layer.editing.latlngs[0], activeVerticalPolyline);
      setVerticalPolyline(false);
    },
    [activeVerticalPolyline, onCalibrationUpdate]
  );

  const handleEdit = React.useCallback(
    (e) => {
      onCalibrationUpdate(e.layer.editing.latlngs[0]);
    },
    [onCalibrationUpdate]
  );

  const handleOnMounted = React.useCallback((e) => {
    layerRef.current = e.layer;
    polyline.current = e._toolbars.draw._modes.polyline;
    polyline.current?.handler.enable();
  }, []);

  const clearLayer = (e?: any, isVerticalPolyline?: boolean) => {
    if (layerRef.current) {
      if (!isVerticalPolyline) {
        setVerticalPolyline(false);
      }
      layerRef.current.editing.disable();
      layerRef.current.remove();
      polyline.current?.handler.enable();
    }
  };

  const handleOnVercitalPolyline = () => {
    setVerticalPolyline(true);
    clearLayer(undefined, true);
  };

  React.useEffect(() => {
    clearLayer();
  }, [planFile]);

  // workaround to fix toolbar draw options not updating when `canDraw` changes
  const renderToolbar = React.useCallback(
    (drawable: boolean) => {
      if (drawable !== canDraw) return null;
      return (
        <PlanViewerToolbar
          onMounted={handleOnMounted}
          onCreated={handleCreate}
          onEdited={handleEdit}
          onPolylineClick={clearLayer}
          onVerticalPolylineClick={handleOnVercitalPolyline}
          draw={{
            rectangle: false,
            polygon: false,
            marker: false,
            polyline: drawable
              ? {
                  allowIntersection: false,
                  showLength: false,
                  shapeOptions: {
                    interactive: false,
                    clickable: false,
                    stroke: true,
                    lineCap: "round",
                    color: "#3FBB64",
                  },
                  maxPoints: 2,
                }
              : false,
          }}
          editable={false}
        />
      );
    },
    [canDraw, activeVerticalPolyline]
  );

  return (
    <PlanViewer
      planFile={planFile}
      onBoundsChange={onBoundsChange}
      className={classNames("scale-section", {
        "scale-section-active-vertical-polyline": activeVerticalPolyline,
      })}
    >
      {renderToolbar(true)}
      {renderToolbar(false)}
    </PlanViewer>
  );
};

export default CalibrateScaleViewer;
