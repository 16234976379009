import React from "react";
import { Helmet } from "react-helmet";
import { compact, filter } from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";

import DetailsCard, {
  DetailsCardAction,
} from "../../../components/dashboard/details-card";
import { DetailsCardDataItem } from "../../../components/dashboard/details-card/DetailsCardList";
import EditCompanyModal from "../../../components/settings/company/edit-company";
import EditInvoiceModal from "../../../components/settings/company/edit-invoice";
import EditPurchaseOrderModal from "../../../components/settings/company/edit-purchase-order";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { GET_BUSINESS } from "../../../graphql/queries/business/queries";
import { BusinessResponse } from "../../../graphql/types/models/business";

import "./styles.scss";

const CompanyOverview: React.FC = () => {
  const { t } = useTranslation();

  const editRef = React.useRef<ModalDisplayRef>(null);
  const editInvoiceRef = React.useRef<ModalDisplayRef>(null);
  const editOrderRef = React.useRef<ModalDisplayRef>(null);

  const { data: businessData } = useQuery<BusinessResponse>(GET_BUSINESS);

  const business = React.useMemo(() => businessData?.getBusiness, [
    businessData,
  ]);

  const detailsCardData: DetailsCardDataItem[] = React.useMemo(() => {
    if (!business) {
      return [];
    }

    return filter(
      [
        {
          icon: "business",
          text: business.name,
          title: t("company.companyName"),
        },
        {
          icon: "shield",
          text: business.abn ? t("company.abnNumberValue", business) : "",
          title: t("company.abnNumber"),
        },
        {
          icon: "room",
          text: compact([
            business.address,
            business.address2,
            business.city,
            business.state,
            business.postcode,
          ]).join(", "),
          title: t("company.address"),
        },
        {
          icon: "mail",
          text: business.email,
          title: t("authentication.emailAddress"),
        },
        {
          icon: "local_phone",
          text: business.phone_number,
          title: t("company.phone"),
        },
      ],
      (o) => !!o.text
    );
  }, [business]);

  const invoiceCardData: DetailsCardDataItem[] = React.useMemo(() => {
    return filter(
      [
        {
          icon: "account_balance",
          text: business?.bankName
            ? business.bankName
            : t("company.unsetBankName"),
          title: t("company.bankDetails"),
        },
        {
          icon: "badge",
          text: business?.accountName
            ? business.accountName
            : t("company.unsetAccountName"),
          title: t("company.accountName"),
        },
        {
          icon: "payments",
          text:
            business?.BSB || business?.accountNumber
              ? compact([
                  business?.BSB
                    ? t("company.BSBValue", business)
                    : t("company.unsetBSB"),
                  business?.accountNumber
                    ? t("company.accountNumberValue", business)
                    : t("company.unsetAccountNumber"),
                ]).join(" ")
              : t("company.unsetBankAccount"),
          title: t("company.bankDetails"),
        },
        {
          icon: "today",
          text: business?.defaultDueDays
            ? t("company.defaultDueDays", business)
            : "",
          title: t("company.dueDate"),
        },
        {
          icon: "format_quote",
          text: business?.paymentTerms || "",
          title: t("company.paymentTerms"),
        },
      ],
      (o) => !!o.text
    );
  }, [business]);

  const orderCardData: DetailsCardDataItem[] = React.useMemo(() => {
    return filter(
      [
        {
          icon: "format_quote",
          text: business?.richOrderTerms?.text
            ? business?.richOrderTerms?.text?.replace(/\u0001/g, "\n")
            : t("company.unsetOrderTerms"),
          title: t("company.orderTerms"),
        },
      ],
      (o) => !!o.text
    );
  }, [business]);

  const detailsCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        // disabled: supplierLoading,
        title: t("common.edit"),
        onClick: () => editRef.current?.show(true),
        className: "button info",
      },
    ],
    [t, editRef]
  );

  const invoiceCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("common.edit"),
        onClick: () => editInvoiceRef.current?.show(true),
        className: "button info",
      },
    ],
    [t, editInvoiceRef]
  );

  const orderCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("common.edit"),
        onClick: () => editOrderRef.current?.show(true),
        className: "button info",
      },
    ],
    [t, editOrderRef]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.company")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SETTINGS.COMPANY} />
      <Row className="company-details">
        <Col md={6} xs={12} className="pb-sm-5 pb-lg-0">
          <DetailsCard
            fullHeight
            title={t("common.details")}
            data={detailsCardData}
            actions={detailsCardActions}
          >
            <div className="profile-avatar text-center pb-4">
              <img src={business?.logo?.url} />
            </div>
          </DetailsCard>
        </Col>
        <Col md={6} xs={12} className="pb-sm-5 pb-lg-0">
          <div className="pb-sm-4">
            <DetailsCard
              fullHeight
              title={t("company.invoiceSettings")}
              data={invoiceCardData}
              actions={invoiceCardActions}
            ></DetailsCard>
          </div>
          <DetailsCard
            fullHeight
            title={t("company.orderSettings")}
            data={orderCardData}
            actions={orderCardActions}
          ></DetailsCard>
        </Col>
        {/* <Col lg={8} xs={12} className="pb-sm-5 pb-lg-0">
          <DashboardCard className="category-select-card">
            <DashboardCardHeader className="text-capitalize justify-content-center p-0">
              {t("company.emailCapture")}
            </DashboardCardHeader>
            <DashboardCardBody>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("company.emailForward", {
                    email: business?.incomingEmail,
                  }),
                }}
              />
            </DashboardCardBody>
          </DashboardCard>
        </Col> */}
      </Row>
      {business && (
        <>
          <EditCompanyModal ref={editRef} company={business} />
          <EditInvoiceModal ref={editInvoiceRef} company={business} />
          <EditPurchaseOrderModal ref={editOrderRef} company={business} />
        </>
      )}
    </Container>
  );
};
export default CompanyOverview;
