import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { map } from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";

import { notify } from "../../../components/notification";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { LIST_BUILDINGS_TEMPLATES } from "../../../graphql/queries/estimation-templates/queries";
import {
  EstimationTemplate,
  EstimationTemplateDeletePayload,
  EstimationTemplateListResponse,
  EstimationTemplateType,
} from "../../../graphql/types/models/estimation-templates";
import { DELETE_BUILDING_TEMPLATE } from "../../../graphql/queries/estimation-templates/mutations";

const EstimationTemplatesOverview: React.FC = (props) => {
  const { t } = useTranslation();

  const {
    data: estimationTemplates,
    loading: dataLoading,
    refetch: refetchEstimationTemplates,
  } = useQuery<EstimationTemplateListResponse>(LIST_BUILDINGS_TEMPLATES, {
    fetchPolicy: "cache-and-network",
  });

  const [deleteBuildingTemplate, { loading: templateDeleting }] = useMutation<
    EstimationTemplateListResponse,
    EstimationTemplateDeletePayload
  >(DELETE_BUILDING_TEMPLATE, {
    onCompleted: () => {
      refetchEstimationTemplates();
      notify({
        title: t("estimationTemplate.removeTemplate"),
        content: t("estimationTemplate.success.removeTemplate"),
      });
      deleteConfirmRef.current?.show(false);
    },
    onError: () => {
      notify({
        error: true,
        title: t("estimationTemplate.removeTemplate"),
        content: t("estimationTemplate.error.removeTemplate"),
      });
    },
  });

  const deleteConfirmRef = useRef<ConfirmDialogRef>();

  const handleDelete = React.useCallback(
    (templateId: string) => () => {
      if (!templateId) {
        return;
      }
      deleteBuildingTemplate({
        variables: {
          templateId: templateId,
        },
      });
    },
    [deleteBuildingTemplate]
  );

  const openRemoveDialog = React.useCallback(
    (row?: EstimationTemplate) => {
      if (row) {
        deleteConfirmRef.current?.show(true, handleDelete(row._id));
      }
    },
    [handleDelete]
  );

  const estimationTemplatesData: TableCardData<EstimationTemplate> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("estimationTemplate.name"),
        },
        {
          valueKey: "type",
          title: t("estimationTemplate.type"),
          formatValue: (row: any, column: any, value: string) =>
            value === EstimationTemplateType.Custom
              ? t("common.custom")
              : t("common.system"),
        },
        {
          valueKey: "updatedAt",
          title: t("estimationTemplate.lastUpdated"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
      ],
      rows: map(
        estimationTemplates?.listBuildingTemplates,
        (estimationTemplate: EstimationTemplate) => ({
          cells: estimationTemplate,
        })
      ),
    };
  }, [estimationTemplates?.listBuildingTemplates, t]);

  const tableRowActions: TableRowActionData<
    EstimationTemplate
  >[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "document-list",
        options: [
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openRemoveDialog,
          },
        ],
        shouldRender: (row) => row.type !== EstimationTemplateType.System,
      },
    ],
    [openRemoveDialog, t]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet
        title={t("navigation.settings.templatesSection.estimationTemplates")}
      />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.ESTIMATION_TEMPLATES
        }
      />
      <ConfirmDialog
        ref={deleteConfirmRef}
        disabled={templateDeleting}
        title={t("estimationTemplate.removeTemplate")}
        confirm={t("common.delete")}
      >
        <div className="field-text">
          {t("estimationTemplate.removeTemplateMessage")}
        </div>
      </ConfirmDialog>

      <TableCard
        isDataLoading={dataLoading}
        fullHeight
        overflowXHidden
        data={estimationTemplatesData}
        rowActions={tableRowActions}
        alignEnd
      />
    </Container>
  );
};

export default EstimationTemplatesOverview;
