import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function customiseQuoteSchema(t: TFunction) {
  return yup.object().shape({
    templateId: yup.string().required(),
    // is_system: yup.boolean().equals([false])
  });
}
