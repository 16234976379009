import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { TodoChecklistTemplates } from "../../../models/task";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import saveChecklistAsTemplateSchema from "./SaveAsTemplate.schema";

import { saveChecklistAsTemplateFields } from "./utils";

type SaveChecklistAsTemplateModalProps = {
  onSubmit: (name: string, type: string) => void;
  onClose?: () => void;
  templatesData: TodoChecklistTemplates[];
  template?: TodoChecklistTemplates | null;
  isChecklistsSection?: boolean;
  isCloneAction?: boolean;
};

const SaveChecklistAsTemplateModal: React.FC<SaveChecklistAsTemplateModalProps> = (
  {
    onSubmit,
    templatesData,
    template,
    onClose,
    isChecklistsSection,
    isCloneAction,
  },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<{ name: string; type: string }>
  >({});

  React.useEffect(() => {
    if (!templatesData) return;
    setFormFields(saveChecklistAsTemplateFields(t, isChecklistsSection));
  }, [t, templatesData]);

  const handleSubmit = React.useCallback(
    (values: { name: string; type: string }) => {
      onSubmit(values.name, values.type);
      handleOnClose();
    },
    [onSubmit]
  );
  const handleOnClose = React.useCallback(() => {
    onClose?.();
    hide();
  }, [hide, onClose]);

  const validationSchema = React.useMemo(
    () =>
      saveChecklistAsTemplateSchema(
        t,
        templatesData,
        Boolean(template),
        isChecklistsSection
      ),
    [t, templatesData, template, isChecklistsSection]
  );

  if (!templatesData) return null;

  return (
    <CreateEntityModal
      validationSchema={validationSchema}
      className="checklist-template"
      title={
        template
          ? !isCloneAction
            ? t("common.editTemplate")
            : t("tasks.saveAsTemplate")
          : t("tasks.saveAsTemplate")
      }
      show={shouldShow}
      data={{ name: template?.name || "", type: template?.type || "" }}
      submitText={t("common.save")}
      onSubmit={handleSubmit}
      onClose={handleOnClose}
      fields={formFields}
    />
  );
};

export default forwardRef(SaveChecklistAsTemplateModal);
