import React from "react";

import "./styles.scss";
import classNames from "classnames";

type DashboardCardProps = {
  className?: string;
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames("dashboard-card", className)}>{children}</div>
  );
};

export default DashboardCard;
