import React from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_JOB_CONTACTS } from "../graphql/queries/job/queries";
import { GetJobContactsResponse } from "../graphql/types/models/job";

export function useJobContacts(jobId?: string) {
  const [getJobContacts, { data: jobContactList }] = useLazyQuery<
    GetJobContactsResponse
  >(GET_JOB_CONTACTS);

  const contacts = React.useMemo(
    () => jobContactList?.contacts?.map((c) => c.contact) || [],
    [jobContactList]
  );
  const contactsAccess = React.useMemo(() => {
    const contacts = jobContactList?.contacts?.filter((c) => c?.clientAccess);
    return contacts?.map((c) => c.contact) || [];
  }, [jobContactList]);

  React.useEffect(() => {
    if (jobId) {
      getJobContacts({
        variables: {
          jobId,
        },
      });
    }
  }, [jobId]);

  return { contacts, contactsAccess };
}
