import React from "react";
import { useJobs } from "./useJobs";
import Dropdown from "../components/dashboard/dropdown";
import { useTranslation } from "react-i18next";
import { chain } from "lodash";

type Filter = {
  id: string;
  name: string;
};

export function useDropdownJobs() {
  const [currentJob, setJob] = React.useState<Filter>({
    id: "",
    name: "",
  });
  const { jobs, jobsLoading } = useJobs();
  const { t } = useTranslation();

  const filterByJobsDropdownItems = React.useMemo(
    () =>
      [
        {
          id: "all",
          label: t("tasks.allJobs"),
          onClick: () =>
            setJob({
              id: "",
              name: "",
            }),
        },
      ].concat(
        chain(jobs)
          .map((job) => ({
            id: job._id,
            label: job.name,
            onClick: () =>
              setJob({
                id: job._id,
                name: job.name,
              }),
          }))
          .sortBy("label")
          .value()
      ),
    [jobs]
  );

  const renderDropdownJobs = () => {
    return (
      <Dropdown
        label={currentJob.name || t("tasks.allJobs")}
        icon="expand_more"
        id="filter-by-jobs"
        items={filterByJobsDropdownItems}
        alignRight
      />
    );
  };

  return { currentJob, renderDropdownJobs, jobsLoading, setJob };
}
