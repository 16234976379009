import React from "react";
import { ContextProps } from "react-leaflet";
import HeaderActionButton from "../../dashboard/header/HeaderActionButton";
import { useTranslation } from "react-i18next";
import QuoteHeader from "../quote-header";
import EstimationLocationHeader from "../../../containers/header/estimation-location-header";

type QuoteReportToolbarProps = ContextProps & {
  onModalToggle?: () => void;
  onZoomIn: () => void;
  onExpand: () => void;
  onZoomOut: () => void;
  onPrint: () => void;
  onCustomise?: () => void;
  onConvertToJob?: () => void;
};

const QuoteReportToolbar: React.FC<QuoteReportToolbarProps> = (props) => {
  const {
    onModalToggle,
    onZoomIn,
    onZoomOut,
    onExpand,
    onPrint,
    onCustomise,
    onConvertToJob,
  } = props;
  const { t } = useTranslation();

  return (
    <EstimationLocationHeader>
      <HeaderActionButton icon="zoom_out" onClick={onZoomOut} />
      <HeaderActionButton icon="zoom_in" onClick={onZoomIn} />
      <HeaderActionButton icon="Expand" svg onClick={onExpand} />
      <HeaderActionButton icon="print" outlined onClick={onPrint}>
        {t("common.print")}
      </HeaderActionButton>
      <HeaderActionButton icon="mail" outlined onClick={onModalToggle}>
        {t("common.send")}
      </HeaderActionButton>
      {!!onCustomise && (
        <HeaderActionButton icon="rule" outlined onClick={onCustomise}>
          {t("quotes.customise")}
        </HeaderActionButton>
      )}
      {!!onConvertToJob && (
        <HeaderActionButton
          icon="add_business"
          outlined
          onClick={onConvertToJob}
        >
          {t("quotes.convertToJob")}
        </HeaderActionButton>
      )}
    </EstimationLocationHeader>
  );
};

export default QuoteReportToolbar;
