import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ContactsClientContainer from "../contacts-client";
import ClientsOverviewContainer from "../clients-overview";
import SuppliersOverviewContainer from "../suppliers-overview";
import ContactsSupplierContainer from "../contacts-supplier";
import ContactsImportContainer from "../contacts-import";

const ContactsContainer = () => {
  return (
    <Switch>
      <Route path="/contacts/clients/:id" component={ContactsClientContainer} />
      <Route
        exact
        path="/contacts/clients"
        component={ClientsOverviewContainer}
      />
      <Route
        path="/contacts/suppliers/:id"
        component={ContactsSupplierContainer}
      />
      <Route
        exact
        path="/contacts/suppliers"
        component={SuppliersOverviewContainer}
      />
      <Route path="/contacts/map" component={ContactsImportContainer} />

      <Route exact path="/contacts">
        <Redirect to="/contacts/clients" />
      </Route>
    </Switch>
  );
};

export default ContactsContainer;
