import { TFunction } from "i18next";
import {
  SupplierListCategoryItem,
  SupplierModalFields,
} from "../../../models/supplier";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { STATES_OPTIONS } from "../../../utils/options";
import { SelectOption } from "../../generic-form/inputs/creatable-select";

export function createSuppliersFormFields(
  t: TFunction,
  categories: SelectOption[],
  onCategoryChange: (value: string) => void,
  onPlaceSelect: UpdateTextInputFn
): GenericFormFields<SupplierModalFields> {
  return {
    supplierData: [
      {
        type: "text",
        label: t("contacts.businessName"),
        placeholder: t("placeholders.businessName"),
        valueKey: "business_name",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("company.abnNumber"),
        placeholder: t("company.abnNumber"),
        valueKey: "abn",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "creatable-select",
        label: t("contacts.categoryName"),
        placeholder: t("placeholders.categoryName"),
        valueKey: "categoryId",
        creatableProps: {
          onCreateOption: onCategoryChange,
          autoSelectCreatedOption: true,
          options: categories,
        },
      },
    ],
    contact: [
      {
        type: "text",
        label: t("contacts.contactPerson"),
        placeholder: t("placeholders.contactPerson"),
        valueKey: "contact_person",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.email"),
        placeholder: t("placeholders.email"),
        valueKey: "email",
        showError: true,
        inputProps: {
          type: "email",
          required: true,
        },
      },
    ],
    phone: [
      {
        type: "text",
        label: t("contacts.primaryPhone"),
        placeholder: t("placeholders.primaryPhone"),
        valueKey: "phone_number",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.secondaryPhone"),
        placeholder: t("placeholders.secondaryPhone"),
        valueKey: "phone_secondary",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    address: [
      {
        type: "place-autocomplete",
        label: t("contacts.address"),
        placeholder: t("placeholders.addressPrimary"),
        valueKey: "address",
        textInputProps: {
          onValueChange: onPlaceSelect,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.addressSecondary"),
        placeholder: t("placeholders.addressSecondary"),
        valueKey: "address2",
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    city: [
      {
        type: "text",
        label: t("contacts.city"),
        placeholder: t("placeholders.city"),
        valueKey: "city",
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "select",
        label: t("contacts.state"),
        placeholder: t("placeholders.state"),
        valueKey: "state",
        autocompleteProps: {
          options: STATES_OPTIONS,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "text",
        label: t("contacts.postcode"),
        placeholder: t("placeholders.postcode"),
        valueKey: "postcode",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      // FAX can piss off back to 1990
      // {
      //   type: "text",
      //   label: t("contacts.fax"),
      //   placeholder: t("placeholders.faxNumber"),
      //   valueKey: "fax_number",
      //   inputProps: {
      //     type: "text",
      //     required: true,
      //   },
      // },
    ],
  };
}
