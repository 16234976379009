import React, { useState } from "react";
import "./styles.scss";
import Icon from "../../icons/Icon";
import { useDropzone } from "react-dropzone";
import Avatar from "../../avatar";

export type ImageUploaderProps = {
  icon?: string;
  outlined?: boolean;
  onUpload?: (file: File) => void;
  imageUrl?: string;
  width?: string;
  height?: string;
  valueKey?: {
    file?: File;
    url?: string;
  };
  readOnly?: boolean;
};

const ImageUploader: React.FC<ImageUploaderProps> = (props) => {
  const {
    outlined,
    icon = "file_upload",
    onUpload,
    imageUrl,
    width,
    height,
    valueKey,
    readOnly,
  } = props;

  const [imgUrl, setUrl] = useState("");

  React.useEffect(() => {
    if (!imageUrl) {
      return;
    }
    setUrl(imageUrl);
  }, [imageUrl]);

  React.useEffect(() => {
    if (!valueKey) {
      return;
    }
    if (valueKey.url) {
      setUrl(valueKey.url);
    }
    if (valueKey.file) {
      setUrl(URL.createObjectURL(valueKey.file));
    }
  }, [valueKey]);

  React.useEffect(() => {
    if (!valueKey && !imageUrl && imgUrl) {
      setUrl("");
    }
  }, [valueKey, imageUrl, imgUrl]);

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      setUrl(
        URL.createObjectURL(
          new Blob([acceptedFiles[0]], { type: acceptedFiles[0].type })
        )
      );
      onUpload && onUpload(acceptedFiles[0]);
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ["image/jpeg", "image/png"],
    multiple: false,
  });

  const styles = React.useMemo(
    () => ({
      width,
      height,
    }),
    [width, height]
  );

  return (
    <div {...getRootProps()} className="image-uploader" style={styles}>
      <div className="image-preview">
        {imgUrl && <Avatar url={imgUrl} width="100%" height="100%" />}
      </div>
      {!readOnly && <input {...getInputProps()} />}
      {icon && !valueKey && <Icon name={icon} outlined={outlined} />}
    </div>
  );
};

export default ImageUploader;
