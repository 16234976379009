import React from "react";
import "./styles.scss";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import ConversationsFooter from "./ConversationFooter";
import classNames from "classnames";
import ConversationEmptyBody from "./ConversationEmptyBody";
import Dropdown, { DropdownItem } from "../../dashboard/dropdown";
import { useTranslation } from "react-i18next";

type CommunicationListProps = {
  title: string | React.ReactElement;
  onNewClick?: () => void;
  className?: string;
  newButtonDisabled?: boolean;
  empty?: boolean;
  emptyMessage?: string;
  loading?: boolean;
  emptyButtonText?: string;
  footerButtonText?: string;
  hideAddBtn?: boolean;
  emptyBodyActions?: () => JSX.Element;
  dropdownItems?: DropdownItem[];
  dropdownWidth?: string;
};

const CommunicationList: React.FC<CommunicationListProps> = (props) => {
  const {
    empty,
    emptyMessage,
    title,
    children,
    onNewClick,
    className,
    newButtonDisabled,
    loading,
    emptyButtonText,
    footerButtonText,
    hideAddBtn = false,
    emptyBodyActions,
    dropdownItems,
    dropdownWidth = "250px",
  } = props;

  const [dropdownShown, setDropdownShown] = React.useState(false);

  const toggleDropdown = React.useCallback(() => {
    setDropdownShown(!dropdownShown);
  }, [dropdownShown]);

  const { t } = useTranslation();

  const cardClasses = classNames(className, "communication-list");

  return (
    <DashboardCard className={cardClasses}>
      <DashboardCardHeader
        className={classNames("communication-header", {
          "dropdown-container": dropdownItems,
        })}
      >
        <div>{title}</div>
        {dropdownItems && (
          <Dropdown
            isVisible={dropdownShown}
            handleToggle={toggleDropdown}
            label={t("common.options")}
            icon="expand_more"
            menuWidth={dropdownWidth}
            id="communication-dropdown"
            items={dropdownItems}
          />
        )}
      </DashboardCardHeader>

      <DashboardCardBody className="communication-body">
        {children}
        {(empty || loading) && (
          <ConversationEmptyBody
            emptyButtonText={emptyButtonText}
            loading={loading}
            emptyMessage={emptyMessage}
            onAddClick={onNewClick}
            disabled={newButtonDisabled}
            emptyBodyActions={emptyBodyActions}
          />
        )}
      </DashboardCardBody>

      {!empty && !loading && !hideAddBtn && (
        <ConversationsFooter
          hideButton={empty}
          onNewClick={onNewClick}
          disabled={newButtonDisabled}
          footerButtonText={footerButtonText}
        />
      )}
    </DashboardCard>
  );
};

export default CommunicationList;
