import { MutationUpdaterFn } from "@apollo/client";
import { GET_ACCOUNTING_INTEGRATION_ERRORS } from "./queries";
import {
  AccountingIntegrationErrorsResponse,
  ClearAccountingIntegrationErrorsResponse,
} from "../../types/models/integrations";

export const handleClearErrors: MutationUpdaterFn<ClearAccountingIntegrationErrorsResponse> = (
  cache,
  response
) => {
  if (response) {
    cache.writeQuery<AccountingIntegrationErrorsResponse>({
      query: GET_ACCOUNTING_INTEGRATION_ERRORS,
      data: {
        getAccountingIntegrationErrors: [],
      },
    });
  }
};
