import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../../utils/yup";
import { BulkPriceListAdjustPayload } from "../../../../models/price-list";

export default function createAdjustPriceListSchema(t: TFunction) {
  return yup.object().shape<BulkPriceListAdjustPayload>({
    amount: yup
      .number()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "priceList.percentAmount"
        )
      ),
  });
}
