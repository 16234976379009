import React from "react";
import { map } from "lodash";
import classNames from "classnames";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import QuoteRequestItem from "./QuoteRequestItem";
import "./styles.scss";
import { QuoteRequest } from "../../../graphql/types/models/quote-request";

export type SalesQuoteRequest = {
  _id: string;
  name: string;
  number: string;
  quoteRequests: QuoteRequest[];
};

type QuoteRequestListProps = {
  title: string | React.ReactElement;
  onNewClick?: () => void;
  className?: string;
  salesQuoteRequests?: SalesQuoteRequest[];
  selectedQuoteRequest: QuoteRequest | null;
  onSelect: (item: QuoteRequest) => void;
};

const QuoteRequestList: React.FC<QuoteRequestListProps> = (props) => {
  const {
    title,
    children,
    salesQuoteRequests,
    selectedQuoteRequest,
    className,
    onSelect,
  } = props;

  const renderItems = () => {
    return map(salesQuoteRequests, (salesQuoteRequest, index) => {
      return (
        <QuoteRequestItem
          salesQuoteRequest={salesQuoteRequest}
          onSelect={onSelect}
          selectedQuoteRequest={selectedQuoteRequest}
          isOpen={
            selectedQuoteRequest?.salesQuote?._id === salesQuoteRequest?._id ||
            selectedQuoteRequest?.job?._id === salesQuoteRequest?._id
          }
        />
      );
    });
  };

  const cardClasses = classNames(className, "quote-request-list");

  return (
    <DashboardCard className={cardClasses}>
      <DashboardCardHeader className="quote-request-header">
        {title}
      </DashboardCardHeader>

      <DashboardCardBody className="quote-request-body">
        {renderItems()}

        {children}
      </DashboardCardBody>
    </DashboardCard>
  );
};

export default QuoteRequestList;
