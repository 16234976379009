import React from "react";
import moment from "moment";
import classnames from "classnames";
import { Badge } from "react-bootstrap";
import { TFunction } from "i18next";
import { useHistory } from "react-router-dom";
import { TodoList } from "../../../models/task";
import "./styles.scss";

type TaskDashboardItemProps = {
  task: TodoList;
  t: TFunction;
};
const TaskDashboardItem: React.FC<TaskDashboardItemProps> = (props) => {
  const { task, t } = props;
  const history = useHistory();

  const badgeVariant = React.useMemo(() => {
    if (!task.completed) {
      return "danger";
    }
    return "success";
  }, [task.completed]);

  const handleClick = React.useCallback(() => history.push(`/tasks`), [
    history,
  ]);

  return (
    <div className={classnames("dashboard-task")} onClick={handleClick}>
      <div className="dashboard-task-message">
        <Badge variant={badgeVariant}>
          {task.completed ? t("tasks.complete") : t("tasks.incomplete")}
        </Badge>
        <b>{task.task}</b>
        <span className="task-date" title={moment(task.dueDate).format("ll")}>
          {moment(task.dueDate).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default TaskDashboardItem;
