import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { find, head, isEmpty } from "lodash";
import moment from "moment";

import {
  EnumSelectionEntityType,
  EnumSelectionStatus,
  GetSelectionTemplateResponse,
  SelectionCategory,
} from "../../../../graphql/types/models/selections";

import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import SelectionCategories from "../../../../components/selection/selection-categories";
import DashboardCard from "../../../../components/dashboard/card";
import DashboardCardHeader from "../../../../components/dashboard/card/DashboardCardHeader";
import SelectionBudge from "../../../../components/selection/badge";
import DashboardCardBody from "../../../../components/dashboard/card/DashboardCardBody";
import CategorySelectionItem from "../../../../components/selection/selection-category-item";
import EmptyPlaceholder from "../../../../components/empty-placeholder";
import { GET_SELECTION_TEMPLATE } from "../../../../graphql/queries/selection/queries";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import { useSelection } from "../../../../hooks/useSelection";
import "./styles.scss";

type Params = {
  id: string;
};

type SelectionTemplateProps = RouteComponentProps<Params> &
  DashboardContextValue;

const SelectionTemplate: React.FC<SelectionTemplateProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { t } = useTranslation();

  const { id: templateId } = match.params;

  const [
    selectedCategory,
    setCategory,
  ] = React.useState<SelectionCategory | null>(null);

  const {
    data: selectionTemplate,
    loading: selectionTemplateLoading,
  } = useQuery<GetSelectionTemplateResponse>(GET_SELECTION_TEMPLATE, {
    variables: {
      templateId,
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    setNavigationContext({
      ...navigationContext,
      selectionTemplate: selectionTemplate?.getSelectionTemplate,
    });
  }, [selectionTemplate, setNavigationContext]);

  React.useEffect(() => {
    const data = selectionTemplate?.getSelectionTemplate.categories;
    const selectionCategory =
      find(data, { _id: selectedCategory?._id }) || head(data);
    setCategory(selectionCategory || null);
  }, [selectionTemplate]);

  const {
    renderCreateUpdateCategoryModal,
    openCreateCategoryModal,
    handleOnEditCategory,
    openDeleteCategoryDialog,
    renderCreateUpdateCategoryItemModal,
    renderCreateUpdateItemOptionModal,
    showCreateCategoryItemModal,
    openDeleteOptionDialog,
    handleUnselectCategoryItem,
    openEditItemOptionModal,
    openCreateItemOptionModal,
    openDeleteCategoryItemDialog,
    openEditCategoryItemModal,
    isDownloadingFiles,
  } = useSelection({
    selectionEntityId: selectionTemplate?.getSelectionTemplate._id,
    selectionEntityType: EnumSelectionEntityType.TEMPLATE,
    selectedCategory,
    templateId,
    categories: selectionTemplate?.getSelectionTemplate.categories,
  });

  const handleCategorySelect = React.useCallback(
    (category: SelectionCategory) => {
      setCategory(category);
    },
    []
  );

  const overDue = React.useMemo(() => {
    return moment().isAfter(moment(selectedCategory?.dueDate).endOf("day"));
  }, [selectedCategory]);

  return (
    <Container fluid className="m-0 p-0 h-100 quote-selections">
      <Helmet title={t("navigation.quotesSection.selections")} />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SELECTION_TEMPLATE
        }
      />

      {renderCreateUpdateCategoryModal(true)}
      {renderCreateUpdateCategoryItemModal(undefined, true)}
      {renderCreateUpdateItemOptionModal()}

      <Row className="h-100">
        <Col lg={4} xs={12}>
          <SelectionCategories
            displayNavButtons={false}
            categories={selectionTemplate?.getSelectionTemplate.categories}
            onDelete={openDeleteCategoryDialog}
            onAddClick={openCreateCategoryModal}
            selectedCategory={selectedCategory}
            selectCategory={handleCategorySelect}
            disabled={false}
            isTemplate={true}
          />
        </Col>
        <Col lg={8} xs={12}>
          <div>
            <DashboardCard>
              <DashboardCardHeader className="justify-content-between">
                <div className="d-flex align-items-center text-capitalize mr-3">
                  {selectedCategory?.name}
                  {overDue &&
                    selectedCategory?.status !==
                      EnumSelectionStatus.COMPLETED && (
                      <SelectionBudge status={EnumSelectionStatus.OVERDUE} />
                    )}
                </div>
                <div className="d-flex">
                  <Button
                    className="button info text-light button-hover"
                    onClick={handleOnEditCategory}
                  >
                    {t("common.edit")}
                  </Button>
                </div>
              </DashboardCardHeader>
              <DashboardCardBody className="category-container">
                <div className="category-details">
                  <div>
                    {selectedCategory?.dueDate && (
                      <div className="category-dueDate">
                        {t("common.due", {
                          date: moment(selectedCategory?.dueDate).format(
                            "DD/MM/YYYY"
                          ),
                        })}
                      </div>
                    )}
                    {selectedCategory?.description && (
                      <div className="category-description">
                        {selectedCategory?.description}
                      </div>
                    )}
                  </div>
                  <Button
                    className="button success create-item"
                    onClick={showCreateCategoryItemModal}
                    disabled={
                      selectionTemplateLoading ||
                      isEmpty(
                        selectionTemplate?.getSelectionTemplate.categories
                      )
                    }
                  >
                    {t("selections.createItem")}
                  </Button>
                </div>
                {selectedCategory?.items.length ? (
                  selectedCategory?.items.map((item) => {
                    return (
                      <CategorySelectionItem
                        key={item._id}
                        item={item}
                        onDeleteOption={openDeleteOptionDialog}
                        onUnselectOption={handleUnselectCategoryItem}
                        onEditOption={openEditItemOptionModal}
                        onCreateOption={openCreateItemOptionModal}
                        onDelete={openDeleteCategoryItemDialog}
                        onEdit={openEditCategoryItemModal}
                        isLoading={isDownloadingFiles}
                        isLocked={false}
                        isTemplate={true}
                      />
                    );
                  })
                ) : (
                  <EmptyPlaceholder
                    message={t("selections.emptyCategoryItemsPlaceholder")}
                  />
                )}
              </DashboardCardBody>
            </DashboardCard>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default withRouter(withDashboardContext(SelectionTemplate));
