import * as yup from "yup";
import { TFunction } from "i18next";

import { ValidationMessagesUtils, ValidationRules } from "../../../utils/yup";

export default function createQuoteSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "quotes.name")
      ),
    contactId: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "quotes.client")
      ),
    templateId: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    postcode: ValidationRules.getPostcodeRules(yup, t),
  });
}
