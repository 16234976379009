import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../icons/Icon";
import "./styles.scss";

type SidebarExpandButtonProps = {
  isActiveExpandButton: boolean;
  isSideMenuExpanded: boolean;
  isSidebarMouseEnter: boolean;
  onExpandButton: () => void;
  expandButtonMouseEnter: () => void;
};

const SidebarExpandButton: FC<SidebarExpandButtonProps> = ({
  isActiveExpandButton,
  onExpandButton,
  expandButtonMouseEnter,
  isSideMenuExpanded,
  isSidebarMouseEnter,
}) => {
  const { t } = useTranslation();

  return (
    <button
      title={isActiveExpandButton ? t("common.collapse") : t("common.expand")}
      onClick={onExpandButton}
      onMouseEnter={expandButtonMouseEnter}
      className={classNames("expand-button-container", {
        active: isSideMenuExpanded,
        "active-transparent": isActiveExpandButton,
        "active-visible": isSidebarMouseEnter,
      })}
    >
      <Icon
        name={
          isActiveExpandButton ? "keyboard_arrow_left" : "keyboard_arrow_right"
        }
      />
    </button>
  );
};

export default SidebarExpandButton;
