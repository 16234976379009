import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import SignInForm from "../../components/authentication/sign-in-form";
import AuthenticationLayout from "../layouts/authentication";
import {
  signIn,
  signInFailed,
  signInSuccess,
} from "../../redux/authentication/actions";
import GraphQLClient, {
  TOKEN_TYPE_ADMIN,
  TOKEN_TYPE_SUBCONTRACTOR,
  TOKEN_TYPE_CLIENT,
  TOKEN_TYPE_AFFILIATE,
} from "../../graphql";
import { IMPERSONATE, SIGN_IN } from "../../graphql/queries/auth/mutations";
import { WB_ADMIN_SIGN_IN } from "../../graphql/queries/wb-admin/auth/mutations";
import { SUBCONTRACTOR_SIGN_IN } from "../../graphql/queries/subcontractor/auth/mutations";
import { CLIENT_SIGN_IN } from "../../graphql/queries/client/auth/mutations";
import { notify } from "../../components/notification";
import {
  SignInResponse,
  SubcontractorSignInResponse,
  ClientSignInResponse,
} from "../../graphql/types/models/auth";
import Context, { SiteContext } from "../../siteContext";
import { AffiliateSignInResponse } from "../../graphql/types/models/affiliate";
import { AFFILIATE_SIGN_IN } from "../../graphql/queries/affiliate/mutations";

type SignInContainerProps = {
  signIn: () => void;
  signInSuccess: (props: object) => void;
  signInFailed: (error: string) => void;
};

const SignInContainer: React.FC<SignInContainerProps> = (props) => {
  const { signIn, signInSuccess, signInFailed } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const { isSubcontractor, isAdmin, isClient, isAffiliate } = React.useContext(
    Context
  ) as SiteContext;

  React.useEffect(() => {
    GraphQLClient.setToken("");
  }, []);

  const [WbAdminSignInAction] = useMutation<SignInResponse>(WB_ADMIN_SIGN_IN, {
    onCompleted: (data) => {
      GraphQLClient.setToken(data.login.token, TOKEN_TYPE_ADMIN);
      signInSuccess({
        isSubcontractor: false,
        isAdmin: true,
        isClient: false,
        isAffiliate: false,
      });
    },
    onError: (error) => {
      notify({
        title: t("authentication.errors.signInTitle"),
        content: error.message,
        error: true,
      });
      signInFailed(error.message);
    },
  });

  const [signInAction] = useMutation<SignInResponse>(SIGN_IN, {
    onCompleted: (data) => {
      GraphQLClient.setToken(data.login.token);
      signInSuccess({
        isSubcontractor: false,
        isAdmin: false,
        isClient: false,
        isAffiliate: false,
      });
    },
    onError: (error) => {
      notify({
        title: t("authentication.errors.signInTitle"),
        content: error.message,
        error: true,
      });
      signInFailed(error.message);
    },
  });

  const [subcontractorSignIn] = useMutation<SubcontractorSignInResponse>(
    SUBCONTRACTOR_SIGN_IN,
    {
      onCompleted: (data) => {
        GraphQLClient.setToken(
          data.subcontractorLogin.token,
          TOKEN_TYPE_SUBCONTRACTOR
        );
        signInSuccess({
          isSubcontractor: true,
          isAdmin: false,
          isClient: false,
          isAffiliate: false,
        });
      },
      onError: (error) => {
        notify({
          title: t("authentication.errors.signInTitle"),
          content: t("authentication.errors.signIn"),
          error: true,
        });
        signInFailed(error.message);
      },
    }
  );

  const [clientSignIn] = useMutation<ClientSignInResponse>(CLIENT_SIGN_IN, {
    onCompleted: (data) => {
      GraphQLClient.setToken(data.clientLogin.token, TOKEN_TYPE_CLIENT);
      signInSuccess({
        isSubcontractor: false,
        isAdmin: false,
        isClient: true,
        isAffiliate: false,
      });
    },
    onError: (error) => {
      notify({
        title: t("authentication.errors.signInTitle"),
        content: t("authentication.errors.signIn"),
        error: true,
      });
      signInFailed(error.message);
    },
  });

  const [affiliateSignIn] = useMutation<AffiliateSignInResponse>(
    AFFILIATE_SIGN_IN,
    {
      onCompleted: (data) => {
        GraphQLClient.setToken(data.affiliateLogin.token, TOKEN_TYPE_AFFILIATE);
        signInSuccess({
          isSubcontractor: false,
          isAdmin: false,
          isClient: false,
          isAffiliate: true,
        });
      },
      onError: (error) => {
        notify({
          title: t("authentication.errors.signInTitle"),
          content: t("authentication.errors.signIn"),
          error: true,
        });
        signInFailed(error.message);
      },
    }
  );

  const [impersonate] = useMutation<SignInResponse>(IMPERSONATE, {
    onCompleted: (data) => {
      GraphQLClient.setToken(data.login.token);
      signInSuccess({
        isSubcontractor: false,
        isAdmin: false,
        isClient: false,
        isAffiliate: false,
      });
    },
    onError: (error) => {
      notify({
        title: t("authentication.errors.signInTitle"),
        content: error.message,
        error: true,
      });
      signInFailed(error.message);
    },
  });

  React.useEffect(() => {
    const oneTimeHash = new URLSearchParams(location.search).get("oth");
    if (oneTimeHash) {
      impersonate({
        variables: {
          hash: oneTimeHash,
        },
      });
    }
  }, []);

  const handleFormSubmit = React.useCallback(
    async (
      credentials: { email: string; password: string },
      rememberMe: boolean
    ) => {
      signIn();

      const { email, password } = credentials;

      if (isSubcontractor) {
        subcontractorSignIn({ variables: { email, password, rememberMe } });
      } else if (isAdmin) {
        WbAdminSignInAction({ variables: { email, password, rememberMe } });
      } else if (isClient) {
        clientSignIn({ variables: { email, password, rememberMe } });
      } else if (isAffiliate) {
        affiliateSignIn({ variables: { email, password } });
      } else {
        signInAction({ variables: { email, password, rememberMe } });
      }
    },
    [
      signInAction,
      subcontractorSignIn,
      signIn,
      WbAdminSignInAction,
      clientSignIn,
      isAdmin,
      isClient,
      isSubcontractor,
      isAffiliate,
    ]
  );

  return (
    <AuthenticationLayout>
      <Helmet title={t("authentication.signIn")} />
      <SignInForm onSubmit={handleFormSubmit} />
    </AuthenticationLayout>
  );
};

export default connect(null, { signIn, signInSuccess, signInFailed })(
  SignInContainer
);
