import { BadgeProps } from "react-bootstrap";
import {
  QuoteRequestClientFeedback,
  QuoteRequestStatus,
} from "../../graphql/types/models/quote-request";
import { t } from "i18next";

export const getStatusBadge = (status: QuoteRequestStatus) => {
  let label = t(`quoteRequest.statuses.${status}`);
  let variant: BadgeProps["variant"] = "info";
  switch (status) {
    case QuoteRequestStatus.ACCEPTED:
      variant = "success";
      break;
    case QuoteRequestStatus.SUBMITTED:
      variant = "warning";
      break;
    case QuoteRequestStatus.DECLINED:
      variant = "danger";
      break;
    case QuoteRequestStatus.REQUESTED:
    default:
      variant = "primary";
  }
  return {
    label,
    variant,
  };
};

export const getClientFeedbackBadge = (
  feedback: QuoteRequestClientFeedback
) => {
  let label = t(`quoteRequest.clientFeedback.${feedback}`);
  let variant: BadgeProps["variant"] = "info";
  switch (feedback) {
    case QuoteRequestClientFeedback.ACCEPTED:
      variant = "success";
      break;
    case QuoteRequestClientFeedback.DECLINED:
      variant = "danger";
      break;
    case QuoteRequestClientFeedback.SHARED:
      variant = "info";
      break;
    case QuoteRequestClientFeedback.NONE:
    default:
      return null;
  }
  return {
    label,
    variant,
  };
};
