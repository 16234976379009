import { User } from "../graphql/types/models/user";
import { Client } from "./client";
import { JobListItem, ScheduleCategoryItem } from "./job";
import { LeadSale } from "./leads";
import { SupplierDetails } from "./supplier";

export type SystemUser = User & Client & SupplierDetails;

enum EnumSystemUserType {
  Subcontractor = "SUBCONTRACTOR",
  Builder = "BUILDER",
  Client = "CLIENT",
  None = "NONE",
}

export enum EnumChecklistTemplateType {
  Todolist = "TODOLIST",
  Job = "JOB",
  Estimation = "ESTIMATION",
}

export type TodoListTag = {
  _id: string;
  name: string;
};

export type TodoListChecklist = {
  _id: string;
  updatedAt: string;
  createdAt: string;
  task: string;
  completed: boolean;
  is_deleted: boolean;
  assignedTo: SystemUser;
  completedByType: EnumSystemUserType;
  assignedToType: EnumSystemUserType;
  completedBy: SystemUser;
  dueDate: string;
};

export type TodoListChecklistCategory = {
  _id: string;
  name: string;
  checklist: TodoListChecklist[];
  todoListId: string;
};

export type TodoList = {
  _id: string;
  task: string;
  description: string;
  completed: boolean;
  is_archived?: boolean;
  dueDate: string;
  user: User;
  job?: JobListItem;
  jobScheduleItem?: string;
  scheduleItem?: ScheduleCategoryItem;
  lead?: LeadSale;
  members?: SystemUser[];
  tags?: TodoListTag[];
  checklistCategories?: TodoListChecklistCategory[];
  progress?: number;
};

export type CreateTodoListPayload = {
  task: string;
  dueDate: string;
  description: string;
  userId?: string;
  jobId: string;
  leadId?: string;
  members?: string[];
};

export type CreateChecklistPayload = {
  _id?: string;
  name: string;
};

export type ToggleChecklistStatusPayload = {
  todoListId: string;
  checklistId: string;
  isComplete: Boolean;
};

export type CreateChecklistItemPayload = {
  _id?: string;
  task: string;
  dueDate?: string;
  assignedTo?: string;
};

export type TodoChecklistTemplateItem = {
  _id: string;
  task: string;
  order: number;
  categoryId: string;
  is_deleted: boolean;
};

export type TodoChecklistTemplateCategory = {
  _id: string;
  name: string;
  is_deleted: boolean;
  order: number;
  templateId: string;
  items: TodoChecklistTemplateItem[];
};

export type CreateUpdateChecklistGroupPayload = {
  _id?: string;
  name: string;
  order?: number;
  items?: TodoChecklistTemplateItemInput[];
};

export type TodoChecklistTemplateItemInput = {
  _id?: string;
  task: string;
  order?: number;
};
export type TodoChecklistTemplates = {
  _id: string;
  name: string;
  is_deleted: boolean;
  type: EnumChecklistTemplateType;
  categories: TodoChecklistTemplateCategory[];
};

export type GetTodoChecklistTemplateListResponse = {
  listChecklistTemplates: TodoChecklistTemplates[];
};

export type DeleteChecklistTemplateListResponse = {
  deleteChecklistTemplate: {
    _id: string;
  };
};

export type CreateUpdateChecklistTemplateResponse = {
  createUpdateChecklistTemplate: TodoChecklistTemplates;
};

export type GetChecklistTemplateResponse = {
  getChecklistTemplate: TodoChecklistTemplates;
};

export type DeleteChecklistTemplateCategoryResponse = {
  deleteChecklistTemplateCategory: TodoChecklistTemplates;
};

export type CreateUpdateChecklistTemplateGroupResponse = {
  createUpdateChecklistTemplateCategory: TodoChecklistTemplates;
};

export type SaveChecklistCategoryTemplateResponse = {
  createUpdateChecklistTemplateCategory: TodoChecklistTemplates;
};

export type ImportTodoFromTemplateResponse = {
  importTodoChecklistFromTemplate: TodoList;
};

export type ImportTodoFromTemplatePayload = {
  todoListId: string;
  templateId: string;
};

export type CreateUpdateChecklistTemplatePayload = {
  template: {
    name: string;
    categories: {
      name: string;
      items: {
        task: string;
        order: number;
      }[];
    }[];
  };
};

export type SaveChecklistCategoryTemplatePayload = {
  templateId: string;
  category: {
    _id?: string;
    name: string;
    order?: string;
    items: {
      task: string;
    }[];
  };
};

export enum TaskStatus {
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete",
  OVERDUE = "Overdue",
}

export enum TaskArchiveStatus {
  ARCHIVED = "ARCHIVED",
  UNARCHIVED = "UNARCHIVED",
}
