import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import CreateEntityModal from "../../modals/create-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createImportScheduleSchema from "./ImportScheduleFromCosting.schema";
import createImportScheduleFields from "./utils";
import {
  BulkJobsScheduleCategoryItemPayload,
  BulkJobsScheduleCategoryPayload,
} from "../../../models/job";
import {
  ImportScheduleFromCostingItemPayload,
  ImportScheduleFromCostingPayload,
} from "../import-schedule-from-costing";
import "./styles.scss";

type ImportScheduleFromCostingItemModalProps = {
  data: BulkJobsScheduleCategoryPayload[];
  onSubmit: (data: ImportScheduleFromCostingPayload) => void;
};

export type ImportScheduleFromCostingItemModalRef = {
  show: (show: boolean) => void;
};

const ImportScheduleFromCostingItemModal: React.FC<ImportScheduleFromCostingItemModalProps> = (
  props,
  ref
) => {
  const { t } = useTranslation();
  const { onSubmit, data } = props;

  const [show, setShowModal] = React.useState(false);
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<BulkJobsScheduleCategoryItemPayload>
  >({});

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => setShowModal(show),
  }));

  React.useEffect(() => {
    setFormFields(createImportScheduleFields(t));
  }, [t]);

  const initialData = React.useMemo<
    ImportScheduleFromCostingItemPayload
  >(() => {
    const items: BulkJobsScheduleCategoryItemPayload[] = [];
    data.forEach((category) => {
      category.items?.forEach((item) => {
        items.push({
          _id: item._id || "",
          categoryId: category._id || "",
          category: category.name || "",
          name: item.name,
          description: item.note || "",
          startDate: category.startDate,
          endDate: category.endDate,
        });
      });
    });
    return { items };
  }, [data]);

  const handleSubmit = React.useCallback(
    (data: ImportScheduleFromCostingItemPayload) => {
      setShowModal(false);
      onSubmit && onSubmit(data);
    },
    [onSubmit]
  );

  return (
    <CreateEntityModal
      validationSchema={createImportScheduleSchema(t)}
      title={t("schedule.importSchedule")}
      className="import-schedule-from-costing-modal"
      data={initialData}
      show={show}
      fields={formFields}
      onSubmit={handleSubmit}
      onClose={() => setShowModal(false)}
      submitText={t("common.import")}
    />
  );
};

export default forwardRef(ImportScheduleFromCostingItemModal);
