import React from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import Icon from "../../icons/Icon";
import Dropdown from "../../dashboard/dropdown";
import SelectionBudge from "../badge";
import SelectionCategoryItem from "./SelectionCategoryItem";
import {
  EnumSelectionStatus,
  SelectionCategory,
} from "../../../graphql/types/models/selections";
import "./styles.scss";

type SelectionCategoriesProps = {
  addButtonPlacement?: "top" | "bottom" | "none";
  displayNavButtons?: boolean;
  categories?: SelectionCategory[];
  onBackClick?: () => void;
  onNextClick?: () => void;
  onDelete?: (specCategoryId: string) => void;
  onAddClick?: () => void;
  onSaveSelectionsClick?: () => void;
  disabled?: boolean;
  isClient?: boolean;
  isTemplate?: boolean;
  selectedOptions?: {
    itemId: string;
    optionId: string;
    categoryId: string;
  }[];
  showSaveSelectionsButton?: boolean;
  selectedCategory: SelectionCategory | null;
  selectCategory?: (category: SelectionCategory) => void;
  onShareSelection?: () => void;
  onSaveAsTemplate?: () => void;
  onImportFromTemplate?: () => void;
  selectionStatus?: EnumSelectionStatus;
};

const SelectionCategories: React.FC<SelectionCategoriesProps> = ({
  addButtonPlacement = "top",
  displayNavButtons = true,
  categories,
  onBackClick,
  onNextClick,
  onDelete,
  onAddClick,
  disabled = false,
  selectedCategory,
  selectCategory,
  onShareSelection,
  selectionStatus,
  onSaveAsTemplate,
  onImportFromTemplate,
  isClient,
  isTemplate,
  showSaveSelectionsButton,
  onSaveSelectionsClick,
  selectedOptions,
}) => {
  const { t } = useTranslation();

  const isCompletedOrSelectedSelection =
    selectionStatus === EnumSelectionStatus.COMPLETED ||
    selectionStatus === EnumSelectionStatus.SELECTED;

  const renderItem = React.useCallback(
    (item: SelectionCategory) => {
      const selectedItems = item.items.filter(
        (categoryItem) =>
          categoryItem.status === EnumSelectionStatus.SELECTED ||
          categoryItem.status === EnumSelectionStatus.COMPLETED
      );
      const selectedItemIds = selectedItems.map((item) => item._id);
      const count = selectedOptions
        ?.filter(
          (option) =>
            !selectedItemIds.includes(option.itemId) &&
            option.categoryId === item?._id
        )
        .filter((option) => option.categoryId === item?._id);

      const currentCategorySelectedCount = t("selections.countSelectedItems", {
        count: selectedItems.length + Number(count?.length),
        total: item?.items.length,
      });
      return (
        <SelectionCategoryItem
          key={item._id}
          category={item}
          onDelete={!disabled ? onDelete : undefined}
          onClick={selectCategory}
          isSelected={item._id === selectedCategory?._id}
          isClient={isClient}
          isTemplate={isTemplate}
          selectedItemsCount={currentCategorySelectedCount}
        />
      );
    },
    [
      selectedCategory,
      selectCategory,
      disabled,
      onDelete,
      isClient,
      isTemplate,
      selectedOptions,
    ]
  );

  const renderCategories = React.useCallback(() => {
    return (
      <div className="quote-selection-list">
        {categories?.map((item) => {
          return renderItem(item);
        })}
      </div>
    );
  }, [categories, renderItem, isClient, isTemplate, selectedOptions]);

  const dropdownItems = React.useMemo(() => {
    return onShareSelection && !disabled
      ? [
          {
            id: "import",
            label: t("selections.shareWithClient"),
            name: "share",
            onClick: onShareSelection,
          },
          {
            id: "saveTemplate",
            label: t("selections.saveAsTemplate"),
            name: "saveTemplate",
            onClick: onSaveAsTemplate,
          },
          {
            id: "importFromTemplate",
            label: t("selections.importFromTemplate"),
            name: "importFromTemplate",
            onClick: onImportFromTemplate,
          },
        ]
      : [];
  }, [disabled, t, onShareSelection]);

  return (
    <DashboardCard className="quote-selection-categories">
      <DashboardCardHeader className="text-capitalize justify-content-between">
        <div className="d-flex align-items-left">
          {t("common.categories")}
          {selectionStatus && (
            <SelectionBudge
              status={
                isClient
                  ? isCompletedOrSelectedSelection
                    ? selectionStatus
                    : EnumSelectionStatus.INCOMPLETE
                  : selectionStatus
              }
            />
          )}
        </div>
        {dropdownItems.length > 0 && (
          <Dropdown
            label={t("common.options")}
            icon="expand_more"
            size="215px"
            id="share-selection-dropdown"
            items={dropdownItems}
          />
        )}
      </DashboardCardHeader>
      <DashboardCardBody>
        <div className="quote-selection-body">
          {addButtonPlacement === "top" && !isClient && (
            <Button
              disabled={disabled}
              className="button info add-category"
              block
              onClick={onAddClick}
            >
              {t("quotes.addCategory")}
            </Button>
          )}
          {renderCategories()}
        </div>
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end">
        {displayNavButtons && (
          <>
            <Button
              className="button info mr-2"
              variant="secondary"
              onClick={onBackClick}
            >
              {t("common.back")}
            </Button>
            <Button className="button success" onClick={onNextClick}>
              {t("common.next")}
            </Button>
          </>
        )}
        {showSaveSelectionsButton && (
          <Button
            className="button success large save-selection-button"
            onClick={onSaveSelectionsClick}
            disabled={!Boolean(selectedOptions?.length)}
          >
            <Icon name="download" />
            {t("selections.saveSelections")}
          </Button>
        )}
      </DashboardCardFooter>
    </DashboardCard>
  );
};

export default SelectionCategories;
