import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { round } from "mathjs";
import _, {
  head,
  map,
  omit,
  pick,
  reduce,
  concat,
  find,
  compact,
} from "lodash";
import moment from "moment";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import CategorySelectorCard, {
  CategorySelectorFilter,
  CategorySelectorPayload,
  SelectableDropdownItem,
} from "../../../components/category-select-card";
import ClaimCard from "../../../components/claims/claim-card";
import ClaimModal from "../../../components/claims/claim-modal";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { ContactListItem } from "../../../graphql/types/models/client";
import {
  GET_CLAIM,
  GET_CLAIM_PREVIEW,
  GET_CLAIM_STATEMENT,
  LIST_CLAIMS,
} from "../../../graphql/queries/job-claim/queries";
import { GET_JOB_CONTACTS } from "../../../graphql/queries/job/queries";
import {
  DeleteClaimResponse,
  GetClaimPreviewResponse,
  GetClaimResponse,
  GetClaimStatementResponse,
  ListClaimsResponse,
} from "../../../graphql/types/models/job-claim";
import {
  DELETE_CLAIM,
  SEND_CLAIM,
} from "../../../graphql/queries/job-claim/mutation";
import {
  ClaimPayload,
  ClaimReal,
  ClaimStatusesEnum,
  ClaimView,
} from "../../../models/claim";
import { handleClaimDelete } from "../../../graphql/queries/job-claim/utils";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";
import SendModal from "../../../components/modals/send-modal";
import { SendEmailForm } from "../../../models/email";
import ConfirmDialog from "../../../components/confirm-dialog";
import { GET_UNCLAIMED_VARIATIONS } from "../../../graphql/queries/job-variation/queries";
import { printBase64Pdf } from "../../../utils/pdf";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import JobLocationHeader from "../../header/job-location-header";
import { notify } from "../../../components/notification";
import ClientHeaderList from "../../header/client-header-list";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import { formatQuoteNumber } from "../../../utils/text";
import { DropdownItem } from "../../../components/dashboard/dropdown";
import ConfigureClaimModal from "../../../components/job/configure-claim-modal";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import TeammateHeaderList from "../../header/teammate-header-list";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { getClaimBadgeVariant } from "./utils";
import { JobType } from "../../../models/job";
import { useJobTotalsQuery } from "../../../hooks/queries/useJobTotalsQuery";
import { useJobQuery } from "../../../hooks/queries/useJobQuery";
import { useAccountingIntegrationQuery } from "../../../hooks/queries/useAccountingIntegrationQuery";
import { AccountingIntegrationSyncItemType } from "../../../graphql/types/models/integrations";
import ClaimReceiveModal from "../../../components/claims/claim-receive-modal";
import { calcGST, GST_PERCENT } from "../../../utils/calculations";
import { SystemFolderType } from "../../../models/documents";

type Params = {
  id: string;
  claim_id?: string;
};
type JobClaims = RouteComponentProps<Params> &
  DashboardContextValue & {
    user: UserPayload | null;
  };
type JobContactResponse = {
  contacts: [
    {
      contact: ContactListItem;
    }
  ];
};

const JobClaims: React.FC<JobClaims> = ({ navigationContext, match, user }) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const isCostPlus = navigationContext?.job?.type === JobType.COST_PLUS;
  const contractTotal = navigationContext?.job?.contractTotal || 0;
  const contractTotalGST = navigationContext?.job?.contractTotalGST || 0;
  const isRetention = navigationContext?.job?.isRetention || false;
  const isContractLocked = navigationContext?.job?.isContractLocked || false;
  const jobId = navigationContext?.job?._id;
  const jobContact = navigationContext?.job?.customer;
  const businessName = navigationContext?.job?.businessName;
  const { claim_id: claimId } = match.params;

  const { jobTotals, jobTotalsLoading, refetchJobTotals } = useJobTotalsQuery(
    jobId
  );

  const { job } = useJobQuery(jobId);
  const history = useHistory();

  const newClaimRef = React.useRef<ModalDisplayRef>(null);
  const sendRef = React.useRef<ModalDisplayRef>();
  const configureClaimRef = React.useRef<ModalDisplayRef>(null);
  const claimReceiveRef = React.useRef<ModalDisplayRef>(null);
  const [totalEstimated, setTotalEstimated] = useState(0);
  const [totalEstimatedGST, setTotalEstimatedGST] = useState(0);
  const [showCc, setShowCc] = React.useState(false);
  const [showBcc, setShowBcc] = React.useState(false);

  useEffect(() => {
    if (!jobTotalsLoading && jobTotals) {
      const amount = isContractLocked
        ? contractTotal
        : jobTotals.totalEstimatedWithMarkup;
      const GST = isContractLocked
        ? contractTotalGST
        : jobTotals.totalEstimatedWithMarkupGST;
      setTotalEstimated(amount);
      setTotalEstimatedGST(GST);
    }
  }, [
    job,
    jobTotals,
    jobTotalsLoading,
    setTotalEstimated,
    isContractLocked,
    contractTotal,
    contractTotalGST,
  ]);

  // useEffect(() => {
  //   if (contractTotal) {
  //     setTotalEstimated(contractTotal);
  //   }
  // }, [contractTotal]);

  const [sendClaim] = useMutation(SEND_CLAIM, {
    onCompleted: () => {
      setEmailTargetId("");
      sendRef.current?.show(false);
      notify({
        title: t("claims.sendEmail"),
        content: t("claims.success.sendClaim"),
      });
      setShowBcc(false);
      setShowCc(false);
    },
    onError: () => {
      notify({
        error: true,
        title: t("claims.sendEmail"),
        content: t("claims.errors.sendClaim"),
      });
      setShowBcc(false);
      setShowCc(false);
    },
  });

  const [contactList, setContactList] = useState<ContactListItem[]>([]);

  const [
    getJobContact,
    {
      data: jobContactList,
      loading: jobContactLoading,
      error: jobContactError,
    },
  ] = useLazyQuery<JobContactResponse>(GET_JOB_CONTACTS);

  useEffect(() => {
    if (jobId) {
      getJobContact({
        variables: {
          jobId,
        },
      });
    }
  }, [jobId, getJobContact]);

  const copyMailOptions = React.useMemo(() => {
    return {
      showCc,
      setShowCc,
      showBcc,
      setShowBcc,
      showReplyTo: true,
    };
  }, [showBcc, showCc]);

  useEffect(() => {
    //contactList, setContactList
    if (jobContactList && jobContactList.contacts.length > 0) {
      const itemList = jobContactList.contacts.map((contact) => {
        const result = omit(contact.contact, "__typename");
        return { ...(result as ContactListItem) };
      });

      setContactList(itemList);
    }
  }, [jobId, jobContactList, setContactList]);

  const client = useApolloClient();

  const [deleteClaim, { loading: claimDeleting }] = useMutation<
    DeleteClaimResponse
  >(DELETE_CLAIM, {
    update: handleClaimDelete(jobId),
    refetchQueries: [
      {
        query: GET_UNCLAIMED_VARIATIONS,
        variables: {
          jobId,
        },
      },
    ],
    onCompleted: () => {
      refetchJobTotals?.();
    },
  });

  const [
    getClaim,
    { data: claimDetails, loading: claimLoading, error: claimError },
  ] = useLazyQuery<GetClaimResponse>(GET_CLAIM);

  const { data: claimsList, loading: claimsLoading } = useQuery<
    ListClaimsResponse
  >(LIST_CLAIMS, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    integrationProvider,
    syncAccountingItem,
  } = useAccountingIntegrationQuery();

  const { t } = useTranslation();

  const [progressFilter, setFilter] = useState<CategorySelectorFilter>({
    id: "",
  });

  const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
  const [deleteTargetId, setDeleteTarget] = useState("");
  const [emailTargetId, setEmailTargetId] = useState("");
  const [receiveTargetId, setReceivedTargetId] = useState("");
  const [claimToUpdate, setClaimToUpdate] = useState<ClaimPayload | null>(null);

  const handleReceive = React.useCallback(
    (id: string) => {
      setReceivedTargetId(id);
      claimReceiveRef.current?.show(true);
    },
    [claimReceiveRef]
  );

  const closeDeleteConfirm = React.useCallback(() => {
    setDeleteDialogVisibility(false);
    setDeleteTarget("");
  }, []);

  const openDeleteConfirm = React.useCallback((id: string) => {
    setDeleteDialogVisibility(true);
    setDeleteTarget(id);
  }, []);

  const openEmailModal = React.useCallback((id: string) => {
    sendRef.current?.show(true);
    setEmailTargetId(id);
  }, []);

  const openClaimModal = React.useCallback(() => {
    newClaimRef.current?.show(true);
  }, []);

  const claim = React.useMemo(() => claimDetails?.getJobProgressClaim, [
    claimDetails,
  ]);

  // TODO: Move to component/hook for Client version to use
  const claimsCategories = React.useMemo<CategorySelectorPayload[]>(() => {
    const data = claimsList?.getJobProgressClaims;
    return map(data, (claim) => ({
      id: claim._id,
      label: formatQuoteNumber(claim.invNumber, "PC"),
      descriptionValue: claim.description,
      description: compact([
        moment(claim.claimDate).format("D MMMM YYYY"),
        claim.description,
      ]).join(": "),
      total: isCostPlus ? claim.invoiceSubTotal : claim.amount,
      rightLabel: t("common.currency", {
        amount: isCostPlus ? claim.invoiceSubTotal : claim.amount,
      }),
      rightBadge: compact([
        claim.isOverdue
          ? {
              variant: "danger",
              label: t(`claims.statuses.OVERDUE`),
            }
          : null,
        {
          variant: getClaimBadgeVariant(claim.status),
          label: t(`claims.statuses.${claim.status}`),
        },
      ]),
    }));
  }, [isCostPlus, claimsList]);

  const handleSelectCategory = React.useCallback(
    (filter) => {
      getClaim({
        variables: {
          jobId,
          progressClaimId: filter.id,
        },
      });
      setFilter(filter);
    },
    [jobId, getClaim]
  );

  const handleClaimCreateUpdate = React.useCallback(
    (progressClaim: ClaimReal) => {
      if (!claimToUpdate) {
        getClaim({
          variables: {
            jobId,
            progressClaimId: progressClaim._id,
          },
        });
        setFilter({
          id: progressClaim._id,
          label: formatQuoteNumber(progressClaim.invNumber, "PC"),
        });
      }
    },
    [jobId, claimToUpdate]
  );

  const handleClaimSend = React.useCallback(
    async (message: SendEmailForm) => {
      try {
        await sendClaim({
          variables: {
            jobId,
            progressClaimId: emailTargetId,
            message: {
              subject: message.title,
              to: message.to,
              ...(showCc && { cc: message.cc }),
              ...(showBcc && { bcc: message.bcc }),
              ...(message.replyTo && { replyTo: message.replyTo }),
              message: message.message,
              remoteAttachmentId: message.remoteAttachmentId,
            },
          },
        });
      } catch (e) {}
    },
    [sendClaim, jobId, emailTargetId, showCc, showBcc]
  );

  const handlePreviewPdf = React.useCallback(
    async (progressClaimId: string, printView?: ClaimView) => {
      try {
        const pdfPreview = await client.query<GetClaimPreviewResponse>({
          query: GET_CLAIM_PREVIEW,
          variables: {
            jobId,
            progressClaimId,
            printView,
          },
          fetchPolicy: "network-only",
        });
        printBase64Pdf(pdfPreview?.data?.getJobProgressClaimPreview.pdf);
      } catch (e) {}
    },
    [jobId]
  );

  const handleClaimDeleteConfirm = React.useCallback(async () => {
    if (!deleteTargetId) {
      return;
    }

    try {
      await deleteClaim({
        variables: {
          jobId,
          progressClaimId: deleteTargetId,
        },
      });

      notify({
        title: t("claims.deleteClaim"),
        content: t("claims.success.deleteClaim"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("claims.deleteClaim"),
        content: t("claims.errors.deleteClaim"),
      });
    }

    setDeleteTarget("");
    setDeleteDialogVisibility(false);
  }, [deleteClaim, deleteTargetId, jobId]);

  React.useEffect(() => {
    if (claimDetails?.getJobProgressClaim || claimLoading || claimError) {
      return;
    }

    let firstClaim = head(claimsList?.getJobProgressClaims);
    if (claimId) {
      const selectedClaim = find(claimsList?.getJobProgressClaims, {
        _id: claimId,
      });
      firstClaim = selectedClaim || firstClaim;
    }

    if (firstClaim) {
      getClaim({
        variables: {
          jobId,
          progressClaimId: firstClaim._id,
        },
      });

      setFilter({
        id: firstClaim._id,
      });
    }
  }, [claimDetails, claimLoading, jobId, getClaim, claimsList, claimError]);

  const claimedAmount = React.useMemo(() => {
    const list = claimsList?.getJobProgressClaims;

    return reduce(
      list,
      (total, claim) => {
        let amount = total + claim.amount;
        if (job?.excludeVariationFromClaimAmount) {
          amount -= claim.variationAmount;
        }
        return amount;
      },
      0
    );
  }, [job, claimsList, totalEstimated]);

  const claimedTotals = React.useMemo(() => {
    let subTotal = jobTotals?.totalClaims || 0;
    let GST = jobTotals?.totalClaimsGST || 0;
    if (job?.excludeVariationFromClaimAmount) {
      subTotal -=
        (jobTotals?.totalVariationClaimed || 0) +
        (jobTotals?.totalVariationItemClaimed || 0);
      GST -=
        (jobTotals?.totalVariationClaimedGST || 0) +
        (jobTotals?.totalVariationItemClaimedGST || 0);
    }
    return {
      subTotal,
      GST,
    };
  }, [job, jobTotals]);

  const handleGoToCosting = React.useCallback(
    () => history.push(`/jobs/${job?._id}/costings`),
    [history, job?._id]
  );

  const handleNewClaim = React.useCallback(() => {
    setClaimToUpdate(null);
    openClaimModal();
  }, []);

  const handleEditClaim = React.useCallback(() => {
    const claim = claimDetails?.getJobProgressClaim;
    if (!claim) {
      return null;
    }

    setClaimToUpdate({
      ...pick(claim, [
        "_id",
        "description",
        "richComment",
        "contact",
        "status",
        "amount",
        "GST",
        "retainedAmount",
        "retainedGST",
        "variationAmount",
        "percentage",
        "items",
        "claimDate",
        "dueDate",
        "hideItems",
        "defaultView",
      ]),
      contact: claim.contact?._id || "",
      variations: claim.variations?.map((v) => v._id),
    });
    openClaimModal();
  }, [claimDetails, openClaimModal]);

  const handlePrintStatement = React.useCallback(
    async (progressClaimIds: string[]) => {
      try {
        const pdfPreview = await client.query<GetClaimStatementResponse>({
          query: GET_CLAIM_STATEMENT,
          variables: {
            jobId,
            progressClaimIds,
          },
          fetchPolicy: "network-only",
        });
        printBase64Pdf(pdfPreview?.data?.getJobProgressClaimStatement.pdf);
      } catch (e) {}
    },
    [jobId]
  );

  const handleSync = React.useCallback(
    async (progressClaimId: string) => {
      try {
        syncAccountingItem({
          variables: {
            jobId,
            itemType: AccountingIntegrationSyncItemType.PROGRESS_CLAIM,
            itemId: progressClaimId,
          },
        });
      } catch (e) {}
    },
    [jobId]
  );

  const renderClaimCard = React.useCallback(() => {
    if (!claimDetails?.getJobProgressClaim || claimLoading) {
      return <CardPlaceholder />;
    }

    return (
      <ClaimCard
        claim={claimDetails.getJobProgressClaim}
        jobType={navigationContext?.job?.type}
        onSend={openEmailModal}
        onDelete={openDeleteConfirm}
        onEdit={handleEditClaim}
        onPrint={handlePreviewPdf}
        onReceive={handleReceive}
        onSync={integrationProvider ? handleSync : undefined}
        syncProvider={integrationProvider}
      />
    );
  }, [
    claimDetails,
    navigationContext,
    openEmailModal,
    openDeleteConfirm,
    handleEditClaim,
    handlePreviewPdf,
    handleReceive,
    integrationProvider,
    handleSync,
  ]);

  const previewFileName = `${businessName} - Claim #${claimDetails?.getJobProgressClaim?.invNumber}.pdf`;

  const maxClaim = React.useMemo(() => {
    let amount = totalEstimated - (claimedAmount - 0);
    if (isRetention) {
      amount =
        Number(jobTotals?.totalRetained) -
        Number(jobTotals?.totalRetentionClaimed);
    }
    return round(amount, 3);
  }, [totalEstimated, claimedAmount, isRetention, jobTotals]);

  const {
    filteredArray,
    filter: inputFilter,
    setFilter: setSearchByInput,
    setFilterbySelect: setArrayFilter,
    filterSelect: arrayFilter,
  } = useFilteredArrayMultipleField(
    claimsCategories,
    ["label", "descriptionValue"],
    true,
    (item: CategorySelectorPayload, search: string) =>
      !!item.rightBadge?.filter((b) => b.label === search).length
  );

  const filterDropdownItems = React.useMemo<DropdownItem[]>(
    () =>
      concat(
        {
          id: "-",
          label: t("common.all"),
          onClick: () => setArrayFilter(""),
        },
        Object.keys(ClaimStatusesEnum).map((key) => ({
          id: key,
          label: t(`claims.statuses.${key}`),
          onClick: () => setArrayFilter(t(`claims.statuses.${key}`)),
        })),
        {
          id: "overdue",
          label: t("claims.statuses.OVERDUE"),
          onClick: () => setArrayFilter(t("claims.statuses.OVERDUE")),
        }
      ),
    [t, setArrayFilter]
  );

  const selectDropdownItems = React.useMemo<SelectableDropdownItem[]>(
    () => [
      {
        id: "printStatement",
        label: t("claims.printStatement"),
        icon: "print",
        onClick: handlePrintStatement,
      },
    ],
    [t, handlePrintStatement]
  );

  const total = React.useMemo(() => {
    return filteredArray.reduce((sum, current) => {
      return sum + (current?.total || 0);
    }, 0);
  }, [filteredArray]);

  return (
    <Container fluid className="m-0 p-0 h-100 job-claims-container">
      <Helmet title={t("navigation.jobsSection.progressClaims")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.JOBS_SECTION.PROGRESS_CLAIMS}
      />

      <JobLocationHeader />

      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      <ConfigureClaimModal ref={configureClaimRef} job={job} />

      <ConfirmDialog
        disabled={claimDeleting}
        title={t("claims.deleteClaim")}
        onClose={closeDeleteConfirm}
        show={showDeleteDialog}
        onSubmit={handleClaimDeleteConfirm}
      >
        <span className="field-text">{t("claims.deleteClaimMessage")}</span>
      </ConfirmDialog>

      <ClaimReceiveModal
        ref={claimReceiveRef}
        jobId={jobId}
        claimId={receiveTargetId}
      />

      {contactList.length > 0 && (
        <SendModal
          ref={sendRef}
          fileName={previewFileName}
          onFileClick={() => claim && handlePreviewPdf(claim._id)}
          title={t("claims.sendEmail")}
          subject={t("claims.sendEmailSubject", {
            invNumber: claim ? formatQuoteNumber(claim.invNumber, "PC") : "",
          })}
          contacts={contactList}
          contact={claim?.contact}
          onSubmit={handleClaimSend}
          submitText={t("common.send")}
          copyOptions={copyMailOptions}
          addDocumentsSelect={{
            entityId: jobId,
            systemFolderType: SystemFolderType.JOB,
          }}
        />
      )}

      {contactList.length > 0 && (
        <ClaimModal
          ref={newClaimRef}
          jobId={jobId}
          isCostPlus={isCostPlus}
          claimedAmount={claimedTotals.subTotal}
          claimedGST={claimedTotals.GST}
          contractTotal={totalEstimated}
          contractTotalGST={totalEstimatedGST}
          claim={claimToUpdate}
          client={jobContact}
          clients={contactList}
          onCreateUpdate={handleClaimCreateUpdate}
        />
      )}

      {claimsList?.getJobProgressClaims?.length ? (
        <Row className="h-100">
          <Col lg={4} xs={12}>
            <CategorySelectorCard
              selectable={isCostPlus || isRetention || true}
              filter={progressFilter}
              onSelectCategory={handleSelectCategory}
              categories={filteredArray}
              disabled={jobContactLoading || claimsLoading || isLocked}
              hideSelectAllButton={true}
              onAddNewItem={handleNewClaim}
              dropdownFilter={arrayFilter}
              filterDropdownItems={filterDropdownItems}
              selectableDropdownItems={selectDropdownItems}
              placeholder={t("claims.emptyFilterPlaceholder", {
                status: arrayFilter,
              })}
              searchOptions={{
                value: inputFilter,
                onChange: setSearchByInput,
              }}
              total={total}
            />
          </Col>
          <Col lg={8} xs={12}>
            {renderClaimCard()}
          </Col>
        </Row>
      ) : !isLocked ? (
        <>
          <EmptyPlaceholder
            message={
              isCostPlus
                ? t("claims.emptyPlaceholder")
                : isRetention
                ? t("claims.emptyPlaceholderOnRetention")
                : t("claims.emptyPlaceholderUnClaimedMessage", {
                    amount: maxClaim,
                  })
            }
            buttonText={
              isRetention ? t("claims.goToCostings") : t("claims.createClaim")
            }
            buttonIcon={isRetention ? "sell" : "add"}
            onButtonPress={
              isCostPlus || maxClaim > 0
                ? handleNewClaim
                : isRetention
                ? handleGoToCosting
                : undefined
            }
          />
          {!isCostPlus && (
            <EmptyPlaceholder
              message={t("claims.configureClaimMessage")}
              buttonIcon="settings"
              buttonText={t("claims.configureMethod")}
              onButtonPress={() => configureClaimRef.current?.show(true)}
            />
          )}
        </>
      ) : (
        <EmptyPlaceholder message={t("claims.emptyPlaceholder")} />
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(JobClaims));
