import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { compact } from "lodash";
import moment from "moment";
import DashboardCardField from "../../dashboard/card/DashboardCardField";
import Totals from "../../costing/total";
import CardTable from "../../dashboard/table-card/CardTable";
import {
  TableCardData,
  TableRowActionData,
} from "../../dashboard/table-card/utils";
import { CostingTable, createCostingTable } from "../../costing/utils";
import {
  calcItemsSubtotal,
  calcGST,
  GST_PERCENT,
} from "../../../utils/calculations";
import { formatQuoteNumber } from "../../../utils/text";
import CustomReportCard from "../../dashboard/report-card/custom-report-card";
import {
  Roster,
  RosterItemPayload,
  RosterStatus,
} from "../../../models/roster";
import { DropdownItem } from "../../dashboard/dropdown";
import { useViewInJob } from "../../../hooks/useViewInJobs";
import { getRosterBadgeVariant } from "../../../containers/jobs/job-roster/utils";

type RosterCardProps = Roster & {
  onSend?: (id: string) => void;
  onDelete?: (id: string) => void;
  onEdit?: () => void;
  onPrint?: (id: string) => void;
  onApprove?: (id: string) => void;
  onUnapprove?: (id: string) => void;
  disableCancel?: boolean;
  withoutControls?: boolean;
  isGlobalView?: boolean;
};

const RosterCard: React.FC<RosterCardProps> = (props) => {
  const { t } = useTranslation();

  const {
    _id,
    onSend,
    onPrint,
    onDelete,
    onEdit,
    onApprove,
    onUnapprove,
    status,
    rosterNumber,
    date,
    reference,
    supplier,
    items,
    withoutControls,
    isGlobalView = false,
    job,
    internalNote,
  } = props;

  const categoryData = React.useMemo<TableCardData<CostingTable>>(
    () =>
      createCostingTable(
        items,
        t,
        0,
        ["clientTotal", "margin_amount"],
        "common.description"
      ),
    [items, t]
  );

  const handlePrint = React.useCallback(() => {
    onPrint && onPrint(_id);
  }, [onPrint, _id]);

  const handleDelete = React.useCallback(() => {
    onDelete && onDelete(_id);
  }, [onDelete, _id]);

  const handleSend = React.useCallback(() => {
    onSend && onSend(_id);
  }, [onSend, _id]);

  const handleApprove = React.useCallback(() => {
    onApprove && onApprove(_id);
  }, [onApprove, _id]);

  const handleUnapprove = React.useCallback(() => {
    onUnapprove && onUnapprove(_id);
  }, [onUnapprove, _id]);

  const subtotal = React.useMemo(() => {
    return calcItemsSubtotal(items, 0);
  }, [items]);

  const gst = React.useMemo(() => {
    return calcGST(subtotal, GST_PERCENT);
  }, [subtotal]);

  const dropdownItems = React.useMemo(() => {
    const controls: DropdownItem[] = compact([
      {
        id: "print",
        label: t("common.print"),
        icon: "print",
        outlined: true,
        onClick: handlePrint,
      },
      {
        id: "mail",
        label: t("common.send"),
        icon: "mail",
        outlined: true,
        onClick: handleSend,
      },
    ]);

    if (status === RosterStatus.DRAFT || status === RosterStatus.SENT)
      controls.push({
        id: "approve",
        label: t("common.approve"),
        icon: "check",
        outlined: true,
        onClick: handleApprove,
      });

    if (status === RosterStatus.APPROVED)
      controls.push({
        id: "unapprove",
        label: t("common.unapprove"),
        icon: "cancel",
        outlined: true,
        onClick: handleUnapprove,
      });

    if (status !== RosterStatus.APPROVED) {
      controls.push({
        id: "edit",
        label: t("common.edit"),
        icon: "edit",
        outlined: true,
        onClick: onEdit,
      });
      controls.push({
        id: "delete",
        label: t("common.delete"),
        icon: "delete",
        outlined: true,
        onClick: handleDelete,
      });
    }
    return controls;
  }, [status, onEdit, handlePrint, handleSend, handleDelete, handleApprove]);

  const { buttons } = useViewInJob({
    isGlobalView: isGlobalView,
    jobId: job?._id,
    orderId: _id,
    path: t("navigation.jobsSection.roster"),
  });

  const rosterRowActions: TableRowActionData<
    RosterItemPayload
  >[] = React.useMemo(
    () => [
      {
        icon: "comment",
        dropdownId: "note",
        onClick: () => {},
        shouldRender: (row) => !!row?.note,
        tooltip: (row) => row?.note as string,
      },
    ],
    []
  );

  return (
    <CustomReportCard
      controls={dropdownItems}
      title={formatQuoteNumber(rosterNumber, "RO")}
      badges={[
        {
          variant: getRosterBadgeVariant(status),
          label: t(`roster.statuses.${status}`),
        },
      ]}
      className="roster-card"
      withoutControls={withoutControls}
      buttons={buttons}
    >
      <Row className="main-row">
        <Col className="report-col" lg={6} xs={12} md={6}>
          <DashboardCardField
            placeholder={t("orders.noReference")}
            title={t("orders.reference")}
            content={reference}
          />
        </Col>
        <Col className="report-col" lg={6} xs={12} md={6}>
          <DashboardCardField
            title={t("orders.orderDate")}
            content={moment(date).format("D MMMM YYYY")}
          />
        </Col>
      </Row>

      <Row className="main-row table-row">
        <CardTable
          rowCount
          table={categoryData}
          showCountTitle={true}
          rowActions={rosterRowActions}
        />
      </Row>

      <Row className="row--big">
        {!!internalNote && (
          <Col lg={12} xs={12} className="report-col--big">
            <DashboardCardField
              placeholder={t("orders.noNotes")}
              title={t("common.internalNote")}
              content={internalNote}
              wrapWords
            />
          </Col>
        )}
        <Col lg={7} xs={12} className="report-col--big"></Col>
        <Col lg={5} xs={12} className="report-col--big">
          <Totals
            title={t("orders.amount")}
            gst={gst}
            total={gst + subtotal}
            subtotal={subtotal}
          />
        </Col>
      </Row>
    </CustomReportCard>
  );
};

export default RosterCard;
