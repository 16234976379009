import { TFunction } from "i18next";
import { chain, map } from "lodash";
import { AccountFilters } from ".";
import {
  AccountingTenant,
  AccountingAccount,
  AccountingCustomConfiguration,
  AccountingCustomConfigurationType,
} from "../../../graphql/types/models/integrations";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const createProviderConfigurationFields = (
  t: TFunction,
  onTenantChange: (tenantId: string) => void,
  accountFilters: AccountFilters,
  handleRemoveFilter: (accountFilter: keyof AccountFilters) => void,
  tenants?: AccountingTenant[],
  accounts?: AccountingAccount[],
  customConfiguration?: AccountingCustomConfiguration[]
): GenericFormFields<any> => {
  const tenantOptions = map(tenants, (tenant: AccountingTenant) => ({
    value: tenant.id,
    label: tenant.tenantName,
  }));

  const salesAccountOptions = chain(accounts)
    .filter(accountFilters.filterSales ? { isSales: true } : {})
    .map((account: AccountingAccount) => ({
      value: account.id,
      label: `${account.code} - ${account.name}`,
    }))
    .value();

  const expensesAccountOptions = chain(accounts)
    .filter(accountFilters.filterExpenses ? { isExpenses: true } : {})
    .map((account: AccountingAccount) => ({
      value: account.id,
      label: `${account.code} - ${account.name}`,
    }))
    .value();

  const fields = {
    tenant: {
      type: "default-select",
      label: t("integrations.account"),
      valueKey: "tenantId",
      selectProps: {
        options: tenantOptions,
        onValueChange: onTenantChange,
      },
      inputProps: {
        required: true,
      },
    },
    sales: {
      type: "default-select",
      label: t("integrations.incomeAccount"),
      valueKey: "salesAccountId",
      selectProps: {
        options: salesAccountOptions,
      },
      inputProps: {
        required: true,
      },
      append: accountFilters.filterSales
        ? {
            onClick: () => handleRemoveFilter("filterSales"),
            icon: "filter_list_off",
          }
        : null,
    },
    expenses: {
      type: "default-select",
      label: t("integrations.expensesAccount"),
      valueKey: "expenseAccountId",
      selectProps: {
        options: expensesAccountOptions,
      },
      inputProps: {
        required: true,
      },
      append: accountFilters.filterExpenses
        ? {
            onClick: () => handleRemoveFilter("filterExpenses"),
            icon: "filter_list_off",
          }
        : null,
    },
    title: {
      type: "group-title",
      placeholder: t("integrations.itemsToSync"),
    },
    syncPurchaseOrders: {
      type: "toggle",
      valueKey: "sync.purchaseOrders",
      toggleProps: {
        label: t("integrations.purchaseOrders"),
      },
    },
    syncPurchaseOrderReceipts: {
      type: "toggle",
      valueKey: "sync.purchaseOrderReceipts",
      toggleProps: {
        label: t("integrations.purchaseOrderReceipts"),
      },
    },
    syncProgressClaims: {
      type: "toggle",
      valueKey: "sync.progressClaims",
      toggleProps: {
        label: t("integrations.progressClaims"),
      },
    },
  } as GenericFormFields<any>;

  customConfiguration?.forEach((customConfig) => {
    let type = "text";
    switch (customConfig.type) {
      case AccountingCustomConfigurationType.SELECT:
        type = "default-select";
        break;
      case AccountingCustomConfigurationType.GROUP:
        type = "group-title";
        break;
      default:
    }
    const field = {
      type,
      label:
        customConfig.type !== AccountingCustomConfigurationType.GROUP
          ? customConfig.label
          : null,
      placeholder:
        customConfig.type === AccountingCustomConfigurationType.GROUP
          ? customConfig.label
          : customConfig.placeholder,
      valueKey: `customConfiguration.${customConfig.key}`,
      hint: customConfig.hint,
      selectProps: {
        options: customConfig.options,
      },
    } as any;
    fields[customConfig.key] = field;
  });

  return fields;
};
