import React from "react";
import { EditorState, Modifier } from "draft-js";
import Icon from "../../../../../icons/Icon";
import classNames from "classnames";
import "./styles.scss";

type PDFMergeProps = {
  editorState: EditorState;
  onChange: any;
};

type PDFMergeOptions = {
  [index: string]: string;
};

const MERGE_OPTIONS: PDFMergeOptions = {
  "[ClientFirstName]": "Client First Name",
  "[ClientFullName]": "Client Full Name",
  "[ClientFullAddress]": "Client Full Address",
  "[SiteAddress]": "Site Address",
  "[BuilderFullName]": "Builder Full Name",
  "[BuilderEmail]": "Builder Email",
  "[BuilderABN]": "Builder ABN",
  "[BuilderPhone]": "Builder Phone Number",
  "[BuilderFullAddress]": "Builder Full Address",
  "[QuoteSubtotal]": "Quote Subtotal (ex.)",
  "[QuoteGST]": "Quote GST",
  "[QuoteTotal]": "Quote Total (inc.)",
};

const PDFMerge: React.FC<PDFMergeProps> = (props) => {
  const { editorState, onChange } = props;
  const [displayOptions, setDisplayOptions] = React.useState(false);
  const handleToggle = React.useCallback(
    () => setDisplayOptions(!displayOptions),
    [displayOptions]
  );

  const handleSelectOption = React.useCallback(
    (value: string) => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        value,
        editorState.getCurrentInlineStyle()
      );

      onChange(EditorState.push(editorState, contentState, "insert-fragment"));
    },
    [editorState]
  );

  const renderOptions = React.useCallback(
    () =>
      Object.keys(MERGE_OPTIONS).map(
        (key) =>
          displayOptions && (
            <li
              className="rdw-dropdownoption-default"
              onClick={() => handleSelectOption(key)}
            >
              {MERGE_OPTIONS[key]}
            </li>
          )
      ),
    [MERGE_OPTIONS, displayOptions]
  );

  return (
    <div className="rdw-merge-wrapper merge-tool" onClick={handleToggle}>
      <div className="rdw-dropdown-wrapper rdw-merge-dropdown">
        <a className="rdw-dropdown-selectedtext" title="Template Values">
          <span>Template Values</span>
          <div
            className={classNames({
              "rdw-dropdown-carettoopen": !displayOptions,
              "rdw-dropdown-carettoclose": displayOptions,
            })}
          ></div>
        </a>
        <ul className="rdw-dropdown-optionwrapper">{renderOptions()}</ul>
      </div>
    </div>
  );
};

export default PDFMerge;
