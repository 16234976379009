import { TFunction } from "i18next";
import {
  FormikPropGetSetValues,
  GenericFormFields,
} from "../../../generic-form/GenericFormBody";
import {
  AddPriceListCategoryPayload,
  PriceListUpdateMethod,
} from "../../../../models/price-list";
import { UOMOption } from "../../../../utils/types/options";

const createAddPriceListCategoryFields = (
  t: TFunction,
  uoms: UOMOption[],
  handleUOMCreate: (uom: string) => void,
  onMethodChange: (value: string) => void,
  onFileUpload: (files: File[]) => void,
  costsHaveGST: boolean,
  handleCostsHaveGST: (
    value: boolean,
    formikProps: FormikPropGetSetValues
  ) => void,
  acceptFile?: string,
  fileName?: string,
  updateMethod: PriceListUpdateMethod = PriceListUpdateMethod.INPUT
): GenericFormFields<AddPriceListCategoryPayload> => {
  const data = {
    category: [
      {
        type: "button-select",
        label: t("priceList.methodToUpdate"),
        valueKey: "method",
        buttonSelectProps: {
          onChange: onMethodChange,
          options: [
            {
              value: PriceListUpdateMethod.INPUT,
              label: t("priceList.manualEntry"),
            },
            {
              value: PriceListUpdateMethod.UPLOAD,
              label: t("priceList.uploadPriceList"),
            },
          ],
        },
      },
    ],
    name: {
      width: "200px",
      type: "text",
      label: t("priceList.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },

    upload: [
      {
        type: "file-input",
        label: t("priceList.importFromSpreadsheet"),
        placeholder: t("placeholders.category"),
        valueKey: "file",
        fileInputProps: {
          hideIcon: true,
          onUpload: onFileUpload,
          fileName: fileName,
          accept: acceptFile,
          label: "",
        },
      },
    ],
    items: {
      tableTitle: t("priceList.items"),
      toggles: [
        {
          name: "costsHaveGST",
          label: t("costing.costsHaveGST"),
          onChange: handleCostsHaveGST,
        },
      ],
      rowGenerator: () => ({
        sku: "",
        name: "",
        cost: "",
        UOM: "",
      }),
      isCompact: true,
      row: [
        {
          maxWidth: "150px",
          type: "text",
          label: t("priceList.sku"),
          placeholder: t("priceList.sku"),
          valueKey: "sku",
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
        },
        {
          width: "200px",
          type: "text",
          label: t("priceList.name"),
          placeholder: t("placeholders.name"),
          valueKey: "name",
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
        },
        {
          width: "50px",
          type: "creatable-select",
          label: t("priceList.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
        },
        !costsHaveGST
          ? {
              width: "120px",
              type: "text",
              label: t("priceList.cost"),
              placeholder: t("placeholders.costValue"),
              valueKey: "cost",
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
              },
            }
          : {
              width: "120px",
              type: "text",
              label: t("priceList.costInc"),
              placeholder: t("placeholders.costValue"),
              valueKey: "cost_inc",
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
              },
            },
      ],
    },
  };

  const fields = data as GenericFormFields<AddPriceListCategoryPayload>;

  if (updateMethod === PriceListUpdateMethod.INPUT) {
    delete fields?.upload;
  } else {
    delete fields?.name;
    delete fields?.items;
  }
  return fields;
};

export default createAddPriceListCategoryFields;
