import { ApolloCache, MutationUpdaterFn } from "@apollo/client";

import { LIST_SITE_DIARIES } from "./queries";
import { concat, filter, findIndex, map, omit } from "lodash";
import {
  SiteDiaryListResponse,
  SiteDiary,
  SiteDiaryDeleteResponse,
  SiteDiaryShareResponse,
  SiteDiaryCreateResponse,
} from "../../types/models/site-diary";
import { Media } from "../../../models/media";
import { SITE_DIARY_FRAGMENT, SITE_DIARY_OVERVIEW_FRAGMENT } from "./fragments";

const getSiteDiaryList = (cache: ApolloCache<any>, jobId: string) => {
  const siteDiaryListResponse = cache.readQuery<SiteDiaryListResponse>({
    query: LIST_SITE_DIARIES,
    variables: {
      jobId,
    },
  });

  return siteDiaryListResponse?.listSiteDiaryOverview;
};

const updateSiteDiaryList = (
  cache: ApolloCache<any>,
  jobId: string,
  siteDiaryList: SiteDiary[]
) => {
  cache.writeQuery<SiteDiaryListResponse>({
    query: LIST_SITE_DIARIES,
    variables: {
      jobId,
    },
    data: {
      listSiteDiaryOverview: siteDiaryList,
    },
  });
};

const decacheAttachments = (attachments?: Media[]) => {
  return (
    attachments?.map((attachment) => ({
      ...attachment,
      url: attachment.url + `?${+new Date()}`,
    })) || []
  );
};

export const handleDiaryAdd = (
  jobId: string
): MutationUpdaterFn<SiteDiaryCreateResponse> => (cache, response) => {
  const siteDiary = response.data?.createUpdateSiteDiary;
  if (!siteDiary) return;
  const updatedSiteDiary: SiteDiary = {
    ...siteDiary,
    attachments: decacheAttachments(siteDiary.attachments),
    values: siteDiary.values.map((fieldValue) => ({
      ...fieldValue,
      attachments: decacheAttachments(fieldValue.attachments),
      subValues: fieldValue.subValues?.map((subFieldValue) => ({
        ...subFieldValue,
        attachments: decacheAttachments(subFieldValue.attachments),
      })),
    })),
  };

  (updatedSiteDiary as any).__typename = "SiteDiaryOverview"; // fix for crash when creating SD

  const siteDiaryList = getSiteDiaryList(cache, jobId);
  if (siteDiaryList) {
    const index = findIndex(siteDiaryList, { _id: siteDiary?._id });
    if (index >= 0) {
      const updatedSiteDiaries = [...siteDiaryList];
      updatedSiteDiaries[index] = updatedSiteDiary;
      updateSiteDiaryList(cache, jobId, updatedSiteDiaries);
    } else {
      const updatedSiteDiaries = [...siteDiaryList, updatedSiteDiary];
      updateSiteDiaryList(cache, jobId, updatedSiteDiaries);
    }
  }
  const id = `SiteDiaryOverview:${siteDiary?._id}`;
  const siteDiaryFragment = cache.readFragment({
    id,
    fragment: SITE_DIARY_OVERVIEW_FRAGMENT,
  });

  if (!siteDiaryFragment) return;

  cache.writeFragment({
    id,
    fragment: SITE_DIARY_OVERVIEW_FRAGMENT,
    data: updatedSiteDiary,
  });
};

export const handleDiaryDelete = (
  jobId?: string
): MutationUpdaterFn<SiteDiaryDeleteResponse> => (cache, response) => {
  const deletedDiary = response.data?.deleteSiteDiary;
  if (!jobId) return;

  const siteDiaryList = getSiteDiaryList(cache, jobId);

  if (!deletedDiary || !siteDiaryList) {
    return;
  }
  const updatedSiteDiaries = filter(
    siteDiaryList,
    (diary) => diary._id !== deletedDiary._id
  );
  updateSiteDiaryList(cache, jobId, updatedSiteDiaries);
};

export const handleDiaryShare = (
  jobId: string
): MutationUpdaterFn<SiteDiaryShareResponse> => (cache, { data }) => {
  const sharedDiary = data?.shareSiteDiaryWithClient;
  const cacheData = getSiteDiaryList(cache, jobId);

  if (!sharedDiary || !cacheData) return;

  const newData = map(cacheData, (siteDiary) =>
    siteDiary._id === sharedDiary._id
      ? { ...siteDiary, is_client_shared: sharedDiary.is_client_shared }
      : siteDiary
  );
  updateSiteDiaryList(cache, jobId, newData);
};
