import React from "react";
import AvatarGroup from "../../avatar-group";
import { Button } from "react-bootstrap";
import { chain } from "lodash";
import { useTranslation } from "react-i18next";
import Avatar from "../../avatar";
import { TeamMember } from "../../../models/team-member";

type TeammateHeaderTopProps = {
  teammates?: TeamMember[];
  onAddClick: () => void;
  isReadonly?: boolean;
};

const TeammateHeader: React.FC<TeammateHeaderTopProps> = (props) => {
  const { teammates, onAddClick, isReadonly = false } = props;

  const { t } = useTranslation();

  const renderAvatars = () => {
    return chain(teammates)
      .take(4)
      .map((teammate) => {
        const fullName = `${teammate.first_name} ${teammate.last_name}`;

        return (
          <Avatar
            key={teammate._id}
            outlined
            fallbackColor="light"
            userName={fullName}
            rounded
            width="35px"
            height="35px"
            url={teammate.profileImage?.url}
          />
        );
      })
      .value();
  };

  return (
    <>
      <div className="mr-2">
        <AvatarGroup>{renderAvatars()}</AvatarGroup>
      </div>
      {!isReadonly && (
        <Button
          className="add-btn button success rounded-pill"
          onClick={onAddClick}
        >
          {t("headerTeammate.addTeammate")}
        </Button>
      )}
    </>
  );
};

export default TeammateHeader;
