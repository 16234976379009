import { TFunction } from "i18next";
import { chain, map, range } from "lodash";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { CreateTaskPayload } from "../../../models/job";
import { SupplierListItem } from "../../../models/supplier";
import { TaskAssignTo } from "../../../utils/options";
import { TeamMember } from "../../../models/team-member";

export const assignTasksFields = (
  t: TFunction,
  suppliers: SupplierListItem[],
  openSupplierModal: () => void,
  team: TeamMember[],
  onAssignToChange: (type: string) => void,
  assignTo: TaskAssignTo,
  customReminder: number,
  handleReminderCreate: (value: string) => void
): GenericFormFields<CreateTaskPayload> => {
  const supplierOptions = map(suppliers, (supplier) => ({
    value: supplier._id,
    label: supplier.business_name,
  }));

  const teamOptions = map(team, (member) => ({
    value: member._id,
    label: [member.first_name, member.last_name].join(" "),
  }));

  const reminderOptions = chain([customReminder, ...range(1, 15)])
    .compact()
    .map((day) => ({
      value: day.toString(),
      label: t("schedule.daysBefore", { count: day }),
    }))
    .value();

  return {
    assignType: {
      type: "button-select",
      label: t("common.assignTo"),
      valueKey: "assignTo",
      buttonSelectProps: {
        onChange: onAssignToChange,
        options: [
          {
            value: TaskAssignTo.TEAM,
            label: t("schedule.teamMember"),
          },
          {
            value: TaskAssignTo.SUPPLIER,
            label: t("schedule.supplier"),
          },
        ],
      },
    },
    assignTo:
      assignTo === TaskAssignTo.TEAM
        ? {
            type: "select",
            label: t("schedule.teamMember"),
            placeholder: t("schedule.select"),
            valueKey: "user",
            autocompleteProps: {
              options: teamOptions,
              isClearable: true,
            },
          }
        : {
            type: "select",
            label: t("schedule.supplier"),
            placeholder: t("schedule.select"),
            valueKey: "supplier",
            autocompleteProps: {
              options: supplierOptions,
              isClearable: true,
            },
            append: {
              onClick: openSupplierModal,
              icon: "add",
            },
          },
    daysBefore: {
      type: "creatable-select",
      label: t("schedule.reminder"),
      placeholder: t("schedule.select"),
      valueKey: "reminder.daysBefore",
      creatableProps: {
        options: reminderOptions,
        isClearable: true,
        autoSelectCreatedOption: true,
        isValidNewOption: (v: string) => Number(v) > 0,
        formatCreateLabel: (v: string) =>
          t("schedule.daysBefore", { count: Number(v) }),
        onCreateOption: handleReminderCreate,
      },
    },
  };
};
