import { Formik, FormikHelpers, FormikProps } from "formik";
import { chain, startCase, trim } from "lodash";
import React from "react";
import { Form } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import GenericFormBody, {
  GenericFormFields,
} from "../generic-form/GenericFormBody";
import { SelectOption } from "../generic-form/inputs/creatable-select";
import Icon from "../icons/Icon";
import { createTagsField } from "./utils";
import "./style.scss";

type TagsProps<TItem> = {
  items?: TItem[];
  allTags?: TItem[];
  onSubmit: (tags: string[] | null) => void;
  children?: React.ReactNode;
  placeholder?: string;
  badgeStyle?: string;
};

type BaseItem = {
  _id: string;
  name: string;
};

const EditableTags = <TItem extends BaseItem>({
  items,
  onSubmit,
  badgeStyle,
  placeholder,
  allTags,
}: TagsProps<TItem>) => {
  const [activeInput, setActiveInput] = React.useState<boolean>(false);
  const [curentTags, setCurentTags] = React.useState<SelectOption[]>([]);
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<{ tags: string[] }>
  >({});
  const formikRef = React.useRef<any>();

  const handleSubmit = ({ tags }: { tags: string[] }) => {
    setActiveInput(false);
    if (!tags) {
      onSubmit(null);
      return;
    }

    onSubmit(tags);
  };

  const handleTempTagsCreate = React.useCallback(
    (tag: string) => {
      const value = startCase(trim(tag.toLowerCase()));
      const option = {
        value: value,
        label: value,
      };

      setCurentTags([...curentTags, option]);
      const currentValue = formikRef.current?.values?.tags;
      const newValues = [...currentValue, value];
      setTimeout(() => {
        formikRef.current?.setFieldValue("tags", newValues);
      }, 100);
    },
    [curentTags, formikRef]
  );

  React.useEffect(() => {
    const tagOptions = chain(allTags)
      .map((tag: BaseItem) => ({
        value: tag.name,
        label: tag.name,
      }))
      .concat(curentTags)
      .uniqBy("value")
      .value();
    setFormFields(createTagsField<BaseItem>(tagOptions, handleTempTagsCreate));
  }, [handleTempTagsCreate, curentTags, allTags]);

  const renderBody = React.useCallback(
    (props: FormikProps<{ tags: string[] }>) => {
      return <GenericFormBody fields={formFields} {...props} />;
    },
    [items, allTags, formFields]
  );

  return (
    <div className="d-flex align-items-center tag-container">
      {/* <Icon name="label" style={{ color: "#7d829e", marginRight: "5px" }} /> */}
      {activeInput ? (
        <Formik
          innerRef={formikRef}
          initialValues={{ tags: items?.map((item) => item?.name || "") ?? [] }}
          onSubmit={(
            values: { tags: string[] },
            formikHelpers: FormikHelpers<{ tags: string[] }>
          ): void | Promise<any> => {
            return handleSubmit(values);
          }}
        >
          {(props) => (
            <Form
              noValidate
              onSubmit={props.handleSubmit}
              onReset={props.handleReset}
            >
              <div className="input-tags-container">
                {renderBody(props)}
                <button className="submitButton" type="submit">
                  <Icon style={{ color: "white" }} name="check" />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          {items ? (
            items.map((tag, index) => (
              <Badge
                key={`${tag?._id}-${index}`}
                className={badgeStyle ? `tag-badge ${badgeStyle}` : "tag-badge"}
                variant="secondary"
              >
                {tag?.name}
              </Badge>
            ))
          ) : (
            <span className="tags-placeholder">{placeholder}</span>
          )}
        </>
      )}

      {!activeInput && (
        <button onClick={() => setActiveInput(true)} className="editButton">
          <Icon
            name="create"
            outlined
            style={{ color: "#7d829e", marginRight: "5px" }}
          />
        </button>
      )}
    </div>
  );
};

export default EditableTags;
