import React from "react";
import { useTranslation } from "react-i18next";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import LeftModal from "../../left-modal";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import { SelectionCategoryItemType } from "../../../graphql/types/models/selections";
import CategorySelectionItem from "../../selection/selection-category-item";
import "./styles.scss";

export type SelectionOptionsModalRef = ModalDisplayRef & {
  setData: (data: SelectionCategoryItemType) => void;
};

const SelectionOptionsModal: React.FC = (_props, ref) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState<SelectionCategoryItemType | null>(
    null
  );

  const { shouldShow, hide } = useModalDisplay<SelectionOptionsModalRef>(
    ref,
    {
      setData: (data: SelectionCategoryItemType) => {
        setData(data);
      },
    } as SelectionOptionsModalRef,
    [setData]
  );

  if (!data) return <></>;

  return (
    <LeftModal show={shouldShow} onHide={hide} className="selection-options">
      <LeftModalHeader title={t("selections.selection")} onClose={hide} />
      <LeftModalBody>
        <CategorySelectionItem item={data} isLocked={true} isOpenItem={true} />
      </LeftModalBody>
    </LeftModal>
  );
};

export default React.forwardRef(SelectionOptionsModal);
