import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { Container, Row, Col } from "react-bootstrap";
import ModalForm from "../../modals/modal-form";
import { map, get, chain, uniqBy, find, trim, omit, pick } from "lodash";
import { useApolloClient, useMutation } from "@apollo/client";
import { notify } from "../../../components/notification";

import {
  GenericFormFields,
  GenericFormTable,
  renderField,
  renderTableForm,
} from "../../generic-form/GenericFormBody";

import { UOMOption } from "../../../utils/types/options";

import createRosterFields from "./utils";
import CalculatorModal, { CalcFieldData } from "../../modals/calculator";
import { CalcBag, Rounding } from "../../calculator/utils";
import createRosterSchema from "./Roster.schema";
import { VariationPayload } from "../../../models/variations";

import { MEASUREMENT_OPTIONS } from "../../../utils/options";

import ModalTotals from "../../modals/modal-totals";

import { RosterItemPayload, RosterPayload } from "../../../models/roster";
import { CreateUpdateRosterResponse } from "../../../graphql/types/models/job-roster";
import { CREATE_UPDATE_ROSTER } from "../../../graphql/queries/job-roster/mutations";
import { handleRosterCreateUpdate } from "../../../graphql/queries/job-roster/utils";

import {
  CostingAllocationType,
  useJobCostingAllocate,
} from "../../../hooks/useJobCostingAllocate";
import "./styles.scss";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import {
  findPriceItemFromCache,
  searchPriceItem,
} from "../../../graphql/queries/price-list/utils";
import {
  findCostingItemFromCache,
  findCostingItemFromCostingsCache,
  searchCostingItem,
} from "../../../graphql/queries/job-costing/utils";
import { JobCostingItem, SearchJobCostingItem } from "../../../models/job";

type CreateRosterModalProps = {
  editMode?: boolean;
  roster?: RosterPayload | null;
  costingItems?: JobCostingItem[] | null;
  jobId: string | null | undefined;
  onSubmit: (values: CreateUpdateRosterResponse) => void;
  onClose?: () => void;
};

export type CreateRosterModalRef = {
  show: (show: boolean) => void;
};

const CreateRosterModal: React.FC<CreateRosterModalProps> = (props, ref) => {
  const { onSubmit, editMode, roster, costingItems, jobId, onClose } = props;
  const [show, setShow] = useState(false);
  const [items, setItems] = React.useState<SelectOption[]>([]);
  const client = useApolloClient();

  React.useImperativeHandle(ref, () => ({
    show: (show: boolean) => {
      setShow(show);
    },
  }));

  const {
    categoryList: costingCategoryList,
    openAllocateCosting,
    getAllocateProps,
    handleCreateCategory,
    handleSelectCategory,
    renderSelectCostingModal,
  } = useJobCostingAllocate({
    type: CostingAllocationType.PurchaseOrder,
    jobId,
    isTable: true,
  });

  const [createUpdateRoster] = useMutation<CreateUpdateRosterResponse>(
    CREATE_UPDATE_ROSTER,
    {
      onCompleted: (result) => {
        notify({
          title: editMode ? t("roster.updateRoster") : t("roster.createRoster"),
          content: editMode
            ? t("roster.success.updateRoster")
            : t("roster.success.createRoster"),
        });
        onSubmit(result);
        setShow(false);
      },
      update: handleRosterCreateUpdate(jobId),
    }
  );

  const onRosterSubmit = React.useCallback(
    (roster: RosterPayload) => {
      const preparedItems = map(roster.items, (item, index) => {
        const preparedItem = {
          ...omit(item, ["items", "roster"]),
          raw_quantity: item.quantity?.toString(),
          itemNumber: index + 1,
          cost: Number(item.cost),
        };

        if (!item.rounding || item.rounding === Rounding.NONE) {
          delete preparedItem?.rounding;
        }
        return preparedItem;
      });

      const request = {
        variables: {
          jobId,
          roster: {
            ...roster,
            items: preparedItems,
          },
        },
      };

      return createUpdateRoster(request);
    },
    [jobId, editMode, createUpdateRoster]
  );

  const [currentUOMOptions, setCurrentUOMOptions] = useState<UOMOption[]>(
    MEASUREMENT_OPTIONS.map((item) => {
      return {
        value: item.value.toString(),
        label: item.label.toString(),
      };
    })
  );

  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<
    GenericFormFields<RosterPayload>
  >({});

  const [calcData, setFieldData] = useState<CalcFieldData>({
    fieldName: "",
  });

  const [showCalculator, setCalculatorVisibility] = useState(false);

  const openCalculatorModal = React.useCallback(
    (fieldName: string, fieldValue?: string, rowIndex?: number) => {
      setFieldData({
        fieldName,
        rowIndex,
      });
      setCalculatorVisibility(true);
    },
    []
  );

  const handleCalculatorSubmit = React.useCallback(
    (formikProps: FormikProps<VariationPayload>, calcBag: CalcBag) => {
      formikProps.setFieldValue(
        `items[${calcData.rowIndex}].rounding`,
        calcBag.rounding
      );
      formikProps.setFieldValue(
        `items[${calcData.rowIndex}].wastage`,
        calcBag.wastage
      );
      setCalculatorVisibility(false);
    },
    [calcData]
  );

  const closeCalcModal = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, []);

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        currentUOMOptions.concat([{ label: uom, value: uom }]),
        "value"
      );
      setCurrentUOMOptions(newUOMs);
    },
    [currentUOMOptions, setCurrentUOMOptions]
  );

  const handlePriceSearch = React.useCallback(
    (value: string) => searchPriceItem(client, value),
    [client]
  );

  const handleCostingSearch = React.useCallback(
    async (value: string) => {
      if (!jobId) return [] as SearchJobCostingItem[];
      return searchCostingItem(client, jobId, value);
    },
    [client, jobId]
  );

  const addItem = React.useCallback(
    (name: string) => {
      const newItem = {
        label: name,
        value: name,
        hidden: true,
      } as SelectOption;
      setItems(uniqBy([...items, newItem], "value"));
    },
    [items]
  );

  const initialValues = React.useMemo(() => {
    if (!editMode || !roster) {
      const template = {
        reference: "",
        internalNote: "",
        date: new Date().toISOString(),
        status: "DRAFT",
        items:
          costingItems && costingItems.length > 0
            ? costingItems.map(
                (costingItem) =>
                  ({
                    costingItemId: costingItem._id,
                    costingCategoryName: costingItem?.category?.name,
                    ...pick(costingItem, [
                      "UOM",
                      "cost",
                      "name",
                      "quantity",
                      "raw_quantity",
                      "rounding",
                      "wastage",
                      "margin_amount",
                      "note",
                    ]),
                  } as RosterItemPayload)
              )
            : [
                {
                  UOM: "qty",
                  name: "",
                  note: "",
                  cost: 0,
                  quantity: null,
                  costingCategoryName: null,
                  rounding: Rounding.NONE,
                  wastage: 0,
                },
              ],
      };

      return template;
    }

    return roster;
  }, [editMode, roster, costingItems]);

  const handlePriceSelect = React.useCallback(
    async (fieldValue, rowIndex, formikProps) => {
      if (!fieldValue || !jobId) return;

      const fieldsArray = `items[${rowIndex}]`;
      const { setFieldValue, values } = formikProps;
      const costingItemId = get(values, fieldsArray)?.costingItemId;

      // skip further price/costing checks if line item is part of an assembly
      if (costingItemId) {
        const assemblyItem = find(initialValues.items, {
          name: fieldValue,
          costingItemId,
        });
        if (assemblyItem) {
          return;
        }
      }

      const priceItem = findPriceItemFromCache(client, fieldValue);
      const costingItem = await findCostingItemFromCostingsCache(
        client,
        jobId,
        fieldValue
      );

      if (!priceItem && !costingItem) {
        // check if value has changed
        // const costingId = get(values, fieldsArray)?.costingItemId;
        // if (costingId) {
        //   const item = findCostingItemFromCache(client, costingId);
        //   if (!item || item.name !== fieldValue) {
        //     setFieldValue(`${fieldsArray}.costingItemId`, "");
        //   }
        // }
        return;
      }

      if (priceItem || costingItem) {
        const UOM = trim(priceItem?.UOM || costingItem?.UOM) || "";
        // setFieldValue(`${fieldsArray}.name`, trim(priceItem?.name || costingItem?.name) || "");
        setFieldValue(`${fieldsArray}.UOM`, UOM);
        setFieldValue(
          `${fieldsArray}.cost`,
          priceItem?.cost || costingItem?.cost || ""
        );

        if (costingItem) {
          const item = costingCategoryList?.find(
            (c) => c.label === costingItem?.category?.name
          );
          setFieldValue(`${fieldsArray}.costingCategoryName`, item?.value);
          setFieldValue(`${fieldsArray}.costingItemId`, costingItem._id);
        }
        setFieldValue(
          `${fieldsArray}.name`,
          priceItem?.name || costingItem?.name
        );

        addItem(priceItem?.name || costingItem?.name || "");
        UOM && handleUOMCreate(UOM); // add UOM to dropdown
        return;
      }
      // setFieldValue(`${fieldsArray}.name`, fieldValue);
      addItem(fieldValue);
    },
    [jobId, addItem, initialValues, costingCategoryList, handleUOMCreate]
  );

  React.useEffect(() => {
    setFormFields(
      createRosterFields(
        t,
        openCalculatorModal,
        currentUOMOptions,
        costingCategoryList,
        handleUOMCreate,
        handleCreateCategory,
        handleSelectCategory,
        openAllocateCosting,
        getAllocateProps,
        items,
        handlePriceSelect,
        handleCostingSearch,
        handlePriceSearch
      )
    );
  }, [
    t,
    openCalculatorModal,
    currentUOMOptions,
    handleUOMCreate,
    handleCreateCategory,
    costingCategoryList,
    handleSelectCategory,
    openAllocateCosting,
    getAllocateProps,
    items,
  ]);

  useEffect(() => {
    const defUOAM = MEASUREMENT_OPTIONS.map((item) => {
      return {
        value: item.value.toString(),
        label: item.label.toString(),
      };
    });

    if (initialValues && initialValues.items) {
      initialValues.items.map((item: any) => {
        if (item.UOM)
          defUOAM.push({
            value: item.UOM,
            label: item.UOM,
          });
      });
    }

    setCurrentUOMOptions(chain(defUOAM).uniqBy("value").value());
  }, [initialValues, roster, setCurrentUOMOptions]);

  const title = React.useMemo(
    () => (editMode && roster ? t("roster.editRoster") : t("roster.newRoster")),
    [editMode, roster]
  );

  const handleClose = React.useCallback(() => {
    onClose && onClose();
    setShow(false);
  }, [onClose]);

  return (
    <>
      <ModalForm<RosterPayload>
        show={show}
        editMode={editMode}
        validationSchema={createRosterSchema(t)}
        data={initialValues}
        className="roster-modal"
        title={title}
        onSubmit={onRosterSubmit}
        onClose={handleClose}
      >
        {(formikProps: FormikProps<RosterPayload>) => (
          <Container className="generic-form-body" fluid>
            {renderSelectCostingModal()}
            <CalculatorModal
              formikProps={formikProps}
              show={showCalculator}
              onSubmit={handleCalculatorSubmit}
              fieldName={calcData.fieldName}
              initialValue={
                get(formikProps.values, calcData.fieldName.split(".")[0])
                  ?.raw_quantity || get(formikProps.values, calcData.fieldName)
              }
              wastage={(
                get(
                  formikProps.values,
                  `items[${calcData.rowIndex}].wastage`,
                  ""
                ) || ""
              ).toString()}
              rounding={(
                get(
                  formikProps.values,
                  `items[${calcData.rowIndex}].rounding`,
                  ""
                ) || ""
              ).toString()}
              onClose={closeCalcModal}
              title={
                get(formikProps.values, calcData.fieldName.split(".")[0])?.name
              }
            />
            <Row>
              {/* {renderField(formikProps, formFields.supplier, 4)} */}
              <Col xs={12} md={12} className="col-no-gutters">
                <Row>
                  {renderField(formikProps, formFields.status, 4)}
                  {renderField(formikProps, formFields.date, 4)}
                  {renderField(formikProps, formFields.reference, 4)}
                  {/* {renderField(formikProps, formFields.deliveryDate, 4)} */}
                </Row>
              </Col>
            </Row>

            <Row>
              {renderTableForm(
                formikProps,
                formFields.items as GenericFormTable<RosterPayload, any>,
                "items"
              )}
            </Row>
            <Row>
              <Col lg={12} xs={12} className="col-no-gutters">
                {renderField(formikProps, formFields.internalNote, 12)}
              </Col>
            </Row>

            <Row>
              <Col lg={7} xs={12}>
                {/* <div className="field-text field-text--underlined delivery-title">
                  {t("orders.delivery")}
                </div>
                <Row className="row-m">
                  <Col lg={6} xs={12} className="col-no-gutters">
                    {renderField(formikProps, formFields.reference, 12)}
                    {renderField(formikProps, formFields.attention, 12)}
                    {renderField(formikProps, formFields.contactNumber, 12)}
                    {renderField(formikProps, formFields.deliveryAddress, 12)}
                  </Col>
                  <Col lg={6} xs={12} className="col-no-gutters">
                    {renderField(
                      formikProps,
                      formFields.deliveryInstructions,
                      12
                    )}
                  </Col>
                </Row> */}
              </Col>

              <Col lg={5} xs={12}>
                <ModalTotals
                  items={formikProps.values.items}
                  margin={0}
                  title={t("orders.amount")}
                />
              </Col>
            </Row>
          </Container>
        )}
      </ModalForm>
    </>
  );
};

export default React.forwardRef(CreateRosterModal);
