import React from "react";
import { useDropzone } from "react-dropzone";

import Icon from "../../../icons/Icon";

type PlansDragzoneProps = {
  onFilesUpload: (files: File[]) => void;
};

const PlansDragzone: React.FC<PlansDragzoneProps> = ({ onFilesUpload }) => {
  // const {t} = useTranslation();
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      onFilesUpload(acceptedFiles);
    },
    [onFilesUpload]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ["application/pdf"],
  });

  return (
    <div {...getRootProps()} className="drag-and-drop-zone">
      <Icon name="description" outlined />
      <input {...getInputProps()} />
      <div className="description">
        {isDragActive ? (
          <p className="field-text">Drop the files here ...</p>
        ) : (
          <p className="field-text">
            Drag and drop or <br /> click here to upload your plans
          </p>
        )}
      </div>
    </div>
  );
};

export default PlansDragzone;
