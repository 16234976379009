import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function importSelectionFromTemplateSchema(t: TFunction) {
  return yup.object().shape({
    templateId: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "selections.selectionTemplate"
        )
      ),
  });
}
