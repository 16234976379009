import React from "react";
import { useApolloClient } from "@apollo/client";
import { useDownloadFile } from "../useDownloadFile";
import { EXPORT_TIMESHEETS } from "../../graphql/queries/timesheet/queries";
import {
  ExportTimesheetsRequest,
  ExportTimesheetsResponse,
} from "../../graphql/types/models/timesheet";
import { TimesheetFilterInput } from "../../models/timesheet";

export function useTimesheetExportQuery() {
  const { downloadFile } = useDownloadFile();
  const client = useApolloClient();

  const exportTimesheets = React.useCallback(
    async (filter: TimesheetFilterInput) => {
      try {
        const result = await client.query<
          ExportTimesheetsResponse,
          ExportTimesheetsRequest
        >({
          query: EXPORT_TIMESHEETS,
          variables: {
            filter,
          },
          fetchPolicy: "network-only",
        });
        const exportData = result?.data?.exportTimesheets;
        downloadFile(exportData.data, exportData.filename);
      } catch (e) {}
    },
    []
  );

  return { exportTimesheets };
}
