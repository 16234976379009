import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import {
  UserTimesheetsResponse,
  DeleteTimesheetResponse,
  CreateUpdateTimesheetResponse,
  ListTimesheetsResponse,
} from "../../types/models/timesheet";
import { Timesheet } from "../../../models/timesheet";
import { LIST_TIMESHEETS, USER_TIMESHEETS } from "./queries";
import { concat, filter, map } from "lodash";
import {
  TimesheetsFilters,
  TimesheetsPagination,
} from "../../../containers/timesheets/timesheets-overview";

type TimesheetsListInput = {
  filterTimesheets: TimesheetsFilters;
  pagination: TimesheetsPagination;
};

const getUserTimesheets = (cache: ApolloCache<any>, isProcessed: boolean) => {
  const userTimesheetsResponse = cache.readQuery<UserTimesheetsResponse>({
    query: USER_TIMESHEETS,
    variables: {
      isProcessed,
    },
  });

  return userTimesheetsResponse?.getUserTimesheets;
};

const getListTimesheets = (
  cache: ApolloCache<any>,
  { filterTimesheets, pagination }: TimesheetsListInput
) => {
  const listTimesheetsResponse = cache.readQuery<ListTimesheetsResponse>({
    query: LIST_TIMESHEETS,
    variables: {
      filter: filterTimesheets,
      pagination,
    },
  });

  return listTimesheetsResponse?.listTimesheets;
};

const updateUserTimesheets = (
  cache: ApolloCache<any>,
  timesheets: Timesheet[],
  isProcessed: boolean
) => {
  cache.writeQuery<UserTimesheetsResponse>({
    query: USER_TIMESHEETS,
    variables: {
      isProcessed,
    },
    data: {
      getUserTimesheets: timesheets,
    },
  });
};

const updatListTimesheets = (
  cache: ApolloCache<any>,
  listTimesheets: ListTimesheetsResponse,
  { filterTimesheets, pagination }: TimesheetsListInput
) => {
  cache.writeQuery<ListTimesheetsResponse>({
    query: LIST_TIMESHEETS,
    variables: {
      filter: filterTimesheets,
      pagination,
    },
    data: {
      listTimesheets: listTimesheets.listTimesheets,
    },
  });
};

export const handleDeleteTimesheet = ({
  filterTimesheets,
  pagination,
}: TimesheetsListInput): MutationUpdaterFn<DeleteTimesheetResponse> => (
  cache,
  response
) => {
  const deletedTimesheet = response.data?.deleteTimesheet;
  const userTimesheets = getListTimesheets(cache, {
    filterTimesheets,
    pagination,
  });

  if (!deletedTimesheet || !userTimesheets) return;

  const filteredTimesheets = filter(
    userTimesheets.timesheets,
    (timesheet) => timesheet._id !== deletedTimesheet._id
  );

  const filteredList = {
    listTimesheets: { ...userTimesheets, timesheets: filteredTimesheets },
  };
  updatListTimesheets(cache, filteredList, { filterTimesheets, pagination });
};

export const handleAddTimesheet = ({
  filterTimesheets,
  pagination,
}: TimesheetsListInput): MutationUpdaterFn<CreateUpdateTimesheetResponse> => (
  cache,
  response
) => {
  const addedTimesheet = response.data?.createUpdateTimesheet;
  const userTimesheets = getListTimesheets(cache, {
    filterTimesheets,
    pagination,
  });

  if (!addedTimesheet || !userTimesheets) return;
  const newList = concat(userTimesheets.timesheets, addedTimesheet);
  const filteredList = {
    listTimesheets: { ...userTimesheets, timesheets: newList },
  };

  updatListTimesheets(cache, filteredList, { filterTimesheets, pagination });
};

export const handleUpdateTimesheet = (
  isProcessed: boolean = false
): MutationUpdaterFn<CreateUpdateTimesheetResponse> => (cache, response) => {
  const updatedTimesheet = response.data?.createUpdateTimesheet;
  const userTimesheets = getUserTimesheets(cache, isProcessed);

  if (!updatedTimesheet || !userTimesheets) return;

  const timesheets = map(userTimesheets, (timesheet) =>
    timesheet._id === updatedTimesheet._id ? updatedTimesheet : timesheet
  );
  updateUserTimesheets(cache, timesheets, isProcessed);
};
