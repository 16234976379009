import { gql } from "@apollo/client";

export const LIST_ASSEMBLIES = gql`
  query {
    listAssemblies {
      _id
      name
      UOM
      total
      items {
        UOM
        cost
        name
        total
        quantity
        raw_quantity
      }
    }
  }
`;
