import { gql } from "@apollo/client";

export const SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT = gql`
  fragment SalesQuotePrintTemplateFragment on SalesQuotePrintTemplate {
    _id
    name
    is_system
    theme {
      backgroundColour
      textColour
    }
    cover {
      enabled
      title
      subtitle
      expiryDays
      style
      background {
        _id
        name
        url
        upload_url
        type
      }
      logo {
        _id
        name
        url
        upload_url
        type
      }
      display {
        logo
      }
    }
    letter {
      enabled
      richContent {
        text
        html
        raw
      }
    }
    costings {
      enabled
      title
      richContent {
        text
        html
        raw
      }
      display {
        categoryTotal
        costIncGST
        items
        itemUnits
        itemCost
        itemTotal
        freeItems
        assemblyItems
        itemNotes
      }
    }
    allowances {
      enabled
      title
      richContent {
        text
        html
        raw
      }
      display {
        categoryTotal
        costIncGST
        items
        itemUnits
        itemCost
        itemTotal
        freeItems
        assemblyItems
        itemNotes
        itemCostExcludeMarkup
      }
    }
    specifications {
      enabled
      title
      richContent {
        text
        html
        raw
      }
      display {
        specificationNumber
      }
    }
    notes {
      enabled
      title
      richContent {
        text
        html
        raw
      }
      display {
        costingItemNotes
      }
    }
    summary {
      enabled
      title
      richContent {
        text
        html
        raw
      }
      display {
        category
        categoryTotal
        costIncGST
        gst
      }
    }
    terms {
      enabled
      title
      richContent {
        text
        html
        raw
      }
    }
    acceptance {
      enabled
      title
      richContent {
        text
        html
        raw
      }
    }
    footer {
      enabled
      richContent {
        text
        html
        raw
      }
      display {
        pageNumbers
      }
    }
    childTemplate {
      letter {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
      costings {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
      allowances {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
      specifications {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
      notes {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
      summary {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
      terms {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
      acceptance {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
      footer {
        isRichContentSpecificToQuote
        richContent {
          text
          html
          raw
        }
      }
    }
  }
`;
