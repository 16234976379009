import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useMutation } from "@apollo/client";
import { VerifyAffiliateResponse } from "../../graphql/types/models/affiliate";
import { VERIFY_AFFILIATE } from "../../graphql/queries/affiliate/mutations";

type AffiliateLinkContainerProps = {};

const AffiliateLinkContainer: React.FC<AffiliateLinkContainerProps> = () => {
  const match = useRouteMatch();
  const { affiliateCode } = match.params as any;
  const { storedValue, setStoredValue } = useLocalStorage(
    "@AFFILIATE_CODE",
    null
  );
  const [verifyAffiliate] = useMutation<VerifyAffiliateResponse>(
    VERIFY_AFFILIATE,
    {
      onCompleted: ({ affiliateVerification }) => {
        if (!storedValue && affiliateVerification) {
          setStoredValue(affiliateCode);
        }
        window.location.replace(process.env.REACT_APP_WEBSITE_URL as string);
      },
    }
  );

  React.useEffect(() => {
    verifyAffiliate({
      variables: {
        affiliateCode,
      },
    });
  }, []);

  return null;
};

export default AffiliateLinkContainer;
