import React from "react";

type LinkProps = {
  href?: string;
};

const Link: React.FC<LinkProps> = (props) => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

export default Link;
