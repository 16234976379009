import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import "./styles.scss";
import createForgetPasswordFormSchema from "./ForgetPasswordForm.schema";
import AuthenticationCard from "../authentication-card";
import ForgetPasswordFormBody from "./ForgetPasswordFormBody";
import ForgetPasswordFormFooter from "./ForgetPasswordFormFooter";

type ForgetPasswordFormProps = {
  onSubmit: (credentials: { email: string }) => void;
};

const ForgetPasswordForm: React.FC<ForgetPasswordFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: createForgetPasswordFormSchema(t),
    onSubmit: (values) => {
      return onSubmit({
        email: values["email"],
      });
    },
  });

  return (
    <AuthenticationCard
      title={t("authentication.forgetPassword")}
      container={Form}
      containerProps={{
        onSubmit: handleSubmit,
        noValidate: true,
        className: "authentication-card-container forget-password-card",
      }}
      body={ForgetPasswordFormBody}
      bodyProps={{
        t,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }}
      footer={ForgetPasswordFormFooter}
      footerProps={{
        t,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
      }}
    />
  );
};

export default ForgetPasswordForm;
