import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { omit } from "lodash";
import CreateEntityModal from "../../modals/create-entity";
import { ScheduleCategoryItem, UpdateTaskPayload } from "../../../models/job";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateSupplierModal, {
  CreateSupplierModalRef,
} from "../../contacts/create-supplier-modal";
import { assignTasksFields } from "./utils";
import { SupplierListResponse } from "../../../graphql/types/models/supplier";
import { LIST_SUPPLIERS } from "../../../graphql/queries/supplier/queries";
import { TaskAssignTo } from "../../../utils/options";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { prepareCategoryItem } from "../../../containers/jobs/job-schedule/utils";
import "./styles.scss";

type AssignTasksModalProps = {
  onSubmit: (scheduleItems: any) => Promise<void>;
  tasks: ScheduleCategoryItem[];
};

export type AssignTasksModalRef = {
  show: (show: boolean, checkedTasks: ScheduleCategoryItem[]) => void;
  close: (close: boolean) => void;
};

const AssignTasksModal: React.FC<AssignTasksModalProps> = (props, ref) => {
  const { onSubmit, tasks } = props;
  const [show, setShow] = React.useState(false);
  const [chekedTasks, setChekedTasks] = React.useState<
    ScheduleCategoryItem[] | []
  >([]);
  const [assignTo, setAssignTo] = React.useState<TaskAssignTo>(
    TaskAssignTo.TEAM
  );
  const [customReminder, setCustomReminder] = React.useState(0);
  const supplierRef = React.useRef<CreateSupplierModalRef>(null);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    show: (show: boolean, checkedTasks: ScheduleCategoryItem[]) => {
      setShow(show);

      if (checkedTasks.length > 0) {
        setChekedTasks(checkedTasks);
      }
    },
    close: (close: boolean) => {
      setShow(close);
    },
  }));

  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const { data: suppliersData } = useQuery<SupplierListResponse>(
    LIST_SUPPLIERS
  );

  const suppliers = React.useMemo(() => suppliersData?.listSuppliers, [
    suppliersData,
  ]);
  const team = React.useMemo(() => teamData?.listMembers, [teamData]);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<UpdateTaskPayload>
  >({});

  const openSupplierModal = React.useCallback(
    () => supplierRef.current?.show(true),
    [supplierRef]
  );

  const handleReminderCreate = React.useCallback((value: string) => {
    setCustomReminder(Number(value));
  }, []);

  const onAssignToChange = React.useCallback(
    (value: string) => {
      if (!suppliers || !team) return;
      const assignValue =
        TaskAssignTo.TEAM.toString() === value
          ? TaskAssignTo.TEAM
          : TaskAssignTo.SUPPLIER;
      setAssignTo(assignValue);

      setFormFields(
        assignTasksFields(
          t,
          suppliers,
          openSupplierModal,
          team,
          onAssignToChange,
          assignValue,
          customReminder,
          handleReminderCreate
        )
      );
    },
    [
      suppliers,
      team,
      t,
      openSupplierModal,
      tasks,
      customReminder,
      handleReminderCreate,
    ]
  );

  const resetForm = React.useCallback(() => {
    if (!suppliers || !team) return;
    setFormFields(
      assignTasksFields(
        t,
        suppliers,
        openSupplierModal,
        team,
        onAssignToChange,
        assignTo,
        customReminder,
        handleReminderCreate
      )
    );
  }, [
    suppliers,
    team,
    t,
    openSupplierModal,
    onAssignToChange,
    assignTo,
    customReminder,
    handleReminderCreate,
  ]);

  React.useEffect(() => {
    if (!suppliers) return;
    resetForm();
  }, [t, suppliers, resetForm]);

  const handleSubmit = React.useCallback(
    (values: UpdateTaskPayload) => {
      const filteredValues = {
        ...omit(values, ["assignTo"]),
        supplier:
          assignTo === TaskAssignTo.SUPPLIER && values.supplier
            ? values.supplier
            : null,
        user:
          assignTo === TaskAssignTo.TEAM && values.user ? values.user : null,
      };

      const chekedTasksIds = chekedTasks.map((task) => {
        return task._id;
      });

      const tasksForAssign = tasks
        .map((taskItem) => {
          if (chekedTasksIds.includes(taskItem._id)) {
            return {
              ...prepareCategoryItem(taskItem),
              reminder: {
                daysBefore: Number(filteredValues.reminder?.daysBefore) ?? 0,
                timezone: taskItem.reminder?.timezone ?? null,
              },
              user: filteredValues.user ?? null,
              supplier: filteredValues.supplier ?? null,
            };
          }
        })
        .filter(Boolean);

      onSubmit && onSubmit(tasksForAssign);
      return true;
    },
    [assignTo, chekedTasks, tasks, resetForm]
  );

  return (
    <CreateEntityModal
      className="assign-task-modal"
      title={t("schedule.assignTasks")}
      show={show}
      data={{
        _id: "",
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        progress: 0,
        supplier: null,
        user: null,
        assignTo: TaskAssignTo.TEAM,
      }}
      onSubmit={handleSubmit}
      onClose={() => setShow(false)}
      fields={formFields}
      submitText={t("schedule.assign")}
    >
      {() => <CreateSupplierModal ref={supplierRef} />}
    </CreateEntityModal>
  );
};

export default forwardRef(AssignTasksModal);
