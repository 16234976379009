import SidebarNavListItem, { SidebarNavItem } from "./SidebarNavListItem";
import React from "react";
import { map, some } from "lodash";
import classNames from "classnames";

type SidebarNavListProps = {
  className?: string;
  isMinified: boolean;
  items: SidebarNavItem[];
  selectedItem: SidebarNavItem | null;
  onItemClick: (item: SidebarNavItem) => void;
};

const isNestedItemSelected = (
  item: SidebarNavItem,
  selectedItem: SidebarNavItem
): boolean => {
  return (
    item.id === selectedItem.id ||
    some(item.items, (i) => isNestedItemSelected(i, selectedItem))
  );
};

const isItemSelected = (
  item: SidebarNavItem,
  selectedItem: SidebarNavItem | null
) => {
  if (!selectedItem) return false;

  return isNestedItemSelected(item, selectedItem);
};

const SidebarNavList: React.FC<SidebarNavListProps> = ({
  className,
  items,
  onItemClick,
  selectedItem,
  isMinified,
}) => {
  const handleItemClick = React.useCallback((item) => () => onItemClick(item), [
    onItemClick,
  ]);

  return (
    <ul
      className={classNames(
        "navigation-list",
        { minified: isMinified },
        className
      )}
    >
      {map(items, (item) => (
        <SidebarNavListItem
          key={item.id}
          item={item}
          isSelected={isItemSelected(item, selectedItem)}
          selectedItem={selectedItem}
          onNestedItemClick={onItemClick}
          onItemClick={handleItemClick(item)}
          isMinified={isMinified}
        />
      ))}
    </ul>
  );
};

export default SidebarNavList;
