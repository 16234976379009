import { TFunction } from "i18next";
import * as yup from "yup";

import {
  CopyTakeOffPayload,
  TakeOffMeasurement,
} from "../../../../models/take-off";
import { ValidationMessagesUtils } from "../../../../utils/yup";

export default function copyTakeOffSchema(
  t: TFunction,
  activeMeasurementTab: string
) {
  return yup.object().shape<CopyTakeOffPayload>({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "takeOffSection.name")
      ),
    ...(activeMeasurementTab === TakeOffMeasurement.CUBIC_METER && {
      depth: yup
        .string()
        .required(
          ValidationMessagesUtils.createRequiredMessage(
            t,
            "takeOffSection.depth"
          )
        ),
    }),
    color: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "takeOffSection.colour"
        )
      ),
  });
}
