import { gql } from "@apollo/client";

export const EDIT_PROFILE = gql`
  mutation UpdateProfile(
    $first_name: String
    $last_name: String
    $email: String
    $profileImage: MediaInput
    $disabledNotifications: ProfileDisabledNotificationsInput
    $signature: ProfileSignatureInput
    $hasOnboarded: Boolean
  ) {
    updateProfile(
      profile: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        profileImage: $profileImage
        signature: $signature
        hasOnboarded: $hasOnboarded
        disabledNotifications: $disabledNotifications
      }
    ) {
      _id
      first_name
      last_name
      email
      hasOnboarded
      signature {
        html
        text
        raw
      }
      role
      profileImage {
        _id
        type
        name
        size
        width
        height
        upload_url
        url
      }
      disabledNotifications {
        email
        push
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($old_password: String!, $new_password: String!) {
    changePassword(old_password: $old_password, new_password: $new_password) {
      _id
      token
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!, $rememberMe: Boolean!) {
    login(email: $email, password: $password, remember_me: $rememberMe) {
      _id
      tokenType
      token
    }
  }
`;

export const IMPERSONATE = gql`
  mutation($hash: String!) {
    login: impersonate(hash: $hash) {
      _id
      tokenType
      token
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp(
    $business_name: String!
    $email: String!
    $phone_number: String!
    $first_name: String!
    $last_name: String!
    $password: String!
    $affiliateCode: String
  ) {
    signUp(
      user: {
        business_name: $business_name
        email: $email
        phone_number: $phone_number
        first_name: $first_name
        last_name: $last_name
        password: $password
        affiliateCode: $affiliateCode
      }
    ) {
      _id
      first_name
      last_name
      email
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      _id
      email
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($resetHash: String!, $password: String!) {
    resetPassword(resetHash: $resetHash, password: $password) {
      _id
      email
    }
  }
`;

export const ACTIVATE_PROFILE = gql`
  mutation($userId: ID!, $confirmHash: String!) {
    activateProfile(userId: $userId, confirmHash: $confirmHash) {
      _id
      email
    }
  }
`;

export const RESEND_ACTIVATION = gql`
  mutation($userId: ID!) {
    resendActivationEmail(userId: $userId) {
      _id
    }
  }
`;

export const EDIT_CLIENT_PROFILE = gql`
  mutation clientEditProfile($profile: ClientProfileInput) {
    clientEditProfile(profile: $profile) {
      _id
      first_name
      last_name
      email
      mobile
    }
  }
`;

export const CHANGE_CLIENT_PASSWORD = gql`
  mutation clientChangePassword($oldPassword: String!, $newPassword: String) {
    clientChangePassword(
      old_password: $oldPassword
      new_password: $newPassword
    ) {
      _id
      token
    }
  }
`;
