import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import importTakeoffFromTemplateSchema from "./ImportTakeoffFromTemplate.schema";
import { importSpecFromTemplateFields } from "./utils";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { useSpecificationsTemplate } from "../../../hooks/useSpecificationsTemplate";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";

type ImportSpecFromTemplateModalProps = {
  salesQuoteId?: string;
  refetchSpecCategories?: () => void;
};

const ImportSpecFromTemplateModal: React.FC<ImportSpecFromTemplateModalProps> = (
  { salesQuoteId, refetchSpecCategories },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<{ templateId: string }>
  >({});

  const onSubmitImport = React.useCallback(() => {
    refetchSpecCategories?.();
    hide();
  }, [hide, refetchSpecCategories]);

  const { specTemplates, importSpecFromTemplate } = useSpecificationsTemplate({
    onImport: onSubmitImport,
  });

  React.useEffect(() => {
    if (!specTemplates) return;
    setFormFields(importSpecFromTemplateFields(t, specTemplates));
  }, [specTemplates, t]);

  const handleSubmit = React.useCallback(
    (values: { templateId: string }) => {
      importSpecFromTemplate({
        variables: {
          templateId: values.templateId,
          salesQuoteId,
        },
      });
    },
    [importSpecFromTemplate, salesQuoteId]
  );

  return (
    <CreateEntityModal
      validationSchema={importTakeoffFromTemplateSchema(t)}
      className="import-spec-template"
      title={t("specifications.importSpecTemplate")}
      show={shouldShow}
      data={{
        templateId: "",
      }}
      submitText={t("common.import")}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
    />
  );
};

export default forwardRef(ImportSpecFromTemplateModal);
