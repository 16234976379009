import React from "react";
import { Helmet } from "react-helmet";
import { map } from "lodash";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";

import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardCard from "../../../../components/dashboard/card";
import DashboardCardBody from "../../../../components/dashboard/card/DashboardCardBody";
import DashboardCardHeader from "../../../../components/dashboard/card/DashboardCardHeader";
import DashboardCardFooter from "../../../../components/dashboard/card/DashboardCardFooter";
import {
  CancelSubscriptionReponse,
  GetSubscriptionStatusResponse,
  ListInvoicesResponse,
  SubscriptionInvoice,
} from "../../../../graphql/types/models/subscription";
import {
  GET_ACTIVE_SUBSCRIPTION,
  LIST_INVOICES,
} from "../../../../graphql/queries/subscription/queries";
import { CANCEL_SUBSCRIPTION } from "../../../../graphql/queries/subscription/mutations";
import UpdatePaymentModal, {
  UpdatePaymentModalRef,
} from "../../../../components/subscription/update-payment-modal";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../../components/confirm-dialog";
import CardTable from "../../../../components/dashboard/table-card/CardTable";
import EmptyPlaceholder from "../../../../components/empty-placeholder";
import "./styles.scss";

type BillingProps = {};

const Billing: React.FC<BillingProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const updatePaymentRef = React.useRef<UpdatePaymentModalRef>(null);

  const { data: subscriptionData, refetch: refetchSubscription } = useQuery<
    GetSubscriptionStatusResponse
  >(GET_ACTIVE_SUBSCRIPTION, {
    fetchPolicy: "cache-and-network",
  });

  const { data: invoicesData, refetch: refetchInvoices } = useQuery<
    ListInvoicesResponse
  >(LIST_INVOICES, {
    // fetchPolicy: 'cache-and-network'
  });

  const [cancelSubscription, { loading: cancelLoading }] = useMutation<
    CancelSubscriptionReponse
  >(CANCEL_SUBSCRIPTION, {
    onCompleted: () => {
      refetchSubscription();
      confirmRef.current?.show(false);
    },
  });

  const subscription = React.useMemo(() => {
    return subscriptionData?.getSubscriptionStatus;
  }, [subscriptionData]);

  const handleChangePlan = React.useCallback(() => {
    history.push(`/settings/billing/plans`);
  }, []);

  const handleCancelConfirmPress = React.useCallback(() => {
    cancelSubscription();
  }, []);

  const handleCancelPress = React.useCallback(() => {
    confirmRef.current?.show(true);
  }, [confirmRef]);

  const handleChangePaymentPress = React.useCallback(() => {
    updatePaymentRef.current?.show(true);
  }, [updatePaymentRef]);

  const renderSubscription = React.useMemo(() => {
    const hasExpired = subscription?.status !== "active";
    const text = hasExpired ? (
      t("billing.subscriptionExpired")
    ) : (
      <>
        {t("billing.nextPaymentOf")}
        <b> {t("common.currency", { amount: subscription?.invoiceAmount })} </b>
        {t("billing.nextPaymentOn", {
          date: moment(subscription?.subscriptionEndDate).format("Do MMM YYYY"),
        })}
      </>
    );

    return (
      <>
        <div>
          <h2>{subscription?.plan?.name}</h2>
          <p>{text}</p>
        </div>
        <Button
          variant="primary"
          className="button primary"
          onClick={handleChangePlan}
        >
          {t("billing.changePlan")}
        </Button>
      </>
    );
  }, [subscription]);

  const renderTrial = React.useMemo(() => {
    const hasEnded = moment(subscription?.subscriptionEndDate).isBefore(
      moment()
    );
    return (
      <>
        <div>
          <h2>{t("billing.trial")}</h2>
          <p>
            {t(hasEnded ? "billing.trialHasEnded" : "billing.trialWillEnd", {
              date: moment(subscription?.subscriptionEndDate).format(
                "Do MMM YYYY"
              ),
            })}
          </p>
        </div>
        <Button
          variant="primary"
          className="button primary"
          onClick={handleChangePlan}
        >
          {t("billing.subscribe")}
        </Button>
      </>
    );
  }, [subscription]);

  const isTrial = React.useMemo(() => subscription?.status === "trialing", [
    subscription,
  ]);
  const showFooter = React.useMemo(
    () =>
      subscription?.plan &&
      (subscription?.status === "active" ||
        subscription?.status === "past_due"),
    [subscription]
  );

  const handleDownloadInvoice = React.useCallback(
    (invoice: SubscriptionInvoice) => {
      window.open(invoice.invoice_pdf, "_blank");
    },
    []
  );

  const invoicesTableData = React.useMemo(
    () => ({
      columns: [
        {
          valueKey: "createdAt",
          title: t("billing.invoiceDate"),
          formatValue: (row: any, column: any, value: number) =>
            moment(value).format("Do MMM YYYY"),
        },
        {
          valueKey: "number",
          title: t("billing.invoiceNumber"),
          formatValue: (row: any, column: any, value: number) => value,
        },
        {
          valueKey: "total",
          title: t("billing.total"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(invoicesData?.listInvoices, (invoice: SubscriptionInvoice) => ({
        cells: invoice,
      })),
    }),
    [invoicesData]
  );

  const tableRowActions = React.useMemo(() => {
    return [
      {
        icon: "download",
        onClick: handleDownloadInvoice,
        className: "field-text",
      },
    ];
  }, []);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.billing")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SETTINGS.BILLING} />
      <Row>
        <Col lg={5} xs={12} className="pb-sm-5 pb-lg-0">
          <DashboardCard className="subscription-card">
            <DashboardCardHeader className="text-capitalize justify-content-center p-0">
              {t("billing.subscription")}
            </DashboardCardHeader>
            <DashboardCardBody className="d-flex justify-content-between align-items-center">
              {isTrial ? renderTrial : renderSubscription}
            </DashboardCardBody>
            {showFooter && (
              <DashboardCardFooter className="d-flex justify-content-between">
                <Button
                  variant="primary"
                  className="button primary"
                  onClick={handleChangePaymentPress}
                >
                  {t("billing.changePayment")}
                </Button>
                <Button
                  variant="secondary"
                  className="button info"
                  onClick={handleCancelPress}
                >
                  {t("billing.cancelSubscription")}
                </Button>
              </DashboardCardFooter>
            )}
          </DashboardCard>
        </Col>
        <Col lg={7} xs={12} className="pb-sm-5 pb-lg-0">
          <DashboardCard className="subscription-card">
            <DashboardCardHeader className="text-capitalize justify-content-center p-0">
              {t("billing.invoices")}
            </DashboardCardHeader>
            <DashboardCardBody>
              {invoicesData?.listInvoices &&
              invoicesData?.listInvoices.length > 0 ? (
                <CardTable
                  table={invoicesTableData}
                  rowActions={tableRowActions}
                  showCountTitle={true}
                  tableClass="table-outline table-attachments"
                />
              ) : (
                <EmptyPlaceholder message={t("billing.noInvoices")} />
              )}
            </DashboardCardBody>
          </DashboardCard>
        </Col>
      </Row>

      <ConfirmDialog
        ref={confirmRef}
        disabled={cancelLoading}
        title={t("billing.cancelSubscription")}
        onSubmit={handleCancelConfirmPress}
        cancel={t("common.no")}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("billing.confirmCancelSubscription")}
        </span>
      </ConfirmDialog>
      <UpdatePaymentModal ref={updatePaymentRef} onSuccess={console.log} />
    </Container>
  );
};

export default Billing;
