import React, { useCallback, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type DatePickerProps = {
  calendarClassName?: string;
  value?: string;
  dateFormat?: string;
  showTimeSelectOnly?: boolean;
  showTimeSelect?: boolean;
  onChange: (value: string) => void;
  className: string;
  portalId?: string;
  setDatesByCategory?: <T>() => Promise<T | undefined | any>;
};

const DEFAULT_DATE_PICKER_FORMAT = "dd/MM/yyyy";

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    calendarClassName,
    showTimeSelectOnly,
    showTimeSelect = false,
    onChange,
    value,
    className,
    portalId,
    setDatesByCategory,
  } = props;

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dates, setMax] = useState<{
    maxDate: string | null;
    minDate: string | null;
  } | null>(null);

  const maxDate = dates?.maxDate ? new Date(dates?.maxDate) : null;
  const minDate = dates?.minDate ? new Date(dates?.minDate) : null;

  const handleChange = React.useCallback(
    (date) => {
      if (!date) {
        onChange && onChange("");
      } else {
        date?.setSeconds(0);
        date?.setMilliseconds(0);
        onChange && onChange(date.toISOString());
      }

      setSelectedDate(date);
    },
    [onChange]
  );

  React.useEffect(() => {
    if (!value) {
      return;
    }

    setSelectedDate(new Date(value));
  }, []);

  const handleSetDatesByCategory = useCallback(async () => {
    if (setDatesByCategory) {
      const category = await setDatesByCategory();
      if (category)
        setMax({
          maxDate: category.max,
          minDate: category.min,
        });
    }
  }, [setDatesByCategory]);

  const handleCalendarOpen = useCallback(() => {
    if (setDatesByCategory) handleSetDatesByCategory();
  }, [handleSetDatesByCategory, setDatesByCategory]);

  return (
    <ReactDatePicker
      showTimeSelectOnly={showTimeSelectOnly}
      autoComplete="off"
      className={className}
      onChange={handleChange}
      selected={selectedDate}
      dateFormat={DEFAULT_DATE_PICKER_FORMAT}
      calendarClassName={calendarClassName}
      maxDate={maxDate}
      minDate={minDate}
      portalId={portalId}
      closeOnScroll={true}
      onCalendarOpen={handleCalendarOpen}
    />
  );
};

export default DatePicker;
