import React from "react";
import { Route, Switch } from "react-router-dom";
import TimesheetsOverview from "../timesheets-overview";

const TimesheetsContainer = () => {
  return (
    <Switch>
      <Route path="/timesheets" render={() => <TimesheetsOverview />} />
    </Switch>
  );
};

export default TimesheetsContainer;
