import { ApolloCache, MutationUpdaterFn } from "@apollo/client";

import {
  GET_SPEC_TEMPLATE,
  LIST_SPEC_CATEGORIES,
  LIST_SPEC_TEMPLATES,
} from "./queries";
import {
  SalesQuoteSpecCategoryListResponse,
  DeleteSalesQuoteSpecCategoryResponse,
  CreateUpdateQuoteSpecCategoryResponse,
  UpdateSpecCategoryOrderResponse,
  CreateUpdateSpecTemplateCategory,
} from "../../types/models/specification";
import { filter, concat, map } from "lodash";
import {
  CreateUpdateSpecTemplateResponse,
  DeleteSpecTemplateResponse,
  GetSpecTemplateResponse,
  ListSpecTemplatesResponse,
  SaveSpecAsTemplateResponse,
  SpecTemplate,
  SpecificationCategory,
} from "../../../models/specification";

const getSpecCategories = (cache: ApolloCache<any>, salesQuoteId?: string) => {
  const specCategoriesListResponse = cache.readQuery<
    SalesQuoteSpecCategoryListResponse
  >({
    query: LIST_SPEC_CATEGORIES,
    variables: { salesQuoteId },
  });

  return specCategoriesListResponse?.getSpecBySalesQuoteId;
};

const preventSpecCategoryItemCache = (category: SpecificationCategory) => {
  return {
    ...category,
    items: map(category.items, (item) => ({
      ...item,
      url: item.url ? `${item.url.split("?")[0]}?${+new Date()}` : item.url,
    })),
  };
};

const updateSpecCategoryCache = (
  cache: ApolloCache<any>,
  categories: SpecificationCategory[],
  salesQuoteId?: string
) => {
  cache.writeQuery<SalesQuoteSpecCategoryListResponse>({
    query: LIST_SPEC_CATEGORIES,
    variables: { salesQuoteId },
    data: {
      getSpecBySalesQuoteId: categories,
    },
  });
};

export const handleDeleteSpecCategory = (
  salesQuoteId?: string
): MutationUpdaterFn<DeleteSalesQuoteSpecCategoryResponse> => (
  cache,
  { data }
) => {
  const deletedSpecCategory = data?.deleteSpecCategoryById;
  const specCategoriesList = getSpecCategories(cache, salesQuoteId);

  if (!deletedSpecCategory || !specCategoriesList) {
    return;
  }
  const categories = filter(
    specCategoriesList,
    (specCategory) => specCategory._id !== deletedSpecCategory._id
  );
  updateSpecCategoryCache(cache, categories, salesQuoteId);
};

export const handleAddSpecCategory = (
  salesQuoteId?: string
): MutationUpdaterFn<CreateUpdateQuoteSpecCategoryResponse> => (
  cache,
  { data }
) => {
  const addedSpecCategory = data?.createUpdateSpecCategory;
  const specCategoriesList = getSpecCategories(cache, salesQuoteId);

  if (!addedSpecCategory || !specCategoriesList) {
    return;
  }

  updateSpecCategoryCache(
    cache,
    concat(specCategoriesList, addedSpecCategory),
    salesQuoteId
  );
};

export const handleUpdateSpecCategory = (
  salesQuoteId?: string
): MutationUpdaterFn<CreateUpdateQuoteSpecCategoryResponse> => (
  cache,
  { data }
) => {
  const editedSpecCategory = data?.createUpdateSpecCategory;
  const specCategoriesList = getSpecCategories(cache, salesQuoteId);

  if (!editedSpecCategory || !specCategoriesList) {
    return;
  }
  const categories = map(specCategoriesList, (category) =>
    category._id === editedSpecCategory._id
      ? preventSpecCategoryItemCache(editedSpecCategory)
      : category
  );
  updateSpecCategoryCache(cache, categories, salesQuoteId);
};

export const handleUpdateSpecCategoryOrder = (
  salesQuoteId?: string
): MutationUpdaterFn<UpdateSpecCategoryOrderResponse> => (cache, { data }) => {
  // required to persist order of categories
  const categories = data?.updateSpecCategoryOrder || [];
  updateSpecCategoryCache(cache, categories, salesQuoteId);
};

export const updateCategoryItemUrls = (
  cache: ApolloCache<any>,
  categoryId: string,
  salesQuoteId?: string
) => {
  const specCategoriesList = getSpecCategories(cache, salesQuoteId);

  if (!specCategoriesList?.length) {
    return;
  }
  const categories = map(specCategoriesList, (category) => {
    if (category._id === categoryId) {
      return preventSpecCategoryItemCache(category);
    }
    return category;
  });
  updateSpecCategoryCache(cache, categories, salesQuoteId);
};

const getSpecTemplateList = (cache: ApolloCache<any>) => {
  const cacheData = cache.readQuery<ListSpecTemplatesResponse>({
    query: LIST_SPEC_TEMPLATES,
  });
  return cacheData?.listSpecTemplates;
};

const updateSpecTemplateList = (
  cache: ApolloCache<any>,
  newList: SpecTemplate[]
) => {
  cache.writeQuery<ListSpecTemplatesResponse>({
    query: LIST_SPEC_TEMPLATES,
    data: {
      listSpecTemplates: newList,
    },
  });
};

export const handleSpecTemplateSave: MutationUpdaterFn<SaveSpecAsTemplateResponse> = (
  cache,
  response
) => {
  const item = response.data?.saveSpecAsTemplate;
  const templatesList = getSpecTemplateList(cache) || [];

  if (!item) {
    return;
  }

  updateSpecTemplateList(cache, templatesList.concat(item));
};

export const handleSpecTemplateCreateUpdate: MutationUpdaterFn<CreateUpdateSpecTemplateResponse> = (
  cache,
  response
) => {
  const item = response.data?.createUpdateSpecTemplate;
  const templatesList = getSpecTemplateList(cache) || [];

  if (!item) {
    return;
  }

  updateSpecTemplateList(cache, templatesList.concat(item));
};

export const handleSpecTemplateDelete: MutationUpdaterFn<DeleteSpecTemplateResponse> = (
  cache,
  response
) => {
  const deleteTemplateId = response.data?.deleteSpecTemplate._id;
  const templatesList = getSpecTemplateList(cache) || [];

  if (!deleteTemplateId || !templatesList) {
    return;
  }

  updateSpecTemplateList(
    cache,
    templatesList.filter((template) => template._id !== deleteTemplateId)
  );
};

const getSpecTemplate = (cache: ApolloCache<any>, templateId: string) => {
  const cacheData = cache.readQuery<GetSpecTemplateResponse>({
    query: GET_SPEC_TEMPLATE,
    variables: {
      templateId,
    },
  });
  return cacheData?.getSpecTemplate;
};

const updateSpecTemplate = (
  cache: ApolloCache<any>,
  newTemplate: SpecTemplate,
  templateId: string
) => {
  cache.writeQuery<GetSpecTemplateResponse>({
    query: GET_SPEC_TEMPLATE,
    variables: {
      templateId,
    },
    data: {
      getSpecTemplate: newTemplate,
    },
  });
};

export const handleAddSpecTemplateCategory = (
  templateId: string
): MutationUpdaterFn<CreateUpdateSpecTemplateCategory> => (cache, { data }) => {
  const addedSpecCategory = data?.createUpdateSpecTemplateCategory;
  const template = getSpecTemplate(cache, templateId);

  if (!addedSpecCategory || !template) {
    return;
  }

  updateSpecTemplate(
    cache,
    {
      ...template,
      categories: template.categories.concat(addedSpecCategory),
    },
    templateId
  );
};
