import { gql } from "@apollo/client";

export const ACCOUNTING_INTEGRATION_FRAGMENT = gql`
  fragment AccountingIntegrationFragment on AccountingIntegration {
    _id
    integration_type
    myob {
      tenant {
        id
        tenantName
      }
      expenseAccount {
        id
        name
        code
      }
      salesAccount {
        id
        name
        code
      }
    }
    xero {
      tenant {
        id
        tenantId
        tenantType
        tenantName
      }
      expenseAccount {
        id
        accountID
        code
        name
        type
      }
      salesAccount {
        id
        accountID
        code
        name
        type
      }
    }
    sync {
      purchaseOrders
      purchaseOrderReceipts
      progressClaims
    }
    customConfiguration {
      key
      label
      type
      options {
        value
        label
      }
      value
    }
  }
`;
