import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Container, Row } from "react-bootstrap";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/entry.webpack";
import omitDeep from "omit-deep-lodash";
import { find, get, map, pick, range, set } from "lodash";
import { FormikHelpers, FormikProps } from "formik";
import customiseQuoteSchema from "./CustomiseQuote.schema";
import {
  convertTemplateRichContent,
  customiseQuoteFormFields,
  mergeChildTemplate,
} from "./utils";
import {
  GenericFormFields,
  GenericFormTable,
  renderField,
} from "../../generic-form/GenericFormBody";
import {
  QuotePrintTemplateSection,
  SalesQuote,
} from "../../../models/salesQuote";
import { CREATE_UPDATE_SALES_QUOTE } from "../../../graphql/queries/quote/mutations";
import { QuoteCreateUpdateResponse } from "../../../graphql/types/models/quote";
import { LIST_QUOTE_PRINT_TEMPLATES } from "../../../graphql/queries/quote-designer/queries";
import {
  COPY_SALES_QUOTE_PRINT_TEMPLATE,
  DELETE_SALES_QUOTE_PRINT_TEMPLATE,
  PREVIEW_TEMPALTE_FOR_SALES_QUOTE,
  UPDATE_SALES_QUOTE_PRINT_TEMPLATE,
} from "../../../graphql/queries/quote-designer/mutations";
import ModalForm from "../../modals/modal-form";
import {
  CopySalesQuotePrintTemplateResponse,
  CreateUpdateSalesQuotePrintTemplateResponse,
  DeleteSalesQuotePrintTemplateResponse,
  ListSalesQuotePrintTemplatesResponse,
  PreviewTemplateForSalesQuoteResponse,
  SalesQuotePrintTemplate,
  SalesQuotePrintTemplateNamePayload,
} from "../../../graphql/types/models/quote-designer";
import { resizeFiles, uploadFiles, UploadUrl } from "../../../utils/files";
import NewQuoteTemplateModal, {
  NewQuoteTemplateModalRef,
} from "../new-quote-template-modal";
import {
  handleCopyPrintTemplate,
  handleDeletePrintTemplate,
  handleUpdatePrintTemplate,
} from "../../../graphql/queries/quote-designer/utils";
import { notify } from "../../notification";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";
import { useModalDisplay } from "../../../hooks/useModalDisplay";

import "./styles.scss";

type QuoteCustomiseModalProps = {
  quote: SalesQuote;
  onRefresh?: () => void;
};

export type QuoteCustomiseModalRef = {
  show: (show: boolean) => void;
};

type CoverImageMap = {
  formPath: string;
  templatePath: string;
};

const OMITTED_TEMPLATE_FIELDS = [
  "_id",
  "templateId",
  "coverBackgroundImage",
  "coverLogoImage",
  "is_system",
  "section",
  "url",
  "upload_url",
  "childTemplate",
  "__typename",
];
const COVER_FILES: CoverImageMap[] = [
  {
    formPath: "coverBackgroundImage.file",
    templatePath: "cover.background",
  },
  {
    formPath: "coverLogoImage.file",
    templatePath: "cover.logo",
  },
];

const QuoteCustomiseModal: React.FC<QuoteCustomiseModalProps> = (
  { quote, onRefresh },
  ref
) => {
  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<GenericFormFields<any>>({});

  const [shouldRefresh, setShouldRefresh] = useState(false);
  const newQuoteRef = useRef<NewQuoteTemplateModalRef>();
  const deleteRef = useRef<ConfirmDialogRef>();
  const { shouldShow, hide } = useModalDisplay(ref);

  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = useState<SalesQuotePrintTemplate | null>(null);

  const [
    selectedSection,
    setSelectedSection,
  ] = useState<QuotePrintTemplateSection | null>(null);
  const [initialValues, setInitialValues] = useState<any>({});
  const [pages, setPages] = useState<number[]>([]);
  const [scale, setScale] = useState<number>(1.2);
  const [width, setWidth] = useState<number>();
  const [hasInitialised, setHasInitialised] = useState(false);

  const { data: printTemplatesData } = useQuery<
    ListSalesQuotePrintTemplatesResponse
  >(LIST_QUOTE_PRINT_TEMPLATES, {
    variables: {
      salesQuoteId: quote._id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [getPDFPreview, { data: quotePreview }] = useMutation<
    PreviewTemplateForSalesQuoteResponse
  >(PREVIEW_TEMPALTE_FOR_SALES_QUOTE, {
    fetchPolicy: "network-only",
  });

  const [updateSalesQuote] = useMutation<QuoteCreateUpdateResponse>(
    CREATE_UPDATE_SALES_QUOTE,
    {
      onCompleted: () => {
        notify({
          content: t("quotes.success.saveTemplate"),
          title: t("quotes.saveTemplate"),
        });
      },
    }
  );

  const [updatePrintTemplate] = useMutation<
    CreateUpdateSalesQuotePrintTemplateResponse
  >(UPDATE_SALES_QUOTE_PRINT_TEMPLATE, {
    onCompleted: ({ createUpdateSalesQuotePrintTemplate: template }) => {
      // setSelectedTemplate(template);
      // if (template._id !== selectedTemplate?._id) {
      //   setSelectedSection(null);
      // }
      newQuoteRef.current?.show(false);
    },
    onError: (e) => {
      notify({
        error: true,
        content: t("quotes.errors.saveTemplate"),
        title: t("quotes.saveTemplate"),
      });
    },
    update: handleUpdatePrintTemplate,
  });

  const [copyPrintTemplate] = useMutation<CopySalesQuotePrintTemplateResponse>(
    COPY_SALES_QUOTE_PRINT_TEMPLATE,
    {
      update: handleCopyPrintTemplate,
      onCompleted: ({ copySalesQuotePrintTemplate: template }) => {
        setSelectedTemplate(template);
        setSelectedSection(null);
        newQuoteRef.current?.show(false);
      },
      onError: () => {
        notify({
          error: true,
          content: t("quotes.errors.saveTemplate"),
          title: t("quotes.saveTemplate"),
        });
      },
    }
  );

  const [deletePrintTemplate, { loading: deletingTemplate }] = useMutation<
    DeleteSalesQuotePrintTemplateResponse
  >(DELETE_SALES_QUOTE_PRINT_TEMPLATE, {
    update: handleDeletePrintTemplate,
    onCompleted: () => {
      setSelectedTemplate(null);
      setSelectedSection(null);
      setInitialValues({});
      notify({
        content: t("quotes.success.deleteTemplate"),
        title: t("quotes.deleteTemplate"),
      });
    },
    onError: () => {
      notify({
        error: true,
        content: t("quotes.errors.deleteTemplate"),
        title: t("quotes.deleteTemplate"),
      });
    },
  });

  const handleTemplateSelect = React.useCallback(
    (templateId: string) => {
      if (!templateId && selectedTemplate) {
        setSelectedTemplate(null);
        setSelectedSection(null);
        return;
      }
      const template = find(printTemplatesData?.listSalesQuotePrintTemplates, {
        _id: templateId,
      });
      if (template && template?._id !== selectedTemplate?._id) {
        setSelectedTemplate(template);
        setSelectedSection(null);
        setSelectedSection(selectedSection);
      }
    },
    [printTemplatesData, selectedTemplate, selectedSection]
  );

  const handleSectionSelect = React.useCallback(
    (section: QuotePrintTemplateSection) => {
      setSelectedSection(section);
    },
    [setSelectedSection]
  );

  const handleNewQuotePress = React.useCallback(() => {
    if (!selectedTemplate) return;
    newQuoteRef.current?.show(true, selectedTemplate.name);
  }, [newQuoteRef, selectedTemplate]);

  React.useEffect(() => {
    setFormFields({});

    setFormFields(
      customiseQuoteFormFields(
        t,
        handleTemplateSelect,
        handleSectionSelect,
        handleNewQuotePress,
        printTemplatesData?.listSalesQuotePrintTemplates,
        selectedTemplate,
        selectedSection
      )
    );
  }, [t, quote, printTemplatesData, selectedTemplate, selectedSection]);

  React.useEffect(() => {
    if (hasInitialised) return;
    if (printTemplatesData?.listSalesQuotePrintTemplates) {
      setHasInitialised(true);
      if (quote.printTemplate?._id) {
        const template = find(printTemplatesData.listSalesQuotePrintTemplates, {
          _id: quote.printTemplate._id,
        });
        if (!template) return;

        setSelectedTemplate(template);
      }
    }
  }, [
    quote,
    printTemplatesData,
    selectedTemplate,
    initialValues,
    hasInitialised,
  ]);

  React.useEffect(() => {
    if (selectedTemplate?._id) {
      setInitialValues({
        templateId: selectedTemplate._id,
        section: selectedSection,
        ...mergeChildTemplate(selectedTemplate, selectedTemplate.childTemplate),
      });
    }
  }, [selectedTemplate, setInitialValues]);

  const updatePreview = React.useCallback(
    async (previewTemplate: SalesQuotePrintTemplate) => {
      if (!previewTemplate) return;
      let template = omitDeep(
        previewTemplate,
        OMITTED_TEMPLATE_FIELDS
      ) as SalesQuotePrintTemplate;
      switch (selectedSection) {
        case QuotePrintTemplateSection.THEME: {
          template.cover.enabled = true;
          break;
        }
        case QuotePrintTemplateSection.COVER: {
          if (previewTemplate.coverBackgroundImage) {
            const backgroundImage = previewTemplate.coverBackgroundImage
              ?.file as File;
            const compressedBackground = (await resizeFiles(
              [backgroundImage as File],
              "JPEG",
              "base64"
            )) as string[];
            if (compressedBackground.length) {
              template.cover.background = {
                _id: "backgroundPreview",
                previewBase64: compressedBackground[0] as string,
              };
            }
          }
          if (previewTemplate.coverLogoImage) {
            const logoImage = previewTemplate.coverLogoImage?.file as File;
            const compressedLogo = (await resizeFiles(
              [logoImage as File],
              "JPEG",
              "base64"
            )) as string[];
            if (compressedLogo.length) {
              template.cover.logo = {
                _id: "logoPreview",
                previewBase64: compressedLogo[0] as string,
              };
            }
          }
          break;
        }
        default: {
          template.cover.enabled = false;
        }
      }
      template = convertTemplateRichContent(template);

      getPDFPreview({
        variables: {
          salesQuoteId: quote._id,
          template: template,
          section: selectedSection,
        },
      });
    },
    [quote, selectedTemplate, selectedSection]
  );

  const handleClose = React.useCallback(() => {
    setSelectedTemplate(null);
    setSelectedSection(null);
    setInitialValues({});
    setHasInitialised(false);
    hide();
    if (shouldRefresh) {
      onRefresh && onRefresh();
    }
  }, [shouldRefresh, hide]);

  const handleSubmit = React.useCallback(
    async (values: any, formikHelpers?: FormikHelpers<any>) => {
      if (!selectedTemplate) return;
      let template = {
        ...omitDeep(selectedTemplate, OMITTED_TEMPLATE_FIELDS),
        ...omitDeep(values, OMITTED_TEMPLATE_FIELDS),
        _id: !selectedTemplate?.is_system ? selectedTemplate._id : null,
        salesQuoteId: quote._id,
      } as SalesQuotePrintTemplate;

      template = convertTemplateRichContent(template);

      COVER_FILES.forEach(({ formPath, templatePath }) => {
        const file = get(values, formPath) as File;
        if (file) {
          set(template, templatePath, {
            name: file.name,
            size: file.size,
            type: file.type,
            newFile: true,
          });
        }
      });

      updateSalesQuote({
        variables: {
          quote: {
            ...pick(quote, ["_id", "name"]),
            printTemplateId: selectedTemplate._id,
            contactId: quote.contact?._id,
          },
        },
      });

      if (!values.is_system) {
        const result = await updatePrintTemplate({
          variables: {
            template,
          },
        });
        const files: any[] = [];
        COVER_FILES.forEach(({ formPath, templatePath }) => {
          const file = get(values, formPath) as File;
          const media = get(
            result.data?.createUpdateSalesQuotePrintTemplate,
            templatePath
          );
          if (file && media?.upload_url) {
            files.push({
              file,
              media,
            });
          }
        });
        if (files.length) {
          const resizedFiles = await resizeFiles(files.map((u) => u.file));
          uploadFiles(
            files.map((u) => u.media) as UploadUrl[],
            resizedFiles as File[]
          );
        }
      }
      onRefresh && onRefresh();
      handleClose();
      formikHelpers?.resetForm();
    },
    [updatePrintTemplate, selectedTemplate, onRefresh, handleClose]
  );

  const handleCopyTemplate = React.useCallback(
    (values: SalesQuotePrintTemplateNamePayload) => {
      if (!selectedTemplate) return;
      copyPrintTemplate({
        variables: {
          printTemplateId: selectedTemplate._id,
          name: values.name,
        },
      });
    },
    [updatePrintTemplate, selectedTemplate]
  );

  const handleDeleteTemplate = React.useCallback(() => {
    if (!selectedTemplate) return;

    deletePrintTemplate({
      variables: {
        printTemplateId: selectedTemplate._id,
      },
    });
    if (quote.printTemplate?._id === selectedTemplate._id) {
      setShouldRefresh(true);
    }
  }, [updatePrintTemplate, selectedTemplate]);

  const renderLeftFooter = React.useCallback(
    (formikProps: FormikProps<any>) => {
      if (!selectedTemplate || selectedTemplate.is_system) return null;
      return (
        <>
          <Button
            variant="secondary"
            className="button info"
            onClick={() => deleteRef.current?.show(true)}
          >
            {t("common.delete")}
          </Button>
          <div style={{ flex: 1 }} />
        </>
      );
    },
    [selectedTemplate, handleDeleteTemplate]
  );

  const pdfDoc = React.useMemo(() => {
    if (!quotePreview?.previewTemplateForSalesQuote.pdf) {
      return null;
    }
    return `data:application/pdf;base64,${quotePreview?.previewTemplateForSalesQuote.pdf}`;
  }, [quotePreview]);

  const onDocumentLoadSuccess = React.useCallback(
    ({ numPages }) => {
      setPages(range(1, numPages + 1));
    },
    [setPages]
  );

  const renderPreviewButton = React.useCallback(
    (formikProps: FormikProps<any>) => {
      if (!selectedTemplate || !selectedSection) return null;
      const section = get(formikProps.values, selectedSection.toLowerCase());
      const enabled = get(section, "enabled");
      const disabled = enabled !== undefined && enabled === false;

      return (
        <div className="form-column">
          <div className="form-group">
            <Button
              variant="primary"
              className="button info-reverse full-width"
              onClick={() => updatePreview(formikProps.values)}
              disabled={disabled}
            >
              {t("quotes.preview")}
            </Button>
          </div>
        </div>
      );
    },
    [selectedTemplate, selectedSection]
  );

  return (
    <ModalForm
      validationSchema={customiseQuoteSchema(t)}
      className="quote-design-modal"
      title={t("quotes.customiseQuote")}
      show={shouldShow}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={handleClose}
      submitText={t("common.saveAndClose")}
      leftFooterRenderer={renderLeftFooter}
    >
      {(formikProps) => (
        <Container className="generic-form-body" fluid>
          <div className="quote-design-row">
            <div className="quote-design-settings">
              {map(
                formFields,
                (field: GenericFormTable<SalesQuotePrintTemplate, any>, id) => (
                  <Row>
                    {id === "display" ? (
                      <>
                        <label className="form-input-label form-label">
                          {t("quotes.designer.displayOptions")}
                        </label>
                        {map(formFields.display, (subField) =>
                          renderField(formikProps, subField, 12)
                        )}
                      </>
                    ) : (
                      renderField(formikProps, field, 12)
                    )}
                  </Row>
                )
              )}
              {renderPreviewButton(formikProps)}
            </div>
            <div className="quote-designer-preview-col">
              {pdfDoc && (
                <div className="quote-designer-preview">
                  <Document file={pdfDoc} onLoadSuccess={onDocumentLoadSuccess}>
                    {map(pages, (page) => (
                      <Page
                        pageNumber={page}
                        scale={scale}
                        className="pdf-viewer"
                        width={width}
                        loading=""
                      />
                    ))}
                  </Document>
                </div>
              )}
            </div>
          </div>
          <NewQuoteTemplateModal
            ref={newQuoteRef}
            onSubmit={handleCopyTemplate}
          />
          <ConfirmDialog
            disabled={deletingTemplate}
            ref={deleteRef}
            onSubmit={handleDeleteTemplate}
            title={t("quotes.deleteTemplate")}
            confirm={t("common.delete")}
          >
            <div className="field-text">
              {t("quotes.deleteTemplateMessage")}
            </div>
          </ConfirmDialog>
        </Container>
      )}
    </ModalForm>
  );
};

export default forwardRef(QuoteCustomiseModal);
