import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  useMutation,
  useQuery,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { reverse } from "lodash";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import {
  handleDiaryAdd,
  handleDiaryDelete,
  handleDiaryShare,
} from "../../../graphql/queries/site-diary/utils";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { notify } from "../../../components/notification";
import JobLocationHeader from "../../header/job-location-header";
import ClientHeaderList from "../../header/client-header-list";
import SiteDiaryList from "../../../components/job-site-diary/entry-list";
import SiteDiaryItem from "../../../components/job-site-diary/entry-list-item";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import SiteDiaryModal, {
  SiteDiaryModalRef,
} from "../../../components/job-site-diary/site-diary-modal";
import {
  LIST_SITE_DIARIES,
  GET_SITE_DIARY,
  GET_SITE_DIARIES,
} from "../../../graphql/queries/site-diary/queries";
import { SHARE_SITE_DIARY_WITH_CLIENT } from "../../../graphql/queries/site-diary/mutations";
import {
  SiteDiary,
  SiteDiaryDeletePayload,
  SiteDiaryDeleteResponse,
  SiteDiaryListResponse,
  SiteDiaryShareResponse,
  SiteDiarySharePayload,
  SiteDiaryResponse,
  SiteDiariesResponse,
} from "../../../graphql/types/models/site-diary";
import {
  CREATE_SITE_DIARY,
  DELETE_SITE_DIARY,
} from "../../../graphql/queries/site-diary/mutations";
import { LIST_JOB_CONTACTS } from "../../../graphql/job-contact/queries";
import { ListJobContactsResponse } from "../../../graphql/models/job-contact";
import { ListJobContactsInput } from "../../../graphql/input/job-contacts";
import EmptyPlaceholder from "../../../components/empty-placeholder";

import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import TeammateHeaderList from "../../header/teammate-header-list";
import { RouteComponentProps } from "react-router-dom";

type Params = {
  id: string;
  diaryId?: string;
};
type JobSiteDiaryProps = RouteComponentProps<Params> &
  DashboardContextValue & { user: UserPayload | null };

const JobSiteDiary: React.FC<JobSiteDiaryProps> = ({
  navigationContext,
  match,
  user,
}) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const jobId = navigationContext?.job?._id || "";
  const { diaryId } = match.params;

  const { t } = useTranslation();
  const modalRef = React.useRef<SiteDiaryModalRef>(null);
  const deleteRef = React.useRef<ConfirmDialogRef>(null);
  const shareRef = React.useRef<ConfirmDialogRef>(null);

  const client = useApolloClient();
  const { data: siteDiaryListData } = useQuery<SiteDiaryListResponse>(
    LIST_SITE_DIARIES,
    {
      variables: {
        jobId,
      },
    }
  );

  const [getSiteDiary, { data: siteDiaryData }] = useLazyQuery<
    SiteDiaryResponse
  >(GET_SITE_DIARY);

  const { data: jobContactsData } = useQuery<
    ListJobContactsResponse,
    ListJobContactsInput
  >(LIST_JOB_CONTACTS, {
    variables: {
      jobId,
    },
  });

  const [shareSiteDiary] = useMutation<
    SiteDiaryShareResponse,
    SiteDiarySharePayload
  >(SHARE_SITE_DIARY_WITH_CLIENT);

  const [deleteSiteDiary] = useMutation<
    SiteDiaryDeleteResponse,
    SiteDiaryDeletePayload
  >(DELETE_SITE_DIARY, {
    onCompleted: ({ deleteSiteDiary }) => {
      notify({
        title: t("siteDiary.createEntry"),
        content: t("siteDiary.success.deleteSiteDiary"),
      });
    },
    update: handleDiaryDelete(jobId),
  });

  // const [selectedDiary, setSelectedDiary] = useState<SiteDiary | null>(null);

  const selectedDiary = React.useMemo(
    () => siteDiaryData?.getSiteDiaryById || null,
    [siteDiaryData]
  );

  const siteDiaries = React.useMemo<SiteDiary[]>(
    () =>
      siteDiaryListData?.listSiteDiaryOverview?.length
        ? reverse([...siteDiaryListData.listSiteDiaryOverview])
        : [],
    [siteDiaryListData]
  );

  const isClientExist = React.useMemo(
    () => !!jobContactsData?.jobContacts.some((item) => item.clientAccess),
    [jobContactsData]
  );

  const handleSelect = React.useCallback(
    (siteDiary: SiteDiary) => {
      getSiteDiary({
        variables: {
          jobId,
          diaryId: siteDiary._id,
        },
      });
    },
    [jobId]
  );

  React.useEffect(() => {
    if (siteDiaries?.length) {
      if (diaryId) {
        getSiteDiary({
          variables: {
            jobId,
            diaryId,
          },
        });
      } else {
        handleSelect(siteDiaries[0]);
      }
    }
  }, [siteDiaries, handleSelect, diaryId]);

  const handleAddClick = React.useCallback(() => {
    modalRef?.current?.show(true);
  }, [modalRef]);

  const handleClone = React.useCallback(() => {
    if (selectedDiary) {
      modalRef?.current?.showClone(selectedDiary);
    }
  }, [modalRef, selectedDiary]);

  const handleEdit = React.useCallback(() => {
    if (selectedDiary) {
      modalRef?.current?.showEdit(selectedDiary);
    }
  }, [modalRef, selectedDiary]);

  const handleShare = React.useCallback(async () => {
    if (selectedDiary && jobId) {
      try {
        await shareSiteDiary({
          variables: {
            jobId,
            diaryId: selectedDiary._id,
          },
          update: handleDiaryShare(jobId),
        });

        notify({
          title: t("siteDiary.shareSiteDiary"),
          content: t("siteDiary.success.shareSiteDiary"),
        });
      } catch (err) {
        notify({
          error: true,
          title: t("siteDiary.shareSiteDiary"),
          content: t("siteDiary.error.shareSiteDiary"),
        });
      }
    }
  }, [selectedDiary, t, shareSiteDiary, jobId]);

  const showShareConfirmation = React.useCallback(
    () => shareRef.current?.show(true),
    [shareRef]
  );

  const handleDelete = React.useCallback(() => {
    if (selectedDiary) {
      deleteSiteDiary({
        variables: {
          jobId,
          diaryId: selectedDiary._id,
        },
      });
    }
  }, [selectedDiary]);

  const handleExportAll = React.useCallback(
    async (siteDiaries: SiteDiary[]) => {
      if (siteDiaries.length) {
        const result = await client.query<SiteDiariesResponse>({
          query: GET_SITE_DIARIES,
          fetchPolicy: "network-only",
          variables: {
            jobId,
            diaryIds: siteDiaries.map((s) => s._id),
          },
        });
        return result.data.getSiteDiariesById;
      }
      return [];
    },
    [jobId]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.siteDiary")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.SITE_DIARY} />
      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      {jobId && <SiteDiaryModal ref={modalRef} jobId={jobId} />}

      <ConfirmDialog
        ref={deleteRef}
        title={t("diary.deleteDiary")}
        onSubmit={handleDelete}
      >
        <span className="field-text">{t("diary.deleteMessage")}</span>
      </ConfirmDialog>

      <ConfirmDialog
        ref={shareRef}
        title={t("siteDiary.shareSiteDiary")}
        onSubmit={handleShare}
        isInfoDialog={!isClientExist}
      >
        <span className="field-text">
          {isClientExist
            ? t("siteDiary.shareSiteDiaryMessage")
            : t("siteDiary.noClientsToShare")}
        </span>
      </ConfirmDialog>

      {siteDiaries.length ? (
        <Row className="h-100">
          <Col lg={4} sm={5} xs={12}>
            <SiteDiaryList
              title={t("siteDiary.entries")}
              onSelect={handleSelect}
              entries={siteDiaries}
              selectedEntry={selectedDiary}
              onAddNewItem={handleAddClick}
              onExportAll={handleExportAll}
            />
          </Col>
          <Col lg={8} sm={7} xs={12} className="mh-100">
            {selectedDiary ? (
              <SiteDiaryItem
                title={t("siteDiary.diaryEntry")}
                siteDiary={selectedDiary}
                onDelete={() => deleteRef.current?.show(true)}
                onClone={handleClone}
                onEdit={handleEdit}
                onShare={showShareConfirmation}
              />
            ) : (
              <CardPlaceholder />
            )}
          </Col>
        </Row>
      ) : (
        <EmptyPlaceholder
          message={t("siteDiary.emptyPlaceholder")}
          buttonText={t("siteDiary.createEntry")}
          onButtonPress={handleAddClick}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(JobSiteDiary));
