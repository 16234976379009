import React from "react";
import classNames from "classnames";
import { Badge } from "react-bootstrap";
import { SalesQuoteFile } from "../../../models/salesQuote";
import { TFunction } from "react-i18next";

type ScaleSummaryItemProps = {
  isSelected?: boolean;
  page: SalesQuoteFile;
  pageNumber: number;
  checkScaleValue?: (page: SalesQuoteFile) => string;
  onClick?: (page: number) => void;
  t: TFunction;
};

const ScaleSummaryItem: React.FC<ScaleSummaryItemProps> = (props) => {
  const { page, onClick, pageNumber, t, isSelected, checkScaleValue } = props;

  const getItemClass = (): string => {
    return classNames("summary-item", {
      "summary-item-selected": isSelected,
    });
  };

  const isScalled = Boolean(page.calibration?.lat && page.calibration?.lng);

  const scaleValues = React.useMemo(() => {
    if (isScalled && page?.calibration?.lat) {
      return checkScaleValue?.(page);
    } else return null;
  }, [checkScaleValue, isScalled, page]);

  const handleClick = React.useCallback(() => {
    return onClick && onClick(pageNumber);
  }, [onClick, pageNumber]);

  return (
    <div className={getItemClass()} onClick={handleClick}>
      <div className="item-name">
        {t("common.pageCount", { count: pageNumber })}
      </div>
      <div className="right-side">
        {!isScalled ? (
          <Badge variant={"danger"} className="ml-2">
            {t("plansSection.unscaled")}
          </Badge>
        ) : (
          scaleValues
        )}
      </div>
    </div>
  );
};

export default ScaleSummaryItem;
