import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import ImportMapper from "../../../components/import-mapper";
import DashboardCard from "../../../components/dashboard/card";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import { MapField } from "../../../components/import-mapper/types";
import ScheduleImportUpload from "../../../components/costing/costing-import-upload";
import { useImportMapper } from "../../../hooks/useImportMapper";

type ScheduleImportProps<Data> = {
  fieldMap: MapField<Data>[];
  onImport: (data: Data[]) => any;
  isLoading?: boolean;
};

const ScheduleImport = <Data extends {}>(props: ScheduleImportProps<Data>) => {
  const { fieldMap, onImport, isLoading } = props;
  const { t } = useTranslation();

  const {
    file,
    headerRow,
    sampleRow,
    rows,
    handleFileAccept,
  } = useImportMapper();

  return (
    <Row className="h-100">
      <Col lg={4} xs={12}>
        <ScheduleImportUpload
          title={t("schedule.importSchedule")}
          fileName={file?.name}
          onFileSelect={handleFileAccept}
          fieldMap={fieldMap}
        />
      </Col>
      <Col lg={8} xs={12}>
        {headerRow && (
          <DashboardCard className="import-mapper">
            <DashboardCardHeader>{t("contacts.mapData")}</DashboardCardHeader>
            <ImportMapper
              options={fieldMap}
              rows={rows}
              onSubmit={onImport}
              header={headerRow}
              sample={sampleRow}
              isLoading={isLoading}
            />
          </DashboardCard>
        )}
        {!headerRow && <CardPlaceholder />}
      </Col>
    </Row>
  );
};

export default ScheduleImport;
