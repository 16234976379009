import React from "react";
import { ApolloClient, useMutation } from "@apollo/client";
import { FormikProps } from "formik";
import { get, uniqBy } from "lodash";
import { CREATE_ASSEMBLY } from "../graphql/queries/assemblies/mutations";
import {
  createLocalAssembly,
  handleCreateAssembly,
  prepareAssemblyItem,
} from "../graphql/queries/assemblies/utils";
import { CreateAssemblyResponse } from "../graphql/types/models/assembly";
import { AssemblyCreatePayload, AssemblyListItem } from "../models/assembly";
import { PriceListItem } from "../models/price-list";
import { calcWithGST, GST_PERCENT } from "../utils/calculations";
import { useCostingSearchQuery } from "./queries/useCostingSearchQuery";
import { SearchCostingItemType } from "../graphql/types/models/price-list";
import { SelectOption } from "../components/generic-form/inputs/select";

export function useCostingLookup(
  client: ApolloClient<any>,
  costingSearchItemType?: SearchCostingItemType
) {
  const { searchCostingItem, findCostingItemFromCache } = useCostingSearchQuery(
    client,
    costingSearchItemType
  );

  const [addAssemblyItem] = useMutation<CreateAssemblyResponse>(
    CREATE_ASSEMBLY,
    {
      update: handleCreateAssembly,
    }
  );

  const [defaultItems, setDefaultItems] = React.useState<AssemblyListItem[]>(
    []
  );
  const [newItems, setNewItems] = React.useState<AssemblyListItem[]>([]);
  const [options, setOptions] = React.useState<SelectOption[]>([]);

  const assemblies = React.useMemo(() => {
    return [...defaultItems, ...newItems];
  }, [defaultItems, newItems]);

  const assemblyOptions = React.useMemo(() => {
    const itemOptions = assemblies.map((assembly) => ({
      value: assembly._id,
      label: assembly.name,
      hidden: true,
    }));
    return [...itemOptions, ...options];
  }, [assemblies, options]);

  const handleCostingNameChange = (
    fieldValue: string,
    fieldsArray: string,
    formikProps: FormikProps<any>,
    isJob?: boolean
  ) => {
    if (!fieldValue) {
      const currentFieldValue = get(formikProps.values, fieldsArray);
      // added checks toprevent new row from being created when sorting
      if (currentFieldValue?.items?.length) {
        formikProps.setFieldValue(`${fieldsArray}.items`, []);
      }
      if (currentFieldValue?.assemblyId) {
        formikProps.setFieldValue(`${fieldsArray}.assemblyId`, "");
      }
      if (currentFieldValue?._id) {
        formikProps.setFieldValue(`${fieldsArray}._id`, "");
      }
    }

    let options = [];
    if (isJob) {
      options =
        formikProps.values?.items?.map((item: any) => ({
          label: item.name,
          value: item.name,
          hidden: true,
        })) || [];
    } else {
      options =
        formikProps.values?.costItems?.map((item: any) => ({
          label: item.name,
          value: item.name,
          hidden: true,
        })) || [];
    }

    const uniqOptions = uniqBy([...options], "label");
    setOptions(uniqOptions);

    const searchItem = findCostingItemFromCache(fieldValue);
    const { setFieldValue, values } = formikProps;

    if (searchItem) {
      if (searchItem.__typename === "PriceItem") {
        const priceItem = searchItem as PriceListItem;
        setFieldValue(`${fieldsArray}.name`, priceItem.name);
        setFieldValue(`${fieldsArray}.assemblyId`, "");
        setFieldValue(`${fieldsArray}.UOM`, priceItem.UOM);
        setFieldValue(`${fieldsArray}.cost`, priceItem.cost);
        setFieldValue(
          `${fieldsArray}.cost_inc`,
          calcWithGST(priceItem.cost, GST_PERCENT)
        );
        setFieldValue(`${fieldsArray}.margin_amount`, "0");
        return priceItem;
      }
      if (searchItem.__typename === "Assembly") {
        const assembly = searchItem as AssemblyListItem;
        setFieldValue(`${fieldsArray}.name`, assembly.name);
        setFieldValue(`${fieldsArray}.assemblyId`, fieldValue);
        setFieldValue(`${fieldsArray}.UOM`, assembly.UOM);
        setFieldValue(`${fieldsArray}.cost`, assembly.total.toString());
        setFieldValue(
          `${fieldsArray}.cost_inc`,
          calcWithGST(assembly.total, GST_PERCENT)
        );
        setFieldValue(`${fieldsArray}.margin_amount`, "0");
        setFieldValue(`${fieldsArray}.items`, assembly.items);
        return assembly;
      }
    } else {
      const currentItem = get(values, fieldsArray);
      const currentAssembly = currentItem?.assemblyId
        ? findCostingItemFromCache(currentItem?.assemblyId)
        : null;
      // check if the name of the selected assembly matches the select box value
      if (currentAssembly?.name !== fieldValue) {
        // if (!existing) {
        //   const newItem: AssemblyListItem = {
        //     _id: fieldValue,
        //     name: fieldValue,
        //     UOM: "",
        //     total: 0,
        //     margin_amount: 0,
        //     __typename: "",
        //   };
        //   setNewItems([...newItems, newItem]);
        // }
        // setFieldValue(`${fieldsArray}.name`, fieldValue);
        // setFieldValue(`${fieldsArray}.assemblyId`, "");
        // setFieldValue(`${fieldsArray}.items`, []);
        // setFieldValue(`${fieldsArray}._id`, "");
        // setFieldValue(`${fieldsArray}.type`, "");
      }
      return null;
    }
  };

  const handleAssemblyCreation = async (
    fieldsArray: string,
    assembly: AssemblyCreatePayload,
    formikProps: FormikProps<any>
  ) => {
    const preparedAssembly = prepareAssemblyItem(assembly);

    const { setFieldValue } = formikProps;
    let assemblyField = {
      assemblyId: "",
      name: preparedAssembly.name,
      UOM: preparedAssembly.UOM.toString(),
      cost: preparedAssembly.total,
    };
    if (assembly.saveForReuse) {
      const { data } = await addAssemblyItem({ variables: preparedAssembly });
      const createdAssembly = data?.createAssembly;
      if (createdAssembly) {
        assemblyField = {
          assemblyId: createdAssembly._id,
          name: createdAssembly.name,
          UOM: createdAssembly.UOM.toString(),
          cost: createdAssembly.total,
        };
        setFieldValue(`${fieldsArray}.items`, createdAssembly.items);
      }
    } else {
      const localAssembly = createLocalAssembly(preparedAssembly);
      assemblyField.assemblyId = localAssembly._id;
      setFieldValue(`${fieldsArray}.items`, preparedAssembly.items);
    }
    setFieldValue(
      `${fieldsArray}.assemblyId`,
      `assembly:${assemblyField.assemblyId}`
    );
    setFieldValue(`${fieldsArray}.UOM`, assemblyField.UOM);
    setFieldValue(`${fieldsArray}.cost`, assemblyField.cost.toString());
    setFieldValue(
      `${fieldsArray}.cost_inc`,
      calcWithGST(assemblyField.cost, GST_PERCENT)
    );
    setFieldValue(`${fieldsArray}.margin_amount`, "0");
    setFieldValue(
      `${fieldsArray}.name`,
      `assembly:${assemblyField.assemblyId}`
    );
  };

  const handleIsAssemblyCheck = React.useCallback((rowValues: any) => {
    return (
      !!rowValues?.assemblyId ||
      /^assembly:/.test(rowValues?._id) ||
      !!rowValues?.items?.length
    );
  }, []);

  return {
    assemblies,
    assemblyOptions,
    setDefaultItems,
    setOptions,
    options,
    handleAssemblyCreation,
    handleIsAssemblyCheck,
    handleCostingNameChange,
    searchCostingItem,
  };
}
