import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createNoteSchema(t: TFunction) {
  return yup.object().shape({
    description: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "note.noteDetails")
      ),
  });
}
