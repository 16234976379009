import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { CreateUpdateSpecCategoryModalPayload } from "../../../models/specification";

export const createUpdateSpecificationField = (
  t: TFunction
): GenericFormFields<CreateUpdateSpecCategoryModalPayload> => {
  return {
    category: [
      {
        type: "text",
        label: t("specifications.categoryName"),
        placeholder: t("specifications.categoryName"),
        valueKey: "name",
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    items: {
      tableTitle: t("specifications.items"),
      rowGenerator: () => ({
        name: "",
        description: "",
        order: 0,
      }),
      isDraggable: true,
      row: [
        {
          width: "200px",
          type: "image-uploader",
          label: t("specifications.photo"),
          valueKey: "photo",
          uploadImageProps: {
            icon: "file_upload",
          },
        },
        {
          width: "200px",
          type: "text",
          label: t("specifications.name"),
          placeholder: "",
          valueKey: "name",
          inputProps: {
            type: "text",
            required: true,
          },
        },
        {
          width: "500px",
          type: "text",
          label: t("specifications.description"),
          placeholder: "",
          valueKey: "description",
          inputProps: {
            type: "text",
            required: true,
          },
        },
      ],
    },
  };
};
