import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Icon from "../../icons/Icon";
import { Note } from "../../../graphql/types/models/note";
import { getFullName } from "../../../utils/text";
import "./styles.scss";

type NoteItemProps = {
  note: Note;
  onDelete: (note: Note) => void;
  onEdit: (note: Note) => void;
};

const NoteItem: React.FC<NoteItemProps> = (props) => {
  const { note, onDelete, onEdit } = props;
  const { t } = useTranslation();

  const handleDelete = React.useCallback(() => {
    onDelete(note);
  }, [onDelete, note]);

  const handleEdit = React.useCallback(() => {
    onEdit(note);
  }, [onEdit, note]);

  return (
    <div className="note-item p-3">
      <div className="note-item-content">
        <div className="note-item--description field-text">
          {note.description}
        </div>

        <div className="note-item--creation pt-3">
          <div className="note-item--creation--date d-flex">
            <Icon name="schedule" outlined />
            <div
              className="pl-1 field-text"
              title={moment(note.createdAt).format("Do MMM YYYY HH:mm")}
            >
              {moment(note.createdAt).format("Do MMM YYYY")}
            </div>
          </div>
          <div className="note-item--creation--user pl-4 d-flex">
            <Icon name="person" outlined />
            <div className="pl-1 field-text">{getFullName(note.createdBy)}</div>
          </div>
          <div className="flex-grow-1"></div>
          <div
            className="right-actions d-flex mr-2 cursor-pointer"
            onClick={handleEdit}
          >
            <Icon name="edit" outlined />
            <div className="pl-1 field-text">{t("common.edit")}</div>
          </div>
          <div
            className="right-actions d-flex cursor-pointer"
            onClick={handleDelete}
          >
            <Icon name="delete" outlined />
            <div className="pl-1 field-text">{t("common.delete")}</div>
          </div>
        </div>
        <div className="note-item--attachments d-flex flex-wrap pt-3">
          {note.attachments?.map((attachment) => (
            <a
              href={attachment.url}
              target="_blank"
              className="note-item--attachments--file pr-2 d-flex cursor-pointer"
            >
              <Icon name="description" outlined />
              <div className="pl-1 field-text">{attachment.name}</div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NoteItem;
