import { gql } from "@apollo/client";

export const NOTE_FRAGMENT = gql`
  fragment NoteFragment on Note {
    _id
    entityId
    entityType
    description
    attachments {
      _id
      name
      url
      upload_url
      type
      size
    }
    createdBy {
      _id
      first_name
      last_name
    }
    updatedAt
    createdAt
  }
`;
