import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import GSTC, {
  Config,
} from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import { Plugin as TimelinePointer } from "gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js";
import { Plugin as CalendarScroll } from "gantt-schedule-timeline-calendar/dist/plugins/calendar-scroll.esm.min.js";
import { Plugin as DependencyLines } from "gantt-schedule-timeline-calendar/dist/plugins/dependency-lines.esm.min.js";
import { Plugin as TimeBookmarks } from "gantt-schedule-timeline-calendar/dist/plugins/time-bookmarks.esm.min.js";
import { Plugin as HighlightWeekends } from "gantt-schedule-timeline-calendar/dist/plugins/highlight-weekends.esm.min.js";

import { Properties as CSSProps } from "csstype";
import { chain, merge, reduce, transform } from "lodash";
import { defaultConfig } from "../../components/calendar/utils";
import {
  Avatar,
  calcAverageProgress,
  CalendarTasks,
  ColourIndicator,
  convertLocalToUTCStartEndDate,
} from "../jobs/job-schedule/utils";
import { JobsSchedule } from "../../graphql/types/models/job-schedule";
import { EXTRA_COLOURS } from "../../constants/colours";
import { ProgressItem } from "../../components/calendar/calendar-components/ProgressItem";
import { ScheduleCategory, ScheduleCategoryItem } from "../../models/job";
import { ReactElement, RefObject } from "react";
import { SupplierDetails } from "../../models/supplier";
import { User } from "../../graphql/types/models/user";
import { UserDetailsModalRef } from "../../components/job-schedule/user-details-modal";

type GanttScheduleRow = {
  id: string | number;
  name: string;
  parentId?: string;
  duration: string;
  startDate: string;
  endDate: string;
  colour?: string;
  progress?: string;
  expanded: boolean;
  style?: CSSProps;
  classNames?: string[];
};

export type GanttScheduleChartItem = {
  id: string | number;
  rowId: string | number;
  parentId?: string;
  isHTML?: boolean;
  // label?: string;
  label?: (props: { item: any; vido: any }) => string;
  time: {
    start: number;
    end: number;
  };
  style?: CSSProps;
  classNames?: string[];
};

export type GanttSchedule = {
  [key: string]: GanttScheduleRow;
};

export type GanttScheduleChart = {
  [key: string]: GanttScheduleChartItem;
};

const getTaskDuration = (finishDate: string, startDate: string): number => {
  return moment
    .duration(moment(finishDate).add(1, "day").diff(startDate))
    .as("day");
};

export const createScheduleRows = (
  items: JobsSchedule[],
  t: TFunction
): GanttSchedule => {
  let index = 1;
  return chain(items)
    .keyBy((item) => GSTC.api.GSTCID(item._id))
    .transform((result: GanttSchedule, item: JobsSchedule, key: string) => {
      const { job, categories } = item;
      const colour = EXTRA_COLOURS[index % EXTRA_COLOURS.length];

      result[key] = {
        id: GSTC.api.GSTCID(job._id),
        name: job.name,
        parentId: undefined,
        startDate: moment(job.startDate).format("D/M/YYYY"),
        endDate: moment(job.endDate).format("D/M/YYYY"),
        duration: t("schedule.durationValue", {
          days: Math.floor(getTaskDuration(job.endDate, job.startDate)),
        }),
        colour: colour,
        progress: t("schedule.progressAmount", { amount: 0 }),
        expanded: true,
        style: {
          fontWeight: "bold",
          background: "rgba(220, 220, 220, 0.4)",
        },
        classNames: ["row-parent"],
      };
      if (categories?.length) {
        categories.forEach((category) => {
          const catKey = GSTC.api.GSTCID(category._id);
          result[catKey] = {
            id: catKey,
            name: category.name,
            parentId: key,
            startDate: moment(category.startDate).format("D/M/YYYY"),
            endDate: moment(category.endDate).format("D/M/YYYY"),
            duration: t("schedule.durationValue", {
              days: Math.floor(
                getTaskDuration(category.endDate, category.startDate)
              ),
            }),
            colour: colour,
            progress: t("schedule.progressAmount", { amount: 0 }),
            expanded: false,
            style: {
              fontWeight: "bold",
            },
            classNames: ["row-category row-parent "],
          };

          if (category?.items?.length) {
            const subTasks = (() => {
              return chain(category?.items)
                .keyBy((task) => GSTC.api.GSTCID(task._id))
                .transform((result: CalendarTasks, task, innerkey) => {
                  result[innerkey] = {
                    id: GSTC.api.GSTCID(task._id),
                    name: task.name,
                    parentId: catKey,
                    startDate: moment(task.startDate).format("D/M/YYYY"),
                    endDate: moment(task.endDate).format("D/M/YYYY"),
                    duration: t("schedule.durationValue", {
                      days: Math.floor(
                        getTaskDuration(task.endDate, task.startDate)
                      ),
                    }),
                    supplier: task.supplier,
                    user: task.user,
                    expanded: false,
                    style: {
                      fontWeight: "normal",
                    },
                    classNames: ["row-child"],
                  };
                }, {})
                .value();
            })();
            for (const subTaskKey in subTasks) {
              result[subTaskKey] = subTasks[subTaskKey];
            }
          }
        });
      }

      index++;
    }, {})
    .value();
};

export const createScheduleItems = (
  items: JobsSchedule[],
  allItems: JobsSchedule[]
): GanttScheduleChart => {
  const progressMap: Record<
    string,
    { progress: number; start: Moment | null; end: Moment | null }
  > = {};
  allItems.forEach((category) => {
    let jobProgress = 0;
    let minDate: Moment | null = null;
    let maxDate: Moment | null = null;
    category.categories.forEach((tasks) => {
      if (tasks?.items) {
        const categoryProgress = calcAverageProgress(tasks.items);
        const { startDate, endDate } = convertLocalToUTCStartEndDate(
          tasks.startDate,
          tasks.endDate
        );
        if (!minDate || moment(startDate).isBefore(minDate)) {
          minDate = startDate;
        }
        if (!maxDate || moment(endDate).isAfter(maxDate)) {
          maxDate = endDate;
        }
        progressMap[tasks._id] = {
          progress: categoryProgress,
          start: startDate,
          end: endDate,
        };
        jobProgress += categoryProgress;
        tasks.items?.forEach((task) => {
          const { startDate, endDate } = convertLocalToUTCStartEndDate(
            task.startDate,
            task.endDate
          );
          if (!minDate || moment(startDate).isBefore(minDate)) {
            minDate = startDate;
          }
          if (!maxDate || moment(endDate).isAfter(maxDate)) {
            maxDate = endDate;
          }
          progressMap[task._id] = {
            progress: task.progress,
            start: startDate,
            end: endDate,
          };
        });
      }
    });
    if (minDate && maxDate) {
      progressMap[category._id] = {
        progress: Math.floor(jobProgress / category?.categories?.length) || 0,
        start: minDate,
        end: maxDate,
      };
    }
  });

  const tasksObj = chain(items)
    .keyBy((task) => GSTC.api.GSTCID(task._id))
    .value();
  let index = 0;
  return transform(
    tasksObj,
    (result: GanttScheduleChart, item: JobsSchedule, key: string) => {
      const { job, categories } = item;
      const colour = EXTRA_COLOURS[index % EXTRA_COLOURS.length];
      result[key] = {
        id: GSTC.api.GSTCID(job._id),
        rowId: GSTC.api.GSTCID(job._id),
        parentId: undefined,
        label({ item, vido }) {
          return ProgressItem(
            vido.html,
            job.name,
            job.name,
            progressMap[job._id].progress
          );
        },
        time: {
          start: parseInt((progressMap[job._id].start as Moment).format("x")),
          end: parseInt((progressMap[job._id].end as Moment).format("x")),
        },
        style: {
          background: colour,
        },
      };
      if (categories?.length) {
        categories.forEach((category) => {
          const createItem = (
            category: ScheduleCategory & { progress?: number }
          ) => {
            result[GSTC.api.GSTCID(category._id)] = {
              id: GSTC.api.GSTCID(category._id),
              rowId: GSTC.api.GSTCID(category._id),
              parentId: key,
              label({ item, vido }) {
                return ProgressItem(
                  vido.html,
                  category.name,
                  category.name,
                  progressMap[category._id].progress
                );
              },
              time: {
                start: parseInt(
                  (progressMap[category._id].start as Moment).format("x")
                ),
                end: parseInt(
                  (progressMap[category._id].end as Moment).format("x")
                ),
              },
              style: {
                background: colour,
              },
            };
          };
          createItem(category);
          if (category?.items?.length) {
            category?.items.forEach((task) => {
              createItem(task);
            });
          }
        });
      }
      index++;
    },
    {}
  );
};

export const createSchedulePlugins = (t: TFunction) => [
  TimelinePointer(),
  CalendarScroll(),
  HighlightWeekends(),
  DependencyLines({
    onLine: [
      (line) => {
        line.type = "square";
        return line;
      },
    ],
  }),
  TimeBookmarks({
    bookmarks: {
      today: {
        time: moment().startOf("day").valueOf(),
        label: t("schedule.today"),
      },
    },
  }),
];

export const createScheduleConfig = (
  t: TFunction,
  renderAvatar: (user?: User, supplier?: SupplierDetails) => ReactElement,
  userDetailsRef: RefObject<UserDetailsModalRef>
): Config => {
  const data = {
    colour: {
      id: "colour",
      data: "",
      expander: false,
      width: 10,
      isHTML: false,
      header: {
        content: "",
      },
      resizer: null,
    },
    avatar: {
      id: "avatar",
      data: "avatar",
      expander: false,
      width: 37,
      isHTML: false,
      header: {
        content: "",
      },
      resizer: null,
    },
    id: {
      id: "id",
      data: "name",
      expander: true,
      width: 200,
      isHTML: false,
      header: {
        content: t("schedule.jobTask"),
      },
    },
    startDate: {
      id: "startDate",
      data: "startDate",
      expander: false,
      isHTML: true,
      width: 100,
      header: {
        content: t("schedule.taskStartDate"),
      },
    },
    endDate: {
      id: "endDate",
      data: "endDate",
      expander: false,
      isHTML: true,
      width: 100,
      header: {
        content: t("schedule.taskEndDate"),
      },
    },
    duration: {
      id: "duration",
      data: "duration",
      expander: false,
      isHTML: false,
      width: 100,
      header: {
        content: t("schedule.duration"),
      },
    },
  };

  return merge(
    {
      // height: window.innerHeight - 270,
      innerHeight: window.innerHeight - 230,
      list: {
        columns: {
          data: data,
        },
      },
      actions: {
        "list-column-row": [
          ColourIndicator,
          Avatar(renderAvatar, userDetailsRef),
        ],
      },
      plugins: createSchedulePlugins(t),
    },
    defaultConfig
  );
};

export const getTasks = (taskCategories: ScheduleCategory[]) => {
  return reduce(
    taskCategories,
    (result: ScheduleCategoryItem[], category: ScheduleCategory) => {
      if (!category?.items?.length) return result;
      return result.concat(category.items);
    },
    []
  );
};

export const getCategories = (schedule: JobsSchedule[]) => {
  if (!schedule) return [];
  return reduce(
    schedule,
    (result: ScheduleCategory[], category: JobsSchedule) => {
      if (!category.categories?.length) return result;
      return result.concat(category.categories);
    },
    []
  );
};
