import React from "react";
import DashboardCard from "../card";
import DashboardCardHeader from "../card/DashboardCardHeader";
import DashboardCardBody from "../card/DashboardCardBody";
import DashboardCardFooter from "../card/DashboardCardFooter";
import "./styles.scss";

type PlaceholderProps = {
  hideFooter?: boolean;
};

const CardPlaceholder: React.FC<PlaceholderProps> = (props) => {
  const { hideFooter } = props;

  return (
    <DashboardCard className="card-placeholder">
      <DashboardCardHeader></DashboardCardHeader>
      <DashboardCardBody></DashboardCardBody>
      {!hideFooter && <DashboardCardFooter></DashboardCardFooter>}
    </DashboardCard>
  );
};

export default CardPlaceholder;
