import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { get, map, omit } from "lodash";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";

import { useMutation } from "@apollo/client";
import {
  DeleteChecklistTemplateListResponse,
  TodoChecklistTemplates,
} from "../../../models/task";
import { DELETE_CHECKLIST_TEMPLATE } from "../../../graphql/queries/task/mutation";
import { notify } from "../../../components/notification";
import { handleDeleteChecklistTemplate } from "../../../graphql/queries/task/utils";
import { useChecklistTemplate } from "../../../hooks/useChecklistTemplate";
import SaveChecklistAsTemplateModal from "../../../components/task/create-checklist-template";
import { useHistory } from "react-router-dom";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";

const ChecklistTemplatesOverview: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isCloneAction, setIsCloneAction] = React.useState(false);

  const saveChecklistAsTemplateRef = React.useRef<ModalDisplayRef>();

  const {
    checklistTemplates,
    loadingChecklistTemplatesData,
    createUpdateChecklistTemplate,
    currentTemplate,
    setTemplate,
  } = useChecklistTemplate({});

  const [deleteChecklistTemplate] = useMutation<
    DeleteChecklistTemplateListResponse
  >(DELETE_CHECKLIST_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("tasks.success.deleteTemplate"),
        title: t("tasks.deleteTemplate"),
      });
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("tasks.errors.deleteTemplate")),
        title: t("tasks.deleteTemplate"),
      });
    },
    update: handleDeleteChecklistTemplate(),
  });

  const deleteConfirmRef = useRef<ConfirmDialogRef>();

  const handleDelete = React.useCallback(
    (templateId: string) => () => {
      if (!templateId) {
        return;
      }
      deleteChecklistTemplate({
        variables: {
          templateId,
        },
      });
      deleteConfirmRef.current?.show(false);
    },
    []
  );

  const openRemoveDialog = React.useCallback(
    (row?: TodoChecklistTemplates) => {
      if (row) {
        deleteConfirmRef.current?.show(true, handleDelete(row._id));
      }
    },
    [handleDelete]
  );

  const handleTemplateClick = React.useCallback(
    (row: TableCardDataRow<TodoChecklistTemplates>) => {
      history.push(`/settings/checklist-templates/${row.cells._id}`);
    },
    [history]
  );

  const handleTemplateEdit = React.useCallback(
    (row?: TodoChecklistTemplates) => {
      if (!row) return;
      setTemplate(row);
      saveChecklistAsTemplateRef.current?.show(true);
    },
    [setTemplate]
  );
  const handleCloneTemplate = React.useCallback(
    (row?: TodoChecklistTemplates) => {
      if (!row) return;
      setTemplate({
        ...row,
        name: t("common.templateNameCopy", { name: row.name }),
      });
      setIsCloneAction(true);
      saveChecklistAsTemplateRef.current?.show(true);
    },
    [setTemplate]
  );

  const tableActions = React.useMemo<TableCardAction[]>(
    () => [
      {
        title: t("tasks.createNewChecklist"),
        onClick: () => saveChecklistAsTemplateRef.current?.show(true),
        icon: "add",
        className: "button large large-wide success",
      },
    ],
    [t]
  );

  const checklistTemplatesData: TableCardData<TodoChecklistTemplates> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
        },
        {
          valueKey: "type",
          title: t("common.type"),
          formatValue: (row: any, column: any, value: string) =>
            t(`tasks.checklistTemplateType.${value}`),
        },
      ],
      rows: map(checklistTemplates, (template: TodoChecklistTemplates) => ({
        cells: template,
      })),
    };
  }, [t, checklistTemplates]);

  const tableRowActions: TableRowActionData<
    TodoChecklistTemplates
  >[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "document-list",
        options: [
          {
            icon: "edit",
            outlined: true,
            id: "edit",
            label: t("common.edit"),
            onClick: handleTemplateEdit,
          },
          {
            icon: "content_copy",
            outlined: true,
            id: "clone",
            label: t("common.clone"),
            onClick: handleCloneTemplate,
          },
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openRemoveDialog,
          },
        ],
      },
    ],
    [openRemoveDialog, t]
  );

  const handleSaveChecklistAsTemplate = React.useCallback(
    (name: string, type: string) => {
      const categoriesData = currentTemplate?.categories.map((category) => ({
        ...omit(category, ["is_deleted", "templateId", "__typename", "_id"]),
        items: category.items.map((item) => ({
          ...omit(item, ["categoryId", "__typename", "is_deleted", "_id"]),
          ...(!isCloneAction && { _id: item._id }),
          task: item.task,
          order: item.order,
        })),
        name: category.name,
        ...(!isCloneAction && { _id: category._id }),
        order: category.order,
      }));
      currentTemplate
        ? !isCloneAction
          ? createUpdateChecklistTemplate({
              variables: {
                template: {
                  ...omit(currentTemplate, ["__typename", "is_deleted"]),
                  name,
                  type,
                  categories: categoriesData || [],
                },
              },
            })
          : createUpdateChecklistTemplate({
              variables: {
                template: {
                  ...omit(currentTemplate, ["__typename", "is_deleted", "_id"]),
                  name,
                  type,
                  categories: categoriesData || [],
                },
              },
            })
        : createUpdateChecklistTemplate({
            variables: {
              template: {
                name,
                type,
                categories: [],
              },
            },
          });
    },
    [createUpdateChecklistTemplate, currentTemplate, isCloneAction]
  );

  const handleOnClose = React.useCallback(() => {
    setTemplate(null);
    setIsCloneAction(false);
  }, [setTemplate]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet
        title={t("navigation.settings.templatesSection.checklistTemplates")}
      />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.CHECKLIST_TEMPLATES
        }
      />
      <ConfirmDialog
        ref={deleteConfirmRef}
        title={t("tasks.deleteTemplate")}
        confirm={t("common.delete")}
      >
        <div className="field-text">{t("tasks.removeTemplateMessage")}</div>
      </ConfirmDialog>

      <SaveChecklistAsTemplateModal
        ref={saveChecklistAsTemplateRef}
        onSubmit={handleSaveChecklistAsTemplate}
        templatesData={checklistTemplates}
        template={currentTemplate}
        onClose={handleOnClose}
        isCloneAction={isCloneAction}
      />

      <TableCard
        isDataLoading={loadingChecklistTemplatesData}
        fullHeight
        overflowXHidden
        data={checklistTemplatesData}
        rowActions={tableRowActions}
        onRowClick={handleTemplateClick}
        actions={tableActions}
        alignEnd
      />
    </Container>
  );
};

export default ChecklistTemplatesOverview;
