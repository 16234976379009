import {
  GenericFormFields,
  DisableFieldFn,
  DisableRowDeletingFn,
  FormikPropGetSetValues,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { TFunction } from "i18next";
import { PurchaseOrderReceivePreparatoryPayload } from "../../../models/purchaseOrder";
import { VariationCostItemPayload } from "../../../models/variations";
import { calcItemTotal } from "../../../utils/calculations";
import { UOMOption } from "../../../utils/types/options";
import { getPurchaseOrderReceiptStatusOptions } from "../../../utils/options";

const createPurchaseOrderReceiveFields = (
  t: TFunction,
  openCalculator: (filed: string) => void,
  handleDisableRowField: DisableFieldFn,
  uoms: UOMOption[],
  handleUOMCreate: (uom: string) => void,
  costsHaveGST: boolean,
  handleCostsHaveGST: (
    value: boolean,
    formikProps: FormikPropGetSetValues
  ) => void,
  handleCostChange: (key: string) => UpdateTextInputFn,
  onChangeReceipReference: (value: string) => void,
  referenceFieldWarning: string
): GenericFormFields<PurchaseOrderReceivePreparatoryPayload> => {
  return {
    reference: {
      type: "text",
      label: t("orders.reference"),
      placeholder: t("placeholders.reference"),
      valueKey: "reference",
      warningMessage: referenceFieldWarning,
      inputProps: {
        type: "text",
        required: true,
      },
      textInputProps: {
        onValueChange: onChangeReceipReference,
      },
    },
    dateReceived: {
      type: "date-picker",
      label: t("orders.dateReceived"),
      placeholder: t("placeholders.dateReceived"),
      valueKey: "dateReceived",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    dueDate: {
      type: "date-picker",
      label: t("common.dueDate"),
      placeholder: t("common.dueDate"),
      valueKey: "dueDate",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    purchaseOrderStatus: {
      type: "default-select",
      label: t("orders.receiveStatus"),
      placeholder: t("placeholders.receiveStatus"),
      valueKey: "purchaseOrderStatus",
      selectProps: {
        options: getPurchaseOrderReceiptStatusOptions(t),
      },
      inputProps: {
        required: true,
      },
    },
    isNonBillable: {
      type: "toggle",
      valueKey: "isNonBillable",
      toggleProps: {
        label: t("orders.nonBillableReceipt"),
      },
    },
    items: {
      tableTitle: t("costing.costingItems"),
      toggles: [
        {
          name: "costsHaveGST",
          label: t("costing.costsHaveGST"),
          onChange: handleCostsHaveGST,
        },
      ],
      rowGenerator: () => ({
        name: "",
        UOM: "qty",
        quantity: null,
        cost: 0,
      }),
      row: [
        {
          width: "350px",
          type: "text",
          label: t("common.description"),
          placeholder: t("placeholders.description"),
          valueKey: "name",
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
          disableRowField: handleDisableRowField,
        },
        {
          width: "150px",
          type: "text",
          label: t("orders.receivedQuantity"),
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          disallowNegativeNumber: true,
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
          },
          append: {
            onClick: openCalculator,
            icon: "calculate",
            className: "input-group-append-compact",
          },
        },
        {
          width: "150px",
          type: "creatable-select",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
        },
        costsHaveGST
          ? {
              width: "100px",
              type: "text",
              label: t("costing.costInc"),
              placeholder: t("placeholders.costValue"),
              valueKey: "cost_inc",
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
              },
              textInputProps: {
                onValueChange: handleCostChange("cost_inc"),
              },
            }
          : {
              width: "100px",
              type: "text",
              label: t("costing.cost"),
              placeholder: t("placeholders.costValue"),
              valueKey: "cost",
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
              },
              textInputProps: {
                onValueChange: handleCostChange("cost"),
              },
            },
        {
          width: "100px",
          maxWidth: "100px",
          type: "toggle",
          valueKey: "gstFree",
          label: t("costing.gstFree"),
          toggleProps: {
            label: "",
          },
        },
      ],
      dynamicFields: [
        {
          title: costsHaveGST ? t("costing.totalInc") : t("costing.totalEx"),
          formatValue: (values: VariationCostItemPayload) => {
            const amount = calcItemTotal(
              {
                margin_amount: Number(values.margin_amount) || 0,
                cost:
                  Number(
                    costsHaveGST && !values.gstFree
                      ? values.cost_inc
                      : values.cost
                  ) || 0,
                quantity: values.quantity || 0,
              },
              0
            );

            return t("common.currency", { amount });
          },
        },
      ],
    },
    internalNote: {
      type: "text",
      label: t("orders.notes"),
      placeholder: t("placeholders.notes"),
      valueKey: "internalNote",
      inputProps: {
        type: "text",
        required: true,
      },
    },
  };
};

export default createPurchaseOrderReceiveFields;
