import { gql } from "@apollo/client";

export const SPEC_CATEGORY_FRAGMENT = gql`
  fragment SpecCategoryFragment on SpecCategory {
    _id
    name
    description
    specNumber
    order
    items {
      _id
      name
      description
      file_type
      size
      width
      height
      url
      upload_url
      order
    }
    salesQuote
  }
`;

export const SPEC_TEMPATE_CATEGORY_ITEM_FRAGMENT = gql`
  fragment SpecTemplateItemFragment on SpecTemplateItem {
    _id
    name
    description
    file_type
    size
    width
    height
    url
    upload_url
    order
    updatedAt
    createdAt
    is_deleted
  }
`;

export const SPEC_TEMPATE_CATEGORY_FRAGMENT = gql`
  fragment SpecTemplateCategoryFragment on SpecTemplateCategory {
    _id
    name
    description
    specNumber
    items {
      ...SpecTemplateItemFragment
    }
    order
    is_deleted
    updatedAt
    createdAt
  }
  ${SPEC_TEMPATE_CATEGORY_ITEM_FRAGMENT}
`;

export const SPEC_TEMPATE_FRAGMENT = gql`
  fragment SpecTemplateFragment on SpecTemplate {
    _id
    name
    updatedAt
    createdAt
    is_deleted
    categories {
      ...SpecTemplateCategoryFragment
    }
  }
  ${SPEC_TEMPATE_CATEGORY_FRAGMENT}
`;
