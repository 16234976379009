import React from "react";
import classnames from "classnames";

type HeaderTitleProps = {
  className?: string;
};

const HeaderTitle: React.FC<HeaderTitleProps> = ({ children, className }) => {
  return (
    <div className={classnames("header-title", className)}>{children}</div>
  );
};

export default HeaderTitle;
