import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { chain } from "lodash";
import CreateEntityModal from "../../modals/create-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { createImportFromTemplateField } from "./utils";
import CardTable from "../../dashboard/table-card/CardTable";
import CheckBox from "../../checkbox";
import {
  TableCardData,
  TableRowActionData,
} from "../../dashboard/table-card/utils";
import { EmptyTablePlaceholder } from "../../dashboard/table-card";
import { ListBuildingTemplatesResponse } from "../../../graphql/types/models/quote";
import { LIST_BUILDING_TEMPLATES } from "../../../graphql/queries/building-template/queries";
import {
  BuildingTemplateCategory,
  BuildingTemplateCategoryItem,
} from "../../../models/salesQuote";
import { BulkSalesQuoteCostingItem } from "../../../graphql/types/models/category";
import { useSelectedItems } from "../../../hooks/useSelectedItems";
import "./styles.scss";

type ImportCostingsFromTemplateModalProps = {
  show: boolean;
  onSubmit: (values: BulkSalesQuoteCostingItem[]) => void;
  onClose: () => void;
};

const ImportCostingsFromTemplateModal: React.FC<ImportCostingsFromTemplateModalProps> = (
  props
) => {
  const { show, onClose, onSubmit } = props;

  const { t } = useTranslation();

  const [fields, setFields] = React.useState<
    GenericFormFields<{ template: string }>
  >({});
  const [selectedTemplate, setSelectedTemplate] = React.useState("");

  const formikRef = React.useRef<any>();

  const { data: templateData } = useQuery<ListBuildingTemplatesResponse>(
    LIST_BUILDING_TEMPLATES,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const templates = React.useMemo(() => {
    return templateData?.templates || [];
  }, [templateData]);

  const currentCategories = React.useMemo(() => {
    if (selectedTemplate) {
      const categories = templates?.find(
        (template) => template._id === selectedTemplate
      );

      return categories?.categories || [];
    }
    return [];
  }, [selectedTemplate, templates]);

  const handleTemplateListChange = React.useCallback((value: string) => {
    if (!value) return;
    setSelectedTemplate(value);
  }, []);

  const resetForm = React.useCallback(() => {
    setFields(
      createImportFromTemplateField(t, handleTemplateListChange, templates)
    );
  }, [t, handleTemplateListChange, templates]);

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  const categoriesData = React.useMemo<
    TableCardData<BuildingTemplateCategory>
  >(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("placeholders.categoryName"),
          formatValue: (row: any, column: any, value: string) => value,
        },
      ],
      rows:
        currentCategories?.map((item) => ({
          cells: item,
        })) || [],
    };
  }, [t, currentCategories]);

  const {
    items: categoryIds,
    handleToggle: handleCategoryToggle,
    setItems: setDefaultcategoryIds,
  } = useSelectedItems<string>(currentCategories.map((item) => item._id));

  const checkAllCategories = React.useCallback(() => {
    setDefaultcategoryIds(currentCategories.map((item) => item._id));
  }, [currentCategories, setDefaultcategoryIds]);

  React.useEffect(() => {
    checkAllCategories();
  }, [currentCategories]);

  const toggleSelectedItem = React.useCallback(
    (row?: BuildingTemplateCategory) => {
      if (!row?._id) return;
      handleCategoryToggle(row?._id);
    },
    [handleCategoryToggle]
  );

  const rowActions: TableRowActionData<
    BuildingTemplateCategory
  >[] = React.useMemo(
    () => [
      {
        icon: "check_box",
        dropdownId: "select-item",
        onClick: toggleSelectedItem,
        shouldRender: (row?: BuildingTemplateCategory) => {
          if (!row) return false;
          return categoryIds.includes(row?._id);
        },
      },
      {
        icon: "check_box_outline_blank",
        dropdownId: "select-item",
        onClick: toggleSelectedItem,
        shouldRender: (row?: BuildingTemplateCategory) => {
          if (!row) return false;
          return !categoryIds.includes(row?._id);
        },
      },
    ],
    [categoryIds, toggleSelectedItem]
  );

  const handleOnImport = React.useCallback(() => {
    if (categoryIds.length) {
      const items = categoryIds.reduce((total, categoryId) => {
        const category = currentCategories.find(
          (item) => item._id === categoryId
        );
        return total.concat(
          category?.items.map((item) => ({
            ...item,
            category: category.name,
          })) || []
        );
      }, [] as BuildingTemplateCategoryItem[]);

      const costItems: BulkSalesQuoteCostingItem[] = chain(items)
        .map((item) => ({
          name: item.name || "",
          category: item.category || t("costing.uncategorised"),
          UOM: item.UOM || "",
          cost: Number(item.cost || 0) || 0,
          quantity: item.quantity || 0,
          raw_quantity: item.raw_quantity || (item.quantity || 0).toString(),
          items: item.items?.map((assemblyItem) => ({
            name: assemblyItem.name || "",
            UOM: assemblyItem.UOM || "",
            cost: Number(assemblyItem.cost || 0) || 0,
            quantity: assemblyItem.quantity || 0,
            raw_quantity:
              assemblyItem.raw_quantity ||
              (assemblyItem.quantity || 0).toString(),
          })),
        }))
        .value();
      onSubmit(costItems);
    }

    handleOnClose();
  }, [categoryIds, currentCategories, onSubmit, t]);

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("costing.emptyPriceLookupSearchPlaceholder"),
    }),
    [t]
  );

  const handleOnClose = React.useCallback(() => {
    onClose();
    setSelectedTemplate("");
    setDefaultcategoryIds([]);
  }, [onClose]);

  const isAllCategoriesChecked =
    currentCategories.length === categoryIds.length;

  const headerTableLeftRowActions = React.useCallback(() => {
    return (
      <th className="check-all">
        <CheckBox
          value={isAllCategoriesChecked}
          onClick={() =>
            isAllCategoriesChecked
              ? setDefaultcategoryIds([])
              : checkAllCategories()
          }
        />
      </th>
    );
  }, [checkAllCategories, isAllCategoriesChecked, setDefaultcategoryIds]);

  const table = React.useMemo(() => {
    return (
      <CardTable
        table={categoriesData}
        emptyTableText={emptyPlaceholder.text}
        leftRowActions={rowActions}
        headerTableLeftRowActions={headerTableLeftRowActions}
      />
    );
  }, [
    categoriesData,
    emptyPlaceholder.text,
    headerTableLeftRowActions,
    rowActions,
  ]);

  return (
    <CreateEntityModal
      formikRef={formikRef}
      className="import-from-template-modal"
      title={t("quotes.importFromTemplate")}
      data={{
        template: "",
      }}
      show={show}
      fields={fields}
      onSubmit={handleOnImport}
      onClose={handleOnClose}
      endRenderer={selectedTemplate ? table : undefined}
      submitText={t("common.import")}
    />
  );
};

export default ImportCostingsFromTemplateModal;
