import { LatLngBoundsLiteral } from "leaflet";
import { get } from "lodash";
import React from "react";
import { ImageOverlay } from "react-leaflet";

type PlanViewerImageOverlayProps = {
  url: string;
  bounds: number[];
  onBoundsChange: (bounds: number[]) => void;
};

const PlanViewerImageOverlay: React.FC<PlanViewerImageOverlayProps> = ({
  url,
  bounds,
  onBoundsChange,
}) => {
  const imageOverlayRef = React.useRef<ImageOverlay | null>(null);

  const handleUpdateBounds = React.useCallback(() => {
    const poll = setInterval(() => {
      const DOMImage = get(imageOverlayRef.current, "leafletElement._image");
      if (get(DOMImage, "naturalHeight")) {
        onBoundsChange([DOMImage.naturalHeight, DOMImage.naturalWidth]);
        clearInterval(poll);
      }
    }, 100);
  }, []);

  React.useEffect(() => {
    handleUpdateBounds();
  }, [url]);

  return (
    <ImageOverlay
      url={url}
      ref={imageOverlayRef}
      bounds={[[0, 0], bounds] as LatLngBoundsLiteral}
    />
  );
};

export default PlanViewerImageOverlay;
