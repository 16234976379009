import { Rounding } from "../components/calculator/utils";
import { Client } from "./client";
import { JobCostingItem } from "./job";
import { PurchaseOrderReal, PurchaseOrderReceipt } from "./purchaseOrder";
import { RichContent } from "./rich-content";
import { QuoteMeasurement } from "./salesQuote";

export enum ClaimStatuses {
  DRAFT = "DRAFT",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export enum ClaimView {
  ITEMS = "ITEMS",
  CATEGORIES = "CATEGORIES",
}

export type Claim = {
  id: string | number;
  description: string;
  contact: string;
  createdAt: Date;
  richComment: RichContent;
  status: ClaimStatuses;
  gst: number;
  total: number;
  subtotal: number;
};

export enum ClaimStatusesEnum {
  DRAFT = "DRAFT",
  SENT = "SENT",
  RECEIVED = "RECEIVED",
  PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
}

export enum ClaimInvoiceType {
  Standard = "STANDARD",
  Retention = "RETENTION",
}

export type ClaimPayload = {
  _id?: string;
  description: string;
  contact: string;
  status: ClaimStatusesEnum | null;
  amount: number | string | null;
  GST?: number;
  variationAmount?: number | string | null;
  variationGST?: number;
  percentage: number | string | null;
  retainedAmount?: number;
  retainedGST?: number;
  distributeAmount?: boolean;
  hideItems?: boolean;
  defaultView?: ClaimView;
  defaultCategoriesView?: boolean;
  variations?: string[];
  richComment?: RichContent;
  items?: ClaimCostItemPayload[];
  invoiceType?: ClaimInvoiceType;
  claimDate?: string;
  dueDate?: string;
  gstFreeAmount?: number;
};
export type ClaimCostItemPayload = Omit<
  ClaimItem,
  "progressClaim" | "amount" | "percentage"
> & {
  percentage: number | string;
  amount: number | string;
  costingItemId?: string;
  // cost+
  purchaseOrderId?: string;
  purchaseOrderReceiptId?: string;
  supplier?: string;
  GST?: number | string;
  hasGST?: boolean;
  markup?: number | string;
  markupAmount?: number | string;
  selected?: boolean;
};

export type ClaimListItem = {
  _id: string;
  invNumber: number;
  amount: number;
  variationAmount: number;
  invoiceSubTotal: number;
  status: ClaimStatusesEnum;
  description: string;
  createdAt: string;
  claimDate: string;
  isOverdue?: boolean;
};
export type ClaimItem = {
  _id?: string;
  name: string;
  note: string;
  percentage: number;
  amount: number;
  amountToDate: number;
  percentageToDate: number;
  total: number;
  remaining: number;
  subTotal?: number;
  markup?: number;
  markupSubTotal?: number;
  markupGST?: number;
  markupTotal?: number;
  invoiceSubTotal?: number;
  invoiceGST?: number;
  invoiceTotal?: number;
  progressClaim: ClaimReal;
  costingItemId?: string;
  purchaseOrder?: PurchaseOrderReal;
  purchaseOrderReceipt?: PurchaseOrderReceipt;
  costingItem?: JobCostingItem;
  createdAt?: string;
  claimDate?: string;
  dueDate?: string;
  isOverdue?: boolean;
};

export type ClaimVariation = {
  _id: string;
  subTotal: number;
  GST: number;
  total: number;
  date: string;
  name: string;
  variationNumber: number;
};

export type ClaimReceipt = {
  _id: string;
  amount: number;
  reference: string;
  dateReceived: string;
  note: string;
};

export type ClaimReal = {
  _id: string;
  amount: number;
  retainedAmount: number;
  retainedGST: number;
  variationAmount: number;
  percentage: number;
  variations: ClaimVariation[];
  invNumber: number;
  status: ClaimStatusesEnum;
  isOverdue: boolean;
  description: string;
  richComment: RichContent;
  createdAt: string;
  contact: Client | null;
  items?: ClaimItem[];
  subTotal: number;
  GST: number;
  total: number;
  markupSubTotal: number;
  markupGST: number;
  markupTotal: number;
  invoiceSubTotal: number;
  invoiceGST: number;
  invoiceTotal: number;
  outstandingTotal: number;
  receivedTotal: number;
  receipts?: ClaimReceipt[];
  receivedDate?: string;
  receivedNote?: string;
  hideItems?: boolean;
  externalLastSyncDate: string;
  defaultView?: ClaimView;
  job?: {
    _id: string;
  };
  claimDate: string;
  dueDate: string;
};

export type ClaimPreview = {
  _id: string;
  pdf: string;
};

export type UnclaimedItems = {
  costingItems: JobCostingItem[];
  purchaseOrders: PurchaseOrderReal[];
  purchaseOrderReceipts: PurchaseOrderReceipt[];
};
