import * as yup from "yup";
import { TFunction } from "i18next";

export default function createForgetPasswordFormSchema(t: TFunction) {
  return yup.object().shape({
    email: yup
      .string()
      .email()
      .required(
        t("authentication.errors.isRequired", {
          fieldName: t("authentication.emailAddress"),
        })
      ),
  });
}
