import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthenticationLayout from "../layouts/authentication";
import ForgetPasswordForm from "../../components/authentication/forget-password-form";
import { FORGOT_PASSWORD } from "../../graphql/queries/auth/mutations";
import GraphQLClient from "../../graphql";
import Context, { SiteContext } from "../../siteContext";
import { SUBCONTRACTOR_FORGOT_PASSWORD } from "../../graphql/queries/subcontractor/auth/mutations";
import { CLIENT_FORGOT_PASSWORD } from "../../graphql/queries/client/auth/mutations";
import { notify } from "../../components/notification";
import {
  ForgotPasswordResponse,
  ForgotPasswordPayload,
} from "../../graphql/types/models/auth";
import { AFFILIATE_FORGOT_PASSWORD } from "../../graphql/queries/affiliate/mutations";

const ForgetPasswordContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isSubcontractor, isClient, isAffiliate } = React.useContext(
    Context
  ) as SiteContext;

  React.useEffect(() => {
    GraphQLClient.setToken("");
  }, []);

  const getQueryForRole = React.useMemo(() => {
    if (isSubcontractor) return SUBCONTRACTOR_FORGOT_PASSWORD;
    else if (isClient) return CLIENT_FORGOT_PASSWORD;
    else if (isAffiliate) return AFFILIATE_FORGOT_PASSWORD;
    else return FORGOT_PASSWORD;
  }, [isSubcontractor, isClient, isAffiliate]);

  const [forgotPassword] = useMutation<
    ForgotPasswordResponse,
    ForgotPasswordPayload
  >(getQueryForRole, {
    onCompleted: (data) => {
      const email =
        data.forgotPassword?.email || data.subcontractorForgotPassword?.email;

      notify({
        title: t("authentication.success.forgotPasswordTitle"),
        content: t("authentication.success.forgotPassword", { email }),
      });
      history.push("/sign-in");
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("common.errors.requestFailed"),
        content: error.message,
      });
    },
  });

  const handleFormSubmit = React.useCallback(
    (credentials: { email: string }) => {
      return forgotPassword({
        variables: credentials,
      });
    },
    [forgotPassword]
  );

  return (
    <AuthenticationLayout>
      <Helmet title={t("authentication.forgetPassword")} />
      <ForgetPasswordForm onSubmit={handleFormSubmit} />
    </AuthenticationLayout>
  );
};

export default ForgetPasswordContainer;
