import React, { useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Badge, BadgeProps, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { compact, map } from "lodash";
import moment from "moment";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";

import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/admin-navigation-items";
import {
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";

import { Business, BusinessUser } from "../../../graphql/types/models/business";
import DetailsCard, {
  DetailsCardAction,
} from "../../../components/dashboard/details-card";
import { DetailsCardDataItem } from "../../../components/dashboard/details-card/DetailsCardList";
import TableCard from "../../../components/dashboard/table-card";

import { WB_LIST_GET_BUSINESS_BY_ID } from "../../../graphql/queries/wb-admin/business/queries";
import { WB_ADMIN_IMPERSONATE_USER } from "../../../graphql/queries/wb-admin/auth/mutations";
import { ImpersonateUserResponse } from "../../../graphql/types/models/auth";
import ExtendTrialModal from "../../../components/admin/extend-trial-modal";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { WB_GET_AFFILIATE } from "../../../graphql/queries/wb-admin/affiliate/queries";
import {
  AffiliateApproveResponse,
  AffiliateBusiness,
  AffiliateBusinessStatus,
  AffiliateBusinessTableItem,
  AffiliateDetailsResponse,
  AffiliateStatus,
} from "../../../graphql/types/models/affiliate";
import { getFullAddress, getFullName } from "../../../utils/text";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import {
  WB_APROVE_AFFILIATE,
  WB_REJECT_AFFILIATE,
} from "../../../graphql/queries/wb-admin/affiliate/mutations";
import { getAffiliateBusinessStatusBadge } from "../../affiliate/signups";

type Params = {
  id: string;
};

type AffiliateDetailsContainerProps = RouteComponentProps<Params> &
  DashboardContextValue;

const AffiliateDetailsContainer: React.FC<AffiliateDetailsContainerProps> = ({
  match,
  navigationContext,
  setNavigationContext,
}) => {
  const { t } = useTranslation();
  const [businessName, setBusinessName] = useState("");
  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const rejectRef = React.useRef<ConfirmDialogRef>(null);
  const { id: affiliateId } = match.params;

  const { data, loading } = useQuery<AffiliateDetailsResponse>(
    WB_GET_AFFILIATE,
    {
      variables: {
        affiliateId,
      },
    }
  );
  const affiliate = React.useMemo(() => data?.affiliate, [data]);

  const [approveAffiliate] = useMutation<AffiliateApproveResponse>(
    WB_APROVE_AFFILIATE
  );

  const [rejectAffiliate] = useMutation<AffiliateApproveResponse>(
    WB_REJECT_AFFILIATE
  );

  useEffect(() => {
    if (data?.affiliate) setBusinessName(data.affiliate.business_name);
  }, [data, setBusinessName]);

  const tableData = React.useMemo<
    TableCardData<AffiliateBusinessTableItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("affiliate.businessName"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "subscriptionPlanName",
          title: t("affiliate.subscriptionPlanName"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "subscriptionPlanValue",
          title: t("affiliate.subscriptionPlanValue"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "status",
          title: t("affiliate.status"),
          formatValue: (row: any, column: any, value: string) => {
            const { label, variant } = getAffiliateBusinessStatusBadge(
              value as AffiliateBusinessStatus
            );
            return <Badge variant={variant}>{label}</Badge>;
          },
        },
        {
          valueKey: "createdAt",
          title: t("common.createdAt"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).toLocaleString(),
        },
      ],
      rows: map(data?.affiliate.businesses, (item: AffiliateBusiness) => ({
        cells: {
          ...item,
          subscriptionPlanName: item.subscriptionPlan?.name || "",
          subscriptionPlanValue: Number(item.subscriptionPlan?.price) || 0,
        },
      })),
    };
  }, [data, t]);

  const handleApprove = React.useCallback(() => {
    if (affiliate) {
      confirmRef.current?.show(true, () => {
        approveAffiliate({
          variables: {
            affiliateId: affiliate._id,
          },
        });
      });
    }
  }, [confirmRef, affiliate]);

  const handleReject = React.useCallback(() => {
    if (affiliate) {
      rejectRef.current?.show(true, () => {
        rejectAffiliate({
          variables: {
            affiliateId: affiliate._id,
          },
        });
      });
    }
  }, [rejectRef, affiliate]);

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<AffiliateBusinessTableItem>) => {},
    []
  );

  React.useEffect(() => {
    return () => {
      setNavigationContext({
        ...navigationContext,
        business: undefined,
      });
    };
  }, []);

  const detailsCardData: DetailsCardDataItem[] = React.useMemo(() => {
    if (loading || !data) {
      return [];
    }

    return compact([
      {
        icon: "business",
        title: t("affiliate.businessName"),
        text: affiliate?.business_name || t("common.na"),
      },
      {
        icon: "face",
        title: t("affiliate.name"),
        text: getFullName(affiliate),
      },
      {
        icon: "shield",
        title: t("company.abnNumber"),
        text: affiliate?.ABN || t("common.na"),
      },
      {
        icon: "room",
        title: t("company.address"),
        text: getFullAddress(affiliate),
      },
      {
        icon: "local_phone",
        title: t("authentication.phoneNumber"),
        text: affiliate?.phone || t("common.na"),
      },
      {
        icon: "mail",
        title: t("authentication.emailAddress"),
        text: affiliate?.email || t("common.na"),
      },
      {
        icon: "engineering",
        title: t("wbAdmin.hasBuilders"),
        text: affiliate?.hasBuilders
          ? t("wbAdmin.hasBuildersYes")
          : t("wbAdmin.hasBuildersNo"),
      },
      {
        icon: "badge",
        title: t("authentication.affiliateAbout"),
        text: affiliate?.about || t("common.na"),
      },
      affiliate?.additionalComment
        ? {
            icon: "chat",
            title: t("wbAdmin.additionalComments"),
            text: affiliate?.additionalComment,
          }
        : null,
      {
        icon: "hourglass_empty",
        title: t("affiliate.status"),
        text: affiliate?.status as string,
      },
      affiliate?.affiliateUrl
        ? {
            icon: "link",
            title: t("affiliate.affiliateLink"),
            text: affiliate?.affiliateUrl,
          }
        : null,
    ]);
  }, [affiliate, loading]);

  const detailsCardActions: DetailsCardAction[] = React.useMemo(
    () =>
      compact([
        affiliate?.status === AffiliateStatus.PENDING
          ? {
              title: t("wbAdmin.rejectAffiliate"),
              onClick: handleReject,
              className: "button info",
            }
          : null,
        affiliate?.status === AffiliateStatus.PENDING
          ? {
              title: t("wbAdmin.approveAffiliate"),
              onClick: handleApprove,
              className: "button info",
            }
          : null,
      ]),
    [t, affiliate, handleApprove, handleReject]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={businessName} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.AFFILIATES_SECTION.AFFILIATE_DETAILS}
      />

      <Row>
        <Col lg={4} xs={12} className="pb-sm-5 pb-lg-0">
          <DetailsCard
            fullHeight
            title={t("common.details")}
            data={detailsCardData}
            actions={detailsCardActions}
            cardFooterStyles="justify-content-between"
          />
        </Col>
        <Col lg={8} xs={12} className="pb-sm-5 pb-lg-0">
          <TableCard
            isDataLoading={loading}
            data={tableData}
            alignEnd
            onRowClick={handleRowClick}
            title={t("affiliate.signups")}
          />
        </Col>
      </Row>

      <ConfirmDialog ref={confirmRef} title={t("wbAdmin.approveAffiliate")}>
        <span className="field-text">
          {t("wbAdmin.approveAffiliateMessage")}
        </span>
      </ConfirmDialog>
      <ConfirmDialog ref={rejectRef} title={t("wbAdmin.rejectAffiliate")}>
        <span className="field-text">
          {t("wbAdmin.rejectAffiliateMessage")}
        </span>
      </ConfirmDialog>
    </Container>
  );
};

export default withRouter(withDashboardContext(AffiliateDetailsContainer));
