import React from "react";
import { useMutation } from "@apollo/client";
import { notify } from "../../components/notification";
import { useTranslation } from "react-i18next";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../components/confirm-dialog";
import { UnprocessTimesheetResponse } from "../../graphql/types/models/timesheet";
import { UNPROCESS_TIMESHEET } from "../../graphql/queries/timesheet/mutation";
import { handleTimesheetsUnprocessing } from "../../graphql/queries/job-timesheet/utils";

type TimesheetTarget = {
  jobId: string;
  timesheetId: string;
} | null;

export function useUnprocessTimesheet() {
  const [timesheetTarget, setTimesheetTarget] = React.useState<TimesheetTarget>(
    null
  );
  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const { t } = useTranslation();
  const [unprocessTimesheet, { loading: unprocessLoading }] = useMutation<
    UnprocessTimesheetResponse
  >(UNPROCESS_TIMESHEET, {
    variables: {
      jobId: timesheetTarget?.jobId,
      timesheetId: timesheetTarget?.timesheetId,
    },
    update: handleTimesheetsUnprocessing(timesheetTarget?.jobId as string),
    onCompleted: () => {
      notify({
        content: t("timesheets.success.unprocessTimesheet"),
        title: t("timesheets.unprocessTimesheet"),
      });
    },
    onError: () => {
      notify({
        error: true,
        content: t("timesheets.errors.unprocessTimesheet"),
        title: t("timesheets.unprocessTimesheet"),
      });
    },
  });
  const openUnprocessTimesheetDialog = React.useCallback(
    (tableItem?: TimesheetTarget) => {
      if (tableItem) {
        setTimesheetTarget({
          jobId: tableItem.jobId,
          timesheetId: tableItem.timesheetId,
        });

        confirmRef?.current?.show(true);
      }
    },

    [confirmRef]
  );

  const closeUnprocessDialog = React.useCallback(() => {
    setTimesheetTarget(null);

    confirmRef?.current?.show(false);
  }, [confirmRef]);

  const handleUnprocessTimesheet = React.useCallback(async () => {
    if (!timesheetTarget) return;

    unprocessTimesheet();
  }, [timesheetTarget, unprocessTimesheet]);

  const renderUnprocessTimesheetDialog = React.useCallback(() => {
    return (
      <ConfirmDialog
        disabled={unprocessLoading}
        ref={confirmRef}
        onClose={closeUnprocessDialog}
        onSubmit={handleUnprocessTimesheet}
        title={t("timesheets.unprocessTimesheet")}
        confirm={t("common.yes")}
      >
        <div className="field-text">
          {t("timesheets.unprocessTimesheetMessage")}
        </div>
      </ConfirmDialog>
    );
  }, [closeUnprocessDialog, handleUnprocessTimesheet, t, unprocessLoading]);
  return { openUnprocessTimesheetDialog, renderUnprocessTimesheetDialog };
}
