import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import { map, find } from "lodash";
import { CLIENT_GET_JOB_QUOTES_SUB_BY_ID } from "./queries";
import {
  ClientQuoteRequest,
  ClientJobQuoteSubByIdResponse,
  ClientAcceptQuoteRequestResponse,
} from "../../../graphql/types/models/quote-request";

const getClientSubQuoteRequest = (
  cache: ApolloCache<any>,
  jobId: string,
  quoteId: string,
  subId: string
) => {
  const clientSubQuote = cache.readQuery<ClientJobQuoteSubByIdResponse>({
    query: CLIENT_GET_JOB_QUOTES_SUB_BY_ID,
    variables: {
      jobId,
      quoteRequestId: quoteId,
      subResId: subId,
    },
  });

  return clientSubQuote?.clientGetJobQuoteSubResponseById;
};

const updateClientSubQuoteRequest = (
  cache: ApolloCache<any>,
  jobId: string,
  quoteId: string,
  subId: string,
  quote: ClientQuoteRequest
) => {
  cache.writeQuery<ClientJobQuoteSubByIdResponse>({
    query: CLIENT_GET_JOB_QUOTES_SUB_BY_ID,
    variables: {
      jobId,
      quoteRequestId: quoteId,
      subResId: subId,
    },
    data: {
      clientGetJobQuoteSubResponseById: quote,
    },
  });
};

export const handleClientQuoteRequestAccept = (
  jobId: string,
  quoteId: string,
  subId: string
): MutationUpdaterFn<ClientAcceptQuoteRequestResponse> => (cache, { data }) => {
  const newQuote = data?.clientAcceptJobQuoteSubResponseBySub;
  const cacheData = getClientSubQuoteRequest(cache, jobId, quoteId, subId);

  if (!cacheData || !newQuote) return;

  const newData = {
    ...cacheData,
    subcontractors: map(cacheData.subcontractors, (subcontractor) => {
      if (subcontractor._id === subId) {
        const newSubcontractor = find(
          newQuote.subcontractors,
          (subcontractor) => subcontractor?._id === subId
        );

        if (!newSubcontractor) {
          return subcontractor;
        } else {
          return newSubcontractor;
        }
      } else {
        return subcontractor;
      }
    }),
  };

  updateClientSubQuoteRequest(cache, jobId, quoteId, subId, newData);
};
