import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { find } from "lodash";
import { notify } from "../components/notification";
import {
  CreateUpdateSpecCategoryItemPayload,
  CreateUpdateSpecCategoryModalPayload,
  CreateUpdateSpecCategoryPayload,
  SpecificationCategory,
} from "../models/specification";
import {
  DELETE_SPEC_CATEGORY,
  DELETE_SPEC_TEMPLATE_CATEGORY,
} from "../graphql/queries/specification/mutation";
import { handleDeleteSpecCategory } from "../graphql/queries/specification/utils";
import {
  DeleteSalesQuoteSpecCategoryResponse,
  DeleteSalesQuoteSpecTemplateCategoryResponse,
} from "../graphql/types/models/specification";
import ConfirmDialog, { ConfirmDialogRef } from "../components/confirm-dialog";

export type SpecificationsProps = {
  salesQuoteId?: string;
  categories?: SpecificationCategory[];
  templateId?: string;
  isTemplate?: boolean;
};

export function useSpecifications(props: SpecificationsProps) {
  const { t } = useTranslation();
  const { salesQuoteId, categories, isTemplate, templateId } = props;
  const confirmRef = React.useRef<ConfirmDialogRef>(null);

  const [categoryToDeleteId, setCategoryToDeleteId] = React.useState("");

  const [deleteSpecCategory, { loading: specCategoryDeleting }] = useMutation<
    DeleteSalesQuoteSpecCategoryResponse
  >(DELETE_SPEC_CATEGORY, {
    update: handleDeleteSpecCategory(salesQuoteId),
  });

  const [deleteSpecTemplateCategory] = useMutation<
    DeleteSalesQuoteSpecTemplateCategoryResponse
  >(DELETE_SPEC_TEMPLATE_CATEGORY, {
    //update: handleDeleteSpecCategory(salesQuoteId),
  });

  const deletingCategoryTitle = React.useMemo(() => {
    const category = find(categories, {
      _id: categoryToDeleteId,
    });

    return category?.name;
  }, [categories, categoryToDeleteId]);

  const handleDeleteSubmit = React.useCallback(async () => {
    try {
      if (!categoryToDeleteId) {
        return;
      }
      isTemplate
        ? await deleteSpecTemplateCategory({
            variables: { templateId, categoryId: categoryToDeleteId },
          })
        : await deleteSpecCategory({
            variables: { categoryId: categoryToDeleteId },
          });

      notify({
        title: t("specifications.deleteSpecCategory"),
        content: t("specifications.success.deleteSpecCategory"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("specifications.deleteSpecCategory"),
        content: t("specifications.error.deleteSpecCategory"),
      });
    }
  }, [
    categoryToDeleteId,
    deleteSpecCategory,
    deleteSpecTemplateCategory,
    isTemplate,
    t,
    templateId,
  ]);

  const openDeleteDialog = React.useCallback((specCategoryId: string) => {
    setCategoryToDeleteId(specCategoryId);
    confirmRef.current?.show(true);
  }, []);

  const closeDeleteDialog = React.useCallback(() => {
    confirmRef.current?.show(false);
    setCategoryToDeleteId("");
  }, []);

  const getCategoryInput = (
    specCategory: CreateUpdateSpecCategoryModalPayload
  ) => {
    const result: CreateUpdateSpecCategoryPayload = {
      name: specCategory.name,
      ...(salesQuoteId && { salesQuote: salesQuoteId }),
    };

    if (specCategory._id) {
      result._id = specCategory._id;
    }

    if (specCategory.items) {
      result.items = specCategory.items.map((item, order) => {
        const resultItem: CreateUpdateSpecCategoryItemPayload = {
          name: item.name,
          description: item.description || "",
          order,
        };

        if (item._id) {
          resultItem._id = item._id;
        }

        if (item.photo && item.photo.file) {
          resultItem.file_type = item.photo.file.type;
          resultItem.size = item.photo.file.size;
          resultItem.new_file = true;
        }

        return resultItem;
      });
    }

    return result;
  };

  const renderDeleteCategoryConfirm = React.useCallback(() => {
    return (
      <ConfirmDialog
        ref={confirmRef}
        title={deletingCategoryTitle}
        onSubmit={handleDeleteSubmit}
        onClose={closeDeleteDialog}
        disabled={specCategoryDeleting}
      >
        <span className="field-text">
          {t("specifications.deleteSpecCategoryMessage")}
        </span>
      </ConfirmDialog>
    );
  }, [
    closeDeleteDialog,
    deletingCategoryTitle,
    handleDeleteSubmit,
    specCategoryDeleting,
    t,
  ]);

  return { renderDeleteCategoryConfirm, openDeleteDialog, getCategoryInput };
}
