import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useModalDisplay } from "../../../../hooks/useModalDisplay";
import { useTakeOffTemplate } from "../../../../hooks/useTakeOffTemplate";
import {
  SalesQuoteTakeOffTemplate,
  SaveTakeOffAsTemplatePayload,
} from "../../../../models/salesQuote";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import CreateEntityModal from "../../../modals/create-entity";
import saveTakeOffAsTemplateSchema from "./SaveAsTemplate.schema";

import { saveTakeOffAsTemplateFields } from "./utils";

type SaveScheduleAsTemplateModalProps = {
  salesQuoteId?: string;
};

const SaveTakeOffAsTemplateModal: React.FC<SaveScheduleAsTemplateModalProps> = (
  { salesQuoteId },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<SaveTakeOffAsTemplatePayload>
  >({});

  const { takeOffTemplates, saveTakeOffAsTemplate } = useTakeOffTemplate({
    onSave: hide,
  });

  React.useEffect(() => {
    if (!takeOffTemplates) return;
    setFormFields(saveTakeOffAsTemplateFields(t));
  }, [takeOffTemplates]);

  const handleSubmit = React.useCallback(
    (values: SaveTakeOffAsTemplatePayload) => {
      return saveTakeOffAsTemplate({
        variables: values,
      });
    },
    []
  );

  const validationSchema = React.useMemo(
    () =>
      saveTakeOffAsTemplateSchema(
        t,
        takeOffTemplates as SalesQuoteTakeOffTemplate[]
      ),
    [t, takeOffTemplates]
  );

  if (!takeOffTemplates) return null;

  return (
    <CreateEntityModal
      validationSchema={validationSchema}
      className="import-takeoff-template"
      title={t("takeOffSection.saveAsTemplate")}
      show={shouldShow}
      data={{
        salesQuoteId: salesQuoteId || "",
        name: "",
      }}
      submitText={t("common.save")}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
    />
  );
};

export default forwardRef(SaveTakeOffAsTemplateModal);
