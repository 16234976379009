import { gql } from "@apollo/client";

export const SITE_DIARY_OVERVIEW_FRAGMENT = gql`
  fragment SiteDiaryOverviewFragment on SiteDiaryOverview {
    _id
    name
    entry_date_time
    is_client_shared
    note
    createdAt
    updatedAt
    created_by {
      _id
      first_name
      last_name
      fullName
    }
    job {
      _id
      name
    }
    category {
      _id
      name
    }
    template {
      _id
      name
      is_deleted
    }
  }
`;

export const SITE_DIARY_FRAGMENT = gql`
  fragment SiteDiaryFragment on SiteDiary {
    _id
    name
    entry_date_time
    is_client_shared
    weather {
      location
      temperature
      wind_speed
      humidity
      weather_description
    }
    note
    createdAt
    updatedAt
    created_by {
      _id
      first_name
      last_name
      fullName
    }
    job {
      _id
      name
    }
    category {
      _id
      name
    }
    attachments {
      _id
      name
      url
      type
      size
      upload_url
    }
    template {
      _id
      name
      is_deleted
    }
    values {
      _id
      field {
        _id
        type
        title
        subFields {
          _id
          type
          title
        }
      }
      value
      subValues {
        _id
        columns {
          _id
          field {
            _id
            type
            title
          }
          value
        }
        attachments {
          _id
          name
          url
          type
          size
          upload_url
        }
      }
      attachments {
        _id
        name
        url
        type
        size
        upload_url
      }
    }
  }
`;

export const SITE_DIARY_TEMPLATE_FRAGMENT = gql`
  fragment SiteDiaryTemplateFragment on SiteDiaryTemplate {
    _id
    name
    type
    business {
      _id
      name
    }
    createdAt
    updatedAt
    fields {
      _id
      type
      title
      options
      subFields {
        _id
        type
        title
      }
    }
  }
`;
