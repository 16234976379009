import { Rounding } from "../components/calculator/utils";
import { JobDetails } from "./job";
import { SupplierDetails } from "./supplier";

export type Roster = {
  _id: string;
  supplier: SupplierDetails | null;
  reference: string;
  internalNote?: string;
  status: RosterStatus;
  rosterNumber: number;
  date: string;
  items: RosterItem[];
  job?: JobDetails;
  __typename: string;
};

export type RosterModalListItem = RosterItem & {
  roster: {
    _id: string;
    rosterNumber: number;
    date: string;
    status: string;
    job: {
      _id: string;
    };
  };
};

export type RosterItem = {
  UOM: string;
  cost: number;
  name: string;
  quantity: number;
  rounding: Rounding;
  wastage: number;
  __typename: string;
  _id: string;
  costingItemId: string;
  costingCategoryName: string;
};

export type RosterItemPayload = {
  UOM: string | null;
  cost: number | null;
  name: string;
  note?: string;
  quantity: number | null;
  rounding?: Rounding;
  wastage: number;
  margin_amount?: 0;
  costingItemId?: string;
  costingCategoryName?: string | null;
};

export type RosterPayload = {
  _id?: string;
  reference: string;
  internalNote?: string;
  date: string;
  status?: string;
  items: RosterItemPayload[];
};

export type RosterListItem = {
  _id: string;
  status: RosterStatus;
  date: string;
  reference: string;
  rosterNumber: number;
};

export enum RosterStatus {
  DRAFT = "DRAFT",
  CANCELLED = "CANCELLED",
  SENT = "SENT",
  APPROVED = "APPROVED",
}
