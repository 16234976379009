import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import { map, head, compact } from "lodash";
import moment from "moment";

import CardPlaceholder from "../../../../components/dashboard/card-placeholder";
import {
  CLIENT_LIST_CLAIMS,
  CLIENT_GET_CLAIM,
  GET_CLIENT_CLAIM_PREVIEW,
} from "../../../../graphql/queries/client/job-claims/queries";
import ClientClaimCard from "../../../../components/claims/client-claim-card";
import {
  ClientListClaimsResponse,
  ClientGetClaimByIdResponse,
} from "../../../../graphql/types/models/client-job-claim";
import { ClientGetClaimByIdInput } from "../../../../graphql/types/inputs/client-job-claim";
import EmptyPlaceholder from "../../../../components/empty-placeholder";
import CategorySelectorCard, {
  CategorySelectorFilter,
} from "../../../../components/category-select-card";
import { formatQuoteNumber } from "../../../../utils/text";
import { getClaimBadgeVariant } from "../../../jobs/job-claims/utils";
import { printBase64Pdf } from "../../../../utils/pdf";
import {
  GetClaimPreviewResponse,
  GetClientClaimPreviewResponse,
} from "../../../../graphql/types/models/job-claim";
import { GET_CLAIM_PREVIEW } from "../../../../graphql/queries/job-claim/queries";
import { ClaimView } from "../../../../models/claim";

type Params = {
  id: string;
};

type ClientJobProgressClaimsProps = RouteComponentProps<Params>;

const ClientJobProgressClaims: React.FC<ClientJobProgressClaimsProps> = ({
  match,
}) => {
  const { id: jobId } = match.params;

  const { t } = useTranslation();
  const client = useApolloClient();

  const [progressFilter, setFilter] = useState<CategorySelectorFilter>({
    id: "",
  });

  const { data: clientClaimsList, loading: clientClaimsLoading } = useQuery<
    ClientListClaimsResponse
  >(CLIENT_LIST_CLAIMS, {
    variables: {
      jobId,
    },
  });

  const [
    getClaim,
    { data: clientClaimDetails, loading: claimLoading, error: claimError },
  ] = useLazyQuery<ClientGetClaimByIdResponse, ClientGetClaimByIdInput>(
    CLIENT_GET_CLAIM
  );

  React.useEffect(() => {
    if (
      clientClaimDetails?.clientGetJobProgressClaimById ||
      claimLoading ||
      claimError
    ) {
      return;
    }

    const firstClaim = head(clientClaimsList?.clientGetJobProgressClaims);

    if (firstClaim) {
      getClaim({
        variables: {
          jobId,
          progressId: firstClaim._id,
        },
      });

      setFilter({
        id: firstClaim._id,
      });
    }
  }, [
    clientClaimDetails,
    claimLoading,
    jobId,
    getClaim,
    clientClaimsList,
    claimError,
  ]);

  const handleSelectCategory = React.useCallback(
    (filter: CategorySelectorFilter) => {
      if (filter.id) {
        getClaim({
          variables: {
            jobId,
            progressId: filter.id,
          },
        });
        setFilter(filter);
      }
    },
    [jobId, getClaim]
  );

  const claimsCategories = React.useMemo(() => {
    const data = clientClaimsList?.clientGetJobProgressClaims;

    return map(data, (claim) => ({
      id: claim._id,
      label: formatQuoteNumber(claim.invNumber, "PC"),
      description: compact([
        moment(claim.claimDate).format("D MMMM YYYY"),
        claim.description,
      ]).join(": "),
      rightLabel: t("common.currency", { amount: claim.amount }),
      rightBadge: [
        {
          variant: getClaimBadgeVariant(claim.status),
          label: t(`claims.clientStatus.${claim.status}`),
        },
      ],
    }));
  }, [t, clientClaimsList]);

  const handlePreviewPdf = React.useCallback(
    async (progressId: string) => {
      try {
        const pdfPreview = await client.query<GetClientClaimPreviewResponse>({
          query: GET_CLIENT_CLAIM_PREVIEW,
          variables: {
            jobId,
            progressId,
          },
          fetchPolicy: "network-only",
        });
        printBase64Pdf(pdfPreview?.data?.clientGetProgressClaimPreview.pdf);
      } catch (e) {}
    },
    [client, jobId]
  );

  const renderClaimCard = () => {
    if (!clientClaimDetails?.clientGetJobProgressClaimById || claimLoading) {
      return <CardPlaceholder />;
    }

    return (
      <ClientClaimCard
        claim={clientClaimDetails.clientGetJobProgressClaimById}
        onPrint={handlePreviewPdf}
      />
    );
  };

  return (
    <Container fluid className="h-100">
      <Helmet title={t("navigation.jobsSection.progressClaims")} />

      {clientClaimsList?.clientGetJobProgressClaims?.length ? (
        <Row className="h-100">
          <Col lg={4} xs={12}>
            <CategorySelectorCard
              title={t("claims.allClaims")}
              filter={progressFilter}
              onSelectCategory={handleSelectCategory}
              categories={claimsCategories}
              disabled={clientClaimsLoading || claimLoading}
              hideSelectAllButton
              hideAddButton
            />
          </Col>
          <Col lg={8} xs={12}>
            {renderClaimCard()}
          </Col>
        </Row>
      ) : (
        <EmptyPlaceholder message={t("client.claims.emptyPlaceholder")} />
      )}
    </Container>
  );
};

export default ClientJobProgressClaims;
