import { gql } from "@apollo/client";
import { ROSTER_ITEM_FRAGMENT } from "../job-roster/fragments";
import { TIMESHEET_REQUEST_FRAGMENT } from "../timesheet/fragments";

export const JOB_COSTING_PROGRESS_CLAIM_ITEM_FRAGMENT = gql`
  fragment JobCostingProgressClaimItemFragment on ProgressClaimItem {
    _id
    name
    amount
    percentage
    amountToDate
    percentageToDate
    total
    remaining
    markup
    markupSubTotal
    markupGST
    markupTotal
    note
    updatedAt
    createdAt
    progressClaim {
      _id
      invNumber
      description
      status
      subTotal
      markupSubTotal
      markupGST
      markupTotal
      invoiceSubTotal
      invoiceGST
      invoiceTotal
    }
  }
`;

export const JOB_COSTING_ITEM_FRAGMENT = gql`
  fragment JobCostingItemFragment on JobCostingItem {
    _id
    UOM
    cost
    markup_cost
    name
    quantity
    raw_quantity
    wastage
    rounding
    real_cost
    hasGST
    note
    quoteRequestStatus
    quoteRequestItems {
      _id
      name
      cost
      quantity
      UOM
      quoteRequest {
        _id
        name
        status
        quoteNumber
        due_date
        subcontractors {
          _id
          status
          supplier {
            _id
            business_name
          }
          items {
            _id
            cost
            quoteItemId
          }
        }
      }
    }
    is_locked
    is_restricted_entry
    is_allowance
    type
    category {
      _id
      name
    }
    items {
      _id
      UOM
      cost
      name
      quantity
      wastage
      raw_quantity
      rounding
      computed_quantity
      items {
        _id
        UOM
        cost
        name
        quantity
        wastage
        rounding
        computed_quantity
      }
    }
    purchaseOrderItems {
      _id
      orderId
      rounding
      wastage
      UOM
      cost
      quantity
      name
      purchaseOrder {
        _id
        orderNumber
        reference
        status
        attention
        deliveryDate
        date
        supplier {
          _id
          email
          business_name
          contact_person
        }
        job {
          _id
        }
        receipts {
          _id
          reference
          dateReceived
          hasGST
          subTotal
          GST
          total
          purchaseOrder {
            _id
            orderNumber
            job {
              _id
            }
            supplier {
              _id
              email
              business_name
              contact_person
            }
          }
          items {
            _id
            itemNumber
            name
            UOM
            cost
            margin_amount
            quantity
            purchaseOrderItem {
              _id
              costingItemId
            }
          }
          progressClaimItem {
            ...JobCostingProgressClaimItemFragment
          }
        }
      }
    }
    progressClaimItems {
      ...JobCostingProgressClaimItemFragment
    }
    timesheetItems {
      ...TimesheetRequest
    }
    rosterItems {
      ...RosterItemFragment
    }
    variation {
      _id
      variationId
    }
    variationItems {
      _id
      name
      UOM
      quantity
      cost
      job {
        _id
      }
      variation {
        _id
        variationNumber
        status
        date
      }
    }
  }
  ${JOB_COSTING_PROGRESS_CLAIM_ITEM_FRAGMENT}
  ${TIMESHEET_REQUEST_FRAGMENT}
  ${ROSTER_ITEM_FRAGMENT}
`;
