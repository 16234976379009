import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SalesQuote } from "../../../../../models/salesQuote";
import Icon from "../../../../../components/icons/Icon";
import { formatQuoteNumber, getFullName } from "../../../../../utils/text";
import EstimationStatus from "../../../lead-overview/estimation-status";
import "./styles.scss";

type EstimationItemProps = {
  leadEstimate: SalesQuote;
  onUnlink: (leadEstimate: SalesQuote) => void;
};

const EstimationItem: React.FC<EstimationItemProps> = (props) => {
  const { leadEstimate, onUnlink } = props;
  const { t } = useTranslation();

  const handleUnlink = React.useCallback(() => {
    onUnlink(leadEstimate);
  }, [onUnlink, leadEstimate]);

  return (
    <div className="estimation-item p-3">
      <div className="estimation-item-content">
        <Link to={`/quotes/${leadEstimate._id}`} className="field-text">
          {`${formatQuoteNumber(leadEstimate.number, "Q")} ${
            leadEstimate.name
          }`}
        </Link>

        <div className="estimation-item--creation pt-3">
          <div className="estimation-item--creation--date d-flex">
            <Icon name="schedule" outlined />
            <div
              className="pl-1 field-text"
              title={moment(leadEstimate.createdAt).format("Do MMM YYYY HH:mm")}
            >
              {moment(leadEstimate.createdAt).format("Do MMM YYYY")}
            </div>
          </div>
          <div className="estimation-item--creation--user pl-4 d-flex">
            <Icon name="attach_money" outlined />
            <div className="pl-1 field-text">
              {leadEstimate?.total.toLocaleString("en-US")}
            </div>
          </div>
          <div className="estimation-item--creation--user pl-4 d-flex">
            <Icon name="person" outlined />
            <div className="pl-1 field-text">
              {getFullName(leadEstimate?.contact)}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-2">
          <EstimationStatus status={leadEstimate.status} />
          <div className="flex-grow-1"></div>
          <div
            className="d-flex cursor-pointer align-items-center "
            onClick={handleUnlink}
          >
            <Icon name="link_off" outlined />
            <div className="pl-1 field-text unlink">
              {t("leads.unlinkEstimation")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimationItem;
