import React, { forwardRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { chain, compact, isArray, pick, uniqBy } from "lodash";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import CreateEntityModal from "../create-entity";
import { SendEmailForm } from "../../../models/email";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { createQuoteEmailField } from "./utils";
import createSendQuoteSchema from "./SendModal.schema";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import { ContactDetail } from "../../../graphql/types/models/client";
import { SupplierDetails } from "../../../models/supplier";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { TeamMember } from "../../../models/team-member";
import FormField from "../../generic-form/form-field";
import { useAttachment } from "./hooks/useAttachment";
import { GetRemoteAttachmentsResponse } from "../../../graphql/types/models/email";
import { GET_REMOTE_ATTACHMENTS_URL } from "../../../graphql/queries/email/mutations";
import { getMediaInput } from "../../../utils/transform";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { Document } from "../../../models/documents";
import "./styles.scss";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import {
  SelectDocumentsModalType,
  useSelectDocuments,
} from "../../../hooks/useSelectDocuments";
import Icon from "../../icons/Icon";
import { getFullName } from "../../../utils/text";

type ModalOption = {
  email: string;
  contact_person?: string;
  first_name?: string;
  last_name?: string;
};

type SendModalProps = {
  onSubmit: (data: SendEmailForm) => void;
  contacts?: ModalOption[];
  contact?:
    | TeamMember
    | ContactDetail
    | SupplierDetails
    | TeamMember[]
    | ContactDetail[]
    | SupplierDetails[]
    | null;
  title: string;
  subject?: string;
  fileName?: string;
  onFileClick?: () => void;
  user: UserPayload | null;
  submitText?: string;
  jobId?: string;
  disableContactSelect?: boolean;
  addDocumentsSelect?: SelectDocumentsModalType;
  copyOptions?: {
    showCc?: boolean;
    setShowCc?: (value: boolean) => void;
    showBcc?: boolean;
    setShowBcc?: (value: boolean) => void;
    showReplyTo?: boolean;
  };
};

const SendModal: React.FC<SendModalProps> = (props, ref) => {
  const {
    onSubmit,
    contacts,
    contact,
    title,
    subject,
    fileName,
    onFileClick,
    user,
    submitText,
    disableContactSelect,
    copyOptions,
    addDocumentsSelect,
  } = props;
  const { t } = useTranslation();

  const { shouldShow, hide } = useModalDisplay(ref);

  const [tempContact, setTempContacts] = useState<SelectOption[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<Document[] | null>(
    null
  );
  const [replyTo, setReplyTo] = React.useState(false);

  const {
    attachments,
    openFilePicker,
    renderAttachments,
    uploadAttachments,
    clearAttachments,
    renderDropzone,
  } = useAttachment();

  const handleSelectDocuments = React.useCallback(
    (docs: Document[]) => {
      setSelectedDocuments(
        uniqBy([...compact(selectedDocuments), ...docs], "_id")
      );
    },
    [selectedDocuments]
  );

  const {
    renderSelectDocumentModal,
    handleOpenSelectDocument,
    handleCloseSelectedFiles,
  } = useSelectDocuments({
    entityId: addDocumentsSelect?.entityId,
    systemFolderType: addDocumentsSelect?.systemFolderType,
    onSelect: handleSelectDocuments,
    selected: selectedDocuments,
  });

  React.useEffect(() => {
    if (!shouldShow) {
      clearAttachments();
      setSelectedDocuments(null);
    }
  }, [shouldShow]);

  const [getRemoteAttachments] = useMutation<GetRemoteAttachmentsResponse>(
    GET_REMOTE_ATTACHMENTS_URL
  );
  const { data: teamList } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<SendEmailForm>
  >({});

  const handleTempRecipientCreate = React.useCallback(
    (email: string) => {
      const option = {
        value: email,
        label: email,
      };

      setTempContacts([option, ...tempContact]);
    },
    [tempContact]
  );

  const getContactName = (contact: ModalOption) => {
    const name = compact([
      contact.contact_person,
      contact.first_name,
      contact.last_name,
    ]).join(" ");

    return (name ? `"${name}" ` : "") + `<${contact.email}>`;
  };

  const teamMembersOptions = React.useMemo(() => {
    return chain(teamList?.listMembers)
      .map((contact) => ({
        value: contact.email,
        label: getContactName(contact),
      }))
      .concat(tempContact)
      .uniqBy("value")
      .filter("value")
      .value();
  }, [teamList?.listMembers, tempContact]);

  const replyToOptions = React.useMemo(
    () =>
      copyOptions?.showReplyTo
        ? uniqBy(
            [
              {
                value: user?.email,
                label: `"${getFullName(user)}" <${user?.email}>`,
              },
              {
                value: user?.business.email,
                label: `"${user?.business.name}" <${user?.business.email}>`,
              },
            ],
            "value"
          )
        : [],
    [copyOptions, user]
  );

  const copyMailOptions = React.useMemo(() => {
    if (copyOptions) {
      return {
        ...copyOptions,
        teamMembers: teamMembersOptions,
        replyTo,
        setReplyTo,
      };
    }
  }, [copyOptions, replyTo, teamMembersOptions]);

  const handleRemoveSelectedFile = React.useCallback(
    (file: Document) => {
      if (!selectedDocuments) return;
      setSelectedDocuments(
        selectedDocuments?.filter((selected) => selected._id !== file._id)
      );
    },
    [selectedDocuments]
  );

  React.useEffect(() => {
    if (!shouldShow) {
      handleCloseSelectedFiles();
      if (copyOptions) {
        copyOptions?.setShowCc && copyOptions.setShowCc(false);
        copyOptions?.setShowBcc && copyOptions.setShowBcc(false);
        setReplyTo(false);
      }
    }
  }, [shouldShow, copyOptions]);

  React.useEffect(() => {
    const contactOptions = chain(contacts)
      .map((contact) => ({
        value: contact.email,
        label: getContactName(contact),
      }))
      .concat(tempContact)
      .uniqBy("value")
      .filter("value")
      .value();

    setFormFields(
      createQuoteEmailField(
        t,
        contactOptions,
        handleTempRecipientCreate,
        disableContactSelect,
        copyMailOptions,
        replyToOptions as SelectOption[]
      )
    );
  }, [
    handleTempRecipientCreate,
    t,
    contacts,
    tempContact,
    disableContactSelect,
    copyMailOptions,
  ]);

  const bottomMessage = React.useMemo(() => {
    if (!fileName) {
      return;
    }

    return {
      icon: "insert_drive_file",
      text: fileName,
      outlined: true,
      onClick: onFileClick,
    };
  }, [fileName, onFileClick]);

  const initialValues = React.useMemo(() => {
    const values = {
      title: subject || "",
      // message: "<p></p>" + DOMPurify.sanitize(user?.signature?.html || ""),
      message: "",
      messageRaw: user?.signature?.raw,
      replyTo: user?.email ?? "",
    };
    let to = [];
    if (isArray(contact)) {
      to = compact(contact.map((c) => c.email));
    } else if (contact?.email) {
      to.push(contact.email);
    }
    return { ...values, to };
  }, [contact, user, subject]);

  const handleSubmit = React.useCallback(
    async (values: SendEmailForm) => {
      let remoteAttachmentId;
      if (attachments.length || selectedDocuments?.length) {
        const remoteAttachmentResponse = await getRemoteAttachments({
          variables: {
            attachments: getMediaInput(attachments),
            ...(selectedDocuments && {
              documentIds: selectedDocuments.map((file) => file._id),
            }),
          },
        });
        const remoteAttachmentsData =
          remoteAttachmentResponse.data?.getRemoteAttachmentsUrl;
        const remoteAttachments = remoteAttachmentsData?.attachments;
        const selectedFilesIds = selectedDocuments?.map((file) => file._id);
        const remoteAttachmentsToUpload = remoteAttachments?.filter(
          (remote) => !selectedFilesIds?.includes(remote._id)
        );

        if (remoteAttachmentsToUpload && remoteAttachmentsToUpload.length > 0) {
          await uploadAttachments(remoteAttachmentsToUpload);
        }

        remoteAttachmentId = remoteAttachmentsData?._id;
      }
      return onSubmit({
        ...pick(values, ["title", "to", "message", "cc", "bcc"]),
        ...(replyTo && { replyTo: values.replyTo }),
        remoteAttachmentId,
      });
    },
    [
      attachments,
      selectedDocuments,
      onSubmit,
      getRemoteAttachments,
      uploadAttachments,
      replyTo,
    ]
  );

  const renderAttach = React.useCallback(() => {
    return (
      <Container fluid>
        <Row>
          <Col className="form-column" sm={12}>
            {!Boolean(addDocumentsSelect) ? (
              <>
                <FormField name="attachments" label={t("common.attachments")}>
                  {renderAttachments()}
                </FormField>
              </>
            ) : (
              <>
                <div className="mt-2 d-flex justify-content-between">
                  <FormField
                    name="attachments"
                    label={t("common.attachments")}
                  />
                  <Button
                    variant="primary"
                    className="button info select-documents-button"
                    onClick={handleOpenSelectDocument}
                  >
                    {t("documents.selectFromDocuments")}
                  </Button>
                </div>
                <div className="mb-2">{renderAttachments()}</div>
                <div className="mb-2">
                  {selectedDocuments?.map((attachment) => (
                    <div className="d-flex flex-row ra" key={attachment.name}>
                      <div className="pr-2 ra-name">
                        <b>{attachment.name}</b>
                      </div>
                      <div className="pr-2">
                        <span>
                          ({t("common.fileSize", { amount: attachment.size })})
                        </span>
                      </div>
                      <div
                        className="d-flex align-items-center ra-remove"
                        onClick={() => handleRemoveSelectedFile(attachment)}
                      >
                        <Icon name="close" />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {renderDropzone()}
          </Col>
        </Row>
      </Container>
    );
  }, [
    addDocumentsSelect,
    handleOpenSelectDocument,
    renderAttachments,
    renderDropzone,
    selectedDocuments,
    handleRemoveSelectedFile,
    t,
  ]);

  return (
    <>
      {addDocumentsSelect && renderSelectDocumentModal()}
      <CreateEntityModal
        validationSchema={createSendQuoteSchema(t)}
        className="send-email-modal"
        title={title}
        show={shouldShow}
        data={initialValues}
        onSubmit={handleSubmit}
        onClose={hide}
        fields={formFields}
        bottomMessage={bottomMessage}
        submitText={submitText}
        endRenderer={renderAttach()}
      />
    </>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  forwardRef(SendModal)
);
