import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { filter, find, map, pick, omit } from "lodash";
import CreateEntityModal from "../../modals/create-entity";
import {
  BulkJobsScheduleCategoryItemPayload,
  BulkJobsScheduleCategoryPayload,
} from "../../../models/job";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createImportScheduleSchema from "./ImportScheduleFromCosting.schema";
import createImportScheduleFields from "./utils";
import { JobCostingCategory } from "../../../models/job";
import ImportScheduleFromCostingItemModal, {
  ImportScheduleFromCostingItemModalRef,
} from "../import-schedule-from-costing-items";
import { EXTRA_COLOURS } from "../../../constants/colours";
import "./styles.scss";

export type ImportScheduleFromCostingPayload = {
  items: BulkJobsScheduleCategoryPayload[];
};
export type ImportScheduleFromCostingItemPayload = {
  items: BulkJobsScheduleCategoryItemPayload[];
};
type ImportJobCostingCategory = JobCostingCategory & {
  startDate: string;
  endDate: string;
  // colour: string;
};
type ImportScheduleFromCostingModalProps = {
  data: JobCostingCategory[];
  onSubmit: (data: BulkJobsScheduleCategoryPayload[]) => void;
  onClose: () => void;
};

export type ImportScheduleFromCostingModalRef = {
  show: (show: boolean) => void;
};

const ImportScheduleFromCostingModal: React.FC<ImportScheduleFromCostingModalProps> = (
  props,
  ref
) => {
  const { t } = useTranslation();
  const { onSubmit, onClose, data } = props;

  const [show, setShowModal] = React.useState(false);
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<ImportScheduleFromCostingPayload>
  >({});
  const [selectedCategories, setSelectedCategories] = React.useState<
    ImportJobCostingCategory[]
  >([]);
  const itemRef = React.useRef<ImportScheduleFromCostingItemModalRef>();

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => setShowModal(show),
  }));

  React.useEffect(() => {
    setFormFields(createImportScheduleFields(t));
  }, [t]);

  const initialData = React.useMemo<ImportScheduleFromCostingPayload>(
    () => ({
      items: data.map((item, index) => ({
        _id: item._id,
        name: item.name,
        startDate: moment().format(),
        endDate: moment().add(1, "day").format(),
        colour: EXTRA_COLOURS[index % EXTRA_COLOURS.length],
      })),
    }),
    [data]
  );

  const handleNext = React.useCallback(
    (values: ImportScheduleFromCostingPayload) => {
      let hasItems = false;
      const categories = map(values.items, (value) => {
        const category = find(data, { _id: value._id });
        if (category?.items?.length && category.items.length > 0) {
          hasItems = true;
        }
        return {
          ...pick(value, ["_id", "name", "startDate", "endDate", "colour"]),
          startDate: moment(value.startDate).utc().startOf("day").toISOString(),
          endDate: moment(value.endDate).utc().endOf("day").toISOString(),
          items: category?.items,
        };
      }) as ImportJobCostingCategory[];
      setSelectedCategories(categories);
      if (hasItems) {
        itemRef.current?.show(true);
        return true;
      }
      const scheduleCategories = map(categories, (c) => ({
        ...omit(c, ["items"]),
      })) as ImportJobCostingCategory[];
      onSubmit && onSubmit(scheduleCategories);
      setShowModal(false);
    },
    [data, itemRef, onSubmit]
  );

  const handleSubmit = React.useCallback(
    (values: ImportScheduleFromCostingPayload) => {
      const categories = map(selectedCategories, (category) => {
        const items = filter(values.items, { categoryId: category._id });
        return {
          ...pick(category, ["name", "startDate", "endDate", "colour"]),
          scheduleItems: map(
            items,
            (item: BulkJobsScheduleCategoryItemPayload) => ({
              name: item.name,
              description: item.description,
              startDate: moment(item.startDate)
                .utc()
                .startOf("day")
                .toISOString(),
              endDate: moment(item.endDate).utc().endOf("day").toISOString(),
            })
          ),
        };
      }) as BulkJobsScheduleCategoryPayload[];
      setShowModal(false);
      onSubmit && onSubmit(categories);
    },
    [selectedCategories, itemRef, onSubmit]
  );

  return (
    <>
      <CreateEntityModal
        validationSchema={createImportScheduleSchema(t)}
        title={t("schedule.importSchedule")}
        className="import-schedule-from-costing-modal"
        data={initialData}
        show={show}
        fields={formFields}
        onSubmit={handleNext}
        onClose={onClose}
        submitText={t("common.next")}
      />
      <ImportScheduleFromCostingItemModal
        ref={itemRef}
        data={selectedCategories}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default forwardRef(ImportScheduleFromCostingModal);
