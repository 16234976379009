import { ContactDetail } from "../graphql/types/models/client";
import { SupplierDetails } from "./supplier";

export type CreateReminderPayload = {
  title: string;
  description: string;
  dueDate: string;
  repeat: null | string;
};

export enum ReminderRepeatTypeEnum {
  None = "None",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Annually = "Annually",
}

export type Reminder = {
  _id: string;
  title: string;
  description: string;
  completed: boolean;
  dueDate: number;
  supplier?: SupplierDetails;
  contact?: ContactDetail;
};
