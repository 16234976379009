import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../../utils/yup";

export default function createUpdateAssemblySchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "common.name")
      ),
    UOM: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "takeOffSection.measurement"
        )
      ),
    items: yup.array().of(
      yup.object().shape({
        cost: yup
          .number()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "assembly.cost")
          )
          .moreThan(0),
        quantity: yup
          .number()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "assembly.quantity"
            )
          )
          .moreThan(0),
        UOM: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "assembly.UOM")
          ),
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "common.name")
          ),
      })
    ),
  });
}
