import React from "react";
import { OptionProps } from "react-select";
import classNames from "classnames";

import Icon from "../../icons/Icon";

const HeaderSearchMenuOption: React.FC<OptionProps<any>> = ({
  label,
  data,
  isSelected,
  selectOption,
}) => {
  const handleOptionClick = React.useCallback(() => {
    selectOption(data);
  }, []);

  return (
    <div
      className={classNames("search-menu-option", { selected: isSelected })}
      onMouseDown={handleOptionClick}
    >
      <span>{label}</span>
      <Icon name="chevron_right" />
    </div>
  );
};

export default HeaderSearchMenuOption;
