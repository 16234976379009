import React from "react";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../components/confirm-dialog";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
  DeleteClientMessageResponse,
  DeleteMessageResponse,
} from "../../graphql/types/models/job-messaging";
import { JOB_DELETE_MESSAGE } from "../../graphql/queries/job-messaging/mutations";
import { JOB_DELETE_CLIENT_MESSAGE } from "../../graphql/queries/client-job-messaging/mutations";

type DeleteJobChannelMessageMutationProps = {
  channelId?: string;
  jobId?: string;
  isClient?: boolean;
};

export function useDeleteJobChannelMessageMutation(
  props: DeleteJobChannelMessageMutationProps
) {
  const { channelId, jobId, isClient } = props;
  const { t } = useTranslation();

  const [messageId, setMessageId] = React.useState<string | null>(null);
  const deleteMessageConfirmRef = React.useRef<ConfirmDialogRef>(null);

  const [deleteMessage] = useMutation<DeleteMessageResponse>(
    JOB_DELETE_MESSAGE
  );

  const [deleteClientMessage] = useMutation<DeleteClientMessageResponse>(
    JOB_DELETE_CLIENT_MESSAGE
  );

  const handleShowDeleteMessageConfirmDialog = React.useCallback(
    (id: string) => {
      setMessageId(id);
      deleteMessageConfirmRef.current?.show(true);
    },
    []
  );

  const onClose = React.useCallback(() => {
    setMessageId(null);
    deleteMessageConfirmRef.current?.show(false);
  }, []);

  const handleOnDeleteMessage = React.useCallback(async () => {
    if (!channelId || !jobId) return;

    try {
      if (isClient) {
        return await deleteClientMessage({
          variables: {
            channelId,
            jobId,
            messageId,
          },
        });
      }
      await deleteMessage({
        variables: {
          channelId,
          jobId,
          messageId,
        },
      });
    } catch (e) {}
  }, [
    channelId,
    jobId,
    isClient,
    deleteMessage,
    messageId,
    deleteClientMessage,
  ]);

  const renderDeleteMessageConfirmDialog = React.useCallback(() => {
    return (
      <ConfirmDialog
        ref={deleteMessageConfirmRef}
        title={t("communication.deleteMessage")}
        confirm={t("common.delete")}
        onSubmit={handleOnDeleteMessage}
        onClose={onClose}
      >
        <span className="field-text">
          {t("communication.deleteMessageText")}
        </span>
      </ConfirmDialog>
    );
  }, [handleOnDeleteMessage, onClose, t, channelId]);

  return {
    renderDeleteMessageConfirmDialog,
    handleShowDeleteMessageConfirmDialog,
  };
}
