import React from "react";
import { useTranslation } from "react-i18next";
import {
  UpdateTakeOffPayload,
  TakeoffListItem,
} from "../../../../models/take-off";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import CreateEntityModal from "../../../modals/create-entity";
import createTakeOffSchema from "../create-take-off/CreateTakeOffSchema";
import { createUpdateTakeOffFields } from "./utils";
import { useModalDisplay } from "../../../../hooks/useModalDisplay";
import "../create-take-off/styles.scss";

type UpdateTakeOffModalProps = {
  onSubmit: (data: UpdateTakeOffPayload) => void;
  takeOff?: TakeoffListItem | null;
};
const UpdateTakeOffModal: React.FC<UpdateTakeOffModalProps> = (
  { onSubmit, takeOff },
  ref
) => {
  const { t } = useTranslation();

  const { shouldShow, hide } = useModalDisplay(ref);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<UpdateTakeOffPayload>
  >({});

  React.useEffect(() => {
    setFormFields(createUpdateTakeOffFields(t));
  }, [t]);

  if (!takeOff) return null;

  return (
    <CreateEntityModal
      validationSchema={createTakeOffSchema(t)}
      title={t("takeOffSection.editTakeOff")}
      show={shouldShow}
      data={{
        ...takeOff,
        _id: takeOff._id || "",
        color: takeOff.properties.color,
      }}
      className="take-off-form-modal"
      onSubmit={onSubmit}
      onClose={hide}
      fields={formFields}
      submitText={t("common.update")}
    />
  );
};

export default React.forwardRef(UpdateTakeOffModal);
