import moment, { unitOfTime } from "moment";
import { TFunction } from "i18next";
import pluralize from "pluralize";
import { chain } from "lodash";

type DurationValue = {
  name: string;
  amount: number;
};

export const formatDuration = (
  durationTime: number,
  t: TFunction,
  delimiter: string = " ",
  type: "default" | "short" = "default"
) => {
  const duration = moment.duration(durationTime);

  const keys = ["years", "months", "days", "hours", "minutes"];

  const durationStr = chain(keys)
    .map((key: string) => ({
      name: key,
      amount: Math.abs(duration.get(key as unitOfTime.Base)),
    }))
    .filter((d: DurationValue) => d.amount > 0)
    .map((d) =>
      type === "default"
        ? pluralize(t(`date.${d.name}Amount`), d.amount, true)
        : t(`date.s_${d.name}Amount`, d)
    )
    .value()
    .join(delimiter);

  return durationStr || "0 minutes";
};

export const parseDate = (date: string) => {
  return moment(date, [
    "DD-MM-YYYY",
    "Do MMMM YYYY",
    "DD/MM/YYYY",
    "DD-MM-YY",
    "DD/MM/YY",
    "D-M-YY",
    "D/M/YY",
  ]);
  // .tz(timezone || "Australia/Melbourne")
};
