import React from "react";
import { DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BaseCard, Column } from "..";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";
import ConfirmInput from "../../confirm-input";
import Icon from "../../icons/Icon";
import List from "../list/List";
import "./style.scss";

type ListsProps<TItem> = {
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
  lists: Column<TItem>[];
  setColumnsList: (columns: Column<TItem>[]) => void;
  addColumn: (newColumn: { name: string; order: number; _id?: string }) => void;
  deleteColumn: (columnId: string) => void;
  renderCard: (item: TItem, index: number) => JSX.Element;
  addCard: () => void;
  renderLeadsTable?: () => JSX.Element;
  renderFilters?: () => JSX.Element;
  gridView?: boolean;
  toggleGridView?: () => void;
  addButtonPlaceholder?: string;
  children?: React.ReactNode;
};

const Lists = <TItem extends BaseCard>({
  provided,
  snapshot,
  lists,
  setColumnsList,
  addColumn,
  deleteColumn,
  renderCard,
  addCard,
  addButtonPlaceholder,
  renderFilters,
  renderLeadsTable,
  gridView,
  toggleGridView,
}: ListsProps<TItem>) => {
  const { t } = useTranslation();
  const [openAddColumnInput, setOpenAddColumnInput] = React.useState<boolean>(
    false
  );
  const confirmDeleteStageRef = React.useRef<ConfirmDialogRef>(null);
  const handlePressCancel = React.useCallback(() => {
    setOpenAddColumnInput(false);
  }, []);

  const handlePressAdd = React.useCallback(
    (value: string) => {
      setOpenAddColumnInput(false);
      const newColumn = {
        name: value,
        order: lists ? lists.length + 1 : 1,
      };
      addColumn(newColumn);
    },
    [addColumn, lists]
  );

  const handleOnRename = React.useCallback(
    (stageID: string, order: number, name: string) => {
      const renamedColumn = {
        name,
        _id: stageID,
        order,
      };
      addColumn(renamedColumn);
    },
    [addColumn]
  );

  const handlePressDeleteColumn = React.useCallback(
    (columnId: string) => {
      const selectedStage = lists.find((stage) => stage._id === columnId);
      if (selectedStage && selectedStage?.cards.length > 0) {
        confirmDeleteStageRef?.current?.show(true);
        return;
      }
      const filteredStages = lists.filter((stage) => stage._id !== columnId);
      setColumnsList(filteredStages);
      deleteColumn(columnId);
    },
    [deleteColumn, lists, setColumnsList]
  );

  const closeRemoveStageDialog = React.useCallback(() => {
    confirmDeleteStageRef?.current?.show(false);
  }, [confirmDeleteStageRef]);

  const onAddCard = React.useCallback(() => {
    addCard();
  }, [addCard]);

  const renderGridIcon = () => {
    return (
      <button onClick={toggleGridView} className="grid-icon">
        {gridView ? (
          <Icon name="grid_view" outlined />
        ) : (
          <Icon name="format_list_bulleted" outlined />
        )}
      </button>
    );
  };

  const renderActionButton = () => {
    return (
      <Button
        key={"onAddCard"}
        className="button large success"
        onClick={onAddCard}
      >
        <Icon name="add" />
        {addButtonPlaceholder || t("leads.addCard")}
      </Button>
    );
  };

  return (
    <>
      <div className="filters-container d-flex justify-content-between mb-4">
        <div className="d-flex"> {renderFilters && renderFilters()}</div>
        <div className="d-flex">
          {toggleGridView && renderGridIcon()}
          {renderActionButton()}
        </div>
      </div>
      {gridView ? (
        <Row className="w-100 mt-2">
          <Col className="pl-1 pr-1" xs={12}>
            {renderLeadsTable && renderLeadsTable()}
          </Col>
        </Row>
      ) : (
        <div
          className="leads-container"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {lists.map((list, index) => {
            const totalAmounth = list.cards.reduce(
              (accum, item) => accum + (item?.budget || 0),
              0
            );
            const listCardsCount = list.cards.length;
            const listInfo = {
              amount: `$${totalAmounth.toLocaleString("en-US")}`,
              count: t("leads.titleInfo", { count: listCardsCount }),
            };
            return (
              <List<TItem>
                list={list}
                key={`${list._id} ${index}`}
                index={index}
                isDraggingOver={snapshot.isDraggingOver}
                onDelete={handlePressDeleteColumn}
                renderCard={renderCard}
                listInfo={listCardsCount ? listInfo : undefined}
                onRename={handleOnRename}
              />
            );
          })}
          <div>
            <div className="h-100">
              {openAddColumnInput ? (
                <ConfirmInput
                  onCancel={handlePressCancel}
                  onSubmit={handlePressAdd}
                />
              ) : (
                <button
                  onClick={() => setOpenAddColumnInput(!openAddColumnInput)}
                  className="addColumnIconContainer"
                >
                  <Icon name="add" className="addColumnIcon" />
                </button>
              )}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
      <ConfirmDialog
        ref={confirmDeleteStageRef}
        onClose={closeRemoveStageDialog}
        title={t("leads.deleteSelectedStage")}
        confirm={t("common.delete")}
        isInfoDialog
      >
        <div className="field-text">
          {t("leads.errors.deleteSelectedStage")}
        </div>
      </ConfirmDialog>
    </>
  );
};

export default Lists;
