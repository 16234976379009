import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import { WithT } from "i18next";
import { FormikProps } from "formik";
import { ForgetPasswordFormValues } from "./ForgetPasswordFormBody";
import Context, { SiteContext } from "../../../siteContext";

type ForgetPasswordFormFooterProps = WithT &
  Pick<
    FormikProps<ForgetPasswordFormValues>,
    "values" | "handleChange" | "handleBlur" | "isSubmitting"
  >;

const ForgetPasswordFormFooter: React.FC<ForgetPasswordFormFooterProps> = ({
  t,
  isSubmitting,
}) => {
  const { isUser } = React.useContext(Context) as SiteContext;
  return (
    <Row className="w-100 m-0 sign-up-submit">
      <Col lg={5} xs={12} className="d-flex p-0 justify-content-end">
        <Button
          disabled={isSubmitting}
          size="lg"
          variant="success"
          type="submit"
          className="button large success sign-in-button"
        >
          {t("authentication.resetPassword")}
        </Button>
      </Col>
      <Col lg={7} xs={12} className="p-0">
        {isUser && (
          <div className="d-flex p-0 align-items-center sign-up-other ">
            <span className="field-text">
              {t("authentication.dontHaveAccountYet")}
            </span>
            <Link to="/sign-up" className="sign-up-button">
              <Button
                disabled={isSubmitting}
                size="lg"
                variant="outline-success"
                className="button large outline-success"
              >
                {t("authentication.signUp")}
              </Button>
            </Link>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ForgetPasswordFormFooter;
