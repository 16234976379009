import { gql } from "@apollo/client";
import { ACCOUNTING_INTEGRATION_FRAGMENT } from "./fragments";

export const CONNECT_ACCOUNTING = gql`
  mutation($provider: AccountingIntegrationType!) {
    connectAccounting(provider: $provider) {
      url
    }
  }
`;

export const CONNECT_ACCOUNTING_CALLBACK = gql`
  mutation($provider: AccountingIntegrationType!, $data: String!) {
    connectCallback(provider: $provider, data: $data) {
      ...AccountingIntegrationFragment
    }
  }
  ${ACCOUNTING_INTEGRATION_FRAGMENT}
`;

export const DISCONNECT_ACCOUNTING = gql`
  mutation {
    disconnectAccounting {
      ...AccountingIntegrationFragment
    }
  }
  ${ACCOUNTING_INTEGRATION_FRAGMENT}
`;
export const SET_ACCOUNTING_CONFIGURATION = gql`
  mutation($config: AccountingIntegrationConfigInput!) {
    setAccountingConfiguration(config: $config) {
      ...AccountingIntegrationFragment
    }
  }
  ${ACCOUNTING_INTEGRATION_FRAGMENT}
`;

export const CLEAR_ACCOUNTING_INTEGRATION_ERRORS = gql`
  mutation {
    clearAccountingIntegrationErrors
  }
`;

export const SYNC_ACCOUNTING_ITEM = gql`
  mutation(
    $jobId: ID!
    $itemId: ID!
    $itemType: AccountingIntegrationSyncItemType!
  ) {
    syncAccountingItem(jobId: $jobId, itemId: $itemId, itemType: $itemType)
  }
`;
