import { gql } from "@apollo/client";

export const LIST_CLIENT_JOBS = gql`
  query listClientJobs {
    listClientJobs {
      _id
      jobNumber
      name
      startDate
      endDate
      updatedAt
      createdAt
    }
  }
`;

export const GET_CLIENT_JOB = gql`
  query getClientJob($jobId: ID!) {
    getClientJob(jobId: $jobId) {
      _id
      jobNumber
      name
      startDate
      endDate
      updatedAt
      createdAt
    }
  }
`;
