import { gql } from "@apollo/client";

export const DELETE_FOLDER = gql`
  mutation deleteFolder($folderId: ID!) {
    deleteFolder(folderId: $folderId) {
      _id
      name
    }
  }
`;

export const COPY_FOLDER_CONTENT = gql`
  mutation CopyFolderContent($sourceFolderId: ID!, $destFolderId: ID!) {
    copyFolderContent(
      sourceFolderId: $sourceFolderId
      destFolderId: $destFolderId
    ) {
      _id
      name
    }
  }
`;

export const COPY_DOCUMENT = gql`
  mutation CopyDocument($documentId: ID!, $destFolderId: ID!) {
    copyDocument(documentId: $documentId, destFolderId: $destFolderId) {
      _id
      name
      folder {
        _id
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($documentId: ID!) {
    deleteDocument(documentId: $documentId) {
      _id
      name
      folder {
        _id
        name
      }
      type
      size
      width
      height
      upload_url
      createdAt
    }
  }
`;

export const CREATE_UPDATE_FOLDER = gql`
  mutation createUpdateFolder($folder: NewFolderInput!) {
    createUpdateFolder(folder: $folder) {
      _id
      name
      createdAt
      accessRole
      accessSelectedUsers {
        _id
      }
    }
  }
`;

export const CREATE_UPDATE_DOCUMENT = gql`
  mutation createUpdateDocuments($documents: [NewDocumentInput]!) {
    createUpdateDocuments(documents: $documents) {
      _id
      name
      folder {
        _id
        name
        accessRole
        accessSelectedUsers {
          _id
        }
      }
      type
      size
      width
      height
      upload_url
      createdAt
      accessRole
      accessSelectedUsers {
        _id
      }
    }
  }
`;

export const GET_DOCUMENT_DOWNLOAD_URL = gql`
  mutation getDocumentDownloadURL($documentId: ID!) {
    getDocumentDownloadURL(documentId: $documentId) {
      url
      type
      name
    }
  }
`;
