import { UpdateTextInputFn } from "../components/generic-form/GenericFormBody";
import PlacesAPI from "../services/place";

export const GOOGLE_PLACES_API_KEY = "AIzaSyAJMeR1hn2n_nH5aIWsfVwoZr4bPT2Hxw4";

export const PLACE_SEARCH_OPTIONS = {
  componentRestrictions: {
    country: ["AU"],
  },
};
export const handlePlaceSelect: UpdateTextInputFn = async (
  placeId,
  formikProps
) => {
  const details = await PlacesAPI?.getPlaceDetails(placeId);

  if (!details) {
    return;
  }

  const { setFieldValue } = formikProps;
  const { city, state, postcode, address } = details;

  setFieldValue("address", address);
  setFieldValue("city", city);
  setFieldValue("state", state);
  setFieldValue("postcode", postcode);
};
