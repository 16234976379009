import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  chain,
  filter,
  find,
  findIndex,
  head,
  isEmpty,
  map,
  compact,
  isEqual,
  uniqueId,
  pick,
  reduce,
  omit,
  isNumber,
} from "lodash";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import TableCard from "../../../components/dashboard/table-card";
import { PurchaseOrderItemPayload } from "../../../models/purchaseOrder";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import CostingJobCategories from "../../../components/costing/costing-categories";
import CreateJobCostingModal from "../../../components/job-costing/create-job-costing-modal";
import {
  CREATE_UPDATE_JOB_COSTING_CATEGORY,
  DELETE_JOB_COSTING_CATEGORY,
  UPDATE_JOB_COSTING_CATEGORY_ORDER,
} from "../../../graphql/queries/job-costing/mutations";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import {
  CreateUpdateJobCostingCategoryResponse,
  DeleteJobCostingCategory,
  ExportJobCostingResponse,
  JobBillOfQuantityPreviewResponse,
  ListJobCostingsResponse,
  UpdateCostingCategoryOrderResponse,
} from "../../../graphql/types/models/job-costing";
import {
  EXPORT_JOB_COSTINGS,
  GET_JOB_BILL_OF_QUANTITY_PREVIEW,
  LIST_JOB_COSTINGS,
} from "../../../graphql/queries/job-costing/queries";
import {
  CreateJobCostingPayload,
  JobCostingCategory,
  JobCostingItem,
  JobCostingTableCategory,
  JobType,
  UpdateJobCostingPayload,
} from "../../../models/job";
import {
  handleJobCostingCategoryAdd,
  handleJobCostingCategoryDelete,
} from "../../../graphql/queries/job-costing/utils";
import UpdateJobCostingModal from "../../../components/job-costing/update-job-costing-modal";
import ConfirmDialog from "../../../components/confirm-dialog";
import JobLocationHeader from "../../header/job-location-header";
import {
  calcGST,
  calcCategorySubTotal,
  calcTotal,
  GST_PERCENT,
  calcWithGST,
} from "../../../utils/calculations";
import { notify } from "../../../components/notification";
import ClientHeaderList from "../../header/client-header-list";
import { MEASUREMENT_OPTIONS } from "../../../utils/options";
import { UOMOption } from "../../../utils/types/options";

import { AssemblyListItem } from "../../../models/assembly";
import VariationModal from "../../../components/variation/variation-modal";
import {
  VariationCostItemPayload,
  VariationPayload,
} from "../../../models/variations";
import { printBase64Pdf } from "../../../utils/pdf";
import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import TeammateHeaderList from "../../header/teammate-header-list";
import ListPurchaseOrdersModal, {
  ListPurchaseOrdersModalRef,
} from "../../../components/job-purchase-order/list-purchase-orders-modal";
import {
  getCostingClaimedAmount,
  getCostingClaimedPercent,
  getPreparedItems,
  getPreparedSelectedItems,
} from "./utils";
import { useClaimCreate } from "./hooks/useClaimCreate";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { useTimesheet } from "./hooks/useTimesheet";
import { useOrderCreate } from "./hooks/useOrderCreate";
import "./styles.scss";
import CheckBox from "../../../components/checkbox";
import { useVariation } from "./hooks/useVariation";
import { useRoster } from "./hooks/useRoster";
import Icon from "../../../components/icons/Icon";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";
import { useQuoteRequest } from "./hooks/useQuoteRequest";
import DashboardActionHeader, {
  ActionButton,
} from "../../../components/dashboard/table-card/DashboardActionHeader";
import { getFormattedComputedQuantity } from "../../../components/costing/utils";
import ToggleInput from "../../../components/generic-form/inputs/toggle";
import { usePrintCostings, PrintType } from "./hooks/usePrintCostings";

export type JobCostingTableItem = JobCostingItem & {
  total: number;
  costingCategoryName?: string;
  claimedPercent?: number;
  claimedAmount?: number;
  claimedProfit?: number;
};

type JobCostingProps = DashboardContextValue & {
  user: UserPayload | null;
};

const JobCosting: React.FC<JobCostingProps> = ({ navigationContext, user }) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const isContractLocked = navigationContext?.job?.isContractLocked || false;
  const isCostPlus = navigationContext?.job?.type === JobType.COST_PLUS;
  const jobId = navigationContext?.job?._id;
  const jobContact = navigationContext?.job?.customer;
  const salesQuoteId = navigationContext?.job?.salesQuote?._id;
  const isSalesQuoteHasFiles =
    navigationContext?.job?.salesQuote?.files?.length !== 0;

  const history = useHistory();
  const { t } = useTranslation();
  const client = useApolloClient();

  const listPurchaseOrderRef = React.useRef<ListPurchaseOrdersModalRef>(null);
  const updateCostingsRef = React.useRef<ModalDisplayRef>(null);
  const [selectedCostingItems, setSelectedCostingItems] = useState<
    PurchaseOrderItemPayload[]
  >([]);

  const [showVariationsModal, setVariationModalVisibility] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [variationToUpdate, setVariationToUpdate] = useState<
    VariationPayload
  >();
  const [totalEstimatedCost, setTotalEstimatedCost] = useState(0.0);
  const [totalMarkup, setTotalMarkup] = useState(0.0);
  const [totalRealCost, setTotalRealCost] = useState(0.0);

  const [selectedCategory, setCategory] = useState<JobCostingCategory | null>(
    null
  );
  const [displayAllowance, setDisplayAllowance] = useState<boolean>(false);
  const [categoryCostIncGST, setCategoryCostIncGST] = useState<boolean>(false);
  const [categoriesView, setCategoriesView] = React.useState(false);

  const resetAllSelections = React.useCallback(() => {
    setSelectedCostingItems([]);
    resetVariationItems();
    resetRosterItems();
    resetClaimItems();
    resetQuoteItems();
    resetOrderItems();
    setCheckAll(false);
  }, []);

  const {
    data: jobCostingData,
    loading: costingLoading,
    refetch: refetchCategory,
  } = useQuery<ListJobCostingsResponse>(LIST_JOB_COSTINGS, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    renderClaimModal,
    toggleClaimItem,
    claimTableAction,
    claimRowAction,
    resetClaimItems,
    isItemSelectedForClaim,
  } = useClaimCreate(t, isCostPlus, resetAllSelections, jobId);

  const {
    renderOrderModal,
    orderTableAction,
    orderRowAction,
    toggleOrderItem,
    resetOrderItems,
    isItemSelectedForOrder,
  } = useOrderCreate({
    t,
    resetAllSelections,
    jobId,
    salesQuoteId,
    isSalesQuoteHasFiles,
  });

  const {
    renderQuoteModal,
    quoteTableAction,
    quoteRowActions,
    toggleQuoteItem,
    resetQuoteItems,
    renderListQuoteRequestModal,
  } = useQuoteRequest(t, resetAllSelections, jobId, refetchCategory);

  const {
    toggleVariationItems,
    resetVariationItems,
    selectedVariationItems,
    renderListVariationsModal,
    variationRowAction,
  } = useVariation(t, jobId);

  const {
    toggleRosterItem,
    resetRosterItems,
    renderRosterModal,
    rosterRowAction,
    rosterTableAction,
  } = useRoster(t, resetAllSelections, jobId);

  const { renderTimesheetModal, timesheetRowActions } = useTimesheet(t, jobId);

  const { downloadFile } = useDownloadFile();
  const { renderDropdown: renderPrintDropdown } = usePrintCostings(
    t,
    displayAllowance ? PrintType.Allowances : PrintType.Costings,
    jobId
  );

  const [createUpdateCostingCategory] = useMutation<
    CreateUpdateJobCostingCategoryResponse
  >(CREATE_UPDATE_JOB_COSTING_CATEGORY, {
    onCompleted: ({ jobCreateUpdateCostingCategory: category }) => {
      setCategory(category);
      setCreateModalVisibility(false);
      updateCostingsRef.current?.show(false);
    },
    update: handleJobCostingCategoryAdd(jobId),
  });

  const [deleteCategory, { loading: costingDeleting }] = useMutation<
    DeleteJobCostingCategory
  >(DELETE_JOB_COSTING_CATEGORY, {
    update: handleJobCostingCategoryDelete(jobId),
  });

  const [updateCostingCategoryOrder] = useMutation<
    UpdateCostingCategoryOrderResponse
  >(UPDATE_JOB_COSTING_CATEGORY_ORDER);

  const closeVariationModal = React.useCallback(() => {
    setVariationModalVisibility(false);
  }, []);

  const tableActions = React.useMemo<TableCardAction[]>(
    () =>
      compact([
        quoteTableAction,
        orderTableAction,
        claimTableAction,
        rosterTableAction,
        {
          title: t(
            selectedVariationItems.length
              ? "costing.variationItemsNumber"
              : "costing.variationItems",
            { number: selectedVariationItems.length }
          ),
          onClick: () => {
            const payload = map(
              getPreparedSelectedItems(selectedVariationItems),
              (item) => {
                return {
                  ...item,
                  quantity: Number(item.quantity),
                  raw_quantity:
                    item?.raw_quantity || item?.quantity?.toString(),
                  rounding: item.rounding,
                  wastage: item.wastage,
                  margin_amount: 0,
                } as PurchaseOrderItemPayload;
              }
            );
            setSelectedCostingItems(payload);
            setVariationModalVisibility(true);
          },
          svgIcon: "Change",
          className: "button info-reverse",
          disabled: !selectedVariationItems.length,
        },
      ]),
    [
      orderTableAction,
      claimTableAction,
      rosterTableAction,
      t,
      selectedVariationItems,
    ]
  );

  const [isCreateModalVisible, setCreateModalVisibility] = useState(false);
  const [showDeleteConfirmation, setDeleteConfirmationVisibility] = useState(
    false
  );

  const handleVariationSubmit = React.useCallback(() => {
    setVariationModalVisibility(false);
    resetAllSelections();
    refetchCategory();
    return null;
  }, [refetchCategory, resetAllSelections]);

  const uoms = React.useMemo(
    () =>
      chain(jobCostingData?.getJobCostings)
        .reduce((items: UOMOption[], next: JobCostingCategory) => {
          return items.concat(
            map(next.items, (costing) => ({
              label: costing.UOM,
              value: costing.UOM,
            }))
          );
        }, MEASUREMENT_OPTIONS)
        .uniqBy("value")
        .value(),
    [jobCostingData?.getJobCostings]
  );

  const [deleteTargetId, setDeleteTarget] = useState("");

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteConfirmationVisibility(false);
    setDeleteTarget("");
  }, []);

  const getCostingItemById = React.useCallback(
    (itemId): JobCostingItem | undefined => {
      if (!itemId) return;

      const item = selectedCategory?.items.find((itm) => {
        if (itm._id?.toString() === itemId.toString()) return itm;
      });
      return item;
    },
    [selectedCategory]
  );

  const openDeleteDialog = React.useCallback((id: string) => {
    setDeleteConfirmationVisibility(true);
    setDeleteTarget(id);
  }, []);

  const closeCostingModal = React.useCallback(() => {
    setCreateModalVisibility(false);
  }, []);

  const openCostingModal = React.useCallback(() => {
    setCreateModalVisibility(true);
  }, []);

  const handleSubmit = React.useCallback(
    async (
      values: CreateJobCostingPayload | UpdateJobCostingPayload,
      assemblies?: AssemblyListItem[]
    ) => {
      const prepareItems = getPreparedItems(values.items, assemblies);
      await createUpdateCostingCategory({
        variables: {
          jobId,
          category: {
            ...pick(values, ["_id", "name", "can_timesheet"]),
            items: prepareItems,
          },
        },
      });
    },
    [jobId, createUpdateCostingCategory]
  );

  React.useEffect(() => {
    const data = jobCostingData?.getJobCostings;
    const category =
      find(data, { _id: selectedCategory?._id }) ||
      head(jobCostingData?.getJobCostings);

    let totalEstCost = 0.0,
      totalRCost = 0.0,
      totalMarkupCost = 0.0;
    if (head(jobCostingData?.getJobCostings)) {
      jobCostingData?.getJobCostings.forEach((item) => {
        totalRCost += parseFloat(`${item.totalRealCost}`);
        totalEstCost += parseFloat(`${item.totalEstimatedCost}`);
        totalMarkupCost += parseFloat(`${item.totalMarkup}`);
      });
    }

    setTotalEstimatedCost(totalEstCost);
    setTotalRealCost(totalRCost);
    setTotalMarkup(totalMarkupCost);

    if (!isEqual(category, selectedCategory)) {
      setCategory(category || null);
    }
  }, [jobCostingData, setTotalEstimatedCost, setTotalRealCost, setTotalMarkup]);

  const handleCategorySelect = React.useCallback((category) => {
    setCategory(category);
    setCheckAll(false);
  }, []);

  const handleCategoryDelete = React.useCallback(async () => {
    if (!deleteTargetId) {
      return;
    }

    try {
      await deleteCategory({
        variables: {
          jobId,
          categoryId: deleteTargetId,
        },
      });

      notify({
        title: t("costing.deleteCosting"),
        content: t("costing.success.deleteCosting"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("costing.deleteCosting"),
        content: t("costing.errors.deleteCosting"),
      });
    }

    setDeleteConfirmationVisibility(false);
    setDeleteTarget("");
  }, [deleteTargetId, deleteCategory, jobId, t]);

  const allowanceCategories = React.useMemo(() => {
    return chain(jobCostingData?.getJobCostings)
      .map((category, i) => {
        const items = filter(category.items, { is_allowance: true });
        if (items.length) {
          return {
            ...category,
            items,
          };
        }
        return null;
      })
      .compact()
      .value();
  }, [jobCostingData]);

  const categoriesData = React.useMemo<
    TableCardData<JobCostingTableCategory>
  >(() => {
    const columns = compact([
      {
        valueKey: "name",
        title: t("common.category"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "totalEstimatedCost",
        title: categoryCostIncGST
          ? t("costing.budgetTotalInc")
          : t("costing.budgetTotalEx"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.currency", { amount: value }),
      },
      {
        valueKey: "clientTotal",
        title: categoryCostIncGST
          ? t("costing.clientTotalInc")
          : t("costing.clientTotalEx"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.currency", { amount: value }),
      },
      {
        valueKey: "totalRealCost",
        title: categoryCostIncGST
          ? t("costing.realCostInc")
          : t("costing.realCost"),
        formatValue: (row: any, column: any, value: string) =>
          (value || Number(value) === 0) &&
          t("common.currency", { amount: value }),
      },
      !isCostPlus && {
        valueKey: "diff",
        title: categoryCostIncGST ? t("costing.diffInc") : t("costing.diff"),
        formatValue: (row: any, column: any, value: string) => (
          <span
            className={
              parseFloat(value) > 0
                ? "badge badge-success"
                : parseFloat(value) < 0
                ? "badge badge-danger"
                : ""
            }
          >
            {value !== undefined ? t("common.currency", { amount: value }) : ""}
          </span>
        ),
      },
      {
        valueKey: "claimedAmount",
        title: categoryCostIncGST
          ? t("costing.claimedAmountInc")
          : t("costing.claimedAmount"),
        formatValue: (row: any, column: any, value: string) =>
          value !== undefined ? t("common.currency", { amount: value }) : "",
      },
      !isCostPlus && {
        valueKey: "claimedPercent",
        title: t("costing.claimedPercent"),
        formatValue: (row: any, column: any, value: string) =>
          (value || Number(value) === 0) &&
          t("claims.claimedPercent", { percentage: value }),
      },
    ]);

    const rows = map(
      displayAllowance ? allowanceCategories : jobCostingData?.getJobCostings,
      (category) => {
        const {
          claimedAmount,
          budgetAmount,
          trueCost,
          clientTotal,
        } = category.items.reduce(
          (accum, item) => {
            const claimItem = getCostingClaimedAmount(item);
            let claimedAmount = claimItem.claimedAmount;
            let budgetAmount = Number(item?.cost * item.quantity || 0);
            let trueCost = Number(item?.real_cost || 0);
            let clientTotal = item?.markup_cost
              ? item?.markup_cost * item.quantity
              : 0;
            if (categoryCostIncGST && item.hasGST) {
              claimedAmount = calcWithGST(claimedAmount, GST_PERCENT);
              budgetAmount = calcWithGST(budgetAmount, GST_PERCENT);
              trueCost = calcWithGST(trueCost, GST_PERCENT);
              clientTotal = calcWithGST(clientTotal, GST_PERCENT);
            }
            return {
              claimedAmount: accum.claimedAmount + claimedAmount,
              budgetAmount: accum.budgetAmount + budgetAmount,
              trueCost: accum.trueCost + trueCost,
              clientTotal: accum.clientTotal + clientTotal,
            };
          },
          {
            claimedAmount: 0,
            budgetAmount: 0,
            trueCost: 0,
            clientTotal: 0,
          }
        );

        const claimedPercent = category?.totalEstimatedCost
          ? (claimedAmount / clientTotal) * 100
          : 0;

        return {
          cells: {
            ...category,
            totalEstimatedCost: budgetAmount,
            claimedAmount: claimedAmount,
            clientTotal: clientTotal,
            totalRealCost: trueCost,
            diff: Number(budgetAmount || 0) - Number(trueCost || 0),
            claimedPercent: !isCostPlus ? claimedPercent : 0,
          },
          ...(displayAllowance && {
            subRows: map(category.items, (item) => {
              let clientTotal = item?.markup_cost
                ? item?.markup_cost * item.quantity
                : 0;
              let claimedAmount = getCostingClaimedAmount(item).claimedAmount;
              let budgetAmount = item.cost * item.quantity;
              let trueCost = item.real_cost;
              if (categoryCostIncGST && item.hasGST) {
                clientTotal = calcWithGST(clientTotal, GST_PERCENT);
                claimedAmount = calcWithGST(claimedAmount, GST_PERCENT);
                budgetAmount = calcWithGST(budgetAmount, GST_PERCENT);
                trueCost = calcWithGST(trueCost, GST_PERCENT);
              }
              const claimedPercent = budgetAmount
                ? (claimedAmount / clientTotal) * 100
                : 0;
              return {
                cells: {
                  ...omit(item, ["_id"]),
                  totalEstimatedCost: budgetAmount,
                  claimedAmount,
                  totalRealCost: trueCost,
                  clientTotal: clientTotal,
                  claimedPercent: !isCostPlus ? claimedPercent : 0,
                  diff: (clientTotal || 0) - (trueCost || 0),
                } as any,
              } as any;
            }) as TableCardDataRow<JobCostingTableItem>[],
          }),
        };
      }
    ) as TableCardDataRow<JobCostingTableCategory>[];

    if (rows.length) {
      rows.push({
        isTotal: true,
        cells: reduce(
          rows,
          (result: JobCostingTableCategory, row) => {
            result["totalEstimatedCost"] += row?.cells?.totalEstimatedCost || 0;
            result["totalRealCost"] += row.cells?.totalRealCost || 0;
            result["clientTotal"] += row.cells?.clientTotal || 0;
            result["diff"] += row.cells?.diff;
            result["claimedAmount"] += Number(row.cells?.claimedAmount || 0);
            return result;
          },
          {
            totalEstimatedCost: 0,
            diff: 0,
            totalRealCost: 0,
            claimedAmount: 0,
            clientTotal: 0,
          } as JobCostingTableCategory
        ),
      } as TableCardDataRow<JobCostingTableCategory>);
    }
    return {
      columns,
      rows,
    } as TableCardData<JobCostingTableCategory>;
  }, [
    t,
    isCostPlus,
    selectedCategory,
    displayAllowance,
    allowanceCategories,
    categoryCostIncGST,
  ]);

  const categoryData = React.useMemo<TableCardData<JobCostingTableItem>>(() => {
    const columns = compact([
      {
        valueKey: "name",
        title: t("costing.description"),
        formatValue: (row: any, column: any, value: string) => {
          if (row.cells.items?.length) {
            return (
              <>
                <div
                  className="d-flex align-items-center"
                  title={t("costing.assemblyItem")}
                >
                  <Icon name="list_alt" outlined />
                  {value}
                </div>
              </>
            );
          }
          return value;
        },
      },
      {
        valueKey: "quantity",
        title: t("costing.quantity"),
        formatValue: (row: any, column: any, value: string) =>
          !row.isTotal
            ? isNumber(value)
              ? t("common.quantity", { amount: value })
              : value
            : "",
      },
      {
        valueKey: "UOM",
        title: t("costing.uom"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "cost",
        title: t("costing.cost"),
        formatValue: (row: any, column: any, value: string) =>
          !row.isTotal ? t("common.currency", { amount: value }) : "",
      },
      {
        valueKey: "total",
        title: t("costing.totalEx"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.currency", { amount: value }),
      },
      {
        valueKey: "real_cost",
        title: t("costing.realCost"),
        formatValue: (row: any, column: any, value: string) =>
          value !== undefined ? t("common.currency", { amount: value }) : "",
      },
      !isCostPlus && {
        valueKey: "diff",
        title: t("costing.diff"),
        formatValue: (row: any, column: any, value: string) => (
          <span
            className={
              parseFloat(value) > 0
                ? "badge badge-success"
                : parseFloat(value) < 0
                ? "badge badge-danger"
                : ""
            }
          >
            {value !== undefined ? t("common.currency", { amount: value }) : ""}
          </span>
        ),
      },
      !isCostPlus && {
        valueKey: "claimedPercent",
        title: t("costing.claimedPercent"),
        formatValue: (row: any, column: any, value: string) =>
          value ? t("claims.claimedPercent", { percentage: value }) : "",
      },
      isCostPlus && {
        valueKey: "claimedAmount",
        title: t("costing.claimedAmount"),
        formatValue: (row: any, column: any, value: string) =>
          value !== undefined ? t("common.currency", { amount: value }) : "",
      },
      isCostPlus && {
        valueKey: "claimedProfit",
        title: t("jobOverview.grossProfit"),
        formatValue: (row: any, column: any, value: string) =>
          value !== undefined ? (
            <span
              className={
                parseFloat(value) > 0
                  ? "badge badge-success"
                  : parseFloat(value) < 0
                  ? "badge badge-danger"
                  : ""
              }
            >
              {t("common.currency", { amount: value })}
            </span>
          ) : (
            ""
          ),
      },
    ]);

    const rows = map(selectedCategory?.items, (costing) => ({
      cells: {
        ...costing,
        total: costing.cost * costing.quantity,
        diff: costing.real_cost
          ? costing.cost * costing.quantity - costing.real_cost
          : 0,
        claimedPercent: !isCostPlus ? getCostingClaimedPercent(costing) : 0,
        ...(isCostPlus ? getCostingClaimedAmount(costing) : {}),
      },
      subRows: map(costing.items, (assemblyItem) => ({
        cells: {
          ...omit(assemblyItem, ["_id"]),
          total: assemblyItem.cost * (assemblyItem?.computed_quantity || 0),
          quantity: getFormattedComputedQuantity(
            t,
            assemblyItem?.computed_quantity || 0,
            assemblyItem.quantity as number,
            costing.quantity as number
          ),
        } as any,
      })) as TableCardDataRow<JobCostingTableItem>[],
    })) as TableCardDataRow<JobCostingTableItem>[];

    if (rows.length) {
      rows.push({
        isTotal: true,
        cells: reduce(
          rows,
          (result: JobCostingTableItem, row) => {
            result["total"] += row.cells.total;
            result["real_cost"] += row.cells.real_cost;
            result["claimedAmount"] =
              (result["claimedAmount"] || 0) +
              Number(row.cells.claimedAmount || 0);
            result["claimedProfit"] =
              (result["claimedProfit"] || 0) +
              Number(row.cells.claimedProfit || 0);
            return result;
          },
          {
            name: "",
            quantity: 0,
            cost: 0,
            UOM: "",
            total: 0,
            real_cost: 0,
            claimedPercent: 0,
            claimedAmount: 0,
            claimedProfit: 0,
            note: "",
            is_allowance: false,
          } as JobCostingTableItem
        ),
      } as TableCardDataRow<JobCostingTableItem>);
    }
    return {
      columns,
      rows,
    } as TableCardData<JobCostingTableItem>;
  }, [t, isCostPlus, selectedCategory]);

  const headerAction = React.useMemo<TableCardAction>(
    () => ({
      className: "button large info bg-transparent text-light",
      icon: "edit",
      disabled: !selectedCategory,
      onClick: () => updateCostingsRef.current?.show(true),
      title: t("common.edit"),
    }),
    [t, selectedCategory, updateCostingsRef]
  );

  const deletingCategoryTitle = React.useMemo(() => {
    const category = find(jobCostingData?.getJobCostings, {
      _id: deleteTargetId,
    });

    return category?.name;
  }, [jobCostingData, deleteTargetId]);

  const subTotal = React.useMemo(() => {
    if (!jobCostingData?.getJobCostings) {
      return 0;
    }

    return calcCategorySubTotal(jobCostingData?.getJobCostings);
  }, [jobCostingData]);

  const GST = React.useMemo(() => {
    return calcGST(subTotal, GST_PERCENT);
  }, [subTotal]);

  const total = React.useMemo(() => {
    return calcTotal(subTotal, GST);
  }, [subTotal, GST]);

  const toggleSelectedItem = React.useCallback(
    (row: JobCostingTableItem) => {
      if (!row?._id) return;

      const item = getCostingItemById(row._id);
      const newItem = row;
      newItem.costingCategoryName = selectedCategory?.name;

      // allow multiple POs
      toggleOrderItem(row);
      toggleClaimItem(row);
      toggleQuoteItem(row);
      toggleRosterItem(row);

      if (!item?.variation?._id) {
        toggleVariationItems(row, selectedCategory);
      }
    },
    [
      getCostingItemById,
      selectedCategory,
      toggleOrderItem,
      toggleClaimItem,
      toggleVariationItems,
      toggleRosterItem,
    ]
  );

  const toggleSelectedItems = React.useCallback(
    (rows: JobCostingTableItem[]) => {
      if (!rows.length) return;

      toggleOrderItem(rows);
      toggleClaimItem(rows);
      toggleQuoteItem(rows);
      toggleVariationItems(rows, selectedCategory);
      toggleRosterItem(rows);
    },
    [
      toggleOrderItem,
      toggleClaimItem,
      toggleVariationItems,
      selectedCategory,
      toggleRosterItem,
    ]
  );

  const rowActions: TableRowActionData<JobCostingTableItem>[] = React.useMemo(
    () => [
      {
        icon: "money_off",
        dropdownId: "gstFree",
        title: t("costing.gstFree"),
        onClick: () => {},
        shouldRender: (row) => row._id && !row.hasGST,
      },
      {
        icon: "font_download",
        dropdownId: "allowance",
        title: t("costing.allowance"),
        onClick: () => {},
        shouldRender: (row) => row.is_allowance,
      },
      ...quoteRowActions,
      {
        icon: "comment",
        dropdownId: "note",
        onClick: () => {},
        shouldRender: (row) => !!row.note,
        tooltip: (row) => {
          return row.note;
        },
      },
      orderRowAction,
      rosterRowAction,
      variationRowAction,

      claimRowAction,
      timesheetRowActions,
    ],
    [
      t,
      quoteRowActions,
      orderRowAction,
      rosterRowAction,
      variationRowAction,
      claimRowAction,
      timesheetRowActions,
    ]
  );

  const leftRowActions: TableRowActionData<
    JobCostingTableItem
  >[] = React.useMemo(
    () => [
      {
        icon: "check_box",
        dropdownId: "select-item",
        onClick: toggleSelectedItem,
        shouldRender: (row) => {
          if (!row?._id) return false;
          const hasSelectedVariation =
            findIndex(selectedVariationItems, { _id: row._id }) >= 0;
          const hasSelectedOrder = isItemSelectedForOrder(row._id);
          const hasSelectedClaim = isItemSelectedForClaim(row._id);

          return hasSelectedOrder || hasSelectedVariation || hasSelectedClaim;
        },
      },
      {
        icon: "check_box_outline_blank",
        dropdownId: "select-item",
        onClick: toggleSelectedItem,
        shouldRender: (row) => {
          if (!row?._id) return false;
          const hasSelectedVariation =
            findIndex(selectedVariationItems, { _id: row._id }) >= 0;
          const hasSelectedClaim = isItemSelectedForClaim(row._id);
          const hasSelectedOrder = isItemSelectedForOrder(row._id);

          return (
            !hasSelectedOrder && !hasSelectedVariation && !hasSelectedClaim
          );
        },
      },
    ],
    [
      selectedVariationItems,
      toggleSelectedItem,
      isItemSelectedForOrder,
      isItemSelectedForClaim,
    ]
  );

  const handleCheckAll = React.useCallback(
    (value: boolean) => {
      if (value) {
        const rows = categoryData.rows
          .map((row) => row.cells)
          .filter((c) => !!c._id);

        toggleSelectedItems(rows);
      } else {
        resetAllSelections();
      }
      setCheckAll(value);
    },
    [categoryData, toggleSelectedItems, resetAllSelections]
  );

  const headerTableLeftRowActions = React.useCallback(() => {
    return <CheckBox value={checkAll} onClick={handleCheckAll} />;
  }, [checkAll, handleCheckAll]);

  const handlePrintBillOfQuantity = React.useCallback(async () => {
    try {
      const pdfPreview = await client.query<JobBillOfQuantityPreviewResponse>({
        query: GET_JOB_BILL_OF_QUANTITY_PREVIEW,
        variables: {
          jobId,
        },
        fetchPolicy: "network-only",
      });

      printBase64Pdf(pdfPreview?.data?.getJobBillOfQuantityPreview.pdf);
    } catch (e) {}
  }, [jobId, client]);

  const handleDownloadCostings = React.useCallback(async () => {
    try {
      const result = await client.query<ExportJobCostingResponse>({
        query: EXPORT_JOB_COSTINGS,
        variables: {
          jobId,
        },
        fetchPolicy: "network-only",
      });
      const exportData = result?.data?.exportJobCostings;
      downloadFile(exportData.data, exportData.filename);
    } catch (e) {}
  }, [jobId, client]);

  const onImportCostings = React.useCallback(() => {
    history.push(`/jobs/${jobId}/costings/import`);
  }, [jobId, history]);

  const handleSort = React.useCallback(
    (categories: Pick<JobCostingCategory, "_id" | "name">[]) => {
      updateCostingCategoryOrder({
        variables: {
          jobId,
          categories,
        },
      });
    },
    [jobId]
  );

  const categories = React.useMemo(() => {
    return displayAllowance
      ? allowanceCategories
      : jobCostingData?.getJobCostings;
  }, [jobCostingData, allowanceCategories, displayAllowance]);

  const handleFilterChange = React.useCallback(
    (isAllowance: boolean) => {
      setDisplayAllowance(isAllowance);
      if (isAllowance && allowanceCategories.length) {
        setCategory(allowanceCategories[0]);
      } else if (jobCostingData?.getJobCostings?.length) {
        setCategory(jobCostingData?.getJobCostings[0]);
      }
    },
    [jobCostingData, setDisplayAllowance, allowanceCategories]
  );

  const preparedSelectedItem = React.useMemo(() => {
    if (!selectedCategory) return null;

    const preparedItems = map(selectedCategory.items, (item) => {
      const assemblyId = item.type === "assembly" ? uniqueId("assembly:") : "";
      return {
        ...item,
        assemblyId,
      };
    });

    return {
      ...selectedCategory,
      items: preparedItems,
    };
  }, [selectedCategory]);

  const {
    filteredArray,
    filter: inputFilter,
    setFilter: setSearchByInput,
  } = useFilteredArrayMultipleField(categories || [], ["name"], true);

  const handleToggleCategoriesView = React.useCallback(() => {
    if (displayAllowance) {
      setDisplayAllowance(false);
    }
    setCategoriesView(!categoriesView);
  }, [categoriesView, displayAllowance]);

  const handleToggleAllowancesView = React.useCallback(() => {
    if (categoriesView) {
      setCategoriesView(false);
    }
    handleFilterChange(!displayAllowance);
  }, [categoriesView, displayAllowance, handleFilterChange]);

  const toggleViewButtonAction = React.useMemo<ActionButton[]>(
    () => [
      {
        className: classNames("toggle-view allowances-view", {
          "active-view": displayAllowance,
        }),
        icon: "font_download",
        disabled:
          costingLoading ||
          isEmpty(jobCostingData?.getJobCostings) ||
          !Boolean(allowanceCategories.length),
        onClick: handleToggleAllowancesView,
        titleHint: t("costing.allowances"),
      },
      {
        className: classNames("toggle-view mr-2", {
          "active-view": categoriesView,
        }),
        icon: categoriesView ? "view_list" : "table_rows",
        disabled: costingLoading || isEmpty(jobCostingData?.getJobCostings),
        onClick: handleToggleCategoriesView,
        titleHint: t("costing.allCategories"),
      },
    ],
    [categoriesView, costingLoading, jobCostingData, displayAllowance]
  );
  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<JobCostingTableCategory>) => {
      const category = find(jobCostingData?.getJobCostings, {
        _id: row?.cells._id,
      });
      if (category) {
        setCategory(category);
        setCategoriesView(false);
      }
    },
    [jobCostingData?.getJobCostings]
  );

  const sectionTitle = React.useMemo(() => {
    if (displayAllowance) return t("costing.allowances");
    if (categoriesView) return t("common.categories");
    return t("common.overview");
  }, [categoriesView, displayAllowance, t]);

  return (
    <Container fluid className="m-0 p-0 h-100 job-costing-container">
      <Helmet title={t("navigation.jobsSection.costing")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.COSTING} />
      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      <VariationModal
        editMode={false}
        variation={variationToUpdate}
        contact={jobContact}
        show={showVariationsModal}
        onClose={closeVariationModal}
        onSubmit={handleVariationSubmit}
        jobId={jobId}
        costingItems={selectedCostingItems as VariationCostItemPayload[]}
      />

      <CreateJobCostingModal
        show={isCreateModalVisible}
        onSubmit={handleSubmit}
        onClose={closeCostingModal}
        uoms={uoms}
        isRestrictedEntry={isContractLocked}
      />

      <ListPurchaseOrdersModal ref={listPurchaseOrderRef} />

      {renderQuoteModal()}
      {renderClaimModal()}
      {renderOrderModal()}
      {renderTimesheetModal()}
      {renderRosterModal()}
      {renderListVariationsModal()}
      {renderListQuoteRequestModal()}

      {selectedCategory && preparedSelectedItem && (
        <UpdateJobCostingModal
          ref={updateCostingsRef}
          onSubmit={handleSubmit}
          data={preparedSelectedItem}
          title={selectedCategory.name}
          uoms={uoms}
        />
      )}
      <ConfirmDialog
        disabled={costingDeleting}
        title={deletingCategoryTitle}
        onSubmit={handleCategoryDelete}
        show={showDeleteConfirmation}
        onClose={closeDeleteDialog}
      >
        <span className="field-text">
          {t("costing.deleteCostingCategoryMessage")}
        </span>
      </ConfirmDialog>

      <Row className="h-100 align-content-start justify-content-center">
        <Row className="w-100">
          <Col>
            <div className="d-flex justify-content-between align-items-center">
              <div className="section-title">{sectionTitle}</div>
              <div className="d-flex">
                <DashboardActionHeader
                  shouldRenderActionButton={false}
                  additionalActionButtons={toggleViewButtonAction}
                />
                {user?.role === UserRoles.builderadmin
                  ? renderPrintDropdown()
                  : null}
                <Button
                  key={"onAddCard"}
                  className="button large success"
                  onClick={openCostingModal}
                >
                  <Icon name="add" />
                  {t("costing.addCosting")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="w-100">
          {categoriesView || displayAllowance ? (
            <Col className="job-costing-items">
              <ToggleInput
                name="costIncGST"
                label={t("costing.showCostsIncGst")}
                value={categoryCostIncGST}
                onChange={setCategoryCostIncGST}
              />
              <TableCard
                isDataLoading={isEmpty(categories) || costingLoading}
                light
                rowCount
                data={categoriesData}
                headerTableLeftRowActions={headerTableLeftRowActions}
                onRowClick={handleRowClick}
                className={"category-table"}
              />
            </Col>
          ) : (
            <>
              <Col lg={3} md={4} xs={12}>
                <CostingJobCategories
                  sortable={!isLocked}
                  total={total}
                  totalEstimatedCost={totalEstimatedCost}
                  totalRealCost={totalRealCost}
                  totalMarkup={totalMarkup}
                  subtotal={subTotal}
                  gst={GST}
                  disabled={isLocked || displayAllowance}
                  displayCostingTotals={!displayAllowance}
                  categories={filteredArray}
                  selectedCategory={selectedCategory}
                  onDelete={openDeleteDialog}
                  onCategorySelect={handleCategorySelect}
                  onPrintBillOfQuantity={handlePrintBillOfQuantity}
                  onDownloadCostings={handleDownloadCostings}
                  onImportCostings={onImportCostings}
                  jobId={jobId}
                  allowanceFilter={!!allowanceCategories.length}
                  onFilterChange={handleFilterChange}
                  onSort={handleSort}
                  searchOptions={{
                    value: inputFilter,
                    onChange: setSearchByInput,
                  }}
                  placeholder={t("costing.emptySearchPlaceholder", {
                    filter: inputFilter,
                  })}
                />
              </Col>
              <Col lg={9} md={8} xs={12} className="job-costing-items">
                <TableCard
                  isDataLoading={isEmpty(categories) || costingLoading}
                  headerAction={headerAction}
                  title={selectedCategory?.name}
                  titleBadge={
                    selectedCategory?.can_timesheet
                      ? t("costing.allowedTimesheetAllocation")
                      : ""
                  }
                  rowCount
                  compact
                  data={categoryData}
                  rowActions={rowActions}
                  leftRowActions={leftRowActions}
                  headerTableLeftRowActions={headerTableLeftRowActions}
                  actions={tableActions}
                />
              </Col>
            </>
          )}
        </Row>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(JobCosting));
