import React, { forwardRef } from "react";
import CreateEntityModal from "../../modals/create-entity";
import { useTranslation } from "react-i18next";
import {
  ImportScheduleFromTemplatePayload,
  ScheduleTemplate,
} from "../../../models/job";
import {
  AppendDynamicProps,
  FormikPropGetSetValues,
  GenericFormFields,
} from "../../generic-form/GenericFormBody";
import importScheduleFromTemplateSchema from "./ImportScheduleFromTemplate.schema";
import { useScheduleTemplateQuery } from "../../../hooks/queries/useScheduleTemplateQuery";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { importScheduleFromTemplateFields } from "./utils";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";
import { find } from "lodash";

type ImportScheduleFromTemplateModalProps = {
  jobId?: string;
};

const ImportScheduleFromTemplateModal: React.FC<ImportScheduleFromTemplateModalProps> = (
  { jobId },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const deleteRef = React.useRef<ConfirmDialogRef>();

  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<ImportScheduleFromTemplatePayload>
  >({});
  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = React.useState<ScheduleTemplate | null>(null);

  const {
    scheduleTemplates,
    importScheduleFromTemplate,
    deleteScheduleTemplate,
  } = useScheduleTemplateQuery({
    jobId,
    onImport: hide,
  });

  const handleSelectTemplate = React.useCallback(
    (templateId: string | string[]) => {
      const template = find(scheduleTemplates, { _id: templateId as string });
      if (!template) return;
      setSelectedTemplate(template);
    },
    [scheduleTemplates]
  );

  const handleDeleteConfirm = React.useCallback(
    (formikProps: FormikPropGetSetValues) => () => {
      if (!selectedTemplate) return;
      deleteScheduleTemplate({
        variables: {
          scheduleTemplateId: selectedTemplate?._id,
        },
      }).then(() => {
        formikProps.setFieldValue("scheduleTemplateId", null);
        setSelectedTemplate(null);
      });
    },
    [selectedTemplate, deleteScheduleTemplate]
  );

  const handleDeleteTemplate = React.useCallback(
    (_fieldName, _fieldValue, _rowIndex, formikProps) => {
      deleteRef.current?.show(true, handleDeleteConfirm(formikProps));
    },
    [deleteRef, handleDeleteConfirm]
  );

  const getTemplateAppendProps = React.useCallback((): AppendDynamicProps => {
    if (!selectedTemplate)
      return {
        isHidden: true,
      };
    return {
      icon: "delete",
    };
  }, [selectedTemplate]);

  React.useEffect(() => {
    if (!scheduleTemplates) return;
    setFormFields(
      importScheduleFromTemplateFields(
        t,
        scheduleTemplates,
        getTemplateAppendProps,
        handleSelectTemplate,
        handleDeleteTemplate
      )
    );
  }, [
    scheduleTemplates,
    getTemplateAppendProps,
    handleSelectTemplate,
    handleDeleteTemplate,
  ]);

  const handleSubmit = React.useCallback(
    (values: ImportScheduleFromTemplatePayload) => {
      importScheduleFromTemplate({
        variables: values,
      });
    },
    []
  );

  return (
    <>
      <CreateEntityModal
        validationSchema={importScheduleFromTemplateSchema(t)}
        className="import-schedule-template"
        title={t("schedule.importFromTemplate")}
        show={shouldShow}
        data={{
          jobId: jobId || "",
          scheduleTemplateId: "",
          date: "",
        }}
        submitText={t("common.import")}
        onSubmit={handleSubmit}
        onClose={hide}
        fields={formFields}
      />
      <ConfirmDialog
        ref={deleteRef}
        title={t("schedule.deleteTemplate")}
        confirm={t("common.delete")}
      >
        <span className="field-text">
          {t("schedule.deleteTemplateMessage", {
            name: selectedTemplate?.name,
          })}
        </span>
      </ConfirmDialog>
    </>
  );
};

export default forwardRef(ImportScheduleFromTemplateModal);
