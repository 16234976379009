import React from "react";
import { ValueContainerProps } from "react-select";

const HeaderSearchValueContainer: React.FC<ValueContainerProps<any>> = ({
  children,
}) => {
  return (
    <div className="search-value-container">
      <i className="material-icons">search</i>
      {children}
    </div>
  );
};

export default HeaderSearchValueContainer;
