import { gql } from "@apollo/client";
import { JOB_CONTACT } from "./fragments";

export const ADD_CONTACT_TO_JOB = gql`
  mutation AddContactToJob($jobId: ID!, $contactId: ID!) {
    addedContact: addContactToJob(jobId: $jobId, contactId: $contactId) {
      ...JobContact
    }
  }
  ${JOB_CONTACT}
`;

export const DELETE_JOB_CONTACT = gql`
  mutation RemoveContactFromJob($jobId: ID!, $jobContactId: ID!) {
    removedContact: removeContactFromJob(
      jobId: $jobId
      jobContactId: $jobContactId
    ) {
      _id
    }
  }
`;
