import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const saveSelectionAsTemplateFields = (
  t: TFunction
): GenericFormFields<{ name: string; description: string }> => {
  return {
    name: {
      type: "text",
      label: t("selections.templateName"),
      placeholder: t("selections.templateName"),
      valueKey: "name",
      showError: true,
      inputProps: {},
    },
    description: {
      type: "text",
      controlType: "textarea",
      label: t("common.description"),
      placeholder: t("common.description"),
      valueKey: "description",
    },
  };
};
