import { TFunction } from "i18next";
import { map } from "lodash";
import { CreateTodoListPayload } from "../../../models/task";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { TeamMember } from "../../../models/team-member";
import { JobListItem } from "../../../models/job";

export const createTaskField = (
  t: TFunction,
  canAssign: boolean,
  team: TeamMember[],
  jobs: JobListItem[],
  hasLeadId?: boolean
): GenericFormFields<CreateTodoListPayload> => {
  const teamOptions = map(team, (member) => ({
    value: member._id,
    label: [member.first_name, member.last_name].join(" "),
  }));

  const jobOptions = map(jobs, (job) => ({
    value: job._id,
    label: job.name,
  }));

  const fields: GenericFormFields<CreateTodoListPayload> = {
    task: {
      type: "text",
      label: t("tasks.task"),
      placeholder: t("tasks.task"),
      valueKey: "task",
      inputProps: {
        required: true,
      },
    },
    description: {
      type: "text",
      controlType: "textarea",
      label: t("tasks.description"),
      placeholder: t("tasks.description"),
      valueKey: "description",
      inputProps: {
        required: true,
      },
    },
    dueDate: {
      type: "date-picker",
      label: t("tasks.dueDate"),
      placeholder: t("tasks.dueDate"),
      valueKey: "dueDate",
      inputProps: {
        required: true,
      },
    },
    members: {
      type: "creatable-select",
      label: t("common.assignTo"),
      placeholder: t("common.assignTo"),
      valueKey: "members",
      creatableProps: {
        autoSelectCreatedOption: true,
        isMulti: true,
        options: teamOptions,
      },
      inputProps: {
        required: true,
      },
    },
    ...(!hasLeadId && {
      job: {
        type: "select",
        label: t("jobs.job"),
        placeholder: t("jobs.job"),
        valueKey: "jobId",
        autocompleteProps: {
          isClearable: true,
          options: jobOptions,
        },
      },
    }),
  };
  if (!canAssign) delete fields.user;

  return fields;
};
