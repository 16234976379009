import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import HomeContainer from "../home";
import ScheduleContainer from "../schedule";
import DashboardLayout from "../layouts/dashboard";
import CalendarContainer from "../calendar";
import QuoteContainer from "../quotes/quote-container";
import QuotesListContainer from "../quotes/quotes-list";
import Jobs from "../jobs/jobs";
import JobsContainer from "../jobs/jobs-container";
import JobsWorkInProgress from "../jobs/work-in-progress";
import ProfileContainer from "../profile/profile-container";
import CommunicationContainer from "../communication/communication-container";
import TimesheetsContainer from "../timesheets/timesheets-container";
import ContactsContainer from "../contacts/contacts-container";
import DocumentsContainer from "../documents/documents-container";
import SettingsContainer from "../settings/settings-container";
import QuoteRequestOverviewContainer from "../quote-request/quote-request-container";
import QuoteRequestContainer from "../quote-request/quote-request-details";
import TasksContainer from "../tasks/tasks-container";
import SiteDiaryContainer from "../site-diary";
import { RootReducerState } from "../../redux/reducer";
import {
  getUser,
  getUserFailed,
  getUserSuccess,
} from "../../redux/authentication/actions";
import {
  UserPayload,
  UserProfileResponse,
} from "../../graphql/types/models/auth";
import { useLazyQuery } from "@apollo/client";
import { PROFILE } from "../../graphql/queries/auth/queries";
import { JobStatus } from "../../models/job";
import { UserRoles } from "../../models/team-member";
import LeadsContainer from "../leads/leads-container";
import GlobalSearchContainer from "../jobs/job-global-search";
import { QuoteArchiveStatus } from "../../models/salesQuote";
import MessageNotificationLayout from "../layouts/message-notifications";
import JobChecklists from "../jobs/job-checklists";
import QuoteChecklists from "../quotes/quote-checklists";

export type DashboardContainerProps = {
  getUser: () => void;
  getUserSuccess: (
    user: UserPayload,
    isSubcontractor: boolean,
    isAdmin: boolean,
    isClient: boolean
  ) => void;
  getUserFailed: (error: string) => void;
  user: UserPayload | null;
  isAuthenticated: boolean;
};

const DashboardContainer: React.FC<DashboardContainerProps> = ({
  getUser,
  getUserFailed,
  getUserSuccess,
  isAuthenticated,
  user,
}) => {
  const [getProfile] = useLazyQuery<UserProfileResponse>(PROFILE, {
    onCompleted: ({ profile: user }) => {
      getUserSuccess(user, false, false, false);
      if (process.env.NODE_ENV === "development") return;
    },
    onError: (error) => {
      getUserFailed(error.message);
    },
  });

  React.useEffect(() => {
    if (user && isAuthenticated) {
      return;
    }
    getUser();
    getProfile();
  }, [user, isAuthenticated, getProfile, getUser]);

  const basicRoutes = React.useMemo(
    () => [
      <Route exact path="/" component={HomeContainer} />,
      <Route exact path="/schedule" component={ScheduleContainer} />,
      <Route exact path="/calendar" component={CalendarContainer} />,
      <Route path="/tasks" render={() => <TasksContainer user={user} />} />,
      <Route exact path="/site-diaries" component={SiteDiaryContainer} />,
      <Route
        exact
        path="/jobs"
        render={() => <Jobs jobStatus={JobStatus.ACTIVE} />}
      />,
      <Route
        exact
        path="/jobs/completed"
        render={() => <Jobs jobStatus={JobStatus.ARCHIVED} />}
      />,
      <Route exact path="/jobs/wip" component={JobsWorkInProgress} />,
      <Route exact path="/jobs/search" component={GlobalSearchContainer} />,
      <Route exact path="/jobs/checklists" component={JobChecklists} />,
      <Route
        path="/jobs/:id"
        render={() => <JobsContainer role={user?.role || UserRoles.basic} />}
      />,
      <Route path="/timesheets" component={TimesheetsContainer} />,
      <Route path="/account" component={ProfileContainer} />,
      <Route path="/communication" component={CommunicationContainer} />,
    ],
    [user]
  );

  const managerRoutes = React.useMemo(
    () => [
      ...basicRoutes,
      <Route
        exact
        path="/quote-requests"
        component={QuoteRequestOverviewContainer}
      />,
      <Route
        exact
        path="/quote-requests/:id"
        component={QuoteRequestOverviewContainer}
      />,
      <Route
        path="/quote-requests/:id/sub/:sub_id"
        component={QuoteRequestContainer}
      />,
    ],
    [basicRoutes]
  );

  const builderAdminRoutes = React.useMemo(
    () => [
      ...managerRoutes,
      <Route path="/leads" component={LeadsContainer} />,
      <Route path="/contacts" component={ContactsContainer} />,
      <Route exact path="/documents" component={DocumentsContainer} />,
      <Route path="/documents/:id" component={DocumentsContainer} />,

      <Route
        exact
        path="/quotes"
        render={() => (
          <QuotesListContainer archiveStatus={QuoteArchiveStatus.UNARCHIVED} />
        )}
      />,
      <Route exact path="/quotes/checklists" component={QuoteChecklists} />,
      <Route
        path="/quotes/archived"
        render={() => (
          <QuotesListContainer archiveStatus={QuoteArchiveStatus.ARCHIVED} />
        )}
      />,

      <Route path="/quotes/:id" component={QuoteContainer} />,

      <Route path="/timesheets" component={TimesheetsContainer} />,
      <Route
        exact
        path="/jobs"
        render={() => <Jobs jobStatus={JobStatus.ACTIVE} />}
      />,
      <Route
        exact
        path="/jobs/completed"
        render={() => <Jobs jobStatus={JobStatus.ARCHIVED} />}
      />,
      <Route path="/jobs/:id" component={JobsContainer} />,
      <Route path="/settings" component={SettingsContainer} />,

      <Route>
        <Redirect to="/" />
      </Route>,
    ],
    [managerRoutes]
  );

  const routes = React.useMemo(() => {
    if (!user?.role) return basicRoutes;
    if (user.role === UserRoles.builderadmin) return builderAdminRoutes;
    else if (user.role === UserRoles.manager) return managerRoutes;
    else return basicRoutes;
  }, [builderAdminRoutes, basicRoutes, managerRoutes, user]);

  return (
    <MessageNotificationLayout user={user}>
      <DashboardLayout>
        <Switch>{routes}</Switch>
      </DashboardLayout>
    </MessageNotificationLayout>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
    isAuthenticated: state.authentication.isAuthenticated,
  };
};

export default connect(mapStateToProps, {
  getUser,
  getUserSuccess,
  getUserFailed,
})(DashboardContainer);
