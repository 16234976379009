import { gql } from "@apollo/client";
import { TIMESHEET_REQUEST_FRAGMENT } from "../timesheet/fragments";

export const GET_JOB_TIMESHEETS = gql`
  query getJobTimesheets($jobId: ID!, $isProcessed: Boolean) {
    getJobTimesheets(jobId: $jobId, isProcessed: $isProcessed) {
      ...TimesheetRequest
    }
  }
  ${TIMESHEET_REQUEST_FRAGMENT}
`;
