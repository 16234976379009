import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "@apollo/client";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { compact } from "lodash";
import GraphQLClient from "../../../../graphql";

import { notify } from "../../../../components/notification";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import {
  ChangeClientPasswordResponse,
  EditClientProfileResponse,
  UserPayload,
} from "../../../../graphql/types/models/auth";
import { RootReducerState } from "../../../../redux/reducer";
import DetailsCard, {
  DetailsCardAction,
} from "../../../../components/dashboard/details-card";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";
import HeaderPageAction from "../../../../components/dashboard/header/HeaderPageAction";
import HeaderActionButton from "../../../../components/dashboard/header/HeaderActionButton";
import ChangePasswordModal from "../../../../components/profile/change-pwd-modal";
import UpdateProfileModal from "../../../../components/profile/update-profile-modal";
import {
  ChangePasswordPayload,
  UpdateClientProfilePayload,
} from "../../../../models/profile";
import { DetailsCardDataItem } from "../../../../components/dashboard/details-card/DetailsCardList";
import {
  CHANGE_CLIENT_PASSWORD,
  EDIT_CLIENT_PROFILE,
} from "../../../../graphql/queries/auth/mutations";
import { updateProfile } from "../../../../redux/authentication/actions";
import { getFullName } from "../../../../utils/text";

type ProfileOverviewProps = DashboardContextValue & {
  user: UserPayload | null;
  updateProfile: (user: Partial<UserPayload>) => void;
};

const ClientProfileOverview: React.FC<ProfileOverviewProps> = (props) => {
  const { user, updateProfile } = props;

  const { t } = useTranslation();

  const [showPasswordModal, setPwdModalVisibility] = useState(false);
  const [showEditModal, setEditModalVisibility] = useState(false);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  const [updateClientProfileAction] = useMutation<EditClientProfileResponse>(
    EDIT_CLIENT_PROFILE,
    {
      onCompleted: async (data) => {
        updateProfile(data.clientEditProfile);
        setEditModalVisibility(false);
      },
    }
  );

  const [changePassword] = useMutation<ChangeClientPasswordResponse>(
    CHANGE_CLIENT_PASSWORD,
    {
      onCompleted: (data) => {
        GraphQLClient.setToken(data.clientChangePassword.token);
        setPwdModalVisibility(false);
        notify({
          title: t("profile.changePassword"),
          content: t("profile.success.changePassword"),
        });
      },
      onError: () => {
        notify({
          title: t("profile.changePassword"),
          content: t("profile.errors.changePassword"),
          error: true,
        });
      },
    }
  );

  const openEditModal = React.useCallback(() => {
    setEditModalVisibility(true);
  }, []);

  const closeEditModal = React.useCallback(() => {
    setAvatarFile(null);
    setEditModalVisibility(false);
  }, []);

  const openPwdModal = React.useCallback(() => {
    setPwdModalVisibility(true);
  }, []);

  const closePwdModal = React.useCallback(() => {
    setPwdModalVisibility(false);
  }, []);

  const profileCardData: DetailsCardDataItem[] = React.useMemo(() => {
    return compact([
      {
        icon: "person",
        text: getFullName(user),
      },
      {
        icon: "mail",
        text: user?.email ?? "",
      },
      user?.mobile && {
        icon: "phone",
        text: user?.mobile ?? "",
      },
    ]);
  }, [user]);

  const profileActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("profile.editDetails"),
        onClick: openEditModal,
        className: "button info large",
        disabled: !user,
      },
    ],
    [t, openEditModal, user]
  );

  const handlePwdChangeSubmit = React.useCallback(
    (values: ChangePasswordPayload) => {
      return changePassword({
        variables: {
          oldPassword: values.old_password,
          newPassword: values.new_password,
        },
      });
    },
    [changePassword]
  );

  const handleEditSubmit = React.useCallback(
    (values: UpdateClientProfilePayload) => {
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile: values?.mobile ?? "",
      };

      return updateClientProfileAction({ variables: { profile: payload } });
    },
    [updateClientProfileAction]
  );

  return (
    <Container fluid className="m-0 p-0 h-100 profile-overview">
      <Helmet title={t("navigation.account.profile")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE} />
      <HeaderPageAction>
        <HeaderActionButton icon="lock" outlined onClick={openPwdModal}>
          {t("profile.changePassword")}
        </HeaderActionButton>
      </HeaderPageAction>

      <ChangePasswordModal
        onSubmit={handlePwdChangeSubmit}
        onClose={closePwdModal}
        show={showPasswordModal}
      />

      {user && (
        <UpdateProfileModal
          avatarFile={avatarFile}
          user={user}
          show={showEditModal}
          onClose={closeEditModal}
          onSubmit={handleEditSubmit}
          isClient={true}
        />
      )}

      <Row className="h-100">
        <Col lg={6} xs={12} xl={4}>
          <DetailsCard
            title={t("profile.details")}
            actions={profileActions}
            fullHeight
            data={profileCardData}
          ></DetailsCard>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default withDashboardContext(
  connect(mapStateToProps, { updateProfile })(ClientProfileOverview)
);
