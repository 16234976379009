import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { find, map } from "lodash";
import { useMutation } from "@apollo/client";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

import UpdateEntityModal from "../../modals/update-entity";
import { createUpdateQuoteRequestFields } from "./utils";
import quoteRequestEditSchema from "./quoteRequestEdit.schema";
import {
  QuoteRequest,
  QuoteRequestCostingPayload,
  QuoteRequestResponse,
  QuoteRequestSubcontractor,
  QuoteRequestUpdateSubcontractorItemsPayload,
} from "../../../graphql/types/models/quote-request";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { UPDATE_QUOTE_REQUEST_SUBCONTRACTOR_ITEMS } from "../../../graphql/queries/quote-request/mutations";
import { notify } from "../../notification";
import "./styles.scss";

type QuoteRequestEditModalProps = {
  quoteRequest: QuoteRequest;
  subcontractor: QuoteRequestSubcontractor;
};

const QuoteRequestEditModal: React.FC<QuoteRequestEditModalProps> = (
  props,
  ref
) => {
  const { quoteRequest, subcontractor } = props;

  const { shouldShow, show, hide } = useModalDisplay(ref);

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const [updateSubcontractorItems] = useMutation<
    QuoteRequestResponse,
    QuoteRequestUpdateSubcontractorItemsPayload
  >(UPDATE_QUOTE_REQUEST_SUBCONTRACTOR_ITEMS, {
    onCompleted: () => {
      notify({
        title: t("quoteRequest.manulEntry"),
        content: t("quoteRequest.success.updateQuoteRequest"),
      });
      hide();
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("quoteRequest.manulEntry"),
        content: error.message,
      });
    },
  });

  const handleSubmit = React.useCallback(
    (values: QuoteRequestCostingPayload) => {
      if (!quoteRequest || !subcontractor) return;
      updateSubcontractorItems({
        variables: {
          items: map(values.items, (item) => ({
            quoteItemId: item.quoteItemId,
            cost: item.cost,
            gst_inc: item.gst_inc,
          })),
          quoteRequestId: quoteRequest._id,
          subcontractorId: subcontractor._id,
        },
      });
    },
    [quoteRequest, subcontractor]
  );

  const initialValues = React.useMemo(() => {
    return {
      quoteRequestId: quoteRequest?._id,
      items: map(quoteRequest?.items, (item, index) => {
        const subcontractorItem = find(subcontractor.items, {
          quoteItemId: item._id,
        });
        return {
          quoteItemId: item._id,
          name: item.name || "",
          quantity: item.quantity || 0,
          UOM: item.UOM || "",
          cost: subcontractorItem?.cost
            ? Number(subcontractorItem?.cost).toString()
            : "",
          gst_inc: false,
        };
      }),
    };
  }, []);

  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<QuoteRequestCostingPayload>
  >({});

  React.useEffect(() => {
    setFormFields(createUpdateQuoteRequestFields(t));
  }, [t]);

  return (
    <UpdateEntityModal
      className="quote-request-edit-modal"
      title={t("quoteRequest.manulEntry")}
      validationSchema={quoteRequestEditSchema(t)}
      show={shouldShow}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
      disableEventValidation={true}
      submitButtonTitle={t("common.update")}
    />
  );
};

export default forwardRef(QuoteRequestEditModal);
