import React from "react";
import { useTranslation } from "react-i18next";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import {
  CreateTakeOffLabelPayload,
  TakeOffShape,
} from "../../../models/take-off";
import { createTakeOffLabelFields } from "./utils";

type TakeOffLabelModalProps = {
  shape?: TakeOffShape;
  onSubmit: (values: CreateTakeOffLabelPayload) => void;
};

const TakeOffLabelModal: React.FC<TakeOffLabelModalProps> = (
  { shape, onSubmit },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateTakeOffLabelPayload>
  >({});

  React.useEffect(() => {
    setFormFields(createTakeOffLabelFields(t));
  }, [t]);

  return (
    <CreateEntityModal
      title={t("takeOffSection.takeOffLabel")}
      show={shouldShow}
      data={{
        name: shape?.name || "",
      }}
      onSubmit={onSubmit}
      onClose={hide}
      fields={formFields}
      submitText={t("common.save")}
    />
  );
};

export default React.forwardRef(TakeOffLabelModal);
