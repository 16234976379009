import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { map } from "lodash";
import { EnumChecklistTemplateType } from "../../../models/task";

export const saveChecklistAsTemplateFields = (
  t: TFunction,
  isChecklistsSection?: boolean
): GenericFormFields<{ name: string }> => {
  const checklistTypes = map(
    Object.values(EnumChecklistTemplateType),
    (type) => ({
      value: type,
      label: t(`tasks.checklistTemplateType.${type}`),
    })
  );
  return {
    name: {
      type: "text",
      label: t("tasks.templateName"),
      placeholder: t("tasks.templateName"),
      valueKey: "name",
      showError: true,
      inputProps: {},
    },
    ...(!isChecklistsSection && {
      templateId: {
        type: "default-select",
        label: t("common.type"),
        placeholder: t("common.type"),
        valueKey: "type",
        selectProps: {
          options: checklistTypes,
        },
      },
    }),
  };
};
