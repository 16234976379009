import React from "react";
import { useTranslation } from "react-i18next";
import { PAPER_WIDTH_VALUES, SCALE_OPTIONS } from "../utils/options";
import { SalesQuoteFile } from "../models/salesQuote";

export function useGetPageScale(pageBounds?: number[]) {
  const { t } = useTranslation();

  const calibrationValues = React.useMemo(() => {
    let mapOfCalibrationData: Record<string, number[]> = {};
    if (!pageBounds) return mapOfCalibrationData;

    const pageWidth = Math.max(...pageBounds);
    const sheetSizes = Object.values(PAPER_WIDTH_VALUES);

    SCALE_OPTIONS.forEach((option) => {
      mapOfCalibrationData[option.label] = sheetSizes.map(
        (size) => +option.value * (size / pageWidth)
      );
    });

    return mapOfCalibrationData;
  }, [pageBounds]);

  const checkPageScaleValue = React.useCallback(
    (page: SalesQuoteFile): string => {
      if (!page.calibration.lat) {
        return t("plansSection.unscaled");
      }
      if (page.calibration.scale) {
        return page.calibration.scale;
      }
      const calibrationValue = page.calibration.lat;
      const keys = Object.keys(calibrationValues);
      const scaleValue = keys.filter((key) =>
        calibrationValues[key].includes(calibrationValue)
      );

      return scaleValue.length ? scaleValue[0] : t("common.custom");
    },
    [calibrationValues, t]
  );

  return { checkPageScaleValue };
}
