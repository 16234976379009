import { TFunction } from "i18next";
import { DashboardNavigationContext } from "../../../../containers/layouts/dashboard/DashboardContext";
import { SidebarNavItem } from "../SidebarNavListItem";

import { UserPayload } from "../../../../graphql/types/models/auth";
import { UserRoles } from "../../../../models/team-member";

import { basicNavItems } from "./navigation-items/basic-navigation-items";
import { managerNavItems } from "./navigation-items/manager-navigation-items";
import { builderAdminNavItems } from "./navigation-items/builder-admin-navigation-items";

/*
All routes description

Dashboard -> /
Communication -> /communication
- Conversations -> /communication/conversation
- Emails -> /communication/email
- SMS -> /communication/sms
Tasks -> /tasks
Contacts -> /contacts
- Client -> /contacts/clients/:id
- Supplier -> /contacts/suppliers/:id
- Import Clients -> /contacts/map
Quotes -> /quotes
- Overview -> /quotes/:id
- Calibration -> /quotes/:id/calibration
- Plans -> /quotes/:id/plans
- Takeoff -> /quotes/:id/takeoff
- Costing -> /quotes/:id/costing
- Specifications -> /quotes/:id/specifications
- Preview -> /quotes/:id/preview
Timesheet -> /timesheets
- Processed -> /timesheets/processed
Jobs -> /jobs
CompletedJob -> /jobs/completed
- Overview -> /jobs/:id
- Job Messages -> /jobs/:id/messages
- Schedule -> /jobs/:id/schedule
- Costing -> /jobs/:id/costings
- Purchase Orders -> /jobs/:id/purchase-orders
- Variations -> /jobs/:id/variations
- Progress Claims -> /jobs/:id/progress-claims
- Site Diary  -> /jobs/:id/site-diary
- Documents -> /jobs/:id/documents
- Timesheet -> /jobs/:id/timesheets
Account -> /account
- My Profile -> /account/profile
- Logout -> /account/logout
Team -> /team
- Team Overview -> /team
 */

export const NAVIGATION_ROUTES = {
  DASHBOARD: "DASHBOARD",
  GLOBAL_SCHEDULE: "GLOBAL_SCHEDULE",
  CALENDAR: "CALENDAR",
  COMMUNICATION_SECTION: {
    COMMUNICATION: "COMMUNICATION",
    EMAILS: "EMAILS",
    SMS: "SMS",
    CONVERSATIONS: "CONVERSATIONS",
    MESSAGES: "MESSAGES",
  },
  TASKS: "TASKS",
  TASKS_SECTION: {
    ARCHIVED_TASKS: "ARCHIVED_TASKS",
    OVERVIEW: "TASKS_OVERVIEW",
    TASK: "TASK",
  },
  LEADS: "LEADS",
  LEADS_SECTION: {
    OVERVIEW: "LEADS_OVERVIEW",
    LEAD: "LEAD",
    IMPORT_LEADS: "IMPORT_LEADS",
  },
  CONTACTS_SECTION: {
    IMPORT_CONTACTS: "IMPORT_CONTACTS",
    CONTACTS: "CONTACTS",
    OVERVIEW: "CONTACTS_OVERVIEW",
    CLIENT: "CLIENT",
    SUPPLIER: "SUPPLIER",
  },
  DOCUMENTS_MAIN: "DOCUMENTS_MAIN",
  QUOTES_SECTION: {
    QUOTES: "QUOTES",
    ARCHIVED_QUOTES: "ARCHIVED_QUOTES",
    OVERVIEW: "QUOTES_OVERVIEW",
    CALIBRATION: "CALIBRATION",
    SCALE: "SCALE",
    PLANS: "PLANS",
    TAKEOFF: "TAKEOFF",
    COSTING: "QUOTES_COSTING",
    IMPORT_COSTING: "IMPORT_COSTING",
    QUOTE_REQUESTS: "ESTIMATION_QUOTE_REQUESTS",
    SPECIFICATIONS: "SPECIFICATIONS",
    SELECTIONS: "SELECTIONS",
    PREVIEW: "PREVIEW",
    DOCUMENTS: "QUOTES_DOCUMENTS",
    JOB: "JOB",
    CHECKLISTS: "ESTIMATION_CHECKLISTS",
  },
  QUOTE_REQUESTS_SECTION: {
    QUOTES: "QUOTE_REQUESTS",
    DETAILS: "DETAILS",
  },
  TIMESHEETS_SECTION: {
    TIMESHEETS: "TIMESHEETS",
  },
  JOBS_SECTION: {
    JOBS: "JOBS",
    COMPLETED_JOBS: "COMPLETED_JOBS",
    CHECKLISTS: "JOBS_CHECKLISTS",
    WORK_IN_PROGRESS: "WORK_IN_PROGRESS",
    SEARCH: "SEARCH",
    ESTIMATION: "ESTIMATION",
    OVERVIEW: "JOBS_OVERVIEW",
    SCHEDULE: "SCHEDULE",
    COSTING: "JOBS_COSTING",
    PURCHASE_ORDERS: "PURCHASE_ORDERS",
    ROSTER: "ROSTER",
    VARIATIONS: "VARIATIONS",
    PROGRESS_CLAIMS: "PROGRESS_CLAIMS",
    QUOTE_REQUESTS: "JOB_QUOTE_REQUESTS",
    CLIENT_DIARY: "CLIENT_DIARY",
    SITE_DIARY: "SITE_DIARY",
    DOCUMENTS: "DOCUMENTS",
    JOB_MESSAGES: "JOB_MESSAGES",
    TIMESHEET: "JOB_TIMESHEET",
    REPORTS: "REPORTS",
  },
  SITE_DIARIES: "SITE_DIARIES",
  ACCOUNT: {
    MY_PROFILE: "MY_PROFILE",
    ACCOUNT: "ACCOUNT",
    LOGOUT: "LOGOUT",
  },
  SETTINGS: {
    SETTINGS: "SETTINGS",
    BILLING: "BILLING",
    COMPANY: "COMPANY",
    TEAM: "TEAM",
    PRICE_LISTS: "PRICE_LISTS",
    PRICE_LIST: "PRICE_LIST",
    ASSEMBLIES: "ASSEMBLIES",
    TEMPLATES_SECTION: {
      TEMPLATES: "TEMPLATES",
      SITE_DIARY_TEMPLATES: "SITE_DIARY_TEMPLATES",
      ESTIMATION_TEMPLATES: "ESTIMATION_TEMPLATES",
      TAKEOFF_TEMPLATES: "TAKEOFF_TEMPLATES",
      SCHEDULE_TEMPLATES: "SCHEDULE_TEMPLATES",
      CHECKLIST_TEMPLATES: "CHECKLIST_TEMPLATES",
      CHECKLIST_TEMPLATE: "CHECKLIST_TEMPLATE",
      SPECIFICATION_TEMPLATES: "SPECIFICATION_TEMPLATES",
      SPECIFICATION_TEMPLATE: "SPECIFICATION_TEMPLATE",
      SELECTION_TEMPLATES: "SELECTION_TEMPLATES",
      SELECTION_TEMPLATE: "SELECTION_TEMPLATE",
    },
    INTEGRATIONS: "INTEGRATIONS",
  },
};

export const createNavItems = (
  t: TFunction,
  context: DashboardNavigationContext | null,
  user: UserPayload | null
): SidebarNavItem[] => {
  if (user?.role === UserRoles.builderadmin)
    return builderAdminNavItems(t, context);
  else if (user?.role === UserRoles.manager) return managerNavItems(t, context);
  else if (user?.role === UserRoles.basic) return basicNavItems(t, context);

  return basicNavItems(t, context);
};

export const findItemById = (
  items: SidebarNavItem[],
  id: string
): SidebarNavItem | undefined => {
  let result: SidebarNavItem | undefined;

  for (const item of items) {
    if (result) {
      break;
    }

    if (item.id === id) {
      result = item;
    }

    if (!result && item.items) {
      result = findItemById(item.items, id);
    }
  }

  return result;
};
