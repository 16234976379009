import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

const createEditProfileSchema = (t: TFunction) => {
  return yup.object().shape({
    email: yup
      .string()
      .email()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "profile.email")
      ),
    first_name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "profile.lastName")
      ),
    last_name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "profile.firstName")
      ),
  });
};

export default createEditProfileSchema;
