import React from "react";
import { TableCardData } from "./utils";
import { map, indexOf } from "lodash";
import Cell from "./Cell";
import { Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

type SelectableTableProps<TData> = {
  table: TableCardData<TData>;
  keyField: keyof TData;
  rowCount?: boolean;
  headerClasses?: string;
  showCountTitle?: boolean;
  onChange?: (id: string) => void;
  selected: string[];
};

const SelectableTable: React.FC<SelectableTableProps<any>> = (props) => {
  const {
    table,
    rowCount,
    keyField,
    showCountTitle,
    headerClasses,
    selected,
    onChange,
  } = props;

  const { t } = useTranslation();

  const isSelected = (id: string) => indexOf(selected, id) !== -1;

  const handleRowClick = React.useCallback(
    (id: string) => {
      return () => {
        onChange && onChange(id);
      };
    },
    [onChange]
  );

  const renderTableBody = () => {
    return map(table.rows, (row, index) => {
      const key = row.cells[keyField];

      return (
        <tr
          key={key}
          className={"table-row clickable"}
          onClick={handleRowClick(key)}
        >
          {rowCount && <td className="field-text count-cell">{index + 1}</td>}
          <td className="field-text check-cell">
            <Form.Check
              checked={isSelected(key)}
              type="checkbox"
              id={row.cells[keyField]}
            />
          </td>
          {map(table.columns, (col, index) => (
            <Cell col={col} row={row} key={`cell-${index}`} />
          ))}
        </tr>
      );
    });
  };

  return (
    <Table className="table" hover responsive>
      <thead className={headerClasses}>
        <tr>
          {rowCount && (
            <th className="count-cell field-text">
              {showCountTitle && t("common.number")}
            </th>
          )}
          <th className="field-text check-cell" />
          {map(table.columns, (col) => (
            <th key={col.valueKey as string} className="field-text">
              {col.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="table-body">{renderTableBody()}</tbody>
    </Table>
  );
};

export default SelectableTable;
