import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const newQuoteQuoteTemplateFields = (
  t: TFunction
): GenericFormFields<any> => ({
  name: {
    type: "text",
    label: t("quotes.templateName"),
    placeholder: t("quotes.templateName"),
    valueKey: "name",
  },
});
