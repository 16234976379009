import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { mapValues, pick } from "lodash";

import {
  Business,
  BusinessInvoice,
} from "../../../../graphql/types/models/business";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import editCompanyFields from "./utils";
import editInvoiceSchema from "./EditInvoice.schema";
import UpdateEntityModal from "../../../modals/update-entity";
import { useModalDisplay } from "../../../../hooks/useModalDisplay";
import { useBusinessMutation } from "../../../../hooks/mutations/useBusinessMutation";

type EditInvoiceProps = {
  company: Business;
};

const EditInvoiceModal: React.FC<EditInvoiceProps> = (props, ref) => {
  const { company } = props;

  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<
    GenericFormFields<BusinessInvoice>
  >({});

  const { shouldShow, hide } = useModalDisplay(ref);
  const { updateBusiness } = useBusinessMutation(hide);

  React.useEffect(() => {
    setFormFields(editCompanyFields(t));
  }, [t]);

  const initialValues = React.useMemo(() => {
    return mapValues(company, (s) => s || "");
  }, [company]);

  const handleSubmit = React.useCallback(
    async (values) => {
      const data = pick(values, [
        "defaultDueDays",
        "accountName",
        "bankName",
        "accountName",
        "BSB",
        "accountNumber",
        "paymentTerms",
      ]) as any;
      data.defaultDueDays = parseInt(data.defaultDueDays);

      updateBusiness({
        variables: {
          business: data,
        },
      });
    },
    [updateBusiness]
  );

  return (
    <UpdateEntityModal
      validationSchema={editInvoiceSchema(t)}
      show={shouldShow}
      className="edit-company-modal"
      title={t("company.editInvoiceSettings")}
      fields={formFields}
      data={initialValues}
      onClose={hide}
      onSubmit={handleSubmit}
    />
  );
};

export default forwardRef(EditInvoiceModal);
