import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils, ValidationRules } from "../../../utils/yup";
import { ClientType } from "../../../models/client";

export default function createClientSchema(t: TFunction) {
  return yup.object().shape({
    first_name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "contacts.firstName")
      ),
    last_name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "contacts.lastName")
      ),
    email: yup.string(),
    /*
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "contacts.email")
      )*/
    phase: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "contacts.phase")
      ),
    phone: ValidationRules.getPhoneValidationRules(yup, t),
    /*
    .required(
      ValidationMessagesUtils.createRequiredMessage(t, "contacts.primaryPhone")
    )*/
    phone_secondary: yup.string().nullable(),
    address: yup.string().nullable(),
    address2: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    postcode: ValidationRules.getPostcodeRules(yup, t),
    business_name: yup
      .string()
      .nullable()
      .when("type", {
        is: (value) => value === ClientType.BUSINESS,
        then: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "contacts.businessName"
            )
          ),
      }),
    abn: yup
      .string()
      .nullable()
      .transform((value) => {
        return /\s/.test(value) ? value.replace(/\s/g, "") : value;
      })
      .matches(/^[0-9]+$/, t("errors.invalidABN"))
      .length(11, t("errors.invalidABN")),
  });
}
