import { gql } from "@apollo/client";

export const PRICE_ITEM_FRAGMENT = gql`
  fragment PriceItemFragment on PriceItem {
    _id
    name
    category {
      _id
      name
    }
    price {
      _id
      name
    }
    sku
    UOM
    cost
  }
`;

export const DETAILED_PRICE_LIST_FRAGMENT = gql`
  fragment DetailedPriceList on Price {
    _id
    name
    createdAt
    categorisedItems {
      _id
      name
      items {
        ...PriceItemFragment
      }
    }
    supplier {
      _id
      business_name
    }
  }
  ${PRICE_ITEM_FRAGMENT}
`;
