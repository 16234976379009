import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

const createEditSubProfileSchema = (t: TFunction) => {
  return yup.object().shape({
    email: yup
      .string()
      .email()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "profile.email")
      ),
    business_name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "profile.businessName")
      ),
    contact_person: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "profile.contactPerson"
        )
      ),
  });
};

export default createEditSubProfileSchema;
