import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ProgressBar } from "react-bootstrap";

import Icon from "../../icons/Icon";
import "./styles.scss";
import {
  ChecklistCategoryType,
  ChecklistType,
} from "../../../models/checklists";
import Checklist from "../../../containers/tasks/checklist-task";
import { calcChecklistProgress } from "../checklists-categories/utils";
import ChecklistBadge from "../checklist-badge/ChecklistBadge";

type ChecklistsItemProps = {
  item: ChecklistCategoryType;
  onToggleChecklistStatus: (row: ChecklistType, categoryId: string) => void;
  isLoading: boolean;
};

const ChecklistsItem: React.FC<ChecklistsItemProps> = (props) => {
  const { item, onToggleChecklistStatus, isLoading } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const quoteSelectionItemClasses = classNames(
    {
      borderBottom: !isOpen,
    },
    "checklist-item"
  );

  const toggleExpand = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const handleToggleChecklistStatus = React.useCallback(
    (row: ChecklistType) => {
      if (row) {
        onToggleChecklistStatus(row, item._id);
      }
    },
    [item, onToggleChecklistStatus]
  );

  const hasItems = item.checklist.length > 0;
  const checklistProgress = calcChecklistProgress(item);

  return (
    <div className={"checklists-container"}>
      <div onClick={toggleExpand} className={quoteSelectionItemClasses}>
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column justify-content-center">
            <div className="item-title">
              <span>{item.name}</span>
            </div>
            {!isOpen && (
              <div className="checklist-count">
                {t("common.itemsCount", { count: item?.checklist?.length })}
              </div>
            )}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-end">
            <div className="d-flex align-items-center justify-content-end">
              {Boolean(checklistProgress || checklistProgress === 0) && (
                <ChecklistBadge progress={checklistProgress} />
              )}
              <Icon
                className="expand-button"
                name={isOpen ? "expand_more" : "expand_less"}
              />
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <>
          {hasItems && (
            <div className="pt-4">
              <ProgressBar
                animated={isLoading}
                now={checklistProgress}
                label={`${checklistProgress}%`}
                variant={
                  Math.floor(checklistProgress) === 100 ? "success" : "warning"
                }
              />
            </div>
          )}
          <Checklist<ChecklistType>
            checklist={item.checklist}
            onToggleChecklistStatus={handleToggleChecklistStatus}
            hideTaskOperations={true}
          />
        </>
      )}
    </div>
  );
};

export default ChecklistsItem;
