import { gql } from "@apollo/client";
import { JOB_COSTING_ITEM_FRAGMENT } from "./fragments";

export const DELETE_JOB_COSTING_CATEGORY = gql`
  mutation JobDeleteCostingCategory($jobId: ID!, $categoryId: ID!) {
    jobDeleteCostingCategory(jobId: $jobId, categoryId: $categoryId) {
      _id
    }
  }
`;

export const CREATE_UPDATE_JOB_COSTING_CATEGORY = gql`
  mutation JobCreateUpdateCostingCategory(
    $jobId: ID!
    $category: JobCostingCategoryInput!
  ) {
    jobCreateUpdateCostingCategory(jobId: $jobId, category: $category) {
      _id
      name
      can_timesheet
      is_locked
      is_restricted_entry
      totalRealCost
      totalEstimatedCost
      totalMarkup
      items {
        ...JobCostingItemFragment
      }
    }
  }
  ${JOB_COSTING_ITEM_FRAGMENT}
`;

export const SAVE_JOB_COSTING_BULK = gql`
  mutation($jobId: ID!, $items: [JobCostingBulkItemInput]) {
    jobBulkSaveCostingCategoryItems(jobId: $jobId, items: $items) {
      _id
      jobNumber
    }
  }
`;

export const UPDATE_JOB_COSTING_CATEGORY_ORDER = gql`
  mutation($jobId: ID!, $categories: [JobCostingCategoryInput]!) {
    jobUpdateCostingCategoryOrder(jobId: $jobId, categories: $categories) {
      _id
      name
      order
    }
  }
`;
