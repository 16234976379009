import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { find, isEmpty, head, pick } from "lodash";

import { uploadFiles, resizeFiles } from "../../../utils/files";
import SpecificationCategories from "../../../components/specification/specification-categories";
import SpecificationTable from "../../../components/specification/specification-table";
import CreateUpdateSpecCategoryModal from "../../../components/specification/specification-create-add-modal";
import SpecImportFromCostingModal from "../../../components/specification/specification-import-from-costing";
import SaveSpecificationsAsTemplateModal from "../../../components/specification/save-as-template";
import ImportSpecFromTemplateModal from "../../../components/specification/import-from-template";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import {
  SalesQuoteSpecCategoryListResponse,
  CreateUpdateQuoteSpecCategoryResponse,
  UpdateSpecCategoryOrderResponse,
} from "../../../graphql/types/models/specification";
import {
  SpecificationCategory,
  CreateUpdateSpecCategoryModalPayload,
} from "../../../models/specification";
import { LIST_SPEC_CATEGORIES } from "../../../graphql/queries/specification/queries";
import {
  CREATE_UPDATE_SPEC_CATEGORY,
  UPDATE_SPEC_CATEGORY_ORDER,
} from "../../../graphql/queries/specification/mutation";
import {
  handleAddSpecCategory,
  handleUpdateSpecCategory,
  updateCategoryItemUrls,
  handleUpdateSpecCategoryOrder,
} from "../../../graphql/queries/specification/utils";
import { notify } from "../../../components/notification";
import EstimationLocationHeader from "../../header/estimation-location-header";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useSpecifications } from "../../../hooks/useSpecifications";

type SpecificationsContainerProps = DashboardContextValue;
type SpecificationCategoryOrder = Pick<SpecificationCategory, "_id" | "name">;

const QuoteSpecifications: React.FC<SpecificationsContainerProps> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const salesQuoteId = navigationContext?.quote?._id || "";

  const saveSpecAsTemplateRef = React.useRef<ModalDisplayRef>();
  const importSpecFromTemplateRef = React.useRef<ModalDisplayRef>();

  const [
    selectedSpecCategory,
    setSpecCategory,
  ] = React.useState<SpecificationCategory | null>(null);
  const [showCreateEditModal, setShowCreateEditModal] = React.useState(false);
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);
  const [
    editSpecCategory,
    setEditSpecCategory,
  ] = React.useState<SpecificationCategory | null>(null);

  const {
    data: quoteSpecCategories,
    loading: specCategoriesLoading,
    refetch: refetchSpecCategories,
  } = useQuery<SalesQuoteSpecCategoryListResponse>(LIST_SPEC_CATEGORIES, {
    variables: { salesQuoteId },
    fetchPolicy: "cache-and-network",
  });

  const [createUpdateSpecCategory, { client: specClient }] = useMutation<
    CreateUpdateQuoteSpecCategoryResponse
  >(CREATE_UPDATE_SPEC_CATEGORY, {
    onCompleted: async (data) => {
      if (filesToUpload?.length > 0 && data?.createUpdateSpecCategory?.items) {
        await uploadFiles(
          data.createUpdateSpecCategory.items
            .filter((item) => item.upload_url)
            .map((item) => ({
              name: item.name,
              upload_url: item.upload_url,
            })),
          filesToUpload
        );
      }
      // update image url to reset cache
      updateCategoryItemUrls(
        specClient.cache,
        data.createUpdateSpecCategory._id,
        salesQuoteId
      );

      // select new category
      setSpecCategory(data.createUpdateSpecCategory);
    },
  });

  const [updateSpecCategoryOrder] = useMutation<
    UpdateSpecCategoryOrderResponse
  >(UPDATE_SPEC_CATEGORY_ORDER, {
    update: handleUpdateSpecCategoryOrder(salesQuoteId),
  });

  const {
    renderDeleteCategoryConfirm,
    openDeleteDialog,
    getCategoryInput,
  } = useSpecifications({
    salesQuoteId,
    categories: quoteSpecCategories?.getSpecBySalesQuoteId,
  });

  React.useEffect(() => {
    const data = quoteSpecCategories?.getSpecBySalesQuoteId;
    const specCategory =
      find(data, { _id: selectedSpecCategory?._id }) || head(data);
    setSpecCategory(specCategory || null);
  }, [quoteSpecCategories, selectedSpecCategory]);

  const openCreateModal = React.useCallback(async () => {
    setShowCreateEditModal(true);
  }, [setShowCreateEditModal]);

  const openUpdateModal = React.useCallback(async () => {
    setEditSpecCategory(selectedSpecCategory);
    setShowCreateEditModal(true);
  }, [setShowCreateEditModal, selectedSpecCategory]);

  const closeCreateModal = React.useCallback(async () => {
    setShowCreateEditModal(false);
    setEditSpecCategory(null);
  }, [setShowCreateEditModal, setEditSpecCategory]);

  const openImportModal = React.useCallback(() => setShowImportModal(true), []);

  const closeImportModal = React.useCallback(
    () => setShowImportModal(false),
    []
  );

  const handleCreateSpecCategory = React.useCallback(
    async (specCategory: CreateUpdateSpecCategoryModalPayload) => {
      try {
        if (!specCategory) return;

        if (specCategory.items) {
          const files: File[] = [];

          specCategory.items.forEach((item) => {
            if (item.photo && item.photo.file) {
              files.push(item.photo.file);
            }
          });

          const compressedFiles = await resizeFiles(files);
          setFilesToUpload(compressedFiles as any[]);
        }

        await createUpdateSpecCategory({
          variables: {
            category: getCategoryInput(specCategory),
          },
          update: handleAddSpecCategory(salesQuoteId),
        });

        notify({
          title: t("specifications.addSpecCategory"),
          content: t("specifications.success.addSpecCategory"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("specifications.addSpecCategory"),
          content: t("specifications.error.addSpecCategory"),
        });
      }
      setFilesToUpload([]);
      closeCreateModal();
    },
    [
      salesQuoteId,
      setFilesToUpload,
      getCategoryInput,
      closeCreateModal,
      createUpdateSpecCategory,
      handleAddSpecCategory,
    ]
  );

  const handleEditSpecCategory = React.useCallback(
    async (specCategory: CreateUpdateSpecCategoryModalPayload) => {
      try {
        if (!specCategory) return;

        if (specCategory.items) {
          const files: File[] = [];

          specCategory.items.forEach((item) => {
            if (item.photo && item.photo.file) {
              files.push(item.photo.file);
            }
          });

          const compressedFiles = await resizeFiles(files);
          setFilesToUpload(compressedFiles as any[]);
        }

        await createUpdateSpecCategory({
          variables: {
            category: getCategoryInput(specCategory),
          },
          update: handleUpdateSpecCategory(salesQuoteId),
        });

        notify({
          title: t("specifications.editSpecification"),
          content: t("specifications.success.editSpecification"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("specifications.editSpecification"),
          content: t("specifications.error.editSpecification"),
        });
      }
      setFilesToUpload([]);
      closeCreateModal();
    },
    [
      salesQuoteId,
      setFilesToUpload,
      getCategoryInput,
      closeCreateModal,
      createUpdateSpecCategory,
      handleAddSpecCategory,
    ]
  );

  const handleSort = React.useCallback(
    (categories: SpecificationCategoryOrder[]) => {
      updateSpecCategoryOrder({
        variables: {
          salesQuoteId,
          categories: categories.map((category) =>
            pick(category, ["_id", "name", "order"])
          ),
        },
      });
    },
    [salesQuoteId]
  );

  const handleSpecCategorySelect = React.useCallback(
    (category: SpecificationCategory) => {
      setSpecCategory(category);
    },
    []
  );

  const handleBackClick = React.useCallback(() => {
    history.push(`/quotes/${salesQuoteId}/costing`);
  }, [salesQuoteId, history]);

  const handleNextClick = React.useCallback(() => {
    history.push(`/quotes/${salesQuoteId}/selections`);
  }, [salesQuoteId, history]);

  const handleSaveAsTemplate = React.useCallback(() => {
    saveSpecAsTemplateRef.current?.show(true);
  }, [saveSpecAsTemplateRef]);

  const handleImportTemplate = React.useCallback(() => {
    importSpecFromTemplateRef.current?.show(true);
  }, [importSpecFromTemplateRef]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quotesSection.specifications")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.QUOTES_SECTION.SPECIFICATIONS}
      />
      <EstimationLocationHeader />
      {renderDeleteCategoryConfirm()}

      <CreateUpdateSpecCategoryModal
        data={editSpecCategory}
        show={showCreateEditModal}
        onClose={closeCreateModal}
        onSubmit={
          editSpecCategory ? handleEditSpecCategory : handleCreateSpecCategory
        }
      />

      <SpecImportFromCostingModal
        salesQuoteId={salesQuoteId}
        show={showImportModal}
        onClose={closeImportModal}
        onSubmit={handleCreateSpecCategory}
      />

      <SaveSpecificationsAsTemplateModal
        ref={saveSpecAsTemplateRef}
        salesQuoteId={salesQuoteId}
      />

      <ImportSpecFromTemplateModal
        ref={importSpecFromTemplateRef}
        salesQuoteId={salesQuoteId}
        refetchSpecCategories={refetchSpecCategories}
      />

      <Row className="h-100">
        <Col lg={4} xs={12}>
          <SpecificationCategories
            displayNavButtons={true}
            categories={quoteSpecCategories?.getSpecBySalesQuoteId}
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}
            onDelete={openDeleteDialog}
            onAddClick={openCreateModal}
            selectedCategory={selectedSpecCategory}
            selectCategory={handleSpecCategorySelect}
            onImportFromCosting={openImportModal}
            onSaveAsTemplate={handleSaveAsTemplate}
            onImportFromTemplate={handleImportTemplate}
            onSort={handleSort}
          />
        </Col>
        <Col lg={8} xs={12}>
          <SpecificationTable
            isDataLoading={
              specCategoriesLoading ||
              isEmpty(quoteSpecCategories?.getSpecBySalesQuoteId)
            }
            tableTitle={selectedSpecCategory?.name || ""}
            items={selectedSpecCategory?.items}
            isSelectedCategory={!!selectedSpecCategory}
            updateSpecCategoryPress={openUpdateModal}
            isLocked={false}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withDashboardContext(QuoteSpecifications);
