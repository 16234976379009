import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/client";

import HomeContainer from "../home";
import DashboardLayout from "../../layouts/dashboard";
import AffiliateContainer from "../affiliates";
import AffiliateDetailsContainer from "../affilaite-details";
import BusinessContainer from "../businesses";
import BusinessDetailsContainer from "../business-details";
import UsersContainer from "../users";
import { DashboardContainerProps } from "../../dashboard";
import { RootReducerState } from "../../../redux/reducer";
import {
  getUser,
  getUserFailed,
  getUserSuccess,
} from "../../../redux/authentication/actions";
import { WB_ADMIN_PROFILE } from "../../../graphql/queries/wb-admin/auth/queries";

const WbAdminDashboardContainer: React.FC<DashboardContainerProps> = ({
  getUser,
  getUserFailed,
  getUserSuccess,
  isAuthenticated,
  user,
}) => {
  const [getWbAdminProfile] = useLazyQuery(WB_ADMIN_PROFILE, {
    onCompleted: (data) => {
      getUserSuccess(data.wbAdminProfile, false, true, false);
    },
    onError: (error) => {
      getUserFailed(error.message);
    },
  });

  React.useEffect(() => {
    if (user && isAuthenticated) {
      return;
    }
    getUser();
    getWbAdminProfile();
  }, [user, isAuthenticated, getWbAdminProfile, getUser]);

  return (
    <DashboardLayout>
      <Switch>
        <Route exact path="/" component={HomeContainer} />
        <Route exact path="/businesses" component={BusinessContainer} />
        <Route path="/businesses/:id" component={BusinessDetailsContainer} />
        <Route path="/users" component={UsersContainer} />
        <Route exact path="/affiliates" component={AffiliateContainer} />
        <Route path="/affiliates/:id" component={AffiliateDetailsContainer} />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
    isAuthenticated: state.authentication.isAuthenticated,
  };
};

export default connect(mapStateToProps, {
  getUser,
  getUserSuccess,
  getUserFailed,
})(WbAdminDashboardContainer);
