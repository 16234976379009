import { chain, concat, filter, get, map, omit } from "lodash";
import React from "react";
import omitDeep from "omit-deep-lodash";
import { useMutation } from "@apollo/client";

import PlansCard from "../plans-card";
import TakeOffPlanViewer, {
  TakeOffPlanViewerRef,
} from "../take-off-plan-viewer/TakeOffPlanViewer";
import {
  updateAreaQuantity,
  updateLineQuantity,
} from "../take-off-plan-viewer/utils";
import { Calibration, SalesQuoteFile } from "../../../models/salesQuote";
import {
  SerializedLayer,
  TakeoffListItem,
  TakeOffMeasurement,
  TakeOffShape,
} from "../../../models/take-off";
import { UPDATE_TAKEOFF_ITEMS } from "../../../graphql/queries/take-off/mutations";
import { useSalesQuoteQuery } from "../../../hooks/queries/useSalesQuoteQuery";
import { useTranslation } from "react-i18next";
import { BadgeProps } from "react-bootstrap";
import { useGetPageScale } from "../../../hooks/useGetPageScale";

type TakeOffPlanEditor = {
  salesQuoteId?: string;
  isLocked?: boolean;
  takeOff: TakeoffListItem | null;
  disableAutoSave?: boolean;
  onQuantityChange?: (quantity: number) => void;
};

export type TakeOffPlanEditorRef = {
  getUnsavedTakeoff: () => TakeoffListItem | null;
  selectPage: (page: number) => void;
  focusOnShape: (shape: TakeOffShape) => void;
};

const TakeOffPlanEditor: React.FC<TakeOffPlanEditor> = (
  {
    salesQuoteId,
    takeOff,
    isLocked = false,
    disableAutoSave,
    onQuantityChange,
  },
  ref
) => {
  const { salesQuotePlans } = useSalesQuoteQuery(salesQuoteId);
  const { t } = useTranslation();

  const [updateTakeoff] = useMutation(UPDATE_TAKEOFF_ITEMS);

  const [showViewer, setShowViewr] = React.useState(false);
  const [isSelectedNewTakeOff, setIsSelectedNewTakeOff] = React.useState(false);
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [unsavedShapes, setShapes] = React.useState<TakeOffShape[] | null>(
    null
  );
  const [
    cachedTakeOff,
    setCachedTakeOff,
  ] = React.useState<TakeoffListItem | null>(takeOff);
  const viewerRef = React.useRef<TakeOffPlanViewerRef>();

  React.useImperativeHandle(
    ref,
    () => ({
      getUnsavedTakeoff: () => cachedTakeOff,
      selectPage: setSelectedPage,
      focusOnShape: (shape: TakeOffShape) => {
        viewerRef.current?.focusOnShape(shape);
      },
    }),
    [cachedTakeOff, viewerRef]
  );

  React.useEffect(() => {
    if (cachedTakeOff?._id !== takeOff?._id) {
      setIsSelectedNewTakeOff(true);
    } else {
      setIsSelectedNewTakeOff(false);
    }
    setCachedTakeOff(takeOff);
  }, [takeOff, setCachedTakeOff]);

  React.useLayoutEffect(() => {
    setShowViewr(true);
  }, []);

  const preparedShapes = React.useMemo(() => {
    if (unsavedShapes) {
      return filter(unsavedShapes, (shape) => shape.page === selectedPage);
    }

    return filter(
      cachedTakeOff?.shapes,
      (shape) => shape.page === selectedPage
    );
  }, [cachedTakeOff, selectedPage, unsavedShapes]);

  const calibrations = React.useMemo<Calibration[]>(
    () => salesQuotePlans.map((file) => file.calibration),
    [salesQuotePlans]
  );

  const selectedFile = React.useMemo<SalesQuoteFile | null>(() => {
    if (!salesQuotePlans?.length) {
      return null;
    }
    return salesQuotePlans[selectedPage - 1];
  }, [salesQuotePlans, selectedPage]);

  const handleTakeOffItemUpdate = React.useCallback(
    async (data: any) => {
      if (!cachedTakeOff) return;

      try {
        await updateTakeoff({
          variables: {
            salesQuoteId,
            ...data,
          },
        });
      } catch (e) {
        console.log(e);
      }

      // setSelectedTakeOffItem((prevState: any) => ({
      //   ...prevState,
      //   ...data,
      // }));
    },
    [cachedTakeOff, updateTakeoff, salesQuoteId]
  );

  const handleOnPageSelect = React.useCallback((page: number) => {
    setSelectedPage(page);
  }, []);

  const handleShapesUpdate = React.useCallback(
    async (shapes: SerializedLayer[]) => {
      if (!cachedTakeOff || !selectedFile) {
        return;
      }

      const existItems = chain(cachedTakeOff?.shapes)
        .filter(
          (shape) => shape.page !== selectedPage && shape.type !== "label"
        )
        .map((shape) => omitDeep(shape, ["__typename", "isEdited"]))
        .value();

      const preparedShapes: TakeOffShape[] = map(shapes, (shape) => ({
        ...shape,
        page: shape.page || selectedPage,
        angle:
          cachedTakeOff.UOM === TakeOffMeasurement.METER_SQUARED ||
          cachedTakeOff.UOM === TakeOffMeasurement.LINEAR_METER
            ? get(shape, "angle", null)
            : null,
        wallHeight:
          cachedTakeOff.UOM === TakeOffMeasurement.METER_SQUARED
            ? get(shape, "wallHeight", null)
            : null,
        depth:
          cachedTakeOff.UOM === TakeOffMeasurement.CUBIC_METER
            ? get(shape, "depth", null)
            : null,
        materialWidth:
          cachedTakeOff.UOM === TakeOffMeasurement.LINEAR_METER
            ? get(shape, "materialWidth", null)
            : null,
        weight:
          cachedTakeOff.UOM === TakeOffMeasurement.TONNE
            ? get(shape, "weight", null)
            : null,
        properties: omit(shape.properties, ["__typename", "isEdited"]),
      }));

      const allShapes = concat(preparedShapes, existItems as TakeOffShape[]);

      let quantity = 0;

      switch (cachedTakeOff.UOM) {
        case TakeOffMeasurement.MILLIMETRE:
        case TakeOffMeasurement.LINEAR_METER:
        case TakeOffMeasurement.TONNE:
          quantity = updateLineQuantity(cachedTakeOff, allShapes, calibrations);
          break;
        case TakeOffMeasurement.METER_SQUARED:
        case TakeOffMeasurement.CUBIC_METER:
          quantity = updateAreaQuantity(cachedTakeOff, allShapes, calibrations);
          break;
        case TakeOffMeasurement.QUANTITY:
          quantity = allShapes.length;
          break;
      }

      if (!disableAutoSave) {
        setShapes(preparedShapes);
        await handleTakeOffItemUpdate({
          takeOffItems: [
            {
              quantity,
              _id: cachedTakeOff?._id,
              shapes: allShapes,
            },
          ],
        });
        setShapes(null);
      } else {
        setShapes(allShapes);
        onQuantityChange?.(quantity);
        cachedTakeOff &&
          setCachedTakeOff({
            ...cachedTakeOff,
            quantity,
            shapes: allShapes,
          });
      }
    },
    [
      salesQuotePlans,
      calibrations,
      cachedTakeOff,
      handleTakeOffItemUpdate,
      selectedPage,
      disableAutoSave,
      cachedTakeOff,
      onQuantityChange,
    ]
  );
  const [pageBounds, setPageBounds] = React.useState<number[]>();
  const { checkPageScaleValue } = useGetPageScale(pageBounds);

  const handleBoundsChange = React.useCallback((bounds: number[]) => {
    setPageBounds(bounds);
  }, []);

  const fileBadge = React.useMemo(() => {
    if (!selectedFile) return;
    const calibrationData = selectedFile?.calibration;

    if (!calibrationData.lat) {
      return {
        label: t("plansSection.unscaled"),
        variant: "danger" as BadgeProps["variant"],
      };
    }
    if (!calibrationData.scale && calibrationData.lat) {
      const value = checkPageScaleValue(selectedFile);
      return {
        label: value,
        variant: "info" as BadgeProps["variant"],
      };
    } else {
      return {
        label: calibrationData.scale as string,
        variant: "info" as BadgeProps["variant"],
      };
    }
  }, [checkPageScaleValue, selectedFile, t]);

  return (
    <PlansCard
      files={salesQuotePlans}
      selectedPage={selectedPage}
      onPageChange={handleOnPageSelect}
      badge={fileBadge}
    >
      {showViewer && selectedFile && (
        <TakeOffPlanViewer
          ref={viewerRef}
          planFile={selectedFile}
          takeOff={cachedTakeOff}
          shapes={preparedShapes}
          onShapesUpdate={handleShapesUpdate}
          calibrations={calibrations}
          page={selectedPage}
          isLocked={isLocked}
          isSelectedNewTakeOff={isSelectedNewTakeOff}
          onBoundsChange={handleBoundsChange}
        />
      )}
    </PlansCard>
  );
};

export default React.forwardRef(TakeOffPlanEditor);
