import {
  Conversation,
  Member,
  Message,
  MessageSender,
} from "../models/conversation";
import moment from "moment";

export const MEMBER_1: Member = {
  _id: "1",
  full_name: "Bob Bunnings",
  email: "bob@fdg.au",
  avatar_url:
    "https://coderthemes.com/hyper/saas/assets/images/users/avatar-7.jpg",
};

export const MEMBER_2: Member = {
  _id: "2",
  full_name: "Tracey Linton",
  email: "build43@fdtr.au",
  avatar_url:
    "https://coderthemes.com/hyper/saas/assets/images/users/avatar-4.jpg",
};

export const MEMBER_3: Member = {
  _id: "3",
  email: "supplier@fdtr.au",
  full_name: "Jackie Chan",
  avatar_url:
    "https://coderthemes.com/hyper/saas/assets/images/users/avatar-3.jpg",
};

export const SENDER_1: MessageSender = {
  _id: "1",
  name: "Bob Bunnings",
  email: "bob@fdg.au",
};

export const SENDER_2: MessageSender = {
  _id: "2",
  name: "Tracey Linton",
  email: "build43@fdtr.au",
};

export const SENDER_3: MessageSender = {
  _id: "3",
  email: "supplier@fdtr.au",
  name: "Jackie Chan",
};

export const MESSAGES: Message[] = [
  {
    _id: "0",
    text:
      "Really excited for the next stage of development. It’s shaping up to be an important project milestone.",
    createdAt: moment().subtract(1.23, "day").toString(),
    sender: SENDER_1,
    media: [
      {
        name: "filename1.png",
        _id: "media1",
        size: 3244,
        type: "image",
        // name: "Home #1",
        url:
          "https://images.unsplash.com/photo-1580674571597-618e1004b923?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&h=800&q=80",
        upload_url:
          "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&h=800&q=80",
      },
    ],
  },
  {
    _id: "1",
    text: "Glad to hear this. Everything is tracking on schedule as planned.",
    createdAt: moment().subtract(1, "day").toString(),
    sender: SENDER_2,
    media: [
      {
        name: "filename2.png",
        _id: "media2",
        size: 36788,
        type: "file",
        // name: "Home Plan #11",
        url:
          "https://images.unsplash.com/photo-1580674571597-618e1004b923?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&h=800&q=80",
        upload_url:
          "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&h=800&q=80",
      },
    ],
  },
  {
    _id: "2",
    text:
      "When do you think this can be arranged by?. It certainly is. The structure is now all in place as you can see.",
    createdAt: moment().subtract(2, "hours").toString(),
    sender: SENDER_2,
  },
  {
    _id: "3",
    text:
      "Glad to hear it’s all coming along nicely. I’m looking forward to signing off on the upcoming project.",
    createdAt: moment().toString(),
    sender: SENDER_3,
  },
  {
    _id: "4",
    text:
      "I’m really happy with how things are coming together. Very excited to see what’s to come.",
    createdAt: moment().add(3, "minutes").toString(),
    sender: SENDER_2,
    media: [
      {
        name: "filename3.png",
        _id: "media3",
        size: 643566,
        type: "file",
        // name: "file1.csv",
        url:
          "https://images.unsplash.com/photo-1580674571597-618e1004b923?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&h=800&q=80",
        upload_url:
          "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&h=700&q=80",
      },
    ],
  },
  {
    _id: "5",
    text: "",
    createdAt: moment().subtract(20, "minutes").toString(),
    sender: SENDER_1,
  },
  {
    _id: "6",
    text: "It certainly is. The structure is now all in place as you can see.",
    createdAt: moment().subtract(34.5, "minutes").toString(),
    sender: SENDER_3,
    media: [
      {
        name: "filename4.png",
        _id: "media4",
        size: 343,
        type: "file",
        // name: "Plan32.pdf",
        url:
          "https://images.unsplash.com/photo-1580674571597-618e1004b923?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&h=800&q=80",
        upload_url:
          "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80",
      },
    ],
  },
  {
    _id: "7",
    text:
      "A friendly reminder that your most recent invoice is due for payment tomorrow. If you have any queries please don’t hesitate to reach out to us to discuss further. We are always happy to help.",
    createdAt: moment().subtract(45, "minutes").toString(),
    sender: SENDER_1,
    media: [
      {
        name: "filename5.png",
        _id: "media5",
        size: 7684,
        // name: "img #4",
        type: "image",
        url:
          "https://images.unsplash.com/photo-1580674571597-618e1004b923?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&h=800&q=80",
        upload_url:
          "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80",
      },
    ],
  },
  {
    _id: "8",
    text:
      "The structure is now all in place, which means that the next step is to bring in those in charge of the plastering to ensure that we maintain a steady rate of progress.",
    createdAt: moment().subtract(56, "minutes").toString(),
    sender: SENDER_2,
  },
  {
    _id: "9",
    text:
      "This makes sense and that’s certainly what we had in mind for the rollout of the next stage of development.",
    createdAt: moment().subtract(67, "minutes").toString(),
    sender: SENDER_1,
    media: [
      {
        name: "filename6.png",
        _id: "media6",
        size: 458112,
        // name: "img 2",
        type: "image",
        url:
          "https://images.unsplash.com/photo-1580674571597-618e1004b923?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&h=800&q=80",
        upload_url:
          "https://images.unsplash.com/photo-1580674571597-618e1004b923?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&h=800&q=80",
      },
    ],
  },
];

export const CONVERSATIONS: Conversation[] = [
  {
    _id: "1",
    name: "Brighton Home Project",
    // members: [SENDER_1, SENDER_2, SENDER_3],
    messages: MESSAGES,
    conversation_logo:
      "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80",
  },
  {
    _id: "2",
    name: "Home #378",
    // members: [SENDER_1, SENDER_2, SENDER_3],
    messages: MESSAGES,
    conversation_logo:
      "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80",
  },
  {
    _id: "3",
    name: "Smart Home",
    // members: [SENDER_1, SENDER_2, SENDER_3],
    messages: MESSAGES,
    conversation_logo:
      "https://images.unsplash.com/photo-1518780664697-55e3ad937233?ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80",
  },
  {
    _id: "4",
    name: "Smart Home #3",
    // members: [SENDER_1, SENDER_2, SENDER_3],
    messages: MESSAGES,
    conversation_logo:
      "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80",
  },
  {
    _id: "5",
    name: "Supply",
    // members: [SENDER_1, SENDER_2, SENDER_3],
    messages: MESSAGES,
    conversation_logo:
      "https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
  },
];
