import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { map } from "lodash";
import {
  TableCardDataRow,
  TableRowActionData,
} from "../../dashboard/table-card/utils";
import TableCard, { EmptyTablePlaceholder } from "../../dashboard/table-card";
import LeftModal from "../../left-modal";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalFooter from "../../left-modal/LeftModalFooter";
import {
  PrimaryFolder,
  TableDocumentData,
  Document,
} from "../../../models/documents";
import { DocumentsTable } from "../../../containers/documents/documents-table";
import EmptyPlaceholder from "../../empty-placeholder";
import SearchInput from "../../search-input";
import "./styles.scss";
import filesize from "filesize";

type SelectDocumentModalProps = {
  onSubmit: (files: Document[]) => void;
  show: boolean;
  onClose: () => void;
  folderId?: string;
  tableLoading?: boolean;
  folders: PrimaryFolder[];
  documents: Document[];
  selectedFiles: Document[] | null;
  setSelectedFiles: (files: Document[] | null) => void;
  selectFolder: (id: string) => void;
  parentTree: PrimaryFolder[] | null;
  onViewFile?: (id: string) => void;
};
export enum DocumentType {
  FOLDER = "Folder",
  FILE = "File",
}

const PREVIEW_FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
  "application/pdf",
];

const SelectDocumentModal: React.FC<SelectDocumentModalProps> = (props) => {
  const {
    onSubmit,
    show,
    onClose,
    folderId,
    tableLoading,
    folders,
    selectFolder,
    parentTree,
    documents,
    onViewFile,
    selectedFiles,
    setSelectedFiles,
  } = props;

  const selectedFilesIds = selectedFiles?.map((file) => file._id);

  const { t } = useTranslation();

  const [searchInput, setSearchInput] = React.useState("");
  const filteredFolders = React.useMemo(() => {
    return (
      folders?.filter((folder) =>
        folder.name.toLowerCase().includes(searchInput.toLowerCase())
      ) || []
    );
  }, [folders, searchInput]);

  const filteredDocuments = React.useMemo(() => {
    return (
      documents?.filter((doc) =>
        doc.name.toLowerCase().includes(searchInput.toLowerCase())
      ) || []
    );
  }, [documents, searchInput]);

  const handleSelect = React.useCallback(() => {
    if (!selectedFiles) return;
    onSubmit(selectedFiles);
    setSearchInput("");
  }, [onSubmit, selectedFiles]);

  const transformArraysForTable = (
    folders: PrimaryFolder[],
    documents: Document[]
  ) => {
    return folders
      .map(
        (folder): TableDocumentData => ({
          _id: folder._id,
          name: folder.name,
          size: null,
          type: DocumentType.FOLDER,
          createdAt: folder.createdAt,
        })
      )
      .concat(
        documents.map(
          (document): TableDocumentData => ({
            _id: document._id,
            name: document.name,
            size: document.size,
            type: document.type,
            createdAt: document.createdAt,
            accessRole: document?.accessRole,
            accessSelectedUsers: document?.accessSelectedUsers,
          })
        )
      );
  };

  const documentsTableData = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("documents.name"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => (
            <div className="text-truncate table-name">{value} </div>
          ),
        },
        {
          valueKey: "size",
          title: t("documents.size"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            value ? filesize(value) : "-",
        },
      ],
      rows: map(
        transformArraysForTable(filteredFolders, filteredDocuments),
        (item) => ({
          cells: {
            ...item,
            type: item.type,
          },
        })
      ),
    };
  }, [t, filteredFolders, filteredDocuments]);

  const tableRowActions: TableRowActionData<DocumentsTable>[] = React.useMemo(
    () => [
      {
        icon: "search",
        title: t("documents.documentPreview"),
        onClick: (row) => onViewFile?.(row._id),
        shouldRender: (row) => PREVIEW_FILE_TYPES.includes(row.type),
      },
    ],
    [onViewFile, t]
  );

  const handleOnClose = React.useCallback(() => {
    setSearchInput("");
    onClose();
  }, [onClose]);

  React.useEffect(() => {
    setSelectedFiles(null);
  }, [parentTree]);

  const toggleSelectedFiles = React.useCallback(
    (row: any) => {
      if (!selectedFilesIds || !selectedFiles) {
        return setSelectedFiles([row]);
      }
      if (selectedFilesIds?.includes(row._id)) {
        setSelectedFiles(selectedFiles.filter((file) => file._id !== row._id));
      } else {
        setSelectedFiles(selectedFiles.concat(row));
      }
    },
    [selectedFiles, selectedFilesIds]
  );

  const tableLeftRowActions: TableRowActionData<
    DocumentsTable
  >[] = React.useMemo(
    () => [
      {
        icon: "check_box",
        dropdownId: "document",
        onClick: toggleSelectedFiles,
        shouldRender: (row) =>
          selectedFilesIds?.includes(row._id) &&
          row.type !== DocumentType.FOLDER,
      },
      {
        icon: "check_box_outline_blank",
        dropdownId: "document",
        onClick: toggleSelectedFiles,
        shouldRender: (row) =>
          !selectedFilesIds?.includes(row._id) &&
          row.type !== DocumentType.FOLDER,
      },
    ],
    [toggleSelectedFiles, selectedFilesIds]
  );

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<DocumentsTable>) => {
      if (row.cells.type === DocumentType.FOLDER) {
        selectFolder(row.cells._id);
        setSearchInput("");
      }
    },
    [selectFolder]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("documents.emptyFilesSearchPlaceholder", {
        filter: searchInput,
      }),
    }),
    [searchInput, t]
  );

  return (
    <>
      <LeftModal
        className={"select-document-modal"}
        show={show}
        onHide={handleOnClose}
      >
        <LeftModalHeader
          title={t("documents.selectDocument")}
          onClose={handleOnClose}
        />
        <LeftModalBody className="flex-column">
          <div className="header-container">
            <div className="breadcrumbs-container">
              {parentTree &&
                parentTree.map((folder, index) => (
                  <React.Fragment key={folder._id}>
                    {index !== 0 && (
                      <span className="breadcrumb-delimiter">/</span>
                    )}
                    <Button
                      onClick={() => {
                        selectFolder(folder._id);
                        setSearchInput("");
                      }}
                      className={"button breadcrumb-buttons"}
                    >
                      {`${folder.name}`}
                    </Button>
                  </React.Fragment>
                ))}
            </div>
            <div className="search-input-container">
              <SearchInput
                value={searchInput}
                onChange={setSearchInput}
                showCancelButton
                className="search-input"
                appendSearchIcon
              />
            </div>
          </div>

          {folders.length || documents.length ? (
            <TableCard
              tableId={folderId ?? "documents"}
              isDataLoading={tableLoading}
              data={documentsTableData}
              alignEnd={true}
              onRowClick={handleRowClick}
              alignLeftActionsEnd={false}
              leftRowActions={tableLeftRowActions}
              leftRowActionsClassName="icon"
              emptyPlaceholder={emptyPlaceholder}
              rowActions={tableRowActions}
            />
          ) : (
            <div className="mt-5">
              <EmptyPlaceholder
                message={t("documents.emptySubFolderPlaceholder")}
              />
            </div>
          )}
        </LeftModalBody>
        <LeftModalFooter>
          <div className="w-100 d-flex justify-content-end">
            <div className="d-flex">
              <div className="mr-2">
                <Button
                  variant="secondary"
                  className="button large info"
                  onClick={handleOnClose}
                >
                  {t("common.cancel")}
                </Button>
              </div>

              <Button
                variant="primary"
                disabled={!selectedFilesIds?.length}
                className="button large success"
                onClick={handleSelect}
              >
                {t("common.select")}
              </Button>
            </div>
          </div>
        </LeftModalFooter>
      </LeftModal>
    </>
  );
};

export default SelectDocumentModal;
