import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { notify } from "../components/notification";
import { GET_CHECKLIST_TEMPLATES } from "../graphql/queries/task/queries";
import {
  CreateUpdateChecklistGroupPayload,
  CreateUpdateChecklistTemplateGroupResponse,
  CreateUpdateChecklistTemplateResponse,
  DeleteChecklistTemplateCategoryResponse,
  EnumChecklistTemplateType,
  GetTodoChecklistTemplateListResponse,
  TodoChecklistTemplateCategory,
  TodoChecklistTemplates,
} from "../models/task";
import {
  CREATE_CHECKLIST_TEMPLATE_CATEGORY,
  CREATE_UPDATE_CHECKLIST_TEMPLATE,
  DELETE_CHECKLIST_TEMPLATE_CATEGORY,
} from "../graphql/queries/task/mutation";
import ConfirmDialog from "../components/confirm-dialog";
import {
  handleAddChecklistTemplate,
  handleChecklistTemplateGroupDelete,
} from "../graphql/queries/task/utils";
import CreateUpdateChecklistGroupModal from "../containers/settings/checklist-templates/checklist-create-group-modal";
import { omit } from "lodash";

export type ChecklistTemplateProps = {
  templateId?: string;
  onImport?: () => void;
  onAddGroup?: (data: CreateUpdateChecklistTemplateGroupResponse) => void;
  isSort?: boolean;
  checklistTemplateType?: EnumChecklistTemplateType;
};

export function useChecklistTemplate(props: ChecklistTemplateProps) {
  const { templateId, onAddGroup, isSort, checklistTemplateType } = props;
  const { t } = useTranslation();

  const {
    data: templatesData,
    loading: loadingChecklistTemplatesData,
  } = useQuery<GetTodoChecklistTemplateListResponse>(GET_CHECKLIST_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    ...(checklistTemplateType && {
      variables: {
        type: checklistTemplateType,
      },
    }),
  });

  const [showDeleteDialog, setDeleteDialogVisibility] = React.useState(false);
  const [
    currentGroup,
    setCurrentGroup,
  ] = React.useState<TodoChecklistTemplateCategory | null>(null);
  const [
    currentTemplate,
    setTemplate,
  ] = React.useState<TodoChecklistTemplates | null>(null);
  const [categoryId, setCategoryId] = React.useState("");
  const [showCreateEditModal, setShowCreateEditModal] = React.useState(false);

  const openDeleteGroupDialog = React.useCallback((categoryId: string) => {
    setCategoryId(categoryId);
    setDeleteDialogVisibility(true);
  }, []);

  const closeDeleteDialog = React.useCallback(() => {
    setCategoryId("");
    setDeleteDialogVisibility(false);
  }, []);

  const [deleteChecklistTemplateCategory] = useMutation<
    DeleteChecklistTemplateCategoryResponse
  >(DELETE_CHECKLIST_TEMPLATE_CATEGORY, {
    onCompleted: () => {
      notify({
        title: t("tasks.deleteChecklistCategory"),
        content: t("tasks.success.deleteChecklistCategory"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("tasks.deleteChecklistCategory"),
        content: t("tasks.error.deleteChecklistCategory"),
      });
    },
    update: handleChecklistTemplateGroupDelete(templateId),
  });

  const [createUpdateChecklistTemplateGroup] = useMutation<
    CreateUpdateChecklistTemplateGroupResponse
  >(CREATE_CHECKLIST_TEMPLATE_CATEGORY, {
    onCompleted: (data) => {
      notify({
        title: currentGroup
          ? t("tasks.editChecklistGroup")
          : t("tasks.createNewGroup"),
        content: currentGroup
          ? t("tasks.success.editChecklist")
          : t("tasks.success.createChecklist"),
      });
      if (!currentGroup) {
        onAddGroup?.(data);
      }
    },
    onError: () => {
      notify({
        error: true,
        title: currentGroup
          ? t("tasks.editChecklistGroup")
          : t("tasks.createNewGroup"),
        content: currentGroup
          ? t("tasks.error.editChecklist")
          : t("tasks.error.createChecklist"),
      });
    },
  });

  const [createUpdateChecklistTemplate] = useMutation<
    CreateUpdateChecklistTemplateResponse
  >(CREATE_UPDATE_CHECKLIST_TEMPLATE, {
    onCompleted: () => {
      !isSort &&
        notify({
          title: currentTemplate
            ? t("common.editTemplate")
            : t("tasks.saveAsTemplate"),
          content: t("tasks.success.saveTemplate"),
        });
    },
    onError: () => {
      notify({
        error: true,
        title: currentTemplate
          ? t("common.editTemplate")
          : t("tasks.saveAsTemplate"),
        content: t("tasks.error.saveTemplate"),
      });
    },
    update: handleAddChecklistTemplate(checklistTemplateType),
  });

  const deleteChecklist = React.useCallback(() => {
    deleteChecklistTemplateCategory({
      variables: {
        templateId,
        categoryId,
      },
    });
  }, [categoryId, deleteChecklistTemplateCategory, templateId]);

  const openCreateGroupModal = React.useCallback(async () => {
    setShowCreateEditModal(true);
  }, [setShowCreateEditModal]);

  const openUpdateGroupModal = React.useCallback(
    (
      selectedGroup: TodoChecklistTemplateCategory,
      currentTemplate?: TodoChecklistTemplates
    ) => {
      setCurrentGroup(selectedGroup);
      if (currentTemplate) {
        setTemplate(currentTemplate);
      }
      setShowCreateEditModal(true);
    },
    [setShowCreateEditModal]
  );

  const closeCreateModal = React.useCallback(async () => {
    setShowCreateEditModal(false);
    setCurrentGroup(null);
  }, [setShowCreateEditModal]);

  const handleSort = React.useCallback(
    (categories: CreateUpdateChecklistGroupPayload[]) => {
      if (!currentTemplate) return;
      const categoriesData = categories.map((category) => ({
        ...omit(category, ["is_deleted", "templateId", "__typename"]),
        items: category?.items?.map((item) => ({
          ...omit(item, ["categoryId", "__typename", "is_deleted"]),
          task: item.task,
          order: item.order,
        })),
        name: category.name,
      }));

      createUpdateChecklistTemplate({
        variables: {
          template: {
            ...omit(currentTemplate, ["__typename", "is_deleted"]),
            name: currentTemplate.name,
            categories: categoriesData,
          },
        },
      });
    },
    [createUpdateChecklistTemplate, currentTemplate]
  );

  const handleEditGroup = React.useCallback(
    (group: CreateUpdateChecklistGroupPayload) => {
      const newData = currentTemplate?.categories.map((category) => {
        if (category._id !== group._id) return category;
        return group;
      });

      if (newData) {
        handleSort(newData);
      }
      closeCreateModal();
    },
    [currentTemplate, closeCreateModal, handleSort]
  );

  const handleCreateGroup = React.useCallback(
    async (group: CreateUpdateChecklistGroupPayload) => {
      await createUpdateChecklistTemplateGroup({
        variables: {
          templateId,
          category: group,
        },
      });

      closeCreateModal();
    },
    [closeCreateModal, createUpdateChecklistTemplateGroup, templateId]
  );

  const renderDeleteChecklistGroupConfirm = React.useCallback(() => {
    return (
      <ConfirmDialog
        title={t("tasks.deleteChecklistCategory")}
        show={showDeleteDialog}
        onSubmit={deleteChecklist}
        onClose={closeDeleteDialog}
      >
        <span className="field-text">
          {t("tasks.deleteChecklistCategoryMessage")}
        </span>
      </ConfirmDialog>
    );
  }, [closeDeleteDialog, deleteChecklist, showDeleteDialog, t]);

  const renderCreateUpdateChecklistGroupModal = React.useCallback(() => {
    return (
      <CreateUpdateChecklistGroupModal
        data={currentGroup}
        show={showCreateEditModal}
        onClose={closeCreateModal}
        onSubmit={currentGroup ? handleEditGroup : handleCreateGroup}
      />
    );
  }, [
    closeCreateModal,
    currentGroup,
    handleCreateGroup,
    handleEditGroup,
    showCreateEditModal,
  ]);

  const checklistTemplates = React.useMemo(
    () => templatesData?.listChecklistTemplates || [],
    [templatesData]
  );

  return {
    checklistTemplates,
    loadingChecklistTemplatesData,
    openDeleteGroupDialog,
    renderDeleteChecklistGroupConfirm,
    renderCreateUpdateChecklistGroupModal,
    openCreateGroupModal,
    openUpdateGroupModal,
    createUpdateChecklistTemplate,
    currentTemplate,
    setTemplate,
  };
}
