import React from "react";
import { Route, Switch } from "react-router-dom";
import Leads from "..";
import LeadOverviewContainer from "../lead-overview";
import LeadsImportContainer from "../leads-import";

const LeadsContainer = () => {
  return (
    <Switch>
      <Route path="/leads/map" component={LeadsImportContainer} />
      <Route path="/leads/:id" component={LeadOverviewContainer} />
      <Route exact path="/leads" component={Leads} />
    </Switch>
  );
};

export default LeadsContainer;
