import { TFunction } from "i18next";
import { SiteDiaryTemplatePayload } from ".";
import {
  SiteDiaryFieldType,
  SiteDiaryTemplate,
  SiteDiaryTemplateField,
  SiteDiaryTemplateType,
} from "../../../../graphql/types/models/site-diary";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";

type PayloadTODO = any & {};

export const createSiteDiaryTemplateFields = (
  t: TFunction,
  template: SiteDiaryTemplatePayload,
  onFieldChange: (
    field: string,
    value: string | string[],
    index: number
  ) => void
): GenericFormFields<PayloadTODO> => {
  const readOnly = template.type === SiteDiaryTemplateType.System;
  const fields = {
    name: {
      type: "text",
      label: t("siteDiary.title"),
      placeholder: t("siteDiary.title"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
        readOnly,
      },
    },
  } as GenericFormFields<PayloadTODO>;

  template?.fields?.forEach((field: SiteDiaryTemplateField, index: number) => {
    const row = [];
    row.push({
      type: "text",
      label: t("siteDiary.fieldTitle"),
      placeholder: t("siteDiary.fieldTitle"),
      valueKey: `fields[${index}].title`,
      inputProps: {
        type: "text",
        required: true,
        debounceChangeEvent: true,
        readOnly,
      },
    });

    row.push({
      type: "default-select",
      label: t("siteDiary.fieldType"),
      placeholder: t("siteDiary.fieldType"),
      valueKey: `fields[${index}].type`,
      selectProps: {
        isDisabled: readOnly,
        onValueChange: (value: SiteDiaryFieldType) =>
          onFieldChange("type", value, index),
        options: [
          {
            value: SiteDiaryFieldType.Heading,
            label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.Heading}`),
          },
          {
            value: SiteDiaryFieldType.String,
            label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.String}`),
          },
          {
            value: SiteDiaryFieldType.Number,
            label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.Number}`),
          },
          {
            value: SiteDiaryFieldType.Date,
            label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.Date}`),
          },
          {
            value: SiteDiaryFieldType.Collection,
            label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.Collection}`),
          },
          {
            value: SiteDiaryFieldType.Radio,
            label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.Radio}`),
          },
        ],
      },
      inputProps: {
        required: true,
        readOnly,
      },
    });
    if (field.type === SiteDiaryFieldType.Radio) {
      row.push({
        type: "creatable-select",
        label: t("siteDiary.options"),
        placeholder: t("siteDiary.options"),
        valueKey: `fields[${index}].options`,
        textInputProps: {
          onValueChange: (value: string) =>
            onFieldChange("options", value, index),
        },
        creatableProps: {
          autoSelectCreatedOption: true,
          isMulti: true,
          options: (field.options || [])?.map((option) => ({
            value: option,
            label: option,
          })),
          isValidNewOption: (v: string) => v.length > 0,
        },
      });
    }

    fields[`fields[${index}]`] = row as any;
    if (field.type === SiteDiaryFieldType.Collection) {
      fields[`fields[${index}].subFields`] = {
        tableTitle: t("siteDiary.tableItems"),
        isDisabled: readOnly,
        rowGenerator: (): any => ({
          title: "",
          type: null,
        }),
        row: [
          {
            width: "auto",
            maxWidth: "auto",
            type: "text",
            label: t("siteDiary.fieldTitle"),
            placeholder: t("siteDiary.fieldTitle"),
            valueKey: "title",
            inputProps: {
              type: "text",
              required: true,
              debounceChangeEvent: true,
              readOnly,
            },
          },
          {
            type: "default-select",
            label: t("siteDiary.fieldType"),
            placeholder: t("siteDiary.fieldType"),
            valueKey: "type",
            maxWidth: "250px",
            selectProps: {
              isDisabled: readOnly,
              options: [
                {
                  value: SiteDiaryFieldType.String,
                  label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.String}`),
                },
                {
                  value: SiteDiaryFieldType.Number,
                  label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.Number}`),
                },
                {
                  value: SiteDiaryFieldType.Date,
                  label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.Date}`),
                },
                // {
                //   value: SiteDiaryFieldType.Radio,
                //   label: t(`siteDiary.fieldTypes.${SiteDiaryFieldType.Radio}`),
                // },
              ],
            },
            inputProps: {
              required: true,
              readOnly,
            },
          },
          // {
          //   maxWidth: "250px",
          //   type: "creatable-select",
          //   label: t("siteDiary.options"),
          //   placeholder: t("siteDiary.options"),
          //   valueKey: "options",
          //   textInputProps: {
          //     isDisabled: readOnly,
          //     onValueChange: (value: string) =>
          //       onFieldChange("options", value, index),
          //   },
          //   creatableProps: {
          //     autoSelectCreatedOption: true,
          //     isMulti: true,
          //     options: (field.options || [])?.map((option) => ({
          //       value: option,
          //       label: option,
          //     })),
          //     isValidNewOption: (v: string) => v.length > 0,
          //   },
          // }
        ],
      } as any;
    }
  });

  return fields;
};
