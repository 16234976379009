import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { get } from "lodash";
import moment from "moment";

import createBusinessSchema from "./CreateBusiness.schema";
import { useModalDisplay } from "../../../hooks/useModalDisplay";

import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import {
  WbAdminCreateBusinessPayload,
  WbAdminCreateBusinessPayloadFields,
  WbAdminCreateBusinessResponse,
} from "../../../graphql/types/models/wb-business";
import { createBusinessFields } from "./utils";
import { WB_ADMIN_CREATE_BUSINESS } from "../../../graphql/queries/wb-admin/business/mutations";
import { notify } from "../../notification";

type CreateBusinessModalProps = {};

const CreateBusinessModal: React.FC<CreateBusinessModalProps> = ({}, ref) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<WbAdminCreateBusinessPayloadFields>
  >({});

  const [createBusiness] = useMutation<
    WbAdminCreateBusinessResponse,
    WbAdminCreateBusinessPayload
  >(WB_ADMIN_CREATE_BUSINESS, {
    onCompleted: () => {
      hide();
      notify({
        title: t("wbAdmin.createBusiness"),
        content: t("wbAdmin.success.createBusiness"),
      });
    },
    onError: (e) => {
      notify({
        error: true,
        title: t("wbAdmin.createBusiness"),
        content: get(e, "message", t("wbAdmin.error.createBusiness")),
      });
    },
  });

  React.useEffect(() => {
    setFormFields(createBusinessFields(t));
  }, [t]);

  const handleSubmit = React.useCallback(
    (values: WbAdminCreateBusinessPayloadFields) => {
      return createBusiness({
        variables: {
          business: values,
        },
      });
    },
    []
  );

  return (
    <CreateEntityModal
      validationSchema={createBusinessSchema(t)}
      title={t("wbAdmin.createBusiness")}
      show={shouldShow}
      data={{
        business_name: "",
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        subscriptionEndDate: moment()
          .add(2, "months")
          .endOf("day")
          .toISOString(),
      }}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
      submitText={t("common.save")}
    />
  );
};

export default React.forwardRef(CreateBusinessModal);
