import { gql } from "@apollo/client";

export const BUSINESS_FRAGMENT = gql`
  fragment BusinessFragment on Business {
    _id
    name
    email
    phone_number
    address
    address2
    city
    state
    postcode
    country
    abn
    builderNumber
    defaultDueDays
    bankName
    accountName
    BSB
    accountNumber
    paymentTerms
    richOrderTerms {
      text
      html
      raw
    }
    incomingEmail

    logo {
      _id
      name
      url
      upload_url
      type
      size
      width
      height
    }
  }
`;
