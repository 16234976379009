import React from "react";
import { isNumber, map, omit, reduce } from "lodash";
import { TFunction } from "i18next";
import { TableCardData, TableCardDataRow } from "../dashboard/table-card/utils";
import { filter, includes } from "lodash";
import {
  calcItemClientTotal,
  calcItemTotal,
  calcWithGST,
  GST_PERCENT,
} from "../../utils/calculations";
import { PurchaseOrderItem } from "../../models/purchaseOrder";

export type CostingTableItem = {
  _id?: string;
  name: string;
  UOM: string;
  quantity: number | string;
  cost: number;
  margin_amount?: number;
  wastage?: number;
  total?: number;
  clientTotal?: number;
  computed_quantity?: number;
  quoteRequestStatus?: string;
  allocatedCategory?: string;
  items?: CostingTableItem[];
  hasGST?: boolean;
};

export type SalesQuoteCategoryTableItem = {
  _id: string;
  name: string;
  total: number;
  clientTotal: number;
};

export type CostingTable = CostingTableItem;

export const createExpandedCostingTable = (
  costingItems: PurchaseOrderItem[] | null,
  t: TFunction,
  categoryMargin?: number,
  hiddenFields?: [keyof CostingTableItem],
  titleKey?: string
): TableCardData<CostingTableItem> => {
  const titleTranslationKey = titleKey ? titleKey : "costing.item";

  const table: TableCardData<CostingTableItem> = {
    columns: [
      {
        valueKey: "name",
        title: t(titleTranslationKey),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "quantity",
        title: t("costing.quantity"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.quantity", { amount: value }),
      },
      {
        valueKey: "UOM",
        title: t("costing.uom"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "cost",
        title: t("costing.cost"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.currency", { amount: value }),
      },
      {
        valueKey: "margin_amount",
        title: t("costing.markup"),
        formatValue: (row: any, column: any, value: string) => `${value}%`,
      },
      {
        valueKey: "total",
        title: t("costing.totalEx"),
        formatValue: (row: any, column: any, value: number) =>
          t("common.currency", { amount: value }),
      },
      {
        valueKey: "allocatedCategory",
        title: t("costing.allocatedCategory"),
        formatValue: (row: any, column: any, value: number) => {
          const { cells } = row;
          return cells?.costingCategoryName || "";
        },
      },
    ],
    rows: map(costingItems, (costing) => ({
      cells: {
        ...costing,
        total: calcItemTotal(costing, categoryMargin || 0),
      },
      subRows: map(costing.items, (item) => ({
        cells: {
          ...item,
          total: calcItemTotal(item, categoryMargin || 0),
        },
      })),
    })),
  };

  const filteredColumns = filter(
    table.columns,
    (column) => !includes(hiddenFields, column.valueKey)
  );

  return {
    ...table,
    columns: filteredColumns,
  };
};

export const createCostingTable = (
  costingItems: CostingTableItem[] | null,
  t: TFunction,
  categoryMargin?: number,
  hiddenFields?: (keyof CostingTableItem)[],
  titleKey?: string,
  showPricesIncGst?: boolean,
  markupPercent?: number
): TableCardData<CostingTableItem> => {
  const titleTranslationKey = titleKey ? titleKey : "costing.item";

  const columns = [
    {
      valueKey: "name",
      title: t(titleTranslationKey),
      formatValue: (row: any, column: any, value: string) => value,
    },
    {
      valueKey: "quantity",
      title: t("costing.quantity"),
      tooltip: (cell: any) => {
        const tip = [];
        if (
          cell.raw_quantity &&
          cell.raw_quantity !== cell.quantity.toString()
        ) {
          tip.push(cell.raw_quantity);
        }
        if (cell.wastage) {
          tip.push(t("costing.plusWastage", { percent: cell.wastage }));
        }
        if (tip.length) {
          return tip.join("\n");
        }
        return null;
      },
      formatValue: (row: any, column: any, value: string) =>
        !row.isTotal
          ? row.cells.wastage
            ? t("costing.quantityWaste", {
                amount: value,
                wastage: row.cells.wastage,
              })
            : isNumber(value)
            ? t("common.quantity", {
                amount: value,
              })
            : value
          : "",
    },
    {
      valueKey: "UOM",
      title: t("costing.uom"),
      formatValue: (row: any, column: any, value: string) => value,
    },
    {
      valueKey: "cost",
      title: !showPricesIncGst ? t("costing.cost") : t("costing.costInc"),
      formatValue: (row: any, column: any, value: number) =>
        !row.isTotal
          ? t("common.currency", {
              amount: value,
            })
          : "",
    },
    {
      valueKey: "total",
      title: !showPricesIncGst ? t("costing.totalEx") : t("costing.totalInc"),
      formatValue: (row: any, column: any, value: number) =>
        t("common.currency", {
          amount: value,
        }),
    },
    {
      valueKey: "margin_amount",
      title: t("costing.markup"),
      formatValue: (row: any, column: any, value: string) =>
        !row.isTotal && value !== undefined ? `${value}%` : "",
    },
    {
      valueKey: "allocatedCategory",
      title: t("costing.allocatedCategory"),
      formatValue: (row: any, column: any, value: number) => {
        const { cells } = row;
        return cells?.costingCategoryName || "";
      },
    },
    {
      valueKey: "clientTotal",
      title: !showPricesIncGst
        ? t("costing.clientTotalEx")
        : t("costing.clientTotalInc"),
      tooltip: (row: any) => {
        if (row.distribution_amount) {
          return t("costing.amountFromDistribution", {
            amount: !showPricesIncGst
              ? row.distribution_amount
              : row.hasGST
              ? calcWithGST(row.distribution_amount, GST_PERCENT)
              : row.distribution_amount,
          });
        }
        return null;
      },
      formatValue: (row: any, column: any, value: number) => {
        const {
          cells: { clientTotal, hasGST },
        } = row;
        return t("common.currency", {
          amount: clientTotal || 0,
        });
      },
    },
  ];
  const rows = map(costingItems, (costing) => {
    let cost = costing.cost || 0;
    let total = calcItemTotal({ ...costing, margin_amount: 0 });
    let clientTotal = calcItemClientTotal(
      costing,
      categoryMargin || 0,
      markupPercent || 0
    );
    if (showPricesIncGst && costing.hasGST) {
      cost = calcWithGST(cost, GST_PERCENT);
      total = calcWithGST(total, GST_PERCENT);
      clientTotal = calcWithGST(clientTotal, GST_PERCENT);
    }

    return {
      cells: {
        ...costing,
        cost,
        total,
        clientTotal,
      },
      subRows: map(costing.items, (assemblyItem) => ({
        cells: {
          ...omit(assemblyItem, ["_id"]),
          total: assemblyItem.cost * (assemblyItem?.computed_quantity || 0),
          quantity: getFormattedComputedQuantity(
            t,
            assemblyItem?.computed_quantity || 0,
            assemblyItem.quantity as number,
            costing.quantity as number
          ),
        } as CostingTableItem,
      })),
    };
  }) as TableCardDataRow<CostingTableItem>[];

  if (rows.length) {
    rows.push({
      isTotal: true,
      cells: reduce(
        rows,
        (result: CostingTableItem, row) => {
          result["total"] = (result["total"] || 0) + (row.cells.total || 0);
          result["clientTotal"] =
            (result["clientTotal"] || 0) + (row.cells.clientTotal || 0);
          return result;
        },
        {
          name: "",
          quantity: 0,
          cost: 0,
          UOM: "",
          margin_amount: 0,
          total: 0,
          clientTotal: 0,
        }
      ),
    } as TableCardDataRow<CostingTableItem>);
  }

  const filteredColumns = filter(
    columns,
    (column) => !includes(hiddenFields, column.valueKey)
  );

  return {
    columns: filteredColumns,
    rows,
  } as TableCardData<CostingTableItem>;
};

export function getFormattedComputedQuantity(
  t: TFunction,
  sub_computed_quantity: number,
  sub_quantity: number,
  item_quantity: number
) {
  return `${t("common.quantity", {
    amount: sub_computed_quantity,
  })} (${t("common.quantity", {
    amount: sub_quantity,
  })} x ${t("common.quantity", { amount: item_quantity })})`;
}
