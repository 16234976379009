import React, { useState } from "react";
import "./styles.scss";
import Icon from "../icons/Icon";
import { isImage } from "../../utils/transform";
import { Media } from "../../models/media";

type AttachmentItemProps = {
  file?: File;
  remoteFile?: Media;
  onRemove?: (file: File | Media) => void;
  showFileIcons?: boolean;
};

const AttachmentItem: React.FC<AttachmentItemProps> = (props) => {
  const { file, remoteFile, onRemove, showFileIcons } = props;

  const [objectURL, setObjectURL] = useState<string>();
  const [isMouseOver, setMouseOver] = useState(false);
  const [name, setName] = useState("");

  const isImageFile =
    (file && isImage(file)) || (remoteFile && isImage(remoteFile));

  const handleMouseEnter = React.useCallback(() => {
    setMouseOver(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setMouseOver(false);
  }, []);

  const handleRemove = React.useCallback(() => {
    const item = file || remoteFile;
    if (!item) return;
    onRemove?.(item);
  }, [onRemove, file, remoteFile]);

  React.useEffect(() => {
    if (!isImageFile) {
      return;
    }
    if (file) {
      const objectURL = URL.createObjectURL(file);
      setObjectURL(objectURL);
      setName(file.name);
      return () => {
        URL.revokeObjectURL(objectURL);
      };
    }
    if (remoteFile) {
      setObjectURL(remoteFile.url);
      setName(remoteFile.name);
    }
  }, [isImageFile, file, remoteFile]);

  const renderCloseButton = () => {
    if (!isMouseOver) {
      return null;
    }

    return (
      <div className="attachment-remove">
        <div className="remove-btn" onClick={handleRemove}>
          <Icon name="close" />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    if (isImageFile) {
      return (
        <div className="attachment-item-image">
          <img className="img-fluid" src={objectURL} alt={name} />
        </div>
      );
    }

    return (
      <div className="attachment-item-file">
        <div className="attachment-icon">
          <Icon
            name={
              showFileIcons
                ? (file?.type || remoteFile?.type) === "application/pdf"
                  ? "picture_as_pdf"
                  : "description"
                : "insert_drive_file"
            }
            outlined
          />
        </div>
        <div className="field-text text-truncate attachment-name">{name}</div>
      </div>
    );
  };

  return (
    <div
      className="attachment-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {onRemove && renderCloseButton()}
      {renderBody()}
    </div>
  );
};

export default AttachmentItem;
