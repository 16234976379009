import * as yup from "yup";
import { TFunction } from "i18next";
import moment from "moment";
import { ValidationMessagesUtils, ValidationRules } from "../../../utils/yup";
import { SalesQuote } from "../../../models/salesQuote";

export default function SaveCostingTemplate(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(ValidationMessagesUtils.createRequiredMessage(t, "name")),
  });
}
