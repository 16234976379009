import { TFunction } from "i18next";
import { range } from "lodash";
import {
  QuoteCostingDisplay,
  QuoteMeasurement,
  QuotePrintTemplateSection,
  QuoteStatus,
  PaperType,
} from "../models/salesQuote";
import { UserRoles } from "../models/team-member";
import { ReminderRepeatTypeEnum } from "../models/reminder";
import { UOMOption } from "./types/options";
import { SupplierCertificationType } from "../models/supplier";
import { SalesQuotePrintTemplateCoverStyle } from "../graphql/types/models/quote-designer";
import { ScheduleMoveDirection, ScheduleMovePeriod } from "../models/job";
import { PurchaseOrderStatus } from "../models/purchaseOrder";

export const STATES_OPTIONS = [
  {
    value: "ACT",
    label: "Australian Capital Territory",
  },
  {
    value: "NSW",
    label: "New South Wales",
  },
  {
    value: "NT",
    label: "Northern Territory",
  },
  {
    value: "QLD",
    label: "Queensland",
  },
  {
    value: "SA",
    label: "South Australia",
  },
  {
    value: "TAS",
    label: "Tasmania",
  },
  {
    value: "VIC",
    label: "Victoria",
  },
  {
    value: "WA",
    label: "Western Australia",
  },
];

export const MEASUREMENT_OPTIONS = [
  {
    value: QuoteMeasurement.LINEAR_METER,
    label: "lm",
  },
  {
    value: QuoteMeasurement.METER_SQUARED,
    label: "m2",
  },
  {
    value: QuoteMeasurement.QUANTITY,
    label: "qty",
  },
];

export const PAPER_OPTIONS = Object.values(PaperType)
  .map((paper) => ({
    value: paper.toString(),
    label: paper.toString(),
  }))
  .concat({
    value: "CUSTOM",
    label: `Custom`,
  });

export const SCALE_OPTIONS = [50, 100, 125, 150, 200, 250, 300, 500, 1000].map(
  (scale) => ({
    value: scale.toString(),
    label: `1:${scale}`,
  })
);

export const PAPER_WIDTH_VALUES: Record<PaperType, number> = {
  A0: 1189,
  B1: 1000,
  A1: 841,
  A2: 594,
  A3: 420,
  A4: 297,
};

export const getMemberRoleOptions = (t: TFunction) => {
  return [
    {
      value: UserRoles.builderadmin,
      label: t("team.builderadmin"),
    },
    {
      value: UserRoles.manager,
      label: t("team.manager"),
    },
    {
      value: UserRoles.basic,
      label: t("team.basic"),
    },
  ];
};

export const getCertificationOptions = (t: TFunction) => {
  return [
    {
      value: SupplierCertificationType.Certificate,
      label: t("certification.certificate"),
    },
    {
      value: SupplierCertificationType.License,
      label: t("certification.license"),
    },
    {
      value: SupplierCertificationType.Qualification,
      label: t("certification.qualification"),
    },
    {
      value: SupplierCertificationType.Others,
      label: t("certification.others"),
    },
  ];
};

export const getReminderRepeatOptions = (t: TFunction) => {
  return [
    {
      value: ReminderRepeatTypeEnum.None,
      label: t("reminders.status.None"),
    },
    {
      value: ReminderRepeatTypeEnum.Weekly,
      label: t("reminders.status.Weekly"),
    },
    {
      value: ReminderRepeatTypeEnum.Monthly,
      label: t("reminders.status.Monthly"),
    },
    {
      value: ReminderRepeatTypeEnum.Annually,
      label: t("reminders.status.Annually"),
    },
  ];
};

export enum ContactImportModes {
  CONTACT = "CONTACT",
  SUPPLIER = "SUPPLIER",
}

export enum TaskAssignTo {
  SUPPLIER = "supplier",
  TEAM = "team",
}

export const getImportModeOptions = (t: TFunction) => {
  return [
    {
      value: ContactImportModes.CONTACT,
      label: t("placeholders.contacts"),
    },
    {
      value: ContactImportModes.SUPPLIER,
      label: t("placeholders.suppliers"),
    },
  ];
};

export const getQuoteCostingDisplayOptions = (t: TFunction) => [
  {
    value: QuoteCostingDisplay.INCLUDE,
    label: t("quotes.include"),
  },
  {
    value: QuoteCostingDisplay.DO_NOT_INCLUDE,
    label: t("quotes.doNotInclude"),
  },
];

export const getQuoteStatusOptions = (t: TFunction) => [
  {
    value: QuoteStatus.DRAFT,
    label: t("quotes.statuses.DRAFT"),
  },
  {
    value: QuoteStatus.SENT,
    label: t("quotes.statuses.SENT"),
  },
  {
    value: QuoteStatus.APPROVED,
    label: t("quotes.statuses.APPROVED"),
  },
];

export const getQuotePrintTemplateSectionOptions = (t: TFunction) => [
  {
    value: QuotePrintTemplateSection.THEME,
    label: t("quotes.templateSections.theme"),
  },
  {
    value: QuotePrintTemplateSection.COVER,
    label: t("quotes.templateSections.cover"),
  },
  {
    value: QuotePrintTemplateSection.FOOTER,
    label: t("quotes.templateSections.footer"),
  },
  {
    value: QuotePrintTemplateSection.LETTER,
    label: t("quotes.templateSections.letter"),
  },
  {
    value: QuotePrintTemplateSection.COSTINGS,
    label: t("quotes.templateSections.costings"),
  },
  {
    value: QuotePrintTemplateSection.ALLOWANCES,
    label: t("quotes.templateSections.allowances"),
  },
  {
    value: QuotePrintTemplateSection.SPECIFICATIONS,
    label: t("quotes.templateSections.specifications"),
  },
  {
    value: QuotePrintTemplateSection.NOTES,
    label: t("quotes.templateSections.notes"),
  },
  {
    value: QuotePrintTemplateSection.SUMMARY,
    label: t("quotes.templateSections.summary"),
  },
  {
    value: QuotePrintTemplateSection.TERMS,
    label: t("quotes.templateSections.terms"),
  },
  {
    value: QuotePrintTemplateSection.ACCEPTANCE,
    label: t("quotes.templateSections.acceptance"),
  },
];

export const getPurchaseOrderReceiptStatusOptions = (t: TFunction) => [
  {
    value: PurchaseOrderStatus.RECEIVED,
    label: t("orders.statuses.RECEIVED"),
  },
  {
    value: PurchaseOrderStatus.PARTIALLY_RECEIVED,
    label: t("orders.statuses.PARTIALLY_RECEIVED"),
  },
];

export const getMovePeriodOptions = (t: TFunction) => [
  {
    value: ScheduleMovePeriod.DAYS,
    label: t("schedule.movePeriod.DAYS"),
  },
  {
    value: ScheduleMovePeriod.WEEKS,
    label: t("schedule.movePeriod.WEEKS"),
  },
  {
    value: ScheduleMovePeriod.MONTHS,
    label: t("schedule.movePeriod.MONTHS"),
  },
];

export const getMoveDirectiondOptions = (t: TFunction) => [
  {
    value: ScheduleMoveDirection.FORWARD,
    label: t("schedule.moveDirection.FORWARD"),
  },
  {
    value: ScheduleMoveDirection.BACKWARD,
    label: t("schedule.moveDirection.BACKWARD"),
  },
];

export const getBreakDurationOptions = (t: TFunction) =>
  range(0, 15 * 4 * 12.25, 15).map((value) => {
    const hours = Math.floor(value / 60);
    const minutes = 60 * (value / 60 - hours);
    let label: string[] = [];
    if (hours > 0) {
      label = [t("jobTimesheets.hour", { count: hours })];
    }
    if (minutes > 0 || !hours) {
      label = [...label, t("jobTimesheets.minute", { count: minutes })];
    }
    return {
      value: value.toString(),
      label: label.join(" "),
    };
  });

export const getMinutesfromBreakDuration = (value?: string) => {
  if (!value) return;
  const values = value.split(" ");
  const units = values.filter((item) => !Boolean(Number(item)));
  const numbers = values.filter(Number);

  if (["hours", "hour"].includes(units[0])) {
    return (
      Number(numbers[0]) * 60 +
      (Boolean(numbers[1]) ? Number(numbers[1]) : 0)
    ).toString();
  } else {
    return Number(numbers[0]).toString();
  }
};

export const getQuotePrintTemplateCoverStyleOptions = (t: TFunction) =>
  Object.keys(SalesQuotePrintTemplateCoverStyle).map((key) => ({
    value: key,
    label: key,
  }));

export const findEnum = (list: any, key: string) => {
  const keys = Object.keys(list).filter((x: any) => list[x] === key);
  return keys.length > 0 ? list[keys[0]] : null;
};
