import { gql } from "@apollo/client";
import { SALES_QUOTE_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_SALES_QUOTE = gql`
  mutation CreateSalesQuote($quote: SalesQuoteInput) {
    createUpdateSalesQuote(quote: $quote) {
      ...QuoteFragment
    }
  }
  ${SALES_QUOTE_FRAGMENT}
`;

export const COPY_SALES_QUOTE = gql`
  mutation CopySalesQuote(
    $salesQuoteId: ID!
    $quote: SalesQuoteInput!
    $options: CopySalesQuoteInput!
  ) {
    copySalesQuote(
      salesQuoteId: $salesQuoteId
      quote: $quote
      options: $options
    ) {
      ...QuoteFragment
    }
  }
  ${SALES_QUOTE_FRAGMENT}
`;

export const CREATE_SALES_QUOTE_FILE = gql`
  mutation CreateSalesQuoteFile(
    $salesQuoteId: ID!
    $files: [MediaInput]
    $replaceFiles: Boolean
  ) {
    uploadSalesQuoteFiles(
      salesQuoteId: $salesQuoteId
      files: $files
      replaceFiles: $replaceFiles
    ) {
      _id
      files {
        _id
        type
        size
        name
        url
        upload_url
        calibration {
          lat
          lng
        }
      }
    }
  }
`;

export const CALIBRATE_FILE = gql`
  mutation CalibrateFile(
    $salesQuoteId: ID!
    $calibrations: [CalibrationInput]
  ) {
    calibrateFiles(salesQuoteId: $salesQuoteId, calibrations: $calibrations) {
      _id
      files {
        _id
        type
        size
        name
        url
        upload_url
        calibration {
          lat
          lng
        }
      }
    }
  }
`;

export const SEND_SALES_QUOTE = gql`
  mutation SendSalesQuote($salesQuoteId: ID!, $message: SendSalesQuoteInput) {
    sendSalesQuote(salesQuoteId: $salesQuoteId, message: $message) {
      _id
    }
  }
`;

export const DELETE_SALES_QUOTE = gql`
  mutation($salesQuoteId: ID!) {
    deleteSalesQuote(salesQuoteId: $salesQuoteId) {
      _id
    }
  }
`;

export const ARCHIVE_SALES_QUOTE = gql`
  mutation($salesQuoteId: ID!) {
    archiveSalesQuote(salesQuoteId: $salesQuoteId) {
      _id
      is_archived
    }
  }
`;

export const UNARCHIVE_SALES_QUOTE = gql`
  mutation($salesQuoteId: ID!) {
    unarchiveSalesQuote(salesQuoteId: $salesQuoteId) {
      _id
      is_archived
    }
  }
`;

export const SAVE_TAKE_OFF_AS_TEMPLATE = gql`
  mutation saveTakeOffsAsTemplate($salesQuoteId: ID!, $name: String!) {
    saveTakeOffsAsTemplate(salesQuoteId: $salesQuoteId, name: $name) {
      _id
      name
      updatedAt
      business {
        _id
      }
      name
      takeOffs {
        _id
        name
        properties {
          color
        }
        UOM
      }
    }
  }
`;

export const IMPORT_TAKE_OFF_TEMPLATE = gql`
  mutation ImportTakeOffTemplateToSalesQuote(
    $templateId: ID!
    $salesQuoteId: ID!
  ) {
    importTakeOffTemplateToSalesQuote(
      templateId: $templateId
      salesQuoteId: $salesQuoteId
    ) {
      _id
      takeOffItems {
        _id
        name
        quantity
        UOM
        shapes {
          name
          angle
          wallHeight
          materialWidth
          depth
          geometry {
            lat
            lng
          }
          type
          page
          leaflet_id
          properties {
            text
          }
        }
        properties {
          color
        }
      }
    }
  }
`;

export const DELETE_TAKE_OFF_TEMPLATE = gql`
  mutation DeleteSalesQuoteTakeOffTemplate($templateId: ID!) {
    deleteSalesQuoteTakeOffTemplate(templateId: $templateId) {
      _id
      is_deleted
    }
  }
`;
