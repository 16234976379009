import { gql } from "@apollo/client";
import { SELECTION_FRAGMENT, SELECTION_TEMPLATE_FRAGMENT } from "./fragments";

export const GET_SELECTION = gql`
  query getSelection(
    $linkedEntityId: ID!
    $linkedEntityType: EnumSelectionLinkedEntityType!
  ) {
    getSelection(
      linkedEntityId: $linkedEntityId
      linkedEntityType: $linkedEntityType
    ) {
      ... on Selection {
        ...SelectionFragment
      }
      ... on SelectionTemplate {
        ...SelectionTemplateFragment
      }
    }
  }
  ${SELECTION_FRAGMENT}
  ${SELECTION_TEMPLATE_FRAGMENT}
`;

export const CLIENT_GET_SELECTION = gql`
  query(
    $linkedEntityId: ID!
    $linkedEntityType: EnumSelectionLinkedEntityType!
  ) {
    clientGetSelection(
      linkedEntityId: $linkedEntityId
      linkedEntityType: $linkedEntityType
    ) {
      ...SelectionFragment
    }
  }
  ${SELECTION_FRAGMENT}
`;

export const LIST_SELECTION_TEMPLATES = gql`
  query {
    listSelectionTemplates {
      ...SelectionTemplateFragment
    }
  }
  ${SELECTION_TEMPLATE_FRAGMENT}
`;

export const GET_SELECTION_TEMPLATE = gql`
  query($templateId: ID!) {
    getSelectionTemplate(templateId: $templateId) {
      ...SelectionTemplateFragment
    }
  }
  ${SELECTION_TEMPLATE_FRAGMENT}
`;

export const LIST_CLIENT_ACCESS = gql`
  query ListClientAccess($accessType: ClientAccessType!, $accessId: ID!) {
    listClientAccess(accessType: $accessType, accessId: $accessId) {
      _id
      contact {
        _id
      }
    }
  }
`;
