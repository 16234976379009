import { Col, Row } from "react-bootstrap";
import React from "react";
import { WithT } from "i18next";
import Context, { SiteContext } from "../../../siteContext";

type SignUpActivateFooterProps = WithT & {
  onResendClick: (e: any) => void;
};

const SignUpActivateFooter: React.FC<SignUpActivateFooterProps> = ({
  t,
  onResendClick,
}) => {
  const { isUser } = React.useContext(Context) as SiteContext;
  return (
    <Row className="w-100 m-0">
      <Col lg={12} xs={12} className="d-flex p-0">
        {isUser && (
          <div className="d-flex p-0 align-items-center">
            <span className="field-text">
              {t("authentication.haventReceivedActivation")}
            </span>

            <a href="#" onClick={onResendClick} className="sign-up-button">
              {t("authentication.resendActivation")}
            </a>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default SignUpActivateFooter;
