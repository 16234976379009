import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { HEADER_PAGE_ACTION_ID } from "./HeaderPageAction";
import {
  DashboardBackNavigationOptions,
  DashboardContextValue,
  DashboardSearchOptions,
  withDashboardContext,
} from "../../../containers/layouts/dashboard/DashboardContext";
import HeaderSearch from "../header-search";
import HeaderBackNavigation from "../header-back-navigation";
import SVGIcon from "../../icons/SVGIcon";
import "./styles.scss";

type HeaderProps = DashboardContextValue & {
  user: UserPayload | null;
};

const Header: React.FC<HeaderProps> = ({
  navigationItem,
  searchOptions,
  backNavigationOptions,
  user,
}) => {
  let extraInfromation = null;
  const { t } = useTranslation();
  const location = useLocation();

  switch (true) {
    case !!searchOptions:
      extraInfromation = (
        <HeaderSearch
          {...(searchOptions as DashboardSearchOptions)}
          hideNoOptions
        />
      );
      break;
    case !!backNavigationOptions:
      extraInfromation = (
        <HeaderBackNavigation
          {...(backNavigationOptions as DashboardBackNavigationOptions)}
        />
      );
      break;
  }

  const hideChat = React.useCallback(() => {
    // @ts-ignore
    if (window.$zoho) {
      // @ts-ignore
      $zoho.salesiq.floatbutton.visible("hide");
      // @ts-ignore
      $zoho.salesiq.floatwindow.close();
    }
  }, []);

  const handleHelpClick = React.useCallback(() => {
    // @ts-ignore
    if (window.$zoho) {
      // @ts-ignore
      if ($zoho.salesiq.floatbutton.visible() === "hide") {
        // @ts-ignore
        $zoho.salesiq.floatbutton.visible("visible");
        // @ts-ignore
        $zoho.salesiq.floatwindow.open();
      } else {
        hideChat();
      }
    }
  }, []);

  React.useEffect(() => {
    hideChat();
  }, []);

  React.useEffect(() => {
    hideChat();
  }, [location]);

  return (
    <header className="dashboard-header">
      <div className="title-container">
        <div className="heading">
          {navigationItem?.displayTitle || navigationItem?.title}
        </div>
        <div className="actions">
          <div id={HEADER_PAGE_ACTION_ID} className="page-action-container" />
          {extraInfromation}
        </div>
        <div className="help">
          <div
            className="help-button"
            title={t("common.help")}
            onClick={handleHelpClick}
          >
            <SVGIcon name="AskQuestion" />
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};
export default withDashboardContext(connect(mapStateToProps, {})(Header));
