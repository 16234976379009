import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProfileOverview from "../profile-overview";

const ProfileContainer = () => {
  return (
    <Switch>
      <Route path="/account/profile/" component={ProfileOverview} />
      <Route>
        <Redirect to="/account/profile/" />
      </Route>
    </Switch>
  );
};

export default ProfileContainer;
