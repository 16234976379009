import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import "./styles.scss";
import {
  CreateTaskGroupPayload,
  UpdateTaskGroupPayload,
} from "../../../models/job";
import { createTaskGroupFields } from "../create-schedule-group-modal/utils";
import createTaskGroupSchema from "../create-schedule-group-modal/CreateScheduleGroup.schema";
import UpdateEntityModal from "../../modals/update-entity";

type UpdateTaskGroupModalProps = {
  onSubmit: (data: UpdateTaskGroupPayload) => void;
};
export type UpdateTaskGroupModalRef = {
  show: (show: boolean, data?: UpdateTaskGroupPayload) => void;
  isShowing: () => boolean;
};

const UpdateTaskGroupModal: React.FC<UpdateTaskGroupModalProps> = (
  props,
  ref
) => {
  const { onSubmit } = props;
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState<UpdateTaskGroupPayload | null>();

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    show: (show: boolean, data?: UpdateTaskGroupPayload) => {
      setShow(show);
      if (data) {
        setData(data);
      }
    },
    isShowing: () => show,
  }));

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateTaskGroupPayload>
  >({});

  React.useEffect(() => {
    setFormFields(createTaskGroupFields(t, true));
  }, [t]);

  if (!data) return null;

  return (
    <UpdateEntityModal
      validationSchema={createTaskGroupSchema(t)}
      className="update-task-group-modal"
      title={data.name}
      show={show}
      data={data}
      onSubmit={onSubmit}
      onClose={() => setShow(false)}
      fields={formFields}
    />
  );
};

export default forwardRef(UpdateTaskGroupModal);
