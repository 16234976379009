import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { Col, Row } from "react-bootstrap";
import { filter } from "lodash";
import { SupplierDetailResponse } from "../../../graphql/types/models/supplier";
import { GET_SUPPLIER } from "../../../graphql/queries/supplier/queries";
import { GET_MEMBER } from "../../../graphql/members/queries";
import { DetailsCardDataItem } from "../../dashboard/details-card/DetailsCardList";
import LeftModal from "../../left-modal/";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import DetailsCard from "../../dashboard/details-card";
import Loader from "../../loader";
import Avatar from "../../avatar";
import { GetMemberResponse } from "../../../graphql/models/members";
import { getFullName } from "../../../utils/text";
import "./styles.scss";

export type UserDetailsModalRef = {
  show: (
    show: boolean,
    { type, userId }: { type: UserDetailsRequestType; userId: string }
  ) => void;
  close: (close: boolean) => void;
};

export enum UserDetailsRequestType {
  MEMBER = "member",
  SUPPLIER = "supplier",
}

const UserDetailsModal: React.FC = (props, ref) => {
  const [show, setShow] = React.useState(false);
  const [
    currentUsersType,
    setCurrentUsersType,
  ] = React.useState<UserDetailsRequestType | null>(null);

  const { t } = useTranslation();

  const [
    getSupplier,
    { data: supplierData, loading: supplierLoading },
  ] = useLazyQuery<SupplierDetailResponse>(GET_SUPPLIER, {
    fetchPolicy: "network-only",
  });

  const [
    getMember,
    { data: memberData, loading: memberLoading },
  ] = useLazyQuery<GetMemberResponse>(GET_MEMBER, {
    fetchPolicy: "network-only",
  });
  const isLoading = supplierLoading || memberLoading;

  useImperativeHandle(ref, () => ({
    show: (
      show: boolean,
      { type, userId }: { type: UserDetailsRequestType; userId: string }
    ) => {
      setShow(show);

      if (type === UserDetailsRequestType.MEMBER) {
        setCurrentUsersType(UserDetailsRequestType.MEMBER);
        getMember({
          variables: {
            userId,
          },
        });
      }
      if (type === UserDetailsRequestType.SUPPLIER) {
        setCurrentUsersType(UserDetailsRequestType.SUPPLIER);
        getSupplier({
          variables: {
            id: userId,
          },
        });
      }
    },
    close: (close: boolean) => {
      setShow(close);
    },
  }));

  const isTypeMember = currentUsersType === UserDetailsRequestType.MEMBER;

  const profileCardData: DetailsCardDataItem[] = React.useMemo(() => {
    const userFileds = [
      {
        icon: "person",
        text: getFullName(memberData?.getMember) || "",
      },
      {
        icon: "mail",
        text: memberData?.getMember?.email || "",
      },
      {
        icon: "shield",
        text: t(`team.${memberData?.getMember?.role}`) || "",
      },
    ];

    const supplierFileds = [
      {
        icon: "work_outline",
        text: supplierData?.getSupplierDetails?.business_name || "",
      },
      {
        icon: "person",
        text: supplierData?.getSupplierDetails?.contact_person || "",
      },

      {
        icon: "place",
        text: supplierData?.getSupplierDetails?.address || "",
      },
      {
        icon: "mail",
        text: supplierData?.getSupplierDetails?.email || "",
      },
      {
        icon: "local_phone",
        text: supplierData?.getSupplierDetails?.phone_number || "",
      },
    ];

    return filter(
      isTypeMember ? userFileds : supplierFileds,
      (row) => !!row.text
    );
  }, [
    isTypeMember,
    memberData?.getMember,
    supplierData?.getSupplierDetails,
    t,
  ]);

  const modalTitle = isTypeMember
    ? t("schedule.userDetails")
    : t("schedule.supplierDetails");

  const userName = isTypeMember
    ? getFullName(memberData?.getMember)
    : supplierData?.getSupplierDetails?.business_name;

  return (
    <LeftModal
      className={"user-details-modal"}
      show={show}
      onHide={() => setShow(false)}
    >
      <LeftModalHeader title={modalTitle} onClose={() => setShow(false)} />
      <LeftModalBody className="p-0">
        <Row className="h-100">
          <Col lg={12} xs={12} xl={12}>
            {isLoading ? (
              <div className="loaderContainer">
                <Loader />
              </div>
            ) : (
              <DetailsCard
                title={t("profile.details")}
                fullHeight
                data={profileCardData}
                cardBodyStyles="cardBody"
                withoutHeader
                dashBoardCardStyles="dashBoardCardStyles"
              >
                <div className="profile-avatar">
                  <Avatar
                    url={
                      isTypeMember
                        ? memberData?.getMember?.profileImage?.url
                        : undefined
                    }
                    rounded
                    alt=""
                    width="150px"
                    height="150px"
                    userName={userName}
                    titleFontSize="45px"
                  />
                </div>
              </DetailsCard>
            )}
          </Col>
        </Row>
      </LeftModalBody>
    </LeftModal>
  );
};

export default forwardRef(UserDetailsModal);
