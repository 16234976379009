import React, { useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import classnames from "classnames";
import { useMutation, useQuery } from "@apollo/client";
import { map, chain } from "lodash";
import ModalForm from "../../modals/modal-form";
import { GET_JOB_SCHEDULE } from "../../../graphql/queries/job-schedule/queries";
import { UPDATE_JOB_SCHEDULE_ORDER } from "../../../graphql/queries/job-schedule/mutations";
import {
  ListJobSchedule,
  UpdateJobScheduleOrderResponse,
} from "../../../graphql/types/models/job-schedule";
import SortableList, { SortableListRef } from "../../sortable-list";
import { SortableItem } from "../../sortable-list/types";
import { ScheduleCategory, ScheduleCategoryItem } from "../../../models/job";
import "./styles.scss";
import { handleScheduleSort } from "../../../graphql/queries/job-schedule/utils";
import { ConnectDragSource } from "react-dnd";

type SortTaskModalProps = {
  jobId: string;
  onSortComplete?: () => void;
};

export type SortTaskModalRef = {
  show: (show: boolean) => void;
};

const SortTaskModal: React.FC<SortTaskModalProps> = (props, ref) => {
  const { t } = useTranslation();
  const [show, setShowModal] = React.useState(false);
  const listRef = React.useRef<SortableListRef>(null);

  const { jobId, onSortComplete } = props;

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => {
      setShowModal(show);
    },
  }));

  const { data: jobSchedule } = useQuery<ListJobSchedule>(GET_JOB_SCHEDULE, {
    variables: {
      jobId,
    },
  });

  const [updateOrder] = useMutation<UpdateJobScheduleOrderResponse>(
    UPDATE_JOB_SCHEDULE_ORDER,
    {
      update: handleScheduleSort(jobId),
      onCompleted: (result) => {
        setShowModal(false);
        onSortComplete && onSortComplete();
      },
    }
  );

  const items = React.useMemo<SortableItem[]>(() => {
    if (jobSchedule?.getJobSchedule) {
      const items = chain(jobSchedule?.getJobSchedule)
        .orderBy("order", "asc")
        .map((category: ScheduleCategory) => ({
          id: category._id,
          item: category,
          items: chain(category.items)
            .orderBy("order", "asc")
            .map((item: ScheduleCategoryItem) => ({
              id: item._id,
              item: item,
            }))
            .value(),
        }))
        .value();
      return items;
    }
    return [];
  }, [jobSchedule]);

  const renderItem = React.useCallback(
    (props: SortableItem, dragRef: ConnectDragSource) => {
      const { item } = props;
      const isCategory = item.__typename === "ScheduleCategory";
      return (
        <div
          className={classnames("item", {
            "item-category": isCategory,
            "item-task": !isCategory,
          })}
          ref={dragRef}
        >
          {item.name}
        </div>
      );
    },
    []
  );

  const handleSubmit = React.useCallback(() => {
    const list = listRef.current?.getOrder();
    if (!list) return;

    const categories = map(list, ({ item, items }, order: number) => ({
      _id: item._id,
      order,
      items: map(items, ({ item: subItem }, subOrder: number) => ({
        _id: subItem._id,
        order: subOrder,
      })),
    }));

    updateOrder({
      variables: {
        jobId,
        categories,
      },
    });

    return true;
  }, [listRef, items]);

  return (
    <ModalForm
      className="sort-tasks-modal"
      show={show}
      onClose={() => setShowModal(false)}
      data={{}}
      onSubmit={handleSubmit}
      title={t("schedule.sortTasks")}
      submitText={t("common.update")}
    >
      {(formikProps) => (
        <Container className="generic-form-body" fluid>
          <SortableList ref={listRef} items={items} renderItem={renderItem} />
        </Container>
      )}
    </ModalForm>
  );
};

export default forwardRef(SortTaskModal);
