import { BadgeProps } from "react-bootstrap";
import { PurchaseOrderStatus } from "../../../models/purchaseOrder";
import { RosterStatus } from "../../../models/roster";
import { VariationStatuses } from "../../../models/variations";

export const getOrdersBadgeVariant = (
  status: PurchaseOrderStatus | VariationStatuses | RosterStatus
): BadgeProps["variant"] => {
  if (
    status === PurchaseOrderStatus.SENT ||
    status === VariationStatuses.AWAITING_CLIENT_APPROVAL
  ) {
    return "warning";
  } else if (
    status === PurchaseOrderStatus.RECEIVED ||
    status === VariationStatuses.APPROVED_BY_BUILDER ||
    status === VariationStatuses.APPROVED_BY_CLIENT ||
    status === RosterStatus.APPROVED
  ) {
    return "success";
  } else if (status === PurchaseOrderStatus.PARTIALLY_RECEIVED) {
    return "info";
  } else if (status === PurchaseOrderStatus.CANCELLED) {
    return "danger";
  }
  return "secondary";
};

export const setOrderFormat = {
  purchaseOrders: "PO",
  rosters: "RO",
  variations: "V",
  progressClaims: "PC",
  purchaseOrderReceipts: "PO",
};

export const setEmptyPlaceholder = {
  purchaseOrders: "noPurchaseOrders",
  rosters: "noRosters",
  variations: "noVariations",
  progressClaims: "noProgressClaims",
  purchaseOrderReceipts: "noPurchaseOrderReceipts",
};

export const setOrderStatuses = {
  purchaseOrders: "orders",
  rosters: "roster",
  variations: "variations",
  progressClaims: "claims",
  purchaseOrderReceipts: "orders",
};
