import { MutationUpdaterFn } from "@apollo/client";
import { concat, filter } from "lodash";
import {
  CreateUpdateReminderResponse,
  DeleteReminderResponse,
  ListRemindersResponse,
} from "../../types/models/reminder";
import { LIST_CLIENT_REMINDERS } from "./queries";

export const handleReminderAdd = (
  clientType: string,
  clientId?: string
): MutationUpdaterFn<CreateUpdateReminderResponse> => (cache, response) => {
  const createdReminder = response.data?.createUpdateReminder;

  const remindersListVars = {
    filter: {
      clientId,
      clientType,
    },
  };

  const clientReminders = cache.readQuery<ListRemindersResponse>({
    query: LIST_CLIENT_REMINDERS,
    variables: remindersListVars,
  });

  const reminders = clientReminders?.listReminders;

  if (!createdReminder || !reminders) {
    return;
  }

  cache.writeQuery({
    query: LIST_CLIENT_REMINDERS,
    variables: remindersListVars,
    data: {
      listReminders: concat(reminders, createdReminder),
    },
  });
};

export const handleReminderDelete = (
  clientType: string,
  clientId?: string
): MutationUpdaterFn<DeleteReminderResponse> => (cache, response) => {
  const deletedReminder = response.data?.deleteReminderById;

  const remindersListVars = {
    filter: {
      clientId,
      clientType,
    },
  };

  const clientReminders = cache.readQuery<ListRemindersResponse>({
    query: LIST_CLIENT_REMINDERS,
    variables: remindersListVars,
  });

  const reminders = clientReminders?.listReminders;

  if (!deletedReminder || !reminders) {
    return;
  }

  cache.writeQuery({
    query: LIST_CLIENT_REMINDERS,
    variables: remindersListVars,
    data: {
      listReminders: filter(
        reminders,
        (reminder) => reminder._id !== deletedReminder._id
      ),
    },
  });
};
