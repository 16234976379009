import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createSpecificationShema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "common.name")
      ),
    items: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "common.name")
          ),
        description: yup.string(),
      })
    ),
  });
}
