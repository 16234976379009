import React from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";

type LeftModalBodyProps = {
  className?: string;
};

const LeftModalBody: React.FC<LeftModalBodyProps> = ({
  children,
  className,
}) => {
  return (
    <Modal.Body className={classNames("body", className)}>
      {children}
    </Modal.Body>
  );
};

export default LeftModalBody;
