import { gql } from "@apollo/client";
import { TIMESHEET_REQUEST_FRAGMENT } from "../timesheet/fragments";

export const PROCESS_JOB_TIMESHEETS = gql`
  mutation processTimesheets($timesheets: [ProcessTimesheetInput]) {
    processTimesheets(timesheets: $timesheets) {
      ...TimesheetRequest
    }
  }
  ${TIMESHEET_REQUEST_FRAGMENT}
`;
