import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { find, map, pick } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import Container from "react-bootstrap/Container";
import TableCard from "../../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
  TableRowActionData,
} from "../../../../components/dashboard/table-card/utils";
import { AddMemberPayload } from "../../../../models/team-member";
import AddMemberModal, {
  AddMemberModalRef,
} from "../../../../components/team/add-member";
import ConfirmDialog from "../../../../components/confirm-dialog";
import {
  CREATE_UPDATE_MEMBER,
  DELETE_MEMBER,
} from "../../../../graphql/members/mutations";
import {
  AddMemberInput,
  DeleteMemberInput,
} from "../../../../graphql/input/members";
import {
  CreateUpdateMemberResponse,
  DeleteMemberResponse,
  ListMembersResponse,
} from "../../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../../graphql/members/queries";
import {
  handleMemberAdd,
  handleMemberRemove,
} from "../../../../graphql/members/utils";
import { RootReducerState } from "../../../../redux/reducer";
import { notify } from "../../../../components/notification";
import { UserPayload } from "../../../../graphql/types/models/auth";
import { getFullName } from "../../../../utils/text";

type TeamTable = {
  _id: string;
  fullName: string;
  role: string;
  email: string;
  hourly_rate: number;
};

type TeamOverviewProps = {
  user: UserPayload | null;
};

const TeamOverview: React.FC<TeamOverviewProps> = (props) => {
  const { user } = props;

  const { t } = useTranslation();

  const addMemberRef = React.useRef<AddMemberModalRef>();

  const [createUpdateMember] = useMutation<
    CreateUpdateMemberResponse,
    AddMemberInput
  >(CREATE_UPDATE_MEMBER, {
    update: handleMemberAdd,
  });

  const [removeMember, { loading: memberDeleting }] = useMutation<
    DeleteMemberResponse,
    DeleteMemberInput
  >(DELETE_MEMBER);

  const {
    data: team,
    loading: teamLoading,
    updateQuery: updateMembersList,
  } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const [showRemoveConfirmDialog, setRemoveConfirmVisibility] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState("");

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget("");
    setRemoveConfirmVisibility(false);
  }, []);

  const openRemoveDialog = React.useCallback((row?: TeamTable) => {
    if (row) {
      setDeleteTarget(row._id);
      setRemoveConfirmVisibility(true);
    }
  }, []);

  const handleMemberRemoveSubmit = React.useCallback(async () => {
    try {
      if (!deleteTarget) {
        return;
      }

      await removeMember({
        variables: {
          userId: deleteTarget,
        },
      });

      updateMembersList(handleMemberRemove(deleteTarget));

      notify({
        title: t("team.removeMember"),
        content: t("team.success.removeMember"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("team.removeMember"),
        content: t("team.error.removeMember"),
      });
    }

    setRemoveConfirmVisibility(false);
    setDeleteTarget("");
  }, [deleteTarget]);

  const handleMemberAddSubmit = React.useCallback(
    async (values: AddMemberPayload, isUpdate?: boolean) => {
      try {
        const member = pick(values, [
          "_id",
          "first_name",
          "last_name",
          "email",
          "role",
          "hourly_rate",
        ]);
        await createUpdateMember({
          variables: {
            member,
          },
        });

        notify({
          title: isUpdate ? t("common.update") : t("team.addMember"),
          content: isUpdate
            ? t("team.success.updateMember")
            : t("team.success.addMember"),
        });
        addMemberRef.current?.show(false);
      } catch (e) {
        notify({
          error: true,
          title: isUpdate ? t("common.update") : t("team.addMember"),
          content: isUpdate
            ? t("team.error.updateMember")
            : t("team.error.addMember"),
        });
      }
    },
    []
  );

  const handleEditMember = React.useCallback(
    (member?: TeamTable) => {
      const user = find(team?.listMembers, { _id: member?._id });
      if (!user) return;
      addMemberRef.current?.showEdit(user);
    },
    [addMemberRef, team]
  );

  const teamTable: TableCardData<TeamTable> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "fullName",
          title: t("placeholders.name"),
        },
        {
          valueKey: "email",
          title: t("team.email"),
        },
        {
          valueKey: "role",
          title: t("team.role"),
        },
        {
          valueKey: "hourly_rate",
          title: t("team.hourlyRate"),
          formatValue: (row: any, column: any, value: number) =>
            value ? t("common.currency", { amount: value }) : t("common.na"),
        },
      ],
      rows: map(team?.listMembers, (member) => ({
        cells: {
          role: t(`team.${member.role}`),
          fullName: getFullName(member),
          email: member.email,
          _id: member._id,
          hourly_rate: member.hourly_rate || 0,
        },
      })),
    };
  }, [team]);

  const memberTableRowActions: TableRowActionData<TeamTable>[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "team-member",
        options: [
          {
            icon: "edit",
            outlined: true,
            id: "edit",
            label: t("common.edit"),
            onClick: handleEditMember,
          },
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.remove"),
            onClick: openRemoveDialog,
          },
        ],
        shouldRender: (row: TeamTable) => {
          return user && row._id !== user._id;
        },
      },
      {
        icon: "more_horiz",
        dropdownId: "team-member",
        options: [
          {
            icon: "edit",
            outlined: true,
            id: "edit",
            label: t("common.edit"),
            onClick: handleEditMember,
          },
        ],
        shouldRender: (row: TeamTable) => {
          return user && row._id === user._id;
        },
      },
    ],
    [openRemoveDialog, handleEditMember, user]
  );

  const tableActions = React.useMemo<TableCardAction[]>(
    () => [
      {
        title: t("team.addMember"),
        onClick: () => addMemberRef.current?.show(true),
        icon: "add",
        className: "button large success",
      },
    ],
    [addMemberRef]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.team")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SETTINGS.TEAM} />

      <ConfirmDialog
        disabled={memberDeleting}
        show={showRemoveConfirmDialog}
        onClose={closeRemoveDialog}
        onSubmit={handleMemberRemoveSubmit}
        title={t("team.removeMember")}
      >
        <div className="field-text">{t("team.removeMessage")}</div>
      </ConfirmDialog>

      <AddMemberModal
        ref={addMemberRef}
        onSubmit={handleMemberAddSubmit}
        user={user}
      />

      <TableCard
        isDataLoading={teamLoading}
        fullHeight
        overflowXHidden
        data={teamTable}
        actions={tableActions}
        alignEnd
        rowActions={memberTableRowActions}
      />
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps)(TeamOverview);
