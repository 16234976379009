import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import { concat, filter, find, map, isArray, sortBy } from "lodash";
import {
  CreateTaskItemResponse,
  CreateUpdateJobTaskGroupResponse,
  DeleteScheduleCategoryResponse,
  ListJobSchedule,
  BulkCreateUpdateJobScheduleCategoryResponse,
  UpdateJobScheduleOrderResponse,
  BulkUpdateJobScheduleResponse,
  SaveScheduleAsTemplateResponse,
  ListScheduleTemplatesResponse,
  ImportScheduleFromTemplateResponse,
  DeleteScheduleTemplateResponse,
} from "../../types/models/job-schedule";
import { GET_JOB_SCHEDULE, LIST_SCHEDULE_TEMPLATES } from "./queries";
import { ScheduleCategory, ScheduleTemplate } from "../../../models/job";

const listScheduleTemplates = (cache: ApolloCache<any>) => {
  const templatesResponse = cache.readQuery<ListScheduleTemplatesResponse>({
    query: LIST_SCHEDULE_TEMPLATES,
  });
  return templatesResponse?.listScheduleTemplates;
};

const updateScheduleTemplatesList = (
  cache: ApolloCache<any>,
  scheduleTemplates: ScheduleTemplate[]
) => {
  cache.writeQuery<ListScheduleTemplatesResponse>({
    query: LIST_SCHEDULE_TEMPLATES,
    data: {
      listScheduleTemplates: scheduleTemplates,
    },
  });
};

const getSchedule = (cache: ApolloCache<any>, jobId?: string) => {
  const jobScheduleResponse = cache.readQuery<ListJobSchedule>({
    query: GET_JOB_SCHEDULE,
    variables: {
      jobId,
    },
  });
  return jobScheduleResponse?.getJobSchedule;
};

const updateSchedule = (
  cache: ApolloCache<any>,
  schedule: ScheduleCategory[],
  jobId?: string
) => {
  cache.writeQuery<ListJobSchedule>({
    query: GET_JOB_SCHEDULE,
    variables: {
      jobId,
    },
    data: {
      getJobSchedule: schedule,
    },
  });
};

export const handleScheduleCategoryImport = (
  jobId?: string
): MutationUpdaterFn<BulkCreateUpdateJobScheduleCategoryResponse> => (
  cache,
  { data }
) => {
  const newCategory = data?.bulkCreateUpdateJobScheduleCategory;
  const scheduleList = getSchedule(cache, jobId);

  if (!newCategory || !isArray(scheduleList)) return;

  updateSchedule(cache, concat(scheduleList, ...newCategory), jobId);
};

export const handleScheduleSort = (
  jobId?: string
): MutationUpdaterFn<UpdateJobScheduleOrderResponse> => (cache, response) => {
  const newScheduleList = response.data?.jobUpdateScheduleOrder;
  if (!newScheduleList) {
    return;
  }
  updateSchedule(cache, newScheduleList, jobId);
};

export const handleScheduleCategoryAdd = (
  jobId?: string
): MutationUpdaterFn<CreateUpdateJobTaskGroupResponse> => (cache, response) => {
  const newCategory = response.data?.jobCreateUpdateScheduleCategory;
  const scheduleList = getSchedule(cache, jobId);

  if (!newCategory || !scheduleList) {
    return;
  }
  updateSchedule(
    cache,
    concat(scheduleList, {
      ...newCategory,
      items: [],
    }),
    jobId
  );
};

export const handleScheduleCategoryDelete = (
  jobId?: string
): MutationUpdaterFn<DeleteScheduleCategoryResponse> => (cache, response) => {
  const removedCategory = response.data?.jobDeleteScheduleCategory;
  const scheduleList = getSchedule(cache, jobId);

  if (!removedCategory || !scheduleList) {
    return;
  }

  updateSchedule(
    cache,
    filter(scheduleList, (schedule) => schedule._id !== removedCategory._id),
    jobId
  );
};

export const handleBulkUpdateSchedule = (
  jobId?: string
): MutationUpdaterFn<BulkUpdateJobScheduleResponse> => (cache, response) => {
  const updatedCategories = response.data?.bulkUpdateJobSchedule;
  const scheduleList = getSchedule(cache, jobId);

  if (!updatedCategories || !scheduleList) {
    return;
  }

  const deletedCategories = filter(updatedCategories, { is_deleted: true });
  if (deletedCategories.length) {
    const deletedCategoryIds = deletedCategories.map(
      (category) => category._id
    );
    updateSchedule(
      cache,
      filter(
        scheduleList,
        (schedule) => deletedCategoryIds.indexOf(schedule._id) === -1
      ),
      jobId
    );
  }
};

export const handleScheduleItemUpdate = (
  jobId?: string
): MutationUpdaterFn<CreateTaskItemResponse> => (cache, response) => {
  const item = response.data?.jobCreateUpdateScheduleItem;
  const scheduleList = getSchedule(cache, jobId);

  if (!item || !scheduleList) {
    return;
  }

  const categories = map(scheduleList, (category) => {
    const items = map(category.items, (categoryItem) => {
      if (
        categoryItem._id !== item.predecessor?._id &&
        categoryItem.dependant?.length
      ) {
        return {
          ...categoryItem,
          dependant: filter(categoryItem.dependant, (d) => d._id !== item._id),
        };
      }
      return categoryItem;
    });

    return {
      ...category,
      items,
    };
  });

  updateSchedule(cache, categories, jobId);
};

export const handleScheduleItemDelete = (
  previousQueryResult: ListJobSchedule,
  deletedItemId: string,
  parentId?: string
) => {
  const deletedItem = deletedItemId;
  const schedule = previousQueryResult?.getJobSchedule;

  const categoryToUpdate = find(schedule, { _id: parentId });

  if (!categoryToUpdate || !deletedItem) {
    return previousQueryResult;
  }

  const updatedCategory = {
    ...categoryToUpdate,
    items: filter(
      categoryToUpdate.items,
      (subTask) => subTask._id !== deletedItemId
    ),
  };

  return {
    getJobSchedule: map(schedule, (category) => {
      if (category._id === parentId) {
        return updatedCategory;
      }

      return category;
    }),
  };
};

export const handleScheduleTemplateSave: MutationUpdaterFn<SaveScheduleAsTemplateResponse> = (
  cache,
  response
) => {
  const item = response.data?.jobSaveScheduleAsTemplate;
  const templatesList = listScheduleTemplates(cache) || [];

  if (!item) {
    return;
  }

  const templates = sortBy([...templatesList, item], "name");
  updateScheduleTemplatesList(cache, templates);
};

export const handleDeleteScheduleTemplate: MutationUpdaterFn<DeleteScheduleTemplateResponse> = (
  cache,
  response
) => {
  const item = response.data?.deleteScheduleTemplate;
  const templatesList = listScheduleTemplates(cache) || [];

  if (!item || !templatesList) {
    return;
  }
  const templates = filter(
    templatesList,
    (template) => template._id !== item._id
  );
  updateScheduleTemplatesList(cache, templates);
};

export const handleImportScheduleFromTemplate = (
  jobId?: string
): MutationUpdaterFn<ImportScheduleFromTemplateResponse> => (
  cache,
  { data }
) => {
  const categories = data?.jobImportScheduleFromTemplate;
  if (!isArray(categories)) return;
  updateSchedule(cache, categories, jobId);
};
