import React from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { chain, filter, map, transform } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import ClientHeaderList from "../../header/client-header-list";
import JobLocationHeader from "../../header/job-location-header";
import TeammateHeaderList from "../../header/teammate-header-list";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import CategorySelectorCard, {
  CategorySelectorFilter,
} from "../../../components/category-select-card";
import TableCard from "../../../components/dashboard/table-card";
import {
  DOWNLOAD_REPORT,
  GET_REPORT,
} from "../../../graphql/queries/report/queries";
import {
  DownloadReportResponse,
  GetReportRequest,
  GetReportResponse,
  ReportType,
} from "../../../graphql/types/models/report";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import { RootReducerState } from "../../../redux/reducer";
import { useDownloadFile } from "../../../hooks/useDownloadFile";

type JobReportsProps = DashboardContextValue & {
  user: UserPayload | null;
};

const JobReports: React.FC<JobReportsProps> = ({ navigationContext, user }) => {
  const jobId = navigationContext?.job?._id;
  const { t } = useTranslation();
  const history = useHistory();

  const reports = filter(ReportType, (t) => t !== ReportType.JobsWIP).map(
    (value) => ({
      id: value,
      label: t(`reports.reportTypes.${value}`),
    })
  );

  const [reportFilter, setReportFilter] = React.useState<
    CategorySelectorFilter
  >({
    id: "",
  });

  const { downloadFile } = useDownloadFile();
  const client = useApolloClient();

  const [getReport, { data: getReportData, loading, called }] = useLazyQuery<
    GetReportResponse,
    GetReportRequest
  >(GET_REPORT, {
    fetchPolicy: "cache-and-network",
  });

  const getReportType = (id: string) => {
    let reportType;
    switch (id) {
      case ReportType.JobPurchaseOrder:
        reportType = ReportType.JobPurchaseOrder;
        break;
      case ReportType.JobPurchaseOrderLineItem:
        reportType = ReportType.JobPurchaseOrderLineItem;
        break;
      case ReportType.JobVariation:
        reportType = ReportType.JobVariation;
        break;
      case ReportType.JobVariationLineItem:
        reportType = ReportType.JobVariationLineItem;
        break;
      case ReportType.JobProgressClaim:
        reportType = ReportType.JobProgressClaim;
        break;
      case ReportType.JobProgressClaimLineItem:
        reportType = ReportType.JobProgressClaimLineItem;
        break;
    }
    return reportType;
  };

  React.useEffect(() => {
    if (reportFilter.id) {
      const reportType = getReportType(reportFilter.id);
      if (!reportType) return;

      getReport({
        variables: {
          reportType,
          filters: {
            jobId,
          },
        },
      });
    }
  }, [jobId, reportFilter]);

  const handleSelect = React.useCallback(
    (filter: CategorySelectorFilter) => {
      setReportFilter(filter);
    },
    [setReportFilter]
  );
  const handleDownload = React.useCallback(async () => {
    if (reportFilter.id) {
      const reportType = getReportType(reportFilter.id);
      if (!reportType) return;
      try {
        const result = await client.query<
          DownloadReportResponse,
          GetReportRequest
        >({
          query: DOWNLOAD_REPORT,
          variables: {
            reportType,
            filters: {
              jobId,
            },
          },
          fetchPolicy: "network-only",
        });
        const exportData = result?.data?.downloadReport;
        downloadFile(exportData.data, exportData.filename);
      } catch (e) {}
    }
  }, [jobId, reportFilter]);

  const reportTableData = React.useMemo<TableCardData<any>>(() => {
    if (!getReportData?.getReport) {
      return {
        columns: [],
        rows: [],
      };
    }

    const columns = chain(getReportData?.getReport?.header)
      .map((header) => {
        if (header.key.indexOf("Job") === 0) return null;
        return {
          valueKey: header.key,
          title: header.key,
          formatValue: (row: any, column: any, value: string) => {
            if (header.format === "currency")
              return t("common.currency", { amount: value });
            if (header.format === "date" && value)
              return moment(value).format("DD/MM/YYYY");
            return value;
          },
        };
      })
      .compact()
      .value();

    const rows: TableCardDataRow<any>[] = map(
      getReportData?.getReport.rows,
      (row) => {
        const cells = {
          _id: row._id,
        } as any;
        row.cells.forEach((c) => {
          cells[c.key] = c.value || "";
        });
        return { cells };
      }
    );

    if (rows.length) {
      rows.push({
        isTotal: true,
        cells: transform(
          getReportData?.getReport?.summary,
          (result: any, cell) => {
            result[cell.key] = cell.value || "";
            return result;
          },
          {}
        ),
      });
    }
    return {
      columns,
      rows,
    };
  }, [t, getReportData]);

  const headerAction = React.useMemo<TableCardAction>(() => {
    return {
      className: "button large info bg-transparent text-light",
      icon: "download",
      onClick: handleDownload,
      title: t("common.export"),
    };
  }, [t, handleDownload]);

  const rowActions: TableRowActionData<any>[] = React.useMemo(
    () => [
      {
        icon: "search",
        tooltip: () => t("reports.viewItem"),
        onClick: (row: any) => {
          let path = "";
          switch (reportFilter.id) {
            case ReportType.JobPurchaseOrder:
              path = "/jobs/:jobId/purchase-orders/:id";
              break;
            case ReportType.JobVariation:
              path = "/jobs/:jobId/variations/:id";
              break;
            case ReportType.JobProgressClaim:
              path = "/jobs/:jobId/progress-claims/:id";
              break;
          }
          const url = generatePath(path, { jobId, id: row._id });
          history.push(url);
        },
        shouldRender: (row) => !!row._id,
      },
    ],
    [reportFilter, jobId, history]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.reports")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.REPORTS} />
      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      <Row className="h-100">
        <Col lg={3} xs={12}>
          <CategorySelectorCard
            title={t("reports.reports")}
            filter={reportFilter}
            onSelectCategory={handleSelect}
            categories={reports}
            hideSelectAllButton={true}
            hideAddButton={true}
          />
        </Col>
        <Col lg={9} xs={12}>
          <TableCard
            rowCount
            isDataLoading={!called || loading}
            headerAction={headerAction}
            titleBadge={reportFilter.label || t("reports.selectReport")}
            data={reportTableData}
            rowActions={rowActions}
            emptyPlaceholder={{ text: t("reports.emptyPlaceholder") }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(JobReports));
