import { gql } from "@apollo/client";

export const READ_NOTIFICATION = gql`
  mutation($notificationId: ID!) {
    readNotification(notificationId: $notificationId) {
      _id
      message
      is_read
      createdAt
    }
  }
`;
