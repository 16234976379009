import React, { useState, ReactNode } from "react";
import { compact, filter, includes } from "lodash";
import { useTranslation } from "react-i18next";
import CustomReportCard from "./custom-report-card";
import "./styles.scss";

export enum ReportCardControlsEnum {
  print = "print",
  mail = "mail",
  edit = "edit",
  delete = "delete",
  cancel = "cancel",
  share = "share",
  clone = "clone",
  receive = "receive",
}

type ReportCardProps = {
  title?: string;
  badge?: string;
  hiddenControls?: ReportCardControlsEnum[];
  className?: string;
  onCancel?: () => void;
  onDelete?: () => void;
  onSend?: () => void;
  onPrint?: () => void;
  onEdit?: () => void;
  onShare?: () => void;
  onClone?: () => void;
  onReceive?: () => void;
  status?: string | undefined;
  footer?: ReactNode;
  withoutUppercase?: boolean;
};

const ReportCard: React.FC<ReportCardProps> = (props) => {
  const {
    title,
    badge,
    children,
    className,
    onDelete,
    onCancel,
    onSend,
    onPrint,
    onEdit,
    onShare,
    onClone,
    onReceive,
    hiddenControls,
    status,
    footer,
    withoutUppercase,
  } = props;

  const [isDropdownShow, setDropdownVisibility] = useState(false);

  const { t } = useTranslation();

  const handleToggle = React.useCallback(() => {
    setDropdownVisibility(!isDropdownShow);
  }, [isDropdownShow]);

  const dropdownItems = React.useMemo(() => {
    const controls = compact([
      onPrint
        ? {
            id: "print",
            label: t("common.print"),
            icon: "print",
            name: ReportCardControlsEnum.print,
            outlined: true,
            onClick: onPrint,
          }
        : null,
      onSend
        ? {
            id: "mail",
            label: t("common.send"),
            icon: "mail",
            name: ReportCardControlsEnum.mail,
            outlined: true,
            onClick: onSend,
          }
        : null,
      onEdit
        ? {
            id: "edit",
            label: t("common.edit"),
            icon: "edit",
            name: ReportCardControlsEnum.edit,
            outlined: true,
            onClick: onEdit,
          }
        : null,
      onCancel
        ? {
            id: "close",
            label: t("common.cancel"),
            icon: "close",
            name: ReportCardControlsEnum.cancel,
            outlined: true,
            onClick: onCancel,
          }
        : null,
      onShare
        ? {
            id: "share",
            label: t("common.share"),
            icon: "share",
            name: ReportCardControlsEnum.share,
            outlined: true,
            onClick: onShare,
          }
        : null,
      onClone
        ? {
            id: "clone",
            label: t("common.clone"),
            icon: "content_copy",
            name: ReportCardControlsEnum.clone,
            outlined: true,
            onClick: onClone,
          }
        : null,
      onReceive
        ? {
            id: "receive",
            label: t("common.receive"),
            icon: "check",
            name: ReportCardControlsEnum.receive,
            outlined: true,
            onClick: onReceive,
          }
        : null,
      onDelete
        ? {
            id: "delete",
            label: t("common.delete"),
            icon: "delete",
            name: ReportCardControlsEnum.delete,
            outlined: true,
            onClick: onDelete,
          }
        : null,
    ]);

    return filter(
      controls,
      (control) => !includes(hiddenControls, control.name)
    );
  }, [
    hiddenControls,
    onDelete,
    onCancel,
    onSend,
    onPrint,
    onShare,
    status,
    onEdit,
    onClone,
    onReceive,
    t,
  ]);

  const isDropdownVisible = React.useMemo(
    () => !!(onPrint || onDelete || onCancel || onEdit || onSend),
    [onPrint, onDelete, onCancel, onEdit, onSend]
  );

  return (
    <CustomReportCard
      title={title}
      badge={badge}
      className={className}
      controls={dropdownItems}
      footer={footer}
      withoutControls={!isDropdownVisible}
    >
      {children}
    </CustomReportCard>
  );
};

export default ReportCard;
