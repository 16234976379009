import { PlaceDetails } from "./types";
import { forEach, includes } from "lodash";

class PlacesAPI {
  container: HTMLDivElement;
  placesService: google.maps.places.PlacesService;

  constructor() {
    this.container = document.createElement("div");
    this.placesService = new google.maps.places.PlacesService(this.container);
  }

  async getPlaceDetails(placeId: string): Promise<PlaceDetails | null> {
    return new Promise((resolve) => {
      const getDetailsCallback = (
        optional: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          resolve(null);
          return;
        }

        const locationData: PlaceDetails = {
          state: "",
          address: "",
          postcode: "",
          country: "",
          city: "",
        };
        if (optional) {
          forEach(optional.address_components, (item) => {
            if (includes(item.types, "street_number")) {
              locationData.address = item.long_name;
            }

            if (includes(item.types, "route")) {
              locationData.address += ` ${item.long_name}`;
            }

            if (includes(item.types, "administrative_area_level_1")) {
              locationData.state += item.short_name;
            }

            if (includes(item.types, "country")) {
              locationData.country += item.long_name;
            }

            if (includes(item.types, "postal_code")) {
              locationData.postcode += item.long_name;
            }

            if (includes(item.types, "locality")) {
              locationData.city += item.long_name;
            }
          });
        }

        resolve(locationData);
      };

      this.placesService.getDetails(
        {
          placeId: placeId,
        },
        getDetailsCallback
      );
    });
  }
}

const loadService = (): PlacesAPI | null => {
  try {
    return new PlacesAPI();
  } catch (e) {
    return null;
  }
};

export default loadService();
