import { ClaimReal } from "../../../models/claim";
import { Pagination } from "../../../models/pagination";
import {
  PurchaseOrderReal,
  PurchaseOrderReceipt,
  PurchaseOrderStatus,
} from "../../../models/purchaseOrder";
import { Roster } from "../../../models/roster";
import { SupplierDetails } from "../../../models/supplier";
import { VariationReal } from "../../../models/variations";

export enum JobSearchType {
  JOB_PURCHASE_ORDER = "JOB_PURCHASE_ORDER",
  JOB_PURCHASE_ORDER_RECEIPT = "JOB_PURCHASE_ORDER_RECEIPT",
  JOB_VARIATION = "JOB_VARIATION",
  JOB_PROGRESS_CLAIM = "JOB_PROGRESS_CLAIM",
  JOB_ROSTER = "JOB_ROSTER",
}

export type GlobalSearchFilters = {
  type: JobSearchType;
  keyword: string;
  status?: string;
};

export type GlobalSearchPagination = {
  page: number;
  limit: number;
};

export type JobGlobalSearchPayload = {
  filters: GlobalSearchFilters;
  pagination: GlobalSearchPagination;
};

export type JobGlobalSearchResponse = {
  searchJob: Pagination & {
    purchaseOrderReceipts: PurchaseOrderReceipt[];
    progressClaims: ClaimReal[];
    variations: VariationReal[];
    rosters: Roster[];
    purchaseOrders: PurchaseOrderReal[];
  };
};

export type JobGlobalSearchItems = {
  purchaseOrderReceipts: PurchaseOrderReceipt[] | null;
  progressClaims: ClaimReal[] | null;
  variations: VariationReal[] | null;
  rosters: Roster[] | null;
  purchaseOrders: PurchaseOrderReal[] | null;
};

export type CommonOrderListItem = {
  _id: string;
  status: PurchaseOrderStatus;
  reference: string;
  supplier: SupplierDetails | null;
  orderNumber: number;
  claimDate: string;
  createdAt: string;
  date: string;
  subTotal: number;
  GST: number;
  total: number;
  receipts: PurchaseOrderReceipt[];
  invNumber: number;
  variationNumber: number;
  rosterNumber: number;
  dateReceived?: string;
  job: {
    jobNumber: number;
  };
  purchaseOrder: {
    _id: string;
    orderNumber: number;
    status: PurchaseOrderStatus;
    subTotal: number;
    receipts: PurchaseOrderReceipt[];
    createdAt: string;
  };
};
