import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils, ValidationRules } from "../../../utils/yup";

export default function createSupplierSchema(t: TFunction) {
  return yup.object().shape({
    abn: yup
      .string()
      .transform((value) => {
        return /\s/.test(value) ? value.replace(/\s/g, "") : value;
      })
      .matches(/^[0-9]+$/, t("errors.invalidABN"))
      .length(11, t("errors.invalidABN")),
    phone_number: ValidationRules.getPhoneValidationRules(yup, t),
    /*
    .required(
      ValidationMessagesUtils.createRequiredMessage(t, "contacts.primaryPhone")
    )*/
    phone_secondary: ValidationRules.getPhoneValidationRules(yup, t),
    contact_person: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "contacts.contactPerson"
        )
      ),
    email: yup.string().email(),
    /*.required(
        ValidationMessagesUtils.createRequiredMessage(t, "contacts.email")
      )*/
    new_category_name: yup.string(),
    business_name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "contacts.businessName"
        )
      ),
    address: yup.string(),
    address2: yup.string(),
    city: yup.string(),
    state: yup.string(),
    postcode: ValidationRules.getPostcodeRules(yup, t),
    fax_number: yup.string(),
  });
}
