import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { chain, compact, map } from "lodash";

import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import PlansUploadCard from "../../../components/plans/upload-plans/plans-upload-card";
import { PlanFile } from "../../../components/plans/upload-plans/plans-upload-card/PlansTable";
import SelectPagesCard from "../../../components/plans/upload-plans/select-pages-card";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import HeaderActionButton from "../../../components/dashboard/header/HeaderActionButton";
import { canvasesToImages, PageCanvas } from "../../../utils/pdf";
import { CREATE_SALES_QUOTE_FILE } from "../../../graphql/queries/quote/mutations";
import { QuoteFileUploadResponse } from "../../../graphql/types/models/quote";
import { SelectPagesListItemRef } from "../../../components/plans/upload-plans/select-pages-card/SelectPagesListItem";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import UploadSpinner from "../../../components/upload-spinner";
import { useSalesQuoteQuery } from "../../../hooks/queries/useSalesQuoteQuery";
import { loadPDF, loadPDFPages } from "./utils";
import EstimationLocationHeader from "../../header/estimation-location-header";
import "./styles.scss";

type UploadPlansContainerProps = DashboardContextValue & {};

const UploadPlansContainer: React.FC<UploadPlansContainerProps> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedPlans, setSelectedPlans] = React.useState<PlanFile[]>([]);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [documents, setDocuments] = React.useState<{
    [name: string]: PDFDocumentProxy;
  }>({});
  const [documentPages, setDocumentPages] = React.useState<
    Array<PDFPageProxy | null>
  >([]);

  const { salesQuotePlans } = useSalesQuoteQuery(navigationContext?.quote?._id);

  const [uploadSalesQuoteFiles] = useMutation<QuoteFileUploadResponse>(
    CREATE_SALES_QUOTE_FILE
  );

  const handleFileUpload = React.useCallback((uploadedPlans: PlanFile[]) => {
    async function loadDocuments() {
      const documents: { [name: string]: PDFDocumentProxy } = {};
      const documentPages: Array<PDFPageProxy | null> = [];
      const plans: PlanFile[] = [];

      for (const plan of uploadedPlans) {
        const document = await loadPDF(plan);
        if (document) {
          documents[plan.id] = document;
          const pages = await loadPDFPages(document);
          documentPages.push(...pages);
          plans.push(plan);
        }
      }
      setDocuments(documents);
      setSelectedPlans(plans);
      setDocumentPages(documentPages);
    }

    loadDocuments();
  }, []);

  const handleSelectPagesCancel = React.useCallback(() => {
    setSelectedPlans([]);
    setDocuments({});
    setDocumentPages([]);
  }, [setSelectedPlans, setDocuments, setDocumentPages]);

  const handleSelectPagesSubmit = React.useCallback(
    async (
      pages: number[],
      canvasRefs: SelectPagesListItemRef[],
      replacePlans: boolean
    ) => {
      setSubmitting(true);

      if (!documentPages) {
        return;
      }

      try {
        // const pagesProxied = map(pages, (page) => documentPages[page]);
        // const pagesProxiesPrepared = compact(pagesProxied);
        const type = "image/webp";

        const pageCanvas: PageCanvas[] = compact(
          canvasRefs.map((ref) => {
            const page = ref.getPage();
            const canvas = ref.getCanvas();
            if (page && canvas) {
              return {
                page,
                canvas,
              };
            }
            return null;
          })
        );
        const imagesBlobs = await canvasesToImages(pageCanvas, 0.5, type);
        const indexOffset = salesQuotePlans.length;
        const preparedFiles = map(imagesBlobs, ({ type, size }, index) => ({
          name: `Plan_Page_${indexOffset + index}.${type.split("/")[1]}`,
          type: type,
          size,
        }));

        const fileData = await uploadSalesQuoteFiles({
          variables: {
            salesQuoteId: navigationContext?.quote?._id,
            files: preparedFiles,
            replaceFiles: replacePlans,
          },
        });

        const fileDescriptors = fileData.data;

        const uploadPromises = chain(
          fileDescriptors?.uploadSalesQuoteFiles?.files
        )
          .filter((f) => !!f.upload_url)
          .map((file, index) => {
            return fetch(file.upload_url, {
              method: "PUT",
              body: imagesBlobs[index],
              headers: new Headers({
                "Content-Type": imagesBlobs[index].type,
              }),
            });
          })
          .value();

        await Promise.all(uploadPromises);
      } catch (e) {
        setSubmitting(false);
        return;
      }

      setSubmitting(false);
      history.push(
        `/quotes/${navigationContext?.quote?._id}/calibration-scale`
      );
    },
    [documentPages, navigationContext, salesQuotePlans, uploadSalesQuoteFiles]
  );

  const handleSkipTakeOff = React.useCallback(() => {
    history.push(`/quotes/${navigationContext?.quote?._id}/costing`);
  }, [history]);

  return (
    <Container fluid className="p-0 m-0">
      <Helmet title={t("navigation.quotesSection.plans")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.PLANS} />
      <EstimationLocationHeader>
        <HeaderActionButton
          onClick={handleSkipTakeOff}
          icon="redo"
          width="195px"
        >
          {t("plansSection.skipTakeOff")}
        </HeaderActionButton>
      </EstimationLocationHeader>
      <Row>
        <Col lg={4} xs={12}>
          <PlansUploadCard
            salesQuoteId={navigationContext?.quote?._id}
            onPlanFileUpload={handleFileUpload}
            isDisabled={!!selectedPlans[0]}
          />
        </Col>
        <Col lg={8} xs={12}>
          {documentPages.length ? (
            <SelectPagesCard
              isSubmitting={isSubmitting}
              pages={documentPages}
              hasPlans={!!salesQuotePlans.length}
              onSubmit={handleSelectPagesSubmit}
              onCancel={handleSelectPagesCancel}
              sectionMessage={t("plansSection.uploadPlansTitle")}
            />
          ) : (
            <CardPlaceholder />
          )}
        </Col>
      </Row>
      <UploadSpinner show={isSubmitting} text={t("plansSection.pleaseWait")} />
    </Container>
  );
};

export default withDashboardContext(UploadPlansContainer);
