import React from "react";
import { useQuery } from "@apollo/client";
import { LIST_SUPPLIERS } from "../../graphql/queries/supplier/queries";
import { SupplierListResponse } from "../../graphql/types/models/supplier";

export function useSuppliersQuery() {
  const { data: suppliersData } = useQuery<SupplierListResponse>(
    LIST_SUPPLIERS
  );

  const suppliers = React.useMemo(() => suppliersData?.listSuppliers || [], [
    suppliersData,
  ]);

  return { suppliers };
}
