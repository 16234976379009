import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/entry.webpack";
import { map, range, reverse } from "lodash";
import HeaderActionButton from "../../dashboard/header/HeaderActionButton";
import "./styles.scss";
import Icon from "../../icons/Icon";
import { printUrlPdf } from "../../../utils/pdf";

type PdfViewerModalProps = {};

const SCALE_STEP = 0.2;
const MIN_SCALE = 0.2;
const MAX_SCALE = 10;
const DEFAULT_SCALE_VALUE = 1.2;

export type PdfViewerModalRef = {
  show: (show: boolean, { url, name }: { url: string; name: string }) => void;
};

const PdfViewerModal: React.FC<PdfViewerModalProps> = ({}, ref) => {
  const { t } = useTranslation();

  const [shouldShow, setShow] = React.useState(false);
  const [shouldShowSidebar, setShowSidebar] = React.useState(false);
  const [fileData, setFileData] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [pages, setPages] = useState<number[]>([]);
  const [scale, setScale] = useState<number>(DEFAULT_SCALE_VALUE);
  const [width, setWidth] = useState<number>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const previewRef = React.useRef<HTMLDivElement>(null);
  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const observerRef = React.useRef<IntersectionObserver | null>(null);

  React.useImperativeHandle(ref, () => ({
    show: (show: boolean, { url, name }: { url: string; name: string }) => {
      setShow(show);
      setFileData({ url, name });
    },
  }));

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const pageNumber = Number(
          entry.target.getAttribute("data-page-number")
        );
        setCurrentPage(pageNumber);
        const sidebarItem = sidebarRef.current?.querySelector(
          `[data-page-number="${pageNumber}"]`
        );
        if (sidebarItem) {
          setTimeout(
            () =>
              sidebarItem.scrollIntoView({
                block: "center",
              }),
            150
          );
        }
      }
    });
  };

  React.useEffect(() => {
    const previewConainer = previewRef.current;
    if (previewConainer) {
      observerRef.current = new IntersectionObserver(handleIntersection, {
        root: previewConainer,
        rootMargin: "0px",
        threshold: 0.5,
      });

      const previewElements = previewConainer.querySelectorAll(".pdf-viewer");
      const arr = Array.from(previewElements);
      reverse(arr).forEach((element) => {
        observerRef.current?.observe(element);
      });
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [pages]);

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);

    const previewElement = previewRef.current?.querySelector(
      `[data-page-number="${pageNumber}"]`
    );
    if (previewElement) {
      setTimeout(() => previewElement.scrollIntoView({ block: "start" }), 200);
    }
  };

  const renderPagePreviews = () => {
    const previews = [];
    for (let i = 1; i <= pages.length; i++) {
      previews.push(
        <div
          key={i}
          className={`page-preview ${i === currentPage ? "active" : ""}`}
          onClick={() => {
            handlePageClick(i);
            setCurrentPage(i);
          }}
        >
          <Page pageNumber={i} width={150} />
        </div>
      );
    }
    return previews;
  };

  const handleClose = React.useCallback(() => {
    setScale(DEFAULT_SCALE_VALUE);
    setShow(false);
    setShowSidebar(false);
    setCurrentPage(1);
    setPages([]);
    setFileData(null);
  }, []);

  const onDocumentLoadSuccess = React.useCallback(
    ({ numPages }) => {
      setPages(range(1, numPages + 1));
    },
    [setPages]
  );

  const handleZoomIn = React.useCallback(() => {
    const newScale = scale + SCALE_STEP;

    if (newScale > MAX_SCALE) {
      return;
    }

    setScale(newScale);
    setWidth(undefined);
  }, [scale]);

  const handleZoomOut = React.useCallback(() => {
    const newScale = scale - SCALE_STEP;

    if (newScale < MIN_SCALE) {
      return;
    }

    setWidth(undefined);
    setScale(newScale);
  }, [scale]);

  const handlePrint = React.useCallback(async () => {
    if (!fileData) return;
    printUrlPdf(fileData.url);
  }, [fileData]);

  const handleShowSideBar = React.useCallback(() => {
    if (!fileData) return;
    setShowSidebar(!shouldShowSidebar);
  }, [fileData, shouldShowSidebar]);

  const handleDownload = React.useCallback(() => {
    if (!fileData) return;

    window.open(fileData.url, "_self");
  }, [fileData]);

  const handleExpand = React.useCallback(() => {
    if (!ref.current) {
      return;
    }

    if (!width) {
      setWidth(ref.current?.clientWidth);
      setScale(1);
    } else {
      setWidth(undefined);
    }
  }, [width]);

  return (
    <Modal
      backdrop="static"
      onHide={handleClose}
      bsPrefix="modal"
      dialogClassName="left-modal-container pdf-viewer-modal"
      role="side-dialog"
      show={shouldShow}
    >
      <Modal.Header className="header">
        <Modal.Title className="title">{fileData?.name}</Modal.Title>

        <div className="d-flex align-items-center">
          <HeaderActionButton
            icon="preview"
            outlined
            onClick={handleShowSideBar}
            iconClassName="preview-icon"
          />
          <HeaderActionButton icon="zoom_out" onClick={handleZoomOut} />
          <HeaderActionButton icon="zoom_in" onClick={handleZoomIn} />
          <HeaderActionButton icon="Expand" svg onClick={handleExpand} />
          <HeaderActionButton icon="print" outlined onClick={handlePrint}>
            {t("common.print")}
          </HeaderActionButton>
          <HeaderActionButton
            icon="file_download"
            outlined
            onClick={handleDownload}
          >
            {t("common.download")}
          </HeaderActionButton>
          <Button className="close" onClick={handleClose}>
            <Icon name="close" />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="body flex-column">
        <Container className="generic-form-body" fluid>
          {fileData && (
            <Document
              className="pdf-row"
              file={fileData.url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <div className="spinner-container">
                  <Spinner
                    className="spinner-border-accent spinner"
                    animation="border"
                  />
                </div>
              }
            >
              {shouldShowSidebar && (
                <div className="pdf-sidebar" ref={sidebarRef}>
                  {renderPagePreviews()}
                </div>
              )}
              <div className="pdf-col">
                <div className="pdf-preview" ref={previewRef}>
                  {map(pages, (page) => (
                    <Page
                      pageNumber={page}
                      scale={scale}
                      className="pdf-viewer"
                      width={width || 600}
                      key={page}
                    />
                  ))}
                </div>
              </div>
            </Document>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default forwardRef(PdfViewerModal);
