import { gql } from "@apollo/client";

export const CHECKLIST_FRAGMENT = gql`
  fragment ChecklistFragment on Checklist {
    _id
    entityId
    entityType
    updatedAt
    createdAt
    task
    dueDate
    completed
    completedByType
    assignedToType
    assignedTo {
      ... on User {
        _id
        fullName
        first_name
        last_name
      }
    }
    completedBy {
      ... on User {
        _id
        fullName
        first_name
        last_name
      }
    }
    category {
      _id
      name
    }
    order
    is_deleted
  }
`;

export const CHECKLIST_CATEGORY_FRAGMENT = gql`
  fragment ChecklistCategoryFragment on ChecklistCategory {
    _id
    name
    checklist {
      ...ChecklistFragment
    }
    entityType
    entityId
    order
    is_deleted
  }
  ${CHECKLIST_FRAGMENT}
`;
