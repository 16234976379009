import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { TFunction } from "i18next";
import { BulkJobsScheduleCategoryPayload } from "../../../models/job";

const createImportScheduleCostingItemsFields = (
  t: TFunction
): GenericFormFields<BulkJobsScheduleCategoryPayload> => {
  return {
    items: {
      tableTitle: t("schedule.costingItems"),
      isDisabled: true,
      row: [
        {
          width: "250px",
          type: "text",
          label: t("schedule.category"),
          placeholder: t("schedule.category"),
          valueKey: "category",
          inputProps: {
            type: "text",
            readOnly: true,
          },
        },
        {
          width: "250px",
          type: "text",
          label: t("schedule.item"),
          placeholder: t("schedule.item"),
          valueKey: "name",
          inputProps: {
            type: "text",
            required: true,
          },
        },
      ],
    },
  };
};

export default createImportScheduleCostingItemsFields;
