import { map } from "lodash";
import { ListJobContactsResponse } from "../models/job-contact";

export const updateJobContactsList = (
  contactId: string,
  createdAccess: { _id: string } | null
) => (previousQueryResult: ListJobContactsResponse) => {
  return {
    jobContacts: map(previousQueryResult.jobContacts, (contact) => {
      if (contact.contact?._id === contactId) {
        return {
          ...contact,
          clientAccess: createdAccess,
        };
      }

      return contact;
    }),
  };
};
