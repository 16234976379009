import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { compact, find, get, map, reduce, round } from "lodash";
import moment from "moment";
import Totals from "../../costing/total";
import DashboardCardField from "../../dashboard/card/DashboardCardField";
import {
  ClaimView,
  ClaimItem,
  ClaimReal,
  ClaimReceipt,
  ClaimStatusesEnum,
  ClaimVariation,
} from "../../../models/claim";
import { calcGST, GST_PERCENT } from "../../../utils/calculations";
import CardTable from "../../dashboard/table-card/CardTable";
import {
  TableCardData,
  TableRowActionData,
} from "../../dashboard/table-card/utils";
import { formatQuoteNumber, getFullName } from "../../../utils/text";
import { JobType } from "../../../models/job";
import CustomReportCard from "../../dashboard/report-card/custom-report-card";
import {
  DATE_FORMAT_DATETIME_NUMERIC_24,
  DATE_FORMAT_DATE_STRING,
} from "../../../constants/dates";
import { useViewInJob } from "../../../hooks/useViewInJobs";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";
import { useMutation } from "@apollo/client";
import { DeleteClaimReceiptResponse } from "../../../graphql/types/models/job-claim";
import { DELETE_CLAIM_RECEIPT } from "../../../graphql/queries/job-claim/mutation";
import { notify } from "../../notification";
import { getClaimBadgeVariant } from "../../../containers/jobs/job-claims/utils";

type ClaimCardProps = {
  claim: ClaimReal;
  jobType?: JobType;
  onSend?: (id: string) => void;
  onDelete?: (id: string) => void;
  onEdit?: () => void;
  onPrint?: (id: string, printView?: ClaimView) => void;
  onReceive?: (id: string) => void;
  onSync?: (id: string) => void;
  syncProvider?: string;
  withoutControls?: boolean;
  isGlobalView?: boolean;
};

const ClaimCard: React.FC<ClaimCardProps> = (props) => {
  const { t } = useTranslation();
  const [isEditable, setEditable] = useState(false);
  const deleteClaimReceiptRef = React.useRef<ConfirmDialogRef>(null);
  const {
    claim,
    jobType,
    onPrint,
    onSend,
    onEdit,
    onDelete,
    onReceive,
    onSync,
    syncProvider,
    withoutControls,
    isGlobalView = false,
  } = props;

  const {
    _id,
    invNumber,
    status,
    amount,
    description,
    richComment,
    claimDate,
    dueDate,
    contact,
    variations,
    items,
    job,
    receipts,
    defaultView,
  } = claim;

  const [deleteClaimReceipt] = useMutation<DeleteClaimReceiptResponse>(
    DELETE_CLAIM_RECEIPT,
    {
      onCompleted: () => {
        notify({
          title: t("claims.deleteClaimReceipt"),
          content: t("claims.success.deleteClaimReceipt"),
        });
      },
      onError: (e) => {
        notify({
          error: true,
          title: t("claims.deleteClaimReceipt"),
          content: get(e, "message", t("claims.errors.deleteClaimReceipt")),
        });
      },
    }
  );

  useEffect(() => {
    if (status == "DRAFT" || status == "SENT") setEditable(true);
    else setEditable(false);
  }, [status, setEditable]);

  const isReceivable = React.useMemo(() => {
    return status !== ClaimStatusesEnum.RECEIVED;
  }, [status]);

  const hasReceipt = React.useMemo(() => {
    return receipts && receipts.length > 0;
  }, [receipts]);

  const contactName = getFullName(contact);

  const variationsTotal = React.useMemo(() => {
    return reduce(
      variations,
      (acc, variation) => {
        return acc + variation.subTotal;
      },
      0
    );
  }, [variations]);

  const gst = React.useMemo(
    () => calcGST(variationsTotal + amount, GST_PERCENT),
    [variationsTotal, amount]
  );

  const handleSend = React.useCallback(() => {
    onSend && onSend(_id);
  }, [onSend, _id]);

  const handleDelete = React.useCallback(() => {
    onDelete && onDelete(_id);
  }, [onDelete, _id]);

  const handlePrint = React.useCallback(() => {
    onPrint && onPrint(_id);
  }, [onPrint, _id]);

  const handlePrintItems = React.useCallback(() => {
    onPrint && onPrint(_id, ClaimView.ITEMS);
  }, [onPrint, _id]);

  const handleReceive = React.useCallback(() => {
    onReceive && onReceive(_id);
  }, [onReceive, _id]);

  const handleSync = React.useCallback(() => {
    onSync && onSync(_id);
  }, [onSync, _id]);

  const handleEdit = React.useCallback(() => {
    onEdit && onEdit();
  }, [onEdit]);

  const handleDeleteClaimReceipt = React.useCallback(
    (receipt: ClaimReceipt) => {
      deleteClaimReceiptRef.current?.show(true, () => {
        deleteClaimReceipt({
          variables: {
            jobId: claim?.job?._id,
            progressClaimId: claim?._id,
            receiptId: receipt._id,
          },
        });
      });
    },
    [claim]
  );

  const claimTable = React.useMemo<TableCardData<ClaimReal>>(() => {
    return {
      columns: [
        {
          valueKey: "description",
          title: t("claims.description"),
        },
        {
          valueKey: "amount",
          title: t("costing.totalEx"),
          className: "text-right",
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: [
        {
          cells: {
            ...claim,
            amount:
              Number(claim.amount || 0) - Number(claim.variationAmount || 0),
          },
        },
      ],
    };
  }, [claim]);

  const claimVariationsTable = React.useMemo<
    TableCardData<ClaimVariation>
  >(() => {
    return {
      columns: [
        {
          valueKey: "variationNumber",
          title: t("variations.variationNumber"),
          formatValue: (row: any, column: any, value: number) =>
            formatQuoteNumber(value, "V"),
        },
        {
          valueKey: "date",
          title: t("claims.date"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
        {
          valueKey: "name",
          title: t("common.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "subTotal",
          title: t("costing.totalEx"),
          className: "text-right",
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(variations, (variation) => ({
        cells: variation,
      })),
    };
  }, [variations]);

  const claimItemsTable = React.useMemo<TableCardData<ClaimItem>>(() => {
    if (jobType === JobType.COST_PLUS) {
      return {
        columns: [
          {
            valueKey: "name",
            title: t("costing.item"),
            formatValue: (row: any, column: any, value: string) => value,
          },
          {
            valueKey: "amount",
            title: t("costing.cost"),
            formatValue: (row: any, column: any, value: number) =>
              !isNaN(value) ? t("common.currency", { amount: value }) : "",
          },
          {
            valueKey: "markupSubTotal",
            title: t("claims.builderMargin"),
            formatValue: (row: any, column: any, value: number) =>
              !isNaN(value) ? t("common.currency", { amount: value }) : "",
          },
          {
            valueKey: "markupGST",
            title: t("costing.gst"),
            formatValue: (row: any, column: any, value: number) =>
              !isNaN(value) ? t("common.currency", { amount: value }) : "",
          },
          {
            valueKey: "markupTotal",
            title: t("claims.totalInc"),
            formatValue: (row: any, column: any, value: number) =>
              !isNaN(value) ? t("common.currency", { amount: value }) : "",
          },
        ],
        rows: map(items, (item) => ({
          cells: {
            ...item,
            markupSubTotal: Number(item.markupSubTotal || 0) - item.amount,
          },
          subRows: map(item.purchaseOrderReceipt?.items, (item) => ({
            cells: ({
              ...item,
              amount: item.cost * item.quantity,
            } as any) as ClaimItem,
          })),
        })),
      };
    }

    return {
      columns: [
        {
          valueKey: "name",
          title: t("costing.item"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "total",
          title: t("costing.totalEx"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "amount",
          title: t("claims.claimAmount"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "percentage",
          title: t("claims.claimPercent"),
          formatValue: (row: any, column: any, value: number) =>
            t("claims.claimedPercent", { percentage: value }),
        },
        {
          valueKey: "amountToDate",
          title: t("claims.claimedToDate"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "remaining",
          title: t("claims.remaining"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(items, (item) => ({
        cells: item,
      })),
    };
  }, [jobType, items]);

  const claimRowActions: TableRowActionData<ClaimItem>[] = React.useMemo(
    () => [
      {
        icon: "comment",
        dropdownId: "note",
        onClick: () => {},
        shouldRender: (row) => !!row.note,
        tooltip: (row) => row.note,
      },
    ],
    []
  );

  const receiptsData = React.useMemo<TableCardData<ClaimReceipt>>(
    () => ({
      columns: [
        {
          valueKey: "dateReceived",
          title: t("claims.receivedDate"),
          formatValue: (row: any, column: any, value: string) =>
            value
              ? moment(value).format(DATE_FORMAT_DATE_STRING)
              : t("common.na"),
        },
        {
          valueKey: "reference",
          title: t("common.reference"),
        },
        {
          valueKey: "amount",
          title: t("claims.amount"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(receipts, (receipt) => ({
        cells: receipt,
      })),
    }),
    [receipts]
  );

  const receiptsRowActions: TableRowActionData<ClaimReceipt>[] = React.useMemo(
    () => [
      {
        icon: "comment",
        dropdownId: "view",
        tooltip: (row) => row.note,
        shouldRender: (row) => !!row.note,
      },
      {
        icon: "delete",
        dropdownId: "delete",
        onClick: (row) => {
          const receipt = find(receipts, { _id: row._id });
          if (receipt) {
            handleDeleteClaimReceipt(receipt);
          }
        },
      },
    ],
    [receipts, handleDeleteClaimReceipt]
  );

  const { buttons } = useViewInJob({
    isGlobalView: isGlobalView,
    jobId: job?._id,
    orderId: _id,
    path: "progress-claims",
  });

  const renderTotal = React.useCallback(() => {
    let amount = 0;
    let gst = 0;
    let retention = 0;
    let retentionPercent = 0;
    let total = 0;

    if (claim.invoiceSubTotal) {
      //jobType === JobType.COST_PLUS) {
      amount = claim.invoiceSubTotal;
      gst = claim.invoiceGST;
      total = claim.invoiceTotal;
      retention = claim.retainedAmount;
      retentionPercent = round((retention / (amount + retention)) * 100, 2);
    } else {
      // manual calculation (legacy as of 02.02.23)
      amount = claim.amount;
      if (claim.retainedAmount) {
        amount += Number(claim.retainedAmount);
        retention = claim.retainedAmount;
        retentionPercent = round((retention / amount) * 100, 2);
      }

      const variationsWithoutGST = reduce(
        claim.variations,
        (total, variation) => {
          return total + (!variation.GST ? variation.subTotal : 0);
        },
        0
      );

      gst = calcGST(amount - variationsWithoutGST - retention, GST_PERCENT);
      total = amount - retention + gst;
    }

    return (
      <Totals
        title={t("claims.amount")}
        gst={gst}
        total={total}
        subtotal={amount}
        retention={retention}
        retentionPercent={retentionPercent}
      />
    );
  }, [jobType, claim, gst, amount]);

  const renderReceiptTotal = React.useCallback(() => {
    return (
      <div className="total">
        <div className="field-text field-text--underlined total-title">
          <div className="d-flex justify-content-between align-items-center">
            <div>{t("claims.balance")}</div>
          </div>
        </div>
        <div className="item">
          <div>{t("claims.receivedTotal")}</div>
          <div>
            {t("common.currency", { amount: claim.receivedTotal || 0 })}
          </div>
        </div>
        <div className="item">
          <div>{t("claims.outstandingAmount")}</div>
          <div>
            {t("common.currency", { amount: claim.outstandingTotal || 0 })}
          </div>
        </div>
      </div>
    );
  }, [claim]);

  const dropdownItems = React.useMemo(() => {
    const controls = compact([
      {
        id: "print",
        label: t("common.print"),
        icon: "print",
        outlined: true,
        onClick: handlePrint,
      },
      defaultView === ClaimView.CATEGORIES
        ? {
            id: "printItems",
            label: t("claims.printItems"),
            icon: "print",
            outlined: true,
            onClick: handlePrintItems,
          }
        : null,
      {
        id: "mail",
        label: t("common.send"),
        icon: "mail",
        outlined: true,
        onClick: handleSend,
      },
    ]);
    if (isReceivable) {
      controls.push({
        id: "check",
        label: t("orders.receiveOrder"),
        icon: "check",
        outlined: true,
        onClick: handleReceive,
      });
    }
    if (isEditable) {
      controls.push({
        id: "edit",
        label: t("common.edit"),
        icon: "edit",
        outlined: true,
        onClick: handleEdit,
      });
      controls.push({
        id: "delete",
        label: t("common.delete"),
        icon: "delete",
        outlined: true,
        onClick: handleDelete,
      });
    }
    if (status !== ClaimStatusesEnum.DRAFT) {
      onSync &&
        controls.push({
          id: "sync",
          label: t("integrations.syncWithProvider", {
            provider: t(`integrations.${syncProvider?.toLowerCase()}`),
          }),
          icon: "sync",
          outlined: true,
          onClick: handleSync,
        });
    }
    return controls;
  }, [
    onSync,
    handlePrint,
    handlePrintItems,
    handleSend,
    handleDelete,
    handleReceive,
    onEdit,
    onSync,
    handleSync,
    syncProvider,
    status,
    defaultView,
    isEditable,
  ]);

  return (
    <CustomReportCard
      controls={dropdownItems}
      title={formatQuoteNumber(invNumber, "PC")}
      badges={compact([
        claim.isOverdue
          ? {
              variant: "danger",
              label: t(`claims.statuses.OVERDUE`),
            }
          : null,
        {
          variant: getClaimBadgeVariant(claim.status),
          label: t(`claims.statuses.${claim.status}`),
        },
      ])}
      withoutControls={withoutControls}
      buttons={buttons}
    >
      <Row className="main-row">
        <Col lg={3} xs={12} className="report-col">
          <DashboardCardField
            title={t("claims.contact")}
            content={contactName || ""}
          />
        </Col>
        <Col lg={3} xs={12} className="report-col">
          <DashboardCardField
            title={t("claims.invoiceDate")}
            content={moment(claimDate).format(DATE_FORMAT_DATE_STRING)}
          />
        </Col>
        <Col lg={3} xs={12} className="report-col">
          <DashboardCardField
            title={t("claims.dueDate")}
            content={moment(dueDate).format(DATE_FORMAT_DATE_STRING)}
          />
        </Col>
        <Col lg={3} xs={12} className="report-col">
          {syncProvider && (
            <DashboardCardField
              title={t("integrations.lastSyncDate")}
              content={
                claim.externalLastSyncDate
                  ? moment(claim.externalLastSyncDate).format(
                      DATE_FORMAT_DATETIME_NUMERIC_24
                    )
                  : t("common.na")
              }
            />
          )}
        </Col>
      </Row>
      <Row className="main-row table-row">
        <CardTable rowCount table={claimTable} showCountTitle={true} />
      </Row>
      {variations && variations.length > 0 && (
        <Row className="main-row table-row">
          <CardTable
            rowCount
            table={claimVariationsTable}
            showCountTitle={true}
          />
        </Row>
      )}

      {items && items.length > 0 && (
        <Row className="main-row table-row">
          <CardTable
            rowCount
            table={claimItemsTable}
            rowActions={claimRowActions}
            showCountTitle={true}
          />
        </Row>
      )}

      <Row className="row--big">
        <Col lg={7} xs={12} className="report-col--big">
          <DashboardCardField
            title={t("claims.comments")}
            placeholder={t("placeholders.noComments")}
            htmlContent={richComment?.html}
            height="180px"
          />
        </Col>
        <Col lg={5} xs={12} className="report-col--big">
          {renderTotal()}
          {renderReceiptTotal()}
        </Col>
      </Row>

      {hasReceipt && (
        <Row className="row--big">
          <Col lg={12} xs={12} className="report-col--big">
            <div className="">
              <div className="field-text field-text--underlined table-title">
                {t("orders.receipts")}
              </div>

              <CardTable
                rowCount
                table={receiptsData}
                showCountTitle={true}
                rowActions={receiptsRowActions}
              />
            </div>
          </Col>
        </Row>
      )}
      <ConfirmDialog
        ref={deleteClaimReceiptRef}
        title={t("claims.deleteClaimReceipt")}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("claims.deleteClaimReceiptMessage")}
        </span>
      </ConfirmDialog>
    </CustomReportCard>
  );
};

export default ClaimCard;
