import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";
import ToggleInput from "../../generic-form/inputs/toggle";

type TotalsProps = {
  total?: number;
  gst?: number;
  subtotal?: number;
  grandTotal?: number;
  retention?: number;
  retentionPercent?: number;
  difference?: number;
  markupPercent?: number;
  markup?: number;
  title?: string;
  canToggleGST?: boolean;
  hasGST?: boolean;
  costsHaveGST?: boolean;
  showOnlyTotal?: boolean;
  onToggleGST?: (value: boolean) => void;
};

const Totals: React.FC<TotalsProps> = (props) => {
  const {
    gst,
    total,
    subtotal,
    grandTotal,
    retention,
    retentionPercent,
    title,
    difference,
    markupPercent,
    markup,
    canToggleGST,
    hasGST = true,
    onToggleGST,
    costsHaveGST,
    showOnlyTotal,
  } = props;

  const { t } = useTranslation();

  const hasMarkup = React.useMemo(() => !!markup && !!markupPercent, [
    markup,
    markupPercent,
  ]);

  const hasRetention = React.useMemo(() => !!retention && !!retentionPercent, [
    retention,
    retentionPercent,
  ]);

  const handleToggleGST = React.useCallback(
    (value: boolean) => onToggleGST?.(!value),
    [onToggleGST]
  );

  return (
    <div className="total">
      {(title || canToggleGST || !hasGST) && (
        <div className="field-text field-text--underlined total-title">
          <div className="d-flex justify-content-between align-items-center">
            <div>{title}</div>
            {canToggleGST && onToggleGST && (
              <ToggleInput
                name="gstFree"
                label={t("costing.gstFree")}
                value={!hasGST}
                onChange={handleToggleGST}
              />
            )}
            {!hasGST && !canToggleGST && <i>{t("costing.gstFree")}</i>}
          </div>
        </div>
      )}
      <div>
        {!showOnlyTotal && (
          <div className="item">
            <div>{t("costing.subTotal")}</div>
            <div>{t("common.currency", { amount: subtotal || 0 })}</div>
          </div>
        )}
        {hasRetention && (
          <div className="item">
            <div>
              {t("costing.retentionPercent", { percent: retentionPercent })}
            </div>
            <div>{t("common.currency", { amount: -1 * (retention || 0) })}</div>
          </div>
        )}
        {hasMarkup && (
          <div className="item">
            <div>{t("costing.markupPercent", { percent: markupPercent })}</div>
            <div>{t("common.currency", { amount: markup || 0 })}</div>
          </div>
        )}
        {hasMarkup && (
          <div className="item">
            <div>{t("costing.total")}</div>
            <div>{t("common.currency", { amount: total || 0 })}</div>
          </div>
        )}
        {!showOnlyTotal && (
          <div className="item">
            <div>{t("costing.gst")}</div>
            <div>
              {t("common.currency", {
                amount: (hasGST || costsHaveGST ? gst : 0) || 0,
              })}
            </div>
          </div>
        )}
        {hasMarkup ? (
          <div className="item">
            <div>{t("costing.grandTotal")}</div>
            <div>{t("common.currency", { amount: grandTotal || 0 })}</div>
          </div>
        ) : (
          <div className="item">
            <div>{t("costing.total")}</div>
            <div>
              {t("common.currency", {
                amount: (hasGST ? total : subtotal) || 0,
              })}
            </div>
          </div>
        )}
        {isNumber(difference) && (
          <div className="item">
            <div>{t("costing.difference")}</div>
            <div>{t("common.currency", { amount: difference || 0 })}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Totals;
