import { TFunction } from "i18next";
import { concat, range, map } from "lodash";
import { StateManager } from "react-select/src/stateManager";
import { TakeoffListItem } from "../../models/take-off";

import { GenericFormFields } from "../generic-form/GenericFormBody";
import { convertQuantity } from "../plans/take-off-plan-viewer/utils";
import { CalcInput } from "./CalculatorInput";

export const TEXT_AREA_ROWS: number = 3;

export enum Rounding {
  UP = "up",
  DOWN = "down",
  NONE = "none",
  NEAREST = "nearest",
}

export type CalcOptions = {
  takeOff?: string;
  wastage: string;
  rounding: Rounding;
};

const getWastageOptions = (t: TFunction) => {
  const options = range(5, 105, 5).map((v) => ({
    label: t("costing.wastageOption", { percent: v }),
    value: v.toString(),
  }));

  return concat([{ label: t("costing.noWastage"), value: "0" }], options);
};

const getTakeoffOptions = (takeoffs: TakeoffListItem[]) => {
  return map(takeoffs, (takeoff: TakeoffListItem) => {
    const { quantity, UOM } = convertQuantity(takeoff.quantity, takeoff.UOM);
    return {
      label: `${takeoff.name} (${quantity.toLocaleString("en-us", {
        maximumFractionDigits: 2,
      })} ${UOM})`,
      value: takeoff._id,
    };
  });
};

export const createCalcFields = (
  t: TFunction,
  handleRoundingChange: (value: string | string[]) => void,
  handleWastageChange: (value: string | string[]) => void,
  handleTakeOffChange: (value: string | string[]) => void,
  roundingRef: React.Ref<StateManager>,
  wastageRef: React.Ref<StateManager>,
  takeoffs: TakeoffListItem[],
  handleCreateTakeoff?: () => void
): GenericFormFields<CalcOptions> => {
  const fields = {
    takeOff: {
      type: "default-select",
      label: t("takeOffSection.takeoff"),
      placeholder: t("common.choose"),
      valueKey: "takeOff",
      selectProps: {
        options: getTakeoffOptions(takeoffs),
        onValueChange: handleTakeOffChange,
      },
      append: handleCreateTakeoff
        ? {
            icon: "add",
            onClick: handleCreateTakeoff,
          }
        : null,
    },
    wastage: {
      type: "default-select",
      label: t("common.wastage"),
      placeholder: t("calculator.noWastage"),
      valueKey: "wastage",
      selectProps: {
        options: getWastageOptions(t),
        selectRef: wastageRef,
        onValueChange: handleWastageChange,
      },
    },
    rounding: {
      type: "default-select",
      label: t("common.rounding"),
      placeholder: "",
      valueKey: "rounding",
      selectProps: {
        onValueChange: handleRoundingChange,
        selectRef: roundingRef,
        options: [
          {
            value: Rounding.NONE,
            label: t("calculator.noRounding"),
          },
          {
            value: Rounding.UP,
            label: t("calculator.up"),
          },
          {
            value: Rounding.DOWN,
            label: t("calculator.down"),
          },
          {
            value: Rounding.NEAREST,
            label: t("calculator.nearest"),
          },
        ],
      },
    },
  } as GenericFormFields<CalcOptions>;

  if (!takeoffs.length && !handleCreateTakeoff) {
    delete fields.takeOff;
  }

  return fields;
};

export type CalcBag = {
  rounding: Rounding;
  wastage: number;
  takeOff: TakeoffListItem | null;
  quantity?: string;
};

export type CalcSubmitFunction = (value: number, calcBag: CalcBag) => void;

export enum CalcActions {
  REMOVE = "REMOVE",
  CLEAR = "CLEAR",
}

export const CALC_INPUT_CONFIG: CalcInput = {
  columns: [
    [
      {
        text: "C",
        value: CalcActions.CLEAR,
      },
      {
        text: "7",
        value: "7",
      },
      {
        text: "4",
        value: "4",
      },
      {
        text: "1",
        value: "1",
      },
      {
        text: "0",
        value: "0",
      },
    ],
    [
      {
        icon: "backspace",
        value: CalcActions.REMOVE,
      },
      {
        text: "8",
        value: "8",
      },
      {
        text: "5",
        value: "5",
      },
      {
        text: "2",
        value: "2",
      },
      {
        text: ".",
        value: ".",
      },
    ],
    [
      {
        text: "(",
        value: "(",
      },
      {
        text: "9",
        value: "9",
      },
      {
        text: "6",
        value: "6",
      },
      {
        text: "3",
        value: "3",
      },
      {
        text: "+",
        value: "+",
      },
    ],
    [
      {
        text: ")",
        value: ")",
      },
      {
        text: "/",
        value: "/",
      },
      {
        text: "X",
        value: "*",
      },
      {
        text: "-",
        value: "-",
      },
      {
        text: "=",
        value: "=",
        className: "button success btn btn-primary",
      },
    ],
  ],
};

export const normalizeExpression = (expression: string) =>
  expression
    .replace(/x/g, "*")
    .replace(/[^0-9\.\+\-\*\/\(\)]/g, "")
    .replace(/[\(]{2,}/g, "(")
    .replace(/[\)]{2,}/g, ")")
    .replace(/[\*]{2,}/g, "*")
    .replace(/[\+]{2,}/g, "+")
    .replace(/[\-]{2,}/g, "-")
    .replace(/[\.]{2,}/g, ".")
    .replace(/[\/]{2,}/g, "/")
    .replace(/\([\)|\/|\*\+]/g, "(")
    .replace(/\-[\/\*\-\)]/g, "-")
    .replace(/\+[\/\*\-\)]/g, "+")
    .replace(/\.[^0-9]/g, ".")
    .replace(/^[^0-9\.\(]/g, "");
