import React, { useState, useRef } from "react";
import {
  find,
  head,
  isEmpty,
  filter,
  findIndex,
  chain,
  map,
  omit,
  reduce,
  pick,
  uniqBy,
} from "lodash";
import { Helmet } from "react-helmet";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  CostingCategoryListItem,
  DistributeMarkup,
  EnumSalesQuoteCategoryStatus,
  UpdateCostingCategoryPayload,
} from "../../../models/salesQuote";
import Container from "react-bootstrap/Container";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import CostingQuoteCategories from "../../../components/costing/costing-categories";
import TableCard from "../../../components/dashboard/table-card";
import SaveCostingAsTemplateModal, {
  SaveCostingAsTemplateModalRef,
} from "../.../../../../components/building-template/save-costing-as-template-modal";
import SelectCategoryModal from "../.../../../../components/costing/select-category";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import UpdateCostingModal, {
  UpdateCostingModalRef,
} from "../../../components/costing/update-costing-modal";
import QuoteRequestModal, {
  QuoteRequestRef,
  QuoteRequestTableItem,
  QuoteRequestType,
} from "../../../components/quotes/quote-request-modal";
import ImportCostingsFromTemplateModal from "../../../components/quotes/import-from-template";

import {
  CostingTable,
  createCostingTable,
  SalesQuoteCategoryTableItem,
} from "../../../components/costing/utils";
import {
  AddSelectionItemsFromCostingItemsResponse,
  AddSpecItemsFromCostingItemsResponce,
  BulkSalesQuoteCostingItem,
  CreateQuoteCategoryResponse,
  DeleteQuoteCategoryResponse,
  SalesQuoteCategoryListResponse,
  SaveSalesQuoteCategoryItems,
  SaveSalesQuoteCostingBulkResponse,
  UpdateCostingItemAllowance,
  UpdateSalesQuoteCostingCategoryOrderResponse,
  UpdateSalesQuoteCostingCategoryStatusResponse,
} from "../../../graphql/types/models/category";
import {
  ADD_SELECTION_ITEMS_FROM_COSTING_ITEMS,
  ADD_SPEC_FROM_COSTING_ITEMS,
  CREATE_SALES_QUOTE_CATEGORY,
  DELETE_SALES_QUOTE_CATEGORY,
  SAVE_SALES_QUOTE_COSTING_BULK,
  SAVE_UPDATE_SALES_QUOTE_CATEGORY_ITEMS,
  UPDATE_COSTING_ITEMS_ALLOWANCE,
  UPDATE_SALES_QUOTE_CATEGORY_STATUS,
  UPDATE_SALES_QUOTE_COSTING_CATEGORY_ORDER,
} from "../../../graphql/queries/category/mutations";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { LIST_SALES_QUOTE_CATEGORIES } from "../../../graphql/queries/category/queries";
import {
  handleCreateCostingItems,
  handleDeleteCategory,
  handleUpdateStatusCategory,
  prepareCategoryUpdateItem,
} from "../../../graphql/queries/category/utils";
import {
  prepareSalesQuoteItems,
  handleSendQuoteRequest,
  handleUpdateSendQuote,
} from "../../../graphql/queries/quote/utils";
import {
  calcGST,
  calcCategorySubTotal,
  calcTotal,
  calcTotalMarkup,
  GST_PERCENT,
  calcItemTotal,
} from "../../../utils/calculations";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../components/confirm-dialog";
import { notify } from "../../../components/notification";
import { CategoryCostingItem } from "../../../models/costing";
import { CREATE_QUOTE_REQUEST } from "../../../graphql/queries/quote-request/mutations";
import { LIST_TAKEOFF } from "../../../graphql/queries/take-off/queries";
import {
  QuoteRequestResponse,
  QuoteRequestCreatePayload,
  QuoteRequestPayload,
} from "../../../graphql/types/models/quote-request";
import { ListTakeoffItemsResponse } from "../../../graphql/types/models/take-off";
import { getMediaInput } from "../../../utils/transform";
import { uploadFiles } from "../../../utils/files";
import { UOMOption } from "../../../utils/types/options";
import { MEASUREMENT_OPTIONS } from "../../../utils/options";
import { QuoteRequestItemStatus } from "../../../graphql/types/models/quote-request";
import QuoteMarkupModal from "../../../components/quotes/quote-markup-modal";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import {
  EXPORT_SALES_QUOTE_COSTINGS,
  GET_SALES_QUOTE,
} from "../../../graphql/queries/quote/queries";
import {
  ExportSalesQuoteCostingResponse,
  GetSalesQuoteResponse,
} from "../../../graphql/types/models/quote";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import Dropdown from "../../../components/dashboard/dropdown";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";
import EstimationLocationHeader from "../../header/estimation-location-header";
import DashboardActionHeader, {
  ActionButton,
} from "../../../components/dashboard/table-card/DashboardActionHeader";
import Icon from "../../../components/icons/Icon";
import SelectionOptionsModal, {
  SelectionOptionsModalRef,
} from "../../../components/quotes/selection-options-modal";
import { SelectionCategoryItemType } from "../../../graphql/types/models/selections";
import { useQuoteRequestSend } from "../../../hooks/useQuoteRequestSend";
import ListQuoteRequestsModal, {
  ListQuoteRequestsModalRef,
} from "../../../components/quote-requests/list-quote-requests-modal";
import { getQuoteRequestsItems } from "../../jobs/job-costing/utils";
import { useAccountingIntegrationQuery } from "../../../hooks/queries/useAccountingIntegrationQuery";
import { AccountingIntegrationSyncItemType } from "../../../graphql/types/models/integrations";
import "./styles.scss";
import CheckBox from "../../../components/checkbox";

type CostingContainerProps = DashboardContextValue;

const QuoteCosting: React.FC<CostingContainerProps> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();

  const { downloadFile } = useDownloadFile();

  const { integrationProvider, syncItem } = useAccountingIntegrationQuery();

  const salesQuoteId = navigationContext?.quote?._id;
  const markupPercent = navigationContext?.quote?.markupPercent || 0;
  const isLocked = !!navigationContext?.quote?.isLocked;

  const markupModalRef = React.useRef<ModalDisplayRef>(null);
  const listQuoteRequestsRef = React.useRef<ListQuoteRequestsModalRef>(null);
  const selectCategoryRef = React.useRef<ModalDisplayRef>();

  const [
    selectedCategory,
    setCategory,
  ] = useState<CostingCategoryListItem | null>(null);
  const [specItems, setSpecItems] = useState<CategoryCostingItem[]>([]);
  const [selectionItems, setSelectionItems] = useState<CategoryCostingItem[]>(
    []
  );
  const [selectedItems, setSelectedItems] = useState<CategoryCostingItem[]>([]);
  const [quoteRequestItems, setQuoteRequestItems] = useState<
    CategoryCostingItem[]
  >([]);
  const [showPricesIncGst, setShowPricesIncGst] = React.useState(false);
  const [costingStatusDropdown, setCostingStatusDropdown] = useState(false);
  const [
    showImportFromTemplateModal,
    setShowImportFromTemplateModal,
  ] = React.useState(false);

  const quoteRequestRef = useRef<QuoteRequestRef>(null);
  const saveCostingAsTemplateModalRef = useRef<SaveCostingAsTemplateModalRef>(
    null
  );
  const selectionOptionsRef = React.useRef<SelectionOptionsModalRef>(null);
  const updateCostingRef = useRef<UpdateCostingModalRef>(null);

  const [showDeleteConfirmation, setDeleteDialogVisibility] = useState(false);
  const [categoryToDeleteId, setCategoryToDelete] = useState("");
  const [categoriesView, setCategoriesView] = React.useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const estimationsCostingPricesTypeKey = "estimationsCostingPricesTypeKey";
  const {
    storedValue: storedPricesType,
    setStoredValue: setStoredPricesType,
  } = useLocalStorage(estimationsCostingPricesTypeKey, {
    showPricesIncGst,
  });

  const resetAllSelectedItems = React.useCallback(() => {
    setSpecItems([]);
    setSelectedItems([]);
    setQuoteRequestItems([]);
    setSelectionItems([]);
  }, []);

  const handleSync = React.useCallback(() => {
    if (!salesQuoteId) return;
    syncItem(AccountingIntegrationSyncItemType.SALES_QUOTE, salesQuoteId);
  }, [salesQuoteId]);

  const { showSendQuoteRequest, renderSendQuoteRequest } = useQuoteRequestSend({
    onSendComplete: resetAllSelectedItems,
    quoteId: salesQuoteId,
  });

  const { data: salesQuoteData, refetch: refetchSalesQuote } = useQuery<
    GetSalesQuoteResponse
  >(GET_SALES_QUOTE, {
    variables: {
      salesQuoteId: navigationContext?.quote?._id,
    },
  });

  const salesQuote = React.useMemo(() => salesQuoteData?.getSalesQuote, [
    salesQuoteData,
  ]);

  const [createQuoteRequest, { loading: createRequestLoading }] = useMutation<
    QuoteRequestResponse,
    QuoteRequestCreatePayload
  >(CREATE_QUOTE_REQUEST, {
    onCompleted: () => {
      refetchQuoteCategories();
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("quoteRequest.createQuoteRequest"),
        content: error.message,
      });
    },
  });

  const { data: takeOffItems } = useQuery<ListTakeoffItemsResponse>(
    LIST_TAKEOFF,
    {
      variables: {
        salesQuoteId,
      },
    }
  );

  const [updateCostingCategoryOrder] = useMutation<
    UpdateSalesQuoteCostingCategoryOrderResponse
  >(UPDATE_SALES_QUOTE_COSTING_CATEGORY_ORDER);

  const [
    addQuoteCategoryItems,
    { loading: loadingAddQuoteCategoryItems },
  ] = useMutation<SaveSalesQuoteCategoryItems>(
    SAVE_UPDATE_SALES_QUOTE_CATEGORY_ITEMS,
    {
      update: handleCreateCostingItems(salesQuoteId),
      onCompleted: ({ saveSalesQuoteCostingByCategory: category }) => {
        updateCostingRef.current?.show(false);
        setCategory(category);
        refetchSalesQuote();
      },
    }
  );

  const [updateCostingAllowance] = useMutation<UpdateCostingItemAllowance>(
    UPDATE_COSTING_ITEMS_ALLOWANCE,
    {
      onCompleted: () => {
        resetAllSelectedItems();
      },
    }
  );

  const [addSpecItems] = useMutation<AddSpecItemsFromCostingItemsResponce>(
    ADD_SPEC_FROM_COSTING_ITEMS,
    {
      onCompleted: (data) => {
        resetAllSelectedItems();
        notify({
          title: t("costing.specItems"),
          content: t("costing.success.addSpecificationItems"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: t("costing.specItems"),
          content: t("costing.errors.addItems"),
        });
      },
    }
  );

  const [addSelectionItems] = useMutation<
    AddSelectionItemsFromCostingItemsResponse
  >(ADD_SELECTION_ITEMS_FROM_COSTING_ITEMS, {
    onCompleted: (data) => {
      resetAllSelectedItems();
      notify({
        title: t("costing.selectionItems"),
        content: t("costing.success.addSelectionItems"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("costing.selectionItems"),
        content: t("costing.errors.addItems"),
      });
    },
  });

  const [addQuoteCategory] = useMutation<CreateQuoteCategoryResponse>(
    CREATE_SALES_QUOTE_CATEGORY,
    {
      onCompleted: () => {
        refetchQuoteCategories();
        refetchSalesQuote();
      },
      //  update: handleCreateCategory(salesQuoteId),
    }
  );

  const {
    data: quoteCategories,
    loading: categoriesLoading,
    refetch: refetchQuoteCategories,
  } = useQuery<SalesQuoteCategoryListResponse>(LIST_SALES_QUOTE_CATEGORIES, {
    variables: { salesQuoteId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const [deleteCategory, { loading: categoryDeleting }] = useMutation<
    DeleteQuoteCategoryResponse
  >(DELETE_SALES_QUOTE_CATEGORY, {
    update: handleDeleteCategory(salesQuoteId),
  });

  const [
    updateSalesQuoteCostingStatus,
    { loading: categoryStatusUpdating },
  ] = useMutation<UpdateSalesQuoteCostingCategoryStatusResponse>(
    UPDATE_SALES_QUOTE_CATEGORY_STATUS,
    {
      onCompleted: (data) => {
        notify({
          title: t("costing.updateStatusCosting"),
          content: t("costing.success.updateCosting"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: t("costing.updateStatusCosting"),
          content: t("costing.errors.updateCosting"),
        });
      },
      update: handleUpdateStatusCategory(salesQuoteId),
    }
  );

  const [saveCostingItems] = useMutation<SaveSalesQuoteCostingBulkResponse>(
    SAVE_SALES_QUOTE_COSTING_BULK,
    {
      onCompleted: () => {
        notify({
          title: t("costing.importCostings"),
          content: t("costing.success.importCostings"),
        });
        refetchQuoteCategories();
        refetchSalesQuote();
      },
      onError: () => {
        notify({
          error: true,
          title: t("costing.importCostings"),
          content: t("costing.errors.importCostings"),
        });
      },
    }
  );

  const handleImportCostingTemplate = React.useCallback(
    (values: BulkSalesQuoteCostingItem[]) => {
      saveCostingItems({
        variables: {
          salesQuoteId,
          costItems: values,
        },
      });
    },
    [salesQuoteId, saveCostingItems]
  );

  const selectedCategoryStatus = React.useMemo(() => {
    return selectedCategory?.status;
  }, [selectedCategory?.status]);

  const handleChangeSalesQuoteCostingStatus = React.useCallback(
    (status: EnumSalesQuoteCategoryStatus) => {
      if (status === selectedCategoryStatus) return;

      updateSalesQuoteCostingStatus({
        variables: {
          salesQuoteId,
          categoryId: selectedCategory?._id,
          status,
        },
      });
    },
    [
      salesQuoteId,
      selectedCategory?._id,
      updateSalesQuoteCostingStatus,
      selectedCategoryStatus,
    ]
  );

  const isSelectedAllCategoryItems = React.useMemo(() => {
    const selectedIds = selectedItems.map((item) => item._id);
    if (!Boolean(selectedIds.length)) return false;

    const categoryItems = selectedCategory?.costings?.filter(
      (item) => !selectedIds.includes(item?._id as string)
    );

    return !Boolean(categoryItems?.length);
  }, [selectedCategory, selectedItems]);

  React.useEffect(() => {
    if (storedPricesType) {
      setShowPricesIncGst(storedPricesType.showPricesIncGst);
    } else {
      setStoredPricesType({ showPricesIncGst: showPricesIncGst });
    }
  }, []);

  React.useEffect(() => {
    const data = quoteCategories?.listSalesQuoteCostingCategories;
    const category = find(data, { _id: selectedCategory?._id }) || head(data);
    setCategory(category || null);
  }, [quoteCategories]);

  React.useEffect(() => {
    if (isSelectedAllCategoryItems) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [selectedItems, selectedCategory]);

  const uoms = React.useMemo(
    () =>
      chain(quoteCategories?.listSalesQuoteCostingCategories)
        .reduce((items: UOMOption[], next: CostingCategoryListItem) => {
          return items.concat(
            map(next.costings, (costing) => ({
              label: costing.UOM,
              value: costing.UOM,
            }))
          );
        }, MEASUREMENT_OPTIONS)
        .uniqBy("value")
        .value(),
    [quoteCategories?.listSalesQuoteCostingCategories]
  );

  const toggleSelectedItem = React.useCallback(
    (row: CategoryCostingItem) => {
      if (!row?._id) return;

      const newItems = [...selectedItems];
      const newQuoteRequestItems = [...quoteRequestItems];
      const newSpecItems = [...specItems];
      const newSelectionItems = [...selectionItems];
      const index = findIndex(newItems, { _id: row._id });
      const qrIndex = findIndex(newQuoteRequestItems, { _id: row._id });
      const specIndex = findIndex(newSpecItems, { _id: row._id });
      const selectionIndex = findIndex(newSelectionItems, { _id: row._id });

      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        //if (!row.is_allowance) // disabled to allow copy feature
        newItems.push(row as CategoryCostingItem);
      }
      if (qrIndex >= 0) {
        newQuoteRequestItems.splice(qrIndex, 1);
      } else {
        newQuoteRequestItems.push(row as CategoryCostingItem);
      }
      if (specIndex >= 0) {
        newSpecItems.splice(specIndex, 1);
      } else if (!row?.specCategoryItem?._id) {
        newSpecItems.push(row as CategoryCostingItem);
      }
      if (selectionIndex >= 0) {
        newSelectionItems.splice(selectionIndex, 1);
      } else if (!row?.selectionItem?._id) {
        newSelectionItems.push(row as CategoryCostingItem);
      }
      setSelectedItems(newItems);
      setQuoteRequestItems(newQuoteRequestItems);
      setSpecItems(newSpecItems);
      setSelectionItems(newSelectionItems);
    },
    [selectedItems, quoteRequestItems, specItems, selectionItems]
  );
  const toggleSelectedItems = React.useCallback(
    (rows: CategoryCostingItem[]) => {
      if (!rows?.length) return;
      const getUniqItems = (
        selectedItems: CategoryCostingItem[],
        newItems: CategoryCostingItem[]
      ) => {
        const items = [...selectedItems, ...newItems];

        return uniqBy(items, "_id");
      };

      const newItems = getUniqItems(selectedItems, rows);
      const newQuoteRequestItems = getUniqItems(quoteRequestItems, rows);
      const newSpecItems = getUniqItems(specItems, rows);
      const newSelectionItems = getUniqItems(selectionItems, rows);

      setSelectedItems(newItems);
      setQuoteRequestItems(newQuoteRequestItems);
      setSpecItems(newSpecItems);
      setSelectionItems(newSelectionItems);
    },
    [quoteRequestItems, selectedItems, selectionItems, specItems]
  );

  const takeoffs = React.useMemo(
    () => takeOffItems?.listSalesQuoteTakeOff || [],
    [takeOffItems]
  );

  // const handleSubmit = React.useCallback(
  //   async (values: CreateCostingCategoryPayload, assemblies: AssemblyListItem[]) => {
  //     const { name, margin_amount, costItems } = values;
  //     const preparedItems = prepareSalesQuoteItems(costItems, assemblies);

  //     const newCategory = await addQuoteCategory({
  //       variables: {
  //         name,
  //         margin_amount,
  //         salesQuoteId,
  //       },
  //     });

  //     await addQuoteCategoryItems({
  //       variables: {
  //         salesQuoteId,
  //         category: omit(newCategory?.data?.createSalesQuoteCategory, [
  //           "__typename",
  //         ]),
  //         costItems: preparedItems,
  //       },
  //     });
  //   },
  //   [salesQuoteId, addQuoteCategoryItems, assembliesList, addQuoteCategory]
  // );

  const onSaveTemplate = React.useCallback(() => {
    saveCostingAsTemplateModalRef.current?.show(true);
  }, [quoteCategories]);

  const onImportCostings = React.useCallback(() => {
    history.push(`/quotes/${salesQuoteId}/costing/import`);
  }, [salesQuoteId, history]);

  const onImportFromTemplate = React.useCallback(() => {
    setShowImportFromTemplateModal(true);
  }, []);

  const closeImportFromTemplate = React.useCallback(() => {
    setShowImportFromTemplateModal(false);
  }, []);

  const handleSetShowPricesType = React.useCallback((value: boolean) => {
    setShowPricesIncGst(value);
    setStoredPricesType({ showPricesIncGst: value });
  }, []);

  const onShowPricesIncGst = React.useCallback(() => {
    handleSetShowPricesType(true);
  }, []);

  const onShowPricesExGst = React.useCallback(() => {
    handleSetShowPricesType(false);
  }, []);

  const onDownloadCostings = React.useCallback(async () => {
    try {
      const result = await client.query<ExportSalesQuoteCostingResponse>({
        query: EXPORT_SALES_QUOTE_COSTINGS,
        variables: {
          salesQuoteId,
        },
        fetchPolicy: "network-only",
      });
      const exportData = result?.data?.exportSalesQuoteCostings;
      downloadFile(exportData.data, exportData.filename);
    } catch (e) {}
  }, [salesQuoteId, client]);

  const handleSubmitQuote = React.useCallback(
    async (values: QuoteRequestPayload) => {
      if (!salesQuoteId) return;

      const attachments = getMediaInput(values.attachments as File[]);

      const quoteRequest: QuoteRequestPayload = {
        ...values,
        attachments,
      };
      const result = await createQuoteRequest({
        variables: {
          quoteRequest,
        },
        update: handleSendQuoteRequest(salesQuoteId, values.items),
      });

      if (result.data?.createUpdateQuoteRequest) {
        if (attachments.length && values.attachments.length) {
          const attachmentResults =
            result.data?.createUpdateQuoteRequest?.attachments;
          if (attachmentResults?.length) {
            await uploadFiles(attachmentResults, values.attachments as File[]);
          }
        }
        quoteRequestRef.current?.show(false);
        showSendQuoteRequest(result.data.createUpdateQuoteRequest);
      }
      return true;

      // refetchQuoteCategories();
    },
    [salesQuoteId, createQuoteRequest, quoteRequestRef, showSendQuoteRequest]
  );

  const handleUpdateCategory = React.useCallback(
    async (values: UpdateCostingCategoryPayload, isCopy?: boolean) => {
      const { name, margin_amount, costItems, _id } = values;
      const preparedItems = prepareSalesQuoteItems(costItems, isCopy);

      let category;
      if (!_id) {
        const createCategory = await addQuoteCategory({
          variables: {
            name,
            margin_amount: Number(margin_amount ?? 0) || 0,
            salesQuoteId,
          },
        });
        category = createCategory?.data?.createSalesQuoteCategory;
      }
      return addQuoteCategoryItems({
        variables: {
          salesQuoteId,
          category: {
            _id: category?._id || _id,
            name,
            margin_amount: Number(margin_amount) || 0,
          },
          costItems: preparedItems,
        },
      });
    },
    [salesQuoteId, addQuoteCategoryItems]
  );

  const handleSetAllowance = React.useCallback(() => {
    updateCostingAllowance({
      variables: {
        salesQuoteId,
        costItemIds: map(selectedItems, (i) => i._id),
        status: true,
      },
    });
  }, [selectedItems]);

  const handleUnsetAllowance = React.useCallback(
    (item: CategoryCostingItem) => {
      updateCostingAllowance({
        variables: {
          salesQuoteId,
          costItemIds: [item._id],
          status: false,
        },
      });
    },
    []
  );

  const handleSetSpecItem = React.useCallback(() => {
    if (!salesQuoteId) return;
    addSpecItems({
      variables: {
        salesQuoteId,
        costingItemList: map(specItems, (i) => i._id),
      },
      update: handleUpdateSendQuote(salesQuoteId),
    });
  }, [addSpecItems, salesQuoteId, specItems]);

  const handleSort = React.useCallback(
    (categories: Pick<CostingCategoryListItem, "_id" | "name">[]) => {
      updateCostingCategoryOrder({
        variables: {
          salesQuoteId,
          categories,
        },
      });
    },
    [salesQuoteId]
  );

  const handleSetSelectionItem = React.useCallback(() => {
    if (!salesQuoteId) return;
    addSelectionItems({
      variables: {
        salesQuoteId,
        costingItemList: map(selectionItems, (i) => i._id),
      },
    });
  }, [addSelectionItems, salesQuoteId, selectionItems]);

  const handleAddToCategory = React.useCallback(
    async (category: CostingCategoryListItem) => {
      const items = selectedItems.map((item) => ({
        ...pick(item, [
          "name",
          "cost",
          "quantity",
          "raw_quantity",
          "UOM",
          "rounding",
          "wastage",
          "hasGST",
          "margin_amount",
          "note",
        ]),
        items: map(item.items, (assemblyItem) =>
          pick(assemblyItem, [
            "name",
            "cost",
            "quantity",
            "raw_quantity",
            "UOM",
            "rounding",
            "wastage",
          ])
        ),
      }));

      await addQuoteCategoryItems({
        variables: {
          salesQuoteId,
          category: {
            _id: category?._id,
            name: category.name,
          },
          costItems: items,
        },
      });

      resetAllSelectedItems();
    },
    [addQuoteCategoryItems, salesQuoteId, selectedItems]
  );

  const handleCategorySelect = React.useCallback((category) => {
    setCategory(category);
  }, []);

  const updateCostingPress = React.useCallback(() => {
    if (!selectedCategory) return;
    const category = prepareCategoryUpdateItem(selectedCategory);

    updateCostingRef.current?.show(true, category);
  }, [updateCostingRef, selectedCategory]);

  const openCopy = React.useCallback(
    (categoryId: string) => {
      const data = quoteCategories?.listSalesQuoteCostingCategories;
      const category = find(data, { _id: categoryId });
      if (category) {
        const preparedCategory = prepareCategoryUpdateItem({
          ...category,
          name: t("quotes.copyQuoteName", {
            estimationName: category?.name,
          }),
        });
        updateCostingRef.current?.show(
          true,
          {
            ...omit(preparedCategory, ["_id"]),
          },
          true
        );
      }
    },
    [quoteCategories, t]
  );

  const createCostingPress = React.useCallback(() => {
    updateCostingRef.current?.show(true);
  }, [updateCostingRef]);

  const categoryData = React.useMemo<TableCardData<CostingTable>>(() => {
    return createCostingTable(
      selectedCategory?.costings || null,
      t,
      selectedCategory?.margin_amount,
      ["allocatedCategory"],
      undefined,
      showPricesIncGst,
      markupPercent
    );
  }, [selectedCategory, t, showPricesIncGst, markupPercent]);

  const categoriesData = React.useMemo<
    TableCardData<SalesQuoteCategoryTableItem>
  >(() => {
    const columns = [
      {
        valueKey: "name",
        title: t("common.category"),
        formatValue: (row: any, column: any, value: string) => value,
      },
      {
        valueKey: "total",
        title: t("costing.totalEx"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.currency", { amount: value }),
      },
      {
        valueKey: "clientTotal",
        title: t("costing.clientTotalEx"),
        formatValue: (row: any, column: any, value: string) =>
          t("common.currency", { amount: value }),
      },
    ];

    const rows = map(
      quoteCategories?.listSalesQuoteCostingCategories.filter(
        (category) => category.status !== EnumSalesQuoteCategoryStatus.NOT_USED
      ),
      (category) => {
        const total = category.costings.reduce(
          (acc, item) =>
            acc + calcItemTotal(item, category?.margin_amount || 0),
          0
        );
        const clientTotal =
          total + (total * category?.salesQuote?.markup) / 100;
        return {
          cells: {
            _id: category._id,
            name: category.name,
            total,
            clientTotal,
          },
        };
      }
    ) as TableCardDataRow<SalesQuoteCategoryTableItem>[];

    if (rows.length) {
      rows.push({
        isTotal: true,
        cells: reduce(
          rows,
          (result: SalesQuoteCategoryTableItem, row) => {
            result["total"] += row?.cells?.total || 0;
            result["clientTotal"] += row?.cells?.clientTotal || 0;

            return result;
          },
          {
            total: 0,
            clientTotal: 0,
          } as SalesQuoteCategoryTableItem
        ),
      } as TableCardDataRow<SalesQuoteCategoryTableItem>);
    }
    return {
      columns,
      rows,
    } as TableCardData<SalesQuoteCategoryTableItem>;
  }, [t, quoteCategories?.listSalesQuoteCostingCategories]);

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteDialogVisibility(false);
    setCategoryToDelete("");
  }, []);

  const openDeleteDialog = React.useCallback((categoryId: string) => {
    setDeleteDialogVisibility(true);
    setCategoryToDelete(categoryId);
  }, []);

  const handleDeleteSubmit = React.useCallback(async () => {
    try {
      await deleteCategory({
        variables: { salesQuoteId, categoryId: categoryToDeleteId },
      });
      notify({
        title: t("costing.deleteCosting"),
        content: t("costing.success.deleteCosting"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("costing.deleteCosting"),
        content: t("costing.errors.deleteCosting"),
      });
    }

    setCategoryToDelete("");
    setDeleteDialogVisibility(false);
  }, [categoryToDeleteId]);

  const handleBackClick = React.useCallback(() => {
    history.push(`/quotes/${salesQuoteId}/take-off`);
  }, [salesQuoteId]);

  const handleNextClick = React.useCallback(() => {
    history.push(`/quotes/${salesQuoteId}/specifications`);
  }, [salesQuoteId]);

  const handleEditMarkup = React.useCallback(() => {
    markupModalRef.current?.show(true);
  }, [markupModalRef]);

  const headerAction = React.useMemo<TableCardAction | undefined>(() => {
    if (isLocked) return;

    return {
      className: "button large info bg-transparent text-light",
      icon: "edit",
      disabled: !selectedCategory,
      onClick: updateCostingPress,
      title: t("common.edit"),
    };
  }, [t, updateCostingPress, selectedCategory, isLocked]);

  const includedCategories = React.useMemo(() => {
    return filter(
      quoteCategories?.listSalesQuoteCostingCategories,
      (category) => category.status !== EnumSalesQuoteCategoryStatus.NOT_USED
    );
  }, [quoteCategories]);

  const subTotal = React.useMemo(() => {
    if (!includedCategories.length) {
      return 0;
    }

    return calcCategorySubTotal(includedCategories, false);
  }, [includedCategories]);

  const subTotalGSTFree = React.useMemo(() => {
    if (!includedCategories) {
      return 0;
    }

    return calcCategorySubTotal(includedCategories, true);
  }, [includedCategories]);

  const markupTotal = React.useMemo(() => {
    return calcTotalMarkup(subTotal, markupPercent);
  }, [subTotal, subTotalGSTFree, markupPercent]);

  const markupTotalGSTFree = React.useMemo(() => {
    return calcTotalMarkup(subTotalGSTFree, markupPercent);
  }, [subTotalGSTFree, subTotalGSTFree, markupPercent]);

  const GST = React.useMemo(() => {
    return calcGST(subTotal + markupTotal, GST_PERCENT);
  }, [subTotal, markupTotal]);

  const total = React.useMemo(() => {
    return markupPercent
      ? subTotal + markupTotal + subTotalGSTFree + markupTotalGSTFree
      : calcTotal(subTotal, GST) + subTotalGSTFree;
  }, [subTotal, markupTotal, markupPercent, GST]);

  const grandTotal = React.useMemo(() => {
    return calcTotal(total, GST);
  }, [total, GST]);

  const deletingCategoryTitle = React.useMemo(() => {
    const category = find(quoteCategories?.listSalesQuoteCostingCategories, {
      _id: categoryToDeleteId,
    });

    return category?.name;
  }, [quoteCategories, categoryToDeleteId]);

  //"requested", "accepted"
  const rowActions: TableRowActionData<CategoryCostingItem>[] = React.useMemo(
    () => [
      {
        icon: "money_off",
        dropdownId: "gstFree",
        title: t("costing.gstFree"),
        onClick: () => {},
        shouldRender: (row) => row._id && !row.hasGST,
      },
      {
        icon: "font_download",
        dropdownId: "allowance",
        title: t("costing.allowance"),
        onClick: !isLocked ? handleUnsetAllowance : () => {},
        shouldRender: (row) => row.is_allowance,
      },
      {
        icon: "fact_check",
        dropdownId: "stecItem",
        title: t("costing.specification"),
        shouldRender: (row) => row?.specCategoryItem?._id,
      },
      {
        icon: "pending",
        dropdownId: "quote-request",
        title: (row: CategoryCostingItem) =>
          t("costing.quoteRequestedWithCount", {
            count: getQuoteRequestsItems(row).length,
          }),
        counter: (row: CategoryCostingItem) =>
          getQuoteRequestsItems(row).length,
        onClick: (row: CategoryCostingItem) => {
          const data = getQuoteRequestsItems(row);
          if (salesQuoteId) {
            listQuoteRequestsRef.current?.setData(data, salesQuoteId);
            listQuoteRequestsRef.current?.show(true);
          }
        },
        shouldRender: (row) =>
          row.quoteRequestStatus === QuoteRequestItemStatus.REQUESTED,
      },
      {
        icon: "task_alt",
        dropdownId: "quote-request-accepted",
        title: (row: CategoryCostingItem) =>
          t("costing.quoteAcceptedWithCount", {
            count: getQuoteRequestsItems(row).length,
          }),
        counter: (row: CategoryCostingItem) =>
          getQuoteRequestsItems(row).length,
        onClick: (row: CategoryCostingItem) => {
          const data = getQuoteRequestsItems(row);
          if (salesQuoteId) {
            listQuoteRequestsRef.current?.setData(data, salesQuoteId);
            listQuoteRequestsRef.current?.show(true);
          }
        },
        shouldRender: (row) =>
          row.quoteRequestStatus === QuoteRequestItemStatus.ACCEPTED,
      },
      {
        icon: "group_work",
        dropdownId: "distribute-category",
        tooltip: (_row: CategoryCostingItem) =>
          t("costing.distributeOverCategory"),
        shouldRender: (row) =>
          row.distribute_markup === DistributeMarkup.CATEGORY,
      },
      {
        icon: "workspaces",
        dropdownId: "distribute-estimation",
        tooltip: (_row: CategoryCostingItem) =>
          t("costing.distributeOverEstimation"),
        shouldRender: (row) =>
          row.distribute_markup === DistributeMarkup.ESTIMATION,
      },
      {
        icon: "comment",
        dropdownId: "note",
        onClick: () => {},
        shouldRender: (row) => !!row.note,
        tooltip: (row) => {
          return row.note;
        },
      },
      {
        icon: "grading",
        dropdownId: "selections",
        tooltip: (row) => {
          return row.selectionItem?.name || "";
        },
        onClick: (row) => {
          selectionOptionsRef.current?.setData(
            row.selectionItem as SelectionCategoryItemType
          );
          selectionOptionsRef.current?.show(true);
        },
        shouldRender: (row) => row.selectionItem,
      },
    ],
    [selectedItems, isLocked, toggleSelectedItem]
  );

  const leftRowActions: TableRowActionData<
    CategoryCostingItem
  >[] = React.useMemo(
    () => [
      {
        icon: "check_box",
        dropdownId: "team-member",
        onClick: toggleSelectedItem,
        shouldRender: (row) => {
          return (
            row?._id &&
            (findIndex(quoteRequestItems, { _id: row?._id }) >= 0 ||
              findIndex(selectedItems, { _id: row?._id }) >= 0 ||
              findIndex(specItems, { _id: row?._id }) >= 0)
          );
        },
      },
      {
        icon: "check_box_outline_blank",
        dropdownId: "team-member",
        onClick: toggleSelectedItem,
        shouldRender: (row) =>
          row?._id &&
          findIndex(quoteRequestItems, { _id: row?._id }) < 0 &&
          findIndex(selectedItems, { _id: row?._id }) < 0 &&
          findIndex(specItems, { _id: row?._id }) < 0,
      },
    ],
    [toggleSelectedItem, isLocked, quoteRequestItems, selectedItems, specItems]
  );

  const handleCheckAll = React.useCallback(
    (value: boolean) => {
      if (value) {
        const rows = categoryData.rows
          .map((row) => row.cells)
          .filter((c) => !!c._id);

        toggleSelectedItems(rows as CategoryCostingItem[]);
      } else {
        resetAllSelectedItems();
      }
      setCheckAll(value);
    },
    [categoryData, toggleSelectedItems, resetAllSelectedItems]
  );

  const headerTableLeftRowActions = React.useCallback(() => {
    return <CheckBox value={checkAll} onClick={handleCheckAll} />;
  }, [checkAll, handleCheckAll]);

  const showSelectCategoryModal = React.useCallback(() => {
    selectCategoryRef.current?.show(true);
  }, []);

  const tableActions = React.useMemo<TableCardAction[]>(() => {
    if (isLocked) return [];
    return [
      {
        title: t(
          selectedItems.length
            ? "costing.copyItemsNumber"
            : "costing.copyItems",
          { number: selectedItems.length }
        ),
        onClick: showSelectCategoryModal,
        icon: "copy_all",
        className: "button info-reverse",
        disabled: !selectedItems.length,
      },
      {
        title: t(
          selectionItems.length
            ? "costing.selectionItemsNumber"
            : "costing.selectionItems",
          { number: selectionItems.length }
        ),
        onClick: handleSetSelectionItem,
        icon: "grading",
        className: "button info-reverse",
        disabled: !selectionItems.length,
      },
      {
        title: t(
          specItems.length ? "costing.specItemsNumber" : "costing.specItems",
          { number: specItems.length }
        ),
        onClick: handleSetSpecItem,
        icon: "fact_check",
        className: "button info-reverse",
        disabled: !specItems.length,
      },
      {
        title: t(
          selectedItems.length
            ? "costing.allowanceNumber"
            : "costing.allowance",
          { number: selectedItems.length }
        ),
        onClick: handleSetAllowance,
        svgIcon: "Pricing",
        className: "button large large-wide info-reverse",
        disabled: !selectedItems.length,
      },
      {
        title: t(
          quoteRequestItems.length
            ? "costing.quoteItemsNumber"
            : "costing.quoteItems",
          { number: quoteRequestItems.length }
        ),
        onClick: () => quoteRequestRef?.current?.show(true),
        svgIcon: "Bill",
        className: "button large large-wide info-reverse",
        disabled: !quoteRequestItems.length,
      },
    ];
  }, [
    isLocked,
    t,
    selectedItems,
    selectionItems,
    quoteRequestItems,
    quoteRequestRef,
    isLocked,
    showSelectCategoryModal,
    specItems,
    handleSetSpecItem,
    handleSetAllowance,
    quoteRequestItems,
  ]);

  const toggleCostingStatusDropdown = React.useCallback(() => {
    setCostingStatusDropdown(!costingStatusDropdown);
  }, [costingStatusDropdown]);

  const costingStatusDropdownItems = React.useMemo(() => {
    return [
      {
        id: "completed",
        label: t("common.completed"),
        name: "completed",
        onClick: () =>
          handleChangeSalesQuoteCostingStatus(
            EnumSalesQuoteCategoryStatus.COMPLETE
          ),
      },
      {
        id: "incomplete",
        label: t("common.incomplete"),
        name: "incomplete",
        onClick: () =>
          handleChangeSalesQuoteCostingStatus(
            EnumSalesQuoteCategoryStatus.INCOMPLETE
          ),
      },
      {
        id: "optional",
        label: t("common.optional"),
        name: "optional",
        onClick: () =>
          handleChangeSalesQuoteCostingStatus(
            EnumSalesQuoteCategoryStatus.OPTIONAL
          ),
      },
      {
        id: "notUsed",
        label: t("common.notUsed"),
        name: "notUsed",
        onClick: () =>
          handleChangeSalesQuoteCostingStatus(
            EnumSalesQuoteCategoryStatus.NOT_USED
          ),
      },
    ];
  }, [handleChangeSalesQuoteCostingStatus, t]);

  const additionalHeaderAction = React.useCallback(() => {
    if (isLocked) return <></>;
    let status;
    switch (selectedCategory?.status) {
      case EnumSalesQuoteCategoryStatus.COMPLETE:
        status = t("common.completed");
        break;
      case EnumSalesQuoteCategoryStatus.INCOMPLETE:
        status = t("common.incomplete");
        break;
      case EnumSalesQuoteCategoryStatus.NOT_USED:
        status = t("common.notUsed");
        break;
      default:
        status = t("common.optional");
    }

    return (
      <Dropdown
        isVisible={costingStatusDropdown}
        handleToggle={toggleCostingStatusDropdown}
        label={status}
        icon="expand_more"
        id="optional-dropdown"
        menuWidth="100%"
        items={costingStatusDropdownItems}
      />
    );
  }, [
    costingStatusDropdownItems,
    costingStatusDropdown,
    selectedCategoryStatus,
    isLocked,
  ]);

  const canCreateTakeoff = React.useMemo(
    () => salesQuote?.files && salesQuote.files.length > 0,
    [salesQuote]
  );

  const isLoading = React.useMemo(() => {
    return (
      categoriesLoading ||
      isEmpty(quoteCategories?.listSalesQuoteCostingCategories) ||
      createRequestLoading ||
      categoryStatusUpdating ||
      loadingAddQuoteCategoryItems
    );
  }, [
    categoriesLoading,
    categoryStatusUpdating,
    createRequestLoading,
    quoteCategories?.listSalesQuoteCostingCategories,
    loadingAddQuoteCategoryItems,
  ]);

  const {
    filteredArray,
    filter: inputFilter,
    setFilter: setSearchByInput,
  } = useFilteredArrayMultipleField(
    quoteCategories?.listSalesQuoteCostingCategories || [],
    ["name"],
    true
  );

  const toggleViewButtonAction = React.useMemo<ActionButton[]>(
    () => [
      {
        className: "toggle-view",
        icon: categoriesView ? "view_list" : "table_rows",
        disabled:
          categoriesLoading ||
          isEmpty(quoteCategories?.listSalesQuoteCostingCategories),
        onClick: () => setCategoriesView(!categoriesView),
      },
    ],
    [categoriesView, categoriesLoading, quoteCategories]
  );

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<SalesQuoteCategoryTableItem>) => {
      const category = find(quoteCategories?.listSalesQuoteCostingCategories, {
        _id: row?.cells._id,
      });
      if (category) {
        setCategory(category);
        setCategoriesView(false);
      }
    },
    [quoteCategories?.listSalesQuoteCostingCategories]
  );

  const quoteRequestAllItems = React.useMemo(() => {
    const items: QuoteRequestTableItem[] = [];
    quoteRequestItems.forEach((item) => {
      if (item.items?.length) {
        item.items.forEach((assemblyItem) => {
          items.push({
            ...assemblyItem,
            assemblyId: item._id,
            quantity: assemblyItem.computed_quantity,
          } as QuoteRequestTableItem);
        });
      } else {
        items.push(item as QuoteRequestTableItem);
      }
    });
    return items;
  }, [quoteRequestItems]);

  return (
    <Container fluid className="m-0 p-0 h-100 quote-costing-container">
      <Helmet title={t("navigation.quotesSection.costing")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.COSTING} />
      <EstimationLocationHeader />
      <ConfirmDialog
        title={deletingCategoryTitle}
        onSubmit={handleDeleteSubmit}
        show={showDeleteConfirmation}
        onClose={closeDeleteDialog}
        disabled={categoryDeleting}
      >
        <span className="field-text">{t("quotes.deleteCategoryMessage")}</span>
      </ConfirmDialog>

      <SaveCostingAsTemplateModal
        salesQuoteId={salesQuoteId}
        ref={saveCostingAsTemplateModalRef}
      />
      <SelectionOptionsModal ref={selectionOptionsRef} />

      <SelectCategoryModal
        costingCategories={quoteCategories?.listSalesQuoteCostingCategories}
        ref={selectCategoryRef}
        onSubmit={handleAddToCategory}
      />

      <ImportCostingsFromTemplateModal
        show={showImportFromTemplateModal}
        onClose={closeImportFromTemplate}
        onSubmit={handleImportCostingTemplate}
      />

      {salesQuoteId && (
        <>
          <QuoteRequestModal
            ref={quoteRequestRef}
            onSubmit={handleSubmitQuote}
            itemsForQuote={quoteRequestAllItems}
            itemId={salesQuoteId}
            type={QuoteRequestType.SALES_QUOTE}
          />
          {renderSendQuoteRequest()}
        </>
      )}

      <UpdateCostingModal
        ref={updateCostingRef}
        salesQuoteId={salesQuoteId}
        canCreateTakeoff={canCreateTakeoff}
        takeoffs={takeoffs}
        uoms={uoms}
        onSubmit={handleUpdateCategory}
        onClose={updateCostingPress}
      />

      <ListQuoteRequestsModal
        ref={listQuoteRequestsRef}
        redirectTo={NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES}
      />

      <Row className="h-100 align-content-start justify-content-center">
        <Row className="w-100">
          <Col>
            <div className="d-flex justify-content-end">
              <div className="d-flex">
                <DashboardActionHeader
                  shouldRenderActionButton={false}
                  additionalActionButtons={toggleViewButtonAction}
                />
                {!isLocked && (
                  <Button
                    key={"onAddCard"}
                    className="button large success ml-2"
                    onClick={createCostingPress}
                  >
                    <Icon name="add" />
                    {t("costing.addCosting")}
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="w-100">
          {categoriesView ? (
            <Col>
              <TableCard
                isDataLoading={isLoading}
                rowCount
                data={categoriesData}
                onRowClick={handleRowClick}
                className="category-table"
              />
            </Col>
          ) : (
            <>
              <Col lg={4} xs={12}>
                <CostingQuoteCategories
                  sortable={!isLocked}
                  disabled={isLocked}
                  categories={filteredArray}
                  total={salesQuote?.subTotal}
                  subtotal={subTotal + subTotalGSTFree}
                  grandTotal={salesQuote?.total}
                  gst={salesQuote?.GST}
                  markupPercent={markupPercent}
                  markup={markupTotal + markupTotalGSTFree}
                  onBackClick={handleBackClick}
                  onNextClick={handleNextClick}
                  onDelete={openDeleteDialog}
                  onCopy={openCopy}
                  selectedCategory={selectedCategory}
                  onCategorySelect={handleCategorySelect}
                  displayCostingTotals={true}
                  displayNavButtons={true}
                  itemsForQuote={selectedItems}
                  onSaveTemplate={onSaveTemplate}
                  onImportCostings={onImportCostings}
                  onImportFromTemplate={onImportFromTemplate}
                  onDownloadCostings={onDownloadCostings}
                  onEditMarkup={handleEditMarkup}
                  onSort={handleSort}
                  onShowPricesIncGst={onShowPricesIncGst}
                  onShowPricesExGst={onShowPricesExGst}
                  showPricesIncGst={showPricesIncGst}
                  onSync={integrationProvider ? handleSync : undefined}
                  syncProvider={integrationProvider}
                  titleBadge={t("costing.globalMarkupPercent", {
                    percent: salesQuoteData?.getSalesQuote?.markup,
                  })}
                  searchOptions={{
                    value: inputFilter,
                    onChange: setSearchByInput,
                  }}
                  placeholder={t("costing.emptySearchPlaceholder", {
                    filter: inputFilter,
                  })}
                />
              </Col>
              <Col lg={8} xs={12}>
                <TableCard
                  isDataLoading={isLoading}
                  headerAction={headerAction}
                  titleBadge={t("costing.markupPercent", {
                    percent: selectedCategory?.margin_amount,
                  })}
                  rowCount
                  title={selectedCategory?.name}
                  data={categoryData}
                  rowActions={rowActions}
                  leftRowActions={leftRowActions}
                  actions={tableActions}
                  additionalHeaderAction={additionalHeaderAction}
                  headerTableLeftRowActions={
                    !isLoading ? headerTableLeftRowActions : undefined
                  }
                />
              </Col>
            </>
          )}
        </Row>
      </Row>
      {salesQuote && (
        <QuoteMarkupModal
          ref={markupModalRef}
          salesQuote={salesQuote}
          onSubmit={refetchQuoteCategories}
        />
      )}
    </Container>
  );
};

export default withDashboardContext(QuoteCosting);
