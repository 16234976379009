import gql from "graphql-tag";

export const JOB_MESSAGE_CLIENT_FRAGMENT = gql`
  fragment JobChannelMessage on JobChannelMessage {
    _id
    text
    is_deleted
    linkPreview {
      title
      description
      url
      imageUrl
    }
    channel {
      _id
    }
    media {
      _id
      upload_url
      url
      type
      size
    }
    sender {
      _id
      first_name
      last_name
      email
    }
    createdAt
  }
`;
