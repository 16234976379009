import React from "react";
import {
  calcItemsSubtotal,
  calcGST,
  calcGSTEx,
  CalcInput,
  GST_PERCENT,
  calcItemsGST,
} from "../../../utils/calculations";
import Totals from "../../costing/total";

type ModalTotalsProps = {
  items: CalcInput[];
  margin: number;
  title?: string;
  canToggleGST?: boolean;
  hasGST?: boolean;
  costsHaveGST?: boolean;
  onToggleGST?: (value: boolean) => void;
};

const ModalTotals: React.FC<ModalTotalsProps> = ({
  items,
  margin,
  title,
  canToggleGST,
  hasGST,
  onToggleGST,
  costsHaveGST,
}) => {
  const totals = React.useMemo(() => {
    const subTotal = calcItemsSubtotal(items, margin);
    const gst = calcItemsGST(items, margin, GST_PERCENT);

    return {
      subTotal,
      gst,
      total: gst + subTotal,
    };
  }, [items, margin, costsHaveGST]);

  return (
    <Totals
      title={title}
      subtotal={totals.subTotal}
      total={totals.total}
      gst={totals.gst}
      canToggleGST={canToggleGST}
      hasGST={hasGST}
      onToggleGST={onToggleGST}
      costsHaveGST={costsHaveGST}
    />
  );
};

export default ModalTotals;
