import { gql } from "@apollo/client";
import { TEAM_MEMBER } from "../../members/fragments";

export const LIST_JOBS_SCHEDULE = gql`
  query {
    listJobsSchedule {
      _id
      job {
        _id
        jobNumber
        name
        contractTotal
        address
        city
        state
        postcode
        startDate
        endDate
      }
      categories {
        _id
        name
        startDate
        endDate
        colour
        order
        items {
          _id
          name
          description
          progress
          supplier {
            _id
            business_name
            contact_person
            email
          }
          user {
            ...TeamMember
          }
          predecessor {
            _id
          }
          dependant {
            _id
          }
          reminder {
            daysBefore
            timezone
          }
          startDate
          endDate
          progress
          order
        }
      }
    }
  }
  ${TEAM_MEMBER}
`;

export const GET_JOB_SCHEDULE = gql`
  query($jobId: ID!) {
    getJobSchedule(jobId: $jobId) {
      _id
      name
      startDate
      endDate
      colour
      order
      items {
        _id
        name
        description
        supplier {
          _id
          business_name
          contact_person
          email
        }
        user {
          ...TeamMember
        }
        predecessor {
          _id
        }
        dependant {
          _id
        }
        reminder {
          daysBefore
          timezone
        }
        startDate
        endDate
        progress
        order
      }
    }
  }
  ${TEAM_MEMBER}
`;

export const LIST_SCHEDULE_ITEMS = gql`
  query($jobId: ID, $startRange: ScheduleStartRangeInput!) {
    listScheduleItems(jobId: $jobId, startRange: $startRange) {
      _id
      startDate
      endDate
      name
      progress
      job {
        _id
        name
      }
    }
  }
`;

export const GET_JOB_SCHEDULE_PREVIEW = gql`
  query GetJobSchedulePreview($jobId: ID!, $filter: ScheduleFilterInput) {
    getJobSchedulePreview(jobId: $jobId, filter: $filter) {
      _id
      pdf
    }
  }
`;

export const EXPORT_JOB_SCHEDULE = gql`
  query($jobId: ID!) {
    exportJobSchedule(jobId: $jobId) {
      _id
      filename
      data
    }
  }
`;

export const LIST_SCHEDULE_TEMPLATES = gql`
  query {
    listScheduleTemplates {
      _id
      name
      updatedAt
    }
  }
`;
