import { TFunction } from "i18next";

import {
  CreateTakeOffPayload,
  TakeOffMeasurement,
} from "../../../../models/take-off";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";

export function createTakeOffFields(
  t: TFunction
): GenericFormFields<CreateTakeOffPayload> {
  return {
    name: {
      type: "text",
      label: t("takeOffSection.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    UOM: {
      type: "button-select",
      label: t("takeOffSection.measurement"),
      valueKey: "UOM",
      buttonSelectProps: {
        options: [
          {
            value: TakeOffMeasurement.LINEAR_METER,
            label: t("takeOffSection.lM"),
          },
          {
            value: TakeOffMeasurement.METER_SQUARED,
            label: t("takeOffSection.mSquared"),
          },
          {
            value: TakeOffMeasurement.CUBIC_METER,
            label: t("takeOffSection.mCubic"),
          },
          {
            value: TakeOffMeasurement.QUANTITY,
            label: t("takeOffSection.count"),
          },
          {
            value: TakeOffMeasurement.TONNE,
            label: t("takeOffSection.tonne"),
          },
        ],
      },
    },
    color: {
      type: "colour-picker",
      label: t("takeOffSection.colour"),
      placeholder: t("placeholders.colour"),
      valueKey: "color",
      className: "take-off-colour-picker",
    },
  };
}
