import React, { forwardRef } from "react";
import { filter, omit } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  ScheduleCategory,
  ScheduleCategoryItem,
  UpdateTaskPayload,
} from "../../../models/job";
import CreateSupplierModal, {
  CreateSupplierModalRef,
} from "../../contacts/create-supplier-modal";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createTaskSchema from "../create-schedule-task-modal/CreateScheduleTask.schema";
import { createTaskFields } from "../create-schedule-task-modal/utils";
import UpdateEntityModal from "../../modals/update-entity";
import { SupplierListResponse } from "../../../graphql/types/models/supplier";
import { LIST_SUPPLIERS } from "../../../graphql/queries/supplier/queries";
import { ListMembersResponse } from "../../../graphql/models/members";
import { SupplierDetails } from "../../../models/supplier";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { TaskAssignTo } from "../../../utils/options";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import "./styles.scss";

type UpdateTaskModalProps = {
  onSubmit: (data: UpdateTaskPayload, category: ScheduleCategory) => void;
  tasks: ScheduleCategoryItem[];
};

export type UpdateTaskModalRef = {
  show: (
    show: boolean,
    data?: UpdateTaskPayload,
    category?: ScheduleCategory
  ) => void;
};
const UpdateTaskModal: React.FC<UpdateTaskModalProps> = (props, ref) => {
  const { onSubmit, tasks: allTasks } = props;
  const { t } = useTranslation();

  const { shouldShow, show, hide } = useModalDisplay(ref, {
    show: (
      showModal: boolean,
      data?: UpdateTaskPayload,
      category?: ScheduleCategory
    ) => {
      if (showModal) {
        show();
      } else {
        hide();
      }
      setData(data || null);
      setParentCategory(category || null);
    },
  });
  const [
    parentCategory,
    setParentCategory,
  ] = React.useState<ScheduleCategory | null>(null);
  const [data, setData] = React.useState<UpdateTaskPayload | null>();
  const [assignTo, setAssignTo] = React.useState<TaskAssignTo>(
    TaskAssignTo.TEAM
  );
  const [customReminder, setCustomReminder] = React.useState(0);
  const supplierRef = React.useRef<CreateSupplierModalRef>(null);
  const updateTaskModalRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (!shouldShow) {
      setData(null);
      setAssignTo(TaskAssignTo.TEAM);
    }
  }, [shouldShow]);

  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const { data: suppliersData } = useQuery<SupplierListResponse>(
    LIST_SUPPLIERS
  );

  const suppliers = React.useMemo(() => suppliersData?.listSuppliers, [
    suppliersData,
  ]);
  const team = React.useMemo(() => teamData?.listMembers, [teamData]);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<UpdateTaskPayload>
  >({});

  const tasks = React.useMemo(
    () => filter(allTasks, (t) => t._id !== data?._id),
    [allTasks, data]
  );

  const openSupplierModal = React.useCallback(
    () => supplierRef.current?.show(true),
    [supplierRef]
  );

  const updateAssignTo = React.useCallback(
    (value: string) => {
      const assignValue =
        TaskAssignTo.TEAM.toString() === value
          ? TaskAssignTo.TEAM
          : TaskAssignTo.SUPPLIER;
      setAssignTo(assignValue);

      return assignValue;
    },
    [setAssignTo]
  );

  const handleReminderCreate = React.useCallback((value: string) => {
    const num = Number(value);
    if (num > 14) setCustomReminder(num);
  }, []);

  const onAssignToChange = React.useCallback(
    (value: string) => {
      if (!parentCategory || !suppliers || !team) return;
      const assignValue = updateAssignTo(value);

      setFormFields(
        createTaskFields(
          t,
          parentCategory.startDate,
          parentCategory.endDate,
          suppliers,
          openSupplierModal,
          team,
          onAssignToChange,
          assignValue,
          tasks,
          customReminder,
          handleReminderCreate
        )
      );
    },
    [
      parentCategory,
      suppliers,
      team,
      updateAssignTo,
      t,
      openSupplierModal,
      tasks,
      customReminder,
      handleReminderCreate,
    ]
  );

  React.useEffect(() => {
    if (!data) return;
    const assignTo = data?.supplier ? TaskAssignTo.SUPPLIER : TaskAssignTo.TEAM;
    setAssignTo(assignTo);
  }, [data]);

  React.useEffect(() => {
    if (!parentCategory || !suppliers || !team) return;
    setFormFields(
      createTaskFields(
        t,
        parentCategory.startDate,
        parentCategory.endDate,
        suppliers,
        openSupplierModal,
        team,
        onAssignToChange,
        assignTo,
        tasks,
        customReminder,
        handleReminderCreate
      )
    );
    handleReminderCreate(data?.reminder?.daysBefore?.toString() || "");
  }, [
    t,
    suppliers,
    tasks,
    parentCategory,
    openSupplierModal,
    data,
    customReminder,
    handleReminderCreate,
    team,
    assignTo,
    onAssignToChange,
    updateAssignTo,
  ]);

  const handleSetNewSupplier = React.useCallback(
    (supplier: SupplierDetails) => {
      updateTaskModalRef.current?.setFieldValue("supplier", supplier._id);
      setAssignTo(TaskAssignTo.SUPPLIER);
    },
    [updateTaskModalRef]
  );

  const handleSubmit = React.useCallback(
    (values: UpdateTaskPayload) => {
      if (!parentCategory) return;

      const filtereValues = {
        ...omit(values, ["assignTo"]),
        supplier: assignTo === TaskAssignTo.TEAM ? null : values.supplier,
        user: assignTo === TaskAssignTo.SUPPLIER ? null : values.user,
      };
      onSubmit && onSubmit(filtereValues, parentCategory);
      return true;
    },
    [onSubmit, parentCategory, assignTo]
  );

  const initialValues = React.useMemo(() => {
    if (!data) {
      return {
        _id: "",
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        progress: 0,
        supplier: null,
        user: null,
        assignTo: TaskAssignTo.TEAM,
        predecessor: "",
        reminder: {
          daysBefore: "",
          timezone: "",
        },
      };
    }
    return {
      ...data,
      assignTo: data?.supplier ? TaskAssignTo.SUPPLIER : TaskAssignTo.TEAM,
      reminder: {
        daysBefore: data?.reminder?.daysBefore
          ? data?.reminder?.daysBefore.toString()
          : "",
        timezone: data?.reminder?.timezone || "",
      },
    };
  }, [data, parentCategory]);

  if (!data) return null;

  return (
    <UpdateEntityModal
      formikRef={updateTaskModalRef}
      validationSchema={createTaskSchema(t)}
      className="update-task-modal"
      title={data.name}
      show={shouldShow}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
    >
      {() => (
        <CreateSupplierModal
          ref={supplierRef}
          onCreate={handleSetNewSupplier}
        />
      )}
    </UpdateEntityModal>
  );
};

export default forwardRef(UpdateTaskModal);
