import {
  GenericFormFields,
  UpdateRowFn,
} from "../../../generic-form/GenericFormBody";
import { TFunction } from "i18next";
import { QuoteMeasurement } from "../../../../models/salesQuote";
import { calcItemTotal } from "../../../../utils/calculations";
import {
  AssemblyCreateUpdatePayload,
  AssemblyItemPayload,
} from "../../../../models/assembly";
import { UOMOption, GenericRecord } from "../../../../utils/types/options";
import TopBadgedAutocompleteMenuOption from "../../../generic-form/inputs/autocomplete/TopBadgedAutocompleteMenuOption";
import { SelectOption } from "../../../generic-form/inputs/select";
import CostingAutocompleteMenuOption from "../../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";

export const createAssemblyFields = (
  t: TFunction,
  uoms: UOMOption[],
  assemblyOptions: GenericRecord[],
  handlePriceSearch: (value: string) => Promise<SelectOption[]>,
  handleUOMCreate: (uom: string) => void,
  openCalculatorModal: (fieldName: string) => void,
  handleCreatePriceOption: (data: string) => void,
  handlePriceListChange: UpdateRowFn
): GenericFormFields<AssemblyCreateUpdatePayload> => {
  return {
    name: {
      type: "text",
      label: t("common.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    UOM: {
      type: "button-select",
      label: t("takeOffSection.measurement"),
      valueKey: "UOM",
      buttonSelectProps: {
        options: [
          {
            value: QuoteMeasurement.LINEAR_METER,
            label: t("takeOffSection.linearMeters"),
          },
          {
            value: QuoteMeasurement.METER_SQUARED,
            label: t("takeOffSection.metersSquared"),
          },
          {
            value: QuoteMeasurement.QUANTITY,
            label: t("takeOffSection.quantity"),
          },
        ],
      },
    },

    items: {
      tableTitle: t("assembly.assemblyItems"),
      rowGenerator: (): AssemblyItemPayload => ({
        cost: 0,
        quantity: 0,
        raw_quantity: "",
        UOM: "",
        name: "",
        total: 0,
        margin_amount: 0,
      }),
      row: [
        {
          width: "170px",
          type: "creatable-select",
          label: t("common.name"),
          placeholder: t("placeholders.name"),
          valueKey: "name",
          updateTableRow: handlePriceListChange,
          creatableProps: {
            isEditable: true,
            autoSelectCreatedOption: true,
            options: assemblyOptions,
            asyncOptions: handlePriceSearch,
            optionComponent: CostingAutocompleteMenuOption,
            onCreateOption: handleCreatePriceOption,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
        },
        {
          width: "153px",
          type: "text",
          label: t("assembly.quantity"),
          placeholder: t("assembly.quantity"),
          valueKey: "quantity",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
          },
          append: {
            icon: "calculate",
            className: "text field-text input-group-append-compact",
            onClick: openCalculatorModal,
          },
        },
        {
          width: "160px",
          type: "creatable-select",
          label: t("assembly.UOM"),
          placeholder: t("assembly.UOM"),
          valueKey: "UOM",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
        },
        {
          width: "120px",
          type: "text",
          label: t("assembly.cost"),
          placeholder: t("assembly.cost"),
          valueKey: "cost",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
          },
        },
      ],
      dynamicFields: [
        {
          title: t("assembly.totalEx"),
          formatValue: (values: AssemblyItemPayload) => {
            const amount =
              calcItemTotal(
                {
                  margin_amount: values.margin_amount || 0,
                  cost: values.cost,
                  quantity: values.quantity,
                },
                0
              ) || 0;

            return t("common.currency", { amount });
          },
        },
      ],
    },
  };
};
