import React from "react";

type AvatarGroupProps = {
  xOffset?: number;
  yOffset?: number;
};

const DEFAULT_OFFSET = 10;

const AvatarGroup: React.FC<AvatarGroupProps> = (props) => {
  const { yOffset = 0, xOffset = DEFAULT_OFFSET, children } = props;

  const childCount = React.Children.count(children);

  const renderAvatars = () => {
    return React.Children.map(children, (child, index) => (
      <div
        style={{
          marginRight: index !== childCount - 1 ? -xOffset : 0,
          marginTop: yOffset * index,
        }}
        key={index}
      >
        {child}
      </div>
    ));
  };

  return <div className="d-flex">{renderAvatars()}</div>;
};

export default AvatarGroup;
