import React, { FC } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import Icon from "../../icons/Icon";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownItem } from "../dropdown";

type DashboardActionHeaderProps = {
  className?: string;
  onActionButton?: () => void;
  actionButtonTitle?: string;
  disabledActionButton?: boolean;
  additionalActionButtons?: ActionButton[];
  shouldRenderActionButton?: boolean;
  dropdownItems?: DropdownItem[];
  dropdownWidth?: string;
};

export type ActionButton = {
  title?: string;
  icon?: string;
  titleHint?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

const DashboardActionHeader: FC<DashboardActionHeaderProps> = ({
  children,
  className,
  onActionButton,
  actionButtonTitle,
  disabledActionButton = false,
  additionalActionButtons,
  shouldRenderActionButton = true,
  dropdownItems,
  dropdownWidth,
}) => {
  const { t } = useTranslation();

  const headerActions = React.useMemo<ActionButton>(
    () => ({
      title: actionButtonTitle ? actionButtonTitle : t("common.createNew"),
      onClick: onActionButton,
      icon: "add",
      disabled: disabledActionButton,
      className: "button large success",
    }),
    [actionButtonTitle, t, onActionButton, disabledActionButton]
  );

  const renderActionButton = (action: ActionButton) => {
    return (
      <Button
        disabled={action.disabled}
        key={action.title}
        className={action.className}
        onClick={action.onClick}
        title={action.titleHint}
      >
        {action.icon && <Icon name={action.icon} outlined />}
        {action.title && action.title}
      </Button>
    );
  };

  return (
    <div
      className={classNames(
        "dashboard-action-header d-flex justify-content-between",
        className
      )}
    >
      <div className="d-flex flex-wrap">{children}</div>
      <div className="d-flex">
        {additionalActionButtons &&
          additionalActionButtons.map((button, index) => (
            <div key={index}>{renderActionButton(button)}</div>
          ))}
        {onActionButton && shouldRenderActionButton && (
          <div>{renderActionButton(headerActions)}</div>
        )}
        {dropdownItems && (
          <div className="ml-3">
            <Dropdown
              label={t("common.options")}
              icon="expand_more"
              menuWidth={dropdownWidth ? dropdownWidth : "250px"}
              id="dashboardAction-dropdown"
              items={dropdownItems}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardActionHeader;
