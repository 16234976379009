import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { inRange, trim } from "lodash";
import { useMutation } from "@apollo/client";
import { generatePath, useHistory } from "react-router-dom";
import moment from "moment";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Container from "react-bootstrap/Container";
import { notify } from "../../../components/notification";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { BulkJobsScheduleCategoryItemPayload } from "../../../models/job";
import { EXTRA_COLOURS } from "../../../constants/colours";
import ScheduleImport from "./ScheduleImport";
import { FIELD_MAP_JOB_SCHEDULE, ScheduleCategoryImportMap } from "./constants";
import { parseDate } from "../../../utils/date";
import { BulkCreateUpdateJobScheduleCategoryResponse } from "../../../graphql/types/models/job-schedule";
import { BULK_CREATE_UPDATE_JOB_SCHEDULE_CATEGORY } from "../../../graphql/queries/job-schedule/mutations";
import { handleScheduleCategoryImport } from "../../../graphql/queries/job-schedule/utils";

const JobScheduleImportContainer: React.FC<DashboardContextValue> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const jobId = navigationContext?.job?._id;

  const [importSchedule, { loading: isLoadingImportSchedule }] = useMutation<
    BulkCreateUpdateJobScheduleCategoryResponse
  >(BULK_CREATE_UPDATE_JOB_SCHEDULE_CATEGORY, {
    update: handleScheduleCategoryImport(jobId),
    onCompleted: () => {
      notify({
        title: t("schedule.importSchedule"),
        content: t("schedule.success.importSchedule"),
      });
      if (jobId) {
        const url = generatePath("/jobs/:id/schedule", {
          id: jobId,
        });
        history.push(url);
      }
    },
    onError: () => {
      notify({
        error: true,
        title: t("schedule.importSchedule"),
        content: t("schedule.errors.importSchedule"),
      });
    },
  });

  const handleMapSubmit = React.useCallback(
    async (values: ScheduleCategoryImportMap[]) => {
      if (!jobId) return;

      const categories = new Map();
      values.forEach((value: ScheduleCategoryImportMap) => {
        const startDate = parseDate(value.startDate).startOf("day");
        let endDate = parseDate(value.endDate).endOf("day");
        if (endDate.isBefore(startDate)) {
          endDate = moment(startDate).endOf("day");
        }
        const item = {
          name: trim(value.name),
          description: value.description,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          progress: inRange(Number(value.progress), 0, 101)
            ? Number(value.progress)
            : 0,
          predecessor: value.predecessor,
        } as BulkJobsScheduleCategoryItemPayload;

        const categoryName = trim(value.category);
        if (categoryName && item.name && startDate && endDate) {
          const category = categories.has(categoryName)
            ? categories.get(categoryName)
            : {
                name: categoryName,
                colour: EXTRA_COLOURS[categories.size % EXTRA_COLOURS.length],
                startDate: null,
                endDate: null,
                scheduleItems: [],
              };

          if (
            !category.startDate ||
            moment(category.startDate).isAfter(startDate)
          ) {
            category.startDate = startDate.toISOString();
          }
          if (!category.endDate || moment(category.endDate).isBefore(endDate)) {
            category.endDate = endDate.toISOString();
          }
          category.scheduleItems.push(item);
          categories.set(categoryName, category);
        }
        return categories;
      });

      if (categories.size) {
        importSchedule({
          variables: {
            jobId,
            scheduleCategories: [...categories.values()],
          },
        });
      } else {
        notify({
          error: true,
          title: t("schedule.importSchedule"),
          content: t("schedule.errors.importScheduleNoRows"),
        });
      }
    },
    [jobId]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.importSchedule")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.SCHEDULE} />
      <ScheduleImport
        fieldMap={FIELD_MAP_JOB_SCHEDULE}
        onImport={handleMapSubmit}
        isLoading={isLoadingImportSchedule}
      />
    </Container>
  );
};

export default withDashboardContext(JobScheduleImportContainer);
