import React from "react";
import { SidebarNavItem } from "../dashboard/sidebar/SidebarNavListItem";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../containers/layouts/dashboard/DashboardContext";
import { findItemById } from "../dashboard/sidebar/utils/navigation-items";

type DashboardBackNavigationProps = DashboardContextValue & {
  routeId: string;
  item?: SidebarNavItem;
};

const DashboardBackNavigation: React.FC<DashboardBackNavigationProps> = ({
  routeId,
  item,
  setBackNavigationOptions,
  navigationItems,
}) => {
  React.useEffect(() => {
    const navItem = item || findItemById(navigationItems, routeId);

    if (navItem) {
      setBackNavigationOptions({ item: navItem });
    }
  }, [navigationItems]);

  React.useEffect(() => {
    return () => {
      setBackNavigationOptions(null);
    };
  }, []);

  return null;
};

export default withDashboardContext(DashboardBackNavigation);
