import { MutationTuple } from "@apollo/client";
import GSTC, {
  GSTCResult,
} from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min";
import { capitalize, chain, compact, filter, get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { ScheduleBulkAction, ScheduleCategory } from "../../../../models/job";
import {
  CalendarTask,
  CalendarTaskChartItem,
  getScheduleCategoryItem,
  prepareCategoryItem,
} from "../utils";

type ContextMenuOption = {
  label: string;
  handler: () => void;
};

export function useContextMenu(
  jobId: string | undefined,
  appRef: React.MutableRefObject<GSTCResult | null>,
  calendarStateRef: React.MutableRefObject<any>,
  getScheduleCategories: () => Promise<ScheduleCategory[]>,
  bulkUpdateJobSchedule: (props: any) => any
) {
  const { t } = useTranslation();
  const [anchorPoint, setAnchorPoint] = React.useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = React.useState(false);
  const isSelectingRef = React.useRef(false);
  const selectingRef = React.useRef<CalendarTaskChartItem[] | null>(null);
  const tooltipRef = React.useRef<any>();

  React.useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      if (isSelectingRef.current && tooltipRef.current) {
        tooltipRef.current.style.left = `${ev.clientX + 1}px`;
        tooltipRef.current.style.top = `${ev.clientY + 1}px`;
      }
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      document.getElementsByTagName("body")[0].style.cursor = "default";
    };
  }, []);

  const updateDependancyUI = React.useCallback(
    (updateItems: any[]) => {
      if (updateItems.length) {
        const calendarState = calendarStateRef.current as any;
        const items = calendarState?.get("config.chart.items");
        // workaround to force redraw
        setTimeout(
          () => appRef.current?.api.plugins.Selection.selectItems([]),
          50
        );
        for (let i = 0; i < updateItems.length; i++) {
          const predecessorRowId = GSTC.api.GSTCID(updateItems[i].predecessor);
          const rowId = GSTC.api.GSTCID(updateItems[i]._id);
          const row = items[rowId];
          const predecessor = items[predecessorRowId];

          // ensure both rows are visible to prevent crash
          if (row && predecessor) {
            const currentDependant = [...predecessor.dependant];
            currentDependant.push(rowId);
            items[predecessorRowId].dependant = currentDependant;
          }
        }
        calendarState?.update("config.chart.items", items);
      }
    },
    [appRef, calendarStateRef]
  );

  const handleLinkPredecessorTree = React.useCallback(
    async (rowIds: string[]) => {
      const schedule = (await getScheduleCategories()) as ScheduleCategory[];

      const orderedItems = chain(
        await Promise.all(
          rowIds.map((rowId) => {
            const oRowId = rowId.split("-")[1];
            const item = getScheduleCategoryItem(schedule, oRowId);
            if (item) {
              return prepareCategoryItem(item);
            }
            return null;
          })
        )
      )
        .compact()
        .sortBy((item) => +new Date(item.startDate))
        .reverse()
        .value();
      const updateItems = [];
      for (let i = 0; i < orderedItems.length - 1; i++) {
        updateItems.push({
          ...orderedItems[i],
          predecessor: orderedItems[i + 1]._id,
        });
      }

      if (updateItems.length) {
        updateDependancyUI(updateItems);
      }

      bulkUpdateJobSchedule({
        variables: {
          jobId,
          items: updateItems,
          action: capitalize(ScheduleBulkAction.UPDATE),
        },
      });
    },
    [
      calendarStateRef,
      getScheduleCategories,
      jobId,
      bulkUpdateJobSchedule,
      updateDependancyUI,
    ]
  );

  const handleUpdatePredecessor = React.useCallback(
    async (rowIds: string[], predecessorId?: string) => {
      const oPredecessorId = predecessorId?.split("-")[1];
      const schedule = (await getScheduleCategories()) as ScheduleCategory[];
      const predecessorItem = oPredecessorId
        ? getScheduleCategoryItem(schedule, oPredecessorId)
        : undefined;

      const updateItems = compact(
        await Promise.all(
          rowIds.map((rowId) => {
            const oRowId = rowId.split("-")[1];
            const item = getScheduleCategoryItem(schedule, oRowId);
            if (item) {
              return prepareCategoryItem(item);
            }
            return null;
          })
        )
      );
      if (updateItems.length) {
        updateDependancyUI(updateItems);
      }
      bulkUpdateJobSchedule({
        variables: {
          jobId,
          items: updateItems.map((item) => ({
            ...item,
            predecessor: predecessorItem?._id || null,
          })),
          action: capitalize(ScheduleBulkAction.UPDATE),
        },
      });
    },
    [
      calendarStateRef,
      getScheduleCategories,
      jobId,
      bulkUpdateJobSchedule,
      updateDependancyUI,
    ]
  );

  const handleContextAction = React.useCallback(
    (event: React.MouseEvent, data: CalendarTask) => {
      event.preventDefault();
      const selection =
        appRef.current?.api.plugins.Selection.getSelection().selected[
          "chart-timeline-items-row-item"
        ] || [];
      const selectedItems = filter(
        selection,
        (item: any) => item.parentId
      ) as CalendarTaskChartItem[];
      if (!selectedItems.length) return;
      setShowMenu(false);
      if (!data.parentId) return;
      selectingRef.current = selectedItems;
      setAnchorPoint({
        x: event.pageX,
        y: event.pageY,
      });
      setShowMenu(true);
    },
    []
  );

  const handleSelectContext = React.useCallback(
    (event: React.MouseEvent, data: CalendarTask) => {
      event.preventDefault();
      if (data.parentId && isSelectingRef.current && selectingRef.current) {
        handleUpdatePredecessor(
          selectingRef.current.map((item) => item.id.toString()),
          data.id.toString()
        );
        isSelectingRef.current = false;
        selectingRef.current = null;
        document.getElementsByTagName("body")[0].style.cursor = "default";
      }
    },
    [isSelectingRef, selectingRef, handleUpdatePredecessor]
  );

  const handleClearContext = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setShowMenu(false);
    },
    [isSelectingRef]
  );

  const handleSelectPredecessor = React.useCallback(() => {
    isSelectingRef.current = true;
    document.getElementsByTagName("body")[0].style.cursor = "crosshair";
    setShowMenu(false);
  }, [isSelectingRef]);

  const handleRemovePredecessor = React.useCallback(() => {
    if (!selectingRef.current?.length) return;
    handleUpdatePredecessor(
      selectingRef.current.map((item) => item.id.toString())
    );
    setShowMenu(false);
  }, [isSelectingRef, handleUpdatePredecessor]);

  const handleSelectPredecessorTree = React.useCallback(() => {
    if (!selectingRef.current?.length) return;
    handleLinkPredecessorTree(
      selectingRef.current.map((item) => item.id.toString())
    );
    setShowMenu(false);
  }, [isSelectingRef, handleLinkPredecessorTree]);

  const renderTooltip = React.useCallback(() => {
    return (
      <div
        ref={tooltipRef}
        style={{
          opacity: isSelectingRef.current ? 1 : 0,
          position: "absolute",
          background: "#314961",
          color: "white",
          padding: 10,
          marginLeft: "10px",
          zIndex: 120,
          borderRadius: 5,
        }}
      >
        {t("schedule.selectPredecessorTask")}
      </div>
    );
  }, [isSelectingRef]);

  const renderContextMenu = React.useCallback(() => {
    const Tooltip = renderTooltip();
    if (!showMenu) return Tooltip;
    const options: ContextMenuOption[] = [
      {
        label: t("schedule.setPredecessor"),
        handler: handleSelectPredecessor,
      },
    ];
    if (selectingRef.current && selectingRef.current.length > 1) {
      options.push({
        label: t("schedule.linkPredecessorTree"),
        handler: handleSelectPredecessorTree,
      });
    }

    options.push({
      label: t("schedule.removePredecessor"),
      handler: handleRemovePredecessor,
    });

    return (
      <>
        {Tooltip}
        <div
          style={{
            position: "absolute",
            paddingLeft: 10,
            paddingRight: 10,
            background: "#314961",
            borderRadius: 5,
            color: "white",
            zIndex: 1,
            left: `${anchorPoint.x}px`,
            top: `${anchorPoint.y}px`,
          }}
        >
          {options.map((option) => (
            <div
              key={option.label}
              onClick={option.handler}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                cursor: "pointer",
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      </>
    );
  }, [
    selectingRef,
    isSelectingRef,
    anchorPoint,
    showMenu,
    handleSelectPredecessor,
    handleRemovePredecessor,
    handleLinkPredecessorTree,
    renderTooltip,
  ]);

  return {
    handleClearContext,
    handleSelectContext,
    handleContextAction,
    renderContextMenu,
  };
}
