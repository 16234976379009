import React from "react";
import classNames from "classnames";

import "./styles.scss";
import Xero from "./icons/integrations/xero.svg";
import MYOB from "./icons/integrations/myob.png";

export type IntegrationIconNames = "XERO" | "MYOB";

type IntegrationIconProps = {
  name: IntegrationIconNames;
  className?: string;
};

const IntegrationIcon: React.FC<IntegrationIconProps> = ({
  name,
  className,
}) => {
  let Icon = null;

  switch (name) {
    case "XERO":
      Icon = Xero;
      break;
    case "MYOB":
      Icon = MYOB;
      break;
  }

  return (
    <div className={classNames("integration-icon", className)}>
      <img src={Icon} height="80px" />
    </div>
  );
};

export default IntegrationIcon;
