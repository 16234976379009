import { QuoteMeasurement } from "./salesQuote";
import { Rounding } from "../components/calculator/utils";
import { SupplierDetails } from "./supplier";
import { JobDetails } from "./job";
import { ClaimItem } from "./claim";

export type ReceiptDocuments = {
  _id: string;
  name: string;
  url: string;
};

export type PurchaseOrderReceiptItem = {
  _id: string;
  itemNumber: string;
  name: string;
  UOM: string;
  cost: number;
  margin_amount: number;
  quantity: number;
  purchaseOrderItem: PurchaseOrderItem;
};

export type PurchaseOrderReceiptTableItem = {
  _id: string;
  itemNumber?: string;
  dateReceived?: string;
  reference?: string;
  purchaseOrder?: {
    _id: string;
    orderNumber: number;
    job?: {
      _id: string;
    };
    supplier?: {
      _id: string;
      business_name: string;
    };
  };

  name: string;
  UOM: string;
  cost: number;
  total: number;
  quantity: number;
  purchaseOrderItem?: PurchaseOrderItem;
};

export type PurchaseOrderReceipt = {
  _id: string;
  reference: string;
  dateReceived: string;
  dueDate: string;
  receiptDocuments: ReceiptDocuments[];
  items: PurchaseOrderReceiptItem[];
  hasGST: boolean;
  isNonBillable: boolean;
  subTotal: number;
  GST: number;
  total: number;
  purchaseOrder?: PurchaseOrderReal;
  progressClaimItem?: ClaimItem;
  job?: {
    _id: string;
  };
  supplier?: {
    _id: string;
    business_name: string;
  };
};

export type PurchaseOrderReal = {
  _id: string;
  supplier: SupplierDetails | null;
  reference: string;
  status: PurchaseOrderStatus;
  orderNumber: number;
  attention: string;
  deliveryInstructions: string;
  deliveryAddress: string;
  deliveryDate: string;
  date: string;
  contactNumber: string;
  hasGST: boolean;
  hideCost: boolean;
  costIncGST: boolean;
  items: PurchaseOrderItem[];
  receipts: PurchaseOrderReceipt[];
  subTotal: number;
  GST: number;
  total: number;
  internalNote: string;
  job?: JobDetails;
  externalLastSyncDate: string;
  __typename: string;
};

export type PurchaseOrderItem = {
  _id: string;
  UOM: QuoteMeasurement;
  cost: number;
  name: string;
  quantity: number;
  rounding: Rounding;
  wastage: number;
  items: PurchaseOrderSubItem[];
  costingItemId: string;
  costingCategoryName: string;
  hasGST: boolean;
  __typename: string;
};

export type PurchaseOrderSubItem = {
  _id: string;
  UOM: QuoteMeasurement;
  cost: number;
  name: string;
  quantity: number;
  rounding: Rounding;
  wastage: number;
  __typename: string;
};

export type PurchaseOrderItemSubItem = {
  UOM: string;
  cost: number;
  name: string;
  quantity: number;
};

export type PurchaseOrderItemPayload = {
  _id?: string;
  UOM: QuoteMeasurement | null | string;
  cost: number | null;
  cost_inc?: number | null;
  name: string;
  category?: {
    name: string;
  };
  quantity: number | null;
  raw_quantity?: string | null;
  rounding?: string;
  wastage: number;
  hasGST?: boolean; // TODO: Fix
  gstFree?: boolean;
  margin_amount?: 0;
  costingItemId?: string;
  costingCategoryName?: string | null;
  items?: PurchaseOrderItemSubItem[];
};

export type PurchaseOrderPayload = {
  _id?: string;
  supplier: string;
  reference: string;
  attention: string;
  deliveryInstructions: string;
  deliveryAddress: string;
  deliveryDate: string;
  dateReceived?: string;
  dueDate?: string;
  date: string;
  status?: string;
  hasGST: boolean;
  costIncGST: boolean;
  hideCost: boolean;
  contactNumber: string;
  items: PurchaseOrderItemPayload[];
  costsHaveGST?: boolean;
};

export type PurchaseOrderPreparatoryItemPayload = {
  _id?: string;
  name: string;
  cost: number;
  cost_inc?: number;
  gstFree?: boolean;
  hasGST: boolean;
  quantity: number;
  UOM: string;
  category?: string;
  purchaseOrderItem?: string;
};

export type PurchaseOrderReceivePreparatoryPayload = {
  reference: string;
  dateReceived: string;
  dueDate: string;
  purchaseOrderStatus: PurchaseOrderStatus;
  isNonBillable: boolean;
  items: PurchaseOrderPreparatoryItemPayload[];
};

export type PurchaseOrderReceivedCreate = {
  status: PurchaseOrderStatus;
  reference: string;
  dateReceived: string;
  dueDate?: string;
  hasGST: boolean;
  date: string;
  supplier: string;
  items?: PurchaseOrderItemPayload[];
};

export type PurchaseOrderReceiptItemPayload = {
  name: string;
  UOM: string;
  cost: number;
  quantity: number;
  purchaseOrderItem?: string;
  costingCategoryName?: string;
  itemNumber?: number;
  hasGST: boolean;
};

export type PurchaseOrderReceiptPayload = {
  reference: string;
  dateReceived: string;
  isNonBillable: boolean;
  internalNote?: string;
  items: PurchaseOrderReceiptItemPayload[];
  receiptDocumentId?: string[];
};

export type ReceivePurchaseOrderPayload = {
  jobId: string;
  purchaseOrderId: string;
  receipt: PurchaseOrderReceiptPayload;
};

export type PurchaseOrderListItem = {
  _id: string;
  status: PurchaseOrderStatus;
  reference: string;
  supplier: SupplierDetails | null;
  orderNumber: number;
  date: string;
  subTotal: number;
  GST: number;
  total: number;
  receipts: PurchaseOrderReceipt[];
};

export enum PurchaseOrderStatus {
  DRAFT = "DRAFT",
  CANCELLED = "CANCELLED",
  SENT = "SENT",
  RECEIVED = "RECEIVED",
  PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
}
