import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { get } from "lodash";
import { useMutation } from "@apollo/client";

import LeftModal from "../../left-modal";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalFooter from "../../left-modal/LeftModalFooter";

import {
  PurchaseOrderReal,
  PurchaseOrderReceipt,
} from "../../../models/purchaseOrder";
import { createReceiptItemsTable, createReceiptTable } from "../utils";

import ModalTotals from "../../modals/modal-totals";
import { TableRowActionData } from "../../dashboard/table-card/utils";
import CardTable from "../../dashboard/table-card/CardTable";
import { useDownloadFileFromURL } from "../../../hooks/useDownloadFile";

import DashboardCardField from "../../dashboard/card/DashboardCardField";
import { DATE_FORMAT_DATE_NUMERIC } from "../../../constants/dates";
import ConfirmDialog, { ConfirmDialogRef } from "../../confirm-dialog";
import { CancelPurchaseOrderReceiptResponse } from "../../../graphql/types/models/order";
import { CANCEL_ORDER_RECEIPT } from "../../../graphql/queries/order/mutations";
import { notify } from "../../notification";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import "./styles.scss";

type ReceiptsTable = {
  _id: string;
  name: string;
};

type ReceiptModalProps = {
  order: PurchaseOrderReal;
};
export type ReceiptModalRef = ModalDisplayRef & {
  show: (show: boolean, receipt?: PurchaseOrderReceipt) => void;
};

const ReceiptModal: React.FC<ReceiptModalProps> = (props, ref) => {
  const { order } = props;

  const [receipt, setReceipt] = React.useState<PurchaseOrderReceipt | null>();

  const { shouldShow, show, hide } = useModalDisplay(ref, {
    show: (showModal: boolean, receipt?: PurchaseOrderReceipt) => {
      if (showModal) {
        show();
      } else {
        hide();
      }
      setReceipt(receipt || null);
    },
  });

  const { t } = useTranslation();

  const { downloadFileFromURL } = useDownloadFileFromURL();
  const confirmRef = React.useRef<ConfirmDialogRef>();

  const [cancelReceipt] = useMutation<CancelPurchaseOrderReceiptResponse>(
    CANCEL_ORDER_RECEIPT,
    {
      onCompleted: () => {
        notify({
          title: t("orders.deleteReceipt"),
          content: t(`orders.success.cancelOrderReceipt`),
        });
        hide();
      },
      onError: (e) => {
        notify({
          title: t("orders.deleteReceipt"),
          content: get(e, "message", t("orders.errors.cancelOrderReceipt")),
          error: true,
        });
      },
    }
  );

  const handleDownloadFile = React.useCallback((row: ReceiptsTable) => {
    downloadFileFromURL(row._id);
  }, []);

  const handleDelete = React.useCallback(() => {
    if (!receipt) return;
    confirmRef.current?.show(true, () => {
      cancelReceipt({
        variables: {
          jobId: order.job?._id,
          purchaseOrderId: order._id,
          purchaseOrderReceiptId: receipt._id,
        },
      });
    });
  }, [receipt, order]);

  const tableRowActions: TableRowActionData<ReceiptsTable>[] = React.useMemo(
    () => [
      {
        icon: "download",
        id: "remove",
        onClick: handleDownloadFile,
      },
    ],
    [handleDownloadFile]
  );

  if (!receipt) return null;

  return (
    <LeftModal show={shouldShow} onHide={hide} className="receipt-modal">
      <LeftModalHeader title={t("orders.receiptsDetails")} onClose={hide} />
      <LeftModalBody>
        <Col>
          <Row className="w-100">
            <Col lg={4} xs={12} className="form-column">
              <DashboardCardField
                placeholder={t("orders.noReference")}
                title={t("orders.reference")}
                content={receipt.reference}
              />
            </Col>
            <Col lg={4} xs={12} className="form-column">
              <DashboardCardField
                placeholder={t("common.na")}
                title={t("orders.dateReceived")}
                content={
                  receipt.dateReceived
                    ? moment(receipt.dateReceived).format(
                        DATE_FORMAT_DATE_NUMERIC
                      )
                    : ""
                }
              />
            </Col>
            <Col lg={4} xs={12} className="form-column">
              <DashboardCardField
                placeholder={t("common.na")}
                title={t("common.dueDate")}
                content={
                  receipt.dueDate
                    ? moment(receipt.dueDate).format(DATE_FORMAT_DATE_NUMERIC)
                    : ""
                }
              />
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-between table-form-controls">
              <div className="table-form-title">{t("common.items")}</div>
            </div>
            <CardTable
              rowCount
              table={createReceiptItemsTable(receipt.items, t, [
                "margin_amount",
              ])}
              showCountTitle={true}
            />
          </Row>
          <Row>
            <Col lg={7} xs={12} className="col-form">
              {receipt.receiptDocuments && receipt.receiptDocuments.length > 0 && (
                <>
                  <div className="field-text field-text--underlined receipts-title">
                    {t("orders.receipts")}
                  </div>

                  <Row className="row-m uploader-table">
                    <CardTable
                      table={createReceiptTable(receipt.receiptDocuments, t)}
                      rowActions={tableRowActions}
                      alignEnd={true}
                      hideColumns={true}
                    />
                  </Row>
                </>
              )}
            </Col>

            <Col lg={5} xs={12} className="col-form">
              <ModalTotals
                items={receipt.items}
                margin={0}
                title={t("orders.amount")}
              />
            </Col>
          </Row>
        </Col>
      </LeftModalBody>
      <LeftModalFooter className="justify-content-between">
        <Button
          onClick={handleDelete}
          className="button large large-wide info-reverse"
        >
          {t("orders.deleteReceipt")}
        </Button>

        <Button
          size="lg"
          variant="success"
          onClick={hide}
          className="button large success"
        >
          {t("common.close")}
        </Button>
      </LeftModalFooter>

      <ConfirmDialog
        ref={confirmRef}
        title={t("orders.deleteReceipt")}
        confirm={t("common.yes")}
      >
        <span className="field-text">{t("orders.deleteReceiptMessage")}</span>
      </ConfirmDialog>
    </LeftModal>
  );
};

export default React.forwardRef(ReceiptModal);
