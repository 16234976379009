import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { Container, Row, Col } from "react-bootstrap";
import { map, get, chain, uniqBy, pick, trim, find, omit } from "lodash";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import ModalForm from "../../../modals/modal-form";
import { CreateUpdateOrderResponse } from "../../../../graphql/types/models/order";
import { notify } from "../../../../components/notification";
import { CREATE_UPDATE_ORDER } from "../../../../graphql/queries/order/mutations";
import {
  PurchaseOrderPayload,
  PurchaseOrderItemPayload,
  PurchaseOrderStatus,
  PurchaseOrderReceivedCreate,
} from "../../../../models/purchaseOrder";
import {
  GenericFormFields,
  GenericFormTable,
  renderField,
  renderTableForm,
} from "../../../generic-form/GenericFormBody";
import { UOMOption } from "../../../../utils/types/options";
import createOrderFields from "../utils";
import CalculatorModal, { CalcFieldData } from "../../../modals/calculator";
import { CalcBag, Rounding } from "../../../calculator/utils";
import createOrderSchema from "../Order.schema";
import { VariationPayload } from "../../../../models/variations";
import { MEASUREMENT_OPTIONS } from "../../../../utils/options";
import ModalTotals from "../../../modals/modal-totals";
import { handleOrderAdd } from "../../../../graphql/queries/order/utils";

import CreateSupplierModal, {
  CreateSupplierModalRef,
} from "../../../../components/contacts/create-supplier-modal";

import {
  findPriceItemFromCache,
  searchPriceItem,
} from "../../../../graphql/queries/price-list/utils";
import { SelectOption } from "../../../generic-form/inputs/creatable-select";
import {
  findCostingItemFromCostingsCache,
  searchCostingItem,
} from "../../../../graphql/queries/job-costing/utils";
import { JobCostingItem, SearchJobCostingItem } from "../../../../models/job";
import { useJobQuery } from "../../../../hooks/queries/useJobQuery";
import { getFullAddress } from "../../../../utils/text";
import {
  CostingAllocationType,
  useJobCostingAllocate,
} from "../../../../hooks/useJobCostingAllocate";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../../hooks/useModalDisplay";
import { useSuppliersQuery } from "../../../../hooks/queries/useSuppliersQuery";
import FileInput from "../../../uploaders/file-input";
import { uploadFiles } from "../../../../utils/files";
import filesize from "filesize";
import TableCard from "../../../dashboard/table-card";
import { TableRowActionData } from "../../../dashboard/table-card/utils";
import {
  CreateUpdateDocumentResponse,
  GetSystemSubFolderResponse,
} from "../../../../graphql/types/models/documents";
import { GET_SYSTEM_SUB_FOLDER } from "../../../../graphql/queries/documents/queries";
import {
  EnumDocumentAccessRole,
  SystemFolderType,
  SystemSubFolderType,
} from "../../../../models/documents";
import { CREATE_UPDATE_DOCUMENT } from "../../../../graphql/queries/documents/mutations";
import UploadSpinner from "../../../upload-spinner";
import * as uuid from "uuid";
import {
  calcGSTEx,
  calcWithGST,
  GST_PERCENT,
} from "../../../../utils/calculations";
import { SupplierDetails } from "../../../../models/supplier";
import { TakeoffListItem } from "../../../../models/take-off";
import "../styles.scss";
import { useCostChangeGST } from "../../../../hooks/useCostChangeGST";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

export type PurchaseOrderFormRef = ModalDisplayRef & {
  show: (
    show: boolean,
    order?: PurchaseOrderPayload,
    defaultStatus?: PurchaseOrderStatus
  ) => void;
};
type PurchaseOrderFormProps = {
  costingItems?: JobCostingItem[] | null;
  jobId: string | null | undefined;
  onSubmit: (values: CreateUpdateOrderResponse) => void;
  takeoffs?: TakeoffListItem[] | null;
  salesQuoteId?: string | null;
  supplierId?: string;
  includeComputedQuantity?: boolean;
};

type FileTable = {
  id: string;
  name: string;
  size: number;
};

const PurchaseOrderForm: React.FC<PurchaseOrderFormProps> = (props, ref) => {
  const {
    onSubmit,
    costingItems,
    jobId,
    takeoffs,
    salesQuoteId,
    supplierId,
    includeComputedQuantity,
  } = props;

  const client = useApolloClient();

  const [files, setFiles] = React.useState<{ id: string; file: File }[]>([]);
  const [items, setItems] = React.useState<SelectOption[]>([]);
  const [hideCost, setHideCost] = React.useState(false);
  const [order, setOrder] = React.useState<PurchaseOrderPayload | null>(null);
  const [activeStatus, setActiveStatus] = React.useState<
    PurchaseOrderStatus | string
  >("");
  const [
    defaultStatus,
    setDefaultStatus,
  ] = React.useState<PurchaseOrderStatus | null>(null);

  const {
    costsHaveGST,
    handleCostChange,
    handleCostsHaveGST,
    resetCostsHaveGST,
  } = useCostChangeGST(order?.costIncGST);

  const purchaseOrderHideCostValueKey = "purchaseOrderHideCostValueKey";
  const {
    storedValue: storedHideCostValue,
    setStoredValue: setStoredPricesType,
  } = useLocalStorage(purchaseOrderHideCostValueKey, {
    hideCost,
  });

  const { job } = useJobQuery(jobId ?? undefined);
  const { suppliers } = useSuppliersQuery();
  const createSupplierModalRef = React.useRef<any>(null);
  const [addFile] = useMutation<CreateUpdateDocumentResponse>(
    CREATE_UPDATE_DOCUMENT,
    {
      onCompleted: async (data) => {
        if (
          files &&
          files.length > 0 &&
          data.createUpdateDocuments.length > 0
        ) {
          await uploadFiles(
            data.createUpdateDocuments.map((document) => ({
              name: document.name,
              upload_url: document.upload_url,
            })),
            files.map((fileItem) => fileItem.file)
          );
        }
      },
    }
  );

  const { shouldShow, show, hide } = useModalDisplay(ref, {
    show: (
      showModal: boolean,
      order?: PurchaseOrderPayload,
      defaultStatus?: PurchaseOrderStatus
    ) => {
      if (showModal) {
        show();
        resetCostsHaveGST();
      } else {
        hide();
      }
      if (defaultStatus) {
        setActiveStatus(defaultStatus);
        setDefaultStatus(defaultStatus);
      } else {
        setActiveStatus(PurchaseOrderStatus.DRAFT);
        setDefaultStatus(PurchaseOrderStatus.DRAFT);
      }
      setOrder(order || null);
      if (order?.status) {
        setActiveStatus(order.status);
      }
    },
  });

  const handleOnClose = (): void => {
    hide();
    setOrder(null);
    setFiles([]);
    resetCostsHaveGST();
    setActiveStatus("");
    setDefaultStatus(null);
  };

  const isReceivedStatus =
    activeStatus === PurchaseOrderStatus.RECEIVED ||
    activeStatus === PurchaseOrderStatus.PARTIALLY_RECEIVED;

  const {
    categoryList,
    openAllocateCosting,
    getAllocateProps,
    handleCreateCategory,
    handleSelectCategory,
    renderSelectCostingModal,
  } = useJobCostingAllocate({
    type: CostingAllocationType.PurchaseOrder,
    jobId,
    isTable: true,
  });

  const [createOrder] = useMutation<CreateUpdateOrderResponse>(
    CREATE_UPDATE_ORDER,
    {
      onCompleted: (result) => {
        notify({
          title: t("orders.createOrder"),
          content: t("orders.success.createOrder"),
        });
        onSubmit(result);
        setStoredPricesType({
          hideCost: result.jobCreateUpdatePurchaseOrder.hideCost,
        });
      },
      onError: (e) => {
        notify({
          error: true,
          title: t("orders.createOrder"),
          content: get(e, "message", t("orders.errors.createOrder")),
        });
      },
      update: handleOrderAdd(jobId ?? ""),
    }
  );

  const [updateOrder] = useMutation<CreateUpdateOrderResponse>(
    CREATE_UPDATE_ORDER,
    {
      onCompleted: (result) => {
        notify({
          title: t("orders.updateOrder"),
          content: t("orders.success.updateOrder"),
        });
        onSubmit(result);
        setStoredPricesType({
          hideCost: result.jobCreateUpdatePurchaseOrder.hideCost,
        });
      },
    }
  );

  const { data: systemFolderResponse } = useQuery<GetSystemSubFolderResponse>(
    GET_SYSTEM_SUB_FOLDER,
    {
      variables: {
        refId: jobId,
        systemType: SystemFolderType.JOB,
        subType: SystemSubFolderType.JOB_PURCHASE_ORDER,
      },
    }
  );

  const onPurchaseOrderSubmit = React.useCallback(
    async (values: PurchaseOrderPayload) => {
      const preparedItems = map(values.items, (item, index) => {
        const preparedItem = {
          ...omit(item, ["cost_inc", "gstFree"]),
          cost: costsHaveGST
            ? calcGSTEx(item.cost_inc || 0, GST_PERCENT)
            : Number(item.cost) || 0,
          items: map(item.items, (i) =>
            pick(i, ["name", "quantity", "cost", "UOM"])
          ),
          quantity: Number(item.quantity) || 0,
          raw_quantity: item.quantity?.toString(),
          wastage: item.wastage || 0,
          itemNumber: index + 1,
          hasGST: values.hasGST ? !item.gstFree : false,
        };

        if (!item.rounding || item.rounding === Rounding.NONE) {
          delete preparedItem.rounding;
        }

        return preparedItem;
      });

      delete values.costsHaveGST;
      const request = {
        variables: {
          jobId,
          purchaseOrder: {
            ...values,
            costIncGST: costsHaveGST,
            items: preparedItems,
          },
        },
      };
      if (order) {
        resetCostsHaveGST();
        return updateOrder(request);
      }
      const response = await createOrder(request);
      resetCostsHaveGST();
      return response;
    },
    [jobId, createOrder, order, updateOrder, costsHaveGST]
  );

  const onReceivePurchaseOrderSubmit = React.useCallback(
    async (order: PurchaseOrderReceivedCreate | PurchaseOrderPayload) => {
      let receiptDocument: string[] = [];
      const systemFolder = systemFolderResponse?.getSystemSubFolder;

      const preparedItems = map(order.items, (item, index) => {
        const preparedItem = {
          ...omit(item, ["cost_inc", "gstFree"]),
          cost: costsHaveGST
            ? calcGSTEx(item.cost_inc || 0, GST_PERCENT)
            : item.cost,
          items: map(item.items, (i) =>
            pick(i, ["name", "quantity", "cost", "UOM"])
          ),
          raw_quantity: item.quantity?.toString(),
          hasGST: order.hasGST ? !item.gstFree : false,
          itemNumber: index + 1,
        };
        if (!item.rounding || item.rounding === Rounding.NONE) {
          delete preparedItem?.rounding;
        }

        return preparedItem;
      });

      if (systemFolder) {
        const response = await addFile({
          variables: {
            documents: files.map((fileItem) => ({
              folderId: systemFolder._id,
              name: fileItem.file.name,
              size: fileItem.file.size,
              type: fileItem.file.type,
              accessRole: EnumDocumentAccessRole.MANAGER,
            })),
          },
        });

        if (response.data?.createUpdateDocuments) {
          const receiptDocumentId = response.data?.createUpdateDocuments.map(
            (document) => document._id
          );
          receiptDocument = receiptDocumentId;
        }
      }

      const request = {
        variables: {
          jobId,
          purchaseOrder: {
            status: order.status,
            date: order.date,
            reference: order.reference,
            supplier: order.supplier,
            dateReceived: order.dateReceived,
            dueDate: order.dueDate,
            items: preparedItems,
            receiptDocumentId: receiptDocument,
            costIncGST: costsHaveGST,
          },
        },
      };
      setFiles([]);
      return createOrder(request);
    },
    [
      addFile,
      costsHaveGST,
      createOrder,
      files,
      jobId,
      systemFolderResponse?.getSystemSubFolder,
    ]
  );

  const [currentUOMOptions, setCurrentUOMOptions] = useState<UOMOption[]>(
    MEASUREMENT_OPTIONS.map((item) => {
      return {
        value: item.value.toString(),
        label: item.label.toString(),
      };
    })
  );

  const { t } = useTranslation();

  const supplierRef = React.useRef<CreateSupplierModalRef>(null);

  const [formFields, setFormFields] = useState<
    GenericFormFields<PurchaseOrderPayload>
  >({});

  const [calcData, setFieldData] = useState<CalcFieldData>({
    fieldName: "",
  });

  const [showCalculator, setCalculatorVisibility] = useState(false);

  const openCalculatorModal = React.useCallback(
    (fieldName: string, fieldValue?: string, rowIndex?: number) => {
      setFieldData({
        fieldName,
        rowIndex,
      });
      setCalculatorVisibility(true);
    },
    []
  );

  const handleCalculatorSubmit = React.useCallback(
    (formikProps: FormikProps<VariationPayload>, calcBag: CalcBag) => {
      formikProps.setFieldValue(
        `items[${calcData.rowIndex}].rounding`,
        calcBag.rounding
      );
      formikProps.setFieldValue(
        `items[${calcData.rowIndex}].wastage`,
        calcBag.wastage
      );
      setCalculatorVisibility(false);
    },
    [calcData]
  );

  const closeCalcModal = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, []);

  const onCreateNewSupplier = React.useCallback(() => {
    supplierRef.current?.show(true);
  }, []);

  const handleHideCostChange = React.useCallback(
    (value: boolean) => {
      setHideCost(value);
    },
    [setHideCost]
  );

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        currentUOMOptions.concat([{ label: uom, value: uom }]),
        "value"
      );
      setCurrentUOMOptions(newUOMs);
    },
    [currentUOMOptions, setCurrentUOMOptions]
  );

  const handlePriceSearch = React.useCallback(
    (value: string) => searchPriceItem(client, value),
    [client]
  );

  const handleCostingSearch = React.useCallback(
    async (value: string) => {
      if (!jobId) return [] as SearchJobCostingItem[];
      return searchCostingItem(client, jobId, value);
    },
    [client, jobId]
  );

  const addItem = React.useCallback(
    (name: string) => {
      const newItem = {
        label: name,
        value: name,
        hidden: true,
      } as SelectOption;
      setItems(uniqBy([...items, newItem], "value"));
    },
    [items]
  );

  const initialValues = React.useMemo(() => {
    if (!order) {
      const template = {
        supplier: supplierId || "",
        reference: "",
        attention: "",
        date: Date(),
        dateReceived: "",
        deliveryDate: "",
        dueDate: "",
        internalNote: "",
        contactNumber: "",
        deliveryAddress: job ? getFullAddress(job) : "",
        deliveryInstructions: "",
        status: defaultStatus ?? "DRAFT",
        hasGST: true,
        costIncGST: false,
        costsHaveGST: false,
        hideCost: storedHideCostValue?.hideCost || false,
        items:
          costingItems && costingItems.length > 0
            ? costingItems.reduce((result, costingItem) => {
                if (!costingItem.items?.length) {
                  result.push({
                    costingItemId: costingItem._id,
                    costingCategoryName: costingItem?.category?.name,
                    gstFree: !costingItem.hasGST,
                    ...pick(costingItem, [
                      "UOM",
                      "cost",
                      "name",
                      "quantity",
                      "rounding",
                      "wastage",
                      "margin_amount",
                    ]),
                  } as PurchaseOrderItemPayload);
                } else {
                  costingItem.items?.forEach((assemblyItem) => {
                    result.push({
                      costingItemId: costingItem?._id,
                      costingCategoryName: costingItem?.category?.name,
                      gstFree: !costingItem.hasGST,
                      ...pick(costingItem, [
                        "rounding",
                        "wastage",
                        "margin_amount",
                      ]),
                      ...pick(assemblyItem, ["UOM", "cost", "name"]),
                      quantity: includeComputedQuantity
                        ? assemblyItem.computed_quantity
                        : assemblyItem.quantity,
                    } as PurchaseOrderItemPayload);
                  });
                }
                return result;
              }, [] as PurchaseOrderItemPayload[])
            : [
                {
                  UOM: "qty",
                  name: "",
                  cost: 0,
                  cost_inc: null,
                  quantity: null,
                  costingCategoryName: "",
                  rounding: Rounding.NONE,
                  wastage: 0,
                },
              ],
      };

      return template;
    }

    return {
      ...order,
      costsHaveGST: order.costIncGST,
      items: order.items.map((item) => {
        return {
          ...item,
          cost_inc: calcWithGST(item?.cost || 0, GST_PERCENT),
          gstFree: item.hasGST === false,
        };
      }),
    };
  }, [
    order,
    job,
    costingItems,
    supplierId,
    storedHideCostValue,
    includeComputedQuantity,
    defaultStatus,
  ]);

  const handlePriceSelect = React.useCallback(
    async (fieldValue, rowIndex, formikProps) => {
      if (!fieldValue || !jobId) return;

      const fieldsArray = `items[${rowIndex}]`;
      const { setFieldValue, values } = formikProps;
      const costingItemId = get(values, fieldsArray)?.costingItemId;

      // skip further price/costing checks if line item is part of an assembly
      if (costingItemId) {
        const assemblyItem = find(initialValues.items, {
          name: fieldValue,
          costingItemId,
        });
        if (assemblyItem) {
          return;
        }
      }

      const priceItem = findPriceItemFromCache(client, fieldValue);
      const costingItem = await findCostingItemFromCostingsCache(
        client,
        jobId,
        fieldValue
      );

      if (!priceItem && !costingItem) {
        // check if value has changed
        // const costingId = get(values, fieldsArray)?.costingItemId;
        // if (costingId) {
        //   const item = findCostingItemFromCache(client, costingId);
        //   if (!item || item.name !== fieldValue) {
        //     setFieldValue(`${fieldsArray}.costingItemId`, "");
        //   }
        // }
        return;
      }

      if (priceItem || costingItem) {
        const UOM = trim(priceItem?.UOM || costingItem?.UOM) || "";
        // setFieldValue(`${fieldsArray}.name`, trim(priceItem?.name || costingItem?.name) || "");
        setFieldValue(`${fieldsArray}.UOM`, UOM);
        setFieldValue(
          `${fieldsArray}.cost`,
          priceItem?.cost || costingItem?.cost || ""
        );

        if (costingItem) {
          const item = categoryList?.find(
            (c) => c.label === costingItem?.category?.name
          );
          setFieldValue(`${fieldsArray}.costingCategoryName`, item?.value);
          setFieldValue(`${fieldsArray}.costingItemId`, costingItem._id);
        }
        setFieldValue(
          `${fieldsArray}.name`,
          priceItem?.name || costingItem?.name
        );

        addItem(priceItem?.name || costingItem?.name || "");
        UOM && handleUOMCreate(UOM); // add UOM to dropdown
        return;
      }
      // setFieldValue(`${fieldsArray}.name`, fieldValue);
      addItem(fieldValue);
    },
    [jobId, addItem, initialValues, categoryList, handleUOMCreate]
  );

  React.useEffect(() => {
    setFormFields(
      createOrderFields(
        t,
        openCalculatorModal,
        openAllocateCosting,
        suppliers,
        onCreateNewSupplier,
        currentUOMOptions,
        categoryList,
        hideCost,
        costsHaveGST,
        handleHideCostChange,
        handleCostsHaveGST,
        handleCostChange,
        handleUOMCreate,
        handleCreateCategory,
        handleCostingSearch,
        handlePriceSearch,
        items,
        handlePriceSelect,
        handleSelectCategory,
        getAllocateProps,
        handleStatusSelect,
        activeStatus,
        !!order
      )
    );
  }, [
    t,
    suppliers,
    onCreateNewSupplier,
    openCalculatorModal,
    openAllocateCosting,
    currentUOMOptions,
    categoryList,
    hideCost,
    costsHaveGST,
    handleHideCostChange,
    handleCostsHaveGST,
    handleCostChange,
    handleUOMCreate,
    handleCreateCategory,
    handleCostingSearch,
    handlePriceSearch,
    items,
    handlePriceSelect,
    handleSelectCategory,
    getAllocateProps,
    activeStatus,
    order,
  ]);

  useEffect(() => {
    const defUOAM = MEASUREMENT_OPTIONS.map((item) => {
      return {
        value: item.value.toString(),
        label: item.label.toString(),
      };
    });

    if (initialValues && initialValues.items) {
      initialValues.items.map((itm: PurchaseOrderItemPayload) => {
        if (itm.UOM)
          defUOAM.push({
            value: itm.UOM,
            label: itm.UOM,
          });
      });
    }

    setCurrentUOMOptions(chain(defUOAM).uniqBy("value").value());

    setHideCost(storedHideCostValue?.hideCost || false);
  }, [initialValues, order, setCurrentUOMOptions, storedHideCostValue]);

  const handleStatusSelect = React.useCallback((text: string) => {
    setActiveStatus(text);
  }, []);

  useEffect(() => {
    if (!shouldShow) {
      setActiveStatus(PurchaseOrderStatus.DRAFT);
    } else {
      handleHideCostChange(storedHideCostValue?.hideCost || false);
    }
  }, [shouldShow]);

  const handleToggleGST = React.useCallback(
    (formikProps: FormikProps<PurchaseOrderPayload>) => (value: boolean) => {
      formikProps.setFieldValue("hasGST", value);
      formikProps.values.items?.forEach((_, index) => {
        formikProps.setFieldValue(`items[${index}].gstFree`, !value);
      });
    },
    []
  );

  const orderTitle = order
    ? t("orders.updateOrder")
    : isReceivedStatus
    ? t("orders.orderReceive")
    : t("orders.newOrder");

  const handleFileUpload = React.useCallback(
    (uploadedFiles: File[]) => {
      setFiles([
        ...files,
        ...uploadedFiles.map((item) => ({ id: uuid.v4(), file: item })),
      ]);
    },
    [files]
  );

  const handleDeleteFile = React.useCallback(
    (row?: FileTable) => {
      if (row) {
        const newFiles = files.filter((item) => item.id !== row.id);
        setFiles(newFiles);
      }
    },
    [files]
  );

  const filesTableData = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("documents.documentName"),
        },
        {
          valueKey: "size",
          title: t("documents.size"),
          formatValue: (row: any, column: any, value: number) =>
            value ? filesize(value) : "-",
        },
      ],
      rows: map(files, (file) => {
        const cell = {
          id: file.id,
          name: file.file.name,
          size: file.file.size,
        };
        return { cells: cell };
      }),
    };
  }, [files, t]);

  const tableRowActions: TableRowActionData<FileTable>[] = React.useMemo(
    () => [
      {
        icon: "delete",
        id: "remove",
        onClick: handleDeleteFile,
      },
    ],
    [handleDeleteFile]
  );

  const fileUploader = React.useCallback(
    (formikProps: FormikProps<any>) => {
      return (
        <Row>
          <Col xs={12} className="form-column">
            <div className="uploader mt-4">
              <FileInput onUpload={handleFileUpload} />
            </div>
            <UploadSpinner
              show={formikProps.isSubmitting && files.length > 0}
              text={t("documents.pleaseWaitUpload")}
            />
          </Col>
        </Row>
      );
    },
    [handleFileUpload, files, t]
  );

  const handleSetNewSupplier = React.useCallback(
    (supplier: SupplierDetails) => {
      createSupplierModalRef.current?.setFieldValue("supplier", supplier._id);
    },
    [createSupplierModalRef]
  );

  return (
    <>
      <CreateSupplierModal ref={supplierRef} onCreate={handleSetNewSupplier} />
      {renderSelectCostingModal()}
      <ModalForm<PurchaseOrderPayload>
        formikRef={createSupplierModalRef}
        show={shouldShow}
        editMode={!!order}
        validationSchema={createOrderSchema(t)}
        onClose={handleOnClose}
        data={initialValues}
        className="order-modal"
        title={orderTitle}
        onSubmit={
          isReceivedStatus
            ? onReceivePurchaseOrderSubmit
            : onPurchaseOrderSubmit
        }
      >
        {(formikProps: FormikProps<PurchaseOrderPayload>) => (
          <Container className="generic-form-body" fluid>
            <CalculatorModal
              formikProps={formikProps}
              show={showCalculator}
              onSubmit={handleCalculatorSubmit}
              fieldName={calcData.fieldName}
              takeoffs={takeoffs}
              takeoffProps={{
                canCreateTakeoff: !!salesQuoteId,
                salesQuoteId: salesQuoteId ? salesQuoteId : undefined,
                name: get(formikProps.values, calcData.fieldName.split(".")[0])
                  ?.name,
              }}
              initialValue={
                get(formikProps.values, calcData.fieldName.split(".")[0])
                  ?.raw_quantity || get(formikProps.values, calcData.fieldName)
              }
              wastage={(
                get(
                  formikProps.values,
                  `items[${calcData.rowIndex}].wastage`,
                  ""
                ) || ""
              ).toString()}
              rounding={(
                get(
                  formikProps.values,
                  `items[${calcData.rowIndex}].rounding`,
                  ""
                ) || ""
              ).toString()}
              onClose={closeCalcModal}
              title={
                get(formikProps.values, calcData.fieldName.split(".")[0])?.name
              }
            />
            <Row>
              {renderField(formikProps, formFields.supplier, 4)}
              {renderField(formikProps, formFields.reference, 4)}
              {renderField(formikProps, formFields.status, 4)}
            </Row>
            <Row>
              {renderField(formikProps, formFields.date, 4)}
              {renderField(formikProps, formFields.dateReceived, 4)}
              {renderField(formikProps, formFields.deliveryDate, 4)}
              {renderField(formikProps, formFields.dueDate, 4)}
            </Row>

            <Row>
              <Col lg={6} xs={12} className="col-no-gutters">
                {renderField(formikProps, formFields.displayCost, 4)}
              </Col>
            </Row>

            <Row>
              {renderTableForm(
                formikProps,
                formFields.items as GenericFormTable<PurchaseOrderPayload, any>,
                "items"
              )}
            </Row>
            <Row>
              <Col lg={12} xs={12} className="col-no-gutters">
                {renderField(formikProps, formFields.internalNote, 12)}
              </Col>
            </Row>
            <Row>
              <Col lg={7} xs={12}>
                <div className="field-text field-text--underlined delivery-title">
                  {isReceivedStatus
                    ? t("orders.receipts")
                    : t("orders.delivery")}
                </div>
                <Row className="row-m">
                  {isReceivedStatus ? (
                    <Col lg={12} xs={12} className="col-no-gutters">
                      {fileUploader(formikProps)}
                      {files && files.length > 0 && (
                        <TableCard
                          data={filesTableData}
                          rowCount={true}
                          rowActions={tableRowActions}
                          className="mb-0"
                          light
                        />
                      )}
                    </Col>
                  ) : (
                    <>
                      <Col lg={6} xs={12} className="col-no-gutters">
                        {renderField(formikProps, formFields.attention, 12)}
                        {renderField(formikProps, formFields.contactNumber, 12)}
                        {renderField(
                          formikProps,
                          formFields.deliveryAddress,
                          12
                        )}
                      </Col>
                      <Col lg={6} xs={12} className="col-no-gutters">
                        {renderField(
                          formikProps,
                          formFields.deliveryInstructions,
                          12
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              </Col>

              <Col lg={5} xs={12}>
                <ModalTotals
                  items={!hideCost ? formikProps.values.items : []}
                  margin={0}
                  title={t("orders.amount")}
                  canToggleGST={true}
                  hasGST={formikProps.values.hasGST}
                  onToggleGST={handleToggleGST(formikProps)}
                  costsHaveGST={costsHaveGST}
                />
              </Col>
            </Row>
          </Container>
        )}
      </ModalForm>
    </>
  );
};

export default React.forwardRef(PurchaseOrderForm);
