import { isArray } from "lodash";
import React from "react";
import Icon from "../../icons/Icon";

type DetailsCardListItemProps = {
  icon?: string;
  title?: string;
  text: string | JSX.Element | JSX.Element[];
};

const DetailsCardListItem: React.FC<DetailsCardListItemProps> = ({
  icon,
  text,
  title,
}) => {
  return (
    <li className="details-card-list-item" title={title}>
      {icon && <Icon className="field-text" name={icon} outlined />}
      {!isArray(text) ? (
        <div className="text field-text medium">{text}</div>
      ) : (
        text.map((item) => item)
      )}
    </li>
  );
};

export default DetailsCardListItem;
