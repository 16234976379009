import React from "react";
import { map, isEmpty } from "lodash";
import AttachmentItem from "./AttachmentItem";
import Icon from "../icons/Icon";
import { Media } from "../../models/media";
import "./styles.scss";

type AttachmentsProps = {
  remoteFiles?: Media[] | null;
  files?: File[] | null;
  onClear?: () => void;
  onRemove?: (file: File | Media) => void;
};

const Attachments: React.FC<AttachmentsProps> = (props) => {
  const { files, remoteFiles, onClear, onRemove } = props;

  const renderItems = () => {
    return [
      ...map(remoteFiles, (media) => (
        <div className="attachment-wrapper" key={media.name}>
          <AttachmentItem remoteFile={media} onRemove={onRemove} />
        </div>
      )),
      ...map(files, (file) => (
        <div className="attachment-wrapper" key={file.name}>
          <AttachmentItem file={file} onRemove={onRemove} />
        </div>
      )),
    ];
  };

  if (isEmpty(files) && isEmpty(remoteFiles)) {
    return null;
  }

  return (
    <div className="attachments-list">
      {onClear && (
        <div className="attachments-clear" onClick={onClear}>
          <Icon name="delete" />
        </div>
      )}
      <div className="attachment-list-wrapper">{renderItems()}</div>
    </div>
  );
};

export default Attachments;
