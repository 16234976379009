import React from "react";
import "./styles.scss";

type AuthenticationCardProps = React.PropsWithChildren<{
  title?: string;
  body: React.ElementType;
  footer: React.ElementType;
  container: React.ElementType;
  bodyProps?: any;
  footerProps?: any;
  containerProps?: any;
}>;

const AuthenticationCard: React.FC<AuthenticationCardProps> = ({
  title,
  container: Container,
  body: Body,
  footer: Footer,
  containerProps,
  bodyProps,
  footerProps,
}) => {
  return (
    <Container className="authentication-card-container" {...containerProps}>
      <div className="authentication-logo">
        <img
          className="logo"
          src="/assets/wunderbuild-logo-dark.svg"
          alt="logo"
        />
      </div>
      <div className="authentication-card">
        <div className="header">{title && <h1>{title}</h1>}</div>
        <div className="body d-flex justify-content-between">
          <Body {...bodyProps} />
        </div>
        <div className="footer">
          <Footer {...footerProps} />
        </div>
      </div>
    </Container>
  );
};

AuthenticationCard.defaultProps = {};

export default AuthenticationCard;
