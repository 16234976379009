import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createTaskSchema(t: TFunction) {
  return yup.object().shape({
    task: yup
      .string()
      .required(ValidationMessagesUtils.createRequiredMessage(t, "tasks.task")),
    description: yup.string(),
    // .required(
    //   ValidationMessagesUtils.createRequiredMessage(t, "tasks.description")
    // ),
    dueDate: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "tasks.dueDate")
      ),
  });
}
