import { ApolloCache, MutationUpdaterFn } from "@apollo/client";

import { LIST_SUPPLIER_CERTIFICATION } from "../../../graphql/queries/supplier-certification/queries";
import {
  ListSupplierCertificationResponse,
  CreateUpdateSupplierCertificationResponse,
  DeleteSupplierCertificationResponse,
} from "../../../graphql/types/models/supplier";
import { SupplierCertification } from "../../../models/supplier";
import { concat, filter } from "lodash";

const getSupplierCertification = (
  cache: ApolloCache<any>,
  supplierId: string
) => {
  const supplierCertificationResponse = cache.readQuery<
    ListSupplierCertificationResponse
  >({
    query: LIST_SUPPLIER_CERTIFICATION,
    variables: {
      supplierId,
    },
  });

  return supplierCertificationResponse?.listSupplierCertification;
};

const updateSupplierCertification = (
  cache: ApolloCache<any>,
  supplierId: string,
  certification: SupplierCertification[]
) => {
  cache.writeQuery<ListSupplierCertificationResponse>({
    query: LIST_SUPPLIER_CERTIFICATION,
    variables: {
      supplierId,
    },
    data: {
      listSupplierCertification: certification,
    },
  });
};

export const handleAddCertification = (
  supplierId: string
): MutationUpdaterFn<CreateUpdateSupplierCertificationResponse> => (
  cache,
  { data }
) => {
  const newSupplierCertification = data?.createUpdateSupplierCertification;
  const supplierCertification = getSupplierCertification(cache, supplierId);

  if (!newSupplierCertification || !supplierCertification) return;

  const certifications = concat(
    supplierCertification,
    newSupplierCertification
  );
  updateSupplierCertification(cache, supplierId, certifications);
};

export const handleDeleteCertification = (
  supplierId: string
): MutationUpdaterFn<DeleteSupplierCertificationResponse> => (
  cache,
  { data }
) => {
  const deletedSuppplierCertification = data?.deleteSupplierCertification;
  const supplierCertification = getSupplierCertification(cache, supplierId);

  if (!deletedSuppplierCertification || !supplierCertification) return;

  const certifications = filter(
    supplierCertification,
    (certification) => certification._id !== deletedSuppplierCertification._id
  );
  updateSupplierCertification(cache, supplierId, certifications);
};
