import React, { useState } from "react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import CreateEntityModal from "../../modals/create-entity";
import { CreateChecklistPayload } from "../../../models/task";
import { createChecklistField } from "./utils";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { useJobsQuery } from "../../../hooks/queries/useJobsQuery";
import { useCreateAnother } from "../../../hooks/useCreateAnother";
import createChecklistSchema from "./CreateChecklist.schema";

type CreateCheckistModalProps<TItem> = {
  data: TItem | null;
  show: boolean;
  onSubmit: (data: CreateChecklistPayload, createAnother: boolean) => void;
  onClose: () => void;
};

const CreateCheckistModal = <TItem extends { _id: string; name: string }>(
  props: CreateCheckistModalProps<TItem>
) => {
  const { show, onSubmit, onClose, data } = props;
  const { t } = useTranslation();
  const { jobs } = useJobsQuery();

  const { shouldCreateAnother, renderCreateAnother } = useCreateAnother(
    t("tasks.createAnotherChecklist")
  );

  const [fields, setFields] = useState<
    GenericFormFields<CreateChecklistPayload>
  >({});
  const [initData, setInitData] = React.useState<CreateChecklistPayload>({
    name: "",
  });

  React.useEffect(() => {
    if (data) {
      setInitData({ name: data.name });
    }
    setFields(createChecklistField(t));
  }, [t, jobs, data]);

  const handleSubmit = React.useCallback(
    (payload: CreateChecklistPayload, helpers?: FormikHelpers<any>) => {
      onSubmit(
        { ...payload, ...(data && { _id: data._id }) },
        shouldCreateAnother
      );
      helpers?.resetForm();

      if (shouldCreateAnother) setInitData({ name: "" });
    },
    [onSubmit, data, shouldCreateAnother]
  );

  const handleClose = React.useCallback(() => {
    setInitData({ name: "" });
    onClose();
  }, [onClose]);

  return (
    <CreateEntityModal<CreateChecklistPayload>
      validationSchema={createChecklistSchema()}
      title={data ? t("tasks.editGroup") : t("tasks.createNewGroup")}
      data={initData}
      show={show}
      fields={fields}
      onSubmit={handleSubmit}
      onClose={handleClose}
      leftFooterRenderer={renderCreateAnother}
      submitText={data ? t("common.update") : undefined}
    />
  );
};

export default CreateCheckistModal;
