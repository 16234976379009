import React from "react";
import Container from "react-bootstrap/Container";

type SelectPagesListProps = {};

const SelectPagesList: React.FC<SelectPagesListProps> = ({ children }) => {
  return <Container className="pages-list">{children}</Container>;
};

export default SelectPagesList;
