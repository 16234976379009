import React from "react";
import Container from "react-bootstrap/Container";
import { Button, Col, Row } from "react-bootstrap";
import { compact, filter } from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DetailsCard, {
  DetailsCardAction,
} from "../../../components/dashboard/details-card";
import { DetailsCardDataItem } from "../../../components/dashboard/details-card/DetailsCardList";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardBackNavigation from "../../../components/navigation/DashboardBackNavigation";
import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";
import { notify } from "../../../components/notification";
import { useNote } from "../../../hooks/useNote";
import { NoteEntityType } from "../../../graphql/types/models/note";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { getFullName } from "../../../utils/text";
import moment from "moment-timezone";
import Loader from "../../../components/loader";
import EditableTags from "../../../components/editable-tags";
import {
  CreateChecklistItemPayload,
  CreateChecklistPayload,
  EnumChecklistTemplateType,
  ImportTodoFromTemplatePayload,
  ImportTodoFromTemplateResponse,
  TaskStatus,
  TodoListChecklistCategory,
} from "../../../models/task";
import { useTasks } from "../../../hooks/useTasks";
import {
  GET_TODO_LIST,
  GET_TODO_LIST_TAGS,
} from "../../../graphql/queries/task/queries";
import {
  CreateChecklistItemResponse,
  CreateChecklistResponse,
  DeleteChecklistCategoryResponse,
  DeleteChecklistResponse,
  GetTodoListResponse,
  GetTodoListTagsResponse,
  ToggleChecklistStatusResponse,
  UpdateTodoListTagsResponse,
} from "../../../graphql/types/models/task";
import CreateCheckistModal from "../../../components/task/create-checklist-modal";
import SaveChecklistAsTemplateModal from "../../../components/task/create-checklist-template";
import ImportChecklistTemplateModal from "../../../components/task/import-checklist-template";
import {
  CREATE_TODO_CHECKLIST,
  CREATE_TODO_CHECKLIST_CATEGORY,
  IMPOR_TODO_FROM_TEMPLATE,
  TODO_DELETE_CHECKLIST,
  TODO_DELETE_CHECKLIST_CATEGORY,
  TOGGLE_CHECKLIST_STATUS,
  UPDATE_TODO_LIST_TAGS,
} from "../../../graphql/queries/task/mutation";
import { EnumSystemUserType } from "../../../graphql/types/models/notification";
import ChecklistCategory from "../checklist-category";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useChecklistTemplate } from "../../../hooks/useChecklistTemplate";
import DashboardCard from "../../../components/dashboard/card";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import Icon from "../../../components/icons/Icon";
import Dropdown from "../../../components/dashboard/dropdown";
import DashboardCardBody from "../../../components/dashboard/card/DashboardCardBody";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import "./styles.scss";

type Params = {
  id: string;
};

type ContactsClientContainerProps = {} & RouteComponentProps<Params> &
  DashboardContextValue;

const TaskOverviewContainer: React.FC<ContactsClientContainerProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const todoListId = match.params.id;
  const saveChecklistAsTemplateRef = React.useRef<ModalDisplayRef>();
  const importChecklistTemplateRef = React.useRef<ModalDisplayRef>();

  const [
    showCreateChecklistModal,
    setShowCreateChecklistModal,
  ] = React.useState(false);
  const [
    currentChecklistGroup,
    setCurrentChecklistGroup,
  ] = React.useState<TodoListChecklistCategory | null>(null);

  const { t } = useTranslation();
  const { renderNotesCard } = useNote(NoteEntityType.TodoList, todoListId);

  const { data: todoData, loading: loadingTodoData } = useQuery<
    GetTodoListResponse
  >(GET_TODO_LIST, {
    variables: {
      todoListId,
    },
    fetchPolicy: "network-only",
  });

  const { data: todoListTags } = useQuery<GetTodoListTagsResponse>(
    GET_TODO_LIST_TAGS
  );

  const [createChecklist] = useMutation<CreateChecklistResponse>(
    CREATE_TODO_CHECKLIST_CATEGORY,
    {
      onCompleted: () => {
        notify({
          title: currentChecklistGroup
            ? t("tasks.editGroup")
            : t("tasks.createNewGroup"),
          content: currentChecklistGroup
            ? t("tasks.success.editChecklist")
            : t("tasks.success.createChecklist"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: currentChecklistGroup
            ? t("tasks.editGroup")
            : t("tasks.createNewGroup"),
          content: currentChecklistGroup
            ? t("tasks.error.editChecklist")
            : t("tasks.error.createChecklist"),
        });
      },
    }
  );

  const [
    toggleChecklistStatus,
    { loading: loadingToggleChecklistStatus },
  ] = useMutation<ToggleChecklistStatusResponse>(TOGGLE_CHECKLIST_STATUS);

  const [
    createChecklistItem,
    { loading: createChecklistLoading },
  ] = useMutation<CreateChecklistItemResponse>(CREATE_TODO_CHECKLIST);

  const [deleteChecklistCategory] = useMutation<
    DeleteChecklistCategoryResponse
  >(TODO_DELETE_CHECKLIST_CATEGORY, {
    onCompleted: () => {
      notify({
        title: t("tasks.deleteChecklistCategory"),
        content: t("tasks.success.deleteChecklistCategory"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("tasks.deleteChecklistCategory"),
        content: t("tasks.error.deleteChecklistCategory"),
      });
    },
  });

  const [deleteChecklist, { loading: deleteChecklistLoading }] = useMutation<
    DeleteChecklistResponse
  >(TODO_DELETE_CHECKLIST, {
    onCompleted: () => {
      notify({
        title: t("tasks.deleteTask"),
        content: t("tasks.success.deleteTask"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("tasks.deleteTask"),
        content: t("tasks.error.deleteTask"),
      });
    },
  });

  const [importTodoChecklistFromTemplate] = useMutation<
    ImportTodoFromTemplateResponse,
    ImportTodoFromTemplatePayload
  >(IMPOR_TODO_FROM_TEMPLATE, {
    onCompleted: () => {
      notify({
        title: t("tasks.importChecklistTemplate"),
        content: t("tasks.success.importFromTemplate"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("tasks.importChecklistTemplate"),
        content: t("tasks.error.importFromTemplate"),
      });
    },
  });

  const [
    updateTodoListTags,
    { loading: updateTodoListTagsLoading },
  ] = useMutation<UpdateTodoListTagsResponse>(UPDATE_TODO_LIST_TAGS);

  const {
    checklistTemplates,
    createUpdateChecklistTemplate,
  } = useChecklistTemplate({
    checklistTemplateType: EnumChecklistTemplateType.Todolist,
  });

  const isLoading =
    loadingToggleChecklistStatus ||
    loadingTodoData ||
    updateTodoListTagsLoading ||
    deleteChecklistLoading ||
    createChecklistLoading;

  React.useEffect(() => {
    setNavigationContext({
      ...navigationContext,
      task: {
        _id: todoListId,
        task: todoData?.getTodoList?.task || "",
      },
    });
  }, [setNavigationContext, todoListId, todoData]);

  React.useEffect(() => {
    return () => {
      setNavigationContext({
        ...navigationContext,
        task: undefined,
      });
    };
  }, []);

  const handleCreateChecklistItem = React.useCallback(
    async (data: CreateChecklistItemPayload, checklistId: string) => {
      const isUpdate = Boolean(data._id);
      const category = todoData?.getTodoList.checklistCategories?.find(
        (checklist) => checklist._id === checklistId
      );
      if (category) {
        try {
          await createChecklistItem({
            variables: {
              checkList: {
                todoListId,
                categoryId: category?._id,
                task: data.task,
                dueDate: data?.dueDate,
                ...(isUpdate && { _id: data._id }),
                ...(data?.assignedTo
                  ? {
                      assignedTo: data?.assignedTo,
                      assignedToType: EnumSystemUserType.Builder,
                    }
                  : {
                      assignedTo: null,
                      assignedToType: EnumSystemUserType.None,
                    }),
              },
            },
          });
          notify({
            title: isUpdate
              ? t("tasks.updateTask")
              : t("tasks.createNewChecklistItem"),
            content: isUpdate
              ? t("tasks.success.updateTask")
              : t("tasks.success.createChecklistItem"),
          });
        } catch (error) {
          notify({
            error: true,
            title: isUpdate
              ? t("tasks.updateTask")
              : t("tasks.createNewChecklistItem"),
            content: isUpdate
              ? t("tasks.error.updateTask")
              : t("tasks.error.createChecklistItem"),
          });
        }
      }
    },
    [createChecklistItem, todoData, todoListId]
  );

  const handleChangeTags = React.useCallback(
    (tags: string[] | null) => {
      updateTodoListTags({
        variables: {
          todoListId: todoData?.getTodoList._id,
          tags,
        },
      });
    },
    [todoData]
  );

  const isCompletedTodo = todoData?.getTodoList.completed;

  const status = React.useMemo(() => {
    const overDue = moment().isAfter(
      moment(todoData?.getTodoList.dueDate).endOf("day")
    );
    return (
      <span
        className={
          isCompletedTodo
            ? "badge badge-success"
            : overDue
            ? "badge badge-danger"
            : "badge badge-secondary"
        }
      >
        {isCompletedTodo
          ? TaskStatus.COMPLETE
          : overDue
          ? TaskStatus.OVERDUE
          : TaskStatus.INCOMPLETE}
      </span>
    );
  }, [todoData, isCompletedTodo]);

  const detailsCardData: DetailsCardDataItem[] = React.useMemo(() => {
    const todo = todoData?.getTodoList;

    if (loadingTodoData || !todo) {
      return [];
    }

    return filter(
      compact([
        {
          icon: "title",
          text: todo?.task,
          title: t("tasks.task"),
        },
        todo?.description && {
          icon: "description",
          text: todo?.description,
          title: t("tasks.description"),
        },
        {
          icon: "pending",
          text: status,
          title: t("tasks.status"),
        },
        {
          icon: "label",
          text: (
            <EditableTags
              items={todoData?.getTodoList?.tags}
              allTags={todoListTags?.listTodoListTags}
              onSubmit={handleChangeTags}
              badgeStyle="badge-style"
              placeholder={t("placeholders.editableTags")}
            />
          ),
          title: t("tasks.label"),
        },
        todo?.members?.length && {
          icon: "group",
          title: t("tasks.assignedTo"),
          text: todo?.members ? (
            <div className="d-flex flex-wrap">
              {todo?.members?.map((item) => (
                <div className="text field-text medium contact-name-item">
                  {`${getFullName(item)}`}
                </div>
              ))}
            </div>
          ) : (
            ""
          ),
        },
        {
          icon: "event_available",
          title: t("tasks.dueDate"),
          text: moment(todoData?.getTodoList?.dueDate).format("Do MMMM YYYY"),
        },
        todo?.job && {
          icon: "work",
          title: t("tasks.job"),
          text: todo?.job?.name || " ",
        },
      ]),
      (r) => !!r.text
    );
  }, [todoData, loadingTodoData]);

  const {
    handleUpdateTodoListCompletion,
    renderCreateUpdateTaskModal,
    setTaskToUpdate,
    openCreateTaskModal,
  } = useTasks({ filter: {} });

  const openEditTaskModal = React.useCallback(() => {
    if (todoData?.getTodoList) {
      setTaskToUpdate(todoData?.getTodoList);
      openCreateTaskModal();
    }
  }, [openCreateTaskModal, todoData]);

  const detailsCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: isCompletedTodo
          ? t("tasks.operations.incomplete")
          : t("tasks.operations.complete"),
        onClick: () =>
          isCompletedTodo
            ? handleUpdateTodoListCompletion(todoListId, false)
            : handleUpdateTodoListCompletion(todoListId, true),
        className: "button large success incomplete-button",
        disabled: loadingTodoData,
      },
      {
        title: t("common.edit"),
        onClick: openEditTaskModal,
        className: "button info",
        disabled: loadingTodoData,
      },
    ],
    [isCompletedTodo, t, openEditTaskModal, loadingTodoData]
  );

  const openCreateChecklistModal = React.useCallback(() => {
    setShowCreateChecklistModal(true);
  }, []);

  const closeCreateChecklistModal = React.useCallback(() => {
    setShowCreateChecklistModal(false);
    setCurrentChecklistGroup(null);
  }, []);

  const handleCreateChecklist = React.useCallback(
    async (data: CreateChecklistPayload, createAnother: boolean) => {
      await createChecklist({
        variables: {
          checkListCategory: {
            ...(data._id && { _id: data._id }),
            todoListId,
            name: data.name,
          },
        },
      });
      if (!createAnother) {
        closeCreateChecklistModal();
      } else {
        setCurrentChecklistGroup(null);
      }
    },
    [closeCreateChecklistModal, createChecklist, todoListId]
  );

  const handleEditChecklistGroup = React.useCallback(
    (checklist: TodoListChecklistCategory) => {
      setCurrentChecklistGroup(checklist);
      openCreateChecklistModal();
    },
    [openCreateChecklistModal]
  );

  const handleToggleChecklistStatus = React.useCallback(
    (row: { _id: string; completed: boolean }) => {
      toggleChecklistStatus({
        variables: {
          todoListId: todoData?.getTodoList._id,
          checklistId: row._id,
          isComplete: !row.completed,
        },
      });
    },
    [todoData?.getTodoList._id, toggleChecklistStatus]
  );

  const handleDeleteChecklist = React.useCallback(
    (checklist: TodoListChecklistCategory) => {
      deleteChecklistCategory({
        variables: {
          todoListId: todoData?.getTodoList._id,
          checklistCategoryId: checklist._id,
        },
      });
    },
    [deleteChecklistCategory, todoData?.getTodoList._id]
  );

  const handleDeleteChecklistTask = React.useCallback(
    (checklistId: string) => {
      deleteChecklist({
        variables: {
          todoListId: todoData?.getTodoList._id,
          checklistId,
        },
      });
    },
    [deleteChecklist, todoData]
  );

  const handleSaveChecklistAsTemplate = React.useCallback(
    (name: string) => {
      const categories = todoData?.getTodoList?.checklistCategories?.map(
        (category) => {
          return {
            name: category.name,
            items: category.checklist.map((task, index) => ({
              task: task.task,
              order: index + 1,
            })),
          };
        }
      );
      if (categories) {
        createUpdateChecklistTemplate({
          variables: {
            template: {
              name,
              type: EnumChecklistTemplateType.Todolist,
              categories,
            },
          },
        });
      }
    },
    [createUpdateChecklistTemplate, todoData?.getTodoList?.checklistCategories]
  );

  const handleImportChecklist = React.useCallback(
    (templateId: string) => {
      importTodoChecklistFromTemplate({
        variables: {
          templateId,
          todoListId,
        },
      });
    },
    [importTodoChecklistFromTemplate, todoListId]
  );

  const onSaveChecklistAsTemplate = React.useCallback(() => {
    saveChecklistAsTemplateRef.current?.show(true);
  }, [saveChecklistAsTemplateRef]);

  const onImportTemplateChecklist = React.useCallback(() => {
    importChecklistTemplateRef.current?.show(true);
  }, [importChecklistTemplateRef]);

  const checklistsOptions = React.useMemo(() => {
    return compact([
      todoData?.getTodoList?.checklistCategories?.length
        ? {
            id: "saveChecklistTemplate",
            label: t("tasks.saveAsTemplate"),
            name: "saveChecklistTemplate",
            outlined: true,
            onClick: onSaveChecklistAsTemplate,
          }
        : null,
      {
        id: "importChecklistTemplate",
        label: t("tasks.importChecklistTemplate"),
        name: "importChecklistTemplate",
        outlined: true,
        onClick: onImportTemplateChecklist,
      },
    ]);
  }, [todoData]);

  if (loadingTodoData) {
    return (
      <div className="todo loader-center">
        <Loader />
      </div>
    );
  }

  return (
    <Container className="todo" fluid>
      <DashboardBackNavigation
        routeId={NAVIGATION_ROUTES.TASKS_SECTION.OVERVIEW}
      />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.TASKS_SECTION.TASK} />
      <Helmet title={t("navigation.tasksSection.task")} />
      <SaveChecklistAsTemplateModal
        ref={saveChecklistAsTemplateRef}
        onSubmit={handleSaveChecklistAsTemplate}
        templatesData={checklistTemplates}
        isChecklistsSection={true}
      />

      <ImportChecklistTemplateModal
        ref={importChecklistTemplateRef}
        onSubmit={handleImportChecklist}
        templatesData={checklistTemplates}
      />

      {todoData?.getTodoList && (
        <CreateCheckistModal
          data={currentChecklistGroup}
          show={showCreateChecklistModal}
          onClose={closeCreateChecklistModal}
          onSubmit={handleCreateChecklist}
        />
      )}

      <Row className="w-100">
        <Col lg={4} xs={12} className="pb-sm-5 pb-lg-0 pr-sm-4">
          <div className="card-wrapper">
            <DetailsCard
              fullHeight
              title={t("common.details")}
              data={detailsCardData}
              actions={detailsCardActions}
              cardFooterStyles="justify-content-between"
            />
          </div>
          <div className="card-wrapper"></div>
        </Col>
        <Col lg={8} xs={12} className="pb-sm-5 pb-lg-0 pl-sm-4">
          <DashboardCard>
            <DashboardCardHeader className="justify-content-between">
              {t("tasks.checklists")}
              <div className="d-flex mr-3">
                <Button
                  className="button large bg-transparent"
                  block
                  onClick={openCreateChecklistModal}
                >
                  <Icon name="add" />
                  {t("tasks.createNewGroup")}
                </Button>
                <Dropdown
                  label={t("tasks.templates")}
                  icon="expand_more"
                  menuWidth="270px"
                  id="costing-dropdown"
                  items={checklistsOptions}
                />
              </div>
            </DashboardCardHeader>
            <DashboardCardBody>
              {todoData?.getTodoList?.checklistCategories &&
              todoData?.getTodoList?.checklistCategories.length > 0 ? (
                <>
                  {todoData.getTodoList.checklistCategories.map((checklist) => {
                    return (
                      <ChecklistCategory
                        checklist={checklist}
                        onDeleteChecklist={handleDeleteChecklist}
                        onEditChecklist={handleEditChecklistGroup}
                        isLoading={isLoading}
                        onToggleChecklistStatus={handleToggleChecklistStatus}
                        onCreateChecklistItem={handleCreateChecklistItem}
                        onDeleteChecklistItem={handleDeleteChecklistTask}
                      />
                    );
                  })}
                </>
              ) : (
                <EmptyPlaceholder
                  message={t("tasks.emptyChecklistsPlaceholder")}
                />
              )}
            </DashboardCardBody>
          </DashboardCard>

          {renderCreateUpdateTaskModal()}
          <div className="pt-sm-5 lead-notes">{renderNotesCard()}</div>
        </Col>
        {renderCreateUpdateTaskModal()}
      </Row>
    </Container>
  );
};

export default withDashboardContext(TaskOverviewContainer);
