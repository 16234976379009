import React, { forwardRef } from "react";
import CreateEntityModal from "../../modals/create-entity";
import { useTranslation } from "react-i18next";
import {
  SaveScheduleAsTemplatePayload,
  ScheduleTemplate,
} from "../../../models/job";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import saveScheduleAsTemplateSchema from "./SaveScheduleAsTemplate.schema";
import { useScheduleTemplateQuery } from "../../../hooks/queries/useScheduleTemplateQuery";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { saveScheduleAsTemplateFields } from "./utils";

type SaveScheduleAsTemplateModalProps = {
  jobId?: string;
};

const SaveScheduleAsTemplateModal: React.FC<SaveScheduleAsTemplateModalProps> = (
  { jobId },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);

  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<SaveScheduleAsTemplatePayload>
  >({});

  const {
    scheduleTemplates,
    saveScheduleAsTemplate,
  } = useScheduleTemplateQuery({
    onSave: hide,
  });

  React.useEffect(() => {
    if (!scheduleTemplates) return;
    setFormFields(saveScheduleAsTemplateFields(t));
  }, [scheduleTemplates]);

  const handleSubmit = React.useCallback(
    (values: SaveScheduleAsTemplatePayload) => {
      return saveScheduleAsTemplate({
        variables: values,
      });
    },
    []
  );

  const validationSchema = React.useMemo(
    () => saveScheduleAsTemplateSchema(t, scheduleTemplates),
    [scheduleTemplates]
  );

  if (!scheduleTemplates) return null;

  return (
    <CreateEntityModal
      validationSchema={validationSchema}
      className="import-schedule-template"
      title={t("schedule.saveAsTemplate")}
      show={shouldShow}
      data={{
        jobId: jobId || "",
        scheduleTemplate: {
          name: "",
          saveAssignedUsers: true,
          saveAssignedSuppliers: true,
          saveReminders: true,
        },
      }}
      submitText={t("common.save")}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
    />
  );
};

export default forwardRef(SaveScheduleAsTemplateModal);
