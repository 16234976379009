import { ExportResult } from "./export";

export enum ReportType {
  JobsWIP = "JOBS_WIP",
  JobPurchaseOrder = "JOB_PURCHASE_ORDER",
  JobPurchaseOrderLineItem = "JOB_PURCHASE_ORDER_LINE_ITEM",
  JobVariation = "JOB_VARIATION",
  JobVariationLineItem = "JOB_VARIATION_LINE_ITEM",
  JobProgressClaim = "JOB_PROGRESS_CLAIM",
  JobProgressClaimLineItem = "JOB_PROGRESS_CLAIM_LINE_ITEM",
}
export type ReportHeader = {
  key: string;
  format: string;
};
export type ReportCell = {
  key: string;
  value: string;
};
export type ReportRow = {
  _id: string;
  cells: ReportCell[];
};
export type ReportData = {
  header: ReportHeader[];
  rows: ReportRow[];
  summary: ReportCell[];
};

export type ReportFilters = {
  jobId?: string;
};

export type GetReportRequest = {
  reportType: ReportType;
  filters: ReportFilters;
};

export type GetReportResponse = {
  getReport: ReportData;
};

export type DownloadReportResponse = {
  downloadReport: ExportResult;
};
