import { TFunction } from "i18next";
import { chain, map } from "lodash";
import { CreateJobPayload, JobDetails, JobType } from "../../../models/job";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { ContactListItem } from "../../../graphql/types/models/client";
import { STATES_OPTIONS } from "../../../utils/options";
import { SalesQuote } from "../../../models/salesQuote";
import { getFullName } from "../../../utils/text";

export const createJobField = (
  t: TFunction,
  contacts: ContactListItem[],
  onClientModalOpen: () => void,
  onPlaceSelect: UpdateTextInputFn,
  onJobTypeSelect: (value: string | string[]) => void,
  jobType: JobType,
  job?: JobDetails,
  salesQuote?: SalesQuote
): GenericFormFields<CreateJobPayload> => {
  const fields: GenericFormFields<CreateJobPayload> = {
    type: {
      type: "default-select",
      label: t("jobs.jobType"),
      placeholder: t("jobs.jobType"),
      valueKey: "type",
      selectProps: {
        onValueChange: onJobTypeSelect,
        options: chain(JobType)
          .map((type) => ({
            value: type,
            label: t(`jobs.types.${type}`),
          }))
          .value(),
      },
      inputProps: {
        required: true,
      },
    },
    markup: {
      type: "text",
      label: t("jobs.costPlusPercent"),
      placeholder: t("jobs.costPlusPercent"),
      valueKey: "markup",
      disabled: job?.is_contract_locked,
      inputProps: {
        type: "number",
        required: true,
      },
      append: {
        text: "%",
      },
    },
    contractTotal: {
      type: "text",
      label: t("jobs.contractValue"),
      placeholder: t("placeholders.contractValue"),
      valueKey: "contractTotal",
      disabled: !!salesQuote?._id || job?.is_contract_locked,
      inputProps: {
        type: "number",
        required: true,
      },
      append: {
        text: "$",
      },
    },
    lockContract: {
      type: "toggle",
      valueKey: "is_contract_locked",
      toggleProps: {
        label: t("jobs.limitClaimsToContractValue"),
        readOnly: !!salesQuote?._id || job?.is_contract_locked,
      },
    },
    name: {
      type: "text",
      label: t("jobs.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        required: true,
      },
    },
    customer: {
      width: "278px",
      type: "select",
      label: t("jobs.client"),
      placeholder: t("placeholders.client"),
      valueKey: "customer",
      append: {
        icon: "add",
        onClick: onClientModalOpen,
      },
      autocompleteProps: {
        options: map(contacts, (contact) => ({
          value: contact._id,
          label: getFullName(contact),
        })),
        sortByAsc: true,
      },
      inputProps: {
        required: true,
      },
    },
    dates: [
      {
        type: "date-picker",
        label: t("jobs.startDate"),
        placeholder: t("placeholders.startDate"),
        valueKey: "startDate",
        inputProps: {
          required: true,
        },
      },
      {
        type: "date-picker",
        label: t("jobs.endDate"),
        placeholder: t("placeholders.endDate"),
        valueKey: "endDate",
        inputProps: {
          required: true,
        },
      },
    ],
    address: {
      type: "place-autocomplete",
      label: t("jobs.address"),
      placeholder: t("placeholders.address"),
      valueKey: "address",
      textInputProps: {
        onValueChange: onPlaceSelect,
      },
      inputProps: {
        required: true,
      },
    },
    location: [
      {
        type: "text",
        label: t("contacts.city"),
        placeholder: t("placeholders.city"),
        valueKey: "city",
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "select",
        label: t("contacts.state"),
        placeholder: t("placeholders.state"),
        valueKey: "state",
        autocompleteProps: {
          options: STATES_OPTIONS,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    postcode: {
      type: "text",
      label: t("contacts.postcode"),
      placeholder: t("placeholders.postcode"),
      valueKey: "postcode",
      showError: true,
      inputProps: {
        type: "text",
        required: true,
      },
    },
    title: {
      type: "group-title",
      placeholder: t("jobs.additionalSettings"),
    },
    nonContracted: {
      type: "toggle",
      valueKey: "is_non_contracted",
      toggleProps: {
        label: t("jobs.nonContractedValue"),
      },
    },
    invoiceToJobAddress: {
      type: "toggle",
      valueKey: "invoiceToJobAddress",
      toggleProps: {
        label: t("jobs.invoiceToJobAddress"),
      },
    },
  };
  if (salesQuote) {
    delete fields?.customer;
  } else {
    delete fields?.nonContracted;
  }
  if (job?._id) {
    delete fields.type;
  }
  if (job?.type === JobType.COST_PLUS || jobType === JobType.COST_PLUS) {
    delete fields.contractTotal;
    delete fields.lockContract;
  } else {
    delete fields.markup;
  }

  return fields;
};
