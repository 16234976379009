import React from "react";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import { JobChannel } from "../../../models/job-messaging";
import ChannelGroupItem, { GroupOptions } from "./ChannelGroupItem";
import "./styles.scss";

type ChannelsGlobalListProps = {
  channelGroups: GroupOptions[];
  selectedChannelId: string;
  title: string;
  onSelect: (channel: JobChannel) => void;
};

const ChannelGlobalList: React.FC<ChannelsGlobalListProps> = (props) => {
  const { title, channelGroups, selectedChannelId, onSelect } = props;

  const renderItems = React.useCallback(() => {
    return channelGroups.map((group, index) => (
      <ChannelGroupItem
        key={`${index}-${group.name}`}
        group={group}
        selectedChannelId={selectedChannelId}
        selectChannel={onSelect}
      />
    ));
  }, [channelGroups, selectedChannelId, onSelect]);

  return (
    <DashboardCard className="channel-list">
      <DashboardCardHeader className="channel-header">
        {title}
      </DashboardCardHeader>
      <DashboardCardBody className="channel-body">
        {renderItems()}
      </DashboardCardBody>
    </DashboardCard>
  );
};

export default ChannelGlobalList;
