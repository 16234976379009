import { gql } from "@apollo/client";
import {
  DIGITAL_SIGNATURE_FRAGMENT,
  SELECTION_CATEGORY_FRAGMENT,
  SELECTION_CATEGORY_ITEM_FRAGMENT,
  SELECTION_CATEGORY_ITEM_OPTION_FRAGMENT,
  SELECTION_FRAGMENT,
  SELECTION_TEMPLATE_FRAGMENT,
} from "./fragments";

export const CREATE_UPDATE_SELECTION_CATEGORY = gql`
  mutation(
    $selectionEntityId: ID!
    $selectionEntityType: EnumSelectionEntityType!
    $category: SelectionCategoryInput!
  ) {
    createUpdateSelectionCategory(
      selectionEntityId: $selectionEntityId
      selectionEntityType: $selectionEntityType
      category: $category
    ) {
      ...SelectionCategoryFragment
    }
  }
  ${SELECTION_CATEGORY_FRAGMENT}
`;

export const DELETE_SELECTION_CATEGORY = gql`
  mutation($categoryId: ID!) {
    deleteSelectionCategory(categoryId: $categoryId) {
      ...SelectionCategoryFragment
    }
  }
  ${SELECTION_CATEGORY_FRAGMENT}
`;

export const DELETE_SELECTION_CATEGORY_ITEM = gql`
  mutation($categoryId: ID!, $itemId: ID!) {
    deleteSelectionCategoryItem(categoryId: $categoryId, itemId: $itemId) {
      ...SelectionCategoryItemFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
`;

export const DELETE_SELECTION_CATEGORY_ITEM_OPTION = gql`
  mutation($categoryId: ID!, $itemId: ID!, $optionId: ID!) {
    deleteSelectionCategoryItemOption(
      categoryId: $categoryId
      itemId: $itemId
      optionId: $optionId
    ) {
      ...SelectionCategoryItemOptionFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_OPTION_FRAGMENT}
`;

export const SHARE_SELECTION = gql`
  mutation($selectionEntityId: ID!, $message: JobSendEmailInput!) {
    shareSelection(selectionEntityId: $selectionEntityId, message: $message) {
      ...SelectionFragment
    }
  }
  ${SELECTION_FRAGMENT}
`;

export const CREATE_UPDATE_SELECTION_CATEGORY_ITEM = gql`
  mutation($categoryId: ID!, $item: SelectionCategoryItemInput!) {
    createUpdateSelectionCategoryItem(categoryId: $categoryId, item: $item) {
      ...SelectionCategoryItemFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
`;

export const CREATE_UPDATE_SELECTION_CATEGORY_ITEM_OPTION = gql`
  mutation(
    $categoryId: ID!
    $itemId: ID!
    $option: SelectionCategoryItemOptionInput!
  ) {
    createUpdateSelectionCategoryItemOption(
      categoryId: $categoryId
      itemId: $itemId
      option: $option
    ) {
      ...SelectionCategoryItemOptionFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_OPTION_FRAGMENT}
`;

export const SELECT_SELECTION_CATEGORY_ITEM = gql`
  mutation(
    $selectionEntityId: ID!
    $selectionEntityType: EnumSelectionEntityType!
    $selection: SelectionItemInput!
  ) {
    selectSelectionCategoryItem(
      selectionEntityId: $selectionEntityId
      selectionEntityType: $selectionEntityType
      selection: $selection
    ) {
      ...SelectionCategoryItemFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
`;

export const APPROVE_SELECTION_CATEGORY_ITEM = gql`
  mutation(
    $selectionEntityId: ID!
    $selectionEntityType: EnumSelectionEntityType!
    $selection: SelectionApproveItemInput!
  ) {
    approveSelectionCategoryItem(
      selectionEntityId: $selectionEntityId
      selectionEntityType: $selectionEntityType
      selection: $selection
    ) {
      ...SelectionCategoryItemFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
`;

export const CLIENT_SET_SELECTION_ITEM = gql`
  mutation($selection: ClientSelectionItemInput!) {
    clientSelectSelectionItem(selection: $selection) {
      ...SelectionCategoryItemFragment
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
`;

export const CLIENT_SET_SELECTION_ITEMS = gql`
  mutation(
    $linkedEntityId: ID!
    $linkedEntityType: EnumSelectionLinkedEntityType!
    $items: [ClientSelectionItemsInput]!
    $signature: DigitalSignatureInput!
  ) {
    clientSelectSelectionItems(
      linkedEntityId: $linkedEntityId
      linkedEntityType: $linkedEntityType
      items: $items
      signature: $signature
    ) {
      items {
        ...SelectionCategoryItemFragment
      }
      signature {
        ...DigitalSignatureFragment
      }
    }
  }
  ${SELECTION_CATEGORY_ITEM_FRAGMENT}
  ${DIGITAL_SIGNATURE_FRAGMENT}
`;

export const SAVE_SELECTION_AS_TEMPLATE = gql`
  mutation($selectionEntityId: ID!, $template: SelectionTemplateInput!) {
    saveSelectionAsTemplate(
      selectionEntityId: $selectionEntityId
      template: $template
    ) {
      ...SelectionTemplateFragment
    }
  }
  ${SELECTION_TEMPLATE_FRAGMENT}
`;

export const IMPORT_SELECTION_TEMPLATE = gql`
  mutation(
    $templateId: ID!
    $selectionEntityId: ID!
    $selectionEntityType: EnumSelectionEntityType!
  ) {
    importSelectionTemplate(
      templateId: $templateId
      selectionEntityId: $selectionEntityId
      selectionEntityType: $selectionEntityType
    ) {
      ... on Selection {
        ...SelectionFragment
      }
      ... on SelectionTemplate {
        ...SelectionTemplateFragment
      }
    }
  }
  ${SELECTION_FRAGMENT}
  ${SELECTION_TEMPLATE_FRAGMENT}
`;
