import React from "react";
import classNames from "classnames";
import "./styles.scss";
import { ClientQuoteRequest } from "../../../graphql/types/models/quote-request";
import { formatQuoteNumber } from "../../../utils/text";
import { ClientSalesQuoteRequest } from ".";
import { map } from "lodash";
import Icon from "../../icons/Icon";

type QuoteRequestItemProps = {
  salesQuoteRequest: ClientSalesQuoteRequest;
  onSelect?: (quoteRequest: ClientQuoteRequest) => void;
  isOpen?: boolean;
  selectedQuoteRequest?: ClientQuoteRequest | null;
};

const QuoteRequestItem: React.FC<QuoteRequestItemProps> = ({
  salesQuoteRequest,
  onSelect,
  selectedQuoteRequest,
  isOpen: isOpenDefault,
}) => {
  const [isOpen, setIsOpen] = React.useState(!!isOpenDefault);

  const containerClasses = classNames(
    {
      "quote-request-sq-list--open": isOpen,
    },
    "quote-request-sq-list"
  );

  const toggleQuoteRequests = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const handleSelectQuoteRequest = React.useCallback(
    (quoteRequest) => {
      onSelect && onSelect(quoteRequest);
    },
    [onSelect]
  );

  return (
    <div className={containerClasses}>
      <div
        className="field-text quote-request-sq-item"
        onClick={toggleQuoteRequests}
      >
        <b
          className="quote-request-sq-item--title"
          title={salesQuoteRequest.name}
        >
          {salesQuoteRequest.name}
        </b>
        <span>({formatQuoteNumber(salesQuoteRequest.number, "J")})</span>
        <div style={{ flex: 1 }} />
        <Icon name={isOpen ? "expand_less" : "expand_more"} />
      </div>

      <ul className="quote-request-quotes-list">
        {map(salesQuoteRequest.quoteRequests, (quoteRequest) => (
          <li
            className={classNames("field-text quote-request-quote-item", {
              "quote-request-quotes-list--selected":
                selectedQuoteRequest?._id === quoteRequest._id,
            })}
            onClick={() => handleSelectQuoteRequest(quoteRequest)}
          >
            <div className="quote-request-quote-item--title">
              {quoteRequest.name}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuoteRequestItem;
