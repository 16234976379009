import { gql } from "@apollo/client";
import { JOB_MESSAGE_CLIENT_FRAGMENT } from "./fragments";

export const CLIENT_POST_MESSAGE = gql`
  mutation clientPostMessage(
    $channelId: ID!
    $jobId: ID!
    $message: MessageInput
  ) {
    clientPostMessage(channelId: $channelId, jobId: $jobId, message: $message) {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_CLIENT_FRAGMENT}
`;

export const JOB_DELETE_CLIENT_MESSAGE = gql`
  mutation clientDeleteMessage($channelId: ID!, $jobId: ID!, $messageId: ID!) {
    clientDeleteMessage(
      channelId: $channelId
      jobId: $jobId
      messageId: $messageId
    ) {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_CLIENT_FRAGMENT}
`;
