import * as yup from "yup";
import { TFunction } from "i18next";

import { ValidationMessagesUtils } from "../../../utils/yup";
import moment from "moment";

export default function createTaskGroupSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "schedule.name")
      ),
    startDate: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "schedule.startDate")
      ),
    endDate: yup
      .string()
      .test("endDateTest", "End Date Test Message", function (value: string) {
        const { startDate } = this.parent;
        return moment(value).isSameOrAfter(startDate);
      })
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "schedule.endDate")
      ),
  });
}
