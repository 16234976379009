import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import "./styles.scss";
import AuthenticationCard from "../authentication-card";
import createAffiliateSignUpFormSchema from "./SignUpForm.schema";
import SignUpFormBody from "./SignUpFormBody";
import SignUpFormFooter from "./SignUpFormFooter";
import { AffiliateSignUpPayload } from "../../../graphql/types/models/affiliate";

type AffiliateSignUpFormProps = {
  onSubmit: (values: AffiliateSignUpPayload) => void;
};

const AffiliateSignUpForm: React.FC<AffiliateSignUpFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      business_name: "",
      email: "",
      phone: "",
      ABN: "",
      address: "",
      city: "",
      state: "",
      postcode: "",
      hasBuilders: "no",
      about: "",
      additionalComment: "",
      termsAgreement: false,
    },
    validationSchema: createAffiliateSignUpFormSchema(t),
    onSubmit: (values) => {
      return onSubmit(values);
    },
  });

  return (
    <AuthenticationCard
      title={t("authentication.affiliateSignUp")}
      container={Form}
      containerProps={{
        onSubmit: handleSubmit,
        noValidate: true,
        className: "authentication-card-container sign-up-card",
      }}
      body={SignUpFormBody}
      bodyProps={{
        t,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }}
      footer={SignUpFormFooter}
      footerProps={{ t, isSubmitting }}
    />
  );
};

export default AffiliateSignUpForm;
