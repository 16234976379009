import React, { FC } from "react";
import classNames from "classnames";
import Icon from "../icons/Icon";
import "./style.scss";

type ConfirmInputProps = {
  onCancel: () => void;
  onSubmit: (value: string) => void;
  initialValue?: string;
  inOneRow?: boolean;
};

const ConfirmInput: FC<ConfirmInputProps> = ({
  onCancel,
  onSubmit,
  inOneRow,
  initialValue,
}) => {
  const [inputValue, setInputValue] = React.useState<string>(
    initialValue || ""
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = React.useCallback(() => {
    if (!inputValue) return;

    onSubmit(inputValue);
    setInputValue("");
  }, [inputValue, onSubmit]);

  const containerStyles = classNames("add-column-container", {
    insideColumn: inOneRow,
  });

  return (
    <div className={containerStyles}>
      <div className="wrapper">
        <input
          value={inputValue}
          onChange={handleChange}
          className="input-style"
          type="text"
          autoFocus
          minLength={1}
        />
        <div className="container-icons">
          <button
            onClick={handleSubmit}
            className="container-icon"
            disabled={!inputValue}
          >
            <Icon name="check" />
          </button>
          <button onClick={onCancel} className="container-icon">
            <Icon name="clear" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmInput;
