import { BaseEmoji, EmojiData } from "emoji-mart";
import { EditorState } from "draft-js";
import { insertText } from "../../../utils/draft";

export const createEmojiEntity = (
  emoji: EmojiData,
  editorState: EditorState
): EditorState => {
  const { colons, native } = emoji as BaseEmoji;

  if (!colons) {
    return editorState;
  }

  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "EMOJI",
    "IMMUTABLE",
    {
      emoji: colons,
      native,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  return insertText(editorState, native, entityKey);
};
