import { MutationUpdaterFn, ApolloCache } from "@apollo/client";
import { map } from "lodash";
import { CLIENT_LIST_VARIATIONS, GET_CLIENT_VARIATION } from "./queries";
import {
  ClientListVariationsResponse,
  GetClientJobVariationByIdResponse,
  ClientApproveVariationResponse,
} from "../../../types/models/client-job-variation";
import {
  VariationListItem,
  VariationReal,
} from "../../../../models/variations";

const getClientVariationList = (cache: ApolloCache<any>, jobId: string) => {
  const clientVariationsList = cache.readQuery<ClientListVariationsResponse>({
    query: CLIENT_LIST_VARIATIONS,
    variables: {
      jobId,
    },
  });

  return clientVariationsList?.clientGetJobVariations;
};

const updateClientVariationsList = (
  cache: ApolloCache<any>,
  jobId: string,
  variations: VariationListItem[]
) => {
  cache.writeQuery<ClientListVariationsResponse>({
    query: CLIENT_LIST_VARIATIONS,
    variables: {
      jobId,
    },
    data: {
      clientGetJobVariations: variations,
    },
  });
};

const getClientVariationById = (
  cache: ApolloCache<any>,
  jobId: string,
  variationId: string
) => {
  const clientVariationByIdResponse = cache.readQuery<
    GetClientJobVariationByIdResponse
  >({
    query: GET_CLIENT_VARIATION,
    variables: {
      jobId,
      variationId,
    },
  });

  return clientVariationByIdResponse?.clientGetJobVariationById;
};

const updateClientVariationById = (
  cache: ApolloCache<any>,
  jobId: string,
  variationId: string,
  variation: VariationReal
) => {
  cache.writeQuery<GetClientJobVariationByIdResponse>({
    query: GET_CLIENT_VARIATION,
    variables: {
      jobId,
      variationId,
    },
    data: {
      clientGetJobVariationById: variation,
    },
  });
};

export const handleVariationAccept = (
  jobId: string
): MutationUpdaterFn<ClientApproveVariationResponse> => (cache, { data }) => {
  const newVariation = data?.clientApproveVariation;

  if (!newVariation) return;

  const cacheListData = getClientVariationList(cache, jobId);
  const cacheByIdData = getClientVariationById(cache, jobId, newVariation._id);

  if (cacheListData) {
    const newData = map(cacheListData, (variation) =>
      variation._id === newVariation._id && newVariation.status
        ? {
            ...variation,
            status: newVariation.status,
          }
        : variation
    );

    updateClientVariationsList(cache, jobId, newData);
  }

  if (cacheByIdData && newVariation.status) {
    const newData = {
      ...cacheByIdData,
      status: newVariation.status,
    };

    updateClientVariationById(cache, jobId, newVariation._id, newData);
  }
};
