import { gql } from "@apollo/client";

export const TEAM_MEMBER = gql`
  fragment TeamMember on User {
    _id
    first_name
    last_name
    role
    email
    hourly_rate
    profileImage {
      _id
      url
    }
  }
`;
