import React from "react";
import Dropdown from "../components/dashboard/dropdown";
import { useTranslation } from "react-i18next";
import { chain, find } from "lodash";
import { useQuery } from "@apollo/client";
import { ListMembersResponse } from "../graphql/models/members";
import { LIST_MEMBERS } from "../graphql/members/queries";
import { getFullName } from "../utils/text";
import { UserPayload } from "../graphql/types/models/auth";
import { UserRoles } from "../models/team-member";

type DropdownMembersProps = {
  user: UserPayload | null;
};

export function useDropdownMembers({ user }: DropdownMembersProps) {
  const [memberId, setMemberId] = React.useState("");
  const { t } = useTranslation();

  const { data: teamData, loading: membersLoading } = useQuery<
    ListMembersResponse
  >(LIST_MEMBERS);

  const selectedMember = React.useMemo(() => {
    if (!memberId) return t("tasks.allTeamMembers");
    const member = find(teamData?.listMembers, { _id: memberId });
    return getFullName(member);
  }, [memberId, teamData]);

  const memberOptions = React.useMemo(
    () =>
      [
        {
          id: "all",
          label: t("tasks.allTeamMembers"),
          onClick: () => setMemberId(""),
        },
      ].concat(
        chain(teamData?.listMembers)
          .map((member) => ({
            id: member._id,
            label: getFullName(member),
            onClick: () => setMemberId(member._id),
          }))
          .sortBy("label")
          .value()
      ),
    [teamData]
  );

  const renderDropdownMembers = () => {
    if (
      user?.role !== UserRoles.builderadmin &&
      user?.role !== UserRoles.manager
    )
      return null;
    return (
      <Dropdown
        label={selectedMember}
        icon="expand_more"
        id="filter-member"
        items={memberOptions}
      />
    );
  };

  return { memberId, renderDropdownMembers, membersLoading, setMemberId };
}
