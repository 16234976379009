import React, { useState } from "react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import CreateEntityModal from "../../modals/create-entity";
import UpdateEntityModal from "../../modals/update-entity";
import { CreateTodoListPayload, TodoList } from "../../../models/task";
import { createTaskField } from "./utils";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createTaskSchema from "./CreateTask.schema";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { UserPayload } from "../../../graphql/types/models/auth";
import { useJobsQuery } from "../../../hooks/queries/useJobsQuery";
import { useCreateAnother } from "../../../hooks/useCreateAnother";

type CreateUpdateTaskModalProps = {
  data: TodoList | null;
  user: UserPayload | null;
  canAssign: boolean;
  show: boolean;
  onSubmit: (data: CreateTodoListPayload, createAnother: boolean) => void;
  onClose: () => void;
  leadId?: string;
};

const CreateUpdateTaskModal: React.FC<CreateUpdateTaskModalProps> = (props) => {
  const { data, show, user, canAssign, onSubmit, onClose, leadId } = props;
  const hasLeadId = Boolean(leadId);
  const { t } = useTranslation();
  const { jobs } = useJobsQuery();

  const { shouldCreateAnother, renderCreateAnother } = useCreateAnother(
    t("schedule.createAnotherTask")
  );

  const [fields, setFields] = useState<
    GenericFormFields<CreateTodoListPayload>
  >({});
  const [initData, setInitData] = React.useState<CreateTodoListPayload>({
    task: "",
    description: "",
    dueDate: "",
    userId: user?._id || "",
    jobId: "",
    leadId: leadId || "",
    members: [user?._id || ""],
  });

  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  React.useEffect(() => {
    setFields(
      createTaskField(
        t,
        canAssign,
        teamData?.listMembers || [],
        jobs,
        hasLeadId
      )
    );
  }, [t, teamData, jobs]);

  const handleSubmit = React.useCallback(
    (data: CreateTodoListPayload, helpers?: FormikHelpers<any>) => {
      onSubmit && onSubmit(data, shouldCreateAnother);
      helpers?.resetForm();

      if (shouldCreateAnother)
        setInitData({ ...data, task: "", description: "" });
    },
    [shouldCreateAnother, onSubmit]
  );

  return (
    <>
      {data ? (
        <UpdateEntityModal<CreateTodoListPayload>
          validationSchema={createTaskSchema(t)}
          title={t("tasks.updateTask")}
          data={{
            task: data.task,
            description: data.description,
            dueDate: data.dueDate,
            jobId: data.job?._id || "",
            leadId: leadId || "",
            members: data?.members?.map((member) => member._id) || [],
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      ) : (
        <CreateEntityModal<CreateTodoListPayload>
          validationSchema={createTaskSchema(t)}
          title={t("tasks.createNew")}
          data={initData}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
          leftFooterRenderer={renderCreateAnother}
        />
      )}
    </>
  );
};

export default CreateUpdateTaskModal;
