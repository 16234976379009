import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import saveSelectionAsTemplateSchema from "./SaveAsTemplate.schema";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import { saveSelectionAsTemplateFields } from "./utils";
import { useSelectionTemplates } from "../../../hooks/useSelectionTemplates";

type SaveSelectionAsTemplateModalProps = {
  selectionEntityId?: string;
};

const SaveSelectionAsTemplateModal: React.FC<SaveSelectionAsTemplateModalProps> = (
  { selectionEntityId },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<{ name: string; description: string }>
  >({});

  const { selectionTemplates, saveSelectionAsTemplate } = useSelectionTemplates(
    {
      onSave: hide,
    }
  );

  React.useEffect(() => {
    if (!selectionTemplates) return;
    setFormFields(saveSelectionAsTemplateFields(t));
  }, [t, selectionTemplates]);

  const handleSubmit = React.useCallback(
    (values: { name: string; description: string }) => {
      return saveSelectionAsTemplate({
        variables: {
          selectionEntityId,
          template: {
            ...values,
          },
        },
      });
    },
    [selectionEntityId]
  );

  const validationSchema = React.useMemo(
    () => saveSelectionAsTemplateSchema(t, selectionTemplates),
    [t, selectionTemplates]
  );

  if (!selectionTemplates) return null;

  return (
    <CreateEntityModal
      validationSchema={validationSchema}
      className="import-selection-template"
      title={t("selections.saveAsTemplate")}
      show={shouldShow}
      data={{
        name: "",
        description: "",
      }}
      submitText={t("common.save")}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
    />
  );
};

export default forwardRef(SaveSelectionAsTemplateModal);
