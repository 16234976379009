import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function importScheduleFromTemplateSchema(t: TFunction) {
  return yup.object().shape({
    scheduleTemplateId: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "schedule.scheduleTemplate"
        )
      ),
    date: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "schedule.startDate")
      ),
  });
}
