import React from "react";
import { Field } from "formik";
import { Badge } from "react-bootstrap";
import classNames from "classnames";
import { CategorySelectorPayload } from ".";
import "./styles.scss";

type CategoryItemProps = {
  item: CategorySelectorPayload;
  selectable?: boolean;
  className?: string;
  onSelect?: () => void;
  selected?: boolean;
};

const CategorySelectItem: React.FC<CategoryItemProps> = ({
  item,
  className,
  onSelect,
  selectable,
  selected,
}) => {
  return (
    <div
      className={classNames(className, "list-item", {
        "item-selected": selected,
      })}
      onClick={onSelect}
    >
      <div className="d-flex flex-row align-items-center w-100">
        {selectable && (
          <div className="justify-content-center pr-2">
            <Field
              className="list-checkbox"
              type="checkbox"
              name="selected"
              value={item.id}
            />
          </div>
        )}
        <div className="d-flex justify-content-between overflow-hidden w-100">
          <div className="pt-1 pb-1 pr-3 list-item-label">
            <div>{item.label}</div>
            {item.description && (
              <div className="list-item-label">
                <small>{item.description}</small>
              </div>
            )}
          </div>
          <div className="text-right pt-1 pb-1">
            {item.rightBadge?.length && (
              <div>
                {item.rightBadge.map((badge, index) => (
                  <Badge
                    className="ml-1"
                    key={`rightBadge-${index}`}
                    variant={badge.variant}
                  >
                    {badge.label}
                  </Badge>
                ))}
              </div>
            )}
            {item.rightLabel && (
              <div>
                <small>{item.rightLabel}</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CategorySelectItem;
