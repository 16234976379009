import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createCategorySchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(ValidationMessagesUtils.createRequiredMessage(t, "tasks.task")),
    description: yup.string(),
    dueDate: yup.string(),
  });
}
