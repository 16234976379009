import { useQuery } from "@apollo/client";
import React from "react";
import {
  ListAllMessagesResponse,
  NewMessageSubscriptionResponse,
} from "../../../graphql/types/models/job-messaging";
import { LIST_ALL_MESSAGES } from "../../../graphql/queries/job-messaging/queries";
import { JOB_ALL_CHANNEL_SUBSCRIBE_NEW_MESSAGE } from "../../../graphql/queries/job-messaging/subscription";
import { updateAllChannels } from "../../../graphql/queries/job-messaging/utils";
import { messageNotify } from "../../../components/message-notification";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { formatQuoteNumber } from "../../../utils/text";
import { UserPayload } from "../../../graphql/types/models/auth";

type MessageNotificationLayoutProps = {
  user?: UserPayload | null;
  children?: React.ReactNode;
};
const MessageNotificationLayout = (props: MessageNotificationLayoutProps) => {
  const { user, children } = props;
  const history = useHistory();
  const location = useLocation();
  const {
    data: channelsListResponse,
    subscribeToMore: subscribeToAllMessages,
  } = useQuery<ListAllMessagesResponse>(LIST_ALL_MESSAGES, {
    variables: {
      internalLimit: 1,
      externalLimit: 1,
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    const unsubNewMessages = subscribeToAllMessages<
      NewMessageSubscriptionResponse
    >({
      document: JOB_ALL_CHANNEL_SUBSCRIBE_NEW_MESSAGE,
      updateQuery: (previousQueryResult, response) => {
        const result = updateAllChannels(previousQueryResult, response);
        const newMessage =
          response.subscriptionData.data.jobChannelSubscribeAll;

        const job = newMessage.channel.job;
        const jobChannelUrl = generatePath("/jobs/:id/messages/:channelId", {
          id: job?._id || "",
          channelId: newMessage.channel?._id,
        });
        const globalChannelUrl = generatePath(
          "/communication/messages/:channelId",
          {
            channelId: newMessage.channel?._id,
          }
        );
        if (
          location.pathname !== jobChannelUrl &&
          location.pathname !== globalChannelUrl &&
          newMessage.sender._id !== user?._id &&
          !newMessage.is_deleted
        ) {
          const handleOnLinkPress = () => {
            if (job?._id) {
              history.push(jobChannelUrl);
            }
          };

          const notifyTitle = `${formatQuoteNumber(
            Number(job?.jobNumber),
            "J"
          )} ${job?.name}`;

          messageNotify({
            title: notifyTitle,
            content: newMessage.text,
            sender: newMessage.sender,
            withSound: true,
            onLinkPress: handleOnLinkPress,
          });
        }

        return result;
      },
    });

    return () => {
      unsubNewMessages?.();
    };
  }, [location, user, subscribeToAllMessages]);

  return <>{children}</>;
};

export default MessageNotificationLayout;
