import React from "react";
import { useTranslation } from "react-i18next";
import "../../../components/header/client-header-list/styles.scss";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_CONTACTS_NAMES } from "../../../graphql/queries/client/queries";
import { ListContactsNamesResponse } from "../../../graphql/types/models/client";
import { useParams } from "react-router-dom";
import { notify } from "../../../components/notification";
import {
  ADD_CONTACT_TO_JOB,
  DELETE_JOB_CONTACT,
} from "../../../graphql/job-contact/mutations";
import {
  AddJobContactResponse,
  RemoveJobContactResponse,
} from "../../../graphql/models/job-contact";
import {
  AddJobContactsInput,
  RemoveJobContactInput,
} from "../../../graphql/input/job-contacts";
import ClientHeader from "../../../components/header/client-header-list";
import {
  handleJobContactAdd,
  handleJobContactRemove,
} from "../../../graphql/job-contact/utils";
import { differenceWith } from "lodash";
import { ContactNameListItem } from "../../../models/client";
import { JobContact } from "../../../models/job-contact";
import { useVisibilityWithOutsideClick } from "../../../hooks/useVisibilityWithOutsideClick";
import { useGrantJobContact } from "../../../hooks/useGrantJobContact";

type Params = {
  id: string;
};

type ClientHeaderListProps = {
  isReadonly?: boolean;
};

const ClientHeaderList: React.FC<ClientHeaderListProps> = (props) => {
  const { isReadonly = false } = props;

  const { t } = useTranslation();

  const { id: jobId } = useParams<Params>();
  const {
    ref: showClientsRef,
    isVisible: showClientsList,
    setIsVisible: setClientsListVisibility,
  } = useVisibilityWithOutsideClick(false);

  const {
    selectedClient,
    setSelectedClient,
    renderConfirmGrantDialogs,
    contacts,
    jobContactsLoading,
    accessRevoking,
    accessGranting,
    revokeAccessConfirm,
    grantAccessConfirm,
    handleAccessGrant,
  } = useGrantJobContact(jobId);

  const toggleClientList = React.useCallback(() => {
    setClientsListVisibility((visible) => !visible);
  }, []);

  const { data: allContacts, loading: contactsLoading } = useQuery<
    ListContactsNamesResponse
  >(LIST_CONTACTS_NAMES);

  const [addContact, { loading: contactAdding }] = useMutation<
    AddJobContactResponse,
    AddJobContactsInput
  >(ADD_CONTACT_TO_JOB, {
    update: handleJobContactAdd(jobId),
  });
  const [removeContact, { loading: contactDeleting }] = useMutation<
    RemoveJobContactResponse,
    RemoveJobContactInput
  >(DELETE_JOB_CONTACT, {
    update: handleJobContactRemove(jobId),
  });

  React.useEffect(() => {
    const addClient = async () => {
      if (!selectedClient) {
        return;
      }

      try {
        await addContact({
          variables: {
            jobId,
            contactId: selectedClient,
          },
        });

        notify({
          title: t("clientAccess.addContact"),
          content: t("clientAccess.success.addContact"),
        });
      } catch (e) {
        notify({
          title: t("clientAccess.addContact"),
          content: t("clientAccess.error.addContact"),
          error: true,
        });
      }

      setSelectedClient(null);
    };

    addClient();
  }, [addContact, jobId, selectedClient]);

  const onCreatedRecord = React.useCallback(
    async (contactId: string) => {
      setSelectedClient(contactId);
    },
    [setSelectedClient]
  );

  const handleRemove = React.useCallback(
    async (jobContactId: string) => {
      try {
        await removeContact({
          variables: {
            jobId,
            jobContactId,
          },
        });

        notify({
          title: t("clientAccess.removeContact"),
          content: t("clientAccess.success.removeContact"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("clientAccess.removeContact"),
          content: t("clientAccess.error.removeContact"),
        });
      }
    },
    [jobId, removeContact, t]
  );

  const filteredContacts = React.useMemo(() => {
    if (jobContactsLoading || contactsLoading) {
      return [];
    }

    return differenceWith(
      allContacts?.listContacts || [],
      contacts?.jobContacts || [],
      (client: ContactNameListItem, jobContact: JobContact) => {
        return client._id === jobContact.contact?._id;
      }
    );
  }, [contactsLoading, allContacts, jobContactsLoading, contacts]);

  return (
    <>
      {renderConfirmGrantDialogs()}
      <ClientHeader
        ref={showClientsRef}
        updating={
          contactDeleting ||
          contactAdding ||
          jobContactsLoading ||
          accessRevoking ||
          accessGranting
        }
        onRemove={handleRemove}
        onAccessRevoke={revokeAccessConfirm}
        onAccessGrant={grantAccessConfirm}
        showClientsList={showClientsList}
        toggleClientList={toggleClientList}
        contacts={filteredContacts}
        selectedClient={selectedClient}
        onAddContact={handleAccessGrant}
        jobContacts={contacts?.jobContacts}
        onCreatedRecord={onCreatedRecord}
        isReadonly={isReadonly}
      />
    </>
  );
};

export default ClientHeaderList;
