import React from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import Icon from "../icons/Icon";
import { CalcButton } from "./CalculatorInput";

const CalculatorButton: React.FC<CalcButton> = (props) => {
  const { icon, value, className, text, onClick } = props;

  const handleClick = React.useCallback(() => {
    onClick && onClick(value);
  }, [value, onClick]);

  const buttonClasses = classNames(
    "calc-btn",
    className || "button info btn btn-primary"
  );

  return (
    <Button key={value} className={buttonClasses} onClick={handleClick}>
      {icon && <Icon name={icon} outlined className="icon-centered" />}
      {text && text}
    </Button>
  );
};

export default CalculatorButton;
