import React from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../../hooks/useModalDisplay";
import { TableCardData } from "../../../../components/dashboard/table-card/utils";
import { formatQuoteNumber } from "../../../../utils/text";
import LeftModal from "../../../../components/left-modal";
import LeftModalHeader from "../../../../components/left-modal/LeftModalHeader";
import LeftModalBody from "../../../../components/left-modal/LeftModalBody";
import CardTable from "../../../../components/dashboard/table-card/CardTable";
import { VariationModalListItem } from "../../../../models/variations";
import "./styles.scss";

type VariationItemsTable = {
  _id: string;
  date: string;
  name: string;
  quantity: number;
  UOM: string;
  cost: number;
  total: number;
  status: string;
  variationNumber: string;
  jobId: string;
};

export type ListVariationItemsModalRef = ModalDisplayRef & {
  setData: (data: VariationModalListItem[], jobId: string) => void;
};

const ListVariationItemsModal: React.FC = (_props, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = React.useState<VariationModalListItem[]>([]);

  const { shouldShow, hide } = useModalDisplay<ListVariationItemsModalRef>(
    ref,
    {
      setData: (data: VariationModalListItem[], jobId: string) => {
        setData(data.map((item) => ({ ...item, jobId })));
      },
    } as ListVariationItemsModalRef,
    [setData]
  );

  const tableData = React.useMemo<TableCardData<VariationItemsTable>>(() => {
    return {
      columns: [
        {
          valueKey: "date",
          title: t("variations.date"),
          formatValue: (row: any, column: any, value: number) =>
            moment(value).format("Do MMMM YYYY"),
        },
        {
          valueKey: "variationNumber",
          title: t("variations.variationOrder"),
          className: "table-cell-link",
          onClick: (row) => {
            hide();
            history.push(
              `/jobs/${row.cells.jobId}/variations/${row.cells._id}`
            );
          },
        },
        {
          valueKey: "status",
          title: t("common.status"),
          formatValue: (row: any, column: any, value: number) =>
            t(`variations.statuses.${value}`),
        },
        {
          valueKey: "name",
          title: t("orders.nameLabel"),
        },
        {
          valueKey: "quantity",
          title: t("costing.quantity"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.quantity", { amount: value }),
        },
        {
          valueKey: "UOM",
          title: t("costing.uom"),
        },
        {
          valueKey: "cost",
          title: t("costing.cost"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "total",
          title: t("costing.totalEx"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(data, (item) => ({
        cells: {
          _id: item.variation._id,
          jobId: item.jobId,
          name: item.name,
          quantity: item.quantity,
          UOM: item.UOM,
          cost: item.cost,
          total: item.cost * item.quantity,
          date: item.variation.date,
          status: item.variation.status,
          variationNumber: formatQuoteNumber(
            item.variation.variationNumber,
            "V"
          ),
        },
      })),
    };
  }, [data, hide, history, t]);

  return (
    <LeftModal show={shouldShow} onHide={hide} className="list-variations">
      <LeftModalHeader title={t("variations.variations")} onClose={hide} />
      <LeftModalBody>
        <CardTable table={tableData} tableClass="table-outline" />
      </LeftModalBody>
    </LeftModal>
  );
};

export default React.forwardRef(ListVariationItemsModal);
