import React, { forwardRef, useImperativeHandle } from "react";
import CreateEntityModal from "../../modals/create-entity";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  CreateTaskPayload,
  ScheduleCategory,
  ScheduleCategoryItem,
} from "../../../models/job";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateSupplierModal, {
  CreateSupplierModalRef,
} from "../../contacts/create-supplier-modal";
import { createTaskFields } from "./utils";
import createTaskSchema from "./CreateScheduleTask.schema";
import { SupplierListResponse } from "../../../graphql/types/models/supplier";
import { LIST_SUPPLIERS } from "../../../graphql/queries/supplier/queries";
import { FormikHelpers } from "formik";
import { TaskAssignTo } from "../../../utils/options";
import { omit } from "lodash";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { useCreateAnother } from "../../../hooks/useCreateAnother";
import { SupplierDetails } from "../../../models/supplier";
import "./styles.scss";

type CreateTaskModalProps = {
  onSubmit: (
    data: CreateTaskPayload,
    category: ScheduleCategory,
    createAnother: boolean
  ) => void;
  tasks: ScheduleCategoryItem[];
};

export type CreateTaskModalRef = {
  show: (
    show: boolean,
    category?: ScheduleCategory,
    taskData?: {
      start: Date;
      end: Date;
    }
  ) => void;
};

const CreateTaskModal: React.FC<CreateTaskModalProps> = (props, ref) => {
  const { onSubmit, tasks } = props;
  const [show, setShow] = React.useState(false);
  const [assignTo, setAssignTo] = React.useState<TaskAssignTo>(
    TaskAssignTo.TEAM
  );
  const [customReminder, setCustomReminder] = React.useState(0);
  const [
    parentCategory,
    setParentCategory,
  ] = React.useState<ScheduleCategory | null>(null);
  const [taskData, setTaskData] = React.useState<{
    start: string | null;
    end: string | null;
  }>({
    start: null,
    end: null,
  });
  const supplierRef = React.useRef<CreateSupplierModalRef>(null);
  const createTaskModalRef = React.useRef<any>(null);
  const { t } = useTranslation();

  const { shouldCreateAnother, renderCreateAnother } = useCreateAnother(
    t("schedule.createAnotherTask")
  );

  useImperativeHandle(ref, () => ({
    show: (
      show: boolean,
      category?: ScheduleCategory,
      taskData?: { start: Date; end: Date }
    ) => {
      setShow(show);
      if (category) {
        setParentCategory(category);
      }
      if (taskData) {
        setTaskData({
          start: taskData.start.toISOString(),
          end: taskData.end.toISOString(),
        });
      }
    },
  }));

  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const { data: suppliersData } = useQuery<SupplierListResponse>(
    LIST_SUPPLIERS
  );

  const suppliers = React.useMemo(() => suppliersData?.listSuppliers, [
    suppliersData,
  ]);
  const team = React.useMemo(() => teamData?.listMembers, [teamData]);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateTaskPayload>
  >({});

  const openSupplierModal = React.useCallback(
    () => supplierRef.current?.show(true),
    [supplierRef]
  );

  const handleReminderCreate = React.useCallback((value: string) => {
    setCustomReminder(Number(value));
  }, []);

  const onAssignToChange = React.useCallback(
    (value: string) => {
      if (!parentCategory || !suppliers || !team) return;
      const assignValue =
        TaskAssignTo.TEAM.toString() === value
          ? TaskAssignTo.TEAM
          : TaskAssignTo.SUPPLIER;
      setAssignTo(assignValue);

      setFormFields(
        createTaskFields(
          t,
          parentCategory.startDate,
          parentCategory.endDate,
          suppliers,
          openSupplierModal,
          team,
          onAssignToChange,
          assignValue,
          tasks,
          customReminder,
          handleReminderCreate
        )
      );
    },
    [
      parentCategory,
      suppliers,
      team,
      tasks,
      customReminder,
      handleReminderCreate,
    ]
  );

  const resetForm = React.useCallback(() => {
    if (!parentCategory || !suppliers || !team) return;
    setFormFields(
      createTaskFields(
        t,
        parentCategory.startDate,
        parentCategory.endDate,
        suppliers,
        openSupplierModal,
        team,
        onAssignToChange,
        assignTo,
        tasks,
        customReminder,
        handleReminderCreate
      )
    );
  }, [
    t,
    suppliers,
    team,
    tasks,
    parentCategory,
    openSupplierModal,
    customReminder,
    handleReminderCreate,
  ]);

  React.useEffect(() => {
    if (!parentCategory || !suppliers) return;
    resetForm();
  }, [t, suppliers, parentCategory]);

  const handleSubmit = React.useCallback(
    (values: CreateTaskPayload, helpers?: FormikHelpers<any>) => {
      if (!parentCategory) return;
      const filtereValues = {
        ...omit(values, ["assignTo"]),
        supplier:
          assignTo === TaskAssignTo.SUPPLIER && values.supplier
            ? values.supplier
            : null,
        user:
          assignTo === TaskAssignTo.TEAM && values.user ? values.user : null,
      };
      onSubmit(filtereValues, parentCategory, shouldCreateAnother);
      helpers?.resetForm();
      setFormFields({}); // required to reset suppliers selection
      resetForm();
      setTaskData({
        start: null,
        end: null,
      });
      return true;
    },
    [
      resetForm,
      onSubmit,
      parentCategory,
      assignTo,
      shouldCreateAnother,
      setTaskData,
    ]
  );

  const handleSetNewSupplier = React.useCallback(
    (supplier: SupplierDetails) => {
      createTaskModalRef.current?.setFieldValue("supplier", supplier._id);
    },
    [createTaskModalRef]
  );

  const handleOnClose = React.useCallback(() => {
    setTaskData({
      start: null,
      end: null,
    });
    setShow(false);
  }, []);

  if (!parentCategory) return null;

  return (
    <CreateEntityModal
      formikRef={createTaskModalRef}
      validationSchema={createTaskSchema(t)}
      className="create-task-modal"
      title={t("schedule.addTask")}
      show={show}
      data={{
        name: "",
        description: "",
        startDate: taskData.start || parentCategory.startDate,
        endDate: taskData.end || parentCategory.endDate,
        progress: 0,
        supplier: null,
        user: null,
        assignTo: TaskAssignTo.TEAM,
      }}
      onSubmit={handleSubmit}
      onClose={handleOnClose}
      fields={formFields}
      leftFooterRenderer={renderCreateAnother}
    >
      {() => (
        <CreateSupplierModal
          ref={supplierRef}
          onCreate={handleSetNewSupplier}
        />
      )}
    </CreateEntityModal>
  );
};

export default forwardRef(CreateTaskModal);
