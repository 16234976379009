import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCard from "../../dashboard/card";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import Button from "react-bootstrap/Button";
import "./styles.scss";
import Icon from "../../icons/Icon";
import JobCostTotals from "../total/job-costing-totals";
import Totals from "../total/";
import { compact, pick, map } from "lodash";
import CostingCategoryItem from "./CostingCategoryItem";
import { CalcCategoryItem } from "../../../utils/calculations";
import { JobCostingItem } from "../../../models/job";
import { CategoryCostingItem } from "../../../models/costing";
import Dropdown from "../../dashboard/dropdown";
import SortableList, { SortableListRef } from "../../sortable-list";
import { SortableItem } from "../../sortable-list/types";
import { ConnectDragSource } from "react-dnd";
import ToggleInput from "../../generic-form/inputs/toggle";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { EnumSalesQuoteCategoryStatus } from "../../../models/salesQuote";
import { SearchOptions } from "../../category-select-card";
import SearchInput from "../../search-input";
import EmptyPlaceholder from "../../empty-placeholder";

export type CategorySelectorCosting = {
  _id: string;
  name: string;
  margin_amount?: number;
  costings?: CalcCategoryItem[];
  items?: CalcCategoryItem[];
  is_locked?: boolean;
  order?: number;
  status?: EnumSalesQuoteCategoryStatus;
};

type CostingCategoriesProps = {
  categories?: CategorySelectorCosting[];
  itemsForQuote?: JobCostingItem[] | CategoryCostingItem[];
  onCategorySelect?: (category: CategorySelectorCosting) => void;
  onAddClick?: () => void;
  subtotal?: number;
  total?: number;
  grandTotal?: number;
  totalEstimatedCost?: number;
  totalRealCost?: number;
  totalMarkup?: number;
  selectedCategory: CategorySelectorCosting | null;
  gst?: number;
  markup?: number;
  markupPercent?: number;
  displayCostingTotals?: boolean;
  displayNavButtons?: boolean;
  addButtonPlacement?: "top" | "bottom" | "none";
  allowanceFilter?: boolean;
  disabled?: boolean;
  sortable?: boolean;
  onBackClick?: () => void;
  onNextClick?: () => void;
  onDelete?: (categoryId: string) => void;
  onCopy?: (categoryId: string) => void;
  jobId?: string;
  onImportCostings?: () => void;
  onImportFromTemplate?: () => void;
  onSaveTemplate?: () => void;
  onEditMarkup?: () => void;
  onPrintBillOfQuantity?: () => void;
  onDownloadCostings?: () => void;
  onShowPricesIncGst?: () => void;
  onShowPricesExGst?: () => void;
  showPricesIncGst?: boolean;
  onFilterChange?: (isAllowance: boolean) => void;
  onSort?: (ordered: CategorySelectorCosting[]) => void;
  onSync?: () => void;
  syncProvider?: string;
  titleBadge?: string;
  searchOptions?: SearchOptions;
  placeholder?: string;
};

const CostingCategories: React.FC<CostingCategoriesProps> = (props) => {
  const {
    categories,
    itemsForQuote,
    subtotal,
    total,
    grandTotal,
    gst,
    markup,
    markupPercent,
    onCategorySelect,
    selectedCategory,
    onAddClick,
    displayCostingTotals,
    displayNavButtons,
    addButtonPlacement,
    allowanceFilter,
    disabled,
    sortable,
    onDelete,
    onCopy,
    onNextClick,
    onBackClick,
    totalEstimatedCost,
    totalRealCost,
    totalMarkup,
    jobId,
    onEditMarkup,
    onImportCostings,
    onImportFromTemplate,
    onSaveTemplate,
    onPrintBillOfQuantity,
    onDownloadCostings,
    onSync,
    syncProvider,
    onFilterChange,
    onSort,
    onShowPricesIncGst,
    showPricesIncGst,
    onShowPricesExGst,
    titleBadge,
    searchOptions,
    placeholder,
  } = props;

  const { t } = useTranslation();
  const [dropdownShown, setDropdownShown] = useState(false);
  const [filterShown, setFilterShown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [trueCostTotal, setTrueCostTotal] = React.useState<boolean>(false);
  const listRef = React.useRef<SortableListRef>(null);

  const trueCostStorageKey = `jobCostingTrueCostTotal_${jobId}`;
  const {
    storedValue: storedValues,
    setStoredValue: setStoreTrueCostTotal,
  } = useLocalStorage(trueCostStorageKey, {
    trueCostTotal,
  });

  const toggleDropdown = React.useCallback(() => {
    setDropdownShown(!dropdownShown);
  }, [dropdownShown]);

  const toggleFilter = React.useCallback(() => {
    setFilterShown(!filterShown);
  }, [filterShown]);

  const handleTrueCostToggle = React.useCallback(
    (value: boolean) => {
      setTrueCostTotal(value);
      setStoreTrueCostTotal({ trueCostTotal: value });
    },
    [setStoreTrueCostTotal]
  );

  const dropdownItems = React.useMemo(() => {
    return compact([
      !showPricesIncGst
        ? onShowPricesIncGst && {
            id: "pricesIncGst",
            label: t("quotes.showPricesIncGst"),
            icon: "attach_money",
            name: "pricesIncGst",
            outlined: true,
            onClick: onShowPricesIncGst,
          }
        : onShowPricesExGst && {
            id: "pricesExGst",
            label: t("quotes.showPricesExGst"),
            icon: "attach_money",
            name: "pricesExGst",
            outlined: true,
            onClick: onShowPricesExGst,
          },
      onEditMarkup && {
        id: "markup",
        label: t("quotes.editGlobalMarkup"),
        icon: "percent",
        name: "markup",
        outlined: true,
        onClick: onEditMarkup,
      },
      onImportCostings && {
        id: "upload",
        label: t("costing.importCostings"),
        icon: "upload",
        name: "upload",
        outlined: true,
        onClick: onImportCostings,
      },
      onImportFromTemplate && {
        id: "importFromTemplate",
        label: t("quotes.importFromTemplate"),
        icon: "import_export",
        name: "importFromTemplate",
        outlined: true,
        onClick: onImportFromTemplate,
      },
      onSaveTemplate && {
        id: "save",
        label: t("costing.saveCostAsTemplate"),
        icon: "save",
        name: "save",
        outlined: true,
        onClick: onSaveTemplate,
      },
      onPrintBillOfQuantity && {
        id: "printBOQ",
        label: t("costing.downloadBillOfQuantity"),
        icon: "print",
        name: "print",
        outlined: true,
        onClick: onPrintBillOfQuantity,
      },
      onDownloadCostings && {
        id: "download",
        label: t("costing.downloadCostings"),
        icon: "download",
        name: "download",
        outlined: true,
        onClick: onDownloadCostings,
      },
      onSync && {
        id: "sync",
        label: t("integrations.syncWithProvider", {
          provider: t(`integrations.${syncProvider?.toLowerCase()}`),
        }),
        icon: "sync",
        name: "sync",
        outlined: true,
        onClick: onSync,
      },
    ]);
  }, [categories, showPricesIncGst, syncProvider]);

  const sortableItems = React.useMemo((): SortableItem[] => {
    return map(categories, (category) => ({
      id: category._id,
      item: category,
    }));
  }, [categories]);

  const onDrop = React.useCallback(() => {
    if (!onSort) return;
    const newOrder = listRef.current?.getOrder()?.map(
      ({ item }, order) =>
        ({
          ...pick(item, ["_id", "name"]),
          order,
        } as CategorySelectorCosting)
    );
    if (newOrder) {
      onSort(newOrder);
    }
  }, [onSort, listRef]);

  const renderCategoryItem = React.useCallback(
    (
      category: CategorySelectorCosting,
      dragRef?: ConnectDragSource,
      showPricesIncGst?: boolean
    ) => {
      return (
        <CostingCategoryItem
          key={category._id}
          category={category}
          onDelete={!disabled ? onDelete : undefined}
          onCopy={!disabled ? onCopy : undefined}
          onClick={onCategorySelect}
          isSelected={category._id === selectedCategory?._id}
          itemsForQuote={itemsForQuote}
          trueCostTotal={trueCostTotal}
          dragRef={dragRef}
          showPricesIncGst={showPricesIncGst}
          markupPercent={markupPercent}
        />
      );
    },
    [
      itemsForQuote,
      onCategorySelect,
      disabled,
      onDelete,
      onCopy,
      selectedCategory,
      trueCostTotal,
      showPricesIncGst,
      markupPercent,
    ]
  );

  const renderSortableCategoryItem = React.useCallback(
    () => (data: SortableItem, dragRef: ConnectDragSource) => {
      const { item } = data;
      const category = item as CategorySelectorCosting;
      return renderCategoryItem(category, dragRef, showPricesIncGst);
    },
    [
      renderCategoryItem,
      selectedCategory,
      disabled,
      showPricesIncGst,
      trueCostTotal,
      itemsForQuote,
    ]
  );

  const renderCategories = React.useCallback(() => {
    if (!sortable) {
      return map(categories, (category) =>
        renderCategoryItem(category, undefined, showPricesIncGst)
      );
    }
    return searchOptions?.value && !categories?.length ? (
      <EmptyPlaceholder
        message={placeholder ?? t("placeholders.noCategories")}
      />
    ) : (
      <SortableList
        ref={listRef}
        onDrop={onDrop}
        items={sortableItems}
        renderItem={renderSortableCategoryItem()}
      />
    );
  }, [
    listRef,
    categories,
    selectedCategory,
    sortableItems,
    sortable,
    disabled,
    trueCostTotal,
    showPricesIncGst,
    markupPercent,
    itemsForQuote,
  ]);

  const handleFilterSelect = React.useCallback(
    (id: number) => {
      setSelectedFilter(id);
      onFilterChange && onFilterChange(id > 0);
    },
    [setSelectedFilter, onFilterChange]
  );

  const filterOptions = React.useMemo(() => {
    return [
      {
        id: "categories",
        label: t("costing.allCategories"),
        icon: "category",
        name: "categories",
        outlined: true,
        onClick: () => handleFilterSelect(0),
      },
      {
        id: "allowances",
        label: t("costing.allowances"),
        icon: "font_download",
        name: "allowances",
        outlined: true,
        onClick: () => handleFilterSelect(1),
      },
    ];
  }, [handleFilterSelect]);

  const title = React.useMemo(() => {
    if (!allowanceFilter) {
      return filterOptions[selectedFilter].label;
    }
    return (
      <Dropdown
        isVisible={filterShown}
        handleToggle={toggleFilter}
        label={filterOptions[selectedFilter].label}
        icon="expand_more"
        size="200px"
        id="title-filter"
        toggleClass="justify-content-start"
        items={filterOptions}
      />
    );
  }, [filterOptions, filterShown, allowanceFilter, selectedFilter]);

  return (
    <DashboardCard className="quote-costing-categories">
      <DashboardCardHeader className="text-capitalize justify-content-between">
        <div className="d-flex align-items-center">
          {!searchOptions ? (
            title
          ) : (
            <SearchInput
              value={searchOptions.value}
              onChange={searchOptions.onChange}
              className="searchInput"
              showCancelButton
            />
          )}

          {titleBadge && (
            <div className="title-badge text-capitalize">{titleBadge}</div>
          )}
        </div>

        {dropdownItems.length > 0 && (
          <Dropdown
            isVisible={dropdownShown}
            handleToggle={toggleDropdown}
            label={t("common.options")}
            icon="expand_more"
            menuWidth="270px"
            id="costing-dropdown"
            items={dropdownItems}
          />
        )}
      </DashboardCardHeader>
      <DashboardCardBody>
        {addButtonPlacement === "top" && (
          <Button
            disabled={disabled}
            className="button info add-category"
            block
            onClick={onAddClick}
          >
            {t("quotes.addCategory")}
          </Button>
        )}

        <div className="quote-costing-list">{renderCategories()}</div>
        {displayCostingTotals && (
          <>
            <div className="divider" />
            {totalEstimatedCost === undefined ? (
              <Totals
                gst={gst}
                total={total}
                subtotal={subtotal}
                grandTotal={grandTotal}
                markup={markup}
                markupPercent={markupPercent}
              />
            ) : (
              <JobCostTotals
                totalEstimatedCost={totalEstimatedCost}
                totalRealCost={totalRealCost}
                totalMarkup={totalMarkup}
                jobId={jobId}
              />
            )}
          </>
        )}
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end">
        {displayNavButtons && (
          <>
            <Button
              className="button info mr-2"
              variant="secondary"
              onClick={onBackClick}
            >
              {t("common.back")}
            </Button>
            <Button className="button success" onClick={onNextClick}>
              {t("common.next")}
            </Button>
          </>
        )}
        {addButtonPlacement === "bottom" && (
          <Button
            className="button success large"
            onClick={onAddClick}
            disabled={disabled}
          >
            <Icon name="add" />
            {t("common.createNew")}
          </Button>
        )}
      </DashboardCardFooter>
    </DashboardCard>
  );
};

export default CostingCategories;
