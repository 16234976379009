import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { compact, map } from "lodash";
import CategorySelectorCard from "../../category-select-card";
import EntryGroup from "./EntryGroup";
import CategorySelectItem from "../../category-select-card/CategorySelectItem";
import "./styles.scss";
import {
  QuoteRequest,
  QuoteRequestSubcontractor,
} from "../../../graphql/types/models/quote-request";
import { DATE_FORMAT_DATE_STRING } from "../../../constants/dates";
import { formatQuoteNumber } from "../../../utils/text";
import { getClientFeedbackBadge, getStatusBadge } from "../utils";

type QuoteRequestListProps = {
  quoteRequests?: QuoteRequest[];
  selectedSubcontractorId?: string;
  onSelect?: (
    quoteRequest: QuoteRequest,
    subcontractor: QuoteRequestSubcontractor
  ) => void;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
};

const QuoteRequestList: React.FC<QuoteRequestListProps> = (props) => {
  const {
    quoteRequests,
    selectedSubcontractorId,
    onSelect,
    title,
    placeholder,
    disabled = false,
  } = props;
  const { t } = useTranslation();

  const handleSelectEntry = React.useCallback(
    (quoteRequest: QuoteRequest, subcontractor: QuoteRequestSubcontractor) => {
      onSelect?.(quoteRequest, subcontractor);
    },
    [onSelect, quoteRequests]
  );

  const renderGroupedItems = React.useCallback(() => {
    return map(quoteRequests, (quoteRequest) => {
      return (
        <EntryGroup
          key={quoteRequest._id}
          name={`${formatQuoteNumber(quoteRequest.quoteNumber, "QR")} - ${
            quoteRequest.name
          }`}
          isOpen={true}
        >
          {map(quoteRequest.subcontractors, (subcontractor) => (
            <CategorySelectItem
              key={subcontractor._id}
              selected={selectedSubcontractorId === subcontractor._id}
              item={{
                id: subcontractor._id,
                label: subcontractor.supplier?.business_name || "",
                description: subcontractor.date_submitted
                  ? t("quoteRequest.receivedOnDate", {
                      date: moment(subcontractor.date_submitted).format(
                        DATE_FORMAT_DATE_STRING
                      ),
                    })
                  : "",
                rightLabel: subcontractor.date_submitted
                  ? t("quoteRequest.quotedCost", {
                      cost: subcontractor.items.reduce((total, item) => {
                        return (
                          total +
                          item.cost *
                            (quoteRequest.items?.find(
                              (i) => i._id === item.quoteItemId
                            )?.quantity || 0)
                        );
                      }, 0),
                    })
                  : "",
                rightBadge: compact([
                  getClientFeedbackBadge(subcontractor.client_feedback),
                  getStatusBadge(subcontractor.status),
                ]),
              }}
              onSelect={() => handleSelectEntry(quoteRequest, subcontractor)}
            />
          ))}
        </EntryGroup>
      );
    });
  }, [quoteRequests, selectedSubcontractorId]);

  return (
    <>
      <CategorySelectorCard
        title={title || ""}
        placeholder={placeholder || t("quoteRequest.emptyPlaceholder")}
        disabled={disabled}
        hideSelectAllButton
        hideAddButton
        filter={{
          id: undefined,
          selectAll: undefined,
          label: undefined,
          icon: undefined,
        }}
      >
        {renderGroupedItems()}
      </CategorySelectorCard>
    </>
  );
};

export default QuoteRequestList;
