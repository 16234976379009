import React from "react";
import "./styles.scss";
import filesize from "filesize";
import Icon from "../../icons/Icon";

type MessageFilePreview = {
  size: number;
  name: string;
  download_url?: string;
};

const MessageFilePreview: React.FC<MessageFilePreview> = (props) => {
  const { name, size, download_url } = props;

  return (
    <div className="message-file">
      <div className="message-file-info">
        <div className="message-file-icon">
          <Icon name="insert_drive_file" outlined />
        </div>

        <div>
          <div className="field-text message-file-name">{name}</div>
          <div className="field-text message-file-size">
            {filesize(size || 0)}
          </div>
        </div>
      </div>

      <div className="message-file-download">
        <a href={download_url} target="_blank" rel="noopener noreferrer">
          <Icon name="get_app" className="download-icon" />
        </a>
      </div>
    </div>
  );
};

export default MessageFilePreview;
