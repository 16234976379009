import { QuoteMeasurement } from "./salesQuote";
import { Rounding } from "../components/calculator/utils";
import { DigitalSignature } from "../components/accept-signature-dialog/utils";

export enum VariationStatuses {
  DRAFT = "DRAFT",
  CANCELLED = "CANCELLED",
  AWAITING_CLIENT_APPROVAL = "AWAITING_CLIENT_APPROVAL",
  APPROVED_BY_BUILDER = "APPROVED_BY_BUILDER",
  APPROVED_BY_CLIENT = "APPROVED_BY_CLIENT",
}

export type Variation = {
  id: number | string;
  name: string;
  contactName: string;
  finishedAt: Date;
  notes: string;
  subtotal: number;
  status: VariationStatuses;
  gst: number;
  total: number;
};

export type VariationModalListItem = VariationCostItem & {
  variation: {
    _id: string;
    variationNumber: number;
    date: string;
    status: string;
  };
  jobId: string;
};

export type VariationContact = {
  _id: string;
  first_name: string;
  last_name: string;
};

export type VariationCostItem = {
  name: string;
  UOM: QuoteMeasurement;
  cost: number;
  quantity: number;
  raw_quantity: string;
  wastage: number;
  rounding: Rounding;
  margin_amount: number;
  hasGST: boolean;
  __typename: string;
};

export type VariationListItem = {
  _id: string;
  name: string;
  status: VariationStatuses;
  variationNumber: number;
  progressClaimed: boolean;
  date: string;
  subTotal: number;
  GST: number;
  total: number;
};

export type VariationReal = {
  _id: string;
  name: string;
  variationNumber: number;
  status: VariationStatuses;
  total: number;
  GST: number;
  note: string;
  subTotal: number;
  date: string;
  items: VariationCostItem[];
  job?: {
    _id: string;
  };
  __typename: string;
  contact: VariationContact | null;
  progressClaimed: boolean;
  clientSignature?: DigitalSignature;
  builderSignature?: DigitalSignature;
};

export type VariationCostItemPayload = {
  name: string;
  wastage: number;
  rounding?: Rounding;
  quantity: number | null;
  raw_quantity: string;
  margin_amount: number | string | null;
  cost: number | null;
  cost_inc?: number | null;
  hasGST: boolean;
  gstFree?: boolean;
  UOM: QuoteMeasurement | null | string;
  costingItemId?: string;
  costingCategoryName?: string | null;
};

export type VariationPayload = {
  _id?: string;
  contact: string;
  name: string;
  date: string;
  note: string;
  status: VariationStatuses | null;
  items: VariationCostItemPayload[];
};

export type VariationPreview = {
  _id: string;
  pdf: string;
};

export type UnClaimedVariation = {
  _id: string;
  variationNumber: number;
  name: string;
  date: string;
  subTotal: number;
  GST: number;
  total: number;
};

export type VariationShortInfo = {
  _id: string;
  status?: VariationStatuses;
  clientSignature?: DigitalSignature;
};
