import { TFunction } from "i18next";
import { chain, map } from "lodash";
import {
  AddTakeOffToCostingPayload,
  QuoteMeasurement,
  TakeOffCostingItemPayload,
} from "../../../../models/salesQuote";
import { TakeOffMeasurement } from "../../../../models/take-off";

import { calcItemTotal } from "../../../../utils/calculations";
import { GenericRecord } from "../../../../utils/types/options";
import { Rounding } from "../../../calculator/utils";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";

export function addToCostingFields(
  t: TFunction,
  costingCategoryList: GenericRecord[] | undefined,
  handleCostingCreate: (costing: string) => void
): GenericFormFields<AddTakeOffToCostingPayload> {
  return {
    items: {
      tableTitle: t("costing.costingItems"),
      isCompact: true,
      row: [
        {
          width: "300px",
          type: "text",
          label: t("common.description"),
          placeholder: t("placeholders.description"),
          valueKey: "name",
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          width: "125px",
          maxWidth: "125px",
          type: "text",
          label: t("costing.quantity"),
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          disallowNegativeNumber: true,
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          width: "125px",
          maxWidth: "125px",
          type: "select",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          autocompleteProps: {
            options: chain(TakeOffMeasurement)
              .filter((m) => m !== TakeOffMeasurement.MILLIMETRE)
              .map((m) => ({ value: m, label: m }))
              .value(),
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          width: "140px",
          maxWidth: "140px",
          type: "text",
          label: t("costing.cost"),
          placeholder: t("placeholders.costValue"),
          valueKey: "cost",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          width: "80px",
          maxWidth: "80px",
          type: "text",
          label: t("costing.markup"),
          placeholder: t("placeholders.marginValue"),
          valueKey: "margin_amount",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          type: "creatable-select",
          label: t("costing.costingCategory"),
          placeholder: t("placeholders.category"),
          valueKey: "category",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: costingCategoryList,
            onCreateOption: handleCostingCreate,
            isValidNewOption: (v: string) => v.length > 0,
            isClearable: true,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
      ],
      dynamicFields: [
        {
          title: t("costing.totalEx"),
          formatValue: (values: TakeOffCostingItemPayload) => {
            const amount = calcItemTotal(
              {
                margin_amount: values.margin_amount || 0,
                cost: values.cost || 0,
                quantity: values.quantity || 0,
              },
              0
            );

            return t("common.currency", { amount });
          },
        },
      ],
    },
  };
}
