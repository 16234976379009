import { TFunction } from "i18next";
import * as yup from "yup";
import { AddTakeOffToCostingPayload } from "../../../../models/salesQuote";

import { ValidationMessagesUtils } from "../../../../utils/yup";

export default function addToCostingSchema(t: TFunction) {
  return yup.object().shape({
    items: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .string()
            .required(
              ValidationMessagesUtils.createRequiredMessage(t, "common.name")
            ),
          quantity: yup
            .string()
            .required(
              ValidationMessagesUtils.createRequiredMessage(
                t,
                "common.quantity"
              )
            ),
          UOM: yup
            .string()
            .required(
              ValidationMessagesUtils.createRequiredMessage(t, "costing.uom")
            ),
          cost: yup
            .number()
            .required(
              ValidationMessagesUtils.createRequiredMessage(t, "costing.cost")
            ),
          category: yup
            .string()
            .required(
              ValidationMessagesUtils.createRequiredMessage(
                t,
                "costing.costingCategory"
              )
            ),
        })
      )
      .required(),
  });
}
