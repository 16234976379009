import React from "react";
import AutocompleteInput from "../../generic-form/inputs/autocomplete";
import { useTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import TeammateItem from "./TeammateItem";
import { TeamMember, UserRoles } from "../../../models/team-member";
import { ContactNameListItem } from "../../../models/client";
import Loader from "../../loader";

type TeammateHeaderDropdownProps = {
  jobTeammates?: TeamMember[];
  contacts?: ContactNameListItem[];
  loading: boolean;
  addTeammate: (memberId: string) => void;
  removeTeammate: (memberId: string) => void;
  openCreateModal: () => void;
  isReadonly?: boolean;
};

const TeammateHeaderDropdown: React.FC<TeammateHeaderDropdownProps> = (
  props
) => {
  const {
    jobTeammates,
    contacts,
    loading,
    addTeammate,
    removeTeammate,
    openCreateModal,
    isReadonly = false,
  } = props;

  const { t } = useTranslation();

  const handleAddTeammate = React.useCallback(
    (value: string) => {
      addTeammate(value);
    },
    [addTeammate]
  );

  const contactOptions = React.useMemo(() => {
    return map(contacts, (contact) => {
      const fullName = contact
        ? `${contact?.first_name} ${contact?.last_name}`
        : "";

      return {
        value: contact._id,
        label: fullName,
      };
    });
  }, [contacts]);

  const renderLoader = () => {
    if (!loading) {
      return null;
    }

    return (
      <div className="teammate-loader">
        <Loader />
      </div>
    );
  };

  const renderClients = () => {
    if (!loading && isEmpty(jobTeammates)) {
      return (
        <div className="field-text p-2 text-truncate teammate-empty-message">
          {t("headerTeammate.noTeammateAccess")}
        </div>
      );
    }

    return map(jobTeammates, (jobTeammate) => {
      const {
        email,
        _id,
        first_name,
        last_name,
        profileImage,
        role,
      } = jobTeammate;

      const isBuilderAdmin = role === UserRoles.builderadmin;
      const fullName = `${first_name} ${last_name}`;

      return (
        <TeammateItem
          fullName={fullName}
          email={email}
          teammateId={_id}
          key={_id}
          removeTeammate={removeTeammate}
          isReadonly={isReadonly || isBuilderAdmin}
          avatar_url={profileImage?.url}
        />
      );
    });
  };

  const append = React.useMemo(() => {
    return { onClick: openCreateModal, icon: "add" };
  }, [openCreateModal]);

  return (
    <div className="teammate-header-dropdown">
      {renderLoader()}
      {!isReadonly && (
        <div className="teammate-selector">
          <AutocompleteInput
            name="teammate"
            options={contactOptions}
            placeholder={t("placeholders.chooseTeammate")}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            append={append}
            onChange={handleAddTeammate}
            value={contactOptions[0]}
          />
        </div>
      )}
      <div className="teammate-list">{renderClients()}</div>
    </div>
  );
};

export default TeammateHeaderDropdown;
