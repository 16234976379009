import classNames from "classnames";
import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import * as uuid from "uuid";
import { isEmpty, filter } from "lodash";
import { useMutation, useQuery } from "@apollo/client";

import "./styles.scss";
import DashboardCard from "../../../dashboard/card";
import DashboardCardBody from "../../../dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../../dashboard/card/DashboardCardFooter";
import DashboardCardHeader from "../../../dashboard/card/DashboardCardHeader";
import PlansDragzone from "./PlansDragzone";
import PlansTable, { PlanFile } from "./PlansTable";

import { CREATE_UPDATE_DOCUMENT } from "../../../../graphql/queries/documents/mutations";
import { GET_SYSTEM_SUB_FOLDER } from "../../../../graphql/queries/documents/queries";
import { CreateUpdateDocumentResponse, GetSystemSubFolderResponse } from "../../../../graphql/types/models/documents";
import {
  EnumDocumentAccessRole,
  SystemFolderType,
  SystemSubFolderType,
} from "../../../../models/documents";
import { uploadFiles } from "../../../../utils/files";

type FileItems = PlanFile & {
  file: File;
};

type PlansUploadCardProps = {
  onPlanFileUpload: (plan: PlanFile[]) => void;
  salesQuoteId?: string;
  isDisabled: boolean;
};

const readFile = (file: File): Promise<ArrayBuffer> => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = () => reject();
    reader.onerror = () => reject();
    reader.onload = () => {
      const binaryStr = reader.result as ArrayBuffer;
      resolve(binaryStr);
    };
    reader.readAsArrayBuffer(file);
  });
};

const PlansUploadCard: React.FC<PlansUploadCardProps> = ({
  onPlanFileUpload,
  salesQuoteId,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [uploadedFiles, setUploadedFiles] = React.useState<FileItems[]>([]);

  const { data: systemFolderResponse } = useQuery<GetSystemSubFolderResponse>(
    GET_SYSTEM_SUB_FOLDER,
    {
      variables: {
        refId: salesQuoteId,
        systemType: SystemFolderType.SQ,
        subType: SystemSubFolderType.ESTIMATION_PLANS,
      },
    }
  );

  const [addFile] = useMutation<CreateUpdateDocumentResponse>(
    CREATE_UPDATE_DOCUMENT,
    {
      onCompleted: async (data) => {
        if (
          uploadedFiles &&
          uploadedFiles.length > 0 &&
          data.createUpdateDocuments.length > 0
        ) {
          await uploadFiles(
            data.createUpdateDocuments.map((document) => ({
              name: document.name,
              upload_url: document.upload_url,
            })),
            uploadedFiles.map((fileItem) => fileItem.file)
          );
        }
      },
    }
  );

  React.useEffect(() => {}, []);

  const handleFilesUpload = React.useCallback(
    (files: File[]) => {
      async function uploadFiles() {
        const result: FileItems[] = [];
        for (const file of files) {
          result.push({
            id: uuid.v4(),
            name: file.name,
            size: file.size,
            lastModified: new Date(file.lastModified),
            type: file.type,
            content: await readFile(file),
            file: file,
          });
        }

        setUploadedFiles([...uploadedFiles, ...result]);
      }

      uploadFiles();
    },
    [uploadedFiles]
  );

  const handleFileDelete = React.useCallback(
    (file: PlanFile) => {
      setUploadedFiles(
        filter(uploadedFiles, (f: PlanFile) => f.id !== file.id)
      );
    },
    [uploadedFiles]
  );

  const handleNextClick = React.useCallback(async () => {
    if (systemFolderResponse?.getSystemSubFolder) {
      const folderId = systemFolderResponse?.getSystemSubFolder._id;
      await addFile({
        variables: {
          documents: uploadedFiles.map((file) => ({
            folderId: folderId,
            name: file.name,
            size: file.size,
            type: file.type,
            accessRole: EnumDocumentAccessRole.ADMIN,
          })),
        },
      });
    }

    onPlanFileUpload(uploadedFiles);
  }, [uploadedFiles, systemFolderResponse, addFile]);

  return (
    <DashboardCard
      className={classNames("plans-upload-card", { inactive: isDisabled })}
    >
      <DashboardCardHeader>{t("plansSection.uploadPlans")}</DashboardCardHeader>
      <DashboardCardBody>
        <PlansTable files={uploadedFiles} onFileDelete={handleFileDelete} />
        <PlansDragzone onFilesUpload={handleFilesUpload} />
      </DashboardCardBody>
      <DashboardCardFooter className="d-flex justify-content-end">
        <Button
          onClick={handleNextClick}
          className={classNames("button success", {
            disabled: isEmpty(uploadedFiles),
          })}
        >
          {t("common.next")}
        </Button>
      </DashboardCardFooter>
    </DashboardCard>
  );
};

export default PlansUploadCard;
