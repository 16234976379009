import React, { forwardRef, useImperativeHandle } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import classNames from "classnames";
import { Formik, FormikHelpers } from "formik";
import GenericFormBody, {
  GenericFormFields,
} from "../generic-form/GenericFormBody";
import { createFields } from "./utils";
import acceptSignatureSchema from "./acceptSignature.schema";
import { MediaInput } from "../../graphql/types/inputs/media";
import "./styles.scss";

type AcceptSignatureDialogProps = {
  show?: boolean;
  className?: string;
  title?: string;
  submitButtontText?: string;
  onClose?: () => void;
  onSubmit: (signature: DigitalSignatureInput, file: File) => void;
  disabled?: boolean;
  isLoading?: boolean;
};

export type DigitalSignatureInput = {
  name: string;
  file: MediaInput;
};

export type AcceptSignatureDialogRef = {
  show: (show: boolean, submitCallback?: () => void) => void;
};

const AcceptSignatureDialog: React.FC<AcceptSignatureDialogProps> = (
  props,
  ref
) => {
  const {
    show,
    className,
    title,
    onClose,
    onSubmit,
    disabled,
    submitButtontText,
    isLoading,
  } = props;
  const { t } = useTranslation();

  const [isEmptySignatureField, setIsEmptySignatureField] = React.useState<
    boolean
  >(false);
  const [showModal, setShowModal] = React.useState(false);
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<{ name: string }>
  >({});

  let sigRef = React.useRef<SignatureCanvas>(null);

  const clear = () => {
    sigRef.current?.clear();
  };

  useImperativeHandle(ref, () => ({
    show: (show: boolean, submitCallback?: () => void) => {
      setShowModal(show);
    },
  }));

  React.useEffect(() => {
    setShowModal(!!show);
  }, [show]);

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      setShowModal(false);
    }
  }, [onClose]);

  const handleHide = React.useCallback(() => {
    onClose?.();
  }, [onClose]);

  React.useEffect(() => {
    setFormFields(createFields(t));
  }, [t]);

  const handleOnSubmit = React.useCallback(
    (data: { name: string }, helpers?: FormikHelpers<{ name: string }>) => {
      if (sigRef.current?.isEmpty()) {
        return setIsEmptySignatureField(true);
      }
      sigRef.current?.getCanvas().toBlob((file) => {
        if (!file) return;
        const signImage = new File([file], "signature.png", {
          type: "image/png",
        });
        return onSubmit(
          {
            name: data.name,
            file: {
              type: signImage.type,
              size: signImage.size,
              name: signImage.name,
            },
          },
          signImage
        );
      });
    },
    [onSubmit]
  );

  const validationSchema = React.useMemo(() => acceptSignatureSchema(t), [t]);

  return (
    <Modal
      show={showModal}
      className={classNames(className, "accept-signature-dialog")}
      onHide={handleHide}
      role="dialog"
    >
      <Modal.Header className="header">
        <Modal.Title className="title">{title}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(
          values: { name: string },
          formikHelpers: FormikHelpers<{ name: string }>
        ): void | Promise<any> => {
          return handleOnSubmit(values, formikHelpers);
        }}
        initialValues={{
          name: "",
        }}
      >
        {(props) => {
          return (
            <Form
              noValidate
              onSubmit={props.handleSubmit}
              onReset={props.handleReset}
            >
              <Modal.Body className="body flex-column">
                <Row className="signature-container flex-column">
                  <Row>
                    <Col className="signature-title">
                      {t("placeholders.signBelow")}
                    </Col>
                  </Row>
                  <div className="canvas-container">
                    <SignaturePad
                      ref={sigRef}
                      onBegin={() => setIsEmptySignatureField(false)}
                      canvasProps={{
                        width: 685,
                        height: 200,
                        className: classNames("sigCanvas", {
                          "sigCanvas-error": isEmptySignatureField,
                        }),
                      }}
                    />
                  </div>
                </Row>

                <Row>
                  <Col xs={12}>
                    {<GenericFormBody fields={formFields} {...props} />}
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="footer">
                <div>
                  <Button
                    variant="secondary"
                    className="button info"
                    onClick={clear}
                    disabled={disabled}
                  >
                    {t("common.clear")}
                  </Button>
                </div>
                <div className="d-flex">
                  <Button
                    variant="secondary"
                    className="button info"
                    onClick={handleClose}
                    disabled={disabled}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    className="button success"
                    disabled={isLoading}
                    type="submit"
                  >
                    <span> {submitButtontText || t("common.select")}</span>
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default forwardRef(AcceptSignatureDialog);
