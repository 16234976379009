import { TFunction } from "i18next";
import { map } from "lodash";
import { CostingCategoryListItem } from "../../../models/salesQuote";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const selectCategoryFields = (
  t: TFunction,
  costingCategories: CostingCategoryListItem[]
): GenericFormFields<{ templateId: string }> => {
  const categories = map(costingCategories, (template) => ({
    value: template._id,
    label: template.name,
  }));
  return {
    categoryId: {
      type: "default-select",
      label: t("quotes.costingCategory"),
      placeholder: t("quotes.costingCategory"),
      valueKey: "categoryId",
      selectProps: {
        options: categories,
      },
    },
  };
};
