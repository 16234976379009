import { gql } from "@apollo/client";
import { AFFILIATE_FRAGMENT } from "./fragments";

export const WB_APROVE_AFFILIATE = gql`
  mutation($affiliateId: ID!) {
    affiliates: wbAdminApproveAffiliate(affiliateId: $affiliateId) {
      ...AffiliateFragment
    }
  }
  ${AFFILIATE_FRAGMENT}
`;

export const WB_REJECT_AFFILIATE = gql`
  mutation($affiliateId: ID!) {
    affiliates: wbAdminRejectAffiliate(affiliateId: $affiliateId) {
      ...AffiliateFragment
    }
  }
  ${AFFILIATE_FRAGMENT}
`;
