export enum EstimationTemplateType {
  System = "System",
  Custom = "Custom",
}

export type EstimationTemplate = {
  _id: string;
  name: string;
  type: EstimationTemplateType;
  createdAt: string;
  updatedAt: string;
};

export type EstimationTemplateListResponse = {
  listBuildingTemplates: EstimationTemplate[];
};

export type EstimationTemplateDeletePayload = {
  templateId: string;
};
