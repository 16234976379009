import { gql } from "@apollo/client";
import { WB_USER_LIST_FRAGMENT } from "./fragments";

export const WB_LIST_USERS = gql`
  query WbAdminListUsers {
    wbAdminListUsers {
      ...WbUserListItemRequest
    }
  }
  ${WB_USER_LIST_FRAGMENT}
`;
