import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { map, padStart, range } from "lodash";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/entry.webpack";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import SendModal from "../../../components/modals/send-modal";
import { SendEmailForm } from "../../../models/email";
import QuoteReportToolbar from "../../../components/quotes/quote-report-toolbar";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { ContactListResponse } from "../../../graphql/types/models/client";
import { LIST_CONTACTS } from "../../../graphql/queries/client/queries";
import { SEND_SALES_QUOTE } from "../../../graphql/queries/quote/mutations";
import {
  GetSalesQuoteResponse,
  SendSalesQuoteResponse,
} from "../../../graphql/types/models/quote";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { GET_SALES_QUOTE } from "../../../graphql/queries/quote/queries";
import { printBase64Pdf } from "../../../utils/pdf";
import QuoteCustomiseModal, {
  QuoteCustomiseModalRef,
} from "../../../components/quotes/quote-customise-modal";
import { notify } from "../../../components/notification";
import { PRINT_SALES_QUOTE } from "../../../graphql/queries/quote-designer/queries";
import { PrintSalesQuoteTemplateResponse } from "../../../graphql/types/models/quote-designer";
import CreateJobModal from "../../../components/job/create-job-modal";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import "./styles.scss";

type QuotePreviewProps = DashboardContextValue;

const SCALE_STEP = 0.2;
const MIN_SCALE = 0.2;
const MAX_SCALE = 2.6;

export const QuotePreview: React.FC<QuotePreviewProps> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();

  const salesQuoteId = navigationContext?.quote?._id;
  const businessName = navigationContext?.quote?.businessName;
  const isLocked = !!navigationContext?.quote?.isLocked;
  const quoteNumber = padStart(
    navigationContext?.quote?.quoteNumber.toString(),
    5,
    "0"
  );

  const [scale, setScale] = useState<number>(1.4);
  const [width, setWidth] = useState<number>();
  const [pages, setPages] = useState<number[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const customiseRef = useRef<QuoteCustomiseModalRef>(null);
  const createJobRef = React.useRef<ModalDisplayRef>(null);
  const sendRef = React.useRef<ModalDisplayRef>(null);
  const confirmRef = React.useRef<ConfirmDialogRef>(null);

  const { data: salesQuoteData, loading: salesQuoteLoading } = useQuery<
    GetSalesQuoteResponse
  >(GET_SALES_QUOTE, {
    fetchPolicy: "cache-and-network",
    variables: { salesQuoteId },
  });

  const { data: contactsData } = useQuery<ContactListResponse>(LIST_CONTACTS);

  const [
    getPDFPreview,
    { data: quotePreview, refetch: refetchPDFPreview, loading: pdfLoading },
  ] = useLazyQuery<PrintSalesQuoteTemplateResponse>(PRINT_SALES_QUOTE, {
    fetchPolicy: "cache-and-network",
    variables: {
      salesQuoteId,
    },
  });

  React.useEffect(() => {
    getPDFPreview();
  }, []);

  const [sendQuote] = useMutation<SendSalesQuoteResponse>(SEND_SALES_QUOTE, {
    onCompleted: () => {
      sendRef.current?.show(false);
      notify({
        content: t("quotes.success.sendQuote"),
        title: t("quotes.sendQuote"),
      });
    },
    onError: () => {
      notify({
        error: true,
        content: t("quotes.errors.sendQuote"),
        title: t("quotes.sendQuote"),
      });
    },
  });

  const salesQuote = React.useMemo(() => {
    return salesQuoteData?.getSalesQuote;
  }, [salesQuoteData]);

  const handleSendModal = React.useCallback(() => {
    sendRef.current?.show(true);
  }, [sendRef]);

  const handleSend = React.useCallback(
    (message: SendEmailForm) => {
      return sendQuote({
        variables: {
          message: {
            subject: message.title,
            to: message.to,
            message: message.message,
            remoteAttachmentId: message.remoteAttachmentId,
          },
          salesQuoteId,
        },
      });
    },
    [sendQuote, salesQuoteId]
  );

  const handleZoomIn = React.useCallback(() => {
    const newScale = scale + SCALE_STEP;

    if (newScale > MAX_SCALE) {
      return;
    }

    setScale(newScale);
    setWidth(undefined);
  }, [scale]);

  const handleExpand = React.useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    if (!width) {
      setWidth(containerRef.current?.clientWidth);
      setScale(1);
    } else {
      setWidth(undefined);
    }
  }, [width]);

  const handleZoomOut = React.useCallback(() => {
    const newScale = scale - SCALE_STEP;

    if (newScale < MIN_SCALE) {
      return;
    }

    setWidth(undefined);
    setScale(newScale);
  }, [scale]);

  const pdfDoc = React.useMemo(() => {
    if (!quotePreview?.printSalesQuoteTemplate.pdf) {
      return null;
    }

    return `data:application/pdf;base64,${quotePreview?.printSalesQuoteTemplate.pdf}`;
  }, [quotePreview]);

  const handlePrint = React.useCallback(() => {
    if (!quotePreview?.printSalesQuoteTemplate.pdf) {
      return;
    }

    printBase64Pdf(quotePreview?.printSalesQuoteTemplate.pdf || "");
  }, [quotePreview]);

  const onDocumentLoadSuccess = React.useCallback(
    ({ numPages }) => {
      setPages(range(1, numPages + 1));
    },
    [setPages]
  );

  const handleCustomise = React.useCallback(() => {
    customiseRef.current?.show(true);
  }, []);

  const handleConvertToJob = React.useCallback(() => {
    confirmRef.current?.show(true);
  }, [confirmRef]);

  const handleConvertToJobConfirm = React.useCallback(() => {
    createJobRef.current?.show(true);
  }, [createJobRef]);

  const renderPreview = React.useCallback(() => {
    if (pdfLoading || !pdfDoc) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner
            className="spinner-border-accent"
            animation="border"
            style={{ width: 80, height: 80 }}
          />
        </div>
      );
    }

    return (
      <div className="quote-report" ref={containerRef}>
        <Document file={pdfDoc} onLoadSuccess={onDocumentLoadSuccess}>
          {map(pages, (page, index) => (
            <Page
              key={`page-${index}`}
              pageNumber={page}
              scale={scale}
              className="pdf-viewer"
              width={width}
            />
          ))}
        </Document>
      </div>
    );
  }, [scale, pdfLoading, pdfDoc, pages, onDocumentLoadSuccess, containerRef]);

  const previewFileName = `${businessName} - Q-${quoteNumber}.pdf`;

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quotesSection.quotePreview")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.PREVIEW} />
      {contactsData?.listContacts && (
        <SendModal
          ref={sendRef}
          fileName={previewFileName}
          onFileClick={handlePrint}
          title={t("quotes.sendEmail")}
          contacts={contactsData?.listContacts}
          contact={salesQuote?.contact}
          onSubmit={handleSend}
          submitText={t("common.send")}
        />
      )}
      {salesQuote && (
        <CreateJobModal ref={createJobRef} salesQuote={salesQuote} />
      )}

      <ConfirmDialog
        ref={confirmRef}
        title={t("quotes.convertToJob")}
        onSubmit={handleConvertToJobConfirm}
        confirm={t("common.continue")}
      >
        <span className="field-text">{t("quotes.convertToJobMessage")}</span>
      </ConfirmDialog>

      <QuoteReportToolbar
        onPrint={handlePrint}
        onModalToggle={handleSendModal}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onExpand={handleExpand}
        onCustomise={handleCustomise}
        onConvertToJob={!isLocked ? handleConvertToJob : undefined}
      />

      {renderPreview()}
      {salesQuote && !pdfLoading && (
        <QuoteCustomiseModal
          ref={customiseRef}
          quote={salesQuote}
          onRefresh={() =>
            refetchPDFPreview &&
            refetchPDFPreview({
              fetchPolicy: "network-only",
            })
          }
        />
      )}
    </Container>
  );
};

export default withDashboardContext(QuotePreview);
