import { padStart, compact } from "lodash";

type ObjectWithNames = {
  first_name?: string | null;
  last_name?: string | null;
};
type ObjectWithAddress = {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
};

export const getFullName = (user?: ObjectWithNames | null) => {
  return compact([user?.first_name, user?.last_name]).join(" ");
};
export const getFullAddress = (
  user?: ObjectWithAddress | null,
  delimiter?: string
) => {
  return compact([
    user?.address,
    user?.address2,
    user?.city,
    user?.state,
    user?.postcode,
  ]).join(delimiter || ", ");
};

export const formatQuoteNumber = (
  value: number | string,
  prefix: string = "QR"
) => `${prefix}-${padStart(value?.toString(), 5, "0")}`;
