import { TFunction } from "i18next";
import { map } from "lodash";
import { CreateChecklistItemPayload } from "../../../models/task";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { TeamMember } from "../../../models/team-member";

export const createTaskField = (
  t: TFunction,
  team: TeamMember[]
): GenericFormFields<CreateChecklistItemPayload> => {
  const teamOptions = map(team, (member) => ({
    value: member._id,
    label: [member.first_name, member.last_name].join(" "),
  }));

  const fields: GenericFormFields<CreateChecklistItemPayload> = {
    task: {
      type: "text",
      label: t("tasks.task"),
      placeholder: t("tasks.task"),
      valueKey: "task",
      controlType: "textarea",
      inputProps: {
        required: true,
      },
    },
    dueDate: {
      type: "date-picker",
      label: t("tasks.dueDate"),
      placeholder: t("tasks.dueDate"),
      valueKey: "dueDate",
    },
    assignedTo: {
      type: "select",
      label: t("common.assignTo"),
      placeholder: t("schedule.select"),
      valueKey: "assignedTo",
      autocompleteProps: {
        isClearable: true,
        options: teamOptions,
      },
    },
  };

  return fields;
};
