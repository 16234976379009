import React, { useState } from "react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import CreateEntityModal from "../../modals/create-entity";
import { CreateChecklistItemPayload } from "../../../models/task";
import { createTaskField } from "./utils";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { useJobsQuery } from "../../../hooks/queries/useJobsQuery";
import { useCreateAnother } from "../../../hooks/useCreateAnother";
import createChecklistItemSchema from "./CreateChecklistItem.schema";
import { BaseChecklist } from "../../../containers/tasks/checklist-category";

type CreateUpdateTaskModalProps<SChecklistItem> = {
  data: SChecklistItem | null;
  show: boolean;
  onSubmit: (data: CreateChecklistItemPayload, createAnother: boolean) => void;
  onUpdate?: (data: CreateChecklistItemPayload, createAnother: boolean) => void;
  onClose: () => void;
};

const CreateUpdateChecklistItemModal = <SChecklistItem extends BaseChecklist>(
  props: CreateUpdateTaskModalProps<SChecklistItem>
) => {
  const { show, onSubmit, onClose, data, onUpdate } = props;
  const { t } = useTranslation();
  const { jobs } = useJobsQuery();

  const {
    shouldCreateAnother,
    renderCreateAnother,
    setCreateAnother,
  } = useCreateAnother(t("schedule.createAnotherTask"));

  const [fields, setFields] = useState<
    GenericFormFields<CreateChecklistItemPayload>
  >({});
  const [initData, setInitData] = React.useState<CreateChecklistItemPayload>({
    task: "",
    assignedTo: "",
    dueDate: "",
  });

  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  React.useEffect(() => {
    setInitData({ task: "", assignedTo: "", dueDate: "" });
    setFields(createTaskField(t, teamData?.listMembers || []));
  }, [t, teamData, jobs, show]);

  const handleSubmit = React.useCallback(
    (data: CreateChecklistItemPayload, helpers?: FormikHelpers<any>) => {
      onSubmit && onSubmit(data, shouldCreateAnother);
      helpers?.resetForm();
      if (shouldCreateAnother) {
        setInitData({ ...data, task: "" });
      }
    },
    [shouldCreateAnother, onSubmit]
  );

  const handleUpdate = React.useCallback(
    (inputData: CreateChecklistItemPayload, helpers?: FormikHelpers<any>) => {
      onUpdate &&
        onUpdate({ ...inputData, _id: data?._id }, shouldCreateAnother);
      helpers?.resetForm();

      if (shouldCreateAnother) setInitData({ task: "" });
    },
    [shouldCreateAnother, onUpdate, data]
  );

  const handleClose = React.useCallback(() => {
    onClose();
    setCreateAnother(false);
  }, []);

  return (
    <CreateEntityModal<CreateChecklistItemPayload>
      validationSchema={createChecklistItemSchema()}
      title={data ? t("tasks.updateTask") : t("tasks.createNewChecklistItem")}
      data={
        data
          ? {
              task: data.task,
              assignedTo: data?.assignedTo?._id || "",
              dueDate: data?.dueDate || "",
            }
          : initData
      }
      show={show}
      fields={fields}
      onSubmit={data ? handleUpdate : handleSubmit}
      onClose={handleClose}
      leftFooterRenderer={renderCreateAnother}
      submitText={data ? t("common.update") : t("common.create")}
    />
  );
};

export default CreateUpdateChecklistItemModal;
