import React from "react";
import { map, chain } from "lodash";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";

import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../components/dashboard/table-card";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import {
  TableCardData,
  TableRowActionData,
  TableCardAction,
} from "../../../components/dashboard/table-card/utils";
import { notify } from "../../../components/notification";
import { LIST_ASSEMBLIES } from "../../../graphql/queries/assemblies/queries";
import {
  DELETE_ASSEMBLY,
  CREATE_UPDATE_ASSEMBLY,
} from "../../../graphql/queries/assemblies/mutations";
import {
  handleAddAssembly,
  handleDeleteAssembly,
  handleUpdateAssembly,
} from "../../../graphql/queries/assemblies/utils";
import {
  ListAssembliesResponse,
  DeleteAssemblyResponse,
  CreateUpdateAssemblyResponse,
} from "../../../graphql/types/models/assembly";
import {
  AssemblyTableData,
  AssemblyCreateUpdatePayload,
  AssemblyListItem,
} from "../../../models/assembly";
import "./styles.scss";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import CreateUpdateAssemblyModal, {
  CreateUpdateAssemblyModalRef,
} from "../../../components/settings/assembly/create-update-modal";
import { UOMOption } from "../../../utils/types/options";
import { MEASUREMENT_OPTIONS } from "../../../utils/options";

const Assemblies: React.FC = () => {
  const { t } = useTranslation();

  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const assemblyModalRef = React.useRef<CreateUpdateAssemblyModalRef>(null);

  const [deleteTarget, setDeleteTarget] = React.useState("");
  const [updateTarget, setUpdateTarget] = React.useState("");

  const { data: assembliesResponse, loading: assembliesLoading } = useQuery<
    ListAssembliesResponse
  >(LIST_ASSEMBLIES, {
    fetchPolicy: "cache-and-network",
  });

  const [deleteAssembly, { loading: assemblyDeleting }] = useMutation<
    DeleteAssemblyResponse
  >(DELETE_ASSEMBLY);

  const [createUpdateAssembly] = useMutation<CreateUpdateAssemblyResponse>(
    CREATE_UPDATE_ASSEMBLY
  );

  const currentUoms = React.useMemo(
    () =>
      chain(assembliesResponse?.listAssemblies)
        .reduce((items: UOMOption[], next: AssemblyListItem) => {
          return items.concat(
            map(next.items, (item) => ({
              label: item.UOM,
              value: item.UOM,
            }))
          );
        }, MEASUREMENT_OPTIONS)
        .uniqBy("value")
        .value(),
    [assembliesResponse?.listAssemblies]
  );

  const openRemoveDialog = React.useCallback(
    (row?: AssemblyTableData) => {
      if (row) {
        setDeleteTarget(row._id);
        confirmRef?.current?.show(true);
      }
    },

    [confirmRef]
  );

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget("");
    confirmRef?.current?.show(false);
  }, [confirmRef]);

  const closeCreateUpdateModal = React.useCallback(() => {
    assemblyModalRef.current?.show(false);
    setUpdateTarget("");
  }, [assemblyModalRef, setUpdateTarget]);

  const openCreateModal = React.useCallback(
    () => assemblyModalRef.current?.show(true),
    [assemblyModalRef]
  );

  const openEditModal = React.useCallback(
    (row?: AssemblyTableData, isClone?: boolean) => {
      if (row && assembliesResponse?.listAssemblies) {
        const item = assembliesResponse?.listAssemblies.find(
          (assembly) => assembly._id === row._id
        );
        if (item) {
          if (!isClone) {
            setUpdateTarget(item._id);
          }
          assemblyModalRef.current?.show(true, item);
        }
      }
    },
    [assemblyModalRef, assembliesResponse?.listAssemblies]
  );

  const handleRemoveAssembly = React.useCallback(() => {
    try {
      if (!deleteTarget) return;

      deleteAssembly({
        variables: {
          assemblyId: deleteTarget,
        },
        update: handleDeleteAssembly,
      });

      notify({
        title: t("assembly.deleteAssembly"),
        content: t("assembly.success.deleteAssembly"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("assembly.deleteAssembly"),
        content: t("assembly.errors.deleteAssembly"),
      });
    }
  }, [t, deleteTarget, deleteAssembly]);

  const hanleCreateAssembly = React.useCallback(
    async (data: AssemblyCreateUpdatePayload) => {
      try {
        if (!data) return;

        await createUpdateAssembly({
          variables: {
            assembly: {
              ...data,
              items: data.items.map((item) => ({
                ...item,
              })),
            },
          },
          update: handleAddAssembly,
        });

        notify({
          title: t("assembly.addAssembly"),
          content: t("assembly.success.addAssembly"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("assembly.addAssembly"),
          content: t("assembly.errors.addAssembly"),
        });
      }
      closeCreateUpdateModal();
    },
    [t, createUpdateAssembly, closeCreateUpdateModal]
  );

  const handleEditAssembly = React.useCallback(
    async (data: AssemblyCreateUpdatePayload) => {
      try {
        if (!data) return;

        await createUpdateAssembly({
          variables: {
            assembly: {
              _id: updateTarget,
              ...data,
              items: data.items.map((item) => ({
                ...item,
              })),
            },
          },
          update: handleUpdateAssembly,
        });

        notify({
          title: t("assembly.updateAssembly"),
          content: t("assembly.success.updateAssembly"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("assembly.updateAssembly"),
          content: t("assembly.errors.updateAssembly"),
        });
      }
      closeCreateUpdateModal();
    },
    [t, createUpdateAssembly, closeCreateUpdateModal, updateTarget]
  );

  const assembliesTableData = React.useMemo<
    TableCardData<AssemblyTableData>
  >(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
        },
        { valueKey: "noItems", title: t("assembly.noItems") },
        {
          valueKey: "UOM",
          title: t("assembly.UOM"),
        },
        {
          valueKey: "cost",
          title: t("assembly.cost"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(assembliesResponse?.listAssemblies, (item) => {
        const noItems = item.items?.length || 0;
        const cost = item.total || 0;

        return {
          cells: {
            ...item,
            noItems,
            cost,
          },
        };
      }),
    };
  }, [t, assembliesResponse]);

  const tableRowActions: TableRowActionData<
    AssemblyTableData
  >[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "document-list",
        options: [
          {
            icon: "drive_file_rename_outline",
            outlined: true,
            id: "edit",
            label: t("common.edit"),
            onClick: openEditModal,
          },
          {
            icon: "content_copy",
            outlined: true,
            id: "clone",
            label: t("common.clone"),
            onClick: (row) => openEditModal(row, true),
          },
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openRemoveDialog,
          },
        ],
      },
    ],
    [t, openRemoveDialog, openEditModal]
  );

  const tableActions = React.useMemo<TableCardAction[]>(
    () => [
      {
        title: t("assembly.addAssembly"),
        onClick: openCreateModal,
        icon: "add",
        disabled: assembliesLoading,
        className: "btn-super-large button large success",
      },
    ],

    [t, assembliesLoading, openCreateModal]
  );

  return (
    <Container fluid className="h-100">
      <Helmet title={t("navigation.settings.assemblies")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SETTINGS.ASSEMBLIES} />

      <CreateUpdateAssemblyModal
        ref={assemblyModalRef}
        uoms={currentUoms}
        onSubmit={updateTarget ? handleEditAssembly : hanleCreateAssembly}
        onClose={closeCreateUpdateModal}
      />

      <ConfirmDialog
        disabled={assemblyDeleting}
        ref={confirmRef}
        onClose={closeRemoveDialog}
        onSubmit={handleRemoveAssembly}
        title={t("assembly.deleteAssembly")}
        confirm={t("common.delete")}
      >
        <div className="field-text">{t("assembly.deleteMessage")}</div>
      </ConfirmDialog>

      <Row className="h-100">
        <Col xs={12} className="assemblies-table-container pb-sm-5 pb-lg-0">
          {assembliesResponse?.listAssemblies &&
          assembliesResponse?.listAssemblies.length > 0 ? (
            <TableCard
              isDataLoading={assembliesLoading}
              alignEnd
              data={assembliesTableData}
              rowActions={tableRowActions}
              actions={tableActions}
            />
          ) : (
            <EmptyPlaceholder
              message={t("assembly.emptyPlaceholder")}
              onButtonPress={openCreateModal}
              buttonText={t("assembly.addAssembly")}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Assemblies;
