import { UpdateQueryFn } from "@apollo/client/core/watchQueryOptions";
import { find, map, filter, uniqueId } from "lodash";
import { OnMessageAddResponse } from "../../types/models/job-messaging";
import { OnClientMessageAddedInput } from "../../types/inputs/messaging";
import { GetClientJobChannel } from "../../../graphql/types/models/client-job-messaging";
import { ClientPostMessage } from "../../../graphql/types/models/client-job-messaging";
import { UserPayload } from "../../types/models/auth";
import moment from "moment";

export const updateClientChannel: UpdateQueryFn<
  GetClientJobChannel,
  OnClientMessageAddedInput,
  OnMessageAddResponse
> = (previousQueryResult, response) => {
  const { subscriptionData } = response;

  const { clientGetJobChannel } = previousQueryResult;

  const messages = clientGetJobChannel?.messages;
  const newMessage = subscriptionData.data.message;

  const existMessage = find(messages, { _id: newMessage._id });

  if (existMessage || !clientGetJobChannel) {
    return previousQueryResult;
  }

  return {
    ...previousQueryResult,
    clientGetJobChannel: {
      ...clientGetJobChannel,
      messages: [newMessage, ...(messages || [])],
    },
  };
};

export const handlePostMessage = (
  channelsData: GetClientJobChannel,
  newMessage?: ClientPostMessage | null
): GetClientJobChannel => {
  const channel = channelsData?.clientGetJobChannel;

  if (!channel || !newMessage) {
    return channelsData;
  }

  const newMessages = [
    {
      ...newMessage?.clientPostMessage,
      media: map(newMessage?.clientPostMessage?.media, (media) => ({
        ...media,
        url: media.url + `?${+new Date()}`,
      })),
    },
    ...(filter(
      channel?.messages,
      (message) => message._id !== newMessage?.clientPostMessage?._id
    ) || []),
  ];

  const newChannel = {
    ...channel,
    messages: newMessages,
  };

  return {
    clientGetJobChannel: newChannel,
  };
};

export const createMessageOptimisticResponse = (
  channelId: string,
  user: UserPayload | null
) => (variables: any): ClientPostMessage & { __typename: "Mutation" } => {
  const message = variables.message;

  return {
    __typename: "Mutation",
    clientPostMessage: {
      __typename: "JobChannelMessage",
      _id: uniqueId("message-"),
      createdAt: moment().toISOString(),
      text: message.text,
      is_deleted: false,
      linkPreview: {
        __typename: "MediaPreview",
        description: null,
        imageUrl: null,
        title: null,
        url: null,
      },
      channel: {
        _id: channelId,
        __typename: "JobChannel",
      },
      media: [],
      sender: {
        __typename: "JobChannelMessageSender",
        _id: user?._id || uniqueId("profile-"),
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
      },
    },
  };
};
