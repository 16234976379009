import { gql } from "@apollo/client";
import { SITE_DIARY_FRAGMENT, SITE_DIARY_TEMPLATE_FRAGMENT } from "./fragments";

export const CREATE_SITE_DIARY = gql`
  mutation($jobId: ID!, $siteDiary: SiteDiaryInput!) {
    createUpdateSiteDiary(jobId: $jobId, siteDiary: $siteDiary) {
      ...SiteDiaryFragment
    }
  }
  ${SITE_DIARY_FRAGMENT}
`;

export const CREATE_SITE_DIARY_TEMPLATE = gql`
  mutation($template: SiteDiaryTemplateInput) {
    createSiteDiaryTemplate(template: $template) {
      ...SiteDiaryTemplateFragment
    }
  }
  ${SITE_DIARY_TEMPLATE_FRAGMENT}
`;

export const DELETE_SITE_DIARY_TEMPLATE = gql`
  mutation($templateId: ID!) {
    deleteSiteDiaryTemplate(templateId: $templateId) {
      ...SiteDiaryTemplateFragment
    }
  }
  ${SITE_DIARY_TEMPLATE_FRAGMENT}
`;

export const DELETE_SITE_DIARY = gql`
  mutation($jobId: ID!, $diaryId: ID!) {
    deleteSiteDiary(jobId: $jobId, diaryId: $diaryId) {
      _id
    }
  }
`;

export const SHARE_SITE_DIARY_WITH_CLIENT = gql`
  mutation ShareSiteDiaryWithClient($jobId: ID!, $diaryId: ID!) {
    shareSiteDiaryWithClient(jobId: $jobId, diaryId: $diaryId) {
      _id
      is_client_shared
    }
  }
`;
