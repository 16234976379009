import LeftModal from "../../left-modal";
import React, { forwardRef, ReactNode, useImperativeHandle } from "react";
import { Button, Form } from "react-bootstrap";

import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalFooter from "../../left-modal/LeftModalFooter";
import { useTranslation } from "react-i18next";
import { FormikHelpers, Formik, FormikProps } from "formik";

import GenericFormBody, {
  GenericFormFields,
} from "../../generic-form/GenericFormBody";
import classNames from "classnames";
import Icon from "../../icons/Icon";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export type ModalButton = {
  title: string;
  onClick?: (formik: FormikProps<any>) => void;
  className?: string;
  type?: "button" | "reset" | "submit";
};

type CreateEntityModalProps<TData> = {
  title: string;
  data: TData;
  show: boolean;
  disableEventValidation?: boolean;
  validationSchema?: any;
  fields: GenericFormFields<TData>;
  onSubmit: (data: TData, helpers?: FormikHelpers<TData>) => void;
  onClose: () => void;
  className?: string;
  hideSubmitButtons?: boolean;
  children?: (helpers: FormikProps<TData>) => ReactNode;
  leftButton?: ModalButton;
  leftFooterRenderer?: (helpers: FormikProps<TData>) => ReactNode;
  bottomMessage?: BottomMessage;
  topRenderer?: React.ReactElement | null;
  endRenderer?: React.ReactElement | null;
  submitText?: string;
  leftColumnRenderer?: React.ReactElement;
  formikRef?: React.Ref<FormikProps<TData>>;
};

type BottomMessage = {
  icon?: string;
  text?: string;
  outlined?: boolean;
  onClick?: () => void;
};
function CreateEntityModal<TData extends {}>({
  title,
  fields,
  data,
  show,
  disableEventValidation,
  validationSchema,
  onClose,
  onSubmit,
  className,
  children,
  leftButton,
  leftFooterRenderer,
  bottomMessage,
  topRenderer,
  endRenderer,
  submitText,
  leftColumnRenderer,
  formikRef,
  hideSubmitButtons,
}: CreateEntityModalProps<TData>) {
  const { t } = useTranslation();

  const renderBody = (props: FormikProps<TData>) => {
    if (leftColumnRenderer) {
      return (
        <Row>
          <Col lg={5} xs={12}>
            {leftColumnRenderer}
          </Col>
          <Col lg={7} xs={12}>
            <GenericFormBody fields={fields} {...props} />
          </Col>
        </Row>
      );
    }

    return <GenericFormBody fields={fields} {...props} />;
  };

  return (
    <LeftModal className={className} show={show} onHide={onClose}>
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={data}
        validateOnChange={!disableEventValidation}
        validateOnBlur={!disableEventValidation}
        onSubmit={(
          values: TData,
          formikHelpers: FormikHelpers<TData>
        ): void | Promise<any> => {
          return onSubmit(values, formikHelpers);
        }}
      >
        {(props) => (
          <Form
            noValidate
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}
          >
            <LeftModalHeader title={title} onClose={onClose} />
            <LeftModalBody className="flex-column">
              {topRenderer}
              {renderBody(props)}
              {children && children(props)}
              {endRenderer}
            </LeftModalBody>
            <LeftModalFooter>
              {bottomMessage && (
                <div
                  className={classNames("bottom-message", {
                    "cursor-pointer": !!bottomMessage.onClick,
                  })}
                  onClick={bottomMessage.onClick}
                >
                  {bottomMessage?.icon && (
                    <Icon
                      className="message-icon"
                      name={bottomMessage.icon}
                      outlined={bottomMessage.outlined}
                    />
                  )}
                  <div className="message">{bottomMessage.text}</div>
                </div>
              )}
              <div className="left-button-container">
                {leftFooterRenderer && leftFooterRenderer(props)}
                {leftButton && (
                  <Button
                    variant="primary"
                    type={leftButton.type}
                    disabled={props.isSubmitting}
                    className={classNames(
                      "button large success left-button",
                      leftButton.className
                    )}
                    onClick={() =>
                      leftButton.onClick && leftButton.onClick(props)
                    }
                  >
                    {leftButton.title}
                  </Button>
                )}
              </div>
              {!hideSubmitButtons && (
                <>
                  <Button
                    variant="secondary"
                    className="button large info"
                    disabled={props.isSubmitting}
                    onClick={onClose}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={props.isSubmitting}
                    className="button large success"
                    type="submit"
                  >
                    {submitText || t("common.create")}
                  </Button>
                </>
              )}
            </LeftModalFooter>
          </Form>
        )}
      </Formik>
    </LeftModal>
  );
}

export default CreateEntityModal;
