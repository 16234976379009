import React from "react";
import "./styles.scss";

type LinkPreviewProps = {
  imgUrl: string | null;
  description: string | null;
  url: string;
  title?: string | null;
};

const LinkPreview: React.FC<LinkPreviewProps> = (props) => {
  const { imgUrl, url, description, title } = props;

  const descriptions = description || url.split("/")[2] || url;

  return (
    <div className="link-preview">
      {imgUrl && <img src={imgUrl} alt="" className="img-fluid link-image" />}
      <div>
        <a
          href={url}
          className="field-text link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
        {!!descriptions && <div className="field-text">{descriptions}</div>}
      </div>
    </div>
  );
};

export default LinkPreview;
