import React from "react";
import classNames from "classnames";

import "./styles.scss";
import { ReactComponent as HammerIcon } from "./icons/icons8-hammer.svg";
import { ReactComponent as BillIcon } from "./icons/icons8-bill.svg";
import { ReactComponent as BookmarkIcon } from "./icons/icons8-bookmark.svg";
import { ReactComponent as CalendarIcon } from "./icons/icons8-calendar.svg";
import { ReactComponent as ContactsIcon } from "./icons/icons8-contacts.svg";
import { ReactComponent as DocumentIcon } from "./icons/icons8-document.svg";
import { ReactComponent as Grid2Icon } from "./icons/icons8-grid-2.svg";
import { ReactComponent as HomeIcon } from "./icons/icons8-home.svg";
import { ReactComponent as PricingIcon } from "./icons/icons8-pricing.svg";
import { ReactComponent as PurchaseOrderIcon } from "./icons/icons8-purchase-order.svg";
import { ReactComponent as UserIcon } from "./icons/icons8-user.svg";
import { ReactComponent as InvoiceIcon } from "./icons/icons8-invoice.svg";
import { ReactComponent as ProgressClaimIcon } from "./icons/currency_exchange.svg";
import { ReactComponent as ReorderIcon } from "./icons/icons8-reorder.svg";
import { ReactComponent as TasksIcon } from "./icons/icons8-tasks.svg";
import { ReactComponent as RulerIcon } from "./icons/icons8-ruler.svg";
import { ReactComponent as ConstructionPlanIcon } from "./icons/icons8-construction-plan.svg";
import { ReactComponent as ChangeIcon } from "./icons/icons8-change.svg";

import { ReactComponent as DeductionIcon } from "./icons/drawtools/polygon-deduction.svg";
import { ReactComponent as ExpandIcon } from "./icons/drawtools/icons8-expand.svg";
import { ReactComponent as LineIcon } from "./icons/drawtools/icons8-line.svg";
import { ReactComponent as MapPinIcon } from "./icons/drawtools/icons8-map-pin.svg";
import { ReactComponent as PitchIcon } from "./icons/drawtools/icons8-pitch.svg";
import { ReactComponent as RotateRightIcon } from "./icons/drawtools/icons8-rotate-right.svg";
import { ReactComponent as SpeechIcon } from "./icons/drawtools/icons8-speech.svg";
import { ReactComponent as TrashIcon } from "./icons/drawtools/icons8-trash.svg";
import { ReactComponent as UndoIcon } from "./icons/drawtools/icons8-undo.svg";
import { ReactComponent as ZoomInIcon } from "./icons/drawtools/icons8-zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "./icons/drawtools/icons8-zoom-out.svg";
import { ReactComponent as PolylineDeductionIcon } from "./icons/drawtools/polyline-deduction.svg";
import { ReactComponent as RectangleDeductionIcon } from "./icons/drawtools/icons8-deduction.svg";

import { ReactComponent as CollapseArrowIcon } from "./icons/schedule/icons8-collapse-arrow.svg";
import { ReactComponent as ColumnsIcon } from "./icons/schedule/icons8-columns.svg";
import { ReactComponent as ExpandArrowIcon } from "./icons/schedule/icons8-expand-arrow.svg";
import { ReactComponent as ListIcon } from "./icons/schedule/icons8-list.svg";
import { ReactComponent as PlusMathIcon } from "./icons/schedule/icons8-plus-math.svg";
import { ReactComponent as ResetIcon } from "./icons/schedule/icons8-reset.svg";
import { ReactComponent as TimelineIcon } from "./icons/schedule/icons8-timeline.svg";
import { ReactComponent as Sort } from "./icons/schedule/icons8-sort.svg";

import { ReactComponent as TeamIcon } from "./icons/icons8-team.svg";
import { ReactComponent as SettingsIcon } from "./icons/icons8-settings.svg";
import { ReactComponent as AssemblyIcon } from "./icons/icons8-open-door.svg";
import { ReactComponent as SiteDiaryIcon } from "./icons/icons8-spiral-bound-booklet.svg";
import { ReactComponent as Builder } from "./icons/icons8-worker.svg";
import { ReactComponent as Connect } from "./icons/icons8-connect.svg";
import { ReactComponent as Company } from "./icons/icons8-organization.svg";
import { ReactComponent as AskQuestion } from "./icons/icons8-ask-question.svg";

export type SchedulerIconNames =
  | "CollapseArrow"
  | "Columns"
  | "ExpandArrow"
  | "List"
  | "PlusMath"
  | "Reset"
  | "Sort"
  | "Timeline";

export type PlanViewerDrawIconNames =
  | "Deduction"
  | "PolylineDeduction"
  | "RectangleDeduction"
  | "Expand"
  | "Line"
  | "MapPin"
  | "Pitch"
  | "RotateRight"
  | "Speech"
  | "Trash"
  | "Undo"
  | "ZoomIn"
  | "ZoomOut";

export type SideNavigationIconNames =
  | "Team"
  | "Hammer"
  | "Bill"
  | "Bookmark"
  | "Calendar"
  | "Contacts"
  | "Document"
  | "Grid2"
  | "Home"
  | "Pricing"
  | "Purchase Order"
  | "Progress Claim"
  | "User"
  | "Invoice"
  | "Reorder"
  | "Change"
  | "Tasks"
  | "Construction Plan"
  | "Ruler"
  | "Settings"
  | "Assembly"
  | "Site Diary"
  | "Connect"
  | "Company";

export type IconNames = "Builder" | "AskQuestion";

export type SVGIconNames =
  | SideNavigationIconNames
  | PlanViewerDrawIconNames
  | SchedulerIconNames
  | IconNames;

type SVGIconProps = {
  name: SVGIconNames;
  className?: string;
  fillColor?: string;
};

const SVGIcon: React.FC<SVGIconProps> = ({
  name,
  className,
  fillColor = "#FFFFFF",
}) => {
  let Icon = null;

  switch (name) {
    case "Team":
      Icon = TeamIcon;
      break;
    case "Hammer":
      Icon = HammerIcon;
      break;
    case "Bill":
      Icon = BillIcon;
      break;
    case "Bookmark":
      Icon = BookmarkIcon;
      break;
    case "Calendar":
      Icon = CalendarIcon;
      break;
    case "Contacts":
      Icon = ContactsIcon;
      break;
    case "Document":
      Icon = DocumentIcon;
      break;
    case "Grid2":
      Icon = Grid2Icon;
      break;
    case "Home":
      Icon = HomeIcon;
      break;
    case "Pricing":
      Icon = PricingIcon;
      break;
    case "Purchase Order":
      Icon = PurchaseOrderIcon;
      break;
    case "Progress Claim":
      Icon = ProgressClaimIcon;
      break;
    case "User":
      Icon = UserIcon;
      break;
    case "Invoice":
      Icon = InvoiceIcon;
      break;
    case "Reorder":
      Icon = ReorderIcon;
      break;
    case "Change":
      Icon = ChangeIcon;
      break;
    case "Tasks":
      Icon = TasksIcon;
      break;
    case "Construction Plan":
      Icon = ConstructionPlanIcon;
      break;
    case "Ruler":
      Icon = RulerIcon;
      break;
    case "Deduction":
      Icon = DeductionIcon;
      break;
    case "PolylineDeduction":
      Icon = PolylineDeductionIcon;
      break;
    case "RectangleDeduction":
      Icon = RectangleDeductionIcon;
      break;
    case "Expand":
      Icon = ExpandIcon;
      break;
    case "Line":
      Icon = LineIcon;
      break;
    case "MapPin":
      Icon = MapPinIcon;
      break;
    case "Pitch":
      Icon = PitchIcon;
      break;
    case "RotateRight":
      Icon = RotateRightIcon;
      break;
    case "Speech":
      Icon = SpeechIcon;
      break;
    case "Trash":
      Icon = TrashIcon;
      break;
    case "Undo":
      Icon = UndoIcon;
      break;
    case "ZoomIn":
      Icon = ZoomInIcon;
      break;
    case "ZoomOut":
      Icon = ZoomOutIcon;
      break;
    case "CollapseArrow":
      Icon = CollapseArrowIcon;
      break;
    case "Columns":
      Icon = ColumnsIcon;
      break;
    case "ExpandArrow":
      Icon = ExpandArrowIcon;
      break;
    case "List":
      Icon = ListIcon;
      break;
    case "PlusMath":
      Icon = PlusMathIcon;
      break;
    case "Reset":
      Icon = ResetIcon;
      break;
    case "Timeline":
      Icon = TimelineIcon;
      break;
    case "Settings":
      Icon = SettingsIcon;
      break;
    case "Assembly":
      Icon = AssemblyIcon;
      break;
    case "Site Diary":
      Icon = SiteDiaryIcon;
      break;
    case "Builder":
      Icon = Builder;
      break;
    case "Connect":
      Icon = Connect;
      break;
    case "Company":
      Icon = Company;
      break;
    case "Sort":
      Icon = Sort;
      break;
    case "AskQuestion":
      Icon = AskQuestion;
      break;
  }

  return (
    <div className={classNames("png-icon", className)}>
      <Icon fill={fillColor} width="30px" height="30px" />
    </div>
  );
};

export default SVGIcon;
