import { gql } from "@apollo/client";
import { TIMESHEET_REQUEST_FRAGMENT } from "./fragments";

export const USER_TIMESHEETS = gql`
  query getUserTimesheets($isProcessed: Boolean) {
    getUserTimesheets(isProcessed: $isProcessed) {
      ...TimesheetRequest
    }
  }
  ${TIMESHEET_REQUEST_FRAGMENT}
`;

export const LIST_TIMESHEETS = gql`
  query($filter: TimesheetFilterInput!, $pagination: PaginationInput!) {
    listTimesheets(filter: $filter, pagination: $pagination) {
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
      timesheets {
        ...TimesheetRequest
      }
    }
  }
  ${TIMESHEET_REQUEST_FRAGMENT}
`;

export const EXPORT_TIMESHEETS = gql`
  query exportTimesheets($filter: TimesheetFilterInput!) {
    exportTimesheets(filter: $filter) {
      _id
      data
      filename
    }
  }
`;
