import React from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Col, Row } from "react-bootstrap";
import {
  RouteComponentProps,
  generatePath,
  useHistory,
} from "react-router-dom";
import { compact, isEmpty, first, find } from "lodash";

import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { RootReducerState } from "../../../redux/reducer";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import Conversation from "../../../components/communication/conversation";
import { ComposeRef } from "../../../components/communication/compose";
import ChannelGlobalList from "../../../components/communication/channels-global-list";
import { GroupOptions } from "../../../components/communication/channels-global-list/ChannelGroupItem";
import {
  LIST_ALL_MESSAGES,
  GET_JOB_CHANNEL,
} from "../../../graphql/queries/job-messaging/queries";
import { JOB_CHANNEL_COMPOSE } from "../../../graphql/queries/job-messaging/mutations";
import {
  JOB_ALL_CHANNEL_SUBSCRIBE_NEW_MESSAGE,
  JOB_MESSAGING_SUBSCRIBE_EXTERNAL,
  JOB_MESSAGING_SUBSCRIBE_INTERNAL,
} from "../../../graphql/queries/job-messaging/subscription";
import {
  ListAllMessagesResponse,
  GetJobChannelResponse,
  NewMessageSubscriptionResponse,
  JobChannelComposeResponse,
  OnMessageAddResponse,
} from "../../../graphql/types/models/job-messaging";
import {
  JobChannelComposeInput,
  OnJobMessageAddedInput,
} from "../../../graphql/types/inputs/messaging";
import { JobChannel, JobChannelEnum } from "../../../models/job-messaging";
import { notify } from "../../../components/notification";
import { getMediaInput } from "../../../utils/transform";
import { uploadFiles } from "../../../utils/files";
import {
  handleAddMessage,
  updateAllChannels,
  updateOnlyExternalChannel,
  updateOnlyInternalChannel,
} from "../../../graphql/queries/job-messaging/utils";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import { useDeleteJobChannelMessageMutation } from "../../../hooks/mutations/useDeleteJobChannelMessageMutation";
import { MembersDetails } from "../../../components/communication/members-details";

type Params = {
  id: string;
};

type MessagesContainerProps = {
  user: UserPayload | null;
} & RouteComponentProps<Params>;

const MessageContainer: React.FC<MessagesContainerProps> = ({
  user,
  match,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const channelId = match.params.id;
  const composeRef = React.useRef<ComposeRef>(null);

  const {
    data: channelsListResponse,
    subscribeToMore: subscribeToAllMessages,
  } = useQuery<ListAllMessagesResponse>(LIST_ALL_MESSAGES, {
    variables: {
      internalLimit: 1,
      externalLimit: 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    getChannelById,
    {
      data: getChannelResponse,
      loading: getChannelLoading,
      updateQuery: updateChannel,
      subscribeToMore: subscribeToChannel,
    },
  ] = useLazyQuery<GetJobChannelResponse>(GET_JOB_CHANNEL, {
    fetchPolicy: "cache-and-network",
    onError: () => {
      notify({
        error: true,
        title: t("communication.loadChannel"),
        content: t("communication.error.loadChannel"),
      });
    },
  });

  const [sendMessage] = useMutation<
    JobChannelComposeResponse,
    JobChannelComposeInput
  >(JOB_CHANNEL_COMPOSE);

  const selectedChannel = React.useMemo(
    () => getChannelResponse?.getJobChannelByType,
    [getChannelResponse]
  );

  const {
    renderDeleteMessageConfirmDialog,
    handleShowDeleteMessageConfirmDialog,
  } = useDeleteJobChannelMessageMutation({
    jobId: selectedChannel?.job?._id,
    channelId: selectedChannel?._id,
  });

  React.useEffect(() => {
    const channels = channelsListResponse?.listallJobsMessages;
    let channel = channelId
      ? find(channels?.internal, { _id: channelId }) ||
        find(channels?.external, { _id: channelId })
      : null;
    if (!channel) {
      channel = first(channels?.internal) || first(channels?.external);
    }

    if (
      !selectedChannel &&
      !getChannelLoading &&
      channel &&
      channel?.job?._id
    ) {
      getChannelById({
        variables: {
          jobId: channel.job._id,
          channelType: channel.channelType,
        },
      });
    }
  }, [channelId, channelsListResponse, selectedChannel, getChannelLoading]);

  React.useEffect(() => {
    const unsubNewMessages = subscribeToAllMessages<
      NewMessageSubscriptionResponse
    >({
      document: JOB_ALL_CHANNEL_SUBSCRIBE_NEW_MESSAGE,
      updateQuery: updateAllChannels,
    });

    return () => {
      unsubNewMessages?.();
    };
  }, [subscribeToAllMessages]);

  React.useEffect(() => {
    if (
      selectedChannel &&
      selectedChannel.channelType === JobChannelEnum.Internal &&
      selectedChannel.job?._id
    ) {
      const unsubInternal =
        subscribeToChannel &&
        subscribeToChannel<OnMessageAddResponse, OnJobMessageAddedInput>({
          document: JOB_MESSAGING_SUBSCRIBE_INTERNAL,
          variables: {
            jobId: selectedChannel.job?._id,
            channelId: selectedChannel._id,
          },
          updateQuery: updateOnlyInternalChannel,
        });

      return () => {
        unsubInternal && unsubInternal();
      };
    }
  }, [selectedChannel, subscribeToChannel]);

  React.useEffect(() => {
    if (
      selectedChannel &&
      selectedChannel.channelType === JobChannelEnum.External &&
      selectedChannel.job?._id
    ) {
      const unsubExternal =
        subscribeToChannel &&
        subscribeToChannel<OnMessageAddResponse, OnJobMessageAddedInput>({
          document: JOB_MESSAGING_SUBSCRIBE_EXTERNAL,
          variables: {
            jobId: selectedChannel.job?._id,
            channelId: selectedChannel._id,
          },
          updateQuery: updateOnlyExternalChannel,
        });

      return () => {
        unsubExternal && unsubExternal();
      };
    }
  }, [selectedChannel, subscribeToChannel]);

  const groupOptions = React.useMemo<GroupOptions[]>(() => {
    if (channelsListResponse?.listallJobsMessages) {
      return compact([
        {
          name: t("common.internal"),
          channels: channelsListResponse.listallJobsMessages.internal || [],
        },
        user?.role !== UserRoles.basic && {
          name: t("common.external"),
          channels: channelsListResponse.listallJobsMessages.external || [],
        },
      ]);
    } else {
      return [];
    }
  }, [t, user, channelsListResponse]);

  const handleSelectChannel = React.useCallback(
    (channel: JobChannel) => {
      if (!channel.job?._id || !channel.channelType) return;
      const url = generatePath("/communication/messages/:id", {
        id: channel._id,
      });
      history.replace(url);
      getChannelById({
        variables: {
          jobId: channel.job._id,
          channelType: channel.channelType,
        },
      });
    },
    [getChannelById]
  );

  const handleSendMessage = React.useCallback(
    async (message: string, files: File[] | null) => {
      try {
        if (!selectedChannel || !selectedChannel.job?._id) return;

        if (!message && isEmpty(selectedChannel)) return;

        composeRef.current?.reset();

        const media = getMediaInput(files);
        const channelId = selectedChannel?._id;
        const jobId = selectedChannel.job?._id;

        const response = await sendMessage({
          variables: {
            channelId,
            jobId,
            message: {
              text: message,
              media,
            },
          },
        });

        const newMessage = response.data?.jobChannelCompose;
        if (newMessage?.media && files) {
          await uploadFiles(newMessage.media, files);
        }
        updateChannel &&
          updateChannel((channelDetails) => {
            return handleAddMessage(channelDetails, response.data);
          });
      } catch (err) {}
    },
    [selectedChannel, updateChannel, sendMessage]
  );

  const conversationTitle = React.useMemo(() => {
    if (!selectedChannel) return "";
    return `${selectedChannel.job?.name} (${selectedChannel.channelType})`;
  }, [selectedChannel]);

  const channelMessages = React.useMemo(
    () =>
      selectedChannel?.messages?.filter((message) => !message.is_deleted) || [],
    [selectedChannel]
  );

  const isExternalChat =
    selectedChannel?.channelType === JobChannelEnum.External;

  const externalMembers = React.useMemo(
    () =>
      selectedChannel?.job?.members?.filter((m) => m.role !== UserRoles.basic),
    [selectedChannel]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.COMMUNICATION_SECTION.MESSAGES}
      />
      <Helmet title={t("navigation.communicationSection.messages")} />

      <Row className="h-100">
        <Col lg={4} xs={12} className="mh-100">
          <ChannelGlobalList
            channelGroups={groupOptions}
            selectedChannelId={selectedChannel?._id || ""}
            title={t("communication.channels")}
            onSelect={handleSelectChannel}
          />
        </Col>
        <Col lg={8} xs={12} className="mh-100">
          {getChannelLoading && !selectedChannel ? (
            <CardPlaceholder />
          ) : (
            selectedChannel && (
              <>
                <Conversation
                  ref={composeRef}
                  onSend={handleSendMessage}
                  messages={channelMessages}
                  name={conversationTitle}
                  onDeleteMessage={handleShowDeleteMessageConfirmDialog}
                  user={user}
                  subHeader={() => (
                    <MembersDetails
                      externalMembers={externalMembers}
                      internalMembers={selectedChannel?.job?.members}
                      isExternalChat={isExternalChat}
                      jobId={selectedChannel?.job?._id}
                    />
                  )}
                />
                {renderDeleteMessageConfirmDialog()}
              </>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(MessageContainer);
