import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BadgeProps, Button } from "react-bootstrap";
import classNames from "classnames";
import DashboardCardHeader from "../card/DashboardCardHeader";
import DashboardCardBody from "../card/DashboardCardBody";
import DashboardCard from "../card";
import Dropdown, { DropdownItem } from "../dropdown";
import Icon from "../../icons/Icon";

import "./styles.scss";

type CustomReportCardProps = {
  title?: string;
  badge?: string; // legacy
  badges?: {
    label: string;
    variant: BadgeProps["variant"];
  }[];
  className?: string;
  buttons?: DropdownItem[];
  controls: DropdownItem[];
  withoutControls?: boolean;
  footer?: React.ReactNode;
};

const CustomReportCard: React.FC<CustomReportCardProps> = (props) => {
  const {
    title,
    badge,
    badges,
    children,
    className,
    buttons,
    controls,
    withoutControls,
    footer,
  } = props;

  const [isDropdownShow, setDropdownVisibility] = useState(false);

  const { t } = useTranslation();

  const handleToggle = React.useCallback(() => {
    setDropdownVisibility(!isDropdownShow);
  }, [isDropdownShow]);

  return (
    <DashboardCard className={classNames("mh-100 report-card", className)}>
      <DashboardCardHeader className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          {title}
          {badge && (
            <div className="title-badge text-capitalize w-auto pl-3 pr-3">
              {badge}
            </div>
          )}
          {!!badges?.length &&
            badges.map((badge, index) => (
              <Badge
                key={`badge-${index}`}
                variant={badge.variant}
                className="ml-2"
              >
                {badge.label}
              </Badge>
            ))}
        </div>
        <div className="d-flex">
          {!!buttons?.length && (
            <div className="pr-3">
              {buttons.map((button) => (
                <Button
                  className="button btn btn-primary bg-transparent text-light"
                  onClick={button.onClick}
                >
                  {button.icon && (
                    <Icon name={button.icon} outlined={button.outlined} />
                  )}
                  {button.label}
                </Button>
              ))}
            </div>
          )}
          {!withoutControls && (
            <Dropdown
              isVisible={isDropdownShow}
              handleToggle={handleToggle}
              label={t("common.options")}
              icon="expand_more"
              size="140px"
              id="report-card-dropdown"
              items={controls}
            />
          )}
        </div>
      </DashboardCardHeader>
      <DashboardCardBody className="report-card-body">
        {children}
      </DashboardCardBody>
      {footer}
    </DashboardCard>
  );
};

export default CustomReportCard;
