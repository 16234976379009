import React from "react";
import moment from "moment";
import classnames from "classnames";
import { TFunction } from "i18next";
import { useHistory } from "react-router-dom";
import Icon from "../../icons/Icon";
import { JobListItem } from "../../../models/job";
import { formatQuoteNumber } from "../../../utils/text";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import "./styles.scss";

type DashboardQuoteItemProps = {
  job: JobListItem;
  user: UserPayload | null;
  t: TFunction;
};
const DashboardQuoteItem: React.FC<DashboardQuoteItemProps> = (props) => {
  const { job, user, t } = props;
  const history = useHistory();

  const handleClick = React.useCallback(
    () => history.push(`/jobs/${job._id}`),
    [job]
  );

  return (
    <div className={classnames("dashboard-quote")} onClick={handleClick}>
      <div className="quote-icon">
        <Icon name="construction" />
      </div>
      <div className="quote-message">
        {user?.role !== UserRoles.basic && (
          <>
            <b>{t("common.currency", { amount: job.totalClaimed })}</b>
            <span> {t("dashboard.claimedOf")} </span>
            <b>{t("common.currency", { amount: job.contractTotal })}</b>
            <span> {t("dashboard.onJob")} </span>
          </>
        )}
        <b>
          {formatQuoteNumber(job.jobNumber, "J")} {job.name}
        </b>
        <span
          className="quote-date"
          title={moment(job.updatedAt).format("llll")}
        >
          {moment(job.updatedAt).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default DashboardQuoteItem;
