import React from "react";
import { Button, Form } from "react-bootstrap";
import DashboardCardBody from "../dashboard/card/DashboardCardBody";
import Table from "react-bootstrap/Table";
import { get, map, toArray, compact } from "lodash";
import MapperRow from "./MapperRow";
import DashboardCardFooter from "../dashboard/card/DashboardCardFooter";
import { FormikProps } from "formik";
import { FormValue } from "./index";
import { SelectOption } from "../generic-form/inputs/select";
import { useTranslation } from "react-i18next";

type MapperBodyProps = FormikProps<FormValue> & {
  options: SelectOption[];
  header: string[];
  sample?: object | null;
  onChange: (values: string[]) => void;
  hideSample?: boolean;
  isLoading?: boolean;
  renderTableContainer?: (children: any) => any;
};

const MapperBody: React.FC<MapperBodyProps> = (props) => {
  const { t } = useTranslation();

  const {
    header,
    handleReset,
    handleSubmit,
    values,
    setValues,
    handleBlur,
    options,
    onChange,
    sample,
    hideSample,
    renderTableContainer,
    isLoading,
  } = props;

  React.useEffect(() => {
    const selectedSystemFields = compact(toArray(values));
    onChange(selectedSystemFields);
  }, [onChange, values]);

  const renderContainer = renderTableContainer
    ? renderTableContainer
    : (children: any) => (
        <Form onSubmit={handleSubmit} onReset={handleReset} noValidate>
          <DashboardCardBody>{children}</DashboardCardBody>

          <DashboardCardFooter className="import-mapper-footer">
            {/* <Button variant="secondary" className="button large info mr-2">
            {t("common.cancel")}
          </Button> */}
            <Button
              variant="primary"
              type="submit"
              className="button large success"
              disabled={isLoading}
            >
              {t("common.import")}
            </Button>
          </DashboardCardFooter>
        </Form>
      );

  const proxySetFieldValue = React.useCallback(
    (name, value) => {
      // workaround to support '.' in field name;
      setValues({
        ...values,
        [name]: value,
      });
    },
    [values, setValues]
  );

  return renderContainer(
    <Table className="table" hover responsive>
      <thead className="table-header">
        <tr>
          <th className="field-text">{t("mapping.fileFields")}</th>
          <th className="field-text content-col">
            {t("mapping.systemFields")}
          </th>
          {!hideSample && (
            <th className="field-text">{t("mapping.sampleData")}</th>
          )}
        </tr>
      </thead>
      <tbody className="table-body">
        {map(header, (key) => (
          <MapperRow
            key={key}
            fieldLabel={key}
            name={key}
            sampleData={get(sample, key, "")}
            value={values[key] as string}
            options={options}
            setFieldValue={proxySetFieldValue}
            handleBlur={handleBlur}
            hideSample={hideSample}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default MapperBody;
