import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import { createJobField } from "./utils";
import { CreateJobPayload, JobDetails, JobType } from "../../../models/job";
import { QuoteStatus, SalesQuote } from "../../../models/salesQuote";
import createJobSchema from "./CreateJob.schema";
import { ContactListResponse } from "../../../graphql/types/models/client";
import { assign, pick } from "lodash";
import { handlePlaceSelect } from "../../../utils/place";
import { CreateUpdateJobResponse } from "../../../graphql/types/models/job";
import { CREATE_UPDATE_JOB } from "../../../graphql/queries/job/mutations";
import "./styles.scss";
import { LIST_CONTACTS } from "../../../graphql/queries/client/queries";
import CreateClient, {
  CreateClientRef,
} from "../../../containers/modals/create-client";
import { notify } from "../../notification";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { handleJobCreate } from "../../../graphql/queries/job/utils";

type CreateJobModalProps = {
  salesQuote?: SalesQuote;
  job?: JobDetails;
};

const CreateJobModal: React.FC<CreateJobModalProps> = (props, ref) => {
  const { salesQuote, job } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const createClientRef = React.useRef<CreateClientRef>(null);
  const [jobType, setJobType] = React.useState<JobType>(JobType.CONTRACT);
  const { shouldShow, hide } = useModalDisplay(ref);
  const createModalRef = React.useRef<any>(null);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateJobPayload>
  >({});

  const { data: contactsList, loading: contactsLoading } = useQuery<
    ContactListResponse
  >(LIST_CONTACTS);

  const contacts = React.useMemo(() => contactsList?.listContacts || [], [
    contactsList,
  ]);

  const [createJob] = useMutation<CreateUpdateJobResponse>(CREATE_UPDATE_JOB, {
    update: handleJobCreate,
    onCompleted: (response) => {
      const id = response.createUpdateJob?._id;
      history.push(`/jobs/${id}`);
      hide();

      notify({
        title: title,
        content: job
          ? t("jobs.success.updateJob")
          : t("jobs.success.createJob"),
      });
    },
    onError: (error) => {
      notify({
        error: true,
        title: title,
        content: error.message,
      });
    },
  });

  const openClientModal = React.useCallback(
    () => createClientRef.current?.show(true),
    [createClientRef]
  );

  const handleJobTypeSelect = React.useCallback((value: string | string[]) => {
    if (!value) return;
    setJobType(
      value === JobType.COST_PLUS ? JobType.COST_PLUS : JobType.CONTRACT
    );
  }, []);

  React.useEffect(() => {
    setFormFields(
      createJobField(
        t,
        contacts,
        openClientModal,
        handlePlaceSelect,
        handleJobTypeSelect,
        jobType,
        job,
        salesQuote
      )
    );
  }, [
    t,
    contacts,
    openClientModal,
    handlePlaceSelect,
    jobType,
    job,
    salesQuote,
  ]);

  React.useEffect(() => {
    if (!shouldShow) {
      setJobType(JobType.CONTRACT);
    }
  }, [shouldShow]);

  const initialValues = React.useMemo(() => {
    let address = pick(job, ["address", "city", "state", "postcode"]);
    if (!job?._id) {
      address = pick(salesQuote?.job || salesQuote, [
        "address",
        "city",
        "state",
        "postcode",
      ]);
      if (!salesQuote?.job?.address && !salesQuote?.address) {
        address = {
          address: salesQuote?.contact?.address,
          city: salesQuote?.contact?.city,
          state: salesQuote?.contact?.state,
          postcode: salesQuote?.contact?.postcode,
        };
      }
    }
    return {
      customer: job?.customer?._id || salesQuote?.contact?._id || "",
      name: job?.name || salesQuote?.job?.name || salesQuote?.name || "",
      contractTotal: job?.contractTotal || salesQuote?.subTotal || 0,
      startDate: job?.startDate || salesQuote?.job?.startDate || "",
      endDate: job?.endDate || salesQuote?.job?.endDate || "",
      address: address?.address || "",
      city: address?.city || "",
      state: address?.state || "",
      postcode: address?.postcode || "",
      type: job?.type || salesQuote?.job?.type || JobType.CONTRACT,
      markup: job?.markup || salesQuote?.job?.markup || 0,
      invoiceToJobAddress: !!job?.invoiceToJobAddress,
      is_contract_locked: !!salesQuote?._id || job?.is_contract_locked,
      is_non_contracted: false,
    };
  }, [job, salesQuote]);

  const handleSubmit = React.useCallback(
    (values: CreateJobPayload) => {
      if (job) {
        assign(values, {
          _id: job._id,
          customer: values.customer ? values.customer : job.customer?._id,
        });
      }
      if (salesQuote) {
        assign(values, {
          _id: values._id || salesQuote.job?._id,
          salesQuoteId: salesQuote._id,
          customer: salesQuote.contact?._id,
        });
      }
      return createJob({
        variables: {
          jobId: job?._id || salesQuote?.job?._id,
          job: {
            ...values,
            markup: Number(values.markup || 0),
            contractTotal: Number(values.contractTotal || 0),
          },
        },
      });
    },
    [job, salesQuote]
  );

  const title = React.useMemo(() => {
    if (salesQuote?.status === QuoteStatus.NON_CONTRACTED) {
      return t("jobs.updateJob");
    }
    return job ? t("jobs.editJob") : t("jobs.addNewJob");
  }, [job, salesQuote]);

  const handleSetNewClient = React.useCallback(
    (clientId: string) => {
      if (clientId) {
        createModalRef.current?.setFieldValue("customer", clientId);
      }
    },
    [createModalRef]
  );

  return (
    <>
      <CreateEntityModal
        formikRef={createModalRef}
        validationSchema={createJobSchema(t, job, salesQuote)}
        className="create-job-modal"
        title={title}
        show={shouldShow}
        data={initialValues}
        onSubmit={handleSubmit}
        onClose={hide}
        fields={formFields}
        submitText={job || salesQuote?.job ? t("common.update") : undefined}
      />

      <CreateClient
        ref={createClientRef}
        onCreatedRecord={handleSetNewClient}
      />
    </>
  );
};

export default forwardRef(CreateJobModal);
