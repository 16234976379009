import React from "react";
import classNames from "classnames";

export type IconProps = {
  name: string;
  outlined?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const Icon: React.FC<IconProps> = ({
  name,
  outlined,
  className,
  style,
  ...other
}) => {
  const iconClass = classNames(
    "material-icons",
    { "material-icons--outlined": outlined },
    className
  );

  return (
    <i className={iconClass} style={style} {...other}>
      {name}
    </i>
  );
};

export default Icon;
