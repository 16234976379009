import React from "react";
import classNames from "classnames";
import Icon from "../icons/Icon";
import "./styles.scss";

type AvatarProps = {
  url?: string;
  alt?: string;
  height?: string;
  width?: string;
  titleFontSize?: string;
  rounded?: boolean;
  icon?: string;
  iconSize?: string;
  userName?: string;
  fallbackColor?: "dark" | "default" | "light";
  outlined?: boolean;
  contained?: boolean;
};

const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    url,
    alt,
    height,
    width,
    rounded,
    icon,
    iconSize,
    userName,
    outlined,
    contained,
    fallbackColor = "default",
    titleFontSize,
  } = props;

  const initials = React.useMemo(() => {
    if (!userName) {
      return "";
    }

    return userName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .substring(0, 2);
  }, []);

  const style = React.useMemo(
    () => ({
      height,
      width,
      fontSize: titleFontSize,
    }),
    [height, titleFontSize, width]
  );

  const iconStyles = React.useMemo(
    () => ({
      fontSize: iconSize,
    }),
    [iconSize]
  );

  const imgClasses = classNames(
    {
      "rounded-circle": rounded,
      "avatar-outlined": outlined,
    },
    "img-fluid"
  );

  const fallbackStyles = classNames(
    {
      "rounded-circle": rounded,
      "avatar-outlined": outlined,
      "avatar-fallback-light": fallbackColor === "light",
      "avatar-fallback-dark": fallbackColor === "dark",
    },
    "avatar-fallback"
  );

  if (!url && (icon || initials)) {
    return (
      <div style={style} className={fallbackStyles} title={userName}>
        {icon && !initials && <Icon name={icon} style={iconStyles} />}
        {initials}
      </div>
    );
  }
  return (
    <div
      className={imgClasses}
      title={userName}
      style={{
        backgroundImage: `url("${url}")`,
        backgroundSize: contained ? "contain" : "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width,
        height,
      }}
    />
  );
  // return <img className={imgClasses} src={url} alt={alt} style={style} />;
};

export default Avatar;
