import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { compact, map } from "lodash";
import { useQuery } from "@apollo/client";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import { HeaderSearchOption } from "../../../components/dashboard/header-search";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import CreateClient, { CreateClientRef } from "../../modals/create-client";
import TableCard, {
  EmptyTablePlaceholder,
} from "../../../components/dashboard/table-card";
import { ContactListResponse } from "../../../graphql/types/models/client";
import { LIST_CONTACTS } from "../../../graphql/queries/client/queries";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import { getFullAddress, getFullName } from "../../../utils/text";
import DashboardActionHeader from "../../../components/dashboard/table-card/DashboardActionHeader";
import { ClientType } from "../../../models/client";

type ClientsContainerProps = DashboardContextValue & RouteComponentProps & {};

export type ClientTablePayload = {
  _id: string;
  name: string;
  email: string;
  location: string;
  phone: string;
  phase: string;
  type: string;
};

const ClientsOverviewContainer: React.FC<ClientsContainerProps> = ({
  setSearchOptions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const createClientRef = React.useRef<CreateClientRef>(null);

  const { loading: contactsLoading, data: clientsData } = useQuery<
    ContactListResponse
  >(LIST_CONTACTS);

  const contactsTable = React.useMemo<ClientTablePayload[]>(() => {
    if (contactsLoading) {
      return [];
    }

    return map(clientsData?.listContacts, (client) => ({
      ...client,
      _id: client._id,
      name:
        client.type === ClientType.BUSINESS
          ? [client.business_name, getFullName(client)].join(" / ")
          : getFullName(client),
      email: client.email,
      location: getFullAddress(client),
      phone: compact([client.phone, client.phone_secondary]).join(" / "),
    }));
  }, [clientsData, contactsLoading]);

  const searchOptions = React.useMemo(() => {
    return contactsTable.map((contact) => ({
      value: contact._id,
      label: contact.name,
    }));
  }, [contactsTable]);

  const contactsTableData = React.useMemo<
    TableCardData<ClientTablePayload>
  >(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("contacts.name"),
          sortable: true,
        },
        {
          valueKey: "email",
          title: t("contacts.email"),
          sortable: true,
        },
        {
          valueKey: "phone",
          title: t("contacts.phone"),
          sortable: true,
        },
        {
          valueKey: "location",
          title: t("contacts.address"),
          sortable: true,
        },
        {
          valueKey: "phase",
          title: t("contacts.phase"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            t(`contacts.${value}`),
        },
        {
          valueKey: "type",
          title: t("contacts.clientType"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            value === ClientType.INDIVIDUAL
              ? t("contacts.individual")
              : t("contacts.business"),
        },
      ],
      rows: map(contactsTable, (client) => ({
        cells: client,
      })),
    };
  }, [contactsTable, t]);

  const handleAddItemClick = React.useCallback(
    () => createClientRef.current?.show(true),
    [createClientRef]
  );

  const handleClientTableRowClick = React.useCallback(
    (row: TableCardDataRow<ClientTablePayload>) => {
      history.push(`/contacts/clients/${row.cells._id}`);
    },
    [history]
  );

  const handleSearchSelect = React.useCallback(
    (option: HeaderSearchOption | null) => {
      if (!option) return;
      history.push(`/contacts/clients/${option.value}`);
    },
    [history]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("contacts.emptyContactPlaceholder"),
      buttonText: t("contacts.createClient"),
      onPress: handleAddItemClick,
    }),
    [t, handleAddItemClick]
  );

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("contacts.searchClients"),
      options: searchOptions,
      onSelect: handleSearchSelect,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [t, searchOptions, setSearchOptions]);

  return (
    <Container fluid className="tables-layout">
      <Helmet title={t("navigation.contactsSection.contacts")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.CONTACTS_SECTION.CLIENT} />
      <CreateClient ref={createClientRef} />
      <Row className="w-100 justify-content-end pb-4">
        <Col>
          <DashboardActionHeader onActionButton={handleAddItemClick} />
        </Col>
      </Row>
      <Row className="w-100">
        <Col xs={12}>
          <TableCard
            tableId="clients-list"
            isDataLoading={contactsLoading}
            data={contactsTableData}
            onRowClick={handleClientTableRowClick}
            emptyPlaceholder={emptyPlaceholder}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(withDashboardContext(ClientsOverviewContainer));
