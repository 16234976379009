import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { map, last } from "lodash";
import moment from "moment";
import { JobChannel } from "../../../../models/job-messaging";
import Icon from "../../../icons/Icon";

export type GroupOptions = {
  name: string;
  channels: JobChannel[];
};

type ChannelGroupItemProps = {
  group: GroupOptions;
  selectedChannelId: string;
  selectChannel: (channel: JobChannel) => void;
};

const ChannelGroupItem: React.FC<ChannelGroupItemProps> = (props) => {
  const { group, selectedChannelId, selectChannel } = props;

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleGroupVisibility = React.useCallback(() => setIsOpen(!isOpen), [
    isOpen,
  ]);

  React.useEffect(() => {
    if (selectedChannelId) {
      setIsOpen(group.channels.some((item) => item._id === selectedChannelId));
    }
  }, [group, selectedChannelId]);

  const renderChannels = React.useCallback(() => {
    return map(group.channels, (channel) => {
      const lastMessage = last(channel.messages);
      const lastMessageText =
        lastMessage?.text ||
        (lastMessage?.media ? lastMessage?.media[0]?.name : "");
      const senderName =
        lastMessage?.sender.first_name || lastMessage?.sender.last_name
          ? `${lastMessage?.sender.first_name} ${lastMessage?.sender.last_name}`
          : "";

      return (
        <li
          key={channel._id}
          className={classNames("field-text channel-item", {
            "channel-item-list--selected": selectedChannelId === channel._id,
          })}
          onClick={() => selectChannel(channel)}
        >
          <div className="channel-item-message-info">
            <div className="channel-item-top-content">
              <b className="channel-item--name">{channel.job?.name}</b>
              <div style={{ flex: 1 }} />
              {lastMessage && (
                <b className="channel-item--date">
                  {moment(lastMessage?.createdAt).fromNow()}
                </b>
              )}
            </div>
            <div className="channel-item-bottom-content">
              <i
                className={classNames(
                  { "channel-item--message-text-disabled": !lastMessage },
                  "channel-item--message-text"
                )}
              >
                {lastMessage
                  ? `${senderName}: ${lastMessageText}`
                  : t("communication.emptyPlaceholder")}
              </i>
            </div>
          </div>
        </li>
      );
    });
  }, [group, selectedChannelId, t, selectChannel]);

  const containerClasses = classNames(
    {
      "channel-main-list--open": isOpen,
    },
    "channel-main-list"
  );

  return (
    <div className={containerClasses}>
      <div
        className="field-text channel-main-item"
        onClick={toggleGroupVisibility}
      >
        <b className="channel-main-item--title" title={group.name}>
          {group.name}
        </b>
        <div style={{ flex: 1 }} />
        <Icon name={isOpen ? "expand_less" : "expand_more"} />
      </div>
      <ul className="channel-item-list">{renderChannels()}</ul>
    </div>
  );
};

export default ChannelGroupItem;
