import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { map, compact } from "lodash";
import moment from "moment";

import {
  withDashboardContext,
  DashboardContextValue,
} from "../../layouts/dashboard/DashboardContext";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/admin-navigation-items";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import {
  WbAdminListUsersRespnose,
  UserDetails,
} from "../../../graphql/types/models/wb-user";
import { EmptyTablePlaceholder } from "../../../components/dashboard/table-card";
import TableCard from "../../../components/dashboard/table-card";
import { WB_LIST_USERS } from "../../../graphql/queries/wb-admin/user/queries";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";

import "./styles.scss";

type UserTableItem = UserDetails & {
  name?: string;
  business_name?: string;
};

type UsersContainerProps = DashboardContextValue;

const UsersContainer: React.FC<UsersContainerProps> = ({
  navigationContext,
  setNavigationContext,
  setSearchOptions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data, loading } = useQuery<WbAdminListUsersRespnose>(WB_LIST_USERS);

  const dataRows = React.useMemo(
    () =>
      map(data?.wbAdminListUsers, (user: UserDetails) => ({
        ...user,
        name: compact([user.first_name, user.last_name]).join(" "),
        business_name: user?.business?.name,
        role: t(`team.${user.role}`),
      })),
    [data]
  );

  const {
    filteredArray: filteredRows,
    setFilter,
  } = useFilteredArrayMultipleField(
    dataRows,
    ["first_name", "last_name", "email", "business_name"],
    true
  );

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("common.search"),
      options: [],
      onAutocomplete: setFilter,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [t, setSearchOptions, setFilter]);

  const tableData = React.useMemo<TableCardData<UserTableItem>>(() => {
    return {
      columns: [
        {
          valueKey: "business_name",
          title: t("wbUsers.businessName"),
          className: "ellipsis-field",
        },
        {
          valueKey: "name",
          title: t("common.name"),
          className: "ellipsis-field",
        },
        {
          valueKey: "email",
          title: t("common.email"),
        },
        {
          valueKey: "role",
          title: t("common.role"),
        },
        {
          valueKey: "createdAt",
          title: t("common.createdAt"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
      ],
      rows: filteredRows.map((item) => ({ cells: item })),
    };
  }, [filteredRows, t]);

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<UserTableItem>) => {
      setNavigationContext({
        ...navigationContext,
        business: {
          _id: row.cells?.business?._id || "",
          name: row.cells?.business?.name || "",
        },
      });
      history.push(`/businesses/${row.cells?.business?._id}`);
    },
    [setNavigationContext]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("wbUsers.emptyPlaceholder"),
    }),
    [t]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.wbAdmin.WunderbuidConsole")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.USERS} />
      <Row>
        <Col xs={12}>
          <TableCard
            isDataLoading={loading}
            data={tableData}
            alignEnd
            onRowClick={handleRowClick}
            emptyPlaceholder={emptyPlaceholder}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withDashboardContext(UsersContainer);
