import { UserPayload } from "../../graphql/types/models/auth";
import { AuthActions } from "./constants";

type AuthAction = {
  type: string;
  payload?: any;
};

export const getUser = (): AuthAction => ({
  type: AuthActions.GET_USER,
});

export const signUp = (): AuthAction => ({
  type: AuthActions.SIGN_UP,
});

export const signUpFailed = (): AuthAction => ({
  type: AuthActions.SIGN_UP_FAILED,
});

export const getUserSuccess = (
  userData: UserPayload,
  isSubcontractor: boolean = false,
  isAdmin: boolean = false,
  isClient: boolean = false
): AuthAction => ({
  type: AuthActions.GET_USER_SUCCESS,
  payload: {
    user: userData,
    isSubcontractor,
    isAdmin,
    isClient,
  },
});
export const getUserFailed = (error: string): AuthAction => ({
  type: AuthActions.GET_USER_FAILED,
  payload: error,
});

export const signIn = (): AuthAction => ({
  type: AuthActions.SIGN_IN,
});

export const signInSuccess = ({
  isSubcontractor = false,
  isAdmin = false,
  isClient = false,
}): AuthAction => ({
  type: AuthActions.SIGN_IN_SUCCESS,
  payload: {
    isSubcontractor,
    isAdmin,
    isClient,
  },
});

export const signInFailed = (error: string): AuthAction => ({
  type: AuthActions.SIGN_IN_FAILED,
  payload: error,
});

export const updateProfile = (user: Partial<UserPayload>): AuthAction => ({
  type: AuthActions.UPDATE_PROFILE,
  payload: user,
});

export const logout = (): AuthAction => ({
  type: AuthActions.LOGOUT,
});
