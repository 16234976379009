import React from "react";
import Link from "./Link";
import Text from "./Text";
import ReactMarkdown, { NodeType } from "react-markdown";

const RENDERERS = {
  link: Link,
  text: Text,
};

const ALLOWED_TYPES: NodeType[] = [
  "link",
  "text",
  "list",
  "listItem",
  "paragraph",
  "strong",
  "delete",
  "emphasis",
];

type MarkdownProps = {
  source: string;
};

const Markdown: React.FC<MarkdownProps> = (props) => {
  const { source } = props;

  return (
    <ReactMarkdown
      source={source}
      renderers={RENDERERS}
      allowedTypes={ALLOWED_TYPES}
    />
  );
};

export default Markdown;
