import { ApolloCache, FetchResult } from "@apollo/client";
import { filter } from "lodash";
import {
  CreateUpdateJobResponse,
  ListJobsResponse,
} from "../../../graphql/types/models/job";
import { LIST_JOBS } from "./queries";

export const handleJobCreate = (
  cache: ApolloCache<any>,
  response: FetchResult<CreateUpdateJobResponse>
) => {
  const createdJob = response.data?.createUpdateJob;
  const jobsListResponse = cache.readQuery<ListJobsResponse>({
    query: LIST_JOBS,
  });
  const jobsList = jobsListResponse?.listJobs;
  if (!createdJob || !jobsList) {
    return;
  }

  cache.writeQuery({
    query: LIST_JOBS,
    data: {
      listJobs: [...jobsList, createdJob],
    },
  });
};

export const handleJobRemove = (jobId: string) => (
  jobList?: ListJobsResponse
) => {
  const listJobs = jobList?.listJobs;

  return {
    listJobs: filter(listJobs, (job) => job._id !== jobId),
  };
};
