import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SearchInput from "../../../../components/search-input";

type GlobalSearchInputProps = {
  disabled?: boolean;
  onSearch: (value: string) => void;
};

const GlobalSearchInput: FC<GlobalSearchInputProps> = ({
  disabled,
  onSearch,
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = React.useState("");
  const handleSearch = React.useCallback(() => {
    onSearch(searchInput);
  }, [onSearch, searchInput]);

  return (
    <>
      <SearchInput
        onChange={setSearchInput}
        value={searchInput}
        placeholder={t("common.keywords")}
        className="search-input"
      />
      <Button
        variant="primary"
        disabled={disabled}
        className="button success left-button"
        onClick={handleSearch}
      >
        {t("common.search")}
      </Button>
    </>
  );
};

export default GlobalSearchInput;
