import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { map } from "lodash";
import { useMutation, useQuery } from "@apollo/client";

import { notify } from "../../../../components/notification";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../../components/dashboard/table-card/utils";
import { AddPriceListPayload } from "../../../../models/price-list";
import AddPriceListModal from "../../../../components/settings/price-list/add-list";
import ConfirmDialog from "../../../../components/confirm-dialog";

import {
  CreatePriceListResponse,
  DeletePriceListResponse,
  ListPriceListResponse,
} from "../../../../graphql/types/models/price-list";
import { SupplierListResponse } from "../../../../graphql/types/models/supplier";
import { UserPayload } from "../../../../graphql/types/models/auth";
import { LIST_PRICE_LISTS } from "../../../../graphql/queries/price-list/queries";
import {
  CREATE_PRICE_LIST,
  DELETE_PRICE_LIST,
} from "../../../../graphql/queries/price-list/mutations";

import { LIST_SUPPLIERS } from "../../../../graphql/queries/supplier/queries";

import { RootReducerState } from "../../../../redux/reducer";
import EmptyPlaceholder from "../../../../components/empty-placeholder";

type PriceListTable = {
  _id: string;
  name: string;
  supplier: string | null;
};

type PriceListOverviewProps = {
  user: UserPayload | null;
};

const PriceListOverview: React.FC<PriceListOverviewProps> = (props) => {
  const { user, ...rest } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const { data: suppliersList, loading: suppliersLoading } = useQuery<
    SupplierListResponse
  >(LIST_SUPPLIERS);

  const [createPriceList] = useMutation<CreatePriceListResponse>(
    CREATE_PRICE_LIST,
    {
      onCompleted: (data) => {
        notify({
          title: t("priceList.newPriceList"),
          content: t("priceList.success.addPriceList"),
        });
        setMemberModalVisibility(false);
        history.push(`/settings/price-lists/${data?.createUpdatePrice?._id}`);
      },
      onError: () => {
        notify({
          error: true,
          title: t("priceList.newPriceList"),
          content: t("priceList.error.addPriceList"),
        });
      },
    }
  );

  const [deletePriceList, { loading: memberDeleting }] = useMutation<
    DeletePriceListResponse
  >(DELETE_PRICE_LIST, {
    onCompleted: (data) => {
      refetchPriceList();
      notify({
        title: t("priceList.removePriceList"),
        content: t("priceList.success.removePriceList"),
      });
      setRemoveConfirmVisibility(false);
      setDeleteTarget("");
    },
    onError: () => {
      notify({
        error: true,
        title: t("priceList.removePriceList"),
        content: t("priceList.error.removePriceList"),
      });
    },
  });

  const {
    data: priceLists,
    loading: priceListLoading,
    refetch: refetchPriceList,
  } = useQuery<ListPriceListResponse>(LIST_PRICE_LISTS, {
    fetchPolicy: "cache-and-network",
  });

  const [showMemberModal, setMemberModalVisibility] = useState(false);
  const [showRemoveConfirmDialog, setRemoveConfirmVisibility] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState("");

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget("");
    setRemoveConfirmVisibility(false);
  }, []);

  const openRemoveDialog = React.useCallback((row?: PriceListTable) => {
    if (row) {
      setDeleteTarget(row._id);
      setRemoveConfirmVisibility(true);
    }
  }, []);

  const handlePriceListRemoveSubmit = React.useCallback(async () => {
    if (!deleteTarget) {
      return;
    }
    deletePriceList({
      variables: {
        priceId: deleteTarget,
      },
    });
  }, [deleteTarget]);

  const handleAddSubmit = React.useCallback(
    async (values: AddPriceListPayload) => {
      const { name, supplier: supplierId } = values;
      createPriceList({
        variables: {
          price: {
            name,
            supplierId,
          },
        },
      });
    },
    []
  );

  const togglePriceListModal = React.useCallback(() => {
    setMemberModalVisibility((visible) => !visible);
  }, []);

  const priceListTable: TableCardData<PriceListTable> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("placeholders.name"),
        },
        {
          valueKey: "supplier",
          title: t("placeholders.supplier"),
        },
      ],
      rows: map(priceLists?.listPrice, (priceList) => ({
        cells: {
          name: priceList.name,
          supplier: priceList.supplier?.business_name || "",
          _id: priceList._id,
        },
      })),
    };
  }, [priceLists]);

  const tableRowActions: TableRowActionData<PriceListTable>[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "price-list",
        options: [
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openRemoveDialog,
          },
        ],
      },
    ],
    [openRemoveDialog, user]
  );

  const tableActions = React.useMemo<TableCardAction[]>(
    () => [
      {
        title: t("priceList.addPriceList"),
        onClick: togglePriceListModal,
        icon: "add",
        className: "button large success",
      },
    ],
    [togglePriceListModal]
  );

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<PriceListTable>) => {
      history.push(`/settings/price-lists/${row.cells._id}`);
    },
    [history]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.priceLists")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SETTINGS.PRICE_LISTS} />

      <ConfirmDialog
        disabled={memberDeleting}
        show={showRemoveConfirmDialog}
        onClose={closeRemoveDialog}
        onSubmit={handlePriceListRemoveSubmit}
        title={t("priceList.removePriceList")}
        confirm={t("common.delete")}
      >
        <div className="field-text">{t("priceList.removeMessage")}</div>
      </ConfirmDialog>
      {suppliersList?.listSuppliers && (
        <AddPriceListModal
          suppliers={suppliersList?.listSuppliers}
          show={showMemberModal}
          onClose={togglePriceListModal}
          onSubmit={handleAddSubmit}
        />
      )}
      {priceLists?.listPrice?.length ? (
        <TableCard
          isDataLoading={priceListLoading}
          fullHeight
          overflowXHidden
          data={priceListTable}
          actions={tableActions}
          alignEnd
          rowActions={tableRowActions}
          onRowClick={handleRowClick}
        />
      ) : (
        <EmptyPlaceholder
          message={t("priceList.emptyPlaceholder")}
          buttonText={t("priceList.newPriceList")}
          onButtonPress={togglePriceListModal}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps)(PriceListOverview);
