import React from "react";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { pick } from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import AuthenticationLayout from "../layouts/authentication";
import SignUpForm from "../../components/authentication/sign-up-form";
import { signUp, signUpFailed } from "../../redux/authentication/actions";
import { SIGN_UP } from "../../graphql/queries/auth/mutations";
import { notify } from "../../components/notification";
import GraphQLClient from "../../graphql";
import { SignUpResponse } from "../../graphql/types/models/auth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Context, { SiteContext } from "../../siteContext";
import AffiliateSignUpForm from "../../components/authentication/affiliate-sign-up-form";
import {
  AffiliateSignUpPayload,
  AffiliateSignUpResponse,
} from "../../graphql/types/models/affiliate";
import { AFFILIATE_SIGN_UP } from "../../graphql/queries/affiliate/mutations";

type SignUpContainerProps = {
  signUp: () => void;
  signUpFailed: () => void;
};

const SignUpContainer: React.FC<SignUpContainerProps> = (props) => {
  const { signUp, signUpFailed } = props;
  const { t } = useTranslation();

  const { isAffiliate } = React.useContext(Context) as SiteContext;

  const { storedValue: affiliateCode } = useLocalStorage(
    "@AFFILIATE_CODE",
    null
  );

  const history = useHistory();

  React.useEffect(() => {
    GraphQLClient.setToken("");
  }, []);

  const [registerUser] = useMutation<SignUpResponse>(SIGN_UP, {
    onError: (error) => {
      notify({
        title: t("authentication.errors.signUpTitle"),
        content: error.message,
        error: true,
      });
      signUpFailed();
    },
    onCompleted: ({ signUp }) => {
      history.push(`/sign-up/activate/${signUp._id}`);
    },
  });

  const [registerAffiliate] = useMutation<AffiliateSignUpResponse>(
    AFFILIATE_SIGN_UP,
    {
      onError: (error) => {
        notify({
          title: t("authentication.errors.signUpTitle"),
          content: error.message,
          error: true,
        });
        signUpFailed();
      },
      onCompleted: () => {
        history.push(`/sign-up/thank-you`);
      },
    }
  );

  const handleAffiliateSubmit = React.useCallback(
    async (values: AffiliateSignUpPayload) => {
      return registerAffiliate({
        variables: {
          profile: {
            ...pick(values, [
              "first_name",
              "last_name",
              "business_name",
              "email",
              "phone",
              "ABN",
              "address",
              "city",
              "state",
              "postcode",
              "about",
              "additionalComment",
            ]),
            hasBuilders: values.hasBuilders === "yes",
          },
        },
      });
    },
    []
  );
  const handleFormSubmit = React.useCallback(
    async (credentials) => {
      signUp();

      const preparedCredentials = {
        business_name: credentials.businessName,
        phone_number: credentials.phone,
        email: credentials.email,
        first_name: credentials.firstName,
        last_name: credentials.lastName,
        password: credentials.password,
        affiliateCode,
      };

      const response = await registerUser({ variables: preparedCredentials });

      if (process.env.NODE_ENV === "development") return response;
      try {
        const data = response.data?.signUp;
        if (!data) return response;
      } catch (e) {}

      return response;
    },
    [registerUser, signUp, affiliateCode, isAffiliate]
  );

  return (
    <AuthenticationLayout>
      <Helmet
        title={
          isAffiliate
            ? t("authentication.affiliateSignUp")
            : t("authentication.signUp")
        }
      />
      {isAffiliate ? (
        <AffiliateSignUpForm onSubmit={handleAffiliateSubmit} />
      ) : (
        <SignUpForm onSubmit={handleFormSubmit} />
      )}
    </AuthenticationLayout>
  );
};

export default connect(null, { signUp, signUpFailed })(SignUpContainer);
