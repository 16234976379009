import { map } from "lodash";
import { FileWithPath } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import axios, { AxiosProgressEvent } from "axios";

export const MAX_IMAGE_HEIGHT = 1080;
export const MAX_IMAGE_WIDTH = 1920;
export const IMAGE_RESIZE_QUALITY = 80;
export const DEFAULT_FILE_TYPE = "application/octet-stream";

export type UploadUrl = {
  name: string;
  upload_url: string;
};

export const uploadFiles = (
  medias: UploadUrl[],
  files: File[],
  onProgress?: (file: File, progress: number) => void
) => {
  const filesPromises = map(medias, (file, index) =>
    axios.put(file.upload_url, files[index], {
      headers: {
        // "Content-Disposition": `attachment;filename="${file.name}"`
        "Content-Type": files[index]?.type || DEFAULT_FILE_TYPE,
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        onProgress?.(
          files[index],
          Math.round((progressEvent.progress || 0) * 100)
        );
      },
    })
  );

  return Promise.all(filesPromises);
};

export const resizeFiles = async (
  files: File[] | FileWithPath[],
  type?: string,
  outputType?: string
) => {
  return await Promise.all(
    files.map((file: File | FileWithPath) => {
      if (/^image/i.test(file.type)) {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            MAX_IMAGE_WIDTH,
            MAX_IMAGE_HEIGHT,
            type || file.type.split("/")[1],
            IMAGE_RESIZE_QUALITY,
            0,
            (resizedFile: any) => {
              if (outputType !== "base64") {
                // fix filename + type
                const f = resizedFile as File;
                f.arrayBuffer()
                  .then((data: ArrayBuffer) => {
                    const blobFile = new File([data], file.name, {
                      type: resizedFile.type,
                      lastModified: +new Date(),
                    });
                    resolve(blobFile);
                  })
                  .catch(() => {
                    resolve(file);
                  });
              }
              resolve(resizedFile);
            },
            outputType || "file"
          );
        });
      }
      return file as File;
    })
  );
};
