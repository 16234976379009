import { SupplierDetails } from "./supplier";

export enum PriceListUOM {
  LM = "lm",
  // MM = "mm",
  M2 = "m2",
  QTY = "qty",
}

export type PriceList = {
  _id: string;
  name: string;
  createdAt: Date;
  supplier: SupplierDetails | null;
  categorisedItems: PriceListCategory[] | null;
  items?: PriceListItem[];
};

export type PriceListCategory = {
  _id: string;
  name: string;
  items?: PriceListItem[];
};

export type PriceListItem = {
  _id?: string;
  sku: string;
  cost: number;
  cost_inc?: number;
  name: string;
  UOM: PriceListUOM;
  category?: PriceListCategory;
  __typename: string;
};

export type AddPriceListPayload = {
  _id?: string;
  name: string;
  supplier: string | null;
};

export type AddPriceListCategoryPayload = {
  _id?: string;
  name: string;
  items?: PriceListItem[];
  method?: string;
};

export type BulkPriceListAdjustPayload = {
  _id?: string;
  amount: number | null;
};

export enum PriceListUpdateMethod {
  INPUT = "input",
  UPLOAD = "upload",
}

export type PriceListColumnMap = PriceListItem & {
  category: string;
};
