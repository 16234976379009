export enum AuthActions {
  GET_USER = "GET_USER",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  GET_USER_FAILED = "GET_USER_FAILED",
  SIGN_UP = "SIGN_UP",
  SIGN_UP_FAILED = "SIGN_UP_FAILED",

  SIGN_IN = "SIGN_IN",
  SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS",
  SIGN_IN_FAILED = "SIGN_IN_FAILED",
  UPDATE_PROFILE = "UPDATE_PROFILE",

  LOGOUT = "LOGOUT",

  GET_SUBCONTRACTOR_USER = "GET_SUBCONTRACTOR_USER",
}
