import { MapField } from "../../../../components/import-mapper/types";
import { PriceListColumnMap } from "../../../../models/price-list";

export const FIELD_MAP_PRICE_LIST: MapField<PriceListColumnMap>[] = [
  {
    fieldKey: "category",
    label: "priceList.category",
  },
  {
    fieldKey: "sku",
    label: "priceList.sku",
  },
  {
    fieldKey: "name",
    label: "priceList.name",
    required: true,
  },
  {
    fieldKey: "cost",
    label: "priceList.cost",
    uniqueGroup: "costing",
  },
  {
    fieldKey: "cost_inc",
    label: "priceList.costInc",
    uniqueGroup: "costing",
  },
  {
    fieldKey: "UOM",
    label: "priceList.uom",
    required: true,
  },
];
