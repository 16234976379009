import React from "react";
import { Form } from "react-bootstrap";
import FormFieldWarning from "./form-field-warning";

export type FormFieldProps = {
  name: string;
  label?: string;
  className?: string;
  error?: string;
  warningMessage?: string;
  touched?: boolean;
  hint?: string;
};

const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  error,
  hint,
  className,
  children,
  warningMessage,
}) => {
  return (
    <Form.Group className={className} controlId={name}>
      {label && <Form.Label className="form-input-label">{label}</Form.Label>}
      {children}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      {hint && !error && <Form.Text>{hint}</Form.Text>}
      {warningMessage && !error && (
        <Form.Text>
          <FormFieldWarning text={warningMessage} />
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default FormField;
