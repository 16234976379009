import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { chain } from "lodash";
import { FormikProps } from "formik";
import CreateEntityModal from "../../modals/create-entity";
import {
  BulkUpdateSchedulePayload,
  ScheduleBulkAction,
  ScheduleMoveDirection,
  ScheduleMovePeriod,
} from "../../../models/job";
import createRescheduleSchema from "./createRescheduleSchema.schema";
import { createRescheduleFields } from "./utils";
import CardTable from "../../dashboard/table-card/CardTable";
import { parseDate } from "../../../utils/date";
import "./styles.scss";

type RescheduleTaskModalProps = {
  onSubmit: (data: BulkUpdateSchedulePayload) => void;
};

export type RescheduleTaskModalRef = {
  show: (show: boolean, rows?: any) => void;
};

const RescheduleTaskModal: React.FC<RescheduleTaskModalProps> = (
  props,
  ref
) => {
  const { onSubmit } = props;
  const [show, setShow] = React.useState(false);
  const [rows, setRows] = React.useState<any>([]);

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    show: (show: boolean, rows?: any) => {
      setShow(show);
      setRows(rows);
    },
  }));

  const getRescheduledDate = (date: string, formikProps: FormikProps<any>) => {
    const { move } = formikProps.values;
    const { moveNumber, period, direction } = move;
    if (direction === ScheduleMoveDirection.FORWARD) {
      return parseDate(date)
        .add(Math.abs(moveNumber), period)
        .format("D/M/YYYY");
    }
    return parseDate(date)
      .subtract(Math.abs(moveNumber), period)
      .format("D/M/YYYY");
  };

  const renderSample = React.useCallback(
    (formikProps: FormikProps<any>) => {
      console.log("sample", rows, formikProps.values);
      const { move } = formikProps.values;
      if (!move.period || !move.moveNumber || !move.direction) return null;
      const tableData = {
        columns: [
          {
            valueKey: "name",
            title: t("schedule.name"),
          },
          {
            valueKey: "startDate",
            title: t("schedule.startDate"),
          },
          {
            valueKey: "rescheduledStartDate",
            title: t("schedule.rescheduledStartDate"),
          },
        ],
        rows: chain(rows)
          .take(5)
          .map((row) => ({
            cells: {
              name: row.name,
              startDate: row.startDate,
              rescheduledStartDate: getRescheduledDate(
                row.startDate,
                formikProps
              ),
            },
          }))
          .value(),
      };

      return (
        <>
          <h4 className="pt-5">{t("schedule.reschedulePreview")}</h4>
          <CardTable table={tableData} />
        </>
      );
    },
    [rows]
  );

  const handleSubmit = React.useCallback(
    (values: BulkUpdateSchedulePayload) => {
      const { move } = values;
      if (!move || !move.period || !move.moveNumber || !move.direction)
        return null;
      return onSubmit(values);
    },
    []
  );

  return (
    <CreateEntityModal
      validationSchema={createRescheduleSchema(t)}
      className="reschedule-tasks"
      title={t("schedule.rescheduleItems")}
      show={show}
      data={{
        action: ScheduleBulkAction.RESCHEDULE,
        move: {
          moveNumber: 0,
          period: ScheduleMovePeriod.DAYS,
          direction: ScheduleMoveDirection.FORWARD,
        },
      }}
      onSubmit={handleSubmit}
      onClose={() => setShow(false)}
      fields={createRescheduleFields(t)}
      submitText={t("schedule.reschedule")}
    >
      {(formikProps) => renderSample(formikProps)}
    </CreateEntityModal>
  );
};

export default forwardRef(RescheduleTaskModal);
