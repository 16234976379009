import { gql } from "@apollo/client";
import { CLAIM_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_CLAIM = gql`
  mutation JobCreateUpdateProgressClaim(
    $jobId: ID
    $progressClaim: JobProgressClaimInput
  ) {
    jobCreateUpdateProgressClaim(jobId: $jobId, progressClaim: $progressClaim) {
      ...ProgressClaimFragment
    }
  }
  ${CLAIM_FRAGMENT}
`;

export const SEND_CLAIM = gql`
  mutation JobSendProgressClaim(
    $jobId: ID
    $progressClaimId: ID
    $message: JobSendEmailInput
  ) {
    jobSendProgressClaim(
      jobId: $jobId
      progressClaimId: $progressClaimId
      message: $message
    ) {
      _id
      status
    }
  }
`;

export const RECEIVE_CLAIM = gql`
  mutation(
    $jobId: ID!
    $progressClaimId: ID!
    $received: JobProgressClaimReceivedInput
  ) {
    jobReceiveProgressClaim(
      jobId: $jobId
      progressClaimId: $progressClaimId
      received: $received
    ) {
      ...ProgressClaimFragment
    }
  }
  ${CLAIM_FRAGMENT}
`;

export const DELETE_CLAIM = gql`
  mutation JobDeleteProgressClaim($jobId: ID, $progressClaimId: ID) {
    jobDeleteProgressClaim(jobId: $jobId, progressClaimId: $progressClaimId) {
      _id
      is_deleted
    }
  }
`;

export const DELETE_CLAIM_RECEIPT = gql`
  mutation($jobId: ID!, $progressClaimId: ID!, $receiptId: ID!) {
    jobDeleteProgressClaimReceipt(
      jobId: $jobId
      progressClaimId: $progressClaimId
      receiptId: $receiptId
    ) {
      ...ProgressClaimFragment
    }
  }
  ${CLAIM_FRAGMENT}
`;
