import { gql } from "@apollo/client";

export const BUSINESSES_DETAILS_FRAGMENT = gql`
  fragment BusinessDetailsRequest on Business {
    _id
    name
    abn
    phone_number
    email
    users {
      _id
      first_name
      last_name
      email
      role
      createdAt
    }
    subscriptionPlan {
      _id
      name
      price
      limit
    }
    subscriptionEndDate
  }
`;
