import React, { useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { map } from "lodash";
import moment from "moment";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";

import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/admin-navigation-items";
import {
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";

import { Business, BusinessUser } from "../../../graphql/types/models/business";
import DetailsCard, {
  DetailsCardAction,
} from "../../../components/dashboard/details-card";
import { DetailsCardDataItem } from "../../../components/dashboard/details-card/DetailsCardList";
import TableCard from "../../../components/dashboard/table-card";

import { WB_LIST_GET_BUSINESS_BY_ID } from "../../../graphql/queries/wb-admin/business/queries";
import { WB_ADMIN_IMPERSONATE_USER } from "../../../graphql/queries/wb-admin/auth/mutations";
import { ImpersonateUserResponse } from "../../../graphql/types/models/auth";
import ExtendTrialModal from "../../../components/admin/extend-trial-modal";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";

type Params = {
  id: string;
};

type BusinessUserTableItem = BusinessUser & {};

type BusinessDetailsContainerProps = RouteComponentProps<Params> &
  DashboardContextValue;

export type BusinessDetalsResponse = {
  business: Business;
};

const BusinessDetailsContainer: React.FC<BusinessDetailsContainerProps> = ({
  match,
  navigationContext,
  setNavigationContext,
}) => {
  const { t } = useTranslation();
  const [businessName, setBusinessName] = useState("");
  const extendTrialRef = React.useRef<ModalDisplayRef>(null);
  const { id: businessId } = match.params;

  const { data, loading } = useQuery<BusinessDetalsResponse>(
    WB_LIST_GET_BUSINESS_BY_ID,
    {
      variables: {
        businessId,
      },
    }
  );

  const [impersonateUser] = useMutation<ImpersonateUserResponse>(
    WB_ADMIN_IMPERSONATE_USER,
    {
      onCompleted: ({ impersonateUser }) => {
        const { hash, url } = impersonateUser;
        window.open(`${url}/sign-in/?oth=${hash}`, "_blank");
      },
    }
  );

  useEffect(() => {
    if (data?.business) setBusinessName(data.business.name);
  }, [data, setBusinessName]);

  const tableData = React.useMemo<TableCardData<BusinessUserTableItem>>(() => {
    return {
      columns: [
        {
          valueKey: "first_name",
          title: t("authentication.firstName"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "last_name",
          title: t("authentication.lastName"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "email",
          title: t("team.email"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "role",
          title: t("team.role"),
          formatValue: (row: any, column: any, value: string) =>
            t(`team.${value}`),
        },
        {
          valueKey: "createdAt",
          title: t("common.createdAt"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).toLocaleString(),
        },
      ],
      rows: map(data?.business.users, (item: BusinessUser) => ({
        cells: {
          ...item,
        },
      })),
    };
  }, [data, t]);

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<BusinessUserTableItem>) => {},
    []
  );

  React.useEffect(() => {
    return () => {
      setNavigationContext({
        ...navigationContext,
        business: undefined,
      });
    };
  }, []);

  const handleImpersonateUser = React.useCallback((user: BusinessUser) => {
    if (!user?._id) return;
    impersonateUser({
      variables: {
        userId: user._id,
      },
    });
  }, []);

  const handleExtendTrial = React.useCallback(() => {}, []);

  const detailsCardData: DetailsCardDataItem[] = React.useMemo(() => {
    const business = data?.business;

    if (loading || !data) {
      return [];
    }

    return [
      {
        icon: "person",
        title: t("authentication.businessName"),
        text: business?.name || t("common.na"),
      },
      {
        icon: "local_phone",
        title: t("authentication.phoneNumber"),
        text: business?.phone_number || t("common.na"),
      },
      {
        icon: "badge",
        title: t("businesses.planName"),
        text: business?.subscriptionPlan?.name || t("common.na"),
      },
      {
        icon: "calendar_today",
        title: t("businesses.subscriptionEndDate"),
        text: business?.subscriptionEndDate
          ? moment(business.subscriptionEndDate).format("Do MMM YYYY")
          : t("common.na"),
      },
    ];
  }, [data, loading]);

  const tableRowActions: TableRowActionData<BusinessUser>[] = React.useMemo(
    () => [
      {
        icon: "login",
        id: "impersonate",
        onClick: handleImpersonateUser,
        title: t("authentication.impersonateUser"),
      },
    ],
    []
  );

  const detailsCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("wbAdmin.extendTrial"),
        onClick: () => extendTrialRef.current?.show(true),
        className: "button info",
      },
    ],
    [t]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={businessName} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.ADMIN_SECTION.DETAILS} />

      <Row>
        <Col lg={4} xs={12} className="pb-sm-5 pb-lg-0">
          <DetailsCard
            fullHeight
            title={t("common.details")}
            data={detailsCardData}
            actions={detailsCardActions}
          />
        </Col>
        <Col lg={8} xs={12} className="pb-sm-5 pb-lg-0">
          <TableCard
            isDataLoading={loading}
            data={tableData}
            alignEnd
            onRowClick={handleRowClick}
            title={businessName}
            rowActions={tableRowActions}
          />
        </Col>
      </Row>
      {data?.business?._id && (
        <ExtendTrialModal ref={extendTrialRef} business={data.business} />
      )}
    </Container>
  );
};

export default withRouter(withDashboardContext(BusinessDetailsContainer));
