import { gql } from "@apollo/client";

export const CLIENT_SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!, $rememberMe: Boolean!) {
    clientLogin(email: $email, password: $password, remember_me: $rememberMe) {
      _id
      tokenType
      token
    }
  }
`;

export const CLIENT_FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    clientForgotPassword(email: $email) {
      _id
      email
    }
  }
`;

export const CLIENT_RESET_PASSWORD = gql`
  mutation($resetHash: String!, $password: String!) {
    clientResetPassword(resetHash: $resetHash, password: $password) {
      _id
      email
    }
  }
`;
