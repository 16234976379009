import React, { useState } from "react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import CreateEntityModal from "../../modals/create-entity";
import UpdateEntityModal from "../../modals/update-entity";
import { CreateTodoListPayload } from "../../../models/task";
import { createLeadField } from "./utils";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createLeadSchema from "./CreateLead.schema";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { CreateUpdateLead, LeadStage } from "../../../models/leads";
import { ContactListResponse } from "../../../graphql/types/models/client";
import { LIST_CONTACTS } from "../../../graphql/queries/client/queries";
import CreateClient, {
  CreateClientRef,
} from "../../../containers/modals/create-client";
import { handlePlaceSelect } from "../../../utils/place";

type CreateUpdateLeadModalProps = {
  data: CreateUpdateLead | null;
  listStages: LeadStage[];
  show: boolean;
  onSubmit: (data: CreateUpdateLead) => void;
  onClose: () => void;
  onDelete?: () => void | Promise<any>;
  showDeleteButton?: boolean;
};

const CreateUpdateLeadModal: React.FC<CreateUpdateLeadModalProps> = (props) => {
  const {
    data,
    show,
    onSubmit,
    onClose,
    listStages,
    onDelete,
    showDeleteButton,
  } = props;
  const { t } = useTranslation();

  const [fields, setFields] = useState<
    GenericFormFields<CreateTodoListPayload>
  >({});
  const createClientRef = React.useRef<CreateClientRef>(null);
  const updateModalRef = React.useRef<any>(null);
  const { data: contactsList, loading: contactsLoading } = useQuery<
    ContactListResponse
  >(LIST_CONTACTS);
  const contacts = React.useMemo(() => contactsList?.listContacts || [], [
    contactsList,
  ]);

  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);
  const teamMembers = React.useMemo(() => teamData?.listMembers || [], [
    teamData,
  ]);

  const openClientModal = React.useCallback(
    () => createClientRef.current?.show(true),
    [createClientRef]
  );

  React.useEffect(() => {
    setFields(
      createLeadField(
        t,
        teamMembers,
        listStages || [],
        contacts,
        openClientModal,
        handlePlaceSelect
      )
    );
  }, [contacts, listStages, openClientModal, t, teamData, teamMembers]);

  const handleSubmit = React.useCallback(
    (data: CreateUpdateLead, helpers?: FormikHelpers<any>) => {
      onSubmit && onSubmit({ ...data, budget: Number(data.budget) });
      helpers?.resetForm();
    },
    [onSubmit]
  );

  const handleSetNewClient = React.useCallback(
    (clientId: string) => {
      if (clientId) {
        const contactsValue = updateModalRef.current?.values.contacts;
        updateModalRef.current?.setFieldValue("contacts", [
          ...contactsValue,
          clientId,
        ]);
      }
    },
    [updateModalRef]
  );

  return (
    <>
      {data ? (
        <UpdateEntityModal<CreateUpdateLead>
          validationSchema={createLeadSchema(t)}
          showDeleteButton={showDeleteButton}
          title={t("leads.updateLead")}
          data={{
            stage: data.stage,
            name: data.name,
            description: data.description,
            expectedCloseDate: data.expectedCloseDate,
            budget: data.budget,
            assignedTo: data.assignedTo,
            contacts: data.contacts,
            address: data?.address || "",
            city: data?.city || "",
            state: data?.state || "",
            postcode: data?.postcode || "",
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
          onDelete={onDelete}
        />
      ) : (
        <CreateEntityModal<CreateUpdateLead>
          validationSchema={createLeadSchema(t)}
          formikRef={updateModalRef}
          title={t("leads.createNewLead")}
          data={{
            stage: listStages[0]?._id ?? "",
            name: "",
            description: "",
            expectedCloseDate: "",
            budget: 0,
            assignedTo: [],
            contacts: [],
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      )}
      <CreateClient
        ref={createClientRef}
        onCreatedRecord={handleSetNewClient}
      />
    </>
  );
};

export default CreateUpdateLeadModal;
