import { map } from "lodash";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import { LeadStage } from "../../../models/leads";
import { getAllLeads, prepareLeadsToTable } from "../utils";

type LeadsTableProps = {
  listStages: LeadStage[];
};

type LeadsTableData = {
  _id: string;
  stage: string;
  name: string;
  contact: string;
  assignee: string;
  budget: string;
  closeDay: string;
  labels: string;
};

const LeadsTable: FC<LeadsTableProps> = ({ listStages }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const leadsTable = React.useMemo<LeadsTableData[]>(() => {
    const allLeads = getAllLeads(listStages);

    return prepareLeadsToTable(allLeads);
  }, [listStages]);

  const contactsTableData = React.useMemo<TableCardData<LeadsTableData>>(() => {
    return {
      columns: [
        {
          valueKey: "stage",
          title: t("leads.stage"),
          sortable: true,
        },
        {
          valueKey: "name",
          title: t("placeholders.name"),
          sortable: true,
        },
        {
          valueKey: "contact",
          title: t("placeholders.contact"),
          sortable: true,
        },
        {
          valueKey: "assignee",
          title: t("leads.assignee"),
          sortable: true,
        },
        {
          valueKey: "budget",
          title: t("placeholders.budget"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "closeDay",
          title: t("leads.expectedCloseDate"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            value ? moment(value).format("Do MMM YYYY") : "",
        },
        {
          valueKey: "labels",
          title: t("leads.labels"),
          sortable: true,
        },
      ],
      rows: map(leadsTable, (lead) => ({
        cells: lead,
      })),
    };
  }, [leadsTable, t]);

  const handleClientTableRowClick = React.useCallback(
    (row: TableCardDataRow<LeadsTableData>) => {
      history.push(`/leads/${row.cells._id}`);
    },
    [history]
  );

  return (
    <TableCard
      tableId="leads-list"
      data={contactsTableData}
      onRowClick={handleClientTableRowClick}
    />
  );
};

export default LeadsTable;
