import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FormikHelpers, FormikProps } from "formik";
import { useQuery } from "@apollo/client";
import CreateEntityModal from "../../modals/create-entity";
import UpdateEntityModal from "../../modals/update-entity";
import {
  GenericFormFields,
  FormikPropGetSetValues,
  FormikPropSetValues,
} from "../../generic-form/GenericFormBody";
import createTimesheetSchema from "./CreateTimesheet.schema";
import { CreateUpdateTimesheetPayload } from "../../../models/timesheet";
import { createTimesheetField } from "./utils";
import { TimesheetTableItem } from "../../../models/timesheet";
import {
  CostingAllocationType,
  useJobCostingAllocate,
} from "../../../hooks/useJobCostingAllocate";
import { useCreateAnother } from "../../../hooks/useCreateAnother";
import { ListMembersResponse } from "../../../graphql/models/members";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { UserPayload } from "../../../graphql/types/models/auth";
import { getMinutesfromBreakDuration } from "../../../utils/options";
import { TeamMember, UserRoles } from "../../../models/team-member";
import { JobTimesheetTableItem } from "../../../models/job-timesheet";
import { useJobs } from "../../../hooks/useJobs";

type CreateUpdateTimesheetModalProps = {
  data: TimesheetTableItem | JobTimesheetTableItem | null;
  show: boolean;
  onSubmit: (
    data: CreateUpdateTimesheetPayload,
    createAnother: boolean
  ) => void;
  user?: UserPayload | null;
  onClose: () => void;
  currentJobId?: string;
};

const CreateUpdateTimesheetModal: React.FC<CreateUpdateTimesheetModalProps> = (
  props
) => {
  const { data, show, onSubmit, onClose, user, currentJobId } = props;

  const { t } = useTranslation();

  const { shouldCreateAnother, renderCreateAnother } = useCreateAnother(
    t("timesheets.createAnotherTimesheet")
  );

  const [fields, setFields] = React.useState<
    GenericFormFields<CreateUpdateTimesheetPayload>
  >({});

  const [jobId, setJobId] = React.useState(currentJobId ?? data?.job._id ?? "");
  const [initData, setInitData] = React.useState<CreateUpdateTimesheetPayload>({
    userId: user?._id || "",
    job: "",
    task: "",
    note: "",
    startDate: moment()
      .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
      .toISOString(),
    endDate: moment()
      .set({ hour: 16, minute: 0, second: 0, millisecond: 0 })
      .toISOString(),
    breakDuration: "",
    costingCategoryName: "",
    costingItemId: "",
  });
  const formikRef = React.useRef<any>();

  const {
    categoryList,
    openAllocateCosting,
    getAllocateProps,
    handleSelectCategory,
    renderSelectCostingModal,
    jobCostingLoading,
  } = useJobCostingAllocate({
    type: CostingAllocationType.Timesheet,
    jobId: jobId ?? currentJobId,
  });

  const { jobs } = useJobs();
  const { data: teamData } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const teamMembers = React.useMemo(() => {
    if (teamData?.listMembers) {
      return teamData.listMembers;
    }
    if (user) {
      return [user as TeamMember];
    }
    return [];
  }, [user, teamData]);

  const isAdmin = user?.role === UserRoles.builderadmin;
  const isEdit = isAdmin ? false : !!data?._id;

  const handleJobChange = React.useCallback(
    (value: string, formikProps: FormikPropSetValues) => {
      if (value && (jobId || currentJobId) !== value) {
        formikProps.setFieldValue("costingCategoryName", "");
        formikProps.setFieldValue("costingItemId", "");
        setJobId(value);
      }
    },
    [jobId, currentJobId]
  );

  const handleStartDateChange = React.useCallback(
    (value: string, formikProps: FormikPropGetSetValues) => {
      const { values, setFieldValue } = formikProps;
      const startDate = moment(value);
      const endDate = moment(values.endDate);
      if (
        !values.endDate ||
        moment(endDate).format("YYYY-DD-MM") !==
          startDate.format("YYYY-DD-MM") ||
        (values.endDate && endDate.isSameOrBefore(startDate))
      ) {
        setFieldValue("endDate", moment(value).add(1, "hour").toISOString());
      }
    },
    []
  );

  const resetForm = React.useCallback(() => {
    setFields(
      createTimesheetField(
        t,
        isEdit,
        handleStartDateChange,
        categoryList,
        openAllocateCosting,
        handleSelectCategory,
        getAllocateProps,
        handleJobChange,
        teamMembers,
        jobs ? jobs : [],
        currentJobId ?? jobId,
        jobCostingLoading
      )
    );
  }, [
    jobs,
    t,
    isEdit,
    handleStartDateChange,
    categoryList,
    openAllocateCosting,
    handleSelectCategory,
    getAllocateProps,
    handleJobChange,
    teamMembers,
    jobId,
    currentJobId,
    jobCostingLoading,
  ]);

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleSubmit = React.useCallback(
    (data: CreateUpdateTimesheetPayload, helpers?: FormikHelpers<any>) => {
      onSubmit && onSubmit(data, shouldCreateAnother);
      helpers?.resetForm();

      if (shouldCreateAnother) setInitData({ ...data, task: "" });
    },
    [shouldCreateAnother, onSubmit]
  );

  return (
    <>
      {renderSelectCostingModal()}
      {data ? (
        <UpdateEntityModal
          formikRef={formikRef}
          validationSchema={createTimesheetSchema(t)}
          title={t("timesheets.updateTimesheet")}
          data={{
            job: currentJobId ?? data.job?._id,
            userId: data?.user?._id,
            task: data.task,
            note: data.note,
            startDate: data.startTime,
            endDate: data.endTime,
            breakDuration:
              getMinutesfromBreakDuration(data?.breakDuration as string) || "",
            costingCategoryName: data.costingCategoryName,
            costingItemId: data.costingItemId,
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      ) : (
        <CreateEntityModal
          formikRef={formikRef}
          validationSchema={createTimesheetSchema(t)}
          title={t("timesheets.addTimesheet")}
          data={initData}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={onClose}
          leftFooterRenderer={renderCreateAnother}
        />
      )}
    </>
  );
};

export default CreateUpdateTimesheetModal;
