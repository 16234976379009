import { gql } from "@apollo/client";

export const LIST_JOB_MEMBERS = gql`
  query listJobMembers($jobId: ID!) {
    listJobMembers(jobId: $jobId) {
      _id
      last_name
      first_name
      email
      role
      profileImage {
        url
      }
    }
  }
`;
