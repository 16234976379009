import React from "react";
import { components, MenuProps } from "react-select";

type AutocompleteMenuProps = MenuProps<any> & {};

const AutocompleteMenu: React.FC<AutocompleteMenuProps> = (props) => {
  return (
    <components.Menu {...props} className="autocomplete-menu">
      {props.children}
    </components.Menu>
  );
};

export default AutocompleteMenu;
