import React from "react";
import moment from "moment";
import { compact, filter, get, map } from "lodash";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import classNames from "classnames";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableCardDataRow,
  TableDropdownItem,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import CreateSalesQuoteModal, {
  CreateSalesQuoteModalRef,
} from "../../../components/quotes/create-sales-quote-modal";
import CreateJobModal from "../../../components/job/create-job-modal";
import {
  QuoteArchiveStatus,
  QuoteStatus,
  SalesQuote,
} from "../../../models/salesQuote";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { ContactDetail } from "../../../graphql/types/models/client";
import {
  ARCHIVE_SALES_QUOTE,
  DELETE_SALES_QUOTE,
  UNARCHIVE_SALES_QUOTE,
} from "../../../graphql/queries/quote/mutations";
import { LIST_QUOTES } from "../../../graphql/queries/quote/queries";
import {
  ArchiveSalesQuoteResponse,
  DeleteSalesQuoteResponse,
  QuoteListResponse,
  UnarchiveSalesQuoteResponse,
} from "../../../graphql/types/models/quote";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import {
  formatQuoteNumber,
  getFullAddress,
  getFullName,
} from "../../../utils/text";
import DashboardActionHeader from "../../../components/dashboard/table-card/DashboardActionHeader";
import { useEditEstimation } from "../../../hooks/queries/useEditEstimation";
import { useSalesQuotesQuery } from "../../../hooks/queries/useSalesQuotesQuery";
import "./styles.scss";
import { notify } from "../../../components/notification";

type QuotesListContainerProps = RouteComponentProps &
  DashboardContextValue & {
    archiveStatus?: QuoteArchiveStatus;
  };

type SalesQuoteTable = SalesQuote & {
  contactName: string;
  jobName?: string;
};

const QuotesListContainer: React.FC<QuotesListContainerProps> = ({
  history,
  setSearchOptions,
  archiveStatus,
}) => {
  const { t } = useTranslation();
  const [textFilter, setTextFilter] = React.useState("");
  const createJobRef = React.useRef<ModalDisplayRef>(null);
  const createRef = React.useRef<CreateSalesQuoteModalRef>(null);
  const deleteRef = React.useRef<ConfirmDialogRef>(null);
  const archiveRef = React.useRef<ConfirmDialogRef>(null);
  const unarchiveRef = React.useRef<ConfirmDialogRef>(null);
  const isArchived = archiveStatus === QuoteArchiveStatus.ARCHIVED;

  const { salesQuotes, loading, refetchSalesQuoteList } = useSalesQuotesQuery(
    archiveStatus
  );

  const {
    handleQuoteEdit,
    setSelectedSalesQuote,
    selectedSalesQuote,
    renderUpdateSalesQuoteModal,
    renderCopySalesQuoteModal,
    contactsLoading,
    clients,
    handleQuoteCopy,
  } = useEditEstimation();

  const [deleteSalesQuote, { loading: deletingSalesQuote }] = useMutation<
    DeleteSalesQuoteResponse
  >(DELETE_SALES_QUOTE, {
    update(cache, { data }) {
      const deleteSalesQuote = data?.deleteSalesQuote;

      const cacheData: QuoteListResponse | null = cache.readQuery({
        query: LIST_QUOTES,
        variables: {
          filter: {
            is_archived: isArchived,
          },
        },
      });

      if (!cacheData || !deleteSalesQuote) {
        return;
      }
      cache.writeQuery({
        query: LIST_QUOTES,
        variables: {
          filter: {
            is_archived: isArchived,
          },
        },
        data: {
          listSalesQuotes: filter(
            cacheData.listSalesQuotes,
            (q) => q._id !== deleteSalesQuote._id
          ),
        },
      });
    },
  });

  const [
    archiveSalesQuote,
    { loading: archiveSalesQuoteLoading },
  ] = useMutation<ArchiveSalesQuoteResponse>(ARCHIVE_SALES_QUOTE, {
    onCompleted: () => {
      refetchSalesQuoteList();
      notify({
        title: t("quotes.archiveQuote"),
        content: t("quotes.success.archiveQuote"),
      });
    },
    onError: (e) => {
      notify({
        title: t("quotes.archiveQuote"),
        content: get(e, "message", t("quotes.errors.archiveQuote")),
        error: true,
      });
    },
  });

  const [
    unarchiveSalesQuote,
    { loading: unarchiveSalesQuoteLoading },
  ] = useMutation<UnarchiveSalesQuoteResponse>(UNARCHIVE_SALES_QUOTE, {
    onCompleted: ({ unarchiveSalesQuote }) => {
      notify({
        title: t("quotes.unarchiveQuote"),
        content: t("quotes.success.unarchiveQuote"),
      });
      history.push(`/quotes/${unarchiveSalesQuote?._id}`);
    },
    onError: (e) => {
      notify({
        title: t("quotes.unarchiveQuote"),
        content: get(e, "message", t("quotes.errors.unarchiveQuote")),
        error: true,
      });
    },
  });

  const handleSearch = React.useCallback(
    (text: string) => setTextFilter(text),
    []
  );

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("common.search"),
      options: [],
      onAutocomplete: handleSearch,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [t, setSearchOptions, handleSearch]);

  const filteredQuotes = React.useMemo(
    () =>
      salesQuotes?.filter((quote) => {
        const dataString =
          quote.name +
          getFullName(quote.contact) +
          getFullAddress(quote.contact);
        return dataString.toUpperCase().includes(textFilter.toUpperCase());
      }),
    [salesQuotes, textFilter]
  );

  const handleQuoteDelete = React.useCallback((quote?: SalesQuote) => {
    if (quote) {
      setSelectedSalesQuote(quote);
      deleteRef.current?.show(true);
    }
  }, []);

  const handleQuoteArchive = React.useCallback((quote?: SalesQuote) => {
    if (quote) {
      archiveRef.current?.show(true, () => {
        archiveSalesQuote({
          variables: {
            salesQuoteId: quote._id,
          },
        });
      });
    }
  }, []);
  const handleQuoteUnarchive = React.useCallback((quote?: SalesQuote) => {
    if (quote) {
      archiveRef.current?.show(true, () => {
        unarchiveSalesQuote({
          variables: {
            salesQuoteId: quote._id,
          },
        });
      });
    }
  }, []);

  const handleQuoteConvertJob = React.useCallback(
    (quote?: SalesQuote) => {
      if (quote) {
        setSelectedSalesQuote(quote);
        createJobRef.current?.show(true);
      }
    },
    [setSelectedSalesQuote, createJobRef]
  );

  const handleDeleteSubmit = React.useCallback(() => {
    if (selectedSalesQuote) {
      deleteSalesQuote({
        variables: {
          salesQuoteId: selectedSalesQuote._id,
        },
      });
    }
  }, [selectedSalesQuote, deleteSalesQuote]);

  const handleJobClick = React.useCallback(
    (
      row: any,
      column: any,
      value: string,
      e?: React.MouseEvent<HTMLTableCellElement>
    ) => {
      const jobId = row?.cells?.jobId;
      if (jobId) {
        history.push(`/jobs/${jobId}`);
        if (e) {
          e.stopPropagation();
        }
      } else {
        return;
      }
    },
    [history]
  );

  const quotesTableData = React.useMemo<TableCardData<SalesQuoteTable>>(() => {
    return {
      columns: [
        {
          valueKey: "number",
          title: t("common.number"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            formatQuoteNumber(value, "Q"),
        },
        {
          valueKey: "name",
          title: t("common.name"),
          sortable: true,
          // className: "estimation-name",
          tooltip: (row) => row.name,
        },
        {
          valueKey: "total",
          title: t("common.quoteTotalInc"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "createdAt",
          title: t("common.createdOn"),
          sortable: true,
          formatValue: (row: any, column: any, value: Date) =>
            moment(value).format("Do MMM YYYY"),
        },
        {
          valueKey: "contactName",
          title: t("common.client"),
          sortable: true,
          formatValue: (row: any, column: any, value: ContactDetail) => value,
        },
        {
          valueKey: "address",
          title: t("contacts.address"),
          className: "cell-truncate",
          sortable: true,
          tooltip: (row) => getFullAddress(row),
          formatValue: (row: any, column: any, value: ContactDetail) =>
            getFullAddress(row?.cells),
        },
        {
          valueKey: "status",
          title: t("common.status"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => (
            <span
              className={classNames("badge", {
                "badge-info": value === QuoteStatus.NON_CONTRACTED,
                "badge-success": value === QuoteStatus.APPROVED,
                "badge-warning": value === QuoteStatus.SENT,
                "badge-secondary": value === QuoteStatus.DRAFT,
              })}
            >
              {t(`quotes.statuses.${value}`)}
            </span>
          ),
        },
        {
          valueKey: "jobName",
          title: t("tasks.job"),
          className: "cell-truncate",
          sortable: true,
          tooltip: (row) => row?.jobName || "",
          onClick: handleJobClick,
        },
      ],
      rows: map(filteredQuotes, (quote) => ({
        cells: {
          ...quote,
          jobName: quote?.job?.name,
          jobId: quote?.job?._id,
          contactName: getFullName(quote.contact),
        },
      })),
    };
  }, [t, filteredQuotes]);

  const dropdownActions: TableDropdownItem<SalesQuote>[] = React.useMemo(
    () => [
      {
        icon: "edit",
        outlined: true,
        id: "edit",
        label: t("quotes.editQuote"),
        onClick: handleQuoteEdit,
        shouldRender: (row) =>
          row.status !== "APPROVED" && !row.is_locked && !isArchived,
      },
      {
        icon: "content_copy",
        outlined: true,
        id: "copy",
        label: t("quotes.copyQuote"),
        onClick: handleQuoteCopy,
      },
      {
        icon: "add_business",
        outlined: true,
        id: "convert",
        label: t("quotes.convertToJob"),
        onClick: handleQuoteConvertJob,
        shouldRender: (row) => !row.is_locked && !isArchived,
      },
      {
        icon: "archive",
        outlined: true,
        id: "archive",
        label: t("quotes.archiveQuote"),
        onClick: handleQuoteArchive,
        shouldRender: (row) => !row.is_archived,
      },
      {
        icon: "unarchive",
        outlined: true,
        id: "unarchive",
        label: t("quotes.unarchiveQuote"),
        onClick: handleQuoteUnarchive,
        shouldRender: (row) => row.is_archived,
      },
      {
        icon: "delete",
        outlined: true,
        id: "remove",
        label: t("quotes.deleteQuote"),
        onClick: handleQuoteDelete,
        shouldRender: (row) => row.status !== "APPROVED",
      },
    ],
    [
      t,
      isArchived,
      handleQuoteEdit,
      handleQuoteConvertJob,
      handleQuoteDelete,
      handleQuoteArchive,
      handleQuoteUnarchive,
    ]
  );

  const quotesTableRowActions: TableRowActionData<SalesQuote>[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "more",
        options: dropdownActions,
      },
    ],
    [handleQuoteConvertJob, handleQuoteDelete, handleQuoteEdit]
  );

  const handleQuoteCreateClick = React.useCallback(() => {
    createRef.current?.show(true);
  }, [createRef]);

  const handleQuoteClick = React.useCallback(
    (row: TableCardDataRow<SalesQuote>) => {
      history.push(`/quotes/${row.cells._id}`);
    },
    [history]
  );

  return (
    <div>
      <SetNavigationRoute
        routeId={
          isArchived
            ? NAVIGATION_ROUTES.QUOTES_SECTION.ARCHIVED_QUOTES
            : NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES
        }
      />
      <Helmet title={t("navigation.quotesSection.estimations")} />
      {!contactsLoading && clients && <CreateSalesQuoteModal ref={createRef} />}
      {renderUpdateSalesQuoteModal()}
      {renderCopySalesQuoteModal()}
      <CreateJobModal ref={createJobRef} salesQuote={selectedSalesQuote} />

      <ConfirmDialog
        ref={deleteRef}
        disabled={deletingSalesQuote}
        title={t("quotes.deleteQuote")}
        onSubmit={handleDeleteSubmit}
      >
        <span className="field-text">{t("quotes.deleteQuoteMessage")}</span>
      </ConfirmDialog>
      <ConfirmDialog
        ref={archiveRef}
        disabled={archiveSalesQuoteLoading}
        title={t("quotes.archiveQuote")}
      >
        <span className="field-text">{t("quotes.archiveQuoteMessage")}</span>
      </ConfirmDialog>
      <ConfirmDialog
        ref={unarchiveRef}
        disabled={unarchiveSalesQuoteLoading}
        title={t("quotes.unarchiveQuote")}
      >
        <span className="field-text">{t("quotes.unarchiveQuoteMessage")}</span>
      </ConfirmDialog>
      {!isArchived && (
        <DashboardActionHeader
          onActionButton={handleQuoteCreateClick}
          className="pb-4"
        />
      )}
      {loading || filteredQuotes?.length ? (
        <TableCard
          tableId="quotes"
          isDataLoading={loading}
          data={quotesTableData}
          onRowClick={handleQuoteClick}
          rowActions={quotesTableRowActions}
          className="overflow-visible"
        />
      ) : (
        <EmptyPlaceholder
          message={
            isArchived
              ? t("quotes.emptyArchivedPlaceholder")
              : t("quotes.emptyPlaceholder")
          }
          buttonText={!isArchived ? t("quotes.createEstimation") : undefined}
          onButtonPress={!isArchived ? handleQuoteCreateClick : undefined}
        />
      )}
    </div>
  );
};

export default withRouter(withDashboardContext(QuotesListContainer));
