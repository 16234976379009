import React from "react";
import Icon from "../../../icons/Icon";

type FormFieldWarningProps = {
  text: string;
};

const FormFieldWarning: React.FC<FormFieldWarningProps> = ({ text }) => {
  return (
    <div className="d-flex align-items-center" style={{ color: "#cf8346" }}>
      <Icon
        style={{ fontSize: "21px" }}
        className="mr-2"
        name="report_problem"
        outlined
      />
      <span>{text}</span>
    </div>
  );
};

export default FormFieldWarning;
