import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient, useMutation } from "@apollo/client";
import { FormikProps } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { get, chain, uniqBy, map, pick, find, trim } from "lodash";
import {
  VariationPayload,
  VariationCostItemPayload,
  VariationStatuses,
} from "../../../models/variations";

import { createVariationsFields } from "./utils";
import {
  GenericFormFields,
  GenericFormTable,
  renderField,
  renderTableForm,
} from "../../generic-form/GenericFormBody";
import ModalForm from "../../modals/modal-form";
import CalculatorModal, { CalcFieldData } from "../../modals/calculator";
import { CreateDiaryPayload } from "../../../models/diary";
import createVariationSchema from "./Variation.schema";
import { CalcBag, Rounding } from "../../calculator/utils";
import ModalTotals from "../../modals/modal-totals";
import { UOMOption } from "../../../utils/types/options";
import { MEASUREMENT_OPTIONS } from "../../../utils/options";
import { CreateUpdateVariationResponse } from "../../../graphql/types/models/job-variation";
import { CREATE_UPDATE_VARIATION } from "../../../graphql/queries/job-variation/mutations";
import { handleVariationAdd } from "../../../graphql/queries/job-variation/utils";
import { ContactDetail } from "../../../graphql/types/models/client";
import { useJobContacts } from "../../../hooks/useJobContacts";
import { useJobQuery } from "../../../hooks/queries/useJobQuery";
import {
  CostingAllocationType,
  useJobCostingAllocate,
} from "../../../hooks/useJobCostingAllocate";
import { TakeoffListItem } from "../../../models/take-off";
import { SearchJobCostingItem } from "../../../models/job";
import {
  findCostingItemFromCache,
  findCostingItemFromCostingsCache,
  searchCostingItem,
} from "../../../graphql/queries/job-costing/utils";
import {
  findPriceItemFromCache,
  searchPriceItem,
} from "../../../graphql/queries/price-list/utils";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import "./styles.scss";

type VariationModalProps = {
  show: boolean;
  onSubmit: (variationId: string | undefined) => void;
  onClose: () => void;
  editMode?: boolean;
  variation?: VariationPayload | null;
  jobId: string | undefined;
  contact?: ContactDetail;
  costingItems?: VariationCostItemPayload[] | null;
  takeoffs?: TakeoffListItem[] | null;
  salesQuoteId?: string | null;
};

const VariationModal: React.FC<VariationModalProps> = (props) => {
  const {
    show,
    onClose,
    onSubmit,
    editMode,
    variation,
    contact,
    jobId,
    costingItems,
    takeoffs,
    salesQuoteId,
  } = props;

  const client = useApolloClient();
  const { contacts } = useJobContacts(jobId);
  const { job } = useJobQuery(jobId);
  const markupValue = React.useMemo(() => job?.markup, [job?.markup]);

  const [items, setItems] = React.useState<SelectOption[]>([]);

  /** GQL integrations */
  const [updateVariation] = useMutation<CreateUpdateVariationResponse>(
    CREATE_UPDATE_VARIATION,
    {
      onCompleted: (createdVariation) => {
        const variation = createdVariation.jobCreateUpdateVariation;
        onSubmit(variation._id);
      },
    }
  );

  const [createVariation] = useMutation<CreateUpdateVariationResponse>(
    CREATE_UPDATE_VARIATION,
    {
      onCompleted: (createdVariation) => {
        const variation = createdVariation.jobCreateUpdateVariation;

        onSubmit(variation._id);
      },
      update: handleVariationAdd(jobId),
    }
  );

  const handleSubmit = React.useCallback(
    (variation: VariationPayload) => {
      const preparedItems = map(variation.items, (item, index) => {
        const preparedItem = {
          ...pick(item, [
            "name",
            "quantity",
            "UOM",
            "wastage",
            "rouding",
            "costingItemId",
            "costingCategoryName",
          ]),
          hasGST: !item.gstFree,
          cost: Number(item.cost) || 0,
          quantity: Number(item.quantity) || 0,
          margin_amount: item.margin_amount
            ? parseFloat(item.margin_amount.toString())
            : 0,
          raw_quantity: item.raw_quantity || item.quantity?.toString(),
          itemNumber: index + 1,
        };

        if (!item.rounding || item.rounding === Rounding.NONE) {
          delete preparedItem.rounding;
        }

        return preparedItem;
      });

      const options = {
        variables: {
          jobId,
          variation: {
            ...variation,
            items: preparedItems,
          },
        },
      };

      if (editMode) {
        return updateVariation(options);
      }

      return createVariation(options);
    },
    [createVariation, jobId, editMode, updateVariation]
  );

  const { t } = useTranslation();

  const [currentUOMOptions, setCurrentUOMOptions] = useState<UOMOption[]>(
    MEASUREMENT_OPTIONS.map((item) => {
      return {
        value: item.value.toString(),
        label: item.label.toString(),
      };
    })
  );

  const [formFields, setFormFields] = useState<
    GenericFormFields<CreateDiaryPayload>
  >({});

  const [showCalculator, setCalculatorVisibility] = useState(false);
  const [calcData, setFieldData] = useState<CalcFieldData>({
    fieldName: "",
  });

  const {
    categoryList: costingCategoryList,
    openAllocateCosting,
    getAllocateProps,
    handleCreateCategory,
    handleSelectCategory,
    renderSelectCostingModal,
  } = useJobCostingAllocate({
    type: CostingAllocationType.PurchaseOrder,
    jobId,
    isTable: true,
  });

  const initialValues: VariationPayload = React.useMemo(() => {
    if (costingItems && costingItems.length) {
      const values: VariationPayload = {
        name: "",
        contact: contact?._id || "",
        date: new Date().toISOString(),
        note: "",
        status: VariationStatuses.DRAFT,
        items: [],
      };

      costingItems.forEach((item) => {
        const data = {
          ...item,
          costingItemId: item?.costingItemId,
          costingCategoryName: item?.costingCategoryName,
          margin_amount: "0",
          gstFree: !item.hasGST,
        };

        values.items.push(data as VariationCostItemPayload);
      });

      return values;
    }

    if (!editMode || !variation) {
      return {
        name: "",
        contact: contact?._id || "",
        date: new Date().toISOString(),
        note: "",
        status: VariationStatuses.DRAFT,
        items: [
          {
            name: "",
            quantity: null,
            raw_quantity: "",
            margin_amount: Number(markupValue) || "0",
            cost: 0,
            UOM: "lm",
            wastage: 0,
            rounding: Rounding.NONE,
            gstFree: false,
            hasGST: true,
          },
        ],
      };
    }

    return {
      ...variation,
      items: variation.items?.map((item) => ({
        ...item,
        margin_amount: Number(item.margin_amount) || "0",
        gstFree: !item.hasGST,
      })),
    };
  }, [costingItems, editMode, variation, contact, markupValue]);

  const openCalculatorModal = React.useCallback(
    (fieldName: string, fieldValue?: string, rowIndex?: number) => {
      setFieldData({
        fieldName,
        rowIndex,
      });
      setCalculatorVisibility(true);
    },
    []
  );

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        currentUOMOptions.concat([{ label: uom, value: uom }]),
        "value"
      );
      setCurrentUOMOptions(newUOMs);
    },
    [currentUOMOptions, setCurrentUOMOptions]
  );

  const handleCostingSearch = React.useCallback(
    async (value: string) => {
      if (!jobId) return [] as SearchJobCostingItem[];
      return searchCostingItem(client, jobId, value);
    },
    [client, jobId]
  );

  const handlePriceSearch = React.useCallback(
    (value: string) => searchPriceItem(client, value),
    [client]
  );

  const addItem = React.useCallback(
    (name: string) => {
      const newItem = {
        label: name,
        value: name,
        hidden: true,
      } as SelectOption;
      setItems(uniqBy([...items, newItem], "value"));
    },
    [items]
  );

  const handlePriceSelect = React.useCallback(
    async (fieldValue, rowIndex, formikProps) => {
      if (!fieldValue || !jobId) return;

      const fieldsArray = `items[${rowIndex}]`;
      const { setFieldValue, values } = formikProps;
      const costingItemId = get(values, fieldsArray)?.costingItemId;

      // skip further price/costing checks if line item is part of an assembly
      if (costingItemId) {
        const assemblyItem = find(initialValues.items, {
          name: fieldValue,
          costingItemId,
        });
        if (assemblyItem) {
          return;
        }
      }

      const priceItem = findPriceItemFromCache(client, fieldValue);
      const costingItem = await findCostingItemFromCostingsCache(
        client,
        jobId,
        fieldValue
      );

      if (!priceItem && !costingItem) {
        // check if value has changed
        // const costingId = get(values, fieldsArray)?.costingItemId;
        // if (costingId) {
        //   const item = findCostingItemFromCache(client, costingId);
        //   if (!item || item.name !== fieldValue) {
        //     setFieldValue(`${fieldsArray}.costingItemId`, "");
        //   }
        // }
        return;
      }

      if (priceItem || costingItem) {
        const UOM = trim(priceItem?.UOM || costingItem?.UOM) || "";
        setFieldValue(`${fieldsArray}.UOM`, UOM);
        setFieldValue(
          `${fieldsArray}.cost`,
          priceItem?.cost || costingItem?.cost || ""
        );

        if (costingItem) {
          const item = costingCategoryList?.find(
            (c) => c.label === costingItem?.category?.name
          );
          setFieldValue(`${fieldsArray}.costingCategoryName`, item?.value);
          setFieldValue(`${fieldsArray}.costingItemId`, costingItem._id);
        }
        setFieldValue(
          `${fieldsArray}.name`,
          priceItem?.name || costingItem?.name
        );

        addItem(priceItem?.name || costingItem?.name || "");
        UOM && handleUOMCreate(UOM);
        return;
      }

      addItem(fieldValue);
    },
    [
      jobId,
      client,
      addItem,
      initialValues,
      handleUOMCreate,
      costingCategoryList,
    ]
  );

  React.useEffect(() => {
    setFormFields(
      createVariationsFields(
        t,
        openCalculatorModal,
        currentUOMOptions,
        contacts,
        handleUOMCreate,
        costingCategoryList,
        handleCreateCategory,
        handleSelectCategory,
        openAllocateCosting,
        getAllocateProps,
        handleCostingSearch,
        handlePriceSearch,
        handlePriceSelect,
        items,
        markupValue
      )
    );
  }, [
    t,
    openCalculatorModal,
    currentUOMOptions,
    contacts,
    handleUOMCreate,
    costingCategoryList,
    handleCreateCategory,
    markupValue,
    handleSelectCategory,
    openAllocateCosting,
    getAllocateProps,
    items,
  ]);

  const handleCalcSubmit = React.useCallback(
    (formikProps: FormikProps<VariationPayload>, calcBag: CalcBag) => {
      formikProps.setFieldValue(
        `items[${calcData.rowIndex}].rounding`,
        calcBag.rounding
      );
      formikProps.setFieldValue(
        `items[${calcData.rowIndex}].wastage`,
        calcBag.wastage
      );
      setCalculatorVisibility(false);
    },
    [calcData]
  );

  const closeCalcModal = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, []);

  useEffect(() => {
    const defUOM = MEASUREMENT_OPTIONS.map((item) => {
      return {
        value: item.value.toString(),
        label: item.label.toString(),
      };
    });

    if (initialValues && initialValues.items) {
      initialValues.items.map((itm) => {
        if (itm.UOM)
          defUOM.push({
            value: itm.UOM,
            label: itm.UOM,
          });
      });
    }
    setCurrentUOMOptions(chain(defUOM).uniqBy("value").value());
  }, [initialValues, variation, setCurrentUOMOptions]);

  const title =
    editMode && variation ? variation.name : t("variations.newVariation");

  return (
    <ModalForm<VariationPayload>
      show={show}
      editMode={editMode}
      validationSchema={createVariationSchema(t)}
      onClose={onClose}
      data={initialValues}
      className="variation-modal"
      title={title}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<VariationPayload>) => (
        <Container className="generic-form-body" fluid>
          {renderSelectCostingModal()}
          <CalculatorModal
            initialValue={
              get(formikProps.values, calcData.fieldName.split(".")[0])
                ?.raw_quantity || get(formikProps.values, calcData.fieldName)
            }
            wastage={(
              get(
                formikProps.values,
                `items[${calcData.rowIndex}].wastage`,
                ""
              ) || ""
            ).toString()}
            rounding={(
              get(
                formikProps.values,
                `items[${calcData.rowIndex}].rounding`,
                ""
              ) || ""
            ).toString()}
            formikProps={formikProps}
            show={showCalculator}
            onSubmit={handleCalcSubmit}
            fieldName={calcData.fieldName}
            onClose={closeCalcModal}
            takeoffs={takeoffs}
            takeoffProps={{
              canCreateTakeoff: !!salesQuoteId,
              salesQuoteId: salesQuoteId ? salesQuoteId : undefined,
              name: get(formikProps.values, calcData.fieldName.split(".")[0])
                ?.name,
            }}
          />
          <Row>
            {renderField(formikProps, formFields.name, 4)}
            {renderField(formikProps, formFields.contact, 3)}
            {renderField(formikProps, formFields.date, 3)}
            {renderField(formikProps, formFields.status, 2)}
          </Row>
          <Row>
            {renderTableForm(
              formikProps,
              formFields.items as GenericFormTable<VariationPayload, any>,
              "items"
            )}
          </Row>
          <Row>
            {renderField(formikProps, formFields.note, 8)}
            <Col lg={4} xs={12} className="form-column">
              <ModalTotals
                items={formikProps.values.items}
                margin={0}
                title={t("variations.amount")}
              />
            </Col>
          </Row>
        </Container>
      )}
    </ModalForm>
  );
};

export default VariationModal;
