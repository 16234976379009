import { gql } from "@apollo/client";

export const GRANT_CLIENT_ACCESS = gql`
  mutation GrantClientAccess(
    $accessType: ClientAccessType!
    $accessId: ID!
    $contactId: ID!
  ) {
    grantClientAccess(
      accessType: $accessType
      accessId: $accessId
      contactId: $contactId
    ) {
      _id
      contact {
        _id
        is_invited
      }
    }
  }
`;

export const REVOKE_CLIENT_ACCESS = gql`
  mutation RevokeClientAccess(
    $accessType: ClientAccessType!
    $accessId: ID!
    $clientAccessId: ID!
  ) {
    revokeClientAccess(
      accessType: $accessType
      accessId: $accessId
      clientAccessId: $clientAccessId
    ) {
      _id
    }
  }
`;
