import { User } from "../graphql/types/models/user";

export enum SystemFolderType {
  JOB = "JOB",
  SQ = "SQ",
}

export enum SystemSubFolderType {
  ESTIMATION_PLANS = "ESTIMATION_PLANS",
  JOB_PURCHASE_ORDER = "JOB_PURCHASE_ORDER",
}

export enum EnumDocumentAccessRole {
  ALL = "ALL",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SELECTED = "SELECTED",
}

export type TableFolderWithDocument = {
  _id: string;
  name: string;
  type: string;
  size: number;
  __typename: string;
};

export type RootFolder = {
  _id: string;
  name: string;
  subFolders: PrimaryFolder[];
  documents: Document[];
  createdAt: string;
};

export type PrimaryFolder = {
  _id: string;
  name: string;
  createdAt: string;
  accessRole?: EnumDocumentAccessRole;
  accessSelectedUsers?: User[];
};

export type Document = {
  _id: string;
  name: string;
  folder: PrimaryFolder;
  type: string;
  size: number;
  width: number;
  height: number;
  upload_url: string;
  url: string;
  createdAt: string;
  accessRole?: EnumDocumentAccessRole;
  accessSelectedUsers?: User[];
};

export type Folder = {
  _id: string;
  name: string;
  parent: PrimaryFolder;
  parentTree: PrimaryFolder[];
  subFolders: PrimaryFolder[];
  documents: Document[];
  createdAt: string;
};

export type CurrentDirectory = {
  _id?: string;
  name?: string;
  parent?: PrimaryFolder;
  parentTree?: PrimaryFolder[];
  subFolders: PrimaryFolder[];
  documents: Document[];
};

export type CurrentDirectoryWithId = {
  _id: string;
  name?: string;
  parent?: PrimaryFolder;
  parentTree?: PrimaryFolder[];
  subFolders: PrimaryFolder[];
  documents: Document[];
};

export type TableDocumentData = {
  _id: string;
  name: string;
  type: string;
  size: number | null;
  createdAt: string;
  accessRole?: EnumDocumentAccessRole;
  accessSelectedUsers?: User[];
};

export type CreateFolderPayload = {
  name: string;
  accessRole?: EnumDocumentAccessRole;
  accessSelectedUsers?: string[];
};

export type UpdateDocumentPayload = {
  name: string;
  accessRole?: EnumDocumentAccessRole;
  accessSelectedUsers?: string[];
};
