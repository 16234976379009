import { TFunction } from "i18next";
import { map } from "lodash";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { CreateUpdateTimesheetPayload } from "../../../models/timesheet";
import { JobCostingCategory } from "../../../models/job";

export const createJobCostingsField = (
  t: TFunction,
  handleCategoryChange: UpdateTextInputFn,
  categories: JobCostingCategory[]
): GenericFormFields<CreateUpdateTimesheetPayload> => {
  const categoryOptions = map(categories, (category) => ({
    value: category._id,
    label: category.name,
  }));

  return {
    category: {
      type: "select-autocomplete",
      label: t("common.category"),
      valueKey: "category",
      autocompleteProps: {
        options: categoryOptions,
      },
      textInputProps: {
        onValueChange: handleCategoryChange,
      },
      inputProps: {
        className: "form-input-compact",
      },
    },
  };
};
