import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { find, get, isNull } from "lodash";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { createProviderConfigurationFields } from "./utils";
import createProviderConfigurationSchema from "./ProviderConfigurationSchema";

import {
  AccountingAccountsResponse,
  AccountingConfiguration,
  AccountingConfigurationPayload,
  AccountingConfigurationResponse,
  AccountingCustomConfigurationResponse,
  AccountingTenantsResponse,
} from "../../../graphql/types/models/integrations";
import {
  GET_ACCOUNTING_ACCOUNTS,
  GET_ACCOUNTING_CUSTOM_CONFIGURATION,
  GET_ACCOUNTING_TENANTS,
} from "../../../graphql/queries/integrations/queries";
import CreateEntityModal from "../../modals/create-entity";
import { SET_ACCOUNTING_CONFIGURATION } from "../../../graphql/queries/integrations/mutations";
import { notify } from "../../notification";
import { useAccountingIntegrationQuery } from "../../../hooks/queries/useAccountingIntegrationQuery";
import "./styles.scss";

type ProviderConfigurationModalProps = {
  onSubmit?: (data: any) => void;
};

export type ProviderConfigurationModalRef = {
  show: (show: boolean) => void;
};

export type AccountFilters = {
  filterSales: boolean;
  filterExpenses: boolean;
};

const ProviderConfigurationModal: React.FC<ProviderConfigurationModalProps> = (
  props,
  ref
) => {
  const { t } = useTranslation();
  const { onSubmit } = props;

  const [showModal, setShowModal] = useState(false);
  const [tenantId, setTenantId] = React.useState();
  const [accountFilters, setAccountFilters] = React.useState<AccountFilters>({
    filterSales: true,
    filterExpenses: true,
  });
  const formikRef = React.useRef<any>();

  const {
    accountingIntegration,
    integrationProvider,
    refetchIntegration,
  } = useAccountingIntegrationQuery();

  const { data: tenantData, refetch: refetchTenant } = useQuery<
    AccountingTenantsResponse
  >(GET_ACCOUNTING_TENANTS);

  const { data: customConfigData, refetch: refetchCustomConfig } = useQuery<
    AccountingCustomConfigurationResponse
  >(GET_ACCOUNTING_CUSTOM_CONFIGURATION);

  const [getAccounts, { data: accountsData }] = useLazyQuery<
    AccountingAccountsResponse
  >(GET_ACCOUNTING_ACCOUNTS);

  const currentProviderLower = React.useMemo(
    () => integrationProvider?.toLowerCase(),
    [integrationProvider]
  );

  const [setConfiguration] = useMutation<
    AccountingConfigurationResponse,
    AccountingConfigurationPayload
  >(SET_ACCOUNTING_CONFIGURATION, {
    onCompleted: () => {
      notify({
        title: t("integrations.providerConfiguration", {
          provider: integrationProvider,
        }),
        content: t("integrations.success.configuration", {
          provider: integrationProvider,
        }),
      });
      setShowModal(false);
    },
    onError: () => {
      notify({
        error: true,
        title: t("integrations.providerConfiguration", {
          provider: integrationProvider,
        }),
        content: t("integrations.error.configuration", {
          provider: integrationProvider,
        }),
      });
    },
  });

  const [formFields, setFormFields] = React.useState<GenericFormFields<any>>(
    {}
  );

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => {
      if (show) {
        refetchIntegration();
        refetchTenant();
        refetchCustomConfig();
      }
      setShowModal(show);
    },
  }));

  const initialValues = React.useMemo(() => {
    const defaultSales = find(accountsData?.getAccountingAccounts, {
      isSales: true,
    });
    const defaultExpenses = find(accountsData?.getAccountingAccounts, {
      isExpenses: true,
    });
    const sync = get(accountingIntegration, "sync");
    const customConfiguration = {} as any;
    customConfigData?.getAccountingCustomConfiguration?.forEach(
      (customConfig) => {
        if (customConfig.value) {
          customConfiguration[customConfig.key] = customConfig.value;
        }
      }
    );

    return {
      tenantId: get(
        accountingIntegration,
        `${currentProviderLower}.tenant.id`,
        ""
      ),
      salesAccountId:
        get(
          accountingIntegration,
          `${currentProviderLower}.salesAccount.id`,
          ""
        ) || defaultSales?.id,
      expenseAccountId:
        get(
          accountingIntegration,
          `${currentProviderLower}.expenseAccount.id`,
          ""
        ) || defaultExpenses?.id,
      sync: {
        purchaseOrders: sync?.purchaseOrders || isNull(sync?.purchaseOrders),
        purchaseOrderReceipts:
          sync?.purchaseOrderReceipts || isNull(sync?.purchaseOrderReceipts),
        progressClaims: sync?.progressClaims || isNull(sync?.progressClaims),
      },
      customConfiguration,
    };
  }, [
    currentProviderLower,
    accountingIntegration,
    accountsData,
    customConfigData,
  ]);

  React.useEffect(() => {
    const tenantId = get(
      accountingIntegration,
      `${currentProviderLower}.tenant.id`
    );
    tenantId && setTenantId(tenantId);
  }, [accountingIntegration, currentProviderLower, setTenantId]);

  React.useEffect(() => {
    if (tenantId) {
      getAccounts({
        variables: {
          tenantId,
        },
      });
    }
  }, [tenantId]);

  const handleRemoveFilter = React.useCallback(
    (accountFilter: keyof AccountFilters) => {
      setAccountFilters({
        ...accountFilters,
        [accountFilter]: false,
      });
    },
    [accountFilters]
  );

  const handleTenantChange = React.useCallback(
    (tenantId: string) => {
      formikRef.current?.setFieldValue("salesAccountId", null);
      formikRef.current?.setFieldValue("expenseAccountId", null);
      setFormFields({});

      getAccounts({
        variables: {
          tenantId,
        },
      });
    },
    [getAccounts, formikRef]
  );

  const handleSubmit = React.useCallback(
    (config: AccountingConfiguration) => {
      const customConfigValues = (config as any).customConfiguration;
      const customConfiguration = Object.keys(customConfigValues)?.map(
        (key: string) => ({
          key,
          value: customConfigValues[key] as string,
        })
      );
      setConfiguration({
        variables: {
          config: {
            ...config,
            customConfiguration,
          },
        },
      });
      return true;
    },
    [tenantData]
  );

  React.useEffect(() => {
    setFormFields(
      createProviderConfigurationFields(
        t,
        handleTenantChange,
        accountFilters,
        handleRemoveFilter,
        tenantData?.getAccountingTenants,
        accountsData?.getAccountingAccounts,
        customConfigData?.getAccountingCustomConfiguration
      )
    );
  }, [
    t,
    accountFilters,
    handleRemoveFilter,
    tenantData,
    accountsData,
    customConfigData,
  ]);

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <CreateEntityModal
      formikRef={formikRef}
      validationSchema={createProviderConfigurationSchema(t)}
      className="provider-configuration-modal"
      title={t("integrations.providerConfiguration", {
        provider: integrationProvider,
      })}
      show={showModal}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={handleClose}
      submitText={t("common.save")}
      fields={formFields}
    />
  );
};

export default forwardRef(ProviderConfigurationModal);
