import { gql } from "@apollo/client";
import { BUSINESS_FRAGMENT } from "./fragments";

export const UPDATE_BUSINESS = gql`
  mutation($business: BusinessInput!) {
    updateBusiness(business: $business) {
      ...BusinessFragment
    }
  }
  ${BUSINESS_FRAGMENT}
`;
