import { JobDetails } from "../../../models/job";
import { Media } from "../../../models/media";
import { Pagination } from "../../../models/pagination";
import { QuoteBusiness } from "../../../models/salesQuote";
import { MediaInput } from "../inputs/media";
import { User } from "./user";

export enum SiteDiaryFieldType {
  String = "String",
  Date = "Date",
  File = "File",
  Number = "Number",
  Collection = "Collection",
  Radio = "Radio",
  Heading = "Heading",
}
export enum SiteDiaryTemplateType {
  System = "System",
  Custom = "Custom",
}

export type SiteDiaryField = {
  _id: string;
  type: SiteDiaryFieldType;
  title: string;
  subFields: SiteDiaryField[];
};

export type SiteDiarySubFieldValue = {
  _id: string;
  field: SiteDiaryField;
  value: string;
};

export type SiteDiaryFieldValueRow = {
  _id: string;
  columns: SiteDiarySubFieldValue[];
  attachments?: Media[];
};

export type SiteDiaryFieldValue = {
  _id: string;
  field: SiteDiaryField;
  title: string;
  value: string;
  subValues?: SiteDiaryFieldValueRow[];
  attachments?: Media[];
};

export type SiteDiaryCategory = {
  _id?: string;
  name: string;
};

export type SiteDiary = {
  _id: string;
  job: JobDetails;
  created_by: User;
  template: SiteDiaryTemplate;
  name: string;
  entry_date_time: string;
  weather: SiteDiaryWeather;
  note: string;
  attachments: Media[];
  values: SiteDiaryFieldValue[];
  createdAt: string;
  updatedAt: string;
  is_client_shared?: boolean;
  category?: SiteDiaryCategory;
};

export type SharedSiteDiary = {
  _id: string;
  is_client_shared?: boolean;
};

export type SiteDiaryWeather = {
  location: string;
  temperature: number;
  wind_speed: number;
  humidity: number;
  weather_description: string;
};

export type SiteDiaryTemplate = {
  _id: string;
  name: string;
  type: SiteDiaryTemplateType;
  business: QuoteBusiness;
  fields: SiteDiaryTemplateField[];
  createdAt: string;
  updatedAt: string;
  is_deleted: boolean;
};

export type SiteDiaryTemplateField = {
  _id: string;
  type: SiteDiaryFieldType;
  title: string;
  subFields?: SiteDiaryTemplateField[];
  options?: string[];
};

export type SiteDiaryFilters = {
  dateRange: {
    from?: Date;
    to?: Date;
  };
  jobId: string;
  templateId: string;
};

export type SiteDiaryPagination = {
  page: number;
  limit: number;
};

export type SiteDiaryTemplateListResponse = {
  listSiteDiaryTemplates: SiteDiaryTemplate[];
};

export type SiteDiaryTemplateCreatePayload = {
  template: SiteDiaryTemplate;
};

export type SiteDiaryTemplateCreateResponse = {
  createSiteDiaryTemplate: SiteDiaryTemplate;
};

export type SiteDiaryTemplateDeletePayload = {
  templateId: string;
};

export type SiteDiaryTemplateDeleteResponse = {
  deleteSiteDiaryTemplate: SiteDiaryTemplate;
};

export type SiteDiaryListResponse = {
  listSiteDiaryOverview: SiteDiary[];
};
export type SiteDiariesListPayload = {
  filters: SiteDiaryFilters;
  pagination: SiteDiaryPagination;
};

export type SiteDiariesListResponse = {
  listSiteDiaries: Pagination & {
    siteDiaries: SiteDiary[];
  };
};

export type SiteDiaryResponse = {
  getSiteDiaryById: SiteDiary;
};

export type SiteDiariesResponse = {
  getSiteDiariesById: SiteDiary[];
};

export type SiteDiaryCategoriesResponse = {
  listSiteDiaryCategories: SiteDiaryCategory[];
};

export type SiteDiaryPayloadSubFieldValueRow = {
  fieldId: string;
  value: string;
};

export type SiteDiaryPayloadFieldValueRow = {
  columns: SiteDiaryPayloadSubFieldValueRow[];
  attachments?: MediaInput[];
};

export type SiteDiaryPayloadValue = {
  fieldId: string;
  value?: string;
  subValues?: SiteDiaryPayloadFieldValueRow[];
  attachments?: MediaInput[];
};

export type SiteDiaryCreatePayload = {
  jobId: string;
  siteDiary: SiteDiaryPayload;
};

export type SiteDiaryCreateResponse = {
  createUpdateSiteDiary: SiteDiary;
};

export type SiteDiaryPayload = {
  _id?: string;
  templateId: string;
  jobId: string;
  name: string;
  entry_date_time: string;
  note: string;
  attachments?: MediaInput[];
  values: SiteDiaryPayloadValue[];
  category?: string;
};

export type SiteDiaryDeletePayload = {
  jobId: string;
  diaryId: string;
};

export type SiteDiaryDeleteResponse = {
  deleteSiteDiary: SiteDiary;
};

export type ClientSiteDiariesResponse = {
  clientJobListSharedSiteDiaries: SiteDiary[];
};

export type SiteDiarySharePayload = {
  jobId: string;
  diaryId: string;
};

export type SiteDiaryShareResponse = {
  shareSiteDiaryWithClient: SharedSiteDiary;
};
