import React from "react";
import { TableCardDataColumn, TableCardDataRow } from "./utils";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import * as uuid from "uuid";

type CellProps = {
  row: TableCardDataRow<any>;
  col: TableCardDataColumn<any>;
};

const Cell: React.FC<CellProps> = (props) => {
  const { row, col } = props;

  const getCellValue = (
    row: TableCardDataRow<any>,
    col: TableCardDataColumn<any>
  ) => {
    const value = row.cells[col.valueKey];
    if (col.renderValue) {
      return col.renderValue(row, col, value);
    }
    return col.formatValue ? col.formatValue(row, col, value) : value;
  };

  const cellValue = getCellValue(row, col);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLTableCellElement>) => {
      col.onClick && col.onClick(row, col, cellValue, event);
    },
    [col, row, cellValue]
  );

  const tooltipId = React.useMemo(
    () => (col.tooltip ? `tt-${uuid.v4()}` : ""),
    [col]
  );

  const tooltip = React.useMemo(() => {
    if (col.tooltip) {
      const tt = col.tooltip(row.cells)?.replace(/\n/g, "<br />");
      if (tt) return tt;
    }
    return null;
  }, [col, row]);

  const renderCellContent = React.useCallback(() => {
    if (col.isLink) {
      const href = col.linkKey ? row.cells[col.linkKey] : "";

      return (
        <a target={col.linkTarget} href={href} className="success-text">
          {cellValue}
        </a>
      );
    }

    if (col.tooltip) {
      return (
        <>
          <span data-tip={tooltip} data-for={tooltipId}>
            {cellValue}
          </span>

          <ReactTooltip
            id={tooltipId}
            className="table-tooltip"
            place="bottom"
            html={true}
          />
        </>
      );
    }

    return cellValue;
  }, [col, row, cellValue, tooltip, tooltipId]);

  return (
    <td
      className={classNames("field-text", col.className)}
      onClick={handleClick}
    >
      {renderCellContent()}
    </td>
  );
};

export default Cell;
