import React from "react";
import { PlaceholderProps } from "react-select";

const HeaderSearchPlaceholder: React.FC<PlaceholderProps<any>> = ({
  selectProps: { placeholder },
}) => {
  return <div className="search-placeholder">{placeholder}</div>;
};

export default HeaderSearchPlaceholder;
