import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import { connect } from "react-redux";
import {
  find,
  head,
  map,
  omit,
  pick,
  concat,
  compact,
  get,
  debounce,
} from "lodash";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import CategorySelectorCard, {
  CategorySelectorPayload,
} from "../../../components/category-select-card";
import {
  PurchaseOrderPayload,
  PurchaseOrderStatus,
  ReceivePurchaseOrderPayload,
} from "../../../models/purchaseOrder";
import OrderCard from "../../../components/order/order-card";
import PurchaseOrderForm, {
  PurchaseOrderFormRef,
} from "../../../components/order/order-modal/purchaser-form";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";

import {
  RECEIVE_ORDER,
  DELETE_ORDER,
  CANCEL_RECEIVE_ORDER,
  MARK_ORDER_RECEIVED,
  MARK_ORDER_RECEIPT_BILLABLE,
} from "../../../graphql/queries/order/mutations";
import {
  CheckPurchaseOrderReceiptResponse,
  CreateUpdateOrderResponse,
  DeleteOrderResponse,
  GetOrderPreviewResponse,
  GetOrderResponse,
  ListOrdersResponse,
  MarkPurchaseOrderReceiptBillable,
  MarkPurchaseOrderReceivedResponse,
  ReceivePurchaseOrder,
} from "../../../graphql/types/models/order";
import {
  GET_ORDER,
  GET_ORDER_PREVIEW,
  JOB_CHECK_ORDER_RECEIPT,
  LIST_ORDERS,
} from "../../../graphql/queries/order/queries";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import {
  handleOrderDelete,
  handleUpdateOrderReceive,
} from "../../../graphql/queries/order/utils";
import { printBase64Pdf } from "../../../utils/pdf";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import { notify } from "../../../components/notification";
import JobLocationHeader from "../../header/job-location-header";
import ClientHeaderList from "../../header/client-header-list";
import { RouteComponentProps, useHistory } from "react-router-dom";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import { formatQuoteNumber } from "../../../utils/text";
import PurchaseOrderReceiveModal from "../../../components/job-purchase-order/receive-modal";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";

import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import { DropdownItem } from "../../../components/dashboard/dropdown";
import TeammateHeaderList from "../../header/teammate-header-list";
import { getOrderBadgeVariant } from "./utils";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useSendOrder } from "../job-costing/hooks/useSendOrder";
import { useAccountingIntegrationQuery } from "../../../hooks/queries/useAccountingIntegrationQuery";
import { AccountingIntegrationSyncItemType } from "../../../graphql/types/models/integrations";
import { useGetTakeOffs } from "../../../hooks/useGetTakeOffs";
import { JobType } from "../../../models/job";

type Params = {
  id: string;
  po_id?: string;
};
type JobPurchaseOrderProps = RouteComponentProps<Params> &
  DashboardContextValue & {
    user: UserPayload | null;
  };

const JobPurchaseOrder: React.FC<JobPurchaseOrderProps> = ({
  navigationContext,
  match,
  user,
}) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const jobId = navigationContext?.job?._id;
  const isCostPlus = navigationContext?.job?.type === JobType.COST_PLUS;
  const salesQuoteId = navigationContext?.job?.salesQuote?._id;
  const { po_id: poId } = match.params;

  const { t } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();
  const orderRef = useRef<PurchaseOrderFormRef>();
  const cancelOrderRef = useRef<ConfirmDialogRef>();
  const receiveOrderRef = useRef<ConfirmDialogRef>();

  const { renderSendOrderModal, showSendOrder } = useSendOrder();
  const [referenceFieldWarning, setReferenceFieldWarning] = React.useState("");

  const [
    getOrder,
    { loading: orderLoading, data: orderDetails, error: orderError },
  ] = useLazyQuery<GetOrderResponse>(GET_ORDER);

  const { data: ordersList } = useQuery<ListOrdersResponse>(LIST_ORDERS, {
    variables: {
      jobId,
    },
  });

  const { getTakeOffItems, takeoffs } = useGetTakeOffs();

  React.useEffect(() => {
    if (salesQuoteId) {
      return getTakeOffItems({
        variables: {
          salesQuoteId,
        },
      });
    }
  }, [getTakeOffItems, salesQuoteId]);

  const [
    checkPurchaseOrderReceipt,
    { data: duplicateOrderReceipt },
  ] = useLazyQuery<CheckPurchaseOrderReceiptResponse>(JOB_CHECK_ORDER_RECEIPT, {
    onCompleted: (data) => {
      const isDuplicatePurchaseOrderReceipt =
        data?.jobCheckDuplicatePurchaseOrderReceipt?.length > 0;
      if (isDuplicatePurchaseOrderReceipt) {
        setReferenceFieldWarning(
          t("errors.existsReceipt", {
            value: data?.jobCheckDuplicatePurchaseOrderReceipt[0].reference,
          })
        );
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const debounceCheckReceipt = debounce(checkPurchaseOrderReceipt, 500);

  const [cancelReceived] = useMutation<CreateUpdateOrderResponse>(
    CANCEL_RECEIVE_ORDER,
    {
      onCompleted: () => {
        cancelOrderRef.current?.show(false);
        notify({
          title: t("orders.cancelOrder"),
          content: t("orders.success.cancelOrder"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: t("orders.cancelOrder"),
          content: t("orders.errors.cancelOrder"),
        });
      },
    }
  );

  const [markPurchaseOrderReceived] = useMutation<
    MarkPurchaseOrderReceivedResponse
  >(MARK_ORDER_RECEIVED, {
    onCompleted: () => {
      receiveOrderRef.current?.show(false);
      notify({
        title: t("orders.markAsReceived"),
        content: t("orders.success.receiveOrder"),
      });
    },
    onError: (e) => {
      notify({
        error: true,
        title: t("orders.markAsReceived"),
        content: get(e, "message", t("orders.errors.receiveOrder")),
      });
    },
  });

  const [markPurchaseOrderReceiptBillable] = useMutation<
    MarkPurchaseOrderReceiptBillable
  >(MARK_ORDER_RECEIPT_BILLABLE, {
    onCompleted: (data) => {
      const isNonBillable =
        data.jobMarkPurchaseOrderReceiptBillable?.isNonBillable;
      notify({
        title: isNonBillable
          ? t("orders.markAsNonBillable")
          : t("orders.billable"),
        content: isNonBillable
          ? t("orders.success.markAsNonBillable")
          : t("orders.success.markAsBillable"),
      });
    },
    onError: (e) => {
      notify({
        error: true,
        title: t("orders.purchaseOrder"),
        content: get(e, "message", t("orders.errors.markAsNonBillable")),
      });
    },
  });

  const handleMarkOrderReceiptBillable = React.useCallback(
    (
      purchaseOrderId: string,
      purchaseOrderReceiptId: string,
      isBillable: boolean
    ) => {
      markPurchaseOrderReceiptBillable({
        variables: {
          jobId,
          purchaseOrderId,
          purchaseOrderReceiptId,
          isBillable,
        },
      });
    },
    [jobId, markPurchaseOrderReceiptBillable]
  );

  const [receiveOrder, { loading: orderReceiving }] = useMutation<
    ReceivePurchaseOrder
  >(RECEIVE_ORDER);

  const [deleteOrder, { loading: orderDeleting }] = useMutation<
    DeleteOrderResponse
  >(DELETE_ORDER, {
    update: handleOrderDelete(jobId),
  });

  const {
    integrationProvider,
    syncAccountingItem,
  } = useAccountingIntegrationQuery();

  const [showReceiveConfirm, setReceiveDialogVisibility] = useState(false);
  const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
  const [deleteTargetId, setDeleteTarget] = useState("");
  const [orderFilter, setFilter] = useState({
    id: "",
  });

  const order = React.useMemo(() => orderDetails?.getJobPurchaseOrderById, [
    orderDetails,
  ]);

  const orderToUpdate: PurchaseOrderPayload | null = React.useMemo(() => {
    if (!order) {
      return null;
    }

    const preparedItems = map(order.items, (item) =>
      omit(item, ["__typename"])
    );

    const preparedOrder = {
      ...pick(order, [
        "_id",
        "reference",
        "attention",
        "date",
        "deliveryDate",
        "contactNumber",
        "deliveryAddress",
        "deliveryInstructions",
        "status",
        "hasGST",
        "costIncGST",
        "hideCost",
        "internalNote",
      ]),
      deliveryDate: order.deliveryDate
        ? moment(order.deliveryDate).toISOString()
        : "",
      supplier: order.supplier?._id || "",
    };

    return {
      ...preparedOrder,
      items: preparedItems,
    };
  }, [order]);

  const isSalesQuoteHasFiles = React.useMemo(
    () => navigationContext?.job?.salesQuote?.files?.length !== 0,
    [navigationContext]
  );

  const openDeleteDialog = React.useCallback((id: string) => {
    setDeleteDialogVisibility(true);
    setDeleteTarget(id);
  }, []);

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteDialogVisibility(false);
    setDeleteTarget("");
  }, []);

  const handleMarkReceive = React.useCallback(
    (id: string) => {
      receiveOrderRef.current?.show(true, () => {
        markPurchaseOrderReceived({
          variables: {
            jobId,
            purchaseOrderId: order?._id,
          },
        });
      });
    },
    [receiveOrderRef, jobId, order]
  );

  const handleEdit = React.useCallback(() => {
    orderToUpdate && orderRef.current?.show(true, orderToUpdate);
  }, [orderRef, orderToUpdate]);

  const closeCancelConfirm = React.useCallback(() => {
    setReceiveDialogVisibility(false);
    setReferenceFieldWarning("");
  }, []);

  const openReceiveDialog = React.useCallback((id: string) => {
    setReceiveDialogVisibility(true);
  }, []);

  const openEmailModal = React.useCallback(
    (id: string) => {
      order && showSendOrder(order);
    },
    [order]
  );

  const orderCategories = React.useMemo<CategorySelectorPayload[]>(() => {
    const orders = ordersList?.getJobPurchaseOrders;
    return map(orders, (order) => {
      const { supplier, receipts } = order;
      let subTotal = order.subTotal;
      if (receipts?.length) {
        subTotal = receipts.reduce(
          (total, receipt) => total + receipt.subTotal,
          0
        );
      }

      const supplierName = supplier?.contact_person
        ? `(${supplier?.contact_person})`
        : "";
      const supplierBusinessName = supplier?.business_name;
      return {
        id: order._id,
        label: compact([
          formatQuoteNumber(order.orderNumber, "PO"),
          order.reference,
        ]).join(": "),
        description: compact([
          moment(order.date).format("D MMMM YYYY"),
          compact([supplierBusinessName, supplierName]).join(" "),
        ]).join(": "),
        rightLabel: subTotal
          ? t("common.currency", { amount: subTotal })
          : null,
        total: subTotal ? subTotal : 0,
        rightBadge: [
          {
            variant: getOrderBadgeVariant(order.status),
            label: t(`orders.statuses.${order.status}`),
          },
        ],
        reference: order.reference,
        supplierBusinessName: supplier?.business_name,
        contactPerson: order.supplier?.contact_person,
      };
    });
  }, [ordersList?.getJobPurchaseOrders, t]);

  const handleOrderCancelConfirm = React.useCallback(
    (id: string) => {
      if (!id) return;
      cancelReceived({
        variables: {
          jobId,
          purchaseOrderId: id,
        },
      });
    },
    [cancelReceived, jobId]
  );

  const openCancelDialog = React.useCallback((id: string) => {
    cancelOrderRef.current?.show(true, () => handleOrderCancelConfirm(id));
  }, []);

  const handleOrderDeleteConfirm = React.useCallback(async () => {
    if (!deleteTargetId) {
      return;
    }

    try {
      await deleteOrder({
        variables: {
          jobId,
          purchaseOrderId: deleteTargetId,
        },
      });

      notify({
        title: t("orders.deleteOrder"),
        content: t("orders.success.deleteOrder"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("orders.deleteOrder"),
        content: t("orders.errors.deleteOrder"),
      });
    }

    setDeleteDialogVisibility(false);
    setDeleteTarget("");
  }, [deleteOrder, deleteTargetId, jobId]);

  const handleOrderReceive = React.useCallback(
    async (data: ReceivePurchaseOrderPayload | null) => {
      try {
        if (!data) return;

        await receiveOrder({
          variables: data,
          update: handleUpdateOrderReceive(data.jobId, data.purchaseOrderId),
        });

        notify({
          title: t("orders.receivePurchaseOrder"),
          content: t("orders.success.receiveOrder"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("orders.receivePurchaseOrder"),
          content: get(e, "message", t("orders.errors.receiveOrder")),
        });
      }
    },
    [t, receiveOrder]
  );

  const handleSelectCategory = React.useCallback(
    (filter) => {
      getOrder({
        variables: {
          jobId,
          purchaseOrderId: filter.id,
        },
      });

      setFilter(filter);
    },
    [getOrder, jobId]
  );

  React.useEffect(() => {
    if (order || orderLoading || orderError) {
      return;
    }

    let firstOrder = head(ordersList?.getJobPurchaseOrders);
    if (poId) {
      const purchaseOrder = find(ordersList?.getJobPurchaseOrders, {
        _id: poId,
      });
      if (purchaseOrder) {
        firstOrder = purchaseOrder;
      }
    }

    if (firstOrder) {
      getOrder({
        variables: {
          jobId,
          purchaseOrderId: firstOrder._id,
        },
      });

      setFilter({
        id: firstOrder._id,
      });
    }
  }, [orderError, ordersList, order, jobId, poId, getOrder, orderLoading]);

  const handleSubmit = React.useCallback(
    (result: CreateUpdateOrderResponse) => {
      const order = result.jobCreateUpdatePurchaseOrder;
      orderRef.current?.show(false);

      const supplierName = order.supplier
        ? ` - ${order.supplier.contact_person || order.supplier.business_name}`
        : "";

      const reference = order.reference ? ` - ${order.reference}` : "";

      handleSelectCategory({
        id: order._id,
        label: `${formatQuoteNumber(
          order.orderNumber,
          "PO"
        )}${reference}${supplierName}`,
        rightLabel: t(`orders.statuses.${order.status}`),
      });
    },
    [jobId, orderRef, handleSelectCategory]
  );

  const openOrderModal = React.useCallback(() => {
    orderRef.current?.show(true);
  }, [orderRef]);

  const handleSupplierClick = React.useCallback(() => {
    if (orderDetails?.getJobPurchaseOrderById?.supplier) {
      history.push(
        `/contacts/suppliers/${orderDetails.getJobPurchaseOrderById.supplier._id}`
      );
    }
  }, [history, order]);

  const handlePrint = React.useCallback(
    async (purchaseOrderId: string) => {
      try {
        const preview = await client.query<GetOrderPreviewResponse>({
          query: GET_ORDER_PREVIEW,
          fetchPolicy: "network-only",
          variables: {
            jobId,
            purchaseOrderId,
          },
        });

        printBase64Pdf(preview?.data?.getJobPurchaseOrderPreview.pdf);
      } catch (e) {}
    },
    [jobId]
  );

  const handleSync = React.useCallback(
    async (purchaseOrderId: string) => {
      try {
        syncAccountingItem({
          variables: {
            jobId,
            itemType: AccountingIntegrationSyncItemType.PURCHASE_ORDER,
            itemId: purchaseOrderId,
          },
        });
      } catch (e) {}
    },
    [jobId]
  );

  const renderOrderCard = React.useMemo(() => {
    if (!order || orderLoading) {
      return <CardPlaceholder />;
    }

    return (
      <OrderCard
        order={order}
        onPrint={handlePrint}
        onEdit={handleEdit}
        onDelete={openDeleteDialog}
        onReceive={openReceiveDialog}
        onMarkReceive={handleMarkReceive}
        onCancel={openCancelDialog}
        onSend={openEmailModal}
        onSync={integrationProvider ? handleSync : undefined}
        syncProvider={integrationProvider}
        onSupplierClick={handleSupplierClick}
        disableCancel={order.status === PurchaseOrderStatus.CANCELLED}
        isCostPlus={isCostPlus}
        onMarkOrderReceiptBillable={handleMarkOrderReceiptBillable}
      />
    );
  }, [
    order,
    orderLoading,
    handlePrint,
    handleEdit,
    openDeleteDialog,
    openReceiveDialog,
    openCancelDialog,
    openEmailModal,
    handleSupplierClick,
    integrationProvider,
    isCostPlus,
  ]);

  const {
    filteredArray,
    filter: inputFilter,
    setFilter: setSearchByInput,
    setFilterbySelect,
    filterSelect,
  } = useFilteredArrayMultipleField(
    orderCategories,
    ["reference", "label", "supplierBusinessName", "contactPerson"],
    true,
    (item: CategorySelectorPayload, search: string) =>
      !!item.rightBadge?.filter((b) => b.label === search).length
  );

  const total = React.useMemo(() => {
    return filteredArray.reduce((sum, current) => {
      return sum + (current?.total || 0);
    }, 0);
  }, [filteredArray]);

  const filterDropdownItems = React.useMemo<DropdownItem[]>(
    () =>
      concat(
        {
          id: "-",
          label: t("common.all"),
          onClick: () => setFilterbySelect(""),
        },
        Object.keys(PurchaseOrderStatus).map((key) => ({
          id: key,
          label: t(`orders.statuses.${key}`),
          onClick: () => setFilterbySelect(t(`orders.statuses.${key}`)),
        }))
      ),
    [t, inputFilter]
  );

  const handleOnReferenceChange = React.useCallback(
    async (value: string) => {
      setReferenceFieldWarning("");
      if (!value) return;
      debounceCheckReceipt({
        variables: {
          jobId,
          reference: value,
        },
      });
    },
    [jobId]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.purchaseOrders")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.JOBS_SECTION.PURCHASE_ORDERS}
      />

      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      <ConfirmDialog
        disabled={orderDeleting}
        show={showDeleteDialog}
        onSubmit={handleOrderDeleteConfirm}
        onClose={closeDeleteDialog}
        title={t("orders.deleteOrder")}
        confirm={t("common.yes")}
      >
        <span className="field-text">{t("orders.deleteOrderMessage")}</span>
      </ConfirmDialog>

      <ConfirmDialog
        ref={cancelOrderRef}
        disabled={orderDeleting}
        title={t("orders.cancelOrder")}
        confirm={t("common.yes")}
      >
        <span className="field-text">{t("orders.cancelOrderMessage")}</span>
      </ConfirmDialog>

      <ConfirmDialog
        ref={receiveOrderRef}
        title={t("orders.markAsReceived")}
        confirm={t("common.yes")}
      >
        <span className="field-text">{t("orders.markAsReceivedMessage")}</span>
      </ConfirmDialog>

      <PurchaseOrderReceiveModal
        data={order || null}
        jobId={jobId}
        show={showReceiveConfirm}
        onSubmit={handleOrderReceive}
        onClose={closeCancelConfirm}
        onReferenceChange={handleOnReferenceChange}
        referenceFieldWarning={referenceFieldWarning}
        takeoffs={isSalesQuoteHasFiles ? takeoffs : null}
        salesQuoteId={isSalesQuoteHasFiles ? salesQuoteId : null}
      />

      {renderSendOrderModal()}

      <PurchaseOrderForm
        ref={orderRef}
        jobId={jobId}
        onSubmit={handleSubmit}
        takeoffs={isSalesQuoteHasFiles ? takeoffs : null}
        salesQuoteId={isSalesQuoteHasFiles ? salesQuoteId : null}
      />

      {ordersList?.getJobPurchaseOrders.length ? (
        <Row className="h-100">
          <Col lg={4} xs={12}>
            <CategorySelectorCard
              title={t("orders.allOrders")}
              filter={orderFilter}
              onSelectCategory={handleSelectCategory}
              categories={filteredArray}
              hideSelectAllButton={true}
              disabled={isLocked}
              onAddNewItem={openOrderModal}
              filterDropdownItems={filterDropdownItems}
              dropdownFilter={filterSelect}
              searchOptions={{
                value: inputFilter,
                onChange: setSearchByInput,
              }}
              placeholder={t("orders.emptyFilterPlaceholder", {
                status: filterSelect,
              })}
              total={total}
            />
          </Col>
          <Col lg={8} xs={12}>
            {renderOrderCard}
          </Col>
        </Row>
      ) : !isLocked ? (
        <EmptyPlaceholder
          message={t("orders.emptyPlaceholder")}
          buttonText={t("orders.createOrder")}
          onButtonPress={openOrderModal}
        />
      ) : (
        <EmptyPlaceholder message={t("orders.emptyPlaceholder")} />
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withDashboardContext(JobPurchaseOrder));
