import "pdfjs-dist/build/pdf.worker.entry";
import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import CalibrateScaleContainer from "../calibrate-scale";
import TakeOffContainer from "../take-off";
import UploadPlansContainer from "../upload-plans";
import QuoteCosting from "../quote-costing";
import QuoteCostingImporter from "../quote-costing-importer";
import QuotePreview from "../quote-preview";
import QuoteSpecifications from "../quote-specifications";
import { useQuery } from "@apollo/client";
import { GET_SALES_QUOTE } from "../../../graphql/queries/quote/queries";
import { GetSalesQuoteResponse } from "../../../graphql/types/models/quote";
import QuoteOverview from "../quote-overview";
import QuoteDocuments from "../quote-documents";
import QuoteSelections from "../quote-selections";
import EstimationQuoteRequest from "../quote-requests";

type Params = {
  id: string;
};

type QuoteContainerProps = RouteComponentProps<Params> & DashboardContextValue;

const QuoteContainer: React.FC<QuoteContainerProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { id: salesQuoteId } = match.params;

  const { data: salesQuote, loading: salesQuoteLoading } = useQuery<
    GetSalesQuoteResponse
  >(GET_SALES_QUOTE, {
    variables: { salesQuoteId },
  });

  React.useEffect(() => {
    setNavigationContext({
      ...navigationContext,
      quote: {
        _id: salesQuoteId,
        name: salesQuote?.getSalesQuote?.name || "",
        businessName: salesQuote?.getSalesQuote?.business?.name || "",
        quoteNumber: salesQuote?.getSalesQuote?.number || 0,
        hasFiles: !!salesQuote?.getSalesQuote?.files?.length,
        isLocked: salesQuote?.getSalesQuote?.is_locked || false,
        markupPercent: salesQuote?.getSalesQuote?.markup || 0,
      },
    });
  }, [setNavigationContext, salesQuote, salesQuoteId]);

  React.useEffect(() => {
    return () => {
      setNavigationContext({
        ...navigationContext,
        quote: undefined,
      });
    };
  }, []);

  if (salesQuoteLoading) {
    return null;
  }

  if (!salesQuote?.getSalesQuote && !salesQuoteLoading) {
    return <Redirect to="/quotes" />;
  }

  if (!navigationContext?.quote) return null;
  return (
    <Switch>
      <Route exact path="/quotes/:id" component={QuoteOverview} />,
      <Route path="/quotes/:id/plans" component={UploadPlansContainer} />
      <Route exact path="/quotes/:id/costing" component={QuoteCosting} />
      <Route
        path="/quotes/:id/costing/import"
        component={QuoteCostingImporter}
      />
      <Route
        path="/quotes/:id/specifications"
        component={QuoteSpecifications}
      />
      <Route path="/quotes/:id/selections" component={QuoteSelections} />
      <Route
        exact
        path="/quotes/:id/quote-requests"
        component={EstimationQuoteRequest}
      />
      <Route
        path="/quotes/:id/quote-requests/:qr_id/subcontractor/:sub_id"
        component={EstimationQuoteRequest}
      />
      ,
      <Route
        path="/quotes/:id/calibration-scale"
        component={CalibrateScaleContainer}
      />
      <Route path="/quotes/:id/preview" component={QuotePreview} />
      <Route path="/quotes/:id/take-off" component={TakeOffContainer} />
      <Route exact path="/quotes/:id/documents" component={QuoteDocuments} />,
      <Route
        exact
        path="/quotes/:id/documents/:folderId"
        component={QuoteDocuments}
      />
    </Switch>
  );
};

export default withRouter(withDashboardContext(QuoteContainer));
