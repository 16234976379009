import { gql } from "@apollo/client";
import { TIMESHEET_REQUEST_FRAGMENT } from "./fragments";

export const DELETE_TIMESHEET = gql`
  mutation deleteTimesheet($jobId: ID!, $timesheetId: ID!) {
    deleteTimesheet(jobId: $jobId, timesheetId: $timesheetId) {
      ...TimesheetRequest
    }
  }
  ${TIMESHEET_REQUEST_FRAGMENT}
`;

export const CREATE_UPDATE_TIMESHEET = gql`
  mutation createUpdateTimesheet($timesheets: [TimesheetInput]!) {
    createUpdateTimesheet(timesheets: $timesheets) {
      ...TimesheetRequest
    }
  }
  ${TIMESHEET_REQUEST_FRAGMENT}
`;

export const UNPROCESS_TIMESHEET = gql`
  mutation unprocessTimesheet($jobId: ID!, $timesheetId: ID!) {
    unprocessTimesheet(jobId: $jobId, timesheetId: $timesheetId) {
      ...TimesheetRequest
    }
  }
  ${TIMESHEET_REQUEST_FRAGMENT}
`;
