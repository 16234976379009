import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { get, map, uniqBy } from "lodash";
import CreateEntityModal from "../../../modals/create-entity";
import { createAssemblyFields } from "./utils";
import { QuoteMeasurement } from "../../../../models/salesQuote";
import "./styles.scss";
import CalculatorModal from "../../../modals/calculator";
import { FormikProps } from "formik";
import createAssemblySchema from "./CreateUpdateAssembly.schema";
import {
  AssemblyCreateUpdatePayload,
  AssemblyListItem,
} from "../../../../models/assembly";
import { findEnum } from "../../../../utils/options";
import { UOMOption, GenericRecord } from "../../../../utils/types/options";
import { calcItemTotal } from "../../../../utils/calculations";
import { SearchCostingItemType } from "../../../../graphql/types/models/price-list";
import { useCostingLookup } from "../../../../hooks/useCostingLookup";

type PriceListItemOption = GenericRecord & { badge?: string };

type CreateUpdateAssemblyModalProps = {
  uoms: UOMOption[];
  onSubmit: (data: AssemblyCreateUpdatePayload) => void;
  onClose: () => void;
};

export type CreateUpdateAssemblyModalRef = {
  show: (show: boolean, assembly?: AssemblyListItem) => void;
};

const CreateUpdateAssemblyModal: React.FC<CreateUpdateAssemblyModalProps> = (
  props,
  ref
) => {
  const { uoms: currentUoms, onSubmit, onClose } = props;

  const [show, setShowModal] = useState(false);
  const [showCalculator, setCalculatorVisibility] = useState(false);
  const [calcFieldName, setFieldName] = useState("");
  const [assembly, setAssembly] = useState<AssemblyListItem | null>(null);
  const [uoms, setUOMs] = React.useState<UOMOption[]>(currentUoms);
  const [itemOptions, setItemOptions] = React.useState<UOMOption[]>([]);
  const [priceListOptions, setPriceListOptions] = React.useState<
    PriceListItemOption[]
  >([]);

  const client = useApolloClient();
  const { handleCostingNameChange, searchCostingItem } = useCostingLookup(
    client,
    SearchCostingItemType.PRICE_LIST
  );

  useImperativeHandle(ref, () => ({
    show: (show: boolean, assembly?: AssemblyListItem) => {
      setShowModal(show);
      setAssembly(assembly || null);
    },
  }));

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        uoms.concat([{ label: uom, value: uom }]),
        "value"
      );
      setUOMs(newUOMs);
    },
    [uoms, setUOMs]
  );

  const initialValues = React.useMemo(() => {
    return {
      name: assembly?.name || "",
      UOM:
        (assembly?.UOM && findEnum(QuoteMeasurement, assembly?.UOM)) ||
        QuoteMeasurement.LINEAR_METER,
      items: assembly
        ? map(assembly.items, (item) => ({
            cost: item.cost,
            quantity: parseFloat(item.quantity),
            raw_quantity: item.raw_quantity,
            UOM:
              findEnum(QuoteMeasurement, item.UOM) ||
              QuoteMeasurement.LINEAR_METER,
            name: item.name,
            total: item.total,
          }))
        : [
            {
              cost: 0,
              quantity: 0,
              raw_quantity: "",
              UOM: QuoteMeasurement.LINEAR_METER,
              name: "",
              total: 0,
            },
          ],
      total: 0,
    };
  }, [assembly]);

  const toggleCalculatorModal = React.useCallback(() => {
    setCalculatorVisibility(!showCalculator);
  }, [showCalculator]);

  const { t } = useTranslation();

  const openCalculatorModal = React.useCallback((fieldName: string) => {
    setFieldName(fieldName);
    setCalculatorVisibility(true);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, []);

  React.useEffect(() => {
    if (assembly?.items) {
      setItemOptions(
        assembly.items?.map((item) => ({
          label: item.name,
          value: item.name,
          hidden: true,
        }))
      );
    }
  }, [assembly]);

  const handleCreatePriceListOption = React.useCallback(
    (data: string) => {
      const newListOption = uniqBy(
        priceListOptions.concat([{ label: data, value: data }]),
        "value"
      );
      setPriceListOptions(newListOption);
    },
    [priceListOptions]
  );

  const handlePriceListItemChange = React.useCallback(
    (fieldValue, rowIndex, formikProps) => {
      if (!fieldValue) return;
      const fieldsArray = `items[${rowIndex}]`;
      const item = handleCostingNameChange(
        fieldValue,
        fieldsArray,
        formikProps
      );
      if (item?.UOM) {
        handleUOMCreate(item.UOM);
      }
    },
    [handleCostingNameChange]
  );

  const formFields = React.useMemo(() => {
    return createAssemblyFields(
      t,
      uoms,
      itemOptions.concat(priceListOptions),
      searchCostingItem,
      handleUOMCreate,
      openCalculatorModal,
      handleCreatePriceListOption,
      handlePriceListItemChange
    );
  }, [
    t,
    uoms,
    itemOptions,
    priceListOptions,
    handleUOMCreate,
    openCalculatorModal,
    handleCreatePriceListOption,
    handlePriceListItemChange,
  ]);

  const handleFormSubmit = React.useCallback(
    (data: AssemblyCreateUpdatePayload) => {
      data.items = data.items.map((item) => ({
        ...item,
        raw_quantity: item.quantity.toString(),
        total:
          calcItemTotal(
            {
              margin_amount: item.margin_amount || 0,
              cost: item.cost,
              quantity: item.quantity,
            },
            0
          ) || 0,
      }));

      onSubmit({
        ...data,
        total: data.items
          .map((item) => item.total)
          .reduce((prev, next) => prev + next),
      });
    },
    [onSubmit]
  );

  return (
    <CreateEntityModal
      className="assembly-modal"
      validationSchema={createAssemblySchema(t)}
      title={t("assembly.addAssembly")}
      show={show}
      data={initialValues}
      onSubmit={handleFormSubmit}
      onClose={onClose}
      fields={formFields}
    >
      {(formikProps: FormikProps<any>) => (
        <CalculatorModal
          formikProps={formikProps}
          show={showCalculator}
          onSubmit={handleSubmit}
          fieldName={calcFieldName}
          onClose={toggleCalculatorModal}
          title={get(formikProps.values, calcFieldName.split(".")[0])?.name}
        />
      )}
    </CreateEntityModal>
  );
};

export default forwardRef(CreateUpdateAssemblyModal);
