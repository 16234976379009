import React from "react";
import classNames from "classnames";
import { Badge } from "react-bootstrap";
import { AutocompleteMenuOptionProps } from "./AutocompleteMenuOption";
import "./styles.scss";

const TopBadgedAutocompleteMenuOption: React.FC<AutocompleteMenuOptionProps> = (
  props
) => {
  const { innerProps, children, data } = props;

  let badge = null;
  if (data.badge) {
    badge = <Badge variant={data.badgeVariant || "info"}>{data.badge}</Badge>;
  }

  return (
    // @ts-ignore
    <div
      {...innerProps}
      className={classNames("option", { "option-badged": !!badge })}
    >
      <div className="option-badged-group">
        <span>{badge}</span>
        {children}
      </div>
    </div>
  );
};

export default TopBadgedAutocompleteMenuOption;
