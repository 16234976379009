import { pick } from "lodash";
import { RichContent, RawRichContent } from "../../../../models/rich-content";

export const convertRichContent = (content: RawRichContent): RichContent => {
  return {
    ...pick(content, ["text", "html"]),
    raw:
      typeof content?.raw === "object"
        ? JSON.stringify(content?.raw)
        : content?.raw || "",
  };
};
