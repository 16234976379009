import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { compact } from "lodash";
import CustomReportCard from "../../dashboard/report-card/custom-report-card";
import { VariationReal, VariationStatuses } from "../../../models/variations";
import DashboardCardField from "../../dashboard/card/DashboardCardField";
import Totals from "../../costing/total";
import { TableCardData } from "../../dashboard/table-card/utils";
import { CostingTable, createCostingTable } from "../../costing/utils";
import CardTable from "../../dashboard/table-card/CardTable";
import { formatQuoteNumber } from "../../../utils/text";
import DashboardCardSignField from "../../dashboard/card/DashboardCardSignField";
import { EnumDigitalSignatureType } from "../../accept-signature-dialog/utils";

type ClientVariationCardProps = VariationReal & {
  onAccept: (id: string) => void;
  titleIndex?: number | null;
  isLoading?: boolean;
  onPrint?: (id: string) => void;
};

const ClientVariationCard: React.FC<ClientVariationCardProps> = (props) => {
  const {
    _id,
    name,
    status,
    total,
    GST,
    note,
    subTotal,
    date,
    contact,
    titleIndex,
    items,
    onAccept,
    clientSignature,
    builderSignature,
    isLoading,
    onPrint,
  } = props;

  const { t } = useTranslation();

  const categoryData = React.useMemo<TableCardData<CostingTable>>(
    () =>
      createCostingTable(items, t, 0, [
        "margin_amount",
        "total",
        "allocatedCategory",
      ]),
    [items, t]
  );

  const title = formatQuoteNumber(titleIndex?.toString() || "", "V");

  const handleAccept = React.useCallback(() => {
    onAccept && onAccept(_id);
  }, [_id, onAccept]);

  const handlePrint = React.useCallback(() => {
    onPrint && onPrint(_id);
  }, [_id, onPrint]);

  const dropdownItems = React.useMemo(
    () =>
      compact([
        status === VariationStatuses.AWAITING_CLIENT_APPROVAL && {
          id: "accept",
          label: t("common.accept"),
          icon: "done",
          outlined: true,
          onClick: handleAccept,
        },
        {
          id: "print",
          label: t("common.print"),
          icon: "print",
          outlined: true,
          onClick: handlePrint,
        },
      ]),
    [handleAccept, handlePrint, status, t]
  );

  return (
    <CustomReportCard
      title={title}
      badge={t(`variations.statuses.${status}`)}
      controls={dropdownItems}
      withoutControls={!dropdownItems.length}
      className="variation-card"
    >
      <Row className="main-row">
        <Col lg={6} xs={12} className="report-col">
          <DashboardCardField title={t("variations.name")} content={name} />
        </Col>
        <Col lg={3} xs={12} className="report-col">
          <DashboardCardField
            title={t("variations.contactName")}
            content={compact([contact?.first_name, contact?.last_name]).join(
              " "
            )}
          />
        </Col>
        <Col lg={3} xs={12} className="report-col">
          <DashboardCardField
            title={t("variations.date")}
            content={moment(date).format("D MMMM YYYY")}
          />
        </Col>
      </Row>

      <Row className="main-row table-row">
        <CardTable rowCount table={categoryData} />
      </Row>

      <Row className="row--big">
        <Col lg={7} xs={12} className="report-col--big">
          <DashboardCardField
            title={t("variations.notes")}
            content={note}
            placeholder={t("variations.noNotes")}
            height="180px"
          />
        </Col>
        <Col lg={5} xs={12} className="report-col--big">
          <Totals
            title={t("variations.amount")}
            gst={GST}
            total={total}
            subtotal={subTotal}
          />
        </Col>
      </Row>
      <Row className="row--big">
        <Col lg={6} xs={12} className="report-col--big">
          <DashboardCardSignField
            signature={clientSignature}
            signatureType={EnumDigitalSignatureType.CLIENT}
            isLoading={isLoading}
          />
        </Col>
        <Col lg={6} xs={12} className="report-col--big">
          <DashboardCardSignField
            signature={builderSignature}
            signatureType={EnumDigitalSignatureType.BUILDER}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </CustomReportCard>
  );
};

export default ClientVariationCard;
