import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { TodoChecklistTemplates } from "../../../models/task";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import importChecklistTemplateSchema from "./ImportChekclistTemplate.schema";
import { importChecklistTemplateFields } from "./utils";

type ImportChecklistTemplateModalProps = {
  templatesData: TodoChecklistTemplates[];
  onSubmit: (templateId: string) => void;
};

const ImportChecklistTemplateModal: React.FC<ImportChecklistTemplateModalProps> = (
  { templatesData, onSubmit },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<{ templateId: string }>
  >({});

  React.useEffect(() => {
    if (!templatesData) return;
    setFormFields(importChecklistTemplateFields(t, templatesData));
  }, [templatesData, t]);

  const handleSubmit = React.useCallback(
    (values: { templateId: string }) => {
      onSubmit(values.templateId);
      hide();
    },
    [hide, onSubmit]
  );

  return (
    <>
      <CreateEntityModal
        validationSchema={importChecklistTemplateSchema(t)}
        className="import-cheklist-template"
        title={t("tasks.importChecklistTemplate")}
        show={shouldShow}
        data={{
          templateId: "",
        }}
        submitText={t("common.import")}
        onSubmit={handleSubmit}
        onClose={hide}
        fields={formFields}
      />
    </>
  );
};

export default forwardRef(ImportChecklistTemplateModal);
