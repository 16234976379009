import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { CreateSelectionCategoryPayload } from "../../../graphql/types/models/selections";

export const createCategoryField = (
  t: TFunction,
  isTemplate?: boolean
): GenericFormFields<CreateSelectionCategoryPayload> => {
  const fields: GenericFormFields<CreateSelectionCategoryPayload> = {
    name: {
      type: "text",
      label: t("common.name"),
      placeholder: t("common.name"),
      valueKey: "name",
      inputProps: {
        required: true,
      },
    },
    description: {
      type: "text",
      controlType: "textarea",
      label: t("common.description"),
      placeholder: t("common.description"),
      valueKey: "description",
    },
    ...(!isTemplate && {
      dueDate: {
        type: "date-picker",
        label: t("common.dueDate"),
        placeholder: t("common.dueDate"),
        valueKey: "dueDate",
      },
    }),
  };

  return fields;
};
