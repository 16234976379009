import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CategorySelectorCard, {
  CategorySelectorPayload,
} from "../../../components/category-select-card";

import { compact, concat, find, head, map, omit, pick } from "lodash";
import RosterCard from "../../../components/roster/roster-card";
import CreateRosterModal, {
  CreateRosterModalRef,
} from "../../../components/roster/create-roster-modal";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { SupplierListResponse } from "../../../graphql/types/models/supplier";

import SendModal from "../../../components/modals/send-modal";
import { SendEmailForm } from "../../../models/email";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { printBase64Pdf } from "../../../utils/pdf";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import { notify } from "../../../components/notification";
import JobLocationHeader from "../../header/job-location-header";
import ClientHeaderList from "../../header/client-header-list";
import { RouteComponentProps } from "react-router-dom";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import { formatQuoteNumber } from "../../../utils/text";
import {
  RosterListItem,
  RosterPayload,
  RosterStatus,
} from "../../../models/roster";
import {
  CreateUpdateRosterResponse,
  DeleteRosterResponse,
  GetRosterPreviewResponse,
  GetRosterResponse,
  ListRostersResponse,
  SendRosterPayload,
  SendRosterResponse,
} from "../../../graphql/types/models/job-roster";
import {
  GET_ROSTER,
  GET_ROSTER_PREVIEW,
  LIST_ROSTERS,
} from "../../../graphql/queries/job-roster/queries";
import {
  APPROVE_ROSTER,
  DELETE_ROSTER,
  SEND_ROSTER,
  UNAPPROVE_ROSTER,
} from "../../../graphql/queries/job-roster/mutations";
import { handleRosterDelete } from "../../../graphql/queries/job-roster/utils";
import { LIST_MEMBERS } from "../../../graphql/members/queries";
import { ListMembersResponse } from "../../../graphql/models/members";

import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import TeammateHeaderList from "../../header/teammate-header-list";
import moment from "moment";
import { getRosterBadgeVariant, getTotal } from "./utils";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";
import { DropdownItem } from "../../../components/dashboard/dropdown";
import { PurchaseOrderStatus } from "../../../models/purchaseOrder";
import { SystemFolderType } from "../../../models/documents";

type Params = {
  id: string;
  roster_id?: string;
};
type JobRosterProps = RouteComponentProps<Params> &
  DashboardContextValue & {
    user: UserPayload | null;
  };

const JobRoster: React.FC<JobRosterProps> = ({
  navigationContext,
  match,
  user,
}) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const jobId = navigationContext?.job?._id;
  const businessName = navigationContext?.job?.businessName;
  const { roster_id: rosterId } = match.params;

  const { t } = useTranslation();
  const client = useApolloClient();

  const [showCc, setShowCc] = React.useState(false);
  const [showBcc, setShowBcc] = React.useState(false);

  const createModalRef = React.useRef<CreateRosterModalRef>(null);
  const deleteRef = React.useRef<ConfirmDialogRef>(null);
  const approveRef = React.useRef<ConfirmDialogRef>(null);
  const unapproveRef = React.useRef<ConfirmDialogRef>(null);
  const sendRef = React.useRef<ModalDisplayRef>();

  const [
    getRoster,
    { loading: rosterLoading, data: rosterDetails, error: rosterError },
  ] = useLazyQuery<GetRosterResponse>(GET_ROSTER, {
    fetchPolicy: "cache-and-network",
  });

  const { data: rosterList } = useQuery<ListRostersResponse>(LIST_ROSTERS, {
    variables: {
      jobId,
    },
  });

  const { data: teamList } = useQuery<ListMembersResponse>(LIST_MEMBERS);

  const [sendRoster] = useMutation<SendRosterResponse, SendRosterPayload>(
    SEND_ROSTER,
    {
      onCompleted: () => {
        setTargetId("");
        sendRef.current?.show(false);
        notify({
          title: t("roster.sendRoster"),
          content: t("roster.success.sendRoster"),
        });
        setShowBcc(false);
        setShowCc(false);
      },
      onError: () => {
        notify({
          error: true,
          title: t("roster.sendRoster"),
          content: t("roster.errors.sendRoster"),
        });
        setShowBcc(false);
        setShowCc(false);
      },
    }
  );

  const [approveRoster, { loading: rosterApproving }] = useMutation(
    APPROVE_ROSTER,
    {
      onCompleted: () => {
        setTargetId("");
        approveRef.current?.show(false);
      },
    }
  );

  const [unapproveRoster, { loading: rosterUnapproving }] = useMutation(
    UNAPPROVE_ROSTER,
    {
      onCompleted: () => {
        setTargetId("");
        unapproveRef.current?.show(false);
      },
    }
  );

  const [deleteRoster, { loading: rosterDeleting }] = useMutation<
    DeleteRosterResponse
  >(DELETE_ROSTER, {
    onCompleted: () => {
      notify({
        title: t("roster.deleteRoster"),
        content: t("roster.success.deleteRoster"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("roster.deleteRoster"),
        content: t("roster.errors.deleteRoster"),
      });
    },
    update: handleRosterDelete(jobId),
  });

  const [targetId, setTargetId] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [orderFilter, setFilter] = useState({
    id: "",
  });

  const roster = React.useMemo(() => rosterDetails?.getJobRosterById, [
    rosterDetails,
  ]);

  const openDeleteDialog = React.useCallback(
    (id: string) => {
      setTargetId(id);
      deleteRef.current?.show(true);
    },
    [deleteRef]
  );

  const openApproveDialog = React.useCallback(
    (id: string) => {
      setTargetId(id);
      approveRef.current?.show(true);
    },
    [approveRef]
  );

  const openUnapproveDialog = React.useCallback(
    (id: string) => {
      setTargetId(id);
      unapproveRef.current?.show(true);
    },
    [unapproveRef]
  );

  const handleEdit = React.useCallback(() => {
    setEditMode(true);
    createModalRef.current?.show(true);
  }, [createModalRef]);

  const openEmailModal = React.useCallback(
    (id: string) => {
      sendRef.current?.show(true);
      setTargetId(id);
    },
    [sendRef]
  );

  const rosterCategories = React.useMemo(() => {
    const rosters = rosterList?.getJobRosters;

    return map(rosters, (roster: RosterListItem) => {
      const total = getTotal(
        (roster as RosterListItem & {
          items: { cost: number; quantity: number }[];
        }).items
      );
      return {
        id: roster._id,
        label: formatQuoteNumber(roster.rosterNumber, "RO"),
        description: compact([
          moment(roster.date).format("D MMMM YYYY"),
          roster.reference,
        ]).join(": "),
        rightLabel: t("common.currency", { amount: total }),
        total: total,
        rightBadge: [
          {
            variant: getRosterBadgeVariant(roster.status),
            label: t(`roster.statuses.${roster.status}`),
          },
        ],
        reference: roster.reference,
      };
    });
  }, [rosterList]);

  const handleDeleteRoster = React.useCallback(async () => {
    if (!targetId) {
      return;
    }
    deleteRoster({
      variables: {
        jobId,
        rosterId: targetId,
      },
    });
  }, [targetId, jobId]);

  const handleSelectCategory = React.useCallback(
    (filter) => {
      getRoster({
        variables: {
          jobId,
          rosterId: filter.id,
        },
      });

      setFilter(filter);
    },
    [getRoster, jobId]
  );

  React.useEffect(() => {
    if (roster || rosterLoading || rosterError) {
      return;
    }

    let firstRoster = head(rosterList?.getJobRosters);
    if (rosterId) {
      const roster = find(rosterList?.getJobRosters, {
        _id: rosterId,
      });
      if (roster) {
        firstRoster = roster;
      }
    }

    if (firstRoster) {
      getRoster({
        variables: {
          jobId,
          rosterId: firstRoster._id,
        },
      });

      setFilter({
        id: firstRoster._id,
      });
    }
  }, [
    rosterError,
    rosterList,
    roster,
    jobId,
    rosterId,
    getRoster,
    rosterLoading,
  ]);

  const handleSubmit = React.useCallback(
    (result: CreateUpdateRosterResponse) => {
      setEditMode(false);
      handleSelectCategory({ id: result.jobCreateUpdateRoster._id });
    },
    [setEditMode, handleSelectCategory]
  );

  const handleOnClose = React.useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);

  const handlePrint = React.useCallback(
    async (rosterId: string) => {
      try {
        const preview = await client.query<GetRosterPreviewResponse>({
          query: GET_ROSTER_PREVIEW,
          fetchPolicy: "network-only",
          variables: {
            jobId,
            rosterId,
          },
        });

        printBase64Pdf(preview?.data?.getJobRosterPreview.pdf);
      } catch (e) {}
    },
    [jobId]
  );

  const copyMailOptions = React.useMemo(() => {
    return {
      showCc,
      setShowCc,
      showBcc,
      setShowBcc,
      showReplyTo: true,
    };
  }, [showBcc, showCc]);

  const handleSendRosterSubmit = React.useCallback(
    (message: SendEmailForm) => {
      if (!jobId) return;
      return sendRoster({
        variables: {
          jobId,
          rosterId: targetId,
          message: {
            subject: message.title,
            to: message.to,
            ...(showCc && { cc: message.cc }),
            ...(showBcc && { bcc: message.bcc }),
            ...(message.replyTo && { replyTo: message.replyTo }),
            message: message.message,
            remoteAttachmentId: message.remoteAttachmentId,
          },
        },
      });
    },
    [jobId, sendRoster, targetId, showCc, showBcc]
  );

  const handleRosterApprove = React.useCallback(async () => {
    if (!targetId) {
      return;
    }
    approveRoster({
      variables: {
        jobId,
        rosterId: targetId,
      },
    });
  }, [targetId, jobId]);

  const handleRosterUnapprove = React.useCallback(async () => {
    if (!targetId) {
      return;
    }
    unapproveRoster({
      variables: {
        jobId,
        rosterId: targetId,
      },
    });
  }, [targetId, jobId]);

  const rosterToUpdate: RosterPayload | null = React.useMemo(() => {
    if (!roster) {
      return null;
    }

    const preparedItems = map(roster.items, (item) =>
      omit(item, ["__typename"])
    );

    const preparedRoster = pick(roster, [
      "_id",
      "reference",
      "date",
      "status",
      "internalNote",
    ]);
    return {
      ...preparedRoster,
      items: preparedItems,
    };
  }, [roster]);

  const {
    filteredArray,
    filter: inputFilter,
    setFilter: setSearchByInput,
    setFilterbySelect,
    filterSelect,
  } = useFilteredArrayMultipleField(
    rosterCategories,
    ["reference", "label"],
    true,
    (item: CategorySelectorPayload, search: string) =>
      !!item.rightBadge?.filter((b) => b.label === search).length
  );

  const filterDropdownItems = React.useMemo<DropdownItem[]>(
    () =>
      concat(
        {
          id: "-",
          label: t("common.all"),
          onClick: () => setFilterbySelect(""),
        },
        Object.keys(PurchaseOrderStatus).map((key) => ({
          id: key,
          label: t(`orders.statuses.${key}`),
          onClick: () => setFilterbySelect(t(`orders.statuses.${key}`)),
        }))
      ),
    [t, inputFilter]
  );

  const renderRosterCard = () => {
    if (!roster || rosterLoading) {
      return <CardPlaceholder />;
    }

    return (
      <RosterCard
        {...roster}
        onPrint={handlePrint}
        onEdit={handleEdit}
        onDelete={openDeleteDialog}
        onSend={openEmailModal}
        onApprove={openApproveDialog}
        onUnapprove={openUnapproveDialog}
        disableCancel={roster.status === RosterStatus.CANCELLED}
      />
    );
  };

  const previewFileName = `${businessName} - Roster #${roster?.rosterNumber}.pdf`;

  const total = React.useMemo(() => {
    return filteredArray.reduce((sum, current) => {
      return sum + (current?.total || 0);
    }, 0);
  }, [filteredArray]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.roster")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.ROSTER} />

      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      <ConfirmDialog
        ref={approveRef}
        onSubmit={handleRosterApprove}
        disabled={rosterApproving}
        title={t("roster.approveRoster")}
        confirm={t("common.yes")}
      >
        <span className="field-text">{t("roster.approveRosterMessage")}</span>
      </ConfirmDialog>

      <ConfirmDialog
        ref={unapproveRef}
        onSubmit={handleRosterUnapprove}
        disabled={rosterUnapproving}
        title={t("roster.unapproveRoster")}
        confirm={t("common.yes")}
      >
        <span className="field-text">{t("roster.unapproveRosterMessage")}</span>
      </ConfirmDialog>

      <ConfirmDialog
        ref={deleteRef}
        disabled={rosterDeleting}
        onSubmit={handleDeleteRoster}
        title={t("roster.deleteRoster")}
        confirm={t("common.yes")}
      >
        <span className="field-text">{t("roster.deleteRosterMessage")}</span>
      </ConfirmDialog>

      {teamList?.listMembers && (
        <SendModal
          ref={sendRef}
          fileName={previewFileName}
          onFileClick={() => roster && handlePrint(roster._id)}
          onSubmit={handleSendRosterSubmit}
          contacts={teamList?.listMembers}
          title={t("roster.sendRoster")}
          subject={t("roster.sendRosterSubject", {
            rosterNumber: roster
              ? formatQuoteNumber(roster.rosterNumber, "RO")
              : "",
          })}
          submitText={t("common.send")}
          copyOptions={copyMailOptions}
          addDocumentsSelect={{
            entityId: jobId,
            systemFolderType: SystemFolderType.JOB,
          }}
        />
      )}

      <CreateRosterModal
        ref={createModalRef}
        jobId={jobId}
        roster={rosterToUpdate}
        editMode={isEditMode}
        onSubmit={handleSubmit}
        onClose={handleOnClose}
      />

      {rosterList?.getJobRosters.length ? (
        <Row className="h-100">
          <Col lg={4} xs={12}>
            <CategorySelectorCard
              title={t("roster.allRosters")}
              filter={orderFilter}
              onSelectCategory={handleSelectCategory}
              categories={filteredArray}
              filterDropdownItems={filterDropdownItems}
              hideSelectAllButton={true}
              onAddNewItem={() => createModalRef.current?.show(true)}
              disabled={isLocked}
              searchOptions={{
                value: inputFilter,
                onChange: setSearchByInput,
              }}
              placeholder={t("orders.emptyFilterPlaceholder", {
                status: filterSelect,
              })}
              total={total}
            />
          </Col>
          <Col lg={8} xs={12}>
            {renderRosterCard()}
          </Col>
        </Row>
      ) : !isLocked ? (
        <EmptyPlaceholder
          message={t("roster.emptyPlaceholder")}
          buttonText={t("roster.createRoster")}
          onButtonPress={() => createModalRef.current?.show(true)}
        />
      ) : (
        <EmptyPlaceholder message={t("roster.emptyPlaceholder")} />
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(JobRoster));
