import React from "react";
import { useTranslation } from "react-i18next";
import { SalesQuoteFile } from "../../../models/salesQuote";
import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import PagesSelector from "./PagesSelector";

import "./styles.scss";
import { Badge, BadgeProps } from "react-bootstrap";

type PlansCardProps = {
  files?: SalesQuoteFile[];
  selectedPage: number;
  onPageChange: (page: number) => void;
  badge?: {
    label: string;
    variant: BadgeProps["variant"];
  };
};

const PlansCard: React.FC<PlansCardProps> = ({
  files,
  selectedPage,
  onPageChange,
  badge,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <DashboardCard className="plans-card">
      <DashboardCardHeader className="text-capitalize">
        <div>
          {t("plansSection.plans")}
          {badge && (
            <Badge variant={badge?.variant} className="ml-2">
              {badge?.label}
            </Badge>
          )}
        </div>

        <PagesSelector
          selectedPage={selectedPage}
          pagesCount={files ? files.length : 0}
          onPageSelect={onPageChange}
        />
      </DashboardCardHeader>
      <DashboardCardBody>{children}</DashboardCardBody>
    </DashboardCard>
  );
};

export default PlansCard;
