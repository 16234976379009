export enum UserRoles {
  builderadmin = "builderadmin",
  manager = "manager",
  basic = "basic",
}

export type TeamMember = {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: UserRoles;
  hourly_rate?: number;
  profileImage?: {
    _id: string;
    url: string;
  };
};

export type AddMemberPayload = {
  _id?: string;
  first_name: string;
  last_name: string;
  email: string;
  role: UserRoles;
  hourly_rate?: number;
};
