import classNames from "classnames";
import React from "react";
import { Modal } from "react-bootstrap";

import "./styles.scss";

type LeftModalContainerProps = {
  show: boolean;
  className?: string;
  onHide?: () => void;
};

const LeftModalContainer: React.FC<LeftModalContainerProps> = ({
  children,
  show,
  className,
  onHide,
}) => {
  const handleHide = React.useCallback(() => {
    onHide && onHide();
  }, [onHide]);

  return (
    <Modal
      backdrop="static"
      onHide={handleHide}
      bsPrefix="left-side-modal modal"
      dialogClassName={classNames("left-modal-container", className)}
      role="side-dialog"
      show={show}
    >
      {children}
    </Modal>
  );
};

export default LeftModalContainer;
