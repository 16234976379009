import React, { useState } from "react";
import { isArray, reduce } from "lodash";
import { JobCostingTableItem } from "..";
import { JobCostingCategory, JobCostingItem } from "../../../../models/job";
import { getVariationItems, toggleCostingItem } from "../utils";
import { TableRowActionData } from "../../../../components/dashboard/table-card/utils";
import { TFunction } from "react-i18next";
import ListVariationItemsModal, {
  ListVariationItemsModalRef,
} from "../list-variation-items-modal";

export function useVariation(t: TFunction, jobId?: string) {
  const [selectedVariationItems, setSelectedVariationItems] = useState<
    JobCostingTableItem[]
  >([]);
  const listVariationItemsRef = React.useRef<ListVariationItemsModalRef>(null);

  const resetVariationItems = React.useCallback(() => {
    setSelectedVariationItems([]);
  }, []);

  const getCostingItemById = React.useCallback(
    (
      itemId,
      selectedCategory: JobCostingCategory | null
    ): JobCostingItem | undefined => {
      if (!itemId) return;

      const item = selectedCategory?.items.find((itm) => {
        if (itm._id?.toString() === itemId.toString()) return itm;
      });
      return item;
    },
    []
  );

  const toggleVariationItems = React.useCallback(
    (
      rows: JobCostingTableItem | JobCostingTableItem[],
      selectedCategory: JobCostingCategory | null
    ) => {
      if (isArray(rows)) {
        const { variationItems } = reduce(
          rows,
          (result, row) => {
            const item = getCostingItemById(row._id, selectedCategory);
            if (!item?.variation?._id) {
              result.variationItems = toggleCostingItem(
                result.variationItems,
                row,
                true
              );
            }
            return result;
          },
          {
            variationItems: selectedVariationItems,
          }
        );
        setSelectedVariationItems(variationItems);
      } else {
        setSelectedVariationItems(
          toggleCostingItem(selectedVariationItems, rows)
        );
      }
    },
    [getCostingItemById, selectedVariationItems]
  );

  const variationRowAction = React.useMemo<
    TableRowActionData<JobCostingTableItem>
  >(
    () => ({
      svgIcon: "Change",
      counter: (row: JobCostingTableItem) => getVariationItems(row).length,
      title: (row: JobCostingTableItem) =>
        t("costing.variationCreated", {
          count: getVariationItems(row).length,
        }),
      dropdownId: "variation-item",
      onClick: (row: JobCostingTableItem) => {
        const data = getVariationItems(row);
        if (jobId) {
          listVariationItemsRef.current?.setData(data, jobId);
          listVariationItemsRef.current?.show(true);
        }
      },
      shouldRender: (row: JobCostingTableItem) =>
        getVariationItems(row).length > 0,
    }),
    [jobId, t]
  );

  const renderListVariationsModal = React.useCallback(() => {
    return <ListVariationItemsModal ref={listVariationItemsRef} />;
  }, []);

  return {
    toggleVariationItems,
    resetVariationItems,
    selectedVariationItems,
    renderListVariationsModal,
    variationRowAction,
  };
}
