import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import "./styles.scss";
import AuthenticationCard from "../authentication-card";
import createSignUpFormSchema from "./SignUpForm.schema";
import SignUpFormBody from "./SignUpFormBody";
import SignUpFormFooter from "./SignUpFormFooter";

type SignUpPayload = {
  businessName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
};

type SignUpFormProps = {
  onSubmit: (credentials: SignUpPayload) => void;
};

const SignUpForm: React.FC<SignUpFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: {
      businessName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      rememberMe: false,
    },
    validationSchema: createSignUpFormSchema(t),
    onSubmit: (values) => {
      return onSubmit(values);
    },
  });

  return (
    <AuthenticationCard
      title={t("authentication.signUp")}
      container={Form}
      containerProps={{
        onSubmit: handleSubmit,
        noValidate: true,
        className: "authentication-card-container sign-up-card",
      }}
      body={SignUpFormBody}
      bodyProps={{
        t,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }}
      footer={SignUpFormFooter}
      footerProps={{ t, isSubmitting }}
    />
  );
};

export default SignUpForm;
