import { WithT } from "i18next";
import { FormikProps } from "formik";
import React from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

type SignUpConfirmBodyProps = WithT;

const SignUpConfirmBody: React.FC<SignUpConfirmBodyProps> = ({ t }) => {
  return (
    <Container className="p-0 m-0 mw-100">
      <p className="field-text">{t("authentication.activationFailed")}</p>
    </Container>
  );
};

export default SignUpConfirmBody;
