import { MapField } from "../../../components/import-mapper/types";
import { ClientColumnMap } from "../../../models/client";
import { CreateSupplierPayload } from "../../../models/supplier";

export const FIELD_MAP_CONTACT: MapField<ClientColumnMap>[] = [
  {
    fieldKey: "phase",
    label: "contacts.phase",
  },
  {
    fieldKey: "first_name",
    label: "contacts.firstName",
    required: true,
  },
  {
    fieldKey: "last_name",
    label: "contacts.lastName",
  },
  {
    fieldKey: "business_name",
    label: "contacts.businessName",
  },
  {
    fieldKey: "abn",
    label: "company.abnNumber",
  },
  {
    fieldKey: "email",
    label: "contacts.email",
  },
  {
    fieldKey: "phone",
    label: "contacts.primaryPhone",
  },
  {
    fieldKey: "phone_secondary",
    label: "placeholders.secondaryPhone",
  },
  {
    fieldKey: "address",
    label: "contacts.address",
  },
  {
    fieldKey: "address2",
    label: "contacts.addressSecondary",
  },
  {
    fieldKey: "city",
    label: "contacts.city",
  },
  {
    fieldKey: "state",
    label: "contacts.state",
  },
  {
    fieldKey: "postcode",
    label: "contacts.postcode",
  },
  // {
  //   fieldKey: "mobile",
  //   label: "contacts.mobile",
  // },
];

export const FIELD_MAP_SUPPLIER: MapField<CreateSupplierPayload>[] = [
  {
    fieldKey: "contact_person",
    label: "contacts.contactPerson",
  },
  {
    fieldKey: "business_name",
    label: "contacts.businessName",
    required: true,
  },
  {
    fieldKey: "abn",
    label: "company.abnNumber",
  },
  {
    fieldKey: "phone_number",
    label: "contacts.primaryPhone",
  },
  {
    fieldKey: "fax_number",
    label: "contacts.fax",
  },
  {
    fieldKey: "email",
    label: "contacts.email",
  },
  {
    fieldKey: "categoryId",
    label: "contacts.categoryId",
  },
  {
    fieldKey: "phone_secondary",
    label: "placeholders.secondaryPhone",
  },
  {
    fieldKey: "address",
    label: "contacts.address",
  },
  {
    fieldKey: "address2",
    label: "contacts.addressSecondary",
  },
  {
    fieldKey: "city",
    label: "contacts.city",
  },
  {
    fieldKey: "state",
    label: "contacts.state",
  },
  {
    fieldKey: "postcode",
    label: "contacts.postcode",
  },
];
