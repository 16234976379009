import React, { useState } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState,
  RawDraftContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import classNames from "classnames";
import { TextInputProps } from "../text";
import { RICH_EDITOR_TOOLBAR } from "./configs";
import Attachment from "./custom-buttons/attachment";
import Fullscreen from "./custom-buttons/fullscreen";
import PDFMerge from "./custom-buttons/pdf-merge";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.scss";

export type RichEditorProps = Omit<TextInputProps, "onChange"> & {
  onChange: (value: string, text: string, raw?: RawDraftContentState) => void;
  textKey?: string;
  rawKey?: string;
  rawValue?: string;
  onFilesSelect?: (files: File[]) => void;
  toolbarOptions?: object & { customOptions?: string[] };
};

const RichEditor: React.FC<RichEditorProps> = ({
  onChange,
  touched,
  error,
  placeholder,
  readOnly,
  onFilesSelect,
  value,
  rawValue,
  toolbarOptions,
  className,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [fullscreen, setFullScreen] = useState(false);

  const notifyChange = React.useCallback(
    (content: ContentState) => {
      const editorContent = convertToRaw(content);
      if (content.hasText()) {
        const html = draftToHtml(editorContent);
        const text = content.getPlainText("\u0001");
        onChange(html, text, editorContent);
        return;
      }

      onChange("", "", editorContent);
    },
    [onChange]
  );

  // Workaround to restore value of rich editor when component is hidden then re-shown
  React.useEffect(() => {
    if (typeof rawValue === "object") {
      try {
        const contentState = convertFromRaw(rawValue);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        notifyChange(contentState);
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  React.useEffect(() => {
    let contentState = null;
    if (rawValue) {
      if (typeof rawValue === "object") return; // if rawValue is an object, then it has already been parsed
      try {
        const raw = JSON.parse(rawValue);
        if (raw?.blocks) {
          contentState = convertFromRaw(raw);
        }
      } catch (e) {
        contentState = ContentState.createFromText("");
        console.error("Could not parse raw DraftJS structure", rawValue);
      }
    } else if (!rawValue && editorState.getCurrentContent().hasText()) {
      // value has been reset
      contentState = ContentState.createFromText("");
    } else if (value) {
      const blocksFromHtml = convertFromHTML(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
    }
    if (contentState) {
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
      notifyChange(contentState);
    }
  }, [rawValue]);

  const onEditorStateChange = React.useCallback(
    (state: EditorState) => {
      setEditorState(state);
      notifyChange(state.getCurrentContent());
    },
    [onChange]
  );

  const onFullscreenPress = React.useCallback(() => {
    setFullScreen(!fullscreen);
  }, [fullscreen]);

  const editorClasses = classNames(
    {
      "is-invalid": touched && error,
    },
    "rich-editor-input text-input form-input form-control",
    className
  );

  const customToolbar = React.useMemo(() => {
    const tools = [];
    if (toolbarOptions?.customOptions?.includes("attachment")) {
      tools.push(<Attachment onSelect={onFilesSelect} />);
    }
    if (toolbarOptions?.customOptions?.includes("pdfmerge")) {
      tools.push(<PDFMerge editorState={editorState} onChange={onChange} />);
    }
    tools.push(
      <Fullscreen onToggle={onFullscreenPress} isFullscreen={fullscreen} />
    );
    return tools;
  }, [
    toolbarOptions,
    onFilesSelect,
    onFullscreenPress,
    fullscreen,
    editorState,
    onChange,
  ]);

  const toolbar = {
    ...RICH_EDITOR_TOOLBAR,
    ...toolbarOptions,
  };

  const editor = React.useMemo(
    () => (
      <div className={classNames({ "rich-editor-fullscreen": fullscreen })}>
        <Editor
          wrapperClassName="rich-editor-wrapper"
          toolbarClassName="toolbar-wrapper"
          editorClassName={editorClasses}
          toolbar={toolbar}
          toolbarCustomButtons={customToolbar}
          spellCheck={true}
          placeholder={placeholder}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          handlePastedText={() => false}
          readOnly={readOnly}
        />
      </div>
    ),
    [
      editorClasses,
      toolbar,
      fullscreen,
      placeholder,
      editorState,
      onEditorStateChange,
    ]
  );

  return editor;
};

export default RichEditor;
