import React from "react";
import Container from "react-bootstrap/Container";
import { Button, Col, Row } from "react-bootstrap";
import { compact, filter, map } from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DetailsCard, {
  DetailsCardAction,
} from "../../../components/dashboard/details-card";
import { DetailsCardDataItem } from "../../../components/dashboard/details-card/DetailsCardList";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardBackNavigation from "../../../components/navigation/DashboardBackNavigation";
import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { notify } from "../../../components/notification";
import { useNote } from "../../../hooks/useNote";
import { NoteEntityType } from "../../../graphql/types/models/note";
import CreateUpdateLeadModal from "../../../components/lead/create-update-lead-modal";
import {
  CreateUpdateLead,
  CreateUpdateLeadPayload,
  CreateUpdateLeadResponse,
  DeleteLeadPayload,
  DeleteLeadResponse,
  GetLeadByIdResponse,
  ListLeadStagesResponse,
  ListLeadTagsResponse,
  UpdateLeadTagsPayload,
  UpdateLeadTagsResponse,
} from "../../../models/leads";
import {
  CREATE_UPDATE_LEAD,
  DELETE_LEAD,
  UPDATE_LEAD_TAGS,
} from "../../../graphql/queries/leads/mutations";
import {
  GET_LEAD_BY_ID,
  LIST_LEAD_STAGES,
  LIST_LEAD_TAGS,
} from "../../../graphql/queries/leads/queries";
import { prepareLeadObject } from "../utils";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { CardOperation } from "../../../components/kanban-board/utils";
import { getFullAddress, getFullName } from "../../../utils/text";
import moment from "moment-timezone";
import Loader from "../../../components/loader";
import LeadEstimation from "./lead-estimation";
import EditableTags from "../../../components/editable-tags";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import {
  handleLeadDelete,
  handleLeadUpdate,
} from "../../../graphql/queries/leads/utils";
import { SystemUser, TaskStatus, TodoList } from "../../../models/task";
import { TableRowActionData } from "../../../components/dashboard/table-card/utils";
import { useTasks } from "../../../hooks/useTasks";
import CardTable from "../../../components/dashboard/table-card/CardTable";
import {
  ShowContactModalRef,
  useShowContactModal,
} from "../../../hooks/useShowContactModal";
import { useSalesQuotesQuery } from "../../../hooks/queries/useSalesQuotesQuery";
import Avatar from "../../../components/avatar";
import AvatarGroup from "../../../components/avatar-group";
import DashboardCard from "../../../components/dashboard/card";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../../components/dashboard/card/DashboardCardBody";
import Icon from "../../../components/icons/Icon";
import "./styles.scss";

type Params = {
  id: string;
};

type ContactsClientContainerProps = {} & RouteComponentProps<Params> &
  DashboardContextValue;

const LeadOverviewContainer: React.FC<ContactsClientContainerProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const leadId = match.params.id;
  const history = useHistory();
  const { t } = useTranslation();
  const { renderNotesCard } = useNote(NoteEntityType.Lead, leadId);
  const [showDeleteDialog, setDeleteDialogVisibility] = React.useState(false);
  const showContactModal = React.useRef<ShowContactModalRef>(null);
  const {
    data: leadData,
    loading: leadDataLoading,
    refetch: refetchLeadData,
  } = useQuery<GetLeadByIdResponse>(GET_LEAD_BY_ID, {
    variables: {
      leadId,
    },
    onCompleted: (data) => {
      const preparedLead = prepareLeadObject(
        data?.getLeadById,
        data?.getLeadById.stage._id,
        CardOperation.UPDATE
      );
      setLeadToUpdate(preparedLead);
      refetchTags();
    },
  });

  const { data: listLeadTags, refetch: refetchTags } = useQuery<
    ListLeadTagsResponse
  >(LIST_LEAD_TAGS, {
    fetchPolicy: "cache-and-network",
  });

  const [createUpdateLead] = useMutation<
    CreateUpdateLeadResponse,
    CreateUpdateLeadPayload
  >(CREATE_UPDATE_LEAD, {
    onCompleted: (data) => {
      const preparedLead = prepareLeadObject(
        data?.createUpdateLead,
        data?.createUpdateLead.stage._id,
        CardOperation.UPDATE
      );
      setLeadToUpdate(preparedLead);
    },
  });

  const [updateLeadTags] = useMutation<
    UpdateLeadTagsResponse,
    UpdateLeadTagsPayload
  >(UPDATE_LEAD_TAGS);

  const [deleteLead, { loading: loadingDeleteLead }] = useMutation<
    DeleteLeadResponse,
    DeleteLeadPayload
  >(DELETE_LEAD, {
    update: handleLeadDelete,
  });

  const { data: listLeadStages } = useQuery<ListLeadStagesResponse>(
    LIST_LEAD_STAGES
  );

  const {
    loadTasks,
    tasksList,
    loading: tasksLoading,
    renderCreateUpdateTaskModal,
    openCreateTaskModal,
    handleUpdateStatus,
    setDeleteTarget,
    handleDeleteTask,
    setTaskToUpdate,
  } = useTasks({ filter: { leadId, userId: "" } });

  React.useEffect(() => {
    setNavigationContext({
      ...navigationContext,
      lead: {
        _id: leadId,
        name: leadData?.getLeadById?.name || "",
      },
    });
  }, [setNavigationContext, leadId, leadData]);

  React.useEffect(() => {
    loadTasks();
  }, [leadId, loadTasks]);

  const handleRefetchLeadData = React.useCallback(
    (leadId: string) => {
      refetchLeadData({ leadId });
    },
    [refetchLeadData]
  );

  const handleOnContactName = React.useCallback((id: string) => {
    showContactModal.current?.show(true, id);
  }, []);

  const handleChangeTags = React.useCallback(
    async (tags: string[] | null) => {
      await updateLeadTags({
        variables: {
          leadId,
          tags,
        },
      });
      handleRefetchLeadData(leadId);
    },
    [handleRefetchLeadData, leadId, updateLeadTags]
  );

  const { renderContactDetailsModal } = useShowContactModal(showContactModal);

  const detailsCardData: DetailsCardDataItem[] = React.useMemo(() => {
    const lead = leadData?.getLeadById;

    if (leadDataLoading || !lead) {
      return [];
    }

    return filter(
      [
        {
          icon: "title",
          text: lead?.name,
          title: t("leads.leadName"),
        },
        {
          icon: "description",
          text: lead?.description,
          title: t("common.description"),
        },
        {
          icon: "person",
          title: t("leads.contact"),
          text: lead.contacts.map((item) => (
            <div
              onClick={() => handleOnContactName(item._id)}
              className="text field-text medium contact-name-item"
            >
              {`${getFullName(item)}`}
            </div>
          )),
        },
        {
          icon: "place",
          title: t("common.address"),
          text: getFullAddress(lead),
        },
        {
          icon: "manage_accounts",
          title: t("leads.assignee"),
          text: lead?.assignedTo?.map((item) => getFullName(item)).join(", "),
        },
        {
          icon: "label",
          title: t("leads.stage"),
          text: lead.stage.name,
        },
        {
          icon: "attach_money",
          title: t("placeholders.budget"),
          text: `$${lead?.budget?.toLocaleString("en-US") || 0}`,
        },
        {
          icon: "event_available",
          title: t("leads.expectedCloseDate"),
          text: lead?.expectedCloseDate
            ? moment(lead?.expectedCloseDate).format("Do MMMM YYYY")
            : "",
        },
        {
          icon: "label",
          title: t("leads.expectedCloseDate"),
          text: (
            <EditableTags
              items={leadData?.getLeadById.tags}
              allTags={listLeadTags?.listLeadTags}
              onSubmit={handleChangeTags}
              badgeStyle="badge-style"
              placeholder={t("placeholders.editableTags")}
            />
          ),
        },
      ],
      (r) => !!r.text
    );
  }, [leadData, leadDataLoading]);

  const [
    leadToUpdate,
    setLeadToUpdate,
  ] = React.useState<CreateUpdateLead | null>(null);
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  const openUpdateModal = React.useCallback(() => {
    setShowCreateModal(true);
  }, [setShowCreateModal]);

  const closeUpdateModal = React.useCallback(() => {
    setShowCreateModal(false);
  }, [setShowCreateModal]);

  const detailsCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("common.edit"),
        onClick: openUpdateModal,
        className: "button info",
        disabled: leadDataLoading,
      },
    ],
    [t, leadDataLoading, openUpdateModal]
  );

  const { salesQuotes } = useSalesQuotesQuery();

  const handleUpdateLead = React.useCallback(
    async (data: CreateUpdateLead) => {
      try {
        if (!data || !leadToUpdate) {
          return;
        }

        await createUpdateLead({
          variables: {
            lead: { ...data, _id: leadToUpdate._id },
          },
          update: handleLeadUpdate(leadToUpdate._id as string),
        });

        notify({
          title: t("leads.updateLead"),
          content: t("leads.success.updateLead"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("leads.updateLead"),
          content: t("leads.errors.updateLead"),
        });
      }
      closeUpdateModal();
    },
    [closeUpdateModal, leadToUpdate, createUpdateLead, t]
  );

  const openDeleteDialog = React.useCallback(() => {
    setDeleteDialogVisibility(true);
  }, []);

  const closeDeleteDialog = React.useCallback(() => {
    setDeleteDialogVisibility(false);
  }, []);

  const handleContactDelete = React.useCallback(async () => {
    if (!leadId) {
      return;
    }
    try {
      await deleteLead({ variables: { leadId } });
      notify({
        title: t("leads.deleteLead"),
        content: t("leads.success.deleteLead"),
      });

      history.push("/leads");
    } catch (e) {
      notify({
        error: true,
        title: t("leads.deleteLead"),
        content: t("leads.errors.deleteLead"),
      });
    }
    closeDeleteDialog();
  }, [closeDeleteDialog, deleteLead, history, leadId, t]);

  const renderAvatars = (members: SystemUser[]) => {
    return members.map((user) => {
      return (
        <Avatar
          key={user._id}
          outlined
          fallbackColor="light"
          userName={getFullName(user)}
          rounded
          width="35px"
          height="35px"
          url={user.profileImage?.url}
        />
      );
    });
  };

  const documentsTableData = React.useMemo(() => {
    return {
      columns: compact([
        {
          valueKey: "task",
          title: t("tasks.task"),
          sortable: true,
        },
        {
          valueKey: "description",
          title: t("tasks.description"),
          sortable: true,
        },
        {
          valueKey: "dueDate",
          title: t("tasks.dueDate"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
        {
          valueKey: "completed",
          title: t("tasks.status"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            value ? TaskStatus.COMPLETE : TaskStatus.INCOMPLETE,
        },
        {
          valueKey: "members",
          title: t("tasks.assignedTo"),
          sortable: true,
          formatValue: (row: any, column: any, value: SystemUser[]) => (
            <AvatarGroup>{renderAvatars(value)}</AvatarGroup>
          ),
        },
      ]),
      rows: map(tasksList?.listTodoList, (item) => ({
        cells: {
          ...item,
          job: item.job?.name,
        },
      })),
    };
  }, [t, tasksList]);

  const handleEditTask = React.useCallback(
    (row?: TodoList) => {
      if (row) {
        setTaskToUpdate(row);
      }
    },
    [setTaskToUpdate]
  );

  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const openRemoveDialog = React.useCallback(
    (row?: TodoList) => {
      if (row) {
        setDeleteTarget(row._id);
        confirmRef?.current?.show(true);
      }
    },
    [confirmRef]
  );

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget("");
    confirmRef?.current?.show(false);
  }, [confirmRef]);

  const tableRowActions: TableRowActionData<TodoList>[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "tasks-list",
        options: [
          {
            icon: "delete",
            outlined: true,
            id: "edit",
            label: t("tasks.editTask"),
            onClick: handleEditTask,
            shouldRender: (row: TodoList) => !row.jobScheduleItem,
          },
          {
            icon: "check_circle",
            outlined: true,
            id: "complete",
            label: t("tasks.completeTask"),
            onClick: handleUpdateStatus,
            shouldRender: (row: TodoList) => !row.completed,
          },
          {
            icon: "unpublished",
            outlined: true,
            id: "incomplete",
            label: t("tasks.incompleteTask"),
            onClick: handleUpdateStatus,
            shouldRender: (row: TodoList) => row.completed,
          },
          {
            icon: "delete",
            outlined: true,
            id: "delete",
            label: t("tasks.deleteTask"),
            onClick: openRemoveDialog,
          },
        ],
      },
    ],

    [t, openRemoveDialog, handleUpdateStatus, handleEditTask]
  );

  if (leadDataLoading) {
    return (
      <div className="lead loader-center">
        <Loader />
      </div>
    );
  }

  return (
    <Container className="lead" fluid>
      <DashboardBackNavigation
        routeId={NAVIGATION_ROUTES.LEADS_SECTION.OVERVIEW}
      />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.LEADS_SECTION.LEAD} />
      <Helmet title={t("navigation.leadsSection.lead")} />
      {leadData?.getLeadById && (
        <>
          <CreateUpdateLeadModal
            data={leadToUpdate}
            listStages={listLeadStages?.listLeadStages || []}
            show={showCreateModal}
            onClose={closeUpdateModal}
            onSubmit={handleUpdateLead}
            onDelete={openDeleteDialog}
            showDeleteButton={true}
          />
          <ConfirmDialog
            disabled={loadingDeleteLead}
            title={t("leads.deleteLead")}
            show={showDeleteDialog}
            onSubmit={handleContactDelete}
            onClose={closeDeleteDialog}
          >
            <span className="field-text">{t("leads.deleteLeadMessage")}</span>
          </ConfirmDialog>
        </>
      )}

      <Row className="w-100">
        <Col lg={4} xs={12} className="pb-sm-5 pb-lg-0 pr-sm-4 fontBolder">
          <div className="card-wrapper">
            <DetailsCard
              fullHeight
              title={t("common.details")}
              data={detailsCardData}
              actions={detailsCardActions}
            />
          </div>
          <div className="card-wrapper">
            <LeadEstimation
              refetchLeadData={handleRefetchLeadData}
              estimationsList={salesQuotes ?? []}
              leadId={leadData?.getLeadById._id ?? null}
              leadEstimate={leadData?.getLeadById.estimation ?? null}
              contacts={leadData?.getLeadById?.contacts}
            />
          </div>
        </Col>
        <Col lg={8} xs={12} className="pb-sm-5 pb-lg-0 pl-sm-4">
          <DashboardCard>
            <DashboardCardHeader className="justify-content-between">
              {t("navigation.tasks")}
              <div className="d-flex mr-3">
                <Button
                  className="button large bg-transparent"
                  block
                  onClick={openCreateTaskModal}
                >
                  <Icon name="add" />
                  {t("leads.createTask")}
                </Button>
              </div>
            </DashboardCardHeader>
            <DashboardCardBody>
              {tasksList?.listTodoList &&
              tasksList?.listTodoList?.length > 0 ? (
                <CardTable
                  tableId="leadTasks"
                  isDataLoading={tasksLoading}
                  table={documentsTableData}
                  alignEnd={true}
                  rowActions={tableRowActions}
                />
              ) : (
                <EmptyPlaceholder message={t("leads.emptyTasksPlaceholder")} />
              )}
            </DashboardCardBody>
          </DashboardCard>
          {renderContactDetailsModal()}
          <ConfirmDialog
            ref={confirmRef}
            onClose={closeRemoveDialog}
            onSubmit={handleDeleteTask}
            title={t("tasks.deleteTask")}
            confirm={t("common.delete")}
          >
            <div className="field-text">{t("tasks.removeMessage")}</div>
          </ConfirmDialog>
          <div className="pt-sm-5 lead-notes">{renderNotesCard()}</div>
        </Col>
        {renderCreateUpdateTaskModal()}
      </Row>
    </Container>
  );
};

export default withDashboardContext(LeadOverviewContainer);
