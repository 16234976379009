import React, { useRef, useState } from "react";
import { filter, find } from "lodash";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../components/dashboard/dropdown";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../../components/confirm-dialog";
import {
  BulkUpdateSchedulePayload,
  ScheduleBulkAction,
  ScheduleCategoryItem,
} from "../../../../models/job";
import RescheduleTaskModal, {
  RescheduleTaskModalRef,
} from "../../../../components/job-schedule/reschedule-tasks-modal";

export function useSelectRow(
  calendarStateRef: any,
  onBulkAction: (values: BulkUpdateSchedulePayload, rows: any) => void,
  openAssignTasksModal: (checkedTasks: ScheduleCategoryItem[]) => void
) {
  const { t } = useTranslation();
  const [actionCount, setActionCount] = useState(0);
  const [hasSelection, setHasSelection] = useState(false);
  const [checkedTasks, setCheckedTasks] = useState<ScheduleCategoryItem[] | []>(
    []
  );
  const deleteRef = useRef<ConfirmDialogRef>();
  const completeRef = useRef<ConfirmDialogRef>();
  const rescheduleRef = useRef<RescheduleTaskModalRef>();

  const checkSelection = (rows: any, checked: boolean) => {
    const items = Object.keys(rows).map((row: any) => {
      const currentItem = rows[row];

      if (currentItem.parentId && currentItem.checked) {
        return {
          _id: currentItem.id.split("-")[1],
          name: currentItem?.name ?? "",
          description: currentItem?.description ?? "",
          startDate: currentItem?.startDate ?? "",
          endDate: currentItem?.endDate ?? "",
          progress: Number(currentItem?.progress) ?? 0,
          predecessor: currentItem?.predecessor ?? null,
          supplier: currentItem?.supplier?._id ?? null,
          user: currentItem?.user?._id ?? null,
          reminder: {
            daysBefore: 0,
            timezone: "",
          },
        };
      }
    });

    if (checked || !!find(rows, { checked: true })) {
      setHasSelection(true);
    } else {
      setHasSelection(false);
    }

    setCheckedTasks(items.filter(Boolean) as ScheduleCategoryItem[]);
  };
  const getSelection = () => {
    const calendarState = calendarStateRef.current;
    return filter(calendarState.get(`config.list.rows`), { checked: true });
  };

  const handleSelectRow = (id: string) => {
    const calendarState = calendarStateRef.current;
    const rows = calendarState.get(`config.list.rows`);
    const checked = !rows[id].checked;
    rows[id].checked = checked;

    if (!rows[id].parentId) {
      const tasks = filter(rows, { parentId: id });
      tasks.forEach((task) => {
        task.checked = rows[id].checked;
      });
    }
    calendarState.update(`config.list.rows`, rows);
    checkSelection(rows, checked);
    // const selected = calendarState.get(`config.plugin.Selection.selected`);
    // console.log({selected});
  };

  const handleSelectAllRows = (checked: boolean) => {
    const calendarState = calendarStateRef.current;
    const rows = calendarState.get(`config.list.rows`);
    for (let id in rows) {
      rows[id].checked = checked;
    }
    calendarState.update(`config.list.rows`, rows);
    checkSelection(rows, checked);
  };

  const handleSelectAction = (type: ScheduleBulkAction) => () => {
    const rows = getSelection();

    if (rows.length) {
      setActionCount(rows.length);
      if (type === ScheduleBulkAction.DELETE) {
        deleteRef.current?.show(true);
      } else if (type === ScheduleBulkAction.RESCHEDULE) {
        rescheduleRef.current?.show(true, rows);
      } else if (type === ScheduleBulkAction.COMPLETE) {
        setActionCount(filter(rows, (r) => !!r.parentId).length);
        completeRef.current?.show(true);
      } else if (type === ScheduleBulkAction.ASSIGN) {
        openAssignTasksModal(checkedTasks);
      }
    }
  };

  const handleDeleteConfirm = React.useCallback(() => {
    const rows = getSelection();
    if (rows.length) {
      onBulkAction(
        {
          action: ScheduleBulkAction.DELETE,
        },
        rows
      );
    }
  }, [calendarStateRef]);

  const handleCompleteConfirm = React.useCallback(async () => {
    const rows = filter(getSelection(), (r) => !!r.parentId);
    if (rows.length) {
      await onBulkAction(
        {
          action: ScheduleBulkAction.COMPLETE,
        },
        rows
      );
      checkSelection(getSelection(), false);
    }
  }, [calendarStateRef]);

  const handleReschedule = React.useCallback(
    async (values: BulkUpdateSchedulePayload) => {
      const rows = getSelection();
      if (rows.length) {
        rescheduleRef.current?.show(false);
        await onBulkAction(values, rows);
        checkSelection(getSelection(), false);
        return true;
      }
    },
    [calendarStateRef, rescheduleRef]
  );

  const renderWithSelected = React.useCallback(() => {
    if (!hasSelection) return null;
    const items = [
      {
        id: "reschedule",
        label: t("schedule.reschedule"),
        onClick: handleSelectAction(ScheduleBulkAction.RESCHEDULE),
      },
      {
        id: "assign",
        label: t("schedule.assign"),
        onClick: handleSelectAction(ScheduleBulkAction.ASSIGN),
      },
      {
        id: "complete",
        label: t("common.markComplete"),
        onClick: handleSelectAction(ScheduleBulkAction.COMPLETE),
      },
      {
        id: "delete",
        label: t("common.delete"),
        onClick: handleSelectAction(ScheduleBulkAction.DELETE),
      },
    ];

    return (
      <>
        <Dropdown
          label={t("schedule.withSelected")}
          icon="expand_more"
          id="category-dropdown"
          items={items}
        />
        <ConfirmDialog
          ref={deleteRef}
          title={t("schedule.deleteTasks")}
          onSubmit={handleDeleteConfirm}
        >
          <span className="field-text">
            {t("schedule.deleteTaskWithCount", { count: actionCount })}
          </span>
        </ConfirmDialog>
        <ConfirmDialog
          ref={completeRef}
          title={t("schedule.completeTasks")}
          onSubmit={handleCompleteConfirm}
        >
          <span className="field-text">
            {t("schedule.completeTaskWithCount", { count: actionCount })}
          </span>
        </ConfirmDialog>
        <RescheduleTaskModal ref={rescheduleRef} onSubmit={handleReschedule} />
      </>
    );
  }, [hasSelection, handleSelectAction]);

  return {
    handleSelectRow,
    handleSelectAllRows,
    renderWithSelected,
  };
}
