import React, { forwardRef } from "react";
import ClientHeaderDropdown from "./ClientHeaderDropdown";
import CreateClient, {
  CreateClientRef,
} from "../../../containers/modals/create-client";
import HeaderPageAction from "../../dashboard/header/HeaderPageAction";
import ClientHeaderTop from "./ClientHeaderTop";
import "./styles.scss";
import { JobContact } from "../../../models/job-contact";
import { ContactNameListItem } from "../../../models/client";
import classNames from "classnames";

type ClientHeaderProps = {
  showClientsList: boolean;
  toggleClientList: () => void;
  onRemove: (id: string) => void;
  jobContacts?: JobContact[];
  contacts?: ContactNameListItem[];
  selectedClient: string | null;
  onAccessGrant: (id: string) => void;
  onAccessRevoke: (id: string, contactId: string) => void;
  onAddContact: (id: string) => void;
  updating: boolean;
  onCreatedRecord?: (contactId: string) => void;
  isReadonly?: boolean;
};

const ClientHeader: React.FC<ClientHeaderProps> = (props, ref) => {
  const {
    toggleClientList,
    showClientsList,
    contacts,
    onRemove,
    selectedClient,
    jobContacts,
    onAccessGrant,
    onAccessRevoke,
    updating,
    onAddContact,
    onCreatedRecord,
    isReadonly = false,
  } = props;

  const createClientRef = React.useRef<CreateClientRef>(null);

  const renderClientsDropdown = () => {
    if (!showClientsList) {
      return null;
    }

    return (
      <ClientHeaderDropdown
        updating={updating}
        contacts={contacts}
        openClientModal={() => createClientRef.current?.show(true)}
        jobContacts={jobContacts}
        onRemove={onRemove}
        onAccessRevoke={onAccessRevoke}
        onAccessGrant={onAccessGrant}
        selectedClient={selectedClient}
        onClientChange={onAddContact}
        isReadonly={isReadonly}
      />
    );
  };

  const containerClasses = React.useMemo(
    () =>
      classNames("client-header-container", {
        "client-header-container__open": showClientsList,
      }),
    [showClientsList]
  );

  const width = React.useMemo(() => {
    let value = 140;
    if (jobContacts && jobContacts.length > 0) {
      value += 35 + (jobContacts.length - 1) * 25;
    }
    return `${value}px`;
  }, [jobContacts]);

  return (
    <>
      <CreateClient ref={createClientRef} onCreatedRecord={onCreatedRecord} />
      <HeaderPageAction order="2">
        <div className={containerClasses} style={{ width }} ref={ref}>
          <ClientHeaderTop
            onAddClick={toggleClientList}
            clients={jobContacts}
            isReadonly={isReadonly}
          />
          {renderClientsDropdown()}
        </div>
      </HeaderPageAction>
    </>
  );
};

export default forwardRef(ClientHeader);
