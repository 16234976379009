import React, { forwardRef, useImperativeHandle } from "react";
import { useDropzone } from "react-dropzone";
import { FormikProps } from "formik";
import { chain } from "lodash";

type ModalAttachmentProps = {
  formikProps: FormikProps<any>;
};

export type ModalAttachmentRef = {
  open: () => void;
};

const ModalAttachment: React.FC<ModalAttachmentProps> = (
  { formikProps },
  ref
) => {
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = chain(formikProps.values.attachments)
        .concat(acceptedFiles)
        .compact()
        .uniqBy((f) => [f.path, f.lastModified, f.size].join("."))
        .value();
      formikProps.setFieldValue("attachments", newFiles);
    },
    [formikProps]
  );

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <div>
      <input {...getInputProps()} />
    </div>
  );
};

export default forwardRef(ModalAttachment);
