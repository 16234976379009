import React, { Ref } from "react";

interface CallbackListener {
  (display: boolean): void;
}

export type ModalDisplayRef = {
  show: (show: boolean) => void;
  shouldShow: () => boolean;
  addDisplayListener?: (listener: CallbackListener) => void;
  removeDisplayListener?: (listener: CallbackListener) => void;
};

export function useModalDisplay<T>(
  ref: Ref<T> | undefined,
  additionalMethods?: T,
  additionalDeps?: React.DependencyList
) {
  const [shouldShow, setShow] = React.useState(false);
  const [displayListeners, setDisplayListeners] = React.useState<
    CallbackListener[]
  >([]);

  const hide = React.useCallback(() => {
    setShow(false);
  }, []);

  const show = React.useCallback(() => {
    setShow(true);
  }, []);

  React.useImperativeHandle(
    ref,
    () =>
      ({
        show: (show: boolean) => {
          setShow(show);
        },
        shouldShow: () => shouldShow,
        addDisplayListener: (listener: CallbackListener) => {
          setDisplayListeners(
            displayListeners.filter((l) => l !== listener).concat(listener)
          );
        },
        removeDisplayListener: (listener: CallbackListener) => {
          setDisplayListeners(displayListeners.filter((l) => l !== listener));
        },
        ...additionalMethods,
      } as any),
    [shouldShow, displayListeners, additionalDeps]
  );

  React.useEffect(() => {
    displayListeners?.forEach((cb) => cb(shouldShow));
  }, [shouldShow, displayListeners]);

  return { shouldShow, hide, show };
}
