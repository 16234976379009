import React from "react";
import { useQuery } from "@apollo/client";
import { LIST_JOBS } from "../graphql/queries/job/queries";
import { ListJobsResponse } from "../graphql/types/models/job";
import { JobStatus } from "../models/job";

export function useJobs() {
  const { data: jobsResponse, loading: jobsLoading } = useQuery<
    ListJobsResponse
  >(LIST_JOBS, {
    variables: {
      status: JobStatus.ACTIVE,
    },
  });

  const jobs = React.useMemo(() => jobsResponse?.listJobs || [], [
    jobsResponse,
  ]);

  return { jobs, jobsLoading };
}
