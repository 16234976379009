import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type TakeOffListPageProps = {
  page: number;
  onPageSelect?: (page: number) => void;
  children?: any;
};
const TakeOffListPage: React.FC<TakeOffListPageProps> = ({
  page,
  onPageSelect,
  children,
}) => {
  const { t } = useTranslation();

  const handlePageSelect = React.useCallback(
    (page: number) => () => onPageSelect?.(page),
    [onPageSelect]
  );

  return (
    <ul className="take-off-page-list" onClick={handlePageSelect(page)}>
      <li className="d-flex">
        {t("plansSection.page", { pageNumber: page })}:
      </li>
      {children}
    </ul>
  );
};

export default TakeOffListPage;
