import React from "react";
import Icon from "../../icons/Icon";
import classNames from "classnames";
import SVGIcon, { SVGIconNames } from "../../icons/SVGIcon";

type HeaderActionButton = {
  width?: string;
  onClick?: () => void;
  icon?: string;
  outlined?: boolean;
  children?: string;
  svg?: boolean;
  iconClassName?: string;
};

const HeaderActionButton: React.FC<HeaderActionButton> = (props) => {
  const {
    icon,
    outlined,
    children,
    onClick,
    width,
    svg,
    iconClassName,
  } = props;

  const iconClasses = classNames(iconClassName, {
    "icon-button": !children,
  });

  const btnStyles = {
    width,
  };

  const renderIcon = () => {
    if (!icon) {
      return;
    }

    if (svg) {
      return <SVGIcon name={icon as SVGIconNames} />;
    }

    return <Icon name={icon} outlined={outlined} className={iconClasses} />;
  };

  return (
    <div className="header-action-button" onClick={onClick} style={btnStyles}>
      {renderIcon()}
      <span className="no-wrap info-span">{children}</span>
    </div>
  );
};

export default HeaderActionButton;
