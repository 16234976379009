import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthenticationCard from "../authentication-card";
import SignUpConfirmBody from "./SignUpConfirmBody";
import SignUpConfirmFooter from "./SignUpConfirmFooter";
import "./styles.scss";

type SignUpConfirmProps = {
  failed: boolean;
  loading: boolean;
  onResendClick: (e: any) => void;
};

const SignUpConfirm: React.FC<SignUpConfirmProps> = ({
  failed,
  loading,
  onResendClick,
}) => {
  const { t } = useTranslation();

  if (loading || !failed) {
    return null;
  }

  return (
    <AuthenticationCard
      title={t("authentication.activateYourAccount")}
      container={Form}
      containerProps={{
        className: "authentication-card-container sign-up-confirm-card",
      }}
      body={SignUpConfirmBody}
      bodyProps={{ t }}
      footer={SignUpConfirmFooter}
      footerProps={{
        t,
        onResendClick,
      }}
    />
  );
};

export default SignUpConfirm;
