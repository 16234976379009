import React from "react";
import Select, { ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";
import { find, isEmpty, sortBy } from "lodash";
import classNames from "classnames";
import InputGroup from "react-bootstrap/InputGroup";
import { BadgeProps } from "react-bootstrap";

import "./styles.scss";
import { TextInputProps } from "../text";
import AutocompleteControl from "./AutocompleteControl";
import AutocompleteSelectInput from "./AutocompleteInput";
import AutocompleteMenu from "./AutocompleteMenu";
import AutocompleteMenuOption, {
  AutocompleteMenuOptionProps,
} from "./AutocompleteMenuOption";
import AutocompleteMenuList from "./AutocompleteMenuList";
import AutocompleteValueContainer from "./AutocompleteValueContainer";
import AutocompleteDropdownIndicator from "./AutocompleteDropdownIndicator";
import InputAppend from "../input-apend";
import { FieldAppend } from "../../GenericFormBody";

export type AutocompleteInputOption = {
  value: string;
  label: string;
  badge?: string;
  badgeVariant?: BadgeProps["variant"];
};

const autoCompleteStyles = {
  menuPortal: (base: any) => ({ ...base, zIndex: 1050 }),
};

export type AutocompleteInputProps = {
  onChange: (value: string) => void;
  options: AutocompleteInputOption[];
  onValueChange?: (input: string) => void;
  onAutocompleteInputChange?: (input: string) => void;
  onTouch?: () => void;
  append?: FieldAppend;
  menuPortalTarget?: HTMLElement;
  menuPosition?: "fixed" | "absolute";
  menuPlacement?: "auto" | "bottom" | "top";
  optionComponent?: React.FC<AutocompleteMenuOptionProps>;
  disabled?: boolean;
  isClearable?: boolean;
  isEditable?: boolean;
  sortByAsc?: boolean;
} & Omit<TextInputProps, "onChange">;

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  value,
  options,
  placeholder,
  onAutocompleteInputChange,
  error,
  touched,
  name,
  onBlur,
  onChange,
  append,
  onValueChange,
  onTouch,
  menuPortalTarget,
  menuPosition,
  menuPlacement,
  rowIndex,
  optionComponent = AutocompleteMenuOption,
  disabled,
  isClearable = false,
  isEditable = false,
  className,
  sortByAsc,
}) => {
  const [
    selectedOption,
    setSelectedOption,
  ] = React.useState<AutocompleteInputOption | null>(null);
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>("");
  const { t } = useTranslation();

  const handleChange = React.useCallback(
    (option: any, meta: ActionMeta<AutocompleteInputOption>) => {
      setSelectedOption(option);
      setAutocompleteValue(option ? option.label : "");
      onTouch?.();
    },
    [onTouch]
  );

  const handleBlur = React.useCallback(
    (event: React.SyntheticEvent<any>) => {
      onBlur?.(event);
      onTouch?.();
    },
    [onBlur, onTouch]
  );

  const handleInputValueChange = React.useCallback(
    (input: string, { action }) => {
      if (action === "input-change") setAutocompleteValue(input);
    },
    []
  );

  React.useEffect(() => {
    onAutocompleteInputChange && onAutocompleteInputChange(autocompleteValue);

    if (isEmpty(autocompleteValue)) {
      setSelectedOption(null);
      return;
    }
    if (
      autocompleteValue &&
      selectedOption &&
      selectedOption?.label !== autocompleteValue
    ) {
      setSelectedOption(null);
      return;
    }
  }, [onAutocompleteInputChange, autocompleteValue]);

  React.useEffect(() => {
    if (value) {
      const option = find(
        options,
        (opt: AutocompleteInputOption) => opt.value === value
      );

      option && setSelectedOption(option);
      option && setAutocompleteValue(option.label);
    } else {
      // TODO: see if this is a good idea or not
      // setSelectedOption(null); // disabled as it causes an infinite loop
    }
  }, [value]);

  React.useEffect(() => {
    const value = selectedOption ? selectedOption.value : "";

    onChange(value);
    onValueChange && onValueChange(value);
  }, [selectedOption]);

  const handleAppendClick = React.useCallback(() => {
    append?.onClick &&
      append.onClick(name, value, rowIndex, append.formikProps);
  }, [append, name, value, rowIndex]);

  const sortedOptionsByAsc = React.useMemo(() => sortBy(options, "label"), [
    options,
  ]);

  const renderAppend = () => {
    if (!append) {
      return null;
    }

    return (
      <InputAppend
        {...append}
        fieldName={name}
        rowIndex={rowIndex}
        onClick={handleAppendClick}
      />
    );
  };

  return (
    <InputGroup className="form-input-group">
      <Select
        name={name}
        value={selectedOption}
        onChange={handleChange}
        // inputValue={isEditable ? autocompleteValue : undefined} // WUN-216
        disabled={disabled}
        isClearable={isClearable}
        styles={autoCompleteStyles}
        onInputChange={handleInputValueChange}
        onBlur={handleBlur}
        options={sortByAsc ? sortedOptionsByAsc : options}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        placeholder={!selectedOption ? placeholder : null}
        menuPortalTarget={menuPortalTarget}
        className={classNames("autocomplete", className, {
          "is-invalid": touched && !!error,
        })}
        error={error}
        touched={touched}
        components={{
          Control: AutocompleteControl,
          Input: AutocompleteSelectInput,
          Menu: AutocompleteMenu,
          MenuList: AutocompleteMenuList,
          Option: optionComponent,
          ValueContainer: AutocompleteValueContainer,
          DropdownIndicator: AutocompleteDropdownIndicator,
          Placeholder: () => null,
          IndicatorSeparator: null,
        }}
      />
      {renderAppend()}
    </InputGroup>
  );
};

export default AutocompleteInput;
