import React from "react";
import { MessagePerson } from "../../../models/job-messaging";
import Avatar from "../../avatar";
import { getFullName } from "../../../utils/text";
import AvatarGroup from "../../avatar-group";
import { useTranslation } from "react-i18next";
import { useJobContacts } from "../../../hooks/useJobContacts";

type MembersDetailsProps = {
  internalMembers?: MessagePerson[];
  externalMembers?: MessagePerson[];
  isExternalChat: boolean;
  jobId?: string;
};

export const MembersDetails: React.FC<MembersDetailsProps> = ({
  internalMembers,
  externalMembers,
  jobId,
  isExternalChat,
}) => {
  const { t } = useTranslation();
  const { contactsAccess } = useJobContacts(jobId);

  const renderAvatars = (members: MessagePerson[]) => {
    return members.map((user) => {
      return (
        <Avatar
          key={user._id}
          outlined
          fallbackColor="light"
          userName={getFullName(user)}
          rounded
          width="35px"
          height="35px"
          url={user.profileImage?.url}
        />
      );
    });
  };

  const members = isExternalChat ? externalMembers : internalMembers;

  return (
    <>
      <div className="d-flex align-items-center ml-4">
        <span className="text-uppercase mr-2">
          {t("communication.members")}
        </span>
        <AvatarGroup>{members && renderAvatars(members)}</AvatarGroup>
      </div>
      {isExternalChat && (
        <div className="d-flex align-items-center  ml-4">
          <span className="text-uppercase mr-2">
            {t("communication.clients")}
          </span>
          {contactsAccess?.length ? (
            <AvatarGroup>
              {renderAvatars(contactsAccess as MessagePerson[])}
            </AvatarGroup>
          ) : (
            <span className="sub-header-text">
              {t("clientAccess.noClientInvited")}
            </span>
          )}
        </div>
      )}
    </>
  );
};
