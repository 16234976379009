import { PurchaseOrderStatus } from "./purchaseOrder";
import { Media } from "./media";

export enum SupplierCertificationType {
  Certificate = "Certificate",
  License = "License",
  Qualification = "Qualification",
  Others = "Others",
}

export type SupplierCategory = {
  _id: string;
  name: string;
  updatedAt: string;
  createdAt: string;
};

export type SupplierReal = {
  _id: string;
  abn: string;
  category: SupplierCategory;
  business_name: string;
  contact_person: string;
  email: string;
  phone_number: string;
  phone_secondary: string;
  fax_number: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  pricing: string;
  schedule_items: string;
  bills: string;
  is_deleted: boolean;
  country: string;
  updatedAt: string;
  createdAt: string;
};

export type CreateSupplierPayload = {
  _id?: string;
  abn: string;
  contact_person: string;
  email: string;
  categoryId?: string;
  new_category_name?: string;
  business_name: string;
  address: string;
  address2: string;
  city: string;
  phone_number: string;
  phone_secondary: string;
  state: string;
  postcode: string;
  fax_number: string;
  mobile: string;
};

export type SupplierModalFields = Omit<CreateSupplierPayload, "mobile">;

export type UpdateSupplierPayload = {
  _id: string;
  abn: string;
  categoryId: string;
  contact_person: string;
  email: string;
  business_name: string;
  address: string;
  address2: string;
  city: string;
  phone_number: string;
  phone_secondary: string;
  state: string;
  postcode: string;
  fax_number: string;
  mobile: string;
};

export type SupplierListCategoryItem = {
  _id: string;
  name: string;
};

export type SupplierOrderJob = {
  _id: string;
  name: string;
};

export type SupplierOrder = {
  _id: string;
  orderNumber: number;
  date: string;
  deliveryDate: string;
  reference: string;
  status: PurchaseOrderStatus;
  job: SupplierOrderJob | null;
};

export type SupplierDetails = {
  _id: string;
  abn: string;
  business: SupplierBusiness;
  business_name: string;
  email: string;
  contact_person: string;
  address: string;
  address2: string;
  phone_number: string;
  phone_secondary: string;
  city: string;
  mobile: string;
  state: string;
  postcode: string;
  fax_number: string;
  category?: SupplierListCategoryItem;
  purchase_orders: SupplierOrder[];
  __typename: string;
};

export type SupplierBusiness = {
  _id: string;
  name: string;
};

export type SupplierListItem = {
  _id: string;
  abn: string;
  business_name: string;
  contact_person: string;
  email: string;
  address: string;
  address2: string;
  state: string;
  city: string;
  postcode: string;
  phone_number: string;
  phone_secondary: string;
  __typename: string;
};

export type SupplierPhones = {
  _id: string;
  business_name: string;
  phone_number: string;
  phone_secondary: string;
};

export type SupplierCertification = {
  _id: string;
  name: string;
  document_type: SupplierCertificationType;
  files: Media[];
  expiry_date: string;
  note: string;
};

export type SupplierCertificatioPayload = {
  name: string;
  document_type: SupplierCertificationType;
  expiry_date: string;
  note: string;
  files?: File[];
};
