import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TimesheetCategory } from "../../../models/timesheet";

type TimesheetsCategoryItemProps = {
  isSelected: boolean;
  status: TimesheetCategory;
  total: number;
  onClick?: (status: TimesheetCategory) => void;
};

const TimesheetsCategoryItem: React.FC<TimesheetsCategoryItemProps> = (
  props
) => {
  const { t } = useTranslation();

  const { isSelected, status, total, onClick } = props;

  const getItemClass = (): string => {
    return classNames("jobs-timesheets-list-item", {
      "jobs-timesheets-selected": isSelected,
    });
  };

  const handleClick = React.useCallback(() => {
    return onClick && onClick(status);
  }, [onClick, status]);

  return (
    <div className={getItemClass()} onClick={handleClick}>
      <div className="item-name">{status}</div>
      <div className="right-side">
        <div className="item-total">
          {t("common.currency", { amount: total })}
        </div>
      </div>
    </div>
  );
};

export default TimesheetsCategoryItem;
