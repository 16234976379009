import { gql } from "@apollo/client";
import { NOTE_FRAGMENT } from "./fragments";

export const LIST_NOTES = gql`
  query($entityType: NoteEntityType!, $entityId: ID!) {
    listNotes(entityType: $entityType, entityId: $entityId) {
      ...NoteFragment
    }
  }
  ${NOTE_FRAGMENT}
`;
