import { findIndex, map, omit, pick } from "lodash";
import { JobCostingTableItem } from ".";
import { Rounding } from "../../../components/calculator/utils";
import { AssemblyListItem } from "../../../models/assembly";
import { JobCostingItem, JobCostingItemPayload } from "../../../models/job";
import {
  PurchaseOrderItemPayload,
  PurchaseOrderItemSubItem,
} from "../../../models/purchaseOrder";
import { CategoryCostingItem } from "../../../models/costing";

export const toggleCostingItem = (
  currentItems: JobCostingTableItem[],
  newItem: JobCostingTableItem,
  forceValue?: boolean
) => {
  const items = [...currentItems];
  const index = findIndex(items, { _id: newItem._id });

  if (forceValue) {
    // ensure the item remains checked
    if (forceValue === true) {
      // ensure order is retained
      if (index >= 0) {
        items.splice(index, 1);
      }
      items.push(newItem);
    } else if (forceValue === false) {
      // ensure the item is unchecked
      if (index >= 0) {
        items.splice(index, 1);
      }
    }
  } else {
    if (index >= 0) {
      items.splice(index, 1);
    } else {
      items.push(newItem);
    }
  }
  return items;
};

export const getPreparedSelectedItems = (
  items: JobCostingTableItem[]
): PurchaseOrderItemPayload[] => {
  const payload: PurchaseOrderItemPayload[] = [];

  items.map((item) => {
    const currentItems =
      item?.items?.map((item) => {
        return {
          ...omit(item, [
            "__typename",
            "wastage",
            "raw_quantity",
            "rounding",
            "items",
          ]),
        };
      }) || [];
    payload.push({
      UOM: item.UOM,
      cost: item.cost,
      name: item.name,
      quantity: item.quantity,
      raw_quantity: item.raw_quantity.toString(),
      rounding: item.rounding || Rounding.NONE,
      wastage: item.wastage || 0,
      hasGST: item.hasGST,
      items: currentItems as PurchaseOrderItemSubItem[],
      costingItemId: item._id,
      costingCategoryName: item.category?.name,
    });
  });

  return payload;
};

export const getPreparedItems = (
  items?: JobCostingItemPayload[],
  assemblies?: AssemblyListItem[]
) => {
  return items?.map((costingItem) => {
    const costItem = {
      ...pick(costingItem, ["name", "UOM", "markup_cost", "order"]),
      _id: costingItem._id || undefined,
      raw_quantity:
        costingItem?.raw_quantity || costingItem?.quantity?.toString(),
      quantity: parseFloat(`${costingItem.quantity}`),
      cost: parseFloat(`${costingItem.cost}`),
      rounding: costingItem?.rounding || Rounding.NONE,
      wastage: Number(costingItem?.wastage) || 0,
      real_cost: parseFloat(`${costingItem.real_cost}`) || 0,
      hasGST: costingItem.hasGST,
      items: map(costingItem.items, (item) => {
        return omit(
          {
            ...item,
          },
          ["_id", "__typename", "total"]
        );
      }),
    };

    return omit(costItem, [
      "__typename",
      "category",
      "purchaseOrder",
      "purchaseOrderItems",
      "progressClaimItems",
      "timesheetItems",
      "variation",
      "roster",
      "quoteRequestStatus",
      "is_locked",
      "is_allowance",
      "assemblyId",
      "type",
    ]);
  });
};

export const getCostingClaimedPercent = (item: JobCostingItem) => {
  const claimedTotal =
    item.progressClaimItems?.reduce(
      (total, claim) => total + claim?.amount,
      0
    ) || 0;
  return (
    (claimedTotal / ((item.markup_cost || item.cost) * item.quantity)) * 100
  );
};

export const getCostingClaimedAmount = (item: JobCostingItem) => {
  const progressClaimAmount =
    item.progressClaimItems?.reduce(
      (total, claim) => total + (claim?.markupSubTotal || 0),
      0
    ) || 0;

  const purchaseOrderReceiptAmount =
    item.purchaseOrderItems?.reduce((total, purchaseOrderItem) => {
      let cost = 0;
      purchaseOrderItem.purchaseOrder?.receipts?.forEach((receipt) => {
        if (receipt.progressClaimItem) {
          const receiptItem = receipt.items?.find(
            (i) => i.purchaseOrderItem?._id === purchaseOrderItem._id
          );
          if (receiptItem) {
            let receiptItemCost = receiptItem.cost * receiptItem.quantity;
            const markup = receipt.progressClaimItem.markup;
            if (markup) {
              receiptItemCost = receiptItemCost * (markup / 100 + 1);
            }
            cost += receiptItemCost;
          }
        }
      });
      return total + cost;
    }, 0) || 0;

  const claimedAmount = progressClaimAmount + purchaseOrderReceiptAmount;
  // const claimedProfit = claimedAmount
  //   ? (item.cost * item.quantity) - item.real_cost
  //   : 0;

  const claimedProfit = claimedAmount ? claimedAmount - item.real_cost : 0;
  return {
    claimedAmount,
    claimedProfit,
  };
};

export const getProgressClaimItems = (item: JobCostingTableItem) => {
  let progressClaimItems = item.progressClaimItems ?? [];
  item.purchaseOrderItems?.forEach((poItem) => {
    if (poItem?.purchaseOrder?.receipts?.length) {
      poItem.purchaseOrder.receipts.forEach((receipt) => {
        if (receipt.progressClaimItem) {
          progressClaimItems = progressClaimItems.concat(
            receipt.progressClaimItem
          );
        }
      });
    }
  });
  return progressClaimItems;
};

export const getTimesheetItems = (item: JobCostingTableItem) => {
  let timesheetItems = item.timesheetItems ?? [];
  return timesheetItems;
};

export const getPurchaseOrderItems = (item: JobCostingTableItem) => {
  let purchaseOrderItems = item.purchaseOrderItems ?? [];
  return purchaseOrderItems;
};

export const getRosterItems = (item: JobCostingTableItem) => {
  let purchaseOrderItems = item.rosterItems ?? [];
  return purchaseOrderItems;
};

export const getVariationItems = (item: JobCostingTableItem) =>
  item.variationItems ?? [];

export const getQuoteRequestsItems = (
  item: JobCostingTableItem | CategoryCostingItem
) => item.quoteRequestItems ?? [];
