import { TFunction } from "i18next";
import { map } from "lodash";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import { AddPriceListPayload } from "../../../../models/price-list";
import { SupplierListItem } from "../../../../models/supplier";

const createAddPriceListFields = (
  t: TFunction,
  suppliers: SupplierListItem[],
  openSupplierModal: () => void
): GenericFormFields<AddPriceListPayload> => {
  const supplierOptions = map(suppliers, (supplier) => ({
    value: supplier._id,
    label: supplier.business_name,
  }));
  return {
    name: {
      valueKey: "name",
      type: "text",
      label: t("priceList.name"),
      placeholder: t("placeholders.name"),
      inputProps: {
        required: true,
      },
    },
    supplier: {
      type: "select",
      label: t("schedule.supplier"),
      placeholder: t("placeholders.supplier"),
      valueKey: "supplier",
      autocompleteProps: {
        options: supplierOptions,
      },
      inputProps: {
        required: true,
      },
      append: {
        onClick: openSupplierModal,
        icon: "add",
      },
    },
  };
};

export default createAddPriceListFields;
