import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { map } from "lodash";
import {
  TableCardDataRow,
  TableRowActionData,
} from "../../dashboard/table-card/utils";
import TableCard, { EmptyTablePlaceholder } from "../../dashboard/table-card";
import LeftModal from "../../left-modal";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalFooter from "../../left-modal/LeftModalFooter";
import { PrimaryFolder, TableDocumentData } from "../../../models/documents";
import { DocumentsTable } from "../../../containers/documents/documents-table";
import EmptyPlaceholder from "../../empty-placeholder";
import SearchInput from "../../search-input";
import "./styles.scss";

type SelectFolderModalProps = {
  onSubmit: (id: string) => void;
  show: boolean;
  onClose: () => void;
  folderId?: string;
  tableLoading?: boolean;
  folders: PrimaryFolder[];
  isReadonly?: boolean;
  isRootFolder: boolean;
  selectFolder: (id: string) => void;
  parentTree: PrimaryFolder[] | null;
  selectRootFolders: () => void;
  rootName?: string;
  onAddNewFolderClick: () => void;
};
export enum DocumentType {
  FOLDER = "Folder",
  FILE = "File",
}

const SelectFolderModal: React.FC<SelectFolderModalProps> = (props, ref) => {
  const {
    onSubmit,
    show,
    onClose,
    folderId,
    tableLoading,
    folders,
    isReadonly,
    isRootFolder,
    selectFolder,
    parentTree,
    selectRootFolders,
    onAddNewFolderClick,
  } = props;
  const [selectedFolder, setSelectedFolder] = React.useState("");

  const { t } = useTranslation();

  const [searchInput, setSearchInput] = React.useState("");
  const filteredFolders = React.useMemo(() => {
    return (
      folders?.filter((folder) =>
        folder.name.toLowerCase().includes(searchInput.toLowerCase())
      ) || []
    );
  }, [folders, searchInput]);

  const currentFolderId = React.useMemo(() => {
    return parentTree && parentTree[parentTree?.length - 1]._id;
  }, [parentTree]);

  const handleSelect = React.useCallback(() => {
    if (!selectedFolder && !currentFolderId) return;
    if (!currentFolderId) {
      onSubmit(selectedFolder);
      setSearchInput("");
    } else {
      onSubmit(selectedFolder || currentFolderId);
      setSearchInput("");
    }
  }, [currentFolderId, onSubmit, selectedFolder]);

  const transformArraysForTable = (folders: PrimaryFolder[]) => {
    return folders.map(
      (folder): TableDocumentData => ({
        _id: folder._id,
        name: folder.name,
        size: null,
        type: DocumentType.FOLDER,
        createdAt: folder.createdAt,
      })
    );
  };

  const documentsTableData = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("documents.name"),
          sortable: true,
        },
      ],
      rows: map(transformArraysForTable(filteredFolders), (item) => ({
        cells: {
          ...item,
        },
      })),
    };
  }, [filteredFolders]);

  const handleOnClose = React.useCallback(() => {
    setSelectedFolder("");
    setSearchInput("");
    onClose();
  }, [onClose]);

  React.useEffect(() => {
    setSelectedFolder("");
  }, [parentTree]);

  const toggleSelectedFolder = React.useCallback(
    (row: any) => {
      if (selectedFolder === row._id) {
        setSelectedFolder("");
      } else {
        setSelectedFolder(row._id);
      }
    },
    [selectedFolder]
  );

  const tableLeftRowActions: TableRowActionData<
    DocumentsTable
  >[] = React.useMemo(
    () =>
      isReadonly
        ? []
        : [
            {
              icon: "check_box",
              dropdownId: "document",
              onClick: toggleSelectedFolder,
              shouldRender: (row) => selectedFolder === row._id,
            },
            {
              icon: "check_box_outline_blank",
              dropdownId: "document",
              onClick: toggleSelectedFolder,
              shouldRender: (row) => selectedFolder !== row._id,
            },
          ],
    [isReadonly, toggleSelectedFolder, selectedFolder]
  );

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<DocumentsTable>) => {
      if (row.cells.type === DocumentType.FOLDER) {
        selectFolder(row.cells._id);
        setSearchInput("");
      }
    },
    [selectFolder]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("documents.emptySubFolderSearchPlaceholder", {
        filter: searchInput,
      }),
    }),
    [searchInput, t]
  );

  return (
    <>
      <LeftModal
        className={"select-folder-modal"}
        show={show}
        onHide={handleOnClose}
      >
        <LeftModalHeader
          title={t("documents.selectFolder")}
          onClose={handleOnClose}
        />
        <LeftModalBody className="flex-column">
          <div className="header-container">
            <div className="breadcrumbs-container">
              <Button
                className={"button breadcrumb-buttons"}
                onClick={() => {
                  selectRootFolders();
                  setSearchInput("");
                }}
              >
                {t("common.root")}
              </Button>

              {parentTree &&
                parentTree.map((folder, index) => (
                  <React.Fragment key={folder._id}>
                    <span className="breadcrumb-delimiter">/</span>
                    <Button
                      onClick={() => {
                        selectFolder(folder._id);
                        setSearchInput("");
                      }}
                      className={"button breadcrumb-buttons"}
                    >
                      {`${folder.name}`}
                    </Button>
                  </React.Fragment>
                ))}
            </div>
            <div className="search-input-container">
              <SearchInput
                value={searchInput}
                onChange={setSearchInput}
                showCancelButton
                className="search-input"
                appendSearchIcon
              />
            </div>
          </div>

          {folders.length ? (
            <TableCard
              tableId={folderId ?? "documents"}
              isDataLoading={tableLoading}
              data={documentsTableData}
              alignEnd={true}
              onRowClick={handleRowClick}
              alignLeftActionsEnd={false}
              leftRowActions={tableLeftRowActions}
              leftRowActionsClassName="icon"
              emptyPlaceholder={emptyPlaceholder}
            />
          ) : (
            <div className="mt-5">
              <EmptyPlaceholder
                message={t("documents.emptySubFolderPlaceholder")}
              />
            </div>
          )}
        </LeftModalBody>
        <LeftModalFooter>
          <div className="w-100 d-flex justify-content-between">
            <div className="new-folder-button">
              <Button
                variant="primary"
                className="button large success"
                onClick={onAddNewFolderClick}
              >
                {t("documents.newFolder")}
              </Button>
            </div>

            <div className="d-flex">
              <div className="mr-2">
                <Button
                  variant="secondary"
                  className="button large info"
                  onClick={handleOnClose}
                >
                  {t("common.cancel")}
                </Button>
              </div>

              <Button
                variant="primary"
                disabled={isRootFolder && !selectedFolder}
                className="button large success"
                onClick={handleSelect}
              >
                {t("common.select")}
              </Button>
            </div>
          </div>
        </LeftModalFooter>
      </LeftModal>
    </>
  );
};

export default forwardRef(SelectFolderModal);
