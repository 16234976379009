import React from "react";
import DOMPurify from "dompurify";
import classNames from "classnames";

type DashboardCardField = {
  title?: string;
  content?: string;
  htmlContent?: string;
  height?: string;
  placeholder?: string;
  onClick?: () => void;
  wrapWords?: boolean;
};

const DashboardCardField: React.FC<DashboardCardField> = (props) => {
  const {
    title,
    content,
    htmlContent,
    height,
    placeholder,
    onClick,
    wrapWords,
  } = props;

  const renderBody = () => {
    if (!content && !htmlContent && placeholder) {
      return <span className="card-field-placeholder">{placeholder}</span>;
    }
    const elementContent = htmlContent ? (
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }}
      />
    ) : (
      content
    );
    if (onClick) {
      return (
        <a onClick={onClick} className="card-field-link">
          {elementContent}
        </a>
      );
    }
    return elementContent;
  };

  return (
    <div className="card-field">
      <div className="field-text card-field-header text-truncate">{title}</div>
      <div
        className={classNames("field-text card-field-content", {
          "card-field-text-content": !htmlContent,
          "card-field-text-content-wrap": wrapWords,
        })}
        style={{ minHeight: height }}
      >
        {renderBody()}
      </div>
    </div>
  );
};

export default DashboardCardField;
