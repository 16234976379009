import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../../utils/yup";
import { AddPriceListPayload } from "../../../../models/price-list";

export default function createPriceListSchema(t: TFunction) {
  return yup.object().shape<AddPriceListPayload>({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "priceList.name")
      ),
    supplier: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "priceList.supplier")
      ),
  });
}
