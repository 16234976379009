import React from "react";
import { last } from "lodash";
import { FormikProps } from "formik";
import LeftModal from "../../left-modal";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import { useTranslation } from "react-i18next";
import Calculator from "../../calculator";
import { CalcBag, CalcSubmitFunction, Rounding } from "../../calculator/utils";
import { TakeoffListItem } from "../../../models/take-off";
import "./styles.scss";
import takeOff from "../../../containers/quotes/take-off";
import FloatingTakeOffModal from "../../plans/take-off-modals/floating-take-off";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";

export type CalcFieldData = {
  fieldName: string;
  rowIndex?: number;
};

type CalculatorModalProps = {
  show: boolean;
  onClose: () => void;
  rounding?: Rounding;
  wastage?: string;
  onSubmit: (formik: FormikProps<any>, calc: CalcBag) => void;
  formikProps: FormikProps<any>;
  fieldName?: string;
  title?: string;
  initialValue?: number | string;
  takeoff?: TakeoffListItem;
  takeoffs?: TakeoffListItem[] | null;
  takeoffProps?: {
    name?: string;
    canCreateTakeoff?: boolean;
    salesQuoteId?: string;
  };
};

const CalculatorModal: React.FC<CalculatorModalProps> = (props) => {
  const {
    show,
    onClose,
    formikProps,
    onSubmit,
    fieldName,
    initialValue,
    rounding,
    wastage,
    takeoff,
    takeoffs,
    takeoffProps,
    title,
  } = props;
  const { t } = useTranslation();
  const [initialTakeoff, setInitialTakeoff] = React.useState<TakeoffListItem>();
  const [isTakeoffOpen, setIsTakeoffOpen] = React.useState(false);
  const floatingTakeoffRef = React.useRef<ModalDisplayRef>(null);

  React.useEffect(() => {
    setInitialTakeoff(takeoff);
  }, [takeoff]);

  const handleSubmit: CalcSubmitFunction = React.useCallback(
    (value, calcBag) => {
      if (!fieldName) {
        return;
      }

      // update the UOM for the costing row item
      if (calcBag.takeOff) {
        const name = last(fieldName.split("."));
        name &&
          formikProps.setFieldValue(
            fieldName.replace(name, "UOM"),
            calcBag.takeOff.UOM
          );
      }
      const field = fieldName.split(".");
      formikProps.setFieldValue(
        [field[0], "wastage"].join("."),
        calcBag.wastage.toFixed()
      );

      formikProps.setFieldValue(
        [field[0], "rounding"].join("."),
        calcBag.rounding
      );
      formikProps.setFieldValue(
        [field[0], "raw_quantity"].join("."),
        calcBag.quantity
      );
      formikProps.setFieldValue(fieldName, value);

      onSubmit && onSubmit(formikProps, calcBag);
    },
    [fieldName, formikProps, onSubmit]
  );

  const handleTakeoffDisplayChange = React.useCallback(
    (display: boolean) => setIsTakeoffOpen(display),
    []
  );

  const handleCreateTakeoff = React.useCallback(
    (data: TakeoffListItem) => {
      setInitialTakeoff(data);
    },
    [setInitialTakeoff]
  );

  React.useEffect(() => {
    floatingTakeoffRef.current?.addDisplayListener?.(
      handleTakeoffDisplayChange
    );
    return () => {
      floatingTakeoffRef.current?.removeDisplayListener?.(
        handleTakeoffDisplayChange
      );
    };
  }, [floatingTakeoffRef]);

  const handleCreateTakeoffPress = React.useCallback(() => {
    floatingTakeoffRef.current?.show(true);
  }, [floatingTakeoffRef]);

  const handleClose = React.useCallback(() => {
    onClose();
    setInitialTakeoff(undefined);
  }, [onClose, setInitialTakeoff]);

  const headerTitle = React.useMemo(() => {
    return !title ? (
      <div>{t("common.calculator")}</div>
    ) : (
      <div className="d-flex align-items-center">
        <div>{t("common.calculator")}</div>
        <div className="title-badge">{title}</div>
      </div>
    );
  }, [t, title]);

  return (
    <>
      {takeoffProps?.canCreateTakeoff && (
        <FloatingTakeOffModal
          ref={floatingTakeoffRef}
          salesQuoteId={takeoffProps?.salesQuoteId}
          onCreate={handleCreateTakeoff}
          name={takeoffProps?.name}
        />
      )}
      <LeftModal show={show} onHide={handleClose} className="calculator-modal">
        <LeftModalHeader title={headerTitle} onClose={handleClose} />
        <LeftModalBody>
          {
            <Calculator
              initialWastage={wastage}
              initialRounding={rounding}
              initialTakeoff={initialTakeoff}
              onClose={handleClose}
              onSubmit={handleSubmit}
              initialExpresion={initialValue?.toString() || ""}
              takeoffs={takeoffs || []}
              onCreateTakeoffPress={
                takeoffProps?.canCreateTakeoff
                  ? handleCreateTakeoffPress
                  : undefined
              }
              disableKeypress={isTakeoffOpen}
            />
          }
        </LeftModalBody>
      </LeftModal>
    </>
  );
};

export default CalculatorModal;
