import React from "react";
import { FormikProps } from "formik";
import { TableCardAction } from "../../dashboard/table-card/utils";
import ToggleInput from "../inputs/toggle";
import {
  GenericFormTableInputControl,
  GenericFormTableToggleControl,
} from "../GenericFormBody";
import TextInput from "../inputs/text";
import FormField from "../form-field";
import { Button } from "react-bootstrap";

type TableFormControlsProps = {
  formikProps: Omit<FormikProps<any>, "handleSubmit" | "handleReset">;
  actionButton?: TableCardAction;
  tableTitle?: string;
  withoutTitle?: boolean;
  rightActionButton?: () => JSX.Element;
  toggles?: GenericFormTableToggleControl[];
  inputs?: GenericFormTableInputControl[];
  onToggleChange: (
    toggle: GenericFormTableToggleControl
  ) => (value: boolean) => void;
  onInputChange: (
    input: GenericFormTableInputControl
  ) => (e: React.ChangeEvent<any>) => void;
  onInputBlur: (
    input: GenericFormTableInputControl
  ) => (e: React.ChangeEvent<any>) => void;
  isTwoRow?: boolean;
  secondRowValue?: boolean;
  onToggleSecondRow: (value: boolean) => void;
};

const TableFormControls: React.FC<TableFormControlsProps> = (props) => {
  const {
    formikProps,
    tableTitle,
    toggles,
    inputs,
    onToggleChange,
    isTwoRow,
    onToggleSecondRow,
    onInputChange,
    onInputBlur,
    secondRowValue,
    withoutTitle,
    rightActionButton,
  } = props;

  return (
    <div className="d-flex justify-content-between table-form-controls w-100">
      {(tableTitle || isTwoRow || withoutTitle) && (
        <div className="d-flex flex-column justify-content-between w-100">
          {tableTitle && (
            <div className="table-form-title pb-3">{tableTitle}</div>
          )}
          <div className="d-flex flex-row justify-content-between w-100">
            <div>
              {isTwoRow && (
                <ToggleInput
                  name="isTwoRow"
                  label="Show notes"
                  value={secondRowValue || false}
                  onChange={onToggleSecondRow}
                />
              )}
              {toggles && (
                <div className="toggles-container">
                  {toggles?.map((toggle) => (
                    <ToggleInput
                      key={toggle.name}
                      name={toggle.name}
                      label={toggle.label}
                      value={
                        formikProps.values[toggle.name] || toggle.value || false
                      }
                      onChange={onToggleChange(toggle)}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="d-flex flex-column">
              {inputs && (
                <div className="inputs-container">
                  {inputs?.map((input) => (
                    <div className="d-flex flex-row">
                      <FormField
                        name={input.name}
                        label={input.label}
                      ></FormField>
                      <TextInput
                        placeholder={input.placeholder}
                        name={input.name}
                        type={input.type}
                        value={formikProps.values[input.name] || false}
                        onChange={onInputChange(input)}
                        onBlur={onInputBlur(input)}
                        debounceChangeEvent
                        className="text-input form-input form-input-compact form-control"
                      />
                    </div>
                  ))}
                </div>
              )}
              {rightActionButton && rightActionButton()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableFormControls;
