import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { JOB_TOTALS } from "../../../graphql/queries/job-costing/queries";
import { JobTotalResponse } from "../../../graphql/types/models/job-costing";
import ReactTooltip from "react-tooltip";

type TotalsProps = {
  totalEstimatedCost: number | undefined;
  totalRealCost: number | undefined;
  totalMarkup: number | undefined;
  title?: string;
  jobId?: string;
};

const JobCostTotals: React.FC<TotalsProps> = (props) => {
  const {
    title,
    totalRealCost,
    totalEstimatedCost,
    totalMarkup,
    jobId,
  } = props;

  const [gst, setGst] = useState(0);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [totalVariation, setTotalVariation] = useState(0);

  const [
    getTotals,
    { data: jobTotals, loading: jobTotalLoading },
  ] = useLazyQuery<JobTotalResponse>(JOB_TOTALS, {
    variables: {
      jobId,
    },
  });
  /*
  useEffect(() => {
    if (jobId != undefined) {
      getTotals({
        variables: {
          jobId,
        },
      });
    }
  }, [jobId]);
*/
  useEffect(() => {
    if (totalEstimatedCost != undefined && totalRealCost != undefined) {
      setSubtotal(totalEstimatedCost + (totalMarkup || 0) - totalRealCost);
      setGst(subtotal * 0.1);
      setTotal(subtotal + gst);
    }

    if (jobTotals) {
      setTotalVariation(jobTotals.totals.totalVariation);
      setTotal(subtotal + jobTotals.totals.totalVariation);
    }
  }, [
    totalEstimatedCost,
    totalMarkup,
    subtotal,
    gst,
    totalRealCost,
    setTotal,
    jobTotals,
    setTotalVariation,
  ]);

  const { t } = useTranslation();

  return (
    <div className="total">
      {title && (
        <div className="field-text field-text--underlined total-title">
          {title}
        </div>
      )}
      <div>
        <div className="item">
          <div>{t("costing.estimateTotal")}</div>
          <div>{t("common.currency", { amount: totalEstimatedCost || 0 })}</div>
        </div>
        {!!totalMarkup && (
          <div className="item" data-tip data-for="markup">
            <div>{t("costing.markupTotal")}</div>
            <div>{t("common.currency", { amount: totalMarkup || 0 })}</div>
            <ReactTooltip id="markup" aria-haspopup="true">
              {t("costing.markupTooltip")}
            </ReactTooltip>
          </div>
        )}
        <div className="item">
          <div>{t("costing.costPriceTotal")}</div>
          <div>{t("common.currency", { amount: totalRealCost || 0 })}</div>
        </div>

        {/*
        <div className="item">
          <div>{t("costing.estimateVariation")}</div>
          <div>{t("common.currency", { amount: totalVariation || 0 })}</div>
        </div>
        */}
        {/* <div className="item">
          <div>{t("costing.total")}</div>
          <div>{t("common.currency", { amount: subtotal || 0 })}</div>
        </div> */}

        {/*
        <div className="item">
          <div>{t("costing.gst")}</div>
          <div>{t("common.currency", { amount: gst || 0 })}</div>
        </div>

        <div className="item">
          <div>{t("costing.total")}</div>
          <div>{t("common.currency", { amount: total || 0 })}</div>
        </div>
         */}
      </div>
    </div>
  );
};

export default JobCostTotals;
