import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Prompt } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { find, head } from "lodash";
import moment from "moment";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import {
  ClientGetSelectionResponse,
  ClientSelectSelectionItemsResponse,
  EnumSelectionLinkedEntityType,
  EnumSelectionStatus,
  SelectionCategory,
  SelectionCategoryItemOption,
  SelectionCategoryItemType,
} from "../../../../graphql/types/models/selections";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";
import SelectionCategories from "../../../../components/selection/selection-categories";
import DashboardCard from "../../../../components/dashboard/card";
import DashboardCardHeader from "../../../../components/dashboard/card/DashboardCardHeader";
import SelectionBudge from "../../../../components/selection/badge";
import DashboardCardBody from "../../../../components/dashboard/card/DashboardCardBody";
import CategorySelectionItem from "../../../../components/selection/selection-category-item";
import EmptyPlaceholder from "../../../../components/empty-placeholder";
import { CLIENT_GET_SELECTION } from "../../../../graphql/queries/selection/queries";
import { CLIENT_SET_SELECTION_ITEMS } from "../../../../graphql/queries/selection/mutation";
import { notify } from "../../../../components/notification";
import "./styles.scss";
import AcceptSignatureDialog, {
  AcceptSignatureDialogRef,
  DigitalSignatureInput,
} from "../../../../components/accept-signature-dialog";
import { uploadFiles } from "../../../../utils/files";

type SelectionsContainerProps = DashboardContextValue;

const ClientQuoteSelections: React.FC<SelectionsContainerProps> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();

  const salesQuoteId = navigationContext?.clientQuote?._id || "";
  const acceptSignatureDialogRef = React.useRef<AcceptSignatureDialogRef>(null);

  const [
    selectedCategory,
    setCategory,
  ] = React.useState<SelectionCategory | null>(null);
  const [uploading, setUploading] = React.useState(false);

  const { data: quoteSelection, loading: quoteSelectionLoading } = useQuery<
    ClientGetSelectionResponse
  >(CLIENT_GET_SELECTION, {
    variables: {
      linkedEntityId: salesQuoteId,
      linkedEntityType: EnumSelectionLinkedEntityType.ESTIMATION,
    },
    fetchPolicy: "cache-and-network",
  });

  const [clientSelectSelectionItems] = useMutation<
    ClientSelectSelectionItemsResponse
  >(CLIENT_SET_SELECTION_ITEMS, {
    onCompleted: () => {
      notify({
        title: t("client.quotes.selections"),
        content: t("client.quotes.success.savedSelections"),
      });
      setSelectedOptions([]);
    },
    onError: () => {
      notify({
        error: true,
        title: t("client.quotes.selections"),
        content: t("client.quotes.error.savedSelections"),
      });
    },
  });

  React.useEffect(() => {
    const data = quoteSelection?.clientGetSelection.categories;
    const selectionCategory =
      find(data, { _id: selectedCategory?._id }) || head(data);
    setCategory(selectionCategory || null);
  }, [quoteSelection]);

  const [selectedOptions, setSelectedOptions] = React.useState<
    { itemId: string; optionId: string; categoryId: string; comment?: string }[]
  >([]);

  const closeApproveOptionConfirmModal = React.useCallback(() => {
    acceptSignatureDialogRef.current?.show(false);
  }, []);

  const handleSelectCategoryItems = React.useCallback(
    async (signature: DigitalSignatureInput, file: File) => {
      const result = await clientSelectSelectionItems({
        variables: {
          linkedEntityId: salesQuoteId,
          linkedEntityType: EnumSelectionLinkedEntityType.ESTIMATION,
          signature,
          items: selectedOptions,
        },
      });
      const resultSignature =
        result.data?.clientSelectSelectionItems.signature.file;

      if (resultSignature) {
        setUploading(true);
        await uploadFiles([resultSignature], [file]);
        setUploading(false);
      }
      closeApproveOptionConfirmModal();
    },
    [
      clientSelectSelectionItems,
      closeApproveOptionConfirmModal,
      salesQuoteId,
      selectedOptions,
    ]
  );

  const handleCategorySelect = React.useCallback(
    (category: SelectionCategory) => {
      setCategory(category);
    },
    []
  );

  const openClientSelectOptionsConfirm = React.useCallback(() => {
    acceptSignatureDialogRef.current?.show(true);
  }, []);

  const handleClientSelectOption = React.useCallback(
    (
      option: SelectionCategoryItemOption,
      item: SelectionCategoryItemType,
      comment?: string
    ) => {
      if (!selectedCategory) return;
      if (comment) {
        return setSelectedOptions(
          selectedOptions.map((selected) => {
            if (selected.optionId !== option._id) return selected;
            return { ...selected, comment: comment };
          })
        );
      }
      const selectedItem = {
        itemId: item._id,
        optionId: option._id,
        categoryId: selectedCategory?._id,
      };
      const addedItems = selectedOptions.map((item) => item.itemId);
      if (addedItems.includes(item._id)) {
        const newItems = selectedOptions.map((currentItem) => {
          if (currentItem.itemId !== item._id) return currentItem;
          return { ...currentItem, optionId: option._id, comment: "" };
        });
        setSelectedOptions(newItems);
      } else {
        setSelectedOptions(selectedOptions.concat(selectedItem));
      }
    },
    [selectedOptions, selectedCategory]
  );

  const overDue = React.useMemo(() => {
    return moment().isAfter(moment(selectedCategory?.dueDate).endOf("day"));
  }, [selectedCategory]);

  const isSelectedCategories = React.useMemo(() => {
    const selectedCategoriesCount = selectedCategory?.items.filter(
      (item) =>
        item.status !== EnumSelectionStatus.SELECTED &&
        item.status !== EnumSelectionStatus.COMPLETED
    );
    return Boolean(selectedCategoriesCount?.length);
  }, [selectedCategory]);

  return (
    <Container fluid className="m-0 p-0 h-100 quote-selections">
      <Helmet title={t("navigation.quotesSection.selections")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.QUOTES_SECTION.SELECTIONS}
      />
      <AcceptSignatureDialog
        ref={acceptSignatureDialogRef}
        title={t("client.quotes.selectOption")}
        onSubmit={handleSelectCategoryItems}
        onClose={closeApproveOptionConfirmModal}
      />

      <Prompt
        when={selectedOptions.length > 0}
        message={() => t("client.quotes.leaveSelectionPage")}
      />

      <Row className="h-100">
        <Col lg={4} xs={12}>
          <SelectionCategories
            displayNavButtons={false}
            categories={quoteSelection?.clientGetSelection.categories}
            selectedCategory={selectedCategory}
            selectCategory={handleCategorySelect}
            disabled={true}
            selectionStatus={quoteSelection?.clientGetSelection.status}
            isClient={true}
            showSaveSelectionsButton={true}
            onSaveSelectionsClick={openClientSelectOptionsConfirm}
            selectedOptions={selectedOptions}
          />
        </Col>
        <Col lg={8} xs={12}>
          <div>
            <DashboardCard>
              <DashboardCardHeader className="justify-content-between">
                <div className="d-flex align-items-center text-capitalize mr-3">
                  {selectedCategory?.name}
                  <SelectionBudge
                    status={
                      selectedCategory?.status ===
                        EnumSelectionStatus.COMPLETED ||
                      selectedCategory?.status === EnumSelectionStatus.SELECTED
                        ? selectedCategory?.status
                        : EnumSelectionStatus.INCOMPLETE
                    }
                  />
                  {overDue &&
                    selectedCategory?.status !==
                      EnumSelectionStatus.COMPLETED &&
                    isSelectedCategories && (
                      <SelectionBudge status={EnumSelectionStatus.OVERDUE} />
                    )}
                </div>
              </DashboardCardHeader>
              <DashboardCardBody className="category-container">
                <div className="category-details">
                  <div>
                    {selectedCategory?.dueDate && (
                      <div className="category-dueDate">
                        {t("common.due", {
                          date: moment(selectedCategory?.dueDate).format(
                            "DD/MM/YYYY"
                          ),
                        })}
                      </div>
                    )}
                    {selectedCategory?.description && (
                      <div className="category-description">
                        {selectedCategory?.description}
                      </div>
                    )}
                  </div>
                </div>
                {selectedCategory?.items.length ? (
                  selectedCategory?.items.map((item) => {
                    return (
                      <CategorySelectionItem
                        key={item._id}
                        item={item}
                        isLocked={true}
                        isClient={true}
                        onClientSelectOption={handleClientSelectOption}
                        isLoading={quoteSelectionLoading || uploading}
                        selectedOptions={selectedOptions.map((option) => ({
                          optionId: option.optionId,
                          comment: option?.comment || "",
                        }))}
                      />
                    );
                  })
                ) : (
                  <EmptyPlaceholder
                    message={t("selections.emptyCategoryItemsPlaceholder")}
                  />
                )}
              </DashboardCardBody>
            </DashboardCard>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withDashboardContext(ClientQuoteSelections);
