import { WithT } from "i18next";
import { FormikProps } from "formik";
import React from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

type SignUpActivateBodyProps = WithT;

const SignUpActivateBody: React.FC<SignUpActivateBodyProps> = ({ t }) => {
  return (
    <Container className="p-0 m-0 mw-100">
      <p className="field-text">{t("authentication.signUpThankyou")}</p>
      <p className="field-text">{t("authentication.signUpNext")}</p>
    </Container>
  );
};

export default SignUpActivateBody;
