import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { UpdateProfilePayload } from "../../../models/profile";
import { TFunction } from "i18next";

const createUpdateProfileFields = (
  t: TFunction,
  isClient?: boolean
): GenericFormFields<UpdateProfilePayload> => {
  return {
    first_name: {
      type: "text",
      label: t("profile.firstName"),
      placeholder: t("placeholders.firstName"),
      valueKey: "first_name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    last_name: {
      type: "text",
      label: t("profile.lastName"),
      placeholder: t("placeholders.lastName"),
      valueKey: "last_name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    email: {
      type: "text",
      label: t("profile.email"),
      placeholder: t("placeholders.email"),
      valueKey: "email",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    ...(isClient && {
      mobile: {
        type: "text",
        label: t("contacts.phone"),
        placeholder: t("contacts.phone"),
        valueKey: "mobile",
        inputProps: {
          type: "text",
        },
      },
    }),
    ...(!isClient && {
      signature: {
        type: "rich-editor",
        label: t("profile.emailSignature"),
        placeholder: t("placeholders.signature"),
        valueKey: "signature",
        richEditorProps: {
          textKey: "signatureText",
          rawKey: "signatureRaw",
          toolbarOptions: {
            // image: {
            //   // icon: image,
            //   className: undefined,
            //   component: undefined,
            //   popupClassName: undefined,
            //   urlEnabled: true,
            //   uploadEnabled: true,
            //   alignmentEnabled: true,
            //   uploadCallback: console.log,
            //   previewImage: false,
            //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            //   alt: { present: false, mandatory: false },
            //   defaultSize: {
            //     height: 'auto',
            //     width: 'auto',
            //   },
            // },
          },
        },
      },
      title: {
        type: "group-title",
        placeholder: t("profile.notify"),
      },
      disabledEmailNotifications: {
        type: "toggle",
        valueKey: "disabledEmailNotifications",
        toggleProps: {
          label: t("profile.notifications.disableEmailNotify"),
        },
      },
      disabledPushNotifications: {
        type: "toggle",
        valueKey: "disabledPushNotifications",
        toggleProps: {
          label: t("profile.notifications.disableMobilePushNotify"),
        },
      },
    }),
  };
};

export default createUpdateProfileFields;
