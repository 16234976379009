import React from "react";
import { map } from "lodash";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import { ListNotificationResponse } from "../../../graphql/types/models/notification";
import { LIST_NOTIFICATIONS } from "../../../graphql/queries/notification/queries";
import DashboardNotificationItem from "./NotificationItem";
import EmptyPlaceholder from "../../empty-placeholder";
import "./styles.scss";

type DashboardNotificationCardProps = {};

const DashboardNotificationCard: React.FC<DashboardNotificationCardProps> = () => {
  const { t } = useTranslation();

  const { data, loading } = useQuery<ListNotificationResponse>(
    LIST_NOTIFICATIONS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const renderNotifications = React.useCallback(() => {
    if (loading) return null;
    if (data?.listNotifications?.length) {
      return map(data?.listNotifications, (notification) => (
        <DashboardNotificationItem
          key={notification._id}
          notification={notification}
        />
      ));
    }
    return (
      <EmptyPlaceholder
        message={t("dashboard.emptyNotificationsPlaceholder")}
      />
    );
  }, [data, loading]);

  return (
    <DashboardCard className="dashboard-notifications">
      <DashboardCardHeader className="justify-content-between">
        {t("dashboard.notifications")}
      </DashboardCardHeader>
      <DashboardCardBody>{renderNotifications()}</DashboardCardBody>
    </DashboardCard>
  );
};

export default DashboardNotificationCard;
