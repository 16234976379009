import React from "react";

export type SiteContext = {
  isUser: boolean;
  isAffiliate: boolean;
  isSubcontractor: boolean;
  isClient: boolean;
  isAdmin: boolean;
};

const isSubcontractor =
  !!process.env.REACT_APP_SUBCONTRACTOR ||
  /^sub|beta-sub/i.test(window.location.hostname);
const isAdmin =
  !!process.env.REACT_APP_ADMIN ||
  /^admin|beta-admin/i.test(window.location.hostname);
const isClient =
  !!process.env.REACT_APP_CLIENT ||
  /^client|beta-client/i.test(window.location.hostname);
const isAffiliate =
  !!process.env.REACT_APP_AFFILIATE ||
  /^affiliate|beta-affiliate/i.test(window.location.hostname);

const isUser = !isSubcontractor && !isAdmin && !isClient && !isAffiliate;

export default React.createContext<SiteContext>({
  isUser,
  isAffiliate,
  isClient,
  isSubcontractor,
  isAdmin,
});
