import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import {
  compact,
  debounce,
  filter,
  find,
  get,
  includes,
  isEmpty,
  map,
  omit,
  reduce,
} from "lodash";
import moment from "moment";
import { round } from "mathjs";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FormikProps } from "formik";
import Totals from "../../costing/total";
import claimSchema from "./Claim.schema";
import {
  FormikPropGetSetValues,
  GenericFormFields,
  GenericFormTable,
  renderField,
  renderTableForm,
  UpdateRowFn,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import {
  calculateAmountToDate,
  calculateCostPlusTotal,
  calculateEditingAmountToDate,
  createClaimFields,
} from "./utils";
import ModalForm from "../../modals/modal-form";
import {
  ContactDetail,
  ContactListItem,
} from "../../../graphql/types/models/client";
import {
  ClaimCostItemPayload,
  ClaimView,
  ClaimInvoiceType,
  ClaimPayload,
  ClaimReal,
  ClaimStatusesEnum,
} from "../../../models/claim";
import { UnClaimedVariation } from "../../../models/variations";
import { calcGST, GST_PERCENT } from "../../../utils/calculations";
import {
  TableCardData,
  updateSelectedRows,
} from "../../dashboard/table-card/utils";
import { formatQuoteNumber } from "../../../utils/text";
import { convertRichContent } from "../../generic-form/inputs/rich-editor/utils";
import { RawRichContent } from "../../../models/rich-content";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { JobCostingItem, JobInvoiceType, JobType } from "../../../models/job";
import { CREATE_UPDATE_CLAIM } from "../../../graphql/queries/job-claim/mutation";
import { GET_UNCLAIMED_ITEMS } from "../../../graphql/queries/job-claim/queries";
import {
  CreateUpdateClaimResponse,
  GetUnclaimedItemsResponse,
} from "../../../graphql/types/models/job-claim";
import { handleClaimAdd } from "../../../graphql/queries/job-claim/utils";
import { notify } from "../../notification";
import ListProgressClaimsModal, {
  ListProgressClaimsModalRef,
} from "../../job-claim/list-claims-modal";
import { PurchaseOrderReceipt } from "../../../models/purchaseOrder";
import { useJobQuery } from "../../../hooks/queries/useJobQuery";
import { useJobTotalsQuery } from "../../../hooks/queries/useJobTotalsQuery";
import SelectableTable from "../../dashboard/table-card/SelectableTable";
import {
  GetUnClaimedVariationsResponse,
  ListVariationResponse,
} from "../../../graphql/types/models/job-variation";
import {
  GET_UNCLAIMED_VARIATIONS,
  LIST_VARIATIONS,
} from "../../../graphql/queries/job-variation/queries";
import { useJobCostings } from "../../../hooks/useJobCostings";
import "./styles.scss";

type CreateClaimModalProps = {
  jobId?: string;
  claim?: ClaimPayload | null;
  client?: ContactDetail;
  clients: ContactListItem[];
  isCostPlus: boolean;
  contractTotal: number;
  contractTotalGST: number;
  claimedAmount: number;
  claimedGST: number;
  costingItems?: JobCostingItem[] | null;
  onCreateUpdate?: (claim: ClaimReal) => void;
};

const ClaimModal: React.FC<CreateClaimModalProps> = (props, ref) => {
  const { t } = useTranslation();

  const {
    jobId,
    client,
    clients,
    claim,
    isCostPlus,
    contractTotal,
    contractTotalGST,
    claimedAmount,
    claimedGST,
    costingItems,
    onCreateUpdate,
  } = props;

  const { shouldShow, hide } = useModalDisplay(ref);
  const listClaimsRef = React.useRef<ListProgressClaimsModalRef>(null);

  const mutationType = claim?._id ? "update" : "create";

  const [getUncalimedItems, { data: uncalimedItemsData }] = useLazyQuery<
    GetUnclaimedItemsResponse
  >(GET_UNCLAIMED_ITEMS, {
    fetchPolicy: "cache-and-network",
  });

  const [
    getUnclaimedVariations,
    { data: unclaimedVariationsData, loading: variationsLoading },
  ] = useLazyQuery<GetUnClaimedVariationsResponse>(GET_UNCLAIMED_VARIATIONS, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [getVariations, { data: variationsData }] = useLazyQuery<
    ListVariationResponse
  >(LIST_VARIATIONS, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const { job } = useJobQuery(jobId);
  const { jobTotals, refetchJobTotals } = useJobTotalsQuery(jobId);

  React.useEffect(() => {
    if (shouldShow) {
      if (!costingItems?.length) {
        getUnclaimedVariations({
          variables: {
            jobId,
          },
        });
        getVariations({
          variables: {
            jobId,
          },
        });
      }
      if (isCostPlus) {
        getUncalimedItems({
          variables: {
            jobId,
          },
        });
      }

      refetchJobTotals();
      setSelectedVariations(claim?.variations || []);
    }
  }, [shouldShow, isCostPlus, costingItems]);

  const [createUpdateClaim] = useMutation<CreateUpdateClaimResponse>(
    CREATE_UPDATE_CLAIM,
    {
      update: handleClaimAdd(jobId),
      onCompleted: ({ jobCreateUpdateProgressClaim }) => {
        hide();
        notify({
          title: t(`claims.${mutationType}Claim`),
          content: t(`claims.success.${mutationType}Claim`),
        });
        onCreateUpdate?.(jobCreateUpdateProgressClaim);
      },
      onError: (e) => {
        notify({
          title: t(`claims.${mutationType}Claim`),
          content: get(e, "message", t(`claims.errors.${mutationType}Claim`)),
          error: true,
        });
      },
    }
  );

  const unclaimedVariations = React.useMemo(() => {
    const variationIds = claim?.variations || [];
    const currentVariations = variationIds.length
      ? filter(
          variationsData?.getJobGetVariations,
          (v) => variationIds.indexOf(v._id) >= 0
        )
      : [];
    return [
      ...currentVariations,
      ...(unclaimedVariationsData?.getJobGetUnClaminedVariations || []),
    ];
  }, [unclaimedVariationsData, claim, variationsData]);

  const isRetention = React.useMemo(
    () => job?.invoiceType === JobInvoiceType.Retention,
    [job]
  );

  const isRetentionClaim = React.useMemo(
    () => isRetention && !claim?.items?.length && !costingItems?.length,
    [isRetention, claim, costingItems]
  );

  const getRetention = React.useCallback(
    (amount: number, gstFreeAmount: number) => {
      let retention = 0;
      let retentionGST = 0;
      let retentionPercent = 0;
      if (isRetention) {
        const percentToRetain = Number(job?.retention?.invoicePercentage) / 100;
        retention = round(percentToRetain * amount, 10);
        retentionGST = calcGST(retention, GST_PERCENT);

        const maximumRetention = round(
          Number(jobTotals?.totalRetainable) -
            Number(jobTotals?.totalRetained) +
            Number(claim?.retainedAmount || 0),
          10
        );
        const maximumRetentionGST = round(
          Number(jobTotals?.totalRetainableGST) -
            Number(jobTotals?.totalRetainedGST) +
            Number(claim?.retainedGST || 0),
          10
        );
        if (retention > maximumRetention) {
          retention = maximumRetention;
          retentionGST = calcGST(retention, GST_PERCENT);
        }
        if (retentionGST > maximumRetentionGST) {
          retentionGST = maximumRetentionGST;
        }
        retentionPercent = round((retention / amount) * 100, 2);
      }
      return {
        retention,
        retentionGST,
        retentionPercent,
      };
    },
    [isRetention, job, jobTotals, claim]
  );

  const unclaimedPurchaseOrderReceipts = React.useMemo<PurchaseOrderReceipt[]>(
    () => uncalimedItemsData?.getJobUnclaimedItems.purchaseOrderReceipts || [],
    [uncalimedItemsData]
  );

  const unclaimedCostings = React.useMemo<JobCostingItem[]>(
    () => uncalimedItemsData?.getJobUnclaimedItems.costingItems || [],
    [uncalimedItemsData]
  );

  const [formFields, setFormFields] = useState<GenericFormFields<ClaimPayload>>(
    {}
  );
  const [isDistributeAmount, setIsDistributeAmount] = useState(false);

  const editMode = !!claim?._id;
  const updateTargetAmount = editMode
    ? Number(claim?.amount || 0) - // TODO: check & replace with claim?.total
      (job?.excludeVariationFromClaimAmount
        ? Number(claim?.variationAmount || 0)
        : 0)
    : 0;

  const maxClaim = React.useMemo(() => {
    let amount = contractTotal - (claimedAmount - updateTargetAmount);
    if (isRetentionClaim) {
      amount =
        Number(jobTotals?.totalRetained) -
        Number(jobTotals?.totalRetentionClaimed) +
        updateTargetAmount;
    }
    return round(amount, 3);
  }, [
    isRetentionClaim,
    jobTotals,
    contractTotal,
    claimedAmount,
    updateTargetAmount,
  ]);

  const maxClaimGST = React.useMemo(() => {
    let GST = contractTotalGST - claimedGST + (claim?.GST || 0);

    // if (job?.excludeVariationFromClaimAmount) {
    //   gst += Number(jobTotals?.totalVariationClaimedGST);
    // }
    if (costingItems?.length) {
      GST = costingItems?.reduce((total, item) => {
        const { remaining } = calculateAmountToDate(item);
        return total + calcGST(item.hasGST ? remaining : 0, GST_PERCENT);
      }, 0);
    }

    if (isRetentionClaim) {
      GST =
        Number(jobTotals?.totalRetainedGST || 0) -
        Number(jobTotals?.totalRetentionClaimedGST || 0) +
        Number(claim?.GST || 0);
    }

    return GST;
  }, [
    isRetention,
    isRetentionClaim,
    job,
    jobTotals,
    maxClaim,
    updateTargetAmount,
    contractTotalGST,
    costingItems,
  ]);

  const maxPercentage = React.useMemo(() => {
    let amount = (maxClaim / contractTotal) * 100;
    if (isRetention) {
      amount =
        100 -
        ((Number(jobTotals?.totalRetentionClaimed) - updateTargetAmount) /
          Number(jobTotals?.totalRetained)) *
          100;
    }
    return round(amount || 0, 3);
  }, [isRetention, jobTotals, maxClaim, contractTotal, updateTargetAmount]);

  const handleAmountChange: UpdateTextInputFn = React.useCallback(
    (amount, formikProps) => {
      const { setFieldValue } = formikProps;

      if (!amount || !maxClaim) {
        setFieldValue("percentage", "");
        return;
      }

      const preparedAmount = parseFloat(amount.toString()) || 0;
      const newPercentage = (preparedAmount / maxClaim) * maxPercentage;
      setFieldValue("percentage", round(newPercentage, 3));
    },
    [maxClaim, maxPercentage]
  );

  const handleDistributeAmountChange = React.useCallback((value: boolean) => {
    setIsDistributeAmount(value);
  }, []);

  const handlePercentChange: UpdateTextInputFn = React.useCallback(
    (percentage, formikProps) => {
      const { setFieldValue } = formikProps;

      if (!percentage || !maxPercentage) {
        setFieldValue("amount", "");
        return;
      }

      const preparedAmount = parseFloat(percentage.toString()) || 0;
      const newClaim = (preparedAmount / maxPercentage) * maxClaim;
      setFieldValue("amount", round(newClaim, 3));
    },
    [maxClaim, maxPercentage]
  );

  const calculateRowAmount = React.useCallback(
    (fieldValue, rowIndex, formikProps, valueKey) => {
      const { values } = formikProps;
      const row = values.items[rowIndex];
      const total = row.total;
      const claimedToDate = row.amountToDate || 0;
      const maxValue = total - claimedToDate;
      const maxPercent =
        total !== 0 ? ((total - claimedToDate) / total) * 100 || 0 : 0;
      let amount;
      let percentage;
      const value = parseFloat(fieldValue) || 0;
      if (valueKey === "amount") {
        amount = fieldValue;
        let percent = total !== 0 ? round((value / total) * 100, 10) : 0;
        if (
          (maxValue >= 0 && value > maxValue) ||
          (maxValue < 0 && value < maxValue) // support negative amount
        ) {
          percent = total !== 0 ? round((maxValue / total) * 100, 10) : 0;
          amount = maxValue.toString();
        }
        const percentStr = percent === 0 ? "0" : percent.toString();
        percentage = percentStr;
      } else {
        percentage = fieldValue;
        let amountOfTotal = round((value / 100) * total, 4);
        percentage = value.toString();
        if (value > maxPercent) {
          amount = round((maxPercent / 100) * total, 4);
          percentage = maxPercent.toString();
        }
        const amountStr = amountOfTotal === 0 ? "0" : amountOfTotal.toString();
        amount = amountStr;
      }
      return { amount, percentage };
    },
    []
  );

  const calcGSTFreeAmount = (items?: ClaimCostItemPayload[]) => {
    return reduce(
      items,
      (total, item) => {
        const amount = !item.hasGST ? Number(item.amount.toString() || "0") : 0;
        return total + amount;
      },
      0
    );
  };

  const recalculateAmount = (formikProps: FormikPropGetSetValues) => {
    const { setFieldValue, values } = formikProps;
    const amount = reduce(
      values.items,
      (total, item) => total + parseFloat(item.amount.toString() || "0"),
      0
    );
    const gstFreeAmount = calcGSTFreeAmount(values.items);
    setFieldValue("amount", amount);
    setFieldValue("gstFreeAmount", gstFreeAmount);
  };

  const debounceRecalculateAmount = debounce(recalculateAmount, 200);

  const handleRowChange = React.useCallback(
    (valueKey: string): UpdateRowFn => (fieldValue, rowIndex, formikProps) => {
      if (isCostPlus) return;
      const { setFieldValue, values } = formikProps;
      const row = values.items[rowIndex];
      if (!row) return;
      const { amount, percentage } = calculateRowAmount(
        fieldValue,
        Number(rowIndex),
        formikProps,
        valueKey
      );
      const item = { ...row, amount, percentage };
      setFieldValue(`items.[${rowIndex}]`, item);
      debounceRecalculateAmount(formikProps);
    },
    [isCostPlus]
  );

  const handleBulkPercentChange: UpdateTextInputFn = React.useCallback(
    (fieldValue, formikProps) => {
      if (isCostPlus) return;
      const items = [...formikProps.values.items] as any;
      for (let rowIndex in formikProps.values.items) {
        const { amount, percentage } = calculateRowAmount(
          fieldValue,
          Number(rowIndex),
          formikProps,
          "percentage"
        );
        items[rowIndex].amount = amount;
        items[rowIndex].percentage = percentage;
      }
      formikProps.setFieldValue("items", items);
    },
    [isCostPlus, calculateRowAmount]
  );

  const handleClaimToDateClick = React.useCallback(
    (values: ClaimCostItemPayload) => {
      const costing = find(costingItems, { _id: values.costingItemId });
      if (costing?.progressClaimItems?.length) {
        listClaimsRef.current?.setData(costing.progressClaimItems);
        listClaimsRef.current?.show(true);
      }
    },
    [listClaimsRef, costingItems]
  );

  const { addCostingItemButton, renderJobCostingsModal } = useJobCostings(
    t,
    "items",
    jobId
  );

  React.useEffect(() => {
    if (!job) return;
    return setFormFields(
      createClaimFields(
        t,
        job,
        clients,
        isDistributeAmount,
        isRetention,
        handleDistributeAmountChange,
        handleAmountChange,
        handlePercentChange,
        handleBulkPercentChange,
        handleRowChange,
        costingItems ? handleClaimToDateClick : undefined,
        addCostingItemButton,
        claim ? false : true
      )
    );
  }, [
    t,
    job,
    claim,
    clients,
    isDistributeAmount,
    isRetention,
    handleDistributeAmountChange,
    handleAmountChange,
    handlePercentChange,
    handleBulkPercentChange,
    handleRowChange,
    costingItems,
  ]);

  React.useEffect(() => {
    if (!shouldShow) {
      setSelectedVariations([]);
    }
  }, [shouldShow]);

  const initialValues = React.useMemo(() => {
    let values: ClaimPayload = {
      description: "",
      contact: client?._id || "",
      status: ClaimStatusesEnum.DRAFT,
      claimDate: new Date().toISOString(),
      dueDate: new Date().toISOString(),
      amount: "",
      percentage: "",
      items: [],
      richComment: {
        text: "",
        html: "",
        raw: "",
      },
    };
    if (costingItems && costingItems.length) {
      costingItems.forEach((item) => {
        if (!item._id) return;
        const {
          amountToDate,
          percentageToDate,
          total,
          remaining,
        } = calculateAmountToDate(item);

        values.items?.push({
          costingItemId: item._id,
          name: item.name,
          hasGST: item.hasGST,
          amount: "",
          percentage: "",
          note: "",
          total,
          remaining,
          amountToDate,
          percentageToDate,
        });
      });

      return values;
    }

    if (job?.type === JobType.COST_PLUS) {
      if (claim) {
        values = {
          ...values,
          _id: claim._id,
          contact: claim.contact || "",
          description: claim.description,
          status: claim.status,
          claimDate: claim.claimDate ?? new Date().toISOString(),
          dueDate: claim.dueDate ?? new Date().toISOString(),
          amount: claim.amount,
          percentage: claim.percentage,
          richComment: claim.richComment,
        };
      }
      const existingIds: string[] = [];
      claim?.items?.forEach((item) => {
        if (item.costingItemId) existingIds.push(item.costingItemId);
        if (item.purchaseOrderId) existingIds.push(item.purchaseOrderId);
        if (!item._id) return;
        let GST = item.hasGST
          ? calcGST(Number(item.amount) || 0, GST_PERCENT)
          : 0;
        if (item.purchaseOrder) {
          GST = item.purchaseOrder.GST || 0;
        } else if (item.purchaseOrderReceipt) {
          GST = item.purchaseOrderReceipt.GST || 0;
        }
        values.items?.push({
          selected: true,
          _id: item._id,
          costingItemId: item.costingItem?._id,
          purchaseOrderId: item.purchaseOrder?._id,
          purchaseOrderReceiptId: item.purchaseOrderReceipt?._id,
          name: item.name,
          supplier:
            item.purchaseOrder?.supplier?.business_name ||
            item.purchaseOrderReceipt?.purchaseOrder?.supplier?.business_name ||
            t("common.na"),
          amount: item.amount,
          GST: GST,
          hasGST: item.hasGST,
          percentage: "100",
          note: item.note || "",
          amountToDate: 0,
          percentageToDate: 0,
          total: Number(item.total),
          remaining: Number(item.amount),
          markup: item?.markup || 0,
        });
      });

      if (unclaimedPurchaseOrderReceipts.length > 0) {
        unclaimedPurchaseOrderReceipts.forEach((purchaseOrderReceipt) => {
          if (
            !purchaseOrderReceipt._id ||
            existingIds.indexOf(purchaseOrderReceipt._id) >= 0
          )
            return;
          values.items?.push({
            purchaseOrderReceiptId: purchaseOrderReceipt._id,
            name: compact([
              formatQuoteNumber(
                purchaseOrderReceipt?.purchaseOrder?.orderNumber || "",
                "PO"
              ),
              purchaseOrderReceipt.reference,
            ]).join(" - "),
            supplier:
              purchaseOrderReceipt?.purchaseOrder?.supplier?.business_name ||
              "",
            amount: purchaseOrderReceipt.subTotal || 0,
            GST: purchaseOrderReceipt.GST,
            hasGST: purchaseOrderReceipt.hasGST,
            percentage: "100",
            note: "",
            amountToDate: 0,
            percentageToDate: 0,
            total: purchaseOrderReceipt.subTotal || 0,
            remaining: purchaseOrderReceipt.subTotal || 0,
            markup: job?.markup || 0,
          });
        });
      }

      if (unclaimedCostings.length > 0) {
        unclaimedCostings.forEach((costing) => {
          if (!costing._id || existingIds.indexOf(costing._id) >= 0) return;
          values.items?.push({
            costingItemId: costing._id,
            name: costing.name,
            supplier: t("common.na"),
            amount: costing.real_cost,
            GST: costing.real_cost * 0.1,
            hasGST: true,
            percentage: "100",
            note: "",
            amountToDate: 0,
            percentageToDate: 0,
            total: costing.real_cost,
            remaining: costing.real_cost,
            markup: job?.markup || 0,
          });
        });
      }
      return values;
    }

    if (!claim || !editMode) {
      return values;
    }

    return {
      ...claim,
      claimDate: claim.claimDate ?? new Date().toString(),
      dueDate: claim.dueDate ?? new Date().toString(),
      amount: Number(claim.amount || 0) - Number(claim.variationAmount || 0),
      items: claim.items?.map((item) => {
        const {
          amountToDate,
          percentageToDate,
          total,
          remaining,
        } = calculateEditingAmountToDate(item);
        return {
          ...item,
          amountToDate,
          percentageToDate,
          total,
          remaining,
        };
      }),
      gstFreeAmount: calcGSTFreeAmount(claim.items),
      defaultCategoriesView: claim.defaultView === ClaimView.CATEGORIES,
    };
  }, [
    claim,
    job,
    client,
    costingItems,
    unclaimedPurchaseOrderReceipts,
    unclaimedCostings,
    editMode,
  ]);

  const [selectedVariations, setSelectedVariations] = useState<string[]>([]);

  const selectedVariationsDetail = React.useMemo(() => {
    return filter(unclaimedVariations, (variation) =>
      includes(selectedVariations, variation._id)
    );
  }, [selectedVariations, unclaimedVariations]);

  const variationsAmount = React.useMemo(() => {
    return reduce(
      selectedVariationsDetail,
      (acc, variation) => {
        return acc + variation.subTotal;
      },
      0
    );
  }, [selectedVariationsDetail, unclaimedVariations]);

  const getTotals = React.useCallback(
    (values: ClaimPayload) => {
      let amount = 0;
      let GST = 0;
      let retainedAmount = 0;
      let retainedGST = 0;
      let retainedPercent = 0;
      let variationGST = 0;
      if (job?.type === JobType.COST_PLUS) {
        const items = values.items?.filter((i) => i.selected);
        if (items?.length) {
          const totals = calculateCostPlusTotal(items);
          amount = totals.subTotal;
          GST = totals.GST;
        }
      } else {
        const fixedAmount = Number(values.amount || 0);
        const gstFreeAmount = Number(values.gstFreeAmount || 0);

        amount = fixedAmount + variationsAmount;
        variationGST = selectedVariationsDetail.reduce((total, variation) => {
          return total + (variation.GST || 0);
        }, 0);
        if (isRetention) {
          const calculatedRetention = getRetention(amount, gstFreeAmount);
          retainedAmount = calculatedRetention.retention;
          retainedGST = calculatedRetention.retentionGST;
          retainedPercent = calculatedRetention.retentionPercent;
        }

        GST = calcGST(fixedAmount - gstFreeAmount, GST_PERCENT);
        if (GST > maxClaimGST) {
          GST = maxClaimGST;
        }
        GST += variationGST;
      }
      return {
        amount,
        GST,
        variationGST,
        retainedAmount,
        retainedGST,
        retainedPercent,
      };
    },
    [
      job,
      jobTotals,
      isRetention,
      isRetentionClaim,
      getRetention,
      selectedVariationsDetail,
      variationsAmount,
      maxClaimGST,
    ]
  );

  const renderTotals = React.useCallback(
    (formikProps: FormikProps<ClaimPayload>) => {
      const {
        amount,
        GST,
        retainedAmount,
        retainedGST,
        retainedPercent,
      } = getTotals(formikProps.values);
      return (
        <Totals
          title={t("claims.amount")}
          subtotal={amount}
          total={GST + amount - retainedAmount}
          gst={GST}
          retention={retainedAmount}
          retentionPercent={retainedPercent}
        />
      );
    },
    [getTotals]
  );

  const handleVariationSelect = React.useCallback(
    (id: string) => {
      setSelectedVariations(updateSelectedRows(id, selectedVariations));
    },
    [selectedVariations]
  );

  const variationsTable = React.useMemo<
    TableCardData<UnClaimedVariation>
  >(() => {
    return {
      columns: [
        {
          valueKey: "variationNumber",
          title: t("variations.variationNumber"),
          formatValue: (row: any, column: any, value: number) =>
            formatQuoteNumber(value, "V"),
        },
        {
          valueKey: "date",
          title: t("claims.date"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
        {
          valueKey: "name",
          title: t("common.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "subTotal",
          title: t("costing.totalEx"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.currency", { amount: value }),
        },
      ],
      rows: map(unclaimedVariations, (variation) => ({
        cells: variation,
      })),
    };
  }, [unclaimedVariations]);

  const getUnClaimedMessage = () => {
    const amount = t("common.currency", { amount: maxClaim });
    return t("claims.unClaimedMessage", { amount, percentage: maxPercentage });
  };

  const handleSubmit = React.useCallback(
    (values: ClaimPayload) => {
      const {
        amount,
        GST,
        retainedAmount,
        retainedGST,
        retainedPercent,
        variationGST,
      } = getTotals(values);

      let progressClaim = {
        ...omit(values, [
          "gstFreeAmount",
          "defaultCategoriesView",
          "bulkPercentage",
        ]),
        amount,
        GST,
        variationGST,
        retainedAmount,
        retainedGST,
        claimDate: values.claimDate,
        dueDate: values.dueDate,
        percentage: Number(values.percentage || 0),
        variationAmount: variationsAmount,
        richComment: convertRichContent(
          (values.richComment as unknown) as RawRichContent
        ),
        variations: selectedVariations,
        defaultView: values.defaultCategoriesView
          ? ClaimView.CATEGORIES
          : ClaimView.ITEMS,
      };

      if (progressClaim.items?.length) {
        const claimItems = filter(
          progressClaim.items,
          (item) => job?.type !== JobType.COST_PLUS || item.selected
        ) as ClaimCostItemPayload[];

        let { amount, amountGSTFree } = claimItems.reduce(
          (totals, item) => {
            return {
              amount: totals.amount + parseFloat(item.amount.toString()),
              amountGSTFree:
                totals.amountGSTFree +
                (!item.hasGST ? parseFloat(item.amount.toString()) : 0),
            };
          },
          {
            amount: variationsAmount, // is this even required? Variations shouldn't be claimable along with individual items
            amountGSTFree: 0,
          }
        );
        if (isRetention) {
          progressClaim.amount = round(
            progressClaim.amount - progressClaim.retainedAmount,
            10
          );
        }

        progressClaim = {
          ...progressClaim,
          percentage: 0,
          items: claimItems.map((item) => {
            const itemAmount = Number(item.amount) || 0;
            const itemGST =
              item.GST !== undefined
                ? item.GST
                : item.hasGST
                ? calcGST(itemAmount, GST_PERCENT)
                : 0;

            return {
              _id: item._id,
              name: item.name,
              costingItemId: item.costingItemId,
              purchaseOrderId: item.purchaseOrderId,
              purchaseOrderReceiptId: item.purchaseOrderReceiptId,
              markup: Number(item.markup) || 0,
              amount: round(itemAmount, 10),
              GST: itemGST,
              percentage: round(Number(item.percentage) || 0, 10),
              amountToDate: round(itemAmount + item.amountToDate, 10),
              percentageToDate: round(item.percentageToDate, 10),
              hasGST: item.hasGST !== false,
              total: Number(item.total) || 0,
              remaining: round(item.remaining - itemAmount, 10),
              note: item.note,
            };
          }),
        };
        if (job?.type === JobType.COST_PLUS) {
          progressClaim.amount = reduce(
            progressClaim.items,
            (total, item) => {
              const markup = 1 + Number(item.markup) / 100;
              return total + Number(item.amount) * markup;
            },
            0
          );
        }
      } else if (isRetention) {
        progressClaim.invoiceType = ClaimInvoiceType.Retention;
      }

      return createUpdateClaim({
        variables: {
          jobId,
          progressClaim,
        },
      });
    },
    [
      job,
      maxClaim,
      maxClaimGST,
      maxPercentage,
      isRetention,
      getRetention,
      selectedVariations,
      variationsAmount,
      hide,
    ]
  );

  const renderClaimInputs = React.useCallback(
    (formikProps: FormikProps<ClaimPayload>) => {
      if (!job) return null;
      if (job.type === JobType.COST_PLUS) {
        if (formikProps.values.items?.length === 0) {
          return (
            <Row className="unclaimed-message">
              {t("claims.noClaimableItems")}
            </Row>
          );
        }

        return (
          <>
            <Row>
              {renderTableForm(
                formikProps,
                formFields.items as GenericFormTable<ClaimPayload, any>,
                "items"
              )}
            </Row>
          </>
        );
      } else if (!formikProps.values.items?.length) {
        return (
          <>
            <Row className="unclaimed-message">{getUnClaimedMessage()}</Row>
            <Row>
              {renderField(formikProps, formFields.amount, 6)}
              {renderField(formikProps, formFields.percentage, 6)}
            </Row>
            <Row className="pt-2">
              {renderField(formikProps, formFields.distributeAmount, 6)}
            </Row>
            <Row className="pt-2">
              {renderField(formikProps, formFields.hideItems, 6)}
            </Row>
          </>
        );
      }
      return (
        <Row>
          {renderTableForm(
            formikProps,
            formFields.items as GenericFormTable<ClaimPayload, any>,
            "items"
          )}
        </Row>
      );
    },
    [job, formFields]
  );

  const claimTitle =
    claim && editMode ? t("claims.updateClaim") : t("claims.newClaim");

  return (
    <>
      <ModalForm
        editMode={editMode}
        className="claim-modal"
        show={shouldShow}
        validationSchema={claimSchema(t, maxClaim)}
        onClose={hide}
        data={initialValues}
        onSubmit={handleSubmit}
        title={claimTitle}
      >
        {(formikProps) => (
          <Container className="generic-form-body" fluid>
            {renderJobCostingsModal(formikProps)}
            <Row>
              {renderField(formikProps, formFields.description, 4)}
              {renderField(formikProps, formFields.contact, 4)}
              {renderField(formikProps, formFields.status, 4)}
            </Row>
            <Row>
              {renderField(formikProps, formFields.claimDate, 3)}
              {renderField(formikProps, formFields.dueDate, 3)}
            </Row>
            {renderClaimInputs(formikProps)}
            <Row className="mt-2">
              {!isEmpty(unclaimedVariations) && !isCostPlus && (
                <SelectableTable
                  table={variationsTable}
                  keyField="_id"
                  selected={selectedVariations}
                  onChange={handleVariationSelect}
                />
              )}
            </Row>
            <Row>
              {renderField(formikProps, formFields.comment, 8)}
              <Col xs={4} className="form-column">
                {renderTotals(formikProps)}
              </Col>
            </Row>
          </Container>
        )}
      </ModalForm>

      <ListProgressClaimsModal
        ref={listClaimsRef}
        isCostPlus={job?.type === JobType.COST_PLUS}
      />
    </>
  );
};

export default React.forwardRef(ClaimModal);
