import React from "react";
import { Emoji as EmojiRenderer } from "emoji-mart";
import { ContentState } from "draft-js";
import "./styles.scss";

type EmojiProps = {
  offsetKey: string;
  contentState: ContentState;
  entityKey: string;
  size: number;
};

const Emoji: React.FC<EmojiProps> = (props) => {
  const { offsetKey, contentState, entityKey, size = 23 } = props;

  const entity = contentState.getEntity(entityKey);
  const { emoji } = entity.getData();

  const emojiStyle = React.useMemo(
    () => ({
      width: size,
      height: size,
    }),
    [size]
  );

  return (
    <div data-offset-key={offsetKey} className="emoji" style={emojiStyle}>
      <EmojiRenderer size={size} emoji={emoji} set="apple" />
      <span className="emoji-text" style={emojiStyle}>
        {props.children}
      </span>
    </div>
  );
};

export default Emoji;
