import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { find, map } from "lodash";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateSalesQuotePayload,
  SalesQuoteTemplateType,
} from "../../../models/salesQuote";

import CreateEntityModal from "../../modals/create-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createSalesQuoteSchema from "./CreateQuote.schema";
import { createSalesQuoteFormFields } from "./utils";
import { AutocompleteInputOption } from "../../generic-form/inputs/autocomplete";
import { ContactListResponse } from "../../../graphql/types/models/client";
import CreateClient, {
  CreateClientRef,
} from "../../../containers/modals/create-client";
import { LIST_CONTACTS } from "../../../graphql/queries/client/queries";
import { LIST_BUILDING_TEMPLATES } from "../../../graphql/queries/building-template/queries";
import {
  ListBuildingTemplatesResponse,
  QuoteCreateUpdateResponse,
  QuoteListResponse,
} from "../../../graphql/types/models/quote";
import { CREATE_UPDATE_SALES_QUOTE } from "../../../graphql/queries/quote/mutations";
import { LIST_QUOTES } from "../../../graphql/queries/quote/queries";
import { handlePlaceSelect } from "../../../utils/place";
import { getFullName } from "../../../utils/text";

type CreateSalesQuoteModalProps = {};

export type CreateSalesQuoteModalRef = {
  show: (show: boolean, leadId?: string, contactId?: string) => void;
};

const CreateSalesQuoteModal: React.FC<CreateSalesQuoteModalProps> = (
  {},
  ref
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const createModalRef = React.useRef<any>(null);
  const [show, setShow] = React.useState(false);
  const createClientRef = React.useRef<CreateClientRef>(null);
  const [leadId, setLeadId] = React.useState<string>("");
  const [contactId, setContactId] = React.useState<string>("");

  useImperativeHandle(ref, () => ({
    show: (show: boolean, leadId?: string, contactId?: string) => {
      if (leadId) {
        setLeadId(leadId);
      }
      if (contactId) {
        setContactId(contactId);
      }
      setShow(show);
    },
  }));

  const { data: clientsData } = useQuery<ContactListResponse>(LIST_CONTACTS);

  const { data: templateData } = useQuery<ListBuildingTemplatesResponse>(
    LIST_BUILDING_TEMPLATES,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const templates: AutocompleteInputOption[] = React.useMemo(
    () =>
      map(templateData?.templates, (template) => ({
        label: template.name,
        value: template._id,
        badge:
          template.type === SalesQuoteTemplateType.SYSTEM
            ? t("wunderbuild")
            : undefined,
      })),
    [templateData]
  );

  const [createUpdateSalesQuote] = useMutation<QuoteCreateUpdateResponse>(
    CREATE_UPDATE_SALES_QUOTE,
    {
      onCompleted: () => {
        setShow(false);
      },
      update(cache, { data }) {
        const createUpdateSalesQuote = data?.createUpdateSalesQuote;

        const cacheData: QuoteListResponse | null = cache.readQuery({
          query: LIST_QUOTES,
          variables: {
            filter: {
              is_archived: false,
            },
          },
        });

        if (!cacheData || !createUpdateSalesQuote) {
          return;
        }

        const { listSalesQuotes } = cacheData;

        if (!find(listSalesQuotes, { _id: createUpdateSalesQuote._id })) {
          cache.writeQuery({
            query: LIST_QUOTES,
            variables: {
              filter: {
                is_archived: false,
              },
            },
            data: {
              listSalesQuotes: listSalesQuotes.concat(createUpdateSalesQuote),
            },
          });
          history.push(`/quotes/${createUpdateSalesQuote._id}/plans`);
        }
      },
    }
  );

  const onCreateClient = React.useCallback(
    () => createClientRef.current?.show(true),
    [createClientRef]
  );

  const handleContactSelect = React.useCallback(
    (contactId: string) => {
      if (
        createModalRef.current?.values &&
        !createModalRef.current?.values?.address
      ) {
        const contact = find(
          clientsData?.listContacts,
          (c) => c._id === contactId
        );
        if (contact?.address) {
          createModalRef.current.setFieldValue("address", contact.address);
          createModalRef.current.setFieldValue("city", contact.city);
          createModalRef.current.setFieldValue("state", contact.state);
          createModalRef.current.setFieldValue("postcode", contact.postcode);
        }
      }
    },
    [createModalRef, clientsData]
  );

  const formFields = React.useMemo<
    GenericFormFields<CreateSalesQuotePayload>
  >(() => {
    const clients: AutocompleteInputOption[] = map(
      clientsData?.listContacts,
      (client) => ({
        value: client._id,
        label: getFullName(client),
      })
    );

    return createSalesQuoteFormFields(
      t,
      clients,
      templates,
      onCreateClient,
      handleContactSelect,
      handlePlaceSelect
    );
  }, [
    t,
    templates,
    clientsData,
    onCreateClient,
    handleContactSelect,
    handlePlaceSelect,
  ]);

  const handleSubmit = React.useCallback(
    (quote: CreateSalesQuotePayload) => {
      createUpdateSalesQuote({
        variables: {
          quote: {
            ...quote,
            ...(leadId && { leadId: leadId }),
            markup: Number(quote.markup || 0),
          },
        },
      });
    },
    [createUpdateSalesQuote, leadId]
  );

  const handleSetNewClient = React.useCallback(
    (clientId: string) => {
      if (clientId) {
        createModalRef.current?.setFieldValue("contactId", clientId);
        handleContactSelect(clientId);
      }
    },
    [createModalRef, handleContactSelect]
  );

  return (
    <React.Fragment>
      <CreateEntityModal
        formikRef={createModalRef}
        validationSchema={createSalesQuoteSchema(t)}
        title={t("quotes.newSalesQuote")}
        show={show}
        data={{
          name: "",
          contactId: contactId ?? "",
          templateId: "",
          markup: 0,
          address: "",
          city: "",
          state: "",
          postcode: "",
        }}
        onSubmit={handleSubmit}
        onClose={() => setShow(false)}
        fields={formFields}
      />
      <CreateClient
        ref={createClientRef}
        onCreatedRecord={handleSetNewClient}
      />
    </React.Fragment>
  );
};

export default forwardRef(CreateSalesQuoteModal);
