import React from "react";
import { InputGroup } from "react-bootstrap";
import InputAppend from "../../generic-form/inputs/input-apend";
import TextInput from "../../generic-form/inputs/text";

type PlanViewerToolbarInputProps = {
  name: string;
  value: any;
  type?: string;
  placeholder?: string;
  allowEmpty?: boolean;
  onChange: (value: any) => void;
  onClick?: (event: any) => void;
};

const PlanViewerToolbarInput: React.FC<PlanViewerToolbarInputProps> = ({
  name,
  type = "number",
  value,
  placeholder,
  allowEmpty = false,
  onChange,
  onClick,
}) => {
  const [editValue, setEditValue] = React.useState(value);
  const [editMode, setEditMode] = React.useState(value === undefined);

  React.useEffect(() => {
    setEditValue(value);
    setEditMode(value === undefined);
  }, [value]);

  const handleEdit = React.useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const handleConfirm = React.useCallback(() => {
    if (!allowEmpty && !editValue) return;
    onChange(editValue);
    setEditMode(false);
  }, [allowEmpty, editValue, editMode]);

  return (
    <div className="button-input" onClick={onClick}>
      {editMode ? (
        <TextInput
          name={name}
          className="form-input"
          type={type}
          placeholder={placeholder}
          value={editValue}
          onChange={(value) => setEditValue(value.target.value)}
          append={{
            icon: "check",
            className: "text field-text success",
            onClick: handleConfirm,
          }}
        />
      ) : (
        <InputGroup className="form-input-group">
          <InputGroup.Text className="form-input-text">
            {placeholder}: {value}
          </InputGroup.Text>
          <InputAppend icon="edit" onClick={handleEdit} />
        </InputGroup>
      )}
    </div>
  );
};

export default PlanViewerToolbarInput;
