import React from "react";
import { compact } from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContactDetail } from "../../../../graphql/types/models/client";
import { getFullName } from "../../../../utils/text";
import CardPlaceholder from "../../../../components/dashboard/card-placeholder";
import DashboardCard from "../../../../components/dashboard/card";
import DashboardCardHeader from "../../../../components/dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../../../components/dashboard/card/DashboardCardBody";
import "./styles.scss";

type ContactDetailsProps = {
  disabled: boolean;
  contact?: ContactDetail | null;
  secondaries?: ContactDetail[] | null;
};

const ContactDetails: React.FC<ContactDetailsProps> = (props) => {
  const { t } = useTranslation();
  const { contact, disabled } = props;
  const phones = React.useMemo(() => {
    return compact([contact?.phone, contact?.phone_secondary]).join(", ");
  }, [contact]);

  const renderContacts = React.useCallback(() => {
    return (
      <Link
        className="quote-overview quote-contact"
        to={`/contacts/clients/${contact?._id}`}
      >
        <div className="field-text mb-3">{getFullName(contact)}</div>
        <div className="field-text">{contact?.email}</div>
        <div className="field-text">{phones}</div>
      </Link>
    );
  }, [contact, phones]);

  if (disabled) {
    return <CardPlaceholder />;
  }

  return (
    <DashboardCard>
      <DashboardCardHeader>{t("quotes.clientDetails")}</DashboardCardHeader>
      <DashboardCardBody>{renderContacts()}</DashboardCardBody>
    </DashboardCard>
  );
};
export default ContactDetails;
