import { gql } from "@apollo/client";
import { ROSTER_FRAGMENT } from "./fragments";

export const LIST_ROSTERS = gql`
  query($jobId: ID!) {
    getJobRosters(jobId: $jobId) {
      _id
      rosterNumber
      date
      reference
      status
      updatedAt
      createdAt
      internalNote
      items {
        cost
        quantity
      }
    }
  }
`;

export const GET_ROSTER = gql`
  query($jobId: ID!, $rosterId: ID!) {
    getJobRosterById(jobId: $jobId, rosterId: $rosterId) {
      ...RosterData
    }
  }
  ${ROSTER_FRAGMENT}
`;

export const GET_ROSTER_PREVIEW = gql`
  query($jobId: ID!, $rosterId: ID!) {
    getJobRosterPreview(jobId: $jobId, rosterId: $rosterId) {
      _id
      pdf
    }
  }
`;
