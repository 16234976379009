import React, { forwardRef, useImperativeHandle } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Properties as CSSProps } from "csstype";
import "./styles.scss";

type ConfirmDialogProps = {
  show?: boolean;
  className?: string;
  footerClassName?: string;
  title?: string;
  text?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  disableHide?: boolean;
  disabled?: boolean;
  confirm?: string;
  cancel?: string;
  children?: any;
  isInfoDialog?: boolean;
  style?: CSSProps;
};

export type ConfirmDialogRef = {
  show: (show: boolean, submitCallback?: () => void) => void;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props, ref) => {
  const {
    show,
    className,
    footerClassName,
    title,
    text,
    onClose,
    onSubmit,
    children,
    disabled,
    disableHide,
    confirm,
    cancel,
    isInfoDialog = false,
    style,
  } = props;
  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState(false);
  const [submitCallback, setSubmitCallback] = React.useState<any>();

  useImperativeHandle(ref, () => ({
    show: (show: boolean, submitCallback?: () => void) => {
      setShowModal(show);
      setSubmitCallback([submitCallback ? submitCallback : onSubmit]);
    },
  }));

  React.useEffect(() => {
    setShowModal(!!show);
  }, [show]);

  React.useEffect(() => {
    onSubmit && setSubmitCallback([onSubmit]);
  }, [onSubmit]);

  const handleClose = React.useCallback(() => {
    if (onClose) {
      // fallback
      onClose();
    } else {
      setShowModal(false);
    }
  }, [onClose]);

  const handleSubmit = React.useCallback(() => {
    submitCallback?.length && submitCallback[0]();
    handleClose();
  }, [submitCallback, handleClose]);

  const handleHide = React.useCallback(() => {
    if (!disableHide && onClose) onClose();
  }, [disableHide, onClose]);

  return (
    <Modal
      show={showModal}
      className={classNames(className, "confirm-dialog")}
      onHide={handleHide}
      role="dialog"
      style={style}
    >
      <Modal.Header className="header">
        <Modal.Title className="title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">{children || text}</Modal.Body>
      <Modal.Footer className={classNames(footerClassName, "footer")}>
        <Button
          variant="secondary"
          className="button info"
          onClick={handleClose}
          disabled={disabled}
        >
          {cancel || t("common.cancel")}
        </Button>
        {!isInfoDialog && (
          <Button
            onClick={handleSubmit}
            variant="primary"
            className="button success"
            disabled={disabled}
          >
            {confirm || t("common.yes")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default forwardRef(ConfirmDialog);
