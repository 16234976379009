import React from "react";
import { useTranslation } from "react-i18next";
import UpdateEntityModal from "../../modals/update-entity";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { UpdateSubProfilePayload } from "../../../models/profile";
import { UserPayload } from "../../../graphql/types/models/auth";
import createUpdateSubProfileFields from "./utils";
import createEditSubProfileSchema from "./EditSubProfile.schema";

type UpdateProfileModalProps = {
  show: boolean;
  user?: UserPayload;
  onSubmit: (data: UpdateSubProfilePayload) => void;
  onClose: () => void;
};

const UpdateProfileModal: React.FC<UpdateProfileModalProps> = (props) => {
  const { show, user, onSubmit, onClose } = props;

  const { t } = useTranslation();

  const formFields = React.useMemo<
    GenericFormFields<UpdateSubProfilePayload>
  >(() => {
    return createUpdateSubProfileFields(t);
  }, [t]);

  const handleSubmit = React.useCallback(
    (data) => {
      onSubmit(data);
    },
    [onSubmit]
  );

  const initialValues = React.useMemo<UpdateSubProfilePayload>(
    () => ({
      contact_person: user?.contact_person || "",
      business_name: user?.business_name || "",
      email: user?.email || "",
    }),
    [user]
  );

  return (
    <UpdateEntityModal
      validationSchema={createEditSubProfileSchema(t)}
      title={t("profile.editDetailsTitle")}
      show={show}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={onClose}
      fields={formFields}
    />
  );
};

export default UpdateProfileModal;
