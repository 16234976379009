import { find, head, isEmpty, last, omit } from "lodash";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import omitDeep from "omit-deep-lodash";

import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import TakeOffCard from "../../../components/plans/take-off-card";
import CreateTakeOffModal, {
  CreateTakeOffModalRef,
} from "../../../components/plans/take-off-modals/create-take-off";
import UpdateTakeOffModal from "../../../components/plans/take-off-modals/update-take-off";
import {
  CopyTakeOffPayload,
  CreateTakeOffPayload,
  TakeoffListItem,
  TakeOffMeasurement,
  TakeOffShape,
  TakeOffShapes,
  UpdateTakeOffPayload,
} from "../../../models/take-off";
import { useMutation, useQuery } from "@apollo/client";
import { generatePath, useHistory } from "react-router-dom";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import {
  CREATE_TAKEOFF_ITEM,
  DELETE_TAKEOFF_ITEM,
  UPDATE_TAKEOFF_ITEMS,
} from "../../../graphql/queries/take-off/mutations";
import {
  CreateTakeoffResponse,
  DeleteTakeoffResponse,
  ListTakeoffItemsResponse,
} from "../../../graphql/types/models/take-off";
import { LIST_TAKEOFF } from "../../../graphql/queries/take-off/queries";
import {
  handleTakeOffAdd,
  handleTakeoffDelete,
} from "../../../graphql/queries/take-off/utils";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { notify } from "../../../components/notification";
import QuoteHeader from "../../../components/quotes/quote-header";
import AddToCostingModal, {
  AddToCostingModalModalRef,
} from "../../../components/plans/take-off-modals/add-to-costing";
import PlanTakeOffEditor, {
  TakeOffPlanEditorRef,
} from "../../../components/plans/take-off-plan-editor";
import { useSalesQuoteQuery } from "../../../hooks/queries/useSalesQuoteQuery";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useTakeOffItemsExportQuery } from "../../../hooks/queries/useTakeOffItemsExportQuery";
import EstimationLocationHeader from "../../header/estimation-location-header";
import CopyTakeOffModal from "../../../components/plans/take-off-modals/copy-take-off";

type TakeOffContainerProps = DashboardContextValue;

const TakeOffContainer: React.FC<TakeOffContainerProps> = ({
  navigationContext,
}) => {
  const salesQuoteId = navigationContext?.quote?._id;
  const isLocked = navigationContext?.quote?.isLocked || false;

  const [showDeleteConfirmation, setDialogDeleteVisibility] = useState(false);
  const [deleteTargetId, setDeleteTarget] = useState("");
  const [ignoreUnscaledMessage, setIgnoreUnscaledMessage] = useState(false);
  const costingRef = React.useRef<AddToCostingModalModalRef>(null);
  const createTakeOffRef = React.useRef<CreateTakeOffModalRef>(null);
  const copyTakeOffRef = React.useRef<ModalDisplayRef>(null);
  const updateTakeOffRef = React.useRef<ModalDisplayRef>(null);
  const unscaledRef = React.useRef<ConfirmDialogRef>(null);
  const editorRef = React.useRef<TakeOffPlanEditorRef>(null);

  const { salesQuotePlans } = useSalesQuoteQuery(salesQuoteId);

  const closeDeleteDialog = React.useCallback(() => {
    setDialogDeleteVisibility(false);
    setDeleteTarget("");
  }, []);

  const openDeleteDialog = React.useCallback((id: string) => {
    setDialogDeleteVisibility(true);
    setDeleteTarget(id);
  }, []);

  const [deleteTakeoff, { loading: takeOffDeleting }] = useMutation<
    DeleteTakeoffResponse
  >(DELETE_TAKEOFF_ITEM, {
    update: handleTakeoffDelete(salesQuoteId),
  });

  const { data: takeOffItems, refetch: refetchTakeOffItems } = useQuery<
    ListTakeoffItemsResponse
  >(LIST_TAKEOFF, {
    variables: {
      salesQuoteId,
    },
    fetchPolicy: "cache-and-network",
  });

  const { exportTakeOffItems } = useTakeOffItemsExportQuery();

  const handleExportTakeOffItems = React.useCallback(() => {
    if (salesQuoteId) {
      exportTakeOffItems(salesQuoteId);
    }
  }, [exportTakeOffItems, salesQuoteId]);

  const [createTakeoff] = useMutation<CreateTakeoffResponse>(
    CREATE_TAKEOFF_ITEM,
    {
      onCompleted: () => {
        if (!createTakeOffRef.current?.shouldCreateAnother()) {
          createTakeOffRef.current?.show(false);
        }
        const takeOff = last(takeOffItems?.listSalesQuoteTakeOff);
        if (takeOff) {
          setSelectedTakeOffItem(takeOff);
        }
      },
      update: handleTakeOffAdd(salesQuoteId),
    }
  );

  const [updateTakeoff] = useMutation(UPDATE_TAKEOFF_ITEMS, {
    onCompleted: () => {
      updateTakeOffRef.current?.show(false);
    },
  });

  const { t } = useTranslation();
  const history = useHistory();

  const [
    selectedTakeOffItem,
    setSelectedTakeOffItem,
  ] = React.useState<TakeoffListItem | null>(null);

  React.useEffect(() => {
    if (salesQuotePlans.length) {
      let hasScaled = true;
      salesQuotePlans.forEach((plan) => {
        if (!plan.calibration.lat || !plan.calibration.lng) {
          hasScaled = false;
          return;
        }
      });
      if (!hasScaled && !ignoreUnscaledMessage) {
        unscaledRef.current?.show(true);
      }
    }
  }, [salesQuotePlans]);

  const handleOnCloseUnscaledDialog = React.useCallback(() => {
    unscaledRef.current?.show(false);
    setIgnoreUnscaledMessage(true);
  }, []);

  const handleUnscaleRedirect = React.useCallback(() => {
    if (!salesQuoteId) return;
    const url = generatePath("/quotes/:id/calibration-scale", {
      id: salesQuoteId,
    });
    history.push(url);
  }, [history, salesQuoteId]);

  const handleAddTakeOffClick = React.useCallback(() => {
    createTakeOffRef.current?.show(true);
  }, []);

  const handleEditTakeOffClick = React.useCallback(
    (takeOff: TakeoffListItem) => {
      setSelectedTakeOffItem(takeOff);
      updateTakeOffRef.current?.show(true);
    },
    []
  );

  const handleCopyTakeOffClick = React.useCallback(
    (takeOff: TakeoffListItem) => {
      setSelectedTakeOffItem(takeOff);
      copyTakeOffRef.current?.show(true);
    },
    []
  );

  const handleAddCostingClick = React.useCallback(() => {
    costingRef.current?.show(true);
  }, [costingRef]);

  const handleCreateTakeOffModalSubmit = React.useCallback(
    (data: CreateTakeOffPayload) => {
      return createTakeoff({
        variables: {
          salesQuoteId,
          takeOffItem: {
            quantity: 0,
            UOM: data.UOM,
            name: data.name,
            properties: {
              color: data.color,
            },
          },
        },
      });
    },
    [createTakeoff, salesQuoteId]
  );

  const handleCopyTakeOffModalSubmit = React.useCallback(
    (data: CopyTakeOffPayload) => {
      if (!selectedTakeOffItem) return;

      const isLm = data.UOM === TakeOffMeasurement.LINEAR_METER;
      const isM2 = data.UOM === TakeOffMeasurement.METER_SQUARED;
      const isM3 = data.UOM === TakeOffMeasurement.CUBIC_METER;
      const isTonne = data.UOM === TakeOffMeasurement.TONNE;

      let shapes = selectedTakeOffItem?.shapes?.map((item) => {
        return {
          ...omit(item, ["__typename"]),
          geometry: item.geometry.map((item) => {
            return omit(item, ["__typename"]);
          }),
          properties: omit(item.properties, ["__typename"]),
        };
      });
      if (isLm && selectedTakeOffItem.UOM !== TakeOffMeasurement.LINEAR_METER) {
        shapes = shapes?.map((item) => {
          if (item.type === TakeOffShapes.COMMENT) return item;
          const hasWallHeight = Boolean(item?.wallHeight);
          const value = { ...item, type: TakeOffShapes.POLYLINE, depth: null };
          if (hasWallHeight) {
            return value;
          }
          return {
            ...value,
            geometry: [...item.geometry, item.geometry[0]],
          };
        });
      }

      if (isTonne) {
        shapes = shapes?.map((item) => {
          if (item.type === TakeOffShapes.COMMENT) return item;
          const value = { ...item, depth: null };
          return value;
        });
      }

      if (isM3 && selectedTakeOffItem.UOM !== TakeOffMeasurement.CUBIC_METER) {
        shapes = shapes
          ?.map((item) => {
            if (item.type === TakeOffShapes.COMMENT) return item;
            const value = {
              ...item,
              type: TakeOffShapes.RECTANGLE,
              depth: Number(data?.depth),
            };
            return value;
          })
          .filter((shape) => !Boolean(shape.wallHeight));
      }

      if (
        isM2 &&
        selectedTakeOffItem.UOM !== TakeOffMeasurement.METER_SQUARED
      ) {
        shapes = shapes?.map((item) => {
          if (item.type === TakeOffShapes.COMMENT) return item;
          const value = {
            ...item,
            depth: null,
          };
          return value;
        });
      }

      copyTakeOffRef.current?.show(false);
      return createTakeoff({
        variables: {
          salesQuoteId,
          takeOffItem: {
            quantity: shapes?.length === 0 ? 0 : selectedTakeOffItem.quantity,
            shapes,
            UOM: data.UOM,
            name: data.name,
            properties: {
              color: data.color,
            },
          },
        },
      });
    },
    [createTakeoff, salesQuoteId, selectedTakeOffItem]
  );

  const handleUpdateTakeOffModalSubmit = React.useCallback(
    (data: UpdateTakeOffPayload) => {
      if (!selectedTakeOffItem) return;
      return updateTakeoff({
        variables: {
          salesQuoteId,
          takeOffItems: [
            {
              ...omitDeep(selectedTakeOffItem, ["__typename"]),
              name: data.name,
              properties: {
                color: data.color,
              },
            },
          ],
        },
      });
    },
    [selectedTakeOffItem, salesQuoteId]
  );

  const handleTakeOffSelect = React.useCallback(
    (takeOff: TakeoffListItem | null) => {
      setSelectedTakeOffItem(takeOff);
    },
    []
  );

  const handlePageSelect = React.useCallback(
    (page: number) => {
      editorRef.current?.selectPage(page);
    },
    [editorRef]
  );

  const handleShapeClick = React.useCallback(
    (shape: TakeOffShape) => {
      editorRef.current?.focusOnShape(shape);
    },
    [editorRef]
  );

  const handleDeleteTakeOff = React.useCallback(async () => {
    try {
      await deleteTakeoff({
        variables: {
          takeOffItemId: deleteTargetId,
        },
      });

      const data = takeOffItems?.listSalesQuoteTakeOff;

      if (isEmpty(data)) {
        setSelectedTakeOffItem(null);
        return;
      }

      setSelectedTakeOffItem(head(data) || null);

      notify({
        title: t("takeOffSection.deleteTakeOff"),
        content: t("takeOffSection.success.deleteTakeOff"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("takeOffSection.deleteTakeOff"),
        content: t("takeOffSection.errors.deleteTakeOff"),
      });
    }

    setDeleteTarget("");
    setDialogDeleteVisibility(false);
  }, [deleteTargetId, deleteTakeoff, takeOffItems]);

  React.useEffect(() => {
    const data = takeOffItems?.listSalesQuoteTakeOff;

    setSelectedTakeOffItem(
      find(data, { _id: selectedTakeOffItem?._id }) || null
    );
  }, [takeOffItems, selectedTakeOffItem]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quotesSection.takeoff")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.TAKEOFF} />
      <EstimationLocationHeader />

      <UpdateTakeOffModal
        ref={updateTakeOffRef}
        onSubmit={handleUpdateTakeOffModalSubmit}
        takeOff={selectedTakeOffItem}
      />
      <CreateTakeOffModal
        ref={createTakeOffRef}
        onSubmit={handleCreateTakeOffModalSubmit}
        colourIndex={takeOffItems?.listSalesQuoteTakeOff?.length || 0}
      />
      <CopyTakeOffModal
        ref={copyTakeOffRef}
        onSubmit={handleCopyTakeOffModalSubmit}
        colourIndex={takeOffItems?.listSalesQuoteTakeOff?.length || 0}
        takeOff={selectedTakeOffItem}
      />

      {salesQuoteId && (
        <AddToCostingModal
          ref={costingRef}
          salesQuoteId={salesQuoteId}
          takeOffs={takeOffItems?.listSalesQuoteTakeOff}
        />
      )}

      <ConfirmDialog
        ref={unscaledRef}
        footerClassName="justify-content-between"
        title={t("takeOffSection.unscaledPlans")}
        onClose={handleOnCloseUnscaledDialog}
        onSubmit={handleUnscaleRedirect}
        confirm={t("plansSection.scalePlans")}
        cancel={t("common.ignore")}
      >
        <span className="field-text">
          {t("takeOffSection.unscaledPlansMessage")}
        </span>
      </ConfirmDialog>

      <ConfirmDialog
        disabled={takeOffDeleting}
        title={t("takeOffSection.deleteTakeOff")}
        onSubmit={handleDeleteTakeOff}
        show={showDeleteConfirmation}
        onClose={closeDeleteDialog}
      >
        <span className="field-text">
          {t("takeOffSection.deleteTakeOffMessage")}
        </span>
      </ConfirmDialog>
      <Row className="h-100">
        <Col lg={4} xs={12} className="mh-100">
          {salesQuoteId && (
            <TakeOffCard
              salesQuoteId={salesQuoteId}
              isLocked={false}
              onDeleteTakeOffClick={openDeleteDialog}
              takeOffs={takeOffItems?.listSalesQuoteTakeOff}
              onTakeOffItemSelect={handleTakeOffSelect}
              selectedTakeOffItem={selectedTakeOffItem}
              onAddTakeOffClick={handleAddTakeOffClick}
              onEditTakeOffClick={handleEditTakeOffClick}
              onCopyTakeOffClick={handleCopyTakeOffClick}
              onAddCostingClick={handleAddCostingClick}
              onPageSelect={handlePageSelect}
              onShapeClick={handleShapeClick}
              onDownloadTakeOffs={handleExportTakeOffItems}
              refetchTakeOffItems={refetchTakeOffItems}
            />
          )}
        </Col>
        <Col lg={8} xs={12} className="h-100">
          <PlanTakeOffEditor
            ref={editorRef}
            salesQuoteId={salesQuoteId}
            takeOff={selectedTakeOffItem}
            isLocked={false}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withDashboardContext(TakeOffContainer);
