import React from "react";
import { useTranslation } from "react-i18next";
import Avatar from "../../../avatar";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./styles.scss";

type TeammateItemProps = {
  email: string;
  fullName: string;
  teammateId: string;
  removeTeammate: (memberId: string) => void;
  isReadonly?: boolean;
  avatar_url?: string;
};

const TeammateItem: React.FC<TeammateItemProps> = (props) => {
  const {
    fullName,
    email,
    teammateId,
    removeTeammate,
    isReadonly = false,
    avatar_url,
  } = props;

  const { t } = useTranslation();

  const handleRemoveTeammate = React.useCallback(() => {
    removeTeammate && removeTeammate(teammateId);
  }, [teammateId, removeTeammate]);

  return (
    <div className="teammate-item">
      <div className="teammate-avatar">
        <Avatar
          userName={fullName}
          width="35px"
          height="35px"
          rounded
          fallbackColor="dark"
          url={avatar_url}
        />
      </div>
      <div className="teammate-info">
        <div className="field-text user-name text-truncate">{fullName}</div>
        <div className="text-truncate">{email}</div>
      </div>
      {!isReadonly && (
        <DropdownButton
          id={`teammate-actions-${teammateId}`}
          size="sm"
          variant="light"
          className="teammate-actions"
          title=""
        >
          <Dropdown.Item eventKey="1" onClick={handleRemoveTeammate}>
            {t("headerTeammate.removeFromJob")}
          </Dropdown.Item>
        </DropdownButton>
      )}
    </div>
  );
};

export default TeammateItem;
