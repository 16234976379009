import { useLazyQuery } from "@apollo/client";
import { get } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  GET_FOLDER,
  GET_SYSTEM_FOLDER,
} from "../graphql/queries/documents/queries";
import { Document } from "../../src/models/documents";
import {
  GetFolderResponse,
  GetSystemFolderResponse,
} from "../graphql/types/models/documents";
import { CurrentDirectory, SystemFolderType } from "../models/documents";

import { useDownloadFileFromURL } from "./useDownloadFile";
import { imageTypes, useImageViewer } from "./useImageViewer";
import PdfViewerModal, {
  PdfViewerModalRef,
} from "../components/documents/pdf-viewer-modal";
import SelectDocumentModal from "../components/documents-main/select-document-modal";
import { NAVIGATION_ROUTES } from "../components/dashboard/sidebar/utils/navigation-items";

export type SelectDocumentsModalType = {
  entityId?: string;
  systemFolderType?: SystemFolderType;
  onSelect?: (files: Document[]) => void;
  selected?: Document[] | null;
};

const routePath = {
  [SystemFolderType.JOB]: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
  [SystemFolderType.SQ]: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
};

export function useSelectDocuments(props: SelectDocumentsModalType) {
  const { entityId, systemFolderType, onSelect, selected } = props;
  const [showSelectDocumentModal, setShowSelectDocumentModal] = React.useState(
    false
  );
  const [currentDirectory, setCurrentDirectory] = React.useState<
    CurrentDirectory
  >({
    subFolders: [],
    documents: [],
  });
  const [currentDirectoryId, setCurrentDirectoryId] = React.useState("");
  const [selectedFiles, setSelectedFiles] = React.useState<Document[] | null>(
    null
  );

  const pdfViewerModalRef = React.useRef<PdfViewerModalRef>(null);

  const history = useHistory();
  const { downloadFileFromURL } = useDownloadFileFromURL(imageTypes);
  const { openImageViewer, renderGallery } = useImageViewer();

  const sectionRoute = routePath[
    systemFolderType as SystemFolderType
  ]?.toLowerCase();

  const handleCloseSelectedFiles = React.useCallback(() => {
    setCurrentDirectoryId("");
    setShowSelectDocumentModal(false);
    setSelectedFiles(null);
  }, []);

  const handleOpenSelectDocument = React.useCallback(() => {
    setShowSelectDocumentModal(true);
  }, []);

  const selectModalFolder = React.useCallback((id: string) => {
    setCurrentDirectoryId(id);
  }, []);

  const handleSelectDocument = React.useCallback(
    (files: Document[]) => {
      onSelect?.(files);
      handleCloseSelectedFiles();
    },
    [handleCloseSelectedFiles, onSelect]
  );

  const [getSystemFolder, { refetch: refetchRoot }] = useLazyQuery<
    GetSystemFolderResponse
  >(GET_SYSTEM_FOLDER, {
    onCompleted: (data) => {
      setCurrentDirectory(data.getSystemFolder);
    },
    fetchPolicy: "cache-and-network",
    variables: {
      refId: entityId,
      systemType: systemFolderType,
    },
  });

  const [getFolder] = useLazyQuery<GetFolderResponse>(GET_FOLDER, {
    onCompleted: (data) => {
      setCurrentDirectory(data.getFolder);
    },
    onError: (error) => {
      const code = get(error, "graphQLErrors.0.extensions.code");
      if (code === "FOLDER_DOES_NOT_EXIST") {
        history.push(`/${sectionRoute}/${entityId}/documents`);
        refetchRoot && refetchRoot();
      }
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (currentDirectoryId) {
      getFolder({
        variables: {
          folderId: currentDirectoryId,
        },
      });
    }
  }, [currentDirectoryId]);

  React.useEffect(() => {
    if (!showSelectDocumentModal && entityId && systemFolderType) {
      getSystemFolder();
      if (!selected) return;
      setSelectedFiles(selected);
    }
  }, [showSelectDocumentModal, selected, entityId, systemFolderType]);

  const handleViewFile = React.useCallback(
    async (fileId: string) => {
      const file = currentDirectory.documents.find((doc) => doc._id === fileId);
      if (file) {
        const type = file.type;
        switch (type) {
          case "image/jpeg":
          case "image/jpg":
          case "image/png":
          case "image/webp":
            const imageData = await downloadFileFromURL(fileId);
            openImageViewer({
              url: imageData.data.getDocumentDownloadURL.url,
              name: imageData.data.getDocumentDownloadURL.name,
            });
            break;
          case "application/pdf":
            const res = await downloadFileFromURL(fileId);
            pdfViewerModalRef.current?.show(true, {
              url: res.data.getDocumentDownloadURL.url,
              name: res.data.getDocumentDownloadURL.name,
            });
            break;

          default:
            break;
        }
      }
    },
    [currentDirectory, downloadFileFromURL, openImageViewer]
  );

  const renderSelectDocumentModal = () => {
    return (
      <>
        {renderGallery()}
        <PdfViewerModal ref={pdfViewerModalRef} />
        <SelectDocumentModal
          documents={currentDirectory.documents}
          show={showSelectDocumentModal}
          onSubmit={handleSelectDocument}
          onClose={handleCloseSelectedFiles}
          folders={currentDirectory.subFolders}
          selectFolder={selectModalFolder}
          parentTree={currentDirectory.parentTree || null}
          onViewFile={handleViewFile}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </>
    );
  };

  return {
    renderSelectDocumentModal,
    handleOpenSelectDocument,
    handleCloseSelectedFiles,
  };
}
