import React from "react";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { isEmpty, map, compact, find, filter } from "lodash";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import TableCard, {
  EmptyTablePlaceholder,
} from "../../../components/dashboard/table-card";
import DetailsCard, {
  DetailsCardAction,
} from "../../../components/dashboard/details-card";
import { DetailsCardDataItem } from "../../../components/dashboard/details-card/DetailsCardList";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import {
  TableCardData,
  TableCardDataRow,
  TableCardAction,
  TableRowActionData,
  ExpandedFieldOptions,
} from "../../../components/dashboard/table-card/utils";
import DashboardBackNavigation from "../../../components/navigation/DashboardBackNavigation";
import CreateSupplierModal, {
  CreateSupplierModalRef,
} from "../../../components/contacts/create-supplier-modal";
import {
  SupplierOrder,
  SupplierCertification,
  SupplierCertificatioPayload,
} from "../../../models/supplier";
import { GET_SUPPLIER } from "../../../graphql/queries/supplier/queries";
import { LIST_SUPPLIER_CERTIFICATION } from "../../../graphql/queries/supplier-certification/queries";
import {
  CREATE_UPDATE_SUPPLIER_CERTIFICATION,
  DELETE_SUPPLIER_CERTIFICATION,
} from "../../../graphql/queries/supplier-certification/mutations";
import {
  SupplierDetailResponse,
  ListSupplierCertificationResponse,
  CreateUpdateSupplierCertificationResponse,
  DeleteSupplierCertificationResponse,
} from "../../../graphql/types/models/supplier";
import {
  handleAddCertification,
  handleDeleteCertification,
} from "../../../graphql/queries/supplier-certification/utils";
import { formatQuoteNumber } from "../../../utils/text";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import AddCertificationModal, {
  AddCertificationModalRef,
} from "../../../components/contacts/add-certification-modal";
import { uploadFiles } from "../../../utils/files";
import { getMediaInput } from "../../../utils/transform";
import { Media } from "../../../models/media";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import "./styles.scss";
import { notify } from "../../../components/notification";
import { useNote } from "../../../hooks/useNote";
import { NoteEntityType } from "../../../graphql/types/models/note";

type Params = {
  id: string;
};

type ContactsSupplierContainerProps = {} & RouteComponentProps<Params>;

type SupplierOrderTable = Omit<SupplierOrder, "job"> & {
  job: string;
};

const ContactsSupplierContainer: React.FC<ContactsSupplierContainerProps> = (
  props
) => {
  const history = useHistory();
  const { match } = props;
  const supplierId = match.params.id;

  const supplierRef = React.useRef<CreateSupplierModalRef>(null);
  const addCertificationRef = React.useRef<AddCertificationModalRef>(null);
  const confirmRef = React.useRef<ConfirmDialogRef>(null);

  const { renderNotesCard } = useNote(NoteEntityType.Supplier, supplierId);

  const [files, setFiles] = React.useState<File[]>([]);
  const [deleteTarget, setDeleteTarget] = React.useState("");

  const { data: supplierData, loading: supplierLoading, error } = useQuery<
    SupplierDetailResponse
  >(GET_SUPPLIER, {
    variables: {
      id: supplierId,
    },
  });

  const {
    data: supplierCertificationList,
    loading: supplierCertificationsLoading,
  } = useQuery<ListSupplierCertificationResponse>(LIST_SUPPLIER_CERTIFICATION, {
    variables: {
      supplierId,
    },
  });

  const [
    addUpdateSupplierCertification,
    { loading: suppplierAddingLoading },
  ] = useMutation<CreateUpdateSupplierCertificationResponse>(
    CREATE_UPDATE_SUPPLIER_CERTIFICATION,
    {
      onCompleted: async (data) => {
        if (
          files &&
          files.length > 0 &&
          data.createUpdateSupplierCertification.files
        ) {
          await uploadFiles(
            data.createUpdateSupplierCertification.files.map((file) => ({
              name: file.name,
              upload_url: file.upload_url,
            })),
            files
          );
        }
      },
    }
  );

  const [
    deleteSupplierCertification,
    { loading: supplierCertificationDeleting },
  ] = useMutation<DeleteSupplierCertificationResponse>(
    DELETE_SUPPLIER_CERTIFICATION
  );

  const { t } = useTranslation();
  const ordersTableData = React.useMemo<TableCardData<SupplierOrderTable>>(
    () => ({
      columns: [
        {
          valueKey: "orderNumber",
          title: t("common.number"),
          formatValue: (row: any, column: any, value: string) =>
            formatQuoteNumber(value, "PO"),
        },
        {
          valueKey: "reference",
          title: t("orders.reference"),
        },
        {
          valueKey: "date",
          title: t("orders.orderDate"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
        {
          valueKey: "deliveryDate",
          title: t("orders.deliveryDate"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
        {
          valueKey: "job",
          title: t("orders.jobName"),
        },
        {
          valueKey: "status",
          title: t("common.status"),
          formatValue: (row: any, column: any, value: string) =>
            t(`orders.statuses.${value}`),
        },
      ],
      rows: map(supplierData?.getSupplierDetails?.purchase_orders, (order) => ({
        cells: {
          ...order,
          job: order.job?.name || "",
        },
      })),
    }),
    [t, supplierData]
  );

  const handleCloseAddCertification = React.useCallback(
    () => addCertificationRef.current?.show(false),
    [addCertificationRef]
  );

  const handleOpenAddCertification = React.useCallback(
    () => addCertificationRef.current?.show(true),
    [addCertificationRef]
  );

  const handleAddCertificationSubmit = React.useCallback(
    async (data: SupplierCertificatioPayload) => {
      try {
        addCertificationRef.current?.show(false);

        if (!data) return;
        if (data.files) setFiles(data.files);

        await addUpdateSupplierCertification({
          variables: {
            certification: {
              ...data,
              supplierId,
              files: getMediaInput(data.files),
            },
          },
          update: handleAddCertification(supplierId),
        });

        notify({
          title: t("certification.addCertification"),
          content: t("certification.success.addCertification"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("certification.addCertification"),
          content: t("certification.error.addCertification"),
        });
      }

      setFiles([]);
    },
    [t, addCertificationRef, addUpdateSupplierCertification, supplierId]
  );

  const openCofirmDialog = React.useCallback(
    (row?: SupplierCertification) => {
      if (row) {
        setDeleteTarget(row._id);
        confirmRef.current?.show(true);
      }
    },
    [confirmRef]
  );

  const closeConfirmDialog = React.useCallback(() => {
    setDeleteTarget("");
    confirmRef.current?.show(false);
  }, [confirmRef]);

  const handleRemoveCertification = React.useCallback(async () => {
    try {
      if (!deleteTarget) return;

      await deleteSupplierCertification({
        variables: {
          certificationId: deleteTarget,
        },
        update: handleDeleteCertification(supplierId),
      });

      notify({
        title: t("certification.deleteCertification"),
        content: t("certification.success.deleteCertification"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("certification.deleteCertification"),
        content: t("certification.error.deleteCertification"),
      });
    }
    setDeleteTarget("");
  }, [t, deleteSupplierCertification, supplierId, deleteTarget]);

  const certificationsTableData = React.useMemo<
    TableCardData<SupplierCertification>
  >(
    () => ({
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
        },
        {
          valueKey: "document_type",
          title: t("certification.type"),
        },
        {
          valueKey: "expiry_date",
          title: t("certification.expiryDate"),
          formatValue: (row: any, col: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
      ],
      rows: map(
        supplierCertificationList?.listSupplierCertification,
        (item) => ({
          cells: item,
        })
      ),
    }),
    [supplierCertificationList, t]
  );

  const supplierCertificationsTableActions = React.useMemo<TableCardAction[]>(
    () => [
      {
        title: t("certification.addCertification"),
        onClick: handleOpenAddCertification,
        icon: "add",
        className: "button large success btn-supper-large",
      },
    ],
    [t, handleOpenAddCertification]
  );

  const supplierCertificationsRowActions = React.useMemo<
    TableRowActionData<SupplierCertification>[]
  >(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "certification-list",
        options: [
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openCofirmDialog,
          },
        ],
      },
    ],
    [t, openCofirmDialog]
  );

  const detailsCardData: DetailsCardDataItem[] = React.useMemo(() => {
    const data = supplierData?.getSupplierDetails;

    if (supplierLoading || !data) {
      return [];
    }

    return filter(
      [
        {
          icon: "work_outline",
          text: data.business_name,
        },
        {
          icon: "shield",
          text: data.abn ? t("company.abnNumberValue", data) : "",
        },
        {
          icon: "person",
          text: data.contact_person,
        },
        {
          icon: "label",
          text: data.category?.name
            ? data.category.name
            : t("contacts.uncategorised"),
        },
        {
          icon: "room",
          text: compact([
            data.address,
            data.address2,
            data.city,
            data.state,
            data.postcode,
          ]).join(", "),
        },
        {
          icon: "mail",
          text: data.email,
        },
        {
          icon: "local_phone",
          text: compact([data.phone_number, data.phone_secondary]).join(", "),
        },
      ],
      (r) => !!r.text
    );
  }, [supplierData, supplierLoading]);

  const handleEditButtonClick = React.useCallback(() => {
    supplierRef.current?.show(true);
  }, [supplierRef]);

  const detailsCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        disabled: supplierLoading,
        title: t("common.edit"),
        onClick: handleEditButtonClick,
        className: "button info",
      },
    ],
    [t, supplierLoading, handleEditButtonClick]
  );

  const handlePurchaseOrderRow = React.useCallback(
    (row: TableCardDataRow<SupplierOrderTable>) => {
      const po = find(supplierData?.getSupplierDetails?.purchase_orders, {
        _id: row.cells._id,
      });
      if (po?.job) {
        history.push(`/jobs/${po.job._id}/purchase-orders/${po._id}`);
      }
    },
    [supplierData]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("certification.emptyPlaceholder"),
      buttonText: t("certification.addCertification"),
      onPress: handleOpenAddCertification,
    }),
    [t, handleOpenAddCertification]
  );

  const handleFileClick = React.useCallback((file: Media) => {
    if (!file) return;

    window.open(file.url, "_certification");
  }, []);

  const getLabel = React.useCallback(
    (quantity: number) => {
      if (quantity === 1)
        return t("certification.fileNumber", { number: quantity });
      return t("certification.filesNumber", { number: quantity });
    },
    [t]
  );

  const expandedFieldOptions = React.useMemo<ExpandedFieldOptions<Media>>(
    () => ({
      expandedField: "files",
      expandedSubFiled: "name",
      dropdownId: "files-id",
      onFieldClick: handleFileClick,
      icon: "more_horiz",
      getLabel: getLabel,
    }),
    [handleFileClick, getLabel]
  );

  if (!!error) {
    return null;
  }

  return (
    <Container className="contacts-supplier" fluid>
      <DashboardBackNavigation
        routeId={NAVIGATION_ROUTES.CONTACTS_SECTION.CONTACTS}
      />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.CONTACTS_SECTION.SUPPLIER}
      />
      <Helmet title={t("navigation.contactsSection.client")} />

      {supplierData?.getSupplierDetails && (
        <CreateSupplierModal
          ref={supplierRef}
          supplier={supplierData?.getSupplierDetails}
        />
      )}

      <ConfirmDialog
        disabled={supplierCertificationDeleting}
        title={t("certification.deleteCertification")}
        onSubmit={handleRemoveCertification}
        onClose={closeConfirmDialog}
        ref={confirmRef}
      >
        <div className="field-text">
          {t("certification.deleteCertificationMessage")}
        </div>
      </ConfirmDialog>

      <AddCertificationModal
        ref={addCertificationRef}
        onClose={handleCloseAddCertification}
        onSubmit={handleAddCertificationSubmit}
      />

      <Row className="w-100">
        <Col lg={4} xs={12} className="pb-sm-5 pb-lg-0">
          <DetailsCard
            fullHeight
            title={t("common.details")}
            data={detailsCardData}
            actions={detailsCardActions}
          />
          <div className="pt-sm-5">{renderNotesCard()}</div>
        </Col>
        <Col lg={8} xs={12} className="pb-sm-5 pb-lg-0">
          <TableCard
            isDataLoading={
              supplierLoading ||
              isEmpty(supplierData?.getSupplierDetails?.purchase_orders)
            }
            headerVariant="default"
            title={t("navigation.jobsSection.purchaseOrders")}
            data={ordersTableData}
            onRowClick={handlePurchaseOrderRow}
          />
          <div className="certification-wrapper">
            <TableCard
              isDataLoading={
                supplierCertificationsLoading ||
                supplierCertificationDeleting ||
                suppplierAddingLoading
              }
              headerVariant="default"
              title={t("certification.certifications")}
              data={certificationsTableData}
              actions={supplierCertificationsTableActions}
              rowActions={supplierCertificationsRowActions}
              emptyPlaceholder={emptyPlaceholder}
              expandedFieldOptions={expandedFieldOptions}
              expandedFieldTitle={t("certification.files")}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactsSupplierContainer;
