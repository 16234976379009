import React from "react";
import moment from "moment";
import classnames from "classnames";
import { compact, lowerFirst } from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Icon from "../../icons/Icon";
import {
  EnumSystemUserType,
  EnumNotificationType,
  Notification,
  ReadNotificationResponse,
} from "../../../graphql/types/models/notification";
import {
  QuoteRequest,
  QuoteRequestComment,
} from "../../../graphql/types/models/quote-request";
import { READ_NOTIFICATION } from "../../../graphql/queries/notification/mutations";
import { formatQuoteNumber } from "../../../utils/text";
import "./styles.scss";
import { VariationReal } from "../../../models/variations";
import {
  EnumSelectionLinkedEntityType,
  Selection,
} from "../../../graphql/types/models/selections";

type DashboardNotificationItemProps = {
  notification: Notification;
};
const DashboardNotificationItem: React.FC<DashboardNotificationItemProps> = (
  props
) => {
  const { notification } = props;
  const t = useTranslation();
  const history = useHistory();

  const [readNotification] = useMutation<ReadNotificationResponse>(
    READ_NOTIFICATION
  );

  const sender = React.useMemo(() => {
    if (notification.senderType === EnumSystemUserType.Subcontractor) {
      return (
        notification.sender.business_name || notification.sender.contact_person
      );
    } else if (notification.senderType === EnumSystemUserType.Builder) {
      return notification.sender.fullName;
    } else if (notification.senderType === EnumSystemUserType.Client) {
      return compact([
        notification.sender.first_name,
        notification.sender.last_name,
      ]).join(" ");
    }
  }, [notification.sender]);

  const entity = React.useMemo(() => {
    switch (notification.notificationType) {
      case EnumNotificationType.QuoteRequestComment: {
        const entity = notification.entity as QuoteRequestComment;
        return [
          formatQuoteNumber(entity.quoteRequest?.quoteNumber || ""),
          entity.quoteRequest?.name,
        ].join(" - ");
      }
      case EnumNotificationType.QuoteRequest:
      case EnumNotificationType.QuoteRequestClientAccept: {
        const entity = notification.entity as QuoteRequest;
        return [formatQuoteNumber(entity.quoteNumber || ""), entity.name].join(
          " - "
        );
      }
      case EnumNotificationType.VariationClientAccept: {
        const entity = notification.entity as VariationReal;
        return [
          formatQuoteNumber(entity.variationNumber || "", "V"),
          entity.name,
        ].join(" - ");
      }
      case EnumNotificationType.SelectionClientSubmit: {
        const entity = notification.entity as Selection;
        let data = [];
        if (
          entity.linkedEntityType === EnumSelectionLinkedEntityType.ESTIMATION
        ) {
          data = [
            formatQuoteNumber(entity.entity?.number || "", "Q"),
            entity.entity?.name,
          ];
        } else {
          data = [
            formatQuoteNumber(entity.entity?.jobNumber || "", "J"),
            entity.entity?.name,
          ];
        }
        return data.join(" - ");
      }
      default:
        return null;
    }
  }, [notification]);

  const icon = React.useMemo(() => {
    switch (notification.notificationType) {
      case EnumNotificationType.QuoteRequestComment:
        return "comment";
      case EnumNotificationType.QuoteRequest:
      case EnumNotificationType.QuoteRequestClientAccept:
        return "request_quote";
      case EnumNotificationType.VariationClientAccept:
        return "carpenter";
      case EnumNotificationType.SelectionClientSubmit:
        return "checklist";
      default:
        return "";
    }
  }, [notification.notificationType]);

  const handleClick = React.useCallback(async () => {
    if (!notification.is_read) {
      await readNotification({
        variables: {
          notificationId: notification._id,
        },
      });
    }

    switch (notification.notificationType) {
      case EnumNotificationType.QuoteRequestComment: {
        const entity = notification.entity as QuoteRequestComment;
        history.push(
          `/quote-requests/${entity.quoteRequest?._id}/sub/${entity.subcontractorResponseId}`
        );
        break;
      }
      case EnumNotificationType.QuoteRequest:
      case EnumNotificationType.QuoteRequestClientAccept: {
        const entity = notification.entity as QuoteRequest;
        history.push(`/quote-requests/${entity._id}`);
        break;
      }
      case EnumNotificationType.VariationClientAccept: {
        const entity = notification.entity as QuoteRequest;
        history.push(`/jobs/${entity.job._id}/variations`);
        break;
      }
      case EnumNotificationType.SelectionClientSubmit: {
        const entity = notification.entity as Selection;
        if (
          entity.linkedEntityType === EnumSelectionLinkedEntityType.ESTIMATION
        ) {
          history.push(`/quotes/${entity.entity?._id}/selections`);
        } else {
          history.push(`/jobs/${entity.entity?._id}/selections`);
        }
        break;
      }
      default:
        return null;
    }
  }, [notification]);

  return (
    <div
      className={classnames("dashboard-notification", {
        unread: !notification.is_read,
      })}
      onClick={handleClick}
    >
      <div className="notification-icon">
        <Icon name={icon} />
      </div>
      <div className="notification-message">
        <b>{sender} </b>
        {lowerFirst(notification.message)}
        <b> {entity}</b>
        <span
          className="notification-date"
          title={moment(notification.createdAt).format("llll")}
        >
          {moment(notification.createdAt).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default DashboardNotificationItem;
