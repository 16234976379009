import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { map } from "lodash";
import moment from "moment";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { useTakeOffTemplate } from "../../../hooks/useTakeOffTemplate";
import { SalesQuoteTakeOffTemplate } from "../../../models/salesQuote";

const TakeoffTemplatesOverview: React.FC = () => {
  const { t } = useTranslation();

  const {
    takeOffTemplates,
    templatesDataLoading,
    deleteTakeoffTemplate,
  } = useTakeOffTemplate();

  const deleteConfirmRef = useRef<ConfirmDialogRef>();

  const handleDelete = React.useCallback(
    (templateId: string) => () => {
      if (!templateId) {
        return;
      }
      deleteTakeoffTemplate({
        variables: {
          templateId,
        },
      });

      deleteConfirmRef.current?.show(false);
    },
    [deleteTakeoffTemplate]
  );

  const openRemoveDialog = React.useCallback(
    (row?: SalesQuoteTakeOffTemplate) => {
      if (row) {
        deleteConfirmRef.current?.show(true, handleDelete(row._id));
      }
    },
    [handleDelete]
  );

  const takeoffTemplatesData: TableCardData<SalesQuoteTakeOffTemplate> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
        },
        {
          valueKey: "updatedAt",
          title: t("common.lastUpdated"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
      ],
      rows: map(takeOffTemplates, (template: SalesQuoteTakeOffTemplate) => ({
        cells: template,
      })),
    };
  }, [t, takeOffTemplates]);

  const tableRowActions: TableRowActionData<
    SalesQuoteTakeOffTemplate
  >[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "document-list",
        options: [
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openRemoveDialog,
          },
        ],
      },
    ],
    [openRemoveDialog, t]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet
        title={t("navigation.settings.templatesSection.takeoffTemplates")}
      />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.TAKEOFF_TEMPLATES}
      />
      <ConfirmDialog
        ref={deleteConfirmRef}
        title={t("takeOffSection.deleteTemplate")}
        confirm={t("common.delete")}
      >
        <div className="field-text">
          {t("takeOffSection.removeTemplateMessage")}
        </div>
      </ConfirmDialog>

      <TableCard
        isDataLoading={templatesDataLoading}
        fullHeight
        overflowXHidden
        data={takeoffTemplatesData}
        rowActions={tableRowActions}
        alignEnd
      />
    </Container>
  );
};

export default TakeoffTemplatesOverview;
