import { TFunction } from "i18next";

import {
  CreateTakeOffPayload,
  TakeOffMeasurement,
} from "../../../../models/take-off";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import { compact } from "lodash";

export function copyTakeOffFields(
  t: TFunction,
  currentUOM: string,
  onMeasurementChange: (value: string) => void,
  activeMeasurementTab: string,
  isHideMeasurement: boolean
): GenericFormFields<CreateTakeOffPayload> {
  const isSquareMeters = currentUOM === TakeOffMeasurement.METER_SQUARED;
  const isActiveTabCubicMeters =
    activeMeasurementTab === TakeOffMeasurement.CUBIC_METER;

  return {
    name: {
      type: "text",
      label: t("takeOffSection.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    ...(!isHideMeasurement && {
      UOM: {
        type: "button-select",
        label: t("takeOffSection.measurement"),
        valueKey: "UOM",
        buttonSelectProps: {
          onChange: onMeasurementChange,
          options: compact([
            {
              value: TakeOffMeasurement.LINEAR_METER,
              label: t("takeOffSection.linearMeters"),
            },
            {
              value: TakeOffMeasurement.METER_SQUARED,
              label: t("takeOffSection.metersSquared"),
            },
            {
              value: TakeOffMeasurement.CUBIC_METER,
              label: t("takeOffSection.metersCubic"),
            },
          ]),
        },
      },
    }),
    ...(isSquareMeters &&
      isActiveTabCubicMeters && {
        depth: {
          type: "text",
          label: t("takeOffSection.depth"),
          placeholder: t("takeOffSection.depth"),
          valueKey: "depth",
          disallowNegativeNumber: true,
          showError: true,
          inputProps: {
            type: "text",
            required: true,
          },
        },
      }),
    color: {
      type: "colour-picker",
      label: t("takeOffSection.colour"),
      placeholder: t("placeholders.colour"),
      valueKey: "color",
      className: "take-off-colour-picker",
    },
  };
}
