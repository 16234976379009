import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import TasksTable from "../tasks-table";
import TaskOverviewContainer from "../task-overview";
import { TaskArchiveStatus } from "../../../models/task";
import { UserPayload } from "../../../graphql/types/models/auth";

type TasksContainerProps = {
  user: UserPayload | null;
};

const TasksContainer: FC<TasksContainerProps> = ({ user }) => {
  return (
    <Switch>
      <Route
        exact
        path="/tasks"
        render={() => (
          <TasksTable
            user={user}
            archiveStatus={TaskArchiveStatus.UNARCHIVED}
          />
        )}
      />
      <Route
        path="/tasks/archived"
        render={() => (
          <TasksTable user={user} archiveStatus={TaskArchiveStatus.ARCHIVED} />
        )}
      />
      <Route path="/tasks/:id" component={TaskOverviewContainer} />
    </Switch>
  );
};

export default TasksContainer;
