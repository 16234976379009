import { gql } from "@apollo/client";
import {
  QUOTE_REQUEST_FRAGMENT,
  CLIENT_QUOTE_REQUEST_FRAGMENT,
} from "./fragments";

export const CREATE_QUOTE_REQUEST = gql`
  mutation($quoteRequest: QuoteRequestInput!) {
    createUpdateQuoteRequest(quoteRequest: $quoteRequest) {
      ...QuoteRequest
    }
  }
  ${QUOTE_REQUEST_FRAGMENT}
`;

export const UPDATE_QUOTE_REQUEST_SUBCONTRACTOR_ITEMS = gql`
  mutation(
    $quoteRequestId: ID!
    $subcontractorId: ID!
    $items: [SubcontractorQuoteItemInput]!
  ) {
    updateQuoteRequestSubcontractorItems(
      quoteRequestId: $quoteRequestId
      subcontractorId: $subcontractorId
      items: $items
    ) {
      ...QuoteRequest
    }
  }
  ${QUOTE_REQUEST_FRAGMENT}
`;

export const SEND_QUOTE_REQUEST = gql`
  mutation(
    $quoteRequestId: ID!
    $subcontractorId: ID
    $message: JobSendEmailInput
  ) {
    sendQuoteRequest(
      quoteRequestId: $quoteRequestId
      subcontractorId: $subcontractorId
      message: $message
    ) {
      ...QuoteRequest
    }
  }
  ${QUOTE_REQUEST_FRAGMENT}
`;

export const COMMENT_QUOTE_REQUEST = gql`
  mutation($quoteRequestId: ID!, $comment: QuoteRequestCommentInput!) {
    commentQuoteRequest(quoteRequestId: $quoteRequestId, comment: $comment) {
      _id
      subcontractorResponseId
      comment
      is_deleted
      updatedAt
      createdAt
      attachments {
        _id
        url
        upload_url
        type
        size
        name
      }
    }
  }
`;

export const ACCEPT_QUOTE_REQUEST = gql`
  mutation($quoteRequestId: ID!, $subcontractorResponseId: ID!) {
    acceptQuoteRequestResponse(
      quoteRequestId: $quoteRequestId
      subcontractorResponseId: $subcontractorResponseId
    ) {
      _id
      status
      subcontractors {
        _id
        status
        client_feedback
      }
    }
  }
`;

export const DECLINE_QUOTE_REQUEST = gql`
  mutation($quoteRequestId: ID!, $subcontractorResponseId: ID!) {
    declineQuoteRequestResponse(
      quoteRequestId: $quoteRequestId
      subcontractorResponseId: $subcontractorResponseId
    ) {
      _id
      status
      subcontractors {
        _id
        status
        client_feedback
      }
    }
  }
`;

export const SHARE_QUOTE_REQUEST_WITH_CLIENT = gql`
  mutation ShareQuoteRequestResponseWithClient(
    $jobId: ID!
    $quoteRequestId: ID!
    $subcontractorResponseIdList: [ID]!
    $message: JobSendEmailInput!
  ) {
    shareQuoteRequestResponseWithClient(
      jobId: $jobId
      quoteRequestId: $quoteRequestId
      subcontractorResponseIdList: $subcontractorResponseIdList
      message: $message
    ) {
      ...QuoteRequest
    }
  }
  ${QUOTE_REQUEST_FRAGMENT}
`;

export const CLIENT_ACCEPT_JOB_QUOTE_REQUEST = gql`
  mutation ClientAcceptJobQuoteSubResponseBySub(
    $jobId: ID!
    $quoteRequestId: ID!
    $subResId: ID!
  ) {
    clientAcceptJobQuoteSubResponseBySub(
      jobId: $jobId
      quoteRequestId: $quoteRequestId
      subResId: $subResId
    ) {
      ...ClientQuoteRequestData
    }
  }
  ${CLIENT_QUOTE_REQUEST_FRAGMENT}
`;
