import { gql } from "@apollo/client";
import { SUBSCRIPTION_PLAN } from "./fragments";

export const LIST_PLANS = gql`
  query {
    listPlans {
      ...Plan
    }
  }
  ${SUBSCRIPTION_PLAN}
`;

export const LIST_INVOICES = gql`
  query {
    listInvoices {
      _id
      number
      amount_due
      amount_paid
      amount_remaining
      total
      subtotal
      invoice_pdf
      paid
      period_start
      period_end
      createdAt
    }
  }
`;

export const GET_ACTIVE_SUBSCRIPTION = gql`
  query {
    getSubscriptionStatus {
      subscriptionEndDate
      invoiceAmount
      status
      plan {
        ...Plan
      }
    }
  }
  ${SUBSCRIPTION_PLAN}
`;
