import React from "react";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import { useTranslation } from "react-i18next";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import CheckList from "../../check-list";
import { IMPORT_CHECKLIST } from "./constants";
import FileInput, {
  FILE_RESTRICTION_SPREADSHEET,
} from "../../uploaders/file-input";
import "./styles.scss";

type LeadsImportType = {
  onFileSelect: (file: File[]) => void;
  fileName?: string;
};

const LeadsImport: React.FC<LeadsImportType> = (props) => {
  const { t } = useTranslation();
  const { onFileSelect, fileName } = props;

  const renderLabel = (label: string) => {
    return (
      <div className="field-text form-input-label font-weight-bold">
        {label}
      </div>
    );
  };

  return (
    <DashboardCard className="leads-import">
      <DashboardCardHeader>{t("mapping.importLeads")}</DashboardCardHeader>
      <DashboardCardBody>
        <div className="field-text form-input-label font-weight-bold">
          {t("leads.checkList")}
        </div>
        <CheckList items={IMPORT_CHECKLIST} />

        {renderLabel(t("leads.uploadFile"))}
        <FileInput
          fileName={fileName}
          accept={FILE_RESTRICTION_SPREADSHEET}
          label={t("leads.dropLeadsFile")}
          iconSize={60}
          onUpload={onFileSelect}
        />
      </DashboardCardBody>
      <DashboardCardFooter className="import-footer"></DashboardCardFooter>
    </DashboardCard>
  );
};

export default LeadsImport;
