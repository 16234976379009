import React from "react";
import { TFunction } from "i18next";
import { DashboardNavigationContext } from "../../../../../containers/layouts/dashboard/DashboardContext";

import Icon from "../../../../icons/Icon";
import SVGIcon from "../../../../icons/SVGIcon";

import { NAVIGATION_ROUTES } from "../navigation-items";

export const builderAdminNavItems = (
  t: TFunction,
  context: DashboardNavigationContext | null
) => [
  {
    id: NAVIGATION_ROUTES.DASHBOARD,
    icon: () => <Icon name="home" outlined />,
    title: t("navigation.dashboard"),
    url: "/",
  },
  {
    id: NAVIGATION_ROUTES.GLOBAL_SCHEDULE,
    icon: () => <SVGIcon name="Tasks" />,
    title: t("navigation.schedule"),
    url: "/schedule",
  },
  {
    id: NAVIGATION_ROUTES.CALENDAR,
    icon: () => <Icon name="date_range" outlined />,
    title: t("navigation.calendar"),
    url: "/calendar",
  },
  {
    id: NAVIGATION_ROUTES.COMMUNICATION_SECTION.COMMUNICATION,
    expandable: true,
    icon: () => <Icon name="forum" outlined />,
    url: "/communication",
    title: t("navigation.communicationSection.communication"),
    defaultItemId: NAVIGATION_ROUTES.COMMUNICATION_SECTION.MESSAGES,
    items: [
      // {
      //   id: NAVIGATION_ROUTES.COMMUNICATION_SECTION.CONVERSATIONS,
      //   icon: () => <Icon name="chat_bubble_outline" outlined />,
      //   url: "/communication/conversation",
      //   title: t("navigation.communicationSection.conversations"),
      // },
      // {
      //   id: NAVIGATION_ROUTES.COMMUNICATION_SECTION.EMAILS,
      //   icon: () => <Icon name="email" outlined />,
      //   url: "/communication/email",
      //   title: t("navigation.communicationSection.emails"),
      // },
      {
        id: NAVIGATION_ROUTES.COMMUNICATION_SECTION.MESSAGES,
        icon: () => <Icon name="chat_bubble_outline" outlined />,
        title: t("navigation.communicationSection.messages"),
        url: `/communication/messages`,
      },
      // {
      //   id: NAVIGATION_ROUTES.COMMUNICATION_SECTION.SMS,
      //   icon: () => <Icon name="textsms" outlined />,
      //   url: "/communication/sms",
      //   title: t("navigation.communicationSection.sms"),
      // },
    ],
  },
  {
    id: NAVIGATION_ROUTES.LEADS,
    icon: () => <Icon name="add_business" outlined />,
    title: t("navigation.leads"),
    url: "/leads",
    expandable: true,
    defaultItemId: NAVIGATION_ROUTES.LEADS_SECTION.OVERVIEW,
    items: [
      {
        id: NAVIGATION_ROUTES.LEADS_SECTION.OVERVIEW,
        icon: () => <Icon name="add_business" outlined />,
        title: t("navigation.leads"),
        url: "/leads/",
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.LEADS_SECTION.LEAD,
        icon: () => <Icon name="add_business" outlined />,
        title: t("navigation.leadsSection.lead"),
        url: `/leads/${context?.lead?._id}`,
        hideInSidebarMenu: true,
        displayTitle: t("navigation.leadsSection.leadName", {
          name: context?.lead?.name,
        }),
      },
      {
        id: NAVIGATION_ROUTES.LEADS_SECTION.IMPORT_LEADS,
        icon: () => <Icon name="cloud_upload" outlined />,
        title: t("navigation.leadsSection.importLeads"),
        url: "/leads/map",
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.TASKS,
    icon: () => <Icon name="task" outlined />,
    title: t("navigation.tasks"),
    url: "/tasks",
    expandable: true,
    defaultItemId: NAVIGATION_ROUTES.TASKS_SECTION.OVERVIEW,
    items: [
      {
        id: NAVIGATION_ROUTES.TASKS_SECTION.ARCHIVED_TASKS,
        icon: () => <Icon name="archive" outlined />,
        title: t("navigation.tasksSection.archived"),
        url: "/tasks/archived",
        hideInSidebarMenu: !!context?.task,
      },

      {
        id: NAVIGATION_ROUTES.TASKS_SECTION.OVERVIEW,
        icon: () => <Icon name="task" outlined />,
        title: t("navigation.tasks"),
        url: "/tasks",
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.TASKS_SECTION.TASK,
        icon: () => <Icon name="task" outlined />,
        title: t("navigation.tasksSection.task"),
        url: `/tasks/${context?.task?._id}`,
        hideInSidebarMenu: true,
        displayTitle: t("navigation.tasksSection.taskName", {
          name: context?.task?.task,
        }),
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.CONTACTS_SECTION.CONTACTS,
    icon: () => <Icon name="account_box" outlined />,
    title: t("navigation.contactsSection.contacts"),
    url: "/contacts",
    expandable: true,
    defaultItemId: NAVIGATION_ROUTES.CONTACTS_SECTION.OVERVIEW,
    items: [
      {
        id: NAVIGATION_ROUTES.CONTACTS_SECTION.OVERVIEW,
        icon: () => <Icon name="home" outlined />,
        title: t("navigation.contactsSection.contacts"),
        url: "/contacts/",
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.CONTACTS_SECTION.CLIENT,
        icon: () => <Icon name="people_alt" outlined />,
        title: t("navigation.contactsSection.client"),
        url: "/contacts/clients/",
      },
      {
        id: NAVIGATION_ROUTES.CONTACTS_SECTION.SUPPLIER,
        icon: () => <Icon name="construction" outlined />,
        title: t("navigation.contactsSection.supplier"),
        url: "/contacts/suppliers/",
      },
      {
        id: NAVIGATION_ROUTES.CONTACTS_SECTION.IMPORT_CONTACTS,
        icon: () => <Icon name="cloud_upload" outlined />,
        title: t("navigation.contactsSection.importContacts"),
        url: "/contacts/map",
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.DOCUMENTS_MAIN,
    icon: () => <Icon name="folder" outlined />,
    title: t("navigation.documents"),
    url: "/documents",
  },
  {
    id: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
    icon: () => <SVGIcon name="Bill" />,
    title: t("navigation.quotesSection.estimations"),
    url: "/quotes",
    expandable: true,
    defaultItemId: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
    items: [
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.ARCHIVED_QUOTES,
        icon: () => <Icon name="assignment_turned_in" outlined />,
        title: t("navigation.quotesSection.archived"),
        url: "/quotes/archived",
        hideInSidebarMenu: !!context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
        icon: () => <SVGIcon name="Grid2" />,
        title: t("navigation.quotesSection.quotes"),
        displayTitle: t("navigation.quotesSection.estimations"),
        url: "/quotes",
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.OVERVIEW,
        icon: () => <SVGIcon name="Grid2" />,
        title: t("navigation.quotesSection.overview"),
        url: `/quotes/${context?.quote?._id}`,
        hideInSidebarMenu: !context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.PLANS,
        icon: () => <SVGIcon name="Construction Plan" />,
        title: t("navigation.quotesSection.plans"),
        url: `/quotes/${context?.quote?._id}/plans`,
        hideInSidebarMenu: !context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.SCALE,
        icon: () => <SVGIcon name="Ruler" />,
        title: t("navigation.quotesSection.scale"),
        url: `/quotes/${context?.quote?._id}/calibration-scale`,
        displayTitle: t("navigation.quotesSection.calibrateScale"),
        hideInSidebarMenu: !context?.quote || !context?.quote?.hasFiles,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.TAKEOFF,
        icon: () => <SVGIcon name="Purchase Order" />,
        title: t("navigation.quotesSection.takeoff"),
        url: `/quotes/${context?.quote?._id}/take-off`,
        hideInSidebarMenu: !context?.quote || !context?.quote?.hasFiles,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.COSTING,
        icon: () => <SVGIcon name="Pricing" />,
        title: t("navigation.quotesSection.costing"),
        url: `/quotes/${context?.quote?._id}/costing`,
        hideInSidebarMenu: !context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.IMPORT_COSTING,
        icon: () => <Icon name="cloud_upload" outlined />,
        title: t("navigation.quotesSection.importCostings"),
        url: `/quotes/${context?.quote?._id}/true`,
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.SPECIFICATIONS,
        icon: () => <Icon name="summarize" outlined />,
        title: t("navigation.quotesSection.specifications"),
        url: `/quotes/${context?.quote?._id}/specifications`,
        hideInSidebarMenu: !context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.SELECTIONS,
        icon: () => <Icon name="grading" outlined />,
        title: t("navigation.quotesSection.selections"),
        url: `/quotes/${context?.quote?._id}/selections`,
        hideInSidebarMenu: !context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTE_REQUESTS,
        icon: () => <Icon name="price_check" />,
        title: t("navigation.quotesSection.quoteRequests"),
        url: `/quotes/${context?.quote?._id}/quote-requests`,
        hideInSidebarMenu: !context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.PREVIEW,
        icon: () => <SVGIcon name="Document" />,
        title: t("navigation.quotesSection.preview"),
        url: `/quotes/${context?.quote?._id}/preview`,
        hideInSidebarMenu: !context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.DOCUMENTS,
        icon: () => <Icon name="document_scanner" outlined />,
        title: t("navigation.quotesSection.documents"),
        url: `/quotes/${context?.quote?._id}/documents`,
        hideInSidebarMenu: !context?.quote,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.JOB,
        icon: () => <SVGIcon name="Hammer" />,
        title: t("navigation.quotesSection.job"),
        url: `/jobs/${context?.quote?.jobId}`,
        hideInSidebarMenu: !context?.quote?.jobId,
      },
      {
        id: NAVIGATION_ROUTES.QUOTES_SECTION.CHECKLISTS,
        icon: () => <Icon name="checklist" outlined />,
        title: t("navigation.quotesSection.checklists"),
        url: "/quotes/checklists",
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.QUOTE_REQUESTS_SECTION.QUOTES,
    icon: () => <SVGIcon name="Pricing" />,
    title: t("navigation.quoteRequestSection.quoteRequests"),
    url: "/quote-requests",
    defaultItemId: NAVIGATION_ROUTES.QUOTE_REQUESTS_SECTION.QUOTES,
    expandable: true,
    items: [
      {
        id: NAVIGATION_ROUTES.QUOTE_REQUESTS_SECTION.QUOTES,
        icon: () => <SVGIcon name="Pricing" />,
        title: t("navigation.quoteRequestSection.quoteRequests"),
        url: `/quote-requests/`,
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.QUOTE_REQUESTS_SECTION.DETAILS,
        icon: () => <SVGIcon name="Pricing" />,
        title: t("navigation.quoteRequestSection.quoteRequest"),
        displayTitle: `${context?.quoteRequest?.quoteNumber} ${context?.quoteRequest?.name}`,
        url: `/quote-requests/${context?.quoteRequest?._id || ""}`,
        hideInSidebarMenu: true,
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.TIMESHEETS_SECTION.TIMESHEETS,
    icon: () => <Icon name="schedule" outlined />,
    title: t("navigation.timesheetsSection.timesheets"),
    url: "/timesheets",
    expandable: true,
    defaultItemId: NAVIGATION_ROUTES.TIMESHEETS_SECTION.TIMESHEETS,
    items: [
      {
        id: NAVIGATION_ROUTES.TIMESHEETS_SECTION.TIMESHEETS,
        icon: () => <Icon name="schedule" outlined />,
        title: t("navigation.timesheetsSection.timesheets"),
        url: "/timesheets",
        hideInSidebarMenu: true,
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
    icon: () => <SVGIcon name="Hammer" />,
    title: t("navigation.jobsSection.jobs"),
    url: "/jobs",
    expandable: true,
    defaultItemId: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
    items: [
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.JOBS,
        icon: () => <SVGIcon name="Hammer" />,
        title: t("navigation.jobsSection.jobs"),
        url: "/jobs",
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.SEARCH,
        icon: () => <Icon name="search" outlined />,
        title: t("navigation.jobsSection.search"),
        url: "/jobs/search",
        hideInSidebarMenu: !!context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.COMPLETED_JOBS,
        icon: () => <Icon name="assignment_turned_in" outlined />,
        title: t("navigation.jobsSection.completedJobs"),
        url: "/jobs/completed",
        hideInSidebarMenu: !!context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.WORK_IN_PROGRESS,
        icon: () => <Icon name="construction" outlined />,
        title: t("navigation.jobsSection.workInProgress"),
        url: "/jobs/wip",
        hideInSidebarMenu: !!context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.ESTIMATION,
        icon: () => <SVGIcon name="Bill" />,
        title: t("navigation.jobsSection.estimation"),
        url: `/quotes/${context?.job?.salesQuote?._id}/costing`,
        hideInSidebarMenu: !context?.job?.salesQuote?._id,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.OVERVIEW,
        icon: () => <SVGIcon name="Grid2" />,
        title: t("navigation.jobsSection.overview"),
        url: `/jobs/${context?.job?._id}`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.JOB_MESSAGES,
        icon: () => <Icon name="chat_bubble_outline" outlined />,
        title: t("navigation.jobsSection.jobMessages"),
        url: `/jobs/${context?.job?._id}/messages`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.SCHEDULE,
        icon: () => <SVGIcon name="Tasks" />,
        title: t("navigation.jobsSection.schedule"),
        url: `/jobs/${context?.job?._id}/schedule`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.COSTING,
        icon: () => <SVGIcon name="Pricing" />,
        title: t("navigation.jobsSection.costing"),
        url: `/jobs/${context?.job?._id}/costings`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.PURCHASE_ORDERS,
        icon: () => <SVGIcon name="Purchase Order" />,
        title: t("navigation.jobsSection.purchaseOrders"),
        url: `/jobs/${context?.job?._id}/purchase-orders`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.TIMESHEET,
        icon: () => <Icon name="schedule" outlined />,
        title: t("navigation.jobsSection.timesheets"),
        url: `/jobs/${context?.job?._id}/timesheets`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.ROSTER,
        icon: () => <SVGIcon name="Team" />,
        title: t("navigation.jobsSection.roster"),
        url: `/jobs/${context?.job?._id}/roster`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.VARIATIONS,
        icon: () => <SVGIcon name="Change" />,
        title: t("navigation.jobsSection.variations"),
        url: `/jobs/${context?.job?._id}/variations`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.PROGRESS_CLAIMS,
        icon: () => <SVGIcon name="Progress Claim" />,
        title: t("navigation.jobsSection.progressClaims"),
        url: `/jobs/${context?.job?._id}/progress-claims`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.QUOTE_REQUESTS,
        icon: () => <Icon name="price_check" />,
        title: t("navigation.jobsSection.quoteRequests"),
        url: `/jobs/${context?.job?._id}/quote-requests`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.SITE_DIARY,
        icon: () => <Icon name="book" outlined />,
        title: t("navigation.jobsSection.siteDiary"),
        url: `/jobs/${context?.job?._id}/site-diary`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.DOCUMENTS,
        icon: () => <SVGIcon name="Document" />,
        title: t("navigation.jobsSection.documents"),
        url: `/jobs/${context?.job?._id}/documents`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.REPORTS,
        icon: () => <Icon name="analytics" outlined />,
        title: t("navigation.jobsSection.reports"),
        url: `/jobs/${context?.job?._id}/reports`,
        hideInSidebarMenu: !context?.job,
      },
      {
        id: NAVIGATION_ROUTES.JOBS_SECTION.CHECKLISTS,
        icon: () => <Icon name="checklist" outlined />,
        title: t("navigation.jobsSection.checklists"),
        url: "/jobs/checklists",
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.SITE_DIARIES,
    icon: () => <Icon name="book" outlined />,
    title: t("navigation.siteDiaries"),
    url: "/site-diaries",
    expandable: false,
  },
  {
    id: NAVIGATION_ROUTES.SETTINGS.SETTINGS,
    icon: () => <SVGIcon name="Settings" />,
    title: t("navigation.settings.settingsOverview"),
    url: "/settings",
    defaultItemId: NAVIGATION_ROUTES.SETTINGS.COMPANY,
    expandable: true,
    items: [
      {
        id: NAVIGATION_ROUTES.SETTINGS.BILLING,
        icon: () => <SVGIcon name="Bill" />,
        title: t("navigation.settings.billing"),
        url: "/settings/billing",
      },
      {
        id: NAVIGATION_ROUTES.SETTINGS.COMPANY,
        icon: () => <SVGIcon name="Company" />,
        title: t("navigation.settings.company"),
        url: "/settings/company",
      },
      {
        id: NAVIGATION_ROUTES.SETTINGS.TEAM,
        icon: () => <SVGIcon name="Team" />,
        title: t("navigation.settings.team"),
        url: "/settings/team",
      },
      {
        id: NAVIGATION_ROUTES.SETTINGS.PRICE_LISTS,
        icon: () => <SVGIcon name="Pricing" />,
        title: t("navigation.settings.priceLists"),
        url: "/settings/price-lists",
      },
      {
        id: NAVIGATION_ROUTES.SETTINGS.PRICE_LIST,
        icon: () => <SVGIcon name="Pricing" />,
        title: t("navigation.settings.priceList"),
        displayTitle: t("navigation.settings.priceListName", {
          name: context?.priceList?.name,
        }),
        url: `/settings/price-list/${context?.priceList?._id}`,
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.SETTINGS.ASSEMBLIES,
        icon: () => <SVGIcon name="Assembly" />,
        title: t("navigation.settings.assemblies"),
        url: "/settings/assemblies",
      },
      {
        id: NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.TEMPLATES,
        icon: () => <Icon name="library_add_check" outlined />,
        title: t("navigation.settings.templatesSection.templates"),
        url: "/settings/templates",
        expandable: true,
        defaultItemId: NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.TEMPLATES,
        items: [
          {
            id: NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.TEMPLATES,
            icon: () => <Icon name="library_add_check" outlined />,
            title: t("navigation.settings.templatesSection.templates"),
            url: "/settings/templates",
            hideInSidebarMenu: true,
          },
          {
            id:
              NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SITE_DIARY_TEMPLATES,
            icon: () => <SVGIcon name="Site Diary" />,
            title: t("navigation.settings.templatesSection.siteDiaries"),
            url: "/settings/site-diaries",
          },
          {
            id:
              NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.ESTIMATION_TEMPLATES,
            icon: () => <SVGIcon name="Tasks" />,
            title: t(
              "navigation.settings.templatesSection.estimationTemplates"
            ),
            url: "/settings/estimation-templates",
          },
          {
            id: NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.TAKEOFF_TEMPLATES,
            icon: () => <Icon name="auto_awesome_motion" outlined />,
            title: t("navigation.settings.templatesSection.takeoffTemplates"),
            url: "/settings/takeoff-templates",
          },
          {
            id:
              NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.CHECKLIST_TEMPLATES,
            icon: () => <Icon name="fact_check" outlined />,
            title: t("navigation.settings.templatesSection.checklistTemplates"),
            url: "/settings/checklist-templates",
          },
          {
            id: NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.CHECKLIST_TEMPLATE,
            icon: () => <Icon name="summarize" outlined />,
            title: t("navigation.settings.templatesSection.checklistTemplates"),
            displayTitle: t(
              "navigation.settings.templatesSection.checklistTemplateName",
              {
                name: context?.checklistTemplate?.name,
              }
            ),
            url: `/settings/checklist-templates/${context?.checklistTemplate?._id}`,
            hideInSidebarMenu: true,
          },
          {
            id:
              NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION
                .SPECIFICATION_TEMPLATES,
            icon: () => <Icon name="summarize" outlined />,
            title: t(
              "navigation.settings.templatesSection.specificationTemplates"
            ),
            url: "/settings/specification-templates",
          },
          {
            id:
              NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SELECTION_TEMPLATES,
            icon: () => <Icon name="grading" outlined />,
            title: t("navigation.settings.selectionTemplates"),
            url: "/settings/selection-templates",
          },
          {
            id: NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SELECTION_TEMPLATE,
            icon: () => <Icon name="grading" outlined />,
            title: t("navigation.settings.selectionTemplates"),
            displayTitle: t("navigation.settings.selectionTemplateName", {
              name: context?.selectionTemplate?.name,
            }),
            url: `/settings/selection-templates/${context?.selectionTemplate?._id}`,
            hideInSidebarMenu: true,
          },
          {
            id:
              NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION
                .SPECIFICATION_TEMPLATE,
            icon: () => <Icon name="summarize" outlined />,
            title: t("navigation.settings.specificationTemplates"),
            displayTitle: t(
              "navigation.settings.templatesSection.specificationTemplateName",
              {
                name: context?.specificationTemplate?.name,
              }
            ),
            url: `/settings/specification-templates/${context?.specificationTemplate?._id}`,
            hideInSidebarMenu: true,
          },
          {
            id: NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SCHEDULE_TEMPLATES,
            icon: () => <Icon name="calendar_today" outlined />,
            title: t("navigation.settings.templatesSection.scheduleTemplates"),
            url: "/settings/schedule-templates",
          },
        ],
      },
      {
        id: NAVIGATION_ROUTES.SETTINGS.INTEGRATIONS,
        icon: () => <SVGIcon name="Connect" />,
        title: t("navigation.settings.integrations"),
        url: "/settings/integrations",
      },
    ],
  },
  {
    id: NAVIGATION_ROUTES.ACCOUNT.ACCOUNT,
    icon: () => <SVGIcon name="User" />,
    title: t("navigation.account.profile"),
    url: "/account",
    defaultItemId: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
    expandable: true,
    items: [
      {
        id: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
        icon: () => <SVGIcon name="User" />,
        title: t("navigation.account.profile"),
        url: "/account/profile",
        hideInSidebarMenu: true,
      },
      {
        id: NAVIGATION_ROUTES.ACCOUNT.LOGOUT,
        icon: () => <Icon name="logout" outlined />,
        title: t("navigation.account.logout"),
        isLogout: true,
      },
    ],
  },
];
