import React, { useState } from "react";
import { map, uniqBy, get, omit } from "lodash";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { useApolloClient } from "@apollo/client";
import UpdateEntityModal from "../../modals/update-entity";
import {
  CreateJobCostingPayload,
  UpdateJobCostingPayload,
  JobCostingItem,
  JobCostingCategory,
  JobCostingSubItemPayload,
} from "../../../models/job";
import CreateAssemblyModal, {
  CreateAssemblyModalRef,
} from "../../costing/create-assembly-modal";
import {
  AssemblyCreatePayload,
  AssemblyListItem,
} from "../../../models/assembly";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import {
  AppendDynamicProps,
  DynamicProps,
  FormikPropGetSetValues,
  GenericFormFields,
} from "../../generic-form/GenericFormBody";
import { createJobCostingFields } from "../create-job-costing-modal/utils";
import CalculatorModal from "../../modals/calculator";
import createJobCostingSchema from "../create-job-costing-modal/CreateJobCosting.schema";
import { UOMOption } from "../../../utils/types/options";
import {
  calcGSTEx,
  calcWithGST,
  GST_PERCENT,
} from "../../../utils/calculations";
import { useCostingLookup } from "../../../hooks/useCostingLookup";
import { addAssemblyItem } from "../../costing/update-costing-modal/utils";
import { Rounding } from "../../calculator/utils";
import "./styles.scss";
import { useCostChangeGST } from "../../../hooks/useCostChangeGST";
import { usePriceLookup } from "../../../hooks/usePriceLookup";
import { useCreateAssemblyFromSelection } from "../../../hooks/useCreateAssemblyFromSelection";

type UpdateJobCostingModalProps = {
  onSubmit: (
    data: UpdateJobCostingPayload,
    assemblies?: AssemblyListItem[]
  ) => void;
  data: JobCostingCategory;
  title: string;
  uoms: UOMOption[];
};

const UpdateJobCostingModal: React.FC<UpdateJobCostingModalProps> = (
  props,
  ref
) => {
  const { onSubmit, data, title, uoms: currentUOMs } = props;

  const { t } = useTranslation();
  const client = useApolloClient();
  const {
    assemblies,
    assemblyOptions,
    setDefaultItems,
    handleAssemblyCreation,
    handleCostingNameChange,
    handleIsAssemblyCheck,
    searchCostingItem,
  } = useCostingLookup(client);

  const {
    handleCostChange,
    costsHaveGST,
    handleCostsHaveGST,
    resetCostsHaveGST,
  } = useCostChangeGST();

  const { shouldShow, hide } = useModalDisplay(ref);

  const assemblyRef = React.useRef<CreateAssemblyModalRef>(null);

  const [showCalculator, setCalculatorVisibility] = useState(false);
  const [uoms, setUOMs] = React.useState<UOMOption[]>([]);
  const [initialised, setInitialised] = React.useState(false);
  const [assemblyRowIndex, setAssemblyRowIndex] = React.useState<
    number | undefined
  >();

  const handleUOMCreate = React.useCallback(
    (uom: string) => {
      const newUOMs = uniqBy(
        uoms.concat([{ label: uom, value: uom }]),
        "value"
      );
      setUOMs(newUOMs);
    },
    [uoms, setUOMs]
  );

  const { priceLookupButton, renderPriceLookupModal } = usePriceLookup(
    t,
    "items",
    handleUOMCreate,
    !!data.is_locked || !!data.is_restricted_entry
  );

  const {
    handleOnCreateAssemblyFromSelection,
    hasSelectedItems,
    handleResetSelectedItems,
  } = useCreateAssemblyFromSelection("items", setAssemblyRowIndex, assemblyRef);

  React.useEffect(() => {
    setUOMs(currentUOMs);
  }, [currentUOMs]);

  React.useEffect(() => {
    const items = map(
      data?.items,
      (item: JobCostingItem) =>
        ({
          ...item,
          _id: item.assemblyId || item.name,
          name: item.name,
          UOM: item.UOM,
          total: Number(item.cost) || 0,
          __typename: "",
        } as AssemblyListItem)
    );
    setDefaultItems(items);
  }, [data]);

  const toggleCalculatorModal = React.useCallback(() => {
    setCalculatorVisibility(!showCalculator);
  }, [showCalculator]);

  const [calcFieldName, setFieldName] = useState("");

  const isLocked = React.useMemo(() => {
    return data.is_locked || false;
  }, [data]);

  const openCalculatorModal = React.useCallback((fieldName: string) => {
    setFieldName(fieldName);
    setCalculatorVisibility(true);
  }, []);

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateJobCostingPayload>
  >({});

  const handleSubmit = React.useCallback(() => {
    setCalculatorVisibility(false);
  }, []);

  const handlePriceSearch = React.useCallback(
    (value: string) =>
      data.is_locked ? Promise.resolve([]) : searchCostingItem(value),
    [client, data]
  );

  const initialData = React.useMemo(() => {
    setInitialised(true);
    return {
      ...data,
      items: data.items
        .filter((item) => !item.is_locked)
        .map((item) => ({
          ...item,
          cost_inc: calcWithGST(item.cost, GST_PERCENT),
          real_cost_inc: calcWithGST(item.real_cost, GST_PERCENT),
          wastage: Number(item.wastage || 0),
          note: item.note || "",
          order: 0,
          gstFree: !item.hasGST,
        })),
    };
  }, [data]);

  const handleAssemblyPress = React.useCallback(
    (
      fieldName: string,
      fieldValue?: string,
      rowIndex?: number,
      formikProps?: FormikProps<any>
    ) => {
      const formAssembly = get(formikProps?.values, fieldName.split(".")[0]);
      setAssemblyRowIndex(rowIndex);
      const assembly = formAssembly?.assemblyId
        ? formAssembly
        : { ...formAssembly, ...addAssemblyItem(formAssembly) };

      assemblyRef.current?.show(true, assembly);
    },
    [assemblies, assemblyRef]
  );

  const handleAssemblyChange = React.useCallback(
    (fieldValue, rowIndex, formikProps, fieldName) => {
      if (!initialised) return;

      const fieldsArray = `items[${rowIndex}]`;
      const item = handleCostingNameChange(
        fieldValue,
        fieldsArray,
        formikProps,
        true
      );
      if (item?.UOM) {
        handleUOMCreate(item.UOM);
      }
    },
    [initialised, handleUOMCreate]
  );

  const getAssemblyProps = React.useCallback(
    (formikProps, __, rowIndex): AppendDynamicProps => {
      if (
        data.is_locked ||
        data.is_restricted_entry ||
        formikProps.values.items[rowIndex]?.is_restricted_entry
      ) {
        return {
          isHidden: true,
        };
      }

      const hasAssembly = !!formikProps.values.items[rowIndex]?.assemblyId;
      return {
        icon: "file_copy",
        className: hasAssembly ? "success" : "",
      };
    },
    [data]
  );

  const handleAssemblySubmit = React.useCallback(
    async (assembly: AssemblyCreatePayload, formikProps: FormikProps<any>) => {
      const fieldsArray = `items[${assemblyRowIndex}]`;
      await handleAssemblyCreation(fieldsArray, assembly, formikProps);
      if (hasSelectedItems) {
        handleResetSelectedItems(formikProps);
      }
      assemblyRef.current?.show(false);
    },
    [assemblyRef, assemblyRowIndex, hasSelectedItems]
  );

  const getFieldProps = React.useCallback(
    (formikProps, fieldName, rowIndex): DynamicProps => {
      const name = fieldName.split(".")?.[1];
      const fieldValue = formikProps.values.items[rowIndex];

      let disabled = false;
      if (data.is_locked || data.is_restricted_entry) {
        if (
          fieldValue?.is_locked ||
          fieldValue?.is_restricted_entry ||
          ["quantity", "UOM", "cost", "cost_inc"].indexOf(name) >= 0
        ) {
          disabled = true;
        }
      }
      if (fieldValue?.is_restricted_entry) {
        disabled = true;
      }
      return { disabled };
    },
    [data]
  );

  const getCalcProps = React.useCallback(
    (formikProps, __, rowIndex): AppendDynamicProps => {
      if (
        data.is_locked ||
        data.is_restricted_entry ||
        formikProps.values.items[rowIndex]?.is_restricted_entry
      ) {
        return {
          isHidden: true,
        };
      }
      return {};
    },
    [data]
  );

  const handleDisableRowDeleting = React.useCallback(
    (formikProps: FormikPropGetSetValues, rowIndex: number) => {
      const item = get(formikProps.values, ["items", rowIndex]);
      return item?.is_locked || item?.is_restricted_entry;
    },
    []
  );

  // not used
  const handleDisableRowEditing = React.useCallback(
    (subItem: JobCostingSubItemPayload) => {
      if (subItem?._id) {
        const filter = data.items.filter(
          (item) => item?._id === subItem?._id && item?.is_locked
        );
        return !!filter.length;
      }
      return false;
    },
    [data]
  );

  // clear wastage when manually setting quantity
  const handleQuantityChange = React.useCallback(
    (fieldValue, formikProps, rowIndex) => {
      if (!initialised) return;
      formikProps.setFieldValue(
        `items[${rowIndex}].raw_quantity`,
        fieldValue.toString()
      );
      formikProps.setFieldValue(`items[${rowIndex}].wastage`, 0);
      formikProps.setFieldValue(`items[${rowIndex}].rounding`, "");
    },
    [initialised]
  );

  React.useEffect(() => {
    setFormFields(
      createJobCostingFields(
        t,
        uoms,
        openCalculatorModal,
        handleUOMCreate,
        handlePriceSearch,
        assemblyOptions,
        getFieldProps,
        getAssemblyProps,
        getCalcProps,
        handleAssemblyPress,
        handleAssemblyChange,
        handleQuantityChange,
        handleCostChange,
        isLocked,
        costsHaveGST,
        handleCostsHaveGST,
        handleDisableRowDeleting,
        priceLookupButton,
        handleDisableRowEditing,
        handleOnCreateAssemblyFromSelection
      )
    );
  }, [
    t,
    uoms,
    assemblyOptions,
    handlePriceSearch,
    openCalculatorModal,
    handleUOMCreate,
    getFieldProps,
    getAssemblyProps,
    getCalcProps,
    handleAssemblyPress,
    handleAssemblyChange,
    handleQuantityChange,
    handleCostChange,
    handleDisableRowEditing,
    handleDisableRowDeleting,
    costsHaveGST,
    handleCostsHaveGST,
    isLocked,
    priceLookupButton,
    handleOnCreateAssemblyFromSelection,
  ]);

  const handleUpdateSubmit = React.useCallback(
    (data: UpdateJobCostingPayload) => {
      onSubmit &&
        onSubmit(
          {
            ...data,
            items: data.items.map((item, index) => ({
              ...omit(item, ["cost_inc", "real_cost_inc", "gstFree"]),
              cost: costsHaveGST
                ? calcGSTEx(item.cost_inc || 0, GST_PERCENT)
                : item.cost,
              real_cost: costsHaveGST
                ? calcGSTEx(item.real_cost_inc || 0, GST_PERCENT)
                : item.real_cost,
              order: index + 1,
              hasGST: !item.gstFree,
              rounding: item?.rounding || Rounding.NONE,
            })),
          },
          assemblies
        );
      hide();
      resetCostsHaveGST();
    },
    [onSubmit, assemblies, costsHaveGST]
  );

  const handleAssemblyOnClose = React.useCallback(() => {
    assemblyRef.current?.show(false);
  }, []);

  const handleOnClose = React.useCallback(() => {
    hide();
    resetCostsHaveGST();
    assemblyRef.current?.show(false);
  }, []);

  return (
    <UpdateEntityModal
      title={title}
      data={initialData}
      onClose={handleOnClose}
      validationSchema={createJobCostingSchema(t)}
      onSubmit={handleUpdateSubmit}
      show={shouldShow}
      fields={formFields}
      className="update-job-costing-modal"
      disableEventValidation={true}
    >
      {(formikProps) => {
        const wastage = get(formikProps.values, calcFieldName.split(".")[0])
          ?.wastage;
        const rounding = get(formikProps.values, calcFieldName.split(".")[0])
          ?.rounding;
        const quantity = get(formikProps.values, calcFieldName);
        const raw_quantity = get(
          formikProps.values,
          calcFieldName.split(".")[0]
        )?.raw_quantity;
        const rawQuantity = raw_quantity || quantity;
        const itemName = get(formikProps.values, calcFieldName.split(".")[0])
          ?.name;

        return (
          <>
            {renderPriceLookupModal(formikProps)}
            <CalculatorModal
              formikProps={formikProps}
              show={showCalculator}
              onSubmit={handleSubmit}
              fieldName={calcFieldName}
              initialValue={rawQuantity}
              onClose={toggleCalculatorModal}
              wastage={wastage?.toString()}
              rounding={rounding}
              title={itemName}
            />

            <CreateAssemblyModal
              ref={assemblyRef}
              uoms={uoms}
              onSubmit={handleAssemblySubmit}
              onClose={handleAssemblyOnClose}
              formikProps={formikProps}
            />
          </>
        );
      }}
    </UpdateEntityModal>
  );
};

export default React.forwardRef(UpdateJobCostingModal);
