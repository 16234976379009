import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { compact, map } from "lodash";
import { useQuery } from "@apollo/client";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";

import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import { HeaderSearchOption } from "../../../components/dashboard/header-search";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import TableCard, {
  EmptyTablePlaceholder,
} from "../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import { getFullAddress } from "../../../utils/text";
import CreateSupplierModal, {
  CreateSupplierModalRef,
} from "../../../components/contacts/create-supplier-modal";
import { SupplierListResponse } from "../../../graphql/types/models/supplier";
import { LIST_SUPPLIERS } from "../../../graphql/queries/supplier/queries";
import DashboardActionHeader, {
  ActionButton,
} from "../../../components/dashboard/table-card/DashboardActionHeader";
import { useSuppliersExportQuery } from "../../../hooks/queries/useSuppliersExportQuery";

type SuppliersContainerProps = DashboardContextValue & RouteComponentProps & {};

export type SupplierTablePayload = {
  _id: string;
  name: string;
  email: string;
  location: string;
  phone: string;
};

const SuppliersOverviewContainer: React.FC<SuppliersContainerProps> = ({
  setSearchOptions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { exportSuppliers } = useSuppliersExportQuery();

  const supplierRef = React.useRef<CreateSupplierModalRef>(null);

  const { loading: suppliersLoading, data: suppliersData } = useQuery<
    SupplierListResponse
  >(LIST_SUPPLIERS);

  const suppliersTable = React.useMemo<SupplierTablePayload[]>(() => {
    if (suppliersLoading) {
      return [];
    }

    return map(suppliersData?.listSuppliers, (supplier) => ({
      _id: supplier._id,
      name: supplier.business_name,
      email: supplier.email,
      location: getFullAddress(supplier),
      phone: compact([supplier.phone_number, supplier.phone_secondary]).join(
        " / "
      ),
    }));
  }, [suppliersLoading, suppliersData]);

  const searchOptions = React.useMemo(() => {
    return suppliersTable.map((supplier) => ({
      value: supplier._id,
      label: supplier.name,
    }));
  }, [suppliersTable]);

  const suppliersTableData = React.useMemo<
    TableCardData<SupplierTablePayload>
  >(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("contacts.name"),
          sortable: true,
        },
        {
          valueKey: "email",
          title: t("contacts.email"),
          sortable: true,
        },
        {
          valueKey: "phone",
          title: t("contacts.phone"),
          sortable: true,
        },
        {
          valueKey: "location",
          title: t("contacts.location"),
          sortable: true,
        },
      ],
      rows: map(suppliersTable, (supplier) => ({
        cells: supplier,
      })),
    };
  }, [suppliersTable, t]);

  const handleSupplierTableRowClick = React.useCallback(
    (row: TableCardDataRow<SupplierTablePayload>) => {
      history.push(`/contacts/suppliers/${row.cells._id}`);
    },
    [history]
  );

  const handleAddItemClick = React.useCallback(
    () => supplierRef.current?.show(true),
    [supplierRef]
  );

  const handleSearchSelect = React.useCallback(
    (option: HeaderSearchOption | null) => {
      if (!option) return;
      history.push(`/contacts/suppliers/${option.value}`);
    },
    [history]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("contacts.emptySupplierPlaceholder"),
      buttonText: t("contacts.createSupplier"),
      onPress: handleAddItemClick,
    }),
    [t, handleAddItemClick]
  );

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("contacts.searchSuppliers"),
      options: searchOptions,
      onSelect: handleSearchSelect,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [t, searchOptions, setSearchOptions]);

  const exportButtonAction = React.useMemo<ActionButton[]>(
    () => [
      {
        className: "export-button",
        icon: "download",
        onClick: () => exportSuppliers({}),
      },
    ],
    [exportSuppliers]
  );

  return (
    <Container fluid className="tables-layout">
      <Helmet title={t("navigation.contactsSection.contacts")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.CONTACTS_SECTION.SUPPLIER}
      />
      <CreateSupplierModal ref={supplierRef} />
      <Row className="w-100 justify-content-end pb-4">
        <Col>
          <DashboardActionHeader
            onActionButton={handleAddItemClick}
            additionalActionButtons={exportButtonAction}
          />
        </Col>
      </Row>
      <Row className="w-100">
        <Col xs={12}>
          <TableCard
            tableId="suppliers-list"
            isDataLoading={suppliersLoading}
            data={suppliersTableData}
            onRowClick={handleSupplierTableRowClick}
            emptyPlaceholder={emptyPlaceholder}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(withDashboardContext(SuppliersOverviewContainer));
