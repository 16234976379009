import classNames from "classnames";
import React, { useState } from "react";

type PlanViewerToolbarButtonProps = {
  actionReference?: any;
  onClick?: () => void;
  disabled?: boolean;
  onSelect?: () => void;
  reset?: () => void;
  isTwoStep?: boolean;
  canDraw?: boolean;
  allowEmpty?: boolean;
  title?: string;
  className?: string;
};

const PlanViewerToolbarButton: React.FC<PlanViewerToolbarButtonProps> = ({
  actionReference,
  onClick,
  children,
  disabled,
  onSelect,
  reset,
  title,
  isTwoStep = false,
  canDraw = true,
  allowEmpty = false,
  className,
}) => {
  const [isActive, setActive] = useState(false);
  const [isPreActive, setPreActive] = useState(false);

  const onActionEnabled = React.useCallback(
    (e: any) => {
      setActive(true);
      onSelect && onSelect();
    },
    [onSelect]
  );

  const onActionDisabled = React.useCallback(() => {
    setActive(false);
    setPreActive(false);
  }, []);

  const disablePrestep = React.useCallback(() => {
    setPreActive(false);
    setActive(false);
  }, []);

  React.useEffect(() => {
    if (!actionReference) return;

    actionReference.handler.on("enabled", onActionEnabled);
    actionReference.handler.on("disabled", onActionDisabled);
    actionReference.handler.disablePrestep = disablePrestep;

    return () => {
      actionReference.handler.off("enabled", onActionEnabled);
      actionReference.handler.off("disabled", onActionDisabled);
      actionReference.handler.disablePrestep = null;
    };
  }, [onActionEnabled, onActionDisabled, actionReference]);

  const handleButtonClick = React.useCallback(() => {
    if (!actionReference) return;
    const action = actionReference.handler;

    if (!isTwoStep || action._enabled) {
      if (action._enabled) {
        action.save && action.save();
        action.disable();
      } else {
        reset && reset();
        action.enable();
      }
    } else {
      reset && reset();
      setPreActive(!isPreActive);
    }
  }, [actionReference, reset, isPreActive, isTwoStep]);

  React.useEffect(() => {
    if (!isTwoStep) return;
    if (!actionReference) return;
    const action = actionReference.handler;
    if (canDraw || allowEmpty) {
      if (isPreActive) {
        reset && reset();
        action.enable();
      }
    } else if (action._enabled) {
      action.disable();
      setPreActive(true);
    }
  }, [actionReference, canDraw, isTwoStep, isPreActive]);

  const getHandler = () => {
    if (disabled) {
      return;
    }

    if (onClick) {
      return onClick;
    }

    return handleButtonClick;
  };

  return (
    <div
      className={classNames(className, "toolbar-button", {
        enabled: isActive || isPreActive,
        disabled,
      })}
      onClick={getHandler()}
      title={title}
    >
      {children}
    </div>
  );
};

export default PlanViewerToolbarButton;
