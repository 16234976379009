import React from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_CLAIM } from "../../graphql/queries/job-claim/queries";
import { GetClaimResponse } from "../../graphql/types/models/job-claim";

export function useProgressClaimQuery(
  jobId?: string,
  progressClaimId?: string
) {
  const [getClaim, { data: claimData }] = useLazyQuery<GetClaimResponse>(
    GET_CLAIM
  );

  React.useEffect(() => {
    if (jobId && progressClaimId) {
      getClaim({
        variables: {
          jobId,
          progressClaimId,
        },
      });
    }
  }, [jobId, progressClaimId]);

  const progressClaim = React.useMemo(() => claimData?.getJobProgressClaim, [
    claimData,
  ]);

  return { progressClaim };
}
