import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import "./styles.scss";
import { DashboardBackNavigationOptions } from "../../../containers/layouts/dashboard/DashboardContext";
import Icon from "../../icons/Icon";

type HeaderBackNavigationProps = RouteComponentProps &
  DashboardBackNavigationOptions & {};

const HeaderBackNavigation: React.FC<HeaderBackNavigationProps> = ({
  item,
  history,
}) => {
  const handleNavigationClick = React.useCallback(() => {
    item.url && history.push(item.url);
  }, [history, item]);

  return (
    <div onClick={handleNavigationClick} className="back-navigation">
      <Icon name="chevron_left" />
      <div className="title">{item.title}</div>
    </div>
  );
};

export default withRouter(HeaderBackNavigation);
