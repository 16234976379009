import { gql } from "@apollo/client";

export const CLIENT_LIST_SALES_QUOTES = gql`
  query {
    listClientSalesQuotes {
      _id
      name
      address
      city
      state
      postcode
      number
      status
      updatedAt
      createdAt
      is_deleted
      business {
        name
        abn
        address
        address2
        city
        state
        postcode
        country
        logo {
          _id
          url
        }
      }
    }
  }
`;
