import { gql } from "@apollo/client";

export const SALES_QUOTE_FRAGMENT = gql`
  fragment QuoteFragment on SalesQuote {
    _id
    name
    address
    city
    state
    postcode
    number
    subTotal
    GST
    total
    createdAt
    status
    include_costing
    terms
    markup
    is_locked
    contact {
      _id
      first_name
      last_name
    }
    printTemplate {
      _id
      name
    }
  }
`;
