import React from "react";
import { components, MultiValueProps } from "react-select";
import classNames from "classnames";

type SelectMultiValueLabelProps = MultiValueProps<any> & {};

const SelectMultiValueLabel: React.FC<SelectMultiValueLabelProps> = (props) => {
  const innerProps = {
    ...props.innerProps,
    className: classNames(props.innerProps.className, "multi-value-label"),
  };

  return <components.MultiValueLabel {...props} innerProps={innerProps} />;
};

export default SelectMultiValueLabel;
