import { MutationUpdaterFn } from "@apollo/client";

import { GET_ROSTER, LIST_ROSTERS } from "./queries";
import { concat, filter, findIndex, omit } from "lodash";
import {
  CreateUpdateRosterResponse,
  DeleteRosterResponse,
  GetRosterResponse,
  ListRostersResponse,
} from "../../types/models/job-roster";
import {
  findCostingItemFromCache,
  updateCostingItemCache,
} from "../job-costing/utils";
import { RosterModalListItem } from "../../../models/roster";

export const handleRosterCreateUpdate = (
  jobId?: string | null
): MutationUpdaterFn<CreateUpdateRosterResponse> => (cache, response) => {
  const createdRoster = response.data?.jobCreateUpdateRoster;

  const rosterListResponse = cache.readQuery<ListRostersResponse>({
    query: LIST_ROSTERS,
    variables: {
      jobId,
    },
  });

  if (!createdRoster) {
    return;
  }
  if (rosterListResponse) {
    const rosters = [...rosterListResponse.getJobRosters];
    const index = findIndex(rosters, { _id: createdRoster._id });
    if (index >= 0) {
      rosters[index] = {
        ...rosters[index],
        ...createdRoster,
      };
    } else {
      rosters.push(createdRoster);
    }

    cache.writeQuery<ListRostersResponse>({
      query: LIST_ROSTERS,
      data: {
        getJobRosters: rosters,
      },
      variables: {
        jobId,
      },
    });
  }

  cache.writeQuery<GetRosterResponse>({
    query: GET_ROSTER,
    data: {
      getJobRosterById: createdRoster,
    },
    variables: {
      jobId,
      rosterid: createdRoster._id,
    },
  });

  createdRoster.items?.forEach((item) => {
    if (!item) return;
    const rosterItem = {
      ...item,
      roster: createdRoster,
    } as RosterModalListItem;
    const costingFragment = findCostingItemFromCache(cache, item.costingItemId);
    if (costingFragment) {
      updateCostingItemCache(cache, {
        ...costingFragment,
        rosterItems: (costingFragment.rosterItems || []).concat(rosterItem),
      });
    }
  });
};

export const handleRosterDelete = (
  jobId?: string
): MutationUpdaterFn<DeleteRosterResponse> => (cache, response) => {
  const deletedRoster = response.data?.jobDeleteRoster;

  const rosterListResponse = cache.readQuery<ListRostersResponse>({
    query: LIST_ROSTERS,
    variables: {
      jobId,
    },
  });

  if (!deletedRoster || !rosterListResponse) {
    return;
  }

  const rosters = filter(
    rosterListResponse.getJobRosters,
    (r) => r._id !== deletedRoster._id
  );

  cache.writeQuery<ListRostersResponse>({
    query: LIST_ROSTERS,
    data: {
      getJobRosters: rosters,
    },
    variables: {
      jobId,
    },
  });

  cache.writeQuery<GetRosterResponse>({
    query: GET_ROSTER,
    data: {
      getJobRosterById: null,
    },
    variables: {
      jobId,
      rosterId: deletedRoster._id,
    },
  });
};
