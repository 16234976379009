import { gql } from "@apollo/client";

export const LIST_BUILDINGS_TEMPLATES = gql`
  query ListBuildingTemplates {
    listBuildingTemplates {
      _id
      type
      name
      createdAt
      updatedAt
    }
  }
`;
