import React, { forwardRef, useImperativeHandle } from "react";
import { ConnectDragSource, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { findIndex } from "lodash";
import { findItem, removeNode } from "./utils";
import Tree from "./Tree";
import { SortableItem } from "./types";

type SortableListProps = {
  items: SortableItem[];
  renderItem: (item: any, dragRef: ConnectDragSource) => React.ReactElement;
  onDrop?: () => void;
};

export type SortableListRef = {
  getOrder: () => SortableItem[];
};
const SortableList: React.FC<SortableListProps> = (props, ref) => {
  const { items, renderItem, onDrop } = props;
  const [tree, setTree] = React.useState<SortableItem[]>([]);

  useImperativeHandle(ref, () => ({
    getOrder: () => tree,
  }));

  React.useEffect(() => {
    setTree(items);
  }, [items]);

  const moveItem = React.useCallback(
    (id: string, afterId?: string, parentId?: string) => {
      if (id === afterId) return;
      const newTree = [...tree];
      const item = { ...findItem(id, newTree) };
      if (!item.id) {
        return;
      }

      const destination = parentId
        ? findItem(parentId, newTree).items
        : newTree;

      if (!afterId) {
        removeNode(id, newTree);
        destination.push(item);
      } else {
        const index = findIndex(destination, { id: afterId });
        removeNode(id, newTree);
        destination.splice(index, 0, item);
      }

      setTree(newTree);
    },
    [tree]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Tree
        items={tree}
        moveItem={moveItem}
        renderItem={renderItem}
        onDrop={onDrop}
      />
    </DndProvider>
  );
};
export default forwardRef(SortableList);
