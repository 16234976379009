import React from "react";
import { findIndex, isArray, reduce } from "lodash";
import { TFunction } from "react-i18next";
import { JobCostingTableItem } from "..";
import PurchaseOrderForm, {
  PurchaseOrderFormRef,
} from "../../../../components/order/order-modal/purchaser-form";
import {
  TableCardAction,
  TableRowActionData,
} from "../../../../components/dashboard/table-card/utils";
import { ModalDisplayRef } from "../../../../hooks/useModalDisplay";
import { JobCostingItem } from "../../../../models/job";
import { getPurchaseOrderItems, toggleCostingItem } from "../utils";
import ListPurchaseOrdersModal, {
  ListPurchaseOrdersModalRef,
} from "../../../../components/job-purchase-order/list-purchase-orders-modal";
import { CreateUpdateOrderResponse } from "../../../../graphql/types/models/order";
import { useSendOrder } from "./useSendOrder";
import { useGetTakeOffs } from "../../../../hooks/useGetTakeOffs";
import Dropdown from "../../../../components/dashboard/dropdown";
import { PurchaseOrderStatus } from "../../../../models/purchaseOrder";

type UseOrderCreateProps = {
  t: TFunction;
  resetAllSelections: () => void;
  jobId?: string;
  salesQuoteId?: string;
  isSalesQuoteHasFiles?: boolean;
};

export function useOrderCreate(props: UseOrderCreateProps) {
  const {
    t,
    resetAllSelections,
    jobId,
    salesQuoteId,
    isSalesQuoteHasFiles,
  } = props;
  const modalRef = React.useRef<PurchaseOrderFormRef>(null);
  const listPurchaseOrderRef = React.useRef<ListPurchaseOrdersModalRef>(null);
  const [orderItems, setOrderItems] = React.useState<JobCostingTableItem[]>([]);

  const { renderSendOrderModal, showSendOrder } = useSendOrder();
  const { getTakeOffItems, takeoffs } = useGetTakeOffs();

  const [selectedCostingItems, setSelectedCostingItems] = React.useState<
    JobCostingItem[]
  >([]);

  const resetOrderItems = React.useCallback(() => {
    setOrderItems([]);
  }, []);

  const toggleOrderItem = React.useCallback(
    (row: JobCostingTableItem | JobCostingTableItem[]) => {
      if (isArray(row)) {
        const items = reduce(
          row,
          (result, item) => toggleCostingItem(result, item, true),
          orderItems
        );
        setOrderItems(items);
      } else {
        setOrderItems(toggleCostingItem(orderItems, row));
      }
    },
    [orderItems]
  );

  const isItemSelectedForOrder = React.useCallback(
    (id: string) => {
      return findIndex(orderItems, { _id: id }) >= 0;
    },
    [orderItems]
  );

  const options = React.useMemo(
    () => [
      {
        id: t("orders.createOrder"),
        label: t("orders.createOrder"),
        onClick: () => {
          if (orderItems) {
            if (salesQuoteId) {
              getTakeOffItems({
                variables: {
                  salesQuoteId,
                },
              });
            }

            setSelectedCostingItems(orderItems);
            modalRef.current?.show(true);
          }
        },
      },
      {
        id: t("orders.addExpenses"),
        label: t("orders.addExpenses"),
        onClick: () => {
          if (orderItems) {
            if (salesQuoteId) {
              getTakeOffItems({
                variables: {
                  salesQuoteId,
                },
              });
            }

            setSelectedCostingItems(orderItems);
            modalRef.current?.show(
              true,
              undefined,
              PurchaseOrderStatus.RECEIVED
            );
          }
        },
      },
    ],
    [getTakeOffItems, orderItems, salesQuoteId, t]
  );

  const orderTableAction = React.useMemo<TableCardAction | null>(
    () => ({
      title: "",
      onClick: () => {},
      isComponent: true,
      component: (
        <Dropdown
          label={t(
            orderItems.length
              ? "costing.orderItemsNumber"
              : "costing.orderItems",
            { number: orderItems.length }
          )}
          icon="receipt"
          id={t("costing.orderItems")}
          items={options}
          isDisabled={!orderItems.length}
          iconFirst
        />
      ),
    }),
    [t, orderItems, options]
  );

  const orderRowAction = React.useMemo<TableRowActionData<JobCostingTableItem>>(
    () => ({
      svgIcon: "Bill",
      counter: (row: JobCostingTableItem) => getPurchaseOrderItems(row).length,
      title: (row: JobCostingTableItem) =>
        t("costing.orderCreatedWithCount", {
          count: getPurchaseOrderItems(row).length,
        }),
      dropdownId: "order-item",
      onClick: (row: JobCostingTableItem) => {
        const data = getPurchaseOrderItems(row);
        listPurchaseOrderRef.current?.setData(data, row?._id);
        listPurchaseOrderRef.current?.show(true);
      },
      shouldRender: (row: JobCostingTableItem) =>
        getPurchaseOrderItems(row).length > 0,
    }),
    [jobId, orderItems, selectedCostingItems]
  );

  const handleOrderCreated = React.useCallback(
    (values: CreateUpdateOrderResponse) => {
      modalRef.current?.show(false);
      const status = values.jobCreateUpdatePurchaseOrder.status;
      if (
        status === PurchaseOrderStatus.RECEIVED ||
        status === PurchaseOrderStatus.PARTIALLY_RECEIVED
      ) {
        return resetAllSelections();
      }
      showSendOrder(values.jobCreateUpdatePurchaseOrder);
      resetAllSelections();
    },
    [modalRef, showSendOrder]
  );

  const renderOrderModal = React.useCallback(() => {
    return (
      <>
        <PurchaseOrderForm
          ref={modalRef}
          jobId={jobId}
          onSubmit={handleOrderCreated}
          costingItems={selectedCostingItems}
          takeoffs={isSalesQuoteHasFiles ? takeoffs : null}
          salesQuoteId={isSalesQuoteHasFiles ? salesQuoteId : null}
          includeComputedQuantity={true}
        />
        {renderSendOrderModal()}
        <ListPurchaseOrdersModal ref={listPurchaseOrderRef} />
      </>
    );
  }, [
    selectedCostingItems,
    renderSendOrderModal,
    handleOrderCreated,
    isSalesQuoteHasFiles,
    takeoffs,
    salesQuoteId,
  ]);

  return {
    renderOrderModal,
    orderTableAction,
    orderRowAction,
    toggleOrderItem,
    resetOrderItems,
    isItemSelectedForOrder,
  };
}
