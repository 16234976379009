import React from "react";
import { chain, map } from "lodash";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCard from "../../dashboard/card";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import CardPlaceholder from "../../dashboard/card-placeholder";
import { ContactDetail } from "../../../graphql/types/models/client";
import { getFullName } from "../../../utils/text";
import { JobContact } from "../../../models/job-contact";
import "./styles.scss";

type ContactDetailsProps = {
  disabled: boolean;
  primary?: ContactDetail | null;
  jobContacts?: JobContact[];
  onContactActionButton: (contactId: string, accessId?: string) => void;
};

const ContactDetails: React.FC<ContactDetailsProps> = (props) => {
  const { t } = useTranslation();

  const { primary, jobContacts, disabled, onContactActionButton } = props;

  const secondaryContacts = React.useMemo(() => {
    if (jobContacts?.length) {
      return map(
        jobContacts,
        (jobContact) => jobContact.contact as ContactDetail
      );
    }
    return [];
  }, [jobContacts]);

  const getClientAccessId = React.useCallback(
    (id?: string) => {
      if (!id) return;
      const contact = jobContacts?.find(
        (contact) => contact.contact?._id === id
      );
      if (contact) {
        return contact?.clientAccess?._id;
      }
    },
    [jobContacts]
  );
  const handleActionContactButton = React.useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement>,
      contactId: string,
      accessId?: string
    ) => {
      e.preventDefault();
      onContactActionButton(contactId, accessId);
    },
    [onContactActionButton]
  );

  const renderContacts = React.useCallback(() => {
    return chain([primary])
      .concat(secondaryContacts)
      .uniqBy("_id")
      .map((contact, index) => {
        const fullName = getFullName(contact);
        const clientAccessId = getClientAccessId(contact?._id);
        return (
          <Link
            className="job-contact"
            to={`/contacts/clients/${contact?._id}`}
          >
            {index === 0 && <Badge variant="info">{t("common.primary")}</Badge>}
            <Badge variant={clientAccessId ? "success" : "secondary"}>
              {t(clientAccessId ? "jobs.portalAccess" : "jobs.noPortalAccess")}
            </Badge>
            <div className="field-text mb-3">{fullName}</div>
            <div className="d-flex justify-content-between">
              <div>
                <div className="field-text">{contact?.email}</div>
                <div className="field-text">{contact?.phone}</div>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  className="button info invite-button"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    handleActionContactButton(
                      e,
                      contact?._id as string,
                      clientAccessId
                    )
                  }
                >
                  {clientAccessId
                    ? t("clientAccess.revoke")
                    : t("clientAccess.invite")}
                </Button>
              </div>
            </div>
          </Link>
        );
      })
      .value();
  }, [
    getClientAccessId,
    handleActionContactButton,
    primary,
    secondaryContacts,
    t,
  ]);

  if (disabled) {
    return <CardPlaceholder />;
  }

  return (
    <DashboardCard>
      <DashboardCardHeader>
        {t("jobOverview.customerDetails")}
      </DashboardCardHeader>
      <DashboardCardBody>{renderContacts()}</DashboardCardBody>
    </DashboardCard>
  );
};

export default ContactDetails;
