import React from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { WithT } from "i18next";
import { FormikProps } from "formik";

export type ResetPasswordFormBody = {
  password: string;
  confirmPassword: string;
};

type ResetPasswordFormBodyProps = WithT &
  Pick<
    FormikProps<ResetPasswordFormBody>,
    "values" | "errors" | "touched" | "handleChange" | "handleBlur"
  >;

const ResetPasswordFormBody: React.FC<ResetPasswordFormBodyProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation();

  return (
    <Container className="m-0 p-0 mw-100">
      <Row>
        <Col lg={6} md={12}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="form-input-label">
              {t("authentication.password")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              isInvalid={touched.password && !!errors.password}
              value={values.password}
              className="form-input"
              type="password"
              placeholder={t("placeholders.password")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group controlId="formBasicPassword">
            <Form.Label className="form-input-label">
              {t("authentication.confirmPassword")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="confirmPassword"
              value={values.confirmPassword}
              isInvalid={touched.confirmPassword && !!errors.confirmPassword}
              type="password"
              className="form-input"
              placeholder={t("placeholders.confirmPassword")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordFormBody;
