import { applyMiddleware, compose, createStore } from "redux";
import logger from "redux-logger";
import createRootReducer from "./reducer";
import { routerMiddleware } from "connected-react-router";

const middlewares: any = [];
if (process.env.NODE_ENV === "production") {
  middlewares.push(logger);
}

export function configureStore(history: any, initialState: {}) {
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  middlewares.push(routerMiddleware(history));
  return createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}
