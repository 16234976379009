import { gql } from "@apollo/client";

export const PROFILE = gql`
  query {
    profile {
      _id
      first_name
      last_name
      email
      signature {
        html
        text
        raw
      }
      role
      hasOnboarded
      profileImage {
        size
        width
        height
        upload_url
        url
      }
      business {
        _id
        name
        phone_number
        email
      }
      disabledNotifications {
        email
        push
      }
    }
  }
`;
