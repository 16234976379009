import range from "lodash/range";
import pdfjsLib, { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import { PlanFile } from "../../../components/plans/upload-plans/plans-upload-card/PlansTable";

export const loadPDF = async (plan: PlanFile) => {
  if (!plan) {
    return null;
  }

  try {
    return await pdfjsLib.getDocument(new Uint8Array(plan.content)).promise;
  } catch (e) {
    console.log("PDF LOAD ERROR", e);
    return null;
  }
};

export const loadPDFPage = async (
  document: PDFDocumentProxy,
  pageNumber: number
): Promise<PDFPageProxy | null> => {
  try {
    return await document.getPage(pageNumber);
  } catch (e) {
    console.log("Error while loading page", e);
    throw new Error(`Error while loading page`);
  }
};

export const loadPDFPages = async (
  document: PDFDocumentProxy
): Promise<Array<PDFPageProxy | null>> => {
  const pagesRange = range(1, document.numPages + 1);
  const pages = [];

  try {
    for (const pageNumber of pagesRange) {
      try {
        pages.push(await loadPDFPage(document, pageNumber));
      } catch (e) {
        console.group("ERROR WHILE LOADING PDF PAGE");
        console.log("Error", e);
        console.log("Document", document);
        console.log("Page number", pageNumber);
        console.groupEnd();
      }
    }
  } catch (e) {
    console.group("ERROR WHILE LOADING PDF DOCUMENT PAGES");
    console.log("Error", e);
    console.log("Document", document);
    console.groupEnd();
  }

  return pages;
};
