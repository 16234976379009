import React from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { chain, map, get, forEachRight, forEach } from "lodash";
import { FieldArray, FormikProps, ArrayHelpers, Field } from "formik";
import { ConnectDragSource } from "react-dnd";
import classNames from "classnames";
import {
  GenericFormField,
  GenericFormTable,
  GenericFormTableDynamicField,
  GenericFormTableInputControl,
  GenericFormTableToggleControl,
  RenderSecondRowFn,
} from "../GenericFormBody";
import Icon from "../../icons/Icon";
import TableFormControls from "./TableFormControls";
import TableFormCell from "./TableFormCell";

import SortableList, { SortableListRef } from "../../sortable-list";
import { SortableItem } from "../../sortable-list/types";
import { Button } from "react-bootstrap";

type TableFormProps = Omit<FormikProps<any>, "handleSubmit" | "handleReset"> &
  GenericFormTable<any, any> & {
    name: string;
    showCounterTitle?: boolean;
    isDisabled?: boolean;
    isDraggable?: boolean;
    renderSecondRow?: RenderSecondRowFn;
  };
type RowData = {
  items?: GenericFormField<any, any>[];
  postDynamicItems?: GenericFormField<any, any>[];
  secondRowItems?: GenericFormField<any, any>[];
};
enum IndexedCellTypeEnum {
  INPUT,
  DYNAMIC,
}
type IndexedCellType = {
  cellType: IndexedCellTypeEnum;
  secondRow?: boolean;
};
type IndexedInputCell = GenericFormField<any, any> & IndexedCellType;
type IndexedDynamicCell = GenericFormTableDynamicField & IndexedCellType;
type IndexedCell = (IndexedInputCell | IndexedDynamicCell)[];
const TableForm: React.FC<TableFormProps> = (props) => {
  const {
    row,
    name,
    rowGenerator,
    enableRowCopying,
    disableRowDeleting,
    disableRowEditing,
    tableTitle,
    isCompact,
    isIndexed,
    dynamicFields,
    showCounterTitle,
    isDisabled,
    isDraggable,
    isSelectable,
    actionWithSelected,
    isTwoRow,
    toggles,
    rightActionButton,
    inputs,
    deleteTableRow,
    renderSecondRow,
    withoutTitle,
    ...formikProps
  } = props;

  const { t } = useTranslation();
  const listRef = React.useRef<SortableListRef>(null);
  const [displaySecondRow, setDisplaySecondRow] = React.useState(false);

  const { values } = formikProps;

  const checkIsEditable = React.useCallback(
    (rowValues: any) => {
      if (!disableRowEditing) return true;
      return !disableRowEditing(rowValues);
    },
    [disableRowEditing]
  );

  const renderCellHeader = (
    index: number,
    cell: GenericFormField<any, any>,
    isNotTable?: boolean
  ) => {
    const Comp = isNotTable ? "div" : "th";
    return (
      <Comp
        className="field-text"
        key={index}
        style={{ minWidth: cell.width, width: cell.maxWidth || "100%" }}
      >
        {cell.label}
      </Comp>
    );
  };

  const rowData = React.useMemo<RowData>(() => {
    const data: RowData = {
      items: [],
      postDynamicItems: [],
      secondRowItems: [],
    };
    row?.forEach((cell) => {
      if (cell.secondRow) {
        data.secondRowItems?.push(cell);
      } else if (cell.afterDynamicFields) {
        data.postDynamicItems?.push(cell);
      } else {
        data.items?.push(cell);
      }
    });

    return data;
  }, [row, isIndexed]);

  const isEmptyTable = React.useMemo(() => {
    if (rowData && rowData.items) {
      return rowData?.items.length === 0;
    }
  }, [rowData]);

  const indexedRowData = React.useMemo<IndexedCell | null>(() => {
    let data = [] as IndexedCell;
    row?.forEach((cell) => {
      data.push({
        ...cell,
        cellType: IndexedCellTypeEnum.INPUT,
      });
    });
    dynamicFields?.forEach((cell) => {
      data.push({
        ...cell,
        cellType: IndexedCellTypeEnum.DYNAMIC,
      });
    });

    let flow = chain(data).filter((c) => !c.secondRow);
    if (isIndexed) {
      flow = flow.sortBy("index");
    }
    return flow.value();
  }, [row, dynamicFields, isIndexed]);

  const renderDynamicRowCell = React.useCallback(
    (
      rowIndex: string,
      cellIndex: number,
      cell: GenericFormTableDynamicField,
      isNotTable?: boolean
    ) => {
      const Comp = isNotTable ? "div" : "td";
      return (
        <Comp
          key={`dyn${rowIndex}-${cellIndex}`}
          className={classNames("field-text dynamic-field", {
            "dynamic-field-compact": isCompact,
            "dynamic-field-link": !!cell.onClick,
          })}
          style={{ width: cell.maxWidth }}
          onClick={() => cell.onClick?.(values[name][rowIndex], values)}
        >
          {cell.render
            ? cell.render(name, Number(rowIndex), values)
            : cell.formatValue(values[name][rowIndex], values)}
        </Comp>
      );
    },
    [name, values, isCompact]
  );

  const renderCell = React.useCallback(
    (
      rowIndex: string,
      cellIndex: number,
      cell: GenericFormField<any, any>,
      isNotTable?: boolean
    ) => (
      <TableFormCell
        rowIndex={Number(rowIndex)}
        rowKey={`${name}[${rowIndex}]`}
        key={cellIndex}
        cell={cell}
        formikProps={formikProps}
        valueKey={`${name}[${rowIndex}].${cell.valueKey}`}
        isNotTable={isNotTable}
      />
    ),
    [formikProps, name]
  );

  const renderIndexedCells = React.useCallback(
    (rowValues: any, rowIndex: string, isNotTable?: boolean) => {
      return chain(indexedRowData)
        .map((cell, cellIndex) => {
          if (cell.cellType === IndexedCellTypeEnum.INPUT) {
            return renderCell(
              rowIndex,
              cellIndex,
              {
                ...(cell as GenericFormField<any, any>),
                disabled:
                  !checkIsEditable(rowValues) ||
                  (isSelectable && !rowValues.selected && !actionWithSelected),
              },
              isNotTable
            );
          } else {
            return renderDynamicRowCell(
              rowIndex,
              cellIndex,
              cell as GenericFormTableDynamicField,
              isNotTable
            );
          }
        })
        .value();
    },
    [indexedRowData, renderCell, renderDynamicRowCell, actionWithSelected]
  );

  const renderRowCell = React.useCallback(
    (
      rowValues: any,
      rowIndex: string,
      dataKey: keyof RowData,
      isNotTable?: boolean
    ) =>
      chain(rowData[dataKey])
        .map((cell, cellIndex) =>
          renderCell(
            rowIndex,
            cellIndex,
            {
              ...cell,
              disabled:
                !checkIsEditable(rowValues) ||
                (isSelectable && !rowValues.selected && !actionWithSelected),
            },
            isNotTable
          )
        )
        .value(),
    [rowData, renderCell, checkIsEditable, isSelectable, actionWithSelected]
  );

  const handleDeleteRow = React.useCallback(
    (helpers: ArrayHelpers, rowIndex: number) => {
      deleteTableRow?.(formikProps, name, rowIndex);
      helpers.remove(rowIndex);
    },
    [formikProps, deleteTableRow]
  );

  const handleCopyRow = React.useCallback(
    (rowIndex: number) => {
      enableRowCopying?.(formikProps, rowIndex);
    },
    [formikProps, enableRowCopying]
  );

  const renderTableRow = React.useCallback(
    (rowValues: any, rowIndex: string, helpers: ArrayHelpers) => {
      return (
        <React.Fragment key={rowIndex}>
          <tr
            className={classNames("table-row", {
              "table-row-compact": isCompact,
            })}
          >
            <td className="count-cell field-text">{rowIndex + 1}</td>
            {renderIndexedCells(rowValues, rowIndex)}
            {renderRowCell(rowValues, rowIndex, "postDynamicItems")}
            {!isDisabled &&
              !(
                disableRowDeleting &&
                disableRowDeleting(formikProps, Number(rowIndex))
              ) && (
                <td
                  className="field-text cursor-pointer delete-row"
                  onClick={() => handleDeleteRow(helpers, Number(rowIndex))}
                >
                  <Icon name="clear" />
                </td>
              )}
          </tr>
          {renderSecondRow &&
            renderSecondRow(formikProps, name, Number(rowIndex))}
        </React.Fragment>
      );
    },
    [
      values,
      row,
      dynamicFields,
      indexedRowData,
      name,
      isDisabled,
      disableRowDeleting,
      renderCell,
      checkIsEditable,
      isIndexed,
      renderRowCell,
      renderIndexedCells,
      renderSecondRow,
    ]
  );

  const renderRows = React.useCallback(
    (helpers: ArrayHelpers) => {
      return map(get(values, name), (rowValues, rowIndex) =>
        renderTableRow(rowValues, rowIndex, helpers)
      );
    },
    [name, values, renderTableRow]
  );

  const items = React.useMemo((): SortableItem[] => {
    return map(get(values, name), (rowValues, rowIndex) => ({
      id: rowIndex + 1,
      item: {
        rowValues,
        rowIndex,
      },
    }));
  }, [name, values]);

  const renderDraggableRow = React.useCallback(
    (
      rowValues: any,
      rowIndex: string,
      helpers: ArrayHelpers,
      dragRef: ConnectDragSource
    ) => (
      <div key={rowIndex} className="table-row">
        <div
          className={classNames("table-row-draggable", {
            "table-row-compact": isCompact,
          })}
        >
          {isSelectable && (
            <div className="check-cell field-text">
              <Field
                className=""
                type="checkbox"
                name={`${name}[${rowIndex}].selected`}
              />
            </div>
          )}
          <div ref={dragRef} className="count-cell field-text">
            {rowIndex + 1}
          </div>
          {renderIndexedCells(rowValues, rowIndex, true)}
          {renderRowCell(rowValues, rowIndex, "postDynamicItems", true)}
          {!isDisabled && enableRowCopying && (
            <div
              className="field-text cursor-pointer copy-row"
              onClick={() => handleCopyRow?.(Number(rowIndex))}
            >
              <Icon name="content_copy" />
            </div>
          )}
          {!isDisabled &&
          !(
            disableRowDeleting &&
            disableRowDeleting(formikProps, Number(rowIndex))
          ) ? (
            <div
              className="field-text cursor-pointer delete-row"
              onClick={() => handleDeleteRow(helpers, Number(rowIndex))}
            >
              <Icon name="clear" />
            </div>
          ) : (
            <div className="field-text delete-row" />
          )}
        </div>
        {displaySecondRow && isTwoRow && (
          <div
            className={classNames("table-row-draggable", {
              "table-row-compact": isCompact,
            })}
          >
            <div className="count-cell field-text">Note:</div>
            {renderRowCell(rowValues, rowIndex, "secondRowItems", true)}
          </div>
        )}
      </div>
    ),
    [
      isDisabled,
      values,
      dynamicFields,
      name,
      rowData,
      disableRowDeleting,
      renderCell,
      checkIsEditable,
      isTwoRow,
      isSelectable,
      displaySecondRow,
      renderIndexedCells,
    ]
  );

  const renderItem = React.useCallback(
    (helpers: ArrayHelpers) => (
      data: SortableItem,
      dragRef: ConnectDragSource
    ) => {
      const { item } = data;
      return renderDraggableRow(
        item.rowValues,
        item.rowIndex,
        helpers,
        dragRef
      );
    },
    [renderDraggableRow]
  );

  const renderIndexedHeaderCells = React.useCallback(
    (isNotTable?: boolean) => {
      const Comp = isNotTable ? "div" : "th";
      return chain(indexedRowData)
        .map((cell, cellIndex) => {
          if (cell.cellType === IndexedCellTypeEnum.INPUT) {
            return renderCellHeader(
              cellIndex,
              cell as GenericFormField<any, any>,
              isNotTable
            );
          } else {
            return (
              <Comp
                className="field-text dynamic-field"
                key={cellIndex}
                style={{ width: cell.maxWidth || "100%" }}
              >
                {(cell as GenericFormTableDynamicField).title}
              </Comp>
            );
          }
        })
        .value();
    },
    [indexedRowData]
  );

  const renderDraggableHeader = React.useCallback(
    () => (
      <div
        className={classNames(
          "table-row-draggable-header table-row-draggable table-header table-header-nowrap",
          { "table-row-compact": isCompact }
        )}
      >
        {isSelectable && <div className="count-cell field-text" />}
        <div className="count-cell field-text">
          {showCounterTitle && t("common.number")}
        </div>
        {renderIndexedHeaderCells(true)}
        {chain(rowData.postDynamicItems)
          .map((cell, index) => renderCellHeader(index, cell, true))
          .value()}
        {!isDisabled && <div className="field-text delete-row" />}
      </div>
    ),
    [rowData, renderDraggableRow, isCompact, isSelectable]
  );

  const onDrop = React.useCallback(
    (helpers: ArrayHelpers) => () => {
      const currentList = listRef.current?.getOrder();
      const oldList = items;

      forEachRight(oldList, (_item, index) => {
        helpers.remove(index);
      });

      forEach(currentList, (data) => {
        helpers.push(data.item.rowValues);
      });
    },
    [items]
  );

  const handleToggleChange = React.useCallback(
    (toggle: GenericFormTableToggleControl) => (value: boolean) => {
      formikProps.setFieldValue(toggle.name, value);
      toggle.onChange?.(value, formikProps);
    },
    [toggles, formikProps]
  );

  const hasSelectedItems = React.useMemo(() => {
    if (!actionWithSelected) return false;
    const isSelected = values[actionWithSelected.fieldName]?.filter(
      (item: any) => item.selected === true
    );

    return Boolean(isSelected.length);
  }, [actionWithSelected, values]);

  const handleSumbitSelectedItems = React.useCallback(() => {
    actionWithSelected?.action?.(formikProps);
  }, [actionWithSelected, formikProps]);

  const handleInputChange = React.useCallback(
    (input: GenericFormTableInputControl) => (e: React.ChangeEvent<any>) => {
      formikProps.setFieldValue(input.name, e.target.value);
    },
    [inputs, formikProps]
  );

  const handleInputBlur = React.useCallback(
    (input: GenericFormTableInputControl) => (e: React.ChangeEvent<any>) => {
      input.onChange?.(e.target.value, formikProps);
    },
    [inputs, formikProps]
  );

  const renderFormBody = React.useCallback(
    (helpers: ArrayHelpers) => {
      const handleAddFormRow = () => {
        rowGenerator && helpers.push(rowGenerator());
      };

      return (
        <div className="table-card table-card-responsive">
          <TableFormControls
            tableTitle={tableTitle}
            isTwoRow={isTwoRow}
            toggles={toggles}
            rightActionButton={rightActionButton}
            inputs={inputs}
            withoutTitle={withoutTitle}
            formikProps={formikProps}
            onToggleChange={handleToggleChange}
            onInputChange={handleInputChange}
            onInputBlur={handleInputBlur}
            secondRowValue={displaySecondRow}
            onToggleSecondRow={(value: boolean) => setDisplaySecondRow(value)}
          />
          {!isDraggable && !isEmptyTable && (
            <Table className="table table-form" hover responsive>
              <thead className="table-header table-header-nowrap">
                <tr>
                  <th className="count-cell field-text">
                    {showCounterTitle && t("common.number")}
                  </th>
                  {renderIndexedHeaderCells()}
                  {map(rowData.postDynamicItems, (cell, index) =>
                    renderCellHeader(index, cell)
                  )}
                  {!isDisabled && <th className="field-text delete-row" />}
                </tr>
              </thead>
              <tbody className="table-body">{renderRows(helpers)}</tbody>
            </Table>
          )}
          {isDraggable && (
            <>
              {renderDraggableHeader()}
              <SortableList
                ref={listRef}
                onDrop={onDrop(helpers)}
                items={items}
                renderItem={renderItem(helpers)}
              />
            </>
          )}
          {!isDisabled && rowGenerator && (
            <div className="d-flex align-items-center justify-content-between flex-row-reverse mt-4">
              <Button
                className="button large info btn btn-primary"
                onClick={handleAddFormRow}
              >
                <Icon name="add" /> {t("common.addItem")}
              </Button>
              {actionWithSelected && hasSelectedItems && (
                <Button
                  className="button large info btn btn-primary selected-action-button"
                  onClick={handleSumbitSelectedItems}
                >
                  {actionWithSelected.buttonTitle}
                </Button>
              )}
            </div>
          )}
        </div>
      );
    },
    [
      isDisabled,
      isDraggable,
      showCounterTitle,
      tableTitle,
      dynamicFields,
      toggles,
      handleToggleChange,
      row,
      t,
      items,
      actionWithSelected,
      renderRows,
      onDrop,
      renderItem,
      rowGenerator,
      withoutTitle,
      hasSelectedItems,
      rightActionButton,
    ]
  );

  return <FieldArray name={name}>{renderFormBody}</FieldArray>;
};

export default TableForm;
