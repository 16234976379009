import React, { forwardRef } from "react";
import { TeamMember } from "../../../models/team-member";
import { ContactNameListItem } from "../../../models/client";
import HeaderPageAction from "../../dashboard/header/HeaderPageAction";
import TeammateHeaderDropdown from "./TeammateHeaderDropdown";
import TeammateHeaderTop from "./TeammateHeaderTop";
import classNames from "classnames";
import CreateTeammatModal, {
  CreateTeammatModalRef,
} from "../../../containers/modals/create-teammate";

import "./styles.scss";

type TeammateHeaderProps = {
  loading?: boolean;
  jobMembers?: TeamMember[];
  contacts?: ContactNameListItem[];
  showTeammatesList: boolean;
  toggleTeammateList: () => void;
  addTeammate: (memberId: string) => void;
  removeTeammate: (memberId: string) => void;
  isReadonly?: boolean;
};

const TeammateHeader: React.FC<TeammateHeaderProps> = (props, ref) => {
  const {
    loading = false,
    jobMembers,
    contacts,
    showTeammatesList,
    toggleTeammateList,
    addTeammate,
    removeTeammate,
    isReadonly = false,
  } = props;

  const createTeammateRef = React.useRef<CreateTeammatModalRef>(null);

  const handleOpenCreateModal = React.useCallback(
    () => createTeammateRef.current?.show(true),
    [createTeammateRef]
  );

  const renderClientsDropdown = () => {
    if (!showTeammatesList) {
      return null;
    }

    return (
      <TeammateHeaderDropdown
        loading={loading}
        contacts={contacts}
        jobTeammates={jobMembers}
        addTeammate={addTeammate}
        removeTeammate={removeTeammate}
        openCreateModal={handleOpenCreateModal}
        isReadonly={isReadonly}
      />
    );
  };

  const containerClasses = React.useMemo(
    () =>
      classNames("teammate-header-container", {
        "teammate-header-container__open": showTeammatesList,
      }),
    [showTeammatesList]
  );

  return (
    <>
      <CreateTeammatModal ref={createTeammateRef} />
      <HeaderPageAction order="1">
        <div className={containerClasses} ref={ref}>
          <TeammateHeaderTop
            teammates={jobMembers}
            onAddClick={toggleTeammateList}
            isReadonly={isReadonly}
          />
          {renderClientsDropdown()}
        </div>
      </HeaderPageAction>
    </>
  );
};

export default forwardRef(TeammateHeader);
