import { TFunction } from "i18next";
import { compact, map, reduce } from "lodash";
import {
  GenericFormFields,
  UpdateRowFn,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { ContactListItem } from "../../../graphql/types/models/client";
import {
  ClaimCostItemPayload,
  ClaimPayload,
  ClaimStatusesEnum,
} from "../../../models/claim";
import {
  JobCostingItem,
  JobDetails,
  JobInvoiceType,
  JobType,
} from "../../../models/job";
import { PDF_TOOLBAR_BASIC } from "../../generic-form/inputs/rich-editor/configs";
import {
  GST_PERCENT,
  calcGST,
  calcTotal,
  calcTotalMarkup,
} from "../../../utils/calculations";

export const calculateAmountToDate = (item: JobCostingItem) => {
  const total = (item.markup_cost || item.cost) * item.quantity;
  return reduce(
    item.progressClaimItems,
    (total, claim) => {
      return {
        ...total,
        amountToDate: total.amountToDate + claim.amount,
        percentageToDate: total.percentageToDate + claim.percentage,
        remaining: Math.max(0, total.remaining - claim.amount),
      };
    },
    {
      amountToDate: 0,
      percentageToDate: 0,
      total: total,
      remaining: total,
    }
  );
};

export const calculateEditingAmountToDate = (item: ClaimCostItemPayload) => {
  const total = item.total;
  const amountToDate = Number(item.amountToDate) - Number(item.amount);
  return {
    total,
    amountToDate,
    percentageToDate: (amountToDate / total) * 100,
    remaining: Number(item.remaining) + Number(item.amount),
  };
};

export const calculateCostPlusTotal = (items: ClaimCostItemPayload[]) => {
  return reduce(
    items,
    (totals, item) => {
      const itemGST = Number(item.GST) || 0;
      const itemMarkup = Number(item.markup) || 0;
      const markupAmount = calcTotalMarkup(
        Number(item.amount || 0),
        itemMarkup
      );
      const subTotal = Number(item.amount) + markupAmount;
      const GST = itemGST + calcGST(markupAmount, GST_PERCENT);
      const total = calcTotal(subTotal, GST);
      return {
        GST: totals.GST + GST,
        subTotal: totals.subTotal + subTotal,
        total: totals.total + total,
      };
    },
    {
      subTotal: 0,
      GST: 0,
      total: 0,
    }
  );
};

export const createClaimFields = (
  t: TFunction,
  job: JobDetails,
  clients: ContactListItem[],
  isDistributeAmount: boolean,
  isRetention: boolean,
  onDistributeAmountChange: (value: boolean) => void,
  onAmountChange: UpdateTextInputFn,
  onPercentChange: UpdateTextInputFn,
  onBulkPercentageChange: UpdateTextInputFn,
  onRowChange: (valueKey: string) => UpdateRowFn,
  onClaimToDateClick?: (values: ClaimCostItemPayload) => void,
  onAddItem?: () => JSX.Element,
  isNewClaim?: boolean
): GenericFormFields<ClaimPayload> => {
  let fields: GenericFormFields<ClaimPayload> = {
    description: {
      type: "text",
      label: t("claims.description"),
      placeholder: t("placeholders.description"),
      valueKey: "description",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    contact: {
      type: "select",
      label: t("claims.contact"),
      placeholder: t("placeholders.contact"),
      valueKey: "contact",
      autocompleteProps: {
        options: map(clients, (client) => ({
          value: client._id,
          label: `${client.first_name} ${client.last_name}`,
        })),
      },
      inputProps: {
        required: true,
      },
    },

    status: {
      type: "select",
      label: t("claims.status"),
      placeholder: t("placeholders.status"),
      valueKey: "status",
      autocompleteProps: {
        options: compact([
          {
            label: t(`claims.statuses.${ClaimStatusesEnum.DRAFT}`),
            value: ClaimStatusesEnum.DRAFT,
          },
          // !isNewClaim && {
          //   label: t(`claims.statuses.${ClaimStatusesEnum.RECEIVED}`),
          //   value: ClaimStatusesEnum.RECEIVED,
          // },
          {
            label: t(`claims.statuses.${ClaimStatusesEnum.SENT}`),
            value: ClaimStatusesEnum.SENT,
          },
        ]),
      },
      inputProps: {
        required: true,
      },
    },
    claimDate: {
      type: "date-picker",
      label: t("claims.invoiceDate"),
      placeholder: t("placeholders.date"),
      valueKey: "claimDate",
      inputProps: {
        required: true,
      },
    },
    dueDate: {
      type: "date-picker",
      label: t("claims.dueDate"),
      placeholder: t("placeholders.date"),
      valueKey: "dueDate",
      inputProps: {
        required: true,
      },
    },
    amount: {
      type: "text",
      label: t("claims.fixedAmount"),
      placeholder: t("placeholders.fixedAmount"),
      valueKey: "amount",
      showError: true,
      disallowNegativeNumber: true,
      textInputProps: {
        onValueChange: onAmountChange,
      },
      inputProps: {
        min: 0,
        type: "number",
        required: true,
      },
    },

    percentage: {
      type: "text",
      label: isRetention
        ? t("claims.proportionRetained")
        : t("claims.proportion"),
      placeholder: isRetention
        ? t("claims.proportionRetained")
        : t("placeholders.proportion"),
      valueKey: "percentage",
      showError: true,
      disallowNegativeNumber: true,
      textInputProps: {
        onValueChange: onPercentChange,
      },
      inputProps: {
        min: 0,
        type: "number",
        required: true,
      },
    },

    distributeAmount: {
      type: "toggle",
      valueKey: "distributeAmount",
      toggleProps: {
        label: t("claims.distributeAmountCostingItems"),
        onChange: onDistributeAmountChange,
      },
    },

    hideItems: {
      type: "toggle",
      valueKey: "hideItems",
      toggleProps: {
        label: t("claims.hideItemsPrint"),
        readOnly: !isDistributeAmount,
      },
    },
    comment: {
      type: "rich-editor",
      label: t("claims.comments"),
      valueKey: "richComment.html",
      richEditorProps: {
        className: "rdw-editor-resize rdw-editor-m150",
        textKey: "richComment.text",
        rawKey: "richComment.raw",
        toolbarOptions: PDF_TOOLBAR_BASIC,
      },
    },
  };
  if (job.type === JobType.COST_PLUS) {
    fields.items = {
      tableTitle: t("claims.claimableItems"),
      isCompact: true,
      isDraggable: true,
      isIndexed: true,
      isSelectable: true,
      isTwoRow: true,
      disableRowDeleting: () => true,
      row: [
        {
          index: 4,
          width: "150px",
          maxWidth: "150px",
          type: "text",
          label: t("claims.costPlusPercent"),
          placeholder: t("placeholders.marginValue"),
          valueKey: "markup",
          inputProps: {
            type: "number",
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          type: "text",
          controlType: "textarea",
          label: t("costing.note"),
          placeholder: "",
          valueKey: "note",
          secondRow: true,
          inputProps: {
            className: "form-input-compact",
            type: "text",
            rows: 2,
            debounceChangeEvent: true,
          },
        },
      ],
      dynamicFields: [
        {
          index: 0,
          title: t("costing.item"),
          maxWidth: "100%",
          formatValue: (values: ClaimCostItemPayload) => values?.name,
        },
        {
          index: 1,
          title: t("common.supplier"),
          maxWidth: "270px",
          formatValue: (values: ClaimCostItemPayload) => values?.supplier || "",
        },
        {
          index: 2,
          title: t("costing.realCost"),
          maxWidth: "200px",
          formatValue: (values: ClaimCostItemPayload) => {
            return t("common.currency", { amount: values?.total });
          },
        },
        {
          index: 3,
          title: t("costing.gst"),
          maxWidth: "180px",
          formatValue: (values: ClaimCostItemPayload) => {
            return t("common.currency", { amount: values?.GST });
          },
        },
        {
          index: 5,
          title: t("claims.costPlusAmount"),
          maxWidth: "180px",
          formatValue: (values: ClaimCostItemPayload) => {
            return t("common.currency", {
              amount: calcTotalMarkup(
                Number(values?.amount || 0),
                Number(values?.markup || 0)
              ),
            });
          },
        },
      ],
    };
  } else {
    fields.items = {
      tableTitle: t("costing.costingItems"),
      rightActionButton: onAddItem,
      isCompact: true,
      isDraggable: true,
      isIndexed: true,
      isTwoRow: true,
      disableRowDeleting: () => !isRetention,
      toggles: [
        {
          name: "defaultCategoriesView",
          label: t("claims.printItemCategories"),
        },
      ],
      inputs: [
        {
          name: "bulkPercentage",
          label: t("claims.claimPercent"),
          placeholder: t("placeholders.marginValue"),
          type: "number",
          onChange: onBulkPercentageChange,
        },
      ],
      row: [
        {
          index: 3,
          width: "150px",
          maxWidth: "150px",
          type: "text",
          label: t("claims.claimAmount"),
          placeholder: t("placeholders.costValue"),
          valueKey: "amount",
          updateTableRow: onRowChange("amount"),
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          index: 4,
          width: "150px",
          maxWidth: "150px",
          type: "text",
          label: t("claims.claimPercent"),
          placeholder: t("placeholders.marginValue"),
          valueKey: "percentage",
          updateTableRow: onRowChange("percentage"),
          inputProps: {
            type: "number",
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          type: "text",
          controlType: "textarea",
          label: t("costing.note"),
          placeholder: "",
          valueKey: "note",
          secondRow: true,
          inputProps: {
            className: "form-input-compact",
            type: "text",
            rows: 2,
            debounceChangeEvent: true,
          },
        },
      ],
      dynamicFields: [
        {
          index: 0,
          title: t("costing.item"),
          maxWidth: "350px",
          formatValue: (values: ClaimCostItemPayload) => values?.name,
        },
        {
          index: 1,
          title: t("costing.totalEx"),
          maxWidth: "180px",
          formatValue: (values: ClaimCostItemPayload) => {
            return t("common.currency", { amount: values?.total });
          },
        },
        {
          index: 2,
          title: t("claims.claimedToDate"),
          maxWidth: "180px",
          formatValue: (values: ClaimCostItemPayload) => {
            return values?.amountToDate
              ? t("claims.claimedAmountPercent", {
                  amount: values?.amountToDate,
                  percentage: values?.percentageToDate,
                })
              : t("common.currency", { amount: values?.amountToDate });
          },
          onClick: onClaimToDateClick,
        },
        {
          index: 2,
          title: t("claims.remaining"),
          maxWidth: "180px",
          formatValue: (values: ClaimCostItemPayload) => {
            return t("common.currency", { amount: values?.remaining });
          },
        },
      ],
    };
  }
  if (isRetention) {
    delete fields.distributeAmount;
    delete fields.hideItems;
  }
  return fields;
};
