import React from "react";
import moment from "moment";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { ClientSalesQuote } from "../../../../models/salesQuote";
import { ClientQuoteListResponse } from "../../../../graphql/types/models/quote";
import { CLIENT_LIST_SALES_QUOTES } from "../../../../graphql/queries/client/quotes/queries";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../../components/dashboard/table-card/utils";
import { Col, Container, Row } from "react-bootstrap";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import TableCard from "../../../../components/dashboard/table-card";
import EmptyPlaceholder from "../../../../components/empty-placeholder";
import "./styles.scss";

const ClientsQuoteList: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: quotesData, loading: listClientQuotesLoading } = useQuery<
    ClientQuoteListResponse
  >(CLIENT_LIST_SALES_QUOTES);

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<ClientSalesQuote>) => {
      history.push(`/quotes/${row.cells._id}/selections`);
    },
    [history]
  );

  const quotesTableData = React.useMemo<TableCardData<ClientSalesQuote>>(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("client.quotes.estimation"),
          sortable: true,
          tooltip: (row) => row.name,
        },
        {
          valueKey: "businessName",
          title: t("client.quotes.businessName"),
          className: "cell-truncate",
          sortable: true,
        },

        {
          valueKey: "updatedAt",
          title: t("client.quotes.lastUpdated"),
          sortable: true,
          formatValue: (row: any, column: any, value: Date) =>
            moment(value).format("Do MMM YYYY"),
        },
      ],
      rows: map(quotesData?.listClientSalesQuotes, (quote) => ({
        cells: {
          ...quote,
          businessName: quote?.business?.name,
        },
      })),
    };
  }, [t, quotesData]);

  return (
    <Container fluid className="h-100">
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES} />

      <Row className="h-100">
        <Col xs={12} className="pb-sm-5 pb-lg-0">
          {quotesData?.listClientSalesQuotes &&
          quotesData?.listClientSalesQuotes.length > 0 ? (
            <TableCard
              isDataLoading={listClientQuotesLoading}
              alignEnd
              data={quotesTableData}
              onRowClick={handleRowClick}
            />
          ) : (
            <EmptyPlaceholder
              message={t("client.quotes.emptyQuoteListPlaceholder")}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ClientsQuoteList;
