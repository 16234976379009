import * as yup from "yup";
import { TFunction } from "i18next";

import { find, trim } from "lodash";
import { ValidationMessagesUtils } from "../../../../utils/yup";
import { SalesQuoteTakeOffTemplate } from "../../../../models/salesQuote";

export default function saveTakeOffAsTemplateSchema(
  t: TFunction,
  takeOffTemplates: SalesQuoteTakeOffTemplate[]
) {
  return yup.object().shape({
    name: yup
      .string()
      .test(
        "duplicateNameTest",
        ({ value }) => t("takeOffSection.templateNameExists", { name: value }),
        function (value: string) {
          if (!value) return true;
          const name = trim(value);
          return !find(takeOffTemplates, { name });
        }
      )
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "takeOffSection.templateName"
        )
      ),
  });
}
