import React from "react";
import classNames from "classnames";

export type DashboardCardHeaderProps = {
  className?: string;
};

const DashboardCardHeader: React.FC<DashboardCardHeaderProps> = ({
  children,
  className,
}) => {
  return <div className={classNames("header", className)}>{children}</div>;
};

export default DashboardCardHeader;
