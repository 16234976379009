import { gql } from "@apollo/client";
import { TEAM_MEMBER } from "./fragments";

export const LIST_MEMBERS = gql`
  query ListMembers {
    listMembers {
      ...TeamMember
    }
  }
  ${TEAM_MEMBER}
`;

export const GET_MEMBER = gql`
  query GetMember($userId: ID!) {
    getMember(userId: $userId) {
      ...TeamMember
    }
  }
  ${TEAM_MEMBER}
`;
