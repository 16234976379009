import DashboardCard from "../card";
import React from "react";

import "./styles.scss";
import { map } from "lodash";
import DashboardCardBody from "../card/DashboardCardBody";
import DashboardCardHeader from "../card/DashboardCardHeader";
import DashboardCardFooter from "../card/DashboardCardFooter";
import Button from "react-bootstrap/Button";
import Icon from "../../icons/Icon";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { TableCardAction, TableCardData } from "../table-card/utils";
import CardTable from "../table-card/CardTable";

export type TabsTableCardData = {
  id: string;
  title: string;
  isDataLoading?: boolean;
  data: TableCardData<any>;
  tableProps?: any;
};

type TabsTableCardProps = {
  defaultTab?: string;
  data: TabsTableCardData[];
  actions?: TableCardAction[];
};

const TabsTableCard: React.FC<TabsTableCardProps> = ({
  data,
  actions,
  defaultTab,
}) => {
  return (
    <Tab.Container defaultActiveKey={defaultTab}>
      <div className="tabs-table-card">
        <DashboardCard>
          <DashboardCardHeader>
            <Nav className="flex-column">
              {map(data, (item) => (
                <Nav.Item key={item.id}>
                  <Nav.Link eventKey={item.id}>{item.title}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </DashboardCardHeader>
          <DashboardCardBody>
            <Tab.Content>
              {map(data, (item) => (
                <Tab.Pane key={item.id} eventKey={item.id}>
                  <CardTable
                    table={item.data}
                    {...item.tableProps}
                    isDataLoading={item.isDataLoading}
                  />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </DashboardCardBody>
          {actions && (
            <DashboardCardFooter>
              {map(actions, (action) => (
                <Button
                  key={action.title}
                  className={action.className}
                  onClick={action.onClick}
                >
                  {action.icon && <Icon name={action.icon} />}
                  {action.title}
                </Button>
              ))}
            </DashboardCardFooter>
          )}
        </DashboardCard>
      </div>
    </Tab.Container>
  );
};

export default TabsTableCard;
