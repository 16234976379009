import { gql } from "@apollo/client";
import { BUSINESS_FRAGMENT } from "./fragments";

export const GET_BUSINESS = gql`
  query {
    getBusiness {
      ...BusinessFragment
    }
  }
  ${BUSINESS_FRAGMENT}
`;
