import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { CreateTaskGroupPayload } from "../../../models/job";
import { EXTRA_COLOURS } from "../../../constants/colours";

export const createTaskGroupFields = (
  t: TFunction,
  isEdit?: boolean
): GenericFormFields<CreateTaskGroupPayload> => {
  const fields = {
    name: {
      type: "text",
      label: t("schedule.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        required: true,
      },
    },
    dates: [
      {
        type: "date-picker",
        label: t("schedule.startDate"),
        placeholder: t("placeholders.startDate"),
        valueKey: "startDate",
        inputProps: {
          required: true,
        },
      },
      {
        type: "date-picker",
        label: t("schedule.endDate"),
        placeholder: t("placeholders.endDate"),
        valueKey: "endDate",
        inputProps: {
          required: true,
        },
      },
    ],
    colour: {
      type: "colour-picker",
      label: t("placeholders.colour"),
      placeholder: t("placeholders.colour"),
      valueKey: "colour",
      colourPickerProps: {
        disableAlpha: true,
        presetColors: EXTRA_COLOURS,
      },
    },
    progress: {
      type: "toggle",
      label: t("schedule.progress"),
      valueKey: "complete",
      toggleProps: {
        label: t("schedule.completeAllItems"),
      },
    },
  } as GenericFormFields<CreateTaskGroupPayload>;
  if (!isEdit) {
    delete fields.progress;
  }
  return fields;
};
