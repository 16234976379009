import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import Avatar from "../../../avatar";
import { Dropdown, DropdownButton } from "react-bootstrap";

type ClientItemProps = {
  fullName: string;
  email: string;
  jobContactId: string;
  onRemove?: (id: string) => void;
  onRevoke?: (id: string, contactId: string) => void;
  accessId?: string;
  onGrant: (id: string) => void;
  contactId: string;
  isReadonly?: boolean;
};

const ClientItem: React.FC<ClientItemProps> = (props) => {
  const {
    fullName,
    jobContactId,
    onRemove,
    email,
    onGrant,
    accessId,
    contactId,
    onRevoke,
    isReadonly = false,
  } = props;

  const { t } = useTranslation();

  const handleRemove = React.useCallback(() => {
    onRemove && onRemove(jobContactId);
  }, [onRemove, jobContactId]);

  const handleGrant = React.useCallback(() => {
    onGrant && onGrant(contactId);
  }, [onGrant, contactId]);

  const handleRevoke = React.useCallback(() => {
    accessId && onRevoke && onRevoke(accessId, contactId);
  }, [onRevoke, accessId, contactId]);

  const actionHandler = accessId ? handleRevoke : handleGrant;

  const accessActionTitle = accessId ? "revoke" : "invite";

  return (
    <div className="client-item">
      <div className="client-avatar">
        <Avatar
          userName={fullName}
          width="35px"
          height="35px"
          rounded
          fallbackColor="dark"
        />
      </div>
      <div className="client-info">
        <div className="field-text user-name text-truncate">{fullName}</div>
        <div className="text-truncate">{email}</div>
      </div>
      {!isReadonly && (
        <DropdownButton
          id={`client-actions-${jobContactId}`}
          size="sm"
          variant="light"
          className="client-actions"
          title=""
        >
          <Dropdown.Header>{t("clientAccess.portalAccess")}</Dropdown.Header>
          <Dropdown.Item eventKey="1" onClick={actionHandler}>
            {t(`clientAccess.${accessActionTitle}`)}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="2" onClick={handleRemove}>
            {t("clientAccess.removeClient")}
          </Dropdown.Item>
        </DropdownButton>
      )}
    </div>
  );
};

export default ClientItem;
