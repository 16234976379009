import { gql } from "@apollo/client";

export const CLIENT_PROFILE = gql`
  query {
    clientProfile {
      _id
      first_name
      last_name
      mobile
      email
    }
  }
`;
