import * as yup from "yup";
import { TFunction } from "i18next";

export default function createSignInFormSchema(t: TFunction) {
  return yup.object().shape({
    email: yup
      .string()
      .email(
        t("authentication.errors.isInvalid", {
          fieldName: t("authentication.emailAddress"),
        })
      )
      .required(
        t("authentication.errors.isRequired", {
          fieldName: t("authentication.emailAddress"),
        })
      ),
    password: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.password"),
      })
    ),
    rememberMe: yup.boolean().required(),
  });
}
