import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useModalDisplay } from "../../../../hooks/useModalDisplay";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import { ImportTakeOffTemplatePayload } from "../../../../models/salesQuote";
import { useTakeOffTemplate } from "../../../../hooks/useTakeOffTemplate";
import CreateEntityModal from "../../../modals/create-entity";
import importTakeoffFromTemplateSchema from "./ImportTakeoffFromTemplate.schema";
import { importTakeOffFromTemplateFields } from "./utils";

type ImportTakeoffFromTemplateModalProps = {
  salesQuoteId?: string;
  refetchTakeOffItems: () => void;
};

const ImportTakeoffFromTemplateModal: React.FC<ImportTakeoffFromTemplateModalProps> = (
  { salesQuoteId, refetchTakeOffItems },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<ImportTakeOffTemplatePayload>
  >({});

  const onSubmitImport = React.useCallback(() => {
    refetchTakeOffItems();
    hide();
  }, []);

  const { takeOffTemplates, importTakeOffFromTemplate } = useTakeOffTemplate({
    onImport: onSubmitImport,
  });

  React.useEffect(() => {
    if (!takeOffTemplates) return;
    setFormFields(importTakeOffFromTemplateFields(t, takeOffTemplates));
  }, [takeOffTemplates, t]);

  const handleSubmit = React.useCallback(
    (values: ImportTakeOffTemplatePayload) => {
      importTakeOffFromTemplate({
        variables: values,
      });
    },
    []
  );

  return (
    <>
      <CreateEntityModal
        validationSchema={importTakeoffFromTemplateSchema(t)}
        className="import-takeOff-template"
        title={t("takeOffSection.importTakeOffTemplate")}
        show={shouldShow}
        data={{
          salesQuoteId: salesQuoteId || "",
          templateId: "",
        }}
        submitText={t("common.import")}
        onSubmit={handleSubmit}
        onClose={hide}
        fields={formFields}
      />
    </>
  );
};

export default forwardRef(ImportTakeoffFromTemplateModal);
