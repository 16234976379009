import React from "react";
import AvatarGroup from "../../avatar-group";
import { Button } from "react-bootstrap";
import { chain } from "lodash";
import { useTranslation } from "react-i18next";
import Avatar from "../../avatar";
import { JobContact } from "../../../models/job-contact";

type ClientHeaderTopProps = {
  onAddClick: () => void;
  clients?: JobContact[];
  isReadonly?: boolean;
};

const ClientHeaderTop: React.FC<ClientHeaderTopProps> = (props) => {
  const { onAddClick, clients, isReadonly = false } = props;
  const { t } = useTranslation();

  const renderAvatars = () => {
    return (
      chain(clients)
        // .take(4)
        .map((jobContact) => {
          const { contact } = jobContact;

          const fullName = contact
            ? `${contact.first_name} ${contact.last_name}`
            : "";

          return (
            <Avatar
              key={jobContact._id}
              outlined
              fallbackColor="light"
              userName={fullName}
              rounded
              width="35px"
              height="35px"
            />
          );
        })
        .value()
    );
  };

  return (
    <>
      <div className="mr-2">
        <AvatarGroup>{renderAvatars()}</AvatarGroup>
      </div>
      {!isReadonly && (
        <Button
          className="add-btn button success rounded-pill"
          onClick={onAddClick}
        >
          {t("common.addClient")}
        </Button>
      )}
    </>
  );
};

export default ClientHeaderTop;
