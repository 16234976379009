import React from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { map } from "lodash";
import Carousel, { CarouselState, Modal, ModalGateway } from "react-images";
import Avatar from "../../avatar";
import { Media, MediaPreview } from "../../../models/media";
import MessageAttachment from "./MessageAttachment";
import { getMessageTime } from "../conversation-item";
import Markdown from "../../markdown";
import LinkPreview from "../link-preview";
import Icon from "../../icons/Icon";
import "./styles.scss";

type MessageProps = {
  _id: string;
  avatar_url?: string;
  full_name: string;
  content: string;
  createdAt: string;
  attachments?: Media[];
  linkPreview?: MediaPreview;
  noAvatar?: boolean;
  isOwnMessage?: boolean;
  onDeleteMessage?: () => void;
};

const Message: React.FC<MessageProps> = (props) => {
  const {
    content,
    avatar_url,
    createdAt,
    full_name,
    attachments,
    linkPreview,
    noAvatar,
    isOwnMessage,
    onDeleteMessage,
  } = props;

  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

  const openLightbox = React.useCallback((index: number) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = React.useCallback(() => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  }, []);

  const renderMessageAttachment = React.useCallback(() => {
    return map(attachments, (attachments, index) => (
      <MessageAttachment
        attachment={attachments}
        key={attachments._id}
        onClick={() => openLightbox(index)}
        targetSize={200}
      />
    ));
  }, [attachments, openLightbox]);

  const gallery = React.useMemo(
    () =>
      attachments?.map((attachment) => ({
        src: attachment.url,
        name: attachment.name,
        width: attachment.width ? attachment.width / 100 : 0,
        height: attachment.height ? attachment.height / 100 : 0,
      })),
    [attachments]
  );

  const getViewStyle = (base: React.CSSProperties, state: CarouselState) => {
    return {
      ...base,
      display: "flex !important",
      height: state.isFullscreen ? window.outerHeight : window.innerHeight,
      width: state.isFullscreen ? window.outerWidth : window.innerWidth,
    };
  };

  const renderGallery = React.useCallback(
    () =>
      !!gallery?.length && (
        <>
          {renderMessageAttachment()}
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal
                onClose={closeLightbox}
                styles={{
                  dialog: (base) => ({
                    ...base,
                    maxWidth: window.innerWidth,
                    maxHeight: window.innerHeight,
                  }),
                }}
              >
                <Carousel
                  styles={{ view: getViewStyle }}
                  currentIndex={currentImage}
                  views={gallery.map((photo) => ({
                    source: {
                      regular: photo.src,
                      fullscreen: photo.src,
                    },
                    caption: photo.name,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </>
      ),
    [
      gallery,
      viewerIsOpen,
      closeLightbox,
      currentImage,
      renderMessageAttachment,
    ]
  );

  return (
    <div className="chat-message">
      <div
        className={classNames("message-content", {
          "message-content-noavatar": noAvatar,
        })}
      >
        {!noAvatar && (
          <div className="message-avatar-container">
            <Avatar
              height="60px"
              width="60px"
              url={avatar_url}
              userName={full_name}
              rounded={true}
            />
          </div>
        )}
        <div className="message-data">
          <div className="message-header">
            <div className="message-owner field-text">{full_name}</div>
            <div className="field-text d-flex align-items-center">
              <div>{getMessageTime(createdAt)}</div>
              <div className="delete-message-container">
                {isOwnMessage && (
                  <Button className="delete-message" onClick={onDeleteMessage}>
                    <Icon className="delete-icon" name="delete" outlined />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="field-text message-content">
            <div>
              <Markdown source={content} />
            </div>
          </div>
          {linkPreview?.url && (
            <LinkPreview
              imgUrl={linkPreview.imageUrl}
              description={linkPreview?.description}
              title={linkPreview.title}
              url={linkPreview.url}
            />
          )}
          {renderGallery()}
        </div>
      </div>
    </div>
  );
};

export default Message;
