import React from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { find, compact, chain, pick } from "lodash";
import moment from "moment";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/subcontractor-navigation-items";

import {
  PreviewQuoteRequestResponse,
  QuoteRequestClientFeedback,
  QuoteRequestCommentResponse,
  QuoteRequestCostingPayload,
  QuoteRequestResponse,
  QuoteRequestStatus,
  QuoteRequestUpdateSubcontractorItemsPayload,
} from "../../../graphql/types/models/quote-request";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { ListTableRow } from "../../../components/dashboard/list-table";
import Conversation, {
  ConversationRef,
} from "../../../components/communication/conversation";
import DashboardCard from "../../../components/dashboard/card";
import DashboardCardFooter from "../../../components/dashboard/card/DashboardCardFooter";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import SendModal from "../../../components/modals/send-modal";
import { SendEmailForm } from "../../../models/email";
import { Message } from "../../../models/conversation";
import {
  GET_QUOTE_REQUEST,
  PREVIEW_QUOTE_REQUEST,
} from "../../../graphql/queries/quote-request/queries";
import { getMediaInput } from "../../../utils/transform";
import { formatQuoteNumber } from "../../../utils/text";
import { notify } from "../../../components/notification";
import "./styles.scss";
import { UPDATE_QUOTE_REQUEST_SUBCONTRACTOR_ITEMS } from "../../../graphql/queries/quote-request/mutations";
import ConfirmDialog from "../../../components/confirm-dialog";
import { uploadFiles } from "../../../utils/files";

import Icon from "../../../components/icons/Icon";
import { printBase64Pdf } from "../../../utils/pdf";
import QuoteRequestEntry from "../quote-request-entry";
import { QUOTE_REQUEST_COMMENT_SUB } from "../../../graphql/queries/quote/subscription";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { NoteEntityType } from "../../../graphql/types/models/note";
import { useNote } from "../../../hooks/useNote";
import { useQuoteRequestOperationsMutation } from "../../../hooks/mutations/useQuoteRequestOperationsMutation";
import { useCreateOrderMutation } from "../../../hooks/mutations/useCreateOrderMutation";

type Params = {
  id: string;
  sub_id: string;
};
type QuoteDetailsContainerProps = RouteComponentProps<Params> &
  DashboardContextValue;

const QuoteDetailsContainer: React.FC<QuoteDetailsContainerProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id: quoteRequestId, sub_id: subcontractorId } = match.params;

  const [acceptDialogVisible, setAcceptDialogVisible] = React.useState(false);
  const [declineDialogVisible, setDeclineDialogVisible] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);

  const conversationRef = React.useRef<ConversationRef>(null);
  const sendRef = React.useRef<ModalDisplayRef>();

  const { data, loading, refetch, subscribeToMore } = useQuery<
    QuoteRequestResponse
  >(GET_QUOTE_REQUEST, {
    variables: {
      quoteRequestId,
    },
  });

  const {
    acceptQuoteRequest,
    declineQuoteRequest,
    shareQuoteRequest,
    sendComment,
  } = useQuoteRequestOperationsMutation({
    onCompletedDeclineQuoteRequest: () => refetch(),
  });

  React.useEffect(() => {
    if (!quoteRequestId) {
      return;
    }

    const subscribeToNewComment = subscribeToMore<
      QuoteRequestCommentResponse,
      { quoteRequestId: string }
    >({
      document: QUOTE_REQUEST_COMMENT_SUB,
      variables: {
        quoteRequestId: quoteRequestId,
      },
      updateQuery: (previousQueryResult, response) => {
        refetch();

        return {};
      },
    });

    return () => {
      subscribeToNewComment?.();
    };
  }, [subscribeToMore, refetch, quoteRequestId]);

  const [updateSubcontractorItems] = useMutation<
    QuoteRequestResponse,
    QuoteRequestUpdateSubcontractorItemsPayload
  >(UPDATE_QUOTE_REQUEST_SUBCONTRACTOR_ITEMS, {
    onCompleted: () => {
      notify({
        title: t("quoteRequest.manulEntry"),
        content: t("quoteRequest.success.updateQuoteRequest"),
      });
      setIsEditing(false);
    },
    onError: (error) => {
      notify({
        error: true,
        title: t("quoteRequest.manulEntry"),
        content: error.message,
      });
      setIsEditing(false);
    },
  });

  const [getPDF] = useLazyQuery<PreviewQuoteRequestResponse>(
    PREVIEW_QUOTE_REQUEST,
    {
      onCompleted: (data) => {
        printBase64Pdf(data?.previewQuoteRequest.pdf);
      },
      fetchPolicy: "network-only",
    }
  );

  const quote = React.useMemo(() => data?.getQuoteRequestById, [data]);
  const subcontractor = React.useMemo(
    () => find(quote?.subcontractors, { _id: subcontractorId }),
    [quote]
  );

  const {
    renderPurchaseOrderForm,
    handleShowCreateOrderModal,
  } = useCreateOrderMutation({
    jobId: quote?.job?._id,
    subcontractor,
    data: data?.getQuoteRequestById?.items || [],
  });

  const { renderNotesCard } = useNote(
    NoteEntityType.QUOTE_REQUEST,
    subcontractorId
  );

  const status = React.useMemo(() => subcontractor?.status, [subcontractor]);
  const canAction = React.useMemo(
    () => subcontractor?.status === QuoteRequestStatus.SUBMITTED,
    [subcontractor]
  );
  const canDecline = React.useMemo(
    () =>
      subcontractor?.status === QuoteRequestStatus.SUBMITTED ||
      subcontractor?.status === QuoteRequestStatus.REQUESTED
        ? true
        : false,
    [subcontractor]
  );

  const isAccepted = subcontractor?.status === QuoteRequestStatus.ACCEPTED;

  React.useEffect(() => {
    if (quote) {
      setNavigationContext({
        ...navigationContext,
        quoteRequest: {
          _id: quoteRequestId,
          name: quote?.salesQuote?.name || quote?.job?.name || "",
          quoteNumber: formatQuoteNumber(
            quote?.salesQuote?.number || quote?.job?.jobNumber,
            "Q"
          ),
        },
      });
    }
  }, [setNavigationContext, quote]);

  const detailsData = React.useMemo<ListTableRow[]>(() => {
    if (!quote) return [];
    return [
      {
        label: t("quoteRequest.quoteNo"),
        value: formatQuoteNumber(quote.quoteNumber),
      },
      {
        label: t("quoteRequest.subcontractor"),
        value: compact([
          subcontractor?.supplier?.contact_person,
          subcontractor?.supplier?.business_name,
        ]).join(" / "),
      },
      {
        label: t("quoteRequest.due_date"),
        value: moment(quote.due_date).format("Do MMM YYYY"),
      },
      { label: t("common.status"), value: status },
      {
        label: t("quoteRequest.clientStatus"),
        value: subcontractor?.client_feedback,
      },
    ];
  }, [quote]);

  const messages = React.useMemo<Message[]>(() => {
    return chain(subcontractor?.comments)
      .map((comment) => ({
        _id: comment._id,
        text: comment.comment,
        media: comment.attachments,
        createdAt: comment.createdAt,
        sender: pick(comment.sender, ["_id", "name", "email"]),
      }))
      .reverse()
      .value();
  }, [quote]);

  const handleSubmitComment = React.useCallback(
    async (comment: string, files: File[] | null) => {
      if (quote && comment && subcontractor) {
        const attachments = getMediaInput(files);

        const result = await sendComment({
          variables: {
            quoteRequestId: quote._id,
            comment: {
              comment,
              subcontractorResponseId: subcontractor._id,
              attachments,
            },
          },
        });

        conversationRef?.current?.reset();

        if (files?.length && attachments.length) {
          const attachmentResults =
            result.data?.commentQuoteRequest?.attachments;
          if (attachmentResults?.length) {
            await uploadFiles(attachmentResults, files);
          }
        }
        refetch();
      }
    },
    [sendComment, quote, data]
  );

  const toggleAcceptDialog = React.useCallback(() => {
    setAcceptDialogVisible(!acceptDialogVisible);
  }, [setAcceptDialogVisible, acceptDialogVisible]);

  const toggleDeclineDialog = React.useCallback(() => {
    setDeclineDialogVisible(!declineDialogVisible);
  }, [setDeclineDialogVisible, declineDialogVisible]);

  const handleDecline = React.useCallback(() => {
    declineQuoteRequest({
      variables: {
        quoteRequestId: quoteRequestId,
        subcontractorResponseId: subcontractorId,
      },
    });

    toggleDeclineDialog();
  }, [quoteRequestId, subcontractorId, toggleDeclineDialog]);

  const handleAccept = React.useCallback(() => {
    acceptQuoteRequest({
      variables: {
        quoteRequestId: quoteRequestId,
        subcontractorResponseId: subcontractorId,
      },
    });
    toggleAcceptDialog();
  }, [quoteRequestId, subcontractorId, toggleAcceptDialog]);

  const handlePrint = React.useCallback(async () => {
    getPDF({
      variables: {
        quoteRequestId,
        subcontractorId,
      },
    });
  }, [quoteRequestId, subcontractorId]);

  const handleManualEntry = React.useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const openShareModal = React.useCallback(() => sendRef.current?.show(true), [
    sendRef,
  ]);

  const handleShareQuoteRequest = React.useCallback(
    (data: SendEmailForm) => {
      try {
        if (!quote) return;

        shareQuoteRequest({
          variables: {
            jobId: quote.job._id,
            quoteRequestId: quote._id,
            subcontractorResponseIdList: [subcontractorId],
            message: {
              message: data.message,
              to: data.to,
              subject: data.title,
              remoteAttachmentId: data.remoteAttachmentId,
            },
          },
        });

        notify({
          title: t("quoteRequest.shareQuoteRequest"),
          content: t("quoteRequest.success.shareQuoteRequest"),
        });
      } catch (err) {
        notify({
          error: true,
          title: t("quoteRequest.shareQuoteRequest"),
          content: t("quoteRequest.error.shareQuoteRequest"),
        });
      }
    },
    [quote, subcontractorId, shareQuoteRequest]
  );

  const contact = React.useMemo(() => {
    if (quote?.job) {
      return quote.job.customer;
    }

    if (quote?.salesQuote) {
      return quote.salesQuote.contact;
    }

    return null;
  }, [quote]);

  const handleSubmit = React.useCallback(
    (data: SendEmailForm) => {
      handleShareQuoteRequest(data);
      sendRef.current?.show(false);
    },
    [handleShareQuoteRequest, sendRef]
  );

  const handleSubmitEdit = React.useCallback(
    (values: QuoteRequestCostingPayload) => {
      if (!quote || !subcontractor) return;
      updateSubcontractorItems({
        variables: {
          ...values,
          quoteRequestId: quote._id,
          subcontractorId: subcontractor._id,
        },
      });
    },
    [quote, subcontractor]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quoteRequestSection.quoteRequest")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.DETAILS} />

      <ConfirmDialog
        title={t("quoteRequest.declineQuote")}
        show={declineDialogVisible}
        onSubmit={handleDecline}
        onClose={toggleDeclineDialog}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("quoteRequest.declineQuoteConfirm")}
        </span>
      </ConfirmDialog>

      <ConfirmDialog
        title={t("quoteRequest.acceptQuote")}
        show={acceptDialogVisible}
        onSubmit={handleAccept}
        onClose={toggleAcceptDialog}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("quoteRequest.acceptQuoteConfirm")}
        </span>
      </ConfirmDialog>

      {quote?.job && (
        <SendModal
          ref={sendRef}
          title={t("quoteRequest.shareQuoteRequest")}
          submitText={t("common.share")}
          onSubmit={handleSubmit}
          contacts={contact ? [contact] : []}
          contact={contact}
          disableContactSelect
        />
      )}

      <Row className="quote-request-details">
        <Col lg={8} md={12}>
          <DashboardCard className="quote-request-details-card">
            <DashboardCardHeader className="text-capitalize justify-content-between">
              {quote?.name}
              <Row>
                {quote?.job &&
                  subcontractor &&
                  subcontractor.status === QuoteRequestStatus.SUBMITTED &&
                  subcontractor.client_feedback ===
                    QuoteRequestClientFeedback.NONE && (
                    <Button
                      className="button info bg-transparent text-light"
                      onClick={openShareModal}
                    >
                      <Icon name="share" outlined />
                      {t("quoteRequest.shareWithClient")}
                    </Button>
                  )}
                {!isEditing &&
                  subcontractor?.status === QuoteRequestStatus.REQUESTED && (
                    <Button
                      className="button info bg-transparent text-light mr-4"
                      onClick={handleManualEntry}
                    >
                      <Icon name="edit" outlined />
                      {t("quoteRequest.manulEntry")}
                    </Button>
                  )}
                <Button
                  className="button info bg-transparent text-light mr-4"
                  onClick={handlePrint}
                >
                  <Icon name="print" outlined />
                  {t("common.print")}
                </Button>
              </Row>
            </DashboardCardHeader>

            {quote && subcontractor && (
              <QuoteRequestEntry
                quoteRequest={quote}
                subcontractor={subcontractor}
                isEditing={isEditing}
                onSubmit={handleSubmitEdit}
                onCancel={() => setIsEditing(false)}
                detailsData={detailsData}
                canCancel={true}
              />
            )}

            {!isEditing && (
              <DashboardCardFooter className="d-flex justify-content-end">
                {canDecline && (
                  <Button
                    variant="danger"
                    className="button large danger"
                    onClick={toggleDeclineDialog}
                  >
                    {t("quoteRequest.decline")}
                  </Button>
                )}

                {canAction && (
                  <Button
                    variant="primary"
                    className="button large success"
                    onClick={toggleAcceptDialog}
                  >
                    {t("quoteRequest.accept")}
                  </Button>
                )}
                {!!quote?.salesQuote && (
                  <Button
                    variant="info"
                    className="button large large-wide danger"
                    onClick={() => {
                      if (!!quote?.salesQuote)
                        history.push(
                          `/quotes/${quote?.salesQuote?._id}/costing`
                        );
                    }}
                  >
                    <Icon name="search" />
                    {t("quoteRequest.success.viewEstimation")}
                  </Button>
                )}
                {!!quote?.job && (
                  <>
                    {renderPurchaseOrderForm()}
                    {isAccepted && (
                      <Button
                        variant="primary"
                        className="button large success create-order-button"
                        onClick={handleShowCreateOrderModal}
                      >
                        <Icon name="add" />
                        {t("quoteRequest.createOrder")}
                      </Button>
                    )}
                    <Button
                      variant="info"
                      className="button large danger"
                      onClick={() => {
                        if (!!quote?.job)
                          history.push(`/jobs/${quote?.job?._id}/costings`);
                      }}
                    >
                      <Icon name="search" />
                      {t("quoteRequest.success.viewJob")}
                    </Button>
                  </>
                )}
              </DashboardCardFooter>
            )}
          </DashboardCard>
        </Col>
        <Col lg={4} md={12}>
          <Conversation
            ref={conversationRef}
            onSend={(comment, files) => handleSubmitComment(comment, files)}
            messages={messages}
            name={t("quoteRequest.comments")}
            noAvatar
          />
          {renderNotesCard()}
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(withDashboardContext(QuoteDetailsContainer));
