import { gql } from "@apollo/client";

export const COSTING_ITEM_FRAGMENT = gql`
  fragment SalesQuoteCostingItemFragment on CostItem {
    _id
    name
    quantity
    raw_quantity
    UOM
    cost
    margin_amount
    is_allowance
    note
    order
    hasGST
    wastage
    rounding
    distribute_markup
    distribution_amount
    items {
      _id
      name
      quantity
      raw_quantity
      computed_quantity
      UOM
      cost
    }
  }
`;

export const COSTING_CATEGORY_FRAGMENT = gql`
  fragment SalesQuoteCostingCategoryFragment on SalesQuoteCategory {
    _id
    name
    margin_amount
    order
    costings {
      ...SalesQuoteCostingItemFragment
    }
  }
  ${COSTING_ITEM_FRAGMENT}
`;

export const COSTING_FRAGMENT = gql`
  fragment SalesQuoteCostingFragment on SalesQuote {
    _id
    total
    GST
    margin
    subTotal
    costingCategories {
      ...SalesQuoteCostingCategoryFragment
    }
  }
  ${COSTING_CATEGORY_FRAGMENT}
`;
