import { gql } from "@apollo/client";

export const CREATE_UPDATE_SUPPLIER_CERTIFICATION = gql`
  mutation createUpdateSupplierCertification(
    $certification: SupplierCertificationInput!
  ) {
    createUpdateSupplierCertification(certification: $certification) {
      _id
      name
      document_type
      files {
        _id
        name
        url
        upload_url
        type
        size
      }
      expiry_date
      note
    }
  }
`;

export const DELETE_SUPPLIER_CERTIFICATION = gql`
  mutation deleteSupplierCertification($certificationId: ID!) {
    deleteSupplierCertification(certificationId: $certificationId) {
      _id
      name
      document_type
      files {
        _id
        name
        url
        upload_url
        type
        size
      }
      expiry_date
      note
    }
  }
`;
