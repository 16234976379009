import React from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { chain, map, get, pick } from "lodash";
import moment from "moment";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/subcontractor-navigation-items";

import {
  QuoteRequestCommentPayload,
  QuoteRequestCommentResponse,
  QuoteRequestCostingPayload,
  QuoteRequestCostingResponse,
  QuoteRequestResponse,
} from "../../../graphql/types/models/quote-request";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { ListTableRow } from "../../../components/dashboard/list-table";
import Conversation, {
  ConversationRef,
} from "../../../components/communication/conversation";
import DashboardCard from "../../../components/dashboard/card";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import { Message } from "../../../models/conversation";
import { GET_QUOTE_REQUEST } from "../../../graphql/queries/subcontractor/quotes/queries";
import {
  COMMENT_QUOTE_REQUEST,
  SUBMIT_QUOTE_REQUEST,
} from "../../../graphql/queries/subcontractor/quotes/mutations";
import { formatQuoteNumber } from "../../../utils/text";
import { notify } from "../../../components/notification";
import "./styles.scss";
import { uploadFiles } from "../../../utils/files";

import { QUOTE_REQUEST_COMMENT_SUB } from "../../../graphql/queries/quote/subscription";
import QuoteRequestEntry from "../../quote-request/quote-request-entry";

type Params = {
  id: string;
};
type QuoteDetailsContainerProps = RouteComponentProps<Params> &
  DashboardContextValue;

const QuoteDetailsContainer: React.FC<QuoteDetailsContainerProps> = ({
  navigationContext,
  setNavigationContext,
  match,
}) => {
  const { t } = useTranslation();

  const { id: quoteRequestId } = match.params;

  const conversationRef = React.useRef<ConversationRef>(null);

  const { data, loading, refetch, subscribeToMore } = useQuery<
    QuoteRequestResponse
  >(GET_QUOTE_REQUEST, {
    variables: {
      quoteRequestId,
    },
  });

  //comment sub

  React.useEffect(() => {
    if (!quoteRequestId) {
      return;
    }

    const subscribeToNewComment = subscribeToMore<
      QuoteRequestCommentResponse,
      { quoteRequestId: string }
    >({
      document: QUOTE_REQUEST_COMMENT_SUB,
      variables: {
        quoteRequestId: quoteRequestId,
      },
      updateQuery: (previousQueryResult, response) => {
        refetch();

        return {};
      },
    });

    return () => {
      subscribeToNewComment();
    };
  }, [subscribeToMore, refetch, quoteRequestId]);

  const [sendComment] = useMutation<
    QuoteRequestCommentResponse,
    QuoteRequestCommentPayload
  >(COMMENT_QUOTE_REQUEST);

  const [sendQuote] = useMutation<
    QuoteRequestCostingResponse,
    QuoteRequestCostingPayload
  >(SUBMIT_QUOTE_REQUEST, {
    onCompleted: () => {
      refetch();
      notify({
        title: t("quoteRequest.submitQuote"),
        content: t("quoteRequest.success.submitQuote"),
      });
    },
  });

  const quote = React.useMemo(() => data?.subcontractorGetQuoteRequestById, [
    data,
  ]);

  const status = React.useMemo(() => get(quote, "subcontractors.0.status"), [
    quote,
  ]);
  const canSubmit = React.useMemo(() => status === "Requested", [status]);

  const detailsData = React.useMemo<ListTableRow[]>(() => {
    if (!quote) return [];
    return [
      {
        label: t("quoteRequest.quoteNo"),
        value: formatQuoteNumber(quote.quoteNumber),
      },
      { label: t("quoteRequest.builder"), value: quote.business.name },
      {
        label: t("quoteRequest.due_date"),
        value: moment(quote.due_date).format("Do MMM YYYY"),
      },
      { label: t("common.status"), value: status },
    ];
  }, [quote]);

  const messages = React.useMemo<Message[]>(() => {
    return chain(quote?.subcontractors[0]?.comments)
      .map((comment) => ({
        _id: comment._id,
        text: comment.comment,
        media: comment.attachments,
        createdAt: comment.createdAt,
        sender: pick(comment.sender, ["_id", "name", "email"]),
      }))
      .reverse()
      .value();
  }, [quote]);

  const handleSubmitComment = React.useCallback(
    async (comment: string, files: File[] | null) => {
      if (quote && comment) {
        const attachments = map(files, (file) => ({
          type: file.type,
          size: file.size,
        }));

        const result = await sendComment({
          variables: {
            quoteRequestId: quote._id,
            comment: {
              comment,
              subcontractorResponseId: quote?.subcontractors[0]._id,
              attachments,
            },
          },
        });

        conversationRef?.current?.reset();

        if (files?.length && attachments.length) {
          const attachmentResults =
            result.data?.subcontractorCommentQuoteRequest?.attachments;
          if (attachmentResults?.length) {
            await uploadFiles(attachmentResults, files);
          }
        }
        refetch();
      }
    },
    [sendComment, quote, data]
  );

  const handleSubmit = React.useCallback(
    (values: QuoteRequestCostingPayload) => {
      return sendQuote({
        variables: values,
      });
    },
    [quote]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quoteRequestSection.quoteRequest")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.DETAILS} />
      <Row>
        <Col lg={8} md={12}>
          <DashboardCard className="quote-request-details-card">
            <DashboardCardHeader className="text-capitalize justify-content-center p-0">
              {quote?.name}
            </DashboardCardHeader>
            {quote && quote?.subcontractors[0] && (
              <QuoteRequestEntry
                quoteRequest={quote}
                subcontractor={quote?.subcontractors[0]}
                isEditing={canSubmit}
                onSubmit={handleSubmit}
                detailsData={detailsData}
              />
            )}
          </DashboardCard>
        </Col>
        <Col lg={4} md={12}>
          <Conversation
            ref={conversationRef}
            onSend={(comment, files) => handleSubmitComment(comment, files)}
            messages={messages}
            name={t("quoteRequest.comments")}
            noAvatar
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(withDashboardContext(QuoteDetailsContainer));
