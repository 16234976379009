import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function claimSchema(t: TFunction, maxAmount: number) {
  return yup.object().shape({
    description: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "claims.description")
      ),
    contact: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "claims.contact")
      ),
    status: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "claims.status")
      ),
    claimDate: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "claims.claimDate")
      ),
    amount: yup
      .number()
      .nullable()
      .when("items", {
        is: (value) => {
          return !value || value.length === 0;
        },
        then: yup
          .number()
          .max(
            maxAmount,
            ValidationMessagesUtils.createMaxEqualMessageCurrency(
              t,
              "claims.amount",
              maxAmount
            )
          )
          .min(
            0,
            ValidationMessagesUtils.createMinEqualMessageCurrency(
              t,
              "claims.amount",
              0
            )
          ),
      }),
    percentage: yup
      .number()
      .nullable()
      .when("items", {
        is: (value) => {
          return !value || value.length === 0;
        },
        then: yup.number(),
      }),
    comment: yup.string(),
    items: yup.array().of(
      yup.object().shape({
        amount: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "claims.claimAmount"
            )
          ),
        percentage: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "claims.claimPercent"
            )
          ),
      })
    ),
  });
}
