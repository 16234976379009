import React from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";

import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
} from "../../../components/dashboard/table-card/utils";
import { SpecificationCategoryItem } from "../../../models/specification";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import "./styles.scss";

type SpecificationTableProps = {
  items?: SpecificationCategoryItem[];
  tableTitle: string;
  isDataLoading: boolean;
  isLocked: boolean;
  isSelectedCategory: boolean;
  updateSpecCategoryPress: () => void;
};

const SpecificationTable: React.FC<SpecificationTableProps> = ({
  items,
  tableTitle,
  isDataLoading,
  isLocked,
  isSelectedCategory,
  updateSpecCategoryPress,
}) => {
  const { t } = useTranslation();

  const specificationItemsTableData = React.useMemo<
    TableCardData<SpecificationCategoryItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "url",
          title: t(""),
          formatValue: (row: any, column: any, value: string) => {
            return value ? (
              <img
                className="spec-categories-table-image"
                alt={t("specifications.image")}
                src={value}
              />
            ) : null;
          },
        },
        {
          valueKey: "name",
          title: t("specifications.name"),
        },
        {
          valueKey: "description",
          title: t("specifications.description"),
        },
      ],
      rows: map(items, (item) => ({
        cells: item,
      })),
    };
  }, [t, items]);

  const headerAction = React.useMemo<TableCardAction | undefined>(() => {
    if (isLocked) return;

    return {
      className: "button large info bg-transparent text-light",
      icon: "edit",
      disabled: !isSelectedCategory,
      onClick: updateSpecCategoryPress,
      title: t("common.edit"),
    };
  }, [t, isSelectedCategory, updateSpecCategoryPress, isLocked]);

  return items && items.length === 0 ? (
    <EmptyPlaceholder
      message={t("specifications.emptyPlaceholder")}
      buttonText={t("common.edit")}
      onButtonPress={updateSpecCategoryPress}
    />
  ) : (
    <TableCard
      isDataLoading={isDataLoading}
      headerAction={headerAction}
      rowCount
      title={tableTitle}
      data={specificationItemsTableData}
    />
  );
};

export default SpecificationTable;
