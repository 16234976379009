import { gql } from "@apollo/client";
import { ASSEMBLY_FRAGMENT } from "../assemblies/fragments";
import { PRICE_ITEM_FRAGMENT, DETAILED_PRICE_LIST_FRAGMENT } from "./fragments";

export const LIST_PRICE_LISTS = gql`
  query listPrice {
    listPrice {
      _id
      name
      createdAt
      supplier {
        _id
        business_name
      }
    }
  }
`;

export const LIST_PRICE_LIST_CATEGORIES = gql`
  query listPriceCategory {
    listPriceCategory {
      _id
      name
    }
  }
`;

export const GET_PRICE_LIST = gql`
  query getPrice($priceId: ID!) {
    getPrice(priceId: $priceId) {
      ...DetailedPriceList
    }
  }
  ${DETAILED_PRICE_LIST_FRAGMENT}
`;

export const SEARCH_PRICE_LIST_ITEM = gql`
  query($search: String!) {
    searchPriceItem(search: $search) {
      ...PriceItemFragment
    }
  }
  ${PRICE_ITEM_FRAGMENT}
`;

export const SEARCH_COSTING_ITEM = gql`
  query($search: String!, $searchItemType: EnumSearchItemType) {
    searchCostingItem(search: $search, searchItemType: $searchItemType) {
      priceItems {
        ...PriceItemFragment
      }
      assemblyItems {
        ...AssemblyFragment
      }
    }
  }
  ${PRICE_ITEM_FRAGMENT}
  ${ASSEMBLY_FRAGMENT}
`;

export const PRICE_PREVIEW = gql`
  query PreviewPrice($priceId: ID!) {
    getPricePreview(priceId: $priceId) {
      _id
      pdf
    }
  }
`;
