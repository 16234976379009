import React, { useState } from "react";
import {
  AddMemberPayload,
  TeamMember,
  UserRoles,
} from "../../../models/team-member";
import CreateEntityModal from "../../modals/create-entity";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import createAddMemberFields from "./utils";
import createMemberSchema from "./AddMember.schema";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import { UserPayload } from "../../../graphql/types/models/auth";

type AddMemberModalProps = {
  onSubmit: (data: AddMemberPayload, isUpdate?: boolean) => void;
  user?: UserPayload | null;
};
export type AddMemberModalRef = ModalDisplayRef & {
  showEdit: (member: TeamMember) => void;
};
const AddMemberModal: React.FC<AddMemberModalProps> = (props, ref) => {
  const { onSubmit, user } = props;

  const [initialData, setInitialData] = React.useState<AddMemberPayload>({
    email: "",
    first_name: "",
    hourly_rate: 0,
    last_name: "",
    role: UserRoles.builderadmin,
  });

  const { shouldShow, hide, show } = useModalDisplay(ref, {
    showEdit: (member: TeamMember) => {
      setInitialData(member);
      show();
    },
  });

  const isRoleDisabled = React.useMemo(
    () => !!initialData?._id && user?._id === initialData._id,
    [user, initialData]
  );

  React.useEffect(() => {
    if (!shouldShow) {
      setInitialData({
        email: "",
        first_name: "",
        last_name: "",
        hourly_rate: 0,
        role: UserRoles.builderadmin,
      });
    }
  }, [shouldShow]);

  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<
    GenericFormFields<AddMemberPayload>
  >({});

  const descriptionBlock = React.useMemo(
    () => (
      <div className="item-description-wrapper">
        <p className="item-description-head field-text field-text--underlined">
          {t("team.roles")}
        </p>
        <p className="item-description">
          <span className="item-role">{t("team.builderadmin")}</span>
          <br />
          {t("team.builderadminDescription")}
        </p>
        <p className="item-description">
          <span className="item-role">{t("team.manager")}</span>
          <br />
          {t("team.managerDescription")}
        </p>
        <p className="item-description">
          <span className="item-role">{t("team.basic")}</span>
          <br />
          {t("team.basicDescription")}
          <p className="field-text--underlined mt-2"></p>
        </p>
      </div>
    ),
    [t]
  );

  React.useEffect(() => {
    const fields = createAddMemberFields(t, descriptionBlock, isRoleDisabled);

    setFormFields(fields);
  }, [isRoleDisabled]);

  const handleSubmit = React.useCallback(
    (data: AddMemberPayload) => {
      onSubmit(
        { ...data, hourly_rate: Number(data?.hourly_rate) ?? 0 },
        data?._id ? true : false
      );
    },
    [onSubmit]
  );

  return (
    <CreateEntityModal
      validationSchema={createMemberSchema(t)}
      show={shouldShow}
      className="add-team-member-modal"
      onSubmit={handleSubmit}
      data={initialData}
      onClose={hide}
      title={initialData?._id ? t("team.editMember") : t("team.newMember")}
      fields={formFields}
      submitText={initialData?._id ? t("common.update") : t("common.create")}
    />
  );
};

export default React.forwardRef(AddMemberModal);
