import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  includes,
  map,
  fromPairs,
  invert,
  pickBy,
  identity,
  chain,
  filter,
  mapValues,
  isNull,
} from "lodash";
import { MapField, MapResult } from "./types";
import MapperBody from "./MapperBody";
import { FormikObserver } from "../formik-observer";
import { notify } from "../notification";
import { mapFields } from "../../utils/sheets";
import "./styles.scss";

export type FormValue = {
  [name: string]: string | null;
};
type ImportMapperDisplayOptions = {
  hideSample?: boolean;
  renderTableContainer?: (children: any) => any;
};

type ImportMapperProps<Data> = {
  options?: MapField<Data>[];
  header: string[];
  sample?: object | null;
  rows?: object[] | null;
  onSubmit?: (values: Data[]) => void;
  onChange?: (values: any) => void;
  displayOptions?: ImportMapperDisplayOptions;
  isLoading?: boolean;
};
export type ImportMapperRef = {
  prepareMap: (values: FormValue) => void;
};

const ImportMapper = <Data extends {}>(
  props: ImportMapperProps<Data>,
  ref: any
) => {
  const { t } = useTranslation();

  const {
    onSubmit,
    onChange,
    header,
    sample,
    rows,
    options,
    displayOptions,
    isLoading,
  } = props;

  const [selectedSystemFields, setSelectedFields] = useState<string[]>([]);

  const initialValues = React.useMemo(() => {
    const pairs = map(header, (field, key) => {
      return [key, null];
    });

    return fromPairs(pairs);
  }, [header]);

  const selectOptions = React.useMemo(() => {
    let uniqueGroupKey: null | string = null;
    if (options) {
      selectedSystemFields.forEach((selected: string) => {
        const optionValue = options.find((el) => el.fieldKey === selected);
        if (optionValue && optionValue.uniqueGroup) {
          uniqueGroupKey = optionValue.uniqueGroup;
        }
      });
    }

    const filteredOptions = chain(options)
      .map((option) => ({
        value: option.fieldKey as string,
        label: t(option.label),
        uniqueGroup: option.uniqueGroup,
      }))
      .filter((option) => !includes(selectedSystemFields, option.value))
      .value();

    return uniqueGroupKey
      ? filteredOptions.filter((option) =>
          uniqueGroupKey
            ? !Boolean(option.uniqueGroup === uniqueGroupKey)
            : true
        )
      : filteredOptions;
  }, [selectedSystemFields, options, t]);

  const prepareMap = React.useCallback(
    (values: FormValue) => {
      if (!rows) return false;
      const preparedValues = invert(pickBy(values, identity)) as MapResult<
        Data
      >;

      // validate
      const requiredFields = filter(options, { required: true });
      for (let i = 0; i < requiredFields?.length || 0; i++) {
        const field = requiredFields[i];
        if (!(preparedValues as any)[field.fieldKey]) {
          notify({
            error: true,
            title: t("common.import"),
            content: t("common.errors.fieldMapRequired", {
              field: t(field.label),
            }),
          });
          return false;
        }
      }

      const mappedFields = mapFields(preparedValues, rows);
      const importData = [];
      if (mappedFields && mappedFields.length > 0) {
        for (let i = 0; i < mappedFields.length; i++) {
          if (Object.keys(mappedFields[i]).length > 0) {
            importData.push(
              mapValues(mappedFields[i], (v) => (isNull(v) ? "" : `${v}`))
            );
          }
        }
      }
      return (importData as unknown) as Data[];
    },
    [options, rows]
  );

  const handleSubmit = React.useCallback(
    (values: FormValue) => {
      const result = prepareMap(values);
      result && onSubmit && onSubmit(result);
    },
    [onSubmit, options, rows]
  );

  const handleValuesChange = React.useCallback((values: string[]) => {
    setSelectedFields(values);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      prepareMap: (values: FormValue) => prepareMap(values),
    }),
    [prepareMap]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikProps) => (
        <>
          <FormikObserver value={formikProps.values} onChange={onChange} />
          <MapperBody
            {...formikProps}
            header={header}
            sample={sample}
            options={selectOptions}
            onChange={handleValuesChange}
            hideSample={displayOptions?.hideSample}
            renderTableContainer={displayOptions?.renderTableContainer}
            isLoading={isLoading}
          />
        </>
      )}
    </Formik>
  );
};

export default React.forwardRef(ImportMapper) as <Data extends {}>(
  props: ImportMapperProps<Data> & { ref?: React.Ref<any> }
) => React.ReactElement;
