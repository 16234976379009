import { TFunction } from "i18next";
import * as yup from "yup";
import moment from "moment";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createImportScheduleSchema(t: TFunction) {
  return yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "common.name")
          ),
        startDate: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "jobs.startDate")
          ),
        endDate: yup
          .string()
          .test("endDateTest", "End Date Test Message", function (
            value: string
          ) {
            const { startDate } = this.parent;
            return moment(value).isSameOrAfter(startDate);
          })
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "jobs.endDate")
          ),
      })
    ),
  });
}
