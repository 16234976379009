import { TFunction } from "i18next";
import { map } from "lodash";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { CreateUpdateTimesheetPayload } from "../../../models/timesheet";
import { PriceList, PriceListCategory } from "../../../models/price-list";

export const createPriceLookupField = (
  t: TFunction,
  handlePriceListChange: UpdateTextInputFn,
  handleCategoryChange: UpdateTextInputFn,
  selectedPriceList: string,
  categories: PriceListCategory[],
  priceLists: PriceList[]
): GenericFormFields<CreateUpdateTimesheetPayload> => {
  const priceOptions = map(priceLists, (list) => ({
    value: list._id,
    label: list.name,
  }));

  const categoryOptions = map(categories, (category) => ({
    value: category._id,
    label: category.name,
  }));

  return {
    priceList: {
      type: "select-autocomplete",
      label: t("costing.priceList"),
      valueKey: "priceList",
      autocompleteProps: {
        options: priceOptions,
      },
      textInputProps: {
        onValueChange: handlePriceListChange,
      },
      inputProps: {
        className: "form-input-compact",
      },
    },
    ...(selectedPriceList && {
      category: {
        type: "select-autocomplete",
        label: t("common.category"),
        valueKey: "category",
        autocompleteProps: {
          options: categoryOptions,
        },
        textInputProps: {
          onValueChange: handleCategoryChange,
        },
        inputProps: {
          className: "form-input-compact",
        },
      },
    }),
  };
};
