import { gql } from "@apollo/client";

export const LIST_LEAD_STAGES_FRAGMENT = gql`
  fragment LeadStagesFragment on LeadStage {
    _id
    name
    order
    leads {
      _id
      name
      description
      stage {
        _id
        name
        order
      }
      expectedCloseDate
      budget
      tags {
        _id
        name
      }
      contacts {
        _id
        first_name
        last_name
      }
      assignedTo {
        _id
        first_name
        last_name
        profileImage {
          _id
          url
        }
      }
    }
  }
`;

export const LEAD_FRAGMENT = gql`
  fragment LeadFragment on LeadSale {
    _id
    name
    description
    address
    city
    state
    postcode
    estimation {
      _id
      number
      name
      status
      total
      createdAt
      contact {
        _id
        first_name
        last_name
      }
    }
    stage {
      _id
      name
      order
    }
    tags {
      _id
      name
    }
    expectedCloseDate
    budget
    contacts {
      _id
      first_name
      last_name
    }
    assignedTo {
      _id
      first_name
      last_name
      profileImage {
        _id
        url
      }
    }
  }
`;
