import { gql } from "@apollo/client";

export const LIST_TAKEOFF = gql`
  query ListSalesQuoteTakeOff($salesQuoteId: ID!) {
    listSalesQuoteTakeOff(salesQuoteId: $salesQuoteId) {
      _id
      name
      quantity
      UOM
      shapes {
        name
        angle
        weight
        wallHeight
        materialWidth
        depth
        geometry {
          lat
          lng
        }
        type
        page
        leaflet_id
        properties {
          text
        }
      }
      properties {
        color
      }
    }
  }
`;

export const EXPORT_TAKE_OFF_ITEMS = gql`
  query ExportTakeOffItems($salesQuoteId: ID!) {
    exportTakeOffItems(salesQuoteId: $salesQuoteId) {
      _id
      data
      filename
    }
  }
`;
