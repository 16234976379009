import React, { useState } from "react";
import { map, take } from "lodash";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import HeaderTitle from "../../../components/dashboard/header/HeaderTitle";
import HeaderPageAction from "../../../components/dashboard/header/HeaderPageAction";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import Dropdown from "../../../components/dashboard/dropdown";
import { useSalesQuotesQuery } from "../../../hooks/queries/useSalesQuotesQuery";
import "./styles.scss";
import { formatQuoteNumber } from "../../../utils/text";

type EstimationLocationHeaderProps = DashboardContextValue & {
  children?: any;
};

const EstimationLocationHeader: React.FC<EstimationLocationHeaderProps> = (
  props
) => {
  const { navigationContext, children } = props;

  const history = useHistory();
  const match = useRouteMatch();
  const estimationName = `${formatQuoteNumber(
    Number(navigationContext?.quote?.quoteNumber),
    "Q"
  )} ${navigationContext?.quote?.name}`;

  const { salesQuotes } = useSalesQuotesQuery();

  const [showDropdown, setDropdownVisibility] = useState(false);

  const handleToggle = React.useCallback(() => {
    setDropdownVisibility(!showDropdown);
  }, [showDropdown]);

  const quoteOptions = React.useMemo(() => {
    return map(salesQuotes, (quote) => ({
      id: quote._id,
      label: `${formatQuoteNumber(Number(quote?.number), "Q")} ${quote?.name}`,
      onClick: () => {
        const path = take(match.path.split("/"), 4).join("/");
        const url = generatePath(path, { id: quote._id });
        history.push(url);
      },
    }));
  }, [salesQuotes]);

  return (
    <HeaderPageAction order="2">
      {children}
      <HeaderTitle>
        <Dropdown
          iconFirst
          toggleClass="estimation-navigation-toggle"
          isVisible={showDropdown}
          handleToggle={handleToggle}
          label={estimationName}
          outlined
          size="340px"
          id="estimation-navigation-toolbar"
          items={quoteOptions}
          menuWidth="auto"
        />
      </HeaderTitle>
    </HeaderPageAction>
  );
};

export default withDashboardContext(EstimationLocationHeader);
