import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";
import { createSaveCostingAsTemplateField } from "./utils";
import { SAVE_QUOTE_COSTING_AS_TEMPLATE } from "../../../graphql/queries/building-template/mutations";
import SaveCostingTemplate from "./SaveCostingTemplate.schema";
import { notify } from "../../../components/notification";
import "./styles.scss";

type SaveCostingAsTemplateModalProps = {
  salesQuoteId: string | undefined;
};

export type CostingTempaltePayload = {
  name: string;
};

export type SaveCostingAsTemplateModalRef = {
  show: (show: boolean) => void;
};
const SaveCostingAsTemplateModal: React.FC<SaveCostingAsTemplateModalProps> = (
  props,
  ref
) => {
  const { salesQuoteId } = props;

  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  const [saveQuoteCostingAsTemplate] = useMutation<any, any>(
    SAVE_QUOTE_COSTING_AS_TEMPLATE,
    {
      onCompleted: () => {
        notify({
          title: t("buildingTemplate.template"),
          content: t("buildingTemplate.success.templateCreated"),
        });
        setShowModal(false);
      },
    }
  );

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => setShowModal(show),
  }));

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CostingTempaltePayload>
  >({});

  React.useEffect(() => {
    setFormFields(createSaveCostingAsTemplateField(t));
  }, [t, salesQuoteId]);

  const initialValues = React.useMemo(
    () => ({
      name: "",
    }),
    [salesQuoteId]
  );

  const handleSubmit = React.useCallback(
    async (values: CostingTempaltePayload) => {
      const result = await saveQuoteCostingAsTemplate({
        variables: {
          salesQuoteId: salesQuoteId,
          templateName: values.name,
        },
      });
    },
    [salesQuoteId, setShowModal, saveQuoteCostingAsTemplate]
  );

  return (
    <>
      <CreateEntityModal
        validationSchema={SaveCostingTemplate(t)}
        className="create-job-modal"
        title={t("costing.saveCostAsTemplate")}
        show={showModal}
        data={initialValues}
        onSubmit={handleSubmit}
        onClose={() => setShowModal(false)}
        fields={formFields}
      />
    </>
  );
};

export default forwardRef(SaveCostingAsTemplateModal);
