import { TFunction } from "i18next";
import * as yup from "yup";
import { PurchaseOrderStatus } from "../../../models/purchaseOrder";
import { ValidationMessagesUtils, ValidationRules } from "../../../utils/yup";

export default function createOrderSchema(t: TFunction) {
  return yup.object().shape({
    supplier: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "orders.contactName")
      ),
    /*
    attention: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "orders.attention")
      ),
      */
    reference: yup.string().when("status", {
      is: (value) => {
        return (
          value === PurchaseOrderStatus.RECEIVED ||
          value === PurchaseOrderStatus.PARTIALLY_RECEIVED
        );
      },
      then: yup.string().required(),
    }),
    date: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "orders.orderDate")
      ),
    deliveryDate: yup.string().optional(),
    // .required(
    //   ValidationMessagesUtils.createRequiredMessage(t, "orders.deliveryDate")
    // ),
    /*
    contactNumber: ValidationRules.getPhoneValidationRules(yup, t).required(
      ValidationMessagesUtils.createRequiredMessage(t, "orders.contactNumber")
    ),
    deliveryAddress: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "orders.address")
      ),
      */
    deliveryInstructions: yup.string().nullable().optional(),
    dateReceived: yup.string().when("status", {
      is: (value) => {
        return (
          value === PurchaseOrderStatus.RECEIVED ||
          value === PurchaseOrderStatus.PARTIALLY_RECEIVED
        );
      },
      then: yup.string().required(),
    }),

    items: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "common.description"
            )
          ),
        quantity: yup
          .number()
          .moreThan(0)
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.quantity")
          ),
        cost: yup.number().nullable().optional(),
        UOM: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.uom")
          ),
      })
    ),
  });
}
