import React from "react";
import Toggle from "react-toggle";
import { TextInputProps } from "../text";
import "./styles.scss";
import "react-toggle/style.css";

export type ToggleInputProps = Omit<TextInputProps, "onChange"> & {
  onChange: (value: boolean) => void;
  value: boolean;
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
  width?: string;
  maxWidth?: string;
};

const ToggleInput: React.FC<ToggleInputProps> = (props) => {
  const {
    onChange,
    value,
    name,
    label,
    disabled,
    readOnly,
    width,
    maxWidth,
  } = props;

  const handleToggle = React.useCallback(
    (e: any) => {
      if (readOnly || disabled) return;
      onChange(e.target.checked as boolean);
    },
    [onChange]
  );

  const style = React.useMemo(
    () => ({
      minWidth: width,
      width: maxWidth,
    }),
    [width, maxWidth]
  );

  return (
    <div className="form-input-toggle field-text" style={style}>
      <Toggle
        checked={value}
        name={name}
        onChange={handleToggle}
        disabled={disabled || readOnly}
      />
      <span>{label}</span>
    </div>
  );
};

export default ToggleInput;
