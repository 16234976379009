import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { map } from "lodash";
import moment from "moment";

import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/client-navigation-items";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../../components/dashboard/table-card/utils";
import TableCard from "../../../../components/dashboard/table-card";
import EmptyPlaceholder from "../../../../components/empty-placeholder";

import { LIST_CLIENT_JOBS } from "../../../../graphql/queries/client/job/queries";
import { ListClientJobsResponse } from "../../../../graphql/types/models/job";
import { ClientJob } from "../../../../models/job";

const ClientJobs: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: jobsData, loading: listClientJobLoading } = useQuery<
    ListClientJobsResponse
  >(LIST_CLIENT_JOBS);

  const jobsClientTableData = React.useMemo<TableCardData<ClientJob>>(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("client.jobs.project"),
        },
        {
          valueKey: "updatedAt",
          title: t("client.jobs.lastUpdated"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
      ],
      rows: map(jobsData?.listClientJobs, (item) => ({
        cells: item,
      })),
    };
  }, [t, jobsData]);

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<ClientJob>) => {
      history.push(`/projects/${row.cells._id}/discussion`);
    },
    [history]
  );

  return (
    <Container fluid className="h-100">
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.JOBS} />

      <Row className="h-100">
        <Col xs={12} className="client-jobs-table-container pb-sm-5 pb-lg-0">
          {jobsData?.listClientJobs && jobsData?.listClientJobs.length > 0 ? (
            <TableCard
              isDataLoading={listClientJobLoading}
              alignEnd
              data={jobsClientTableData}
              onRowClick={handleRowClick}
            />
          ) : (
            <EmptyPlaceholder message={t("client.jobs.emptyPlaceholder")} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ClientJobs;
