import React from "react";
import { map } from "lodash";
import Icon from "../../../../../icons/Icon";
import "./styles.scss";

type AttachmentProps = {
  onSelect?: (files: File[]) => void;
};

const Attachment: React.FC<AttachmentProps> = (props) => {
  const { onSelect } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = React.useCallback(() => {
    inputRef.current?.click();
  }, []);

  const handleFileSelect = React.useCallback(() => {
    const current = inputRef.current;

    if (!current) {
      return;
    }

    const files = map(current.files, (file) => file);
    current.value = "";

    onSelect && onSelect(files);
  }, [onSelect]);

  return (
    <div className="rdw-option-wrapper attachment-tool" onClick={handleClick}>
      <input
        multiple
        ref={inputRef}
        type="file"
        className="attachment-input"
        onChange={handleFileSelect}
      />
      <Icon name="attach_file" />
    </div>
  );
};

export default Attachment;
