import React, { ChangeEvent, ReactElement, RefObject } from "react";
import ReactDOM from "react-dom";
import omitDeep from "omit-deep-lodash";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import GSTC, {
  Config,
  Item,
} from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import { Plugin as TimelinePointer } from "gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js";
import { Plugin as Selection } from "gantt-schedule-timeline-calendar/dist/plugins/selection.esm.min.js";
import { Plugin as ItemResizing } from "gantt-schedule-timeline-calendar/dist/plugins/item-resizing.esm.min.js";
import { Plugin as ItemMovement } from "gantt-schedule-timeline-calendar/dist/plugins/item-movement.esm.min.js";
import { Plugin as CalendarScroll } from "gantt-schedule-timeline-calendar/dist/plugins/calendar-scroll.esm.min.js";
import { Plugin as DependencyLines } from "gantt-schedule-timeline-calendar/dist/plugins/dependency-lines.esm.min.js";
import { Plugin as TimeBookmarks } from "gantt-schedule-timeline-calendar/dist/plugins/time-bookmarks.esm.min.js";
import { Plugin as HighlightWeekends } from "gantt-schedule-timeline-calendar/dist/plugins/highlight-weekends.esm.min.js";

import { Properties as CSSProps } from "csstype";
import {
  get,
  chain,
  merge,
  reduce,
  transform,
  size,
  difference,
  pick,
} from "lodash";
import {
  JobDetails,
  ScheduleCategory,
  ScheduleCategoryChartItem,
  ScheduleCategoryItem,
} from "../../../models/job";
import { defaultConfig } from "../../../components/calendar/utils";
import { ProgressItem } from "../../../components/calendar/calendar-components/ProgressItem";
import { SupplierDetails } from "../../../models/supplier";
import { User } from "../../../graphql/types/models/user";
import { parseDate } from "../../../utils/date";
import {
  UserDetailsModalRef,
  UserDetailsRequestType,
} from "../../../components/job-schedule/user-details-modal";

export type CalendarTask = {
  id: string | number;
  name: string;
  parentId?: string;
  duration: string;
  startDate: string;
  endDate: string;
  supplier?: SupplierDetails;
  colour?: string;
  progress?: string;
  predecessor?: string;
  user?: User;
  expanded: boolean;
  style?: CSSProps;
  classNames?: string[];
};

export type CalendarTaskChartItem = {
  id: string | number;
  rowId: string | number;
  parentId?: string;
  isHTML?: boolean;
  // label?: string;
  label?: (props: { item: any; vido: any }) => string;
  time: {
    start: number;
    end: number;
  };
  style?: CSSProps;
  dependant?: string[];
  classNames?: string[];
};

export type CalendarChartTime = {
  from: number;
  to: number;
};

export type LocalToUTCStartEndDate = {
  startDate: Moment;
  endDate: Moment;
};

export type CalendarTasks = {
  [key: string]: CalendarTask;
};

export type CalendarTasksChart = {
  [key: string]: CalendarTaskChartItem;
};

type ContextHandlers = {
  handleSelectContext: (event: React.MouseEvent, data: CalendarTask) => any;
  handleContextAction: (event: React.MouseEvent, data: CalendarTask) => any;
  handleClearContext: (event: React.MouseEvent) => any;
};

type TimelineContextHandlers = {
  handleTimelineSelectContext: (event: React.MouseEvent) => any;
  handleTimelineContextAction: (event: React.MouseEvent) => any;
};

const getTaskDuration = (finishDate: string, startDate: string): number => {
  return moment
    .duration(moment(finishDate).add(1, "day").diff(startDate))
    .as("day");
};

export const convertLocalToUTCStartEndDate = (
  startDate: string,
  endDate: string
): LocalToUTCStartEndDate => {
  const start = moment(startDate).startOf("day");
  const end = moment(endDate).endOf("day");
  const diff = start.diff(end, "seconds");
  const timeStart = moment(moment(startDate).format("YYYY-MM-DD")).utc();
  const timeEnd = moment(timeStart).add(Math.abs(diff), "seconds");
  return {
    startDate: timeStart,
    endDate: timeEnd,
  };
};

export const updateChartBySizeDifference = (
  target: any,
  currentRows: object,
  updatedRows: object
) => {
  const hasDeleted = size(currentRows) > size(updatedRows);
  if (hasDeleted) {
    const deletedItems = difference(
      Object.keys(currentRows),
      Object.keys(updatedRows)
    );
    for (let rowId of deletedItems) {
      delete target[rowId];
    }
  } else {
    target = updatedRows;
  }
};

export const getChartTime = (
  tasks: ScheduleCategoryChartItem[]
): CalendarChartTime => {
  let from = moment(get(tasks, "0.startDate")).startOf("day");
  let to = moment(get(tasks, "0.endDate")).endOf("day");
  const time = reduce(
    tasks,
    (result: any, value: ScheduleCategoryChartItem) => {
      return {
        from: moment.min(result.from, moment(value.startDate)),
        to: moment.min(result.to, moment(value.endDate)),
      };
    },
    { from, to }
  );

  return {
    from: parseInt(time.from.format("x")),
    to: parseInt(time.to.format("x")),
  };
};

export const getScheduleCategoryItem = (
  categories: ScheduleCategory[],
  itemId: string
) => {
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    if (category.items) {
      for (let j = 0; j < category.items?.length; j++) {
        const item = category.items[j];
        if (item._id === itemId) {
          return item;
        }
      }
    }
  }
};

export const checkStartEndCategory = (
  items: ScheduleCategoryItem[] | undefined,
  task: {
    min: Moment;
    max: Moment;
    id: string;
  }
) => {
  if (!items) return { minDate: task.min, maxDate: task.max };
  let minDate = task.min;
  let maxDate = task.max;
  items?.forEach((categoryItem) => {
    if (categoryItem._id !== task.id) {
      minDate = moment.min(minDate, moment(categoryItem.startDate));
      maxDate = moment.max(maxDate, moment(categoryItem.endDate));
    }
  });

  return { minDate, maxDate };
};

export const createScheduleRows = (
  tasks: ScheduleCategoryChartItem[],
  t: TFunction,
  parent?: ScheduleCategory,
  subIndex?: number
): CalendarTasks => {
  let index = 1;

  return chain(tasks)
    .orderBy(["order"], ["asc"])
    .keyBy((task) => GSTC.api.GSTCID(task._id))
    .transform(
      (result: CalendarTasks, task: ScheduleCategoryChartItem, key: string) => {
        const progress = task.items
          ? calcAverageProgress(task.items)
          : task.progress || 0;

        result[key] = {
          id: GSTC.api.GSTCID(task._id),
          name: task.name,
          // displayId: subIndex ? `${subIndex}.${index}` : index.toString(),
          parentId: parent?._id ? GSTC.api.GSTCID(parent._id) : undefined,
          startDate: moment(task.startDate).format("D/M/YYYY"),
          endDate: moment(task.endDate).format("D/M/YYYY"),
          duration: t("schedule.durationValue", {
            days: Math.floor(getTaskDuration(task.endDate, task.startDate)),
          }),
          supplier: task.supplier,
          user: task.user,
          colour: task.colour,
          progress: t("schedule.progressAmount", { amount: progress }),
          expanded: true,
          predecessor: task.predecessor?._id, // only used for context action
          style: {
            fontWeight: parent ? "normal" : "bold",
            ...(!parent ? { background: "rgba(220, 220, 220, 0.4)" } : {}),
            // background: parent ? "rgb(240, 240, 240)" : "rgb(220, 220, 220)",
          },
          classNames: [parent ? "row-child" : "row-parent"],
        };
        if (task.items?.length) {
          const subTasks = createScheduleRows(task.items || [], t, task, index);

          for (const subTaskKey in subTasks) {
            result[subTaskKey] = subTasks[subTaskKey];
          }
        }

        index++;
      },
      {}
    )
    .value();
};

export const calcAverageProgress = (
  subTasks: ScheduleCategoryChartItem[]
): number => {
  if (!subTasks.length) {
    return 0;
  }

  const sum = reduce(
    subTasks,
    (acc, current) => {
      acc += current.progress || 0;
      return acc;
    },
    0
  );

  return Math.floor(sum / subTasks.length);
};

export const createScheduleSubscription = (
  t: TFunction,
  calendarState: any
) => {
  calendarState.subscribe(
    "config.chart.items.:id.time",
    (time: any, eventInfo: any) => {
      const id = eventInfo.params.id;
      const row = calendarState.get(`config.list.rows.${id}`);
      const startDate = moment(time.start);
      const startDateFormat = startDate.format("D/M/YYYY");
      const endDate = moment(time.end);
      const duration = t("schedule.durationValue", {
        days: Math.floor(
          getTaskDuration(endDate.toISOString(), startDate.toISOString())
        ),
      });
      if (row?.startDate !== startDateFormat || row?.duration !== duration) {
        calendarState.update(
          `config.list.rows.${id}.startDate`,
          startDateFormat
        );
        calendarState.update(`config.list.rows.${id}.duration`, duration);
      }
    }
  );
};

export const createScheduleChart = (
  tasks: ScheduleCategoryChartItem[],
  parent?: ScheduleCategory,
  allTasks?: Map<string, boolean>
): CalendarTasksChart => {
  const tasksObj = chain(tasks)
    .orderBy(["order"], ["asc"])
    .keyBy((task) => GSTC.api.GSTCID(task._id))
    .value();

  const tasksMap =
    allTasks ||
    reduce(
      tasks,
      (result, task) => {
        task.items?.forEach((item) =>
          result.set(GSTC.api.GSTCID(item._id), true)
        );
        return result;
      },
      new Map<string, boolean>()
    );

  return transform(
    tasksObj,
    (
      result: CalendarTasksChart,
      task: ScheduleCategoryChartItem,
      key: string
    ) => {
      const progress = task.items
        ? calcAverageProgress(task.items)
        : task.progress || 0;

      // fix utc date
      const { startDate, endDate } = convertLocalToUTCStartEndDate(
        task.startDate,
        task.endDate
      );

      result[key] = {
        id: GSTC.api.GSTCID(task._id),
        rowId: GSTC.api.GSTCID(task._id),
        parentId: parent?._id ? GSTC.api.GSTCID(parent._id) : undefined,
        label({ item, vido }) {
          return ProgressItem(
            vido.html,
            task.name,
            task.description || task.name,
            progress
          );
        },
        time: {
          start: parseInt(startDate.format("x")),
          end: parseInt(endDate.format("x")),
        },
        style: {
          background:
            parent?.colour || task.colour || (parent ? "#7d829e" : "#5e6278"),
        },
        dependant: task.dependant?.length
          ? chain(task.dependant)
              .compact()
              .map((d) => GSTC.api.GSTCID(d._id))
              .filter((id) => !!tasksMap.get(id)) // ensure dependant exists to prevent crash
              .value()
          : [],
      };
      if (task.items?.length) {
        const subTasks = createScheduleChart(task.items || [], task, tasksMap);
        for (const subTaskKey in subTasks) {
          result[subTaskKey] = {
            ...subTasks[subTaskKey],
          };
        }
      }
    },
    {}
  );
};

export const prepareCategory = (category: ScheduleCategory) => {
  return pick(category, [
    "_id",
    "name",
    "startDate",
    "endDate",
  ]) as ScheduleCategory;
};

export const prepareCategoryItem = (item: ScheduleCategoryItem) => {
  return {
    ...omitDeep(
      pick(item, [
        "_id",
        "name",
        "description",
        "startDate",
        "endDate",
        "progress",
        "reminder",
      ]),
      "__typename"
    ),
    predecessor: item.predecessor?._id,
    supplier: item.supplier?._id,
    user: item.user?._id,
  } as ScheduleCategoryItem;
};

const TaskActions = (
  onUpdateTaskGroup: (id: string) => void,
  onCreateSubTask: (parentId: string) => void,
  onUpdateTask: (id: string, parentId?: string) => void,
  onNudge: (id: string, parentId?: string) => void,
  onDelete: (id: string, parentId?: string) => void
) => (element: HTMLElement, data: any) => {
  const handleEdit = () => {
    if (data.row.parentId) {
      onUpdateTask(data.row.id, data.row.parentId);
    } else {
      onUpdateTaskGroup(data.row.id);
    }
  };

  const handleCreate = () => {
    onCreateSubTask(data.row.id);
  };

  const handleNudge = () => {
    onNudge(data.row.id, data.row.parentId);
  };
  const handleDelete = () => {
    onDelete(data.row.id, data.row.parentId);
  };

  const addButton = () => {
    if (data?.column?.id === "action") {
      const container = element.querySelector(".gstc__list-column-row-content");
      const buttons = [];
      // nudge
      const hasAvatar = data?.row?.user || data?.row?.supplier;
      const nudgeButton = document.createElement("i");
      nudgeButton.classList.add("material-icons");
      nudgeButton.classList.add("material-icons-outlined");
      nudgeButton.classList.add("action");
      nudgeButton.classList.add("action-nudge");
      nudgeButton.innerText = "notifications";
      nudgeButton.addEventListener("click", handleNudge);
      buttons.push(nudgeButton);
      if (!hasAvatar || !data.row.parentId) {
        nudgeButton.style.display = "none";
      }
      // edit task
      const updateButton = document.createElement("i");
      updateButton.classList.add("material-icons");
      updateButton.classList.add("material-icons-outlined");
      updateButton.classList.add("action");
      updateButton.innerText = "create";
      updateButton.addEventListener("click", handleEdit);
      buttons.push(updateButton);

      // delete task
      const deleteButton = document.createElement("i");
      deleteButton.classList.add("material-icons");
      deleteButton.classList.add("material-icons-outlined");
      deleteButton.classList.add("action");
      deleteButton.innerText = "delete";
      deleteButton.addEventListener("click", handleDelete);
      buttons.push(deleteButton);

      // subtask
      const createButton = document.createElement("i");
      createButton.classList.add("material-icons");
      createButton.classList.add("action");
      createButton.classList.add("action-create");
      createButton.innerText = "add";
      createButton.addEventListener("click", handleCreate);
      if (data.row.parentId) {
        createButton.style.display = "none";
      }
      buttons.push(createButton);

      if (container) {
        const actions = document.createElement("div");
        actions.classList.add("calendar-actions");
        buttons.forEach((button) => actions.appendChild(button));
        container.appendChild(actions);
      }
    }
  };

  const updateButton = (element: HTMLElement, data: any) => {
    if (data?.column?.id === "action") {
      const actions = element.querySelector(".calendar-actions");
      if (!actions) return;
      const createButton = actions.querySelector(
        ".action-create"
      ) as HTMLElement;
      if (createButton) {
        createButton.style.display = data?.row?.parentId ? "none" : "block";
      }
      const nudgeButton = actions.querySelector(".action-nudge") as HTMLElement;
      if (nudgeButton) {
        const hasAvatar = data?.row?.user || data?.row?.supplier;
        nudgeButton.style.display =
          hasAvatar && data?.row?.parentId ? "block" : "none";
      }
    }
  };
  const removeButton = (element: HTMLElement) => {
    if (data?.column?.id === "action") {
      const actions = element.querySelector(".calendar-actions");
      if (actions) actions.remove();
    }
  };

  addButton();

  return {
    update(element: HTMLElement, data: any) {
      // data was changed - clear things from previous data and set up element for new one
      updateButton(element, data);
    },
    destroy(...destroyProps: any) {
      removeButton(element);
    },
  };
};

export const Avatar = (
  renderAvatar: (user?: User, supplier?: SupplierDetails) => ReactElement,
  userDetailsRef: RefObject<UserDetailsModalRef>
) => (element: HTMLElement, data: any) => {
  const handleShowProfile = (user: User, supplier: SupplierDetails) => {
    if (user) {
      userDetailsRef.current?.show(true, {
        type: UserDetailsRequestType.MEMBER,
        userId: user._id,
      });
    } else if (supplier) {
      userDetailsRef.current?.show(true, {
        type: UserDetailsRequestType.SUPPLIER,
        userId: supplier._id,
      });
    }
  };

  const addAvatar = () => {
    if (data?.column?.id === "avatar") {
      const row = data?.row;

      const container = element.querySelector(
        ".gstc__list-column-row-content"
      ) as HTMLElement;

      if (container) {
        container.style.position = "relative";
        const existAvatarContent = element.querySelector(".avatar-content");
        if (existAvatarContent) {
          container.innerHTML = "";
        }
        const avatar = document.createElement("div");
        avatar.className = "avatar-content";
        avatar.id = row.id;
        avatar.setAttribute("data-id", row?.user?._id || row?.supplier?._id);
        avatar.addEventListener("click", () => {
          handleShowProfile(row?.user, row?.supplier);
        });
        ReactDOM.render(
          renderAvatar(row?.user as User, row?.supplier as SupplierDetails),
          avatar
        );

        container.appendChild(avatar);
      }
    }
  };

  const removeAvatar = (element: HTMLElement) => {
    if (data?.column?.id === "avatar") {
      const container = element.querySelector(".gstc__list-column-row-content");
      if (container) container.innerHTML = "";
    }
  };

  addAvatar();

  return {
    update(element: HTMLElement, data: any) {
      if (data?.column?.id === "avatar") {
        const currentId = data.row.id;
        const container = element.querySelector(
          ".gstc__list-column-row-content"
        );
        const row = data?.row;
        if (container) {
          const avatarContent = container.querySelector(".avatar-content");
          if (avatarContent) {
            if (
              avatarContent.id !== currentId ||
              avatarContent.getAttribute("data-id") !==
                (row?.user?._id || row?.supplier?._id)
            ) {
              addAvatar();
            }
          }
        }
      }
    },
    destroy(...destroyProps: any) {
      removeAvatar(element);
    },
  };
};

const StartDateInput = (
  renderStartDate: (
    date: string,
    onDateSelect: (value: string) => void,
    values: {
      id: string;
      parentId: string;
      dateType: string;
    }
  ) => ReactElement
) => (element: HTMLElement, data: any) => {
  const container = element.querySelector(".gstc__list-column-row-content");

  const onDateSelect = (value: string) => {
    if (container) {
      const dateContent = container.querySelector("span");
      const datePicker = container.querySelector("div");
      if (datePicker && dateContent) {
        dateContent.innerHTML = moment(value).format("DD/MM/YYYY");
        dateContent.style.display = "block";
        datePicker.style.visibility = "hidden";
      }
    }
  };

  const toggleDatePicker = () => {
    if (container) {
      const dateContent = container.querySelector("span");
      const datePicker = container.querySelector("div");

      if (datePicker && dateContent) {
        dateContent.style.display = "none";
        datePicker.style.visibility = "visible";
      }
    }
  };
  const addStartDateInput = () => {
    if (
      data?.column?.id === "startDateSelect" ||
      data?.column?.id === "endDateSelect"
    ) {
      const isStartDate = Boolean(data?.column?.id === "startDateSelect");
      if (container) {
        container.id = element.dataset["gstcid"] as string;
        container.innerHTML = "";
        const dateContent = document.createElement("span");
        dateContent.className = "date-content";
        dateContent.style.position = "absolute";
        isStartDate
          ? (dateContent.innerHTML = data?.row.startDate)
          : (dateContent.innerHTML = data?.row.endDate);
        dateContent.addEventListener("click", toggleDatePicker);

        container.appendChild(dateContent);

        const datePicker = container.querySelector(".datePicker-content");
        if (!datePicker) {
          const datePickerContainer = document.createElement("div");
          datePickerContainer.className = "datePicker-content";
          datePickerContainer.style.position = "absolute";
          datePickerContainer.style.visibility = "hidden";

          ReactDOM.render(
            renderStartDate(
              parseDate(
                isStartDate ? data?.row.startDate : data?.row.endDate
              ).toISOString(),
              onDateSelect,
              {
                id: data?.row.id,
                parentId: data?.row.parentId,
                dateType: isStartDate ? "startDate" : "endDate",
              }
            ),
            datePickerContainer
          );

          container.appendChild(datePickerContainer);
        }
      }
    }
  };

  const removeDatePicker = (element: HTMLElement) => {
    if (
      data?.column?.id === "startDateSelect" ||
      data?.column?.id === "endDateSelect"
    ) {
      const container = element.querySelector(".gstc__list-column-row-content");
      if (container) container.innerHTML = "";
    }
  };

  addStartDateInput();

  return {
    update(element: HTMLElement, data: any) {
      const container = element.querySelector(
        ".gstc__list-column-row-content"
      ) as HTMLElement;
      container.style.position = "relative";
      const dateContent = element.querySelector(
        ".gstc__list-column-row-content .date-content"
      ) as HTMLElement;
      const datePicker = element.querySelector(
        ".gstc__list-column-row-content .datePicker-content"
      ) as HTMLElement;

      if (
        data?.column?.id === "startDateSelect" ||
        data?.column?.id === "endDateSelect"
      ) {
        const currentElementId = element.dataset["gstcid"];
        const dataId = element.querySelector(".gstc__list-column-row-content")
          ?.id;

        if (currentElementId !== dataId) {
          addStartDateInput();
        } else {
          if (data?.column?.id === "startDateSelect") {
            dateContent.innerHTML = data?.row?.startDate;
          } else {
            dateContent.innerHTML = data?.row?.endDate;
          }
          dateContent.style.display = "block";
          datePicker.style.visibility = "hidden";
        }
      }
    },
    destroy(...destroyProps: any) {
      removeDatePicker(element);
    },
  };
};

const Progress = (
  handleProgressChange: (id: string, parentId: string, progress: number) => void
) => (element: HTMLElement, data: any) => {
  const handleChange = (e: any) => {
    handleProgressChange(
      data?.row.id,
      data?.row.parentId,
      Number(e.target.value)
    );
  };

  const addProgress = () => {
    if (data?.column?.id === "progressSelect") {
      const row = data?.row;

      const container = element.querySelector(".gstc__list-column-row-content");
      const progress = document.createElement("span");
      progress.innerHTML = row.progress;
      progress.style.display = !row.parentId ? "block" : "none";
      const select = document.createElement("select");
      select.name = row.id;
      select.style.display = row.parentId ? "block" : "none";

      select.addEventListener("change", handleChange);

      for (let i = 0; i <= 100; i += 10) {
        const option = document.createElement("option");
        option.value = i.toString();
        option.innerHTML = `${i}%`;
        option.selected = option.innerHTML == row.progress;
        select.appendChild(option);
      }
      if (container) {
        container.className = `${container.className} progress-select`;
        container.appendChild(select);
        container.appendChild(progress);
      }
    }
  };

  const removeProgress = (element: HTMLElement) => {
    if (data?.column?.id === "progressSelect") {
      const container = element.querySelector(".gstc__list-column-row-content");
      if (container) container.innerHTML = "";
    }
  };

  addProgress();

  return {
    update(element: HTMLElement, data: any) {
      if (data?.column?.id === "progressSelect") {
        const row = data?.row;
        const container = element.querySelector(
          ".gstc__list-column-row-content"
        );
        if (container) {
          const select = container.querySelector("select");
          const progress = container.querySelector("span");
          if (select && progress) {
            select.name = row.id;
            select.value = (
              Math.ceil(parseInt(row.progress) / 10) * 10
            ).toString();
            progress.style.display = !row.parentId ? "block" : "none";
            select.style.display = row.parentId ? "block" : "none";
            progress.innerHTML = row.progress;
          }
        }
      }
    },
    destroy() {
      removeProgress(element);
    },
  };
};

export const ColourIndicator = (element: HTMLElement, data: any) => {
  const addIndicator = () => {
    if (data?.column?.id === "colour") {
      const row = data?.row;
      const colour = row?.colour;

      const container = element.querySelector(".gstc__list-column-row-content");
      if (container) {
        container.setAttribute("style", `background-color: ${colour}`);
      }
    }
  };

  const removeIndicator = (element: HTMLElement) => {
    if (data?.column?.id === "colour") {
      const container = element.querySelector(".gstc__list-column-row-content");
      if (container) {
        container.removeAttribute("style");
      }
    }
  };

  addIndicator();

  return {
    update(element: HTMLElement, data: any) {
      removeIndicator(element);
      addIndicator();
    },
    destroy(...destroyProps: any) {
      removeIndicator(element);
    },
  };
};

const Checkbox = (handleSelect: (id: string) => void) => (
  element: HTMLElement,
  data: any
) => {
  const addCheckbox = () => {
    if (data?.column?.id === "checkbox") {
      const row = data?.row;
      const checked = row?.checked;

      const container = element.querySelector(".gstc__list-column-row-content");
      if (container) {
        const checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        checkbox.name = row.id;
        checkbox.checked = checked;
        checkbox.onclick = () => {
          handleSelect(checkbox.name);
        };
        container.appendChild(checkbox);
      }
    }
  };

  const removeCheckbox = (element: HTMLElement) => {
    if (data?.column?.id === "checkbox") {
      const container = element.querySelector(".gstc__list-column-row-content");
      if (container) {
        container.innerHTML = "";
      }
    }
  };

  addCheckbox();

  return {
    update(element: HTMLElement, data: any) {
      if (data?.column?.id === "checkbox") {
        const row = data?.row;
        const checked = row?.checked;
        const checkbox = element.querySelector("input");
        if (checkbox) {
          checkbox.name = row.id;
          checkbox.checked = checked;
        }
      }
    },
    destroy(...destroyProps: any) {
      removeCheckbox(element);
    },
  };
};

const ContextOuterAction = (handlers: ContextHandlers) => (
  element: HTMLElement,
  data: any
) => {
  const handleClick = (event: React.MouseEvent) => {
    handlers.handleClearContext(event);
  };

  element.addEventListener("click", handleClick as any);
  return {
    update(element: HTMLElement, data: any) {},
    destroy(element: HTMLElement, data: any) {
      element.removeEventListener("click", handleClick as any);
    },
  };
};

const ContextAction = (handlers: ContextHandlers) => (
  element: HTMLElement,
  data: any
) => {
  const handleContext = (event: React.MouseEvent) => {
    handlers.handleContextAction(event, data.row as CalendarTask);
  };
  const handleSelect = (event: React.MouseEvent) => {
    handlers.handleSelectContext(event, data.row as CalendarTask);
  };

  element.addEventListener("contextmenu", handleContext as any);
  element.addEventListener("click", handleSelect as any);
  return {
    update(element: HTMLElement, data: any) {},
    destroy(element: HTMLElement, data: any) {
      element.removeEventListener("contextmenu", handleContext as any);
      element.removeEventListener("click", handleSelect as any);
    },
  };
};

const ContextTimelineAction = (handlers: TimelineContextHandlers) => (
  element: HTMLElement,
  data: any
) => {
  const handleContext = (event: React.MouseEvent) => {
    handlers.handleTimelineContextAction(event);
  };
  const handleSelect = (event: React.MouseEvent) => {
    handlers.handleTimelineSelectContext(event);
  };

  element.addEventListener("contextmenu", handleContext as any);
  element.addEventListener("click", handleSelect as any);
  return {
    update(element: HTMLElement, data: any) {},
    destroy(element: HTMLElement, data: any) {
      element.removeEventListener("contextmenu", handleContext as any);
      element.removeEventListener("click", handleSelect as any);
    },
  };
};

export const createMovementConfig = (onMove: (tasks: Item[]) => void) => ({
  events: {
    onMove(props: any) {
      const { items } = props;
      // prevent items to change row
      return items.before.map((beforeMovementItem: any, index: number) => {
        const afterMovementItem = items.after[index];
        const myItem: any = GSTC.api.merge({}, afterMovementItem);
        myItem.rowId = beforeMovementItem.rowId; // lock to same row
        return myItem;
      });
    },
    onEnd(props: any) {
      const { items } = props;
      onMove(items.after);
      return items.after;
    },
  },
  snapToTime: {
    start(props: any) {
      const { startTime } = props;
      return startTime.startOf("day");
    },
    end(props: any) {
      const { endTime } = props;
      return endTime.endOf("day");
    },
  },
});

export const createSchedulePlugins = (
  t: TFunction,
  job: JobDetails,
  onMove: (tasks: Item[]) => void
) => [
  TimelinePointer(),
  Selection(),
  ItemResizing(createMovementConfig(onMove)),
  ItemMovement(createMovementConfig(onMove)),
  CalendarScroll(),
  HighlightWeekends(),
  DependencyLines({
    onLine: [
      (line) => {
        line.type = "square";
        return line;
      },
    ],
  }),
  TimeBookmarks({
    bookmarks: {
      today: {
        time: moment().startOf("day").valueOf(),
        label: t("schedule.today"),
      },
      jobStart: {
        time: moment(job.startDate).startOf("day").valueOf(),
        label: t("schedule.jobStart"),
        color: "green",
      },
      jobCompletion: {
        time: moment(job.endDate).startOf("day").valueOf(),
        label: t("schedule.jobCompletion"),
        color: "red",
      },
    },
  }),
];

export const createScheduleConfig = (
  t: TFunction,
  job: JobDetails,
  isReadonly: boolean = false,
  openTaskGroupModal: () => void,
  onUpdateTaskGroup: (id: string) => void,
  onCreateSubTask: (parentId: string) => void,
  onUpdateTask: (id: string, parentId?: string) => void,
  onNudge: (id: string, parentId?: string) => void,
  onDelete: (id: string, parentId?: string) => void,
  onTaskMove: (tasks: Item[]) => void,
  renderAvatar: (user?: User, supplier?: SupplierDetails) => ReactElement,
  handleProgressChange: (
    id: string,
    parentId: string,
    progress: number
  ) => void,
  handleSelect: (id: string) => void,
  handleSelectAll: (checked: boolean) => void,
  handleContext: ContextHandlers,
  renderDatePicker: (
    date: string,
    onDateSelect: (value: string) => void,
    values: {
      id: string;
      parentId: string;
      dateType: string;
    }
  ) => ReactElement,
  userDetailsRef: RefObject<UserDetailsModalRef>,
  handleTimelineContext: TimelineContextHandlers
): Config => {
  const data = !isReadonly
    ? {
        colour: {
          id: "colour",
          data: "",
          expander: false,
          width: 10,
          isHTML: false,
          header: {
            content: "",
          },
          resizer: null,
        },
        checkbox: {
          id: "checkbox",
          data: "",
          expander: false,
          width: 30,
          isHTML: true,
          resizer: null,
          header: {
            content(props: any) {
              const { vido } = props;
              const onClick = (e: ChangeEvent<HTMLInputElement>) =>
                handleSelectAll(e.target.checked);
              return vido.html`<input type="checkbox" @click=${onClick} />`;
            },
          },
        },
        avatar: {
          id: "avatar",
          data: "avatar",
          expander: false,
          width: 37,
          isHTML: false,
          header: {
            content: "",
          },
          resizer: null,
        },
        id: {
          id: "id",
          data: "name",
          expander: true,
          width: 200,
          isHTML: false,
          header: {
            content: t("schedule.taskName"),
          },
        },
        startDateSelect: {
          id: "startDateSelect",
          data: "startDateSelect",
          expander: false,
          isHTML: true,
          width: 100,
          header: {
            content: t("schedule.taskStartDate"),
          },
        },
        endDateSelect: {
          id: "endDateSelect",
          data: "endDateSelect",
          expander: false,
          isHTML: true,
          width: 100,
          header: {
            content: t("schedule.taskEndDate"),
          },
        },
        duration: {
          id: "duration",
          data: "duration",
          expander: false,
          isHTML: false,
          width: 100,
          header: {
            content: t("schedule.duration"),
          },
        },
        progressSelect: {
          id: "progressSelect",
          data: "progressSelect",
          expander: false,
          isHTML: true,
          width: 75,
          header: {
            content: t("schedule.progress"),
          },
        },
        action: {
          id: "action",
          data: () => "",
          expander: false,
          isHTML: true,
          width: 120,
          resizer: null,
          header: {
            content(props: any) {
              const { vido } = props;
              return vido.html`<button type="button" class="add-category btn btn-success btn-sm" @click=${openTaskGroupModal}>
            <i class="material-icons">add</i>Category
            </button>`;
            },
          },
        },
      }
    : {
        avatar: {
          id: "avatar",
          data: "avatar",
          expander: false,
          width: 47,
          isHTML: false,
          header: {
            content: "",
          },
          resizer: null,
        },
        id: {
          id: "id",
          data: "name",
          expander: true,
          width: 130,
          isHTML: false,
          header: {
            content: t("schedule.taskName"),
          },
        },
        // name: {
        //   id: "name",
        //   data: "name",
        //   width: 180,
        //   expander: false,
        //   isHTML: false,
        //   header: {
        //     content: t("schedule.taskName"),
        //   },
        // },
        startDate: {
          id: "startDate",
          data: "startDate",
          expander: false,
          isHTML: false,
          width: 100,
          header: {
            content: t("schedule.taskStartDate"),
          },
        },
        endDate: {
          id: "endDate",
          data: "endDate",
          expander: false,
          isHTML: false,
          width: 100,
          header: {
            content: t("schedule.taskEndDate"),
          },
        },
        duration: {
          id: "duration",
          data: "duration",
          expander: false,
          isHTML: false,
          width: 100,
          header: {
            content: t("schedule.duration"),
          },
        },
      };

  return merge(
    {
      // height: window.innerHeight - 270,
      //innerHeight: window.innerHeight - 230,
      list: {
        columns: {
          data: data,
        },
      },
      actions: !isReadonly
        ? {
            "list-column-row": [
              ColourIndicator,
              Checkbox(handleSelect),
              StartDateInput(renderDatePicker),
              Avatar(renderAvatar, userDetailsRef),
              Progress(handleProgressChange),
              TaskActions(
                onUpdateTaskGroup,
                onCreateSubTask,
                onUpdateTask,
                onNudge,
                onDelete
              ),
            ],
            "chart-timeline": [
              ContextTimelineAction(handleTimelineContext),
              ContextOuterAction(handleContext),
            ],
            "chart-timeline-items-row-item": [ContextAction(handleContext)],
          }
        : undefined,
      plugins: !isReadonly
        ? createSchedulePlugins(t, job, onTaskMove)
        : undefined,
    },
    defaultConfig
  );
};
