import "pdfjs-dist/build/pdf.worker.entry";
import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import JobSchedule from "../job-schedule";
import JobScheduleImporter from "../job-schedule-importer";
import JobCosting from "../job-costing";
import JobCostingImporter from "../job-costing-importer";
import JobSiteDiary from "../job-site-diary";
import JobDocuments from "../job-documents";
import JobClaims from "../job-claims";
import JobVariations from "../job-variations";
import JobPurchaseOrder from "../job-purchase-orders";
import { useQuery } from "@apollo/client";
import { GET_JOB } from "../../../graphql/queries/job/queries";
import { JobResponse } from "../../../graphql/types/models/job";
import JobOverview from "../job-overview";
import JobMessages from "../job-messages";
import JobTimesheets from "../job-timesheets";
import JobReports from "../job-reports";
import JobRoster from "../job-roster";
import JobQuoteRequest from "../job-quote-requests";
import { UserRoles } from "../../../models/team-member";
import { JobInvoiceType } from "../../../models/job";

type Params = {
  id: string;
};

type JobsContainerProps = RouteComponentProps<Params> &
  DashboardContextValue & { role: string };

const JobsContainer: React.FC<JobsContainerProps> = ({
  navigationContext,
  setNavigationContext,
  match,
  role,
}) => {
  const { id: jobId } = match.params;

  const { data: jobData, loading: jobLoading } = useQuery<JobResponse>(
    GET_JOB,
    {
      variables: {
        jobId,
      },
    }
  );

  React.useEffect(() => {
    const job = jobData?.getJob;
    setNavigationContext({
      ...navigationContext,
      job: {
        _id: jobId,
        name: job?.name || "",
        contractTotal: job?.contractTotal || 0,
        contractTotalGST: job?.contractTotalGST || 0,
        businessName: job?.business?.name || "",
        customer: job?.customer || undefined,
        isLocked: job?.is_locked || false,
        isContractLocked: job?.is_contract_locked || false,
        isRetention: job?.invoiceType === JobInvoiceType.Retention || false,
        salesQuote: job?.salesQuote,
        type: job?.type,
        jobNumber: job?.jobNumber,
      },
    });
  }, [setNavigationContext, jobId, jobData]);

  React.useEffect(() => {
    return () => {
      setNavigationContext({
        ...navigationContext,
        job: undefined,
      });
    };
  }, []);

  const basicRoutes = React.useMemo(
    () => [
      <Route
        key="job-schedule"
        exact
        path="/jobs/:id/schedule"
        component={JobSchedule}
      />,
      <Route
        key="job-site-diaries"
        exact
        path="/jobs/:id/site-diary"
        component={JobSiteDiary}
      />,
      <Route
        key="job-site-diary"
        path="/jobs/:id/site-diary/:diaryId"
        component={JobSiteDiary}
      />,
      <Route
        key="job-messages"
        exact
        path="/jobs/:id/messages"
        component={JobMessages}
      />,
      <Route
        key="job-message"
        path="/jobs/:id/messages/:channelId"
        component={JobMessages}
      />,
      <Route
        key="job-documents"
        exact
        path="/jobs/:id/documents"
        component={JobDocuments}
      />,
      <Route
        key="job-document-folder"
        path="/jobs/:id/documents/:folderId"
        component={JobDocuments}
      />,
      <Route key="job" path="/jobs/:id" component={JobOverview} />,
    ],
    []
  );

  const routes = React.useMemo(() => {
    if (role !== UserRoles.basic) {
      return [
        <Route
          key="schedule-import"
          path="/jobs/:id/schedule/import"
          component={JobScheduleImporter}
        />,
        <Route
          key="job-costings"
          exact
          path="/jobs/:id/costings"
          component={JobCosting}
        />,
        <Route
          key="job-costings-import"
          path="/jobs/:id/costings/import"
          component={JobCostingImporter}
        />,
        <Route
          key="job-claims"
          exact
          path="/jobs/:id/progress-claims"
          component={JobClaims}
        />,
        <Route
          key="job-claim"
          path="/jobs/:id/progress-claims/:claim_id"
          component={JobClaims}
        />,
        <Route
          key="job-variations"
          exact
          path="/jobs/:id/variations"
          component={JobVariations}
        />,
        <Route
          key="job-variation"
          path="/jobs/:id/variations/:var_id"
          component={JobVariations}
        />,
        <Route
          key="job-orders"
          exact
          path="/jobs/:id/purchase-orders"
          component={JobPurchaseOrder}
        />,
        <Route
          key="job-order"
          path="/jobs/:id/purchase-orders/:po_id"
          component={JobPurchaseOrder}
        />,
        <Route
          key="job-quotes"
          exact
          path="/jobs/:id/quote-requests"
          component={JobQuoteRequest}
        />,
        <Route
          key="job-quote"
          path="/jobs/:id/quote-requests/:qr_id/subcontractor/:sub_id"
          component={JobQuoteRequest}
        />,
        <Route
          key="job-rosters"
          exact
          path="/jobs/:id/roster"
          component={JobRoster}
        />,
        <Route
          key="job-roster"
          path="/jobs/:id/roster/:roster_id"
          component={JobRoster}
        />,
        <Route
          key="job-timesheets"
          exact
          path="/jobs/:id/timesheets"
          component={JobTimesheets}
        />,
        <Route
          key="job-reports"
          exact
          path="/jobs/:id/reports"
          component={JobReports}
        />,
        ...basicRoutes,
      ];
    }

    return basicRoutes;
  }, [role, basicRoutes]);

  if (jobLoading) {
    return null;
  }

  if (!jobData?.getJob && !jobLoading) {
    return <Redirect to="/jobs" />;
  }

  if (!navigationContext?.job) return null;

  return <Switch>{routes}</Switch>;
};

export default withRouter(withDashboardContext(JobsContainer));
