import React from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";

import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
} from "../../../components/dashboard/table-card/utils";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import { TodoChecklistTemplateItem } from "../../../models/task";

type ChecklistTableProps = {
  items?: TodoChecklistTemplateItem[];
  tableTitle: string;
  isDataLoading: boolean;
  isLocked: boolean;
  isSelectedCategory: boolean;
  updateGroupPress: () => void;
};

const ChecklistTable: React.FC<ChecklistTableProps> = ({
  items,
  tableTitle,
  isDataLoading,
  isLocked,
  isSelectedCategory,
  updateGroupPress,
}) => {
  const { t } = useTranslation();

  const tasksTableData = React.useMemo<
    TableCardData<TodoChecklistTemplateItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "task",
          title: t("tasks.task"),
        },
      ],
      rows: map(items, (item) => ({
        cells: item,
      })),
    };
  }, [t, items]);

  const headerAction = React.useMemo<TableCardAction | undefined>(() => {
    if (isLocked) return;

    return {
      className: "button large info bg-transparent text-light",
      icon: "edit",
      disabled: !isSelectedCategory,
      onClick: updateGroupPress,
      title: t("common.edit"),
    };
  }, [t, isSelectedCategory, updateGroupPress, isLocked]);

  return items && items.length === 0 ? (
    <EmptyPlaceholder
      message={t("tasks.emptyTasksPlaceholder")}
      buttonText={t("common.edit")}
      onButtonPress={updateGroupPress}
    />
  ) : (
    <TableCard
      isDataLoading={isDataLoading}
      headerAction={headerAction}
      rowCount
      title={tableTitle}
      data={tasksTableData}
    />
  );
};

export default ChecklistTable;
