import classNames from "classnames";
import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../../models/pagination";

export enum PaginationButton {
  Previous = "Previous",
  Next = "Next",
}

type CardPaginationProps = {
  pagination: Pagination;
  onPagination: (type: PaginationButton) => void;
  className?: string;
};

const CardPagination: FC<CardPaginationProps> = ({
  pagination,
  onPagination,
  className,
}) => {
  const { t } = useTranslation();
  const handleListPagination = React.useCallback(
    (type: PaginationButton) => {
      onPagination(type);
    },
    [onPagination]
  );

  return (
    <div className={classNames("d-flex align-items-center", className)}>
      {pagination.totalPages > 0 && (
        <div>
          <span className="field-text mr-1">
            {t("pagination.pageOfTotal", pagination)}
          </span>
        </div>
      )}
      <Button
        className="button large large-wide info-reverse"
        onClick={() => handleListPagination(PaginationButton.Previous)}
        disabled={!pagination.hasPrevPage}
      >
        {t("common.previous")}
      </Button>
      <Button
        className="button large large-wide info-reverse"
        onClick={() => handleListPagination(PaginationButton.Next)}
        disabled={!pagination.hasNextPage}
      >
        {t("common.next")}
      </Button>
    </div>
  );
};

export default CardPagination;
