import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { chain, compact, filter, map } from "lodash";
import "./styles.scss";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import Message from "../message";
import Compose, { ComposeRef } from "../compose";
import Attachments from "../../attachments";
import { Message as ConversationMessage } from "../../../models/conversation";
import { resizeFiles } from "../../../utils/files";
import EmptyPlaceholder from "../../empty-placeholder";
import { Media } from "../../../models/media";
import { UserPayload } from "../../../graphql/types/models/auth";
import classNames from "classnames";

type ConversationProps = {
  messages?: ConversationMessage[]; // TODO: convert to generic type
  name: string;
  onSend: (message: string, file: File[] | null) => void;
  noAvatar?: boolean;
  disabled?: boolean;
  user?: UserPayload | null;
  onDeleteMessage?: (id: string) => void;
  subHeader?: () => JSX.Element;
};
export type ConversationRef = ComposeRef & {};

const Conversation: React.FC<ConversationProps> = (props, ref) => {
  const { t } = useTranslation();
  const {
    messages,
    name,
    onSend,
    noAvatar,
    disabled = false,
    user,
    onDeleteMessage,
    subHeader,
  } = props;

  const [files, setFiles] = useState<File[] | null>(null);

  const composeRef = React.useRef<ComposeRef>(null);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setFiles(null);
      composeRef?.current?.reset();
    },
  }));

  const handleFilesSelect = React.useCallback(
    async (acceptedFiles: File[]) => {
      const compressedFiles = await resizeFiles(acceptedFiles);
      const newFiles = chain(files as any)
        .concat(compressedFiles)
        .compact()
        .uniqBy((f) => [f.name, f.lastModified, f.size].join("."))
        .value();
      setFiles(newFiles);
    },
    [files, setFiles]
  );

  const handleFileRemove = useCallback(
    (fileToRemove: File | Media) => {
      setFiles(filter(files, (file) => file !== fileToRemove));
    },
    [files]
  );

  const clearFiles = useCallback(() => {
    setFiles(null);
  }, []);

  const handleSend = useCallback(
    (messsage) => {
      onSend && onSend(messsage, files);
    },
    [files, onSend]
  );

  const renderMessage = () => {
    if (!messages?.length) {
      return <EmptyPlaceholder message={t("communication.emptyPlaceholder")} />;
    }
    return map(messages, (msg) => {
      const handleOnDeleteMessage = () => onDeleteMessage?.(msg._id);
      const sender = msg?.sender;
      const isOwnMessage = sender._id === user?._id;

      const name = compact([
        sender.name,
        sender.first_name,
        sender.last_name,
      ]).join(" ");
      return (
        <Message
          key={msg._id}
          linkPreview={msg.linkPreview}
          full_name={name}
          content={msg.text}
          attachments={msg.media}
          createdAt={msg.createdAt}
          _id={msg._id}
          noAvatar={noAvatar}
          avatar_url={sender?.profileImage?.url}
          isOwnMessage={isOwnMessage}
          onDeleteMessage={handleOnDeleteMessage}
        />
      );
    });
  };

  return (
    <DashboardCard className="conversation">
      <DashboardCardHeader
        className={classNames("conversation-name", {
          "sub-header-height": subHeader,
        })}
      >
        <div className="chat-title">{name}</div>
        {subHeader && <div className="sub-header-container">{subHeader()}</div>}
      </DashboardCardHeader>
      <DashboardCardBody className="conversation-messages">
        {renderMessage()}
      </DashboardCardBody>
      <DashboardCardFooter className="conversation-footer">
        <Attachments
          files={files}
          onClear={clearFiles}
          onRemove={handleFileRemove}
        />
        {!disabled && (
          <Compose
            ref={composeRef}
            onFilesSelect={handleFilesSelect}
            placeholder={t("placeholders.writeMessage")}
            onSend={handleSend}
          />
        )}
      </DashboardCardFooter>
    </DashboardCard>
  );
};

export default forwardRef(Conversation);
