import { gql } from "@apollo/client";

export const LIST_QUOTES = gql`
  query($filter: SalesQuoteFilterInput) {
    listSalesQuotes(filter: $filter) {
      _id
      name
      address
      city
      state
      postcode
      number
      subTotal
      GST
      total
      createdAt
      updatedAt
      status
      markup
      is_archived
      is_locked
      job {
        _id
        jobNumber
        name
        address
        city
        city_normalized
        state
        postcode
        startDate
        endDate
        markup
        type
        invoiceToJobAddress
      }
      contact {
        _id
        first_name
        last_name
        address
        address2
        city
        state
        postcode
      }
      files {
        _id
        name
      }
    }
  }
`;

export const GET_SALES_QUOTE = gql`
  query getSalesQuote($salesQuoteId: ID!) {
    getSalesQuote(salesQuoteId: $salesQuoteId) {
      _id
      name
      address
      city
      state
      postcode
      number
      include_costing
      terms
      status
      total
      GST
      subTotal
      markup
      is_archived
      is_locked
      costingCategories {
        _id
        name
        costings {
          _id
          name
          UOM
          specCategoryItem {
            _id
          }
        }
      }
      job {
        _id
        name
      }
      contact {
        _id
        email
        first_name
        last_name
        phone
        phone_secondary
        is_invited
      }
      business {
        _id
        name
      }
      files {
        _id
        type
        size
        name
        url
        upload_url
        calibration {
          lat
          lng
          horizontal
          scale
          sheet
        }
      }
      printTemplate {
        _id
        name
      }
    }
  }
`;

export const QUOTE_PREVIEW = gql`
  query PreviewSalesQuote($salesQuoteId: ID!) {
    previewSalesQuote(salesQuoteId: $salesQuoteId) {
      _id
      pdf
    }
  }
`;

export const EXPORT_SALES_QUOTE_COSTINGS = gql`
  query($salesQuoteId: ID!) {
    exportSalesQuoteCostings(salesQuoteId: $salesQuoteId) {
      _id
      data
      filename
    }
  }
`;

export const SALES_QUOTE_TAKE_OFF_TEMPLATES = gql`
  query ListSalesQuoteTakeOffTemplates {
    listSalesQuoteTakeOffTemplates {
      _id
      name
      updatedAt
      business {
        _id
      }
      name
      takeOffs {
        _id
        name
        properties {
          color
        }
        UOM
      }
    }
  }
`;
