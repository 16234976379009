import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { chain } from "lodash";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Container from "react-bootstrap/Container";
import { notify } from "../../../components/notification";
import {
  BulkSalesQuoteCostingItem,
  SaveSalesQuoteCostingBulkResponse,
} from "../../../graphql/types/models/category";
import { SAVE_SALES_QUOTE_COSTING_BULK } from "../../../graphql/queries/category/mutations";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { PriceListUOM } from "../../../models/price-list";
import CostingImport from "../../../components/costing/costing-import";
import { FIELD_MAP_QUOTE_COSTING } from "../../../components/costing/costing-import/constants";
import { SalesQuoteCostingItemImportMap } from "../../../components/costing/costing-import/types";

const QuoteCostingImportContainer: React.FC<DashboardContextValue> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const salesQuoteId = navigationContext?.quote?._id;

  const [
    saveCostingItems,
    { loading: isLoadingSaveCostingItems },
  ] = useMutation<SaveSalesQuoteCostingBulkResponse>(
    SAVE_SALES_QUOTE_COSTING_BULK,
    {
      onCompleted: () => {
        history.push(`/quotes/${salesQuoteId}/costing`);

        notify({
          title: t("costing.importCostings"),
          content: t("costing.success.importCostings"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: t("costing.importCostings"),
          content: t("costing.errors.importCostings"),
        });
      },
    }
  );

  const handleMapSubmit = React.useCallback(
    async (values: SalesQuoteCostingItemImportMap[]) => {
      if (!salesQuoteId) return;

      const costItems: BulkSalesQuoteCostingItem[] = chain(values)
        .filter((item) => !!item.name)
        .map((item, i) => ({
          name: item.name || "",
          note: item.note || "",
          category: item.category || t("costing.uncategorised"),
          UOM: item.UOM || PriceListUOM.QTY || "",
          cost: parseFloat(item.cost?.toString() || "") || 0,
          quantity: parseFloat(item.quantity || "") || 0,
          raw_quantity: (parseFloat(item.quantity || "") || 0).toString(),
          margin_amount: parseFloat(item.margin_amount || "") || 0,
          is_allowance: /(y|1)/i.test(item.is_allowance || ""),
          hasGST: !/(y|1)/i.test(item.gstFree || ""),
          order: i,
        }))
        .value();

      if (costItems.length > 0) {
        saveCostingItems({
          variables: {
            salesQuoteId,
            costItems,
          },
        });
      }
    },
    [salesQuoteId]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.quotesSection.importCostings")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.QUOTES_SECTION.IMPORT_COSTING}
      />
      <CostingImport
        fieldMap={FIELD_MAP_QUOTE_COSTING}
        onImport={handleMapSubmit}
        isLoading={isLoadingSaveCostingItems}
      />
    </Container>
  );
};

export default withDashboardContext(QuoteCostingImportContainer);
