import { TFunction } from "i18next";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import { Business } from "../../../../graphql/types/models/business";
import { PDF_TOOLBAR_BASIC } from "../../../generic-form/inputs/rich-editor/configs";

const createPurchaseOrderFields = (
  t: TFunction
): GenericFormFields<Business> => {
  return {
    orderTerms: [
      {
        type: "rich-editor",
        label: t("company.orderTerms"),
        valueKey: "richOrderTerms.html",
        richEditorProps: {
          textKey: "richOrderTerms.text",
          rawKey: "richOrderTerms.raw",
          toolbarOptions: PDF_TOOLBAR_BASIC,
        },
      },
    ],
  };
};

export default createPurchaseOrderFields;
