import { gql } from "@apollo/client";

export const CLIENT_LIST_CLAIMS = gql`
  query ClientGetJobProgressClaims($jobId: ID!) {
    clientGetJobProgressClaims(jobId: $jobId) {
      _id
      status
      invNumber
      amount
    }
  }
`;

export const CLIENT_GET_CLAIM = gql`
  query ClientGetJobProgressClaimById($jobId: ID!, $progressId: ID!) {
    clientGetJobProgressClaimById(jobId: $jobId, progressId: $progressId) {
      _id
      invNumber
      status
      description
      comment
      amount
      variationAmount
      percentage
      createdAt
      claimDate
      invoiceSubTotal
      invoiceGST
      invoiceTotal
      retainedAmount
      dueDate
      receipts {
        _id
        reference
        dateReceived
        note
        amount
      }
      variations {
        _id
        subTotal
        total
        GST
        date
        name
        variationNumber
      }
      contact {
        _id
        first_name
        last_name
        email
      }
    }
  }
`;

export const GET_CLIENT_CLAIM_PREVIEW = gql`
  query clientGetProgressClaimPreview($jobId: ID!, $progressId: ID!) {
    clientGetProgressClaimPreview(jobId: $jobId, progressId: $progressId) {
      _id
      pdf
    }
  }
`;
