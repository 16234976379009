import { gql } from "@apollo/client";
import { ASSEMBLY_FRAGMENT } from "./fragments";

export const CREATE_ASSEMBLY = gql`
  mutation CreateAssembly(
    $name: String
    $UOM: String
    $total: Float
    $items: [AssemblyItemInput]
  ) {
    createAssembly(
      assembly: { name: $name, UOM: $UOM, total: $total, items: $items }
    ) {
      ...AssemblyFragment
    }
  }
  ${ASSEMBLY_FRAGMENT}
`;

export const DELETE_ASSEMBLY = gql`
  mutation deleteAssembly($assemblyId: ID!) {
    deleteAssembly(assemblyId: $assemblyId) {
      ...AssemblyFragment
    }
  }
  ${ASSEMBLY_FRAGMENT}
`;

export const CREATE_UPDATE_ASSEMBLY = gql`
  mutation createUpdateAssembly($assembly: AssemblyInput!) {
    createUpdateAssembly(assembly: $assembly) {
      ...AssemblyFragment
    }
  }
  ${ASSEMBLY_FRAGMENT}
`;
