import React from "react";

export type PlanViewerContextOptions = {
  isHorizontal: boolean;
  onMapRotate: (isHorizontal?: boolean) => void;
};

export const PlanViewerContext = React.createContext<PlanViewerContextOptions>(
  null as any
);

export function withPlanViewer<P extends {}>(
  Component: React.FC<P & PlanViewerContextOptions>
) {
  return (props: P) => (
    <PlanViewerContext.Consumer>
      {(value) => <Component {...value} {...props} />}
    </PlanViewerContext.Consumer>
  );
}
