import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { get, map, omit } from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";

import { notify } from "../../../../components/notification";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../../components/dashboard/table-card";
import {
  TableCardAction,
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../../../components/dashboard/table-card/utils";
import ConfirmDialog from "../../../../components/confirm-dialog";
import { LIST_SITE_DIARY_TEMPLATES } from "../../../../graphql/queries/site-diary/queries";
import SiteDiaryTemplateModal, {
  SiteDiaryTemplateModalRef,
} from "../../../../components/settings/site-diary/add-site-diary";
import {
  SiteDiaryTemplate,
  SiteDiaryTemplateCreatePayload,
  SiteDiaryTemplateCreateResponse,
  SiteDiaryTemplateDeletePayload,
  SiteDiaryTemplateDeleteResponse,
  SiteDiaryTemplateListResponse,
  SiteDiaryTemplateType,
} from "../../../../graphql/types/models/site-diary";
import {
  CREATE_SITE_DIARY_TEMPLATE,
  DELETE_SITE_DIARY_TEMPLATE,
} from "../../../../graphql/queries/site-diary/mutations";

const SiteDiaryTemplateOverview: React.FC = (props) => {
  const { t } = useTranslation();

  const modalRef = React.useRef<SiteDiaryTemplateModalRef>(null);

  const {
    data: siteDiaryTemplates,
    loading: dataLoading,
    refetch: refetchSiteDiaryTemplates,
  } = useQuery<SiteDiaryTemplateListResponse>(LIST_SITE_DIARY_TEMPLATES);

  const [createSiteDiaryTemplate] = useMutation<
    SiteDiaryTemplateCreateResponse,
    SiteDiaryTemplateCreatePayload
  >(CREATE_SITE_DIARY_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        title: t("siteDiary.saveTemplate"),
        content: t("siteDiary.success.saveTemplate"),
      });
      modalRef.current?.show(false);
      refetchSiteDiaryTemplates();
    },
    onError: (e) => {
      notify({
        error: true,
        title: t("siteDiary.saveTemplate"),
        content: get(e, "message", t("siteDiary.error.saveTemplate")),
      });
    },
  });

  const [deleteSiteDiaryTemplate, { loading: memberDeleting }] = useMutation<
    SiteDiaryTemplateDeleteResponse,
    SiteDiaryTemplateDeletePayload
  >(DELETE_SITE_DIARY_TEMPLATE, {
    onCompleted: (data) => {
      refetchSiteDiaryTemplates();
      notify({
        title: t("siteDiary.removeTemplate"),
        content: t("siteDiary.success.removeTemplate"),
      });
      setRemoveConfirmVisibility(false);
      setDeleteTarget("");
    },
    onError: () => {
      notify({
        error: true,
        title: t("siteDiary.removeTemplate"),
        content: t("siteDiary.error.removeTemplate"),
      });
    },
  });

  const [showRemoveConfirmDialog, setRemoveConfirmVisibility] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState("");
  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget("");
    setRemoveConfirmVisibility(false);
  }, []);

  const openRemoveDialog = React.useCallback((row?: SiteDiaryTemplate) => {
    if (row) {
      setDeleteTarget(row._id);
      setRemoveConfirmVisibility(true);
    }
  }, []);

  const handleCloneTemplate = React.useCallback((row?: SiteDiaryTemplate) => {
    if (row) {
      const cloned = {
        ...omit(row, ["_id", "fields", "type"]),
        fields: map(row.fields, (field) => ({
          ...omit(field, ["_id", "subFields"]),
          subFields: map(field.subFields, (subField) =>
            omit(subField, ["_id"])
          ),
        })),
      } as SiteDiaryTemplate;

      modalRef.current?.showTemplate(cloned);
    }
  }, []);

  const handleDeleteConfirmed = React.useCallback(() => {
    if (!deleteTarget) {
      return;
    }
    deleteSiteDiaryTemplate({
      variables: {
        templateId: deleteTarget,
      },
    });
  }, [deleteTarget]);

  const handleAddSubmit = React.useCallback((values: SiteDiaryTemplate) => {
    createSiteDiaryTemplate({
      variables: {
        template: values,
      },
    });
  }, []);

  const siteDiaryTemplateData: TableCardData<SiteDiaryTemplate> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("siteDiary.template"),
        },
        {
          valueKey: "type",
          title: t("siteDiary.type"),
          formatValue: (row: any, column: any, value: string) =>
            value === SiteDiaryTemplateType.Custom
              ? t("common.custom")
              : t("common.system"),
        },
        {
          valueKey: "updatedAt",
          title: t("siteDiary.lastUpdated"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
      ],
      rows: map(
        siteDiaryTemplates?.listSiteDiaryTemplates,
        (siteDiaryTemplate: SiteDiaryTemplate) => ({
          cells: siteDiaryTemplate,
        })
      ),
    };
  }, [siteDiaryTemplates]);

  const tableRowActions: TableRowActionData<
    SiteDiaryTemplate
  >[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "price-list",
        options: [
          {
            icon: "content_copy",
            outlined: true,
            id: "clone",
            label: t("common.clone"),
            onClick: handleCloneTemplate,
          },
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openRemoveDialog,
            shouldRender: (row: SiteDiaryTemplate) =>
              row.type === SiteDiaryTemplateType.Custom,
          },
        ],
      },
    ],
    [openRemoveDialog, handleCloneTemplate]
  );

  const tableActions = React.useMemo<TableCardAction[]>(
    () => [
      {
        title: t("siteDiary.addTemplate"),
        onClick: () => modalRef.current?.show(true),
        icon: "add",
        className: "button large large-wide success",
      },
    ],
    [modalRef]
  );

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<SiteDiaryTemplate>) => {
      modalRef.current?.showTemplate(row.cells);
    },
    [modalRef]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.templatesSection.siteDiaries")} />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SITE_DIARY_TEMPLATES
        }
      />

      <ConfirmDialog
        disabled={memberDeleting}
        show={showRemoveConfirmDialog}
        onClose={closeRemoveDialog}
        onSubmit={handleDeleteConfirmed}
        title={t("siteDiary.removeTemplate")}
        confirm={t("common.delete")}
      >
        <div className="field-text">{t("siteDiary.removeTemplateMessage")}</div>
      </ConfirmDialog>

      <SiteDiaryTemplateModal ref={modalRef} onSubmit={handleAddSubmit} />

      <TableCard
        isDataLoading={dataLoading}
        fullHeight
        overflowXHidden
        data={siteDiaryTemplateData}
        actions={tableActions}
        alignEnd
        rowActions={tableRowActions}
        onRowClick={handleRowClick}
      />
    </Container>
  );
};

export default SiteDiaryTemplateOverview;
