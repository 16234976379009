import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import { PrimaryFolder } from "../../../models/documents";
import Icon from "../../../components/icons/Icon";

type DocumentHeaderProps = {
  onUploadClick: () => void;
  onAddNewFolderClick: () => void;
  selectFolder: (id: string) => void;
  onCopyContent?: () => void;
  onEditFolder?: () => void;
  selectRootFolders: () => void;
  openRemoveDialogForSelectedItems: () => void;
  parentTree: PrimaryFolder[] | null;
  quantityOfSelectedItems: number;
  rootName?: string;
  isReadonly?: boolean;
  isDisabledHeaderCopyContent?: boolean;
};

const DocumentsHeader: React.FC<DocumentHeaderProps> = (props) => {
  const {
    onUploadClick,
    selectFolder,
    selectRootFolders,
    onAddNewFolderClick,
    openRemoveDialogForSelectedItems,
    parentTree,
    quantityOfSelectedItems,
    rootName,
    isReadonly,
    onCopyContent,
    isDisabledHeaderCopyContent,
    onEditFolder,
  } = props;

  const { t } = useTranslation();

  return (
    <DashboardCardHeader>
      <Button
        className={"button breadcrumb-buttons"}
        onClick={selectRootFolders}
      >
        {rootName || "Root"}
      </Button>
      {parentTree &&
        parentTree.map((folder) => (
          <React.Fragment key={folder._id}>
            <span className="breadcrumb-delimiter">/</span>
            <Button
              onClick={() => selectFolder(folder._id)}
              className={"button breadcrumb-buttons"}
            >
              {`${folder.name}`}
            </Button>
          </React.Fragment>
        ))}
      {!isReadonly && (
        <div className="documents-button-wrapper">
          <Button
            className="delete-button button large danger text-light"
            onClick={openRemoveDialogForSelectedItems}
            disabled={quantityOfSelectedItems === 0}
          >
            <Icon name={"delete"} />
            {t("documents.deleteSelected")}
          </Button>
          {onCopyContent && (
            <Button
              className="button large danger text-light"
              onClick={onCopyContent}
              disabled={isDisabledHeaderCopyContent}
            >
              <Icon name={"content_copy"} />
              {t("documents.copyContents")}
            </Button>
          )}
          <Button
            className="button large info bg-transparent text-light"
            onClick={onAddNewFolderClick}
          >
            <Icon name={"create_new_folder"} />
            {t("documents.newFolder")}
          </Button>
          <Button
            className="button large info bg-transparent text-light"
            onClick={onUploadClick}
            disabled={!parentTree}
          >
            <Icon name={"file_upload"} />
            {t("documents.uploadFiles")}
          </Button>
          {onEditFolder && (
            <Button
              className="button info bg-transparent text-light"
              onClick={onEditFolder}
              disabled={!parentTree}
            >
              <Icon name={"edit"} />
              {t("common.edit")}
            </Button>
          )}
        </div>
      )}
    </DashboardCardHeader>
  );
};

export default DocumentsHeader;
