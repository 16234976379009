import { TFunction } from "i18next";
import { NotePayload } from "../../../graphql/types/models/note";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

const createNoteFields = (t: TFunction): GenericFormFields<NotePayload> => {
  return {
    description: {
      type: "text",
      controlType: "textarea",
      label: t("note.note"),
      placeholder: t("note.noteDetails"),
      valueKey: "description",
      inputProps: {
        type: "text",
        required: true,
        rows: 6,
      },
    },
  };
};

export default createNoteFields;
