import React, { useRef, useState } from "react";
import GSTC, {
  GSTCResult,
} from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import "gantt-schedule-timeline-calendar/dist/style.css";
import "./styles.scss";
import { useWindowSize } from "../../hooks/useWindowSize";

export type CalendarWrapperProps = {
  state: any;
  onCreate?: (app: any) => void;
  isDisabled?: boolean;
};

const CalendarWrapper: React.FC<CalendarWrapperProps> = (props) => {
  const { state, onCreate, isDisabled = false } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [gstcApp, setApp] = useState<GSTCResult | null>(null);
  const [width, height] = useWindowSize();

  const initApp = () => {
    if (!wrapperRef.current) return;
    // @ts-ignore
    const app = GSTC({
      element: wrapperRef.current,
      state,
    });

    // prevent calendar from overflowing size
    // wrapperRef.current.style.width = `${wrapperRef.current.clientWidth}px`;
    // wrapperRef.current.style.width = `${window.innerWidth - 145}px`;

    onCreate && !isDisabled && onCreate(app);
    setApp(app);
  };

  React.useEffect(() => {
    if (!wrapperRef.current || !!gstcApp) {
      return;
    }

    initApp();
  }, [state, gstcApp]);

  React.useEffect(() => {
    return () => {
      if (!!gstcApp) {
        // @ts-ignore
        gstcApp?.app?.destroy();
      }
    };
  }, [gstcApp]);

  React.useEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.width = `${width - 155}px`;
    wrapperRef.current.style.height = `${height - 170}px`;
  }, [width, height]);

  return <div ref={wrapperRef} className="calendar"></div>;
};

export default CalendarWrapper;
