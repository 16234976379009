import {
  AppendDynamicPropsFn,
  AppendOnClickFn,
  FormikPropGetSetValues,
  GenericFormFields,
  UpdateRowFn,
} from "../../generic-form/GenericFormBody";
import { TFunction } from "i18next";
import { calcTotalTimesheetValue } from "../../../utils/calculations";
import {
  ProcessTimesheetModalFormItem,
  ProcessTimesheetModalForm,
} from "../../../models/job-timesheet";

import { GenericRecord } from "../../../utils/types/options";
import { getBreakDurationOptions } from "../../../utils/options";

const createProcessFields = (
  t: TFunction,
  costingCategoryList: GenericRecord[] | undefined,
  openAllocateCosting: AppendOnClickFn,
  handleCostingCreate: (costing: string) => void,
  handleDateChange: UpdateRowFn,
  handleCategorySelect: UpdateRowFn,
  getAllocateProps: AppendDynamicPropsFn,
  isCostingCategoriesLoading?: boolean,
  hendleMenuOpen?: (
    formikProps?: FormikPropGetSetValues,
    rowIndex?: number
  ) => void
): GenericFormFields<ProcessTimesheetModalForm> => {
  return {
    items: {
      tableTitle: t("jobTimesheets.timesheetItems"),
      isDisabled: true,
      isCompact: true,
      row: [
        {
          width: "200px",
          type: "text",
          controlType: "textarea",
          label: t("jobTimesheets.task"),
          placeholder: t("jobTimesheets.task"),
          valueKey: "task",
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
            rows: 1,
            debounceChangeEvent: true,
          },
        },
        {
          type: "date-picker",
          label: t("timesheets.startDate"),
          placeholder: t("timesheets.startDate"),
          valueKey: "startTime",
          updateTableRow: handleDateChange,
          inputProps: {
            required: true,
            className: "form-input-compact",
          },
          datePickerProps: {
            showTimeSelect: true,
            calendarClassName: "datetime-picker",
            buttonClassName: "input-group-append-compact",
          },
        },
        {
          type: "date-picker",
          label: t("timesheets.endDate"),
          placeholder: t("timesheets.endDate"),
          valueKey: "endTime",
          updateTableRow: handleDateChange,
          inputProps: {
            required: true,
            className: "form-input-compact",
          },
          datePickerProps: {
            showTimeSelect: true,
            calendarClassName: "datetime-picker",
            buttonClassName: "input-group-append-compact",
          },
        },
        {
          width: "120px",
          type: "select-autocomplete",
          label: t("timesheets.break"),
          valueKey: "breakDuration",
          autocompleteProps: {
            options: getBreakDurationOptions(t),
            className: "form-input-compact",
          },
        },
        {
          width: "150px",
          type: "text",
          label: t("jobTimesheets.duration"),
          placeholder: t("jobTimesheets.duration"),
          valueKey: "duration",
          inputProps: {
            type: "text",
            required: true,
            readOnly: true,
            className: "form-input-compact",
          },
        },
        {
          width: "80px",
          maxWidth: "80px",
          type: "text",
          label: t("jobTimesheets.rate"),
          placeholder: t("jobTimesheets.rate"),
          valueKey: "rate",
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
          },
        },
        {
          width: "200px",
          type: "creatable-select",
          label: t("costing.costingCategory"),
          placeholder: t("placeholders.category"),
          valueKey: "costingCategoryName",
          updateTableRow: handleCategorySelect,
          creatableProps: {
            onMenuOpen: hendleMenuOpen,
            autoSelectCreatedOption: true,
            options: costingCategoryList,
            onCreateOption: handleCostingCreate,
            isValidNewOption: (v: string) => v.length > 0,
            isClearable: true,
            isLoading: isCostingCategoriesLoading,
          },
          inputProps: {
            type: "text",
            className: "form-input-compact",
            required: true,
          },
          append: {
            onClick: openAllocateCosting,
            className: "input-group-append-compact",
            dynamicProps: getAllocateProps,
          },
        },
      ],
      dynamicFields: [
        {
          title: t("jobTimesheets.total"),
          formatValue: (values: ProcessTimesheetModalFormItem) => {
            const amount = calcTotalTimesheetValue(
              values.rate,
              values.startTime,
              values.endTime,
              Number(values.breakDuration)
            );
            return t("common.currency", { amount });
          },
        },
      ],
    },
  };
};

export default createProcessFields;
