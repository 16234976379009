import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import "./styles.scss";
import createSignInFormSchema from "./SignInForm.schema";
import AuthenticationCard from "../authentication-card";
import SignInFormBody from "./SignInFormBody";
import SignInFormFooter from "./SignInFormFooter";
import Context, { SiteContext } from "../../../siteContext";

type SignInFormProps = {
  onSubmit: (
    credentials: { email: string; password: string },
    rememberMe: boolean
  ) => void;
};

const SignInForm: React.FC<SignInFormProps> = ({ onSubmit }) => {
  const { isSubcontractor, isAdmin, isClient, isAffiliate } = React.useContext(
    Context
  ) as SiteContext;

  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: createSignInFormSchema(t),
    onSubmit: (values) => {
      return onSubmit(
        {
          email: values["email"],
          password: values["password"],
        },
        values["rememberMe"]
      );
    },
  });

  const title = React.useMemo(() => {
    if (isClient) return t("authentication.clientSignIn");
    else if (isSubcontractor) return t("authentication.subcontractorSignIn");
    else if (isAffiliate) return t("authentication.affiliateSignIn");
    return t("authentication.builderSignIn");
  }, [isClient, isSubcontractor, isAffiliate]);

  return (
    <AuthenticationCard
      title={title}
      container={Form}
      containerProps={{
        onSubmit: handleSubmit,
        noValidate: true,
        className: "authentication-card-container sign-in-card",
      }}
      body={SignInFormBody}
      bodyProps={{
        t,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }}
      footer={SignInFormFooter}
      footerProps={{
        t,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
      }}
    />
  );
};

export default SignInForm;
