import * as yup from "yup";
import { TFunction } from "i18next";
import {
  ValidationMessagesUtils,
  ValidationRules,
} from "../../../../utils/yup";
import { Business } from "../../../../graphql/types/models/business";

export default function editCompanySchema(t: TFunction) {
  return yup.object().shape<Business>({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "company.companyName")
      ),
    email: yup
      .string()
      .email()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "contacts.email")
      ),
    phone_number: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "company.phone")
      ),
    abn: yup
      .string()
      .matches(/^[0-9]+$/, t("errors.invalidABN"))
      .length(11, t("errors.invalidABN")),
    address: yup.string(),
    address2: yup.string(),
    city: yup.string(),
    state: yup.string(),
    postcode: ValidationRules.getPostcodeRules(yup, t),
  });
}
