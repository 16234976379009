import React from "react";
import { TFunction } from "react-i18next";
import { JobCostingTableItem } from "..";
import { TableRowActionData } from "../../../../components/dashboard/table-card/utils";
import ListTimesheetsModal, {
  ListTimesheetsModalRef,
} from "../../../../components/job-timesheets/list-timesheets-modal";
import { getTimesheetItems } from "../utils";

export function useTimesheet(t: TFunction, jobId?: string) {
  const timesheetRef = React.useRef<ListTimesheetsModalRef>(null);

  const timesheetRowActions = React.useMemo<
    TableRowActionData<JobCostingTableItem>
  >(
    () => ({
      icon: "schedule",
      counter: (row: JobCostingTableItem) => {
        return getTimesheetItems(row).length;
      },
      title: (row: JobCostingTableItem) =>
        t("costing.timesheetCreatedWithCount", {
          count: getTimesheetItems(row).length,
        }),
      dropdownId: "timesheet-item",
      onClick: (row: JobCostingTableItem) => {
        const data = getTimesheetItems(row);
        if (data?.length) {
          timesheetRef.current?.setData(row.name, data);
          timesheetRef.current?.show(true);
        }
      },
      shouldRender: (row: JobCostingTableItem) =>
        getTimesheetItems(row).length > 0,
    }),
    [jobId]
  );

  const renderTimesheetModal = React.useCallback(() => {
    return <ListTimesheetsModal ref={timesheetRef} />;
  }, [timesheetRef]);

  return {
    timesheetRowActions,
    renderTimesheetModal,
  };
}
