import { gql } from "@apollo/client";
import { JOB_CONTACT } from "./fragments";

export const LIST_JOB_CONTACTS = gql`
  query GetJobContacts($jobId: ID!) {
    jobContacts: getJobContacts(jobId: $jobId) {
      ...JobContact
    }
  }
  ${JOB_CONTACT}
`;
