import { UserPayload } from "../../graphql/types/models/auth";
import { AuthActions } from "./constants";

export type AuthenticationReducerState = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isRegistering: boolean;
  isClient: boolean;
  isSubcontractor: boolean;
  isAdmin: boolean;
  user: UserPayload | null;
};

const defaultState: AuthenticationReducerState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isRegistering: false,
  isSubcontractor: false,
  isClient: false,
  isAdmin: false,
  user: null,
};

export default function authenticationReducer(
  state = defaultState,
  action: any
) {
  switch (action.type) {
    case AuthActions.SIGN_UP: {
      return {
        ...state,
        isRegistering: true,
      };
    }
    case AuthActions.SIGN_UP_FAILED: {
      return {
        ...state,
        isRegistering: false,
      };
    }
    case AuthActions.SIGN_IN:
    case AuthActions.GET_USER: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }
    case AuthActions.GET_USER_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        isSubcontractor: !!action?.payload?.isSubcontractor,
        isAdmin: !!action?.payload?.isAdmin,
        isClient: !!action?.payload?.isClient,
        user: action.payload.user,
      };
    }
    case AuthActions.GET_USER_FAILED: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
      };
    }
    case AuthActions.SIGN_IN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        isSubcontractor: !!action?.payload?.isSubcontractor,
        isAdmin: !!action?.payload?.isAdmin,
        isClient: !!action?.payload?.isClient,
      };
    }

    case AuthActions.SIGN_IN_FAILED: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        isSubcontractor: false,
        isAdmin: false,
        isClient: false,
      };
    }

    case AuthActions.UPDATE_PROFILE: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }

    case AuthActions.LOGOUT: {
      return {
        isAuthenticated: false,
        isAuthenticating: false,
        isRegistering: false,
        isSubcontractor: false,
        isClient: false,
        isAdmin: false,
        user: null,
      };
    }

    default:
      return state;
  }
}
