import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { chain, map, transform } from "lodash";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Badge } from "react-bootstrap";
import { useApolloClient, useQuery } from "@apollo/client";

import { DashboardContextValue } from "../../../containers/layouts/dashboard/DashboardContext";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import DashboardActionHeader, {
  ActionButton,
} from "../../../components/dashboard/table-card/DashboardActionHeader";
import "./styles.scss";
import {
  DownloadReportResponse,
  GetReportRequest,
  GetReportResponse,
  ReportType,
} from "../../../graphql/types/models/report";
import {
  DOWNLOAD_REPORT,
  GET_REPORT,
} from "../../../graphql/queries/report/queries";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { formatQuoteNumber } from "../../../utils/text";

const WIP: React.FC<DashboardContextValue> = () => {
  const { t } = useTranslation();

  const { downloadFile } = useDownloadFile();
  const client = useApolloClient();

  const { data: getReportData, loading } = useQuery<
    GetReportResponse,
    GetReportRequest
  >(GET_REPORT, {
    variables: {
      filters: {},
      reportType: ReportType.JobsWIP,
    },
    fetchPolicy: "cache-and-network",
  });

  const handleDownload = React.useCallback(async () => {
    try {
      const result = await client.query<
        DownloadReportResponse,
        GetReportRequest
      >({
        query: DOWNLOAD_REPORT,
        variables: {
          reportType: ReportType.JobsWIP,
          filters: {},
        },
        fetchPolicy: "network-only",
      });
      const exportData = result?.data?.downloadReport;
      downloadFile(exportData.data, exportData.filename);
    } catch (e) {
      console.log({ e });
    }
  }, []);

  const reportTableData = React.useMemo<TableCardData<any>>(() => {
    if (!getReportData?.getReport) {
      return {
        columns: [],
        rows: [],
      };
    }

    const columns = chain(getReportData?.getReport?.header)
      .map((header) => {
        return {
          valueKey: header.key,
          title: header.key,
          formatValue: (row: any, column: any, value: string) => {
            let formattedValue = value;
            if (header.key === "Job Number" && value) {
              formattedValue = formatQuoteNumber(value, "J");
            } else if (header.format === "currency") {
              formattedValue = t("common.currency", { amount: value });
            } else if (header.format === "percent") {
              formattedValue = t("common.percent", { amount: value });
            }
            const isWIP = header.key.indexOf("WIP") >= 0;
            if (header.key.indexOf("Gross") >= 0 || isWIP) {
              let numericValue = parseFloat(value);
              if (isWIP) {
                // invert
                numericValue *= -1;
              }
              return (
                <Badge variant={numericValue >= 0 ? "success" : "danger"}>
                  {formattedValue}
                </Badge>
              );
            }
            return formattedValue;
          },
        };
      })
      .compact()
      .value();

    const rows: TableCardDataRow<any>[] = map(
      getReportData?.getReport.rows,
      (row) => {
        const cells = {
          _id: row._id,
        } as any;
        row.cells.forEach((c) => {
          cells[c.key] = c.value || "";
        });
        return { cells };
      }
    );

    if (rows.length) {
      rows.push({
        isTotal: true,
        cells: transform(
          getReportData?.getReport?.summary,
          (result: any, cell) => {
            result[cell.key] = cell.value || "";
            return result;
          },
          {}
        ),
      });
    }

    return {
      columns,
      rows,
    };
  }, [t, getReportData]);

  const exportButtonAction = React.useMemo<ActionButton[]>(
    () => [
      {
        className: "export-button",
        icon: "download",
        onClick: handleDownload,
      },
    ],
    [handleDownload]
  );

  return (
    <div>
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.JOBS_SECTION.WORK_IN_PROGRESS}
      />
      <Helmet title={t("navigation.jobsSection.workInProgress")} />

      <Row className="justify-content-end pb-4">
        <Col>
          <DashboardActionHeader additionalActionButtons={exportButtonAction} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {loading || reportTableData.rows.length ? (
            <TableCard
              tableId="jobs"
              isDataLoading={loading}
              data={reportTableData}
              alignEnd
              className="overflow-visible"
            />
          ) : (
            <EmptyPlaceholder message={t("jobs.emptyPlaceholder")} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default WIP;
