import React from "react";
import { useQuery } from "@apollo/client";
import {
  ListNotesResponse,
  NoteEntityType,
} from "../graphql/types/models/note";
import { LIST_NOTES } from "../graphql/queries/note/queries";
import NoteCard from "../components/note/note-card";

export function useNote(entityType: NoteEntityType, entityId: string) {
  const { data: notesData } = useQuery<ListNotesResponse>(LIST_NOTES, {
    variables: {
      entityType,
      entityId,
    },
  });

  const notes = React.useMemo(() => notesData?.listNotes || [], [notesData]);

  const renderNotesCard = React.useCallback(() => {
    return (
      <NoteCard entityType={entityType} entityId={entityId} notes={notes} />
    );
  }, [notes, entityType, entityId]);

  return { notes, renderNotesCard };
}
