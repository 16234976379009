import { gql } from "@apollo/client";

export const AFFILIATE_FRAGMENT = gql`
  fragment AffiliateFragment on Affiliate {
    _id
    first_name
    last_name
    business_name
    phone
    ABN
    address
    city
    state
    postcode
    email
    affiliateCode
    affiliateUrl
    hasBuilders
    status
    about
    additionalComment
    reviewedBy {
      _id
      first_name
      last_name
    }
    is_deleted
  }
`;
