import L, { DrawOptions, LeafletEventHandlerFn, Map } from "leaflet";
import "leaflet-draw";
import { ContextProps, FeatureGroup, withLeaflet } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { each, get, set } from "lodash";
import Icon from "../../icons/Icon";
import SVGIcon from "../../icons/SVGIcon";
import { PlanViewerContextOptions, withPlanViewer } from "./PlanViewerContext";
import PlanViewerToolbarButton from "./PlanViewerToolbarButton";
import { COMMENT_PATH } from "../take-off-plan-viewer/utils";
import PlanViewerToolbarInput from "./PlanViewerToolbarInput";
import "./PlanViewerToolbar.styles.scss";

export type PlanViewerDrawOptions = {
  polyline?: DrawOptions.PolylineOptions | false;
  polygon?: DrawOptions.PolygonOptions | false;
  rectangle?: DrawOptions.RectangleOptions | false;
  circle?: DrawOptions.CircleOptions | false;
  marker?: DrawOptions.MarkerOptions | false;
  comment?: DrawOptions.MarkerOptions | false;
  deduction?: DrawOptions.PolygonOptions | false;
  polylineDeduction?: DrawOptions.PolylineOptions | false;
  rectangleDeduction?: DrawOptions.RectangleOptions | false;
  circlemarker?: DrawOptions.CircleOptions | false;
};

type PlanViewerToolbarProps = ContextProps &
  PlanViewerContextOptions & {
    draw?: PlanViewerDrawOptions;
    edit?: L.Control.EditOptions;
    onEdited?: LeafletEventHandlerFn;
    onDeleted?: LeafletEventHandlerFn;
    onLayerAdd?: LeafletEventHandlerFn;
    onCreated?: (e: any) => void;
    onMounted?: (e: any) => void;
    onWallHeightChange?: (value: number) => void;
    onPitchAngleChange?: (value: number) => void;
    onMeasureWeightChange?: (value: number) => void;
    onDepthChange?: (value: number) => void;
    onMaterialWidthChange?: (value: number) => void;
    onPolylineClick?: () => void;
    onVerticalPolylineClick?: () => void;
    showWallHeight?: boolean;
    showPitchAngle?: boolean;
    showMeasureWeight?: boolean;
    showDepth?: boolean;
    showMaterialWidth?: boolean;
    wallHeight?: number;
    pitchAngle?: number;
    measureWeight?: number;
    depth?: number;
    materialWidth?: number;
    editable?: Boolean;
    continuous?: Boolean;
  };

const PlanViewerToolbar: React.FC<PlanViewerToolbarProps> = ({
  draw,
  edit,
  leaflet,
  onMapRotate,
  onCreated,
  onLayerAdd,
  onEdited,
  onDeleted,
  onMounted,
  showPitchAngle,
  showWallHeight,
  showDepth,
  showMaterialWidth,
  wallHeight,
  pitchAngle,
  depth,
  materialWidth,
  onWallHeightChange,
  onPitchAngleChange,
  onMeasureWeightChange,
  onDepthChange,
  onMaterialWidthChange,
  onPolylineClick,
  onVerticalPolylineClick,
  editable = true,
  continuous,
  showMeasureWeight,
  measureWeight,
}) => {
  const { t } = useTranslation();

  const [map, setMap] = React.useState<Map | null>(null);
  const editControlRef = React.useRef<any | null>(null);
  const [toolbarDraw, setToolbarDraw] = React.useState<any | null>(null);
  const [toolbarEdit, setToolbarEdit] = React.useState<any | null>(null);
  const [isFullscreen, setFullscreen] = React.useState<boolean>(false);
  const [isDeleteSelected, setDeleteSelected] = React.useState(false);
  const [isEditSelected, setEditSelected] = React.useState(false);

  const controlsDisabled = isDeleteSelected || isEditSelected;

  const removeInvalidComments = React.useCallback(() => {
    map?.eachLayer((layer: any) => {
      if (layer.layerType === "comment") {
        if (!get(layer, COMMENT_PATH)?.properties?.text) {
          layer.remove();
        }
      }
    });
  }, [map]);

  // hide/show labels when editing
  const toggleLabels = React.useCallback(
    (display: boolean) => {
      map?.eachLayer((layer: any) => {
        if (layer.layerType === "label") {
          let className = get(layer, "_icon.className", "");
          if (display) {
            className = className.replace(/leaflet-label-hidden/g, "");
          } else {
            className = `${className} leaflet-label-hidden`;
          }
          set(layer, "_icon.className", className);
        }
      });
    },
    [map]
  );

  const handleRemoveSelect = React.useCallback(() => {
    setDeleteSelected(true);
    toggleLabels(false);
  }, [toggleLabels]);

  const deselectEditControl = React.useCallback(
    (e) => {
      e.stopPropagation();
      setEditSelected(false);
      toggleLabels(true);

      const action = toolbarEdit?.edit?.handler;
      action && action.revertLayers();
      action && action.disable();

      removeInvalidComments();
    },
    [toolbarEdit, toggleLabels]
  );

  const deselectRemoveControl = React.useCallback(
    (e) => {
      e.stopPropagation();
      setDeleteSelected(false);
      toggleLabels(true);

      const action = toolbarEdit?.remove?.handler;
      action && action.revertLayers();
      action && action.disable();

      removeInvalidComments();
    },
    [toolbarEdit, toggleLabels]
  );

  const confirmRemove = React.useCallback(
    (e) => {
      e.stopPropagation();
      setDeleteSelected(false);

      const action = toolbarEdit?.remove?.handler;
      action && action.save();
      action && action.disable();
    },
    [toolbarEdit]
  );

  const confirmEdit = React.useCallback(
    (e) => {
      e.stopPropagation();
      setEditSelected(false);
      toggleLabels(true);

      const action = toolbarEdit?.edit?.handler;
      action && action.save();
      action && action.disable();
    },
    [toolbarEdit, toggleLabels]
  );

  const deselectRemoveAction = React.useCallback(() => {
    setDeleteSelected(false);
  }, []);

  const deselectEditAction = React.useCallback(() => {
    setEditSelected(false);
  }, []);

  React.useEffect(() => {
    const removeAction = toolbarEdit?.remove?.handler;
    const editAction = toolbarEdit?.edit?.handler;

    if (!removeAction || !removeAction) {
      return;
    }

    removeAction.on("disabled", deselectRemoveAction);
    editAction.on("disabled", deselectEditAction);

    return () => {
      removeAction.off("disabled", deselectRemoveAction);
      editAction.off("disabled", deselectEditAction);
    };
  }, [toolbarEdit, deselectRemoveAction, deselectEditAction]);

  const handleOnMounted = React.useCallback(
    (e) => {
      setToolbarDraw(e._toolbars.draw._modes);
      editable && setToolbarEdit(e._toolbars.edit._modes);
      setMap(e._map);
      onMounted && onMounted(e);
    },
    [onMounted, editable]
  );

  const handleOnCreated = React.useCallback(
    (e) => {
      onCreated && onCreated(e);
      if (!continuous) return;
      // repeat drawing (repeatMode config didn't work well)
      each(toolbarDraw, (action: any) => {
        if (action.handler?.type === e.layerType) {
          setTimeout(() => action.handler?.enable(), 200);
        }
      });
    },
    [onCreated, toolbarDraw, continuous]
  );

  const handleZoomInClick = React.useCallback(() => {
    if (!map) return;

    (map as any).zoomControl._zoomInButton.click();
  }, [map]);

  const handleZoomOutClick = React.useCallback(() => {
    if (!map) return;

    (map as any).zoomControl._zoomOutButton.click();
  }, [map]);

  const handleMapRotate = React.useCallback(() => {
    onMapRotate();
  }, [onMapRotate]);

  const handleFullscreenClick = React.useCallback(() => {
    if (!leaflet?.map) return;
    const map = leaflet.map as any;
    map.toggleFullscreen();
    if (!isFullscreen) {
      map.setZoom(0);
    } else {
      map.setZoom(map.options.minZoom);
    }
    setFullscreen(!isFullscreen);
  }, [leaflet, isFullscreen]);

  const handleButtonInputClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
    },
    [toolbarDraw]
  );

  const handleWallHeightChange = React.useCallback(
    (value) => {
      try {
        onWallHeightChange!(Number.parseFloat(value));
      } catch (e) {
        console.log("ERROR: onWallHeightChange", e);
      }
    },
    [onWallHeightChange]
  );

  const handleDepthChange = React.useCallback(
    (value) => {
      try {
        onDepthChange!(Number.parseFloat(value));
      } catch (e) {
        console.log("ERROR: onDepthChange", e);
      }
    },
    [onDepthChange]
  );

  const handlePitchAngleChange = React.useCallback(
    (value) => {
      try {
        onPitchAngleChange!(Number.parseFloat(value));
      } catch (e) {
        console.log("ERROR: onPitchAngleChange", e);
      }
    },
    [onPitchAngleChange]
  );

  const handleMeasureWeightChange = React.useCallback(
    (value) => {
      try {
        onMeasureWeightChange!(Number.parseFloat(value));
      } catch (e) {
        console.log("ERROR: onMeasureWeightChange", e);
      }
    },
    [onMeasureWeightChange]
  );

  const handleMaterialWidthChange = React.useCallback(
    (value) => {
      try {
        onMaterialWidthChange!(Number.parseFloat(value));
      } catch (e) {
        console.log("ERROR: onMaterialWidthChange", e);
      }
    },
    [onMaterialWidthChange]
  );

  const handleReset = React.useCallback(() => {
    each(toolbarDraw, (action: any) => {
      action.handler?.disable();
      action.handler?.disablePrestep && action.handler.disablePrestep();
    });
    // hide comment popups
    map?.closePopup();
  }, [toolbarDraw]);

  const handleEditSelect = React.useCallback(() => {
    handleReset();
    setEditSelected(true);
    toggleLabels(false);
  }, [map, handleReset, toggleLabels]);

  // Close polyline when first point is clicked
  const onDrawVertex = React.useCallback(
    (e: any) => {
      const layers = e.layers.getLayers();
      if (layers.length === 3) {
        layers[0].on("click", (e: any) => {
          each(toolbarDraw, (action: any) => {
            if (
              action.handler?._enabled &&
              (action.handler.type === "polyline" ||
                action.handler.type === "polylineDeduction")
            ) {
              action.handler._markers.push(
                action.handler._createMarker(e.latlng)
              );
              action.handler._poly.addLatLng(e.latlng);
              action.handler.completeShape();
            }
          });
        });
        return true;
      }
    },
    [toolbarDraw]
  );

  React.useEffect(() => {
    if (!map) {
      return;
    }

    // @ts-ignore
    onLayerAdd && map.on("layeradd", onLayerAdd);
    // @ts-ignore
    map.on("draw:deleted", onDeleted);
    // @ts-ignore
    map.on("draw:edited", onEdited);
    // @ts-ignore
    map.on("draw:drawvertex", onDrawVertex);

    return () => {
      map.off("draw:deleted", onDeleted);
      map.off("draw:edited", onEdited);
      map.off("draw:drawvertex", onDrawVertex);
      onLayerAdd && map.off("layeradd", onLayerAdd);
    };
  }, [map, onEdited, onDeleted, onDrawVertex, onLayerAdd]);

  const depthInput = React.useMemo(() => {
    if (!showDepth) return null;
    return (
      <PlanViewerToolbarInput
        onClick={handleButtonInputClick}
        name="depth"
        placeholder={t("takeOffSection.depth")}
        value={depth}
        onChange={handleDepthChange}
      />
    );
  }, [showDepth, depth, handleDepthChange, handleButtonInputClick]);

  const pitchInput = React.useMemo(() => {
    if (!showPitchAngle) return null;
    return (
      <PlanViewerToolbarInput
        onClick={handleButtonInputClick}
        name="pitch-angle"
        placeholder={t("takeOffSection.pitch")}
        value={pitchAngle}
        onChange={handlePitchAngleChange}
        allowEmpty={true}
      />
    );
  }, [
    showPitchAngle,
    pitchAngle,
    handlePitchAngleChange,
    handleButtonInputClick,
  ]);

  const measureWeightInput = React.useMemo(() => {
    if (!showMeasureWeight) return null;
    return (
      <PlanViewerToolbarInput
        onClick={handleButtonInputClick}
        name="weight"
        placeholder={t("takeOffSection.measureWeight")}
        value={measureWeight}
        onChange={handleMeasureWeightChange}
        allowEmpty={false}
      />
    );
  }, [
    showMeasureWeight,
    handleButtonInputClick,
    t,
    measureWeight,
    handleMeasureWeightChange,
  ]);

  const wallHeightInput = React.useMemo(() => {
    if (!showWallHeight) return null;
    return (
      <PlanViewerToolbarInput
        onClick={handleButtonInputClick}
        name="wall-height"
        placeholder={t("takeOffSection.wallHeight")}
        value={wallHeight}
        onChange={handleWallHeightChange}
      />
    );
  }, [
    showWallHeight,
    wallHeight,
    handleWallHeightChange,
    handleButtonInputClick,
  ]);

  const materialWidthInput = React.useMemo(() => {
    if (!showMaterialWidth) return null;
    return (
      <PlanViewerToolbarInput
        onClick={handleButtonInputClick}
        name="material-width"
        placeholder={t("takeOffSection.materialWidth")}
        value={materialWidth}
        onChange={handleMaterialWidthChange}
      />
    );
  }, [
    showMaterialWidth,
    materialWidth,
    handleMaterialWidthChange,
    handleButtonInputClick,
  ]);

  // remove toolbars to prevent duplicate draw events when toolbar is re-created
  React.useEffect(() => {
    return () => {
      setTimeout(() => {
        each(toolbarDraw, (action: any) => {
          if (action.handler?._enabled) {
            action.handler?.disable();
          }
        });
      }, 100);
    };
  }, [toolbarDraw]);

  return (
    <>
      <div className="plan-viewer-toolbar plan-viewer-toolbar-left">
        <div className="buttons-container">
          {toolbarDraw?.polyline && (
            <>
              <PlanViewerToolbarButton
                disabled={controlsDisabled}
                actionReference={toolbarDraw?.polyline}
                onClick={onPolylineClick}
                reset={handleReset}
                isTwoStep={
                  showPitchAngle || showWallHeight || showMeasureWeight
                }
                canDraw={
                  (showPitchAngle && !!pitchAngle) ||
                  (showWallHeight && !!wallHeight) ||
                  (showMeasureWeight && !!measureWeight)
                }
                title={t("takeOffSection.line")}
                allowEmpty={!showWallHeight && !showMeasureWeight}
                className="polyline"
              >
                <SVGIcon name="Line" />
                {pitchInput}
                {wallHeightInput}
                {measureWeightInput}
              </PlanViewerToolbarButton>

              {onVerticalPolylineClick && (
                <PlanViewerToolbarButton
                  disabled={controlsDisabled}
                  actionReference={toolbarDraw?.polyline}
                  onClick={onVerticalPolylineClick}
                  reset={handleReset}
                  canDraw={false}
                  title={t("takeOffSection.verticalLine")}
                  allowEmpty={!showWallHeight}
                  className="vertical-polyline"
                >
                  <SVGIcon name="Line" />
                </PlanViewerToolbarButton>
              )}
            </>
          )}
          {toolbarDraw?.polygon && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.polygon}
              reset={handleReset}
              isTwoStep={showPitchAngle || showDepth || showMaterialWidth}
              canDraw={
                (showPitchAngle && !!pitchAngle) ||
                (showDepth && !!depth) ||
                (showMaterialWidth && !!materialWidth)
              }
              allowEmpty={showPitchAngle}
              title={t("takeOffSection.polygon")}
            >
              <Icon name="pentagon" outlined />
              {pitchInput}
              {depthInput}
              {materialWidthInput}
            </PlanViewerToolbarButton>
          )}
          {toolbarDraw?.circle && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.circle}
              reset={handleReset}
            >
              <Icon name="add" />
            </PlanViewerToolbarButton>
          )}
          {toolbarDraw?.circlemarker && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.circlemarker}
              reset={handleReset}
            >
              <Icon name="add" />
            </PlanViewerToolbarButton>
          )}
          {toolbarDraw?.marker && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.marker}
              reset={handleReset}
            >
              <SVGIcon name="MapPin" />
            </PlanViewerToolbarButton>
          )}
          {toolbarDraw?.rectangle && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.rectangle}
              reset={handleReset}
              isTwoStep={showPitchAngle || showDepth || showMaterialWidth}
              canDraw={
                (showPitchAngle && !!pitchAngle) ||
                (showDepth && !!depth) ||
                (showMaterialWidth && !!materialWidth)
              }
              allowEmpty={showPitchAngle && !showMaterialWidth}
              title={t("takeOffSection.rectangle")}
            >
              <Icon name="crop_3_2" />
              {pitchInput}
              {depthInput}
              {materialWidthInput}
            </PlanViewerToolbarButton>
          )}
          {toolbarDraw?.polylineDeduction && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.polylineDeduction}
              onClick={onPolylineClick}
              reset={handleReset}
              isTwoStep={showWallHeight || showMeasureWeight}
              canDraw={
                (showWallHeight && !!wallHeight) ||
                (showMeasureWeight && !!measureWeight)
              }
              allowEmpty={!showWallHeight && !showMeasureWeight}
              title={t("takeOffSection.deductionPolyline")}
            >
              <SVGIcon name="PolylineDeduction" />
              {pitchInput}
              {wallHeightInput}
              {measureWeightInput}
            </PlanViewerToolbarButton>
          )}
          {toolbarDraw?.deduction && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.deduction}
              reset={handleReset}
              isTwoStep={showPitchAngle || showDepth || showMaterialWidth}
              canDraw={
                (showPitchAngle && !!pitchAngle) ||
                (showDepth && !!depth) ||
                (showMaterialWidth && !!materialWidth)
              }
              allowEmpty={showPitchAngle && !showMaterialWidth}
              title={t("takeOffSection.deductionPolygon")}
            >
              <SVGIcon name="Deduction" />
              {pitchInput}
              {depthInput}
              {materialWidthInput}
            </PlanViewerToolbarButton>
          )}
          {toolbarDraw?.rectangleDeduction && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.rectangleDeduction}
              reset={handleReset}
              isTwoStep={showPitchAngle || showDepth || showMaterialWidth}
              canDraw={
                (showPitchAngle && !!pitchAngle) ||
                (showDepth && !!depth) ||
                (showMaterialWidth && !!materialWidth)
              }
              allowEmpty={showPitchAngle && !showMaterialWidth}
              title={t("takeOffSection.deductionRectangle")}
            >
              <SVGIcon name="RectangleDeduction" />
              {pitchInput}
              {depthInput}
              {materialWidthInput}
            </PlanViewerToolbarButton>
          )}
          {toolbarDraw?.comment && (
            <PlanViewerToolbarButton
              disabled={controlsDisabled}
              actionReference={toolbarDraw?.comment}
              reset={handleReset}
              title={t("takeOffSection.comment")}
            >
              <SVGIcon name="Speech" />
            </PlanViewerToolbarButton>
          )}
        </div>
      </div>
      <div className="plan-viewer-toolbar plan-viewer-toolbar-right">
        <div className="buttons-container">
          {toolbarEdit?.remove && (
            <PlanViewerToolbarButton
              onSelect={handleRemoveSelect}
              disabled={controlsDisabled && !isDeleteSelected}
              actionReference={toolbarEdit?.remove}
              reset={handleReset}
            >
              <SVGIcon name="Trash" />
              <div className="edit-controls">
                <Button
                  onClick={confirmRemove}
                  variant="primary"
                  className="button success control-button"
                >
                  {t("common.save")}
                </Button>
                <Button
                  onClick={deselectRemoveControl}
                  variant="secondary"
                  className="button info control-button"
                >
                  {t("common.cancel")}
                </Button>
              </div>
            </PlanViewerToolbarButton>
          )}
          {/*onMapRotate && (
          <PlanViewerToolbarButton
            onClick={handleMapRotate}
            disabled={controlsDisabled}
            reset={handleReset}
          >
            <SVGIcon name="RotateRight" />
          </PlanViewerToolbarButton>
        )*/}
          {map && (
            <PlanViewerToolbarButton
              onClick={handleZoomInClick}
              disabled={controlsDisabled}
            >
              <SVGIcon name="ZoomIn" />
            </PlanViewerToolbarButton>
          )}
          {map && (
            <PlanViewerToolbarButton
              onClick={handleZoomOutClick}
              disabled={controlsDisabled}
            >
              <SVGIcon name="ZoomOut" />
            </PlanViewerToolbarButton>
          )}
          {toolbarEdit?.edit && (
            <PlanViewerToolbarButton
              onSelect={handleEditSelect}
              actionReference={toolbarEdit?.edit}
              disabled={controlsDisabled && !isEditSelected}
            >
              <Icon name="edit" outlined />
              <div className="edit-controls">
                <Button
                  onClick={confirmEdit}
                  variant="primary"
                  className="button success control-button"
                >
                  {t("common.save")}
                </Button>
                <Button
                  onClick={deselectEditControl}
                  variant="secondary"
                  className="button info control-button"
                >
                  {t("common.cancel")}
                </Button>
              </div>
            </PlanViewerToolbarButton>
          )}
          {map && (
            <PlanViewerToolbarButton
              onClick={handleFullscreenClick}
              disabled={controlsDisabled}
            >
              <SVGIcon name="Expand" />
            </PlanViewerToolbarButton>
          )}
        </div>
        <FeatureGroup>
          <EditControl
            position="topleft"
            onMounted={handleOnMounted}
            onCreated={handleOnCreated}
            draw={draw}
            edit={edit}
            ref={editControlRef}
          />
        </FeatureGroup>
      </div>
    </>
  );
};

export default withLeaflet(withPlanViewer(PlanViewerToolbar));
