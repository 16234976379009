import { gql } from "@apollo/client";
import { LIST_LEAD_STAGES_FRAGMENT } from "./fragments";

export const LIST_LEAD_STAGES = gql`
  query {
    listLeadStages {
      ...LeadStagesFragment
    }
  }
  ${LIST_LEAD_STAGES_FRAGMENT}
`;

export const LIST_LEAD_TAGS = gql`
  query ListLeadTags {
    listLeadTags {
      _id
      name
    }
  }
`;

export const GET_LEAD_BY_ID = gql`
  query getLeadById($leadId: ID!) {
    getLeadById(leadId: $leadId) {
      _id
      name
      description
      expectedCloseDate
      budget
      address
      city
      state
      postcode
      estimation {
        _id
        number
        name
        status
        total
        createdAt
        contact {
          _id
          first_name
          last_name
        }
      }
      stage {
        _id
        name
      }
      assignedTo {
        _id
        first_name
        last_name
        profileImage {
          _id
          url
        }
      }
      contacts {
        _id
        first_name
        last_name
      }
      tags {
        _id
        name
      }
    }
  }
`;
