import { filter, reduce } from "lodash";
import { round } from "mathjs";
import moment from "moment";
import { DistributeMarkup } from "../models/salesQuote";

export type CalcInput = {
  cost?: number | string | null;
  cost_inc?: number | string | null;
  quantity?: number | string | null;
  margin_amount?: number | string | null;
  distribution_amount?: number | string | null;
  distribute_markup?: DistributeMarkup;
  hasGST?: boolean;
  gstFree?: boolean;
};

export type CalcCategoryItem = {
  _id?: string;
  quantity: number;
  cost: number;
  real_cost?: number;
  margin_amount?: number;
  distribution_amount?: number;
  distribute_markup?: DistributeMarkup;
  hasGST?: boolean;
};

export type CalcCategory = {
  margin_amount?: number;
  costings?: CalcCategoryItem[];
  items?: CalcCategoryItem[];
};

export const GST_PERCENT = 10;

export const calcItemTotal = (
  input: CalcInput,
  category_margin: number = 0
) => {
  let cost = Number(input.cost) || 0;

  const quantity = Number(input.quantity || 0);
  const margin_amount = Number(input.margin_amount || 0);
  const markup = 1 + (margin_amount + Number(category_margin || 0)) / 100;

  return round(cost * quantity * markup, 3);
};

export const calcItemClientTotal = (
  input: CalcInput,
  category_markup: number = 0,
  global_markup: number = 0
) => {
  if (
    input.distribute_markup === DistributeMarkup.CATEGORY ||
    input.distribute_markup === DistributeMarkup.ESTIMATION
  ) {
    return round(Number(input.cost || 0) * Number(input.quantity || 0), 3);
  }
  return (
    calcWithGST(calcItemTotal(input, category_markup), global_markup) +
    Number(input.distribution_amount || 0)
  );
};

export const calcItemsSubtotal = (
  items: CalcInput[],
  category_margin: number | string
) => {
  return reduce(
    items,
    (acc, item) => {
      return acc + calcItemTotal(item, Number(category_margin) || 0);
    },
    0
  );
};

export const calcItemsGST = (
  items: CalcInput[],
  category_margin: number | string,
  percent: number
) => {
  const subTotal = reduce(
    items,
    (acc, item) => {
      const itemTotal = (Number(item.cost) || 0) * Number(item.quantity) || 0;
      let gstApplicable = !item.gstFree ? itemTotal : 0;
      const markupAmount =
        calcItemTotal(item, Number(category_margin) || 0) - itemTotal;
      gstApplicable += markupAmount;
      return acc + gstApplicable;
    },
    0
  );
  return calcGST(subTotal, percent);
};

export const calcWithGST = (subTotal = 0, percent: number) => {
  return round(subTotal + calcGST(subTotal, percent), 10);
};
export const calcGST = (subTotal = 0, percent: number) => {
  return subTotal ? (subTotal * percent) / 100 : 0;
};

export const calcGSTEx = (subTotal = 0, percent: number) => {
  return subTotal ? round(subTotal - subTotal / (1 + percent), 10) : 0;
};

export const calcTotal = (subTotal: number, GST: number) => {
  return subTotal + GST;
};

export const calcCategorySubTotal = (
  categories: CalcCategory[],
  gstFreeItems?: boolean
) => {
  let total = 0;
  if (categories && categories.length) {
    total = reduce(
      categories,
      (result, category) => {
        const total = calcCategoryTotal(
          category.margin_amount,
          category.costings || category.items,
          gstFreeItems
        );
        return result + total;
      },
      0
    );
  }
  return total;
};

export const calcTotalMarkup = (subTotal = 0, percent: number) => {
  return calcGST(subTotal, percent);
};

export const calcCategoryTotal = (
  margin_amount: number = 0,
  costingItems: CalcCategoryItem[] = [],
  gstFreeItems?: boolean,
  showPricesIncGst?: boolean
) => {
  let total = 0;
  if (costingItems && costingItems.length) {
    total = reduce(
      gstFreeItems !== undefined
        ? filter(costingItems, { hasGST: !gstFreeItems })
        : costingItems,
      (result, value) => {
        const { cost, quantity, margin_amount: costing_margin } = value;
        const markup = 1 + ((costing_margin || 0) + margin_amount) / 100;
        const itemTotal = cost * quantity * markup;
        return result + itemTotal;
      },
      0
    );
  }

  return showPricesIncGst ? calcWithGST(total, GST_PERCENT) : total;
};

export const calcCategoryClientTotal = (
  costingItems: CalcCategoryItem[] = [],
  markup_amount: number = 0,
  global_markup: number = 0,
  showPricesIncGst?: boolean
) => {
  const { total, totalInc } = reduce(
    costingItems,
    (result, value) => {
      const itemTotal = calcItemClientTotal(
        value,
        markup_amount,
        global_markup
      );
      return {
        total: result.total + itemTotal,
        totalInc:
          result.totalInc +
          (value.hasGST ? calcWithGST(itemTotal, GST_PERCENT) : itemTotal),
      };
    },
    {
      total: 0,
      totalInc: 0,
    }
  );
  return showPricesIncGst ? totalInc : total;
};

export const calcCategoryRealTotal = (
  costingItems: CalcCategoryItem[] = []
) => {
  return reduce(
    costingItems,
    (total, value) => {
      return total + Number(value.real_cost || 0);
    },
    0
  );
};

export const calcTotalTimesheetValue = (
  rate: number,
  startTime: string,
  endTime: string,
  breakDuration: number
) => {
  const startDate = moment(startTime);
  const endDate = moment(endTime);

  const duration = moment.duration(
    endDate.subtract(breakDuration, "minutes").diff(startDate)
  );

  return duration.asHours() * rate;
};

export const getTimeRange = (startTime: string, endTime: string) => {
  const startDate = moment(startTime);
  const endDate = moment(endTime);

  return `${startDate.format("hh:mma")} - ${endDate.format("hh:mma")}`;
};

export const getTimeInterval = (startTime: string, endTime: string) => {
  const startDate = moment(startTime);
  const endDate = moment(endTime);

  if (startDate.format("DD/MM/YYYY") === endDate.format("DD/MM/YYYY")) {
    return `${startDate.format("DD/MM/YYYY hh:mma")} - ${endDate.format(
      "hh:mma"
    )}`;
  }

  return `${startDate.format("DD/MM/YYYY hh:mma")} - ${endDate.format(
    "DD/MM/YYYY hh:mma"
  )}`;
};
