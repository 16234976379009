import React from "react";
import { FormikProps } from "formik";
import PriceLookupModal, {
  CostingsfieldName,
} from "../components/costing/price-lookup-modal";

import { FormikPropGetSetValues } from "../components/generic-form/GenericFormBody";
import {
  addAssemblyItem,
  createNewRow,
} from "../components/costing/update-costing-modal/utils";
import { AssemblyCostingItem } from "../models/assembly";
import { CreateAssemblyModalRef } from "../components/costing/create-assembly-modal";
import { omit } from "lodash";

export function useCreateAssemblyFromSelection(
  fieldName: CostingsfieldName,
  setAssemblyRowIndex: (index: number) => void,
  assemblyRef: React.RefObject<CreateAssemblyModalRef>
) {
  const [hasSelectedItems, setHasSelectedItems] = React.useState(false);

  const handleOnCreateAssemblyFromSelection = React.useCallback(
    (formikProps?: FormikPropGetSetValues) => {
      if (formikProps) {
        const { values, setFieldValue } = formikProps;
        setHasSelectedItems(true);
        const newRow = createNewRow();
        setFieldValue(fieldName, values[fieldName].concat(newRow));
        const rowIndex = values[fieldName].length;
        setAssemblyRowIndex(rowIndex);
        const selectedItems: AssemblyCostingItem[] = values[fieldName].filter(
          (item: any) => item.selected === true
        );
        const assembly = {
          _id: "",
          UOM: selectedItems[0]?.UOM || "",
          name: "",
          total: 0,
          ...addAssemblyItem(selectedItems),
        };
        assemblyRef.current?.show(true, assembly);
      }
    },
    [assemblyRef, fieldName, setAssemblyRowIndex]
  );

  const handleResetSelectedItems = React.useCallback(
    (formikProps?: FormikPropGetSetValues) => {
      if (formikProps) {
        const { values, setFieldValue } = formikProps;
        const selectedItems: AssemblyCostingItem[] = values[
          fieldName
        ].map((item: any) => omit(item, "selected"));

        setFieldValue(fieldName, selectedItems);
        setHasSelectedItems(false);
      }
    },
    [fieldName]
  );

  return {
    handleOnCreateAssemblyFromSelection,
    hasSelectedItems,
    setHasSelectedItems,
    handleResetSelectedItems,
  };
}
