import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardCard from "../../../components/dashboard/card";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { SystemFolderType } from "../../../models/documents";
import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import QuoteHeader from "../../../components/quotes/quote-header";
import { useDocuments } from "../../../hooks/useDocuments";
import EstimationLocationHeader from "../../header/estimation-location-header";
import "./styles.scss";

type Params = {
  folderId: string;
};
type QuoteDocumentsProps = RouteComponentProps<Params> &
  DashboardContextValue & {
    user: UserPayload | null;
  };

const QuoteDocuments: React.FC<QuoteDocumentsProps> = ({
  navigationContext,
  user,
}) => {
  const quoteId = navigationContext?.quote?._id;
  const { t } = useTranslation();

  const {
    renderDocumentModals,
    renderDocumentsTableComponent,
    renderDocumentsHeader,
  } = useDocuments({
    id: quoteId,
    systemFolderType: SystemFolderType.SQ,
    user,
    route: NAVIGATION_ROUTES.QUOTES_SECTION.QUOTES,
  });

  return (
    <Container fluid>
      <Helmet title={t("navigation.quotesSection.documents")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.QUOTES_SECTION.DOCUMENTS}
      />
      <EstimationLocationHeader />
      {renderDocumentModals()}
      <Row>
        <Col xs={12} className="pb-sm-5 pb-lg-0">
          <DashboardCard className="quotes-documents-wrapper">
            {renderDocumentsHeader()}
            {renderDocumentsTableComponent()}
          </DashboardCard>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withDashboardContext(QuoteDocuments));
