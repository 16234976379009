import React from "react";
import { useTranslation } from "react-i18next";
import { compact, find, map } from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  TableCardData,
  TableCardDataRow,
  TableCardAction,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import TableCard from "../../dashboard/table-card";
import SendModal from "../../../components/modals/send-modal";
import { SendEmailForm } from "../../../models/email";
import {
  QuoteRequest,
  QuoteRequestSubcontractor,
  QuoteRequestStatus,
  QuoteRequestClientFeedback,
} from "../../../graphql/types/models/quote-request";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";

type SubcontractorQuoteRequestListProps = {
  title: string | React.ReactElement;
  onSelect?: (
    item: QuoteRequest,
    subcontractor: QuoteRequestSubcontractor
  ) => void; // todo
  className?: string;
  quoteRequest: QuoteRequest;
  selectedSubcontractorsId: string[];
  toggleSelectSubcontractorsId: (id: string) => void;
  handleShareQuoteRequest: (data: SendEmailForm) => void;
  resetSelectSubcontractorsId: () => void;
};

type SubcontractorsTableItem = {
  _id: string;
  business_name?: string;
  dateReceived?: string;
  status?: string;
  clientStatus?: string;
  cost?: string;
};

const SubcontractorQuoteRequestList: React.FC<SubcontractorQuoteRequestListProps> = (
  props
) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    title,
    onSelect,
    quoteRequest,
    className,
    selectedSubcontractorsId,
    toggleSelectSubcontractorsId,
    handleShareQuoteRequest,
    resetSelectSubcontractorsId,
  } = props;

  const sendRef = React.useRef<ModalDisplayRef>();

  const openSendModal = React.useCallback(() => sendRef.current?.show(true), [
    sendRef,
  ]);

  const handleRowClick = React.useCallback(
    (
      row: TableCardDataRow<SubcontractorsTableItem>,
      index: number | undefined
    ) => {
      if (index === undefined) return;
      onSelect && onSelect(quoteRequest, quoteRequest.subcontractors[index]);
      // history.push(`/quote-requests/${row.cells._id}`);
    },
    [quoteRequest, onSelect]
  );

  const getSubcontractorCost = React.useCallback(
    (subcontractor: QuoteRequestSubcontractor) => {
      let sum = 0;
      subcontractor?.items?.forEach((item) => {
        const qty =
          find(quoteRequest.items, { _id: item.quoteItemId })?.quantity || 0;
        sum += item.cost * qty;
      });
      return sum;
    },
    [quoteRequest]
  );

  const tableData = React.useMemo<
    TableCardData<SubcontractorsTableItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "business_name",
          title: t("common.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "dateReceived",
          title: t("quoteRequest.receivedDate"),
          formatValue: (row: any, column: any, value: string) =>
            value
              ? moment(value).format("Do MMM YYYY")
              : t("quoteRequest.pending"),
        },
        {
          valueKey: "cost",
          title: t("costing.cost"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "status",
          title: t("common.status"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "clientStatus",
          title: t("quoteRequest.clientStatus"),
          formatValue: (row: any, column: any, value: string) => value,
        },
      ],
      rows: map(
        quoteRequest.subcontractors,
        (item: QuoteRequestSubcontractor) => ({
          cells: {
            _id: item._id,
            business_name: compact([
              item.supplier?.business_name,
              item.supplier?.contact_person,
            ]).join(" - "),
            dateReceived: item.date_submitted,
            status: item.status,
            clientStatus: item.client_feedback,
            cost: t("common.currency", { amount: getSubcontractorCost(item) }),
          },
        })
      ),
    };
  }, [quoteRequest, getSubcontractorCost]);

  const toggleSelectedItem = React.useCallback(
    (data?: SubcontractorsTableItem) => {
      if (data?.status === QuoteRequestStatus.SUBMITTED) {
        toggleSelectSubcontractorsId(data._id);
      }
    },
    [toggleSelectSubcontractorsId]
  );

  const tableLeftRowActions: TableRowActionData<
    SubcontractorsTableItem
  >[] = React.useMemo(
    () => [
      {
        icon: "check_box",
        dropdownId: "quote-request",
        onClick: toggleSelectedItem,
        shouldRender: (row) =>
          row.status === QuoteRequestStatus.SUBMITTED &&
          row.clientStatus === QuoteRequestClientFeedback.NONE &&
          selectedSubcontractorsId.find((id) => id === row._id),
      },
      {
        icon: "check_box_outline_blank",
        dropdownId: "quote-request",
        onClick: toggleSelectedItem,
        shouldRender: (row) =>
          quoteRequest.job &&
          row.status === QuoteRequestStatus.SUBMITTED &&
          row.clientStatus === QuoteRequestClientFeedback.NONE &&
          !selectedSubcontractorsId.find((id) => id === row._id),
      },
    ],
    [quoteRequest, selectedSubcontractorsId, toggleSelectedItem]
  );

  const headerAction = React.useMemo<TableCardAction>(
    () => ({
      title: t(
        selectedSubcontractorsId.length
          ? "quoteRequest.shareWithClientNumber"
          : "quoteRequest.shareWithClient",
        { number: selectedSubcontractorsId.length }
      ),
      onClick: openSendModal,
      icon: "share",
      className: "button max-super-large info bg-transparent text-light",
      disabled: !selectedSubcontractorsId.length,
    }),
    [selectedSubcontractorsId, openSendModal]
  );

  const contact = React.useMemo(() => {
    if (quoteRequest.job) {
      return quoteRequest.job.customer;
    }

    if (quoteRequest.salesQuote) {
      return quoteRequest.salesQuote.contact;
    }

    return null;
  }, [quoteRequest]);

  const handleSubmit = React.useCallback(
    (data: SendEmailForm) => {
      handleShareQuoteRequest(data);
      resetSelectSubcontractorsId();
      sendRef.current?.show(false);
    },
    [resetSelectSubcontractorsId, handleShareQuoteRequest, sendRef]
  );

  return (
    <>
      <SendModal
        ref={sendRef}
        title={t("quoteRequest.shareQuoteRequest")}
        submitText={t("common.share")}
        onSubmit={handleSubmit}
        contacts={contact ? [contact] : []}
        contact={contact}
        disableContactSelect
      />
      <TableCard
        data={tableData}
        isDataLoading={false}
        onRowClick={handleRowClick}
        title={t("quoteRequest.subcontractors")}
        headerAction={headerAction}
        leftRowActions={tableLeftRowActions}
      />
    </>
  );
};

export default SubcontractorQuoteRequestList;
