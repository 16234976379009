import { gql } from "@apollo/client";

export const SUBCONTRACTOR_PROFILE = gql`
  query {
    subcontractorProfile {
      _id
      business_name
      email
      mobile
      contact_person
    }
  }
`;
