import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import { FormikProps } from "formik";
import { map, uniqBy } from "lodash";
import { useMutation, useQuery } from "@apollo/client";

import {
  CreateTakeOffPayload,
  TakeoffListItem,
} from "../../../../models/take-off";
import {
  GenericFormFields,
  GenericFormTable,
  renderTableForm,
} from "../../../generic-form/GenericFormBody";
import addToCostingSchema from "./AddToCostingSchema";
import { addToCostingFields } from "./utils";
import { AddTakeOffToCostingPayload } from "../../../../models/salesQuote";
import ModalForm from "../../../modals/modal-form";
import { LIST_SALES_QUOTE_CATEGORIES } from "../../../../graphql/queries/category/queries";

import { GenericRecord } from "../../../../utils/types/options";
import {
  SalesQuoteCategoryListResponse,
  SaveSalesQuoteCostingBulkPayload,
  SaveSalesQuoteCostingBulkResponse,
} from "../../../../graphql/types/models/category";
import { convertQuantity } from "../../take-off-plan-viewer/utils";
import { SAVE_SALES_QUOTE_COSTING_BULK } from "../../../../graphql/queries/category/mutations";
import { notify } from "../../../notification";
import "./styles.scss";

type AddToCostingModalProps = {
  salesQuoteId: string;
  takeOffs?: TakeoffListItem[] | null;
  onSubmit?: (data: AddTakeOffToCostingPayload) => void;
};

export type AddToCostingModalModalRef = {
  show: (show: boolean) => void;
};

const AddToCostingModal: React.FC<AddToCostingModalProps> = (
  { salesQuoteId, onSubmit, takeOffs },
  ref
) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const [formFields, setFormFields] = React.useState<
    GenericFormFields<CreateTakeOffPayload>
  >({});

  const [costingCategoryList, setCostingCategoryList] = React.useState<
    GenericRecord[]
  >();

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => setShowModal(show),
  }));

  const { data: quoteCategories } = useQuery<SalesQuoteCategoryListResponse>(
    LIST_SALES_QUOTE_CATEGORIES,
    {
      variables: { salesQuoteId },
      fetchPolicy: "cache-and-network",
    }
  );

  const [saveCostingItems] = useMutation<
    SaveSalesQuoteCostingBulkResponse,
    SaveSalesQuoteCostingBulkPayload
  >(SAVE_SALES_QUOTE_COSTING_BULK, {
    onCompleted: ({ saveSalesQuoteCostingBulk }) => {
      notify({
        title: t("takeOffSection.addTakeOffToCosting"),
        content: t("takeOffSection.success.addTakeOffToCosting"),
      });
      setShowModal(false);
    },
    onError: () => {
      notify({
        error: true,
        title: t("takeOffSection.addTakeOffToCosting"),
        content: t("takeOffSection.errors.addTakeOffToCosting"),
      });
    },
  });
  React.useEffect(() => {
    if (quoteCategories?.listSalesQuoteCostingCategories) {
      setCostingCategoryList(
        map(quoteCategories.listSalesQuoteCostingCategories, (category) => ({
          label: category.name,
          value: category.name,
        }))
      );
    }
  }, [quoteCategories]);

  const handleCostingCreate = React.useCallback(
    (costing: string) => {
      if (costingCategoryList) {
        const newCosting = uniqBy(
          costingCategoryList.concat([{ label: costing, value: costing }]),
          "value"
        );
        setCostingCategoryList(newCosting);
      } else {
        setCostingCategoryList([{ label: costing, value: costing }]);
      }
    },
    [costingCategoryList, setCostingCategoryList]
  );

  React.useEffect(() => {
    setFormFields(
      addToCostingFields(t, costingCategoryList, handleCostingCreate)
    );
  }, [t, costingCategoryList]);

  const handleSubmit = React.useCallback((data: AddTakeOffToCostingPayload) => {
    saveCostingItems({
      variables: {
        salesQuoteId,
        costItems: map(data.items, (item) => ({
          ...item,
          margin_amount: parseFloat(item.margin_amount?.toString() || "0"),
          cost: parseFloat(item.cost?.toString() || "0"),
          quantity: parseFloat(item.quantity?.toString() || "0"),
        })),
      },
    });
  }, []);

  const initialValues = React.useMemo(() => {
    return {
      items: map(takeOffs, (takeOff) => {
        const { quantity, UOM } = convertQuantity(
          takeOff.quantity,
          takeOff.UOM
        );
        return {
          name: takeOff.name,
          UOM: UOM,
          quantity: quantity,
          raw_quantity: quantity?.toString(),
          margin_amount: null,
          cost: 0,
          category: "",
        };
      }),
    };
  }, [takeOffs]);

  return (
    <ModalForm<AddTakeOffToCostingPayload>
      show={showModal}
      validationSchema={addToCostingSchema(t)}
      onClose={() => setShowModal(false)}
      data={initialValues}
      className="add-to-costing-modal"
      title={t("takeOffSection.addTakeOffToCosting")}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<AddTakeOffToCostingPayload>) => (
        <Container className="generic-form-body" fluid>
          <Row>
            {renderTableForm(
              formikProps,
              formFields.items as GenericFormTable<
                AddTakeOffToCostingPayload,
                any
              >,
              "items"
            )}
          </Row>
        </Container>
      )}
    </ModalForm>
  );
};

export default forwardRef(AddToCostingModal);
