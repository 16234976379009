import { TFunction } from "i18next";
import { CreateSalesQuotePayload } from "../../../models/salesQuote";
import { STATES_OPTIONS } from "../../../utils/options";

import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { AutocompleteInputOption } from "../../generic-form/inputs/autocomplete";
import BadgedAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/BadgedAutocompleteMenuOption";
import ClientInputContainer from "./ClientInputContainer";

export function createSalesQuoteFormFields(
  t: TFunction,
  clientOptions: AutocompleteInputOption[],
  templatesOptions: AutocompleteInputOption[],
  onAddClientClick: () => void,
  onContactSelect: (contactId: string) => void,
  onPlaceSelect: UpdateTextInputFn
): GenericFormFields<CreateSalesQuotePayload> {
  return {
    name: {
      type: "text",
      label: t("quotes.name"),
      placeholder: t("placeholders.quoteName"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    contactId: {
      type: "select",
      label: t("quotes.client"),
      placeholder: t("placeholders.client"),
      valueKey: "contactId",
      autocompleteProps: {
        options: clientOptions,
        onValueChange: onContactSelect,
        sortByAsc: true,
      },
      inputProps: {
        required: true,
      },
      formField: ClientInputContainer,
      formFieldProps: {
        onAddClientClick: onAddClientClick as any,
      },
    },
    templateId: {
      type: "select",
      label: t("quotes.template"),
      placeholder: t("placeholders.template"),
      valueKey: "templateId",
      autocompleteProps: {
        options: templatesOptions,
        optionComponent: BadgedAutocompleteMenuOption,
      },
      inputProps: {
        required: true,
      },
    },
    markup: {
      type: "text",
      label: t("costing.markupPercentLabel"),
      placeholder: t("costing.markupPercentLabel"),
      valueKey: "markup",
      inputProps: {
        type: "number",
        required: true,
      },
    },

    address: {
      type: "place-autocomplete",
      label: t("quotes.siteAddress"),
      placeholder: t("placeholders.address"),
      valueKey: "address",
      textInputProps: {
        onValueChange: onPlaceSelect,
      },
      inputProps: {
        required: true,
      },
    },
    location: [
      {
        type: "text",
        label: t("contacts.city"),
        placeholder: t("placeholders.city"),
        valueKey: "city",
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "select",
        label: t("contacts.state"),
        placeholder: t("placeholders.state"),
        valueKey: "state",
        autocompleteProps: {
          options: STATES_OPTIONS,
        },
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    postcode: {
      type: "text",
      label: t("contacts.postcode"),
      placeholder: t("placeholders.postcode"),
      valueKey: "postcode",
      showError: true,
      inputProps: {
        type: "text",
        required: true,
      },
    },
  };
}
