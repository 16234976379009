import { gql } from "@apollo/client";

export const SUBCONTRACTOR_SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!, $rememberMe: Boolean!) {
    subcontractorLogin(
      email: $email
      password: $password
      remember_me: $rememberMe
    ) {
      _id
      tokenType
      token
    }
  }
`;

export const SUBCONTRACTOR_FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    subcontractorForgotPassword(email: $email) {
      _id
      email
    }
  }
`;

export const SUBCONTRACTOR_RESET_PASSWORD = gql`
  mutation($resetHash: String!, $password: String!) {
    subcontractorResetPassword(resetHash: $resetHash, password: $password) {
      _id
      email
    }
  }
`;

export const SUBCONTRACTOR_CHANGE_PASSWORD = gql`
  mutation SubcontractorChangePassword(
    $old_password: String!
    $new_password: String!
  ) {
    changePassword: subcontractorChangePassword(
      old_password: $old_password
      new_password: $new_password
    ) {
      _id
      token
    }
  }
`;

export const SUBCONTRACTOR_EDIT_PROFILE = gql`
  mutation SubcontractorEditProfile($subcontractor: SubcontractorInput!) {
    subcontractorEditProfile(subcontractor: $subcontractor) {
      _id
      business_name
      email
      mobile
      contact_person
    }
  }
`;
