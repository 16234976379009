import React from "react";
import classNames from "classnames";
import {
  ChecklistEntityResult,
  EnumChecklistEntityType,
} from "../../../models/checklists";
import { calcChecklistCategoryProgress, getCategoryNumber } from "./utils";
import ChecklistBadge from "../checklist-badge/ChecklistBadge";
import { formatQuoteNumber } from "../../../utils/text";

type ChecklistsCategoryItemProps = {
  isSelected: boolean;
  category: ChecklistEntityResult;
  onClick?: (category: ChecklistEntityResult) => void;
  entityType: EnumChecklistEntityType;
};

const ChecklistsCategoryItem: React.FC<ChecklistsCategoryItemProps> = (
  props
) => {
  const { isSelected, category, onClick, entityType } = props;

  const getItemClass = (): string => {
    return classNames("checklist-list-item", {
      "checklist-category-selected": isSelected,
    });
  };

  const handleClick = React.useCallback(() => {
    return onClick && onClick(category);
  }, [onClick, category]);

  const categoryNumber = React.useMemo(() => {
    if (entityType === EnumChecklistEntityType.Job) {
      return formatQuoteNumber(category.entity.jobNumber, "J");
    }

    if (entityType === EnumChecklistEntityType.Quote) {
      return formatQuoteNumber(category.entity.number, "Q");
    }
    return category?.entity?.name;
  }, [category, entityType]);

  return (
    <div className={getItemClass()} onClick={handleClick}>
      <div className="item-name">{`${getCategoryNumber(entityType, category)} ${
        category?.entity?.name
      }`}</div>
      <div className="right-side">
        <ChecklistBadge progress={calcChecklistCategoryProgress(category)} />
      </div>
    </div>
  );
};

export default ChecklistsCategoryItem;
