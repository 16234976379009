import { gql } from "@apollo/client";

export const EMAIL_ATTACHMENT = gql`
  fragment EmailAttachmentData on EmailAttachment {
    _id
    file {
      _id
      name
      type
      upload_url
      size
    }
  }
`;

export const EMAIL_DATA = gql`
  fragment EmailData on Email {
    _id
    fromEmail
    toEmails
    ccEmails
    subject
    text
    html
    attachments {
      ...EmailAttachmentData
    }
    createdAt
  }
  ${EMAIL_ATTACHMENT}
`;

export const EMAIL_RECIPIENT_DATA = gql`
  fragment EmailRecipientData on EmailRecipient {
    _id
    full_name
    email
    recipientType
  }
`;

export const EMAIL_CHANNEL = gql`
  fragment EmailChannelData on EmailChannel {
    _id
    recipients {
      ...EmailRecipientData
    }
    lastUpdated
  }
  ${EMAIL_DATA}
  ${EMAIL_RECIPIENT_DATA}
`;
