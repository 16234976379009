import { gql } from "@apollo/client";

export const LIST_CONTACTS = gql`
  query ListContacts {
    listContacts {
      _id
      business_name
      abn
      last_name
      first_name
      email
      address
      address2
      state
      city
      postcode
      phone
      phone_secondary
      phase
      type
    }
  }
`;

export const LIST_CONTACTS_NAMES = gql`
  query ListContacts {
    listContacts {
      _id
      business_name
      abn
      last_name
      first_name
      email
      type
    }
  }
`;

export const GET_CONTACT = gql`
  query GetContact($id: ID!) {
    getContactDetails(contactId: $id) {
      _id
      business_name
      abn
      first_name
      last_name
      email
      phase
      phone
      mobile
      phone_secondary
      address
      address2
      city
      state
      postcode
      type
      jobs {
        _id
        name
        contractTotal
        state
        createdAt
      }
      salesQuotes {
        _id
        name
        number
        subTotal
        total
        createdAt
        status
      }
    }
  }
`;

export const GET_CONTACTS_SUPPLIERS_PHONES = gql`
  query ListContactsSuppliersNumbers {
    listSuppliers {
      _id
      business_name
      phone_number
      phone_secondary
    }
    listContacts {
      _id
      last_name
      first_name
      phone
      phone_secondary
    }
  }
`;
