export type RevokeAccessInput = {
  accessId: string;
  clientAccessId: string;
  accessType: ClientAccessType;
};

export type GrantAccessInput = {
  contactId: string;
  accessId: string;
  accessType: ClientAccessType;
};

export enum ClientAccessType {
  JOB = "JOB",
  ESTIMATION = "ESTIMATION",
  LEAD = "LEAD",
}
