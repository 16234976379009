import { WithT } from "i18next";
import { FormikProps } from "formik";
import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

export type SignUpFormValues = {
  businessName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
};

type SignUpFormBodyProps = WithT &
  Pick<
    FormikProps<SignUpFormValues>,
    "values" | "errors" | "touched" | "handleChange" | "handleBlur"
  >;

const SignUpFormBody: React.FC<SignUpFormBodyProps> = ({
  t,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const handlePhoneChange = React.useCallback(
    (e) => {
      if (e?.target?.value) {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
      }
      handleChange(e);
    },
    [handleChange]
  );

  return (
    <Container className="m-0 p-0 mw-100">
      <Row>
        <Col md={12}>
          <Form.Group controlId="businessName">
            <Form.Label className="form-input-label">
              {t("authentication.businessName")}
            </Form.Label>
            <Form.Control
              required
              key="businessName"
              onChange={handleChange}
              onBlur={handleBlur}
              name="businessName"
              isInvalid={touched.businessName && !!errors.businessName}
              value={values.businessName}
              className="form-input"
              type="text"
              placeholder={t("placeholders.businessName")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.businessName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12}>
          <Form.Group controlId="email">
            <Form.Label className="form-input-label">
              {t("authentication.emailAddress")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="email"
              isInvalid={touched.email && !!errors.email}
              value={values.email}
              className="form-input"
              type="email"
              placeholder={t("placeholders.emailAddress")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group controlId="phoneNumber">
            <Form.Label className="form-input-label">
              {t("authentication.phoneNumber")}
            </Form.Label>
            <Form.Control
              required
              key="phone"
              onChange={handlePhoneChange}
              onBlur={handleBlur}
              name="phone"
              isInvalid={touched.phone && !!errors.phone}
              value={values.phone}
              className="form-input"
              type="text"
              placeholder={t("placeholders.phoneNumber")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12}>
          <Form.Group controlId="firstName">
            <Form.Label className="form-input-label">
              {t("authentication.firstName")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="firstName"
              isInvalid={touched.firstName && !!errors.firstName}
              value={values.firstName}
              className="form-input"
              type="text"
              placeholder={t("placeholders.firstName")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group controlId="lastName">
            <Form.Label className="form-input-label">
              {t("authentication.lastName")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="lastName"
              isInvalid={touched.lastName && !!errors.lastName}
              value={values.lastName}
              className="form-input"
              type="text"
              placeholder={t("placeholders.lastName")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12}>
          <Form.Group controlId="password">
            <Form.Label className="form-input-label">
              {t("authentication.password")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              value={values.password}
              isInvalid={touched.password && !!errors.password}
              type="password"
              className="form-input"
              placeholder={t("placeholders.password")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group controlId="confirmPassword">
            <Form.Label className="form-input-label">
              {t("authentication.confirmPassword")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="confirmPassword"
              value={values.confirmPassword}
              isInvalid={touched.confirmPassword && !!errors.confirmPassword}
              type="password"
              className="form-input"
              placeholder={t("placeholders.confirmPassword")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpFormBody;
