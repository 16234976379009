import { DropResult } from "react-beautiful-dnd";
import { BaseCard, Column } from ".";

export enum CardOperation {
  CREATE = "Create",
  UPDATE = "Update",
}

export enum CardReorder {
  BETWEEN = "Between",
  INSIDE = "Inside",
  COMBINE = "Combine",
}

const getColumnById = <TItem>(id: string, columnsList: Column<TItem>[]) => {
  const column = columnsList.find((column) => column._id === id);
  if (column) return column;
};

const reorderInsideColumn = <TItem>(
  destinationColumn: Column<TItem>,
  columnsList: Column<TItem>[],
  result: DropResult,
  type?: CardReorder
) => {
  const { destination, source } = result;
  if (destination) {
    const newSet = Array.from(destinationColumn.cards);

    if (type === CardReorder.COMBINE) {
      const currentColumn = getColumnById(destination.droppableId, columnsList);
      if (currentColumn) {
        const copyOfcurrentColumn = Array.from(currentColumn.cards);
        const [removed] = copyOfcurrentColumn.splice(
          copyOfcurrentColumn.length - 1,
          1
        );
        newSet.splice(destination.index, 0, removed);
      }
    } else {
      const [removed] = newSet.splice(source.index, 1);
      newSet.splice(destination.index, 0, removed);
    }
    const newColumnsList = columnsList.map((list) => {
      if (list._id === destination.droppableId) {
        const reorderedList = { ...list, cards: [...newSet] };
        return reorderedList;
      }

      return list;
    });

    return newColumnsList;
  }
};

const reorderBetweenColumn = <TItem>(
  destinationColumn: Column<TItem>,
  sourceColumn: Column<TItem & BaseCard>,
  columnsList: Column<TItem>[],
  currentItem: TItem,
  result: DropResult
) => {
  const { destination, source, draggableId } = result;
  const newDestinationColumn = {
    ...destinationColumn,
    cards: [...destinationColumn.cards, currentItem],
  };
  const newSourceColumn = {
    ...sourceColumn,
    cards: [...sourceColumn.cards.filter((card) => card._id !== draggableId)],
  };
  if (destination) {
    const newColumnsList = columnsList.map((list) => {
      if (list._id === destination.droppableId) {
        return newDestinationColumn;
      }
      if (list._id === source.droppableId) {
        return newSourceColumn;
      }

      return list;
    });

    return newColumnsList;
  }
};

export const reorderCards = <TItem extends BaseCard>(
  columnsList: Column<TItem>[],
  result: DropResult
): {
  newColumnsList: Column<TItem>[];
  currentItem: TItem;
  reorderType: CardReorder;
} | void => {
  const { destination, source, draggableId } = result;
  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const destinationColumn = columnsList.find(
    (stage) => stage._id === destination.droppableId
  );
  const sourceColumn = columnsList.find(
    (stage) => stage._id === source.droppableId
  );
  const currentItem = sourceColumn?.cards.find(
    (card) => card._id === draggableId
  );
  //reorder inside the one column
  if (destination.droppableId === source.droppableId) {
    if (currentItem && destinationColumn) {
      const newColumnsList = reorderInsideColumn(
        destinationColumn,
        columnsList,
        result
      );
      if (newColumnsList)
        return { newColumnsList, currentItem, reorderType: CardReorder.INSIDE };
    }
  }

  if (
    destinationColumn &&
    destination.droppableId !== source.droppableId &&
    destinationColumn?.cards.length > 0 &&
    destination.index !== destinationColumn?.cards.length
  ) {
    if (currentItem && sourceColumn) {
      const tempColumns = reorderBetweenColumn(
        destinationColumn,
        sourceColumn,
        columnsList,
        currentItem,
        result
      );

      if (tempColumns) {
        const newColumnsList = reorderInsideColumn(
          destinationColumn,
          tempColumns,
          result,
          CardReorder.COMBINE
        );
        if (newColumnsList) {
          return {
            newColumnsList,
            currentItem,
            reorderType: CardReorder.COMBINE,
          };
        }
      }
    }
  }

  //reorder between columns
  if (currentItem && destinationColumn && sourceColumn) {
    const newColumnsList = reorderBetweenColumn(
      destinationColumn,
      sourceColumn,
      columnsList,
      currentItem,
      result
    );
    if (newColumnsList)
      return { newColumnsList, currentItem, reorderType: CardReorder.BETWEEN };
  }
};

export const reorderColumn = <TItem>(
  listStages: Column<TItem>[],
  result: DropResult
): Column<TItem>[] | void => {
  const { destination, source } = result;
  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }
  const newSet = Array.from(listStages);
  const [removed] = newSet.splice(source.index, 1);
  newSet.splice(destination.index, 0, removed);

  return newSet;
};
