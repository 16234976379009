import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils, ValidationRules } from "../../../utils/yup";

export default function createLeadSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(ValidationMessagesUtils.createRequiredMessage(t, "tasks.task")),
    description: yup.string(),
    stage: yup.string().required(),
    expectedCloseDate: yup.string(),
    budget: yup.number(),
    assignedTo: yup.array().of(yup.string()).required(),
    contacts: yup.array().of(yup.string()).required(),
    postcode: ValidationRules.getPostcodeRules(yup, t),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
  });
}
