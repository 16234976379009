import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";
import { JobInvoiceType } from "../../../models/job";

export default function configureClaimSchema(t: TFunction) {
  return yup.object().shape({
    invoiceType: yup
      .string()
      .oneOf([JobInvoiceType.Standard, JobInvoiceType.Retention])
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "jobs.invoiceType")
      ),
    retention: yup.object().shape({
      invoicePercentage: yup
        .number()
        .min(0)
        .max(100)
        .required(
          ValidationMessagesUtils.createRequiredMessage(
            t,
            "jobs.retentionInvoicePercentage"
          )
        ),
      totalPercentage: yup
        .number()
        .min(0)
        .max(100)
        .required(
          ValidationMessagesUtils.createRequiredMessage(
            t,
            "jobs.retentionTotalPercentage"
          )
        ),
    }),
  });
}
