import { gql } from "@apollo/client";

export const ADD_JOB_MEMBER = gql`
  mutation jobAddMember($jobId: ID!, $memberId: ID!) {
    jobAddMember(jobId: $jobId, memberId: $memberId) {
      _id
      last_name
      first_name
      email
      role
    }
  }
`;

export const REMOVE_JOB_MEMBER = gql`
  mutation jobRemoveMember($jobId: ID!, $memberId: ID!) {
    jobRemoveMember(jobId: $jobId, memberId: $memberId) {
      _id
      last_name
      first_name
      email
      role
    }
  }
`;
