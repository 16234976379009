import { Media } from "../../../models/media";
import { QuoteBusiness, SalesQuote } from "../../../models/salesQuote";
import { SupplierDetails } from "../../../models/supplier";
import { MediaInput } from "../inputs/media";
import { JobCostingItem, JobDetails } from "../../../models/job";

export enum QuoteRequestStatus {
  REQUESTED = "Requested",
  ACCEPTED = "Accepted",
  DECLINED = "Declined",
  SUBMITTED = "Submitted",
}

export enum QuoteRequestClientFeedback {
  NONE = "None",
  ACCEPTED = "Accepted",
  DECLINED = "Declined",
  SHARED = "Shared",
}

export enum QuoteRequestItemStatus {
  NONE = "none",
  REQUESTED = "requested",
  ACCEPTED = "accepted",
}

export type QuoteRequest = {
  _id: string;
  quoteNumber: string;
  name: string;
  note: string;
  scope: string;
  due_date: string;
  business: QuoteBusiness;
  attachments: Media[];
  items: QuoteRequestItem[];
  subcontractors: QuoteRequestSubcontractor[];
  salesQuote: SalesQuote;
  job: JobDetails;
  createdAt: string;
};

export type QuoteRequestItem = {
  _id: string;
  name: string;
  UOM: string;
  quantity: number;
  job_costing?: JobCostingItem;
  cost?: number;
};

export type QuoteRequestModalListItem = QuoteRequestItem & {
  quoteRequest: {
    _id: string;
    quoteNumber: number;
    due_date: string;
    status: string;
    subcontractors: {
      _id: string;
      status: string;
      items: {
        _id: string;
        cost: number;
        quoteItemId: string;
      }[];
      supplier: {
        _id: string;
        business_name: string;
      };
    }[];
  };
  id: string;
};

export type QuoteRequestCreatePayload = {
  quoteRequest: QuoteRequestPayload;
};

export type QuoteRequestUpdateSubcontractorItemsPayload = {
  quoteRequestId: string;
  subcontractorId: string;
  items: QuoteRequestCostingItemPayload[];
};

export type QuoteRequestPayload = {
  _id?: string;
  salesQuote: string;
  name: string;
  note: string;
  scope: string;
  due_date: string;
  attachments: MediaInput[];
  items: string[];
  suppliers: string[];
  plans?: string[];
};

export type QuoteRequestListResponse = {
  listQuoteRequests?: QuoteRequest[];
  subcontractorListQuoteRequests?: QuoteRequest[];
};

export type ShareQuoteRequestWithClient = {
  shareQuoteRequestResponseWithClient: {
    _id: string;
  };
};

export type QuoteRequestResponse = {
  getQuoteRequestById?: QuoteRequest;
  subcontractorGetQuoteRequestById?: QuoteRequest;
  createUpdateQuoteRequest?: QuoteRequest;
  updateQuoteRequestSubcontractorItems?: QuoteRequest;
  sendQuoteRequest?: QuoteRequest;
};

export type ListQuoteRequestMessagesResponse = {
  listQuoteRequestMessages?: QuoteRequestComment[];
};

export type QuoteRequestSendPayload = {
  quoteRequestId?: string;
  subcontractorId?: string;
};

export type QuoteRequestSubcontractor = {
  _id: string;
  supplier?: SupplierDetails;
  status: QuoteRequestStatus;
  date_submitted: string;
  client_feedback: QuoteRequestClientFeedback;
  items: QuoteRequestSubcontractorItem[];
  comments: QuoteRequestComment[];
};

export type QuoteRequestSubcontractorItem = {
  _id: string;
  quoteItemId: string;
  cost: number;
  gst_inc: boolean;
};

export type QuoteRequestComment = {
  _id: string;
  comment: string;
  sender: QuoteRequestCommentSender;
  attachments?: Media[];
  createdAt: string;
  quoteRequest?: QuoteRequest;
  subcontractorResponseId?: string;
};
export type QuoteRequestCommentSender = {
  _id: string;
  name: string;
  email: string;
  senderType: string;
};

export type QuoteRequestCostingItemPayload = {
  quoteItemId: string;
  cost: string;
  gst_inc: boolean;
};

export type QuoteRequestCostingPayload = {
  quoteRequestId?: string;
  items: QuoteRequestCostingItemPayload[];
};

export type QuoteRequestCostingResponse = {
  subcontractorSubmitQuote: QuoteRequest;
};

export type QuoteRequestCommentAttachment = {
  type: string;
  size: number;
  width?: number;
  height?: number;
};

export type QuoteRequestCommentPayload = {
  quoteRequestId: string;
  comment: {
    subcontractorResponseId: string;
    comment: string;
    attachments?: QuoteRequestCommentAttachment[];
  };
};

export type QuoteRequestCommentResponse = {
  commentQuoteRequest?: QuoteRequestComment;
  subcontractorCommentQuoteRequest?: QuoteRequestComment;
};

export type OnQuoteRequestComment = {
  comment: QuoteRequestComment;
};

export type QuoteRequestAcceptPayload = {
  quoteRequestId: string;
  subcontractorResponseId: string;
};

export type PreviewQuoteRequestResponse = {
  previewQuoteRequest: {
    _id: string;
    pdf: string;
    filename: string;
  };
};

export type PreviewQuoteRequestShareResponse = {
  previewQuoteRequestShare: {
    _id: string;
    pdf: string;
    filename: string;
  };
};

export type ClientSubcontractorItem = {
  _id: string;
  name: string;
  UOM: string;
  cost: number;
  quantity: number;
};

export type ClientSubcontractor = {
  _id: string;
  business_name: string;
  contact_person: string;
  items?: ClientSubcontractorItem[];
  client_feedback: QuoteRequestClientFeedback;
};

export type ClientQuoteRequest = {
  _id: string;
  name: string;
  subcontractors?: ClientSubcontractor[];
};

export type ClientListJobQuoteResponse = {
  clientListJobQuoteResponses: ClientQuoteRequest[];
};

export type ClientJobQuoteSubByIdResponse = {
  clientGetJobQuoteSubResponseById: ClientQuoteRequest;
};

export type ClientAcceptQuoteRequestResponse = {
  clientAcceptJobQuoteSubResponseBySub?: ClientQuoteRequest;
};
