import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Container, Col, Row } from "react-bootstrap";
import { useHistory, RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { chain, find, first } from "lodash";
import { useQuery, useMutation } from "@apollo/client";

import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import QuoteRequestList, {
  SalesQuoteRequest,
} from "../../../components/quote-requests/combined-quote-request-list";
import SubcontractorQuoteRequestList from "../../../components/quote-requests/subcontractor-quote-request-list";
import {
  QuoteRequest,
  QuoteRequestListResponse,
  QuoteRequestSubcontractor,
  ShareQuoteRequestWithClient,
} from "../../../graphql/types/models/quote-request";
import { notify } from "../../../components/notification";
import { LIST_QUOTE_REQUESTS } from "../../../graphql/queries/quote-request/queries";
import { SHARE_QUOTE_REQUEST_WITH_CLIENT } from "../../../graphql/queries/quote-request/mutations";
import { SalesQuote } from "../../../models/salesQuote";
import { SendEmailForm } from "../../../models/email";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import { formatQuoteNumber } from "../../../utils/text";

type Params = {
  id: string;
};

type QuoteRequestProps = RouteComponentProps<Params> & DashboardContextValue;

const QuoteRequestPage: React.FC<QuoteRequestProps> = ({
  setSearchOptions,
  match,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { id: quoteRequestId } = match.params;

  const { data, loading } = useQuery<QuoteRequestListResponse>(
    LIST_QUOTE_REQUESTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [shareQuoteRequest] = useMutation<ShareQuoteRequestWithClient>(
    SHARE_QUOTE_REQUEST_WITH_CLIENT
  );

  const quotes = React.useMemo(() => data?.listQuoteRequests, [data]);

  const categorisedQuotes = React.useMemo<SalesQuoteRequest[]>(() => {
    if (!quotes) return [] as SalesQuoteRequest[];

    const salesQuotes = chain(quotes.filter((item) => !!item.salesQuote))
      .groupBy((quote) => quote.salesQuote?._id)
      .map((items, key) => ({
        ...items[0].salesQuote,
        number: formatQuoteNumber(items[0].salesQuote.number, "Q"),
        quoteRequests: items,
      }))
      .value();

    const jobsQuotes = chain(quotes.filter((item) => !!item.job))
      .groupBy((quote) => quote.job?._id)
      .map((items, key) => ({
        ...items[0].job,
        number: formatQuoteNumber(items[0].job.jobNumber, "J"),
        quoteRequests: items,
      }))
      .value();

    return [...salesQuotes, ...jobsQuotes];
  }, [quotes]);

  const [
    selectedSalesQuoteRequest,
    setSelectedSalesQuoteRequest,
  ] = useState<SalesQuoteRequest | null>(null);

  const [
    selectedQuoteRequest,
    setSelectedQuoteRequest,
  ] = useState<QuoteRequest | null>(null);

  const [selectedSubcontractorsId, setSelectedSubcontractorsId] = useState<
    string[]
  >([]);

  const toggleSelectSubcontractorsId = React.useCallback(
    (id: string) => {
      const isSelected = !!selectedSubcontractorsId.find((item) => item === id);

      const newSelectedSubcontractorsId = isSelected
        ? selectedSubcontractorsId.filter((item) => item !== id)
        : selectedSubcontractorsId.concat([id]);

      setSelectedSubcontractorsId(newSelectedSubcontractorsId);
    },
    [selectedSubcontractorsId]
  );

  const resetSelectSubcontractorsId = React.useCallback(
    () => setSelectedSubcontractorsId([]),
    []
  );

  const handleShareQuoteRequest = React.useCallback(
    (data: SendEmailForm) => {
      try {
        if (!selectedQuoteRequest) return;

        shareQuoteRequest({
          variables: {
            jobId: selectedQuoteRequest.job._id,
            quoteRequestId: selectedQuoteRequest._id,
            subcontractorResponseIdList: selectedSubcontractorsId,
            message: {
              message: data.message,
              to: data.to,
              subject: data.title,
            },
          },
        });

        notify({
          title: t("quoteRequest.shareQuoteRequest"),
          content: t("quoteRequest.success.shareQuoteRequest"),
        });
      } catch (err) {
        notify({
          error: true,
          title: t("quoteRequest.shareQuoteRequest"),
          content: t("quoteRequest.error.shareQuoteRequest"),
        });
      }
    },
    [selectedQuoteRequest, selectedSubcontractorsId, shareQuoteRequest]
  );

  React.useEffect(() => {
    resetSelectSubcontractorsId();
  }, [selectedQuoteRequest]);

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("communication.search"),
      options: [],
    });

    return () => {
      setSearchOptions(null);
    };
  }, [t, setSearchOptions]);

  React.useEffect(() => {
    if (categorisedQuotes.length && !selectedSalesQuoteRequest) {
      if (quoteRequestId) {
        const quoteRequest = find(quotes, { _id: quoteRequestId });
        if (quoteRequest) {
          setSelectedQuoteRequest(quoteRequest);
          setSelectedSalesQuoteRequest(
            find(categorisedQuotes, { _id: quoteRequest.salesQuote?._id }) ||
              find(categorisedQuotes, { _id: quoteRequest.job?._id }) ||
              null
          );
        }
      } else {
        setSelectedSalesQuoteRequest(categorisedQuotes[0]);
        setSelectedQuoteRequest(categorisedQuotes[0].quoteRequests[0]);
      }
    }
  }, [
    quoteRequestId,
    quotes,
    categorisedQuotes,
    setSelectedSalesQuoteRequest,
    setSelectedQuoteRequest,
  ]);

  const handleSelect = React.useCallback(
    (quoteRequest) => {
      setSelectedQuoteRequest(quoteRequest);
      history.push(`/quote-requests/${quoteRequest._id}`);
    },
    [selectedSalesQuoteRequest, setSelectedSalesQuoteRequest]
  );

  const handleSubcontractorSelect = React.useCallback(
    (quoteRequest: QuoteRequest, subcontractor: QuoteRequestSubcontractor) => {
      // setSelectedContractorQuoteRequest(quoteRequest);
      history.push(
        `/quote-requests/${quoteRequest._id}/sub/${subcontractor._id}`
      );
    },
    []
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.QUOTE_REQUESTS_SECTION.QUOTES}
      />
      <Helmet title={t("navigation.quoteRequestSection.quoteRequests")} />

      {categorisedQuotes?.length ? (
        <Row className="h-100">
          <Col lg={4} xs={12} className="mh-100">
            {selectedQuoteRequest && (
              <QuoteRequestList
                title={t("quoteRequest.quoteRequests")}
                salesQuoteRequests={categorisedQuotes}
                selectedQuoteRequest={selectedQuoteRequest}
                onSelect={handleSelect}
              />
            )}
          </Col>
          <Col lg={8} xs={12} className="mh-100">
            {selectedQuoteRequest && (
              <SubcontractorQuoteRequestList
                title={t("quoteRequest.quoteRequests")}
                quoteRequest={selectedQuoteRequest}
                selectedSubcontractorsId={selectedSubcontractorsId}
                onSelect={handleSubcontractorSelect}
                toggleSelectSubcontractorsId={toggleSelectSubcontractorsId}
                handleShareQuoteRequest={handleShareQuoteRequest}
                resetSelectSubcontractorsId={resetSelectSubcontractorsId}
              />
            )}
          </Col>
        </Row>
      ) : (
        <EmptyPlaceholder message={t("quoteRequest.emptyPlaceholder")} />
      )}
    </Container>
  );
};

export default withRouter(withDashboardContext(QuoteRequestPage));
