import { gql } from "@apollo/client";
import { JOB_MESSAGE_FRAGMENT } from "./fragments";

export const JOB_CHANNEL_COMPOSE = gql`
  mutation JobChannelCompose(
    $channelId: ID!
    $jobId: ID!
    $message: MessageInput
  ) {
    jobChannelCompose(channelId: $channelId, jobId: $jobId, message: $message) {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_FRAGMENT}
`;

export const JOB_DELETE_MESSAGE = gql`
  mutation jobChannelDeleteMessage(
    $channelId: ID!
    $jobId: ID!
    $messageId: ID!
  ) {
    jobChannelDeleteMessage(
      channelId: $channelId
      jobId: $jobId
      messageId: $messageId
    ) {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_FRAGMENT}
`;
