import React, { useState } from "react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import CreateEntityModal from "../../modals/create-entity";
import UpdateEntityModal from "../../modals/update-entity";
import { createCategoryItemField } from "./utils";
import {
  FormikPropGetSetValues,
  GenericFormFields,
} from "../../generic-form/GenericFormBody";
import {
  CreateSelectionCategoryItemPayload,
  SelectionCategoryItemType,
} from "../../../graphql/types/models/selections";
import { SalesQuoteCategory } from "../../../models/salesQuote";
import createCategoryItemSchema from "./CreateCategoryItem.schema";
import { useApolloClient } from "@apollo/client";
import { useCostingSearchQuery } from "../../../hooks/queries/useCostingSearchQuery";
import { SearchCostingItemType } from "../../../graphql/types/models/price-list";
import { SelectOption } from "../../generic-form/inputs/creatable-select";

type CreateCategoryItemModalProps = {
  data?: SelectionCategoryItemType | null;
  show: boolean;
  onSubmit: (data: CreateSelectionCategoryItemPayload) => void;
  onClose: () => void;
  costingCategories: SalesQuoteCategory[];
  isTemplate?: boolean;
};

const CreateUpdateCategoryItemModal: React.FC<CreateCategoryItemModalProps> = (
  props
) => {
  const {
    data,
    show,
    onSubmit,
    onClose,
    costingCategories,
    isTemplate,
  } = props;
  const { t } = useTranslation();
  const client = useApolloClient();

  const { searchCostingItem } = useCostingSearchQuery(
    client,
    SearchCostingItemType.PRICE_LIST
  );

  const [loadedPriceOptions, setLoadedPriceOptions] = React.useState<
    SelectOption[]
  >([]);
  const [fields, setFields] = useState<
    GenericFormFields<CreateSelectionCategoryItemPayload>
  >({});
  const [costingCategoryId, setCostingCategoryId] = React.useState("");

  const handleCostingCategoryChange = React.useCallback(
    (costingCategoryId: string, formikProps: FormikPropGetSetValues) => {
      if (costingCategoryId) {
        const { values, setFieldValue } = formikProps;
        const currentCategrory = costingCategories.find(
          (category) => category._id === costingCategoryId
        );
        if (currentCategrory) {
          if (
            currentCategrory.costings
              .map((item) => item._id)
              .includes(values["costingItemEntityId"])
          )
            return setCostingCategoryId(costingCategoryId);
          else {
            setFieldValue("costingItemEntityId", "");
            setCostingCategoryId(costingCategoryId);
          }
        }
      }
    },
    [costingCategories]
  );

  const handleCostingItemNameChange = React.useCallback(
    (costingCategoryId: string, formikProps: FormikPropGetSetValues) => {
      const selectedItem = loadedPriceOptions.find(
        (option) => option.value === costingCategoryId
      );
      if (selectedItem) {
        formikProps.setFieldValue("costingItemEntityName", selectedItem.label);
        return formikProps.setFieldValue(
          "costingCategoryEntityId",
          selectedItem.value.split(":")[1]
        );
      } else {
        const selectedItem = loadedPriceOptions.find(
          (option) => option.label === costingCategoryId
        );
        if (selectedItem) {
          formikProps.setFieldValue(
            "costingItemEntityName",
            selectedItem.label
          );
          return formikProps.setFieldValue(
            "costingCategoryEntityId",
            selectedItem.value.split(":")[1]
          );
        }
      }

      formikProps.setFieldValue("costingCategoryEntityId", null);
    },
    [loadedPriceOptions]
  );

  const costingItems = React.useMemo(() => {
    const currentCategrory = costingCategories.find(
      (category) => category._id === costingCategoryId
    );
    if (currentCategrory) {
      return currentCategrory.costings;
    }
    return [];
  }, [costingCategories, costingCategoryId]);

  const handlePriceSearch = React.useCallback(async (value: string) => {
    const result = await searchCostingItem(value);
    setLoadedPriceOptions(result);
    return result;
  }, []);

  React.useEffect(() => {
    setFields(
      createCategoryItemField(
        t,
        costingItems,
        costingCategories,
        handleCostingCategoryChange,
        costingCategoryId,
        handlePriceSearch,
        handleCostingItemNameChange,
        isTemplate
      )
    );
  }, [
    costingItems,
    costingCategories,
    costingCategoryId,
    isTemplate,
    loadedPriceOptions,
  ]);

  const handleSubmit = React.useCallback(
    (
      data: CreateSelectionCategoryItemPayload,
      helpers?: FormikHelpers<any>
    ) => {
      onSubmit &&
        onSubmit({
          ...omit(data, ["costingCategoryEntityId"]),
          ...(Boolean(data?.costingCategoryEntityId) && {
            costingCategoryEntityId: data?.costingCategoryEntityId,
          }),
        });
      setCostingCategoryId("");
      helpers?.resetForm();
    },
    [onSubmit]
  );

  const handleOnClose = React.useCallback(() => {
    onClose();
    setCostingCategoryId("");
  }, [onClose]);

  return (
    <>
      {data ? (
        <UpdateEntityModal<CreateSelectionCategoryItemPayload>
          validationSchema={createCategoryItemSchema(t, isTemplate)}
          title={t("selections.editCategoryItem")}
          data={{
            name: data.name || "",
            description: data.description || "",
            dueDate: data.dueDate || "",
            costingCategoryEntityId: data?.costingCategoryEntityId || "",
            costingItemEntityName: data?.costingItemEntityName || "",
            costingItemEntityId: data?.costingItem?._id || "",
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={handleOnClose}
        />
      ) : (
        <CreateEntityModal<CreateSelectionCategoryItemPayload>
          validationSchema={createCategoryItemSchema(t, isTemplate)}
          title={t("selections.createNewSelectionItem")}
          data={{
            name: "",
            description: "",
            dueDate: "",
            costingItemEntityId: "",
            costingCategoryEntityId: "",
            ...(!isTemplate && { costingItemEntityName: "" }),
          }}
          show={show}
          fields={fields}
          onSubmit={handleSubmit}
          onClose={handleOnClose}
        />
      )}
    </>
  );
};

export default CreateUpdateCategoryItemModal;
