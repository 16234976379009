import React from "react";
import { Route, Switch } from "react-router-dom";
import Billing from "../billing/overview";
import BillingPlan from "../billing/plan";
import CompanyDetails from "../company";
import TeamOverview from "../team/team-overview";
import PriceListOverview from "../price-list/overview";
import PriceListDetails from "../price-list/details";
import SiteDiaryOverview from "../site-diary/overview";
import IntegrationsOverview from "../integrations/overview";
import Assemblies from "../assemblies";
import EstimationTemplatesOverview from "../estimation-templates";
import TakeoffTemplatesOverview from "../takeoff-templates";
import SelectionTemplatesOverview from "../selection-templates";
import SelectionTemplate from "../selection-templates/edit-selection-template";
import ChecklistTemplatesOverview from "../checklist-templates";
import ChecklistTemplate from "../checklist-templates/checklist-template";
import SpecTemplatesOverview from "../specification-templates";
import SpecificationTemplate from "../specification-templates/spec-template";
import ScheduleTemplatesOverview from "../schedule-templates";
import TemplatesList from "../templates";

const SettingsContainer = () => {
  return (
    <Switch>
      <Route exact path="/settings/billing" component={Billing} />
      <Route path="/settings/billing/plans" component={BillingPlan} />
      <Route path="/settings/company" component={CompanyDetails} />
      <Route path="/settings/team" component={TeamOverview} />
      <Route path="/settings/price-lists/:id" component={PriceListDetails} />
      <Route path="/settings/price-lists" component={PriceListOverview} />
      <Route path="/settings/assemblies" component={Assemblies} />
      <Route path="/settings/site-diaries" component={SiteDiaryOverview} />
      <Route path="/settings/templates" component={TemplatesList} />
      <Route
        path="/settings/estimation-templates"
        component={EstimationTemplatesOverview}
      />
      <Route
        path="/settings/takeoff-templates"
        component={TakeoffTemplatesOverview}
      />
      <Route
        path="/settings/selection-templates/:id"
        component={SelectionTemplate}
      />
      <Route
        path="/settings/selection-templates"
        component={SelectionTemplatesOverview}
      />
      <Route
        path="/settings/schedule-templates"
        component={ScheduleTemplatesOverview}
      />
      <Route
        path="/settings/checklist-templates/:id"
        component={ChecklistTemplate}
      />
      <Route
        path="/settings/checklist-templates"
        component={ChecklistTemplatesOverview}
      />
      <Route
        path="/settings/specification-templates/:id"
        component={SpecificationTemplate}
      />
      <Route
        path="/settings/specification-templates"
        component={SpecTemplatesOverview}
      />
      <Route
        path="/settings/integrations"
        exact
        component={IntegrationsOverview}
      />
      <Route
        path="/settings/integrations/:provider-callback"
        component={IntegrationsOverview}
      />
    </Switch>
  );
};

export default SettingsContainer;
