import * as yup from "yup";
import { TFunction } from "i18next";

import { find, trim } from "lodash";
import { ValidationMessagesUtils } from "../../../utils/yup";
import { SpecTemplate } from "../../../models/specification";

export default function saveSpecAsTemplateSchema(
  t: TFunction,
  templates: SpecTemplate[]
) {
  return yup.object().shape({
    name: yup
      .string()
      .test(
        "duplicateNameTest",
        ({ value }) => t("specifications.templateNameExists", { name: value }),
        function (value: string) {
          if (!value) return true;
          const name = trim(value);
          return !find(templates, { name });
        }
      )
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "specifications.templateName"
        )
      ),
  });
}
