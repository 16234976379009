import { ApolloClient, MutationUpdaterFn } from "@apollo/client";
import { last } from "lodash";
import { GET_PRICE_LIST, SEARCH_PRICE_LIST_ITEM } from "./queries";
import { PRICE_ITEM_FRAGMENT } from "./fragments";
import {
  DeletePriceListCategory,
  SearchPriceListItemResponse,
} from "../../types/models/price-list";
import { PriceListItem } from "../../../models/price-list";

export const handlePriceListCategoryDelete = (
  priceListId?: string
): MutationUpdaterFn<DeletePriceListCategory> => (cache, response) => {
  const priceList = response?.data?.deletePriceItemsByCategory;

  cache.writeQuery({
    query: GET_PRICE_LIST,
    data: {
      getPrice: priceList,
    },
    variables: {
      priceListId,
    },
  });
};

// @deprecated
export const findPriceItemFromCache = (
  client: ApolloClient<any>,
  priceItemId: string
): PriceListItem | null => {
  if (priceItemId.indexOf("price:") < 0) return null;

  const id = `PriceItem:${last(priceItemId.split(":"))}`;
  const priceItem = client?.readFragment({
    id,
    fragment: PRICE_ITEM_FRAGMENT,
  });
  return priceItem;
};

export const searchPriceItem = async (
  client: ApolloClient<any>,
  search: string
): Promise<PriceListItem[]> => {
  const { data } = await client.query<SearchPriceListItemResponse>({
    query: SEARCH_PRICE_LIST_ITEM,
    variables: { search },
    fetchPolicy: "cache-first",
  });
  return data.searchPriceItem;
};
