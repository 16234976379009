import { gql } from "@apollo/client";
import { NOTE_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_NOTE = gql`
  mutation($entityType: NoteEntityType!, $entityId: ID!, $note: NoteInput!) {
    createUpdateNote(
      entityType: $entityType
      entityId: $entityId
      note: $note
    ) {
      ...NoteFragment
    }
  }
  ${NOTE_FRAGMENT}
`;

export const DELETE_NOTE = gql`
  mutation($entityType: NoteEntityType!, $entityId: ID!, $noteId: ID!) {
    deleteNote(entityType: $entityType, entityId: $entityId, noteId: $noteId) {
      ...NoteFragment
    }
  }
  ${NOTE_FRAGMENT}
`;
