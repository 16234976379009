import { gql } from "@apollo/client";

export const JOB_CONTACT = gql`
  fragment JobContact on JobContact {
    _id
    clientAccess {
      _id
    }
    contact {
      _id
      first_name
      last_name
      email
    }
  }
`;
