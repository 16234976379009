export default `
body {
  width: 100%;
  padding: 20px;
}

.site-diary-title {
  color: #314961;
  display: block;
}

.site-diary-entry-overview {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 15px 0;
}

.site-diary-entry-overview-item {
  display: flex;
  flex-direction: row;
  color: #314961;
  font-weight: 500;
  align-items: center;
  margin-right: 20px;
}

.material-icons {
  display: inline-block !important;
  width: 24px;
  height: 24px;
  color: #000;
}

.site-diary-entry-note {
  color: #314961;
}

.site-diary-entry-question {
  color: #7d829e;
  font-size: 24px;
  margin: 20px 0;
  display: block !important;
}

.site-diary-entry-id {
  float: left;
}

.photo {
  margin-bottom: 20px;
  display: block;
}

.photo img {
  max-height: 100% !important;
  max-width: 100% !important;
  border: 1px solid black;
  display: block;
}
`;
