import React from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "react-bootstrap";
import { get } from "lodash";
import classNames from "classnames";
import { AutocompleteMenuOptionProps } from "./AutocompleteMenuOption";
import "./styles.scss";

const CostingAutocompleteMenuOption: React.FC<AutocompleteMenuOptionProps> = (
  props
) => {
  const { t } = useTranslation();
  const { innerProps, children, data, isSelected, isFocused } = props;

  const assembly =
    get(data, "data.type") === "assembly" ||
    get(data, "data.__typename") === "Assembly";
  const price = get(data, "data.price.name");
  const category = get(data, "data.category.name");

  let badge = null;
  if (price && category) {
    badge = (
      <Badge variant="info">
        {price}: {category}
      </Badge>
    );
  } else if (assembly) {
    badge = <Badge variant="primary">{t("assembly.assembly")}</Badge>;
  } else if (category) {
    badge = <Badge variant="primary">{category}</Badge>;
  }

  return (
    // @ts-ignore
    <div
      {...innerProps}
      className={classNames("option", {
        "option-badged": !!badge,
        "option-selected": (isSelected && !data.__isNew__) || isFocused,
      })}
    >
      <div className="option-badged-group">
        {badge}
        {children}
      </div>
    </div>
  );
};

export default CostingAutocompleteMenuOption;
