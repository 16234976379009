import React from "react";
import classNames from "classnames";
import { Badge, BadgeProps } from "react-bootstrap";
import "./styles.scss";
import {
  QuoteRequest,
  QuoteRequestStatus,
} from "../../../graphql/types/models/quote-request";
import { formatQuoteNumber } from "../../../utils/text";
import { SalesQuoteRequest } from ".";
import { map } from "lodash";
import Icon from "../../icons/Icon";

type QuoteRequestStatusBadgeProps = {
  status: QuoteRequestStatus;
};
const QuoteRequestStatusBadge: React.FC<QuoteRequestStatusBadgeProps> = ({
  status,
}) => {
  let variant: BadgeProps["variant"] = "info";
  let text = "";
  switch (status) {
    case QuoteRequestStatus.ACCEPTED:
      variant = "primary";
      text = "Accepted";
      break;
    case QuoteRequestStatus.SUBMITTED:
      variant = "info";
      text = "Submitted";
      break;
    case QuoteRequestStatus.DECLINED:
      variant = "danger";
      text = "Declined";
      break;
    case QuoteRequestStatus.REQUESTED:
    default:
      variant = "warning";
      text = "Requested";
      break;
  }
  return <Badge variant={variant}>{text}</Badge>;
};

type QuoteRequestItemProps = {
  salesQuoteRequest: SalesQuoteRequest;
  onSelect?: (quoteRequest: QuoteRequest) => void;
  isOpen?: boolean;
  selectedQuoteRequest?: QuoteRequest | null;
};

const QuoteRequestItem: React.FC<QuoteRequestItemProps> = ({
  salesQuoteRequest,
  onSelect,
  selectedQuoteRequest,
  isOpen: isOpenDefault,
}) => {
  const [isOpen, setIsOpen] = React.useState(!!isOpenDefault);

  const containerClasses = classNames(
    {
      "quote-request-sq-list--open": isOpen,
    },
    "quote-request-sq-list"
  );

  const toggleQuoteRequests = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const handleSelectQuoteRequest = React.useCallback(
    (quoteRequest) => {
      onSelect && onSelect(quoteRequest);
    },
    [onSelect]
  );

  return (
    <div className={containerClasses}>
      <div
        className="field-text quote-request-sq-item"
        onClick={toggleQuoteRequests}
      >
        <b
          className="quote-request-sq-item--title"
          title={salesQuoteRequest.name}
        >
          {salesQuoteRequest.name}
        </b>
        <span>({salesQuoteRequest.number})</span>
        <div style={{ flex: 1 }} />
        <Icon name={isOpen ? "expand_less" : "expand_more"} />
      </div>

      <ul className="quote-request-quotes-list">
        {map(salesQuoteRequest.quoteRequests, (quoteRequest) => (
          <li
            className={classNames("field-text quote-request-quote-item", {
              "quote-request-quotes-list--selected":
                selectedQuoteRequest?._id == quoteRequest._id,
            })}
            onClick={() => handleSelectQuoteRequest(quoteRequest)}
          >
            <div className="quote-request-quote-item--title">
              {quoteRequest.name}{" "}
              <span>({formatQuoteNumber(quoteRequest.quoteNumber)})</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuoteRequestItem;
