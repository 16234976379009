import React from "react";
import { Button } from "react-bootstrap";
import DashboardCard from "../dashboard/card";
import DashboardCardBody from "../dashboard/card/DashboardCardBody";
import Icon from "../icons/Icon";
import "./styles.scss";

export type ButtonConfig = {
  buttonText?: string;
  buttonIcon?: string;
  onButtonPress?: () => void;
}[];

type EmptyPlaceholderProps = {
  message: string;
  buttonText?: string;
  buttonIcon?: string;
  onButtonPress?: () => void;
  buttonConfig?: ButtonConfig;
};
const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = ({
  message,
  buttonText,
  buttonIcon = "add",
  onButtonPress,
  buttonConfig,
}) => {
  return (
    <DashboardCard className="empty-placeholder">
      <DashboardCardBody>
        <div className="field-text empty-message">{message}</div>
        {onButtonPress && (
          <div className="button-container">
            <Button
              variant="primary"
              className="button large success fit"
              onClick={onButtonPress}
            >
              <Icon name={buttonIcon} />
              {buttonText}
            </Button>
          </div>
        )}
        {buttonConfig && (
          <div className="button-container">
            {buttonConfig.map(
              (item, index) =>
                item.onButtonPress && (
                  <Button
                    key={index}
                    variant="primary"
                    className="button large success fit"
                    onClick={item.onButtonPress}
                  >
                    <Icon name={item.buttonIcon || "add"} outlined />
                    {item.buttonText}
                  </Button>
                )
            )}
          </div>
        )}
      </DashboardCardBody>
    </DashboardCard>
  );
};
export default EmptyPlaceholder;
