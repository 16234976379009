import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { filter, map } from "lodash";
import moment from "moment";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useQuery, useLazyQuery } from "@apollo/client";
import { FormikProps } from "formik";
import {
  GenericFormFields,
  renderField,
} from "../../generic-form/GenericFormBody";
import FormField from "../../generic-form/form-field";
import ModalForm from "../../modals/modal-form";
import { SupplierListResponse } from "../../../graphql/types/models/supplier";
import { LIST_SUPPLIERS } from "../../../graphql/queries/supplier/queries";
import createQuoteRequestSchema from "./QuoteRequestSchema";
import { TableCardData } from "../../dashboard/table-card/utils";
import CardTable from "../../dashboard/table-card/CardTable";
import PlanSelection from "./PlanSelection";
import ModalAttachment, {
  ModalAttachmentRef,
} from "../../modals/modal-attachment";
import { createQuoteRequestFields } from "./utils";
import { QuoteFileListResponse } from "../../../graphql/types/models/quote";
import { GET_SALES_QUOTE } from "../../../graphql/queries/quote/queries";
import { QuoteRequestPayload } from "../../../graphql/types/models/quote-request";
import CreateSupplierModal, {
  CreateSupplierModalRef,
} from "../../contacts/create-supplier-modal";
import "./styles.scss";

export enum QuoteRequestType {
  JOB = "JOB",
  SALES_QUOTE = "SALES_QUOTE",
}

export type QuoteRequestTableItem = {
  _id: string;
  name: string;
  quantity: number;
  UOM: string;
  assemblyId?: string;
};

type QuoteRequestModalProps = {
  itemId: string;
  type: QuoteRequestType;
  itemsForQuote?: QuoteRequestTableItem[];
  onSubmit: (data: any) => void; //CreateCostingCategoryPayload
};

export type QuoteRequestRef = {
  show: (show: boolean) => void;
};
const QuoteRequestModal: React.FC<QuoteRequestModalProps> = (props, ref) => {
  const { t } = useTranslation();
  const { itemId, type, onSubmit, itemsForQuote } = props;

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<QuoteRequestPayload>
  >({});

  const [showModal, setShowModal] = useState(false);

  const modalAttachmentRef = useRef<ModalAttachmentRef>(null);
  const supplierRef = React.useRef<CreateSupplierModalRef>(null);

  useImperativeHandle(ref, () => ({
    show: (show: boolean) => setShowModal(show),
  }));

  const { data: suppliersList, loading: suppliersLoading } = useQuery<
    SupplierListResponse
  >(LIST_SUPPLIERS);

  const [getSalesQuoteFile, { data: quoteFiles }] = useLazyQuery<
    QuoteFileListResponse
  >(GET_SALES_QUOTE, {
    variables: {
      salesQuoteId: itemId,
    },
  });

  React.useEffect(() => {
    if (type === QuoteRequestType.SALES_QUOTE) {
      getSalesQuoteFile();
    }
  }, [type, getSalesQuoteFile]);

  const handleSubmit = React.useCallback((values: any) => {
    return onSubmit(values);
  }, []);

  const tableData = React.useMemo<TableCardData<QuoteRequestTableItem>>(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("costing.name"),
          formatValue: (row: any, column: any, value: number) => value,
        },
        {
          valueKey: "quantity",
          title: t("costing.quantity"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.quantity", { amount: value }),
        },
        {
          valueKey: "UOM",
          title: t("costing.uom"),
          formatValue: (row: any, column: any, value: number) => value,
        },
      ],
      rows: map(itemsForQuote, (item: QuoteRequestTableItem) => ({
        cells: item,
      })),
    };
  }, [itemsForQuote]);

  const attachmentData = React.useCallback((formikProps) => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("quoteRequest.fileName"),
          formatValue: (row: any, column: any, value: number) => value,
        },
        {
          valueKey: "size",
          title: t("quoteRequest.fileSize"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.fileSize", { amount: value }),
          // value,
        },
      ],
      rows: map(formikProps.values.attachments, (item: File) => ({
        cells: item,
      })),
    } as TableCardData<File>;
  }, []);

  const handleRemoveAttachment = React.useCallback(
    (formikProps: FormikProps<any>, file: File) => {
      formikProps.setFieldValue(
        "attachments",
        filter(formikProps.values.attachments, (f) => f !== file)
      );
    },
    []
  );

  const tableRowActions = React.useCallback(
    (formikProps: FormikProps<any>) => {
      return [
        {
          icon: "clear",
          onClick: (file: File) => handleRemoveAttachment(formikProps, file),
          className: "field-text",
        },
      ];
    },
    [handleRemoveAttachment]
  );

  const openSupplierModal = React.useCallback(
    () => supplierRef.current?.show(true),
    [supplierRef]
  );

  React.useEffect(() => {
    setFormFields(
      createQuoteRequestFields(
        t,
        openSupplierModal,
        suppliersList?.listSuppliers
      )
    );
  }, [t, itemsForQuote, suppliersList]);

  const plans = useMemo(() => quoteFiles?.getSalesQuote?.files || [], [
    quoteFiles,
  ]);

  const handleAttachment = useCallback(
    () => modalAttachmentRef?.current?.open(),
    [modalAttachmentRef]
  );

  const renderLeftFooter = useMemo(() => {
    return (
      <>
        <Button
          variant="secondary"
          className="button large large-wide info"
          onClick={handleAttachment}
        >
          {t("quoteRequest.addAttachment")}
        </Button>
        <div style={{ flex: 1 }} />
      </>
    );
  }, [handleAttachment]);

  const initialValues = React.useMemo(() => {
    if (type === QuoteRequestType.JOB)
      return {
        job: itemId,
        name: "",
        suppliers: [],
        scope: "",
        note: "",
        due_date: moment().add(7, "days"),
        plans: plans.map((p) => p._id),
        attachments: [],
        items: map(itemsForQuote, (i) => ({
          costingId: i.assemblyId ?? i._id,
          assemblyItemId: i.assemblyId ? i._id : null,
        })),
      };

    if (type === QuoteRequestType.SALES_QUOTE)
      return {
        salesQuote: itemId,
        name: "",
        suppliers: [],
        scope: "",
        note: "",
        due_date: moment().add(7, "days"),
        plans: plans.map((p) => p._id),
        attachments: [],
        items: map(itemsForQuote, (i) => ({
          costingId: i.assemblyId ?? i._id,
          assemblyItemId: i.assemblyId ? i._id : null,
        })),
      };

    return {};
  }, [itemsForQuote, itemId, type]);

  return (
    <ModalForm
      validationSchema={createQuoteRequestSchema(t)}
      className="quote-request-modal"
      title={t("quoteRequest.quoteRequest")}
      show={showModal}
      data={initialValues}
      onSubmit={handleSubmit}
      onClose={() => setShowModal(false)}
      submitText={t("common.save")}
      leftFooterRenderer={() => renderLeftFooter}
    >
      {(formikProps) => (
        <Container className="generic-form-body" fluid>
          <Row>
            <Col lg={6} md={12}>
              <Row>{renderField(formikProps, formFields.title, 12)}</Row>

              <Row>{renderField(formikProps, formFields.suppliers, 12)}</Row>

              <Row>{renderField(formikProps, formFields.due_date, 12)}</Row>

              {/* <Row className="unclaimed-message">{getUnClaimedMessage()}</Row> */}
              <Row>{renderField(formikProps, formFields.scope, 12)}</Row>
              <ModalAttachment
                ref={modalAttachmentRef}
                formikProps={formikProps}
              />
              {!!formikProps.values?.attachments?.length && (
                <Row>
                  <Col xs={12} className="form-column">
                    <FormField
                      name="attachments"
                      label={t("common.attachments")}
                    >
                      <CardTable
                        table={attachmentData(formikProps)}
                        rowActions={tableRowActions(formikProps)}
                        showCountTitle={true}
                        tableClass="table-outline table-attachments"
                      />
                    </FormField>
                  </Col>
                </Row>
              )}
            </Col>
            <Col lg={6} md={12}>
              <Row>
                <Col xs={12} className="form-column">
                  <FormField name="items" label={t("costing.quoteItems")}>
                    <CardTable
                      table={tableData}
                      showCountTitle={true}
                      tableClass="table-outline"
                    />
                  </FormField>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="form-column">
                  {type === QuoteRequestType.SALES_QUOTE && (
                    <FormField name="plans" label={t("plansSection.plans")}>
                      <PlanSelection plans={plans} formikProps={formikProps} />
                    </FormField>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <CreateSupplierModal ref={supplierRef} />
        </Container>
      )}
    </ModalForm>
  );
};

export default forwardRef(QuoteRequestModal);
