const COLOURS = [
  "#0074D9",
  "#7FDBFF",
  "#39CCCC",
  "#3D9970",
  "#2ECC40",
  "#01FF70",
  "#FFDC00",
  "#FF851B",
  "#FF4136",
  "#F012BE",
  "#B10DC9",
  "#85144B",
  "#111111",
  "#001F3F",
];

export const EXTRA_COLOURS = [
  "#B6D53C",
  "#71AA34",
  "#397B44",
  "#69c976",
  "#3D9970",
  "#2ECC40",
  "#01FF70",
  "#A0938E",
  "#CFC6B8",
  "#DFF6F5",
  "#8AEBF1",
  "#28CCDF",
  "#3978A8",
  "#0074D9",
  "#7FDBFF",
  "#5b6ee1",
  "#8E478C",
  "#CD6093",
  "#FFAEB6",
  "#a34474",
  "#B10DC9",
  "#d77bba",
  "#F4B41B",
  "#F47E1B",
  "#E6482E",
  "#A93B3B",
  "#d95763",
  "#7A444A",
  "#A05B53",
  "#BF7958",
  "#EEA160",
  "#F4CCA1",
];

export default COLOURS;
