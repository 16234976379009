import {
  CreateSupplierResponse,
  DeleteSupplierResponse,
  SupplierListCategoriesResponse,
  SupplierListResponse,
} from "../../types/models/supplier";
import { LIST_SUPPLIERS, LIST_SUPPLIERS_CATEGORIES } from "./queries";
import { MutationUpdaterFn } from "@apollo/client";

export const handleSupplierAdd: MutationUpdaterFn<CreateSupplierResponse> = (
  cache,
  { data }
) => {
  const createUpdateSupplier = data?.createUpdateSupplier;
  const cacheData = cache.readQuery<SupplierListResponse>({
    query: LIST_SUPPLIERS,
  });
  if (!cacheData || !createUpdateSupplier) {
    return;
  }

  const { listSuppliers } = cacheData;
  cache.writeQuery({
    query: LIST_SUPPLIERS,
    data: {
      listSuppliers: listSuppliers.concat([createUpdateSupplier]),
    },
  });

  const suppliersCategoriesCache: SupplierListCategoriesResponse | null = cache.readQuery(
    {
      query: LIST_SUPPLIERS_CATEGORIES,
    }
  );

  if (!suppliersCategoriesCache || !createUpdateSupplier.category) {
    return;
  }

  const { listSupplierCategories } = suppliersCategoriesCache;

  cache.writeQuery({
    query: LIST_SUPPLIERS_CATEGORIES,
    data: {
      listSupplierCategories: listSupplierCategories.concat([
        createUpdateSupplier.category,
      ]),
    },
  });
};

export const handleSupplierDelete: MutationUpdaterFn<DeleteSupplierResponse> = (
  cache,
  { data }
) => {
  const deleteSupplier = data?.deleteSupplier;

  const cacheData: SupplierListResponse | null = cache.readQuery({
    query: LIST_SUPPLIERS,
  });

  if (!cacheData || !deleteSupplier) {
    return;
  }

  const { listSuppliers } = cacheData;

  cache.writeQuery({
    query: LIST_SUPPLIERS,
    data: {
      listSuppliers: listSuppliers.filter(
        (supplier) => supplier._id !== deleteSupplier?._id
      ),
    },
  });
};
