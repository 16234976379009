import { gql } from "@apollo/client";
import { BUSINESSES_DETAILS_FRAGMENT } from "./fragments";

export const WB_LIST_BUSINESS = gql`
  query {
    businesses: wbAdminListBusinesses {
      ...BusinessDetailsRequest
    }
  }
  ${BUSINESSES_DETAILS_FRAGMENT}
`;

export const WB_LIST_GET_BUSINESS_BY_ID = gql`
  query($businessId: ID!) {
    business: wbAdminGetBusinessById(businessId: $businessId) {
      ...BusinessDetailsRequest
    }
  }
  ${BUSINESSES_DETAILS_FRAGMENT}
`;
