import React from "react";
import { useApolloClient } from "@apollo/client";
import { useDownloadFile } from "../useDownloadFile";
import { EXPORT_TAKE_OFF_ITEMS } from "../../graphql/queries/take-off/queries";
import {
  ExportTakeoffItemsRequest,
  ExportTakeoffItemsResponse,
} from "../../graphql/types/models/take-off";

export function useTakeOffItemsExportQuery() {
  const { downloadFile } = useDownloadFile();
  const client = useApolloClient();

  const exportTakeOffItems = React.useCallback(async (salesQuoteId: string) => {
    try {
      const result = await client.query<
        ExportTakeoffItemsResponse,
        ExportTakeoffItemsRequest
      >({
        query: EXPORT_TAKE_OFF_ITEMS,
        variables: {
          salesQuoteId,
        },
        fetchPolicy: "network-only",
      });
      const exportData = result?.data?.exportTakeOffItems;
      downloadFile(exportData.data, exportData.filename);
    } catch (e) {}
  }, []);

  return { exportTakeOffItems };
}
