import { Email } from "../models/email";
import { MEMBER_1, MEMBER_2, MEMBER_3 } from "./conversations";

const EMAILS: Email[] = [
  {
    _id: "1",
    author: MEMBER_1,
    subject: "Next Steps",
    to: [MEMBER_2, MEMBER_3],
    message:
      "The structure is now all in place, which means that the next \n" +
      "step is to bring in those in charge of the plastering to ensure that we maintain a steady rate of progress. \n" +
      "\n When do you think this can be arranged by? Blake",
    createdAt: new Date().toString(),
  },
  {
    _id: "2",
    subject: "Site Manager",
    author: MEMBER_2,
    to: [MEMBER_3],
    message: "Who do we have in place as the dedicated site manage",
    createdAt: new Date().toString(),
  },
  {
    _id: "3",
    author: MEMBER_1,
    subject: "Subject",
    to: [MEMBER_3, MEMBER_2],
    message:
      "This makes sense and that’s certainly what \n  we had in mind for the rollout of the next stage of development.",
    createdAt: new Date().toString(),
  },
  {
    _id: "4",
    author: MEMBER_3,
    subject: "Inclement Weather ",
    to: [MEMBER_2, MEMBER_1],
    message: "Do we expect the upcoming inclement weather to delay",
    createdAt: new Date().toString(),
  },
];

export default EMAILS;
