import React from "react";
import { TFunction } from "i18next";
import { DashboardNavigationContext } from "../../../../containers/layouts/dashboard/DashboardContext";
import { SidebarNavItem } from "../SidebarNavListItem";

import Icon from "../../../icons/Icon";
import SVGIcon from "../../../icons/SVGIcon";

export const NAVIGATION_ROUTES = {
  DASHBOARD: "DASHBOARD",
  SIGNUPS_SECTION: {
    SIGNUPS: "SIGNUPS",
  },
  ACCOUNT: {
    ACCOUNT: "ACCOUNT",
    MY_PROFILE: "MY_PROFILE",
    LOGOUT: "LOGOUT",
  },
};

export const createAffiliateNavItems = (
  t: TFunction,
  context: DashboardNavigationContext | null
): SidebarNavItem[] => {
  return [
    // {
    //   id: NAVIGATION_ROUTES.DASHBOARD,
    //   icon: () => <Icon name="home" outlined />,
    //   title: t("navigation.dashboard"),
    //   url: "/",
    // },
    {
      id: NAVIGATION_ROUTES.SIGNUPS_SECTION.SIGNUPS,
      icon: () => <SVGIcon name="Bill" />,
      title: t("navigation.affiliatesSection.signups"),
      url: "/signups",
    },
    {
      id: NAVIGATION_ROUTES.ACCOUNT.ACCOUNT,
      icon: () => <SVGIcon name="User" />,
      title: t("navigation.account.profile"),
      url: "/account",
      defaultItemId: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
      expandable: true,
      items: [
        {
          id: NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE,
          icon: () => <SVGIcon name="User" />,
          title: t("navigation.account.profile"),
          url: "/account/profile",
          hideInSidebarMenu: true,
        },
      ],
    },
    {
      id: NAVIGATION_ROUTES.ACCOUNT.LOGOUT,
      icon: () => <Icon name="logout" outlined />,
      title: t("navigation.account.logout"),
      isLogout: true,
    },
  ];
};
