import React from "react";
import { useQuery } from "@apollo/client";
import { GET_JOB } from "../../graphql/queries/job/queries";
import { JobResponse } from "../../graphql/types/models/job";

export function useJobQuery(jobId?: string) {
  const { data: jobData } = useQuery<JobResponse>(GET_JOB, {
    variables: {
      jobId,
    },
  });

  const job = React.useMemo(() => jobData?.getJob, [jobData]);

  return { job };
}
