import React from "react";
import { findIndex, map, omit } from "lodash";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableRowActionData,
  TableCardAction,
} from "../../../components/dashboard/table-card/utils";
import { LIST_TIMESHEETS } from "../../../graphql/queries/timesheet/queries";
import {
  DELETE_TIMESHEET,
  CREATE_UPDATE_TIMESHEET,
} from "../../../graphql/queries/timesheet/mutation";
import {
  handleAddTimesheet,
  handleDeleteTimesheet,
  handleUpdateTimesheet,
} from "../../../graphql/queries/timesheet/utils";
import {
  DeleteTimesheetResponse,
  CreateUpdateTimesheetResponse,
  ListTimesheetsResponse,
} from "../../../graphql/types/models/timesheet";
import {
  TimesheetStatus,
  TimesheetTableItem,
  CreateUpdateTimesheetInput,
} from "../../../models/timesheet";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { notify } from "../../../components/notification";
import CreateUpdateTimesheetModal from "../../../components/timesheets/create-update-timesheet-modal";
import { CreateUpdateTimesheetPayload } from "../../../models/timesheet";
import { formatDuration } from "../../../utils/date";
import Dropdown from "../../../components/dashboard/dropdown";
import { useDropdownJobs } from "../../../hooks/useDropdownJobs";
import { useDropdownMembers } from "../../../hooks/useDropdownMembers";
import { useDropdownDateRange } from "../../../hooks/useDropdownDateRange";
import { RootReducerState } from "../../../redux/reducer";
import { connect } from "react-redux";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import ProcessTimesheetsModal from "../../../components/job-timesheets/process-timesheets-modal";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { JobTimesheetsProcessResponse } from "../../../graphql/types/models/job-timesheet";
import { PROCESS_JOB_TIMESHEETS } from "../../../graphql/queries/job-timesheet/mutation";
import {
  JobTimesheetTableItem,
  ProcessTimesheetModalFormItem,
  ProcessTimesheetPayloadInput,
} from "../../../models/job-timesheet";
import { Pagination } from "../../../models/pagination";
import { PaginationButton } from "../../../components/dashboard/card-pagination";
import { getTimeInterval, getTimeRange } from "../../../utils/calculations";
import {
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_PAGINATION_PAGE,
} from "../../../constants/pagination";
import DashboardActionHeader, {
  ActionButton,
} from "../../../components/dashboard/table-card/DashboardActionHeader";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { handleTimesheetsProcessing } from "../../../graphql/queries/job-timesheet/utils";
import { getFullName } from "../../../utils/text";
import { User } from "../../../graphql/types/models/user";
import { useTimesheetExportQuery } from "../../../hooks/queries/useTimesheetExportQuery";
import { useUnprocessTimesheet } from "../../../hooks/mutations/useUnprocessTimesheetMutation";
import "./styles.scss";
import { getTimesheetInput } from "./util";

export type DeleteTarget = {
  jobId: string;
  timesheetId: string;
} | null;

export type TimesheetsFilters = {
  userId: string;
  jobId: string;
  isProcessed?: boolean | string;
  dateRange: {
    from?: Date;
    to?: Date;
  };
};

type TimesheetsOverviewProp = DashboardContextValue & {
  user: UserPayload | null;
};

export type TimesheetsPagination = {
  page: number;
  limit: number;
};

const TimesheetsOverview: React.FC<TimesheetsOverviewProp> = ({ user }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const proccesModalRef = React.useRef<ModalDisplayRef>(null);
  const [deleteTarget, setDeleteTarget] = React.useState<DeleteTarget>(null);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [
    timesheetToUpdate,
    setTimesheetToUpdate,
  ] = React.useState<TimesheetTableItem | null>(null);

  const [
    timesheetsListPagitation,
    setTimesheetsListPagitation,
  ] = React.useState<TimesheetsPagination>({
    page: DEFAULT_PAGINATION_PAGE,
    limit: DEFAULT_PAGINATION_LIMIT,
  });

  const [listPagination, setListPagination] = React.useState<Pagination>();

  const [processed, setIsProcessed] = React.useState<string | boolean>("");
  const { currentJob, renderDropdownJobs, setJob } = useDropdownJobs();
  const { memberId, renderDropdownMembers, setMemberId } = useDropdownMembers({
    user,
  });
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    renderDropdownDateRange,
  } = useDropdownDateRange({ showResetIcon: true });

  const timesheetsFiltersStorageKey = "globalTimesheetsFilters";
  const {
    storedValue: storedFilters,
    setStoredValue: setStoredFilters,
  } = useLocalStorage(timesheetsFiltersStorageKey, {
    startDate,
    endDate,
    currentJob,
    processed,
    memberId,
  });

  const { exportTimesheets } = useTimesheetExportQuery();

  const [
    getTimesheets,
    { data: timesheets, loading: timesheetsLoading },
  ] = useLazyQuery<ListTimesheetsResponse>(LIST_TIMESHEETS, {
    variables: {
      filter: {
        dateRange: {
          from: startDate || undefined,
          to: endDate || undefined,
        },
        jobId: currentJob.id,
        userId: memberId,
        ...(processed !== "" && { isProcessed: processed }),
      },
      pagination: timesheetsListPagitation,
    },
    onCompleted: (data) => {
      setStoredFilters({ startDate, endDate, currentJob, processed, memberId });
      setListPagination(omit(data.listTimesheets, ["timesheets"]));
    },
    fetchPolicy: "cache-and-network",
  });

  const [createUpdateTimesheet] = useMutation<CreateUpdateTimesheetResponse>(
    CREATE_UPDATE_TIMESHEET
  );

  const [deleteTimesheet, { loading: timesheetDeleting }] = useMutation<
    DeleteTimesheetResponse
  >(DELETE_TIMESHEET);

  const [processTimesheets] = useMutation<JobTimesheetsProcessResponse>(
    PROCESS_JOB_TIMESHEETS
  );

  const resetPagination = React.useCallback(() => {
    setTimesheetsListPagitation({
      ...timesheetsListPagitation,
      page: 1,
    });
  }, [timesheetsListPagitation]);

  React.useEffect(() => {
    resetPagination();
    getTimesheets();
  }, [getTimesheets, currentJob, memberId, processed, startDate, endDate]);

  React.useEffect(() => {
    if (storedFilters) {
      const {
        startDate,
        endDate,
        currentJob,
        processed,
        memberId,
      } = storedFilters;
      setJob(currentJob);
      setMemberId(memberId);
      setMemberId(memberId);
      setStartDate(startDate ? new Date(startDate) : null);
      setEndDate(endDate ? new Date(endDate) : null);
      setIsProcessed(processed);
    } else {
      setStartDate(null);
      setEndDate(null);
      setStoredFilters({ startDate, endDate, currentJob, processed, memberId });
    }
  }, []);

  const openRemoveDialog = React.useCallback(
    (row?: TimesheetTableItem) => {
      if (row) {
        setDeleteTarget({
          jobId: row.job?._id,
          timesheetId: row._id,
        });

        confirmRef?.current?.show(true);
      }
    },

    [confirmRef]
  );

  const isAdmin = user?.role === UserRoles.builderadmin;

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget(null);

    confirmRef?.current?.show(false);
  }, [confirmRef]);

  const handleRemoveTimesheet = React.useCallback(async () => {
    try {
      if (!deleteTarget) return;

      await deleteTimesheet({
        variables: deleteTarget,
        update: handleDeleteTimesheet({
          filterTimesheets: {
            dateRange: {
              from: startDate || undefined,
              to: endDate || undefined,
            },
            jobId: currentJob.id,
            userId: memberId,
            ...(processed !== "" && { isProcessed: processed }),
          },
          pagination: timesheetsListPagitation,
        }),
      });

      notify({
        title: t("timesheets.deleteTimesheet"),
        content: t("timesheets.success.deleteTimesheet"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("timesheets.deleteTimesheet"),
        content: t("timesheets.errors.deleteTimesheet"),
      });
    }
  }, [
    deleteTarget,
    deleteTimesheet,
    startDate,
    endDate,
    currentJob.id,
    memberId,
    processed,
    timesheetsListPagitation,
  ]);

  const openCreateModal = React.useCallback(() => {
    setShowCreateModal(true);
  }, [setShowCreateModal]);

  const openEditModal = React.useCallback(
    (row?: TimesheetTableItem) => {
      if (row) {
        setTimesheetToUpdate(row);
        setShowCreateModal(true);
      }
    },
    [setTimesheetToUpdate, setShowCreateModal]
  );

  const closeCreateModal = React.useCallback(() => {
    setTimesheetToUpdate(null);
    setShowCreateModal(false);
  }, [setTimesheetToUpdate, setShowCreateModal]);

  const getTimesheetsInputArray = React.useCallback(
    (
      items: ProcessTimesheetModalFormItem[]
    ): ProcessTimesheetPayloadInput[] => {
      return items.map((item) => {
        const result: ProcessTimesheetPayloadInput = {
          task: item.task,
          rate: Number(item.rate),
          jobId: item.job._id,
          timesheetId: item._id,
          startTime: item.startTime,
          endTime: item.endTime,
          breakDuration: Number(item.breakDuration),
          costingCategoryName: item.costingCategoryName,
          costingItemId: item.costingItemId,
        };

        return result;
      });
    },
    []
  );

  const handleCreateTimesheet = React.useCallback(
    async (data: CreateUpdateTimesheetPayload, createAnother: boolean) => {
      try {
        if (!data) return;

        await createUpdateTimesheet({
          variables: {
            timesheets: [getTimesheetInput(data)],
          },
          update: handleAddTimesheet({
            filterTimesheets: {
              dateRange: {
                from: startDate || undefined,
                to: endDate || undefined,
              },
              jobId: currentJob.id,
              userId: memberId,
              ...(processed !== "" && { isProcessed: processed }),
            },
            pagination: timesheetsListPagitation,
          }),
        });

        notify({
          title: t("timesheets.addTimesheet"),
          content: t("timesheets.success.addTimesheet"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("timesheets.addTimesheet"),
          content: t("timesheets.errors.addTimesheet"),
        });
      }

      if (!createAnother) closeCreateModal();
    },
    [
      closeCreateModal,
      createUpdateTimesheet,
      getTimesheetInput,
      startDate,
      endDate,
      currentJob.id,
      memberId,
      processed,
      timesheetsListPagitation,
    ]
  );

  const handleEditTimesheet = React.useCallback(
    async (data: CreateUpdateTimesheetPayload, createAnother: boolean) => {
      try {
        if (!data || !timesheetToUpdate) return;

        await createUpdateTimesheet({
          variables: {
            timesheets: [
              getTimesheetInput({ ...data, _id: timesheetToUpdate._id }),
            ],
          },
          update: handleUpdateTimesheet(),
        });

        notify({
          title: t("timesheets.updateTimesheet"),
          content: t("timesheets.success.updateTimesheet"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("timesheets.updateTimesheet"),
          content: t("timesheets.errors.updateTimesheet"),
        });
      }

      closeCreateModal();
    },
    [t, createUpdateTimesheet, timesheetToUpdate, closeCreateModal]
  );

  const {
    renderUnprocessTimesheetDialog,
    openUnprocessTimesheetDialog,
  } = useUnprocessTimesheet();

  const handleJobClick = React.useCallback(
    (jobId: string) => {
      history.push(`/jobs/${jobId}`);
    },
    [history]
  );

  const timesheetsTableData = React.useMemo<
    TableCardData<TimesheetTableItem>
  >(() => {
    return {
      columns: [
        {
          valueKey: "startTime",
          title: t("timesheets.date"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            moment(value).format("D MMM YYYY"),
        },
        {
          valueKey: "timeRange",
          title: t("timesheets.time"),
          sortable: true,
        },
        {
          valueKey: "breakDuration",
          title: t("timesheets.break"),
        },
        {
          valueKey: "user",
          title: t("timesheets.teamMember"),
          sortable: true,
          formatValue: (row: any, column: any, value: User) =>
            getFullName(value),
        },
        {
          valueKey: "task",
          title: t("timesheets.task"),
          sortable: true,
        },
        {
          valueKey: "jobName",
          title: t("timesheets.job"),
          sortable: true,
          onClick: (row: any, column: any, value: string) => {
            if (row && row.cells) {
              handleJobClick(row.cells?.job?._id);
            }
          },
        },
        {
          valueKey: "duration",
          title: t("timesheets.durationMinusBreak"),
          sortable: true,
        },
        {
          valueKey: "status",
          title: t("timesheets.status"),
          sortable: true,
          formatValue: (row: any, column: any, value: TimesheetStatus) => {
            return (
              <span
                className={
                  value === TimesheetStatus.PROCESSED
                    ? "badge badge-success"
                    : "badge badge-secondary"
                }
              >
                {value}
              </span>
            );
          },
        },
      ],

      rows: map(timesheets?.listTimesheets?.timesheets, (item) => {
        const startDate = moment(item.startTime);
        const endDate = moment(item.endTime).subtract(
          item.breakDuration,
          "minutes"
        );

        return {
          cells: {
            ...item,
            jobName: item.job?.name,
            timeRange: getTimeRange(item.startTime, item.endTime),
            breakDuration: formatDuration(item.breakDuration * 60000 || 0, t),

            duration: formatDuration(endDate.diff(startDate), t),
            status: item.is_processed
              ? TimesheetStatus.PROCESSED
              : TimesheetStatus.PENDING,
          },
        };
      }),
    };
  }, [t, timesheets?.listTimesheets, handleJobClick]);

  const tableRowActions: TableRowActionData<
    TimesheetTableItem
  >[] = React.useMemo(
    () => [
      {
        icon: "comment",
        onClick: () => {},
        shouldRender: (row) => !!row.note,
        tooltip: (row) => row.note,
      },
      {
        icon: "edit",
        shouldRender: (row) => !row.is_processed,
        onClick: openEditModal,
      },
      {
        icon: "restore",
        title: t("timesheets.unprocess"),
        onClick: (row) =>
          openUnprocessTimesheetDialog({
            jobId: row.job._id,
            timesheetId: row._id,
          }),
        shouldRender: (row) => row.is_processed,
      },
      {
        icon: "delete",
        onClick: openRemoveDialog,
        shouldRender: (row) => !row.is_processed,
      },
    ],
    [openEditModal, openRemoveDialog, openUnprocessTimesheetDialog]
  );

  const statusesOptions = React.useMemo(
    () => [
      {
        id: "all",
        label: t("tasks.allStatuses"),
        onClick: () => setIsProcessed(""),
      },
      {
        id: TimesheetStatus.PENDING,
        label: TimesheetStatus.PENDING,
        onClick: () => setIsProcessed(false),
      },
      {
        id: TimesheetStatus.PROCESSED,
        label: TimesheetStatus.PROCESSED,
        onClick: () => setIsProcessed(true),
      },
    ],
    []
  );

  const statusLabel = React.useMemo(() => {
    if (processed === "") return t("tasks.allStatuses");
    return processed ? TimesheetStatus.PROCESSED : TimesheetStatus.PENDING;
  }, [processed]);

  const [selectedItemsToProcesss, setSelectedItemsToProcesss] = React.useState<
    JobTimesheetTableItem[]
  >([]);

  const toggleSelectedItem = React.useCallback(
    (row?: JobTimesheetTableItem) => {
      if (!row?._id) return;

      const newItems = [...selectedItemsToProcesss];
      const index = findIndex(newItems, { _id: row._id });
      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        newItems.push(row);
      }
      setSelectedItemsToProcesss(newItems);
    },
    [setSelectedItemsToProcesss, selectedItemsToProcesss]
  );

  const leftRowActions: TableRowActionData<
    JobTimesheetTableItem
  >[] = React.useMemo(
    () => [
      {
        icon: "check_box",
        dropdownId: "select-item",
        onClick: toggleSelectedItem,
        shouldRender: (row) => {
          if (!row?._id || row?.is_processed) return false;
          const hasSelected =
            findIndex(selectedItemsToProcesss, { _id: row._id }) >= 0;

          return hasSelected;
        },
      },
      {
        icon: "check_box_outline_blank",
        dropdownId: "select-item",
        onClick: toggleSelectedItem,
        shouldRender: (row) => {
          if (!row?._id || row?.is_processed) return false;
          const hasSelected =
            findIndex(selectedItemsToProcesss, { _id: row._id }) < 0;
          return hasSelected;
        },
      },
    ],
    [toggleSelectedItem]
  );

  const handleProcessTimesheets = React.useCallback(
    async (items: ProcessTimesheetModalFormItem[]) => {
      try {
        if (!items) return;

        await processTimesheets({
          variables: {
            timesheets: getTimesheetsInputArray(items),
          },
          update: handleTimesheetsProcessing(currentJob.id),
        });

        notify({
          title: t("jobTimesheets.processTimesheets"),
          content: t("jobTimesheets.success.processTimesheets"),
        });
        setSelectedItemsToProcesss([]);
      } catch (e) {
        notify({
          error: true,
          title: t("jobTimesheets.processTimesheets"),
          content: t("jobTimesheets.errors.processTimesheets"),
        });
      }
      setSelectedItemsToProcesss([]);
    },

    [t, processTimesheets]
  );

  const showProcessModal = React.useCallback(() => {
    proccesModalRef.current?.show(true);
  }, [proccesModalRef]);

  const isDisabledTableButton =
    !!processed ||
    selectedItemsToProcesss.length === 0 ||
    (timesheets && timesheets?.listTimesheets?.timesheets?.length === 0);

  const tableActions = React.useMemo<TableCardAction[]>(
    () => [
      {
        title: t("timesheets.processSelected"),
        onClick: showProcessModal,
        disabled: isDisabledTableButton,
        className: "button large success",
      },
    ],
    [t, showProcessModal, isDisabledTableButton]
  );

  const handleListPagination = React.useCallback(
    (type: PaginationButton) => {
      if (type === PaginationButton.Next) {
        setTimesheetsListPagitation({
          ...timesheetsListPagitation,
          page: timesheetsListPagitation.page + 1,
        });
      }
      if (type === PaginationButton.Previous) {
        setTimesheetsListPagitation({
          ...timesheetsListPagitation,
          page: timesheetsListPagitation.page - 1,
        });
      }

      getTimesheets();
    },
    [timesheetsListPagitation]
  );

  const filters = React.useMemo(
    () => [
      renderDropdownDateRange(),
      renderDropdownMembers(),
      renderDropdownJobs(),
      <Dropdown
        label={statusLabel}
        icon="expand_more"
        id="filter-complete"
        items={statusesOptions}
      />,
    ],
    [
      renderDropdownDateRange,
      renderDropdownJobs,
      renderDropdownMembers,
      statusLabel,
      statusesOptions,
    ]
  );

  const exportButtonAction = React.useMemo<ActionButton[]>(
    () => [
      {
        className: "export-button",
        icon: "download",
        disabled:
          timesheets && timesheets?.listTimesheets?.timesheets?.length === 0,
        onClick: () =>
          exportTimesheets({
            dateRange: {
              from: startDate || undefined,
              to: endDate || undefined,
            },
            jobId: currentJob.id,
            userId: memberId,
            ...(processed !== "" && { isProcessed: processed as boolean }),
            timezone: moment.tz.guess(),
          }),
      },
    ],
    [currentJob.id, endDate, memberId, processed, startDate, timesheets]
  );

  return (
    <Container fluid className="h-100 timesheets-container">
      <Helmet title={t("navigation.jobsSection.timesheets")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.TIMESHEETS_SECTION.TIMESHEETS}
      />

      {showCreateModal && (
        <CreateUpdateTimesheetModal
          data={timesheetToUpdate}
          show={showCreateModal}
          onClose={closeCreateModal}
          onSubmit={
            timesheetToUpdate ? handleEditTimesheet : handleCreateTimesheet
          }
          user={user}
        />
      )}

      <ConfirmDialog
        disabled={timesheetDeleting}
        ref={confirmRef}
        onClose={closeRemoveDialog}
        onSubmit={handleRemoveTimesheet}
        title={t("timesheets.deleteTimesheet")}
        confirm={t("common.delete")}
      >
        <div className="field-text">
          {t("timesheets.deleteTimesheetMessage")}
        </div>
      </ConfirmDialog>

      {renderUnprocessTimesheetDialog()}

      <ProcessTimesheetsModal
        ref={proccesModalRef}
        jobId={currentJob.id}
        jobTimesheetItems={selectedItemsToProcesss}
        onSubmit={handleProcessTimesheets}
      />

      <Row className="h-100">
        <Col xs={12} className="timesheets-table-container pb-sm-5 pb-lg-0">
          <DashboardActionHeader
            onActionButton={openCreateModal}
            additionalActionButtons={exportButtonAction}
            disabledActionButton={timesheetsLoading}
          >
            {filters.map((filter, index) => (
              <div key={index} className="pr-2 pb-4">
                {filter}
              </div>
            ))}
          </DashboardActionHeader>
          {
            <TableCard
              tableId="timesheets"
              isDataLoading={timesheetsLoading}
              alignEnd
              data={timesheetsTableData ?? []}
              rowActions={tableRowActions}
              actions={isAdmin ? tableActions : []}
              leftRowActions={isAdmin ? leftRowActions : []}
              pagination={listPagination}
              onPagination={handleListPagination}
              emptyTableText={t("timesheets.noTimesheets")}
            />
          }
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withDashboardContext(TimesheetsOverview));
