import { TFunction } from "i18next";
import * as yup from "yup";

const createResetPasswordSchema = (t: TFunction) => {
  return yup.object().shape({
    password: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.password"),
      })
    ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), ""],
        t("authentication.errors.confirmPassword")
      )
      .required(
        t("authentication.errors.isRequired", {
          fieldName: t("authentication.confirmPassword"),
        })
      ),
  });
};

export default createResetPasswordSchema;
