import React from "react";
import { useTranslation } from "react-i18next";
import { compact } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "react-bootstrap";
import {
  ChecklistCategoryType,
  CreateChecklistCategoriesResponse,
  CreateUpdateChecklistResponse,
  DeleteChecklistCategoryResponse,
  DeleteChecklistResponse,
  EnumChecklistEntityType,
  ImportChecklistFromTemplateResponse,
  ListChecklistCategoriesResponse,
  ToggleChecklistStatusResponse,
} from "../models/checklists";
import { LIST_CHECKLIST_CATEGORIES } from "../graphql/queries/checklists/queries";
import {
  CreateChecklistItemPayload,
  CreateChecklistPayload,
  EnumChecklistTemplateType,
} from "../models/task";
import { ModalDisplayRef } from "./useModalDisplay";
import {
  CREATE_UPDATE_CHECKLIST,
  CREATE_UPDATE_CHECKLIST_CATEGORY,
  DELETE_CHECKLIST,
  DELETE_CHECKLIST_CATEGORY,
  IMPORT_CHECKLIST_FROM_TEMPLATE,
  TOGGLE_CHECKLIST_STATUS,
} from "../graphql/queries/checklists/mutation";
import { notify } from "../components/notification";
import { EnumSystemUserType } from "../graphql/types/models/notification";
import ChecklistCategory from "../containers/tasks/checklist-category";
import CreateCheckistModal from "../components/task/create-checklist-modal";
import ImportChecklistTemplateModal from "../components/task/import-checklist-template";
import SaveChecklistAsTemplateModal from "../components/task/create-checklist-template";
import { useChecklistTemplate } from "./useChecklistTemplate";
import {
  handleCreateCategory,
  handleCreateItemChecklist,
} from "../graphql/queries/checklists/utils";
import DashboardCard from "../components/dashboard/card";
import DashboardCardHeader from "../components/dashboard/card/DashboardCardHeader";
import Dropdown from "../components/dashboard/dropdown";
import DashboardCardBody from "../components/dashboard/card/DashboardCardBody";
import EmptyPlaceholder from "../components/empty-placeholder";
import Icon from "../components/icons/Icon";

export type ChecklistsProps = {
  entityId: string;
  entityType: EnumChecklistEntityType;
  checklistTemplateType: EnumChecklistTemplateType;
};

export function useChecklists(props: ChecklistsProps) {
  const { entityId, entityType, checklistTemplateType } = props;
  const { t } = useTranslation();

  const [
    showCreateChecklistModal,
    setShowCreateChecklistModal,
  ] = React.useState(false);
  const [
    currentChecklistGroup,
    setCurrentChecklistGroup,
  ] = React.useState<ChecklistCategoryType | null>(null);

  const saveChecklistAsTemplateRef = React.useRef<ModalDisplayRef>();
  const importChecklistTemplateRef = React.useRef<ModalDisplayRef>();

  const {
    data: listChecklistCategoriesData,
    loading: loadinglistChecklistCategories,
    refetch: refetchListChecklistCategoriesData,
  } = useQuery<ListChecklistCategoriesResponse>(LIST_CHECKLIST_CATEGORIES, {
    fetchPolicy: "network-only",
    variables: {
      entityId,
      entityType,
    },
  });

  const listChecklistCategories = React.useMemo(
    () => listChecklistCategoriesData?.listChecklistCategories || [],
    [listChecklistCategoriesData]
  );

  const {
    checklistTemplates,
    createUpdateChecklistTemplate,
  } = useChecklistTemplate({
    checklistTemplateType,
  });

  const openCreateChecklistModal = React.useCallback(() => {
    setShowCreateChecklistModal(true);
  }, []);

  const closeCreateChecklistModal = React.useCallback(() => {
    setShowCreateChecklistModal(false);
    setCurrentChecklistGroup(null);
  }, []);

  const onSaveChecklistAsTemplate = React.useCallback(() => {
    saveChecklistAsTemplateRef.current?.show(true);
  }, [saveChecklistAsTemplateRef]);

  const onImportTemplateChecklist = React.useCallback(() => {
    importChecklistTemplateRef.current?.show(true);
  }, [importChecklistTemplateRef]);

  const checklistsOptions = React.useMemo(() => {
    return compact([
      listChecklistCategoriesData?.listChecklistCategories?.length
        ? {
            id: "saveChecklistTemplate",
            label: t("tasks.saveAsTemplate"),
            name: "saveChecklistTemplate",
            outlined: true,
            onClick: onSaveChecklistAsTemplate,
          }
        : null,
      {
        id: "importChecklistTemplate",
        label: t("tasks.importChecklistTemplate"),
        name: "importChecklistTemplate",
        outlined: true,
        onClick: onImportTemplateChecklist,
      },
    ]);
  }, [listChecklistCategoriesData]);

  const [createChecklist] = useMutation<CreateChecklistCategoriesResponse>(
    CREATE_UPDATE_CHECKLIST_CATEGORY,
    {
      onCompleted: () => {
        notify({
          title: currentChecklistGroup
            ? t("tasks.editGroup")
            : t("tasks.createNewGroup"),
          content: currentChecklistGroup
            ? t("tasks.success.editChecklist")
            : t("tasks.success.createChecklist"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: currentChecklistGroup
            ? t("tasks.editGroup")
            : t("tasks.createNewGroup"),
          content: currentChecklistGroup
            ? t("tasks.error.editChecklist")
            : t("tasks.error.createChecklist"),
        });
      },
      update: handleCreateCategory(entityId, entityType),
    }
  );

  const [importTodoChecklistFromTemplate] = useMutation<
    ImportChecklistFromTemplateResponse
  >(IMPORT_CHECKLIST_FROM_TEMPLATE, {
    onCompleted: () => {
      refetchListChecklistCategoriesData();
      notify({
        title: t("tasks.importChecklistTemplate"),
        content: t("tasks.success.importFromTemplate"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("tasks.importChecklistTemplate"),
        content: t("tasks.error.importFromTemplate"),
      });
    },
  });

  const [deleteChecklistCategory] = useMutation<
    DeleteChecklistCategoryResponse
  >(DELETE_CHECKLIST_CATEGORY, {
    onCompleted: () => {
      notify({
        title: t("tasks.deleteChecklistCategory"),
        content: t("tasks.success.deleteChecklistCategory"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("tasks.deleteChecklistCategory"),
        content: t("tasks.error.deleteChecklistCategory"),
      });
    },
  });

  const [deleteChecklist] = useMutation<DeleteChecklistResponse>(
    DELETE_CHECKLIST,
    {
      onCompleted: () => {
        notify({
          title: t("tasks.deleteTask"),
          content: t("tasks.success.deleteTask"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: t("tasks.deleteTask"),
          content: t("tasks.error.deleteTask"),
        });
      },
    }
  );

  const [toggleChecklistStatus] = useMutation<ToggleChecklistStatusResponse>(
    TOGGLE_CHECKLIST_STATUS
  );

  const [createChecklistItem] = useMutation<CreateUpdateChecklistResponse>(
    CREATE_UPDATE_CHECKLIST
  );

  const handleDeleteChecklist = React.useCallback(
    (checklist: ChecklistCategoryType) => {
      deleteChecklistCategory({
        variables: {
          entityId,
          entityType,
          categoryId: checklist._id,
        },
      });
    },
    [deleteChecklistCategory, entityId, entityType]
  );

  const handleDeleteChecklistTask = React.useCallback(
    (checklistId: string, categoryId: string) => {
      deleteChecklist({
        variables: {
          entityId,
          entityType,
          checklistId,
          categoryId,
        },
      });
    },
    [deleteChecklist, entityId, entityType]
  );

  const handleEditChecklistGroup = React.useCallback(
    (checklist: ChecklistCategoryType) => {
      setCurrentChecklistGroup(checklist);
      openCreateChecklistModal();
    },
    [openCreateChecklistModal]
  );

  const handleToggleChecklistStatus = React.useCallback(
    (row: { _id: string; completed: boolean }, categoryId: string) => {
      toggleChecklistStatus({
        variables: {
          entityId,
          entityType,
          checklistId: row._id,
          isComplete: !row.completed,
          categoryId,
        },
      });
    },
    [entityId, entityType, toggleChecklistStatus]
  );

  const handleCreateChecklistItem = React.useCallback(
    async (data: CreateChecklistItemPayload, checklistId: string) => {
      const isUpdate = Boolean(data._id);
      try {
        await createChecklistItem({
          variables: {
            checklist: {
              entityId,
              entityType,
              categoryId: checklistId,
              task: data.task,
              dueDate: data?.dueDate,
              ...(isUpdate && { _id: data._id }),
              ...(data?.assignedTo
                ? {
                    assignedTo: data?.assignedTo,
                    assignedToType: EnumSystemUserType.Builder,
                  }
                : {
                    assignedTo: null,
                    assignedToType: EnumSystemUserType.None,
                  }),
            },
          },
          update: handleCreateItemChecklist(entityId, entityType),
        });
        notify({
          title: isUpdate
            ? t("tasks.updateTask")
            : t("tasks.createNewChecklistItem"),
          content: isUpdate
            ? t("tasks.success.updateTask")
            : t("tasks.success.createChecklistItem"),
        });
      } catch (error) {
        notify({
          error: true,
          title: isUpdate
            ? t("tasks.updateTask")
            : t("tasks.createNewChecklistItem"),
          content: isUpdate
            ? t("tasks.error.updateTask")
            : t("tasks.error.createChecklistItem"),
        });
      }
    },
    [createChecklistItem, entityId, entityType, t]
  );

  const handleCreateChecklist = React.useCallback(
    async (data: CreateChecklistPayload, createAnother: boolean) => {
      await createChecklist({
        variables: {
          checklistCategory: {
            ...(data._id && { _id: data._id }),
            entityId,
            name: data.name,
            entityType,
          },
        },
      });
      if (!createAnother) {
        closeCreateChecklistModal();
      } else {
        setCurrentChecklistGroup(null);
      }
    },
    [closeCreateChecklistModal, createChecklist, entityId, entityType]
  );

  const handleSaveChecklistAsTemplate = React.useCallback(
    (name: string) => {
      const categories = listChecklistCategories?.map((category) => {
        return {
          name: category.name,
          items: category.checklist.map((task, index) => ({
            task: task.task,
            order: index,
          })),
        };
      });
      if (categories) {
        createUpdateChecklistTemplate({
          variables: {
            template: {
              name,
              type: checklistTemplateType,
              categories,
            },
          },
        });
      }
    },
    [
      checklistTemplateType,
      createUpdateChecklistTemplate,
      listChecklistCategories,
    ]
  );

  const handleImportChecklist = React.useCallback(
    (templateId: string) => {
      importTodoChecklistFromTemplate({
        variables: {
          templateId,
          entityId,
          entityType,
        },
      });
    },
    [entityId, entityType, importTodoChecklistFromTemplate]
  );

  const renderChecklists = () => {
    return (
      <>
        <DashboardCard>
          <DashboardCardHeader className="justify-content-between">
            {t("tasks.checklists")}
            <div className="d-flex mr-3">
              <Button
                className="button large bg-transparent"
                block
                onClick={openCreateChecklistModal}
              >
                <Icon name="add" />
                {t("tasks.createNewGroup")}
              </Button>
              <Dropdown
                label={t("tasks.templates")}
                icon="expand_more"
                menuWidth="270px"
                id="costing-dropdown"
                items={checklistsOptions}
              />
            </div>
          </DashboardCardHeader>
          <DashboardCardBody>
            {listChecklistCategories.length > 0 ? (
              <>
                {listChecklistCategories.map((checklist) => {
                  return (
                    <ChecklistCategory
                      checklist={checklist}
                      onDeleteChecklist={handleDeleteChecklist}
                      onEditChecklist={handleEditChecklistGroup}
                      isLoading={loadinglistChecklistCategories}
                      onToggleChecklistStatus={handleToggleChecklistStatus}
                      onCreateChecklistItem={handleCreateChecklistItem}
                      onDeleteChecklistItem={handleDeleteChecklistTask}
                    />
                  );
                })}
              </>
            ) : (
              <EmptyPlaceholder
                message={t("tasks.emptyChecklistsPlaceholder")}
              />
            )}
          </DashboardCardBody>
        </DashboardCard>
        <CreateCheckistModal
          data={currentChecklistGroup}
          show={showCreateChecklistModal}
          onClose={closeCreateChecklistModal}
          onSubmit={handleCreateChecklist}
        />
        <ImportChecklistTemplateModal
          ref={importChecklistTemplateRef}
          onSubmit={handleImportChecklist}
          templatesData={checklistTemplates}
        />
        <SaveChecklistAsTemplateModal
          ref={saveChecklistAsTemplateRef}
          onSubmit={handleSaveChecklistAsTemplate}
          templatesData={checklistTemplates}
          isChecklistsSection={true}
        />
      </>
    );
  };

  return {
    listChecklistCategories,
    loadinglistChecklistCategories,
    renderChecklists,
  };
}
