import { TFunction } from "i18next";
import { map } from "lodash";
import { TodoChecklistTemplates } from "../../../models/task";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const importChecklistTemplateFields = (
  t: TFunction,
  templatesData: TodoChecklistTemplates[]
): GenericFormFields<{ templateId: string }> => {
  const checklistsTemplate = map(templatesData, (template) => ({
    value: template._id,
    label: template.name,
  }));
  return {
    templateId: {
      type: "default-select",
      label: t("common.template"),
      placeholder: t("common.template"),
      valueKey: "templateId",
      selectProps: {
        options: checklistsTemplate,
      },
    },
  };
};
