import { TFunction } from "i18next";
import { map } from "lodash";
import { SelectionTemplateType } from "../../../graphql/types/models/selections";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const importSelectionFromTemplateFields = (
  t: TFunction,
  selectionTemplates: SelectionTemplateType[]
): GenericFormFields<{ templateId: string }> => {
  const templateOptions = map(selectionTemplates, (template) => ({
    value: template._id,
    label: template.name,
  }));
  return {
    templateId: {
      type: "default-select",
      label: t("common.template"),
      placeholder: t("common.template"),
      valueKey: "templateId",
      selectProps: {
        options: templateOptions,
      },
    },
  };
};
