import { ApolloCache, ApolloClient, MutationUpdaterFn } from "@apollo/client";
import { concat, filter, omit, uniqBy } from "lodash";
import { ClaimListItem } from "../../../models/claim";
import {
  CreateUpdateClaimResponse,
  DeleteClaimResponse,
  ListClaimsResponse,
} from "../../types/models/job-claim";
import {
  findCostingItemFromCache,
  updateCostingItemCache,
} from "../job-costing/utils";
import { GET_CLAIM, LIST_CLAIMS } from "./queries";

const getClaimsList = (cache: ApolloCache<any>, jobId?: string) => {
  const claimsList = cache.readQuery<ListClaimsResponse>({
    query: LIST_CLAIMS,
    variables: {
      jobId,
    },
  });
  return claimsList?.getJobProgressClaims;
};

const updateClaimsList = (
  cache: ApolloCache<any>,
  claims: ClaimListItem[],
  jobId?: string
) => {
  cache.writeQuery({
    query: LIST_CLAIMS,
    variables: {
      jobId,
    },
    data: {
      getJobProgressClaims: claims,
    },
  });
};

const updateClaim = (
  cache: ApolloCache<any>,
  progressClaimId: string,
  claim: ClaimListItem | null,
  jobId?: string
) => {
  cache.writeQuery({
    query: GET_CLAIM,
    variables: {
      jobId,
      progressClaimId,
    },
    data: {
      getJobProgressClaim: claim,
    },
  });
};

export const handleClaimAdd = (
  jobId?: string
): MutationUpdaterFn<CreateUpdateClaimResponse> => (cache, response) => {
  const createdClaim = response.data?.jobCreateUpdateProgressClaim;
  if (!createdClaim) return;

  const claims = getClaimsList(cache, jobId);

  if (claims) {
    const claimListItem = {
      ...createdClaim,
    };
    const updatedClaims = uniqBy(concat(claims, claimListItem), "_id");
    updateClaimsList(cache, updatedClaims, jobId);
    updateClaim(cache, createdClaim._id, createdClaim, jobId);
  }

  const progressClaim = omit(createdClaim, ["items"]);
  createdClaim.items?.forEach((item) => {
    if (!item.costingItemId) return;
    const progressClaimItem = {
      ...item,
      progressClaim,
    };
    const costingFragment = findCostingItemFromCache(cache, item.costingItemId);
    if (costingFragment) {
      updateCostingItemCache(cache, {
        ...costingFragment,
        progressClaimItems: (costingFragment.progressClaimItems || []).concat(
          progressClaimItem
        ),
      });
    }
  });
};

export const handleClaimDelete = (
  jobId?: string
): MutationUpdaterFn<DeleteClaimResponse> => (cache, response) => {
  const deletedClaim = response.data?.jobDeleteProgressClaim;

  const claims = getClaimsList(cache, jobId);

  if (!deletedClaim || !claims) {
    return;
  }
  const updatedClaims = filter(
    claims,
    (claim) => claim._id !== deletedClaim._id
  );

  updateClaimsList(cache, updatedClaims, jobId);
  updateClaim(cache, deletedClaim._id, null, jobId);
};
