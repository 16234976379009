import React, { useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { chain, filter, find, get, isArray, map, set } from "lodash";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import omitDeep from "omit-deep-lodash";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import {
  GenericFormFields,
  GenericFormTable,
  renderTableForm,
  renderField,
  renderFields,
} from "../../../generic-form/GenericFormBody";
import ModalForm from "../../../modals/modal-form";
import createSiteDiaryTemplateSchema from "./SiteDiaryTemplateSchema";
import { createSiteDiaryTemplateFields } from "./utils";
import {
  SiteDiaryFieldType,
  SiteDiaryTemplate,
  SiteDiaryTemplateField,
  SiteDiaryTemplateType,
} from "../../../../graphql/types/models/site-diary";
import Icon from "../../../icons/Icon";
import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../../hooks/useModalDisplay";
import "./styles.scss";
import SortableList, { SortableListRef } from "../../../sortable-list";
import { SortableItem } from "../../../sortable-list/types";
import { ConnectDragSource } from "react-dnd";

type SiteDiaryTemplateModalProps = {
  onSubmit: (data: any) => void;
};

export type SiteDiaryTemplatePayload = Omit<
  SiteDiaryTemplate,
  "business" | "createdAt" | "updatedAt"
>;

export type SiteDiaryTemplateModalRef = ModalDisplayRef & {
  showTemplate: (template: SiteDiaryTemplate) => void;
};

const DEFAULT_VALUES = {
  name: "",
} as SiteDiaryTemplatePayload;

const DEFAULT_TEMPLATE = {
  fields: [
    {
      // _id: "new",
      type: SiteDiaryFieldType.String,
      title: "",
      subFields: [
        {
          title: "",
        },
      ],
    },
  ],
} as SiteDiaryTemplate;

const SiteDiaryTemplateModal: React.FC<SiteDiaryTemplateModalProps> = (
  props,
  ref
) => {
  const { t } = useTranslation();
  const { onSubmit } = props;

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<SiteDiaryTemplatePayload>
  >({});

  const [template, setTemplate] = useState<SiteDiaryTemplatePayload>(
    DEFAULT_VALUES
  );
  const [originalName, setOriginalName] = useState("");
  const [saveAsCopy, setSaveAsCopy] = useState(false);
  const listRef = React.useRef<SortableListRef>(null);

  const { shouldShow, show, hide } = useModalDisplay(ref, {
    showTemplate: (editTemplate: SiteDiaryTemplate) => {
      const fields: any = editTemplate?.fields?.map(
        (field: SiteDiaryTemplateField, index: number) => ({
          _id: field._id,
          title: field.title,
          type: field.type,
          options: field.options || [],
          subFields: map(
            field.subFields,
            (subField: SiteDiaryTemplateField) => ({
              _id: subField._id,
              title: subField.title,
              type: subField.type,
            })
          ),
        })
      );
      const _template = {
        ...editTemplate,
        fields,
      };
      setTemplate(_template);
      setOriginalName(editTemplate.name);
      show();
    },
  });

  const isReadonly = React.useMemo(() => {
    return template?.type === SiteDiaryTemplateType.System;
  }, [template]);

  const validationSchema = React.useMemo(() => {
    return createSiteDiaryTemplateSchema(t);
  }, [t]);

  React.useEffect(() => {
    if (shouldShow) return;
    setTemplate(DEFAULT_VALUES);
    setOriginalName("");
  }, [shouldShow]);

  const handleSubmit = React.useCallback(
    (values: any) => {
      if (isReadonly) return hide();

      let name = values.name;
      if (saveAsCopy && originalName === name) {
        name = t("siteDiary.templateNameCopy", values);
      }
      const data = {
        _id: values._id,
        name,
        fields: map(values.fields, (field: SiteDiaryTemplateField) => ({
          ...field,
          options:
            field.type === SiteDiaryFieldType.Radio ? field.options : null,
          subFields:
            field.type === SiteDiaryFieldType.Collection
              ? field.subFields
              : null,
        })),
      };
      onSubmit(saveAsCopy ? omitDeep(data, ["_id"]) : data);
      return true;
    },
    [isReadonly, onSubmit, hide, saveAsCopy, originalName]
  );

  const onFieldChange = React.useCallback(
    (field: string, value: string | string[], index: number) => {
      if (!template) return;
      const currentValue = get(template.fields, index);
      if (!value || get(currentValue, field) === value) return;

      const fields = template.fields ? [...template.fields] : [];

      set(fields, index, {
        ...currentValue,
        [field]: value,
      });
      const newTemplate = {
        ...template,
        fields,
      };
      setTemplate(newTemplate);
    },
    [template]
  );

  const removeRow = React.useCallback(
    (formikProps, field) => {
      const index = template.fields?.indexOf(field);
      if (index >= 0) {
        const newTemplate = {
          ...template,
          fields: template.fields?.filter((f) => f !== field),
        };
        const newValues = {
          ...formikProps.values,
          fields: formikProps.values.fields?.filter(
            (_f: any, id: number) => id !== index
          ),
        };
        formikProps.setValues(newValues);
        setTemplate(newTemplate);
      }
    },
    [template]
  );

  const renderRow = React.useCallback(
    (formikProps, field, id, dragRef?: ConnectDragSource) => {
      if (isArray(field)) {
        const templateField = get(template, id);
        return (
          <div className="sdt-row">
            <div className="sdt-row-count field-text" ref={dragRef}>
              {template.fields?.indexOf(templateField) + 1}.
            </div>
            <div className="sdt-row-fields">
              <Row>{renderFields(formikProps, field)}</Row>
            </div>
            <div className="sdt-row-remove field-text">
              <div
                className="remove-action"
                onClick={() => {
                  removeRow(formikProps, templateField);
                }}
              >
                <Icon name="clear" />
              </div>
            </div>
          </div>
        );
      } else if (isArray(field?.row)) {
        return (
          <div className="sdt-sub-row">
            {renderTableForm(formikProps, field, id)}
          </div>
        );
      }
      return renderField(formikProps, field, 12);
    },
    [template, removeRow]
  );

  const onDrop = React.useCallback(
    (formikProps: FormikProps<any>, helpers: ArrayHelpers) => () => {
      const currentList = listRef.current?.getOrder();
      const templateFields: SiteDiaryTemplateField[] = [];
      const formikFields: any[] = [];
      const regex = /([0-9])+/;
      currentList?.forEach((item) => {
        const index = (regex.exec(item.id)?.[0] || -1) as number;
        if (index >= 0) {
          templateFields.push(template.fields[index]);
          formikFields.push(formikProps.values.fields[index]);
        }
      });
      formikProps.setFieldValue("fields", formikFields); // reorder fields in formik
      setTemplate({
        ...template,
        fields: templateFields,
      });
    },
    [formFields, template, listRef]
  );

  const handleAddField = React.useCallback(
    (formikProps: FormikProps<any>) => {
      if (!template) return;
      const newTemplate = {
        ...template,
        fields: [...(template.fields || []), { ...DEFAULT_TEMPLATE.fields[0] }],
      };

      setTemplate(newTemplate);
      formikProps.setFieldValue(
        `fields.[${newTemplate.fields.length - 1}].subFields`,
        [
          {
            title: "",
          },
        ]
      );
    },
    [template]
  );

  const renderLeftFooter = React.useCallback(
    (formikProps: FormikProps<any>) => {
      if (isReadonly) return null;
      return (
        <>
          <Button
            variant="secondary"
            className="button large large-wide info"
            onClick={() => handleAddField(formikProps)}
          >
            <Icon name="add" /> {t("siteDiary.addField")}
          </Button>
          <div style={{ flex: 1 }} />
          {template._id && template.type !== SiteDiaryTemplateType.System && (
            <Form.Group controlId="saveAsCopy">
              <Form.Check
                checked={saveAsCopy}
                onChange={() => setSaveAsCopy(!saveAsCopy)}
                type="checkbox"
                label={t("siteDiary.saveAsCopy")}
              />
            </Form.Group>
          )}
        </>
      );
    },
    [handleAddField, saveAsCopy, template, isReadonly]
  );

  React.useEffect(() => {
    if (!template) return;
    setFormFields(createSiteDiaryTemplateFields(t, template, onFieldChange));
  }, [t, template, onFieldChange]);

  const renderItem = React.useCallback(
    (formikProps: FormikProps<any>, helpers: ArrayHelpers) => (
      data: SortableItem,
      dragRef: ConnectDragSource
    ) => {
      const field = (formFields as any)[data.id];
      const subId = `${data.id}.subFields`;
      const collection = formFields[subId]
        ? renderRow(formikProps, formFields[subId], subId, dragRef)
        : null;

      return (
        <Row>
          {renderRow(formikProps, field, data.id, dragRef)}
          {collection}
        </Row>
      );
    },
    [template, formFields]
  );

  const renderSortableFields = React.useCallback(
    (formikProps: FormikProps<any>, helpers: ArrayHelpers) => {
      const items = filter(
        Object.keys(formFields),
        (key: string) =>
          key.indexOf("fields") === 0 && key.indexOf("subFields") === -1
      ).map((key) => {
        return ({
          id: key,
          item: formFields[key],
        } as unknown) as SortableItem;
      });

      return (
        <SortableList
          ref={listRef}
          onDrop={onDrop(formikProps, helpers)}
          items={items}
          renderItem={renderItem(formikProps, helpers)}
        />
      );
    },
    [template, formFields]
  );

  if (!shouldShow) return null;

  return (
    <ModalForm
      validationSchema={validationSchema}
      className="site-diary-template-modal"
      title={
        template?._id
          ? t("siteDiary.updateTemplate")
          : t("siteDiary.createTemplate")
      }
      show={shouldShow}
      data={template}
      onSubmit={handleSubmit}
      onClose={hide}
      submitText={isReadonly ? t("common.done") : t("common.save")}
      leftFooterRenderer={renderLeftFooter}
      enableReinitialize={false}
    >
      {(formikProps) => (
        <Container className="generic-form-body" fluid>
          <Row>
            <Col lg={12} md={12}>
              <Row>{renderRow(formikProps, formFields.name, "name")}</Row>
              <FieldArray name={"fields"}>
                {(helpers: ArrayHelpers) =>
                  renderSortableFields(formikProps, helpers)
                }
              </FieldArray>
            </Col>
          </Row>
        </Container>
      )}
    </ModalForm>
  );
};

export default forwardRef(SiteDiaryTemplateModal);
