import { WithT } from "i18next";
import { FormikProps } from "formik";
import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { AffiliateSignUpPayload } from "../../../graphql/types/models/affiliate";
import { STATES_OPTIONS } from "../../../utils/options";

type SignUpFormBodyProps = WithT &
  Pick<
    FormikProps<AffiliateSignUpPayload>,
    "values" | "errors" | "touched" | "handleChange" | "handleBlur"
  >;

const SignUpFormBody: React.FC<SignUpFormBodyProps> = ({
  t,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const handlePhoneChange = React.useCallback(
    (e) => {
      if (e?.target?.value) {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
      }
      handleChange(e);
    },
    [handleChange]
  );

  const isInvalid = (fieldName: keyof AffiliateSignUpPayload) => {
    return touched[fieldName] && !!errors[fieldName];
  };

  const renderInput = (
    fieldName: keyof AffiliateSignUpPayload,
    label: string
  ) => {
    return (
      <Form.Group controlId={fieldName}>
        <Form.Label className="form-input-label">{label}</Form.Label>
        <Form.Control
          required
          key={fieldName}
          onChange={handleChange}
          onBlur={handleBlur}
          name={fieldName}
          isInvalid={isInvalid(fieldName)}
          value={values[fieldName] as string}
          className="form-input"
          placeholder={label}
        />
        <Form.Control.Feedback type="invalid">
          {errors[fieldName]}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  const renderTextarea = (
    fieldName: keyof AffiliateSignUpPayload,
    label: string
  ) => {
    return (
      <Form.Group controlId={fieldName}>
        <Form.Label className="form-input-label">{label}</Form.Label>
        <Form.Control
          as="textarea"
          required
          key={fieldName}
          onChange={handleChange}
          onBlur={handleBlur}
          name={fieldName}
          isInvalid={isInvalid(fieldName)}
          value={values[fieldName] as string}
          className="form-input"
          rows={4}
        />
        <Form.Control.Feedback type="invalid">
          {errors[fieldName]}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  return (
    <Container className="m-0 p-0 mw-100">
      <p className="lead">Thank you for your interest to be an affiliate.</p>
      <p className="lead">
        We want to know you better. Please take a moment to share your details
        below:
      </p>
      <Row>
        <Col md={6}>
          {renderInput("business_name", t("authentication.businessName"))}
        </Col>
        <Col md={6}>{renderInput("ABN", t("company.abnNumber"))}</Col>
      </Row>
      <Row>
        <Col lg={6} md={12}>
          {renderInput("first_name", t("authentication.firstName"))}
        </Col>
        <Col lg={6} md={12}>
          {renderInput("last_name", t("authentication.lastName"))}
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={12}>
          {renderInput("email", t("authentication.emailAddress"))}
        </Col>
        <Col lg={6} md={12}>
          <Form.Group controlId="phoneNumber">
            <Form.Label className="form-input-label">
              {t("authentication.phoneNumber")}
            </Form.Label>
            <Form.Control
              required
              key="phone"
              onChange={handlePhoneChange}
              onBlur={handleBlur}
              name="phone"
              isInvalid={isInvalid("phone")}
              value={values.phone}
              className="form-input"
              placeholder={t("placeholders.phoneNumber")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12}>
          {renderInput("address", t("authentication.address"))}
        </Col>
        <Col lg={6} md={12}>
          {renderInput("city", t("authentication.city"))}
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12}>
          <Form.Group controlId="state">
            <Form.Label className="form-input-label">
              {t("authentication.state")}
            </Form.Label>
            <Form.Control
              as="select"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="state"
              isInvalid={isInvalid("state")}
              value={values.state}
              className="form-input"
              placeholder={t("authentication.state")}
            >
              <option value="">{t("common.select")}</option>
              {STATES_OPTIONS.map((state) => (
                <option value={state.value}>{state.label}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.state}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          {renderInput("postcode", t("authentication.postcode"))}
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          {renderTextarea("about", t("authentication.affiliateAbout"))}
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          {renderTextarea(
            "additionalComment",
            t("authentication.affiliateComment")
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <Form.Group controlId="hasBuilders">
            <Form.Label className="form-input-label">
              {t("authentication.affiliateHasBuilders")}
            </Form.Label>
            <Form.Check
              type="radio"
              id={"hasBuildersYes"}
              name={"hasBuilders"}
              label={t("common.yes")}
              checked={(values.hasBuilders as string) === "yes"}
              onChange={handleChange}
              value={"yes"}
            />
            <Form.Check
              type="radio"
              id={"hasBuildersNo"}
              name={"hasBuilders"}
              label={t("common.no")}
              checked={(values.hasBuilders as string) === "no"}
              onChange={handleChange}
              value={"no"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <Form.Group controlId="termsAgreement">
            <Form.Label className="form-input-label">
              {t("authentication.agreement")}
            </Form.Label>
            <Form.Check
              type="checkbox"
              id={"termsAgreement"}
              onChange={handleChange}
              label={
                <>
                  {t("authentication.affiliateAgreement")}{" "}
                  <a
                    target="_blank"
                    href={process.env.REACT_APP_AFFILIATE_TERMS_URL as string}
                  >
                    {t("authentication.affiliateTermsAndConditions")}
                  </a>
                </>
              }
              isInvalid={isInvalid("termsAgreement")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.termsAgreement}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpFormBody;
