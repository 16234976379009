import React from "react";

type TextProps = {
  value: string;
};

const Text: React.FC<TextProps> = (props) => {
  const { value } = props;
  return <span>{props.children}</span>;
};

export default Text;
