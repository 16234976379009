import { gql } from "@apollo/client";
import {
  SPEC_CATEGORY_FRAGMENT,
  SPEC_TEMPATE_CATEGORY_FRAGMENT,
  SPEC_TEMPATE_FRAGMENT,
} from "./fragments";

export const DELETE_SPEC_CATEGORY = gql`
  mutation deleteSpecCategoryById($categoryId: ID!) {
    deleteSpecCategoryById(categoryId: $categoryId) {
      ...SpecCategoryFragment
    }
  }
  ${SPEC_CATEGORY_FRAGMENT}
`;

export const DELETE_SPEC_TEMPLATE_CATEGORY = gql`
  mutation($templateId: ID!, $categoryId: ID!) {
    deleteSpecTemplateCategory(
      templateId: $templateId
      categoryId: $categoryId
    ) {
      ...SpecTemplateCategoryFragment
    }
  }
  ${SPEC_TEMPATE_CATEGORY_FRAGMENT}
`;

export const DELETE_SPEC_CATEGORY_ITEM = gql`
  mutation deleteSpecCategoryItemById($categoryId: ID!, $itemId: ID!) {
    deleteSpecCategoryItemById(categoryId: $categoryId, itemId: $itemId) {
      _id
      name
      description
      file_type
      size
      width
      height
      url
      upload_url
    }
  }
`;

export const CREATE_UPDATE_SPEC_CATEGORY = gql`
  mutation createUpdateSpecCategory($category: SpecCategoryInput!) {
    createUpdateSpecCategory(category: $category) {
      ...SpecCategoryFragment
    }
  }
  ${SPEC_CATEGORY_FRAGMENT}
`;

export const UPDATE_SPEC_CATEGORY_ORDER = gql`
  mutation($salesQuoteId: ID!, $categories: [SpecCategoryOrderInput]!) {
    updateSpecCategoryOrder(
      salesQuoteId: $salesQuoteId
      categories: $categories
    ) {
      ...SpecCategoryFragment
    }
  }
  ${SPEC_CATEGORY_FRAGMENT}
`;

export const SAVE_SPEC_AS_TEMPLATE = gql`
  mutation($salesQuoteId: ID!, $name: String!) {
    saveSpecAsTemplate(salesQuoteId: $salesQuoteId, name: $name) {
      ...SpecTemplateFragment
    }
  }
  ${SPEC_TEMPATE_FRAGMENT}
`;

export const CREATE_UPDATE_SPEC_TEMPLATE = gql`
  mutation createUpdateSpecTemplate($template: SpecTemplateInput!) {
    createUpdateSpecTemplate(template: $template) {
      ...SpecTemplateFragment
    }
  }
  ${SPEC_TEMPATE_FRAGMENT}
`;

export const IMPORT_SPEC_TEMPLATE = gql`
  mutation($templateId: ID!, $salesQuoteId: ID!) {
    importSpecFromTemplate(
      templateId: $templateId
      salesQuoteId: $salesQuoteId
    ) {
      ...SpecCategoryFragment
    }
  }
  ${SPEC_CATEGORY_FRAGMENT}
`;

export const DELETE_SPEC_TEMPLATE = gql`
  mutation($templateId: ID!) {
    deleteSpecTemplate(templateId: $templateId) {
      ...SpecTemplateFragment
    }
  }
  ${SPEC_TEMPATE_FRAGMENT}
`;

export const CREATE_UPDATE_SPEC_TEMPLATE_CATEGORY = gql`
  mutation($templateId: ID!, $category: SpecTemplateCategoryInput!) {
    createUpdateSpecTemplateCategory(
      templateId: $templateId
      category: $category
    ) {
      ...SpecTemplateCategoryFragment
    }
  }
  ${SPEC_TEMPATE_CATEGORY_FRAGMENT}
`;
