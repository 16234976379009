import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import {
  ListJobMembersResponse,
  AddJobMemberResponse,
  RemoveJobMemberResponse,
} from "../../../graphql/types/models/job-teammate";
import { LIST_JOB_MEMBERS } from "../../../graphql/queries/job-teammate/queries";
import { TeamMember } from "../../../models/team-member";
import { concat, filter } from "lodash";

const getJobMembers = (cache: ApolloCache<any>, jobId: string) => {
  const jobMembers = cache.readQuery<ListJobMembersResponse>({
    query: LIST_JOB_MEMBERS,
    variables: {
      jobId,
    },
  });

  return jobMembers?.listJobMembers;
};

const updateJobMembers = (
  cache: ApolloCache<any>,
  jobId: string,
  members: TeamMember[]
) => {
  cache.writeQuery<ListJobMembersResponse>({
    query: LIST_JOB_MEMBERS,
    variables: {
      jobId,
    },
    data: {
      listJobMembers: members,
    },
  });
};

export const handleAddTeammate = (
  jobId: string
): MutationUpdaterFn<AddJobMemberResponse> => (cache, { data }) => {
  const addedJobMember = data?.jobAddMember;
  const jobMembers = getJobMembers(cache, jobId);

  if (!addedJobMember || !jobMembers) return;

  const members = concat(jobMembers, addedJobMember);
  updateJobMembers(cache, jobId, members);
};

export const handleRemoveTeammate = (
  jobId: string
): MutationUpdaterFn<RemoveJobMemberResponse> => (cache, { data }) => {
  const deletedJobMember = data?.jobRemoveMember;
  const jobMembers = getJobMembers(cache, jobId);

  if (!deletedJobMember || !jobMembers) return;

  const members = filter(
    jobMembers,
    (member) => member._id !== deletedJobMember._id
  );
  updateJobMembers(cache, jobId, members);
};
