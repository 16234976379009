import { MapField } from "../../../components/import-mapper/types";
import { LeadsColumnMap } from "../../../models/leads";

export const FIELD_MAP_LEAD: MapField<LeadsColumnMap>[] = [
  {
    fieldKey: "stage",
    label: "leads.stage",
    required: true,
  },
  {
    fieldKey: "name",
    label: "leads.leadName",
    required: true,
  },
  {
    fieldKey: "description",
    label: "placeholders.description",
  },
  {
    fieldKey: "expectedCloseDate",
    label: "leads.expectedCloseDate",
  },
  {
    fieldKey: "budget",
    label: "placeholders.budget",
  },
  {
    fieldKey: "address",
    label: "placeholders.address",
  },
  {
    fieldKey: "city",
    label: "placeholders.city",
  },
  {
    fieldKey: "state",
    label: "placeholders.state",
  },
  {
    fieldKey: "postcode",
    label: "placeholders.postcode",
  },
  {
    fieldKey: "tags",
    label: "leads.tags",
  },
  {
    fieldKey: "assignedToFirstName",
    label: "leads.assignedToFirstName",
  },
  {
    fieldKey: "assignedToLastName",
    label: "leads.assignedToLastName",
  },
  {
    fieldKey: "assignedToEmail",
    label: "leads.assignedToEmail",
  },
  {
    fieldKey: "contactFirstName",
    label: "leads.contactFirstName",
    required: true,
  },
  {
    fieldKey: "contactLastName",
    label: "leads.contactLastName",
    required: true,
  },
];
