import * as yup from "yup";
import { TFunction } from "i18next";

import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createRescheduleSchema(t: TFunction) {
  return yup.object().shape({
    move: yup.object().shape({
      moveNumber: yup
        .number()
        .required(
          ValidationMessagesUtils.createRequiredMessage(
            t,
            "schedule.moveNumber"
          )
        ),
      period: yup
        .string()
        .required(
          ValidationMessagesUtils.createRequiredMessage(t, "schedule.period")
        ),
      direction: yup
        .string()
        .required(
          ValidationMessagesUtils.createRequiredMessage(t, "schedule.direction")
        ),
    }),
  });
}
