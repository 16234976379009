import { TFunction } from "i18next";

import {
  UpdateTakeOffPayload,
  TakeOffMeasurement,
} from "../../../../models/take-off";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";

export function createUpdateTakeOffFields(
  t: TFunction
): GenericFormFields<UpdateTakeOffPayload> {
  return {
    name: {
      type: "text",
      label: t("takeOffSection.name"),
      placeholder: t("placeholders.name"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    color: {
      type: "colour-picker",
      label: t("takeOffSection.colour"),
      placeholder: t("placeholders.colour"),
      valueKey: "color",
      className: "take-off-colour-picker",
    },
  };
}
