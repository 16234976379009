import React from "react";
import { components, MultiValueProps } from "react-select";
import classNames from "classnames";

type SelectMultiValueContainerProps = MultiValueProps<any> & {};

const SelectMultiValueContainer: React.FC<SelectMultiValueContainerProps> = (
  props
) => {
  const innerProps = {
    ...props.innerProps,
    className: classNames(props.innerProps.className, "multi-value-container"),
  };

  return <components.MultiValueContainer {...props} innerProps={innerProps} />;
};

export default SelectMultiValueContainer;
