import GSTC, {
  Config,
} from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";

export const createState = (config: any) => {
  // @ts-ignore
  return GSTC.api.stateFromConfig(config);
};

export const getAppUtils = (app: any) => {
  // @ts-ignore
  const { html, svg } = app?.app.vidoInstance;
  return { html, svg };
};

// const generateRows = () => {
//   /**
//    * @type { import("gantt-schedule-timeline-calendar").Rows }
//    */
//   const rows = {} as any;
//   for (let i = 0; i < 1; i++) {
//     const id = `gstcid-placeholder-${i}`;
//     rows[id] = {
//       id,
//       label: `Row ${i}`,
//     };
//   }
//   return rows;
// };

// @ts-ignore
// @ts-ignore
export const defaultConfig: Config = {
  licenseKey: process.env.REACT_APP_GANTT_KEY || "",
  autoInnerHeight: true,
  // actions: {
  //   'chart-timeline-items-row': [updateRowClassAction],
  // },
  // utcMode: true,
  // headerHeight: 120,
  list: {
    toggle: {
      display: false,
    },
    // rowHeight: 60,
    // columns: {
    //   data: {
    //     [GSTC.api.GSTCID('id')]: {
    //       id: GSTC.api.GSTCID('id'),
    //       width: 60,
    //       data: ({ row }) => GSTC.api.sourceID(row.id),
    //       header: {
    //         content: 'ID',
    //       },
    //     },
    //     [GSTC.api.GSTCID('label')]: {
    //       id: GSTC.api.GSTCID('label'),
    //       width: 200,
    //       data: 'label',
    //       header: {
    //         content: 'Label',
    //       },
    //     },
    //   }
    // },
    // rows: generateRows(),
    rows: {},
    // expander: {
    //   icons: {
    //     child:
    //       '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>',
    //   },
    // },
  },
  chart: {
    // items: generateItems(),
    // time: {
    //   leftGlobal: GSTCDate('2021-07-01').valueOf(),
    //   from:  GSTCDate('2021-07-01').valueOf(),
    //   to: GSTCDate('2021-07-01').endOf('month').valueOf()
    // }
  },
  locale: {
    name: "en",
    weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
    weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
    months: "January_February_March_April_May_June_July_August_September_October_November_December".split(
      "_"
    ),
    monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
    weekStart: 1,
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
    formats: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd, D MMMM YYYY HH:mm",
    },
    ordinal: (n: number) => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return `[${n}${s[(v - 20) % 10] || s[v] || s[0]}]`;
    },
  },
};

export type ElementUtils = {
  html: any;
  svg: any;
};
