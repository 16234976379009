import React from "react";
import DashboardCard from "../../dashboard/card";
import DashboardCardHeader from "../../dashboard/card/DashboardCardHeader";
import { useTranslation } from "react-i18next";
import DashboardCardBody from "../../dashboard/card/DashboardCardBody";
import DashboardCardFooter from "../../dashboard/card/DashboardCardFooter";
import "./styles.scss";
import CheckList from "../../check-list";
import { IMPORT_CHECKLIST } from "./constants";
import FileInput, {
  FILE_RESTRICTION_SPREADSHEET,
} from "../../uploaders/file-input";
import { MapField } from "../../import-mapper/types";

type CostingImportUpload<Data> = {
  fieldMap: MapField<Data>[];
  onFileSelect: (file: File[]) => void;
  fileName?: string;
  title?: string;
};

const CostingImportUpload = <Data extends {}>(
  props: CostingImportUpload<Data>
) => {
  const { t } = useTranslation();
  const { fieldMap, onFileSelect, fileName, title } = props;

  const renderLabel = (label: string) => {
    return (
      <div className="field-text form-input-label font-weight-bold">
        {label}
      </div>
    );
  };

  const columns = React.useMemo(() => fieldMap.map((item) => item.label), [
    fieldMap,
  ]);

  return (
    <DashboardCard className="costing-import">
      <DashboardCardHeader>
        {title || t("costing.importCostings")}
      </DashboardCardHeader>
      <DashboardCardBody>
        <div className="field-text form-input-label font-weight-bold">
          {t("contacts.checkList")}
        </div>
        <CheckList items={IMPORT_CHECKLIST} />
        <div className="field-text form-input-label font-weight-bold">
          {t("costing.columnsToImport")}
        </div>
        <CheckList items={columns} />

        {renderLabel(t("contacts.uploadFile"))}
        <FileInput
          fileName={fileName}
          accept={FILE_RESTRICTION_SPREADSHEET}
          label={t("contacts.dropContactsFile")}
          iconSize={60}
          onUpload={onFileSelect}
        />
      </DashboardCardBody>

      <DashboardCardFooter className="import-footer"></DashboardCardFooter>
    </DashboardCard>
  );
};

export default CostingImportUpload;
