import React from "react";
import { useTranslation } from "react-i18next";

import { createUpdateChecklistField } from "./utils";
import CreateEntityModal from "../../../../components/modals/create-entity";
import UpdateEntityModal from "../../../../components/modals/update-entity";
import { GenericFormFields } from "../../../../components/generic-form/GenericFormBody";
import {
  CreateUpdateChecklistGroupPayload,
  TodoChecklistTemplateCategory,
  TodoChecklistTemplateItemInput,
} from "../../../../models/task";
import createGroupShema from "./CreateGroupSchema";
import "./styles.scss";

type CreateUpdateChecklistGroupModalProps = {
  data: TodoChecklistTemplateCategory | null;
  show: boolean;
  onSubmit: (data: CreateUpdateChecklistGroupPayload) => void;
  onClose: () => void;
};

const CreateUpdateChecklistGroupModal: React.FC<CreateUpdateChecklistGroupModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const { show, onSubmit, onClose, data } = props;

  const initialCreateData = React.useMemo<CreateUpdateChecklistGroupPayload>(
    () => ({
      name: "",
      order: 0,
      items: [
        {
          task: "",
          order: 0,
        },
      ],
    }),
    []
  );

  const formFields = React.useMemo<
    GenericFormFields<CreateUpdateChecklistGroupPayload>
  >(() => {
    return createUpdateChecklistField(t);
  }, [t]);

  const getInitialUpdateData = React.useCallback(() => {
    if (data) {
      const result: CreateUpdateChecklistGroupPayload = {
        _id: data._id,
        name: data.name,
      };

      if (data.items) {
        result.items = data.items.map((item, order) => {
          const resultItem: TodoChecklistTemplateItemInput = {
            _id: item._id,
            task: item.task,
            order: item.order || order,
          };
          return resultItem;
        });
      }
      return result;
    }
    return initialCreateData;
  }, [data, initialCreateData]);

  const handleSubmit = React.useCallback(
    (data: CreateUpdateChecklistGroupPayload) => {
      onSubmit({
        ...data,
        items: data.items?.map((item, order) => ({ ...item, order })),
      });
    },
    []
  );

  return data ? (
    <UpdateEntityModal
      className="create-group-modal"
      validationSchema={createGroupShema(t)}
      title={t("tasks.editChecklistGroup")}
      show={show}
      data={getInitialUpdateData()}
      onSubmit={handleSubmit}
      onClose={onClose}
      fields={formFields}
    />
  ) : (
    <CreateEntityModal
      className="create-group-modal"
      validationSchema={createGroupShema(t)}
      title={t("tasks.addGroup")}
      show={show}
      data={initialCreateData}
      onSubmit={handleSubmit}
      onClose={onClose}
      fields={formFields}
    />
  );
};

export default CreateUpdateChecklistGroupModal;
