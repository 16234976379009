import { gql } from "@apollo/client";

export const SUBSCRIPTION_PLAN = gql`
  fragment Plan on SubscriptionPlan {
    _id
    name
    price
    limit
    subscribed
    subscribable
  }
`;
