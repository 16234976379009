import React from "react";
import classNames from "classnames";

type ChecklistBadgeProps = {
  progress: number;
};

const ChecklistBadge: React.FC<ChecklistBadgeProps> = ({ progress }) => {
  const styles = classNames("badge", {
    "badge-success": progress === 100,
    "badge-warning": progress > 0 && progress < 100,
    "badge-danger": progress === 0,
  });

  return <span className={styles}>{`${progress}%`}</span>;
};

export default ChecklistBadge;
