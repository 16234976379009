import { TFunction } from "i18next";
import { map } from "lodash";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

import {
  CreateCostingCategoryPayload,
  CreateCostingItemPayload,
} from "../../../models/salesQuote";
import { SupplierListItem } from "../../../models/supplier";

export const createQuoteRequestFields = (
  t: TFunction,
  openSupplierModal: () => void,
  suppliers?: SupplierListItem[]
): GenericFormFields<CreateCostingCategoryPayload> => {
  const supplierOptions = map(suppliers, (supplier) => ({
    value: supplier._id,
    label: supplier.business_name,
  }));
  return {
    title: {
      type: "text",
      label: t("quoteRequest.title"),
      placeholder: t("quoteRequest.title"),
      valueKey: "name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    suppliers: {
      type: "creatable-select",
      label: t("placeholders.suppliers"),
      placeholder: t("placeholders.suppliers"),
      valueKey: "suppliers",
      creatableProps: {
        autoSelectCreatedOption: false,
        isMulti: true,
        options: supplierOptions,
        isValidNewOption: () => false,
      },
      inputProps: {
        required: true,
      },
      append: {
        onClick: openSupplierModal,
        icon: "add",
      },
    },
    due_date: {
      type: "date-picker",
      label: t("quoteRequest.due_date"),
      placeholder: t("quoteRequest.due_date"),
      valueKey: "due_date",
      inputProps: {
        required: true,
      },
    },
    scope: {
      type: "text",
      controlType: "textarea",
      label: t("quoteRequest.proposal"),
      placeholder: t("quoteRequest.scopeOfWork"),
      valueKey: "scope",
      inputProps: {
        rows: 5,
      },
    },
  };
};
