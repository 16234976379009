import "./styles.scss";
import React from "react";
import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { Redirect } from "react-router-dom";

type AuthenticationLayoutProps = React.PropsWithChildren<{
  isAuthenticated: boolean;
}>;

const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = ({
  children,
  isAuthenticated,
}) => {
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <div className="authentication-layout">{children}</div>;
};

const mapStateToProps = (state: RootReducerState) => ({
  isAuthenticated: state.authentication.isAuthenticated,
});

export default connect(mapStateToProps)(AuthenticationLayout);
