import { gql } from "@apollo/client";

export const SUBMIT_QUOTE_REQUEST = gql`
  mutation($quoteRequestId: ID!, $items: [SubcontractorQuoteItemInput]!) {
    subcontractorSubmitQuote(quoteRequestId: $quoteRequestId, items: $items) {
      _id
    }
  }
`;

export const COMMENT_QUOTE_REQUEST = gql`
  mutation($quoteRequestId: ID!, $comment: QuoteRequestCommentInput!) {
    subcontractorCommentQuoteRequest(
      quoteRequestId: $quoteRequestId
      comment: $comment
    ) {
      _id
      subcontractorResponseId
      comment
      is_deleted
      updatedAt
      createdAt
      attachments {
        _id
        url
        upload_url
        type
        size
      }
    }
  }
`;
