import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createCategoryItemSchema(
  t: TFunction,
  isTemplate?: boolean
) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "common.name")
      ),
    ...(!isTemplate && { costingCategoryEntityId: yup.string().required() }),
    ...(!isTemplate && { costingItemEntityId: yup.string().required() }),
    ...(isTemplate && { costingItemEntityName: yup.string().required() }),
    description: yup.string(),
    dueDate: yup.string(),
  });
}
