import { MapField } from "../../../components/import-mapper/types";
import { BulkJobsScheduleCategoryItemPayload } from "../../../models/job";

export type ScheduleCategoryImportMap = {
  [field in keyof Required<BulkJobsScheduleCategoryItemPayload>]: string;
};

export const FIELD_MAP_JOB_SCHEDULE: MapField<ScheduleCategoryImportMap>[] = [
  {
    fieldKey: "category",
    label: "schedule.category",
    required: true,
  },
  {
    fieldKey: "name",
    label: "schedule.taskName",
    required: true,
  },
  {
    fieldKey: "description",
    label: "schedule.description",
  },
  {
    fieldKey: "startDate",
    label: "schedule.startDate",
    required: true,
  },
  {
    fieldKey: "endDate",
    label: "schedule.endDate",
    required: true,
  },
  {
    fieldKey: "progress",
    label: "schedule.progressPercent",
  },
  {
    fieldKey: "predecessor",
    label: "schedule.predecessor",
  },
];
