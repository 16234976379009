import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { compact, find, map, sumBy } from "lodash";
import moment from "moment";
import {
  PurchaseOrderReal,
  PurchaseOrderStatus,
} from "../../../models/purchaseOrder";
import CustomReportCard from "../../dashboard/report-card/custom-report-card";
import DashboardCardField from "../../dashboard/card/DashboardCardField";
import Totals from "../../costing/total";
import CardTable from "../../dashboard/table-card/CardTable";
import {
  TableCardData,
  TableRowActionData,
} from "../../dashboard/table-card/utils";
import {
  CostingTable,
  CostingTableItem,
  createExpandedCostingTable,
} from "../../costing/utils";
import { formatQuoteNumber } from "../../../utils/text";
import ReceiptModal, { ReceiptModalRef } from "../receipt-modal";
import {
  DATE_FORMAT_DATETIME_NUMERIC_24,
  DATE_FORMAT_DATE_STRING,
} from "../../../constants/dates";
import { useViewInJob } from "../../../hooks/useViewInJobs";
import { getOrderBadgeVariant } from "../../../containers/jobs/job-purchase-orders/utils";
import "./styles.scss";

type OrderCardProps = {
  order: PurchaseOrderReal;
  onSend?: (id: string) => void;
  onDelete?: (id: string) => void;
  onEdit?: () => void;
  onReceive?: (id: string) => void;
  onMarkReceive?: (id: string) => void;
  onPrint?: (id: string) => void;
  onSync?: (id: string) => void;
  onSupplierClick?: () => void;
  disableCancel?: boolean;
  onCancel?: (id: string) => void;
  onMarkOrderReceiptBillable?: (
    purchaseOrderId: string,
    purchaseOrderReceiptId: string,
    isBillable: boolean
  ) => void;
  syncProvider?: string;
  withoutControls?: boolean;
  isGlobalView?: boolean;
  isCostPlus?: boolean;
};

type ReceiptTable = {
  _id: string;
  reference: string;
  dateReceived: string;
  dueDate?: string;
  subTotal: number;
  GST: number;
  total: number;
  isNonBillable: boolean;
};

const OrderCard: React.FC<OrderCardProps> = (props) => {
  const { t } = useTranslation();

  const {
    order,
    onSend,
    onReceive,
    onMarkReceive,
    onCancel,
    onPrint,
    onSync,
    onDelete,
    onEdit,
    onSupplierClick,
    disableCancel,
    syncProvider,
    withoutControls,
    isGlobalView = false,
    isCostPlus,
    onMarkOrderReceiptBillable,
  } = props;

  const {
    _id,
    status,
    orderNumber,
    deliveryAddress,
    deliveryInstructions,
    deliveryDate,
    attention,
    date,
    contactNumber,
    reference,
    supplier,
    hasGST,
    hideCost,
    items,
    receipts,
    job,
    internalNote,
  } = order;

  const receiptRef = React.useRef<ReceiptModalRef>();
  const shouldHideCost = React.useMemo(
    () => hideCost && status !== PurchaseOrderStatus.RECEIVED,
    [hideCost, status]
  );

  const categoryData = React.useMemo<TableCardData<CostingTable>>(() => {
    let hideColumns = ["margin_amount"];
    if (shouldHideCost) {
      hideColumns.push("cost", "total");
    }
    return createExpandedCostingTable(
      items,
      t,
      0,
      hideColumns as [keyof CostingTableItem],
      "common.description"
    );
  }, [items, shouldHideCost, t]);

  const receiptsData = React.useMemo<TableCardData<ReceiptTable>>(
    () => ({
      columns: compact([
        {
          valueKey: "dateReceived",
          title: t("orders.dateReceived"),
          formatValue: (row: any, column: any, value: string) =>
            value
              ? moment(value).format(DATE_FORMAT_DATE_STRING)
              : t("common.na"),
        },
        {
          valueKey: "dueDate",
          title: t("common.dueDate"),
          formatValue: (row: any, column: any, value: string) =>
            value
              ? moment(value).format(DATE_FORMAT_DATE_STRING)
              : t("common.na"),
        },
        {
          valueKey: "reference",
          title: t("orders.reference"),
        },
        {
          valueKey: "subTotal",
          title: t("costing.subTotal"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "GST",
          title: t("costing.gst"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
        {
          valueKey: "total",
          title: t("costing.total"),
          formatValue: (row: any, column: any, value: string) =>
            t("common.currency", { amount: value }),
        },
        isCostPlus && {
          valueKey: "isNonBillable",
          title: t("orders.billable"),
          formatValue: (row: any, column: any, value: string) =>
            value ? t("orders.nonBillable") : t("orders.billable"),
        },
      ]),
      rows: map(receipts, (receipt) => ({
        cells: receipt,
      })),
    }),
    [receipts, isCostPlus]
  );

  const receiptsRowActions: TableRowActionData<ReceiptTable>[] = React.useMemo(
    () =>
      compact([
        {
          icon: "search",
          dropdownId: "view",
          onClick: (row) => {
            const receipt = find(receipts, { _id: row._id });
            receipt && receiptRef.current?.show(true, receipt);
          },
        },
        isCostPlus && {
          icon: "attach_money",
          dropdownId: "billable",
          tooltip: (row) => t("orders.markAsBillable"),
          onClick: (row) =>
            onMarkOrderReceiptBillable?.(
              order._id,
              row._id,
              row?.isNonBillable
            ),
          shouldRender: (row) => row?.isNonBillable,
        },
        isCostPlus && {
          icon: "money_off",
          dropdownId: "not-billable",
          tooltip: (row) => t("orders.markAsNonBillable"),
          onClick: (row) =>
            onMarkOrderReceiptBillable?.(
              order._id,
              row._id,
              row?.isNonBillable
            ),
          shouldRender: (row) => !row?.isNonBillable,
        },
      ]),
    [isCostPlus, receipts, t, onMarkOrderReceiptBillable, order._id]
  );

  const handlePrint = React.useCallback(() => {
    onPrint && onPrint(_id);
  }, [onPrint, _id]);

  const handleDelete = React.useCallback(() => {
    onDelete && onDelete(_id);
  }, [onDelete, _id]);

  const handleReceive = React.useCallback(() => {
    onReceive && onReceive(_id);
  }, [onReceive, _id]);

  const handleMarkReceive = React.useCallback(() => {
    onMarkReceive && onMarkReceive(_id);
  }, [onMarkReceive, _id]);

  const handleCancel = React.useCallback(() => {
    onCancel && onCancel(_id);
  }, [onCancel, _id]);

  const handleSend = React.useCallback(() => {
    onSend && onSend(_id);
  }, [onSend, _id]);

  const handleSync = React.useCallback(() => {
    onSync && onSync(_id);
  }, [onSync, _id]);

  const handleEdit = React.useCallback(() => {
    onEdit && onEdit();
  }, [onEdit]);

  const supplierName = supplier
    ? compact([supplier.business_name, supplier.contact_person]).join(" / ")
    : "";

  const subtotalReceipt = React.useMemo(() => {
    return sumBy(receipts, "subTotal");
  }, [receipts]);

  const gstReceipt = React.useMemo(() => {
    return sumBy(receipts, "GST");
  }, [receipts]);

  const hasReceipt = React.useMemo(() => {
    return receipts && receipts.length > 0;
  }, [receipts]);

  const dropdownItems = React.useMemo(() => {
    const controls = compact([
      {
        id: "print",
        label: t("common.print"),
        icon: "print",
        outlined: true,
        onClick: handlePrint,
      },
      {
        id: "mail",
        label: t("common.send"),
        icon: "mail",
        outlined: true,
        onClick: handleSend,
      },
    ]);

    if (
      status === PurchaseOrderStatus.RECEIVED ||
      status === PurchaseOrderStatus.PARTIALLY_RECEIVED
    ) {
      controls.push({
        id: "cancel",
        label: t("orders.cancelOrder"),
        icon: "cancel",
        outlined: true,
        onClick: handleCancel,
      });
      if (status === PurchaseOrderStatus.PARTIALLY_RECEIVED) {
        controls.push({
          id: "receive",
          label: t("orders.markAsReceived"),
          icon: "check",
          outlined: true,
          onClick: handleMarkReceive,
        });
      }
    }
    controls.push({
      id: "check",
      label: t("orders.receiveOrder"),
      icon: "receipt_long",
      outlined: true,
      onClick: handleReceive,
    });
    if (
      status !== PurchaseOrderStatus.RECEIVED &&
      status !== PurchaseOrderStatus.PARTIALLY_RECEIVED
    ) {
      controls.push({
        id: "edit",
        label: t("common.edit"),
        icon: "edit",
        outlined: true,
        onClick: handleEdit,
      });
      controls.push({
        id: "delete",
        label: t("common.delete"),
        icon: "delete",
        outlined: true,
        onClick: handleDelete,
      });
    }
    if (status !== PurchaseOrderStatus.DRAFT) {
      onSync &&
        controls.push({
          id: "sync",
          label: t("integrations.syncWithProvider", {
            provider: t(`integrations.${syncProvider?.toLowerCase()}`),
          }),
          icon: "sync",
          outlined: true,
          onClick: handleSync,
        });
    }
    return controls;
  }, [
    handlePrint,
    handleSend,
    handleDelete,
    handleReceive,
    onSync,
    handleSync,
    syncProvider,
    onEdit,
    status,
  ]);

  const { buttons } = useViewInJob({
    isGlobalView: isGlobalView,
    jobId: job?._id,
    orderId: _id,
    path: "purchase-orders",
  });

  return (
    <CustomReportCard
      controls={dropdownItems}
      title={formatQuoteNumber(orderNumber, "PO")}
      badges={[
        {
          variant: getOrderBadgeVariant(order.status),
          label: t(`orders.statuses.${order.status}`),
        },
      ]}
      className="order-card"
      withoutControls={withoutControls}
      buttons={buttons}
    >
      <ReceiptModal ref={receiptRef} order={order} />
      <Row className="">
        <Col className="report-col" lg={4} xs={12} md={6}>
          <DashboardCardField
            placeholder={t("orders.noReference")}
            title={t("orders.reference")}
            content={reference}
          />
        </Col>
        <Col className="report-col" lg={4} xs={12} md={6}>
          <DashboardCardField
            title={t("orders.contactName")}
            content={supplierName}
            onClick={onSupplierClick}
          />
        </Col>
        <Col className="report-col" lg={4} xs={12} md={6}>
          <DashboardCardField
            title={t("company.abnNumber")}
            content={supplier?.abn}
            onClick={onSupplierClick}
          />
        </Col>
      </Row>

      <Row className="main-row">
        <Col className="report-col" lg={4} xs={12} md={6}>
          <DashboardCardField
            title={t("orders.orderDate")}
            content={moment(date).format(DATE_FORMAT_DATE_STRING)}
          />
        </Col>
        <Col className="report-col" lg={4} xs={12} md={6}>
          <DashboardCardField
            title={t("orders.deliveryDate")}
            content={
              deliveryDate
                ? moment(deliveryDate).format(DATE_FORMAT_DATE_STRING)
                : ""
            }
          />
        </Col>
        <Col className="report-col" lg={4} xs={12} md={6}>
          {syncProvider && (
            <DashboardCardField
              title={t("integrations.lastSyncDate")}
              content={
                order.externalLastSyncDate
                  ? moment(order.externalLastSyncDate).format(
                      DATE_FORMAT_DATETIME_NUMERIC_24
                    )
                  : t("common.na")
              }
            />
          )}
        </Col>
      </Row>

      <Row className="table-row">
        <CardTable rowCount table={categoryData} showCountTitle={true} />
      </Row>

      <Row className="row--big">
        {!!internalNote && (
          <Col lg={12} xs={12} className="report-col--big">
            <DashboardCardField
              placeholder={t("orders.noNotes")}
              title={t("common.internalNote")}
              content={internalNote}
              wrapWords
            />
          </Col>
        )}
        <Col lg={7} xs={12} className="report-col--big">
          <div className="delivery">
            <div className="field-text field-text--underlined delivery-title">
              {t("orders.delivery")}
            </div>

            <Row className="delivery-body">
              <Col xl={6} xs={12}>
                <DashboardCardField
                  title={t("orders.attention")}
                  content={attention}
                />
                <DashboardCardField
                  title={t("orders.contactNumber")}
                  content={contactNumber}
                />
                <DashboardCardField
                  title={t("orders.address")}
                  content={deliveryAddress}
                />
              </Col>
              <Col xl={6} xs={12}>
                <DashboardCardField
                  title={t("orders.instruction")}
                  placeholder={t("orders.noInstruction")}
                  content={deliveryInstructions}
                  height="320px"
                  wrapWords
                />
              </Col>
            </Row>
          </div>
        </Col>

        <Col lg={5} xs={12} className="report-col--big">
          <Totals
            title={t("orders.amount")}
            subtotal={order.subTotal}
            gst={order.GST}
            total={order.total}
            hasGST={hasGST}
          />
          {hasReceipt && (
            <Totals
              title={t("orders.receiptAmount")}
              subtotal={subtotalReceipt}
              gst={gstReceipt}
              total={gstReceipt + subtotalReceipt}
              difference={subtotalReceipt - order.subTotal}
              hasGST={hasGST}
            />
          )}
        </Col>
      </Row>

      {hasReceipt && (
        <Row className="row--big">
          <Col lg={12} xs={12} className="report-col--big">
            <div className="delivery">
              <div className="field-text field-text--underlined delivery-title">
                {t("orders.receipts")}
              </div>

              <CardTable
                rowCount
                table={receiptsData}
                showCountTitle={true}
                rowActions={receiptsRowActions}
              />
            </div>
          </Col>
        </Row>
      )}
    </CustomReportCard>
  );
};

export default OrderCard;
