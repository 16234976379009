import * as yup from "yup";
import { TFunction } from "i18next";

export default function createSignUpFormSchema(t: TFunction) {
  return yup.object().shape({
    businessName: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.businessName"),
      })
    ),
    firstName: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.firstName"),
      })
    ),
    lastName: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.lastName"),
      })
    ),
    email: yup
      .string()
      .email()
      .required(
        t("authentication.errors.isRequired", {
          fieldName: t("authentication.emailAddress"),
        })
      ),
    phone: yup
      .string()
      .required(
        t("authentication.errors.isRequired", {
          fieldName: t("authentication.phoneNumber"),
        })
      )
      .matches(
        /^[0-9]+$/,
        t("authentication.errors.isInvalid", {
          fieldName: t("authentication.phoneNumber"),
        })
      )
      .min(
        10,
        t("authentication.errors.isInvalid", {
          fieldName: t("authentication.phoneNumber"),
        })
      )
      .max(
        10,
        t("authentication.errors.isInvalid", {
          fieldName: t("authentication.phoneNumber"),
        })
      ),
    password: yup.string().required(
      t("authentication.errors.isRequired", {
        fieldName: t("authentication.password"),
      })
    ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), ""],
        t("authentication.errors.confirmPassword")
      )
      .required(
        t("authentication.errors.isRequired", {
          fieldName: t("authentication.confirmPassword"),
        })
      ),
  });
}
