import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createQuoteRequestCostingSchema(t: TFunction) {
  return yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        cost: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.cost")
          ),
      })
    ),
  });
}
