import { gql } from "@apollo/client";
import { LEAD_FRAGMENT } from "./fragments";

export const CREATE_UPDATE_LEAD_STAGE = gql`
  mutation createUpdateLeadStage($stage: LeadStageInput!) {
    createUpdateLeadStage(stage: $stage) {
      _id
      name
      order
      leads {
        ...LeadFragment
      }
    }
  }
  ${LEAD_FRAGMENT}
`;

export const CREATE_UPDATE_LEAD = gql`
  mutation createUpdateLead($lead: LeadSaleInput!) {
    createUpdateLead(lead: $lead) {
      ...LeadFragment
    }
  }
  ${LEAD_FRAGMENT}
`;

export const UNLINK_LEAD_FROM_ESTIMATE = gql`
  mutation unLinkLeadFromEstimate($leadId: ID!, $estimateId: ID!) {
    unLinkLeadFromEstimate(leadId: $leadId, estimateId: $estimateId) {
      ...LeadFragment
    }
  }
  ${LEAD_FRAGMENT}
`;

export const LINK_LEAD_TO_ESTIMATE = gql`
  mutation linkLeadToEstimate($leadId: ID!, $estimateId: ID!) {
    linkLeadToEstimate(leadId: $leadId, estimateId: $estimateId) {
      ...LeadFragment
    }
  }
  ${LEAD_FRAGMENT}
`;

export const UPDATE_LEAD_TAGS = gql`
  mutation updateLeadTags($leadId: ID!, $tags: [String!]) {
    updateLeadTags(leadId: $leadId, tags: $tags) {
      ...LeadFragment
    }
  }
  ${LEAD_FRAGMENT}
`;

export const REORDER_LEAD_STAGES = gql`
  mutation reOrderLeadStages($orderedList: [ID!]!) {
    reOrderLeadStages(orderedList: $orderedList) {
      _id
    }
  }
`;

export const DELETE_LEAD_STAGES = gql`
  mutation deleteLeadStage($leadStageId: ID!) {
    deleteLeadStage(leadStageId: $leadStageId) {
      _id
    }
  }
`;

export const RE_ORDER_LEADS = gql`
  mutation reOrderLeads($leadStageId: ID!, $orderedList: [ID!]!) {
    reOrderLeads(leadStageId: $leadStageId, orderedList: $orderedList) {
      _id
    }
  }
`;

export const DELETE_LEAD = gql`
  mutation DdeleteLead($leadId: ID!) {
    deleteLead(leadId: $leadId) {
      _id
    }
  }
`;

export const IMPORT_LEADS = gql`
  mutation ImportLeads($leads: [ImportLeadSaleInput]!) {
    importLeads(leads: $leads) {
      ...LeadFragment
    }
  }
  ${LEAD_FRAGMENT}
`;
