import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Dropdown from "../../../components/dashboard/dropdown";
import { BaseCard, Column } from "..";
import ConfirmInput from "../../confirm-input";
import "./style.scss";

type ListProps<TItem> = {
  list: Column<TItem>;
  index: number;
  isDraggingOver: boolean;
  onDelete: (stageID: string) => void;
  onRename: (stageID: string, order: number, name: string) => void;
  renderCard: (item: TItem, index: number) => JSX.Element;
  listInfo?: {
    amount: string;
    count: string;
  };
};

const List = <TItem extends BaseCard>({
  list,
  index,
  isDraggingOver,
  onDelete,
  renderCard,
  listInfo,
  onRename,
}: ListProps<TItem> & { children?: React.ReactNode }) => {
  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "#dbdcdf" : "transparent",
  });
  const getTitleListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "#3fbb64" : "transparent",
    color: isDraggingOver ? "white" : "#7d829e",
  });

  const [isDropdownShow, setIsDropdownShow] = React.useState(false);
  const [isEditInputShow, setIsEditInputShow] = React.useState(false);

  const toggleDropdownShow = React.useCallback(
    () => setIsDropdownShow(!isDropdownShow),
    [isDropdownShow]
  );

  const handleOnRenameStage = React.useCallback(
    (stageId: string) => setIsEditInputShow(true),
    []
  );

  const dropDownItem = [
    {
      id: "editStage",
      onClick: () => handleOnRenameStage(list._id),
      label: "Rename",
      icon: "edit",
    },
    {
      id: "deleteStage",
      onClick: () => onDelete(list._id),
      label: "Delete",
      icon: "delete",
    },
  ];

  const onRenameStage = React.useCallback(
    (value: string) => {
      if (value !== list.name) {
        onRename(list._id, list.order, value);
      }
      setIsEditInputShow(false);
    },
    [list]
  );

  const handlePressCancel = React.useCallback(() => {
    setIsEditInputShow(false);
  }, []);

  return (
    <Draggable draggableId={list._id} index={index}>
      {(provided) => (
        <div
          key={list._id}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="list-cards">
            {
              //@ts-ignore
              <div
                className="title-list"
                id={list._id}
                {...provided.dragHandleProps}
                style={getTitleListStyle(isDraggingOver)}
              >
                <div className="d-flex align-item-center justify-content-between">
                  <div>
                    {isEditInputShow ? (
                      <ConfirmInput
                        onCancel={handlePressCancel}
                        onSubmit={onRenameStage}
                        inOneRow
                        initialValue={list.name}
                      />
                    ) : (
                      <div>{list.name}</div>
                    )}

                    <div className="list-info">
                      {listInfo && (
                        <>
                          <b>{listInfo?.amount} · </b>
                          <span>{listInfo?.count}</span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className={`more ${isEditInputShow && "d-none"}`}>
                    <Dropdown
                      menuWidth="50px"
                      icon="more_horiz"
                      isVisible={isDropdownShow}
                      handleToggle={toggleDropdownShow}
                      light
                      id="stage-dropdown"
                      items={dropDownItem}
                      toggleClass="toggleClass"
                    />
                  </div>
                </div>
              </div>
            }
            <Droppable key={list._id} droppableId={list._id} type="lead">
              {(provided, snapshot) => {
                return (
                  <div
                    className="container-cards"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <div className="card-container">
                      {list.cards.map(renderCard)}
                    </div>
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default List;
