import { ClaimItem, ClaimStatusesEnum } from "./claim";
import { ContactDetail } from "../graphql/types/models/client";
import { TaskAssignTo } from "../utils/options";
import { User } from "../graphql/types/models/user";
import { SupplierDetails } from "./supplier";
import {
  QuoteRequestItemStatus,
  QuoteRequestModalListItem,
} from "../graphql/types/models/quote-request";
import {
  PurchaseOrderItem,
  PurchaseOrderReal,
  PurchaseOrderReceipt,
} from "./purchaseOrder";
import { SalesQuote } from "./salesQuote";
import { Timesheet } from "./timesheet";
import { RosterModalListItem } from "./roster";
import { VariationModalListItem } from "./variations";

export type ClientJob = {
  _id: string;
  jobNumber: number;
  name: string;
  startDate: string;
  endDate: string;
  updatedAt: string;
  createdAt: string;
};

export enum JobStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}
export enum JobType {
  CONTRACT = "CONTRACT",
  COST_PLUS = "COST_PLUS",
}

export type JobCostingSubItemPayload = {
  _id?: string;
  UOM: string;
  cost: number | null;
  name: string;
  quantity: number | null;
};
export type JobCostingItemPayload = {
  _id?: string;
  UOM: string;
  cost: number | string | null;
  cost_inc?: number | null;
  name: string;
  hasGST: boolean;
  gstFree?: boolean;
  quantity: number | string | null;
  raw_quantity?: string;
  wastage: number;
  rounding: string;
  real_cost: number | string | null;
  real_cost_inc?: number | null;
  items?: JobCostingSubItemPayload[];
  assemblyId?: string;
  order: number;
};

export type CreateJobCostingPayload = {
  name: string;
  can_timesheet: boolean;
  items: JobCostingItemPayload[];
};

export type UpdateJobCostingPayload = CreateJobCostingPayload & {
  _id: string;
};

export type CreateJobPayload = {
  _id?: string;
  salesQuoteId?: string;
  customer: string;
  name: string;
  contractTotal: number;
  startDate: string;
  endDate: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  type: string;
  markup: number;
};

export type ConfigureInvoicePayload = {
  invoiceType: JobInvoiceType;
  retention: JobRetention;
  excludeVariationFromClaimAmount: boolean;
};

export type CreateTaskGroupPayload = {
  name: string;
  startDate: string;
  endDate: string;
  colour?: string;
};

export type UpdateTaskGroupPayload = CreateTaskGroupPayload & {
  _id?: string;
  updateItems?: boolean;
  complete?: boolean;
};

export type CreateTaskPayload = {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  progress: number;
  supplier: string | null;
  user: string | null;
  predecessor?: string;
  assignTo?: TaskAssignTo;
  reminder?: TaskReminder;
  _id?: string;
};

export type UpdateTaskPayload = CreateTaskPayload & {
  _id: string;
};

export type Task = {
  id: string | number;
  name: string;
  parentId: string | number | null;
  startDate: Date;
  finishDate: Date;
  progress: number;
};
export type TaskReminder = {
  daysBefore?: number | string;
  timezone: string;
};

export enum ScheduleBulkAction {
  UPDATE = "UPDATE",
  ASSIGN = "ASSIGN",
  RESCHEDULE = "RESCHEDULE",
  COMPLETE = "COMPLETE",
  DELETE = "DELETE",
}

export enum ScheduleMovePeriod {
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
}

export enum ScheduleMoveDirection {
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
}

export type BulkUpdateSchedulePayload = {
  action: ScheduleBulkAction;
  move?: {
    moveNumber: number;
    period: ScheduleMovePeriod;
    direction: ScheduleMoveDirection;
  };
};

export type DeleteScheduleTemplatePayload = {
  scheduleTemplateId: string;
};

export type ImportScheduleFromTemplatePayload = {
  jobId: string;
  scheduleTemplateId: string;
  date: string;
};

export type SaveScheduleAsTemplatePayload = {
  jobId: string;
  scheduleTemplate: {
    name: string;
    saveAssignedUsers: boolean;
    saveAssignedSuppliers: boolean;
    saveReminders: boolean;
  };
};

export type ScheduleCategoryItem = {
  _id: string;
  startDate: string;
  supplier?: SupplierDetails;
  user?: User;
  // supplier?: {
  //   _id: string;
  // };
  // user?: {
  //   _id: string;
  // };
  category?: {
    _id: string;
  };
  job?: JobDetails;
  endDate: string;
  name: string;
  description: string;
  progress: number;
  predecessor?: ScheduleCategoryItem;
  dependant?: ScheduleCategoryItem[];
  reminder?: TaskReminder;
};

export type JobSchedule = {
  _id: string;
  job: JobDetails;
  categories: ScheduleCategory[];
};

export type ScheduleCategory = {
  _id: string;
  startDate: string;
  endDate: string;
  name: string;
  colour?: string;
  items?: ScheduleCategoryItem[];
  supplier?: SupplierDetails;
  user?: User;
  is_deleted?: boolean;
};

export type ScheduleCategoryChartItem = ScheduleCategory & {
  description?: string;
  progress?: number;
  predecessor?: ScheduleCategoryItem;
  dependant?: ScheduleCategoryItem[];
};

type JobListItemCustomer = {
  _id: string;
  last_name: string;
  first_name: string;
};

export type JobListItem = {
  _id: string;
  jobNumber: number;
  name: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  customer: JobListItemCustomer | null;
  contractTotal: number;
  contractTotalGST: number;
  totalVariation: number;
  totalClaimed: number;
  totalClaims: number;
  totalRealCost: number;
  totalEstimated: number;
  totalEstimatedWithMarkup: number;
  totalWIP: number;
  type: JobType;
  createdAt: string;
  updatedAt: string;
};
export type JobCostingItemSubItem = {
  _id: string;
  UOM: string;
  cost: number;
  name: string;
  quantity: number;
  computed_quantity: number;
  wastage?: number;
  rounding?: string;
};

export type JobCostingPurchaseOrder = PurchaseOrderReal & {
  progressClaimItems?: ClaimItem[];
};

export type JobCostingPurchaseOrderItem = PurchaseOrderItem & {
  orderId: string;
  purchaseOrder: JobCostingPurchaseOrder;
  receipts: PurchaseOrderReceipt[];
};
export type JobCostingItem = {
  _id?: string;
  UOM: string;
  cost: number;
  markup_cost?: number;
  name: string;
  raw_quantity: string;
  quantity: number;
  wastage: number;
  rounding: string;
  real_cost: number;
  hasGST: boolean;
  gstFree?: boolean;
  can_timesheet?: boolean;
  note: string;
  computed_quantity?: number;
  category?: {
    _id: string;
    name: string;
  };
  items?: JobCostingItemSubItem[];
  purchaseOrderItems?: JobCostingPurchaseOrderItem[];
  progressClaimItems?: ClaimItem[];
  timesheetItems?: Timesheet[];
  variation?: {
    _id: string;
    variationId: string;
  };
  roster?: {
    _id: string;
    rosterId: string;
  };
  rosterItems?: RosterModalListItem[];
  variationItems?: VariationModalListItem[];
  quoteRequestItems?: QuoteRequestModalListItem[];
  is_locked?: boolean;
  is_restricted_entry?: boolean;
  is_allowance: boolean;
  quoteRequestStatus?: QuoteRequestItemStatus;
  type?: string;
  assemblyId?: string;
};

export type JobCostingCategory = {
  _id: string;
  name: string;
  can_timesheet: boolean;
  is_locked?: boolean;
  is_restricted_entry?: boolean;
  totalRealCost?: number;
  totalEstimatedCost?: number;
  totalMarkup?: number;
  items: JobCostingItem[];
  order: number;
};

export type JobCostingTableCategory = {
  _id: string;
  name: string;
  totalRealCost: number;
  clientTotal: number;
  totalEstimatedCost: number;
  diff: number;
  items: JobCostingItem[];
  order: number;
  claimedAmount: number;
};

export type JobCostingItemBasic = {
  _id?: string;
  name: string;
  UOM: string;
};
export type JobCostingCategoryBasic = {
  _id: string;
  name: string;
  items: JobCostingItemBasic[];
  jobId?: string;
};

export type JobFile = {
  _id: string;
  name: string;
  type: string;
  size: number;
  upload_url: string;
};

export type JobBusiness = {
  _id: string;
  name: string;
};

export enum JobInvoiceType {
  Standard = "STANDARD",
  Retention = "RETENTION",
}

export type JobRetention = {
  invoicePercentage: number;
  totalPercentage: number;
};

export type JobDetails = {
  _id: string;
  jobNumber: number;
  name: string;
  contractTotal: number;
  contractTotalGST: number;
  business: JobBusiness | null;
  address: string;
  city: string;
  city_normalized: string;
  state: string;
  postcode: string;
  customer: ContactDetail | null;
  startDate: string;
  endDate: string;
  type: JobType;
  markup: number;
  updatedAt: string;
  createdAt: string;
  is_locked: boolean;
  is_contract_locked: boolean;
  is_non_contracted: boolean;
  salesQuote?: SalesQuote;
  invoiceType?: JobInvoiceType;
  invoiceToJobAddress?: boolean;
  retention?: JobRetention;
  excludeVariationFromClaimAmount?: boolean;
};

export type DashboardScheduleItem = {
  _id: string;
  name: string;
  progress: number;
};

export type DashboardClaim = {
  _id: string;
  amount: number;
  invNumber: number;
  status: ClaimStatusesEnum;
};

export type JobDashboard = {
  _id: string;
  budget: number;
  total_variation: number;
  total_invoiced: number;
  total_profit: number;
  progress_claims: DashboardClaim[];
  today_schedules: DashboardScheduleItem[];
};

export type JobTotal = {
  contractTotal: number;
  totalEstimated: number;
  totalEstimatedWithMarkup: number;
  totalEstimatedWithMarkupGST: number;
  totalRealCost: number;
  totalVariation: number;
  totalVariationGST: number;
  totalVariationClaimed: number;
  totalVariationClaimedGST: number;
  totalVariationItemClaimed: number;
  totalVariationItemClaimedGST: number;
  totalCost: number;
  totalClaims: number;
  totalClaimsGST: number;
  totalClaimed: number;
  totalClaimedGST: number;
  totalClaimedPercent: number;
  completionPercent: number;
  totalProfit: number;
  totalRetained: number;
  totalRetainedGST: number;
  totalRetainable: number;
  totalRetainableGST: number;
  totalRetentionClaimed: number;
  totalRetentionClaimedGST: number;
  totalWIP: number;
  totalRevenue: number;
  totalExpectedRealCost: number;
  totalGrossProfit: number;
  totalGrossMargin: number;
};

export type JobBillOfQuantityPreview = {
  _id: string;
  pdf: string;
};

export type JobWithStatus = {
  is_locked: boolean;
  _id: string;
  name: string;
};

export type DeleteJobResponse = {
  deleteJob: JobListItem;
};

export type BulkJobsScheduleCategoryPayload = {
  _id?: string;
  name: string;
  startDate: string;
  endDate: string;
  colour?: string;
  items?: JobCostingItem[];
};

export type BulkJobsScheduleCategoryItemPayload = {
  _id?: string;
  categoryId?: string;
  category: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  progress?: number;
  predecessor?: string;
};

export type JobSchedulePreview = {
  _id: string;
  pdf: string;
};

export type SearchJobCostingItem = JobCostingItem & {
  category: {
    _id: string;
    name: string;
  };
};

export type ScheduleTemplate = {
  _id: string;
  name: string;
  updatedAt?: string;
};
