import { TFunction } from "i18next";
import { WbAdminExtendSubscriptionDatePayload } from "../../../graphql/types/models/wb-business";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export function createBusinessFields(
  t: TFunction
): GenericFormFields<WbAdminExtendSubscriptionDatePayload> {
  return {
    business: {
      type: "text",
      label: t("company.companyName"),
      placeholder: t("company.companyName"),
      valueKey: "business_name",
      showError: true,
      inputProps: {
        type: "text",
        required: true,
      },
    },
    first_name: {
      type: "text",
      label: t("profile.firstName"),
      placeholder: t("placeholders.firstName"),
      valueKey: "first_name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    last_name: {
      type: "text",
      label: t("profile.lastName"),
      placeholder: t("placeholders.lastName"),
      valueKey: "last_name",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    email: {
      type: "text",
      label: t("profile.email"),
      placeholder: t("placeholders.email"),
      valueKey: "email",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    phone_number: {
      type: "text",
      label: t("contacts.phone"),
      placeholder: t("contacts.phone"),
      valueKey: "phone_number",
      inputProps: {
        type: "text",
      },
    },
    subscriptionEndDate: {
      valueKey: "subscriptionEndDate",
      type: "date-picker",
      label: t("businesses.subscriptionEndDate"),
      placeholder: t("businesses.subscriptionEndDate"),
      inputProps: {
        type: "text",
        required: true,
      },
    },
  };
}
