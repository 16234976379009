import { chain } from "lodash";
import moment from "moment";
import React, { FC } from "react";

import { Draggable } from "react-beautiful-dnd";
import Badge from "react-bootstrap/Badge";
import Avatar from "../../../components/avatar";
import AvatarGroup from "../../../components/avatar-group";
import { LeadSale } from "../../../models/leads";
import { getFullName } from "../../../utils/text";
import "./style.scss";

type LeadCardProps = {
  lead: LeadSale;
  index: number;
  onPress: (lead: LeadSale) => void;
};

const LeadCard: FC<LeadCardProps> = ({ lead, index, onPress }) => {
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    backgroundColor: isDragging ? "#3fbb6442" : "white",
    ...draggableStyle,
  });

  const renderAvatars = () => {
    return chain(lead.assignedTo)
      .take(4)
      .map((user) => {
        return (
          <Avatar
            key={user._id}
            outlined
            fallbackColor="light"
            userName={getFullName(user)}
            rounded
            width="35px"
            height="35px"
          />
        );
      })
      .value();
  };

  return (
    <Draggable key={lead._id} draggableId={lead._id} index={index}>
      {(provided, snapshot) => {
        return (
          //@ts-ignore
          <div
            className="lead-content mb-2"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            onClick={() => onPress(lead)}
          >
            <div className="lead-name">{lead.name}</div>
            <div className="lead-budget">
              {`$${lead?.budget?.toLocaleString("en-US") || 0}`}
            </div>
            <div className="lead-closeDay">
              {lead?.expectedCloseDate &&
                "Close day: " +
                  moment(lead.expectedCloseDate).format("Do MMMM YYYY")}
            </div>
            <div className="lead-tags">
              {lead.tags &&
                lead.tags.map((tag, index) => (
                  <Badge className="lead-tag" key={index} variant="light">
                    {tag.name}
                  </Badge>
                ))}
            </div>
            <div className="lead-assignedTo w-100">
              {lead.contacts && (
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div>
                    {lead.contacts.map((item, index) => (
                      <div key={index}>{getFullName(item)}</div>
                    ))}
                  </div>
                  <AvatarGroup>{renderAvatars()}</AvatarGroup>
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default LeadCard;
