import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { map } from "lodash";
import moment from "moment";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import TableCard from "../../../components/dashboard/table-card";
import {
  TableCardData,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { SalesQuoteTakeOffTemplate } from "../../../models/salesQuote";
import { useScheduleTemplateQuery } from "../../../hooks/queries/useScheduleTemplateQuery";
import { ScheduleTemplate } from "../../../models/job";

const ScheduleTemplatesOverview: React.FC = () => {
  const { t } = useTranslation();

  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = React.useState<ScheduleTemplate | null>(null);

  const {
    scheduleTemplates,
    deleteScheduleTemplate,
  } = useScheduleTemplateQuery({});

  const deleteConfirmRef = useRef<ConfirmDialogRef>();

  const handleDelete = React.useCallback(
    (scheduleTemplateId: string) => () => {
      if (!scheduleTemplateId) {
        return;
      }
      deleteScheduleTemplate({
        variables: {
          scheduleTemplateId,
        },
      });

      deleteConfirmRef.current?.show(false);
      setSelectedTemplate(null);
    },
    [deleteScheduleTemplate]
  );

  const openRemoveDialog = React.useCallback(
    (row?: ScheduleTemplate) => {
      if (row) {
        setSelectedTemplate(row);
        deleteConfirmRef.current?.show(true, handleDelete(row._id));
      }
    },
    [handleDelete]
  );

  const templatesData: TableCardData<ScheduleTemplate> = React.useMemo(() => {
    return {
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
        },
        {
          valueKey: "updatedAt",
          title: t("common.lastUpdated"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMMM YYYY"),
        },
      ],
      rows: map(scheduleTemplates, (template: ScheduleTemplate) => ({
        cells: template,
      })),
    };
  }, [t, scheduleTemplates]);

  const tableRowActions: TableRowActionData<
    SalesQuoteTakeOffTemplate
  >[] = React.useMemo(
    () => [
      {
        icon: "more_horiz",
        dropdownId: "document-list",
        options: [
          {
            icon: "delete",
            outlined: true,
            id: "remove",
            label: t("common.delete"),
            onClick: openRemoveDialog,
          },
        ],
      },
    ],
    [openRemoveDialog, t]
  );

  const handleOnClose = React.useCallback(() => {
    deleteConfirmRef.current?.show(false);
    setSelectedTemplate(null);
  }, []);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet
        title={t("navigation.settings.templatesSection.scheduleTemplates")}
      />
      <SetNavigationRoute
        routeId={
          NAVIGATION_ROUTES.SETTINGS.TEMPLATES_SECTION.SCHEDULE_TEMPLATES
        }
      />
      <ConfirmDialog
        ref={deleteConfirmRef}
        title={t("schedule.deleteTemplate")}
        confirm={t("common.delete")}
        onClose={handleOnClose}
      >
        <span className="field-text">
          {t("schedule.deleteTemplateMessage", {
            name: selectedTemplate?.name,
          })}
        </span>
      </ConfirmDialog>

      <TableCard
        fullHeight
        overflowXHidden
        data={templatesData}
        rowActions={tableRowActions}
        alignEnd
      />
    </Container>
  );
};

export default ScheduleTemplatesOverview;
