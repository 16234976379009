import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { CREATE_UPDATE_MEMBER } from "../../../graphql/members/mutations";
import { AddMemberInput } from "../../../graphql/input/members";
import { CreateUpdateMemberResponse } from "../../../graphql/models/members";
import { AddMemberPayload, TeamMember } from "../../../models/team-member";
import { handleMemberAdd } from "../../../graphql/members/utils";

import { notify } from "../../../components/notification";
import AddMemberModal from "../../../components/team/add-member";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";

type CreateTeammateModalProps = {
  closeOnSubmit?: boolean;
  onCreatedRecord?: (member: TeamMember) => void;
};

export type CreateTeammatModalRef = {
  show: (show: boolean) => void;
};

const CreateTeammateModal: React.FC<CreateTeammateModalProps> = (
  props,
  ref
) => {
  const { onCreatedRecord } = props;

  const { t } = useTranslation();

  const addMemberRef = React.useRef<ModalDisplayRef>();

  React.useImperativeHandle(
    ref,
    () => ({
      show: (show: boolean) => {
        addMemberRef.current?.show(show);
      },
    }),
    [addMemberRef]
  );

  const [createdMember] = useMutation<
    CreateUpdateMemberResponse,
    AddMemberInput
  >(CREATE_UPDATE_MEMBER, {
    onCompleted: ({ addedMember }) => {
      if (addedMember && onCreatedRecord) onCreatedRecord(addedMember);
      addMemberRef.current?.show(false);
    },
    update: handleMemberAdd,
  });

  const handleSubmit = React.useCallback(
    async (member: AddMemberPayload) => {
      try {
        if (!member) return;

        await createdMember({
          variables: { member },
        });

        notify({
          title: t("team.addMember"),
          content: t("team.success.addMember"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("team.addMember"),
          content: t("team.error.addMember"),
        });
      }
    },
    [createdMember, t]
  );

  return <AddMemberModal ref={addMemberRef} onSubmit={handleSubmit} />;
};

export default forwardRef(CreateTeammateModal);
