import { gql } from "@apollo/client";
import { AFFILIATE_FRAGMENT } from "./fragments";

export const WB_LIST_AFFILIATES = gql`
  query($filter: AffiliateFilterInput) {
    affiliates: wbAdminListAffiliates(filter: $filter) {
      ...AffiliateFragment
      businesses {
        _id
      }
    }
  }
  ${AFFILIATE_FRAGMENT}
`;

export const WB_GET_AFFILIATE = gql`
  query($affiliateId: ID!) {
    affiliate: wbAdminGetAffiliate(affiliateId: $affiliateId) {
      ...AffiliateFragment
      businesses {
        _id
        name
        stripeSubscriptionId
        subscriptionPlan {
          _id
          name
          price
        }
        subscriptionEndDate
        status
        createdAt
      }
    }
  }
  ${AFFILIATE_FRAGMENT}
`;
