import { BadgeProps } from "react-bootstrap/Badge";
import {
  VariationListItem,
  VariationStatuses,
} from "../../../models/variations";

export const getVariationBadgeVariant = (
  variation: VariationListItem
): BadgeProps["variant"] => {
  if (variation.progressClaimed) {
    return "success";
  } else if (variation.status === VariationStatuses.AWAITING_CLIENT_APPROVAL) {
    return "warning";
  } else if (variation.status === VariationStatuses.CANCELLED) {
    return "danger";
  } else if (variation.status === VariationStatuses.APPROVED_BY_CLIENT) {
    return "primary";
  } else if (variation.status === VariationStatuses.APPROVED_BY_BUILDER) {
    return "info";
  }
  return "secondary";
};
