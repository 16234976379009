import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import SignUpActivate from "../../components/authentication/sign-up-activate";
import AuthenticationLayout from "../layouts/authentication";
import { RESEND_ACTIVATION } from "../../graphql/queries/auth/mutations";
import { notify } from "../../components/notification";
import { ResendAcivationEmailResponse } from "../../graphql/types/models/auth";

type Params = {
  id: string;
};

const SignUpActivateContainer: React.FC = (props) => {
  const match = useRouteMatch<Params>();
  const { t } = useTranslation();

  const [resendActivationEmail] = useMutation<ResendAcivationEmailResponse>(
    RESEND_ACTIVATION,
    {
      onCompleted: (data) => {
        notify({
          title: t("authentication.success.resendActivationTitle"),
          content: t("authentication.success.resendActivation"),
        });
      },
      onError: (error) => {
        notify({
          title: t("authentication.errors.resendActivationTitle"),
          content: t("authentication.errors.resendActivation"),
          error: true,
        });
      },
    }
  );

  const handleResend = React.useCallback(
    (e: any) => {
      console.log("resend");
      e.preventDefault();
      resendActivationEmail({
        variables: {
          userId: match.params.id,
        },
      });
    },
    [resendActivationEmail, match]
  );

  return (
    <AuthenticationLayout>
      <Helmet title={t("authentication.activateYourAccount")} />
      <SignUpActivate onResendClick={handleResend} />
    </AuthenticationLayout>
  );
};

export default SignUpActivateContainer;
