import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import { AddMemberPayload } from "../../../models/team-member";
import { getMemberRoleOptions } from "../../../utils/options";

const createAddMemberFields = (
  t: TFunction,
  descriptionBlock: JSX.Element,
  isRoleDisabled: boolean
): GenericFormFields<AddMemberPayload> => {
  return {
    names: [
      {
        valueKey: "first_name",
        type: "text",
        label: t("team.firstName"),
        placeholder: t("placeholders.firstName"),
      },
      {
        valueKey: "last_name",
        type: "text",
        label: t("team.lastName"),
        placeholder: t("placeholders.lastName"),
      },
    ],
    options: [
      {
        valueKey: "email",
        type: "text",
        label: t("team.email"),
        placeholder: t("placeholders.email"),
      },
      {
        valueKey: "role",
        type: "default-select",
        label: t("team.role"),
        placeholder: t("placeholders.role"),
        selectProps: {
          options: getMemberRoleOptions(t),
          readOnly: isRoleDisabled,
        },
      },
    ],
    rate: {
      maxWidth: "49%",
      type: "text",
      label: t("team.hourlyRate"),
      placeholder: t("placeholders.costValue"),
      valueKey: "hourly_rate",
      inputProps: {
        min: 0,
        type: "number",
      },
    },
    description: {
      type: "description-block",
      descriptionBlockProps: {
        element: descriptionBlock,
      },
    },
  };
};

export default createAddMemberFields;
