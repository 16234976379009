import { gql } from "@apollo/client";

export const WB_USER_LIST_FRAGMENT = gql`
  fragment WbUserListItemRequest on User {
    _id
    first_name
    last_name
    fullName
    email
    role
    country
    createdAt
    updatedAt
    business {
      _id
      name
    }
  }
`;
