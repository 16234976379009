import React from "react";
import { Helmet } from "react-helmet";
import { map } from "lodash";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";

import {
  ListPlansResponse,
  SubscribeToPlanPayload,
  SubscribeToPlanReponse,
  SubscriptionPlan,
} from "../../../../graphql/types/models/subscription";
import { LIST_PLANS } from "../../../../graphql/queries/subscription/queries";
import { SUBSCRIPE_TO_PLAN } from "../../../../graphql/queries/subscription/mutations";
import SubscriptionPaymentModal, {
  SubscriptionPaymentModalRef,
} from "../../../../components/subscription/subscription-payment-modal";
import "./styles.scss";

type BillingProps = {};

const BillingPlan: React.FC<BillingProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const paymentRef = React.useRef<SubscriptionPaymentModalRef>(null);

  const { data: plansData, refetch: refetchPlans } = useQuery<
    ListPlansResponse
  >(LIST_PLANS, {
    fetchPolicy: "cache-and-network",
  });

  const plans = React.useMemo(() => plansData?.listPlans, [plansData]);

  const handleSubscribePress = React.useCallback((plan: SubscriptionPlan) => {
    paymentRef.current?.show(true, plan);
  }, []);

  const handleSubscriptionSuccess = React.useCallback(() => {
    history.push("/settings/billing");
  }, []);

  const renderPlan = React.useCallback((plan) => {
    return (
      <Col md={3} xs={12} className="pb-sm-5 pb-lg-0">
        <Card className="plan-card">
          <Card.Header>
            <h3 className="text-center">{plan.name}</h3>
          </Card.Header>
          <Card.Body className="text-center">
            <h4>
              ${plan.price}
              <small className="text-muted">/ mo</small>
            </h4>

            <ul className="list-unstyled">
              <li>
                {plan.limit || t("billing.unlimited")} {t("billing.projects")}
              </li>
              <li>{t("billing.accessAllFeatures")}</li>
              <li>{t("billing.unlimitedUsers")}</li>
              <li>{t("billing.noLockContract")}</li>
            </ul>
          </Card.Body>
          <Card.Footer>
            <Button
              variant={plan.subscribed ? "secondary" : "primary"}
              className={classnames("button large", {
                success: !plan.subscribed,
              })}
              onClick={() => handleSubscribePress(plan)}
              disabled={!plan.subscribable}
            >
              {plan.subscribed
                ? t("billing.currentPlan")
                : t("billing.selectPlan")}
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    );
  }, []);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.billing")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SETTINGS.BILLING} />
      <Row className="company-details">{map(plans, renderPlan)}</Row>
      <SubscriptionPaymentModal
        onSuccess={handleSubscriptionSuccess}
        ref={paymentRef}
      />
    </Container>
  );
};

export default BillingPlan;
