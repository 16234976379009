import { useMutation } from "@apollo/client";
import { notify } from "../../components/notification";
import { useTranslation } from "react-i18next";
import {
  QuoteRequestAcceptPayload,
  QuoteRequestCommentPayload,
  QuoteRequestCommentResponse,
  QuoteRequestResponse,
  ShareQuoteRequestWithClient,
} from "../../graphql/types/models/quote-request";
import {
  ACCEPT_QUOTE_REQUEST,
  COMMENT_QUOTE_REQUEST,
  DECLINE_QUOTE_REQUEST,
  SEND_QUOTE_REQUEST,
  SHARE_QUOTE_REQUEST_WITH_CLIENT,
} from "../../graphql/queries/quote-request/mutations";

type QuoteRequestOperationsProps = {
  onCompletedShareQuoteRequest?: () => void;
  onCompletedSendQuoteRequest?: () => void;
  onCompletedDeclineQuoteRequest?: () => void;
};

export function useQuoteRequestOperationsMutation(
  props: QuoteRequestOperationsProps
) {
  const {
    onCompletedShareQuoteRequest,
    onCompletedSendQuoteRequest,
    onCompletedDeclineQuoteRequest,
  } = props;
  const { t } = useTranslation();

  const [shareQuoteRequest] = useMutation<ShareQuoteRequestWithClient>(
    SHARE_QUOTE_REQUEST_WITH_CLIENT,
    {
      onCompleted: () => {
        notify({
          title: t("quoteRequest.shareQuoteRequest"),
          content: t("quoteRequest.success.shareQuoteRequest"),
        });
        onCompletedShareQuoteRequest?.();
      },
      onError: (e) => {
        notify({
          error: true,
          title: t("quoteRequest.shareQuoteRequest"),
          content: t("quoteRequest.error.shareQuoteRequest"),
        });
      },
    }
  );

  const [sendQuoteRequest] = useMutation<QuoteRequestResponse>(
    SEND_QUOTE_REQUEST,
    {
      onCompleted: () => {
        notify({
          title: t("quoteRequest.createQuoteRequest"),
          content: t("quoteRequest.success.createQuoteRequest"),
        });
        onCompletedSendQuoteRequest?.();
      },
      onError: (error) => {
        notify({
          error: true,
          title: t("quoteRequest.createQuoteRequest"),
          content: error.message,
        });
      },
    }
  );

  const [acceptQuoteRequest] = useMutation<
    QuoteRequestResponse,
    QuoteRequestAcceptPayload
  >(ACCEPT_QUOTE_REQUEST, {
    onCompleted: () => {
      notify({
        title: t("quoteRequest.acceptQuote"),
        content: t("quoteRequest.success.acceptQuote"),
      });
    },
  });

  const [declineQuoteRequest] = useMutation<
    QuoteRequestResponse,
    QuoteRequestAcceptPayload
  >(DECLINE_QUOTE_REQUEST, {
    onCompleted: () => {
      notify({
        title: t("quoteRequest.declineQuote"),
        content: t("quoteRequest.declined"),
      });
      onCompletedDeclineQuoteRequest?.();
    },
  });

  const [sendComment] = useMutation<
    QuoteRequestCommentResponse,
    QuoteRequestCommentPayload
  >(COMMENT_QUOTE_REQUEST);

  return {
    shareQuoteRequest,
    sendQuoteRequest,
    acceptQuoteRequest,
    declineQuoteRequest,
    sendComment,
  };
}
