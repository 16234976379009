import { TFunction } from "i18next";
import { filter, find, map, merge, omit, pick } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { Attachment, AttachmentFilter } from ".";
import {
  SiteDiaryFieldType,
  SiteDiaryTemplate,
  SiteDiaryTemplateField,
  SiteDiaryPayload,
  SiteDiary,
  SiteDiaryPayloadValue,
  SiteDiaryCategory,
} from "../../../graphql/types/models/site-diary";
import { JobListItem } from "../../../models/job";
import { Media } from "../../../models/media";
import { uploadFiles, UploadUrl } from "../../../utils/files";
import { getMediaInput } from "../../../utils/transform";
import {
  DeleteRowFn,
  GenericFormFields,
  RenderDynamicRowCellFn,
  RenderSecondRowFn,
} from "../../generic-form/GenericFormBody";

const createField = (field: SiteDiaryTemplateField) => {
  switch (field.type) {
    case SiteDiaryFieldType.String:
    case SiteDiaryFieldType.Number:
      return {
        width: "auto",
        maxWidth: "auto",
        type: "text",
        label: field.title,
        placeholder: field.title,
        valueKey: field._id,
        showError: true,
        controlType: "textarea",
        inputProps: {
          rows: 1,
          type: "text",
          required: true,
        },
      };
    case SiteDiaryFieldType.Radio:
      return {
        width: "auto",
        maxWidth: "auto",
        type: "default-select",
        label: field.title,
        placeholder: field.title,
        valueKey: field._id,
        showError: true,
        selectProps: {
          options: field.options?.map((option) => ({
            label: option,
            value: option,
          })),
          required: true,
        },
      };
    case SiteDiaryFieldType.Date:
      return {
        width: "auto",
        maxWidth: "auto",
        type: "date-picker",
        label: field.title,
        placeholder: field.title,
        valueKey: field._id,
        showError: true,
        datePickerProps: {},
        inputProps: {
          required: true,
        },
      };
    case SiteDiaryFieldType.File:
      // NFI
      break;
  }
  return null;
};

export const createSiteDiaryRequestFields = (
  t: TFunction,
  isEditing: boolean,
  onTemplateSelect: (templateId: string) => void,
  templates?: SiteDiaryTemplate[],
  template?: SiteDiaryTemplate | null,
  categories?: SiteDiaryCategory[],
  onCreateCategory?: (value: string) => void,
  jobs?: JobListItem[] | null,
  renderSecondRow?: RenderSecondRowFn,
  renderTableRowPhoto?: RenderDynamicRowCellFn,
  onDeleteRow?: DeleteRowFn
): GenericFormFields<SiteDiaryPayload> => {
  const templateOptions = map(templates, (template: SiteDiaryTemplate) => ({
    value: template._id,
    label: template.name,
  }));

  const categoryOptions = map(categories, (category: SiteDiaryCategory) => ({
    value: category._id,
    label: category.name,
  }));

  const templateField = {
    type: "select",
    label: t("siteDiary.template"),
    valueKey: "templateId",
    showError: true,
    autocompleteProps: {
      options: templateOptions,
      onValueChange: onTemplateSelect,
    },
    inputProps: {
      required: true,
    },
  };

  if (!template) {
    return {
      template: [templateField],
    } as GenericFormFields<SiteDiaryPayload>;
  }

  const fields = {
    group: [
      templateField,
      {
        type: "creatable-select",
        label: t("siteDiary.group"),
        placeholder: t("siteDiary.addToGroup"),
        valueKey: "category",
        showError: true,
        creatableProps: {
          options: categoryOptions,
          autoSelectCreatedOption: true,
          onCreateOption: onCreateCategory,
          isClearable: true,
        },
        inputProps: {
          required: true,
        },
      },
    ],
    info: [
      {
        type: "text",
        label: t("siteDiary.title"),
        placeholder: t("siteDiary.title"),
        valueKey: "name",
        showError: true,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "date-picker",
        label: t("siteDiary.entryDate"),
        placeholder: t("siteDiary.entryDate"),
        valueKey: "entry_date_time",
        datePickerProps: {
          showTimeSelect: true,
        },
        inputProps: {
          required: true,
        },
      },
    ],
  } as GenericFormFields<SiteDiaryPayload>;

  if (template) {
    template.fields.forEach((field: SiteDiaryTemplateField) => {
      if (field.type !== SiteDiaryFieldType.Collection) {
        fields[field._id] = createField(field) as any;
      } else {
        fields[field._id] = {
          tableTitle: field.title,
          renderSecondRow,
          deleteTableRow: onDeleteRow,
          rowGenerator: (): any =>
            merge(
              {},
              ...map(field.subFields, (subField) => ({
                [subField._id]: "",
              }))
            ),
          row: map(field.subFields, (subField) => createField(subField) as any),
          dynamicFields: [
            {
              title: "",
              maxWidth: "60px",
              render: renderTableRowPhoto,
              formatValue: () => "",
            },
          ],
        };
      }
    });
  }

  fields.note = {
    type: "text",
    controlType: "textarea",
    label: t("siteDiary.additionalNotes"),
    placeholder: t("siteDiary.additionalNotes"),
    valueKey: "note",
    inputProps: {
      rows: 3,
    },
  };

  if (isEditing) {
    (fields?.group as any[])?.splice(0, 1); //templateId;
    const group = (fields?.group as any[])?.pop();
    (fields?.info as any[]).push(group);
  }

  if (jobs && jobs.length > 0) {
    fields.jobSelect = {
      type: "select-autocomplete",
      // label: t("siteDiary.cloneJob"),
      placeholder: t("siteDiary.cloneJob"),
      valueKey: "job",
      autocompleteProps: {
        isClearable: true,
        options: map(jobs, (job) => ({
          value: job._id,
          label: `${job.name}`,
        })),
      },
    };
  }

  return fields;
};

export const filterAttachments = (
  attachments: Attachment[],
  fieldId?: string,
  rowId?: number
) => {
  const result: AttachmentFilter = {
    media: [],
    uploads: [],
  };
  attachments
    ?.filter((a) => a.fieldId === fieldId && a.rowId === rowId)
    .forEach((attachment) => {
      if (attachment.file) {
        result.uploads.push(attachment.file as File);
      } else if (attachment.media) {
        result.media.push(attachment.media);
      }
    });
  return result;
};

export const preloadDiaryAttachments = (
  attachments?: Media[],
  fieldId?: string,
  rowId?: number
) => {
  return (
    attachments?.map((attachment) => ({
      fieldId,
      rowId,
      media: omit(attachment, ["__typename", "upload_url"]) as Media,
    })) || []
  );
};

export const prepareSiteDiaryPayload = (
  template: SiteDiaryTemplate,
  categories: SiteDiaryCategory[],
  values: SiteDiaryPayload
): SiteDiaryPayload => {
  const standardValues: SiteDiaryPayload = {
    ...pick(values, [
      "_id",
      "jobId",
      "name",
      "note",
      "attachments",
      "category",
    ]),
    templateId: template._id,
    entry_date_time: moment(values.entry_date_time).toISOString(),
    values: [],
  };
  const fieldValues = values as any;
  const attachments = (standardValues.attachments as any) as Attachment[];

  // fix category
  const category = find(categories, { _id: standardValues.category });
  if (category) {
    standardValues.category = category?.name;
  }

  const templateValues: SiteDiaryPayloadValue[] = [];
  template.fields.forEach((field: SiteDiaryTemplateField) => {
    const fieldAttachments = filterAttachments(attachments, field._id);
    const fieldMedia = [
      ...fieldAttachments.media,
      ...getMediaInput(fieldAttachments.uploads),
    ];
    switch (field.type) {
      case SiteDiaryFieldType.String:
      case SiteDiaryFieldType.Number:
      case SiteDiaryFieldType.Radio:
        templateValues.push({
          fieldId: field._id,
          value: fieldValues[field._id],
          attachments: fieldMedia,
        });
        break;
      case SiteDiaryFieldType.Date:
        templateValues.push({
          fieldId: field._id,
          value: fieldValues[field._id]
            ? moment(fieldValues[field._id]).toISOString()
            : "",
          attachments: fieldMedia,
        });
        break;
      case SiteDiaryFieldType.Collection:
        templateValues.push({
          fieldId: field._id,
          subValues: map(fieldValues[field._id], (row, rowIndex: number) => {
            const subFieldAttachments = filterAttachments(
              attachments,
              field._id,
              rowIndex
            );
            const subFieldMedia = [
              ...subFieldAttachments.media,
              ...getMediaInput(subFieldAttachments.uploads),
            ];
            return {
              columns: map(row, (value: string, fieldId: string) => ({
                fieldId,
                value,
              })),
              attachments: subFieldMedia,
            };
          }),
          attachments: fieldMedia,
        });
        break;
    }
  });
  const generalAttachments = filterAttachments(attachments);
  const generalMedia = [
    ...generalAttachments.media,
    ...getMediaInput(generalAttachments.uploads),
  ];
  return omitDeep(
    {
      ...standardValues,
      name: standardValues.name || template.name,
      values: templateValues,
      attachments: generalMedia,
    },
    ["url"]
  ) as SiteDiaryPayload;
};

const prepareAttachmentUploads = (
  attachments: Attachment[],
  remoteAttachments: Media[],
  fieldId?: string,
  rowId?: number
) => {
  const newUploadUrls: UploadUrl[] = [];
  const newUploads: File[] = [];
  const newAttachments = filter(remoteAttachments, (a) => !!a.upload_url);
  if (newAttachments.length) {
    const fieldAttachments = filterAttachments(attachments, fieldId, rowId);
    if (fieldAttachments.uploads.length) {
      newUploadUrls.push(...newAttachments);
      newUploads.push(...fieldAttachments.uploads);
    }
  }
  return {
    urls: newUploadUrls,
    uploads: newUploads,
  };
};

export const uploadSiteDiaryAttachments = async (
  attachments: Attachment[],
  result: SiteDiary
) => {
  const { urls, uploads } = prepareAttachmentUploads(
    attachments,
    result.attachments
  );
  const newUploadUrls: UploadUrl[] = urls;
  const newUploads: File[] = uploads;
  result.values?.forEach((value) => {
    if (value.attachments) {
      const { urls, uploads } = prepareAttachmentUploads(
        attachments,
        value.attachments,
        value.field._id
      );
      newUploadUrls.push(...urls);
      newUploads.push(...uploads);
    }
    value.subValues?.forEach((subValue, rowId) => {
      if (subValue.attachments) {
        const { urls, uploads } = prepareAttachmentUploads(
          attachments,
          subValue.attachments,
          value.field._id,
          rowId
        );
        newUploadUrls.push(...urls);
        newUploads.push(...uploads);
      }
    });
  });

  if (newUploadUrls.length && newUploads.length) {
    await uploadFiles(newUploadUrls, newUploads);
  }
};
