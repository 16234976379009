import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { get, map } from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../../components/confirm-dialog";

import { notify } from "../../../../components/notification";
import ProviderConfigurationModal, {
  ProviderConfigurationModalRef,
} from "../../../../components/integrations/provider-configuration-modal";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/navigation-items";
import { Button, Col, Row } from "react-bootstrap";
import DashboardCard from "../../../../components/dashboard/card";
import DashboardCardBody from "../../../../components/dashboard/card/DashboardCardBody";
import DashboardCardHeader from "../../../../components/dashboard/card/DashboardCardHeader";
import IntegrationIcon from "../../../../components/icons/IntegrationIcon";
import {
  AccountingIntegrationError,
  AccountingIntegrationErrorsResponse,
  AccountingIntegrationResponse,
  AccountingIntegrationType,
  ClearAccountingIntegrationErrorsResponse,
  ConnectAccountingCallbackPayload,
  ConnectAccountingCallbackResponse,
  ConnectAccountingRequest,
  ConnectAccountingResponse,
  DisconnectAccountingResponse,
} from "../../../../graphql/types/models/integrations";
import {
  CLEAR_ACCOUNTING_INTEGRATION_ERRORS,
  CONNECT_ACCOUNTING,
  CONNECT_ACCOUNTING_CALLBACK,
  DISCONNECT_ACCOUNTING,
} from "../../../../graphql/queries/integrations/mutations";
import {
  GET_ACCOUNTING_INTEGRATION,
  GET_ACCOUNTING_INTEGRATION_ERRORS,
} from "../../../../graphql/queries/integrations/queries";
import "./styles.scss";
import { findEnum } from "../../../../utils/options";
import {
  TableCardAction,
  TableCardData,
  TableRowActionData,
} from "../../../../components/dashboard/table-card/utils";
import TableCard from "../../../../components/dashboard/table-card";
import { handleClearErrors } from "../../../../graphql/queries/integrations/utils";

type Params = {
  provider?: string;
};
const IntegrationsOverview: React.FC<RouteComponentProps<Params>> = (props) => {
  const { location, match } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const [connectProvider, setConnectProvider] = React.useState<
    AccountingIntegrationType
  >();

  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const disconnectRef = React.useRef<ConfirmDialogRef>(null);
  const clearErrorsRef = React.useRef<ConfirmDialogRef>(null);
  const providerConfigRef = React.useRef<ProviderConfigurationModalRef>(null);

  const { data, refetch } = useQuery<AccountingIntegrationResponse>(
    GET_ACCOUNTING_INTEGRATION
  );

  const { data: errorData } = useQuery<AccountingIntegrationErrorsResponse>(
    GET_ACCOUNTING_INTEGRATION_ERRORS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [clearErrors] = useMutation<ClearAccountingIntegrationErrorsResponse>(
    CLEAR_ACCOUNTING_INTEGRATION_ERRORS,
    {
      update: handleClearErrors,
    }
  );

  const handleConfirmProvider = React.useCallback(
    (provider: AccountingIntegrationType) => {
      setConnectProvider(provider);
      confirmRef.current?.show(true);
    },
    [confirmRef, setConnectProvider]
  );

  const [connectAccounting] = useMutation<
    ConnectAccountingResponse,
    ConnectAccountingRequest
  >(CONNECT_ACCOUNTING, {
    onCompleted: ({ connectAccounting }) => {
      window.location.href = connectAccounting.url;
    },
    onError: () => {},
  });

  const [disconnectAccounting] = useMutation<DisconnectAccountingResponse>(
    DISCONNECT_ACCOUNTING,
    {
      onCompleted: () => {
        notify({
          title: t("integrations.disconnectProvider", {
            provider: currentProvider,
          }),
          content: t("integrations.success.disconnectProvider", {
            provider: currentProvider,
          }),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: t("integrations.disconnectProvider", {
            provider: currentProvider,
          }),
          content: t("integrations.error.disconnectProvider", {
            provider: currentProvider,
          }),
        });
      },
    }
  );

  const [connectAccountingCallback, { loading: loadingCallback }] = useMutation<
    ConnectAccountingCallbackResponse,
    ConnectAccountingCallbackPayload
  >(CONNECT_ACCOUNTING_CALLBACK, {
    onCompleted: () => {
      refetch();
      notify({
        title: t("integrations.connectProvider", { provider: connectProvider }),
        content: t("integrations.success.connectProvider", {
          provider: connectProvider,
        }),
      });
      history.replace("/settings/integrations");
      providerConfigRef.current?.show(true);
    },
    onError: () => {
      notify({
        error: true,
        title: t("integrations.connectProvider", { provider: connectProvider }),
        content: t("integrations.error.connectProvider", {
          provider: connectProvider,
        }),
      });
    },
  });

  const currentProvider = React.useMemo(
    () => data?.getCurrentAccountingIntegration?.integration_type,
    [data]
  );

  const handleConnectProvider = React.useCallback(() => {
    if (connectProvider) {
      connectAccounting({
        variables: {
          provider: connectProvider,
        },
      });
    }
  }, [connectAccounting, connectProvider]);

  const handleDisconnectProvider = React.useCallback(() => {
    if (currentProvider) {
      disconnectAccounting();
    }
  }, [currentProvider, disconnectAccounting]);

  const handleConfigure = React.useCallback(() => {
    if (!currentProvider) return;
    providerConfigRef.current?.show(true);
  }, [providerConfigRef, currentProvider]);

  const code = React.useMemo(
    () => new URLSearchParams(location.search).get("code"),
    [location]
  );

  React.useEffect(() => {
    const provider = match.params?.provider?.toUpperCase();
    if (provider && code) {
      const connectingProvider = findEnum(AccountingIntegrationType, provider);
      setConnectProvider(connectingProvider);
      const data =
        connectingProvider === AccountingIntegrationType.XERO
          ? location.search
          : code;

      connectAccountingCallback({
        variables: {
          provider: connectingProvider,
          data: data,
        },
      });
    }
  }, [code, setConnectProvider]);

  const renderIntegrations = React.useCallback(() => {
    return map(AccountingIntegrationType, (provider) => {
      const isCurrentProvider = provider === currentProvider;
      const providerName = provider.toLowerCase();
      const tenantName = get(
        data,
        `getCurrentAccountingIntegration.${providerName}.tenant.tenantName`
      );
      return (
        <Col lg={6}>
          <DashboardCard className="integrations">
            <DashboardCardHeader className="text-capitalize justify-content-between">
              <div className="d-flex align-items-center">
                {t(`integrations.${providerName}`)}
              </div>
            </DashboardCardHeader>
            <DashboardCardBody>
              <div className="integration-summary">
                <div className="integration-brand">
                  <IntegrationIcon name={provider} />
                </div>
                <div className="integration-status">
                  <div className="field-text">
                    {isCurrentProvider
                      ? tenantName
                      : t("integrations.notConnected")}
                  </div>
                </div>
              </div>

              {!isCurrentProvider && (
                <Button
                  className="button success"
                  block
                  onClick={() => handleConfirmProvider(provider)}
                  disabled={loadingCallback}
                >
                  {t("integrations.connect")}
                </Button>
              )}
              {isCurrentProvider && (
                <div className="d-flex">
                  <Button
                    className="button info"
                    block
                    onClick={handleConfigure}
                    disabled={loadingCallback}
                  >
                    {t("integrations.configure")}
                  </Button>

                  <Button
                    className="button info"
                    block
                    onClick={() => disconnectRef.current?.show(true)}
                    disabled={loadingCallback}
                  >
                    {t("integrations.disconnect")}
                  </Button>
                </div>
              )}
            </DashboardCardBody>
          </DashboardCard>
        </Col>
      );
    });
  }, [
    data,
    currentProvider,
    loadingCallback,
    handleConfirmProvider,
    disconnectRef,
  ]);

  const errorsTableData = React.useMemo<
    TableCardData<AccountingIntegrationError>
  >(
    () => ({
      columns: [
        {
          valueKey: "createdAt",
          title: t("common.date"),
          formatValue: (row: any, col: any, value: string) =>
            moment(value).format("DD/MM/YYYY HH:mm:ss"),
        },
        {
          valueKey: "type",
          title: t("integrations.errorType"),
        },
        {
          valueKey: "message",
          title: t("integrations.errorMessage"),
        },
        {
          valueKey: "code",
          title: t("integrations.errorCode"),
        },
      ],
      rows: map(errorData?.getAccountingIntegrationErrors, (item) => ({
        cells: item,
      })),
    }),
    [errorData]
  );

  const handleErrorView = React.useCallback(
    (error: AccountingIntegrationError) => {
      const errorWindow = window.open(
        "",
        error.message,
        "height=700, width=1000"
      );
      if (errorWindow) {
        errorWindow.document.body.innerHTML = `<pre>${error.details}</pre>`;
      }
    },
    []
  );

  const errorsTableRowActions = React.useMemo<
    TableRowActionData<AccountingIntegrationError>[]
  >(
    () => [
      {
        icon: "pageview",
        onClick: handleErrorView,
      },
    ],
    [t]
  );

  const errorsHeaderAction = React.useMemo<TableCardAction>(
    () => ({
      title: t("integrations.clearErrors"),
      onClick: () => clearErrorsRef.current?.show(true, clearErrors),
      icon: "delete_forever",
      className: "button max-super-large info bg-transparent text-light",
    }),
    [clearErrorsRef, clearErrors]
  );

  const renderErrors = React.useCallback(() => {
    if (
      !errorData?.getAccountingIntegrationErrors ||
      errorData.getAccountingIntegrationErrors.length === 0
    )
      return null;
    return (
      <Row>
        <Col lg={12}>
          <TableCard
            className="mt-5"
            title={t("integrations.errorLogs")}
            data={errorsTableData}
            rowActions={errorsTableRowActions}
            headerAction={errorsHeaderAction}
          />
        </Col>
      </Row>
    );
  }, [errorsTableData, errorsTableRowActions, errorsHeaderAction]);

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.settings.integrations")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.SETTINGS.INTEGRATIONS} />
      <ConfirmDialog
        ref={confirmRef}
        title={t("integrations.connectProvider", { provider: connectProvider })}
        onSubmit={handleConnectProvider}
        confirm={t("common.continue")}
      >
        <span className="field-text">
          {t("integrations.connectProviderMessage", {
            provider: connectProvider,
          })}
        </span>
      </ConfirmDialog>
      <ConfirmDialog
        ref={disconnectRef}
        title={t("integrations.disconnectProvider", {
          provider: currentProvider,
        })}
        onSubmit={handleDisconnectProvider}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("integrations.disconnectMessage", { provider: currentProvider })}
        </span>
      </ConfirmDialog>
      <ConfirmDialog
        ref={clearErrorsRef}
        title={t("integrations.clearErrors")}
        confirm={t("common.yes")}
      >
        <span className="field-text">
          {t("integrations.clearErrorsMessage")}
        </span>
      </ConfirmDialog>
      <ProviderConfigurationModal ref={providerConfigRef} />
      <Row>{renderIntegrations()}</Row>
      {renderErrors()}
    </Container>
  );
};

export default withRouter(IntegrationsOverview);
