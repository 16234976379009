import React, { useState } from "react";
import { isArray, reduce } from "lodash";
import { TFunction } from "react-i18next";
import { JobCostingTableItem } from "..";
import { JobCostingCategory, JobCostingItem } from "../../../../models/job";
import { getRosterItems, toggleCostingItem } from "../utils";
import ListRosterItemsModal, {
  ListRosterItemsModalRef,
} from "../list-roster-items-modal";
import {
  TableCardAction,
  TableRowActionData,
} from "../../../../components/dashboard/table-card/utils";
import CreateRosterModal, {
  CreateRosterModalRef,
} from "../../../../components/roster/create-roster-modal";

export function useRoster(
  t: TFunction,
  resetAllSelections: () => void,
  jobId?: string
) {
  const [rosterItems, setRosterItems] = useState<JobCostingTableItem[]>([]);
  const listRosterItemsRef = React.useRef<ListRosterItemsModalRef>(null);
  const createRosterRef = React.useRef<CreateRosterModalRef>(null);

  const [selectedCostingItems, setSelectedCostingItems] = React.useState<
    JobCostingItem[]
  >([]);

  const resetRosterItems = React.useCallback(() => {
    setRosterItems([]);
  }, []);

  const getCostingItemById = React.useCallback(
    (
      itemId,
      selectedCategory: JobCostingCategory | null
    ): JobCostingItem | undefined => {
      if (!itemId) return;

      const item = selectedCategory?.items.find((itm) => {
        if (itm._id?.toString() === itemId.toString()) return itm;
      });
      return item;
    },
    []
  );

  const toggleRosterItem = React.useCallback(
    (row: JobCostingTableItem | JobCostingTableItem[]) => {
      if (isArray(row)) {
        const items = reduce(
          row,
          (result, item) => toggleCostingItem(result, item, true),
          rosterItems
        );
        setRosterItems(items);
      } else {
        setRosterItems(toggleCostingItem(rosterItems, row));
      }
    },
    [getCostingItemById, rosterItems]
  );

  const rosterTableAction = React.useMemo<TableCardAction | null>(
    () => ({
      title: t(
        rosterItems.length
          ? "costing.rosterItemsNumber"
          : "costing.rosterItems",
        { number: rosterItems.length }
      ),
      onClick: () => {
        if (rosterItems) {
          setSelectedCostingItems(rosterItems);
          createRosterRef.current?.show(true);
        }
      },
      svgIcon: "Team",
      className: "button info-reverse",
      disabled: !rosterItems.length,
    }),
    [createRosterRef, rosterItems]
  );

  const rosterRowAction = React.useMemo<
    TableRowActionData<JobCostingTableItem>
  >(
    () => ({
      svgIcon: "Team",
      counter: (row: JobCostingTableItem) => getRosterItems(row).length,
      title: (row: JobCostingTableItem) =>
        t("costing.rosterCreatedWithCount", {
          count: getRosterItems(row).length,
        }),
      dropdownId: "roster-item",
      onClick: (row: JobCostingTableItem) => {
        const data = getRosterItems(row);
        if (jobId) {
          listRosterItemsRef.current?.setData(data);
          listRosterItemsRef.current?.show(true);
        }
      },
      shouldRender: (row: JobCostingTableItem) =>
        getRosterItems(row).length > 0,
    }),
    [jobId]
  );

  const handleRosterCreated = React.useCallback(() => {
    createRosterRef.current?.show(false);
    resetAllSelections();
  }, [resetAllSelections]);

  const renderRosterModal = React.useCallback(() => {
    return (
      <>
        <CreateRosterModal
          ref={createRosterRef}
          jobId={jobId}
          roster={null}
          costingItems={selectedCostingItems}
          onSubmit={handleRosterCreated}
        />
        <ListRosterItemsModal ref={listRosterItemsRef} />
      </>
    );
  }, [selectedCostingItems, handleRosterCreated]);

  return {
    toggleRosterItem,
    resetRosterItems,
    rosterRowAction,
    rosterTableAction,
    rosterItems,
    renderRosterModal,
  };
}
