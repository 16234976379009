import { User } from "../graphql/types/models/user";

export enum TimesheetStatus {
  PENDING = "Pending",
  PROCESSED = "Processed",
}

export enum TimesheetCategory {
  UNPROCESSED = "Unprocessed",
  PROCESSED = "Processed",
}

export type TimesheetJob = {
  _id: string;
  name: string;
  address: string;
};

export type Timesheet = {
  _id: string;
  task: string;
  startTime: string;
  endTime: string;
  note: string;
  job: TimesheetJob;
  is_processed: boolean;
  rate: number;
  breakDuration: number;
  user: User;
};

export type TimesheetTableItem = {
  _id: string;
  task: string;
  note: string;
  startTime: string;
  endTime: string;
  timeRange: string;
  job: TimesheetJob;
  is_processed: boolean;
  rate: number;
  jobName: string;
  duration: number | string;
  breakDuration: number | string;
  status: string;
  costingCategoryName?: string;
  costingItemId?: string;
  user: User;
};

export type CreateUpdateTimesheetPayload = {
  _id?: string;
  userId?: string;
  job: string;
  task: string;
  note: string;
  startDate: string;
  endDate: string;
  breakDuration: string;
  scheduleItemId?: string;
  costingCategoryName?: string;
  costingItemId?: string;
};

export type CreateUpdateTimesheetInput = {
  _id?: string;
  userId?: string;
  task: string;
  note: string;
  startTime: string;
  endTime: string;
  breakDuration: number;
  jobId: string;
  scheduleItemId?: string;
  costingCategoryName?: string;
  costingItemId?: string;
};

export type TimesheetFilterInput = {
  jobId?: string;
  userId?: string;
  isProcessed?: boolean;
  dateRange?: {
    from?: Date;
    to?: Date;
  };
  timezone?: string;
};
