import React from "react";
import { useQuery } from "@apollo/client";
import { LIST_QUOTES } from "../../graphql/queries/quote/queries";
import { QuoteListResponse } from "../../graphql/types/models/quote";
import { QuoteArchiveStatus } from "../../models/salesQuote";

export function useSalesQuotesQuery(archiveStatus?: QuoteArchiveStatus) {
  const {
    data: quotesList,
    loading,
    refetch: refetchSalesQuoteList,
  } = useQuery<QuoteListResponse>(LIST_QUOTES, {
    variables: {
      filter: {
        is_archived: archiveStatus === QuoteArchiveStatus.ARCHIVED,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const salesQuotes = React.useMemo(() => quotesList?.listSalesQuotes, [
    quotesList,
  ]);

  return { salesQuotes, loading, refetchSalesQuoteList };
}
