import React from "react";
import classNames from "classnames";
import Icon from "../../../icons/Icon";
import { CategorySelectorPriceList } from "./index";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type CostingCategoryItemProps = {
  isSelected: boolean;
  category: CategorySelectorPriceList;
  onDelete?: (id: string) => void;
  onClick?: (category: CategorySelectorPriceList) => void;
};

const CostingCategoryItem: React.FC<CostingCategoryItemProps> = (props) => {
  const { isSelected, category, onDelete, onClick } = props;
  const { t } = useTranslation();

  const getItemClass = (): string => {
    return classNames("price-list-category-item", {
      "price-list-category-selected": isSelected,
    });
  };

  const handleDelete = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      onDelete && onDelete(category._id);
    },
    [onDelete, category]
  );

  const handleClick = React.useCallback(() => {
    return onClick && onClick(category);
  }, [onClick, category]);

  return (
    <div className={getItemClass()} onClick={handleClick}>
      <div className="item-name">{category.name}</div>
      <div className="right-side">
        {category.items?.length && (
          <Badge variant="secondary" className="item-count">
            {t("priceList.itemsNumber", { count: category.items?.length })}
          </Badge>
        )}
        <div className="remove-action" onClick={handleDelete}>
          <Icon name="clear" />
        </div>
      </div>
    </div>
  );
};

export default CostingCategoryItem;
