import gql from "graphql-tag";

export const JOB_MESSAGE_FRAGMENT = gql`
  fragment JobChannelMessage on JobChannelMessage {
    _id
    text
    is_deleted
    linkPreview {
      title
      description
      url
      imageUrl
    }
    channel {
      _id
      job {
        _id
        jobNumber
        name
      }
    }
    media {
      _id
      name
      upload_url
      url
      type
      size
    }
    sender {
      _id
      first_name
      last_name
      email
      profileImage {
        url
      }
    }
    createdAt
  }
`;

export const JOB_CHANNEL_FRAGMENT = gql`
  fragment JobChannelConversation on JobChannel {
    _id
    channelType
    job {
      _id
      members {
        _id
        first_name
        last_name
        email
        role
        profileImage {
          url
        }
      }
    }
    messages {
      ...JobChannelMessage
    }
  }
  ${JOB_MESSAGE_FRAGMENT}
`;
