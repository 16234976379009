import { MediaInput } from "../graphql/types/inputs/media";
import { isEmpty, map } from "lodash";
import { EmailAttachmentInput } from "../graphql/types/inputs/email";
import { DEFAULT_FILE_TYPE } from "./files";

type MediaFile = File & {
  _id?: string;
};

export const getMediaInput = (files?: MediaFile[] | null): MediaInput[] => {
  if (isEmpty(files)) {
    return [];
  }

  return map(files, (file) => ({
    _id: file._id,
    type: file?.type || DEFAULT_FILE_TYPE,
    size: file.size,
    name: file.name,
  }));
};

export const getEmailMediaInput = (
  files: File[] | null
): EmailAttachmentInput[] => {
  if (isEmpty(files)) {
    return [];
  }

  return map(files, (file) => ({
    type: file.type,
    size: file.size,
    name: file.name,
  }));
};

export const isImage = (file: { type: string }) => {
  return file.type.split("/")[0] === "image";
};

export const getDownloadUrl = (url?: string) => {
  return url?.split("?")[0];
};
