import React, { useState } from "react";
import { AddPriceListPayload } from "../../../../models/price-list";
import CreateEntityModal from "../../../modals/create-entity";
import { SupplierDetails, SupplierListItem } from "../../../../models/supplier";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import createAddPriceListFields from "./utils";
import createPriceListSchema from "./AddList.schema";
import CreateSupplierModal, {
  CreateSupplierModalRef,
} from "../../../contacts/create-supplier-modal";

type AddPriceListProps = {
  show: boolean;
  suppliers: SupplierListItem[];
  onSubmit: (data: AddPriceListPayload) => void;
  onClose: () => void;
};

const AddPriceListModal: React.FC<AddPriceListProps> = (props) => {
  const { onSubmit, show, onClose, suppliers } = props;

  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<
    GenericFormFields<AddPriceListPayload>
  >({});
  const createSupplierModalRef = React.useRef<any>(null);
  const supplierRef = React.useRef<CreateSupplierModalRef>(null);

  const handleSetNewSupplier = React.useCallback(
    (supplier: SupplierDetails) => {
      createSupplierModalRef.current?.setFieldValue("supplier", supplier._id);
    },
    [createSupplierModalRef]
  );
  const openSupplierModal = React.useCallback(() => {
    supplierRef.current?.show(true);
  }, []);

  React.useEffect(() => {
    setFormFields(createAddPriceListFields(t, suppliers, openSupplierModal));
  }, [t, suppliers, openSupplierModal]);

  return (
    <>
      <CreateEntityModal
        formikRef={createSupplierModalRef}
        validationSchema={createPriceListSchema(t)}
        show={show}
        className="add-price-list-modal"
        title={t("priceList.newPriceList")}
        fields={formFields}
        data={{
          name: "",
          supplier: null,
        }}
        onClose={onClose}
        onSubmit={onSubmit}
      />
      <CreateSupplierModal ref={supplierRef} onCreate={handleSetNewSupplier} />
    </>
  );
};

export default AddPriceListModal;
