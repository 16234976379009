import React from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "@apollo/client";
import { notify } from "../components/notification";
import {
  APPROVE_SELECTION_CATEGORY_ITEM,
  CREATE_UPDATE_SELECTION_CATEGORY,
  CREATE_UPDATE_SELECTION_CATEGORY_ITEM,
  CREATE_UPDATE_SELECTION_CATEGORY_ITEM_OPTION,
  DELETE_SELECTION_CATEGORY,
  DELETE_SELECTION_CATEGORY_ITEM,
  DELETE_SELECTION_CATEGORY_ITEM_OPTION,
  SELECT_SELECTION_CATEGORY_ITEM,
  SHARE_SELECTION,
} from "../graphql/queries/selection/mutation";
import {
  CreateSelectionCategoryItemPayload,
  CreateSelectionCategoryPayload,
  CreateUpdateItemOptionResponse,
  CreateUpdateQuoteSelectionCategoryResponse,
  CreateUpdateSelectionCategoryItemResponse,
  DeleteSelectionCategoryItemResponse,
  DeleteSelectionCategoryResponse,
  DeleteSelectionOptionResponse,
  EnumSelectionEntityType,
  EnumSelectionLinkedEntityType,
  EnumSelectionStatus,
  ListClientAccessResponse,
  SelectSelectionCategoryItemResponse,
  SelectionCategory,
  SelectionCategoryItemOption,
  SelectionCategoryItemOptionInput,
  SelectionCategoryItemType,
  ShareSelectionResponse,
} from "../graphql/types/models/selections";
import CreateUpdateCategoryModal from "../components/selection/create-update-category-modal";
import {
  handleAddCategory,
  handleAddCategoryItem,
  handleAddCategoryItemOption,
  handleAddTemplateCategory,
  handleAddTemplateCategoryItem,
  handleAddTemplateCategoryItemOption,
} from "../graphql/queries/selection/utils";
import ConfirmDialog, { ConfirmDialogRef } from "../components/confirm-dialog";
import { find, omit } from "lodash";
import CreateUpdateCategoryItemModal from "../components/selection/create-update-category-item-modal";
import { UploadUrl } from "../utils/files";
import CreateItemOptionModal from "../components/selection/create-item-option-modal";
import { UOMOption } from "../utils/types/options";
import { SalesQuoteCategory } from "../models/salesQuote";
import {
  ClientAccessType,
  GrantAccessInput,
} from "../graphql/input/client-access";
import { GRANT_CLIENT_ACCESS } from "../graphql/queries/client-access/mutations";
import { GrantClientAccessResponse } from "../graphql/types/models/client-access";
import { Client } from "../models/client";
import { handleUpdateGrantSendQuote } from "../graphql/queries/quote/utils";
import { LIST_CLIENT_ACCESS } from "../graphql/queries/selection/queries";
import { ModalDisplayRef } from "./useModalDisplay";
import SendModal from "../components/modals/send-modal";
import { SendEmailForm } from "../models/email";

export type SelectionProps = {
  selectionEntityId?: string;
  selectionEntityType: EnumSelectionEntityType;
  selectedCategory: SelectionCategory | null;
  categories?: SelectionCategory[];
  salesQuoteId?: string;
  templateId?: string;
  contact?: Client;
};

export function useSelection(props: SelectionProps) {
  const {
    selectionEntityId,
    selectedCategory,
    selectionEntityType,
    templateId,
    salesQuoteId,
    categories,
    contact,
  } = props;
  const { t } = useTranslation();

  const isTemplate = selectionEntityType === EnumSelectionEntityType.TEMPLATE;

  const confirmRef = React.useRef<ConfirmDialogRef>(null);
  const confirDeleteItemRef = React.useRef<ConfirmDialogRef>(null);
  const confirDeleteOptionRef = React.useRef<ConfirmDialogRef>(null);
  const confirApproveOptionRef = React.useRef<ConfirmDialogRef>(null);
  const sendRef = React.useRef<ModalDisplayRef>();
  const checkUnallocatedItemsRef = React.useRef<ConfirmDialogRef>(null);

  const [isDownloadingFiles, setIsDownloadingFiles] = React.useState(false);
  const [categoryItemSelectedId, setCategoryItemSelectedId] = React.useState(
    ""
  );
  const [
    showCreateEditCategoryModal,
    setShowCreateEditCategoryModal,
  ] = React.useState(false);
  const [
    editCategory,
    setEditCategory,
  ] = React.useState<SelectionCategory | null>(null);
  const [categoryToDeleteId, setCategoryToDeleteId] = React.useState("");
  const [
    showCreateEditCategoryItemModal,
    setShowCreateEditCategoryItemModal,
  ] = React.useState(false);
  const [
    editCategoryItem,
    setEditCategoryItem,
  ] = React.useState<SelectionCategoryItemType | null>(null);
  const [
    editItemOption,
    setEditItemOption,
  ] = React.useState<SelectionCategoryItemOption | null>(null);
  const [
    showCreateEditItemOptionModal,
    setShowCreateEditItemOptionModal,
  ] = React.useState(false);

  const [createUpdateCategory] = useMutation<
    CreateUpdateQuoteSelectionCategoryResponse
  >(CREATE_UPDATE_SELECTION_CATEGORY);

  const [deleteSelectionCategory, { loading: categoryDeleting }] = useMutation<
    DeleteSelectionCategoryResponse
  >(DELETE_SELECTION_CATEGORY, {
    onCompleted: () => {
      notify({
        title: t("selections.deleteCategory"),
        content: t("selections.success.deleteCategory"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("selections.deleteCategory"),
        content: t("selections.error.deleteCategory"),
      });
    },
  });

  const [createUpdateCategoryItem] = useMutation<
    CreateUpdateSelectionCategoryItemResponse
  >(CREATE_UPDATE_SELECTION_CATEGORY_ITEM);

  const [deleteSelectionCategoryItem] = useMutation<
    DeleteSelectionCategoryItemResponse
  >(DELETE_SELECTION_CATEGORY_ITEM, {
    onCompleted: () => {
      notify({
        title: t("selections.deleteCategoryItem"),
        content: t("selections.success.deleteCategoryItem"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("selections.deleteCategoryItem"),
        content: t("selections.error.deleteCategoryItem"),
      });
    },
  });

  const [deleteOption] = useMutation<DeleteSelectionOptionResponse>(
    DELETE_SELECTION_CATEGORY_ITEM_OPTION,
    {
      onCompleted: () => {
        notify({
          title: t("selections.deleteOption"),
          content: t("selections.success.deleteOption"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: t("selections.deleteOption"),
          content: t("selections.error.deleteOption"),
        });
      },
    }
  );

  const [createUpdateItemOption] = useMutation<CreateUpdateItemOptionResponse>(
    CREATE_UPDATE_SELECTION_CATEGORY_ITEM_OPTION,
    {
      onCompleted: () => {
        notify({
          title: editItemOption
            ? t("selections.editItemOption")
            : t("selections.createOption"),
          content: editItemOption
            ? t("selections.success.editOption")
            : t("selections.success.addOption"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: editItemOption
            ? t("selections.editItemOption")
            : t("selections.createOption"),
          content: editItemOption
            ? t("selections.error.editOption")
            : t("selections.error.addOption"),
        });
      },
    }
  );

  const [getListClientAccess, { data: listClientAccess }] = useLazyQuery<
    ListClientAccessResponse
  >(LIST_CLIENT_ACCESS, {
    variables: {
      accessType: ClientAccessType.ESTIMATION,
      accessId: salesQuoteId,
    },
    onCompleted: (data) => {
      if (data?.listClientAccess) {
        const client = data?.listClientAccess.find(
          (item) => item.contact._id === contact?._id
        );
        if (!client) {
          handleGrantSubmit();
        }
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const checkIsInvitedContact = React.useCallback(async () => {
    getListClientAccess({
      variables: {
        accessType: ClientAccessType.ESTIMATION,
        accessId: salesQuoteId,
      },
    });
  }, [salesQuoteId]);

  const [shareSelectionCategory] = useMutation<ShareSelectionResponse>(
    SHARE_SELECTION,
    {
      onCompleted: () => {
        notify({
          title: t("selections.shareWithClient"),
          content: t("selections.success.shareSelection"),
        });
        checkIsInvitedContact();
      },
      onError: () => {
        notify({
          error: true,
          title: t("selections.shareWithClient"),
          content: t("selections.error.shareSelection"),
        });
      },
    }
  );

  const [selectSelectionCategoryItem] = useMutation<
    SelectSelectionCategoryItemResponse
  >(SELECT_SELECTION_CATEGORY_ITEM, {
    onCompleted: () => {
      notify({
        title: t("selections.selectOption"),
        content: t("selections.success.selectOption"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("selections.selectOption"),
        content: t("selections.error.selectOption"),
      });
    },
  });

  const [approveSelectionCategoryItem] = useMutation<
    SelectSelectionCategoryItemResponse
  >(APPROVE_SELECTION_CATEGORY_ITEM, {
    onCompleted: () => {
      notify({
        title: t("selections.approveOption"),
        content: t("selections.success.approveOption"),
      });
    },
    onError: () => {
      notify({
        error: true,
        title: t("selections.approveOption"),
        content: t("selections.error.approveOption"),
      });
    },
  });

  const [grantAccess] = useMutation<
    GrantClientAccessResponse,
    GrantAccessInput
  >(GRANT_CLIENT_ACCESS);

  const handleGrantSubmit = React.useCallback(async () => {
    if (!contact || !salesQuoteId) {
      return;
    }
    await grantAccess({
      variables: {
        accessType: ClientAccessType.ESTIMATION,
        accessId: salesQuoteId,
        contactId: contact._id,
      },
      update: handleUpdateGrantSendQuote(salesQuoteId),
    });
  }, [contact, grantAccess, salesQuoteId]);

  const handleDeleteCategoryItemSubmit = React.useCallback(() => {
    if (!categoryItemSelectedId) {
      return;
    }
    deleteSelectionCategoryItem({
      variables: {
        categoryId: selectedCategory?._id,
        itemId: categoryItemSelectedId,
      },
    });
  }, [
    categoryItemSelectedId,
    deleteSelectionCategoryItem,
    selectedCategory?._id,
  ]);

  const openCreateCategoryModal = React.useCallback(() => {
    setShowCreateEditCategoryModal(true);
  }, [setShowCreateEditCategoryModal]);

  const handleOnEditCategory = React.useCallback(() => {
    setEditCategory(selectedCategory);
    setShowCreateEditCategoryModal(true);
  }, [selectedCategory]);

  const closeCreateCategoryModal = React.useCallback(() => {
    setShowCreateEditCategoryModal(false);
    setEditCategory(null);
  }, [setShowCreateEditCategoryModal, setEditCategory]);

  const handleDeleteSubmit = React.useCallback(() => {
    if (!categoryToDeleteId) {
      return;
    }
    deleteSelectionCategory({
      variables: { categoryId: categoryToDeleteId },
    });
  }, [categoryToDeleteId, deleteSelectionCategory]);

  const openDeleteCategoryDialog = React.useCallback((categoryId: string) => {
    setCategoryToDeleteId(categoryId);
    confirmRef.current?.show(true);
  }, []);

  const closeDeleteDialog = React.useCallback(() => {
    confirmRef.current?.show(false);
    setCategoryToDeleteId("");
  }, []);

  const handleEditCategory = React.useCallback(
    async (data: CreateSelectionCategoryPayload) => {
      try {
        if (!data) return;
        createUpdateCategory({
          variables: {
            selectionEntityId,
            selectionEntityType,
            category: {
              _id: editCategory?._id,
              name: data.name,
              description: data.description,
              dueDate: data.dueDate,
            },
          },
        });
        notify({
          title: t("selections.editCategory"),
          content: t("selections.success.editCategory"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("selections.editCategory"),
          content: t("selections.error.editCategory"),
        });
      }

      closeCreateCategoryModal();
    },
    [
      closeCreateCategoryModal,
      createUpdateCategory,
      editCategory?._id,
      selectionEntityId,
      selectionEntityType,
      t,
    ]
  );

  const handleDeleteOptionSubmit = React.useCallback(() => {
    if (!editItemOption || !editCategoryItem || !selectedCategory) {
      return;
    }
    deleteOption({
      variables: {
        categoryId: selectedCategory?._id,
        itemId: editCategoryItem._id,
        optionId: editItemOption._id,
      },
    });
  }, [deleteOption, editCategoryItem, editItemOption, selectedCategory]);

  const closeCreateItemOptionModal = React.useCallback(() => {
    setShowCreateEditItemOptionModal(false);
    setEditCategoryItem(null);
    setEditItemOption(null);
  }, []);

  const openEditItemOptionModal = React.useCallback(
    (option: SelectionCategoryItemOption, item: SelectionCategoryItemType) => {
      setEditItemOption(option);
      setEditCategoryItem(item);
      setShowCreateEditItemOptionModal(true);
    },
    []
  );

  const openCreateItemOptionModal = React.useCallback(
    (item: SelectionCategoryItemType) => {
      setEditCategoryItem(item);
      setShowCreateEditItemOptionModal(true);
    },
    []
  );

  const openEditCategoryItemModal = React.useCallback(
    (item: SelectionCategoryItemType) => {
      setEditCategoryItem(item);
      setShowCreateEditCategoryItemModal(true);
    },
    []
  );

  const openDeleteCategoryItemDialog = React.useCallback((id: string) => {
    setCategoryItemSelectedId(id);
    confirDeleteItemRef.current?.show(true);
  }, []);

  const openDeleteOptionDialog = React.useCallback(
    (option: SelectionCategoryItemOption, item: SelectionCategoryItemType) => {
      setEditItemOption(option);
      setEditCategoryItem(item);
      confirDeleteOptionRef.current?.show(true);
    },
    []
  );

  const closeDeleteCategoryItemDialog = React.useCallback(() => {
    confirDeleteItemRef.current?.show(false);
    setCategoryItemSelectedId("");
  }, []);

  const closeDeleteOptionDialog = React.useCallback(() => {
    confirDeleteOptionRef.current?.show(false);
    setEditItemOption(null);
    setEditCategoryItem(null);
  }, []);

  const closeApproveOptionConfirmModal = React.useCallback(() => {
    setEditCategoryItem(null);
    confirApproveOptionRef.current?.show(false);
  }, []);

  const handleSelectSelectionCategoryItem = React.useCallback(
    (option: SelectionCategoryItemOption, item: SelectionCategoryItemType) => {
      selectSelectionCategoryItem({
        variables: {
          selectionEntityId,
          selectionEntityType: EnumSelectionEntityType.SELECTION,
          selection: {
            categoryId: selectedCategory?._id,
            itemId: item._id,
            optionId: option._id,
          },
        },
      });
    },
    [selectSelectionCategoryItem, selectedCategory?._id, selectionEntityId]
  );

  const handleApproveSelectionCategoryItem = React.useCallback(() => {
    approveSelectionCategoryItem({
      variables: {
        selectionEntityId,
        selectionEntityType: EnumSelectionEntityType.SELECTION,
        selection: {
          categoryId: selectedCategory?._id,
          itemId: editCategoryItem?._id,
        },
      },
    });
    closeApproveOptionConfirmModal();
  }, [
    approveSelectionCategoryItem,
    closeApproveOptionConfirmModal,
    editCategoryItem?._id,
    selectedCategory?._id,
    selectionEntityId,
  ]);

  const handleShareSelection = React.useCallback(
    (data: SendEmailForm) => {
      sendRef.current?.show(false);
      shareSelectionCategory({
        variables: {
          selectionEntityId,
          message: { ...omit(data, ["title"]), subject: data.title },
        },
      });
    },
    [selectionEntityId, shareSelectionCategory]
  );

  const openApproveOptionConfirmModal = React.useCallback(
    (item: SelectionCategoryItemType) => {
      setEditCategoryItem(item);
      confirApproveOptionRef.current?.show(true);
    },
    []
  );

  const hasUnallocatedItems = React.useCallback(() => {
    const items = categories?.reduce((total, category) => {
      return total.concat(category.items);
    }, [] as SelectionCategoryItemType[]);
    const unallocated = items?.find((item) => !item?.costingItem);
    return Boolean(unallocated);
  }, [categories]);

  const openSendModal = React.useCallback(() => {
    if (hasUnallocatedItems()) {
      checkUnallocatedItemsRef.current?.show(true);
    } else {
      sendRef.current?.show(true);
    }
  }, [hasUnallocatedItems]);

  const deletingCategoryItemTitle = React.useMemo(() => {
    const category = find(selectedCategory?.items, {
      _id: categoryItemSelectedId,
    });

    return category?.name;
  }, [selectedCategory, categoryItemSelectedId]);

  const deletingOptionTitle = React.useMemo(() => {
    return editItemOption?.name;
  }, [editItemOption?.name]);

  const handleUnselectCategoryItem = React.useCallback(
    (item: SelectionCategoryItemType, isUnapprove?: boolean) => {
      createUpdateCategoryItem({
        variables: {
          categoryId: selectedCategory?._id,
          item: {
            status: !isUnapprove
              ? EnumSelectionStatus.DRAFT
              : EnumSelectionStatus.SELECTED,
            _id: item?._id,
            name: item.name,
          },
        },
      });
    },
    [createUpdateCategoryItem, selectedCategory]
  );

  const handleCreateItemOption = React.useCallback(
    async (
      data: SelectionCategoryItemOptionInput,
      cb: (urls: UploadUrl[]) => Promise<void>
    ) => {
      setIsDownloadingFiles(true);
      const result = await createUpdateItemOption({
        variables: {
          categoryId: selectedCategory?._id,
          itemId: editCategoryItem?._id,
          option: {
            ...data,
            ...(editItemOption && { _id: editItemOption._id }),
            cost: Number(data?.cost) || 0,
            quantity: Number(data.quantity) || 0,
          },
        },
        update: !isTemplate
          ? !editItemOption
            ? handleAddCategoryItemOption(
                salesQuoteId,
                selectedCategory?._id,
                editCategoryItem?._id
              )
            : undefined
          : handleAddTemplateCategoryItemOption(
              templateId,
              selectedCategory?._id,
              editCategoryItem?._id
            ),
      });
      const resultAttachments =
        result.data?.createUpdateSelectionCategoryItemOption.attachments;
      const existingFiles = editItemOption?.attachments.map((file) => file._id);
      const newFiles = resultAttachments?.filter(
        (file) => !existingFiles?.includes(file._id)
      );
      if (newFiles?.length) {
        await cb(newFiles);
      }
      setIsDownloadingFiles(false);
      closeCreateItemOptionModal();
    },
    [
      createUpdateItemOption,
      selectedCategory?._id,
      editCategoryItem?._id,
      editItemOption,
      isTemplate,
      salesQuoteId,
      templateId,
      closeCreateItemOptionModal,
    ]
  );

  const handleCreateCategory = React.useCallback(
    async (data: CreateSelectionCategoryPayload) => {
      try {
        if (!data) return;
        createUpdateCategory({
          variables: {
            selectionEntityId: selectionEntityId,
            selectionEntityType,
            category: {
              ...data,
            },
          },
          update: isTemplate
            ? handleAddTemplateCategory(templateId)
            : handleAddCategory(salesQuoteId),
        });
        notify({
          title: t("selections.createNewCategory"),
          content: t("selections.success.addCategory"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("selections.createNewCategory"),
          content: t("selections.error.addCategory"),
        });
      }

      closeCreateCategoryModal();
    },
    [
      closeCreateCategoryModal,
      createUpdateCategory,
      isTemplate,
      salesQuoteId,
      selectionEntityId,
      selectionEntityType,
      t,
      templateId,
    ]
  );

  const closeCreateCategoryItemModal = React.useCallback(() => {
    setShowCreateEditCategoryItemModal(false);
    setEditCategoryItem(null);
  }, []);

  const showCreateCategoryItemModal = React.useCallback(() => {
    setShowCreateEditCategoryItemModal(true);
  }, []);

  const handleOnCloseUnallocatedItemsDialog = React.useCallback(() => {
    checkUnallocatedItemsRef.current?.show(false);
  }, []);

  const handleEditCategoryItem = React.useCallback(
    async (data: CreateSelectionCategoryItemPayload) => {
      try {
        if (!data) return;
        createUpdateCategoryItem({
          variables: {
            categoryId: selectedCategory?._id,
            item: {
              ...data,
              _id: editCategoryItem?._id,
              costingItemEntityId: data?.costingItemEntityId || null,
              costingItemEntityType: EnumSelectionLinkedEntityType.ESTIMATION,
            },
          },
        });
        notify({
          title: t("selections.editCategoryItem"),
          content: t("selections.success.editCategoryItem"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("selections.editCategoryItem"),
          content: t("selections.error.editCategoryItem"),
        });
      }

      closeCreateCategoryItemModal();
    },
    [createUpdateCategoryItem, selectedCategory, editCategoryItem, t]
  );

  const handleCreateCategoryItem = React.useCallback(
    async (data: CreateSelectionCategoryItemPayload) => {
      try {
        if (!data) return;
        createUpdateCategoryItem({
          variables: {
            categoryId: selectedCategory?._id,
            item: {
              ...data,
              costingItemEntityId: data?.costingItemEntityId || null,
              costingItemEntityType: EnumSelectionLinkedEntityType.ESTIMATION,
            },
          },
          update: !isTemplate
            ? handleAddCategoryItem(salesQuoteId, selectedCategory?._id)
            : handleAddTemplateCategoryItem(templateId, selectedCategory?._id),
        });
        notify({
          title: t("selections.createNewSelectionItem"),
          content: t("selections.success.addCategoryItem"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("selections.createNewSelectionItem"),
          content: t("selections.error.addCategoryItem"),
        });
      }

      closeCreateCategoryItemModal();
    },
    [
      closeCreateCategoryItemModal,
      createUpdateCategoryItem,
      selectedCategory?._id,
      isTemplate,
      salesQuoteId,
      templateId,
      t,
    ]
  );

  const deletingCategoryTitle = React.useMemo(() => {
    const category = find(categories, {
      _id: categoryToDeleteId,
    });

    return category?.name;
  }, [categories, categoryToDeleteId]);

  const renderCreateUpdateCategoryModal = React.useCallback(
    (isTemplate?: boolean) => {
      return (
        <>
          <CreateUpdateCategoryModal
            show={showCreateEditCategoryModal}
            onClose={closeCreateCategoryModal}
            onSubmit={editCategory ? handleEditCategory : handleCreateCategory}
            data={editCategory}
            isTemplate={isTemplate}
          />
          <ConfirmDialog
            ref={confirmRef}
            title={deletingCategoryTitle}
            onSubmit={handleDeleteSubmit}
            onClose={closeDeleteDialog}
            disabled={categoryDeleting}
          >
            <span className="field-text">
              {t("selections.deleteCategoryMessage")}
            </span>
          </ConfirmDialog>
        </>
      );
    },
    [
      categoryDeleting,
      closeCreateCategoryModal,
      closeDeleteDialog,
      deletingCategoryTitle,
      editCategory,
      handleCreateCategory,
      handleDeleteSubmit,
      handleEditCategory,
      showCreateEditCategoryModal,
      t,
    ]
  );

  const renderCreateUpdateCategoryItemModal = React.useCallback(
    (costingCategories?: SalesQuoteCategory[], isTemplate?: boolean) => {
      return (
        <>
          <CreateUpdateCategoryItemModal
            show={showCreateEditCategoryItemModal}
            onClose={closeCreateCategoryItemModal}
            onSubmit={
              editCategoryItem
                ? handleEditCategoryItem
                : handleCreateCategoryItem
            }
            costingCategories={costingCategories || []}
            data={editCategoryItem}
            isTemplate={isTemplate}
          />
          <ConfirmDialog
            ref={confirDeleteItemRef}
            title={deletingCategoryItemTitle}
            onSubmit={handleDeleteCategoryItemSubmit}
            onClose={closeDeleteCategoryItemDialog}
          >
            <span className="field-text">
              {t("selections.deleteCategoryItemMessage")}
            </span>
          </ConfirmDialog>
        </>
      );
    },
    [
      closeCreateCategoryItemModal,
      closeDeleteCategoryItemDialog,
      deletingCategoryItemTitle,
      editCategoryItem,
      handleCreateCategoryItem,
      handleDeleteCategoryItemSubmit,
      handleEditCategoryItem,
      showCreateEditCategoryItemModal,
      t,
    ]
  );

  const renderCreateUpdateItemOptionModal = React.useCallback(
    (allUoms?: UOMOption[]) => {
      return (
        <>
          <CreateItemOptionModal
            show={showCreateEditItemOptionModal}
            onClose={closeCreateItemOptionModal}
            onSubmit={handleCreateItemOption}
            data={editItemOption}
            uoms={allUoms || []}
          />
          <ConfirmDialog
            ref={confirDeleteOptionRef}
            title={deletingOptionTitle}
            onSubmit={handleDeleteOptionSubmit}
            onClose={closeDeleteOptionDialog}
          >
            <span className="field-text">
              {t("selections.deleteOptionMessage")}
            </span>
          </ConfirmDialog>
        </>
      );
    },
    [
      closeCreateItemOptionModal,
      closeDeleteOptionDialog,
      deletingOptionTitle,
      editItemOption,
      handleCreateItemOption,
      handleDeleteOptionSubmit,
      showCreateEditItemOptionModal,
      t,
    ]
  );

  const renderApproveOptionConfirm = React.useCallback(() => {
    return (
      <ConfirmDialog
        ref={confirApproveOptionRef}
        title={t("selections.approveOption")}
        onSubmit={handleApproveSelectionCategoryItem}
        onClose={closeApproveOptionConfirmModal}
      >
        <span className="field-text">
          {t("selections.approveOptionMessage")}
        </span>
      </ConfirmDialog>
    );
  }, [closeApproveOptionConfirmModal, handleApproveSelectionCategoryItem, t]);

  const renderShareSelectionConfirm = React.useCallback(() => {
    return (
      <>
        <SendModal
          ref={sendRef}
          title={t("selections.shareWithClient")}
          submitText={t("common.share")}
          onSubmit={handleShareSelection}
          contacts={contact ? [contact] : []}
          contact={contact}
          subject={t("selections.selection")}
          disableContactSelect
        />
        <ConfirmDialog
          ref={checkUnallocatedItemsRef}
          isInfoDialog
          title={t("selections.unallocatedItemsTitle")}
          onClose={handleOnCloseUnallocatedItemsDialog}
        >
          <span className="field-text">
            {t("selections.unallocatedItemsMessage")}
          </span>
        </ConfirmDialog>
      </>
    );
  }, [handleShareSelection, contact, t]);

  return {
    renderCreateUpdateCategoryModal,
    openCreateCategoryModal,
    handleOnEditCategory,
    openDeleteCategoryDialog,
    renderCreateUpdateCategoryItemModal,
    renderCreateUpdateItemOptionModal,
    showCreateCategoryItemModal,
    openDeleteOptionDialog,
    handleUnselectCategoryItem,
    openEditItemOptionModal,
    openCreateItemOptionModal,
    openDeleteCategoryItemDialog,
    openEditCategoryItemModal,
    isDownloadingFiles,
    handleSelectSelectionCategoryItem,
    openApproveOptionConfirmModal,
    openSendModal,
    renderApproveOptionConfirm,
    renderShareSelectionConfirm,
  };
}
