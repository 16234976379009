import React from "react";
import TabsTableCard from "../../dashboard/tabs-table-card";
import { isEmpty, map } from "lodash";
import { useTranslation } from "react-i18next";
import {
  TableCardData,
  TableCardDataRow,
  TableRowActionData,
} from "../../dashboard/table-card/utils";
import { ClientJob, ClientSalesQuote } from "../../../models/client";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ContactDetail } from "../../../graphql/types/models/client";

type ClientActivity = {
  contacts?: ContactDetail | null;
  contactLoading?: boolean;
};

const ClientActivity: React.FC<ClientActivity> = (props) => {
  const { contacts, contactLoading } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const handleQuoteDelete = React.useCallback((quote: ClientSalesQuote) => {
    console.log(quote);
  }, []);

  const handleQuotesRowClick = React.useCallback(
    (row: TableCardDataRow<ClientSalesQuote>) => {
      history.push(`/quotes/${row.cells._id}/plans`);
    },
    []
  );

  const handleJobRowClick = React.useCallback(
    (row: TableCardDataRow<ClientJob>) => {
      history.push(`/jobs/${row.cells._id}/schedule`);
    },
    []
  );

  const quotesTableRowActions: TableRowActionData<
    ClientSalesQuote
  >[] = React.useMemo(
    () => [
      {
        icon: "delete",
        onClick: handleQuoteDelete,
      },
    ],
    [handleQuoteDelete]
  );

  const quotesTableData = React.useMemo<TableCardData<ClientSalesQuote>>(
    () => ({
      columns: [
        {
          valueKey: "number",
          title: t("common.number"),
          formatValue: (row: any, column: any, value: string) => `Q-${value}`,
        },
        {
          valueKey: "name",
          title: t("common.name"),
          formatValue: (row: any, column: any, value: string) => value,
        },
        {
          valueKey: "subTotal",
          title: t("common.quoteTotal"),
          formatValue: (row: any, column: any, value: number) =>
            `$${value.toLocaleString("en-US")}`,
        },
        {
          valueKey: "createdAt",
          title: t("common.createdOn"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
        {
          valueKey: "status",
          title: t("common.status"),
          formatValue: (row: any, column: any, value: string) =>
            t(`quotes.statuses.${value}`),
        },
      ],
      rows: map(contacts?.salesQuotes, (quote) => ({
        cells: quote,
      })),
    }),
    [t, contacts]
  );

  const jobsTableData = React.useMemo<TableCardData<ClientJob>>(
    () => ({
      columns: [
        {
          valueKey: "name",
          title: t("common.name"),
        },
        {
          valueKey: "state",
          title: t("common.location"),
        },
        {
          valueKey: "contractTotal",
          title: t("jobs.contractTotal"),
          formatValue: (row: any, column: any, value: number) =>
            `$${value.toLocaleString("en-US")}`,
        },
        {
          valueKey: "createdAt",
          title: t("common.createdOn"),
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format("Do MMM YYYY"),
        },
      ],
      rows: map(contacts?.jobs, (job) => ({
        cells: job,
      })),
    }),
    [contacts, t]
  );

  const tables = React.useMemo(() => {
    return [
      {
        id: "jobs",
        title: t("common.jobs"),
        data: jobsTableData,
        isDataLoading: contactLoading || isEmpty(contacts?.jobs),
        tableProps: {
          onRowClick: handleJobRowClick,
          rowActions: quotesTableRowActions,
        },
      },
      {
        id: "quotes",
        title: t("common.quotes"),
        data: quotesTableData,
        isDataLoading: contactLoading || isEmpty(contacts?.salesQuotes),
        tableProps: {
          onRowClick: handleQuotesRowClick,
          rowActions: quotesTableRowActions,
        },
      },
    ];
  }, [
    contacts,
    contactLoading,
    handleJobRowClick,
    handleQuotesRowClick,
    jobsTableData,
    quotesTableData,
    quotesTableRowActions,
    t,
  ]);

  return <TabsTableCard defaultTab="jobs" data={tables} />;
};

export default ClientActivity;
