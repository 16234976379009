import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../../utils/yup";
import {
  AddPriceListCategoryPayload,
  PriceListUpdateMethod,
} from "../../../../models/price-list";

export default function createPriceListCategorySchema(t: TFunction) {
  return yup.object().shape({
    name: yup.string().when("method", {
      is: (value) => {
        return value === PriceListUpdateMethod.INPUT;
      },
      then: yup
        .string()
        .required(
          ValidationMessagesUtils.createRequiredMessage(t, "priceList.name")
        ),
    }),
    method: yup.string().notRequired(),
    items: yup
      .array()
      .of(
        yup.object().shape({
          sku: yup.string(),
          name: yup
            .string()
            .required(
              ValidationMessagesUtils.createRequiredMessage(t, "priceList.name")
            ),
          UOM: yup
            .string()
            .required(
              ValidationMessagesUtils.createRequiredMessage(t, "priceList.uom")
            ),
          cost: yup.number(),
          // .required(
          //   ValidationMessagesUtils.createRequiredMessage(t, "priceList.cost")
          // ),
        })
      )
      .when("method", {
        is: (value) => {
          return value === PriceListUpdateMethod.INPUT;
        },
        then: yup.array().min(1).required(),
      }),
  });
}
