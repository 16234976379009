import { MutationUpdaterFn, ApolloCache } from "@apollo/client";
import { concat, filter, template } from "lodash";
import {
  CopySalesQuotePrintTemplateResponse,
  CreateUpdateSalesQuotePrintTemplateResponse,
  DeleteSalesQuotePrintTemplateResponse,
  ListSalesQuotePrintTemplatesResponse,
  SalesQuotePrintTemplate,
  SalesQuotePrintTemplateCover,
  SalesQuotePrintTemplateCoverImage,
} from "../../types/models/quote-designer";
import { SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT } from "./fragments";
import { LIST_QUOTE_PRINT_TEMPLATES } from "./queries";

const getPrintTemplates = (cache: ApolloCache<any>) => {
  const response = cache.readQuery<ListSalesQuotePrintTemplatesResponse>({
    query: LIST_QUOTE_PRINT_TEMPLATES,
  });

  return response?.listSalesQuotePrintTemplates;
};

const updatePrintTemplates = (
  cache: ApolloCache<any>,
  templates: SalesQuotePrintTemplate[]
) => {
  cache.writeQuery<ListSalesQuotePrintTemplatesResponse>({
    query: LIST_QUOTE_PRINT_TEMPLATES,
    data: {
      listSalesQuotePrintTemplates: templates,
    },
  });
};

export const handleUpdatePrintTemplate: MutationUpdaterFn<CreateUpdateSalesQuotePrintTemplateResponse> = (
  cache,
  response
) => {
  const updatedTemplate = response.data?.createUpdateSalesQuotePrintTemplate;
  const id = `SalesQuotePrintTemplate:${updatedTemplate?._id}`;
  const templateFragment = cache.readFragment({
    id,
    fragment: SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT,
  });
  if (!templateFragment) return;

  const [background, logo] = ["background", "logo"].map((image) => {
    const key = image as keyof SalesQuotePrintTemplateCover;
    const cover = updatedTemplate?.cover as SalesQuotePrintTemplateCover;
    const data = cover?.[key] as SalesQuotePrintTemplateCoverImage;
    return data
      ? {
          ...data,
          url: data.url ? `${data.url}?${+new Date()}` : undefined,
        }
      : null;
  });
  const template = {
    ...updatedTemplate,
    cover: {
      ...updatedTemplate?.cover,
      background,
      logo,
    },
  };
  cache.writeFragment({
    id,
    fragment: SALES_QUOTE_PRINT_TEMPALTE_FRAGMENT,
    data: template,
  });
};

export const handleCopyPrintTemplate: MutationUpdaterFn<CopySalesQuotePrintTemplateResponse> = (
  cache,
  response
) => {
  const addedTemplate = response.data?.copySalesQuotePrintTemplate;
  const templatesCache = getPrintTemplates(cache);

  if (!addedTemplate || !templatesCache) return;

  const templates = concat(templatesCache, addedTemplate);
  updatePrintTemplates(cache, templates);
};

export const handleDeletePrintTemplate: MutationUpdaterFn<DeleteSalesQuotePrintTemplateResponse> = (
  cache,
  response
) => {
  const deletedTemplate = response.data?.deleteSalesQuotePrintTemplate;
  const templatesCache = getPrintTemplates(cache);

  if (!deletedTemplate || !templatesCache) return;

  const templates = filter(
    templatesCache,
    (template) => template._id !== deletedTemplate._id
  );

  updatePrintTemplates(cache, templates);
};
