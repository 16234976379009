import classNames from "classnames";
import map from "lodash/map";
import range from "lodash/range";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import Icon from "../../icons/Icon";

type PagesSelectorProps = {
  selectedPage: number;
  pagesCount: number;
  onPageSelect: (page: number) => void;
};

const PagesSelector: React.FC<PagesSelectorProps> = ({
  selectedPage,
  pagesCount,
  onPageSelect,
}) => {
  const { t } = useTranslation();
  const canGoBack = React.useMemo(() => selectedPage > 1, [selectedPage]);
  const canGoForward = React.useMemo(() => selectedPage < pagesCount, [
    selectedPage,
    pagesCount,
  ]);

  const handleNavigationClick = React.useCallback(
    (goBack: boolean) => () => {
      if (goBack && canGoBack) onPageSelect(selectedPage - 1);
      if (!goBack && canGoForward) onPageSelect(selectedPage + 1);
    },
    [selectedPage, canGoBack, canGoForward, onPageSelect]
  );

  const handleMenuItemSelect = React.useCallback(
    (pageNumber: number) => () => {
      onPageSelect(pageNumber);
    },
    [onPageSelect]
  );

  return (
    <div className="page-selector">
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className="selector-button">
          {t("plansSection.pageOf", {
            currentPage: selectedPage,
            totalPages: pagesCount,
          })}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {map(range(1, pagesCount + 1), (pageNumber) => (
            <Dropdown.Item
              key={`page-${pageNumber}`}
              className="field-text"
              active={pageNumber === selectedPage}
              onClick={handleMenuItemSelect(pageNumber)}
            >
              {t("plansSection.pageNumber", { pageNumber })}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <div
        className={classNames("selector-button", { disabled: !canGoBack })}
        onClick={handleNavigationClick(true)}
      >
        <Icon name="chevron_left" />
      </div>
      <div
        className={classNames("selector-button", { disabled: !canGoForward })}
        onClick={handleNavigationClick(false)}
      >
        <Icon name="chevron_right" />
      </div>
    </div>
  );
};

export default PagesSelector;
