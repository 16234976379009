import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import { WithT } from "i18next";

type SignUpFormFooterProps = WithT & { isSubmitting: boolean };

const SignUpFormFooter: React.FC<SignUpFormFooterProps> = ({
  t,
  isSubmitting,
}) => {
  return (
    <Row className="w-100 m-0 sign-up-submit">
      <Col
        lg={4}
        xs={12}
        className="d-flex p-0 align-items-center justify-content-end"
      >
        <Button
          size="lg"
          variant="success"
          type="submit"
          className="button large success"
          disabled={isSubmitting}
        >
          {t("authentication.signUp")}
        </Button>
      </Col>
      <Col
        lg={8}
        xs={12}
        className="d-flex p-0 align-items-center sign-up-other"
      >
        <span className="field-text">
          {t("authentication.alreadyHaveAccount")}
        </span>
        <Link to="/sign-in" className="sign-in-button">
          <Button
            size="lg"
            disabled={isSubmitting}
            variant="outline-success"
            className="button large outline-success mr-2"
          >
            {t("authentication.signIn")}
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

export default SignUpFormFooter;
