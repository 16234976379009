import { MutationUpdaterFn } from "@apollo/client";
import { concat, filter } from "lodash";
import {
  AddJobContactResponse,
  ListJobContactsResponse,
  RemoveJobContactResponse,
} from "../models/job-contact";
import { LIST_JOB_CONTACTS } from "./queries";
import { ListJobContactsInput } from "../input/job-contacts";

export const handleJobContactAdd = (
  jobId?: string
): MutationUpdaterFn<AddJobContactResponse> => (cache, response) => {
  if (!jobId) {
    return;
  }

  const addedContact = response?.data?.addedContact;

  const contacts = cache.readQuery<ListJobContactsResponse>({
    query: LIST_JOB_CONTACTS,
    variables: {
      jobId,
    },
  });

  if (!addedContact || !contacts) {
    return;
  }

  const jobContacts = contacts?.jobContacts;

  cache.writeQuery<ListJobContactsResponse, ListJobContactsInput>({
    query: LIST_JOB_CONTACTS,
    data: {
      jobContacts: concat(jobContacts, addedContact),
    },
    variables: {
      jobId,
    },
  });
};

export const handleJobContactRemove = (
  jobId?: string
): MutationUpdaterFn<RemoveJobContactResponse> => (cache, response) => {
  if (!jobId) {
    return;
  }

  const removedContact = response?.data?.removedContact;

  const contacts = cache.readQuery<ListJobContactsResponse>({
    query: LIST_JOB_CONTACTS,
    variables: {
      jobId,
    },
  });

  if (!removedContact || !contacts) {
    return;
  }

  const jobContacts = contacts?.jobContacts;

  cache.writeQuery<ListJobContactsResponse, ListJobContactsInput>({
    query: LIST_JOB_CONTACTS,
    data: {
      jobContacts: filter(
        jobContacts,
        (contact) => contact._id !== removedContact._id
      ),
    },
    variables: {
      jobId,
    },
  });
};
