export type ClientSalesQuote = {
  _id: string;
  name: string;
  number: number;
  subTotal: number;
  total: number;
  createdAt: string;
  status: string;
};

export type ContactNameListItem = {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
};

export type ClientJob = {
  _id: string;
  name: string;
  contractTotal: number;
  state: string;
  createdAt: string;
};

export type Client = {
  _id: string;
  business_name: string;
  abn: string;
  first_name: string;
  last_name: string;
  email: string;
  phase: string;
  phone: string;
  phone_secondary: string;
  mobile: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  jobs?: ClientJob[];
  salesQuotes?: ClientSalesQuote[];
  postcode: string;
  is_invited?: boolean;
  type: ClientType;
};

export enum ClientPhase {
  LEAD = "lead",
  PROSPECT = "prospect",
  CUSTOMER = "customer",
}

export enum ClientType {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}

export type CreateClientPayload = Omit<Client, "_id"> & {
  _id?: string;
};

export type CreateClientFormFields = Omit<CreateClientPayload, "mobile">;
export type ClientColumnMap = Omit<
  CreateClientPayload & { mobile: string },
  "salesQuotes" | "jobs"
>;

export type ContactPhones = {
  _id: string;
  last_name: string;
  first_name: string;
  phone: string;
  phone_secondary: string;
};

export enum ClientTypeEnum {
  CONTACT = "CONTACT",
  SUPPLIER = "SUPPLIER",
}
