import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { findIndex } from "lodash";

import JobTimesheetsCategories from "../../../components/job-timesheets/timesheets-categories";
import JobTimesheetsTable from "../../../components/job-timesheets/timesheets-table";
import ProcessTimesheetsModal from "../../../components/job-timesheets/process-timesheets-modal";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { GET_JOB_TIMESHEETS } from "../../../graphql/queries/job-timesheet/queries";
import { PROCESS_JOB_TIMESHEETS } from "../../../graphql/queries/job-timesheet/mutation";
import {
  handleDeleteJobTimesheet,
  handleTimesheetsProcessing,
} from "../../../graphql/queries/job-timesheet/utils";
import {
  JobTimesheetsListResponse,
  JobTimesheetsProcessResponse,
} from "../../../graphql/types/models/job-timesheet";
import {
  CreateUpdateTimesheetPayload,
  TimesheetCategory,
} from "../../../models/timesheet";
import {
  JobTimesheetTableItem,
  ProcessTimesheetModalFormItem,
  ProcessTimesheetPayloadInput,
} from "../../../models/job-timesheet";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { notify } from "../../../components/notification";
import JobLocationHeader from "../../header/job-location-header";
import ClientHeaderList from "../../header/client-header-list";

import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import TeammateHeaderList from "../../header/teammate-header-list";
import { useUnprocessTimesheet } from "../../../hooks/mutations/useUnprocessTimesheetMutation";
import CreateUpdateTimesheetModal from "../../../components/timesheets/create-update-timesheet-modal";
import {
  CreateUpdateTimesheetResponse,
  DeleteTimesheetResponse,
} from "../../../graphql/types/models/timesheet";
import {
  CREATE_UPDATE_TIMESHEET,
  DELETE_TIMESHEET,
} from "../../../graphql/queries/timesheet/mutation";
import { getTimesheetInput } from "../../timesheets/timesheets-overview/util";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import { DeleteTarget } from "../../timesheets/timesheets-overview";

type JobTimesheetsProps = DashboardContextValue & {
  user: UserPayload | null;
};

const JobTimesheets: React.FC<JobTimesheetsProps> = ({
  navigationContext,
  user,
}) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const jobId = navigationContext?.job?._id;

  const { t } = useTranslation();

  const proccesModalRef = React.useRef<ModalDisplayRef>(null);
  const confirmDeleteRef = React.useRef<ConfirmDialogRef>(null);

  const [category, setCategory] = React.useState<TimesheetCategory>(
    TimesheetCategory.UNPROCESSED
  );
  const [selectedItemsToProcesss, setSelectedItemsToProcesss] = React.useState<
    JobTimesheetTableItem[]
  >([]);
  const [
    timesheetToUpdate,
    setTimesheetToUpdate,
  ] = React.useState<JobTimesheetTableItem | null>(null);
  const [showEditModal, setShowEditModal] = React.useState(false);

  const {
    data: timesheetsUnprocessedResponse,
    loading: timesheetsUnprocessedLoading,
  } = useQuery<JobTimesheetsListResponse>(GET_JOB_TIMESHEETS, {
    variables: {
      jobId,
      isProcessed: false,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: timesheetsProcessedResponse,
    loading: timesheetsProcessedLoading,
  } = useQuery<JobTimesheetsListResponse>(GET_JOB_TIMESHEETS, {
    variables: {
      jobId,
      isProcessed: true,
    },
    fetchPolicy: "cache-and-network",
  });

  const [processTimesheets] = useMutation<JobTimesheetsProcessResponse>(
    PROCESS_JOB_TIMESHEETS
  );

  React.useEffect(() => {
    setSelectedItemsToProcesss([]);
  }, [category]);

  const toggleSelectedItem = React.useCallback(
    (row?: JobTimesheetTableItem) => {
      if (!row?._id) return;

      const newItems = [...selectedItemsToProcesss];
      const index = findIndex(newItems, { _id: row._id });
      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        newItems.push(row);
      }
      setSelectedItemsToProcesss(newItems);
    },
    [setSelectedItemsToProcesss, selectedItemsToProcesss]
  );

  const getTimesheetsInputArray = React.useCallback(
    (
      items: ProcessTimesheetModalFormItem[]
    ): ProcessTimesheetPayloadInput[] => {
      return items.map((item) => {
        const result: ProcessTimesheetPayloadInput = {
          task: item.task,
          rate: Number(item.rate),
          jobId: item.job._id,
          timesheetId: item._id,
          startTime: item.startTime,
          endTime: item.endTime,
          breakDuration: Number(item.breakDuration),
          costingCategoryName: item.costingCategoryName,
          costingItemId: item.costingItemId,
        };

        return result;
      });
    },
    []
  );

  const [createUpdateTimesheet] = useMutation<CreateUpdateTimesheetResponse>(
    CREATE_UPDATE_TIMESHEET
  );
  const [deleteTimesheet] = useMutation<DeleteTimesheetResponse>(
    DELETE_TIMESHEET
  );

  const handleProcessTimesheets = React.useCallback(
    async (items: ProcessTimesheetModalFormItem[]) => {
      try {
        if (!items || !jobId) return;

        await processTimesheets({
          variables: {
            timesheets: getTimesheetsInputArray(items),
          },
          update: handleTimesheetsProcessing(jobId),
        });

        notify({
          title: t("jobTimesheets.processTimesheets"),
          content: t("jobTimesheets.success.processTimesheets"),
        });
        setSelectedItemsToProcesss([]);
      } catch (e) {
        notify({
          error: true,
          title: t("jobTimesheets.processTimesheets"),
          content: t("jobTimesheets.errors.processTimesheets"),
        });
      }
    },
    [t, processTimesheets, getTimesheetsInputArray, jobId]
  );

  const openEditModal = React.useCallback(
    (row?: JobTimesheetTableItem) => {
      if (row) {
        setTimesheetToUpdate(row);
        setShowEditModal(true);
      }
    },
    [setShowEditModal]
  );

  const closeCreateModal = React.useCallback(() => {
    setTimesheetToUpdate(null);
    setShowEditModal(false);
  }, [setShowEditModal]);

  const handleEditTimesheet = React.useCallback(
    async (data: CreateUpdateTimesheetPayload, createAnother: boolean) => {
      try {
        if (!data || !timesheetToUpdate) return;
        await createUpdateTimesheet({
          variables: {
            timesheets: [
              getTimesheetInput({ ...data, _id: timesheetToUpdate._id }),
            ],
          },
        });
        notify({
          title: t("timesheets.updateTimesheet"),
          content: t("timesheets.success.updateTimesheet"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("timesheets.updateTimesheet"),
          content: t("timesheets.errors.updateTimesheet"),
        });
      }
      closeCreateModal();
    },
    [closeCreateModal, timesheetToUpdate, createUpdateTimesheet, t]
  );
  const [deleteTarget, setDeleteTarget] = React.useState<DeleteTarget>(null);
  const openRemoveDialog = React.useCallback(
    (row?: JobTimesheetTableItem) => {
      if (row) {
        setDeleteTarget({
          jobId: row.job?._id,
          timesheetId: row._id,
        });
        confirmDeleteRef?.current?.show(true);
      }
    },

    []
  );

  const closeRemoveDialog = React.useCallback(() => {
    setDeleteTarget(null);
    confirmDeleteRef?.current?.show(false);
  }, []);

  const handleRemoveTimesheet = React.useCallback(async () => {
    try {
      if (!deleteTarget) return;
      await deleteTimesheet({
        variables: deleteTarget,
        update: handleDeleteJobTimesheet(jobId as string),
      });
      notify({
        title: t("timesheets.deleteTimesheet"),
        content: t("timesheets.success.deleteTimesheet"),
      });
    } catch (e) {
      notify({
        error: true,
        title: t("timesheets.deleteTimesheet"),
        content: t("timesheets.errors.deleteTimesheet"),
      });
    }
  }, [deleteTarget, deleteTimesheet, t, jobId]);

  const showProcessModal = React.useCallback(() => {
    proccesModalRef.current?.show(true);
  }, [proccesModalRef]);

  const tableData = React.useMemo(() => {
    return category === TimesheetCategory.PROCESSED
      ? timesheetsProcessedResponse?.getJobTimesheets
      : timesheetsUnprocessedResponse?.getJobTimesheets;
  }, [category, timesheetsProcessedResponse, timesheetsUnprocessedResponse]);

  const tableLoading = React.useMemo(() => {
    return category === TimesheetCategory.PROCESSED
      ? timesheetsProcessedLoading
      : timesheetsUnprocessedLoading;
  }, [category, timesheetsProcessedLoading, timesheetsUnprocessedLoading]);

  const {
    renderUnprocessTimesheetDialog,
    openUnprocessTimesheetDialog,
  } = useUnprocessTimesheet();

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.timesheets")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.TIMESHEET} />
      <JobLocationHeader />
      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />

      <ProcessTimesheetsModal
        ref={proccesModalRef}
        jobId={jobId}
        jobTimesheetItems={selectedItemsToProcesss}
        onSubmit={handleProcessTimesheets}
      />
      <CreateUpdateTimesheetModal
        data={timesheetToUpdate}
        show={showEditModal}
        onClose={closeCreateModal}
        onSubmit={handleEditTimesheet}
        user={user}
        currentJobId={jobId}
      />
      <ConfirmDialog
        ref={confirmDeleteRef}
        onClose={closeRemoveDialog}
        onSubmit={handleRemoveTimesheet}
        title={t("timesheets.deleteTimesheet")}
        confirm={t("common.delete")}
      >
        <div className="field-text">
          {t("timesheets.deleteTimesheetMessage")}
        </div>
      </ConfirmDialog>

      {renderUnprocessTimesheetDialog()}

      <Row className="h-100">
        <Col lg={4} xs={12}>
          <JobTimesheetsCategories
            selectedCategory={category}
            items={{
              processed: timesheetsProcessedResponse?.getJobTimesheets,
              unprocessed: timesheetsUnprocessedResponse?.getJobTimesheets,
            }}
            selectCategory={(status: TimesheetCategory) => setCategory(status)}
          />
        </Col>
        <Col lg={8} xs={12}>
          <JobTimesheetsTable
            jobId={jobId}
            isProcessed={category === TimesheetCategory.PROCESSED}
            selectedCategory={category}
            items={tableData}
            selectedItems={selectedItemsToProcesss}
            tableTitle={category}
            isDataLoading={tableLoading}
            processPress={showProcessModal}
            toggleSelectedItem={toggleSelectedItem}
            onUnprocess={openUnprocessTimesheetDialog}
            openEditModal={openEditModal}
            openRemoveDialog={openRemoveDialog}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withDashboardContext(JobTimesheets));
