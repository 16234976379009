import React from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { chain, map, get, pick, find } from "lodash";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  QuoteRequest,
  QuoteRequestCostingPayload,
  QuoteRequestSubcontractor,
} from "../../../graphql/types/models/quote-request";
import { renderField } from "../../../components/generic-form/GenericFormBody";
import createQuoteRequestCostingSchema from "./createQuoteRequestCostingSchema";
import ListTable, {
  ListTableRow,
} from "../../../components/dashboard/list-table";
import ProposalText from "../../../components/quote-requests/proposal";
import DashboardCardBody from "../../../components/dashboard/card/DashboardCardBody";
import { calcGST, calcTotal, GST_PERCENT } from "../../../utils/calculations";
import DashboardCardFooter from "../../../components/dashboard/card/DashboardCardFooter";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";

type QuoteRequestEntryProps = {
  isEditing: boolean;
  quoteRequest: QuoteRequest;
  subcontractor: QuoteRequestSubcontractor;
  detailsData: ListTableRow[];
  onSubmit: (values: QuoteRequestCostingPayload) => void;
  canCancel?: boolean;
  onCancel?: () => void;
};

const QuoteRequestEntry: React.FC<QuoteRequestEntryProps> = ({
  isEditing,
  quoteRequest,
  subcontractor,
  detailsData,
  onSubmit,
  onCancel,
  canCancel,
}) => {
  const { t } = useTranslation();
  const confirmRef = React.useRef<ConfirmDialogRef>(null);

  const initialValues = React.useMemo<QuoteRequestCostingPayload>(
    () => ({
      quoteRequestId: quoteRequest?._id,
      items: map(quoteRequest?.items, (item, index) => ({
        quoteItemId: item._id,
        cost:
          get(
            quoteRequest,
            `subcontractors.0.items.${index}.cost`,
            ""
          ).toString() || "",
        gst_inc: false,
      })),
    }),
    [quoteRequest]
  );

  const attachmentData = React.useMemo<ListTableRow[]>(() => {
    return map(quoteRequest?.attachments, (attachment) => ({
      label: attachment.name,
      value: t("common.fileSize", { amount: attachment.size }),
    }));
  }, [quoteRequest]);

  const handleAttachmentClick = React.useCallback(
    (row: ListTableRow, index: number) => {
      const url = quoteRequest?.attachments[index]?.url;
      window.open(url, "_blank");
    },
    [quoteRequest]
  );

  const calcTotals = React.useCallback(
    (formikProps: FormikProps<QuoteRequestCostingPayload>) => {
      let subTotal: number;
      if (isEditing) {
        subTotal = chain(formikProps.values.items)
          .map((item, index) => ({
            ...item,
            quantity: quoteRequest?.items[index].quantity || 0,
          }))
          .reduce(
            (total, item) => total + item.quantity * +(item?.cost ?? 0),
            0
          )
          .value();
      } else {
        subTotal = chain(subcontractor?.items)
          .map((item, index) => ({
            ...item,
            quantity: quoteRequest?.items[index].quantity || 0,
          }))
          .reduce(
            (total, item) => total + item.quantity * +(item?.cost ?? 0),
            0
          )
          .value();
      }

      const GST = calcGST(subTotal, GST_PERCENT);
      const total = calcTotal(subTotal, GST);

      return [
        {
          label: "Subtotal",
          value: t("common.currency", { amount: subTotal }),
        },
        { label: "GST", value: t("common.currency", { amount: GST }) },
        { label: "Total", value: t("common.currency", { amount: total }) },
      ];
    },
    [isEditing, quoteRequest, subcontractor]
  );

  const handleConfirm = React.useCallback(() => {
    confirmRef.current?.show(true);
  }, [confirmRef]);

  return (
    <Formik
      validationSchema={createQuoteRequestCostingSchema(t)}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleConfirm}
    >
      {(formikProps) => {
        return (
          <Form
            onSubmit={formikProps.handleSubmit}
            onReset={formikProps.handleReset}
            noValidate
            className="quote-request-details"
          >
            <DashboardCardBody>
              <Row>
                <Col lg={6} md={12}>
                  <ProposalText title={t("quoteRequest.proposal")}>
                    {quoteRequest?.scope}
                  </ProposalText>
                </Col>
                <Col lg={6} md={12}>
                  <ListTable title={t("common.details")} rows={detailsData} />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Table
                    className="table table-form table-outline"
                    hover
                    responsive
                  >
                    <thead className="table-header table-header-nowrap">
                      <tr>
                        <th className="field-text">{t("costing.name")}</th>
                        <th className="field-text">{t("costing.quantity")}</th>
                        <th className="field-text">{t("costing.uom")}</th>
                        <th className="field-text">{t("costing.cost")}</th>
                        <th className="field-text text-right">
                          {t("costing.totalEx")}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {map(quoteRequest.items, (item, index) => (
                        <tr key={index}>
                          <td
                            className="field-text"
                            style={{ minWidth: "100px" }}
                          >
                            {item.name}
                          </td>
                          <td
                            className="field-text"
                            style={{ minWidth: "100px" }}
                          >
                            {t("common.quantity", {
                              amount: item.quantity,
                            })}
                          </td>
                          <td
                            className="field-text"
                            style={{ minWidth: "100px" }}
                          >
                            {item.UOM}
                          </td>
                          <td
                            className="field-text"
                            style={{ minWidth: "100px" }}
                          >
                            {isEditing
                              ? renderField(
                                  formikProps,
                                  {
                                    type: "text",
                                    valueKey: `items.${index}.cost`,
                                    inputProps: {
                                      type: "number",
                                      required: true,
                                    },
                                  },
                                  12
                                )
                              : t("common.currency", {
                                  amount: get(
                                    subcontractor,
                                    `items.${index}.cost`,
                                    ""
                                  ),
                                })}
                          </td>
                          <td
                            className="field-text text-right"
                            style={{ minWidth: "100px" }}
                          >
                            {t("common.currency", {
                              amount:
                                item.quantity *
                                (parseFloat(
                                  isEditing
                                    ? get(
                                        formikProps,
                                        `values.items.${index}.cost`,
                                        ""
                                      ).toString()
                                    : get(
                                        subcontractor,
                                        `items.${index}.cost`,
                                        ""
                                      ).toString()
                                ) || 0),
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  {!!attachmentData.length && (
                    <ListTable
                      title={t("common.attachments")}
                      rows={attachmentData}
                      onRowClick={handleAttachmentClick}
                    />
                  )}
                </Col>
                <Col lg={6}>
                  <ListTable
                    title={t("quoteRequest.cost")}
                    rows={calcTotals(formikProps)}
                  />
                </Col>
              </Row>
            </DashboardCardBody>
            {isEditing && (
              <DashboardCardFooter className="d-flex justify-content-end">
                {canCancel && (
                  <Button
                    variant="primary"
                    className="button large info"
                    onClick={onCancel}
                  >
                    {t("common.cancel")}
                  </Button>
                )}
                <Button
                  variant="primary"
                  className="button large success"
                  type="submit"
                  disabled={formikProps.isSubmitting}
                >
                  {t("common.submit")}
                </Button>
              </DashboardCardFooter>
            )}
            <ConfirmDialog
              ref={confirmRef}
              title={t("quoteRequest.submitQuote")}
              onSubmit={() => onSubmit(formikProps.values)}
            >
              <span className="field-text">
                {t("quoteRequest.confirmSubmit")}
              </span>
            </ConfirmDialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default QuoteRequestEntry;
