import React from "react";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { FormikProps } from "formik";
import * as uuid from "uuid";
import InputGroup from "react-bootstrap/InputGroup";
import { AppendDynamicPropsFn } from "../../GenericFormBody";
import Icon from "../../../icons/Icon";

type InputAppendProps = {
  icon?: string;
  text?: string;
  onClick?: () => void;
  className?: string;
  outlined?: boolean;
  formikProps?: Pick<FormikProps<any>, "values" | "setFieldValue">;
  fieldName?: string;
  rowIndex?: number;
  dynamicProps?: AppendDynamicPropsFn;
};

const InputAppend: React.FC<InputAppendProps> = (props) => {
  const {
    onClick,
    className,
    icon,
    text,
    outlined,
    dynamicProps,
    formikProps,
    fieldName,
    rowIndex,
  } = props;

  const tooltipId = `tt-markup-${uuid.v1()}`;
  const appendProps = React.useMemo(() => {
    return dynamicProps?.(formikProps, fieldName, rowIndex);
  }, [dynamicProps, formikProps, fieldName, rowIndex]);

  const appendIcon = React.useMemo(() => {
    const name = icon || appendProps?.icon;
    if (!name) return null;
    if (appendProps?.tooltip) {
      return (
        <>
          <span data-tip={appendProps?.tooltip} data-for={tooltipId}>
            <Icon name={name} outlined={outlined} />
          </span>
          <ReactTooltip
            id={tooltipId}
            place="bottom"
            getContent={() => appendProps?.tooltip}
          />
        </>
      );
    }
    return <Icon name={name} outlined={outlined} />;
  }, [icon, outlined, appendProps]);

  if (appendProps?.isHidden) return null;

  return (
    <>
      <InputGroup.Append className="append">
        <InputGroup.Text
          className={classNames("text", className, appendProps?.className)}
          onClick={onClick}
        >
          {text && text}
          {appendIcon}
        </InputGroup.Text>
      </InputGroup.Append>
    </>
  );
};

export default InputAppend;
