import React from "react";
import { useTranslation } from "react-i18next";
import { compact, map } from "lodash";
import filesize from "filesize";
import moment from "moment";

import TableCard, {
  EmptyTablePlaceholder,
} from "../../../components/dashboard/table-card";
import {
  TableCardDataRow,
  TableRowActionData,
} from "../../../components/dashboard/table-card/utils";
import Icon from "../../../components/icons/Icon";
import {
  Document,
  EnumDocumentAccessRole,
  PrimaryFolder,
  TableDocumentData,
} from "../../../models/documents";
import { CopyAction } from "../../../hooks/useDocumentsCopy";
import { User } from "../../../graphql/types/models/user";

export enum DocumentType {
  FOLDER = "Folder",
  FILE = "File",
}

export type DocumentsTable = {
  _id: string;
  name: string;
  type: string;
  size: number | null;
  accessRole?: EnumDocumentAccessRole;
  accessSelectedUsers?: User[];
};

type DocumentsTableProps = {
  folders: PrimaryFolder[];
  documents: Document[];
  tableLoading: boolean;
  selectFolder: (id: string) => void;
  handleEditFolderName: (tableItem: DocumentsTable) => void;
  handleEditFileName: (tableItem: DocumentsTable) => void;
  onUploadClick: () => void;
  onAddNewFolderClick: () => void;
  isRootFolder: boolean;
  selectedItemsToDelete: DocumentsTable[];
  addSelectedItemsToDelete: (items: DocumentsTable[]) => void;
  openRemoveDialog: (row?: DocumentsTable) => void;
  handleDownloadFile: (id: string) => void;
  onCopyAction?: (id: string, copyAction: CopyAction) => void;
  folderId?: string;
  isReadonly?: boolean;
  setAccessPermissions?: boolean;
};

const DocumentsTableComponent: React.FC<DocumentsTableProps> = (props) => {
  const {
    folderId,
    tableLoading,
    folders,
    documents,
    selectFolder,
    handleEditFolderName,
    handleEditFileName,
    onUploadClick,
    onAddNewFolderClick,
    isRootFolder,
    selectedItemsToDelete,
    addSelectedItemsToDelete,
    openRemoveDialog,
    handleDownloadFile,
    isReadonly,
    onCopyAction,
    setAccessPermissions,
  } = props;

  const { t } = useTranslation();

  const handleEditFolder = React.useCallback(
    (row?: DocumentsTable) => {
      if (row) {
        if (row.type === DocumentType.FOLDER) {
          handleEditFolderName(row);
        }
        if (row.type === DocumentType.FILE) {
          handleEditFileName(row);
        }
      }
    },
    [handleEditFolderName, handleEditFileName]
  );

  const handleCopyAction = React.useCallback(
    (row?: DocumentsTable) => {
      if (row) {
        if (row.type === DocumentType.FOLDER) {
          onCopyAction && onCopyAction(row._id, CopyAction.COPY_FOLDER);
        }
        if (row.type === DocumentType.FILE) {
          onCopyAction && onCopyAction(row._id, CopyAction.COPY_FILE);
        }
      }
    },
    [onCopyAction]
  );

  const transformArraysForTable = (
    folders: PrimaryFolder[],
    documents: Document[]
  ) => {
    return folders
      .map(
        (folder): TableDocumentData => ({
          _id: folder._id,
          name: folder.name,
          size: null,
          type: DocumentType.FOLDER,
          createdAt: folder.createdAt,
          accessRole: folder?.accessRole,
          accessSelectedUsers: folder?.accessSelectedUsers,
        })
      )
      .concat(
        documents.map(
          (document): TableDocumentData => ({
            _id: document._id,
            name: document.name,
            size: document.size,
            type: DocumentType.FILE,
            createdAt: document.createdAt,
            accessRole: document?.accessRole,
            accessSelectedUsers: document?.accessSelectedUsers,
          })
        )
      );
  };

  const toggleSelectedItem = React.useCallback(
    (row?: DocumentsTable) => {
      if (!row?._id) return;

      const newItems = [...selectedItemsToDelete];
      const item = newItems.find((item) => item._id === row._id);

      if (item) {
        newItems.splice(newItems.indexOf(item), 1);
      } else {
        newItems.push(row);
      }
      addSelectedItemsToDelete(newItems);
    },
    [selectedItemsToDelete, addSelectedItemsToDelete]
  );

  const documentsTableData = React.useMemo(() => {
    return {
      columns: compact([
        {
          valueKey: "",
          title: "",
          className: "icon",
          formatValue: (row: any, column: any) => {
            if (row?.cells?.type === DocumentType.FOLDER) {
              return <Icon name="folder" />;
            }
            if (row?.cells?.type === DocumentType.FILE) {
              return <Icon name="description" outlined />;
            }
          },
        },
        {
          valueKey: "name",
          title: t("documents.name"),
          sortable: true,
        },
        {
          valueKey: "type",
          title: t("documents.type"),
          sortable: true,
        },
        {
          valueKey: "size",
          title: t("documents.size"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            value ? filesize(value) : "-",
        },
        setAccessPermissions && {
          valueKey: "accessRole",
          title: t("common.access"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            t(`documents.documentAccessRoles.${value}`),
        },
        {
          valueKey: "createdAt",
          title: t("documents.createdAt"),
          sortable: true,
          formatValue: (row: any, column: any, value: number) =>
            moment(value).format("DD/MM/YY h:mma"),
        },
      ]),
      rows: map(transformArraysForTable(folders, documents), (item) => ({
        cells: {
          ...item,
        },
      })),
    };
  }, [selectedItemsToDelete, folders, documents, setAccessPermissions]);

  const tableRowActions: TableRowActionData<
    DocumentsTable
  >[] = React.useMemo(() => {
    return isReadonly
      ? []
      : [
          {
            icon: "more_horiz",
            dropdownId: "document-list",
            options: [
              {
                icon: "delete",
                outlined: true,
                id: "remove",
                label: t("common.delete"),
                onClick: openRemoveDialog,
              },
              {
                icon: "drive_file_rename_outline",
                outlined: true,
                id: "rename",
                label: t("common.edit"),
                onClick: handleEditFolder,
              },
              {
                icon: "content_copy",
                outlined: true,
                id: "copy-folder",
                label: t("documents.copyContents"),
                onClick: handleCopyAction,
                shouldRender: (row) =>
                  !!onCopyAction && row.type === DocumentType.FOLDER,
              },
              {
                icon: "content_copy",
                outlined: true,
                id: "copy-file",
                label: t("documents.copyDocument"),
                onClick: handleCopyAction,
                shouldRender: (row) =>
                  !!onCopyAction && row.type === DocumentType.FILE,
              },
            ],
          },
        ];
  }, [
    isReadonly,
    folders,
    documents,
    handleEditFolder,
    openRemoveDialog,
    onCopyAction,
  ]);

  const tableLeftRowActions: TableRowActionData<
    DocumentsTable
  >[] = React.useMemo(
    () =>
      isReadonly
        ? []
        : [
            {
              icon: "check_box",
              dropdownId: "document",
              onClick: toggleSelectedItem,
              shouldRender: (row) =>
                selectedItemsToDelete.find((item) => item._id === row._id),
            },
            {
              icon: "check_box_outline_blank",
              dropdownId: "document",
              onClick: toggleSelectedItem,
              shouldRender: (row) =>
                !selectedItemsToDelete.find((item) => item._id === row._id),
            },
          ],
    [isReadonly, selectedItemsToDelete, folders, documents, toggleSelectedItem]
  );

  const handleRowClick = React.useCallback(
    async (row: TableCardDataRow<DocumentsTable>) => {
      if (row.cells.type === DocumentType.FOLDER) {
        selectFolder(row.cells._id);
      }
      if (row.cells.type === DocumentType.FILE) {
        handleDownloadFile(row.cells._id);
      }
    },
    [selectFolder, documents]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () =>
      isRootFolder
        ? isReadonly
          ? {
              text: t("documents.emptyRootFolderBasicUser"),
            }
          : {
              text: t("documents.emptyRootFolderPlaceholder"),
              buttonText: t("documents.createFolder"),
              onPress: onAddNewFolderClick,
            }
        : isReadonly
        ? {
            text: t("documents.emptyFolderBasicUser"),
          }
        : {
            text: t("documents.emptyFolderPlaceholder"),
            buttonText: t("documents.uploadFiles"),
            onPress: onUploadClick,
          },
    [t, isRootFolder, onAddNewFolderClick, onUploadClick, isReadonly]
  );

  return (
    <>
      <TableCard
        tableId={folderId ?? "documents"}
        isDataLoading={tableLoading}
        data={documentsTableData}
        alignEnd={true}
        onRowClick={handleRowClick}
        alignLeftActionsEnd={false}
        leftRowActions={tableLeftRowActions}
        leftRowActionsClassName="icon"
        rowActions={tableRowActions}
        emptyPlaceholder={emptyPlaceholder}
        overflowInherit
      />
    </>
  );
};

export default DocumentsTableComponent;
