import React from "react";

import "./styles.scss";
import DashboardCard from "../card";
import DashboardCardHeader from "../card/DashboardCardHeader";
import DashboardCardBody from "../card/DashboardCardBody";
import DetailsCardList, { DetailsCardDataItem } from "./DetailsCardList";
import DashboardCardFooter from "../card/DashboardCardFooter";
import { map } from "lodash";
import Button from "react-bootstrap/Button";
import Icon from "../../icons/Icon";
import classNames from "classnames";

type DetailsCardProps = {
  title: string;
  data: DetailsCardDataItem[];
  actions?: DetailsCardAction[];
  fullHeight?: boolean;
  cardBodyStyles?: string;
  cardFooterStyles?: string;
  dashBoardCardStyles?: string;
  withoutHeader?: boolean;
};

export type DetailsCardAction = {
  title: string;
  icon?: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
};

const DetailsCard: React.FC<DetailsCardProps> = ({
  fullHeight,
  title,
  data,
  actions,
  children,
  cardBodyStyles,
  withoutHeader,
  dashBoardCardStyles,
  cardFooterStyles,
}) => {
  const cardClasses = classNames(
    {
      "mh-100": fullHeight,
    },
    "details-card",
    dashBoardCardStyles
  );

  return (
    <DashboardCard className={cardClasses}>
      {!withoutHeader && <DashboardCardHeader>{title}</DashboardCardHeader>}

      <DashboardCardBody className={cardBodyStyles}>
        {children}
        <DetailsCardList items={data} />
      </DashboardCardBody>
      {actions && (
        <DashboardCardFooter className={cardFooterStyles}>
          {map(actions, (action) => (
            <Button
              disabled={action.disabled}
              key={action.title}
              className={action.className}
              onClick={action.onClick}
            >
              {action.icon && <Icon name={action.icon} />}
              {action.title}
            </Button>
          ))}
        </DashboardCardFooter>
      )}
    </DashboardCard>
  );
};

export default DetailsCard;
