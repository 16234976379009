import { gql } from "@apollo/client";
import {
  QUOTE_REQUEST_FRAGMENT,
  CLIENT_QUOTE_REQUEST_FRAGMENT,
} from "./fragments";

export const LIST_QUOTE_REQUESTS = gql`
  query($filter: QuoteRequestFilterInput) {
    listQuoteRequests(filter: $filter) {
      ...QuoteRequest
    }
  }
  ${QUOTE_REQUEST_FRAGMENT}
`;

export const LIST_QUOTE_REQUEST_MESSAGES = gql`
  query($quoteRequestId: ID!, $subcontractorId: ID!) {
    listQuoteRequestMessages(
      quoteRequestId: $quoteRequestId
      subcontractorId: $subcontractorId
    ) {
      _id
      comment
      createdAt
      sender {
        _id
        name
        email
        senderType
      }
      attachments {
        _id
        upload_url
        url
        type
        size
      }
    }
  }
`;

export const GET_QUOTE_REQUEST = gql`
  query($quoteRequestId: ID!) {
    getQuoteRequestById(quoteRequestId: $quoteRequestId) {
      _id
      quoteNumber
      name
      note
      scope
      due_date

      business {
        _id
        name
      }

      job {
        _id
        name
        jobNumber
        customer {
          _id
          first_name
          last_name
          email
        }
      }

      salesQuote {
        _id
        number
        name
        contact {
          _id
          first_name
          last_name
          email
        }
      }

      attachments {
        _id
        url
        upload_url
        type
        size
        name
      }

      items {
        _id
        name
        UOM
        quantity
        job_costing {
          _id
          name
          UOM
          quantity
          hasGST
          rounding
          wastage
          category {
            _id
            name
          }
        }
      }

      subcontractors {
        _id
        supplier {
          _id
          business_name
          contact_person
          email
          business {
            _id
            name
          }
        }
        status
        date_submitted
        client_feedback
        items {
          _id
          cost
          gst_inc
          quoteItemId
        }
      }
    }
  }
`;

export const PREVIEW_QUOTE_REQUEST = gql`
  query($quoteRequestId: ID!, $subcontractorId: ID!) {
    previewQuoteRequest(
      quoteRequestId: $quoteRequestId
      subcontractorId: $subcontractorId
    ) {
      _id
      pdf
      filename
    }
  }
`;

export const PREVIEW_QUOTE_REQUEST_SHARE = gql`
  query($quoteRequestId: ID!, $subcontractorId: ID!) {
    previewQuoteRequestShare(
      quoteRequestId: $quoteRequestId
      subcontractorId: $subcontractorId
    ) {
      _id
      pdf
      filename
    }
  }
`;

export const CLIENT_LIST_JOB_QUOTE_REQUEST = gql`
  query ClientListJobQuoteResponses($jobId: ID!) {
    clientListJobQuoteResponses(jobId: $jobId) {
      ...ClientQuoteRequestData
    }
  }
  ${CLIENT_QUOTE_REQUEST_FRAGMENT}
`;

export const CLIENT_GET_JOB_QUOTES_SUB_BY_ID = gql`
  query ClientGetJobQuoteSubResponseById(
    $jobId: ID!
    $quoteRequestId: ID!
    $subResId: ID!
  ) {
    clientGetJobQuoteSubResponseById(
      jobId: $jobId
      quoteRequestId: $quoteRequestId
      subResId: $subResId
    ) {
      ...ClientQuoteRequestData
    }
  }
  ${CLIENT_QUOTE_REQUEST_FRAGMENT}
`;
