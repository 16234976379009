import React from "react";
import classNames from "classnames";
import "./styles.scss";

export type GroupTitleProps = {
  placeholder: string;
  style?: string;
};

const GroupTitle: React.FC<GroupTitleProps> = ({ placeholder, style }) => {
  const groupClasses = classNames("group-title", style);

  return <div className={groupClasses}>{placeholder}</div>;
};

export default GroupTitle;
