import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";
import "./utils/i18n";

import "./utils/leaflet-updated";
import * as serviceWorker from "./serviceWorker";
import AppContainer from "./containers/app";
import { configureStore } from "./redux/store";
import appHistory from "./router/history";
import GraphQLClient from "./graphql";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

ReactDOM.render(
  <>
    <ToastContainer />
    <ApolloProvider client={GraphQLClient.client}>
      <Provider store={configureStore(appHistory, {})}>
        <React.StrictMode>
          <Elements stripe={stripePromise}>
            <AppContainer />
          </Elements>
        </React.StrictMode>
      </Provider>
    </ApolloProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
