import classnames from "classnames";
import L, { LatLng, LatLngLiteral, Map, Zoom } from "leaflet";
import "leaflet-draw";
import { get, reduce } from "lodash";
import {
  calculateDistanceWithAngle,
  calculateWeight,
  convertQuantity,
  geodesicArea,
} from "../components/plans/take-off-plan-viewer/utils";
import { TakeOffMeasurement } from "../models/take-off";

const Leaflet: any = L;

const checkScale = (scale: any, name: any) => {
  if (!scale[name]) {
    scale[name] = 1;
  }
  return 1;
};

Leaflet.LineUtil._sqDistScale = function (
  p1: LatLng | LatLngLiteral,
  p2: LatLng | LatLngLiteral,
  scale: LatLng | LatLngLiteral
) {
  scale = scale || {};
  checkScale(scale, "lng");
  checkScale(scale, "lat");
  const dLat = (p1.lat - p2.lat) * scale.lat;
  const dLng = (p1.lng - p2.lng) * scale.lng;
  return Math.sqrt(dLng * dLng + dLat * dLat);
};

Leaflet.LatLng.prototype.distanceTo = function (
  other: LatLng | LatLngLiteral | [number, number],
  scale: number
) {
  return Leaflet.LineUtil._sqDistScale(this, other, scale);
};

let getInput = (
  container: any,
  buttonIndex: number,
  classNamePrefix: string,
  enabled: boolean,
  options: any
) => {
  options = options || {};
  let inputContainer = Leaflet.DomUtil.create(
    "div",
    classnames(classNamePrefix + "-container " + (options.class || ""), {
      "leaflet-disabled": !enabled,
    }),
    container
  );
  let input = Leaflet.DomUtil.create(
    "input",
    classnames(classNamePrefix + "-input", { "leaflet-disabled": !enabled }),
    inputContainer
  );
  input.value = get(options, "value") || 0;
  get(options, "onBlurSubmit") &&
    Leaflet.DomEvent.on(input, "click", Leaflet.DomEvent.stopPropagation)
      .on(input, "mousedown", Leaflet.DomEvent.stopPropagation)
      .on(input, "dblclick", Leaflet.DomEvent.stopPropagation)
      .on(input, "touchstart", Leaflet.DomEvent.stopPropagation)
      .on(input, "click", Leaflet.DomEvent.preventDefault)
      .on(input, "blur", options.onBlurSubmit)
      .on(input, "keydown", (e: any) => {
        e.keyCode === 13 && options.onBlurSubmit(e);
      });
};

Leaflet.DrawToolbar.include({
  getModeHandlers: function (map: Map) {
    let deduction = new Leaflet.Draw.Polygon(map, this.options.deduction);
    deduction.type = "deduction";

    let polylineDeduction = new Leaflet.Draw.Polyline(
      map,
      this.options.polylineDeduction
    );
    polylineDeduction.type = "polylineDeduction";

    let rectangleDeduction = new Leaflet.Draw.Rectangle(
      map,
      this.options.rectangleDeduction
    );
    rectangleDeduction.type = "rectangleDeduction";

    let comment = new Leaflet.Draw.Marker(map, this.options.comment);
    comment.type = "comment";

    return [
      {
        enabled: this.options.polyline,
        handler: new Leaflet.Draw.Polyline(map, this.options.polyline),
        title: Leaflet.drawLocal.draw.toolbar.buttons.polyline,
      },
      {
        addCustomHandler: (
          container: any,
          buttonIndex: any,
          classNamePrefix: string,
          enabled: boolean
        ) =>
          getInput(
            container,
            buttonIndex,
            classNamePrefix,
            enabled,
            this.options.wallHeight || {}
          ),
        enabled: this.options.wallHeight,
        title: "wall height",
      },
      {
        enabled: this.options.polygon,
        handler: new Leaflet.Draw.Polygon(map, this.options.polygon),
        title: Leaflet.drawLocal.draw.toolbar.buttons.polygon,
      },
      {
        enabled: this.options.rectangle,
        handler: new Leaflet.Draw.Rectangle(map, this.options.rectangle),
        title: Leaflet.drawLocal.draw.toolbar.buttons.rectangle,
      },
      {
        enabled: this.options.deduction,
        handler: deduction,
        title: "cut",
      },
      {
        enabled: this.options.polylineDeduction,
        handler: polylineDeduction,
        title: "line cut",
      },
      {
        enabled: this.options.rectangleDeduction,
        handler: rectangleDeduction,
        title: "line cut",
      },
      {
        addCustomHandler: (
          container: any,
          buttonIndex: any,
          classNamePrefix: string,
          enabled: boolean
        ) =>
          getInput(
            container,
            buttonIndex,
            classNamePrefix,
            enabled,
            this.options.pitchAngle
          ),
        enabled: this.options.pitchAngle,
        title: "pitch angle",
      },
      {
        enabled: this.options.marker,
        handler: new Leaflet.Draw.Marker(map, this.options.marker),
        title: Leaflet.drawLocal.draw.toolbar.buttons.marker,
      },
      {
        enabled: this.options.comment,
        handler: comment,
        title: "Add comment",
      },
    ];
  },
});

const _hasAncestor = (el: any, cls: any) => {
  while ((el = el.parentElement) && !el.classList.contains(cls)) {}
  return el;
};

// Fix to prevent event being handled when clicking on a toolbar item
Leaflet.Draw.SimpleShape.prototype._onMouseDown = function (e: any) {
  const isToolbarEvent = _hasAncestor(
    e.originalEvent.srcElement,
    "plan-viewer-toolbar"
  );
  if (isToolbarEvent) {
    return;
  }

  this._isDrawing = true;
  this._startLatLng = e.latlng;

  Leaflet.DomEvent.on(document, "mouseup", this._onMouseUp, this)
    .on(document, "touchend", this._onMouseUp, this)
    .preventDefault(e.originalEvent);
};

// Fix to prevent event being handled when clicking on a toolbar item
Leaflet.Draw.Marker.prototype._onClick = function (e: any) {
  const isToolbarEvent =
    e?.originalEvent?.srcElement &&
    _hasAncestor(e.originalEvent.srcElement, "plan-viewer-toolbar");

  if (isToolbarEvent || e === undefined) {
    return;
  }
  this._fireCreatedEvent();

  this.disable();
  if (this.options.repeatMode) {
    this.enable();
  }
};

// Fix issue where marker is drawn when 2step value is being entered
Leaflet.Draw.Polyline.prototype._onMouseUp = function (e: any) {
  const isToolbarEvent = _hasAncestor(
    e.originalEvent.srcElement,
    "plan-viewer-toolbar"
  );
  if (isToolbarEvent) return;
  const originalEvent = e.originalEvent;
  const clientX = originalEvent.clientX;
  const clientY = originalEvent.clientY;
  this._endPoint.call(this, clientX, clientY, e);
  this._clickHandled = null;
};

// Fix to allow panning while drawing shape
Leaflet.Draw.Polyline.prototype._onTouch = L.Util.falseFn;

// Fix tooltip distance calculation (Polyline)
Leaflet.Draw.Polyline.prototype._updateRunningMeasure = function (
  latlng: LatLng,
  added: boolean
) {
  var markersLength = this._markers.length,
    previousMarkerIndex,
    distance;

  if (this._markers.length === 1) {
    this._measurementRunningTotal = 0;
  } else {
    const page = this._markers[0].page;
    if (!page) return;
    const calibration = this.options.calibrations[page - 1];

    previousMarkerIndex = markersLength - (added ? 2 : 1);
    distance = Leaflet.LineUtil._sqDistScale(
      latlng,
      this._markers[previousMarkerIndex].getLatLng(),
      calibration
    );
    this._measurementRunningTotal += distance * (added ? 1 : -1);
  }
};

Leaflet.Draw.Polyline.prototype._getMeasurementString = function () {
  var currentLatLng = this._currentLatLng,
    previousLatLng = this._markers[this._markers.length - 1].getLatLng(),
    distance;
  const page = this._markers[0].page;
  if (!page) return;
  const calibration = this.options.calibrations[page - 1];

  distance =
    this._measurementRunningTotal +
    Leaflet.LineUtil._sqDistScale(currentLatLng, previousLatLng, calibration);
  if (
    this.options.UOM === TakeOffMeasurement.LINEAR_METER &&
    Leaflet.Draw.Polyline.prototype.options.angle
  ) {
    distance = calculateDistanceWithAngle(
      distance,
      Leaflet.Draw.Polyline.prototype.options.angle
    );
  }
  if (
    this.options.UOM === TakeOffMeasurement.TONNE &&
    Leaflet.Draw.Polyline.prototype.options.weight
  ) {
    distance = calculateWeight(
      distance,
      Leaflet.Draw.Polyline.prototype.options.weight
    );
  }
  if (this.options.UOM === TakeOffMeasurement.METER_SQUARED) {
    distance *= Leaflet.Draw.Polyline.prototype.options.wallHeight;
  }

  const values = convertQuantity(distance, this.options.UOM);
  return `${values.quantity} ${values.UOM}`;
};

// Fix to allow map panning while drawing a rectangle
Leaflet.Draw.Rectangle.prototype._onMouseUp = function (e: any) {
  this._container.style.cursor = "crosshair";
  const isToolbarEvent =
    e?.originalEvent?.srcElement &&
    _hasAncestor(e.originalEvent.srcElement, "plan-viewer-toolbar");

  if (isToolbarEvent || e === undefined) {
    return;
  }

  this._isMouseDown = false;
  if (this._shouldIgnoreNextClick) {
    this._shouldIgnoreNextClick = false;

    return;
  }

  if (!this._isDrawing) {
    this._isDrawing = true;
    if (!this._startLatLng) {
      this._startLatLng = e.latlng;
    }
  }

  this._shouldIgnoreNextClick = false;

  if (!this._shape && !this._isCurrentlyTwoClickDrawing) {
    this._isCurrentlyTwoClickDrawing = true;
    return;
  }

  Leaflet.Draw.SimpleShape.prototype._onMouseUp.call(this);
  this._startLatLng = null;
  this._shape = null;
};

Leaflet.Draw.Rectangle.prototype._onMouseMove = function (e: any) {
  this._isMouseMoving = true;
  if (this._isMouseDown) {
    this._shouldIgnoreNextClick = true;
    this._container.style.cursor = "pointer";
  }
  var latlng = e.latlng;

  this._tooltip.updatePosition(latlng);
  if (this._isDrawing) {
    this._tooltip.updateContent(this._getTooltipText());
    this._drawShape(latlng);
  }
};

Leaflet.Draw.Rectangle.prototype._onMouseDown = function (e: any) {
  const isToolbarEvent =
    e?.originalEvent?.srcElement &&
    _hasAncestor(e.originalEvent.srcElement, "plan-viewer-toolbar");

  if (isToolbarEvent || e === undefined) {
    return;
  }
  this._isMouseDown = true;
};

Leaflet.Draw.Rectangle.prototype.addHooks = function () {
  Leaflet.Draw.Feature.prototype.addHooks.call(this);
  if (this._map) {
    this._mapDraggable = this._map.dragging.enabled();

    //TODO refactor: move cursor to styles
    this._container.style.cursor = "crosshair";

    this._tooltip.updateContent({ text: this._initialLabelText });

    this._map
      .on("mouseup", this._onMouseUp, this)
      .on("mousedown", this._onMouseDown, this)
      .on("mousemove", this._onMouseMove, this)
      .on("touchstart", this._onTouch, this)
      .on("touchmove", this._onMouseMove, this);

    // we should prevent default, otherwise default behavior (scrolling) will fire,
    // and that will cause document.touchend to fire and will stop the drawing
    // (circle, rectangle) in touch mode.
    // (update): we have to send passive now to prevent scroll, because by default it is {passive: true} now, which means,
    // handler can't event.preventDefault
    // check the news https://developers.google.com/web/updates/2016/06/passive-event-listeners
    document.addEventListener("touchstart", L.DomEvent.preventDefault, {
      passive: false,
    });
  }
};

Leaflet.Draw.Rectangle.prototype.removeHooks = function () {
  Leaflet.Draw.Feature.prototype.removeHooks.call(this);
  if (this._map) {
    if (this._mapDraggable) {
      this._map.dragging.enable();
    }

    //TODO refactor: move cursor to styles
    this._container.style.cursor = "";

    this._map
      .off("mouseup", this._onMouseUp, this)
      .off("mousedown", this._onMouseDown, this)
      .off("mousemove", this._onMouseMove, this)
      .off("touchstart", this._onMouseDown, this)
      .off("touchmove", this._onMouseMove, this);

    L.DomEvent.off(document as any, "mouseup", this._onMouseUp, this);
    L.DomEvent.off(document as any, "touchend", this._onMouseUp, this);

    document.removeEventListener("touchstart", L.DomEvent.preventDefault);

    // If the box element doesn't exist they must not have moved the mouse, so don't need to destroy/return
    if (this._shape) {
      this._map.removeLayer(this._shape);
      delete this._shape;
    }
  }
  this._isDrawing = false;
  this._isMouseDown = false;
  this._shouldIgnoreNextClick = false;
};

Leaflet.Draw.Rectangle.prototype.disable = function () {
  if (!this._enabled) {
    return;
  }

  this._isCurrentlyTwoClickDrawing = false;
  this._isDrawing = false;
  L.Draw.SimpleShape.prototype.disable.call(this);
};
// END: Fix to allow map panning while drawing a rectangle

// Fix tooltip distance calculation (Rectangle)
Leaflet.Draw.Rectangle.prototype._getTooltipText = function () {
  var tooltipText = Leaflet.Draw.SimpleShape.prototype._getTooltipText.call(
      this
    ),
    shape = this._shape,
    showArea = this.options.showArea,
    latLngs,
    area,
    subtext;

  if (shape) {
    const page = shape.page;
    const calibration = this.options.calibrations[page - 1];
    latLngs = this._shape._defaultShape
      ? this._shape._defaultShape()
      : this._shape.getLatLngs();

    const materialWidth =
      this.options.UOM === TakeOffMeasurement.LINEAR_METER
        ? this._shape.materialWidth ||
          Leaflet.Draw.Rectangle.prototype.options.materialWidth
        : 0;

    const angle =
      this.options.UOM === TakeOffMeasurement.METER_SQUARED
        ? this._shape.angle || Leaflet.Draw.Rectangle.prototype.options.angle
        : 0;

    const weight =
      this.options.UOM === TakeOffMeasurement.TONNE
        ? this._shape.weight || Leaflet.Draw.Rectangle.prototype.options.weight
        : 0;

    const depth =
      this.options.UOM === TakeOffMeasurement.CUBIC_METER
        ? this._shape.depth || Leaflet.Draw.Rectangle.prototype.options.depth
        : 0;

    area = geodesicArea(
      latLngs,
      calibration,
      angle || 0,
      depth || 0,
      materialWidth || 0,
      weight || 0
    );
    if (this.options.UOM === TakeOffMeasurement.LINEAR_METER) {
      area = area / 1000;
    }
    if (this.options.UOM === TakeOffMeasurement.TONNE) {
      area = area / 1000;
    }
    const values = convertQuantity(area, this.options.UOM);
    subtext = showArea ? `${values.quantity} ${values.UOM}` : "";
  }

  return {
    text: tooltipText.text,
    subtext: subtext,
  };
};

// Fix tooltip distance calculation (Polygon)
Leaflet.Draw.Polygon.prototype._getTooltipText = function () {
  var showLength = this.options.showLength,
    latLngs,
    text,
    subtext;

  if (!this._markers || this._markers.length === 0) {
    text = Leaflet.drawLocal.draw.handlers.polygon.tooltip.start;
  } else {
    if (this._markers.length < 3) {
      text = Leaflet.drawLocal.draw.handlers.polygon.tooltip.cont;
    } else {
      text = Leaflet.drawLocal.draw.handlers.polygon.tooltip.end;
    }
    latLngs = this._markers.map((marker: any) => marker.getLatLng());
    latLngs.push(this._currentLatLng);

    const angle =
      this.options.UOM === TakeOffMeasurement.METER_SQUARED
        ? reduce(this._markers, (value, marker) => marker.angle || value, 0) ||
          Leaflet.Draw.Polygon.prototype.options.angle
        : 0;

    const weight =
      this.options.UOM === TakeOffMeasurement.TONNE
        ? reduce(this._markers, (value, marker) => marker.weight || value, 0) ||
          Leaflet.Draw.Polygon.prototype.options.weight
        : 0;

    const depth =
      this.options.UOM === TakeOffMeasurement.CUBIC_METER
        ? reduce(this._markers, (value, marker) => marker.depth || value, 0) ||
          Leaflet.Draw.Polygon.prototype.options.depth
        : 0;

    const materialWidth =
      this.options.UOM === TakeOffMeasurement.LINEAR_METER
        ? reduce(
            this._markers,
            (value, marker) => marker.materialWidth || value,
            0
          ) || Leaflet.Draw.Polygon.prototype.options.materialWidth
        : 0;

    const page = this._markers[0].page;
    const calibration = this.options.calibrations[page - 1];

    let area = geodesicArea(
      latLngs,
      calibration,
      angle,
      depth,
      materialWidth,
      weight
    );
    if (this.options.UOM === TakeOffMeasurement.LINEAR_METER) {
      area = area / 1000;
    }
    const values = convertQuantity(area, this.options.UOM);

    subtext = showLength ? `${values.quantity} ${values.UOM}` : "";
  }

  return {
    text: text,
    subtext: subtext,
  };
};

export default Leaflet;
