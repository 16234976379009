import React from "react";
import moment from "moment";
import classnames from "classnames";
import { TFunction } from "i18next";
import { useHistory } from "react-router-dom";
import Icon from "../../icons/Icon";
import { SalesQuote } from "../../../models/salesQuote";
import "./styles.scss";
import { formatQuoteNumber } from "../../../utils/text";

type DashboardQuoteItemProps = {
  quote: SalesQuote;
  t: TFunction;
};
const DashboardQuoteItem: React.FC<DashboardQuoteItemProps> = (props) => {
  const { quote, t } = props;
  const history = useHistory();

  const stages = React.useMemo(
    () => ({
      costing: !!quote.total,
      files: !!quote.files?.length,
    }),
    [quote]
  );

  const icon = React.useMemo(() => {
    if (stages.costing) {
      return "price_change";
    } else if (stages.files) {
      return "highlight_alt";
    }
    return "upload_file";
  }, [stages]);

  const handleClick = React.useCallback(() => {
    if (stages.costing) {
      history.push(`/quotes/${quote._id}/costing`);
    } else if (stages.files) {
      history.push(`/quotes/${quote._id}/take-off`);
    } else {
      history.push(`/quotes/${quote._id}/plans`);
    }
  }, [stages, quote]);

  const stage = React.useMemo(() => {
    if (stages.costing) {
      return t("navigation.quotesSection.costing");
    } else if (stages.files) {
      return t("navigation.quotesSection.takeoff");
    } else {
      return t("navigation.quotesSection.plans");
    }
  }, [t, stages]);

  return (
    <div className={classnames("dashboard-quote")} onClick={handleClick}>
      <div className="quote-icon">
        <Icon name={icon} />
      </div>
      <div className="quote-message">
        <b>
          {formatQuoteNumber(quote.number, "Q")} {quote.name}
        </b>
        <span> {t("dashboard.atStage")} </span>
        <b>{stage}</b>
        <span
          className="quote-date"
          title={moment(quote.updatedAt).format("llll")}
        >
          {moment(quote.updatedAt).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default DashboardQuoteItem;
