import React, { ReactElement, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import {
  compact,
  find,
  isEmpty,
  map,
  forEach,
  size,
  each,
  reduce,
  chain,
  capitalize,
} from "lodash";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import GSTC, {
  GSTCResult,
  Item,
} from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import moment, { unitOfTime } from "moment-timezone";
import SVGIcon from "../../../components/icons/SVGIcon";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../../../components/dashboard/card/DashboardCardBody";
import DashboardCard from "../../../components/dashboard/card";
import CreateTaskGroupModal, {
  CreateTaskGroupModalRef,
} from "../../../components/job-schedule/create-schedule-group-modal";
import CalendarWrapper from "../../../components/calendar";
import Dropdown from "../../../components/dashboard/dropdown";
import SendModal from "../../../components/modals/send-modal";
import { createState } from "../../../components/calendar/utils";
import SortTaskModal, {
  SortTaskModalRef,
} from "../../../components/job-schedule/sort-schedule-modal";
import {
  checkStartEndCategory,
  convertLocalToUTCStartEndDate,
  createScheduleChart,
  createScheduleConfig,
  createScheduleRows,
  createScheduleSubscription,
  prepareCategory,
  prepareCategoryItem,
  updateChartBySizeDifference,
} from "./utils";
import {
  CREATE_UPDATE_JOB_SCHEDULE_CATEGORY,
  CREATE_UPDATE_JOB_SCHEDULE_ITEM,
  DELETE_JOB_SCHEDULE_CATEGORY,
  DELETE_JOB_SCHEDULE_ITEM,
  BULK_CREATE_UPDATE_JOB_SCHEDULE_CATEGORY,
  SEND_JOB_SCHEDULE,
  SEND_JOB_SCHEDULE_ITEM_REMINDER,
  BULK_UPDATE_JOB_SCHEDULE,
} from "../../../graphql/queries/job-schedule/mutations";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import {
  CreateTaskItemResponse,
  CreateUpdateJobTaskGroupResponse,
  DeleteScheduleCategoryItemResponse,
  DeleteScheduleCategoryResponse,
  ListJobSchedule,
  BulkCreateUpdateJobScheduleCategoryResponse,
  GetJobSchedulePreviewResponse,
  JobSendScheduleResponse,
  JobSendScheduleItemReminderResponse,
  BulkUpdateJobScheduleResponse,
} from "../../../graphql/types/models/job-schedule";
import { LIST_JOB_COSTINGS } from "../../../graphql/queries/job-costing/queries";
import { LIST_SUPPLIERS } from "../../../graphql/queries/supplier/queries";
import { ListJobCostingsResponse } from "../../../graphql/types/models/job-costing";
import { SupplierListResponse } from "../../../graphql/types/models/supplier";
import {
  EXPORT_JOB_SCHEDULE,
  GET_JOB_SCHEDULE,
  GET_JOB_SCHEDULE_PREVIEW,
} from "../../../graphql/queries/job-schedule/queries";
import {
  handleScheduleCategoryAdd,
  handleScheduleCategoryDelete,
  handleScheduleItemDelete,
  handleScheduleItemUpdate,
  handleScheduleCategoryImport,
  handleBulkUpdateSchedule,
} from "../../../graphql/queries/job-schedule/utils";
import CreateTaskModal, {
  CreateTaskModalRef,
} from "../../../components/job-schedule/create-schedule-task-modal";
import {
  CreateTaskGroupPayload,
  CreateTaskPayload,
  ScheduleCategory,
  ScheduleCategoryItem,
  BulkJobsScheduleCategoryPayload,
  UpdateTaskGroupPayload,
  ScheduleBulkAction,
  BulkUpdateSchedulePayload,
  ScheduleMoveDirection,
  ScheduleMovePeriod,
} from "../../../models/job";
import UpdateTaskGroupModal, {
  UpdateTaskGroupModalRef,
} from "../../../components/job-schedule/update-schedule-group-modal";
import UpdateTaskModal, {
  UpdateTaskModalRef,
} from "../../../components/job-schedule/update-schedule-task-modal";
import ConfirmDialog, {
  ConfirmDialogRef,
} from "../../../components/confirm-dialog";
import ImportScheduleFromCostingModal, {
  ImportScheduleFromCostingModalRef,
} from "../../../components/job-schedule/import-schedule-from-costing";
import { notify } from "../../../components/notification";
import JobLocationHeader from "../../header/job-location-header";
import ClientHeaderList from "../../header/client-header-list";
import "./styles.scss";
import EmptyPlaceholder, {
  ButtonConfig,
} from "../../../components/empty-placeholder";
import Avatar from "../../../components/avatar";
import { User } from "../../../graphql/types/models/user";
import { SupplierDetails } from "../../../models/supplier";
import { connect } from "react-redux";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { SendEmailForm } from "../../../models/email";
import { TeamMember, UserRoles } from "../../../models/team-member";
import TeammateHeaderList from "../../header/teammate-header-list";
import { printBase64Pdf } from "../../../utils/pdf";
import { useScheduleFilter } from "./hooks/useScheduleFilter";
import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import { useHistory, generatePath } from "react-router-dom";
import { ExportJobScheduleResponse } from "../../../models/job-schedule";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { useSelectRow } from "./hooks/useSelectRow";
import { useImportFromTemplate } from "./hooks/useImportFromTemplate";
import { useContextMenu } from "./hooks/useContextMenu";
import { useJobQuery } from "../../../hooks/queries/useJobQuery";

import UserDetailsModal, {
  UserDetailsModalRef,
} from "../../../components/job-schedule/user-details-modal";
import AssignTasksModal, {
  AssignTasksModalRef,
} from "../../../components/job-schedule/assign-tasks-modal";
import DatePicker from "../../../components/date-picker";
import { useTimelineContextMenu } from "./hooks/useContextMenuTimeline";
import { SystemFolderType } from "../../../models/documents";

type JobScheduleProps = DashboardContextValue & { user: UserPayload | null };

type Reminder = {
  contact: TeamMember | SupplierDetails;
  task: ScheduleCategoryItem;
};

const JobSchedule: React.FC<JobScheduleProps> = ({
  navigationContext,
  user,
}) => {
  const isLocked = navigationContext?.job?.isLocked || false;
  const jobId = navigationContext?.job?._id;
  const businessName = navigationContext?.job?.businessName;
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();

  const createCategoryRef = React.useRef<CreateTaskGroupModalRef>(null);
  const updateCategoryRef = React.useRef<UpdateTaskGroupModalRef>(null);
  const createTaskRef = React.useRef<CreateTaskModalRef>(null);
  const assignTasksRef = useRef<AssignTasksModalRef>(null);
  const userDetailsRef = useRef<UserDetailsModalRef>(null);
  const updateTaskRef = React.useRef<UpdateTaskModalRef>(null);
  const sortTaskRef = React.useRef<SortTaskModalRef>(null);
  const confirmDialogRef = React.useRef<ConfirmDialogRef>(null);
  const importScheduleRef = React.useRef<ImportScheduleFromCostingModalRef>(
    null
  );
  const sendReminderRef = React.useRef<ModalDisplayRef>(null);
  const sendEmailRef = React.useRef<ModalDisplayRef>(null);

  const [deleteTarget, setDeleteTarget] = useState({ isChild: false });
  const [reminder, setReminder] = useState<Reminder | null>(null);
  const [isDropdownShow, setDropdownVisibility] = useState(false);
  const [showCc, setShowCc] = React.useState(false);
  const [showBcc, setShowBcc] = React.useState(false);

  const { downloadFile } = useDownloadFile();
  const { job } = useJobQuery(jobId);
  const {
    renderImportFromTemplate,
    handleImportFromTemplate,
    renderSaveAsTemplate,
    handleSaveAsTemplate,
  } = useImportFromTemplate(jobId);

  const {
    data: jobSchedule,
    refetch: refetchTasks,
    loading: tasksLoading,
    updateQuery: updateSchedule,
    client: jobScheduleClient,
  } = useQuery<ListJobSchedule>(GET_JOB_SCHEDULE, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: jobCostingData, loading: costingLoading } = useQuery<
    ListJobCostingsResponse
  >(LIST_JOB_COSTINGS, {
    variables: {
      jobId,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: suppliersList } = useQuery<SupplierListResponse>(
    LIST_SUPPLIERS,
    {
      variables: {
        jobId,
      },
    }
  );

  const [sendJobSchedule] = useMutation<JobSendScheduleResponse>(
    SEND_JOB_SCHEDULE,
    {
      onCompleted: () => {
        sendEmailRef.current?.show(false);
        setShowBcc(false);
        setShowCc(false);
      },
      onError: () => {
        setShowBcc(false);
        setShowCc(false);
      },
    }
  );

  const [sendScheduleItemReminder] = useMutation<
    JobSendScheduleItemReminderResponse
  >(SEND_JOB_SCHEDULE_ITEM_REMINDER, {
    onCompleted: () => {
      sendReminderRef.current?.show(false);

      notify({
        title: t("schedule.sendReminder"),
        content: t("schedule.success.sendReminder"),
      });
      setShowBcc(false);
      setShowCc(false);
    },
    onError: () => {
      notify({
        error: true,
        title: t("schedule.sendReminder"),
        content: t("schedule.errors.sendReminder"),
      });
      setShowBcc(false);
      setShowCc(false);
    },
  });

  const [createJobTaskGroup, { client: createJobClient }] = useMutation<
    CreateUpdateJobTaskGroupResponse
  >(CREATE_UPDATE_JOB_SCHEDULE_CATEGORY, {
    onCompleted: () => {
      if (calendarApp) {
        calendarApp.reload();
      }
    },
    // update: handleScheduleCategoryAdd(jobId),
  });

  const [importScheduleFromCosting] = useMutation<
    BulkCreateUpdateJobScheduleCategoryResponse
  >(BULK_CREATE_UPDATE_JOB_SCHEDULE_CATEGORY);

  const [bulkUpdateJobSchedule] = useMutation<BulkUpdateJobScheduleResponse>(
    BULK_UPDATE_JOB_SCHEDULE,
    {
      onCompleted: () => {
        // Disabled as it's very slow when moving/resizing a large number of items
        // if (calendarApp) {
        //   calendarApp.reload();
        // }
        updateTaskRef.current?.show(false);
      },
      update: handleBulkUpdateSchedule(jobId),
    }
  );

  const [updateCategory] = useMutation<CreateUpdateJobTaskGroupResponse>(
    CREATE_UPDATE_JOB_SCHEDULE_CATEGORY,
    {
      onCompleted: () => {
        // updateCategoryRef.current?.isShowing() && refreshChart(); // disabled as it resets collapsed state
        updateCategoryRef.current?.show(false);
        applyScheduleFilter(calendarState);
      },
    }
  );

  const [deleteScheduleCategory, { loading: categoryDeleting }] = useMutation<
    DeleteScheduleCategoryResponse
  >(DELETE_JOB_SCHEDULE_CATEGORY, {
    update: handleScheduleCategoryDelete(jobId),
  });

  const [
    deleteScheduleCategoryItem,
    { loading: categoryItemDeleting },
  ] = useMutation<DeleteScheduleCategoryItemResponse>(DELETE_JOB_SCHEDULE_ITEM);

  const [createJobScheduleItem] = useMutation<CreateTaskItemResponse>(
    CREATE_UPDATE_JOB_SCHEDULE_ITEM,
    {
      onCompleted: () => {
        notify({
          title: t("schedule.addTask"),
          content: t("schedule.success.createTaskItem"),
        });
      },
    }
  );

  const [updateJobScheduleItem] = useMutation<CreateTaskItemResponse>(
    CREATE_UPDATE_JOB_SCHEDULE_ITEM,
    {
      onCompleted: () => {
        // updateTaskRef.current?.isShowing() && refreshChart(); // disabled as it resets collapsed state
        updateTaskRef.current?.show(false);
        applyScheduleFilter(calendarState);
      },
      update: handleScheduleItemUpdate(jobId),
    }
  );

  const openTaskGroupModal = React.useCallback(() => {
    createCategoryRef.current?.show(true);
  }, [createCategoryRef]);

  const openTimelineTaskGroupModal = React.useCallback(
    (id: string, time: { start: Date; end: Date }) => {
      createCategoryRef.current?.show(true, time);
    },
    []
  );

  const isReadonly = React.useMemo(() => user?.role === UserRoles.basic, [
    user,
  ]);

  const tasks = React.useMemo(() => {
    if (!jobSchedule?.getJobSchedule) return [];
    return reduce(
      jobSchedule?.getJobSchedule,
      (result: ScheduleCategoryItem[], category: ScheduleCategory) => {
        if (!category.items?.length) return result;
        return result.concat(category.items);
      },
      []
    );
  }, [jobSchedule]);

  const openUpdateGroupModal = React.useCallback(
    async (taskId: string) => {
      const categories = await getScheduleCategories();
      const group = find(categories, (c) => GSTC.api.GSTCID(c._id) === taskId);
      if (!group) return;
      const data = {
        ...prepareCategory(group),
        colour: group.colour || "#7d829e",
        updateItems: true,
      };
      updateCategoryRef.current?.show(true, data);
    },
    [jobSchedule, updateCategoryRef]
  );

  const openTaskModal = React.useCallback(
    async (parentId: string) => {
      const categories = await getScheduleCategories();
      const category = find(
        categories,
        (c) => GSTC.api.GSTCID(c._id) === parentId
      );
      if (!category) return;
      createTaskRef.current?.show(true, category);
    },
    [createTaskRef]
  );

  const openTimelineTaskModal = React.useCallback(
    async (id: string, parentId: string, time: { start: Date; end: Date }) => {
      const taskData = {
        start: time.start,
        end: time.end,
      };
      const categories = await getScheduleCategories();
      const category = find(categories, (c) => GSTC.api.GSTCID(c._id) === id);
      if (!category) {
        const parentCategory = find(
          categories,
          (c) => GSTC.api.GSTCID(c._id) === parentId
        );
        if (parentCategory) {
          createTaskRef.current?.show(true, parentCategory, taskData);
        }
        return;
      }
      createTaskRef.current?.show(true, category, taskData);
    },
    []
  );

  const openAssignTasksModal = React.useCallback(
    (checkedTasks: ScheduleCategoryItem[]) => {
      assignTasksRef.current?.show(true, checkedTasks);
    },
    [assignTasksRef]
  );

  const openUpdateTaskModal = React.useCallback(
    async (taskId: string, parentId?: string) => {
      const categories = await getScheduleCategories();
      const category = find(
        categories,
        (c) => GSTC.api.GSTCID(c._id) === parentId
      );
      const task = find(
        category?.items,
        (i) => GSTC.api.GSTCID(i._id) === taskId
      );

      if (!category || !task) {
        return null;
      }

      const data = {
        ...prepareCategoryItem(task),
        supplier: task.supplier?._id || "",
        user: task.user?._id || "",
        predecessor: task.predecessor?._id || "",
      };

      updateTaskRef.current?.show(true, data, category);
    },
    [updateTaskRef]
  );

  const openNudgeModal = React.useCallback(
    async (taskId: string, parentId?: string) => {
      const categories = await getScheduleCategories();
      const category = find(
        categories,
        (c) => GSTC.api.GSTCID(c._id) === parentId
      );
      const task = find(
        category?.items,
        (i) => GSTC.api.GSTCID(i._id) === taskId
      );
      if (!task?.user && !task?.supplier) return;
      const contact = ((task.user as any) as TeamMember) || task.supplier;
      setReminder({
        contact,
        task,
      });
      sendReminderRef.current?.show(true);
    },
    [sendReminderRef, setDeleteTarget]
  );
  const openDeleteDialog = React.useCallback(
    (scheduleId: string, parentId?: string) => {
      setDeleteTarget({ isChild: !!parentId });
      confirmDialogRef.current?.show(true, () =>
        handleCategoryDelete(scheduleId, parentId)
      );
    },
    [confirmDialogRef, setDeleteTarget]
  );

  // React + Gantt (Non-react) callbacks don't work well.
  // We need to get the latest data directly from the apollo cache
  const getScheduleCategories = React.useCallback(async () => {
    const cacheData = await jobScheduleClient.query<ListJobSchedule>({
      query: GET_JOB_SCHEDULE,
      variables: {
        jobId,
      },
    });
    return cacheData?.data?.getJobSchedule;
  }, [jobScheduleClient, jobId]);

  const calendarStateRef = useRef();
  const appRef = useRef<GSTCResult | null>(null);

  const onTaskMoveResize = React.useCallback(
    async (tasks: Item[]) => {
      const categories = await getScheduleCategories();
      const categoriesToResize = {} as any;
      const updateCategories: ScheduleCategory[] = [];
      const updateItems: ScheduleCategoryItem[] = [];
      Promise.all(
        map(tasks, async (task) => {
          const category = find(
            categories,
            (c) =>
              GSTC.api.GSTCID(c._id) === task.parentId ||
              GSTC.api.GSTCID(c._id) === task.id
          );

          if (!category) return;
          const startDate = moment(task.time.start);
          const endDate = moment(task.time.end);
          // update sub task
          if (task.parentId) {
            const item = find(
              category.items,
              (i) => GSTC.api.GSTCID(i._id) === task.id
            );
            if (!item) return;

            if (
              !startDate.isSame(item.startDate) ||
              !endDate.isSame(item.endDate)
            ) {
              const { minDate, maxDate } = checkStartEndCategory(
                category?.items,
                {
                  min: startDate,
                  max: endDate,
                  id: item._id,
                }
              );
              categoriesToResize[category._id] = {
                ...category,
                startDate: minDate,
                endDate: maxDate,
              };
              updateItems.push(
                prepareCategoryItem({
                  ...item,
                  startDate: startDate.toISOString(),
                  endDate: endDate.toISOString(),
                })
              );
            }
          } else {
            // update parent (category) task
            if (
              !startDate.isSame(category.startDate) ||
              !endDate.isSame(category.endDate)
            ) {
              categoriesToResize[category._id] = {
                ...category,
                startDate: moment.min(
                  startDate,
                  moment(
                    categoriesToResize[category._id]?.startDate || startDate
                  )
                ),
                endDate: moment.max(
                  endDate,
                  moment(categoriesToResize[category._id]?.endDate || endDate)
                ),
              };
            }
          }
        })
      );

      each(categoriesToResize, (category) => {
        const { startDate, endDate } = convertLocalToUTCStartEndDate(
          category.startDate,
          category.endDate
        );
        const time = {
          start: startDate,
          end: endDate,
          // start: moment(category.startDate).utc(),
          // end: moment(category.endDate).utc(),
        };
        (calendarStateRef.current as any)?.update(
          `config.chart.items.${GSTC.api.GSTCID(category._id)}.time`,
          {
            start: time.start.unix() * 1000,
            end: time.end.unix() * 1000,
          }
        );

        updateCategories.push(
          prepareCategory({
            ...category,
            startDate: time.start.toISOString(),
            endDate: time.end.toISOString(),
          })
        );
      });

      if (updateItems.length || updateCategories.length) {
        bulkUpdateJobSchedule({
          variables: {
            jobId,
            categories: updateCategories,
            items: updateItems,
            action: capitalize(ScheduleBulkAction.RESCHEDULE),
          },
        });
      }
    },
    [
      jobId,
      jobSchedule,
      updateJobScheduleItem,
      updateCategory,
      calendarStateRef,
    ]
  );

  const renderAvatar = (
    user?: User,
    supplier?: SupplierDetails
  ): ReactElement => {
    if (!user && !supplier) return <div />;

    const name = user
      ? compact([user.first_name, user.last_name]).join(" ")
      : supplier?.business_name;

    return (
      <Avatar
        rounded
        height="24px"
        width="24px"
        // url={logo}
        iconSize="24px"
        userName={name}
      />
    );
  };

  const handleChangeItem = React.useCallback(
    async (
      scheduleItem: CreateTaskPayload | ScheduleCategoryItem,
      category: ScheduleCategory
    ) => {
      const categories = [];
      const items = [];

      const changedCategory = {
        _id: category._id,
        name: category.name,
        startDate: category.startDate,
        endDate: category.endDate,
        ...(category.colour && { colour: category.colour }),
      };

      const { minDate, maxDate } = checkStartEndCategory(category?.items, {
        min: moment(scheduleItem.startDate),
        max: moment(scheduleItem.endDate),
        id: scheduleItem?._id || "",
      });

      changedCategory.startDate = minDate.toISOString();
      changedCategory.endDate = maxDate.toISOString();
      categories.push(changedCategory);

      items.push({
        ...scheduleItem,
        reminder: {
          daysBefore: Number(scheduleItem.reminder?.daysBefore) || 0,
          timezone: moment.tz.guess(),
        },
      });

      return bulkUpdateJobSchedule({
        variables: {
          jobId,
          categories,
          items,
          action: capitalize(ScheduleBulkAction.RESCHEDULE),
        },
      });
    },
    [jobId]
  );

  const handleDateUpdate = React.useCallback(
    async (id: string, parentId: string, date: string, dateType: string) => {
      const schedule = await getScheduleCategories();
      const dateField =
        dateType === "startDate" ? { startDate: date } : { endDate: date };

      if (parentId) {
        const category = find(schedule, { _id: parentId.split("-")[1] });
        const scheduleItem = find(category?.items, { _id: id.split("-")[1] });

        if (category && scheduleItem) {
          handleChangeItem(
            prepareCategoryItem({ ...scheduleItem, ...dateField }),
            category
          );
        }
      } else {
        const scheduleCategory = {
          _id: id.split("-")[1],
          ...dateField,
        };
        updateCategory({
          variables: {
            jobId,
            scheduleCategory,
          },
        });
      }
    },
    [getScheduleCategories, jobId, updateCategory, updateJobScheduleItem]
  );

  const renderDatePicker = React.useCallback(
    (
      date: string,
      onDateSelect: (value: string) => void,
      values: {
        id: string;
        parentId: string;
        dateType: string;
      }
    ): ReactElement => {
      const { id, parentId, dateType } = values;

      const getMinMaxDates = async () => {
        const minMaxDates: { min: Date | null; max: Date | null } = {
          min: null,
          max: null,
        };
        const categories = await getScheduleCategories();

        if (parentId) {
          const category = find(
            categories,
            (c) => GSTC.api.GSTCID(c._id) === parentId
          );
          const scheduleItem = find(category?.items, { _id: id.split("-")[1] });

          if (category && scheduleItem) {
            const catStartDate = new Date(category?.startDate);
            const catEndDate = new Date(category?.endDate);
            const itemStartDay = new Date(scheduleItem.startDate);
            const itemEndDay = new Date(scheduleItem.endDate);

            if (dateType === "startDate") {
              // minMaxDates.min = catStartDate;
              minMaxDates.max = itemEndDay;
            }
            if (dateType === "endDate") {
              minMaxDates.min = itemStartDay;
              // minMaxDates.max = catEndDate;
            }
          }

          return minMaxDates;
        } else {
          const category = find(
            categories,
            (c) => GSTC.api.GSTCID(c._id) === id
          );

          if (category) {
            if (dateType === "startDate") {
              minMaxDates.max = new Date(category?.endDate);
            }
            if (dateType === "endDate") {
              minMaxDates.min = new Date(category?.startDate);
            }
          }

          return minMaxDates;
        }
      };

      const handleDateSelect = async (value: string) => {
        onDateSelect(value);
        handleDateUpdate(id, parentId, value, dateType);

        return value;
      };

      return (
        <DatePicker
          value={date}
          onChange={handleDateSelect}
          className="datePickerStyle"
          portalId="datePicker-portal"
          setDatesByCategory={getMinMaxDates}
        />
      );
    },
    [getScheduleCategories, handleDateUpdate]
  );

  const getRescheduledDate = (
    date: string,
    values: BulkUpdateSchedulePayload
  ) => {
    const { move } = values;
    if (!move) return date;
    const { moveNumber, period, direction } = move;
    let momentPeriod = "days";
    if (period === ScheduleMovePeriod.WEEKS) {
      momentPeriod = "weeks";
    } else if (period === ScheduleMovePeriod.MONTHS) {
      momentPeriod = "months";
    }
    if (direction === ScheduleMoveDirection.FORWARD) {
      return moment(date)
        .add(Math.abs(moveNumber), momentPeriod as unitOfTime.Base)
        .toISOString();
    }
    return moment(date)
      .subtract(Math.abs(moveNumber), momentPeriod as unitOfTime.Base)
      .toISOString();
  };

  const handleBulkAction = React.useCallback(
    async (values: BulkUpdateSchedulePayload, rows: any) => {
      const rowCategoryIds = chain(rows)
        .filter((r) => !r.parentId)
        .map((r) => r.id.split("-")[1])
        .value();
      const rowItemIds = chain(rows)
        .filter((r) => !!r.parentId)
        .map((r) => r.id.split("-")[1])
        .value();

      const schedule = await getScheduleCategories();
      const categories: ScheduleCategory[] = [];
      const items: ScheduleCategoryItem[] = [];

      if (values.action === ScheduleBulkAction.RESCHEDULE) {
        each(schedule, (category) => {
          if (rowCategoryIds.indexOf(category._id) >= 0) {
            categories.push({
              ...prepareCategory(category),
              startDate: getRescheduledDate(category.startDate, values),
              endDate: getRescheduledDate(category.endDate, values),
            });
          }
          each(category.items, (item) => {
            if (rowItemIds.indexOf(item._id) >= 0) {
              items.push({
                ...prepareCategoryItem(item),
                startDate: getRescheduledDate(item.startDate, values),
                endDate: getRescheduledDate(item.endDate, values),
              });
            }
          });
        });
      }
      if (values.action === ScheduleBulkAction.COMPLETE) {
        each(schedule, (category) => {
          each(category.items, (item) => {
            if (rowItemIds.indexOf(item._id) >= 0) {
              items.push({
                ...prepareCategoryItem(item),
                progress: 100,
              });
            }
          });
        });
      }
      if (values.action === ScheduleBulkAction.DELETE) {
        each(schedule, (category) => {
          if (rowCategoryIds.indexOf(category._id) >= 0) {
            categories.push(prepareCategory(category));
          }
          each(category.items, (item) => {
            if (rowItemIds.indexOf(item._id) >= 0) {
              items.push(prepareCategoryItem(item));
            }
          });
        });
      }
      await bulkUpdateJobSchedule({
        variables: {
          jobId,
          categories,
          items,
          action: capitalize(values.action),
        },
      });
    },
    [getScheduleCategories, jobId]
  );

  const handleProgressChange = React.useCallback(
    async (id: string, parentId: string, progress: number) => {
      const schedule = await getScheduleCategories();
      const category = find(schedule, { _id: parentId.split("-")[1] });
      const scheduleItem = find(category?.items, { _id: id.split("-")[1] });
      if (category && scheduleItem) {
        // UI update
        // TODO: update category progress
        const calendarState = calendarStateRef.current as any;
        const item = calendarState?.get(`config.chart.items.${id}`);
        const updateTask = {
          ...scheduleItem,
          progress,
        };
        console.log(updateTask);

        const itemTask = [updateTask];
        const categoryTask = [
          {
            ...category,
            items: category.items?.map((item) => {
              if (item._id === scheduleItem._id) {
                return updateTask;
              }
              return item;
            }),
          },
        ];
        const itemTasks = createScheduleChart(itemTask, category);
        const categoryTasks = createScheduleChart(categoryTask);
        const categoryRows = createScheduleRows(categoryTask, t);
        calendarState?.update(`config.chart.items.${id}`, {
          ...item,
          label: itemTasks[id].label,
        });
        calendarState?.update(
          `config.chart.items.${parentId}`,
          categoryTasks[parentId]
        );
        calendarState?.update(
          `config.list.rows.${id}.progress`,
          t("schedule.progressAmount", { amount: progress })
        );
        calendarState?.update(
          `config.list.rows.${parentId}`,
          categoryRows[parentId]
        );
        updateJobScheduleItem({
          variables: {
            jobId,
            categoryId: category._id,
            scheduleItem: {
              ...prepareCategoryItem(scheduleItem),
              progress,
            },
          },
        });
      }
    },
    [getScheduleCategories, t, updateJobScheduleItem, jobId]
  );

  const {
    handleSelectRow,
    handleSelectAllRows,
    renderWithSelected,
  } = useSelectRow(calendarStateRef, handleBulkAction, openAssignTasksModal);

  const {
    handleClearContext,
    handleContextAction,
    handleSelectContext,
    renderContextMenu,
  } = useContextMenu(
    jobId,
    appRef,
    calendarStateRef,
    getScheduleCategories,
    bulkUpdateJobSchedule
  );

  const {
    handleTimelineContextAction,
    handleTimelineSelectContext,
    renderTimelineContextMenu,
  } = useTimelineContextMenu(
    appRef,
    openTimelineTaskGroupModal,
    openTimelineTaskModal
  );

  const createCalendarState = React.useCallback(() => {
    if (!job) return null;
    return createState(
      createScheduleConfig(
        t,
        job,
        isReadonly,
        openTaskGroupModal,
        openUpdateGroupModal,
        openTaskModal,
        openUpdateTaskModal,
        openNudgeModal,
        openDeleteDialog,
        onTaskMoveResize,
        renderAvatar,
        handleProgressChange,
        handleSelectRow,
        handleSelectAllRows,
        {
          handleContextAction,
          handleSelectContext,
          handleClearContext,
        },
        renderDatePicker,
        userDetailsRef,
        {
          handleTimelineContextAction,
          handleTimelineSelectContext,
        }
      )
    );
  }, [job]);

  const [calendarState, setCalendarState] = useState<any | null>(null);
  const [calendarApp, setApp] = useState<GSTCResult | null>(null);

  React.useEffect(() => {
    const state = createCalendarState();
    calendarStateRef.current = state;
    setCalendarState(state);
    createScheduleSubscription(t, state);
    getScheduleCategories();
  }, []);

  const resetCalendarState = React.useCallback(() => {
    const state = createCalendarState();
    calendarStateRef.current = state;
    setCalendarState(state);
    return state;
  }, [setCalendarState]);

  // Reset state once all categories are removed to prevent crashing
  React.useEffect(() => {
    if (
      jobSchedule?.getJobSchedule &&
      jobSchedule?.getJobSchedule.length === 0
    ) {
      resetCalendarState();
    }
  }, [jobSchedule?.getJobSchedule]);

  const {
    applyScheduleFilter,
    renderAssigneeFilter,
    renderCategoryFilter,
    scheduleFilter,
  } = useScheduleFilter(calendarState, tasks, jobSchedule?.getJobSchedule);

  const handleTaskGroupSubmit = React.useCallback(
    async (
      scheduleCategory: CreateTaskGroupPayload,
      createAnother: boolean
    ) => {
      const response = await createJobTaskGroup({
        variables: {
          jobId,
          scheduleCategory,
        },
        update: () => {
          notify({
            title: t("schedule.addScheduleCategory"),
            content: t("schedule.success.createTaskGroup"),
          });
        },
      });
      // Disabled as it appeared to cause blank rows on first category creation
      // if (response.data?.jobCreateUpdateScheduleCategory) {
      //   const item = response.data?.jobCreateUpdateScheduleCategory;
      //   const data = [item];
      //   const task = createScheduleChart(data);
      //   const row = createScheduleRows(data, t);
      //   const id = item._id;
      //   calendarState.update(`config.chart.items.${id}`, task[id]);
      //   calendarState.update(`config.list.rows.${id}`, row[id]);
      // }
      handleScheduleCategoryAdd(jobId)(createJobClient as any, response);
      applyScheduleFilter(calendarState);

      if (!createAnother) {
        createCategoryRef.current?.show(false);
      }
    },
    [jobId, createCategoryRef, createJobTaskGroup, calendarState]
  );

  const handleTaskSubmit = React.useCallback(
    async (
      scheduleItem: CreateTaskPayload,
      category: ScheduleCategory,
      createAnother: boolean
    ) => {
      const categoryId = category._id;
      const response = await createJobScheduleItem({
        variables: {
          jobId,
          categoryId,
          scheduleItem: {
            ...scheduleItem,
            supplier: scheduleItem.supplier || null,
            predecessor: scheduleItem.predecessor || null,
            reminder: {
              daysBefore: Number(scheduleItem.reminder?.daysBefore) || 0,
              timezone: moment.tz.guess(),
            },
          },
        },
      });
      const createdTask = response.data?.jobCreateUpdateScheduleItem;

      if (!createAnother) {
        createTaskRef.current?.show(false);
      }

      if (!createdTask) {
        return;
      }

      const { minDate, maxDate } = checkStartEndCategory(category?.items, {
        min: moment(createdTask.startDate),
        max: moment(createdTask.endDate),
        id: createdTask?._id || "",
      });
      if (
        !minDate.isSame(category.startDate) ||
        !maxDate.isSame(category.endDate)
      ) {
        const updateCategory = prepareCategory(category);
        bulkUpdateJobSchedule({
          variables: {
            jobId,
            categories: [
              { ...updateCategory, startDate: minDate, endDate: maxDate },
            ],
            action: capitalize(ScheduleBulkAction.RESCHEDULE),
          },
        });
      }

      if (response.data?.jobCreateUpdateScheduleItem) {
        const item = response.data?.jobCreateUpdateScheduleItem;
        const data = [item];
        const task = createScheduleChart(data, category);
        const row = createScheduleRows(data, t, category);
        const id = GSTC.api.GSTCID(item._id);
        calendarState.update(`config.list.rows.${id}`, row[id]);
        calendarState.update(`config.chart.items.${id}`, task[id]);
        applyScheduleFilter(calendarState);
      }
    },
    [jobId, createTaskRef, createJobScheduleItem, calendarState]
  );
  const handleAssignTasksSubmit = React.useCallback(
    async (scheduleItems: ScheduleCategoryItem[]) => {
      await bulkUpdateJobSchedule({
        variables: {
          jobId,
          items: scheduleItems,
          action: capitalize(ScheduleBulkAction.ASSIGN),
        },
      });
      assignTasksRef.current?.close(false);
      notify({
        title: t("schedule.assignTasks"),
        content: t("schedule.success.bulkAssign"),
      });
    },
    [bulkUpdateJobSchedule, jobId, t]
  );

  const handleCategoryDelete = React.useCallback(
    async (scheduleId: string, parentId?: string) => {
      if (!scheduleId) {
        return;
      }

      const notificationTransKey = parentId ? "deleteTask" : "deleteTaskGroup";
      const id = GSTC.api.isGSTCID(scheduleId)
        ? scheduleId.split("-")[1]
        : scheduleId;
      const parent =
        parentId && GSTC.api.isGSTCID(parentId)
          ? parentId.split("-")[1]
          : parentId;

      try {
        if (!parentId) {
          await deleteScheduleCategory({
            variables: {
              jobId,
              scheduleCategoryId: id,
            },
          });
        } else {
          const response = await deleteScheduleCategoryItem({
            variables: {
              jobId,
              scheduleItemId: id,
            },
          });

          if (!response) {
            return;
          }

          updateSchedule((previousQueryResult) => {
            const deletedItem = response.data?.jobDeleteScheduleItem;

            if (!deletedItem) {
              return previousQueryResult;
            }

            return handleScheduleItemDelete(
              previousQueryResult,
              deletedItem._id,
              parent
            );
          });
        }

        notify({
          title: t(`schedule.${notificationTransKey}`),
          content: t(`schedule.success.${notificationTransKey}`),
        });
      } catch (e) {
        notify({
          error: true,
          title: t(`schedule.${notificationTransKey}`),
          content: t(`schedule.errors.${notificationTransKey}`),
        });
      }
      confirmDialogRef.current?.show(false);
    },
    [
      confirmDialogRef,
      deleteScheduleCategoryItem,
      deleteScheduleCategory,
      jobId,
      updateSchedule,
    ]
  );

  const refreshChart = React.useCallback(() => {
    const tasksData = jobSchedule?.getJobSchedule;
    if (!tasksData || isEmpty(tasksData)) return;

    const chartData = createScheduleChart(tasksData);
    const rows = createScheduleRows(tasksData, t);
    calendarState.update("config.list.rows", rows);
    calendarState.update("config.chart.items", chartData);
    applyScheduleFilter(calendarState);

    const scrollY = calendarApp?.api.getScrollTop();
    // calendarApp?.api.setScrollTop(0); // force redraw
    scrollY && calendarApp?.api.setScrollTop(scrollY.dataId); // force redraw to current position
  }, [calendarApp, jobSchedule, calendarState, resetCalendarState]);

  React.useEffect(() => {
    const scheduleCategories = jobSchedule?.getJobSchedule;

    if (!calendarApp || !scheduleCategories || isEmpty(scheduleCategories)) {
      return;
    }

    const chartData = createScheduleChart(scheduleCategories);

    const firstTask = Object.keys(chartData)[0];
    let endDate = chartData[firstTask].time.end; //Number.MIN_SAFE_INTEGER;
    let startDate = chartData[firstTask].time.start; //Number.MAX_SAFE_INTEGER;

    forEach(chartData, (task) => {
      if (endDate < task.time.end) {
        endDate = task.time.end;
      }

      if (startDate > task.time.start) {
        startDate = task.time.start;
      }
    });
    const mStartDate = moment(startDate).isAfter(new Date())
      ? moment()
      : moment(startDate);
    const mEndDate = moment(endDate);
    const jobStartDate = job?.startDate ? moment(job.startDate) : null;
    const jobEndDate = job?.endDate ? moment(job.endDate) : null;
    if (jobStartDate && mStartDate.isAfter(jobStartDate)) {
      mStartDate.set(jobStartDate.toObject());
    }
    if (jobEndDate && mEndDate.isBefore(jobEndDate)) {
      mEndDate.set(jobEndDate.toObject());
    }
    mStartDate.subtract(3, "days");
    mEndDate.add(3, "days");

    calendarState.update("config", (config: any) => {
      // only update the rows/items if size has changed (better UX)
      const rows = createScheduleRows(scheduleCategories, t);

      const currentRows = config.list.rows;
      const currentItems = config.chart.items;
      const isPlaceholder =
        currentRows.length === 0 || !!currentRows["placeholder"];

      // the following code sometimes causes crashes
      config.chart.time.to = +mEndDate;
      config.chart.time.from = +mStartDate;
      // config.chart.time.additionalSpaces = {
      //   hour: { before: 24, after: 24, period: "hour" },
      //   day: { before: 7, after: 28, period: "day" },
      // };

      if (isPlaceholder || size(currentRows) !== size(rows)) {
        updateChartBySizeDifference(config.list.rows, currentRows, rows);
      }

      if (isPlaceholder || size(currentItems) !== size(chartData)) {
        updateChartBySizeDifference(
          config.chart.items,
          currentItems,
          chartData
        );
      }
      return config;
    });
    applyScheduleFilter(calendarState);

    // calendarState.update("config.chart.time",
    //   getChartTime(tasksData)
    // );
  }, [
    calendarApp,
    t,
    job,
    jobSchedule,
    calendarState,
    openTaskGroupModal,
    openDeleteDialog,
    openTaskModal,
    openUpdateGroupModal,
    openUpdateTaskModal,
  ]);

  React.useEffect(() => {
    if (!calendarApp) {
      return;
    }
    calendarApp.api.scrollToTime(
      parseInt(moment().startOf("day").format("x")),
      false
    );
  }, [calendarApp]);

  const handleTimelineToggle = React.useCallback(() => {
    const value = calendarState.get("config.list.columns.percent");
    calendarState.update("config.list.columns.percent", !value ? 100 : 0);
  }, [calendarState]);

  const handleZoomIn = React.useCallback(() => {
    if (calendarState.get("config.chart.time.zoom") === 18) return; // it breaks going below 18
    calendarState.update(
      "config.chart.time.zoom",
      calendarState.get("config.chart.time.zoom") - 1
    );
  }, [calendarState]);

  const handleZoomOut = React.useCallback(() => {
    calendarState.update(
      "config.chart.time.zoom",
      calendarState.get("config.chart.time.zoom") + 1
    );
  }, [calendarState]);

  const handleSort = React.useCallback(() => {
    sortTaskRef.current?.show(true);
  }, [sortTaskRef]);

  const handleCalendarCreate = React.useCallback(
    (app: any) => {
      setApp(app);
      appRef.current = app;
    },
    [jobSchedule, onTaskMoveResize]
  );

  const handleGroupUpdateSubmit = React.useCallback(
    async (scheduleCategory: UpdateTaskGroupPayload) => {
      const response = await updateCategory({
        variables: {
          jobId,
          scheduleCategory,
        },
      });
      // disabled as it doesn't seem to be required anymore (due to refactor?)
      // if (response.data?.jobCreateUpdateScheduleCategory) {
      //   const category = response.data.jobCreateUpdateScheduleCategory;
      //   const data = [category];
      //   const tasks = createScheduleChart(data);
      //   const rows = createScheduleRows(data, t);
      //   calendarState.update("config", (config: any) => {
      //     for (let id of Object.keys(rows)) {
      //       config.list.rows[id] = rows[id];
      //       config.chart.items[id] = tasks[id];
      //     }
      //     return config;
      //   });
      // }
    },
    [jobId, updateCategory, calendarState]
  );

  const handleTaskUpdateSubmit = React.useCallback(
    (scheduleItem: CreateTaskPayload, category: ScheduleCategory) => {
      handleChangeItem(scheduleItem, category);
    },
    [jobId, updateJobScheduleItem]
  );

  const handleImportSpreadsheet = React.useCallback(() => {
    jobId &&
      history.push(
        generatePath("/jobs/:id/schedule/import", {
          id: jobId,
        })
      );
  }, [history, jobId]);

  const handleExportSpreadsheet = React.useCallback(async () => {
    try {
      const result = await client.query<ExportJobScheduleResponse>({
        query: EXPORT_JOB_SCHEDULE,
        variables: {
          jobId,
        },
        fetchPolicy: "network-only",
      });
      const exportData = result?.data?.exportJobSchedule;
      downloadFile(exportData.data, exportData.filename);
    } catch (e) {}
  }, [jobId, client]);

  const openImportScheduleModal = React.useCallback(
    () => importScheduleRef.current?.show(true),
    [importScheduleRef]
  );

  const closeImportScheduleModal = React.useCallback(
    () => importScheduleRef.current?.show(false),
    [importScheduleRef]
  );

  const handleImportSchedule = React.useCallback(
    async (items: BulkJobsScheduleCategoryPayload[]) => {
      try {
        if (!jobId) return;

        await importScheduleFromCosting({
          variables: {
            jobId,
            scheduleCategories: items,
          },
          update: handleScheduleCategoryImport(jobId),
        });

        notify({
          title: t("schedule.importFromCostings"),
          content: t("schedule.success.importFromCostings"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("schedule.importFromCostings"),
          content: t("schedule.errors.importFromCostings"),
        });
      }
    },
    [t, jobId, importScheduleFromCosting]
  );

  const deleteDialogTitle = !deleteTarget.isChild
    ? t("schedule.deleteTaskGroup")
    : t("schedule.deleteTask");
  const deleteDialogMessage = !deleteTarget.isChild
    ? t("schedule.deleteTaskGroupMessage")
    : t("schedule.deleteTaskMessage");

  const buttonConfig = React.useMemo<ButtonConfig>(
    () => [
      {
        buttonText: t("schedule.addTaskGroup"),
        onButtonPress: openTaskGroupModal,
      },
      {
        buttonIcon: "article",
        buttonText: t("schedule.importFromTemplate"),
        onButtonPress: handleImportFromTemplate,
      },
      {
        buttonIcon: "attach_money",
        buttonText: t("schedule.importFromCostings"),
        onButtonPress: openImportScheduleModal,
      },
      {
        buttonIcon: "view_list",
        buttonText: t("schedule.importFromSpreadsheet"),
        onButtonPress: handleImportSpreadsheet,
      },
    ],
    [t, openTaskGroupModal, openImportScheduleModal, handleImportSpreadsheet]
  );

  const handleToggle = React.useCallback(() => {
    setDropdownVisibility(!isDropdownShow);
  }, [isDropdownShow]);

  const openEmailModal = React.useCallback(() => {
    sendEmailRef.current?.show(true);
  }, [sendEmailRef]);

  const copyMailOptions = React.useMemo(() => {
    return {
      showCc,
      setShowCc,
      showBcc,
      setShowBcc,
      showReplyTo: true,
    };
  }, [showBcc, showCc]);

  const handlePreviewPdf = React.useCallback(async () => {
    try {
      if (!jobId) return;

      const pdfPreview = await client.query<GetJobSchedulePreviewResponse>({
        query: GET_JOB_SCHEDULE_PREVIEW,
        variables: {
          jobId,
          filter: {
            assignee: scheduleFilter.assignee?._id,
            assigneeType: scheduleFilter.assigneeType,
            category: scheduleFilter.category?._id,
          },
        },
        fetchPolicy: "network-only",
      });

      printBase64Pdf(pdfPreview?.data?.getJobSchedulePreview.pdf);
    } catch (err) {
      notify({
        error: true,
        title: t("schedule.printSchedule"),
        content: t("schedule.errors.printSchedule"),
      });
    }
  }, [jobId, client, scheduleFilter]);

  const handleSendSchedule = React.useCallback(
    async (mail: SendEmailForm) => {
      try {
        await sendJobSchedule({
          variables: {
            jobId,
            message: {
              subject: mail.title,
              to: mail.to,
              ...(showCc && { cc: mail.cc }),
              ...(showBcc && { bcc: mail.bcc }),
              ...(mail.replyTo && { replyTo: mail.replyTo }),
              message: mail.message,
              remoteAttachmentId: mail.remoteAttachmentId,
            },
            filter: {
              assignee: scheduleFilter.assignee?._id,
              assigneeType: scheduleFilter.assigneeType,
              category: scheduleFilter.category?._id,
            },
          },
        });

        notify({
          title: t("schedule.sendSchedule"),
          content: t("schedule.success.sendSchedule"),
        });
      } catch (e) {
        notify({
          error: true,
          title: t("schedule.sendSchedule"),
          content: t("schedule.errors.sendSchedule"),
        });
      }
      sendEmailRef.current?.show(false);
    },
    [jobId, scheduleFilter, sendJobSchedule, sendEmailRef, showCc, showBcc]
  );

  const handleSendReminder = React.useCallback(
    async (mail: SendEmailForm) => {
      if (!reminder?.task) return;
      sendScheduleItemReminder({
        variables: {
          jobId,
          scheduleItemId: reminder.task._id,
          message: {
            subject: mail.title,
            to: mail.to,
            ...(showCc && { cc: mail.cc }),
            ...(showBcc && { bcc: mail.bcc }),
            ...(mail.replyTo && { replyTo: mail.replyTo }),
            message: mail.message,
            remoteAttachmentId: mail.remoteAttachmentId,
          },
        },
      });
    },
    [jobId, reminder, sendScheduleItemReminder, showCc, showBcc]
  );

  const dropdownItems = React.useMemo(
    () =>
      compact([
        !isReadonly
          ? {
              id: "import-template",
              label: t("schedule.importFromTemplate"),
              icon: "article",
              outlined: true,
              onClick: handleImportFromTemplate,
            }
          : null,
        !isReadonly
          ? {
              id: "import-costing",
              label: t("schedule.importFromCostings"),
              icon: "attach_money",
              outlined: true,
              onClick: openImportScheduleModal,
            }
          : null,
        !isReadonly
          ? {
              id: "import-spreadsheet",
              label: t("schedule.importFromSpreadsheet"),
              icon: "view_list",
              outlined: true,
              onClick: handleImportSpreadsheet,
            }
          : null,
        {
          id: "save",
          label: t("schedule.saveAsTemplate"),
          icon: "save",
          outlined: true,
          onClick: handleSaveAsTemplate,
        },
        {
          id: "export",
          label: t("schedule.exportSpreadsheet"),
          icon: "download",
          outlined: true,
          onClick: handleExportSpreadsheet,
        },
        {
          id: "print",
          label: t("common.print"),
          icon: "print",
          outlined: true,
          onClick: handlePreviewPdf,
        },
        {
          id: "mail",
          label: t("common.send"),
          icon: "mail",
          outlined: true,
          onClick: openEmailModal,
        },
      ]),
    [
      isReadonly,
      openImportScheduleModal,
      handlePreviewPdf,
      openEmailModal,
      handleImportFromTemplate,
      handleSaveAsTemplate,
    ]
  );

  const previewFileName = `${businessName} - Schedule.pdf`;

  return (
    <Container fluid className="schedule-container">
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.SCHEDULE} />
      <Helmet title={t("navigation.jobsSection.jobs")} />

      <ClientHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <TeammateHeaderList isReadonly={user?.role !== UserRoles.builderadmin} />
      <JobLocationHeader />

      <ConfirmDialog ref={confirmDialogRef} title={deleteDialogTitle}>
        <span className="field-text">{deleteDialogMessage}</span>
      </ConfirmDialog>

      <CreateTaskGroupModal
        ref={createCategoryRef}
        onSubmit={handleTaskGroupSubmit}
        colourIndex={jobSchedule?.getJobSchedule?.length}
      />
      <UpdateTaskGroupModal
        ref={updateCategoryRef}
        onSubmit={handleGroupUpdateSubmit}
      />
      <CreateTaskModal
        ref={createTaskRef}
        onSubmit={handleTaskSubmit}
        tasks={tasks}
      />
      <AssignTasksModal
        ref={assignTasksRef}
        onSubmit={handleAssignTasksSubmit}
        tasks={tasks}
      />
      <UserDetailsModal ref={userDetailsRef} />
      <UpdateTaskModal
        ref={updateTaskRef}
        onSubmit={handleTaskUpdateSubmit}
        tasks={tasks}
      />

      <SendModal
        ref={sendReminderRef}
        title={t("schedule.sendReminder")}
        submitText={t("common.send")}
        onSubmit={handleSendReminder}
        contacts={reminder?.contact ? [reminder.contact] : []}
        contact={reminder?.contact}
        subject={t("schedule.reminderSubject", {
          name: reminder?.task?.name,
          date: moment(reminder?.task?.startDate).format("ddd Do MMM"),
        })}
        disableContactSelect
        copyOptions={copyMailOptions}
        addDocumentsSelect={{
          entityId: jobId,
          systemFolderType: SystemFolderType.JOB,
        }}
      />

      {suppliersList?.listSuppliers && (
        <SendModal
          ref={sendEmailRef}
          fileName={previewFileName}
          title={t("schedule.sendEmail")}
          contacts={suppliersList?.listSuppliers}
          onSubmit={handleSendSchedule}
          submitText={t("common.send")}
          copyOptions={copyMailOptions}
          addDocumentsSelect={{
            entityId: jobId,
            systemFolderType: SystemFolderType.JOB,
          }}
        />
      )}

      <ImportScheduleFromCostingModal
        ref={importScheduleRef}
        onSubmit={handleImportSchedule}
        data={
          jobCostingData?.getJobCostings ? jobCostingData?.getJobCostings : []
        }
        onClose={closeImportScheduleModal}
      />

      {jobId && (
        <SortTaskModal
          ref={sortTaskRef}
          jobId={jobId}
          onSortComplete={refreshChart}
        />
      )}

      {renderImportFromTemplate()}
      {renderSaveAsTemplate()}
      {renderContextMenu()}
      {renderTimelineContextMenu()}

      <DashboardCard className="schedule-body">
        <DashboardCardHeader className="d-flex">
          <div>{t("schedule.timeline")}</div>
          {calendarState && !isEmpty(jobSchedule?.getJobSchedule) && (
            <>
              <div className="actions-container">
                {renderWithSelected()}
                {renderCategoryFilter()}
                {renderAssigneeFilter()}
                {!isReadonly && (
                  <div className="action" onClick={handleTimelineToggle}>
                    <SVGIcon name="Timeline" />
                  </div>
                )}
                <div className="action" onClick={handleSort}>
                  <SVGIcon name="Sort" />
                </div>
                <div className="action" onClick={handleZoomIn}>
                  <SVGIcon name="ZoomIn" />
                </div>
                <div className="action" onClick={handleZoomOut}>
                  <SVGIcon name="ZoomOut" />
                </div>
              </div>
              <Dropdown
                isVisible={isDropdownShow}
                handleToggle={handleToggle}
                label={t("common.options")}
                icon="expand_more"
                size="140px"
                id="report-card-dropdown"
                items={dropdownItems}
              />
            </>
          )}
        </DashboardCardHeader>
        <DashboardCardBody>
          {calendarState && !isEmpty(jobSchedule?.getJobSchedule) ? (
            <CalendarWrapper
              state={calendarState}
              onCreate={handleCalendarCreate}
            />
          ) : !isLocked && !isReadonly ? (
            <EmptyPlaceholder
              message={t("schedule.emptyPlaceholder")}
              buttonConfig={buttonConfig}
            />
          ) : (
            <EmptyPlaceholder message={t("schedule.emptyPlaceholder")} />
          )}
        </DashboardCardBody>
      </DashboardCard>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(mapStateToProps, {})(withDashboardContext(JobSchedule));
