import { TFunction } from "i18next";
import { map } from "lodash";
import {
  GenericFormFields,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import { SelectionCategoryItemOptionInput } from "../../../graphql/types/models/selections";
import { UOMOption } from "../../../utils/types/options";
import { PriceListItem } from "../../../models/price-list";
import CostingAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";
import { SelectOption } from "../../generic-form/inputs/select";

export const createCategoryItemField = (
  t: TFunction,
  uoms: UOMOption[],
  handleUOMCreate: (uom: string) => void,
  handleNameChange: UpdateTextInputFn,
  handlePriceSearch: (value: string) => Promise<PriceListItem[]>
): GenericFormFields<SelectionCategoryItemOptionInput> => {
  const priceOptions = async (value: string) => {
    if (!value) return [];
    const prices = await handlePriceSearch(value);
    const priceItems = map(
      prices,
      (priceItem) =>
        ({
          label: priceItem.name,
          value: `price:${priceItem._id}`,
          hidden: false,
          data: priceItem,
        } as SelectOption)
    );
    return priceItems;
  };
  const fields: GenericFormFields<SelectionCategoryItemOptionInput> = {
    name: {
      type: "creatable-select",
      label: t("common.name"),
      valueKey: "name",
      placeholder: t("placeholders.name"),
      autocompleteProps: {
        options: [],
      },
      textInputProps: {
        onValueChange: handleNameChange,
      },
      inputProps: {
        type: "text",
        required: true,
      },
      creatableProps: {
        autoSelectCreatedOption: true,
        isEditable: true,
        isClearable: true,
        options: [],
        asyncOptions: priceOptions,
        optionComponent: CostingAutocompleteMenuOption,
        isValidNewOption: (v: string) => v.length > 0,
        noOptionsMessage: () => null,
      },
    },
    description: {
      type: "text",
      controlType: "textarea",
      label: t("common.description"),
      placeholder: t("common.description"),
      valueKey: "description",
    },
    cost: {
      type: "text",
      label: t("placeholders.costEx"),
      placeholder: t("placeholders.costEx"),
      valueKey: "cost",
      disallowNegativeNumber: true,
      inputProps: {
        type: "number",
        required: true,
        min: 0,
      },
    },
    quantity: {
      type: "text",
      label: t("placeholders.quantity"),
      placeholder: t("placeholders.quantity"),
      valueKey: "quantity",
      disallowNegativeNumber: true,
      inputProps: {
        type: "number",
        required: true,
        min: 0,
      },
    },
    UOM: {
      type: "creatable-select",
      label: t("placeholders.uom"),
      placeholder: t("placeholders.uom"),
      valueKey: "UOM",
      creatableProps: {
        autoSelectCreatedOption: true,
        options: uoms,
        onCreateOption: handleUOMCreate,
        isValidNewOption: (v: string) => v.length > 0,
      },
      inputProps: {
        type: "text",
        required: true,
      },
    },
    note: {
      type: "text",
      label: t("note.note"),
      placeholder: t("note.note"),
      valueKey: "note",
    },
  };

  return fields;
};
