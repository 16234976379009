import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { mapValues } from "lodash";

import {
  Business,
  BusinessInvoice,
} from "../../../../graphql/types/models/business";
import { GenericFormFields } from "../../../generic-form/GenericFormBody";
import createPurchaseOrderFields from "./utils";
import editPurchaseOrderSchema from "./EditPurchaseOrder.schema";
import UpdateEntityModal from "../../../modals/update-entity";
import { useModalDisplay } from "../../../../hooks/useModalDisplay";
import { useBusinessMutation } from "../../../../hooks/mutations/useBusinessMutation";
import { convertRichContent } from "../../../generic-form/inputs/rich-editor/utils";

type EditInvoiceProps = {
  company: Business;
};

const EditPurchaseOrderModal: React.FC<EditInvoiceProps> = (props, ref) => {
  const { company } = props;

  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<
    GenericFormFields<BusinessInvoice>
  >({});

  const { shouldShow, hide } = useModalDisplay(ref);
  const { updateBusiness } = useBusinessMutation(hide);

  React.useEffect(() => {
    setFormFields(createPurchaseOrderFields(t));
  }, [t]);

  const initialValues = React.useMemo(() => {
    return mapValues(company, (s) => s || "");
  }, [company]);

  const handleSubmit = React.useCallback(
    (values) => {
      const data = {
        richOrderTerms:
          typeof values.richOrderTerms === "object"
            ? convertRichContent(values.richOrderTerms)
            : {},
      };

      updateBusiness({
        variables: {
          business: data,
        },
      });
    },
    [updateBusiness]
  );

  return (
    <UpdateEntityModal
      validationSchema={editPurchaseOrderSchema(t)}
      show={shouldShow}
      className="edit-company-modal"
      title={t("company.editOrderSettings")}
      fields={formFields}
      data={initialValues}
      onClose={hide}
      onSubmit={handleSubmit}
    />
  );
};

export default forwardRef(EditPurchaseOrderModal);
