import React from "react";
import { components, OptionProps } from "react-select";

export type AutocompleteMenuOptionProps = OptionProps<any> & {};

const AutocompleteMenuOption: React.FC<AutocompleteMenuOptionProps> = (
  props
) => {
  return <components.Option {...props} className="option" />;
};

export default AutocompleteMenuOption;
