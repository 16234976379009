import { MapField } from "../../../components/import-mapper/types";
import {
  JobCostingItemImportMap,
  SalesQuoteCostingItemImportMap,
} from "./types";

export const FIELD_MAP_QUOTE_COSTING: MapField<
  SalesQuoteCostingItemImportMap
>[] = [
  {
    fieldKey: "category",
    label: "costing.categoryName",
  },
  {
    fieldKey: "name",
    label: "costing.name",
  },
  {
    fieldKey: "quantity",
    label: "costing.quantity",
  },
  {
    fieldKey: "UOM",
    label: "costing.uom",
  },
  {
    fieldKey: "cost",
    label: "costing.cost",
  },
  {
    fieldKey: "margin_amount",
    label: "costing.markupPercentLabel",
  },
  {
    fieldKey: "is_allowance",
    label: "costing.isAllowanceYesNo",
  },
  {
    fieldKey: "gstFree",
    label: "costing.gstFreeYesNo",
  },
  {
    fieldKey: "note",
    label: "costing.note",
  },
];

export const FIELD_MAP_JOB_COSTING: MapField<JobCostingItemImportMap>[] = [
  {
    fieldKey: "category",
    label: "costing.categoryName",
  },
  {
    fieldKey: "name",
    label: "costing.name",
  },
  {
    fieldKey: "quantity",
    label: "costing.quantity",
  },
  {
    fieldKey: "UOM",
    label: "costing.uom",
  },
  {
    fieldKey: "cost",
    label: "costing.cost",
  },
  {
    fieldKey: "real_cost",
    label: "costing.realCost",
  },
  {
    fieldKey: "gstFree",
    label: "costing.gstFreeYesNo",
  },
  {
    fieldKey: "note",
    label: "costing.note",
  },
];
