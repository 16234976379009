import { GET_LEAD_BY_ID, LIST_LEAD_STAGES } from "./queries";
import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import {
  CreateUpdateLeadResponse,
  DeleteLeadResponse,
  GetLeadByIdResponse,
  LeadSale,
  ListLeadStagesResponse,
} from "../../../models/leads";

const getLeadById = (cache: ApolloCache<any>, leadId: string) => {
  const cacheData = cache.readQuery<GetLeadByIdResponse>({
    query: GET_LEAD_BY_ID,
    variables: {
      leadId,
    },
  });
  return cacheData?.getLeadById;
};

const updateLead = (
  cache: ApolloCache<any>,
  newLead: LeadSale,
  leadId: string
) => {
  cache.writeQuery<GetLeadByIdResponse>({
    query: GET_LEAD_BY_ID,
    variables: {
      leadId,
    },
    data: {
      getLeadById: newLead,
    },
  });
};

export const handleLeadUpdate = (
  leadId: string
): MutationUpdaterFn<CreateUpdateLeadResponse> => (cache, { data }) => {
  const newLead = data?.createUpdateLead;
  const cachedLead = getLeadById(cache, leadId);

  if (!cachedLead || !newLead) {
    return;
  }

  updateLead(cache, newLead, leadId);
};

export const handleLeadDelete: MutationUpdaterFn<DeleteLeadResponse> = (
  cache,
  { data }
) => {
  const deleteLeadId = data?.deleteLead._id;

  const cacheData: ListLeadStagesResponse | null = cache.readQuery({
    query: LIST_LEAD_STAGES,
  });

  if (!cacheData || !deleteLeadId) {
    return;
  }

  const { listLeadStages } = cacheData;
  const filteredListLeadStages = listLeadStages.map((stage) => {
    const filteredLeads = stage.leads.filter(
      (lead) => lead._id !== deleteLeadId
    );
    const currentStage = {
      ...stage,
      leads: filteredLeads,
    };
    return currentStage;
  });

  cache.writeQuery({
    query: LIST_LEAD_STAGES,
    data: {
      listLeadStages: filteredListLeadStages,
    },
  });
};
