import React from "react";
import { Reminder as ReminderType } from "../../../models/reminder";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Icon from "../../icons/Icon";
import moment from "moment";

type ReminderProps = ReminderType & {
  onStatusChange: (id: string, currentStatus: boolean) => void;
  onDelete: (id: string) => void;
};

const Reminder: React.FC<ReminderProps> = (props) => {
  const {
    title,
    description,
    completed,
    dueDate,
    onStatusChange,
    _id,
    onDelete,
  } = props;
  const { t } = useTranslation();

  const handleStatusChange = React.useCallback(() => {
    if (completed) {
      return;
    }

    onStatusChange(_id, completed);
  }, [completed, onStatusChange, _id]);

  const handleDelete = React.useCallback(() => {
    onDelete(_id);
  }, [onDelete, _id]);

  const reminderStatusClasses = classNames(
    {
      "reminder-item-status--completed": completed,
      "reminder-item-status--disabled": !completed,
    },
    "reminder-item-status"
  );

  const statusTranslationKey = completed
    ? "reminders.completed"
    : "reminders.complete";
  const statusIconName = completed
    ? "check_circle_outline"
    : "radio_button_unchecked";

  return (
    <div className="reminder-item">
      <div className="reminder-item-content">
        <div className="reminder-item-header">
          <div className="reminder-item-name field-text">{title}</div>
        </div>

        <div className="reminder-item-description field-text">
          {description}
        </div>

        <div className="reminder-item-statuses">
          <div className="reminder-item-status">
            <Icon name="schedule" className="status-icon" />
            <div className="field-text">
              {moment(dueDate).format("D MMM YYYY @ h:mma")}
            </div>
          </div>

          <div className="right-actions">
            <div className={reminderStatusClasses} onClick={handleStatusChange}>
              <Icon name={statusIconName} className="status-icon" />
              <div className="field-text">{t(statusTranslationKey)}</div>
            </div>

            <div
              className="remove-action cursor-pointer"
              onClick={handleDelete}
            >
              <Icon name="delete" outlined />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reminder;
