import React from "react";
import { components, MultiValueProps } from "react-select";
import classNames from "classnames";
import Icon from "../../../icons/Icon";

type SelectMultiValueRemoveProps = MultiValueProps<any> & {};

const SelectMultiValueRemove: React.FC<SelectMultiValueRemoveProps> = (
  props
) => {
  const innerProps = {
    ...props.innerProps,
    className: classNames(props.innerProps.className, "multi-value-remove"),
  };

  return (
    <components.MultiValueRemove {...props} innerProps={innerProps}>
      <Icon name="close" />
    </components.MultiValueRemove>
  );
};

export default SelectMultiValueRemove;
