import { gql } from "@apollo/client";
import { TEAM_MEMBER } from "./fragments";

export const CREATE_UPDATE_MEMBER = gql`
  mutation($member: MemberInput!) {
    addedMember: createUpdateMember(member: $member) {
      ...TeamMember
    }
  }
  ${TEAM_MEMBER}
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($userId: ID!) {
    deleteMember(userId: $userId) {
      success
    }
  }
`;
