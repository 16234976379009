import React from "react";
import MessageFilePreview from "../message-file-preview";
import { isImage } from "../../../utils/transform";
import classNames from "classnames";

type Attachment = {
  url?: string;
  upload_url: string;
  type: string;
  size: number;
  name?: string;
};

type MessageAttachmentProps = {
  attachment: Attachment;
  horizontal?: boolean;
  onClick?: () => void;
  targetSize?: number;
};

const MessageAttachment: React.FC<MessageAttachmentProps> = (props) => {
  const { attachment, horizontal, targetSize, onClick } = props;

  const [dimensions, setDimensions] = React.useState({ height: 0, width: 0 });

  const getMeta = React.useCallback(() => {
    const img = new Image();
    img.src = attachment.url || "";
    img.onload = () => {
      setDimensions({ height: img.height, width: img.width });
    };
  }, [attachment]);

  React.useEffect(() => {
    getMeta();
  }, [getMeta]);

  const targetDimensions = React.useMemo(() => {
    const aspectRatio = dimensions.width / dimensions.height;

    if (aspectRatio < 1) {
      return {
        height: targetSize,
        width: Math.floor((targetSize || 0) * aspectRatio),
      };
    } else {
      return {
        width: targetSize,
        height: Math.floor((targetSize || 0) / aspectRatio),
      };
    }
  }, [dimensions, targetSize]);

  const renderAttachment = () => {
    if (isImage(attachment)) {
      return (
        <div className="image-attachment" onClick={onClick}>
          <img
            className="rounded border"
            src={attachment.url}
            alt=""
            style={
              targetDimensions.height && targetDimensions.width
                ? {
                    height: targetDimensions.height,
                    width: targetDimensions.width,
                  }
                : { maxWidth: targetSize }
            }
          />
        </div>
      );
    }

    return (
      <div className="file-attachment">
        <MessageFilePreview
          size={attachment.size}
          name={attachment.name || "File name"}
          download_url={attachment.url}
        />
      </div>
    );
  };

  const containerClasses = classNames({
    attachment: !horizontal,
    "attachment--horizontal": horizontal,
  });

  return <div className={containerClasses}>{renderAttachment()}</div>;
};

export default MessageAttachment;
