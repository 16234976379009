import { BadgeProps } from "react-bootstrap/Badge";
import { ClaimStatusesEnum } from "../../../models/claim";

export const getClaimBadgeVariant = (
  status: ClaimStatusesEnum
): BadgeProps["variant"] => {
  if (status === ClaimStatusesEnum.SENT) {
    return "warning";
  } else if (status === ClaimStatusesEnum.RECEIVED) {
    return "success";
  } else if (status === ClaimStatusesEnum.PARTIALLY_RECEIVED) {
    return "info";
  }
  return "secondary";
};
