import React from "react";
import { useTranslation } from "react-i18next";
import map from "lodash/map";
import findIndex from "lodash/findIndex";

import CardTable from "../../../dashboard/table-card/CardTable";
import {
  TableCardData,
  TableRowActionData,
} from "../../../dashboard/table-card/utils";
import filesize from "filesize";

export type PlanFile = {
  id: string;
  name: string;
  size: number;
  lastModified: Date;
  type: string;
  content: ArrayBuffer;
};

export type TablePlanFile = PlanFile & {
  tableId: number;
};

type PlansTableProps = {
  files: PlanFile[];
  onFileDelete: (file: PlanFile) => void;
};

const PlansTable: React.FC<PlansTableProps> = ({ files, onFileDelete }) => {
  const { t } = useTranslation();
  const handleFileClick = React.useCallback(
    (plan: PlanFile) => {
      onFileDelete(plan);
    },
    [onFileDelete]
  );

  const plansTable = React.useMemo<TableCardData<PlanFile>>(
    () => ({
      columns: [
        {
          valueKey: "id",
          title: "#",
          formatValue: (row, column, value) => {
            return findIndex(files, (f) => f.id === value) + 1;
          },
        },
        {
          valueKey: "name",
          title: t("plansSection.documentName"),
        },
        {
          valueKey: "size",
          title: t("plansSection.size"),
          formatValue: (row, column, value: number) => filesize(value),
        },
      ],
      rows: map(files, (file: PlanFile) => ({
        cells: file,
      })),
    }),
    [t, files]
  );
  const tableRowActions = React.useMemo<TableRowActionData<PlanFile>[]>(
    () => [
      {
        icon: "clear",
        onClick: handleFileClick,
        className: "field-text",
      },
    ],
    [handleFileClick]
  );

  if (!files.length) return null;

  return <CardTable table={plansTable} rowActions={tableRowActions} />;
};

export default PlansTable;
