import classNames from "classnames";
import { filter, find, includes, isEmpty, map, sortBy } from "lodash";
import React, { ReactElement, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import { InputActionMeta } from "react-select";

import { FieldAppend, FormikPropGetSetValues } from "../../GenericFormBody";
import AutocompleteControl from "../autocomplete/AutocompleteControl";
import AutocompleteDropdownIndicator from "../autocomplete/AutocompleteDropdownIndicator";
import AutocompleteSelectInput from "../autocomplete/AutocompleteInput";
import AutocompleteMenu from "../autocomplete/AutocompleteMenu";
import AutocompleteMenuList from "../autocomplete/AutocompleteMenuList";
import AutocompleteMenuOption, {
  AutocompleteMenuOptionProps,
} from "../autocomplete/AutocompleteMenuOption";
import AutocompleteValueContainer from "../autocomplete/AutocompleteValueContainer";
import InputAppend from "../input-apend";
import SelectMultiValueContainer from "../autocomplete/SelectMultiValueContainer";
import SelectMultiValueRemove from "../autocomplete/SelectMultiValueRemove";
import SelectMultiValueLabel from "../autocomplete/SelectFieldValueLabel";
import { TextInputProps } from "../text";

export type SelectOption = {
  value: string;
  label: string;
  hidden?: boolean;
  data?: any;
};

export type CreatableSelectProps = Omit<TextInputProps, "onChange"> & {
  onChange: (value: string | string[]) => void;
  onMenuOpen?: (
    formikProps?: FormikPropGetSetValues,
    rowIndex?: number
  ) => void;
  options: SelectOption[];
  asyncOptions?: (value: string) => Promise<SelectOption[]>;
  debounceAsyncOptions?: (
    value: string,
    callback: (options: SelectOption[]) => void
  ) => void;
  append?: FieldAppend;
  isMulti?: boolean;
  isLoading?: boolean;
  onCreateOption?: (value: string) => void;
  isValidNewOption?: (inputValue: string) => boolean;
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  autoSelectCreatedOption?: boolean;
  optionComponent?: React.FC<AutocompleteMenuOptionProps>;
  isDisabled?: boolean;
  isClearable?: boolean;
  hideSelectedOptions?: boolean;
  isEditable?: boolean;
  defaultValue?: string;
  sortByAsc?: boolean;
};

const CreatableSelectInput: React.FC<CreatableSelectProps> = (props) => {
  const {
    value,
    options,
    asyncOptions,
    debounceAsyncOptions,
    autoSelectCreatedOption,
    placeholder,
    error,
    touched,
    name,
    onBlur,
    onChange,
    append,
    isValidNewOption,
    onCreateOption,
    rowIndex,
    isMulti,
    noOptionsMessage,
    optionComponent = AutocompleteMenuOption,
    isDisabled,
    isClearable,
    isEditable,
    defaultValue,
    maxWidth,
    className,
    hideSelectedOptions,
    formatCreateLabel,
    sortByAsc,
    onMenuOpen,
    isLoading,
  } = props;

  const handleAppendClick = React.useCallback(() => {
    append?.onClick &&
      append.onClick(name, value, rowIndex, append.formikProps);
  }, [append, name, rowIndex, value]);

  const [selectedOption, setSelectedOption] = useState<
    SelectOption | SelectOption[] | null
  >(null);
  const [inputValue, setInputValue] = useState(defaultValue || "");

  React.useEffect(() => {
    if (!isMulti) {
      const option =
        find(options, { value }) || find(options, { label: value }); // assemblies use 'label' for lookup
      if (option) {
        setInputValue(option.label);
        setSelectedOption(option);
      } else {
        setInputValue(value); // retain value when item is changed by item array/rows deleted
        setSelectedOption(null);
      }
    } else {
      const preparedOptions: SelectOption[] = filter(options, (option) =>
        includes(value, option.value)
      );
      setSelectedOption(preparedOptions);
    }
  }, [value, isMulti, options]);

  const renderAppend = React.useCallback(() => {
    if (!append) {
      return null;
    }

    return (
      <InputAppend
        {...append}
        fieldName={name}
        rowIndex={rowIndex}
        onClick={handleAppendClick}
      />
    );
  }, [append, name, rowIndex, handleAppendClick]);

  const handleSelectChange = React.useCallback(
    (option) => {
      if (option === null) {
        // when isClearable=true and cleared
        onChange("");
        setInputValue("");
        setSelectedOption(null);
      } else if (!isMulti) {
        onChange(option.value);
        setInputValue(option.label);
        setSelectedOption(option);
      } else {
        const values: string[] = map(option, "value");
        onChange(values);
        setSelectedOption(option);
      }
    },
    [onChange, setSelectedOption, isMulti]
  );

  const handleOptionCreate = React.useCallback(
    (newOption: string) => {
      onCreateOption && onCreateOption(newOption);
      if (!autoSelectCreatedOption) {
        return;
      }
      if (!isMulti) {
        onChange(newOption);
        setInputValue(newOption);
      } else {
        onChange(value ? [...value, newOption] : [newOption]);
      }
    },
    [isMulti, onChange, value, onCreateOption, autoSelectCreatedOption]
  );

  const handleBlur = React.useCallback(
    (ev: any) => {
      const value = ev.target.value;
      if (value) {
        if (
          isMulti ||
          (!isMulti && value !== (selectedOption as SelectOption)?.value)
        ) {
          handleOptionCreate(value);
        }
      }
    },
    [handleOptionCreate, selectedOption]
  );

  const filteredOptions = React.useMemo(
    () => filter(options, (o) => !o.hidden),
    [options]
  );

  const sortedOptionsByAsc = React.useMemo(
    () => sortBy(filteredOptions, "label"),
    [filteredOptions]
  );
  const SelectComponent =
    asyncOptions !== undefined || debounceAsyncOptions !== undefined
      ? AsyncCreatableSelect
      : CreatableSelect;

  const handleInputChange = React.useCallback(
    (value: string, action: InputActionMeta) => {
      if (action?.action !== "input-blur" && action?.action !== "menu-close") {
        setInputValue(value);
      }
    },
    []
  );

  return (
    <InputGroup
      className={classNames("form-input-group", {
        "form-input-group-multi": isMulti,
        "form-input-group-disabled": isDisabled,
      })}
      style={{ width: maxWidth, backgroundColor: "#e9ecef" }}
    >
      <SelectComponent
        isDisabled={isDisabled}
        name={name}
        onChange={handleSelectChange}
        onMenuOpen={onMenuOpen}
        isLoading={isLoading}
        onBlur={handleBlur}
        value={selectedOption}
        isValidNewOption={isValidNewOption}
        isClearable={isClearable}
        onCreateOption={handleOptionCreate}
        hideSelectedOptions={hideSelectedOptions}
        options={sortByAsc ? sortedOptionsByAsc : filteredOptions}
        formatCreateLabel={formatCreateLabel}
        // inputValue={!isMulti ? inputValue : undefined}
        inputValue={isEditable ? inputValue : undefined}
        onInputChange={!isMulti ? handleInputChange : undefined}
        defaultOptions={filteredOptions}
        loadOptions={asyncOptions || debounceAsyncOptions}
        placeholder={selectedOption && !isMulti ? null : placeholder}
        className={classNames("autocomplete", className, {
          "is-invalid": touched && !!error,
          "autocomplete-multi": isMulti && !isEmpty(selectedOption),
        })}
        isMulti={isMulti}
        noOptionsMessage={noOptionsMessage}
        tabSelectsValue={false}
        error={error}
        touched={touched}
        components={{
          ...(!isMulti ? { Input: AutocompleteSelectInput } : {}),
          MultiValueLabel: SelectMultiValueLabel,
          MultiValueRemove: SelectMultiValueRemove,
          MultiValueContainer: SelectMultiValueContainer,
          Control: AutocompleteControl,
          Menu: AutocompleteMenu,
          MenuList: AutocompleteMenuList,
          Option: optionComponent,
          ValueContainer: AutocompleteValueContainer,
          DropdownIndicator: AutocompleteDropdownIndicator,
          Placeholder: () => null,
          IndicatorSeparator: null,
        }}
      />
      {renderAppend()}
    </InputGroup>
  );
};

export default CreatableSelectInput;
