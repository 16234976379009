import { gql } from "@apollo/client";
import { CHECKLIST_CATEGORY_FRAGMENT } from "./fragments";

export const LIST_CHECKLIST_CATEGORIES = gql`
  query($entityId: ID!, $entityType: EnumChecklistEntityType!) {
    listChecklistCategories(entityId: $entityId, entityType: $entityType) {
      ...ChecklistCategoryFragment
    }
  }
  ${CHECKLIST_CATEGORY_FRAGMENT}
`;

export const LIST_CHECKLIST_CATEGORIES_BY_ENTITY_TYPE = gql`
  query($entityType: EnumChecklistEntityType!) {
    listChecklistCategoiresByEntityType(entityType: $entityType) {
      _id
      entity {
        ... on Job {
          _id
          jobNumber
          name
        }
        ... on SalesQuote {
          _id
          number
          name
        }
      }
      categories {
        ...ChecklistCategoryFragment
      }
    }
  }
  ${CHECKLIST_CATEGORY_FRAGMENT}
`;
