import React from "react";
import { useTranslation } from "react-i18next";
import { map, omit } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import moment from "moment";

import {
  ModalDisplayRef,
  useModalDisplay,
} from "../../../hooks/useModalDisplay";
import { QuoteRequestModalListItem } from "../../../graphql/types/models/quote-request";
import { TableCardData } from "../../dashboard/table-card/utils";
import { formatQuoteNumber } from "../../../utils/text";
import LeftModal from "../../left-modal";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import CardTable from "../../dashboard/table-card/CardTable";
import LeftModalBody from "../../left-modal/LeftModalBody";
import "./styles.scss";

type QuoteRequestItemsTable = {
  _id: string;
  due_date: string;
  name: string;
  quantity: number;
  UOM: string;
  cost?: number | null;
  total?: number | null;
  status: string;
  quoteNumber: string;
  id: string;
  subcontractorId: string;
  subcontractors: {
    status: string;
    _id: string;
    items: {
      _id: string;
      cost: number;
      quoteItemId: string;
    }[];
    supplier: {
      _id: string;
      business_name: string;
    };
  }[];
};

export type ListQuoteRequestsModalRef = ModalDisplayRef & {
  setData: (data: QuoteRequestModalListItem[], id: string) => void;
};

type ListQuoteRequestsModalProps = {
  redirectTo: string;
};

const ListQuoteRequestsModal: React.FC<ListQuoteRequestsModalProps> = (
  props,
  ref
) => {
  const { redirectTo } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = React.useState<QuoteRequestModalListItem[]>([]);

  const { shouldShow, hide } = useModalDisplay<ListQuoteRequestsModalRef>(
    ref,
    {
      setData: (data: QuoteRequestModalListItem[], id: string) => {
        setData(data.map((item) => ({ ...item, id })));
      },
    } as ListQuoteRequestsModalRef,
    [setData]
  );

  const allSubcontractors = React.useMemo(() => {
    let subcontractors: QuoteRequestModalListItem[] = [];

    data.forEach((item) => {
      item.quoteRequest.subcontractors.forEach((sub) => {
        const obj = { ...omit(item, "subcontractors") };
        subcontractors.push({
          ...obj,
          ...{ quoteRequest: { ...obj.quoteRequest, subcontractors: [sub] } },
        });
      });
    });

    return subcontractors;
  }, [data]);

  const tableData = React.useMemo<TableCardData<QuoteRequestItemsTable>>(() => {
    return {
      columns: [
        {
          valueKey: "due_date",
          title: t("quoteRequest.due_date"),
          formatValue: (row: any, column: any, value: number) =>
            moment(value).format("Do MMMM YYYY"),
        },
        {
          valueKey: "quoteNumber",
          title: t("quoteRequest.quoteRequest"),
          className: "table-cell-link",
          onClick: (row) => {
            hide();
            history.push(
              generatePath(
                "/:section/:id/quote-requests/:qr_id/subcontractor/:sub_id",
                {
                  section: redirectTo.toLowerCase(),
                  id: row.cells.id,
                  qr_id: row.cells._id,
                  sub_id: row.cells.subcontractorId,
                }
              )
            );
          },
        },
        {
          valueKey: "subcontractors",
          title: t("quoteRequest.subcontractors"),
          formatValue: (row: any, column: any, value: any) => {
            return value
              ?.map((item: any) => item?.supplier?.business_name)
              .join(", ");
          },
        },
        {
          valueKey: "status",
          title: t("common.status"),
          formatValue: (row: any, column: any, value: number) =>
            t(`quoteRequest.statuses.${value}`),
        },
        {
          valueKey: "name",
          title: t("orders.nameLabel"),
        },
        {
          valueKey: "quantity",
          title: t("costing.quantity"),
          formatValue: (row: any, column: any, value: number) =>
            t("common.quantity", { amount: value }),
        },
        {
          valueKey: "UOM",
          title: t("costing.uom"),
        },
        {
          valueKey: "cost",
          title: t("costing.cost"),
          formatValue: (row: any, column: any, value: number) =>
            value ? t("common.currency", { amount: value }) : t("common.na"),
        },
        {
          valueKey: "total",
          title: t("costing.totalEx"),
          formatValue: (row: any, column: any, value: number) =>
            value ? t("common.currency", { amount: value }) : t("common.na"),
        },
      ],
      rows: map(allSubcontractors, (item) => {
        const costItems = item?.quoteRequest?.subcontractors?.reduce(
          (accum, subcontractor) => {
            subcontractor.items.forEach((i) => accum.push(i));
            return accum;
          },
          [] as {
            _id: string;
            cost: number;
            quoteItemId: string;
          }[]
        );
        const currentItem = costItems.find(
          (cost) => cost.quoteItemId === item._id
        );

        const subcontractor = item.quoteRequest.subcontractors[0];

        return {
          cells: {
            _id: item.quoteRequest._id,
            id: item.id,
            subcontractorId: subcontractor._id,
            name: item.name,
            quantity: item.quantity,
            UOM: item.UOM,
            cost: currentItem?.cost || null,
            total: currentItem?.cost ? currentItem?.cost * item.quantity : null,
            due_date: item?.quoteRequest?.due_date,
            status: subcontractor.status,
            subcontractors: item?.quoteRequest?.subcontractors,
            quoteNumber: formatQuoteNumber(item.quoteRequest.quoteNumber, "QR"),
          },
        };
      }),
    };
  }, [allSubcontractors, hide, history, redirectTo, t]);

  return (
    <LeftModal show={shouldShow} onHide={hide} className="list-quote-requests">
      <LeftModalHeader title={t("quoteRequest.quoteRequests")} onClose={hide} />
      <LeftModalBody>
        <CardTable table={tableData} tableClass="table-outline" />
      </LeftModalBody>
    </LeftModal>
  );
};

export default React.forwardRef(ListQuoteRequestsModal);
