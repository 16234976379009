import { gql } from "@apollo/client";
import { AFFILIATE_BUSINESS_FRAGMENT, AFFILIATE_FRAGMENT } from "./fragments";

export const AFFILIATE_PROFILE = gql`
  query {
    affiliateProfile {
      ...AffiliateFragment
    }
  }
  ${AFFILIATE_FRAGMENT}
`;

export const LIST_AFFILIATE_BUSINESSES = gql`
  query($filter: AffiliateBusinessFilterInput) {
    affiliateListBusinesses(filter: $filter) {
      ...AffiliateBusinessFragment
    }
  }
  ${AFFILIATE_BUSINESS_FRAGMENT}
`;
