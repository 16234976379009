import React from "react";

import { SidebarNavItem } from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

type SidebarNavigationProps = {
  isMinified: boolean;
  navigationItem: SidebarNavItem | null;
  navigationItems: SidebarNavItem[];
  onNavigationItemClick: (item: SidebarNavItem) => void;
};

const SidebarNavigation: React.FC<SidebarNavigationProps> = ({
  isMinified,
  navigationItem,
  navigationItems,
  onNavigationItemClick,
}) => {
  const handleListItemClick = React.useCallback(
    (item: SidebarNavItem) => {
      onNavigationItemClick(item);
    },
    [onNavigationItemClick]
  );

  return (
    <nav className="navigation-container">
      <SidebarNavList
        items={navigationItems}
        onItemClick={handleListItemClick}
        selectedItem={navigationItem}
        isMinified={isMinified}
      />
    </nav>
  );
};

export default SidebarNavigation;
