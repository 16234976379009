import { gql } from "@apollo/client";

export const WB_ADMIN_SIGN_IN = gql`
  mutation($email: String!, $password: String!) {
    login: wbAdminLogin(email: $email, password: $password) {
      _id
      tokenType
      token
    }
  }
`;

export const WB_ADMIN_IMPERSONATE_USER = gql`
  mutation($userId: ID!) {
    impersonateUser: wbAdminImpersonateUser(userId: $userId) {
      hash
      url
    }
  }
`;
