import { TFunction } from "i18next";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export const createClaimReceiveFields = (
  t: TFunction
): GenericFormFields<any> => ({
  amount: {
    valueKey: "amount",
    type: "text",
    label: t("claims.amount"),
    placeholder: t("claims.amount"),
    showError: true,
    inputProps: {
      required: true,
      type: "number",
    },
  },
  reference: {
    valueKey: "reference",
    type: "text",
    label: t("common.reference"),
    placeholder: t("placeholders.reference"),
    inputProps: {
      required: true,
    },
  },
  dateReceived: {
    valueKey: "dateReceived",
    type: "date-picker",
    label: t("claims.receivedDate"),
    placeholder: t("claims.receivedDate"),
    inputProps: {
      type: "text",
      required: true,
    },
  },
  note: {
    type: "text",
    controlType: "textarea",
    label: t("claims.receivedNotes"),
    placeholder: "",
    valueKey: "note",
    secondRow: true,
    inputProps: {
      className: "form-input-compact",
      type: "text",
      rows: 5,
      debounceChangeEvent: true,
    },
  },
});
