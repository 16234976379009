import * as yup from "yup";
import { TFunction } from "i18next";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createAddCertificationSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "common.name")
      ),
    document_type: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "certification.type")
      ),
    expiry_date: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "certification.expiryDate"
        )
      ),
    note: yup.string(),
  });
}
