import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./styles.scss";

type UploadSpinnerProps = {
  show: boolean;
  text?: string;
  filesProgress?: UploadFileProgress[];
};

export type UploadFileProgress = {
  file: File;
  progress: number;
};

const UploadSpinner: React.FC<UploadSpinnerProps> = ({
  show,
  text,
  filesProgress,
}) => {
  return (
    <Modal
      show={show}
      className={"upload-spinner"}
      backdropClassName={"upload-spinner-backdrop"}
      role="dialog"
      centered
      onHide={() => {}}
    >
      <Modal.Body className="body field-text">
        <div className="d-flex flex-fill flex-column align-items-center">
          {text && <p className="text-center">{text}</p>}
          <Spinner animation="border" style={{ width: 50, height: 50 }} />
          {filesProgress?.length && (
            <div className="mt-2 flex">
              {filesProgress?.map((fp) => (
                <div className="upload-spinner--file d-flex justify-content-between">
                  <div className="upload-spinner--filename">{fp.file.name}</div>
                  <div className="ml-2">{fp.progress}%</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UploadSpinner;
