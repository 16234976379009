import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import { map, omit } from "lodash";
import {
  CreateCostingItemPayload,
  DeleteTakeOffTemplatePayloadResponse,
  DistributeMarkup,
  ImportTakeOffTemplatePayloadResponse,
  ListSalesQuoteTakeOffTemplates,
  SalesQuote,
  SalesQuoteTakeOffTemplate,
  SaveTakeOffAsTemplateResponse,
} from "../../../models/salesQuote";
import {
  AddSpecItemsFromCostingItemsResponce,
  SalesQuoteCategoryListResponse,
} from "../../../graphql/types/models/category";
import { LIST_SALES_QUOTE_CATEGORIES } from "../../../graphql/queries/category/queries";
import { QuoteRequestItemStatus } from "../../../graphql/types/models/quote-request";
import { QuoteRequestResponse } from "../../../graphql/types/models/quote-request";
import { Rounding } from "../../../components/calculator/utils";
import {
  GetSalesQuoteResponse,
  QuoteCopyResponse,
  QuoteListResponse,
} from "../../types/models/quote";
import {
  GET_SALES_QUOTE,
  LIST_QUOTES,
  SALES_QUOTE_TAKE_OFF_TEMPLATES,
} from "./queries";
import { ListTakeoffItemsResponse } from "../../types/models/take-off";
import { LIST_TAKEOFF } from "../take-off/queries";
import { GrantClientAccessResponse } from "../../types/models/client-access";

export const prepareSalesQuoteItems = (
  costItems: CreateCostingItemPayload[],
  isCopy?: boolean
) => {
  return map(costItems, (costing) => ({
    // _id: !costing._id || (costing.assemblyId && costing.assemblyId === costing._id) ? undefined : costing._id,
    order: costing.order,
    ...(!isCopy && { _id: costing.originalId }),
    name: costing.name,
    UOM: costing.UOM,
    hasGST: costing.hasGST,
    wastage: costing?.wastage ? Number(costing?.wastage) : 0,
    rounding: costing.rounding || Rounding.NONE,
    cost: parseFloat(costing.cost) || 0,
    margin_amount: parseFloat(costing.margin_amount) || 0,
    quantity: parseFloat(costing.quantity),
    raw_quantity:
      costing?.raw_quantity?.toString() || costing.quantity.toString(),
    note: costing.note,
    distribute_markup: costing.distribute_markup || DistributeMarkup.NONE,
    items: map(costing.items, (item) => {
      return omit(
        {
          ...item,
          quantity: Number(item.quantity || 0),
          cost: Number(item.cost || 0),
          raw_quantity: item.raw_quantity.toString(),
        },
        ["__typename", "total"]
      );
    }),
  }));
};

export const handleSendQuoteRequest = (
  salesQuoteId: string,
  itemsId: string[]
): MutationUpdaterFn<QuoteRequestResponse> => (cache, { data }) => {
  const categoriesResponse = cache.readQuery<SalesQuoteCategoryListResponse>({
    query: LIST_SALES_QUOTE_CATEGORIES,
    variables: { salesQuoteId },
  });

  if (!itemsId || !categoriesResponse) return;

  const categoriesList = map(
    categoriesResponse.listSalesQuoteCostingCategories,
    (category) => ({
      ...category,
      costings: map(category.costings, (item) =>
        itemsId.find((id) => id === item._id)
          ? {
              ...item,
              quoteRequestStatus: QuoteRequestItemStatus.REQUESTED,
            }
          : item
      ),
    })
  );

  cache.writeQuery({
    query: LIST_SALES_QUOTE_CATEGORIES,
    data: {
      listSalesQuoteCostingCategories: categoriesList,
    },
    variables: { salesQuoteId },
  });
};

export const handleUpdateSendQuote = (
  salesQuoteId: string
): MutationUpdaterFn<AddSpecItemsFromCostingItemsResponce> => (
  cache,
  { data }
) => {
  const categoriesResponse = cache.readQuery<GetSalesQuoteResponse>({
    query: GET_SALES_QUOTE,
    variables: { salesQuoteId },
  });
  const newSaleQuote = data?.addSpecItemsFromCostingItems;
  if (!newSaleQuote || !categoriesResponse) return;

  cache.writeQuery({
    query: GET_SALES_QUOTE,
    data: {
      getSalesQuote: newSaleQuote,
    },
    variables: { salesQuoteId },
  });
};

export const handleUpdateGrantSendQuote = (
  salesQuoteId: string
): MutationUpdaterFn<GrantClientAccessResponse> => (cache, { data }) => {
  const salesQuoteData = cache.readQuery<GetSalesQuoteResponse>({
    query: GET_SALES_QUOTE,
    variables: { salesQuoteId },
  });
  const contactData = data?.grantClientAccess.contact;
  if (!contactData || !salesQuoteData) return;

  const newData = {
    ...salesQuoteData.getSalesQuote,
    contact: {
      ...salesQuoteData.getSalesQuote,
      is_invited: contactData.is_invited,
    },
  };

  cache.writeQuery({
    query: GET_SALES_QUOTE,
    data: {
      getSalesQuote: newData,
    },
    variables: { salesQuoteId },
  });
};

const getQuotesList = (cache: ApolloCache<any>) => {
  const cacheData = cache.readQuery<QuoteListResponse>({
    query: LIST_QUOTES,
  });
  return cacheData?.listSalesQuotes;
};

const updateQuotesList = (cache: ApolloCache<any>, newList: SalesQuote[]) => {
  cache.writeQuery<QuoteListResponse>({
    query: LIST_QUOTES,
    data: {
      listSalesQuotes: newList,
    },
  });
};

export const handleCopyQuote = (): MutationUpdaterFn<QuoteCopyResponse> => (
  cache,
  response
) => {
  const newQuote = response.data?.copySalesQuote;
  const cacheData = getQuotesList(cache);
  const listQuotes = cacheData;

  if (!newQuote || !listQuotes) {
    return;
  }
  updateQuotesList(cache, cacheData.concat(newQuote));
};

const getTakeoffTemplateList = (cache: ApolloCache<any>) => {
  const cacheData = cache.readQuery<ListSalesQuoteTakeOffTemplates>({
    query: SALES_QUOTE_TAKE_OFF_TEMPLATES,
  });
  return cacheData?.listSalesQuoteTakeOffTemplates;
};

const updateTakeoffTemplateList = (
  cache: ApolloCache<any>,
  newList: SalesQuoteTakeOffTemplate[]
) => {
  cache.writeQuery<ListSalesQuoteTakeOffTemplates>({
    query: SALES_QUOTE_TAKE_OFF_TEMPLATES,
    data: {
      listSalesQuoteTakeOffTemplates: newList,
    },
  });
};

export const handleTakeoffTemplateSave: MutationUpdaterFn<SaveTakeOffAsTemplateResponse> = (
  cache,
  response
) => {
  const item = response.data?.saveTakeOffsAsTemplate;
  const templatesList = getTakeoffTemplateList(cache) || [];

  if (!item) {
    return;
  }

  updateTakeoffTemplateList(cache, templatesList.concat(item));
};

export const handleTakeoffTemplateDelete: MutationUpdaterFn<DeleteTakeOffTemplatePayloadResponse> = (
  cache,
  response
) => {
  const deleteTemplateId = response.data?.deleteSalesQuoteTakeOffTemplate._id;
  const templatesList = getTakeoffTemplateList(cache) || [];

  if (!deleteTemplateId || !templatesList) {
    return;
  }

  updateTakeoffTemplateList(
    cache,
    templatesList.filter((template) => template._id !== deleteTemplateId)
  );
};

export const handleImportTakeoffFromTemplate = (): MutationUpdaterFn<
  ImportTakeOffTemplatePayloadResponse
> => (cache, { data }) => {
  const takeoffs = data?.importTakeOffTemplateToSalesQuote.takeOffItems;
  if (!takeoffs) return;

  cache.writeQuery<ListTakeoffItemsResponse>({
    query: LIST_TAKEOFF,
    data: {
      listSalesQuoteTakeOff: takeoffs,
    },
  });
};

//export handleUpdateSendQuote
