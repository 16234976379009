import { Media, MediaPreview } from "./media";
import { UserRoles } from "./team-member";

export enum JobChannelEnum {
  Internal = "Internal",
  External = "External",
}

export type JobMessage = {
  _id: string;
  text: string;
  media?: Media[];
  linkPreview?: MediaPreview;
  is_deleted?: boolean;
  sender: JobChannelMessageSender;
  createdAt: string;
  channel: {
    _id: string;
    __typename: "JobChannel";
    job?: ChannelJobInfo;
  };
  __typename: "JobChannelMessage";
};

export type JobChannelMessageSender = {
  _id: string;
  __typename: "JobChannelMessageSender";
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  profileImage?: {
    url?: string;
  };
};

export type JobChannel = {
  _id: string;
  channelType: JobChannelEnum;
  messages?: JobMessage[];
  job?: ChannelJobInfo;
};

export type ChannelJobInfo = {
  _id: string;
  jobNumber: string;
  name: string;
  members: MessagePerson[];
};

export type MessagePerson = {
  _id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  profileImage?: PersonAvatar;
  role?: UserRoles;
};

export type PersonAvatar = {
  _id?: string;
  url?: string;
};
