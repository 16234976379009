import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { get } from "lodash";
import {
  DeleteScheduleTemplateResponse,
  ImportScheduleFromTemplateResponse,
  ListScheduleTemplatesResponse,
  SaveScheduleAsTemplateResponse,
} from "../../graphql/types/models/job-schedule";
import { LIST_SCHEDULE_TEMPLATES } from "../../graphql/queries/job-schedule/queries";
import {
  DeleteScheduleTemplatePayload,
  ImportScheduleFromTemplatePayload,
  SaveScheduleAsTemplatePayload,
  ScheduleCategory,
  ScheduleTemplate,
} from "../../models/job";
import {
  DELETE_SCHEDULE_TEMPLATE,
  IMPORT_JOB_SCHEDULE_FROM_TEMPLATE,
  SAVE_JOB_SCHEDULE_AS_TEMPLATE,
} from "../../graphql/queries/job-schedule/mutations";
import { notify } from "../../components/notification";
import {
  handleDeleteScheduleTemplate,
  handleImportScheduleFromTemplate,
  handleScheduleTemplateSave,
} from "../../graphql/queries/job-schedule/utils";

export type ScheduleTemplateProps = {
  jobId?: string;
  onImport?: (categories: ScheduleCategory[]) => void;
  onSave?: (template: ScheduleTemplate) => void;
};

export function useScheduleTemplateQuery(props?: ScheduleTemplateProps) {
  const { t } = useTranslation();
  const { data: templatesData } = useQuery<ListScheduleTemplatesResponse>(
    LIST_SCHEDULE_TEMPLATES
  );

  const [importScheduleFromTemplate] = useMutation<
    ImportScheduleFromTemplateResponse,
    ImportScheduleFromTemplatePayload
  >(IMPORT_JOB_SCHEDULE_FROM_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("schedule.success.importFromTemplate"),
        title: t("schedule.importFromTemplate"),
      });
      props?.onImport?.(data.jobImportScheduleFromTemplate);
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("schedule.errors.importFromTemplate")),
        title: t("schedule.importFromTemplate"),
      });
    },
    update: handleImportScheduleFromTemplate(props?.jobId),
  });

  const [deleteScheduleTemplate] = useMutation<
    DeleteScheduleTemplateResponse,
    DeleteScheduleTemplatePayload
  >(DELETE_SCHEDULE_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("schedule.success.deleteTemplate"),
        title: t("schedule.deleteTemplate"),
      });
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("schedule.errors.deleteTemplate")),
        title: t("schedule.deleteTemplate"),
      });
    },
    update: handleDeleteScheduleTemplate,
  });

  const [saveScheduleAsTemplate] = useMutation<
    SaveScheduleAsTemplateResponse,
    SaveScheduleAsTemplatePayload
  >(SAVE_JOB_SCHEDULE_AS_TEMPLATE, {
    onCompleted: (data) => {
      notify({
        content: t("schedule.success.saveAsTemplate"),
        title: t("schedule.saveAsTemplate"),
      });
      props?.onSave?.(data.jobSaveScheduleAsTemplate);
    },
    onError: (e) => {
      notify({
        error: true,
        content: get(e, "message", t("schedule.errors.saveAsTemplate")),
        title: t("schedule.saveAsTemplate"),
      });
    },
    update: handleScheduleTemplateSave,
  });

  const scheduleTemplates = React.useMemo(
    () => templatesData?.listScheduleTemplates || [],
    [templatesData]
  );

  return {
    scheduleTemplates,
    saveScheduleAsTemplate,
    importScheduleFromTemplate,
    deleteScheduleTemplate,
  };
}
