import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import Icon from "../../icons/Icon";

export const FILE_RESTRICTION_SPREADSHEET =
  "text/csv,text/x-csv,application/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv";
export type FileInputProps = {
  icon?: string;
  onUpload?: (file: File[]) => void;
  onCancel?: () => void;
  accept?: string;
  label?: string;
  title?: string;
  iconSize?: number;
  hideIcon?: boolean;
  fileName?: string | null;
};

export type FileInputRef = {
  open: () => void;
};
const FileInput: React.FC<FileInputProps> = (props, ref) => {
  const {
    hideIcon,
    icon,
    onUpload,
    onCancel,
    fileName,
    accept,
    label,
    title,
    iconSize,
  } = props;
  const { t } = useTranslation();

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      onUpload && onUpload(acceptedFiles);
    },
    [onUpload]
  );

  const iconStyles = React.useMemo(
    () => ({
      fontSize: `${iconSize}px`,
    }),
    [iconSize]
  );

  const iconName = icon ? icon : "insert_drive_file";
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept,
    onFileDialogCancel: onCancel,
  });

  const getText = () => {
    if (fileName) {
      return <p className="text">{fileName}</p>;
    }

    if (isDragActive) {
      return t("common.dragFilesHere");
    } else {
      return (
        <div>
          {title && <div className="field-text input-title">{title}</div>}
          <div
            dangerouslySetInnerHTML={{
              __html: label ? label : t("common.dropFile"),
            }}
          />
        </div>
      );
    }
  };

  React.useImperativeHandle(
    ref,
    () => ({
      open: () => {
        open();
      },
    }),
    [open]
  );

  return (
    <div {...getRootProps()} className="file-input">
      <input {...getInputProps()} />
      <div className="text-container field-text">
        {!hideIcon && (
          <div className="input-icon">
            <Icon name={iconName} outlined style={iconStyles} />
          </div>
        )}
        <div>{getText()}</div>
      </div>
    </div>
  );
};

export default React.forwardRef(FileInput);
