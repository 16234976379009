import React, { ReactElement } from "react";
import { compact, get } from "lodash";
import { useTranslation } from "react-i18next";
import { Calibration } from "../../../models/salesQuote";
import { TakeOffMeasurement, TakeOffShape } from "../../../models/take-off";
import {
  convertQuantity,
  getCalibrationFromShape,
  getShapeArea,
  getShapeDistance,
} from "../take-off-plan-viewer/utils";
import SVGIcon from "../../icons/SVGIcon";
import Icon from "../../icons/Icon";

type TakeOffListShapesProps = {
  UOM: string;
  calibrations: Calibration[];
  shapes: TakeOffShape[];
  onShapeDelete: (shape: TakeOffShape) => void;
  onShapeLabel: (shape: TakeOffShape) => void;
  onShapeClick?: (shape: TakeOffShape) => void;
};
const TakeOffListShapes: React.FC<TakeOffListShapesProps> = ({
  UOM,
  calibrations,
  shapes,
  onShapeDelete,
  onShapeLabel,
  onShapeClick,
}) => {
  const { t } = useTranslation();

  const qtyFn = React.useCallback(
    (shape: TakeOffShape, calibration: Calibration) => {
      switch (UOM) {
        case TakeOffMeasurement.MILLIMETRE:
        case TakeOffMeasurement.LINEAR_METER:
        case TakeOffMeasurement.TONNE:
          return getShapeDistance(shape, calibration);
        case TakeOffMeasurement.METER_SQUARED:
        case TakeOffMeasurement.CUBIC_METER:
          return getShapeArea(shape, calibration);
      }
      return 0;
    },
    [UOM, shapes]
  );

  const handleShapeClick = React.useCallback(
    (shape: TakeOffShape) => () => {
      onShapeClick?.(shape);
    },
    [onShapeClick]
  );

  const getTotalUnit = (qty: number, unit: string) => {
    const { quantity, UOM } = convertQuantity(qty, unit);
    return `${quantity.toLocaleString("en-us", {
      maximumFractionDigits: 2,
    })} ${UOM}`;
  };

  const renderIcon = React.useCallback((shape?: TakeOffShape) => {
    let icon = <SVGIcon name="MapPin" />;
    switch (shape?.type) {
      case "polyline":
        icon = <SVGIcon name="Line" />;
        break;
      case "polygon":
        icon = <Icon name="pentagon" outlined />;
        break;
      case "rectangle":
        icon = <Icon name="crop_3_2" />;
        break;
      case "deduction":
        icon = <SVGIcon name="Deduction" />;
        break;
      case "polylineDeduction":
        icon = <SVGIcon name="PolylineDeduction" />;
        break;
      case "rectangleDeduction":
        icon = <SVGIcon name="RectangleDeduction" />;
        break;
      case "comment":
        icon = <SVGIcon name="Speech" />;
        break;
    }
    return <div className="take-off-shapes-list-icon">{icon}</div>;
  }, []);

  const renderLabel = React.useCallback((shape?: TakeOffShape) => {
    if (!shape?.name) return null;
    return <div className="take-off-shapes-list-label">{shape.name}</div>;
  }, []);

  const renderDelete = React.useCallback(
    (shape: TakeOffShape) => {
      return (
        <div className="action" onClick={() => onShapeDelete(shape)}>
          <i className="material-icons">clear</i>
        </div>
      );
    },
    [onShapeDelete]
  );

  const renderEditLabel = React.useCallback(
    (shape: TakeOffShape) => {
      return (
        <div
          className="action action-hidden"
          onClick={() => onShapeLabel(shape)}
        >
          <i className="material-icons">label</i>
        </div>
      );
    },
    [onShapeLabel]
  );

  const renderShapes = React.useCallback(() => {
    if (UOM === TakeOffMeasurement.QUANTITY) {
      return (
        <li className="d-flex" key={`qty${shapes?.length}`}>
          <div className="d-flex">
            {renderIcon()}
            {getTotalUnit(shapes.length, UOM)}
          </div>
        </li>
      );
    }
    const items: ReactElement[] = [];
    shapes.forEach((shape) => {
      if (shape.type === "comment") {
        return items.push(
          <li
            className="d-flex flex-row justify-content-between"
            key={`shape${shape.leaflet_id}`}
            onClick={handleShapeClick(shape)}
          >
            <div className="d-flex">
              {renderIcon(shape)}
              {get(shape, "properties.text")}
            </div>
            {renderDelete(shape)}
          </li>
        );
      }
      const calibration = getCalibrationFromShape(calibrations, shape);
      if (!calibration) return;
      const quantity = qtyFn(shape, calibration);
      const total = getTotalUnit(quantity, UOM);

      let shapeDepth = null;
      if (shape.depth && UOM === TakeOffMeasurement.CUBIC_METER) {
        const rawArea = getShapeArea({ ...shape, depth: 0 }, calibration);
        const rawTotal = getTotalUnit(
          rawArea,
          TakeOffMeasurement.METER_SQUARED
        );
        shapeDepth = t("takeOffSection.shapeDepth", {
          depth: shape.depth,
          area: rawTotal,
        });
      }

      let shapeHeight = null;
      if (shape.wallHeight && UOM === TakeOffMeasurement.METER_SQUARED) {
        const rawDistance = getShapeDistance(
          { ...shape, wallHeight: 0 },
          calibration
        );
        const rawTotal = getTotalUnit(
          rawDistance,
          TakeOffMeasurement.LINEAR_METER
        );
        shapeHeight = t("takeOffSection.shapeHeight", {
          height: shape.wallHeight,
          length: rawTotal,
        });
      }

      let shapeWidth = null;
      if (shape.materialWidth && UOM === TakeOffMeasurement.LINEAR_METER) {
        const rawDistance = getShapeArea(
          { ...shape, materialWidth: 0 },
          calibration
        );
        const rawTotal = getTotalUnit(
          rawDistance,
          TakeOffMeasurement.METER_SQUARED
        );
        shapeWidth = t("takeOffSection.shapeWidth", {
          width: shape.materialWidth,
          area: rawTotal,
        });
      }
      let shapeAngle = null;
      if (shape.angle && UOM === TakeOffMeasurement.METER_SQUARED) {
        const rawArea = getShapeArea({ ...shape, angle: 0 }, calibration);
        const rawTotal = getTotalUnit(
          rawArea,
          TakeOffMeasurement.METER_SQUARED
        );
        shapeWidth = t("takeOffSection.shapeAngle", {
          angle: shape.angle,
          area: rawTotal,
        });
      }
      if (shape.angle && UOM === TakeOffMeasurement.LINEAR_METER) {
        const rawDistance = getShapeDistance(
          { ...shape, angle: 0 },
          calibration
        );
        const rawTotal = getTotalUnit(
          rawDistance,
          TakeOffMeasurement.LINEAR_METER
        );
        shapeWidth = t("takeOffSection.shapeAngle", {
          angle: shape.angle,
          area: rawTotal,
        });
      }
      let shapeWeight = null;
      if (shape.weight && UOM === TakeOffMeasurement.TONNE) {
        const rawArea = getShapeDistance({ ...shape, weight: 0 }, calibration);
        const rawTotal = getTotalUnit(rawArea, TakeOffMeasurement.LINEAR_METER);
        shapeWeight = t("takeOffSection.shapeWeight", {
          weight: shape.weight,
          area: rawTotal,
        });
      }

      const features = compact([
        shapeDepth,
        shapeHeight,
        shapeWidth,
        shapeAngle,
        shapeWeight,
      ]).join(", ");

      items.push(
        <li
          className="take-off-shape-item d-flex flex-column"
          key={`shape${shape.leaflet_id}`}
          onClick={handleShapeClick(shape)}
        >
          {renderLabel(shape)}
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex">
              {renderIcon(shape)}
              {total}
            </div>
            <div className="d-flex">
              <div>{features}</div>
              {renderEditLabel(shape)}
              {renderDelete(shape)}
            </div>
          </div>
        </li>
      );
    });
    return items;
  }, [UOM, shapes, qtyFn, handleShapeClick]);

  return <ul className="take-off-shapes-list">{renderShapes()}</ul>;
};

export default TakeOffListShapes;
