import { gql } from "@apollo/client";

export const LIST_SUPPLIER_CERTIFICATION = gql`
  query listSupplierCertification($supplierId: ID!) {
    listSupplierCertification(supplierId: $supplierId) {
      _id
      name
      document_type
      files {
        _id
        name
        url
        upload_url
        type
        size
      }
      expiry_date
      note
    }
  }
`;
