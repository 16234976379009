import * as yup from "yup";
import { TFunction } from "i18next";
import { BusinessInvoice } from "../../../../graphql/types/models/business";

export default function editInvoiceSchema(t: TFunction) {
  return yup.object().shape<BusinessInvoice>({
    defaultDueDays: yup.number().optional(),
    bankName: yup.string().optional(),
    accountName: yup.string().optional(),
    BSB: yup.string().optional(),
    accountNumber: yup.string().optional(),
    paymentTerms: yup.string().optional(),
  });
}
