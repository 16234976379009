import React from "react";
import { Helmet } from "react-helmet";
import { useLazyQuery } from "@apollo/client";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { compact, map, omit, reverse } from "lodash";
import { Pagination } from "../../../models/pagination";
import {
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_PAGINATION_PAGE,
} from "../../../constants/pagination";
import Dropdown, { DropdownItem } from "../../../components/dashboard/dropdown";
import { PaginationButton } from "../../../components/dashboard/card-pagination";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import DashboardActionHeader from "../../../components/dashboard/table-card/DashboardActionHeader";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import { GLOBAL_JOB_SEARCH } from "../../../graphql/queries/job/queries";
import {
  CommonOrderListItem,
  GlobalSearchPagination,
  JobGlobalSearchItems,
  JobGlobalSearchPayload,
  JobGlobalSearchResponse,
  JobSearchType,
} from "../../../graphql/types/models/job-search";
import CategorySelectorCard, {
  CategorySelectorPayload,
} from "../../../components/category-select-card";
import OrderCard from "../../../components/order/order-card";
import CardPlaceholder from "../../../components/dashboard/card-placeholder";
import { GetOrderResponse } from "../../../graphql/types/models/order";
import { GET_ORDER } from "../../../graphql/queries/order/queries";
import {
  PurchaseOrderReal,
  PurchaseOrderReceipt,
  PurchaseOrderStatus,
} from "../../../models/purchaseOrder";
import { formatQuoteNumber } from "../../../utils/text";
import moment from "moment";
import { Roster, RosterStatus } from "../../../models/roster";
import { VariationReal, VariationStatuses } from "../../../models/variations";
import {
  ClaimReal,
  ClaimStatuses,
  ClaimStatusesEnum,
} from "../../../models/claim";
import {
  getOrdersBadgeVariant,
  setEmptyPlaceholder,
  setOrderFormat,
  setOrderStatuses,
} from "./utils";
import VariationCard from "../../../components/variation/variation-card";
import { GetVariationResponse } from "../../../graphql/types/models/job-variation";
import { GET_VARIATION } from "../../../graphql/queries/job-variation/queries";
import { GET_ROSTER } from "../../../graphql/queries/job-roster/queries";
import { GetRosterResponse } from "../../../graphql/types/models/job-roster";
import RosterCard from "../../../components/roster/roster-card";
import { GetClaimResponse } from "../../../graphql/types/models/job-claim";
import { GET_CLAIM } from "../../../graphql/queries/job-claim/queries";
import ClaimCard from "../../../components/claims/claim-card";
import GlobalSearchInput from "./global-search-input";
import "./styles.scss";

type Filter = {
  type: JobSearchType;
  fieldKey:
    | "purchaseOrderReceipts"
    | "progressClaims"
    | "variations"
    | "rosters"
    | "purchaseOrders";
};

type StatusOption = {
  status: string;
  label: string;
};

const GlobalSearchContainer: React.FC = () => {
  const { t } = useTranslation();
  const [purchaseOrders, setPurchaseOrders] = React.useState<
    PurchaseOrderReal[] | []
  >([]);
  const [rosters, setRosters] = React.useState<Roster[] | []>([]);
  const [variations, setVariations] = React.useState<VariationReal[] | []>([]);
  const [progressClaims, setProgressClaims] = React.useState<ClaimReal[] | []>(
    []
  );
  const [purchaseOrderReceipts, setPurchaseOrderReceipts] = React.useState<
    PurchaseOrderReceipt[] | []
  >([]);

  const [currentType, setType] = React.useState<Filter>({
    type: JobSearchType.JOB_PURCHASE_ORDER,
    fieldKey: "purchaseOrders",
  });
  const [currentStatus, setCurrentStatus] = React.useState<StatusOption>({
    status: "",
    label: t("common.all"),
  });
  const [listPagination, setListPagination] = React.useState<Pagination>();
  const [searchInput, setSearchInput] = React.useState("");
  const [globalSearchPagitation, setGlobalSearchPagitation] = React.useState<
    GlobalSearchPagination
  >({
    page: DEFAULT_PAGINATION_PAGE,
    limit: DEFAULT_PAGINATION_LIMIT,
  });
  const [activeItem, setActiveItem] = React.useState({
    id: "",
  });

  const handleChangeType = React.useCallback(
    (data: JobGlobalSearchResponse) => {
      switch (currentType.type) {
        case JobSearchType.JOB_PURCHASE_ORDER:
          return setPurchaseOrders(
            reverse([...data.searchJob?.purchaseOrders])
          );
        case JobSearchType.JOB_ROSTER:
          return setRosters(reverse([...data.searchJob?.rosters]));
        case JobSearchType.JOB_VARIATION:
          return setVariations(reverse([...data.searchJob?.variations]));
        case JobSearchType.JOB_PROGRESS_CLAIM:
          return setProgressClaims(
            reverse([...data.searchJob?.progressClaims])
          );
        case JobSearchType.JOB_PURCHASE_ORDER_RECEIPT:
          setCurrentStatus({ status: "", label: t("common.all") });
          return setPurchaseOrderReceipts(
            reverse([...data.searchJob?.purchaseOrderReceipts])
          );
      }
    },
    [currentType.type]
  );

  const [
    jobSearchList,
    { data: jobSearchListData, loading: jobSearchListLoading },
  ] = useLazyQuery<JobGlobalSearchResponse, JobGlobalSearchPayload>(
    GLOBAL_JOB_SEARCH,
    {
      fetchPolicy: "network-only",
      variables: {
        filters: {
          type: currentType.type,
          keyword: searchInput,
          status: currentStatus.status,
        },
        pagination: globalSearchPagitation,
      },
      onCompleted: (data) => {
        if (data) {
          resetAllStates();
          handleChangeType(data);
          setListPagination(
            omit(data.searchJob, [
              "purchaseOrderReceipts",
              "progressClaims",
              "variations",
              "rosters",
              "purchaseOrders",
            ])
          );
        }
      },
    }
  );

  const resetStatus = React.useCallback(() => {
    setCurrentStatus({ status: "", label: t("common.all") });
  }, []);

  const resetAllStates = React.useCallback(() => {
    setRosters([]);
    setVariations([]);
    setProgressClaims([]);
    setPurchaseOrderReceipts([]);
    setPurchaseOrders([]);
  }, []);

  const handleSearch = React.useCallback((value: string) => {
    setSearchInput(value);
    resetPagination();
  }, []);

  const resetPagination = React.useCallback(() => {
    setGlobalSearchPagitation({
      ...globalSearchPagitation,
      page: 1,
    });
  }, [globalSearchPagitation]);

  const handleListPagination = React.useCallback(
    (type: PaginationButton) => {
      if (type === PaginationButton.Next) {
        setGlobalSearchPagitation({
          ...globalSearchPagitation,
          page: globalSearchPagitation.page + 1,
        });
      }
      if (type === PaginationButton.Previous) {
        setGlobalSearchPagitation({
          ...globalSearchPagitation,
          page: globalSearchPagitation.page - 1,
        });
      }
    },
    [globalSearchPagitation, setGlobalSearchPagitation]
  );

  const [
    getPurchaseOrder,
    { loading: purchaseOrderLoading, data: purchaseOrderDetails },
  ] = useLazyQuery<GetOrderResponse>(GET_ORDER);

  const [
    getVariation,
    { loading: variationLoading, data: variationDetails },
  ] = useLazyQuery<GetVariationResponse>(GET_VARIATION);

  const [
    getRoster,
    { loading: rosterLoading, data: rosterDetails },
  ] = useLazyQuery<GetRosterResponse>(GET_ROSTER);

  const [
    getClaim,
    { data: claimDetails, loading: claimLoading },
  ] = useLazyQuery<GetClaimResponse>(GET_CLAIM);

  const isLoading = React.useMemo(() => {
    return (
      purchaseOrderLoading || variationLoading || rosterLoading || claimLoading
    );
  }, [claimLoading, purchaseOrderLoading, rosterLoading, variationLoading]);

  const handleGetItemById = React.useCallback(
    async (jobId: string, id: string) => {
      switch (currentType.type) {
        case JobSearchType.JOB_VARIATION:
          getVariation({
            variables: {
              jobId,
              variationId: id,
            },
          });
          break;
        case JobSearchType.JOB_ROSTER:
          getRoster({
            variables: {
              jobId,
              rosterId: id,
            },
          });
          break;
        case JobSearchType.JOB_PROGRESS_CLAIM:
          getClaim({
            variables: {
              jobId,
              progressClaimId: id,
            },
          });
          break;
        default:
          getPurchaseOrder({
            variables: {
              jobId,
              purchaseOrderId: id,
            },
          });
      }
    },
    [currentType.type, getClaim, getPurchaseOrder, getRoster, getVariation]
  );

  const purchaseOrder = React.useMemo(
    () => purchaseOrderDetails?.getJobPurchaseOrderById,
    [purchaseOrderDetails]
  );

  const variation = React.useMemo(
    () => variationDetails?.getJobGetVariationById,
    [variationDetails]
  );

  const roster = React.useMemo(() => rosterDetails?.getJobRosterById, [
    rosterDetails,
  ]);

  const claim = React.useMemo(() => claimDetails?.getJobProgressClaim, [
    claimDetails,
  ]);

  const currentItems = React.useMemo<JobGlobalSearchItems>(
    () => ({
      purchaseOrders,
      rosters,
      variations,
      progressClaims,
      purchaseOrderReceipts,
    }),
    [progressClaims, purchaseOrderReceipts, purchaseOrders, rosters, variations]
  );
  const isEmptyList = React.useMemo(() => {
    const items = currentItems[currentType.fieldKey];
    if (items) {
      return items.length === 0;
    }
  }, [currentItems, currentType.fieldKey]);

  const orderCategories = React.useMemo<CategorySelectorPayload[]>(() => {
    const orders: any = currentItems[currentType.fieldKey];
    const isPurchaseOrderReceipts =
      currentType.fieldKey === "purchaseOrderReceipts";

    return map(orders, (order: CommonOrderListItem) => {
      const { supplier, receipts } = order;
      let subTotal = !isPurchaseOrderReceipts
        ? order.subTotal
        : order.purchaseOrder.subTotal;
      if (receipts?.length || order?.purchaseOrder?.receipts?.length) {
        isPurchaseOrderReceipts
          ? (subTotal = order?.purchaseOrder?.receipts.reduce(
              (total, receipt) => total + receipt.subTotal,
              0
            ))
          : (subTotal = receipts.reduce(
              (total, receipt) => total + receipt.subTotal,
              0
            ));
      }

      const supplierName = supplier?.contact_person || supplier?.business_name;
      const date = isPurchaseOrderReceipts
        ? order.purchaseOrder.createdAt
        : order.date;

      return {
        id: order._id,
        label: `${formatQuoteNumber(
          isPurchaseOrderReceipts
            ? order.purchaseOrder.orderNumber
            : order.orderNumber,
          setOrderFormat[currentType.fieldKey]
        )} / ${formatQuoteNumber(order?.job?.jobNumber, "J")}`,
        description: compact([
          moment(date).format("D MMMM YYYY"),
          compact([order.reference, supplierName]).join(" - "),
        ]).join(": "),
        rightLabel: subTotal
          ? t("common.currency", { amount: subTotal })
          : null,
        rightBadge: [
          {
            variant: !isPurchaseOrderReceipts
              ? getOrdersBadgeVariant(order.status)
              : getOrdersBadgeVariant(order.purchaseOrder.status),
            label: t(
              `${setOrderStatuses[currentType.fieldKey]}.statuses.${
                !isPurchaseOrderReceipts
                  ? order.status
                  : order.purchaseOrder.status
              }`
            ),
          },
        ],
      };
    });
  }, [currentItems, currentType.fieldKey, t]);

  React.useEffect(() => {
    resetPagination();
    jobSearchList();
  }, []);

  React.useEffect(() => {
    if (jobSearchListData) {
      const items = currentItems[currentType.fieldKey];

      if (items && items?.length > 0) {
        const firstOrder = items[0];
        if (firstOrder && firstOrder?.job) {
          if (currentType.type === JobSearchType.JOB_PURCHASE_ORDER_RECEIPT) {
            const id = firstOrder as PurchaseOrderReceipt;
            if (id?.purchaseOrder) {
              handleGetItemById(firstOrder?.job?._id, id.purchaseOrder._id);
            }
          } else {
            handleGetItemById(firstOrder?.job?._id, firstOrder._id);
          }
        }
      }
    }
  }, [currentItems, jobSearchListData]);

  const filterByTypeDropdownItems = React.useMemo<DropdownItem[]>(
    () => [
      {
        id: JobSearchType.JOB_PURCHASE_ORDER,
        label: t("navigation.jobsSection.purchaseOrders"),
        onClick: () => {
          resetPagination();
          resetStatus();
          setType({
            fieldKey: "purchaseOrders",
            type: JobSearchType.JOB_PURCHASE_ORDER,
          });
        },
      },
      {
        id: JobSearchType.JOB_PURCHASE_ORDER_RECEIPT,
        label: t("navigation.jobsSection.purchaseOrderReceipts"),
        onClick: () => {
          resetPagination();
          resetStatus();
          setType({
            fieldKey: "purchaseOrderReceipts",
            type: JobSearchType.JOB_PURCHASE_ORDER_RECEIPT,
          });
        },
      },
      {
        id: JobSearchType.JOB_PROGRESS_CLAIM,
        label: t("navigation.jobsSection.progressClaims"),
        onClick: () => {
          resetPagination();
          resetStatus();
          setType({
            fieldKey: "progressClaims",
            type: JobSearchType.JOB_PROGRESS_CLAIM,
          });
        },
      },
      {
        id: JobSearchType.JOB_VARIATION,
        label: t("navigation.jobsSection.variations"),
        onClick: () => {
          resetPagination();
          resetStatus();
          setType({
            fieldKey: "variations",
            type: JobSearchType.JOB_VARIATION,
          });
        },
      },
      {
        id: JobSearchType.JOB_ROSTER,
        label: t("navigation.jobsSection.rosters"),
        onClick: () => {
          resetPagination();
          resetStatus();
          setType({
            fieldKey: "rosters",
            type: JobSearchType.JOB_ROSTER,
          });
        },
      },
    ],
    []
  );

  const handleStatusChange = React.useCallback(
    (status, label) => () => {
      setCurrentStatus({ status, label });
      jobSearchList();
    },
    [resetStatus]
  );

  const statusItems = React.useMemo<DropdownItem[]>(() => {
    const options = [
      {
        id: "all",
        label: t("common.all"),
        onClick: resetStatus,
      },
    ];
    switch (currentType.type) {
      case JobSearchType.JOB_PROGRESS_CLAIM:
        return options
          .concat(
            Object.values(ClaimStatusesEnum).map((status) => ({
              id: status,
              label: t(`claims.statuses.${status}`),
              onClick: handleStatusChange(
                status,
                t(`claims.statuses.${status}`)
              ),
            }))
          )
          .concat([
            {
              id: "overdue",
              label: t(`claims.statuses.OVERDUE`),
              onClick: handleStatusChange(
                "OVERDUE",
                t(`claims.statuses.OVERDUE`)
              ),
            },
          ]);
      case JobSearchType.JOB_ROSTER:
        return options.concat(
          Object.values(RosterStatus)
            .filter((s) => s !== RosterStatus.CANCELLED)
            .map((status) => ({
              id: status,
              label: t(`roster.statuses.${status}`),
              onClick: handleStatusChange(
                status,
                t(`roster.statuses.${status}`)
              ),
            }))
        );
      case JobSearchType.JOB_VARIATION:
        return options.concat(
          Object.values(VariationStatuses)
            .filter((s) => s !== VariationStatuses.CANCELLED)
            .map((status) => ({
              id: status,
              label: t(`variations.statuses.${status}`),
              onClick: handleStatusChange(
                status,
                t(`variations.statuses.${status}`)
              ),
            }))
        );
      case JobSearchType.JOB_PURCHASE_ORDER:
        return options.concat(
          Object.values(PurchaseOrderStatus)
            .filter((s) => s !== PurchaseOrderStatus.CANCELLED)
            .map((status) => ({
              id: status,
              label: t(`orders.statuses.${status}`),
              onClick: handleStatusChange(
                status,
                t(`orders.statuses.${status}`)
              ),
            }))
        );
      case JobSearchType.JOB_PURCHASE_ORDER_RECEIPT:
      default:
        return [];
    }
  }, [handleStatusChange, currentType]);

  console.log({ statusItems });

  const filters = React.useMemo(
    () =>
      compact([
        <Dropdown
          label={t(`navigation.jobsSection.${currentType.fieldKey}`)}
          icon="expand_more"
          id="filter-by-type"
          items={filterByTypeDropdownItems}
          alignRight
        />,
        statusItems.length ? (
          <Dropdown
            label={currentStatus.label}
            icon="expand_more"
            id="filter-by-type"
            items={statusItems}
            alignRight
          />
        ) : null,
      ]),
    [currentType, filterByTypeDropdownItems, statusItems, currentStatus]
  );

  const handleSelectCategory = React.useCallback(
    (filter) => {
      const current = currentItems[currentType.fieldKey];
      if (current) {
        current.forEach((elem) => {
          if (elem._id === filter.id) {
            if (elem?.job) {
              if (
                currentType.type === JobSearchType.JOB_PURCHASE_ORDER_RECEIPT
              ) {
                const id = elem as PurchaseOrderReceipt;
                if (id?.purchaseOrder) {
                  handleGetItemById(elem?.job?._id, id.purchaseOrder._id);
                }
              } else {
                handleGetItemById(elem?.job?._id, elem._id);
              }
            }
          }
        });
      }

      setActiveItem(filter);
    },
    [currentItems, currentType.fieldKey, handleGetItemById]
  );

  const renderPurchaseOrderCard = React.useMemo(() => {
    if (!purchaseOrder || isLoading || isEmptyList) {
      return <CardPlaceholder />;
    }
    return <OrderCard order={purchaseOrder} withoutControls isGlobalView />;
  }, [purchaseOrder, isLoading, isEmptyList]);

  const renderVariationCard = React.useMemo(() => {
    if (!variation || isLoading || isEmptyList) {
      return <CardPlaceholder />;
    }
    return (
      <VariationCard
        variation={variation}
        titleIndex={variation?.variationNumber}
        withoutControls
        isGlobalView
      />
    );
  }, [variation, isLoading, isEmptyList]);

  const renderRosterCard = React.useMemo(() => {
    if (!roster || isLoading || isEmptyList) {
      return <CardPlaceholder />;
    }

    return <RosterCard {...roster} withoutControls isGlobalView />;
  }, [isLoading, roster, isEmptyList]);

  const renderClaimCard = React.useMemo(() => {
    if (!claim || isLoading || isEmptyList) {
      return <CardPlaceholder />;
    }

    return <ClaimCard claim={claim} withoutControls isGlobalView />;
  }, [claim, isLoading, isEmptyList]);

  const renderOrderCard = React.useCallback(() => {
    switch (currentType.type) {
      case JobSearchType.JOB_ROSTER:
        return renderRosterCard;
      case JobSearchType.JOB_VARIATION:
        return renderVariationCard;
      case JobSearchType.JOB_PROGRESS_CLAIM:
        return renderClaimCard;
      default:
        return renderPurchaseOrderCard;
    }
  }, [
    currentType.type,
    renderPurchaseOrderCard,
    renderRosterCard,
    renderVariationCard,
    renderClaimCard,
  ]);

  return (
    <Container fluid className="m-0 p-0 h-100 global-search-container">
      <Helmet title={t("navigation.jobsSection.search")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.SEARCH} />
      <DashboardActionHeader>
        {filters.map((filter, index) => (
          <div className="pr-2 pb-4" key={index}>
            {filter}
          </div>
        ))}
        <GlobalSearchInput disabled={isLoading} onSearch={handleSearch} />
      </DashboardActionHeader>

      <Row>
        <Col lg={4} xs={12} className="selectorCard-container">
          <CategorySelectorCard
            title={t(`navigation.jobsSection.${currentType.fieldKey}`)}
            filter={activeItem}
            onSelectCategory={handleSelectCategory}
            categories={orderCategories}
            hideSelectAllButton={true}
            pagination={listPagination}
            onPagination={handleListPagination}
            hideAddButton={true}
            headerClassName="select-category-header"
            placeholder={t(
              `placeholders.${setEmptyPlaceholder[currentType.fieldKey]}`
            )}
          />
        </Col>
        <Col lg={8} xs={12}>
          {renderOrderCard()}
        </Col>
      </Row>
    </Container>
  );
};

export default GlobalSearchContainer;
