import LeftModal from "../../left-modal";
import React, { ReactNode } from "react";
import { Button, Form } from "react-bootstrap";
import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalFooter from "../../left-modal/LeftModalFooter";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers, FormikProps } from "formik";

type ModalFormProps<TData> = {
  title: string;
  className?: string;
  data: TData;
  show: boolean;
  validationSchema?: any;
  editMode?: boolean;
  submitText?: string;
  enableReinitialize?: boolean;
  onSubmit: (data: TData, formikHelpers?: FormikHelpers<TData>) => void;
  onClose: () => void;
  children?: (helpers: FormikProps<TData>) => ReactNode;
  leftFooterRenderer?: (helpers: FormikProps<TData>) => ReactNode;
  formikRef?: React.Ref<FormikProps<TData>>;
};

function ModalForm<TData extends {}>({
  title,
  data,
  show,
  validationSchema,
  onClose,
  onSubmit,
  editMode,
  className,
  submitText,
  children,
  leftFooterRenderer,
  enableReinitialize = true,
  formikRef,
}: ModalFormProps<TData>) {
  const { t } = useTranslation();

  const submitBtnText = submitText
    ? submitText
    : editMode
    ? t("common.update")
    : t("common.create");

  return (
    <LeftModal className={className} show={show} onHide={onClose}>
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        enableReinitialize={enableReinitialize}
        initialValues={data}
        onSubmit={(
          values: TData,
          formikHelpers: FormikHelpers<TData>
        ): void | Promise<any> => {
          return onSubmit(values, formikHelpers);
        }}
      >
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            onReset={formikProps.handleReset}
            noValidate
          >
            <LeftModalHeader title={title} onClose={onClose} />
            <LeftModalBody>{children && children(formikProps)}</LeftModalBody>
            <LeftModalFooter>
              {leftFooterRenderer && leftFooterRenderer(formikProps)}
              <Button
                variant="secondary"
                className="button large info"
                onClick={onClose}
                disabled={formikProps.isSubmitting}
              >
                {t("common.cancel")}
              </Button>
              <Button
                disabled={formikProps.isSubmitting || !formikProps.isValid}
                variant="primary"
                className="button large success"
                type="submit"
              >
                {submitBtnText}
              </Button>
            </LeftModalFooter>
          </Form>
        )}
      </Formik>
    </LeftModal>
  );
}

export default ModalForm;
