import React from "react";
import { GenericFormField, RenderField } from "../GenericFormBody";
import { FormikProps } from "formik";
import { get } from "lodash";

type TableFormCellProps = {
  cell: GenericFormField<any, any>;
  valueKey: string;
  rowIndex: number;
  rowKey: string;
  formikProps: Omit<FormikProps<any>, "handleSubmit" | "handleReset">;
  isNotTable?: boolean;
};

const TableFormCell: React.FC<TableFormCellProps> = (props) => {
  const { cell, valueKey, formikProps, rowIndex, rowKey, isNotTable } = props;
  const initialized = React.useRef<boolean>();

  const fieldValue = React.useMemo(() => get(formikProps.values, valueKey), [
    formikProps,
  ]);
  const rowValues = React.useMemo(() => get(formikProps.values, rowKey), [
    formikProps,
  ]);

  const cellProps = React.useMemo(() => {
    return {
      ...cell,
      ...cell.dynamicProps?.(formikProps, valueKey, rowIndex),
    };
  }, [cell]);

  const isFieldDisabled = React.useMemo(() => {
    if (cellProps.disabled || cellProps.creatableProps?.isDisabled) return true;
    return cell.disableRowField && cell.disableRowField(rowValues, rowIndex);
  }, [cellProps, rowIndex, rowValues]);

  const cellData = React.useMemo(() => {
    return {
      ...cellProps,
      valueKey,
      disabled: isFieldDisabled,
      creatableProps: {
        ...cell.creatableProps,
        isDisabled: isFieldDisabled,
      },
    };
  }, [valueKey, cellProps, isFieldDisabled]);

  // Workaround to prevent updateTableRow running on first load
  React.useEffect(() => {
    if (initialized.current) {
      const { updateTableRow } = cell;
      updateTableRow &&
        updateTableRow(fieldValue, rowIndex, formikProps, valueKey);
    }
  }, [fieldValue, rowIndex]);

  React.useEffect(() => {
    initialized.current = true;
  }, []);

  return !isNotTable ? (
    <td>
      <RenderField
        field={cellData}
        {...formikProps}
        variant="table"
        rowIndex={rowIndex}
      />
    </td>
  ) : (
    <div style={{ width: cell.maxWidth || "100%" }}>
      <RenderField
        field={cellData}
        {...formikProps}
        variant="table"
        rowIndex={rowIndex}
      />
    </div>
  );
};

export default TableFormCell;
