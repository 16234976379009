import { gql } from "@apollo/client";

export const GET_REPORT = gql`
  query($reportType: ReportType!, $filters: ReportFilters!) {
    getReport(reportType: $reportType, filters: $filters) {
      header {
        key
        format
      }
      rows {
        _id
        cells {
          key
          value
        }
      }
      summary {
        key
        value
      }
    }
  }
`;

export const DOWNLOAD_REPORT = gql`
  query($reportType: ReportType!, $filters: ReportFilters!) {
    downloadReport(reportType: $reportType, filters: $filters) {
      _id
      filename
      data
    }
  }
`;
