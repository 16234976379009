import { initReactI18next } from "react-i18next";
import moment from "moment";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        format = format!.replace(/^['"]|['"]$/g, "");
        if (value instanceof Date) {
          moment.locale(lng);
          return moment(value).format(format || "DD.MM.YYYY");
        }

        if (format === "currency") {
          return new Intl.NumberFormat(lng, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value);
        }

        if (format === "bytes") {
          const KB = value / 1024;
          const MB = KB / 1024;
          let unit = "KB";
          let calculatedvalue = KB;
          if (MB > 1) {
            calculatedvalue = MB;
            unit = "MB";
          }

          const formattedValue = new Intl.NumberFormat(lng, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(calculatedvalue);

          return `${formattedValue} ${unit}`;
        }

        return value;
      },
    },
    // react: {
    //   useSuspense: false
    // }
  });

export default i18n;
