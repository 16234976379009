import { gql } from "@apollo/client";
import { SIGNATURE_FRAGMENT } from "../../job-variation/fragments";

export const CLIENT_LIST_VARIATIONS = gql`
  query ClientGetJobVariations($jobId: ID!) {
    clientGetJobVariations(jobId: $jobId) {
      _id
      status
      variationNumber
      subTotal
      date
    }
  }
`;

export const GET_CLIENT_VARIATION_PREVIEW = gql`
  query ClientGetJobVariationPreview($variationId: ID!, $jobId: ID) {
    clientGetJobVariationPreview(variationId: $variationId, jobId: $jobId) {
      _id
      pdf
    }
  }
`;

export const GET_CLIENT_VARIATION = gql`
  query ClientGetJobVariationById($jobId: ID!, $variationId: ID!) {
    clientGetJobVariationById(jobId: $jobId, variationId: $variationId) {
      _id
      name
      status
      total
      GST
      note
      variationNumber
      date
      clientSignature {
        ...SignatureFragment
      }
      builderSignature {
        ...SignatureFragment
      }
      subTotal
      contact {
        last_name
        first_name
        email
      }
      items {
        _id
        name
        UOM
        cost
        quantity
        margin_amount
        wastage
        rounding
        costingCategoryName
        costingItemId
      }
    }
  }
  ${SIGNATURE_FRAGMENT}
`;
