import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/client";

import DashboardLayout from "../../layouts/dashboard";
import { RootReducerState } from "../../../redux/reducer";
import {
  getUser,
  getUserFailed,
  getUserSuccess,
} from "../../../redux/authentication/actions";
import { DashboardContainerProps } from "../../dashboard";

import { AFFILIATE_PROFILE } from "../../../graphql/queries/affiliate/queries";
import { AffiliateProfileResponse } from "../../../graphql/types/models/affiliate";
import AffiliateSignups from "../signups";
import AffiliateProfileContainer from "../profile/profile-container";

const AffiliateDashboardContainer: React.FC<DashboardContainerProps> = ({
  getUser,
  getUserFailed,
  getUserSuccess,
  isAuthenticated,
  user,
}) => {
  const [getProfile] = useLazyQuery<AffiliateProfileResponse>(
    AFFILIATE_PROFILE,
    {
      onCompleted: (data) => {
        getUserSuccess(data.affiliateProfile as any, false, false, true);
      },
      onError: (error) => {
        getUserFailed(error.message);
      },
    }
  );

  React.useEffect(() => {
    if (user && isAuthenticated) {
      return;
    }

    getUser();
    getProfile();
  }, [user, isAuthenticated, getProfile, getUser]);

  return (
    <DashboardLayout>
      <Switch>
        {/* <Route exact path="/" component={HomeContainer} /> */}
        <Route exact path="/signups" component={AffiliateSignups} />
        <Route path="/account" component={AffiliateProfileContainer} />
        <Route>
          <Redirect to="/signups" />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
    isAuthenticated: state.authentication.isAuthenticated,
    isAuthenticating: state.authentication.isAuthenticating,
  };
};

export default connect(mapStateToProps, {
  getUser,
  getUserSuccess,
  getUserFailed,
})(AffiliateDashboardContainer);
