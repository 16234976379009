import React, { useImperativeHandle } from "react";
import { compact, filter, omit } from "lodash";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import LeftModal from "../components/left-modal";
import LeftModalHeader from "../components/left-modal/LeftModalHeader";
import LeftModalBody from "../components/left-modal/LeftModalBody";
import LeftModalFooter from "../components/left-modal/LeftModalFooter";
import { ContactDetailResponse } from "../graphql/types/models/client";
import { GET_CONTACT } from "../graphql/queries/client/queries";
import CreateClientModal from "../components/contacts/create-client-modal";
import { CreateClientPayload } from "../models/client";
import { CREATE_UPDATE_CLIENT } from "../graphql/queries/client/mutations";
import { notify } from "../components/notification";
import { DetailsCardAction } from "../components/dashboard/details-card";
import DetailsCardList, {
  DetailsCardDataItem,
} from "../components/dashboard/details-card/DetailsCardList";
import { getFullName } from "../utils/text";
import Loader from "../components/loader";

export type ShowContactModalRef = {
  show: (show: boolean, contactId: string) => void;
};

export function useShowContactModal(ref: any) {
  const [showClientModal, setShowClientModal] = React.useState(false);
  const [showEditClientModal, setShowEditClientModal] = React.useState(false);
  const [id, setId] = React.useState("");

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    show: (show: boolean, contactId: string) => {
      setShowClientModal(show);
      setId(contactId);
    },
  }));

  const [
    getContactDetails,
    { data: contactData, loading: contactLoading },
  ] = useLazyQuery<ContactDetailResponse>(GET_CONTACT, {
    variables: {
      id,
    },
  });

  React.useEffect(() => {
    getContactDetails();
  }, [getContactDetails, id]);

  const handleClientModalCancel = React.useCallback(() => {
    setShowClientModal(false);
  }, []);
  const handleEditClientModalCancel = React.useCallback(() => {
    setShowEditClientModal(false);
  }, []);

  const [updateClient] = useMutation(CREATE_UPDATE_CLIENT, {
    onCompleted: () => {
      setShowEditClientModal(false);
    },
  });

  const handleEditClientModalSubmit = React.useCallback(
    async (data: CreateClientPayload) => {
      const preparedData = {
        ...data,
        address: data.address ? data.address : "",
      };
      try {
        await updateClient({
          variables: {
            contact: omit(preparedData, ["jobs", "salesQuotes", "__typename"]),
          },
        });
        notify({
          content: t("contacts.success.updateContact"),
          title: t("contacts.updateContact"),
        });
      } catch (e) {
        notify({
          error: true,
          content: t("contacts.errors.updateContact"),
          title: t("contacts.updateContact"),
        });
      }
    },
    [t, updateClient]
  );

  const detailsCardData: DetailsCardDataItem[] = React.useMemo(() => {
    const contact = contactData?.getContactDetails;

    if (contactLoading || !contact) {
      return [];
    }

    return filter(
      [
        {
          icon: "person",
          text: `${contact.first_name} ${contact.last_name}`,
        },
        {
          icon: "label",
          text: t(`contacts.${contact.phase}`),
        },
        {
          icon: "room",
          text: compact([
            contact.address,
            contact.address2,
            contact.city,
            contact.state,
            contact.postcode,
          ]).join(", "),
        },
        {
          icon: "mail",
          text: contact.email,
        },
        {
          icon: "local_phone",
          text: compact([contact.phone, contact.phone_secondary]).join(", "),
        },
      ],
      (r) => !!r.text
    );
  }, [contactData?.getContactDetails, contactLoading, t]);

  const handleEditButtonClick = React.useCallback(() => {
    setShowEditClientModal(true);
  }, []);

  const detailsCardActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("common.edit"),
        onClick: handleEditButtonClick,
        className: "button info",
        disabled: contactLoading,
      },
    ],
    [t, contactLoading, handleEditButtonClick]
  );

  const clientFullName = React.useMemo(() => {
    return getFullName(contactData?.getContactDetails);
  }, [contactData?.getContactDetails]);

  const renderContactDetailsModal = React.useCallback(() => {
    return (
      <LeftModal show={showClientModal} onHide={handleClientModalCancel}>
        <LeftModalHeader
          title={clientFullName}
          onClose={handleClientModalCancel}
        />
        <LeftModalBody className="d-block details-card">
          {contactLoading ? (
            <div
              style={{ height: "calc(100vh - 250px)" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Loader />
            </div>
          ) : (
            <DetailsCardList items={detailsCardData} />
          )}

          {contactData?.getContactDetails && (
            <CreateClientModal
              disabled={contactLoading}
              client={contactData?.getContactDetails}
              show={showEditClientModal}
              onClose={handleEditClientModalCancel}
              onSubmit={handleEditClientModalSubmit}
            />
          )}
        </LeftModalBody>
        <LeftModalFooter className="justify-content-end">
          <Button
            variant="success"
            className="button large success"
            disabled={false}
            onClick={handleEditButtonClick}
          >
            {t("common.edit")}
          </Button>
        </LeftModalFooter>
      </LeftModal>
    );
  }, [
    clientFullName,
    contactData?.getContactDetails,
    contactLoading,
    detailsCardActions,
    detailsCardData,
    handleClientModalCancel,
    handleEditClientModalCancel,
    handleEditClientModalSubmit,
    showClientModal,
    showEditClientModal,
    t,
  ]);

  return { renderContactDetailsModal };
}
