import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { CONVERSATION_TOOLBAR } from "../../generic-form/inputs/rich-editor/configs";
import "./styles.scss";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { draftToMarkdown } from "markdown-draft-js";
import classNames from "classnames";
import { EmojiData } from "emoji-mart";
import { createEmojiEntity } from "./utils";
import { DECORATORS } from "./decorators";
import Toolbar from "./toolbar";
import draftToHtml from "draftjs-to-html";

type ComposeProps = {
  showIcon?: boolean;
  toolbar?: object;
  placeholder?: string;
  sendBtnText?: string;
  onSend: (value: string, html?: string) => void;
  onFilesSelect?: (files: File[]) => void;
  initialValue?: string;
  html?: boolean;
  rawText?: boolean;
  disabled?: boolean;
};

export type ComposeRef = {
  reset: () => void;
};

const Compose: React.RefForwardingComponent<ComposeRef, ComposeProps> = (
  props,
  ref
) => {
  const {
    disabled,
    toolbar = CONVERSATION_TOOLBAR,
    onFilesSelect,
    placeholder,
    sendBtnText,
    showIcon = true,
    onSend,
    initialValue = "",
    html,
    rawText,
  } = props;

  useImperativeHandle(ref, () => ({
    reset: () => {
      setEditorState(EditorState.createEmpty());
    },
  }));

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText(initialValue))
  );

  const onEditorStateChange = React.useCallback((editorState: EditorState) => {
    setEditorState(editorState);
  }, []);

  const sendBtnClasses = classNames(
    {
      "send-btn-icon": showIcon && !sendBtnText,
      large: sendBtnText,
    },
    "button success"
  );

  const handleEmojiSelect = React.useCallback(
    (emoji: EmojiData) => {
      const newState = createEmojiEntity(emoji, editorState);
      setEditorState(newState);
    },
    [editorState]
  );

  const handleSend = React.useCallback(() => {
    if (editorState.getCurrentContent().hasText()) {
      const editorContent = convertToRaw(editorState.getCurrentContent());
      let markdown = "";

      if (rawText) {
        markdown = editorState.getCurrentContent().getPlainText("\u0001");
      } else {
        markdown = draftToMarkdown(editorContent);
      }

      let htmlText = "";

      if (html) {
        htmlText = draftToHtml(editorContent);
      }

      onSend(markdown.trim(), htmlText);
      return;
    }

    onSend("");
  }, [rawText, html, onSend, editorState]);

  const renderTools = () => {
    return (
      <Toolbar
        onFilesSelect={onFilesSelect}
        onSend={handleSend}
        showIcon={showIcon}
        onEmojiSelect={handleEmojiSelect}
        sendBtnText={sendBtnText}
        sendBtnDisabled={disabled}
        sendBtnClasses={sendBtnClasses}
      />
    );
  };

  return (
    <Editor
      customDecorators={DECORATORS}
      onEditorStateChange={onEditorStateChange}
      editorState={editorState}
      wrapperClassName="compose-wrapper"
      toolbarClassName="toolbar-wrapper"
      spellCheck={true}
      toolbar={toolbar}
      toolbarCustomButtons={[renderTools()]}
      placeholder={placeholder}
    />
  );
};

export default forwardRef(Compose);
