import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Badge, BadgeProps, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { map } from "lodash";
import moment from "moment";

import {
  withDashboardContext,
  DashboardContextValue,
} from "../../layouts/dashboard/DashboardContext";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/admin-navigation-items";
import {
  TableCardData,
  TableCardDataRow,
} from "../../../components/dashboard/table-card/utils";
import TableCard from "../../../components/dashboard/table-card";
import { EmptyTablePlaceholder } from "../../../components/dashboard/table-card";
import { useFilteredArrayMultipleField } from "../../../hooks/useFilteredArray";
import {
  Affiliate,
  AffiliateListResponse,
  AffiliateStatus,
} from "../../../graphql/types/models/affiliate";
import { WB_LIST_AFFILIATES } from "../../../graphql/queries/wb-admin/affiliate/queries";
import { getFullName } from "../../../utils/text";
import { DATE_FORMAT_DATE_STRING } from "../../../constants/dates";

type AffiliateItem = Affiliate & {
  numberSignups: number;
  name: string;
};

type AffiliateContainerProps = DashboardContextValue;

export const getStatusBadge = (status: AffiliateStatus) => {
  let label = status;
  let variant: BadgeProps["variant"] = "info";
  switch (status) {
    case AffiliateStatus.APPROVED:
      variant = "success";
      break;
    case AffiliateStatus.PENDING:
      variant = "warning";
      break;
    case AffiliateStatus.REJECTED:
      variant = "danger";
      break;
    case AffiliateStatus.REVIEWED:
    default:
      variant = "primary";
  }
  return {
    label,
    variant,
  };
};

const AffiliateContainer: React.FC<AffiliateContainerProps> = ({
  navigationContext,
  setNavigationContext,
  setSearchOptions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const isPending =
    new URLSearchParams(location.search).get("status") ===
    AffiliateStatus.PENDING.toLowerCase();

  const { data, loading } = useQuery<AffiliateListResponse>(
    WB_LIST_AFFILIATES,
    {
      variables: {
        filter: {
          status: isPending
            ? AffiliateStatus.PENDING
            : AffiliateStatus.APPROVED,
        },
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const affiliates = React.useMemo(() => data?.affiliates ?? [], [data]);

  const { filteredArray, setFilter } = useFilteredArrayMultipleField(
    affiliates,
    ["business_name", "email", "phone", "first_name", "last_name"],
    true
  );

  React.useEffect(() => {
    setSearchOptions({
      placeholder: t("common.search"),
      options: [],
      onAutocomplete: setFilter,
    });

    return () => {
      setSearchOptions(null);
    };
  }, [t, setSearchOptions, setFilter]);

  const tableData = React.useMemo<TableCardData<AffiliateItem>>(() => {
    return {
      columns: [
        {
          valueKey: "business_name",
          title: t("affiliate.businessName"),
          sortable: true,
        },
        {
          valueKey: "name",
          title: t("affiliate.name"),
          sortable: true,
        },
        {
          valueKey: "phone",
          title: t("company.phone"),
          sortable: true,
        },
        {
          valueKey: "email",
          title: t("team.email"),
          sortable: true,
        },
        {
          valueKey: "numberSignups",
          title: t("wbAdmin.numberSignups"),
          sortable: true,
        },
        {
          valueKey: "createdAt",
          title: t("common.createdOn"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) =>
            moment(value).format(DATE_FORMAT_DATE_STRING),
        },
        {
          valueKey: "status",
          title: t("affiliate.status"),
          sortable: true,
          formatValue: (row: any, column: any, value: string) => {
            const { label, variant } = getStatusBadge(value as AffiliateStatus);
            return <Badge variant={variant}>{label}</Badge>;
          },
        },
      ],
      rows: map(filteredArray, (item: Affiliate) => ({
        cells: {
          ...item,
          name: getFullName(item),
          numberSignups: item.businesses?.length || 0,
        },
      })),
    };
  }, [filteredArray, t]);

  const handleRowClick = React.useCallback(
    (row: TableCardDataRow<AffiliateItem>) => {
      setNavigationContext({
        ...navigationContext,
        affiliate: {
          _id: row.cells._id || "",
          business_name: row.cells.business_name,
          first_name: row.cells.first_name,
          last_name: row.cells.last_name,
        },
      });
      history.push(`/affiliates/${row.cells._id}`);
    },
    [setNavigationContext]
  );

  const emptyPlaceholder = React.useMemo<EmptyTablePlaceholder>(
    () => ({
      text: t("businesses.emptyPlaceholder"),
    }),
    [t]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.wbAdmin.WunderbuidConsole")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.AFFILIATES} />
      <Row>
        <Col xs={12}>
          <TableCard
            isDataLoading={loading}
            data={tableData}
            alignEnd
            onRowClick={handleRowClick}
            emptyPlaceholder={emptyPlaceholder}
            tableId="admin-affiliate-list"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withDashboardContext(AffiliateContainer);
