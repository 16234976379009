import React from "react";
import classNames from "classnames";

export type DescriptionBlockProps = {
  style?: string;
  element: JSX.Element;
};

const DescriptionBlock: React.FC<DescriptionBlockProps> = ({
  style,
  element,
}) => {
  const blockClasses = classNames(style);

  return <div className={blockClasses}>{element}</div>;
};

export default DescriptionBlock;
