import { chain, filter } from "lodash";
import { TFunction } from "i18next";
import {
  GenericFormFields,
  UpdateRowFn,
  DisableRowEditingFn,
  AppendDynamicPropsFn,
  FormikPropGetSetValues,
  DynamicPropsFn,
  DisableRowDeletingFn,
  UpdateTextInputFn,
} from "../../generic-form/GenericFormBody";
import CostingAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import { CreateJobCostingPayload } from "../../../models/job";
import { CreateCostingItemPayload } from "../../../models/salesQuote";
import { PriceListItem } from "../../../models/price-list";
import { UOMOption } from "../../../utils/types/options";
import { AssemblyListItem } from "../../../models/assembly";

export const createJobCostingFields = (
  t: TFunction,
  uoms: UOMOption[],
  openCalculatorModal: (fieldName: string) => void,
  handleUOMCreate: (uom: string) => void,
  handlePriceSearch: (value: string) => Promise<SelectOption[]>,
  assemblyOptions: SelectOption[],
  getFieldProps: DynamicPropsFn,
  getAssemblyProps: AppendDynamicPropsFn,
  getCalcProps: AppendDynamicPropsFn,
  onOpenAssemblyModal: (fieldName: string, value?: string) => void,
  handleAssemblyChange: UpdateRowFn,
  handleQuantityChange: UpdateTextInputFn,
  handleCostChange: (key: string) => UpdateTextInputFn,
  isLocked: boolean,
  costsHaveGST: boolean,
  handleCostsHaveGST: (
    value: boolean,
    formikProps: FormikPropGetSetValues
  ) => void,
  handleDisableRowDeleting: DisableRowDeletingFn,
  priceLookupButton: () => JSX.Element,
  handleDisableRowEditing?: DisableRowEditingFn,
  onCreateAssemblyFromSelection?: (formikProps?: FormikPropGetSetValues) => void
): GenericFormFields<CreateJobCostingPayload> => {
  // const assemblyOptions = assemblies.map((assembly) => ({
  //   value: assembly._id,
  //   label: assembly.name,
  //   hidden: assembly._id.indexOf("assembly:") === -1,
  //   data: assembly,
  // }));
  //const assemblyOptions = [] as SelectOption[];

  const costingOptions = async (value: string) => {
    const searchRegex = new RegExp(value, "i");
    const options = !!value
      ? filter(
          assemblyOptions,
          (option) => option.label.search(searchRegex) >= 0
        )
      : assemblyOptions;

    if (!value) return [];

    const result = await handlePriceSearch(value);
    return [...options, ...result];
  };

  return {
    name: [
      {
        type: "text",
        label: t("costing.categoryName"),
        placeholder: t("placeholders.categoryName"),
        valueKey: "name",
        disabled: isLocked ? true : undefined,
        inputProps: {
          type: "text",
          required: true,
        },
      },
      {
        type: "toggle",
        label: t("costing.timesheetAllocation"),
        valueKey: "can_timesheet",
        toggleProps: {
          label: t("costing.allowTimesheetAllocation"),
        },
      },
    ],
    items: {
      tableTitle: t("costing.costingItems"),
      rightActionButton: priceLookupButton,
      toggles: [
        {
          name: "costsHaveGST",
          label: t("costing.costsHaveGST"),
          onChange: handleCostsHaveGST,
        },
      ],
      rowGenerator: () => ({
        UOM: "qty",
        cost: "0",
        name: "",
        quantity: "0",
        real_cost: "0",
        assemblyId: "",
        order: 0,
        gstFree: false,
        hasGST: true,
      }),
      isDraggable: true,
      isCompact: true,
      isTwoRow: true,
      // disableRowEditing: handleDisableRowEditing,
      isSelectable: true,
      disableRowDeleting: handleDisableRowDeleting,
      actionWithSelected: {
        action: onCreateAssemblyFromSelection,
        buttonTitle: t("costing.createAssemblyFromSelection"),
        fieldName: "items",
      },
      row: [
        {
          // width: "200px",
          type: "creatable-select",
          label: t("costing.name"),
          placeholder: t("placeholders.name"),
          valueKey: "name",
          updateTableRow: handleAssemblyChange,
          dynamicProps: getFieldProps,
          creatableProps: {
            isEditable: true,
            isClearable: true,
            autoSelectCreatedOption: true,
            options: assemblyOptions,
            asyncOptions: costingOptions,
            optionComponent: CostingAutocompleteMenuOption,
            isValidNewOption: (v: string) => v.length > 0,
            noOptionsMessage: () => null,
            className: "form-input-compact",
            hideSelectedOptions: true,
          },
          append: {
            icon: "file_copy",
            className: "input-group-append-compact",
            onClick: onOpenAssemblyModal,
            dynamicProps: getAssemblyProps,
          },
          inputProps: {
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        {
          width: "125px",
          maxWidth: "125px",
          type: "text",
          label: t("costing.quantity"),
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          disallowNegativeNumber: false,
          dynamicProps: getFieldProps,
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
          textInputProps: {
            onValueChange: handleQuantityChange,
          },
          append: {
            icon: "calculate",
            onClick: openCalculatorModal,
            className: "input-group-append-compact",
            dynamicProps: getCalcProps,
          },
        },
        {
          width: "125px",
          maxWidth: "125px",
          type: "creatable-select",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          dynamicProps: getFieldProps,
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
            debounceChangeEvent: true,
          },
        },
        !costsHaveGST
          ? {
              width: "140px",
              maxWidth: "140px",
              type: "text",
              label: t("costing.cost"),
              placeholder: t("placeholders.costValue"),
              valueKey: "cost",
              dynamicProps: getFieldProps,
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
                debounceChangeEvent: true,
              },
              textInputProps: {
                onValueChange: handleCostChange("cost"),
              },
            }
          : {
              width: "140px",
              maxWidth: "140px",
              type: "text",
              label: t("costing.costInc"),
              placeholder: t("placeholders.costValue"),
              valueKey: "cost_inc",
              dynamicProps: getFieldProps,
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
                debounceChangeEvent: true,
              },
              textInputProps: {
                onValueChange: handleCostChange("cost_inc"),
              },
            },
        !costsHaveGST
          ? {
              width: "140px",
              maxWidth: "140px",
              type: "text",
              label: t("costing.realCost"),
              placeholder: t("placeholders.costValue"),
              valueKey: "real_cost",
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
                debounceChangeEvent: true,
              },
            }
          : {
              width: "140px",
              maxWidth: "140px",
              type: "text",
              label: t("costing.realCostInc"),
              placeholder: t("placeholders.costValue"),
              valueKey: "real_cost_inc",
              inputProps: {
                type: "number",
                required: true,
                className: "form-input-compact",
                debounceChangeEvent: true,
              },
            },
        {
          width: "100px",
          maxWidth: "100px",
          type: "toggle",
          valueKey: "gstFree",
          label: t("costing.gstFree"),
          dynamicProps: getFieldProps,
          toggleProps: {
            label: "",
          },
        },
        {
          type: "text",
          controlType: "textarea",
          label: t("costing.note"),
          placeholder: "",
          valueKey: "note",
          secondRow: true,
          inputProps: {
            className: "form-input-compact",
            type: "text",
            rows: 2,
            debounceChangeEvent: true,
          },
        },
      ],
      dynamicFields: [
        {
          title: costsHaveGST ? t("costing.totalInc") : t("costing.totalEx"),
          maxWidth: "160px",
          formatValue: (values: CreateCostingItemPayload) => {
            if (
              values?.quantity &&
              (costsHaveGST ? values.cost_inc : values.cost)
            ) {
              return t("common.currency", {
                amount:
                  Number(values.quantity) *
                  Number(
                    (costsHaveGST && !values.gstFree
                      ? values.cost_inc
                      : values.cost) || 0
                  ),
              });
            }

            return "$0.00";
          },
        },
      ],
    },
  };
};
