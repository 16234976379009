import React from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";

type LeftModalFooterProps = {
  className?: string;
};

const LeftModalFooter: React.FC<LeftModalFooterProps> = ({
  children,
  className,
}) => {
  return (
    <Modal.Footer className={classNames("footer", className)}>
      {children}
    </Modal.Footer>
  );
};

export default LeftModalFooter;
