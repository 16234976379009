import React, { FC } from "react";
import Icon from "../icons/Icon";
import "./styles.scss";

type Props = {
  value: boolean;
  onClick: (value: boolean) => void;
};

const CheckBox: FC<Props> = ({ value, onClick }) => {
  const handleOnClick = () => {
    onClick(!value);
  };

  return (
    <div className="checkbox-container container">
      <label className="label">
        {value ? (
          <Icon
            name="check_box"
            className="checkbox-icon material-icons--outlined action-icon m-0"
          />
        ) : (
          <Icon
            name="check_box_outline_blank"
            className="checkbox-icon material-icons--outlined action-icon m-0"
          />
        )}

        <input
          id="checkbox"
          className="input"
          checked={value}
          onChange={handleOnClick}
          type="checkbox"
        />
      </label>
    </div>
  );
};

export default CheckBox;
