import React from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "react-bootstrap";
import { AutocompleteMenuOptionProps } from "./AutocompleteMenuOption";
import "./styles.scss";

const BadgedAutocompleteMenuOption: React.FC<AutocompleteMenuOptionProps> = (
  props
) => {
  const { innerProps, children, data } = props;

  let badge = null;
  if (data.badge) {
    badge = (
      <Badge variant={data.badgeVariant || "primary"}>{data.badge}</Badge>
    );
  }

  return (
    // @ts-ignore
    <div {...innerProps} className="option">
      <div className="option-badged-inline">
        {children}
        {badge}
      </div>
    </div>
  );
};

export default BadgedAutocompleteMenuOption;
