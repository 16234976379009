import LeftModal from "../../left-modal";
import React, { ReactNode } from "react";
import { Button, Form } from "react-bootstrap";

import LeftModalHeader from "../../left-modal/LeftModalHeader";
import LeftModalBody from "../../left-modal/LeftModalBody";
import LeftModalFooter from "../../left-modal/LeftModalFooter";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers, FormikProps } from "formik";
import GenericFormBody, {
  GenericFormFields,
} from "../../generic-form/GenericFormBody";

type UpdateEntityModalProps<TData> = {
  title: string;
  className?: string;
  submitButtonTitle?: string;
  data: TData;
  show: boolean;
  disableEventValidation?: boolean;
  validationSchema?: any;
  fields: GenericFormFields<TData>;
  onSubmit: (data: TData) => void;
  onClose: () => void;
  topRenderer?: React.ReactElement;
  showDeleteButton?: boolean;
  onDelete?: () => void | Promise<any>;
  disabled?: boolean;
  children?: (helpers: FormikProps<TData>) => ReactNode;
  formikRef?: React.Ref<FormikProps<TData>>;
  endRenderer?: React.ReactElement | null;
};

function UpdateEntityModal<TData extends {}>({
  title,
  fields,
  data,
  show,
  disableEventValidation,
  validationSchema,
  onClose,
  onSubmit,
  className,
  topRenderer,
  showDeleteButton,
  onDelete,
  children,
  disabled,
  formikRef,
  submitButtonTitle,
  endRenderer,
}: UpdateEntityModalProps<TData>) {
  const { t } = useTranslation();

  const handleDelete = React.useCallback(() => {
    onDelete && onDelete();
  }, [onDelete]);

  return (
    <LeftModal className={className} show={show} onHide={onClose}>
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={data}
        validateOnChange={!disableEventValidation}
        validateOnBlur={!disableEventValidation}
        onSubmit={(
          values: TData,
          formikHelpers: FormikHelpers<TData>
        ): void | Promise<any> => {
          return onSubmit(values);
        }}
      >
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            onReset={formikProps.handleReset}
            noValidate
          >
            <LeftModalHeader title={title} onClose={onClose} />
            <LeftModalBody className="d-block">
              {topRenderer}
              {children && children(formikProps)}
              <GenericFormBody fields={fields} {...formikProps} />
              {endRenderer}
            </LeftModalBody>
            <LeftModalFooter className="justify-content-between">
              <div>
                {showDeleteButton && (
                  <Button
                    variant="danger"
                    className="button large info"
                    onClick={handleDelete}
                    disabled={formikProps.isSubmitting || disabled}
                  >
                    {t("common.delete")}
                  </Button>
                )}
              </div>
              <div className="d-flex">
                <Button
                  variant="secondary"
                  className="button large info mr-2"
                  onClick={onClose}
                  disabled={formikProps.isSubmitting || disabled}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  variant="primary"
                  className="button large success"
                  type="submit"
                  disabled={formikProps.isSubmitting || disabled}
                >
                  {submitButtonTitle ? submitButtonTitle : t("common.update")}
                </Button>
              </div>
            </LeftModalFooter>
          </Form>
        )}
      </Formik>
    </LeftModal>
  );
}

export default UpdateEntityModal;
