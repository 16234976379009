import { TFunction } from "i18next";
import { WbAdminExtendSubscriptionDatePayload } from "../../../graphql/types/models/wb-business";
import { GenericFormFields } from "../../generic-form/GenericFormBody";

export function extendTrialFields(
  t: TFunction
): GenericFormFields<WbAdminExtendSubscriptionDatePayload> {
  return {
    date: {
      valueKey: "date",
      type: "date-picker",
      label: t("businesses.subscriptionEndDate"),
      placeholder: t("businesses.subscriptionEndDate"),
      inputProps: {
        type: "text",
        required: true,
      },
    },
  };
}
