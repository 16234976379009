import { TFunction } from "i18next";
import { GenericFormFields } from "../../../../components/generic-form/GenericFormBody";
import { CreateUpdateChecklistGroupPayload } from "../../../../models/task";

export const createUpdateChecklistField = (
  t: TFunction
): GenericFormFields<CreateUpdateChecklistGroupPayload> => {
  return {
    category: [
      {
        type: "text",
        label: t("tasks.groupName"),
        placeholder: t("tasks.groupName"),
        valueKey: "name",
        inputProps: {
          type: "text",
          required: true,
        },
      },
    ],
    items: {
      tableTitle: t("common.items"),
      isDraggable: true,
      rowGenerator: () => ({
        task: "",
        order: 0,
      }),
      row: [
        {
          width: "200px",
          type: "text",
          label: t("tasks.task"),
          placeholder: "",
          valueKey: "task",
          inputProps: {
            type: "text",
            required: true,
          },
        },
      ],
    },
  };
};
