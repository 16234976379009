import { gql } from "@apollo/client";
import {
  SITE_DIARY_FRAGMENT,
  SITE_DIARY_OVERVIEW_FRAGMENT,
  SITE_DIARY_TEMPLATE_FRAGMENT,
} from "./fragments";

export const GET_SITE_DIARY = gql`
  query($jobId: ID, $diaryId: ID!) {
    getSiteDiaryById(jobId: $jobId, diaryId: $diaryId) {
      ...SiteDiaryFragment
    }
  }
  ${SITE_DIARY_FRAGMENT}
`;

export const GET_SITE_DIARIES = gql`
  query($jobId: ID!, $diaryIds: [ID]!) {
    getSiteDiariesById(jobId: $jobId, diaryIds: $diaryIds) {
      ...SiteDiaryFragment
    }
  }
  ${SITE_DIARY_FRAGMENT}
`;

export const LIST_SITE_DIARIES = gql`
  query($jobId: ID!) {
    listSiteDiaryOverview(jobId: $jobId) {
      ...SiteDiaryOverviewFragment
    }
  }
  ${SITE_DIARY_OVERVIEW_FRAGMENT}
`;

export const LIST_SITE_DIARIES_BY_FILTERS = gql`
  query($filters: SiteDiaryFilterInput!, $pagination: PaginationInput!) {
    listSiteDiaries(filters: $filters, pagination: $pagination) {
      siteDiaries {
        _id
        name
        entry_date_time
        is_client_shared
        note
        createdAt
        updatedAt
        created_by {
          _id
          first_name
          last_name
          fullName
        }
        job {
          _id
          name
        }
        category {
          _id
          name
        }
        template {
          _id
          name
          is_deleted
        }
      }
      totalDocs
      limit
      page
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      totalPages
    }
  }
`;

export const LIST_SITE_DIARY_TEMPLATES = gql`
  query {
    listSiteDiaryTemplates {
      ...SiteDiaryTemplateFragment
    }
  }
  ${SITE_DIARY_TEMPLATE_FRAGMENT}
`;

export const LIST_SITE_DIARY_CATEGORIES = gql`
  query($jobId: ID!) {
    listSiteDiaryCategories(jobId: $jobId) {
      _id
      name
    }
  }
`;

export const CLIENT_JOB_LIST_SHARED_SITE_DIARIES = gql`
  query ClientJobListSharedSiteDiaries($jobId: ID!) {
    clientJobListSharedSiteDiaries(jobId: $jobId) {
      ...SiteDiaryFragment
    }
  }
  ${SITE_DIARY_FRAGMENT}
`;
