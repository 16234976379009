import { gql } from "@apollo/client";

export const VERIFY_AFFILIATE = gql`
  mutation($affiliateCode: String!) {
    affiliateVerification(affiliateCode: $affiliateCode)
  }
`;

export const AFFILIATE_SIGN_UP = gql`
  mutation($profile: AffiliateInput!) {
    affiliateSignup(profile: $profile) {
      first_name
      last_name
      email
      business_name
      phone
    }
  }
`;

export const AFFILIATE_SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    affiliateLogin(email: $email, password: $password) {
      _id
      tokenType
      token
    }
  }
`;

export const AFFILIATE_FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    affiliateForgotPassword(email: $email) {
      _id
      email
    }
  }
`;

export const AFFILIATE_RESET_PASSWORD = gql`
  mutation($resetHash: String!, $password: String!) {
    affiliateResetPassword(resetHash: $resetHash, password: $password) {
      _id
      email
    }
  }
`;

export const AFFILIATE_CHANGE_PASSWORD = gql`
  mutation($old_password: String!, $new_password: String!) {
    changePassword: affiliateChangePassword(
      old_password: $old_password
      new_password: $new_password
    ) {
      _id
      email
    }
  }
`;
