import React from "react";
import { components, MenuListComponentProps } from "react-select";

type AutocompleteMenuListProps = MenuListComponentProps<any> & {};

const AutocompleteMenuList: React.FC<AutocompleteMenuListProps> = (props) => {
  return <components.MenuList {...props} className="menu-list" />;
};

export default AutocompleteMenuList;
