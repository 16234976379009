import { WithT } from "i18next";
import { FormikProps } from "formik";
import React from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export type SignInFormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type SignInFormBodyProps = WithT &
  Pick<
    FormikProps<SignInFormValues>,
    "values" | "errors" | "touched" | "handleChange" | "handleBlur"
  >;

const SignInFormBody: React.FC<SignInFormBodyProps> = ({
  t,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <Container className="p-0 m-0 mw-100">
      <Row>
        <Col lg={6} md={12}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="form-input-label">
              {t("authentication.emailAddress")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="email"
              isInvalid={touched.email && !!errors.email}
              value={values.email}
              className="form-input"
              type="email"
              placeholder={t("placeholders.emailAddress")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group controlId="formBasicPassword">
            <Form.Label className="form-input-label">
              {t("authentication.password")}
            </Form.Label>
            <Form.Control
              required
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              value={values.password}
              isInvalid={touched.password && !!errors.password}
              type="password"
              className="form-input"
              placeholder={t("placeholders.password")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
            <Form.Text
              as={Link}
              to="/forget-password"
              className="text-muted d-flex justify-content-end"
            >
              {t("authentication.forgetPassword")}
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default SignInFormBody;
