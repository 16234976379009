import React from "react";
import "./styles.scss";

type ProposalTextProps = {
  title: string;
  children: any;
};

const ProposalText: React.FC<ProposalTextProps> = ({ title, children }) => {
  return (
    <div className="proposal">
      <div className="proposal-header">
        <div className="field-text text-center">{title}</div>
      </div>
      <div className="proposal-body field-text">{children}</div>
    </div>
  );
};

export default ProposalText;
