import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { selectCategoryFields } from "./utils";
import selectCategorySchema from "./SelectCategory.schema";
import { CostingCategoryListItem } from "../../../models/salesQuote";
import { useModalDisplay } from "../../../hooks/useModalDisplay";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import CreateEntityModal from "../../modals/create-entity";

type SelectCategoryModalProps = {
  onSubmit: (category: CostingCategoryListItem) => void;
  costingCategories?: CostingCategoryListItem[];
};

const SelectCategoryModal: React.FC<SelectCategoryModalProps> = (
  { costingCategories, onSubmit },
  ref
) => {
  const { shouldShow, hide } = useModalDisplay(ref);
  const { t } = useTranslation();

  const [formFields, setFormFields] = React.useState<
    GenericFormFields<{ categoryId: "" }>
  >({});

  React.useEffect(() => {
    if (!costingCategories) return;
    setFormFields(selectCategoryFields(t, costingCategories));
  }, [costingCategories, t]);

  const handleSubmit = React.useCallback(
    (value: { categoryId: "" }) => {
      const currentCategory = costingCategories?.find(
        (category) => category._id === value.categoryId
      );
      if (currentCategory) {
        onSubmit(currentCategory);
      }

      hide();
    },
    [costingCategories, hide, onSubmit]
  );

  return (
    <CreateEntityModal
      validationSchema={selectCategorySchema()}
      className="select-caterogy"
      title={t("quotes.selectCategoryToCopy")}
      show={shouldShow}
      data={{
        categoryId: "",
      }}
      submitText={t("common.copy")}
      onSubmit={handleSubmit}
      onClose={hide}
      fields={formFields}
    />
  );
};

export default forwardRef(SelectCategoryModal);
