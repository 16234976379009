import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import ModalForm from "../../modals/modal-form";
import {
  SubscribeToPlanPayload,
  SubscribeToPlanReponse,
  SubscriptionPlan,
} from "../../../graphql/types/models/subscription";
import { SUBSCRIPE_TO_PLAN } from "../../../graphql/queries/subscription/mutations";
import { notify } from "../../notification";
import "./styles.scss";

type SubscriptionPaymentModalProps = {
  onSuccess: () => void;
};

export type SubscriptionPaymentModalRef = {
  show: (show: boolean, plan?: SubscriptionPlan) => void;
};
const SubscriptionPaymentModal: React.FC<SubscriptionPaymentModalProps> = (
  props,
  ref
) => {
  const { t } = useTranslation();

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  const { onSuccess } = props;

  const [showModal, setShowModal] = useState(false);
  const [plan, setPlan] = useState<SubscriptionPlan>();

  useImperativeHandle(ref, () => ({
    show: (show: boolean, plan?: SubscriptionPlan) => {
      setShowModal(show);
      if (plan) {
        setPlan(plan);
      }
    },
  }));

  const [subscribeToPlan] = useMutation<
    SubscribeToPlanReponse,
    SubscribeToPlanPayload
  >(SUBSCRIPE_TO_PLAN, {
    onError: (error) => {
      notify({
        error: true,
        content: error?.message,
        title: t("billing.subscribeTo", plan),
      });
    },
  });

  const handleClose = React.useCallback(() => setShowModal(false), []);

  const handleSubmit = React.useCallback(
    async (values: any) => {
      if (!plan || !elements || !stripe) return;
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) return;

      const subscribe = await subscribeToPlan({
        variables: {
          planId: plan._id,
        },
      });

      const clientSecret = subscribe.data?.subscribeToPlan.clientSecret;
      const requiresPaymentMethod =
        subscribe.data?.subscribeToPlan.requiresPaymentMethod;

      // payment details already exists.. update card
      if (!requiresPaymentMethod) {
        notify({
          content: t("billing.success.subscribe", plan),
          title: t("billing.subscribeTo", plan),
        });
        return onSuccess();
      }

      if (!clientSecret) return;
      // Use card Element to tokenize payment details
      let { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );
      if (paymentIntent && paymentIntent.status === "succeeded") {
        notify({
          content: t("billing.success.subscribe", plan),
          title: t("billing.subscribeTo", plan),
        });
        return onSuccess();
      } else {
        notify({
          error: true,
          content: error?.message || t("billing.error.payment"),
          title: t("billing.subscribeTo", plan),
        });
      }
    },
    [plan]
  );

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return null;
  }

  const createOptions = () => {
    return {
      style: {
        base: {
          fontSize: "16px",
          color: "#495057",
          "::placeholder": {
            color: "#868e96",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };

  return (
    <ModalForm
      className="payment-modal"
      show={showModal}
      onClose={handleClose}
      data={{}}
      onSubmit={handleSubmit}
      title={t("billing.subscribeToPlan")}
      submitText={t("billing.confirmSubscription")}
    >
      {(formikProps) => (
        <Container className="generic-form-body" fluid>
          <label className="form-input-label form-label">
            {t("billing.selectedPlan")}:
          </label>
          <h2>{plan?.name}</h2>
          <h4>
            ${plan?.price}
            <small className="text-muted">/ mo</small>
          </h4>

          <label className="form-input-label form-label">
            {t("billing.enterCardDetails")}:
          </label>
          <CardElement
            className="form-control form-input"
            options={createOptions()}
          />
        </Container>
      )}
    </ModalForm>
  );
};

export default forwardRef(SubscriptionPaymentModal);
