import { TFunction } from "i18next";
import {
  startCase,
  chain,
  each,
  get,
  map,
  pick,
  omit,
  set,
  merge,
} from "lodash";
import { SalesQuotePrintTemplate } from "../../../graphql/types/models/quote-designer";
import { QuotePrintTemplateSection } from "../../../models/salesQuote";
import {
  getQuotePrintTemplateCoverStyleOptions,
  getQuotePrintTemplateSectionOptions,
} from "../../../utils/options";
import { PDF_TOOLBAR_MERGE } from "../../generic-form/inputs/rich-editor/configs";
import { GenericFormFields } from "../../generic-form/GenericFormBody";
import TopBadgedAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/TopBadgedAutocompleteMenuOption";
import { convertRichContent } from "../../generic-form/inputs/rich-editor/utils";

const buildEnable = (
  t: TFunction,
  template: SalesQuotePrintTemplate,
  section: QuotePrintTemplateSection
) => {
  const hasEnable = get(template, [section.toLowerCase(), "enabled"].join("."));
  if (hasEnable === undefined) return;

  return {
    type: "toggle",
    valueKey: `${section.toLowerCase()}.enabled`,
    toggleProps: {
      label: t("quotes.designer.enable", {
        section: startCase(section.toLowerCase()),
      }),
    },
    inputProps: {
      readOnly: template.is_system,
    },
  };
};

const buildGenericTexts = (
  t: TFunction,
  template: SalesQuotePrintTemplate,
  section: QuotePrintTemplateSection
) => {
  const items = {} as any;
  const hasTitle = get(template, [section.toLowerCase(), "title"].join("."));
  if (hasTitle !== undefined) {
    items.title = {
      type: "text",
      label: t("quotes.designer.title"),
      valueKey: `${section.toLowerCase()}.title`,
      showError: true,
      inputProps: {
        required: true,
        readOnly: template.is_system,
      },
    };
  }

  const hasText = get(
    template,
    [section.toLowerCase(), "richContent"].join(".")
  );
  if (hasText !== undefined) {
    items.title = {
      type: "group-title",
      placeholder: t("quotes.designer.text"),
    };
    items.isRichContentSpecificToQuote = {
      type: "toggle",
      valueKey: `${section.toLowerCase()}.isRichContentSpecificToQuote`,
      toggleProps: {
        label: t("quotes.designer.textIsSpecificToQuote"),
      },
      inputProps: {
        readOnly: template.is_system,
      },
    };
    items.richContent = {
      type: "rich-editor",
      // label: t("quotes.designer.text"),
      valueKey: `${section.toLowerCase()}.richContent.html`,
      richEditorProps: {
        textKey: `${section.toLowerCase()}.richContent.text`,
        rawKey: `${section.toLowerCase()}.richContent.raw`,
        toolbarOptions: PDF_TOOLBAR_MERGE,
      },
      inputProps: {
        readOnly: template.is_system,
      },
    };
  }
  return items;
};

const buildDisplayOptions = (
  t: TFunction,
  template: SalesQuotePrintTemplate,
  section: QuotePrintTemplateSection
) => {
  const keys = Object.keys(
    get(template, [section.toLowerCase(), "display"].join("."), []) || {}
  );
  return chain(keys)
    .filter((key) => key !== "__typename")
    .map((key) => ({
      type: "toggle",
      valueKey: `${section.toLowerCase()}.display.${key}`,
      toggleProps: {
        label: t(`quotes.designer.display.${key}`),
        readOnly: template.is_system,
      },
    }))
    .value();
};

export const customiseQuoteFormFields = (
  t: TFunction,
  onTemplateSelect: (templateId: string) => void,
  onSectionSelect: (section: QuotePrintTemplateSection) => void,
  onCopyTemplate: () => void,
  templates?: SalesQuotePrintTemplate[],
  template?: SalesQuotePrintTemplate | null,
  section?: QuotePrintTemplateSection | null
): GenericFormFields<any> => {
  const templateOptions = map(
    templates,
    (template: SalesQuotePrintTemplate) => ({
      value: template._id,
      label: template.name,
      badge: template.is_system ? t("common.system") : t("common.custom"),
    })
  );

  const templateField = {
    type: "select",
    label: t("siteDiary.template"),
    valueKey: "templateId",
    autocompleteProps: {
      options: templateOptions,
      onValueChange: onTemplateSelect,
      optionComponent: TopBadgedAutocompleteMenuOption,
    },
    inputProps: {
      required: true,
    },
    append: template
      ? {
          icon: "content_copy",
          className: "text field-text",
          onClick: onCopyTemplate,
        }
      : null,
  };

  if (!template)
    return { template: templateField } as GenericFormFields<
      SalesQuotePrintTemplate
    >;

  const sectionField = {
    type: "default-select",
    label: t("quotes.customiseSection"),
    valueKey: "section",
    selectProps: {
      options: getQuotePrintTemplateSectionOptions(t),
      onValueChange: onSectionSelect,
    },
    inputProps: {
      required: true,
    },
  };

  let fields = {
    template: templateField,
    section: sectionField,
  } as GenericFormFields<any>;

  if (template && section) {
    const enabled = buildEnable(t, template, section) as any;
    if (enabled) {
      fields.enabled = enabled;
    }

    const texts = buildGenericTexts(t, template, section) as any;
    if (Object.keys(texts).length) {
      fields = {
        ...fields,
        ...texts,
      };
    }

    switch (section) {
      case QuotePrintTemplateSection.THEME: {
        fields.backgroundColour = {
          type: "colour-picker",
          label: t("quotes.designer.theme.backgroundColour"),
          placeholder: t("placeholders.colour"),
          valueKey: "theme.backgroundColour",
          colourPickerProps: {
            disableAlpha: true,
            readOnly: template.is_system,
          },
        };
        fields.textColour = {
          type: "colour-picker",
          label: t("quotes.designer.theme.textColour"),
          placeholder: t("placeholders.colour"),
          valueKey: "theme.textColour",
          colourPickerProps: {
            disableAlpha: true,
            readOnly: template.is_system,
          },
        };
        break;
      }
      case QuotePrintTemplateSection.COVER: {
        fields = {
          ...pick(fields, ["template", "section", "enabled"]),
          style: {
            type: "default-select",
            label: t("quotes.designer.cover.style"),
            valueKey: `${section.toLowerCase()}.style`,
            selectProps: {
              options: getQuotePrintTemplateCoverStyleOptions(t),
            },
            inputProps: {
              required: true,
              readOnly: template.is_system,
            },
          },
          ...omit(fields, ["template", "section", "enabled"]),
        };

        fields.subtitle = {
          type: "text",
          label: t("quotes.designer.cover.subtitle"),
          valueKey: `${section.toLowerCase()}.subtitle`,
          showError: true,
          inputProps: {
            required: true,
            readOnly: template.is_system,
          },
        };
        fields.expiryDays = {
          type: "text",
          label: t("quotes.designer.cover.expiryDays"),
          valueKey: `${section.toLowerCase()}.expiryDays`,
          showError: true,
          inputProps: {
            required: true,
            readOnly: template.is_system,
            type: "number",
          },
        };
        fields.background = {
          width: "200px",
          type: "image-uploader",
          label: t("quotes.designer.cover.backgroundImage"),
          valueKey: "coverBackgroundImage",
          uploadImageProps: {
            width: "200px",
            height: "150px",
            imageUrl: template?.cover?.background?.url,
            readOnly: template.is_system,
          },
        };
        fields.logo = {
          width: "200px",
          type: "image-uploader",
          label: t("quotes.designer.cover.logoImage"),
          valueKey: "coverLogoImage",
          uploadImageProps: {
            width: "200px",
            height: "100px",
            imageUrl: template?.cover?.logo?.url,
            readOnly: template.is_system,
          },
        };
        break;
      }
    }
    const display = buildDisplayOptions(t, template, section) as any;
    if (display.length) {
      fields.display = display;
    }
  }
  return fields;
};

export const convertTemplateRichContent = (
  template: SalesQuotePrintTemplate
) => {
  each(template, (item: any) => {
    if (item?.richContent) {
      item.richContent = convertRichContent(item.richContent);
      delete item.text;
    }
  });
  return template;
};

export const mergeChildTemplate = (
  template: SalesQuotePrintTemplate,
  childTemplate?: SalesQuotePrintTemplate
) => {
  let localSections = {};
  each(childTemplate, (childItem: any, section: string) => {
    if (childItem?.isRichContentSpecificToQuote) {
      set(localSections, [section, "richContent"], {
        ...childItem.richContent,
      });
      set(localSections, [section, "isRichContentSpecificToQuote"], true);
    }
  });
  const result = merge({}, template, localSections) as SalesQuotePrintTemplate;

  return result;
};
