import { gql } from "@apollo/client";
import { JOB_CHANNEL_FRAGMENT, JOB_MESSAGE_FRAGMENT } from "./fragments";

export const GET_JOB_CHANNEL_BY_TYPE = gql`
  query GetJobChannelByType($jobId: ID!, $channelType: EnumJobChannelType!) {
    getJobChannelByType(jobId: $jobId, channelType: $channelType) {
      ...JobChannelConversation
    }
  }
  ${JOB_CHANNEL_FRAGMENT}
`;

export const GET_JOB_CHANNELS = gql`
  query GetJobChannels($jobId: ID!) {
    internalChannel: getJobChannelByType(jobId: $jobId, channelType: Internal) {
      ...JobChannelConversation
    }
    externalChannel: getJobChannelByType(jobId: $jobId, channelType: External) {
      ...JobChannelConversation
    }
  }
  ${JOB_CHANNEL_FRAGMENT}
`;

export const LIST_ALL_MESSAGES = gql`
  query ListallJobsMessages($internalLimit: Int, $externalLimit: Int) {
    listallJobsMessages {
      internal {
        _id
        channelType
        job {
          _id
          jobNumber
          name
          members {
            _id
            first_name
            last_name
            email
            profileImage {
              url
            }
          }
        }
        messages(limit: $internalLimit) {
          ...JobChannelMessage
        }
      }
      external {
        _id
        channelType
        job {
          _id
          jobNumber
          name
          members {
            _id
            first_name
            last_name
            email
            profileImage {
              url
            }
          }
        }
        messages(limit: $externalLimit) {
          ...JobChannelMessage
        }
      }
    }
  }
  ${JOB_MESSAGE_FRAGMENT}
`;

export const GET_JOB_CHANNEL = gql`
  query GetJobChannelByType(
    $jobId: ID!
    $channelType: EnumJobChannelType!
    $limit: Int
  ) {
    getJobChannelByType(jobId: $jobId, channelType: $channelType) {
      _id
      channelType
      job {
        _id
        jobNumber
        name
        members {
          _id
          first_name
          last_name
          email
          role
          profileImage {
            url
          }
        }
      }
      messages(limit: $limit) {
        _id
        text
        createdAt
        is_deleted
        linkPreview {
          title
          description
          url
          imageUrl
        }
        media {
          _id
          name
          upload_url
          url
          type
          size
        }
        sender {
          _id
          first_name
          last_name
          email
          profileImage {
            url
          }
        }
      }
    }
  }
`;
