import React, { forwardRef, useImperativeHandle } from "react";
import Icon from "../../icons/Icon";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import "./styles.scss";
import map from "lodash/map";
import classNames from "classnames";

export type DropdownItem = {
  id: string;
  label?: string;
  icon?: string;
  onClick?: () => void;
  onSelect?: () => void;
  outlined?: boolean;
};

type DropdownProps = {
  label?: string;
  icon?: string;
  outlined?: boolean;
  onClick?: () => void;
  onSelect?: () => void;
  isVisible?: boolean;
  items?: DropdownItem[];
  size?: string;
  id: string;
  handleToggle?: () => void;
  iconFirst?: boolean;
  light?: boolean;
  dropdownClass?: string;
  toggleClass?: string;
  fixed?: boolean;
  menuWidth?: string;
  isDisabled?: boolean;
  alignRight?: boolean;
  split?: boolean;
  disableAutoClose?: boolean;
};

export type DashboardDropdownRef = {
  open: () => void;
  close: () => void;
};

const DashboardDropdown: React.FC<DropdownProps> = (props, ref) => {
  const {
    iconFirst,
    label,
    icon,
    size,
    items,
    id,
    onClick,
    isVisible,
    handleToggle,
    outlined,
    dropdownClass,
    toggleClass,
    light,
    fixed,
    menuWidth,
    isDisabled = false,
    alignRight,
    split,
    disableAutoClose,
  } = props;
  const [isDropdownVisible, setDropdownVisible] = React.useState<boolean>(
    false
  );
  const toggleDropdown = React.useCallback((show) => setDropdownVisible(show), [
    setDropdownVisible,
  ]);

  const handleOnToggle = React.useCallback(
    (isOpen: boolean, e: any, metadata: { source: string }) => {
      if (handleToggle) {
        // fallback
        handleToggle();
      } else {
        if (disableAutoClose && !isOpen && metadata.source === "select") {
          return toggleDropdown(true);
        }
        toggleDropdown(!isDropdownVisible);
      }
    },
    [handleToggle, toggleDropdown, isDropdownVisible, disableAutoClose]
  );

  React.useEffect(() => setDropdownVisible(isVisible || false), [isVisible]); // fallback

  useImperativeHandle(ref, () => ({
    open: () => toggleDropdown(true),
    close: () => toggleDropdown(false),
  }));

  const styles = React.useMemo(
    () => ({
      width: size,
    }),
    [size]
  );

  const menuStyles = React.useMemo(
    () => ({
      width: menuWidth,
    }),
    [menuWidth]
  );

  const iconClass = classNames({ "m-0": !label });

  const renderToggleContent = () => {
    return (
      <>
        {iconFirst && icon && (
          <Icon name={icon} outlined={outlined} className={iconClass} />
        )}
        <span className="text-truncate">{label}</span>
        {!iconFirst && icon && <Icon name={icon} outlined={outlined} />}
      </>
    );
  };

  const dropdownClasses = classNames("dashboard-dropdown", dropdownClass);

  const toggleClasses = classNames(
    "dashboard-dropdown-toggle button",
    toggleClass,
    {
      "dashboard-dropdown-toggle--light": light,
      "dashboard-dropdown-split-dropdown": split,
    }
  );

  const splitClasses = classNames(
    "dashboard-dropdown-toggle button dashboard-dropdown-split",
    toggleClass,
    {
      "dashboard-dropdown-toggle--light": light,
    }
  );

  const buttonVariant = light ? "light" : "primary";
  const itemClasses = classNames(
    "dashboard-dropdown-item button btn btn-primary",
    {
      "dashboard-dropdown-item--light": light,
    }
  );

  const popperConfig = React.useMemo(
    () => ({
      strategy: fixed ? "fixed" : "absolute",
    }),
    [fixed]
  );

  return (
    <Dropdown
      as={ButtonGroup}
      className={dropdownClasses}
      show={isDropdownVisible}
      onToggle={handleOnToggle}
      alignRight={alignRight}
    >
      {split ? (
        <Button
          className={splitClasses}
          variant={buttonVariant}
          disabled={isDisabled}
          onClick={onClick}
        >
          {renderToggleContent()}
        </Button>
      ) : null}
      <Dropdown.Toggle
        id={id}
        className={toggleClasses}
        style={styles}
        variant={buttonVariant}
        disabled={isDisabled}
        split={split}
      >
        {!split ? renderToggleContent() : <Icon name="expand_more" />}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dashboard-dropdown-menu"
        popperConfig={popperConfig}
        style={menuStyles}
      >
        {map(items, (item) => (
          <Dropdown.Item
            key={item.id}
            as="button"
            className={itemClasses}
            onClick={item.onClick}
            onSelect={item.onSelect}
          >
            {item.icon && <Icon name={item.icon} outlined={item.outlined} />}
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default forwardRef(DashboardDropdown);
