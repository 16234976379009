import {
  AppendDynamicPropsFn,
  AppendOnClickFn,
  GenericFormFields,
  UpdateRowFn,
} from "../../generic-form/GenericFormBody";
import { TFunction } from "i18next";
import { RosterPayload, RosterStatus } from "../../../models/roster";
import { Rounding } from "../../calculator/utils";
import { chain, map } from "lodash";
import { VariationCostItemPayload } from "../../../models/variations";
import { calcItemTotal } from "../../../utils/calculations";

import { UOMOption, GenericRecord } from "../../../utils/types/options";
import { SelectOption } from "../../generic-form/inputs/creatable-select";
import CostingAutocompleteMenuOption from "../../generic-form/inputs/autocomplete/CostingAutocompleteMenuOption";
import { PriceListItem } from "../../../models/price-list";
import { SearchJobCostingItem } from "../../../models/job";

const createRosterFields = (
  t: TFunction,
  openCalculator: (filed: string) => void,
  uoms: UOMOption[],
  costingCategoryList: GenericRecord[] | undefined,
  handleUOMCreate: (uom: string) => void,
  handleCostingCreate: (costing: string) => void,
  handleCategorySelect: UpdateRowFn,
  openAllocateCosting: AppendOnClickFn,
  getAllocateProps: AppendDynamicPropsFn,
  priceItems: SelectOption[],
  handlePriceSelect: UpdateRowFn,
  handleCostingSearch: (value: string) => Promise<SearchJobCostingItem[]>,
  handlePriceSearch: (value: string) => Promise<PriceListItem[]>
): GenericFormFields<RosterPayload> => {
  const costingOptions = async (value: string) => {
    if (!value) return [];

    const costings = await handleCostingSearch(value.toLowerCase());
    const costingItems = map(
      costings,
      (costingItem) =>
        ({
          label: costingItem.name,
          value: `costing:${costingItem._id}`,
          hidden: false,
          data: costingItem,
        } as SelectOption)
    );
    const prices = await handlePriceSearch(value);

    const priceItems = map(
      prices,
      (priceItem) =>
        ({
          label: priceItem.name,
          value: `price:${priceItem._id}`,
          hidden: false,
          data: priceItem,
        } as SelectOption)
    );
    return costingItems.concat(priceItems);
  };
  return {
    status: {
      type: "select",
      label: t("variations.status"),
      placeholder: t("placeholders.status"),
      valueKey: "status",
      autocompleteProps: {
        options: chain(RosterStatus)
          .filter((s) => s !== RosterStatus.APPROVED)
          .map((status) => ({
            value: status,
            label: t(`roster.statuses.${status}`),
          }))
          .value(),
      },
      inputProps: {
        type: "text",
        required: true,
      },
    },
    // supplier: {
    //   type: "select",
    //   label: t("orders.contactName"),
    //   placeholder: t("placeholders.contactName"),
    //   valueKey: "supplier",
    //   autocompleteProps: {
    //     options: map(suppliers, (supplier) => ({
    //       value: supplier._id,
    //       label: supplier.business_name,
    //     })),
    //   },
    //   inputProps: {
    //     type: "text",
    //     required: true,
    //   },
    //   append: {
    //     onClick: openSupplierModal,
    //     icon: "add",
    //   },
    // },
    // attention: {
    //   type: "text",
    //   label: t("orders.attention"),
    //   placeholder: t("placeholders.attention"),
    //   valueKey: "attention",
    //   inputProps: {
    //     type: "text",
    //     required: true,
    //   },
    // },
    reference: {
      type: "text",
      label: t("orders.reference"),
      placeholder: t("placeholders.reference"),
      valueKey: "reference",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    date: {
      type: "date-picker",
      label: t("placeholders.date"),
      placeholder: t("placeholders.orderDate"),
      valueKey: "date",
      inputProps: {
        type: "text",
        required: true,
      },
    },
    // deliveryDate: {
    //   type: "date-picker",
    //   label: t("orders.deliveryDate"),
    //   placeholder: t("placeholders.deliveryDate"),
    //   valueKey: "deliveryDate",
    //   inputProps: {
    //     type: "text",
    //     required: true,
    //   },
    // },
    // contactNumber: {
    //   type: "text",
    //   label: t("orders.contactNumber"),
    //   placeholder: t("placeholders.contactNumber"),
    //   valueKey: "contactNumber",
    //   showError: true,
    //   inputProps: {
    //     type: "text",
    //     required: true,
    //   },
    // },
    // deliveryAddress: {
    //   type: "place-autocomplete",
    //   label: t("orders.address"),
    //   placeholder: t("placeholders.address"),
    //   valueKey: "deliveryAddress",
    //   inputProps: {
    //     type: "text",
    //     required: true,
    //   },
    // },

    // deliveryInstructions: {
    //   type: "text",
    //   label: t("orders.instruction"),
    //   placeholder: t("placeholders.instruction"),
    //   valueKey: "deliveryInstructions",
    //   controlType: "textarea",
    //   inputProps: {
    //     rows: 12,
    //     type: "text",
    //     required: true,
    //   },
    // },
    items: {
      tableTitle: t("roster.rosterItems"),
      rowGenerator: () => ({
        name: "",
        quantity: null,
        UOM: "qty",
        cost: 0,
        rounding: Rounding.NONE,
        wastage: 0,
      }),
      isTwoRow: true,
      isDraggable: true,
      isCompact: true,
      row: [
        {
          width: "350px",
          type: "creatable-select",
          label: t("common.description"),
          placeholder: t("placeholders.description"),
          valueKey: "name",
          updateTableRow: handlePriceSelect,
          inputProps: {
            type: "text",
            className: "form-input-compact",
            required: true,
          },
          creatableProps: {
            autoSelectCreatedOption: true,
            isEditable: true,
            isClearable: true,
            options: priceItems,
            asyncOptions: costingOptions,
            optionComponent: CostingAutocompleteMenuOption,
            isValidNewOption: (v: string) => v.length > 0,
          },
        },
        {
          width: "150px",
          type: "text",
          label: t("costing.quantity"),
          placeholder: t("placeholders.quantity"),
          valueKey: "quantity",
          disallowNegativeNumber: true,
          inputProps: {
            type: "number",
            className: "form-input-compact",
            required: true,
          },
          append: {
            onClick: openCalculator,
            icon: "calculate",
            className: "input-group-append-compact",
          },
        },
        {
          width: "150px",
          type: "creatable-select",
          label: t("costing.uom"),
          placeholder: t("placeholders.uom"),
          valueKey: "UOM",
          creatableProps: {
            autoSelectCreatedOption: true,
            options: uoms,
            onCreateOption: handleUOMCreate,
            isValidNewOption: (v: string) => v.length > 0,
          },
          inputProps: {
            type: "text",
            className: "form-input-compact",
            required: true,
          },
        },
        {
          width: "100px",
          type: "text",
          label: t("costing.cost"),
          placeholder: t("placeholders.costValue"),
          valueKey: "cost",
          disallowNegativeNumber: true,
          inputProps: {
            type: "number",
            required: true,
            className: "form-input-compact",
          },
        },
        {
          width: "233px",
          type: "creatable-select",
          label: t("costing.costingCategory"),
          placeholder: t("placeholders.category"),
          valueKey: "costingCategoryName",
          updateTableRow: handleCategorySelect,
          creatableProps: {
            autoSelectCreatedOption: true,
            options: costingCategoryList,
            onCreateOption: handleCostingCreate,
            isValidNewOption: (v: string) => v.length > 0,
            isClearable: true,
          },
          inputProps: {
            type: "text",
            required: true,
            className: "form-input-compact",
          },
          append: {
            onClick: openAllocateCosting,
            className: "input-group-append-compact",
            dynamicProps: getAllocateProps,
          },
        },
        {
          type: "text",
          controlType: "textarea",
          label: t("costing.note"),
          placeholder: "",
          valueKey: "note",
          secondRow: true,
          inputProps: {
            className: "form-input-compact",
            type: "text",
            rows: 2,
            debounceChangeEvent: true,
          },
        },
      ],
      dynamicFields: [
        {
          title: t("costing.totalEx"),
          formatValue: (values: VariationCostItemPayload) => {
            const amount = calcItemTotal(
              {
                margin_amount: Number(values?.margin_amount) || 0,
                cost: values?.cost || 0,
                quantity: values?.quantity || 0,
              },
              0
            );

            return t("common.currency", { amount });
          },
        },
      ],
    },
    internalNote: {
      type: "text",
      label: t("common.internalNote"),
      placeholder: t("placeholders.notes"),
      valueKey: "internalNote",
      inputProps: {
        type: "text",
        required: true,
      },
    },
  };
};

export default createRosterFields;
