import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createOrderReceiveSchema(t: TFunction) {
  return yup.object().shape({
    reference: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "common.reference")
      ),
    dateReceived: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "orders.dateReceived")
      ),
    purchaseOrderStatus: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(
          t,
          "orders.purchaseOrderStatus"
        )
      ),
    items: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "common.name")
          ),
        quantity: yup
          .number()
          .moreThan(0)
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.quantity")
          ),
        cost: yup
          .number()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.cost")
          ),
        UOM: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(t, "costing.uom")
          ),
      })
    ),
  });
}
