import React from "react";
import Carousel, { CarouselState, Modal, ModalGateway } from "react-images";

export const imageTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
  "application/pdf",
];

export function useImageViewer() {
  const [imageData, setImageData] = React.useState<null | {
    url: string;
    name: string;
  }>(null);

  const openImageViewer = React.useCallback(
    ({ url, name }: { url: string; name: string }) => {
      setImageData({ url, name });
    },
    []
  );

  const closeLightbox = React.useCallback(() => {
    setImageData(null);
  }, []);

  const getViewStyle = (base: React.CSSProperties, state: CarouselState) => {
    return {
      ...base,
      display: "flex !important",
      height: state.isFullscreen ? window.outerHeight : window.innerHeight,
      width: state.isFullscreen ? window.outerWidth : window.innerWidth,
    };
  };

  const renderGallery = React.useCallback(
    () =>
      imageData && (
        <>
          <ModalGateway>
            <Modal
              onClose={closeLightbox}
              styles={{
                dialog: (base) => ({
                  ...base,
                  maxWidth: window.innerWidth,
                  maxHeight: window.innerHeight,
                }),
              }}
            >
              <Carousel
                styles={{ view: getViewStyle }}
                views={[
                  {
                    source: {
                      regular: imageData.url,
                      fullscreen: imageData.url,
                    },
                    caption: imageData.name,
                  },
                ]}
              />
            </Modal>
          </ModalGateway>
        </>
      ),
    [imageData, closeLightbox]
  );

  return { renderGallery, openImageViewer };
}
