import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useMutation } from "@apollo/client";

import HeaderActionButton from "../../../../components/dashboard/header/HeaderActionButton";
import HeaderPageAction from "../../../../components/dashboard/header/HeaderPageAction";
import SetNavigationRoute from "../../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../../components/dashboard/sidebar/utils/subcontractor-navigation-items";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../../layouts/dashboard/DashboardContext";
import ChangePasswordModal from "../../../../components/profile/change-pwd-modal";
import { notify } from "../../../../components/notification";
import DetailsCard, {
  DetailsCardAction,
} from "../../../../components/dashboard/details-card";
import UpdateSubProfileModal from "../../../../components/profile/sub-update-profile-modal";
import { UpdateSubProfilePayload } from "../../../../models/profile";
import GraphQLClient from "../../../../graphql";
import {
  SUBCONTRACTOR_CHANGE_PASSWORD,
  SUBCONTRACTOR_EDIT_PROFILE,
} from "../../../../graphql/queries/subcontractor/auth/mutations";
import { DetailsCardDataItem } from "../../../../components/dashboard/details-card/DetailsCardList";
import {
  UserPayload,
  ChangePasswordResponse,
  SubcontractorEditProfileResponse,
} from "../../../../graphql/types/models/auth";
import { updateProfile } from "../../../../redux/authentication/actions";
import { RootReducerState } from "../../../../redux/reducer";

type ProfileOverviewProps = DashboardContextValue & {
  user: UserPayload | null;
  updateProfile: (user: Partial<UserPayload>) => void;
};

const ProfileOverview: React.FC<ProfileOverviewProps> = (props) => {
  const { user, updateProfile } = props;

  const { t } = useTranslation();

  const [showPasswordModal, setPwdModalVisibility] = useState(false);
  const [showEditModal, setEditModalVisibility] = useState(false);

  const [subcontractorChangePassword] = useMutation<ChangePasswordResponse>(
    SUBCONTRACTOR_CHANGE_PASSWORD,
    {
      onCompleted: (data) => {
        GraphQLClient.setToken(data.changePassword.token);
        setPwdModalVisibility(false);
        notify({
          title: t("profile.changePassword"),
          content: t("profile.success.changePassword"),
        });
      },
      onError: () => {
        notify({
          title: t("profile.changePassword"),
          content: t("profile.errors.changePassword"),
          error: true,
        });
      },
    }
  );

  const [updateSubcotractorProfileAction] = useMutation<
    SubcontractorEditProfileResponse
  >(SUBCONTRACTOR_EDIT_PROFILE, {
    onCompleted: (data) => {
      updateProfile(data.subcontractorEditProfile);
      setEditModalVisibility(false);
    },
    onError: () => {
      notify({
        error: true,
        title: t("profile.updateProfile"),
        content: t("profile.updateProfile"),
      });
      setEditModalVisibility(false);
    },
  });

  const openPwdModal = React.useCallback(() => {
    setPwdModalVisibility(true);
  }, []);

  const closePwdModal = React.useCallback(() => {
    setPwdModalVisibility(false);
  }, []);

  const openEditModal = React.useCallback(() => {
    setEditModalVisibility(true);
  }, []);

  const closeEditModal = React.useCallback(() => {
    setEditModalVisibility(false);
  }, []);

  const handlePwdChangeSubmit = React.useCallback(
    (values) => {
      return subcontractorChangePassword({ variables: values });
    },
    [subcontractorChangePassword]
  );

  const handleEditSubmit = React.useCallback(
    (values: UpdateSubProfilePayload) => {
      return updateSubcotractorProfileAction({
        variables: { subcontractor: values },
      });
    },
    [updateSubcotractorProfileAction]
  );

  const profileCardData: DetailsCardDataItem[] = React.useMemo(() => {
    return [
      {
        icon: "work_outline",
        text: user?.business_name || "",
      },
      {
        icon: "person",
        text: user?.contact_person || "",
      },
      {
        icon: "mail",
        text: user ? user.email : "",
      },
    ];
  }, [user]);

  const profileActions: DetailsCardAction[] = React.useMemo(
    () => [
      {
        title: t("profile.editDetails"),
        onClick: openEditModal,
        className: "button info large",
        disabled: !user,
      },
    ],
    [t, openEditModal, user]
  );

  return (
    <Container fluid className="m-0 p-0 h-100 profile-overview">
      <Helmet title={t("navigation.account.profile")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.ACCOUNT.MY_PROFILE} />
      <HeaderPageAction>
        <HeaderActionButton icon="lock" outlined onClick={openPwdModal}>
          {t("profile.changePassword")}
        </HeaderActionButton>
      </HeaderPageAction>

      <ChangePasswordModal
        onSubmit={handlePwdChangeSubmit}
        onClose={closePwdModal}
        show={showPasswordModal}
      />

      {user && (
        <UpdateSubProfileModal
          user={user}
          show={showEditModal}
          onClose={closeEditModal}
          onSubmit={handleEditSubmit}
        />
      )}

      <Row className="h-100">
        <Col lg={6} xs={12} xl={4}>
          <DetailsCard
            title={t("profile.details")}
            actions={profileActions}
            fullHeight
            data={profileCardData}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default withDashboardContext(
  connect(mapStateToProps, { updateProfile })(ProfileOverview)
);
