import React from "react";

export function useLocalStorage<T>(storageKey: string, initialValue: T) {
  const [storedValue, setValue] = React.useState<T>(() => {
    try {
      const stored = localStorage.getItem(storageKey);
      return stored ? JSON.parse(stored) : null;
    } catch (error) {
      return initialValue;
    }
  });

  const setStoredValue = (value: T) => {
    try {
      setValue(value);
      localStorage.setItem(storageKey, JSON.stringify(value));
    } catch {}
  };

  return { storedValue, setStoredValue };
}
