import { TFunction } from "i18next";
import moment from "moment";
import * as yup from "yup";
import { ValidationMessagesUtils } from "../../../utils/yup";

export default function createProcessSchema(t: TFunction) {
  return yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        rate: yup
          .number()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "jobTimesheets.rate"
            )
          ),
        startTime: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "timesheets.startDate"
            )
          ),
        endTime: yup
          .string()
          .test("endTimeTest", "End Date Test Message", function (
            value: string
          ) {
            const { startTime } = this.parent;
            return moment(value).isAfter(startTime);
          })
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "timesheets.endDate"
            )
          ),
      })
    ),
  });
}
