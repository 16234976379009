import classNames from "classnames";
import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Badge, Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

import Icon from "../../icons/Icon";
import {
  TableCardDataRow,
  TableDropdownItem,
  TableRowActionData,
} from "./utils";
import Dropdown, { DropdownItem } from "../dropdown";
import { chain, map } from "lodash";
import SVGIcon from "../../icons/SVGIcon";

type CartTableRowActionProps = {
  row: TableCardDataRow<any>;
  action: TableRowActionData<any>;
};

const TableRowAction: React.FC<CartTableRowActionProps> = ({ row, action }) => {
  const [showDropdown, setDropdownVisibility] = useState(false);

  const toggleDropdown = React.useCallback(() => {
    setDropdownVisibility(!showDropdown);
  }, [showDropdown]);

  const handleClick = React.useCallback(() => {
    action.onClick && action.onClick(row.cells);
  }, [action, row]);

  const shouldRender = action.shouldRender
    ? action.shouldRender(row.cells)
    : true;

  const options: DropdownItem[] = React.useMemo(() => {
    return chain(action.options)
      .filter((option) =>
        option.shouldRender ? option.shouldRender(row.cells) : true
      )
      .map((option) => ({
        ...option,
        onClick: () => {
          option.onClick && option.onClick(row.cells);
        },
      }))
      .value();
  }, [action, row]);

  const tooltip = React.useMemo(() => {
    if (action.tooltip) {
      const tt = action.tooltip(row.cells)?.replace(/\n/g, "<br />");
      if (tt) return tt;
    }
    return null;
  }, [action, row]);

  const title = React.useMemo(() => {
    if (!action.title || typeof action.title === "string") return action.title;
    return action.title(row.cells);
  }, [action, row]);

  const badgeCounter = React.useMemo(() => {
    if (!action.counter) return null;
    let count = 0;
    if (typeof action.counter === "number") {
      count = action.counter;
    } else {
      count = action.counter(row.cells);
    }
    if (count === 0) return null;
    return (
      <Badge pill variant="warning">
        {count}
      </Badge>
    );
  }, [action, row]);

  if (!shouldRender) {
    return null;
  }

  if (!isEmpty(action.options) && action.dropdownId) {
    return (
      <Dropdown
        menuWidth="230px"
        light
        iconFirst
        isVisible={showDropdown}
        handleToggle={toggleDropdown}
        label={action.text}
        icon={action.icon}
        outlined
        id={action.dropdownId}
        items={options}
      />
    );
  }

  const iconsClasses = classNames("action-icon", {
    "m-0": !action.text,
  });

  return (
    <>
      <Button
        variant="light"
        onClick={handleClick}
        className={classNames("button action cursor-pointer", action.className)}
        title={title}
        data-tip={tooltip}
        data-for={`${action.dropdownId}${row.cells._id}`}
      >
        {badgeCounter}
        {action.icon && (
          <Icon name={action.icon} outlined className={iconsClasses} />
        )}
        {action.svgIcon && (
          <SVGIcon name={action.svgIcon} className={iconsClasses} />
        )}
        {action.text && <div>{action.text}</div>}
      </Button>

      {tooltip && (
        <ReactTooltip
          id={`${action.dropdownId}${row.cells._id}`}
          className="table-tooltip"
          place="left"
          html={true}
        />
      )}
    </>
  );
};

export default TableRowAction;
