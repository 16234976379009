import React from "react";
import { SingleValueProps } from "react-select";
import classNames from "classnames";

const HeaderSearchSingleValue: React.FC<SingleValueProps<any>> = ({
  className,
}) => {
  return <div className={classNames(className, "search-single-value")}></div>;
};

export default HeaderSearchSingleValue;
