import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { find, head, chain, map, isEmpty } from "lodash";
import moment from "moment";

import { ModalDisplayRef } from "../../../hooks/useModalDisplay";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import EstimationLocationHeader from "../../header/estimation-location-header";
import SelectionCategories from "../../../components/selection/selection-categories";
import { GET_SELECTION } from "../../../graphql/queries/selection/queries";
import {
  EnumSelectionEntityType,
  EnumSelectionLinkedEntityType,
  EnumSelectionStatus,
  SalesQuoteGetSelectionResponse,
  SelectionCategory,
  SelectionCategoryItemOption,
} from "../../../graphql/types/models/selections";
import { useSalesQuoteQuery } from "../../../hooks/queries/useSalesQuoteQuery";
import DashboardCardHeader from "../../../components/dashboard/card/DashboardCardHeader";
import DashboardCard from "../../../components/dashboard/card";
import { UOMOption } from "../../../utils/types/options";
import { SalesQuoteCategory } from "../../../models/salesQuote";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import DashboardCardBody from "../../../components/dashboard/card/DashboardCardBody";
import CategorySelectionItem from "../../../components/selection/selection-category-item";
import SelectionBudge from "../../../components/selection/badge";
import SaveSelectionAsTemplateModal from "../../../components/selection/save-template-selection-modal";
import ImportSelectionFromTemplateModal from "../../../components/selection/import-selection-from-template";
import { useSelection } from "../../../hooks/useSelection";
import "./styles.scss";

type SelectionsContainerProps = DashboardContextValue;

const QuoteSelections: React.FC<SelectionsContainerProps> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const salesQuoteId = navigationContext?.quote?._id || "";
  const isLocked = !!navigationContext?.quote?.isLocked;

  const saveSelectionAsTemplateRef = React.useRef<ModalDisplayRef>();
  const importSelectionFromTemplateRef = React.useRef<ModalDisplayRef>();

  const [
    selectedCategory,
    setCategory,
  ] = React.useState<SelectionCategory | null>(null);

  const { salesQuote } = useSalesQuoteQuery(salesQuoteId);

  const { data: quoteSelection, loading: quoteSelectionLoading } = useQuery<
    SalesQuoteGetSelectionResponse
  >(GET_SELECTION, {
    variables: {
      linkedEntityId: salesQuoteId,
      linkedEntityType: EnumSelectionLinkedEntityType.ESTIMATION,
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    const data = quoteSelection?.getSelection.categories;
    const selectionCategory =
      find(data, { _id: selectedCategory?._id }) || head(data);
    setCategory(selectionCategory || null);
  }, [quoteSelection]);

  const {
    renderCreateUpdateCategoryModal,
    openCreateCategoryModal,
    handleOnEditCategory,
    openDeleteCategoryDialog,
    renderCreateUpdateCategoryItemModal,
    renderCreateUpdateItemOptionModal,
    showCreateCategoryItemModal,
    openDeleteOptionDialog,
    handleUnselectCategoryItem,
    openEditItemOptionModal,
    openCreateItemOptionModal,
    openDeleteCategoryItemDialog,
    openEditCategoryItemModal,
    isDownloadingFiles,
    handleSelectSelectionCategoryItem,
    openApproveOptionConfirmModal,
    openSendModal,
    renderApproveOptionConfirm,
    renderShareSelectionConfirm,
  } = useSelection({
    selectionEntityId: quoteSelection?.getSelection._id,
    selectionEntityType: EnumSelectionEntityType.SELECTION,
    selectedCategory,
    salesQuoteId,
    categories: quoteSelection?.getSelection.categories,
    contact: salesQuote?.contact,
  });

  const handleCategorySelect = React.useCallback(
    (category: SelectionCategory) => {
      setCategory(category);
    },
    []
  );

  const handleBackClick = React.useCallback(() => {
    history.push(`/quotes/${salesQuoteId}/specifications`);
  }, [salesQuoteId, history]);

  const handleNextClick = React.useCallback(() => {
    history.push(`/quotes/${salesQuoteId}/preview`);
  }, [salesQuoteId, history]);

  const uoms = React.useMemo(
    () =>
      chain(salesQuote?.costingCategories)
        .reduce((items: UOMOption[], next: SalesQuoteCategory) => {
          return items.concat(
            map(next.costings, (costing) => ({
              label: costing.UOM,
              value: costing.UOM,
            }))
          );
        }, [])
        .value(),
    [salesQuote?.costingCategories]
  );

  const allUoms = React.useMemo(
    () =>
      chain(quoteSelection?.getSelection.categories)
        .reduce(
          (
            items: SelectionCategoryItemOption[],
            category: SelectionCategory
          ) => {
            return items.concat(...map(category.items, (item) => item.options));
          },
          []
        )
        .map((option) => ({
          label: option.UOM,
          value: option.UOM,
        }))
        .concat(uoms)
        .uniqBy("value")
        .value(),
    [quoteSelection?.getSelection.categories, uoms]
  );

  const handleSaveAsTemplate = React.useCallback(() => {
    saveSelectionAsTemplateRef.current?.show(true);
  }, [saveSelectionAsTemplateRef]);

  const handleImportFromTemplate = React.useCallback(() => {
    importSelectionFromTemplateRef.current?.show(true);
  }, [importSelectionFromTemplateRef]);

  const overDue = React.useMemo(() => {
    return moment().isAfter(moment(selectedCategory?.dueDate).endOf("day"));
  }, [selectedCategory]);

  return (
    <Container fluid className="m-0 p-0 h-100 quote-selections">
      <Helmet title={t("navigation.quotesSection.selections")} />
      <SetNavigationRoute
        routeId={NAVIGATION_ROUTES.QUOTES_SECTION.SELECTIONS}
      />
      <EstimationLocationHeader />

      {renderApproveOptionConfirm()}
      {renderShareSelectionConfirm()}
      {renderCreateUpdateCategoryModal()}
      {renderCreateUpdateCategoryItemModal(salesQuote?.costingCategories)}
      {renderCreateUpdateItemOptionModal(allUoms)}

      <SaveSelectionAsTemplateModal
        ref={saveSelectionAsTemplateRef}
        selectionEntityId={quoteSelection?.getSelection._id}
      />
      <ImportSelectionFromTemplateModal
        ref={importSelectionFromTemplateRef}
        selectionEntityId={quoteSelection?.getSelection._id}
      />

      <Row className="h-100">
        <Col lg={4} xs={12}>
          <SelectionCategories
            displayNavButtons={true}
            categories={quoteSelection?.getSelection.categories}
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}
            onDelete={openDeleteCategoryDialog}
            onAddClick={openCreateCategoryModal}
            selectedCategory={selectedCategory}
            selectCategory={handleCategorySelect}
            disabled={isLocked}
            onShareSelection={openSendModal}
            selectionStatus={quoteSelection?.getSelection.status}
            onSaveAsTemplate={handleSaveAsTemplate}
            onImportFromTemplate={handleImportFromTemplate}
          />
        </Col>
        <Col lg={8} xs={12}>
          <div>
            <DashboardCard>
              <DashboardCardHeader className="justify-content-between">
                <div className="d-flex align-items-center text-capitalize mr-3">
                  {selectedCategory?.name}
                  <SelectionBudge status={selectedCategory?.status} />
                  {overDue &&
                    selectedCategory?.status !==
                      EnumSelectionStatus.COMPLETED && (
                      <SelectionBudge status={EnumSelectionStatus.OVERDUE} />
                    )}
                </div>
                <div className="d-flex">
                  {!(
                    isLocked || isEmpty(quoteSelection?.getSelection.categories)
                  ) && (
                    <Button
                      className="button info text-light button-hover"
                      onClick={handleOnEditCategory}
                    >
                      {t("common.edit")}
                    </Button>
                  )}
                </div>
              </DashboardCardHeader>
              <DashboardCardBody className="category-container">
                <div className="category-details">
                  <div>
                    {selectedCategory?.dueDate && (
                      <div className="category-dueDate">
                        {t("common.due", {
                          date: moment(selectedCategory?.dueDate).format(
                            "DD/MM/YYYY"
                          ),
                        })}
                      </div>
                    )}
                    {selectedCategory?.description && (
                      <div className="category-description">
                        {selectedCategory?.description}
                      </div>
                    )}
                  </div>
                  <Button
                    className="button success create-item"
                    onClick={showCreateCategoryItemModal}
                    disabled={
                      quoteSelectionLoading ||
                      isLocked ||
                      isEmpty(quoteSelection?.getSelection.categories)
                    }
                  >
                    {t("selections.createItem")}
                  </Button>
                </div>
                {selectedCategory?.items.length ? (
                  selectedCategory?.items.map((item) => {
                    return (
                      <CategorySelectionItem
                        key={item._id}
                        item={item}
                        onDeleteOption={openDeleteOptionDialog}
                        onSelectOption={handleSelectSelectionCategoryItem}
                        onUnselectOption={handleUnselectCategoryItem}
                        onEditOption={openEditItemOptionModal}
                        onCreateOption={openCreateItemOptionModal}
                        onApproveOption={openApproveOptionConfirmModal}
                        onDelete={openDeleteCategoryItemDialog}
                        onEdit={openEditCategoryItemModal}
                        isLoading={isDownloadingFiles}
                        isLocked={isLocked}
                      />
                    );
                  })
                ) : (
                  <EmptyPlaceholder
                    message={t("selections.emptyCategoryItemsPlaceholder")}
                  />
                )}
              </DashboardCardBody>
            </DashboardCard>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withDashboardContext(QuoteSelections);
