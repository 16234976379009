import { TFunction } from "i18next";
import * as yup from "yup";
import {
  SiteDiaryFieldType,
  SiteDiaryTemplate,
} from "../../../../graphql/types/models/site-diary";
import { ValidationMessagesUtils } from "../../../../utils/yup";

export default function createSiteDiaryTemplateSchema(t: TFunction) {
  return yup.object().shape({
    name: yup
      .string()
      .required(
        ValidationMessagesUtils.createRequiredMessage(t, "siteDiary.title")
      ),
    fields: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "siteDiary.fieldTitle"
            )
          ),
        type: yup
          .string()
          .oneOf([
            SiteDiaryFieldType.String,
            SiteDiaryFieldType.Number,
            SiteDiaryFieldType.Date,
            SiteDiaryFieldType.Collection,
            SiteDiaryFieldType.Radio,
            SiteDiaryFieldType.Heading,
          ])
          .required(
            ValidationMessagesUtils.createRequiredMessage(
              t,
              "siteDiary.fieldType"
            )
          ),
        subFields: yup.array().when("type", {
          is: (value) => value === SiteDiaryFieldType.Collection,
          then: yup
            .array()
            .of(
              yup.object().shape({
                title: yup
                  .string()
                  .required(
                    ValidationMessagesUtils.createRequiredMessage(
                      t,
                      "siteDiary.fieldTitle"
                    )
                  ),
                type: yup
                  .string()
                  .oneOf([
                    SiteDiaryFieldType.String,
                    SiteDiaryFieldType.Number,
                    SiteDiaryFieldType.Date,
                  ])
                  .required(
                    ValidationMessagesUtils.createRequiredMessage(
                      t,
                      "siteDiary.fieldType"
                    )
                  ),
              })
            )
            .required(
              ValidationMessagesUtils.createRequiredMessage(
                t,
                "siteDiary.fieldType"
              )
            ),
        }),
        options: yup.array().when("type", {
          is: (value) => value === SiteDiaryFieldType.Radio,
          then: yup
            .array()
            .min(1)
            .required(
              ValidationMessagesUtils.createRequiredMessage(
                t,
                "siteDiary.option"
              )
            ),
        }),
      })
    ),
  });
}
