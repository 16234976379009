import React, { ChangeEvent } from "react";
import { map } from "lodash";
import { Row, Col, Form } from "react-bootstrap";
import { TEXT_AREA_ROWS } from "./utils";
import CalculatorButton from "./CalculatorButton";

export type CalcButton = {
  text?: string;
  icon?: string;
  value: string;
  className?: string;
  onClick?: (value: string) => void;
};

export type CalcButtonCol = CalcButton[];

export type CalcInput = {
  columns: CalcButtonCol[];
};

type CalculatorInputProps = {
  config: CalcInput;
  expression: string;
  onTextareaChange: (value: ChangeEvent<HTMLTextAreaElement>) => void;
  onCalcButtonClick: (value: string) => void;
  calcResult: number | null;
};

const CalculatorInput: React.FC<CalculatorInputProps> = (props) => {
  const {
    onCalcButtonClick,
    config,
    onTextareaChange,
    expression,
    calcResult,
  } = props;

  const renderColumn = (column: CalcButtonCol, index: number) => {
    return (
      <Col key={index} className="btn-col">
        {map(column, (action) => (
          <CalculatorButton
            key={action.value}
            {...action}
            onClick={onCalcButtonClick}
          />
        ))}
      </Col>
    );
  };

  return (
    <div className="calculator-input">
      <div className="calc-textarea-container">
        <Form.Control
          as="textarea"
          onChange={onTextareaChange}
          value={expression}
          rows={TEXT_AREA_ROWS}
          className="calc-textarea"
        />
        <div className="calculator-result">{calcResult}</div>
      </div>
      <Row className="calc-row">{map(config.columns, renderColumn)}</Row>
    </div>
  );
};

export default CalculatorInput;
