import { MutationUpdaterFn } from "@apollo/client";
import {
  CreateTakeoffResponse,
  DeleteTakeoffResponse,
  ListTakeoffItemsResponse,
} from "../../types/models/take-off";
import { LIST_TAKEOFF } from "./queries";
import { concat, filter } from "lodash";

export const handleTakeOffAdd = (
  salesQuoteId?: string
): MutationUpdaterFn<CreateTakeoffResponse> => (cache, response) => {
  const newTakeOff = response.data?.createTakeOffItem;

  const takeOffListResponse = cache.readQuery<ListTakeoffItemsResponse>({
    query: LIST_TAKEOFF,
    variables: { salesQuoteId },
  });

  if (!newTakeOff || !takeOffListResponse) {
    return;
  }

  const takeOffItems = takeOffListResponse.listSalesQuoteTakeOff;

  cache.writeQuery({
    query: LIST_TAKEOFF,
    variables: { salesQuoteId },
    data: {
      listSalesQuoteTakeOff: concat(takeOffItems, newTakeOff),
    },
  });
};

export const handleTakeoffDelete = (
  salesQuoteId?: string
): MutationUpdaterFn<DeleteTakeoffResponse> => (cache, response) => {
  const removedTakeoffId = response?.data?.deleteTakeOffItem?._id;

  const takeOffListResponse = cache.readQuery<ListTakeoffItemsResponse>({
    query: LIST_TAKEOFF,
    variables: { salesQuoteId },
  });

  if (!removedTakeoffId || !takeOffListResponse) {
    return;
  }

  const takeOffItems = takeOffListResponse.listSalesQuoteTakeOff;

  cache.writeQuery({
    query: LIST_TAKEOFF,
    variables: { salesQuoteId },
    data: {
      listSalesQuoteTakeOff: filter(takeOffItems, (takeOff) => {
        return takeOff._id !== removedTakeoffId;
      }),
    },
  });
};
