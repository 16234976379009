import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { BadgeProps, Button } from "react-bootstrap";
import classNames from "classnames";
import { map, reduce } from "lodash";
import { Formik, FormikProps } from "formik";
import DashboardCardHeader from "../dashboard/card/DashboardCardHeader";
import DashboardCardBody from "../dashboard/card/DashboardCardBody";
import EmptyPlaceholder from "../../components/empty-placeholder";
import DashboardCard from "../dashboard/card";
import DashboardCardFooter from "../dashboard/card/DashboardCardFooter";
import Dropdown, { DropdownItem } from "../dashboard/dropdown";
import Icon from "../icons/Icon";
import CategorySelectItem from "./CategorySelectItem";
import SearchInput from "../search-input";
import { Pagination } from "../../models/pagination";
import CheckBox from "../checkbox";
import Totals from "../costing/total";
import "./styles.scss";

export type CategorySelectorPayload = {
  id: string;
  label: string;
  description?: string;
  descriptionValue?: string;
  count?: number;
  rightLabel?: string | null;
  rightBadge?: {
    label: string;
    variant: BadgeProps["variant"];
  }[];
  orderNumber?: string;
  reference?: string;
  total?: number;
  supplierBusinessName?: string;
  contactPerson?: string;
};
export type CategorySelectorFilter = {
  id?: string;
  selectAll?: boolean;
  label?: string;
  icon?: string;
};

export type SelectableDropdownItem = {
  id?: string;
  label?: string;
  icon?: string;
  onClick: (ids: string[]) => void;
};

export enum PaginationButton {
  Previous = "Previous",
  Next = "Next",
}

export type SearchOptions = {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
};

type CategorySelectorCardProps = {
  categories?: CategorySelectorPayload[];
  onSelectCategory?: (category: CategorySelectorFilter) => void;
  onAddNewItem?: () => void;
  labelSelectAll?: string;
  filter: CategorySelectorFilter;
  title?: string;
  disabled?: boolean;
  hideSelectAllButton?: boolean;
  hideAddButton?: boolean;
  hideFilter?: boolean;
  filterDropdownItems?: DropdownItem[];
  dropdownFilter?: string;
  dropdownFilterLabel?: string;
  placeholder?: string;
  selectable?: boolean;
  selectableDropdownItems?: SelectableDropdownItem[];
  searchOptions?: SearchOptions;
  additionalFilters?: () => JSX.Element;
  pagination?: Pagination;
  onPagination?: (type: PaginationButton) => void;
  headerClassName?: string;
  total?: number;
};

const CategorySelectorCard: React.FC<CategorySelectorCardProps> = (props) => {
  const {
    categories,
    onSelectCategory,
    onAddNewItem,
    labelSelectAll,
    title,
    filter,
    disabled,
    hideSelectAllButton,
    hideAddButton,
    filterDropdownItems,
    dropdownFilter,
    dropdownFilterLabel,
    placeholder,
    selectable,
    selectableDropdownItems,
    children,
    searchOptions,
    additionalFilters,
    pagination,
    onPagination,
    hideFilter,
    headerClassName,
    total,
  } = props;
  const { t } = useTranslation();

  const [isDropdownShow, setIsDropdownShow] = React.useState(false);
  const [checkAllItems, setCheckAllItems] = React.useState<boolean>(false);

  const toggleDropdownShow = React.useCallback(
    () => setIsDropdownShow(!isDropdownShow),
    [isDropdownShow]
  );

  const handleItemClick = React.useCallback(
    (
      category: CategorySelectorPayload,
      formikProps?: FormikProps<any>
    ) => () => {
      onSelectCategory &&
        onSelectCategory({
          id: category.id,
          label: category.label,
        });
      if (selectable) {
        const { selected } = formikProps?.values as { selected: string[] };
        if (selected) {
          if (selected.includes(category.id)) {
            setCheckAllItems(false);
          } else {
            if (selected.length + 1 === categories?.length) {
              setCheckAllItems(true);
            }
          }
        }
      }
    },
    [categories?.length, onSelectCategory, selectable]
  );

  const handleSelectAll = React.useCallback(() => {
    onSelectCategory &&
      onSelectCategory({
        selectAll: true,
      });
  }, [onSelectCategory]);

  const totalCount = React.useMemo(() => {
    return reduce(
      categories,
      (prev, current) => {
        if (!current.count) {
          return 0;
        }

        return prev + current.count;
      },
      0
    );
  }, [categories]);

  const getItemAllClass = (): string => {
    return classNames("list-item", {
      "item-selected": filter.selectAll,
    });
  };

  const handleListPagination = React.useCallback(
    (type: PaginationButton) => {
      if (onPagination) {
        onPagination(type);
      }
    },
    [onPagination]
  );

  const renderList = (formikProps?: FormikProps<any>) => {
    if (children) return children;
    return !categories?.length ? (
      <EmptyPlaceholder
        message={placeholder ?? t("placeholders.noCategories")}
      />
    ) : (
      map(categories, (category: CategorySelectorPayload) => {
        return (
          <CategorySelectItem
            key={category.id}
            item={category}
            selectable={selectable}
            onSelect={handleItemClick(category, formikProps)}
            selected={category.id === filter.id}
          />
        );
      })
    );
  };

  const renderBody = (formikProps?: FormikProps<any>) => {
    return (
      <div className="list">
        {!hideSelectAllButton && (
          <div className={getItemAllClass()} onClick={handleSelectAll}>
            <div>{labelSelectAll}</div>
            <div>{totalCount}</div>
          </div>
        )}
        {renderList(formikProps)}
      </div>
    );
  };

  const headerClassNames = classNames("text-capitalize", headerClassName, {
    "justify-content-center p-0": !filterDropdownItems,
    "justify-content-between": filterDropdownItems,
    "pl-3": selectable,
  });

  const selectableItems = React.useCallback(
    (formikProps?: FormikProps<any>) =>
      map(
        selectableDropdownItems,
        (item) =>
          ({
            ...item,
            onClick: () => {
              item.onClick(formikProps?.values?.selected);
            },
          } as DropdownItem)
      ),
    [selectableDropdownItems]
  );

  const selectAllItems = React.useCallback(
    (formikProps?: FormikProps<any>) => {
      if (checkAllItems) {
        formikProps?.setFieldValue("selected", []);
        setCheckAllItems(false);
      } else {
        formikProps?.setFieldValue(
          "selected",
          categories?.map(({ id }) => id)
        );
        setCheckAllItems(true);
      }

      return [];
    },
    [categories, checkAllItems]
  );

  const Content = (formikProps?: FormikProps<any>) => (
    <DashboardCard className="category-select-card">
      <DashboardCardHeader className={headerClassNames}>
        {filterDropdownItems && (
          <>
            <div className="d-flex justify-content-center">
              {selectable && categories?.length !== 0 && (
                <CheckBox
                  value={checkAllItems}
                  onClick={() => selectAllItems(formikProps)}
                />
              )}
              {!searchOptions ? (
                <>
                  {!Boolean(additionalFilters) && (
                    <div className="d-flex align-items-left">{title}</div>
                  )}
                </>
              ) : (
                <SearchInput
                  value={searchOptions.value}
                  onChange={searchOptions.onChange}
                  showCancelButton
                />
              )}
            </div>
            {additionalFilters && additionalFilters()}
            {filterDropdownItems.length > 0 && !hideFilter && (
              <>
                <Dropdown
                  isVisible={isDropdownShow}
                  handleToggle={toggleDropdownShow}
                  label={
                    dropdownFilter ||
                    dropdownFilterLabel ||
                    t("common.filterByStatus")
                  }
                  icon="expand_more"
                  id="category-dropdown"
                  items={filterDropdownItems}
                  alignRight
                />
              </>
            )}
          </>
        )}
        {!filterDropdownItems && title}
      </DashboardCardHeader>
      <DashboardCardBody
        className={classNames({ heightWithTotal: Boolean(total) })}
      >
        {renderBody(formikProps)}
      </DashboardCardBody>
      {Boolean(total) && (
        <>
          <div className="divider" />
          <div className="total-container">
            <Totals total={total} showOnlyTotal />
          </div>
        </>
      )}
      {pagination && (
        <DashboardCardFooter className="d-flex justify-content-between align-items-center">
          <Button
            className="button info"
            onClick={() => handleListPagination(PaginationButton.Previous)}
            disabled={!pagination.hasPrevPage}
          >
            {t("common.previous")}
          </Button>
          {pagination.totalPages > 0 && (
            <div>
              <span className="field-text">
                {t("pagination.pageOfTotal", pagination)}
              </span>
            </div>
          )}
          <Button
            className="button info"
            onClick={() => handleListPagination(PaginationButton.Next)}
            disabled={!pagination.hasNextPage}
          >
            {t("common.next")}
          </Button>
        </DashboardCardFooter>
      )}
      <DashboardCardFooter
        className={classNames("d-flex", {
          "justify-content-end": !selectable,
          "justify-content-between": selectable,
        })}
      >
        {selectable && (
          <Dropdown
            label={t("common.withSelected")}
            icon="expand_more"
            id="selected-dropdown"
            items={selectableItems(formikProps)}
            isDisabled={!formikProps?.values?.selected?.length}
          />
        )}
        {!hideAddButton && (
          <Button
            className="button success"
            onClick={onAddNewItem}
            disabled={disabled}
          >
            <Icon name="add" />
            {t("common.createNew")}
          </Button>
        )}
      </DashboardCardFooter>
    </DashboardCard>
  );
  if (selectable) {
    return (
      <Formik
        initialValues={{ selected: [] }}
        onSubmit={async (values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {(formikProps) => Content(formikProps)}
      </Formik>
    );
  }
  return Content();
};

export default CategorySelectorCard;
