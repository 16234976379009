import React from "react";
import ReactDOM from "react-dom";

export const HEADER_PAGE_ACTION_ID = "header-page-action";

type HeaderPageActionProps = {
  order?: string;
};

let pageActionRoot: HTMLElement | null = null;

const HeaderPageAction: React.FC<HeaderPageActionProps> = ({
  children,
  order,
}) => {
  const container = React.useMemo(() => {
    const doc = document.createElement("div");
    doc.className = "page-action";

    if (order) {
      doc.style.order = order;
    }

    return doc;
  }, []);

  React.useEffect(() => {
    pageActionRoot = document.getElementById(HEADER_PAGE_ACTION_ID);
    pageActionRoot?.appendChild(container);

    return () => {
      pageActionRoot?.removeChild(container);
    };
  });

  return ReactDOM.createPortal(children, container);
};

export default HeaderPageAction;
