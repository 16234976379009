export type ListSalesQuotePrintTemplatesResponse = {
  listSalesQuotePrintTemplates: SalesQuotePrintTemplate[];
};

export type PreviewTemplateForSalesQuoteResponse = {
  previewTemplateForSalesQuote: {
    _id: string;
    pdf: string;
  };
};
export type PrintSalesQuoteTemplateResponse = {
  printSalesQuoteTemplate: {
    _id: string;
    pdf: string;
  };
};

export type CreateUpdateSalesQuotePrintTemplateResponse = {
  createUpdateSalesQuotePrintTemplate: SalesQuotePrintTemplate;
};

export type CopySalesQuotePrintTemplateResponse = {
  copySalesQuotePrintTemplate: SalesQuotePrintTemplate;
};

export type DeleteSalesQuotePrintTemplateResponse = {
  deleteSalesQuotePrintTemplate: SalesQuotePrintTemplate;
};

export enum SalesQuotePrintTemplateCoverStyle {
  ACHROMATIC = "ACHROMATIC",
  BRILLIANT = "BRILLIANT",
  BALANCE = "BALANCE",
}

export type SalesQuotePrintTemplateCoverImage = {
  _id?: string;
  name?: string;
  type?: string;
  size?: number;
  url?: string;
  upload_url?: string;
  previewBase64?: string;
  newFile?: boolean;
};
export type SalesQuotePrintTemplateCover = {
  enabled: boolean;
  title: string;
  subtitle: string;
  style: SalesQuotePrintTemplateCoverStyle;
  background: SalesQuotePrintTemplateCoverImage;
  logo: SalesQuotePrintTemplateCoverImage;
  display: {
    logo: boolean;
  };
};
export type SalesQuotePrintTemplate = {
  _id: string;
  salesQuoteId?: string;
  name: string;
  is_system: boolean;
  coverBackgroundImage?: {
    file?: File;
  };
  coverLogoImage?: {
    file?: File;
  };
  theme: {
    backgroundColour: string;
    textColour: string;
  };
  cover: SalesQuotePrintTemplateCover;
  letter: {
    enabled: boolean;
    text: string;
  };
  costings: {
    enabled: boolean;
    title: string;
    text: string;
    display: {
      categoryTotal: boolean;
      items: boolean;
      itemUnits: boolean;
      itemCost: boolean;
      costIncGST: boolean;
      itemTotal: boolean;
      freeItems: boolean;
      assemblyItems: boolean;
      itemNotes: boolean;
    };
  };
  allowances: {
    enabled: boolean;
    title: string;
    text: string;
    display: {
      categoryTotal: boolean;
      items: boolean;
      itemUnits: boolean;
      itemCost: boolean;
      costIncGST: boolean;
      itemTotal: boolean;
      itemCostExcludeMarkup: boolean;
      freeItems: boolean;
      assemblyItems: boolean;
    };
  };
  specifications: {
    enabled: boolean;
    title: string;
    text: string;
    display: {
      specificationNumber: boolean;
    };
  };
  notes: {
    enabled: boolean;
    title: string;
    text: string;
    display: {
      costingItemNotes: boolean;
    };
  };
  summary: {
    enabled: boolean;
    title: string;
    text: string;
    display: {
      category: boolean;
      categoryTotal: boolean;
      costIncGST: boolean;
      gst: boolean;
    };
  };
  terms: {
    enabled: boolean;
    title: string;
    text: string;
  };
  acceptance: {
    enabled: boolean;
    title: string;
    text: string;
  };
  footer: {
    enabled: boolean;
    text: string;
    display: {
      pageNumbers: boolean;
    };
  };
  childTemplate?: SalesQuotePrintTemplate;
};
export type SalesQuotePrintTemplateNamePayload = {
  name: string;
};
