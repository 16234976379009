import React from "react";
import { useQuery } from "@apollo/client";
import {
  ListQuoteRequestMessagesResponse,
  OnQuoteRequestComment,
} from "../../graphql/types/models/quote-request";
import { LIST_QUOTE_REQUEST_MESSAGES } from "../../graphql/queries/quote-request/queries";
import { QUOTE_REQUEST_COMMENT_SUB } from "../../graphql/queries/quote/subscription";

type QuoteRequestFilter = {
  jobId?: string;
  salesQuoteId?: string;
};
export function useQuoteRequestMessageQuery(
  quoteRequestId: string,
  subcontractorId: string
) {
  const { data: quoteRequestMessagesData, subscribeToMore } = useQuery<
    ListQuoteRequestMessagesResponse
  >(LIST_QUOTE_REQUEST_MESSAGES, {
    variables: {
      quoteRequestId,
      subcontractorId,
    },
  });

  React.useEffect(() => {
    if (!subscribeToMore) {
      return;
    }

    const unsubNewComment = subscribeToMore<
      OnQuoteRequestComment,
      { quoteRequestId: string; subcontractorId: string }
    >({
      document: QUOTE_REQUEST_COMMENT_SUB,
      variables: {
        quoteRequestId,
        subcontractorId,
      },
      onError: (error) => {
        console.error({ error });
      },
      updateQuery: (previousQueryResult, response) => {
        return {
          ...previousQueryResult,
          listQuoteRequestMessages: [
            ...(previousQueryResult.listQuoteRequestMessages || []),
            response.subscriptionData.data.comment,
          ],
        };
      },
    });

    return () => {
      unsubNewComment?.();
    };
  }, [subscribeToMore, quoteRequestId, subcontractorId]);

  const quoteRequestMessages = React.useMemo(
    () => quoteRequestMessagesData?.listQuoteRequestMessages || [],
    [quoteRequestMessagesData]
  );

  return { quoteRequestMessages };
}
