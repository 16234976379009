import { Formik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StateManager } from "react-select/src/stateManager";
import { TakeoffListItem } from "../../models/take-off";

import GenericFormBody, {
  GenericFormFields,
} from "../generic-form/GenericFormBody";
import { CalcOptions, createCalcFields, Rounding } from "./utils";

type CalculatorOptionsProps = {
  onRoundingChange: (value: string | string[]) => void;
  onWastageChange: (value: string | string[]) => void;
  onTakeOffChange: (value: string | string[]) => void;
  roundingRef: React.Ref<StateManager>;
  wastageRef: React.Ref<StateManager>;
  initialRounding?: Rounding;
  initialWastage?: string;
  initialTakeoff?: TakeoffListItem;
  takeoffs: TakeoffListItem[];
  onCreateTakeoffPress?: () => void;
};

const CalculatorOptions: React.FC<CalculatorOptionsProps> = (props) => {
  const { t } = useTranslation();
  const {
    onRoundingChange,
    onWastageChange,
    onTakeOffChange,
    roundingRef,
    wastageRef,
    initialWastage,
    initialRounding,
    initialTakeoff,
    takeoffs,
    onCreateTakeoffPress,
  } = props;

  const calcFields = React.useMemo<GenericFormFields<CalcOptions>>(() => {
    return createCalcFields(
      t,
      onRoundingChange,
      onWastageChange,
      onTakeOffChange,
      roundingRef,
      wastageRef,
      takeoffs,
      onCreateTakeoffPress
    );
  }, [
    t,
    onTakeOffChange,
    onWastageChange,
    onRoundingChange,
    roundingRef,
    wastageRef,
    takeoffs,
    onCreateTakeoffPress,
  ]);
  return (
    <div className="calculator-form">
      <Formik
        enableReinitialize={true}
        onSubmit={() => {}}
        initialValues={{
          takeOff: initialTakeoff?._id ? initialTakeoff._id : "",
          wastage: initialWastage ? initialWastage : "0",
          rounding: initialRounding ? initialRounding : Rounding.NONE,
        }}
      >
        {(props) => (
          <Form
            noValidate
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}
          >
            {<GenericFormBody fields={calcFields} {...props} />}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CalculatorOptions;
