import { TFunction } from "react-i18next";

export const getTemplatesSections = (t: TFunction) => [
  {
    sectionName: t("navigation.quotesSection.estimations"),
    sectionItems: [
      {
        title: t("navigation.jobsSection.estimation"),
        url: "/settings/estimation-templates",
      },
      {
        title: t("navigation.quotesSection.takeoff"),
        url: "/settings/takeoff-templates",
      },
      {
        title: t("costing.specification"),
        url: "/settings/specification-templates",
      },
    ],
  },
  {
    sectionName: t("navigation.quotesSection.job"),
    sectionItems: [
      {
        title: t("navigation.jobsSection.schedule"),
        url: "/settings/schedule-templates",
      },
      {
        title: t("navigation.jobsSection.siteDiary"),
        url: "/settings/site-diaries",
      },
    ],
  },
  {
    sectionName: t("common.other"),
    sectionItems: [
      {
        title: t("tasks.checklist"),
        url: "/settings/checklist-templates",
      },
    ],
  },
];
