import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { chain } from "lodash";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import Container from "react-bootstrap/Container";
import { notify } from "../../../components/notification";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { PriceListUOM } from "../../../models/price-list";
import CostingImport from "../../../components/costing/costing-import";
import { FIELD_MAP_JOB_COSTING } from "../../../components/costing/costing-import/constants";
import { JobCostingItemImportMap } from "../../../components/costing/costing-import/types";
import {
  BulkJobCostingItem,
  SaveJobCostingBulkPayload,
  SaveJobCostingBulkResponse,
} from "../../../graphql/types/models/job-costing";
import { SAVE_JOB_COSTING_BULK } from "../../../graphql/queries/job-costing/mutations";

const JobCostingImportContainer: React.FC<DashboardContextValue> = ({
  navigationContext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const jobId = navigationContext?.job?._id;

  const [
    saveCostingItems,
    { loading: isLoadingSaveCostingItems },
  ] = useMutation<SaveJobCostingBulkResponse, SaveJobCostingBulkPayload>(
    SAVE_JOB_COSTING_BULK,
    {
      onCompleted: () => {
        history.push(`/jobs/${jobId}/costings`);

        notify({
          title: t("costing.importCostings"),
          content: t("costing.success.importCostings"),
        });
      },
      onError: () => {
        notify({
          error: true,
          title: t("costing.importCostings"),
          content: t("costing.errors.importCostings"),
        });
      },
    }
  );

  const handleMapSubmit = React.useCallback(
    async (values: JobCostingItemImportMap[]) => {
      if (!jobId) return;

      const items: BulkJobCostingItem[] = chain(values)
        .filter((item) => !!item.name)
        .map((item, i) => ({
          name: item.name || "",
          note: item.note || "",
          category: item.category || t("costing.uncategorised"),
          UOM: item.UOM || PriceListUOM.QTY || "",
          cost: parseFloat(item.cost?.toString() || "") || 0,
          real_cost: parseFloat(item.real_cost?.toString() || "") || 0,
          quantity: parseFloat(item.quantity || "") || 0,
          raw_quantity: (parseFloat(item.quantity || "") || 0).toString(),
          hasGST: !/(y|1)/i.test(item.gstFree || ""),
        }))
        .value();

      if (items.length > 0) {
        saveCostingItems({
          variables: {
            jobId,
            items,
          },
        });
      }
    },
    [jobId]
  );

  return (
    <Container fluid className="m-0 p-0 h-100">
      <Helmet title={t("navigation.jobsSection.importCostings")} />
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.JOBS_SECTION.COSTING} />
      <CostingImport
        fieldMap={FIELD_MAP_JOB_COSTING}
        onImport={handleMapSubmit}
        isLoading={isLoadingSaveCostingItems}
      />
    </Container>
  );
};

export default withDashboardContext(JobCostingImportContainer);
