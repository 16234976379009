import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import SetNavigationRoute from "../../../components/navigation/SetNavigationRoute";
import { NAVIGATION_ROUTES } from "../../../components/dashboard/sidebar/utils/navigation-items";
import {
  DashboardContextValue,
  withDashboardContext,
} from "../../layouts/dashboard/DashboardContext";
import { RootReducerState } from "../../../redux/reducer";
import { UserPayload } from "../../../graphql/types/models/auth";
import { UserRoles } from "../../../models/team-member";
import { useSalesQuoteQuery } from "../../../hooks/queries/useSalesQuoteQuery";
import QuoteDetailsCard from "./quote-details";
import ContactDetails from "./contact-details";
import { useNote } from "../../../hooks/useNote";
import { NoteEntityType } from "../../../graphql/types/models/note";
import { RouteComponentProps } from "react-router-dom";
import { useEditEstimation } from "../../../hooks/queries/useEditEstimation";
import EstimationLocationHeader from "../../header/estimation-location-header";
import { useChecklists } from "../../../hooks/useChecklists";
import { EnumChecklistEntityType } from "../../../models/checklists";
import { EnumChecklistTemplateType } from "../../../models/task";
import "./styles.scss";

type QuoteOverviewProps = DashboardContextValue &
  RouteComponentProps<Params> & {
    user: UserPayload | null;
  };

type Params = {
  id: string;
};

const QuoteOverview: React.FC<QuoteOverviewProps> = ({
  navigationContext,
  setNavigationContext,
  user,
  match,
}) => {
  const { t } = useTranslation();
  const isReadonly = React.useMemo(() => user?.role === UserRoles.basic, [
    user,
  ]);
  const isLocked = navigationContext?.quote?.isLocked || false;
  const quoteId = match.params.id;

  const { salesQuote, loading: salesQuoteLoading } = useSalesQuoteQuery(
    quoteId
  );
  const { renderNotesCard } = useNote(NoteEntityType.Estimation, quoteId);
  const { handleQuoteEdit, renderUpdateSalesQuoteModal } = useEditEstimation();
  const { renderChecklists } = useChecklists({
    entityId: quoteId,
    entityType: EnumChecklistEntityType.Quote,
    checklistTemplateType: EnumChecklistTemplateType.Estimation,
  });

  const job = React.useMemo(() => {
    return salesQuote?.job;
  }, [salesQuote?.job]);

  React.useEffect(() => {
    const quote = navigationContext?.quote;
    if (quote) {
      setNavigationContext({
        quote: { ...quote, ...(job && { jobId: job._id }) },
      });
    }
  }, [setNavigationContext, job]);

  const renderQuoteDetails = () => {
    return (
      <Row>
        <Col lg={4} xs={12}>
          <div className="card-wrapper">
            {salesQuote && (
              <QuoteDetailsCard
                quote={salesQuote}
                disabled={!salesQuote || salesQuoteLoading}
                onEdit={handleQuoteEdit}
                buttonDisabled={isLocked || isReadonly}
                isReadonly={isReadonly}
              />
            )}
          </div>
          <div className="card-wrapper">
            <ContactDetails
              disabled={salesQuoteLoading}
              contact={salesQuote?.contact}
            />
          </div>
        </Col>
        <Col lg={8} xs={12} className="pb-sm-5 pb-lg-0 pl-sm-4">
          <div className="pb-sm-4">{renderNotesCard()}</div>
          <div className="mb-2">{renderChecklists()}</div>
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid className="m-0 p-0 h-100 job-overview">
      <SetNavigationRoute routeId={NAVIGATION_ROUTES.QUOTES_SECTION.OVERVIEW} />
      <Helmet title={t("navigation.quotesSection.overview")} />
      <EstimationLocationHeader />
      {renderQuoteDetails()}
      {salesQuote && renderUpdateSalesQuoteModal(salesQuote)}
    </Container>
  );
};

const mapStateToProps = (state: RootReducerState) => {
  return {
    user: state.authentication.user,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withDashboardContext(QuoteOverview));
